import { Tabs } from '@vs/vsf-kit';
import PerformDepartment from './tabs/PerformDepartment';
import LabItem from './tabs/LabItem';
import { useEffect } from 'react';

import '@/style/adaptation.less';

const Tab = (props) => {
  const items = [
    {
      key: '1',
      label: '检验项目维护',
      children: <LabItem value={props.value} />,
    },
    {
      key: '2',
      label: '执行科室维护',
      children: <PerformDepartment value={props.value} />,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      items={items}
      className="aspirin-tabs-box heightAll100"
      style={{ height: '100%' }}
    />
  );
};

export default Tab;
