import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpAdmissionController_getAvailableByClinicInpAdmissionQto_405065: {
    method: 'post',
    url: '/api/clinic-inp-admission/get-available-by-clinic-inp-admission-qto',
    parameterFomatter: (data?: {
      qto: ClinicInpAdmissionQtoClinicInpAdmissionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicInpAdmissionShowVoClinicInpAdmissionEntranceWebVo,
    ) => data,
  },
  ClinicInpAdmissionController_saveClinicInpAdmission_b04761: {
    method: 'post',
    url: '/api/clinic-inp-admission/save-clinic-inp-admission',
    parameterFomatter: (data?: {
      clinicInpAdmissionShowVo: ClinicInpAdmissionShowVoClinicInpAdmissionEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-operation-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallOperationDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OperationDictionaryVoClinicInpAdmissionEntranceWebVoClinicinpadmissionshowvo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058: {
    method: 'post',
    url: '/api/organization/get-paged-by-department-id-and-search-code',
    parameterFomatter: (data?: { searchCode: string; departmentId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoClinicInpAdmissionEntranceWebVoClinicinpadmissionshowvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrganizationController_getAllByUpInstitutionCodeAndSearchCode_257200: {
    method: 'post',
    url: '/api/organization/get-all-by-up-institution-code-and-search-code',
    parameterFomatter: (data?: {
      upInstitutionIdIs: number;
      searchCode: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoClinicInpAdmissionEntranceWebVoClinicinpadmissionshowvo[],
    ) => data,
  },
  ClinicRecordController_getOutpDiagnosisBySearchLike_08f2cd: {
    method: 'post',
    url: '/api/clinic-record/get-outp-diagnosis-by-search-like',
    parameterFomatter: (data?: {
      outpEncounterId: number;
      searchLike: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosisRecordWithDiagnosisVoClinicRecordEntranceWebVo[],
    ) => data,
  },
});
