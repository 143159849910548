import {
  Button,
  ButtonProps,
  compose,
  Dropdown,
  MenuProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

/**
 * 定义功能键的枚举类型
 */
enum methodKeyType {
  ctrlKey,
  altKey,
  shiftKey,
  metaKey,
}

enum ordinary {
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
}
enum mathKey {
  zero = '0',
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
  six = '6',
  seven = '7',
  eight = '8',
  nine = '9',
}

type ordinaryKeyType = keyof typeof ordinary | mathKey;

type paramsType = {
  [key: string]: any;
};
type newMenuProps = {
  items: {
    label: string;
    key: string | number;
    methodKey: keyof typeof methodKeyType;
    ordinaryKey: ordinaryKeyType;
    onClick: ({ event, params }) => void;
    params: paramsType;
    size?: any;
  }[];
};
export type ButtonAndKeyDownProps = {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 键盘按下
   * 值变化回调
   */
  onChange?: (value?: any) => void;
  menu?: newMenuProps;
  /**
   * 点击事件
   */
  onClick: ({ params, event }) => void;
  /**
   * 功能键
   */
  methodKey: keyof typeof methodKeyType;
  /**
   * 普通键
   */
  ordinaryKey: ordinaryKeyType;
  /**
   * 参数
   */
  params?: { [key: string]: any };
  children: React.ReactNode;
  /**
   * 是按钮的类型
   */
  buttonType?: 'button' | 'dropdown';
  disabled?: boolean;
  type?: ButtonProps['type'];
  ghost?: boolean;
  danger?: boolean;

  /**
   * 停止默认行为
   */
  preventDefault?: boolean;
  className?: string;
};
/**
 * 所有键盘事件集合
 */
type keyDictType = {
  [key: string]: {
    key: {
      ctrl: ButtonAndKeyDownProps['methodKey'];
      key: ButtonAndKeyDownProps['ordinaryKey'];
    };
    func: ({ event, params }) => void;
    params: {
      [key: string]: string;
    };
    disabled: boolean;
  };
};
/**
 * 拓展按钮
 */
const ButtonAndKeyDown = forwardRef((props: ButtonAndKeyDownProps, ref) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    methodKey,
    ordinaryKey,
    menu,
    onClick,
    buttonType = 'button',
    preventDefault,
    className,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const [disabled, setDisabled] = useState(props?.disabled ?? false);

  /**
   * 匹配按钮组合组件的 props
   */
  const newMenu: MenuProps = { items: [] };
  /**
   * 用于存取键盘的集合
   */
  const keyDict: keyDictType = useMemo(() => ({}), []);
  /**
   * 设置键盘事件集合函数
   * @param item 收集
   */
  const updateKey = useCallback(
    (item) => {
      /**
       * 小提醒
       */
      if (!item.methodKey) {
        console.error('需要 props 参数 methodKey');
        return;
      }
      if (!item.ordinaryKey) {
        console.error('需要 props 参数 ordinaryKey');
        return;
      }
      /**
       * 把功能键和普通键合起来用作对象的键 ctrlKey+C
       */
      const str = [item.methodKey, item.ordinaryKey].join('-');
      keyDict[str] = {
        key: {
          ctrl: item.methodKey,
          key: item.ordinaryKey,
        },
        func: item.onClick,
        params: item.params,
        disabled: item.disabled,
      };
    },
    [keyDict],
  );
  /**
   * 判断是否组合键函数
   */
  const isDropdown = useCallback(() => {
    if (buttonType === 'dropdown' && menu && menu.items) {
      menu.items.forEach((item) => {
        updateKey(item);
        /**
         * 这是把组合按钮需要的值从 menu 剥离到新对象 newMenu 上
         */
        if (newMenu.items) {
          newMenu.items.push({
            label: item.label,
            key: item.key,
            onClick: (event) => {
              /**
               * 同步键盘事件让它点击触发也可以传参数
               */
              item.onClick({
                event,
                params: item.params,
              });
            },
          });
        }
      });
    }
  }, [buttonType, menu, newMenu.items, updateKey]);

  useImperativeHandle(
    ref,
    () => ({
      getDisabled: disabled,
      setDisabled: (state) => {
        setDisabled(state);
      },
    }),
    [disabled],
  );

  /**
   * 键盘事件函数
   * @param event 键盘事件 Event
   */
  const onKeyChange = useCallback(
    (event) => {
      /**
       * 按下的键转大写
       */
      const name = event?.key?.toLocaleUpperCase();
      /**
       * 循环键盘集合找出匹配的
       */
      for (const item in keyDict) {
        const { ctrl, key } = keyDict[item].key;
        if (
          event[ctrl] &&
          name === key.toLocaleUpperCase() &&
          !keyDict[item].disabled
        ) {
          if (preventDefault) {
            event.preventDefault();
          }
          /**
           * 执行函数
           */
          keyDict[item]?.func({
            event,
            params: keyDict[item].params,
          });
        }
      }
    },
    [keyDict, preventDefault],
  );
  /**
   * 同步键盘事件让它点击触发也可以传参数
   */
  const submit = (event) => {
    onClick({ event, params: props.params });
  };
  /**
   * 添加功能键提示
   * @returns 文字
   */
  const renderKey = () => {
    return (
      props.methodKey &&
      props.ordinaryKey &&
      `(${props.ordinaryKey.toLocaleUpperCase()})`
    );
  };
  useEffect(() => {
    updateKey(props);
    isDropdown();
    /**
     * 绑定按键事件
     */
    document.addEventListener('keydown', onKeyChange);
    return () => {
      /**
       * 清除事件
       */
      document.removeEventListener('keydown', onKeyChange);
    };
  }, [isDropdown, onKeyChange, props, updateKey]);

  if (buttonType === 'button') {
    return (
      <Button
        onClick={submit}
        {...rest}
        disabled={ref ? disabled : props?.disabled}
        className={classNames('icon16', className)}
      >
        {props.children} {renderKey()}
      </Button>
    );
  }

  if (buttonType === 'dropdown') {
    const { type, ...state } = rest;
    return (
      <Dropdown.Button
        onClick={submit}
        menu={newMenu}
        {...state}
        disabled={disabled}
      >
        {props.children} {renderKey()}
      </Dropdown.Button>
    );
  }
  return <>你确定type正确吗</>;
});
ButtonAndKeyDown.displayName = 'ButtonAndKeyDown';
export default compose(
  withField<string>({
    name: 'ButtonAndKeyDown',
  }),
  withPreview<ButtonAndKeyDownProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(ButtonAndKeyDown) as typeof ButtonAndKeyDown;
