export const convertGetInpInpAdmissionByClinicInpAdmissionQtoRes2InpInpAdmissionForm =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.name = _.get(input, 'data.patientVo.name');
    output.gender = _.get(input, 'data.patientVo.gender');
    output.birthday = _.get(input, 'data.patientVo.birthday');
    output.birthAddress = _.get(input, 'data.patientVo.birthAddress');
    output.nativeAddress = _.get(
      input,
      'data.patientVo.patientProfileDto.nativeAddress',
    );
    output.idNumber = _.get(input, 'data.patientVo.idNumber');
    output.citizenship = _.get(
      input,
      'data.patientVo.patientProfileDto.citizenship',
    );
    output.nation = _.get(input, 'data.patientVo.patientProfileDto.nation');
    output.marriageStatus = _.get(
      input,
      'data.patientVo.patientProfileDto.marriageStatus',
    );
    output.phoneNumber = _.get(input, 'data.patientVo.phoneNumber');
    output.cellphone = _.get(input, 'data.patientVo.cellphone');
    output.currentAddress = _.get(
      input,
      'data.patientVo.patientProfileDto.currentAddress',
    );
    output.chargeType = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBillingInfoDto.chargeType',
    );
    output.identityCode = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBillingInfoDto.identityCode',
    );
    output.unitInContract = _.get(
      input,
      'data.patientVo.patientProfileDto.unitInContract',
    );
    output.workUnit = _.get(input, 'data.patientVo.patientProfileDto.workUnit');
    output.admissionWay = _.get(input, 'data.inpAdmissionApplyVo.admissionWay');
    output.admissionDateTime = _.get(
      input,
      'data.inpAdmissionApplyVo.admissionDateTime',
    );
    output.appointWard = {};
    output.appointWard = _.get(input, 'data.inpAdmissionApplyVo.appointWard');
    output.inpAppointDto = {};
    output.inpAppointDto.id = _.get(
      input,
      'data.inpAdmissionApplyVo.inpAppointDto.id',
    );
    output.visitStartDate = _.get(input, 'data.visitStartDate');
    output.virtualInHospitalType = _.get(
      input,
      'data.inpAdmissionApplyVo.virtualInHospitalType',
    );
    output.diagnosisList = _.get(
      input,
      'data.inpAdmissionApplyVo.diagnosisList',
    );
    output.visitType = _.get(input, 'data.inpVisitVo.visitType'); // InpVisitTypeEnum 住院业务类型
    output.patientClass = _.get(input, 'data.inpVisitVo.patientClass');
    output.admissionPurpose = _.get(input, 'data.inpVisitVo.admissionPurpose');
    output.virtualInHospitalIndication = _.get(
      input,
      'data.inpVisitVo.virtualInHospitalIndication',
    );
    output.inpVisitVirtualInHospitalType = _.get(
      input,
      'data.inpVisitVo.virtualInHospitalType',
    );
    output.admissionToDepartment = _.get(
      input,
      'data.inpVisitVo.admissionToDepartment',
    );
    output.contactPersonName = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonName',
    );
    output.contactPersonRelationship = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonRelationship',
    );
    output.contactPersonPhone = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonPhone',
    );
    output.contactPersonAddress = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonAddress',
    );
    output.job = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.job',
    );
    output.ward = _.get(input, 'data.inpVisitVo.patientInHospitalDto.ward');
    output.medicalGroupLeader = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.medicalGroupLeader',
    );
    output.applyDoctor = {};
    output.applyDoctor.id = _.get(
      input,
      'data.inpAdmissionApplyVo.applyDoctor.id',
    );
    output.applyDoctor.staffName = _.get(
      input,
      'data.inpAdmissionApplyVo.applyDoctor.staffName',
    );
    output.patientAdmissionCondition = _.get(
      input,
      'data.inpVisitVo.patientAdmissionCondition',
    );
    output.id = _.get(input, 'data.inpAdmissionApplyVo.id');
    output.patientId = _.get(input, 'data.patientVo.id');
    output.inpAdmissionApplyPatientInfoDtoId = _.get(
      input,
      'data.inpAdmissionApplyVo.inpAdmissionApplyPatientInfoDto.id',
    );
    output.inpVisitVo = _.get(input, 'data.inpVisitVo');
    output.applyDate = _.get(input, 'data.inpAdmissionApplyVo.applyDate');
    return output;
  };
// ------------------------------

export const convertInpInpAdmissionForm2SaveInpInpAdmissionReq = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.saveInpInpAdmissionApplyBto = {};
  output.saveInpInpAdmissionApplyBto.id = _.get(input, 'id');
  output.saveInpInpAdmissionApplyBto.admissionDateTime = _.get(
    input,
    'admissionDateTime',
  );
  output.saveInpInpAdmissionApplyBto.inpAppointBto = _.get(
    input,
    'inpAppointDto',
  );
  output.saveInpVisitForAdmissionBto = {};
  output.saveInpVisitForAdmissionBto.admissionApplyId = _.get(input, 'id');
  output.saveInpVisitForAdmissionBto.patientId = _.get(input, 'patientId');
  output.saveInpVisitForAdmissionBto.patientClass = _.get(
    input,
    'patientClass',
  );
  output.saveInpVisitForAdmissionBto.admissionPurpose = _.get(
    input,
    'admissionPurpose',
  );
  output.saveInpVisitForAdmissionBto.patientAdmissionCondition = _.get(
    input,
    'patientAdmissionCondition',
  );
  output.saveInpVisitForAdmissionBto.virtualInHospitalIndication = _.get(
    input,
    'virtualInHospitalIndication',
  );
  output.saveInpVisitForAdmissionBto.virtualInHospitalType = _.get(
    input,
    'virtualInHospitalType',
  );
  output.saveInpVisitForAdmissionBto.admissionToDepartmentId = _.get(
    input,
    'admissionToDepartment.id',
  );
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto = _.get(
    input,
    'inpVisitVo.inpVisitExtensionPatientInfoDto',
  );
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto.job =
    _.get(input, 'job');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto.contactPersonName =
    _.get(input, 'contactPersonName');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto.contactPersonRelationship =
    _.get(input, 'contactPersonRelationship');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto.contactPersonAddress =
    _.get(input, 'contactPersonAddress');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionPatientInfoBto.contactPersonPhone =
    _.get(input, 'contactPersonPhone');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionStaffBto = _.get(
    input,
    'inpVisitVo.inpVisitExtensionStaffDto',
  );
  output.saveInpVisitForAdmissionBto.inpVisitExtensionStaffBto.medicalGroupLeaderId =
    _.get(input, 'medicalGroupLeader.id');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionBillingInfoBto = {};
  // output.saveInpVisitForAdmissionBto.inpVisitExtensionBillingInfoBto = _.get(
  //   input,
  //   'inpVisitVo.inpVisitExtensionBillingInfoBto',
  // );
  output.saveInpVisitForAdmissionBto.inpVisitExtensionBillingInfoBto.identityCode =
    _.get(input, 'identityCode');
  output.saveInpVisitForAdmissionBto.inpVisitExtensionBillingInfoBto.chargeType =
    _.get(input, 'chargeType');
  output.saveInpVisitForAdmissionBto.patientInHospitalBto = {};
  output.saveInpVisitForAdmissionBto.patientInHospitalBto = _.get(
    input,
    'inpVisitVo.patientInHospitalDto',
  );
  output.saveInpVisitForAdmissionBto.patientInHospitalBto.wardId = _.get(
    input,
    'ward.id',
  );
  output.updatePatientBto = {};
  output.updatePatientBto.id = _.get(input, 'patientId');
  output.updatePatientBto.name = _.get(input, 'name'); // TODO output.updatePatientBto.nameInputCode = null; // String 姓名输入码
  output.updatePatientBto.gender = _.get(input, 'gender');
  output.updatePatientBto.birthday = _.get(input, 'birthday');
  output.updatePatientBto.patientProfileBto = {};
  output.updatePatientBto.patientProfileBto.nativeAddress = _.get(
    input,
    'nativeAddress',
  );
  output.updatePatientBto.patientProfileBto.currentAddress = _.get(
    input,
    'currentAddress',
  );
  output.updatePatientBto.patientProfileBto.registeredResidenceAddress = _.get(
    input,
    'registeredResidenceAddress',
  );
  output.updatePatientBto.patientProfileBto.workUnit = _.get(input, 'workUnit');
  output.updatePatientBto.patientProfileBto.contact = _.get(input, 'contact');
  output.updatePatientBto.patientProfileBto.marriageStatus = _.get(
    input,
    'marriageStatus',
  );
  output.updatePatientBto.patientProfileBto.nation = _.get(input, 'nation');
  output.updatePatientBto.patientProfileBto.citizenship = _.get(
    input,
    'citizenship',
  );
  output.updatePatientBto.birthAddress = _.get(input, 'birthAddress'); // TODO output.updatePatientBto.status = null; // String 状态码
  output.updatePatientBto.idNumber = _.get(input, 'idNumber');
  output.updatePatientBto.cellphone = _.get(input, 'cellphone');
  output.updatePatientBto.phoneNumber = _.get(input, 'phoneNumber');
  return output;
};
