import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Icon,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  Tree,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icons from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

// import Modal from '../registerInformation/modal';
import Content from './content';
// import styles from './index.less';
import TreeMenu from './tree_menu_focus';

const Index = () => {
  const [chiefItem, setchiefItem] = useState<any>();
  const chiefItemRef = useRef<any>();

  const handelEditChief = (v) => {
    chiefItemRef.current?.handelAdd(v);
  };
  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '20%',
            bgColor: 'var(--background)',
          },
          {
            block: 1,
            // bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <TreeMenu
        handelEditChief={handelEditChief}
        onChange={(v) => {
          if (v?.parent) {
            setchiefItem(null);
          } else if (!v?.parent && !('' + v?.id)?.includes('create_')) {
            setchiefItem(v);
          }
        }}
      />

      <Content ref={chiefItemRef} chiefItem={chiefItem} />
    </Container>
  );
};
export default Index;
// 主诉维护
