import vsf, { defineStore } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import { mockOutpVisitEncounterId } from '@/mock';
import storage from '@/utils/storage';

import common from './common';
import cpoe from './cpoe';

const convertQueryPagefallByClinicBedWithPatientForDoctorQtoRes2PatientInfoBar =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.id = _.get(input, `data.result.[0].id`);
    output.patientType = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientType`,
    );
    output.wardName = _.get(input, `data.result.[0].wardName`);
    output.bedLabel = _.get(input, `data.result.[0].bedLabel`);
    output.patientName = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientName`,
    );
    output.patientGender = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientGender`,
    );
    output.patientBirthday = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientBirthday`,
    );
    output.patientAge = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientAge`,
    );
    output.patientDisplayId = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientDisplayId`,
    );
    output.patientAllergy = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.patientAllergy`,
    )?.map((pa) => {
      if (pa.allergyName) {
        return pa.allergyName;
      } else if (pa.allergyDrugProductName) {
        return pa.allergyDrugProductName;
      } else if (pa.allergyDrugName) {
        return pa.allergyDrugName;
      } else {
        return pa.allergyDrugClassName;
      }
    });
    output.diagnosisDescription = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.diagnosisDescription`,
    );
    output.seriousIndicator = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.seriousIndicator`,
    );
    output.crisisIndicator = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.crisisIndicator`,
    );
    output.nursingClass = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.nursingClass`,
    );
    output.inChargeDoctorName = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.inChargeDoctorName`,
    );
    output.admissionDateTime = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.admissionDateTime`,
    );
    output.admissionDays = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.admissionDays`,
    );
    output.operationEndDateTime = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.operationEndDateTime`,
    );
    output.afterOperationDays = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.afterOperationDays`,
    );
    output.chargeType = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.chargeType`,
    );
    output.identityCode = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.identityCode`,
    );
    output.totalCost = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.totalCost`,
    );
    output.prePaymentAmount = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.prePaymentAmount`,
    );
    output.prePaymentBalance = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.prePaymentBalance`,
    );
    output.drugResistanceType = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.drugResistanceType`,
    );
    output.drugResistanceName = _.get(
      input,
      `data.result.[0].clinicPatientBedDto.drugResistanceName`,
    );
    return output;
  };

const user = defineStore({
  medicalType: storage.get('medicalType') || [],
  // 患者列表
  patientList: storage.get('patientList') || [],
  // 当前患者
  currentPatient: storage.get('currentPatient') || {},
  // currentPatient: {},
  // 门诊未诊患者数量
  patientNumber: storage.get('patientNumber') || {},
  // 当前门诊就诊信息
  outVisitInfo: storage.get('outVisitInfo') || {},
  // 当前门诊挂号信息
  outRegisterInfo: storage.get('outRegisterInfo') || {},
  // 当前住院就诊信息
  inVisitInfo: storage.get('inVisitInfo'),
  // 当前急诊就诊信息
  erVisitInfo: storage.get('erVisitInfo'),
  // 医嘱列表信息
  doctorOrder: {},
  // 当前就诊类型: in. 住院 out. 门诊 er.急诊
  hospitalType: storage.get('hospitalType') || 'out',
  // 当前门诊就诊信息
  outVisitTreatInfo: storage.get('outVisitTreatInfo') || {},
  // 当前住院就诊信息(信息栏)
  inVisitTreatInfo: storage.get('inVisitTreatInfo') || {},
  // 当前急诊就诊信息(信息栏)
  erVisitTreatInfo: storage.get('erVisitTreatInfo') || {},
  // 门诊设置
  patientLabelConfig: [],
  accountInfo: {},
  // 医生信息
  staffInfo: {},
  // 医嘱校对、医嘱执行等页面患者列表
  // orderPatientList: storage.get('orderPatientList') || [],
  orderPatientList: [],
  // 医嘱校对、医嘱执行等页面患者列表当前选中患者
  // currentOrderPatient: storage.get('currentOrderPatient') || {},
  currentOrderPatient: null,
  // 病区ID
  wardId: '',
  initKey: 0,
  rest: storage.get('rest') ?? 0,
  // 更新
  setInitKey: () => {
    user.initKey = user.initKey + 1;
  },
  // 更新
  setRest: () => {
    user.rest = user.rest + 1;
    storage.set('rest', user.rest + 1);
  },

  // 住院 (in) or 门诊 (out) or急诊 (er)
  setHospitalType: (v) => {
    user.hospitalType = v;
    storage.set('hospitalType', v);
  },

  // 出诊号别
  setMedicalType: (v) => {
    user.medicalType = v;
    storage.set('medicalType', v);
  },

  // 获取就诊患者列表(全局没有用到先注释，接口已经没有了)
  // getPatientList: async () => {
  //   const res =
  //     await vsf?.services?.RegisterPatientController_allRegisterPatient_315489?.(
  //       {
  //         qto: {
  //           registerTypeIs: '生殖普通门诊',
  //           //  || user.medicalType?.[0]?.label || '生殖普通门诊',
  //         },
  //       },
  //     );
  //   user.setPatientList(res?.data);
  //   return res?.data;
  // },

  // 设置就诊患者列表
  // setPatientList: (v) => {
  //   user.patientList = v;
  //   storage.set('patientList', v);
  // },

  // 设置当前就诊患者
  setCurrentPatient: (v) => {
    user.currentPatient = v;
    storage.set('currentPatient', v);
  },
  // !---------------门诊---------------------
  // 门诊未诊患者数量
  setPatientNumber: (v) => {
    user.patientNumber = v;
    storage.set('patientNumber', v);
  },
  // 门诊未诊患者数量
  // getOutPatientNumber: async () => {
  //   const res =
  //     await vsf.services?.OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538(
  //       {
  //         qto: {
  //           clinicRegisterDetailIdIn: stores?.cpoe?.signSelection.map(
  //             (item) => item?.clinicRegisterDetailId,
  //           ),
  //           size: 5,
  //         },
  //       },
  //     );
  //   user.patientNumber(res?.data?.length);
  //   return res?.data;
  // },
  // 双击门诊候诊队列()
  setOutVisitInfo: (v) => {
    user.outVisitInfo = v;
    storage.set('outVisitInfo', v);
  },
  // 门诊挂号信息
  setOutRegisterInfo: (v) => {
    user.outRegisterInfo = v;
    storage.set('outRegisterInfo', v);
  },
  // 获取当前就诊信息相关信息 --- 门诊
  getOutVisitTreatInfo: async (id) => {
    if (id) {
      const res =
        await vsf.services?.OutpVisitController_getOutpVisitOverviewById_d464cf?.(
          {
            id,
          },
        );
      user.setOutVisitTreatInfo(res?.data);
      return res?.data;
    }
  },
  // 设置当前就诊信息 (包含患者信息, 病历信息) --- 门诊
  setOutVisitTreatInfo: (v) => {
    user.outVisitTreatInfo = v;
    storage.set('outVisitTreatInfo', v);
  },
  // !---------------住院---------------------
  // 住院医生站双击床卡/列表 就诊患者信息
  setInVisitInfo: (v) => {
    user.inVisitInfo = v;
    storage.set('inVisitInfo', v);
    user.setInitKey();
  },
  // 获取当前就诊信息相关信息 --- 住院
  getInVisitTreatInfo: async (id) => {
    if (id) {
      const res =
        await vsf?.services?.ClinicBedController_queryPagefallByClinicBedWithPatientForDoctorQto_d563e0?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: 0,
              size: 20,
              inpVisitIdIs: id,
            },
            ext: {},
          },
        );
      const output =
        convertQueryPagefallByClinicBedWithPatientForDoctorQtoRes2PatientInfoBar?.(
          res,
        );

      user.setInVisitTreatInfo(output);
      return res?.data;
    }
  },
  // 设置当前就诊信息 (包含患者信息, 病历信息) --- 住院
  setInVisitTreatInfo: (v) => {
    user.inVisitTreatInfo = v;
    storage.set('inVisitTreatInfo', v);
  },
  // !---------------急诊---------------------
  // 点击预检分诊列表
  setErVisitInfo: (v) => {
    user.inVisitInfo = v;
    storage.set('erVisitInfo', v);
    user.setInitKey();
  },
  setErVisitTreatInfo: (v) => {
    user.outVisitTreatInfo = v;
    storage.set('erVisitTreatInfo', v);
  },

  // get医嘱校对、医嘱执行等页面患者列表
  getOrderPatientList: async (params) => {
    const res =
      await vsf.services?.ClinicInpAdtController_patientListForTransferOrInDepartment_3544c7?.(
        {
          eo: {
            ...params,
          },
        },
      );
    // console.log(res?.data, 'res?.data');
    user.setOrderPatientList(res?.data);
    return res?.data ?? [];
  },

  // set医嘱校对、医嘱执行等页面患者列表
  setOrderPatientList: (v) => {
    user.orderPatientList = v;
    // storage.set('orderPatientList', v);
  },
  // set医嘱校对、医嘱执行等页面患者列表
  setCurrentOrderPatient: (v) => {
    user.currentOrderPatient = v;
    storage.set('currentOrderPatient', v);
  },
  // 提交病历
  submitMedicalDiagnose: async (params) => {
    const res =
      user?.hospitalType === 'out'
        ? await vsf?.services?.TreatInfoController_createTreatInfo_18fdc5?.({
            btoParam: {
              registerId: user?.outVisitInfo?.id,
              mainDiagnose: params?.formData?.diagnosis,
              patientId: user?.currentPatient?.patientId,
              doctorId: 102,
              departmentName: '生殖专科',
            },
          })
        : await vsf?.services?.TreatInfoController_createHospitalTreatInfo_af1168?.(
            {
              btoParam: {
                hospitalId: user?.outVisitInfo?.id,
                mainDiagnose: params?.formData?.diagnosis,
                patientId: user?.currentPatient?.patientId,
                doctorId: 102,
                departmentName: '生殖专科',
              },
            },
          );
    const res2 =
      await vsf?.services?.MedicalRecordController_createMedicalRecord_a984f3?.(
        {
          btoParam: {
            treatId: res?.data,
            medicalRecordContent: params?.JSON,
            templateId: params?.tpl?.id ?? null,
          },
        },
      );
    user.getOutVisitTreatInfo(res?.data);
    user.setInitKey();
  },
  // 获取医嘱信息 --- 门诊
  getDoctorOrder: async ({ hospitalType, id, today }) => {
    if (id) {
      let res;
      if (hospitalType === 'out') {
        res =
          await vsf?.services?.ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63?.(
            { outpVisitEncounterId: id },
          );
      } else {
        res =
          await vsf?.services?.ClinicRecordOrderController_getByInpVisitEncounterId_4acd8c?.(
            { inpVisitId: id, today },
          );
      }

      const data = res?.data?.map((item) => {
        if (item?.orderClass === 'DRUG') {
          return {
            ...item,
            drugPriceItemId: item?.drugOrder?.drugPriceItemId,
          };
        }
        return item;
      });
      user.setDoctorOrder(res?.data);
      return data;
    }
    return [];
  },

  // 设置当前医嘱信息 --- 门诊
  setDoctorOrder: (v) => {
    user.doctorOrder = v;
    // storage.set('doctorOrder', v);
  },

  setAccountInfo: async ({ accountInfo, id }) => {
    if (id) {
      const staffInfo =
        await vsf?.services?.StaffDetailDtoController_getById_6ecdf0({
          id,
        });
      if (staffInfo?.code === 200) {
        user?.getStaffInfo?.();
      }
    }
  },

  getStaffInfo: async () => {
    const res =
      await vsf?.services?.UserStaffController_getLoginStaff_278173?.();
    if (res?.code === 200) {
      user.staffInfo = res?.data;
      return res?.data;
    } else {
      user.staffInfo = null;
      vsf?.navigateTo('/login');
    }
  },

  setPatientLabelConfig: (v = []) => {
    user.patientLabelConfig = v;
  },
  // 门诊设置已选标签
  getListPatientLabelConfig: async (params) => {
    if (!params?.staffId) return;
    // 查询已选
    const res =
      await vsf?.services?.PatientLabelConfigController_getListPatientLabelConfigVoByStaffId_c961eb(
        params,
      );
    user.setPatientLabelConfig([...res?.data]);
    return res;
  },
  // 双签名验证
  staffLoginWithoutResponseCookies: async (doubleSignatureFormData) => {
    // 验证双签名
    const res =
      await vsf?.services?.AccountController_staffLoginWithoutResponseCookies_5fd9d3?.(
        {
          username: doubleSignatureFormData?.username,
          password: doubleSignatureFormData?.password,
        },
      );
    return res;
  },
  logout: async () => {
    const res = await vsf?.services?.AccountController_logout_377959?.();
    if (res?.code === 200) {
      message?.success('退出登录成功');
      vsf?.navigateTo('/login');
      // for (const item in user) {
      //   if (typeof user[item] !== 'function') {
      //     user[item] = null;
      //   }
      // }
    }
  },
  // 首次加载清空患者信息
  clearPatientData: () => {
    user.currentPatient = null;
    user.inVisitInfo = null;
    user.outRegisterInfo = null;
    user.inVisitTreatInfo = null;
    user.outVisitInfo = null;
    user.outVisitTreatInfo = null;
    user.outVisitTreatInfo = null;
    storage.set('outVisitInfo', null);
    storage.set('outRegisterInfo', null);
    storage.set('outVisitTreatInfo', null);
    storage.set('inVisitTreatInfo', null);
    storage.set('inVisitInfo', null);
  },
});

export default user;
