import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseParameterController_queryAllByParameterConfigQto_f22bd2: {
    method: 'post',
    url: '/api/base-parameter/query-all-by-parameter-config-qto',
    parameterFomatter: (data?: {
      qto: ParameterConfigQtoBaseParameterPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ParameterConfigVoBaseParameterEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseParameterController_getListParameterConfigVoByParentConfigId_96b3ca: {
    method: 'post',
    url: '/api/base-parameter/get-list-parameter-config-vo-by-parent-config-id',
    parameterFomatter: (data?: { parentConfigId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ParameterConfigVoBaseParameterEntranceWebVo[],
    ) => data,
  },
});
