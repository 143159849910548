import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugFormVsDispenseRoundingSettingController_getAllDrugVsDispenseRoundingSettingQto_062dbf:
    {
      method: 'post',
      url: '/api/drug-billing-setting/get-all-drug-vs-dispense-rounding-setting-qto',
      parameterFomatter: (data?: {
        qto: QueryDrugVsDispenseRoundingSettingQtoDrugBillingSettingPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugVsDispenseRoundingSettingWithProductVoDrugBillingSettingEntranceWebVo[],
      ) => data,
    },
  DrugFormVsDispenseRoundingSettingController_saveDrugVsDispenseRoundingSetting_078431:
    {
      method: 'post',
      url: '/api/drug-billing-setting/save-drug-vs-dispense-rounding-setting',
      parameterFomatter: (data?: {
        saveRounding: SaveDrugVsDispenseRoundingSettingBtoDrugBillingSettingServiceBto[];
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  DrugFormVsDispenseRoundingSettingController_deleteDrugVsDispenseRoundingSetting_6ae6b6:
    {
      method: 'post',
      url: '/api/drug-billing-setting/delete-drug-vs-dispense-rounding-setting',
      parameterFomatter: (data?: {
        deleteRounding: SaveDrugVsDispenseRoundingSettingBtoDrugBillingSettingServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  DrugFormVsDispenseRoundingSettingController_queryPagefallByQueryDrugVsDispenseRoundingSettingDropQtoByQueryDrugVsDispenseRoundingSettingDropQto_88dd5a:
    {
      method: 'post',
      url: '/api/drug-billing-setting/query-pagefall-by-query-drug-vs-dispense-rounding-setting-drop-qto-by-query-drug-vs-dispense-rounding-setting-drop-qto',
      parameterFomatter: (data?: {
        qto: QueryDrugVsDispenseRoundingSettingDropQtoDrugBillingSettingPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugVsDispenseRoundingSettingWithProductVoDrugBillingSettingEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  DrugDrugController_queryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQto_7eafe8:
    {
      method: 'post',
      url: '/api/drug-drug/query-pagefall-by-query-drug-product-dictionary-name-form-qto-by-query-drug-product-dictionary-name-form-qto',
      parameterFomatter: (data?: {
        qto: QueryDrugProductDictionaryNameFormQtoDrugDrugPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugProductDictionaryNameFormVoDrugDrugEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
