import vsf from '@vs/vsf-boot';
import {
  Button,
  Form,
  message,
  Modal,
  Radio,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ButtonAndKeyDown, Determine } from '@/pages/Index/components';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';

const DefendEditForm = forwardRef((props: any, ref) => {
  const { agreedPrescriptionIndicator, tree, value } = props;
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const templateClass = Form?.useWatch('templateClass', form);
  /**
   * 切换分类需要更新数据源并且清空已选值
   */
  useAsyncEffect(async () => {
    const res =
      await vsf.services?.OrderTemplateMasterController_getOrderTemplateMaster_bfb379?.(
        {
          templateClass,
          agreedPrescriptionIndicator,
        },
      );
    form?.setFieldValue('upTemplateId', void 0);
    setDataSource(
      res?.data
        ?.filter((item) => item?.folderOrTemplateIndicator === 'FOLDER')
        ?.map((item) => ({
          ...item,
          upTemplateId: item?.upTemplateId === 1 ? null : item?.upTemplateId,
        })),
    );
  }, [templateClass]);

  const onOk = async () => {
    try {
      const value = await vsf.refs.DefendEdit.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.OrderTemplateMasterController_createOrderTemplateMaster_f5aa85(
          {
            btoParam: {
              ...value,
              folderOrTemplateIndicator: 'TEMPLATE',
              agreedPrescriptionIndicator,
              ...(agreedPrescriptionIndicator
                ? {
                    templateClass: 'HOSPITAL_TEMPLATE',
                  }
                : {
                    templateClass,
                  }),
            },
          },
        );
      if (res?.code === 200) {
        message?.success('保存成功');
        return true;
      }
    } catch {
      return Promise?.reject();
    }
  };

  useImperativeHandle(ref, () => {
    return {
      onOk,
    };
  });
  useEffect(() => {
    if (value?.folderOrTemplateIndicator === 'FOLDER') {
      form?.setFieldValue('upTemplateId', { id: value?.id });
      form.setFieldValue(
        'sortNumber',
        tree?.current?.onSortNumber({
          upTemplateId: null,
          folderOrTemplateIndicator: 'TEMPLATE',
          id: value?.value ?? value?.id,
        }) + 1,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, value?.id, dataSource]);
  return (
    <VSForm id="DefendEdit" form={form} labelAlign="left">
      <Determine
        condition={agreedPrescriptionIndicator}
        fallback={
          <VSFormLayout labelWidth={100} columnCount={1}>
            <VSFormItem
              valueType="radioButton"
              label="可见范围"
              name={['templateClass']}
              initialValue={'PERSONAL_TEMPLATE'}
              dataSource={[
                {
                  value: 'PERSONAL_TEMPLATE',
                  label: '个人',
                },
                {
                  value: 'DEPARTMENT_TEMPLATE',
                  label: '科室',
                },
              ]}
              fieldProps={{
                buttonStyle: 'solid',
              }}
            />
          </VSFormLayout>
        }
      >
        <VSFormLayout labelWidth={100} columnCount={1}>
          <VSFormItem
            valueType="radioButton"
            label="使用范围"
            name={['clinicClass']}
            initialValue={'ERP'}
            dataSource={[
              { label: '全院', value: 'ERP' },
              { label: '住院', value: 'INP' },
              { label: '门诊', value: 'OUTP' },
            ]}
            fieldProps={{
              buttonStyle: 'solid',
            }}
          />
        </VSFormLayout>
      </Determine>
      <VSFormLayout labelWidth={100} columnCount={1}>
        <VSFormItem
          name={['upTemplateId']}
          label="模版分类"
          valueType="selectCode"
          rules={[
            {
              required: true,
            },
          ]}
          fieldNames={{
            label: 'title',
            value: 'id',
          }}
          fieldProps={{
            placeholder: '请选择模版分类',
            showSearch: true,
            onChange: (value) => {
              form.setFieldValue(
                'sortNumber',
                tree?.current?.onSortNumber({
                  upTemplateId: null,
                  folderOrTemplateIndicator: 'TEMPLATE',
                  id: value?.value ?? value?.id,
                }) + 1,
              );
            },
          }}
          transform={(value) => {
            return {
              upTemplateId: value?.id ?? value,
            };
          }}
          dataSource={[
            {
              title: '顶级目录',
              id: 1,
            },
            ...(dataSource ?? []),
          ]}
        />
        <VSFormItem
          name={['title']}
          label="模版名称"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            {
              required: true,
            },
          ]}
          fieldProps={{
            onChange: (event) => {
              vsf?.refs?.DefendEdit?.setFieldValue(
                'inputCode',
                askRomanAlphabet(event?.target?.value),
              );
            },
          }}
        />
        <VSFormItem
          name={['inputCode']}
          label="输入码"
          valueType="text"
          rules={[
            {
              required: true,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['sortNumber']}
          label="排序号"
          valueType="digit"
          rules={[
            {
              required: true,
            },
          ]}
          fieldProps={{
            width: '100%',
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
});

DefendEditForm.displayName = 'DefendEditForm';

const DefendEdit = (props) => {
  const { agreedPrescriptionIndicator, tree, value } = props;
  const ref = useRef<any>(null);
  const footer = (
    <div
      className="aspirin-table-footer-button"
      style={{ justifyContent: 'flex-end' }}
    >
      <ButtonAndKeyDown
        type="primary"
        methodKey="altKey"
        ordinaryKey="C"
        ghost
        onClick={props?.onCancel}
      >
        取消
      </ButtonAndKeyDown>
      <ButtonAndKeyDown
        type="primary"
        methodKey="altKey"
        ordinaryKey="D"
        onClick={async () => {
          const save = await ref?.current?.onOk();
          if (save) {
            props?.onCancel();
            props?.onReload();
          }
        }}
      >
        确定
      </ButtonAndKeyDown>
    </div>
  );

  return (
    <Modal {...props} title="新建医嘱模版" footer={footer} destroyOnClose>
      <DefendEditForm
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        value={value}
        tree={tree}
        ref={ref}
      />
    </Modal>
  );
};

export default DefendEdit;
