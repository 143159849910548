import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_getListInpPreDischargePatientWithPatientVoByInpVisitId_7bd55c:
  {
    method: 'post',
    url: '/api/clinic-inp-adt/get-list-inp-pre-discharge-patient-with-patient-vo-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreDischargePatientWithPatientVoClinicInpAdtEntranceWebVo[],
    ) => data,
  },
});
