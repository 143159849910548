import * as ExcelJS from 'exceljs';

export const getExportEntityForExcel = async (
  value: {
    staffNumber: string;
    staffName: string;
    departmentName: string;
    signatureImage?: string;
  }[],
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('签名留样');
  worksheet.columns = [
    { header: '工号', key: 'staffNumber', width: 20 },
    { header: '姓名', key: 'staffName', width: 20 },
    { header: '科室', key: 'departmentName', width: 20 },
    { header: '留样图片', key: 'signatureImage', width: 30 },
    { header: '留样状态', key: 'signatureStatus', width: 20 },
  ];
  // 设置标题行的行高
  worksheet.getRow(1).height = 20;
  value.forEach((item, index) => {
    const rowIndex = index + 2; // 行索引从2开始，因为第一行是标题行

    // 添加数据
    const row = worksheet.addRow({
      staffNumber: item.staffNumber,
      staffName: item.staffName,
      departmentName: item.departmentName,
      signatureStatus: item.signatureImage ? '已留样' : '未留样',
    });

    // 调整行高
    row.height = 30;

    // 添加图片
    if (item.signatureImage) {
      const imageId = workbook.addImage({
        base64: item.signatureImage, // 使用记录中的图片数据
        extension: 'png',
      });
      // worksheet.addImage(imageId, `D${rowIndex}:D${rowIndex}`);
      worksheet.addImage(imageId, {
        tl: { col: 3, row: rowIndex - 1 },
        ext: { width: 100, height: 60 },
        editAs: 'oneCell',
      });
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();

  // 使用一个适合浏览器的方法来保存这个ArrayBuffer
  // 这里是一个示例，你可能需要根据你的实际情况来修改这部分代码
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  // test link jira 1
  a.download = '签名留样统计.xlsx';
  a.click();
};
