import {
  Section,
  VSPromiseTable,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  Button,
  message,
} from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import React, { useCallback } from 'react';

import { getScrollY } from '@/utils';
import Icon from '@/module/Icon';
import routes from '@/router';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import FunctionEdit from './edit';

const FunctionTable = (props) => {
  const getAllCode = (data) => {
    const codeList = [];
    const recursion = (item) => {
      if (item?.children && item?.children?.length > 0) {
        item?.children?.forEach((cItem) => {
          recursion(cItem);
        });
      }
      if (item?.code) {
        codeList.push(item?.code);
      }
    };
    recursion(data);
    return codeList;
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.ApplicationFunctionDtoController_getAllByFunctionQto_233f12?.(
        {
          qto: {
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            functionClassIn: getAllCode(params.extra),
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <VSPromiseTable
      id="FunctionListTable"
      scroll={{
        y: 0,
      }}
      className="table_overflow_400 aspirin-table aspirin-table-no-background-box"
      searchConfig={{
        labelWidth: 90,
        collapsed: false,
        collapseRender: false,
        searchButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        resetButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        optionRender: (...arr) => {
          const [rest, search, dom] = arr;

          return (
            <SearchContainer.Header title="功能查询">
              <div
                onClick={() => {
                  // 刷新tree
                  vsf?.refs?.functionTree?.handleReload();
                  // 清空treeSelect
                  props?.handelResert();
                  // 重置table 搜索
                  vsf?.refs?.FunctionListTable?.getSearchForm()?.resetFields();
                }}
              >
                {dom?.[0]}
              </div>
              <div>{dom?.[1]}</div>
            </SearchContainer.Header>
          );
        },
      }}
      searchFormRender={(item, label) => {
        return (
          <SearchContainer mode="inline" formPadding="0 170px 0 100px">
            {label}
          </SearchContainer>
        );
      }}
      form={{
        labelAlign: 'left ',
      }}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
      }}
      pagination={false}
      vsid="04228"
      onFetch={onFetch}
      onAdd={async (params) => {
        console.log('新增');
        const res =
          await vsf?.services?.ApplicationFunctionBOController_createApplicationFunction_cd0986?.(
            {
              btoParam: {
                ...({
                  ...params,
                  functionPermissionBtoList:
                    params.functionPermissionBtoList?.map((item) => {
                      delete item.id;
                      return item;
                    }),
                } ?? {}),
              },
            },
          );
        return res?.data;
      }}
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.ApplicationFunctionBOController_updateApplicationFunction_f37290?.(
            {
              btoParam: {
                ...params,
                functionPermissionBtoList:
                  params.functionPermissionBtoList?.map((item) => {
                    if (item.id) delete item.id;
                    return item;
                  }),
                functionVsPermissionDtoList: undefined,
              },
              extApiParam: params?.extApiParam,
            },
          );
        return res?.data;
      }}
      onRemove={async (params) => {
        const res =
          await vsf?.services?.ApplicationFunctionBOController_deleteFunction_d7c670?.(
            { btoParam: { ...(params ?? {}) } },
          );
        return res?.code === 200;
      }}
      addLinkPage={{
        linkPage: () => <FunctionEdit />,
        modalProps: {
          title: '新增功能模块',
          className: 'aspirin-linkpage',
          width: '60rem',
          bodyStyle: {
            // height: 752,
            // overflowY: 'scroll',
            overflowX: 'hidden',
          },
          className: 'modalHeight752',
        },
        onLinkPageValidate: async () => {
          try {
            const res =
              await vsf.refs.FunctionForm?.validateFieldsReturnFormatValue();
            return res;
          } catch (e) {
            return false;
          }
        },
      }}
      updateLinkPage={{
        linkPage: () => <FunctionEdit />,
        modalProps: {
          title: '编辑功能模块',
          className: 'aspirin-linkpage',
          width: '60rem',
          bodyStyle: {
            // height: 752,
            // overflowY: 'scroll',
            overflowX: 'hidden',
          },
          className: 'modalHeight752',
          footerRender: (
            dom,
            { okButtonProps, cancelButtonProps },
            value,
            row,
          ) => {
            return (
              <>
                <Button
                  style={{
                    color: '#5D6268',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                  }}
                  {...cancelButtonProps}
                >
                  取消
                </Button>
                <Button
                  onClick={async () => {
                    Tip({
                      content: `是否删除：${row?.name}`,
                      onOk: async () => {
                        const res =
                          await vsf?.services?.ApplicationFunctionBOController_deleteFunction_d7c670?.(
                            { btoParam: { ...(row ?? {}) } },
                          );
                        if (res.code === 200) {
                          vsf?.refs.FunctionListTable.reload();
                          message.open({
                            type: 'success',
                            content: `删除成功`,
                          });
                          cancelButtonProps.onClick();
                        }
                        return res?.data;
                      },
                      cancelText: '取消',
                    });
                  }}
                  style={{
                    color: 'red',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                  }}
                >
                  删除
                </Button>
                <Button {...okButtonProps}>保存</Button>
              </>
            );
          },
        },
        onLinkPageValidate: async () => {
          try {
            const res =
              await vsf.refs.FunctionForm?.validateFieldsReturnFormatValue();
            return res;
          } catch (e) {
            return false;
          }
        },
      }}
      extraParams={props.value}
    >
      <VSTableColumn
        dataIndex={['name']}
        title="名称"
        valueType="text"
        search
        columnKey={'nameLike'}
        formItemProps={{
          rules: [
            { message: '名称长度不合法', type: 'string', min: 0, max: 40 },
          ],
        }}
        fieldProps={{ className: 'width200' }}
      />
      <VSTableColumn
        dataIndex={['source']}
        title="启动地址"
        valueType="text"
        // dataSource={{routes}}

        formItemProps={{
          rules: [
            { message: '来源长度不合法', type: 'string', min: 0, max: 200 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['note']}
        title="备注"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '备注长度不合法', type: 'string', min: 0, max: 1024 },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title="功能列表"
      >
        <VSTableAddition
          children="添加"
          position="top"
          size="large"
          className="icon16"
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(FunctionTable);
