import { getScrollY } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableAddition,
  Button,
  VSTable,
  Radio,
  withRef,
  VSPromiseTable,
} from '@vs/vsf-kit';
import React from 'react';
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import Icon from '@/module/cpoe/Icon';

const PatientMergeDetail = React.forwardRef((props, ref) => {
  const [retainIndicator, setRetainIndicator] = useState();
  const [patientRetainDisplay, setPatientRetainDisplay] = useState();
  const [selectIds, setSelectIds] = useState();

  useImperativeHandle(ref, () => {
    return {
      getRetainedId: () => Promise.resolve(retainIndicator),
      getPatientRetainDisplay: () => Promise.resolve(patientRetainDisplay),
      setSelectRows: (_selectIds) => {
        setSelectIds(_selectIds);
      },
    };
  });

  /**
   * convertGetPatientsByIdsRes2VSTablePatientMergeDetail
   * @param {any} input
   * @returns {any}
   */
  const convertGetPatientsByIdsRes2VSTablePatientMergeDetail = (input) => {
    console.debug(input);
    const _ = window._ ?? {};
    const customResult = input.map((item) => ({
      id: _.get(item, 'id'),
      displayId: _.get(item, 'displayId'),
      name: _.get(item, 'name'),
      gender: _.get(item, 'gender'),
      age: _.get(item, 'age'),
      cellphone: _.get(item, 'cellphone'),
      birthAddress:
        (_.get(item, 'birthAddress.provice') ?? '') +
        (_.get(item, 'birthAddress.city') ?? '') +
        (_.get(item, 'birthAddress.county') ?? ''),
      idNumber: _.get(item, 'idNumber'),
    }));
    console.debug(customResult);
    return customResult;
  };

  const handelRadioClick = async (record) => {
    setRetainIndicator(record?.id);
    setPatientRetainDisplay(record?.displayId);
    await vsf.refs.VSTablePatientMergeDetail?.getValues()?.forEach((item) => {
      vsf.refs.VSTablePatientMergeDetail?.update({
        ...item,
        retainIndicator: record?.id === item?.id,
      });
    });
  };

  return (
    <>
      <VSTable
        pagination={false}
        vsid="54012"
        scroll={{
          x: 890,
          y: getScrollY(430),
        }}
        // rowKey={'rowKey'}
        id="VSTablePatientMergeDetail"
        // onFetch={async (params) => {
        //   // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
        //   // Tips: 以下是你选择的请求函数
        //   const res =
        //     await vsf.services?.PatientMainController_getPatientsByIds_265269?.(
        //       {
        //         // TODO: 请检查以下这部分传参是否符合需求
        //         id: props.patientIds,
        //       },
        //     ); // TODO: 你需要自己补充入参input来源及出参output用法
        //   const customResult =
        //     convertGetPatientsByIdsRes2VSTablePatientMergeDetail(
        //       res?.data,
        //     ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
        //   return {
        //     data: customResult ?? res?.data ?? [],
        //     total: res?.data?.count ?? res?.data?.length ?? 0,
        //   };
        // }}
        rowSelection={{
          selectedRows: selectIds,
          selectType: 'multiple',
          getCheckboxProps: (record) => {
            return {
              style: {
                visibility: record?.isMerged ? 'hidden' : 'visible',
              },
            };
          },
          onSelectedRowsChange: (rows) => {
            setSelectIds(rows);
          },
        }}
        className="table_overflow_384"
      >
        <VSTableColumn
          width={120}
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  display: 'flex',
                  background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                  color: record?.isMerged ? '#85898D' : '',
                }}
              >
                {dom}
                {record?.patientMergeWithProfileDtoList?.length ? (
                  <div style={{ color: '#3F76C8' }}>
                    <Icon type="icon-chengzulianjie" size={16} />
                  </div>
                ) : null}
              </div>
            );
          }}
        />

        <VSTableColumn
          ellipsis
          width={70}
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={70}
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          ellipsis
          width={70}
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={134}
          dataIndex={['cellphone']}
          title="手机号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={134}
          dataIndex={['birthAddress']}
          title="出生地"
          valueType="address"
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={210}
          dataIndex={['idNumber']}
          title="身份证号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={60}
          dataIndex={['retainIndicator']}
          title="保留"
          valueType="radio"
          fieldProps={{}}
          render={(_, record) => {
            return (
              <div
                style={{
                  background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                  color: record?.isMerged ? '#85898D' : '',
                }}
              >
                <Radio
                  disabled={record?.isMerged}
                  checked={record?.retainIndicator}
                  key={record?.id}
                  onClick={() => handelRadioClick(record)}
                />
              </div>
            );
          }}
        />
      </VSTable>

      {/* <Section title="">
        <Button
          children="取消合并"
          shape="round"
          onClick={async (params) => {
            console.debug('取消合并点击'); //
            // Tips: 以下是你选择的请求函数
            const res =
              await vsf.services?.PatientExtensionController_deletePatientMerge_ca4500?.(
                {
                  // TODO: 请检查以下这部分传参是否符合需求
                  btoParam: params,
                  extApiParam: params?.extApiParam,
                },
              ); // TODO: 你可能需要整理返回数据格式
            return res?.data;
          }}
        />

        <Button
          children="合并"
          shape="round"
          onClick={async (params) => {
            console.debug('合并点击');
            const selectedRows =
              vsf.refs.VSTablePatientMergeDetail.getSelectedRowValues();
            console.debug(selectedRows);
          }}
        />
      </Section> */}
    </>
  );
});
PatientMergeDetail.displayName = 'PatientMergeDetail';
export default withRef()(PatientMergeDetail);
