import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';
import Block from '@/module/Block';
import React from 'react';

import ProfileListTable from './table';

import './index.less';

const DrugStorageProfile = (props) => {
  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            padding: '0',
            bgColor: '#fff',
          },
        ],
      }}
    >
      <Block
        style={{ padding: 0 }}
        className="drug_storage_profile_wapper common_lfc_style"
      >
        <ProfileListTable />
      </Block>
    </Container>
  );
};
export default definePage(DrugStorageProfile);
