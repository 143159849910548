import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-platform-dictionary-qto',
    parameterFomatter: (data?: {
      qto: PlatformDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlatformDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deletePlatformDictionary_16247a: {
    method: 'post',
    url: '/api/outp-register-define/delete-platform-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeletePlatformDictionaryBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlatformDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdatePlatformDictionary_f592fa: {
    method: 'post',
    url: '/api/outp-register-define/add-update-platform-dictionary',
    parameterFomatter: (data?: {
      btoParamList: AddUpdatePlatformDictionaryBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
