import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderExtensionController_getAllByQueryAllQto_c20981: {
    method: 'post',
    url: '/api/clinic-record-order-extension/get-all-by-query-all-qto',
    parameterFomatter: (data?: {
      qto: QueryAllQtoClinicRecordOrderExtensionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformLabelSettingMasterWithItemVoClinicRecordOrderExtensionEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderExtensionController_saveLabelSettingMaster_6a1c2d: {
    method: 'post',
    url: '/api/clinic-record-order-extension/save-label-setting-master',
    parameterFomatter: (data?: {
      eo: SaveLabelSettingMasterEoClinicRecordOrderExtensionPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
