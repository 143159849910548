import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Section, Todo } from '@vs/vsf-kit';
import React from 'react';
import Icon from "@/module/Icon"
import { TreeMenu } from '@/components';
const LabClassTree = (props) => {

  return (
    <TreeMenu
      id="LabClassTree"
      title="检验项目分类"
      treeProps={{
        dataSource: [
          {
            title: 'parent 1',
            key: '0-0',
            children: [
              {
                title: 'parent 1-0',
                key: '0-0-0',
                children: [
                  { title: 'aaa', key: '0-0-0-0' },
                  { title: 'bbb', key: '0-0-0-1' },
                ],
              },
            ],
          },
        ],
        defaultExpandAll: true,
      }}
      fieldNames={{ title: 'labClassName', key: 'id', children: 'children' }}
      dataRelationFieldNames={{ id: 'id', parentId: 'parentClassLabId' }}
      onSelect={props.onChange}
      loadData={async () => {
        const res =
          await vsf?.services?.LabController_getAllByLabClassQto_a6f1c8?.({
            qto: {
              stopIndicatorIs: false
            }
          });
        return res;
      }}
      row={{
        extraShow: 'select',
        expand: true,
        extra: (node) => {
          return (
            <div className="aspirin-tree-button">
              <Icon
                type="icon-bianji"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onUpdate(node)
                }}
                size={20}
              />
              <Icon
                type="icon-tingyong"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onStop(node)
                }}
                size={20}
              />
            </div>
          )
        }
      }}
      headerRender={() => {
        return <Button onClick={() => props.onAdd({})}>新增</Button>
      }}
    />
  );
};
export default definePage(LabClassTree);
