import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Drawer,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSForm,
  VSFormDependency,
  Steps,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import MySteps from '@/pages/Index/components/step';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';
import HeaderInfo from '@/pages/Index/components/head_info';
import {
  convertSearchPrarms,
  convertGet,
  convertVSFormInpPreTransferApplyCreate2SaveInpPreTransferApplyReq,
  convertGetInpVisitInHospitalWithBedVoByIdRes2VSFormInpPreTransferApplyCreate,
  convertGetListInpPreTransferApplyToDepartmentVoByInpVisitIdRes2VSTableInpPreTransferApplyList,
  convertGetAllByInpTransferApplyByVisitIdAndStatusQtoRes2VSFormInpPreTransferApplyCreate,
} from './convert';
import Info from '@/pages/Index/cpoe/center/header/Info/in_info';
const formItemWidth = 360;
const formItemLabelWidth = 140;
const description = 'This is a description.';

const Index = (props) => {
  const { stores } = props;
  const { user } = stores;
  const { inVisitInfo, initKey } = user;

  // form详情
  const [detail, setDetail] = useState();
  // 就诊id
  const [inpVisitIds, setInpVisitId] = useState();
  // 信息栏
  const [informationBar, setInformationBar] = useState();
  // 转科/转病区
  const [type, setType] = useState('1');
  // 列表
  const [tableList, setTableList] = useState();
  //床卡列表
  const [cardList, setCardList] = useState();
  // 转科记录查到的id
  const [inpPreTransferApplyId, setInpPreTransferApplyId] = useState();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    storage.set('treatPatientInfo', {});
  }, []);

  useEffect(() => {
    getDetail();
  }, [initKey, inVisitInfo]);

  const onChangeType = (v) => {
    setType(v);
  };

  // 转科form
  const getDetail = useCallback(async () => {
    if (!inVisitInfo?.inpVisitId) return;

    setInpPreTransferApplyId(null);
    // 查询有没有转科记录
    const inpTransferApplyRes =
      await vsf.services?.ClinicInpAdtController_getAllByInpTransferApplyByVisitIdAndStatusQto_d0e747?.(
        {
          qto: {
            from: 0,
            size: 1,
            // inpVisitIdIs: 2101,
            inpVisitIdIs: inVisitInfo?.inpVisitId,
            statusIn: ['WAIT_AUDIT', 'AUDIT_SUCCESS'],
          },
        },
      );
    const inpTransferApplyResData = inpTransferApplyRes?.data?.[0];
    // 有转科记录
    let formData = {};
    if (inpTransferApplyResData?.id) {
      formData =
        convertGetAllByInpTransferApplyByVisitIdAndStatusQtoRes2VSFormInpPreTransferApplyCreate(
          inpTransferApplyResData,
        );

      vsf.refs.vSFormInpPreTransferApplyCreate?.setFieldsValue(formData);
      setDetail(formData);
      setInpPreTransferApplyId(inpTransferApplyResData?.id);
    } else {
      // 没有转科记录
      const inpVisitRes =
        await vsf.services?.ClinicInpVisitController_getInpVisitInHospitalWithBedVoById_0824de?.(
          // 2101
          {
            // id: inpVisitIds,
            id: inVisitInfo?.inpVisitId,
          },
        );
      formData =
        convertGetInpVisitInHospitalWithBedVoByIdRes2VSFormInpPreTransferApplyCreate(
          inpVisitRes,
        );
      vsf.refs.vSFormInpPreTransferApplyCreate?.setFieldsValue(formData);
      setDetail(formData);
    }
    return formData;
  }, [inVisitInfo]);

  // 创建/保存
  const saveInpPreTransferApply = async () => {
    const _params = vsf?.refs?.vSFormInpPreTransferApplyCreate?.getFieldValue();

    const reqParams =
      convertVSFormInpPreTransferApplyCreate2SaveInpPreTransferApplyReq(
        _params,
      );

    const res =
      await vsf.services?.ClinicInpAdtController_saveInpPreTransferApply_0c4ee0?.(
        reqParams,
      );
    if (res?.code == 200) {
      getDetail();
    }
    return res?.code;
  };
  // 保存
  const handelSave = () => {
    const _toWardName =
      vsf?.refs?.vSFormInpPreTransferApplyCreate?.getFieldsValue()?.toWard
        ?.departmentName;
    Modal.confirm({
      title: '提示',
      icon: null,
      centered: true,
      content: (
        <div>
          <Icon style={{ marginRight: 4 }} type="icon-a-tishichenggong" />
          是否确认转至{_toWardName}？
        </div>
      ),
      onOk: async () => {
        const res = await saveInpPreTransferApply();

        if (res == 200) {
          getDetail();
          Modal.confirm({
            title: '提示',
            centered: true,
            icon: null,
            content: (
              <div>
                <Icon
                  style={{ marginRight: 4, color: '#30a313' }}
                  color="#30a313"
                  type="icon-Frame2"
                />
                该病人于{dayjs().format('YYYY-MM-DD HH:mm:ss')}
                转科，请及时书写转科记录！
              </div>
            ),
          });
        }
      },
    });
  };
  // 删除
  const handelDelete = () => {
    Modal.confirm({
      title: '提示',
      icon: null,
      centered: true,
      content: '确认删除吗？',
      onOk: async () => {
        const res =
          await vsf.services?.ClinicInpAdtController_deleteInpPreTransferApply_75b615?.(
            {
              btoParam: { id: inpPreTransferApplyId },
              extApiParam: {},
            },
          );
        if (res?.code == 200) {
          getDetail();
          setInpPreTransferApplyId(null);
          message.success('成功删除转科申请');
        }
      },
    });
    // message.info('转科申请已不存在，请刷新界面！');
    // message.info('转科医嘱核对，请联系护士回退医嘱！');
    // message.info('患者不是在科状态，不能删除转科申请！');
  };
  // 去向院区
  const getToBranchInstitution = useCallback(async (params) => {
    const res =
      await vsf.services?.OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 2000,
            // branchInstitutionIdIs: params?.toBranchInstitution?.id,
            stopIndicatorIs: false,
            clinicAttributeIn: ['2'],
          },
        },
      ); // TODO: 你可能需要整理返回数据格式
    return res?.data;
  }, []);

  return (
    <div className="doctor_station_transfer">
      {/* <HeaderInfo patientInfo={informationBar}></HeaderInfo> */}
      <div style={{ marginBottom: 16 }}>{<Info></Info>}</div>
      <div className="doctor_station_transfer_content">
        <div className="doctor_station_transfer_content_title">
          <div className="doctor_station_transfer_content_title_tag"></div>
          <span>患者信息</span>
          <div
            style={{ margin: '0 auto 15px' }}
            className="doctor_station_transfer_content_title_steps"
          >
            <MySteps
              dataSource={[
                { title: '转科申请', value: 'WAIT_AUDIT' },
                { title: '申请通过', value: 'AUDIT_SUCCESS' },
                { title: '护士转出', value: 'FINISH' },
              ]}
              current={detail?.status}
            ></MySteps>
          </div>
          <div
            style={{ visibility: 'hidden' }}
            className="doctor_station_transfer_content_title_tag"
          ></div>
          <span style={{ visibility: 'hidden' }}> 患者信息</span>
        </div>

        <div className="doctor_station_transfer_content_form hideScroll">
          {/* <div className="doctor_station_transfer_content_form_content"> */}
          <VSForm
            vsid="37481"
            id="vSFormInpPreTransferApplyCreate"
            // disabled={
            //   tableList?.length > 0 || tableList?.[0]?.status === 'WAIT_AUDIT'
            // }
            // onFetch={getDetail}
            disabled={inpPreTransferApplyId}
            labelAlign="left"
            className="doctor_station_transfer_content_form_vsform"
            labelWrap
          >
            <VSFormLayout
              labelWidth={formItemLabelWidth}
              key="0"
              gutter={130}
              columnCount={2}
            >
              <VSFormItem
                name={['patientDisplayId']}
                label="患者ID"
                valueType="text"
                rules={[
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{
                  disabled: true,
                  className: 'width360',
                }}
              />
              <VSFormItem
                name={['bedLabel']}
                label="床号"
                fieldProps={{
                  disabled: true,
                  className: 'width360',
                }}
              />
              <VSFormItem
                name={['patientName']}
                label="姓名"
                valueType="text"
                rules={[
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={{
                  disabled: true,
                  className: 'width360',
                }}
              />
              <VSFormItem
                name={['patientGender']}
                label="性别"
                valueType="select"
                fieldProps={{
                  disabled: true,
                  className: 'width360',
                }}
                dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
              />
              <VSFormDependency name={['institutionId']}>
                {(data) => {
                  return (
                    <VSFormItem
                      name={['toBranchInstitution']}
                      label="去向院区"
                      valueType="select"
                      required
                      fieldProps={{
                        disabled: true,
                        showSearch: true,
                        labelWidth: formItemLabelWidth,
                        className: 'width360',
                        defaultValue: {
                          label: 'institutionName',
                          value: 'id',
                        },
                        fieldNames: { label: 'institutionName', value: 'id' },
                        dataSource: async (params) => {
                          // Tips: 以下是你选择的请求函数
                          if (!params?.institutionId) return;
                          const res =
                            await vsf.services?.MedicalInstitutionDtoController_getInstitutionById_5594f2?.(
                              {
                                // TODO: 请检查以下这部分传参是否符合需求
                                id: params?.institutionId,
                              },
                            ); // TODO: 你可能需要整理返回数据格式
                          return res?.data?.branchInstitutionList;
                        },
                      }}
                      dataSourceParams={data}
                    />
                  );
                }}
              </VSFormDependency>

              <VSFormDependency name={['toBranchInstitution']}>
                {(data) => {
                  return (
                    <VSFormItem
                      name={['toDepartment']}
                      label="去向科室"
                      valueType="select"
                      required
                      fieldProps={{
                        className: 'width360',
                        fieldNames: { label: 'departmentName', value: 'id' },
                        dataSource: async (params) => {
                          var qtoParams = {
                            from: params.pagination?.from ?? 0,
                            size: params.pagination?.size ?? 200,
                            branchInstitutionIdIs:
                              params?.toBranchInstitution?.id,
                            stopIndicatorIs: false,
                            stopIndicatorIsNullOrNot: true,
                            clinicAttributeIs: '0',
                          };
                          if (params?.departmentNameLike) {
                            qtoParams.departmentNameLike =
                              params?.departmentNameLike;
                          }
                          const res =
                            await vsf.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                              { qto: qtoParams },
                            );
                          return res?.data;
                        },
                        searchKey: 'departmentNameLike',
                        showSearch: true,
                      }}
                      dataSourceParams={data}
                    />
                  );
                }}
              </VSFormDependency>
              <VSFormDependency name={['toBranchInstitution']}>
                {(data) => {
                  return (
                    <VSFormItem
                      name={['toWard']}
                      label="去向病区"
                      valueType="select"
                      required
                      fieldProps={{
                        className: 'width360',
                        fieldNames: { label: 'departmentName', value: 'id' },
                        dataSource: async (params) => {
                          var qtoParams = {
                            from: params.pagination?.from ?? 0,
                            size: params.pagination?.size ?? 200,
                            branchInstitutionIdIs:
                              params?.toBranchInstitution?.id,
                            stopIndicatorIs: false,
                            stopIndicatorIsNullOrNot: true,
                            clinicAttributeIs: '2',
                          };
                          if (params?.departmentNameLike) {
                            qtoParams.departmentNameLike =
                              params?.departmentNameLike;
                          }
                          const res =
                            await vsf.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                              { qto: qtoParams },
                            );
                          return res?.data;
                        },
                        searchKey: 'departmentNameLike',
                        showSearch: true,
                      }}
                      dataSourceParams={data}
                    />
                  );
                }}
              </VSFormDependency>
            </VSFormLayout>
            <VSFormLayout
              labelWidth={formItemLabelWidth}
              key="1"
              gutter={240}
              columnCount={1}
            >
              <VSFormItem
                name={['memo']}
                label="与转入科室沟通结果"
                valueType="textarea"
                fieldProps={{
                  className: 'width1100',
                }}
              />
            </VSFormLayout>
            <VSFormLayout
              labelWidth={formItemLabelWidth}
              key="2"
              gutter={240}
              columnCount={2}
            >
              <VSFormItem
                name={['classCWithoutSignature']}
                label="丙类未签名"
                valueType="digit"
                fieldProps={{
                  className: 'width360',
                  defaultValue: 0,
                  disabled: true,
                }}
              />
            </VSFormLayout>
          </VSForm>
          {/* </div> */}
        </div>
      </div>
      <div className="doctor_station_transfer_bottom">
        <Button
          className="doctor_station_transfer_bottom_delete"
          onClick={handelDelete}
          style={{ color: '#3276E8', border: '1px solid #3276E8' }}
          disabled={!inpPreTransferApplyId}
        >
          删除
        </Button>
        <Button
          type="primary"
          className="doctor_station_transfer_bottom_save"
          onClick={handelSave}
          disabled={inpPreTransferApplyId || !inVisitInfo?.inpVisitId}
          // disabled={inpPreTransferApplyId}
        >
          保存
        </Button>
      </div>
    </div>
  );
};

export default definePage(Index);
