// left table
export const convertGetChiefComplaintVsJudgeRes2ChiefComplaintJudgeDictionarVoyRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.chiefComplaintJudgeName = _.get(
          d,
          'chiefComplaintJudge.chiefComplaintJudgeName',
        );
        obj.patientType = _.get(d, 'chiefComplaintJudge.patientType');
        obj.diseaseLevelName = _.get(
          d,
          'chiefComplaintJudge.diseaseLevel.diseaseLevelName',
        );
        obj.colorCode = _.get(d, 'chiefComplaintJudge.diseaseLevel.colorCode');
        return obj;
      }) ?? []),
    );
    return output;
  };
// right table
export const convertGetJudgeByChiefComplaintRes2ChiefComplaintJudgeCheckVoRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.chiefVsJudgeId = _.get(d, 'chiefVsJudgeId');
      obj.chiefComplaintJudgeName = _.get(d, 'chiefComplaintJudgeName');
      obj.patientType = _.get(d, 'patientType');
      obj.checkStatus = _.get(d, 'checkStatus'); // TODO 请确认下行字段是否符合逻辑
      obj.selected = _.get(d, 'checkStatus'); // TODO 请确认下行字段是否符合逻辑
      obj.colorCode = _.get(d, 'diseaseLevel.colorCode'); // TODO 请确认下行字段是否符合逻辑
      obj.diseaseLevelName = _.get(d, 'diseaseLevel.diseaseLevelName');
      obj.id = _.get(d, 'id');
      obj.inputCode = _.get(d, 'inputCode');
      return obj;
    }) ?? []),
  );
  return output;
};
