import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_getListBedGroupVoByWardId_532946: {
    method: 'post',
    url: '/api/clinic-bed/get-list-bed-group-vo-by-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (_, __, data?: BedGroupVoClinicBedEntranceWebVo[]) =>
      data,
  },
});
