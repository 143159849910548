import '../index.less';

import vsf from '@vs/vsf-boot';
import { Badge, Button, Modal, VSTable, VSTableColumn } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import React from 'react';

import { getScrollY } from '@/utils';

import { DosageUnit } from '../../../dosage';
import Flex from '../../../flex';
import { useGroupCheck, useWithResolvers } from '../../../func/hooks';
import { UsageUnit } from '../../../usage';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import { orderClassDataSource, repeatIndicatorDataSource } from './datasource';

const LagTimeOrder = forwardRef((props, ref) => {
  const [value, setValue] = useGetState([]);
  const [state, setState] = useState([]);
  const [open, setOpen] = useState(false);
  const tableRef = useRef(null);
  const {
    common: { systemParameter },
    user: { staffInfo },
  } = vsf?.stores || {};
  const { promise, resolve } = useWithResolvers();
  const Order_Lose_Submit_After_Hour = systemParameter?.find(
    (item) => item?.name === 'Order_Lose_Submit_After_Hour',
  );
  useImperativeHandle(ref, () => ({
    onOpen: (value) => {
      // 医嘱列表中医嘱状态为0-开立未提交 && ( SYSTEMDATA -ORDER.CREATE_DATE_TIME  > 系统参数Order_Lose_Submit_After_Hour.value）&& （ORDER.DOCTOR_ID = 当前登录人）
      const list = value?.filter((item) => {
        const today = dayjs();
        const createDateTime = dayjs(
          item?.createDateTime,
          'YYYY-MM-DD HH:mm:ss',
        );
        return (
          ['OPEN', 'SAVE']?.includes(item?.orderStatus) &&
          dayjs?.(today).diff(createDateTime, 'hour') >
            Number(Order_Lose_Submit_After_Hour?.systemValue) &&
          item?.doctor?.id === staffInfo?.id
        );
      });
      if (list?.length) {
        setState(value);
        setValue(group?.current?.onSliced?.(list));
        setOpen(true);
        return promise;
      }
      return Promise.resolve?.([]);
    },
  }));

  const { element, getValue } = useGroupCheck(value);

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'],
        ['dripSpeed'],
        ['frequency'],
        ['useDescription'],
        ['performDays'],
      ],
      tip() {},
    }),
  );

  const onOk = () => {
    const list = getValue();
    const array: any = [...state];
    for (const item of list) {
      const index = state?.findIndex((int: any) => int?.id === item?.id);
      array?.splice?.(index, 1, {
        ...item,
        startPerformDateTime: dayjs().format('YYYY/MM/DD HH:mm:ss')?.valueOf(),
      });
    }
    resolve(array);
    setOpen(false);
  };

  const Footer = () => {
    return (
      <Flex justify="space-between" align="center">
        <Flex gap={10}>
          <span>
            已选中 <Badge count={getValue()?.length} showZero color="#69a1ff" />{' '}
            条
          </span>
          <span style={{ color: 'var(--red)' }}>
            请勾选需要提交的滞后医嘱，单击确定按钮，再单击保存按钮，最后单击发送按钮进行医嘱提交.
          </span>
        </Flex>
        <Flex gap={5}>
          <Button type="primary" onClick={onOk}>
            确定
          </Button>
          <Button
            onClick={() => {
              resolve(false);
              setOpen(false);
            }}
          >
            取消
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal
      title="滞后提交医嘱确认提示"
      open={open}
      width="70%"
      footer={<Footer />}
      onCancel={() => {
        resolve(false);
        setOpen(false);
      }}
    >
      <VSTable
        scroll={{
          y: getScrollY(260),
        }}
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        bordered
        value={value}
        pagination={false}
      >
        <VSTableColumn {...(element as any)} />
        <VSTableColumn
          title="开始时间"
          dataIndex={['startPerformDateTime']}
          valueType="aspirinDateTimePicker"
          fieldProps={{
            format: 'YYYY/MM/DD HH:mm:ss',
            previewFormat: 'MM/DD HH:mm',
            valueType: 'timestamp',
          }}
          width="100px"
          render={(...[, record]) => {
            const { startPerformDateTime } = record || {};
            return (
              <Flex justify="center">
                {startPerformDateTime
                  ? dayjs(startPerformDateTime).format('MM/DD HH:mm')
                  : ''}
              </Flex>
            );
          }}
        />
        <VSTableColumn
          title="长/临"
          dataIndex={['repeatIndicator']}
          valueType="select"
          dataSource={repeatIndicatorDataSource}
          width="50px"
          render={(...[, record]) => {
            return (
              <Flex justify="center">
                {
                  repeatIndicatorDataSource?.find(
                    (item) => item?.value === !!record?.repeatIndicator,
                  )?.label
                }
              </Flex>
            );
          }}
        />
        <VSTableColumn
          title="类别"
          dataIndex={['orderClass']}
          valueType="select"
          dataSource={orderClassDataSource}
          preview
          width="45px"
        />
        <VSTableColumn
          title="医嘱"
          dataIndex={['orderText']}
          valueType="text"
          render={(...[, record]) => {
            return (
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                {record?.orderText}
              </div>
            );
          }}
          width="286px"
          className="aspirin-table-order-cell"
        />
        <VSTableColumn
          title="剂量"
          dataIndex={['dosage']}
          valueType="dosage"
          width="70px"
          render={(...[, record]) => <DosageUnit value={record?.dosage} />}
        />
        <VSTableColumn
          title="用量"
          dataIndex={['usage']}
          valueType="usage"
          width="70px"
          render={(...[, record]) => <UsageUnit value={record?.usage} />}
        />
        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
          width={100}
        />
        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          valueType="select"
          width={100}
          dataSource={vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
            (item) => {
              return {
                ...item,
                ...JSON?.parse(item?.extensionJson ?? '{}'),
              };
            },
          )}
          render={(...[, record]) => {
            return (
              record?.frequency?.frequency ?? record?.frequency?.label ?? ''
            );
          }}
          fieldNames={{
            label: 'frequency',
            value: 'frequency',
          }}
        />
        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          width={100}
        />

        <VSTableColumn
          dataIndex={['totalAmount']}
          title="数量"
          valueType="inputUnit"
          width={70}
          render={(...[, record]) => {
            const { orderClass } = record || {};
            if (orderClass === 'DRUG') {
              return record?.drugOrder?.prescriptionIndicator ||
                record?.drugOrder?.prescriptionId ? (
                <UsageUnit value={record?.drugOrder?.totalAmount} />
              ) : (
                ''
              );
            } else {
              return Object?.keys(
                record?.drugOrder?.totalAmount ?? {},
              )?.includes('value')
                ? Object?.values(record?.drugOrder?.totalAmount ?? {})
                : record?.drugOrder?.totalAmount;
            }
          }}
        />
        <VSTableColumn
          dataIndex={['duration']}
          title="持续时间"
          valueType="select"
          width={100}
          render={(...arr) => {
            const [, record] = arr;
            const { disposalOrder } = record || {};

            return disposalOrder?.duration
              ? `${disposalOrder?.duration}${disposalOrder?.durationUnit}`
              : '';
          }}
        />
      </VSTable>
    </Modal>
  );
});

LagTimeOrder.displayName = 'LagTimeOrder';

export default LagTimeOrder;
