import { message as Message } from '@vs/vsf-kit';
import { useLockFn } from 'ahooks';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

type Code = {
  code: UseFetch['code'];
  [key: string]: any;
};

type UseFetch = {
  code?: number | boolean | string;
  fetch: (params?: any) => Promise<Code>;
  /**
   * @message 消息提示
   */
  message?:
    | {
        loading?: string;
        success?: string;
        error?: string;
      }
    | string;
  /**
   * 成功后并且 code 正确回调
   * @returns {void}
   */
  success?: (value?: any) => void;
  /**
   * 失败的回调
   */
  error?: (error: unknown) => void;
};

type StartFetch<T> = (params?: T) => Promise<T>;

export default function useFetch<T>(params: UseFetch): StartFetch<T> {
  const {
    code = 200,
    fetch: newly,
    message = {
      loading: '加载成功',
      success: '执行成功',
      error: '执行失败',
    },
    success,
    error,
  } = params;
  let time: any = null;
  const key = getUUID() ?? '';
  const fetch = useLockFn(newly);
  const startAsyncFetch = (value) => {
    return new Promise((resolve, reject) => {
      Message.open({
        key,
        type: 'loading',
        content:
          typeof message === 'string' ? message + '中' : message?.loading,
        duration: 0,
      });
      clearTimeout(time);
      time = setTimeout(async () => {
        try {
          const res = await fetch(value);
          if (res?.code === code) {
            const content =
              typeof message === 'string' ? message + '成功' : message?.success;
            content
              ? Message?.open({
                  key,
                  type: 'success',
                  content,
                  duration: 2,
                })
              : Message?.destroy();
            success?.(res);
          } else {
            const content =
              typeof message === 'string' ? message + '失败' : message?.error;
            content
              ? Message?.open({
                  key,
                  type: 'error',
                  content,
                  duration: 2,
                })
              : Message?.destroy();
          }
          resolve(res);
        } catch (e) {
          reject(e);
          error?.(e);
          Message?.destroy();
        }
      }, 500);
    });
  };
  return startAsyncFetch as StartFetch<T>;
}
