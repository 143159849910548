import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamController_getAllByExamClassItemClassQto_4b0f3f: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-item-class-qto',
    parameterFomatter: (data?: { qto: ExamClassItemClassQtoExamPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  ExamController_getExamVsDepartmentByExamClassId_86c409: {
    method: 'post',
    url: '/api/exam/get-exam-vs-department-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamVsDepartmentVoExamEntranceWebVo[]) =>
      data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
