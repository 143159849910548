import {
  compose,
  Divider,
  Form,
  FormItemProps,
  Section,
  SelectProps,
  useControllableState,
  VSFormLayout,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React, {
  Children,
  cloneElement,
  Fragment,
  useCallback,
  useRef,
} from 'react';

import { useFrequencyEffect } from '../func/hooks';

export type SelectFormValue = FormItemProps & {
  /** 自定义类型字段 */
  select: (number | string)[];
  form: {
    [key: string]: any;
  };
};

export type SelectFormProps = {
  /**
   * 默认值
   */
  defaultValue?: SelectFormValue;
  /**
   * 值
   */
  value?: SelectFormValue;
  /**
   * 值变化回调
   */
  onChange?: (value?: SelectFormValue) => void;
  dataSource: {
    label: string;
    value: string | number;
  }[];
  children: JSX.Element;
  /**
   * 开启选择全部选项默认开启
   */
  selectAll?: boolean;
  selectAllText?: string;
  selectLayoutProps: any;
  id: string;
};

const FormItem = (props) => {
  const { children, item, name, value, form, dataSource, ant } = props;
  const formKey = Array.isArray(name) ? name : [name];
  const childrenProps = {
    item,
    formKey: [...formKey, 'form', `form_${item}`],
    form,
    value: value.form && value?.form[`form_${item}`],
  };

  // useFrequencyEffect(
  //   () => {
  //     props?.onChange?.(value);
  //   },
  //   1,
  //   [value],
  //   'after',
  // );
  return (
    <Form.List name={['form', `form_${item}`]}>
      {() => (
        <Fragment>
          {Children.toArray(children).length !== 1
            ? children
            : cloneElement(children, childrenProps)}
          <Form.Item
            style={{ display: 'none' }}
            initialValue={dataSource?.[ant]?.label}
            name={['label']}
          />
          <Form.Item
            style={{ display: 'none' }}
            initialValue={item}
            name={['value']}
          />
        </Fragment>
      )}
    </Form.List>
  );
};

/**
 * 选择表单
 */
const SelectForm = (props: SelectFormProps) => {
  const {
    defaultValue,
    value: valueProp = { select: [], form: {} },
    onChange,
    dataSource,
    selectAll = true,
    selectAllText = '全选',
    selectLayoutProps,
    children,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const form = Form.useFormInstance();
  const select = useRef<any>(null);
  const SelectOption = useCallback(
    (props) => {
      const options = [...(dataSource ?? [])];
      if (selectAll && options?.length) {
        options.unshift({
          value: 'all',
          label: selectAllText,
        });
      }
      return (
        <Select
          mode="multiple"
          placeholder="请选择院区"
          options={options}
          value={props?.value}
          ref={select}
          onChange={(state) => {
            if (state?.includes('all')) {
              if (state.length - 1 === dataSource.length) {
                props?.onChange?.(undefined);
              } else {
                props?.onChange?.(options?.slice(1).map((item) => item.value));
              }
              select?.current?.blur?.();
            } else {
              props?.onChange?.(state);
              if (state?.length === dataSource?.length || state?.length === 0) {
                select?.current?.blur?.();
              }
            }
          }}
        />
      );
    },
    [dataSource, selectAll, selectAllText],
  );
  const name = Array.isArray(props?.id) ? props?.id : props?.id?.split('_');
  return (
    <Form.List name={name}>
      {() => {
        return (
          <>
            <VSFormLayout {...selectLayoutProps}>
              <Form.Item name="select" {...rest}>
                <SelectOption />
              </Form.Item>
            </VSFormLayout>
            {children &&
              value?.select?.map((item) => {
                const ant = dataSource?.findIndex((el) => el.value === item);
                return (
                  <Section key={item} title={dataSource?.[ant]?.label}>
                    <Divider />
                    <FormItem
                      {...{
                        children,
                        item,
                        name,
                        value,
                        form,
                        dataSource,
                        ant,
                        onChange: props?.onChange,
                      }}
                    />
                  </Section>
                );
              })}
          </>
        );
      }}
    </Form.List>
  );
};
SelectForm.displayName = 'SelectForm';

export default compose(
  withField<SelectFormValue>({
    name: 'SelectForm',
  }),
  withPreview<SelectFormProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(SelectForm) as typeof SelectForm;
/**
 * 由于这个组件的数据结构与众不同，需要这个函数转化初始值
 * @param param 数组, 唯一键 id 的层级
 * @returns 转化后符合组件的数据结构
 */
export const askSelectFormInitValue = ({ array, select }) => {
  const state = array?.map((item: any) => {
    const sel = select.reduce((accumulator: any, currentValue: any) => {
      return accumulator[currentValue];
    }, item);
    return {
      select: sel,
      form: [`form_${sel}`, item],
    };
  });

  return {
    select: state?.map((item: any) => item.select),
    form: Object.fromEntries(state?.map((item: any) => item.form) ?? []),
  };
};
