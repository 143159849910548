import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-order-drug-and-valid',
    parameterFomatter: (data?: {
      drugItemId: string;
      patientId: number;
      departmentId: string;
      staffId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo,
    ) => data,
  },
  ClinicRecordOrderDrugController_getStorageIds_82d8b5: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-storage-ids',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageDepartmentVoDrugStorageImportExportEntranceWebVoDrugexportmasterlistvo[],
    ) => data,
  },
  DrugDrugController_queryPagefallByDrugDictionaryDrugListQto_270f4c: {
    method: 'post',
    url: '/api/drug-drug/query-pagefall-by-drug-dictionary-drug-list-qto',
    parameterFomatter: (data?: {
      qto: DrugDictionaryDrugListQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
