import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getPatientVisitHistoryDetail_d961fc: {
    method: 'post',
    url: '/api/clinic-record/get-patient-visit-history-detail',
    parameterFomatter: (data?: {
      eo: QueryPatientVisitHistoryDetailEoClinicRecordPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientVisitHistoryDetailVoClinicRecordEntranceWebVo,
    ) => data,
  },
});
