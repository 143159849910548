import { Empty } from '@vs/vsf-kit';
import React, { memo } from 'react';

import Loading from '@/module/Loading';

type SuspenseProps = {
  /**
   * 是否 loading 中
   */
  loading: boolean;
  /**
   * loading 进行中展示的内容
   */
  fallback?: JSX.Element;
  /**
   * loading 结束后展示的内容
   */
  children: any;
  /**
   * 数据未空时
   */
  dataSource?: any;
  /**
   * 数据为空时
   */
  empty?: React.ReactNode;
};

/**
 * 通过补充实现 React 内置组件 Suspense 的不足
 * 最好搭配 useRequest 使用
 * @param props ;
 */
const AsyncSuspense = (props: SuspenseProps) => {
  const {
    loading = true,
    fallback = <Loading size="normal" />,
    children,
    dataSource = true,
    empty,
  } = props;
  const EmptyMemo: React.FC<{ description: string }> = (props) => {
    const { description } = props;
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 60 }}
        description={description}
      />
    );
  };

  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return loading ? (
    <div style={style}>{fallback}</div>
  ) : dataSource ? (
    children
  ) : (
    <div style={style}>
      {empty ? empty : <EmptyMemo description="暂无数据" />}
    </div>
  );
};

export default AsyncSuspense;
