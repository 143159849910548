import { createServices } from '@vs/vsf-boot';
export default createServices({
  TreatController_getTreatClassWithInstitutionById_552f69: {
    method: 'post',
    url: '/api/treat/get-treat-class-with-instution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClassWithInstitutionVoTreatEntranceWebVo,
    ) => data,
  },
  TreatController_updateTreatClassDictionary_c37450: {
    method: 'post',
    url: '/api/treat/update-treat-class-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateTreatClassDictionaryBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo) =>
      data,
  },
  TreatController_createTreatClassDictionary_f6601d: {
    method: 'post',
    url: '/api/treat/create-treat-class-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateTreatClassDictionaryBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo) =>
      data,
  },
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
});
