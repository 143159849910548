import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getPagedByPriceItemCurrentQto_22ee99: {
    method: 'post',
    url: '/api/price-list/get-paged-by-price-item-current-qto',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentQtoPriceListPersistQto;
      isIncludeDrugOrHerb: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemBaseVoPriceListEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
