import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugImportRecordCreateController_getDrugImportMasterWithDetail_19192c: {
    method: 'post',
    url: '/api/drug-storage-import/get-drug-import-master-with-detail',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugImportRecordCreateController_createOrUpdateDrugStorageImportRecord_be0cad:
    {
      method: 'post',
      url: '/api/drug-storage-import/create-or-update-drug-storage-import-record',
      parameterFomatter: (data?: {
        bto: CreateOrUpdateDrugStorageImportMasterWithDetailBtoDrugStorageImportExportServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  DrugImportRecordCreateController_drugImportRecordAccounting_4cc5e6: {
    method: 'post',
    url: '/api/drug-storage-import/drug-import-record-accounting',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugStorageImportMasterWithDetailBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugSupplyStatusEoEoDrugStorageImportExportPersistEo[],
    ) => data,
  },
  DrugImportRecordCreateController_getCandidateStorageList_1ef4f5: {
    method: 'post',
    url: '/api/drug-storage-import/get-candidate-storage-list',
    parameterFomatter: (data?: { clzCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: StorageForImportExportClassVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugController_getDrugListForImportStorageDetail_e9c707: {
    method: 'post',
    url: '/api/drug-drug/get-drug-list-for-import-storage-detail',
    parameterFomatter: (data?: { name: string }) => data,
    responseFormatter: (_, __, data?: DrugForDrugImportEoDrugDrugPersistEo[]) =>
      data,
  },
  DrugStorageImportExportController_getExportMasterWithDetailById_c96935: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-export-master-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugStorageController_getSubStorageList_cb7e95: {
    method: 'post',
    url: '/api/drug-storage/get-sub-storage-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SubDrugStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_updateDrugStockSupplyIndicator_4b7b0b: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stock-supply-indicator',
    parameterFomatter: (data?: {
      eo: UpdateDrugStockSupplyIndicatorEoDrugStoragePersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_getDrugSupplyLogList_4df404: {
    method: 'post',
    url: '/api/drug-storage/get-drug-supply-log-list',
    parameterFomatter: (data?: {
      qto: DrugSupplyLogQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugSupplyLogVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
