import './index.less';

import { TabPane, Tabs } from '@vs/vsf-kit';
import React from 'react';

import Empty from '@/module/Empty';
import CheckReport from '@/pages/Index/cpoe/center/left/checked';
import ExamineReport from '@/pages/Index/cpoe/center/left/examine';

// import CheckReport from './checkReport';
import DoctorAdvice from './doctorAdvice';
// import ExamineReport from './examineReport';
import Overview from './overview';
import PricingInformation from './pricing_information/index';

const Index = (props) => {
  const {
    detail,
    currentRecordItem,
    medicalHistoryDetailType,
    getistoryDetailType,
  } = props;
  console.log(props, 'currentRecord');

  const onChange = (v) => {
    if (v == '1' || v == '2' || v == '4') return;
    getistoryDetailType?.(v ?? 'OVERVIEW');
  };

  return (
    <div className="medical-detail">
      <Tabs
        onChange={onChange}
        // className="aspirin-tab aspirin-history-tab aspirin-history-tab-border"
        className="aspirin-history-tab-border aspirin-history-tab"
      >
        <TabPane tab="概览" key="OVERVIEW">
          <Overview detail={detail} />
        </TabPane>
        <TabPane tab="病例记录" key="1">
          {/* <div style={{ border: '1px solid red' }}> */}
          <Empty />
          {/* </div> */}
        </TabPane>
        <TabPane tab="病历文书" key="2">
          <Empty />
        </TabPane>
        <TabPane tab="医嘱" key="ORDER">
          <DoctorAdvice
            detail={detail}
            id={currentRecordItem?.id}
            visitRecordHistoryType={currentRecordItem?.visitRecordHistoryType}
          />
        </TabPane>
        <TabPane tab="计价信息" key="PRICE_INFO">
          <PricingInformation
            detail={detail}
            id={currentRecordItem?.id}
            visitRecordHistoryType={currentRecordItem?.visitRecordHistoryType}
          />
        </TabPane>
        <TabPane tab="检查报告" key="EXAM_REPORT">
          <CheckReport
            currentRecordItem={currentRecordItem}
            showSearch={false}
            // detail={detail}
            medicalHistoryDetailType={medicalHistoryDetailType}
          />
        </TabPane>
        <TabPane tab="检验报告" key="LAB_REPORT">
          <ExamineReport
            currentRecordItem={currentRecordItem}
            showSearch={false}
            medicalHistoryDetailType={medicalHistoryDetailType}
            // detail={detail}
          />
        </TabPane>
        <TabPane tab="外来资料" key="4">
          <Empty />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
