import vsf from '@vs/vsf-boot';
import { Form, Modal, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import { useGetState, useResetState } from 'ahooks';
import dayjs from 'dayjs';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import {
  useFetch,
  useOnChangeValue,
  useWithResolvers,
} from '@/pages/Index/components/func/hooks';

type DialogProps = {
  OrderRef: any;
  row: any;
};

enum method {
  double,
  cancel,
}

const Dialog = forwardRef((props: DialogProps, ref) => {
  const { OrderRef, row } = props;

  const [show, setShow, resetShow] = useResetState(false);
  const [performId, setPerformId] = useGetState(null);
  const [form] = Form.useForm();
  const [on] = useOnChangeValue<keyof typeof method>('cancel');

  const { promise, resolve } = useWithResolvers();
  // const onCancelSave = useFetch({
  //   fetch: async () => {
  //     await form?.validateFields();
  //     const value = form?.getFieldsValue();
  //     const cookies =
  //       await vsf?.services?.AccountController_staffLoginWithoutResponseCookies_5fd9d3(
  //         {
  //           ...value,
  //           username: value?.loginName,
  //         },
  //       );
  //     if (cookies?.code === 200) {
  //       return vsf.services?.ClinicRecordOrderPerformController_cancelSignature_171e54?.(
  //         {
  //           eo: {
  //             ...value,
  //             performId,
  //             userName: value?.loginName,
  //           },
  //         },
  //       );
  //     } else {
  //       return {
  //         code: 500,
  //       };
  //     }
  //   },
  //   message: '取消签名',
  //   success() {
  //     resetShow();
  //     row?.onChange({});
  //     OrderRef?.current?.reload?.();
  //   },
  // });

  const onDoubleSave = async () => {
    await form?.validateFields();
    const value = form?.getFieldsValue();
    const cookies =
      await vsf?.services?.AccountController_staffLoginWithoutResponseCookies_5fd9d3(
        {
          ...value,
          username: value?.loginName,
        },
      );
    if (cookies?.code === 200) {
      resolve?.({
        checkNurseId: cookies?.data,
        checkDateTime: dayjs()?.valueOf(),
      });
      setShow(false);
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen: (prams) => {
      const { value, loginName, performId } = prams;
      on?.onChange?.(value);
      setShow(true);
      setPerformId(performId);
      if (value === 'double') return promise;
      if (value === 'cancel') {
        setTimeout(() => {
          form?.setFieldValue('loginName', loginName);
        }, 500);
      }
    },
    onCancel: () => {
      resetShow?.();
    },
  }));

  return (
    <Modal
      open={show}
      title="用户签名"
      // onOk={on.value === 'cancel' ? onCancelSave : onDoubleSave}
      onCancel={resetShow}
      destroyOnClose
      okText="确定"
      cancelText="取消"
    >
      <VSForm labelAlign="left" form={form}>
        <VSFormLayout columnCount={1} labelWidth={70}>
          <VSFormItem
            valueType="text"
            name="loginName"
            fieldProps={{ disabled: on?.value === 'cancel' }}
            label="用户名"
          />
          <VSFormItem valueType="password" label="密码" name="password" />
        </VSFormLayout>
      </VSForm>
    </Modal>
  );
});

Dialog.displayName = 'Dialog';

export default Dialog;
