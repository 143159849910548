import { AuxiliaryRef } from '../../order/in_patient/assist/auxiliary';
import { Drug } from '../../order/out_patient';
import { Refs, TableRefs } from '../../order/out_patient/table';

type paramsType = {
  OLD_VALUE: any[];
  table: React.RefObject<Drug & TableRefs & Refs>;
  value: any[];
};
/**
 * 导入的成组组号需要重新赋值
 */
export default function askGroupNumber(params: paramsType) {
  const { OLD_VALUE = [], table, value: list = [] } = params;
  const value = [...list];
  const LAST_GROUP_NUMBER = OLD_VALUE.filter(
    (item: any) => item?.drugOrder?.groupNumber,
  )?.map((item: any) => item?.drugOrder?.groupNumber);
  const GROUP_NUMBER = Math?.max(
    ...(new Set(
      LAST_GROUP_NUMBER?.length ? LAST_GROUP_NUMBER : [0],
    ) as unknown as any[]),
  );
  const STATIC = table?.current?.onSliced(value ?? []) ?? [];
  const GROUP: any =
    STATIC.filter(
      (item) => item?.groupName === 'aspirin-order-text-group-last',
    ) ?? [];
  const GROUP_VALUE = GROUP?.map((item) => {
    return value?.filter((...[, index]) => item?.groupIndex?.includes(index));
  });
  GROUP.forEach((...[, index]) => {
    const start = GROUP?.[index]?.groupIndex?.[0];
    const len = GROUP?.[index]?.groupIndex?.length;
    const NEW_GROUP_VALUE = GROUP_VALUE?.[index]?.map((item, i) => {
      return {
        ...item,
        drugOrder: {
          ...item?.drugOrder,
          groupNumber: GROUP_NUMBER + (index + 1),
        },
        ...(i !== 0
          ? {
              performDepartment: GROUP_VALUE?.[index]?.[0]?.performDepartment,
            }
          : {}),
      };
    });
    value?.splice?.(start, len, ...NEW_GROUP_VALUE);
  });
  return value;
}
