import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_getClinicDetailForAppoint_724f16: {
    method: 'post',
    url: '/api/outp-register/get-clinic-detail-for-appoint',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterForAppointVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
  OutpRegisterController_getClinicRegisterFirstAvailableTimeDivision_afebf5: {
    method: 'post',
    url: '/api/outp-register/get-first-available-time-division',
    parameterFomatter: (data?: { clinicRegisterDetailId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDetailTimeDivisionVoOutpRegisterEntranceWebVo,
    ) => data,
  },
  OutpRegisterController_getPagedByClinicRegisterDetailTimeDivisionQto_b378a7: {
    method: 'post',
    url: '/api/outp-register/get-paged-by-clinic-register-detail-time-division-qto',
    parameterFomatter: (data?: {
      detailTimeDivisionQto: ClinicRegisterDetailTimeDivisionQtoOutpRegisterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicRegisterDetailTimeDivisionDtoOutpRegisterManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
