import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getAllByClinicItemQto_e2d337: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_createClinicItem_477596: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicItemBaseController_updateClinicItem_6d8e3f: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getAllByClinicItemExamNewQtoByClinicItemExamNewQto_a2cd1a:
    {
      method: 'post',
      url: '/api/clinic-item-base/get-all-by-clinic-item-exam-new-qto-by-clinic-item-exam-new-qto',
      parameterFomatter: (data?: {
        qto: ClinicItemExamNewQtoClinicItemBasePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ExamClinicItemDetailVoClinicItemBaseEntranceWebVo[],
      ) => data,
    },
  ClinicItemBaseController_getAllByClinicItemLabNewQtoByClinicItemLabNewQto_4ab6b4:
    {
      method: 'post',
      url: '/api/clinic-item-base/get-all-by-clinic-item-lab-new-qto-by-clinic-item-lab-new-qto',
      parameterFomatter: (data?: {
        qto: ClinicItemLabNewQtoClinicItemBasePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo[],
      ) => data,
    },
  ClinicItemBaseController_getAllByClinicItemNewQtoByClinicItemNewQto_4c6bdd: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-new-qto-by-clinic-item-new-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemNewQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_getAllByClinicItemTreatNewQtoByClinicItemTreatNewQto_93ae7e:
    {
      method: 'post',
      url: '/api/clinic-item-base/get-all-by-clinic-item-treat-new-qto-by-clinic-item-treat-new-qto',
      parameterFomatter: (data?: {
        qto: ClinicItemTreatNewQtoClinicItemBasePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: TreatClinicItemDetailVoClinicItemBaseEntranceWebVo[],
      ) => data,
    },
});
