import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Divider,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
  Placeholder,
} from '@vs/vsf-kit';
import './index.less';
function ParModal(props) {
  const { payParamData, flag, totalCharge } = props;
  //弹窗相关
  const [payWays, setPayWays] = useState('CASH');
  useEffect(() => {
    !flag &&
      vsf?.refs?.acceptAdvanceForm?.setFieldsValue({
        totalCharge: totalCharge,
      });
  }, [totalCharge, flag]);
  return (
    <div className="pay-layout-container">
      <div className="pay-person-detaill">
        <Row>
          <Col span={6}>
            姓名：
            <span className="person-detaill"></span>
            <span>{payParamData?.name || ''}</span>
          </Col>

          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div style={{ width: '600px', display: 'flex', justifyContent: 'start' }}>
        <div>
          <VSControlledForm id="acceptAdvanceForm">
            <VSFormLayout
              key="1"
              columnCount={1}
              labelWidth={120}
              style={{ marginTop: '28px' }}
            >
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">现金</div>}
                fieldProps={{
                  style: { height: '48px' },
                  placeholder: '请输入现金',
                  disabled: !flag,
                }}
                valueType="text"
              />
            </VSFormLayout>
            <VSFormLayout key="3" columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['paymentDetail', 'paymentType']}
                valueType="radio"
                dataSource={[
                  {
                    label: '聚合支付',
                    value: 'INTEGRATION_PAYMENT',
                    disabled: true,
                  },
                  { label: '现金', value: 'CASH', disabled: true },
                  { label: '银联卡', value: '3', disabled: true },
                  { label: '支票', value: '4', disabled: true },
                ]}
                initialValue={'CASH'}
                fieldProps={{
                  onChange: (item) => {
                    setPayWays(item);
                  },
                }}
              />
            </VSFormLayout>

            {payWays === 'INTEGRATION_PAYMENT' && (
              <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                <VSFormItem
                  name={['paymentDetail', 'paymentAmount']}
                  label={<div className="receivable-form-title">授权码</div>}
                  fieldProps={{
                    style: { height: '48px' },
                    placeholder: '请输入授权码',
                  }}
                  transform={(v) => {
                    return { paymentAmount: Number(v) };
                  }}
                  valueType="text"
                />
              </VSFormLayout>
            )}
            {payWays === '4' && (
              <>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={<div className="receivable-form-title">支票号</div>}
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入支票号',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行名称</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行名称',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="6" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行账户</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行账户',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
              </>
            )}
          </VSControlledForm>
        </div>
      </div>
    </div>
  );
}

export default ParModal;
