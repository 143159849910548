import React, { useCallback, useState, useEffect } from 'react';
import { VSTableColumn, VSPromiseTable, Modal, message } from '@vs/vsf-kit';
import { TreeSelect, Select } from 'antd';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { includes, isEmpty, isNull, isNumber, isObject } from 'lodash';
import dayjs from 'dayjs';
import ProjectNameSelect from './ProjectNameSelect';
import {
  getTableValues,
  scrollTo,
  addTableRow,
  getSelectedRowValues,
  clearTable,
} from './methods';
import { getPricePatternMasterId } from './services';
// 费用明细
const AdviceDetatlsModal = (props) => {
  const { common, user } = props?.stores || {};
  const { staffInfo } = user || {};
  const { applicationList, currentApplication } = common || {};
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(
    () => setSelectedRows([]),
    [props?.modalAdviceDetailsParams?.orderId],
  );
  const onClose = () => {
    props.setModalAdviceDetailsParams({
      ...props?.modalAdviceDetailsParams,
      open: false,
      orderId: null,
    });
  };
  /**
   * convertOrderBillingDetailBillingList2CreateInpBillingDetailForNurseStationReq
   * @param {any} input
   * @returns {any}
   */
  const convertOrderBillingDetailBillingList2CreateInpBillingDetailForNurseStationReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.saveInpBillingDetailEoList = [];
      output.saveInpBillingDetailEoList.push(
        ...(input?.map((d, i) => {
          // const obj = {};
          // obj.id = _.get(d, `[${i}].id`); // Long 住院费用明细id
          // obj.inpVisitId = _.get(d, `[${i}].inpVisitId`); // Long 住院主记录id
          // obj.itemId = _.get(d, `[${i}].itemId`); // String 价表项目ID
          // obj.patientId = _.get(d, `[${i}].patientId`); // Long 患者id
          // obj.itemName = _.get(d, `[${i}].itemName`); // String 项目名称
          // obj.amount = _.get(d, `[${i}].amount`); // Bigdecimal 数量
          // obj.adjustAmount = _.get(d, `[${i}].adjustAmount`);
          // obj.itemClass = _.get(d, `[${i}].itemClass`); // String 价表项目分类
          // obj.orderDate = _.get(d, `[${i}].orderDate`); // Date 业务时间
          // obj.operateDate = _.get(d, `[${i}].operateDate`); // Date 录入时间
          // obj.memo = _.get(d, `[${i}].memo`); // String 补退说明
          // obj.operatorId = _.get(d, `[${i}].operatorId`); // Long 录入人
          // obj.cost = _.get(d, `[${i}].cost`); // Bigdecimal 计价金额
          // obj.charge = _.get(d, `[${i}].charge`); // Bigdecimal 应收金额
          // obj.applyDepartmentId = _.get(d, `[${i}].applyDepartmentId`); // Long 开单科室
          // obj.applyStaffId = _.get(d, `[${i}].applyStaffId`); // Long 开单医生
          // obj.performDepartmentId = _.get(d, `[${i}].performDepartmentId`); // Long 执行科室
          // obj.orderId = _.get(d, `[${i}].orderId`); // Long 医嘱id
          // obj.performStaffId = _.get(d, `[${i}].performStaffId`); // Long 执行人

          // TODO 没有匹配到合适字段 obj.inpVisitId = null; // Long 住院主记录id
          const obj = {};
          isNumber(_.get(d, `id`)) && (obj.id = _.get(d, `id`)); // Long 住院费用明细id
          obj.inpVisitId = props?.onUserInfo?.inpVisitId; // 就诊流水号
          obj.patientId = props?.onUserInfo?.patientId; // Long 患者id
          obj.orderId = props?.modalAdviceDetailsParams?.orderId;
          if (includes(['New', 'Pattern'], d?.TheRowType)) {
            // TODO 没有匹配到合适字段
            obj.itemName = isObject(_.get(d, `itemName`))
              ? _.get(d, `itemName`).label
              : _.get(d, `itemName`); // String 项目名称
            obj.itemId = isObject(_.get(d, `itemName`))
              ? _.get(d, `itemName`).value
              : _.get(d, `itemId`); // String 项目id
            obj.amount = _.get(d, `amount`); // Bigdecimal 数量
            obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
            obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
            obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
            obj.operatorId = _.get(d, `operatorId`); // Long 录入人
            obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
            obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
            obj.applyDepartmentId = _.get(
              d,
              `applyDepartmentName.applyDepartmentId`,
            ); // Long 开单科室
            obj.applyStaffId = _.get(d, `operatorId`); // Long 开单医生
            obj.performDepartmentId = _.get(d, `performDepartmentId`); // Long 执行科室
            // TODO 没有匹配到合适字段 obj.orderId = null; // Long 医嘱id
            obj.performStaffId = _.get(d, `operatorId`); // Long 执行人
            obj.insuranceCode = _.get(d, `insuranceCode`) || null; // Long 医保编码
            obj.medicalInsuranceType = _.get(d, `medicalInsuranceType`); // Long 医保项目类别
          }
          !d?.new && (obj.adjustAmount = _.get(d, `adjustAmount`)); // Bigdecimal 调整数量
          !isEmpty(_.get(d, `memo`)) && (obj.memo = _.get(d, `memo`)); // String 补退说明

          return obj;
        }) ?? []),
      );
      return output;
    };

  /**
   * convertGetInpBillingDetailByOrderIdRes2OrderBillingDetailBillingList
   * @param {any} input
   * @returns {any}
   */
  const convertGetInpBillingDetailByOrderIdRes2OrderBillingDetailBillingList = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.TheRowType = 'Save';
        obj.id = _.get(d, 'id');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemName = {
          label: _.get(d, 'itemName'),
          value: _.get(d, 'itemId'),
        };
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.unit = _.get(d, 'unit');
        obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段 obj.adjustAmount = null; // Bigdecimal 调整数量
        obj.memo = _.get(d, 'memo');
        obj.cost = _.get(d, 'cost');
        obj.charge = _.get(d, 'charge');
        obj.orderDate = _.get(d, 'orderDate');
        obj.applyDepartmentId = _.get(d, 'applyDepartment.id');
        obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.performDepartmentId = _.get(d, 'performDepartment.id');
        obj.performDepartmentName = _.get(
          d,
          'performDepartment.departmentName',
        );
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.operateDate = _.get(d, 'operateDate');
        obj.operateStaffName = _.get(d, 'operator.staffName');
        obj.insuranceAuditNumber = _.get(d, 'insuranceAuditNumber'); // String 审批号
        return obj;
      }) ?? []),
    );
    return output;
  };
  const orderBillingDetailBillingListOnFetch = useCallback(
    async (params) => {
      if (props?.modalAdviceDetailsParams?.stop) return [];
      const res =
        await vsf?.services?.InpBillingDetailController_getInpBillingDetailByOrderId_2827f8?.(
          {
            inpVisitId: props?.onUserInfo?.inpVisitId,
            orderId: props?.modalAdviceDetailsParams?.orderId,
            itemId: props?.modalAdviceDetailsParams?.itemId,
          },
        ); // TODO: 请确认入参来源及出参用法
      const output =
        convertGetInpBillingDetailByOrderIdRes2OrderBillingDetailBillingList?.(
          res,
        );
      return { data: output ?? [], total: output?.length ?? 0 };
    },
    [props?.modalAdviceDetailsParams],
  );
  return (
    <>
      <Modal
        title={<div className="modal-fee-revivw">费用明细</div>}
        open={props?.modalAdviceDetailsParams?.open}
        footer={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <tr>
                <td>
                  <ButtonAndKeyDown
                    children="费别转换"
                    onClick={() => {}}
                    type="primary"
                    methodKey="altKey"
                    ordinaryKey=""
                    disabled
                  />
                </td>
                <td>
                  <ButtonAndKeyDown
                    children="保存"
                    onClick={async (_) => {
                      // await vsf?.refs?.orderBillingDetailBillingList
                      //   ?.getEditForm()
                      //   ?.validateFields();
                      // TODO: 请确认入参来源及出参用法
                      const output =
                        convertOrderBillingDetailBillingList2CreateInpBillingDetailForNurseStationReq?.(
                          vsf?.refs?.orderBillingDetailBillingList?.getValues(),
                        );

                      const res =
                        await vsf?.services?.InpBillingDetailController_createInpBillingDetailForNurseStation_3910aa?.(
                          output,
                        );

                      if (res?.code === 200) {
                        setSelectedRows([]);
                        res?.data?.forEach(
                          ({ id, amountRefund, amount, price }) => {
                            let showAmount =
                              parseInt(amount) - parseInt(amountRefund);
                            let cost = (
                              parseInt(showAmount) * parseFloat(price)
                            ).toFixed(2);
                            if (amountRefund > 0)
                              vsf?.refs?.orderBillingDetailBillingList
                                ?.getEditForm()
                                ?.setFieldsValue({
                                  [id]: {
                                    amount: showAmount,
                                    cost: cost,
                                    charge: cost,
                                  },
                                });
                          },
                        );
                        clearTable('orderBillingDetailBillingList');
                        vsf?.refs?.orderBillingDetailBillingList?.reload();
                        vsf?.refs?.orderBillingDetailGroupList?.reload();
                        return message.success('保存成功');
                      }
                      // return res?.data;
                    }}
                    type="primary"
                    methodKey="altKey"
                    ordinaryKey=""
                  />
                </td>
                <td>
                  <ButtonAndKeyDown
                    children="关闭"
                    onClick={onClose}
                    type="primary"
                    ghost={true}
                    methodKey="altKey"
                    ordinaryKey=""
                  />
                </td>
              </tr>
            </div>
          </>
        }
        onCancel={onClose}
        width={1446}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <tr>
            <td style={{ paddingRight: '18px' }}>选择模板</td>
            <td>
              <Select
                value={undefined}
                allowClear
                style={{ width: '180px' }}
                showSearch
                placeholder="选择模板"
                options={props?.PatternList}
                onChange={async (event) => {
                  if (!event) return;
                  const res = await getPricePatternMasterId(event);
                  addTableRow(
                    'orderBillingDetailBillingList',
                    res?.map((Pattern) => ({
                      charge: 0, // 应收金额
                      unit: Pattern?.priceItemVo?.unit, // 单位
                      amount: Pattern?.pricePatternDetailVo?.amount, // 数量
                      insuranceCode: Pattern?.priceItemVo?.insuranceNationCode, // 医保编码
                      itemClass: Pattern?.priceItemVo?.billingItem, // 价表项目分类
                      itemName: {
                        label: Pattern?.priceItemVo?.itemName, // 项目名称
                        value: Pattern?.priceItemVo?.itemId, // 项目编码
                      },
                      itemSpecification:
                        Pattern?.priceItemVo?.itemSpecification, // 项目规格
                      // memo: Pattern?.priceItemVo?.memo, // 备注
                      cost: (
                        parseFloat(Pattern?.priceItemVo?.price) *
                        parseInt(Pattern?.pricePatternDetailVo?.amount)
                      ).toFixed(2), // 应收费用
                      medicalInsuranceType: 'ALPHA', //InsuranceChargeTypeEnum, // 医保项目类别
                      price: Pattern?.priceItemVo?.price, // 项目单价
                      TheRowType: 'Pattern',
                    })),
                  ).then((d) =>
                    scrollTo(
                      'orderBillingDetailBillingList',
                      getTableValues('orderBillingDetailBillingList')?.[
                        getTableValues('orderBillingDetailBillingList').length -
                          1
                      ]?.id,
                      '',
                    ),
                  );
                }}
              />
            </td>
          </tr>
          <tr>
            <td>
              <ButtonAndKeyDown
                children="新增"
                onClick={() =>
                  addTableRow('orderBillingDetailBillingList', {}).then((d) =>
                    scrollTo(
                      'orderBillingDetailBillingList',
                      getTableValues('orderBillingDetailBillingList')?.[
                        getTableValues('orderBillingDetailBillingList').length -
                          1
                      ]?.id,
                      '',
                    ),
                  )
                }
                type="primary"
                methodKey="altKey"
                ordinaryKey=""
              />
            </td>
            <td>
              <ButtonAndKeyDown
                children="删除"
                onClick={() => {
                  let i = 0,
                    data =
                      vsf?.refs?.orderBillingDetailBillingList?.getSelectedRowValues();
                  while (data.length > i) {
                    if (includes(['Save'], data?.[i]?.TheRowType))
                      return message.error(
                        '删除项包含不可删除的项目，请重新选中。',
                      );
                    i++;
                  }
                  data?.forEach((row) => {
                    includes(['New', 'Pattern'], row?.TheRowType) &&
                      vsf?.refs?.orderBillingDetailBillingList?.remove({
                        id: row?.id,
                      });
                  });
                  message.success('删除成功。');
                }}
                type="primary"
                methodKey="altKey"
                ordinaryKey=""
              />
            </td>
          </tr>
        </div>
        <VSPromiseTable
          rowSelection={{
            selectType: 'multiple',
            selectedRows,
            onSelectedRowsChange: (data) => {
              setSelectedRows(data);
            },
          }}
          style={{ textAlign: 'center' }}
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
            onFieldChange: async (key, value, record, form) => {
              const therow = vsf?.refs?.orderBillingDetailBillingList
                .getValues()
                ?.find((element) => element.id === key);
              if (
                includes(['New', 'Pattern'], therow?.TheRowType) &&
                therow?.price
              )
                form?.setFieldValue?.(
                  [key, 'cost'],
                  (
                    parseFloat(record?.amount) * parseFloat(therow?.price)
                  ).toFixed(2),
                );
            },
          }}
          className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          scroll={{ x: 2280, y: 480 }}
          vsid="40783"
          id="orderBillingDetailBillingList"
          onFetch={orderBillingDetailBillingListOnFetch}
          pagination={false}
          onRecord={async () => {
            let params = {
              // new: true, // 新增标识
              TheRowType: 'New',
              itemName: '请选择项目名称',
              amount: 1, // 数量
              cost: 0, // 计价费用
              charge: 0, // 应收金额
              orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
              operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
              operateStaffName: staffInfo?.staffName, // 员工姓名
              operatorId: staffInfo?.id, // Long 录入人
              // applyDepartmentId: currentApplication?.department?.id, // 科室主键名称
              applyDepartmentName: {
                applyDepartmentName:
                  currentApplication?.department?.departmentName,
                applyDepartmentId: currentApplication?.department?.id,
              }, // 科室名称
              performDepartmentName:
                currentApplication?.department?.departmentName, // 科室名称
              performDepartmentId: currentApplication?.department?.id, // 科室主键名称
            };
            return {
              id: `TEMP_${Math?.random?.()}`,
              ...params,
            };
          }}
        >
          <VSTableColumn
            dataIndex={['itemClass']}
            title="价表项目分类"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.BILL_ITEM_CLASS_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
            preview
          />

          <VSTableColumn
            dataIndex={['itemName']}
            title="项目名称"
            valueType="select"
            formItemProps={{
              rules: [
                {
                  required: true,
                  type: 'object',
                },
              ],
            }}
            width={250}
            fieldProps={{
              placeholder: '请选择',
              dataSource: props?.getValue?.(),
            }}
            renderFormItem={(...args) => {
              let [schema, rows, form] = args,
                component = null;
              if (includes(['New'], rows?.record?.TheRowType)) {
                const props = Object.assign(
                  { ...schema?.originProps },
                  { ...rows, form },
                  { table_id: 'orderBillingDetailBillingList' },
                );
                component = <ProjectNameSelect {...props} />;
              } else {
                component = <span> {rows?.record?.itemName?.label}</span>;
              }
              return component;
            }}
          />

          <VSTableColumn
            dataIndex={['itemSpecification']}
            title="项目规格"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['unit']}
            title="单位"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['amount']}
            title="数量"
            valueType="digit"
            fieldProps={{}}
            preview={(value, record, i) =>
              !includes(['New', 'Pattern'], record?.TheRowType)
            }
            formItemProps={(_) => {
              return {
                rules: [
                  {
                    required: true,
                    message: '必填',
                    type: 'number',
                  },
                ],
              };
            }}
          />

          <VSTableColumn
            width={150}
            dataIndex={['adjustAmount']}
            title="调整数量"
            valueType="digit"
            fieldProps={{}}
            formItemProps={(form, config) => {
              config = Object.assign(config, {
                entity: getTableValues('orderBillingDetailBillingList').find(
                  (f) => f.id === config?.entity?.id,
                ),
              });
              return {
                rules: [
                  {
                    validator(record, value) {
                      if (value && !Number.isInteger(value)) {
                        return Promise.reject('数量必须是整数');
                      } else if (
                        value &&
                        value < 0 &&
                        config?.entity?.amount < Math.abs(value)
                      ) {
                        return Promise.reject('调整数量的绝对值不能大于数量');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ],
              };
            }}
            preview={(value, record, i) =>
              !includes(['Save'], record?.TheRowType)
            }
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="补退说明"
            valueType="text"
            formItemProps={(from, record) => {
              const { entity } = record;
              return {
                rules: [
                  {
                    validator: (_, value) => {
                      const { adjustAmount, TheRowType } =
                        vsf?.refs?.orderBillingDetailBillingList
                          ?.getValues?.()
                          ?.find((element) => element?.id === entity?.id);

                      const methods = {
                        // 新增时
                        Add: () => {
                          if (isEmpty(value))
                            return Promise.reject('请填写补退说明。');
                          return Promise.resolve();
                        },
                        // 保存时
                        Save: () => {
                          // 调整数量为空是 补退费 可不填
                          if (isNull(adjustAmount)) return Promise.resolve();

                          // 调整数量为空是 补退费 为必填
                          if (adjustAmount && isEmpty(value))
                            return Promise.reject('请填写补退说明。');
                          return Promise.resolve();
                        },
                      };

                      return methods?.[
                        includes(['Save'], TheRowType) ? 'Save' : 'Add'
                      ]();
                    },
                  },
                ],
              };
            }}
            fieldProps={{}}
            width={128}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['cost']}
            title="应收费用"
            valueType="digit"
            fieldProps={{}}
            preview
            renderFormItem={(_, { record }) =>
              parseFloat(record?.cost).toFixed(2)
            }
          />

          <VSTableColumn
            dataIndex={['charge']}
            title="实收费用"
            valueType="digit"
            fieldProps={{}}
            preview
            renderFormItem={(_, { record }) =>
              parseFloat(record?.charge).toFixed(2)
            }
          />
          <VSTableColumn
            dataIndex={['price']}
            title="项目单价"
            valueType="digit"
            fieldProps={{}}
            hideInTable
            preview
          />
          <VSTableColumn
            dataIndex={['orderDate']}
            title="业务时间"
            valueType="aspirinDateTimePicker"
            fieldProps={{
              format: 'YYYY/MM/DD HH:mm:ss',
              valueType: 'timestamp',
            }}
            width={182}
            preview={(value, record, i) =>
              !includes(['New', 'Pattern'], record?.TheRowType)
            }
            formItemProps={{
              rules: [
                {
                  required: true,
                },
              ],
            }}
          />

          <VSTableColumn
            dataIndex={['applyDepartmentName']}
            title="开单科室"
            valueType="selectCode"
            formItemProps={{}}
            fieldProps={{}}
            dataSource={applicationList?.map((element) => ({
              applyDepartmentName: element?.department?.departmentName,
              applyDepartmentId: element?.department?.id,
            }))}
            preview={(value, record, i) =>
              !includes(['New', 'Pattern'], record?.TheRowType)
            }
            fieldNames={{
              label: 'applyDepartmentName',
              value: 'applyDepartmentId',
            }}
          />

          <VSTableColumn
            dataIndex={['performDepartmentName']}
            title="执行科室"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['medicalInsuranceType']}
            title="医保项目类别"
            valueType="select"
            dataSource={[
              { label: '甲', value: 'ALPHA' },
              { label: '乙', value: 'BETA' },
              { label: '丙', value: 'GAMMA' },
            ]}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['insuranceCode']}
            title="医保编码"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['operateDate']}
            title="录入时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            preview
          />

          <VSTableColumn
            dataIndex={['operateStaffName']}
            title="员工姓名"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['insuranceAuditNumber']}
            title="审批号"
            valueType="text"
            fieldProps={{}}
            preview
          />
        </VSPromiseTable>
      </Modal>
    </>
  );
};

export default AdviceDetatlsModal;
