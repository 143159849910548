import { labelColWidth, labelColWidthRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Todo,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import './reset.less';

const DrugProductExtensionManage = (props) => {
  const { drugInfo } = props;

  const [disabledState, setDisabledState] = useState({
    baseDrugIndicator: true,
    erpDrugIndicator: true,
    trialTimeDrugIndicator: true,
    reportDrugIndicator: true,
    temporaryDrugIndicator: true,
    eliminateIndicator: true,
    recordPurchaseDrugIndicator: true,
  });

  const loadFormData = useCallback(async () => {
    try {
      if (drugInfo?.id) {
        const response =
          await vsf?.services?.DrugController_getDrugPorductExtensionByDrugProductId_e17bca?.(
            {
              drugProductId: drugInfo?.id,
            },
          );
        if (response && response.data) {
          setDisabledState({
            baseDrugIndicator: !response?.data?.baseDrugIndicator,
            erpDrugIndicator: !response?.data?.erpDrugIndicator,
            trialTimeDrugIndicator: !response?.data?.trialTimeDrugIndicator,
            reportDrugIndicator: !response?.data?.reportDrugIndicator,
            temporaryDrugIndicator: !response?.data?.temporaryDrugIndicator,
            eliminateIndicator: !response?.data?.eliminateIndicator,
            recordPurchaseDrugIndicator:
              !response?.data?.recordPurchaseDrugIndicator,
          });
          vsf?.refs?.DrugProductExtensionManage?.setFieldsValue(response?.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [drugInfo]);

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  const handelFieldsChange = (_value) => {
    const value = _value?.[0];
    if (value && Array.isArray(value?.value)) {
      setDisabledState({
        ...disabledState,
        [value?.name?.[0]]: !value?.value?.[0],
      });
    }
  };
  return (
    <VSControlledForm
      id="DrugProductExtensionManage"
      vsid="12827"
      className="aspirin-form drugProductExtensionManage"
      value={{
        drugProductName: drugInfo?.drugProductName,
      }}
      onFieldsChange={handelFieldsChange}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormLayout
        columnCount={2}
        labelCol={{
          style: {
            width: '5.8rem',
          },
        }}
      >
        <VSFormItem
          name={['drugProductName']}
          label="商品名称"
          valueType="text"
          required
          readonly
          rules={[{ required: true, message: '请输入商品名称' }]}
          fieldProps={{
            width: '16rem',
          }}
        />

        <VSFormItem
          name={['admissionDate']}
          label="进院日期"
          valueType="aspirinDateTimePicker"
          fieldProps={{
            width: '16rem',
            type: 'single',
            format: 'YYYY-MM-DD HH:mm:ss',
            valueType: 'date',
          }}
        />
      </VSFormLayout>

      <VSFormLayout
        key="1"
        columnCount={1}
        labelCol={{
          style: {
            width: '8rem',
          },
        }}
      >
        <VSFormItem
          name={['retailPriceBasis']}
          label="零售价格依据"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 60 }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['importBasis']}
          label="引进依据"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 60 }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['tradePriceBasis']}
          label="进价依据"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 60 }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['adjustBasis']}
          label="调整依据"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 60 }]}
          fieldProps={{}}
        />

        <VSFormLayout>
          <VSFormItem
            name={['baseDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '基本用药目录',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  baseDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                baseDrugIndicator: v,
              };
            }}
          />
          <VSFormItem
            name={['baseDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['baseDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['erpDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '急诊用药目录',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  erpDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                erpDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['erpDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['erpDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['trialTimeDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '试用期用药目录',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  trialTimeDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                trialTimeDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['trialTimeDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['trialTimeDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['reportDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '报告用药品种',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  reportDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                reportDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['reportDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['reportDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['temporaryDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '临时用药',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  temporaryDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                temporaryDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['temporaryDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['temporaryDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['eliminateIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '淘汰品种',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  eliminateIndicator: v?.[0] ?? false,
                };
              }
              return {
                eliminateIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['eliminateDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['eliminateIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>

        <VSFormLayout>
          <VSFormItem
            name={['recordPurchaseDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            style={{
              paddingLeft: '0.8rem',
            }}
            fieldProps={{
              dataSource: [
                {
                  label: '备案采购目录',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  recordPurchaseDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                recordPurchaseDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['recordPurchaseDrugBasis']}
            label="依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{
              width: '54.8rem',
              disabled: disabledState['recordPurchaseDrugIndicator'],
            }}
            labelCol={labelColWidthRem('3rem')}
          />
        </VSFormLayout>
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(DrugProductExtensionManage);
