import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_getWardLabel_acafc8: {
    method: 'post',
    url: '/api/clinic-inp-adt/get-ward-label',
    parameterFomatter: (data?: {
      eo: QueryWardOrderExecutedEoClinicInpAdtPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformLabelSettingMasterWithCountVoClinicInpAdtEntranceWebVo[],
    ) => data,
  },
});
