import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSForm,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import { phoneNumber, phoneNumberMessage } from '@/pattern';
import FormTitle from '@/pages/Index/components/form_title';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';
// import {
//   convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail,
//   convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq,
//   convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq,
// } from '../convert';
const Index = (props, ref) => {
  const {} = props;

  const [open, setOpen] = useState(false);
  // 详情
  const [detail, setDetail] = useState();
  const [list, setList] = useState();
  const [search, setSearch] = useState();
  //   useEffect(() => {
  //     getInpVisitBaseWithDiagnosisVoById();
  //   }, [bedCardId]);

  //   useEffect(() => {
  //     getGroupController();
  //   }, [detail?.currentDepartmentId]);

  useEffect(() => {
    getPagedByUserStaffQto();
  }, [search, detail?.departmentId]);

  useImperativeHandle(
    ref,
    () => ({
      handelModal,
    }),
    [],
  );

  const modalProps = {
    open: open,
    title: (
      <div style={{ fontSize: 20, fontWeight: 500, color: '#3276E8' }}>
        患者详情
      </div>
    ),
    cancelText: '取消',
    okText: '确认',
    width: 1356,

    centered: true,
  };
  //查看患者详情
  // const getInpVisitBaseWithDiagnosisVoById = async (bedCardId) => {
  //   if (!bedCardId?.inpVisitId && !open) return;
  //   const res =
  //     await vsf.services?.ClinicInpVisitController_getInpVisitBaseWithDiagnosisVoById_6417ed?.(
  //       {
  //         id: bedCardId?.inpVisitId,
  //       },
  //     );
  //   const resultData =
  //     convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail(
  //       res,
  //     ); // Tips: 需要返回内容数据，结构和表单RO一致
  //   vsf.refs?.vSFormBedPatientInpDetail?.setFieldsValue({
  //     ...resultData,
  //   });

  //   if (res?.code == 200) {
  //     setDetail(resultData);
  //     //   getGroupController(resultData?.currentDepartmentId);
  //   }
  // };

  const handelModal = (value, bedCardId) => {
    console.log(value, 'open');
    setOpen(value);
    if (value) {
      getPagedByUserStaffQto();
      // getInpVisitBaseWithDiagnosisVoById(bedCardId);
    }
  };
  //医疗组长
  const getGroupController = async (i) => {
    if (!detail?.departmentId) return;
    const input = {};
    //   当前患者所在科室id
    input.departmentId = detail?.departmentId;
    const reqParams =
      convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq(input);
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        eo: reqParams?.eo,
      });
    const _res = res?.data?.result
      ?.map((item) => {
        return item?.medicalGroupMemberIndexDtoList?.[0]?.staff;
      })
      .filter((item) => {
        return item?.id;
      });
    return _res ?? [];
  };
  // 主治医师/住院医师
  const getPagedByUserStaffQto = async (v) => {
    if (!detail?.departmentId) return;
    console.log(v, search);
    const searchParams = {};
    searchParams.staffDepartmentIdIs = detail?.departmentId;
    searchParams.jobClassCodeIs = 'A1';
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        qto: {
          from: 0,
          size: 2000,
          ...searchParams,
          inputCodeLike: search ? search : v?.inputCodeIs,
        },
      });
    const selectItems = [];
    res?.data?.result?.forEach((dataItem) => {
      const selectItem = {};
      selectItem.staffName = dataItem?.staffName;
      selectItem.id = dataItem?.id;
      selectItems.push(selectItem);
    });
    setList(selectItems);
    return res?.data?.result;
  };
  // 责任护士/质控护士searchKey="inputCodeLike"
  const getPagedByUserStaffQto1 = async (v) => {
    const searchParams = {};
    searchParams.staffDepartmentIdIs = detail?.departmentId;
    searchParams.jobClassCodeIs = 'A2'; // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 200,
          ...searchParams,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式

    return res?.data?.result ?? [];
  };

  // 保存
  const save = async () => {
    const params = vsf.refs.vSFormBedPatientInpDetail.getFieldsValue();
    const params1 = vsf.refs.vSFormBedPatientInpDetail.getFieldValue();
    console.log(params, params1);
    const btoParams =
      convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq({
        ...params,
        id: params1?.id,
      }); // Tips: 以下是你选择的请求函数

    const res =
      await vsf.services?.ClinicInpVisitController_updateInpVisitExtensionStaff_c2a009?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          btoParam: btoParams,
          extApiParam: {
            ...params?.extApiParam,
            cellphoneNumber: params?.cellphoneNumber,
          },
        },
      );
    if (res?.code == 200) {
      handelModal(false);
      message.success('保存成功!');
    }
  };

  return (
    <div className="bed_card_detail_modal">
      <Modal
        {...modalProps}
        onCancel={() => {
          handelModal(false);
        }}
        onOk={save}
        bodyStyle={{
          padding: '12px 24px',
          backgroundColor: '#f4f5f7',
          maxHeight: 636,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        todo
      </Modal>
    </div>
  );
};
// export default Index;
export default React.forwardRef(Index);
