import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSTable,
  Row,
  Col,
  Table,
  message,
  Divider,
} from '@vs/vsf-kit';
import PricePatternEdit from './edit';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Icon from '@/module/Icon';
import Block from '@/module/Block';
import { useLockFn, useRequest, useSetState } from 'ahooks';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { askTotalAmount } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { getScrollY } from '@/utils';
import Loading from '@/module/Loading';
import { Flex } from '@/pages/Index/components';
import Dosage from '@/pages/Index/components/dosage';
import { DosageUnit, UsageUnit } from '@/components/InputUnit';

const Selected = memo(({ recode, onChange, form, value }) => {
  const [dataSource, setDataSource] = useState([]);
  let time = null;
  const { loading, run: getPriceItem } = useRequest(
    async (params) => {
      const res =
        await vsf?.services?.PriceListDtoController_getAllByPriceItemReviewQto_39771a?.(
          {
            qto: {
              ...params,
              auditIndicatorIs: 'PASS',
            },
          },
        );
      setDataSource(res?.data.map((item) => ({ ...item, key: item.itemId })));
      return res;
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    getPriceItem({
      size: 100,
    });
  }, []);
  /**
   * 过滤其他以选项
   */
  const otherSelected = vsf.refs?.PricePatternTable.getValues()
    .filter((item) => {
      const priceItemForClinic = item?.priceItemForClinic;
      return (
        (priceItemForClinic?.itemName?.value ??
          priceItemForClinic?.priceItemId) !== (value?.value ?? value)
      );
    })
    .map((item) => item.priceItemId);
  const options = dataSource.filter((item) => {
    return !otherSelected.includes(item.itemId);
  });

  return (
    <ComplexSelect
      mode="radio"
      showLabel="itemName"
      value={value ?? ''}
      hideSelectIcon
      onChange={(value) => {
        form.setFieldValue(recode.recordKey, {
          ...form.getFieldValue(recode.recordKey),
          priceItemId: value.itemId,
          priceItemForClinic: {
            ...value,
            itemName: {
              label: value.itemName,
              value: value.itemId,
            },
          },
        });
        onChange?.({
          label: value.itemName,
          value: value.itemId,
        });
      }}
      dropdownMatchSelectWidth={false}
      onSearch={(value) => {
        clearTimeout(time);
        time = setTimeout(() => {
          setDataSource([]);
          getPriceItem(
            value.trim()
              ? {
                  itemNameLike: value,
                }
              : {
                  size: 100,
                },
          );
        }, 500);
      }}
      dropdownStyle={{
        width: 900,
      }}
      listHeight={1000}
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={[
          {
            title: '价格项目名称',
            dataIndex: 'itemName',
            key: 'item_name',
          },
          {
            title: '规格',
            dataIndex: 'itemSpecification',
            key: 'item_specification',
          },
          {
            title: '品牌',
            dataIndex: 'brand',
            key: 'brand',
          },
          {
            title: '费用类别',
            dataIndex: 'itemClass',
            key: 'item_class',
            render: (text) => {
              for (const item of vsf?.stores?.dbenums?.enums
                ?.RECK_ITEM_CLASS_DICT) {
                if (item?.value === text) {
                  return item.label;
                }
              }
            },
          },
          {
            title: '单位',
            dataIndex: 'unit',
            key: 'unit',
            render: (value) => <UsageUnit value={{ unit: value }} />,
          },
          {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
          },
          {
            title: '备注',
            dataIndex: 'memo',
            key: 'memo',
          },
        ]}
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={16}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        size="small"
        pagination={false}
        dataSource={options}
        scroll={{
          y: 400,
        }}
      />
    </ComplexSelect>
  );
}, Object.is);

const PricePatternDetailTable = (props) => {
  const [dataStore, setDataStore] = useSetState({
    data: [],
    department: [],
  });
  const [data, setData] = useState([{ id: 'create_' + Math.random() }]);

  const getInstitution = async () => {
    const res =
      await vsf.services.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a();
    return res.data;
  };

  const onFetch = useCallback(async () => {
    /**
     * @param {treeLevel} 点击二级才触发
     */
    if (props?.value?.id) {
      switch (props.value?.treeLevel) {
        case 1:
          const res =
            await vsf?.services?.PriceListController_getPricePatternMasterDetailById_9a4b90?.(
              { id: props?.value?.id },
            );
          /**
           * 设置上面的表单数据
           */
          vsf.refs.PricePattern.setFieldsValue({
            ...res.data,
            departmentId: res.data?.department?.id,
            total: askTotalAmount(
              res.data.pricePatternDetailDtoList.map((item) => {
                return [item.priceItemForClinic?.price, item.amount];
              }),
            ),
          });
          const list = res.data.pricePatternDetailDtoList
            .map((item) => {
              return {
                ...item,
                priceItemForClinic: {
                  ...item.priceItemForClinic,
                  itemName: {
                    label: item?.priceItemForClinic?.itemName,
                    value: item.priceItemId,
                  },
                },
                performDepartmentId: item.performDepartment?.id,
              };
            })
            .reverse();
          setData(list.length ? list : [{ id: 'create_' + Math.random() }]);
          break;
        case 0:
          vsf.refs.PricePattern?.resetFields();
          vsf.refs.PricePattern?.setFieldsValue({
            patternRangeType: props.value?.code,
          });
          setDataStore({
            data: [],
          });
          break;
      }
    }
    return [];
  }, [props?.value?.id]);

  const getDepartment = async () => {
    const Institution = await getInstitution();
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9(
        {
          qto: {
            institutionIdIs: Institution?.id,
            stopIndicatorIs: false,
          },
        },
      );
    setDataStore({
      department: res.data.map((item) => ({
        value: item.id,
        label: `${item.departmentName}(${item.branchInstitution?.institutionName})`,
      })),
    });
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const onScreen = () => {};

  useEffect(() => {
    onFetch();
  }, [props?.value?.id]);

  const Footer = () => {
    const success = async () => {
      const funcName = vsf.refs?.PricePattern?.getFieldValue('id')
        ? 'PriceListController_updatePricePatternMaster_321135'
        : 'PriceListController_createPricePatternMaster_f783fc';
      const res = await vsf?.services?.[funcName]?.({
        btoParam: {
          ...vsf.refs.PricePattern.getFieldsValue(),
          pricePatternDetailBtoList: vsf.refs?.PricePatternTable?.getValues()
            .filter((item) => {
              return (
                item?.priceItemForClinic?.itemName || item?.performDepartmentId
              );
            })
            .map((item, index) => {
              /**
               * 如果是从 create 判定为新添加的删除 id
               */
              if (String(item.id)?.startsWith('create')) delete item.id;
              item.sortNumber = index + 1;
              return item;
            }),
        },
      });
      if (res.code === 200) {
        message.success('保存收费模板成功');
        vsf.refs.PricePatternTree.handleReload();
        onFetch();
      }
    };
    /**
     * 如果校验表格项
     */
    const PricePatternTable = async () => {
      const res =
        await vsf.refs.PricePatternTable?.getEditForm()?.validateFieldsReturnFormatValue();
      if (res) {
        return res;
      } else {
        throw '有表格项未填写';
      }
    };
    const onSubmit = useLockFn(() => {
      Promise.all([
        vsf.refs.PricePattern.validateFieldsReturnFormatValue(),
        PricePatternTable(),
      ])
        .then(success)
        .catch((reject) => reject);
    });
    return (
      <div
        className="aspirin-table-footer-button"
        style={{ paddingTop: 16, justifyContent: 'flex-end' }}
      >
        <ButtonAndKeyDown
          type="primary"
          onClick={onSubmit}
          methodKey="altKey"
          ordinaryKey="S"
        >
          保存
        </ButtonAndKeyDown>
        <ButtonAndKeyDown
          type="primary"
          onClick={onScreen}
          methodKey="altKey"
          ordinaryKey="R"
          ghost
        >
          清屏
        </ButtonAndKeyDown>
      </div>
    );
  };

  const onRemove = async (entity) => {
    if (!String(entity.id).startsWith('create_')) {
      Tip({
        title: '操作窗口',
        content: `确定删除{【${entity?.priceItemForClinic?.itemName?.label}】}，删除后不可恢复！`,
        onOk: async () => {
          const res =
            await vsf?.services?.PriceListController_removePriceDetailFromMaster_040237?.(
              {
                btoParam: {
                  id: vsf.refs.PricePattern.getFieldValue('id'),
                  pricePatternDetailBtoList: [{ id: entity.id }],
                },
              },
            );
          if (res.code === 200) {
            message.success('删除收费模板成功');
            onFetch();
          }
        },
        centered: true,
        okText: '确定',
        cancelText: '取消',
      });
    } else {
      vsf.refs.PricePatternTable.remove(entity);
    }
  };

  const onAddAndEdit = async (entity) => {
    const index = data.findIndex((item) => item.id === entity.id);
    const id = { id: 'create_' + Math.random() };
    setData(data.toSpliced(index + 1, 0, id));
  };

  return (
    <Block footer={<Footer />} title="收费模版维护">
      <PricePatternEdit />
      <Divider />
      <VSTable
        editable={{
          editType: 'single',
          saveOnClickOutside: true,
          columnProps: {
            dataIndex: '__vs__table__edit',
            hideInTable: true,
          },
        }}
        onChange={setData}
        id="PricePatternTable"
        rowClassName="aspirin-vs-table-row-box"
        className="aspirin-table-no-background-box"
        dragSort={{
          dragSortDataIndex: 'sort',
          onDragSortEnd: (sortedData) => {
            setData(sortedData);
          },
        }}
        rowClick={{
          rowClickType: {
            type: 'edit-save',
            action: 'click',
          },
        }}
        value={data}
        vsid="27807"
        pagination={false}
        scroll={
          {
            // y: getScrollY(50),
          }
        }
      >
        <VSTableColumn
          dataIndex="sort"
          // width={30}
          editable={false}
          render={(element) => (
            <Flex justify="center" flex={1} style={{ width: '100%' }}>
              {element}
            </Flex>
          )}
        />
        <VSTableColumn
          dataIndex={['priceItemId']}
          title="价表项目标识号"
          valueType="text"
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable
          valueType="digit"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemForClinic', 'itemName']}
          title="项目名称"
          valueType="custom"
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
              />
            );
          }}
          render={(...[, record]) =>
            record?.priceItemForClinic?.itemName?.label ??
            record?.priceItemForClinic?.itemName
          }
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['priceItemForClinic', 'itemClass']}
          title="项目类型"
          valueType="select"
          preview
          dataSource={vsf.stores.dbenums?.enums?.RECK_ITEM_CLASS_DICT}
          ellipsis={{
            showTitle: true,
          }}
          // width={100}
        />

        <VSTableColumn
          dataIndex={['priceItemForClinic', 'itemSpecification']}
          title="规格"
          valueType="text"
          preview
          width={70}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['priceItemForClinic', 'brand']}
          title="品牌"
          valueType="text"
          preview
          width={70}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['priceItemForClinic', 'price']}
          title="现行单价"
          valueType="digit"
          preview
          width={100}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          initialValue={1}
          formItemProps={{
            stringMode: true,
            rules: [
              {
                required: true,
              },
            ],
          }}
          fieldProps={{
            width: '100%',
            min: 1,
            precision: 2,
          }}
        />
        <VSTableColumn
          dataIndex={['priceItemForClinic', 'itemSpecification']}
          title="单位"
          valueType="text"
          preview
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['performDepartmentId']}
          title="执行科室"
          valueType="select"
          dataSource={dataStore.department}
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['babyIndicator']}
          title="新生儿"
          valueType="check"
          fieldProps={{}}
          width={70}
          formItemProps={{
            initialValue: false,
            rules: [
              {
                required: true,
              },
            ],
          }}
        />
        <VSTableColumn
          dataIndex={['__vs__table__edit']}
          title={() => <Flex justify="center">操作</Flex>}
          valueType="custom"
          fieldProps={{}}
          width={100}
          render={(...[, entity]) => {
            return (
              <Flex
                justify="center"
                align="center"
                gap={5}
                style={{
                  width: '100%',
                }}
              >
                <Icon
                  type="icon-tianjia1"
                  style={{ color: 'var(--blue)' }}
                  size={16}
                  onClick={(event) => {
                    event.stopPropagation();
                    onAddAndEdit(entity);
                  }}
                />
                <Icon
                  type="icon-wudishanchu"
                  size={16}
                  onClick={(event) => {
                    event.stopPropagation();
                    /**
                     * 如果点击第一行只清空数据，不删除行
                     */
                    const index = data.findIndex(
                      (item) => item.id === entity.id,
                    );
                    if (index === 0) {
                      setData([
                        { id: entity.id },
                        ...data.filter((item) => entity.id !== item.id),
                      ]);
                      return;
                    }
                    onRemove(entity);
                  }}
                />
              </Flex>
            );
          }}
          renderFormItem={(...[, recode]) => {
            return (
              <Flex justify="center" align="center">
                <Icon
                  type="icon-a-Frame437"
                  size={16}
                  onClick={(event) => {
                    event.stopPropagation();
                    vsf.refs.PricePatternTable.saveEdit(recode.record.id);
                  }}
                />
              </Flex>
            );
          }}
        />
      </VSTable>
    </Block>
  );
};
export default definePage(PricePatternDetailTable);
