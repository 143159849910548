import { memo, useEffect, useState } from 'react';

import { VSForm, VSFormItem, VSTable, VSTableColumn, Form } from '@vs/vsf-kit';
import { getScrollY, getScrollYRem } from '@/utils';
import SearchContainer from '@/module/Search';
import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import { Flex } from '@/pages/Index/components';

const RenderLeft = memo((props) => {
  return (
    <VSTable
      VSTable
      pagination={false}
      size="small"
      rowSelection={{
        hideSelectAll: true,
        ...props.rowSelection,
      }}
      scroll={{
        // y: getScrollY(570),
        y: getScrollYRem(36),
      }}
      value={props.value}
      className="aspirin-table-box-no aspirin-table-no-background-box"
      rowClick={{
        rowClickType: 'select',
      }}
      rowKey="id"
    >
      <VSTableColumn
        dataIndex={['departmentName']}
        title="科室名称"
        valueType="text"
        fieldProps={{}}
      />
    </VSTable>
  );
});
const RenderRight = memo((props) => {
  return (
    <VSTable
      pagination={false}
      size="small"
      {...props}
      className="aspirin-table-box-no aspirin-table-no-background-box"
      id="PerformDepartment"
      editable={{
        editType: 'single',
        columnProps: {
          hideInTable: true,
        },
      }}
      rowKey="id"
      dragSort={{
        dragSortDataIndex: ['sort'],
        onDragSortEnd: (value) => {
          props?.onChange?.(value);
        },
      }}
    >
      <VSTableColumn
        dataIndex="sort"
        width={50}
        fieldProps={{}}
        editable
        render={(element) => {
          return (
            <Flex align="center" justify="center">
              {element}
            </Flex>
          );
        }}
      />
      <VSTableColumn
        title={() => (
          <Flex justify="center" align="center">
            优先级
          </Flex>
        )}
        dataIndex="priority"
        valueType="index"
        width={120}
        editable
        fieldProps={{}}
        render={(element) => {
          return (
            <Flex align="center" justify="center">
              {element}
            </Flex>
          );
        }}
      />
      <VSTableColumn
        title="id"
        valueType="digit"
        hideInTable
        dataIndex="_id"
        fieldProps={{}}
      />
      <VSTableColumn
        title="科室ID"
        valueType="digit"
        hideInTable
        name="departmentId"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['departmentName']}
        title="科室名称"
        valueType="text"
        editable={false}
      />
    </VSTable>
  );
});

const Department = ({ filterDataSource, getDataSource }) => {
  const onChange = (value) => {
    const { branchInstitution, departmentName } = value;
    if (departmentName || branchInstitution?.id) {
      filterDataSource((dataSource) => {
        return dataSource.filter((item) => {
          if (departmentName && branchInstitution) {
            return (
              item.branchInstitution?.id === branchInstitution?.id &&
              askSearch(departmentName, item, 'departmentName')
            );
          }
          return (
            item.branchInstitution?.id === branchInstitution?.id ||
            (departmentName &&
              askSearch(departmentName, item, 'departmentName'))
          );
        });
      });
    } else {
      filterDataSource((dataSource) => dataSource);
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form?.resetFields();
  }, [getDataSource]);

  return (
    <div
      style={{
        marginTop: 24,
      }}
    >
      <SearchContainer.Header title="科室">
        <VSForm
          style={{
            paddingRight: 24,
            paddingTop: 24,
          }}
          onChange={onChange}
          form={form}
        >
          <VSFormItem
            label="院区"
            valueType="selectCode"
            name="branchInstitution"
            dataSource={getDataSource}
            fieldProps={{
              showSearch: true,
              placeholder: '请选择院区搜索',
            }}
            fieldNames={{
              label: 'institutionName',
              value: 'id',
            }}
          />
          <VSFormItem label="科室" name="departmentName" fieldProps={{}} />
        </VSForm>
      </SearchContainer.Header>
    </div>
  );
};
export { Department, RenderLeft, RenderRight };
