import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListBOController_updatePriceItemCategoryStatus_2942fa: {
    method: 'post',
    url: '/api/price-list/update-price-item-category-status',
    parameterFomatter: (data?: { id: number; stop: boolean }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseCodeDtoServiceController_getValidCodeList_3cb2b9: {
    method: 'post',
    url: '/api/base-dictionary/get-valid-code-list',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
});
