import React, { useEffect, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import Container from '@/module/Container';
import { Screen } from '@/pages/Index/components';

import DrugApplyTable from './table';
import MasterList from './master_list';

import './index.less';
import { useRequest } from 'ahooks';
import { getParentStorages } from './services';

const DrugApply = (props) => {
  const { activityBill, setActivityBill } = props;

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: '#fff',
          },
        ],
      }}
      className="drug_apply_wrapper"
    >
      <DrugApplyTable
        {...props}
        activityBill={activityBill}
        setActivityBill={setActivityBill}
      />
    </Container>
  );
};

const DrugApplyScreen = (props) => {
  const [activityBill, setActivityBill] = useState();
  const handelSelectChange = (v) => {
    setActivityBill(Object.assign({ ...activityBill }, v));
  };
  // 药库列表
  const [Storages, setStorages] = useState();
  const ParentStorages = useRequest(
    async () => {
      const res = await getParentStorages();
      setStorages(res);
      handelSelectChange({ storageId: res?.[0]?.value });
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    ParentStorages?.run();
  }, []);
  props = Object.assign(
    { ...props },
    {
      Storages,
    },
  );
  return (
    <>
      <MasterList onSelectChange={handelSelectChange} />
      <DrugApply
        activityBill={activityBill}
        {...props}
        setActivityBill={setActivityBill}
      />
    </>
  );
};

export default definePage(DrugApplyScreen);
