import { Card, Image, VSTable, VSTableColumn } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React from 'react';

import ArrowsImg from '@/assets/arrows_img.png';
import { AsyncSuspense, Determine, Flex, Grid } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { Base } from '@/pages/Index/components/patient_journey/detail';
const Detail = (props) => {
  const { detail } = props;
  const [show] = useOnChangeValue<any>({});
  const [loading] = useOnChangeValue(false);
  return (
    <Flex vertical gap={20}>
      <VSTable
        value={detail?.orderLogWithStaffList?.map((item) => ({
          ...item,
          planDateTime: item?.orderPerformPlanRecordVo?.planDateTime,
          orderText: detail?.orderVoList?.[0]?.orderText,
        }))}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            loading?.onChange?.(true);
            setTimeout(() => {
              loading?.onChange?.(false);
              show?.onChange?.(record);
            }, 500);
          },
        })}
        scroll={{
          y: 300,
        }}
        rowClassName={(record) => {
          const time = [record?.planDateTime, show.value?.planDateTime];
          return time?.filter((item) => item)?.length && time[0] === time[1]
            ? 'aspirin-table-row-active'
            : '';
        }}
      >
        <VSTableColumn
          dataIndex={['planDateTime']}
          valueType="text"
          fieldProps={{}}
          title="计划时间"
          width={170}
        />
        <VSTableColumn
          dataIndex={['orderText']}
          valueType="text"
          title="医嘱正文"
          fieldProps={{}}
        />
      </VSTable>
      <AsyncSuspense
        loading={loading?.value}
        dataSource={show?.value?.operationLogOrderVoList?.length}
      >
        <Flex vertical gap={20}>
          <span>闭环执行明细</span>
          <Grid amount={4} gap={40}>
            {show?.value?.operationLogOrderVoList?.map((item, index) => {
              const dataSource = [
                { label: '开立', value: 'SAVE' },
                { label: '提交', value: 'SUBMIT' },
                { label: '取消提交', value: 'CANCEL_SUBMIT' },
                { label: '作废', value: 'INVALID' },
                { label: '撤销', value: 'REVOKE' },
                { label: '停止', value: 'STOP' },
                { label: '护士校对', value: 'NURSE_CHECK' },
                { label: '取消校对', value: 'CANCEL_CHECK' },
                { label: '退费', value: 'REFUND' },
                { label: '收费', value: 'CHARGE' },
              ];
              return (
                <Flex key={item?.id} justify="space-between" align="center">
                  <Card
                    title={
                      dataSource?.find(
                        ({ value }) => value === item?.operateType,
                      )?.label ?? '-'
                    }
                    style={{
                      width: 200,
                    }}
                  >
                    <span>
                      {item?.operateTime
                        ? dayjs(item?.operateTime)?.format(
                            'YYYY-MM-DD HH:mm:ss',
                          )
                        : ''}
                    </span>
                  </Card>
                  <Determine
                    condition={
                      index !== show?.value?.operationLogOrderVoList?.length - 1
                    }
                  >
                    <div className="arrows">
                      <Image
                        preview={false}
                        width={33}
                        height={33}
                        src={ArrowsImg}
                      />
                    </div>
                  </Determine>
                </Flex>
              );
            })}
          </Grid>
        </Flex>
      </AsyncSuspense>
    </Flex>
  );
};

export default Detail;
