// 新建病人
export const convertCreateErpPatientForm2CreateErpPatientReq = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoParam = {};
  output.btoParam.name = _.get(input, 'name');
  output.btoParam.gender = _.get(input, 'gender');
  output.btoParam.birthday = _.get(input, 'birthday');
  output.btoParam.birthAddress = {};
  output.btoParam.patientExpressAddressBtoList = [];
  output.btoParam.patientRelationshipBtoList = [];
  output.btoParam.patientIdentificationBtoList = _.get(
    input,
    'patientIdentificationBtoList',
  );
  output.btoParam.patientProfileBto = {};
  output.btoParam.patientProfileBto.nativeAddress = {};
  output.btoParam.patientProfileBto.currentAddress = {};
  output.btoParam.patientProfileBto.registeredResidenceAddress = {};
  output.btoParam.patientProfileBto.workUnit = {};
  output.btoParam.patientProfileBto.workUnit.address = {};
  output.btoParam.patientProfileBto.contact = {};
  output.btoParam.patientProfileBto.contact.address = {};
  output.btoParam.label = {};
  output.extApiParam = {};
  return output;
};
// 待诊
export const convertQueryAllGreenWaitByErpVisitForPreExamPatientListQtoRes2ErpGreenWaitPatientList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'erpVisitWithDiseaseDictionary.id');
        obj.spacicalIds = _.get(
          d,
          'erpVisitWithDiseaseDictionary.erpPreExamRecordMaster',
        );
        obj.spacicalId = _.get(d, 'erpVisitWithDiseaseDictionary.id');
        obj.clinicLabel = _.get(d, 'clinicRegisterDefine.clinicLabel');
        obj.clinicLabelId = _.get(d, 'clinicRegisterDefine.id');
        // obj.diseaseLevelName = _.get(
        //   d,
        //   'erpVisitWithDiseaseDictionary.diseaseLevel.id',
        // );
        obj.assessmentDateTime = _.get(
          d,
          'erpVisitWithDiseaseDictionary.erpPreExamRecordMaster',
        );
        obj.diseaseLevel = _.get(
          d,
          'erpVisitWithDiseaseDictionary.diseaseLevel',
        );
        obj.diseaseLevelName = _.get(
          d,
          'erpVisitWithDiseaseDictionary.diseaseLevel.diseaseLevelName',
        );

        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.patientId = _.get(d, 'patient.id');
        obj.gender = _.get(d, 'patient.gender');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 待入
export const convertQueryErpPreExamBedSearchWithPatientQtoRes2RescuePatientList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.clinicLabel = _.get(d, 'clinicRegisterDefine.clinicLabel');
        obj.clinicLabelId = _.get(d, 'clinicRegisterDefine.id');
        obj.diseaseLevelName = _.get(
          d,
          'erpVisitWithDiseaseDictionary.diseaseLevel.diseaseLevelName',
        );
        obj.spacicalId = _.get(d, 'erpVisitWithDiseaseDictionary.id');
        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.patientId = _.get(d, 'patient.id');
        obj.gender = _.get(d, 'patient.gender');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 待转区
export const convertQueryAllWaitTransferByErpVisitForPreExamPatientListQtoRes2ErpWaitTransferPatientList =
  (input) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const erpPreTransferApplyBaseList =
          d?.erpVisitWithDiseaseDictionary?.erpPreTransferApplyBaseList &&
          d?.erpVisitWithDiseaseDictionary?.erpPreTransferApplyBaseList
            ?.length > 0
            ? d?.erpVisitWithDiseaseDictionary?.erpPreTransferApplyBaseList?.[0]
            : {};

        const obj = {}; // TODO 请确认下行字段是否符合逻辑
        obj.clinicLabel = _.get(d, 'clinicRegisterDefine.clinicLabel'); // TODO 请确认下行字段是否符合逻辑
        obj.diseaseLevelName = _.get(
          d,
          'erpVisitWithDiseaseDictionary.diseaseLevel.diseaseLevelName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.spacicalId = _.get(d, 'erpVisitWithDiseaseDictionary.id');
        obj.name = _.get(d, 'patient.name'); // TODO 请确认下行字段是否符合逻辑
        obj.displayId = _.get(d, 'patient.displayId'); //仅含一条
        obj.toTreatAreaCode = _.get(
          erpPreTransferApplyBaseList,
          'toTreatAreaCode',
        );
        obj.transferReason = _.get(
          erpPreTransferApplyBaseList,
          'transferReason',
        );
        obj.patientId = _.get(d, 'patient.id');

        return obj;
      }) ?? []),
    );
    return output;
  };
// 床位
export const convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    // console.log(input);
    output.push(
      ...(input?.map((d, i) => {
        const obj = {};
        obj.treatAreaCode = _.get(d, 'treatAreaCode');
        // obj.recoveryAreaWaitNumber = _.get(
        //   input,
        //   'data.recoveryAreaWaitNumber',
        // ); // Bigdecimal 复苏区待接收数
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
        obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
        obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
        obj.patientDisplayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
        obj.displayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
        obj.erpVisitId = _.get(d, 'clinicPatientBedDto.erpVisitId');
        obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
        obj.patientId = _.get(d, 'patient.id');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 特殊患者弹窗保存
export const convertSpecialPatientMemoForm2UpdateErpExamRecordMasterForSpecialPatientReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = _.get(input, 'id');
    output.btoParam.specialPatientMemo = _.get(input, 'specialPatientMemo');
    output.extApiParam = {};
    return output;
  };

// 详情list
export const convertGetInpExpenseListByConditionRes2InpExpenseDetailListRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.bedLabel = _.get(d, 'inpVisit.patientInHospitalBedDto.bed.bedLabel');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.name = _.get(d, 'inpVisit.inpVisitExtensionPatientInfoDto.name');
      obj.departmentName = _.get(d, 'orderWard.departmentName');
      obj.itemClass = _.get(d, 'itemClass');
      obj.itemName = _.get(d, 'itemName');
      obj.itemSpecification = _.get(d, 'itemSpecification');
      obj.unit = _.get(d, 'unit');
      obj.price = _.get(d, 'price');
      obj.amount = _.get(d, 'amount');
      obj.cost = _.get(d, 'cost');
      obj.performDepartmentName = _.get(d, 'performDepartment.departmentName');
      obj.memo = _.get(d, 'memo');
      return obj;
    }) ?? []),
  );
  return output;
};
// 详情
export const convertGetErpPreExamMainVoRes2PreExamMainForm = (input) => {
  const _ = window._ ?? {};
  const output = {};

  output.name = _.get(input, 'data.preExamBaseInfoVo.patient.name');
  output.gender = _.get(input, 'data.preExamBaseInfoVo.patient.gender');
  output.age = _.get(input, 'data.preExamBaseInfoVo.patient.age');
  output.birthday = _.get(input, 'data.preExamBaseInfoVo.patient.birthday');
  output.defaultChargeType = _.get(
    input,
    'data.preExamBaseInfoVo.patient.defaultChargeType',
  );
  output.cellphone = _.get(input, 'data.preExamBaseInfoVo.patient.cellphone');
  output.currentAddress = _.get(
    input,
    'data.preExamBaseInfoVo.patient.patientProfileDto.currentAddress',
  );
  output.idNumber = _.get(input, 'data.preExamBaseInfoVo.patient.idNumber');
  output.workUnit = _.get(
    input,
    'data.preExamBaseInfoVo.patient.patientProfileDto.workUnit',
  );
  output.arriveHospitalType = _.get(
    input,
    'data.preExamBaseInfoVo.arriveHospitalType',
  );
  output.arriveHospitalPatternMemo = _.get(
    input,
    'data.preExamBaseInfoVo.arriveHospitalPatternMemo',
  );
  output.ambulanceTypeCode = _.get(
    input,
    'data.preExamBaseInfoVo.ambulanceTypeCode',
  );
  output.arriveHospitalDateTime = _.get(
    input,
    'data.preExamBaseInfoVo.arriveHospitalDateTime',
  );
  output.ohcaIndicator = _.get(input, 'data.preExamBaseInfoVo.ohcaIndicator');
  output.notPlanReturnRescueIndicator = _.get(
    input,
    'data.preExamBaseInfoVo.notPlanReturnRescueIndicator',
  );
  output.vitalsignStatus = _.get(
    input,
    'data.preExamBaseInfoVo.vitalsignStatus',
  );
  output.preExamPatientType = _.get(
    input,
    'data.preExamBaseInfoVo.preExamPatientType',
  );
  output.patientType = _.get(input, 'data.preExamBaseInfoVo.patientType');
  output.payAfterClinicReason = _.get(
    input,
    'data.preExamBaseInfoVo.payAfterClinicReason',
  );
  output.epidemiologyHistory = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.epidemiologyHistory',
  );
  output.epidemiologyHistoryMemo = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.epidemiologyHistoryMemo',
  );
  output.erpPreExamRecordDetailWithVitalsignDictionaryList = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.erpPreExamRecordDetailWithVitalsignDictionaryList',
  );

  // output.erpPreExamRecordDetailWithVitalsignDictionaryList = [
  //   { item: '19', itemValue: '192' },
  //   { item: '20', itemValue: '201' },
  //   { item: '21', itemValue: '211' },
  //   { item: '05', itemValue: '37' },
  //   { item: '10', itemValue: '收缩' },
  //   { item: '11', itemValue: '舒张' },
  // ];
  output.nurseAssessmentMasterBaseDtoList = _.get(
    input,
    'data.preExamBaseInfoVo.nurseAssessmentMasterBaseDtoList',
  );
  output.gcsScore = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.gcsScore',
  );
  output.cramsScore = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.cramsScore',
  );
  output.nrsScore = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.nrsScore',
  );
  output.mewsScore = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.mewsScore',
  );
  output.pewsScore = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.pewsScore',
  );
  const _referenceDiseaseLevelId = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.referenceDiseaseLevel',
  );
  output.referenceDiseaseLevelId = {
    ..._referenceDiseaseLevelId,
    label: _referenceDiseaseLevelId?.diseaseLevelName,
    value: _referenceDiseaseLevelId?.id
      ? String(_referenceDiseaseLevelId?.id)
      : '',
  };
  output.referenceDiseaseLevelIds = _referenceDiseaseLevelId?.diseaseLevelName;
  // output.referenceDiseaseLevelIds = _referenceDiseaseLevelId?.diseaseLevelName;

  const _diseaseLevelId = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.diseaseLevel',
  );
  output.diseaseLevel = {
    ..._diseaseLevelId,
    label: _diseaseLevelId?.diseaseLevelName,
    value: _diseaseLevelId?.id ? String(_diseaseLevelId?.id) : '',
  };
  // output.diseaseLevel = _diseaseLevelId?.diseaseLevelName;

  output.diseaseLevelChangeReason = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.diseaseLevelChangeReason',
  );

  output.directionChangeReasonCode = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.directionChangeReasonCode',
  );
  output.treatAreaCode = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.treatAreaCode',
  );
  output.treatAreaSortCode = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.treatAreaSortCode',
  );
  output.directionChangeReason = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.directionChangeReason',
  );
  output.clinicRegister = _.get(input, 'data.preExamBaseInfoVo.clinicRegister');

  // output.clinicDepartment = {};
  output.clinicDepartment = _.get(
    input,
    'data.preExamBaseInfoVo.clinicRegister.clinicRegisterDefine.clinicDepartment.departmentName',
  );
  // output.clinicDepartment.value = _.get(
  //   input,
  //   'data.preExamBaseInfoVo.clinicRegister.id',
  // );
  // output.clinicDepartment = _.get(
  //   input,
  //   'data.preExamBaseInfoVo.clinicRegister.id',
  // );

  output.patientChiefComplaintVsJudgeDictionaryDtoList = _.get(
    input,
    'data.erpPreExamRecordMasterWithDetailVo.patientChiefComplaintVsJudgeDictionaryDtoList',
  );
  output.patientId = _.get(input, 'data.preExamBaseInfoVo.patient.id');
  output.id = _.get(input, 'data.preExamBaseInfoVo.id');
  // 到院方式
  // output.arriveHospital = {
  //   arriveHospitalType: _.get(
  //     input,
  //     'data.preExamBaseInfoVo.arriveHospitalType',
  //   ),
  //   arriveHospitalPatternMemo: _.get(
  //     input,
  //     'data.preExamBaseInfoVo.arriveHospitalPatternMemo',
  //   ),
  // };
  // const _diseaseLevelId = _.get(
  //   input,
  //   'data.erpPreExamRecordMasterWithDetailVo.diseaseLevel',
  // );
  // 实际病情分级
  // output.diseaseLevel = {
  //   diseaseLevelId: {
  //     ..._diseaseLevelId,
  //     label: _diseaseLevelId?.diseaseLevelName,
  //     value: _diseaseLevelId?.id ? String(_diseaseLevelId?.id) : '',
  //   },
  //   diseaseLevelChangeReason: _.get(
  //     input,
  //     'data.erpPreExamRecordMasterWithDetailVo.diseaseLevelChangeReason',
  //   ),
  // };

  // 病人去向
  // output.treatArea = {
  //   treatAreaCode: _.get(
  //     input,
  //     'data.erpPreExamRecordMasterWithDetailVo.treatAreaCode',
  //   ),
  //   directionChangeReason: _.get(
  //     input,
  //     'data.erpPreExamRecordMasterWithDetailVo.directionChangeReason',
  //   ),
  // };

  return output;
};
// 保存
export const convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq = (
  input,
) => {
  // console.log(input, 'ppppooooinput');
  const _ = window._ ?? {};
  const output = {};
  output.saveErpVisitForPreExamBto = {};
  output.saveErpVisitForPreExamBto.id = _.get(input, 'id');
  output.saveErpVisitForPreExamBto.patientId = _.get(input, 'patientId'); // Long 患者ID
  output.saveErpVisitForPreExamBto.arriveHospitalDateTime = _.get(
    input,
    'arriveHospitalDateTime',
  );
  output.saveErpVisitForPreExamBto.arriveHospitalType =
    _.get(input, 'arriveHospitalType')?.value ??
    _.get(input, 'arriveHospitalType');
  output.saveErpVisitForPreExamBto.arriveHospitalPatternMemo = _.get(
    input,
    'arriveHospitalPatternMemo',
  );
  output.saveErpVisitForPreExamBto.clinicRegisterId = _.get(
    input,
    'clinicRegister.id',
  );
  output.saveErpVisitForPreExamBto.patientType = _.get(input, 'patientType');
  output.saveErpVisitForPreExamBto.ohcaIndicator = _.get(
    input,
    'ohcaIndicator',
  );
  output.saveErpVisitForPreExamBto.notPlanReturnRescueIndicator = _.get(
    input,
    'notPlanReturnRescueIndicator',
  );
  output.saveErpVisitForPreExamBto.ambulanceTypeCode = _.get(
    input,
    'ambulanceTypeCode',
  );
  output.saveErpVisitForPreExamBto.vitalsignStatus = _.get(
    input,
    'vitalsignStatus',
  );
  output.saveErpVisitForPreExamBto.preExamPatientType = _.get(
    input,
    'preExamPatientType',
  );
  output.saveErpVisitForPreExamBto.payAfterClinicReason = _.get(
    input,
    'payAfterClinicReason',
  );
  output.saveErpVisitForPreExamBto.treatAreaSortCode = _.get(
    input,
    'treatAreaSortCode',
  );
  output.saveErpVisitForPreExamBto.treatAreaCode =
    _.get(input, 'treatAreaCode')?.value ?? _.get(input, 'treatAreaCode');
  output.createErpPreExamRecordBto = {};
  output.createErpPreExamRecordBto.treatAreaSortCode = _.get(
    input,
    'treatAreaSortCode',
  );
  output.createErpPreExamRecordBto.treatAreaCode =
    _.get(input, 'treatAreaCode')?.value ?? _.get(input, 'treatAreaCode');
  output.createErpPreExamRecordBto.gcsScore = _.get(input, 'gcsScore'); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.gcsLevel = null; // String 格拉斯哥评分等级
  // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.rtsScore = null; // Long 患者外伤评分得分
  // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.rtsLevel = null; // String 患者外伤评分等级
  output.createErpPreExamRecordBto.nrsScore = _.get(input, 'nrsScore'); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.nrsLevel = null; // String 患者疼痛评分等级
  output.createErpPreExamRecordBto.cramsScore = _.get(input, 'cramsScore'); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.cramsLevel = null; // String 创伤评分等级
  output.createErpPreExamRecordBto.pewsScore = _.get(input, 'pewsScore');
  output.createErpPreExamRecordBto.mewsScore = _.get(input, 'mewsScore'); // Long MEWS评分
  output.createErpPreExamRecordBto.referenceDiseaseLevelId =
    _.get(input, 'referenceDiseaseLevelId')?.value ??
    _.get(input, 'referenceDiseaseLevelId');
  // console.log(_.get(input, 'diseaseLevel'), 'diseaseLevelId');
  output.createErpPreExamRecordBto.diseaseLevelId =
    // _.get(input, 'diseaseLevel')?.value ?? String(_.get(input, 'diseaseLevel'));
    _.get(input, 'diseaseLevel') ?? String(_.get(input, 'diseaseLevel'));
  output.createErpPreExamRecordBto.diseaseLevelChangeReason =
    _.get(input, 'diseaseLevelChangeReason')?.value ??
    _.get(input, 'diseaseLevelChangeReason'); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.diseaseLevelReasonCode = null; // Dbenum<PRE_LEVEL_CHANGE_DICT> 病情分级修改理由ID
  output.createErpPreExamRecordBto.directionChangeReason =
    _.get(input, 'directionChangeReason')?.value ??
    _.get(input, 'directionChangeReason');
  output.createErpPreExamRecordBto.directionChangeReasonCode = _.get(
    input,
    'directionChangeReasonCode',
  );
  output.createErpPreExamRecordBto.epidemiologyHistory = _.get(
    input,
    'epidemiologyHistory',
  );
  output.createErpPreExamRecordBto.epidemiologyHistoryMemo = _.get(
    input,
    'epidemiologyHistoryMemo',
  );
  output.createErpPreExamRecordBto.erpPreExamRecordDetailBtoList = _.get(
    input,
    'erpPreExamRecordDetailWithVitalsignDictionaryList',
  );
  output.createPatientChiefComplaintVsJudgeBtoList = _.get(
    input,
    'patientChiefComplaintVsJudgeDictionaryDtoList',
  ); //三个ID

  output.saveVitalsignMeasureRecordBto = {}; //temperature, low_pressure, high_pressure,breathe, nrs, blood_oxygen_saturation, pulse
  // TODO 没有匹配到合适字段 output.saveVitalsignMeasureRecordBto.temperatureType = null; // TemperatureMeasureTypeEnum 体温测量方式
  // TODO 没有匹配到合适字段 output.saveVitalsignMeasureRecordBto.bloodPressureType = null; // BloodPressureMeasureTypeEnum 血压测量方式
  // TODO 没有匹配到合适字段 output.saveVitalsignMeasureRecordBto.bloodSugar = null; // Bigdecimal 血糖
  // TODO 没有匹配到合适字段 output.saveVitalsignMeasureRecordBto.recordType = null; // ErpVitalsignMeasureTypeEnum 体征记录类型(预检/测量)
  output.createVitalsignRecordBtoList = [];
  output.saveErpPreExamFuncFlowApiParam = {};
  output.saveErpPreExamFuncFlowApiParam.patientId = _.get(input, 'patientId'); // Long undefined
  output.saveErpPreExamFuncFlowApiParam.clinicRegisterDetailIdList = _.get(
    input,
    'id',
  )
    ? []
    : _.get(input, 'clinicRegisterDetailList'); // Long undefined
  output.saveErpPreExamFuncFlowApiParam.erpVisitId = _.get(input, 'id'); // Long undefined
  // TODO 没有匹配到合适字段 output.saveErpPreExamFuncFlowApiParam.erpPreExamRecordMasterId = null; // Long undefined
  output.saveErpPreExamFuncFlowApiParam.saveErpPreExamFunctionEnum = _.get(
    input,
    'id',
  )
    ? 'REPEAT_ERP'
    : 'NEW_ERP'; // SaveErpPreExamFunctionEnum undefined
  // 分科 新增多选[50119] 二次分诊单选
  output.saveErpPreExamFuncFlowApiParam.clinicRegisterIdList = _.get(
    input,
    'id',
  )
    ? []
    : _.get(input, 'clinicDepartment')?.map((i) => i?.value);
  return output;
};
// 72小时留观
export const convertGetAllByErpVisitQtoFor72HoursRes2ErpVisit72Hours = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.name = _.get(d, 'patient.name');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.gender = _.get(d, 'patient.gender');
      obj.age = _.get(d, 'patient.age');
      obj.nurseAcceptDateTime = _.get(d, 'nurseAcceptDateTime');
      obj.dischargeDateTime = _.get(d, 'dischargeDateTime');
      return obj;
    }) ?? []),
  );
  return output;
};
// 48小时
export const convertGetAllByErpVisitQtoFor48HoursRes2ErpVisit48List = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.name = _.get(d, 'patient.name');
      obj.id = _.get(d, 'id');
      obj.displayId = _.get(d, 'patient.displayId'); // TODO 请确认下行字段是否符合逻辑
      obj.clinicDepartmentId = _.get(
        d,
        'clinicRegister.clinicRegisterDefine.clinicDepartment.id',
      );
      obj.gender = _.get(d, 'patient.gender');
      obj.age = _.get(d, 'patient.age');
      obj.visitDateTime = _.get(d, 'visitDateTime');
      obj.clinicStatus = _.get(d, 'clinicStatus');
      return obj;
    }) ?? []),
  );
  return output;
};
// 取消分诊
export const convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq1 = (
  input,
) => {
  // console.log(input, 'inputinput');
  const _ = window._ ?? {};
  const output = {};
  output.saveErpVisitForPreExamBto = {};
  output.createErpPreExamRecordBto = {};
  output.createErpPreExamRecordBto.erpPreExamRecordDetailBtoList = [];
  output.createPatientChiefComplaintVsJudgeBtoList = [];
  output.saveVitalsignMeasureRecordBto = {};
  output.createVitalsignRecordBtoList = [];
  output.saveErpPreExamFuncFlowApiParam = {};
  output.saveErpPreExamFuncFlowApiParam.erpVisitId = _.get(input, 'id');
  output.saveErpPreExamFuncFlowApiParam.patientId = _.get(input, 'patientId');
  output.saveErpPreExamFuncFlowApiParam.saveErpPreExamFunctionEnum =
    'CANCEL_ERP';
  return output;
};
