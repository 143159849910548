import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Select,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Flex } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import {
  getIdentification,
  getPromiseHeight,
  getScrollY,
  labelColWidthRem,
} from '@/utils';
import { CertificateSearch } from '@/components';
import {
  getHerbDispenseCountForInpRefundPerform,
  getPatientDetall,
  getWardPagedBySearchCode,
  queryScrollWardPatientList,
} from './services';
import { useRequest } from 'ahooks';
import { omitBy } from 'lodash';
import { isEmpty } from '../../MedicalTechnology/OutpMedicalTechnologyCharge/methods';

const Search = (props) => {
  const { select } = props;

  const search = useRef({});
  const table = useRef(null);
  const onFetch = useCallback(async () => {
    const res = await getHerbDispenseCountForInpRefundPerform({
      ...search?.current,
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickRow = (node) => select?.onChange?.(node);
  const onHandleDataMethods = useCallback(async (key, value) => {
    switch (key) {
      // 患者ID
      case 'patientId':
        return (
          getIdentification(value) &&
          (await getPatientDetall(getIdentification(value)))
        );
      case 'dispenseTime': {
        const [dispenseTimeStart, dispenseTimeEnd] = value;
        return { dispenseTimeStart, dispenseTimeEnd };
      }
      case 'inpVisitId':
        return value?.inpVisitId ?? '';
      case 'wardId':
        return value?.id ?? '';
      default:
        return value;
    }
  }, []);
  const onInquire = async () => {
    const searchs = vsf?.refs?.inquire_form?.getFieldsValue();
    const searchs_keys = Object?.keys(searchs);
    let values = {};
    let i = 0;
    while (searchs_keys?.length > i) {
      if (!isEmpty(searchs[searchs_keys[i]]))
        values = {
          ...values,
          [searchs_keys[i]]: await onHandleDataMethods(
            searchs_keys[i],
            searchs[searchs_keys[i]],
          ),
        };
      i++;
    }
    search.current = omitBy(values, (value) => isEmpty(value));
    table?.current?.reload();
    onClickRow();
  };
  const { data: WardDataSource } = useRequest(getWardPagedBySearchCode),
    { data: WardPatientDataSource, run: WardPatientRun } = useRequest(
      async (params) => {
        const res = await queryScrollWardPatientList(params);
        return res?.map?.((item) => ({
          ...item,
          name: `${item?.bedLabel} - ${item?.name}`,
        }));
      },
      { manual: true },
    );
  const [yMaxHeight, setYMaxHeight] = useState(328);
  getPromiseHeight('search_container').then((res) => {
    setYMaxHeight(
      res - document?.querySelector('#form_container')?.offsetHeight - 24,
    );
  });
  return (
    <Flex vertical id="search_container">
      <Flex gap={10} vertical id="form_container">
        <VSForm labelAlign="left" id="inquire_form">
          <VSFormLayout columnCount={1} labelCol={labelColWidthRem('4rem')}>
            <VSFormItem label="" name={['patientId']} valueType="custom">
              <CertificateSearch />
            </VSFormItem>
            <VSFormItem label="姓名" valueType="text" name={['nameLike']} />
            <VSFormItem
              label="病区"
              name={['wardId']}
              valueType="selectCode"
              fieldProps={{
                showSearch: true,
                onChange: (value) => {
                  WardPatientRun({ wardId: value?.id });
                },
                placeholder: '请选择病区',
                allowClear: true,
              }}
              dataSource={WardDataSource}
              fieldNames={{ label: 'name', value: 'id' }}
              searchKey="searchText"
            />
            <VSFormItem
              label="床号"
              name={['inpVisitId']}
              valueType="selectCode"
              fieldProps={{
                showSearch: true,
                placeholder: '请选择床号',
                allowClear: true,
              }}
              dataSource={WardPatientDataSource}
              fieldNames={{ label: 'name', value: 'id' }}
              searchKey="searchText"
            />
          </VSFormLayout>
        </VSForm>
        <Button type="primary" onClick={onInquire}>
          查询
        </Button>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />
      <div id="table_container">
        <VSPromiseTable
          onFetch={onFetch}
          className="common_table"
          rowClassName={(record) => {
            return record?.id === select?.value?.id
              ? 'aspirin-row-selected-box'
              : 'aspirin-row-select-box';
          }}
          pagination={false}
          scroll={{
            y: yMaxHeight,
          }}
          onRow={(record) => ({
            onClick: () => {
              if (record?.id === select?.value?.id) return onClickRow?.();
              onClickRow?.(record);
            },
          })}
          ref={table}
        >
          <VSTableColumn
            dataIndex={['wardName']}
            title="病区"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['bedLabel']}
            title="床号"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </div>
    </Flex>
  );
};

export default definePage(Search);
