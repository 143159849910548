import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryHerbList_50f5a9: {
    method: 'post',
    url: '/api/drug-drug/query-herb-list',
    parameterFomatter: (data?: {
      qto: HerbListQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-order-drug-for-temp',
    parameterFomatter: (data?: { drugItemId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo,
    ) => data,
  },
});
