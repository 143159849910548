import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_getTodayClinicRegister_c256f2: {
    method: 'post',
    url: '/api/outp-register/get-today-clinic-register',
    parameterFomatter: (data?: { showDeptAll: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: TodayClinicRegisterVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
});
