import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getAllByPricePatternQto_8ee5de: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-pattern-qto',
    parameterFomatter: (data?: { qto: PricePatternQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PricePatternVoPriceListEntranceWebVo[]) =>
      data,
  },
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
});
