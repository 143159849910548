import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
  VSTableInstance,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { init } from 'echarts';
import moment from 'moment';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Block from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import {
  ButtonAndKeyDown,
  Determine,
  Ellipsis,
  Flex,
  NewDrawer,
} from '@/pages/Index/components';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import {
  useAvoidState,
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Mybutton from '@/pages/Index/components/my_button';
import PatientList from '@/pages/Index/components/patient_list';
import { getScrollY, getScrollYRem } from '@/utils';

const TdCell = (props: any) => {
  const { onMouseEnter, onMouseLeave, ...restProps } = props;
  return <td {...restProps} />;
};

const WardOrderExecutedOrderList = (props) => {
  const { stores } = props;
  const {
    stores: {
      common: { currentApplication },
      user: { setInitKey, currentOrderPatient, setCurrentOrderPatient },
    },
  } = props;
  const [isPrint, setIsPrint] = useState(true);
  const [open, setOpen] = useState(false);
  const [patient] = useOnChangeValue({
    inpVisitId: void 0,
  });
  const wardId = currentApplication?.department?.id;
  const executedRef = useRef<VSTableInstance<any, 'text'> | null>(null);
  const [select] = useOnChangeValue<any[]>([]);
  const [tabs, setTabs] = useAvoidState<any[]>([]);
  const [order] = useOnChangeValue<any[]>([]);
  const [form] = Form?.useForm();
  // const isPrinted = Form?.useWatch?.('isPrinted', form) ?? false;
  const isOrderExecuted = Form?.useWatch?.('isOrderExecuted', form) ?? true;
  // const orderRepeatStatus =
  //   Form?.useWatch?.('orderRepeatStatus', form) ?? 'ALL';
  // const [startTime, endTime] = Form.useWatch('startTime', form) || [
  //   moment().subtract(2, 'days').startOf('day')?.format('YYYY-MM-DD HH:mm:ss'), // 前两天的00:00
  //   moment().endOf('day')?.format('YYYY-MM-DD HH:mm:ss'), // 今天的23:59
  // ];
  // const isOral = Form?.useWatch('isOral', form) ?? false;
  // const isIntravenousDrug = Form?.useWatch('isIntravenousDrug', form) ?? false;
  const search = useRef({
    isOrderExecuted: true,
    orderRepeatStatus: null,
    startTime: moment()
      .subtract(2, 'days')
      .startOf('day')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment().endOf('day')?.format('YYYY-MM-DD HH:mm:ss'),
    isOral: false,
    isIntravenousDrug: false,
    isPrinted: false,
  });
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'useDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip() {},
  });
  const status = useRef<'default' | 'bed' | 'plan' | 'print'>('default');
  const onSplice = useCallback(
    (list: any[]) => {
      const push = {};
      const slice = list?.flatMap((item) => {
        if (!item?.orderPerformVoList?.length) return [item];
        if (isOrderExecuted) {
          return item?.orderPerformVoList?.map((value) => {
            return {
              ...item,
              ...value,
            };
          });
        } else {
          const value = item?.orderPerformVoList?.map((item) => [
            item?.order?.orderPerformPlanRecordDtoList,
            item,
          ]);
          return value?.flatMap((state) => {
            const [arr, current] = state;
            return arr?.map((option) => ({
              ...option,
              ...current,
              ...item,
            }));
          });
        }
      });
      for (const item of slice) {
        if (item?.performLabel?.id) {
          push[item?.performLabel?.id] = [
            ...(push[item?.performLabel?.id] ?? []),
            item,
          ];
        }
      }
      for (const index in push) {
        const item = push[index];
        const pop = {};
        for (const value of item) {
          pop[value?.inpTransferRecordVo?.patient?.id] = [
            ...(pop[value?.inpTransferRecordVo?.patient?.id] ?? []),
            value,
          ];
        }
        push[index] = pop;
      }
      const arr: any = Object?.values(push)?.map((item) => {
        const state = Object?.values(item ?? {})
          ?.map((value) => {
            return [value?.at(-1), ...value];
          })
          ?.map((option) =>
            option?.map((item, index) => {
              if (index === 0) {
                return {
                  ...item,
                  sortNumber: item.inpTransferRecordVo?.bed?.sortNumber,
                  id: 'create' + Math.random(),
                };
              } else {
                return {
                  ...item,
                  id: getUUID(),
                };
              }
            }),
          );
        const value = state?.flat()?.at(-1);
        return [
          [
            {
              ...value,
              orderPerformVoList: state?.flat(),
            },
          ],
          ...state,
        ];
      });
      let array: any = [];
      switch (status.current) {
        case 'default':
          array = arr;
          break;
        case 'bed':
          array = arr.map((item) => [
            item?.[0],
            ...item
              ?.slice(1)
              ?.toSorted(
                ([a], [b]) =>
                  a.inpTransferRecordVo.bed.sortNumber -
                  b.inpTransferRecordVo.bed.sortNumber,
              ),
          ]);
          break;
        case 'plan':
          array = arr.map((item) => [
            item?.[0],
            ...item
              ?.slice(1)
              ?.toSorted(
                ([a], [b]) =>
                  dayjs(a.planDateTime)?.valueOf() -
                  dayjs(b.planDateTime)?.valueOf(),
              ),
          ]);
      }
      const list_s = array?.flatMap((item: any) => {
        return item?.flatMap((option, index: number) => {
          if (index === 0) {
            return {
              bedLabel: option?.at(-1)?.performLabel?.labelName ?? '',
              orderPerformVoList: item
                ?.slice(1)
                ?.flat()
                ?.map((item) => ({
                  ...item,
                  orderPerformId:
                    item?.order?.orderPerformPlanRecordDtoList?.at(-1)?.id,
                })),
              bebel: option?.bed?.bedLabel,
              id: 'create' + Math.random(),
              key: 'create' + Math.random(),
            };
          } else {
            return option?.map((value, index) => {
              const { inpTransferRecordVo } = value || {};
              if (index === 0) {
                const green =
                  stores.dbenums.enums.SEX_DICT?.find(
                    (item) =>
                      item?.value === inpTransferRecordVo?.patient?.gender,
                  )?.label ?? '';
                const age = inpTransferRecordVo?.patient?.age;
                return {
                  bedLabel: inpTransferRecordVo?.bed?.bedLabel,
                  name: inpTransferRecordVo?.patient?.name,
                  startPerformDateTime: inpTransferRecordVo?.patient?.displayId,
                  planDateTime: inpTransferRecordVo?.patient?.displayId,
                  wardExecutedOrder: [[age ? age + '岁' : '', green]],
                  orderPerformVoList: option?.slice(1)?.map((item) => ({
                    ...item,
                    orderPerformId:
                      item?.order?.orderPerformPlanRecordDtoList?.at(-1)?.id,
                  })),
                  // id: value?.id,
                  id: 'create' + Math.random(),
                  key: 'create' + Math.random(),
                };
              } else {
                const orderClass =
                  (value?.order?.orderClass ?? '')?.toLocaleLowerCase?.() +
                  'Order';
                const {
                  useDescription,
                  dosage,
                  usage,
                  dosageUnit,
                  usageUnit,
                  frequency,
                  administration,
                } = value?.order?.orderDrugWithPriceItemDto || {};
                const useDescriptions =
                  vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT?.find(
                    ({ value }) => value === useDescription,
                  )?.label ?? '';
                const administrations =
                  vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
                    ({ value }) => value === administration,
                  )?.label;

                return {
                  wardExecutedOrder: [
                    [
                      value?.order?.orderDrugWithPriceItemDto?.prescriptionId
                        ? '[处方]'
                        : '',
                      value?.order?.orderText,
                    ],
                    // orderClass === 'drugOrder'
                    //   ? [
                    //       (dosage ?? '') +
                    //         (dosageUnit ?? '') +
                    //         `(${(usage ?? '') + (usageUnit ?? '')})`,
                    //       administrations,
                    //       frequency?.frequency,
                    //       useDescriptions,
                    //     ]
                    //   : [],
                  ],
                  prescriptionId:
                    value?.order?.orderDrugWithPriceItemDto?.prescriptionId,
                  startPerformDateTime: value?.order?.startPerformDateTime,
                  performList: value?.order?.orderPerformPlanRecordDtoList,
                  id: value?.id,
                  planDateTime:
                    value?.order?.orderPerformPlanRecordDtoList?.at(-1)
                      ?.planDateTime,
                  dripSpeed: value?.order?.orderDrugWithPriceItemDto?.dripSpeed,
                  orderClass: value?.order?.orderClass,
                  orderId: value?.order?.id,

                  duration:
                    orderClass === 'drugOrder'
                      ? ''
                      : isOrderExecuted
                      ? // ? value?.performSchedulePlan
                        value?.order?.orderDisposalDto?.duration &&
                        value?.order?.orderDisposalDto?.duration +
                          value?.order?.orderDisposalDto?.durationUnit
                      : value?.order?.orderPerformPlanRecordDtoList?.map(
                          (item) => item?.planDateTime,
                        ),
                  orderPerformId:
                    value?.order?.orderPerformPlanRecordDtoList?.at(-1)?.id,
                  [orderClass]: {
                    ...value?.order?.orderDrugWithPriceItemDto,
                    dosage: (dosage ?? '') + (dosageUnit ?? ''),
                    usage: (usage ?? '') + (usageUnit ?? ''),
                  },
                  key: 'create' + Math.random(),
                };
              }
            });
          }
        });
      });
      return list_s;
    },
    [stores.dbenums.enums.SEX_DICT, isOrderExecuted],
  );
  const onTabs = useCallback(async () => {
    if (wardId) {
      const eo = {
        wardId,
        ...search?.current,
        inpVisitId: currentOrderPatient?.inpVisit?.id,
      };
      const res =
        await vsf.services?.ClinicInpAdtController_getWardLabel_acafc8?.({
          eo,
        });
      const tabList = [
        {
          label: '全部',
          key: null,
        },
        ...(res?.data ?? [])?.map((item) => {
          return {
            label: item?.labelName + (item?.count ? `(${item?.count})` : ''),
            key: item?.id,
          };
        }),
      ];

      console.log(tabList, 'tabList');

      setTabs(tabList);
      order?.onChange?.(
        res?.data
          ?.filter((item) => item?.defaultShowIndicator)
          ?.map((item) => item?.id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardId, patient?.value?.inpVisitId]);

  const onFetch = useCallback(
    async (params?) => {
      if (wardId) {
        const res =
          await vsf.services?.ClinicInpAdtController_getWardExecutedOrder_91bb10?.(
            {
              eo: {
                ...params?.search,
                ...params?.pagination,
                labelId: order?.value?.filter(Boolean),
                wardId,
                ...search?.current,
                inpVisitId: currentOrderPatient?.inpVisit?.id,
              },
            },
          );
        return {
          data: res?.data?.length
            ? group?.onSliced(onSplice(res?.data ?? []))
            : [],
          total: 0,
        };
      } else {
        return {
          data: [],
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wardId, order?.value, patient?.value?.inpVisitId],
  );

  // const onFetch = useCallback(async () => {
  //   return await [
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //     {
  //       name: '张三',
  //     },
  //   ];
  // }, []);

  const sort = {
    babel: (value: boolean) => {
      const list = executedRef?.current?.getValues?.();
    },
  };

  useEffect(() => {
    onTabs();
  }, [onTabs]);

  const Footer = () => {
    const onLabel = (type) => {
      onPrint(type);
    };
    const onTwoLabel = () => {
      onPrint('DOUBLE_PRINT');
    };

    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          gap: 10,
          background: 'var(--background)',
        }}
      >
        <Determine
          condition={true}
          // fallback={
          //   <ButtonAndKeyDown
          //     ordinaryKey="P"
          //     methodKey="altKey"
          //     onClick={() => {
          //       onLabel();
          //     }}
          //     type="primary"
          //     disabled={!select?.value?.length}
          //   >
          //     有效执行单打印
          //   </ButtonAndKeyDown>
          // }
        >
          {(isOrderExecuted || (!isOrderExecuted && !isPrint)) && (
            <ButtonAndKeyDown
              ordinaryKey="P"
              methodKey="altKey"
              onClick={() => {
                onLabel('VALID_PRINT');
              }}
              type="primary"
              disabled={!select?.value?.length}
            >
              有效执行单打印
            </ButtonAndKeyDown>
          )}
          {!isOrderExecuted && isPrint && (
            <Button
              type="primary"
              onClick={() => {
                onLabel('LABEL_PRINT');
              }}
              disabled={!select?.value?.length}
            >
              标签打印
            </Button>
          )}
          {!isOrderExecuted && isPrint && (
            <Button
              type="primary"
              onClick={onTwoLabel}
              disabled={!select?.value?.length}
            >
              双份打印
            </Button>
          )}
        </Determine>
      </div>
    );
  };

  // 打印
  const onPrint = useFetch({
    fetch: async (printMethod) => {
      isOrderExecuted;
      const eo = {
        idList: [
          ...new Set(
            select?.value
              ?.map((item) =>
                isOrderExecuted ? item?.orderId : item?.orderPerformId,
              )
              ?.filter(Boolean),
          ),
        ],
        ...(isOrderExecuted ? { isOrderExecuted: true } : {}),
        printMethod,
      };
      const res =
        await vsf?.services?.ClinicInpAdtController_printWardExecuteOrder_75fe4c?.(
          {
            eo,
          },
        );
      return res;
    },
    message: {
      loading: '打印中',
      success: '打印成功',
      error: '打印失败',
    },
    success: () => {
      executedRef.current?.reload?.();
      select?.onChange?.([]);
    },
  });

  return (
    <Fragment>
      <Fragment>
        <Flex
          align="center"
          justify="space-between"
          style={{
            padding: isMinScreen ? '12px' : '24px',
            width: '100%',
            // height: 90,
          }}
          className="aspirin-table-search-form-box"
        >
          <VSForm
            form={form}
            onChange={(value) => {
              search.current = { ...search.current, ...value };
            }}
          >
            <Flex align="center" gap={20}>
              <VSFormItem
                valueType="radioButton"
                dataSource={[
                  {
                    label: '医嘱执行单',
                    value: true,
                  },
                  {
                    label: '拆分计划单',
                    value: false,
                  },
                ]}
                name={'isOrderExecuted'}
                initialValue={true}
                fieldProps={{
                  buttonStyle: 'solid',
                  onChange: (value) => {
                    if (!value) {
                      status.current = 'bed';
                      executedRef.current?.reload?.();
                      onTabs?.();
                    }
                  },
                }}
              />
              <VSFormItem
                label="起始时间"
                valueType="limitTime"
                name={'startTime'}
                fieldProps={{}}
                transform={(value) => {
                  const [startTime, endTime] = value;
                  return {
                    startTime,
                    endTime,
                  };
                }}
              />
              <VSFormItem
                valueType="radio"
                name={'orderRepeatStatus'}
                initialValue={null}
                dataSource={[
                  { label: '长期', value: 'true' },
                  { label: '临时', value: 'false' },
                  { label: '全部', value: null },
                  // { label: '长期', value: 'LONG_TERM' },
                  // { label: '临时', value: 'TEMPORARY' },
                  // { label: '全部', value: 'ALL' },
                ]}
                fieldProps={{}}
              />
              <Determine condition={!isOrderExecuted}>
                <VSFormItem
                  valueType="radio"
                  name={'isPrinted'}
                  initialValue={false}
                  dataSource={[
                    { label: '已打印', value: true },
                    { label: '未打印', value: false },
                  ]}
                />
              </Determine>
              <Determine condition={!isOrderExecuted}>
                <Flex
                  gap={20}
                  align="center"
                  style={{
                    height: '100%',
                    borderLeft: '1px var(--blue) solid',
                    paddingLeft: 20,
                  }}
                >
                  <Flex vertical>
                    <VSFormItem
                      valueType="check"
                      name={['isOral']}
                      label="包药机口服药"
                    />
                    <VSFormItem
                      valueType="check"
                      name={['isIntravenousDrug']}
                      label="静配药"
                    />
                  </Flex>
                  <VSFormItem
                    valueType="radio"
                    name={['babelLabel']}
                    initialValue={true}
                    fieldProps={{
                      style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        gap: 10,
                      },
                      onChange: (value) => {
                        status.current = value ? 'bed' : 'plan';
                      },
                    }}
                    dataSource={[
                      {
                        label: '按患者排序',
                        value: true,
                      },
                      {
                        label: '按执行计划排序',
                        value: false,
                      },
                    ]}
                  />
                  <VSFormItem
                    valueType="radio"
                    name={['isPrint']}
                    fieldProps={{
                      style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        gap: 10,
                      },
                      onChange: (value) => {
                        setIsPrint(value);
                      },
                    }}
                    initialValue={true}
                    dataSource={[
                      {
                        label: '标签打印',
                        value: true,
                      },
                      {
                        label: '有效执行单打印',
                        value: false,
                      },
                    ]}
                  />
                </Flex>
              </Determine>
            </Flex>
          </VSForm>
          <ButtonAndKeyDown
            onClick={() => {
              executedRef.current?.reload?.();
              // onTabs?.();
            }}
            methodKey="altKey"
            ordinaryKey="Q"
            type="primary"
          >
            查询
          </ButtonAndKeyDown>
        </Flex>
        <div
          className="aspirin-table-inter-box"
          style={{
            padding: 24,
            height: 'auto',
            overflowX: 'auto',
          }}
        >
          <div className="aspirin-table-tabs-box">
            {tabs?.map((item) => {
              const condition =
                item?.key === null
                  ? order?.value?.length === tabs?.length - 1
                  : order?.value?.includes(item?.key);

              return (
                <div
                  className={classNames(
                    'aspirin-table-check-box',
                    condition ? 'aspirin-table-check-active-box' : '',
                  )}
                  style={{ padding: '0 10px' }}
                  key={item?.key}
                  onClick={() => {
                    /**
                     * 全部
                     */
                    if (item?.key === null) {
                      if (order?.value?.length === tabs?.length - 1) {
                        order?.onChange([]);
                      } else {
                        order?.onChange(
                          tabs?.slice(1)?.map((item) => item?.key),
                        );
                      }
                      return;
                    }
                    if (order?.value?.includes(item?.key)) {
                      order?.onChange(
                        order?.value?.filter((option) => option !== item?.key),
                      );
                    } else {
                      order?.onChange([...order?.value, item?.key]);
                    }
                  }}
                >
                  {item?.label}
                  <Determine condition={condition}>
                    <Icon
                      type="icon-xuanze"
                      className="aspirin-table-check-icon-box"
                    />
                  </Determine>
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
      <Block
        scroll={false}
        footer={<Footer />}
        style={{
          padding: 0,
        }}
      >
        <VSPromiseTable
          ref={executedRef}
          id="wardExecuted"
          bordered
          onFetch={onFetch}
          className="aspirin-table-no-background-box"
          pagination={false}
          components={{
            body: TdCell,
          }}
          rowClassName={(record) => {
            return record?.startPerformDateTime
              ? ''
              : 'aspirin-table-row-background-box';
          }}
          searchConfig={{
            layout: 'inline',
          }}
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
          }}
          rowKey="key"
          scroll={{
            // y: getScrollY(isMinScreen ? 220 : 430),
            y: getScrollYRem(25),
          }}
        >
          <VSTableColumn
            dataIndex={['check']}
            title={() => {
              const value: any = executedRef?.current
                ?.getValues?.()
                ?.filter((item) => item?.performList);
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    onChange={(info) => {
                      if (info) {
                        select?.onChange?.(value);
                      } else {
                        select?.onChange([]);
                      }
                    }}
                    indeterminate={
                      select?.value?.length > 0 &&
                      select?.value?.length < value?.length
                    }
                    value={
                      select?.value?.length === value?.length &&
                      select?.value?.length !== 0 &&
                      value?.length !== 0
                    }
                  />
                </div>
              );
            }}
            valueType="custom"
            renderFormItem={(...[, { record: recode }]) => {
              const record = executedRef?.current
                ?.getValues?.()
                ?.find((item) => item?.id === recode?.id);
              const value = select?.value
                ?.map((item: any) => item?.id)
                .includes(record?.id);
              const orderPerformVoList = record?.orderPerformVoList?.filter(
                (item) => !String(item?.id)?.startsWith('create'),
              );
              const state = select?.value?.filter((item) =>
                orderPerformVoList?.map((item) => item?.id)?.includes(item?.id),
              );
              let render: any = '';
              if (!record?.performList?.length) {
                render = (
                  <Checkbox
                    value={state?.length === orderPerformVoList.length}
                    onChange={(info) => {
                      if (info) {
                        select?.onChange([
                          ...select?.value?.filter(
                            (item) =>
                              !orderPerformVoList
                                ?.map((item) => item?.id)
                                ?.includes(item?.id),
                          ),
                          ...orderPerformVoList,
                        ]);
                      } else {
                        select?.onChange(
                          select?.value?.filter(
                            (item) =>
                              !orderPerformVoList
                                ?.map((item) => item?.id)
                                ?.includes(item?.id),
                          ),
                        );
                      }
                    }}
                    indeterminate={
                      state?.length > 0 &&
                      state?.length < orderPerformVoList.length
                    }
                  />
                );
              } else if (
                record?.groupName === 'aspirin-order-text-group-first'
              ) {
                render = (
                  <Checkbox
                    value={value}
                    onChange={(info) => {
                      if (record?.groupIndex?.length) {
                        const state = (executedRef?.current as any)
                          ?.getValues?.()
                          ?.filter((...[, index]) =>
                            record?.groupIndex?.includes(index),
                          );
                        if (info) {
                          select?.onChange([...select?.value, ...state]);
                        } else {
                          select?.onChange?.(
                            select?.value.filter(
                              (item) =>
                                !state
                                  ?.map((item) => item?.id)
                                  ?.includes(item?.id),
                            ),
                          );
                        }
                      } else {
                        if (info) {
                          select?.onChange([...select?.value, record]);
                        } else {
                          select?.onChange?.(
                            select?.value.filter(
                              (item) => item?.id !== record?.id,
                            ),
                          );
                        }
                      }
                    }}
                  />
                );
              } else if (!record?.groupName) {
                render = (
                  <Checkbox
                    value={value}
                    onChange={(info) => {
                      if (info) {
                        select?.onChange([
                          ...select?.value?.filter(
                            (item) => !state?.includes(item?.id),
                          ),
                          record,
                        ]);
                      } else {
                        select?.onChange(
                          select?.value?.filter(
                            (item) => item?.id !== record?.id,
                          ),
                        );
                      }
                    }}
                  />
                );
              }
              return (
                <Flex justify="center" align="center">
                  {render}
                </Flex>
              );
            }}
            width={50}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['bedLabel']}
            title=""
            valueType="text"
            width={100}
            hideInTable
            fieldProps={{}}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['bedLabel']}
            title="床号"
            valueType="text"
            width={100}
            fieldProps={{}}
            editable={false}
          />

          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            width={100}
            fieldProps={{}}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['planDateTime']}
            title="执行时间"
            valueType="text"
            hideInTable={isOrderExecuted}
            width={150}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['startPerformDateTime']}
            title="起始时间"
            valueType="text"
            width={170}
            hideInTable={!isOrderExecuted}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['wardExecutedOrder']}
            title="医嘱内容"
            valueType="text"
            width={500}
            render={(...[, record]) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: 15,
                    justifyContent: 'space-between',
                  }}
                  className={classNames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  {record?.wardExecutedOrder?.map((item, index: number) => (
                    <Flex key={index} gap={index !== 0 ? 15 : 0}>
                      {item?.map((option, index) => (
                        <span key={index}>{option}</span>
                      ))}
                    </Flex>
                  ))}
                </div>
              );
            }}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['drugOrder', 'dosage']}
            title="剂量"
            valueType="text"
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['drugOrder', 'usage']}
            title="用量"
            valueType="text"
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['drugOrder', 'administration']}
            title="途径"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['drugOrder', 'frequency', 'frequency']}
            title="频次"
            valueType="text"
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['drugOrder', 'useDescription']}
            title=" 用法"
            valueType="descriptionSelect"
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['planDateTime']}
            title="执行时间"
            valueType="text"
            width={150}
            hideInTable={!isOrderExecuted}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['duration']}
            title="持续时间"
            valueType="text"
            width={200}
            render={(...[, record]) => {
              return (
                <Ellipsis
                  content={
                    Array?.isArray(record?.duration)
                      ? record?.duration?.join(' ')
                      : record?.duration
                  }
                  title="持续时间"
                >
                  <Flex gap={15}>
                    <Determine
                      condition={Array?.isArray(record?.duration)}
                      fallback={record?.duration}
                    >
                      {record?.duration?.map?.((item, index: number) => (
                        <span key={index}>{item}</span>
                      ))}
                    </Determine>
                  </Flex>
                </Ellipsis>
              );
            }}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['dripSpeed']}
            title="滴速"
            valueType="text"
            width={50}
            editable={false}
          />
          <VSTableColumn
            dataIndex={['performList', 'performDepartment', 'departmentName']}
            title="执行科室"
            valueType="text"
            width={100}
            hideInTable={!isOrderExecuted}
            render={(...[, record]) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    gap: 15,
                  }}
                >
                  {
                    record?.performList?.at(-1)?.performDepartment
                      ?.departmentName
                  }
                </div>
              );
            }}
            editable={false}
          />
        </VSPromiseTable>
      </Block>
      <NewDrawer
        open={open}
        width="294px"
        onOpen={() => {
          setOpen(!open);
        }}
        title="患者列表"
        buttonTitle="患者列表"
        // footer={null}
        buttonStyle={{
          top: '20%',
        }}
        style={{
          top: 104,
        }}
        drift="98px"
        drawerClassName="aspirin-check-order-patient-drawer"
        footer={
          <div className="aspirin-execute-order-patient-drawer-button">
            <Mybutton
              buttonProps={{
                ghost: true,
                onClick: () => {
                  onFetch();
                  setInitKey();
                },
              }}
            >
              刷新
            </Mybutton>
          </div>
        }
      >
        <div
          style={{
            padding: 24,
          }}
        >
          <PatientList
            statusDataSource={[{ label: '在科', value: 'IN_DEPARTMENT' }]}
            hideField={['signaturePatient']}
            onChange={(value: any) => {
              patient?.onChange?.({
                ...value,
                inpVisitId: value?.inpVisit?.id,
              });
              setOpen(false);
            }}
          />
        </div>
      </NewDrawer>
    </Fragment>
  );
};
export default definePage(WardOrderExecutedOrderList);
