import { createFromIconfontCN } from '@vs/vsf-icons';
import React from 'react';

// import { ICON_URL } from '@/config';
const ICON_URL = '/offline/iconfont.js';

const IconFont = createFromIconfontCN({
  scriptUrl: ICON_URL,
});

type IconType = {
  type: string;
  style?: any;
  size?: number;
};

const Index = (props: IconType) => {
  const { type, style = {}, size } = props;
  const formatStyle = style;
  if (size) {
    formatStyle['fontSize'] = size;
  }
  return (
    <IconFont
      className={`vp-icon icon${size}`}
      type={type}
      style={formatStyle}
    />
  );
};

export default Index;
