import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_getOtherInpBillingDetailGroupInfo_d3c3df: {
    method: 'post',
    url: '/api/inp-billing/get-other-inp-billing-detail-group-info',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailGroupForNurseStationVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getOtherInpBillingDetail_165b8e: {
    method: 'post',
    url: '/api/inp-billing/get-other-inp-billing-detail',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_createOtherBillingDetailForBillingStation_9efff4: {
    method: 'post',
    url: '/api/inp-billing/create-other-billing-detail-for-billing-station',
    parameterFomatter: (data?: {
      saveInpBillingDetailEoList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_adjustBillingDetailAmount_df7221: {
    method: 'post',
    url: '/api/inp-billing/adjust-billing-detail-amount',
    parameterFomatter: (data?: {
      adjustBillingDetailEoList: AdjustBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
