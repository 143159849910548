import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAddition,
  VSTableToolbar,
  Button,
  Todo,
  Modal,
  message,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import { isMinScreen } from '@/config';

import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';
import { getScrollY } from '@/utils';
import { useMount, useRequest } from 'ahooks';
import Tip from '@/pages/Index/components/func/Tip';
import { askSearch } from '@/pages/Index/components/func/ask';
import classnames from 'classnames';
const ClinicRegisterTypePriceItemTable = (props) => {
  const [projectNameDatasource, setProjectNameDatasource] = useState();
  const [projectNameAllData, setProjectNameAllData] = useState();
  const [saveStatus, setSaveStatus] = useState(true);
  useMount(() => {
    (async (params) => {
      const res =
        await vsf?.services?.PriceListDtoController_getAllByPriceItemQueryQto_273108?.(
          {
            qto: {
              stopIs: false,
            },
          },
        );
      console.log('---------');
      console.log(res?.data);
      setProjectNameAllData(res?.data);
      setProjectNameDatasource(
        res?.data?.map((d) => ({
          id: d.id,
          label: d.itemName,
          value: d.itemId,
        })),
      );
    })();
  });
  useEffect(() => {
    props.value && setSaveStatus(false);
  }, [props.value]);
  const onSave = async (params) => {
    const data = vsf.refs.ClinicRegisterPriceItemTable.getValues();
    if (!data.length) {
      return;
    }
    const formatData = data.map((d) => {
      const dict = {
        ...d,
        clinicRegisterTypeId: props.value?.id,
        priceItemId: d.priceItem?.priceItemId,
        priceItemName: d?.priceItem?.priceItemName,
      };
      if (('' + d.id)?.startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const req = {
      id: props.value?.id,
      clinicRegisterPriceItemBtoList: formatData,
    };

    const res =
      await vsf?.services?.OutpRegisterDefineController_addUpdateClinicRegisterPriceItem_705179?.(
        { btoParam: req, extApiParam: {} },
      );
    message.success('收费项目已保存！');

    vsf.refs.ClinicRegisterPriceItemTable.reload();
    vsf.refs.clinicRegisterTypeTable.reload();
    return res?.data;
  };
  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteClinicRegisterPriceItem_5c1f6c?.(
        {
          btoParam: {
            id: props.value?.id,
            clinicRegisterPriceItemBtoList: [{ ...params }],
          },
          extApiParam: params?.extApiParam,
        },
      );
    res?.code === 200 && message.success('删除成功');
    vsf.refs.ClinicRegisterPriceItemTable.reload();
    vsf.refs.clinicRegisterTypeTable.reload();
    return res?.code === 200;
  };
  const onFetch = async (params) => {
    console.log(props.value);

    if (typeof props.value?.id === 'string') {
      // message.error('未保存的号类没有编码条目');
      return;
    }
    console.log('-');
    if (!props.value?.id) {
      return;
    }
    console.log('-');
    const res =
      await vsf?.services?.OutpRegisterDefineController_getPriceItemByClinicRegisterTypeId_daf5db?.(
        { clinicRegisterTypeId: props.value?.id },
      );
    const result = res?.data
      ?.sort(function (a, b) {
        return a.chargeItemNumber - b.chargeItemNumber;
      })
      ?.map((item) => ({
        ...item,
        priceItem: {
          priceItemId: item?.priceItemId,
          priceItemName: item?.priceItemName,
        },
      }));
    return {
      data: result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  const { data: PriceData, run: PriceDataRun } = useRequest(
    async (value) => {
      const res =
        await vsf?.services?.PriceListDtoController_getPriceItemForClinicType_c67b85?.(
          {
            qto: {
              // itemNameLike: value?.priceItemName,
              stopIs: false,
            },
          },
        );
      console.log(
        res?.data?.map((item) => ({
          id: item.id,
          priceItemName: item.priceItemName,
          priceItemId: item.priceItemId,
        })),
      );
      // return res?.data ?? [];
      return res?.data?.map((item) => ({
        id: item.id,
        priceItemName: item.itemName,
        priceItemId: item.itemId,
      }));
    },
    { manual: true },
  );
  useEffect(() => PriceDataRun(), []);
  return (
    <VSPromiseTable
      className={classnames(
        'aspirin-table aspirin-table-search-padding-no table_overflow_844',
        isMinScreen ? 'table_overflow_884' : 'table_overflow_844',
      )}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        onFieldChange: (key, value, allValue, form) => {
          if (value.priceItemId) {
            const spriceItem = projectNameAllData.find(
              (item) => item.itemId === allValue?.priceItemId?.value,
            );
            form.setFieldValue(key, {
              ...allValue,
              price: spriceItem?.price,
            });
          }
        },
        onCanDelete: () => null,
        extraOperations: [
          {
            children: <Icon type="icon-shanchu1" size={24} />,
            order: 1,
            onClick: (event, data) => {
              Tip({
                content: `是否删除{【${data?.priceItemName}】}`,
                onOk: () => {
                  onRemove(data);
                },
              });
            },
          },
        ],
      }}
      vsid="97726"
      onFetch={onFetch}
      onRecord={() => ({ id: 'create_' + Math.random() })}
      id="ClinicRegisterPriceItemTable"
      pagination={false}
      scroll={{
        y: 0,
        // y: getScrollY(800),
      }}
    >
      <VSTableColumn
        dataIndex={['chargeItemNumber']}
        title="排序号"
        valueType="digit"
        // fieldProps={{
        //   disabled: true,
        // }}
        formItemProps={{
          rules: [{ message: '该项为必选项', required: true }],
        }}
      />

      <VSTableColumn
        dataIndex={['priceItem']}
        title="项目名称"
        valueType="select"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        fieldNames={{ key: 'id', label: 'priceItemName', value: 'priceItemId' }}
        fieldProps={{
          showSearch: true,
        }}
        dataSource={PriceData}
      />

      <VSTableColumn
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{
          disabled: true,
        }}
        formItemProps={
          {
            // rules: [{ message: '该项为必选项', required: true }],
          }
        }
      />

      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        editable={false}
        valueType="text"
        fieldProps={{}}
        // formItemProps={{
        //   rules: [{ message: '该项为必选项', required: true }],
        // }}
      />

      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableToolbar
        title={
          <div className="all-save">
            <div>编码条目编辑区</div>
          </div>
        }
        // style={{ paddingLeft: '20px' }}
      >
        <VSTableAddition
          type="dashed"
          disabled={saveStatus}
          position="top"
          children="添加"
        />
        <Button
          type="primary"
          disabled={saveStatus}
          children="保存"
          onClick={onSave}
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(ClinicRegisterTypePriceItemTable);
