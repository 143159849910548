import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentDtoController_getAllByDepartmentQto_1c2a91: {
    method: 'post',
    url: '/api/organization/get-all-by-department-qto',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
});
