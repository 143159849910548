import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicWorkShiftSettingController_deleteShiftNotifyTemplate_71a078: {
    method: 'post',
    url: '/api/clinic-work-shift-setting/delete-shift-notify-template',
    parameterFomatter: (data?: {
      btoParam: DeleteShiftNotifyTemplateBtoClinicWorkShiftSettingServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WordShiftNotifyTemplateBaseVoClinicWorkShiftSettingEntranceWebVo,
    ) => data,
  },
  ClinicWorkShiftSettingController_deleteShiftNotifyTemplateList_dc5811: {
    method: 'post',
    url: '/api/clinic-work-shift-setting/delete-shift-notify-template-list',
    parameterFomatter: (data?: { ids: number[] }) => data,
    responseFormatter: (
      _,
      __,
      data?: WordShiftNotifyTemplateBaseVoClinicWorkShiftSettingEntranceWebVo[],
    ) => data,
  },
  ClinicWorkShiftSettingController_getDepartmentWordShiftNotifyTemplateList_ebf666:
    {
      method: 'post',
      url: '/api/clinic-work-shift-setting/get-department-word-shift-notify-template-list',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: WordShiftNotifyTemplateBaseVoClinicWorkShiftSettingEntranceWebVo[],
      ) => data,
    },
  ClinicWorkShiftSettingController_saveShiftNotifyTemplateList_77b7a1: {
    method: 'post',
    url: '/api/clinic-work-shift-setting/save-shift-notify-template-list',
    parameterFomatter: (data?: {
      btoList: CreateShiftNotifyTemplateBtoClinicWorkShiftSettingServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WordShiftNotifyTemplateBaseVoClinicWorkShiftSettingEntranceWebVo[],
    ) => data,
  },
});
