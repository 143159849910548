import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getDiseaseHistoryById_23156c: {
    method: 'post',
    url: '/api/clinic-record-history/get-disease-history-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createDiseaseHistory_71c74d: {
    method: 'post',
    url: '/api/clinic-record-history/create-disease-history',
    parameterFomatter: (data?: {
      btoParam: CreateDiseaseHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_updateDiseaseHistory_38e949: {
    method: 'post',
    url: '/api/clinic-record-history/update-disease-history',
    parameterFomatter: (data?: {
      btoParam: UpdateDiseaseHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
