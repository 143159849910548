import vsf, { definePage } from '@vs/vsf-boot';
import {
  DatePicker,
  DateRangePicker,
  Radio,
  RadioGroup,
  Select,
  Tag,
} from '@vs/vsf-kit';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import CheckBlock from './check';

const mock = [
  {
    label: '三个月',
    value: 3,
  },
  {
    label: '半年',
    value: 6,
  },
  {
    label: '一年',
    value: 12,
  },
  {
    label: '全部',
    value: 'all',
  },
];

const reportTimes = [
  { label: '5次', value: '5' },
  { label: '10次', value: '10' },
  { label: '20次', value: '20' },
  { label: '全部', value: 'all' },
];

const Index = (props) => {
  const { search, handelSearch } = props;
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};
  // 时间
  const [radioValue, setRadioValue] = useState();
  // 类型
  const [checkBlockValue, setCheckBlockValue] = useState();
  // 时间范围
  const [reportTimeRage, setReportTimeRage] = useState<any>();
  //疾病筛选
  const [selectValue, setSelectValue] = useState<string[]>(['all']);
  //疾病筛选型数据源
  const [selectValueList, setSelectValueList] = useState<string[]>([]);
  //次数筛选
  const [timesValue, setTimesValue] = useState<string[]>();

  const _patientId = currentPatient?.patientId;

  // useEffect(() => {
  //   handelSearch?.({
  //     checkBlockValue,
  //     radioValue,
  //     reportTimeRage,
  //   });
  // }, [handelSearch, checkBlockValue, radioValue, reportTimeRage]);

  const handleChange = (value) => {
    handelSearch?.({
      checkBlockValue,
      radioValue,
      reportTimeRage,
      selectValue: value,
    });
    setSelectValue(value);
  };

  const getSelectValueList = useCallback(async () => {
    if (_patientId) {
      const res =
        await vsf.services?.VisitHistoryController_getDiagnosisDictionaryByPatientId_82a0ca?.(
          {
            patientId: _patientId,
          },
        );
      if (res?.code === 200) {
        const _res = res?.data?.map((item) => {
          return { label: item?.diagnosisName, value: item?.id };
        });

        setSelectValueList([{ label: '全部', value: 'all' }, ..._res]);
      }
    }
  }, [_patientId]);

  useEffect(() => {
    getSelectValueList();
  }, [getSelectValueList]);
  // 疾病筛选
  const handleSelect = (value) => {
    if (value?.value === 'all') {
      setSelectValue([value.value]);
      handelSearch?.({
        checkBlockValue,
        radioValue,
        reportTimeRage,
        selectValue: [value.value],
      });
    }
    if (selectValue.length === 1 && selectValue?.[0] === 'all') {
      handelSearch?.({
        checkBlockValue,
        radioValue,
        reportTimeRage,
        selectValue: [
          ...cloneDeep(selectValue).filter((item) => item !== 'all'),
          value.value,
        ],
      });
      setSelectValue([
        ...cloneDeep(selectValue).filter((item) => item !== 'all'),
        value.value,
      ]);
    }
  };
  // 次数
  const onChange = (v) => {
    setRadioValue(v);
    setReportTimeRage(undefined);
    handelSearch?.({
      checkBlockValue,
      radioValue: v,
      reportTimeRage,
      selectValue,
    });
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const renderTime = (type) => {
    if (type === 'all') {
      return undefined;
    } else {
      return dayjs().subtract(type, 'month').format('YYYY-MM-DD 00:00:00');
    }
  };

  return (
    <div className="history-search">
      <div style={{ display: 'flex', flexShrink: 0 }}>
        <Select
          className="width140"
          // width={106}
          // height={32}
          // placeholder="报告时间"
          dataSource={reportTimes}
          style={{ marginRight: '6px' }}
          value={radioValue}
          onChange={onChange}
          defaultValue="all"
        />
        <DateRangePicker
          locale={locale}
          // defaultValue={record?.performDateTime}
          placeholder={['请选择开始时间', '请选择结束时间']}
          onChange={(v) => {
            handelSearch?.({
              checkBlockValue,
              radioValue: undefined,
              reportTimeRage: {
                start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
                end: v?.[1] && dayjs(v?.[1]).format('YYYY-MM-DD 00:00:00'),
              },
            });
            setReportTimeRage({
              start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
              end: v?.[1] && dayjs(v?.[1]).format('YYYY-MM-DD 00:00:00'),
            });
          }}
          // onChange={(v) => {
          //   handelSearch?.({
          //     checkBlockValue,
          //     radioValue: undefined,
          //     reportTimeRage: {
          //       ...reportTimeRage,
          //       start: v && dayjs(v).format('YYYY-MM-DD 00:00:00'),
          //     },
          //   });
          //   setReportTimeRage({
          //     ...reportTimeRage,
          //     start: v && dayjs(v).format('YYYY-MM-DD 00:00:00'),
          //   });
          // }}
        />
        {/* <DatePicker
          locale={locale}
          style={{ marginLeft: -2 }}
          // defaultValue={record?.performDateTime}
          placeholder="请选择结束时间"
          onChange={(v) => {
            console.log(v, reportTimeRage, 'ppp');
            handelSearch?.({
              checkBlockValue,
              radioValue: undefined,
              reportTimeRage: {
                ...reportTimeRage,
                end: v && dayjs(v).format('YYYY-MM-DD 23:59:59'),
              },
            });
            setReportTimeRage({
              ...reportTimeRage,
              end: v && dayjs(v).format('YYYY-MM-DD 23:59:59'),
            });
          }}
        /> */}
        {/* <DateRangePicker
          width={224}
          placeholder={['开始时间', '结束时间']}
          value={[reportTimeRage?.start, reportTimeRage?.end]}
          onChange={(value) => {
            const v = value;
            setRadioValue(undefined);
            handelSearch?.({
              checkBlockValue,
              radioValue: undefined,
              reportTimeRage: {
                start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
                end: v?.[0] && dayjs(v?.[1]).format('YYYY-MM-DD 23:59:59'),
              },
            });
            setReportTimeRage({
              start: v?.[0] && v?.[0],
              end: v?.[0] && v?.[1],
            });
            console.log(v, '时间范围');
          }}
        /> */}
      </div>
      {/* <RadioGroup
        optionType="button"
        onChange={onChange}
        value={radioValue}
        dataSource={mock}
        style={{
          marginLeft: '12px',
          backgroundColor: '#ebf2fc',
        }}
      ></RadioGroup> */}
      <CheckBlock
        style={{
          marginLeft: 24,
          flexShrink: 0,
        }}
        handelConditionQuery={(v) => {
          handelSearch?.({
            checkBlockValue: v,
            radioValue: undefined,
            reportTimeRage,
          });
          setCheckBlockValue(v);
          search?.(v);
        }}
      />
      <div
        style={{
          flexShrink: 0,
          marginRight: '20px',
          color: '#343B42',
        }}
        className="marginLeft24 icon18"
      >
        疾病筛选
      </div>
      <Select
        mode="multiple"
        tagRender={tagRender}
        allowClear
        style={{ width: '100%' }}
        placeholder="请选择报告类型"
        value={selectValue}
        onChange={handleChange}
        onSelect={(e) => {
          handleSelect(e);
        }}
        dataSource={selectValueList}
      />
    </div>
  );
};

export default definePage(Index);
