import React, { useEffect, useState } from 'react';
import './index.less';
import {
  Row,
  Col,
  Modal,
  Pagination,
  Space,
  Button,
  message,
} from '@vs/vsf-kit';
import { flatMap } from 'lodash';
import NumberOrderModal from './NumberOrderModal';
import { useMount, useRequest, useUpdate } from 'ahooks';
import askTimeBto from '@/pages/Index/components/func/ask/askTimeBto';
import index from '@/pages/Index/cpoe/index';
const selectStyle = {
  background: '#EBF2FF',
  border: ' 2px solid #3276E8',
};
const unSelectStyle = {
  background: '#fff',
};

export const TableCart = (props) => {
  const {
    indexId,
    data,
    selectCardChange,
    selectNumChange,
    subFlushStatusChange,
    subFlushStatus,
    selectIndex,
    selectChange,
    selectData,
  } = props;
  const [numOrderParam, setNumOrderParam] = useState();
  const [open, setOpen] = useState(false);
  const [dafalueNum, setDafalueNum] = useState();
  const { run: getNumberOrderList, loading } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpRegisterController_getClinicRegisterFirstAvailableTimeDivision_afebf5?.(
          {
            clinicRegisterDetailId: params?.id,
          },
        );
      setDafalueNum(res?.data);
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const cancelModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const dafalueNumChange = (val) => {
    return setDafalueNum(val);
  };
  useEffect(() => {
    numOrderParam && getNumberOrderList(numOrderParam);
  }, [numOrderParam]);
  useEffect(() => {
    selectNumChange(dafalueNum);
  }, [dafalueNum]);
  useEffect(() => {
    if (!selectData) {
      selectChange(undefined);
      setDafalueNum(undefined);
      selectCardChange(undefined);
      setNumOrderParam(undefined);
    }
  }, [selectData]);
  return (
    <>
      {data?.clinicRegisterDetailVoList.length &&
        data?.clinicRegisterDetailVoList.map((item, index) => {
          return (
            <div>
              <div className={index === 0 ? 'cart-am' : 'un-cart-am'}>
                <div
                  className="cart-am-farewell"
                  style={
                    item?.platformRemainingLimit || !item?.timeDescription
                      ? subFlushStatus || selectIndex === `${indexId}+${index}`
                        ? selectStyle
                        : unSelectStyle
                      : {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          color: 'rgba(0, 0, 0, 0.25)',
                        }
                  }
                  onClick={() => {
                    if (item?.platformRemainingLimit) {
                      selectChange(`${indexId}+${index}`);
                      subFlushStatusChange(index);
                      selectCardChange(item);
                      setNumOrderParam(item);

                      // 点击取消
                      if (selectIndex === `${indexId}+${index}`) {
                        selectChange(undefined);
                        // setSelectData(undefined);
                        setDafalueNum(undefined);
                        selectCardChange(undefined);
                        setNumOrderParam(undefined);
                      }
                    } else {
                      message.error('未查询到可用号序');
                    }
                  }}
                >
                  {item?.timeDescription && (
                    <div className="cart-am-farewell-content">
                      <Row>
                        <Col span={12}>
                          {item?.timeDescription === 'PM' ? '下午' : '上午'}
                        </Col>
                        <Col span={12}>
                          <span
                            className={
                              item?.platformRemainingLimit
                                ? 'cart-am-farewell-content-font'
                                : 'cart-am-farewell-content-font-disable'
                            }
                          >
                            剩余号量:
                            {item?.platformRemainingLimit}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          号序{' '}
                          <a
                            style={{
                              paddingLeft: '3px',
                              display: 'inline-block',
                              textDecoration: 'underline',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpen(true);
                            }}
                          >
                            {selectIndex === `${indexId}+${index}` &&
                              dafalueNum?.serialNumber}
                          </a>
                        </Col>
                        <Col span={12}>
                          <span
                            className={
                              item?.platformRemainingLimit
                                ? 'cart-am-farewell-content-font'
                                : 'cart-am-farewell-content-font-disable'
                            }
                          >
                            {askTimeBto(item?.waitTimePeriod)}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>

              {data?.clinicRegisterDetailVoList?.length < 2 && (
                <div className="un-cart-am"></div>
              )}

              <NumberOrderModal
                open={open}
                cancelModal={cancelModal}
                openModal={openModal}
                dafalueNum={dafalueNum}
                numOrderParam={numOrderParam}
                dafalueNumChange={dafalueNumChange}
              />
            </div>
          );
        })}
    </>
  );
};
export const SelectedCart = (props) => {
  const { selectData, numberData } = props;
  // useEffect(() => {
  //   console.log('lh');
  //   console.log(selectData);
  //   console.log(numberData);
  // });
  return (
    <>
      <div className="cart-am-farewell">
        <div className="register-table-inner-select-cart">
          <Row>
            <Col span={12}>
              {selectData?.timeDescription === 'PM' ? '下午' : '上午'}
            </Col>
            <Col span={12}>
              <span className="cart-am-farewell-content-font">
                剩余号量:{selectData?.platformRemainingLimit}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              号序{' '}
              <a
                style={{
                  paddingLeft: '3px',
                  display: 'inline-block',
                  textDecoration: 'underline',
                }}
              >
                {numberData?.serialNumber}
              </a>
            </Col>
            <Col span={12}>
              <span className="cart-am-farewell-content-font">
                {selectData && askTimeBto(selectData?.waitTimePeriod)}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
