import ExamCategoryDetail from './tabs/ExamCategoryDetails';
import { Tabs } from '@vs/vsf-kit';
import PerformDepartment from './tabs/PerformDepartment';
import ExamBody from './tabs/ExamBody';
import ExamMethod from './tabs/ExamMethod';
import ExamItem from './tabs/ExamItem';
import '@/style/adaptation.less';
import { useMemo } from 'react';

import '@/style/adaptation.less';

const Tab = (props) => {
  const {
    value: [thisClass, allClass],
  } = props;
  const parent = [
    {
      key: '4',
      label: '检查方法维护',
      children: <ExamMethod value={thisClass} />,
    },
    {
      key: '3',
      label: '检查部位字典维护',
      children: <ExamBody value={thisClass} />,
    },
  ];
  const items = [
    {
      key: '1',
      label: '检查类别详细信息',
      children: (
        <ExamCategoryDetail
          value={props.data}
          onDetailChange={props?.onDetailChange}
        />
      ),
    },
    {
      key: '2',
      label: '执行科室维护',
      children: <PerformDepartment value={thisClass} />,
    },
    {
      key: '5',
      label: '检查项目维护',
      children: <ExamItem data={thisClass} value={allClass} />,
    },
  ];
  const item = useMemo(() => {
    /**
     * 判断是是否选中和是否顶级
     */
    return props?.value.length && !props?.value[0]?.parentExamClassId
      ? [...items, ...parent]
      : items;
  }, [props.value, props.data]);
  return (
    <Tabs
      defaultActiveKey="5"
      items={item}
      onChange={props.onChange}
      activeKey={props.tab}
      className="aspirin-tabs-box heightAll100"
    />
  );
};

export default Tab;
