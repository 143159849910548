import './index.less';

import { compose, Slider, withField, withPreview, withRef } from '@vs/vsf-kit';
import React from 'react';

let Index: any = (props, ref) => {
  return (
    <div className="aspirin-slider">
      <Slider {...props} ref={ref} />
      <div className="value">{props?.renderValue?.(props?.value)}</div>
    </div>
  );
};

Index.displayName = 'Slider';

Index = React.forwardRef(Index);

export default compose(
  withRef(),
  withField<any>({
    name: 'slider',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      return <>{props?.value}</>;
    },
  }),
)(Index);
