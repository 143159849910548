import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitController_queryAllRedAndYellowWaitByErpVisitForPreExamPatientListQto_7e5c08:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/query-all-red-and-yellow-wait-by-erp-visit-for-pre-exam-patient-list-qto',
      parameterFomatter: (data?: {
        qto: ErpVisitForPreExamPatientListQtoClinicOutpVisitPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OutpVisitForPreExamPatientVoClinicOutpVisitEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicErpAdtController_cancelConfirmErpTransferForSearchList_afbd92: {
    method: 'post',
    url: '/api/clinic-erp-adt/cancel-confirm-erp-transfer-for-search-list',
    parameterFomatter: (data?: { erpVisitId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
