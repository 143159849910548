import vsf from '@vs/vsf-boot';
const Fetch = {
  getPatientPreDischarge: async (inpVisitId: number) => {
    const res =
      await vsf?.services?.ClinicInpAdtController_getPatientPreDischargeNotifyStatus_02790e?.(
        {
          inpVisitId,
        },
      );
    return res?.data;
  },
};

export default Fetch;
