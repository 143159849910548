import {
  Section,
  Column,
  Columns,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  VSTableAction,
  Switch,
  message,
} from '@vs/vsf-kit';
import React, { useState, useCallback } from 'react';
import { isMinScreen } from '@/config';

import { TreeMenu } from '@/components';
import { getScrollY } from '@/utils';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';
// import PatientGroupTree from '@/pages/Index/manage/staff/tree';
import PatientGroupEdit from './edit';
import PatientGroupTree from './tree.jsx';

const PatientGroupTreeTable = (props) => {
  /**
   * 系统参数
   * @type {object}
   */
  const [selectedPatientGroup, setSelectedPatientGroup] = useState();
  const [isShow, setIsShow] = useState(true);
  const [showStop, setShowStop] = useState(true);
  const [codeType, setCodeType] = useState();
  /**
   * 转换
   * @param {object} codeType
   * @returns {object}
   */
  const codeTypeToClassId = (codeType) => {
    if (!codeType) {
      return {};
    }
    return { classIdIs: codeType.code };
  };

  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            gap: '0',
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <PatientGroupTree
        onChange={(_value, upValue) => {
          setSelectedPatientGroup({ ..._value, upValue: { ...upValue } });
        }}
      />

      <VSPromiseTable
        className="aspirin-table
        aspirin-table-header-background-color
        aspirin-table-row-hover-background-color
       table_overflow_480
        aspirin-table-body-empty-transparent-background-color "
        id="patientGroupTable"
        rowKey="rowKeyId"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <div></div>,
          columnProps: {
            width: 80,
          },
        }}
        vsid="53249"
        scroll={{ y: 0 }}
        onFetch={async (params) => {
          // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
          // Tips: 以下是你选择的请求函数
          console.log(selectedPatientGroup);
          if (selectedPatientGroup?.id && !selectedPatientGroup?.disabledAdd) {
            const res =
              await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.(
                {
                  // TODO: 请检查以下这部分传参是否符合需求
                  eo: {
                    from: params.pagination?.from ?? 0,
                    size: params.pagination?.size ?? 20,
                    orderList: params.orderList,
                    ...params.search,
                    ...params.filters,
                    ...(params?.extra ?? {}),
                    departmentId: selectedPatientGroup?.id,
                    // departmentId: 117,
                    stopIndicator: showStop,
                    isGetGroup: true,
                  },
                },
              ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
            return {
              data:
                res?.data?.result?.map((i) => {
                  return { ...i, rowKeyId: Math.random() };
                }) ??
                res?.data ??
                [],
              total: res?.data?.count ?? res?.data?.length ?? 0,
            };
          }
        }}
        addLinkPage={{
          linkPage: () => (
            <PatientGroupEdit
              departmentId={selectedPatientGroup?.id}
              title="add"
            />
          ),
          modalProps: {
            title: '主诊组维护',
            className: 'aspirin-linkpage',
            width: isMinScreen ? 956 : 1300,
            bodyStyle: {
              // height: 680,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            className: 'modalHeight720',
          },
          onLinkPageValidate: async () => {
            try {
              const validateRes =
                await vsf.refs.OperateGroupDepartmentBranch?.validateFieldsReturnFormatValue();
              const res = validateRes?.medicalGroupMemberIndexDtoList?.map(
                (item) => {
                  return {
                    ...item,
                    staffId: item?.staff?.id,
                    departmentId: item?.departmentId?.id,
                  };
                },
              );
              const _response =
                await vsf.services.UserGroupController_createMedicalGroup_f4b186(
                  {
                    btoParam: {
                      ...validateRes,
                      medicalGroupMemberBtoList: res,
                      departmentId: selectedPatientGroup?.id,
                      stopIndicator: true,
                    },
                  },
                );
              if (_response?.code === 200) {
                vsf.refs.patientGroupTable?.reload();
                return true;
              } else {
                return false;
              }
            } catch (e) {
              return false;
            }
          },
        }}
        onAdd={async (params) => {
          return params;
        }}
        updateLinkPage={{
          linkPage: () => (
            <PatientGroupEdit departmentId={selectedPatientGroup?.id} />
          ),
          modalProps: {
            title: '主诊组维护',
            className: 'aspirin-linkpage',
            width: isMinScreen ? 956 : 1300,
            bodyStyle: {
              // height: 600,
              overflowY: 'scroll',
              backgroundColor: '#f4f5f7',
            },
            className: 'modalHeight720',
          },
          onLinkPageValidate: async (params) => {
            try {
              const validateRes =
                await vsf.refs.OperateGroupDepartmentBranch?.validateFieldsReturnFormatValue();
              const res = validateRes?.medicalGroupMemberIndexDtoList?.map(
                (item) => {
                  return {
                    ...item,
                    staffId: item?.staff?.id,
                    departmentId: item?.departmentId?.id,
                  };
                },
              );
              const _response =
                await vsf?.services?.UserGroupController_updateMedicalGroup_75af28?.(
                  {
                    btoParam: {
                      ...validateRes,
                      medicalGroupMemberBtoList: res,
                      departmentId: selectedPatientGroup?.id,
                    },
                    extApiParam: {},
                  },
                );
              if (_response?.code === 200) {
                message.success('修改成功');
                vsf.refs.patientGroupTable?.reload();
                return true;
              } else {
                return false;
              }
            } catch (e) {
              return false;
            }
          },
        }}
        onUpdate={async (param) => {
          return param;
        }}
        searchConfig={{
          labelWidth: 90,
          span: 6,
          collapsed: false,
          collapseRender: false,
          disableSearchButtonLoading: true,

          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="搜索">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return <SearchContainer>{dom}</SearchContainer>;
        }}
        form={{
          labelAlign: 'left ',
        }}
      >
        <VSTableColumn
          dataIndex={['groupName']}
          title="组名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
          render={(dom) => {
            return dom;
          }}
        />

        <VSTableColumn
          dataIndex={['medicalGroupMemberIndexDtoList', 'staff', 'staffName']}
          title="组成员名称"
          valueType="text"
          search
          // order={-1}
          columnKey={'staffName'}
          searchFieldProps={{
            placeholder: '请输入医生姓名',
          }}
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
            label: '医生姓名',
          }}
          render={(_, record) => {
            console.log(record, 'recordrecord1');
            const res = record?.medicalGroupMemberIndexDtoList?.map((item) => {
              const _v = vsf.stores.dbenums?.enums?.EMP_ROLE.filter((v) => {
                return item?.staffRole === v?.value;
              });
              return item?.staff?.staffName + '[' + _v[0]?.label + ']';
            });
            return res.join(';');
          }}
        />
        {/* dataSource={vsf.stores.dbenums?.enums?.EMP_ROLE} */}
        <VSTableColumn
          dataIndex={['staffNumber']}
          title="医生编码"
          valueType="text"
          search
          hideInTable
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="停用标识"
          valueType="switch"
          // search
          order={-3}
          // columnKey={'stopIndicatorIs'}
          fieldProps={{
            defaultChecked: true,
            onChange: (v) => {
              setIsShow(v);
            },
          }}
          formItemProps={{
            label: '显示停用',
          }}
          render={(_, value) => {
            return (
              <div
                style={{
                  color: value?.stopIndicator ? '#E34A4E ' : '#30A313',
                }}
              >
                {value?.stopIndicator ? '停用' : '在用'}
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="停用标识"
          valueType="switch"
          search
          order={-3}
          // columnKey={'stopIndicatorIs'}
          hideInTable
          fieldProps={{
            defaultChecked: true,
            onChange: (v) => {
              setShowStop(v);
            },
          }}
          formItemProps={{
            label: '显示停用',
          }}
        />
        <VSTableColumn
          dataIndex={['drgsAuditor', 'staffName']}
          title="DRGS审核专员"
          valueType="text"
          // search
          order={-1}
          columnKey={'staffNameIn'}
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="启用"
          valueType="switch"
          fieldProps={{
            defaultChecked: true,
          }}
          render={(_, value) => {
            // console.log(_, value, 'pppp');
            return (
              <Switch
                onChange={(check) => {
                  // console.log(check, 'pppp');
                  vsf.services
                    ?.UserGroupController_updateMedicalGroupStopIndicator_019d99(
                      {
                        btoParam: { id: value?.id, stopIndicator: !check },
                        extApiParam: {},
                      },
                    )
                    .then((res) => {
                      if (res.code === 200) {
                        message.success(check ? '启用成功' : '停用成功');
                      }
                      vsf.refs.patientGroupTable?.reload();
                    });
                }}
                defaultChecked={!value?.stopIndicator}
              />
            );
          }}
        />
        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          title="功能列表"
        >
          <VSTableAddition
            disabled={
              !selectedPatientGroup?.id || selectedPatientGroup?.disabledAdd
            }
            children="添加"
          />
        </VSTableToolbar>
      </VSPromiseTable>
    </Container>
  );
};
export default definePage(PatientGroupTreeTable);
