import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_getAllByBaseCodeCodeTypeCodeQto_267d9f: {
    method: 'post',
    url: '/api/base-dictionary/get-all-by-base-code-code-type-code-qto',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  DrugDrugController_queryPagefallByDrugProductNameIndexQto_9a2599: {
    method: 'post',
    url: '/api/drug-drug/query-pagefall-by-drug-product-name-index-qto',
    parameterFomatter: (data?: {
      qto: DrugProductNameIndexQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugProductBaseVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugDrugController_queryPagefallByDrugProductNewIndexQtoByDrugProductNewIndexQto_254045:
    {
      method: 'post',
      url: '/api/drug-drug/query-pagefall-by-drug-product-new-index-qto-by-drug-product-new-index-qto',
      parameterFomatter: (data?: {
        qto: DrugProductNewIndexQtoDrugDrugPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugProductListVoDrugDrugEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicRecordHistoryController_updatePatientAllergy_260cc6: {
    method: 'post',
    url: '/api/clinic-record-history/update-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: UpdatePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_getPatientAllergyById_ba748b: {
    method: 'post',
    url: '/api/clinic-record-history/get-patient-allergy-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createPatientAllergy_5b54a1: {
    method: 'post',
    url: '/api/clinic-record-history/create-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: CreatePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
});
