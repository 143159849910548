import { Checkbox, useControllableState, VSTableColumn } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, { forwardRef, useImperativeHandle } from 'react';

import useDataStore from './useDataStore';

const useGroupCheck = (dataSource: any[]) => {
  const [value, setValue, getValue] = useGetState<any>([]);
  return {
    element: {
      dataIndex: ['check'],
      valueType: 'custom',
      render: (...[, record]) => {
        const state = value?.map((item) => item?.id);
        const _value = state?.includes(record?.id);
        let render: any = null;
        if (record?.groupName === 'aspirin-order-text-group-first') {
          const group = (dataSource ?? [])?.filter((...[, index]) =>
            record?.groupIndex?.includes(index),
          );
          render = (
            <Checkbox
              value={_value}
              onChange={(info) => {
                if (info) {
                  setValue?.([
                    ...(value ?? [])?.filter(
                      (item) =>
                        !group
                          ?.map((item: any) => item?.id)
                          ?.includes(item?.id),
                    ),
                    ...group,
                  ]);
                } else {
                  setValue?.(
                    value?.filter(
                      (item) =>
                        !group
                          ?.map((item: any) => item?.id)
                          ?.includes(item?.id),
                    ),
                  );
                }
              }}
            />
          );
        }
        if (!record?.groupName) {
          render = (
            <Checkbox
              value={_value}
              onChange={(info) => {
                if (info) {
                  setValue?.([...(value ?? []), record]);
                } else {
                  setValue?.(value?.filter((item) => item?.id !== record?.id));
                }
              }}
            />
          );
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {render}
          </div>
        );
      },
      title: () => {
        const _value: any =
          value?.length === dataSource?.length &&
          dataSource?.length !== 0 &&
          dataSource?.length !== 0;
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              value={_value}
              indeterminate={
                (value ?? [])?.length > 0 &&
                (value ?? [])?.length < (dataSource ?? []).length
              }
              onChange={(info) => {
                if (info) {
                  setValue?.(dataSource);
                } else {
                  setValue?.([]);
                }
              }}
            />
          </div>
        );
      },
      fixed: 'left',
      width: 30,
    },
    getValue,
  };
};

export default useGroupCheck;
