import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4: {
    method: 'post',
    url: '/api/organization/get-all-by-department-branch-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentBranchInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
