import { createServices } from '@vs/vsf-boot';
export default createServices({
  OperationDictionaryController_createOperationDictionary_3b9dcf: {
    method: 'post',
    url: '/api/base-common/create-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_updateOperationDictionary_b93514: {
    method: 'post',
    url: '/api/base-common/update-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_deleteOperationDictionary_f3b6eb: {
    method: 'post',
    url: '/api/base-common/delete-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
});
