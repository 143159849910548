import {
  Button,
  VSFormItem,
  VSForm,
  VSFormLayout,
  Image,
  Space,
  Select,
  Checkbox,
  Modal,
  message,
  Row,
  Col,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

import readCard from '@/assets/icon/readCard.png';
import vsf, { definePage } from '@vs/vsf-boot';

import { askFixed } from '../../components/func/ask';
import ParModal from './ParModal';
import PrePaymentMasterList from './PrePaymentMasterList';

import './index.less';

const index = (props) => {
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });
  const [totalCharge, setTotalCharge] = useState();
  const [patientInfo, setPatientInfo] = useState();
  const [prePaymentDetailBaseDtoList, setPrePaymentDetailBaseDtoList] =
    useState();
  const [selectTableData, setSelectTableData] = useState();
  const [payadvance, setPayadvance] = useState(false);
  const [flag, setFlag] = useState(true);
  const [dataSource, setDataSource] = useState();
  const onChange = (param) => {
    setSelectTableData(param);
  };

  // 患者ID搜索
  const { data: getInfo, run } = useRequest(
    async (params) => {
      // console.log(params,'manage_run')
      const res =
        await vsf.services?.FinancePrePaymentController_getPrePaymentInfo_aa8a03?.(
          {
            displayId: params?.displayId,
            prePaymentType: 'INP',
          },
        );
      // if (!res?.data?.wardCode) {
      //   message?.error('未查询到该患者所在的病区');
      //   return false;
      // }
      if (res?.code === 200) {
        vsf?.refs?.prePaymentInfo?.setFieldsValue({ ...res?.data });
        vsf?.refs?.prePaymentInfo1?.setFieldsValue({
          ...res?.data,
          bedLabel: `${res?.data?.bedLabel}${res?.data?.name}`,
        });
        vsf?.refs?.prePaymentInfo2?.setFieldsValue({ ...res?.data });
        bedDataRun(res?.data?.wardCode);
        setPatientInfo({
          ...res?.data,
          totalPrePayment: Number(res?.data?.totalPrePayment).toFixed(2),
          selfPaymentTotalCost: Number(res?.data?.selfPaymentTotalCost).toFixed(
            2,
          ),
          totalCost: Number(res?.data?.totalCost).toFixed(2),
          yuer: Number(
            res.data?.totalPrePayment - res?.data?.selfPaymentTotalCost,
          ).toFixed(2),
        });
      } else {
        setPatientInfo(undefined);
        setTotalCharge(undefined);
        setDataSource(undefined);
        setSelectTableData(undefined);
        vsf?.refs?.prePaymentInfo1?.resetFields();
      }
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const { data: wardNameList, run: wardNameRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
          {
            searchCode: params?.searchCode,
            clinicType: params?.clinicType,
          },
        );

      return res?.data?.result;
    },
    {
      manual: true,
    },
  );

  // 作废按钮
  const { data: cancelData, run: invalidDataRun } = useRequest(
    async (param) => {
      const res =
        await vsf.services?.FinancePrePaymentController_cancelPrePaymentMaster_85130c?.(
          param,
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        message.success('作废交易成功');
      }
      return res?.data;
    },
    {
      manual: true,
    },
  );

  // 选择病区名称
  const { data: bedData, run: bedDataRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
          {
            bedWardId: params,
          },
        );
      vsf?.refs?.prePaymentInfo?.setFieldsValue({ bedId: res.data.bedId });

      const data = (res?.data ?? [])?.map((item) => {
        return { ...item, bedLabel: `${item?.bedLabel}${item?.patientName}` };
      });
      return data;
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (cancelData) {
      const file = vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
      const pram = {
        displayId: file,
        patientId: undefined,
        visitId: undefined,
        prePaymentType: undefined,
      };
      run(pram);
    }
  }, [cancelData]);
  useMount(() => {
    wardNameRun();
    // bedDataRun()
  });
  useEffect(() => {
    getInfo && setDataSource(getInfo);
  }, [getInfo]);
  const acceptAdvance = async () => {
    vsf?.refs?.acceptAdvanceForm?.validateFields().then(async (item) => {
      const needAccept = vsf?.refs?.acceptAdvanceForm?.getFieldsValue();
      if (!needAccept?.totalCharge) {
        message.error('请输入预交金');
        return;
      }
      if (flag) {
        //收费
        if (needAccept?.totalCharge <= 0) {
          message.error('预交金不能是0和负数');
          return;
        }
        const accept =
          await vsf.services?.FinancePrePaymentController_savePrePaymentMaster_59f4c5?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              savePrePaymentMasterBvo: {
                patientId: dataSource?.patientId,
                inpVisitId: getInfo?.visitId,
                prePaymentSource: 'WINDOW',
                prePaymentType: 'INP',
                prePaymentStatus: 'CASH_IN_RECEIVE_PRE_PAYMENT',
                prePaymentAmount: Number(needAccept?.totalCharge),
                prePaymentDetailBvoList: [
                  {
                    serialNumber: '1',
                    paySourceType: '1',
                    paymentType: needAccept?.paymentDetail?.paymentType,
                    paymentAmount: Number(needAccept?.totalCharge),
                  },
                ],
              },
            },
          );
        if (accept?.code === 200) {
          message.success('预交金缴费成功');
          const file = vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
          const param = {
            displayId: file,
            patientId: undefined,
            visitId: undefined,
            prePaymentType: undefined,
          };
          run(param);
          setPayadvance(false);
        }
      } else {
        const out =
          await vsf.services?.FinancePrePaymentController_refundPrePaymentMaster_a09098?.(
            {
              savePrePaymentMasterBvo: {
                ...prePaymentDetailBaseDtoList,
              },
            },
          );
        if (out?.code === 200) {
          message.success('预交金退费成功');
          const file = vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
          const param = {
            displayId: file,
            patientId: undefined,
            visitId: undefined,
            prePaymentType: undefined,
          };
          run(param);
          setPayadvance(false);
        }
      }
    });
  };
  return (
    <div className="pre-paymen-layout">
      <div className="top">
        <div className="pre-paymen-top-left">
          <VSForm vsid="76446" id="prePaymentInfo1">
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['displayId']}
                label="患者ID"
                valueType="text"
                fieldProps={{
                  onKeyPress: (e) => {
                    if (e.key === 'Enter') {
                      const file =
                        vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
                      const param = {
                        displayId: file,
                        patientId: undefined,
                        visitId: undefined,
                        prePaymentType: undefined,
                      };
                      run(param);
                      // wardNameRun();
                    }
                  },
                }}
              />
              <VSFormItem
                name={['patientIdIs']}
                label="证件及编号"
                valueType="text"
                fieldProps={{
                  // width: '480px',
                  style: { paddingLeft: '10px' },
                  className: 'patientIdIs',
                  addonBefore: (
                    <>
                      <Select
                        // width={150}
                        className="patientIdIs-select"
                        dataSource={certificateData}
                        fieldNames={{ label: 'name', value: 'code' }}
                      />
                    </>
                  ),

                  suffix: (
                    <div
                      className="suffix-readCard"
                      onClick={() => {
                        message.success('读卡中');
                      }}
                    >
                      <div className="suffix-cardImg">
                        <Image
                          src={readCard}
                          className="suffix-readCard-image"
                          // style={{ width: '20px', height: '20px' }}
                          preview={false}
                        />
                      </div>
                      <div className="suffix-cardText">读卡</div>
                    </div>
                  ),
                  placeholder: '请读卡',
                }}
              />
            </VSFormLayout>

            <VSFormLayout key="2" columnCount={2}>
              <VSFormItem
                name={['wardName']}
                label="病区名称"
                valueType="codeTableSelect"
                fieldProps={{
                  dataSource: wardNameList,
                  fieldNames: { label: 'departmentName', value: 'id' },
                  showSearch: true,
                  searchKey: 'searchCode',
                }}
                fieldNames={{ label: 'departmentName', value: 'id' }}
              />
              {/* <VSFormItem
                name={['wardName']}
                label="病区名称"
                valueType="select"
                dataSource={wardNameList}
                fieldNames={{ label: 'departmentName', value: 'id' }}
                fieldProps={{
                  onSelect: (v) => {
                    bedDataRun(v?.value);
                  },
                }}
              /> */}

              <VSFormItem
                name={['bedLabel']}
                label="床号"
                valueType="select"
                dataSource={bedData}
                fieldNames={{ label: 'bedLabel', value: 'patientDisplayId' }}
                fieldProps={{
                  onSelect: (v) => {
                    const param = {
                      displayId: v.value,
                      patientId: undefined,
                      visitId: undefined,
                      prePaymentType: undefined,
                    };
                    run(param);
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div className="pre-paymen-top-center">
          {/* <Row style={{ marginTop: '6px' }}> */}
          <Row>
            <Col span={5}>姓名:{patientInfo?.name || ''}</Col>
            <Col span={5}>
              性别:
              {patientInfo &&
                (vsf.stores.dbenums.enums.SEX_DICT?.find(
                  (item) => item?.value === patientInfo.gender,
                )?.label ??
                  '')}
            </Col>
            <Col span={5}>
              预交金总额:{patientInfo?.totalPrePayment || '0.00'}
            </Col>
            <Col span={5} style={{ display: 'flex' }}>
              <div>个人自付:</div>
              <div>
                {(patientInfo?.selfPaymentTotalCost || '0.00') >= 0 ? (
                  <div style={{ color: '#3276E8' }}>
                    {patientInfo?.selfPaymentTotalCost || '0.00'}
                  </div>
                ) : (
                  <div style={{ color: '#E34A4E' }}>
                    {patientInfo?.selfPaymentTotalCost || '0.00'}
                  </div>
                )}
              </div>
            </Col>
            <Col span={4}>
              账户状态:
              {patientInfo?.sealAccountantIndicator ? '冻结' : '未冻结'}
            </Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col span={5}>
              费别:
              {patientInfo &&
                (vsf.stores.dbenums.enums.CHARGE_TYPE_DICT?.find(
                  (item) => item?.value === patientInfo.chargeType,
                )?.label ??
                  '')}
            </Col>
            {/* <Col span={5}>
              性别:
              {patientInfo&&(vsf.stores.dbenums.enums.SEX_DICT?.find(
                (item) => item?.value === patientInfo.gender,
              )?.label ?? '')}
            </Col> */}
            <Col span={5}>
              人员类别:
              {patientInfo &&
                (vsf.stores.dbenums.enums.IDENTITY_DICT?.find(
                  (item) => item?.value === patientInfo.identity,
                )?.label ??
                  '')}
            </Col>
            <Col span={5}>医疗费用总额:{patientInfo?.totalCost || '0.00'}</Col>
            <Col span={9} style={{ display: 'flex' }}>
              <div>预交金余额:</div>
              <div>
                {(patientInfo?.yuer || '0.00') >= 0 ? (
                  <div style={{ color: '#3276E8' }}>
                    {patientInfo?.yuer || '0.00'}
                  </div>
                ) : (
                  <div style={{ color: '#E34A4E' }}>
                    {patientInfo?.yuer || '0.00'}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="bottom">
        <div style={{ flex: '1' }}>
          <PrePaymentMasterList
            dataSource={dataSource?.prePaymentMasterVoList ?? []}
            onChange={onChange}
            selectTableData={selectTableData}
          />
        </div>
        <div style={{ marginTop: '7px', height: '50px', textAlign: 'right' }}>
          <Space size={4}>
            <Button
              onClick={() => {
                message.destroy();
                vsf?.refs?.prePaymentInfo1?.resetFields();
                setDataSource(undefined);
                message.success('清屏成功');
              }}
            >
              清屏
            </Button>
            <Button
              onClick={async () => {
                if (getInfo?.sealAccountantIndicator) {
                  const res =
                    await vsf.services?.FinanceBillingRecordController_unsealAccountById_f73ea8?.(
                      {
                        patientBillingRecordId: getInfo?.patientBillingRecordId,
                      },
                    );
                  if (res?.code === 200) {
                    message.success('解冻成功');
                    const file =
                      vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
                    const param = {
                      displayId: file,
                      patientId: undefined,
                      visitId: undefined,
                      prePaymentType: undefined,
                    };
                    run(param);
                  }
                  return res?.data;
                }
              }}
            >
              解冻
            </Button>
            <Button disabled={dataSource ? false : true}>补打</Button>
            <Button
              type="primary"
              onClick={async () => {
                if (!selectTableData) {
                  message.error('请选择数据');
                  return;
                }
                const out =
                  await vsf.services?.FinancePrePaymentController_prePaymentPreRefund_b971d1?.(
                    {
                      prePaymentMasterId: selectTableData?.id,
                    },
                  );
                if (out?.code === 200) {
                  Modal.confirm({
                    content: '确定作废交易',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                      const param = {
                        savePrePaymentMasterBvo: out?.data,
                      };
                      invalidDataRun(param);
                    },
                  });
                }
              }}
              disabled={dataSource ? false : true}
            >
              作废交易
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                if (!selectTableData) {
                  message.error('请选择数据');
                  return;
                }
                const out =
                  await vsf.services?.FinancePrePaymentController_prePaymentPreRefund_b971d1?.(
                    {
                      prePaymentMasterId: selectTableData?.id,
                    },
                  );
                if (out?.code === 200) {
                  setFlag(0);
                  setPayadvance(true);
                  setPrePaymentDetailBaseDtoList(out?.data);

                  // getFieldValue
                  const file =
                    vsf?.refs?.prePaymentInfo1.getFieldValue('displayId');
                  const param = {
                    displayId: file,
                    patientId: undefined,
                    visitId: undefined,
                    prePaymentType: undefined,
                  };
                  run(param);
                  setTotalCharge(out?.data?.prePaymentAmount);
                  // vsf?.refs?.acceptAdvanceForm?.setFieldsValue({
                  //   totalCharge: out?.data?.prePaymentAmount,
                  // });
                }
              }}
              disabled={dataSource ? false : true}
            >
              退预交金
            </Button>
            <Button
              type="primary"
              onClick={() => {
                vsf?.refs?.acceptAdvanceForm?.resetFields();
                setFlag(1);
                setPayadvance(true);
              }}
              disabled={dataSource ? false : true}
            >
              收预交金
            </Button>
          </Space>
        </div>
        <Modal
          open={payadvance}
          title={<div style={{ color: '#3276E8' }}>{'支付'}</div>}
          onCancel={() => {
            // setOpen(false);
            setPayadvance(false);
          }}
          okText={'确定'}
          cancelText={'取消'}
          width={1000}
          footer={
            <div>
              <Space size={4}>
                <Button
                  onClick={() => {
                    setPayadvance(false);
                  }}
                >
                  取消
                </Button>
                <Button disabled={!flag}>查询为记账交易</Button>
                <Button type="primary" onClick={acceptAdvance}>
                  确认
                </Button>
              </Space>
            </div>
          }
        >
          {/* <ParModal /> */}
          <ParModal
            payParamData={dataSource}
            flag={flag}
            totalCharge={totalCharge}
          />
        </Modal>
      </div>
    </div>
  );
};
export default definePage(index);
