import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

import Table from './table';

const Index = (props) => {
  const {
    user,
    common: { currentApplication },
  } = props?.stores || {};

  return (
    <div style={{ width: '100%' }}>
      <Table />
    </div>
  );
};
export default definePage(Index);
