import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  InputProps,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React from 'react';

import { SelectCode } from '..';
import { useInputEnter } from '../dosage/utils';
import { askFixed } from '../func/ask';

/**
 * 住院医嘱配送地点选择
 */
let DeliveryDepartmentSelect: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const list = vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;

  return (
    <Select
      {...props}
      value={value}
      showSearch
      ref={ref}
      dataSource={async () => {
        const res =
          await vsf.services.ClinicRecordOrderDrugController_getDeliveryDepartmentList_bc5ead(
            {
              code: record?.useDescription,
            },
          );
        // return (res?.data ?? [])?.map((item) => ({
        //   label: item?.departmentName,
        //   value: item?.id,
        // }));
        return res?.data ?? [];
      }}
      onChange={onChange}
      fieldNames={{
        label: 'departmentName',
        value: 'id',
      }}
      {...fieldEnterProps}
      {...rest}
    />
  );
};

DeliveryDepartmentSelect.displayName = 'deliveryDepartmentSelect';

DeliveryDepartmentSelect = React.forwardRef(DeliveryDepartmentSelect);

export const WithPreviewComponent = definePage((props: any) => {
  const { record, value } = props || {};
  return <>{value?.departmentName ?? value}</>;
});

export default compose(
  withRef(),
  withField<any>({
    name: 'deliveryDepartmentSelect',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: WithPreviewComponent,
    },
  ),
)(DeliveryDepartmentSelect) as typeof DeliveryDepartmentSelect;
