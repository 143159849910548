import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
// 计费模板
export const getAllByPricePattern = async (params) => {
  const res =
    await vsf.services?.PriceListController_getAllByPricePatternQto_8ee5de?.({
      // TODO: 请检查以下这部分传参是否符合需求
      qto: {
        patternRangeTypeIn: [],
      },
    });
  if (res?.code !== 200 || res?.data?.length <= 0)
    return message.warning('计费模板查询失败。');
  return res?.data?.map((element) => ({
    label: element?.patternName,
    value: element?.id,
  }));
};
export const getPricePatternMasterId = async (params) => {
  const res =
    await vsf.services?.PriceListController_getByPricePatternByPricePatternMasterId_0dd128?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        pricePatternMasterId: params,
      },
    ); // TODO: 你可能需要整理返回数据格式};
  if (res?.code !== 200 || res?.data?.length <= 0) return [];
  return res?.data;
};
