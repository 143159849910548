import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpPreExamController_deleteCountErpAccidentEventRecord_48654f: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/delete-count-erp-accident-event-record',
    parameterFomatter: (data?: {
      btoParam: DeleteAccidentEventRecordDetailBtoClinicErpPreExamServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpAccidentEventRecordMasterAddVoClinicErpPreExamEntranceWebVo,
    ) => data,
  },
  ClinicErpPreExamController_queryErpAccidentEventRecordMasterSearchVoByErpAccidentEventRecordMasterEventNameQto_93a2df:
    {
      method: 'post',
      url: '/api/clinic-erp-pre-exam/query-erp-accident-event-record-master-search-vo-by-erp-accident-event-record-master-event-name-qto',
      parameterFomatter: (data?: {
        qto: ErpAccidentEventRecordMasterEventNameQtoClinicErpPreExamPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpAccidentEventRecordMasterSearchVoClinicErpPreExamEntranceWebVo[],
      ) => data,
    },
  ClinicErpPreExamController_saveSingleErpAccidentEventRecordFuncFlowExecutor_585f54:
    {
      method: 'post',
      url: '/api/clinic-erp-pre-exam/save-single-erp-accident-event-record-func-flow-executor',
      parameterFomatter: (data?: {
        createOrUpdatePatientBto: CreateOrUpdatePatientBtoPatientMainServiceBto;
        saveErpVisitForPreExamBto: SaveErpVisitForPreExamBtoClinicErpVisitServiceBto;
        createErpPreExamRecordBto: CreateErpPreExamRecordBtoClinicErpPreExamServiceBto;
        createPatientChiefComplaintVsJudgeBtoList: CreatePatientChiefComplaintVsJudgeBtoClinicErpRecordServiceBto[];
        saveErpAccidentEventRecordMasterBto: SaveErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
        saveErpAccidentEventRecordDetailBto: SaveErpAccidentEventRecordDetailBtoClinicErpPreExamServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: string) => data,
    },
  ClinicErpPreExamController_deleteErpAccidentEventRecord_4f6d91: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/delete-erp-accident-event-record',
    parameterFomatter: (data?: {
      btoParam: DeleteErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicErpPreExamController_queryErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchQto_b1a675:
    {
      method: 'post',
      url: '/api/clinic-erp-pre-exam/query-erp-accident-event-record-detail-batch-search-vo-by-erp-accident-event-record-detail-batch-search-qto',
      parameterFomatter: (data?: {
        qto: ErpAccidentEventRecordDetailBatchSearchQtoClinicErpPreExamPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpAccidentEventRecordDetailBatchSearchVoClinicErpPreExamEntranceWebVo[],
      ) => data,
    },
  ClinicErpPreExamController_addCountErpAccidentEventRecord_a23090: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/add-count-erp-accident-event-record',
    parameterFomatter: (data?: {
      btoParam: AddCountErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpAccidentEventRecordMasterAddVoClinicErpPreExamEntranceWebVo,
    ) => data,
  },
  ClinicErpPreExamController_saveErpAccidentEventRecordFuncFlowExecutor_bb1f34:
    {
      method: 'post',
      url: '/api/clinic-erp-pre-exam/save-erp-accident-event-record-func-flow-executor',
      parameterFomatter: (data?: {
        createOrUpdatePatientBtoList: CreateOrUpdatePatientBtoPatientMainServiceBto[];
        saveErpVisitForPreExamBtoList: SaveErpVisitForPreExamBtoClinicErpVisitServiceBto[];
        createErpPreExamRecordBtoList: CreateErpPreExamRecordBtoClinicErpPreExamServiceBto[];
        createPatientChiefComplaintVsJudgeBtoList: CreatePatientChiefComplaintVsJudgeBtoClinicErpRecordServiceBto[];
        saveErpAccidentEventRecordMasterBto: SaveErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
        saveErpAccidentEventRecordDetailBtoList: SaveErpAccidentEventRecordDetailBtoClinicErpPreExamServiceBto[];
      }) => data,
      responseFormatter: (_, __, data?: number[]) => data,
    },
});
