// import PatientTable from './patient';
import './index.less';

import { definePage } from '@vs/vsf-boot';
import React from 'react';

import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import PatientTable from '@/pages/Index/components/patient_list';

import OrderTable from './order';

const MedicalOrdersShow = (props) => {
  const [value] = useOnChangeValue({}, (v) => {
    if (v) return v;
    return {};
  });
  const [dataSource] = useOnChangeValue([]);
  return (
    <Container
      layout={{
        items: [
          {
            block: isMinScreen ? '200px' : '300px',
            bgColor: 'var(--background)',
            padding: '15px',
            gap: '10px',
          },
          {},
        ],
      }}
    >
      <PatientTable
        type="page"
        onDataSourceChange={dataSource?.onChange}
        {...value}
      />
      <OrderTable dataSource={dataSource?.value} {...value} />
    </Container>
  );
};

export default definePage(MedicalOrdersShow);
