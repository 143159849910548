import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns } from '@vs/vsf-kit';
import React, { useState } from 'react';
import StaffList from './table';
import DepartmentTree from './tree';
import Container from '@/module/Container';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMinScreen } from '@/config';
const StaffManagerAdmin = (props) => {
  /**
   * departmentId
   * @type {object}
   */
  const [departmentId, setDepartmentId] = useState();
  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            bgColor: 'var(--background)',
            block: 1,
          },
        ],
      }}
    >
      <DepartmentTree
        onChange={(_value) => {
          setDepartmentId({ ..._value });
        }}
      />

      <StaffList value={departmentId} />
    </Container>
  );
};
export default definePage(StaffManagerAdmin);
