import React from 'react';
import { VSTable, VSTableColumn } from '@vs/vsf-kit';
import { getScrollY, getScrollYRem } from '@/utils';
import Icon from '@/components/TreeMenu/icon';

export default function checkcontext(props) {
  const { data, setactiveList, tabValue } = props;
  return (
    <div className="vspromiseTable">
      <VSTable
        value={data}
        rowSelection={{
          selectType: 'multiple',
          onSelectedRowsChange: setactiveList,
        }}
        rowClick={{
          rowClickType: 'select',
        }}
        bordered
        scroll={{
          y: getScrollYRem(24),
        }}
        pagination={false}
        id="listTable"
      >
        <VSTableColumn
          title="急"
          dataIndex={['emergencyIndicator']}
          width={50}
          valueType="text"
          className="width70"
          render={(dom, entity, index, action, schema) => {
            if (!!entity.emergencyIndicator) {
              return (
                <Icon
                  type="icon-a-Frame1517"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              );
            }
            return;
          }}
        />
        <VSTableColumn
          title="状态"
          dataIndex={['resultStatus']}
          valueType="treeSelect"
          dataSource={
            tabValue === 'EXAM'
              ? vsf?.stores?.dbenums?.enums?.EXAM_RESULT_STATUS_DICT
              : vsf?.stores?.dbenums?.enums?.LAB_RESULT_STATUS_DICT
          }
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          title="检查检验项目"
          dataIndex={['orderText']}
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          title="申请时间"
          dataIndex={['submitDateTime']}
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          title="申请医生"
          dataIndex={['doctor', 'staffName']}
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
      </VSTable>
    </div>
  );
}
