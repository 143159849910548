import './index.less';

import { DoubleRightOutlined } from '@ant-design/icons';
import {
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import { CheckReportBasicInfo } from '@/pages/Index/cpoe/center/left/history/detail/index';

const Index = (props) => {
  const { reportDetail } = props;

  const [isShowDetail, setIsShowDetail] = useState(true);
  const [currentReport, setCurrentReport] = useState<number>();
  const [arr, setArr] = useState<any>();

  useEffect(() => {
    setArr(
      reportDetail?.patientJourneyOrderExamReportList?.map((item) => {
        return { ...item, show: true };
      }),
    );
  }, [reportDetail]);

  const handelShow = (index?) => {
    const tempList = cloneDeep(arr);
    setArr(
      tempList?.map((item, _idx) => ({
        ...item,
        show: _idx === index ? !item.show : item.show,
      })),
    );
  };
  console.log(CheckReportBasicInfo, reportDetail, 'CheckReportBasicInfo');

  return (
    <div className="checked_detail">
      <div style={{ position: 'sticky', top: 0 }}>
        <div className="checked_detail_title">
          {reportDetail?.orderVoList?.[0]?.orderText}
        </div>
        <div className="checked_detail_doctor_name">
          {CheckReportBasicInfo?.map((item) => {
            return (
              <div className="item" key={item?.value}>
                <div className="label">{item?.label}：</div>
                <div>
                  {item?.value !== 'doctorName' &&
                  reportDetail?.orderVoList?.[0]?.[item?.value]
                    ? dayjs(
                        reportDetail?.orderVoList?.[0]?.[item?.value],
                      ).format('YYYY-MM-DD HH:mm')
                    : reportDetail?.orderVoList?.[0]?.[item?.value]}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <div>
          <Block titleBackgroundColor="#DBEDFF" title="申请信息">
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">简要病史:</div>
              <div className="checked_detail_content_content">
                {
                  reportDetail?.orderVoList?.[0]
                    ?.briefDiseaseHistoryPhysicalExam
                }
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">诊断:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.orderVoList?.[0]?.diagnosis}
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">执行科室:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.orderVoList?.[0]?.performDepartmentName}
              </div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">注意事项:</div>
              <div className="checked_detail_content_content">
                {reportDetail?.orderVoList?.[0]?.notice}
              </div>
            </div>
            {/* <div className="checked_detail_content">
              <div className="checked_detail_content_label">作用:</div>
              <div className="checked_detail_content_content"></div>
            </div>
            <div className="checked_detail_content">
              <div className="checked_detail_content_label">适应症:</div>
              <div className="checked_detail_content_content"></div>
            </div> */}
          </Block>
        </div>
        {/* {reportDetail?.examineReportDto && ( */}
        <div>
          {arr?.map((item, index) => {
            return (
              <Block
                onClick={() => {
                  handelShow(index);
                }}
                key={item?.id}
                titleBackgroundColor="#DBEDFF"
                headerStyle={{ cursor: 'pointer' }}
                title={'报告' + (index + 1)}
                extra={
                  <div style={{ display: 'flex', paddingRight: '10px' }}>
                    <div
                      style={{ display: item?.reportUrl ? '' : 'none' }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className="checked_detail_view_img"
                    >
                      查看图像
                    </div>
                    <DoubleRightOutlined
                      className={classnames(
                        item?.show
                          ? 'checked_detail_double_up_outlined'
                          : 'checked_detail_double_down_outlined',
                      )}
                      style={{ color: '#3276E8' }}
                    />
                  </div>
                }
              >
                <div style={{ display: item?.show ? 'block' : 'none' }}>
                  <div className="checked_detail_content">
                    <div className="checked_detail_content_label">
                      检查所见:
                    </div>
                    <div className="checked_detail_content_content">
                      {item?.description}
                    </div>
                  </div>
                  <div className="checked_detail_content">
                    <div className="checked_detail_content_label">印象:</div>
                    <div className="checked_detail_content_content">
                      {/* 两侧胸廓对称，气管居中，两肺纹理增多，两肺多发散在斑片状高密度影，右上为著，可见空洞形成。气管及主要支气管通畅。纵隔淋巴结肿大。胸腔内未见积液征。心景未见异常。左侧第3、4、6肋骨陈旧性骨折。 */}
                      {item?.impression}
                    </div>
                  </div>
                  <div className="checked_detail_content">
                    <div className="checked_detail_content_label">建议:</div>
                    <div className="checked_detail_content_content">
                      {item?.advise}
                    </div>
                  </div>
                  {/* <div className="checked_detail_content">
                      <div className="checked_detail_content_label">
                        检查参数:
                      </div>
                      <div className="checked_detail_content_content">
                        {reportDetail?.examineReportDto?.examineParameter}
                      </div>
                    </div>
                    <div className="checked_detail_content">
                      <div className="checked_detail_content_label">
                        是否阳性:
                      </div>
                      <div className="checked_detail_content_content">
                        {reportDetail?.examineReportDto?.positive ? '是' : '否'}
                      </div>
                    </div>
                    <div className="checked_detail_content">
                      <div className="checked_detail_content_label">
                        使用仪器:
                      </div>
                      <div className="checked_detail_content_content">
                        {reportDetail?.examineReportDto?.usedInstrument}
                      </div>
                    </div>
                    <div className="checked_detail_content">
                      <div className="checked_detail_content_label">
                        报告中图像编号:
                      </div>
                      <div className="checked_detail_content_content">
                        {reportDetail?.examineReportDto?.graphicsNum}
                      </div>
                    </div> */}
                </div>
              </Block>
            );
          })}
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default Index;
