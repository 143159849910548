import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Section,
  Divider,
  VSTableColumn,
  VSPromiseTable,
  VSTable,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Input, Tooltip, Select } from 'antd';
import { SearchOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { clearTable, setMaxHeight } from './methods';
import { debounce, includes, set } from 'lodash';
import { getScrollY } from '@/utils';
import { ButtonAndKeyDown, DragLine } from '@/pages/Index/components';

// 医嘱费用
const DoctorsAdvice = (props) => {
  // 医嘱信息
  const [onDoctorsAdviceMsg, setDoctorsAdviceMsg] = useState({
    totalManualBilling: null,
    totalNormalBilling: null,
    totalNotBilling: null,
  });
  // 医嘱信息查询条件；
  // orderClass: params?.search?.orderClass,
  //   repeatIndicator: params?.search?.repeatIndicator,
  //   orderTextLike: params?.search?.orderTextLike,
  const [inquireParams, setInquireParams] = useState({});
  /**
   * convertQueryOrderInfoForNurseStationRes2OrderBillingDetailOrderList
   * @param {any} input
   * @returns {any}
   */
  const convertQueryOrderInfoForNurseStationRes2OrderBillingDetailOrderList = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    setDoctorsAdviceMsg({
      totalManualBilling: _.get(input, `data[${0}.totalManualBilling]`),
      totalNormalBilling: _.get(input, `data[${0}.totalNormalBilling]`),
      totalNotBilling: _.get(input, `data[${0}.totalNotBilling]`),
    });
    output.push(
      ...(_.get(input, `data[${0}.orderInfo]`)?.map((d, i) => {
        const obj = {}; // TODO 请确认下行字段是否符合逻辑
        obj.id = _.get(d, `id`); // TODO 请确认下行字段是否符合逻辑
        obj.inpVisitId = _.get(d, `inpVisit.id`); // TODO 请确认下行字段是否符合逻辑
        obj.orderText = _.get(d, `orderText`); // TODO 请确认下行字段是否符合逻辑
        obj.dosage = _.get(d, `dosage`); // TODO 请确认下行字段是否符合逻辑
        obj.administration = _.get(d, `administration`); // TODO 请确认下行字段是否符合逻辑
        obj.frequency = _.get(d, `frequency`); // TODO 请确认下行字段是否符合逻辑
        obj.performSchedulePlan = _.get(
          d,
          `orderPerformDto.performSchedulePlan`,
        ); // TODO 请确认下行字段是否符合逻辑
        obj.drugBillingAttribute = _.get(
          d,
          `orderDrugDto.drugBillingAttribute`,
        ); // TODO 请确认下行字段是否符合逻辑
        obj.startPerformDateTime = _.get(d, `startPerformDateTime`);
        obj.lastPerformDateTime = _.get(d, `lastPerformDateTime`); // Date 最后执行时间
        // TODO 请确认下行字段是否符合逻辑
        obj.lastBillingDateTime = _.get(
          d,
          `orderPerformDto.lastBillingDateTime`,
        ); // TODO 请确认下行字段是否符合逻辑
        obj.stopOrderDateTime = _.get(d, `stopOrderDateTime`); // TODO 请确认下行字段是否符合逻辑
        obj.orderStatus = _.get(d, `orderStatus`);
        obj.repeatIndicator = d?.repeatIndicator ? '长' : '临';
        return obj;
      }) ?? []),
    );
    return output;
  };
  /**
   * convertGetOrderBillingInpBillingDetailGroupInfoRes2OrderBillingDetailGroupList
   * @param {any} input
   * @returns {any}
   */
  const convertGetOrderBillingInpBillingDetailGroupInfoRes2OrderBillingDetailGroupList =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ...(_.get(input, 'data')?.map((d, i) => {
          const obj = {};
          obj.id = _.get(d, 'itemId');
          obj.itemClass = _.get(d, 'itemClass');
          obj.itemId = _.get(d, 'itemId');
          obj.itemName = _.get(d, 'itemName');
          obj.itemSpecification = _.get(d, 'itemSpecification');
          obj.price = _.get(d, 'price');
          obj.totalAmount = _.get(d, 'totalAmount');
          return obj;
        }) ?? []),
      );
      return output;
    };
  const orderBillingDetailOrderListOnFetch = useCallback(async () => {
    if (!props?.onUserInfo?.inpVisitId) return;
    let params = { inpVisitId: props?.onUserInfo?.inpVisitId };
    for (let index in inquireParams) {
      if (inquireParams[index] !== 'undefined')
        params[index] = inquireParams[index];
    }
    // const res =
    //   await vsf?.services?.NurseBillingController_queryOrderInfoForNurseStation_c4461b?.(
    //     params,
    //   ); // TODO: 请确认入参来源及出参用法
    const res =
      await vsf?.services?.NurseBillingController_queryOrderInfoForBillingStation_23262f?.(
        params,
      ); // TODO: 请确认入参来源及出参用法
    // TODO: 请确认入参来源及出参用法
    const output =
      convertQueryOrderInfoForNurseStationRes2OrderBillingDetailOrderList?.(
        res,
      );
    return { data: output, total: output?.length };
  }, [props?.onUserInfo, inquireParams]);

  const orderBillingDetailGroupListOnFetch = useCallback(
    async (params) => {
      if (!props?.onUserInfo?.inpVisitId || !props?.onDoctorAdviceOrderId) {
        clearTable('orderBillingDetailGroupList');
        return;
      }
      const res =
        await vsf?.services?.InpBillingDetailController_getOrderBillingInpBillingDetailGroupInfo_5b1e07?.(
          {
            inpVisitId: props?.onUserInfo?.inpVisitId,
            orderIdList: [props?.onDoctorAdviceOrderId],
          },
        ); // TODO: 请确认入参来源及出参用法
      const output =
        convertGetOrderBillingInpBillingDetailGroupInfoRes2OrderBillingDetailGroupList?.(
          res,
        );
      return { data: output ?? [], total: output?.length ?? 0 };
    },
    [props?.onDoctorAdviceOrderId],
  );

  // 医嘱信息元素；列表宽度/高度
  const DoctoesMsgRef = useRef(null);
  const ComputeTopScroll = () => ({
    y: setMaxHeight('detailsTable', DoctoesMsgRef?.current?.offsetHeight),
    x: 2280,
  });
  const ComputeButtomScroll = () => ({
    y: setMaxHeight('abvice', 48),
  });
  return (
    <>
      <div className="DoctorsAdvice">
        <div className="doctor">
          <div id="detailsTable">
            <VSPromiseTable
              pagination={false}
              scroll={ComputeTopScroll()}
              editable={{ editType: 'none' }}
              vsid="15954"
              id="orderBillingDetailOrderList"
              onFetch={orderBillingDetailOrderListOnFetch}
              onRow={(record) => {
                return {
                  onClick: () => props?.setDoctorAdviceOrderId(record.id),
                };
              }}
              rowClassName={(node) => {
                return node?.id === props?.onDoctorAdviceOrderId
                  ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
                  : 'aspirin-vs-table-row-box aspirin-row-select-box';
              }}
              searchFormRender={(props, dom) => {
                return (
                  <div className="DisFlex msg" ref={DoctoesMsgRef}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <table>
                        <th>
                          <tr>医嘱信息</tr>
                        </th>
                        <tr>
                          <td>正常计价：</td>
                          <td>{onDoctorsAdviceMsg?.totalManualBilling}</td>
                        </tr>
                        <tr>
                          <td>手工计价：</td>
                          <td>{onDoctorsAdviceMsg?.totalNormalBilling}</td>
                        </tr>
                        <tr>
                          <td>不计价：</td>{' '}
                          <td>{onDoctorsAdviceMsg?.totalNotBilling}</td>
                        </tr>
                      </table>
                    </div>
                    <div>
                      <table aligen="center" valigen="center">
                        <tr>
                          <Tooltip title="输入医嘱名称后请按回车键">
                            <Input
                              allowClear
                              placeholder="请输入医嘱名称"
                              prefix={<SearchOutlined />}
                              onPressEnter={(event) => {
                                // orderTextLike
                                setInquireParams({
                                  ...inquireParams,
                                  orderTextLike: event?.target?.value,
                                });
                              }}
                            />
                          </Tooltip>
                        </tr>
                        <tr>
                          <Select
                            allowClear
                            showSearch
                            placeholder="医嘱类别"
                            defaultValue={'undefined'}
                            options={[
                              { label: '请选择医嘱类别', value: 'undefined' },
                              { label: '西药', value: 'DRUG' },
                              { label: '中药', value: 'HERB' },
                              { label: '检验', value: 'LAB' },
                              { label: '检查', value: 'EXAM' },
                              { label: '治疗', value: 'TREAT' },
                              { label: '手术', value: 'OPERATION' },
                              { label: '麻醉', value: 'ANESTHESIA' },
                              { label: '护理', value: 'NURSING' },
                              { label: '膳食', value: 'MEAL' },
                              { label: '营养', value: 'NUTRITION' },
                              { label: '会诊', value: 'CONSULTATION' },
                              { label: '用血', value: 'BLOOD' },
                              { label: '其他', value: 'OTHER' },
                              { label: '病理', value: 'PATHOLOGY' },
                            ]}
                            onChange={(event) =>
                              setInquireParams({
                                ...inquireParams,
                                orderClass: event,
                              })
                            }
                            style={{
                              width: '158px',
                            }}
                          />
                        </tr>
                        <tr>
                          <Select
                            allowClear
                            showSearch
                            placeholder="医嘱长临"
                            defaultValue={'undefined'}
                            options={[
                              { label: '请选择医嘱长临', value: 'undefined' },
                              { label: '临期', value: 0 },
                              { label: '长期', value: 1 },
                            ]}
                            onChange={(event) =>
                              // repeatIndicator
                              setInquireParams({
                                ...inquireParams,
                                repeatIndicator: event,
                              })
                            }
                            style={{
                              width: '158px',
                            }}
                          />
                        </tr>
                        <tr>
                          <Select
                            allowClear
                            showSearch
                            placeholder="计价属性"
                            options={[]}
                            onChange={(event) => console.log(event)}
                            disabled
                          />
                        </tr>
                      </table>
                    </div>
                  </div>
                );
              }}
            >
              <VSTableColumn
                dataIndex={['orderClass']}
                title="医嘱类别"
                valueType="select"
                search
                order={0}
                columnKey={'orderClass'}
                hideInTable
                dataSource={[
                  { label: '西药', value: 'DRUG' },
                  { label: '中药', value: 'HERB' },
                  { label: '检验', value: 'LAB' },
                  { label: '检查', value: 'EXAM' },
                  { label: '治疗', value: 'TREAT' },
                  { label: '手术', value: 'OPERATION' },
                  { label: '麻醉', value: 'ANESTHESIA' },
                  { label: '护理', value: 'NURSING' },
                  { label: '膳食', value: 'MEAL' },
                  { label: '营养', value: 'NUTRITION' },
                  { label: '会诊', value: 'CONSULTATION' },
                  { label: '用血', value: 'BLOOD' },
                  { label: '其他', value: 'OTHER' },
                  { label: '病理', value: 'PATHOLOGY' },
                ]}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['id']}
                title="主键"
                valueType="digit"
                fieldProps={{}}
                hideInTable
              />

              <VSTableColumn
                dataIndex={['orderText']}
                title="医嘱正文"
                valueType="text"
                fieldProps={{}}
                width={382}
              />
              <VSTableColumn
                dataIndex={['dosage']}
                title="剂量"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['administration']}
                title="途径"
                valueType="select"
                fieldProps={{}}
                dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
              />

              <VSTableColumn
                dataIndex={['frequency']}
                title="频次"
                valueType="text"
                fieldProps={{}}
              />
              <VSTableColumn
                dataIndex={['repeatIndicator']}
                title="长/临"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['performSchedulePlan']}
                title="执行计划"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['drugBillingAttribute']}
                title="摆药"
                valueType="select"
                dataSource={[
                  { label: '正常', value: 'NORMAL' },
                  { label: '自备药', value: 'SELF_PROVIDE' },
                  { label: '不摆药', value: 'NOT_DISPENSE' },
                  { label: '药柜摆药', value: 'CABINET_DISPENSE' },
                  { label: '不计价', value: 'NOT_BILLING' },
                ]}
                fieldProps={{}}
                render={(dom, record) => {
                  if (includes(['SELF_PROVIDE'], record?.drugBillingAttribute))
                    return (
                      <CheckCircleTwoTone
                        style={{
                          fontSize: '28px',
                        }}
                        twoToneColor="rgba(48, 163, 19, 1)"
                      />
                    );
                  return dom;
                }}
              />

              <VSTableColumn
                dataIndex={['startPerformDateTime']}
                title="开始时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['lastPerformDateTime']}
                title="最后执行时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['lastBillingDateTime']}
                title="最后计价时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['stopOrderDateTime']}
                title="结束时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['orderStatus']}
                title="状态"
                valueType="select"
                dataSource={[
                  { label: '保存', value: 'SAVE' },
                  { label: '提交', value: 'SUBMIT' },
                  { label: '执行', value: 'PERFORM' },
                  { label: '停止', value: 'STOP' },
                  { label: '作废', value: 'CANCEL' },
                  { label: '审核未通过', value: 'AUDIT_FAIL' },
                ]}
                fieldProps={{}}
              />
            </VSPromiseTable>
          </div>
        </div>
        <Section
          title={
            <div style={{ width: '100%', position: 'relative' }}>
              <span>费用信息</span>
              <ButtonAndKeyDown
                style={{ position: 'absolute', right: '18px' }}
                children="新增费用"
                type="primary"
                methodKey="altKey"
                ordinaryKey=""
                disabled={!props?.onDoctorAdviceOrderId}
                onClick={() => {
                  props?.setModalAdviceDetailsParams({
                    // ...props?.modalAdviceDetailsParams,
                    open: true,
                    orderId: props?.onDoctorAdviceOrderId,
                    stop: true,
                  });
                }}
              />
            </div>
          }
          id="abvice"
        >
          <VSPromiseTable
            tableRender={(_, dom) => {
              return dom;
            }}
            scroll={ComputeButtomScroll()}
            pagination={false}
            vsid="75743"
            id="orderBillingDetailGroupList"
            onFetch={orderBillingDetailGroupListOnFetch}
            editable={{
              editType: 'single',
              extraOperations: [
                {
                  children: '显示明细',
                  onClick(_, { itemId }) {
                    props?.setModalAdviceDetailsParams({
                      ...props?.modalAdviceDetailsParams,
                      open: true,
                      orderId: props?.onDoctorAdviceOrderId,
                      itemId,
                      stop: false,
                    });
                  },
                },
              ],
              onCanDelete: (_) => false,
              onCanEdit: (_) => false,
            }}
          >
            <VSTableColumn
              dataIndex={['itemClass']}
              title="价表项目分类"
              valueType="select"
              formItemProps={{
                rules: [],
              }}
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.CLINIC_ITEM_CLASS_DICT}
            />

            <VSTableColumn
              dataIndex={['itemId']}
              title="项目编码"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '项目编码长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['itemName']}
              title="项目名称"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 200 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['itemSpecification']}
              title="项目规格"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['price']}
              title="项目单价"
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['totalAmount']}
              title="总计数量"
              valueType="digit"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Section>
      </div>
    </>
  );
};

export default DoctorsAdvice;
