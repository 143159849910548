import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationDtoController_getApplicationById_1c4e15: {
    method: 'post',
    url: '/api/application/getApplicationById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto) =>
      data,
  },
});
