import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox, Image, Select, Switch, Tag } from '@vs/vsf-kit';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import PeopleImage from '@/assets/people.png';
import Empty from '@/module/Empty';
import Visible from '@/module/Visible';
import Block from '@/pages/Index/components/block_no_padding';

import Timeline from '../../../../components/timeline';
import Item from './item';

// enum OrderTypeEnum {
//   // icon-Frame-9
//   MEDICATION = 'MEDICATION',
//   // icon-Frame-6
//   HANDLE = 'HANDLE',
//   HERBS = 'HERBS',
//   // icon-Frame-3
//   EXAMINE = 'EXAMINE',
//   // icon-huayan
//   ASSAY = 'ASSAY',
//   OPERATION = 'OPERATION',
//   PATHOLOGY = 'PATHOLOGY',
// }

// const timelineType = [
//   {
//     label: '全部',
//     value: '全部',
//   },
//   {
//     label: '药疗',
//     value: OrderTypeEnum.MEDICATION,
//   },
//   {
//     label: '检查',
//     value: OrderTypeEnum.EXAMINE,
//   },
//   {
//     label: '检验',
//     value: OrderTypeEnum.ASSAY,
//   },
//   {
//     label: '病理',
//     value: OrderTypeEnum.HERBS,
//   },
//   {
//     label: '手术',
//     value: OrderTypeEnum.OPERATION,
//   },
// ];

enum OrderTypeEnum {
  // 西药/中成药
  DRUG = 'DRUG',
  // 中药
  HERB = 'HERB',
  // 检验
  LAB = 'LAB',
  // 检查
  EXAM = 'EXAM',
  TREAT = 'TREAT',
  // 手术
  OPERATION = 'OPERATION',
  // 病理
  PATHOLOGY = 'PATHOLOGY',
  ANESTHESIA = 'ANESTHESIA',
  NURSING = 'NURSING',
  MEAL = 'MEAL',
  NUTRITION = 'NUTRITION',
  CONSULTATION = 'CONSULTATION',
  BLOOD = 'BLOOD',
  OTHER = 'OTHER',
}
const timelineType = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '西药/中成药',
    value: OrderTypeEnum.DRUG,
  },
  {
    label: '中药',
    value: OrderTypeEnum.HERB,
  },
  {
    label: '检验',
    value: OrderTypeEnum.LAB,
  },
  {
    label: '检查',
    value: OrderTypeEnum.EXAM,
  },
  {
    label: '处置',
    value: OrderTypeEnum.TREAT,
  },
  {
    label: '手术',
    value: OrderTypeEnum.OPERATION,
  },
  {
    label: '会诊',
    value: OrderTypeEnum.CONSULTATION,
  },
  { label: '病理', value: OrderTypeEnum.PATHOLOGY },
];

const Index = (props) => {
  const { user } = props?.stores ?? {};
  const {
    initKey,
    currentPatient,
    outVisitInfo,
    inVisitInfo,
    hospitalType,
    outRegisterInfo,
  } = user ?? {};

  // const [currentType, setcurrentType] = useState('全部');
  const [currentItem, setCurrentItem] = useState();
  const [list, setList] = useState<any[]>([]);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string[]>(['all']);
  // 是否本次就诊
  const [isCurrentTreat, setIsCurrentTreat] = useState<boolean>(true);
  const _patientId = currentPatient?.patientId;
  // const _outpVisitIdIs = outVisitInfo?.outpVisit?.id;
  const _outpVisitIdIs = outRegisterInfo?.id;

  const _inVisitId = inVisitInfo?.inpVisitId;

  const getList = useCallback(async () => {
    // console.log('hha');

    if (!_patientId) {
      setList([]);
    } else {
      const _isTREAT = selectValue?.find((item) => {
        return item == 'TREAT';
      });
      const _id =
        hospitalType == 'out'
          ? { outpVisitIdIs: isCurrentTreat ? _outpVisitIdIs : undefined }
          : { inpVisitIdIs: isCurrentTreat ? _inVisitId : undefined };
      const res =
        await vsf?.services?.PatientJourneyController_getPatientJourney_95f0ff?.(
          {
            qto: {
              orderClassIn:
                selectValue?.[0] === 'all'
                  ? undefined
                  : _isTREAT
                  ? [...selectValue, 'OTHER']
                  : selectValue,
              patientIdIs: _patientId,
              ..._id,
            },
          },
        );
      if (res?.code === 200) {
        setList(res?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // _patientId,
    selectValue,
    isCurrentTreat,
    _outpVisitIdIs,
    _inVisitId,
    hospitalType,
  ]);

  useEffect(() => {
    // console.log('hha');

    getList();
  }, [getList, initKey]);

  const handleChange = (value) => {
    setSelectValue(value);
  };

  const handleSelect = (e) => {
    if (e?.value === 'all') {
      setSelectValue([e.value]);
    }
    if (selectValue.length === 1 && selectValue?.[0] === 'all') {
      setSelectValue([
        ...cloneDeep(selectValue).filter((item) => item !== 'all'),
        e.value,
      ]);
    }
  };

  const tagRender = (params) => {
    const { label, value, closable, onClose } = params;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="timeline">
      <Block
        titleStyle={{ padding: 0 }}
        headerStyle={{ marginBottom: '16px' }}
        desc={
          <Checkbox
            style={{ marginLeft: '15px' }}
            defaultValue={isCurrentTreat}
            onChange={(e) => {
              setIsCurrentTreat(e as boolean);
            }}
          >
            本次就诊
          </Checkbox>
        }
        title={!switchValue ? '患者旅程' : '患者画像'}
        extra={
          <div className="toggle">
            <label htmlFor="switchValue">
              <span className="label" id="switchValue">
                {!switchValue ? '切换画像' : '切换旅程'}
              </span>
              <Switch
                size="small"
                value={switchValue}
                onChange={(v) => {
                  setSwitchValue(v);
                }}
              />
            </label>
          </div>
        }
        // mainStyle={{
        //   paddingRight: `var(--cpoe-padding)`,
        // }}
      >
        {/* 患者旅程 */}
        <Visible value={!switchValue}>
          <div className="timeline-type">
            <Select
              style={{ width: '100%' }}
              mode="tags"
              size="large"
              placeholder="请选择项目类型"
              defaultValue={['all']}
              value={selectValue}
              dataSource={timelineType}
              tagRender={tagRender}
              onChange={handleChange}
              onSelect={(e) => {
                handleSelect(e);
              }}
            />
          </div>
          {!list || list?.length === 0 ? (
            <Empty />
          ) : (
            <div className="hideScroll" style={{ overflowY: 'scroll' }}>
              {list?.map((item, index) => {
                return (
                  <Item
                    currentItem={currentItem}
                    onClick={() => {
                      // setCurrentItem(item?.orderId);
                    }}
                    data={item}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </Visible>
        {/*  患者画像*/}
        <Visible value={switchValue}>
          <div className="people-image-container">
            {/* <div
              className="people-image"
              style={{
                backgroundImage: `url(${PeopleImage})`,
              }}
            ></div> */}
            <Image preview={false} src={PeopleImage}></Image>
          </div>
        </Visible>
      </Block>
    </div>
  );
};

export default definePage(Index);
