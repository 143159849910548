import { createServices } from '@vs/vsf-boot';
export default createServices({
  ChiefComplaintDictionaryController_getChiefComplainDictionary_dd55d3: {
    method: 'post',
    url: '/api/base-common/get-chief-complain-dictionary',
    parameterFomatter: (data?: {
      qto: ChiefComplaintDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintDictionaryListVoBaseCommonEntranceWebVo[],
    ) => data,
  },
  ChiefComplaintDictionaryController_createChiefComplaintDictionary_c6b3af: {
    method: 'post',
    url: '/api/base-common/create-chief-complaint-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateChiefComplaintDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintDictionaryVoBaseCommonEntranceWebVoChiefcomplaintdictionarylistvo,
    ) => data,
  },
  ChiefComplaintDictionaryController_updateChiefComplaintDictionary_ff80d6: {
    method: 'post',
    url: '/api/base-common/update-chief-complaint-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateChiefComplaintDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintDictionaryVoBaseCommonEntranceWebVoChiefcomplaintdictionarylistvo,
    ) => data,
  },
  ChiefComplaintDictionaryController_deleteChiefComplaintDictionary_a123cd: {
    method: 'post',
    url: '/api/base-common/delete-chief-complaint-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteChiefComplaintDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintDictionaryVoBaseCommonEntranceWebVoChiefcomplaintdictionarylistvo,
    ) => data,
  },
});
