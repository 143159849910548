import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0: {
    method: 'post',
    url: '/api/application/get-sys-config-base-code-without-root',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  BaseParameterController_queryPagedByParameterConfigQto_8e5359: {
    method: 'post',
    url: '/api/base-parameter/query-paged-by-parameter-config-qto',
    parameterFomatter: (data?: {
      qto: ParameterConfigQtoBaseParameterPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ParameterConfigVoBaseParameterEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
