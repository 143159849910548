import { message as VSmessage } from '@vs/vsf-kit';

const message = (data: any, success: (v: any) => boolean) => {
  let isSuccess: boolean;
  if (success) {
    isSuccess = success(data);
  } else {
    isSuccess = data?.code === 200;
  }
  if (isSuccess) {
    VSmessage.success(data?.message || '请求成功');
  } else {
    // VSmessage.error(data?.message || '请求失败');
  }
};

export default message;
