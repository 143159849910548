import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentPriceController_deleteOutpOrderCost_b94210: {
    method: 'post',
    url: '/api/clinic-record-order/delete-outp-order-cost',
    parameterFomatter: (data?: {
      btoParam: DeleteOutpOrderCostBtoClinicRecordOrderServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentPriceOutpOrderCostVoClinicRecordOrderEntranceWebVo,
    ) => data,
  },
  DepartmentPriceController_createOutpOrderCost_4229c3: {
    method: 'post',
    url: '/api/clinic-record-order/create-outp-order-cost',
    parameterFomatter: (data?: {
      list: CreateOutpOrderCostBtoClinicRecordOrderServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentPriceOutpOrderCostVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  DepartmentPriceController_getDepartmentPrice_78944b: {
    method: 'post',
    url: '/api/clinic-record-order/get-department-price',
    parameterFomatter: (data?: {
      outpVisitEncounterId: number;
      orderId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentPriceOutpOrderCostVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  DepartmentPriceController_changeMedicalInsuranceType_88cd3f: {
    method: 'post',
    url: '/api/clinic-record-order/change-medical-insurance-type',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpBillingController_changeTally_6a9877: {
    method: 'post',
    url: '/api/outp-billing/change-tally',
    parameterFomatter: (data?: { outpOrderCostId: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpBillingController_cancelTally_99dec3: {
    method: 'post',
    url: '/api/outp-billing/cancel-tally',
    parameterFomatter: (data?: { outpOrderCostId: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
