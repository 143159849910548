import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationBOController_createApplication_c83033: {
    method: 'post',
    url: '/api/application/createApplication',
    parameterFomatter: (data?: {
      btoParam: CreateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_updateApplication_62596e: {
    method: 'post',
    url: '/api/application/updateApplication',
    parameterFomatter: (data?: {
      btoParam: UpdateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationDtoController_getApplicationWithRoleById_cb1f5b: {
    method: 'post',
    url: '/api/application/get-application-with-role-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationWithRoleVoApplicationEntranceWebVo,
    ) => data,
  },
  ApplicationVsRoleDtoController_getByApplicationId_4a7f46: {
    method: 'post',
    url: '/api/application-privilege/get-by-application-id',
    parameterFomatter: (data?: { applicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleWithPermissionVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
});
