/* eslint-disable prefer-const */
import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import amount from '@/pages/Index/components/dosage/amount';

export type StateValueType = {
  id: string | number;
  [key: string]: any;
  performDepartment: {
    id: number;
    departmentName: string;
  };
  orderText: {
    storageDepartmentId: number;
  };
};

interface GroupMedicalOrdersArrange {
  order: string[];
  state?: StateValueType;
  max: number;
  eliminates: any[];
  tip: (method: {
    onOk: () => void;
    onCancel: () => void;
    contrast_index?: any[];
  }) => void;
  disables?: (one, two) => any[];
}

interface AccordEliminate {
  value: boolean;
  type: 'yes' | 'not';
}

/**
 * 处理医嘱成组
 */
function askGroupMedicalOrders(config: GroupMedicalOrdersArrange) {
  const { order, max, state, tip, eliminates, disables = () => [] } = config;
  const accordOrder = (one: string, two: string): boolean => {
    const value = order.includes(one) && order.includes(two);
    if (!value) {
      message?.warning('只有药品医嘱可以成组');
    }
    return value;
  };

  const accordDepartment = (
    one?: number | null,
    two?: number | null,
  ): boolean => {
    // console.log(one, two, 'one');

    if (one !== two) {
      message?.warning('药品药房不同不能成组');
    }
    return one === two;
  };

  const accordMax = (value) => max > value;

  const accordEliminate = async (
    data,
    dataSource,
  ): Promise<AccordEliminate> => {
    return new Promise((resolve) => {
      const [[one], [two]] = accordRange(data, dataSource);
      const eliminate = (value): any[] => {
        const filter: any = [];
        for (const item of eliminates.slice(1)) {
          if (Array?.isArray(item)) {
            const state = item?.reduce(
              (accumulator, current) => accumulator?.[current],
              value,
            );
            filter.push(state);
          } else {
            filter.push(value[item]);
          }
        }
        return filter;
      };
      let contrast_index: any[] = [];
      function ask(one, two) {
        const e = (value) => [null, void 0]?.includes(value);
        const list: boolean[] = [];
        for (const i in one) {
          if (e(one[i]) || e(two[i])) {
            list?.push(true);
          } else if (one[i]?.toString() !== two[i]?.toString()) {
            contrast_index.push(eliminates?.[i]);
            list?.push(false);
          } else {
            list?.push(true);
          }
        }
        // console.log(list);
        return !list?.includes(false);
      }
      if (ask(eliminate(one), eliminate(two))) {
        resolve({
          value: true,
          type: 'yes',
        });
      } else {
        tip?.({
          onOk: () => {
            resolve({
              value: true,
              type: 'not',
            });
          },
          onCancel: () => {
            resolve({
              value: false,
              type: 'not',
            });
          },
          contrast_index,
        });
      }
    });
  };

  const accordNarcotics = (one, two) => {
    if (one?.[label(one)]?.toxicCode !== two?.[label(two)]?.toxicCode) {
      message?.warning('精I、精II、麻、毒、普通药品互相不能成组');
    }
    return one?.[label(one)]?.toxicCode === two?.[label(two)]?.toxicCode;
  };

  type GroupValue = [GroupMedicalOrdersArrange['state'], number];
  const accordRange = (record, dataSource): [GroupValue, GroupValue] => {
    const two = dataSource?.findIndex((item) => item?.id == record?.id);
    const twoIndex = two !== -1 ? two : dataSource?.length;
    return [
      [dataSource[twoIndex - 1], twoIndex - 1],
      [record, twoIndex],
    ];
  };

  const accordSubmit = (one, two) => {
    const value = ![one?.orderStatus, two?.orderStatus].includes('SUBMIT');
    if (!value) {
      message?.warning(`有医嘱状态为提交,无法成组`);
    }
    return value;
  };

  const disabled = (data, dataSource) => {
    return new Promise((resolve) => {
      const [[one], [two]] = accordRange(data, dataSource);
      const oneId =
        one?.performDepartment?.id ?? one?.orderText?.storageDepartmentId;
      const twoId =
        two?.performDepartment?.id ?? two?.orderText?.storageDepartmentId;
      const accord = [
        accordOrder(one?.orderClass, two?.orderClass),
        accordDepartment(oneId, twoId),
        accordSubmit(one, two),
        accordNarcotics?.(one, two),
        ...(disables?.(one, two) ?? []),
      ];
      return resolve(!accord.includes(false));
    });
  };

  const label = (value) =>
    value?.orderClass === 'DRUG' ? 'drugOrder' : 'herbOrder';

  const eliminate = (value) => {
    const data = JSON.parse(JSON.stringify(value));
    const hand = {};
    for (const item of eliminates) {
      hand[item?.at(-1)] = item?.reduce(
        (previousValue: any, currentValue: any, index) => {
          const [state] = [previousValue?.[currentValue]];
          if (item?.length === index + 1) delete previousValue?.[currentValue];
          return state;
        },
        data,
      );
    }
    return [data, hand];
  };

  const intoGroups = async (
    _data,
    dataSource?: any,
    dataType: 'splice' | 'push' = 'push',
  ) => {
    const [[one, oneIndex], [two, twoIndex]] = accordRange(
      _data ?? '',
      dataSource,
    );
    const groupSubNumber = one?.[label(one)]?.groupSubNumber;
    const groupIntNumber = one?.groupIndex?.length ?? 0;
    if (groupIntNumber < max || groupSubNumber === undefined) {
      const [info] = eliminate(two) || [];
      const data = info?.[label(two)];
      const groupNumber = one?.[label(one)]?.groupNumber;
      const group = Math.max(
        ...dataSource
          ?.map((item) => item[label?.(item)]?.groupNumber)
          .filter(Boolean),
      );
      const num = groupNumber || (group || 0) + 1;

      let groupSubNumber = groupNumber ? one?.[label(one)]?.groupSubNumber : 0;

      const item = (list, int) => {
        return list.map((option, index) => {
          groupSubNumber += 1;
          const order = label(option);
          return {
            ...option,
            [order]: {
              ...(option?.id !== one?.id ? data : option?.[order]),
              groupNumber: num,
              groupSubNumber,
            },
            ...((list?.length === 1 || index !== 0) && int === index
              ? {
                  totalAmount: {
                    ...option?.totalAmount,
                    value: amount({
                      performDays:
                        option?.repeatIndicator === true
                          ? one?.repeatValidDays
                          : option?.[label(option)]?.prescriptionIndicator ===
                            true
                          ? one?.repeatValidDays
                          : one?.frequency?.frequencyCount,
                      dosage: option?.dosage?.value,
                      extension: (() => {
                        try {
                          return JSON.parse(
                            one?.group?.frequency?.extensionJson ??
                              one?.frequency?.extensionJson,
                          );
                        } catch {
                          return one?.group?.frequency ?? one?.frequency;
                        }
                      })(),
                      dosagePerUnit: option?.dosagePerUnit,
                      amountPerPackage: option?.amountPerPackage,
                      roundingType: option?.roundingType,
                    }),
                  },
                }
              : {}),
          };
        });
      };
      if (!groupNumber) {
        const res = await accordEliminate(_data ?? '', dataSource);
        // console.log(res?.value, 'value1');
        if (res?.value) {
          const list = item([one, two], 1);
          return [dataSource?.toSpliced(oneIndex, 2, ...list), list];
        } else {
          return [false, []];
        }
      } else {
        if (dataType === 'splice') {
          // console.log(one?.[label(one)]?.groupSubNumber);
          const index = one?.[label(one)]?.groupSubNumber < max - 2 ? 1 : 2;
          const slice = dataSource?.slice(
            twoIndex,
            twoIndex + one.groupIndex?.length - index,
          );
          const list = item(slice, twoIndex + 1);
          return [
            dataSource?.toSpliced(twoIndex, slice?.length, ...list),
            list,
          ];
        } else {
          const res = await accordEliminate(_data ?? '', dataSource);
          // console.log(res?.value, 'value3');
          if (res?.value) {
            const list = item([two], 0);
            return [dataSource?.toSpliced(twoIndex, 1, ...list), list];
          } else {
            return [false, []];
          }
        }
      }
    } else {
      message.warning(`最多 ${max} 条医嘱成组`);
      return [false, []];
    }
  };

  const onGroup = async (data, dataSource?: any) => {
    const value = await disabled(data, dataSource);
    if (value) {
      return intoGroups(data, dataSource) || [false, []];
    }
    return [false, []];
  };

  function* approve(list: any[]) {
    for (let index = 0; index <= list?.length; index++) {
      yield [list[index - 1], [list[index], index], list[index + 1]];
    }
  }

  /**
   * 样式
   * @param dataSource;
   * @returns {list};
   */
  const onSliced = <T>(dataSource: T): T => {
    const arr: never[] = [];
    let groupNumber: number | string = '';
    // eslint-disable-next-line @typescript-eslint/ban-types
    let group = {};
    let groupList = Object.create({
      groupIndex: [],
    });
    if (Array?.isArray(dataSource)) {
      for (const [, [current, index], next] of approve(dataSource)) {
        if (current) {
          let self = { ...current };
          if (current?.orderClass === 'DRUG') {
            const name = label(current);
            const title = current?.[name]?.groupNumber;
            const item: any = {
              groupName: '',
              group: {},
              groupIndex: groupList?.groupIndex,
            };

            if (groupNumber) {
              if (groupNumber === title) {
                item?.groupIndex.push(index);
                item.group = group;
                const [data] = eliminate(current);
                self = {
                  // ...self,
                  ...data,
                  // [label(current)]: data,
                };
                if (next?.[label(next)]?.groupNumber === groupNumber) {
                  item.groupName = 'aspirin-order-text-group-line';
                } else {
                  item.groupName = 'aspirin-order-text-group-last';
                  groupNumber = '';
                  group = {};
                  groupList = Object?.create({
                    groupIndex: [],
                  });
                }
              }
            } else {
              if (title && next?.[label(next)]?.groupNumber === title) {
                groupNumber = title;
                const [, data] = eliminate(current);
                group = data;
                item.groupName = 'aspirin-order-text-group-first';
                groupList?.groupIndex?.push(index);
              } else {
                groupList = Object?.create({
                  groupIndex: [],
                });
              }
            }
            self.__proto__ = Object?.create({
              ...Object?.prototype,
              ...item,
            });
            arr?.push(self as never);
          } else {
            arr?.push(current as never);
          }
        }
      }
    }
    return arr as T;
  };

  const onCancelGroup = (dataSource, state) => {
    try {
      const list = dataSource as any;
      if (state?.groupIndex?.length) {
        const [one] = state?.groupIndex;
        const last = list
          ?.map((item) => item[label?.(item)]?.groupNumber)
          ?.filter((item) => item);
        const group = Math?.max(...(new Set(last) as unknown as any[]));
        const data = list
          ?.filter((...[, i]) => state?.groupIndex?.includes(i))
          ?.map((item, index) => {
            const option = {
              ...item,
              [label?.(item)]: {
                ...(item[label(item)] ?? {}),
                ...(Object?.values(item?.group)?.length ? item?.group : {}),
                groupNumber: (group ?? 0) + (index + 1),
                groupSubNumber: 1,
              },
              ...(Object?.values(item?.group)?.length ? item?.group : {}),
            };
            return option;
          });
        return [list?.toSpliced(one, state?.groupIndex?.length, ...data), data];
      }
    } catch (error) {
      return [];
    }
  };

  const onCancelInsert = (dataSource, list): Promise<never[]> => {
    return new Promise((resolve, reject) => {
      try {
        const index = dataSource?.findIndex(
          (item) => item?.id === list?.[0]?.id,
        );
        resolve(
          dataSource?.toSpliced(
            index,
            list?.length,
            ...list?.map((item, index) => ({
              ...item,
              [label(item)]: {
                ...item[label(item)],
                groupSubNumber: index + 1,
              },
            })),
          ),
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    onGroup,
    onSliced,
    onCancelGroup,
    intoGroups,
    onCancelInsert,
    disabled,
  };
}

export default askGroupMedicalOrders;
