import { Tabs } from '@vs/vsf-kit';
import { definePage } from '@vs/vsf-boot';
import TreatCategoryDetail from './tabs/TreatCategoryDetail';
import TreatItem from './tabs/TreatItem';
import PerformDepartment from './tabs/PerformDepartment';
import { Screen } from '@/pages/Index/components';

import '@/style/adaptation.less';
const Tab = (props) => {
  const items = [
    {
      key: '1',
      label: '治疗类别详细信息',
      children: (
        <TreatCategoryDetail
          value={props.data}
          onDetailChange={props?.onDetailChange}
        />
      ),
    },
    {
      key: '2',
      label: '执行科室维护',
      children: <PerformDepartment value={props.value[0]} />,
    },
    {
      key: '3',
      label: '治疗项目维护',
      children: <TreatItem data={props.value[0]} active={props.value} />,
    },
  ];
  return (
    <Tabs
      items={items}
      onChange={props.onChange}
      activeKey={props.tab}
      destroyInactiveTabPane
      className="aspirin-tabs-box heightAll100"
    />
  );
};

const Major = (props) => {
  return (
    <Screen>
      <Tab {...props} />
    </Screen>
  );
};

export default definePage(Major);
