import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Modal,
  Select,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const RollbackModal = (props, ref): any => {
  const { onSubmit } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = () => {
    onClose();
    onSubmit?.();
  };

  const renderContent = useCallback(() => {
    return (
      <div className="content">
        <div className="first">
          <VSTable>
            <VSTableColumn
              valueType="select"
              dataIndex={['repeatIndicator']}
              title="长/临"
              dataSource={[
                {
                  label: '长期',
                  value: true,
                },
                {
                  label: '临时',
                  value: false,
                },
              ]}
              preview
            />

            <VSTableColumn
              dataIndex={['orderClass']}
              title="类别"
              valueType="select"
              fieldProps={{}}
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '病理', value: 'PATHOLOGY' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
              ]}
              preview
            />

            <VSTableColumn
              dataIndex={['startPerformDateTime']}
              title="开始时间"
              valueType="date"
              fieldProps={{
                format: 'MM-DD HH:mm',
              }}
              preview
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱内容"
              valueType="text"
              // render={render}
              preview
            />

            <VSTableColumn
              dataIndex={['back']}
              title="回退"
              valueType="text"
              // render={render}
              preview
            />

            <VSTableColumn
              dataIndex={['tips']}
              title="操作提示"
              valueType="text"
              // render={render}
              preview
            />
          </VSTable>
        </div>
        <div className="second">
          <div>温馨提示</div>
          <div>可以根据操作提示处理后再进行医嘱回退操作</div>
          <div>
            <span>回退原因</span>
            <Select />
          </div>
        </div>
      </div>
    );
  }, []);

  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button type="primary" onClick={onClose}>
            取消
          </Button>
          <Button type="primary" onClick={onSubmit}>
            确定
          </Button>
        </div>
      ),
    };
  }, [onClose, onSubmit]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  return (
    <Modal
      width={1168}
      open={open}
      destroyOnClose={true}
      title="回退医嘱"
      onCancel={onClose}
      {...renderFooter()}
      className="aspirin-execute-order-rollback-modal"
    >
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(RollbackModal);
