import {
  compose,
  DatePicker,
  DatePickerProps,
  DateRangePickerProps,
  TimePicker,
  TimePickerProps,
  TimeRangePickerProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { cloneDeep, isNaN } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

dayjs.extend((window as any).dayjs_plugin_customParseFormat);
dayjs.extend((window as any).dayjs_plugin_objectSupport);

export type AspirinDateTimeValueTypeSingle = {
  /** 自定义类型字段 */
  year?: string | number | null;
  month?: string | number | null;
  day?: string | number | null;
  hour?: string | number | null;
  minute?: string | number | null;
  second?: string | number | null;
};

export type AspirinDateTimeValueTypeRange = {
  startTimePoint: AspirinDateTimeValueTypeSingle;
  endTimePoint: AspirinDateTimeValueTypeSingle;
};

export type AspirinDateTimeValueType =
  | AspirinDateTimeValueTypeRange
  | AspirinDateTimeValueTypeSingle;

export type AspirinDateTimePickerProps = {
  /**
   * 默认值
   */
  defaultValue?: AspirinDateTimeValueType;
  /**
   * 值
   */
  value?: AspirinDateTimeValueType;
  /**
   * 值变化回调
   */
  onChange?: (value?: AspirinDateTimeValueType) => void;
  /**
   * 组件类型
   */
  type?: 'single' | 'range';
  /**
   * 组件字段format 例如 'YYYY/MM/DD HH:mm:ss'
   */
  format?: string;
  /**
   * @previewFormat 的 format
   */
  previewFormat?: string;
  /**
   * 组件出入值的格式
   */
  valueType?: 'date' | 'timestamp' | 'point';
  /**
   * rangePicker 中是否允许为空
   */
  allowEmpty?: [boolean, boolean];
  /**
   * TimePicker 组件 props
   * @component TimePicker
   */
  timePickerProps?: TimePickerProps;
  /**
   * DatePicker 组件 props
   * @component DatePicker
   */
  datePickerProps?: DatePickerProps;
  /**
   * TimeRangePicker 组件 props
   * @component TimeRangePicker
   */
  timeRangePickerProps?: TimeRangePickerProps;
  /**
   * DateRangePicker 组件 props
   * @component DateRangePicker
   */
  dateRangePickerProps?: DateRangePickerProps;
};

const isFalsy = (v) => {
  return v === undefined || v === null || isNaN(v) || v === '';
};

const getTimeStr = (v, keys) => {
  let len = 0;
  let sum = 0;
  if (keys?.includes('hour')) {
    sum += 1;
  }
  if (keys?.includes('minute')) {
    sum += 1;
  }
  if (keys?.includes('second')) {
    sum += 1;
  }
  if (sum === 1) {
    len = 2;
  } else if (sum === 2) {
    len = 5;
  } else if (sum === 3) {
    len = 8;
  }
  return v?.slice(0, len);
};

/**
 * 字符串转对象
 * @param v 2023-09-25 12:00:00
 * @param keys ['year', 'month', ..]
 * @returns { year: 2023, month: 9, day: 25, hour: 12, minute: 0, second: 0 }
 */
const getObjValue = (v, keys: string[] = [], type = 'date') => {
  const res: any = {};
  if (type === 'date') {
    keys?.forEach((key) => {
      if (key === 'day') {
        res[key] = dayjs(v).get('date');
      } else if (key === 'month') {
        res[key] = dayjs(v).get('month') + 1;
      } else {
        res[key] = dayjs(v).get(key as any);
      }
    });
  } else {
    const target = (getTimeStr(v, keys) ?? '')?.split(':');
    if (keys && keys.length > 0) {
      if (keys?.length === 3) {
        res.hour = target?.[0];
        res.minute = target?.[1];
        res.second = target?.[2];
      } else if (keys?.length === 1) {
        keys?.forEach((key) => {
          res[key] = +target?.[0];
        });
      } else {
        if (keys?.includes('hour')) {
          res.hour = +target?.[0];
          res.minute = +target?.[1];
        }
        if (keys?.includes('second')) {
          res.minute = +target?.[0];
          res.second = +target?.[1];
        }
      }
    }
  }
  return res;
};

/**
 * 对象中含有月份时,需要对月份自减 1
 * @param v { year: 2023, month: 9, day: 25, hour: 12, minute: 0, second: 0 }
 * @returns { year: 2023, month: 8, day: 25, hour: 12, minute: 0, second: 0 }
 */
const handleDecMonth = (v) => {
  const newV: any = cloneDeep(v);
  if (v && v?.month !== undefined && v?.month !== null) {
    newV.month = newV.month - 1;
  }
  return newV;
};

/**
 * 日期时间选择器(阿司匹林)
 */
const AspirinDateTimePicker = (props: AspirinDateTimePickerProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    format = 'YYYY/MM/DD HH:mm:ss',
    type = 'single',
    valueType = 'point',
    allowEmpty = [false, false],
    timePickerProps = {},
    timeRangePickerProps = {},
    datePickerProps = {},
    dateRangePickerProps = {},
  } = props;

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  // 选择器类型 time or date
  const [compType, setCompType] = useState<'time' | 'date' | undefined>();
  // 从 format 中转换出的eo的字段key
  const [objKeys, setObjKeys, getObjKeys] = useGetState<any>();
  // datePicker or dateRangePicker 中 是否需要展示时间
  const [showTime, setShowTime] = useState<boolean>(false);
  /**
   * 设置含有的 value key
   * @param v
   * @returns
   */
  const handleSetObjKeys = (v) => {
    const arr: string[] = [];
    if (v?.indexOf('YYYY') >= 0) {
      arr.push('year');
    }
    if (v?.indexOf('MM') >= 0) {
      arr.push('month');
    }
    if (v?.indexOf('DD') >= 0) {
      arr.push('day');
    }
    if (v?.indexOf('HH') >= 0) {
      arr.push('hour');
    }
    if (v?.indexOf('mm') >= 0) {
      arr.push('minute');
    }
    if (v?.indexOf('ss') >= 0) {
      arr.push('second');
    }
    return arr;
  };

  /**
   * 设置初始的类型跟配置
   */
  const getInfoWithFormat = useCallback(
    (v) => {
      setObjKeys(handleSetObjKeys(format));
      const dateRegex = /YYYY|MM|DD/;
      const timeRegex = /HH|mm|ss/;
      const blankRegex = /\s/;
      if (blankRegex.test(v)) {
        const [date, time] = v?.split(' ');
        if (dateRegex.test(date)) {
          setCompType('date');
          const separator = v.match(/[^\w]/)[0];
          setShowTime(timeRegex.test(v));
        } else {
          console.log('不匹配');
        }
      } else {
        if (dateRegex.test(v)) {
          setCompType('date');
          const separator = v.match(/[^\w]/)[0];
        } else if (timeRegex.test(v)) {
          setCompType('time');
        }
      }
    },
    [format, setObjKeys],
  );

  const isValidValue = (v) => {
    if (Array.isArray(v)) {
      return v?.every((item) => item && !Number.isNaN(item));
    }
    return v && !Number.isNaN(v);
  };

  /**
   * convert input
   * @param v
   * @returns
   */
  const convertInputValue = (v: AspirinDateTimeValueType | undefined) => {
    if (isValidValue(v)) {
      if (typeof v === 'object' && valueType === 'point') {
        if (type === 'single') {
          const newV = handleDecMonth(v);
          // @ts-ignore
          return dayjs(newV);
        } else if (type === 'range') {
          const newV = {
            startTimePoint:
              'startTimePoint' in v && v?.startTimePoint
                ? handleDecMonth(v?.startTimePoint)
                : undefined,
            endTimePoint:
              'endTimePoint' in v && v?.endTimePoint
                ? handleDecMonth(v?.endTimePoint)
                : undefined,
          };
          return [
            // @ts-ignore
            newV?.startTimePoint ? dayjs(newV?.startTimePoint) : '',
            // @ts-ignore
            newV?.endTimePoint ? dayjs(newV?.endTimePoint) : '',
          ];
        }
      } else if (valueType === 'date' || valueType === 'timestamp') {
        if (type === 'single') {
          // @ts-ignore
          return dayjs(v);
        } else if (type === 'range' && Array.isArray(v)) {
          return [
            // @ts-ignore
            dayjs(v?.[0]),
            // @ts-ignore
            dayjs(v?.[1]),
          ];
        }
      }
    }
    return undefined;
  };

  /**
   * convert output
   * @param v
   * @returns
   */
  const convertOutputValue = useCallback(
    (v) => {
      if (!Array.isArray(v)) {
        if (valueType === 'point') {
          return getObjValue(
            typeof v === 'string' ? v : dayjs(v).format(format),
            getObjKeys(),
            compType,
          );
        } else if (valueType === 'date') {
          return dayjs(v).format(format);
        } else if (valueType === 'timestamp') {
          return dayjs(v).valueOf();
        }
      } else {
        if (valueType === 'point') {
          return {
            startTimePoint: isFalsy(v?.[0])
              ? null
              : getObjValue(
                  typeof v?.[0] === 'string'
                    ? v?.[0]
                    : dayjs(v?.[0]).format(format),
                  getObjKeys(),
                  compType,
                ),
            endTimePoint: isFalsy(v?.[1])
              ? null
              : getObjValue(
                  typeof v?.[1] === 'string'
                    ? v?.[1]
                    : dayjs(v?.[1]).format(format),
                  getObjKeys(),
                  compType,
                ),
          };
        } else if (valueType === 'date') {
          return [dayjs(v?.[0]).format(format), dayjs(v?.[1]).format(format)];
        } else if (valueType === 'timestamp') {
          return [dayjs(v?.[0]).valueOf(), dayjs(v?.[1]).valueOf()];
        }
      }
    },
    [format, getObjKeys, compType, valueType],
  );

  const handleChange = (time: Dayjs, timeString: string) => {
    setValue(convertOutputValue(time));
  };

  useEffect(() => {
    getInfoWithFormat(format);
  }, [format, getInfoWithFormat]);

  if (compType === 'time') {
    if (type === 'single') {
      return (
        <TimePicker
          format={format}
          {...timePickerProps}
          // @ts-ignore
          onChange={handleChange}
          // @ts-ignore
          value={convertInputValue(value)}
        />
      );
    } else {
      return (
        <TimePicker.RangePicker
          format={format}
          allowEmpty={allowEmpty}
          {...timeRangePickerProps}
          // @ts-ignore
          onChange={handleChange}
          // @ts-ignore
          value={convertInputValue(value)}
        />
      );
    }
  } else if (compType === 'date') {
    if (type === 'single') {
      return (
        <DatePicker
          format={format}
          showTime={showTime}
          {...datePickerProps}
          // @ts-ignore
          onChange={handleChange}
          // @ts-ignore
          value={convertInputValue(value)}
        />
      );
    } else {
      return (
        <DatePicker.RangePicker
          format={format}
          showTime={showTime}
          allowEmpty={allowEmpty}
          {...dateRangePickerProps}
          // @ts-ignore
          onChange={handleChange}
          // @ts-ignore
          value={convertInputValue(value)}
        />
      );
    }
  } else {
    return null;
  }
};
AspirinDateTimePicker.displayName = 'AspirinDateTimePicker';

export default compose(
  withField<AspirinDateTimeValueType>({
    name: 'AspirinDateTimePicker',
    valueType: 'aspirinDateTimePicker',
  }),
  withPreview<AspirinDateTimePickerProps>({
    renderPreview: (props) => {
      const {
        value,
        format = 'YYYY/MM/DD HH:mm:ss',
        type = 'single',
        previewFormat = 'YYYY/MM/DD HH:mm:ss',
      } = props;
      /** 返回预览模式下的dom */
      if (value) {
        if (type === 'range') {
          const newV = {
            startTimePoint:
              'startTimePoint' in value && value?.startTimePoint
                ? handleDecMonth(value?.startTimePoint)
                : null,
            endTimePoint:
              'endTimePoint' in value && value?.endTimePoint
                ? handleDecMonth(value?.endTimePoint)
                : null,
          };
          return `${
            newV?.startTimePoint
              ? dayjs(newV?.startTimePoint).format(previewFormat)
              : ''
          } ~ ${
            newV?.endTimePoint
              ? dayjs(newV?.endTimePoint).format(previewFormat)
              : '-'
          }`;
        } else {
          const newV = handleDecMonth(value);
          // @ts-ignore
          return dayjs(newV).format(previewFormat);
        }
      } else {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {value}
          </div>
        );
      }
    },
  }),
)(AspirinDateTimePicker) as typeof AspirinDateTimePicker;
