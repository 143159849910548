import vsf from '@vs/vsf-boot';
import {
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState, useKeyPress } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const mockDoctorId = 1603;

const maxIndex = 9;
const minIndex = 0;

const Index = (props) => {
  const {
    onSelect,
    open,
    showRepertory = true,
    storageId,
    searchValue,
  } = props;
  const tableRef = useRef<any>();
  const [index, setIndex, getIndex] = useGetState<number>(-1);
  // const [selectedRows, setSelectedRows] = useState<any[]>();

  const [dataSource, setDataSource] = useState<any>();

  useEffect(() => {
    tableRef?.current?.setCurrentPage(1);
  }, [searchValue]);

  const handleSelect = useCallback(
    (data) => {
      if (open) {
        onSelect?.(data);
      }
    },
    [open, onSelect],
  );

  useKeyPress('downarrow', () => {
    if (index < maxIndex && open) {
      setIndex(getIndex() + 1);
    }
  });

  useKeyPress('uparrow', () => {
    if (index > minIndex && open) {
      setIndex(getIndex() - 1);
    }
  });

  useKeyPress('leftarrow', () => {
    if (open) {
      tableRef?.current?.gotoPreviousPage();
      setIndex(0);
    }
  });

  useKeyPress('rightarrow', () => {
    if (open) {
      tableRef?.current?.gotoNextPage();
      setIndex(0);
    }
  });

  const getCurrentData = useCallback(
    (index) => {
      const currentPage = tableRef?.current?.getCurrentPage();
      const v = currentPage ? currentPage - 1 : 0;
      // handleSelect(dataSource?.[v * 10 + index]);
      handleSelect(dataSource?.[index]);
    },
    [dataSource, handleSelect],
  );

  useKeyPress(
    '1',
    () => {
      if (open) {
        getCurrentData(0);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '2',
    () => {
      if (open) {
        getCurrentData(1);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '3',
    () => {
      if (open) {
        getCurrentData(2);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '4',
    () => {
      if (open) {
        getCurrentData(3);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '5',
    () => {
      if (open) {
        getCurrentData(4);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '6',
    () => {
      if (open) {
        getCurrentData(5);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '7',
    () => {
      if (open) {
        getCurrentData(6);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '8',
    () => {
      if (open) {
        getCurrentData(7);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '9',
    () => {
      if (open) {
        getCurrentData(8);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '0',
    () => {
      if (open) {
        getCurrentData(9);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    'enter',
    () => {
      if (open && index >= 0) {
        getCurrentData(getIndex());
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              itemNameLike: searchValue ?? '',
              inputCodeWubiLike: searchValue ?? '',
              inputCodeLike: searchValue ?? '',
              outpUseIndicatorIs: true,
            },
            isIncludeDrugOrHerb: false,
          },
        );
      setDataSource(res?.data?.result ?? res?.data ?? []);
      return {
        data: res?.data?.result ?? [],
        total: res?.data?.count ?? 0,
      };
    },
    [searchValue],
  );

  return (
    <div className="dictionary-table">
      <VSPromiseTable
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        onFetch={onFetch}
        pagination={{
          defaultPageSize: 10,
        }}
        size="small"
        scroll={{ y: 500 }}
        rowClassName={(record, rowIndex) => {
          return index === rowIndex ? 'ant-table-row-selected' : '';
        }}
        rowSelection={{
          selectType: 'single',
          // selectedRows: selectedRows,
          hideColumn: true,
          onSelect: (node, key, selected) => {
            handleSelect(node);
          },
          onSelectedRowsChange: (data, keys) => {
            handleSelect(data?.[0]);
          },
        }}
        rowClick={{
          rowClickType: 'select',
        }}
      >
        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'item_name长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'item_spec长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['brand']}
          title="品牌"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'brand长度不合法', type: 'string', min: 0, max: 80 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemClass']}
          title="费用类别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'units长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'memo长度不合法', type: 'string', min: 0, max: 300 },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </div>
  );
};

export default Index;
