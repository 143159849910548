import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getInpOverviewExamOrLab_8110e1: {
    method: 'post',
    url: '/api/clinic-record/get-inp-overview-exam-or-lab',
    parameterFomatter: (data?: {
      eo: QueryOverviewReportEoClinicRecordPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OverviewOrderWithExamLabVoClinicRecordEntranceWebVo[],
    ) => data,
  },
});
