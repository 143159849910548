import { createServices } from '@vs/vsf-boot';
export default createServices({
  ChargeSolutionDtoController_getChargeSolutionList_114af0: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-list',
    parameterFomatter: (data?: {
      qto: ChargeSolutionQtoFinanceBillingSettingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionVoFinanceBillingSettingEntranceWebVo[],
    ) => data,
  },
  ChargeSolutionDtoController_getChargeSolutionDetailById_41f387: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-detail-by-id',
    parameterFomatter: (data?: { chargeSolutionId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionWithJudgeConditionVoFinanceBillingSettingEntranceWebVo,
    ) => data,
  },
  ChargeSolutionBOController_createChargeSolution_27fbf3: {
    method: 'post',
    url: '/api/finance-billing-setting/create-charge-solution',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateChargeSolutionBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionBOController_deleteChargeSolutionById_423c88: {
    method: 'post',
    url: '/api/finance-billing-setting/delete-charge-solution-by-id',
    parameterFomatter: (data?: { chargeSolutionId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionBOController_updateChargeSolution_691afa: {
    method: 'post',
    url: '/api/finance-billing-setting/update-charge-solution',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateChargeSolutionBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
