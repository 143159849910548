import vsf, { definePage } from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import React, { useState } from 'react';

import Container from '@/module/Container';

import Screen from '../../../components/screen';
import SavedPurchasePlanDetailList from './SavedPurchasePlanDetailList';
import SavedPurchasePlanList from './SavedPurchasePlanList';
// 侧边栏
const Outdrawer = (props) => {
  return (
    <>
      <SavedPurchasePlanList {...props}></SavedPurchasePlanList>
    </>
  );
};
// 主列表
const StoragePurchasePlan = (props) => {
  // 已保存采购计划单
  const [planMaster, setPlanMaster] = useState();
  // 左侧框选中列；
  const [selectrow, setSelectrow] = useState();
  // 左下角 选中 药品名称
  const [drugProductNameValue, setDrugProductNameValue] = useState();
  // 侧边栏是否展开；
  const [openDrawer, setOpenDrawer] = useState(true);
  props = Object.assign(
    { ...props },
    {
      openDrawer,
      setOpenDrawer,
      planMaster,
      setPlanMaster,
      selectrow,
      setSelectrow,
      drugProductNameValue,
      setDrugProductNameValue,
    },
  );
  return (
    <>
      <Outdrawer {...props}></Outdrawer>
      <Container
        layout={{
          items: [
            {
              block: 1,
              bgColor: 'rgb(255, 255, 255,.3)',
              padding: '1.6rem',
            },
          ],
        }}
      >
        <SavedPurchasePlanDetailList {...props} />
      </Container>
    </>
  );
};
// 组件主页面
const DrugStoragePurchasePlanCreate = (props) => {
  return (
    <>
      <Screen>
        <StoragePurchasePlan {...props}></StoragePurchasePlan>
      </Screen>
    </>
  );
};
export default definePage(DrugStoragePurchasePlanCreate);
