import vsf from '@vs/vsf-boot';
import { Input, message, Radio } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import { ary } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Container from '@/module/Container';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import skin from '@/pages/Index/components/order/out_patient/table/skin';
import { convertOrderInTable } from '@/pages/Index/components/order/out_patient_template/table/convert';
import OutHospitalTemplateImported from '@/pages/Index/components/order/out_patient_template/table/imported';
import SkinTest from '@/pages/Index/components/order/out_patient_template/table/validate_drug';
import { OrderTableRef } from '@/pages/Index/components/order_table';

import DefendTree from './DefendTree';
import FetchOrder from './method';
const toxicCode = {
  Normal: '1',
  Poison: '6',
  PsychotropicFirst: '2',
  PsychotropicSecond: '7',
  Narcotic: '5',
};
const DefendTemplate = forwardRef((props: any, ref) => {
  const { tableRef } = props;
  const table = useRef<OrderTableRef>(null);
  const [data, setData] = useState([]);
  const [template] = useOnChangeValue('PERSONAL_TEMPLATE');
  const [orderTemplate, setOrderTemplate] = useState<any>(null);
  const validate = useRef<any>(null);
  const onSelect = (value) => {
    setOrderTemplate(value);
  };
  let arr = [];
  const onSubmit = useFetch({
    fetch: async () => {
      const list: any[] = [];
      for (const item of data) {
        const value = await FetchOrder(item);
        console.log(value, 'valuevaluevaluevalue');
        if (value?.drugOrder?.skinTestClass) {
          if (value?.validateType === 'ERROR') {
            return message.error(value?.errorMessage);
          } else if (
            value?.validateType === 'WARNING' ||
            value?.drugEo?.toxicCode === toxicCode.Narcotic || // 麻醉药品
            value?.antibioticIndicator || // 抗生素
            !!value?.skinTestType
          ) {
            const array = await validate?.current?.onOpen?.({
              ...value,
              // drugItemId: value?.drugOrder?.drugItemId,
              detailInfo: {
                ...value,

                skinTestFlag:
                  value?.skinTestType && !value?.antibioticIndicator ? 0 : -1, // 前端参数 -1.初步弹框不进行皮试相关弹框; 0.二次弹框, 如果满足皮试相关可以弹框; 1.不进行皮试弹框
              },
            });
            arr = skin(array, [...tableRef?.current?.getValue(), ...arr]);
            list?.push(arr);
          } else {
            if (value?.errorMessage) {
              return message.info(value?.errorMessage);
            }
          }
        } else {
          list?.push({
            ...value,
            id: 'create_' + Math?.random(),
            uuid: getUUID(),
          });
        }
      }
      return {
        code: 200,
        data: list?.flat(),
      };
    },
    message: '导入',
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        onSubmit,
      };
    },
    [onSubmit],
  );

  const onMount = useCallback(async () => {
    if (orderTemplate?.id) {
      const res =
        await vsf.services?.OrderTemplateItemController_getOrderTemplateItemDetailByTemplateId_6c1e5e?.(
          {
            templateId: orderTemplate?.id,
          },
        );
      const value = res?.data?.map((item) => {
        Object.keys(item).forEach((option) => {
          const replace = option.replace(/^orderTemplate(.*)Dto$/, (...arr) => {
            const [, name] = arr;
            return name?.toLocaleLowerCase() + 'Order';
          });
          item[replace] = item[option];
          if (Object?.keys(item[option] ?? {})?.length) {
            Object?.keys(item[option] ?? {})?.forEach((option) => {
              const title = option.replace(
                /^orderTemplate(.*)DetailDtoList$/,
                (...[, name]) => {
                  return `${name?.toLocaleLowerCase()}OrderDetail`;
                },
              );
              if (Array?.isArray(item[replace][option]))
                item[replace][title] = item[replace][option];
            });
          }
        });
        return item;
      });
      table?.current?.setValue(
        convertOrderInTable(value)?.map((item) => ({
          ...item,
          ...(orderTemplate?.agreedPrescriptionIndicator
            ? {
                herbOrder: {
                  ...item?.herbOrder,
                  agreedPrescriptionId: item?.id,
                },
              }
            : {}),
        })),
      );
    }
  }, [orderTemplate]);

  useAsyncEffect(onMount, [onMount]);
  const tree = useRef({
    onSearch: (value: string) => void 0,
  });
  return (
    <div
      className="aspirin-medical-order-template-defend-box"
      style={{
        padding: '24px 0 24px 24px',
      }}
    >
      <nav>
        <div className="aspirin-medical-order-template-defend-left-box">
          <Radio.Group
            buttonStyle="solid"
            {...template}
            optionType="button"
            dataSource={[
              {
                value: 'PERSONAL_TEMPLATE',
                label: '个人',
              },
              {
                value: 'DEPARTMENT_TEMPLATE',
                label: '科室',
              },
              {
                value: 'HOSPITAL_TEMPLATE',
                label: '全院',
              },
            ]}
          />
          <Input
            onChange={(event) => tree?.current?.onSearch(event?.target?.value)}
            placeholder="搜索模板名称"
            prefix={<Icon type="icon-sousuo" />}
            className="width300"
          />
        </div>
      </nav>
      <Container
        layout={{
          items: [
            {
              block: '20%',
              gap: '20px',
            },
          ],
        }}
        style={{
          marginTop: 24,
        }}
      >
        <DefendTree
          template={template?.value}
          onChange={onSelect}
          isEdit={false}
          onReload={onMount}
          ref={tree}
        />
        <div
          style={{
            background: '#F4F5F7',
            height: '100%',
          }}
        >
          <OutHospitalTemplateImported
            ref={table}
            rowSelection={{
              selectedRows: data,
              onSelectedRowsChange: (value) => {
                // console.log(value, 'value');
                setData(value as never[]);
              },
            }}
          />
        </div>
      </Container>
      <SkinTest ref={validate} />
    </div>
  );
});

DefendTemplate.displayName = 'DefendTemplate';

export default DefendTemplate;
