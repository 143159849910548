import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Checkbox,
  List,
  Popover,
  // Select,
  Tooltip,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import { Select } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep, isEqual } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import { askSearch } from '@/pages/Index/components/func/ask';
import { askFixed } from '@/pages/Index/components/func/ask';
import { ASVSTable } from '@/pages/Index/components/table';
import { getScrollY, labelNumberWidth, valueAndUnit } from '@/utils';
import { handelEnums } from '@/utils/index';

import CodeTableSelect from '../../../../../../components/CodeTableSelect';

function calculateAge(birthday) {
  const birthDate = dayjs(birthday);
  const today = dayjs();
  return `${today.diff(birthDate, 'year')}岁`;
}

type OrderCheckTablePropsType = {
  value?: any[];
};

const Index = (props: OrderCheckTablePropsType, ref) => {
  const { value } = props;

  const [performList, setPerformList] = useState<any[]>();

  const getPerformList = useCallback(async () => {
    const res =
      await vsf?.services?.ClinicRecordOrderExtensionController_getLabelSettingMasterInCurrentWard_fa7f91?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
        },
      ); // TODO: 你可能需要整理返回数据格式
    setPerformList(
      (res?.data ?? [])?.map((item) => ({
        label: item?.labelName,
        value: item?.id,
      })),
    );
  }, []);

  useEffect(() => {
    getPerformList();
  }, [getPerformList]);

  // 渲染高危图标
  const renderHighriskIcon = useCallback((record) => {
    const { drugOrder } = record || {};
    const node = (
      <Popover content="高危">
        <Icon
          type="icon-gaowei"
          size={20}
          style={{
            marginRight: 4,
          }}
        />
      </Popover>
    );
    if (drugOrder?.drug?.dangerIndicator) {
      return node;
    } else {
      return null;
    }
  }, []);

  // 渲染加急图标
  const renderUrgentIcon = useCallback((record) => {
    const { examOrder, labOrder, pathologyOrder, operationOrder } =
      record || {};
    const node = (
      <Icon
        type="icon-a-Frame1517"
        size={20}
        style={{
          marginRight: 4,
        }}
      />
    );
    if (
      examOrder?.emergencyIndicator ||
      labOrder?.emergencyIndicator ||
      operationOrder?.operationApplyType === 'EMERGENCY' ||
      pathologyOrder?.emergencyIndicator
    ) {
      return node;
    } else {
      return null;
    }
  }, []);

  const render = useCallback(
    (...args) => {
      const [_, data] = args;
      const record = data;
      const content = record?.orderText?.drugName ?? record?.orderText;
      const classNameTarget = value?.find((item) => item?.id === record?.id);
      let name = (
        <Popover
          title="详细信息"
          content={record?.orderText?.drugName ?? record?.orderText}
          placement="right"
        >
          <div
            className={classnames(
              'aspirin-order-text',
              classNameTarget?.groupName,
            )}
            style={{ display: 'f' }}
          >
            {renderUrgentIcon(record)}
            {renderHighriskIcon(record)}
            <div>
              <div className="text_ellipsis" style={{ width: '100%' }}>
                {record?.drugOrder?.prescriptionIndicator ||
                record?.drugOrder?.prescriptionId
                  ? `[处方] ${content}`
                  : content}
              </div>

              <div>
                {record?.stopOrderDateTime
                  ? `${record?.stopOrderDateTime}停止`
                  : ''}
              </div>
            </div>
          </div>
        </Popover>
      );
      switch (record?.orderClass) {
        case 'EXAM':
          {
            name = (
              <Popover
                placement="right"
                title={
                  <div className="aspirin-title-popover-box">
                    检查
                    <span> {record?.examOrder?.examOrderDetail?.length} </span>
                    项详情
                  </div>
                }
                content={
                  <List
                    size="small"
                    dataSource={record?.examOrder?.examOrderDetail}
                    renderItem={(item: any, index) => {
                      return (
                        <List.Item
                          style={{
                            padding: '8px 0',
                            width: '100%',
                          }}
                        >
                          <Alert
                            type="info"
                            style={{
                              width: '100%',
                            }}
                            message={`${index + 1}. ${item?.examItemName}`}
                          />
                        </List.Item>
                      );
                    }}
                  />
                }
                trigger="hover"
              >
                <div
                  className={classnames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  {renderUrgentIcon(record)}
                  <div>
                    <div className="text_ellipsis" style={{ width: '100%' }}>
                      {record?.orderText?.drugName ?? record?.orderText}
                    </div>

                    <div>
                      {record?.stopOrderDateTime
                        ? `${record?.stopOrderDateTime}停止`
                        : ''}
                    </div>
                  </div>
                </div>
              </Popover>
            );
          }
          break;
        case 'HERB':
          {
            // eslint-disable-next-line no-case-declarations
            const useWay = vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
              (item) => record?.herbOrder?.useWay == item?.value,
            )?.label;
            // eslint-disable-next-line no-case-declarations
            const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.find(
              (item) => record?.herbOrder?.frequency?.frequency == item?.value,
            )?.label;
            // eslint-disable-next-line no-case-declarations
            const useTimeDescription =
              vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
                (item) => item?.value == record?.herbOrder?.useTimeDescription,
              )?.label;
            name = (
              <Popover
                placement="right"
                overlayClassName="aspirin-herb-box"
                content={
                  <List
                    size="small"
                    header={
                      <div className="aspirin-title-herb-popover-box">
                        <div className="aspirin-title-herb-header-box">
                          <div className="aspirin-title-herb-header-left-box">
                            <span>中药汤剂明细</span>
                            <span>
                              共 {record?.herbOrder?.herbOrderDetail?.length} 项
                            </span>
                          </div>
                          <div className="aspirin-title-herb-header-right-box">
                            <Icon
                              type="icon-zhongtangyaodanchuangbeijing"
                              style={{
                                color: 'rgb(232, 225, 217)',
                              }}
                              size={56}
                            />
                          </div>
                        </div>
                        <div className="aspirin-title-message-box">
                          <div>使用方法: {useWay}</div>
                          <div>服用时间: {useTimeDescription}</div>
                          <div>服用频次: {frequency}</div>
                          <div>付数: {record?.herbOrder?.herbRegimen}</div>
                          <div className="aspirin-title-message-memo-box">
                            备注: {record?.herbOrder?.memo}
                          </div>
                        </div>
                      </div>
                    }
                    dataSource={record?.herbOrder?.herbOrderDetail}
                    className="aspirin-list-herb-popover-item-box"
                    renderItem={(item: any, index) => {
                      const find =
                        vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT?.find(
                          ({ value }) => item?.useDescription === value,
                        );
                      const medicalInsuranceType = [
                        { label: '甲', value: 'ALPHA' },
                        { label: '乙', value: 'BETA' },
                        { label: '丙', value: 'GAMMA' },
                      ]?.find(
                        ({ value }) => value === item?.medicalInsuranceType,
                      )?.label;
                      return (
                        <Alert
                          type="warning"
                          message={
                            <div className="aspirin-title-herb-popover-item-box">
                              <span>{index + 1}</span>
                              <span>{item?.drugProductName}</span>
                              {find?.label && <span>{find?.label}</span>}
                              <span>
                                {askFixed(item?.price ?? 0, 2) +
                                  '/' +
                                  (medicalInsuranceType ?? '')}
                              </span>
                            </div>
                          }
                        />
                      );
                    }}
                  />
                }
                trigger="hover"
              >
                <div
                  className={classnames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  <div>
                    <div className="text_ellipsis" style={{ width: '100%' }}>
                      {record?.orderText?.drugName ?? record?.orderText}
                    </div>

                    <div>
                      {record?.stopOrderDateTime
                        ? `${record?.stopOrderDateTime}停止`
                        : ''}
                    </div>
                  </div>
                </div>
              </Popover>
            );
          }
          break;
      }
      return (
        <div style={{ maxHeight: '100px' }}>
          {name}
          {/* {record?.stopOrderDateTime ? `${record?.stopOrderDateTime}停止` : ''} */}
        </div>
      );
    },
    [value, renderHighriskIcon, renderUrgentIcon],
  );

  const renderIndicator = useCallback((...args) => {
    const [, record] = args;
    const { patient, bed, ...rest } = record || {};
    if (patient) {
      return (
        <div className="aspirin-order-table-patient-render">
          <span>{bed?.bedLabel}</span>
          <span>{patient?.name}</span>
          <span>{patient?.displayId}</span>
          <span>{patient?.gender === '1' ? '男' : '女'}</span>
          <span>{calculateAge(patient?.birthday)}</span>
        </div>
      );
    } else {
      return rest?.repeatIndicator ? '长期' : '临时';
    }
  }, []);

  const renderStartTime = useCallback((...args) => {
    const [, record] = args;
    const { startPerformDateTime } = record || {};
    return startPerformDateTime
      ? dayjs(startPerformDateTime).format('MM-DD HH:mm')
      : '';
  }, []);

  const renderDosage = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, herbOrder } = target ?? {};
      return (
        valueAndUnit(drugOrder?.dosage, drugOrder?.dosageUnit) ??
        valueAndUnit(herbOrder?.herbDosage, 'ml') ??
        ''
      );
    },
    [value],
  );
  // 用量
  const renderUsage = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, herbOrder } = target ?? {};
      // console.log(target, 'orderDisposalorderDisposal');
      if (drugOrder) {
        // 西药处方
        return valueAndUnit(drugOrder?.usage, drugOrder?.usageUnit) ?? '';
      } else {
        // 中草药医嘱
        return valueAndUnit(herbOrder?.herbRegimen, '付');
      }
    },
    [value],
  );
  // 处置数量
  const renderAmount = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, herbOrder, disposalOrder } = target ?? {};

      return disposalOrder?.amount;
    },
    [value],
  );
  // 途径
  const renderAdministration = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, herbOrder, disposalOrder } = target ?? {};
      if (herbOrder) {
        const target = (
          vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT ?? []
        )?.find((item) => item?.value === herbOrder?.useWay);
        return target?.label ?? '';
      } else {
        const target = (
          vsf.stores.dbenums.enums.ADMINISTRATION_DICT ?? []
        )?.find(
          (item) =>
            item?.value === drugOrder?.administration ??
            disposalOrder?.administration,
        );
        return target?.label ?? '';
      }
    },
    [value],
  );

  // 频次
  const renderFrequency = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, disposalOrder, herbOrder } = target ?? {};
      // if (herbOrder) {
      //   const target = (vsf.stores.dbenums.enums.PERFORM_FREQ_DICT ?? [])?.find(
      //     (item) => item?.label == herbOrder?.frequency?.frequency,
      //   );
      //   return target?.label ?? '';
      // } else {
      //   const target = (vsf.stores.dbenums.enums.PERFORM_FREQ_DICT ?? [])?.find(
      //     (item) =>
      //       item?.label == drugOrder?.frequency?.frequency ??
      //       disposalOrder?.frequency?.frequency,
      //   );
      //   return target?.label ?? '';
      // }
      return (
        drugOrder?.frequency?.frequency ??
        disposalOrder?.frequency?.frequency ??
        ''
      );
    },
    [value],
  );

  const renderBillingAttribute = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { billingAttribute } = target ?? {};
      const dataSource = [
        { label: '正常计价', value: 'COMMON_BILLING' },
        { label: '手工计价', value: 'MANUAL_BILLING' },
        { label: '不计价', value: 'NOT_BILLING' },
      ];
      return (
        dataSource?.find((item) => item?.value === billingAttribute)?.label ??
        ''
      );
    },
    [value],
  );

  const renderDrugBillingAttribute = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder } = target ?? {};
      const dataSource = [
        {
          label: '正常',
          value: 'NORMAL',
          render: <Icon type="icon-a-yaofangzhengchang" size={20} />,
        },
        {
          label: '自备药',
          value: 'SELF_PROVIDE',
          render: <Icon type="icon-zibei" size={20} />,
        },
        {
          label: '不摆药',
          value: 'NOT_DISPENSE',
          render: <Icon type="icon-bubaiyao" size={20} />,
        },
        {
          label: '药柜摆药',
          value: 'CABINET_DISPENSE',
          render: <Icon type="icon-yaogui" size={20} />,
        },
      ];
      const valueTarget = dataSource?.find(
        (item) => item?.value === drugOrder?.drugBillingAttribute,
      );
      return valueTarget ? (
        <Tooltip placement="right" title={valueTarget?.label}>
          {valueTarget?.render}
        </Tooltip>
      ) : (
        ''
      );
    },
    [value],
  );

  const renderUseDescription = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { drugOrder, herbOrder } = target ?? {};
      if (herbOrder) {
        const target = (
          vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT ?? []
        )?.find((item) => item?.value === herbOrder?.useTimeDescription);
        return target?.label ?? '';
      } else {
        const target = (
          vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT ?? []
        )?.find((item) => item?.value === drugOrder?.useDescription);
        return target?.label ?? '';
      }
    },
    [value],
  );

  const renderDistribute = useCallback((...args) => {
    const [, record] = args;
    const { drugOrder, orderClass } = record || {};

    return orderClass !== 'DRUG'
      ? ''
      : handelEnums(
          vsf.stores.dbenums.enums.DRUG_DISTRIBUTION_DICT,
          drugOrder?.distribute,
        );
  }, []);

  const renderDepartment = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { department } = target ?? {};
      return '';
    },
    [value],
  );

  const renderPathologyOrder = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { pathologyOrder } = target ?? {};
      return pathologyOrder?.sampleLocation
        ? vsf.stores.dbenums.enums.SAMPLE_PLACE_DICT?.find(
            (item) => item?.value === pathologyOrder?.sampleLocation,
          )?.label
        : '';
    },
    [value],
  );

  const renderDuration = useCallback(
    (...args) => {
      const [, record] = args;
      const { id } = record ?? {};
      const target = value?.find((item) => item?.id === id);
      const { disposalOrder, herbOrder } = target ?? {};

      return (
        valueAndUnit(disposalOrder?.duration, disposalOrder?.durationUnit) ?? ''
      );
    },
    [value],
  );

  const performLabelRef = useRef<any>({});

  const columns = [
    {
      dataIndex: ['repeatIndicator'],
      title: '长/临',
      valueType: 'select',
      dataSource: [
        {
          label: '长期',
          value: true,
        },
        {
          label: '临时',
          value: false,
        },
      ],
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      // width: 50,
      width: labelNumberWidth(4),
      // fixed: 'left',
      render: renderIndicator,
    },
    {
      dataIndex: ['orderClass'],
      title: '类别',
      valueType: 'select',
      dataSource: [
        { label: '西药', value: 'DRUG' },
        { label: '中药', value: 'HERB' },
        { label: '检验', value: 'LAB' },
        { label: '检查', value: 'EXAM' },
        { label: '病理', value: 'PATHOLOGY' },
        { label: '治疗', value: 'TREAT' },
        { label: '手术', value: 'OPERATION' },
        { label: '麻醉', value: 'ANESTHESIA' },
        { label: '护理', value: 'NURSING' },
        { label: '膳食', value: 'MEAL' },
        { label: '营养', value: 'NUTRITION' },
        { label: '会诊', value: 'CONSULTATION' },
        { label: '用血', value: 'BLOOD' },
        { label: '其他', value: 'OTHER' },
      ],
      preview: true,
      width: 48,
      // fixed: 'left',
    },
    {
      dataIndex: ['startPerformDateTime'],
      title: '开始时间',
      valueType: 'text',
      fieldProps: { format: 'YYYY-MM-DD HH:mm:ss' },
      preview: true,
      width: 105,
      render: renderStartTime,
      // fixed: 'left',
    },
    {
      dataIndex: ['orderText'],
      title: '医嘱',
      valueType: 'text',
      width: 500,
      render: render,
      preview: true,
    },
    {
      dataIndex: ['drugOrder', 'dosage'],
      title: '剂量',
      valueType: 'text',
      width: 60,
      fieldProps: {
        allowClear: true,
        // dataSource: value,
      },
      preview: true,
      render: renderDosage,
    },
    {
      dataIndex: ['drugOrder', 'usage'],
      title: '用量',
      valueType: 'text',
      width: 100,
      fieldProps: {
        allowClear: false,
      },
      preview: true,
      render: renderUsage,
    },
    {
      dataIndex: ['disposalOrder', 'amount'],
      title: '处置数量',
      valueType: 'text',
      width: 100,
      fieldProps: {
        allowClear: false,
      },
      preview: true,
      render: renderAmount,
    },
    {
      dataIndex: ['drugOrder', 'administration'],
      title: '途径',
      valueType: 'select',
      width: 140,
      dataSource: vsf.stores.dbenums.enums.ADMINISTRATION_DICT,
      preview: true,
      render: renderAdministration,
    },
    {
      dataIndex: ['drugOrder', 'frequency', 'frequency'],
      title: '频次',
      valueType: 'select',
      width: 100,
      dataSource: vsf.stores.dbenums.enums.PERFORM_FREQ_DICT,
      preview: true,
      render: renderFrequency,
    },

    {
      dataIndex: ['drugOrder', 'useDescription'],
      title: '用法',
      valueType: 'select',
      width: 100,
      preview: true,
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      render: renderUseDescription,
    },
    {
      dataIndex: ['orderDisposal', 'duration'],
      title: '持续时间',
      valueType: 'select',
      width: 100,
      preview: true,
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      render: renderDuration,
    },
    {
      dataIndex: ['memo'],
      title: '备注',
      valueType: 'text',
      width: 140,
      preview: true,
    },
    {
      dataIndex: ['performPlan'],
      title: '执行计划',
      valueType: 'text',
      width: 140,
      preview: true,
      render: (...[, record]) => record?.performPlan ?? '',
    },
    {
      dataIndex: ['drugOrder', 'drugBillingAttribute'],
      title: '摆药',
      valueType: 'select',
      fieldProps: {
        allowClear: true,
      },
      width: 100,
      preview: true,
      render: renderDrugBillingAttribute,
    },
    {
      dataIndex: ['drugOrder', 'distribute'],
      title: '配置',
      valueType: 'text',
      width: 100,
      render: renderDistribute,
      preview: true,
    },
    {
      dataIndex: ['billingAttribute'],
      valueType: 'select',
      title: '计价',
      dataSource: [
        { label: '正常计价', value: 'COMMON_BILLING' },
        { label: '手工计价', value: 'MANUAL_BILLING' },
        { label: '不计价', value: 'NOT_BILLING' },
      ],
      width: 140,
      preview: true,
      render: renderBillingAttribute,
    },

    {
      dataIndex: ['performLabelSettingMaster', 'performLabel', 'id'],
      title: '执行单',
      valueType: 'custom',
      width: 140,
      dataSource: performList,
      fieldProps: {
        showSearch: true,
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      render: (...args) => {
        const [, record] = args;
        return (
          <Select
            filterOption={askSearch}
            disabled={record?.checkDateTime}
            defaultValue={record?.performLabelSettingMaster?.performLabel?.id}
            options={performList}
            showSearch={true}
            onChange={(v) => {
              if (performLabelRef.current) {
                performLabelRef.current = {
                  ...(performLabelRef.current || {}),
                  [record.id]: v,
                };
              }
            }}
          />
        );
      },
    },

    {
      dataIndex: ['doctor'],
      title: '开立医生',
      valueType: 'select',
      width: 100,
      fieldNames: {
        label: 'staffName',
        value: 'id',
      },
      fieldProps: {
        allowClear: false,
      },
      preview: true,
    },
    {
      dataIndex: ['performDepartment'],
      title: '执行科室',
      valueType: 'performDepartment',
      width: 120,
      fieldNames: {
        label: 'departmentName',
        value: 'id',
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      render: useCallback((...args) => {
        const [, record] = args;
        return record?.performDepartment?.departmentName ?? '';
      }, []),
      preview: true,
    },

    {
      dataIndex: ['drugOrder', 'department'],
      title: '配送地点(未联调)',
      valueType: 'text',
      fieldProps: {
        allowClear: true,
      },
      // width: 100,
      width: labelNumberWidth(11),
      preview: true,
      render: renderDepartment,
    },
    {
      dataIndex: ['pathologyOrder', 'sampleLocation'],
      title: '病理采集地点',
      valueType: 'text',
      fieldProps: {
        allowClear: true,
      },
      // width: 100,
      width: labelNumberWidth(9),
      preview: true,
      render: renderPathologyOrder,
    },
  ];

  const formatColumns = columns?.map((item, index) => ({
    ...item,
    onCell: (record) => {
      const { patient } = record;
      return {
        colSpan: patient ? (index === 0 ? columns?.length : 0) : undefined,
      };
    },
  }));

  const rowClassName = useCallback((record) => {
    if (record?.patient) {
      return 'aspirin-order-table-patient-render-row';
    } else if (record?.checkDateTime) {
      return 'aspirin-order-table-checked-render-row';
    }
    return '';
  }, []);

  const tableRef = useRef<any>();

  const getValues = () => {
    const target = tableRef?.current?.getValues();
    return target
      ?.map((item) => {
        return {
          ...item,
          performLabelSettingMaster: {
            performLabel: {
              id:
                performLabelRef?.current?.[item?.id] ??
                item?.performLabelSettingMaster?.performLabel?.id,
            },
          },
        };
      })
      ?.filter((item) => !item?.bed);
  };

  const getCheckedValues = () => {
    const target = tableRef?.current?.getCheckedValues();
    // console.log('performLabelRef?.current', performLabelRef?.current);
    return target
      ?.map((item) => {
        return {
          ...item,
          performLabelSettingMaster: {
            performLabel: {
              id:
                performLabelRef?.current?.[item?.id] ??
                item?.performLabelSettingMaster?.performLabel?.id,
            },
          },
        };
      })
      ?.filter((item) => !item?.bed);
  };

  useImperativeHandle(ref, () => ({
    ...(tableRef?.current ?? {}),
    getValidateValues: async (isAll) => {
      return isAll ? getValues() : getCheckedValues();
    },
    getSelected() {
      const res = getCheckedValues();
      return res;
    },
    clearChecked() {
      tableRef?.current?.clearChecked();
    },
  }));

  // console.log(value, 'value');

  return (
    <ASVSTable
      scroll={{
        y: getScrollY(isMinScreen ? 255 : 382),
        x: 2100,
      }}
      className="aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-vs-table-row-box"
      bordered
      ref={tableRef}
      value={value}
      pagination={false}
      rowClassName={rowClassName}
      onVSCheck={{
        type: 'check',
        checkKey: 'id',
        onCheck: (record, status, data) => {
          if (record?.patient) {
            return data
              ?.filter((item) => item?.patientId === record?.patient?.id)
              ?.map((item) => ({
                ...item,
                checked: status,
              }));
          } else if (record?.groupIndex) {
            return data
              ?.filter((item) => item?.groupIndex === record?.groupIndex)
              ?.map((item) => ({
                ...item,
                checked: status,
              }));
          } else {
            return [];
          }
        },
        onCheckAll: (record, status, data) => {
          return data;
        },
      }}
    >
      {formatColumns?.map((item: any, index) => {
        return <VSTableColumn key={index} {...item} />;
      })}
    </ASVSTable>
  );
};

export default React.memo(
  forwardRef(Index),
  (prev: OrderCheckTablePropsType, next: OrderCheckTablePropsType) => {
    return isEqual(prev?.value, next?.value);
  },
);
