import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getPagefallBySearchInpAdministrationApplyByPatientAndApplyDateQto_3f88a3:
    {
      method: 'post',
      url: '/api/clinic-inp-admission/get-pagefall-by-search-inp-administration-apply-by-patient-and-apply-date-qto',
      parameterFomatter: (data?: {
        qto: SearchInpAdministrationApplyByPatientAndApplyDateQtoClinicInpAdmissionPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: InpAdmissionApplyWithPatientAndDepartmentInfoVoClinicInpAdmissionEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
