import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitEncounterController_visitRefund_5da754: {
    method: 'post',
    url: '/api/clinic-outp-visit/visit-refund',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpVisitEncounterController_visitEnd_f34b60: {
    method: 'post',
    url: '/api/clinic-outp-visit/visit-end',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DoctorFavoriteDescriptionController_deleteDoctorFavoriteDescription_14100e: {
    method: 'post',
    url: '/api/clinic-outp-visit/delete-doctor-favorite-description',
    parameterFomatter: (data?: {
      btoParam: DeleteDoctorFavoriteDescriptionBtoClinicOutpVisitServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DoctorFavoriteDescriptionController_getDoctorFavoriteDescription_9014bf: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-doctor-favorite-description',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DoctorFavoriteDescriptionVoClinicOutpVisitEntranceWebVo[],
    ) => data,
  },
  DoctorFavoriteMedicalRecordController_deleteDoctorFavoriteMedicalRecord_bc0e79:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/delete-doctor-favorite-medical-record',
      parameterFomatter: (data?: {
        btoParam: DeleteDoctorFavoriteMedicalRecordBtoClinicOutpVisitServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  DoctorFavoriteMedicalRecordController_createDoctorFavoriteMedicalRecord_f15c7f:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/create-doctor-favorite-medical-record',
      parameterFomatter: (data?: {
        btoParam: CreateDoctorFavoriteMedicalRecordBtoClinicOutpVisitServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  OutpVisitController_queryOutVisitDetailByPatient_3bc295: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-out-visit-detail-by-patient',
    parameterFomatter: (data?: { patientInfo: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OutpVisitEncounterController_createOutpVisitEncounter_a27cb7: {
    method: 'post',
    url: '/api/clinic-outp-visit/create-outp-visit-encounter',
    parameterFomatter: (data?: { outpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo,
    ) => data,
  },
});
