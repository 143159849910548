import SearchContainer from '@/module/Search';
import { getScrollY, labelNumberWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  message,
  Divider,
  VSTableToolbar,
  Checkbox,
  Form,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Container from '@/module/Container';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
  useScroll,
} from '@/pages/Index/components/func/hooks';
import { useGetState, usePagination } from 'ahooks';
import { Flex } from '@/pages/Index/components';
import { UsageUnit } from '@/components/InputUnit';

import { getScrollYRem } from '@/utils';

const PriceListReviewTable = (props) => {
  const table = useRef(null);
  const [scrollId] = useOnChangeValue(null);
  const [scroll] = useOnChangeValue(null);
  const search = useRef({});
  const old = useRef([]);

  const is = ({
    old,
    newly,
    array = ['auditIndicatorIs', 'itemNameLike', 'itemClassIn'],
  }) => {
    const list = [];
    for (const item of array) {
      list?.push(Object?.is(old?.[item], newly?.[item]));
    }
    return !list?.includes(false);
  };

  const auditIndicatorIs = Form.useWatch(
    'auditIndicatorIs',
    table.current?.getSearchForm?.(),
  );
  const itemClassIn = Form.useWatch(
    'itemClassIn',
    table.current?.getSearchForm?.(),
  );

  const onFetch = useCallback(
    async (params) => {
      const { pagination, ...rest } = params;
      const ask = Object.values(rest)
        ?.map((item) => {
          const a = Boolean(Object?.keys(item)?.length);
          if (a) {
            return Object?.values(item)
              ?.map((item) => Boolean(Object?.keys(item)?.length))
              ?.includes(true);
          }
          return a;
        })
        .includes(true);
      if (!ask) {
        return {
          data: [],
          total: 0,
        };
      }
      const res =
        await vsf?.services?.PriceListDtoController_getPagefallByPriceItemReviewNewQto_72e66a?.(
          {
            qto: {
              size: params?.pagination?.size ?? 40,
              orderList: params.orderList, // 排序结果
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              auditIndicatorIs,
              itemClassIn: itemClassIn ? [itemClassIn] : null,
              scrollId: scroll?.value ?? null,
            },
          },
        );
      const list = res?.data?.result ?? res?.data ?? [];
      if (scroll?.value === null) {
        old.current = list;
      } else {
        old.current = [...old.current, ...list];
      }
      scrollId.onChange?.(res?.data?.scrollId);
      return {
        data: old.current?.map((item) => ({
          key: Math.random(),
          ...item,
        })),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [scroll?.value, auditIndicatorIs, itemClassIn],
  );

  const review = [
    {
      label: '复核通过',
      value: 'PASS',
      methodKey: 'altKey',
      ordinaryKey: 'Y',
    },
    {
      label: '复核未通过',
      value: 'FAILED',
      methodKey: 'altKey',
      ordinaryKey: 'N',
    },
  ];
  const [data, setData, getData] = useGetState([]);
  const [dataSource, setDataSource] = useState([]);

  const getDataSource = async () => {
    const res =
      await vsf?.services?.BaseCodeDtoServiceController_getValidCodeList_3cb2b9?.(
        { codeType: 'RECK_ITEM_CLASS_DICT' },
      );
    setDataSource(
      res.data.map((item) => ({
        ...item,
        label: item.name + (item?.code ? `(${item?.code})` : ''),
        value: item.code,
      })),
    );
  };
  useEffect(() => {
    getDataSource();
  }, []);

  const auditIndicator = [
    { label: '未复核', value: 'NOT_REVIEWED' },
    { label: '复核通过', value: 'PASS' },
    { label: '复核未通过', value: 'FAILED' },
  ];

  const onOk = useFetch({
    fetch: async (auditIndicator) => {
      const res =
        await vsf?.services?.PriceListBOController_reviewPriceItem_f0247d?.({
          btoParamList: data.map(({ id }) => ({ id, auditIndicator })),
        });
      return res;
    },
    success: () => {
      setData([]);
      vsf.refs.PriceReview.reload();
    },
    message: '复核',
  });

  const footer = (
    <div className="aspirin-table-footer-button">
      {review.map((item) => {
        const onClick = () => {
          if (!data.length) {
            message.warning('请勾选需要操作的信息');
            return;
          }
          if (data?.map((item) => item?.auditIndicator).includes(item?.value)) {
            const index = auditIndicator?.find(
              ({ value }) => value === item?.value,
            );
            message.warning(`${index?.label}, 请勿重复操作`);
            return;
          }
          Tip({
            content: `是否${item.label}该价表项目?`,
            onOk: () => onOk?.(item?.value),
          });
        };
        return (
          <ButtonAndKeyDown
            type="primary"
            {...item}
            key={item.value}
            onClick={onClick}
          >
            {item.label}
          </ButtonAndKeyDown>
        );
      })}
    </div>
  );

  const { id } = useScroll(() => {
    scroll?.onChange?.(scrollId?.value);
  });

  useEffect(() => {
    if (scroll?.value !== null || scrollId?.value) {
      scroll?.onChange?.(null);
      scrollId?.onChange(null);
    }
  }, [auditIndicatorIs, itemClassIn]);

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <Block footer={footer}>
        <VSPromiseTable
          vsid="26753"
          className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          id="PriceReview"
          pagination={false}
          data-id={id}
          ref={table}
          rowKey="key"
          rowClassName="aspirin-vs-table-row-box"
          rowClick={{
            rowClickType: 'select',
          }}
          bordered
          scroll={{
            y: getScrollYRem(27),
            // y: getScrollY(450)
          }}
          searchFormRender={(props, dom) => {
            return (
              <div className="aspirin-table-search-inline-no">
                <SearchContainer mode="inline" formPadding="0 170px 0 100px">
                  {dom}
                </SearchContainer>
                <Divider />
              </div>
            );
          }}
          searchConfig={{
            // labelWidth: 100,
            labelWidth: '20%',
            labelAlign: 'left',
            collapsed: false,
            collapseRender: false,
            optionRender: (...arr) => {
              const [, , [other, search]] = arr;
              return (
                <SearchContainer.Header title="筛选">
                  {[
                    other,
                    <ButtonAndKeyDown
                      methodKey="altKey"
                      ordinaryKey="Q"
                      {...search?.props}
                      onClick={() => {
                        if (scroll?.value !== null || scrollId?.value) {
                          scroll?.onChange?.(null);
                          scrollId?.onChange(null);
                        }
                        search?.props?.onClick?.();
                      }}
                    >
                      查询
                    </ButtonAndKeyDown>,
                  ]}
                </SearchContainer.Header>
              );
            },
          }}
          onFetch={onFetch}
        >
          <VSTableColumn
            // width={50}
            width={labelNumberWidth(5)}
            valueType="custom"
            title={() => {
              const state = vsf?.refs?.PriceReview?.getValues();
              const value =
                typeof getData()?.length === 'number'
                  ? state?.length === 0 && getData()?.length === 0
                    ? false
                    : getData()?.length === state?.length
                  : false;
              return (
                <Flex justify="center">
                  <Checkbox
                    indeterminate={
                      getData()?.length > 0 && getData()?.length < state.length
                    }
                    value={value}
                    onChange={(value) => {
                      if (value) {
                        setData(state);
                      } else [setData([])];
                    }}
                  />
                </Flex>
              );
            }}
            render={(...[, record]) => {
              const value = getData()
                ?.map?.((item) => item?.id)
                ?.includes(record?.id);
              return (
                <Flex justify="center">
                  <Checkbox
                    value={value}
                    onChange={(value) => {
                      if (value) {
                        setData([...data, record]);
                      } else
                        [
                          setData(
                            data?.filter((item) => item?.id !== record?.id),
                          ),
                        ];
                    }}
                  />
                </Flex>
              );
            }}
          />
          <VSTableColumn
            dataIndex={['auditIndicator']}
            title="复核标记"
            valueType="select"
            search
            order={-130}
            width={labelNumberWidth(10)}
            columnKey={'auditIndicatorIs'}
            dataSource={auditIndicator}
            fieldProps={{}}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['itemId']}
            title="项目ID"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['insuranceCountryCode']}
            title="国家编码"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['itemName']}
            title="项目名称"
            valueType="text"
            search
            order={-131}
            columnKey={'itemNameLike'}
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
            searchFieldProps={{
              onKeyDown: (event) => {
                if (event?.key === 'Enter') {
                  if (scroll?.value !== null || scrollId?.value) {
                    scroll?.onChange?.(null);
                    scrollId?.onChange(null);
                  }
                }
              },
            }}
          />

          <VSTableColumn
            dataIndex={['itemSpecification']}
            title="项目规格"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(8)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['price']}
            title="价格"
            valueType="digit"
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['firmId']}
            title="厂商"
            valueType="digit"
            fieldProps={{}}
            width={labelNumberWidth(8)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['standardCode']}
            title="标准编码"
            valueType="text"
            width={labelNumberWidth(8)}
            fieldProps={{}}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['unit']}
            title="单位"
            valueType="custom"
            fieldProps={{}}
            width={labelNumberWidth(5)}
            render={(...[, record]) => (
              <UsageUnit value={{ value: void 0, unit: record?.unit }} />
            )}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['branchInstitution']}
            title="院区"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['itemClass']}
            title="项目类型"
            valueType="select"
            search
            order={-129}
            columnKey={'itemClassIn'}
            width={labelNumberWidth(8)}
            searchFieldProps={{}}
            dataSource={(value) => {
              return value?.label
                ? dataSource?.filter((item) => askSearch(value?.label, item))
                : dataSource;
            }}
            render={(...[, record]) =>
              dataSource?.find((item) => item?.value === record?.itemClass)
                ?.name ?? record?.itemClass
            }
            searchConfig={{
              transform(value) {
                return {
                  itemClassIn: [value],
                };
              },
            }}
            fieldProps={{
              showSearch: true,
            }}
          />

          <VSTableColumn
            dataIndex={['medicalRecordClass']}
            title="病案首页分类"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.MR_FEE_CLASS_DICT}
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['lastUpdateStaff', 'staffName']}
            title="最近操作员"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['lastUpdateDate']}
            title="最近维护时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={labelNumberWidth(10)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['auditUpdateStaff', 'staffName']}
            title="复核操作员"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(8)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['auditUpdateDate']}
            title="复核时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={labelNumberWidth(8)}
            ellipsis={{
              showTitle: true,
            }}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
            ellipsis={{
              showTitle: true,
            }}
          />
          <VSTableToolbar
            title="价格项目复核列表"
            className="aspirin-toolbar-padding-top-box"
          />
        </VSPromiseTable>
      </Block>
    </Container>
  );
};
export default definePage(PriceListReviewTable);
