import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  Placeholder,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { isMinScreen } from '@/config';
import Empty from '@/module/Empty';
import Block from '@/pages/Index/components/block_no_padding';
import ButtonAndKeyDown, {
  ButtonAndKeyDownProps,
} from '@/pages/Index/components/button_and_keydown';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import { idCard, idCardMessage, passport, passportMessages } from '@/pattern';
import { calculateAge, labelColWidth } from '@/utils/index';

import {
  convertErpObservationApplyForm2SaveErpObservationApplyFuncFlowExecutorReq,
  convertGetErpObservationApplyWithPatientVoByErpObservationApplyWithPatientQtoRes2ErpObservationApplyForm,
} from './convert';
const Index = (props) => {
  const { user, settings, common } = vsf?.stores ?? {};
  const { initKey, currentPatient, outVisitInfo } = user ?? {};
  const { currentApplication } = common ?? {};
  // 13804
  const [erpVisitId, setErpVisitId] = useState<any>(14202);
  const [canEdit, setCanEdit] = useState(false);
  // 院区id
  const [admissionHospital, setAdmissionHospital] = useState<any>();
  // 科室id
  const [department, setDepartment] = useState<any>();
  // 诊断列表
  const [diagnosisLists, setDiagnosisLists] = useState<any>([]);

  const onFetch_t3804 = useCallback(async () => {
    if (!erpVisitId) return {};
    const res =
      await vsf?.services?.ClinicErpAdtController_getErpObservationApplyWithPatientVoByErpObservationApplyWithPatientQto_087f20?.(
        { qto: { erpVisitIdIs: erpVisitId } },
      );
    const output =
      convertGetErpObservationApplyWithPatientVoByErpObservationApplyWithPatientQtoRes2ErpObservationApplyForm?.(
        res,
      );
    if (res?.code == 200) {
      const _diagnose = await getOutpDiagnosis();
      // 当前机构
      const _currentBranch = {
        institutionName:
          currentApplication?.department?.branchInstitution?.institutionName,
        id: currentApplication?.department?.branchInstitution?.id,
      };
      // 当前院区
      const _currentAdmissionToDepartment = {
        departmentName: currentApplication?.department?.departmentName,
        id: currentApplication?.department?.id,
      };

      const _defaultValue = output?.id
        ? {
            doctor: user?.staffInfo?.staffName,
            applyDate: +new Date(),
            firstPrePayment: 0,
            branchInstitutionId: _currentBranch,
            admissionToDepartment: _currentAdmissionToDepartment,
            // ward: { departmentName: '', id: '' },
            diagnosisDescription: [_diagnose?.[0]],
          }
        : {};
      if (output?.id) {
        setAdmissionHospital(_currentBranch);
        setDepartment(_currentAdmissionToDepartment);
      } else {
        setAdmissionHospital(output?.branchInstitutionId);
        setDepartment(output?.admissionToDepartment);
      }

      vsf.refs?.erpObservationApplyFormLeft?.setFieldsValue(output);
      vsf.refs?.erpObservationApplyFormRight?.setFieldsValue({
        ...output,
        ..._defaultValue,
      });
    } else {
      vsf.refs?.erpObservationApplyFormLeft?.setFieldsValue(undefined);
      vsf.refs?.erpObservationApplyFormRight?.setFieldsValue(undefined);
    }
    return output;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erpVisitId]);

  useEffect(() => {
    getDiagnosis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onFetch_t3804();
  }, [onFetch_t3804]);

  const print = () => {};

  const clear = () => {
    vsf.refs?.erpObservationApplyFormLeft.resetFields();
    vsf.refs?.erpObservationApplyFormRight.resetFields();
    onFetch_t3804();
  };
  // 保存接口调用
  const save = async () => {
    await vsf.refs?.erpObservationApplyFormLeft?.validateFields();
    await vsf.refs?.erpObservationApplyFormRight?.validateFields();

    const _form1 = vsf.refs?.erpObservationApplyFormLeft?.getFieldsValue();
    const _form2 = vsf.refs?.erpObservationApplyFormRight?.getFieldsValue();

    const output =
      convertErpObservationApplyForm2SaveErpObservationApplyFuncFlowExecutorReq?.(
        {
          ..._form1,
          ...{
            ..._form2,
            diagnosisDescription: _form2?.diagnosisDescription
              ?.map((i) => i?.diagnosisName)
              ?.join(';'),
          },
          erpVisitId: erpVisitId,
          outpVisit: '',
        },
      );
    // params,
    const res =
      await vsf?.services?.ClinicErpAdtController_saveErpObservationApplyFuncFlowExecutor_a3b04d?.(
        {
          ...output,
        },
      );

    return res;
  };
  // 点击保存
  const handelSave = async (type) => {
    const res = await save();
    if (res?.code == 200) {
      message.success('保存成功');
      if (type == 'submit') {
        setTimeout(() => {
          message.success('打印成功');
        }, 1000);
      }
      setCanEdit(false);
    }
  };

  // 预约院区
  const getAllByUpInstitution = async (v) => {
    const res =
      await vsf.services?.OrganizationController_getAllByUpInstitutionCodeAndSearchCode_257200?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          upInstitutionIdIs: currentApplication?.institutionId,
          searchCode: v?.institutionName,
        },
      );
    return res?.data ?? [];
  };

  // 临床科室
  const getPaged = async (v?) => {
    if (!admissionHospital?.id) return [];
    const res =
      await vsf.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          nameLike: v?.departmentName ?? '',
          outpOrInp: '1',
          branchInstitutionId: admissionHospital?.id,
        },
      );
    return res?.data?.result ?? [];
  };

  // 护理单元
  const getPagedByDepartment = async (v) => {
    if (!department?.id) return [];
    const res =
      await vsf.services?.DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          departmentId: department?.id,
          searchCode: v?.departmentName,
        },
      );
    return res?.data?.result ?? [];
  };

  // 诊断
  const getOutpDiagnosis = async (v?) => {
    const res =
      await vsf.services?.ClinicRecordController_getOutpDiagnosisBySearchLike_08f2cd?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          outpEncounterId: 92801,
          searchLike: v?.searchLike,
        },
      );
    const _res = res?.data?.map((item) => {
      return { diagnosisName: item?.diagnosis?.diagnosisName, id: item?.id };
    });
    return _res ?? [];
  };

  const getDiagnosis = async () => {
    const res = await getOutpDiagnosis();
    setDiagnosisLists(res);
  };

  const departmentChange = (type, value) => {
    if (type && type == 'branchInstitution') {
      setDepartment(null);
      vsf.refs?.erpObservationApplyFormRight?.setFieldsValue({
        admissionToDepartment: null,
      });
    }
    vsf.refs?.erpObservationApplyFormRight?.setFieldsValue({
      ward: null,
    });
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={canEdit ? 'blue' : ''}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const Footer = () => {
    const button: any[] = [
      {
        children: '打印留观证',
        methodKey: 'altKey',
        ordinaryKey: 'P',
        disabled: !canEdit,
        onClick: () => print(),
      },
      {
        children: '清屏',
        methodKey: 'altKey',
        ordinaryKey: 'R',
        ghost: true,
        danger: true,
        disabled: !canEdit,
        onClick: () => clear(),
      },
      {
        children: '保存',
        methodKey: 'altKey',
        ordinaryKey: 'A',
        hidden: !canEdit,
        disabled: !canEdit,
        ghost: true,
        onClick: () => handelSave('save'),
      },
      {
        children: '提交',
        methodKey: 'altKey',
        ordinaryKey: 'W',
        hidden: !canEdit,
        disabled: !canEdit,
        onClick: () => handelSave('submit'),
      },
      {
        children: '修改申请单',
        hidden: canEdit,
        onClick: () => setCanEdit(true),
      },
    ];
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {button?.map((item, index) => (
          <ButtonAndKeyDown
            className="global_ant_btn"
            {...item}
            key={index}
            type="primary"
          />
        ))}
      </div>
    );
  };

  return (
    <div className={classNames('application_observation')}>
      <div className="application_observation_main">
        <div className="application_observation_main_left">
          <Block
            title="患者基础信息"
            headerStyle={{
              marginBottom: '12px',
            }}
          >
            <VSForm
              labelWrap
              id="erpObservationApplyFormLeft"
              labelAlign="left"
              disabled={!canEdit}
            >
              <VSFormLayout
                key="0"
                columnCount={1}
                className="form_label_width_150"
              >
                <VSFormItem
                  name={['displayId']}
                  label="患者id"
                  valueType="text"
                  required
                  fieldProps={{
                    disabled: true,
                  }}
                />

                <VSFormItem
                  name={['name']}
                  label="姓名"
                  valueType="text"
                  required
                  fieldProps={{ disabled: true }}
                />

                <VSFormItem
                  name={['gender']}
                  label="性别"
                  valueType="select"
                  required
                  fieldProps={{ disabled: true }}
                  dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                />

                <VSFormItem
                  name={['age']}
                  label="年龄"
                  valueType="text"
                  required
                  fieldProps={{ disabled: true }}
                />

                <VSFormItem
                  name={['idNumber']}
                  label="身份证号"
                  valueType="text"
                  rules={[
                    {
                      pattern: idCard,
                      message: idCardMessage,
                    },
                  ]}
                  fieldProps={{ disabled: true }}
                />

                <VSFormItem
                  name={['defaultChargeType']}
                  label="费别类别"
                  valueType="select"
                  required
                  fieldProps={{ disabled: true }}
                  dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                />

                <VSFormItem
                  name={['marriageStatus']}
                  label="婚姻状况"
                  valueType="selectCode"
                  fieldProps={{ placeholder: '请选择婚姻状况' }}
                  dataSource={vsf.stores.dbenums?.enums?.MARITAL_STATUS_DICT}
                />

                <VSFormItem
                  name={['cellphone']}
                  label="手机号"
                  valueType="text"
                  fieldProps={{}}
                  required
                />

                <VSFormItem
                  name={['currentAddress']}
                  label="现住址-行政区"
                  valueType="address"
                  fieldProps={{
                    showDetail: true,
                  }}
                />
                {/* <VSFormItem
                  name={['detail']}
                  label="现住址-详细地址"
                  valueType="text"
                  fieldProps={{}}
                /> */}

                <VSFormItem
                  name={['birthAddress']}
                  label="出生地"
                  valueType="address"
                  fieldProps={{
                    width: '100%',
                    showDetail: true,
                  }}
                />
              </VSFormLayout>
            </VSForm>
          </Block>
        </div>
        <div className="application_observation_main_right">
          <Block
            title="留观申请信息"
            headerStyle={{
              marginBottom: '12px',
            }}
          >
            <VSForm
              disabled={!canEdit}
              id="erpObservationApplyFormRight"
              labelAlign="left"
            >
              <VSFormLayout
                key="1"
                columnCount={2}
                className="form_label_width_130"
                gutter={80}
              >
                <VSFormItem
                  name={['patientAdmissionCondition']}
                  label="病情"
                  valueType="radio"
                  rules={[{ required: true }]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.PAT_ADM_CONDITION_DICT}
                />
              </VSFormLayout>
              <VSFormLayout
                key="2"
                columnCount={2}
                className="form_label_width_130"
                gutter={80}
              >
                <VSFormItem
                  name={['branchInstitutionId']}
                  label="预约院区"
                  valueType="select"
                  rules={[{ required: true }]}
                  fieldProps={{
                    showSearch: true,
                    onChange: (v) => {
                      setAdmissionHospital(v);
                      departmentChange('branchInstitution', v);
                    },
                  }}
                  fieldNames={{
                    label: 'institutionName',
                    value: 'id',
                  }}
                  dataSource={getAllByUpInstitution}
                />

                <VSFormItem
                  name={['admissionToDepartment']}
                  label="临床科室"
                  valueType="select"
                  rules={[{ required: true }]}
                  fieldNames={{
                    label: 'departmentName',
                    value: 'id',
                  }}
                  fieldProps={{
                    showSearch: true,
                    onChange: (v) => {
                      setDepartment(v);
                      departmentChange('department', v);
                    },
                  }}
                  dataSource={getPaged}
                />

                <VSFormItem
                  name={['diagnosisDescription']}
                  label="临床诊断"
                  valueType="select"
                  rules={[{ required: true }]}
                  fieldProps={{
                    showSearch: true,
                    mode: 'multiple',
                    allowClear: true,
                    tagRender: tagRender,
                  }}
                  dataSource={getOutpDiagnosis}
                  initialValue={[{ id: diagnosisLists?.[0]?.id }]}
                  fieldNames={{
                    label: 'diagnosisName',
                    value: 'id',
                  }}
                />

                <VSFormItem
                  name={['ward']}
                  label="护理单元"
                  valueType="select"
                  fieldProps={{ showSearch: true }}
                  rules={[{ required: true }]}
                  fieldNames={{
                    label: 'departmentName',
                    value: 'id',
                  }}
                  dataSource={getPagedByDepartment}
                />

                <VSFormItem
                  name={['admissionReason']}
                  label="留观目的"
                  valueType="selectCode"
                  fieldProps={{ placeholder: '请选择留观目的' }}
                  rules={[{ required: true }]}
                  dataSource={vsf.stores.dbenums?.enums?.ADMISSION_CAUSE_DICT}
                />

                <VSFormItem
                  name={['firstPrePayment']}
                  label="首期预缴款"
                  valueType="text"
                  fieldProps={{}}
                  initialValue={0}
                  rules={[
                    { required: true, message: '' },
                    {
                      validator: (rule, value, callback) => {
                        if (!value || value?.length === 0)
                          return callback('请输入首期预缴款');
                        const regex = /^\d+(\.\d{1,2})?$/;
                        if (!regex.test(value)) {
                          callback('请输入一个非负数，最多有两位小数');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                />

                <VSFormItem
                  name={['bedClass']}
                  label="床位类型"
                  valueType="selectCode"
                  fieldProps={{
                    placeholder: '请选择床位类型',
                  }}
                  dataSource={vsf.stores.dbenums?.enums?.BED_CLASS_DICT}
                />

                <VSFormItem
                  name={['appointAdmissionDate']}
                  label="预约留观日期"
                  valueType="date"
                  fieldProps={{
                    width: '100%',
                  }}
                  dataFormat="YYYY-MM-DD"
                  initialValue={+new Date()}
                  rules={[{ required: true }]}
                />

                <VSFormItem
                  name={['doctor']}
                  label="申请医生"
                  valueType="text"
                  fieldProps={{
                    disabled: true,
                  }}
                  rules={[{ required: true }]}
                  initialValue={user?.staffInfo?.staffName}
                />

                <VSFormItem
                  name={['applyDate']}
                  label="申请日期"
                  valueType="date"
                  fieldProps={{ disabled: true, width: '100%' }}
                  dataFormat="YYYY-MM-DD"
                  initialValue={+new Date()}
                />
              </VSFormLayout>
              <VSFormItem
                name={['doctorId']}
                label="申请医生id"
                valueType="text"
                hidden
                initialValue={user?.staffInfo?.id}
              />
            </VSForm>
          </Block>
        </div>
      </div>
      <div className="application_observation_bottom">
        <Footer />
      </div>
    </div>
  );
};

export default definePage(Index);

// 急诊留观申请单
