// 列表get
export const convertGetListInpPreTransferApplyToDepartmentVoByInpVisitIdRes2VSTableInpPreTransferApplyList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    const resData = _.get(input, 'data') ?? [];
    output.push(
      ...resData.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.patientId = _.get(d, 'patient.id');
        obj.patientDisplayId = _.get(d, 'patient.displayId');
        obj.bedId = _.get(d, 'inpVisit.patientInHospitalBaseDto.bed.id');
        obj.bedLabel = _.get(
          d,
          'inpVisit.patientInHospitalBaseDto.bed.bedLabel',
        );
        obj.patientName = _.get(d, 'patient.name');
        obj.patientGender = _.get(d, 'patient.gender');
        obj.toInstitutionId = _.get(d, 'toDepartment.branchInstitution.id');
        obj.toInstitutionName = _.get(
          d,
          'toDepartment.branchInstitution.institutionName',
        );
        obj.toDepartmentId = _.get(d, 'toDepartment.id');
        obj.toDepartmentName = _.get(d, 'toDepartment.departmentName');
        obj.toWardId = _.get(d, 'toDepartment.id');
        obj.toWardName = _.get(d, 'toDepartment.departmentName');
        obj.status = _.get(d, 'status');
        return obj;
      }),
    );
    return output;
  };
// form 没有转科记录
export const convertGetInpVisitInHospitalWithBedVoByIdRes2VSFormInpPreTransferApplyCreate =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.inpVisitId = _.get(input, 'data.id');
    output.patientId = _.get(input, 'data.patient.id');
    output.patientDisplayId = _.get(input, 'data.patient.displayId');
    output.patientName = _.get(input, 'data.patient.name');
    output.patientGender = _.get(input, 'data.patient.gender');
    output.bedId = _.get(input, 'data.patientInHospitalBaseDto.bed.id');
    output.bedLabel = _.get(
      input,
      'data.patientInHospitalBaseDto.bed.bedLabel',
    );
    output.fromBranchInstitution = {};
    output.fromBranchInstitution.id = _.get(
      input,
      'data.patientInHospitalBaseDto.department.branchInstitution.id',
    );
    output.fromBranchInstitution.institutionName = _.get(
      input,
      'data.patientInHospitalBaseDto.department.branchInstitution.institutionName',
    );
    output.toBranchInstitution = output.fromBranchInstitution;
    output.fromDepartment = {};
    output.fromDepartment.id = _.get(
      input,
      'data.patientInHospitalBaseDto.department.id',
    );
    output.fromDepartment.departmentName = _.get(
      input,
      'data.patientInHospitalBaseDto.department.departmentName',
    ); // String 科室名称
    // output.toDepartment = output.fromDepartment;
    output.toDepartment = null;
    output.fromWard = {};
    output.fromWard.id = _.get(input, 'data.patientInHospitalBaseDto.ward.id');
    output.fromWard.departmentName = _.get(
      input,
      'data.patientInHospitalBaseDto.ward.departmentName',
    );
    // output.toWard = output.fromWard;
    output.toWard = null;
    output.institutionId = _.get(input, 'data.institutionId');
    output.memo = _.get(input, 'memo');
    return output;
  };
// form查询有转科记录
export const convertGetAllByInpTransferApplyByVisitIdAndStatusQtoRes2VSFormInpPreTransferApplyCreate =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'id');
    output.inpVisitId = _.get(input, 'inpVisit.id');
    output.patientId = _.get(input, 'patient.id');
    output.patientDisplayId = _.get(input, 'patient.displayId');
    output.patientName = _.get(input, 'patient.name');
    output.patientGender = _.get(input, 'patient.gender');
    output.bedId = _.get(input, 'inpVisit.patientInHospitalBaseDto.bed.id');
    output.bedLabel = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.bed.bedLabel',
    );
    output.fromBranchInstitution = {};
    output.fromBranchInstitution.id = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.department.branchInstitution.id',
    );
    output.fromBranchInstitution.institutionName = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.department.branchInstitution.institutionName',
    );
    output.fromDepartment = {};
    output.fromDepartment.id = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.department.id',
    );
    output.fromDepartment.departmentName = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.department.departmentName',
    );
    output.fromWard = {};
    output.fromWard.wardId = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.ward.id',
    );
    output.fromWard.departmentName = _.get(
      input,
      'inpVisit.patientInHospitalBaseDto.ward.departmentName',
    ); // String 病区名称
    output.toBranchInstitution = output.fromBranchInstitution;
    output.toDepartment = input.toDepartment.departmentName;
    output.toWard = input.toWard.departmentName;
    output.memo = _.get(input, 'memo');
    output.institutionId = _.get(input, 'inpVisit.institutionId'); // Long 医疗机构编码
    output.status = _.get(input, 'status');
    return output;
  };

// 保存
export const convertVSFormInpPreTransferApplyCreate2SaveInpPreTransferApplyReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.inpVisitId = _.get(input, 'inpVisitId'); // Long 住院流水号
    output.btoParam.patientId = _.get(input, 'patientId'); // Long 患者主索引
    output.btoParam.fromDepartmentId = _.get(input, 'fromDepartment.id'); // Long 来源科室
    output.btoParam.toDepartmentId = _.get(input, 'toDepartment.id'); // Long 去向科室
    output.btoParam.fromWardId = _.get(input, 'fromWard.id'); // Long 来源病区
    output.btoParam.toWardId = _.get(input, 'toWard.id'); // Long 去向病区
    output.btoParam.memo = _.get(input, 'memo');
    output.btoParam.status = 'AUDIT_SUCCESS'; // 创建时（无id值时），默认为审核通过，其他场景会有不同的默认值
    output.btoParam.stopOrderIndicator = true; // 创建时（无id值时），默认值停所有长期医嘱，其他场景会有不同的默认值
    output.btoParam.transferWardIndicator = false; // 创建时（无id值时），默认值非转病区，其他场景会有不同的默认值
    output.extApiParam = {}; // 扩展API入参
    return output;
  };
// 患者列表查询
export const convertGet = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output?.push(
    ..._.get(input, 'data.result')?.map((d) => {
      const obj = {};
      obj.bedLabel = _.get(d, 'bedLabel');
      obj.bedStatus = _.get(d, 'bedStatus');
      obj.wardName = _.get(d, 'wardName');
      obj.preTransferIndicator = _.get(
        d,
        'clinicPatientBedDto.preTransferIndicator',
      );
      obj.skinTestPositiveIndicator = _.get(
        d,
        'clinicPatientBedDto.skinTestPositiveIndicator',
      );
      obj.skinTestDrugName = _.get(d, 'clinicPatientBedDto.skinTestDrugName');
      obj.patientAllergyIndicator = _.get(
        d,
        'clinicPatientBedDto.patientAllergyIndicator',
      );
      obj.patientAllergy = _.get(d, 'clinicPatientBedDto.patientAllergy')?.map(
        (pa) => {
          if (pa.allergyDrugProductName) {
            return pa.allergyDrugProductName;
          } else if (pa.allergyDrugName) {
            return pa.allergyDrugName;
          } else {
            return pa.allergyDrugClassName;
          }
        },
      );
      obj.crisisIndicator = _.get(d, 'clinicPatientBedDto.crisisIndicator');
      obj.seriousIndicator = _.get(d, 'clinicPatientBedDto.seriousIndicator');
      obj.waitExamReportIndicator = _.get(
        d,
        'clinicPatientBedDto.waitExamReportIndicator',
      );
      obj.pathwayImportIndicator = _.get(
        d,
        'clinicPatientBedDto.pathwayImportIndicator',
      );
      obj.drugResistanceType = _.get(
        d,
        'clinicPatientBedDto.drugResistanceType',
      );
      obj.drugResistanceName = _.get(
        d,
        'clinicPatientBedDto.drugResistanceName',
      );
      obj.expectedDischargeDate = _.get(
        d,
        'clinicPatientBedDto.expectedDischargeDate',
      );
      obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
      obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
      obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
      obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
      obj.attendingDoctorName = _.get(
        d,
        'clinicPatientBedDto.attendingDoctorName',
      );
      obj.inChargeDoctorName = _.get(
        d,
        'clinicPatientBedDto.inChargeDoctorName',
      );
      obj.chargeType = _.get(d, 'clinicPatientBedDto.chargeType');
      obj.admissionWardDateTime = _.get(
        d,
        'clinicPatientBedDto.admissionWardDateTime',
      );
      obj.admissionDateTime = _.get(d, 'clinicPatientBedDto.admissionDateTime'); // 入院时间
      obj.admissionDays = _.get(d, 'clinicPatientBedDto.admissionDays'); // 入院天数
      obj.needPayAmount = _.get(d, 'clinicPatientBedDto.needPayAmount'); // 欠费金额
      obj.dischargeVisitType = _.get(
        d,
        'clinicPatientBedDto.dischargeVisitType',
      ); // 出院类别
      obj.medicalGroupName = _.get(d, 'clinicPatientBedDto.medicalGroupName'); //诊疗组名称
      obj.operationEndDateTime = _.get(
        d,
        'clinicPatientBedDto.operationEndDateTime',
      );
      obj.vteIndicator = _.get(d, 'clinicPatientBedDto.vteIndicator');
      obj.vteScore = _.get(d, 'clinicPatientBedDto.vteScore');
      obj.catheterIndicator = _.get(d, 'clinicPatientBedDto.catheterIndicator');
      obj.catheterScore = _.get(d, 'clinicPatientBedDto.catheterScore');
      obj.pressureIndicator = _.get(d, 'clinicPatientBedDto.pressureIndicator');
      obj.pressureScore = _.get(d, 'clinicPatientBedDto.pressureScore');
      obj.painIndicator = _.get(d, 'clinicPatientBedDto.painIndicator');
      obj.painScore = _.get(d, 'clinicPatientBedDto.painScore');
      obj.fallIndicator = _.get(d, 'clinicPatientBedDto.fallIndicator');
      obj.fallScore = _.get(d, 'clinicPatientBedDto.fallScore');
      obj.id = _.get(d, 'id');
      obj.attendingDoctorId = _.get(d, 'clinicPatientBedDto.attendingDoctorId');
      obj.inChargeDoctorId = _.get(d, 'clinicPatientBedDto.inChargeDoctorId');
      obj.inChargeNurseId = _.get(d, 'clinicPatientBedDto.inChargeNurseId');
      obj.inChargeNurseName = _.get(d, 'clinicPatientBedDto.inChargeNurseName');
      obj.admissionWardDays = _.get(d, 'clinicPatientBedDto.admissionWardDays');
      obj.beforeDischargeDays = _.get(
        d,
        'clinicPatientBedDto.beforeDischargeDays',
      );
      obj.nursingClass = _.get(d, 'clinicPatientBedDto.nursingClass');
      obj.afterOperationDays = _.get(
        d,
        'clinicPatientBedDto.afterOperationDays',
      );
      obj.diagnosisDescription = _.get(
        d,
        'clinicPatientBedDto.diagnosisDescription',
      );
      obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');
      obj.memo = _.get(d, 'memo');

      return obj;
    }),
  );
  return output;
};
// 入参转换
export const convertSearchPrarms = (input) => {
  const _ = window._ ?? {};
  const searchParams = {};
  searchParams.departmentIdIs = _.get(input, 'departmentIdIs');
  searchParams.wardIdIs = _.get(input, 'wardIdIs');
  searchParams.expectedDischargeDateRangeIn = _.get(
    input,
    'expectedDischargeDateRangeIn',
  );
  searchParams.appointOperationDateTimeRangeIn = _.get(
    input,
    'appointOperationDateTimeRangeIn',
  );
  searchParams.crisisIndicatorIs = _.get(input, 'crisisIndicatorIs');
  searchParams.seriousIndicatorIs = _.get(input, 'seriousIndicatorIs');
  searchParams.patientTypeIn = _.get(input, 'patientTypeIn');
  searchParams.patientAuthorityTypeIn = _.get(input, 'patientAuthorityTypeIn');
  searchParams.attendingDoctorIdIs = _.get(input, 'attendingDoctorIdIs');
  searchParams.medicalGroupIdIs = _.get(input, 'medicalGroupIdIs');
  searchParams.patientIdIs = _.get(input, 'patientIdIs');
  searchParams.patientNameLike = _.get(input, 'patientNameLike');
  searchParams.bedLabelIs = _.get(input, 'bedLabelIs');
  searchParams.inChargeDoctorIdIs = _.get(input, 'inChargeDoctorIdIs');
  // searchParams.ext.statisticsCodes = _.get(input, 'statisticsCodes');
  return searchParams;
};
