import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_useDispenseWindowNo_5107a0: {
    method: 'post',
    url: '/api/drug-prescription-dispense/use-dispense-window-no',
    parameterFomatter: (data?: {
      bto: UpdateDispenseWindowSettingUseStaffBtoDrugPrescriptionDispenseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugPrescriptionDispenseController_unUseDispenseWindowNo_3fb802: {
    method: 'post',
    url: '/api/drug-prescription-dispense/un-use-dispense-window-no',
    parameterFomatter: (data?: {
      bto: UpdateDispenseWindowSettingUseStaffBtoDrugPrescriptionDispenseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugPrescriptionDispenseController_getLocalDispenseWindowSetting_302b1f: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-dispense-window-setting',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DispenseWindowSettingStorageVoDrugPrescriptionDispenseEntranceWebVo,
    ) => data,
  },
  DrugPrescriptionDispenseController_getCurrentStorageDispenseWindowSettings_cb90a1:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-current-storage-dispense-window-settings',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: DispenseWindowSettingStorageVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
});
