import vsf from '@vs/vsf-boot';

const convertQueryHerbWaitDispenseMasterListRes2TableHerbWaitDispenseListRo = (
  input,
) => {
  const _ = window._ ?? {};
  if (!_.get(input, 'data.result')) return [];
  const output: any = []; // TODO 请确认下行字段是否符合逻辑
  output.push(
    ..._.get(input, 'data.result')?.map((d) => {
      const obj: any = {};
      obj.createdAt = _.get(d, 'createdAt'); // TODO 请确认本行字段是否符合逻辑
      obj.name = _.get(d, 'patient.name'); // TODO 请确认本行字段是否符合逻辑
      obj.displayId = _.get(d, 'patient.displayId'); // TODO 请确认本行字段是否符合逻辑
      obj.herbType = _.get(d, 'prescription.orderHerb.herbType'); // TODO 请确认本行字段是否符合逻辑
      obj.windowDescription = _.get(d, 'dispenseWindow.windowDescription'); // TODO 请确认本行字段是否符合逻辑
      obj.id = _.get(d, 'id'); // TODO 请确认本行字段是否符合逻辑
      obj.outpVisitId = _.get(d, 'outpVisitId');
      return obj;
    }),
  );
  return output;
};

const getPatInfo = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.name = _.get(input, 'patient.name');
  output.gender = _.get(input, 'patient.gender');
  output.age = _.get(input, 'age');
  output.displayId = _.get(input, 'patient.displayId');
  output.workAddress = _.get(input, 'patient.patientProfileDto.workUnit.name');
  output.height = _.get(input, 'height');
  output.weight = _.get(input, 'weight');
  output.pressure = _.get(input, 'pressure');
  output.allergyName = _.get(input, 'allergyName');
  output.chargeTypeName = _.get(input, 'chargeTypeName');
  output.identityName = _.get(input, 'identityName');
  output.phoneNumber = _.get(input, 'patient.phoneNumber');
  output.haveRefundSettle = _.get(input, 'haveRefundSettle');
  return output;
};

const convertGetHerbWaitDispenseDetailByMasterIdRes2FormHerb = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.createdAt = _.get(input, 'data.createdAt');
  output.herbDecoctIndicator = _.get(input, 'data.herbDecoctIndicator');
  output.prescriptionSource = _.get(input, 'data.prescriptionSource'); // TODO 请确认下行字段是否符合逻辑
  output.prescriptionNumber = _.get(
    input,
    'data.prescriptionInfo.prescriptionNumber',
  ); // TODO 请确认下行字段是否符合逻辑
  output.staffName = _.get(
    input,
    'data.prescriptionInfo.orderHerb.order.doctorInfo.staffName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.departmentName = _.get(
    input,
    'data.prescriptionInfo.orderHerb.order.orderDepartmentInfo.departmentName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.agreedPrescriptionName = _.get(
    input,
    'data.prescriptionInfo.orderHerb.agreedPrescriptionName',
  );
  output.diagnosisName = _.get(input, 'data.diagnosisName'); // TODO 请确认下行字段是否符合逻辑
  output.herbType = _.get(input, 'data.prescriptionInfo.orderHerb.herbType'); // TODO 请确认下行字段是否符合逻辑
  output.useWay = _.get(input, 'data.prescriptionInfo.orderHerb.useWay');
  output.herbRegimen = _.get(
    input,
    'data.prescriptionInfo.orderHerb.herbRegimen',
  );
  output.averageCharge = _.get(input, 'data.averageCharge');
  output.totalCharge = _.get(input, 'data.totalCharge'); // TODO 请确认下行字段是否符合逻辑
  output.decoctMethod = _.get(
    input,
    'data.prescriptionInfo.orderHerb.decoctMethod',
  );
  return output;
};

const convertGetHerbWaitDispenseDetailByMasterIdRes2TableHerbDetail = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = []; // TODO 请确认下行字段是否符合逻辑
  output.push(
    ..._.get(input, 'data.drugPrescriptionWaitDispenseDetailList').map(
      (d, i) => {
        const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
        obj.drugProductName = _.get(
          d,
          'drugPriceItemInfo.drugProductInfo.drugProductName',
        );
        obj.amount = _.get(d, 'amount');
        obj.medicalInsuranceType = _.get(
          d,
          'outpBillingDetailInfo.medicalInsuranceType',
        );
        obj.amountPackageUnit = _.get(d, 'amountPackageUnit');
        obj.price = _.get(d, 'outpBillingDetailInfo.price');
        obj.charge = _.get(d, 'outpBillingDetailInfo.charge');
        obj.supplyIndicator = _.get(d, 'supplyIndicator');
        obj.charge = _.get(d, 'outpBillingDetailInfo.charge');
        obj.useDescription = _.get(d, 'orderHerbDetailInfo.useDescription');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.id = _.get(d, 'id');
        return obj;
      },
    ),
  );
  return output;
};

const convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispense = (
  input,
) => {
  return {
    secondPrepareDrugStaffId: input?.staff?.id,
    staffName: input?.staff?.staffName,
  };
};

export const onDrugInfoFetch = async (params) => {
  // Tips: 函数入参包含通过表单params属性传入的额外参数(平铺)
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        ...params,
      },
    ); // Tips: 需要返回内容数据，结构和表单RO一致
  const output = convertGetHerbWaitDispenseDetailByMasterIdRes2FormHerb(res);
  return output;
};

export const list = async (params) => {
  // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_queryHerbWaitDispenseMasterList_f7df6f?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          // from: params.pagination?.from ?? 0,
          // size: params.pagination?.size ?? 20,
          // orderList: params.orderList,
          // ...params.search,
          // ...params.filters,
          // ...(params?.extra ?? {}),
          // ...(params ?? {}),
          ...params,
        },
        ext: {},
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output =
    convertQueryHerbWaitDispenseMasterListRes2TableHerbWaitDispenseListRo(res);
  return output ?? [];
};

export const getDetailById = async (params) => {
  if (!params?.id) {
    return {};
  }
  const res =
    await vsf.services?.ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd?.(
      {
        ...params,
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  return getPatInfo(res?.data);
};

export const getTableData = async (params) => {
  if (!params?.id) {
    return [];
  }
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935?.(
      {
        ...params,
      },
    );
  const output =
    convertGetHerbWaitDispenseDetailByMasterIdRes2TableHerbDetail(res);
  return {
    data: output, // res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};

export const drugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispense(m),
  );
};

export const windowDetail = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getLocalDispenseWindowSetting_302b1f?.(
      {},
    );
  return res?.data;
};

export const useDispenseWindowNo = async (params) => {
  return await vsf.services?.DrugPrescriptionDispenseController_useDispenseWindowNo_5107a0?.(
    params,
  );
};

export const unUseDispenseWindowNo = async (params) => {
  return await vsf.services?.DrugPrescriptionDispenseController_unUseDispenseWindowNo_3fb802?.(
    params,
  );
};
export const getRefundState = async (params) => {
  return await vsf.services?.DrugPrescriptionDispenseController_hasRefundChargeForWaitDispense_015e35?.(
    params,
  );
};
