import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSForm,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import { phoneNumber, phoneNumberMessage } from '@/pattern';
import AllergyTable from '@/pages/Index/cpoe/center/left/previous_history/allergy_table';
import FormTitle from '@/pages/Index/components/form_title';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';
import {
  convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail,
  convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq,
  convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq,
} from '../convert';
const Index = (props, ref) => {
  const {} = props;

  const [open, setOpen] = useState(false);
  // 详情
  const [bedPatientId, setBedPatientId] = useState();
  const [bedInpVisitId, setBedInpVisitId] = useState();

  // useEffect(() => {
  //   getPagedByUserStaffQto();
  // }, [search, detail?.departmentId]);

  useImperativeHandle(
    ref,
    () => ({
      handelModal,
    }),
    [],
  );

  const modalProps = {
    open: open,
    title: (
      <div style={{ fontSize: 20, fontWeight: 500, color: '#3276E8' }}>
        既往史
      </div>
    ),
    cancelText: '取消',
    okText: '确认',
    width: 1000,

    centered: true,
  };

  const handelModal = (value, detail) => {
    console.log(value, detail);
    setOpen(value);
    setBedPatientId(detail?.patientId);
    setBedInpVisitId(detail?.inpVisitId);
    // detail.patientId
    // if (value) {
    //   getPagedByUserStaffQto();
    //   getInpVisitBaseWithDiagnosisVoById(bedCardId);
    // }
  };

  return (
    <div className="bed_card_detail_modal">
      <Modal
        {...modalProps}
        onCancel={() => {
          handelModal(false);
        }}
        // onOk={() => {
        //   handelModal(false);
        // }}
        // onOk={save}
        bodyStyle={{
          padding: '12px 24px',
          backgroundColor: '#f4f5f7',
          height: 400,
          // overflowY: 'scroll',
          overflowX: 'hidden',
        }}
        footer={
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => {
                handelModal(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <AllergyTable
          nurseInVisitInfo={{
            inpVisit: { id: bedInpVisitId },
            patient: { id: bedPatientId },
          }}
          type="bedCardAllergy"
          scrollYValue={250}
          currentBedPatient={bedPatientId}
          editModalWidth={1000}
        />
      </Modal>
    </div>
  );
};
// export default Index;
export default React.forwardRef(Index);
