import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleMasterController_queryByInpSettleMasterWithPaymentDetailQtoPaged_bcdd07:
    {
      method: 'post',
      url: '/api/inp-billing/query-by-inp-settle-master-with-payment-detail-qto-paged',
      parameterFomatter: (data?: {
        qto: InpSettleMasterWithPaymentDetailQtoInpBillingPersistQto;
      }) => data,
      responseFormatter: (_, __, data?: VSQueryResult) => data,
    },
  UserGroupController_getInpChargeGroupMemberList_61f49d: {
    method: 'post',
    url: '/api/user-group/get-inp-charge-group-member-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupMemberDetailVoUserGroupEntranceWebVo[],
    ) => data,
  },
});
