import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSTableAddition,
  VSPromiseTable,
  Button,
  VSTableToolbar,
  Modal,
  message,
} from '@vs/vsf-kit';
import React, { useState } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem } from '@/utils';
import Icon from '@/module/Icon';
import './index.less';
const ClinicRegisterPlatformTable = (props) => {
  const [status, setStatus] = useState(true);
  const onSave = async (params) => {
    const data = vsf.refs.ClinicRegisterPlatformTable.getValues();
    const formatData = data.map((d) => {
      const dict = { ...d, registerPlatformIndicator: true };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const arr = [];
    formatData.map((item) => {
      arr.push(item.platformCode);
    });

    let newList = Array.from(new Set(arr));
    if (newList.length !== arr.length) {
      message.error('平台编号不允许重复，请修改重复的平台编号');
    } else {
      const res =
        await vsf?.services?.OutpRegisterDefineController_addUpdatePlatformDictionary_f592fa?.(
          { btoParamList: formatData, extApiParam: {} },
        );
      if (res.code === 200) {
        message.success('保存成功');
        vsf.refs.ClinicRegisterPlatformTable.reload();
      }
    }
  };

  const onFetch = async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a?.(
        {
          qto: {
            // from: params.pagination?.from ?? 0,
            // size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
            registerPlatformIndicatorIs: true,
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deletePlatformDictionary_16247a?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      vsf.refs?.ClinicRegisterPlatformTable?.reload();
    }
    return res?.code === 200;
  };

  return (
    <div className="cli-reg-layout">
      <VSPromiseTable
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.platformName}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        vsid="70851"
        id="ClinicRegisterPlatformTable"
        onFetch={onFetch}
        onRemove={onRemove}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        pagination={false}
        className="aspirin-table"
        scroll={{
          // y: getScrollY(280),
          y: getScrollYRem(20),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformCode']}
          title="平台编号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 20,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformName']}
          title="平台名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 80,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['uploadInfoAddress']}
          title="上传信息地址"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformInstitutionId']}
          title="医院在平台的编码"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableToolbar
          title="挂号平台字典维护"
          style={{ paddingLeft: '20px' }}
        >
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(ClinicRegisterPlatformTable);
