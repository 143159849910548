import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Checkbox,
  DateRangePicker,
  Radio,
  RadioGroup,
  Select,
  TabPane,
  Tabs,
  Tag,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';

const selectOpyion = [
  { label: '全部', value: '全部' },
  { label: '微生物', value: '微生物' },
  { label: 'POCT', value: 'POCT' },
  { label: '免疫', value: '免疫' },
  { label: '急症检验', value: '急症检验' },
  { label: '临检', value: '临检' },
  { label: '生化', value: '生化' },
  { label: '炎症免疫', value: '炎症免疫' },
  { label: '肿瘤类', value: '肿瘤类' },
  { label: '分子生物', value: '分子生物' },
  { label: '普通微生物', value: '普通微生物' },
];

const reportTimeDataSource = [
  { label: '近14日', value: '0.5' },
  { label: '近30日', value: '1' },
  { label: '半年', value: '6' },
];

const Index = (props) => {
  // console.log(props);

  const { handelConditionQuery, stores } = props;
  const {
    user: { currentPatient },
  } = stores;
  const [selectValueList, setSelectValueList] = useState<any>([]);

  // 检验类型
  const [selectValue, setSelectValue] = useState<string[]>(['全部']);
  // 是否本次就诊
  const [thisVisit, setThisVisit] = useState<boolean>(true);
  // 报告状态
  const [isReportValue, setIsReportValue] = useState<boolean>(false);
  // 报告时间
  const [reportTime, setReportTime] = useState();
  // 报告时间范围
  const [reportTimeRage, setReportTimeRage] = useState<any>();
  // 时间
  const [radioValue, setRadioValue] = useState();

  useEffect(() => {
    getSelectValueList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   handelConditionQuery({
  //     isReportValue,
  //     thisVisit,
  //     selectValue,
  //     reportTime,
  //     reportTimeRage,
  //   });
  // }, [
  //   handelConditionQuery,
  //   reportTime,
  //   reportTimeRage,
  //   thisVisit,
  //   isReportValue,
  //   selectValue,
  // ]);

  const getSelectValueList = async () => {
    const res = await vsf.services?.LabController_getAllByLabClassQto_a6f1c8?.({
      qto: {
        stopIndicatorIs: false,
        // 暂时不传，机构id
        // institutionIdIs
        patientIdIs: currentPatient?.patientId,
      },
    });
    if (res?.code === 200) {
      const _res = res?.data?.map((item) => {
        return { ...item, label: item?.labClassName, value: item?.id };
      });

      setSelectValueList([{ label: '全部', value: '全部' }, ...(_res ?? [])]);
    }
  };

  const handleChange = (value) => {
    handelConditionQuery?.({
      isReportValue,
      thisVisit,
      selectValue: value,
      radioValue,
      reportTimeRage,
    });
    setSelectValue(value);
  };
  const onChange = (v) => {
    setRadioValue(v);
    setReportTimeRage(undefined);
    handelConditionQuery?.({
      isReportValue,
      thisVisit,
      selectValue,
      radioValue: renderTime(v),
      reportTimeRage: 'undefined',
    });
  };

  const handleSelect = (e) => {
    if (e?.value === '全部') {
      setSelectValue([e.value]);
      handelConditionQuery?.({
        isReportValue,
        thisVisit,
        selectValue: [e.value],
        radioValue,
        reportTimeRage,
      });
    }
    if (selectValue.length === 1 && selectValue?.[0] === '全部') {
      handelConditionQuery?.({
        isReportValue,
        thisVisit,
        selectValue: [
          ...cloneDeep(selectValue).filter((item) => item !== '全部'),
          e.value,
        ],
        radioValue,
        reportTimeRage,
      });
      setSelectValue([
        ...cloneDeep(selectValue).filter((item) => item !== '全部'),
        e.value,
      ]);
    }
  };
  const renderTime = (type) => {
    if (!type) return undefined;
    if (type === '0.5') {
      return dayjs().subtract(14, 'day').format('YYYY-MM-DD 00:00:00');
    } else {
      return dayjs().subtract(type, 'month').format('YYYY-MM-DD 00:00:00');
    }
  };
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="examine_search">
      <div style={{ display: 'flex', flexShrink: 0 }}>
        <Select
          className="width140"
          // width={106}
          // height={32}
          placeholder="申请时间"
          dataSource={reportTimeDataSource}
          style={{ marginRight: '6px' }}
          onChange={onChange}
          value={radioValue}
          allowClear
        />
        <DateRangePicker
          // width={224}
          // height={32}
          placeholder={['开始时间', '结束时间']}
          style={{ marginRight: '24px' }}
          value={[reportTimeRage?.start, reportTimeRage?.end]}
          onChange={(value) => {
            const v = value;
            setRadioValue(undefined);
            handelConditionQuery?.({
              isReportValue,
              thisVisit,
              selectValue,
              radioValue: undefined,
              reportTimeRage: {
                start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
                end: v?.[0] && dayjs(v?.[1]).format('YYYY-MM-DD 23:59:59'),
              },
            });
            setReportTimeRage({
              start: v?.[0] && v?.[0],
              end: v?.[0] && v?.[1],
            });
            console.log(v, '时间范围');
          }}
          className="width240"
        />
      </div>
      <div style={{ flexShrink: 0, marginRight: '28px' }}>
        <Checkbox
          onChange={(v) => {
            handelConditionQuery?.({
              isReportValue,
              thisVisit: v,
              selectValue,
              radioValue,
              reportTimeRage: {
                start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
                end: v?.[0] && dayjs(v?.[1]).format('YYYY-MM-DD 23:59:59'),
              },
            });
            setThisVisit(v as boolean);
          }}
          value={thisVisit}
        >
          本次就诊
        </Checkbox>
        <Checkbox
          onChange={(v) => {
            handelConditionQuery?.({
              isReportValue: v,
              thisVisit,
              selectValue,
              radioValue,
              reportTimeRage: {
                start: v?.[0] && dayjs(v?.[0]).format('YYYY-MM-DD 00:00:00'),
                end: v?.[0] && dayjs(v?.[1]).format('YYYY-MM-DD 23:59:59'),
              },
            });
            setIsReportValue(v as boolean);
          }}
          value={isReportValue}
        >
          已出报告
        </Checkbox>
      </div>
      <div
        style={{
          flexShrink: 0,
          marginRight: '20px',
          color: '#343B42',
          // fontSize: '18px',
        }}
        className="icon18"
      >
        检验类型
      </div>
      <Select
        dataSource={(option) => {
          const _data = option?.label
            ? selectValueList?.filter(
                (i) =>
                  askSearch(option.label, i, 'inputCode') ||
                  askSearch(option.label, i, 'label'),
              )
            : selectValueList;
          return _data ?? [];
        }}
        // dataSource={selectValueList}
        showSearch
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="请选择报告类型"
        value={selectValue}
        tagRender={tagRender}
        onChange={handleChange}
        onSelect={(e) => {
          handleSelect(e);
        }}
      />
    </div>
  );
};

export default definePage(Index);
