import vsf, { definePage } from '@vs/vsf-boot';
import {
  Form,
  message,
  Modal,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';

import { Determine } from '@/pages/Index/components';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';

const FormRender = (props) => {
  const { dataSource, form, agreedPrescriptionIndicator } = props;
  // console.log(props);

  useEffect(() => {
    const method = {
      'add-child-class': () => {
        form?.setFieldValue('upTemplateId', props?.value?.id ?? 1);
      },
      rename: () => {
        console.log(props?.value, 'value', dataSource);
        form?.setFieldsValue(props?.value);
      },
      'add-brother-class': () => {
        form?.setFieldValue('upTemplateId', props?.value?.upTemplateId ?? 1);
      },
    };
    method?.[props?.value?.key]?.();
  }, [dataSource, form, props?.value]);

  return (
    <VSForm id="DefendFloatEdit" labelAlign="left" form={form}>
      <VSFormItem
        name={['id']}
        label="主键"
        hidden
        valueType="digit"
        fieldProps={{}}
      />
      {/* <VSFormLayout key="1" columnCount={1}>
      <VSFormItem
        name={['templateClass']}
        label="套餐分类"
        valueType="select"
        dataSource={[
          { label: '科室套餐', value: 'DEPARTMENT_TEMPLATE' },
          { label: '个人套餐', value: 'PERSONAL_TEMPLATE' },
          { label: '临床路径', value: 'CLINICAL_PATHWAY' },
          { label: '全院套餐', value: 'HOSPITAL_TEMPLATE' },
          { label: '临床试验', value: 'GCP' },
          { label: '检查带药', value: 'EXAM_VS_DRUG' },
          { label: '检验带检验', value: 'LAB_VS_LAB' },
        ]}
        fieldProps={{}}
      />
    </VSFormLayout> */}
      <Determine
        condition={agreedPrescriptionIndicator}
        // fallback={
        //   <VSFormLayout columnCount={1} labelWidth={100}>
        //     <VSFormItem
        //       hidden={props?.value?.key === 'rename'}
        //       valueType="radioButton"
        //       label="可见范围"
        //       name={['templateClass']}
        //       initialValue={'PERSONAL_TEMPLATE'}
        //       dataSource={[
        //         {
        //           value: 'PERSONAL_TEMPLATE',
        //           label: '个人',
        //         },
        //         {
        //           value: 'DEPARTMENT_TEMPLATE',
        //           label: '科室',
        //         },
        //       ]}
        //       fieldProps={{
        //         buttonStyle: 'solid',
        //       }}
        //     />
        //   </VSFormLayout>
        // }
      >
        <VSFormLayout columnCount={1} labelWidth={100}>
          <VSFormItem
            valueType="radioButton"
            label="使用范围"
            name={['clinicClass']}
            initialValue={'ERP'}
            dataSource={[
              { label: '全院', value: 'ERP' },
              { label: '住院', value: 'INP' },
              { label: '门诊', value: 'OUTP' },
            ]}
            fieldProps={{
              buttonStyle: 'solid',
            }}
          />
        </VSFormLayout>
      </Determine>
      <Determine
        condition={props?.value?.label?.includes('模版')}
        fallback={
          <VSFormItem
            name={['upTemplateId']}
            label="上级编码"
            valueType="digit"
            fieldProps={{}}
            hidden
            initialValue={1}
          />
        }
      >
        <VSFormLayout columnCount={1} labelWidth={100}>
          <VSFormItem
            name={['upTemplateId']}
            label="模版分类"
            valueType="selectCode"
            rules={[
              {
                required: true,
              },
            ]}
            fieldNames={{
              label: 'title',
              value: 'id',
            }}
            fieldProps={{
              showSearch: true,
              placeholder: '请选择模版分类',
            }}
            transform={(value) => ({
              upTemplateId: value?.value ?? value?.id,
            })}
            dataSource={[
              {
                title: '顶级目录',
                id: 1,
              },
              ...(dataSource ?? []),
            ]}
          />
        </VSFormLayout>
      </Determine>
      <VSFormLayout key="3" columnCount={1} labelWidth={100}>
        <VSFormItem
          name={['title']}
          label={`${
            props?.value?.label?.includes('模版') ? '模版' : '分类'
          }名称`}
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            {
              required: true,
            },
          ]}
          fieldProps={{
            onChange: (event) => {
              vsf?.refs?.DefendFloatEdit?.setFieldValue(
                'inputCode',
                askRomanAlphabet(event?.target?.value),
              );
            },
          }}
        />
        <VSFormItem
          name={['inputCode']}
          label="输入码"
          valueType="text"
          rules={[
            {
              required: true,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['sortNumber']}
          label="排序号"
          valueType="digit"
          hidden={props?.value?.key === 'rename'}
          initialValue={
            ['add-brother-class', 'add-child-class'].includes(props?.value?.key)
              ? (props?.value?.sortNumber ?? 0) + 1
              : void 0
          }
          rules={[
            {
              message: '排序号的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
            {
              required: true,
            },
          ]}
          fieldProps={{
            width: '100%',
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
};

const DefendFloatEdit = (props) => {
  const { template, agreedPrescriptionIndicator, reload, onSortNumber } = props;
  const onOk = async () => {
    try {
      const value =
        await vsf.refs.DefendFloatEdit.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.OrderTemplateMasterController_createOrderTemplateMaster_f5aa85(
          {
            btoParam: {
              ...value,
              ...(agreedPrescriptionIndicator
                ? {
                    templateClass: 'HOSPITAL_TEMPLATE',
                  }
                : {
                    templateClass: template,
                  }),
              folderOrTemplateIndicator: props?.value?.label?.includes('模版')
                ? 'TEMPLATE'
                : 'FOLDER',
              agreedPrescriptionIndicator,
            },
          },
        );
      if (res?.code === 200) {
        props?.onClose();
        message?.success('保存成功');
        reload();
      }
    } catch {
      return Promise?.reject();
    }
  };
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const templateClass = Form?.useWatch('templateClass', form);
  /**
   * 切换分类需要更新数据源并且清空已选值
   */
  useAsyncEffect(async () => {
    const res =
      await vsf.services?.OrderTemplateMasterController_getOrderTemplateMaster_bfb379?.(
        {
          templateClass,
          agreedPrescriptionIndicator,
        },
      );
    form?.setFieldValue('upTemplateId', undefined);
    setDataSource(
      res?.data
        ?.filter((item) => item?.folderOrTemplateIndicator === 'FOLDER')
        ?.map((item) => ({
          ...item,
          upTemplateId: item?.upTemplateId === 1 ? null : item?.upTemplateId,
        })),
    );
  }, [templateClass, agreedPrescriptionIndicator]);
  return (
    <Modal
      id="DefendTemplate"
      onOk={onOk}
      className="aspirin-linkpage"
      title={props?.value?.label}
      cancelText="取消"
      okText="确定"
      open={props?.open}
      onCancel={props?.onClose}
    >
      <FormRender
        {...props}
        dataSource={dataSource}
        form={form}
        onSortNumber={onSortNumber}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
      />
    </Modal>
  );
};
export default definePage(DefendFloatEdit);
