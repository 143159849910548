import React from 'react';

import { Section } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import Icon from '@/module/Icon';
// import askGetTreeWhole from '@/pages/Index/components/func/ask/askGetTreeWhole';
import vsf, { definePage } from '@vs/vsf-boot';

const PatientGroup = (props) => {
  // const handleAddWithParent = (node) => {
  //   props.onChange?.({
  //     upInstitutionId: node?.id,
  //     upInstitutionName: node?.institutionName,
  //   });
  // };
  const {
    stores: {
      common: { currentApplication },
    },
  } = props;

  return (
    <TreeMenu
      id="InstitutionTree"
      expand
      // loadData={async () => {
      //   const res =
      //     await vsf.services?.OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4?.(
      //       {
      //         qto: {},
      //       },
      //     );

      //   const tempData = askTree(res?.data, {
      //     parentId: 'upDepartmentCode',
      //     id: 'departmentCode',
      //   })
      //     .map((item) => {
      //       return {
      //         ...item,
      //         children: item?.children?.toSorted((a, b) => {
      //           return a?.serialNumber - b?.serialNumber;
      //         }),
      //       };
      //     })
      //     ?.toSorted((a, b) => {
      //       return a?.id - b?.id;
      //     });

      //   console.log(tempData);
      //   return {
      //     // data: tempData,
      //     data:
      //       res?.data?.length > 0
      //         ? [
      //             {
      //               departmentName:
      //                 res?.data?.[0]?.branchInstitution?.institutionName,
      //               id: 'parentId',
      //               children: tempData,
      //             },
      //           ]
      //         : [],
      //   };
      // }}
      loadData={async () => {
        const res = await vsf.services.DepartmentDtoController_getAll_d6e9c4({
          qto: {
            institutionIdIs:
              currentApplication?.department?.branchInstitution
                ?.upInstitutionId,
            // branchInstitutionIdIs:
            //   currentApplication?.department?.branchInstitution?.id,
          },
        });
        const _data = res?.data.reduce((acc, item, index) => {
          // 查找是否已存在具有相同id的项
          const existingGroup = acc.find(
            (group) => group.departmentCode == item.branchInstitution?.id,
          );
          // 如果不存在，则创建一个新的组
          if (!existingGroup) {
            acc.push({
              departmentName: item.branchInstitution?.institutionName,
              departmentCode: item.branchInstitution?.id,
              upInstitutionId: item.branchInstitution?.upInstitutionId,
              children: [{ ...item }],
            });
          } else {
            // 如果存在，则将当前项添加到该组的arr数组中
            existingGroup.children.push(item);
          }
          // 返回累积的数组
          return acc;
        }, []);
        const tempData = _data?.map((i, index) => {
          return {
            id: Math.random(),
            // 选择院区
            disabledAdd: true,
            departmentName: i?.departmentName,
            departmentCode: i?.departmentCode,
            children: askTree(i?.children, {
              parentId: 'upDepartmentCode',
              id: 'departmentCode',
            }),
          };
        });
        console.log(tempData, _data, 'tempData');
        return {
          // data: tempData,
          data: res?.data?.length > 0 ? tempData : [],
        };
        return res;
      }}
      isTree
      fieldNames={{
        title: 'departmentName',
        key: 'id',
        children: 'children',
        pinyinSearch: 'inputCode',
      }}
      dataRelationFieldNames={{
        id: 'departmentCode',
        parentId: 'upDepartmentCode',
      }}
      onSelect={(_value, tree) => {
        vsf.refs.patientGroupTable.getSearchForm().resetFields();
        if (_value?.id == 'parentId') return;
        props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
      }}
      search={{ status: true, placeholder: '请输入' }}
      title="科室名称"
      reload
      row={{
        // expand: true,
        extraShow: 'default',
      }}
    />
  );
};
export default definePage(PatientGroup);
