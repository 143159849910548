import {
  Divider,
  Form,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import { useSetState } from 'ahooks';
import { debounce } from 'lodash';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';

import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import Icon from '@/module/Icon';
import Screen from '@/pages/Index/components/screen';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import ComparePrice from '../../components/ComparePrice';
import TableRender from '../../components/ComparePrice';
import LabItemEdit from '../labEdit';
import { askFixed } from '@/pages/Index/components/func/ask';
import { Flex } from '@/pages/Index/components';
import int from '../../components/int';
import { getScrollYRem } from '@/utils';
const LabItemFunc = (props) => {
  const table = useRef(null);
  const stopIndicatorIs = Form.useWatch(
    'stopIndicatorIs',
    table.current?.getSearchForm?.(),
  );
  const auditIndicatorIs = Form.useWatch(
    'auditIndicatorIs',
    table.current?.getSearchForm?.(),
  );

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ClinicItemBaseController_getAllByClinicItemLabNewQtoByClinicItemLabNewQto_4ab6b4?.(
          {
            qto: {
              ...(props.value ? { classIdIs: props.value?.id } : {}),
              orderList: params.orderList, // 排序结果
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              auditIndicatorIs,
              stopIndicatorIs,
            },
          },
        );
      if (res.code === 200) {
        const id = res?.data?.find(
          (item) => item?.id === getDataStore()?.id?.id,
        );
        if (id) {
          setDataStore({
            id,
          });
        }
      }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props.value, stopIndicatorIs, auditIndicatorIs],
  );

  const [dataStore, , getDataStore, , , setDataStore] = useGetResetInitState({
    initialState: [],
    data: [],
    id: {
      stopIndicator: true,
      auditIndicator: true,
    },
    dataSource: [],
  });

  useEffect(() => {
    setDataStore({
      id: {
        stopIndicator: true,
        auditIndicator: true,
      },
    });
  }, [props?.value?.id]);

  const [scrollTop, setScrollTop] = useSetState({ x: 2000 });

  const onStop = async (id) => {
    const res =
      await vsf?.services?.ClinicItemBaseController_stopClinicItem_49a98d?.({
        id,
      });
    if (res.code === 200) {
      vsf.refs.LabItem.reload();
      message.success('停用成功');
    }
  };

  const TableSubmit = async ({ params, url, bto }) => {
    /**
     * 特殊处理执行科室/限定科室
     * @param {*} extension
     * @returns
     */
    const getLabItemVsDepartmentBtoList = (extension) => {
      try {
        return extension?.flatMap((item) => {
          if (item.labItemVsDepartmentBtoList) {
            const list = Object.keys(item?.labItemVsDepartmentBtoList);
            return list?.flatMap((label) => {
              return item?.labItemVsDepartmentBtoList?.[label]?.map((lab) => ({
                ...lab,
                labDepartmentType: label,
              }));
            });
          }
        });
      } catch {
        return extension?.flatMap((item) => item.labItemVsDepartmentBtoList);
      }
    };
    try {
      const {
        labVsClinicItemDto: {
          labItemExtensionBtoList,
          labItemVsSampleLocationBtoList: sample,
          ...dto
        },
        ...lab
      } = params;
      console.log(params, 'params');
      const extension = Object.values(labItemExtensionBtoList.select);

      const res = await vsf?.services?.[url]?.({
        btoParam: {
          ...lab,
          branchInstitutionIdList: labItemExtensionBtoList?.select,
        },
        [bto]: {
          ...dto,
          labItemExtensionBtoList: extension?.map((key) => {
            const { labItemVsDepartmentBtoList, value, ...rest } =
              labItemExtensionBtoList?.form?.[`form_${key}`];
            return {
              ...rest,
              branchInstitutionId: value ?? key,
            };
          }),
          labItemVsSampleLocationBtoList: sample?.map((item) => {
            if (String(item?.id)?.startsWith('create')) delete item?.id;
            return item;
          }),
          labItemVsDepartmentBtoList: getLabItemVsDepartmentBtoList(
            extension
              ?.map((key) => labItemExtensionBtoList?.form?.[`form_${key}`])
              ?.filter(Boolean),
          ),
        },
        extApiParam: {},
      });
      if (res.code === 200) {
        message.success('保存检验项目成功');
        vsf.refs.LabItem.reload().then(() => {
          if (typeof res.data === 'number') {
            setDataStore({
              id: vsf.refs?.LabItem?.getValues().filter(
                (item) => res.data === item.id,
              )?.[0],
            });
          }
        });
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const onAdd = (params) => {
    return TableSubmit({
      params,
      url: 'ClinicItemBaseController_createClinicLabItem_7d0d43',
      bto: 'createLabItemBto',
    });
  };

  const onUpdate = (params) => {
    return TableSubmit({
      params,
      url: 'ClinicItemBaseController_updateClinicLabItem_0a1f35',
      bto: 'updateLabItemBto',
    });
  };

  const top = useRef(null);
  useEffect(() => {
    setScrollTop({
      y: top.current?.offsetHeight - 78,
    });
  }, [top]);

  return (
    <Fragment>
      <VSPromiseTable
        id="LabItem"
        height={getScrollYRem(16)}
        className="aspirin-table aspirin-table-search-padding-no aspirin-row-select-hover aspirin-table-no-background-box"
        editable={{
          editType: 'single',
          extraOperations: [
            {
              children: (node) => {
                return node.stopIndicator ? (
                  <Icon type="icon-a-bianjijinyong" size={24} />
                ) : (
                  <Icon type="icon-bianji" size={24} />
                );
              },
              show: (node) => node.stopIndicator,
              disabled: (node) => node.stopIndicator,
              order: 1,
            },
            {
              children: (node) => {
                return node.stopIndicator ? (
                  <Icon type="icon-a-jinyongjinyong" size={24} />
                ) : (
                  <Icon type="icon-tingyong" size={24} />
                );
              },
              disabled: (node) => node.stopIndicator,
              onClick: (event, node) => {
                Tip({
                  title: '操作窗口',
                  onOk: () => {
                    onStop(node.id);
                  },
                  cancelText: '取消',
                  content: `确定停用{【${node.clinicItemName}】}项目? 停用后不可恢复!`,
                });
              },
              order: 1,
            },
          ],
          editText: <Icon type="icon-bianji" size={24} />,
          onCanEdit: (data) => !data.stopIndicator,
          onCanDelete: () => null,
          columnProps: {
            width: 100,
          },
        }}
        style={{
          maxHeight: getScrollYRem(16),
        }}
        vsid="65825"
        ref={table}
        rowClassName={(...arr) => {
          const [recode] = arr;
          return recode.id === dataStore.id.id
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box '
            : 'aspirin-vs-table-row-box aspirin-row-select-box';
        }}
        rowClick={{
          rowClickType: 'select',
          onRowClick: (data) => {
            setDataStore({
              id: data,
            });
          },
        }}
        onFetch={onFetch}
        scroll={scrollTop}
        pagination={false}
        tableRender={(_, dom) => {
          return (
            <TableRender
              value={dataStore?.id}
              // height={window.innerHeight - 410}
              height={getScrollYRem(25)}
              clinicItemId={dataStore.id?.labVsClinicItemDto?.clinicItemId}
              reload={vsf.refs?.LabItem?.reload}
              onDragMove={debounce((value) => {
                setScrollTop({
                  y: value - 88,
                });
              }, 500)}
            >
              <div className="aspirin-compare-price-top-box" ref={top}>
                {dom}
              </div>
            </TableRender>
          );
        }}
        searchFormRender={(props, dom) => (
          <div className="aspirin-table-search-inline-no">
            <SearchContainer mode="inline" formPadding="0 170px 0 100px">
              {dom}
            </SearchContainer>
            <Divider />
          </div>
        )}
        searchConfig={{
          labelWidth: '20%',
          labelAlign: 'left',
          collapsed: false,
          collapseRender: false,
          optionRender: (...arr) => {
            const [, , [other, search]] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {[
                  other,
                  <ButtonAndKeyDown
                    methodKey="altKey"
                    ordinaryKey="Q"
                    {...search?.props}
                  >
                    查询
                  </ButtonAndKeyDown>,
                ]}
              </SearchContainer.Header>
            );
          },
        }}
        onAdd={onAdd}
        onUpdate={onUpdate}
        addLinkPage={{
          linkPage: () => <LabItemEdit data={props.value} />,
          onLinkPageValidate: async () => {
            try {
              await vsf.refs.LabItemForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              console.log(e);
              return false;
            }
          },
          modalProps: {
            title: '新增检验项目',
            className: 'aspirin-linkpage',
            width: '50%',
            bodyStyle: {
              height: '70vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
          },
        }}
        updateLinkPage={{
          linkPage: () => <LabItemEdit data={props.value} />,
          onLinkPageValidate: async () => {
            try {
              await vsf.refs.LabItemForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              console.log(e);
              return false;
            }
          },
          modalProps: {
            title: '编辑检验项目',
            className: 'aspirin-linkpage',
            width: '50%',
            bodyStyle: {
              height: '70vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
          },
        }}
      >
        <VSTableColumn
          dataIndex={['labVsClinicItemDto', 'labClass', 'labClassName']}
          title="检验类别"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['clinicItemName']}
          title="检验项目"
          valueType="text"
          search
          order={-1}
          columnKey={'clinicItemNameLike'}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['specimen']}
          title="检验标本"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['physiologicalCycle']}
          title="生理周期"
          valueType="text"
          dataSource={vsf.stores.dbenums.enums.LAB_PHYSIOLOGICAL_CYCLE_DICT}
          render={(...[, record]) =>
            record?.physiologicalCycle
              ?.split(';')
              ?.map((item) => {
                return vsf.stores.dbenums.enums.LAB_PHYSIOLOGICAL_CYCLE_DICT?.find(
                  (value) => value?.value === item,
                )?.label;
              })
              ?.join(';')
          }
          shouldCellUpdate={() => true}
          fieldProps={{
            mode: 'multiple',
          }}
        />

        <VSTableColumn
          dataIndex={['sampleLocation']}
          title="采集部位"
          valueType="text"
          render={(...[, record]) =>
            record?.sampleLocation
              ?.split(';')
              ?.map((item) => {
                return vsf.stores.dbenums.enums.LAB_COLLECTION_LOCATION_DICT?.find(
                  (value) => value?.value === item,
                )?.label;
              })
              ?.join(';')
          }
          shouldCellUpdate={() => true}
          fieldProps={{
            mode: 'multiple',
          }}
        />

        <VSTableColumn
          dataIndex={['costs']}
          title="合计金额"
          valueType="custom"
          fieldProps={{}}
          render={(...[, record]) => {
            return (
              <Flex justify="flex-end">
                {int(record?.clinicVsChargeDtoList)
                  ? '实时计算'
                  : askFixed(record?.costs)}
              </Flex>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['branchInstitution']}
          title="院区"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['performDepartment']}
          title="执行科室"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['limitDepartment']}
          title="限定科室"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="停用标记"
          valueType="select"
          search
          render={(_, data) => {
            return (
              <span
                style={{ color: data.stopIndicator ? `var(--red)` : 'inherit' }}
              >
                {data.stopIndicator ? '已' : '未'}停用
              </span>
            );
          }}
          order={-2}
          columnKey={'stopIndicatorIs'}
          fieldProps={{}}
          searchFieldProps={{
            dataSource: [
              {
                label: '已停用',
                value: true,
              },
              {
                label: '未停用',
                value: false,
              },
            ],
          }}
        />

        <VSTableColumn
          dataIndex={['auditIndicator']}
          title="审核标记"
          valueType="select"
          search
          order={-4}
          columnKey={'auditIndicatorIs'}
          fieldProps={{}}
          render={(_, data) => {
            return (data.auditIndicator ? '已' : '未') + '审核';
          }}
          searchFieldProps={{
            dataSource: [
              {
                label: '已审核',
                value: true,
              },
              {
                label: '未审核',
                value: false,
              },
            ],
          }}
        />
        <VSTableToolbar
          title="检验项目列表"
          className="aspirin-toolbar-padding-top-box"
        >
          <VSTableAddition children="添加" disabled={!props?.value} />
        </VSTableToolbar>
      </VSPromiseTable>
    </Fragment>
  );
};

const LabItem = (props) => {
  return (
    <Screen>
      <LabItemFunc {...props} />
    </Screen>
  );
};

export default definePage(LabItem);
