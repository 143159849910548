import { definePage } from '@vs/vsf-boot';
import React, { useRef } from 'react';

import { Determine } from '@/pages/Index/components';
import { BeInHospital, OutHospital } from '@/pages/Index/components/order';
import PaymentCom from '@/pages/Index/components/payment_com';

import DepartmentPrice from './department_pricing';

interface Refs {
  onOpen: (value?: unknown) => void;
}

const Order = (props) => {
  const {
    prefixCls,
    stores: { user, common, settings },
  } = props;
  // console.log(user, 'data_user');
  const { hospitalType } = user ?? {};
  const { currentApplication } = common ?? {};
  const { screenSize } = settings ?? {};
  const className = `${prefixCls}-mainContainer ${prefixCls}-mainContainer-${screenSize}`;
  const departmentRef = useRef<Refs>(null);
  const clinicSettlementRef = useRef<any>(null);
  // console.log(props, 'data_props');

  return (
    <div className={className}>
      <DepartmentPrice ref={departmentRef} />
      <Determine
        condition={hospitalType === 'in'}
        fallback={
          <OutHospital
            department={departmentRef}
            clinicSettlementRef={clinicSettlementRef}
          />
        }
      >
        <BeInHospital />
      </Determine>
      <PaymentCom
        ref={clinicSettlementRef}
        callback={() => {
          return '';
        }}
      />
    </div>
  );
};

export default definePage(Order);
