import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import FormTitle from '@/pages/Index/components/form_title';

import {
  convertGetAllByErpVisitQtoFor48HoursRes2ErpVisit48List,
  convertGetAllByErpVisitQtoFor72HoursRes2ErpVisit72Hours,
} from '../convert';

const Index = (props, ref) => {
  const { getDetail } = props;

  const [open, setOpen] = useState(false);
  const [displayId, setDisplayId] = useState();
  const [listData, setData] = useState<any>();
  const [currentRow, setCurrentRow] = useState<any>();
  const [currentTab, setCurrentTab] = useState<any>('1');

  const handelOpen = (v = false, value?) => {
    if (!open) {
      setCurrentTab('1');
      setCurrentRow(null);
    }
    setOpen(v);
    setDisplayId(value);
  };

  const getList48Hour = useCallback(async () => {
    if (!displayId) return;
    const res =
      await vsf?.services?.ClinicErpVisitController_getAllByErpVisitQtoFor48Hours_6115a2?.(
        { displayId: displayId },
      );
    const output = convertGetAllByErpVisitQtoFor48HoursRes2ErpVisit48List(res);
    setData(output);
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [displayId]);

  const getList72Hour = useCallback(async () => {
    if (!displayId) return;
    const res =
      await vsf?.services?.ClinicErpVisitController_getAllByErpVisitQtoFor72Hours_16126e?.(
        { displayId: displayId },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertGetAllByErpVisitQtoFor72HoursRes2ErpVisit72Hours?.(res);
    setData(output ?? []);
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [displayId]);

  useEffect(() => {
    if (currentTab == '1') {
      getList48Hour();
    } else {
      getList72Hour();
    }
  }, [currentTab, getList72Hour, getList48Hour]);

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));
  return (
    <div>
      <Modal
        centered
        width={1100}
        bodyStyle={{
          height: 580,
          overflowY: 'scroll',
        }}
        destroyOnClose={true}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={<div style={{ color: '#3276E8' }}>患者就诊记录</div>}
        onCancel={() => {
          handelOpen(false);
        }}
        footer={
          <div>
            <Button
              type="primary"
              // ghost
              onClick={() => {
                getDetail(true);
                handelOpen(false);
              }}
            >
              新增分诊
            </Button>
            <Button
              type="primary"
              onClick={() => {
                getDetail(false, currentRow?.id);
                handelOpen(false);
              }}
              disabled={!currentRow}
            >
              二次分诊
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handelOpen(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <div className="second_triage_modal" style={{}}>
          {/* <FormTitle
            titleStytle={{ fontSize: 16 }}
            style={{ padding: 0, background: 'transparent' }}
            bodyStytle={{ padding: '0 0 10px' }}
            borderBottom="none"
            title="48小时就诊信息"
            isNeedTag={true}
          />
          <VSTable
            scroll={{
              y: 210,
            }}
            className="aspirin-table-header-background-color-white aspirin-table-row-hover-background-color-white"
            style={{ marginBottom: 20, flex: 1 }}
            pagination={false}
            value={[
              { name: '1', value: '1', id: 1 },
              { label: '2', value: '2', id: 2 },
              { label: '3', value: '3', id: 3 },
              { label: '4', value: '4', id: 4 },
              { label: '4', value: '4', id: 5 },
              { label: '4', value: '4', id: 6 },
              { label: '4', value: '4', id: 7 },
            ]}
            bordered
          >
            <VSTableColumn dataIndex={['name']} title="姓名" />
            <VSTableColumn dataIndex={['value']} title="门诊号" />
            <VSTableColumn dataIndex={['value']} title="分诊科室" />
            <VSTableColumn dataIndex={['value']} title="性别" />
            <VSTableColumn dataIndex={['value']} title="年龄" />
            <VSTableColumn dataIndex={['value']} title="时间" />
            <VSTableColumn dataIndex={['value']} title="已就诊" />
          </VSTable>
          <FormTitle
            titleStytle={{ fontSize: 16 }}
            style={{ padding: 0, background: 'transparent' }}
            bodyStytle={{ padding: '0 0 10px' }}
            borderBottom="none"
            title="72小时留观信息"
            isNeedTag={true}
          />
          <VSTable
            scroll={{
              y: 210,
            }}
            style={{ flex: 1 }}
            className="aspirin-table-header-background-color-white aspirin-table-row-hover-background-color-white"
            pagination={false}
            value={[
              { name: '1', value: '1', id: 1 },
              { label: '2', value: '2', id: 2 },
              { label: '3', value: '3', id: 3 },
              { label: '4', value: '4', id: 4 },
              { label: '4', value: '4', id: 5 },
              { label: '4', value: '4', id: 6 },
              { label: '4', value: '4', id: 7 },
            ]}
            bordered
          >
            <VSTableColumn dataIndex={['name']} title="姓名" />
            <VSTableColumn dataIndex={['value']} title="门诊号" />
            <VSTableColumn dataIndex={['value']} title="分诊科室" />
            <VSTableColumn dataIndex={['value']} title="性别" />
            <VSTableColumn dataIndex={['value']} title="年龄" />
            <VSTableColumn dataIndex={['value']} title="时间" />
            <VSTableColumn dataIndex={['value']} title="已就诊" />
          </VSTable> */}
          <Tabs
            style={{ height: '100%' }}
            type="card"
            destroyInactiveTabPane
            defaultActiveKey="1"
            // tabBarStyle={{
            //   fontWeight: 'bolder',
            //   textAlign: 'center',
            //   display: 'flex',
            // }}

            items={[
              {
                label: <span style={{ fontSize: 15 }}>48小时就诊信息</span>,
                key: '1',
                children: (
                  <VSTable
                    scroll={{
                      y: 410,
                    }}
                    className="
                    aspirin-table-header-background-color-white
                    aspirin-table-row-hover-background-color-white
                   aspirin-table-body-empty-transparent-background-color-white
                   aspirin-table-nopadding"
                    pagination={false}
                    rowClassName={(v: any) => {
                      return v?.id == currentRow?.id
                        ? 'aspirin-table-row-select-background-color'
                        : '';
                    }}
                    onRow={(record) => {
                      return {
                        onDoubleClick: () => {},
                        onClick: () => {
                          if (currentRow && currentRow?.id == record?.id) {
                            setCurrentRow(null);
                          } else {
                            setCurrentRow(record);
                          }
                        },
                      };
                    }}
                    value={listData}
                    bordered
                  >
                    <VSTableColumn
                      dataIndex={['name']}
                      title="姓名"
                      valueType="text"
                      formItemProps={{
                        rules: [
                          {
                            message: '姓名长度不合法',
                            type: 'string',
                            min: 0,
                            max: 60,
                          },
                        ],
                      }}
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['displayId']}
                      title="患者ID"
                      valueType="text"
                      formItemProps={{
                        rules: [
                          {
                            message: '用于显示的id长度不合法',
                            type: 'string',
                            min: 0,
                            max: 40,
                          },
                        ],
                      }}
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['clinicDepartmentId']}
                      title="分诊科室"
                      valueType="digit"
                      formItemProps={{
                        rules: [
                          {
                            message: 'clinic_department的值不合法',
                            type: 'number',
                            min: 0,
                            max: undefined,
                          },
                        ],
                      }}
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['gender']}
                      title="性别"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    />

                    <VSTableColumn
                      dataIndex={['age']}
                      title="年龄"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['visitDateTime']}
                      title="就诊日期"
                      valueType="date"
                      fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                    />

                    <VSTableColumn
                      dataIndex={['clinicStatus']}
                      title="已就诊"
                      valueType="select"
                      dataSource={[
                        { label: '未诊', value: 'NOT_VISIT' },
                        { label: '开诊', value: 'START_VISIT' },
                        { label: '诊结', value: 'END_VISIT' },
                      ]}
                      fieldProps={{}}
                    />
                  </VSTable>
                ),
              },
              {
                label: <span style={{ fontSize: 15 }}>72小时留观信息</span>,
                key: '2',
                children: (
                  <VSTable
                    scroll={{
                      y: 410,
                    }}
                    style={{ flex: 1 }}
                    className="
                    aspirin-table-header-background-color-white
                    aspirin-table-row-hover-background-color-white
                   aspirin-table-body-empty-transparent-background-color-white
                   aspirin-table-nopadding"
                    pagination={false}
                    rowClassName={(v: any) => {
                      return v?.id == currentRow?.id
                        ? 'aspirin-table-row-select-background-color'
                        : '';
                    }}
                    onRow={(record) => {
                      return {
                        onDoubleClick: () => {},
                        onClick: () => {
                          if (currentRow && currentRow?.id == record?.id) {
                            setCurrentRow(null);
                          } else {
                            setCurrentRow(record);
                          }
                        },
                      };
                    }}
                    value={listData}
                    bordered
                  >
                    <VSTableColumn
                      dataIndex={['name']}
                      title="姓名"
                      valueType="text"
                      formItemProps={{
                        rules: [
                          {
                            message: '姓名长度不合法',
                            type: 'string',
                            min: 0,
                            max: 60,
                          },
                        ],
                      }}
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['displayId']}
                      title="患者ID"
                      valueType="text"
                      formItemProps={{
                        rules: [
                          {
                            message: '用于显示的id长度不合法',
                            type: 'string',
                            min: 0,
                            max: 40,
                          },
                        ],
                      }}
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['gender']}
                      title="性别"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    />

                    <VSTableColumn
                      dataIndex={['age']}
                      title="年龄"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSTableColumn
                      dataIndex={['nurseAcceptDateTime']}
                      title="入观时间"
                      valueType="date"
                      fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                    />

                    <VSTableColumn
                      dataIndex={['dischargeDateTime']}
                      title="出观时间"
                      valueType="date"
                      fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                    />
                  </VSTable>
                ),
              },
            ]}
            activeKey={currentTab}
            onChange={(v) => {
              setCurrentRow(null);
              setCurrentTab(v);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 二次分诊
