import React, { memo, useState } from 'react';
import { Row, Col, message } from '@vs/vsf-kit';
import ComplexSelect from '@/pages/Index/components/complex_select';
import Loading from '@/module/Loading';
import { Table } from 'antd';
import { getTableValues, scrollTo, updateTableValues } from './methods';
import { includes } from 'lodash';

/**
 * PurchasePlanComplexSelect；
 * 复用ComplexSelect组件；
 * 作用：定义可模糊查询下拉框；
 *
 */
const PurchasePlanComplexSelect = (props) => {
  const { recode, form, value, type, table_id, setDrugProductNameValue } =
    props;
  const _ = window._ ?? {};
  const [loading, setLoading] = useState(false);
  /**
   * convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate = (
    input,
  ) => {
    setLoading(false);
    return input?.map((m) => ({
      key: m?.id,
      id: m?.id,
      drugProductName: m.drugProduct?.drugProductName,
      firmName: m.drugProduct?.drugFirmInfo?.firmName,
      packageSpecification: m.packageSpecification,
    }));
  };
  const [dataSource, setDrugData] = useState();
  // 发送请求获取数据
  const getTableDate = async (inputCodeLike) => {
    if (!inputCodeLike) return;
    setLoading(true);
    const res =
      await vsf?.services?.DrugDrugController_getPagedProfileDrugProduct_9f4f4f?.(
        { query: { inputCodeLike } },
      );

    const methods = {
      row: () => {
        const filterList = getTableValues?.(table_id).map(
          (f) => f.drugPriceItemId,
        );
        const data = _.get(res, 'data.result').filter(
          (f) => !includes(filterList, f.id),
        );
        return convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate?.(
          data,
        );
      },
      look: () =>
        convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate?.(
          _.get(res, 'data.result'),
        ),
    };
    setDrugData(methods[type]);
  };
  // 定义表格行key值
  const key = recode?.recordKey;
  const getPurchaseDetails = async (drugPriceItemId) => {
    const res =
      await vsf?.services?.DrugStoragePurchaseController_getByDrugPriceItemId_8fd8db?.(
        {
          drugPriceItemId,
        },
      );
    const t = _.get(res, 'data'),
      values = {
        drugPriceItemId: _.get(t, 'drugPriceItem.id'),
        drugProductName: _.get(t, 'drugPriceItem.drugProduct.drugProductName'),
        packageSpecification: _.get(t, 'drugPriceItem.packageSpecification'),
        firmName: _.get(t, 'drugPriceItem.drugProduct.drugFirmInfo.firmName'),
        drugIndicator:
          _.get(t, 'drugPriceItem.drugProduct.drugInfo.drugIndicator') ||
          '药品类型为空值',
        drugSpecification: _.get(
          t,
          'drugPriceItem.drugProduct.drugSpecification',
        ),
        totalAmount: _.get(t, 'totalAmount') || '全院库存为空值',
        storageAmount: _.get(t, 'storageAmount') || '库存量为空值',
        consumeAmount: _.get(t, 'consumeAmount') || 0,
        packageRatio: _.get(t, 'drugPriceItem.packageRatio') || 0,
        packageUnit: _.get(t, 'drugPriceItem.packageUnit'),
        lowLevel: _.get(t, 'lowLevel'),
        price: _.get(t, 'drugPriceItem.price'), //商品单价
      };
    form?.setFieldValue(key, values);
    updateTableValues(table_id, Object.assign({ id: key }, values));
    return;
  };
  return (
    <>
      <ComplexSelect
        value={value}
        mode="radio"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          width: 700,
        }}
        listHeight={1000}
        placeholder="请选择药品"
        onSearch={async (inputCodeLike) => getTableDate(inputCodeLike)}
        onChange={(value) => {
          const methods = {
            row: async () => {
              form?.resetFields([
                [key, 'drugProductName'],
                [key, 'drugIndicator'],
                [key, 'drugSpecification'],
                [key, 'packageSpecification'],
                [key, 'firmName'],
                [key, 'totalAmount'],
                [key, 'storageAmount'],
                [key, 'consumeAmount'],
                [key, 'packageRatio'],
                [key, 'packageUnit'],
                [key, 'lowLevel'],
              ]);
              getPurchaseDetails(value.id);
            },
            look: () => {
              setDrugProductNameValue();
              const filterList = getTableValues?.(
                'tablePurchasePlanCreate',
              ).map((f) => f.drugPriceItemId);
              if (!includes(filterList, value.key))
                return message.error('未找到药品');
              const rowDetails = getTableValues?.(
                'tablePurchasePlanCreate',
              ).find((d) => d.drugPriceItemId === value.key);
              setDrugProductNameValue(value.key);
              scrollTo(
                'tablePurchasePlanCreate',
                rowDetails.id,
                '药品定位成功',
              );
            },
          };
          setDrugData();
          return methods[type]();
        }}
        hideSelected={true}
        hideSelectIcon={true}
      >
        <Table
          rowClassName="aspirin-table-row-box"
          columns={[
            {
              title: '药品名称',
              dataIndex: ['drugProductName'],
              key: 'product_name',
            },
            {
              title: '包装规格',
              dataIndex: ['packageSpecification'],
              key: 'package_specification',
            },
            {
              title: '生产厂家',
              dataIndex: ['firmName'],
              key: 'firm_name',
            },
          ]}
          pagination={false}
          size="middle"
          loading={{
            indicator: (
              <Row
                justify="center"
                style={{
                  left: '40%',
                }}
              >
                <Col span={24}>
                  <Loading />
                </Col>
              </Row>
            ),
            spinning: loading,
          }}
          scroll={{
            y: 400,
          }}
          dataSource={dataSource}
        />
      </ComplexSelect>
    </>
  );
};

export default memo(PurchasePlanComplexSelect);
