import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useAsyncEffect } from 'ahooks';
import vsf, { definePage } from '@vs/vsf-boot';
import './index.less';
import Screen, { CreateScreen } from '../../../components/screen';
import { Section, message } from '@vs/vsf-kit';
//医嘱费用/护士录入
import { Menu, Input, Select, Radio, Tooltip } from 'antd';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
// 医嘱费用
import DoctorsAdvice from './DoctorsAdvice';
// 护士录入
import NurseEntry from './NurseEntry';
// 费用明细
import AdviceDetatlsModal from './AdviceDetatlsModal';
// 计费模板导入
import NurseEntryModalComTemplate from './NurseEntryModalComTemplate';
import { useRequest } from 'ahooks';
import { getAllByPricePattern } from './services';
import dayjs from 'dayjs';
// 上方患者搜索查询/信息展示
const Above = memo((props) => {
  const { onUserInfo, setUserInfo, onWardBedInofList, setWardBedInofList } =
    props;
  // 控制单选框
  const [radioValue, setRadioValue] = useState(1);
  const onRadioChange = (event) => setRadioValue(event?.target?.value);
  // 显示患者id
  const [ShowdisplayId, setShowdisplayId] = useState();
  /**
   * convertGetPatientInpVisitInfoRes2InpVisitInfoForm 处理患者信息
   * @param {any} input
   * @returns {any}
   */
  const convertGetPatientInpVisitInfoRes2InpVisitInfoForm = (input) => {
    const _ = window._ ?? {};
    const output = {};
    const i = 0;
    output.wardBedInfo = {}; // TODO 请确认下行字段是否符合逻辑
    output.bedLabel = _.get(
      input,
      `data.[${i}].patientInHospitalBaseDto.bed.bedLabel`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.name = _.get(
      input,
      `data.[${i}].inpVisitExtensionPatientInfoDto.name`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.gender = _.get(
      input,
      `data.[${i}].inpVisitExtensionPatientInfoDto.gender`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.displayId = _.get(input, `data.[${i}].patient.displayId`);
    output.patientId = _.get(input, `data.[${i}].patient.id`);
    output.admissionDateTime = _.get(input, `data.[${i}].admissionDateTime`); // TODO 请确认下行字段是否符合逻辑
    output.chargeType = _.get(
      input,
      `data.[${i}].inpVisitExtensionBillingInfoDto.chargeType`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.identityCode = _.get(
      input,
      `data.[${i}].inpVisitExtensionBillingInfoDto.identityCode`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.admissionWardDateTime = _.get(
      input,
      `data.[${i}].patientInHospitalBaseDto.admissionWardDateTime`,
    );
    output.inpVisitId = _.get(input, `data.[${i}].id`);
    output.totalDays = _.get(input, `data.[${i}].totalDays`);
    setShowdisplayId(output.displayId);
    return output;
  };
  // 获取患者信息；
  const getUserInfo = async (params) => {
    const res =
      await vsf?.services?.ClinicInpVisitController_getPatientInpVisitInfo_9055d6?.(
        params,
      );
    if (res?.data?.length <= 0 || res?.code !== 200)
      return message.warning('未查询到该患者信息。');
    const output = convertGetPatientInpVisitInfoRes2InpVisitInfoForm?.(res);

    setUserInfo(output);
    message.success('患者信息查询成功。');
  };
  // 获取患者所有床位信息；
  const getWardBedInfo = async (param) => {
    const res =
      await vsf?.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
        { bedWardId: vsf.stores?.common?.currentApplication?.department?.id },
      );
    setWardBedInofList(
      res?.data?.map((d, i) =>
        Object.assign(d, { bedLabel: `${d.bedLabel} ${d.patientName}` }),
      ),
    );
  };
  const WardSelectRef = useRef(null);
  useEffect(() => {
    WardSelectRef?.current?.focus();
  }, [onWardBedInofList]);
  return (
    <>
      <Section className="Above BgColor">
        <div className="container DisFlex">
          <table>
            <Radio.Group name="radiogroup" defaultValue={radioValue}>
              <tr>
                <Radio value={1} onChange={onRadioChange} />
                <td>床号：</td>
                <td>
                  <Select
                    ref={WardSelectRef}
                    style={{ width: '180px' }}
                    placeholder="请选择床号"
                    options={onWardBedInofList}
                    disabled={radioValue == 2}
                    fieldNames={{
                      label: 'bedLabel',
                      value: 'inpVisitId',
                    }}
                    onChange={(inpVisitId) => {
                      props?.setDoctorAdviceOrderId(null);
                      props?.setItem(null);
                      getUserInfo({ inpVisitId });
                    }}
                    value={onUserInfo?.inpVisitId}
                    onDropdownVisibleChange={() =>
                      onWardBedInofList.length <= 0 && getWardBedInfo()
                    }
                  />
                </td>
                <td></td>
              </tr>

              <tr>
                <Radio value={2} onChange={onRadioChange} /> <td>患者ID：</td>
                <td>
                  <Tooltip title="输入患者ID后请按回车键">
                    <Input
                      value={ShowdisplayId}
                      onChange={(event) =>
                        setShowdisplayId(event?.target?.value)
                      }
                      placeholder="输入患者ID"
                      disabled={radioValue == 1}
                      onPressEnter={(event) => {
                        getUserInfo({
                          displayId: String(event?.target?.value),
                        });
                      }}
                    />
                  </Tooltip>
                </td>
                <td>
                  <Select
                    showSearch
                    placeholder="入院时间"
                    disabled={true}
                    style={{ width: '180px' }}
                    fieldNames={{
                      label: 'bedLabel',
                      value: 'inpVisitId',
                    }}
                    value={onUserInfo?.admissionDateTime}
                  />
                </td>
              </tr>
            </Radio.Group>
          </table>
          <table>
            <tr>
              <td>床号：</td>
              <td>{onUserInfo?.bedLabel}</td>
              <td>姓名：</td>
              <td className="no-wrap-ellipsis">{onUserInfo?.name}</td>
              <td>性别：</td>
              <td>
                {
                  vsf?.stores?.dbenums?.enums?.SEX_DICT?.filter(
                    (f) => f.code === String(onUserInfo?.gender),
                  )?.[0]?.cname
                }
              </td>
            </tr>
            <tr>
              <td>费别：</td>
              <td>
                {
                  vsf?.stores?.dbenums?.enums?.CHARGE_TYPE_DICT?.filter(
                    (f) => f.code === String(onUserInfo?.chargeType),
                  )?.[0]?.cname
                }
              </td>
              <td>人员类别：</td>
              <td>
                {
                  vsf?.stores?.dbenums?.enums?.IDENTITY_DICT?.filter(
                    (f) => f.code === String(onUserInfo?.identityCode),
                  )?.[0]?.cname
                }
              </td>
              <td>入科日期：</td>
              <td>
                {onUserInfo?.admissionWardDateTime &&
                  `${dayjs(onUserInfo?.admissionWardDateTime)
                    ?.format('YYYY-MM-DD')
                    ?.valueOf()}（${onUserInfo?.totalDays}天）`}
              </td>
            </tr>
          </table>
        </div>
      </Section>
    </>
  );
});
//医嘱费用/护士录入
const Content = (props) => {
  const button = [
    {
      text: '医保违规检查',
      disabled: true,
      methodKey: 'altKey',
      ordinaryKey: '',
      onClick: () => {},
    },
  ];
  const footer = () => {
    return (
      <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  const onShowBox = (MenuKey) => {
    let component = (
      <>
        <div>无</div>
      </>
    );
    switch (MenuKey) {
      case 'advice':
        component = <DoctorsAdvice {...props} />;
        break;
      case 'enter':
        component = <NurseEntry {...props} />;
        break;
    }
    return component;
  };
  return (
    <>
      <Section className="Content BgColor">
        <div className="PageContainer">
          <Menu
            style={{
              'background-color': 'rgba(255, 255, 255, 0.3)',
            }}
            items={[
              { label: '医嘱费用', key: 'advice' },
              { label: '护士录入', key: 'enter' },
            ]}
            mode="horizontal"
            selectedKeys={props.MenuKey}
            onClick={(event) => props.setMenuKey(event.key)}
          />
          <div className="PageContent">{onShowBox(props.MenuKey)}</div>
          <div className="PageFooter">
            <Block
              footer={footer()}
              style={{ height: '94%', padding: '12px' }}
            />
          </div>
        </div>
      </Section>
    </>
  );
};
// 导出组件
const SupplementRefundBilling = (props) => {
  // 控制tal菜单切换；
  const [MenuKey, setMenuKey] = useState('advice');
  // 控制计费模板弹出框弹出；
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);
  // 计费selects
  const [templates, setTemplates] = useState({});
  // 控制费用明细弹出框
  const [modalAdviceDetailsParams, setModalAdviceDetailsParams] = useState({
    open: false,
  });
  const [value, setValue, getValue, resetValue] = useGetResetInitState([]);

  // 患者信息；
  const [onUserInfo, setUserInfo] = useState({});

  // 床号下拉框数据；
  const [onWardBedInofList, setWardBedInofList] = useState([]);
  // 医嘱oderId
  const [onDoctorAdviceOrderId, setDoctorAdviceOrderId] = useState();
  // 护士录入左侧项目列表选中
  const [item, setItem] = useState(null);
  // 费用明细模板
  const [PatternList, setPatternList] = useState({});
  const Pattern = useRequest(
    async (_) => {
      const res = await getAllByPricePattern();
      setPatternList(res);
    },
    {
      manual: true,
    },
  );
  useEffect((_) => Pattern?.run(), []);
  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState();
  const resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);
  props = Object.assign(
    { ...props },
    {
      PatternList,
      onWardBedInofList,
      setWardBedInofList,
      onUserInfo,
      setUserInfo,
      MenuKey,
      setMenuKey,
      getValue,
      modalTemplateOpen,
      setModalTemplateOpen,
      modalAdviceDetailsParams,
      setModalAdviceDetailsParams,
      templates,
      setTemplates,
      onDoctorAdviceOrderId,
      setDoctorAdviceOrderId,
      item,
      setItem,
    },
  );
  return (
    <>
      <Screen>
        <div className="Container">
          <Above {...props} />
          <Content {...props} />
          <NurseEntryModalComTemplate {...props} />
          <AdviceDetatlsModal {...props} />
        </div>
      </Screen>
    </>
  );
};

export default definePage(SupplementRefundBilling);
