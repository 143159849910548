import './index.less';

import {
  compose,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import React from 'react';

type BlockItemBlockType = number | string;

type BlockItemType = {
  block?: BlockItemBlockType;
  gap?: string;
  bgColor?: string;
  padding?: string;
  itemClassName?: string;
};

export type ContainerPropsType = {
  width?: number | string;
  height?: number | string;
  layout?: {
    direction?: 'vertical' | 'horizontal';
    items?: BlockItemType[];
  };
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * 页面容器
 */
const Container = (props: ContainerPropsType) => {
  const {
    width = '100%',
    height = '100%',
    layout,
    children = [],
    className,
    style,
    ...rest
  } = props;
  const { direction = 'horizontal', items } = layout || {};

  const formatChildren: any[] = !Array.isArray(children)
    ? [children]
    : children;

  const containerStyle = {
    width,
    height,
    ...style,
  };

  /** 编写组件逻辑 */
  return (
    <>
      {/* 这里必须把rest传递给根节点，不要删除 */}
      <div
        {...rest}
        style={containerStyle}
        className={`aspirin-container aspirin-container-${direction} ${
          className ?? ''
        }`}
      >
        {formatChildren?.map((item, index) => {
          // console.log(item, 'ppp');

          const { block, gap, bgColor, padding, itemClassName } =
            items?.[index] || {};
          const blockInfo = block ?? 1;
          const marginInfo = gap ?? 0;
          const bgColorInfo = bgColor ?? 'transparent';
          const paddingInfo = padding ?? '0';
          const style =
            typeof blockInfo === 'number'
              ? {
                  flex: blockInfo,
                  marginRight: marginInfo,
                  backgroundColor: bgColorInfo,
                  width: 0,
                  padding: paddingInfo,
                }
              : {
                  width: blockInfo,
                  maxWidth: blockInfo,
                  minWidth: blockInfo,
                  marginRight: marginInfo,
                  backgroundColor: bgColorInfo,
                  padding: paddingInfo,
                };
          return (
            <div
              key={index}
              style={{
                ...style,
              }}
              className={itemClassName}
            >
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
};
Container.displayName = 'Container';

export default compose(
  withField<ContainerPropsType>({
    name: 'Container',
  }),
  withPreview<ContainerPropsType>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      return <></>;
    },
  }),
)(Container) as typeof Container;
