import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Button,
  Tooltip,
} from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import { getScrollY, getScrollYRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import { isMinScreen } from '@/config';

const OutpSettleMasterQueryList = ({ tableParam, counterIdChange }) => {
  const [dataSorce, setDataSorce] = useState();

  const payTypeData = [
    { label: '现金', value: 'CASH' },
    { label: '支付宝', value: 'ALIPAY' },
    { label: '微信', value: 'WECHAT' },
    { label: '数字人民币', value: 'DIGITAL_RMB' },
    { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
  ];
  const [index, setIndex] = useState();

  const onFetch = useCallback(
    async (params) => {
      let tableData;
      const param = tableParam;

      let settleDateRangeIn = {
        begin: dayjs().startOf('day').valueOf(),
        end: dayjs().endOf('day').valueOf(),
        beginInclude: true,
        endInclude: true,
      };

      if (
        !param?.settleDateRangeIn ||
        (param?.settleDateRangeIn &&
          (isNaN(param?.settleDateRangeIn[0]) || !param?.settleDateRangeIn[0]))
      ) {
        settleDateRangeIn = null;
      }
      if (param?.settleDateRangeIn && param?.settleDateRangeIn[0]) {
        settleDateRangeIn = {
          begin: param?.settleDateRangeIn[0],
          end: param?.settleDateRangeIn[1],
          beginInclude: true,
          endInclude: true,
        };
      }
      if (settleDateRangeIn?.begin && !/^\d+$/.test(settleDateRangeIn?.begin)) {
        settleDateRangeIn = {
          begin: dayjs(settleDateRangeIn?.begin).startOf('day').valueOf(),
          end: dayjs(settleDateRangeIn?.end).endOf('day').valueOf(),
          beginInclude: true,
          endInclude: true,
        };
      }
      const res =
        await vsf.services?.OutpSettleController_queryAllOutpSettleMaster_246314?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              ...param,
              settleDateRangeIn: settleDateRangeIn
                ? settleDateRangeIn
                : undefined,
              settleStatusIs: undefined,
            },
          },
        );
      // console.log(res, 'table_res');
      if (tableParam?.settleStatusIs) {
        if (tableParam.settleStatusIs === 'NOT_CHARGE') {
          tableData = res?.data?.result?.filter(
            (item) =>
              item?.settleStatus === 'FINISH_CHARGE' &&
              !item?.refundSettleDate &&
              !item?.refundVsOutpSettleId,
          );
        } else if (tableParam.settleStatusIs === 'FINISH_CHARGE') {
          tableData = res?.data?.result?.filter((item) => {
            return (
              (item?.settleStatus === 'PARTIAL_REFUND' ||
                item?.settleStatus === 'ALL_REFUND') &&
              item?.refundVsOutpSettleId &&
              !item?.refundSettleDate
            );
          });
        } else if (tableParam.settleStatusIs === 'PARTIAL_REFUND') {
          tableData = res?.data?.result?.filter(
            (item) =>
              (item?.settleStatus === 'FINISH_CHARGE' ||
                item?.settleStatus === 'FINISH_CHARGE') &&
              item?.refundSettleDate &&
              item?.refundVsOutpSettleId,
          );
        } else if (tableParam.settleStatusIs === 'ALL_REFUND') {
          tableData = res?.data?.result?.filter(
            (item) =>
              item?.refundVsOutpSettleId &&
              item?.refundSettleDate &&
              item?.settleStatus === 'FINISH_CHARGE' &&
              item?.id === item?.refundVsOutpSettleId,
          );
        } else {
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }
      } else {
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
      return {
        data: tableData ?? res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [tableParam],
  );
  return (
    <>
      <VSPromiseTable
        vsid="42317"
        id="outpSettleMasterQueryList"
        scroll={{
          x: 2180,
          // y: isMinScreen? 320: 440,
          y: getScrollYRem(35),
        }}
        onRow={(node, index) => {
          return {
            onClick() {
              counterIdChange(node);
              setIndex(index);
            },
            onDoubleClick() {
              setIndex(index);
              if (node?.clinicClass === 'CLINIC_REGISTER') {
                vsf?.navigateTo(
                  `/index/utpatient-withdrawal?dispalyId=${node?.settleNumber}`,
                );
              } else {
                node &&
                  vsf?.navigateTo(
                    `/index/outpatient-refunds?settleNumber=${node?.settleNumber}`,
                  );
              }
              // }
            },
          };
        }}
        // pagination={{ defaultPageSize: 10 }}
        rowClassName={(record, rowIndex) => {
          if (index === rowIndex) {
            return 'aspirin-row-selected-box';
          } else {
            return '';
          }
        }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['patient', 'displayId']}
          title="患者ID"
          valueType="text"
          //   search
          order={0}
          // columnKey={'displayIdIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['nameLike']}
          title="姓名"
          valueType="text"
          //   search
          order={0}
          columnKey={'nameLike'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['identificationClass']}
          title="证件类型"
          valueType="select"
          //   search
          order={0}
          columnKey={'identificationClass'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['identificationNumber']}
          title="证件号"
          valueType="text"
          //   search
          order={0}
          columnKey={'identificationNumber'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['chargeTypeIs']}
          title="费别"
          valueType="select"
          //   search
          order={0}
          columnKey={'chargeTypeIs'}
          hideInTable
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operatorStaffId', 'staffName']}
          title="收款员"
          valueType="text"
          //   search
          order={0}
          columnKey={'operatorStaffIdIs'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['settleDateRangeIn']}
          title="收费日期"
          valueType="dateRange"
          //   search
          order={0}
          columnKey={'settleDateRangeIn'}
          hideInTable
          searchConfig={{
            transform: (values) => {
              if (!Array.isArray(values)) return values;
              return {
                settleDateRangeIn: {
                  begin: values[0] || dayjs().startOf('day').valueOf(),
                  end: values[1] || dayjs().endOf('day').valueOf(),
                  beginInclude: true,
                  endInclude: true,
                },
              };
            },
          }}
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['settleDateRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          convertValue={(obj) => {
            if (Array.isArray(obj)) return obj;
            return [obj?.begin, obj?.end];
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['totalCostIs']}
          title="应收总费用"
          valueType="digit"
          //   search
          order={0}
          columnKey={'totalCostIs'}
          hideInTable
          fieldProps={{}}
          render={(_, v) => {
            return Number(v.totalCostIs).toFixed(2);
          }}
        />

        <VSTableColumn
          dataIndex={['totalChargeIs']}
          title="实收款"
          valueType="digit"
          //   search
          order={0}
          columnKey={'totalChargeIs'}
          hideInTable
          fieldProps={{}}
          render={(_, v) => {
            return Number(v.totalChargeIs).toFixed(2);
          }}
        />

        <VSTableColumn
          dataIndex={['settleStatusIs']}
          title="交费状态"
          valueType="text"
          //   search
          order={0}
          columnKey={'settleStatusIs'}
          hideInTable
          fieldProps={{}}
          render={(_, values) => {
            // console.log(values);
            if (values?.refundVsOutpSettleId) {
              return '已退费';
            } else {
              return [
                { label: '未收费', value: 'NOT_CHARGE' },
                { label: '已收费', value: 'FINISH_CHARGE' },
                { label: '部分退费', value: 'PARTIAL_REFUND' },
                { label: '全部退费', value: 'ALL_REFUND' },
              ]?.find((item) => values.settleStatusIs === item?.value)?.label;
            }
          }}
        />

        <VSTableColumn
          dataIndex={['payTypeIs']}
          title="支付方式"
          valueType="select"
          //   search
          order={0}
          columnKey={'payTypeIs'}
          hideInTable
          dataSource={[
            { label: '现金', value: 'CASH' },
            { label: '支付宝', value: 'ALIPAY' },
            { label: '微信', value: 'WECHAT' },
            { label: '数字人民币', value: 'DIGITAL_RMB' },
            { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['settleTypeIs']}
          title="结算类型"
          valueType="select"
          //   search
          order={0}
          columnKey={'settleTypeIs'}
          hideInTable
          dataSource={[
            { label: '窗口', value: 'WINDOW' },
            { label: '自助机', value: 'SELF_MACHINE' },
            { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
            { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
            { label: '护士站结算', value: 'NURSE_STATION' },
            { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
            { label: '代煎收方', value: 'DECOCT' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['settleNumberIs']}
          title="结算号"
          valueType="text"
          //   search
          order={0}
          columnKey={'settleNumberIs'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['insuranceCardNumberIs']}
          title="医保卡号"
          valueType="text"
          //   search
          order={0}
          columnKey={'insuranceCardNumberIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '医保卡号长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['outpReceiptRecordDtoList', 'physicalReceiptNumber']}
          title="票据号"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['patient', 'displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['chargeType']}
          title="费别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['identityType']}
          title="人员类别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operatorStaffId', 'staffName']}
          title="收款员"
          valueType="text"
          //   search
          order={0}
          // columnKey={'operatorStaffIdIs'}
          // hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['settleDate']}
          title="收费时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={200}
        />

        <VSTableColumn
          dataIndex={['totalCost']}
          title="应收总费用"
          valueType="digit"
          fieldProps={{}}
          render={(_, v) => {
            return Number(v.totalCost).toFixed(2);
          }}
        />

        <VSTableColumn
          dataIndex={['totalCharge']}
          title="实收款"
          valueType="digit"
          fieldProps={{}}
          render={(_, v) => {
            return Number(v.totalCharge).toFixed(2);
          }}
        />

        <VSTableColumn
          width={228}
          dataIndex={['payment', 'paymentDetailDtoList', 'payType']}
          title="付款方式"
          fieldProps={{}}
          render={(_, values) => {
            let payTypeString = '';
            values?.payment?.paymentDetailDtoList?.map((item) => {
              const payType = payTypeData.find(
                (val) => item?.payType === val?.value,
              );
              payTypeString = payTypeString.concat(
                `[${payType?.label}:${Number(item?.paymentAmount).toFixed(2)}]`,
              );
            });
            return (
              <div className="text-overflow-omit">
                <Tooltip title={payTypeString}>{payTypeString}</Tooltip>
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['settleStatus']}
          title="交费状态"
          valueType="text"
          order={0}
          columnKey={'settleStatusIs'}
          fieldProps={{}}
          render={(dom, value) => {
            if (
              !value?.refundVsOutpSettleId &&
              !value?.refundSettleDate &&
              value?.settleStatus === 'FINISH_CHARGE'
            ) {
              return '收费';
            } else if (
              value?.refundVsOutpSettleId &&
              !value?.refundSettleDate &&
              (value?.settleStatus === 'PARTIAL_REFUND' ||
                value?.settleStatus === 'ALL_REFUND')
            ) {
              return <div style={{ color: '#E34A4E' }}>退费</div>;
            } else if (
              value?.refundVsOutpSettleId &&
              value?.refundSettleDate &&
              value?.settleStatus === 'FINISH_CHARGE' &&
              value?.id === value?.refundVsOutpSettleId
            ) {
              return <div style={{ color: '#E34A4E' }}>收费[作废]</div>;
            } else if (
              value?.refundVsOutpSettleId &&
              value?.refundSettleDate &&
              value?.settleStatus === 'FINISH_CHARGE'
            ) {
              return <div style={{ color: '#E34A4E' }}>收费[已退]</div>;
            }
          }}
        />

        {/* <VSTableColumn
          dataIndex={['prePaymentStatus']}
          title="支付状态"
          valueType="select"
          dataSource={[
            { label: '(收)-收预交金', value: 'CASH_IN_RECEIVE_PRE_PAYMENT' },
            { label: '(支)-退预交金', value: 'CASH_OUT_REFUND_PRE_PAYMENT' },
            { label: '(收)-退医疗款', value: 'CASH_IN_REFUND_PAYMENT' },
            { label: '(支)-支付医疗款', value: 'CASH_OUT_PAYMENT' },
          ]}
          fieldProps={{}}
          render={(dom, value) => {
            if (
              value?.refundPrePaymentId &&
              value?.refundPrePaymentId === value?.paymentMasterId
            ) {
              return (
                <div style={{ color: '#E34A4E' }}>(收)-收预交金[已退]</div>
              );
            } else if (
              value?.paymentMasterId &&
              value?.refundPrePaymentId === value?.paymentMasterId
            ) {
              return (
                <div style={{ color: '#E34A4E' }}>(收)-收预交金[已作废]</div>
              );
            } else if (!value?.refundPrePaymentId) {
              return '(收)-收预交金';
            } else {
              return dom;
            }
          }}
        /> */}

        <VSTableColumn
          dataIndex={['receiptPrintStatus']}
          title="票据打印状态"
          valueType="select"
          dataSource={[
            { label: '待打印', value: 'WAIT_PRINT' },
            { label: '已打印', value: 'PRINT' },
            { label: '作废', value: 'CANCEL' },
          ]}
          fieldProps={{
            defaultValue: 'WAIT_PRINT',
          }}
        />

        <VSTableColumn
          dataIndex={['outpReceiptRecordDtoList', 'printStaffId']}
          title="打印操作员"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
          // render={(_, values) => {
          //   return values?.operatorStaffId?.loginName;
          // }}
        />

        <VSTableColumn
          dataIndex={['printDate']}
          title="打印日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['refundSettleDate']}
          title="退结算时间"
          valueType="date"
          ellipsis
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['settleType']}
          title="结算途径"
          valueType="select"
          dataSource={[
            { label: '窗口', value: 'WINDOW' },
            { label: '自助机', value: 'SELF_MACHINE' },
            { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
            { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
            { label: '护士站结算', value: 'NURSE_STATION' },
            { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
            { label: '代煎收方', value: 'DECOCT' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['insuranceSettleType']}
          title="医保结算类型"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unitInContractName']}
          title="单位发票标记"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '合同单位长度不合法',
                type: 'string',
                min: 0,
                max: 50,
              },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </>
  );
};
export default definePage(OutpSettleMasterQueryList);
