import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderDrugController_getSkinTestOrders_eb2e30: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-skin-test-orders',
    parameterFomatter: (data?: {
      testTypeEnum: OrderDrugSkinTestTypeEnum;
      drugItemId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SkinTestVoClinicRecordOrderDrugEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderDrugController_hasSkinTests_f977be: {
    method: 'post',
    url: '/api/clinic-record-order-drug/has-skin-tests',
    parameterFomatter: (data?: {
      qto: PatientHaveDrugEoClinicRecordOrderDrugPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordOrderDrugController_isSkinTestsPositive_e42ea3: {
    method: 'post',
    url: '/api/clinic-record-order-drug/is-skin-tests-positive',
    parameterFomatter: (data?: {
      qto: PatientHaveDrugEoClinicRecordOrderDrugPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
