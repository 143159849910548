import {
  VSFormItem,
  VSTable,
  VSTableColumn,
  VSForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import { Table } from 'antd';
import { difference, isEqual } from 'lodash';
import React, { memo, useCallback, useEffect } from 'react';

import { askFixed, askSearch } from '@/pages/Index/components/func/ask';
import { getScrollY } from '@/utils';
import { TransferExpand } from '@/pages/Index/components';
import { useAvoidState } from '@/pages/Index/components/func/hooks';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

// Customize Table Transfer
const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);

// const TableTransfer = (props) => {
//   console.log(props);
//   const { roleList, value, onChange, application } = props;
//   const [targetKeys, setTargetKeys, getTargetKeys] = useGetState([]);
//   const [dataSource, setDataSource] = useState();
//   const [rightData, setRightData, getRightData] = useGetState([]);

//   const valueIdRef = useRef();

//   const getDataSource = useCallback(async () => {
//     if (application) {
//       const role =
//         await vsf?.services?.ApplicationFunctionDtoController_getAllFunctionListWithRole_3d0e3a(
//           {
//             applicationId: application.id,
//           },
//         );
//       if (role?.code === 200) {
//         setDataSource(
//           role?.data?.map((item) => {
//             const { id, ...rest } = item;
//             return ({
//               ...rest,
//               functionId: id,
//               key: id,
//               id: 'create_' +  Math?.random(),
//               functionRoleBtoList: item.roleList.map((hove) => hove.id),
//             })
//           }),
//         );
//       }
//     } else {
//       const res =
//         await vsf?.services?.ApplicationFunctionDtoController_getAllByFunctionQto_233f12?.(
//           {
//             qto: {
//               from: 0,
//               size: 99999,
//             },
//           },
//         );
//       setDataSource(
//         res?.data?.map((item) => {
//           const { id, ...rest } = item;
//           return ({
//             ...rest,
//             functionId: id,
//             key: id,
//             id: 'create_' +  Math?.random(),
//           })
//         }),
//       );
//     }
//   }, [application]);

//   const setRightDataWithEdit = useCallback(() => {
//     const idStr = (value ?? [])?.map((item) => item?.functionId)?.join('-');
//     if (idStr !== valueIdRef.current && dataSource) {
//       let rightKeys = [];
//       (value ?? [])?.forEach((item) => {
//         if (dataSource?.find((dItem) => dItem?.functionId === item?.functionId)) {
//           rightKeys.push(item?.functionId);
//         }
//       });
//       valueIdRef.current = idStr;
//       setTargetKeys(rightKeys);
//     }
//     setRightData(value ?? []);
//   }, [dataSource, value]);

//   const getTransferData = useCallback(
//     (keys) => {
//       const res = [...(getRightData() ?? [])];
//       keys?.forEach((key) => {
//         const currentRightData = rightData?.find(
//           (item) => item?.functionId === key,
//         );
//         const currentDataSource = dataSource?.find(
//           (item) => item?.functionId === key,
//         );
//         if (!currentRightData && currentDataSource) {
//           res?.push(currentDataSource);
//         }
//       });
//       setRightData(res);
//     },
//     [rightData, dataSource],
//   );
//   const handleTransferOnChange = (v) => {
//     setTargetKeys(v);
//     getTransferData(v);
//     console.log(v);
//     // onChange?.(v);
//   };

//   const handleValuesChange = (values) => {
//     onChange?.(values);
//   };

//   useEffect(() => {
//     getDataSource();
//   }, [getDataSource]);

//   useEffect(() => {
//     setRightDataWithEdit();
//   }, [setRightDataWithEdit]);

//   const listStyle = ({ direction }) =>
//     direction === 'left'
//       ? {
//         flex: 0,
//         flexBasis: '392px',
//       }
//       : {};
//   console.log(rightData);
//   return (
//     <Transfer
//       onChange={handleTransferOnChange}
//       listStyle={listStyle}
//       targetKeys={targetKeys}
//       dataSource={dataSource}
//       className="aspirin-transfer"
//       render={(item) => item.name}
//       showSelectAll={false}
//       oneWay
//     >
//       {({
//         direction,
//         filteredItems,
//         onItemSelectAll,
//         onItemSelect,
//         selectedKeys: listSelectedKeys,
//         disabled: listDisabled,
//       }) => {
//         const rowSelection = {
//           selectType: 'multiple',
//           // getCheckboxProps: (item) => ({
//           //   disabled: listDisabled || item.disabled,
//           // }),
//           onSelectAll(selectedRows, selectKeys, selected) {
//             const treeSelectedKeys = selectedRows
//               .filter((item) => !item.disabled)
//               .map(({ key }) => key);
//             const diffKeys = selected
//               ? difference(treeSelectedKeys, listSelectedKeys)
//               : difference(listSelectedKeys, treeSelectedKeys);
//             onItemSelectAll(diffKeys, selected);
//           },
//           onSelect(node, key, selected) {
//             onItemSelect(key, selected);
//           },
//           selectedRowKeys: listSelectedKeys,
//           selectedRows: dataSource?.filter((item) =>
//             listSelectedKeys?.includes(item?.key),
//           ),
//         };

//         if (direction === 'left') {
//           return (
//             <VSTable
//               rowSelection={rowSelection}
//               value={filteredItems}
//               size="small"
//               // style={{ pointerEvents: listDisabled ? 'none' : undefined }}
//               rowClick={{
//                 rowClickType: 'select',
//               }}
//               tableAlertRender={false}
//               pagination={false}
//               rowKey="functionId"
//             >
//               <VSTableColumn
//                 dataIndex={['name']}
//                 title="功能名称"
//                 valueType="text"
//                 formItemProps={{
//                   rules: [
//                     {
//                       message: '名称长度不合法',
//                       type: 'string',
//                       min: 0,
//                       max: 40,
//                     },
//                   ],
//                 }}
//               />

//               <VSTableColumn
//                 dataIndex={['urlParameter']}
//                 title="功能编码"
//                 valueType="text"
//                 formItemProps={{
//                   rules: [
//                     {
//                       message: 'url参数长度不合法',
//                       type: 'string',
//                       min: 0,
//                       max: 2000,
//                     },
//                   ],
//                 }}
//                 fieldProps={{}}
//               />
//             </VSTable>
//           );
//         } else {
//           return (
//             <VSTable
//               rowSelection={{}}
//               size="small"
//               // style={{ pointerEvents: listDisabled ? 'none' : undefined }}
//               rowClick={{
//                 rowClickType: 'select',
//               }}
//               value={rightData}
//               tableAlertRender={false}
//               pagination={false}
//               editable={{
//                 editType: 'multiple',
//               }}
//               onChange={(value, change) => {
//                 if (change?.operation === 'remove') {
//                   const keys = (getTargetKeys() ?? [])?.filter(
//                     (item) => item !== change?.data?.functionId,
//                   );
//                   setTargetKeys(keys);
//                   const state  =
//                   rightData?.filter((item) =>
//                     keys?.includes(item?.functionId),
//                   );
//                   handleValuesChange?.(state)
//                   setRightData(state);
//                 }
//               }}
//               onValuesChange={handleValuesChange}
//               dragSort={{
//                 dragSortDataIndex: ['sort'],
//                 onDragSortEnd: (data) => {
//                   handleValuesChange?.(data);
//                   setRightData(data)
//                 }
//               }}
//             >
//               <VSTableColumn
//                 dataIndex={['sort']}
//                 valueType="sort"
//                 fieldProps={{}}
//                 editable={false}
//                 width={50}
//               />
//               <VSTableColumn
//                 title="序号"
//                 valueType="index"
//                 width={100}
//                 fieldProps={{}}
//               />

//               <VSTableColumn
//                 dataIndex={['name']}
//                 title="功能名称"
//                 valueType="text"
//                 preview
//                 fieldProps={{
//                   disabled: true,
//                 }}
//               />

//               <VSTableColumn
//                 dataIndex={['commonFunctionIndicator']}
//                 title="自启动"
//                 valueType="switch"
//                 fieldProps={{}}
//               />

//               <VSTableColumn
//                 dataIndex={['showType']}
//                 title="显示标识"
//                 valueType="select"
//                 fieldProps={{
//                   showSearch: true,
//                 }}
//                 dataSource={[
//                   { label: '不显示', value: 'DISPLAY' },
//                   { label: '显示页面', value: 'PAGE' },
//                   { label: '显示弹框', value: 'DIALOG' },
//                 ]}
//               />

//               <VSTableColumn
//                 dataIndex={['functionSubGroup']}
//                 title="分组号"
//                 valueType="digit"
//                 fieldProps={{
//                   width: '100%'
//                 }}
//               />

//               <VSTableColumn
//                 dataIndex={['functionRoleBtoList']}
//                 title="角色"
//                 valueType="select"
//                 fieldProps={{
//                   multiple: true,
//                 }}
//                 multiple
//                 dataSource={roleList.map((item) => ({
//                   label: item?.roleName,
//                   value: item?.id,
//                 }))}
//               />
//             </VSTable>
//           );
//         }
//       }}
//     </Transfer>
//   );
// };

// const Index = React.memo(TableTransfer, (prev, next) => {
//   return prev?.value?.length === next?.value?.length;
// });

// export default Index;

const Left = memo((props) => {
  // console.log(props);
  return (
    <VSTable
      size="small"
      {...props}
      rowClick={{
        rowClickType: 'select',
      }}
      tableAlertRender={false}
      pagination={false}
      rowKey="key"
      style={{ height: '100%' }}
      className="table_overflow_650"
      scroll={{
        y: 0,
      }}
    >
      <VSTableColumn dataIndex={['name']} title="功能名称" valueType="text" />

      <VSTableColumn dataIndex={['urlParameter']} title="功能编码" />
    </VSTable>
  );
});

const Right = memo((props) => {
  return (
    <VSTable
      {...props}
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
      }}
      className="table_overflow_500"
      scroll={{ y: 0 }}
      rowKey="key"
      pagination={false}
      onChange={(value) => {
        props?.onChange(value);
      }}
      rowClick={{
        rowClickType: 'default',
      }}
      dragSort={{
        dragSortDataIndex: ['sort'],
        onDragSortEnd: (data) => {
          props?.onChange?.(data);
        },
      }}
    >
      <VSTableColumn
        dataIndex={['sort']}
        valueType="sort"
        fieldProps={{}}
        editable={false}
        width={50}
      />
      <VSTableColumn
        title="序号"
        valueType="index"
        width={100}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['name']}
        title="功能名称"
        valueType="text"
        preview
        fieldProps={{
          disabled: true,
        }}
      />

      <VSTableColumn
        dataIndex={['commonFunctionIndicator']}
        title="自启动"
        valueType="switch"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['showType']}
        title="显示标识"
        valueType="select"
        fieldProps={{
          showSearch: true,
        }}
        dataSource={[
          { label: '不显示', value: 'DISPLAY' },
          { label: '显示页面', value: 'PAGE' },
          { label: '显示弹框', value: 'DIALOG' },
        ]}
      />

      <VSTableColumn
        dataIndex={['functionSubGroup']}
        title="分组号"
        valueType="digit"
        fieldProps={{
          width: '100%',
        }}
      />

      <VSTableColumn
        dataIndex={['functionRoleBtoList']}
        title="角色"
        valueType="select"
        fieldProps={{
          multiple: true,
        }}
        multiple
        dataSource={props?.roleList.map((item) => ({
          label: item?.roleName,
          value: item?.id,
        }))}
      />
    </VSTable>
  );
});

const Department = ({ filterDataSource }) => {
  const onChange = (value) => {
    const { functionClass, functionName } = value;
    console.log(
      functionClass,
      functionName,
      'item.functionClass == functionClass ',
    );
    if (functionName || functionClass) {
      filterDataSource((dataSource) => {
        const list = dataSource.filter((item) => {
          if (functionName && functionClass) {
            return (
              item.functionClass == functionClass &&
              (askSearch(functionName, item, 'name') ||
                askSearch(functionName, item, 'inputCode'))
            );
          } else if (functionName) {
            return (
              askSearch(functionName, item, 'name') ||
              askSearch(functionName, item, 'inputCode')
            );
          } else if (functionClass) {
            return item.functionClass == functionClass;
          }
          return functionName;
        });
        return list;
      });
    } else {
      filterDataSource((dataSource) => dataSource);
    }
  };
  return (
    <div
      style={{
        marginTop: 24,
      }}
    >
      <SearchContainer.Header title="可选功能">
        <VSForm
          style={{
            paddingRight: 24,
            paddingTop: 24,
          }}
          onChange={onChange}
        >
          <VSFormLayout columnCount={2}>
            <VSFormItem
              valueType="selectCode"
              name="functionClass"
              dataSource={
                vsf.stores.dbenums?.enums?.SYS_CONFIG_TYPE?.[0]?.children
              }
              fieldProps={{
                showSearch: true,
                placeholder: '请选择功能分类搜索',
                allowClear: true,
              }}
            />
            <VSFormItem
              name="functionName"
              fieldProps={{
                placeholder: '请输入功能名称',
              }}
            />
          </VSFormLayout>
        </VSForm>
      </SearchContainer.Header>
    </div>
  );
};

const Index = (props) => {
  const { roleList, value, onChange, application } = props;
  const [dataSource, setDataSource] = useAvoidState([]);
  const getDataSource = useCallback(async () => {
    let list = [];
    if (application) {
      const role =
        await vsf?.services?.ApplicationFunctionDtoController_getAllFunctionListWithRole_3d0e3a(
          {
            applicationId: application.id,
          },
        );
      if (role?.code === 200) {
        list = role?.data?.map((item) => {
          const { id, ...rest } = item;
          return {
            ...rest,
            functionId: id,
            key: id,
            id: 'create_' + Math?.random(),
            functionRoleBtoList: item.roleList.map((hove) => hove.id),
          };
        });
      }
    } else {
      const res =
        await vsf?.services?.ApplicationFunctionDtoController_getAllByFunctionQto_233f12?.(
          {
            qto: {
              from: 0,
              size: 99999,
            },
          },
        );
      list = res?.data?.map((item) => {
        const { id, ...rest } = item;
        return {
          ...rest,
          functionId: id,
          key: id,
          id: 'create_' + Math?.random(),
        };
      });
    }
    setDataSource(list);
  }, [application]);

  useEffect(() => {
    getDataSource?.();
  }, [getDataSource]);

  const data = (value ?? [])?.map((item) => ({
    key: item?.functionId,
    ...item,
  }));

  const TransferProps = {
    showSelectAll: false,
    dataSource,
    style: {
      height: getScrollY(500),
    },
    listStyle: ({ direction }) => {
      const dict = {
        left: {
          flex: 0,
          flexBasis: '20%',
          borderTop: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        right: {
          flex: 0,
          flexBasis: '80%',
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
      };
      return dict[direction];
    },
  };
  return (
    <TransferExpand
      className="aspirin-transfer-height"
      left={<Left />}
      right={<Right roleList={roleList} onChange={onChange} />}
      dataSource={dataSource}
      data={data}
      // className="table_overflow_280"
      labelKey="key"
      {...TransferProps}
      search={{
        left: {
          render: <Department />,
        },
      }}
    />
  );
};

export default Index;
