import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/get-outp-visit-patient-vitalsign-allergy-vo-by-id',
      parameterFomatter: (data?: { id: number; queryDay: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: OutpVisitPatientVitalsignAllergyVoClinicOutpVisitEntranceWebVo,
      ) => data,
    },
  DrugPrescriptionDispenseController_getWaitDispenseDetails_323b08: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-wait-dispense-details',
    parameterFomatter: (data?: {
      qto: QueryPatientWaitDispenseMasterByDateQtoDrugPrescriptionDispensePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPrescriptionWaitDispenseMasterForDispenseDetailVoDrugPrescriptionDispenseEntranceWebVo[],
    ) => data,
  },
  DrugPrescriptionDispenseController_dispensingPrescriptions_582638: {
    method: 'post',
    url: '/api/drug-prescription-dispense/dispensing-prescriptions',
    parameterFomatter: (data?: {
      dispensingParam: DispensingPrescriptionEoDrugPrescriptionDispensePersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce:
    {
      method: 'post',
      url: '/api/application-privilege/get-current-application-vs-staff-with-staff-vo',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffWithStaffVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
  ApplicationPrivilegeController_getListApplicationVsStaffWithStaffVoByApplicationId_52e054:
    {
      method: 'post',
      url: '/api/application-privilege/get-list-application-vs-staff-with-staff-vo-by-application-id',
      parameterFomatter: (data?: { applicationId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffWithStaffVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
});
