import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderTemplateMasterController_createOrderTemplateMaster_f5aa85: {
    method: 'post',
    url: '/api/clinic-record-order-template/create-order-template-master',
    parameterFomatter: (data?: {
      btoParam: CreateOrderTemplateMasterBtoClinicRecordOrderTemplateServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterVoClinicRecordOrderTemplateEntranceWebVo,
    ) => data,
  },
  OrderTemplateMasterController_getOrderTemplateMasterById_63ba49: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-master-by-id',
    parameterFomatter: (data?: { orderTemplateMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterVoClinicRecordOrderTemplateEntranceWebVo,
    ) => data,
  },
  OrderTemplateMasterController_getOrderTemplateMaster_bfb379: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-master',
    parameterFomatter: (data?: {
      templateClass: OrderTemplateClassEnum;
      agreedPrescriptionIndicator: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  OrderTemplateMasterController_getOrderTemplateMasterDetailById_5e8485: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-master-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterDetailVoClinicRecordOrderTemplateEntranceWebVo,
    ) => data,
  },
});
