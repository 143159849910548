import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListDtoController_getPriceItemCategory_77dde8: {
    method: 'post',
    url: '/api/price-list/get-price-item-category',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PriceItemCategoryEoPriceListPersistEo) =>
      data,
  },
  PriceListBOController_updatePriceItemCategory_f52851: {
    method: 'post',
    url: '/api/price-list/update-price-item-category',
    parameterFomatter: (data?: {
      priceItemCategoryEo: PriceItemCategoryEoPriceListPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListBOController_createPriceItemCategory_337e94: {
    method: 'post',
    url: '/api/price-list/create-price-item-category',
    parameterFomatter: (data?: {
      priceItemCategoryEo: PriceItemCategoryEoPriceListPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
