import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSPromiseTable, VSTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback } from 'react';

import { Flex } from '@/pages/Index/components';

const ClinicPatientVisitHistoryDetailPriceInfo = (props) => {
  const { id, visitRecordHistoryType, detail } = props;
  console.log(detail?.priceInfo, 'detail?.priceInfo');

  // const onFetch = useCallback(
  //   async (params) => {
  //     const res =
  //       await vsf?.services?.VisitHistoryController_getPatientVisitHistoryDetail_d961fc?.(
  //         {
  //           eo: {
  //             id,
  //             visitRecordHistoryType,
  //             detailType: 'PRICE_INFO',
  //           },
  //         },
  //       );
  //     return res?.data?.priceInfoList?.map((item) => ({
  //       ...item.outpBillingDetailVo,
  //       ...item.orderVo,
  //     }));
  //   },
  //   [id, visitRecordHistoryType],
  // );
  return (
    <Flex>
      <VSTable
        className="
        aspirin-table-header-background-color
       aspirin-table-row-hover-background-color
       aspirin-table-nopadding
       aspirin-table-body-empty-transparent-background-color
"
        value={detail?.priceInfo ?? []}
        pagination={false}
        scroll={{ y: 500 }}
      >
        <VSTableColumn
          dataIndex={['itemClass']}
          title="价表项目分类"
          valueType="select"
          fieldProps={{}}
          ellipsis
          dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          fieldProps={{}}
          width={170}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['price']}
          title="项目单价"
          valueType="digit"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['charge']}
          title="实收费用"
          valueType="digit"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['orderDepartmentName']}
          title="开单科室名称"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['performDepartmentName']}
          title="执行科室名称"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['submitDateTime']}
          title="医嘱提交时间"
          valueType="date"
          width={160}
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          ellipsis
        />
      </VSTable>
    </Flex>
  );
};
export default ClinicPatientVisitHistoryDetailPriceInfo;
