import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  List,
  message,
  Modal,
  Popover,
  Tooltip,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep, isEqual } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AspirinDateTimePicker } from '@/components';
import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import { askFixed } from '@/pages/Index/components/func/ask';
import { ASVSTable } from '@/pages/Index/components/table';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';

function calculateAge(birthday) {
  const birthDate = dayjs(birthday);
  const today = dayjs();
  return `${today.diff(birthDate, 'year')}岁`;
}

type OrderExecuteTablePropsType = {
  value?: any[];
  handleExecute?: (record?: any) => void;
  handleCancel?: (record?: any) => void;
  handleInvalid?: (record?: any) => void;
  handleStop?: (record?: any) => void;
  handlePause?: (record?: any) => void;
  handleUnstop?: (record?: any) => void;
  handleShowPauseRecord?: (record?: any) => void;
  scrollFunc: any;
  scrollId: any;
  ids: any;
};

const Index = (props: OrderExecuteTablePropsType, ref) => {
  const {
    value,
    handleExecute,
    handleCancel,
    handleInvalid,
    handleStop,
    handlePause,
    handleUnstop,
    handleShowPauseRecord,
    ids,
  } = props;

  const [tableData, setTableData] = useState<any[]>([]);
  console.log(tableData, 'tableDatatableData');

  const formatValue = (data) => {
    // const keys = data?.map((item) => item?.id);
    // setExpandedRows(data);
    return data;
  };

  useEffect(() => {
    setTableData(formatValue(value));
  }, [value]);

  // 渲染高危图标
  const renderHighriskIcon = (record) => {
    const { orderDrug } = record || {};
    const node = (
      <Popover content="高危">
        <Icon
          type="icon-gaowei"
          size={20}
          style={{
            marginRight: 4,
          }}
        />
      </Popover>
    );
    if (orderDrug?.drug?.dangerIndicator) {
      return node;
    } else {
      return null;
    }
  };

  // 渲染加急图标
  const renderUrgentIcon = (record) => {
    const { examOrder, labOrder, pathologyOrder, operationOrder } =
      record || {};
    const node = (
      <Popover content="加急">
        <Icon
          type="icon-a-Frame1517"
          size={20}
          style={{
            marginRight: 4,
          }}
        />
      </Popover>
    );
    if (
      examOrder?.emergencyIndicator ||
      labOrder?.emergencyIndicator ||
      operationOrder?.operationApplyType === 'EMERGENCY' ||
      pathologyOrder?.emergencyIndicator
    ) {
      return node;
    } else {
      return null;
    }
  };

  const render = useCallback((...args) => {
    const [_, record] = args;
    const data = record?.children ?? [record];
    return (
      <div
        style={{
          padding: '4px 0',
        }}
      >
        {data?.map((item, index) => {
          // console.log(record, item, 'po');
          return (
            <div key={index}>
              <span>{renderUrgentIcon(record)}</span>
              <span>{renderHighriskIcon(record)}</span>
              <span>{item?.orderText}</span>
              {/* 皮试结果阴性 orderClass == 'DRUG'显示 vsf.stores.dbenums.enums.SKIN_RESULT 1 */}
              {record?.skinTestResult == '1' &&
                record?.order?.orderClass == 'DRUG' && (
                  <Popover content="皮试阴性">
                    <Icon
                      size={18}
                      style={{ marginLeft: 6 }}
                      type="icon-a-Frame1520"
                    />
                  </Popover>
                )}
              {/* 皮试结果阳性 orderClass == 'DRUG'显示  vsf.stores.dbenums.enums.SKIN_RESULT 2 */}
              {record?.skinTestResult == '2' &&
                record?.order?.orderClass == 'DRUG' && (
                  <Popover content="皮试阳性">
                    <Icon
                      size={20}
                      style={{ marginLeft: 6 }}
                      type="icon-a-Frame1521"
                    />
                  </Popover>
                )}
            </div>
          );
        })}
      </div>
    );
  }, []);

  const tableRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    ...(tableRef?.current ?? {}),
    getValidateValues: async (isAll) => {
      const res = isAll
        ? tableRef?.current?.getValues()
        : tableRef?.current?.getCheckedValues();
      const time = timeRef?.current;
      return res?.map((item) => {
        const performDateTime =
          time?.current?.[item?.id]?.value ?? new Date()?.valueOf();
        return {
          ...item,
          performDateTime,
        };
      });
    },
    getSelected() {
      const res = tableRef?.current?.getCheckedValues();
      const time = timeRef?.current;
      return res?.map((item) => {
        const performDateTime =
          time?.current?.[item?.id]?.value ?? new Date()?.valueOf();
        return {
          ...item,
          performDateTime,
        };
      });
    },
    clearChecked() {
      tableRef?.current?.clearChecked();
    },
  }));

  const [expandedRows, setExpandedRows] = useState<any>();

  const timeRef = useRef<any>({});

  const onTimeChange = (value, record) => {
    const current = timeRef?.current?.[record?.id];
    current.value = value;
  };

  const expandedColumns = [
    [
      {
        title: '开单医师',
        dataIndex: 'orderDoctorName',
      },
      // {
      //   title: '开单时间',
      //   dataIndex: 'test',
      // },
    ],
    [
      {
        title: '执行人',
        dataIndex: 'performNurseName',
      },
      {
        title: '执行时间',
        dataIndex: 'performDateTime',
        render: (record) => {
          return (
            <DatePicker
              showTime
              defaultValue={record?.performDateTime}
              onChange={(v) => {
                onTimeChange(v, record);
              }}
              ref={(ref) => {
                const prev = timeRef.current[record?.id]?.value;
                timeRef.current[record?.id] = {
                  ref: ref,
                  value: prev ?? '',
                };

                if (prev) {
                  // ref?.set(prev === 'checked');
                }
              }}
            />
          );
        },
      },
    ],
    // [
    //   {
    //     title: '核对人',
    //     dataIndex: 'checkNurseName',
    //   },
    // ],
    [
      {
        title: '结束人',
        dataIndex: 'stopNurseName',
      },
      {
        title: '结束时间',
        dataIndex: 'stopDateTime',
      },
    ],
    [
      {
        // orderClass !== 'DRUG'显示
        title: '皮试结果',
        dataIndex: 'skinTestResult',
        render: (record) => {
          return (
            <div>
              {record?.skinTestResult == '2' && '皮试阳性'}
              {record?.skinTestResult == '1' && '皮试阴性'}
            </div>
          );
        },
      },
    ],
  ];

  const getFormatRecord = (record) => {
    const time = timeRef?.current;
    const performDateTime =
      time?.current?.[record?.id]?.value ?? new Date()?.valueOf();
    console.log('performDateTime', performDateTime);
    const formatRecord = {
      ...record,
      performDateTime: record?.performDateTime ?? performDateTime,
    };
    return formatRecord;
  };

  const renderAction = (record, icon, title, type) => {
    let callback;

    switch (type) {
      case 'execute': // 执行
        {
          callback = () => {
            console.log(record, icon, title, type, 'record, icon, title, type');

            if (record?.doubleSignatureIndicator) {
              // 验证双签名
              Modal.confirm({
                title: '用户验证(皮试第二次签名验证)',
                centered: true,
                onOk: async (params) => {
                  const doubleSignatureFormData =
                    vsf?.refs?.vSFormDoubleSignature?.getFieldsValue();

                  return new Promise((resolve, reject) => {
                    if (
                      doubleSignatureFormData?.username ===
                      vsf?.stores?.user?.staffInfo?.loginName
                    ) {
                      vsf.showToast('双签名的用户不能是当前登陆用户');
                      return reject();
                    }
                    vsf?.services
                      ?.AccountController_staffLoginWithoutResponseCookies_5fd9d3?.(
                        {
                          username: doubleSignatureFormData?.username,
                          password: doubleSignatureFormData?.password,
                        },
                      )
                      .then((res) => {
                        if (res?.code == 200) {
                          resolve(res);
                          message.success('验证成功');
                          handleExecute?.(
                            getFormatRecord({
                              ...record,
                              checkNurseId: res?.data,
                            }),
                          );
                        } else {
                          reject();
                        }
                      });
                  });
                },

                okText: '确定',
                cancelText: '取消',
                width: 480,
                content: (
                  <VSForm
                    id="vSFormDoubleSignature"
                    // onChange={(formData) => {
                    //   props?.onChange?.(formData);
                    // }}
                    labelAlign="left"
                  >
                    <VSFormLayout key="0" columnCount={1} labelWidth={80}>
                      <VSFormItem
                        name={['username']}
                        label="用户名"
                        valueType="text"
                        fieldProps={{}}
                        required
                      />
                      <VSFormItem
                        name={['password']}
                        label="密码"
                        valueType="password"
                        fieldProps={{}}
                        required
                      />
                    </VSFormLayout>
                  </VSForm>
                ),
              });
            } else {
              handleExecute?.(getFormatRecord(record));
            }
          };
        }
        break;
      case 'cancel': // 取消
        {
          callback = () => {
            handleCancel?.(getFormatRecord(record));
          };
        }
        break;
      case 'invalid': // 作废
        {
          callback = () => {
            handleInvalid?.(getFormatRecord(record));
          };
        }
        break;
      case 'stop': // 结束
        {
          callback = () => {
            handleStop?.(getFormatRecord(record));
          };
        }
        break;
      case 'pause': // 暂停
        {
          callback = () => {
            handlePause?.(getFormatRecord(record));
          };
        }
        break;
      case 'unstop': // 取消暂停
        {
          callback = () => {
            handleUnstop?.(getFormatRecord(record));
          };
        }
        break;
    }

    if (type === 'execute' || type === 'pause') {
      return (
        <Button icon={icon} type="primary" ghost onClick={callback}>
          {title}
        </Button>
      );
    } else {
      return (
        <Button icon={icon} onClick={callback}>
          {title}
        </Button>
      );
    }
  };

  const renderOperation = (...args) => {
    const [, record] = args;

    return (
      <div className="aspirin-order-execute-oper-right">
        {record?.performStatus === 'NOT_EXECUTED' && (
          <>
            {renderAction(
              record,
              <PlayCircleOutlined />,
              '医嘱执行',
              'execute',
            )}
            {renderAction(record, <StopOutlined />, '取消执行', 'cancel')}
            {renderAction(record, <StopOutlined />, '作废执行', 'invalid')}
          </>
        )}
        {record?.performStatus === 'EXECUTED' && (
          <>{renderAction(record, <StopOutlined />, '取消执行', 'cancel')}</>
        )}
        {record?.performStatus === 'PROCESSING' && (
          <>
            {renderAction(record, <PauseCircleOutlined />, '暂停执行', 'pause')}
            {renderAction(record, <StopOutlined />, '结束执行', 'stop')}
            {renderAction(record, <StopOutlined />, '作废执行', 'invalid')}
          </>
        )}
        {record?.performStatus === 'SUSPEND' && (
          <>
            <>{renderAction(record, <StopOutlined />, '取消暂停', 'unstop')}</>
          </>
        )}
        {record?.performStatus === 'DEPRECATE' && (
          <>
            <>{renderAction(record, <StopOutlined />, '取消执行', 'cancel')}</>
          </>
        )}
      </div>
    );
  };

  const renderPauseRecord = (v) => {
    const showList = ['EXECUTED', 'PROCESSING', 'SUSPEND'];
    if (showList?.includes(v?.performStatus)) {
      return (
        <Button
          onClick={() => {
            handleShowPauseRecord?.(v);
          }}
        >
          查看暂停记录
        </Button>
      );
    } else {
      return null;
    }
  };

  const expandedRowRender = (data) => {
    const obj = {};
    return (
      <div className="aspirin-execute-order-table-expanded">
        <div className="left">
          {expandedColumns?.map((item, index) => {
            return (
              <div key={index} className="group">
                {item?.map((cItem, index) => {
                  console.log(data, 'poi');

                  return cItem?.dataIndex == 'skinTestResult' ? (
                    <div
                      style={{
                        display: data?.skinTestVsDrugIndicator ? '' : 'none',
                      }}
                      className="item"
                      key={index}
                    >
                      <span className="label">{cItem?.title}</span>
                      {cItem?.render ? (
                        <span className="value">{cItem?.render(data)}</span>
                      ) : (
                        <span className="value">
                          {data?.[cItem?.dataIndex] ?? ''}
                        </span>
                      )}
                    </div>
                  ) : (
                    // skinTestResult value?.format?.skinTestVsDrugIndicator
                    <div className="item" key={index}>
                      <span className="label">{cItem?.title}</span>
                      {cItem?.render ? (
                        <span className="value">{cItem?.render(data)}</span>
                      ) : (
                        <span className="value">
                          {data?.[cItem?.dataIndex] ?? ''}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderClassName = useCallback((record) => {
    let resClassName = '';
    // if (state?.id === record?.id) {
    //   resClassName = 'aspirin-table-row-active ';
    // }
    const { planTimeStatus } = record ?? {};
    switch (planTimeStatus) {
      case 'NOT_START':
        resClassName += 'aspirin-table-row-order-blue';
        break;
      case 'NORMAL':
        resClassName += 'aspirin-table-row-order-green';
        break;
      case 'TIME_OUT':
        resClassName += 'aspirin-table-row-order-red';
        break;
    }
    return resClassName;
  }, []);
  // console.log(tableData, 'tableData');

  return (
    <ASVSTable
      scroll={{
        x: 1500,
        // y: getScrollY(isMinScreen ? 230 : 425),
        y: getScrollYRem(25),
      }}
      data-id={ids}
      className="aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-vs-table-row-box aspirin-order-execute"
      bordered
      ref={tableRef}
      value={tableData}
      pagination={false}
      onVSCheck={{
        type: 'check',
        checkKey: 'id',
        onCheck: (record, status, data) => {
          return [];
        },
        onCheckAll: (record, status, data) => {
          return data;
        },
      }}
      expandable={{
        rowExpandable: (record) => {
          // console.log(record, 'po');
          return true;
        },
        expandColumnIndex: 1,
        expandedRowRender,
        expandedRows: expandedRows,
        onExpandedRowsChange: (v) => {
          setExpandedRows(v);
        },
        showExpandAll: true,
      }}
    >
      <VSTableColumn
        ellipsis
        dataIndex={['bedLabel']}
        title="床号"
        editable={false}
        valueType="text"
        fieldProps={{}}
        width={50}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['patientName']}
        title="姓名"
        editable={false}
        valueType="text"
        // width={120}
        width={labelNumberWidth(6)}
        formItemProps={{
          rules: [
            { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['planDateTime']}
        title="计划时间"
        editable={false}
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
        // width={160}
        width={labelNumberWidth(8)}
        render={(...args) => {
          const [, record] = args;
          return (
            <div className={renderClassName(record)}>
              {dayjs(record?.planDateTime)
                .format('YYYY-MM-DD HH:mm')
                ?.slice(5, 16)}
            </div>
          );
        }}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['startPerformDateTime']}
        title="开始时间"
        editable={false}
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
        width={160}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['orderText']}
        title="医嘱内容"
        editable={false}
        valueType="text"
        fieldProps={{}}
        // width={350}
        render={render}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['dosage']}
        title="剂量/用量"
        editable={false}
        valueType="custom"
        fieldProps={{}}
        width={100}
        render={(...args) => {
          const [, record] = args;

          const data = record?.children ?? [record];
          return data?.map((i) => {
            const { dosage, dosageUnit, usage, usageUnit } = i || {};
            const dosageStr = dosage ? `${dosage}${dosageUnit ?? ''}` : '';
            const usageStr = usage ? `${usage}${usageUnit ?? ''}` : '';
            return `${dosageStr}${usageStr ? `/${usageStr}` : ''}`;
          });
        }}
        preview
      />

      <VSTableColumn
        ellipsis
        dataIndex={['administration']}
        editable={false}
        title="途径"
        valueType="text"
        fieldProps={{}}
        width={100}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['frequency']}
        editable={false}
        title="频次"
        valueType="text"
        fieldProps={{}}
        width={100}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['pause']}
        editable={false}
        title="查看"
        valueType="custom"
        fieldProps={{}}
        shouldCellUpdate={undefined}
        render={(...[, record]) => renderPauseRecord(record)}
        // width={135}
        width={labelNumberWidth(8)}
      />

      <VSTableColumn
        ellipsis
        dataIndex={['operation']}
        editable={false}
        title="操作"
        valueType="custom"
        fieldProps={{}}
        shouldCellUpdate={undefined}
        render={renderOperation}
        // width={385}
        width={getScrollYRem(42)}
      />
    </ASVSTable>
  );
};

export default React.memo(
  forwardRef(Index),
  (prev: OrderExecuteTablePropsType, next: OrderExecuteTablePropsType) => {
    return isEqual(prev?.value, next?.value);
  },
);
