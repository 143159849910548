import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getMedicineRecord_c2259a: {
    method: 'post',
    url: '/api/clinic-record/get-medicine-record',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicineRecordVoClinicRecordEntranceWebVo[],
    ) => data,
  },
});
