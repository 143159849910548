/* eslint-disable react-hooks/exhaustive-deps */
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Input,
  message,
  Row,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState, useRequest, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Container from '@/module/Container';
import Loading from '@/module/Loading';
import {
  ButtonAndKeyDown,
  ComplexSelect,
  Flex,
  Grid,
} from '@/pages/Index/components';
import { useFetch } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { getPromiseHeight } from '@/utils';
import { add } from '@/utils/setDataUtil';

import {
  getDispenseForInpHerbRefundApplyByMasterIds,
  saveInpHerbRefundApply,
} from './services';
import UserInfo from './UserInfo';
/**
 * 病区草药退药申请
 * @returns {JSX.Element}
 */
const ApplicationForReturningHerbalMedicineInTheWard = (props) => {
  const [expandedRows, setExpandedRows]: any = useState();
  const [selectTableData, setSelectTableData]: any = useState();
  const [Amount, setAmount]: any = useState(0);
  const [applies, setApplies]: any = useState({});
  const [heavy, setHeavy]: any = useState(0);
  const onFetch = useCallback(async () => {
    if (!props?.value?.drugPrescriptionDispenseMasterId) {
      setAmount(0);
      // console.log(heavy);
      return {
        data: [],
        total: 0,
      };
    }
    // console.log(props?.value, '7475_value');
    const res = await getDispenseForInpHerbRefundApplyByMasterIds?.({
      value: props?.value,
    });
    // console.log(res, 'res');
    setExpandedRows(res?.data);
    setSelectTableData(res?.data);
    setAmount(res?.data?.length);
    setApplies(
      res?.data?.reduce?.((current, next) => {
        return {
          ...current,
          [next?.cid]: next?.dispenseDetails[0]?.refundAmount,
        };
      }, {}),
    );
    return res;
  }, [props?.value, heavy]);
  const expandedRowRender = (record) => {
    return (
      <VSTable
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
          onFieldChange: (
            key,
            _,
            { refundAmount, canRefundAmount },
            from,
          ): any => {
            if (
              !['', null, undefined]?.includes(canRefundAmount) &&
              refundAmount >= 1 &&
              refundAmount <= canRefundAmount
            )
              setApplies({ ...applies, [key]: refundAmount });
          },
        }}
        showHeader={false}
        pagination={false}
        bordered
        value={record?.dispenseDetails}
      >
        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          editable={false}
          valueType="text"
          fieldProps={{}}
          onCell={() => ({ colSpan: 7 })}
        />

        <VSTableColumn
          dataIndex={['canRefundAmount']}
          title="可退药数量"
          // editable={false}
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          className="number_input"
          dataIndex={['refundAmount']}
          title="申请退药数量"
          valueType="digit"
          fieldProps={(...args) => {
            return {
              min: 1,
              controls: false,
              onChange: (value) => {},
              onFocus: (v) => {
                v?.currentTarget?.select();
              },
            };
          }}
          formItemProps={(form, { entry, ...conig }: any) => {
            return {
              rules: [
                {
                  validator: (_, value) => {
                    if (['', null, undefined].includes(value))
                      return Promise.reject('退药数量不能为空');
                    if (value <= 0) return Promise.reject('退药数量需大于1');
                    // 使用正则表达式来匹配可能的输入
                    const regex = /^(.?\d*)$/;
                    if (value && !regex.test(value))
                      return Promise.reject('只能输入整数');
                    if (
                      value &&
                      // String(value).startsWith('-') &&
                      Math.abs(value) > entry?.canRefundAmount
                    )
                      return Promise.reject('退药数量不可大于可退药数量');
                    return Promise.resolve();
                  },
                },
              ],
            };
          }}
        />
      </VSTable>
    );
  };
  const [yMaxHeight, setYMaxHeight] = useState(528);
  getPromiseHeight('DetailsContainer').then((res) => {
    setYMaxHeight((res as number) - 30);
  });
  const onSave = useFetch({
    fetch: async (params) => {
      const res = saveInpHerbRefundApply?.({
        applies: params,
      });

      return res;
    },
    message: '发起退药',
    success: async (value) => setHeavy(Math?.random()),
  });
  return (
    <Flex
      vertical
      style={{
        height: '100%',
      }}
      gap={20}
    >
      <Grid
        style={{
          background: 'var(--background)',
          padding: '0.8rem',
        }}
        amount={1}
        gap={20}
      >
        <UserInfo {...props} inpVisitId={props?.value?.inpVisitId} />
      </Grid>
      <Flex
        flex={1}
        vertical
        justify="space-between"
        style={{
          background: 'var(--background)',
          padding: '1.6rem',
        }}
      >
        <div id="DetailsContainer">
          <h2 className="title">{`可退药明细共 ${Amount} 个处方`}</h2>
          <VSPromiseTable
            id="tableInpHerbDispenseDetailForRefundApply"
            onFetch={onFetch}
            rowSelection={{
              selectType: 'multiple',
              onSelectedRowsChange: (data, keys) => {
                setSelectTableData(data);
              },
              selectedRows: selectTableData,
            }}
            className="aspirin-table-no-background-box common_table "
            expandable={{
              expandedRows,
              expandDataIndex: 'dispenseDetails',
              expandedRowRender,
              onExpandedRowsChange: (rows) => setExpandedRows(rows),
            }}
            showHeader={false}
            pagination={false}
            scroll={{ x: '6.66rem', y: yMaxHeight }}
            rowClassName={'table_head'}
          >
            <VSTableColumn
              dataIndex={['index']}
              title="序号"
              editable={false}
              valueType="text"
              fieldProps={{}}
              render={(_, __, index) => index + 1}
              width="2.13rem"
            />
            <VSTableColumn
              width="8.4rem"
              dataIndex={['prescriptionDataTime']}
              title="处方日期"
              editable={false}
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD' }}
            />

            <VSTableColumn
              dataIndex={['departmentName']}
              title="开单科室"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              width="5.73rem"
              dataIndex={['staffName']}
              title="开单医生"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['prescriptionAttribute']}
              title="中药类型"
              editable={false}
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
            />

            <VSTableColumn
              width="16.4rem"
              dataIndex={['dispenseTime']}
              title="发药时间"
              editable={false}
              valueType="text"
              render={(_, { dispenseTime }) => `发药时间：${dispenseTime}`}
            />

            <VSTableColumn
              width="5.73rem"
              dataIndex={['herbRegimen']}
              title="总付数"
              editable={false}
              valueType="digit"
              fieldProps={{}}
              render={(_, { herbRegimen }) => `${herbRegimen}付`}
            />

            <VSTableColumn
              dataIndex={['agreedPrescriptionName']}
              title="协定方名称"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['']}
              editable={false}
              valueType="text"
              fieldProps={{}}
              render={() => '可退贴数'}
            />
            <VSTableColumn
              dataIndex={['']}
              editable={false}
              valueType="text"
              fieldProps={{}}
              render={() => '退药贴数'}
            />
          </VSPromiseTable>
        </div>
        <Divider
          style={{
            background: 'var(--blue)',
          }}
        />

        <Flex gap={10} justify="flex-end">
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="P"
            type="primary"
            ghost
            onClick={() => {}}
          >
            打印
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="S"
            type="primary"
            onClick={() => {
              const table = vsf?.refs?.tableInpHerbDispenseDetailForRefundApply,
                table_values = table?.getValues(),
                selecteds = table
                  ?.getSelectedRowValues()
                  ?.map?.((ele) => ele?.cid);
              if (!selecteds?.length) return message?.error('请选择退药处方');
              Tip({
                content: '是否发起退药？',
                onOk: async (...args) =>
                  onSave?.(
                    selecteds?.map?.((id) => {
                      const value = table_values?.find?.(
                        (item) => item?.cid == id,
                      );
                      return {
                        detailId: id,
                        refundAmount: applies[id],
                        orderText: value?.dispenseDetails[0]?.orderText,
                      };
                    }),
                  ),
              });
            }}
          >
            退药确认
          </ButtonAndKeyDown>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default definePage(ApplicationForReturningHerbalMedicineInTheWard);
