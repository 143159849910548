import {
  Col,
  Row,
  VSFormItem,
  Input,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  VSTable,
  Button,
  VSTableAction,
  Space,
  message,
  Modal,
  VSForm,
  VSFormLayout,
  VSFormFooter,
  Form,
  Tooltip,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { subtract } from '@/utils/setDataUtil';
import Screen from '@/pages/Index/components/screen';

import { getDetailById } from './Service';

import './index.less';
import UserInfo from './UserInfo';
const Big = require('big.js');

export default function TableWaitConfirm({
  tabaleParam,
  onChangeRun,
  callback,
}) {
  /**
   * convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391
   * @param {any} input
   * @returns {any}
   */
  const convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391 =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.returnDrugReason = _.get(input, `data.[0].returnDrugReason`);
      return output;
    };
  const { data: detailData, run } = useRequest(getDetailById, {
    manual: true,
  });
  const { data: withdrawalReasonData, run: reasonRun } = useRequest(
    async (p) => {
      const res =
        await vsf.services?.DrugPrescriptionRefundDispenseController_getRefundHerbDetailByMasterIds_fd2064?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            id: tabaleParam?.refundMasterId ?? [2504], //TODO test
          },
        ); // Tips: 需要返回内容数据，结构和表单RO一致
      return convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391(
        res,
      );
    },
  );
  const [prescriptionCount, setPrescriptionCount] = useState();
  // const [withdrawalReason, setWithdrawalReason] = useState(withdrawalReasonData?.returnDrugReason||'');
  const [selectTableData, setSelectTableData] = useState();
  /**
   * convertGetRefundHerbDetailByMasterIdsRes2TableHerbRefundDetail
   * @param {any} input
   * @returns {any}
   */
  const convertGetRefundHerbDetailByMasterIdsRes2TableHerbRefundDetail = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ..._.get(input, 'data').map((d, i) => {
        const obj = {}; // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionDataTime = _.get(
          d,
          'prescription.prescriptionDataTime',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.departmentName = _.get(
          d,
          'prescription.orderHerb.order.orderDepartmentInfo.departmentName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.staffName = _.get(
          d,
          'prescription.orderHerb.order.doctorInfo.staffName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.herbType = _.get(d, 'prescription.orderHerb.herbType'); // TODO 请确认下行字段是否符合逻辑
        obj.settleDate = _.get(d, 'prescriptionDispense.outpSettle.settleDate'); // TODO 请确认下行字段是否符合逻辑
        obj.herbRegimen = _.get(d, 'prescriptionDispense.herbRegimen'); // TODO 请确认下行字段是否符合逻辑
        obj.id = _.get(d, 'id');
        obj.agreedPrescriptionName = _.get(
          d,
          'prescription.orderHerb.agreedPrescriptionName',
        );
        obj.refundDetails = [
          {
            orderText: _.get(d, 'prescription.orderHerb.order.orderText'),
            applyRefundHerbRegimen: _.get(d, 'applyRefundHerbRegimen'),
            actualRefundHerbRegimen: _.get(d, 'actualRefundHerbRegimen'),
            refundAmount:
              _.get(d, 'applyRefundHerbRegimen') -
              _.get(d, 'actualRefundHerbRegimen'),
            id: _.get(d, 'id'),
          },
        ];
        return obj;
      }),
    );
    return output;
  };
  /**
   * convertTableOrderDrugDispenseDetail2PerformRefundReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableOrderDrugDispenseDetail2PerformRefundReq = (input) => {
    const output = [];
    (input ?? []).forEach((item) => {
      const obj = {
        detailId: item.id,
        refundAmount: item.refundAmount || 0,
        orderText: item.orderText,
      };
      output.push(obj);
    });
    return (output ?? []).filter((m) => m.refundAmount > 0);
  };

  /**
   * convertTableHerbRefundDetail2PerformHerbRefundReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableHerbRefundDetail2PerformHerbRefundReq = (input) => {
    const output = [];
    (input ?? []).forEach((d) => {
      (d.refundDetails ?? []).forEach((item) => {
        const obj = {
          detailId: item.id,
          refundAmount: item.refundAmount,
          orderText: item.orderText,
        };
        output.push(obj);
      });
    });
    return output;
  };

  const [paramData, setParamData] = useState();
  useEffect(() => {
    setPrescriptionCount(0);
    run(tabaleParam);
    reasonRun(tabaleParam);
  }, [tabaleParam]);
  const [expandedRows, setExpandedRows] = useState();
  const expandedRowRender = (record) => {
    return (
      <VSTable
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
        }}
        id="tableOrderDrugDispenseDetail-refundApplyDetailList"
        showHeader={false}
        pagination={false}
        bordered
        value={record?.refundDetails}
        className=" row_font_size"
        rowClassName="row_font_size"
        onChange={(v) => {
          onFetch;
          const arr = [];
          paramData.map((item) => {
            const data = v?.find((items) => items.id === item.detailId);
            if (data?.refundAmoun > 0) {
              const param = {
                detailId: data?.id,
                refundAmount: data?.refundAmount,
                orderText: data?.orderText,
              };
            }
            arr.push(param);
          });
          paramData && setParamData(arr);
        }}
      >
        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          editable={false}
          valueType="text"
          fieldProps={{}}
          preview
          render={(_, values) => {
            return (
              <div style={{ textAlign: 'left', paddingLeft: '12px' }}>
                {values?.orderText}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['amountUnit']}
          title="数量"
          editable={false}
          valueType="text"
          hideInTable={true}
          fieldProps={{}}
          width={100}
          preview
        />

        <VSTableColumn
          dataIndex={['usageUnit']}
          title="用量"
          editable={false}
          valueType="text"
          fieldProps={{}}
          hideInTable={true}
          width={100}
          preview
        />

        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          editable={false}
          valueType="select"
          fieldProps={{}}
          hideInTable={true}
          dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
          width={100}
          preview
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="给药途径"
          editable={false}
          hideInTable={true}
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['performDays']}
          title="医嘱天数"
          editable={false}
          valueType="digit"
          hideInTable={true}
          fieldProps={{}}
          width={100}
          preview
          render={(_, values) => {
            return `${values?.performDays}天`;
          }}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          width={100}
          hideInTable={true}
          render={(_, values) => {
            return Number(values?.price).toFixed(2);
          }}
          preview
        />

        <VSTableColumn
          dataIndex={['applyRefundHerbRegimen']}
          title="申请贴数"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '实际退药数量的值不合法',
                type: 'number',
                min: 1,
                max: undefined,
              },
            ],
          }}
          width={100}
          fieldProps={{}}
          preview
        />
        <VSTableColumn
          dataIndex={['actualRefundHerbRegimen']}
          title="已退数量"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '实际退药数量的值不合法',
                type: 'number',
                min: 1,
                max: undefined,
              },
            ],
          }}
          width={100}
          fieldProps={{}}
          render={(_, value) => {
            return value?.actualRefundHerbRegimen ?? 0;
          }}
          preview
        />
        <VSTableColumn
          dataIndex={['refundAmount']}
          title="退药贴数"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                validator(_, value) {
                  if ([undefined, null].includes(value)) {
                    return Promise.reject('请输入数量');
                  } else if (!Number.isInteger(value)) {
                    return Promise.reject('数量必须是整数');
                  } else if (value < 0) {
                    return Promise.reject('数量必须是正整数');
                  } else if (value === 0) {
                    return Promise.reject('数量不能为0');
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ],
          }}
          width={150}
        />
      </VSTable>
    );
  };

  const onFetch = useCallback(
    async (params) => {
      setSelectTableData([]);
      if (!tabaleParam) return [];
      const res =
        await vsf.services?.DrugPrescriptionRefundDispenseController_getRefundHerbDetailByMasterIds_fd2064?.(
          {
            id: tabaleParam?.refundMasterId,
          },
        );
      const output =
        convertGetRefundHerbDetailByMasterIdsRes2TableHerbRefundDetail(res);
      setPrescriptionCount(res?.data?.count ?? res?.data?.length ?? 0);

      setSelectTableData(output);
      setExpandedRows(output);
      return {
        data: output,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },

    [tabaleParam],
  );

  useEffect(() => {
    if (selectTableData && selectTableData.length > 0) {
      let arr = [];
      selectTableData.map((item) => {
        arr = [...arr];
      });
      const details = convertTableHerbRefundDetail2PerformHerbRefundReq(
        selectTableData ?? [],
      );

      setParamData(details);
    }
  }, [selectTableData]);
  const confirmDistribute = async () => {
    if (!paramData || paramData?.length === 0) {
      return '没有选中药品!';
    }
    if (!selectTableData) {
      message.error('没有选中药品!');
      return;
    }
    if (selectTableData && selectTableData.length === 0) {
      message.error('没有选中药品!');
      return;
    }
    const paramArr = [];
    const data = vsf?.refs?.tableOrderDrugDispenseDetail?.getValues();

    data?.map((item) => {
      item?.refundDetails?.map((item) => {
        paramArr?.push(item);
      });
    });

    const param = paramData?.map((obj) => {
      const data = paramArr?.find((item) => item?.id === obj?.detailId);
      if (data) {
        return { ...data, detailId: data.id };
      } else {
        return obj;
      }
    });

    const res =
      await vsf.services?.DrugPrescriptionRefundDispenseController_performHerbRefund_8e3546?.(
        {
          refundDetails: param,
        },
      );
    if (res?.code === 200) {
      setSelectTableData(undefined);
      message.success('退药成功');
      onChangeRun();
      callback();
      vsf.refs.tableOrderDrugDispenseDetail?.reload();
    }
  };
  return (
    <>
      <div className="top">
        <UserInfo outpVisitId={tabaleParam?.outpVisitId} />
      </div>
      <div className="bottom">
        <div className="total-table-prescription">
          <span className="total-chager-chufang">
            退药明细 共{prescriptionCount || 0}个处方
          </span>
        </div>
        <VSPromiseTable
          vsid="98204"
          id="tableOrderDrugDispenseDetail"
          rowSelection={{
            selectType: 'multiple',
            onSelect: (node, key, selected) => {},
            onSelectedRowsChange: (data, keys) => {
              setSelectTableData(data);
            },
            selectedRows: selectTableData,
          }}
          expandable={{
            expandDataIndex: ['refundApplyDetailList'],
            expandedRowRender,
            expandedRows,
            onExpandedRowsChange: (rows) => setExpandedRows(rows),
          }}
          onFetch={onFetch}
          showHeader={false}
          pagination={false}
          rowClassName={(...[, rowIndex]) => {
            return 'aspirin-table-row-background-box';
          }}
          scroll={{ x: 1100}}
        >
          <VSTableColumn
            dataIndex={['prescriptionDataTime']}
            title="处方日期"
            valueType="index"
            width={100}
            fieldProps={
              {
                // width:'150'
              }
            }
          />

          <VSTableColumn
            dataIndex={['prescriptionDataTime']}
            title="处方日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['departmentName']}
            title="开单科室"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['staffName']}
            title="开单医生"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['herbType']}
            title="处方类型"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.PRESC_TYPE_DICT}
          />

          <VSTableColumn
            dataIndex={['settleDate']}
            title="收费日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={200}
          />

          <VSTableColumn
            dataIndex={['herbRegimen']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            render={(_, v) => {
              return `${v.herbRegimen}付`;
            }}
          />

          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width={100}
            render={(_, values) => {
              return '申请贴数';
            }}
          />

          <VSTableColumn
            dataIndex={['actualAmount']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width={100}
            render={(_, values) => {
              return '已退数量';
            }}
          />
          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width={150}
            render={(_, values) => {
              return '退药贴数';
            }}
          />
        </VSPromiseTable>
      </div>
      <div className="center-dec">
        <Input
          placeholder="请输入退药原因"
          bordered={false}
          value={`退药原因:${withdrawalReasonData?.returnDrugReason || ''}`}
          onChange={(v) => {
            console.log(vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT);
            // setWithdrawalReason(v.target.value);
          }}
          disabled
        />
      </div>
      <div className="center">
        <div></div>
        <div>
          <Space size={4}>
            <Button type="primary"> 打印</Button>
            <Button type="primary" onClick={confirmDistribute}>
              退药确认
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}
