import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getDrugExportDetailListByDrugExportMasterId_30f5f3:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-export-detail-list',
      parameterFomatter: (data?: { drugExportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugExportDetailVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
});
