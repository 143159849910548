/* eslint-disable no-prototype-builtins */
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Modal,
  Popover,
  Tooltip,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';
import React, {
  createContext,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { cdssShow } from '@/mock';
import Block from '@/module/Block';
import Icon from '@/module/Icon';
import { askMedicalOrders } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import Stop from '@/pages/Index/cpoe/center/main/stop';
import { getScrollY } from '@/utils';

const Index = (props, ref) => {
  const { onConfirm } = props;

  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState<any>();
  const [orderData, setOrderData] = useState<any>();
  const [submintObj, setSubmintObj] = useState<any>({});
  // false:继续 true:强制
  const [btnType, setBtnType] = useState(false);
  useEffect(() => {
    getTableData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const decisionSupport = {
    BLOCKED: { text: '拦截', icon: 'icon-lanjie', color: '#e64340' },
    WARN: { text: '警示', icon: 'icon-jingshi', color: '#0b8228' },
  };

  const handelOpen = (v = false, orderDatas?: any) => {
    setOpenModal(v);
    if (v) {
      setOrderData(orderDatas);
    }
  };

  const getTableData = (v) => {
    const res = v?.filterRule?.find((item) => {
      return item?.warnList?.find((i) => {
        return i?.level === 'BLOCKED';
      });
    });
    setBtnType(res ? true : false);
    setSubmintObj({
      operateStatus: btnType ? '1' : '2',
      filterId: v?.filterId,
    });
    setTableData(v?.filterRule ?? []);
  };

  const handelSubmint = () => {
    const {
      user: { inVisitInfo, outVisitInfo },
      cdss: { cdssOrderOperate },
    } = vsf?.stores;

    cdssOrderOperate({
      inpVisitId: inVisitInfo?.inpVisitId,
      outpVisitId: outVisitInfo?.outpVisit?.id,
      operateBlockedOrderEo: submintObj,
    });
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
    getTableData,
  }));

  return (
    <Modal
      className="cdss_modal"
      open={openModal}
      centered
      title={<div style={{ color: 'black' }}>CDSS临床决策支持系统</div>}
      width={900}
      bodyStyle={{ height: 450 }}
      closeIcon={() => {
        return false;
      }}
      footer={[
        btnType ? (
          <Button
            icon={
              <Icon style={{ color: '#e64340' }} type="icon-qiangzhizhihang" />
            }
            style={{
              //   borderRadius: '8px',
              //   height: 34,
              marginRight: 20,
              //   color: '#e64340',
              //   border: '1px solid #e64340',
            }}
            size="large"
            key="submit"
            danger
            onClick={() => {
              handelOpen(false);
              handelSubmint();
              onConfirm?.(orderData);
            }}
          >
            强制执行
          </Button>
        ) : (
          <Button
            icon={<Icon type="icon-qiangzhizhihang" />}
            style={{
              //   borderRadius: '8px',
              //   height: 34,
              marginRight: 20,
              //   border: '1px solid #a29e9e',
              //   color: 'black',
            }}
            // size="middle"
            size="large"
            key="submit"
            type="default"
            // ghost
            onClick={() => {
              handelOpen(false);
              handelSubmint();
              onConfirm?.();
            }}
          >
            继续执行
          </Button>
        ),
        <Button
          style={{ width: 110 }}
          type="primary"
          // size="middle"
          size="large"
          key="cancel"
          onClick={() => {
            handelSubmint();
            handelOpen(false);
          }}
        >
          修改
        </Button>,
      ]}
    >
      <VSTable
        id="filterRuleList"
        value={tableData}
        pagination={false}
        bordered
        scroll={{ y: getScrollY(545) }}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="报错项目"
          valueType="text"
          width={200}
          align="center"
          render={(dom, value) => {
            return (
              <div className="text_ellipsis2">
                <Popover content={value?.name}>{value?.name}</Popover>
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['warnList']}
          title="问题描述"
          valueType="text"
          align="center"
          width="100%"
          fieldProps={{
            style: { padding: 0 },
          }}
          className="warnList"
          render={(_, value) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {value?.warnList?.map((item, index) => {
                  return (
                    <div
                      className="text_ellipsis2"
                      style={{
                        padding: '8px 12px',
                        borderBottom:
                          index == value?.warnList?.length - 1
                            ? 'none'
                            : '1px solid #f0f0f0',
                      }}
                      key={index}
                    >
                      <Popover content={item?.filterMessage}>
                        {item?.filterMessage}
                      </Popover>
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['warnList']}
          title="决策支持"
          valueType="text"
          align="center"
          width={150}
          fieldProps={{
            style: { padding: 0 },
          }}
          className="warnList"
          render={(_, value) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {value?.warnList?.map((item, index) => {
                  return (
                    <div
                      className="text_ellipsis2"
                      style={{
                        padding: '8px 12px',
                        color: decisionSupport?.[item?.level]?.color,
                        borderBottom:
                          index == value?.warnList?.length - 1
                            ? 'none'
                            : '1px solid #f0f0f0',
                      }}
                      key={index}
                    >
                      <Icon
                        style={{ marginRight: 4 }}
                        size={14}
                        type={decisionSupport?.[item?.level]?.icon}
                      />
                      {decisionSupport?.[item?.level]?.text}
                    </div>
                  );
                })}
              </div>
            );
          }}
        />
      </VSTable>
    </Modal>
  );
};

export default React.forwardRef(Index);
