import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_getRefundHerbDetailByMasterIds_fd2064:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-list-drug-prescription-refund-apply-master-herb-dispense-vo-by-ids',
      parameterFomatter: (data?: { id: number[] }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionRefundApplyMasterHerbDispenseVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  InpRefundDispenseController_getDispenseForInpHerbRefundApplyByMasterIds_40e4cd:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-dispense-for-inp-herb-refund-apply-by-master-ids',
      parameterFomatter: (data?: { id: number[] }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionDispenseMasterForInpHerbRefundApplyVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  InpRefundDispenseController_saveInpHerbRefundApply_3c3316: {
    method: 'post',
    url: '/api/drug-prescription-dispense/save-inp-herb-refund-apply',
    parameterFomatter: (data?: {
      applies: RefundApplyDetailEoDrugPrescriptionDispensePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
