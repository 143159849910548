import vsf from '@vs/vsf-boot';

import React, { useState, Fragment } from 'react';

import DrugPriceAdjustList from './List';
import DrugPriceAdjustListDetail from './ListDetail';

const DrugPriceAdjust = (props) => {
  const [Master, setMaster] = useState();

  return (
    <Fragment>
      <DrugPriceAdjustList {...{ Master, setMaster }} />
      <DrugPriceAdjustListDetail {...{ Master, setMaster }} />
    </Fragment>
  );
};

export default DrugPriceAdjust;
