import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  Form,
  InputNumber,
  message,
  Row,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { useLockFn, useRequest, useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import Block from '@/module/Block';
import Icon from '@/module/Icon';

import { ButtonAndKeyDown } from '@/pages/Index/components';
import {
  askFixed,
  askIsInteger,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';
import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelColWidthRem,
} from '@/utils';
import storage from '@/utils/storage';

import { CreateScreen } from '@/pages/Index/components/screen';
import { DrugExportContext } from '.';
import { isMinScreen } from '@/config';

const ExportRecord = (props) => {
  const [drugStorageDataSource, setDrugStorageDataSource] = useState([]);
  // const [available, setAvailable] = useState([]);
  const [receive, setReceive] = useState([]);

  const onScreen = useContext(CreateScreen);

  const invalid = useRef(null);

  // const getAvailable = useCallback(async () => {
  //   const res =
  //     await vsf?.services?.DrugImportRecordCreateController_getCandidateImportExportClzList_6a4edf(
  //       {
  //         isImport: false,
  //       },
  //     );
  //   setAvailable(res.data);
  // }, []);

  const getReceive = useCallback(async (clzCode) => {
    const res =
      await vsf?.services?.DrugImportRecordCreateController_getCandidateStorageList_1ef4f5(
        {
          clzCode,
        },
      );
    setReceive(
      res?.data?.map((item) => ({ ...item, departmentName: item.name })),
    );
  }, []);

  const { run: getValue } = useRequest(
    async (id) => {
      const res =
        await vsf.services.DrugStorageImportExportController_getExportMasterWithDetailById_c96935(
          {
            id,
          },
        );
      if (res.code === 200) {
        const {
          drugExportDetailWithProductFirmDtoList,
          exportDate,
          subDrugStorage: subDrugStorageId,
          ...rest
        } = res.data;
        vsf?.refs?.ExportRecord?.setFieldsValue({
          ...rest,
          ...(rest?.receiveDepartment
            ? {
                receiveDepartment: rest.receiveDepartment?.id,
              }
            : {}),
          subDrugStorageId,
          drugExportDetailBtoList:
            drugExportDetailWithProductFirmDtoList?.toReversed(),
        });
        invalid?.current?.setDisabled(false);
        getReceive(rest?.exportImportCode);
      }
    },
    {
      manual: true,
    },
  );

  /**
   * 记账
   */
  const onAccount = useLockFn(async () => {
    const params = vsf?.refs?.ExportRecord?.getFieldsValue();
    Tip({
      content: `确定记账流水号为:{ ${params?.financeDocumentNumber} }出库单据?`,
      async onOk() {
        const res =
          await vsf.services?.DrugStorageImportExportController_accountantExport_d26633?.(
            {
              btoParam: {
                ...params,
                exportDate: dayjs(params?.exportDate)?.valueOf(),
                subDrugStorageId: params?.subDrugStorageId?.id,
                receiveDepartmentId: params?.receiveDepartment?.id,
                cost: String(params.cost),
              },
            },
          );
        if (res.code === 200) {
          onScreen(() => {
            message.success('记账成功');
          });
        }
        return res?.data;
      },
      okButtonProps: {},
    });
  });

  /**
   * 作废
   */
  const onInvalid = useLockFn(async () => {
    Tip({
      content: `确定作废{ ${vsf?.refs?.ExportRecord?.getFieldValue(
        'financeDocumentNumber',
      )} }待记账单据?`,
      async onOk() {
        const res =
          await vsf.services?.DrugStorageImportExportController_invalidDrugExportMaster_edb33f?.(
            {
              id: vsf?.refs?.ExportRecord?.getFieldValue('id'),
            },
          );
        if (res.code === 200) {
          message.success('作废成功');
          onScreen(() => void 0);
        }
      },
    });
  });

  /**
   * 保存
   */
  const onSubmit = useLockFn(async () => {
    try {
      if (
        !vsf?.refs?.ExportRecord?.getFieldValue('drugExportDetailBtoList')
          ?.length
      ) {
        await vsf?.refs?.ExportRecordTable?.add({
          id: 'create_' + crypto?.randomUUID(),
        });
      }
      const [params] = await Promise?.all([
        vsf?.refs?.ExportRecord?.validateFieldsReturnFormatValue(),
        // vsf?.refs?.ExportRecordTable?.getEditForm()?.validateFieldsReturnFormatValue(),
      ]);
      const res =
        await vsf.services?.DrugStorageImportExportController_createUpdateDrugExportMasterWithDetail_40b45d?.(
          {
            btoParam: {
              ...params,
              ...(props?.value?.applyNumber
                ? {
                    linkApplyNumber: props?.value?.applyNumber,
                    drugApplyMasterId: props?.value?.id,
                  }
                : {}),
              drugExportDetailBtoList: params?.drugExportDetailBtoList
                ?.filter(
                  (item) =>
                    item?.drugProduct?.drugProductName &&
                    item?.stockId &&
                    item?.amount,
                )
                ?.map((item, index) => {
                  if (String(item.id).startsWith('create')) delete item.id;
                  const retailAmount = askFixed(
                    (item.retailPrice ?? 0) * item.amount,
                    2,
                  );
                  return {
                    ...item,
                    itemNumber: index + 1,
                    drugProductId: item?.productId ?? item?.drugProduct?.id,
                    firmId: item.firm?.id,
                    checkIndicator: false,
                    retailAmount,
                  };
                }),
            },
            extApiParam: params?.extApiParam,
          },
        );
      if (res.code === 200) {
        invalid?.current?.setDisabled(false);
        message.success('出库信息已保存');
        getValue(res?.data?.id);
      }
    } catch (error) {
      return error;
    }
  });

  const getStorage = async () => {
    const res =
      await vsf?.services?.DrugStorageController_getSubStorageListByDrugStorageId_8b4549(
        {},
      );
    // vsf?.refs?.ExportRecord?.setFieldValue('subDrugStorageId', res?.data?.[0]);
    setDrugStorageDataSource(res.data);
  };

  const Footer = () => {
    const button = [
      {
        text: '保存',
        ordinaryKey: 'S',
        methodKey: 'altKey',
        disabled: false,
        onClick: onSubmit,
      },
      {
        text: '记账',
        ordinaryKey: 'A',
        methodKey: 'altKey',
        ref: invalid,
        disabled: invalid?.current?.getDisabled ?? true,
        onClick: onAccount,
      },
      {
        text: '作废单据',
        ordinaryKey: 'D',
        methodKey: 'altKey',
        disabled: !props?.value?.id,
        onClick: onInvalid,
      },
      {
        text: '清屏',
        ordinaryKey: 'R',
        methodKey: 'altKey',
        disabled: false,
        onClick: onScreen,
      },
    ];

    return (
      <div className="aspirin-table-footer-button">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (props?.value?.financeDocumentNumber) {
      /**
       * 判断是否有流水号
       */
      getValue(props?.value?.id);
    } else if (props?.value?.applyNumber) {
      /**
       * 判断是否有申请号
       */
      getReceive(props?.value?.applyType);
      const list = props?.value?.drugExportDetailBtoList;
      // console.log(list, 'list');
      vsf?.refs?.ExportRecord?.setFieldsValue({
        exportImportCode: props?.value?.applyType,
        receiveDepartment: props?.value?.applyDepartment?.id,
        ...(list?.length
          ? {
              /**
               * 总金额
               */
              cost: askTotalAmount(
                list?.flatMap((item) =>
                  item?.drugStockList?.map((item) => [
                    item?.stockPrice,
                    item?.exportAmount,
                  ]),
                ),
              ),
              /**
               * 药品列表
               */
              drugExportDetailBtoList: list?.flatMap((item) => {
                if (item?.drugStockList?.length) {
                  return item?.drugStockList?.map((option) => {
                    const { id, exportAmount, stockPrice, ...rest } = option;
                    return {
                      ...rest,
                      id: 'create' + Math.random(),
                      firm: item?.drugProduct?.drugFirm,
                      amount: exportAmount,
                      drugSpecification: item?.drugSpecification,
                      stockId: id,
                      purchasePrice: stockPrice,
                      purchaseAmount: askFixed(stockPrice * exportAmount, 2),
                      unit: item?.unit,
                    };
                  });
                }
              }),
            }
          : {}),
      });
    }
  }, [props?.value]);

  /**
   * 修改工作站清屏页面
   */
  const currentApplication = storage.get('currentApplication');
  useUpdateEffect(() => {
    onScreen(() => void 0);
  }, [currentApplication?.id]);

  useEffect(() => {
    getStorage();
    // getAvailable();
  }, []);

  // const applyNumber =
  //   props?.value?.applyNumber ??
  //   vsf?.refs?.ExportRecord?.getFieldValue(['linkApplyNumber']);
  const financeDocumentNumber = new Date()
    .toLocaleString()
    .replaceAll('/', '')
    .replaceAll(':', '')
    .replace(/\s/, '');

  const available = useContext(DrugExportContext);
  const [form] = Form.useForm();
  const drugExportDetailBtoList =
    Form?.useWatch('drugExportDetailBtoList', form) ?? [];
  return (
    <Block footer={<Footer />} title="药品出库明细">
      <VSForm
        vsid="17359"
        labelAlign="left"
        id="ExportRecord"
        onChange={(value) => {
          if (!invalid?.current?.getDisabled) {
            invalid?.current?.setDisabled(true);
          }
          const cost = value?.drugExportDetailBtoList?.reduce(
            (accountValue, currentValue) =>
              (accountValue ?? 0) + Number(currentValue?.purchaseAmount ?? 0),
            0,
          );
          vsf?.refs?.ExportRecord?.setFieldValue(
            'cost',
            askFixed(cost + (value?.additionalCost ?? 0), 2),
          );
        }}
        form={form}
        onFieldsChange={(val) => {
          if (val[0].name[0] == 'exportImportCode') {
            const vs = vsf?.refs?.ExportRecord?.getFieldsValue();
            vs.receiveDepartment = undefined;
            vsf?.refs?.ExportRecord?.setFieldsValue({ ...vs });
          }
        }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout
          key="2"
          columnCount={4}
          labelCol={labelColWidthRem('6.66rem')}
        >
          {/* 出库类型 */}
          <VSFormItem
            name={['exportImportCode']}
            label="出库类型"
            valueType="select"
            fieldProps={{
              disabled: Object.keys(props?.value).length,
              onChange: (value) => {
                getReceive(value?.code);
              },
              showSearch: true,
            }}
            dataSource={available}
            transform={(value) => {
              return { exportImportCode: value?.code ?? value };
            }}
            fieldNames={{
              label: 'name',
              value: 'code',
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          {/* 出库日期 */}
          <VSFormItem
            name={['exportDate']}
            label="出库日期"
            valueType="aspirinDateTimePicker"
            initialValue={dayjs()?.valueOf()}
            fieldProps={{
              width: '100%',
              format: 'YYYY-MM-DD',
              valueType: 'timestamp',
              datePickerProps: {
                width: '100%',
              },
            }}
          />

          {/* 发往 */}
          <VSFormItem
            name={['receiveDepartment']}
            label="发往"
            valueType="select"
            fieldProps={{
              disabled: Object.keys(props?.value).length,
              showSearch: true,
            }}
            transform={(value) => {
              return {
                receiveDepartmentId: value?.exportId ?? value,
              };
            }}
            dataSource={receive}
            fieldNames={{
              label: 'departmentName',
              value: 'exportId',
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          {/* 应收款 */}
          <VSFormItem
            name={['cost']}
            label="应收款"
            valueType="digit"
            fieldProps={{
              width: '100%',
              addonAfter: '元',
              disabled: true,
              precision: 2,
              placeholder: '',
            }}
          />

          {/* 附加费 */}
          <VSFormItem
            name={['additionalCost']}
            label="附加费"
            valueType="digit"
            fieldProps={{
              width: '100%',
              addonAfter: '元',
            }}
          />

          {/* 出库药房 */}
          <VSFormItem
            name={['subDrugStorageId']}
            label="出库药房"
            valueType="select"
            dataSource={drugStorageDataSource}
            transform={({ id }) => {
              return {
                subDrugStorageId: id,
              };
            }}
            fieldNames={{
              label: 'subStorageName',
              value: 'id',
            }}
            fieldProps={{
              // disabled: Object.keys(props?.value).length,
              disabled: drugExportDetailBtoList?.length,
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          {/* 备注 */}
          <VSFormItem
            name={['memo']}
            label="备注"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ]}
            fieldProps={{}}
          />

          {/* 流水号 */}
          <VSFormItem
            name={['financeDocumentNumber']}
            label="流水号"
            valueType="text"
            initialValue={financeDocumentNumber}
            fieldProps={{
              disabled: true,
            }}
          />
        </VSFormLayout>
        <VSFormItem name={['drugExportDetailBtoList']} valueType="custom">
          <VSTable
            className="aspirin-table-no-background-box aspirin-table aspirin-table-search-padding-no"
            bordered
            editable={{
              editType: 'multiple',
              onCanDelete: () => null,
              columnProps: {
                width: '4.66rem',
              },
              extraOperations: [
                {
                  children: <Icon type="icon-shanchu" className="icon24" />,
                  onClick(_, data) {
                    const name = data?.drugProduct?.drugProductName;
                    if (String(data?.id)?.startsWith('create')) {
                      vsf?.refs?.ExportRecordTable?.remove(data);
                    } else {
                      Tip({
                        content: `确定删除药品:{ ${name?.label ?? name} }?`,
                        onOk() {
                          vsf?.refs?.ExportRecordTable?.remove(data);
                        },
                      });
                    }
                  },
                },
              ],
              switchMode: {
                onRowFinish: async () => true,
                onColumnFinish: async () => true,
              },
            }}
            // scroll={{ y: isMinScreen ? 340 : 470 }}
            scroll={{ y: getScrollYRem(32.8) }}
            vsid="56010"
            onRecord={() => ({ id: 'create' + Math.random() })}
            id="ExportRecordTable"
            pagination={false}
            rowClassName="aspirin-vs-table-row-box"
          >
            <VSTableColumn
              dataIndex={['itemNumber']}
              title="序号"
              editable={false}
              valueType="index"
              fieldProps={{}}
              width="4rem"
            />

            <VSTableColumn
              dataIndex={['drugProduct', 'drugProductName']}
              title="药品名称"
              valueType="drugSelect"
              formItemProps={{
                rules: [
                  () => ({
                    validator(_, value) {
                      return value
                        ? Promise.resolve()
                        : Promise.reject('药品名称是必填的');
                    },
                  }),
                ],
                placeholder: '输入药品名称/首字母',
              }}
              fieldProps={(record) => ({
                record,
                subStorageId:
                  vsf?.refs?.ExportRecord?.getFieldValue('subDrugStorageId')
                    ?.id,
                id: String(record?.id),
              })}
            />

            <VSTableColumn
              dataIndex={['drugSpecification']}
              title="规格"
              valueType="text"
              preview
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['packageSpecification']}
              title="包装规格"
              valueType="text"
              preview
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['packageUnit']}
              title="单位"
              valueType="custom"
              width="6.66rem"
              preview
              renderFormItem={(...[, { record }]) =>
                vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                  ({ value }) => value === record?.packageUnit,
                )?.label ??
                record?.packageUnit ??
                ''
              }
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['firm', 'firmName']}
              title="厂家"
              valueType="text"
              preview
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['drugStockLocation']}
              title="库位码"
              valueType="text"
              fieldProps={{
                disabled: true,
              }}
              preview
            />

            <VSTableColumn
              dataIndex={['batchNumber']} //stockId
              title="批号"
              valueType="drugStockBatchNumber"
              formItemProps={{
                rules: [
                  () => ({
                    validator(_, value) {
                      return value
                        ? Promise.resolve()
                        : Promise.reject('批号是必填的');
                    },
                  }),
                ],
              }}
              fieldProps={(record) => {
                // console.log(record);
                return {
                  id: String?.(record?.id),
                  record,
                };
              }}
            />

            <VSTableColumn
              dataIndex={['amount']}
              title="数量"
              valueType="drugInputNumber"
              fieldProps={(record) => {
                return {
                  id: String?.(record?.id),
                };
              }}
              formItemProps={{
                rules: [
                  () => ({
                    validator(_, value) {
                      return askIsInteger(value);
                    },
                  }),
                ],
              }}
            />
            <VSTableColumn
              dataIndex={['purchasePrice']}
              title="出库价"
              valueType="custom"
              preview
              renderFormItem={(...[, record]) =>
                askFixed(record?.record?.purchasePrice ?? 0, 2) + '元'
              }
            />
            <VSTableColumn
              dataIndex={['expirationDate']}
              title="有效期"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD' }}
              preview
              render={(...[, recode]) => {
                const [date] =
                  recode?.record?.expirationDate?.split(/\s/) ?? [];
                return date ?? '';
              }}
            />
            <VSTableColumn
              dataIndex={['purchaseAmount']}
              title="出库金额"
              valueType="custom"
              preview
              renderFormItem={(...[, record]) =>
                askFixed(record?.record?.purchaseAmount ?? 0, 2) + '元'
              }
            />
            <VSTableToolbar title="药品列表">
              <VSTableAddition children="添加" />
            </VSTableToolbar>
          </VSTable>
        </VSFormItem>
      </VSForm>
    </Block>
  );
};
export default definePage(ExportRecord);
