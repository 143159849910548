import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
  PriceListController_updatePricePatternMaster_321135: {
    method: 'post',
    url: '/api/price-list/update-price-pattern-master',
    parameterFomatter: (data?: {
      btoParam: UpdatePricePatternMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListController_createPricePatternMaster_f783fc: {
    method: 'post',
    url: '/api/price-list/create-price-pattern-master',
    parameterFomatter: (data?: {
      btoParam: CreatePricePatternMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
});
