import './index.less';

import CSS from 'csstype';
import React, { useEffect } from 'react';

import { getPrefixCls } from '@/config';

type BlockPropsType = {
  children?: React.ReactNode;
  style?: CSS.Properties;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  className?: string;
  title?: string;
  extra?: React.ReactNode;
  scroll?: boolean;
};

const Index = (props: BlockPropsType) => {
  const {
    children,
    style,
    width = '100%',
    height = '100%',
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    className,
    title,
    extra,
    scroll,
  } = props;
  const blockCls = getPrefixCls('index-block');

  return (
    <div
      className={`${blockCls} ${className}`}
      style={{
        ...style,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        width,
        height,
      }}
    >
      {title && (
        <div className="header">
          <div className="left-block">{title}</div>
          <div className="right-block">{extra}</div>
        </div>
      )}
      <div
        className="content"
        style={{
          overflow: scroll ? 'auto' : 'unset',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Index;
