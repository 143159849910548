import vsf from '@vs/vsf-boot';
// 住院中药退药：获取可退药中药处方列表
export const getDispenseForInpHerbRefundApplyByMasterIds = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getDispenseForInpHerbRefundApplyByMasterIds_40e4cd?.(
      { id: params?.value?.drugPrescriptionDispenseMasterId },
    );
  return {
    data: convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableInpHerbDispenseDetailForRefundApply?.(
      res,
    ),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableInpHerbDispenseDetailForRefundApply
 * @param {any} input
 * @returns {any}
 */
const convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableInpHerbDispenseDetailForRefundApply =
  (input) => {
    const _ = window._ ?? {};
    const output: any = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = Math?.random() + _.get(d, 'id');
        obj.cid = _.get(d, 'id');
        obj.prescriptionDataTime = _.get(
          d,
          'prescription.prescriptionDataTime',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.departmentName = _.get(
          d,
          'prescription.orderHerb.order.orderDepartmentInfo.departmentName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.staffName = _.get(
          d,
          'prescription.orderHerb.order.doctorInfo.staffName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionAttribute = _.get(
          d,
          'prescription.prescriptionAttribute',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.dispenseTime = _.get(d, 'dispenseTime'); // TODO 请确认下行字段是否符合逻辑
        obj.herbRegimen = _.get(d, 'herbRegimen'); // TODO 请确认下行字段是否符合逻辑
        obj.agreedPrescriptionName = _.get(
          d,
          'prescription.orderHerb.agreedPrescriptionName',
        );
        obj.dispenseDetails = [];
        obj.dispenseDetails.push({
          id: _.get(d, 'id'),
          orderText: _.get(d, 'prescription.orderHerb.order.orderText'),
          canRefundAmount: _.get(d, 'canRefundAmount'),
          refundAmount: _.get(d, 'canRefundAmount'),
          allowRefundHerbRegimen: _.get(d, 'allowRefundHerbRegimen'),
        });
        return obj;
      }) ?? []),
    );
    return output;
  };
// **住院中药退药：执行退药申请
export const saveInpHerbRefundApply = async (event) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_saveInpHerbRefundApply_3c3316?.(
      { ...event },
    );
  return res;
};

export const queryScrollWardPatientList = async (value) => {
  const res =
    await vsf?.services?.PatientInHosptialController_queryScrollWardPatientList_346269?.(
      {
        qto: {
          ...value,
        },
      },
    );
  return (res?.data?.result ?? res?.data ?? [])?.map((item) => ({
    ...item,
    id: item?.patient?.id,
    name: item?.bed?.bedLabel + '-' + item?.patient?.name,
  }));
};
// 获取患者列表
export const getHerbDispenseCountForInpRefundDrugApply = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getHerbDispenseCountForInpRefundDrugApply_7d4f29?.(
      {
        ...params,
      },
    );
  return {
    data: onSpliced?.(res),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
const onSpliced = (input) => {
  const _ = window._ ?? {};
  return (
    input?.data?.map((m) => ({
      ...m,
      id: m?.bedLabel + m?.name,
    })) ?? []
  );
};
// 获取患者详情；
export const getPatientDetall = async (params) => {
  const res =
    await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
      { qto: { ...params, from: 0, size: 20 } },
    );
  if (res?.data?.result.length >= 1) return res?.data?.result[0]?.id;
  // message.error('未查到患者信息');
  return false;
};
