import NewDrawer from '@/pages/Index/components/new_drawer';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn, Button, message } from '@vs/vsf-kit';
import React, { useCallback, useContext, useState } from 'react';
import { CreateScreen } from '@/pages/Index/components/screen';
import { DrugExportContext } from '.';
const DrugAccounting = (props) => {
  const [open, setOpen] = useState(true);
  const [select, setSelect] = useState([]);

  const onScreen = useContext(CreateScreen);

  const onFetch = useCallback(async () => {
    if (open) {
      const res =
        await vsf.services?.DrugStorageImportExportController_getAllByDrugExportMasterForAccountantQto_3b2e58(
          {
            qto: {},
          },
        );
      return res.data;
    }
  }, [open]);

  const onReload = () => {
    vsf?.refs?.DrugExportAccounting?.reload();
  };

  const onInvalid = useCallback(async () => {
    Tip({
      content: `确定作废流水号为:{ ${select?.financeDocumentNumber} }待记账单据?`,
      onOk: async () => {
        const res =
          await vsf.services?.DrugStorageImportExportController_invalidDrugExportMaster_edb33f?.(
            {
              id: select?.id,
            },
          );
        if (res.code === 200) {
          message.success('作废成功');
          // onScreen(() => void 0);
          onReload?.();
          setSelect([]);
        }
      },
    });
  }, [select]);

  const footer = () => {
    const onHide = () => {
      setOpen(false);
    };
    return (
      <div className="aspirin-table-footer-button">
        <Button type="primary" onClick={onReload}>
          刷新
        </Button>
        <Button type="primary" onClick={onHide}>
          隐藏
        </Button>
        <Button
          type="primary"
          onClick={onInvalid}
          disabled={!select?.id}
          danger
        >
          作废单据
        </Button>
      </div>
    );
  };

  const available = useContext(DrugExportContext);
  return (
    <NewDrawer
      open={open}
      width="40%"
      onOpen={() => {
        setOpen(!open);
      }}
      title="待记账单据"
      buttonTitle="待记账单据"
      backgroundColor="var(--blue)"
      footer={footer()}
      isOther
      buttonStyle={{
        top: '20%',
      }}
      style={{
        top: 104,
      }}
      drift="98px"
    >
      <VSPromiseTable
        vsid="66607"
        onFetch={onFetch}
        id="DrugExportAccounting"
        onRow={(node) => {
          return {
            onClick() {
              setSelect(node);
            },
            onDoubleClick() {
              setOpen(false);
              props?.onChange(node);
            },
          };
        }}
        pagination={false}
        rowClassName={(node) => {
          return `aspirin-vs-table-row-box ${
            node?.id === select?.id
              ? 'aspirin-row-selected-box'
              : 'aspirin-row-select-box'
          }`;
        }}
      >
        <VSTableColumn
          dataIndex={['exportImportCode']}
          title="业务类型"
          valueType="custom"
          render={(...[, record]) =>
            available?.find((item) => record?.exportImportCode === item?.code)
              ?.name ?? record?.exportImportCode
          }
          fieldNames={{
            label: 'name',
            value: 'code',
          }}
        />

        <VSTableColumn
          dataIndex={['receiveDepartment', 'departmentName']}
          title="接收单位"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['financeDocumentNumber']}
          title="流水号"
          valueType="text"
          className="aspirin-table-td-blue-box"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['receiveDepartment', 'id']}
          title="主键"
          valueType="digit"
          hideInTable
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['id']}
          title="出库单号"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};
export default definePage(DrugAccounting);
