import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_getDrugProductByNameOrInputCode_1b2090: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-by-name-or-input-code',
    parameterFomatter: (data?: { input: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductBaseVoDrugDrugEntranceWebVo[],
    ) => data,
  },
});
