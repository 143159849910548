import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpRegisterAppointQueryRecordController_updateClinicRegisterAppoint_8f1a1e:
    {
      method: 'post',
      url: '/api/clinic-outp-register-appoint/update-clinic-register-appoint',
      parameterFomatter: (data?: {
        btoParam: UpdateClinicRegisterAppointPatientBtoClinicOutpRegisterAppointServiceBto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRegisterAppointRecordDetailVoClinicOutpRegisterAppointEntranceWebVo,
      ) => data,
    },
  ClinicOutpRegisterAppointQueryRecordController_cancelClinicRegisterAppoint_e8ffee:
    {
      method: 'post',
      url: '/api/clinic-outp-register-appoint/cancel-clinic-register-appoint',
      parameterFomatter: (data?: {
        btoParam: CancelClinicRegisterAppointBtoClinicOutpRegisterAppointServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRegisterAppointRecordDetailVoClinicOutpRegisterAppointEntranceWebVo,
      ) => data,
    },
});
