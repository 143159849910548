import NewDrawer from '@/pages/Index/components/new_drawer';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn, Button, message } from '@vs/vsf-kit';
import React, { useCallback, useContext, useState } from 'react';

const DrugPriceAdjustList = (props) => {
  const [open, setOpen] = useState(true);

  const onFetch = useCallback(async () => {
    if (open) {
      const res =
        await vsf.services?.DrugPriceAdjustController_getAllByDrugPriceAdjustMasterQto_3a7109(
          {
            qto: {},
          },
        );

      return res.data;
    }
  }, [open]);

  const onReload = () => {
    vsf?.refs?.drugPriceAdjustList?.reload();
  };
  const footer = () => {
    const onHide = () => {
      setOpen(false);
    };
    return (
      <div className="aspirin-table-footer-button">
        <Button type="primary" onClick={onReload}>
          刷新
        </Button>
        <Button type="primary" onClick={onHide}>
          隐藏
        </Button>
      </div>
    );
  };
  const onHangeDoubleClick = (node) => {
    props?.setMaster(node);
    setOpen(!open);
  };
  return (
    <NewDrawer
      open={open}
      width="40%"
      onOpen={() => setOpen(!open)}
      title={<div style={{ color: '#3276E8' }}>已提交调价单</div>}
      buttonTitle="已提交调价单"
      backgroundColor="var(--blue)"
      footer={footer()}
      buttonStyle={{
        top: '20%',
      }}
      style={{
        top: 104,
      }}
      drift="98px"
    >
      <VSPromiseTable
        vsid="67280"
        id="drugPriceAdjustList"
        onFetch={onFetch}
        pagination={false}
        rowClassName={(node) => {
          return node?.id === props?.Master?.id
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
            : 'aspirin-vs-table-row-box aspirin-row-select-box';
        }}
        onRow={(node) => {
          return {
            onDoubleClick: () => onHangeDoubleClick(node),
          };
        }}
      >
        <VSTableColumn
          valueType="index"
          render={(v) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width={50}
          preview
        />
        <VSTableColumn
          dataIndex={['id']}
          title="调价单号"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['createStaffName']}
          title="制作人"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['submitTime']}
          title="保存时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};
export default definePage(DrugPriceAdjustList);
