import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getSimpleMedicalRecordById_466f96: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-simple-medical-record-by-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordSimpleVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
});
