import { createServices } from '@vs/vsf-boot';
export default createServices({
  GreenChannelDictionaryController_queryByGreenChannelDictionaryBaseQto_d1c0b1:
    {
      method: 'post',
      url: '/api/finance-billing-record/query-by-green-channel-dictionary-base-qto',
      parameterFomatter: (data?: {
        qto: GreenChannelDictionaryBaseQtoFinanceBillingRecordPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: GreenChannelDictionaryBaseVoFinanceBillingRecordEntranceWebVo[],
      ) => data,
    },
  GreenChannelDictionaryController_createGreenChannelDictionary_4cdb1b: {
    method: 'post',
    url: '/api/finance-billing-record/create-green-channel-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateGreenChannelDictionaryBtoFinanceBillingRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: GreenChannelDictionaryBaseVoFinanceBillingRecordEntranceWebVo,
    ) => data,
  },
  GreenChannelDictionaryController_enableOrDisableGreenChannelDictionary_1d13a2:
    {
      method: 'post',
      url: '/api/finance-billing-record/enable-or-disable-green-channel-dictionary',
      parameterFomatter: (data?: {
        bto: EnableOrDisableGreenChannelDictionaryBtoFinanceBillingRecordServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
});
