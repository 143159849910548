import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const SendModal = (props, ref): any => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const renderContent = useCallback(() => {
    return (
      <div className="content">
        <div className="first">
          <VSTable value={value} pagination={false}>
            {/* <VSTableColumn
              valueType="text"
              dataIndex={['bed']}
              title="床号"
              preview
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['name22']}
              title="医嘱内容"
              valueType="text"
              fieldProps={{}}
              preview
            /> */}

            <VSTableColumn
              dataIndex={['reason']}
              title="失败详情"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
      </div>
    );
  }, [value]);

  const onClose = useCallback(() => {
    setOpen(false);
    props?.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button onClick={onClose}>关闭</Button>
        </div>
      ),
    };
  }, [onClose]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  return (
    <Modal
      width={1168}
      open={open}
      destroyOnClose={true}
      title="医嘱执行失败"
      onCancel={onClose}
      {...renderFooter()}
      className="aspirin-execute-order-fail-modal"
    >
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(SendModal);
