import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, Tabs, message } from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import StandardApplicationTree from './tree';
import ModalEdit from './edit';
import StandardApplicationParameter from './parameter/table';
import StandardApplicationFunction from './function/table';
import StandardApplicationPrivilege from './privilege';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import '../index.less';
import Tip from '@/pages/Index/components/func/Tip';
import { useFetch } from '@/pages/Index/components/func/hooks';
const StandardApplication = (props) => {
  /**
   * application
   * @type {object}
   */
  const [application, setApplication] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [addNode, setAddNode] = useState(false);
  const ref = useRef(null);
  const handleAdd = (node) => {
    setAddNode(node);
    setAddOpen(true);
  };
  const onRemove = useFetch({
    fetch: async (node) => {
      const res =
        await vsf?.services?.ApplicationBOController_deleteApplication_cc9a1c({
          btoParam: {
            id: node?.id,
          },
          extApiParam: {},
        });
      return res;
    },
    message: '删除',
    success: () => vsf?.refs?.StandardApplicationTree?.handleReload(),
  });
  const handleDel = (node) => {
    Tip({
      icon: <Icon type="icon-jingshi" />,
      content: `是否删除：{${node.name}} 及其对应的功能和参数数据?`,
      onOk: () => onRemove?.(node),
    });
  };

  const handleEdit = (node) => {
    setAddNode(node);
    setAddOpen(true);
  };

  const handleCloseWithReload = () => {
    vsf?.refs?.StandardApplicationTree?.handleReload();
    handleClose();
  };

  const handleClose = () => {
    setAddOpen(false);
  };

  const items = [
    {
      key: 'function',
      label: `功能设置`,
      children: <StandardApplicationFunction application={application} />,
    },
    {
      key: 'parameter',
      label: `参数设置`,
      children: <StandardApplicationParameter application={application} />,
    },
    {
      key: 'permission',
      label: `权限设置`,
      children: <StandardApplicationPrivilege application={application} />,
    },
  ];

  return (
    <>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '10px 24px 0 24px',
            },
          ],
        }}
      >
        <StandardApplicationTree
          onChange={(_value) => {
            setApplication({ ..._value });
          }}
          onAdd={handleAdd}
          onDel={handleDel}
          onEdit={handleEdit}
        />
        <Tabs
          className="aspirin-tab aspirin-tabs-style-box"
          defaultActiveKey="1"
          items={items}
        />
      </Container>
      <ModalEdit
        open={addOpen}
        value={addNode}
        handleClose={handleClose}
        handleCloseWithReload={handleCloseWithReload}
      />
    </>
  );
};
export default definePage(StandardApplication);
