/* eslint-disable react-hooks/exhaustive-deps */
import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import { Select } from 'antd';
import dayjs from 'dayjs';
import { number } from 'echarts';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import AspirinSelect from '@/components/AspirinSelect';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import FormTitle from '@/pages/Index/components/form_title';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY, labelColWidth, labelNumberWidth } from '@/utils';

import {
  convertGetErpPreExamMainVoRes2PreExamMainForm,
  convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq,
  convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq1,
} from '../convert';
import ActionChief from '../modal/action_chief';
import CRAMSModal from '../modal/crams_modal';
import FallRiskModal from '../modal/fall_risk_modal';
import GCSModal from '../modal/gcs_modal';
import NRSModal from '../modal/nrs_modal';
import PEWSModal from '../modal/pews_modal';
import PreviousModal from '../modal/previous_history_modal';
import SecondTirage from '../modal/secondary_triage';
import { calculateMews, cheifClassify } from './calculate';
import PatientAssessment from './patient_assessment';

const Index = (props) => {
  const {
    currentRowId = {},
    clearScreen,
    init,
    searchId,
    type,
    handelSaveSuccess,
    handelReloadList,
  } = props;
  // 无生命迹象/拒绝TPRBP/小儿患者
  const [isRequired, setIsRequired] = useState(true);
  // 无生命迹象/拒绝TPRBP
  const [isRequired1, setIsRequired1] = useState(true);

  const [diseaseLevelColor, setDiseaseLevelColor] = useState<any>();
  const [referenceDiseaseLevelIdColor, setReferenceDiseaseLevelIdColor] =
    useState<any>();

  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const isChildren = useRef<boolean>(false);
  // 分级list
  const [levelList, setLevelList] = useState<any>([]);
  // 事实际病情原因是否必填
  const [isRequiredDiseaseLevel, setIsRequiredDiseaseLevel] = useState(true);
  // 病人去向原因是否必填
  const [isRequiredTreatAreaCode, setIsRequiredTreatAreaCode] = useState(true);
  const nrsRef = useRef<any>();
  const cramsRef = useRef<any>();
  const actionCiefRef = useRef<any>();
  const pewsRef = useRef<any>();
  const gcsRef = useRef<any>();
  const fallRiskRef = useRef<any>();
  const previousRef = useRef<any>();
  const secondTriageRef = useRef<any>();
  const [detail, setDetail] = useState<any>();
  const requiredRef = useRef<any>();

  const _labelWidth = isMinScreen ? 100 : 128;

  useEffect(() => {
    getLevel1();
  }, []);

  const handelData = (arr = [], size = 3) => {
    const _arr: any = [];
    const residue = arr?.length % 3;
    const length = residue == 0 ? arr?.length / 3 : arr?.length / 3 + 1;
    for (let i = 0; i < 3; i++) {
      const fieldArr: any = [];
      for (let j = 0; j < length; j++) {
        if (j * 3 + i <= arr.length - 1) {
          fieldArr.push(arr[j * 3 + i]);
        }
      }
      _arr.push(fieldArr);
    }

    return _arr;
  };

  useEffect(() => {
    getAllByChief();
    // cheifClassify()
  }, []);
  // 判定依据
  const getAllByChief = async () => {
    const res =
      await vsf.services.ComplaintJudgeDictionaryController_getAllByChiefComplaintJudgeDictionaryNewQto_989929(
        { qto: {} },
      );

    if (res?.code == 200) {
      cheifClassify(res?.data ?? []);
      return res?.data ?? [];
    }
    return [];
  };
  // 根据患者类别设置PEWS（小儿）默认值
  const handelSetPews = () => {
    const _res =
      vsf.refs?.preExamMainForm?.getFieldsValue?.erpPreExamRecordDetailWithVitalsignDictionaryList?.filter(
        (i) => {
          return i?.item == '54' || i?.item == '55' || i?.item == '56';
        },
      );
    let _modalList: any = [];
    if (_res?.length == 3) {
      const _res =
        vsf.refs?.preExamMainForm?.getFieldsValue?.erpPreExamRecordDetailWithVitalsignDictionaryList?.map(
          (i) => {
            return i?.item == '54'
              ? { item: '54', itemValue: '541' }
              : i?.item == '55'
              ? { item: '55', itemValue: '551' }
              : i?.item == '56'
              ? { item: '56', itemValue: '561' }
              : i;
          },
        );
      _modalList = _res;
    } else {
      _modalList = [
        ...(vsf.refs?.preExamMainForm?.getFieldsValue
          ?.erpPreExamRecordDetailWithVitalsignDictionaryList ?? []),
        { item: '54', itemValue: '541' },
        { item: '55', itemValue: '551' },
        { item: '56', itemValue: '561' },
      ];
    }
    vsf.refs?.preExamMainForm?.setFieldsValue({
      preExamPatientType: 'BABY',
      erpPreExamRecordDetailWithVitalsignDictionaryList: _modalList,
    });
  };
  // 根据体征状态置gcs默认值
  const handelSetGcs = () => {
    const _res =
      vsf.refs?.preExamMainForm?.getFieldsValue?.erpPreExamRecordDetailWithVitalsignDictionaryList?.filter(
        (i) => {
          return i?.item == '19' || i?.item == '20' || i?.item == '21';
        },
      );
    let _modalList: any = [];
    if (_res?.length == 3) {
      _modalList =
        vsf.refs?.preExamMainForm?.getFieldsValue?.erpPreExamRecordDetailWithVitalsignDictionaryList?.map(
          (i) => {
            return i?.item == '19'
              ? { item: '19', itemValue: '194' }
              : i?.item == '20'
              ? { item: '20', itemValue: '205' }
              : i?.item == '21'
              ? { item: '21', itemValue: '216' }
              : i;
          },
        );
    } else {
      _modalList = [
        ...(vsf.refs?.preExamMainForm?.getFieldsValue
          ?.erpPreExamRecordDetailWithVitalsignDictionaryList ?? []),
        { item: '19', itemValue: '194' },
        { item: '20', itemValue: '205' },
        { item: '21', itemValue: '216' },
      ];
    }
    vsf.refs?.preExamMainForm?.setFieldsValue({
      GCS: 15,
      erpPreExamRecordDetailWithVitalsignDictionaryList: _modalList,
    });
  };
  // form 详情
  const getDetail = useCallback(
    // isNewErp为true 二次分诊弹窗新增分诊-保存NEW_ERP；直接查询患者-保存NEW_ERP；
    // 二次分诊弹窗二次分诊, 取消分诊 CANCEL_ERP
    // 二次分诊弹窗二次分诊,保存 REPEAT_ERP
    async (isNewErp?, erpVisitId?) => {
      if (!currentRowId?.id && !erpVisitId) return;
      setLoading(true);
      setShowDetail(false);
      if (isNewErp) {
        setDetail(null);
        vsf.refs?.preExamMainForm?.setFieldsValue(null);
      }
      const res =
        erpVisitId || currentRowId?.type == 'doubleClick'
          ? await vsf?.services?.ClinicErpVisitController_getErpPreExamMainVo_da8a4e?.(
              {
                // 二次分诊弹窗二次分诊查询/双击列表查询
                erpVisitId: erpVisitId ?? currentRowId?.spacicalId,
              },
            )
          : await vsf?.services?.ClinicErpVisitController_getNewErpPreExamMainVoByDisplayId_adbc63?.(
              !isNewErp
                ? {
                    // 直接查询
                    displayId: currentRowId?.id,
                  }
                : {
                    // 二次分诊弹窗新增分诊查询
                    displayId: currentRowId?.id,
                    isNewErp: true,
                  },
            );

      let output = convertGetErpPreExamMainVoRes2PreExamMainForm?.(res);
      // erpPreExamRecordDetailWithVitalsignDictionaryList
      if (!erpVisitId && currentRowId?.type != 'doubleClick') {
        output = {
          ...output,
          erpPreExamRecordDetailWithVitalsignDictionaryList: [
            {
              item: '08',
              itemValue: [],
            },
          ],
        };
      }
      setReferenceDiseaseLevelIdColor(output.treatAreaCode);
      // const saveErpPreExamFunctionEnum = isNewErp?'NEW_ERP';

      setLoading(false);

      if (res?.code == 200) {
        if (res?.data) {
          setShowDetail(true);

          // 主诉list
          const _patientChiefComplaintVsJudgeDictionaryDtoList = (
            output?.patientChiefComplaintVsJudgeDictionaryDtoList ?? []
          )?.map((i) => {
            return {
              ...i,
              secondId: i?.chiefComplaint?.id,
              secondName: i?.chiefComplaint?.chiefComplaintName,
              id: i?.chiefComplaintJudge?.id,
              chiefComplaintJudgeName:
                i?.chiefComplaintJudge?.chiefComplaintJudgeName,
              chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
            };
          });
          // 患者评估list
          const list =
            output?.erpPreExamRecordDetailWithVitalsignDictionaryList;

          // 弹窗
          const modalList = vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.sort(
            (a, b) => {
              return a?.order - b?.order;
            },
          )
            ?.filter((i) => {
              const iCode =
                i?.extensionJson &&
                JSON.parse(i?.extensionJson)?.preExamScoreTypeCode;
              return (
                iCode == '01' ||
                iCode == '11' ||
                iCode == '12' ||
                iCode == '02' ||
                iCode == '03'
              );
            })
            ?.map((obj) => {
              const index = list?.find((item) => item.item === obj.code);

              if (index) {
                return {
                  ...obj,
                  ...index,
                };
              } else {
                return {
                  ...obj,
                };
              }
            })
            .filter((i) => i?.item);

          // 判断是否是小孩
          const isChildrens =
            output?.birthday && dayjs().diff(output?.birthday, 'year') <= 6;

          isChildren.current = isChildrens;

          const childrenModalList = isChildrens
            ? [
                { item: '54', itemValue: '541' },
                { item: '55', itemValue: '551' },
                { item: '56', itemValue: '561' },
              ]
            : [];

          const defaultValue = {
            arriveHospitalDateTime: output?.arriveHospitalDateTime
              ? output?.arriveHospitalDateTime
              : +new Date(),
            arriveHospitalType: output?.arriveHospitalType
              ? output?.arriveHospitalType
              : vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0],
            ohcaIndicator: output?.ohcaIndicator
              ? output?.ohcaIndicator
              : false,
            notPlanReturnRescueIndicator: output?.notPlanReturnRescueIndicator
              ? output?.notPlanReturnRescueIndicator
              : false,
            vitalsignStatus: output?.vitalsignStatus
              ? output?.vitalsignStatus
              : 'HAVE_VITALSIGN',
            preExamPatientType: output?.preExamPatientType
              ? output?.preExamPatientType
              : isChildrens
              ? 'BABY'
              : 'GENERAL',
            erpPreExamRecordDetailWithVitalsignDictionaryList: [
              { item: '19', itemValue: '191' },
              { item: '20', itemValue: '201' },
              { item: '21', itemValue: '211' },
              ...childrenModalList,
            ],
            tableList: [
              {
                item: '23',
                itemValue: vsf.stores.dbenums.enums.AVPU_REACT_DICT?.[0]?.code,
              },
            ],
          };

          const _obj = {
            ...output,
            nrsScore: output?.nrsScore ? output?.nrsScore : 0,
            pewsScore: output?.pewsScore ? output?.pewsScore : 0,
            // mewsScore: _filter,
            gcsScore: output?.gcsScore
              ? output?.gcsScore
              : output?.vitalsignStatus == 'WITHOUT_VITALSIGN'
              ? 3
              : 15,
            diseaseLevel: {
              ...output?.diseaseLevel,
              treatArea: levelList?.find(
                (i) => i?.id == output?.referenceDiseaseLevelId?.value,
              )?.treatArea,
            },

            cramsScore: output?.cramsScore ? Number(output?.cramsScore) : '',
            patientChiefComplaintVsJudgeDictionaryDtoList:
              _patientChiefComplaintVsJudgeDictionaryDtoList,
            patientChiefComplaintVsJudgeDictionary:
              output?.patientChiefComplaintVsJudgeDictionaryDtoList
                ?.map(
                  (item) =>
                    (item.chiefComplaint?.chiefComplaintName ?? '*') +
                    ',' +
                    item?.chiefComplaintJudge?.chiefComplaintJudgeName,
                )
                .join(';'),
            erpPreExamRecordDetailWithVitalsignDictionaryList: modalList,
            tableList: list,
          };
          const _formValue = output?.id ? _obj : { ..._obj, ...defaultValue };
          if (
            _formValue?.preExamPatientType == 'BABY' ||
            _formValue?.vitalsignStatus == 'WITHOUT_VITALSIGN' ||
            _formValue?.vitalsignStatus == 'NOT_TPRBP'
          ) {
            setIsRequired(false);
          } else {
            setIsRequired(true);
          }
          if (
            _formValue?.vitalsignStatus == 'NOT_TPRBP' ||
            _formValue?.vitalsignStatus == 'WITHOUT_VITALSIGN'
          ) {
            setIsRequired1(false);
          } else {
            setIsRequired1(true);
          }
          // 判断实际分级原因是否必填
          handelIsRequiredDiseaseLevel(_formValue);
          // 病人去向原因是否必填
          handeldIseaseLevelChangeReason(_formValue);

          setDetail(_formValue);
          // 设置参考分级颜色
          setReferenceDiseaseLevelIdColor(
            levelList?.find(
              (i) => i?.id == _formValue?.referenceDiseaseLevelId?.value,
            )?.colorCode,
          );
          // 设置实际分级颜色
          setDiseaseLevelColor(
            levelList?.find((i) => i?.id == _formValue?.diseaseLevel?.value)
              ?.colorCode,
          );

          vsf.refs?.preExamMainForm?.setFieldsValue(_formValue);
        } else {
          secondTriageRef?.current?.handelOpen(true, currentRowId?.id);
        }
      } else {
        setDetail(null);
        vsf.refs?.preExamMainForm?.setFieldsValue(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRowId?.spacicalId, currentRowId?.id, currentRowId?.init, init],
  );
  // 参考/实际分级（初始获取）
  const getLevel1 = async () => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    setLevelList(res?.data);
  };

  const handelColor = (v) => {
    setDiseaseLevelColor(v);
    setReferenceDiseaseLevelIdColor(v);
  };

  useEffect(() => {
    requiredRef.current = isRequired;
  }, [isRequired]);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetail, init]);

  const getLevel = async (params?) => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );

    const _res = res?.data?.map((i) => {
      return {
        ...i,
        label: i?.diseaseLevelName,
        value: i?.diseaseLevelCode,
      };
    });
    const output = params?.label
      ? _res?.filter((item) =>
          askSearch(params?.label, item, 'diseaseLevelName'),
        )
      : _res;
    return output;
  };
  // 刷新
  const handelClear = () => {
    vsf.refs.patientSearch.resetFields();
    clearScreen();
  };

  // 判断实际病情分级是否必填
  const handelIsRequiredDiseaseLevel = (formValue?) => {
    const _fromValue = formValue
      ? formValue
      : vsf.refs.preExamMainForm.getFieldsValue();
    const _diseaseLevel = _fromValue?.diseaseLevel;
    const _referenceDiseaseLevelId = _fromValue?.referenceDiseaseLevelId;
    if (
      (_referenceDiseaseLevelId?.value ?? _referenceDiseaseLevelId) ==
      (_diseaseLevel?.value ?? _diseaseLevel?.value)
    ) {
      setIsRequiredDiseaseLevel(false);
    } else {
      setIsRequiredDiseaseLevel(true);
    }
  };
  // 判断病人去向是否必填
  const handeldIseaseLevelChangeReason = (formValue?) => {
    const _fromValue = formValue
      ? formValue
      : vsf.refs.preExamMainForm.getFieldsValue();
    const _diseaseLevel = _fromValue?.diseaseLevel;
    const _treatAreaCode = _fromValue?.treatAreaCode;

    if (
      (_treatAreaCode?.value ?? _treatAreaCode) ==
      (_diseaseLevel?.treatArea ?? _diseaseLevel)
    ) {
      setIsRequiredTreatAreaCode(false);
    } else {
      setIsRequiredTreatAreaCode(true);
    }
  };
  // 主诉变化
  const handelActionChief = (params) => {
    handeldIseaseLevelChangeReason(params);
    handeldIseaseLevelChangeReason(params);
  };

  // ohca变化，影响实际分级/参考分级/病人去向
  //体征状态为无生命迹象，影响实际分级/参考分级/病人去向
  const ohcaChange = async () => {
    const _res = await getLevel();
    const findI = _res?.find((i) => i?.diseaseLevel == '1');

    const _obj = {
      ...findI?.diseaseLevel,
      label: findI?.diseaseLevelName,
      value: findI?.diseaseLevelCode,
    };
    vsf.refs.preExamMainForm.setFieldsValue({
      diseaseLevel: _obj,
      referenceDiseaseLevelId: _obj,
      referenceDiseaseLevelIds: findI?.diseaseLevelName,
      treatAreaCode: findI?.treatArea,
    });
    // 设置参考分级颜色
    // 设置实际分级颜色
    // setDiseaseLevelColor(findI?.colorCode);
    // setReferenceDiseaseLevelIdColor(findI?.colorCode);
    handelColor(findI?.colorCode);
  };

  const readonly = true;

  return (
    <div className="detail_content">
      <div className="detail_content_body hideScroll">
        {!loading ? (
          currentRowId?.id && showDetail ? (
            <VSForm
              labelAlign="left"
              id="preExamMainForm"
              labelWrap
              onChange={(v) => {}}
            >
              <VSFormItem
                name={['erpPreExamRecordDetailWithVitalsignDictionaryList']}
                label="erpPreExamRecordDetailWithVitalsignDictionaryList"
                valueType="text"
                hidden
              />

              <VSFormItem
                name={['patientId']}
                label="患者id"
                valueType="text"
                hidden
              />
              <VSFormItem name={['id']} label="id" valueType="text" hidden />
              <VSFormItem
                name={['patientChiefComplaintVsJudgeDictionaryDtoList']}
                label="主诉及判定依据"
                valueType="select"
                fieldProps={{}}
                hidden
              />
              <FormTitle
                className="icon16"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '10px 0' }}
                title="基本信息"
                isNeedTag={true}
              >
                <VSFormLayout
                  key="1"
                  columnCount={3}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <VSFormItem
                    name={['name']}
                    label="姓名"
                    valueType="text"
                    readonly={readonly}
                    rules={[
                      {
                        message: '姓名长度不合法',
                        type: 'string',
                        min: 0,
                        max: 60,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['gender']}
                    label="性别"
                    valueType="select"
                    dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['age']}
                    label="年龄"
                    valueType="text"
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['defaultChargeType']}
                    label="费别"
                    valueType="select"
                    dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['cellphone']}
                    label="电话"
                    valueType="text"
                    // rules={[
                    //   {
                    //     message: '数据格式错误',
                    //     type: 'string',
                    //     pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                    //   },
                    // ]}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['currentAddress']}
                    label="住址"
                    valueType="address"
                    fieldProps={{ showDetail: true }}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['idNumber']}
                    label="身份证号"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        pattern:
                          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                      },
                    ]}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['workUnit', 'address']}
                    valueType="address"
                    label="单位地址"
                    fieldProps={{
                      showDetail: true,
                    }}
                    readonly={readonly}
                  />
                </VSFormLayout>
                <VSFormLayout
                  key="2"
                  columnCount={2}
                  columnSpan={[16, 8]}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <VSFormItem
                      name={['arriveHospitalType']}
                      label="到院方式"
                      fieldNames={{ label: 'label', value: 'value' }}
                      labelCol={
                        isMinScreen ? labelColWidth[15] : labelColWidth[7.5]
                      }
                      valueType="codeTableSelect"
                      style={{
                        width: isMinScreen ? 366 : 314,
                      }}
                      required
                      initialValue={
                        vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0]
                      }
                      fieldProps={{
                        showSearch: true,
                      }}
                      dataSource={vsf.stores.dbenums.enums.ARRI_PATTERN_DICT}
                      rules={[{ required: true }]}
                    />
                    <VSFormItem
                      name={['arriveHospitalPatternMemo']}
                      label=""
                      style={{
                        width: 430,
                      }}
                      valueType="text"
                    />
                  </Space.Compact>
                  {/* <VSFormItem
                    name={['arriveHospital']}
                    label="到院方式"
                    valueType="selectAndText"
                    // dataSource={vsf.stores.dbenums.enums.ARRI_PATTERN_DICT}
                    // requestParamsFieldNames={{
                    //   selectName: 'arriveHospitalType',
                    //   inputName: 'arriveHospitalPatternMemo',
                    // }}
                    rules={[{ required: true }]}
                    fieldProps={{
                      selectProps: {
                        required: true,
                        allowClear: true,
                        dataSource: vsf.stores.dbenums.enums.ARRI_PATTERN_DICT,
                        defaultValue:
                          vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0],
                        className: 'widthAutoFill',
                      },
                      inputProps: {},
                      requestParamsFieldNames: {
                        selectName: 'arriveHospitalType',
                        inputName: 'arriveHospitalPatternMemo',
                      },
                    }}
                  /> */}

                  <VSFormItem
                    name={['ambulanceTypeCode']}
                    label="120类型"
                    valueType="codeTableSelect"
                    dataSource={vsf.stores.dbenums.enums.DRIVE_TYPE_DICT}
                    fieldProps={{
                      allowClear: true,
                      showSearch: true,
                      className: 'widthAutoFill',
                    }}
                  />
                </VSFormLayout>
                <VSFormLayout
                  key="3"
                  columnCount={3}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <VSFormItem
                    name={['arriveHospitalDateTime']}
                    label="到院时间"
                    valueType="date"
                    fieldProps={{
                      showTime: true,
                      width: '100%',
                      className: 'widthAutoFill',
                      disabledDate: (current) => {
                        return current && current > dayjs().endOf('day');
                      },
                    }}
                    initialValue={+new Date()}
                    rules={[{ required: true }]}
                    // labelWidth={_labelWidth}
                  />
                  {/* 院外心博停止 */}
                  <VSFormItem
                    name={['ohcaIndicator']}
                    label="OHCA"
                    valueType="radioButton"
                    initialValue={false}
                    fieldProps={{
                      onChange: (v) => {
                        if (v) {
                          ohcaChange();
                        }
                      },
                    }}
                    rules={[{ required: true }]}
                    dataSource={[
                      { label: '是', value: true },
                      { label: '否', value: false },
                    ]}
                  />

                  <VSFormItem
                    name={['notPlanReturnRescueIndicator']}
                    label="非计划重返抢救室"
                    valueType="select"
                    dataSource={[
                      { label: '是', value: true },
                      { label: '否', value: false },
                    ]}
                    initialValue={false}
                    fieldProps={{
                      className: 'widthAutoFill',
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['vitalsignStatus']}
                    label="体征状态"
                    valueType="radioButton"
                    dataSource={[
                      { label: '有生命迹象', value: 'HAVE_VITALSIGN' },
                      { label: '无生命迹象', value: 'WITHOUT_VITALSIGN' },
                      { label: '拒绝TPRBP', value: 'NOT_TPRBP' },
                    ]}
                    initialValue="HAVE_VITALSIGN"
                    fieldProps={{
                      onChange: (v) => {
                        const res = vsf.refs?.preExamMainForm?.getFieldsValue();

                        if (v == 'WITHOUT_VITALSIGN') {
                          // 设置GCS评分
                          handelSetGcs();
                          //体征状态为无生命迹象，影响实际分级/参考分级/病人去向
                          ohcaChange();
                          // 设置血压19（10、11、12、13）、呼吸06、血氧饱和14、脉搏04
                          const _arr = ['15', '14', '08'];
                          const _arr1 = ['10', '11', '12', '13'];
                          const _res = detail?.tableList
                            ?.filter((i) => !_arr?.includes(i?.item))
                            ?.map((i) => {
                              return _arr1?.includes(i?.item)
                                ? { ...i, itemValue: 0 }
                                : i;
                            });

                          setDetail({
                            ...detail,
                            tableList: [
                              ..._res,
                              {
                                itemClass: '04',
                                item: '15',
                                itemValue: '0',
                              },
                              {
                                itemClass: '06',
                                item: '08',
                                itemValue: '0',
                              },
                              {
                                itemClass: '14',
                                item: '14',
                                itemValue: '0',
                              },
                              // 血压需要确定是上肢或者下肢
                              // {
                              //   itemClass: '19',
                              //   item: '9',
                              //   itemValue: '0',
                              // },
                            ],
                          });
                        }
                        if (v == 'WITHOUT_VITALSIGN' || v == 'NOT_TPRBP') {
                          vsf.refs?.preExamMainForm?.setFieldsValue({
                            treatArea: {
                              treatAreaCode: '01',
                            },
                            // gcsScore: 3,
                          });
                          setIsRequired(false);
                          setIsRequired1(false);
                        } else {
                          setIsRequired1(true);
                          if (res?.preExamPatientType == 'BABY') {
                            setIsRequired(false);
                          } else {
                            setIsRequired(true);
                          }
                        }
                      },
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['preExamPatientType']}
                    label="患者类别"
                    valueType="radioButton"
                    dataSource={[
                      { label: '普通患者', value: 'GENERAL' },
                      { label: '小儿患者', value: 'BABY' },
                      { label: '产科患者', value: 'CHILDBIRTH' },
                    ]}
                    initialValue="GENERAL"
                    fieldProps={{
                      onChange: (v) => {
                        const res = vsf.refs?.preExamMainForm?.getFieldsValue();

                        if (v == 'BABY') {
                          // setIsChildren(true);
                          handelSetPews();
                          setIsRequired(false);
                          isChildren.current = true;
                        } else {
                          if (
                            res?.vitalsignStatus == 'WITHOUT_VITALSIGN' ||
                            res?.vitalsignStatus == 'NOT_TPRBP'
                          ) {
                            setIsRequired(false);
                          } else {
                            setIsRequired(true);
                          }
                          isChildren.current = false;
                        }
                      },
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['patientType']}
                    label="患者类型"
                    valueType="codeTableSelect"
                    dataSource={
                      vsf.stores.dbenums.enums.EMERGENCY_VISIT_PATIENT_TYPE
                    }
                    fieldProps={{
                      className: 'widthAutoFill',
                      showSearch: true,
                      allowClear: true,
                      onChange: (v) => {
                        vsf.refs?.preExamMainForm?.setFieldsValue({
                          patientChiefComplaintVsJudgeDictionaryDtoList: [],
                          patientChiefComplaintVsJudgeDictionary: null,
                          referenceDiseaseLevelId: null,
                          referenceDiseaseLevelIds: null,
                          //diseaseLevel/treatAreaCode  ?
                          // diseaseLevel: null,
                          // treatAreaCode: null,
                        });
                      },
                    }}
                  />

                  <VSFormItem
                    name={['payAfterClinicReason']}
                    label="医后付理由"
                    valueType="custom"
                    dataSource={
                      vsf.stores.dbenums?.enums?.ERP_PAY_AFTER_VISIT_REASON
                    }
                    // style={{ width: 320 }}
                    // fieldProps={{
                    //   width: labelWidth,
                    //   showSearch: true,
                    //   allowClear: true,
                    //   selectProps: {
                    //     allowClear: true,
                    //   },
                    //   addItemOptions: {
                    //     status: true,
                    //     mode: 'single',
                    //   },
                    // }}
                    fieldNames={{
                      label: 'label',
                      value: 'value',
                    }}
                    customComponent={(v: any) => {
                      return (
                        <AspirinSelect
                          dataSource={vsf.stores.dbenums?.enums?.ERP_PAY_AFTER_VISIT_REASON?.map(
                            (i) => {
                              return { ...i, value: i?.label };
                            },
                          )}
                          value={v?.value}
                          onChange={(value) => {
                            v?.onChange(value);
                          }}
                          autoCompleteProps={{
                            showSearch: true,
                            placeholder: '请选择或输入医后付理由',
                          }}
                          addItemOptions={{
                            status: true,
                            mode: 'single',
                          }}
                          selectProps={{
                            fieldNames: {
                              label: 'label',
                              value: 'value',
                            },
                            showSearch: true,
                            allowClear: true,
                          }}
                        />
                      );
                    }}
                  />

                  <VSFormItem
                    name={['epidemiologyHistory']}
                    label="流行病学史"
                    valueType="codeTableSelect"
                    dataSource={
                      vsf.stores.dbenums.enums.EPIDEMIOLOGY_HISTORY_DICT
                    }
                    fieldProps={{
                      className: 'widthAutoFill',
                      allowClear: true,
                      showSearch: true,
                    }}
                  />

                  <VSFormItem
                    name={['epidemiologyHistoryMemo']}
                    label="流行病学史备注"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 100,
                      },
                    ]}
                    fieldProps={{ className: 'widthAutoFill' }}
                  />
                </VSFormLayout>
              </FormTitle>
              <FormTitle
                className="icon16"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '0 0 10px', overflowX: 'hidden' }}
                title="患者评估"
                isNeedTag={true}
                extra={
                  <div>
                    <Button size="small" type="primary">
                      体征采集
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginLeft: 20 }}
                      onClick={() => {
                        previousRef.current?.handelOpen(
                          true,
                          vsf.refs?.preExamMainForm.getFieldsValue(),
                        );
                      }}
                    >
                      既往史
                    </Button>
                  </div>
                }
              >
                <PatientAssessment
                  fallRiskRef={fallRiskRef}
                  list={detail?.tableList}
                  detail={detail}
                  isRequired={isRequired}
                  isRequired1={isRequired1}
                  handelColor={handelColor}
                />

                <div className="patient_score">
                  <VSFormLayout
                    columnCount={4}
                    gutter={isMinScreen ? 40 : 100}
                    labelCol={labelColWidth[4]}
                  >
                    {/* 格拉斯哥评分得分 */}
                    <VSFormItem
                      label="GCS"
                      name={['gcsScore']}
                      valueType="select"
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          gcsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                      dataSource={[
                        { label: '3分(重度)', value: 3 },
                        { label: '4分(重度)', value: 4 },
                        { label: '5分(重度)', value: 5 },
                        { label: '6分(重度)', value: 6 },
                        { label: '7分(重度)', value: 7 },
                        { label: '8分(重度)', value: 8 },
                        { label: '9分(中度)', value: 9 },
                        { label: '10分(中度)', value: 10 },
                        { label: '11分(中度)', value: 11 },
                        { label: '12分(中度)', value: 12 },
                        { label: '13分(轻度)', value: 13 },
                        { label: '14分(轻度)', value: 14 },
                        { label: '15分(轻度)', value: 15 },
                      ]}
                      rules={[
                        {
                          required: isRequired,
                          message: '',
                        },
                      ]}
                    />
                    {/* 创伤评分得分 */}
                    <VSFormItem
                      label="CRAMS"
                      name={['cramsScore']}
                      valueType="select"
                      // rules={[
                      //   {
                      //     message: '数据格式错误',
                      //     type: 'string',
                      //     min: 0,
                      //     max: 2,
                      //   },
                      // ]}
                      dataSource={[
                        { label: '0分(极重伤)', value: 0 },
                        { label: '1分(极重伤)', value: 1 },
                        { label: '2分(极重伤)', value: 2 },
                        { label: '3分(极重伤)', value: 3 },
                        { label: '4分(极重伤)', value: 4 },
                        { label: '5分(极重伤)', value: 5 },
                        { label: '6分(极重伤)', value: 6 },
                        { label: '7分(重伤)', value: 7 },
                        { label: '8分(重伤)', value: 8 },
                        { label: '9分(轻伤)', value: 9 },
                        { label: '10分(轻伤)', value: 10 },
                      ]}
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          cramsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                    />
                    {/* 患者疼痛评分得分 */}
                    <VSFormItem
                      label="NRS"
                      name={['nrsScore']}
                      valueType="select"
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          nrsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                      initialValue={0}
                      dataSource={[
                        { label: '0分（无痛）', value: 0 },
                        { label: '1分（轻度疼痛）', value: 1 },
                        { label: '2分（轻度疼痛）', value: 2 },
                        { label: '3分（轻度疼痛）', value: 3 },
                        { label: '4分（中度疼痛）', value: 4 },
                        { label: '5分（中度疼痛）', value: 5 },
                        { label: '6分（中度疼痛）', value: 6 },
                        { label: '7分（重度疼痛）', value: 7 },
                        { label: '8分（重度疼痛）', value: 8 },
                        { label: '9分（重度疼痛）', value: 9 },
                        { label: '10分（重度疼痛）', value: 10 },
                      ]}
                      rules={[{ required: isRequired, message: '' }]}
                    />
                    {/* 患者类别选择小儿患者时不显示MEWS 少字段*/}

                    {/* 患者类别选择小儿患者时显示 少字段 */}

                    {isChildren.current ? (
                      <VSFormItem
                        name={['pewsScore']}
                        label={`PEWS`}
                        valueType="text"
                        fieldProps={{
                          onClick: (e) => {
                            pewsRef.current?.handelOpen(
                              true,
                              vsf.refs?.preExamMainForm.getFieldsValue(),
                            );
                          },
                          addonAfter: '分',
                          onChange: (e) => {
                            e.preventDefault();
                          },
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                        }}
                        rules={[{ required: isChildren.current }]}
                      />
                    ) : (
                      <VSFormItem
                        name={['mewsScore']}
                        label={`MEWS`}
                        valueType="text"
                        fieldProps={{
                          disabled: true,
                          addonAfter: '分',
                        }}
                      />
                    )}
                  </VSFormLayout>
                </div>
              </FormTitle>
              {type != 'modal' && (
                <FormTitle
                  className="icon16"
                  style={{ padding: 0, background: 'transparent' }}
                  bodyStytle={{ padding: '10px 0' }}
                  title="患者分级安置"
                  isNeedTag={true}
                >
                  <VSFormLayout
                    key="3"
                    columnCount={1}
                    // labelCol={labelColWidth[6]}
                    // labelWidth={isMinScreen ? 126 : 148}
                    gutter={100}
                    labelWidth={labelNumberWidth(9)}
                    // gutter={50}
                  >
                    <VSFormItem
                      name={['patientChiefComplaintVsJudgeDictionary']}
                      label="主诉及判定依据"
                      valueType="text"
                      required={true}
                      // rules={[
                      //   {
                      //     message: '参考病情分级的值不合法',
                      //     type: 'number',
                      //     min: 0,
                      //     max: undefined,
                      //   },
                      // ]}

                      fieldProps={{
                        allowClear: false,
                        onClick: () => {
                          actionCiefRef.current?.getList(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                        onChange: (e) => {
                          e.preventDefault();
                        },
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                      }}
                    />
                    <VSFormItem
                      name={['referenceDiseaseLevelId']}
                      className="disabled_select_color"
                      label="参考病情分级"
                      valueType="select"
                      hidden
                      fieldNames={{ label: 'label', value: 'value' }}
                      style={{ color: referenceDiseaseLevelIdColor }}
                      fieldProps={{
                        style: {
                          width: 200,
                          color: referenceDiseaseLevelIdColor,
                        },

                        showSearch: true,
                        disabled: true,
                      }}
                      dataSource={async (param) => {
                        const res = getLevel(param);
                        return res;
                      }}
                    />
                    <VSFormItem
                      name={['referenceDiseaseLevelIds']}
                      className="disabled_select_color"
                      label="参考病情分级"
                      valueType="text"
                      fieldNames={{ label: 'label', value: 'value' }}
                      style={{ color: referenceDiseaseLevelIdColor }}
                      fieldProps={{
                        style: {
                          width: 200,
                          color: referenceDiseaseLevelIdColor,
                        },

                        disabled: true,
                      }}
                      readonly
                      dataSource={async (param) => {
                        const res = getLevel(param);
                        return res;
                      }}
                      required={true}
                      rules={[{ required: true, message: '' }]}
                    />

                    <VSFormLayout columnCount={1} labelWidth={120}>
                      <Space.Compact style={{ width: '100%' }}>
                        <VSFormItem
                          name={['diseaseLevel']}
                          label="实际病情分级"
                          fieldNames={{ label: 'label', value: 'value' }}
                          labelCol={
                            isMinScreen ? labelColWidth[19] : labelColWidth[8]
                          }
                          valueType="select"
                          style={{
                            width: 350,
                          }}
                          fieldProps={{
                            showSearch: true,
                            style: {
                              color: diseaseLevelColor,
                            },
                            onChange: (v) => {
                              vsf.refs.preExamMainForm.setFieldsValue({
                                treatAreaCode: v?.treatArea,
                              });
                              handelIsRequiredDiseaseLevel();
                              handeldIseaseLevelChangeReason();
                              setTimeout(() => {
                                // 设置实际分级颜色
                                setDiseaseLevelColor(v?.colorCode);
                              }, 100);
                            },
                          }}
                          dataSource={async (param) => {
                            const res = getLevel(param);
                            return res;
                          }}
                          rules={[{ required: true }]}
                        />
                        <VSFormItem
                          name={['diseaseLevelChangeReason']}
                          label=""
                          valueType="custom"
                          dataSource={
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT
                          }
                          style={{ width: 470 }}
                          // required={isRequiredDiseaseLevel}
                          rules={[
                            {
                              required: isRequiredDiseaseLevel,
                              message: '请选择或输入病情分级变更原因',
                            },
                          ]}
                          customComponent={(v: any) => {
                            return (
                              <AspirinSelect
                                dataSource={vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                                  (i) => {
                                    return { ...i, value: i?.label };
                                  },
                                )}
                                value={v?.value}
                                onChange={(value) => {
                                  v?.onChange(value);
                                }}
                                autoCompleteProps={{
                                  showSearch: true,
                                  placeholder: '请选择或输入病情分级变更原因',
                                }}
                                addItemOptions={{
                                  status: true,
                                  mode: 'single',
                                }}
                                selectProps={{
                                  fieldNames: {
                                    label: 'label',
                                    value: 'value',
                                  },
                                  showSearch: true,
                                  allowClear: true,
                                }}
                              />
                            );
                          }}
                        />
                      </Space.Compact>
                    </VSFormLayout>
                    {/* <VSFormLayout columnCount={1} labelWidth={120}> */}
                    <VSFormLayout
                      columnCount={1}
                      labelWidth={labelNumberWidth(9)}
                    >
                      <Space.Compact style={{ width: '100%' }}>
                        <VSFormItem
                          name={['treatAreaCode']}
                          label="病人去向"
                          fieldNames={{ label: 'label', value: 'value' }}
                          labelCol={
                            isMinScreen ? labelColWidth[19] : labelColWidth[8]
                          }
                          valueType="select"
                          style={{
                            width: 350,
                          }}
                          fieldProps={{
                            showSearch: true,
                            onChange: (v) => {
                              handeldIseaseLevelChangeReason();
                            },
                          }}
                          dataSource={vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.filter(
                            (i) => i?.code != '07',
                          )}
                          rules={[{ required: true }]}
                        />
                        <VSFormItem
                          name={['directionChangeReason']}
                          label=""
                          valueType="custom"
                          dataSource={
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT
                          }
                          style={{ width: 470 }}
                          // required={isRequiredDiseaseLevel}
                          rules={[
                            {
                              required: isRequiredTreatAreaCode,
                              message: '请选择或输入病人去向变更原因',
                            },
                          ]}
                          customComponent={(v: any) => {
                            return (
                              <AspirinSelect
                                dataSource={vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                                  (i) => {
                                    return { ...i, value: i?.label };
                                  },
                                )}
                                value={v?.value}
                                onChange={(value) => {
                                  v?.onChange(value);
                                }}
                                autoCompleteProps={{
                                  showSearch: true,
                                  placeholder: '请选择或输入病情分级变更原因',
                                }}
                                addItemOptions={{
                                  status: true,
                                  mode: 'single',
                                }}
                                selectProps={{
                                  fieldNames: {
                                    label: 'label',
                                    value: 'value',
                                  },
                                  showSearch: true,
                                  allowClear: true,
                                }}
                              />
                            );
                          }}
                        />
                      </Space.Compact>
                    </VSFormLayout>
                    {/* <VSFormItem
                      name={['treatArea']}
                      label="病人去向"
                      valueType="selectAndText"
                      style={{
                        width: 800,
                      }}
                      fieldProps={{
                        isAspirinSelect: true,
                        requestParamsFieldNames: {
                          selectName: 'treatAreaCode',
                          inputName: 'directionChangeReason',
                        },
                        selectProps: {
                          width: 200,
                          dataSource:
                            vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.filter(
                              (i) => i?.code != '07',
                            ),
                        },
                        inputProps: {
                          dataSource:
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                              (i) => {
                                return {
                                  ...i,
                                  label: i?.label,
                                  value: i?.label,
                                };
                              },
                            ),
                          placeholder: '请选择或输入病人去向变更原因',
                        },
                      }}
                      rules={[{ required: true }]}
                    /> */}

                    <VSFormItem
                      name={['clinicRegisterDetailList']}
                      label=""
                      hidden
                      valueType="select"
                    />
                    <VSFormItem
                      name={['clinicDepartment']}
                      label="分科"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                        disabled: !detail?.id ? false : true,
                        mode: !detail?.id ? 'multiple' : undefined,
                        onChange: (v) => {
                          const _clinicRegisterDetailList = !detail?.id
                            ? v?.map((i) => i?.id)
                            : [v?.id];
                          vsf?.refs?.preExamMainForm.setFieldsValue({
                            clinicRegisterDetailList: _clinicRegisterDetailList,
                          });
                        },
                      }}
                      fieldNames={{
                        label: 'label',
                        value: 'value',
                      }}
                      dataSource={async (param) => {
                        const res =
                          await vsf?.services?.OutpRegisterController_getTodayErpClinicRegister_c4b2c6?.(
                            {},
                          );

                        const _res = (res?.data ?? [])?.map((i) => {
                          return {
                            ...i,
                            label:
                              i?.clinicRegister?.clinicRegisterDefine
                                .clinicLabel,
                            value: i?.clinicRegister?.id,
                          };
                        });
                        const _searchRes = _res?.filter((i) =>
                          askSearch(param?.label, i, 'label'),
                        );

                        return param?.label?.length > 0 ? _searchRes : _res;
                      }}
                      rules={[{ required: true }]}
                    />
                  </VSFormLayout>
                </FormTitle>
              )}
            </VSForm>
          ) : (
            <Empty />
          )
        ) : (
          <Loading size="normal" />
        )}
        {/* 疼痛评估弹窗 */}
        <NRSModal ref={nrsRef} />
        {/* 格拉斯 */}
        <GCSModal ref={gcsRef} />
        {/* 创伤评分 */}
        <CRAMSModal ref={cramsRef} />
        {/* 儿童早期预警 */}
        <PEWSModal ref={pewsRef} />
        {/* 跌倒风险评估 */}
        <FallRiskModal ref={fallRiskRef} />
        {/* 主诉及判定依据 */}
        <ActionChief
          ref={actionCiefRef}
          onChange={handelActionChief}
          handelColor={handelColor}
        />
        {/* 既往史 */}
        <PreviousModal ref={previousRef} />
        {/* 二次分诊 secondTriageRef */}
        <SecondTirage ref={secondTriageRef} getDetail={getDetail} />
      </div>
      {type != 'modal' && (
        <div className="detail_content_bottom">
          <Button type="primary" className="detail_content_bottom_button">
            打印腕带
          </Button>
          <Button type="primary" className="detail_content_bottom_button">
            打印
          </Button>
          <Button
            type="primary"
            ghost
            className="detail_content_bottom_button"
            onClick={handelClear}
          >
            刷新
          </Button>
          <Button
            type="primary"
            ghost
            className="detail_content_bottom_button"
            onClick={async () => {
              const output =
                convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq1?.({
                  id: vsf.refs?.preExamMainForm?.getFieldsValue()?.id,
                  patientId:
                    vsf.refs?.preExamMainForm?.getFieldsValue()?.patientId,
                });
              const res =
                await vsf?.services?.ClinicErpPreExamController_saveErpPreExamFuncFlowExecutor_a6c909?.(
                  {
                    ...output,
                  },
                );
              if (res?.code == 200) {
                message.success('取消成功');

                setDetail(null);
                // handelClear();
                clearScreen();
                setTimeout(() => {
                  handelReloadList?.();
                }, 500);
              }
            }}
          >
            取消分诊
          </Button>
          <Button
            type="primary"
            className="detail_content_bottom_button"
            onClick={async () => {
              // table校验
              const validate1 = vsf.refs?.patientAssessmentFirst
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              const validate2 = vsf.refs?.patientAssessmentSecond
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              const validate3 = vsf.refs?.patientAssessmentThird
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              // form校验
              const validate4 = vsf.refs.preExamMainForm.validateFields();
              // 获取form的值
              const res = vsf.refs?.preExamMainForm.getFieldsValue();

              // 评分弹窗的值
              const dictionaryModal =
                res?.erpPreExamRecordDetailWithVitalsignDictionaryList
                  ?.filter((element) => !Array.isArray(element))
                  .map((i) => {
                    return { item: i?.item, itemValue: i?.itemValue };
                  });
              // 体征table的值
              const dictionaryList1 =
                vsf.refs?.patientAssessmentFirst.getValues();
              const dictionaryList2 =
                vsf.refs?.patientAssessmentSecond.getValues();
              const dictionaryList3 =
                vsf.refs?.patientAssessmentThird.getValues();

              const dictionaryList: any = [
                ...dictionaryList1,
                ...dictionaryList2,
                ...dictionaryList3,
              ]
                ?.filter((i) => {
                  return (
                    (i?.itemValue?.length > 0 &&
                      i?.itemValue?.[0]?.length > 0) ||
                    (i?.itemValue?.length > 0 && i?.item == '16')
                  );
                })
                ?.map((i) => {
                  return i?.item == '62'
                    ? [
                        { item: 10, itemValue: i?.itemValue?.[0] },
                        { item: 11, itemValue: i?.itemValue?.[1] },
                      ]
                    : i?.item == '63'
                    ? [
                        { item: 12, itemValue: i?.itemValue?.[0] },
                        { item: 13, itemValue: i?.itemValue?.[1] },
                      ]
                    : i?.item == '26'
                    ? { item: '26', itemValue: null }
                    : { item: i?.item, itemValue: i?.itemValue?.[0] };
                })
                .flat();

              const selectTreatArea =
                vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.find((i) => {
                  return (
                    (res?.treatAreaCode?.value ?? res?.treatAreaCode) == i?.code
                  );
                });

              Promise.all([validate1, validate2, validate3, validate4]).then(
                (r) => {
                  const _params = {
                    ...res,
                    ...res?.treatArea,
                    // ...res?.diseaseLevel,
                    ...res?.arriveHospital,

                    treatAreaSortCode:
                      selectTreatArea &&
                      selectTreatArea?.extensionJson &&
                      JSON.parse(selectTreatArea?.extensionJson)
                        ?.treatAreaSortCode,
                    patientChiefComplaintVsJudgeDictionaryDtoList:
                      res?.patientChiefComplaintVsJudgeDictionaryDtoList?.map(
                        (i) => {
                          return {
                            chiefComplaintId: i?.secondId,
                            chiefComplaintJudgeId: i?.id,
                            chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
                          };
                        },
                      ) ?? [],

                    erpPreExamRecordDetailWithVitalsignDictionaryList: [
                      ...dictionaryList,
                      ...dictionaryModal,
                    ]?.filter((i) => i?.item && i?.itemValue),
                  };

                  const output =
                    convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq?.({
                      ..._params,
                    });

                  const qto = {
                    saveErpVisitForPreExamBto:
                      output?.saveErpVisitForPreExamBto,
                    createErpPreExamRecordBto:
                      output?.createErpPreExamRecordBto,
                    createPatientChiefComplaintVsJudgeBtoList:
                      output?.createPatientChiefComplaintVsJudgeBtoList,
                    saveVitalsignMeasureRecordBto:
                      output?.saveVitalsignMeasureRecordBto,
                    createVitalsignRecordBtoList:
                      output?.createVitalsignRecordBtoList,
                    saveErpPreExamFuncFlowApiParam:
                      output?.saveErpPreExamFuncFlowApiParam,
                    erpPreExamRecordDetailWithVitalsignDictionaryList: [
                      ...dictionaryList,
                      ...dictionaryModal,
                    ]?.filter((i) => i?.item && i?.itemValue),
                  };
                  if (
                    qto.createErpPreExamRecordBto.diseaseLevelId
                      .diseaseLevelName.label
                  ) {
                    const diseaseLevelId =
                      qto?.createErpPreExamRecordBto?.diseaseLevelId
                        ?.diseaseLevelName?.treatArea;
                    qto.createErpPreExamRecordBto.diseaseLevelId =
                      diseaseLevelId;
                  } else {
                    const Id =
                      vsf?.refs?.preExamMainForm?.getFieldValue()?.diseaseLevel
                        .treatArea;
                    qto.createErpPreExamRecordBto.diseaseLevelId = Id;
                  }

                  vsf?.services
                    ?.ClinicErpPreExamController_saveErpPreExamFuncFlowExecutor_a6c909?.(
                      { ...qto },
                    )
                    .then((response) => {
                      if (response?.code == 200) {
                        message.success('保存成功');
                        setTimeout(() => {
                          handelSaveSuccess();
                        }, 500);
                        setDetail(null);
                        clearScreen();
                      }
                    });
                },
              );
            }}
          >
            保存
          </Button>
        </div>
      )}
    </div>
  );
};
export default Index;
