/* eslint-disable prefer-const */
import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import amount from '../../dosage/amount';

export type StateValueType = {
  id: string | number;
  [key: string]: any;
  performDepartment: {
    id: number;
    departmentName: string;
  };
  orderText: {
    storageDepartmentId: number;
  };
};

interface GroupMedicalOrdersArrange {
  order: string[];
  state?: StateValueType;
  max: number;
  eliminates: any[];
  tip: (method: { onOk: () => void; onCancel: () => void }) => void;
  disables?: (one, two) => any[];
}

interface AccordEliminate {
  value: boolean;
  type: 'yes' | 'not';
}

/**
 * 处理医嘱成组
 */
function askGroupMedicalOrders(config: GroupMedicalOrdersArrange) {
  const { order, max, state, tip, eliminates, disables = () => [] } = config;
  /**
   * 判断是否符合医嘱类型
   * @param value 医嘱类型
   * @returns {boolean}
   */
  const accordOrder = (one: string, two: string): boolean => {
    const value = order.includes(one) && order.includes(two);
    if (!value) {
      message?.warning('只有药品医嘱可以成组');
    }
    return value;
  };

  /**
   * 判断科室是否一致
   * @param one
   * @param two
   * @returns {boolean}
   */
  const accordDepartment = (
    one?: number | null,
    two?: number | null,
  ): boolean => {
    if (one !== two) {
      message?.warning('药品药房不同不能成组');
    }
    return one === two;
  };

  const accordMax = (value) => max > value;
  /**
   * 判断特定元素是否一致
   * @returns {Array}
   */
  const accordEliminate = async (
    data,
    dataSource,
  ): Promise<AccordEliminate> => {
    return new Promise((resolve) => {
      const [[one], [two]] = accordRange(data, dataSource);
      const eliminate = (value): any[] => {
        const filter: any = [];
        for (const item of eliminates) {
          if (Array?.isArray(item)) {
            const state = item?.reduce(
              (accumulator, current) => accumulator?.[current],
              value,
            );
            filter.push(state);
          } else {
            filter.push(value[item]);
          }
        }
        return filter;
      };
      if (eliminate(one)?.toString() === eliminate(two)?.toString()) {
        resolve({
          value: true,
          type: 'yes',
        });
      } else {
        tip?.({
          onOk: () => {
            resolve({
              value: true,
              type: 'not',
            });
          },
          onCancel: () => {
            resolve({
              value: false,
              type: 'not',
            });
          },
        });
      }
    });
  };

  // - 精I、精II、麻、毒、普通药品互相不能成组（order_drug.toxic_code），
  // 但有一个例外，精毒麻药品可与特定的溶媒药成组（drug\drug_drug\drug_dictionary_extension.solvent_indicator==true）；
  // 不满足时提示 => 精I、精II、麻、毒、普通药品互相不能成组

  const accordNarcotics = () => {};

  /**
   * 获取上一条
   */
  type GroupValue = [GroupMedicalOrdersArrange['state'], number];
  const accordRange = (record, dataSource): [GroupValue, GroupValue] => {
    /**
     * 假如保存后就从 id  判断，否则就强制取最后一条
     */
    const two = dataSource?.findIndex((item) => item?.id == record?.id);
    const twoIndex = two !== -1 ? two : dataSource?.length;

    return [
      [dataSource[twoIndex - 1], twoIndex - 1],
      [record, twoIndex],
    ];
  };

  /**
   * 判断是否提交
   */
  const accordSubmit = (one, two) => {
    const value = ![one?.orderStatus, two?.orderStatus].includes('SUBMIT');
    if (!value) {
      message?.warning(
        `
          有医嘱状态为提交,无法成组
        `,
      );
    }
    return value;
  };

  const disabled = (data, dataSource) => {
    return new Promise((resolve) => {
      const [[one], [two]] = accordRange(data, dataSource);
      const oneId =
        one?.performDepartment?.id ?? one?.orderText?.storageDepartmentId;
      const twoId =
        two?.performDepartment?.id ?? two?.orderText?.storageDepartmentId;
      const accord = [
        accordOrder(one?.orderClass, two?.orderClass),
        accordDepartment(oneId, twoId),
        accordSubmit(one, two),
        ...(disables?.(one, two) ?? []),
      ];
      return resolve(!accord.includes(false));
    });
  };

  const label = (value) =>
    value?.orderClass === 'DRUG' ? 'drugOrder' : 'herbOrder';

  const eliminate = (value) => {
    const data = JSON.parse(JSON.stringify(value));
    const hand = {};
    for (const item of eliminates) {
      hand[item?.at(-1)] = item?.reduce(
        (previousValue: any, currentValue: any, index) => {
          const [state] = [previousValue?.[currentValue]];
          if (item?.length === index + 1) delete previousValue?.[currentValue];
          return state;
        },
        data,
      );
    }
    return [data, hand];
  };

  const intoGroups = async (
    _data,
    dataSource?: any,
    dataType: 'splice' | 'push' = 'push',
  ) => {
    const [[one, oneIndex], [two, twoIndex]] = accordRange(
      _data ?? '',
      dataSource,
    );
    const groupSubNumber = one?.[label(one)]?.groupSubNumber;
    const groupIntNumber = one?.groupIndex?.length ?? 0;
    if (groupIntNumber < max || groupSubNumber === undefined) {
      const [info] = eliminate(two) || [];
      const data = info?.[label(two)];
      const groupNumber = one?.[label(one)]?.groupNumber;

      const group = dataSource?.findLast(
        (item) => item[label?.(item)]?.groupNumber,
      );
      const num = groupNumber || (group?.[label(group)]?.groupNumber || 0) + 1;

      let groupSubNumber = groupNumber ? one?.[label(one)]?.groupSubNumber : 0;

      const item = (list, int) => {
        return list.map((option, index) => {
          groupSubNumber += 1;
          const order = label(option);
          return {
            ...option,
            [order]: {
              ...(option?.id !== one?.id ? data : option?.[order]),
              groupNumber: num,
              groupSubNumber,
              ...((list?.length === 1 || index !== 0) && int === index
                ? {
                    totalAmount: {
                      ...option?.[order]?.totalAmount,
                      value: amount({
                        performDays:
                          vsf?.stores?.user?.hospitalType === 'out'
                            ? one?.group?.performDays ??
                              one?.[order]?.performDays
                            : option?.repeatIndicator === true
                            ? one?.repeatValidDays
                            : one?.[order]?.frequency?.frequencyCount,
                        dosage: option?.[order]?.dosage?.value,
                        extension:
                          one?.group?.frequency ?? one?.[order]?.frequency,
                        dosagePerUnit: option?.dosagePerUnit,
                        amountPerPackage: option?.amountPerPackage,
                        roundingType: option?.roundingType,
                      }),
                    },
                  }
                : {}),
            },
          };
        });
      };
      /**
       * 判断第一条是否有医嘱号，没有生成
       */
      if (!groupNumber) {
        const res = await accordEliminate(_data ?? '', dataSource);
        if (res?.value) {
          return dataSource?.toSpliced(oneIndex, 2, ...item([one, two], 1));
        } else {
          return false;
        }
      } else {
        /**
         * 处理插入成组
         */
        if (dataType === 'splice') {
          const slice = dataSource?.slice(
            twoIndex,
            twoIndex + one?.groupIndex?.length - 1,
          );
          return dataSource?.toSpliced(
            twoIndex,
            slice?.length,
            ...item(slice, twoIndex + 1),
          );
        } else {
          const res = await accordEliminate(_data ?? '', dataSource);
          if (res?.value) {
            return dataSource?.toSpliced(twoIndex, 1, ...item([two], 0));
          } else {
            return false;
          }
        }
      }
    } else {
      message.warning(`最多 ${max} 条医嘱成组`);
      return false;
    }
  };

  const onGroup = async (data, dataSource?: any) => {
    const value = await disabled(data, dataSource);
    if (value) {
      return intoGroups(data, dataSource);
    }
  };

  function* approve(list: any[]) {
    for (let index = 0; index <= list?.length; index++) {
      yield [list[index - 1], [list[index], index], list[index + 1]];
    }
  }

  /**
   * 样式
   * @param dataSource;
   * @returns {list};
   */
  const onSliced = <T>(dataSource: T): T => {
    // console.log('dataSource', dataSource);
    const arr: never[] = [];
    let groupNumber: number | string = '';
    // eslint-disable-next-line @typescript-eslint/ban-types
    let group = {};
    /** 创建一个原型/通过原型的特性进行数据存取 */
    let groupList = Object.create({
      groupIndex: [],
    });
    if (Array?.isArray(dataSource)) {
      for (const [, [current, index], next] of approve(dataSource)) {
        if (current) {
          let self = { ...current };
          if (current?.orderClass === 'DRUG') {
            const name = label(current);
            const title = current?.[name]?.groupNumber;
            const item: any = {
              /**
               * 样式
               */
              groupName: '',
              /**
               * 用量、天数...
               */
              group: {},
              /**
               * 当前组的所有index下标
               */
              groupIndex: groupList?.groupIndex,
            };

            if (groupNumber) {
              if (groupNumber === title) {
                /**
                 * 判断后一个是否与当前组号一至
                 */
                item?.groupIndex.push(index);
                item.group = group;
                const [data] = eliminate(current);
                self = {
                  ...self,
                  ...data,
                  // [label(current)]: data,
                };
                if (
                  next?.[label(next)]?.groupNumber === groupNumber &&
                  next?.[label(next)]?.groupSubNumber !==
                    current?.[name]?.groupSubNumber
                ) {
                  item.groupName = 'aspirin-order-text-group-line';
                } else {
                  item.groupName = 'aspirin-order-text-group-last';
                  /**
                   * 清空记录
                   */
                  groupNumber = '';
                  /**
                   * 清空数据
                   */
                  group = {};
                  /**
                   * 如果是最后一个新建原型
                   */
                  groupList = Object?.create({
                    groupIndex: [],
                  });
                }
              }
            } else {
              /**
               * 判断当前组号是否单一
               */
              if (title && next?.[label(next)]?.groupNumber === title) {
                groupNumber = title;
                const [, data] = eliminate(current);
                group = data;
                item.groupName = 'aspirin-order-text-group-first';
                groupList?.groupIndex?.push(index);
              } else {
                groupList = Object?.create({
                  groupIndex: [],
                });
              }
            }
            self.__proto__ = Object?.create({
              ...Object?.prototype,
              ...item,
            });
            arr?.push(self as never);
          } else {
            arr?.push(current as never);
          }
        }
      }
    }
    return arr as T;
  };

  const onCancelGroup = (dataSource, state) => {
    const [[one], [two]] = accordRange(state, dataSource);
    const list = dataSource as any;
    const index = two ?? one;
    if (index?.groupIndex?.length) {
      const [one] = index?.groupIndex;
      /**
       * 查找最后一个组号补上
       */
      const last = list
        ?.map((item) => item[label?.(item)]?.groupNumber)
        ?.filter((item) => item);
      const group = Math?.max(...(new Set(last) as unknown as any[]));
      const data = list
        ?.filter((...[, i]) => index?.groupIndex?.includes(i))
        ?.map((item, index) => {
          const option = {
            ...item,
            [label?.(item)]: {
              ...item[label(item)],
              ...(Object?.values(item?.group)?.length ? item?.group : {}),
              groupNumber: (group ?? 0) + (index + 1),
              groupSubNumber: 1,
            },
          };
          return option;
        });
      return [list?.toSpliced(one, index?.groupIndex?.length, ...data), data];
    }
  };

  return {
    onGroup,
    onSliced,
    onCancelGroup,
    intoGroups,
  };
}

export default askGroupMedicalOrders;
