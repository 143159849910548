import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpVisitController_getErpVisitTransferOutDetailVoByErpVisitTransferOutDetailQto_a1a29e:
    {
      method: 'post',
      url: '/api/clinic-erp-visit/get-erp-visit-transfer-out-detail-vo-by-erp-visit-transfer-out-detail-qto',
      parameterFomatter: (data?: {
        qto: ErpVisitTransferOutDetailQtoClinicErpVisitPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpVisitTransferOutDetailVoClinicErpVisitEntranceWebVo,
      ) => data,
    },
  ClinicErpAdtController_transferErpPatientOut_792c49: {
    method: 'post',
    url: '/api/clinic-erp-adt/transfer-erp-patient-out',
    parameterFomatter: (data?: { erpVisitId: number }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
