import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitController_getPatientInHospitalInfo_4691ef: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-patient-in-hospital-info',
    parameterFomatter: (data?: { inpVisitId: number; patientId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitPatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
});
