import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Row,
  Col,
  Divider,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
  Placeholder,
  VSForm,
  Popover,
  VSPromiseTable,
  VSTableColumn,
  VSTable,
  message,
  Button,
  Form,
} from '@vs/vsf-kit';
import './index.less';
import { askFixed, askTotalAmount } from '@/pages/Index/components/func/ask';
import { add, subtract } from '@/utils/setDataUtil';
import { number } from 'echarts';
import { ButtonAndKeyDown, Flex } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';


function ParModal(props) {
  const { payParamData, appointmentStatus, patientInformation, callback } =
    props;

  //弹窗相关
  const chargeType = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;
  const identity = vsf.stores.dbenums.enums.IDENTITY_DICT;
  // const [payWays, setPayWays] = useState([]);
  const [form] = Form.useForm();
  const payWays = Form.useWatch(['paymentDetail', 'paymentType'], form)
  const [selectAdvanceData, setSelectSelectAdvanceData] = useState();

  const [advanceTotal, setAdvanceTotal] = useState();
  const [chargeData, setChargeData] = useState();
  const [identityData, setIdentityData] = useState();

  // const [submitStatus, setSubmitStatus] = useState(false);
  const [paymentWays, setPaymentWays] = useState([]);

  useEffect(() => {
    // chargeType.map((item) => {
    //   if (item?.value === payParamData?.outpSettleMasterVo?.chargeType) {
    //     setChargeData(item);
    //   }
    // });
    // identity.map((item) => {
    //   if (item?.value === payParamData?.outpSettleMasterVo?.identityType) {
    //     setIdentityData(item);
    //   }
    // });
  }, [patientInformation, identity]);

  useEffect(() => {
    if (payParamData) {
      vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
        'prePaymentAmount',
        payParamData?.prePaymentAmount,
      );
      // vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
      //   'totalCharge',
      //   payParamData?.inpSettleMasterVo?.totalCharge,
      // );
      let arr = [];
      // [payParamData]?.map((item) => {
      //   item?.prePaymentMasterVoList?.map((obj) => {
      //     obj?.prePaymentDetailBaseDtoList?.map((i) => {
      //       arr.push(i.paymentType);
      //     });
      //   });
      // });

      const data =
       (payParamData?.prePaymentMasterVoList ??
        []).map((item) => {
          return {
            id: item?.id,
            prePaymentAmount: item?.prePaymentAmount,
            prePaymentDate: item?.prePaymentDate,
            physicalReceiptNumber:
              item?.inpReceiptRecordBaseDtoList?.[0]?.physicalReceiptNumber,
            receiptNumber: item?.inpReceiptRecordBaseDtoList?.[0]?.receiptNumber,
            paymentType: item?.prePaymentDetailBaseDtoList?.[0]?.paymentType,
          };
        });
      setPaymentWays(
        Array.from(new Set(data?.map((item) => item?.paymentType))),
      );
    }
  }, [payParamData]);

  useEffect(() => {
    message.destroy();
    let tatal = 0;
    selectAdvanceData?.map((item) => {
      tatal = add(tatal, item?.prePaymentAmount, 2);
    });
    const needAccept = askFixed(payParamData?.inpSettleMasterVo?.totalCost, 2);
    setAdvanceTotal(tatal);
    // vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
    //   'totalCharge',
    //   subtract(needAccept, tatal, 2),
    // );
    if (subtract(needAccept, tatal, 2) > 0) {
      tatal && message.error('已选预交款退款不足！请继续选择需要退款的预交款');
    } else {
    }
  }, [selectAdvanceData]);

  const int = (text) => {
    const sum =  vsf?.refs?.hospitalizationParmentForm?.getFieldValue(
      ['paymentDetail', 'paymentType']
    );
    const set = vsf?.refs?.hospitalizationParmentForm?.setFieldValue;
    const get = vsf?.refs?.hospitalizationParmentForm?.getFieldValue
    switch ((sum ?? [])?.length) {
      case 1:
        switch (text) {
          case 'CASH':
            set(['remaining'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge - (get(['totalCharge']) ?? 0)));
            break;
          case 'INTEGRATION_PAYMENT':
            set(['remaining'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge - (get(['aggregate']) ?? 0)));
            break;
        }
      case 2:
        const label = sum.filter((item) => item !== text);
          if (label?.includes('CASH')) {
            set(
              ['totalCharge'],
              payParamData?.inpSettleMasterVo?.totalCharge - (get(['aggregate']) ?? 0),
            );
            set(['remaining'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge - ((get(['aggregate']) ?? 0)) - (get(['totalCharge']) ?? 0)));
          }
          if (label?.includes('INTEGRATION_PAYMENT')) {
            set(
              ['aggregate'],
              payParamData?.inpSettleMasterVo?.totalCharge - (get(['totalCharge']) ?? 0),
            )
            set(['remaining'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge - ((get(['aggregate']) ?? 0)) - (get(['totalCharge']) ?? 0)));
          }
        break
    }
  }

  const handelTotalCharge = (value) => {
    const aggregate =
      vsf?.refs?.hospitalizationParmentForm?.getFieldValue(
        'aggregate',
      );
    int('CASH');
  }

  const handelAggregate = (value) => {
    const totalCharge =
      vsf?.refs?.hospitalizationParmentForm?.getFieldValue(
        'totalCharge',
      );
      int('INTEGRATION_PAYMENT')
  }
  const [select] = useOnChangeValue([]);
  const [check] = useOnChangeValue(false);

  const [open, setOpen] = useState(false);

  const advance = () => {
    return (
      <Flex vertical gap={24} style={{ width: 700 }}>
        <VSTable
          editable={{ editType: 'none' }}
          vsid="15941"
          id="advanceMoneyHospitalizationTable"
          rowSelection={{
            selectType: 'multiple',
            onSelectedRowsChange: (data, keys) => {
              select?.onChange?.(data);
              // setSelectSelectAdvanceData(data);
              // callback(data);
            },
          }}
  
          value={() => {
            if (Array.isArray(payParamData?.prePaymentMasterVoList)) {
              const data = payParamData?.prePaymentMasterVoList.map((item) => {
                return {
                  id: item?.id,
                  prePaymentAmount: item?.prePaymentAmount,
                  prePaymentDate: item?.prePaymentDate,
                  physicalReceiptNumber:
                    item?.inpReceiptRecordBaseDtoList[0]
                      ?.physicalReceiptNumber ?? '',
                  receiptNumber:
                    item?.inpReceiptRecordBaseDtoList[0]?.receiptNumber ?? '',
                  paymentType:
                    item?.prePaymentDetailBaseDtoList[0]?.paymentType,
                  prePaymentId: item?.id,
                  serialNumber:
                    item?.prePaymentDetailBaseDtoList[0]?.serialNumber,
                };
              });
              return data;
            } 
          }}
          rowKey={'id'}
          pagination={false}
        >
          <VSTableColumn
            dataIndex={['prePaymentDate']}
            title="支付日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />
  
          <VSTableColumn
            dataIndex={['prePaymentAmount']}
            title="预交金数额"
            valueType="digit"
            fieldProps={{}}
          />
  
          <VSTableColumn
            dataIndex={['physicalReceiptNumber']}
            title="票据号"
            valueType="text"
            fieldProps={{}}
          />
  
          <VSTableColumn
            dataIndex={['receiptNumber']}
            title="支票号"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['paymentType']}
            title="付款方式"
            valueType="select"
            dataSource={[
              { label: '现金', value: 'CASH' },
              { label: '支付宝', value: 'ALIPAY' },
              { label: '微信', value: 'WECHAT' },
              { label: '数字人民币', value: 'DIGITAL_RMB' },
              { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
            ]}
          />
        </VSTable>
        <Flex justify="flex-end">
          <Button 
            type="primary" 
            onClick={() => {
              const total = askTotalAmount(
                select?.value?.map((item) => [item?.prePaymentAmount, 1])
              );
              if(total < Math.abs(payParamData?.inpSettleMasterVo?.totalCharge)) {
                message?.error('已选预交款退款不足！请继续选择需要退款的预交款')
              } else {
                const amount = {};
                const enums = {
                  CASH: 'totalCharge',
                  INTEGRATION_PAYMENT: 'aggregate',
                }
                for (const item of select?.value){
                  if (!amount[item?.paymentType]) {
                    amount[item?.paymentType] = 0;
                  }
                  amount[item?.paymentType] = item?.prePaymentAmount + (amount[item?.paymentType]);
                }
                check?.onChange(true)
                vsf?.refs?.hospitalizationParmentForm?.setFieldValue(['paymentDetail', 'paymentType'], Object?.keys(amount));
                Object?.keys(amount)?.map((key) => {
                  vsf?.refs?.hospitalizationParmentForm?.setFieldValue([enums[key]], -amount?.[key]);
                });
                const key = select?.value?.at(-1)?.paymentType;
                vsf?.refs?.hospitalizationParmentForm?.setFieldValue([enums[key]], -amount?.[key] -  (Math.abs(payParamData?.inpSettleMasterVo?.totalCharge) - total))
                vsf?.refs?.hospitalizationParmentForm?.setFieldValue?.(['remaining'], 0);
                setOpen(false)
              }
            }}
          >确定</Button>
        </Flex>
      </Flex>
    );
  };
  

  return (
    <div className="pay-layout-container">
      <div className="pay-person-detaill">
        <Row>
          <Col span={6}>
            姓名：
            <span className="person-detaill">
              {patientInformation?.name ?? ''}
            </span>
            {/* <span>{feibie}</span> */}
          </Col>
          <Col span={6}>
            费别：
            <span className="person-detaill">
              {/* {patientInformation?.chargeType ?? ''}
              {patientInformation?.chargeType ?? ''} */}
              {patientInformation?.chargeType &&
                (vsf.stores.dbenums.enums.CHARGE_TYPE_DICT?.find(
                  (item) => item?.value === patientInformation?.chargeType,
                )?.label ??
                  '')}
            </span>
          </Col>
          <Col span={6}>
            人员类别：
            <span className="person-detaill">
              {/* vsf.stores.dbenums.enums.IDENTITY_DICT */}
              {patientInformation?.identityCode &&
                (vsf.stores.dbenums.enums.IDENTITY_DICT?.find(
                  (item) => item?.value === patientInformation?.identityCode,
                )?.label ??
                  '')}
            </span>
          </Col>
          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="pay-person-content">
        <div className="pay-person-content-left">
          <div>
            <Row>
              {payParamData?.inpSettleApportionItemVoList?.map((item) => {
                return (
                  <>
                    <Col span={12}>
                      <span className="total-cost">
                        {item?.settleApportionName}:
                      </span>
                      <span className="total-number">
                        {askFixed(item?.apportionCharge, 2)}元
                      </span>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="pay-person-content-right">
          <div className="receivable-accounts" style={{ marginBottom: '12px' }}>
            <div>应收款</div>
            <div className="receivable-number">
              {askFixed(payParamData?.inpSettleMasterVo?.totalCharge, 2)}元
            </div>
          </div>
          <div className="receivable-accounts" style={{ marginBottom: '12px' }}>
            <div>预交金</div>
            <div className="receivable-number">
              {/* {payParamData?.inpSettleMasterVo?.totalCharge <
              payParamData?.prePaymentAmount ? ( */}
               {payParamData?.prePaymentMasterVoList ? (
                <Popover
                trigger="click"
                open={open}
                onOpenChange={() => setOpen(true)}
                  content={advance()}
                  title={`需要退款金额：${Math.abs(
                    payParamData?.inpSettleMasterVo?.totalCharge,
                  ).toFixed(2)}，已选预交金金额：${
                    askTotalAmount(
                      select?.value?.map((item) => [item?.prePaymentAmount, 1])
                    )
                  }`}
                  style={{ width: '500px' }}
                >
                  <a style={{ textDecoration: 'underline' }}>
                    {advanceTotal === 0
                      ? askFixed(payParamData?.prePaymentAmount, 2)
                      : askFixed(advanceTotal, 2)}
                    元
                  </a>
                </Popover>
              ) : (
                <a>{askFixed(payParamData?.prePaymentAmount, 2)}元</a>
              )}
            </div>
          </div>

          <VSForm id="hospitalizationParmentForm" form={form}>
            {payWays?.includes('CASH') && (
              <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
                <VSFormItem
                  name={['totalCharge']}
                  label={<div className="receivable-form-title">现金</div>}
                  valueType="digit"
                  fieldProps={{
                  style: { height: '48px', lineHeight: '48px' },
                      width: '100%',
                  precision: 2,
                    onChange: handelTotalCharge,
                    disabled: check?.value,
                  }}
                />
              </VSFormLayout>
            )}

            {payWays?.includes('INTEGRATION_PAYMENT') && (
              <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
                <VSFormItem
                  name={['aggregate']}
                  label={<div className="receivable-form-title">聚合支付</div>}
                  valueType="digit"
                  fieldProps={{
                    style: { height: '48px' },
                    style: { height: '48px', lineHeight: '48px' },
                    width: '100%',
                    precision: 2,
                    disabled: check?.value,
                    onChange: handelAggregate,
                  }}
                />
              </VSFormLayout>
            )}

            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['remaining']}
                label={<div className="receivable-form-title">找零</div>}
                valueType="digit"
                fieldProps={{
                  style: { height: '48px', lineHeight: '48px' },
                  width: '100%',
                  precision: 2,
                  disabled: true,
                }}
              />
            </VSFormLayout>

            <VSFormLayout columnCount={1}>
              <VSFormItem
                name={['paymentDetail', 'paymentType']}
                valueType="checkbox"
                dataSource={[
                  { label: '现金', value: 'CASH' },
                  { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
                  { label: '支票', value: '' },
                  // { label: '产科减免', value: '3', disabled: true },
                  { label: '核销', value: '4', disabled: true },
                ]}
                fieldProps={{
                  onChange: (sum) => {
                    const set = vsf?.refs?.hospitalizationParmentForm?.setFieldValue;
                    const reset = vsf?.refs?.hospitalizationParmentForm?.resetFields
                    switch ((sum ?? [])?.length) {
                      case 1:
                          const [text] = sum;
                          switch (text) {
                            case 'CASH':
                              set(['totalCharge'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge));
                              reset([['aggregate']]);
                              set(['remaining'], askFixed(0));
                              break;
                            case 'INTEGRATION_PAYMENT':
                              set(['aggregate'], askFixed(payParamData?.inpSettleMasterVo?.totalCharge));
                              reset([['totalCharge']]);
                              set(['remaining'], askFixed(0));
                              break;
                          }
                        break;
                    }
                    // if(!item?.includes('CASH')) {
                    //   vsf.refs.hospitalizationParmentForm?.setFieldValue(
                    //     'totalCharge',
                    //     '',
                    //   );
                    //   handelTotalCharge({ target: { value: '' }})
                    // }
                    // if(!item?.includes('INTEGRATION_PAYMENT')) {
                    //   vsf.refs.hospitalizationParmentForm?.setFieldValue(
                    //     'aggregate',
                    //     '',
                    //   );
                    //   handelAggregate({ target: { value: '' }})
                    // }
                    // setPayWays(sum);
                  },
                  disabled: check?.value,
                }}
              />
            </VSFormLayout>

            {/* <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">支票号</div>}
                valueType="text"
                fieldProps={{
                  style: { height: '48px' },
                }}
              />
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">银行名称</div>}
                valueType="text"
                fieldProps={{
                  style: { height: '48px' },
                }}
              />
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">银行账户</div>}
                valueType="text"
                fieldProps={{
                  style: { height: '48px' },
                }}
              />
            </VSFormLayout> */}
          </VSForm>
        </div>
      </div>
    </div>
  );
}

export default ParModal;
