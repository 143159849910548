import {
  compose,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React from 'react';

export type AspirinTimeProps = {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 值变化回调
   */
  onChange?: (value?: string) => void;
};

/**
 * 时间选择器(废弃)
 * @deprecated
 */
const AspirinTime = (props: AspirinTimeProps) => {
  const { defaultValue, value: valueProp, onChange } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  /** 编写组件逻辑 */
  return (
    <div>
      <>展示值：{value}</>
      <a onClick={() => setValue(undefined)}>修改值</a>
    </div>
  );
};
AspirinTime.displayName = 'AspirinTime';

export default compose(
  withField<string>({
    name: 'AspirinTime',
  }),
  withPreview<AspirinTimeProps>({
    renderPreview: (props) => {
      const { value } = props;
      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(AspirinTime);
