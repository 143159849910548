import '@/pages/Index/components/order_table/index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Input,
  List,
  message,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Table,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useGetState, useUpdateEffect } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AspirinDateTimePicker } from '@/components';
import Icon from '@/module/Icon';
import {
  askFixed,
  askGroupMedicalOrders,
} from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useGetResetInitState,
  useOnChangeValue,
  useScroll,
} from '@/pages/Index/components/func/hooks';
import Mybutton from '@/pages/Index/components/my_button';
import NewDrawer from '@/pages/Index/components/new_drawer';
import PatientTable from '@/pages/Index/components/patient_list';
import { getScrollY } from '@/utils';

import ExecuteModal from './execute';
import FailModal from './fail';
import PauseModal from './pause';
import RollbackModal from './rollback';
import SendModal from './send';
import OrderCheck from './table';

const Index = (props) => {
  const { routes, stores } = props;
  const { user, common } = stores || {};
  const {
    hospitalType,
    inVisitInfo,
    setCurrentOrderPatient,
    currentOrderPatient,
    staffInfo,
  } = user || {};
  const { currentApplication } = common || {};
  const orderExecuteRef = useRef<any>();

  const [patientOpen, setPatientOpen, getPatientOpen] =
    useGetState<boolean>(false);

  const [tableData, setTableData, getTableData] = useGetState<any[]>([]);
  const [rest, setRest] = useState(true);
  const [queryKey, setQueryKey] = useState<number>(0);
  const [reload, setReoad] = useState(0);
  const [value] = useOnChangeValue({});

  const sendModalRef = useRef<any>();
  const rollbackModalRef = useRef<any>();
  const failModalRef = useRef<any>();
  const pauseModalRef = useRef<any>();
  const executeModalRef = useRef<any>();

  const [planDateTimeRangeIn, setPlanDateTimeRangeIn, getPlanDateTimeRangeIn] =
    useGetState<any>([
      dayjs().startOf('day').valueOf(),
      dayjs().endOf('day').valueOf(),
    ]);

  const [repeatIndicatorIs, setRepeatIndicatorIs, getRepeatIndicatorIs] =
    useGetState<any>();

  const [performLabel, setPerformLabel, getPerformLabel] = useGetState<any>();

  const [orderClass, setOrderClass, getOrderClass] = useGetState<any>();

  const [performStatusIs, setPerformStatusIs, getPerformStatusIs] =
    useGetState<any>();

  const patientRef = useRef<any>();
  // form
  const [scrollId] = useOnChangeValue<any>(0);
  const [scroll] = useOnChangeValue(0);
  const tempData = useRef<any>([]);
  const { id } = useScroll(() => {
    scroll?.onChange?.(scrollId?.value);
  });

  useEffect(() => {
    if (routes?.query?.patientId) {
      setCurrentOrderPatient({
        patient: {
          id: routes?.query?.patientId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  // useEffect(() => {
  //   clearScrollId();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [patientRef.current, currentOrderPatient]);

  const clearScrollId = () => {
    const table = document.querySelector(`[data-id="${id}"] .ant-table-body`);
    scrollId?.onChange?.(0);
    scroll?.onChange?.(0);
    table?.scrollTo(0, 0);
    tempData.current = [];
  };

  const handleQuery = () => {
    clearScrollId();
    setTimeout(() => {
      setQueryKey((v) => v + 1);
    }, 100);
  };

  const formatData = (data) => {
    const newData = data
      ?.map((item: any) => {
        if (item?.groupNumber && item?.groupSubNumber === 1) {
          const children = data
            ?.filter((cItem) => item?.planDateTime === cItem?.planDateTime)
            ?.filter((cItem) => cItem?.groupNumber === item?.groupNumber)
            ?.sort((item) => item?.groupSubNumber);
          if (children?.length > 1) {
            return {
              ...item,
              children: children,
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
      ?.filter((item) => !item?.groupSubNumber || item?.groupSubNumber === 1);
    return newData;
  };

  const convertQueryPagedByOrderPerformPlanIndexQtoRes2VSPromiseTableOrderPerformPlanRo =
    (input) => {
      const _ = window._ ?? {};
      const output: any[] = [];
      output.push(
        ...(_.get(input, 'data.result')?.map((d, i) => {
          const obj: any = {};
          obj.id = _.get(d, 'id');
          obj.patientId = _.get(d, 'patient.id');
          obj.bedId = _.get(d, 'inpVisitHospitalBed.patientInHospital.bed.id');
          obj.bedLabel = _.get(
            d,
            'inpVisitHospitalBed.patientInHospital.bed.bedLabel',
          );
          obj.patientName = _.get(d, 'patient.name');
          obj.planDateTime = _.get(d, 'planDateTime');
          obj.planTimeStatus = _.get(d, 'planTimeStatus');
          obj.performStatus = _.get(d, 'performStatus');
          obj.performResult = _.get(d, 'performResult');
          obj.inpVisitId = _.get(d, 'inpVisitHospitalBed.id');
          obj.orderId = _.get(d, 'order.id');
          obj.startPerformDateTime = _.get(d, 'order.startPerformDateTime');
          obj.orderDrugId = _.get(d, 'orderDrug.id');
          obj.groupNumber = _.get(d, 'orderDrug.groupNumber');
          obj.groupSubNumber = _.get(d, 'orderDrug.groupSubNumber');
          obj.duration = _.get(d, 'orderDisposal.duration');
          obj.durationUnit = _.get(d, 'orderDisposal.durationUnit');
          obj.orderDoctorId = _.get(d, 'order.doctorInfo.id');
          obj.orderDoctorName = _.get(d, 'order.doctorInfo.staffName');
          obj.checkNurseId = _.get(d, 'checkNurse.id');
          obj.checkNurseName = _.get(d, 'checkNurse.staffName');
          obj.performDateTime = _.get(d, 'performDateTime');
          obj.performNurseId = _.get(d, 'performNurse.id');
          obj.performNurseName = _.get(d, 'performNurse.staffName');
          obj.stopDateTime = _.get(d, 'stopDateTime');
          obj.stopNurseId = _.get(d, 'stopNurse.id');
          obj.stopNurseName = _.get(d, 'stopNurse.staffName');
          obj.repeatIndicator = _.get(d, 'order.repeatIndicator');
          obj.dangerIndicator = _.get(d, 'orderDrug.drug.dangerIndicator');
          obj.skinTestResult = _.get(d, 'orderDrug.skinTestResult');
          obj.orderClass = _.get(d, 'order.orderClass');
          obj.skinTestVsDrugIndicator = _.get(
            d,
            'order.skinTestVsDrugIndicator',
          );
          obj.doubleSignatureIndicator = _.get(
            d,
            'order.doubleSignatureIndicator',
          );
          const orderClass = _.get(d, 'order.orderClass'); // 医嘱类型
          if (orderClass == 'DRUG') {
            obj.dosage = _.get(d, 'orderDrug.dosage'); // 剂量
            obj.dosageUnit = _.get(d, 'orderDrug.dosageUnit');
            obj.usage = _.get(d, 'orderDrug.usage'); // 用量
            obj.usageUnit = _.get(d, 'orderDrug.usageUnit');
            obj.administration =
              vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
                (item) => item?.code == _.get(d, 'orderDrug.administration'),
              )?.cname; // 途径
            obj.frequency = _.get(d, 'orderDrug.frequency.frequency'); // 频次
            obj.amount = _.get(d, 'orderDrug.totalAmount'); // 数量
            obj.amountUnit = _.get(d, 'orderDrug.packageUnit');
          } else if (orderClass == 'HERB') {
            obj.dosage = _.get(d, 'orderHerb.herbDosage'); // 剂量
            obj.dosageUnit = 'ml'; // 剂量单位
            obj.administration =
              vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
                (item) => item?.code == _.get(d, 'orderHerb.useWay'),
              )?.cname; // 途径
            obj.frequency = _.get(d, 'orderHerb.frequency.frequency'); // 频次
            obj.amount = _.get(d, 'orderHerb.herbRegimen'); // 数量
            obj.amountUnit = '付';
          } else {
            obj.administration =
              vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
                (item) =>
                  item?.code == _.get(d, 'orderDisposal.administration'),
              )?.cname; // 途径
            obj.frequency = _.get(d, 'orderDisposal.frequency.frequency'); // 频次
            obj.amount = _.get(d, 'orderDisposal.amount'); // 数量
            obj.amountUnit = '';
          } // 医嘱正文前缀
          let orderTextPrefix = '';
          const tpnIndicator = _.get(d, 'orderDrug.tpnIndicator');
          const prescriptionId = _.get(d, 'orderDrug.prescriptionId');
          if (tpnIndicator && tpnIndicator === true) {
            orderTextPrefix = '[TPN]';
          } else if (prescriptionId && prescriptionId > 0) {
            orderTextPrefix = '[处方]';
          }
          obj.orderText =
            (orderTextPrefix ?? '') + (_.get(d, 'order.orderText') ?? ''); // 医嘱正文
          return obj;
        }) ?? []),
      );
      console.log(output, 'outputoutput');

      return output;
    };

  const formatDateTime = () => {
    const value = getPlanDateTimeRangeIn();
    if (!value) {
      return {
        begin: dayjs().startOf('day').valueOf(),
        beginInclude: true,
        end: dayjs().endOf('day').valueOf(),
        endInclude: true,
      };
    } else {
      return {
        begin: value?.[0],
        beginInclude: true,
        end: value?.[1],
        endInclude: true,
      };
    }
  };

  const formatQuery = () => {
    const currentPerformStatusIs = getPerformStatusIs() ?? 'NOT_EXECUTED';

    let timeParams: any;

    if (
      currentPerformStatusIs === 'EXECUTED' ||
      currentPerformStatusIs === 'DEPRECATE'
    ) {
      timeParams = {
        performDateTimeBiggerThanEqual: formatDateTime()?.begin,
        performDateTimeLessThanEqual: formatDateTime()?.end,
      };
    } else {
      timeParams = {
        planDateTimeBiggerThanEqual: formatDateTime()?.begin,
        planDateTimeLessThanEqual: formatDateTime()?.end,
      };
    }

    const performLabelValue = getPerformLabel() ?? [];

    const searchParams: any = {
      wardIdIs: currentApplication?.department?.id,
      performStatusIs: currentPerformStatusIs,
      ...timeParams,
    };
    if (performLabelValue?.length > 0) {
      searchParams.performLabelIdIn = performLabelValue;
    }
    const repeatIndicatorIs = getRepeatIndicatorIs();
    const orderClass = getOrderClass();
    if (repeatIndicatorIs !== 'ALL') {
      searchParams.repeatIndicatorIs = repeatIndicatorIs;
    }
    if (orderClass == 'DRUG') {
      searchParams.orderClassIn = ['DRUG', 'HERB'];
    } else if (orderClass == 'NOT_DRUG') {
      searchParams.orderClassNotIn = ['DRUG', 'HERB'];
    }
    return searchParams;
  };

  const init = useCallback(
    async (patientId?: number) => {
      const searchParams = formatQuery();
      const res =
        await vsf?.services?.ClinicRecordOrderPerformController_queryPagedByOrderPerformPlanIndexQto_e60d36?.(
          {
            qto: {
              from: scrollId?.value,
              size: 20,
              ...searchParams,
              patientIdIs: patientId ?? currentOrderPatient?.patient?.id,
            },
            ext: {},
          },
        );

      if (res?.code === 200) {
        if (res?.data?.size >= 20) {
          scrollId?.onChange?.(res?.data?.result?.length + scrollId?.value);
        }
        const resultData =
          convertQueryPagedByOrderPerformPlanIndexQtoRes2VSPromiseTableOrderPerformPlanRo?.(
            res,
          );
        tempData.current = [...tempData.current, ...(resultData ?? [])];
        const _res =
          tempData.current?.reduce((prev, cur) => {
            if (!prev.some((item) => item.id === cur.id)) {
              prev.push(cur);
            }
            return prev;
          }, []) ?? [];
        console.log(_res, '_res_res');

        setTableData(formatData([..._res]));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scroll?.value, queryKey, setTableData, currentOrderPatient],
  );
  useEffect(() => {
    init();
  }, [init, currentOrderPatient, reload]);
  // useEffect(() => {
  //   init();
  // }, [init]);

  const renderPatientList = () => {
    return (
      <div className="aspirin-execute-order-patient-list">
        <div className="content">
          <PatientTable
            patientOpen={patientOpen}
            statusDataSource={[{ label: '在科', value: 'IN_DEPARTMENT' }]}
            hideField={['signaturePatient']}
            onChange={(v) => {
              if (v) {
                clearScrollId();
                patientRef.current = v?.patient?.id;
                value?.onChange(v);
                // init(v?.patient?.id);
                setPatientOpen(false);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getRecordDetailInfo = async (id) => {
    const res =
      await vsf?.services?.ClinicRecordOrderPerformController_getOrderPerformPlanIndexVoById_936ff1?.(
        { id },
      );
    return res?.code === 200 ? res?.data : {};
  };

  const handleSubmit = () => {
    // sendModalRef?.current?.open(123);
    // rollbackModalRef?.current?.open(123);
    // failModalRef?.current?.open(123);
    // pauseModalRef?.current?.open(123);
    executeModalRef?.current?.open({
      type: 'execute',
      data: {},
    });
  };

  const formatDetailData = (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.id = _.get(input, 'id');
    const orderClass = _.get(input, 'order.orderClass');
    output.orderClass = orderClass;
    output.dangerIndicator = _.get(input, 'orderDrug.drug.dangerIndicator');
    output.skinTestVsDrugIndicator = _.get(
      input,
      'order.skinTestVsDrugIndicator',
    );
    output.doubleSignatureIndicator = _.get(
      input,
      'order.doubleSignatureIndicator',
    );
    output.startPerformDateTime = _.get(input, 'order.startPerformDateTime');
    output.planDateTime = _.get(input, 'planDateTime');
    output.duration = _.get(input, 'orderDisposal.duration');
    output.durationUnit = _.get(input, 'orderDisposal.durationUnit');
    output.operationScheduleDateTime = _.get(
      input,
      'orderOperation.scheduleDateTime',
    );
    output.operationRoomdepartmentName = _.get(
      input,
      'orderOperation.operationRoomDepartment.departmentName',
    );
    output.operationRoomNumber = _.get(
      input,
      'orderOperation.operationRoom.roomNumber',
    );
    output.operationSequence = _.get(input, 'orderOperation.operationSequence');
    output.examScheduleDateTime = _.get(input, 'orderExam.scheduleDateTime');
    output.performDepartmentName = _.get(
      input,
      'order.performDepartment.departmentName',
    );
    output.skinTestResult = _.get(input, 'orderDrug.skinTestResult');
    output.performDateTime = _.get(input, 'performDateTime');
    output.stopDateTime = _.get(input, 'stopDateTime');
    output.performStatus = _.get(input, 'performStatus');
    output.performResult = _.get(input, 'performResult');
    output.performResultDescription = _.get(input, 'performResultDescription');
    output.performDosage = _.get(input, 'performDosage');
    output.memo = _.get(input, 'memo');
    let dosage = '';
    let dosageUnit = '';
    let usage = '';
    let usageUnit = '';
    let administration = '';
    let frequency = '';
    let amount = '';
    let amountUnit = '';
    if (orderClass == 'DRUG') {
      dosage = _.get(input, 'orderDrug.dosage'); // 剂量
      dosageUnit = _.get(input, 'orderDrug.dosageUnit');
      usage = _.get(input, 'orderDrug.usage'); // 用量
      usageUnit = _.get(input, 'orderDrug.usageUnit');
      administration = vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
        (item) => item?.code == _.get(input, 'orderDrug.administration'),
      )?.cname; // 途径
      frequency = _.get(input, 'orderDrug.frequency.frequency'); // 频次
      amount = _.get(input, 'orderDrug.totalAmount'); // 数量
      amountUnit = _.get(input, 'orderDrug.packageUnit');
    } else if (orderClass == 'HERB') {
      dosage = _.get(input, 'orderHerb.herbDosage'); // 剂量
      dosageUnit = 'ml'; // 剂量单位
      administration = vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
        (item) => item?.code == _.get(input, 'orderHerb.useWay'),
      )?.cname; // 途径
      frequency = _.get(input, 'orderHerb.frequency.frequency'); // 频次
      amount = _.get(input, 'orderHerb.herbRegimen'); // 数量
      amountUnit = '付';
    } else {
      administration = vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
        (item) => item?.code == _.get(input, 'orderDisposal.administration'),
      )?.cname; // 途径
      frequency = _.get(input, 'orderDisposal.frequency.frequency'); // 频次
      amount = _.get(input, 'orderDisposal.amount'); // 数量
      amountUnit = '';
    }
    let orderTextPrefix = '';
    const tpnIndicator = _.get(input, 'orderDrug.tpnIndicator');
    const prescriptionId = _.get(input, 'orderDrug.prescriptionId');
    if (tpnIndicator && tpnIndicator === true) {
      orderTextPrefix = '[TPN]';
    } else if (prescriptionId && prescriptionId > 0) {
      orderTextPrefix = '[处方]';
    }
    const orderTextStr =
      (orderTextPrefix ?? '') + (_.get(input, 'order.orderText') ?? '');
    const dosageStr = (dosage ?? '') + (dosageUnit ?? '');
    const usageStr = (usage ?? '') + (usageUnit ?? '');
    const amountStr = (amount ?? '') + (amountUnit ?? '');
    const orderTextArr = [
      orderTextStr,
      dosageStr,
      usageStr,
      administration ?? '',
      frequency ?? '',
      amountStr,
    ];
    const filterOrderTextArr = orderTextArr.filter((str) => str !== '');
    output.orderText = filterOrderTextArr.join(' ') ?? ''; // 医嘱正文

    return output;
  };

  // 执行
  const handleExecute = async (v?: any) => {
    const type = 'execute';
    const values = await orderExecuteRef?.current?.getValidateValues();

    if (v) {
      const data = await getRecordDetailInfo(v?.id);

      executeModalRef?.current?.open({
        modalType: 'execute',
        type: 'EXECUTED',
        data: {
          origin: {
            ...data,
            performDateTime: v?.performDateTime,
            checkNurseId: v?.checkNurseId,
          },
          format: {
            ...formatDetailData(data),
            performDateTime: v?.performDateTime,
            checkNurseId: v?.checkNurseId,
          },
        },
      });
    } else {
      handleSubmitRequest({
        type: 'EXECUTED',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  // 取消
  const handleCancel = async (v?: any) => {
    const values = await orderExecuteRef?.current?.getValidateValues();
    if (v) {
      const data = await getRecordDetailInfo(v?.id);
      executeModalRef?.current?.open({
        modalType: 'cancel',
        type: 'NOT_EXECUTED',
        data: {
          origin: {
            ...data,
            performDateTime: v?.performDateTime,
          },
          format: {
            ...formatDetailData(data),
            performDateTime: v?.performDateTime,
          },
        },
      });
    } else {
      handleSubmitRequest({
        type: 'NOT_EXECUTED',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  // 作废
  const handleInvalid = async (v?: any) => {
    const values = await orderExecuteRef?.current?.getValidateValues();
    if (v) {
      const data = await getRecordDetailInfo(v?.id);
      executeModalRef?.current?.open({
        modalType: 'invaild',
        type: 'DEPRECATE',
        data: {
          origin: {
            ...data,
            performDateTime: v?.performDateTime,
          },
          format: {
            ...formatDetailData(data),
            performDateTime: v?.performDateTime,
          },
        },
      });
    } else {
      handleSubmitRequest({
        type: 'DEPRECATE',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  // 停止
  const handleStop = async (v) => {
    const values = await orderExecuteRef?.current?.getValidateValues();
    if (v) {
      const data = await getRecordDetailInfo(v?.id);
      executeModalRef?.current?.open({
        modalType: 'stop',
        type: 'EXECUTED',
        data: {
          origin: {
            ...data,
            performDateTime: v?.performDateTime,
          },
          format: {
            ...formatDetailData(data),
            performDateTime: v?.performDateTime,
          },
        },
      });
    } else {
      handleSubmitRequest({
        type: 'EXECUTED',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  // 暂停
  const handlePause = async (v) => {
    const values = await orderExecuteRef?.current?.getValidateValues();
    if (v) {
      handleSubmitRequest({
        type: 'SUSPEND',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  // 取消暂停
  const handleUnstop = async (v) => {
    const values = await orderExecuteRef?.current?.getValidateValues();
    if (v) {
      handleSubmitRequest({
        type: 'PROCESSING',
        detail: values?.map((item) => {
          return {
            origin: {
              ...item,
              performDateTime: item?.performDateTime,
            },
            format: {
              ...formatDetailData(item),
              performDateTime: item?.performDateTime,
            },
          };
        }),
      });
    }
  };

  const pauseFormatData = (input) => {
    const _ = window._ ?? {};
    const resultTableData: any[] = [];
    resultTableData.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.operatorId = _.get(d, 'operator.id');
        const suspendIndicator = _.get(d, 'suspendIndicator');
        obj.suspendIndicator = suspendIndicator;
        obj.operatorType = '未知'; // 操作类型
        if (suspendIndicator == true) {
          obj.operatorType = '暂停';
        } else if (suspendIndicator == false) {
          obj.operatorType = '取消暂停';
        }
        obj.operateDate = _.get(d, 'operateDate');
        obj.operatorName = _.get(d, 'operator.staffName');
        obj.reason = _.get(d, 'reason');
        return obj;
      }) ?? []),
    );
    return resultTableData;
  };

  const handleShowPauseRecord = async (v) => {
    pauseModalRef?.current?.open(v?.id);
  };

  const formatSubmitRequestData = (data) => {
    const { type, detail, values } = data;
    console.log(data, 'datadata1');

    const res: any[] = [];
    const target = Array.isArray(detail) ? detail : [detail];
    target?.forEach((item) => {
      res.push({
        performType: 'MANUAL',
        id: item?.origin?.id,
        ...values,
        performDateTime: values?.performDateTime ?? new Date().valueOf(),
        performStatus: type,
        checkNurseId: item?.origin?.checkNurseId,
        checkDateTime: +new Date(),
      });
    });
    const extApiParam = values?.skinTestResult
      ? {
          skinTestResult: values?.skinTestResult,
        }
      : {};
    return {
      btoParamList: res,
      extApiParam,
    };
  };

  const handleSubmitRequest = async (values) => {
    console.log(values);

    orderExecuteRef?.current?.clearChecked();
    const reqParam = formatSubmitRequestData(values);
    const showMessage = Array.isArray(values?.detail);

    const res =
      await vsf?.services?.ClinicRecordOrderPerformController_updateOrderPerformPlanStatus_c91f69?.(
        reqParam,
      );
    if (showMessage) {
      if (
        res?.code === 200 &&
        (!res?.data?.failIdWithReasonList ||
          res?.data?.failIdWithReasonList <= 0)
      ) {
        message.success(res?.message || '操作成功');
      } else {
        message.error(res?.message || '操作失败');
      }
    }
    if (res?.code === 200) {
      const { failIdWithReasonList, successIdList } = res?.data;
      if (failIdWithReasonList && failIdWithReasonList?.length > 0) {
        failModalRef?.current?.open(failIdWithReasonList);
      } else {
        clearScrollId();
        setTimeout(() => {
          if (scroll?.value != 0) return;
          init();
          // setQueryKey((v) => {
          //   return v + 1;
          // });
        }, 1000);
        // format successIdList

        // setQueryKey((v) => {
        //   return v + 1;
        // });
        return res;
      }
    }
    return {
      code: 500,
      message: '操作失败',
    };
  };

  return (
    <div className="aspirin-execute-order-container">
      <NewDrawer
        open={patientOpen}
        width="290px"
        onOpen={() => {
          setPatientOpen(!getPatientOpen());
        }}
        title="患者列表"
        buttonTitle="患者列表"
        isOther
        buttonStyle={{
          top: '20%',
        }}
        style={{
          top: 104,
        }}
        drift="98px"
        drawerClassName="aspirin-execute-order-patient-drawer"
        footer={
          <div className="aspirin-execute-order-patient-drawer-button">
            <Mybutton
              buttonProps={{
                ghost: true,
                onClick: () => {
                  // init();
                  setPatientOpen(false);
                  setReoad(reload + 1);
                  clearScrollId();
                  setCurrentOrderPatient(null);
                },
              }}
            >
              刷新
            </Mybutton>
          </div>
        }
      >
        {renderPatientList()}
      </NewDrawer>
      <div className="aspirin-execute-order-main">
        <div className="header">
          <div>
            <span>日期</span>
            <AspirinDateTimePicker
              valueType="timestamp"
              type="range"
              dateRangePickerProps={{
                placeholder: ['开始时间', '结束时间'],
              }}
              value={planDateTimeRangeIn}
              onChange={(v) => setPlanDateTimeRangeIn(v)}
            ></AspirinDateTimePicker>
          </div>
          <div>
            <span>类别</span>
            <Select
              defaultValue={'ALL'}
              dataSource={[
                { label: '全部', value: 'ALL' },
                { label: '长期', value: true },
                { label: '临时', value: false },
              ]}
              value={repeatIndicatorIs}
              onChange={(v) => setRepeatIndicatorIs(v)}
            />
          </div>
          <div>
            <span>执行分类</span>
            <Select
              dataSource={async (params) => {
                const res =
                  await vsf?.services?.ClinicRecordOrderExtensionController_getAllByQueryMasterByWardQto_97e768?.(
                    {
                      qto: {
                        wardIdIsNullOrNot: true,
                        wardIdIs: props?.wardId,
                        branchInstitutionIdIs: props?.branchInstitutionId,
                      },
                    },
                  );
                return res?.data?.map((item) => ({
                  label: item?.labelName,
                  value: item?.id,
                }));
              }}
              value={performLabel}
              mode="multiple"
              onChange={(v) => setPerformLabel(v)}
            />
          </div>
          <div>
            <span>医嘱类型</span>
            <Select
              defaultValue={'ALL'}
              dataSource={[
                { label: '全部', value: 'ALL' },
                { label: '药物', value: 'DRUG' },
                { label: '非药物', value: 'NOT_DRUG' },
              ]}
              value={orderClass}
              onChange={(v) => setOrderClass(v)}
            />
          </div>
          <div>
            <RadioGroup
              defaultValue={'NOT_EXECUTED'}
              value={performStatusIs}
              dataSource={[
                { label: '未执行', value: 'NOT_EXECUTED' },
                { label: '已执行', value: 'EXECUTED' },
                { label: '执行中', value: 'PROCESSING' },
                { label: '执行暂停', value: 'SUSPEND' },
                { label: '执行作废', value: 'DEPRECATE' },
              ]}
              onChange={(v) => {
                setPerformStatusIs(v);
                handleQuery();
              }}
            ></RadioGroup>
          </div>
          <Button type="primary" onClick={handleQuery}>
            查询
          </Button>
        </div>
        <div className="main">
          <OrderCheck
            scrollFunc={scroll}
            scrollId={scrollId}
            ids={id}
            value={tableData}
            ref={orderExecuteRef}
            handleExecute={handleExecute}
            handleCancel={handleCancel}
            handleInvalid={handleInvalid}
            handleStop={handleStop}
            handlePause={handlePause}
            handleUnstop={handleUnstop}
            handleShowPauseRecord={handleShowPauseRecord}
          />
          <div className="aspirin-execute-order-footer">
            <div className="left"></div>
            <div className="right">
              <Button
                className="footer-btn"
                type="primary"
                onClick={() => {
                  handleCancel();
                }}
              >
                取消执行
              </Button>
              <Button
                className="footer-btn"
                type="primary"
                onClick={() => {
                  handleInvalid();
                }}
              >
                作废执行
              </Button>
              <Button
                className="footer-btn"
                type="primary"
                onClick={() => {
                  handleExecute();
                }}
              >
                执行
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SendModal ref={sendModalRef} />
      <RollbackModal ref={rollbackModalRef} />
      <FailModal ref={failModalRef} init={handleQuery} />
      <PauseModal ref={pauseModalRef} />
      <ExecuteModal ref={executeModalRef} onSubmit={handleSubmitRequest} />
    </div>
  );
};

export default definePage(Index);
