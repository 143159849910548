import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderDispenseController_getWardOrderWaitDispensePatientCount_6fc9bf: {
    method: 'post',
    url: '/api/drug-order-dispense/get-ward-order-wait-dispense-patient-count',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitDispenseAuditPatientCountGroupWardEoDrugDispenseAuditPersistEo[],
    ) => data,
  },
});
