import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import TableDetails from './TalebDetails';
import UserForm from './UserForm';
import { useRequest } from 'ahooks';
const OutpMedicalTechnologyCharge = (props) => {
  // 医生
  const { data: operationDoctorData, run: operationDoctorRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.UserStaffController_getDoctorStaffListOfInstitution_ae1040?.();
      return res?.data?.map?.((ele) => {
        return {
          staffId: ele?.id,
          staffName: ele?.staffName,
        };
      });
    },
    { manual: true },
  );

  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState(),
    [outpVisitId, setOutpVisitId] = useState(),
    resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    operationDoctorRun?.();
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);
  return (
    <div className="OutpMedicalTechnologyCharge">
      <UserForm {...{ setOutpVisitId }} />
      <TableDetails {...{ outpVisitId, operationDoctorData }} />
    </div>
  );
};

export default definePage(OutpMedicalTechnologyCharge);
