import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_updateOrCreateDrugUsageByProductId_48b45e: {
    method: 'post',
    url: '/api/drug-drug/update-or-create-drug-usage-by-product-id',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugProductUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugProductUsage_2c8f6a: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product-usage',
    parameterFomatter: (data?: {
      bto: DeleteDrugProductUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_getProductUsageListByDrugProductId_82d6a2: {
    method: 'post',
    url: '/api/drug-drug/get-product-usage-list-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductUsageWithProductVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugStorageImportExportController_getExportMasterWithDetailById_c96935: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-export-master-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
});
