import React from 'react';

import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import { Section, Todo } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const DepartmentTree = (props) => {
  const {
    stores: {
      common: { currentApplication },
    },
  } = props;
  /**
   * 获取树节点id
   * @param {object} department
   * @returns {any}
   */
  const getId = (department) => {
    return department.id;
  };
  return (
    <TreeMenu
      id="staffTree"
      isTree
      loadData={async () => {
        const res = await vsf.services.DepartmentDtoController_getAll_d6e9c4({
          qto: {
            institutionIdIs:
              currentApplication?.department?.branchInstitution
                ?.upInstitutionId,
            // branchInstitutionIdIs:
            //   currentApplication?.department?.branchInstitution?.id,
          },
        });
        const _data = res?.data.reduce((acc, item, index) => {
          // 查找是否已存在具有相同id的项
          const existingGroup = acc.find(
            (group) => group.departmentCode == item.branchInstitution?.id,
          );
          // 如果不存在，则创建一个新的组
          if (!existingGroup) {
            acc.push({
              departmentName: item.branchInstitution?.institutionName,
              departmentCode: item.branchInstitution?.id,
              upInstitutionId: item.branchInstitution?.upInstitutionId,
              children: [{ ...item }],
            });
          } else {
            // 如果存在，则将当前项添加到该组的arr数组中
            existingGroup.children.push(item);
          }
          // 返回累积的数组
          return acc;
        }, []);
        const tempData = _data?.map((i, index) => {
          return {
            id: Math.random(),
            // 选择院区
            disabledAdd: true,
            departmentName: i?.departmentName,
            departmentCode: i?.departmentCode,
            children: askTree(i?.children, {
              parentId: 'upDepartmentCode',
              id: 'departmentCode',
            }),
          };
        });
        console.log(tempData, _data, 'tempData');
        return {
          // data: tempData,
          data: res?.data?.length > 0 ? tempData : [],
        };
        return res;
      }}
      fieldNames={{
        title: 'departmentName',
        key: 'departmentCode',
        children: 'children',
      }}
      dataRelationFieldNames={{
        id: 'departmentCode',
        parentId: 'upDepartmentCode',
      }}
      search={{
        status: true,
        placeholder: '请输入工作站名称',
      }}
      onSelect={(_value) => {
        console.log(_value);
        vsf.refs.StaffListTable.getSearchForm().resetFields();
        // if (_value?.departmentCode == 'parentDepartmentCode') return;
        props.onChange?.(_value);
      }}
      title="科室分类"
      reload

      // row={{
      //   // expand: true,
      //   extra: (node) => {
      //     return (
      //       <div>
      //         <Icon
      //           type="icon-tianjia"
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             handleAddWithParent(node);
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      //   extraShow: 'default',
      // }}
      // context={{
      //   defaultContextMenu: {
      //     items: [
      //       {
      //         label: '新增科室',
      //         key: 'addInstitution',
      //         hidden: false,
      //         close: true,
      //         callback: (node) => {
      //           props.onChange?.({});
      //         },
      //       },
      //     ],
      //     trigger: 'click',
      //   },
      // }}
    />
  );
};
export default definePage(DepartmentTree);
