import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryProductWithCurrentPrice_4621c9: {
    method: 'post',
    url: '/api/drug-drug/query-product-with-current-price',
    parameterFomatter: (data?: {
      qto: DrugPriceItemDictionaryNameProductFormQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugPriceItemDictionaryNameProductFormVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicRecordOrderDrugController_getInpStorageIds_10643e: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-inp-storage-ids',
    parameterFomatter: (data?: { temp: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageDepartmentVoClinicRecordOrderDrugEntranceWebVo[],
    ) => data,
  },
  InpRefundDispenseController_saveInpDrugRefundApply_bf29f0: {
    method: 'post',
    url: '/api/drug-prescription-dispense/save-inp-drug-refund-apply',
    parameterFomatter: (data?: {
      applies: InpRefundApplyInputEoDrugPrescriptionDispensePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpRefundDispenseController_queryInpDrugRefundApply_921cf8: {
    method: 'post',
    url: '/api/drug-prescription-dispense/query-inp-drug-refund-apply',
    parameterFomatter: (data?: {
      qto: QueryInpDrugRefundApplyQtoDrugPrescriptionDispensePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPrescriptionWaitDispenseMasterInpRefundApplySearchVoDrugPrescriptionDispenseEntranceWebVo[],
    ) => data,
  },
  InpRefundDispenseController_getPlanAndDispenseForInpRefundDrugApply_f6c86d: {
    method: 'post',
    url: '/api/drug-prescription-dispense/query-paged-order-plan-for-inp-refund-drug-apply-qto',
    parameterFomatter: (data?: {
      qto: InpRefundDrugApplyQtoClinicRecordOrderPerformPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformPlanRecordForInpRefundDrugApplyVoDrugPrescriptionDispenseEntranceWebVo[],
    ) => data,
  },
});
