import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import { isObject, omitBy } from 'lodash';

import * as stores from '@/stores/index';
// 	查询当前院区病区（护理单元）列表
export const getWardPagedBySearchCode = async (param) => {
  const res =
    await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
      { searchCode: param?.searchCode },
    );
  return res?.data?.result;
};
//查询床位病区的在床患者简单信息列表
export const getListClinicPatientBedSimpleVoByBedWardId = async (param) => {
  if (!param?.bedWardId) return [];
  const res =
    await vsf?.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
      {
        bedWardId: param?.bedWardId,
      },
    );
  return res?.data;
};
//获取患者信息
export const getPatientInpVisitInfo = async (params) => {
  // TODO: 请确认入参来源及出参用法
  // inpVisitId: number;
  //   patientId: number;
  //   displayId: string;
  const res =
    await vsf?.services?.ClinicInpVisitController_getPatientInpVisitInfo_9055d6(
      params,
    );
  const output =
    convertGetPatientInpVisitInfoRes2InpMedicalTechnologyChargeInpVisitList?.(
      res,
    );
  return output;
};
/**
 * convertGetPatientInpVisitInfoRes2InpMedicalTechnologyChargeInpVisitList
 * @param {any} input
 * @returns {any}
 */
const convertGetPatientInpVisitInfoRes2InpMedicalTechnologyChargeInpVisitList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.admissionDateTime = _.get(d, 'admissionDateTime');
        obj.id = _.get(d, 'id');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.name = _.get(d, 'inpVisitExtensionPatientInfoDto.name');
        obj.gender = _.get(d, 'inpVisitExtensionPatientInfoDto.gender');
        obj.age = _.get(d, 'inpVisitExtensionPatientInfoDto.age');
        obj.chargeType = _.get(d, 'inpVisitExtensionBillingInfoDto.chargeType');
        obj.identityCode = _.get(
          d,
          'inpVisitExtensionBillingInfoDto.identityCode',
        );
        obj.inpVisitId = _.get(d, `id`);
        obj.patientId = _.get(d, `patient.id`);
        obj.departmentName = _.get(
          d,
          `patientInHospitalBaseDto.ward.departmentName`,
        );
        obj.bed = _.get(d, `patientInHospitalBaseDto.bed`);
        obj.ward = _.get(d, `patientInHospitalBaseDto.ward`);
        return obj;
      }) ?? []),
    );
    return output;
  };
// 获取患者信息。
export const queryPagedByPatientFullQto = async (params) => {
  const res =
    await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
      { qto: { ...params, from: 0, size: 20 } },
    );
  if (res?.data?.result?.length <= 0) return message?.error('未查询到患者');
  return res?.data?.result;
};

export const getOrderListByInpVisitId = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_getOrderListByInpVisitId_13d62c?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetOrderListByInpVisitIdRes2InpMedicalTechnologyOrderChargeOrderList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetOrderListByInpVisitIdRes2InpMedicalTechnologyOrderChargeOrderList
 * @param {any} input
 * @returns {any}
 */
const convertGetOrderListByInpVisitIdRes2InpMedicalTechnologyOrderChargeOrderList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.patientId = _.get(d, 'patientId');
        obj.clinicClass = _.get(d, 'clinicClass');
        obj.staffName = _.get(d, 'doctor.staffName');
        obj.orderText = _.get(d, 'orderText');
        obj.dosage = _.get(d, 'dosage');
        obj.administration = _.get(d, 'administration');
        obj.frequency = _.get(d, 'frequency');
        obj.orderClass = _.get(d, 'orderClass');
        obj.repeatIndicator = _.get(d, 'repeatIndicator');
        obj.createDateTime = _.get(d, 'createDateTime');
        obj.stopOrderDateTime = _.get(d, 'stopOrderDateTime'); // Date 结束时间
        obj.orderStatus = _.get(d, 'orderStatus');
        obj.doctor = _.get(d, 'doctor');
        obj.orderDepartment = _.get(d, 'orderDepartment');
        return obj;
      }) ?? []),
    );
    return output;
  };

// 非医嘱费用 计费日期
export const getNoOrderInpBillingDetailList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_getNoOrderInpBillingDetailList_b0878c?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetNoOrderInpBillingDetailListRes2InpMedicalTechnologyChargeNoOrderChargeGroupDateList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetNoOrderInpBillingDetailListRes2InpMedicalTechnologyChargeNoOrderChargeGroupDateList
 * @param {any} input
 * @returns {any}
 */
const convertGetNoOrderInpBillingDetailListRes2InpMedicalTechnologyChargeNoOrderChargeGroupDateList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.clinicClass = _.get(d, 'clinicClass');
        obj.orderDate = _.get(d, 'orderDate');
        obj.inpBillingDetailList = _.get(d, 'inpBillingDetailList')?.map(
          (ele) => ({
            ...ele,
            id: Math?.random() + '_' + _.get(ele, 'id'),
            TheRowType: 'Sava',
            applyDepartmentId: _.get(ele, 'applyDepartment.id'), //开单科室
            applyDepartmentName: _.get(ele, 'applyDepartment.departmentName'), //开单科室
            applyStaffId: _.get(ele, 'applyStaff.id'), //开单医生
            applyStaffName: _.get(ele, 'applyStaff.staffName'), //开单医生
            operatorStaffId: _.get(ele, 'operator.id'), // 操作人
            operatorStaffName: _.get(ele, 'operator.staffName'), // 操作人
            performDepartmentId: _.get(ele, 'performDepartment.id'), //执行科室
            performDepartmentName: _.get(
              ele,
              'performDepartment.departmentName',
            ), //执行科室
            performStaffId: _.get(ele, 'performStaff.id'), //执行科室
            performStaffName: _.get(ele, 'performStaff.staffName'), //执行科室
          }),
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
// 保存
export const saveInpBillingDetails = async (event) => {
  const output =
    convertInpMedicalTechnologyChargeNoOrderChargeDetailList2SaveInpBillingDetailsReq?.(
      event,
    );
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_saveInpBillingDetails_dcf810?.(
      output,
    );
  return res;
};
/**
 * convertInpMedicalTechnologyChargeNoOrderChargeDetailList2SaveInpBillingDetailsReq
 * @param {any} input
 * @returns {any}
 */
const convertInpMedicalTechnologyChargeNoOrderChargeDetailList2SaveInpBillingDetailsReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailList = [];
    output.saveInpBillingDetailList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          obj.id = _.get(d, `id`)?.split('_')?.[1]; // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`); // TODO 没有匹配到合适字段
        }
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        // TODO 没有匹配到合适字段
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        // TODO 没有匹配到合适字段
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`) ?? '测试'; // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        // TODO 没有匹配到合适字段
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        // TODO 没有匹配到合适字段
        // obj.charge = null; // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartmentId`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartmentId`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          [undefined, null, NaN, ''].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
// 医嘱信息计费项目
export const getOrderInpOrderCostGroupList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_getOrderInpOrderCostGroupList_5b7080?.(
      { orderId: params?.orderId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetOrderInpOrderCostGroupListRes2InpMedicalTechnologyOrderChargeOrderCostList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetOrderInpOrderCostGroupListRes2InpMedicalTechnologyOrderChargeOrderCostList
 * @param {any} input
 * @returns {any}
 */
const convertGetOrderInpOrderCostGroupListRes2InpMedicalTechnologyOrderChargeOrderCostList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.id = _.get(d, 'itemId');
        obj.itemId = _.get(d, 'itemId');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemName = _.get(d, 'itemName');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.price = _.get(d, 'price');
        obj.unit = _.get(d, 'unit');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 医嘱信息计费项目明细
export const getOrderInpBillingDetailList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_getOrderInpBillingDetailList_364d5f?.(
      {
        orderId: params?.orderId,
        itemId: params?.itemId,
      },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetOrderInpBillingDetailListRes2InpMedicalTechnologyOrderChargeOrderBillingDetailList?.(
      res,
    );
  return output;
};
/**
 * convertGetOrderInpBillingDetailListRes2InpMedicalTechnologyOrderChargeOrderBillingDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetOrderInpBillingDetailListRes2InpMedicalTechnologyOrderChargeOrderBillingDetailList =
  (input) => {
    // console.log(stores?.user?.staffInfo);
    const staffInfo = stores?.user?.staffInfo;
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.id = Math?.random() + '_' + _.get(d, 'id');
        obj.itemName = _.get(d, 'itemId'); //项目ID
        obj.itemName = _.get(d, 'itemName'); //项目名称
        obj.applyDepartmentId = _.get(d, 'applyDepartment.id'); //开单科室
        obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName'); //开单科室
        obj.applyStaffId = _.get(d, 'applyStaff.id'); //开单医生
        obj.applyStaffName = _.get(d, 'applyStaff.staffName'); //开单医生
        obj.operatorStaffId = staffInfo?.id; // 操作人
        obj.operatorStaffName = staffInfo?.staffName; // 操作人
        obj.performDepartmentId = _.get(d, 'performDepartment.id'); //执行科室
        obj.performDepartmentName = _.get(
          d,
          'performDepartment.departmentName',
        ); //执行科室
        obj.performStaffId = _.get(d, 'performStaff.id') ?? staffInfo?.id; //执行医生
        obj.performStaffName =
          _.get(d, 'performStaff.staffName') ?? staffInfo?.staffName; //执行医生
        obj.orderDate = _.get(d, 'orderDate');
        obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段 obj.adjustAmount = null; // Bigdecimal 调整数量
        obj.price = _.get(d, 'price');
        obj.unit = _.get(d, 'unit');
        obj.cost = _.get(d, 'cost');
        obj.materialCode = _.get(d, 'materialCode');
        obj.billingStatus = _.get(d, 'billingStatus');
        obj.itemId = _.get(d, 'itemId');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.orderId = _.get(d, 'orderId');
        obj.operatorName = _.get(d, 'operator.staffName');
        obj.operateDate = _.get(d, 'operateDate');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        return obj;
      }) ?? []),
    );
    return output;
  };

// 医嘱信息计费项目明细保存
export const saveOrderInpBillingDetails = async (event) => {
  const output =
    convertInpMedicalTechnologyOrderChargeOrderBillingDetailList2SaveInpBillingDetailsReq?.(
      event,
    );
  const res =
    await vsf?.services?.MedicalTechnologyInpChargeController_saveInpBillingDetails_dcf810?.(
      output,
    );
  return res;
};
// 医嘱信息计费项目明细新增数据处理
/**
 * convertInpMedicalTechnologyOrderChargeOrderBillingDetailList2SaveInpBillingDetailsReq
 * @param {any} input
 * @returns {any}
 */
const convertInpMedicalTechnologyOrderChargeOrderBillingDetailList2SaveInpBillingDetailsReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailList = [];
    output.saveInpBillingDetailList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          const id__name = _.get(d, 'billingStatus') ? 'id' : 'orderCostId';
          // _.get(d, 'billingStatus') && (obj.id = id); // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`); // TODO 没有匹配到合适字段
          // !_.get(d, 'billingStatus') && (obj.orderCostId = id); // Long 住院费用明细id
          obj[id__name] = _.get(d, `id`)?.split('_')?.[1];
        }

        // obj.id = _.get(d, `id`); // Long 住院费用明细id
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        // TODO 没有匹配到合适字段
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        // TODO 没有匹配到合适字段
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`) ?? 'CS'; // String 补退说明
        // obj.insuranceProvinceCode = _.get(d, `insuranceProvinceCode`) ?? 10; // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        // TODO 没有匹配到合适字段
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        // TODO 没有匹配到合适字段
        // obj.charge = null; // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartmentId`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartmentId`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          [undefined, null, NaN, ''].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
