import { createServices } from '@vs/vsf-boot';
export default createServices({
  TreatAndOtherItemController_queryTreatAndOtherItems_22e81a: {
    method: 'post',
    url: '/api/clinic-item-base/query-treat-and-other-items',
    parameterFomatter: (data?: {
      qry: ClinicItemTreatOtherQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicItemDictionaryVsPriceEoClinicItemBasePersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TreatAndOtherItemController_queryTreatItems_de7128: {
    method: 'post',
    url: '/api/clinic-item-base/query-treat-items',
    parameterFomatter: (data?: {
      qry: ClinicItemTreatOtherQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryVsPriceEoClinicItemBasePersistEo[],
    ) => data,
  },
  TreatAndOtherItemController_queryOtherItems_998cf7: {
    method: 'post',
    url: '/api/clinic-item-base/query-other-items',
    parameterFomatter: (data?: {
      qry: ClinicItemTreatOtherQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicItemDictionaryVsPriceEoClinicItemBasePersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TreatController_getAllByQueryTreatClassQto_274867: {
    method: 'post',
    url: '/api/treat/get-all-by-query-treat-class-qto',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo[]) =>
      data,
  },
  TreatController_getAllByQueryTreatSubClassQto_60b9c0: {
    method: 'post',
    url: '/api/treat/get-all-by-query-treat-sub-class-qto',
    parameterFomatter: (data?: { qto: QueryTreatSubClassQtoTreatPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo[]) =>
      data,
  },
});
