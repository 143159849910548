import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Todo } from '@vs/vsf-kit';
import React, { useEffect } from 'react';
import { TreeMenu } from '@/components';
const PricePatternTree = (props) => {
  const onFetch = async () => {
    const resolve = await Promise.all([
      vsf?.services?.BaseCodeDtoServiceController_getByCodeType_5f9695?.(
        { codeType: 'RECK_ITEM_CLASS_DICT' },
      ),
      vsf?.services?.PriceListController_getAllByPricePatternQto_8ee5de?.(
        { qto: {}, },
      )
    ])
    resolve[1].data = resolve[1].data.map((item) => ({
      ...item,
      name: item.patternName
    }))
    return {
      data: resolve.flatMap((item) => item.data)
    }
  }
  return (
    <TreeMenu
      treeProps={{
        defaultExpandAll: true,
      }}
      title="收费模版分类"
      loadData={onFetch}
      id="PricePatternTree"
      fieldNames={{ title: 'name', key: 'id', children: 'children' }}
      dataRelationFieldNames={{ id: 'code', parentId: 'patternRangeType' }}
      onSelect={(_value) => {
        props.onChange?.(_value);
      }}
      row={{
        expand: true
      }}
    />
  );
};
export default definePage(PricePatternTree);
