import Block from '@/module/Block';
import SearchContainer from '@/module/Search';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import SelectForm, {
  askSelectFormInitValue,
} from '@/pages/Index/components/select_form';
import {
  askGetTreeWhole,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { getScrollYRem, labelNumberWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Divider,
  Button,
  message,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useFetch } from '@/pages/Index/components/func/hooks';

const Extension = () => {
  return (
    <VSFormLayout columnCount={1} gutter={50} labelWidth={labelNumberWidth(7)}>
      {/* <VSFormLayout columnCount={1} labelWidth={130} gutter={50}> */}
      <VSFormItem
        name={['guideInfo']}
        label="导引单信息"
        valueType="textarea"
        rules={[{ message: `导引单信息不能超过500字`, max: 500 }]}
        fieldProps={{
          autoSize: { minRows: 3, maxRows: 5 },
        }}
      />
    </VSFormLayout>
  );
};

const TreatCategoryDetail = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [select, setSelect] = useState([]);

  const getSelect = useCallback(async () => {
    const res =
      await vsf?.services?.TreatController_getAllByTreatClassQto_9c1625?.({
        qto: {
          stopIndicatorIs: false,
        },
      });
    setSelect(res.data);
  }, []);

  const getDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );
    if (res.code === 200) {
      setDataSource(
        res.data.branchInstitutionList?.map((item) => ({
          label: item?.institutionName,
          value: item?.id,
        })),
      );
    }
  }, []);

  const getValue = async () => {
    const { data } =
      await vsf?.services?.TreatController_getTreatClassWithInstitutionById_552f69?.(
        {
          id: props.value.id,
        },
      );
    const [parentTreatClassName] = select?.filter(
      (item) => item?.id === data.parentTreatClassId,
    );

    const state = askSelectFormInitValue({
      array: data.treatClassDictionaryExtensionDtoList ?? [],
      select: ['branchInstitution', 'id'],
    });
    vsf.refs.TreatClassForm.setFieldsValue({
      ...data,
      parentTreatClassName: parentTreatClassName?.treatClassName ?? '顶级',
      treatClassDictionaryExtensionBtoList: state,
    });
  };

  useEffect(() => {
    getSelect();
    getDataSource();
  }, []);

  useEffect(() => {
    vsf.refs.TreatClassForm.resetFields();
    switch (props.value?.title) {
      case 'update':
        getValue();
        break;
      case 'add':
        vsf.refs.TreatClassForm.setFieldsValue({
          parentTreatClassId: props.value.id ?? null,
          parentTreatClassName: props.value?.treatClassName ?? '顶级',
        });
        break;
      default:
        vsf.refs.TreatClassForm.setFieldsValue({
          parentTreatClassId: null,
          parentTreatClassName: '顶级',
        });
    }
  }, [props.value, select]);

  const onSubmit = useFetch({
    fetch: async (params) => {
      const funcName = params.id // 条件，id是否存在，也可以改成其它条件
        ? 'TreatController_updateTreatClassDictionary_c37450'
        : 'TreatController_createTreatClassDictionary_f6601d';

      const treatClassDictionaryExtensionBtoList =
        params?.treatClassDictionaryExtensionBtoList;
      const res = await vsf?.services?.[funcName]?.({
        btoParam: {
          ...({
            ...params,
            treatClassDictionaryExtensionBtoList:
              treatClassDictionaryExtensionBtoList?.select?.map((key) => {
                const { value, ...item } =
                  treatClassDictionaryExtensionBtoList?.form?.[`form_${key}`] ||
                  {};
                return {
                  ...item,
                  branchInstitutionId: value ?? key,
                };
              }),
          } ?? {}),
        },
      });
      return res;
    },
    message: `${
      props?.title?.title === 'update' ? '修改' : '添加'
    }治疗分类成功`,
    success: () => {
      // vsf.refs.TreatClassForm.resetFields();
      vsf?.refs?.TreatClassTree?.handleReload();
      props?.onDetailChange(false);
    },
  });

  const footer = (
    <div
      className="aspirin-table-footer-button"
      style={{ justifyContent: 'flex-end', paddingTop: 24 }}
    >
      <ButtonAndKeyDown
        type="primary"
        methodKey="altKey"
        ordinaryKey="S"
        onClick={async () => {
          const value =
            await vsf.refs?.TreatClassForm?.validateFieldsReturnFormatValue();
          onSubmit(value);
        }}
      >
        保存
      </ButtonAndKeyDown>
    </div>
  );

  return (
    <Block
      // height={getScrollY(237)}
      height={getScrollYRem(16)}
      className="aspirin-block-no-padding"
      scroll={{ y: true }}
      footer={footer}
    >
      <VSControlledForm
        id="TreatClassForm"
        vsid="42355"
        labelAlign="left"
        onSubmit={onSubmit}
        onChange={() => {
          props?.onDetailChange(true);
        }}
      >
        <SearchContainer.Header title="全院区通用">
          <Divider />
          <VSFormItem
            name={['id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['parentTreatClassId']}
            label="上级类别ID"
            valueType="digit"
            style={{
              display: 'none',
            }}
            fieldProps={{}}
          />
          <VSFormLayout
            key="2"
            columnCount={2}
            labelWidth={labelNumberWidth(7)}
          >
            <VSFormItem
              name={['parentTreatClassName']}
              label="上级类别名称"
              valueType="text"
              fieldProps={{
                disabled: true,
              }}
            />
            <VSFormItem
              name={['treatClassCode']}
              label="类别编码"
              valueType="text"
              fieldProps={{
                disabled: props.value?.title === 'update',
              }}
            />

            <VSFormItem
              name={['treatClassName']}
              label="类别名称"
              valueType="text"
              rules={[{ required: true }]}
              fieldProps={{
                onChange: ({ target }) => {
                  vsf.refs.TreatClassForm.setFieldsValue({
                    inputCode: askRomanAlphabet(target.value),
                  });
                },
              }}
            />

            <VSFormItem
              name={['inputCode']}
              label="拼音输入码"
              valueType="text"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (String(value).includes(',')) {
                      return Promise.reject('多音字待确认');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </SearchContainer.Header>
        <SearchContainer.Header title="各院区使用">
          <Divider />
          <VSFormItem
            name={['treatClassDictionaryExtensionBtoList']}
            valueType="custom"
            fieldProps={{}}
            // rules={[
            //   { required: true, message: '' }
            // ]}
          >
            <SelectForm
              formProps={{
                labelAlign: 'left',
              }}
              selectLayoutProps={{
                columnCount: 1,
                // labelWidth: 130,
                labelWidth: labelNumberWidth(7),
                gutter: 50,
              }}
              label="院区"
              rules={[{ required: true, message: '请选择院区' }]}
              selectAllText="全部院区"
              dataSource={dataSource}
            >
              <Extension />
            </SelectForm>
          </VSFormItem>
        </SearchContainer.Header>
      </VSControlledForm>
    </Block>
  );
};

export default TreatCategoryDetail;
