import vsf from '@vs/vsf-boot';
import {
  Badge,
  Button,
  Checkbox,
  Drawer,
  Empty,
  message,
  Popover,
  RadioGroup,
  Table,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useGetState } from 'ahooks';
import { Select } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';
// import '../index.less';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Icon from '@/module/cpoe/Icon';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import ICON from '@/module/Icon';
import { Determine, Flex } from '@/pages/Index/components';
import Block from '@/pages/Index/components/block_no_padding';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useFrequencyEffect,
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import TabCheck from '@/pages/Index/components/tab_check';
import { getScrollYRem } from '@/utils';
const data = Array.from({ length: 20 })
  .fill({
    label: '24小时尿蛋白定量',
  })
  .map((item: any, index) => ({
    label: `${item?.label}${index}`,
    value: index,
  }));

const mock = [
  { label: '个人常用', value: 'PERSONAL' },
  { label: '科室常用', value: 'DEPARTMENT' },
];

const Price = ({ recode }) => {
  const [favorite] = useOnChangeValue(false);
  const onCollect = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
          {
            favoriteType: 'PERSONAL',
            itemClass: 'TREAT',
            itemId: params?.clinicItemId,
          },
        );
      return res;
    },
    success: () => {
      favorite?.onChange?.(true);
    },
    message: '收藏',
  });
  const onCancelCollect = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0?.(
          {
            itemClass: 'TREAT',
            favoriteType: 'PERSONAL',
            itemId: params?.clinicItemId,
          },
        );
      return res;
    },
    message: '取消收藏',
    success() {
      favorite?.onChange?.(false);
    },
  });
  useEffect(() => {
    favorite?.onChange(recode?.isFavorite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recode?.isFavorite]);
  return (
    <Flex>
      {favorite?.value ? (
        <Icon
          size={24}
          style={{ color: '#ffa77e' }}
          type="icon-shoucang1"
          onClick={(event: any) => {
            event?.stopPropagation?.();
            onCancelCollect(recode);
          }}
        />
      ) : (
        <Icon
          type="icon-meiyoushoucang"
          size={24}
          style={{ color: 'rgb(174, 176, 179)' }}
          onClick={(event: any) => {
            event?.stopPropagation();
            onCollect(recode);
          }}
        />
      )}
    </Flex>
  );
};

const Main = (props, ref) => {
  const { currentTab, open, onClose, defaultValue, allPrice } = props;
  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};

  const tableRef = useRef<any>();

  const [init, setInit] = useState(false);

  const [currentInfo, setCurrentInfo] = useState<any>({
    purpose: '明确诊断',
    reportType: '图文报告',
  });
  const [checkList, setCheckList, getCheckList, restCheckList] =
    useGetResetInitState<any[]>([]);

  const [radio] = useOnChangeValue('PERSONAL', (favoriteTypeIs) => {
    if (secondActive)
      getList({ treatSubClassIdIs: secondActive, favoriteTypeIs });
    if (firstActive) getList({ treatClassIdIs: firstActive, favoriteTypeIs });
    return favoriteTypeIs;
  });

  const [firstList, setFirstList] = useState([]);
  const [firstActive, setFirstActive] = useGetState('');

  const [secondList, setSecondList] = useState([]);
  const [secondActive, setSecondActive] = useState('');

  const [thirdList, setThirdList] = useState([]);
  const [thirdActive, setThirdActive] = useState('');

  const [list, setList] = useGetResetInitState([]);

  const getCheckDetail = async (data) => {
    const res =
      await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
        {
          clinicItemId: data?.clinicItemId,
        },
      );
    return res?.data;
  };

  const handleCheckItem = async (data) => {
    const detail = await getCheckDetail(data);
    const target = getCheckList();
    const v = target?.find(
      (item) => item?.disposalOrder?.clinicItemId === data?.clinicItemId,
    );
    if (!v) {
      const performDepartment = await onPerformDepartment?.(data?.clinicItemId);
      setCheckList((value) => {
        return [
          ...value,
          {
            clinicItemId: data?.clinicItemId,
            performDepartment,
            disposalOrder: {
              ...data,
              detailInfo: detail,
              itemName: data?.clinicItemName,
            },
          },
        ];
      });
    } else {
      setCheckList(
        [...target]?.filter(
          (v) => v?.disposalOrder?.clinicItemId !== data?.clinicItemId,
        ),
      );
    }
  };
  // 计算总价
  const onSetAllPrice = useCallback(
    (_value) => {
      if (_value?.length) {
        const all = askTotalAmount(
          (_value ?? [])?.map((item) => [
            item?.disposalOrder?.amount ?? 1,
            item?.disposalOrder?.price ?? 0,
          ]),
          2,
        );
        allPrice?.onChange?.(all);
      } else {
        allPrice?.onChange?.(0);
      }
    },
    [allPrice],
  );
  useEffect(() => onSetAllPrice(checkList), [checkList, onSetAllPrice]);
  const getPart = useCallback(
    async (value) => {
      const res =
        await vsf.services?.TreatController_getAllByQueryTreatSubClassQto_60b9c0?.(
          {
            qto: {
              from: 0,
              size: 999,
              favoriteTypeIs: radio?.value,
              ...value,
            },
          },
        );
      setSecondList(res?.data?.toReversed());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [radio?.value],
  );

  const getList = useCallback(
    async (value) => {
      const res =
        await vsf.services?.TreatAndOtherItemController_queryTreatItems_de7128?.(
          {
            qry: {
              favoriteTypeIs: radio?.value,
              ...value,
              size: 999,
              from: 0,
            },
          },
        );
      setThirdList(res?.data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [radio?.value],
  );
  const handleChooseFirst = useCallback((data) => {
    setFirstActive(data?.id);
    if (data?.id) {
      getPart({ parentTreatClassIdIs: data?.id });
      getList({ treatClassIdIs: data?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChooseSecond = (data) => {
    setSecondActive(data?.id);
  };

  const getCategory = useCallback(
    async (currentTab) => {
      const method = {
        async TREAT() {
          const res =
            await vsf?.services?.TreatController_getAllByQueryTreatClassQto_274867?.();
          setFirstList(res?.data?.toReversed());
          handleChooseFirst(res?.data?.toReversed()?.[0]);
          return res?.data?.toReversed() ?? [];
        },
        async OTHER() {
          const res =
            await vsf.services?.TreatAndOtherItemController_queryOtherItems_998cf7?.(
              {
                qry: {},
              },
            );
          setThirdList(res?.data?.result);
          // setFirstActive(data?.[0]?.id);
          return res?.data?.result?.toReversed() ?? [];
        },
      };

      if (currentTab) {
        const data = await method[currentTab]?.();
      }
    },
    [handleChooseFirst],
  );

  const onPerformDepartment = async (clinicItemId) => {
    const resolve =
      await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
        {
          clinicItemId,
        },
      );
    if (resolve?.code === 200) {
      const { performDepartmentId: id, performDepartmentName: departmentName } =
        (resolve?.data || {}) as any;
      if (id && departmentName) {
        return {
          id,
          departmentName,
        };
      } else {
        return undefined;
      }
    }
  };

  useEffect(() => {
    getCategory(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    setSecondList([]);
    setThirdList([]);
    handleClearWithFirst();
    setFirstActive('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleClearWithFirst = () => {
    setSecondActive('');
    setThirdActive('');
  };
  const handleClearWithSecond = () => {
    setThirdActive('');
  };

  useEffect(() => {
    if (secondActive) {
      getList({ treatSubClassIdIs: secondActive });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondActive]);

  const onSubmit = useCallback(async () => {
    await vsf?.refs?.disposeTable
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    return getCheckList();
  }, [getCheckList]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onSubmit,
      };
    },
    [onSubmit],
  );

  const empty = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  // eslint-disable-next-line react/display-name
  const EmptyMemo: React.FC<{ description: string }> = memo((props) => {
    const { description } = props;
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 60 }}
        description={description}
      />
    );
  });

  const columns = [
    {
      title: '收费项目名称',
      dataIndex: ['currentPriceItem', 'itemName'],
    },
    {
      title: '单价（元）',
      dataIndex: ['currentPriceItem', 'price'],
    },
    {
      title: '单位',
      dataIndex: ['currentPriceItem', 'unit'],
    },
    {
      title: '数量',
      dataIndex: 'amount',
    },
    {
      title: '金额',
      dataIndex: 'sum',
      render: (...[, record]) =>
        askFixed(
          (record?.amount ?? 0) * (record?.currentPriceItem?.price ?? 0),
          2,
        ),
    },
  ];

  useAsyncEffect(async () => {
    const res =
      await vsf.services?.TreatAndOtherItemController_queryTreatItems_de7128?.({
        qry: {
          size: 999,
          from: 0,
          itemClassIn: ['TREAT', 'OTHER'],
        },
      });
    // console.log(res?.data, 'res?.data');
    setList(res?.data ?? []);
  }, []);

  return (
    <div className="cpoe-exam">
      {/* <div className="header"></div> */}
      <div className="main">
        <Block
          title="项目列表"
          className="left"
          style={{
            background: '#fff',
            padding: 0,
          }}
          extra={
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: 20,
              }}
            >
              <Select
                style={{
                  flex: '0 0 50%',
                }}
                placeholder="请输入项目名称或首字母"
                showSearch
                filterOption={(...arr) => askSearch(...arr, 'clinicItemName')}
                onChange={(...[, value]) => {
                  handleCheckItem(value);
                }}
                options={list}
                fieldNames={{
                  label: 'clinicItemName',
                  value: 'clinicItemId',
                }}
              />
              <RadioGroup
                optionType="button"
                {...radio}
                dataSource={mock}
                style={{
                  marginLeft: '12px',
                }}
              />
            </div>
          }
        >
          <div className="left-container">
            {currentTab === 'TREAT' && (
              <>
                <div className="first">
                  {firstList?.map((item: any, index) => (
                    <div
                      key={index}
                      className={classnames('item', {
                        'active-item': firstActive === item?.id,
                      })}
                      onClick={() => {
                        handleChooseFirst(item);
                        handleClearWithFirst();
                      }}
                    >
                      <span className="label">{item?.treatClassName}</span>
                      <Icon type="icon-you" size={22} />
                    </div>
                  ))}
                </div>
                <Determine condition={Boolean(secondList?.length)}>
                  <div
                    className="second"
                    style={secondList?.length ? {} : empty}
                  >
                    {secondList?.length ? (
                      secondList?.map((item: any, index) => {
                        return (
                          <div
                            key={index}
                            className={classnames('item', {
                              'active-item': secondActive === item?.id,
                            })}
                            onClick={() => {
                              handleChooseSecond(item);
                              handleClearWithSecond();
                            }}
                          >
                            <span className="label">
                              {item?.treatClassName}
                            </span>
                            <Badge />
                          </div>
                        );
                      })
                    ) : (
                      <EmptyMemo description="暂无相关治疗子类" />
                    )}
                  </div>
                </Determine>
              </>
            )}
            <div className="third" style={thirdList?.length ? {} : empty}>
              {thirdList?.length ? (
                thirdList?.map((item: any, index) => {
                  const value =
                    checkList?.findIndex(
                      (citem) =>
                        citem?.disposalOrder?.clinicItemId ===
                        item?.clinicItemId,
                    ) >= 0;
                  return (
                    <div
                      key={index}
                      className="item"
                      onClick={() => handleCheckItem(item)}
                    >
                      <div className="left">
                        <Checkbox value={value} />
                        <span className="label">{item?.clinicItemName}</span>
                      </div>
                      <div className="right">
                        <Price recode={item} />
                        <Popover
                          color="#fff"
                          style={{
                            width: 'auto',
                          }}
                          trigger="hover"
                          title="收费详情"
                          content={
                            <Table
                              pagination={false}
                              columns={columns}
                              dataSource={item?.currentPriceItem}
                            />
                          }
                        >
                          <Icon type="icon-Frame-14" size={18} />
                        </Popover>
                      </div>
                    </div>
                  );
                })
              ) : (
                <EmptyMemo description="暂无相关治疗项目" />
              )}
            </div>
          </div>
        </Block>
        <div className="right">
          <div className="first">
            <Block
              title="处置项目"
              extra={
                <Button type="primary" onClick={() => restCheckList()}>
                  清空
                </Button>
              }
            >
              <VSTable
                id="disposeTable"
                ref={tableRef}
                className="aspirin-table aspirin-table-nopadding"
                editable={{
                  editType: 'multiple',
                  columnProps: {
                    width: 70,
                  },
                  deleteText: <ICON type="icon-shanchu" size={24} />,
                }}
                bordered
                rowKey="clinicItemId"
                value={checkList}
                onChange={(_value) => {
                  if (_value?.length) {
                    const all = askTotalAmount(
                      (_value ?? [])?.map((item) => [
                        item?.disposalOrder?.amount ?? 0,
                        item?.disposalOrder?.price ?? 0,
                      ]),
                      2,
                    );
                    allPrice?.onChange?.(all);
                  } else {
                    allPrice?.onChange?.(0);
                  }
                  setCheckList(_value);
                }}
                pagination={false}
                scroll={{
                  y: getScrollYRem(18),
                }}
                style={{
                  overflowY: 'auto',
                }}
              >
                <VSTableColumn
                  dataIndex={['disposalOrder', 'itemName']}
                  title="项目名称"
                  valueType="select"
                  fieldProps={{}}
                  preview
                />

                <VSTableColumn
                  dataIndex={['disposalOrder', 'amount']}
                  title="数量"
                  valueType="digit"
                  fieldProps={{
                    width: '100%',
                  }}
                  initialValue={1}
                  formItemProps={{
                    rules: [
                      {
                        validator(_, value) {
                          if ([undefined, null].includes(value)) {
                            return Promise.reject('请输入数量');
                          } else if (!Number.isInteger(value) || value < 1) {
                            return Promise.reject('数量必须是正整数');
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ],
                  }}
                />

                <VSTableColumn
                  dataIndex={['performDepartment']}
                  title="执行科室"
                  valueType="select"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  fieldNames={{
                    label: 'departmentName',
                    value: 'id',
                  }}
                  dataSource={async (_, record) => {
                    const res =
                      await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
                        {
                          clinicItemId: record?.clinicItemId,
                        },
                      );
                    return res?.data?.clinicItemDictionaryVo?.clinicPerformDepartmentDtoList?.map(
                      (item) => ({
                        departmentName: item?.performDepartment?.departmentName,
                        id: item?.performDepartment?.id,
                      }),
                    );
                  }}
                />
              </VSTable>
            </Block>
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = forwardRef(Main);

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [currentTab, setCurrentTab] = useState('TREAT');
  const [currentTabInfo, setCurrentTabInfo] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [testItems, setTestItems] = useState<any>([
    {
      label: '治疗',
      key: 'TREAT',
      value: 'TREAT',
    },
    {
      label: '其他',
      key: 'OTHER',
      value: 'OTHER',
    },
  ]);

  const onChange = (v, record) => {
    setCurrentTab(v);
    setCurrentTabInfo(record);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    setData(data ?? undefined);
    setCurrentTab(v?.categoryType ?? 'TREAT');
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));
  const content = useRef<any>({
    onSubmit: async () => {},
  });

  const [allPrice] = useOnChangeValue(0);

  const Submit = async () => {
    const value = await content?.current.onSubmit();
    const state: any = Object?.values(value);
    const list = state?.map((item) => {
      const allPrice = askTotalAmount(
        item?.disposalOrder?.currentPriceItem?.map((item) => [
          item?.amount,
          item?.currentPriceItem?.price,
        ]),
        2,
      );
      return {
        orderClass: currentTab,
        orderStatus: 'OPEN',
        submitDateTime: dayjs()?.valueOf(),
        id: 'create' + Math?.random(),
        orderText: item?.disposalOrder?.clinicItemName,
        uuid: getUUID(),
        performDepartment: item?.performDepartment,
        disposalOrder: {
          itemId: item?.disposalOrder?.clinicItemId,
          itemName: item?.disposalOrder?.clinicItemName,
          amount: item?.disposalOrder?.amount ?? 1,
        },
        allPrice: askFixed(Number(allPrice) * item?.disposalOrder?.amount, 2),
      };
    });
    onSubmit(list);
    onClose();
  };

  const Footer = () => {
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div className="info">
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥ {allPrice?.value}元</span>
        </div>
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={Submit}
          style={{
            marginLeft: 12,
          }}
        >
          确定
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      className="aspirin-drawer-border-bottom"
      title={
        <TabCheck
          onChange={onChange}
          dataSource={testItems}
          value={currentTab}
        />
      }
      placement="right"
      onClose={onClose}
      destroyOnClose
      open={open}
      width={'70%'}
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      footer={<Footer />}
      closeIcon={false}
    >
      <Content
        data={data}
        open={open}
        currentTab={currentTab}
        onSubmit={onSubmit}
        onClose={onClose}
        allPrice={allPrice}
        ref={content}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
