import { createServices } from '@vs/vsf-boot';
export default createServices({
  WardDebtManageController_getWardDebtManage_2041a3: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-ward-debt-manage',
    parameterFomatter: (data?: {
      qto: InpVisitWardDebtManageQtoClinicInpVisitPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InpVisitWardDebtManageVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  WardDebtManageController_freezeOrUnfreezePatientBillingRecord_d1b801: {
    method: 'post',
    url: '/api/clinic-inp-visit/freeze-or-unfreeze-patient-billing-record',
    parameterFomatter: (data?: { id: number; isFreeze: boolean }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoClinicInpVisitEntranceWebVoInpvisitwarddebtmanagevo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
