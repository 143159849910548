import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeItemController_batchSaveMedicalTechnologyChargeItemForDepartment_c103e1:
    {
      method: 'post',
      url: '/api/medical-technology/batch-save-medical-technology-charge-item-for-department',
      parameterFomatter: (data?: {
        medicalTechnologyChargeItemBtoList: CreateOrUpdateMedicalTechnologyChargeItemBtoMedicalTechnologyServiceBto[];
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeItemWithPriceItemVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
  MedicalTechnologyChargeItemController_getChargePriceItemList_3c6504: {
    method: 'post',
    url: '/api/medical-technology/get-charge-price-item-list',
    parameterFomatter: (data?: {
      qto: MedicalTechnologyChargeItemBaseDtoQtoMedicalTechnologyPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalTechnologyChargeItemWithPriceItemVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeTypeAndParentCode_27dfb1: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeTypeAndParentCode',
    parameterFomatter: (data?: {
      var: BaseCodeCodeTypeParentCodeEoBaseDictionaryPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
});
