import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Checkbox,
  DateRangePicker,
  Radio,
  RadioGroup,
  Select,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { handelEnums } from '@/utils/index';

const Index = (props) => {
  const { value, dataSource, onChange } = props;

  const [filterValue, setFilterValue] = useState(1);
  const [checkValue, setcheckValue] = useState(['finished']);
  console.log(vsf.stores.dbenums.enums.EXAM_RESULT_STATUS_DICT);

  const change = (e) => {
    setFilterValue(e?.target?.value);
  };

  return (
    <div className="checked_list">
      <div className="checked_list_content hideScroll">
        {dataSource?.map((item, index) => {
          return (
            <div
              key={index}
              className={classnames(
                'checked_list_content_item',
                item?.orderId === value
                  ? 'checked_list_content_item_active'
                  : '',
              )}
              onClick={() => {
                onChange?.(item);
              }}
            >
              <div className="checked_list_content_item_time dinpro-medium">
                <span>
                  {dayjs(item?.submitDateTime).format('YYYY-MM-DD HH:mm')}
                </span>
                {item?.secondTime && (
                  <span className="checked_list_content_item_time_line"></span>
                )}
                {item?.secondTime && (
                  <span>
                    {dayjs(item?.secondTime).format('YYYY-MM-DD HH:mm')}
                  </span>
                )}
              </div>
              <div className="checked_list_content_item_classify_name">
                {item?.orderName?.[0]}
              </div>
              <div className="checked_list_content_item_doctor_name">
                <div>
                  <span className="checked_list_content_item_doctor_name_label">
                    申请医师：
                  </span>
                  <span>{item?.doctorName}</span>
                </div>
                <div
                  style={{ visibility: item?.status ? 'visible' : 'hidden' }}
                  className="checked_list_content_item_doctor_name_text"
                >
                  {handelEnums(
                    vsf.stores.dbenums.enums.EXAM_RESULT_STATUS_DICT,
                    item?.status,
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
