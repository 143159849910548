// table
export const convertQueryErpVisitTransferOutListVoByEWardIdRes2ErpVisitTransferOutTable =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.erpVisitId = _.get(d, 'id');
        obj.preDischargeIndicator = _.get(d, 'preDischargeIndicator'); // TODO 请确认下行字段是否符合逻辑
        obj.bedLabel = _.get(d, 'bed.bedLabel'); // TODO 请确认下行字段是否符合逻辑
        obj.name = _.get(d, 'patient.name'); // TODO 请确认下行字段是否符合逻辑
        obj.displayId = _.get(d, 'patient.displayId');
        obj.status = _.get(d, 'status');
        return obj;
      }) ?? []),
    );
    return output;
  };
//   form查

export const convertGetErpVisitTransferOutDetailVoByErpVisitTransferOutDetailQtoRes2ErpVisitTransferOutDetailForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {}; // TODO 请确认下行字段是否符合逻辑
    output.displayId = _.get(input, 'data.patient.displayId'); // TODO 请确认下行字段是否符合逻辑
    output.bedLabel = _.get(input, 'data.bed.bedLabel'); // TODO 请确认下行字段是否符合逻辑
    output.name = _.get(input, 'data.patient.name'); // TODO 请确认下行字段是否符合逻辑
    output.gender = _.get(input, 'data.patient.gender'); // TODO 请确认下行字段是否符合逻辑
    output.age = _.get(input, 'data.patient.age'); // TODO 请确认下行字段是否符合逻辑
    output.birthAddress = _.get(input, 'data.patient.birthAddress'); // TODO 请确认下行字段是否符合逻辑
    output.identityCode = _.get(input, 'data.patient.identityCode'); // TODO 请确认下行字段是否符合逻辑
    output.defaultChargeType = _.get(input, 'data.patient.defaultChargeType');
    output.contactPersonName = _.get(input, 'data.contactPersonName');
    output.contactPersonPhone = _.get(input, 'data.contactPersonPhone');
    output.arriveHospitalDateTime = _.get(input, 'data.arriveHospitalDateTime');
    output.nurseAcceptDateTime = _.get(input, 'data.nurseAcceptDateTime');
    output.patientCondition = _.get(input, 'data.patientCondition');
    output.nursingClass = _.get(input, 'data.nursingClass'); // TODO 请确认下行字段是否符合逻辑
    output.staffName = _.get(input, 'data.attendingDoctor.staffName');
    output.diagnosisDescription = _.get(input, 'data.diagnosisDescription');
    output.notChargeTally = _.get(input, 'data.notChargeTally');
    output.notChargeLab = _.get(input, 'data.notChargeLab');
    output.notPerformExam = _.get(input, 'data.notPerformExam');
    output.notOperateDrugDescription = _.get(
      input,
      'data.notOperateDrugDescription',
    );
    output.notTakeDischargeDrug = _.get(input, 'data.notTakeDischargeDrug');
    output.notSubmitOrder = _.get(input, 'data.notSubmitOrder');
    output.notPerformOrder = _.get(input, 'data.notPerformOrder');
    output.notHandleReturnOrder = _.get(input, 'data.notHandleReturnOrder');
    output.preDischargeDateExpected = _.get(
      input,
      'data.preDischargeDateExpected',
    );
    output.dischargeWay = _.get(input, 'data.dischargeWay');
    output.outPatientDisease = _.get(input, 'data.outPatientDisease');
    output.outPatientDosc1 = _.get(input, 'data.outPatientDosc1');
    return output;
  };
// form 保存
export const convertErpPatientInTransferAcceptForm2AcceptErpPatientFuncFlowExecutorReq =
  (input) => {
    console.log(input, 'inputinput');
    const _ = window._ ?? {};
    const output = {};
    output.createDiagnosisRecordBtoList = [];
    output.updateErpVisitForTransferBto = {};
    output.updateErpVisitForTransferBto.id = _.get(input, 'erpVisitId');
    output.updateErpVisitForTransferBto.nurseAcceptDateTime = _.get(
      input,
      'nurseAcceptDateTime',
    );
    output.updateErpVisitForTransferBto.bedId =
      _.get(input, 'bed.id') ?? _.get(input, 'bed');
    output.updateErpVisitForTransferBto.nursingClass = _.get(
      input,
      'nursingClass',
    );
    output.updateErpVisitForTransferBto.isolationIndicator = _.get(
      input,
      'isolationIndicator',
    );
    output.updateErpVisitForTransferBto.fastingIndicator = _.get(
      input,
      'fastingIndicator',
    );
    output.updateErpVisitForTransferBto.patientCondition = _.get(
      input,
      'patientCondition',
    );
    output.updateErpVisitForTransferBto.attendingDoctorId = _.get(
      input,
      'attendingDoctor.id',
    );
    output.createOrUpdatePatientBillingRecordBto = {};
    // output.createOrUpdatePatientBillingRecordBto.prePaymentAmount = _.get(
    //   input,
    //   'prePaymentBalance',
    // ); // Bigdecimal 预交金金额
    return output;
  };
