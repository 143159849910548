import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSTable } from '@vs/vsf-kit';
import React from 'react';
import Icon from '@/module/Icon';

const PatientRelationships = (props) => {
  return (
    <VSTable
      value={props?.value}
      scroll={{
        x: 1500
      }}
      editable={{
        saveOnClickOutside: true,
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        columnProps: {
          width: 94,
        },
      }}
      vsid="95652"
      id="vSTablePatientRelationships"
      rowClick={{
        rowClickType: {
          action: 'double-click',
          type: 'edit-save'
        }
      }}
      onChange={(_value) => {
        props?.onChange?.(_value)
      }}

    >
      <VSTableColumn
        width={60}
        title=""
        valueType="text"
        render={(_, __, index) => index + 1}
        preview
      />

      <VSTableColumn
        width={210}
        dataIndex={['name']}
        title="监护人姓名"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入监护人姓名' },
            { message: '姓名长度不合法', type: 'string', min: 0, max: 1024 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={188}
        dataIndex={['relationshipType']}
        title="关系"
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
        fieldProps={{}}
        formItemProps={{}}
      />

      <VSTableColumn
        width={182}
        dataIndex={['identificationClass']}
        title="证件类型"
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.IDENTIFICATION_CLASS_DICT}
        fieldProps={{}}
      />

      <VSTableColumn
        width={398}
        dataIndex={['identificationNumber']}
        title="证件号"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '证件号长度不合法', type: 'string', min: 0, max: 200 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={212}
        dataIndex={['phoneNumber']}
        title="手机号"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '手机号长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
          label: undefined,
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={212}
        dataIndex={['wechatAccount']}
        title="微信号"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '微信号长度不合法', type: 'string', min: 0, max: 100 },
          ],
        }}
        fieldProps={{}}
      />
    </VSTable>
  );
};
export default definePage(PatientRelationships);
