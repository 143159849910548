import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpVisitController_queryErpVisitTransferOutListVoByEWardId_286418: {
    method: 'post',
    url: '/api/clinic-erp-visit/query-erp-visit-transfer-out-list-vo-by-e-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpVisitTransferOutListVoClinicErpVisitEntranceWebVo[],
    ) => data,
  },
});
