import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const DrugManger = (props) => {
  return (
    <VSControlledForm vsid="75421">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={1}>
        <VSFormItem
          name={['drug', 'drugCode']}
          label="药品编码"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'dosageFormCode']}
          label="剂型"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 10 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'administration']}
          label="常用给药途径"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 256 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'forbiddenAdministration']}
          label="禁止给药途径"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 256 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'frequency']}
          label="默认执行频率描述"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 16 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'classCode']}
          label="药理分类编码"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'drugIndicator']}
          label="药品类别标志"
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'toxicCode']}
          label="毒理分类编码"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'typeOneIncision']}
          label="一类切口预防可用标签"
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'skinTestType']}
          label="皮试属性"
          valueType="select"
          dataSource={[
            { label: '强制皮试', value: 'FORCE' },
            { label: '建议皮试', value: 'ADVISE' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'skinTestClass']}
          label="皮试类型"
          valueType="select"
          dataSource={[
            { label: '原液皮试', value: 'RAW_LIQUID' },
            { label: '非原液皮试', value: 'NOT_RAW_LIQUID' },
            { label: '二者皆可', value: 'ALL' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'antibioticTypeCode']}
          label="抗菌药级别分类代码"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'ddd']}
          label="DDD值"
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'coldIndicator']}
          label="冷藏药标志"
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['drug', 'herbType']}
          label="中药类型"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 30 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['highElectrolyteIndicator']}
          label="是否高浓度电解质"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['seeminglyDrugIndicator']}
          label="是否看似药品"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['temperature']}
          label="存贮温度"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['soundDrugIndicator']}
          label="是否听似药品"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['storageInDarkIndicator']}
          label="贮存避光"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['useInDark']}
          label="使用避光"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['babyUseForbidden']}
          label="新生儿童禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['childUseForbidden']}
          label="儿童禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['pregnantUseForbidden']}
          label="孕妇禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['lactationUseForbidden']}
          label="哺乳期禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['elderUseForbidden']}
          label="老人禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['hepaticInsufficiencyUseForbidden']}
          label="严重肝功能不全禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['renalInsufficiency1UseForbidden']}
          label="肾功能不全50-30umol/禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['renalInsufficiency2UseForbidden']}
          label="肾功能不全50-15umol/禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['renalInsufficiency3UseForbidden']}
          label="肾功能不全15umol/禁忌使用"
          valueType="select"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['ppiIndicator']}
          label="PPI"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['adjuvantDrugIndicator']}
          label="辅助用药"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['doubleSignatureIndicator']}
          label="是否双签名"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['herbSimilarDrugType']}
          label="中药同类药品类型"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 10 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['preparationIndicator']}
          label="是否自制制剂"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['preparationType']}
          label="制剂类型"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['accountBookClass']}
          label="账簿类别"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['herbInjectionIndicator']}
          label="是否中药注射剂"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['valuableHerbIndicator']}
          label="是否中药贵重药品"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['decoctionPieceWaterAbsorption']}
          label="饮片吸水率"
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['materialIndicator']}
          label="辅料"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['antitumorLevel']}
          label="抗肿瘤药物级别"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['machineGrainIndicator']}
          label="是否机配颗粒"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />

        <VSFormItem
          name={['multipleSpecificationIndicator']}
          label="是否多规 "
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(DrugManger);
