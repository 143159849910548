import vsf from '@vs/vsf-boot';
export const queryScrollWardPatientList = async (value) => {
  const res =
    await vsf?.services?.PatientInHosptialController_queryScrollWardPatientList_346269?.(
      {
        qto: {
          ...value,
        },
      },
    );
  return (res?.data?.result ?? res?.data ?? [])?.map((item) => ({
    ...item,
    id: item?.patient?.id,
    name: item?.bed?.bedLabel + '-' + item?.patient?.name,
    value: item?.patient?.id,
    label: item?.bed?.bedLabel + '-' + item?.patient?.name,
  }));
};
// **病区退药申请：查询待退药列表
export const getPlanAndDispenseForInpRefundDrugApply = async (params) => {
  // if (!inpVisitId) {
  //   return {
  //     data: [],
  //     total: 0,
  //   };
  // }
  const res =
    await vsf?.services?.InpRefundDispenseController_getPlanAndDispenseForInpRefundDrugApply_f6c86d?.(
      {
        qto: {
          from: params?.pagination?.from ?? 0,
          size: params?.pagination?.size ?? 9999,
          ...params?.search,
          // inpVisitIdIs: inpVisitId,
          // ...search?.current,
          ...params,
        },
      },
    );
  return {
    data: onSpliced?.(res),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
const onSpliced = (input) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'id');
      obj.drugPrescriptionDispenseDetailId = _.get(
        d,
        'drugPrescriptionDispenseDetailId',
      );
      obj.orderPerformPlanRecordId = _.get(d, 'orderPerformPlanRecordId');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.bedLabel = _.get(d, 'inpVisit.patientInHospital.bed.bedLabel');
      obj.name = _.get(d, 'patient.name');
      obj.gender = _.get(d, 'patient.gender');
      obj.repeatIndicator = _.get(d, 'order.repeatIndicator');
      obj.orderText = _.get(d, 'order.orderText');
      obj.amountAndUnit = _.get(d, 'amountAndUnit');
      obj.refundAmount = _.get(d, 'refundAmount');
      obj.planDateTime = _.get(d, 'planDateTime');
      obj.performStatus = _.get(d, 'performStatus');
      obj.administration = _.get(d, 'orderDrug.administration');
      obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
      obj.packageUnit = _.get(d, 'orderDrug.packageUnit');
      obj.packageSpecification = _.get(d, 'orderDrug.packageSpecification');
      obj.departmentName = _.get(d, 'performDepartment.departmentName');
      obj.billDetail =
        _.get(d, 'inpBillingDetail.amount') -
        _.get(d, 'inpBillingDetail.amountRefund');
      return obj;
    }) ?? []),
  );
  return output;
};
