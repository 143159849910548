import { createServices } from '@vs/vsf-boot';
export default createServices({
  StaffDetailDtoController_getById_6ecdf0: {
    method: 'post',
    url: '/api/user-staff/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: StaffVoUserStaffEntranceWebVo) => data,
  },
  UserStaffController_getDoctorDetail_9057e9: {
    method: 'post',
    url: '/api/user-staff/get-doctor-detail',
    parameterFomatter: (data?: { staffId: number }) => data,
    responseFormatter: (_, __, data?: DoctorDetailVoUserStaffEntranceWebVo) =>
      data,
  },
  StaffBOController_updateDoctorInfo_907e11: {
    method: 'post',
    url: '/api/user-staff/update-doctor-info',
    parameterFomatter: (data?: {
      btoParam: UpdateDoctorInfoBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffVoUserStaffEntranceWebVo) => data,
  },
  DepartmentDtoController_getAllByDepartmentQto_1c2a91: {
    method: 'post',
    url: '/api/organization/get-all-by-department-qto',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de: {
    method: 'post',
    url: '/api/organization/get-all-by-department-in-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
