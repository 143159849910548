import React from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import Container from '@/module/Container';
import Block from '@/module/Block';
import SupplyTable from './table';

import './index.less';

const StockSupplyList = (props) => {
  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            padding: '0',
            bgColor: '#fff',
          },
        ],
      }}
    >
      <Block
        style={{ padding: 0 }}
        className="drug_stock_supply_manage_wapper common_lfc_style"
      >
        <SupplyTable />
      </Block>
    </Container>
  );
};
export default definePage(StockSupplyList);
