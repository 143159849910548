import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStocktakingTypeController_saveDrugStocktakingTypes_f2d3f1: {
    method: 'post',
    url: '/api/drug-storage/save-drug-stocktaking-types',
    parameterFomatter: (data?: {
      drugStocktakingTypeDtos: DrugStocktakingTypeDtoDrugStorageManagerDto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStocktakingTypeController_getByDrugStorageProfileId_77aeb2: {
    method: 'post',
    url: '/api/drug-storage/get-by-drug-storage-profile-id',
    parameterFomatter: (data?: { drugStorageProfileId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStocktakingTypeVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStocktakingTypeController_getDrugStocktakingList_9a0124: {
    method: 'post',
    url: '/api/drug-storage/get-drug-stocktaking-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStocktakingTypeVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStocktakingTypeController_deleteDrugStocktakingType_a36bfa: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-stocktaking-type',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
