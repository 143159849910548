import React, { useEffect, useState } from 'react';
import './index.less';
import { Divider, Popover } from '@vs/vsf-kit';
import PopoverCart from './PopoverCart';
export function TableCart(props) {
  const { wasSelect, datasource, onChangeCartItem, data } = props;

  return (
    <div>
      <div
        onClick={() => {
          if (!wasSelect) {
            onChangeCartItem(data, datasource);
          } else {
            onChangeCartItem(undefined, undefined);
          }
        }}
        className="register-table-inner-cart"
      >
        <div className="register-table-inner-cart-title">
          <div className="register-table-inner-cart-title-left">
            {data?.timeDescription === 'PM' ? '下午' : '上午'}
          </div>
          <div className="register-table-inner-cart-title-center">
            {
              data?.clinicRegister?.clinicRegisterDefine?.clinicDepartment
                ?.departmentName
            }
          </div>
          <div className="register-table-inner-cart-title-right">
            {`${data?.registerNumber} / ${data?.totalLimit}(+${
              data?.addLimit || 0
            })`}
          </div>
        </div>
        <Divider />
        <div className="register-table-inner-cart-content">
          {data?.clinicRegister?.clinicRegisterDefine?.clinicLabel}
        </div>
        <div className="register-table-inner-cart-footer">
          <div>
            {
              data?.clinicRegister?.clinicRegisterDefine?.clinicRegisterType
                ?.clinicType
            }
          </div>
          <div>
            <Popover
              content={
                <PopoverCart
                  doctor={data?.clinicRegister?.clinicRegisterDefine?.doctor}
                />
              }
            >
              <a style={{ textDecoration: 'underline' }}>
                {data?.clinicRegister?.clinicRegisterDefine?.doctor?.staffName}
              </a>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}
export function SelectTableCart(props) {
  const { selectNumberType, datasource, appointmentData } = props;
  // useEffect(() => {
  //   console.log('lhlhlh');
  //   //整个数组
  //   console.log(appointmentData);

  // });
  return (
    <div className="register-table-inner-select-cart">
      <div className="register-table-inner-cart-select-title">
        <div className="register-table-inner-cart-title-select-left">
          {selectNumberType
            ? selectNumberType?.timeDescription === 'PM'
              ? '下午'
              : '上午'
            : appointmentData?.timeDescription === 'PM'
            ? '下午'
            : '上午'}
        </div>
        <div className="register-table-inner-cart-select-content">
          {datasource?.outpSpecialName}
        </div>
      </div>
      {/* <Divider /> */}
      <div className="register-table-inner-cart-select-footer">
        <div className="cart-footer-type">
          {selectNumberType
            ? selectNumberType?.clinicRegister?.clinicRegisterDefine
                ?.clinicRegisterType?.clinicType
            : appointmentData?.clinicRegister?.clinicRegisterDefine
                ?.clinicRegisterType?.clinicType}
          {/* (西医) */}
        </div>
        <div className="cart-footer-name">
          {selectNumberType
            ? selectNumberType?.clinicRegister?.clinicRegisterDefine?.doctor
                ?.staffName
            : appointmentData?.clinicRegister?.clinicRegisterDefine?.doctor
                ?.staffName}
        </div>
      </div>
    </div>
  );
}
