import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getInpAdmissionApplyWithPatientAndDepartmentDetailInfoVoById_b7f8b0:
    {
      method: 'post',
      url: '/api/clinic-inp-admission/get-inp-admission-apply-with-patient-and-department-detail-info-vo-by-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpAdmissionApplyWithPatientAndDepartmentDetailInfoVoClinicInpAdmissionEntranceWebVo,
      ) => data,
    },
  ClinicInpAdmissionController_updatePrintDate_a67c72: {
    method: 'post',
    url: '/api/clinic-inp-admission/update-print-date',
    parameterFomatter: (data?: {
      btoParam: UpdatePrintDateBtoClinicInpAdmissionServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_getAllByInstitutionListQto_a59f48: {
    method: 'post',
    url: '/api/organization/get-all-by-institution-list-qto',
    parameterFomatter: (data?: {
      qto: InstitutionListQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoClinicInpAdmissionEntranceWebVoInpadmissionapplywithpatientanddepartmentdetailinfovo[],
    ) => data,
  },
});
