import '@/pages/Index/components/order_table/index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message, RadioGroup } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Loading from '@/module/Loading';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import Mybutton from '@/pages/Index/components/my_button';
import NewDrawer from '@/pages/Index/components/new_drawer';
import PatientTable from '@/pages/Index/components/patient_list';

import RollbackModal from './rollback';
import SendModal from './send';
import OrderCheck from './table';

const emptyData = [];

const flattenArray = (array) => {
  let result: any = [];

  array.forEach((item: any) => {
    const { children } = item;
    result.push(item); // 添加当前项，不包含children
    if (children && children.length) {
      result = result.concat(flattenArray(children)); // 递归添加children
    }
  });

  return result;
};

const Index = (props) => {
  const { routes } = props;
  // console.log(props.history.valueOf(), 'pppp');

  const { user } = vsf?.stores || {};
  const {
    hospitalType,
    setInitKey,
    currentOrderPatient,
    setCurrentOrderPatient,
  } = user || {};
  const orderCheckRef = useRef<any>();
  const orderCheckDataRef = useRef<any>();

  const [selectedRows, setSelectedRows, getSelectedRows] = useGetState<any[]>(
    [],
  );
  // console.log(currentOrderPatient, 'pp');

  const [patientOpen, setPatientOpen, getPatientOpen] =
    useGetState<boolean>(false);
  const [value] = useOnChangeValue({});

  const [title] = useOnChangeValue({
    title: '',
    message: '',
  });
  const [condition] = useOnChangeValue?.();

  const [tableData, setTableData, getTableData] = useGetState<any[]>([]);
  const [repeatIndicator, setRepeatIndicator, getRepeatIndicator] = useGetState<
    boolean | undefined
  >();
  const [checkTypeIs, setCheckTypeIs, getCheckTypeIs] =
    useGetState<string>('NOT_CHECK');

  const [isDrug, setIsDrug, getIsDrug] = useGetState<string>('1');

  const [queryKey, setQueryKey] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const patientRef = useRef<any>();

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['drugOrder', 'administration'],
        ['drugOrder', 'dripSpeed'],
        ['drugOrder', 'frequency', 'frequency'],
        ['drugOrder', 'useDescription'],
        ['drugOrder', 'performDays'],
      ],
      tip() {},
    }),
  );

  // useEffect(() => {
  //   console.log();

  //   if (!routes?.query?.patientId) {
  //     setCurrentOrderPatient({
  //       patient: {
  //         id: routes?.query?.patientId,
  //       },
  //     });
  //   } else {
  //     setCurrentOrderPatient(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [routes]);

  const handleQuery = () => {
    setQueryKey((v) => v + 1);
  };

  const handleSubmit = async () => {
    // const values = await orderCheckRef?.current?.getValidateValues();
  };

  const handleCheck = useFetch({
    fetch: async () => {
      const values = await orderCheckRef?.current?.getValidateValues();
      const res =
        await vsf?.services?.OrderCheckController_checkOrders_a6b510?.({
          orders: values?.map((item) => ({
            orderId: item?.id,
            labelId: item?.performLabelSettingMaster?.performLabel?.id,
          })),
        });
      return res;
    },
    message: {
      loading: '校对医嘱中',
      error: '校对医嘱失败',
    },
    success(res) {
      const status = res?.data?.filter(
        (item) => item?.checkStatus !== 'SUCCESS',
      );
      if (status?.length) {
        title?.onChange({
          title: '校对失败详情',
          message: '部分校对失败列表',
        });
        condition?.onChange?.(
          status?.length !== orderCheckRef?.current?.getSelected()?.length,
        );
        sendModalRef.current?.open(res?.data);
      } else {
        init(value?.value);
        message?.success('校对医嘱成功');
      }
      setTimeout(() => {
        orderCheckRef?.current?.clearChecked();
      }, 0);
    },
  });

  const handleAllCheck = async () => {
    const values = await orderCheckRef?.current?.getValidateValues(true);
    const res = await vsf?.services?.OrderCheckController_checkOrders_a6b510?.({
      orders: values?.map((item) => ({
        orderId: item?.id,
        labelId: item?.performLabelSettingMaster?.performLabel?.id,
      })),
    });
    if (res?.code === 200) {
      sendModalRef?.current?.open(res?.data);
      setTimeout(() => {
        orderCheckRef?.current?.clearChecked();
      }, 0);
    }
  };

  const formatData = (data) => {
    const res: any[] = [];
    data?.forEach((item: any) => {
      const target = res?.find(
        (rItem: any) => rItem?.id === item?.patientWithBed?.id,
      );
      if (!target) {
        res?.push({
          ...(item?.patientWithBed ?? {}),
          children: [item],
        });
      } else {
        target.children.push(item);
      }
    });
    return res;
  };

  const init = useCallback(
    async (patient?: any) => {
      setLoading(true);
      const repeatIndicator = getRepeatIndicator();
      const isDrug = getIsDrug();
      const checkTypeIs = getCheckTypeIs();
      const query: any = {
        checkTypeIs,
      };

      if (typeof repeatIndicator !== undefined) {
        query.repeatIndicatorIs = repeatIndicator;
      }

      if (isDrug === '2') {
        query.orderClassIn = ['DRUG', 'HERB'];
      } else if (isDrug === '3') {
        query.orderClassDrugIsNot = 'DRUG';
        query.orderClassHerbIsNot = 'HERB';
      }

      /**
       * checkTypeIs: "NOT_CHECK"
       * repeatIndicatorIs: undefined
       */

      // const res =
      //   await vsf?.services?.NurseBillingController_queryOrderInfoForBillingStation_23262f?.(
      //     {
      //       qto: query,
      //     },
      //   );
      const res =
        await vsf?.services?.ClinicRecordOrderController_getOrdersForCheck_6ff6b3?.(
          {
            qto: query,
          },
        );
      if (res?.code === 200) {
        const targetData = flattenArray(
          formatData(group?.current?.onSliced(res?.data ?? [])),
        );
        orderCheckDataRef.current = targetData;
        formatTableData(patient ?? patientRef?.current);
        setLoading(false);
        // }
      } else {
        orderCheckDataRef.current = [];
        formatTableData(patient ?? patientRef?.current);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setTableData, currentOrderPatient],
  );

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, queryKey]);

  // 侧边患者列表
  const renderPatientList = () => {
    return (
      <div className="aspirin-check-order-patient-list">
        <div className="content">
          <PatientTable
            patientOpen={patientOpen}
            statusDataSource={[{ label: '在科', value: 'IN_DEPARTMENT' }]}
            hideField={['signaturePatient']}
            onChange={(v) => {
              if (v !== undefined || v !== null) {
                value?.onChange(v);
                patientRef.current = v;
                setPatientOpen(false);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const formatTableData = (value) => {
    // const patientId = value?.patient?.id
    //   ? value?.patient?.id
    //   : routes?.query?.patientId;
    const patientId = currentOrderPatient?.patient?.id;
    const prevData = orderCheckDataRef.current;
    // console.log(patientId, 'patientId1');

    if (patientId) {
      setPatientOpen(false);
      setTableData(
        prevData?.filter(
          (item) =>
            item?.patient?.id == patientId ||
            item?.patientWithBed?.patient?.id == patientId,
        ),
      );
    } else {
      setTableData(prevData);
    }
  };

  useEffect(() => {
    // console.log('加载列表', currentOrderPatient);

    init(value?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.value]);

  const sendModalRef = useRef<any>();
  const rollbackModalRef = useRef<any>();

  const onBackDoctor = async () => {
    rollbackModalRef?.current?.open(orderCheckRef?.current?.getSelected());
    // setTimeout(() => {
    //   orderCheckRef?.current?.clearChecked();
    // }, 0);
  };

  const onCancel = useFetch({
    fetch: async (select) => {
      const res =
        await vsf?.services?.OrderCheckController_uncheckOrders_76b087?.({
          orderIds: select?.map((item) => item?.id),
        });
      return res;
    },
    message: {
      loading: '取消校对中',
    },
    success(value) {
      const status = value?.data?.filter(
        (item) => item?.checkStatus !== 'SUCCESS',
      );
      if (status?.length) {
        title?.onChange({
          title: '取消校对失败详情',
          message: '部分取消校对失败列表',
        });
        condition?.onChange?.(
          status?.length !== orderCheckRef?.current?.getSelected()?.length,
        );
        sendModalRef.current?.open(value?.data);
      } else {
        message?.success('取消校对成功');
        init?.(value?.value);
        setTimeout(() => {
          orderCheckRef?.current?.clearChecked();
        }, 0);
      }
    },
  });

  const onCancelProofread = async () => {
    // 不能取消校对已有执行记录、已摆药、已产生费用的医嘱，具体校对规则看4.2校验规则，停止医嘱不能取消发送，前端控制停止的医嘱不能取消校对
    const select = orderCheckRef?.current?.getSelected() as any[];
    const orderStatus = select?.map((item) => item?.orderStatus);
    if (orderStatus.includes('STOP')) {
      message?.warning('停止医嘱不能取消校对');
      return;
    }
    for (const item of select) {
      // 校验order.order_status!=2执行状态(0-开立未提交、1-新开、2-执行、3-停止、4-作废、7-审核未通过)则气泡提示：“【医嘱正文】【状态为+order_status】不能取消校对！”
      if (item?.orderStatus !== 'PERFORM') {
        const status = [
          { label: '执行', value: 'PERFORM' },
          { label: '停止', value: 'STOP' },
          { label: '作废', value: 'CANCEL' },
          { label: '审核未通过', value: 'AUDIT_FAIL' },
          { label: '待停', value: 'WAIT_STOP' },
          { label: '保存', value: 'SAVE' },
          { label: '提交', value: 'SUBMIT' },
          { label: '开立', value: 'OPEN' },
        ]?.find(({ value }) => value === item?.orderStatus)?.label;
        Tip({
          content: `{【${item?.orderText}】}状态为 { ${status} } 不能取消校对`,
        });
        return;
      }
    }
    // 转科、出院医嘱取消校对校验
    onCancel?.(select);
  };
  return (
    <div className="aspirin-check-order-container">
      <NewDrawer
        open={patientOpen}
        width="290px"
        onOpen={() => {
          setPatientOpen(!getPatientOpen());
        }}
        title="患者列表"
        buttonTitle="患者列表"
        // footer={null}
        buttonStyle={{
          top: '20%',
        }}
        style={{
          top: 104,
        }}
        drift="98px"
        drawerClassName="aspirin-check-order-patient-drawer"
        footer={
          <div className="aspirin-execute-order-patient-drawer-button">
            <Mybutton
              buttonProps={{
                ghost: true,
                onClick: () => {
                  // init();
                  setPatientOpen(false);
                  setCurrentOrderPatient(null);
                },
              }}
            >
              刷新
            </Mybutton>
          </div>
        }
      >
        {renderPatientList()}
      </NewDrawer>
      <div className="aspirin-check-order-main">
        <div className="header">
          <div>
            <RadioGroup
              value={repeatIndicator}
              dataSource={[
                {
                  label: '全部',
                  value: undefined,
                },
                {
                  label: '长期',
                  value: true,
                },
                {
                  label: '临时',
                  value: false,
                },
              ]}
              onChange={(v) => setRepeatIndicator(v)}
            />
          </div>
          <div>
            <RadioGroup
              value={checkTypeIs}
              dataSource={[
                {
                  label: '全部',
                  value: 'ALL',
                },
                {
                  label: '未校对',
                  value: 'NOT_CHECK',
                },
                {
                  label: '今日已校对',
                  value: 'TODAY_CHECK',
                },
              ]}
              onChange={(v) => setCheckTypeIs(v)}
            />
          </div>
          <div>
            <RadioGroup
              value={isDrug}
              dataSource={[
                {
                  label: '全部',
                  value: '1',
                },
                {
                  label: '药物',
                  value: '2',
                },
                {
                  label: '非药物',
                  value: '3',
                },
              ]}
              onChange={(v) => setIsDrug(v)}
            />
          </div>
          <Button type="primary" onClick={handleQuery}>
            提取
          </Button>
        </div>
        <div className="main">
          {/* <AsyncSuspense
            loading={loading}
            dataSource={Boolean(tableData?.length)}
            empty={<Empty desc="暂无数据" />}
          >

          </AsyncSuspense> */}
          {loading ? (
            <div className="order-check-loading">
              <Loading />
            </div>
          ) : (
            <OrderCheck value={tableData} ref={orderCheckRef} />
          )}

          <div className="aspirin-check-order-footer">
            <div className="left">
              {/* <div>
                <span className="label">口服药</span>
                <span className="value">未确认</span>
              </div>
              <div>
                <span className="label">针剂药</span>
                <span className="value">未确认</span>
              </div> */}
            </div>
            <div className="right">
              <Button
                className="footer-btn"
                type="primary"
                onClick={handleAllCheck}
              >
                全部校对
              </Button>
              <Button
                className="footer-btn"
                type="primary"
                onClick={handleCheck}
                // onClick={() => {
                //   orderCheckRef?.current?.clearChecked();
                // }}
              >
                校对
              </Button>
              <Button className="footer-btn" onClick={onBackDoctor}>
                回退医生
              </Button>
              <Button className="footer-btn" onClick={onCancelProofread}>
                取消校对
              </Button>
              <Button
                className="footer-btn"
                type="primary"
                onClick={handleSubmit}
              >
                变更单打印
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SendModal
        ref={sendModalRef}
        title={title.value?.title}
        condition={condition?.value}
        message={title?.value?.message}
      />
      <RollbackModal
        ref={rollbackModalRef}
        onReload={() => {
          init(value?.value);
        }}
      />
    </div>
  );
};

export default definePage(Index);
