import '../index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  VSTableInstance,
} from '@vs/vsf-kit';
import { useAsyncEffect, useRequest, useResetState } from 'ahooks';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Icon from '@/module/cpoe/Icon';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import { Determine, Flex } from '@/pages/Index/components';
import Block from '@/pages/Index/components/block_no_padding';
import {
  askFixed,
  askIsInteger,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import {
  useFrequencyEffect,
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import { dataSourceHerb } from '@/pages/Index/components/order/in_patient/table/datasource';
import TabCheck from '@/pages/Index/components/tab_check';
import {
  getScrollYRem,
  labelColWidth,
  labelColWidthRem,
  labelNumberWidth,
} from '@/utils';

const Main = (props, ref) => {
  const {
    currentTab,
    open,
    onClose,
    defaultValue,
    show = true,
    agreedPrescriptionIndicator = false,
  } = props;
  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};

  const tableRef = useRef<VSTableInstance<any, 'text'> | null>();

  const [value, setValue, getValue, resetValue] = useGetResetInitState<any>([]);
  const [state] = useOnChangeValue<any>({});

  const { data: CHINESE_MEDICINE_DICT } = useRequest(async () => {
    const res =
      await vsf.services?.BaseDictionaryController_getByCodeType_a0a0ce?.({
        codeType: 'CHINESE_MEDICINE_DICT',
      });
    return res?.data;
  });
  useEffect(() => {
    const data = CHINESE_MEDICINE_DICT?.map((item) => ({
      ...item,
      ...JSON.parse(item?.extensionJson ?? '{}'),
    }))?.find((item) => item?.code === currentTab);
    state?.onChange?.(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, CHINESE_MEDICINE_DICT]);

  // console.log(state, 'data');

  const handleSubmit = useCallback(async () => {
    // setValue((value) =>
    //   value?.filter((item) => item?.drugName?.value ?? item?.drugPriceItemId),
    // );
    await vsf?.refs?.CHMTABLE?.remove(
      vsf?.refs?.CHMTABLE?.getValues()?.filter((ele) =>
        ['', null, undefined].includes(ele?.drugItemId),
      ),
    );
    if (!getValue()?.length) {
      const id = 'create' + Math?.random();
      await tableRef?.current?.add({
        id,
      });
      return Promise.reject(message.warn('至少添加一条中药明细'));
    }
    try {
      const [value] = await Promise?.all([
        vsf.refs.chmForm?.validateFieldsReturnFormatValue(),
        tableRef?.current?.getEditForm()?.validateFieldsReturnFormatValue?.(),
      ]);
      return {
        ...value,
        list: getValue(),
      };
    } catch (error) {
      return Promise?.reject(error);
    }
  }, [getValue]);

  const handleDelItem = (v) => {
    tableRef?.current?.remove(v);
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit: handleSubmit,
      getValue: () => {
        return {
          ...vsf?.refs?.chmForm?.getFieldsValue(),
          list: getValue(),
        };
      },
      resetValue: () => {
        resetValue();
        vsf?.refs?.chmForm?.resetFields();
      },
    }),
    [getValue, handleSubmit, resetValue],
  );

  const handleCancelItem = (v) => {
    tableRef?.current?.remove(v?.record);
  };

  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);

  /**
   * 全局操作: 新增一行
   */
  const handleAddRecord = async () => {
    await tableRef?.current?.saveEdit();
    const id = 'create' + Math?.random();
    const target = (getValue() ?? [])?.find((item) => !item?.orderDrugDto);
    vsf?.refs?.CHMTABLE?.add({
      id,
    });
  };

  const [storageList, setStorageList] = useState<any>([]);
  const [form] = Form.useForm();
  const getStorageList = useCallback(async () => {
    const res =
      await vsf?.services?.ClinicRecordOrderDrugController_getHerbStorages_5deb50?.(
        {
          isOutp: hospitalType === 'out',
          herbTypeCode: currentTab,
        },
      );
    // const ex = JSON?.parse(
    //   vsf.stores.dbenums.enums.CHINESE_MEDICINE_DICT?.find(
    //     (ele) => ele?.code === currentTab,
    //   )?.extensionJson,
    // );
    // vsf?.refs?.chmForm?.setFieldsValue({
    //   ...ex,
    //   herbDecoctionIndicator: ex?.decoctionEditable,
    //   processingChargeIndicator: ex?.showProcessingCharge,
    // });
    if (res?.code === 200) {
      const list = res?.data?.map((item) => ({
        departmentName: item?.storageDepartmentName,
        id: item?.storageDepartmentId,
        storageId: item?.id,
      }));
      setStorageList(list);
    }
  }, [hospitalType, currentTab]);

  useEffect(() => {
    getStorageList();
  }, [getStorageList]);

  const totalAmount = (value) => {
    const total: any = value?.reduce(
      (old, newly) => Number(old) + Number(newly?.price ?? 0),
      0,
    );
    /**
     * @herbRegimen 付数
     */
    const int = (total ?? 0) * vsf?.refs?.chmForm?.getFieldValue('herbRegimen');
    return askFixed(Number?.isNaN(int) ? 0 : int, 2);
  };

  useAsyncEffect(async () => {
    const { herbOrderDetail = [], ...value } = defaultValue || {};
    const storage = {
      ...value?.performDepartment,
      storageId: storageList?.find(
        (item) => item?.storageDepartmentId === value?.performDepartment?.id,
      )?.id,
    };
    vsf?.refs?.chmForm?.setFieldsValue({
      ...value,
      storage,
    });

    const list = await Promise.all(
      herbOrderDetail?.map(async (item) => {
        const drug = await onDrugDetailsFetch(item?.drugItemId);
        const { drugEo } = drug;
        return {
          id: 'create' + Math?.random(),
          ...item,
          retailPrice: drug?.retailPrice,
          price: askFixed(
            drug?.retailPrice * (item?.totalAmount?.value ?? item?.totalAmount),
            2,
          ),
          prescriptionRatio: drug?.prescriptionRatio,
          amountPerPackage: drug?.amountPerPackage,
          maxDosage: drug?.maxDosage,
          totalAmount: {
            value: item?.totalAmount?.value ?? item?.totalAmount,
            unit:
              vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                ({ value }) =>
                  value == (item?.packageUnit ?? drugEo?.packageUnit),
              )?.label ?? item.packageUnit,
          },
          drugName: {
            label: item?.drugProductName,
            value: item?.drugPriceItemId,
          },
          medicalInsuranceType:
            item?.medicalInsuranceType ?? drug?.medicalInsuranceType,
        };
      }),
    );
    setValue(
      (list || [])?.map((item) => {
        tableRef?.current?.getEditForm?.()?.setFieldValue(item?.id, item);
        return item;
      }),
    );
    props?.onChange?.(totalAmount(list));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, storageList]);

  const storage = Form.useWatch('storage', form) ?? storageList?.[0];

  // 付数默认值
  const handelHerbRegimen = useCallback(() => {
    const res = vsf?.stores?.dbenums.enums?.CHINESE_MEDICINE_DICT?.find((i) => {
      return i?.value == currentTab;
    });
    if (res && res?.extensionJson) {
      vsf.refs?.chmForm?.setFieldsValue({
        herbRegimen: JSON.parse(res?.extensionJson)?.defaultRegimen ?? '',
        useTimeDescription:
          JSON.parse(res?.extensionJson)?.defaultUseTimeDescription ?? '',
      });
    } else {
      vsf.refs?.chmForm?.setFieldsValue({
        herbRegimen: '',
        useTimeDescription: '',
      });
    }
  }, [currentTab]);

  useEffect(() => {
    handelHerbRegimen();
  }, [handelHerbRegimen]);

  useEffect(() => {
    form?.setFieldValue('storage', storageList?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageList?.[0]]);

  const [adjuvant] = useOnChangeValue(
    Array.from({ length: 4 }).map(() => ({
      name: '紫草(免煎)',
      unit: '袋',
      price: askFixed(0),
    })),
  );

  return (
    <div className="cpoe-chm">
      <div className="main">
        <div className="left">
          <div className="first">
            <Block title="用药信息">
              <VSControlledForm
                className="info-form"
                // value={currentInfo}
                labelAlign="left"
                id="chmForm"
                form={form}
              >
                <Determine condition={!show}>
                  <VSFormLayout
                    columnCount={1}
                    labelCol={labelColWidthRem('6rem')}
                  >
                    <VSFormItem
                      name={['herbPrescriptionName']}
                      label="处方名称"
                      valueType="text"
                    />
                  </VSFormLayout>
                </Determine>
                <Determine condition={state?.value?.decoctionEditable && show}>
                  <VSFormLayout
                    columnCount={1}
                    labelCol={labelColWidthRem('8rem')}
                  >
                    <VSFormItem
                      name={['herbDecoctionIndicator']}
                      label="是否需要代煎"
                      valueType="switch"
                      initialValue={false}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </Determine>
                <VSFormLayout
                  columnCount={1}
                  labelCol={labelColWidthRem('6rem')}
                >
                  <VSFormItem
                    name={['storage']}
                    label="药房"
                    valueType="select"
                    fieldProps={{
                      showSearch: true,
                    }}
                    fieldNames={{
                      label: 'departmentName',
                      value: 'id',
                    }}
                    rules={[{ required: true }]}
                    dataSource={storageList}
                  />

                  <VSFormItem
                    name={['useWay']}
                    label="使用方法"
                    valueType="select"
                    initialValue={vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
                      (item) => item?.label === '温服',
                    )}
                    dataSource={vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT}
                    fieldProps={{
                      showSearch: true,
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['useTimeDescription']}
                    label="服药时间"
                    valueType="select"
                    initialValue={vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
                      (item) => item?.label === '餐后',
                    )}
                    dataSource={
                      vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT
                    }
                    fieldProps={{
                      showSearch: true,
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['frequency']}
                    label="服药频次"
                    valueType="select"
                    initialValue={vsf.stores.dbenums.enums.MEDICINE_FREQ_DICT?.map(
                      (item) => {
                        return {
                          ...item,
                          ...JSON?.parse(item?.extensionJson ?? '{}'),
                        };
                      },
                    )?.find((item) => item?.label === '一日两次')}
                    dataSource={vsf.stores.dbenums.enums.MEDICINE_FREQ_DICT?.map(
                      (item) => {
                        return {
                          ...item,
                          ...JSON?.parse(item?.extensionJson ?? '{}'),
                        };
                      },
                    )}
                    fieldNames={{
                      label: 'frequency',
                      value: 'frequency',
                    }}
                    // dataSource={vsf.stores.dbenums.enums.MEDICINE_FREQ_DICT}
                    fieldProps={{
                      showSearch: true,
                    }}
                    // rules={[{ required: true }]}
                  />
                </VSFormLayout>
                <Determine condition={show}>
                  <VSFormLayout
                    columnCount={1}
                    labelCol={labelColWidthRem('6rem')}
                  >
                    <VSFormItem
                      name={['herbDosage']}
                      label="一次用量"
                      valueType="digit"
                      fieldProps={{
                        addonAfter: 'ml',
                        precision: 2,
                      }}
                      rules={[
                        {
                          validator: (...[, value]) => {
                            if ([null, void 0]?.includes(value))
                              return Promise.resolve();
                            if (value < 0) {
                              return Promise.reject('数量必须是正数');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    />
                  </VSFormLayout>
                </Determine>
                {currentTab && currentTab !== '01' && (
                  <VSFormLayout
                    columnCount={1}
                    labelCol={labelColWidthRem('6rem')}
                  >
                    <VSFormItem
                      name={['decoctMethod']}
                      label="煎药方式"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                      }}
                      rules={[{ required: true }]}
                      dataSource={vsf.stores.dbenums?.enums?.DECOCT_METHOD_DICT}
                    />
                  </VSFormLayout>
                )}
                <Determine condition={agreedPrescriptionIndicator}>
                  <VSFormLayout
                    columnCount={1}
                    labelCol={labelColWidthRem('6rem')}
                  >
                    <VSFormItem
                      valueType="codeTableSelect"
                      label="处方类型"
                      name={['agreed_prescription_type']}
                      dataSource={
                        vsf.stores.dbenums?.enums?.TCM_PRESC_TYPE_DICT
                      }
                      fieldProps={{
                        showSearch: true,
                      }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    />
                  </VSFormLayout>
                </Determine>
                <VSFormLayout
                  columnCount={1}
                  labelCol={labelColWidthRem('6rem')}
                >
                  <VSFormItem
                    name={['herbRegimen']}
                    label="付数"
                    valueType="digit"
                    fieldProps={{
                      addonAfter: '付',
                      onChange: () => {
                        props?.onChange?.(totalAmount(getValue()));
                      },
                    }}
                    // initialValue={handelHerbRegimen?.()}
                    rules={[
                      // currentTab
                      { required: true, message: '' },
                      {
                        validator: (...[, value]) => {
                          return askIsInteger(value);
                        },
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['memo']}
                    label="备注"
                    valueType="textarea"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 2000,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <Determine
                    condition={show && !!state?.value?.showProcessingCharge}
                  >
                    <VSFormItem
                      name={['processingChargeIndicator']}
                      label="是否需要加工费"
                      valueType="switch"
                      fieldProps={{}}
                    />
                  </Determine>
                  <Determine condition={hospitalType === 'in'}>
                    <VSFormItem
                      name={['dischargeDrug']}
                      label="出院带药"
                      valueType="check"
                      fieldProps={{}}
                    />
                  </Determine>
                </VSFormLayout>
              </VSControlledForm>
            </Block>
          </div>
        </div>
        <Block
          title="开立中药"
          className="right"
          extra={
            <div className="cpoe-chm-extra">
              <Button
                type="primary"
                icon={<Icon type="icon-zhongcaoyao" size={14} />}
                disabled={defaultValue?.agreedPrescriptionId}
              >
                <span>中药模板</span>
              </Button>
              <Button
                type="primary"
                icon={<Icon type="icon-tianjiagongneng" size={14} />}
                onClick={handleAddRecord}
                disabled={defaultValue?.agreedPrescriptionId}
              >
                <span>增加中药</span>
              </Button>
            </div>
          }
        >
          <Flex
            vertical
            justify="space-between"
            style={{
              height: '100%',
            }}
          >
            <div className="right-container">
              <VSTable
                ref={tableRef as any}
                scroll={{
                  y: getScrollYRem(28),
                }}
                className="aspirin-table aspirin-table-nopadding aspirin-table-order"
                id="CHMTABLE"
                {...(defaultValue?.agreedPrescriptionId
                  ? {}
                  : {
                      editable: {
                        editType: 'multiple',
                        onCanEdit: (value) => {
                          return true;
                        },
                        columnProps: {
                          fixed: 'right',
                          width: 50,
                          hideInTable: true,
                        },
                        switchMode: {
                          onRowFinish: async (value) => {
                            return true;
                          },
                          onColumnFinish: async (value) => {
                            return true;
                          },
                        },
                        onPreventEdit: () => {},
                      },
                    })}
                bordered
                onRecord={async () => ({ id: 'create' + Math.random() })}
                value={value}
                onChange={(_value) => {
                  props?.onChange?.(totalAmount(_value));
                  setValue(_value);
                }}
                pagination={false}
                rowClick={{
                  rowClickType: {
                    type: 'edit-save',
                    action: 'double-click',
                  },
                }}
              >
                {/* <VSTableColumn title="序号" valueType="index" width="3.33rem" /> */}
                <VSTableColumn
                  title="序号"
                  valueType="index"
                  width={labelNumberWidth(4)}
                />
                <VSTableColumn
                  dataIndex={['drugName']}
                  title="中草药名称"
                  valueType="drugHerbSelect"
                  render={(...[, record]) => record?.drugName?.label}
                  width="16.66rem"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  fieldProps={(...[, record]) => ({
                    placeholder: '请选择中草药',
                    dataSource: getValue?.(),
                    record,
                    storage,
                    herbType: currentTab,
                  })}
                />
                <VSTableColumn
                  dataIndex={['totalAmount']}
                  title="剂量"
                  valueType="herbTotalAmount"
                  fieldProps={(record) => {
                    const key = record?.id;
                    const form = tableRef?.current?.getEditForm();
                    return {
                      width: '100%',
                      onChange: (value) => {
                        if (![void 0, null].includes(value)) {
                          const retailPrice =
                            form?.getFieldValue([key, 'retailPrice']) ??
                            record?.retailPrice;
                          form?.setFieldValue(
                            [key, 'price'],
                            askFixed(
                              (value?.value ?? value ?? 0) * retailPrice,
                              4,
                            ),
                          );
                        }
                      },
                      keyboard: false,
                      id: record?.id,
                    };
                  }}
                  formItemProps={(form, record) => {
                    const [key] = record?.rowKey || [];
                    const prescriptionRatio = form?.getFieldValue([
                      key,
                      'prescriptionRatio',
                    ]);
                    const amountPerPackage = form?.getFieldValue([
                      key,
                      'amountPerPackage',
                    ]);
                    const drugName = form?.getFieldValue([key, 'drugName']);
                    const maxDosage = form?.getFieldValue([key, 'maxDosage']);
                    return {
                      rules: [
                        {
                          required: true,
                        },
                        {
                          validator: (...[, value]) => {
                            if (prescriptionRatio) {
                              if (
                                ((value?.value ?? value) * amountPerPackage) %
                                  prescriptionRatio !==
                                0
                              ) {
                                return Promise.reject(
                                  `
                                ${
                                  drugName?.label ?? drugName
                                }默认开单系数为【${prescriptionRatio}】，请输入其倍数
                                `,
                                );
                              }
                            }
                            if (maxDosage) {
                              if ((value?.value ?? value) > maxDosage) {
                                return Promise.reject(
                                  `${
                                    drugName?.label ?? drugName
                                  }最高用量为${maxDosage}`,
                                );
                              }
                            }
                            if ((value?.value ?? value) < 1) {
                              return Promise?.reject('剂量不能小于1');
                            } else {
                              return askIsInteger(value?.value ?? value);
                            }
                          },
                        },
                      ],
                    };
                  }}
                />

                <VSTableColumn
                  dataIndex={['useDescription']}
                  title="方法"
                  valueType="descriptionSelect"
                  dataSource={vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT}
                  fieldProps={{
                    placeholder: '请选择方法',
                  }}
                />

                <VSTableColumn
                  dataIndex={['price']}
                  title="价格"
                  valueType="custom"
                  fieldProps={{}}
                  render={(...[, record]) => record?.price}
                  renderFormItem={(...[, { record }]) => record?.price}
                  preview
                />
                <VSTableColumn
                  dataIndex={['medicalInsuranceType']}
                  title="医保类型"
                  valueType="text"
                  render={(...[, record]) => {
                    const { medicalInsuranceType } = record || {};
                    const target = dataSourceHerb?.find(
                      (item) => item?.value === medicalInsuranceType,
                    );
                    return target?.label ?? '';
                  }}
                  renderFormItem={(...[, data]) => {
                    const { record } = data || {};
                    const { medicalInsuranceType } = record || {};
                    const target = dataSourceHerb?.find(
                      (item) => item?.value === medicalInsuranceType,
                    );
                    return target?.label ?? '';
                  }}
                  preview
                />
                <VSTableColumn
                  title="操作"
                  renderFormItem={(dom, record) => {
                    return (
                      <Button
                        type="text"
                        onClick={() => {
                          handleCancelItem(record);
                        }}
                      >
                        取消
                      </Button>
                    );
                  }}
                  render={(dom, record) => {
                    const forbidDel =
                      record?.orderItemDtoList?.orderItemStatus === 'SEND';
                    return (
                      <Button
                        type="text"
                        onClick={() => {
                          handleDelItem(record);
                        }}
                        disabled={forbidDel}
                      >
                        删除
                      </Button>
                    );
                  }}
                  hideInTable={defaultValue?.agreedPrescriptionId}
                  fixed="right"
                  width="5rem"
                />
              </VSTable>
            </div>
            <Determine condition={currentTab === '03'}>
              <div
                style={{
                  background: '#f4f5f7',
                  padding: '1.33rem',
                }}
              >
                <VSForm>
                  <VSFormLayout columnCount={3}>
                    {adjuvant?.value?.map((item, index) => {
                      return (
                        <VSFormItem
                          key={index}
                          valueType="digit"
                          label={item?.name}
                          fieldProps={{
                            addonAfter: item?.unit + '/' + item?.price,
                          }}
                        />
                      );
                    })}
                  </VSFormLayout>
                </VSForm>
              </div>
            </Determine>
          </Flex>
        </Block>
      </div>
    </div>
  );
};

const Content: any = forwardRef(Main);

const Index = (props, ref) => {
  const { onSubmit, params, show, agreedPrescriptionIndicator } = props;
  const { dbenums } = vsf.stores;
  const [testItems, setTestItems] = useState<any[]>();
  const [currentTab, setCurrentTab] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [defaultValue, setDefaultValue, restDefaultValue] =
    useResetState<any>(null);
  const [state, setState] = useState('');
  const [amount] = useOnChangeValue([]);
  const onChange = (v, record) => {
    // const {
    //   list,
    //   herbDecoctionIndicator = false,
    //   ...rest
    // } = submit?.current?.getValue();
    setCurrentTab(v);
    setState(record);
    restDefaultValue?.();
    submit?.current?.resetValue();
  };

  const onClose = () => {
    setOpen(false);
    restDefaultValue();
  };
  const onOpen = (v) => {
    setDefaultValue(v ?? undefined);
    if (v) {
      setCurrentTab(v?.herbType);
    }
    setOpen(true);
  };

  useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useFrequencyEffect(
    () => {
      if (!defaultValue) {
        const _testItems = testItems?.find(
          (i) => i?.extensionJson?.isDefaultHerbType,
        );

        setCurrentTab(_testItems ? _testItems?.value : testItems?.[0]?.value);
        setState(_testItems ? _testItems : testItems?.[0]);
      }
    },
    1,
    [defaultValue, testItems],
    'after',
  );

  useEffect(() => {
    const _temptestItems =
      dbenums.enums.CHINESE_MEDICINE_DICT?.map((item) => {
        return item?.extensionJson
          ? {
              ...item,
              key: item?.value,
              extensionJson: JSON.parse(item?.extensionJson),
            }
          : { ...item, key: item?.value };
      }) ?? [];

    setTestItems(_temptestItems);
  }, [dbenums.enums]);

  const submit = useRef<any>({
    onSubmit: async () => {},
  });

  const handleWithDrug = async () => {
    if (vsf?.stores?.user?.hospitalType === 'in') {
      const res =
        await vsf.services?.ClinicInpAdtController_getListInpPreDischargePatientBaseVoByInpVisitId_f3ad3f?.(
          {
            inpVisitId: vsf?.stores?.user?.inVisitInfo?.inpVisitId,
          },
        );
      return res?.data && res?.data?.length > 0;
    } else {
      return false;
    }
  };

  const Submit = async () => {
    const { list = [], ...value } = (await submit?.current.onSubmit()) || {};
    const find = (value) =>
      vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT?.find(
        (item) => item?.value === value,
      )?.label ?? '';

    const orderText = value?.herbPrescriptionName
      ? value?.herbPrescriptionName
      : `【${(state as any)?.label}】 ${list
          ?.map(
            (item) =>
              `${item?.drugName?.label} ${Object?.values(
                item?.totalAmount,
              )?.join('')} ${
                item?.useDescription
                  ? '【' + find(item?.useDescription) + '】'
                  : ''
              }`,
          )
          .join('、')}`;

    const dischargeDrug = await handleWithDrug();

    const extraInfo =
      vsf?.stores?.user?.hospitalType === 'in'
        ? {
            dischargeDrug,
          }
        : {};

    onSubmit?.({
      ...extraInfo,
      orderStatus: 'OPEN',
      orderClass: 'HERB',
      orderText,
      herbPrescriptionName: value?.herbPrescriptionName,
      uuid: defaultValue?.uuid ?? getUUID(),
      performDepartmentId: value?.storage?.id,
      performDepartment: value?.storage,
      memo: value?.memo,
      dischargeDrug: value?.dischargeDrug,
      herbOrder: {
        ...value,
        herbType: currentTab,
        herbOrderDetail: list?.map((item, index) => {
          return {
            ...item,
            drugProductName: item?.drugName?.label,
            drugPriceItemId: item?.drugName?.value,
            totalAmount: item?.totalAmount?.value ?? item?.totalAmount,
            packageUnit: item?.totalAmount?.unit ?? '',
            herbItemNumber: index,
          };
        }),
      },
      allPrice: amount?.value,
    });
    onClose();
  };

  const Footer = () => {
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div
          className="info"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥{amount?.value}</span>
        </div>
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button type="primary" onClick={Submit}>
          确定
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      className="aspirin-drawer-border-bottom"
      title={
        <TabCheck
          value={currentTab}
          onChange={onChange}
          dataSource={testItems}
        />
      }
      placement="right"
      onClose={onClose}
      open={open}
      width={'70%'}
      closeIcon={false}
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      destroyOnClose
      footer={<Footer />}
    >
      <Content
        defaultValue={defaultValue}
        open={open}
        currentTab={currentTab}
        onClose={onClose}
        ref={submit}
        onChange={amount?.onChange}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        show={show}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
