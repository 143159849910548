import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyInpChargeController_getOrderListByInpVisitId_13d62c: {
    method: 'post',
    url: '/api/medical-technology/get-order-list-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderWithDrugAndDisposalAndDoctorVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
});
