import {
  getListClinicPatientBedSimpleVoByBedWard,
  getPatientInHospitalInfo,
} from './Service';
import { useMount, useRequest } from 'ahooks';
import React, { useEffect, useRef, useState } from 'react';

import { VSForm, VSFormLayout, VSFormItem, Row, Col } from '@vs/vsf-kit';

import patientInfo from '../../cpoe/center/left/home_page_information/content/patientInfo';
import WardDoctorOrderInfo from './WardDoctorOrderInfo';
import WardNurseBillingOrderInfo from './WardNurseBillingOrderInfo';

import './index.less';
import { labelColWidth } from '@/utils';

function index(props) {
  const { routes } = props;
  // console.log(routes);
  const { data: wardNameList, run: wardNameRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
          {},
        );

      return res?.data?.result;
    },
    {
      manual: true,
    },
  );

  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      }
      return res?.data?.result;
    },
    { manual: true },
  );

  const [doctorOrderformData, setDoctorOrderformData] = useState({
    currentDateValidIndicator: true,
    repeatIndicator: true,
    repeatIndicator1: true,
  });
  const [nurseOrderformData, setNurseOrderformData] = useState({
    medicalBring: true,
    nurseBring: true,
  });
  useMount(() => {
    wardNameRun();
  });
  //获取患者信息
  const { data: getPatientInfo, run: getPatientInfoRun } = useRequest(
    getPatientInHospitalInfo,
    { manual: true },
  );
  // 床号列表数据源
  const { data: bedList } = useRequest(
    getListClinicPatientBedSimpleVoByBedWard,
  );

  const [selectBedId, setSelectBedId] = useState();

  const [patientId, setPatientId] = useState();

  const [selectId, setSelectId] = useState();
  const onSelectIdsChange = (param) => {
    setSelectId(param);
  };

  useEffect(() => {
    if (getPatientInfo) {
      setSelectBedId({ key: getPatientInfo?.id });
      setPatientId(getPatientInfo?.patient?.id);
    }
  }, [getPatientInfo]);

  useEffect(() => {
    if (!routes?.query?.inpVisitId) return;
    getPatientInfoRun({ inpVisitId: Number(routes?.query?.inpVisitId) });
    setSelectBedId({
      label: routes?.query?.bedLabel,
      value: routes?.query?.patientId,
      key: routes?.query?.inpVisitId,
    });
  }, [routes]);

  return (
    <div className="outpatient-fee-layout">
      <div className="top">
        <div className="top-first-form">
          <VSForm labelAlign="left">
            <VSFormLayout key={1} columnCount={1} labelCol={labelColWidth[4]}>
              <VSFormItem
                name={['wardName']}
                label="床号"
                valueType="selectCode"
                dataSource={bedList}
                fieldNames={{
                  label: 'bedLabel',
                  value: 'patientId',
                  key: 'inpVisitId',
                }}
                initialValue={routes?.query?.bedLabel}
                fieldProps={{
                  onSelect: (
                    _,
                    { bedLabel: label, inpVisitId: key, patientId: value },
                  ) => {
                    const v = { label, key, value };
                    getPatientInfoRun({ inpVisitId: v?.key });
                    setSelectBedId(v);
                  },
                  placeholder: '请选择床号',
                }}
              />
            </VSFormLayout>
            <VSFormLayout key={2} columnCount={1} labelCol={labelColWidth[4]} >
              <VSFormItem
                name={['displayId']}
                label="患者ID"
                valueType="text"
                initialValue={routes?.query?.displayId}
                fieldProps={{
                  onPressEnter: (v) => {
                    console.log(v.target.value);
                    if (v.target.value) {
                      patientDetallRun({
                        displayIdIs: v.target.value,
                      }).then((item) => {
                        getPatientInfoRun({
                          displayId: v.target.value,
                          patientId: item[0]?.id,
                        });
                      });
                    }
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div className="top-first-info">
          <Row className='top6'>
            <Col span={5}>
              床号:
              {getPatientInfo &&
                getPatientInfo?.patientInHospitalBaseDto?.bed?.bedLabel}
            </Col>
            <Col span={5}>
              姓名:
              {getPatientInfo &&
                getPatientInfo?.inpVisitExtensionPatientInfoDto?.name}
            </Col>
            <Col span={5}>
              性别:
              {getPatientInfo &&
                getPatientInfo?.inpVisitExtensionPatientInfoDto?.gender &&
                (vsf.stores.dbenums.enums.SEX_DICT?.find(
                  (item) =>
                    item?.value ===
                    getPatientInfo?.inpVisitExtensionPatientInfoDto?.gender,
                )?.label ??
                  '')}
            </Col>

            <Col span={5}>
              年龄:
              {getPatientInfo &&
                getPatientInfo?.inpVisitExtensionPatientInfoDto?.age}
            </Col>
            <Col span={4}>
              费别:
              {getPatientInfo &&
                (vsf.stores.dbenums.enums.CHARGE_TYPE_DICT?.find(
                  (item) =>
                    item?.value ===
                    getPatientInfo?.inpVisitExtensionBillingInfoDto?.chargeType,
                )?.label ??
                  '')}
            </Col>
          </Row>
          <Row className='top30'>
            <Col span={5}>
              人员类别:
              {getPatientInfo &&
                (vsf.stores.dbenums.enums.IDENTITY_DICT?.find(
                  (item) =>
                    item?.value ===
                    getPatientInfo?.inpVisitExtensionBillingInfoDto
                      ?.identityCode,
                )?.label ??
                  '')}
            </Col>
            <Col span={5}>
              住院日期：
              {getPatientInfo && getPatientInfo?.admissionDateTime}
            </Col>

            <Col span={5}>
              预交金:
              {patientInfo &&
                Number(
                  patientInfo?.patientBillingRecordBaseDto?.prePaymentAmount ??
                    0,
                ).toFixed(2)}
            </Col>
            <Col span={4}>诊断：</Col>
          </Row>
        </div>
      </div>
      <div className="bootom">
        <div className="left">
          <div className="left-order">
            <div className="text-before-weight">
              医生医嘱
            </div>
            <div style={{ flex: 1 }}>
              <VSForm
                labelAlign="left"
                id="DayDoctorOrderForm"
                onChange={(v) => {
                  setDoctorOrderformData(v);
                }}
              >
                <VSFormLayout key={3} columnCount={2}>
                  <VSFormLayout
                    key={4}
                    columnCount={3}
                    style={{ marginRight: '10px' }}
                  >
                    <VSFormItem
                      name={['repeatIndicator']}
                      valueType="check"
                      label={'长期'}
                      fieldProps={{}}
                      initialValue={true}
                    />
                    <VSFormItem
                      name={['repeatIndicator1']}
                      valueType="check"
                      label={'临时'}
                      fieldProps={{}}
                      initialValue={true}
                    />
                    <VSFormItem
                      name={['currentDateValidIndicator']}
                      valueType="check"
                      label={'当日有效'}
                      fieldProps={{}}
                      initialValue={true}
                    />
                  </VSFormLayout>
                  <VSFormItem
                    name={['orderClass']}
                    label="类别"
                    valueType="select"
                    dataSource={[
                      { label: '西药', value: 'DRUG' },
                      { label: '中药', value: 'HERB' },
                      { label: '检验', value: 'LAB' },
                      { label: '检查', value: 'EXAM' },
                      { label: '治疗', value: 'TREAT' },
                      { label: '手术', value: 'OPERATION' },
                      { label: '麻醉', value: 'ANESTHESIA' },
                      { label: '护理', value: 'NURSING' },
                      { label: '膳食', value: 'MEAL' },
                      { label: '营养', value: 'NUTRITION' },
                      { label: '会诊', value: 'CONSULTATION' },
                      { label: '用血', value: 'BLOOD' },
                      { label: '其他', value: 'OTHER' },
                      { label: '病理', value: 'PATHOLOGY' },
                    ]}
                    fieldProps={{
                      allowClear: true,
                      showSearch: true,
                    }}
                  />
                </VSFormLayout>
              </VSForm>
            </div>
          </div>
          <div style={{ overflow: 'auto' }}>
            <WardDoctorOrderInfo
              selectBedId={selectBedId}
              onSelectIdsChange={onSelectIdsChange}
              doctorOrderformData={doctorOrderformData}
              getPatientInfo={getPatientInfo}
            />
          </div>
        </div>
        <div className="right">
          <div className="nurse-billing-title">
            <div className="text-before-weight">护士计费医嘱</div>
            <div className='text-before-content'>
              <VSForm
                labelAlign="left"
                onChange={(v) => {
                  console.log(v);
                  setNurseOrderformData(v);
                }}
              >
                <VSFormLayout key={4} id="nurseBillingOrders" columnCount={4}>
                  <VSFormItem
                    name={['medicalBring']}
                    valueType="check"
                    initialValue={true}
                    label={'医嘱带入'}
                  />
                  <VSFormItem
                    name={['nurseBring']}
                    valueType="check"
                    initialValue={true}
                    label={'护士录入'}
                  />
                  <VSFormItem
                    name={['includeTodayCancel']}
                    valueType="check"
                    label={'今日已作废'}
                    fieldProps={{
                      width: 200,
                    }}
                  />
                </VSFormLayout>
              </VSForm>
            </div>
          </div>
          <WardNurseBillingOrderInfo
            selectBedId={selectBedId}
            selectId={selectId}
            nurseOrderformData={nurseOrderformData}
            patientId={patientId}
          />
          {/* </div> */}
        </div>
      </div>
      {/* 弹框 */}
    </div>
  );
}

export default index;
