/* eslint-disable react/display-name */
import { ParamsType } from '@ant-design/pro-provider';
import { VSTableValueType } from '@vs/vs-table';
import {
  Checkbox,
  VSPromiseTable,
  VSPromiseTableProps,
  VSTable,
  VSTableColumn,
  VSTableProps,
} from '@vs/vsf-kit';
import React, { useImperativeHandle, useRef } from 'react';

type TableProps = {
  componentType: React.ComponentType;
  props: Record<string, any>;
};

const Index = (props: TableProps, ref) => {
  const { componentType: Compnent, props: p = {} } = props;
  const { onVSCheck, children } = p;
  const { onCheckAll, checkKey = 'id', onCheck, type } = onVSCheck ?? {};

  const checkRef = useRef<any>({});
  const checkAllRef = useRef<any>();
  const tableRef = useRef<any>();

  const newP: any = {
    ...p,
    ref: tableRef,
  };

  const getCheckedValue = (v) => {
    if (typeof v === 'string') {
      return v;
    } else if (typeof v === 'boolean') {
      return v ? 'checked' : 'unchecked';
    } else {
      return '';
    }
  };

  const setCheckAll = () => {
    const target = checkRef?.current;
    const allChecked = Object.values(target)?.every(
      (item: any) => item?.value === 'checked',
    );
    const hasCheckedLen = Object.values(target)?.filter(
      (item: any) => item?.value === 'checked',
    )?.length;
    const hasChecked =
      hasCheckedLen > 0 && hasCheckedLen < Object.values(target)?.length;
    const allUnChecked = Object.values(target)?.every(
      (item: any) => item?.value === 'unchecked',
    );
    if (checkAllRef?.current?.value === 'checked') {
      if (allChecked) {
        checkAllRef?.current?.ref?.setChecked(true);
      } else if (hasChecked) {
        checkAllRef?.current?.ref?.setChecked(false);
      } else {
        checkAllRef?.current?.ref?.setChecked(false);
      }
    } else {
      if (allChecked) {
        checkAllRef?.current?.ref?.setChecked(true);
      } else if (hasChecked) {
        // checkAllRef?.current?.ref?.setIndeterminate(true);
      }
    }
    if (allUnChecked) {
      console.log(1);
      checkAllRef?.current?.ref?.setChecked(false);
      // checkAllRef?.current?.ref?.setIndeterminate(false);
    }
  };

  const onAllCheckChange = (v) => {
    const tableData = tableRef?.current?.getValues();
    tableData?.forEach((item) => {
      const target = checkRef?.current?.[item?.[checkKey]];
      const itemChecked = target?.value === 'checked';
      const shouleUpdate = itemChecked !== v;

      if (shouleUpdate) {
        target?.ref?.setChecked(v);
        target.value = getCheckedValue(v);
      }
    });
    // checkAllRef?.current?.ref?.setIndeterminate(false);
  };

  const onCheckChange = (v, record) => {
    const tableData = tableRef?.current?.getValues();
    const res = onCheck?.(record, v, tableData);
    const current = checkRef?.current?.[record?.[checkKey]];
    current.value = getCheckedValue(v);

    res?.forEach((item) => {
      const target = checkRef?.current?.[item?.[checkKey]];
      const itemChecked = target?.value === 'checked';
      const shouleUpdate = itemChecked !== v;

      if (shouleUpdate && item?.[checkKey] !== record?.[checkKey]) {
        target?.ref?.setChecked(item?.checked);
        target.value = getCheckedValue(item?.checked);
      }
    });

    setCheckAll();
  };

  const getCheckedValues = () => {
    const values = tableRef?.current?.getValues();
    return values?.filter((item) => {
      return checkRef?.current?.[item?.[checkKey]]?.value === 'checked';
    });
  };

  const clearCheckedRef = useRef<any>({});

  const clearChecked = () => {
    const tableData = getCheckedValues();
    tableData?.forEach((item) => {
      const target = checkRef?.current?.[item?.[checkKey]];
      const itemChecked = target?.value === 'checked';
      const shouleUpdate = itemChecked !== false;

      if (shouleUpdate && !clearCheckedRef.current?.[item?.[checkKey]]) {
        target?.ref?.setChecked(false);
        // setChecked 有问题 多执行一遍
        target?.ref?.setChecked(false);
        target.value = getCheckedValue(false);
        clearCheckedRef.current = {
          [item[checkKey]]: true,
        };
      }
    });
    checkAllRef?.current?.ref?.setChecked(false);
    clearCheckedRef.current = {};
  };

  useImperativeHandle(ref, () => ({
    getCheckedValues,
    getValues: () => {
      const values = tableRef?.current?.getValues();
      return values;
    },
    clearChecked: () => {
      clearChecked();
    },
  }));

  if (onVSCheck) {
    return (
      <Compnent {...newP}>
        <VSTableColumn
          dataIndex={['checked']}
          title={
            onCheckAll ? (
              <Checkbox
                ref={(ref) => {
                  checkAllRef.current = {
                    ref: ref,
                    value: checkAllRef.current?.value ?? '',
                  };
                }}
                onChange={onAllCheckChange}
              />
            ) : (
              ''
            )
          }
          editable={false}
          valueType="custom"
          render={(...args) => {
            const [, record] = args;
            const extra = {
              ref: (ref) => {
                const prev = checkRef.current[record[checkKey]]?.value;
                checkRef.current[record[checkKey]] = {
                  ref: ref,
                  value: prev ?? '',
                };

                if (prev) {
                  ref?.setChecked(prev === 'checked');
                }
              },
            };
            return (
              <Checkbox
                {...extra}
                // onClick={}
                onChange={(v) => {
                  onCheckChange(v, record);
                }}
              />
            );
          }}
          width={40}
        />
        {children?.map((item) => item)}
      </Compnent>
    );
  } else {
    return <Compnent {...newP}></Compnent>;
  }
};

const Table = React.forwardRef(Index);

type VSTableComponent = (<
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType extends VSTableValueType = 'text',
>(
  props: VSTableProps<DataType, Params, ValueType> & {
    onVSCheck?: {
      type: 'check' | 'radio';
      checkKey: string;
      onCheck: (record, status, tableData) => any[];
      onCheckAll: (record, status, tableData) => any[];
    };
  },
) => JSX.Element) & {
  displayName?: string;
};

type VSPromiseTableComponent = (<
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType extends VSTableValueType = 'text',
>(
  props: VSPromiseTableProps<DataType, Params, ValueType> & {
    onVSCheck?: {
      type: 'check' | 'radio';
      checkKey: string;
      onCheck: (record, status, tableData) => any[];
      onCheckAll: (record, status, tableData) => any[];
    };
  },
) => JSX.Element) & {
  displayName?: string;
};

// @ts-ignore
export const ASVSTable: VSTableComponent = React.forwardRef((props, ref) => {
  return <Table componentType={VSTable} props={props} ref={ref} />;
});

// @ts-ignore
export const ASVSPromiseTable: VSPromiseTableComponent = React.forwardRef(
  (props, ref) => {
    return <Table componentType={VSPromiseTable} props={props} ref={ref} />;
  },
);
