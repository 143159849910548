import {
  Button,
  message,
  Section,
  VSControlledForm,
  Space,
  VSForm,
  VSFormDependency,
  VSFormFooter,
  VSFormFooterReset,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useEffect, useRef, useState } from 'react';

import { calculateAge, labelColWidth } from '@/utils';
import { idNumberReg } from '@/pattern';
import Icon from '@/module/cpoe/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import { MENUKEYENUM } from '../menu';
import PatientExpressAddresses from './PatientExpressAddresses';
import PatientIdentifications from './PatientIdentifications';
import PatientRelationships from './PatientRelationships';
import { isMinScreen } from '@/config';

const PatientFullInfo = (props) => {
  const {
    isNeedFooter = true,
    patientAttr = [],
    fullInfo,
    identification,
    onIdNumberChange,
    onSubmit,
    routes,
  } = props;
  const [formValue, setFormValue] = useState();
  const [insuranceNumberRequired, setInsuranceNumberRequired] = useState(false);
  const [ageCheck, setAgeCheck] = useState(false);
  useEffect(() => {
    if (fullInfo) {
      setFormValue(convertGetFullInfoByIdRes2VSFormPatientFullInfo(fullInfo));
    }
  }, [fullInfo]);

  const renderHeader = (title, domId, tags) => {
    const [open, setOpen] = useState(true);

    const handelFoldClick = () => {
      setOpen(!open);
      const dom = document?.getElementById?.(domId);
      if (dom) {
        dom.style.display = open ? 'none' : 'block';
      }
    };

    return (
      <div className="patient_main_form_header">
        <div className="aspirin-block-title">{title}</div>
        <div className="icon" onClick={handelFoldClick}>
          {open ? (
            <Icon type="icon-shuangjiantoushang" size={18} />
          ) : (
            <Icon type="icon-shuangjiantouxia" size={18} />
          )}
        </div>
        <div className="tags">
          {tags?.map((tag) => {
            return <div {...tag?.fieldProps}>{tag.label}</div>;
          })}
        </div>
        {ageCheck && domId === MENUKEYENUM.FAMILY && (
          <div className="age_check_message">
            患者小于14周岁，家庭信息不能为空，至少填写1位
          </div>
        )}
      </div>
    );
  };

  const getTagsProps = () => {
    return {
      documentTags: [
        {
          label: '新增证件',
          key: 'add',
          fieldProps: {
            onClick: async () => {
              const recordPatientIdentificationDtoList =
                await vsf?.refs?.vSFormPatientFullInfo?.getFieldValue(
                  'patientIdentificationDtoList',
                );
              setFormValue({
                ...formValue,
                patientIdentificationDtoList: recordPatientIdentificationDtoList
                  ? [
                      ...recordPatientIdentificationDtoList,
                      recordPatientIdentificationDtoList?.[
                        recordPatientIdentificationDtoList?.length - 1
                      ]?.identificationClass &&
                      recordPatientIdentificationDtoList?.[
                        recordPatientIdentificationDtoList?.length - 1
                      ]?.identificationNumber
                        ? {}
                        : undefined,
                    ]?.filter((item) => item)
                  : [{}],
              });
            },
          },
        },
        {
          label: '读卡',
          key: 'readCard',
          fieldProps: {
            onClick: () => {
              console.log('读卡');
            },
          },
        },
      ],
      familyInfoTags: [
        {
          label: '新增家属',
          key: 'add',
          fieldProps: {
            onClick: async () => {
              const _id = `create_${Math.random()}`;
              const tableValue =
                await vsf.refs?.vSTablePatientRelationships?.getValues();
              if (
                !(
                  Object.keys(tableValue?.[0] ?? {})?.length === 1 &&
                  Object.keys(tableValue?.[0] ?? {})?.[0] === 'id'
                )
              ) {
                await vsf?.refs?.vSTablePatientRelationships?.saveEdit();
                await vsf?.refs?.vSTablePatientRelationships?.add?.(
                  { id: _id },
                  'top',
                );
                await vsf?.refs?.vSTablePatientRelationships?.startEdit?.(_id);
              }
            },
          },
        },
      ],
      addressTags: [
        {
          label: '新增地址',
          key: 'add',
          fieldProps: {
            onClick: async () => {
              const _id = `create_${Math.random()}`;
              const tableValue =
                await vsf.refs?.vSTablePatientExpressAddress?.getValues();
              if (
                !(
                  Object.keys(tableValue?.[0] ?? {})?.length === 1 &&
                  Object.keys(tableValue?.[0] ?? {})?.[0] === 'id'
                )
              ) {
                await vsf?.refs?.vSTablePatientExpressAddress?.saveEdit();
                await vsf?.refs?.vSTablePatientExpressAddress?.add?.(
                  { id: _id },
                  'top',
                );
                await vsf?.refs?.vSTablePatientExpressAddress?.startEdit?.(_id);
              }
            },
          },
        },
      ],
    };
  };

  const convertGetFullInfoByIdRes2VSFormPatientFullInfo = (_input) => {
    const _ = window._ ?? {};
    const input = {
      data: _input,
    };
    const output = {};
    // console.log(_.get(input, 'data'), 'patient_显示数据');
    output.id = _.get(input, 'data.id');
    output.displayId = _.get(input, 'data.displayId');
    output.unknownIndicator = _.get(input, 'data.unknownIndicator');
    output.bloodCardIndicator = _.get(input, 'data.bloodCardIndicator');
    output.disabilityIndicator = _.get(input, 'data.disabilityIndicator');
    output.vipIndicator = _.get(input, 'data.vipIndicator');
    output.name = _.get(input, 'data.name');
    output.gender = _.get(input, 'data.gender');
    output.birthday = _.get(input, 'data.birthday');
    output.idNumber = _.get(input, 'data.idNumber');
    output.defaultChargeType = _.get(input, 'data.defaultChargeType');
    output.identityCode = _.get(input, 'data.identityCode');
    output.marriageStatus = _.get(
      input,
      'data.patientProfileDto.marriageStatus',
    );
    output.cellphone = _.get(input, 'data.cellphone');
    output.phoneNumber = _.get(input, 'data.phoneNumber');
    output.birthAddress = _.get(input, 'data.birthAddress');
    output.insuranceType = _.get(input, 'data.insuranceType');
    output.insuranceNumber = _.get(input, 'data.insuranceNumber');
    output.insuranceCardNumber = _.get(input, 'data.insuranceCardNumber');
    output.currentAddress = _.get(
      input,
      'data.patientProfileDto.currentAddress',
    );
    output.currentAddressPostcode = _.get(
      input,
      'data.patientProfileDto.nativeAddress.postcode',
    );
    output.contanctName = _.get(input, 'data.patientProfileDto.contact.name');
    output.contactRelationshipType = _.get(
      input,
      'data.patientProfileDto.contact.relationshipType',
    );
    output.contactPhoneNumber = _.get(
      input,
      'data.patientProfileDto.contact.phoneNumber',
    );
    output.nativeAddress = _.get(input, 'data.patientProfileDto.nativeAddress');
    output.citizenship = _.get(input, 'data.patientProfileDto.citizenship');
    output.nation = _.get(input, 'data.patientProfileDto.nation');
    output.email = _.get(input, 'data.patientProfileDto.email');
    output.workUnit = _.get(input, 'data.patientProfileDto.workUnit.name');
    output.workUnitPhoneNumber = _.get(
      input,
      'data.patientProfileDto.workUnit.phoneNumber',
    );
    output.wechatAccount = _.get(input, 'data.patientProfileDto.wechatAccount');
    output.alipayAccount = _.get(input, 'data.patientProfileDto.alipayAccount');
    output.patientIdentificationDtoList = _.get(
      input,
      'data.patientIdentificationDtoList',
    );
    output.patientRelationshipDtoList = _.get(
      input,
      'data.patientRelationshipDtoList',
    );
    output.patientExpressAddressDtoList = _.get(
      input,
      'data.patientExpressAddressDtoList',
    )?.map((item) => ({
      ...item,
      label: item?.label?.[0]?.name,
    }));
    return output;
  };

  // 保存
  const handelSubmit = async () => {
    try {
      if (ageCheck) {
        message.error('患者小于14周岁，家庭信息不能为空，至少填写1位');
        return;
      }
      const params = convertVSFormPatientFullInfo2CreateOrUpdatePatientReq(
        await vsf?.refs?.vSFormPatientFullInfo?.validateFieldsReturnFormatValue?.(),
      );
      // console.log(params, 'data_params保存');
      await vsf.refs.vSTablePatientRelationships
        ?.getEditForm()
        ?.validateFields();
      await vsf.refs.vSTablePatientExpressAddress
        ?.getEditForm()
        ?.validateFields();
      // Tips: 函数入参为表单数据，格式和RO一致
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.PatientMainController_createOrUpdatePatient_d8b932?.(
          params,
        ); // Tips: 需要返回提交的完整数据，和表单RO一致
      if (res && res.code === 200) {
        message.success('保存成功');
        if (routes?.query?.type === 'appointmentRegister') {
          vsf?.navigateTo(
            `/index/appointment-registration?displayId=${res?.data?.displayId}`,
          );
        }
        if (routes?.query?.type === 'register') {
          vsf?.navigateTo(
            `/index/outpatient-registration?displayId=${res?.data?.displayId}`,
          );
        }
        onSubmit?.(res?.data);
      }
      return params;
    } catch (error) {
      console.error(error);
    }
  };

  const convertVSFormPatientFullInfo2CreateOrUpdatePatientReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    // console.log(input, 'patient_input');
    output.btoParam.id = input?.id; // Long 主键
    output.btoParam.displayId = input?.displayId; // String 用于显示的id
    output.btoParam.name = input?.name; // String 姓名
    output.btoParam.gender = input?.gender; // String 生理性别
    output.btoParam.idNumber = input?.idNumber; // String 身份证号
    output.btoParam.insuranceType = input?.insuranceType; // String 医保类型
    output.btoParam.insuranceNumber = input?.insuranceNumber; // String 医保账号
    output.btoParam.insuranceCardNumber = input?.insuranceCardNumber; // String 医保卡号
    output.btoParam.cellphone = input?.cellphone; // String 手机号
    output.btoParam.phoneNumber = input?.phoneNumber; // String 联系电话
    output.btoParam.defaultChargeType = input?.defaultChargeType; // String 默认费别
    output.btoParam.identityCode = input?.identityCode; // String 人员类别编码
    output.btoParam.birthday = input?.birthday; // Date 出生日期
    output.btoParam.birthAddress = {};
    output.btoParam.birthAddress.province = input?.birthAddress?.province; // String 省
    output.btoParam.birthAddress.city = input?.birthAddress?.city; // String 市
    output.btoParam.birthAddress.county = input?.birthAddress?.county; // String 区
    output.btoParam.birthAddress.detail = input?.birthAddress?.detail; // String 详细地址
    output.btoParam.birthAddress.code = input?.birthAddress?.code; // String 地区编码
    output.btoParam.birthAddress.postcode = input?.birthAddress?.postcode; // String 邮编
    //TODO output.btoParam.creator = input?.; // String 创建人
    //TODO output.btoParam.operator = input?.; // String 操作人
    output.btoParam.patientExpressAddressBtoList =
      input?.patientExpressAddressDtoList?.map((item) => ({
        ...item,
        patientId: identification?.patientId,
        id:
          typeof item?.id === 'string' && item?.id?.includes('create_')
            ? undefined
            : item?.id,
        label: [
          {
            name: item?.label,
          },
        ],
      })); // List<PatientExpressAddressBto> 患者收货信息列表
    output.btoParam.patientRelationshipBtoList =
      input?.patientRelationshipDtoList?.map((item) => ({
        ...item,
        id:
          typeof item?.id === 'string' && item?.id?.includes('create_')
            ? undefined
            : item?.id,
        patientId: identification?.patientId,
      })); // List<PatientRelationshipBto> 患者亲属关系信息列表
    output.btoParam.patientIdentificationBtoList =
      input?.patientIdentificationDtoList?.map((item) => ({
        ...item,
        patientId: identification?.patientId,
      })); // List<PatientIdentificationBto> 患者身份识别信息列表
    output.btoParam.patientProfileBto = {};
    output.btoParam.patientProfileBto.citizenship = input?.citizenship; // String 国籍
    output.btoParam.patientProfileBto.nation = input?.nation; // String 民族
    output.btoParam.patientProfileBto.marriageStatus = input?.marriageStatus; // String 婚姻状态
    output.btoParam.patientProfileBto.nativeAddress = {};
    output.btoParam.patientProfileBto.nativeAddress.province =
      input?.nativeAddress?.province; // String 省
    output.btoParam.patientProfileBto.nativeAddress.city =
      input?.nativeAddress?.city; // String 市
    output.btoParam.patientProfileBto.nativeAddress.county =
      input?.nativeAddress?.county; // String 区
    output.btoParam.patientProfileBto.nativeAddress.detail =
      input?.nativeAddress?.detail; // String 详细地址
    output.btoParam.patientProfileBto.nativeAddress.code =
      input?.nativeAddress?.code; // String 地区编码
    output.btoParam.patientProfileBto.nativeAddress.postcode =
      input?.currentAddressPostcode; // String 邮编
    output.btoParam.patientProfileBto.email = input?.email; // String 电子邮件
    output.btoParam.patientProfileBto.currentAddress = {};
    output.btoParam.patientProfileBto.currentAddress.province =
      input?.currentAddress?.province; // String 省
    output.btoParam.patientProfileBto.currentAddress.city =
      input?.currentAddress?.city; // String 市
    output.btoParam.patientProfileBto.currentAddress.county =
      input?.currentAddress?.county; // String 区
    output.btoParam.patientProfileBto.currentAddress.detail =
      input?.currentAddress?.detail; // String 详细地址
    output.btoParam.patientProfileBto.currentAddress.code =
      input?.currentAddress?.code; // String 地区编码
    // output.btoParam.patientProfileBto.currentAddress.postcode =
    //   input?.currentAddress?.postcode; // String 邮编
    // output.btoParam.patientProfileBto.currentAddress.postcode =
    //   input?.currentAddressPostcode; // String 邮编
    output.btoParam.patientProfileBto.workUnit = {};
    output.btoParam.patientProfileBto.workUnit.name = input?.workUnit; // String 单位名称
    output.btoParam.patientProfileBto.workUnit.phoneNumber =
      input?.workUnitPhoneNumber; // String 单位联系电话
    // output.btoParam.patientProfileBto.workUnit.address = {};
    // output.btoParam.patientProfileBto.workUnit.address.province = input?.; // String 省
    // output.btoParam.patientProfileBto.workUnit.address.city = input?.; // String 市
    // output.btoParam.patientProfileBto.workUnit.address.county = input?.; // String 区
    // output.btoParam.patientProfileBto.workUnit.address.detail = input?.; // String 详细地址
    // output.btoParam.patientProfileBto.workUnit.address.code = input?.; // String 地区编码
    // output.btoParam.patientProfileBto.workUnit.address.postcode = input?.; // String 邮编
    // output.btoParam.patientProfileBto.unitInContract = input?.; // String 合同单位
    output.btoParam.patientProfileBto.contact = {};
    output.btoParam.patientProfileBto.contact.name = input?.contanctName; // String 联系人姓名
    output.btoParam.patientProfileBto.contact.relationshipType =
      input?.contactRelationshipType; // String 联系人关系类型
    output.btoParam.patientProfileBto.contact.phoneNumber =
      input?.contactPhoneNumber; // String 联系人的联系电话
    // output.btoParam.patientProfileBto.creator = input?.; // String 创建人
    // output.btoParam.patientProfileBto.operator = input?.; // String 操作员
    output.btoParam.patientProfileBto.wechatAccount = input?.wechatAccount; // String 微信号
    output.btoParam.patientProfileBto.alipayAccount = input?.alipayAccount; // String 支付宝账号
    output.btoParam.unknownIndicator = input?.unknownIndicator; // Boolean 无名患者标识
    output.btoParam.bloodCardIndicator = input?.bloodCardIndicator; // Boolean 献血证标识
    output.btoParam.disabilityIndicator = input?.disabilityIndicator; // Boolean 残疾人证标识
    output.btoParam.vipIndicator = input?.vipIndicator; // Boolean VIP标识

    // console.log(input, 'patient_input 输入框内容');
    // console.log(output, 'patient_output 传入内容');

    // 患者其他属性
    const attributes = [
      'unknownIndicator',
      'bloodCardIndicator',
      'disabilityIndicator',
      'vipIndicator',
    ];
    attributes?.forEach((item) => {
      output.btoParam[item] = patientAttr?.includes(item);
    });

    output.extApiParam = {};
    return output;
  };

  const handelFormChange = async (value) => {
    if (value.idNumber) {
      if (idNumberReg.test(value.idNumber)) {
        onIdNumberChange && onIdNumberChange(true);

        // 获取现有证件信息 如果没有则新增一条
        const recordPatientIdentificationDtoList =
          await vsf?.refs?.vSFormPatientFullInfo?.getFieldValue(
            'patientIdentificationDtoList',
          );
        if (
          !recordPatientIdentificationDtoList?.find(
            (v) => v?.identificationNumber === value?.idNumber,
          )
        ) {
          const idCardValue =
            await vsf?.stores?.dbenums?.enums?.IDENTIFICATION_CLASS_DICT?.find(
              (item) => item?.label === '身份证',
            )?.value;
          vsf.refs?.vSFormPatientFullInfo?.setFieldValue(
            'patientIdentificationDtoList',
            recordPatientIdentificationDtoList
              ? recordPatientIdentificationDtoList?.map((item) => ({
                  ...item,
                  identificationNumber:
                    item?.identificationClass === idCardValue
                      ? value?.idNumber
                      : item?.identificationNumber,
                }))
              : [
                  {
                    identificationClass: idCardValue,
                    identificationNumber: value?.idNumber,
                  },
                ],
          );
        }
      }
    } else {
      onIdNumberChange && onIdNumberChange(false);
      const recordPatientIdentificationDtoList =
        await vsf?.refs?.vSFormPatientFullInfo?.getFieldValue(
          'patientIdentificationDtoList',
        );
      vsf.refs?.vSFormPatientFullInfo?.setFieldValue(
        'patientIdentificationDtoList',
        recordPatientIdentificationDtoList?.filter(
          (item) => item?.identificationClass !== '身份证',
        ),
      );
    }

    if (value?.defaultChargeType) {
      setInsuranceNumberRequired(value?.defaultChargeType === '2');
    }

    if (value?.birthday) {
      // 小于14周岁
      setAgeCheck(
        calculateAge(value?.birthday) < 14 &&
          !value?.patientRelationshipDtoList?.length,
      );
    }
  };

  // 过滤人员类别
  const handelFilter = (data) => {
    const res = vsf.stores.dbenums?.enums?.IDENTITY_DICT?.filter((item) => {
      return (
        item?.extensionJson &&
        JSON.parse(item?.extensionJson)?.chargeTypeCode == data
      );
    });
    if (res && res?.length > 0) {
      return res;
    } else {
      return [];
    }
  };

  return (
    <div className="patient_full_info_wapper">
      <div id="PatientFullInfoMain" className="main">
        <VSControlledForm
          value={formValue}
          className="aspirin-form"
          id="vSFormPatientFullInfo"
          vsid="25192"
          labelAlign="left"
          // labelWrap
          onChange={handelFormChange}
        >
          {renderHeader('基本信息', MENUKEYENUM.FOUNDATION)}
          <VSFormItem
            name={'id'}
            label="主键"
            valueType="text"
            style={{
              display: 'none',
            }}
          />
          <div id={MENUKEYENUM.FOUNDATION}>
            <VSFormLayout key="1" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['name']}
                label="姓名"
                valueType="text"
                required
                rules={[
                  { required: true, message: '请输入姓名' },
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={
                  {
                    // disabled: fullInfo?.id,
                  }
                }
              />

              <VSFormItem
                name={['gender']}
                label="性别"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                required
                rules={[{ required: true, message: '请选择性别' }]}
                fieldProps={{
                  showSearch: true,
                  // disabled: fullInfo?.id,
                }}
              />

              <VSFormItem
                name={['birthday']}
                label="出生日期"
                valueType="date"
                fieldProps={{}}
                required
                rules={[{ required: true, message: '请选择出生日期' }]}
              />
            </VSFormLayout>
            <VSFormLayout key="2" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['idNumber']}
                label="身份证号"
                valueType="text"
                required={!patientAttr?.includes('unknownIndicator')}
                rules={[
                  {
                    required: !patientAttr?.includes('unknownIndicator'),
                    message: '请输入身份证号',
                  },
                  {
                    message: '请检查身份证号格式',
                    type: 'string',
                    pattern:
                      /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  },
                ]}
                fieldProps={
                  {
                    // disabled: fullInfo?.id,
                  }
                }
              />

              <VSFormItem
                name={['defaultChargeType']}
                label="费别"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
                fieldProps={{
                  showSearch: true,
                  // disabled: fullInfo?.id,
                  onClear: () => {
                    vsf.refs.vSFormPatientFullInfo.setFieldsValue({
                      identityCode: '',
                    });
                  },
                  onChange: (v) => {
                    vsf.refs.vSFormPatientFullInfo.setFieldsValue({
                      identityCode: handelFilter(v)?.[0]?.value,
                    });
                  },
                }}
                formItemProps={{}}
                required
                rules={[{ required: true, message: '请选择费别' }]}
              />
              <VSFormDependency name={['defaultChargeType']}>
                {(data) => {
                  return (
                    <VSFormItem
                      labelCol={labelColWidth[9]}
                      name={['identityCode']}
                      label="人员类别"
                      valueType="select"
                      fieldProps={{ showSearch: true }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      dataSource={handelFilter(data?.defaultChargeType)}
                    />
                  );
                }}
              </VSFormDependency>
              {/* <VSFormItem
                name={['identityCode']}
                label="人员类别"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
                fieldProps={{
                  
                  showSearch: true,
                }}
                formItemProps={{}}
              /> */}
            </VSFormLayout>
            <VSFormLayout key="3" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['marriageStatus']}
                label="婚姻状况"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.MARITAL_STATUS_DICT}
                fieldProps={{
                  showSearch: true,
                }}
                formItemProps={{}}
                required={!patientAttr?.includes('unknownIndicator')}
                rules={[
                  {
                    required: !patientAttr?.includes('unknownIndicator'),
                    message: '请选择婚姻状况',
                  },
                ]}
              />

              <VSFormItem
                name={['cellphone']}
                label="手机号"
                valueType="text"
                required
                rules={[
                  { required: true, message: '请输入手机号' },
                  {
                    message: '请检查手机号格式',
                    type: 'string',
                    pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                  },
                ]}
                fieldProps={{
                  placeholder: '请输入11位手机号',
                }}
              />

              <VSFormItem
                name={['phoneNumber']}
                label="联系电话"
                valueType="text"
                rules={[]}
                fieldProps={{
                  placeholder: '请输入',
                }}
              />
            </VSFormLayout>
            <VSFormLayout key="4" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['birthAddress']}
                label="出生地"
                valueType="address"
                fieldProps={{
                  disabled: fullInfo?.id,
                }}
                convertValue={(v) => ({
                  ...v,
                  district: v?.district ? v?.district : v?.county,
                })}
              />
              <VSFormItem
                name={['insuranceType']}
                label="医保类型"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.INSUR_TYPE_DICT}
                fieldProps={{
                  showSearch: true,
                }}
              />

              <VSFormItem
                name={['insuranceNumber']}
                label="医保账号"
                valueType="text"
                required={insuranceNumberRequired}
                rules={[
                  {
                    required: insuranceNumberRequired,
                    message: '请输入医保账号',
                  },
                ]}
                fieldProps={
                  {
                    // disabled: fullInfo?.id,
                  }
                }
              />
            </VSFormLayout>
            <VSFormLayout
              key="5"
              columnCount={2}
              labelCol={labelColWidth[9]}
              columnSpan={[8, 12]}
            >
              <VSFormLayout gutter={0} key="1" columnCount={2}>
                <VSFormItem
                  name={['insuranceCardNumber']}
                  label="医保卡号"
                  valueType="text"
                  rules={[
                    {
                      message: '医保卡号长度不合法',
                      type: 'string',
                      min: 0,
                      max: 200,
                    },
                  ]}
                  fieldProps={
                    {
                      // disabled: fullInfo?.id,
                    }
                  }
                />
              </VSFormLayout>
              <VSFormLayout gutter={0} key="5" columnCount={2}>
                {/* <Space.Compact style={{ width: '100%' }}> */}
                <VSFormItem
                  name={['currentAddress']}
                  label="现住址"
                  valueType="address"
                  labelCol={labelColWidth[9]}
                  fieldProps={{}}
                  width="100%"
                  convertValue={(v) => ({
                    ...v,
                    district: v?.district ? v?.district : v?.county,
                  })}
                />
                <VSFormItem
                  name={['currentAddress', 'detail']}
                  valueType="text"
                  className={isMinScreen ? 'marginLefts30' : 'marginLeft10'}
                  // style={{ marginLeft: '-31px' }}
                />
                {/* </Space.Compact> */}
              </VSFormLayout>
            </VSFormLayout>
            <VSFormLayout key="6" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['currentAddressPostcode']}
                label="邮编"
                valueType="text"
                rules={[
                  {
                    message: '邮编长度不合法',
                    type: 'string',
                    min: 0,
                    max: 100,
                  },
                ]}
                fieldProps={
                  {
                    // disabled: fullInfo?.id,
                  }
                }
                formItemProps={{}}
              />
              <VSFormItem
                name={['contanctName']}
                label="联系人"
                valueType="text"
                rules={[
                  {
                    message: '联系人姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={{}}
                formItemProps={{}}
              />

              <VSFormItem
                name={['contactRelationshipType']}
                label="联系人关系"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
                fieldProps={{
                  showSearch: true,
                }}
                formItemProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="6" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['contactPhoneNumber']}
                label="联系人电话"
                valueType="text"
                rules={[
                  {
                    message: '请检查手机号格式',
                    type: 'string',
                    pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                  },
                ]}
                fieldProps={{}}
                formItemProps={{}}
              />
            </VSFormLayout>
          </div>

          {renderHeader('其他信息', MENUKEYENUM.OTHER)}
          <div id={MENUKEYENUM.OTHER}>
            <VSFormLayout key="7" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['nativeAddress']}
                label="籍贯"
                valueType="address"
                fieldProps={{}}
                convertValue={(v) => ({
                  ...v,
                  district: v?.district ? v?.district : v?.county,
                })}
              />

              <VSFormItem
                name={['citizenship']}
                label="国籍"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.COUNTRY_DICT}
                fieldProps={{
                  showSearch: true,
                }}
              />

              <VSFormItem
                name={['nation']}
                label="民族"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.NATION_DICT}
                fieldProps={{
                  showSearch: true,
                }}
              />
            </VSFormLayout>
            <VSFormLayout key="8" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['email']}
                label="E-mail"
                valueType="text"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  },
                ]}
                fieldProps={{}}
                formItemProps={{}}
              />

              <VSFormItem
                name={['workUnit']}
                label="单位及地址"
                valueType="text"
                fieldProps={{}}
                formItemProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="9" columnCount={3} labelCol={labelColWidth[9]}>
              <VSFormItem
                name={['workUnitPhoneNumber']}
                label="单位电话"
                valueType="text"
                rules={[
                  {
                    message: '单位联系电话长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{}}
                formItemProps={{}}
              />

              <VSFormItem
                name={['wechatAccount']}
                label="微信号"
                valueType="text"
                rules={[
                  {
                    message: '微信号长度不合法',
                    type: 'string',
                    min: 0,
                    max: 100,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['alipayAccount']}
                label="支付宝账号"
                valueType="text"
                rules={[
                  {
                    message: '支付宝账号长度不合法',
                    type: 'string',
                    min: 0,
                    max: 100,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
          </div>

          {renderHeader(
            '证件信息',
            MENUKEYENUM.DOCUMENT,
            getTagsProps().documentTags,
          )}
          <div id={MENUKEYENUM.DOCUMENT}>
            <VSFormLayout key="10" columnCount={1}>
              <VSFormItem
                name={['patientIdentificationDtoList']}
                label=""
                valueType="patientIdentifications"
              />
            </VSFormLayout>
          </div>

          {renderHeader(
            '家庭信息',
            MENUKEYENUM.FAMILY,
            getTagsProps().familyInfoTags,
          )}
          <div id={MENUKEYENUM.FAMILY}>
            <VSFormLayout key="11" columnCount={1}>
              <VSFormItem
                name={['patientRelationshipDtoList']}
                label=""
                valueType="custom"
                customComponent={PatientRelationships}
              />
            </VSFormLayout>
          </div>

          {renderHeader(
            '收货地址',
            MENUKEYENUM.ADDRESS,
            getTagsProps().addressTags,
          )}
          <div id={MENUKEYENUM.ADDRESS}>
            <VSFormLayout key="12" columnCount={1}>
              <VSFormItem
                name={['patientExpressAddressDtoList']}
                label=""
                valueType="custom"
                customComponent={PatientExpressAddresses}
              />
            </VSFormLayout>
          </div>
          {/* <VSFormFooter>
            <VSFormFooterSubmit children="提交" />

            <VSFormFooterReset children="重置" />
          </VSFormFooter>
          <Button onClick={async () => {
            const params = convertVSFormPatientFullInfo2CreateOrUpdatePatientReq(
              await vsf?.refs?.vSFormPatientFullInfo?.validateFieldsReturnFormatValue?.()
            )
            // Tips: 函数入参为表单数据，格式和RO一致
            // Tips: 以下是你选择的请求函数
            const res =
              await vsf.services?.PatientMainController_createOrUpdatePatient_d8b932?.(
                params
              ); // Tips: 需要返回提交的完整数据，和表单RO一致
            return params;

          }}>保存</Button> */}
        </VSControlledForm>
      </div>

      {isNeedFooter && (
        <div className="footer">
          <div className="normal">取消</div>
          <div className="normal">打印条码</div>
          <div className="normal" onClick={props?.onClearScreen}>
            清屏
          </div>
          <div className="normal primary" onClick={handelSubmit}>
            保存
          </div>
        </div>
      )}
    </div>
  );
};
export default definePage(PatientFullInfo);
