import locale from 'antd/es/date-picker/locale/zh_CN';
import cn from 'classnames';
import dayjs from 'dayjs';

import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';

import { Input, Select, Modal } from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import { convertQueryAllByDiagnosisRecordWithDiagnosisQtoRes2DiagnosisRecordList } from './convert';

import './index.less';

const Index = (props, ref) => {
  const {
    save,
    mainDiagnoseType = '3',
    diagnoseListType = 'A',
    diagnosisList = [],
  } = props;
  // 诊断弹窗
  const [diagnoseModal, setDiagnoseModal] = useState(false);
  // 主要诊断
  const [mainDiagnose, setMainDiagnose] = useState();
  // 其他诊断
  const [otherDiagnoseList, setOtherDiagnoseModalList] = useState([{}]);

  useEffect(() => {
    if (!diagnoseModal) {
      setMainDiagnose(null);
      setOtherDiagnoseModalList(null);
    } else {
      const main = diagnosisList
        ?.filter((item) => {
          return item?.diagnosisType == '3';
        })
        .map((item) => {
          return { ...item, diagnosisCode: item?.diagnosisCode };
        });
      const other = diagnosisList
        ?.filter((item) => {
          return item?.diagnosisType == 'A';
        })
        .map((item) => {
          return { ...item, diagnosisCode: item?.diagnosisCode };
        });
      setMainDiagnose(main?.[0]);
      setOtherDiagnoseModalList(other?.length === 0 ? [{}] : other);
    }
  }, [diagnoseModal]);

  const handelOpen = (v) => {
    setDiagnoseModal(v);
  };
  // 查询诊断
  const getAllByDiagnosisRecord = async (v) => {
    const res =
      await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          qto: {
            from: 0,
            size: 100,
            ...v,
          },
          ext: {},
        },
      );
    return res?.data?.result ?? [];
  };
  // 选择其他诊断
  const handelSelectOtherDiagnose = (index, value) => {
    const _res = otherDiagnoseList?.map((item, _index) => {
      if (_index == index) {
        return value;
      } else {
        return { ...item };
      }
    });
    console.log(_res);
    setOtherDiagnoseModalList(_res);
  };
  // 删除其他诊断
  const handelDeleteOtherDiagnose = (index) => {
    const _res = otherDiagnoseList?.filter((item, _index) => {
      return index !== _index;
    });
    setOtherDiagnoseModalList(_res);
  };
  // 保存
  const handelSave = () => {
    console.log(otherDiagnoseList, mainDiagnose);
    const _diagnoseList = otherDiagnoseList
      ?.map((item) => {
        return {
          ...item,
          diagnosisType: 'A',
          diagnosisId: item?.id,
        };
      })
      .filter((i) => {
        return i?.diagnosisId;
      });

    save?.(
      mainDiagnose?.id
        ? [
            {
              ...mainDiagnose,
              diagnosisType: '3',
              diagnosisId: mainDiagnose?.id,
            },

            ..._diagnoseList,
          ]
        : [..._diagnoseList],
    );
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <Modal
      open={diagnoseModal}
      title={
        <div
          style={{
            color: '#3276E8',
            fontSize: 20,
          }}
        >
          诊断
        </div>
      }
      destroyOnClose={true}
      centered
      width={634}
      bodyStyle={{ maxHeight: 400, overflowY: 'scroll' }}
      cancelText="关闭"
      okText="保存"
      onOk={() => {
        handelSave();
      }}
      onCancel={() => {
        handelOpen(false);
      }}
    >
      <div className="doctor_station_out_hospital_modal_item">
        <div className="doctor_station_out_hospital_modal_item_label">
          主要诊断
        </div>
        <div>
          <Select
            style={{ marginRight: 20 }}
            width={240}
            dataSource={getAllByDiagnosisRecord}
            defaultValue={mainDiagnose}
            fieldNames={{ label: 'diagnosisName', value: 'id' }}
            showSearch={true}
            searchKey="searchInputLike"
            onChange={(v) => {
              setMainDiagnose(v);
            }}
          />
          <Input value={mainDiagnose?.diagnosisCode} width={120} disabled />
        </div>
      </div>
      {otherDiagnoseList?.map((item, index) => {
        return (
          <div key={item} className="doctor_station_out_hospital_modal_item">
            <div className="doctor_station_out_hospital_modal_item_label">
              {index === 0 ? '其他诊断' : ''}
            </div>
            <div className="doctor_station_out_hospital_modal_item_content">
              <Select
                dataSource={getAllByDiagnosisRecord}
                fieldNames={{ label: 'diagnosisName', value: 'id' }}
                showSearch={true}
                searchKey="searchInputLike"
                style={{ marginRight: 20 }}
                width={240}
                onChange={(v) => {
                  handelSelectOtherDiagnose(index, v);
                }}
                defaultValue={item}
              />
              <Input
                style={{ marginRight: 20 }}
                width={120}
                disabled
                value={item?.diagnosisCode}
              />
              {/* {index === otherDiagnoseList?.length - 1 && ( */}
              <div
                style={{ marginRight: 5 }}
                onClick={() => {
                  handelDeleteOtherDiagnose(index);
                }}
              >
                <Icon
                  size={20}
                  type="icon-shanchu1"
                  className="doctor_station_out_hospital_margin"
                />
              </div>
              {/* )} */}
              {index === otherDiagnoseList?.length - 1 && (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOtherDiagnoseModalList([...otherDiagnoseList, {}]);
                  }}
                >
                  <Icon
                    size={22}
                    type="icon-tianjia"
                    // className="doctor_station_out_hospital_margin"
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Modal>
  );
};
export default forwardRef(Index);
