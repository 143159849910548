import React, { useEffect, useState } from 'react';
import { labelColWidth, labelNumberWidth } from '@/utils';
import {
  VSFormLayout,
  VSFormItem,
  VSForm,
  Select,
  InputNumber,
} from '@vs/vsf-kit';
import './index.less';
import '@/style/adaptation.less';
import dayjs from 'dayjs';
import '@/style/adaptation.less';

export default function Model(props) {
  const { active, day, setDay } = props;
  useEffect(async () => {
    const qto = {};
    if (active?.clinicType === 'OUTP') {
      qto.outpVisitId = active?.id;
    } else if (active?.clinicType === 'INP') {
      qto.outpVisitId = active?.diagnosisId;
    }
    const res =
      await vsf?.services?.MedicalCertificationRecordController_getCertificationRecordDetail_6b5779?.(
        { ...qto },
      );
    const record = { ...active, ...res?.data };
    const doctorName = vsf?.stores?.user?.staffInfo?.staffName;
    if (res?.data) {
      // 时间
      record.timer = res?.data?.medicalDate;
      record.doctorName = res?.data?.doctor?.staffName;
      // 时间单位
      setDay(res?.data?.restDurationUnit);
    } else {
      record.timer = dayjs().format('YYYY-MM-DD HH:mm:ss');
      record.doctorName = doctorName;
    }
    vsf?.refs?.medicalCertificate.setFieldsValue(record);
  }, []);

  return (
    <div className="model widthAutoFill">
      <div className="model_title widthAutoFill icon18">
        宜兴市人民医院医学证明书
      </div>

      <VSForm vsid="17359" labelAlign="left" id="medicalCertificate">
        <VSFormLayout key="1" columnCount={3}>
          <VSFormLayout
            key="1"
            columnCount={3}
            labelWidth={labelNumberWidth(3)}
          >
            <VSFormItem
              label="姓名"
              name={['patient', 'name']}
              valueType="text"
              fieldProps={{
                precision: 1,
              }}
              readonly={true}
            />
            <VSFormItem
              label="性别"
              name={['patient', 'gender']}
              valueType="select"
              fieldProps={{
                precision: 1,
              }}
              readonly={true}
              dataSource={vsf.stores.dbenums.enums.SEX_DICT}
            />
            <VSFormItem
              label="年龄"
              name={['patientBirthday']}
              valueType="text"
              fieldProps={{
                precision: 1,
              }}
              readonly={true}
            />
          </VSFormLayout>
          <VSFormLayout
            key="1"
            columnCount={5}
            labelWidth={labelNumberWidth(3)}
          >
            <VSFormItem
              label="籍贯"
              name={['nativePlace']}
              valueType="text"
              fieldProps={{
                width: '140%',
                precision: 4,
              }}
              readonly={true}
            />
          </VSFormLayout>
          <VSFormLayout
            key="1"
            columnCount={5}
            labelWidth={labelNumberWidth(8)}
          >
            <VSFormItem
              label="工作单位或地址"
              name={['serviceAgency']}
              valueType="text"
              fieldProps={{
                width: '100%',
                precision: 2,
                placeholder: '',
              }}
              readonly={true}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={2} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['medicalDiagnosis']}
            label="初步诊断"
            valueType="select"
            fieldProps={{
              showSearch: true,
              mode: 'multiple',
              className: 'widthAutoFill',
            }}
            searchKey="patientName"
            dataSource={async () => {
              const res =
                await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
                  { qto: { size: 1000, from: 0 } },
                );
              return res?.data?.result?.map((item) => ({
                label: item.diagnosisName,
                value: item.diagnosisName,
              }));
            }}
          />
          <VSFormItem
            label="门诊/住院号"
            name={['id']}
            valueType="text"
            fieldProps={{
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
        </VSFormLayout>
        <VSFormLayout key="3" columnCount={2}>
          <VSFormItem
            label="休息"
            name={['restDuration']}
            valueType="digit"
            fieldProps={{
              rules: [
                {
                  required: true,
                  width: '15rem',
                },
              ],
            }}
            addonAfter={
              <Select
                style={{ marginLeft: '-0.85rem' }}
                defaultValue={day}
                onChange={(v) => {
                  setDay(String(v));
                  const vs =
                    vsf?.refs?.medicalCertificate?.getFieldFormatValue();
                  vs.restDurationUnit = String(v);
                  vsf?.refs?.medicalCertificate?.setFieldsValue({ ...vs });
                }}
                dataSource={vsf?.stores?.dbenums?.enums?.TIME_UNITS_DICT}
              />
            }
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={1}>
          <div className="icon18">处理意见:(建议)</div>
          <VSFormItem
            label=""
            name={['memo']}
            className="widthAutoFill"
            valueType="textarea"
            fieldProps={{
              precision: 2,
              required: true,
            }}
          />
        </VSFormLayout>

        <VSFormLayout key="5" columnCount={5} labelWidth={labelNumberWidth(10)}>
          <VSFormLayout
            key="1"
            columnCount={5}
            labelWidth={labelNumberWidth(40)}
          >
            <VSFormItem
              label="公章"
              name={['visitDepartment', 'departmentName']}
              valueType="text"
              fieldProps={{
                width: '100%',
                precision: 2,
                placeholder: '',
              }}
              readonly={true}
            />
          </VSFormLayout>
          <VSFormLayout
            key="2"
            columnCount={5}
            labelWidth={labelNumberWidth(3)}
          >
            <VSFormItem
              label="医生"
              name={['doctorName']}
              valueType="text"
              fieldProps={{
                width: '100%',
                precision: 2,
                placeholder: '',
              }}
              readonly={true}
            />
            <VSFormItem
              label=""
              name={['timer']}
              valueType="text"
              fieldProps={{
                width: '100%',
                precision: 2,
                placeholder: '',
              }}
              readonly={true}
            />
          </VSFormLayout>
        </VSFormLayout>
      </VSForm>
      <div className="model_footer widthAutoFill icon14">
        未盖医疗专用章或涂改者无效
      </div>
    </div>
  );
}
