import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getPatientInpVisitInfo_9055d6: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-patient-inp-visit-info',
    parameterFomatter: (data?: {
      inpVisitId: number;
      patientId: number;
      displayId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitPatientInfoVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitController_getMedicalRecordVoById_150e6f: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-medical-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpVisitController_getPatientInpVisitInfoForSettle_eebc78: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-patient-inp-visit-info-for-settle',
    parameterFomatter: (data?: {
      inpVisitId: number;
      patientId: number;
      displayId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitPatientInfoVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
  InpSettleController_preSettle_5135f4: {
    method: 'post',
    url: '/api/inp-billing/pre-settle',
    parameterFomatter: (data?: {
      patientId: number;
      inpVisitId: number;
      newbornIds: number[];
      creditIndicator: boolean;
    }) => data,
    responseFormatter: (_, __, data?: InpPreSettleVoInpBillingEntranceWebVo) =>
      data,
  },
});
