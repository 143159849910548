import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

import { convertQueryAllByQueryCancelInAndOutTransferQtoRes2CancelInAndOutTransferPatientList } from './convert';
import MyDrawer from './drawer';

const adtStatus = [
  {
    label: '新入',
    value: 'TRANSFER_IN',
  },
  {
    label: '转科',
    value: 'TRANSFER_OUT',
  },
  {
    label: '出院',
    value: 'DISCHARGE',
  },
];

const searchTime = {
  TRANSFER_IN: 'admissionWardDateTimeRangeIn',
  TRANSFER_OUT: 'transferDateTimeRangeIn',
  DISCHARGE: 'dischargeDateTimeRangeIn',
};

const Index = (props) => {
  const {
    user,
    common: { currentApplication },
  } = props?.stores || {};

  const [search, setSearch] = useState(0);
  // 当前双击选择的table行数据
  const [currentRow, setCurrentRow] = useState<any>();

  const drawerRef = useRef<any>();

  return (
    <div className="nurse_cancel_operation">
      <div className="nurse_cancel_operation_search">
        <VSForm labelAlign="left" id="operationForm">
          <VSFormLayout
            columnCount={5}
            labelWidth={70}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <VSFormItem
              name={['adtStatusIs']}
              label=""
              valueType="radioButton"
              initialValue="TRANSFER_IN"
              dataSource={adtStatus}
              fieldProps={{
                onChange: () => {
                  setSearch(search + 1);
                },
              }}
            />
            <VSFormItem
              name={['date']}
              label=""
              fieldProps={{
                disabled: true,
                onChange: () => {
                  setSearch(search + 1);
                },
              }}
              valueType="date"
              initialValue={+new Date()}
            />
            <VSFormItem
              name={['admissionWardDateTimeRangeIn']}
              label=""
              valueType="select"
              initialValue="today"
              dataSource={[
                {
                  label: '今天',
                  value: 'today',
                },
                {
                  label: '昨天',
                  value: 'yesterday',
                },
              ]}
              fieldProps={{
                showSearch: true,
                width: 100,
                onChange: (v) => {
                  if (v == 'today') {
                    vsf.refs.operationForm.setFieldsValue({
                      date: +new Date(),
                    });
                  } else if (v == 'yesterday') {
                    vsf.refs.operationForm.setFieldsValue({
                      date: dayjs(+new Date()).subtract(1, 'day').format(),
                    });
                  }
                  setSearch(search + 1);
                },
              }}
            />

            <VSFormItem
              name={['displayIdIs']}
              label="患者ID"
              valueType="text"
            />
            <Button
              style={{ marginLeft: 30 }}
              type="primary"
              onClick={() => {
                setSearch(search + 1);
              }}
            >
              查询
            </Button>
          </VSFormLayout>
        </VSForm>
      </div>
      <div className="nurse_cancel_operation_body">
        <VSPromiseTable
          id="cancelInAndOutTransferPatientList"
          className="table_overflow_310
          aspirin-table-nopadding
          aspirin-table-header-background-color
          aspirin-table-row-hover-background-color
          aspirin-table-body-empty-transparent-background-color
        "
          pagination={false}
          scroll={{ y: getScrollY(300) }}
          onFetch={async (params) => {
            const searchParams = vsf.refs.operationForm.getFieldsValue();
            const time =
              searchParams?.admissionWardDateTimeRangeIn == 'today'
                ? {
                    begin: dayjs(+new Date()).format('YYYY-MM-DD 00:00:00'),
                    end: dayjs(+new Date())
                      .add(1, 'day')
                      .format('YYYY-MM-DD 00:00:00'),
                    beginInclude: true,
                    endInclude: true,
                  }
                : {
                    begin: dayjs(+new Date())
                      .subtract(1, 'day')
                      .format('YYYY-MM-DD 00:00:00'),
                    end: dayjs(+new Date()).format('YYYY-MM-DD 00:00:00'),
                    beginInclude: true,
                    endInclude: false,
                  };

            const res =
              await vsf?.services?.ClinicInpVisitController_queryAllByQueryCancelInAndOutTransferQto_7091f3?.(
                {
                  qto: {
                    from: params?.pagination?.from ?? 0,
                    size: params?.pagination?.size ?? 1000,
                    wardIdIs: currentApplication?.department?.id,
                    ...{
                      [searchTime[searchParams?.adtStatusIs]]: time,
                    },
                    displayIdIs:
                      searchParams?.displayIdIs?.length > 0
                        ? searchParams?.displayIdIs
                        : null,
                  },
                  ext: {
                    cancelInAndOutTransferEnum: searchParams?.adtStatusIs,
                  },
                },
              );
            const output =
              convertQueryAllByQueryCancelInAndOutTransferQtoRes2CancelInAndOutTransferPatientList?.(
                res,
              );
            const _id =
              drawerRef?.current?.currentValue
                ?.saveInpVisitForCancelInAndOutTransferBto?.id;

            return {
              data: _id ? output?.filter((i) => i?.id != _id) : output,
              // data: output,
              total: res?.data?.count ?? res?.data?.length ?? 0,
            };
          }}
          extraParams={{ search }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                drawerRef?.current?.handelOpen(true, record);
                setCurrentRow(record);
              },
            };
          }}
        >
          <VSTableColumn
            dataIndex={['status']}
            title="状态"
            valueType="select"
            dataSource={[
              { label: '转入', value: 'TRANSFER_IN' },
              { label: '转出', value: 'TRANSFER_OUT' },
              { label: '出院', value: 'DISCHARGE' },
              { label: '新入', value: 'NEW_IN' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['displayId']}
            title="患者ID"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['inpVisitId']}
            title="住院号"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            ellipsis
            formItemProps={{
              rules: [
                {
                  message: '姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['gender']}
            title="性别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.SEX_DICT}
            fieldProps={{}}
          />

          {/* <VSTableColumn
            dataIndex={['birthday']}
            title="出生日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          /> */}
          <VSTableColumn dataIndex={['age']} title="年龄" valueType="text" />

          <VSTableColumn
            dataIndex={['identityCode']}
            title="费别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['admissionDepartmentName']}
            title="入院科室"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['admissionDateTime']}
            title="入院时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['dischargeDepartmentName']}
            title="出院科室"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['dischargeDateTime']}
            title="出院时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />
        </VSPromiseTable>
      </div>
      <MyDrawer
        ref={drawerRef}
        data={currentRow}
        status={vsf.refs?.operationForm?.getFieldsValue()?.adtStatusIs}
      ></MyDrawer>
    </div>
  );
};
export default definePage(Index);

// 取消入出转
