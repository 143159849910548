import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockController_queryDrugs_529e82: {
    method: 'post',
    url: '/api/drug-storage/query-drugs',
    parameterFomatter: (data?: {
      qry: DrugProductHaveStockAndPriceQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSplitVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugDrugController_queryPagedByDrugProductSpecificationVsStorageQto_172d45: {
    method: 'post',
    url: '/api/drug-drug/query-paged-by-drug-product-specification-vs-storage-qto',
    parameterFomatter: (data?: {
      qto: DrugProductSpecificationVsStorageQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugProductDictionaryNameFormStockPriceVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
