import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderExtensionController_getAllByQueryMasterByWardQto_97e768: {
    method: 'post',
    url: '/api/clinic-record-order-extension/get-all-by-query-master-by-ward-qto',
    parameterFomatter: (data?: {
      qto: QueryMasterByWardQtoClinicRecordOrderExtensionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformLabelSettingMasterVoClinicRecordOrderExtensionEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderPerformController_queryPagedByOrderPerformPlanIndexQto_e60d36:
    {
      method: 'post',
      url: '/api/clinic-record-order-perform/query-paged-by-order-perform-plan-index-qto',
      parameterFomatter: (data?: {
        qto: OrderPerformPlanIndexQtoClinicRecordOrderPerformPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrderPerformPlanIndexVoClinicRecordOrderPerformEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  AccountController_staffLoginWithoutResponseCookies_5fd9d3: {
    method: 'post',
    url: '/api/user-account/staff-login-without-cookies',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
