import { cloneDeep } from 'lodash';

export const insertBlock = (A, start, length, insertAt) => {
  // 创建数组A的副本
  const B = cloneDeep(A);

  // 从副本中移除子数组C
  const C = B.splice(start, length);

  // 如果插入的下标大于移除的起始下标，需要调整插入的下标
  if (insertAt > start) {
    insertAt = insertAt - length;
  }

  // 将子数组C插入到数组B的指定下标处
  B.splice(insertAt, 0, ...C);

  // 返回修改后的数组B
  return B;
};
