import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamClassDictionaryDtoController_getExamClassWithDepartmentById_9db767: {
    method: 'post',
    url: '/api/exam/get-exam-class-with-department-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithDepartmentVoExamEntranceWebVo,
    ) => data,
  },
  ExamClassDictionaryBOController_addDepartmentToExamClass_e05bca: {
    method: 'post',
    url: '/api/exam/add-department-to-exam-class',
    parameterFomatter: (data?: {
      btoParam: AddDepartmentToExamClassBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithDepartmentVoExamEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9: {
    method: 'post',
    url: '/api/organization/get-all-by-department-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
