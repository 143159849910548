import React from 'react';

const data = [
  {
    value: '91.2%',
    name: '氧合血红蛋白',
  },
  {
    value: '3.3mmol/L',
    name: '钾离子',
  },
  {
    value: '1.08mmol/L',
    name: '钙离子',
  },
];

const Index = (props) => {
  const { data } = props;

  const handelList = () => {
    return data?.filter((item) => {
      return item?.orderApplyDto?.abnormal;
    });
  };
  console.log(data, '=');

  return (
    <div className="test-block">
      {/* data */}
      {data?.map((item, index) => {
        return (
          <div key={index} className="item">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  background: '#FF7E7E',
                }}
              ></div>
              <div style={{ margin: '0 5px 0' }}>
                {item?.result}
                {item?.unit}
              </div>
              {/* 差icon */}
              <div className="item_reportItemName" style={{ color: '#F54141' }}>
                {item?.abnormalSignature}
              </div>
            </div>
            <div className="item_reportItemName">{item?.reportItemName}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
