import SelectForm, {
  askSelectFormInitValue,
} from '@/pages/Index/components/select_form';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSForm,
  Section,
  Divider,
  Form,
} from '@vs/vsf-kit';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { labelNumberWidth } from '@/utils';

const NewSelectForm = (props) => {
  const { form, formKey } = props;

  const getDepartmentDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
        {
          qto: {
            branchInstitutionIdIn: [props.item],
          },
        },
      );
    return res.data.map((item) => ({
      label: `${item.departmentName}(${item.branchInstitution?.institutionName})`,
      value: item.id,
    }));
  }, [props.item]);

  /**
   * 预约为false删除已选预约选项
   */
  useUpdateEffect(() => {
    if (!props.value?.needAppointIndicator) {
      form.setFieldValue([...formKey, 'appointMethod'], undefined);
    }
  }, [props.value?.needAppointIndicator]);
  return (
    <>
      <VSFormItem
        name={['examItemId']}
        label="病理项目ID"
        valueType="digit"
        style={{
          display: 'none',
        }}
        fieldProps={{}}
      />
      <VSFormLayout
        key="16"
        columnCount={3}
        gutter={50}
        labelWidth={labelNumberWidth(7)}
      >
        <VSFormItem
          name={['useScope']}
          label="使用范围"
          valueType="select"
          initialValue={'ALL'}
          dataSource={[
            { label: '全院', value: 'ALL' },
            { label: '住院', value: 'INP' },
            { label: '门诊', value: 'OUTP' },
          ]}
          rules={[{ required: true }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['examLocation']}
          label="病理地点"
          valueType="text"
          rules={[]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['needAppointIndicator']}
          label="需预约"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['appointMethod']}
          label="预约方式"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.APPOINT_METHOD_DICT}
          rules={[
            {
              required: props?.value?.needAppointIndicator,
            },
          ]}
          fieldProps={{
            disabled: !props?.value?.needAppointIndicator,
          }}
        />
        <VSFormItem
          name={['dischargeCheckType']}
          label="出院校验类型"
          valueType="select"
          dataSource={[
            { label: '报告', value: 'REPORT' },
            { label: '计费', value: 'CHARGE' },
            { label: '全部', value: 'ALL' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['radiationFilmOption']}
          label="默认胶片选项"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.RAD_FILM_OPTION}
          rules={[]}
          fieldProps={{}}
        />
      </VSFormLayout>
      <VSFormLayout
        key="19"
        columnCount={1}
        gutter={50}
        labelWidth={labelNumberWidth(7)}
      >
        <VSFormItem
          name={['examItemVsDepartmentBtoList']}
          label="限定开单科室"
          valueType="select"
          dataSource={getDepartmentDataSource}
          fieldProps={{
            mode: 'multiple',
          }}
        />
        <VSFormItem
          name={['guideInfo']}
          label="导引信息"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 500 },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['notice']}
          label="注意事项"
          valueType="textarea"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 65536 },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />
        <VSFormItem
          name={['simpleNotice']}
          label="简版注意事项"
          valueType="textarea"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 65536 },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />
        <VSFormItem
          name={['effect']}
          label="作用"
          valueType="textarea"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />
        <VSFormItem
          name={['indicationDisease']}
          label="适应症"
          valueType="textarea"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />
      </VSFormLayout>
    </>
  );
};

const NewBody = (props) => {
  /**
   * 需要过滤除了当前的已选的选项
   */
  const newGetBody = async () => {
    const resolve = await props.getBody();
    const allSelectedOptions = vsf.refs.ExamForm.getFieldValue([
      'examVsClinicItemDetailDto',
      'examItemVsBodyBtoList',
    ]);

    /**
     * 先过滤其他的已选项
     */
    const otherSelectedOptions = allSelectedOptions
      ?.map((item) => {
        return (
          item?.value?.examBodyId ??
          item?.examBodyId ??
          item?.value?.examBody?.id ??
          item?.examBody?.id
        );
      })
      ?.filter((item) => item !== props?.value?.examBody?.id);

    /**
     * 然后过滤出未选项
     */
    return resolve?.filter((item) => {
      return !otherSelectedOptions?.includes(item.id);
    });
  };

  const [form] = Form?.useForm();

  const { status } = Form?.Item?.useStatus();

  useUpdateEffect(() => {
    if (status === 'validating') {
      form?.validateFields();
    }
  }, [status]);

  useUpdateEffect(() => {
    if (props.value?.examBody?.id === undefined) {
      props?.onChange?.(undefined);
      form?.setFieldValue('chargeTimes', undefined);
    }
  }, [props.value?.examBody]);

  return (
    <Form
      form={form}
      onValuesChange={(...[, value]) => props?.onChange?.(value)}
      initialValues={props?.value}
    >
      <VSFormItem
        name={['examBody']}
        valueType="select"
        dataSource={newGetBody}
        fieldNames={{
          label: 'examBodyName',
          value: 'id',
        }}
      />
      <VSFormItem
        name={['chargeTimes']}
        valueType="digit"
        rules={[
          {
            required: props.value?.examBody,
            message: '请输入病理次数',
          },
        ]}
        fieldProps={{
          placeholder: '---次数---',
          disabled: !props.value?.examBody,
          width: '100%',
        }}
      />
    </Form>
  );
};

const ExamItemEdit = (props) => {
  const [dataSource, setDataSource] = useState([]);
  /**
   * 设置参数
   */
  const getValue = useCallback(async () => {
    const res =
      await vsf.services.ClinicItemBaseController_getExamClinicItemDetailById_f60f06(
        { id: props.value.id },
      );
    if (res.code === 200) {
      const {
        examVsClinicItemDetailDto: {
          examItemVsBodyDtoList: body,
          examItemVsMethodDtoList: method,
          examItemVsDepartmentDtoList,
          examItemExtensionDtoList: examItemExtensionBtoList,
          ...clinicItemDetailDto
        },
        ...rest
      } = res?.data;
      vsf.refs.ExamForm.setFieldsValue({
        ...rest,
        examVsClinicItemDetailDto: {
          ...clinicItemDetailDto,
          examItemVsBodyBtoList: body?.map((item) => ({
            value: {
              ...item,
              examBodyId: item?.examBody?.id,
            },
          })),
          examItemVsMethodBtoList: method.map((item) => ({
            ...item,
            examMethodName: item?.examMethod?.examMethodName,
            examMethodId: item?.examMethod?.id,
          })),
          examItemExtensionBtoList: askSelectFormInitValue({
            array: examItemExtensionBtoList.map((item) => {
              return {
                ...item,
                examItemVsDepartmentBtoList:
                  examItemVsDepartmentDtoList?.filter((option) => {
                    const { department } = option;
                    option.label = department?.departmentName;
                    option.value = department?.id;
                    return (
                      department?.branchInstitution?.id ===
                      item?.branchInstitution?.id
                    );
                  }),
              };
            }),
            select: ['branchInstitution', 'id'],
          }),
        },
      });
    }
  }, [props.value]);

  const isValue = useMemo(() => props.value, []);
  const isLimit = useMemo(() => {
    try {
      const {
        examVsClinicItemDetailDto: {
          limitAgeSymbol,
          limitAgeUnit,
          limitAgeNumber,
        },
      } = props.value;
      return limitAgeSymbol || limitAgeUnit || limitAgeNumber;
    } catch {
      return false;
    }
  }, [props.value]);

  const itemCode = useMemo(() => {
    return (
      new Date().toLocaleDateString().replaceAll('/', '') +
      (Math.random() * (99 * 10000)).toFixed(0)
    );
  }, []);

  /**
   * 使用院区来自分类
   */
  const getDataInfo = useCallback(async () => {
    const res =
      await vsf?.services?.ExamClassDictionaryWithInstitutionDtoController_getExamClassWithInstitutionById_acabd3?.(
        {
          id: isValue
            ? props.value?.examVsClinicItemDetailDto?.directExamClassId
            : props.active?.id,
        },
      );
    setDataSource(
      res.data?.examClassDictionaryExtensionDtoList.map((item) => ({
        label: item.branchInstitution?.institutionName,
        value: item.branchInstitution.id,
      })),
    );
  }, []);

  const getBody = useCallback(async () => {
    const res =
      await vsf?.services?.ExamBodyDictionaryDtoController_getByExamClassId_99e20a?.(
        {
          examClassId: isValue
            ? props.value?.examVsClinicItemDetailDto.examClass?.id
            : props?.data[0]?.id,
        },
      );
    return res.data;
  }, []);
  /**
   * 获取《方法》函数
   */
  const getMethod = useCallback(async () => {
    const res =
      await vsf?.services?.ExamMethodDictionaryDtoController_getByExamClassId_994cf6?.(
        {
          examClassId: isValue
            ? props.value?.examVsClinicItemDetailDto.examClass?.id
            : props?.data[0]?.id,
        },
      );
    return res.data.map((item) => {
      /**
       * 这样做是因为修改需要传id
       */
      const { id, ...rest } = item;
      const method = vsf.refs.ExamForm.getFieldValue([
        'examVsClinicItemDetailDto',
        'examItemVsMethodBtoList',
      ]);
      if (method && isValue) {
        const index = method.findIndex((el) => item?.id === el?.examMethodId);
        return index !== -1
          ? method[index]
          : {
              ...rest,
              examMethodId: id,
            };
      }
      return {
        ...rest,
        examMethodId: id,
      };
    });
  }, []);

  useEffect(() => {
    if (!isValue) {
      if (props.data) {
        const [examClass, examSubClass, examThirdClass] = props.data.map(
          (item) => ({
            examClassName: item?.examClassName,
            id: item?.id,
          }),
        );
        vsf.refs.ExamForm.setFieldsValue({
          examVsClinicItemDetailDto: {
            examClass,
            examSubClass,
            examThirdClass: examThirdClass ?? { id: null },
            directExamClassId: props?.active?.id,
          },
        });
      }
    }
  }, [props.data]);

  useEffect(() => {
    getDataInfo();
    if (isValue) getValue();
  }, []);
  console.log(props?.value);
  return (
    <VSControlledForm
      vsid="25275"
      id="ExamForm"
      labelAlign="left"
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
    >
      <Section title="全院区通用">
        <Divider />
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['examVsClinicItemDetailDto', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['examVsClinicItemDetailDto', 'directExamClassId']}
          label="直接关联主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout
          key="3"
          columnCount={3}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examClass', 'examClassName']}
            label="病理大类"
            fieldProps={{ disabled: true }}
            valueType="text"
          />

          <VSFormItem
            name={[
              'examVsClinicItemDetailDto',
              'examSubClass',
              'examClassName',
            ]}
            label="病理子类"
            fieldProps={{ disabled: true }}
            valueType="text"
          />

          <VSFormItem
            name={[
              'examVsClinicItemDetailDto',
              'examThirdClass',
              'examClassName',
            ]}
            label="病理小类"
            fieldProps={{ disabled: true, placeholder: '' }}
            valueType="text"
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={3} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examClass', 'id']}
            label="病理大类ID"
            valueType="digit"
            style={{
              display: 'none',
            }}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examSubClass', 'id']}
            label="病理子类ID"
            valueType="digit"
            style={{
              display: 'none',
            }}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examThirdClass', 'id']}
            label="病理小类ID"
            valueType="digit"
            style={{
              display: 'none',
            }}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout
          key="5"
          columnCount={3}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['itemCode']}
            label="项目代码"
            valueType="text"
            initialValue={itemCode}
            rules={[{ required: true }]}
            fieldProps={{
              disabled: isValue,
            }}
          />

          <VSFormItem
            name={['clinicItemName']}
            label="病理项目名称"
            valueType="text"
            rules={[
              {
                message: '项目名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
              { required: true },
            ]}
            fieldProps={{
              onChange: ({ target }) => {
                vsf.refs.ExamForm.setFieldsValue({
                  inputCode: askRomanAlphabet(target.value),
                });
              },
            }}
          />

          <VSFormItem
            name={['inputCode']}
            label="拼音码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              { required: true },
              () => ({
                validator(_, value) {
                  if (String(value).includes(',')) {
                    return Promise.reject('多音字待确认');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout
          key="6"
          columnCount={4}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['limitGender']}
            label="限制性别"
            valueType="select"
            dataSource={[
              { label: '无限制', value: 'NO_LIMIT' },
              { label: '限男性', value: 'MALE_ONLY' },
              { label: '限女性', value: 'FEMALE_ONLY' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'limitAgeSymbol']}
            label="限制年龄符号"
            valueType="select"
            dataSource={[
              { label: '大于', value: 'GT' },
              { label: '大于等于', value: 'GTE' },
              { label: '等于', value: 'EQUAL' },
              { label: '小于', value: 'LT' },
              { label: '小于等于', value: 'LTE' },
            ]}
            rules={[
              {
                required: isLimit,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'limitAgeNumber']}
            label="限制年龄数值"
            valueType="digit"
            fieldProps={{}}
            rules={[
              {
                required: isLimit,
              },
            ]}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'limitAgeUnit']}
            label="限制年龄单位"
            valueType="select"
            rules={[
              {
                required: isLimit,
              },
            ]}
            dataSource={[
              {
                label: '年',
                value: 'YEAR',
              },
              {
                label: '月',
                value: 'MONTH',
              },
              {
                label: '日',
                value: 'DAY',
              },
              {
                label: '周',
                value: 'WEEK',
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>

        <VSFormLayout
          key="8"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examItemVsBodyBtoList']}
            valueType="listBox"
            label="病理部位"
            fieldProps={{
              children: <NewBody getBody={getBody} />,
            }}
            transform={(value) => {
              const set = (item) =>
                item?.value?.examBodyId ?? item?.value?.examBody?.id;
              return {
                examVsClinicItemDetailDto: {
                  examItemVsBodyBtoList: value?.filter(set)?.map((item) => {
                    return {
                      ...item?.value,
                      examBodyId: set(item),
                    };
                  }),
                },
              };
            }}
            rules={[
              () => {
                return {
                  validator(...[, value]) {
                    return value
                      ?.filter((item) => item?.value)
                      ?.map((item) => item?.value?.chargeTimes)
                      ?.includes(undefined)
                      ? Promise?.reject()
                      : Promise?.resolve();
                  },
                };
              },
            ]}
          />
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examItemVsMethodBtoList']}
            label="病理方法"
            valueType="select"
            dataSource={getMethod}
            fieldNames={{
              label: 'examMethodName',
              value: 'examMethodId',
            }}
            fieldProps={{
              mode: 'multiple',
            }}
          />
        </VSFormLayout>

        <VSFormLayout key="11" columnCount={6}>
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'anesthesiaIndicator']}
            label="是否默认麻醉"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'modifyAnesthesiaIndicator']}
            label="是否可选择麻醉"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={[
              'examVsClinicItemDetailDto',
              'emptyAnesthesiaAssessmentIndicator',
            ]}
            label="是否麻醉评估"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'emptyStomachIndicator']}
            label="是否空腹"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'suffocateUrineIndicator']}
            label="是否憋尿"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['examVsClinicItemDetailDto', 'bedsideIndicator']}
            label="是否床边"
            valueType="check"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout
          key="12"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'notice']}
            label="注意事项"
            valueType="textarea"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
            ]}
            fieldProps={{
              autoSize: { minRows: 3, maxRows: 5 },
            }}
          />
        </VSFormLayout>
        <VSFormLayout
          key="13"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'simpleNotice']}
            label="简版注意事项"
            valueType="textarea"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
            ]}
            fieldProps={{
              autoSize: { minRows: 3, maxRows: 5 },
            }}
          />
        </VSFormLayout>
        <VSFormLayout
          key="14"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'effect']}
            label="作用"
            valueType="textarea"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
            ]}
            fieldProps={{
              autoSize: { minRows: 3, maxRows: 5 },
            }}
          />
        </VSFormLayout>
        <VSFormLayout
          key="15"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(7)}
        >
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'indicationDisease']}
            label="适应症"
            valueType="textarea"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
            ]}
            fieldProps={{
              autoSize: { minRows: 3, maxRows: 5 },
            }}
          />
        </VSFormLayout>
      </Section>
      <Section title="各院区使用">
        <Divider />
        <VSFormLayout key="16" columnCount={1}>
          <VSFormItem
            name={['examVsClinicItemDetailDto', 'examItemExtensionBtoList']}
            valueType="custom"
            rules={[{ required: true, message: '请选择院区' }]}
          >
            <SelectForm
              dataSource={dataSource}
              label="院区"
              selectAllText="全部院区"
              selectLayoutProps={{
                columnCount: 1,
                labelWidth: labelNumberWidth(7),
                gutter: 50,
              }}
              rules={[{ required: true, message: '' }]}
            >
              <NewSelectForm />
            </SelectForm>
          </VSFormItem>
        </VSFormLayout>
      </Section>
    </VSControlledForm>
  );
};

export default ExamItemEdit;
