import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable, VSTableToolbar, VSTableAddition, withRef } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from '@/module/Icon';
const DrugProductMangerTable = React.forwardRef((props, ref) => {

  const { drugInfo, onSelectChange } = props

  const [selectedRow, setSelectedRow] = useState();
  const [canRowSelection, setCanRowSelection] = useState(true)
  const [drugFirmList, setDrugFirmList] = useState([])

  const loadDrugFirmList = async (searchCode) => {
    try {
      //
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugFirmDictionaryDtoController_queryDrugFirmDictionary_25e16e?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {},
          },
        ); // TODO: 你可能需要整理返回数据格式
      setDrugFirmList(res?.data?.map(item => ({
        label: item?.firmName,
        value: item?.id,
        firmClass: item?.firmClass
      })) ?? [])
    } catch (error) {
      setDrugFirmList([])
    }
  }

  useEffect(() => {
    loadDrugFirmList()
  }, [])

  const listRef = useRef([])

  const onFetch = useCallback(async () => {
    if (drugInfo?.drug?.id) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugController_getProductListToEdit_216b79?.({
          // TODO: 请检查以下这部分传参是否符合需求
          drugId: drugInfo?.drug?.id,
        }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }

      const _data = res?.data?.result ?? res?.data ?? []
      listRef.current = _data
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  }, [drugInfo])

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return Promise.resolve(listRef.current)
      },
    }
  })

  const handelAdd = async (params) => {
    setCanRowSelection(true)
    return params
  }

  const handelRowClick = async record => {
    if (canRowSelection) {
      setSelectedRow(record)
      onSelectChange?.(record)
    }
  }

  const handelRemove = async (record) => {
    const res = await vsf?.services?.DrugController_deleteDrugProduct_8ced4d?.({
      btoParam: {
        id: drugInfo?.drug?.id,
        drugProductBtoList: [
          {
            id: record?.id
          }
        ]
      },
      extApiParam: record?.extApiParam,
    });
    vsf?.refs?.DrugListTable?.reload?.()
    return res?.code === 200;
  }
  return (
    <VSPromiseTable
      id="DrugProductMangerTable"
      scroll={{ x: 1300 }}
      className='aspirin-table aspirin-table-nopadding aspirin-table-autoheight'
      editable={{
        editType: 'single',
        editText: <Icon
          type="icon-bianji"
          size={24}
        />,
        deleteText: <Icon
          type="icon-shanchu1"
          size={24}
        />,
        columnProps: {
          width: 120
        },
        onFieldChange: (key, value, record, form) => {
          if (!value?.drugSpecificationDictionary?.hasOwnProperty('drugSpecification')) {
            form?.setFieldValue(key, {
              ...record,
              drugSpecificationDictionary: {
                ...record?.drugSpecificationDictionary,
                drugSpecification: `${record?.drugSpecificationDictionary?.dosagePerUnit ?? ''}${vsf.stores.dbenums.enums.DOSAGE_UNITS_DICT?.find(item => item.value === record?.drugSpecificationDictionary?.dosageUnit)?.label ?? ''}`
              }
            })
          }
        }
      }}
      vsid="92417"
      onFetch={onFetch}
      onRecord={() => {
        setCanRowSelection(false)
        return {
          id: `create_${Math.random()}`
        }
      }}
      onAdd={handelAdd}
      onRemove={handelRemove}
      onValuesChange={(v) => {
        listRef.current = v
      }}
      rowSelection={
        canRowSelection ? {
          hideColumn: true,
          selectType: 'single',
          selectedRows: selectedRow ? [selectedRow] : [],
        } : {}
      }
      rowClick={{
        onRowClick: handelRowClick
      }}
    >

      <VSTableToolbar title="规格信息" style={{ padding: '0 24px' }}>
        <VSTableAddition children="新增" position='top' />
      </VSTableToolbar>

      <VSTableColumn
        dataIndex={['drugFirm', 'id']}
        title="主键"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={272}
        dataIndex={['drugProductName']}
        title="*商品名称"
        valueType="text"
        required
        formItemProps={{
          rules: [
            { required: true, message: '请输入商品名称' }
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={158}
        dataIndex={['drugSpecificationDictionary', 'dosagePerUnit']}
        title="*最小单位剂量"
        valueType="digit"
        formItemProps={{
          rules: [
            { required: true, message: '请输入最小单位剂量' }
          ]
        }}
      />


      <VSTableColumn
        width={180}
        dataIndex={['drugSpecificationDictionary', 'dosageUnit']}
        title="*单位含量"
        valueType="codeTableSelect"
        formItemProps={{
          rules: [
            { required: true, message: '请选择单位含量' },
          ],
        }}
        fieldProps={{
          dataSource: vsf.stores.dbenums.enums.DOSAGE_UNITS_DICT,
          showSearch: true
        }}
      />

      <VSTableColumn
        width={108}
        dataIndex={['drugSpecificationDictionary', 'drugSpecification']}
        title="*规格"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入规格' },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['drugSpecificationDictionary', 'unit']}
        title="*单位"
        valueType="codeTableSelect"
        formItemProps={{
          rules: [
            { required: true, message: '请选择单位' },
          ],
        }}
        fieldProps={{
          dataSource: vsf.stores.dbenums.enums.MEASURES_DICT,
          showSearch: true
        }}
      />

      <VSTableColumn
        width={124}
        dataIndex={['ddd']}
        title="DDD计算值"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={272}
        dataIndex={['drugFirm', 'firmName']}
        title="*厂家"
        valueType="codeTableSelect"
        formItemProps={{
          rules: [
            { required: true, message: '请选择厂家' },
          ],
        }}
        fieldProps={{
          dataSource: drugFirmList?.filter(item => item?.firmClass === 'PRODUCER'),
          showSearch: true,
        }}

      />

      <VSTableColumn
        width={72}
        initialValue={false}
        dataIndex={['stopIndicator']}
        title="停用"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        initialValue={false}
        dataIndex={['baseDrugClass']}
        title="基药属性"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        initialValue={false}
        dataIndex={['centralPurchaseIndicator']}
        title="是否集采"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={172}
        dataIndex={['qualificationCode']}
        title="批准文号"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={72}
        initialValue={false}
        dataIndex={['nationNegotiateIndicator']}
        title="国谈"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={72}
        initialValue={false}
        dataIndex={['outpSpecialIndicator']}
        title="门特"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={72}
        initialValue={false}
        dataIndex={['outpProfessionalIndicator']}
        title="门专"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['storeCondition']}
        title="贮藏条件"
        valueType="codeTableSelect"
        dataSource={vsf.stores.dbenums.enums.STORE_CONDITION_DICT}
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['medicalRecordClass']}
        title="*病案首页分类"
        valueType="codeTableSelect"
        formItemProps={{
          rules: [
            { required: true, message: '请选择病案首页分类' }
          ],
        }}
        dataSource={vsf.stores.dbenums.enums.MR_FEE_CLASS_DICT}
        fieldProps={{
          showSearch: true
        }}
      />

      <VSTableColumn
        width={108}
        dataIndex={['supplier']}
        title="供货单位"
        valueType="codeTableSelect"
        formItemProps={{
        }}
        fieldProps={{
          dataSource: drugFirmList?.filter(item => item?.firmClass === 'SUPPLIER'),
          showSearch: true,
        }}
      />

      <VSTableColumn
        width={108}
        dataIndex={['source']}
        title="*来源"
        valueType="codeTableSelect"
        dataSource={[
          { label: '医院药品', value: 'IN_HOSPITAL' },
          { label: '自备药', value: 'SELF_PROVIDE' },
        ]}
        formItemProps={{
          rules: [
            { required: true, message: '请选择来源' }
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['insuranceNationCode']}
        title="国家编码"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 40 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['biddingId']}
        title="招标ID"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={172}
        dataIndex={['drugProductCode']}
        title="商品编码"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['useDescription']}
        title="用法描述"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['maxDosage']}
        title="最高用量"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['dayMaxDosage']}
        title="日最高剂量"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['specialAttribute']}
        title="特殊属性"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 30 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['maxDay']}
        title="最大使用天数"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['prescriptionRatio']}
        title="开单系数"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['conversionRatioNumerator']}
        title="颗粒剂转换比"
        valueType="digit"
        fieldProps={{}}
        render={(_, record => {
          return (
            <div className='aic'>
              <span>{record?.conversionRatioNumerator}</span>
              <span style={{ width: 20, background: '#F5F7FA' }}>/</span>
              <span>{record?.conversionRatioDenominator}</span>
            </div>
          )
        })}
      />

      {/* <VSTableColumn
        dataIndex={['conversionRatioDenominator']}
        title="颗粒剂转换比-分母"
        valueType="digit"
        fieldProps={{}}
      /> */}

      <VSTableColumn
        width={108}
        initialValue={false}
        dataIndex={['donationIndicator']}
        title="捐赠标志"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={72}
        initialValue={false}
        dataIndex={['drugRatioIndicator']}
        title="药比"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['selfCode']}
        title="老系统自编码"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['purchasePlatform']}
        title="采购平台"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={154}
        dataIndex={['supplierAdjustBasis']}
        title="供货商调整依据"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        dataIndex={['packageRatio']}
        title="包装系数"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={108}
        initialValue={false}
        dataIndex={['singleSettleIndicator']}
        title="独立结算"
        valueType="check"
        fieldProps={{}}
      />

      <VSTableColumn
        width={118}
        dataIndex={['drugStandardCode']}
        title="药品标准码"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 40 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={190}
        dataIndex={['dayMaxAmount']}
        title="当日开立数量上限"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        width={190}
        dataIndex={['twentyOneDayMaxAmount']}
        title="21日开立数量上限"
        valueType="digit"
        fieldProps={{}}
      />
    </VSPromiseTable>
  );
})

DrugProductMangerTable.displayName = 'DrugProductMangerTable'
export default withRef()(DrugProductMangerTable)
