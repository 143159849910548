import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
  ApplicationDtoController_getApplication_fbf818: {
    method: 'post',
    url: '/api/application/get-application',
    parameterFomatter: (data?: { template: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVoApplicationEntranceWebVo[],
    ) => data,
  },
  ApplicationBOController_createApplication_c83033: {
    method: 'post',
    url: '/api/application/createApplication',
    parameterFomatter: (data?: {
      btoParam: CreateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationDtoController_getApplicationById_1c4e15: {
    method: 'post',
    url: '/api/application/getApplicationById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto) =>
      data,
  },
  ApplicationBOController_updateApplication_62596e: {
    method: 'post',
    url: '/api/application/updateApplication',
    parameterFomatter: (data?: {
      btoParam: UpdateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
});
