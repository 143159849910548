//通用正则表达式文件
export const regex = /[^a-zA-Z]/;
//------------数字且不能带-
export const numberAndNegative = /^\d+$/;
export const numberAndNegativeMessage = '请输入正数';
// 正数
export const positive = /^\d+(\.\d)?$/;
export const positiveMessage = '请输入正数';

// 正整数
export const positiveInteger = /^\+?[1-9]\d*$/;
export const positiveIntegerMessage = '请输入正整数';
//------------身份证校验
export const idCard =
  /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[Xx\d]$/;
export const idCardMessage = '请输入正确的身份证格式';
// ------------护照
export const passport =
  /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/;
export const passportMessages = '请输入正确的护照格式';

// 手机号/座机
export const phoneNumber =
  /^(?:\+?86)?1[3-9]\d{9}$|^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
export const phoneNumberMessage = '请输入正确的电话格式';
// 邮编
export const postcode = /^[1-9]\d{5}(?!\d)$/;
export const postcodeMessage = '请输入正确的邮编格式';
// 天数，最多一位小数
export const day = /^\d+(\.\d{1,1})?$/;
export const dayMessage = '最多为一位小数';
// 身高
export const height = /^\d+(\.\d{1,2})?$/;
export const heightMessage = '请输入最多为两位小数的非负数';
//天数，最多四位整数，两位小数
export const dayOne = /(^[0-9]{1,4})+(\.[0-9]{1,2})?$/;
export const dayOneMessage = '最多为四位整数，两位小数的非负数';
// 时
export const hour = /^([0-9]|1[0-9]|2[0-3])$/;
// 分
export const minute = /^([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])$/;
// 四位整数两位小数
export const dayNumber = /^\d{4}(\.\d{1,2})?$/;
// 身份证
export const idNumberReg =
  /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
