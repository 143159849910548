export const billingAttributeEnums = [
  { label: '正常计价', value: 'COMMON_BILLING' },
  { label: '手工计价', value: 'MANUAL_BILLING' },
  { label: '不计价', value: 'NOT_BILLING' },
];
export const orderClassEnums = [
  { label: '西药', value: 'DRUG' },
  { label: '中药', value: 'HERB' },
  { label: '检验', value: 'LAB' },
  { label: '检查', value: 'EXAM' },
  { label: '治疗', value: 'TREAT' },
  { label: '手术', value: 'OPERATION' },
  { label: '麻醉', value: 'ANESTHESIA' },
  { label: '护理', value: 'NURSING' },
  { label: '膳食', value: 'MEAL' },
  { label: '营养', value: 'NUTRITION' },
  { label: '会诊', value: 'CONSULTATION' },
  { label: '用血', value: 'BLOOD' },
  { label: '其他', value: 'OTHER' },
  { label: '病理', value: 'PATHOLOGY' },
];
