import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitController_getVisitRecordList_034435: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-visit-record-list',
    parameterFomatter: (data?: {
      qto: OutpVisitQuerySimpleQtoClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[],
    ) => data,
  },
});
