import {
  Button,
  message,
  Input,
  DatePicker,
  Select,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  Modal,
} from '@vs/vsf-kit';
import locale from 'antd/es/date-picker/locale/zh_CN';
import cn from 'classnames';
import dayjs from 'dayjs';

import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  ref,
} from 'react';
// import { Select } from 'antd';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import MySteps from '@/pages/Index/components/step';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY, labelColWidth } from '@/utils';
import storage from '@/utils/storage';
import HeaderInfo from '@/pages/Index/components/head_info';
import {
  convertGenerateNewDischargeNoticeRes2DischargeNoticeEditForm,
  convertDischargeNoticeEditForm2DischargeNoticeReq,
  convertQueryAllByDiagnosisRecordWithDiagnosisQtoRes2DiagnosisRecordList,
  convertDiagnosisRecordList2SaveDiagnosisRecordListReq,
} from './convert';
import Info from '@/pages/Index/cpoe/center/header/Info/in_info';
import DiagnoseModal from './diagnoseModal';

const formItemWidth = 360;
const formItemLabelWidth = 140;

const Index = (props) => {
  const { stores } = props;
  const { user } = stores;
  const { inVisitInfo, initKey } = user;

  // form详情
  const [detail, setDetail] = useState();
  // 查到的出院记录出院记录
  const [inpPreTransferApply, setInpPreTransferApply] = useState();
  // 诊断弹窗
  const [diagnoseModal, setDiagnoseModal] = useState(false);
  // 主要诊断
  const [mainDiagnose, setMainDiagnose] = useState();
  // 其他诊断
  const [diagnoseList, setDiagnoseModalList] = useState([{}]);
  // 停止医嘱弹窗
  const [stopOrderModal, setStopOrderModal] = useState(false);
  const [isToday, setIsToday] = useState(true);
  const modalRef = useRef();

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // 禁用日期之前的函数
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('days'); // 禁用今天之前的日期
  };

  // 禁用时间之前的函数
  const disabledTime = (current) => {
    const res1 = dayjs(current).isSame(+new Date(), 'day');

    setIsToday(res1);
    return res1;
    // // dayjs().isSame(dayjs('2011-01-01'))
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(0, dayjs().hour()),
    disabledMinutes: () => range(0, dayjs().minute()),
    disabledSeconds: () => [55, 56],
  });

  useEffect(() => {
    getDetail();
  }, [initKey, inVisitInfo]);

  // 出院form
  const getDetail = useCallback(
    async (isRest = true) => {
      if (!inVisitInfo?.inpVisitId) return;
      // 查询有没有转科记录
      const inpTransferApplyRes =
        await vsf.services?.ClinicInpAdtController_getListInpPreDischargePatientWithPatientVoByInpVisitId_7bd55c?.(
          {
            inpVisitId: inVisitInfo?.inpVisitId,
          },
        );
      const inpTransferApplyResData = inpTransferApplyRes?.data;
      setInpPreTransferApply(inpTransferApplyResData);

      let formData = {};
      // 有出院记录
      if (inpTransferApplyResData?.length > 0) {
        formData = convertGenerateNewDischargeNoticeRes2DischargeNoticeEditForm(
          {
            data: inpTransferApplyResData?.[0],
          },
        );
        if (isRest) {
          vsf.refs.dischargeNoticeEditForm?.setFieldsValue(formData);
        } else {
          vsf.refs.dischargeNoticeEditForm?.setFieldValue(
            'diagnosisRecordWithDiagnosisVoList',
            formData?.diagnosisRecordWithDiagnosisVoList,
          );
        }
        setDetail(formData);
        // const [i, ...v] = formData?.diagnosisRecordWithDiagnosisVoList ?? [];
        // 主要诊断
        // setMainDiagnose(i);
        // 其他诊断
        // setDiagnoseModalList(v?.length <= 0 ? [{}] : v);
      } else {
        // 没有出院记录
        const inpVisitRes =
          await vsf.services?.ClinicInpAdtController_generateNewDischargeNotice_e1c133?.(
            {
              inpVisitId: inVisitInfo?.inpVisitId,
            },
          );
        formData =
          convertGenerateNewDischargeNoticeRes2DischargeNoticeEditForm(
            inpVisitRes,
          );
        if (isRest) {
          vsf.refs.dischargeNoticeEditForm?.setFieldsValue(formData);
        } else {
          vsf.refs.dischargeNoticeEditForm?.setFieldValue(
            'diagnosisRecordWithDiagnosisVoList',
            formData?.diagnosisRecordWithDiagnosisVoList,
          );
        }
        setDetail(formData);
        // const [i, ...v] = formData?.diagnosisRecordWithDiagnosisVoList ?? [];
        // i第一项，主要诊断，v剩余项，其他诊断

        // 主要诊断
        // setMainDiagnose(i);
        // 其他诊断
        // setDiagnoseModalList(v?.length <= 0 ? [{}] : v);
      }
    },
    [inVisitInfo],
  );
  // 创建/保存
  const handelSave = async () => {
    const resValidate =
      await vsf.refs.dischargeNoticeEditForm?.validateFieldsReturnFormatValue();
    console.log(resValidate);
    if (!resValidate) return;
    const params = vsf?.refs?.dischargeNoticeEditForm?.getFieldValue();

    let createInpPreDischargePatientBto = params;

    createInpPreDischargePatientBto =
      convertDischargeNoticeEditForm2DischargeNoticeReq({
        ...createInpPreDischargePatientBto,
        inpVisitId: inVisitInfo?.inpVisitId,
        patientId: inVisitInfo?.patientId,
      });
    const res =
      await vsf.services?.ClinicInpAdtController_dischargeNotice_5c9f9b?.({
        ...createInpPreDischargePatientBto,
      });
    if (res?.code == 200) {
      getDetail();
      message.info('保存成功');
    }
    return res?.code;
  };
  // 删除出院通知
  const handelDelete = () => {
    Modal.confirm({
      title: '提示',
      icon: null,
      centered: true,
      okText: '确定',
      cancelText: '取消',
      content: '删除出院通知',
      onOk: async () => {
        const res =
          await vsf.services?.ClinicInpAdtController_cancelDischargeNotice_9097a3(
            {
              deleteInpPreDischargePatientBto: {
                id: detail?.id,
              },
            },
          );
        if (res?.code == 200) {
          getDetail();
          message.info('删除成功');
        }
      },
    });
  };
  // 保存诊断
  const saveDiagnosisRecordList = async (v) => {
    const _params = convertDiagnosisRecordList2SaveDiagnosisRecordListReq(
      v?.map((item) => {
        return {
          ...item,
          id: inVisitInfo?.id,
          inpVisitId: inVisitInfo?.inpVisitId,
          patientId: inVisitInfo?.patientId,
        };
      }),
    );

    const res =
      await vsf?.services?.ClinicRecordController_saveOverviewDiagnosisRecordList_a21903(
        {
          ..._params,
          extApiParam: {},
        },
      );
    if (res?.code == 200) {
      getDetail(false);
      modalRef?.current?.handelOpen(false);
    } else {
      modalRef?.current?.handelOpen(true);
    }
  };
  // 停止医嘱
  const renderStopOrderModal = () => {
    return (
      <Modal
        open={stopOrderModal}
        title={
          <div
            style={{
              color: '#3276E8',
              fontSize: 20,
            }}
          >
            停止医嘱
          </div>
        }
        destroyOnClose
        centered
        width={700}
        bodyStyle={{ maxHeight: 400, overflowY: 'scroll' }}
        cancelText="取消"
        okText="确定"
        onOk={() => {
          setStopOrderModal(false);
        }}
        onCancel={() => {
          setStopOrderModal(false);
        }}
      >
        <div className="doctor_station_out_hospital_stopOrderModal">
          <div className="doctor_station_out_hospital_stopOrderModal_item1">
            [停]
          </div>
          <div className="doctor_station_out_hospital_stopOrderModal_item2">
            维生素C片0.1g*100
          </div>
          <div className="doctor_station_out_hospital_stopOrderModal_item3">
            1粒
          </div>
          <div className="doctor_station_out_hospital_stopOrderModal_item4">
            口服
          </div>
          <div className="doctor_station_out_hospital_stopOrderModal_item5">
            qd
          </div>
        </div>
        <div className="doctor_station_out_hospital_stopOrderModal_time">
          <span className="doctor_station_out_hospital_stopOrderModal_time_label">
            停止时间
          </span>
          <DatePicker
            locale={locale}
            showTime
            onChange={(v) => {
              console.log(v);
            }}
            onOk={(v) => {
              console.log(v);
            }}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className="doctor_station_out_hospital">
      <div style={{ marginBottom: 16 }}>{<Info></Info>}</div>
      <div className="doctor_station_out_hospital_content">
        <div className="doctor_station_out_hospital_content_title">
          <div className="doctor_station_out_hospital_content_title_tag"></div>
          <span>出院通知</span>
          <div
            style={{ margin: '0 auto 10px' }}
            className="doctor_station_out_hospital_content_title_steps"
          >
            <MySteps
              lineWidth={128}
              dataSource={[
                { title: '出院申请', value: 'NOTIFY_AUDIT' },
                { title: '护士出院通知', value: 'NOTIFY_SETTLE' },
              ]}
              current={detail?.wardNotifyStatus}
            ></MySteps>
          </div>
          <div
            style={{ visibility: 'hidden' }}
            className="doctor_station_out_hospital_content_title_tag"
          ></div>
          <span style={{ visibility: 'hidden' }}>出院通知</span>
        </div>
        <div className="doctor_station_out_hospital_content_form hideScroll">
          <VSControlledForm
            vsid="99630"
            id="dischargeNoticeEditForm"
            labelAlign="left"
            disabled={inpPreTransferApply?.length > 0}
            onChange={(v) => {
              // console.log(v, 'pppppppp');
            }}
          >
            <VSFormLayout
              labelCol={labelColWidth[8]}
              key="0"
              gutter={240}
              columnCount={2}
            >
              <VSFormItem
                name={['displayId']}
                label="患者ID"
                valueType="text"
                rules={[
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{ disabled: true }}
              />

              <VSFormItem
                name={['bedLabel']}
                label="床号"
                valueType="text"
                fieldProps={{ disabled: true }}
              />

              <VSFormItem
                name={['name']}
                label="姓名"
                valueType="text"
                rules={[
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={{ disabled: true }}
              />

              <VSFormItem
                name={['gender']}
                label="性别"
                valueType="select"
                fieldProps={{ disabled: true }}
                dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
              />

              <VSFormItem
                name={['admissionDateTime']}
                label="入院时间"
                valueType="date"
                fieldProps={{
                  width: '100%',
                  disabled: true,
                  showTime: true,
                }}
              />

              <VSFormItem
                name={['dischargeDateExpected']}
                label="预计出院时间"
                valueType="date"
                fieldProps={{
                  width: '100%',
                  required: true,
                  showTime: true,
                  disabledTime: isToday ? disabledDateTime : false,
                  disabledDate: disabledDate,
                  onSelect: (v) => {
                    dayjs(v).format('YYYY-MM-DD HH:mm:ss');
                    disabledTime(v);
                  },
                }}
                required
              />

              <VSFormItem
                name={['dischargeWay']}
                label="出院方式"
                valueType="select"
                fieldProps={{
                  showSearch: true,
                }}
                required
                dataSource={
                  vsf.stores.dbenums?.enums?.DISCHARGE_DISPOSITION_DICT
                }
              />
            </VSFormLayout>
            <VSFormLayout
              labelCol={labelColWidth[8]}
              key="1"
              gutter={240}
              columnCount={1}
            >
              <div className="doctor_station_out_hospital_content_form_textarea">
                <VSFormLayout
                  labelCol={labelColWidth[8]}
                  key="1"
                  gutter={240}
                  columnCount={1}
                >
                  <VSFormItem
                    name={['diagnosisRecordWithDiagnosisVoList']}
                    label="诊断"
                    valueType="textarea"
                    fieldProps={{
                      disabled: true,
                      autoSize: {
                        maxRows: 4,
                        minRows: 4,
                      },
                    }}
                    convertValue={(v) => {
                      return v
                        ?.map((item) => {
                          return item?.diagnosisDescription;
                        })
                        ?.join('；');
                    }}
                  />
                </VSFormLayout>
                <div
                  className="doctor_station_out_hospital_content_form_textarea_icon"
                  onClick={() => {
                    // getAllByDiagnosisRecord();
                    if (
                      inpPreTransferApply?.length > 0 ||
                      !inVisitInfo?.inpVisitId
                    )
                      return;
                    modalRef?.current?.handelOpen(true);
                  }}
                >
                  <Icon size={18} type="icon-wudibianji"></Icon>
                </div>
              </div>
            </VSFormLayout>
          </VSControlledForm>
        </div>
      </div>
      <div className="doctor_station_out_hospital_bottom">
        <Button
          className="doctor_station_out_hospital_bottom_delete"
          onClick={handelDelete}
          style={{ color: '#3276E8', border: '1px solid #3276E8' }}
          disabled={
            inpPreTransferApply?.length <= 0 ||
            detail?.wardNotifyStatus === 'NOTIFY_SETTLE'
          }
        >
          删除
        </Button>
        <Button
          type="primary"
          className="doctor_station_out_hospital_bottom_save"
          onClick={handelSave}
          disabled={
            inpPreTransferApply?.length > 0 ||
            !inVisitInfo?.inpVisitId ||
            detail?.wardNotifyStatus === 'NOTIFY_SETTLE'
          }
        >
          保存
        </Button>
      </div>
      {/* 诊断弹窗 */}
      {
        <DiagnoseModal
          ref={modalRef}
          save={saveDiagnosisRecordList}
          diagnosisList={convertQueryAllByDiagnosisRecordWithDiagnosisQtoRes2DiagnosisRecordList(
            { data: detail?.diagnosisRecordWithDiagnosisVoList },
          )}
        ></DiagnoseModal>
      }
      {/* 停止医嘱弹窗 */}
      {renderStopOrderModal()}
    </div>
  );
};

export default definePage(Index);
