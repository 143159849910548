import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentPriceController_getDepartmentPrice_78944b: {
    method: 'post',
    url: '/api/clinic-record-order/get-department-price',
    parameterFomatter: (data?: {
      outpVisitEncounterId: number;
      orderId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentPriceOutpOrderCostVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
