import {
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSTableAction,
  message,
  Popover,
} from '@vs/vsf-kit';
import {
  convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge,
  convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo,
} from './convert';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import Block from '@/pages/Index/components/block_no_padding';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import storage from '@/utils/storage';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Tips from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import BedClassVsChargeEdit from './edit';

import './index.less';

const Index = (props) => {
  const onFetch_t2153 = useCallback(async (params) => {
    // 根据关键词查询到相关的床位等级码表code列表
    const searchKeyword = params?.search?.keyword;
    var bedClassIn;
    if (searchKeyword) {
      const bedClassInfoListResult =
        await vsf?.services?.BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27?.(
          {
            qto: {
              from: 0,
              size: 200,
              codeTypeIs: 'BED_CLASS_DICT',
              orInputCodeLike: searchKeyword.toUpperCase(),
              orNameLike: searchKeyword,
              orCodeLike: searchKeyword,
            },
          },
        );
      const bedClassInfoList = bedClassInfoListResult?.data?.result;
      if (bedClassInfoList && bedClassInfoList?.length > 0) {
        bedClassIn = bedClassInfoList?.map(
          (bedClassInfo) => bedClassInfo?.code,
        );
      }
    }
    const res =
      await vsf?.services?.ClinicBedConfigController_queryByBedClassVsChargeQtoPaged_dae380?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 200,
            bedClassIn: bedClassIn, // 床位等级编码列表
          },
        },
      );
    const resultData =
      convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo?.(
        res,
      );
    console.log(resultData, 'resultData');
    return {
      data: resultData ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Container
      layout={{
        items: [
          {
            bgColor: 'var(--background)',
            block: '100%',
            height: '100%',
          },
        ],
      }}
    >
      <VSPromiseTable
        editable={{
          editType: 'single',
          onCanDelete: (data) => {
            return false;
          },
          columnProps: {
            hideInTable: true,
          },
        }}
        scroll={{
          y: getScrollY(360),
        }}
        pagination={false}
        vsid="37935"
        id="vSPromiseTableBedClassVsCharge"
        onFetch={onFetch_t2153}
        bordered
        className="aspirin-table aspirin-table-no-background-box aspirin-table-header-background-color"
        searchConfig={{
          labelAlign: 'left',
          labelWidth: 120,
          collapsed: false,
          collapseRender: false,
          disableSearchButtonLoading: true,
          searchGutter: 50,
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="搜索">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 10px 0 80px">
              {dom}
            </SearchContainer>
          );
        }}
        // updateLinkPage={{
        //   // linkPage: () => <BedClassVsChargeEdit />,
        //   modalProps: {
        //     title: '床位等级设置-编辑',
        //     okText: '保存',
        //     confirmLoading: true,
        //   },
        //   onLinkPageValidate: async (value, values, row) => {
        //     // 组装保存的请求入参
        //     const btoParam = {
        //       id: value?.id,
        //       bedClass: value?.bedClass,
        //       priceItemCode: value?.priceItem?.itemId,
        //       sortNumber: value?.sortNumber,
        //       baseCodeType: 'BED_CLASS_DICT',
        //       baseCodeName: value?.name,
        //       deprecateIndicator: value?.deprecateIndicator,
        //       memo: value?.memo,
        //     }; // 请求保存接口
        //     const res =
        //       await vsf?.services?.ClinicBedConfigController_saveBedClassVsCharge_8bd901?.(
        //         { btoParam: btoParam, extApiParam: {} },
        //       );
        //     if (!res?.data) {
        //       vsf?.showToast(
        //         res?.message ?? '保存失败，请联系技术支持',
        //         'error',
        //       );
        //       return false;
        //     } // 获取保存的ID的对应的床位价表对照详情
        //     const bedClassVsChargeDetailResult =
        //       await vsf?.services?.ClinicBedConfigController_getBedClassVsChargeDetailVoById_6d8e13?.(
        //         { id: value?.id },
        //       ); // 如果失败，报错
        //     if (!bedClassVsChargeDetailResult?.data) {
        //       vsf?.showToast(
        //         bedClassVsChargeDetailResult?.message ??
        //           '获取保存后的详情失败，请联系技术支持',
        //         'error',
        //       );
        //       return false;
        //     } // 转换为表格的行数据
        //     const bedClassVsChargeDetailRowData =
        //       convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge?.(
        //         bedClassVsChargeDetailResult,
        //       );
        //     return bedClassVsChargeDetailRowData;
        //   },
        // }}
        addLinkPage={{
          linkPage: () => <BedClassVsChargeEdit />,
          modalProps: {
            className: 'aspirin-linkpage',
            title: '床位等级设置-创建',
            confirmLoading: true,
            okText: '保存',
            centered: true,
            width: 800,
          },
          onLinkPageValidate: async (value, values, row) => {
            const res =
              await vsf.refs.vSFormBedClassVsCharge?.validateFieldsReturnFormatValue();
            // 组装保存的请求入参
            const btoParam = {
              bedClass: value?.bedClass,
              priceItemCode: value?.priceItem?.itemId,
              sortNumber: value?.sortNumber,
              baseCodeType: 'BED_CLASS_DICT',
              baseCodeName: value?.name,
              deprecateIndicator: value?.deprecateIndicator,
              memo: value?.memo,
            }; // 请求保存接口
            const saveResult =
              await vsf?.services?.ClinicBedConfigController_saveBedClassVsCharge_8bd901?.(
                { btoParam: btoParam, extApiParam: {} },
              );
            if (saveResult?.code == 200) {
              vsf.refs?.vSPromiseTableBedClassVsCharge?.reload();
              message.success('新增成功');
              return true;
            } else {
              return false;
            }
            // if (!saveResult?.data) {
            //   vsf?.showToast(
            //     saveResult?.message ?? '保存失败，请联系技术支持',
            //     'error',
            //   );
            //   return false;
            // } // 获取保存的ID的对应的床位价表对照详情
            // const savedId = saveResult?.data;
            // const bedClassVsChargeDetailResult =
            //   await vsf?.services?.ClinicBedConfigController_getBedClassVsChargeDetailVoById_6d8e13?.(
            //     { id: savedId },
            //   ); // 如果失败，报错
            // if (!bedClassVsChargeDetailResult?.data) {
            //   vsf?.showToast(
            //     bedClassVsChargeDetailResult?.message ??
            //       '获取保存后的详情失败，请联系技术支持',
            //     'error',
            //   );
            //   return false;
            // } // 转换为表格的行数据
            // const bedClassVsChargeDetailRowData =
            //   convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge?.(
            //     bedClassVsChargeDetailResult,
            //   );
            // return bedClassVsChargeDetailRowData;
          },
        }}
      >
        <VSTableColumn
          dataIndex={['keyword']}
          title="床位等级名称"
          valueType="text"
          search
          order={0}
          columnKey={'keyword'}
          hideInTable
          fieldProps={{ allowClear: true }}
        />

        <VSTableColumn
          dataIndex={['sortNumber']}
          title="序号"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedClass']}
          title="编码"
          editable={false}
          valueType="text"
          fieldProps={{}}
          // dataSource={vsf.stores.dbenums?.enums?.BED_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="编码名称"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="价格"
          valueType="digit"
          fieldProps={{ formatter: (value) => Number(value).toFixed(2) }}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['deprecateIndicator']}
          title="停用标识"
          valueType="switch"
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          fieldProps={{}}
        /> */}
        <VSTableColumn
          title="操作"
          name={'option'}
          valueType="option"
          width={100}
        >
          <VSTableAction
            linkPage={{
              linkPage: () => <BedClassVsChargeEdit />,
              modalProps: {
                className: 'aspirin-linkpage',
                title: '床位等级设置-编辑',
                okText: '保存',
                centered: true,
                confirmLoading: true,
                width: 800,
              },
              onLinkPageValidate: async (value, values, row) => {
                await vsf.refs.vSFormBedClassVsCharge?.validateFieldsReturnFormatValue();
                // 组装保存的请求入参
                const btoParam = {
                  id: value?.id,
                  bedClass: value?.bedClass,
                  priceItemCode: value?.priceItem?.itemId ?? value?.itemId,
                  sortNumber: value?.sortNumber,
                  baseCodeType: 'BED_CLASS_DICT',
                  baseCodeName: value?.name,
                  deprecateIndicator: value?.deprecateIndicator,
                  memo: value?.memo,
                }; // 请求保存接口
                const res =
                  await vsf?.services?.ClinicBedConfigController_saveBedClassVsCharge_8bd901?.(
                    { btoParam: btoParam, extApiParam: {} },
                  );
                if (res?.code == 200) {
                  vsf.refs?.vSPromiseTableBedClassVsCharge?.reload();
                  return res?.data;
                } else {
                  return false;
                }
              },
            }}
          >
            {/* <Popover content="编辑"> */}
            <Icon type="icon-bianji" size={24} />
            {/* </Popover> */}
          </VSTableAction>
          <VSTableAction
            linkPage={{
              linkPage: () => false,
              onLinkPageValidate: async (row) => {
                Tips({
                  content: `是否${
                    !row?.deprecateIndicator ? '停用' : '取消停用'
                  }{【${row?.name}】}数据`,
                  onOk: async () => {
                    const btoParam = {
                      id: row?.id,
                      bedClass: row?.bedClass,
                      deprecateIndicator: !row?.deprecateIndicator
                        ? true
                        : false,
                    }; // 请求停用接口
                    const res =
                      await vsf?.services?.ClinicBedConfigController_saveBedClassVsCharge_8bd901?.(
                        {
                          btoParam: btoParam,
                          extApiParam: {
                            deprecateOperation: true,
                          },
                        },
                      ); // 如果停用成功，且停用成功的ID和请求ID相同，则更新停用标识

                    if (res.code === 200) {
                      vsf.refs?.vSPromiseTableBedClassVsCharge?.reload();
                      message.open({
                        type: 'success',
                        content: !row?.deprecateIndicator
                          ? `停用成功`
                          : '取消停用成功',
                      });
                    }
                    return res?.data;
                  },
                  cancelText: '取消',
                });
              },
            }}
          >
            {/* <Popover content="停用"> */}
            <Icon type="icon-tingyong" size={24} />
            {/* </Popover> */}
          </VSTableAction>
        </VSTableColumn>
        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          title="床位等级价表对照列表"
        >
          <VSTableAddition children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Container>
  );
};
export default Index;
