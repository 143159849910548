import { ComplexSelect } from '@/pages/Index/components';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import { Table } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import vsf, { definePage } from '@vs/vsf-boot';
import { askSearch } from '@/components/AspirinSelect/utils';
const InquireHospitalSelect = (props) => {
  // 常量
  const [
      hospitalDataSource,
      setHospitalDataSource,
      ,
      ,
      getInitHospitalDataSource,
    ] = useGetResetInitState([]),
    {
      dataSource,
      columns = [],
      placeholder = '请选择门诊专科',
      dropdownMatchSelectWidth = 700,
    } = props;
  useAsyncEffect(async () => {
    setHospitalDataSource();
    dataSource?.length >= 1 &&
      setHospitalDataSource(
        dataSource?.map((dataEle) => ({
          ...dataEle,
        })),
      );
  }, [dataSource]);
  return (
    <ComplexSelect
      mode="radio"
      placeholder={placeholder}
      hideSelectIcon
      showLabel="label"
      allowClear
      value={props?.value ?? ''}
      onChange={(params) => props?.onChange(params)}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      listHeight={1000}
      onSearch={(value, filterOption) => {
        // console.log(value, '7443_value');
        // console.log(dataSource, '7443_dataSource');
        let resData = [];
        dataSource.map((item) => {
          if (value) {
            const flag = askSearch(value, item, 'bedLabel');
            if (flag) {
              resData.push(item);
            }
          } else {
            resData.push(item);
          }
        });
        setHospitalDataSource(resData);
        // if (value?.trim()) {
        //   setHospitalDataSource(
        //     filterOption(value, getInitHospitalDataSource()),
        //   );
        // } else {
        //   setHospitalDataSource(getInitHospitalDataSource());
        // }
      }}
    >
      <Table
        className="common_table"
        columns={columns}
        dataSource={hospitalDataSource}
        pagination={false}
        size="middle"
        scroll={{
          y: 300,
        }}
      />
    </ComplexSelect>
  );
};

export default definePage(InquireHospitalSelect);
