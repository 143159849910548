import {
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSTableAction,
  Section,
  VSFormItem,
  VSForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import {
  convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge,
  convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo,
} from './convert';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import Block from '@/pages/Index/components/block_no_padding';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import storage from '@/utils/storage';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import { convertGetClinicBedDetailVoByIdRes2VSFormBedInfo } from './convert';

import './index.less';

const Index = (props) => {
  const onFetch_t1745 = useCallback(async (params) => {
    if (!params?.id) return {};
    const res =
      await vsf?.services?.ClinicBedController_getClinicBedDetailVoById_2c9af4?.(
        { id: params?.id },
      );
    if (!res?.data) {
      return {};
    } // 转化为床位信息表单数据
    const formData = convertGetClinicBedDetailVoByIdRes2VSFormBedInfo?.(res);
    return formData;
  }, []);

  return (
    <VSForm
      vsid="75967"
      id="vSFormBedInfo"
      params={props?.value}
      onFetch={onFetch_t1745}
      onChange={props?.onChange}
      labelAlign="left"
    >
      <VSFormLayout key="0" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['sortNumber']}
          label="排序号"
          valueType="digit"
          fieldProps={{
            width: '100%',
          }}
          required={true}
        />

        <VSFormItem
          name={['bedLabel']}
          label="床号"
          valueType="text"
          fieldProps={{}}
          required={true}
        />
      </VSFormLayout>
      <VSFormLayout key="1" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['bedRoomId']}
          label="房间号"
          valueType="text"
          fieldProps={{}}
        />

        <VSFormItem
          name={['ward']}
          label="护理单元"
          valueType="select"
          fieldProps={{
            fieldNames: { label: 'departmentName', value: 'id' },
            dataSource: async (param) => {
              const res =
                await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                  {
                    qto: {
                      clinicAttributeIs: '2',
                      stopIndicatorIs: false,
                      stopIndicatorIsNullOrNot: true,
                      departmentNameLike: param?.searchKey,
                      inputCodeLike: param?.searchKey,
                      inputCodeWubiLike: param?.searchKey,
                    },
                  },
                );
              if (!res?.data) {
                return [];
              }
              return res?.data?.map((department) => {
                return {
                  id: department?.id,
                  departmentName: department?.departmentName,
                };
              });
            },
            searchKey: 'searchKey',
            showSearch: true,
          }}
          required={true}
        />
      </VSFormLayout>
      <VSFormLayout key="2" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['department']}
          label="所属科室"
          valueType="select"
          fieldProps={{
            showSearch: true,
            searchKey: 'searchKey',
            dataSource: async (param) => {
              const res =
                await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                  {
                    qto: {
                      clinicAttributeIs: '0',
                      stopIndicatorIs: false,
                      stopIndicatorIsNullOrNot: true,
                      departmentNameLike: param?.searchKey,
                      inputCodeLike: param?.searchKey,
                      inputCodeWubiLike: param?.searchKey,
                    },
                  },
                );
              if (!res?.data) {
                return [];
              }
              return res?.data?.map((department) => {
                return {
                  id: department?.id,
                  departmentName: department?.departmentName,
                };
              });
            },
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
          // required={true}
        />

        <VSFormItem
          name={['bedClass']}
          label="床位等级"
          // valueType="select"
          valueType="codeTableSelect"
          fieldProps={{
            allowClear: true,
            showSearch: true,
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
            onChange: async (value) => {
              const bedClass = value; // 床位等级编码
              // 根据床位等级编码查询床位等级对照表详细信息
              const bedClassVsChargeResult =
                await vsf?.services?.ClinicBedConfigController_queryByBedClassVsChargeQtoPaged_dae380?.(
                  { qto: { from: 0, size: 1, bedClassIn: [bedClass] } },
                );
              const bedClassPrice =
                bedClassVsChargeResult?.data?.result?.[0]?.priceItemInfo?.price;
              if (bedClassPrice) {
                // 如果床位等级价格存在
                vsf?.refs?.vSFormBedInfo?.setFieldsValue({
                  bedClassPrice: Number?.(bedClassPrice)?.toFixed?.(2),
                });
              } else {
                vsf?.refs?.vSFormBedInfo?.setFieldsValue({ bedClassPrice: '' });
              }
            },
          }}
          required={true}
          dataSource={vsf.stores.dbenums?.enums?.BED_CLASS_DICT}
        />
      </VSFormLayout>
      <VSFormLayout key="3" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['bedClassPrice']}
          label="床位价格"
          readonly
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['bedType']}
          label="床位类型"
          // valueType="select"
          valueType="codeTableSelect"
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
            showSearch: true,
            allowClear: true,
          }}
          required={true}
          dataSource={vsf.stores.dbenums?.enums?.BED_TYPE_DICT}
        />
      </VSFormLayout>
      <VSFormLayout key="4" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['bedStatus']}
          label="床位状态"
          // valueType="select"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
            allowClear: true,
          }}
          required={true}
          dataSource={vsf.stores.dbenums?.enums?.BED_STATUS_DICT}
        />

        <VSFormItem
          name={['bedSettingType']}
          label="编制类型"
          // valueType="select"
          valueType="codeTableSelect"
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
            showSearch: true,
            allowClear: true,
          }}
          required={true}
          dataSource={vsf.stores.dbenums?.enums?.BED_APPROVED_TYPE_DICT}
        />
      </VSFormLayout>
      <VSFormLayout key="5" columnCount={1} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['patternCode']}
          label="计费模板"
          valueType="codeTableSelect"
          // dataSource={vsf.stores.dbenums?.enums?.BILL_PATTERN_CLASS_DICT}
          fieldProps={{
            showSearch: true,
            allowClear: true,
            // dataSource: vsf.stores.dbenums?.enums?.BILL_PATTERN_CLASS_DICT,
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
};

export default Index;
