import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSDataProvider,
  VSTableAddition,
  Todo,
  VSFormLayout,
  Button,
  VSFormFooter,
  VSForm,
  // VSFormFooterSubmit,
} from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import { async } from 'q';
import React, { useEffect, useState } from 'react';

import { askSearch } from '@/pages/Index/components/func/ask';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import RoleEditableTable from '@/pages/Index/components/func/RoleEditableTable';
import routes from '@/router/IndexRoute';
import vsf, { definePage } from '@vs/vsf-boot';

import { items } from '../..';

const FunctionEdit = (props) => {
  /**
   * funcition
   * @type {any}
   */
  const [funcition, setFuncition] = useState();
  const [iconList, setIconList] = useState();
  /**
   * getId
   * @param {any} data
   * @returns {any}
   */
  const getId = (data) => {
    return data?.id;
  };
  console.log(vsf.stores.dbenums?.enums?.SYS_CONFIG_TYPE, '-====');
  const NewRoleEditableTable = (newProps) => {
    return (
      <RoleEditableTable
        // value={props?.value?.functionVsPermissionDtoList}
        {...newProps}
      >
        <VSFormItem
          name={['code']}
          label="权限编码"
          valueType="text"
          rules={[
            { message: '权限编码长度不合法', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['name']}
          label="权限名称"
          valueType="text"
          rules={[
            { message: '权限名字长度不合法', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['permissionType']}
          label="权限类型"
          valueType="select"
          dataSource={[
            { label: '可见', value: 'VIEW' },
            { label: '可操作', value: 'OPERATE' },
          ]}
          fieldProps={{}}
        />
      </RoleEditableTable>
    );
  };
  useEffect(() => {
    if (props.value) {
      vsf.services
        .ApplicationFunctionDtoController_getById_0d38bf(props.value)
        .then((res) => {
          vsf.refs?.FunctionForm?.setFieldsValue({
            ...res.data,
            functionPermissionBtoList: res?.data?.functionVsPermissionDtoList,
          });
        });
    }
    getIconList();
  }, []);

  const getIconList = async () => {
    const res =
      await vsf?.services?.BaseCommonController_getByCategory_1fe731?.({
        category: 'APPLICATION',
      });

    const _res = res?.data.map((item) => {
      return { label: item?.url, value: String(item?.id) };
    });
    setIconList(_res);
    return _res;
  };

  const formatFormData = (_value) => {
    return {
      ..._value,
      functionPermissionBtoList: _value?.functionVsPermissionDtoList,
    };
  };

  const menuArr = (data, arr = []) => {
    data.forEach((item) => {
      if (item.children && item.children.length > 0) {
        menuArr(item.children, arr);
      } else {
        arr.push({ label: item.label, value: item.path });
      }
    });
    return arr;
  };

  return (
    <VSForm
      vsid="16141"
      id="FunctionForm"
      // value={formatFormData(props.value)}
      // onChange={(_value) => {
      //   console.log(_value);
      //   props.onChange?.({
      //     ..._value,
      //     functionVsPermissionDtoList: _value?.functionPermissionBtoList,
      //     inputCode: askRomanAlphabet(_value?.name),
      //   });
      // }}
      labelAlign="left"
      fieldProps={{}}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout labelWidth={120} columnCount={2} gutter={[80]}>
        <VSFormItem
          name={['functionClass']}
          label="功能分类"
          valueType="select"
          fieldProps={{
            showSearch: true,
          }}
          rules={[{ required: true }]}
          dataSource={vsf.stores.dbenums?.enums?.SYS_CONFIG_TYPE?.[0]?.children}
        />
        <VSFormItem
          name={['permissionCode']}
          label="功能编码"
          valueType="text"
          rules={[
            {
              message: '全下编码长度不合法',
              type: 'string',
              min: 0,
              max: 1024,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['name']}
          label="功能名称"
          valueType="text"
          rules={[
            { message: '名称长度不合法', type: 'string', min: 0, max: 4000 },
            { required: true },
          ]}
          fieldProps={{
            onChange: (event) => {
              vsf?.refs?.FunctionForm?.setFieldValue(
                'inputCode',
                askRomanAlphabet(event?.target?.value),
              );
            },
          }}
        />
        <VSFormItem
          name={['inputCode']}
          label="输入码"
          valueType="text"
          rules={[
            { message: '名称长度不合法', type: 'string', min: 0, max: 4000 },
            { required: true },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['icon']}
          label="功能图标"
          valueType="select"
          fieldProps={{}}
          dataSource={iconList}
        />
        <VSFormItem
          name={['functionType']}
          label="功能类型"
          valueType="select"
          dataSource={[
            { label: ' 内部系统功能', value: 'INTERNAL' },
            { label: '三方系统功能', value: 'THIRD_PARTY' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />
        <VSFormItem
          name={['openBy']}
          label="打开方式"
          valueType="select"
          dataSource={[
            { label: '系统浏览器', value: 'SYSTEM_BROWSER' },
            { label: 'ie浏览器', value: 'IE_BROWSER' },
            { label: 'exe 可运行程序', value: 'EXE_APPLICATION' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />
      </VSFormLayout>
      <VSFormLayout labelWidth={120} columnCount={1} gutter={[80]}>
        <VSFormItem
          name={['source']}
          label="启动地址"
          valueType="select"
          dataSource={(value) => {
            console.log(value, '启动地址');
            const list = menuArr(items);
            return value?.label
              ? list?.filter((item) => askSearch(value?.label, item))
              : list;
          }}
          rules={[
            {
              message: '启动地址长度不合法',
              type: 'string',
              min: 0,
              max: 200,
            },
            { required: true },
          ]}
          fieldProps={{ showSearch: true }}
        />

        <VSFormItem
          name={['note']}
          label="备注"
          valueType="text"
          rules={[
            { message: '备注长度不合法', type: 'string', min: 0, max: 1024 },
          ]}
          fieldProps={{}}
        />
      </VSFormLayout>

      <VSFormItem
        name={['functionPermissionBtoList']}
        valueType="custom"
        customComponent={(_props) => <NewRoleEditableTable {..._props} />}
        fieldProps={{}}
      />
    </VSForm>
  );
};
export default definePage(FunctionEdit);
