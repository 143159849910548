import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import {
  VSForm,
  VSFormItem,
  VSFormLayout,
  Select,
  message,
  Image,
  Row,
  Col,
  Modal,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import readCard from '@/assets/icon/readCard.png';
import { convertGetExpenseListRes2InpExpenseList, getFormList } from './Servce';
import { day } from '@/pattern';
import dayjs from 'dayjs';
function InpExpenseForm({
  selectDrawerData,
  callback,
  onChangedisplayIdData,
  selectDrawerDataChange,
  clearDrawer = { clearDrawer },
}) {
  const [open, setOpen] = useState(false);
  const [selectRowData, setSelectRowData] = useState();
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const { data: getQueryData, run: getQueryDataRun } = useRequest(getFormList, {
    manual: true,
  });

  useEffect(() => {
    selectDrawerData && getQueryDataRun(selectDrawerData);
  }, [selectDrawerData]);

  useEffect(() => {
    selectRowData && current(selectRowData);
  }, [selectRowData]);
  const current = (params) => {
    params && callback(params);
    if (params && params?.displayId) {
      params?.displayId &&
        vsf?.refs?.inpExpenseForm?.setFieldValue(
          'displayId',
          params?.displayId,
        );

      onChangedisplayIdData(params);

      setOpen(false);
    }
  };
  useEffect(() => {
    if (getQueryData?.length >= 2) return setOpen(true);
    setSelectRowData(getQueryData?.[0]);
  }, [getQueryData]);
  return (
    <>
      <div className="inp-expense-form">
        <div className="inp-expense-form-left">
          <Row>
            <Col span={24}>
              <VSForm id="inpExpenseForm">
                <VSFormLayout columnCount={2}>
                  <VSFormItem
                    name={'displayId'}
                    label="患者ID"
                    valueType="text"
                    fieldProps={{
                      onPressEnter: (v) => {
                        clearDrawer();
                        v.target.value &&
                          getQueryDataRun({ displayId: v.target.value });
                      },
                    }}
                  />
                  <VSFormItem
                    name={['patientIdIs']}
                    label="证件及编号"
                    valueType="text"
                    fieldProps={{
                      // width: '480px',
                      className: 'patientIdIs',
                      // style: { paddingLeft: '10px' },
                      addonBefore: (
                        <>
                          <Select
                            // width={150}
                            className="patientIdIs-select"
                            dataSource={certificateData}
                            fieldNames={{ label: 'name', value: 'code' }}
                          />
                        </>
                      ),

                      suffix: (
                        <div
                          className="suffix-readCard"
                          onClick={() => {
                            message.success('读卡中');
                          }}
                        >
                          <div className="suffix-cardImg">
                            <Image
                              src={readCard}
                              // style={{ width: '20px', height: '20px' }}
                              className="suffix-cardImg-image"
                              preview={false}
                            ></Image>
                          </div>
                          <div className="suffix-cardText">读卡</div>
                        </div>
                      ),
                      placeholder: '请读卡',
                    }}
                  />
                </VSFormLayout>
              </VSForm>
            </Col>
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <Col span={5}>姓名：{selectRowData?.name ?? ''}</Col>
            <Col span={5}>
              费别：
              {selectRowData?.chargeType &&
                (vsf.stores.dbenums.enums.CHARGE_TYPE_DICT?.find(
                  (item) => item?.value === selectRowData?.chargeType,
                )?.label ??
                  '')}
            </Col>
            <Col span={5}>
              人员类别：
              {selectRowData &&
                selectRowData?.inpVisitExtensionBillingInfoDto &&
                (vsf.stores.dbenums.enums.IDENTITY_DICT?.find(
                  (item) =>
                    item?.value ===
                    selectRowData?.inpVisitExtensionBillingInfoDto
                      ?.identityCode,
                )?.label ??
                  'selectRowData?.inpVisitExtensionBillingInfoDto?.identityCode')}
            </Col>
            <Col span={5}>病区：{selectRowData?.departmentName ?? ''}</Col>
            <Col span={4}>床号：{selectRowData?.bedLabel ?? ''}</Col>
          </Row>
          <Row>
            <Col span={5}>
              入院日期：{selectRowData?.admissionDateTime ?? ''}
            </Col>
            <Col span={5}>
              出院日期：{selectRowData?.dischargeDateTime ?? ''}
            </Col>
            <Col span={5}>
              住院天数：
              {dayjs(selectRowData?.dischargeDateTime).diff(
                dayjs(selectRowData?.admissionDateTime),
                'day',
              )}
              天
            </Col>
            <Col span={5}>
              入院诊断：{selectRowData?.diagnosisDescription ?? ''}
            </Col>
            <Col span={4}>
              出院诊断：{selectRowData?.diagnosisDescription ?? ''}
            </Col>
          </Row>
        </div>
        <div className="inp-expense-form-right">
          <div style={{ flex: '1' }}>
            <Row style={{ paddingBottom: '24px' }}>
              <Col span={12}>
                费用合计：{Number(selectRowData?.totalCost ?? 0).toFixed(2)}
              </Col>
              <Col span={12}>
                个人自付：
                {Number(selectRowData?.selfPaymentTotalCost ?? 0).toFixed(2)}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                预交金：
                {Number(selectRowData?.prePaymentAmount ?? 0).toFixed(2)}
              </Col>
              <Col span={12}>
                本次应付：{Number(selectRowData?.totalCharge ?? 0).toFixed(2)}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        title={<div style={{ color: '#3276E8' }}>患者住院信息</div>}
        open={open}
        onCancel={() => setOpen(false)}
        width={800}
      >
        <VSPromiseTable
          vsid="42317"
          id="outpSettleMasterQueryList"
          rowClick={{
            type: 'default',
            action: 'double-click',
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setSelectRowData(record);
                setOpen(false);
              },
              onClick: () => {},
            };
          }}
          pagination={false}
          onFetch={() => getQueryData}
        >
          <VSTableColumn
            dataIndex={['drugName']}
            title="序号"
            valueType="index"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['displayId']}
            title="患者ID"
            valueType="text"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['admissionDateTime']}
            title="入院时间"
            valueType="text"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['settleIndicator']}
            title="结算状态"
            valueType="text"
            fieldProps={{}}
            render={(_, val) => {
              if (val?.settleIndicator) {
                return '已结算';
              } else {
                return '未结算';
              }
            }}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'settleNumber']}
            title="入院时间"
            hideInTable
            valueType="text"
            fieldProps={{}}
            width={150}
            render={(_, v) => {
              return '2024-01-23 12:00:12:00';
            }}
          />
        </VSPromiseTable>
      </Modal>
    </>
  );
}

export default InpExpenseForm;
