import vsf, { definePage } from '@vs/vsf-boot';
import {
  Form,
  Modal,
  Section,
  Todo,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import Block from '@/module/Block';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import { useFetch, useSignal } from '@/pages/Index/components/func/hooks';
import { CreateScreen } from '@/pages/Index/components/screen';
import { calculateAge, labelColWidth } from '@/utils/index';

type Value = {
  validateFieldsReturnFormatValue?: () => Promise<never>;
};

type DictionaryEditProps = {
  value: any;
};

const DictionaryEdit = forwardRef((props: DictionaryEditProps, ref) => {
  const form = useRef<any>(null);
  const onScreen = useContext(CreateScreen);
  const Footer = () => {
    const onSave: any = useFetch({
      fetch: async (value) => {
        const res =
          await vsf.services?.BaseDictionaryController_createUpdateBaseCodeType_f84265?.(
            {
              btoParam: {
                ...value,
                id: Number(String(dayjs()?.valueOf())?.slice(0, 20)),
              },
              extApiParam: value?.extApiParam,
            },
          );
        return res;
      },
      message: {
        loading: '正在保存中, 请勿做其他操作',
        success: '保存成功',
        error: '保存失败',
      },
      success() {
        onScreen(() => void 0);
      },
    });
    useEffect(() => {
      form?.current?.resetFields();
      form?.current?.setFieldsValue({
        ...props?.value,
        parentTypeId: props?.value?.parentTypeId ?? null,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.value]);
    const onReset: any = () =>
      form?.current?.resetFields([
        ['code'],
        ['name'],
        ['sortNumber'],
        ['memo'],
        ['codeTypeClass'],
      ]);

    useImperativeHandle(ref, () => ({
      onSave,
      onReset,
    }));

    return (
      <div
        className="aspirin-table-footer-button"
        style={{
          justifyContent: 'flex-end',
        }}
      >
        <ButtonAndKeyDown
          onClick={async () => {
            const value =
              await form?.current?.validateFieldsReturnFormatValue?.();
            onSave?.(value);
          }}
          methodKey="altKey"
          ordinaryKey="S"
          type="primary"
        >
          保存
        </ButtonAndKeyDown>
        <ButtonAndKeyDown
          onClick={onReset}
          methodKey="altKey"
          ordinaryKey="R"
          type="primary"
        >
          重置
        </ButtonAndKeyDown>
      </div>
    );
  };

  return (
    <Block title="码表详细信息" footer={<Footer />}>
      <VSControlledForm labelAlign="left" ref={form}>
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout
          key="1"
          columnCount={3}
          labelCol={labelColWidth[8]}
          gutter={40}
        >
          <VSFormItem
            name={['parentTypeId']}
            label="上级编码"
            valueType="text"
            fieldProps={{
              disabled: true,
              placeholder: '',
            }}
          />
          <VSFormItem
            name={['parentTypeName']}
            label="上级名称"
            valueType="text"
            fieldProps={{
              disabled: true,
              placeholder: '',
            }}
          />
          <VSFormItem
            name={['code']}
            label="类型编码"
            valueType="text"
            rules={[
              {
                message: '类型code长度不合法',
                type: 'string',
                min: 0,
                max: 16,
              },
              {
                required: true,
              },
            ]}
            fieldProps={{
              // disabled: true,
              placeholder: '请输入类型编码',
            }}
          />

          <VSFormItem
            name={['name']}
            label="类型名称"
            valueType="text"
            rules={[
              {
                message: '码表类型名字长度不合法',
                type: 'string',
                min: 0,
                max: 32,
              },
              {
                required: true,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['sortNumber']}
            label="排序号"
            valueType="text"
            rules={[
              { message: '排序号长度不合法', type: 'string', min: 0, max: 32 },
              {
                required: true,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['codeTypeClass']}
            label="码表分类"
            valueType="select"
            dataSource={[
              { label: '用户', value: 'USER' },
              { label: '系统', value: 'SYSTEM' },
            ]}
            initialValue="USER"
            fieldProps={{
              showSearch: true,
              defaultValue: 'USER',
            }}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={3} labelCol={labelColWidth[8]} gutter={40}>
          <VSFormItem
            name={['memo']}
            label="备注"
            valueType="text"
            rules={[
              { message: '备注长度不合法', type: 'string', min: 0, max: 1024 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Block>
  );
});

DictionaryEdit.displayName = 'DictionaryEdit';

export default DictionaryEdit;
