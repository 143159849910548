import React, { useState } from 'react';

import { Page, Column, Columns } from '@vs/vsf-kit';
import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';

import InstitutionEdit from './edit';
import InstitutionTree from './tree';

const InstitutionManage = (props) => {
  /**
   * selectedInstitution
   * @type {object}
   */
  const [selectedInstitution, setSelectedInstitution] = useState();
  const handleFormChange = () => {
    vsf?.refs?.InstitutionTree?.handleReload();
  };
  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <InstitutionTree
        onChange={(_value) => {
          setSelectedInstitution({ ..._value });
        }}
      />
      <InstitutionEdit
        value={selectedInstitution}
        onChange={handleFormChange}
      />
    </Container>
  );
};
export default definePage(InstitutionManage);
