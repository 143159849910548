import {
  VSTableColumn,
  VSPromiseTable,
  VSTableAddition,
  VSTableToolbar,
  Button,
  VSTableAction,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';

import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';
import { includes } from 'lodash';

const DrugStocktakingTypeList = (props) => {
  const StocktakingFilters = props?.StocktakingList?.map?.(
    (element) => element?.stocktakingCode,
  );

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStocktakingTypeController_getByDrugStorageProfileId_77aeb2?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          drugStorageProfileId: params?.extra?.id,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handelRemoveClick = async (params) => {
    if (typeof params?.id === 'string' && `${params?.id}`.includes('create_')) {
      vsf?.refs?.vSPromiseTable615191?.remove?.(params);
      return true;
    }
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStocktakingTypeController_deleteDrugStocktakingType_a36bfa?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          id: params?.id,
        },
      ); // Tips: 需要返回删除成功与否
    if (res.code === 200) {
      await vsf?.refs?.vSPromiseTable615191?.remove?.(params);
    }
    return res?.code === 200;
  };

  const handelRecord = () => {
    return {
      id: `create_${Math.random()}`,
    };
  };

  return (
    <VSPromiseTable
      vsid="61519"
      id="vSPromiseTable615191"
      className="aspirin-table"
      editable={{
        editType: 'single',
        saveOnClickOutside: true,
        columnProps: {
          hideInTable: true,
          width: '5.33rem',
        },
      }}
      rowClick={{
        rowClickType: {
          action: 'click',
          type: 'edit-save',
        },
      }}
      extraParams={props.value}
      onFetch={onFetch}
      // onRemove={handelRemove}
      onRecord={handelRecord}
    >
      <VSTableToolbar title="盘点分类列表" style={{ padding: '0 24px' }}>
        <VSTableAddition children="添加" position="bottom" />
      </VSTableToolbar>

      <VSTableColumn
        width="6.66rem"
        title="序号"
        valueType="text"
        render={(_, __, index) => index + 1}
        preview
      />

      <VSTableColumn
        width="18.66rem"
        dataIndex={['stocktakingCode']}
        title="盘点分类"
        valueType="select"
        fieldProps={{
          showSearch: true,
        }}
        formItemProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.DRUG_TAKING_CLASS_DICT?.filter?.(
          (element) => includes(StocktakingFilters, element?.code),
        )}
      />

      <VSTableColumn
        width="18.66rem"
        dataIndex={['location']}
        title="库位码"
        valueType="text"
        formItemProps={{
          rules: [{ message: '数据格式错误', type: 'string', min: 0, max: 20 }],
        }}
        fieldProps={{}}
      />

      <VSTableColumn width="5.33rem" title="操作" valueType="option" preview>
        <VSTableAction
          popConfirm={(record) => {
            return {
              title: '确定删除吗',
              okButtonProps: {
                onClick: () => handelRemoveClick(record),
              },
            };
          }}
          children={<Icon type="icon-shanchu1" size={24} />}
        />
      </VSTableColumn>
    </VSPromiseTable>
  );
};
export default definePage(DrugStocktakingTypeList);
