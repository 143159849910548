import { message } from '@vs/vsf-kit';
import React, { createContext, useCallback, useState } from 'react';

type onscreen = (func?: () => void) => void;

export const CreateScreen = createContext<onscreen>(() => void 0);

export default function Screen(props: { children: any }) {
  const { children } = props;
  const [screen, setScreen] = useState<number>(0);
  const onScreen = (func?: () => void) => {
    setScreen(screen + 1);
    if (typeof func === 'function') {
      func();
    } else {
      message.success('清屏成功');
    }
  };
  // eslint-disable-next-line react/display-name
  const NewScreen = useCallback(() => {
    return children;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen, children]);

  return (
    <CreateScreen.Provider value={onScreen}>
      {screen === 0 ? children : <NewScreen />}
    </CreateScreen.Provider>
  );
}
