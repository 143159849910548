import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpRefundDispenseController_getHerbDispenseCountForInpRefundPerform_770d75: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-herb-dispense-count-for-inp-refund-perform',
    parameterFomatter: (data?: {
      query: QueryInpHerbDispenseForRefundApplyInputEoDrugPrescriptionDispensePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: QueryInpHerbDispenseForRefundApplyOutEoDrugPrescriptionDispensePersistEo[],
    ) => data,
  },
  PatientInHosptialController_queryScrollWardPatientList_346269: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-scroll-ward-patient-list',
    parameterFomatter: (data?: {
      qto: PageWardPatientQueryEoClinicInpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientInHospitalBedWardPatientVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoClinicInpVisitEntranceWebVoPatientinhospitalbedwardpatientvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
