import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_createChargeGroupMember_e4951d: {
    method: 'post',
    url: '/api/user-group/create-charge-group-member',
    parameterFomatter: (data?: {
      btoParam: CreateChargeGroupMemberBtoUserGroupServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
