import React, { useEffect } from 'react';
import { Image, Divider } from '@vs/vsf-kit';

export default function PopoverCart(props) {
  const { doctor } = props;
  return (
    <div className="doctor-detaill-container">
      <div className="doctor-detaill">医生信息</div>
      <div className="doctor-main-detaill">
        <div className="doctor-detaill-avatar">
          <div>头像</div>
          <div className="doctor-detaill-Img">
            <Image width={100} src={doctor?.staffInfoNoteDto?.staffPhoto} />
          </div>
        </div>
        <div className="doctor-detaill-all-detail">
          <div className="doctor-detaill-all-detail-sub">
            <div className="doctor-detaill-title-left">
              <div>姓名</div>
              <div className="doctor-detaill-name">{doctor?.staffName}</div>
            </div>
            <div className="doctor-detaill-title-right">
              <div className="doctor-detaill-title-sex">性别</div>
              <div className="name">{ doctor?.gender&&(vsf.stores.dbenums.enums.SEX_DICT?.find(
        (item) => item?.value === doctor?.gender)?.label ?? '')}</div>
            </div>
          </div>

          <div className="doctor-detaill-all-detail-sub">
            <div className="doctor-detaill-title-left">
              <div>职称</div>
              <div className="doctor-detaill-name">{(vsf.stores.dbenums.enums.TITLE_DICT?.find(
        (item) => item?.value === doctor?.titleCode)?.label ?? '')}</div>
            </div>
            <div className="doctor-detaill-title-right">
              <div className="doctor-detaill-title-sex">工号</div>
              <div className="name">{doctor?.titleCode}</div>
            </div>
          </div>

          <div className="doctor-detaill-park">
            <div className="doctor-detaill-title">
              <div>所在院区</div>
              <div className="doctor-detaill-name">
                {doctor?.staffDepartment?.branchInstitution?.institutionName}
              </div>
            </div>
          </div>

          <div className="doctor-detaill-park">
            <div className="doctor-detaill-title">
              <div>临床科室</div>
              <div className="doctor-detaill-name">
                {doctor?.staffDepartment?.departmentName}
              </div>
            </div>
          </div>
          <Divider />
          <div className="doctor-detaill-all-detail-sub">
            <div
              className="doctor-detaill-title-left"
              style={{ width: '100%' }}
            >
              <div>简介</div>
              <div className="doctor-detaill-name">
                {doctor?.descriptionInfo}
              </div>
            </div>
          </div>
          <div className="doctor-detaill-all-detail-sub">
            <div
              className="doctor-detaill-title-left"
              style={{ width: '100%' }}
            >
              <div>特长</div>
              <div className="doctor-detaill-name">
                {doctor?.doctorDto?.specialityDescription}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
