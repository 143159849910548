import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Form,
  List,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Todo,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import classNames from 'classnames';
import React, { useRef } from 'react';

import { useAvoidState, useFetch } from '../../components/func/hooks';

const RefundAudit = (props) => {
  const [form] = Form?.useForm();
  const [rejectDataSource, setRejectDataSource] = useAvoidState([]);
  const onOk = useFetch({
    fetch: async (value) => {
      const res =
        await vsf.services?.InpDrugAuditController_refundOrders_b565a6?.({
          bto: {
            ...value,
            drugDispenseAuditDetailBtoList: props?.value?.map((item) => ({
              orderId: item?.orderId,
            })),
          },
        });
      return res;
    },
    message: {
      error: '回退医嘱失败',
      success: '回退医嘱成功',
      loading: '回退医嘱中',
    },
    success() {
      props?.onCancel();
      props?.reload();
    },
  });
  const problemClassCode = Form.useWatch('rejectProblemClass', form);
  useAsyncEffect(async () => {
    if (problemClassCode) {
      const res =
        await vsf.services?.InpDrugAuditController_getOrderProblemByClassCode_dc8536?.(
          {
            problemClassCode,
          },
        );
      setRejectDataSource(
        res?.data?.map((item) => {
          return {
            label: item?.problemDescription,
            value: item?.id,
          };
        }),
      );
    }
  }, [problemClassCode]);
  return (
    <Modal
      title="回退医嘱"
      {...props}
      onOk={async () => {
        if (!props?.value?.length) {
          message?.warning('未选择相关医嘱');
          return;
        }
        const value =
          await vsf?.refs?.RejectForm?.validateFieldsReturnFormatValue?.();
        onOk?.(value);
      }}
      width="50%"
      cancelText="取消"
      okText="确定"
    >
      <VSTable
        id="tableOrderForRefund"
        value={props?.value}
        pagination={false}
        scroll={{
          y: 500,
        }}
      >
        <VSTableColumn
          dataIndex={['startPerformDateTime']}
          title="开始时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          render={(...[, record]) => record?.startPerformDateTime}
        />

        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          valueType="text"
          fieldProps={{}}
          render={(...[, record]) => {
            return (
              <div
                className={classNames('aspirin-order-text', record?.groupName)}
              >
                {record?.orderText}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['usageAndUnit']}
          title="剂量"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSTableColumn
          dataIndex={['frequency', 'frequencyCount']}
          title="频次"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
          render={(...[, record]) => {
            return (
              <Popover
                content={
                  <List
                    dataSource={[
                      {
                        label: '详情',
                        value: record?.frequency?.frequencyDetail,
                      },
                      {
                        label: '总数',
                        value: record?.frequency?.frequencyCount,
                      },
                      {
                        label: '间隔',
                        value: record?.frequency?.frequencyInterval,
                      },
                      {
                        label: '间隔单位',
                        value: record?.frequency?.frequencyIntervalUnit,
                      },
                      {
                        label: '频次',
                        value: record?.frequency?.frequency,
                      },
                    ]}
                    renderItem={(item) => {
                      return (
                        <List.Item
                          style={{
                            display: 'flex',
                            gap: 10,
                          }}
                        >
                          <span>{item?.label}</span>
                          <span>{item?.value}</span>
                        </List.Item>
                      );
                    }}
                    bordered
                  />
                }
                title="频次详情"
                placement="right"
              >
                <span>{record?.frequency?.frequency}</span>
              </Popover>
            );
          }}
          fieldNames={{
            label: 'label',
            value: 'order',
          }}
        />
      </VSTable>
      <Divider />
      <VSForm id="RejectForm" labelAlign="left" form={form}>
        <VSFormLayout key="0" columnCount={2} gutter={50} labelWidth={100}>
          <VSFormItem
            name={['rejectProblemClass']}
            label="问题类型"
            valueType="selectCode"
            fieldProps={{
              placeholder: '请选择问题类型',
            }}
            dataSource={vsf.stores.dbenums.enums.ORDER_PROBLEM_CLASS_DICT}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <VSFormItem
            name={['rejectProblemId']}
            label="医嘱问题"
            valueType="selectCode"
            dataSource={rejectDataSource}
            fieldProps={{
              placeholder: '请选择医嘱问题',
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          <VSFormItem
            name={['rejectProblemLevel']}
            label="等级"
            valueType="selectCode"
            dataSource={vsf.stores.dbenums.enums.ORDER_PROBLEM_GRADE_DICT}
            fieldProps={{
              placeholder: '请选择等级',
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          <VSFormItem
            name={['auditMemo']}
            label="回退原因"
            valueType="text"
            fieldProps={{}}
            rules={[
              {
                required: true,
              },
            ]}
          />
        </VSFormLayout>
      </VSForm>
    </Modal>
  );
};
export default definePage(RefundAudit);
