/* eslint-disable no-case-declarations */
import vsf from '@vs/vsf-boot';
import { Alert, List, Popover, Tooltip } from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import Icon from '@/module/Icon';
import { day } from '@/pattern';

import Determine from '../../../determine';
import Flex from '../../../flex';
import { askFixed } from '../../../func/ask';
import { dataSourceHerb } from './datasource';

const render = ({ select, getValue }) => {
  return {
    rowClassName: (record) => {
      let resClassName = '';
      if (select?.value?.id === record?.id) {
        resClassName = 'aspirin-table-row-active ';
      }
      const { orderStatus } = record ?? {};
      switch (orderStatus) {
        case 'SAVE':
          resClassName += 'aspirin-table-row-order-blue';
          break;
        case 'SUBMIT':
          resClassName += 'aspirin-table-row-order-green';
          break;
        case 'PERFORM':
          resClassName += 'aspirin-table-row-order-black';
          break;
        case 'STOP':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'CANCEL':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'AUDIT_FAIL':
          resClassName += 'aspirin-table-row-order-pink';
          break;
        default:
          resClassName += 'aspirin-table-row-order-blue';
          break;
      }
      return resClassName;
    },
    renderOrderText: (...args) => {
      const [, tableRecord] = args;
      const record = getValue()?.find((item) => item?.id === tableRecord?.id);

      const { examOrder, labOrder, operationOrder, pathologyOrder, drugOrder } =
        record || {};
      const content = record?.orderText?.drugProductName
        ? `${record?.orderText?.drugProductName}(${record?.orderText?.packageSpecification})`
        : record?.orderText;
      let name = (
        <Popover title="详细信息" content={content} placement="right">
          <div className={classnames('aspirin-order-text', record?.groupName)}>
            <Determine
              condition={Boolean(
                examOrder?.emergencyIndicator ||
                  labOrder?.emergencyIndicator ||
                  operationOrder?.operationApplyType === 'EMERGENCY' ||
                  pathologyOrder?.emergencyIndicator,
              )}
            >
              <Popover content="加急">
                <Icon
                  type="icon-a-Frame1517"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              </Popover>
            </Determine>
            <Determine condition={Boolean(drugOrder?.drug?.dangerIndicator)}>
              <Popover content="高危">
                <Icon
                  type="icon-gaowei"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              </Popover>
            </Determine>
            <Determine
              condition={
                record?.orderStatus === 'PERFORM' &&
                record?.stopOrderDateTime &&
                record?.stopOrderDoctorId
              }
            >
              <Popover
                placement="top"
                content="停止医嘱待护士校对"
                trigger="hover"
              >
                <Icon type="icon-a-Frame15221" size={30} />
              </Popover>
            </Determine>
            <span>
              {record?.drugOrder?.prescriptionIndicator ||
              record?.drugOrder?.prescriptionId
                ? `[处方] ${content}`
                : record?.orderStatus === 'CANCEL'
                ? `(撤销)${content}`
                : content}
              {/* 原液皮试或非原液皮试的药品 显示皮试类型（原液皮试/非原液皮试）*/}
              {(record?.drugOrder?.skinTestType == 'ORIGINAL_SKIN_TEST' ||
                record?.drugOrder?.skinTestType ==
                  'NOT_ORIGINAL_SKIN_TEST') && (
                <Popover
                  placement="top"
                  trigger="hover"
                  content={
                    record?.drugOrder?.skinTestType == 'ORIGINAL_SKIN_TEST'
                      ? '原液皮试'
                      : record?.drugOrder?.skinTestType ==
                        'NOT_ORIGINAL_SKIN_TEST'
                      ? '非原液皮试'
                      : ''
                  }
                >
                  <Icon
                    size={16}
                    style={{ marginLeft: 6 }}
                    type="icon-a-Frame1518"
                  />
                </Popover>
              )}
              {/* 续用的药品 显示续用理由 SKIN_TEST_CONTINUE_REASON*/}
              {record?.drugOrder?.skinTestType == 'CONTINUE_USE' && (
                <Popover
                  // content={record?.drugOrder?.skinTestContinueReason ?? ''}
                  content={
                    vsf.stores.dbenums.enums.ST_CONTINUT_REASON_DICT?.find(
                      (i: any) => {
                        return (
                          i?.value == record?.drugOrder?.skinTestContinueReason
                        );
                      },
                    )?.label
                  }
                >
                  <Icon
                    size={16}
                    style={{ marginLeft: 6 }}
                    type="icon-a-Frame1519"
                  />
                </Popover>
              )}
              {/* 皮试结果阴性 vsf.stores.dbenums.enums.SKIN_RESULT 1 */}
              {record?.drugOrder?.skinTestResult == '1' && (
                <Popover content="皮试阴性">
                  <Icon
                    size={16}
                    style={{ marginLeft: 6 }}
                    type="icon-a-Frame1520"
                  />
                </Popover>
              )}
              {/* 皮试结果阳性 vsf.stores.dbenums.enums.SKIN_RESULT 2 */}
              {record?.drugOrder?.skinTestResult == '2' && (
                <Popover content="皮试阳性">
                  <Icon
                    size={16}
                    style={{ marginLeft: 6 }}
                    type="icon-a-Frame1521"
                  />
                </Popover>
              )}
            </span>
          </div>
        </Popover>
      );

      switch (record?.orderClass) {
        case 'EXAM':
          {
            name = (
              <Popover
                placement="right"
                title={
                  <div className="aspirin-title-popover-box">
                    检查
                    <span> {record?.examOrder?.examOrderDetail?.length} </span>
                    项详情
                  </div>
                }
                content={
                  <List
                    size="small"
                    dataSource={record?.examOrder?.examOrderDetail}
                    renderItem={(item: any, index) => {
                      return (
                        <List.Item
                          style={{
                            padding: '8px 0',
                            width: '100%',
                          }}
                        >
                          <Alert
                            type="info"
                            style={{
                              width: '100%',
                            }}
                            message={`${index + 1}. ${item?.examItemName}`}
                          />
                        </List.Item>
                      );
                    }}
                  />
                }
                trigger="hover"
              >
                <div
                  className={classnames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  <Determine
                    condition={Boolean(
                      examOrder?.emergencyIndicator ||
                        labOrder?.emergencyIndicator ||
                        operationOrder?.operationApplyType === 'EMERGENCY' ||
                        pathologyOrder?.emergencyIndicator,
                    )}
                  >
                    <Popover content="加急">
                      <Icon
                        type="icon-a-Frame1517"
                        size={20}
                        style={{
                          marginRight: 4,
                        }}
                      />
                    </Popover>
                  </Determine>
                  <span>{content}</span>
                </div>
              </Popover>
            );
          }
          break;
        case 'HERB':
          const use_way_label = record?.herbOrder?.useWay;
          const useWay = use_way_label?.label
            ? use_way_label?.label
            : vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
                (item) => use_way_label == item?.value,
              )?.label;
          const frequency = record?.herbOrder?.frequency?.frequency;
          const use_time_description = record?.herbOrder?.useTimeDescription;
          const useTimeDescription = use_time_description?.label
            ? use_time_description?.label
            : vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
                (item) => item?.value == use_time_description,
              )?.label;
          name = (
            <Popover
              placement="right"
              overlayClassName="aspirin-herb-box"
              content={
                <List
                  size="small"
                  header={
                    <div className="aspirin-title-herb-popover-box">
                      <div className="aspirin-title-herb-header-box">
                        <div className="aspirin-title-herb-header-left-box">
                          <span>中药汤剂明细</span>
                          <span>
                            共 {record?.herbOrder?.herbOrderDetail?.length} 项
                          </span>
                        </div>
                        <div className="aspirin-title-herb-header-right-box">
                          <Icon
                            type="icon-zhongtangyaodanchuangbeijing"
                            style={{
                              color: 'rgb(232, 225, 217)',
                            }}
                            size={56}
                          />
                        </div>
                      </div>
                      <div className="aspirin-title-message-box">
                        <div>使用方法: {useWay}</div>
                        <div>服用时间: {useTimeDescription}</div>
                        <div>服用频次: {frequency}</div>
                        <div>付数: {record?.herbOrder?.herbRegimen}</div>
                        <div className="aspirin-title-message-memo-box">
                          备注: {record?.memo}
                        </div>
                      </div>
                    </div>
                  }
                  dataSource={record?.herbOrder?.herbOrderDetail}
                  className="aspirin-list-herb-popover-item-box"
                  renderItem={(item: any, index) => {
                    const find =
                      vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT?.find(
                        ({ value }) => item?.useDescription === value,
                      );
                    const medicalInsuranceType = dataSourceHerb?.find(
                      ({ value }) => value === item?.medicalInsuranceType,
                    )?.label;
                    return (
                      <Alert
                        type="warning"
                        message={
                          <div className="aspirin-title-herb-popover-item-box">
                            <span>{index + 1}</span>
                            <span>{item?.drugProductName}</span>
                            <span>
                              {Object?.values(
                                {
                                  value: item?.totalAmount,
                                  unit:
                                    vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                                      ({ value }) => value == item?.packageUnit,
                                    )?.label ?? item.packageUnit,
                                } ?? {},
                              )}
                            </span>
                            {find?.label && <span>{find?.label}</span>}
                            <span>
                              {String?.(item?.id).startsWith('create')
                                ? askFixed(item?.price ?? 0, 2)
                                : askFixed(
                                    item?.price *
                                      (item?.totalAmount?.value ??
                                        item?.totalAmount),
                                    2,
                                  )}
                              {medicalInsuranceType
                                ? '/' + medicalInsuranceType
                                : ''}
                            </span>
                          </div>
                        }
                      />
                    );
                  }}
                />
              }
              trigger="hover"
            >
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                <span>{record?.orderText?.drugName ?? record?.orderText}</span>
              </div>
            </Popover>
          );
          break;
      }
      return name;
    },
    renderRepeatValidDays: (...[, record]) => {
      /**
       * 仅执行状态的医嘱（ORDER.STATUS = 2 执行）显示闹钟图标
       * 当长期医嘱有效期天数不为空，且未达到设置的有效期时（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME < REPEAT_VALID_DAYS），在有效期天数后显示浅蓝色闹钟图标
       * 鼠标移入图标时，出现气泡，显示“有效期剩余X天”，天数计算公式：REPEAT_VALID_DAYS-（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME）
       * 当达到设置的有效期当天时（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME = REPEAT_VALID_DAYS），在有效期天数后显示橙色闹钟图标\
       * 鼠标移入图标时，出现气泡，显示“有效期剩余0天”，天数计算公式：REPEAT_VALID_DAYS-（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME）
       * 当超过有效期后（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME > REPEAT_VALID_DAYS）,闹钟图标变为红色
       * 鼠标移入图标时，出现气泡，显示“已超过有效期X天”，天数计算公式：（当前时间SYSTEMDATE-开始执行时间ORDER.START_PERFORM_DATE_TIME）-REPEAT_VALID_DAYS
       */
      const today = dayjs().format('YYYY/MM/DD');
      const startPerformDateTime = dayjs?.(
        record?.startPerformDateTime,
      )?.format('YYYY/MM/DD');
      const repeatValidDays = record?.repeatValidDays;
      const diff = dayjs(today).diff(startPerformDateTime, 'day');
      const blue = diff < repeatValidDays;
      const orange = diff === repeatValidDays;
      const red = diff > repeatValidDays;

      return (
        <Flex justify="space-between" align="center">
          <Determine condition={Boolean(repeatValidDays)}>
            <Determine
              condition={record?.orderStatus === 'PERFORM'}
              fallback={<span>{repeatValidDays}天</span>}
            >
              <Determine
                condition={
                  record?.repeatIndicator === true &&
                  ![null, void 0]?.includes(record?.repeatValidDays)
                }
              >
                <span>{repeatValidDays}天</span>
                <div className="aspirin-date-box">
                  <Determine condition={blue}>
                    <Popover
                      placement="top"
                      content={`有效期剩余${repeatValidDays - diff}天`}
                      trigger="hover"
                    >
                      <Icon
                        type="icon-naozhong"
                        style={{ color: 'var(--blue)' }}
                        size={24}
                      />
                    </Popover>
                  </Determine>
                  <Determine condition={orange}>
                    <Popover
                      content="有效期剩余0天"
                      placement="top"
                      trigger="hover"
                    >
                      <Icon
                        type="icon-naozhong"
                        style={{ color: 'rbg(255, 155, 69)' }}
                        size={24}
                      />
                    </Popover>
                  </Determine>
                  <Determine condition={red}>
                    <Popover
                      content={`已超过有效期${diff}天`}
                      placement="top"
                      trigger="hover"
                    >
                      <Icon
                        type="icon-naozhong"
                        style={{ color: 'var(--red)' }}
                        size={24}
                      />
                    </Popover>
                  </Determine>
                </div>
              </Determine>
            </Determine>
          </Determine>
        </Flex>
      );
    },
  };
};

export default render;
