import { NewDrawer } from '@/pages/Index/components';
import { getScrollY } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, VSTable, VSTableColumn } from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { useEffect } from 'react';
import { getNoSettleInpVisitList } from './services';

// const AwaitDrawer = (props) => {
//   const { data, run } = useRequest(
//     async (params) => {
//       const res = await getNoSettleInpVisitList();
//       console.log(res);
//       return res;
//     },
//     { manual: true },
//   );
//   const { DrawerProps, setDrawerProps } = props;
//   const onClose = () => {
//       setDrawerProps({
//         ...DrawerProps,
//         open: false,
//       });
//     },
//     reload = () => {};
//   useEffect(() => DrawerProps?.open && run?.(), [DrawerProps]);
//   return (
//     <NewDrawer
//       open={DrawerProps?.open}
//       width="40%"
//       onOpen={() => setDrawerProps({ open: !DrawerProps?.open })}
//       title={<div style={{ color: '#3276E8' }}>待出院患者</div>}
//       buttonTitle="待出院患者"
//       backgroundColor="var(--blue)"
//       footer={
//         <div className="aspirin-table-footer-button">
//           <Button type="primary" onClick={reload}>
//             刷新
//           </Button>
//           <Button type="primary" onClick={onClose}>
//             隐藏
//           </Button>
//         </div>
//       }
//       buttonStyle={{
//         top: '50%',
//       }}
//       drift="68px"
//       style={{
//         top: 104,
//       }}
//       isOther
//     >
//       待出院患者
//     </NewDrawer>
//   );
// };
import Outdrawer from '../../financial/hospitalization-settlement/outdrawer';
const AwaitDrawer = (props) => {
  const selectDrawerDataChange = (params) => props?.AwaitDrawerCallback(params);
  return <Outdrawer {...{ selectDrawerDataChange }} />;
};
export default definePage(AwaitDrawer);
