import { useState } from 'react';

type SetActionState<S> = (state: S) => void;

type State<S> = [S, SetActionState<S>];

/**
 * 防止空值
 */
export default function useAvoidState<S>(
  initialState: S,
  avoid?: any,
): State<S> {
  const [state, setState] = useState(initialState);
  let data = state;
  const Avoid = avoid ? avoid : '';
  switch (typeof initialState) {
    case 'object':
      if (state) {
        data = state ? state : Avoid;
        break;
      }
      if (Array?.isArray(state)) {
        data = state?.length ? state : Avoid;
        break;
      }
      if (state instanceof Object) {
        data = Object?.keys(state)?.length ? state : Avoid;
        break;
      }
      break;
    case 'string':
      data = state ? state : Avoid;
      break;
    case 'number':
      data = Number?.isNaN(state) ? Avoid : state;
      break;
  }
  return [data, setState];
}
