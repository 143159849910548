import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitController_queryAllGreenWaitByErpVisitForPreExamPatientListQto_c982fb:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/query-all-green-wait-by-erp-visit-for-pre-exam-patient-list-qto',
      parameterFomatter: (data?: {
        qto: ErpVisitForPreExamPatientListQtoClinicOutpVisitPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OutpVisitForPreExamPatientVoClinicOutpVisitEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicErpPreExamController_updateErpExamRecordMasterForSpecialPatient_6afd29:
    {
      method: 'post',
      url: '/api/clinic-erp-pre-exam/update-erp-exam-record-master-for-special-patient',
      parameterFomatter: (data?: {
        btoParam: UpdateErpExamRecordMasterForSpecialPatientBtoClinicErpPreExamServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
});
