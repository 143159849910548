import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpPreExamController_createNewAccidentEvent_e5b269: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/create-new-accident-event',
    parameterFomatter: (data?: {
      saveErpAccidentEventRecordMasterBto: SaveErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
