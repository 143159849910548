import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_updateDrugProductExtension_0df600: {
    method: 'post',
    url: '/api/drug-drug/update-drug-product-extension',
    parameterFomatter: (data?: {
      btoParam: UpdateDrugProductExtensionBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_getDrugPorductExtensionByDrugProductId_e17bca: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-extension-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
});
