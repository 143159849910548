import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyDepartmentList_59899b:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-department-list',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: DepartmentDtoOrganizationManagerDto[],
      ) => data,
    },
});
