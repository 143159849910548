import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';

const RollbackModal = (props, ref): any => {
  const { onSubmit, onReload } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();
  const [reject] = useOnChangeValue('');
  const [failList, setFailList] = useState<any>();
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = () => {
    onClose();
    onSubmit?.();
  };

  const onBackDoctor = useFetch({
    fetch: async () => {
      const res =
        await vsf?.services?.OrderCheckController_backToDoctor_cc25e8?.({
          backToDoctorEos: value?.map((item) => ({
            orderId: item?.id,
            reason: reject?.value,
          })),
        });
      return res;
    },
    message: {
      loading: '回退医嘱中',
      error: '回退医嘱失败',
      success: '回退医嘱成功',
    },
    success(res) {
      console.log(res, res?.data?.[0]?.failOrderList?.length <= 0);
      if (res?.data?.[0]?.failOrderList?.length <= 0) {
        onClose?.();
        onReload?.();
      } else {
        // 打开弹窗
        onClose?.();
        setModalOpen(true);
        setTimeout(() => {
          setFailList(
            res?.data?.[0]?.failOrderList?.map((item) => {
              return { ...item, patientName: res?.data?.[0]?.patientName };
            }),
          );
        }, 100);
      }
    },
  });

  const renderContent = useCallback(() => {
    return (
      <div className="content">
        <div className="first">
          <VSTable value={value}>
            <VSTableColumn
              valueType="select"
              dataIndex={['repeatIndicator']}
              title="长/临"
              dataSource={[
                {
                  label: '长期',
                  value: true,
                },
                {
                  label: '临时',
                  value: false,
                },
              ]}
              preview
            />

            <VSTableColumn
              dataIndex={['orderClass']}
              title="类别"
              valueType="select"
              fieldProps={{}}
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '病理', value: 'PATHOLOGY' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
              ]}
              preview
            />

            <VSTableColumn
              dataIndex={['createDateTime']}
              title="开始时间"
              valueType="date"
              fieldProps={{
                format: 'MM-DD HH:mm',
              }}
              render={(...args) => {
                const [, record] = args;
                const { startPerformDateTime } = record || {};
                return startPerformDateTime
                  ? dayjs(startPerformDateTime).format('MM-DD HH:mm')
                  : '';
              }}
              preview
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱内容"
              valueType="text"
              // render={render}
              preview
            />

            <VSTableColumn
              dataIndex={['back']}
              title="回退"
              valueType="text"
              // render={render}
              preview
            />

            <VSTableColumn
              dataIndex={['tips']}
              title="操作提示"
              valueType="text"
              // render={render}
              preview
            />
          </VSTable>
        </div>
        <div className="second">
          <div>温馨提示</div>
          <div>可以根据操作提示处理后再进行医嘱回退操作</div>
          <div>
            <span>回退原因</span>
            <Select
              {...reject}
              showSearch
              placeholder="请选择回退原因"
              filterOption={askSearch}
              options={vsf.stores.dbenums.enums.REGRESSION_REASON_DICT}
            />
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reject, value]);

  const renderContent1 = () => {
    return (
      <div className="content">
        <div className="first">
          <VSTable value={failList} pagination={false}>
            <VSTableColumn
              valueType="text"
              dataIndex={['patientName']}
              title="患者信息"
              preview
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱内容"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['errorMessage']}
              title="失败详情"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
      </div>
    );
  };
  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button type="primary" onClick={onClose}>
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (reject?.value) {
                onBackDoctor();
              } else {
                message.error('请选择回退原因');
              }
            }}
          >
            确定
          </Button>
        </div>
      ),
    };
  }, [onBackDoctor, onClose, reject?.value]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  // if (!open) {
  //   return null;
  // }

  return (
    <div>
      <Modal
        width={1168}
        open={open}
        destroyOnClose={true}
        title="回退医嘱"
        onCancel={onClose}
        {...renderFooter()}
        className="aspirin-check-order-rollback-modal"
      >
        {renderContent()}
      </Modal>
      <Modal
        width={1168}
        open={modalOpen}
        destroyOnClose={true}
        title="回退医嘱失败信息"
        // onCancel={onClose}
        onCancel={() => {
          setModalOpen(false);
        }}
        footer={[
          <Button
            key="close"
            onClick={() => {
              setModalOpen(false);
            }}
            ghost
            type="primary"
          >
            关闭
          </Button>,
        ]}
        className="aspirin-check-order-rollback-modal"
      >
        {renderContent1()}
      </Modal>
    </div>
  );
};

export default forwardRef(RollbackModal);
