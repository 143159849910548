import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStocktakingController_getDrugStocktakingClassList_28161f: {
    method: 'post',
    url: '/api/drug-storage/get-drug-stocktaking-class-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  DrugStocktakingController_createDrugStocktakingClass_bfbdee: {
    method: 'post',
    url: '/api/drug-storage/create-drug-stocktaking-class',
    parameterFomatter: (data?: {
      createBtoList: CreateBaseCodeBtoBaseDictionaryServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStocktakingController_updateDrugStocktakingClass_85e160: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stocktaking-class',
    parameterFomatter: (data?: {
      updateBtoList: UpdateBaseCodeBtoBaseDictionaryServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStocktakingController_deleteDrugStocktakingClass_e40876: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-stocktaking-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
