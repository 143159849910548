import React from 'react';

const data = [
  {
    name: '微生物',
    data: ['血培养及鉴定+药敏[全血]', '血培养及鉴定+药敏[全血]'],
  },
  {
    name: '免疫',
    data: [
      '自身免疫抗体测定[血清(静脉采血)]',
      '自身免疫抗体测定[血清(静脉采血)]',
      '自身免疫抗体测定[血清(静脉采血)]',
    ],
  },
  {
    name: '急诊检验',
    data: ['血培养及鉴定+药敏[全血]', '血培养及鉴定+药敏[全血]'],
  },
  {
    name: '微生物',
    data: ['血培养及鉴定+药敏[全血]', '血培养及鉴定+药敏[全血]'],
  },
  {
    name: '免疫',
    data: [
      '自身免疫抗体测定[血清(静脉采血)]',
      '自身免疫抗体测定[血清(静脉采血)]',
      '自身免疫抗体测定[血清(静脉采血)]',
    ],
  },
  {
    name: '急诊检验',
    data: ['血培养及鉴定+药敏[全血]', '血培养及鉴定+药敏[全血]'],
  },
];
// applyCategory
const Index = (props) => {
  const { data, className = '', type } = props;

  const handelList = () => {
    const arr: any = [];
    data?.forEach((item) => {
      if (arr?.find((v) => v.title === item?.itemClass)) {
        arr?.[
          arr?.findIndex((v) => v.title === item?.itemClass)
        ]?.content?.push(`${item?.orderText}`);
      } else {
        arr?.push({
          title: item?.itemClass,
          content:
            type === 'EXAMINE'
              ? [`${item?.examItemName}`]
              : [`${item?.orderText}`],
        });
      }
    });
    return arr;
  };

  return (
    <div className={`exam-block ${className}`}>
      {handelList()?.map((item, index) => {
        return (
          <div key={index} className="item">
            <div className="type">{item?.title}</div>
            <div className="detail">
              {item?.content?.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
