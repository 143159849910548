import { disposalOrderClassDataSource } from './datasource';

/**
 * 剂量转换
 * @param data
 * @returns
 */
export const dosageConvert = (data) => {
  const { orderClass, drugOrder, herbOrder } = data;

  const CLASS = {
    DRUG: () => {
      return Object?.keys(drugOrder?.dosage ?? {}).includes('value')
        ? drugOrder?.dosage
        : {
            value: drugOrder?.dosage,
            unit: drugOrder?.dosageUnit,
          };
    },
    HERB: () => {
      return {
        value: herbOrder?.herbDosage,
        unit: herbOrder?.herbDosage ? 'ml' : '',
      };
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 用量转换
 * @param data
 * @returns
 */
export const usageConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return Object?.keys(drugOrder?.usage ?? {}).includes('value')
        ? drugOrder?.usage
        : {
            value: drugOrder?.usage,
            unit: drugOrder?.usageUnit,
          };
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 途径转换
 * @param data
 * @returns
 */
export const administrationConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.administration;
    },
    HERB: () => {
      return herbOrder?.useWay;
    },
  };

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  if (isDisposalOrder) {
    return disposalOrder?.administration;
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 滴速转换
 * @param data
 * @returns
 */
export const dripSpeedConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.dripSpeed;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 频次转换
 * @param data
 * @returns
 */
export const frequencyConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.frequency;
    },
    HERB: () => {
      return herbOrder?.frequency;
    },
  };

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  // if (isDisposalOrder) {
  //   return disposalOrder?.frequency;
  // } else {
  //   return CLASS[orderClass]?.();
  // }
  return CLASS[orderClass]?.() ?? disposalOrder?.frequency;
};

/**
 * 数量转换
 * @param data
 * @returns
 */
export const totalAmountConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return Object?.keys(drugOrder?.totalAmount ?? {}).includes('value')
        ? drugOrder?.totalAmount
        : {
            value: drugOrder?.totalAmount,
            unit: drugOrder?.packageUnit,
          };
    },
    HERB: () => {
      return Object?.keys?.(herbOrder?.herbRegimen ?? {})?.includes('value')
        ? herbOrder?.herbRegimen
        : {
            value: herbOrder?.herbRegimen,
            unit: '付',
          };
    },
  };

  // const isDisposalOrder = disposalOrderClassDataSource?.find(
  //   (item) => item?.value === orderClass,
  // );

  if (CLASS[orderClass]?.()) {
    return CLASS[orderClass]?.();
  } else {
    return {
      value: disposalOrder?.amount,
      unit: '',
    };
  }
};

/**
 * 用法转换
 * @param data
 * @returns
 */
export const descriptionConvert = (data) => {
  const { orderClass, drugOrder, herbOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.useDescription;
    },
    HERB: () => {
      return herbOrder?.useTimeDescription;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 有效期转换
 * @param data
 * @returns
 */
export const performDaysConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, repeatIndicator } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.performDays;
    },
    HERB: () => {
      return herbOrder?.performDays;
    },
  };

  if (repeatIndicator || drugOrder?.prescriptionId) {
    return drugOrder?.performDays || '';
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 摆药方式转换
 * @param data
 * @returns
 */
export const drugBillingAttributeConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.drugBillingAttribute === 'SELF_PROVIDE';
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 配置转换
 * @param data
 * @returns
 */
export const distributeConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.distribute;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 出院带药转换
 * @param data
 * @returns
 */
export const dischargeDrugConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return !!drugOrder?.prescriptionId;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 持续时间转换
 * @param data
 * @returns
 */
export const durationConvert = (data) => {
  const { orderClass, disposalOrder } = data;

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  return isDisposalOrder
    ? disposalOrder?.duration
      ? `${disposalOrder?.duration}${disposalOrder?.durationUnit}`
      : ''
    : '';
};

/**
 * 注射转换
 * @param data
 * @returns
 */

export const administrationAmountConvert = (data) => {
  return data?.drugOrder?.administrationAmount ?? '';
};

export const dispenseHandleTypeCovert = (data) => {
  return {
    DRUG: () => {
      return data?.drugOrder?.dispenseHandleType;
    },
    HERB: () => {
      return data?.herbOrder?.dispenseHandleType;
    },
  }?.[data?.orderClass]?.();
};
