import React, { useCallback, useEffect, useRef, useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Drawer,
  Image,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Button,
  Divider,
  Space,
  message,
  Row,
  Col,
} from '@vs/vsf-kit';
import './components/index.less';
const _ = require('lodash');
import yes from '@/assets/icon/yes.png';
import { VSTableColumn, VSTable, VSTableAddition } from '@vs/vsf-kit';
import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelNumberWidth,
} from '@/utils';
import Icon from '@/module/Icon';
import '../../../../../assets/fonts/you/you/iconfont.css';
import { removeIds, repeatArrayElements } from '@/utils/setDataUtil';
import { useMount } from 'ahooks';
const createIsNumberOrderRuleData = (
  total,
  limitTotal,
  time,
  appointStartNumber,
  onlyIndex,
) => {
  let flag;
  if (time) {
    let startTime =
      total?.startTimePoint?.hour * 60 + total?.startTimePoint?.minute;

    let endTime = total?.endTimePoint?.hour * 60 + total?.endTimePoint?.minute;

    //总时间：单位：min
    let totalTime = endTime - startTime;

    const timeSegmentCount = Math.floor(totalTime / time);

    startTime = startTime - time;

    return Array.from({ length: timeSegmentCount }).map((_, index) => {
      startTime = startTime + time;
      endTime = startTime + time;

      if (index < appointStartNumber) {
        flag = 1;
      } else {
        flag = 0;
      }
      return {
        serialNumber: index + 1,
        waitTimePeriod: {
          startTimePoint: {
            hour: Math.floor(startTime / 60),
            minute: startTime % 60,
          },
          endTimePoint: {
            hour: Math.floor(endTime / 60),
            minute: endTime % 60,
          },
        },
        flag: flag,
        index: onlyIndex,
      };
    });
  } else {
    let Total = limitTotal;
    let startTime =
      total?.startTimePoint?.hour * 60 + total?.startTimePoint?.minute;
    let endTime = total?.endTimePoint?.hour * 60 + total?.endTimePoint?.minute;

    let avgTime = (endTime - startTime) / limitTotal;

    if (avgTime > 1) {
      avgTime = Math.floor(avgTime);
    } else {
      avgTime = Math.ceil(avgTime);
    }

    let timeSegmentCount = Math.floor((endTime - startTime) / avgTime);

    startTime = startTime - avgTime;

    return Array.from({ length: timeSegmentCount }).map((item, index) => {
      startTime = startTime + avgTime;
      endTime = startTime + avgTime;

      let limit = index + 1;
      if (limit > Total) {
        limit = 0;
      }

      if (index < appointStartNumber) {
        flag = 1;
      } else {
        flag = 0;
      }

      return {
        serialNumber: limit,
        waitTimePeriod: {
          startTimePoint: {
            hour: Math.floor(startTime / 60),
            minute: startTime % 60,
          },
          endTimePoint: {
            hour: Math.floor(endTime / 60),
            minute: endTime % 60,
          },
        },
        flag: flag,
        index: onlyIndex,
      };
    });
  }
};
function AddTable(props) {
  const { open, onCancel, checkCellData } = props;
  const [createNumberOrderRuleData, setCreateNumberOrderRuleData] = useState();
  const [numberOrderRuleTime, setNumberOrderRuleTime] = useState(true);
  const [dayWorkFile, setDayWorkFile] = useState();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [saveMap, setSaveMap] = useState();
  const [avgTime, setAvgTime] = useState();
  const m = new Map();

  useEffect(() => {
    const m = new Map();
    setSaveMap(m);
  }, [open]);
  const onFetch = async () => {
    if (checkCellData?.id && open) {
      await vsf?.services
        ?.OutpRegisterController_getClinicRegisterScheduleWithDetailById_ab9458?.(
          {
            id: checkCellData?.id,
          },
        )
        .then((res) => {
          // m.clear();
          setAvgTime(res?.data?.clinicRegisterDefine?.averageVisitTime);
          res?.data?.clinicRegisterScheduleDetailDetailDtoList.map(
            (item, index) => {
              const data =
                item?.clinicRegisterScheduleDetailTimeDivisionDtoList.sort(
                  function (a, b) {
                    return a.serialNumber - b.serialNumber;
                  },
                );
              // console.log('-------------');
              // console.log(data);

              if (!saveMap) {
                const m1 = new Map();
                m1.set(index + 1, data);
                setSaveMap(m1);
              } else {
                saveMap.set(index + 1, data);
              }

              // setSaveMap(m1)
            },
          );
          setSaveMap(saveMap);

          const data =
            res?.data?.clinicRegisterScheduleDetailDetailDtoList[0]?.clinicRegisterScheduleDetailTimeDivisionDtoList?.map(
              (item, index) => {
                if (
                  index <
                  res?.data?.clinicRegisterScheduleDetailDetailDtoList[0]
                    ?.appointStartNumber
                ) {
                  return { ...item, flag: 1 };
                } else {
                  return { ...item, flag: 0 };
                }
              },
            );
          vsf?.refs?.ClinicRegisterScheduleDetailForm?.setFieldValue(
            ['clinicRegisterScheduleDetailTimeDivisionDtoList1'],
            data,
          );
          setUpdateStatus(false);

          setDayWorkFile(res?.data?.dayOfWeek);

          vsf?.refs?.ClinicRegisterScheduleDetailForm?.setFieldsValue({
            ...res?.data,
            dayOfWeek: [res?.data?.dayOfWeek],
          });

          vsf?.refs?.ClinicRegisterScheduleDetailForm?.setFieldValue(
            ['clinicRegisterScheduleDetailBtoList'],
            res?.data?.clinicRegisterScheduleDetailDetailDtoList,
          );
          return res?.data;
        });
    }
  };

  useEffect(() => {
    onFetch();
    !checkCellData &&
      vsf?.refs?.ClinicRegisterScheduleDetailForm?.resetFields();
    !checkCellData &&
      vsf.refs?.ClinicRegisterScheduleDetailForm?.setFieldValue(
        ['clinicRegisterScheduleDetailDetailDtoList1'],
        {},
      );
  }, [checkCellData, open]);

  //根据号别带入信息
  useEffect(() => {
    vsf?.refs?.ClinicRegisterScheduleDetailForm?.setFieldsValue({
      clinicRegisterDefine: createNumberOrderRuleData,
      roomLocation: createNumberOrderRuleData?.roomLocation,
    });
  }, [createNumberOrderRuleData]);

  const handleSubmit = () => {
    vsf?.refs?.ClinicRegisterScheduleDetailForm?.submit();
  };

  const handleReset = () => {
    vsf?.refs?.ClinicRegisterScheduleDetailForm?.resetFields();
  };

  const onSubmit = async (params) => {
    params.clinicRegisterDefineId = params.clinicRegisterDefine.id;
    const file = JSON.parse(JSON.stringify(params));

    const formdata = file.clinicRegisterScheduleDetailBtoList?.map(
      (item, index) => {
        return {
          ...item,
          clinicRegisterScheduleDetailTimeDivisionBtoList: saveMap.get(
            index + 1,
          ),
        };
      },
    );

    const formatData = formdata.map((d) => {
      const dict = { ...d, branchInstitutionId: d.branchInstitution?.id };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });

    const isSubmit = formdata?.map((item, index) => {
      if (!saveMap.get(index + 1)) {
        message.error(
          `[${item?.summerIndicator ? '夏令' : '冬令'},${
            item?.timeDescription === 'AM' ? '上午' : '下午'
          }]需要重新生成号序`,
        );
        return false;
      } else {
        return true;
      }
    });

    file.clinicRegisterScheduleDetailBtoList = formatData;

    if (Array.isArray(file.dayOfWeek)) {
      let arr = file?.dayOfWeek?.map((item) => {
        if (item === dayWorkFile) {
          return { ...file, dayOfWeek: item };
        } else {
          if (file?.id) {
            delete file?.id;
          }

          return { ...removeIds(file), dayOfWeek: item };
        }
      });

      if (!isSubmit.includes(false)) {
        const res =
          await vsf?.services?.OutpRegisterController_createUpdateClinicRegisterSchedule_e56057?.(
            {
              btoParamList: arr,
            },
          );
        if (res.code === 200) {
          message.success('保存成功');
          vsf?.refs?.clinicRegisterScheduleTable?.reload();
        }
        onCancel();
        m.clear();
        setSaveMap(saveMap.clear());
      } else {
        return;
      }
    }
    // return params;
  };

  const onClose = () => {
    setNumberOrderRuleTime(null);
    onCancel();
  };

  const handelValuesChange = async (v) => {
    const formParams =
      await vsf.refs?.ClinicRegisterScheduleDetailForm.getFieldsValue();
    if (v?.clinicRegisterDefine?.id || v?.dayOfWeek?.length) {
      if (
        formParams?.clinicRegisterDefine?.id &&
        formParams?.dayOfWeek?.length
      ) {
        const res =
          await vsf.services?.OutpRegisterController_getClinicRegisterScheduleWithDetailByDefineIdAndDayOfWeek_b3f6b6?.(
            {
              clinicRegisterDefineId: formParams?.clinicRegisterDefine?.id,
              dayOfWeekList: formParams?.dayOfWeek,
            },
          );
        if (
          res.code === 200 &&
          res?.data?.clinicRegisterScheduleDetailDetailDtoList
        ) {
          vsf?.refs?.ClinicRegisterScheduleDetailForm?.setFieldValue(
            ['clinicRegisterScheduleDetailBtoList'],
            res?.data?.clinicRegisterScheduleDetailDetailDtoList,
          );
        }
      }
    }
  };

  return (
    <Drawer open={open} closable={false} width={'90%'} onClose={onClose}>
      <VSControlledForm
        id="ClinicRegisterScheduleDetailForm"
        onSubmit={onSubmit}
        label
        labelAlign="left"
        onValuesChange={handelValuesChange}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormItem
          name={['clinicRegisterScheduleDetailBtoList']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicRegisterDefine', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicRegisterDefine', 'clinicRegisterType', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={[
            'clinicRegisterDefine',
            'clinicRegisterType',
            'branchInstitution',
            'id',
          ]}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicRegisterDefine', 'clinicDepartment', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicRegisterDefine', 'doctor', 'id']}
          label="组件"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicRegisterDefine', 'outpSpecialClinic', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={[
            'clinicRegisterDefine',
            'outpSpecialClinic',
            'branchInstitution',
            'id',
          ]}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout key="8" columnCount={4} labelWidth={labelNumberWidth(6)}>
          <VSFormItem
            name={['clinicRegisterDefine', 'clinicLabel']}
            label="号别"
            valueType="select"
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{
              dataSource: async (params) => {
                const res =
                  await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterDefineNewQto_3be984?.(
                    {
                      qto: { ...params },
                    },
                  );
                //过滤停用
                const file = res?.data?.filter((item) => {
                  return !item?.stopIndicator;
                });
                return file;
              },
              fieldNames: { label: 'clinicLabel', value: 'id' },
              showSearch: true,
              searchKey: 'clinicLabelLike',
              onChange: (value) => {
                setCreateNumberOrderRuleData(value);
              },
            }}
          />
          <VSFormItem
            name={['clinicRegisterDefine', 'clinicRegisterType', 'clinicType']}
            label="号类"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 12,
                required: true,
              },
            ]}
          />
          <VSFormItem
            name={[
              'clinicRegisterDefine',
              'clinicDepartment',
              'departmentName',
            ]}
            label="门诊科室"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
                required: true,
              },
            ]}
            fieldProps={{ disabled: true }}
          />
          <VSFormItem
            name={[
              'clinicRegisterDefine',
              'outpSpecialClinic',
              'outpSpecialClinicName',
            ]}
            label="门诊专科"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 40,
                required: true,
              },
            ]}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={4} labelWidth={labelNumberWidth(6)}>
          <VSFormItem
            name={['clinicRegisterDefine', 'doctor', 'staffName']}
            label="医生:"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ]}
          />

          <VSFormItem
            name={['clinicRegisterDefine', 'doctor', 'staffNumber']}
            label="工号"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: 'emp_no长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ]}
          />

          <VSFormItem
            name={['clinicRegisterDefine', 'doctor', 'titleCode']}
            label="职称"
            labelAlign="right"
            fieldProps={{ disabled: true, showSearch: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.TITLE_DICT}
          />

          <VSFormItem
            name={['clinicRegisterDefine', 'doctor', 'cellphoneNumber']}
            label="联系号码"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: 'phone_number长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ]}
          />
        </VSFormLayout>
        <VSFormLayout key="10" columnCount={3} labelWidth={labelNumberWidth(8)}>
          <VSFormItem
            name={['effectTimePeriod', 'amTimePeriod']}
            label="上午启用"
            valueType="aspirinDateTimePicker"
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{
              type: 'range',
              format: 'YYYY/MM/DD',
              allowEmpty: [true, true],
            }}
          />

          <VSFormItem
            name={['effectTimePeriod', 'pmTimePeriod']}
            label="下午启用"
            valueType="aspirinDateTimePicker"
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{
              type: 'range',
              format: 'YYYY/MM/DD',
              allowEmpty: [true, true],
            }}
          />

          <VSFormItem
            name={['dayOfWeek']}
            label="星期"
            valueType="select"
            dataSource={[
              { label: '周一', value: 1 },
              { label: '周二', value: 2 },
              { label: '周三', value: 3 },
              { label: '周四', value: 4 },
              { label: '周五', value: 5 },
              { label: '周六', value: 6 },
              { label: '周日', value: 7 },
            ]}
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{
              mode: 'multiple',
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="11" columnCount={1} labelWidth={labelNumberWidth(6)}>
          <Row>
            <Col span={18}>
              <VSFormItem
                name={['roomLocation']}
                label="科室地址"
                valueType="text"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 60,
                    required: true,
                  },
                ]}
                fieldProps={{}}
              />
            </Col>
            <Col span={6} style={{ textAlign: 'right', marginBottom: '23px' }}>
              <Space size={4}>
                <Button onClick={handleReset} className="aspirin-btn">
                  重置
                </Button>
                <Button onClick={handleSubmit} className="aspirin-btn-primary">
                  保存
                </Button>
              </Space>
            </Col>
          </Row>
        </VSFormLayout>
        <VSFormLayout key="12" columnCount={4} columnSpan={[18]}>
          <VSFormItem
            name={['clinicRegisterScheduleDetailBtoList']}
            valueType="custom"
            fieldProps={{}}
          >
            <VSTable
              editable={{
                editType: 'multiple',
                editText: <Icon type="icon-bianji" size={24} />,
                onCanDelete: () => null,
                extraOperations: [
                  {
                    children: <Icon type="icon-shanchu1" size={24} />,
                    onClick: (event, data) => {
                      const file =
                        vsf?.refs?.clinicRegisterScheduleDetailBtoList?.getRowValue(
                          data?.index,
                        );

                      vsf.refs.clinicRegisterScheduleDetailBtoList?.remove(
                        file,
                      );
                      vsf.refs.ClinicRegisterScheduleDetailForm.setFieldValue(
                        ['clinicRegisterScheduleDetailTimeDivisionDtoList1'],
                        [],
                      );
                    },
                  },
                  {
                    children: <div className="iconfont icon-you"></div>,
                    order: 1,
                    onClick: (event, data) => {
                      const rowData =
                        vsf?.refs?.clinicRegisterScheduleDetailBtoList?.getRowValue(
                          data?.index,
                        );
                      if (rowData?.appointLimit > rowData?.totalLimit) {
                        message.error('预约量大于总量！生成号序失败，请修改');
                        vsf.refs.ClinicRegisterScheduleDetailForm.setFieldValue(
                          ['clinicRegisterScheduleDetailTimeDivisionDtoList1'],
                          [],
                        );
                        return;
                      }
                      const file = JSON.parse(
                        JSON.stringify(
                          vsf?.refs?.clinicRegisterScheduleDetailBtoList?.getValues(),
                        ),
                      );
                      // file.pop()
                      // file.map((item)=>{
                      //   // console.log(item)

                      //   if(item&&item?.summerIndicator===rowData?.summerIndicator){
                      //     if(item?.timeDescription===rowData?.timeDescription){
                      //       message.error('同时令的午别不能重复！')
                      //       return
                      //     }
                      //   }
                      // })

                      if (
                        rowData?.appointStartNumber -
                          1 +
                          rowData?.appointLimit >
                        rowData?.totalLimit
                      ) {
                        message.error(
                          '提示当日窗口挂号和限预约量大于总量！生成号序失败，请修改',
                        );
                        return;
                      }
                      vsf?.refs?.clinicRegisterScheduleDetailBtoList
                        .getValues()
                        .map((item) => {});
                      let dataSorce = createIsNumberOrderRuleData(
                        rowData?.waitTimePeriod,
                        rowData?.totalLimit,
                        createNumberOrderRuleData?.averageVisitTime || avgTime,
                        rowData?.appointStartNumber,
                        data?.index,
                      );
                      if (dataSorce?.length > rowData?.totalLimit) {
                        dataSorce = dataSorce.slice(0, rowData?.totalLimit);
                      }

                      if (rowData?.totalLimit > dataSorce?.length) {
                        let intNumber = Math.floor(
                          rowData?.totalLimit / dataSorce.length,
                        );

                        let remainder = rowData?.totalLimit % dataSorce.length;

                        const arr1 = repeatArrayElements(dataSorce, intNumber);

                        let a = 0;
                        for (a = 1; a < remainder + 1; a++) {
                          arr1.splice(a * intNumber, 0, dataSorce[a - 1]);
                        }
                        dataSorce = arr1.map((item, index) => {
                          return { ...item, serialNumber: index + 1 };
                        });
                      }
                      // 限号总量>号序,截取
                      dataSorce = dataSorce?.map((item, index) => {
                        if (index < rowData?.appointStartNumber - 1) {
                          return { ...item, flag: 1 };
                        } else {
                          return { ...item, flag: 0 };
                        }
                      });
                      saveMap.set(data?.index + 1, dataSorce);
                      setSaveMap(saveMap);
                      vsf.refs.ClinicRegisterScheduleDetailForm.setFieldValue(
                        ['clinicRegisterScheduleDetailTimeDivisionDtoList1'],
                        dataSorce,
                      );
                    },
                  },
                ],
                columnProps: {
                  width: 80,
                },
              }}
              vsid="86392"
              id="clinicRegisterScheduleDetailBtoList"
              onRow={(record, index) => {
                return {
                  onChange: (c) => {
                    // console.log(index);
                    // console.log(saveMap.get(index + 1));
                    if (saveMap.get(index + 1)) {
                      saveMap.delete(index + 1);
                      setSaveMap(saveMap);
                    }
                  },
                };
              }}
              onRecord={() => ({ id: 'create_' + Math.random() })}
              pagination={false}
              scroll={{
                // y: getScrollY(400),
                y: getScrollYRem(27),
              }}
            >
              <VSTableColumn
                dataIndex={['id']}
                title="主键"
                hideInTable={true}
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['summerIndicator']}
                title="时令"
                valueType="select"
                dataSource={[
                  { label: '夏令', value: true },
                  { label: '冬令', value: false },
                ]}
                formItemProps={{ rules: [{ required: true }] }}
              />

              <VSTableColumn
                dataIndex={['timeDescription']}
                title="午别"
                valueType="select"
                dataSource={[
                  { label: '上午', value: 'AM' },
                  { label: '下午', value: 'PM' },
                ]}
                fieldProps={{
                  disabled: updateStatus,
                }}
                formItemProps={{ rules: [{ required: true }] }}
              />

              <VSTableColumn
                width={200}
                dataIndex={['waitTimePeriod']}
                title="看诊时间"
                valueType="aspirinDateTimePicker"
                fieldProps={{
                  type: 'range',
                  format: 'HH:mm',
                  disabled: true,
                  timeRangePickerProps: { disabled: updateStatus },
                }}
                formItemProps={{ rules: [{ required: true }] }}
              />

              <VSTableColumn
                dataIndex={['totalLimit']}
                title="限号总量"
                valueType="digit"
                fieldProps={{
                  disabled: updateStatus,
                }}
                formItemProps={{ rules: [{ required: true }] }}
              />

              <VSTableColumn
                dataIndex={['appointLimit']}
                title="限预约量"
                valueType="digit"
                formItemProps={{
                  rules: [
                    {
                      message: '预约限号量的值不合法',
                      type: 'number',
                      min: 0,
                      max: null,
                    },
                  ],
                }}
                fieldProps={{
                  disabled: updateStatus,
                }}
              />

              <VSTableColumn
                dataIndex={['appointStartNumber']}
                title="预约起始号"
                valueType="digit"
                formItemProps={{
                  rules: [
                    {
                      message: '预约起始号的值不合法',
                      type: 'number',
                      min: 0,
                      max: null,
                    },
                  ],
                }}
                fieldProps={{
                  disabled: updateStatus,
                }}
              />

              <VSTableAddition children="添加" />
            </VSTable>
          </VSFormItem>
          <VSFormItem
            name={['clinicRegisterScheduleDetailTimeDivisionDtoList1']}
            valueType="custom"
            fieldProps={
              {
                // style:{height:'100px'}
              }
            }
          >
            <VSTable
              id="clinicRegisterScheduleDetailTimeDivisionDtoList1"
              pagination={false}
              scroll={{
                // y: getScrollY(400),
                y: getScrollYRem(27),
              }}
            >
              <VSTableColumn
                dataIndex={['id']}
                title="主键"
                hideInTable={true}
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['serialNumber']}
                title="号序"
                valueType="digit"
                formItemProps={{
                  rules: [
                    {
                      message: '序号的值不合法',
                      type: 'number',
                      min: 0,
                      max: null,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['index']}
                title="就诊时段"
                valueType="text"
                hideInTable={true}
              />

              <VSTableColumn
                dataIndex={['waitTimePeriod']}
                title="看诊时间"
                valueType="aspirinDateTimePicker"
                fieldProps={{
                  type: 'range',
                  format: 'HH:mm',
                  previewFormat: 'HH:mm',
                }}
                formItemProps={{ rules: [{ required: true }] }}
              />

              <VSTableColumn
                dataIndex={['flag']}
                title="当前窗口号"
                valueType="custom"
                render={(item, props) => {
                  return props?.flag ? (
                    <Image preview={false} width={30} src={yes} />
                  ) : (
                    ''
                  );
                }}
              />
            </VSTable>
          </VSFormItem>
        </VSFormLayout>
      </VSControlledForm>
      <Divider />
    </Drawer>
  );
}

export default AddTable;
