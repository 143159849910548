import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_saveMedicalRecord_a12ec8: {
    method: 'post',
    url: '/api/clinic-inp-visit/save-medical-record',
    parameterFomatter: (data?: {
      updatePatientBto: UpdatePatientBtoPatientMainServiceBto;
      saveInpVisitForMedicalRecordBto: SaveInpVisitForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionStatisticsBto: SaveInpVisitExtensionStatisticsBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionNurseRecordForMedicalRecordBto: SaveInpVisitExtensionNurseRecordForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionBloodInfoBto: SaveInpVisitExtensionBloodInfoBtoClinicInpVisitServiceBto;
      createDiagnosisRecordBtoList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      saveDiagnosisCompareRecordBtoList: SaveDiagnosisCompareRecordBtoClinicRecordServiceBto[];
      saveOperationRecordBtoList: SaveOperationRecordBtoClinicInpVisitRecordServiceBto[];
      saveIcuRecordBtoList: SaveIcuRecordBtoClinicRecordServiceBto[];
      saveBloodTransfusionRecordBto: SaveBloodTransfusionRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicInpVisitController_getMedicalRecordVoById_150e6f: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-medical-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoClinicInpVisitEntranceWebVoMedicalrecordvo[],
    ) => data,
  },
  UserStaffController_getPagedByUserStaffQto_c82b82: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-user-staff-qto',
    parameterFomatter: (data?: { qto: UserStaffNewQtoUserStaffPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
