import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpRegisterAppointQueryRecordController_queryByClinicRegisterAppointRecordQtoPaged_f5f62b:
    {
      method: 'post',
      url: '/api/clinic-outp-register-appoint/query-by-clinic-register-appoint-record-qto-paged-by-clinic-register-appoint-record-qto',
      parameterFomatter: (data?: {
        qto: ClinicRegisterAppointRecordQtoClinicOutpRegisterAppointPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ClinicRegisterAppointRecordVoClinicOutpRegisterAppointEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
