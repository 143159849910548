import SelectForm, {
  askSelectFormInitValue,
} from '@/pages/Index/components/select_form';
import {
  askRomanAlphabet,
  askDtoToBto,
} from '@/pages/Index/components/func/ask';
import { Select } from 'antd';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSForm,
  Divider,
  Form,
} from '@vs/vsf-kit';
import { useAsyncEffect, useMount, useUpdateEffect } from 'ahooks';
import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useMemo,
  useRef,
} from 'react';
import { ListItem } from '@/pages/Index/components';
import {
  useAvoidState,
  useFrequencyEffect,
  useGetResetInitState,
} from '@/pages/Index/components/func/hooks';
import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import { labelNumberWidth } from '@/utils';

const style = {
  display: 'flex',
  alignItems: 'center',
};

const LabDepartment = (props) => {
  const { form, formKey } = props;
  const [dataSource, setDataSource] = useState([]);
  const [LIMIT, SetLIMIT] = useState([]);
  const [PERFORM, SetPERFORM] = useState([]);
  const getDepartment = useCallback(async () => {
    const resolve =
      await vsf.services.DepartmentDtoController_getAllByDepartmentQto_1c2a91({
        qto: {
          branchInstitutionIdIs: props.item,
          stopIndicatorIs: false,
        },
      });
    return resolve.data.map((item) => ({
      performDepartmentId: item.id,
      departmentName: item.departmentName,
    }));
  }, []);

  /**
   * 替换选项中的已选项
   */
  const getDataAndSource = useCallback(async () => {
    const dataSource = await getDepartment();
    const dict = {
      LIMIT: {
        data: [],
        dataSource: [...dataSource],
      },
      PERFORM: {
        data: [],
        dataSource: [...dataSource],
      },
    };
    if (props.value?.labItemVsDepartmentBtoList?.length) {
      props.value?.labItemVsDepartmentBtoList?.forEach((item) => {
        const index = dataSource.findIndex((label) => {
          return label?.performDepartmentId, item.performDepartment?.id;
        });
        const list = dict[item.labDepartmentType].dataSource;
        if (index !== -1) {
          list[index] = {
            id: item.id,
            departmentName: item.performDepartment.departmentName,
            performDepartmentId: item.performDepartment?.id,
          };
          dict[item.labDepartmentType].data.push(list[index]);
        }
      });
    }
    return dict;
  }, [dataSource]);

  useAsyncEffect(async () => {
    const data = await getDataAndSource();
    form.setFieldValue([...formKey, 'labItemVsDepartmentBtoList'], {
      LIMIT: data.LIMIT.data,
      PERFORM: data.PERFORM.data,
    });
    SetLIMIT(data.LIMIT.dataSource);
    SetPERFORM(data.PERFORM.dataSource);
  }, [getDataAndSource]);

  return (
    <Fragment>
      <VSFormLayout columnCount={2} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['labItemVsDepartmentBtoList', 'LIMIT']}
          label="限定科室"
          valueType="select"
          dataSource={(value) => {
            const list = LIMIT.length ? LIMIT : dataSource;
            return value?.departmentName
              ? list?.filter((item) =>
                  askSearch(value?.departmentName, item, 'departmentName'),
                )
              : list;
          }}
          fieldProps={{
            mode: 'multiple',
            showSearch: true,
          }}
          fieldNames={{
            label: 'departmentName',
            value: 'performDepartmentId',
          }}
        />
        <VSFormItem
          name={['labItemVsDepartmentBtoList', 'PERFORM']}
          label="执行科室"
          valueType="select"
          dataSource={(value) => {
            const list = PERFORM.length ? PERFORM : dataSource;
            return value?.departmentName
              ? list?.filter((item) =>
                  askSearch(value?.departmentName, item, 'departmentName'),
                )
              : list;
          }}
          fieldProps={{
            mode: 'multiple',
            showSearch: true,
          }}
          fieldNames={{
            label: 'departmentName',
            value: 'performDepartmentId',
          }}
        />
        <VSFormItem
          name={['useScope']}
          label="使用范围"
          valueType="select"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={[
            { label: '全院', value: 'ALL' },
            { label: '住院', value: 'INP' },
            { label: '门诊', value: 'OUTP' },
          ]}
          rules={[
            {
              required: true,
            },
          ]}
        />

        <VSFormItem
          name={['emergencyIndicator']}
          label="能否加急"
          valueType="check"
          fieldProps={{}}
        />
      </VSFormLayout>
    </Fragment>
  );
};

/**
 * 标本
 * @param {*} props
 * @returns
 */
const Specimen = (props) => {
  const { getInitLabItemVsSpecimenBtoList } = props;

  const [dataSource, setDataSource] = useState([]);
  const name = ['labVsClinicItemDto', 'labItemVsSpecimenBtoList'];
  const labItemVsSpecimenBtoList = vsf.refs.LabItemForm?.getFieldValue(name);
  // const [labItemVsSpecimenBtoList, setLabItemVsSpecimenBtoList, , , getInitLabItemVsSpecimenBtoList] = useGetResetInitState([]);
  const time = useRef(0);
  const transform = labItemVsSpecimenBtoList?.map?.(
    (item) => item?.specimenId ?? item?.specimen?.id,
  );

  const getLabDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.SpecimenController_getAllBySpecimenWithPatternQto_47cbca?.(
        {
          qto: {},
        },
      );
    setDataSource(res?.data);
  }, []);

  useEffect(() => {
    getLabDataSource();
  }, []);

  /**
   * 过滤出除了自己的已选项
   */
  const otherSelected = transform?.filter(
    (item) =>
      !Object.is(item, props?.value?.specimenId ?? props?.value?.specimen?.id),
  );
  const selected = dataSource
    .filter((item) => {
      return !otherSelected?.includes(item.id);
    })
    ?.map((item) => ({
      ...item,
      label: item?.specimenName,
      value: item?.id,
    }));

  const { status } = Form?.Item?.useStatus();
  const form = useRef(null);
  useUpdateEffect(() => {
    if (status !== 'success') {
      form?.current?.validateFields();
    }
  }, [status]);

  const onChange = ListItem?.useListItemOnChange?.();
  useMount(() => {
    form?.current?.setFieldsValue({
      ...props?.value,
      specimenId: props?.value?.specimen?.id ?? props?.value?.specimenId,
    });
  });

  useEffect(() => {
    form?.current?.setFieldsValue({
      ...props?.value,
    });
  }, [props?.value?.defaultIndicator]);

  return (
    <VSForm
      ref={form}
      onChange={(value) => {
        const id = getInitLabItemVsSpecimenBtoList?.find(
          (item) => item?.specimen?.id === value?.specimenId,
        )?.id;
        props?.onChange?.({
          id,
          ...value,
        });
      }}
    >
      <VSFormItem name={['id']} valueType="digit" hidden />
      <VSFormLayout
        columnCount={2}
        columnSpan={[22, 2]}
        labelWidth={labelNumberWidth(8)}
        style={style}
      >
        <VSFormItem
          name={['specimenId']}
          valueType="custom"
          rules={[
            {
              required: true,
              message: '请选择标本',
            },
          ]}
          fieldProps={{}}
        >
          <Select
            options={selected}
            showSearch
            filterOption={askSearch}
            placeholder="请选择标本"
            style={{
              width: '100%',
            }}
          />
        </VSFormItem>
        <VSFormItem
          name={['defaultIndicator']}
          label="默认"
          valueType="check"
          initialValue={false}
          fieldProps={{
            onChange: (status) => {
              if (status) {
                clearTimeout(time?.current);
                time.current = setTimeout(() => {
                  onChange?.((value) =>
                    (value ?? [])?.map((item, index) => {
                      if (props?.index !== index) {
                        return {
                          ...item,
                          defaultIndicator: false,
                        };
                      } else {
                        return {
                          ...item,
                          defaultIndicator: true,
                        };
                      }
                    }),
                  );
                }, 200);
              }
            },
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
};
/**
 * 生理周期
 * @param {*} props
 * @returns
 */
const Physiological = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const name = ['labVsClinicItemDto', 'labItemVsPhysiologicalCycleBtoList'];
  const labItemVsPhysiologicalCycleBtoList =
    vsf?.refs?.LabItemForm?.getFieldValue(name);
  const getLabDataSource = async () => {
    const data = vsf.stores.dbenums.enums.LAB_PHYSIOLOGICAL_CYCLE_DICT;
    /**
     * 过滤出除了自己的已选项
     */
    const otherSelected = labItemVsPhysiologicalCycleBtoList
      ?.map(
        (item) =>
          item.value?.physiologicalCycleCodeId ?? item.physiologicalCycleCodeId,
      )
      ?.filter(
        (item) => !Object.is(item, props.value?.physiologicalCycleCodeId),
      );
    const selected = data?.filter?.((item) => {
      return !otherSelected?.includes(item.value);
    });
    setDataSource(selected);
  };
  useEffect(() => {
    getLabDataSource();
  }, [labItemVsPhysiologicalCycleBtoList]);

  const onChange = ListItem?.useListItemOnChange?.();
  const [form] = Form.useForm();
  const time = useRef(0);

  useEffect(() => {
    form?.current?.setFieldsValue({
      ...props?.value,
      physiologicalCycleCodeId:
        props?.value?.physiologicalCycleCode?.id ??
        props?.value?.physiologicalCycleCodeId,
    });
  }, [props?.value]);

  return (
    <VSForm
      ref={form}
      onValuesChange={(...[, value]) => props?.onChange?.(value)}
    >
      <VSFormLayout
        columnCount={2}
        columnSpan={[22, 2]}
        labelWidth={labelNumberWidth(8)}
        style={style}
      >
        <VSFormItem name={['physiologicalCycleCodeId']} valueType="custom">
          <Select
            showSearch
            options={dataSource}
            placeholder="请选择生理周期"
            filterOption={askSearch}
          />
        </VSFormItem>
        <VSFormItem
          name={['defaultIndicator']}
          label="默认"
          initialValue={false}
          valueType="check"
          fieldProps={{
            onChange: (status) => {
              if (status) {
                clearTimeout(time?.current);
                time.current = setTimeout(() => {
                  onChange?.((value) =>
                    (value ?? [])?.map((item, index) => {
                      if (props?.index !== index) {
                        return {
                          ...item,
                          defaultIndicator: false,
                        };
                      } else {
                        return {
                          ...item,
                          defaultIndicator: true,
                        };
                      }
                    }),
                  );
                }, 200);
              }
            },
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
};

/**
 * 采集部位
 * @param {*} props
 * @returns
 */
const Sample = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const labItemVsSampleLocationBtoList = vsf.refs.LabItemForm?.getFieldValue([
    'labVsClinicItemDto',
    'labItemVsSampleLocationBtoList',
  ]);
  const getLabDataSource = useCallback(async () => {
    const data = vsf.stores.dbenums.enums.LAB_COLLECTION_LOCATION_DICT;
    /**
     * 过滤出除了自己的已选项
     */
    const otherSelected = labItemVsSampleLocationBtoList
      ?.map((item) => item.value ?? item)
      ?.map((item) => item.sampleLocationCodeId)
      ?.filter((item) => !Object.is(item, props.value?.sampleLocationCodeId));
    const selected = data?.filter((item) => {
      return !otherSelected?.includes(item.value);
    });
    setDataSource(selected);
  }, []);

  useEffect(() => {
    getLabDataSource();
  }, [labItemVsSampleLocationBtoList]);

  const onChange = ListItem?.useListItemOnChange?.();
  const [form] = Form.useForm();
  const time = useRef(0);

  useEffect(() => {
    form?.current?.setFieldsValue({
      ...props?.value,
      physiologicalCycleCodeId:
        props?.value?.sampleLocationCode?.id ??
        props?.value?.sampleLocationCodeId,
    });
  }, [props?.value]);

  return (
    <VSForm
      ref={form}
      onValuesChange={(...[, value]) => {
        props?.onChange?.(value);
      }}
    >
      <VSFormLayout
        columnCount={2}
        columnSpan={[22, 2]}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem name={['sampleLocationCodeId']} valueType="custom">
          <Select
            showSearch
            options={dataSource}
            placeholder="请选择采集部位"
            filterOption={askSearch}
          />
        </VSFormItem>
        <VSFormItem
          name={['defaultIndicator']}
          label="默认"
          valueType="check"
          initialValue={false}
          fieldProps={{
            onChange: (status) => {
              if (status) {
                clearTimeout(time?.current);
                time.current = setTimeout(() => {
                  onChange?.((value) =>
                    (value ?? [])?.map((item, index) => {
                      if (props?.index !== index) {
                        return {
                          ...item,
                          defaultIndicator: false,
                        };
                      } else {
                        return {
                          ...item,
                          defaultIndicator: true,
                        };
                      }
                    }),
                  );
                }, 200);
              }
            },
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
};

const LabItemEdit = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const isValue = useMemo(() => props.value, []);
  const [getInitLabItemVsSpecimenBtoList, setInitLabItemVsSpecimenBtoList] =
    useAvoidState([]);
  const getValue = async () => {
    if (props.value?.id) {
      const resolve =
        await vsf.services.ClinicItemBaseController_getLabClinicItemDetailById_cae31e(
          {
            id: props.value.id,
          },
        );
      getDataSource(
        resolve.data?.labVsClinicItemDto?.labClass?.branchInstitutionIdList,
      );
      const { labVsClinicItemDto, ...rest } = resolve.data;
      try {
        vsf.refs.LabItemForm?.setFieldsValue({
          ...rest,
          labVsClinicItemDto: {
            ...askDtoToBto(labVsClinicItemDto),
            labClassId: labVsClinicItemDto.labClass.id,
            labItemExtensionBtoList: askSelectFormInitValue({
              array: labVsClinicItemDto?.labItemExtensionDtoList?.map(
                (item) => {
                  const labItemVsDepartmentBtoList =
                    labVsClinicItemDto.labItemVsDepartmentDtoList.filter(
                      (department) => {
                        return (
                          department.performDepartment.branchInstitution.id ===
                          item.branchInstitution.id
                        );
                      },
                    );
                  return {
                    ...item,
                    labItemVsDepartmentBtoList,
                  };
                },
              ),
              select: ['branchInstitution', 'id'],
            }),
          },
        });
        setInitLabItemVsSpecimenBtoList?.(
          labVsClinicItemDto?.labItemVsSpecimenDtoList,
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDataSource = useCallback(
    async (branchInstitutionIdList) => {
      const resolve =
        await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.(
          {},
        );
      const branchInstitutionList = resolve.data.branchInstitutionList
        .filter((item) => branchInstitutionIdList?.includes(item.id))
        .map((item) => ({
          label: item.institutionName,
          value: item.id,
        }));
      setDataSource(branchInstitutionList);
    },
    [props.data?.id],
  );

  /**
   * 从检验分类拿到使用院区
   */
  useEffect(() => {
    /**
     * 判断是新增还是修改
     */
    if (!isValue) {
      vsf?.services
        ?.LabController_getLabClassById_f20312({
          id: props.data?.id,
        })
        .then((res) => {
          getDataSource(res.data?.branchInstitutionIdList);
          vsf.refs.LabItemForm?.setFieldsValue({
            labVsClinicItemDto: {
              labClassId: props.data?.id,
            },
          });
        });
    } else {
      getValue();
    }
  }, []);

  const isLimit = useMemo(() => {
    try {
      const {
        labVsClinicItemDto: { limitAgeSymbol, limitAgeUnit, limitAgeNumber },
      } = props.value;
      return limitAgeSymbol || limitAgeUnit || limitAgeNumber;
    } catch {
      return false;
    }
  }, [props.value]);

  const itemCode = useMemo(() => {
    return (
      new Date().toLocaleDateString().replaceAll('/', '') +
      (Math.random() * (99 * 10000)).toFixed(0)
    );
  }, []);

  return (
    <VSControlledForm
      vsid="27929"
      id="LabItemForm"
      labelAlign="left"
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
    >
      <Section title="全院区通用">
        <Divider />
        <VSFormItem
          name={['labVsClinicItemDto', 'labClassId']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['labVsClinicItemDto', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout
          key="3"
          columnCount={3}
          gutter={50}
          labelWidth={labelNumberWidth(8)}
        >
          {/* <VSFormLayout key="3" columnCount={3} labelWidth={130} gutter={50}> */}
          <VSFormItem
            name={['itemCode']}
            label="检验项目代码"
            valueType="text"
            rules={[
              {
                message: `检验项目代码不能超过20个字`,
                max: 20,
                min: 0,
              },
              { required: true },
            ]}
            initialValue={itemCode}
            fieldProps={{
              disabled: isValue,
            }}
          />

          <VSFormItem
            name={['clinicItemName']}
            label="检验项目名称"
            valueType="text"
            rules={[
              {
                message: `检验项目名称不能超过1024个字`,
                max: 1024,
                min: 0,
              },
              { required: true },
            ]}
            fieldProps={{
              onChange: ({ target }) => {
                vsf.refs.LabItemForm.setFieldValue(
                  'inputCode',
                  askRomanAlphabet(target.value),
                );
              },
            }}
          />

          <VSFormItem
            name={['inputCode']}
            label="拼音输入码"
            valueType="text"
            fieldProps={{}}
            rules={[
              {
                message: `拼音输入码不能超过20个字`,
                max: 50,
                min: 0,
              },
              () => ({
                validator(_, value) {
                  if (String(value).includes(',')) {
                    return Promise.reject('多音字待确认');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          />
        </VSFormLayout>
        <VSFormLayout
          key="4"
          columnCount={4}
          gutter={50}
          labelWidth={labelNumberWidth(8)}
        >
          <VSFormItem
            name={['labVsClinicItemDto', 'limitGender']}
            label="限制性别"
            valueType="select"
            dataSource={[
              { label: '无限制', value: 'NO_LIMIT' },
              { label: '限男性', value: 'MALE_ONLY' },
              { label: '限女性', value: 'FEMALE_ONLY' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['labVsClinicItemDto', 'limitAgeSymbol']}
            label="限制年龄符号"
            valueType="select"
            dataSource={[
              { label: '大于', value: 'GT' },
              { label: '大于等于', value: 'GTE' },
              { label: '等于', value: 'EQUAL' },
              { label: '小于', value: 'LT' },
              { label: '小于等于', value: 'LTE' },
            ]}
            fieldProps={{}}
            rules={[
              {
                required: isLimit,
              },
            ]}
          />

          <VSFormItem
            name={['labVsClinicItemDto', 'limitAgeNumber']}
            label="限制年龄数值"
            valueType="digit"
            fieldProps={{
              width: '100%',
            }}
            rules={[
              {
                required: isLimit,
              },
            ]}
          />

          <VSFormItem
            name={['labVsClinicItemDto', 'limitAgeUnit']}
            label="限制年龄单位"
            valueType="select"
            rules={[
              {
                required: isLimit,
              },
            ]}
            dataSource={[
              {
                label: '年',
                value: 'YEAR',
              },
              {
                label: '月',
                value: 'MONTH',
              },
              {
                label: '日',
                value: 'DAY',
              },
              {
                label: '周',
                value: 'WEEK',
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout
          key="5"
          columnCount={1}
          gutter={50}
          labelWidth={labelNumberWidth(8)}
        >
          <VSFormItem
            name={['labVsClinicItemDto', 'labItemVsSpecimenBtoList']}
            valueType="listItem"
            label="标本"
            rules={[
              { required: true, message: '' },
              () => {
                return {
                  validator(...[, value]) {
                    return value
                      ?.map((item) => item?.specimenId ?? item?.specimen?.id)
                      ?.includes(undefined)
                      ? Promise.reject()
                      : Promise?.resolve();
                  },
                };
              },
            ]}
            fieldProps={{
              children: (
                <Specimen
                  getInitLabItemVsSpecimenBtoList={
                    getInitLabItemVsSpecimenBtoList
                  }
                />
              ),
              editStyle: {
                alignSelf: 'flex-start',
                marginTop: 2,
              },
            }}
            // transform={(value) =>{
            //   console.log(value, 'value');
            //   return {
            //     labVsClinicItemDto:{
            //       labItemVsSpecimenBtoList: value?.map((item) => item?.value ?? item)
            //     }
            //   }
            // }}
          />
          <VSFormItem
            name={['labVsClinicItemDto', 'labItemVsPhysiologicalCycleBtoList']}
            valueType="listItem"
            label="生理周期"
            fieldProps={{
              children: <Physiological />,
              editStyle: {
                alignSelf: 'flex-start',
                marginTop: 2,
              },
            }}
            // transform={(value) =>{
            //   return {
            //     labVsClinicItemDto: {
            //       labItemVsPhysiologicalCycleBtoList: value?.map((item) => item?.value ?? item)
            //     }
            //   }
            // }}
          />
          <VSFormItem
            name={['labVsClinicItemDto', 'labItemVsSampleLocationBtoList']}
            label="采集部位"
            valueType="listItem"
            fieldProps={{
              children: <Sample />,
              editStyle: {
                alignSelf: 'flex-start',
                marginTop: 2,
              },
            }}
            // transform={(value) =>{
            //   return {
            //     labVsClinicItemDto: {
            //       labItemVsSampleLocationBtoList: value?.map((item) => item?.value ?? item)
            //     }
            //   }
            // }}
          />
        </VSFormLayout>
      </Section>
      <Section title="各院区使用">
        <Divider />
        <VSFormLayout key="8" columnCount={1} labelWidth={labelNumberWidth(8)}>
          <VSFormItem
            name={['labVsClinicItemDto', 'labItemExtensionBtoList']}
            valueType="custom"
            fieldProps={{}}
          >
            <SelectForm
              label="院区"
              selectAllText="全部院区"
              dataSource={dataSource}
              selectLayoutProps={{
                columnCount: 1,
                // labelWidth: 130,
                labelWidth: labelNumberWidth(8),
                gutter: 50,
              }}
              rules={[{ required: true, message: '请选择院区' }]}
            >
              <LabDepartment />
            </SelectForm>
          </VSFormItem>
        </VSFormLayout>
      </Section>
    </VSControlledForm>
  );
};

export default definePage(LabItemEdit);
