import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderExtensionController_getLabelSettingMasterInCurrentWard_fa7f91:
    {
      method: 'post',
      url: '/api/clinic-record-order-extension/get-label-setting-master-in-current-ward',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderPerformLabelSettingMasterVoClinicRecordOrderEntranceWebVoOrderperformwithlabelmastervo[],
      ) => data,
    },
  OrderCheckController_checkOrders_a6b510: {
    method: 'post',
    url: '/api/clinic-record-order/check-orders',
    parameterFomatter: (data?: {
      orders: OrderCheckEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientOrderCheckResultEoClinicRecordOrderPersistEo[],
    ) => data,
  },
  ClinicRecordOrderController_getOrdersForCheck_6ff6b3: {
    method: 'post',
    url: '/api/clinic-record-order/get-orders-for-check',
    parameterFomatter: (data?: {
      qto: OrderForCheckQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  OrderCheckController_uncheckOrders_76b087: {
    method: 'post',
    url: '/api/clinic-record-order/uncheck-orders',
    parameterFomatter: (data?: { orderIds: number[] }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientOrderCheckResultEoClinicRecordOrderPersistEo[],
    ) => data,
  },
  OrderCheckController_backToDoctor_cc25e8: {
    method: 'post',
    url: '/api/clinic-record-order/back-to-doctor',
    parameterFomatter: (data?: {
      backToDoctorEos: OrderBackToDoctorEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientOrderCheckResultEoClinicRecordOrderPersistEo[],
    ) => data,
  },
  ClinicRecordOrderController_getOrdersForCheckPrint_2b0eb9: {
    method: 'post',
    url: '/api/clinic-record-order/get-orders-for-check-print',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
