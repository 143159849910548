import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_createClinicRegister_3586d1: {
    method: 'post',
    url: '/api/outp-register/create-clinic-register',
    parameterFomatter: (data?: {
      createClinicRegisterBto: CreateUpdateClinicRegisterBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpRegisterController_deleteClinicRegister_e3bc35: {
    method: 'post',
    url: '/api/outp-register/delete-clinic-register',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpRegisterController_getPagedByClinicRegisterWithDetailQto_ac5145: {
    method: 'post',
    url: '/api/outp-register/get-paged-by-clinic-register-with-detail-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterWithDefineDetailQtoOutpRegisterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterWithDetailSimpleVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
  OutpRegisterController_stopClinicRegister_cb74e2: {
    method: 'post',
    url: '/api/outp-register/stop-clinic-register',
    parameterFomatter: (data?: {
      updateStopIndicatorBto: UpdateClinicRegisterDetailStopIndicatorBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpRegisterController_updateClinicRegister_e24234: {
    method: 'post',
    url: '/api/outp-register/update-clinic-register',
    parameterFomatter: (data?: {
      updateClinicRegisterDetailBto: UpdateClinicRegisterDetailBtoOutpRegisterServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpRegisterController_getClinicRegisterStopInfo_809063: {
    method: 'post',
    url: '/api/outp-register/get-clinic-register-stop-info',
    parameterFomatter: (data?: { clinicRegisterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterWithDetailStopInfoVoOutpRegisterEntranceWebVo,
    ) => data,
  },
  OutpRegisterController_getClinicRegisterWithDefineDetailById_6f8402: {
    method: 'post',
    url: '/api/outp-register/get-clinic-register-with-define-detail-by-id',
    parameterFomatter: (data?: { clinicRegisterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterWithDefineDetailVoOutpRegisterEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterDefineNewQto_3be984: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-define-new-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterDefineNewQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterEntranceWebVoClinicregisterwithdetailsimplevo[],
    ) => data,
  },
});
