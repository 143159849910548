import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable, Todo, withRef, VSTableToolbar, VSTableAddition } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from '@/module/Icon';
import { cloneDeep } from 'lodash';

const ProductDepartmentUsageEditor = React.forwardRef((props, ref) => {

  const { drugInfo } = props

  const dataSourceRef = useRef([])
  const [dataSourceState, setDataSourceState] = useState([])
  const [departmentDataSource, setDepartmentDataSource] = useState()
  const [currentEditDepartment, setCurrentEditDepartment] = useState()

  useEffect(() => {
    getDepartmentDataSource()
  }, [])

  const getInstitution = useCallback(async (params) => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );

    return res.data
  }, []);


  const getDepartmentDataSource = useCallback(async (branchInstitutionIdIn) => {
    const institution = await getInstitution();
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
        {
          qto: {
            stopIndicatorIs: false,
            institutionIdIs: institution?.id,
            branchInstitutionIdIn,
          },
        },
      );

    setDepartmentDataSource(res.data.map((item) => {
      return ({
        label: item?.departmentName,
        value: item?.id
      })
    }))
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return Promise.resolve(dataSourceRef.current)
      }
    }
  })


  const onFetch = useCallback(async (params) => {
    if (drugInfo?.id) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugController_getDrugProductDepartmentUsageByDrugProductId_cdc3d7?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugProductId: drugInfo?.id
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      const _data = res?.data?.result ?? res?.data ?? []
      setDataSourceState(_data)

      return {
        data: _data?.map(item => ({
          ...item,
          attribute: [
            item?.outpIndicator && item?.outpIndicator ? 'outpIndicator' : undefined,
            item?.erpIndicator && item?.erpIndicator ? 'erpIndicator' : undefined,
            item?.inpIndicator && item?.inpIndicator ? 'inpIndicator' : undefined,
          ]?.filter(item => item),
        })),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      }
    }
  }, [drugInfo])

  const handelRemove = async (record) => {
    try {
      const response = await vsf?.services?.DrugController_deleteDrugProductDepartmentUsage_e9a4a5?.({
        id: record?.drugProduct?.specification?.drugId
      })
      return response?.code === 200
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const getAttribute = (_, record) => ([
    {
      label: '门诊',
      value: 'outpIndicator',
      disabled: dataSourceState?.filter(item => item?.department?.id === record?.department?.id)?.find(item => item?.outpIndicator)
    },
    {
      label: '急诊',
      value: 'erpIndicator',
      disabled: dataSourceState?.filter(item => item?.department?.id === record?.department?.id)?.find(item => item?.erpIndicator)
    },
    {
      label: '住院',
      value: 'inpIndicator',
      disabled: dataSourceState?.filter(item => item?.department?.id === record?.department?.id)?.find(item => item?.inpIndicator)
    }
  ])

  return (
    <Section>
      {/* <Todo
        type="block"
        onService={async (params) => {
          //
          // Tips: 以下是你选择的请求函数
          const res =
            await vsf.services?.DrugController_deleteDrugProductDepartmentUsage_e9a4a5?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                id: params?.id,
              },
            ); // TODO: 你可能需要整理返回数据格式
          return res?.data;
        }}
        content="根据id删除记录"
      />

      <Todo
        type="block"
        onService={async (params) => {
          //
          // Tips: 以下是你选择的请求函数
          const res =
            await vsf.services?.DrugController_createOrUpdateDrugProductDepartmentUsage_e60de7?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                bto: params?.bto,
              },
            ); // TODO: 你可能需要整理返回数据格式
          return res?.data;
        }}
        content="批量创建或则更新记录"
      /> */}

      <VSPromiseTable
        id="ProductDepartmentUsageEditorTable"
        className='ProductDepartmentUsageEditorTable'
        // scroll={{ y: 600}}
        editable={{
          editType: 'single',
          onFieldChange: (key, value, record, form) => {
            // if (value?.department) {
            //   setCurrentEditDepartment(value?.department?.departmentName)
            // }
            if(value?.department?.departmentName) {
              form.setFieldValue(key, {
                department: {
                  ...value?.department,
                  departmentName: departmentDataSource?.find(item => item?.value === value?.department?.departmentName)?.label,
                  id: value?.department?.departmentName
                }
              })
            }else {
              form.setFieldValue(key, {
                ...record,
                drugProduct: {
                  specification: {
                    unit: drugInfo?.drugSpecificationDictionary?.dosageUnit
                  }
                },
                attribute: value?.attribute ? value?.attribute : record?.attribute,
              })
            }
          },
          onValuesChange: (_, _dataSource) => {
            dataSourceRef.current = _dataSource?.map(item => {
              let _attribute = {}
              cloneDeep(item?.attribute)?.forEach(v => {
                _attribute = {
                  ..._attribute,
                  [v]: true
                }
              })
              return {
                ...item,
                ..._attribute
              }
            })
          },
          saveButtonProps: {
            onClick: () => {
              setDataSourceState(dataSourceRef.current)
            }
          },
          editText: <Icon
            type="icon-bianji"
          />,
          deleteText: <Icon
            type="icon-shanchu1"
          />,
          columnProps: {
            width: 108
          }
        }}
        vsid="92222"
        onRecord={() => {
          return {
            id: `create_${Math.random()}`
          }
        }}
        onFetch={onFetch}
        onRemove={handelRemove}
      >
        <VSTableToolbar>
          <VSTableAddition children='添加' />
        </VSTableToolbar>
        <VSTableColumn
          dataIndex={['department', 'id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['drugProduct', 'specification', 'drugId']}
          title="药品 id"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          width={72}
          title='序号'
          dataIndex={'idx'}
          render={(_, __, index) => index + 1}
          preview
        />


        <VSTableColumn
          width={108}
          dataIndex={['department', 'departmentName']}
          title="科室"
          valueType="select"
          fieldProps={{
            showSearch: true
          }}
          dataSource={departmentDataSource}
          formItemProps={{
          }}
        />
        <VSTableColumn
          dataIndex={['department', 'id']}
          title=""
          valueType="custom"
          fieldProps={{
            style: {
              visibility: 'hidden'
            }
          }}
        />

        {/* <VSTableColumn
          width={1}
          dataIndex={['department', 'id']}
          title=""
          valueType="text"
          fieldProps={{}}
          preview
          render={() => null}
        /> */}

        <VSTableColumn
          width={108}
          dataIndex={['usageDescription']}
          title="用法描述"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 500 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.DRUG_USING_DESCRIPTION_DICT,
            showSearch: true
          }}
        />

        <VSTableColumn
          width={108}
          dataIndex={['administration']}
          title="给药途径"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT,
            showSearch: true
          }}
        />

        <VSTableColumn
          width={108}
          dataIndex={['dosage']}
          title="单次用量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          width={108}
          dataIndex={['drugProduct', 'specification', 'unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            initialValue: drugInfo?.drugSpecificationDictionary?.dosageUnit,
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        {/* <VSTableColumn
          dataIndex={['administration']}
          title="给药途径"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
        /> */}

        <VSTableColumn
          width={72}
          dataIndex={['frequency']}
          title="频次"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 16 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT,
            showSearch: true
          }}
        />

        <VSTableColumn
          width={272}
          dataIndex={['attribute']}
          title="属性"
          valueType="checkbox"
          formItemProps={{}}
          dataSource={getAttribute}
          fieldProps={{}}
        />

        <VSTableColumn
          width={272}
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['outpIndicator']}
          title="门诊"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['erpIndicator']}
          title="急诊"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inpIndicator']}
          title="住院"
          valueType="digit"
          fieldProps={{}}
        /> */}

      </VSPromiseTable>
    </Section>
  );
})

ProductDepartmentUsageEditor.displayName = "ProductDepartmentUsageEditor"
export default withRef()(ProductDepartmentUsageEditor)
