import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpDrugAuditController_auditForceSuccess_3f847d: {
    method: 'post',
    url: '/api/drug-dispense-audit/audit-force-success',
    parameterFomatter: (data?: { orderIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpDrugAuditController_getWaitAuditWardPatientCount_82cd2a: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-wait-audit-ward-patient-count',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitDispenseAuditPatientCountGroupWardEoDrugDispenseAuditPersistEo[],
    ) => data,
  },
  InpDrugAuditController_auditToSuccess_d620a4: {
    method: 'post',
    url: '/api/drug-dispense-audit/audit-to-success',
    parameterFomatter: (data?: { orderIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpDrugAuditController_getWaitAuditPatientByWard_e5c3c3: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-wait-audit-patient-by-ward',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitAuditPatientVoDrugDispenseAuditEntranceWebVo[],
    ) => data,
  },
  InpDrugAuditController_cancelAudit_099e55: {
    method: 'post',
    url: '/api/drug-dispense-audit/cancel-audit',
    parameterFomatter: (data?: { orderIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpDrugAuditController_getOrderToAuditByWard_ed7357: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-order-to-audit-by-ward',
    parameterFomatter: (data?: {
      query: QueryWardPatientWaitAuditEoDrugDispenseAuditPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformForDispenseAuditGroupPatientVoDrugDispenseAuditEntranceWebVo[],
    ) => data,
  },
  InpDrugAuditController_refundOrders_b565a6: {
    method: 'post',
    url: '/api/drug-dispense-audit/refund-orders',
    parameterFomatter: (data?: {
      bvo: CreateDrugDispenseAuditMasterAndDetailBvoDrugDispenseAuditEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
