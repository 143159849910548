import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getAllByInstitutionQto_926a63: {
    method: 'post',
    url: '/api/organization/get-all-by-institution-qto',
    parameterFomatter: (data?: { qto: InstitutionQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
