import '../index.less';

import { Button, Carousel, Image } from '@vs/vsf-kit';
import React from 'react';

import CarouselImage from '@/assets/Slice 10@2x.png';
import Icon from '@/module/cpoe/Icon';
import Schedule from '@/module/cpoe/Schedule';
import Block from '@/pages/Index/components/block_no_padding';

const mock1 = [
  {
    label: '推进合理用药一一浙大N院药剂科在行动',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '合作共建启新程凝心聚力促发展——“一带一...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '浙大N院210人医疗队驰援衢州抗疫纪实',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '浙大义乌校地合作进入新征程 虎年共商高...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '推进合理用药一一浙大N院药剂科在行动',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '“三院一体“厉奋发在路上!2022，我们的新月...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '春节前夕，浙江大学党委副书记朱世强一行...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '浙大N院获批一项浙江省“尖兵”“领雁”研发攻...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '七年厉奋发，圆梦三甲向未来!浙大N院开启...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
  {
    label: '欢迎回家!浙大N院驰援永康医疗队圆满完...',
    desc: '根据卫生医疗组织的要求，决定自9月...',
  },
];
const mock2 = [
  {
    label: '泌尿专家汇报',
    time: '2023-08-17  08:00',
    createTime: '创建时间:2023-08-16  14:56',
  },
  {
    label: '泌尿专家门诊',
    time: '2023-08-26  08:00',
    createTime: '创建时间:2023-08-24  15:36',
  },
];

const Index = () => {
  const onChange = (currentSlide: number) => {};

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '582px',
    color: '#fff',
    lineHeight: '582px',
    textAlign: 'center',
    background: '#364d79',
  };

  return (
    <div className="center-container">
      <div className="first">
        {/* <div className="login-ab-bg"></div> */}
        <div className="left">
          <Carousel afterChange={onChange} autoplay>
            <div>
              <h3 style={contentStyle}>
                <Image src={CarouselImage} preview={false} />
              </h3>
            </div>
            <div>
              <h3 style={contentStyle}>
                <Image src={CarouselImage} preview={false} />
              </h3>
            </div>
            <div>
              <h3 style={contentStyle}>
                <Image src={CarouselImage} preview={false} />
              </h3>
            </div>
            <div>
              <h3 style={contentStyle}>
                <Image src={CarouselImage} preview={false} />
              </h3>
            </div>
          </Carousel>
        </div>
        <div className="right">
          <Block
            title="院内动态"
            extra={
              <div className="extra-oper">
                <span>查看全部</span>
                <Icon type="icon-you" />
              </div>
            }
            scroll={{
              y: true,
            }}
          >
            {mock1?.map((item: any, index) => (
              <div key={index} className="right-item">
                <div className="info">
                  <Icon
                    type="icon-Frame-15"
                    size={26}
                    style={{
                      color: index === 0 ? '#FF7E7E' : '#3276E8',
                    }}
                  />
                  <span
                    className="index dinpro"
                    style={{
                      color: index === 0 ? '#FF7E7E' : 'black',
                    }}
                  >
                    {index + 1}
                  </span>
                  <div className="main">
                    <div
                      className="first"
                      style={{ color: index === 0 ? '#FF7E7E' : '#343B42' }}
                    >
                      {item?.label}
                    </div>
                    <div className="second">{item?.desc}</div>
                  </div>
                </div>
                <div className="oper">
                  <div className="first">
                    立即查看
                    <Icon type="icon-you" size={20} />
                  </div>
                  <div className="second">20分钟前</div>
                </div>
              </div>
            ))}
          </Block>
        </div>
      </div>
      <div className="second">
        {/* <div className="login-ab-bg"></div> */}
        <div className="left">
          <Block
            title="我的日程"
            extra={
              <div>
                <Button
                  size="small"
                  type="primary"
                  icon={<Icon type="icon-tianjiagongneng" />}
                >
                  新增
                </Button>
              </div>
            }
          >
            <Schedule />
          </Block>
        </div>
        <div className="right">
          <Block
            title="我的便签"
            extra={
              <div>
                <Button
                  size="small"
                  type="primary"
                  icon={<Icon type="icon-tianjiagongneng" />}
                >
                  新增
                </Button>
              </div>
            }
            scroll={{
              y: true,
            }}
          >
            {mock2?.map((item: any, index) => {
              return (
                <div key={index} className="right-item">
                  <div className="left">
                    <div className="label">{item?.label}</div>
                    <div className="time">{item?.time}</div>
                  </div>
                  <div className="right">
                    <div className="oper">
                      <Icon type="icon-bianji" size={20} />
                      <Icon type="icon-shanchu1" size={20} />
                    </div>
                    <div className="word">{item?.createTime}</div>
                  </div>
                </div>
              );
            })}
          </Block>
        </div>
      </div>
    </div>
  );
};

export default Index;
