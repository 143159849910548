import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_getTreatClinicItemDetailById_9d3b21: {
    method: 'post',
    url: '/api/clinic-item-base/get-treat-clinic-item-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
});
