import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message } from '@vs/vsf-kit';
import React, { Fragment, useEffect, useState } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { useMount } from 'ahooks';
import _ from 'lodash';
function Tree(props) {
  return (
    <TreeMenu
      title="出入库类型"
      loadData={async () => {
        const res =
          await vsf.services.DrugImportExportController_getAllExportImportTypeDictionary_c468af();

        const importData = res?.data
          ?.filter((item) => {
            return item?.json?.importIndicator;
          })
          .map((item) => {
            return { ...item, templateApplicationId: 1 };
          });

        const outData = res?.data
          ?.filter((item) => {
            return !item?.json?.importIndicator;
          })
          .map((item) => {
            return { ...item, templateApplicationId: 2 };
          });
        res.data = importData.concat(outData).concat([
          { id: 1, name: '入库' },
          { id: 2, name: '出库' },
        ]);
        return res;
      }}
      dataRelationFieldNames={{
        id: 'id',
        parentId: 'templateApplicationId',
      }}
      fieldNames={{ title: 'name', key: 'id', children: 'children' }}
      row={{
        expand: true,
        extraShow: 'hover',
      }}
      onSelect={(_value) => {

        props.onChange?.(_value);
      }}
    />
  );
}

export default Tree;
