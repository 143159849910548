import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable, Todo, VSTableToolbar, VSTableAddition, withRef, } from '@vs/vsf-kit';
import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import Icon from '@/module/Icon';
import { cloneDeep } from 'lodash';

const ProductDefaultUsageEditor = React.forwardRef((props, ref) => {

  const { drugInfo } = props

  const dataSourceRef = useRef()
  const [dataSourceState, setDataSourceState] = useState([])

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return Promise.resolve(dataSourceRef.current)
      }
    }
  })

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    if (drugInfo?.id) {
      const res =
        await vsf.services?.DrugController_getProductUsageListByDrugProductId_82d6a2?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugProductId: drugInfo?.id,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }

      const _data = res?.data?.result ?? res?.data ?? []
      setDataSourceState(_data)

      return {
        data: _data?.map(item => ({
          ...item,
          attribute: [
            item?.outpIndicator && item?.outpIndicator ? 'outpIndicator' : undefined,
            item?.erpIndicator && item?.erpIndicator ? 'erpIndicator' : undefined,
            item?.inpIndicator && item?.inpIndicator ? 'inpIndicator' : undefined,
          ]?.filter(item => item)
        })),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, [drugInfo])

  const handelRemove = async (record) => {
    try {
      if(typeof record?.id === 'string' && record?.id?.includes('create_')) {
        return true
      }
      const response = await vsf?.services?.DrugController_deleteDrugProductUsage_2c8f6a?.({
        bto: {
          id: drugInfo?.drug?.id,
          drugProductBtoList: [{
            id: drugInfo?.id,
            drugProductUsageBtoList: [{
              id: record?.id
            }]
          }]
        }
      })
      return response?.code === 200
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return (
    <Section>
      {/* <Todo
        type="block"
        content="编辑后的保存按钮，新增的和编辑的一把保存"
        onService={async (params) => {
          const res =
            await vsf?.services?.DrugController_updateOrCreateDrugUsageByProductId_48b45e?.(
              { bto: params?.bto },
            );
          return res?.data; // todo 整理返回数据格式
        }}
      />

      <Todo
        type="block"
        content="删除请调用接口"
        onService={async (params) => {
          const res =
            await vsf?.services?.DrugController_deleteDrugProductUsage_2c8f6a?.(
              { bto: params?.bto },
            );
          return res?.data; // todo 整理返回数据格式
        }}
      /> */}

      <VSPromiseTable
        id="ProductDefaultUsageEditorTable"
        className='ProductDefaultUsageEditorTable'
        scroll={{ y: 600}}
        editable={{
          editType: 'single',
          onValuesChange: (_, _dataSource) => {
            dataSourceRef.current = _dataSource?.map(item => {
              let _attribute = {}
              cloneDeep(item?.attribute)?.forEach(v => {
                _attribute = {
                  ..._attribute,
                  [v]: true
                }
              })
              return {
                ...item,
                ..._attribute
              }
            })
          },
          saveButtonProps: {
            onClick: () => {
              setDataSourceState(dataSourceRef.current)
            }
          },
          editText: <Icon
            type="icon-bianji"
          />,
          deleteText: <Icon
            type="icon-shanchu1"
          />,
          columnProps: {
            width: 108
          }
        }}
        vsid="30495"
        onRecord={() => {
          return {
            id: `create_${Math.random()}`
          }
        }}
        onFetch={onFetch}
        onRemove={handelRemove}
      >
        <VSTableToolbar>
          <VSTableAddition children='添加' />
        </VSTableToolbar>
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['drugProduct', 'id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['drugProduct', 'drugId']}
          title="药品ID"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          width={72}
          title='序号'
          dataIndex={'idx'}
          render={(_, __, index) => index + 1}
          preview
        />

        <VSTableColumn
          width={108}
          dataIndex={['usageDescription']}
          title="用法描述"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 500 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.DRUG_USING_DESCRIPTION_DICT,
            showSearch: true
          }}
        />

        <VSTableColumn
          width={108}
          dataIndex={['administration']}
          title="给药途径"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT,
            showSearch: true
          }}
        />

        <VSTableColumn
          width={108}
          dataIndex={['dosage']}
          title="单次剂量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          width={108}
          dataIndex={['drugProduct', 'specification', 'dosageUnit']}
          title="单位"
          valueType="text"
          formItemProps={{
            initialValue: drugInfo?.drugSpecificationDictionary?.dosageUnit,
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width={72}
          dataIndex={['frequency']}
          title="频次"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 16 },
            ],
          }}
          fieldProps={{
            dataSource: vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT,
            showSearch: true
          }}
        />


        <VSTableColumn
          width={272}
          dataIndex={['attribute']}
          title="属性"
          valueType="checkbox"
          formItemProps={{}}
          dataSource={[
            {
              label: '门诊',
              value: 'outpIndicator',
              disabled: dataSourceState?.find(item => item?.outpIndicator)
            },
            {
              label: '急诊',
              value: 'erpIndicator',
              disabled: dataSourceState?.find(item => item?.erpIndicator)
            },
            {
              label: '住院',
              value: 'inpIndicator',
              disabled: dataSourceState?.find(item => item?.inpIndicator)
            }
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          width={272}
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['outpIndicator']}
          title="门诊使用标识"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '门诊使用标识的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['erpIndicator']}
          title="急诊使用标识"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '急诊使用标识的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inpIndicator']}
          title="住院使用标识"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '住院使用标识的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ],
          }}
          fieldProps={{}}
        /> */}

      </VSPromiseTable>
    </Section>
  );
})
ProductDefaultUsageEditor.displayName = 'ProductDefaultUsageEditor'
export default withRef()(ProductDefaultUsageEditor)