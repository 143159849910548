import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getDrugExportDetailListForFloat_2691cc: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-drug-export-detail-list-for-float',
    parameterFomatter: (data?: { drugExportMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportDetailForFloatVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
});
