import TransferExpand from '@/pages/Index/components/transfer_expand/index.jsx';
import { getScrollY, getScrollYRem, getScrollRemImport } from '@/utils';
import { Button, message } from '@vs/vsf-kit';
import { useLockFn, useRequest } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import {
  Department,
  RenderLeft,
  RenderRight,
} from '../../components/department';

const PerformDepartment = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const { data: branchInstitutionList, runAsync: getInstitution } = useRequest(
    async (params) => {
      const res =
        await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
          {},
        );

      if (res.code === 200) {
        return res?.data?.branchInstitutionList ?? [];
      }
    },
  );

  const getDataSource = useCallback(async () => {
    const institution = await getInstitution();
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
        {
          qto: {
            stopIndicatorIs: false,
            institutionIdIs: institution?.id,
          },
        },
      );
    setDataSource(
      res?.data?.map?.((item) => ({
        ...item,
        departmentName: `${item.departmentName}(${item?.branchInstitution?.institutionName})`,
      })),
    );
  }, []);
  const getData = useCallback(async (id) => {
    const res =
      await vsf?.services?.LabController_getLabWithDepartmentById_4053dd?.({
        id,
      });
    if (res.data?.labVsDepartmentDtoList) {
      res.data.labVsDepartmentDtoList = res?.data?.labVsDepartmentDtoList?.map(
        (item) => ({
          ...item,
          id: item.department.id,
          _id: item.id,
          departmentName: item.department.departmentName,
        }),
      );
      setData(res.data);
    }
  }, []);

  useEffect(() => {
    if (props.value) {
      getDataSource();
      getData(props.value.id);
    }
  }, [props.value]);
  const TransferProps = {
    showSelectAll: false,
    dataSource,
    style: {
      // height: getScrollY(300),
      // height: getScrollYRem(16),
      height: 'calc(-16rem + 100vh) !important',
    },
    listStyle: ({ direction }) => {
      const dict = {
        left: {
          flex: 0,
          flexBasis: '25%',
          borderTop: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        right: {
          flex: 0,
          flexBasis: '75%',
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
        // left: {
        //   flex: 0,
        //   flexBasis: '392px',
        //   borderTop: 'none',
        //   borderLeft: 'none',
        //   borderBottom: 'none',
        // },
        // right: {
        //   borderTop: 'none',
        //   borderRight: 'none',
        //   borderBottom: 'none',
        // },
      };
      return dict[direction];
    },
  };
  const onSubmit = useLockFn(async (params) => {
    try {
      await vsf?.refs?.PerformDepartment?.getEditForm()?.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.LabController_addDepartmentToLabClass_8b9e30?.({
          btoParam: {
            id: props.value.id,
            labVsDepartmentBtoList: params?.map((item, index) => {
              const dict = {
                departmentId: item.id,
                priority: index + 1,
              };
              if (item._id) dict.id = item._id;
              return dict;
            }),
          },
          extApiParam: params?.extApiParam,
        });
      if (res.code === 200) {
        message.success('保存执行科室成功');
        getData(props.value.id);
      }
    } catch (error) {
      return error;
    }
  });
  return (
    <TransferExpand
      {...TransferProps}
      left={<RenderLeft />}
      right={<RenderRight />}
      data={data?.labVsDepartmentDtoList ?? []}
      search={{
        left: {
          render: <Department getDataSource={branchInstitutionList ?? []} />,
        },
      }}
      labelKey="id"
      footer={(data) => (
        <Button
          onClick={() => onSubmit(data)}
          disabled={!props?.value?.id}
          type="primary"
        >
          保存
        </Button>
      )}
    />
  );
};

export default PerformDepartment;
