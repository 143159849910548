// 列表查询
export const convertGet = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output?.push(
    ..._.get(input, 'data.result')?.map((d) => {
      const obj = {};
      obj.bedLabel = _.get(d, 'bedLabel');
      obj.bedStatus = _.get(d, 'bedStatus');
      obj.wardName = _.get(d, 'wardName');
      obj.preTransferIndicator = _.get(
        d,
        'clinicPatientBedDto.preTransferIndicator',
      );
      obj.skinTestPositiveIndicator = _.get(
        d,
        'clinicPatientBedDto.skinTestPositiveIndicator',
      );
      obj.skinTestDrugName = _.get(d, 'clinicPatientBedDto.skinTestDrugName');
      obj.patientAllergyIndicator = _.get(
        d,
        'clinicPatientBedDto.patientAllergyIndicator',
      );
      // obj.patientAllergy = _.get(d, 'clinicPatientBedDto.patientAllergy')?.map(
      //   (pa) => {
      //     if (pa.allergyDrugProductName) {
      //       return pa.allergyDrugProductName;
      //     } else if (pa.allergyDrugName) {
      //       return pa.allergyDrugName;
      //     } else {
      //       return pa.allergyDrugClassName;
      //     }
      //   },
      // );
      obj.patientAllergy = (
        _.get(d, 'clinicPatientBedDto.patientAllergy') ?? []
      ).map(
        (pa) =>
          pa.allergyName ||
          pa.allergyDrugProductName ||
          pa.allergyDrugName ||
          pa.allergyDrugClassName,
      );
      obj.crisisIndicator = _.get(d, 'clinicPatientBedDto.crisisIndicator');
      obj.seriousIndicator = _.get(d, 'clinicPatientBedDto.seriousIndicator');
      obj.waitExamReportIndicator = _.get(
        d,
        'clinicPatientBedDto.waitExamReportIndicator',
      );
      obj.pathwayImportIndicator = _.get(
        d,
        'clinicPatientBedDto.pathwayImportIndicator',
      );
      obj.drugResistanceType = _.get(
        d,
        'clinicPatientBedDto.drugResistanceType',
      );
      obj.drugResistanceName = _.get(
        d,
        'clinicPatientBedDto.drugResistanceName',
      );
      obj.expectedDischargeDate = _.get(
        d,
        'clinicPatientBedDto.expectedDischargeDate',
      );
      obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
      obj.displayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
      obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
      obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
      obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
      obj.attendingDoctorName = _.get(
        d,
        'clinicPatientBedDto.attendingDoctorName',
      );
      obj.inChargeDoctorName = _.get(
        d,
        'clinicPatientBedDto.inChargeDoctorName',
      );
      obj.chargeType = _.get(d, 'clinicPatientBedDto.chargeType');
      obj.admissionWardDateTime = _.get(
        d,
        'clinicPatientBedDto.admissionWardDateTime',
      );
      obj.admissionDateTime = _.get(d, 'clinicPatientBedDto.admissionDateTime'); // 入院时间
      obj.admissionDays = _.get(d, 'clinicPatientBedDto.admissionDays'); // 入院天数
      obj.needPayAmount = _.get(d, 'clinicPatientBedDto.needPayAmount'); // 欠费金额
      obj.totalCost = _.get(d, 'clinicPatientBedDto.totalCost'); // 已产生费用
      obj.dischargeVisitType = _.get(
        d,
        'clinicPatientBedDto.dischargeVisitType',
      ); // 出院类别
      obj.medicalGroupName = _.get(d, 'clinicPatientBedDto.medicalGroupName'); //诊疗组名称
      obj.operationEndDateTime = _.get(
        d,
        'clinicPatientBedDto.operationEndDateTime',
      );
      obj.vteIndicator = _.get(d, 'clinicPatientBedDto.vteIndicator');
      obj.vteScore = _.get(d, 'clinicPatientBedDto.vteScore');
      obj.catheterIndicator = _.get(d, 'clinicPatientBedDto.catheterIndicator');
      obj.catheterScore = _.get(d, 'clinicPatientBedDto.catheterScore');
      obj.pressureIndicator = _.get(d, 'clinicPatientBedDto.pressureIndicator');
      obj.pressureScore = _.get(d, 'clinicPatientBedDto.pressureScore');
      obj.painIndicator = _.get(d, 'clinicPatientBedDto.painIndicator');
      obj.painScore = _.get(d, 'clinicPatientBedDto.painScore');
      obj.fallIndicator = _.get(d, 'clinicPatientBedDto.fallIndicator');
      obj.fallScore = _.get(d, 'clinicPatientBedDto.fallScore');
      obj.id = _.get(d, 'id');
      obj.attendingDoctorId = _.get(d, 'clinicPatientBedDto.attendingDoctorId');
      obj.inChargeDoctorId = _.get(d, 'clinicPatientBedDto.inChargeDoctorId');
      obj.inChargeNurseId = _.get(d, 'clinicPatientBedDto.inChargeNurseId');
      obj.inChargeNurseName = _.get(d, 'clinicPatientBedDto.inChargeNurseName');
      obj.admissionWardDays = _.get(d, 'clinicPatientBedDto.admissionWardDays');
      obj.beforeDischargeDays = _.get(
        d,
        'clinicPatientBedDto.beforeDischargeDays',
      );
      obj.nursingClass = _.get(d, 'clinicPatientBedDto.nursingClass');
      obj.afterOperationDays = _.get(
        d,
        'clinicPatientBedDto.afterOperationDays',
      );
      obj.diagnosisDescription = _.get(
        d,
        'clinicPatientBedDto.diagnosisDescription',
      );
      obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');

      return obj;
    }),
  );
  return output;
};
// 入参转换
export const convertSearchPrarms = (input) => {
  const _ = window._ ?? {};
  const searchParams = {};
  searchParams.patientDepartmentIdIs = _.get(input, 'departmentIdIs');
  searchParams.wardIdIs = _.get(input, 'wardIdIs');
  searchParams.expectedDischargeDateRangeIn = _.get(
    input,
    'expectedDischargeDateRangeIn',
  );
  searchParams.appointOperationDateTimeRangeIn = _.get(
    input,
    'appointOperationDateTimeRangeIn',
  );
  searchParams.crisisIndicatorIs = _.get(input, 'crisisIndicatorIs');
  searchParams.seriousIndicatorIs = _.get(input, 'seriousIndicatorIs');
  searchParams.patientTypeIn = _.get(input, 'patientTypeIn');
  searchParams.patientAuthorityTypeIn = _.get(input, 'patientAuthorityTypeIn');
  searchParams.attendingDoctorIdIs = _.get(input, 'attendingDoctorIdIs');
  searchParams.medicalGroupIdIs = _.get(input, 'medicalGroupIdIs');
  searchParams.patientIdIs = _.get(input, 'patientIdIs');
  searchParams.patientNameLike = _.get(input, 'patientNameLike');
  searchParams.bedLabelIs = _.get(input, 'bedLabelIs');
  searchParams.patientDisplayIdIs = _.get(input, 'patientDisplayIdIs');
  searchParams.inChargeDoctorIdIs = _.get(input, 'inChargeDoctorIdIs');
  // searchParams.ext.statisticsCodes = _.get(input, 'statisticsCodes');
  return searchParams;
};
// 床卡icon
export const patientDetailConditionIcon = [
  // #todo
  {
    icon: 'icon-a-Group1311',
    label: '日间患者',
    content: '日间患者#todo',
    isShow: 'waitExamReportIndicator',
    type: 'string',
  },
  // {
  //   label: '皮试阳性',
  //   icon: 'icon-yangxing',
  //   content: '新开医嘱',
  //   isShow: 'newDoctorOrderIndicator',
  //   type: 'string',
  // },
  // 过敏
  {
    label: '过敏史',
    icon: 'icon-guomin',
    content: 'patientAllergy',
    isShow: 'patientAllergyIndicator',
    type: 'arr',
  },
  // 危
  {
    label: '病危',
    icon: 'icon-bingwei1',
    content: '病危',
    isShow: 'crisisIndicator',
    type: 'string',
  },
  // 重
  {
    label: '病重',
    icon: 'icon-bingzhong',
    content: '病重',
    isShow: 'seriousIndicator',
    type: 'string',
  },
  // 隔
  // {
  //   label: '隔离',
  //   icon: 'icon-geli',
  //   content: 'isolationType',
  //   isShow: 'isolationIndicator',
  //   type: 'arr',
  // },
  {
    label: '有检查报告未出',
    icon: 'icon-weidayin',
    content: '有检查报告未出',
    isShow: 'waitExamReportIndicator',
    type: 'string',
  },
  {
    label: '临床路径',
    icon: 'icon-hulilujing',
    content: '临床路径执行中#todo',
    isShow: 'pathwayImportIndicator',
    type: 'string',
  },
  // drugResistanceName
  {
    label: '耐药标识',
    icon: 'icon-naiyaobiaoshi',
    content: 'drugResistanceName',
    isShow: 'drugResistanceType',
    type: 'arr',
  },
];
// 耐药图标
export const renderDrugResistance = (value) => {
  if (value === 'M') {
    return 'icon-naijiayangxilinjinhuangseputaoqiujun';
  } else if (value === 'S') {
    return 'icon-youchuanranxingdemeidu';
  } else if (value === 'C') {
    return 'icon-naitanqingmeixichangganjunkexijun';
  } else if (value === 'E') {
    return 'icon-a-chanESBLschaoguangpu-neixiananmeidegelanshiyinxingjun';
  } else if (value === 'A') {
    return 'icon-naitanqingmeixicusuangai-baomanbudongganjun';
  } else if (value === 'V') {
    return 'icon-naigumeisuchangqiujun';
  } else if (value === 'B') {
    return 'icon-a-yigandaxiaosanyang';
  } else if (value === 'P') {
    return 'icon-quannaiyaoxijun';
  } else if (value === 'H') {
    return 'icon-aizi';
  } else if (value === '乙') {
    return 'icon-yigan';
  } else if (value === '丙') {
    return 'icon-binggan';
  } else if (value === 'T') {
    return 'icon-jiehe';
  } else if (value === 'G') {
    return 'icon-yunchanfuBqunlianqiujunyangxing';
  } else {
    return '';
  }
};
// 右键
export const doctorRightContextMenuData = [
  { label: '医嘱管理', value: 'order', isDisabled: false },
  { label: '病例文书', value: 'caseFile', isDisabled: false },
  { label: '基本信息', value: 'basicInfo', isDisabled: false },
  { label: '医疗授权', value: '3', isDisabled: false },
  { label: '费用查询', value: '4', isDisabled: false },
  { label: '院感监测', value: '5', isDisabled: false },
  { label: '检查检验报告', value: 'report', isDisabled: false },
];
