import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStoragePurchaseController_getAuditPurchasePlanMasterList_e10698: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-save-purchase-plan-master-list',
    parameterFomatter: (data?: {
      qto: GetMasterByStorageStatusTypeQtoDrugStoragePurchasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SavePurchasePlanMasterListVoDrugStoragePurchaseEntranceWebVo[],
    ) => data,
  },
});
