import React, { useEffect, useState } from 'react';
import {
  Input,
  VSForm,
  VSFormLayout,
  Button,
  VSFormItem,
  Checkbox,
  Search,
  Space,
  message,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';

export default function index() {
  const [amFarewell, setAmFarewell] = useState(true);
  const [pmFarewell, setPmFarewell] = useState(true);
  const [checkboxDisable, setCheckboxDisable] = useState();

  return (
    <div className="register-container-layout">
      <div className="register-cart-top">
        <div className="register-cart-top-left">
          <div className="medical-steps">
            <ul>
              {/* {register
                ?.filter((item) => item.clinicRegisterList.length !== 0)
                .map((item, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      className={indexing !== index ? 'selected' : 'unselect'}
                      index={index}
                    >
                      <a
                        href={'#index' + index}
                        onClick={() => scrollToAnchor(index)}
                      >
                        {item?.outpSpecialName}
                      </a>
                    </li>
                  );
                })} */}
            </ul>
          </div>
        </div>
        <div className="register-cart-top-right">
          <div className="register-cart-serach">
            <div className="serach-input">
              <Input
                placeholder="请输入号别"
                width={200}
                // onChange={InputValueChange}
                prefix={<Icon type="icon-sousuo"></Icon>}
              />
            </div>
            <div className="select-cart-show">
              <div className="select-cart-show-title">选中号别：</div>
              <div
                className="select-cart-show-content"
                // onClick={() => {
                //   selectNumberType && setSelectNumberType(undefined);
                //   selectNumberType && setCheackCart(undefined);
                //   appointmentData && setAppointmentData(undefined);

                //   setCheackCart(undefined);
                // }}
              >
                {/* {selectNumberType && (
                  <SelectTableCart
                    selectNumberType={selectNumberType}
                    datasource={selectDatasource}
                  />
                )}
                {appointmentData &&
                  appointmentData.map((item) => {
                    return (
                      item && (
                        <SelectTableCart
                          selectNumberType={selectNumberType}
                          datasource={selectDatasource}
                          appointmentData={item}
                        />
                      )
                    );
                  })} */}
              </div>
            </div>
            <div className="bill-number">
              <div className="select-cart-number">票据NO：</div>
              <div className="select-cart-farewell">
                <Checkbox
                  value={amFarewell}
                  disabled={checkboxDisable}
                  onChange={(item) => {
                    // item && setPmFarewell(false);
                    // setAmFarewell(item);
                  }}
                >
                  上午
                </Checkbox>
                <Checkbox
                  value={pmFarewell}
                  onChange={(item) => {
                    // setPmFarewell(item);
                    // item && setAmFarewell(false);
                  }}
                >
                  下午
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="register-cart-main">
            <div className="register-cart-main-container">
              {/* {register
                    ?.filter((item) => item.clinicRegisterList.length !== 0)
                    ?.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="register-cart-main-title">
                            <div
                              className="register-cart-main-title-before"
                              id={'index' + index}
                            >
                              {item?.outpSpecialName}
                            </div>
                          </div>
                          <div className="register-cart-main-content">
                            {item?.clinicRegisterList?.map((data, index1) => {
                              return (
                                <div
                                  key={`${index}${index1}`}
                                  style={
                                    cheackCart ===
                                    `${item?.outpSpecialId}${data?.id}`
                                      ? selectStyle
                                      : unSelectStyle
                                  }
                                  onClick={() => {
                                    // selectNumberType &&
                                    // setSelectNumberType(undefined);
                                    selectNumberType && setCheackCart(undefined);
                                    setAppointmentData(undefined);
                                    setCheackCart(
                                      `${item?.outpSpecialId}${data?.id}`,
                                    );
    
                                    if (
                                      cheackCart ===
                                      `${item?.outpSpecialId}${data?.id}`
                                    ) {
                                      setCheackCart(undefined);
                                      onChangeCartItem(undefined, undefined);
                                    }
                                  }}
                                >
                                  <TableCart
                                    data={data}
                                    datasource={item}
                                    onChangeCartItem={onChangeCartItem}
                                    wasSelect={
                                      `${index}${index1}` === cheackCart
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  {register.length === 0 && (
                    <div className="register-empty">
                      <Empty />
                    </div>
                  )} */}
              {/* <div className='register-loadding'>{loading&&<Loading />}</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="register-serach-bottom">
        {/* <RegistratuonForm
              selectNumberType={selectNumberType}
              appointmentCallback={appointmentCallback}
              onChange={() => {
                run();
              }}
              routes={routes}
            /> */}
      </div>
    </div>
  );
}
