import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { message, Popover } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/cpoe/Icon';
import AspirinIcon from '@/module/Icon';
import Collect from '@/pages/Index/components/collect';
import Tip from '@/pages/Index/components/func/Tip';
import TipModal from '@/pages/Index/components/func/TipModal';
import { handelSexIcon } from '@/pages/Index/manage/bed_card/content/bedCardList';
import PatientMainSearch from '@/pages/Index/manage/patient_main_manager/patient_main_search/table.jsx';
import { useHorizontalScroll } from '@/utils/hooks';
import { handleSex } from '@/utils/index';
import storage from '@/utils/storage';

import Oper from '../oper';
import PatientInfoSetting from '../setting/patientInfoSetting';

const renderHospitalIcon = [
  {
    icon: (
      <AspirinIcon
        type="icon-a-yigandaxiaosanyang"
        style={{
          color: '#3276E8',
          cursor: 'pointer',
        }}
        size={20}
      />
    ),
    value: 'drugResistanceName',
    other: 'drugResistanceType',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-linchuanglujing"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '临床路径',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-linchuangshiyan"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '临床试验',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-lvsetongdao"
        style={{ color: '#30A313', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '绿色通道',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-DRG"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: 'DRGS',
  },
];

type InfoPropsType = {
  data: any;
  color?: string;
  info?: any;
  type?: 'in' | 'out';
};

const Index = (props) => {
  const { user } = props?.stores || {};
  const {
    outVisitInfo,
    hospitalType,
    currentPatient,
    inVisitInfo,
    inVisitTreatInfo,
    getInVisitTreatInfo,
  } = user || {};
  const indexCls = getPrefixCls('cpoe-index-center-info');
  const [openModal, setOpenModal] = useState<boolean>();
  // 取消收藏弹窗
  const [openCancelCollectModal, setCancelCollextModal] = useState(false);
  // 收藏弹窗
  const [openCollectModal, setCollextModal] = useState<boolean>(false);
  // 取消收藏/收藏弹窗参数
  const [collectParams, setCollectParams] = useState<any>();
  // const [settingModal, setSettingModal] = useState<boolean>(false);
  const infoRef = useRef<any>();
  // 设置弹窗
  const settingModal = useRef<any>();
  useHorizontalScroll(infoRef);

  useEffect(() => {
    if (inVisitInfo?.inpVisitId && hospitalType == 'in') {
      getInVisitTreatInfo(inVisitInfo?.inpVisitId);
    }
  }, [hospitalType, getInVisitTreatInfo, inVisitInfo]);

  const mockIn = [
    {
      color: '#3276E8',
      data: [
        { label: '诊断', value: '-', field: 'diagnosisDescription' },
        { label: '过敏史', value: '-', field: 'patientAllergy' },
      ],
    },
    {
      color: '#FFA57E',

      data: [
        { label: '病情', value: '-', field: 'condition' },
        { label: '住院医生', value: '-', field: 'inChargeDoctorName' },
      ],
    },
    {
      color: '#69A1FF',
      data: [
        {
          label: '入院日期',
          value: '-',
          field: 'admissionDateTime',
          other: 'admissionDays',
        },
        {
          label: '手术日期',
          value: '-',
          field: 'operationEndDateTime',
          other: 'afterOperationDay',
        },
      ],
    },
    {
      color: '#69A1FF',
      data: [
        {
          label: '费别',
          value: '-',
          field: 'chargeType',
          other: vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
        },
        {
          label: '人员类别',
          value: '-',
          field: 'identityCode',
          other: vsf.stores.dbenums?.enums?.IDENTITY_DICT,
        },
      ],
    },
    {
      color: '#69A1FF',
      data: [
        { label: '总费用', value: '0', field: 'totalCost' },
        { label: '预交金', value: '0', field: 'prePaymentAmount' },
      ],
    },
    {
      color: '#69A1FF',
      data: [
        { label: '预交金余额', value: '0', field: 'prePaymentBalance' },
        { label: 'icon', value: '-', field: 'icon' },
      ],
    },
  ];

  const patientType = {
    INP: '在科患者',
    CONSULTATION: '会诊患者',
    TRANSFER: '转科患者',
    AUTHORITY: '授权患者',
    PRE_INP: '预住院患者',
    NOT_SUBMIT_MEDICAL_RECORD: '病历未提交',
  };

  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    if (_res?.length > 0) {
      return _res?.[0]?.label;
    } else {
      return '';
    }
  };

  // 收藏相关
  // 收藏分类列表
  const getCollectClassifyList = async () => {
    const res =
      await vsf.services?.DoctorFavoriteDescriptionController_getDoctorFavoriteDescription_9014bf?.(); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.map((item) => {
      return {
        label: item?.medicalRecordDescription,
        value: item?.id,
        amount: item?.amount,
      };
    });
    // setCollectClassifyList(_res);
    return _res ?? [];
  };
  // 添加收藏
  const handelCollect = (row, collectDescription, isMySelect = 'other') => {
    setCollextModal(true);
    setCollectParams({
      outVisitInfo,
      collectDescription,
      isMySelect,
    });
  };
  // 取消收藏
  const handelCancelCollect = (row, isMySelect = 'other') => {
    setCancelCollextModal(true);
    setCollectParams({
      outVisitInfo,
      isMySelect,
    });
  };
  // 删除
  const deleteCollect = async (value) => {
    const content =
      value?.amount > 0
        ? `该分类有${value?.amount ?? ''}条病例，删除后，将其从收藏病例里移出?`
        : '确定删除该分类?';
    Tip({
      content: content,
      onOk: async () => {
        const res =
          await vsf.services?.DoctorFavoriteDescriptionController_deleteDoctorFavoriteDescription_14100e?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              btoParam: {
                medicalRecordDescription: value?.label,
              },
              extApiParam: {},
            },
          ); // TODO: 你可能需要整理返回数据格式
        // 打开列表
        // openDropdown();
        // onDropdownOpen(true);
        if (res?.code === 200) {
          // getCollectClassifyList();
          // vsf.refs.collectTable?.reload();
          // vsf.refs.otherTable?.reload();
          // vsf.refs.notTreatTable?.reload();
          message.open({
            type: 'success',
            content: `删除成功!`,
          });
        }
      },
      onCancel: () => {
        // 打开列表
        // openDropdown();
      },
    });
  };

  // 住院信息栏
  const InItem = (props: InfoPropsType) => {
    const { data, info, type = 'out' } = props;
    return (
      <div
        className="info-item"
        style={{
          width: data?.data?.[0]?.field === 'admissionDateTime' ? 200 : '',
        }}
      >
        <div className="info-content">
          {(data?.data || [])?.map((item, index) => {
            if (item?.field == 'patientAllergy') {
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={
                      info?.[item?.field]?.length > 0
                        ? info?.[item?.field].join(' ； ')
                        : '暂无'
                    }
                    key={index}
                  >
                    <div className="value">
                      {item?.field &&
                      info?.[item?.field] &&
                      info?.[item?.field]?.length > 0
                        ? info?.[item?.field].join(' ； ')
                        : item?.value}
                    </div>
                  </Popover>
                </div>
              );
            } else if (item?.field == 'condition') {
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={
                      info?.crisisIndicator
                        ? '病危'
                        : info?.seriousIndicator
                        ? '病重'
                        : '暂无'
                    }
                    key={index}
                  >
                    <div className="value">
                      {item?.field && info?.[item?.field]
                        ? info?.crisisIndicator
                          ? '病危'
                          : info?.seriousIndicator
                          ? '病重'
                          : '暂无'
                        : item?.value}
                    </div>
                  </Popover>
                </div>
              );
            } else if (
              item?.field == 'admissionDateTime' ||
              item?.field == 'operationEndDateTime'
            ) {
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={
                      info?.[item?.field]
                        ? `${dayjs(info?.[item?.field]).format('YYYY-MM-DD')}(${
                            info?.[item?.other]
                          }天)`
                        : '暂无'
                    }
                    key={index}
                  >
                    <div className="value">
                      {item?.field && info?.[item?.field]
                        ? `${dayjs(info?.[item?.field]).format('YYYY-MM-DD')}(${
                            info?.[item?.other]
                          }天)`
                        : item?.value}
                    </div>
                  </Popover>
                </div>
              );
            } else if (
              item?.field == 'chargeType' ||
              item?.field == 'identityCode'
            ) {
              // console.log(
              //   handelStopwatch(info?.[item?.field], item?.other),
              //   info?.[item?.field],
              //   item?.other,
              //   'aa===',
              //   vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
              // );

              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={
                      info?.[item?.field]
                        ? handelStopwatch(info?.[item?.field], item?.other)
                        : '暂无'
                    }
                    key={index}
                  >
                    <div className="value">
                      {item?.field && info?.[item?.field]
                        ? handelStopwatch(info?.[item?.field], item?.other)
                        : item?.value}
                    </div>
                  </Popover>
                </div>
              );
            } else if (
              item?.field == 'totalCost' ||
              item?.field == 'prePaymentAmount' ||
              item?.field == 'inChargeDoctorName' ||
              item?.field == 'prePaymentBalance' ||
              item?.field == 'diagnosisDescription'
            ) {
              return (
                <div className="item-row " key={index}>
                  <div
                    className="label"
                    style={
                      {
                        // width: item?.label === '预交金余额' ? 80 : 70,
                      }
                    }
                  >
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={
                      info?.[item?.field]
                        ? info?.[item?.field]
                        : item?.field == 'diagnosisDescription'
                        ? '暂无'
                        : item?.value
                    }
                    key={index}
                  >
                    <div className="value">
                      {item?.field && info?.[item?.field]
                        ? info?.[item?.field]
                        : item?.value}
                    </div>
                  </Popover>
                </div>
              );
            } else if (item?.field == 'icon') {
              return (
                <div className="icons" key={item?.field}>
                  {renderHospitalIcon?.map((i, index) => {
                    // drugResistanceName耐药
                    if (i?.value == 'drugResistanceName') {
                      const res = info?.[i?.value]?.map((item, index) => {
                        return `(${info?.drugResistanceType?.[index]}):${item}`;
                      });

                      return (
                        <Popover
                          content={
                            info?.[i?.value]?.length > 0
                              ? res.join('；')
                              : '暂无'
                          }
                          key={index}
                        >
                          <div>{i?.icon}</div>
                        </Popover>
                      );
                    } else {
                      return (
                        <Popover content={i?.value} key={index}>
                          <div>{i?.icon}</div>
                        </Popover>
                      );
                    }
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const renderHeader = (type = 'out') => {
    if (type === 'in') {
      return (
        <div className="header-right-container">
          <div className="in_item">
            <Icon
              type="icon-weiji"
              style={{
                color: '#F36969',
              }}
            />
            <div className="label">危急</div>
          </div>
          <div className="in_item">
            <Icon type="icon-shoucang1" style={{ color: '#ffa57e' }} />
            {/* 没有收藏 */}
            {/* <Icon
                  type="icon-meiyoushoucang"
                  style={{ color: '#343B42' }}

                /> */}
            <div className="label">收藏</div>
          </div>
          <div
            className="in_item"
            // 不确定要不要
            // onClick={() => {
            //   settingModal.current?.handeOpen(true);
            // }}
          >
            <Icon type="icon-shezhi" />
            <div className="label">设置</div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  };
  // 主索引弹窗
  const handelmodal = (v) => {
    setOpenModal(v);
  };
  // 收藏弹窗
  const rendelCollectModal = (params) => {
    const { isMySelect, outVisitInfo: row, collectDescription } = params;
    return (
      <TipModal
        content={`${
          (
            isMySelect === 'other'
              ? row?.doctorFavoriteDescription
              : row?.medicalRecordDescription
          )
            ? '是否更改收藏目录?'
            : '确认收藏?'
        }`}
        open={openCollectModal}
        onCancel={() => {
          setCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_createDoctorFavoriteMedicalRecord_f15c7f?.(
              {
                btoParam: {
                  // TODO: 请检查以下这部分传参是否符合需求
                  outpVisitEncounterId: row?.id,

                  medicalRecordDescription: collectDescription?.label,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCollextModal(false);
            message.open({
              type: 'success',
              content: `收藏成功`,
            });
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
            }, 100);
          }
        }}
      />
    );
  };
  // 取消收藏弹窗
  const rendelCancelCollectModal = (params) => {
    const { isMySelect, outVisitInfo: row } = params;
    return (
      <TipModal
        content="是否取消收藏"
        open={openCancelCollectModal}
        onCancel={() => {
          setCancelCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_deleteDoctorFavoriteMedicalRecord_bc0e79?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: {
                  outpVisitEncounterId:
                    isMySelect === 'other'
                      ? row?.id
                      : row?.outpVisitEncounter?.id,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCancelCollextModal(false);
            message.open({
              type: 'success',
              content: `成功取消收藏`,
            });
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
              // getCollectClassifyList();
            }, 100);
          }
        }}
      />
    );
  };
  const onOper = () => {
    if (props?.common_components) return;
    return <Oper />;
  };
  return (
    <div className={indexCls}>
      {onOper()}
      {/* <Oper /> */}
      {hospitalType === 'in' && inVisitInfo?.id ? (
        <div className="aspirin-header-user-info">
          <div className="bg"></div>
          <div className="user-info-container">
            <div className="hospital-number">
              <div className="hospital-number_img">
                <Icon type="icon-Frame2" size={30} style={{ color: '#fff' }} />
              </div>
              <div className="hospital-number_tag">
                {inVisitTreatInfo?.patientType
                  ? patientType[inVisitTreatInfo?.patientType]
                  : '暂无'}
              </div>
            </div>
            <div className="main hospital-main">
              <div className="first">
                <Popover content={inVisitTreatInfo?.patientName}>
                  <div className="name">{inVisitTreatInfo?.patientName}</div>
                </Popover>
                <span className="gender">
                  <Popover
                    content={
                      handleSex(
                        inVisitTreatInfo?.patientGender,
                        vsf.stores.dbenums?.enums?.SEX_DICT,
                      )?.label
                    }
                  >
                    {handelSexIcon(inVisitTreatInfo?.patientGender)}
                  </Popover>
                </span>
                <Popover content={inVisitTreatInfo?.patientAge}>
                  <span className="age">{inVisitTreatInfo?.patientAge}</span>
                </Popover>
              </div>
              <div className="second">
                <span className="label">患者ID</span>
                <Popover content={inVisitTreatInfo?.patientDisplayId}>
                  <span className="value">
                    {inVisitTreatInfo?.patientDisplayId}
                  </span>
                </Popover>
              </div>
            </div>
            <div className="hospital-info info noscroll" ref={infoRef}>
              {mockIn?.map((item, index) => (
                <InItem
                  type="in"
                  key={index}
                  data={item}
                  info={{
                    ...cloneDeep(inVisitTreatInfo),
                    ...(inVisitTreatInfo ?? {}),
                  }}
                />
              ))}
            </div>
          </div>
          {renderHeader('in')}
        </div>
      ) : null}
      {/* 以下功能不确定是否需要，现在逻辑同门诊 */}
      {/* 主索引 */}
      {openModal && (
        <PatientMainSearch
          patientId={currentPatient?.patientId}
          handelmodal={handelmodal}
          showTable={false}
        />
      )}
      {/* 收藏二次确认弹窗 */}
      {openCollectModal && rendelCollectModal(collectParams)}
      {/* 取消收藏二次确认弹窗 */}
      {openCancelCollectModal && rendelCancelCollectModal(collectParams)}
      {/* 点击设置 */}
      <PatientInfoSetting ref={settingModal} />
    </div>
  );
};

export default definePage(Index);
