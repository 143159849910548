import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  Table,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import SearchContainer from '@/module/Search';
import ComplexSelect from '@/pages/Index/components/complex_select';
import CustomSelect from './CustomSelect';

const DrugManger = (props) => {
  const { drugId, onDrugChange } = props;

  const [skinTestClassRequired, setSkinTestClassRequired] = useState(false);
  const [herbTypeRequired, setHerbTypeRequired] = useState(false);

  const loadDrugInfo = useCallback(
    async (_drugId) => {
      try {
        if (drugId || _drugId) {
          const response =
            await vsf?.services?.DrugController_getDrugExtensionByDrugId_e6769c?.(
              {
                drugId: drugId ?? _drugId,
              },
            );
          if (response && response.code === 200) {
            console.log(response.data, 'drug_response');
            console.log(
              response?.data?.drug?.dangerIndicator,
              'drug_dangerIndicator',
            );

            const resData = {
              ...response?.data,
              dangerIndicator: [response?.data?.drug?.dangerIndicator],
            };
            console.log(resData, 'drug_resData');
            vsf?.refs?.DrugMangerForm?.setFieldsValue(resData);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [drugId],
  );

  useEffect(() => {
    loadDrugInfo();
  }, [drugId]);

  const handelDrugNameSelectChange = (v) => {
    onDrugChange?.(v);
  };

  return (
    <VSControlledForm
      id="DrugMangerForm"
      vsid="75421"
      className="aspirin-form drug_manager"
      omitNil={false}
    >
      <SearchContainer.Header title="药品信息" />

      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
        transform={() => {
          return {
            id: drugId,
          };
        }}
      />

      <VSFormLayout
        key="1"
        columnCount={4}
        labelWidth={100}
        style={{ marginTop: 20 }}
      >
        <VSFormItem
          name={['drug', 'drugName']}
          label="通用名称"
          valueType="custom"
          required
        >
          <CustomSelect
            placeholder={'请输入通用名称'}
            handelDrugNameSelectChange={handelDrugNameSelectChange}
            addItemOptions={{
              status: true,
              mode: 'single',
            }}
          />
        </VSFormItem>

        <VSFormItem
          width={200}
          name={['drug', 'classCode']}
          label="药理分类编码"
          valueType="treeSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_CLASS_DICT}
          rules={[{ required: true, message: '请选择药理分类编码' }]}
        />

        <VSFormItem
          name={['accountBookClass']}
          label="账簿类别"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.ACCOUNT_BOOK_CATEGORY}
          rules={[{ required: true, message: '请选择账簿类别' }]}
        />

        <VSFormItem
          name={['drug', 'drugCode']}
          label="药品编码"
          initialValue={Math.floor(Math.random() * 900000) + 100000}
          valueType="text"
          fieldProps={{
            disabled: true,
          }}
        />

        <VSFormItem
          name={['drug', 'dosageFormCode']}
          label="剂型"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_FORM_DICT}
          rules={[{ required: true, message: '请选择剂型' }]}
        />

        <VSFormItem
          name={['drug', 'drugIndicator']}
          label="药品类型"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
            onChange: (v) => {
              setHerbTypeRequired(v === '2');
            },
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_INDICATOR_DICT}
          rules={[{ required: true, message: '请选择药品类型' }]}
        />

        <VSFormItem
          name={['drug', 'skinTestType']}
          label="皮试属性"
          valueType="codeTableSelect"
          dataSource={[
            { label: '强制皮试', value: 'FORCE' },
            { label: '建议皮试', value: 'ADVISE' },
          ]}
          fieldProps={{
            showSearch: true,
            onChange: (v) => {
              if (v) setSkinTestClassRequired(true);
            },
          }}
        />

        <VSFormItem
          name={['drug', 'skinTestClass']}
          label="皮试类型"
          valueType="codeTableSelect"
          dataSource={[
            { label: '原液皮试', value: 'RAW_LIQUID' },
            { label: '非原液皮试', value: 'NOT_RAW_LIQUID' },
            { label: '二者皆可', value: 'ALL' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
          rules={[
            {
              required: skinTestClassRequired,
              message: '请选择皮试类型',
            },
          ]}
        />

        <VSFormItem
          name={['drug', 'antibioticTypeCode']}
          label="抗菌药级别分类代码"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_ANTIBIOTIC_TYPE_DICT}
        />

        <VSFormItem
          name={['drug', 'ddd']}
          label="DDD值"
          valueType="digit"
          fieldProps={{
            // width: 240
            className: 'ddd',
          }}
        />

        <VSFormItem
          name={['drug', 'toxicCode']}
          label="毒理分类编码"
          valueType="codeTableSelect"
          required
          fieldProps={{
            showSearch: true,
          }}
          rules={[
            {
              required: true,
              message: '请选择毒理分类编码',
            },
          ]}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['seeminglyDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '是否看似药品',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  seeminglyDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                seeminglyDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['soundDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '是否听似药品',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  soundDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                soundDrugIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['adjuvantDrugIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '辅助用药',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  adjuvantDrugIndicator: v?.[0] ?? false,
                };
              }
              return {
                adjuvantDrugIndicator: v,
              };
            }}
          />
        </div>

        <VSFormItem
          name={['drug', 'administration']}
          label="常用给药途径"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSFormItem
          name={['drug', 'forbiddenAdministration']}
          label="禁止给药途径"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSFormItem
          name={['drug', 'frequency']}
          label="默认执行频率描述"
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['highElectrolyteIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '是否高浓度电解质',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  highElectrolyteIndicator: v?.[0] ?? false,
                };
              }
              return {
                highElectrolyteIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['ppiIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: 'PPI',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  ppiIndicator: v?.[0] ?? false,
                };
              }
              return {
                ppiIndicator: v,
              };
            }}
          />
          <VSFormItem
            name={['dangerIndicator']}
            label=""
            valueType="checkbox"
            fieldProps={{
              dataSource: [
                {
                  label: '高危',
                  value: true,
                },
              ],
            }}
            initialValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  dangerIndicator: v?.[0] ?? false,
                };
              }
              return {
                drug: {
                  dangerIndicator: v,
                },
              };
            }}
          />
        </div>

        <VSFormItem
          name={['drug', 'typeOneIncision']}
          label="一类切口预防可用标签"
          valueType="select"
          dataSource={[
            { label: '可用', value: '1' },
            { label: '不可用', value: '0' },
          ]}
        />

        <VSFormItem
          name={['babyUseForbidden']}
          label="新生儿禁忌使用"
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          name={['childUseForbidden']}
          label="儿童禁忌使用"
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['machineGrainIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '是否机配颗粒',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  machineGrainIndicator: v?.[0] ?? false,
                };
              }
              return {
                machineGrainIndicator: v,
              };
            }}
          />

          <VSFormItem
            name={['multipleSpecificationIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '是否多规',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  multipleSpecificationIndicator: v?.[0] ?? false,
                };
              }
              return {
                multipleSpecificationIndicator: v,
              };
            }}
          />

          <div className="custom_width120" />
        </div>

        <VSFormItem
          name={['pregnantUseForbidden']}
          label="孕妇禁忌使用"
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          name={['lactationUseForbidden']}
          label="哺乳期禁忌使用"
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          label="老人禁忌使用"
          name={['elderUseForbidden']}
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['drug', 'coldIndicator']}
            label=""
            valueType="checkbox"
            fieldProps={{
              dataSource: [
                {
                  label: '冷藏药标志',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  drug: {
                    coldIndicator: v?.[0] ?? false,
                  },
                };
              }
              return {
                drug: {
                  coldIndicator: v,
                },
              };
            }}
          />

          <VSFormItem
            name={['storageInDarkIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '贮存避光',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  storageInDarkIndicator: v?.[0] ?? false,
                };
              }
              return {
                storageInDarkIndicator: v,
              };
            }}
          />

          <div className="custom_width120" />
        </div>

        <VSFormItem
          label="严重肝功能不全禁忌使用"
          name={['hepaticInsufficiencyUseForbidden']}
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          label="肾功能不全50-30umol/禁忌使用"
          name={['renalInsufficiency1UseForbidden']}
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          label="肾功能不全50-15umol/禁忌使用"
          name={['renalInsufficiency2UseForbidden']}
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['useInDark']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '使用避光',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  useInDark: v?.[0] ?? false,
                };
              }
              return {
                useInDark: v,
              };
            }}
          />
          <VSFormItem
            name={['preparationIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '自制制剂',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  preparationIndicator: v?.[0] ?? false,
                };
              }
              return {
                preparationIndicator: v,
              };
            }}
          />
          <VSFormItem
            name={['herbInjectionIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '中药注射剂',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  herbInjectionIndicator: v?.[0] ?? false,
                };
              }
              return {
                herbInjectionIndicator: v,
              };
            }}
          />
        </div>

        <VSFormItem
          label="肾功能不全15umol/禁忌使用"
          name={['renalInsufficiency3UseForbidden']}
          valueType="codeTableSelect"
          dataSource={[
            { label: '禁用', value: 'FORBIDDEN' },
            { label: '慎用', value: 'CAUTIOUS' },
            { label: '不明', value: 'UNKNOWN' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSFormItem
          label="存贮温度"
          name={['temperature']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.STORE_TEMPERATURE_DICT}
        />

        <VSFormItem
          label="制剂类型"
          name={['preparationType']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.PREPARATION_TYPE_DICT}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <VSFormItem
            name={['materialIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '辅料',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  materialIndicator: v?.[0] ?? false,
                };
              }
              return {
                materialIndicator: v,
              };
            }}
          />
          <VSFormItem
            name={['valuableHerbIndicator']}
            label=""
            valueType="checkbox"
            initialValue={false}
            fieldProps={{
              dataSource: [
                {
                  label: '中药贵重药品',
                  value: true,
                },
              ],
            }}
            convertValue={(v) => `${v}`}
            transform={(v) => {
              if (Array.isArray(v)) {
                return {
                  valuableHerbIndicator: v?.[0] ?? false,
                };
              }
              return {
                valuableHerbIndicator: v,
              };
            }}
          />

          <div style={{ width: '3rem' }} />
        </div>

        <VSFormItem
          label="是否双签名"
          name={['doubleSignatureIndicator']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={[
            {
              label: '是',
              value: true,
            },
            {
              label: '否',
              value: false,
            },
          ]}
        />

        <VSFormItem
          label="中药类型"
          name={['drug', 'herbType']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          rules={[{ required: herbTypeRequired, message: '请选择中药类型' }]}
          dataSource={vsf.stores.dbenums?.enums?.CHINESE_MEDICINE_TYPE}
        />

        <VSFormItem
          label="中药针剂同类药品"
          name={['herbSimilarDrugType']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.SIMILAR_DRUG_TYPE_DICT}
        />

        <div
          style={{ display: 'flex' }}
          className="drug_manager_custom_label_width"
        >
          <VSFormItem
            name={['decoctionPieceWaterAbsorption']}
            label="饮片吸水率"
            valueType="digit"
            fieldProps={{
              className: 'decoctionPieceWaterAbsorption',
            }}
          />
        </div>

        <VSFormItem
          label="中药标识"
          name={['herbType']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.CN_MEDICINE_MARK_DICT}
        />

        <VSFormItem
          label="抗肿瘤药物级别"
          name={['antitumorLevel']}
          valueType="codeTableSelect"
          fieldProps={{
            showSearch: true,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_ANTITUMOR_LEVEL}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(DrugManger);
