import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { ButtonAndKeyDown } from '@/pages/Index/components';
import Tip from '@/pages/Index/components/func/Tip';
import Screen, { CreateScreen } from '@/pages/Index/components/screen';

import HerbStorageDurgSelect from './components/herb_storage_durg_select';
import Outdrawer from './outdrawer';
import Search from './search';
import { savePurchasePlan, submitPurchasePlan } from './service';
import Table from './table';
const HerbStoragePurchasePlanCreate = (props) => {
  // 采购单
  const [orderProps, setOrderProps] = useState<any>();

  // 按钮
  const onButton = (button) => {
    return (
      <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  const [herbType, setHerbType] = useState<string>('');
  const SearchFromRef = useRef<any>(null);
  const SearchFromMethod = useCallback(() => {
    return {
      setTotal: (value: number) => SearchFromRef?.current?.setTotal(value),
      form: SearchFromRef?.current?.form,
    };
  }, [SearchFromRef]);
  const TableRef = useRef<any>(null);
  const TableMethod = useCallback(() => {
    return {
      table: TableRef?.current?.table,
      add: async (value = {}) => await TableRef?.current?.table?.add(value),
      getValues: async () => await TableRef?.current?.table?.getValues(),
      scrollTo: async (key) => await TableRef?.current?.table?.scrollTo(key),
      validateFields: async () =>
        await TableRef?.current?.table?.getEditForm?.()?.validateFields(),
      reload: async () => await TableRef?.current?.table?.reload?.(),
    };
  }, [TableRef]);
  const onChangeTotal = useCallback(async () => {
    const values = await TableMethod()?.table?.getValues();
    SearchFromMethod?.()?.setTotal(
      values?.reduce((current, next) => {
        return (
          Number(current ?? 0) +
          Number(Number(next?.amount ?? 0) * Number(next?.price ?? 0))
        );
      }, 0),
    );
  }, [TableMethod, SearchFromMethod]);
  const onTableAdd = useCallback(
    async (value = {}) => {
      await TableMethod?.()?.validateFields();
      await TableMethod?.()?.add(value);
      const values = await TableMethod()?.getValues();
      await TableMethod()?.scrollTo(values?.[values?.length - 1]?.id);
    },
    [TableMethod],
  );
  const [drugItemId, setDrugItemId] = useState<any>();
  // onScreen 清屏 需要import useContext CreateScreen
  const onScreen = useContext(CreateScreen);
  return (
    <div className="HerbStoragePurchasePlanCreate">
      <div className="container">
        <div className="search">
          <Search
            {...{
              ref: SearchFromRef,
              onButton,
              herbType,
              setHerbType,
              onTableAdd,
              orderProps,
              TableMethod,
            }}
          />
        </div>
        <div className="table">
          <Table
            {...{
              ref: TableRef,
              orderProps,
              herbType,
              onChangeTotal,
              drugItemId,
            }}
          />
        </div>
        <div className="footer">
          <div className="search_durg">
            <VSForm>
              <VSFormLayout columnCount={1}>
                <VSFormItem label="查找药品" valueType="custom">
                  <HerbStorageDurgSelect
                    {...{
                      placeholder: '请选择药品名称',
                      herbType,
                      getChangeParams: async (params) => {
                        setDrugItemId('');
                        const values = await TableMethod?.()?.getValues?.();
                        const find = values?.find?.(
                          (e) => e?.drugItemId == params?.drugItemId,
                        );
                        if (!find?.id) return message?.error('未找到药品');
                        await TableMethod?.()?.scrollTo(find?.id);
                        setDrugItemId(params?.drugItemId);
                        message?.success('药品定位成功');
                      },
                    }}
                  />
                </VSFormItem>
              </VSFormLayout>
            </VSForm>
          </div>
          {onButton([
            {
              ghost: true,
              disabled: false,
              text: '清屏',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: onScreen,
            },
            {
              disabled: true,
              text: '打印',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: () => {},
            },
            {
              disabled: false,
              text: '保存',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => {
                await TableMethod?.()?.validateFields?.();
                const values = await TableMethod?.()?.getValues?.();
                if (values?.length == 0)
                  return message?.error('请新增采购药品');
                const { id } = orderProps ?? {};
                const res = await savePurchasePlan({
                  id,
                  herbType,
                  drugPurchasePlanDetailBtoList: values?.map((e) => {
                    const { type, ...rest } = e;
                    if (['new']?.includes?.(type)) {
                      delete rest.id;
                    }
                    return {
                      ...rest,
                    };
                  }),
                });
                if (res?.code != 200) return;
                message?.success('保存成功');
                setOrderProps({ id: res?.data });
                await TableMethod?.()?.reload?.();
              },
            },
            {
              disabled: false,
              text: '提交',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => {
                await TableMethod?.()?.validateFields?.();
                const values = await TableMethod?.()?.getValues?.();
                if (values?.length == 0)
                  return message?.error('请新增采购药品');
                Tip({
                  content: `提交后不可修改，确认提交吗?`,
                  onOk: async () => {
                    const { id } = orderProps ?? {};
                    const res = await submitPurchasePlan({
                      id,
                      herbType,
                      drugPurchasePlanDetailBtoList: values?.map((e) => {
                        const { type, ...rest } = e;
                        if (['new']?.includes?.(type)) {
                          delete rest.id;
                        }
                        return {
                          ...rest,
                        };
                      }),
                    });
                    if (res?.code != 200) return;
                    onScreen?.(message?.success('提交成功'));
                  },
                });
              },
            },
          ])}
        </div>
      </div>
      <Outdrawer
        {...{
          orderProps,
          setOrderProps,
        }}
      />
    </div>
  );
};
const Index = (props) => {
  return (
    <Screen>
      <HerbStoragePurchasePlanCreate />
    </Screen>
  );
};
export default definePage(Index);
