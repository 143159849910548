/**
 * convertQueryInpSettleMasterDetailRes2InpSettleMasterDetailInfo
 * @param {any} input
 * @returns {any}
 */
export const convertQueryInpSettleMasterDetailRes2InpSettleMasterDetailInfo = (
  input,
) => {
  if (input?.data.count === 1) {
    const _ = window._ ?? {};
    const output = {};
    const preList = {};
    output.physicalReceiptNumber = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.inpReceiptRecordBaseDtoList[0].physicalReceiptNumber`,
    );
    output.settleNumber = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.settleNumber`,
    );

    output.displayId = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.patient.displayId`,
    );
    output.name = _.get(input, `data.result.[0].inpSettleMasterVo.name`);
    output.chargeTypeCode = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.chargeTypeCode`,
    );
    output.identityCode = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.identityCode`,
    );
    output.staffName = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.operatorStaff.staffName`,
    );
    output.settleDate = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.settleDate`,
    );
    output.singleSettleIndicator = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.singleSettleIndicator`,
    );
    output.inpVisitId = _.get(input, `data.result.[0].inpSettleMasterVo.id`);
    output.totalCost = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.totalCost`,
    );
    output.inpReceiptClassDetailBaseDtoList = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.inpReceiptClassDetailBaseDtoList`,
    );
    output.inpSettleApportionItemBaseDtoList = _.get(
      input,
      `data.result.[0].inpSettleMasterVo.inpSettleApportionItemBaseDtoList`,
    );
    output.payment = _.get(input, `data.result.[0].inpSettleMasterVo.payment`);
    output.InpSettleApportionItemVoInpBillingEntranceWebVoInpsettlemasterdetailvo =
      _.get(
        input,
        `data.result.[0].inpSettleMasterVo.InpSettleApportionItemVoInpBillingEntranceWebVoInpsettlemasterdetailvo`,
      );
    output.prePaymentMasterVoList =
      input?.data.result[0]?.prePaymentMasterVoList?.map((item) => {
        return {
          ...item,
          ...item?.inpReceiptRecordBaseDtoList[0],
          ...item?.prePaymentDetailBaseDtoList[0],
        };
      });
    output.id = _.get(input, `data.result.[0].inpSettleMasterVo.id`);
    return output;
  } else {
    return input?.data.result ?? [];
  }
};

/**
 * convertGetExpenseListRes2InpExpenseGroupList
 * @param {any} input
 * @returns {any}
 */
const convertGetExpenseListRes2InpExpenseGroupList = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.inpReceiptClass = _.get(d, 'inpReceiptClass');
      obj.subTotal = _.get(d, 'subTotal');
      obj.inpBillingDetailVoList = _.get(d, 'inpBillingDetailVoList');
      obj.id = i;
      return obj;
    }) ?? []),
  );
  return output;
};

export const fetchData = async (params) => {
  const res =
    await vsf?.services?.InpSettleController_queryInpSettleMasterDetail_0051ee?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: params?.pagination?.from ?? 0,
          size: params?.pagination?.size ?? 20,
          orderList: params?.orderList,
          ...params?.search,
          ...params?.filters,
          ...params?.extra,
          ...(params ?? {}),
        },
      },
    ); // Tips: 需要返回内容数据，结构和表单RO一致
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output =
    convertQueryInpSettleMasterDetailRes2InpSettleMasterDetailInfo?.(res);
  return output;
};
//挂账记录查询
export const chargeRecordQuery = async (params) => {
  // console.log('--------');
  // console.log(params);
  const res =
    await vsf?.services?.InpBillingDetailController_getExpenseList_7847bb?.({
      // TODO: 请检查以下这部分传参是否符合需求
      inpBillingDetailSimpleQto: {
        ...params,
        // inpVisitId: params?.inpVisitId,
        // orderDateRangeIn: params?.search?.orderDateRangeIn,
        // inpSettleIdIsNullOrNot:
        //   params?.search?.inpSettleIdIsNullOrNot,
      },
    });
  const output = convertGetExpenseListRes2InpExpenseGroupList?.(res);
  const arr = [];
  output?.map((item) => {
    arr.push(item);
    item?.inpBillingDetailVoList?.map((obj) => {
      arr.push(obj);
    });
  });
  return { data: arr ?? [], total: output?.length ?? 0 };
};

//与退费
export const preRefund = async (param) => {
  const res = await vsf?.services?.InpSettleController_preRefund_92acd9?.({
    inpSettleMasterId: param,
  });
  return res?.data;
};
//全部退费
// {
//   inpSettleMasterId: params?.inpSettleMasterId,
//   chargeInPrePaymentIndicator: params?.chargeInPrePaymentIndicator,
// }
export const refund = async (params) => {
  const res = await vsf?.services?.InpSettleController_refund_d561b5?.(params);
  return res;
};
