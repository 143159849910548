import React from 'react';

import { Section, VSTableColumn, VSTable } from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const PatientExpressAddresses = (props) => {
  return (
    <VSTable
      value={props?.value}
      scroll={{
        x: 1500,
      }}
      editable={{
        saveOnClickOutside: true,
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        columnProps: {
          width: 94,
        },
        cancelButtonProps: {
          onClick: () => {
            const currentRow = vsf.refs.vSTablePatientExpressAddress.getRowValue(0)
            const keys = Object.keys(currentRow)
            if(keys?.length === 1 && keys?.[0] === 'id') {
              // 删除
              vsf.refs.vSTablePatientExpressAddress?.remove(currentRow)
            }else {
              // 取消编辑状态
              vsf.refs.vSTablePatientExpressAddress?.cancelEdit()
            }
          }
        }
      }}
      vsid="99209"
      id="vSTablePatientExpressAddress"
      rowClick={{
        rowClickType: {
          action: 'double-click',
          type: 'edit-save',
        },
      }}
      onChange={(_value) => {
        props?.onChange?.(_value);
      }}
    >
      <VSTableColumn
        width={60}
        title=""
        valueType="text"
        render={(_, __, index) => index + 1}
        preview
      />

      <VSTableColumn
        width={210}
        dataIndex={['consignee']}
        title="收货人"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入收货人' },
            { message: '收货人长度不合法', type: 'string', min: 0, max: 60 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={188}
        dataIndex={['expressAddress']}
        title="收货地址"
        valueType="address"
        fieldProps={{}}
        formItemProps={{
          showDetail:false,
          rules: [
            { required: true, message: '请输入收货地址' },
          ]
        }}
      />

      <VSTableColumn
        width={418}
        dataIndex={['expressAddress', 'detail']}
        title=""
        valueType="text"
        fieldProps={{}}
        formItemProps={{}}
      />

      <VSTableColumn
        width={212}
        dataIndex={['phoneNumber']}
        title="手机号"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入手机号' },
            {
              message: '联系电话长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
          label: undefined,
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        width={212}
        dataIndex={['label']}
        title="地址标签"
        valueType="select"
        initialValue={'家'}
        fieldProps={{
          showSearch: true,
          dataSource: [
            {
              label: '家',
              value: '家',
            },
            {
              label: '公司',
              value: '公司',
            },
            {
              label: '学校',
              value: '学校',
            },
            {
              label: '其他',
              value: '其他',
            },
          ],
        }}
      />

      <VSTableColumn
        width={162}
        dataIndex={['defaultIndicator']}
        title="默认地址"
        valueType="switch"
        fieldProps={{}}
        formItemProps={{}}
      />
    </VSTable>
  );
};
export default definePage(PatientExpressAddresses);
