import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Dropdown,
  message,
  Modal,
  Section,
  Tree,
  TreeProps,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useAvoidState,
  useGetResetInitState,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

import { MedicalOrderTemplateDefendProps } from './Defend';
import DefendFloatEdit from './DefendFloatEdit';
type DefendTreeProps = MedicalOrderTemplateDefendProps & {
  isEdit?: boolean;
  template?: string;
  onChange?: (value: any) => void;
  search?: string;
  onReload?: () => Promise<any>;
  reset?: (value: any) => void;
};

const DefendTree = forwardRef((props: DefendTreeProps, ref) => {
  const {
    isEdit = true,
    agreedPrescriptionIndicator,
    onReload,
    onChange,
    reset,
  } = props;

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [dataSource, setDataSource, , , getInitDataSource] =
    useGetResetInitState<any>([]);
  const [flat, setFlat] = useAvoidState([]);
  const [value, setValue] = useState({});
  const onOpen = (value) => {
    setOpen(true);
    setValue(value);
  };
  const onRemove = async (node) => {
    Tip({
      content: `${
        node?.folderOrTemplateIndicator === 'TEMPLATE'
          ? '模板'
          : '删除分类将同时删除所有子分类及分类下的所有模板，是否确认删除'
      } {${node?.title}} `,
      async onOk() {
        const res =
          await vsf.services?.OrderTemplateMasterController_deleteOrderTemplateMaster_3fa4a4?.(
            {
              orderTemplateMasterId: node?.id,
            },
          );
        if (res?.code === 200) {
          message?.success('删除成功');
          onFetch();
          reset?.('删除成功');
        }
        return res?.data;
      },
    });
  };

  const onDragEnter = (...arr) => {};

  const onDrop: TreeProps['onDrop'] = async (info: any) => {
    const { dropPosition, dragNode, node } = info;
    const index = dataSource?.findIndex(
      (item: any) => item?.id === dragNode?.id,
    );
    let data = {};
    if (node?.folderOrTemplateIndicator === 'TEMPLATE') {
      message?.warning('目标类型为模板');
      return;
    }
    // else if (dropPosition === 0) {
    //   data = {
    //     ...dataSource[index],
    //     upTemplateId: 0,
    //     sortNumber: node?.sortNumber,
    //   };
    // }
    if ([-1, 1]?.includes(dropPosition)) {
      data = {
        ...dataSource[index],
        upTemplateId: node?.upTemplateId ?? 0,
        sortNumber: node?.sortNumber,
      };
    } else {
      data = {
        ...dataSource[index],
        upTemplateId: node?.id,
      };
    }
    await vsf?.services?.OrderTemplateMasterController_createOrderTemplateMaster_f5aa85?.(
      {
        btoParam: data,
      },
    );
    setDataSource(dataSource?.toSpliced(index, 1, data));
  };

  const set = useCallback((parent, list) => {
    return list
      ?.filter((item) => item?.upTemplateId == parent?.id)
      ?.map((item) => {
        return {
          ...item,
          children: set(item, list),
        };
      });
  }, []);

  const get = useCallback((list) => {
    return list?.map((item) => {
      const arr = [item];
      if (item?.children?.length) {
        arr.push(...get(item?.children)?.flat(Infinity));
      }
      return arr;
    });
  }, []);

  const onFetch = useCallback(async () => {
    const res =
      await vsf.services?.OrderTemplateMasterController_getOrderTemplateMaster_bfb379?.(
        {
          ...(agreedPrescriptionIndicator
            ? {
                templateClass: 'HOSPITAL_TEMPLATE',
              }
            : {
                templateClass: props?.template,
              }),
          agreedPrescriptionIndicator,
        },
      );
    if (res?.code === 200 && res?.data?.length) {
      const value = res?.data
        ?.map((item) => ({
          icon:
            item?.folderOrTemplateIndicator === 'TEMPLATE' ? (
              <Icon type="icon-wenjian" className="icon20" />
            ) : (
              <Icon type="icon-wenjianjia" className="icon20" />
            ),
          ...item,
          upTemplateId: item?.upTemplateId === 1 ? null : item?.upTemplateId,
        }))
        ?.toSorted((a: any, b: any) => {
          return a?.sortNumber - b.sortNumber;
        });
      setDataSource(value);
      const tree = await vsf?.refs?.DefendTree?.buildData(
        value,
        async () => ({
          data: value,
        }),
        false,
      );
      setFlat(get(tree));
    } else {
      const value = [
        {
          title: '顶级目录',
          id: null,
          upTemplate: null,
          key: '<top>',
        },
      ] as never;
      setDataSource(value);
      setFlat(value);
    }
  }, [
    agreedPrescriptionIndicator,
    get,
    props?.template,
    setDataSource,
    setFlat,
  ]);

  useEffect(() => {
    onFetch();
  }, [onFetch]);

  useImperativeHandle(ref, () => ({
    onReload: onFetch,
    onSearch: (value) => {
      vsf?.refs?.DefendTree?.handleSearch({ target: { value } });
    },
    onSortNumber,
  }));

  const onSortNumber = ({
    folderOrTemplateIndicator = 'FOLDER',
    id,
    upTemplateId,
  }) => {
    const array = flat?.filter((item: any[]) => {
      return item?.map?.((int) => int?.id)?.includes(id);
    });
    const int = array
      ?.flat?.()
      ?.filter?.(
        (item: any) =>
          (upTemplateId === null
            ? true
            : item?.upTemplateId === upTemplateId) &&
          item?.folderOrTemplateIndicator === folderOrTemplateIndicator,
      );
    const list = int?.map?.((item: any) => item?.sortNumber);
    return Math.max(...new Set(list?.length ? list : [0]));
  };

  return (
    <Fragment>
      <TreeMenu
        id="DefendTree"
        treeData={dataSource}
        row={{
          extra: isEdit
            ? (node: any) => {
                const float = node?.folderOrTemplateIndicator === 'TEMPLATE';
                const menu = [
                  {
                    key: 'rename',
                    label: `${float ? '编辑模版' : '重命名'}`,
                  },
                ]?.map((item) => {
                  return {
                    ...item,
                    onClick: () => {
                      onOpen({
                        ...item,
                        ...node,
                      });
                    },
                  };
                });
                const items =
                  node?.key === '<top>'
                    ? [
                        {
                          key: 'add-child-class',
                          label: `新建子分类`,
                          onClick: () =>
                            onOpen({
                              key: 'add-child-class',
                              label: `新建子分类`,
                            }),
                        },
                      ]
                    : [
                        ...menu,
                        {
                          key: 'remove-class',
                          label: `删除${float ? '模版' : '分类'}`,
                          onClick: () => {
                            if (float) {
                              onRemove(node)?.then(() => onReload?.());
                            } else {
                              onRemove(node);
                            }
                          },
                        },
                      ];
                if (!float && node?.folderOrTemplateIndicator) {
                  const sortNumber = onSortNumber({
                    folderOrTemplateIndicator: 'FOLDER',
                    id: node?.id,
                    upTemplateId: node?.id,
                  });
                  // console.log(sortNumber);
                  items?.splice(
                    1,
                    0,
                    {
                      key: 'add-child-class',
                      label: '新建子分类',
                      onClick: () => {
                        onOpen({
                          key: 'add-child-class',
                          label: '新建子分类',
                          ...node,
                          sortNumber,
                        });
                      },
                    },
                    {
                      key: 'add-brother-class',
                      label: `新建同级分类`,
                      onClick: () => {
                        const sortNumber = onSortNumber({
                          folderOrTemplateIndicator: 'FOLDER',
                          id: node?.id,
                          upTemplateId: node?.upTemplateId,
                        });
                        onOpen({
                          key: 'add-brother-class',
                          label: '新建同级分类',
                          ...node,
                          sortNumber,
                        });
                      },
                    },
                  );
                }
                return (
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={['click']}
                  >
                    <div
                      className="medical-order-template-tree-row-box"
                      onClick={(event) => {
                        event?.stopPropagation();
                      }}
                    >
                      <Icon type="icon-gengduo" className="icon16" />
                    </div>
                  </Dropdown>
                );
              }
            : () => void 0,
          expand: true,
        }}
        className="aspirin-tree-hide-switcher-box padding24_0"
        dataRelationFieldNames={{
          parentId: 'upTemplateId',
          id: 'id',
        }}
        onSelect={(_value) => {
          onChange?.(_value);
        }}
        fieldNames={{
          key: 'id',
          title: 'title',
          children: 'children',
        }}
        search={{
          searchType: 'static',
        }}
        treeProps={{
          draggable: true,
          blockNode: true,
          onDragEnter,
          onDrop,
          showIcon: true,
          switcherIcon: null,
        }}
      />
      <DefendFloatEdit
        template={props?.template}
        open={open}
        onClose={onClose}
        value={value}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        reload={onFetch}
        onSortNumber={onSortNumber}
      />
    </Fragment>
  );
});
DefendTree.displayName = 'DefendTree';

export default DefendTree;
