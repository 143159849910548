import { createServices } from '@vs/vsf-boot';
export default createServices({
  PurchaseImportController_getSavePurchaseImportMasterList_e0609a: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-save-purchase-import-master-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterSupplierVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  PurchaseImportController_cancelImportMaster_062cb8: {
    method: 'post',
    url: '/api/drug-storage-import-export/cancel-import-master',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
