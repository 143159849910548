import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getLabClinicItemDetailById_cae31e: {
    method: 'post',
    url: '/api/clinic-item-base/get-lab-clinic-item-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  SpecimenController_getAllBySpecimenWithPatternQto_47cbca: {
    method: 'post',
    url: '/api/specimen/get-all-by-specimen-with-pattern-qto',
    parameterFomatter: (data?: {
      qto: SpecimenWithPatternQtoSpecimenPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SpecimenVoClinicItemBaseEntranceWebVoLabclinicitemdetailvo[],
    ) => data,
  },
  UserStaffController_getLoginStaff_278173: {
    method: 'post',
    url: '/api/user-staff/get-login-staff',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffVoClinicItemBaseEntranceWebVoLabclinicitemdetailvo,
    ) => data,
  },
  AccountController_logout_377959: {
    method: 'post',
    url: '/api/user-account/logout',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
