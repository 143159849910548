import './index.less';

import { ProFormInstance } from '@vs/pro-form';
import vsf, { definePage } from '@vs/vsf-boot';
import type { DataType } from '@vs/vsf-kit';
import { Button, message } from '@vs/vsf-kit';
import React, { Fragment, useState } from 'react';

import Block from '@/module/Block';
import Container from '@/module/Container';

import DictionaryTable from './table';
const WindowDictionaryMaintenance = (props) => {
  const [editRows, setEditRows] = useState<any[]>([]);

  const getEditValues = (record: DataType, form: ProFormInstance) => {
    const editValue = form?.getFieldsValue();
    const id = Number(Object.keys(editValue)?.[0]);
    if (!record && id) {
      setEditRows((preItems) => {
        const updatedItems = preItems?.map((item) => {
          if (item?.id === id) {
            return { ...editValue[id]?.dispenseWindowSettingVo, id };
          }
          return item;
        });

        if (updatedItems.some((item) => item?.id === id)) {
          return updatedItems;
        } else {
          return [
            ...updatedItems,
            { ...editValue[id]?.dispenseWindowSettingVo, id },
          ];
        }
      });
    }
  };

  const handelSave = async () => {
    const addValue = vsf?.refs?.WindowDictionaryTable?.getValues()
      ?.filter(
        (rowValue) =>
          typeof rowValue?.dispenseWindowSettingVo?.id === 'string' &&
          rowValue?.dispenseWindowSettingVo?.id.includes('create_'),
      )
      ?.map((item) => {
        const { id, ...rest } = item.dispenseWindowSettingVo ?? {};
        return rest;
      });
    if (
      (addValue.length > 0 &&
        addValue?.every((item) => Object.keys(item).length > 0)) ||
      editRows.length > 0
    ) {
      try {
        const res =
          await vsf?.services?.DispenseWindowSettingController_saveDispenseWindowSettingList_337cf1?.(
            {
              btoList: [...addValue, ...editRows],
            },
          );
        if (res.code === 200) {
          message.success('保存成功');
          setEditRows([]);
        }
        vsf?.refs?.WindowDictionaryTable?.reload();
      } catch (error) {
        console.log('error', error);
      }
    }
  };
  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <div className="window-dictionaty-maintenance-main">
        <Block className="aspirin-table-search-padding-no">
          <DictionaryTable getEditValues={getEditValues} />
        </Block>
        <div className="footer">
          <Button type="primary" onClick={() => handelSave()}>
            保存
          </Button>
        </div>
      </div>
    </Container>
  );
};
export default definePage(WindowDictionaryMaintenance);
