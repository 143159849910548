import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemController_getFavoriteClinicItem_fa660a: {
    method: 'post',
    url: '/api/clinic-item-base/get-favorite-clinic-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemController_getClinicItemDictionaryLabById_ada097: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-lab-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryLabVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemController_allClinicItemDictionaryCharge_d97a65: {
    method: 'post',
    url: '/api/clinic-item-base/all-clinic-item-dictionary-charge',
    parameterFomatter: (data?: {
      qto: ClinicItemDictionaryQtoSimpleClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  LabController_getAllByLabClassQto_a6f1c8: {
    method: 'post',
    url: '/api/lab/get-all-by-lab-class-qto',
    parameterFomatter: (data?: { qto: LabClassQtoLabPersistQto }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo[]) => data,
  },
});
