import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  RadioGroup,
  Section,
  VSControlledForm,
  VSDataProvider,
  VSForm,
  VSFormFooter,
  VSFormFooterReset,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  askDtoToBto,
  askRomanAlphabet,
  askSearch,
} from '@/pages/Index/components/func/ask';

const Edit = (props) => {
  const { title, value } = props;

  const [detail, setDtail] = useState<any>();
  const [diseaseNameList, setDiseaseNameList] = useState();
  const [diseaseName, setDiseaseName] = useState();

  useEffect(() => {
    getDiseaseName();
    hasId(value) &&
      vsf.services
        ?.ClinicRecordHistoryController_getDiseaseHistoryById_23156c?.({
          // TODO: 请检查以下这部分传参是否符合需求
          id: value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            setDtail(res?.data);
            vsf.refs?.diseaseForm?.setFieldsValue({
              ...res.data,
              diseaseName: res?.data?.diseaseName,
              diagnosisId: res?.data?.diagnosisId,
            });
          }
        });
  }, [value]);

  const hasId = (data) => {
    return data?.id;
  };

  const getDiseaseName = async (v?) => {
    const res =
      await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          qto: {
            from: 0,
            size: 100,
            ...v,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      const _res = res?.data?.result?.map((item) => {
        return { label: item?.diagnosisName, value: item?.id };
      });
      return _res;
      // setDiseaseNameList(_res);
    }
  };

  const handelTime = (date1, date2 = dayjs()) => {
    const res = date2.diff(date1, 'month');
    console.log(res, '====res');
    if (res === 0) {
      return { durationTime: 0, unit: 'MONTH' };
    } else if (res % 12 === 0) {
      return {
        durationTime: res / 12,
        unit: 'YEAR',
      };
    } else {
      return {
        durationTime: res,
        unit: 'MONTH',
      };
    }
  };

  return (
    <div className="add_disease_modal">
      <VSControlledForm labelAlign="left" id="diseaseForm">
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormLayout key="1" labelWidth={120} columnCount={1}>
          <VSFormItem
            name={['diseaseName']}
            label="疾病名称"
            valueType="select"
            dataSource={getDiseaseName}
            rules={[
              // { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              { required: true },
            ]}
            fieldProps={{
              disabled: title === 'update',
              showSearch: true,
              // onSelect: (v) => {
              //   setDiseaseName(v?.label);
              // },
              // onSearch: (v) => {
              //   getDiseaseName(v);
              //   console.log(v);
              // },
            }}
            fieldNames={{ label: 'label', value: 'value' }}
            searchKey="searchInputLike"
            transform={(v) => {
              console.log(v, 'pp');
              return {
                diseaseName:
                  title === 'update' ? detail?.diseaseName : v?.label,
                diagnosisId:
                  title === 'update' ? detail?.diagnosisId : v?.value,
              };
            }}
          />

          <VSFormItem
            name={['startDate']}
            label="发病时间"
            valueType="date"
            initialValue={+new Date()}
            fieldProps={{
              width: 300,
              defaultValue: +new Date(),
              disabledDate: (current) => {
                return current && current > dayjs().endOf('day');
              },
              onChange: (v) => {
                const res = handelTime(v);
                vsf.refs?.diseaseForm?.setFieldsValue({
                  durationTimeUnit: res?.unit,
                  durationTime: res?.durationTime,
                });
              },
            }}
          />
          <VSFormItem
            name={['durationTime']}
            label="持续时间"
            valueType="digit"
            rules={[{ message: '值不合法', type: 'number', min: 0, max: 999 }]}
            initialValue={0}
            fieldProps={{
              defaultValue: 0,
              addonAfter: (
                <div style={{ height: 30, width: 70 }}>
                  <VSFormItem
                    name={['durationTimeUnit']}
                    // label="持续时间单位"
                    className="durationTime_formItem"
                    valueType="select"
                    dataSource={[
                      { label: '月', value: 'MONTH' },
                      { label: '年', value: 'YEAR' },
                    ]}
                    initialValue={'YEAR'}
                    fieldProps={{
                      placeholder: '',
                      allowClear: false,
                    }}
                  />
                </div>
              ),
              width: 300,
            }}
          />

          <VSFormItem
            name={['chronicAcuteDisease']}
            label="慢病标识"
            valueType="select"
            initialValue="CHRONIC_DISEASE"
            dataSource={[
              { label: '慢病', value: 'CHRONIC_DISEASE' },
              { label: '急症', value: 'ACUTE_DISEASE' },
            ]}
            fieldProps={{
              width: 300,
            }}
          />
          <VSFormItem
            name={['cureIndicator']}
            label="当前状态"
            valueType="radio"
            dataSource={[
              {
                label: '未治愈',
                value: false,
              },
              {
                label: '治愈',
                value: true,
              },
            ]}
            initialValue={false}
            fieldProps={{}}
          />
          <VSFormItem
            name={['treatDescription']}
            label="治疗情况"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['selfComplaintIndicator']}
            label="信息来源"
            valueType="radio"
            dataSource={[
              {
                label: '患者自述',
                value: true,
              },
              {
                label: '本院发生',
                value: false,
              },
            ]}
            initialValue={true}
            transform={(v) => {
              return {
                selfComplaintIndicator: v ? true : false,
                inHospitalIndicator: v ? false : true,
              };
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </div>
  );
};

export default definePage(Edit);
