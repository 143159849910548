import { getWubiCode } from 'lancet-pywb/PY_WB';
import { pinyin as getPinYinCode } from 'pinyin-pro';
type Type = {
  wubi: string;
  pinyin: string;
  all: [Type['pinyin'], Type['wubi']];
};
/**
 * 获取汉字罗马字母的函数
 * @param str 汉字
 * @param type 返回类型五笔/拼音/全部
 * @returns 返回根据 type 来
 */

export default function askRomanAlphabet(
  str: string,
  type: keyof Type = 'pinyin',
  character = true,
) {
  const int: string = getPinYinCode(str, {
    pattern: 'first',
    toneType: 'none',
    type: 'string',
  });
  // const pinyin = character ? int?.split(',')?.[0] ?? '' : int;
  const pinyin = int
    ?.replace(/\s/g, '')
    ?.split('')
    ?.map((item) => item?.toLocaleUpperCase())
    ?.join('');
  switch (type) {
    case 'all':
      return [pinyin, getWubiCode(str)];
    case 'pinyin':
      return pinyin;
    case 'wubi':
      return getWubiCode(str);
  }
}
