import {
  Input,
  VSForm,
  VSFormLayout,
  Button,
  VSFormItem,
  Checkbox,
  Modal,
  Space,
  message,
  Image,
  Select,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

import { CertificateSearch } from '@/components';
import { idCard } from '@/pattern';
import { subtract } from '@/utils/setDataUtil';
import { getScrollY, labelColWidth, labelNumberWidth } from '@/utils';
import readCard from '@/assets/icon/readCard.png';
import SerachIcon from '@/assets/icon/Search.png';

import PayModal from './ParModal';
import PrimaryIndexModal from './PrimaryIndexModal';

import './index.less';
import { isMinScreen } from '@/config';

function RegistratuonForm(props) {
  const { selectNumberType, appointmentCallback, routes } = props;
  const [registerData, setRegisterData] = useState();
  const [open, setOpen] = useState();
  const [payParamData, setPayParamData] = useState();
  const [appointmentStatus, setAppointmentStatus] = useState(false);

  const [pickStatus, setPickStatus] = useState(0);

  const [standingType, setStandingType] = useState();

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const { run: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
        onPressEnter(res?.data?.result[0].displayId);
      }

      return res?.data?.result;
    },
    { manual: true },
  );

  const [indexOpen, setIndexOpen] = useState(false);

  const [indexData, setIndexData] = useState();

  const indexOpenChange = (param) => {
    setIndexOpen(param);
  };
  useEffect(() => {
    if (registerData?.clinicRegisterAppointInfoList) {
      if (registerData?.clinicRegisterAppointInfoList?.length) {
        const arr = registerData?.clinicRegisterAppointInfoList?.map(
          (item, index) => {
            if (index > pickStatus) {
              return { ...item };
            }
          },
        );
        const file = { ...registerData, clinicRegisterAppointInfoList: arr };
        appointmentCallback(file);
      }
    }
  }, [pickStatus]);

  useEffect(() => {
    indexData && onPressEnter(indexData?.displayId);
  }, [indexData]);
  useEffect(() => {
    if (routes?.query?.displayId) {
      onPressEnter(routes?.query?.displayId);
    }
  }, [routes]);
  const onModalSave = async (params) => {
    const needAccept =
      vsf?.refs?.clinicOutpRegisterMainRegisterForm?.getFieldsValue();

    if (!needAccept?.totalCharge) {
      message.error('请输入现金或使用其他方式支付');
      return;
    }

    if (
      Number(Number(needAccept?.totalCharge ?? 0).toFixed(2)) <
      Number(Number(payParamData?.selfPaymentTotalCost ?? 0).toFixed(2))
    ) {
      message.error('所收金额应大于等于应收金额');
      return;
    }

    const formData = {
      paymentDetail: [
        {
          paymentType: 'CASH',
          paymentAmount: subtract(
            Number(needAccept?.totalCharge ?? 0).toFixed(2),
            Number(needAccept?.remaining ?? 0).toFixed(2),
            2,
          ),
        },
      ],
      tallyIndicator: false,
      patientDisplayId: registerData?.patientDisplayId,
      outpPreSettleTradeId: payParamData?.outpPreSettleTradeId,
      totalCost: payParamData?.selfPaymentTotalCost,
      totalCharge: payParamData?.selfPaymentTotalCost,
      patientId: payParamData?.patientId,
      retrieveIndicator:
        appointmentStatus.clinicRegisterAppointInfoList && true,
    };

    if (appointmentStatus.clinicRegisterAppointInfoList) {
      if (appointmentStatus?.clinicRegisterAppointInfoList.length > 0) {
        formData['clinicRegisterAppointId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterAppointId;
        formData['clinicRegisterDetailTimeDivisionId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterDetailTimeDivisionId;
        formData['clinicRegisterDetailId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterDetailId;
      }
    }
    const res =
      await vsf.services?.ClinicOutpRegisterMainController_register_7c692c?.({
        outpSettleInputParameterEo: formData,
      });
    if (res.code === 200) {
      message.success('挂号成功');
      vsf?.refs?.PatientFullByDisplayForm?.resetFields();
      appointmentCallback(undefined);
      props.onChange();
      setOpen(false);
    }
  };

  const onModalCancel = () => {
    setOpen(false);
  };

  const getAppointment = (v) => {
    setRegisterData(v);
    const file = JSON.parse(JSON.stringify(v));
    //todo
    file.clinicRegisterAppointInfoList = [
      file.clinicRegisterAppointInfoList[0],
    ];
    setAppointmentStatus(file);
    appointmentCallback(file);
  };

  const onSubmit = async (params) => {
    message.destroy();
    const filed = JSON.parse(
      JSON.stringify(vsf?.refs?.PatientFullByDisplayForm?.getFieldsValue()),
    );

    const formData = {
      // ...registerData,
      patientDisplayId: filed?.patientDisplayId,
      chargeTypeCode: filed?.chargeTypeCode,
      identityCode: filed?.identityCode,
      clinicRegisterDetailId:
        registerData.clinicRegisterAppointInfoList[0]?.clinicRegisterDetailId ||
        selectNumberType?.id,
      cellphone: filed?.cellphone,
      currentAddress: registerData?.currentAddress,
      patientId: registerData?.patientId,
    };
    if (appointmentStatus.clinicRegisterAppointInfoList) {
      if (appointmentStatus?.clinicRegisterAppointInfoList.length > 0) {
        formData['clinicRegisterAppointId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterAppointId;
        formData['clinicRegisterDetailTimeDivisionId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterDetailTimeDivisionId;
        formData['clinicRegisterDetailId'] =
          appointmentStatus?.clinicRegisterAppointInfoList[0]?.clinicRegisterDetailId;
      }
    }

    if (!filed?.patientDisplayId) {
      message.error('请输入患者ID');
      return;
    }
    if (!formData?.clinicRegisterDetailId) {
      message.error('请选择号别');
      return;
    }
    if (filed?.chargeTypeCode !== '1') {
      message.error('请选择自费患者,其他患者暂不支持');
      return;
    }
    const res =
      await vsf.services?.ClinicOutpRegisterMainController_getRegisterPaymentInfo_8f478e?.(
        {
          outpApportionPreSettleEo: formData,
        },
      );
    if (res?.data) {
      setOpen(true);
      setPayParamData(res?.data);
    }
  };

  const onPressEnter = async (displayId) => {
    const res = await vsf.services
      ?.ClinicOutpRegisterMainController_getPatientInfo_b111a4?.({
        patientDisplayId: displayId,
      })
      .then((res) => {
        vsf?.refs?.PatientFullByDisplayForm.setFieldsValue({ ...res.data });
        setRegisterData(res?.data);
        if (res?.data?.clinicRegisterAppointInfoList.length) {
          setAppointmentStatus(true);

          Modal.confirm({
            title: (
              <span style={{ color: '#E34A4E' }}>
                该患者有未取号预约记录，是否取号？
              </span>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              getAppointment(res?.data);
            },
          });
        }

        return res?.data;
      });
    // if (!res) {
    //   vsf?.refs?.PatientFullByDisplayForm.resetFields();
    //   message.warn('请去主索引完善信息');
    // }

    // if (res?.clinicRegisterAppointInfoList) {
    //   setAppointmentStatus(true);

    //   Modal.confirm({
    //     title: (
    //       <span style={{ color: '#E34A4E' }}>
    //         该患者有未取号预约记录，是否取号？
    //       </span>
    //     ),
    // okText: '确定',
    // cancelText: '取消',
    //     onOk: () => {
    //       getAppointment();
    //     },
    //   });
    // }
  };

  return (
    <div className="patientfull-bydisplay-form">
      <VSForm
        id="PatientFullByDisplayForm"
        onSubmit={onSubmit}
        labelAlign="left"
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout
          key="1"
          // columnSpan={[6, 12, 6]}
          columnCount={3}
          labelWidth={labelNumberWidth(7)}
          // labelWidth={120}
          // gutter={150}
        >
          <VSFormItem
            name={['patientDisplayId']}
            label="患者ID"
            valueType="text"
            fieldProps={{
              onPressEnter: (e) => {
                onPressEnter(e.target.value);
              },
              suffix: (
                <div>
                  <Image
                    src={SerachIcon}
                    className="search_icon"
                    preview={false}
                    onClick={() => {
                      setIndexOpen(true);
                    }}
                  ></Image>
                </div>
              ),
              allowClear: false,
            }}
          />
          <VSFormItem name={['ident']} label="证件及编号" valueType="custom">
            <CertificateSearch
              onEnter={(event) => {
                const { identificationClassIn, identificationNumberIn } = event;
                // handelSearchIdentificationClass(
                //   identificationClassIn,
                //   identificationNumberIn,
                // );
                patientDetallRun({
                  identificationNumberIn: [identificationNumberIn],
                  identificationClassIn: [identificationClassIn],
                });
              }}
              style={{
                height: 20,
              }}
              inputProps={{
                // width: 690,
                width: '100%',
                // height: 10,
              }}
              selectProps={{
                width: 210,
              }}
            />
          </VSFormItem>

          <VSFormItem
            name={['chargeTypeCode']}
            label="费别"
            fieldProps={{ disabled: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            rules={[
              {
                message: '显示费别长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
          />
          {/* <CertificateSearch
            label="pp"
            
            onEnter={(event) => {
              const { identificationClassIn, identificationNumberIn } = event;
              // handelSearchIdentificationClass(
              //   identificationClassIn,
              //   identificationNumberIn,
              // );
              patientDetallRun({
                identificationNumberIn: [identificationNumberIn],
                identificationClassIn: [identificationClassIn],
              });
            }}
            inputProps={{
              width: 710,
            }}
            selectProps={{
              width: 240,
            }}
          /> */}
          {/* <VSFormItem
            name={['ident']}
            label="证件及编号"
            valueType="text"
            rules={[
              {
                pattern: idCard,
                message: '身份证格式不正确',
              },
            ]}
            fieldProps={{
              style: { paddingLeft: '10px' },
              addonBefore: (
                <>
                  <Select
                    style={{ width: '200px' }}
                    dataSource={certificateData}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(v) => {
                      setStandingType(v.name);
                    }}
                  />
                </>
              ),
              suffix: (
                <div
                  className="suffix-readCard"
                  onClick={() => {
                    message.success('读卡中');
                  }}
                >
                  <div className="suffix-cardImg">
                    <Image
                      src={readCard}
                      style={{ width: '20px', height: '20px' }}
                      preview={false}
                    ></Image>
                  </div>
                  <div className="suffix-cardText">读卡</div>
                </div>
              ),
              placeholder: '请读卡',

              onPressEnter: (e) => {
                if (standingType && e.target.value) {
                  patientDetallRun({
                    identificationNumberIn: [e.target.value],
                    identificationClassIn: [standingType],
                  });
                }
              },
            }}
          /> */}
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={4} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['name']}
            label="姓名"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: '姓名长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
          />
          <VSFormItem
            name={['gender']}
            label="性别"
            fieldProps={{ disabled: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
          />
          <VSFormItem
            name={['birthday']}
            label="出生日期"
            fieldProps={{ disabled: true }}
            valueType="date"
          />

          <VSFormItem
            name={['identityCode']}
            label="人员类别"
            fieldProps={{ disabled: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
          />
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={2} labelWidth={labelNumberWidth(7)}>
          <VSFormLayout
            key="26"
            columnCount={2}
            labelWidth={labelNumberWidth(7)}
          >
            <VSFormItem
              name={['patientProfileDto', 'currentAddress', 'code']}
              label="现住地址"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.AREA_DICT}
              fieldProps={{
                className: 'patientProfileDto_currentAddress_code',
                showSearch: true,
              }}
            />
            <VSFormItem
              name={['patientProfileDto', 'currentAddress', 'detail']}
              valueType="text"
              fieldProps={{
                style: {
                  className: 'patientProfileDto_currentAddress_detail',
                },
              }}
              dataSource={vsf.stores.dbenums.enums.AREA_DICT}
            />
          </VSFormLayout>
          <VSFormItem
            name={['cellphone']}
            label="手机号"
            valueType="text"
            rules={[
              {
                message: 'mobile_number长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ]}
            fieldProps={{
              className: 'patientProfileDto_cellphone',
            }}
          />
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={2} labelWidth={labelNumberWidth(7)}>
          <a
            onClick={() => {
              vsf?.navigateTo(`/index/patient-main-add?type=${'register'}`);
            }}
          >
            新建主索引
          </a>
          <div style={{ textAlign: 'right' }}>
            <Space>
              <Button>取消</Button>
              <Button
                onClick={() => {
                  vsf?.refs?.PatientFullByDisplayForm?.resetFields();
                }}
              >
                刷新
              </Button>
              <Button>病历本</Button>
              <Button
                type="primary"
                onClick={() => {
                  vsf?.refs?.PatientFullByDisplayForm?.submit();
                }}
                shape="default"
                size="medium"
              >
                挂号
              </Button>
            </Space>
          </div>
        </VSFormLayout>
      </VSForm>
      <Modal
        title={<div style={{ color: '#3276E8' }}>支付确认</div>}
        open={open}
        okText={'确定'}
        cancelText={'取消'}
        width={1000}
        className="demo1"
        onCancel={onModalCancel}
        footer={
          <div className="pay-confim-footer">
            <div>
              <Checkbox defaultValue>生成票据</Checkbox>
              <Checkbox>单位发票</Checkbox>
            </div>
            <div>
              <Space size={4}>
                <Button onClick={onModalCancel}>取消</Button>
                <Button type="primary" onClick={onModalSave}>
                  确认
                </Button>
              </Space>
            </div>
          </div>
        }
      >
        <PayModal
          data={appointmentStatus}
          payParamData={payParamData}
        ></PayModal>
      </Modal>
      <Modal
        open={indexOpen}
        title={'主索引查询'}
        width={isMinScreen ? 800 : 1600}
        onCancel={() => {
          setIndexOpen(false);
        }}
        bodyStyle={{
          height: isMinScreen ? 400 : 700,
        }}
        okText="确认"
        cancelText="取消"
      >
        <PrimaryIndexModal
          callback={(param) => {
            setIndexData(param);
            setRegisterData(param);
            setIndexOpen(false);
          }}
        ></PrimaryIndexModal>
      </Modal>
    </div>
  );
}

export default RegistratuonForm;
