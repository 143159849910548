import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getDiagnosisRecordByInp_7c3a6f: {
    method: 'post',
    url: '/api/clinic-record/get-diagnosis-record-by-inp',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosisRecordWithMainDiagnosisVoClinicRecordEntranceWebVo[],
    ) => data,
  },
  VisitHistoryController_saveDiagnosisRecord_aaf5cf: {
    method: 'post',
    url: '/api/clinic-record/save-diagnosis-record',
    parameterFomatter: (data?: {
      eo: SaveDiagnosisRecordEoClinicRecordPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  VisitHistoryController_deleteDiagnosisRecord_b9d3a4: {
    method: 'post',
    url: '/api/clinic-record/delete-diagnosis-record',
    parameterFomatter: (data?: {
      bto: DeleteDiagnosisRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordController_saveDiagnosisRecordInTypeList_d3794f: {
    method: 'post',
    url: '/api/clinic-record/save-diagnosis-record-in-type-list',
    parameterFomatter: (data?: {
      btoParamList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoClinicRecordEntranceWebVoDiagnosisrecordwithmaindiagnosisvo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
