import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPerformController_getOrderPerformPlanIndexVoById_936ff1: {
    method: 'post',
    url: '/api/clinic-record-order-perform/get-order-perform-plan-index-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformPlanIndexVoClinicRecordOrderPerformEntranceWebVo,
    ) => data,
  },
  ClinicRecordOrderPerformController_updateOrderPerformPlanStatus_c91f69: {
    method: 'post',
    url: '/api/clinic-record-order-perform/update-batch-order-perform-plan-status',
    parameterFomatter: (data?: {
      btoParamList: UpdateOrderPerformPlanStatusBtoClinicRecordOrderPerformServiceBto[];
      extApiParam: {
        skinTestResult: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BatchReturnResultEoClinicRecordOrderPerformPersistEo,
    ) => data,
  },
  AccountController_staffLoginWithoutResponseCookies_5fd9d3: {
    method: 'post',
    url: '/api/user-account/staff-login-without-cookies',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
