import './index.less';
import './convert';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  message,
  Modal,
  Row,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { useGetState, useLockFn, useRequest, useUpdateEffect } from 'ahooks';
import { Button } from 'antd';
import { Empty, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isMinScreen } from '@/config';
import Icon from '@/module/cpoe/Icon';
import Loading from '@/module/Loading';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import { add } from '@/utils/setDataUtil';
import storage from '@/utils/storage';

import {
  convertChargeSolutionItemListRo2SaveChargeSolutionItemListReq,
  convertGetChargeSolutionItemListRes2ChargeSolutionItemListRo,
  convertGetChargeSolutionListRes2ChargeSolutionSimpleList,
} from './convert';
import Selected from './project_select';

const Index = (props) => {
  const {
    stores: {
      user: { staffInfo },
    },
  } = props;
  // 收费方案项目停用弹窗
  const [open, setOpen] = useState(false);
  // 左侧table选中行
  const [selectedChargeSolutionRow, setSelectedChargeSolutionRow] =
    useState<any>();
  // 右侧table选中行
  const [selectedRow, setSelectedRow] = useState<any>();
  // 停用
  const [selectRows, setSelectRows] = useState<any>([]);
  // 左侧table
  const onFetch_t2513 = useCallback(async (params) => {
    setSelectedChargeSolutionRow(null);
    const res =
      await vsf?.services?.ChargeSolutionDtoController_getChargeSolutionList_114af0?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
          },
        },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertGetChargeSolutionListRes2ChargeSolutionSimpleList?.(res); // return {
    //   data: res?.data?.result ?? res?.data ?? [],
    //   total: res?.data?.count ?? res?.data?.length ?? 0
    // };
    return output;
  }, []);
  // 右侧table
  const onFetch_t1862 = useCallback(async (params) => {
    if (!params?.extra?.selectedChargeSolutionRow?.id) return [];
    const itemIdIs =
      params?.search?.itemIdIs ??
      params?.search?.itemInfo?.itemId ??
      params?.search?.itemInfo?.value;
    const res =
      await vsf?.services?.ChargeSolutionItemDtoController_getChargeSolutionItemList_09f8cd?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...(params ?? {}),
            chargeSolutionIdIs: params?.extra?.selectedChargeSolutionRow?.id,
            itemIdIs: itemIdIs?.length > 0 ? itemIdIs : null,
          },
        },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertGetChargeSolutionItemListRes2ChargeSolutionItemListRo?.(res)?.map(
        (i) => {
          return {
            ...i,
            reduceSequence:
              params?.extra?.selectedChargeSolutionRow?.reduceSequence,
          };
        },
      );
    setSelectRows([]);
    return {
      data: [...output] ?? [],
      total: output?.length ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 停用
  const handelStop = (v = false) => {
    setOpen(v);
  };
  // 保存
  const save = async () => {
    // TODO: 请确认入参来源及出参用法
    const output =
      convertChargeSolutionItemListRo2SaveChargeSolutionItemListReq?.(
        (vsf?.refs?.chargeSolutionList?.getValues?.() ?? [])?.map((row) => {
          if (String(row.id).startsWith('create_')) {
            const { id, ..._row } = row;
            return { ..._row, chargeSolutionId: selectedChargeSolutionRow?.id };
          } else {
            return { ...row, chargeSolutionId: selectedChargeSolutionRow?.id };
          }
        }),
      );
    if (output?.length == 0) return;
    const res =
      await vsf?.services?.ChargeSolutionItemBOController_saveChargeSolutionItemList_30322a?.(
        output,
      );
    if (res?.code == 200) {
      message.success('保存成功');
      vsf.refs?.chargeSolutionList?.reload();
    }
    return res?.data;
  };

  // const Selected: any = ({ recode, onChange, form, value }) => {
  //   const {
  //     data: dataSource,
  //     loading,
  //     run: onFetch,
  //   } = useRequest(
  //     async (param) => {
  //       const res =
  //         await vsf?.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
  //           {
  //             qto: {
  //               from: param?.pagination?.from ?? 0,
  //               size: param?.pagination?.size ?? 20,
  //               ...param?.search,
  //               ...param?.extra,
  //               ...(param ?? {}),
  //               inputCodeLike: param?.itemNameLike,
  //               inputCodeWubiLike: param?.itemNameLike,
  //             },
  //             isIncludeDrugOrHerb: true,
  //           },
  //         );
  //       if (res?.code === 200) {
  //         return res?.data?.result ?? [];
  //       } else {
  //         return [];
  //       }
  //     },
  //     {
  //       manual: true,
  //       debounceWait: 500,
  //     },
  //   );

  //   const ref = useRef<any>(null);

  //   useEffect(() => {
  //     if (String(recode?.record?.id)?.startsWith('create')) {
  //       ref?.current?.focus?.();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  //   return (
  //     <ComplexSelect
  //       mode="radio"
  //       showLabel="itemName"
  //       allowClear
  //       ref={ref}
  //       value={value?.itemId ?? null}
  //       onChange={(value) => {
  //         const key = recode.recordKey;

  //         onChange({
  //           ...value,
  //           itemName: value?.itemName,
  //           itemId: value?.itemId,
  //         });
  //       }}
  //       onSearch={(value) => {
  //         if (value.trim()) {
  //           onFetch({
  //             itemNameLike: value.trim(),
  //           });
  //         }
  //       }}
  //       hideSelectIcon
  //       dropdownMatchSelectWidth={false}
  //       dropdownStyle={{
  //         width: 700,
  //       }}
  //       placeholder="搜索项目名称/首字母"
  //       listHeight={1000}
  //     >
  //       <Table
  //         style={{
  //           width: 700,
  //         }}
  //         scroll={{
  //           y: 400,
  //         }}
  //         pagination={false}
  //         loading={{
  //           indicator: (
  //             <Row
  //               justify="center"
  //               style={{
  //                 left: '40%',
  //               }}
  //             >
  //               <Col span={24}>
  //                 <Loading />
  //               </Col>
  //             </Row>
  //           ),
  //           spinning: loading,
  //         }}
  //         // locale={{
  //         //   emptyText: (
  //         //     <Empty
  //         //       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
  //         //       imageStyle={{ height: 60 }}
  //         //       description="搜索价格项目名称/首字母"
  //         //     />
  //         //   ),
  //         // }}
  //         rowClassName="aspirin-table-row-box"
  //         dataSource={dataSource}
  //         size="large"
  //         // virtual
  //         columns={[
  //           {
  //             title: '项目名称',
  //             dataIndex: 'itemName',
  //             key: 'item_name',
  //             width: 100,
  //             ellipsis: true,
  //           },
  //           {
  //             title: '规格',
  //             dataIndex: 'itemSpecification',
  //             key: 'item_specification',
  //             width: 100,
  //             ellipsis: true,
  //           },
  //           {
  //             title: '标准编码',
  //             dataIndex: 'standardCode',
  //             key: 'standard_code',
  //             width: 100,
  //             ellipsis: true,
  //           },
  //         ]}
  //       />
  //     </ComplexSelect>
  //   );
  // };

  return (
    <div className="special_charge_project_scheme">
      <div className="special_charge_project_scheme_left">
        <VSPromiseTable
          pagination={false}
          id="chargeSolutionSimpleList"
          className="
          aspirin-table-header-background-color
         aspirin-table-row-hover-background-color
         aspirin-table-nopadding
         aspirin-table-body-empty-transparent-background-color
         "
          rowClassName={(record, rowIndex) => {
            return record?.id == selectedChargeSolutionRow?.id
              ? 'aspirin-table-row-select-background-color'
              : '';
          }}
          rowClick={{
            rowClickType: 'select',
            onRowClick: (_value) => {
              if (_value?.id == selectedChargeSolutionRow?.id) {
                setSelectedChargeSolutionRow(null);
              } else {
                setSelectedChargeSolutionRow({ ..._value });
              }
            },
          }}
          onFetch={onFetch_t2513}
          scroll={{
            y: isMinScreen ? getScrollY(200) : getScrollY(380),
          }}
        >
          <VSTableColumn
            dataIndex={['reduceSequence']}
            title="reduceSequence"
            valueType="text"
            fieldProps={{}}
            hideInTable
          />
          <VSTableColumn
            dataIndex={['chargeSolutionCode']}
            title="方案ID"
            valueType="text"
            fieldProps={{}}
            ellipsis
            width={50}
          />

          <VSTableColumn
            dataIndex={['chargeSolutionName']}
            title="方案名称"
            valueType="text"
            fieldProps={{}}
            width={100}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['solutionMemo']}
            title="方案说明"
            valueType="text"
            fieldProps={{}}
            width={100}
            ellipsis
          />
        </VSPromiseTable>
      </div>
      <div className="special_charge_project_scheme_right widthAutoFill">
        <div style={{ flex: 1 }} className="widthAutoFill">
          <VSPromiseTable
            className="
           aspirin-table-header-background-color
          aspirin-table-row-hover-background-color
          aspirin-table-nopadding
          aspirin-table-body-empty-transparent-background-color
          "
            rowKey="id"
            editable={{
              editType: 'single',
              saveOnClickOutside: true,
              onFieldChange: (key, value: any, record, form) => {
                if ('itemName' in value) {
                  form.setFieldValue(
                    [key, 'itemName'],
                    value?.itemName?.itemName,
                  );
                  form.setFieldValue([key, 'itemId'], value?.itemName?.itemId);
                  form.setFieldValue(
                    [key, 'itemClass'],
                    value?.itemName?.itemClass,
                  );
                  form.setFieldValue(
                    [key, 'itemSpecification'],
                    value?.itemName?.itemSpecification,
                  );
                  form.setFieldValue([key, 'price'], value?.itemName?.price);
                }
              },
              onCanEdit: () => false, // editText: <Icon type="icon-Frame-21" className="icon18" />,
              onCanDelete: (row) => String(row.id).startsWith('create_'),
              deleteText: <Icon type="icon-shanchuwudi" className="icon18" />,
              columnProps: {
                width: 90,
                fixed: 'right',

                hideInTable: true,
              },
              // onFieldChange: (key, value: any, record: any, form) => {},
            }}
            rowSelection={{
              selectType: 'multiple',
              selectedRows: selectRows,
              onSelectedRowsChange: setSelectRows,
            }}
            rowClassName={(record) => {
              return record?.id == selectedRow?.id
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            rowClick={{
              onRowClick: (_value) => {
                if (_value?.id == selectedRow?.id) {
                  setSelectedRow(null);
                } else {
                  setSelectedRow({ ..._value });
                }
              },
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record?.id == selectedRow?.id) {
                    setSelectedRow(null);
                  } else {
                    setSelectedRow({ ...record });
                  }
                },
                onDoubleClick: async () => {
                  const res = await vsf.refs.chargeSolutionList.saveEdit();
                  if (res) {
                    vsf.refs.chargeSolutionList.startEdit(record?.id);
                  }
                },
              };
            }}
            id="chargeSolutionList"
            pagination={false}
            searchConfig={{
              labelAlign: 'left',
              labelWidth: 110,
            }}
            scroll={{
              x: 1520,
              y: getScrollY(360),
            }}
            onFetch={onFetch_t1862}
            extraParams={{ selectedChargeSolutionRow }}
            onRemove={async (data) => {
              return true;
            }}
            // onRecord={async () => {
            //   return {
            //     id: `TEMP_${Math?.random?.()}`,
            //     // chargeSolutionId: selectedChargeSolutionRow?.id,
            //   };
            // }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title=""
              valueType="text"
              hideInTable
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['itemInfo']}
              title="项目名称"
              valueType="select"
              search
              order={0}
              columnKey={'itemInfo'}
              hideInTable
              fieldProps={{ showSearch: true }}
              renderFormItem={(schema, config, form, action) => {
                return (
                  <Selected
                    className="widthAutoFill"
                    form={form}
                    onChange={schema.originProps.onChange}
                    placeholder="请选择项目名称"
                    // value={config?.record?.itemInfo?.itemName}
                  />
                );
              }}
            />
            <VSTableColumn
              dataIndex={['itemIdIs']}
              title="项目ID"
              valueType="text"
              search
              columnKey={'itemIdIs'}
              hideInTable
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['itemId']}
              title="项目ID"
              valueType="text"
              fieldProps={{}}
              width={100}
              ellipsis
              preview
            />
            <VSTableColumn
              dataIndex={['itemClass']}
              title="类别"
              valueType="text"
              fieldProps={{}}
              preview
              width={70}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['itemName']}
              title="项目名称"
              valueType="select"
              fieldProps={{
                showSearch: true,
              }}
              width={150}
              ellipsis
              fieldNames={{ label: 'itemName', value: 'itemId' }}
              editable={(_, row) => {
                return String(row.id).startsWith('create_');
              }}
              dataSource={async (param) => {
                const res =
                  await vsf?.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
                    {
                      qto: {
                        from: param?.pagination?.from ?? 0,
                        size: param?.pagination?.size ?? 20,
                        ...param?.search,
                        ...param?.extra,
                        ...(param ?? {}),
                      },
                      isIncludeDrugOrHerb: true,
                    },
                  );
                return res?.data?.result;
              }}
              renderFormItem={(schema, config, form, action) => {
                return (
                  <Selected
                    form={form}
                    onChange={schema.originProps.onChange}
                    value={config?.record?.itemInfo?.itemName}
                    placeholder="请选择项目名称"
                  />
                );
              }}
              formItemProps={{
                rules: [{ required: true }],
              }}
            />
            <VSTableColumn
              dataIndex={['itemSpecification']}
              title="规格"
              valueType="text"
              fieldProps={{}}
              preview
              width={90}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              valueType="text"
              fieldProps={{}}
              preview
              width={80}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['calculationWay']}
              title=" 计算方式"
              valueType="codeTableSelect"
              dataSource={[
                { label: '固定金额', value: 'FIXED_AMOUNT' },
                { label: '收费系数', value: 'CHARGE_RATIO' },
              ]}
              fieldProps={{}}
              editable={(_, row) => {
                return row?.reduceSequence != 'AFTER_INSURANCE';
              }}
              formItemProps={{
                rules: [{ required: true }],
              }}
              width={80}
              ellipsis
            />
            {/* 收费系数时必填 */}
            <VSTableColumn
              dataIndex={['chargeRatio']}
              title="收费系数"
              valueType="digit"
              fieldProps={{}}
              formItemProps={(form, row) => {
                return {
                  rules: [
                    {
                      required:
                        form.getFieldsValue()?.[row?.entity?.id]
                          ?.calculationWay == 'CHARGE_RATIO',
                    },
                  ],
                };
              }}
              width={100}
              ellipsis
            />
            {/* 固定金额时必填 */}
            <VSTableColumn
              dataIndex={['specialPrice']}
              title="特殊价格"
              valueType="digit"
              fieldProps={{}}
              width={100}
              ellipsis
              formItemProps={(form, row) => {
                return {
                  rules: [
                    {
                      required:
                        form.getFieldsValue()?.[row?.entity?.id]
                          ?.calculationWay == 'FIXED_AMOUNT',
                    },
                  ],
                };
              }}
            />
            <VSTableColumn
              dataIndex={['freeLimit']}
              title="单次最高免除"
              valueType="digit"
              fieldProps={{}}
              ellipsis
              width={110}
            />
            <VSTableColumn
              dataIndex={['freeNumber']}
              title="单项最高免除"
              valueType="digit"
              width={110}
              fieldProps={{}}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['startDate']}
              title="启用时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              width={150}
              ellipsis
              initialValue={+new Date()}
              preview
            />
            <VSTableColumn
              dataIndex={['stopDate']}
              title="停用时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              width={150}
              ellipsis
              preview
            />
            <VSTableColumn
              dataIndex={['lastModifyStaffName']}
              title="最后修改人"
              valueType="select"
              fieldProps={{}}
              width={100}
              ellipsis
              preview
            />
            <VSTableColumn
              dataIndex={['lastModifyDate']}
              title="最后修时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              width={150}
              ellipsis
              preview
              initialValue={+new Date()}
            />
            {/* <VSTableColumn
              dataIndex={['lastModifyDate']}
              title="操作"
              valueType="custom"
              fixed="right"
              width={90}
              render={(_, record) => {
                return String(record.id).startsWith('create_') ? (
                  <Icon
                    type="icon-shanchuwudi"
                    className="icon18"
                    onClick={() => {
                      vsf.refs.chargeSolutionList?.remove(record.id);
                    }}
                  />
                ) : null;
              }}
              renderFormItem={(_, record) => {
                return String(record.record?.id).startsWith('create_') ? (
                  <Icon
                    type="icon-shanchuwudi"
                    className="icon18"
                    onClick={() => {
                      vsf.refs.chargeSolutionList?.remove(record.record);
                    }}
                  />
                ) : null;
              }}
            /> */}
            {/* <VSTableToolbar
              className="ant-pro-table-list-toolbar-padding"
              // title="收费方案"
            >
              <VSTableAddition children="添加" />
            </VSTableToolbar> */}
          </VSPromiseTable>
        </div>
        <div className="special_charge_project_scheme_bottom">
          <Button
            onClick={() => {
              vsf.refs?.chargeSolutionSimpleList?.reload();
              vsf.refs?.chargeSolutionList?.reload();
              vsf.refs?.chargeSolutionList?.getSearchForm()?.resetFields();
            }}
            type="primary"
            ghost
            children="清屏"
          />
          <Button
            onClick={() => {
              handelStop(true);
            }}
            disabled={selectRows?.length <= 0}
            ghost
            type="primary"
            children="停用"
          />
          <Button
            onClick={async () => {
              const res = await vsf.refs.chargeSolutionList.saveEdit();

              if (res) {
                save();
              }
            }}
            type="primary"
            children="保存"
            disabled={!selectedChargeSolutionRow?.id}
          />
          <Button
            onClick={async () => {
              if (!selectedRow?.id) {
                return message.info('请选择要编辑的收费方案项目');
              }
              const res = await vsf.refs.chargeSolutionList.saveEdit();
              if (res) {
                vsf.refs.chargeSolutionList.startEdit(selectedRow.id);
              }
            }}
            type="primary"
            children="编辑"
            disabled={!selectedRow?.id}
          />
          <Button
            onClick={async () => {
              const res = await vsf.refs.chargeSolutionList.saveEdit();
              if (res) {
                const _id = 'create_' + Math.random();
                vsf.refs.chargeSolutionList.add(
                  {
                    id: _id,
                    lastModifyStaffName: {
                      label: staffInfo?.staffName,
                      value: staffInfo?.id,
                    },
                    calculationWay:
                      selectedChargeSolutionRow?.reduceSequence ==
                      'AFTER_INSURANCE'
                        ? 'CHARGE_RATIO'
                        : null,
                    reduceSequence: selectedChargeSolutionRow?.reduceSequence,
                  },
                  'bottom',
                  true,
                );
              }
            }}
            disabled={!selectedChargeSolutionRow?.id}
            type="primary"
            children="新增"
          />
          <Button
            onClick={async () => {
              Modal.confirm({
                title: '删除收费方案项目',
                onOk: () => {
                  vsf.refs.chargeSolutionList?.remove(selectedRow);
                },
              });
            }}
            disabled={
              !selectedRow?.id ||
              (selectedRow.id && !String(selectedRow.id).startsWith('create_'))
            }
            type="primary"
            children="删除"
          />
        </div>
      </div>
      {/* 停用 */}
      <Modal
        centered
        open={open}
        title="收费方案项目停用"
        okText="确认"
        cancelText="取消"
        onCancel={() => {
          handelStop(false);
        }}
        onOk={async (values) => {
          // selectRows,
          const formValue =
            vsf.refs.stopChargeSolutionItemControlledForm.getFieldsValue();

          const res =
            await vsf?.services?.ChargeSolutionItemBOController_batchStopChargeSolutionItem_a24111?.(
              {
                chargeSolutionItemIdList: selectRows?.map((i) => i?.id),
                stopDate: formValue?.stopDate,
              },
            );
          if (res?.code == 200) {
            message.success('停用成功');
            handelStop(false);
            vsf.refs?.chargeSolutionList?.reload();
          }
        }}
      >
        <VSForm id="stopChargeSolutionItemControlledForm" labelAlign="left">
          <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[6]}>
            {/* <VSFormItem
              name={['itemName']}
              label="项目名称"
              readonly
              valueType="text"
              fieldProps={{}}
            /> */}

            <VSFormItem
              name={['stopDate']}
              label="停用时间"
              valueType="date"
              fieldProps={{
                disabledDate: (current) => {
                  return current && current < dayjs();
                },
              }}
            />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};

export default definePage(Index);
// 收费方案项目维护
