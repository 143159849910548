import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSDataProvider,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useState, useEffect } from 'react';

import vsf, { definePage } from '@vs/vsf-boot';
import { useRequest } from 'ahooks';

const LocalParameterEdit = (props) => {
  /**
   * param
   * @type {object}
   */
  const [param, setParam] = useState();

  useEffect(() => {
    hasId(props.value) &&
      vsf.services
        .LocalParameterConfigDtoServiceController_getLocalParameterById_c782e0({
          id: props?.value?.id,
        })
        .then((res) => {
          vsf.refs.LocalParameterForm.setFieldsValue({
            ...res.data,
          });
        });
  }, [props.value]);

  /**
   * haveId
   * @param {object} data
   * @returns {any}
   */
  const hasId = (data) => {
    return data?.id;
  };

  const {
    data: dataSource,
  } = useRequest(async () => {
        const res =
          await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
            {
              codeType: '000400',
            },
          );
        return res?.data?.map((item) => ({
          label: item?.name,
          value: item?.code
        }));
      })

  return (
    <Section title="">
      {/* {haveId(props.value) && (
        <VSDataProvider
          service={
            vsf.services
              .LocalParameterConfigDtoServiceController_getLocalParameterById_c782e0
          }
          params={props.value}
          onDataLoaded={(_value) => {
            setParam({ ..._value });
          }}
        />
      )} */}

      <VSControlledForm
        vsid="14942"
        id="LocalParameterForm"
        // value={param}
        // onChange={(_value) => {
        //   props.onChange?.(_value);
        // }}
        labelAlign="left"
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout labelWidth={120} columnCount={2} gutter={[150]}>
          <VSFormItem
            name={['classId']}
            label="配置分类"
            valueType="select"
            rules={[{ required: true }]}
            fieldProps={{
              showSearch: true,
            }}
            // dataSource={vsf.stores.dbenums?.enums?.SYS_CONFIG_TYPE}
            dataSource={dataSource}
          />

          <VSFormItem
            name={['name']}
            label="参数名"
            valueType="text"
            rules={[
              {
                message: 'local_config_name长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
              { required: true },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['cnName']}
            label="参数中文名"
            valueType="text"
            rules={[
              {
                message: 'local_config_cn_name长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['description']}
            label="参数描述"
            valueType="text"
            rules={[
              {
                message: 'local_config_description长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
              { required: true },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['valueType']}
            label="值类型"
            valueType="select"
            dataSource={[
              { label: 'Boolean', value: 'BOOL_TYPE' },
              { label: 'Integer', value: 'INTEGER_TYPE' },
              { label: 'FLoat', value: 'FLOAT_TYPE' },
              { label: 'String', value: 'STRING_TYPE' },
            ]}
            rules={[{ required: true }]}
            fieldProps={{
              showSearch: true,
            }}
          />

          <VSFormItem
            name={['scopeType']}
            label="范围类型"
            valueType="select"
            dataSource={[
              { label: '纯文字描述', value: 'TEXT_TYPE' },
              { label: '是/否', value: 'BOOL_TYPE' },
              { label: 'SQL语句（多项选择）', value: 'SQL_MULTIPLE' },
              { label: 'SQL语句（单项选择）', value: 'SQL_SINGLE' },
            ]}
            rules={[{ required: true }]}
            fieldProps={{
              showSearch: true,
            }}
          />
        </VSFormLayout>
        <VSFormLayout labelWidth={120} columnCount={1} gutter={[150]}>
          <VSFormItem
            name={['scopeList']}
            label="范围值"
            valueType="text"
            rules={[
              {
                message: 'local_scope_list长度不合法',
                type: 'string',
                min: 0,
                max: 1000,
              },
              { required: true },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout labelWidth={120} columnCount={2} gutter={[150]}>
          <VSFormItem
            name={['storageLocation']}
            label="存放位置"
            valueType="select"
            dataSource={[
              { label: '服务器', value: 'SERVER' },
              { label: '应用', value: 'LOCAL' },
              { label: '人员', value: 'PERSON' },
            ]}
            rules={[{ required: true }]}
            fieldProps={{
              showSearch: true,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(LocalParameterEdit);
