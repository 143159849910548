import React, { useCallback, useState } from 'react';
import NewDrawer from '@/pages/Index/components/new_drawer';
import {
  Section,
  VSPromiseTable,
  VSTableColumn,
  Button,
  Popover,
} from '@vs/vsf-kit';
function outdrawer({ callback }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const onHide = () => {
    setOpenDrawer(false);
  };
  const reload = () => {
    vsf?.refs?.ImportOutDrawerTable?.reload();
  };
  const onFetch = useCallback(async () => {
    const res =
      await vsf?.services?.DrugPrescriptionDispenseController_getWardWaitDispPatientCount_1af918?.(
        {},
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <>
      <NewDrawer
        open={openDrawer}
        width="20%"
        onOpen={() => setOpenDrawer(!openDrawer)}
        title={<div style={{ color: '#3276E8' }}>普通处方</div>}
        buttonTitle="普通处方"
        backgroundColor="var(--blue)"
        footer={
          <div className="aspirin-table-footer-button">
            <Button type="primary" onClick={reload}>
              刷新
            </Button>
            <Button type="primary" onClick={onHide}>
              隐藏
            </Button>
          </div>
        }
        isOther={true}
        buttonStyle={{
          top: '40%',
        }}
        drift="98px"
        style={{
          top: 104,
        }}
      >
        <VSPromiseTable
          vsid="99867"
          id="tableWardWaitDispPatientCount"
          onFetch={onFetch}
          onRow={(node) => {
            return {
              onClick() {
                vsf?.refs?.hempDetaillForm?.resetFields();
                callback(node?.wardId);

                onHide();
              },
              onDoubleClick() {
                vsf?.refs?.hempDetaillForm?.resetFields();
                callback(node?.wardId);

                onHide();
              },
            };
          }}
          pagination={false}
        >
          <VSTableColumn
            dataIndex={['wardName']}
            title="病区"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'ward_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['patientCount']}
            title="患者人数"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: 'patient_count的值不合法',
                  type: 'number',
                  min: 0,
                  max: undefined,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </NewDrawer>
    </>
  );
}

export default outdrawer;
