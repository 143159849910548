import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPerformController_getPagedByQueryOrderPerformPlanRecordQto_79ca66:
    {
      method: 'post',
      url: '/api/clinic-record-order-perform/get-paged-by-query-order-perform-plan-record-qto',
      parameterFomatter: (data?: {
        qto: OrderPerformPlanIndexQtoClinicRecordOrderPerformPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OrderPerformPlanIndexVoClinicRecordOrderPerformEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
