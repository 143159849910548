import React, { useCallback, useState } from 'react';
import NewDrawer from '@/pages/Index/components/new_drawer';
import {
  Section,
  VSPromiseTable,
  VSTableColumn,
  Button,
  Popover,
} from '@vs/vsf-kit';
import { convertQueryPatientsAwaitingDischargeRes2InpWaitSettlePatientListRo } from './Servce';
import { getScrollY, getScrollYRem } from '@/utils';
function outdrawer({ selectDrawerDataChange }) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const onHide = () => {
    setOpenDrawer(false);
  };
  const reload = () => {
    vsf?.refs?.inpWaitSettlePatientList?.reload();
  };

  return (
    <>
      <NewDrawer
        open={openDrawer}
        width="70%"
        onOpen={() => setOpenDrawer(!openDrawer)}
        title={<div style={{ color: '#3276E8' }}>待出院患者</div>}
        buttonTitle="待出院患者"
        backgroundColor="var(--blue)"
        footer={
          <div className="aspirin-table-footer-button">
            <Button type="primary" onClick={reload}>
              刷新
            </Button>
            <Button type="primary" onClick={onHide}>
              隐藏
            </Button>
          </div>
        }
        buttonStyle={{
          top: '20%',
        }}
        drift="98px"
        style={{
          top: 104,
        }}
      >
        <VSPromiseTable
          editable={{ editType: 'none' }}
          vsid="59876"
          id="inpWaitSettlePatientList"
          scroll={{
            // y: getScrollY(450)
            y: getScrollYRem(28),
          }}
          onFetch={async (params) => {
            if (openDrawer) {
              const res =
                await vsf?.services?.InpSettleController_queryPatientsAwaitingDischarge_1b0e1e?.(
                  {
                    displayId: params?.search?.displayId,
                    wardId: params?.search?.wardInfoList?.id,
                  },
                );
              const output =
                convertQueryPatientsAwaitingDischargeRes2InpWaitSettlePatientListRo?.(
                  res,
                );
              return {
                data: res?.data?.result ?? output ?? [],
                total: res?.data?.count ?? output?.length ?? 0,
              };
            } else {
              return {
                data: res?.data?.result ?? output ?? [],
                total: res?.data?.count ?? output?.length ?? 0,
              };
            }
          }}
          pagination={false}
          onRow={(node) => {
            return {
              onClick() {
                selectDrawerDataChange(node);
                onHide();
              },
              onDoubleClick() {
                selectDrawerDataChange(node);
                onHide();
              },
            };
          }}
        >
          //需求被干掉了
          <VSTableColumn
            dataIndex={['sas']}
            valueType="radioButton"
            search
            order={0}
            hideInTable
            fieldProps={{}}
            initialValue={'GENERAL_ANESTHESIA'}
            dataSource={[
              { label: '待出院', value: 'GENERAL_ANESTHESIA' },
              { label: '已挂账', value: 'LOCAL_ANESTHESIA' },
            ]}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 60 },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['displayId']}
            title="患者ID"
            valueType="text"
            search
            formItemProps={{
              rules: [
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['bedLabel']}
            title="床号"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['totalCost']}
            title="金额"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return Number(value?.totalCost).toFixed(2);
            }}
          />
          <VSTableColumn
            dataIndex={['prePaymentAmount']}
            title="预交金"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return Number(value?.prePaymentAmount).toFixed(2);
            }}
          />
          <VSTableColumn
            dataIndex={['selfPaymentTotalCost']}
            title="个人自付"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return Number(value?.selfPaymentTotalCost).toFixed(2);
            }}
          />
          <VSTableColumn
            dataIndex={['prePaymentBalance']}
            title="余额"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return Number(value?.prePaymentBalance).toFixed(2);
            }}
          />
          <VSTableColumn
            dataIndex={['dischargeDateTime']}
            title="出院日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />
          <VSTableColumn
            dataIndex={['departmentName']}
            title="护理单元"
            valueType="select"
            columnKey={'wardInfoList'}
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{
              dataSource: async (params) => {
                const res =
                  await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
                    {
                      searchCode: params?.searchCode,
                      clinicType: params?.clinicType,
                    },
                  );
                return res?.data?.result;
              },
              fieldNames: { label: 'departmentName', value: 'id' },
            }}
          />
          <VSTableColumn
            dataIndex={['departmentName']}
            title="病区"
            valueType="select"
            search
            columnKey={'wardInfoList'}
            hideInTable
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{
              dataSource: async (params) => {
                const res =
                  await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
                    {
                      searchCode: params?.searchCode,
                      clinicType: params?.clinicType,
                    },
                  );
                return res?.data?.result;
              },
              fieldNames: { label: 'departmentName', value: 'id' },
              showSearch: true,
              searchKey: 'searchCode',
            }}
          />
          <VSTableColumn
            dataIndex={['chargeType']}
            title="费别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            fieldProps={{ dataSource: vsf.stores.dbenums.enums.IDENTITY_DICT }}
          />
          <VSTableColumn
            dataIndex={['identityCode']}
            title="人员类别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
            fieldProps={{ dataSource: vsf.stores.dbenums.enums.IDENTITY_DICT }}
          />
          <VSTableColumn
            dataIndex={['admissionDateTime']}
            title="入院日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />
        </VSPromiseTable>
      </NewDrawer>
    </>
  );
}

export default outdrawer;
