/**
 * convertQueryWaitDispenseDrugDetailListRes2TableInpToxiWaitDispenseDetails
 * @param {any} input
 * @returns {any}
 */
export const convertQueryWaitDispenseDrugDetailListRes2TableInpToxiWaitDispenseDetails =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.bedLabel = _.get(d, 'inpVisit.patientInHospital.bed.bedLabel');
        obj.id = _.get(d, 'inpVisit.id');
        obj.inpVisitId = _.get(d, 'inpVisit.id');
        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.waitDispenseDetail = [];
        obj.gender = _.get(d, 'patient.gender');
        obj.birthday = _.get(d, 'patient.birthday');
        obj.waitDispenseDetail.push(
          ...(_.get(d, 'drugPrescriptionWaitDispenseDetail')?.map((d, i) => {
            const obj = {};
            obj.orderText = _.get(d, 'order.orderText');
            obj.amountAndPackageUnit = _.get(d, 'amountAndPackageUnit');
            obj.usageAndUnit = _.get(d, 'usageAndUnit');
            obj.administration = _.get(d, 'orderDrug.administration');
            obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
            obj.price = _.get(d, 'price');
            obj.supplyIndicator = _.get(d, 'supplyIndicator');
            obj.dangerIndicator = _.get(d, 'dangerIndicator');
            obj.isStockInsufficiency = _.get(d, 'isStockInsufficiency');
            obj.id = _.get(d, 'id');
            obj.prescriptionWaitDispenseId = _.get(
              d,
              'prescriptionWaitDispenseId',
            );
            obj.prescriptionDataTime = _.get(
              d,
              'orderDrug.prescription.prescriptionDataTime',
            );
            obj.prescriptionAttribute = _.get(
              d,
              'orderDrug.prescription.prescriptionAttribute',
            );
            return obj;
          }) ?? []),
        );
        return obj;
      }) ?? []),
    );
    return output;
    // return [
    //   {
    //     bedLabel: '01号',
    //     id: 123,
    //     inpVisitId: 123,
    //     name: '刘欢',
    //     displayId: '1000001231',
    //     waitDispenseDetail: [
    //       {
    //         id: 2312,
    //         orderText: 'orderText',
    //         amountAndPackageUnit: '1g',
    //         usageAndUnit: '2k',
    //         administration: 'chaine',
    //         frequency: '123',
    //         price: 100.0,
    //         supplyIndicator: true,
    //         dangerIndicator: true,
    //         isStockInsufficiency: false,
    //         prescriptionWaitDispenseId: 1213,
    //         prescriptionDataTime: '2024-01-12',
    //         prescriptionAttribute: 'prescriptionAttribute',
    //       },
    //       {
    //         id: 2313,
    //         orderText: 'orderText',
    //         amountAndPackageUnit: '1.2g',
    //         usageAndUnit: '2k',
    //         administration: 'chaine',
    //         frequency: '123',
    //         price: 100.0,
    //         supplyIndicator: true,
    //         dangerIndicator: true,
    //         isStockInsufficiency: false,
    //         prescriptionWaitDispenseId: 1214,
    //         prescriptionDataTime: '2024-01-12',
    //         prescriptionAttribute: 'prescriptionAttribute',
    //       },
    //     ],
    //   },
    //   {
    //     bedLabel: '01号',
    //     id: 1231,
    //     inpVisitId: 123,
    //     name: '刘欢',
    //     displayId: '1000001231',
    //     waitDispenseDetail: [
    //       {
    //         id: 2314,
    //         orderText: 'orderText',
    //         amountAndPackageUnit: '1g',
    //         usageAndUnit: '2k',
    //         administration: 'chaine',
    //         frequency: '123',
    //         price: 100.0,
    //         supplyIndicator: true,
    //         dangerIndicator: true,
    //         isStockInsufficiency: false,
    //         prescriptionWaitDispenseId: 1213,
    //         prescriptionDataTime: '2024-01-12',
    //         prescriptionAttribute: 'prescriptionAttribute',
    //       },
    //       {
    //         id: 2316,
    //         orderText: 'orderText',
    //         amountAndPackageUnit: '1.2g',
    //         usageAndUnit: '2k',
    //         administration: 'chaine',
    //         frequency: '123',
    //         price: 100.0,
    //         supplyIndicator: true,
    //         dangerIndicator: true,
    //         isStockInsufficiency: false,
    //         prescriptionWaitDispenseId: 1214,
    //         prescriptionDataTime: '2024-01-12',
    //         prescriptionAttribute: 'prescriptionAttribute',
    //       },
    //     ],
    //   },
    // ];
  };

/**
 * convertQuerySpecialToxiBedDropListQtoRes2TableInpToxiWaitDispenseDetails
 * @param {any} input
 * @returns {any}
 */
export const convertQuerySpecialToxiBedDropListQtoRes2TableInpToxiWaitDispenseDetails =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'inpVisit.id');
        obj.inpVisitId = _.get(d, 'inpVisit.id'); //住院流水号
        obj.bedLabel = _.get(d, 'inpVisit.patientInHospital.bed.bedLabel'); //床号
        obj.inpVisitNumber = _.get(d, 'inpVisit.inpVisitNumber'); //住院号
        obj.name = _.get(d, 'patient.name'); //姓名
        obj.displayId = _.get(d, 'patient.displayId'); //患者ID
        obj.gender = _.get(d, 'patient.gender'); //性别
        return obj;
      }) ?? []),
    );
    return output;
  };
/**
 * convertGetCurrentApplicationVsStaffWithStaffVoRes2FormStaff
 * @param {any} input
 * @returns {any}
 */
export const convertGetCurrentApplicationVsStaffWithStaffVoRes2FormStaff = (
  input,
) => {
  const _ = window._ ?? {};
  const output = ((input ?? {}).data ?? []).map((m) => ({
    prepareDrugStaffId: m.staff?.id,
    secondPrepareDrugStaffId: m.staff?.id,
    staffName: m.staff?.staffName,
  }));
  return output;
};

/**
 * convertFormStaff2DispensingInpPrescriptionsReq
 * @param {any} input
 * @returns {any}
 */
const convertFormStaff2DispensingInpPrescriptionsReq = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output = {}; // TODO 没有匹配到合适字段 output.dispensingParam.waitDispenseMasterId = null; // List<Long> 带发药主记录id
  output.prepareDrugStaffId = _.get(input, 'prepareDrugStaffId');
  output.secondPrepareDrugStaffId = _.get(input, 'secondPrepareDrugStaffId');
  return output;
};

//发药人
export const pharmaceutist = async (params) => {
  //
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf?.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  var output =
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormStaff?.(res);
  return output;
};

//发药
export const sendDrugReq = async (param) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_dispensingInpPrescriptions_ce193f?.(
      {
        dispensingParam: param,
      },
    );
  return res;
};
