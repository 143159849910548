import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getInpPreTransferPatientInfoVoById_101113: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-inp-pre-transfer-patient-info-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreTransferPatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_transferPatientOut_6a2980: {
    method: 'post',
    url: '/api/clinic-inp-adt/transfer-patient-out',
    parameterFomatter: (data?: {
      btoParam: TransferOutInpTransferRecordBtoClinicInpAdtServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpTransferRecordVoClinicInpVisitEntranceWebVoInppretransferpatientinfovo,
    ) => data,
  },
});
