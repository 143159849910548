import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getAllByDrugExportMasterForAccountantQto_3b2e58:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-all-by-drug-export-master-for-accountant-qto',
      parameterFomatter: (data?: {
        qto: DrugExportMasterForAccountantQtoDrugStorageImportExportPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugExportMasterWithReceiveDepartmentVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugStorageImportExportController_invalidDrugExportMaster_edb33f: {
    method: 'post',
    url: '/api/drug-storage-import/invalid-drug-export-master',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
