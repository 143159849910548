import {
  Button,
  Form,
  Image,
  TabPane,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { getScrollY } from '@/utils';
import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import IdentityDocuments from '@/pages/Index/components/identity_documents';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  currentRowId,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};
export default DraggableBodyRow;
