import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  Checkbox,
  VSPromiseTable,
  CheckboxState,
} from '@vs/vsf-kit';
import { useLockFn, useRequest, useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useRef, useState } from 'react';
import Block from '@/module/Block';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, removeObjectFalsy } from '@/utils';
import { getAllByDrugPriceAdjustMasterQtoDetails } from './services';
import { isEmpty, isNil, omitBy } from 'lodash';

const DrugPriceAdjustListDetail = (props) => {
  const { staffInfo } = props?.stores?.user;
  const invalid = useRef(null);
  const Footer = () => {
    /**
     * 作废
     */
    const onInvalid = useLockFn(async () => {
      Tip({
        content: `确定作废{ ${props?.Master?.id} }调价单据?`,
        async onOk() {
          const res =
            await vsf.services?.DrugPriceAdjustController_updateDrugPriceAdjustMaster_1a082d?.(
              {
                btoParam: {
                  id: props?.Master?.id,
                  state: 'CANCEL',
                  auditStaffId: staffInfo?.staffInfoNoteDto?.staffId,
                  auditTime: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(),
                  drugPriceAdjustDetailBtoList:
                    vsf?.refs?.drugPriceAdjustListDetail
                      ?.getValues()
                      ?.map((e) => ({
                        id: e?.id,
                        auditOpinion: !!e?.auditOpinion,
                      })),
                },
                extApiParam: {},
              },
            );
          if (res.code === 200) {
            vsf?.refs?.DrugPriceAdjustForm.resetFields();
            props?.setMaster();
            message.success('作废成功');
          }
        },
      });
    });

    /**
     * 执行调价
     */
    const onExecute = useLockFn(async () => {
      Tip({
        content: `确定作废{ ${vsf?.refs?.ExportRecord?.getFieldValue(
          'financeDocumentNumber',
        )} }待记账单据?`,
        async onOk() {},
      });
    });
    const button = [
      {
        text: '作废单据',
        ordinaryKey: 'D',
        methodKey: 'altKey',
        disabled: !props?.Master,
        onClick: onInvalid,
      },
      {
        text: '执行调价',
        ordinaryKey: 'A',
        methodKey: 'altKey',
        ref: invalid,
        disabled: true, //!props?.Master,
        onClick: onExecute,
      },
      {
        text: '关闭',
        ordinaryKey: 'C',
        methodKey: 'altKey',
        disabled: true,
      },
    ];

    return (
      <div className="aspirin-table-footer-button">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  const [inquire, setInquire] = useState();
  const onTableFetch = useCallback(
    async (params) => {
      if (!props?.Master?.id) return [];
      const FormParams = {};
      omitBy(
        removeObjectFalsy(vsf?.refs?.DrugPriceAdjustForm?.getFieldsValue()),
        (value, index) => {
          if (isNaN(value)) return;
          let res;
          switch (index) {
            case 'submitTimeRangeIn':
              res = { begin: value };
              break;
            case 'startDateRangeIn':
              res = { begin: value };
              break;
            default:
              res = value;
              break;
          }
          FormParams[index] = res;
        },
      );

      const res = await getAllByDrugPriceAdjustMasterQtoDetails({
        ...params,
        ...FormParams,
        drugStorageId: props?.Master?.drugStorageId,
      });
      return res?.data;
    },
    [props?.Master?.id, inquire],
  );

  const checkRef = useRef(null);
  const onHangedTableItemCheck = async (node) => {
    await vsf?.refs?.drugPriceAdjustListDetail.update(node);
    onHangedCheckRefState();
  };
  const onHangedTableTitleCheck = async ([state, event]) => {
    if (!event) return;
    const tableData = vsf?.refs?.drugPriceAdjustListDetail?.getValues();
    for (let i in tableData) {
      await vsf?.refs?.drugPriceAdjustListDetail.update({
        ...tableData[i],
        auditOpinion: state,
      });
    }
    onHangedCheckRefState();
  };
  const onHangedCheckRefState = async () => {
    const tableData = vsf?.refs?.drugPriceAdjustListDetail?.getValues();
    let number = 0;
    for (let i in tableData) {
      if (tableData?.[i]?.auditOpinion) {
        number++;
      }
    }
    if (number === 0) checkRef?.current?.setChecked(false);
    else if (number === tableData?.length) checkRef?.current?.setChecked(true);
    else if (number < tableData?.length)
      checkRef?.current?.setIndeterminate(true);
  };
  return (
    <Block footer={<Footer />} title="拟调价项目">
      <VSControlledForm
        id="DrugPriceAdjustForm"
        vsid="75421"
        className="aspirin-form drug_manager"
      >
        <VSFormLayout key="1" columnCount={4}>
          <VSFormItem
            name={['submitTimeRangeIn']}
            label="提交时间"
            valueType="aspirinDateTimePicker"
            fieldProps={{
              width: '100%',
              format: 'YYYY-MM-DD',
              valueType: 'timestamp',
              datePickerProps: {
                width: '100%',
              },
            }}
          />

          <VSFormItem
            name={['startDateRangeIn']}
            label="新价格启用日期"
            valueType="aspirinDateTimePicker"
            fieldProps={{
              width: '100%',
              format: 'YYYY-MM-DD',
              valueType: 'timestamp',
              datePickerProps: {
                width: '100%',
              },
            }}
          />
          <VSFormItem
            name={['adjustReasonIs']}
            label="调价依据"
            valueType="text"
          />
          <ButtonAndKeyDown
            ordinaryKey="Q"
            methodKey="altKey"
            key="查询"
            type="primary"
            children="查询"
            onClick={() => setInquire(Math?.random?.())}
          />
        </VSFormLayout>
      </VSControlledForm>
      <VSPromiseTable
        vsid="10956"
        id="drugPriceAdjustListDetail"
        className="aspirin-table-no-background-box aspirin-table aspirin-table-search-padding-no"
        scroll={{
          y: getScrollY(470),
        }}
        pagination={false}
        rowClassName={{}}
        onRow={() => {}}
        onFetch={onTableFetch}
      >
        <VSTableColumn
          valueType="index"
          render={(v) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width={50}
          preview
        />
        <VSTableColumn
          dataIndex={['drug_product_name']}
          title="药品名称"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['packageSpecification']}
          title="包装规格"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['firm_name']}
          title="厂家"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['packageUnit']}
          title="单位"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
        />
        <VSTableColumn
          dataIndex={['oldRetailPrice']}
          title="原零售价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['newRetailPrice']}
          title="新零售价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['auditOpinion']}
          title={() => (
            <Checkbox
              ref={checkRef}
              onChange={(...state) => onHangedTableTitleCheck(state)}
            >
              审核意见
            </Checkbox>
          )}
          valueType="text"
          fieldProps={{}}
          render={(_, { auditOpinion, ...reset }) => (
            <Checkbox
              value={auditOpinion}
              onChange={(state) =>
                onHangedTableItemCheck({ auditOpinion: state, ...reset })
              }
            >
              同意
            </Checkbox>
          )}
        />
      </VSPromiseTable>
    </Block>
  );
};
export default definePage(DrugPriceAdjustListDetail);
