import React from 'react';

import Defend from './Defend';
const AgreedPartPackage = () => {
  return (
    <Defend
      agreedPrescriptionIndicator
      showRadio={false}
      eliminates={['herb', 'import']}
      eliminateButtons={['保存']}
    />
  );
};

export default AgreedPartPackage;
