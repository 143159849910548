import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentDtoController_getById_3da0f7: {
    method: 'post',
    url: '/api/organization/getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentBOController_createDepartment_af7d4c: {
    method: 'post',
    url: '/api/organization/createDepartment',
    parameterFomatter: (data?: {
      btoParam: CreateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentDtoController_getAllByDepartmentQto_1c2a91: {
    method: 'post',
    url: '/api/organization/get-all-by-department-qto',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentBOController_updateDepartment_da3dee: {
    method: 'post',
    url: '/api/organization/updateDepartment',
    parameterFomatter: (data?: {
      btoParam: UpdateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentBOController_deleteDepartment_f91e76: {
    method: 'post',
    url: '/api/organization/deleteDepartment',
    parameterFomatter: (data?: {
      btoParam: DeleteDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicalInstitutionDtoController_getInstitutionById_5594f2: {
    method: 'post',
    url: '/api/organization/get-institution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo,
    ) => data,
  },
  ClinicInpVisitController_getInpVisitInHospitalWithBedVoById_0824de: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-inp-visit-in-hospital-with-bed-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitInHospitalWithBedVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
});
