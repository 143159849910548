import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitController_queryByOutpVisitRegisterRecordQtoPaged_d99a2f: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-by-outp-visit-register-record-qto-paged-by-outp-visit-register-record-qto',
    parameterFomatter: (data?: {
      qto: OutpVisitRegisterRecordQtoClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitRegisterRecordVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058: {
    method: 'post',
    url: '/api/organization/get-paged-by-department-id-and-search-code',
    parameterFomatter: (data?: { searchCode: string; departmentId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
