import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTable,
  VSTableToolbar,
  VSTableAddition,
  Tag,
  message,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';

import { getScrollY } from '@/utils';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import FunctionEdit from './edit';
import { isMinScreen } from '@/config';

const expandedRowRender = (record) => {
  // console.log(record, 'record');
  const dataSource =
    record?.applicationFunctionVsGroupWithFunctionDtoList ?? [];
  return (
    <VSTable
      bordered
      className=" aspirin-table aspirin-child-table aspirin-table-nopadding"
      value={dataSource}
      pagination={false}
      editable={{
        editType: 'none',
      }}
      showHeader={false}
      // scroll={{ y: 0 }}
    >
      <VSTableColumn
        dataIndex={['sort']}
        valueType="sort"
        fieldProps={{}}
        width={68}
      />
      <VSTableColumn
        title="序号"
        valueType="index"
        fieldProps={{}}
        width={268}
      />

      <VSTableColumn
        dataIndex={['function', 'name']}
        title="功能名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'function_name长度不合法',
              type: 'string',
              min: 0,
              max: 30,
            },
          ],
        }}
        fieldProps={{}}
        width={268}
      />

      <VSTableColumn
        dataIndex={['commonFunctionIndicator']}
        title="自启动"
        valueType="switch"
        fieldProps={{}}
        width={168}
      />

      <VSTableColumn
        dataIndex={['showType']}
        title="显示标识"
        valueType="select"
        fieldProps={{}}
        dataSource={[
          { label: '不显示', value: 'DISPLAY' },
          { label: '显示页面', value: 'PAGE' },
          { label: '	显示弹框', value: 'DIALOG' },
        ]}
      />

      {/* <VSTableColumn
        dataIndex={['functionSubGroup']}
        title="分组号"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['function', 'roleInfo']}
        title="角色"
        valueType="custom"
        fieldProps={{}}
        render={(dom, node) => {
          const roleList = node?.function?.roleInfo?.roleList || [];
          return (
            <div>
              {roleList?.map((item) => {
                return <Tag color="blue">{item?.name}</Tag>;
              })}
            </div>
          );
        }}
      /> 

      <VSTableColumn title="操作" valueType="text" fieldProps={{}} />*/}
    </VSTable>
  );
};

const StandardApplicationFuncitonEdit = (props) => {
  const { application } = props;
  /**
   * 转换
   * @param {object} value
   * @returns {object}
   */
  const valueToApplicationId = (value) => {
    if (!value) {
      return {};
    }
    return { applicationId: value?.id };
  };

  const linkPage = useCallback(
    () => <FunctionEdit application={application} />,
    [application],
  );

  const onFetch = useCallback(async (params) => {
    if (!params.extra?.applicationId) {
      return {
        data: [],
        total: 0,
      };
    } else {
      const res =
        await vsf?.services?.ApplicationFunctionGroupDtoController_getFunctionGroupByApplicationId_ddcfcd?.(
          { ...(params?.extra ?? {}) },
        );

      return {
        data: res?.data?.result?.toReversed() ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);
  const onRemove = async (params) => {
    const res =
      await vsf?.services?.ApplicationBOController_deleteFunctionGroup_e56168?.(
        {
          btoParam: {
            id: params?.applicationId,
            applicationFunctionGroupBtoList: [
              {
                id: params?.id,
              },
            ],
          },
        },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      vsf?.refs?.FunctionGroup?.reload();
    }
  };

  const onAdd = async (params) => {
    const roleList = (
      params?.applicationFunctionVsGroupWithFunctionDtoList || []
    )?.map((item) => {
      return {
        functionId: item?.functionId,
        applicationRoleIdList: item?.functionRoleBtoList,
      };
    });
    const res =
      await vsf?.services?.ApplicationBOController_createFunctionGroup_b538da?.(
        {
          btoParam: {
            id: props?.application?.id,
            applicationFunctionGroupBtoList: [
              {
                ...params,
                applicationFunctionVsGroupBtoList:
                  params?.applicationFunctionVsGroupWithFunctionDtoList?.map(
                    (item, index) => {
                      if (String(item?.id).startsWith('create'))
                        delete item?.id;
                      return {
                        ...item,
                        sortNumber: index + 1,
                      };
                    },
                  ),
              },
            ],
          },
          extApiParam: {},
          btoList: roleList,
        },
      );
    return res?.data;
  };

  const onUpdate = async (params) => {
    // console.log(params);
    const roleList = (
      params?.applicationFunctionVsGroupWithFunctionDtoList || []
    )?.map((item) => ({
      functionId: item?.functionId,
      applicationRoleIdList: item?.functionRoleBtoList,
    }));

    let applicationFunctionGroupBtoList = params;

    if (Array?.isArray(params)) {
      applicationFunctionGroupBtoList = params?.map((item, index) => ({
        ...item,
        sortNumber: index + 1,
      }));
    } else {
      applicationFunctionGroupBtoList = [
        {
          ...params,
          applicationFunctionVsGroupBtoList:
            params?.applicationFunctionVsGroupWithFunctionDtoList?.map(
              (item, index) => {
                if (String(item?.id).startsWith('create')) delete item?.id;
                return {
                  ...item,
                  sortNumber: index + 1,
                };
              },
            ),
        },
      ];
    }

    const res =
      await vsf?.services?.ApplicationBOController_updateFunctionGroup_1f6261?.(
        {
          btoParam: {
            id: props?.application?.id,
            applicationFunctionGroupBtoList,
          },
          extApiParam: {},
          btoList: roleList,
        },
      );
    if (res?.code === 200) {
      message?.success('保存成功');
      vsf?.refs?.FunctionGroup?.reload();
    }
    return res?.data;
  };

  return (
    <VSPromiseTable
      scroll={{
        y: getScrollY(350),
      }}
      className="table_overflow_358 aspirin-table aspirin-table-nopadding aspirin-tab-privilege aspirin-table-no-background-box"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        extraOperations: [
          {
            children: <Icon type="icon-shanchu" size={24} />,
            order: 1,
            onClick: (event, data) => {
              Tip({
                content: `是否删除数据：{${data.name}}`,
                onOk: async () => {
                  onRemove(data);
                },
              });
            },
          },
        ],
        disableDeleteConfirm: false,
        onCanDelete: () => false,
      }}
      vsid="74364"
      onFetch={onFetch}
      id="FunctionGroup"
      addLinkPage={{
        linkPage: linkPage,
        modalProps: {
          title: '功能设置',
          // className：aspirin-linkpage-static-height 不知道干吗用，注释了
          className: 'aspirin-linkpage',
          // style: {
          //   height: isMinScreen ? '70vh' : '40vh',
          // },
          className: isMinScreen ? 'modalHeight720' : 'modalHeight640',
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.FunctionForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        updateType: 'reload',
      }}
      onAdd={onAdd}
      updateLinkPage={{
        linkPage,
        modalProps: {
          title: '功能编辑',
          className: 'aspirin-linkpage',
          style: {
            // height: isMinScreen ? '70vh' : '40vh',
          },
          className: isMinScreen ? 'modalHeight720' : 'modalHeight640',
        },
        onLinkPageValidate: async (v) => {
          try {
            const data =
              await vsf.refs.FunctionForm?.validateFieldsReturnFormatValue();
            return data;
          } catch (e) {
            return false;
          }
        },
        updateType: 'reload',
      }}
      onUpdate={onUpdate}
      extraParams={valueToApplicationId(props?.application)}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => {
          return (
            record?.applicationFunctionVsGroupWithFunctionDtoList?.length > 0
          );
        },
      }}
      dragSort={{
        dragSortDataIndex: ['sort'],
        onDragSortEnd: (data) => {
          onUpdate(data);
        },
      }}
      pagination={false}
      searchConfig={{}}
    >
      <VSTableColumn
        dataIndex={['sort']}
        valueType="sort"
        fieldProps={{}}
        width={68}
      />
      {/* <VSTableColumn
        dataIndex={['sortNumber']}
        title="序号"
        valueType="index"
        fieldProps={{}}
        width={68}
      /> */}
      <VSTableColumn
        dataIndex={['name']}
        title="序号"
        valueType="text"
        fieldProps={{}}
        width={268}
      />
      <VSTableColumn
        title="功能名称"
        valueType="text"
        dataIndex={['']}
        formItemProps={{
          rules: [
            {
              message: 'function_name长度不合法',
              type: 'string',
              min: 0,
              max: 30,
            },
          ],
        }}
        fieldProps={{}}
        // width={268}
      />

      <VSTableColumn
        title="自启动"
        valueType="text"
        fieldProps={{}}
        width={168}
      />
      <VSTableColumn
        title="显示标识"
        valueType="text"
        formItemProps={{
          rules: [
            { message: 'name长度不合法', type: 'string', min: 0, max: 50 },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableToolbar title="">
        <VSTableAddition
          size="large"
          className="icon16"
          children="添加"
          disabled={!application?.id}
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(StandardApplicationFuncitonEdit);
