import '../index';

import { DoubleRightOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Image, Modal, VSTable, VSTableColumn } from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import ArrowsImg from '@/assets/arrows_img.png';
import Cdss from '@/module/cpoe/Cdss';
import Empty from '@/module/Empty';
import { Determine } from '@/pages/Index/components';
import Block from '@/pages/Index/components/block_no_padding';

import Tendency from '../tendency';
import {
  checkBasicInfo,
  checkFloatList,
  examineBasicInfo,
  examineFloatList,
  herbsBasicDetail,
  herbsBasicInfo,
  herbsFloatList,
  westernMedicineAndChinesePatentMedicineBasicInfo,
  westernMedicineAndChinesePatentMedicineFloatList,
} from './index';

const Base = ({ list }) => {
  const dataSource = [
    { label: '开立', value: 'SAVE' },
    { label: '提交', value: 'SUBMIT' },
    { label: '取消提交', value: 'CANCEL_SUBMIT' },
    { label: '作废', value: 'INVALID' },
    { label: '撤销', value: 'REVOKE' },
    { label: '停止', value: 'STOP' },
    { label: '护士校对', value: 'NURSE_CHECK' },
    { label: '取消校对', value: 'CANCEL_CHECK' },
    { label: '退费', value: 'REFUND' },
    { label: '收费', value: 'CHARGE' },
  ];
  return list?.map((item, index) => {
    const time = item?.operateTime;
    return (
      <div key={item?.id} className="item" style={{ width: '100%' }}>
        <div className="img">
          <div className="title">
            {dataSource?.find(({ value }) => value === item?.operateType)
              ?.label ?? ''}
          </div>
          <div className="time">
            {time ? dayjs(time)?.format('YYYY-MM-DD HH:mm:ss') : ''}
          </div>
          <div className="name">{item?.operatorName}</div>
        </div>
        <Determine condition={index !== list?.length - 1}>
          <div className="arrows">
            <Image preview={false} width={33} height={33} src={ArrowsImg} />
          </div>
        </Determine>
      </div>
    );
  });
};

// 西药/中成药
const Index = (props) => {
  const { detail, type } = props;
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};
  const [open, setOpen] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState(true);
  const [herbCurrrentItem, setherbCurrrentItem] = useState<number>();
  // 检查报告列表
  const [reportDetail, setReportDetail] = useState<any>();
  const [reportList, setReportList] = useState<any>();
  // 分析数据
  const [analysisData, setAnalysisData] = useState<any>();

  const _patientId = currentPatient?.patientId;

  useEffect(() => {
    const tempList = cloneDeep(detail?.patientJourneyOrderExamReportList);
    const _arr = tempList?.map((item) => {
      return {
        ...item,
        show: false,
      };
    });
    setReportDetail(_arr);
  }, [detail]);

  const handelHearder = (item, v) => {
    if (item?.value === 'orderDoctorName') {
      return detail?.orderDoctor?.name;
    } else if (item?.value === 'mainDiagnose') {
      return detail?.treat?.mainDiagnose;
    } else {
      return detail?.[item?.value];
    }
  };

  const handelBasic = (extar?) => {
    // 西药/中成药
    if (type === 'DRUG') {
      return {
        basicInfo: westernMedicineAndChinesePatentMedicineBasicInfo,
        floatList: westernMedicineAndChinesePatentMedicineFloatList,
      };
    } else if (type === 'HERB' && extar === 'detail') {
      return {
        basicInfo: herbsBasicDetail,
        floatList: [],
      };
    } else if (type === 'HERB') {
      // 中草药
      return {
        basicInfo: herbsBasicInfo,
        floatList: herbsFloatList,
      };
    } else if (type === 'LAB') {
      // 检验
      return {
        basicInfo: examineBasicInfo,
        floatList: examineFloatList,
      };
    } else if (type === 'EXAM') {
      // 检查
      return {
        basicInfo: checkBasicInfo,
        floatList: checkFloatList,
      };
    } else {
      return { basicInfo: [], floatList: [] };
    }
  };

  const handelShow = (index?) => {
    const tempList = cloneDeep(reportDetail);
    const _arr = tempList?.map?.((item, _idx) => {
      return {
        ...item,
        show: _idx === index ? !item.show : item.show,
      };
    });
    setReportDetail(_arr);
  };

  const getAnalysisData = async (row, time?) => {
    console.log(_patientId);

    const res = await vsf?.services?.PatientJourneyController_analysis_61065d({
      patientId: _patientId,
      qto: {
        resultDateTimeRangeIn: {
          begin: time?.start,
          end: time?.end,
          beginInclude: true,
          endInclude: true,
        },
        reportItemCodeIs: row?.reportItemCode,
      },
    });
    if (res?.code === 200) {
      setAnalysisData(res?.data);
    }
  };

  const renderMedicalInsuranceType = (type) => {
    if (type === 'ALPHA') {
      return '甲';
    }
    if (type === 'BETA') {
      return '乙';
    }
    if (type === 'GAMMA') {
      return '丙';
    }
  };

  const dataSource = [
    { label: '保存', value: 'SAVE' },
    { label: '提交', value: 'SUBMIT' },
    { label: '取消提交', value: 'CANCEL_SUBMIT' },
    { label: '作废', value: 'INVALID' },
    { label: '撤销', value: 'REVOKE' },
    { label: '停止', value: 'STOP' },
    { label: '护士校对', value: 'NURSE_CHECK' },
    { label: '取消校对', value: 'CANCEL_CHECK' },
    { label: '退费', value: 'REFUND' },
    { label: '收费', value: 'CHARGE' },
  ];
  const operationLogCommonVoList =
    detail?.orderLogWithStaffList?.[0]?.operationLogCommonVoList?.[0] || [];
  return (
    <div className="timeline-detail">
      {/* 基础信息 */}
      {detail?.orderVoList?.map((item) => {
        return (
          <div key={item?.id} className="info">
            {handelBasic()?.basicInfo?.map((v, _index) => {
              return (
                <div key={_index} className="item">
                  <span className="label">{v?.label}</span>
                  <span className="value">{item?.[v?.value ?? '']}</span>
                </div>
              );
            })}
          </div>
        );
      })}
      <div className="content">
        {/* 流程图 */}
        {handelBasic()?.floatList?.length > 0 && (
          <div
            style={{
              height: handelBasic()?.floatList?.length > 5 ? '300px' : '160px',
            }}
            className="flow_chart"
          >
            <Determine
              condition={
                detail?.orderLogWithStaffList?.[0]?.operationLogCommonVoList
                  ?.length
              }
              fallback={<Empty />}
            >
              <Base
                list={
                  detail?.orderLogWithStaffList?.[0]
                    ?.operationLogCommonVoList ?? []
                }
              />
            </Determine>
          </div>
        )}
        {/* 检验表格 */}
        {type === 'LAB' && (
          <div
            className="items"
            style={{
              borderTop: '1px solid #d6d8d9',
            }}
          >
            <VSTable
              scroll={{
                y: 436,
              }}
              className="aspirin-table aspirin-table-nopadding"
              editable={{
                editType: 'none',
              }}
              value={detail?.patientJourneyOrderLabResultList}
              pagination={false}
            >
              <VSTableColumn
                valueType="index"
                fieldProps={{}}
                editable={false}
                width={40}
              />
              <VSTableColumn
                dataIndex={['reportItemName']}
                title="项目"
                valueType="text"
                fieldProps={{}}
                width={250}
                // render={(_, data) => {
                //   return <div>{data?.reportItem?.assayItemName}</div>;
                // }}
              />

              <VSTableColumn
                dataIndex={['result']}
                title="结果"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['resultReference']}
                title="参考范围"
                valueType="text"
                fieldProps={{}}
                // render={(_, data) => {
                //   return (
                //     <div style={{ color: '#3276E8' }}>
                //       {data?.reportItem?.isNumber
                //         ? `${data?.reportItem?.limitStart}-${data?.reportItem?.limitEnd}`
                //         : data?.reportItem?.descr}
                //     </div>
                //   );
                // }}
              />

              <VSTableColumn
                dataIndex={['unit']}
                title="单位"
                valueType="text"
                fieldProps={{}}
                // render={(_, data) => {
                //   return data?.reportItem?.unit;
                // }}
              />

              <VSTableColumn
                dataIndex={['abnormalSignature']}
                title="异常结果"
                valueType="text"
                fieldProps={{}}
                render={(_, data) => {
                  return (
                    <div style={{ color: '#E34A4E' }}>
                      {data?.abnormalSignature}
                    </div>
                  );
                }}
              />

              {/* <VSTableColumn
                dataIndex={['a5']}
                title="危急值"
                valueType="text"
                fieldProps={{}}
                render={() => {
                  return '差字段';
                }}
              /> */}

              <VSTableColumn
                dataIndex={['a6']}
                title="操作"
                valueType="text"
                fieldProps={{}}
                render={(_, data) => {
                  const pattern = new RegExp('[\u4E00-\u9FA5]+');

                  return !pattern.test(data?.result) ? (
                    <Cdss
                      title="智能助手(检测报告)"
                      placement="left"
                      data={data}
                    >
                      <Button
                        type="text"
                        style={{
                          color: '#3276E8',
                        }}
                        // onClick={() => {
                        //   setOpen(true);
                        // }}
                      >
                        分析
                      </Button>
                    </Cdss>
                  ) : (
                    ''
                  );
                }}
              />
            </VSTable>
          </div>
        )}
        {/* 检查报告 */}
        {type === 'EXAM' && reportDetail?.length > 0 && (
          <div>
            {reportDetail?.map((item, index) => {
              return (
                <Block
                  key={index}
                  titleBackgroundColor="#DBEDFF"
                  title={'报告' + (index + 1)}
                  titleStyle={{ marginLeft: '10px' }}
                  extra={
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '10px',
                      }}
                    >
                      <div className="timeline-detail_checked_detail_view_img">
                        查看图像
                      </div>
                      <DoubleRightOutlined
                        className={className(
                          item?.show
                            ? 'timeline-detail_checked_detail_double_up_outlined'
                            : 'timeline-detail_checked_detail_double_down_outlined',
                        )}
                        style={{ color: '#3276E8' }}
                        onClick={() => {
                          handelShow(index);
                          // setIsShowDetail(!isShowDetail);
                        }}
                      />
                    </div>
                  }
                >
                  <div
                    style={{
                      display: item?.show ? 'block' : 'none',
                      padding: '0 35px',
                    }}
                  >
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        检查所见:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.description}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        印象:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {/* 两侧胸廓对称，气管居中，两肺纹理增多，两肺多发散在斑片状高密度影，右上为著，可见空洞形成。气管及主要支气管通畅。纵隔淋巴结肿大。胸腔内未见积液征。心景未见异常。左侧第3、4、6肋骨陈旧性骨折。 */}
                        {item?.impression}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        建议:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.advise}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        检查参数:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.examParameter}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        是否阳性:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.positiveIndicator ? '是' : '否'}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        使用仪器:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.device}
                      </div>
                    </div>
                    <div className="timeline-detail_checked_detail_content">
                      <div className="timeline-detail_checked_detail_content_label">
                        报告中图像编号:
                      </div>
                      <div className="timeline-detail_checked_detail_content_content">
                        {item?.useImage}
                      </div>
                    </div>
                  </div>
                </Block>
              );
            })}
          </div>
        )}
        {/* 中草药 */}
        {type === 'HERB' && (
          <div className="herbcontent">
            <div className="title">
              <span>中汤药剂明细</span>
              <span className="number">
                (共{detail?.patientJourneyOrderHerbDetailList?.length ?? 0}味药)
              </span>
            </div>
            <div className="info" style={{ border: 'none' }}>
              {handelBasic('detail')?.basicInfo?.map((v, _index) => {
                return (
                  <div key={_index} className="item">
                    <span className="label">{v?.label}</span>
                    <span className="value">{detail?.[v?.value ?? '']}</span>
                  </div>
                );
              })}
            </div>
            {/* detail?.patientJourneyOrderHerbDetailList?.length>0&& */}
            <div className="list">
              {detail?.patientJourneyOrderHerbDetailList?.map((item, index) => {
                return (
                  <div
                    // className={className('item', {
                    //   ['active-item']: herbCurrrentItem === index,
                    // })}
                    className={className('item', 'active-item')}
                    key={index}
                    onClick={() => {
                      setherbCurrrentItem(index);
                    }}
                  >
                    <div
                      className="index"
                      style={{ flex: 30, textAlign: 'center' }}
                    >
                      {index + 1}
                    </div>
                    <div className="name" style={{ flex: 160 }}>
                      {item?.drugProductName}名字
                    </div>
                    <div className="text" style={{ flex: 56 }}>
                      {item?.totalAmount}
                    </div>
                    <div className="text" style={{ flex: 56 }}>
                      {item?.useDescription}
                    </div>
                    <div className="text" style={{ flex: 90 }}>
                      {item?.price ?? ''}元/
                      {renderMedicalInsuranceType(item?.medicalInsuranceType)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
