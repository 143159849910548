import { useRequest, useSafeState } from 'ahooks';
import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSFormItem,
  Image,
  Select,
  withRef,
  VSTable,
  VSForm,
} from '@vs/vsf-kit';
import { getScrollY } from '@/utils';
import Icon from '@/module/cpoe/Icon';
import readCard from '@/assets/icon/readCard.png';
import vsf, { definePage } from '@vs/vsf-boot';
import classNames from 'classnames';
import { isMinScreen } from '@/config';

const PatientMergeSearch = React.forwardRef((props, ref) => {
  const [tableData, setTableData] = useState([]);
  const insuranceTypeIsRef = useRef();
  const [selectIds, setSelectIds] = useState();
  const firstFetch = useRef(true);

  useEffect(() => {
    onFetch();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      refresh: handelSearch,
      setSelectRows: (_selectIds) => {
        setSelectIds(_selectIds);
      },
    };
  });

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  /**
   * convertVSPromiseTablePatientListQro2QueryPagedByPatientFullQtoReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSPromiseTablePatientListQro2QueryPagedByPatientFullQtoReq = (
    input,
  ) => {
    const _ = window._ ?? {};
    const searchParam = {};
    searchParam.statusIs = 'VALID';
    searchParam.displayIdIs = _.get(input, 'displayIdIs');
    searchParam.nameLike = _.get(input, 'nameLike');
    searchParam.genderIs = _.get(input, 'genderIs');
    searchParam.cellphoneIs = _.get(input, 'cellphoneIs');
    const birthdayIs = _.get(input, 'birthdayRangeIn');
    if (birthdayIs) {
      searchParam.birthdayRangeIn = {};
      searchParam.birthdayRangeIn.begin = birthdayIs;
      searchParam.birthdayRangeIn.end = birthdayIs;
      searchParam.birthdayRangeIn.beginInclude = true;
      searchParam.birthdayRangeIn.endInclude = true;
    }
    if (input?.ident?.identificationClassIn === '患者ID') {
      searchParam.displayIdIs = input?.ident?.identificationNumberIn;
      searchParam.identificationClassIn = undefined;
      searchParam.identificationNumberIn = undefined;
    } else {
      searchParam.identificationClassIn = input?.ident?.identificationClassIn
        ? [input?.ident?.identificationClassIn]
        : undefined;
      searchParam.identificationNumberIn = input?.ident?.identificationNumberIn
        ? [input?.ident?.identificationNumberIn]
        : undefined;
    }

    const _searchParam = {};
    for (const key in searchParam) {
      _searchParam[key] = !!searchParam[key] ? searchParam[key] : undefined;
    }
    return _searchParam;
  };
  /**
   * convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo = (
    input,
  ) => {
    const _ = window._ ?? {};
    const customResult = input?.map((item) => ({
      id: _.get(item, 'id'),
      displayId: _.get(item, 'displayId'),
      name: _.get(item, 'name'),
      gender: _.get(item, 'gender'),
      age: _.get(item, 'age'),
      cellphone: _.get(item, 'cellphone'),
      idNumber: _.get(item, 'idNumber'),
      patientMergeWithProfileDtoList: _.get(
        item,
        'patientMergeWithProfileDtoList',
      ),
      isMerged: _.get(item, 'isMerged'),
    }));
    return customResult;
  };

  const onFetch = useCallback(async (params) => {
    if (!firstFetch.current) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // TODO: 你需要自己补充入参input来源及出参output用法
      const searchParam =
        convertVSPromiseTablePatientListQro2QueryPagedByPatientFullQtoReq(
          params?.search,
        ); // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              orderList: params?.orderList,
              ...searchParam,
              ...params?.filters,
              ...(params?.extra ?? {}),
              // ...(params ?? {}),
            },
            ext: {},
          },
        );
      const customResult =
        convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
          res?.data?.result,
        );
      console.debug(customResult); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      const originData = customResult ?? res?.data ?? [];
      const realData = cloneDeep(originData);
      originData?.forEach((item, _idx) => {
        if (item?.patientMergeWithProfileDtoList?.length) {
          realData.splice(
            realData?.findIndex((v) => v?.id === item?.id) + 1,
            1,
            ...convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
              item?.patientMergeWithProfileDtoList?.map((v) => ({
                ...v?.patientRelated,
                isMerged: true,
              })),
            ),
          );
        }
      });
      setTableData(
        realData?.map((item) => ({
          ...item,
          retainIndicator: !!item?.patientMergeWithProfileDtoList?.length,
          rowKey: Math.random(),
        })),
      );
    }
    firstFetch.current = false;
  }, []);

  const handelIdentChange = (e) => {
    insuranceTypeIsRef.current = e.code;
  };

  const handelSearch = async (e) => {
    const searchParams = await vsf.refs?.vSPromiseTablePatientList
      .getSearchForm()
      .getFieldFormatValue();
    onFetch({ search: searchParams });
  };

  const handelReset = async () => {
    await vsf.refs?.vSPromiseTablePatientList.getSearchForm().resetFields();
    insuranceTypeIsRef.current = undefined;
    handelSearch();
  };

  return (
    <>
      {/* <VSForm>
      <VSFormItem 
        label='证件及编号'
        name={'indent'}
      />
    </VSForm> */}
      <VSTable
        value={tableData}
        // pagination={false}
        // vsid="65260"
        // scroll={{
        //   y: getScrollY(500),
        //   x: 700
        // }}
        rowKey={'rowKey'}
        pagination={{
          defaultPageSize: 20,
        }}
        // className='aspirin-table'
        id="vSPromiseTablePatientList"
        // onFetch={onFetch}
        rowSelection={{
          selectedRows: selectIds,
          selectType: 'multiple',
          getCheckboxProps: (record) => {
            return {
              style: {
                visibility: record?.isMerged ? 'hidden' : 'visible',
              },
            };
          },
          onSelectedRowsChange: (rows) => {
            setSelectIds(rows);
          },
        }}
        searchConfig={{
          labelWidth: isMinScreen ? 70 : 100,
          labelAlign: 'left',
          span: 8,
          searchButtonProps: {
            onClick: handelSearch,
            size: 'large',
            className: 'icon16',
          },
          resetButtonProps: {
            onClick: handelReset,
            size: 'large',
            className: 'icon16',
          },
        }}
        scroll={{ y: 0 }}
        onSearch={() => true}
        className="table_overflow_480"
      >
        <VSTableColumn
          search
          hideInTable
          dataIndex={['ident']}
          columnKey={'ident'}
          title="证件及编号"
          valueType="certificateSearch"
          formItemProps={{
            style: {
              // width: 536,
            },
            className: isMinScreen ? 'width550' : 'width571',
          }}
          fieldProps={{
            classNames: '',
            selectProps: {
              className: 'width164',
              // width: 140,
            },
            inputProps: {
              onPressEnter: handelSearch,
            },
          }}
        />

        <VSTableColumn
          dataIndex={['nameLike']}
          title="姓名"
          valueType="text"
          search
          order={0}
          columnKey={'nameLike'}
          hideInTable
          formItemProps={{
            rules: [
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{
            onPressEnter: handelSearch,
          }}
        />

        <VSTableColumn
          dataIndex={['displayIdIs']}
          title="患者ID"
          valueType="text"
          search
          order={0}
          columnKey={'displayIdIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{
            onPressEnter: handelSearch,
          }}
        />

        <VSTableColumn
          dataIndex={['genderIs']}
          title="性别"
          valueType="select"
          search
          order={0}
          columnKey={'genderIs'}
          hideInTable
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          fieldProps={{
            onPressEnter: handelSearch,
          }}
        />

        <VSTableColumn
          dataIndex={['cellphoneIs']}
          title="手机号"
          valueType="text"
          search
          order={0}
          columnKey={'cellphoneIs'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{
            onPressEnter: handelSearch,
          }}
        />

        <VSTableColumn
          dataIndex={['birthdayRangeIn']}
          title="出生日期"
          valueType="date"
          search
          order={0}
          columnKey={'birthdayRangeIn'}
          hideInTable
          searchConfig={{
            transform: (values) => {
              if (!Array.isArray(values)) return values;
              return {
                birthdayRangeIn: {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              };
            },
          }}
          fieldProps={{
            format: 'YYYY-MM-DD HH:mm:ss',
            onPressEnter: handelSearch,
          }}
        />

        <VSTableColumn
          width={114}
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  display: 'flex',
                  background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                  color: record?.isMerged ? '#85898D' : '',
                }}
              >
                {dom}
                {record?.patientMergeWithProfileDtoList?.length ? (
                  <div style={{ color: '#3F76C8' }}>
                    <Icon type="icon-chengzulianjie" size={16} />
                  </div>
                ) : null}
              </div>
            );
          }}
        />

        <VSTableColumn
          ellipsis
          width={70}
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={70}
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={70}
          ellipsis
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
                height: 46,
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={144}
          dataIndex={['cellphone']}
          title="手机号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />

        <VSTableColumn
          width={230}
          dataIndex={['idNumber']}
          title="身份证号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => (
            <div
              style={{
                background: record?.isMerged ? 'rgba(0,0,0,0.04)' : '',
                color: record?.isMerged ? '#85898D' : '',
              }}
            >
              {dom}
            </div>
          )}
        />
      </VSTable>
    </>
  );
});

export default withRef()(PatientMergeSearch);
