import { createServices } from '@vs/vsf-boot';
export default createServices({
  PrivilegeController_searchCandidateStaff_e2616c: {
    method: 'post',
    url: '/api/application-privilege/search-candidate-staff',
    parameterFomatter: (data?: {
      departmentId: number;
      name: string;
      applicationId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDepartmentDtoUserStaffManagerDto[],
    ) => data,
  },
  PrivilegeController_deleteApplicationStaffWithRole_7de462: {
    method: 'post',
    url: '/api/application-privilege/delete-application-staff-with-role',
    parameterFomatter: (data?: {
      bto: DeleteStaffWithRoleBtoApplicationPrivilegeServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_createOrUpdateApplicationStaffWithRole_0bd2f6: {
    method: 'post',
    url: '/api/application-privilege/create-or-update-application-staff-with-role',
    parameterFomatter: (data?: {
      btoList: CreateStaffWithRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_queryApplicationStaffWithRoleList_8aadd4: {
    method: 'post',
    url: '/api/application-privilege/query-application-staff-with-role-list',
    parameterFomatter: (data?: {
      qto: ApplicationVsStaffQtoApplicationPrivilegePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVsStaffDetailVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
});
