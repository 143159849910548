import './index.less';
import '@/pages/Index/cpoe/center/main/index.less';

import type { VSTableInstance } from '@vs/vs-table';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Checkbox,
  List,
  message,
  Popover,
  Select,
  Tooltip,
  VSTable,
  VSTableColumn,
  VSTableEditable,
  VSTableProps,
} from '@vs/vsf-kit';
import { useGetState, useMount } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, {
  cloneElement,
  forwardRef,
  Fragment,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { BlockPropsType } from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import type { DictionaryProps } from '@/pages/Index/components/dictionary/index';
import Chm from '@/pages/Index/cpoe/center/main/chm';
import Dispose from '@/pages/Index/cpoe/center/main/dispose';
import Exam from '@/pages/Index/cpoe/center/main/exam';
import Operation from '@/pages/Index/cpoe/center/main/operation';
import Pathology from '@/pages/Index/cpoe/center/main/pathology';
import Test from '@/pages/Index/cpoe/center/main/test';
import { getScrollY } from '@/utils';
import { useClickPreventionOnDoubleClick } from '@/utils/hooks';

import Determine from '../determine';
import { askFixed, askGroupMedicalOrders, askSearch } from '../func/ask';
import { StateValueType } from '../func/ask/askGroupMedicalOrders';
import { useGetResetInitState, useOnChangeValue } from '../func/hooks';
import Tip from '../func/Tip';
import Fetch from './fetch';

type Footer = {
  /**
   * 插入
   * @returns {void}
   */
  onInsert: () => void;
  /**
   * 成组
   * @param value
   * @returns {void}
   */
  onGroup: (value: any) => void;
  /**
   * 取消成组
   * @returns {void}
   */
  onCancelGroup: () => void;
  /**
   * 控制取消成组是否禁用
   * @returns {boolean}
   */
  cancelGroupDisabled: () => boolean;
  /**
   * 控制是否可以插入
   * @returns {boolean}
   */
  insertDisabled: () => boolean;
  state: any;
};

type StateValue = {
  groupIndex: number[];
  groupName: string;
};

enum Eliminate {
  orderStatus,
  drugOrder_orderStatus,
  drugOrder_startTime,
  orderClass,
  orderText,
  drugOrder_dosage,
  drugOrder_usage,
  drugOrder_administration,
  drugOrder_dripSpeed,
  drugOrder_frequency,
  drugOrder_userDescription,
  drugOrder_performDays,
  drugOrder_allAmount,
  drugOrder_injectionAmount,
  drugOrder_totalAmount,
  drugOrder_administrationAmount,
  drugOrder_skinTestType,
  performDepartment,
  allPrice,
  memo,
  doctor,
  drugOrder_orderTime,
  drugOrder_endTime,
  submitDateTime,
  /**
   * 编辑
   */
  edit,
  check,
}

type LabProps = {
  showLab?: boolean;
};

type OrderTableProps = DictionaryProps & {
  flagSource?: string;
  onChange?: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  prefixCls?: string;
  tableProps?: VSTableProps<any, any>;
  blockProps?: BlockPropsType;
  onRemove?: (params) => Promise<any>;
  onCancelRequest?: (params) => Promise<any>;
  onUnStopRequest?: (params) => Promise<any>;
  onUndoRequest?: (params) => Promise<any>;
  hospitalType?: 'out' | 'in';
  dragSort?: boolean;
  eliminate?: {
    /**
     * 抛弃一些字段
     */
    eliminates: (keyof typeof Eliminate)[];
    /**
     * 控制正反
     */
    type?: boolean;
  };
  /**
   * 插入新字段
   */
  splice?: {
    splices: [];
    /**
     * 位置
     */
    index: keyof typeof Eliminate;
  };
  isRowSelect?: boolean;
  labProps?: LabProps;
  filterParams?: any;
  skinTest?: boolean;
};

export interface Ref {
  onOpen: (value?: never) => void;
}

export type TableRefs = VSTableInstance<Value, 'text'> | null;

enum method {
  onExam,
  onTest,
  onChm,
  onOperation,
  onPathology,
  onDispose,
}

export type OrderTableRef = {
  [key in keyof typeof method]: Ref['onOpen'];
} & {
  onAdd: (value?: never) => void;
  setValue: (state) => void;
  getValue: () => any;
};

type Value = SaveOrderEoClinicRecordOrderPersistEo &
  Omit<StateValueType, 'id'> & {
    uuid: string;
    groupIndex: number[];
    groupName: string;
    group: never;
  };

const OrderTable = (props: OrderTableProps, ref) => {
  const {
    header,
    footer,
    extra,
    // prefixCls,
    tableProps: inputTableProps,
    blockProps,
    onDrugFetch,
    onDrugDetailsFetch,
    showDepartment,
    showStorage,
    eliminate,
    onRemove,
    onCancelRequest,
    onUnStopRequest,
    onUndoRequest,
    hospitalType = 'out',
    dragSort = false,
    isRowSelect = true,
    labProps,
    filterParams,
    skinTest,
    flagSource,
  } = props;

  const [value, setValue, getValue] = useGetState<Value[]>([]);
  // eslint-disable-next-line @typescript-eslint/ban-types
  // const [state, setState, restState] = useResetState<any>({});
  const _state = useRef<any>({});
  const state = _state?.current;
  const onEditingRowsChange = useRef<React.Key[]>([]);
  const group = useRef<any>({});
  useMount(() => {
    group.current = askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['drugOrder', 'administration'],
        ['drugOrder', 'dripSpeed'],
        ['drugOrder', 'frequency'],
        ['drugOrder', 'useDescription'],
        ['drugOrder', 'performDays'],
        ['repeatValidDays'],
      ],
      tip({ onOk, onCancel }) {
        Tip({
          content: '当前医嘱与上一条医嘱不一致，是否成组',
          onOk,
          onCancel,
        });
      },
      disables(one, two) {
        return [
          (() => {
            const init = [one?.repeatIndicator, two?.repeatIndicator]?.filter(
              (item) => typeof item === 'boolean',
            );
            if (
              one?.repeatIndicator !== two?.repeatIndicator &&
              init?.length === 2
            ) {
              message?.warning('长期和临时医嘱不能成组');
            }
            return (
              one?.repeatIndicator === two?.repeatIndicator ||
              init?.length !== 2
            );
          })(),
        ];
      },
    });
  });

  /**
   * 操作 handle
   */
  const testRef = useRef<Ref>();
  const examRef = useRef<Ref>();
  const chmRef = useRef<Ref>();
  const operationRef = useRef<Ref>();
  const pathologyRef = useRef<Ref>();
  const disposeRef = useRef<Ref>();
  const tableRef = useRef<TableRefs>(null);
  const footerRef = useRef<any>(null);

  const getGroup = useCallback(async (data) => {
    const value: any = Object?.values(
      tableRef?.current?.getEditForm()?.getFieldsValue(true),
    )?.at(-1);
    const id = Object?.keys(
      tableRef?.current?.getEditForm()?.getFieldsValue(),
    )?.at(-1);

    // const __value: any = getValue()?.find((item) => item?.id == id) ?? {};
    const __info__ = data ?? {
      id,
      ...value,
      // ...__value,
      // drugOrder: {
      //   ...value?.drugOrder,
      //   ...(__value?.drugOrder ?? {}),
      //   // drugPriceItemId: (getValue()?.find((item) => item?.id == id) ?? {})
      //   //   ?.drugOrder?.drugPriceItemId,
      // },
    };
    // console.log(value);

    const drugItemId =
      __info__?.drugOrder?.drugPriceItemId ??
      __info__?.orderText?.drugPriceItemId;

    let res: any = {};
    if (drugItemId) {
      res =
        await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
          {
            drugItemId,
            patientId: 1001,
            departmentId: 193,
            staffId: vsf?.stores?.user?.staffInfo?.id,
          },
        );
    }
    const init = res?.data
      ? {
          amountPerPackage: res?.data?.amountPerPackage ?? 0,
          dosagePerUnit: res?.data?.dosagePerUnit ?? 0,
          roundingType: res?.data?.roundingType ?? 'BY_AMOUNT',
        }
      : {};
    return {
      ...__info__,
      ...init,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 成组
   * @param data
   */
  const onGroup = useCallback(
    async (data?: any) => {
      const form = tableRef?.current?.getEditForm();
      const [key] = Object?.keys(form?.getFieldsValue());
      const save = await form?.validateFields([
        [key, 'drugOrder', 'usage'],
        [key, 'drugOrder', 'dosage'],
      ]);
      if (save) {
        const value = await getGroup(data);
        // console.log(value);
        const and = await group.current?.onGroup(value, getValue());
        if (and) {
          const slice = group.current?.onSliced(and);
          const edit = slice?.find((item) => item?.id == key);
          form?.setFieldValue(
            [key ?? '', 'drugOrder', 'totalAmount'],
            edit?.drugOrder?.totalAmount,
          );
          setValue(slice);
          new Promise((resolve, reject) => {
            const save = tableRef?.current?.cancelEdit();
            save ? resolve(save) : reject(save);
          }).then(() => {
            form?.resetFields();
          });
        }
      }
    },
    [getGroup, setValue, getValue],
  );

  /**
   * 取消成组
   */
  const onCancelGroup = useCallback(async (init) => {
    const [, data] = group.current.onCancelGroup(
      getValue(),
      init ?? _state?.current,
    );
    // setValue(data);
    for (const item of data) {
      tableRef?.current?.update(item);
    }
    _state.current = {};
    footerRef?.current?.setState({});
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 禁用/启用取消成组
   * @returns
   */
  const cancelGroupDisabled = useCallback((state) => {
    return !state?.groupIndex?.length || state?.orderStatus === 'SUBMIT';
  }, []);

  /**
   * 插入一条数据
   */
  const onInsert = useCallback(
    async (_info?: any) => {
      const list = value as any;
      const data = {
        id: 'create' + Math.random(),
        uuid: getUUID(),
        orderStatus: 'OPEN',
        is_one: true,
        orderClass: 'DRUG',
      };
      const name = value?.findIndex((item) => item?.id === _info?.id);
      if (
        _info?.drugOrder?.groupSubNumber &&
        _info?.drugOrder?.groupSubNumber !== 1
      ) {
        const value = list?.toSpliced(name, 0, data);
        const and = await group.current?.intoGroups(
          await getGroup(data),
          value,
          'splice',
        );
        if (and) {
          setValue(group.current?.onSliced(and));
          setTimeout(() => {
            tableRef?.current?.startEdit(data?.id);
          }, 200);
        }
      } else {
        setValue(list?.toSpliced(name, 0, data));
        setTimeout(() => {
          tableRef?.current?.startEdit(data?.id);
        }, 200);
      }
    },
    [getGroup, group, setValue, value],
  );

  /**
   * 控制是否可以插入
   */
  const insertDisabled = useCallback(() => {
    if (state) {
      return (
        state?.drugOrder?.groupSubNumber !== 1 &&
        state?.groupIndex?.length >= 5 &&
        state?.orderClass !== 'SUBMIT'
      );
    }
    return true;
  }, [state]);

  const onAdd = async (isOther?: boolean) => {
    const record = {
      id: 'create' + Math.random(),
      uuid: getUUID(),
      orderStatus: 'OPEN',
      is_one: true,
      isOther,
    };
    const save = await tableRef?.current?.saveEdit();
    if (save) {
      tableRef?.current?.add(record as any, undefined, true)?.then(() => {
        tableRef?.current?.startEdit(record?.id);
      });
    }
  };

  /**
   * 导出复用方法
   */
  useImperativeHandle<any, OrderTableRef>(ref, () => ({
    onAdd: () => {
      onAdd();
    },
    onExam: () => examRef?.current?.onOpen(),
    onTest: () => testRef?.current?.onOpen(),
    onOperation: () => operationRef?.current?.onOpen(),
    onPathology: () => pathologyRef?.current?.onOpen(),
    onDispose: () => disposeRef?.current?.onOpen(),
    onChm: () => chmRef?.current?.onOpen(),
    onOther: () => {
      onAdd(true);
    },
    getValue,
    setValue: (value) => {
      setValue(group.current?.onSliced(value));
    },
    onInsert,
    ...tableRef?.current,
  }));

  /**
   * 表格单行操作: 双击
   */
  const doubleClick = async ([target]) => {
    // const [target] = data;

    const {
      examOrder,
      herbOrder,
      labOrder,
      orderClass,
      operationOrder,
      pathologyOrder,
    } = target || {};
    if (target?.orderStatus === 'SUBMIT') {
      message?.warning('已提交无法修改');
      return;
    }
    const METHOD = {
      DRUG: async () => {
        tableRef?.current?.saveEdit()?.then((res) => {
          if (res) {
            tableRef?.current?.startEdit(target?.id);
          } else {
            tableRef?.current?.getEditForm()?.validateFields();
          }
        });
      },
      HERB: () => {
        chmRef?.current?.onOpen({
          ...herbOrder,
          performDepartment: target?.performDepartment,
          uuid: target?.uuid,
        });
      },
      LAB: () => {
        testRef?.current?.onOpen({
          ...labOrder,
          allPrice: target?.allPrice,
          performDepartment: target?.performDepartment,
          uuid: target?.uuid,
        });
      },
      EXAM: () => {
        examRef?.current?.onOpen({
          uuid: target?.uuid,
          ...examOrder,
          patientSpecialInfo: target?.patientSpecialInfo,
          performDepartment: target?.performDepartment,
          orderDiagnosisIdList: target?.orderDiagnosisIdList,
        });
      },
      OPERATION: () => {
        operationRef?.current?.onOpen({
          uuid: target?.uuid,
          ...operationOrder,
          orderDiagnosisIdList: target?.orderDiagnosisIdList,
          patientSpecialInfo: target?.patientSpecialInfo,
        });
      },
      PATHOLOGY: () => {
        pathologyRef?.current?.onOpen({
          uuid: target?.uuid,
          ...pathologyOrder,
          performDepartment: target?.performDepartment,
          orderDiagnosisIdList: target?.orderDiagnosisIdList,
        });
      },
    };
    if (METHOD?.[orderClass]) {
      METHOD?.[orderClass]?.();
    } else {
      tableRef?.current?.saveEdit();
      tableRef?.current?.startEdit(target?.id);
    }
  };

  /**
   * 单击方法
   * @param data
   */
  const click = useCallback(
    async (data) => {
      const save = Boolean(onEditingRowsChange?.current?.length);
      if (!save && isRowSelect) {
        const [id] = data;
        _state.current = id;
        footerRef?.current?.setState?.(_state?.current);
        const dom = document?.querySelector(`[data-row-key="${id?.id}"]`);
        new Promise((resolve, reject) => {
          try {
            dom?.parentNode
              ?.querySelector('.aspirin-table-row-active')
              ?.classList?.remove('aspirin-table-row-active');
            resolve(true);
          } catch (error) {
            reject(error);
          }
        })?.then(() => {
          dom?.classList?.add('aspirin-table-row-active');
        });
      }
    },
    [isRowSelect],
  );

  const [onClick, onDoubleClick] = useClickPreventionOnDoubleClick(
    click,
    doubleClick,
  );

  /**
   * 抽屉操作: 提交数据到单行
   */
  const onDrawerSubmit = useCallback(
    (value: Value) => {
      const target = (getValue() ?? []) as Value[] as any;
      const index = (target ?? [])?.findIndex(
        (item) => item?.uuid === value?.uuid,
      );
      const id = 'create' + Math?.random();
      if (Array?.isArray(value)) {
        if (
          value
            ?.map?.((item) =>
              Object?.keys(item).some((value) => /^(.*)Order$/.test(value)),
            )
            ?.includes(true)
        ) {
          setValue([
            ...target,
            ...value?.map((item) => {
              return {
                id: 'create' + Math?.random(),
                uuid: getUUID(),
                orderStatus: 'OPEN',
                ...item,
              };
            }),
          ]);
        } else {
          // const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
          //   (item) => {
          //     return {
          //       ...item,
          //       ...JSON?.parse(item?.extensionJson ?? '{}'),
          //     };
          //   },
          // )?.find(({ frequency }) => frequency === 'once');
          const items = value?.map((item, index) => {
            const order = preview(void 0, {
              orderClass: item?.orderClass,
            })
              ? 'disposalOrder'
              : item?.orderClass?.toLocaleLowerCase() + 'Order';
            return {
              id: 'create' + Math?.random(),
              uuid: getUUID(),
              orderStatus: 'OPEN',
              orderText: item?.orderText,
              orderClass: item?.orderClass,
              performDepartment: item?.performDepartment,
              drugStorageId: item?.drugStorageId,
              ...(index === 0
                ? {
                    relatedType: 'SKIN_TEST_VS_DRUG',
                  }
                : {
                    relatedType: 'SKIN_TEST_VS_DRUG',
                  }),
              [order]: {
                ...item,
              },
              ...(order === 'disposalOrder'
                ? {
                    drugOrder: {
                      totalAmount: {
                        value: item?.amount,
                      },
                    },
                  }
                : {}),
            };
          });

          setValue(
            target?.toSpliced(
              index,
              1,
              ...group?.current?.onSliced(
                items?.map((item, _index) => ({
                  ...item,
                  ...(_index === 0
                    ? {
                        _skin: item?.id,
                        is_one: true,
                        repeatIndicator: (
                          Object?.values(
                            tableRef?.current?.getEditForm()?.getFieldsValue(),
                          )?.[0] as any
                        )?.repeatIndicator,
                      }
                    : {
                        repeatIndicator: false,
                        // parentRelatedUuid: items?.[0]?.uuid,
                        parentRelatedUuid: items?.[0]?.uuid,
                      }),
                  /**
                   * 皮试关联关系
                   */
                  _skinTest: items?.map((item) => item?.id),
                })),
              ),
            ),
          );
          new Promise((resolve, reject) => {
            setTimeout(() => {
              try {
                resolve(tableRef?.current?.startEdit?.(items?.[0]?.id));
              } catch (error) {
                reject(error);
              }
            }, 500);
          }).then(() => {
            tableRef?.current
              ?.getEditForm()
              ?.getFieldInstance([items?.[0]?.id, 'drugOrder', 'dosage'])
              ?.focus();
          });
        }
      } else {
        if (index !== -1) {
          setValue(
            target?.toSpliced(index, 1, {
              ...target[index],
              ...value,
            }),
          );
        } else {
          setValue([...target, { id, ...value }]);
        }
      }
    },
    [getValue, setValue],
  );

  // 渲染高危图标
  const renderHighriskIcon = (record) => {
    const { drugOrder } = record;
    const node = (
      <Popover content="高危">
        <Icon
          type="icon-gaowei"
          size={20}
          style={{
            marginRight: 4,
          }}
        />
      </Popover>
    );
    if (drugOrder?.drugDictionary?.dangerIndicator) {
      return node;
    } else {
      return null;
    }
  };

  // 渲染加急图标
  const renderUrgentIcon = (record) => {
    const { examOrder, labOrder, pathologyOrder, operationOrder } = record;
    const node = (
      <Icon
        type="icon-a-Frame1517"
        size={20}
        style={{
          marginRight: 4,
        }}
      />
    );
    if (
      examOrder?.emergencyIndicator ||
      labOrder?.emergencyIndicator ||
      operationOrder?.operationApplyType === 'EMERGENCY' ||
      pathologyOrder?.emergencyIndicator
    ) {
      return node;
    } else {
      return null;
    }
  };

  const render = useCallback((...args) => {
    const [, record] = args;
    const content = record?.orderText?.drugName ?? record?.orderText;
    let name = (
      <Popover title="详细信息" content={content} placement="right">
        <div className={classnames('aspirin-order-text', record?.groupName)}>
          {renderUrgentIcon(record)}
          {renderHighriskIcon(record)}
          <span>
            {record?.drugOrder?.prescriptionIndicator ||
            record?.drugOrder?.prescriptionId
              ? `[处方] ${content}`
              : content}
          </span>
        </div>
      </Popover>
    );
    switch (record?.orderClass) {
      case 'EXAM':
        name = (
          <Popover
            placement="right"
            title={
              <div className="aspirin-title-popover-box">
                检查
                <span> {record?.examOrder?.examOrderDetail?.length} </span>
                项详情
              </div>
            }
            content={
              <List
                size="small"
                dataSource={record?.examOrder?.examOrderDetail}
                renderItem={(item: any, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '8px 0',
                        width: '100%',
                      }}
                    >
                      <Alert
                        type="info"
                        style={{
                          width: '100%',
                        }}
                        message={`${index + 1}. ${item?.examItemName}`}
                      />
                    </List.Item>
                  );
                }}
              />
            }
            trigger="hover"
          >
            <div
              className={classnames('aspirin-order-text', record?.groupName)}
            >
              {renderUrgentIcon(record)}

              <span>{record?.orderText?.drugName ?? record?.orderText}</span>
            </div>
          </Popover>
        );
        break;
      case 'HERB':
        // eslint-disable-next-line no-case-declarations
        const useWay = vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
          (item) => record?.herbOrder?.useWay == item?.value,
        )?.label;
        // eslint-disable-next-line no-case-declarations
        const frequency = vsf.stores.dbenums.enums.MEDICINE_FREQ_DICT?.find(
          (item) => record?.herbOrder?.frequency?.frequency == item?.label,
        )?.label;
        // eslint-disable-next-line no-case-declarations
        const useTimeDescription =
          vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
            (item) => item?.value == record?.herbOrder?.useTimeDescription,
          )?.label;
        name = (
          <Popover
            placement="right"
            overlayClassName="aspirin-herb-box"
            content={
              <List
                size="small"
                header={
                  <div className="aspirin-title-herb-popover-box">
                    <div className="aspirin-title-herb-header-box">
                      <div className="aspirin-title-herb-header-left-box">
                        <span>中药汤剂明细</span>
                        <span>
                          共 {record?.herbOrder?.herbOrderDetail?.length} 项
                        </span>
                      </div>
                      <div className="aspirin-title-herb-header-right-box">
                        <Icon
                          type="icon-zhongtangyaodanchuangbeijing"
                          style={{
                            color: 'rgb(232, 225, 217)',
                          }}
                          size={56}
                        />
                      </div>
                    </div>
                    <div className="aspirin-title-message-box">
                      <div>使用方法: {useWay}</div>
                      <div>服用时间: {useTimeDescription}</div>
                      <div>服用频次: {frequency}</div>
                      <div>付数: {record?.herbOrder?.herbRegimen}</div>
                      <div className="aspirin-title-message-memo-box">
                        备注: {record?.herbOrder?.memo}
                      </div>
                    </div>
                  </div>
                }
                dataSource={record?.herbOrder?.herbOrderDetail}
                className="aspirin-list-herb-popover-item-box"
                renderItem={(item: any, index) => {
                  const find =
                    vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT?.find(
                      ({ value }) => item?.useDescription === value,
                    );
                  const medicalInsuranceType = [
                    { label: '甲', value: 'ALPHA' },
                    { label: '乙', value: 'BETA' },
                    { label: '丙', value: 'GAMMA' },
                  ]?.find(
                    ({ value }) => value === item?.medicalInsuranceType,
                  )?.label;
                  return (
                    <Alert
                      type="warning"
                      message={
                        <div className="aspirin-title-herb-popover-item-box">
                          <span>{index + 1}</span>
                          <span>{item?.drugProductName}</span>
                          {find?.label && <span>{find?.label}</span>}
                          <span>
                            {askFixed(item?.price ?? 0, 2) +
                              '/' +
                              (medicalInsuranceType ?? '')}
                          </span>
                        </div>
                      }
                    />
                  );
                }}
              />
            }
            trigger="hover"
          >
            <div
              className={classnames('aspirin-order-text', record?.groupName)}
            >
              <span>{record?.orderText?.drugName ?? record?.orderText}</span>
            </div>
          </Popover>
        );
        break;
    }
    return name;
  }, []);

  /**
   * 删除医嘱
   */
  const onDelete = useCallback(
    (_value) => {
      if (!_value?.id || String(_value?.id)?.startsWith('create')) {
        const state = value?.filter((item) => item?.id !== _value?.id);
        setValue(group.current?.onSliced(state));
      } else {
        Tip({
          content: `确定删除医嘱 {${_value?.orderText}} ?`,
          onOk() {
            onRemove?.(_value);
          },
        });
      }
    },
    [group, onRemove, setValue, value],
  );

  /**
   * 取消停止医嘱
   */
  const onUnStop = useCallback(
    (_value) => {
      Tip({
        content: `确定取消停止医嘱 {${_value?.orderText}} ?`,
        onOk() {
          onUnStopRequest?.(_value);
        },
      });
    },
    [onUnStopRequest],
  );

  /**
   * 作废医嘱
   */
  const onCancel = useCallback(
    (_value) => {
      Tip({
        content: `确定作废医嘱 {${_value?.orderText}} ?`,
        onOk() {
          onCancelRequest?.(_value);
        },
      });
    },
    [onCancelRequest],
  );

  /**
   * 撤回医嘱
   */
  const onUndo = useCallback(
    (_value) => {
      Tip({
        // content: `确定撤回医嘱 {${_value?.orderText}} ?`,
        content: `医嘱撤销后，将在医嘱单中体现撤销医嘱，是否继续撤销 {${_value?.orderText}}`,
        onOk() {
          onUndoRequest?.(_value);
        },
      });
    },
    [onUndoRequest],
  );

  const onFieldChange = async (key, value, record, form) => {
    //临时医嘱的频次默认为once且不允许修改
    const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
      (item) => {
        return {
          ...item,
          ...JSON?.parse(item?.extensionJson ?? '{}'),
        };
      },
    )?.find(({ frequency }) => frequency === 'once');
    if (value?.orderText) {
      // console.log('value?.orderText', value?.orderText);
      // id 要去除, 不然会顶掉 form 的 rowKey
      const { id, detailInfo, ...rest } = value?.orderText || {};
      const DischargeMedication = await Fetch.getPatientPreDischarge(
        vsf?.stores?.user?.inVisitInfo?.id,
      );

      const orderClass = rest?.orderClass ?? detailInfo?.orderClass;
      switch (orderClass) {
        case 'DRUG':
          {
            // eslint-disable-next-line no-case-declarations
            const {
              drugEo: { groupNumber, groupSubNumber, ...drug },
            } = detailInfo || {};

            let newDrug = drug;
            let extraObj = {};
            if (typeof rest?.prescriptionIndicator === 'boolean') {
              extraObj = {
                prescriptionIndicator: rest?.prescriptionIndicator,
              };

              const { prescriptionId, prescriptionIndicator, ...restDrug } =
                drug;
              newDrug = restDrug;
            }
            form.setFieldsValue({
              [key]: {
                orderClass: 'DRUG',
                drugOrder: {
                  ...(newDrug ?? {}),
                  unitPrice: detailInfo?.retailPrice, // 存疑
                  dosage: {
                    value: null,
                    unit: drug?.dosageUnit ?? detailInfo?.unit,
                  },
                  usage: {
                    value: drug?.usage ?? 1,
                    unit: drug?.usageUnit,
                  },
                  totalAmount: {
                    unit: drug?.packageUnit,
                    value: 0,
                  },
                  ...extraObj,
                  ...(record?.repeatIndicator || record?.prescriptionIndicator
                    ? {
                        frequency: undefined,
                      }
                    : {
                        frequency,
                      }),
                  amountPerPackage: detailInfo?.amountPerPackage,
                  dosagePerUnit: detailInfo?.dosagePerUnit,
                },
                dischargeDrug: DischargeMedication,
                drugPriceItemId: drug?.drugPriceItemId,
                performDepartmentId: rest?.storageDepartmentId,
                performDepartment: {
                  departmentName: rest?.storageName,
                  id: rest?.storageDepartmentId,
                },
                uuid: getUUID(),
              },
            });
          }
          break;
        case 'TREAT':
        case 'OTHER':
          {
            const data = {
              orderClass,
              orderText: rest?.clinicItemName,
              disposalOrder: {
                itemId: rest?.clinicItemId,
                itemName: rest?.clinicItemName,
              },
              drugOrder: {
                totalAmount: {
                  value: 1,
                  unit: undefined,
                },
                itemId: rest?.clinicItemId,
                itemName: rest?.clinicItemName,
              },
            };
            form?.setFieldsValue({
              [key]: data,
            });
          }
          break;
        default:
          {
            const data = {
              orderClass,
              orderText: rest?.clinicItemName,
              disposalOrder: {
                itemId: rest?.clinicItemId,
                itemName: rest?.clinicItemName,
              },
              drugOrder: {
                totalAmount: {
                  value: 1,
                  unit: undefined,
                },
                itemId: rest?.clinicItemId,
                itemName: rest?.clinicItemName,
              },
            };
            form?.setFieldsValue({
              [key]: data,
            });
          }
          break;
      }
    } else if (typeof value?.repeatIndicator === 'boolean') {
      await form?.resetFields();
      await form?.setFieldsValue({
        [key]: {
          repeatIndicator: value?.repeatIndicator,
          ...(value?.repeatIndicator
            ? {}
            : {
                drugOrder: {
                  frequency,
                },
              }),
        },
      });
      form.getFieldInstance([key, 'orderText'])?.focus();
    } else if (value?.dischargeDrug) {
      // 如果成组医嘱勾选出院带药后取消成组，则重新分方，两个处方都默认“出院带药”
      const record = getValue()?.find((item) => item?.id == key);
      if (record?.groupIndex?.length) {
        onCancelGroup(record);
      }
    }
  };

  const administrationRender = (...arr) => {
    const [, record] = arr;
    const { drugOrder, disposalOrder, herbOrder } = record;
    let res: any =
      drugOrder?.administration ??
      disposalOrder?.administration ??
      herbOrder?.useWay ??
      '';
    if (herbOrder) {
      res = (vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT ?? []).find(
        (item) => item?.value === res,
      )?.label;
    } else {
      res = (vsf.stores.dbenums.enums.ADMINISTRATION_DICT ?? []).find(
        (item) => item?.value === res,
      )?.label;
    }
    return res;
  };

  const inter: any = (...[, , index]) => {
    const value = tableRef?.current?.getRowValue(index);
    return (
      value?.drugOrder?.groupSubNumber && value?.drugOrder?.groupSubNumber !== 1
    );
  };

  const preview: any = (...[, row]) => {
    const is = [
      { label: '治疗', value: 'TREAT' },
      { label: '手术', value: 'OPERATION' },
      { label: '麻醉', value: 'ANESTHESIA' },
      { label: '护理', value: 'NURSING' },
      { label: '膳食', value: 'MEAL' },
      { label: '营养', value: 'NUTRITION' },
      { label: '会诊', value: 'CONSULTATION' },
      { label: '用血', value: 'BLOOD' },
      { label: '其他', value: 'OTHER' },
    ];
    const value = is?.map((item) => item?.value).includes(row?.orderClass);
    return value;
  };

  const search = useRef<string[]>([]);
  const width = eliminate?.eliminates?.includes('edit')
    ? eliminate?.type === false
      ? 50
      : 100
    : 100;
  // console.log(width);
  const columns = [
    {
      dataIndex: ['orderStatus'],
      title: '状态',
      valueType: 'select',
      dataSource: [
        { label: '执行', value: 'PERFORM' },
        { label: '停止', value: 'STOP' },
        { label: '作废', value: 'CANCEL' },
        { label: '审核未通过', value: 'AUDIT_FAIL' },
        { label: '待停', value: 'WAIT_STOP' },
        { label: '保存', value: 'SAVE' },
        { label: '提交', value: 'SUBMIT' },
        { label: '开立', value: 'OPEN' },
      ],
      fieldProps: {
        defaultValue: 'SAVE',
      },
      preview: true,
      width: 60,
      // fixed: 'left',
    },
    {
      dataIndex: ['orderClass'],
      title: '类别',
      valueType: 'select',
      dataSource: [
        { label: '西药', value: 'DRUG' },
        { label: '中药', value: 'HERB' },
        { label: '检验', value: 'LAB' },
        { label: '检查', value: 'EXAM' },
        { label: '病理', value: 'PATHOLOGY' },
        { label: '治疗', value: 'TREAT' },
        { label: '手术', value: 'OPERATION' },
        { label: '麻醉', value: 'ANESTHESIA' },
        { label: '护理', value: 'NURSING' },
        { label: '膳食', value: 'MEAL' },
        { label: '营养', value: 'NUTRITION' },
        { label: '会诊', value: 'CONSULTATION' },
        { label: '用血', value: 'BLOOD' },
        { label: '其他', value: 'OTHER' },
      ],
      preview: true,
      width: 55,
      // fixed: 'left',
    },
    hospitalType === 'in' && [
      {
        dataIndex: ['startPerformDateTime'],
        title: '开始时间',
        valueType: 'date',
        fieldProps: { format: 'YYYY-MM-DD HH:mm:ss' },
        preview: true,
        width: 110,
        render: (...args) => {
          const [, record] = args;
          const { startPerformDateTime } = record || {};
          return startPerformDateTime
            ? dayjs(startPerformDateTime).format('MM-DD HH:mm')
            : '';
        },
        // fixed: 'left',
      },
      {
        dataIndex: ['repeatIndicator'],
        title: '长/临',
        valueType: 'select',
        dataSource: [
          {
            label: '长期',
            value: true,
          },
          {
            label: '临时',
            value: false,
          },
        ],
        formItemProps: {
          rules: [
            {
              required: true,
            },
          ],
        },
        width: 100,
        // fixed: 'left',
        render: (...arr) => {
          const [, record] = arr;
          return record?.repeatIndicator ? '长期' : '临时';
        },
        preview: (...[, record]) => {
          return record?._skinTest;
        },
      },
    ],
    {
      dataIndex: ['orderText'],
      title: '医嘱',
      valueType: 'dictionary',
      width: 286,
      fieldProps: (...args) => {
        const [record] = args;
        const className = value?.find(
          (item) => item?.id === record?.id,
        )?.groupName;
        return {
          width: '100%',
          handleExtraSubmit: onDrawerSubmit,
          onDrugFetch,
          onDrugDetailsFetch,
          showDepartment,
          showStorage,
          skinTest,
          isOther: record?.isOther,
          hospitalType,
          repeatIndicator: record?.repeatIndicator,
          // disabled: preview('', record),
          // disabled: preview('', record),
          className,
        };
      },
      render,
      // fixed: 'left',
      className: 'aspirin-table-order-cell',
      preview: (...[, record]) => {
        return record?._skinTest;
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      dataIndex: ['drugOrder', 'dosage'],
      title: '剂量',
      valueType: 'dosage',
      width,
      fieldProps: (record) => {
        return {
          // record: value?.find((item) => item?.id === record?.id),
          record,
          allowClear: true,
          dataSource: value,
        };
      },
      preview,
      formItemProps: {
        rules: [
          {
            required: true,
          },
          {
            validator: (...[, value]) => {
              if ([undefined, null].includes(value?.value)) {
                return Promise?.reject('剂量不能为空');
              } else if ((value?.value ?? value) <= 0) {
                return Promise?.reject('剂量不能小于0');
              } else {
                return Promise?.resolve();
              }
            },
          },
        ],
      },
    },
    {
      dataIndex: ['drugOrder', 'usage'],
      title: '用量',
      valueType: 'usage',
      width,
      fieldProps: (record) => {
        return {
          record,
          allowClear: true,
          dataSource: value,
        };
      },
      preview,
      formItemProps: {
        rules: [
          {
            required: true,
          },
          {
            validator: (...[, value]) =>
              value?.value
                ? Promise?.resolve()
                : Promise?.reject('用量不能为空'),
          },
        ],
      },
    },
    {
      dataIndex: ['drugOrder', 'administration'],
      title: '途径',
      valueType: 'administrationSelect',
      width,
      dataSource: vsf.stores.dbenums.enums.ADMINISTRATION_DICT,
      preview: (...arr) => inter(...arr) || preview(...arr),
      fieldProps: {
        allowClear: false,
        showSearch: true,
        placeholder: '请选择途径',
        onInputKeyDown: (event) => {
          search.current = [search?.current?.at(-1) ?? '', event?.key];
          const [one, two] = search?.current;
          if (one === '/' && two === 'Enter') {
            onGroup(void 0);
          }
        },
      },
      formItemProps: (record) => {
        return {
          ...(record?.drugOrder?.groupSubNumber === 1
            ? {
                rules: [
                  {
                    required: true,
                  },
                ],
              }
            : {}),
        };
      },
    },
    {
      dataIndex: ['drugOrder', 'dripSpeed'],
      title: '滴速',
      valueType: 'text',
      width: 100,
      fieldProps: {
        allowClear: false,
      },
      preview: (...arr) => inter(...arr) || preview(...arr),
    },
    {
      dataIndex: ['drugOrder', 'frequency'],
      title: '频次',
      valueType: 'select',
      width,
      dataSource: (option) => {
        const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
          (item) => {
            return {
              ...item,
              ...JSON?.parse(item?.extensionJson ?? '{}'),
            };
          },
        );
        const state = option?.frequency
          ? value?.filter((item) =>
              askSearch(option?.frequency, item, 'frequency'),
            )
          : value;
        return state;
      },
      preview: (...arr) => {
        const [, record] = arr;
        if (hospitalType === 'out') {
          return inter(...arr) || preview(...arr);
        } else {
          if (inter(...arr)) {
            return true;
          } else if (
            record?.repeatIndicator ||
            record?.drugOrder?.prescriptionId
          ) {
            const dataSource = [
              { label: '西药', value: 'DRUG' },
              { label: '治疗', value: 'TREAT' },
              { label: '麻醉', value: 'ANESTHESIA' },
              { label: '护理', value: 'NURSING' },
              { label: '膳食', value: 'MEAL' },
              { label: '营养', value: 'NUTRITION' },
              { label: '会诊', value: 'CONSULTATION' },
              { label: '用血', value: 'BLOOD' },
              { label: '其他', value: 'OTHER' },
            ];
            return !dataSource?.find(
              (item) => item?.value === record?.orderClass,
            );
          } else {
            return false;
          }
        }
      },
      render: (...[, record]) => {
        return (
          record?.drugOrder?.frequency?.frequency ??
          record?.drugOrder?.frequency?.label ??
          ''
        );
      },
      fieldProps: {
        allowClear: false,
        placeholder: '请选择频次',
        showSearch: true,
      },
      fieldNames: {
        label: 'frequency',
        value: 'frequency',
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      dataIndex: ['drugOrder', 'performDays'],
      title: '天数',
      valueType: 'digit',
      width,
      fieldProps: {
        allowClear: true,
      },
      preview: (...arr) => inter(...arr) || preview(...arr),
      formItemProps: {
        rules: [
          {
            required: true,
          },
          {
            min: 1,
            type: 'digit',
          },
        ],
      },
    },

    {
      dataIndex: ['drugOrder', 'totalAmount'],
      title: '数量',
      valueType: 'inputUnit',
      width,
      preview: (...arr) => !preview(...arr),
      fieldProps: {
        width: '100%',
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
          {
            type: 'digit',
          },
        ],
      },
    },
    {
      dataIndex: ['drugOrder', 'administrationAmount'],
      title: '注射',
      valueType: 'text',
      fieldProps: {
        allowClear: true,
      },
      width: 140,
      preview,
    },
    {
      dataIndex: ['drugOrder', 'skinTestType'],
      title: '皮试',
      valueType: 'text',
      width: 140,
      editable: false,
      render: (...arr) => {
        const [, record] = arr;
        const find = [
          { label: '非皮试', value: 'NOT_SKIN_TEST' },
          { label: '原液皮试', value: 'ORIGINAL_SKIN_TEST' },
          { label: '非原液皮试', value: 'NOT_ORIGINAL_SKIN_TEST' },
          { label: '皮试临时', value: 'TEMPORARY_SKIN_TEST' },
          { label: '皮试溶媒', value: 'SKIN_TEST_SOLVENT' },
          { label: '不皮试', value: 'NOT_RAW_LIQUID' },
        ];
        const value = record?.record?.orderText?.detailInfo?.skinTestClass;
        return find?.find((item) => item?.value === value)?.label;
      },
      preview,
    },
    {
      dataIndex: ['performDepartment'],
      title: '执行科室',
      valueType: 'performDepartment',
      width: 170,
      fieldNames: {
        label: 'departmentName',
        value: 'id',
      },
      formItemProps: {
        rules: [
          {
            required: true,
          },
        ],
      },
      fieldProps: (...args) => {
        const [record] = args;
        return {
          record,
        };
      },
      render: (...arr) => {
        const [, record] = arr;
        return record?.performDepartment?.departmentName ?? '';
      },
    },
    {
      dataIndex: ['allPrice'],
      title: '总价',
      valueType: 'text',
      width: 140,
      fieldProps: {
        allowClear: false,
      },
      render: (...[, record]) => {
        const price = askFixed(record?.allPrice ?? 0, 2);
        return Number?.isNaN(price) || !record?.allPrice ? '——' : price + '元';
      },
      preview: true,
    },
    {
      dataIndex: ['memo'],
      title: '备注',
      valueType: 'text',
      width: 140,
    },
    {
      dataIndex: ['doctor'],
      title: '开单医师',
      valueType: 'select',
      width: 100,
      fieldNames: {
        label: 'staffName',
        value: 'id',
      },
      fieldProps: {
        allowClear: false,
      },
      preview: true,
      render: (...[, record]) => {
        const { doctor } = record;
        return doctor?.staffName ?? filterParams?.staffInfo?.staffName;
      },
    },
    {
      dataIndex: ['edit'],
      title: '操作',
      valueType: 'oper',
      renderFormItem: (...[, record]) => {
        return (
          <Button
            type="text"
            onClick={() => {
              if (!record?.record?.is_one) {
                tableRef?.current?.cancelEdit();
              } else {
                if (record?.record?._skin) {
                  tableRef?.current?.remove(
                    value?.filter((item) =>
                      record?.record?._skinTest?.includes(item?.id),
                    ),
                  );
                } else {
                  tableRef?.current?.remove(record?.record);
                }
              }
            }}
          >
            取消
          </Button>
        );
      },
      render: (...[, record]) => {
        return (
          <Fragment>
            <Determine
              condition={
                !['PERFORM', 'SUBMIT']?.includes(record?.orderStatus) &&
                !record?.parentRelatedUuid
              }
            >
              <Tooltip placement="top" title="删除">
                <Icon
                  type="icon-wudishanchu"
                  size={20}
                  onClick={() => {
                    if (
                      record?.relatedType === 'SKIN_TEST_VS_DRUG' ||
                      record?._skin
                    ) {
                      Tip({
                        content:
                          '当前医嘱为皮试医嘱，删除会连带关联医嘱一起删除',
                        onOk: () => {
                          if (record?._skin) {
                            tableRef?.current?.remove(
                              value?.filter((item) =>
                                record?._skinTest?.includes(item?.id),
                              ),
                            );
                          } else {
                            onDelete(record);
                          }
                        },
                      });
                    } else {
                      onDelete(record);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="插入">
                <Icon
                  type="icon-xiangshangcharu"
                  size={20}
                  onClick={() => onInsert(record)}
                />
              </Tooltip>
            </Determine>
          </Fragment>
        );
      },
      fixed: 'right',
      width: 70,
    },
  ];

  const renderClassName = useCallback(
    (record) => {
      let resClassName = '';
      // if (state?.id === record?.id) {
      //   resClassName = 'aspirin-table-row-active ';
      // }
      if (hospitalType === 'in') {
        const { orderStatus } = record ?? {};
        switch (orderStatus) {
          case 'SAVE':
            resClassName += 'aspirin-table-row-order-blue';
            break;
          case 'SUBMIT':
            resClassName += 'aspirin-table-row-order-green';
            break;
          case 'PERFORM':
            resClassName += 'aspirin-table-row-order-black';
            break;
          case 'STOP':
            resClassName += 'aspirin-table-row-order-red';
            break;
          case 'CANCEL':
            resClassName += 'aspirin-table-row-order-red';
            break;
          case 'AUDIT_FAIL':
            resClassName += 'aspirin-table-row-order-pink';
            break;
          default:
            resClassName += 'aspirin-table-row-order-blue';
            break;
        }
      }
      return resClassName;
    },
    [hospitalType],
  );

  const onChange = useCallback(
    (_value) => {
      const index = _value?.findIndex(
        (item) => item?.id === onEditingRowsChange?.current?.at(-1),
      );
      // console.log(_value, 'value');
      if (index !== -1) {
        setValue(
          group.current?.onSliced(
            (_value as any)?.toSpliced(index, 1, {
              ..._value[index],
              is_one: false,
            }),
          ),
        );
      } else {
        setValue(_value);
      }
    },
    [setValue],
  );

  const tableProps = useMemo(() => {
    return (
      inputTableProps || {
        onRecord: async () => ({
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
        }),
      }
    );
  }, [inputTableProps]);

  const editable = useMemo(() => {
    const ret: VSTableEditable<any> = {
      editType: 'single',
      columnProps: {
        fixed: 'right',
        width: 50,
        hideInTable: true,
      },
      onEditingRowsChange: (...[, key]) => {
        onEditingRowsChange.current = key;
      },
      switchMode: true,
      onFieldChange,
    };
    return ret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dragSortProp = useMemo(() => {
    return {
      dragSortDataIndex: 'sort',
      onDragSortEnd: (sortedData) => {
        setValue?.(sortedData);
      },
    };
  }, [setValue]);

  const onRow = useCallback(
    (record) => {
      return {
        onDoubleClick: () => {
          onDoubleClick(record);
        },
        onClick: () => {
          onClick(record);
        },
      };
    },
    [onClick, onDoubleClick],
  );

  return (
    <Block
      {...(footer
        ? {
            footer: cloneElement(
              footer as ReactElement<any, string | JSXElementConstructor<any>>,
              {
                cancelGroupDisabled,
                onCancelGroup,
                onInsert,
                insertDisabled,
                onGroup,
                ref: footerRef,
              },
            ),
          }
        : {})}
      {...blockProps}
    >
      {header}
      <VSTable
        scroll={{
          y: getScrollY(500),
          x: 2100,
        }}
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-vs-table-row-box"
        editable={editable}
        bordered
        value={value}
        onChange={onChange}
        pagination={false}
        rowKey="id"
        onRow={onRow}
        rowClassName={renderClassName}
        dragSort={dragSortProp}
        {...tableProps}
        rowSelection={undefined}
      >
        <VSTableColumn
          dataIndex={['check']}
          valueType="custom"
          render={(...[, record]) => {
            const selectedRows = props?.tableProps?.rowSelection
              ?.selectedRows as any[];
            const state = selectedRows?.map((item) => item?.id);
            const _value = state?.includes(record?.id);
            let render: any = null;
            if (record?.groupName === 'aspirin-order-text-group-first') {
              render = (
                <Checkbox
                  value={_value}
                  onChange={(info) => {
                    const group = value?.filter((...[, index]) =>
                      record?.groupIndex?.includes(index),
                    );
                    if (info) {
                      props?.tableProps?.rowSelection?.onSelectedRowsChange?.(
                        [
                          ...selectedRows?.filter(
                            (item) =>
                              !group
                                ?.map((item) => item?.id)
                                ?.includes(item?.id),
                          ),
                          ...group,
                        ],
                        [],
                      );
                    } else {
                      props?.tableProps?.rowSelection?.onSelectedRowsChange?.(
                        selectedRows?.filter(
                          (item) =>
                            !group?.map((item) => item?.id)?.includes(item?.id),
                        ),
                        [],
                      );
                    }
                  }}
                />
              );
            }
            if (!record?.groupName) {
              render = (
                <Checkbox
                  value={_value}
                  onChange={(info) => {
                    if (info) {
                      props?.tableProps?.rowSelection?.onSelectedRowsChange?.(
                        [...selectedRows, record],
                        [],
                      );
                    } else {
                      props?.tableProps?.rowSelection?.onSelectedRowsChange?.(
                        selectedRows?.filter((item) => item?.id !== record?.id),
                        [],
                      );
                    }
                  }}
                />
              );
            }
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {render}
              </div>
            );
          }}
          title={() => {
            const selectedRows: any = props?.tableProps?.rowSelection;
            const _value: any =
              selectedRows?.selectedRows?.length === value?.length &&
              selectedRows?.selectedRows?.length !== 0 &&
              value?.length !== 0;
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Checkbox
                  value={_value}
                  indeterminate={
                    selectedRows?.selectedRows?.length > 0 &&
                    selectedRows?.selectedRows?.length < value.length
                  }
                  onChange={(info) => {
                    if (info) {
                      selectedRows?.onSelectedRowsChange?.(value, []);
                    } else {
                      selectedRows?.onSelectedRowsChange?.([], []);
                    }
                  }}
                />
              </div>
            );
          }}
          editable={false}
          hideInTable={!props?.tableProps?.rowSelection}
          fixed="left"
          width={50}
        />
        {dragSort && (
          <VSTableColumn
            dataIndex="sort"
            title="排序"
            editable={false}
            width={70}
            fixed="left"
          />
        )}
        {columns
          ?.flat()
          ?.filter((item) => {
            if (item) {
              const split: any = item?.dataIndex?.join('_');
              const include = eliminate?.eliminates.includes(split);
              return eliminate?.type ? include : !include;
            }
          })
          ?.map((item: any, index) => {
            return <VSTableColumn key={index} {...item} />;
          })}
      </VSTable>
      <Test ref={testRef} onSubmit={onDrawerSubmit} {...labProps} />
      <Exam ref={examRef} onSubmit={onDrawerSubmit} />
      <Chm
        ref={chmRef}
        onSubmit={onDrawerSubmit}
        params={{
          hospitalType,
          inVisitInfo: filterParams?.inVisitInfo,
        }}
      />
      <Operation ref={operationRef} onSubmit={onDrawerSubmit} />
      <Pathology ref={pathologyRef} onSubmit={onDrawerSubmit} />
      <Dispose ref={disposeRef} onSubmit={onDrawerSubmit} />
    </Block>
  );
};

export default forwardRef(OrderTable);
