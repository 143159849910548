import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientJourneyController_getPatientJourney_95f0ff: {
    method: 'post',
    url: '/api/clinic-record-order/get-patient-journey',
    parameterFomatter: (data?: {
      qto: PatientJourneyOrderNewQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientJourneyVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
