import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getAllByClinicItemLabQto_f468d1: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-lab-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemLabQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_createClinicLabItem_7d0d43: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-lab-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      createLabItemBto: CreateLabItemBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_updateClinicLabItem_0a1f35: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-lab-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateLabItemBto: UpdateLabItemBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
});
