/* eslint-disable no-prototype-builtins */
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message, Tooltip, VSTableColumn } from '@vs/vsf-kit';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';
import React, {
  createContext,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { cdssShow } from '@/mock';
import Block from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import {
  askGroupNumber,
  askMedicalOrders,
} from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

import { ButtonAndKeyDown } from '../..';
import CdssTable from '../cdss_modal';
import { AuxiliaryRef } from './assist/auxiliary';
import { HeaderRefs } from './assist/header';
import SaveAsTemplate from './assist/save_as_template';
import OutHospital, { Refs, TableRefs, Value } from './table';
import { convertOrderInTable, convertOrderOutTable } from './table/convert';
import skin from './table/skin';

export const BeIn = createContext((value) => {});

export type Drug = {
  onDrug: (value?: never) => void;
  onOther: () => void;
};
export type AuxiliaryRefs = AuxiliaryRef;

const OutHospitals = (props) => {
  const {
    prefixCls = 'aspirin-cpoe-center',
    department,
    clinicSettlementRef,
  } = props;
  const {
    user: { currentPatient, outVisitInfo },
    settings: { screenSize },
    common: { currentApplication },
    cdss: { cdssOrderResponse },
  } = vsf?.stores;

  const auxiliary = useRef<AuxiliaryRefs | null>(null);
  const table = useRef<
    Drug &
      TableRefs &
      Refs & { tableRef: Element } & { id: () => string | number }
  >(null);
  const header = useRef<HeaderRefs>(null);
  const footer = useRef<{ click: () => void }>();
  const cdssTable = useRef<any>();

  const save_as_template = useRef<any>(null);

  const onSubmit = useCallback(async () => {
    await table?.current?.onSave();
    const values = (table?.current?.getValue?.() ?? [])?.filter(
      (item: any) => item?.orderText,
    );
    const visitInfo = { outpVisitEncounterId: outVisitInfo?.id };
    return convertOrderOutTable(
      table?.current?.onSliced(values),
      visitInfo,
      currentPatient?.patientId,
    )?.filter((item) => ['SAVE', 'OPEN']?.includes(item?.orderStatus));
  }, [currentPatient?.patientId, outVisitInfo?.id]);

  const onSend = useFetch({
    fetch: async (value?) => {
      const order = value ? value : await onSubmit?.();
      const res =
        await vsf.services?.ClinicRecordOrderController_submitOrder_89360b?.({
          order: order?.map((item) => ({
            ...item,
            orderStatus: 'SAVE',
          })),
        });
      return res;
    },
    message: {
      loading: '提交中',
      success: '提交成功',
      error: '提交失败',
    },
    success() {
      table?.current?.onReload?.();
    },
  });
  const Footer = forwardRef((props: any, ref) => {
    const [click] = useOnChangeValue<any>({});
    const [group] = useOnChangeValue(true);

    // 收费明细
    const [calculationCost, setCalculationCost] = useState<any>();
    useEffect(() => {
      getAllOrderTableList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outVisitInfo?.id, currentApplication]);

    // 费用计算
    const getAllOrderTableList = useCallback(async () => {
      if (!outVisitInfo?.id) return;
      const res =
        await vsf.services?.DepartmentPriceController_getDepartmentPrice_78944b?.(
          {
            outpVisitEncounterId: outVisitInfo?.id,
          },
        );
      if (res?.code == 200 && res?.data && res?.data?.length > 0) {
        // 全部待交明细
        const costSum = res?.data.reduce((accumulator, currentValue) => {
          return accumulator + currentValue?.cost;
        }, 0);
        const chargeSum = res?.data.reduce((accumulator, currentValue) => {
          return accumulator + currentValue?.charge;
        }, 0);
        // 本次收费明细
        const currentCost = costSum - chargeSum;
        setCalculationCost({
          costSum: costSum.toFixed(2),
          currentCost: currentCost.toFixed(2),
        });
      } else {
        setCalculationCost({ costSum: '0.00', currentCost: '0.00' });
      }
      // setAllOrderTableList(res?.data?.result ?? res?.data ?? []);
      return res?.data ?? [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outVisitInfo?.id]);

    const onSave = useFetch({
      fetch: async () => {
        try {
          const order = await onSubmit?.();
          const res =
            await vsf.services?.ClinicRecordOrderController_saveOrder_c9c63c?.({
              order: order
                ?.filter(
                  (item) =>
                    !item?.orderStatus ||
                    ['SAVE', 'OPEN']?.includes(item?.orderStatus),
                )
                ?.map?.((item) => ({
                  ...item,
                  orderStatus: 'SAVE',
                })),
            });
          return res;
        } catch (error) {
          return {
            code: 500,
          };
        }
      },
      message: {
        loading: '保存中',
        success: '保存成功',
        error: '保存失败',
      },
      success() {
        // 查询全部费用和代收费费用
        getAllOrderTableList();
        table?.current?.onReload?.();
        const tableContainer =
          document.getElementsByClassName('ant-table-body')[0];
        if (tableContainer) {
          tableContainer.scrollLeft = 0;
        }
      },
    });

    const onCancelSend = useFetch({
      fetch: async (params) => {
        const res =
          await vsf.services?.ClinicRecordOrderController_cancelSubmitOrder_28057d?.(
            {
              orderId: params?.id,
            },
          );
        return res;
      },
      message: '取消提交',
      success() {
        /**
         * 有关联型的一起修改
         */
        // 查询全部费用和代收费费用
        getAllOrderTableList();
        const list: any = table?.current?.getValue?.() ?? [];
        const test =
          list?.filter?.(
            (item) => item?.parentRelatedUuid === click?.value?.uuid,
          ) ?? [];
        const group = (table?.current?.getValue?.() ?? [])?.filter?.(
          (...[, index]) => click?.value?.groupIndex?.includes(index),
        );
        const array = test?.length ? [click?.value, ...test] : group;
        const state = array?.length ? array : [click?.value];
        for (const item of state) {
          table.current?.update({
            ...item,
            orderStatus: 'SAVE',
          });
        }
      },
    });

    useImperativeHandle(ref, () => ({
      click: click?.onChange,
      group: group?.onChange,
    }));
    const button: any = [
      {
        type: 'primary',
        ghost: true,
        title: '刷新',
        onClick: () => {
          // 查询全部费用和代收费费用
          getAllOrderTableList();
          table?.current?.onReload();
        },
      },
      {
        type: 'primary',
        title: '成组',
        onClick: () => table?.current?.onGroup?.(),
        disabled: group?.value,
      },
      {
        type: 'primary',
        ghost: true,
        title: '取消成组',
        onClick: () => table?.current?.onCancelGroup?.(),
        /**
         * 执行和提交状态，不可以取消成组
         * 并且有关联的医嘱不可以取消成组
         */
        disabled:
          click?.value?.parentRelatedUuid ||
          ['PERFORM', 'SUBMIT']?.includes(click?.value?.orderStatus) ||
          !click?.value?.groupIndex?.length,
      },
      {
        methodKey: 'altKey',
        ordinaryKey: 'a',
        type: 'primary',
        ghost: true,
        title: '取消提交',
        /**
         * 不是执行和提交状态，不可以取消提交
         * 并且有关联的医嘱只可以取消提交主医嘱
         */
        disabled:
          !['PERFORM', 'SUBMIT']?.includes(click?.value?.orderStatus) ||
          click?.value?.parentRelatedUuid,
        onClick: () => {
          if (
            click?.value?.doctor?.id !==
            vsf?.stores?.user?.staffInfo?.doctorDto?.staffId
          ) {
            message?.warning(
              `当前医嘱开立者为${click?.value?.doctor?.staffName}医生，无法操作`,
            );
            return;
          }
          onCancelSend(click?.value);
        },
      },
      {
        methodKey: 'altKey',
        ordinaryKey: 'q',
        type: 'primary',
        title: '保存',
        onClick: async () => {
          const save = await table?.current?.onSave?.();
          if (save) {
            onSave();
          }
        },
      },
      {
        methodKey: 'altKey',
        ordinaryKey: 'w',
        type: 'primary',
        title: '提交',
        // onClick: onSend,
        onClick: async () => {
          const order = await onSubmit?.();
          for (const item of order) {
            if (item?.orderClass === 'EXAM') {
              if (
                !item?.examOrder?.reportType ||
                !item?.examOrder?.examReason
              ) {
                message?.info('有未填写完整的申请单，请填写后提交');
                const state = await auxiliary.current?.onAsyncExam({
                  uuid: item?.uuid,
                  ...item?.examOrder,
                  patientSpecialInfo: item?.patientSpecialInfo,
                  performDepartment: item?.performDepartment,
                  orderDiagnosisIdList: item?.orderDiagnosisIdList,
                });
                const index = order?.findIndex?.(({ id }) => id === item?.id);
                order.splice(index, 1, {
                  ...(state ?? {}),
                  id: item?.id,
                });
              }
            }
          }
          console.log(order, 'orderorder');

          if (cdssShow) {
            // const order = await onSubmit?.();
            const _order = order
              ?.filter((item) =>
                ['SAVE', 'AUDIT_FAIL', 'OPEN']?.includes(item?.orderStatus),
              )
              ?.map((item) => ({
                ...item,
                orderStatus: 'SAVE',
              }));
            if (order?.length > 0) {
              message.open({
                type: 'loading',
                content: '加载中',
              });

              const res = await cdssOrderResponse({
                outpVisitId: outVisitInfo?.outpVisit?.id,
                saveOrderEoList: _order?.map((i) => {
                  return i?.orderClass == 'TREAT'
                    ? i
                    : {
                        ...i,
                        drugOrder: {
                          ...i?.drugOrder,
                          drugId: i?.drugOrder?.drugId
                            ? i?.drugOrder?.drugId
                            : i?.drugOrder?.drug?.id,
                        },
                      };
                }),
              });

              message.destroy();
              if (res?.code !== 200) return message.error(res?.message);
              if (res?.data?.filterStatus == 'BLOCKED') {
                cdssTable.current.handelOpen(true, _order);
                cdssTable.current.getTableData(res?.data);
              } else {
                onSend(_order);
              }
            } else {
              message.info('请选择医嘱');
            }
          } else {
            onSend(order);
          }
        },
      },
      {
        methodKey: 'altKey',
        ordinaryKey: 'b',
        type: 'primary',
        title: '诊间结算',
        onClick: () => {
          clinicSettlementRef?.current?.onOpen();
        },
      },
      {
        type: 'primary',
        title: '其他操作',
        onClick: () => {},
      },
    ];

    return (
      <div
        className="footer aspirin-order-module-footer"
        style={{
          zIndex: 1000,
        }}
      >
        <div className="first">
          <div className="desc" style={{ color: '#babcbd' }}>
            <Icon
              style={{ color: '#babcbd', marginRight: '5px' }}
              type="icon-bangzhu"
              size={14}
            />
            图标说明
          </div>
          <div className="oper">
            {button?.map((item, index) => {
              const { title, ...rest } = item;
              return (
                // <Button {...rest} key={index}>
                //   {item?.title}
                // </Button>
                <ButtonAndKeyDown key={item.title} {...item} type="primary">
                  {item.title}
                </ButtonAndKeyDown>
              );
            })}
          </div>
        </div>
        <div
          className="second"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div
            style={{
              marginRight: '10px',
              color: '#3276E8',
              cursor: 'pointer',
            }}
            // onClick={() => {
            //   save_as_template.current?.onOpen?.();
            // }}
          >
            <Icon type="icon-Frame-19" style={{ marginRight: '5px' }} />
            <ButtonAndKeyDown
              {...{
                style: { border: 'none' },
                onClick: () => save_as_template.current?.onOpen?.(),
                methodKey: 'altKey',
                ordinaryKey: 'G',
                children: '另存为模板',
                key: '另存为模板',
                ghost: true,
                type: 'primary',
              }}
            />
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '15px',
              }}
            >
              <div className="label icon18">
                <Icon
                  className="icon24"
                  type="icon-jine"
                  // size={14}
                  style={{ marginRight: '5px' }}
                />
                全部费用
              </div>
              <div className="value dinpro icon18">
                ¥ {calculationCost?.costSum}
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="label icon18">待收费费用</div>
              <div className="value dinpro icon18">
                ¥ {calculationCost?.costSum}
              </div>
              <div
                onClick={() => {
                  department?.current?.onOpen();
                }}
                style={{ marginLeft: '4px', cursor: 'pointer' }}
              >
                <Icon className="icon24" type="icon-you" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  Footer.displayName = 'Footer';

  // 插入皮试
  const insertSkin = (value, target, index) => {
    return new Promise((resolve, reject) => {
      const items = skin(value, table?.current?.getValue() ?? []);
      table?.current?.setValue(
        target?.toSpliced(index, 1, ...table?.current?.onSliced(items)),
      );
      new Promise((a, b) => {
        setTimeout(() => {
          try {
            a(table?.current?.onStart?.(items?.[0]?.id));
          } catch (error) {
            b(error);
          }
        }, 500);
      }).then(() => {
        table?.current
          ?.getEditForm()
          ?.getFieldInstance([items?.[0]?.id, 'dosage'])
          ?.focus();
        resolve(true);
      });
    });
  };

  /**
   * 抽屉操作: 提交数据到单行
   */
  const onDrawerSubmit = useCallback((value: Value) => {
    const id = 'create' + Math?.random();
    const target = (table?.current?.getValue() ?? []) as Value[] as any;
    // const self = table.current?.getEditForm()?.getFieldsValue(true)?.[
    //   table?.current?.id() ?? ''
    // ];
    const self = target?.find((item) => item?.id === table?.current?.id());
    const index = (target ?? [])?.findIndex(
      (item) => item?.uuid === value?.uuid,
    );
    if (self?.insert) {
      const index = target?.findIndex(
        (item) => item?.id === table?.current?.id(),
      );
      const is = Array?.isArray(value);
      if (
        is &&
        ['ORIGINAL_SKIN_TEST', 'NOT_ORIGINAL_SKIN_TEST'].includes(
          value?.[0]?.relatedType,
        )
      )
        return insertSkin(value, target, index);
      table?.current?.setValue(
        target?.toSpliced?.(
          index,
          1,
          ...(is
            ? value?.map((item) => {
                return {
                  id: 'create' + Math?.random(),
                  uuid: getUUID(),
                  orderStatus: 'OPEN',
                  ...item,
                };
              })
            : [{ id, ...value }]),
        ),
      );
      return;
    }
    if (Array?.isArray(value)) {
      if (value?.length) {
        if (
          ['ORIGINAL_SKIN_TEST', 'NOT_ORIGINAL_SKIN_TEST'].includes(
            value?.[0]?.relatedType,
          )
        ) {
          const items = skin(value, table?.current?.getValue() ?? []);
          table?.current?.setValue(
            target?.toSpliced(index, 1, ...table?.current?.onSliced(items)),
          );
          new Promise((resolve, reject) => {
            setTimeout(() => {
              try {
                resolve(table?.current?.onStart?.(items?.[0]?.id));
              } catch (error) {
                reject(error);
              }
            }, 500);
          }).then(() => {
            table?.current
              ?.getEditForm()
              ?.getFieldInstance([items?.[0]?.id, 'dosage'])
              ?.focus();
          });
        } else {
          console.log(target, value, 'targettarget1');
          // const _id = 'create' + Math?.random();
          const _ids: any[] = [];
          const _data = convertOrderInTable([
            ...target,
            ...value?.map((item) => {
              const _id = 'create' + Math?.random();
              _ids?.push({
                orderClass: item?.orderClass,
                id: _id,
              });
              return {
                uuid: getUUID(),
                orderStatus: 'OPEN',
                ...item,
                id: _id,
                drugOrder: {
                  ...item,
                },
              };
            }),
          ])?.filter((i) => i?.orderClass);
          console.log(_data, 'targettarget1');

          table?.current?.setValue(_data);
          // table?.current?.onStart?.(_id);
          _ids?.forEach((ele) => {
            !['LAB']?.includes(ele?.orderClass) &&
              table?.current?.onStart?.(ele?.id);
          });
        }
      }
    } else {
      if (index !== -1) {
        table?.current?.setValue(
          convertOrderInTable(
            target?.toSpliced(index, 1, {
              ...target[index],
              ...value,
            }),
          ),
        );
      } else {
        table?.current?.setValue([
          ...target,
          ...convertOrderInTable([{ id, ...value }]),
        ]);
      }
    }
    setTimeout(() => {
      table?.current?.tableRef?.scrollTo(
        0,
        table?.current?.tableRef?.scrollHeight,
      );
    }, 500);
  }, []);

  const onRemove = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.ClinicRecordOrderController_deleteOrder_0de5cf?.({
          orderId: params?.id,
          deleteGroup: params?.deleteGroup ?? false,
        });
      if (res?.code === 200) {
        table?.current?.setValue((value) => {
          return table?.current?.onSliced(
            value?.filter(
              (item, index) =>
                item?.id !== params?.id &&
                item?.parentRelatedUuid !== params?.uuid &&
                (params?.deleteGroup
                  ? !params?.groupIndex?.includes?.(index)
                  : true),
            ),
          );
        });
      }
      return res;
    },
    message: '删除医嘱',
  });

  const onChange = (value) => {
    const old = askGroupNumber({
      OLD_VALUE: (table?.current?.getValue() ?? []) as any[],
      value,
      table,
    });
    const list = convertOrderInTable(old ?? [])?.map((item) => {
      return {
        ...item,
        orderStatus: 'OPEN',
      };
    });
    console.log(list, 'listlistlist');
    const target = (table?.current?.getValue() ?? []) as Value[] as any;
    const self = target?.find((item) => item?.id === table?.current?.id());
    if (self?.insert) {
      const index = target?.findIndex(
        (item) => item?.id === table?.current?.id(),
      );
      table?.current?.setValue(target?.toSpliced?.(index, 1, ...list));
      return;
    }
    table?.current?.setValue((value = []) =>
      table?.current?.onSliced?.([...value, ...list]),
    );
  };

  const on = {
    delete(_value) {
      const remove = () => {
        table?.current?.setValue((value) =>
          table?.current?.onSliced(
            value?.filter((item) => item?.id !== _value?.id),
          ),
        );
      };

      if (_value?.relatedType === 'SKIN_TEST_VS_DRUG' || _value?._skin) {
        Tip({
          content: '当前医嘱为皮试医嘱，删除会连带关联医嘱一起删除',
          onOk: () => {
            if (_value?._skin) {
              table?.current?.setValue((value) =>
                value?.filter((item) => !_value?._skinTest?.includes(item?.id)),
              );
            } else {
              onRemove?.(_value);
            }
          },
        });
      } else if (_value?.groupName) {
        Tip({
          title: '是否删除整组医嘱?',
          content: '你删除的医嘱附带成组数据,是否删除与当前医嘱成组的所有数据',
          onCancel() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              table?.current?.setValue((value) =>
                table?.current?.onSliced(
                  // value
                  //   ?.filter((item, index) => {
                  //     return !_value?.groupIndex?.includes?.(index);
                  //   })
                  //   ?.filter((item) => {
                  //     const skin = value?.filter(
                  //       (item, index) =>
                  //         _value?.groupIndex?.includes?.(index) &&
                  //         item?.relatedType === 'SKIN_TEST_VS_DRUG',
                  //     );
                  //     return item?.parentRelatedUuid !== skin?.uuid;
                  //   }),
                  value.filter((item, index) => {
                    const skin = value.find(
                      (item, index) =>
                        item?.relatedType === 'SKIN_TEST_VS_DRUG' &&
                        _value.groupIndex.includes(index),
                    );
                    return (
                      !_value.groupIndex.includes(index) &&
                      item?.parentRelatedUuid !== skin?.uuid
                    );
                  }),
                ),
              );
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: true,
                groupIndex: _value?.groupIndex,
              });
            }
          },
          onOk() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              remove?.();
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: false,
              });
            }
          },
          okButtonProps: {
            danger: false,
            type: 'primary',
          },
          okText: '删除单条医嘱',
          cancelText: '删除整组医嘱',
        });
      } else if (!_value?.id || String(_value?.id)?.startsWith('create')) {
        remove?.();
      } else {
        Tip({
          content: `确定删除医嘱 {${_value?.orderText}} ?`,
          onOk() {
            onRemove?.(_value);
          },
        });
      }
    },
  };

  useUpdateEffect(() => {
    table?.current?.onReload?.();
  }, [outVisitInfo?.id]);

  return (
    <BeIn.Provider value={onDrawerSubmit}>
      <Block footer={<Footer ref={footer} />} scroll={false}>
        <OutHospital.Header auxiliary={auxiliary} ref={header} table={table} />
        <OutHospital
          auxiliary={auxiliary}
          ref={table}
          operation={on}
          header={header}
          footer={footer}
        />
        <OutHospital.Auxiliary
          ref={auxiliary}
          onChange={onChange}
          table={table}
        />
        {cdssShow && <CdssTable ref={cdssTable} onConfirm={onSend} />}
      </Block>
      <SaveAsTemplate ref={save_as_template} />
    </BeIn.Provider>
  );
};

export default definePage(OutHospitals);
