// 列表get
export const convertGetListWaitInpPreTransferApplyVoByToWardIdRes2WaitInpPreTransferApplyList =
  (input) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ..._.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'inpPreTransferApplyVo.id'); // Long 住院流水号
        obj.inpVisitId = _.get(d, 'inpPreTransferApplyVo.inpVisitId');
        obj.patientId = _.get(d, 'inpPreTransferApplyVo.patient.id');
        obj.gender = _.get(d, 'inpPreTransferApplyVo.patient.gender');
        obj.name = _.get(d, 'inpPreTransferApplyVo.patient.name');
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.displayId = _.get(d, 'inpPreTransferApplyVo.patient.displayId');
        return obj;
      }),
    );
    return output;
  };
// form
export const convertGetInpPreTransferPatientInfoVoByIdRes2InpPreTransferPatientInfo =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.bedLabel = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.bed.bedLabel',
    );
    output.displayId = _.get(input, 'data.patientVo.displayId');
    output.name = _.get(input, 'data.patientVo.name');
    output.gender = _.get(input, 'data.patientVo.gender');
    output.birthday = _.get(input, 'data.patientVo.birthday');
    output.chargeType = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBillingInfoDto.chargeType',
    );
    output.identityCode = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBillingInfoDto.identityCode',
    );
    output.prePaymentAmount = _.get(
      input,
      'data.patientBillingRecordVo.prePaymentAmount',
    );
    output.contactName = _.get(
      input,
      'data.patientVo.patientProfileDto.contact.name',
    );
    output.phoneNumber = _.get(
      input,
      'data.patientVo.patientProfileDto.contact.phoneNumber',
    );
    // output.department = {};
    // output.department.id = _.get(
    //   input,
    //   'data.inpVisitVo.patientInHospitalDto.department.id',
    // );
    // output.department.departmentName = _.get(
    //   input,
    //   'data.inpVisitVo.patientInHospitalDto.department.departmentName',
    // );
    output.departmentName = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.department.departmentName',
    );
    output.admissionDateTime = _.get(
      input,
      'data.inpTransferRecordVoList.admissionDateTime',
    );
    output.patientCondition = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.patientCondition',
    );
    output.nursingClass = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.nursingClass',
    );
    // output.attendingDoctor = {};
    // output.attendingDoctor.id = _.get(
    //   input,
    //   'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.id',
    // );
    // output.attendingDoctor.staffName = _.get(
    //   input,
    //   'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.staffName',
    // );
    output.attendingDoctor = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.staffName',
    );
    // output.nurseInCharge = {};
    // output.nurseInCharge.id = _.get(
    //   input,
    //   'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.id',
    // );
    // output.nurseInCharge.staffName = _.get(
    //   input,
    //   'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.staffName',
    // );
    output.nurseInCharge = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.staffName',
    );
    output.operationDateTime = _.get(input, 'data.operationDateTime');
    output.diagnosisDescription = _.get(
      input,
      'data.diagnosisRecordVo.diagnosisDescription',
    );
    output.toDepartment = {};
    output.toDepartment.id = _.get(
      input,
      'data.inpPreTransferApplyVo.toDepartment.id',
    );
    output.toDepartment.departmentName = _.get(
      input,
      'data.inpPreTransferApplyVo.toDepartment.departmentName',
    );
    output.toWard = {};
    output.toWard.id = _.get(input, 'data.inpPreTransferApplyVo.toWard.id');
    output.toWard.departmentName = _.get(
      input,
      'data.inpPreTransferApplyVo.toWard.departmentName',
    );
    output.createdAt = _.get(input, 'data.inpPreTransferApplyVo.createdAt');
    output.transferReason = _.get(
      input,
      'data.inpPreTransferApplyVo.transferReason',
    );
    output.inpTransferRecordId = _.get(
      input,
      'data.inpTransferRecordVoList.id',
    );
    output.notLabCount = _.get(input, 'data.notLabCount');
    output.notExecutedOrderCount = _.get(input, 'data.notExecutedOrderCount');
    output.notSubmitOrderCount = _.get(input, 'data.notSubmitOrderCount');
    output.notSendDrug = _.get(input, 'data.notSendDrug');
    output.notReturnDrugPrescription = _.get(
      input,
      'data.notReturnDrugPrescription',
    );
    output.notReturnOrderCount = _.get(input, 'data.notReturnOrderCount');
    output.notStopLongTimeOrder = _.get(input, 'data.notStopLongTimeOrder');
    return output;
  };
// 保存
export const convertInpPreTransferPatientInfo2TransferPatientOutReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoParam = {};
  output.btoParam.id = _.get(input, 'inpTransferRecordId');
  output.btoParam.transferDateTime = _.get(input, 'createdAt'); // Date 转科提交时记录的时间
  output.btoParam.transferReason = _.get(input, 'transferReason'); // String 转科原因
  output.extApiParam = {};
  return output;
};
