import vsf, { definePage } from '@vs/vsf-boot';
import {
  Form,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { Fragment, useCallback } from 'react';

import Container from '@/module/Container';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import Tip from '@/pages/Index/components/func/Tip';

const DrugReceiveAndCheckList = (props) => {
  const [form] = Form?.useForm();
  const onFetch = useCallback(async () => {
    const eo = form?.getFieldsValue();
    if (eo?.deliveryNumber) {
      const res =
        await vsf?.services?.DrugDispenseSummaryController_receiveAndCheck_2802ec?.(
          {
            eo,
          },
        );
      Tip({
        content: res?.data?.message ?? '药品接收成功',
        okButtonProps: {
          danger: res?.data?.message,
        },
      });
      return {
        data:
          res?.data?.receiveDrugInfo?.map((item) => {
            return {
              ...item?.orderVo,
              ...item?.patientVo,
              ...item?.clinicBedVo,
              receiveStatus: res?.data?.receiveIndicator,
              ...(item?.orderVo?.orderDrug?.dosage
                ? {
                    dosage: {
                      value: item?.orderVo?.orderDrug?.dosage,
                      unit: item?.orderVo?.orderDrug?.dosageUnit,
                    },
                  }
                : {}),
            };
          }) ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
    return {
      data: [],
    };
  }, [form]);
  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
          },
        ],
      }}
    >
      <VSPromiseTable
        id="drugReceiveAndCheck"
        className="aspirin-table-no-background-box"
        onFetch={onFetch}
        bordered
        pagination={false}
        tableRender={(...[, table]) => {
          return (
            <Fragment>
              <div
                style={{
                  padding: 24,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                className="aspirin-table-search-form-box"
              >
                <VSForm form={form}>
                  <VSFormLayout columnCount={5}>
                    <VSFormItem
                      name={['checkMethod']}
                      label="核对方式"
                      valueType="select"
                      initialValue={'SCAN_CODE'}
                      dataSource={[
                        { label: '扫码', value: 'SCAN_CODE' },
                        { label: '手工', value: 'MANUAL_INPUT' },
                      ]}
                      fieldProps={{
                        width: '100%',
                      }}
                    />
                    <VSFormItem
                      name={['deliveryNumber']}
                      label="配送单号"
                      valueType="digit"
                      transform={(value) => {
                        return {
                          deliveryNumber: Number(value),
                        };
                      }}
                      fieldProps={{
                        width: '100%',
                      }}
                    />
                    <VSFormItem
                      name={['receiveTime']}
                      label="接收时间"
                      valueType="date"
                      fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                    />

                    <VSFormItem
                      name={['receiveType']}
                      label="接收类型"
                      valueType="select"
                      dataSource={[
                        { value: 'ORAL_MEDICINE', label: '口服药接收' },
                        {
                          value: 'INTRAVENOUS_MEDICINE',
                          label: '静配药接收',
                        },
                      ]}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </VSForm>
                <ButtonAndKeyDown
                  methodKey="altKey"
                  ordinaryKey="Q"
                  onClick={() => {
                    vsf?.refs?.drugReceiveAndCheck?.reload();
                  }}
                  type="primary"
                >
                  查询
                </ButtonAndKeyDown>
              </div>
              <div
                className="aspirin-table-inter-box"
                style={{
                  padding: 24,
                }}
              >
                <div>{table}</div>
              </div>
            </Fragment>
          );
        }}
      >
        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床标号"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="患者姓名"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['gender']}
          title="患者性别"
          valueType="select"
          dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
        />

        <VSTableColumn
          dataIndex={['age']}
          title="年龄"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['repeatIndicator']}
          title="长期医嘱标志"
          valueType="custom"
          render={(...[, record]) => {
            return record?.repeatIndicator ? '长期' : '临时';
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱正文"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderDrug', 'groupNumber']}
          title="医嘱组号"
          valueType="digit"
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['groupSubNumber']}
          title="医嘱序号"
          valueType="digit"
          fieldProps={{}}
        /> */}

        <VSTableColumn
          dataIndex={['dosage']}
          title="剂量"
          valueType="inputUnit"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['orderDrug', 'administration']}
          title="给药途径"
          valueType="select"
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSTableColumn
          dataIndex={['receiveStatus']}
          title="状态"
          valueType="text"
          render={(...[, record]) =>
            record?.receiveStatus ? '已接收' : '未接收'
          }
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Container>
  );
};
export default definePage(DrugReceiveAndCheckList);
