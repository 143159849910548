import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getDischargePatientInfoVoByInpVisitId_504d06: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-discharge-patient-info-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DischargePatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_dischargeNoticeSettle_d9b5af: {
    method: 'post',
    url: '/api/clinic-inp-adt/discharge-notice-settle',
    parameterFomatter: (data?: {
      saveInpVisitForDischargeBto: SaveInpVisitForDischargeBtoClinicInpVisitServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  UserStaffController_getPagedByUserStaffQto_c82b82: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-user-staff-qto',
    parameterFomatter: (data?: { qto: UserStaffNewQtoUserStaffPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
