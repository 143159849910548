import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  message,
} from '@vs/vsf-kit';
import { useLockFn, useRequest, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import MyButton from '@/pages/Index/components/my_button';
import vsf, { definePage } from '@vs/vsf-boot';

import { convertSaveMedicalRecordRes2SaveMedicalRecordReq } from '../convert';
import CostInfo from './costInfo';
import DiagnosticInfo from './diagnosticInfo';
import OperationInfo from './operationInfo';
import OtherInfo from './otherInfo';
import PatientInfo from './patientInfo';

import './index.less';

const Index = (props) => {
  const {
    detail1,
    detail2,
    detail3,
    detail4,
    detail5,
    diagnosticForm,
    operationForm,
    otherInfoTable,
    value,
    stores,
    patientIdentificationList,
    getMedicalRecord,
    detail,
  } = props;

  const { common, user } = stores || {};
  const { getServerTime } = common || {};
  const { initKey, currentPatient, inVisitInfo } = user ?? {};
  const [idCardNumList, setIdCardNumList] = useState(patientIdentificationList);

  const homePageInfoValidate = async () => {
    const res = await vsf.refs?.operationRecordTable
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 患者信息form
    const res1 = await vsf.refs?.medicalRecordPatientInfo
      // ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 诊断table
    const res2 = await vsf.refs?.diagnosticInfoTable
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 诊断form
    const res3 = await vsf.refs?.diagnosisInfoForm
      // ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 手术table
    const res4 = await vsf.refs?.operationRecordTable
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 手术form
    const res5 = await vsf.refs?.operationInfoForm
      // ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 其它table
    const res6 = await vsf.refs?.otherInfoTable
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue();
    // 其它form
    const res7 =
      await vsf.refs?.otherInfoForm?.validateFieldsReturnFormatValue();
    if (res) {
      return res;
    } else {
      throw '有表格项未填写';
    }
  };

  // 重置
  const handelReset = () => {
    getMedicalRecord?.();
  };

  const getPatientIdentificationDtoList = (v) => {
    if (v) {
      setIdCardNumList(v);
    } else {
      setIdCardNumList(patientIdentificationList);
    }
  };
  console.log(value, 'value');
  return (
    <div className="homepageinfo">
      {value && (
        <div
          style={{
            overflowY: value === 'diagnostic-info' ? 'hidden' : 'scroll',
          }}
          className="homepageinfo_top hideScroll"
        >
          {value === 'patient-info' && (
            <PatientInfo
              data={detail1}
              patientIdentificationList={patientIdentificationList}
              getList={getPatientIdentificationDtoList}
            />
          )}
          {value === 'diagnostic-info' && (
            <DiagnosticInfo data={detail2} diagnosticForm={diagnosticForm} />
          )}
          {value === 'operation-info' && (
            <OperationInfo data={detail3} operationForm={operationForm} />
          )}
          {value === 'cost-info' && <CostInfo data={detail5} />}
          {value === 'other-info' && (
            <OtherInfo data={detail4} otherInfoTable={otherInfoTable} />
          )}
        </div>
      )}

      <div className="homepageinfo_bottom">
        <MyButton>打印预览</MyButton>
        <MyButton buttonProps={{ ghost: true }}>打印正面</MyButton>
        <MyButton>打印反面</MyButton>
        <MyButton buttonProps={{ ghost: true, onClick: handelReset }}>
          重置
        </MyButton>
        <MyButton
          buttonProps={{
            onClick: async () => {
              // homePageInfoValidate();
              const res1 = vsf.refs?.medicalRecordPatientInfo
                ? vsf.refs?.medicalRecordPatientInfo?.getFieldsValue()
                : detail?.patientInfo;
              const res2 = vsf.refs?.diagnosisInfoForm
                ? vsf.refs?.diagnosisInfoForm?.getFieldsValue()
                : detail?.diagnosticForm;
              const res3 = vsf.refs?.diagnosticInfoTable
                ? vsf.refs?.diagnosticInfoTable?.getValues()
                : detail?.diagnosticInfo;
              const res4 = vsf.refs?.operationRecordTable
                ? vsf.refs?.operationRecordTable?.getValues()
                : detail?.operationInfo;
              const res5 = vsf.refs?.operationInfoForm
                ? vsf.refs?.operationInfoForm?.getFieldsValue()
                : detail?.operationInfoForm;
              const res6 = vsf.refs?.otherInfoForm
                ? vsf.refs?.otherInfoForm?.getFieldsValue()
                : detail?.otherInfoForm;
              const res7 = vsf.refs?.otherInfoTable
                ? vsf.refs?.otherInfoTable?.getValues()
                : detail?.otherInfoTable;
              console.log(
                res6,
                'res6',
                vsf.refs?.otherInfoForm?.getFieldsValue(),
              );
              const output = convertSaveMedicalRecordRes2SaveMedicalRecordReq({
                ...detail,
                ...res1,
                ...res2,
                ...res5,
                ...res6,
                ...res7,
                diagnosticInfoTable: res3,
                operationRecordTable: res4,
                otherInfoTable: res7,
                patientIdentificationBtoList: idCardNumList
                  ? idCardNumList
                  : detail?.patientInfo?.patientIdentificationDtoList,
                // patientIdentificationBtoList,
              });
              console.log(res5, '诊断123');
              const params = {
                ...output,
                updatePatientBto: {
                  ...output?.updatePatientBto,
                  id: currentPatient?.patientId,
                },
                saveInpVisitForMedicalRecordBto: {
                  ...output?.saveInpVisitForMedicalRecordBto,
                  id: inVisitInfo?.inpVisitId,
                },
                saveInpVisitExtensionStatisticsBto: {
                  ...output?.saveInpVisitExtensionStatisticsBto,
                  patientId: currentPatient?.patientId,
                  inpVisitId: inVisitInfo?.inpVisitId,
                },
                saveInpVisitExtensionNurseRecordForMedicalRecordBto: {
                  ...output?.saveInpVisitExtensionNurseRecordForMedicalRecordBto,
                  inpVisitId: inVisitInfo?.inpVisitId,
                },
                saveInpVisitExtensionBloodInfoBto: {
                  ...output?.saveInpVisitExtensionBloodInfoBto,
                  patientId: currentPatient?.patientId,
                  inpVisitId: inVisitInfo?.inpVisitId,
                },
                createDiagnosisRecordBtoList:
                  output?.createDiagnosisRecordBtoList
                    ?.filter((item) => {
                      return item?.diagnosis?.diagnosisCode;
                    })
                    .map((i) => {
                      const { id, ..._i } = i;
                      return String(i.id).startsWith('create_') ? _i : i;
                    }),
                saveOperationRecordBtoList: output?.saveOperationRecordBtoList
                  ?.filter((item) => {
                    return item?.operationDescription;
                  })
                  .map((i, index) => {
                    const _item =
                      index == 0
                        ? { ...i, mainOperationIndicator: true }
                        : { ...i, mainOperationIndicator: false };
                    const { id, ..._i } = _item;

                    return String(i.id).startsWith('create_') ? _i : _item;
                  }),
                saveIcuRecordBtoList: output?.saveIcuRecordBtoList
                  ?.filter((item) => {
                    return item?.department;
                  })
                  .map((i) => {
                    const { id, ..._i } = i;
                    return String(i.id).startsWith('create_')
                      ? { ..._i, departmentId: i?.department?.id }
                      : { ...i, departmentId: i?.department?.id };
                  }),
                saveBloodTransfusionRecordBto: {
                  ...output?.saveBloodTransfusionRecordBto,
                  patientId: currentPatient?.patientId,
                  inpVisitId: inVisitInfo?.inpVisitId,
                },
                saveDiagnosisCompareRecordBtoList:
                  output?.saveDiagnosisCompareRecordBtoList ?? [],
              };
              const res =
                await vsf.services?.ClinicInpVisitController_saveMedicalRecord_a12ec8?.(
                  params,
                );
              if (res?.code == 200) {
                message.success('保存成功');
                setTimeout(() => {
                  getMedicalRecord?.();
                }, 100);
              }
            },
          }}
        >
          保存
        </MyButton>
      </div>
    </div>
  );
};

export default definePage(Index);
