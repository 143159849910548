import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getFamilyHistoryById_d003a3: {
    method: 'post',
    url: '/api/clinic-record-history/get-family-history-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FamilyHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  BaseDictionaryController_getAllByBaseCodeCodeTypeCodeQto_267d9f: {
    method: 'post',
    url: '/api/base-dictionary/get-all-by-base-code-code-type-code-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeCodeTypeCodeQtoBaseDictionaryPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
});
