import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  message,
  Popover,
  Section,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import RightContextMenu from '@/pages/Index/components/right_click_menu';
import { handelSexIcon } from '@/pages/Index/manage/bed_card/content/bedCardList';
import { convertGet } from '@/pages/Index/manage/bed_card/convert';
import { getScrollY } from '@/utils';
import { handleSex } from '@/utils/index';

import { doctorRightContextMenuData } from '../convert';

const Index = (props) => {
  const {
    onSelect,
    patientType,
    type,
    cardList = [],
    renderIcon,
    stores,
    handelViewDetail,
  } = props;

  const { user } = stores ?? {};
  // const [cardList, setCardList] = useState<any>([]);
  // const [currentSimple, setCurrentSimple] = useState<any>();
  const [isShowTag, setIsShowTag] = useState<boolean>();

  // 码表转换
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    return _res?.[0]?.label;
  };
  // 计算时间
  const handelTime = (time) => {
    const currentTime = new Date();

    return dayjs(currentTime).diff(time ?? currentTime, 'day');
  };
  //床号字体颜色判断
  const handelbedNum = (item) => {
    const getTime = (value) => {
      return +new Date(value);
    };
    if (item?.preTransferIndicator) {
      // 待转科
      return {
        class:
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num1',
        value: '待转科',
      };
    } else if (
      // 当天新入科
      !item?.preTransferIndicator &&
      getTime(item?.admissionWardDateTime) >
        getTime(item?.expectedDischargeDate) &&
      dayjs(item?.admissionWardDateTime).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        label: '新入科',
        class:
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num2',
      };
    } else if (
      // 今日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class:
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num4',
        value: '今日出院',
      };
    } else if (
      // 明日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 00:00:00'),
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class:
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num3',
        label: '明日出院',
      };
    } else {
      return {
        label: '明日出院',
        class:
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num5',
      };
    }
  };
  // 护理等级颜色
  const handelNursingClass = (value) => {
    if (value == 0) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_teji',
        value: '护理等级：特级',
      };
    } else if (value == 1) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_yiji',
        value: '护理等级：一级',
      };
    } else if (value == 2) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_erji',
        value: '护理等级：二级',
      };
    } else if (value == 3) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_sanji',
        value: '护理等级：三级',
      };
    } else {
      return {};
    }
  };
  //  右键禁用
  const handelDisabled = (detail?) => {
    // occupyBedRightContextMenuData
    const res = doctorRightContextMenuData?.map((item) => {
      if (item?.value === 'doctorOrder') {
        return { ...item };
      } else {
        return { ...item };
      }
    });
    return res;
  };
  const handelClickMenuItem = (value, detail) => {
    if (value?.value === 'order') {
      // 医嘱
      // vsf?.navigateTo(`/index/cpoe/center?type=order`);
      vsf?.navigateTo('/index/cpoe/center?type=order');
    } else if (value?.value === 'basicInfo') {
      // 基本信息
      handelViewDetail?.(detail);
    } else if (value?.value === 'report') {
      // 检查检验报告
      vsf?.navigateTo(`/index/cpoe/center?type=report`);
    } else {
      return message.info('todo');
    }
  };
  // 床号
  const renderBedNum = (value, item) => {
    return (
      <div
        className={cn(
          'doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text_num',
          handelbedNum(item)?.class,
        )}
      >
        {value}床
      </div>
    );
  };
  // 占床
  const occupyBed = (item, index) => {
    // console.log(index);

    return (
      <div
        className="doctor_station_my_bed_card_list_detail_item_content"
        // style={{ position: 'relative' }}
        style={{
          position: 'relative',
          borderTop: handelNursingClass(item?.nursingClass)?.class
            ? '1px solid transparent'
            : '1px solid #d6d8d9',
        }}
        // className={cn()}
      >
        <ContextMenuTrigger
          id={'contextMenuDoctorOccupyBed' + index}
          holdToDisplay={200}
        >
          {RightContextMenu({
            detail: item,
            dataSource: handelDisabled?.(item),
            id: `contextMenuDoctorOccupyBed${index}`,
            handelClickMenuItem: handelClickMenuItem,
          })}
          <Popover content={handelNursingClass(item?.nursingClass)?.value}>
            <div
              className={cn(
                'doctor_station_my_bed_card_list_detail_item_content_topbgc',
                handelNursingClass(item?.nursingClass)?.class,
              )}
            ></div>
          </Popover>
          <div className="doctor_station_my_bed_card_list_detail_item_content_body">
            <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1">
              <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_tag">
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_text">
                  <Popover content={renderBedNum(item?.bedLabel, item)}>
                    <span className="text_ellipsis" style={{ maxWidth: 60 }}>
                      {renderBedNum(item?.bedLabel, item)}
                    </span>
                  </Popover>
                  <Popover content={handelbedNum(item)?.value}>
                    <span>{handelbedNum(item)?.value}</span>
                  </Popover>
                </div>
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_tag_icon">
                  {renderIcon?.(item)}
                </div>
              </div>
              {/* <Popover content={handelNursingClass(item?.nursingClass)?.value}> */}
              <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_info">
                <div className="text_ellipsis" style={{ display: 'flex' }}>
                  <span
                    style={{ maxWidth: 110 }}
                    className="doctor_station_my_bed_card_list_detail_item_content_body_item1_info_name
                    text_ellipsis
                    "
                  >
                    <Popover content={item?.patientName}>
                      {item?.patientName}
                    </Popover>
                  </span>
                  <Popover
                    content={
                      handleSex(
                        item?.patientGender,
                        vsf.stores.dbenums?.enums?.SEX_DICT,
                      )?.label
                    }
                  >
                    {handelSexIcon(item?.patientGender)}
                  </Popover>
                </div>
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_info_name_age">
                  <Popover content={item?.patientAge}>
                    {item?.patientAge}
                  </Popover>
                </div>
              </div>
              <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_type">
                {/* <span style={{ maxWidth: 110 }}> */}
                <Popover content={item?.displayId}>
                  <span
                    className="text_ellipsis"
                    style={{
                      maxWidth: 110,
                      flexShrink: 0,
                      wordBreak: 'break-all',
                    }}
                  >
                    {item?.displayId}
                  </span>
                </Popover>
                {/* </span> */}
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item1_type_text">
                  <Popover
                    content={handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  >
                    {handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  </Popover>
                </div>
              </div>
              {/* </Popover> */}
            </div>
            <div className="doctor_station_my_bed_card_list_detail_item_content_body_item2">
              <div className="doctor_station_my_bed_card_list_detail_item_content_body_item2_content">
                <div
                  className="
                  doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item
                  text_ellipsis
                  "
                  style={{
                    maxWidth: '50%',
                    flexShrink: 0,
                    wordBreak: 'break-word',
                  }}
                >
                  <Popover content={item?.attendingDoctorName}>
                    <span>医生：</span>
                    <span>{item?.attendingDoctorName}</span>
                  </Popover>
                </div>
                <div
                  className="text_ellipsis doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item"
                  style={{
                    maxWidth: '50%',
                    flexShrink: 0,
                    wordBreak: 'break-word',
                  }}
                >
                  <Popover content={item?.inChargeNurseName}>
                    <span>护士：</span>
                    <span>{item?.inChargeNurseName}</span>
                  </Popover>
                </div>
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.diagnosisDescription}>
                    <span className="text_ellipsis">
                      {item?.diagnosisDescription ?? '暂无'}
                    </span>
                  </Popover>
                </div>
                <div
                  style={{ wordBreak: 'break-all' }}
                  className="text_ellipsis doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item"
                >
                  <Popover content={item?.needPayAmount}>
                    <span>金额：</span>
                    <span className="" style={{ width: 36 }}>
                      {item?.needPayAmount}
                    </span>
                  </Popover>
                </div>
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.admissionWardDateTime}>
                    <span>入科：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.admissionWardDays}天
                    </span>
                  </Popover>
                </div>
                <div className="doctor_station_my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.operationEndDateTime}>
                    <span>术后：</span>
                    <span>{item?.afterOperationDays}天</span>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </ContextMenuTrigger>
      </div>
    );
  };
  // 床位状态
  const renderBedStatus = (type, item, index) => {
    // console.log(type, index, 'typetype');

    switch (type) {
      // 空床
      case '0':
        return (
          <div
            style={{ position: 'relative' }}
            className="doctor_station_my_bed_card_list_detail_item_content"
          >
            <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty">
              <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed">
              <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                <Icon size={88} type="icon-kongchuang" />
              </div>
              <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_name">
                空床
              </div>
            </div>
          </div>
        );
      //  占床
      case '1':
        return occupyBed(item, index);
      // 停用
      case '4':
        return (
          <div
            style={{ position: 'relative' }}
            className="doctor_station_my_bed_card_list_detail_item_content"
          >
            <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty">
              <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div
              style={{ background: 'rgba(0,0,0,.5)' }}
              className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed"
            >
              <div className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                <Icon size={88} type="icon-tingyong1" />
              </div>
              <div
                style={{ color: '#fff' }}
                className="doctor_station_my_bed_card_list_detail_item_content_detail_card_empty_bed_name"
              >
                停用
              </div>
            </div>
          </div>
        );
    }
  };
  return (
    <div className="doctor_station_my_bed_card_list hideScroll">
      {/* cardList */}
      {cardList?.map((item, index) => {
        return (
          <div
            key={index}
            className="doctor_station_my_bed_card_list_detail_item"
            onDoubleClick={() => {
              if (item?.bedStatus == 0 || item?.status == 4)
                return message.info('当前床位无患者');
              onSelect?.(item);
            }}
          >
            {/* 病历未提交是查以前的床位 */}
            {renderBedStatus(
              patientType === 'NOT_SUBMIT_MEDICAL_RECORD'
                ? '1'
                : item?.bedStatus,
              item,
              index,
            )}
          </div>
        );
      })}
    </div>
  );
};

export default definePage(Index);
