import { getScrollYRem, labelNumberWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Button,
  Todo,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { values } from 'lodash';
import React, { useCallback, useState } from 'react';
const List = ({ detailList, onChange, rowKeys, rowKeyChange, PageType }) => {
  const onFetch = useCallback(
    async (params) => {
      if (detailList) {
        const data = detailList?.outpVisitUnsettledRecord?.map(
          (item, index) => {
            return { id: index, ...item };
          },
        );
        onChange([]);
        detailList.outpVisitUnsettledRecord = data;
        if (
          PageType == 'routes' ||
          detailList?.outpVisitUnsettledRecord?.length === 1
        ) {
          rowKeyChange?.(detailList?.outpVisitUnsettledRecord);
          onChange(detailList?.outpVisitUnsettledRecord);
        }
        return detailList?.outpVisitUnsettledRecord ?? [];
      } else {
        return [];
      }
    },
    [detailList],
  );

  return (
    <VSPromiseTable
      scroll={{ x: 500, y: getScrollYRem(20) }}
      vsid="67681"
      id="unSettledRecordList"
      rowSelection={{
        selectType: 'multiple',
        onSelectedRowsChange: (data, keys) => {
          onChange(data);
        },
        selectedRows: rowKeys,
      }}
      onFetch={onFetch}
      pagination={false}
      className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
      bordered
    >
      <VSTableColumn
        dataIndex={['registerDate']}
        title="挂号时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD' }}
        // width={100}
        // className="columnWidth100"
        width={labelNumberWidth(8)}
        ellipsis
        render={(text, record) => {
          return text;
        }}
      />

      <VSTableColumn
        dataIndex={['clinicLabel']}
        title="号别"
        // width={150}
        // className="columnWidth150"
        width={labelNumberWidth(6)}
        ellipsis
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['doctorName']}
        // width={100}
        // className="columnWidth100"
        width={labelNumberWidth(6)}
        ellipsis
        title="医生姓名"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['chargeTypeCode']}
        title="费别"
        valueType="select"
        // width={150}
        // className="columnWidth150"
        width={labelNumberWidth(4)}
        ellipsis
        dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['identityType']}
        title="人员类别"
        // width={150}
        // className="columnWidth150"
        width={labelNumberWidth(6)}
        ellipsis
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
      />
      <VSTableColumn
        dataIndex={['chargeTypeCode']}
        title="目录外"
        // width={150}
        // className="columnWidth150"
        width={labelNumberWidth(5)}
        valueType="select"
        ellipsis
        dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
        fieldProps={{}}
        render={(_, values) => {
          return '';
        }}
      />
      <VSTableColumn
        dataIndex={['diseaseTypeName']}
        title="病种名称"
        // width={150}
        // className="columnWidth150"
        valueType="select"
        width={labelNumberWidth(6)}
        ellipsis
        dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
        fieldProps={{}}
      />
    </VSPromiseTable>
  );
};
export default List;
