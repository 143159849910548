import {
  askGetTreeWhole,
  askRomanAlphabet,
  askSearch,
} from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import { VSFormItem, VSControlledForm, VSFormLayout } from '@vs/vsf-kit';
import { Cascader } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { labelNumberWidth } from '@/utils';

const PriceListEdit = (props) => {
  const [branch, setBranch] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const getValue = async () => {
    const resolve =
      await vsf?.services.PriceListDtoController_getPriceItemById_c2c8d8({
        id: props.value.id,
      });

    const get = () => {
      const list = askGetTreeWhole(
        resolve?.data?.medicalRecordClass,
        vsf.stores.dbenums.enums.MR_FEE_CLASS_DICT,
        {
          id: 'value',
          children: 'children',
        },
      );
      const map = new Map();
      for (const item of list?.reverse()) {
        if (!map.has(item?.value)) {
          map.set(item?.value, item);
        }
      }
      return [...map.values()]?.reverse()?.map((item) => item?.value);
    };

    vsf.refs.PriceListForm.setFieldsValue({
      ...resolve?.data,
      ...(resolve?.data?.medicalRecordClass
        ? { medicalRecordClass: get() }
        : {}),
    });
  };

  const isValue = useMemo(() => props.value, []);

  const getBranch = useCallback(async () => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );
    if (res.code === 200) {
      setBranch(
        res.data.branchInstitutionList?.map((item) => ({
          label: item?.institutionName,
          value: item?.id,
        })),
      );
    }
  }, []);

  const getFirm = useCallback(async (value) => {
    const resolve =
      await vsf.services.DrugFirmDictionaryDtoController_queryDrugFirmDictionaryNew_617280(
        {
          ...value,
        },
      );
    return resolve.data;
  }, []);

  const getDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.BaseCodeDtoServiceController_getValidCodeList_3cb2b9?.(
        { codeType: 'RECK_ITEM_CLASS_DICT' },
      );
    setDataSource(
      res.data?.map((item) => {
        return {
          label: item.name + `(${item.code})`,
          value: item.code,
        };
      }),
    );
  }, []);

  useEffect(() => {
    if (props.value) {
      getValue();
    }
    if (props.data) {
      vsf.refs?.PriceListForm.setFieldValue('itemClass', props.data?.code);
    }
    getBranch();
    getDataSource();
  }, []);

  return (
    <VSControlledForm
      vsid="72791"
      id="PriceListForm"
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
      labelAlign="left"
      labelWidth="auto"
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={1} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['itemName']}
          label="项目名称"
          valueType="text"
          rules={[
            {
              message: 'item_name长度不合法',
              type: 'string',
              min: 0,
              max: 200,
            },
            { required: true },
          ]}
          fieldProps={{
            onChange: (event) => {
              vsf?.refs?.PriceListForm?.setFieldValue(
                'inputCode',
                askRomanAlphabet(event?.target?.value),
              );
            },
          }}
        />
      </VSFormLayout>
      <VSFormLayout
        key="2"
        columnCount={3}
        gutter={50}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['itemId']}
          label="项目ID"
          valueType="text"
          rules={[
            { message: 'item_id长度不合法', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{
            disabled: isValue,
          }}
        />

        <VSFormItem
          name={['unit']}
          label="单位"
          valueType="text"
          rules={[
            { message: 'units长度不合法', type: 'string', min: 0, max: 20 },
            { required: true },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['itemSpecification']}
          label="项目规格"
          valueType="text"
          rules={[
            {
              message: 'item_spec长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
            { required: true },
          ]}
          fieldProps={{}}
        />
      </VSFormLayout>
      <VSFormLayout
        key="3"
        columnCount={3}
        gutter={50}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['price']}
          label="价格"
          valueType="digit"
          rules={[{ required: true }]}
          initialValue={0}
          fieldProps={{
            addonAfter: '元',
            min: 0,
            precision: 4,
            width: '100%',
          }}
        />
        <VSFormItem
          name={['itemClass']}
          label="类别"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={dataSource}
            filterOption={(value, option) => {
              return askSearch(value, option);
            }}
            width="100%"
            showSearch
            placeholder="请选择类别"
          />
        </VSFormItem>
      </VSFormLayout>
      <VSFormLayout
        key="4"
        columnCount={4}
        gutter={50}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['singleSettleIndicator']}
          label="独立结算"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['outpUseIndicator']}
          label="门诊开展"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['inpUseIndicator']}
          label="住院开展"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['specialNeedIndicator']}
          label="是否特需"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />
      </VSFormLayout>
      <VSFormLayout
        key="5"
        columnCount={2}
        gutter={70}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['inputCode']}
          label="拼音码"
          valueType="text"
          rules={[
            { message: '输入码长度不合法', type: 'string', min: 0, max: 50 },
            () => ({
              validator(_, value) {
                if (String(value).includes(',')) {
                  return Promise.reject('多音字待确认');
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['priceAttribute']}
          label="价格属性"
          valueType="select"
          dataSource={[
            { label: '固定', value: 'FIXED' },
            { label: '可变', value: 'VARIABLE' },
          ]}
          rules={[{ required: true }]}
          fieldProps={{
            showSearch: true,
          }}
        />
        <VSFormItem
          name={['firm']}
          label="厂商"
          valueType="select"
          searchKey="name"
          dataSource={getFirm}
          fieldNames={{
            label: 'firmName',
            value: 'id',
          }}
          fieldProps={{
            showSearch: true,
          }}
        />
        <VSFormItem
          name={['branchInstitutionIdList']}
          label="院区"
          valueType="select"
          dataSource={branch}
          fieldProps={{
            mode: 'multiple',
            showSearch: true,
          }}
        />
        <VSFormItem
          name={['biddingCode']}
          label="中标编码"
          valueType="text"
          rules={[
            {
              message: 'bidding_code长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['brand']}
          label="品牌"
          valueType="text"
          rules={[
            { message: 'brand长度不合法', type: 'string', min: 0, max: 80 },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['insuranceItemCode']}
          label="自编码"
          valueType="text"
          rules={[
            {
              message: '医保自编码长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['standardCode']}
          label="标准编码"
          valueType="text"
          rules={[
            {
              message: 'standard_code长度不合法',
              type: 'string',
              min: 0,
              max: 30,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['insuranceNationCode']}
          label="国家编码"
          valueType="text"
          rules={[
            {
              message: 'insurance_country_code长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['medicalRecordClass']}
          label="病案首页分类"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Cascader
            options={vsf.stores.dbenums.enums.MR_FEE_CLASS_DICT}
            changeOnSelect
            showSearch={{
              filter: (inputValue, options) => {
                for (const item of options) {
                  return askSearch(inputValue, item);
                }
              },
            }}
            placeholder="请选择病案首页分类"
          />
        </VSFormItem>
      </VSFormLayout>
      <VSFormLayout
        key="15"
        columnCount={1}
        gutter={200}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['memo']}
          label="备注"
          valueType="textarea"
          rules={[
            { message: 'memo长度不合法', type: 'string', min: 0, max: 300 },
          ]}
          fieldProps={{}}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(PriceListEdit);
