import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import {
  patientDetailConditionIcon,
  renderDrugResistance,
} from '@/pages/Index/manage/bed_card/convert';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import storage from '@/utils/storage';

import AllergyHistoryModal from './allergyHistoryModal';
import BedCard from './content/bedCardList';
import {
  convertFirstBedData2FirstReqParam,
  convertGet,
  convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo,
  convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail,
  convertSecondBedData2SecondReqParam,
  convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq,
  currentWardTotalData,
  occupyBedRightContextMenuData,
  totalData,
} from './convert';
import DetailModal from './detailModal/index';

const Index = (props) => {
  const {
    common,
    user: { setCurrentOrderPatient },
  } = props?.stores || {};
  const { currentApplication } = common || {};
  // console.log(currentApplication);

  const [cardList, setCardList] = useState<any>([]);
  // 统计loading
  const [totalLoading, setTotalLoading] = useState<boolean>(true);
  // 当前统计项
  const [currentSelectCarBed, setCurrentSelectCarBed] = useState<any>();
  // 统计列表
  const [totalList, setTotalList] = useState<any>([]);
  // 本科室统计列表
  const [currentWardTotalList, setCurrentWardTotalList] = useState<any>([]);
  //详卡/简卡/列表
  const [radioGroupValue, setRadioGroup] = useState('detail');
  //护理组筛选
  const [wardGroup, setWardGroup] = useState();
  // 当前选择列表项:left/当前列表选中项
  const [currentSelectCarBedList, setCurrentSelectCarBedList] = useState<any>();
  // 当前列表选中行信息（列表）
  const [
    currentSelectPatientInfoInpVisitId,
    setCurrentSelectPatientInfoInpVisitId,
  ] = useState<any>();
  // 拖动放到的床卡 right
  const [currentSelectCarBedRightList, setCurrentSelectCarBedRightList] =
    useState<any>();
  // 换床modal
  const [bedModal, setBedModal] = useState(false);
  // 换床选择id left
  const [changeBedLeftId, setChangeBedLeftId] = useState<any>();
  // 换床选择id right
  const [changeBedRightId, setChangeBedRightId] = useState<any>();
  // 查详情-拖动
  const [selectDetailLeft, setSelectDetailLeft] = useState<any>();
  const [selectDetailRight, setSelectDetailRight] = useState<any>();
  // 患者详情
  const [bedCardDetail, setBedCardDetail] = useState<any>();
  // 转科是否禁用
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const cardRef = useRef<any>();
  // 患者详情弹窗
  const modalRef = useRef<any>();
  // 过敏史弹窗
  const allergyHistorymodalRef = useRef<any>();
  // const currentApplication = storage.get('currentApplication');

  const explainIcon = [
    { label: '皮试阳性', icon: 'icon-yangxing', width: '50%' },
    { label: '过敏史', icon: 'icon-guomin', width: '50%' },
    { label: '病危', icon: 'icon-bingwei1', width: '50%' },
    { label: '病重', icon: 'icon-bingzhong', width: '50%' },
    { label: '隔离', icon: 'icon-geli', width: '100%' },
    { label: '已预约未打印的检查', icon: 'icon-weidayin', width: '100%' },
    { label: '护理路径', icon: 'icon-hulilujing', width: '50%' },
    { label: '耐药标识', icon: 'icon-naiyaobiaoshi', width: '50%' },
  ];

  useEffect(() => {
    if (radioGroupValue === 'list') return;
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioGroupValue, wardGroup, currentApplication]);

  useEffect(() => {
    if (currentSelectCarBedList?.id && bedModal) {
      getClinicBedWithPatientVoById(currentSelectCarBedList?.id, 'first');
    }
    if (currentSelectCarBedRightList?.id && bedModal) {
      getClinicBedWithPatientVoById(currentSelectCarBedRightList?.id, 'second');
    }
  }, [currentSelectCarBedList, currentSelectCarBedRightList, bedModal]);

  const handelAllByInpTransferApplyByVisitIdAndStatus =
    useCallback(async () => {
      const inpTransferApplyRes =
        await vsf.services?.ClinicInpAdtController_getAllByInpTransferApplyByVisitIdAndStatusQto_d0e747?.(
          {
            qto: {
              from: 0,
              size: 1,
              // inpVisitIdIs: 2101,
              inpVisitIdIs: currentSelectPatientInfoInpVisitId,
              statusIn: ['WAIT_AUDIT', 'AUDIT_SUCCESS'],
            },
          },
        );
      if (inpTransferApplyRes && inpTransferApplyRes?.data?.length > 0) {
        // AUDIT_SUCCESS
        if (inpTransferApplyRes?.data?.[0]?.status === 'AUDIT_SUCCESS') {
          setIsDisable(false);
        }
      } else {
        setIsDisable(true);
      }
    }, [currentSelectPatientInfoInpVisitId]);

  useEffect(() => {
    if (!currentSelectPatientInfoInpVisitId) return;
    handelAllByInpTransferApplyByVisitIdAndStatus();
    // currentSelectPatientInfoInpVisitId
  }, [
    currentSelectPatientInfoInpVisitId,
    handelAllByInpTransferApplyByVisitIdAndStatus,
  ]);

  const getTableList = useCallback(
    async (params?) => {
      if (radioGroupValue !== 'list') return {};
      // 本病区下所有床位
      const res =
        await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: 0,
              size: 50,
              //护理组
              bedGroupIdIs: wardGroup,
              // 统计选择全科需要传当前病区病区-写死
              // wardIdIs: currentSelectCarBed === 'WARD' ? 359 : undefined,
              wardIdIs: currentApplication?.department?.id,
            },
            ext: {},
          },
        );
      const customResult = convertGet(res);
      const _customResult = customResult.map((item) => {
        return { ...item, isShowDetail: false };
      });
      setCardList(_customResult);
      return {
        data: customResult ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [radioGroupValue, wardGroup, currentApplication?.department?.id],
  );

  const refresh = () => {
    window.location.reload();
  };
  // 本病区统计
  const getCurrentWardTotal = useCallback(async () => {
    // const arr: any = [];
    const arr = currentWardTotalData?.map((item) => {
      return item?.value;
    });

    const res =
      await vsf.services?.ClinicBedController_queryBedPatientStatistics_3cd3df({
        statisticsCodes: arr,
        qto: {
          // 写死
          // wardIdIs: item?.value === 'WARD' ? 359 : undefined,
          wardIdIs: currentApplication?.department?.id,
        },
      });
    // const _totalList = res?.data?.result?.map((item) => {
    //   return { ...item?.data?.result?.[0] };
    // });
    // const _data = _totalList.map((item) => {
    //   const res = currentWardTotalData?.find((_item) => {
    //     return item?.code === _item?.value;
    //   });
    //   return { ...item, label: res?.label };
    // });
    setCurrentWardTotalList(res?.data?.result);
  }, [currentApplication?.department?.id]);

  useEffect(() => {
    getCurrentWardTotal();
  }, [getCurrentWardTotal]);

  // 统计查询
  const getTotal = async () => {
    setTotalLoading(true);
    const _res = totalData?.map((item) => {
      return item?.value;
    });
    const response =
      await vsf.services?.ClinicBedController_queryBedPatientStatistics_3cd3df({
        statisticsCodes: _res,
        qto: {
          // 写死
          // wardIdIs: item?.value === 'WARD' ? 359 : undefined,
          wardIdIs: currentApplication?.department?.id,

          // 护理组筛选
          bedGroupIdIs: wardGroup,
        },
      });
    setTotalLoading(false);
    setTotalList(response?.data?.result);
  };
  // 换床下拉数据源
  const getListClinicBedVoByWardId = async () => {
    const res =
      await vsf.services?.ClinicBedController_getListClinicBedVoByWardId_662549(
        {
          // 写死
          wardId: currentApplication?.department?.id,
        },
      );
    return res?.data?.map((item) => {
      return { ...item, bedLabel: item?.bedLabel + '床' };
    });
  };
  // 床卡拖动换床-打开弹窗
  const bedCardChange = (left, right) => {
    setCurrentSelectCarBedList({
      id: left?.id,
      bedLabel: left?.bedLabel + '床',
      bedStatus: left?.bedStatus,
      inpVisitId: left?.inpVisitId,
    });
    setCurrentSelectCarBedRightList({
      id: right?.id,
      bedLabel: right?.bedLabel + '床',
      bedStatus: right?.bedStatus,
      inpVisitId: left?.inpVisitId,
    });
    handelChangeBedModal?.(true);
  };
  // 根据选择的床号查详情
  const getClinicBedWithPatientVoById = async (id, position) => {
    const res =
      await vsf.services?.ClinicBedController_getClinicBedWithPatientVoById_84c713(
        { id },
      );
    if (position === 'first') {
      const _res =
        convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo(
          res,
        );

      setSelectDetailLeft(_res?.[0]);
    } else if (position === 'second') {
      const _res =
        convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo(
          res,
        );
      // console.log(_res?.[0], '090');
      setSelectDetailRight(_res?.[0]);
    }
  };
  // 护理组数据源
  const getListBedGroupVoByWardId = async () => {
    const res =
      await vsf.services?.ClinicBedController_getListBedGroupVoByWardId_532946?.(
        {
          // 写死
          wardId: currentApplication?.department?.id,
        },
      );
    const convert2BedGroupSelectorList = (input) => {
      const _ = window._ ?? {};
      const output = _.get(input, 'data').map((item) => ({
        label: item.groupName,
        value: item.id,
      }));
      return output;
    };
    const resData = convert2BedGroupSelectorList(res);
    console.debug('resData', resData);
    return resData;
  };
  // 码表转换
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    return _res?.[0]?.label;
  };
  // 入科
  const IntoTheFamily = () => {
    if (currentSelectCarBedList?.id) {
      if (currentSelectCarBedList?.bedStatus == '1') {
        message.info('当前床位已存有患者，不能进行入科操作！');
      } else if (currentSelectCarBedList?.bedStatus == '0') {
        vsf.navigateTo(
          `/index/nurse-admission?bedId=${currentSelectCarBedList?.id}&bedLabel=${currentSelectCarBedList?.bedLabel}`,
        );
      } else if (currentSelectCarBedList?.bedStatus == '4') {
        message.info('当前床位已停用，不能进行入科操作！');
      }
    } else {
      message.info('入科请选择一个空床位！');
    }
  };
  // 换床
  const handelChangeBed = async () => {
    const _res1 = convertSecondBedData2SecondReqParam(selectDetailRight);
    const _res2 = convertFirstBedData2FirstReqParam(selectDetailLeft);
    const res =
      await vsf.services?.ClinicBedController_changeBedPatient_d31918?.({
        first: _res1,
        second: _res2,
      });
    if (res?.code === 200) {
      setBedModal(false);
      if (radioGroupValue === 'list') {
        setChangeBedRightId('');
        setChangeBedLeftId('');
        setSelectDetailRight('');
        setSelectDetailLeft('');
        setTimeout(() => {
          vsf.refs.vSPromiseTableBedPatient?.reload();
        }, 100);
      } else {
        cardRef?.current?.getCardList();
      }
    }
  };
  // 换床modal
  const handelChangeBedModal = (value) => {
    setBedModal(value);
  };
  //床号字体颜色判断
  const handelbedNum = (item) => {
    const getTime = (value) => {
      return +new Date(value);
    };
    if (item?.preTransferIndicator) {
      // 待转科
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num1',
        label: '待转科',
      };
    } else if (
      // 当天新入科
      !item?.preTransferIndicator &&
      getTime(item?.admissionWardDateTime) >
        getTime(item?.expectedDischargeDate) &&
      dayjs(item?.admissionWardDateTime).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num2',
        label: '新入科',
      };
    } else if (
      // 今日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      // console.log(item?.expectedDischargeDate, item);

      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num4',
        label: '今日出院',
      };
    } else if (
      // 明日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 00:00:00'),
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num3',
        label: '明日出院',
      };
    } else {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num5',
        label: '',
      };
    }
  };
  // 患者详情modal
  const handelViewDetail = (detail) => {
    const data = detail?.id
      ? {
          id: detail?.id,
          bedStatus: detail?.bedStatus,
          bedLabel: detail?.bedLabel + '床',
          inpVisitId: detail?.inpVisitId,
        }
      : currentSelectCarBedList;
    if (data?.id) {
      if (data?.bedStatus == '1') {
        // 打开患者详情弹窗
        modalRef?.current?.handelModal(true, data);
      } else if (data?.bedStatus == '0' || data?.bedStatus == '4') {
        message.info('该床位上不存在患者，无法进行操作！');
      }
    } else {
      message.info('请选择床位后再操作！');
    }
  };
  // 过敏史modal
  const handelAllergyDetail = (detail) => {
    // console.log(detail);

    allergyHistorymodalRef?.current?.handelModal(true, detail);
    // const data = detail?.id
    //   ? {
    //       id: detail?.id,
    //       bedStatus: detail?.bedStatus,
    //       bedLabel: detail?.bedLabel + '床',
    //       inpVisitId: detail?.inpVisitId,
    //     }
    //   : currentSelectCarBedList;
    // if (data?.id) {
    //   if (data?.bedStatus == '1') {
    //     // 打开患者详情弹窗
    //     modalRef?.current?.handelModal(true, data);
    //   } else if (data?.bedStatus == '0' || data?.bedStatus == '4') {
    //     message.info('该床位上不存在患者，无法进行操作！');
    //   }
    // } else {
    //   message.info('请选择床位后再操作！');
    // }
  };
  // 床号
  const renderBedNum = (value, item) => {
    return (
      <div
        className={cn(
          'my_bed_card_list_detail_item_content_body_item1_tag_text_num',
          handelbedNum(item)?.class,
        )}
      >
        {value}床
      </div>
    );
  };

  const renderIcon = (value) => {
    return (
      <div>
        {patientDetailConditionIcon?.map((item: any, index) => {
          if (item?.type === 'arr') {
            // 耐药
            if (item?.isShow === 'drugResistanceType') {
              return value?.[item?.content]?.length === 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={renderDrugResistance(value?.[item?.content]?.[0])}
                  />
                </Popover>
              ) : value?.[item?.isShow]?.length > 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else if (item?.isShow === 'patientAllergyIndicator') {
              // 过敏
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content].join(' ； ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else {
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            }
          } else if (item?.type === 'string') {
            return value?.[item?.isShow] ? (
              <Popover key={item?.isShow} content={item?.content}>
                <Icon
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.icon}
                />
              </Popover>
            ) : (
              ''
            );
          }
        })}
      </div>
    );
  };
  const renderIcon1 = (value) => {
    return (
      <div>
        {patientDetailConditionIcon?.map((item, index) => {
          if (item?.isShow === 'crisisIndicator') {
            if (value?.[item?.isShow]) {
              return (
                <Icon
                  key={index}
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.label}
                />
              );
            } else if (value?.[item?.isShowZhong as string]) {
              return (
                <Icon
                  key={index}
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.labelZhong as string}
                />
              );
            } else {
              return '';
            }
          } else if (item?.isShow === 'isolationIndicator') {
            return value?.[item?.isShow] ? (
              <Popover
                key="index"
                content={handelStopwatch(
                  value?.[item?.value],
                  vsf.stores.dbenums?.enums?.ISOLATION_TYPE_DICT,
                )}
                style={{
                  display: value?.[item?.isShow] ? 'block' : 'none',
                }}
              >
                <Icon
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.label}
                />
              </Popover>
            ) : (
              ''
            );
          } else {
            return value?.[item?.isShow] ? (
              <Popover
                key="index"
                content={value?.[item?.value]}
                style={{
                  display: value?.[item?.isShow] ? 'block' : 'none',
                }}
              >
                <Icon
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.label}
                />
              </Popover>
            ) : (
              ''
            );
          }
        })}
      </div>
    );
  };

  const renderExplain = () => {
    return (
      <div className="nurses_stationbed_card_search_input_right_item_explain">
        <div className="nurses_stationbed_card_search_input_right_item_explain_title">
          状态条护理等级
        </div>
        <div className="nurses_stationbed_card_search_input_right_item_explain_leave">
          <div className="nurses_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#E34A4E' }}
              className="nurses_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            特级
          </div>
          <div className="nurses_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#ff7d45' }}
              className="nurses_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            一级
          </div>
          <div className="nurses_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#FFD900' }}
              className="nurses_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            二级
          </div>
          <div className="nurses_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#AEB0B3' }}
              className="nurses_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            三级
          </div>
        </div>
        <div className="nurses_stationbed_card_search_input_right_item_explain_title">
          图标信息
        </div>
        <div className="nurses_stationbed_card_search_input_right_item_explain_icon">
          {explainIcon?.map((item, index) => {
            return (
              <div
                key={index}
                className="nurses_stationbed_card_search_input_right_item_explain_icon_item"
                style={{ width: item?.width }}
              >
                <div className="nurses_stationbed_card_search_input_right_item_explain_icon_item_icon">
                  <Icon type={item?.icon} size={16} />
                </div>
                <div>{item?.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="nurses_stationbed_card_search_input">
        <div className="nurses_stationbed_card_search_input_left">
          <div className="nurses_stationbed_card_search_input_left_item">
            <RadioGroup
              optionType="button"
              defaultValue="detail"
              value={radioGroupValue}
              onChange={(v) => {
                setCurrentSelectCarBed('');
                setCurrentSelectCarBedList('');
                setRadioGroup(v);
              }}
              dataSource={[
                { label: '详卡', value: 'detail' },
                { label: '简卡', value: 'simple' },
                { label: '列表', value: 'list' },
              ]}
            ></RadioGroup>
          </div>
          <div className="nurses_stationbed_card_search_input_left_item">
            护理组筛选
            <span className="nurses_stationbed_card_search_input_left_item_select">
              <Select
                width={240}
                dataSource={getListBedGroupVoByWardId}
                showSearch={true}
                onChange={(v) => {
                  setWardGroup(v);
                }}
                allowClear
              ></Select>
            </span>
          </div>
          <div className="nurses_stationbed_card_search_input_left_item">
            <span> 本病区：</span>【
            <div className="nurses_stationbed_card_search_input_left_item_total">
              {currentWardTotalList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="nurses_stationbed_card_search_input_left_item_total_item"
                  >
                    {item?.name}：{item?.value}
                    {item?.unit}
                  </div>
                );
              })}
            </div>
            】
          </div>
          <div className="nurses_stationbed_card_search_input_left_item"></div>
        </div>
        <div className="nurses_stationbed_card_search_input_right">
          <div className="nurses_stationbed_card_search_input_right_item">
            <div>
              <Icon size={20} type="icon-daiban" />
            </div>
            <div>代办</div>
          </div>
          <div className="nurses_stationbed_card_search_input_right_item">
            <Popover
              className="nurses_stationbed_card_search_input_right_item_popover"
              placement="bottomLeft"
              content={renderExplain()}
              color={'rgba(1, 16, 53, 0.5)'}
              // open={true}
            >
              <div>
                <Icon
                  type="icon-bangzhu"
                  style={{ color: '#3276E8' }}
                  size={20}
                />
              </div>
              <div>说明</div>
            </Popover>
          </div>
          <div
            className="nurses_stationbed_card_search_input_right_item"
            onClick={refresh}
          >
            <div>
              <Icon
                size={20}
                type="icon-shuaxin"
                style={{ color: '#3276E8' }}
              />
            </div>
            <div>刷新</div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // console.log(radioGroupValue, '7412_radioGroupValue');
    switch (radioGroupValue) {
      case 'detail':
        return (
          <div className="nurses_stationbed_card_content">
            <div className="nurses_stationbed_card_content_total">
              <Block title="过滤及统计" titleStyle={{ fontWeight: '600' }}>
                {totalList?.length > 0 && !totalLoading && (
                  <div className="nurses_stationbed_card_content_total_table">
                    <div className="nurses_stationbed_card_content_total_table_header">
                      <div className="nurses_stationbed_card_content_total_table_header_title">
                        {totalList?.[0]?.name}
                      </div>
                      <div className="nurses_stationbed_card_content_total_table_header_amount">
                        {totalList?.[0]?.value}
                        {totalList?.[0]?.unit}
                      </div>
                    </div>
                    <div className="nurses_stationbed_card_content_total_table_content hideScroll">
                      {totalList?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: item?.code === 'BED_COUNT' ? 'none' : '',
                            }}
                            className={cn(
                              'nurses_stationbed_card_content_total_table_content_item',
                              item?.code === currentSelectCarBed
                                ? 'nurses_stationbed_card_content_total_table_content_active_item'
                                : '',
                            )}
                            onClick={() => {
                              if (
                                currentSelectCarBed &&
                                currentSelectCarBed == item?.code
                              ) {
                                setCurrentSelectCarBed(null);
                              } else {
                                setCurrentSelectCarBed(item?.code);
                              }
                            }}
                          >
                            <div
                              style={{ textAlign: 'center' }}
                              className={cn(
                                'nurses_stationbed_card_content_total_table_content_item_title',
                              )}
                            >
                              {item?.name}
                            </div>
                            <div
                              className={cn(
                                'nurses_stationbed_card_content_total_table_content_item_amount',
                              )}
                            >
                              {item?.value}
                              {item?.unit}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {totalList?.length <= 0 && !totalLoading && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className="nurses_stationbed_card_content_total_table"
                  >
                    <Empty className="nurses_stationbed_card_content_total_empty" />
                  </div>
                )}
                {totalLoading && <Loading size="small" />}
              </Block>
            </div>
            <div className="nurses_stationbed_card_content_list">
              <BedCard
                ref={cardRef}
                currentSelectCarBed={currentSelectCarBed}
                type={radioGroupValue}
                radioGroupValue={radioGroupValue}
                wardGroup={wardGroup}
                bedCardChange={bedCardChange}
                handelViewDetail={handelViewDetail}
                handelAllergyDetail={handelAllergyDetail}
                currentSelectPatientInfoInpVisitId={
                  currentSelectPatientInfoInpVisitId
                }
                setCurrentOrderPatient={setCurrentOrderPatient}
                currentApplication={currentApplication}
                isDisable={isDisable}
              ></BedCard>
            </div>
          </div>
        );
      case 'simple':
        return (
          <div className="nurses_stationbed_card_content">
            <div className="nurses_stationbed_card_content_total">
              <Block title="过滤及统计" titleStyle={{ fontWeight: '600' }}>
                {totalList?.length > 0 && !totalLoading && (
                  <div className="nurses_stationbed_card_content_total_table">
                    <div className="nurses_stationbed_card_content_total_table_header">
                      <div className="nurses_stationbed_card_content_total_table_header_title">
                        {totalList?.[0]?.name}
                      </div>
                      <div className="nurses_stationbed_card_content_total_table_header_amount">
                        {totalList?.[0]?.value}
                        {totalList?.[0]?.unit}
                      </div>
                    </div>
                    <div className="nurses_stationbed_card_content_total_table_content hideScroll">
                      {totalList?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: item?.code === 'BED_COUNT' ? 'none' : '',
                            }}
                            className={cn(
                              'nurses_stationbed_card_content_total_table_content_item',
                              item?.code === currentSelectCarBed
                                ? 'nurses_stationbed_card_content_total_table_content_active_item'
                                : '',
                            )}
                            onClick={() => {
                              setCurrentSelectCarBed(item?.code);
                            }}
                          >
                            <div
                              style={{ textAlign: 'center' }}
                              className={cn(
                                'nurses_stationbed_card_content_total_table_content_item_title',
                              )}
                            >
                              {item?.name}
                            </div>
                            <div
                              className={cn(
                                'nurses_stationbed_card_content_total_table_content_item_amount',
                              )}
                            >
                              {item?.value}
                              {item?.unit}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {totalList?.length <= 0 && !totalLoading && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className="nurses_stationbed_card_content_total_table"
                  >
                    <Empty className="nurses_stationbed_card_content_total_empty" />
                  </div>
                )}
                {totalLoading && <Loading size="small" />}
              </Block>
            </div>
            <div className="nurses_stationbed_card_content_list">
              <BedCard
                currentSelectPatientInfoInpVisitId={
                  currentSelectPatientInfoInpVisitId
                }
                isDisable={isDisable}
                handelViewDetail={handelViewDetail}
                currentSelectCarBed={currentSelectCarBed}
                type={radioGroupValue}
                radioGroupValue={radioGroupValue}
                wardGroup={wardGroup}
              ></BedCard>
            </div>
          </div>
        );
      case 'list':
        return (
          <div className="nurses_stationbed_card_list_content">
            <div className="nurses_stationbed_card_list_content_table">
              <VSPromiseTable
                // editable={{ editType: 'single' }}
                // nurses_stationbed_card_list_content_table_VSPromiseTable
                className="
                aspirin-table-header-background-color
                aspirin-table-row-hover-background-color
                 aspirin-table-nopadding
                 aspirin-table-body-empty-transparent-background-color
                  "
                id="vSPromiseTableBedPatient"
                pagination={false}
                bordered
                onFetch={getTableList}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setCurrentSelectPatientInfoInpVisitId(record?.inpVisitId);
                      setCurrentSelectCarBedList({
                        id: record?.id,
                        bedLabel: record?.bedLabel + '床',
                        bedStatus: record?.bedStatus,
                        inpVisitId: record?.inpVisitId,
                      });
                    },
                  };
                }}
                rowClassName={(v) => {
                  return v?.id == currentSelectCarBedList?.id
                    ? 'aspirin-table-row-select-background-color'
                    : '';
                }}
                scroll={{
                  // y: getScrollY(380)
                  y: getScrollYRem(25.5),
                }}
              >
                <VSTableColumn
                  dataIndex={['bedLabel']}
                  title="床号"
                  valueType="text"
                  fieldProps={{}}
                  width={labelNumberWidth(6)}
                />
                <VSTableColumn
                  dataIndex={['patientName']}
                  title="患者姓名"
                  valueType="text"
                  fieldProps={{}}
                  width={labelNumberWidth(5)}
                  ellipsis
                />
                <VSTableColumn
                  dataIndex={['displayId']}
                  title="患者ID"
                  valueType="text"
                  fieldProps={{}}
                  // width={100}
                  width={labelNumberWidth(8)}
                />
                <VSTableColumn
                  dataIndex={['patientGender']}
                  title="性别"
                  valueType="select"
                  fieldProps={{}}
                  width={labelNumberWidth(7)}
                  dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                />
                <VSTableColumn
                  dataIndex={['patientAge']}
                  title="年龄"
                  valueType="text"
                  fieldProps={{}}
                  width={labelNumberWidth(5)}
                />
                <VSTableColumn
                  dataIndex={['crisisIndicator']}
                  title="病情"
                  valueType="text"
                  // search
                  order={-6}
                  // columnKey={'crisisIndicatorIs'}
                  // hideInTable
                  fieldProps={{}}
                  render={(dom, value) => {
                    // console.log(value, 'valuevaluevalue11');

                    return value?.crisisIndicator ? (
                      <Icon size={16} type="icon-bingwei1" />
                    ) : value?.seriousIndicator ? (
                      <Icon size={16} type="icon-bingzhong" />
                    ) : value?.bedStatus == '0' ? (
                      ''
                    ) : (
                      '一般'
                    );
                  }}
                  width={labelNumberWidth(4)}
                />
                <VSTableColumn
                  dataIndex={['nursingClass']}
                  title="护理"
                  valueType="select"
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                  render={(dom, value) => {
                    // console.log(value, 'valuevaluevalue11');

                    if (value?.nursingClass == '0') {
                      return <Tag color="#df494d">特级</Tag>;
                    } else if (value?.nursingClass == '1') {
                      return <Tag color="#fe7d42">一级</Tag>;
                    } else if (value?.nursingClass == '2') {
                      return <Tag color="#fed800">二级</Tag>;
                    } else if (value?.nursingClass == '3') {
                      return <Tag color="#adb1b4">三级</Tag>;
                    } else {
                      return '';
                    }
                  }}
                  width={labelNumberWidth(4)}
                />
                <VSTableColumn
                  dataIndex={['attendingDoctorName']}
                  title="主治医生"
                  valueType="text"
                  fieldProps={{}}
                  width={labelNumberWidth(6)}
                />
                <VSTableColumn
                  dataIndex={['chargeType']}
                  title="费别"
                  valueType="select"
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                  width={labelNumberWidth(4)}
                />
                <VSTableColumn
                  dataIndex={['admissionDays']}
                  title="入院/日"
                  valueType="text"
                  width={labelNumberWidth(8)}
                />
                <VSTableColumn
                  dataIndex={['admissionWardDays']}
                  title="入科/日"
                  valueType="text"
                  width={labelNumberWidth(8)}
                />
                <VSTableColumn
                  dataIndex={['operationEndDateTime']}
                  title="手术日期"
                  valueType="date"
                  width={180}
                  fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
                />
                <VSTableColumn
                  dataIndex={['diagnosisDescription']}
                  title="诊断"
                  valueType="text"
                  width={300}
                  fieldProps={{}}
                />
                <VSTableColumn
                  dataIndex={['skinTestPositiveIndicator']}
                  title=""
                  valueType="text"
                  fieldProps={{}}
                  width={220}
                  render={(_, value) => {
                    return renderIcon(value);
                  }}
                />
                <VSTableColumn
                  dataIndex={['bedStatus']}
                  title="床位状态"
                  valueType="select"
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.BED_STATUS_DICT}
                  width={labelNumberWidth(6)}
                />
              </VSPromiseTable>
            </div>
            <div className="nurses_stationbed_card_list_content_bottom">
              <div
                className="nurses_stationbed_card_list_content_bottom_item1"
                onClick={() => {
                  handelChangeBedModal(true);
                }}
              >
                换床
              </div>
              <div
                style={{ backgroundColor: 'transparent', width: 96 }}
                className="nurses_stationbed_card_list_content_bottom_item1"
              >
                临床护理
              </div>
              <div
                onClick={handelViewDetail}
                className="nurses_stationbed_card_list_content_bottom_item2"
              >
                患者详情
              </div>
              <Button
                style={{
                  width: 96,
                  marginLeft: 32,
                  fontSize: 16,
                  // backgroundColor: !isDisable ? '#3276e8' : '',
                  // color: '#fff',
                }}
                type="primary"
                disabled={isDisable || !currentSelectPatientInfoInpVisitId}
                // className="nurses_stationbed_card_list_content_bottom_item1"
                onClick={() => {
                  vsf?.navigateTo(
                    `/index/nurse-station-transfer?inpVisitId=${currentSelectPatientInfoInpVisitId}`,
                  );
                }}
                // isDisable
              >
                转科
              </Button>
              <div
                style={{ backgroundColor: '#3276e8', color: '#fff' }}
                className="nurses_stationbed_card_list_content_bottom_item2"
                onClick={IntoTheFamily}
              >
                入科
              </div>
            </div>
          </div>
        );
        break;
      default:
        '';
    }
  };
  // 占床
  const occupyBed = (res) => {
    return (
      <div className="nurses_stationbed_card_modal_left_content">
        <div className="nurses_stationbed_card_modal_left_content_header">
          <Popover content={renderBedNum(res?.bedLabel, res)}>
            {renderBedNum(res?.bedLabel, res)}
          </Popover>
          <Popover content={handelbedNum(res)?.label}>
            <span style={{ color: '#85898D' }}>{handelbedNum(res)?.label}</span>
          </Popover>
          {/* <span className="nurses_stationbed_card_modal_left_content_header_bedNum">
            {res?.bedLabel}床
          </span>
          <span className="nurses_stationbed_card_modal_left_content_header_text">
            新入
          </span> */}
        </div>
        <div className="nurses_stationbed_card_modal_left_content_item1">
          <div className="nurses_stationbed_card_modal_left_content_item1_info">
            <div>
              <span className="nurses_stationbed_card_modal_left_content_item1_info_name">
                {res?.patientName}
              </span>
              <span>
                {res?.patientGender == 1 ? (
                  <Icon size={18} type="icon-nan" />
                ) : (
                  <Icon size={18} type="icon-nv" />
                )}
              </span>
            </div>
            <div className="nurses_stationbed_card_modal_left_content_item1_info_age">
              {res?.patientAge}
              {/* {(vsf.stores.dbenums?.enums?.SEX_DICT)} */}
            </div>
          </div>
          <div className="nurses_stationbed_card_modal_left_content_item1_info">
            <span className="nurses_stationbed_card_modal_left_content_item1_info_num">
              {res?.patientId}
            </span>
            <span className="nurses_stationbed_card_modal_left_content_item1_info_type">
              {handelStopwatch(
                res?.chargeType,
                vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
              )}
            </span>
          </div>
          <div></div>
        </div>
        <div className="nurses_stationbed_card_modal_left_content_item2">
          <div className="nurses_stationbed_card_modal_left_content_item2_item">
            <span className="nurses_stationbed_card_modal_left_content_item2_item_label">
              医生：
            </span>
            <span className="nurses_stationbed_card_modal_left_content_item2_item_value">
              {res?.attendingDoctorName}
            </span>
          </div>
          <div className="nurses_stationbed_card_modal_left_content_item2_item">
            <span className="nurses_stationbed_card_modal_left_content_item2_item_label">
              护士：
            </span>
            <span className="nurses_stationbed_card_modal_left_content_item2_item_value">
              {res?.inChargeNurseName}
            </span>
          </div>
          <div className="nurses_stationbed_card_modal_left_content_item2_item">
            <span className="nurses_stationbed_card_modal_left_content_item2_item_label">
              入科：
            </span>
            <span className="nurses_stationbed_card_modal_left_content_item2_item_value">
              {res?.admissionWardDays}天
            </span>
          </div>
          <div className="nurses_stationbed_card_modal_left_content_item2_item">
            <span className="nurses_stationbed_card_modal_left_content_item2_item_label">
              术后：
            </span>
            <span className="nurses_stationbed_card_modal_left_content_item2_item_value">
              {res?.afterOperationDays}天
            </span>
          </div>
        </div>
      </div>
    );
  };
  // modal卡片床位状态
  const renderBedStatus = (types, item) => {
    switch (types) {
      // 空床0
      case '0':
        return (
          <div
            style={{ position: 'relative', height: 178 }}
            className="nurses_stationbed_card_modal_left_content"
          >
            <div className="nurses_stationbed_card_modal_left_content_detail_card_empty">
              <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed">
              <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_icon">
                <Icon size={88} type="icon-kongchuang" />
              </div>
              <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_name">
                空床
              </div>
            </div>
          </div>
        );
      //  占床
      case '1':
        return occupyBed(item);
      // 停用
      case '4':
        return (
          <div
            style={{ position: 'relative' }}
            className="nurses_stationbed_card_modal_left_content"
          >
            <div className="nurses_stationbed_card_modal_left_content_detail_card_empty">
              <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div
              style={{ background: 'rgba(0,0,0,.5)' }}
              className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed"
            >
              <div className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_icon">
                <Icon size={88} type="icon-tingyong1" />
              </div>
              <div
                style={{ color: '#fff' }}
                className="nurses_stationbed_card_modal_left_content_detail_card_empty_bed_name"
              >
                停用
              </div>
            </div>
          </div>
        );
    }
  };
  // 换床modal里的卡片
  const renderChangeBed = (position) => {
    const res: any =
      position === 'first'
        ? selectDetailLeft
        : position === 'second'
        ? selectDetailRight
        : '';
    const selectDefaultValue =
      position === 'first'
        ? currentSelectCarBedList
        : position === 'second'
        ? currentSelectCarBedRightList
        : {};
    // console.log(res, 'resresres');

    //(changeBedLeftId || currentSelectCarBedList) && changeBedRightId &&
    return (
      <div className="nurses_stationbed_card_modal_left">
        <div className="nurses_stationbed_card_modal_left_select">
          <Select
            dataSource={getListClinicBedVoByWardId}
            fieldNames={{ label: 'bedLabel', value: 'id' }}
            showSearch
            width={240}
            defaultValue={{ ...selectDefaultValue }}
            onChange={(v) => {
              // console.log(v, 'vvvvv');
              if (position === 'first') {
                getClinicBedWithPatientVoById(v?.id, position);
                setChangeBedLeftId(v?.id);
              } else if (position === 'second') {
                getClinicBedWithPatientVoById(v?.id, position);
                setChangeBedRightId(v?.id);
              }
            }}
          />
        </div>
        {renderBedStatus(res?.bedStatus, res)}
      </div>
    );
  };

  return (
    <div className="nurses_stationbed_card">
      <div className="nurses_stationbed_card_search">{renderSearch()}</div>
      {renderContent()}
      {/* 换床弹窗 */}
      <Modal
        width={720}
        title="换床管理"
        centered
        open={bedModal}
        cancelText="取消"
        okText="确认换床"
        onOk={handelChangeBed}
        onCancel={() => {
          setChangeBedRightId('');
          setChangeBedLeftId('');
          setSelectDetailRight('');
          setSelectDetailLeft('');
          handelChangeBedModal(false);
        }}
      >
        <div className="nurses_stationbed_card_modal">
          {renderChangeBed('first')}
          {
            <div className="nurses_stationbed_card_modal_center">
              <IconCpoe
                size={25}
                type="icon-qiehuan"
                style={{ color: '#3276E8' }}
              />
            </div>
          }
          {renderChangeBed('second')}
        </div>
      </Modal>
      {/* 患者详情modal */}
      <DetailModal
        ref={modalRef}
        bedCardId={currentSelectCarBedList}
      ></DetailModal>
      {/* 过敏史modal */}
      <AllergyHistoryModal
        ref={allergyHistorymodalRef}
        bedCardId={currentSelectCarBedList}
      ></AllergyHistoryModal>
    </div>
  );
};
export default definePage(Index);
// 床卡
