/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { askSearch } from '@/components/AspirinSelect/utils';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { isMinScreen } from '@/config';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import {
  convertFirstBedData2FirstReqParam,
  convertGet,
  convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo,
  convertSecondBedData2SecondReqParam,
  currentWardTotalData,
  totalData,
} from '@/pages/Index/manage/bed_card/convert';
import { height, heightMessage } from '@/pattern';
import { getScrollY, labelColWidth, labelNumberWidth } from '@/utils';
import storage from '@/utils/storage';

// import Modal from '../registerInformation/modal';
import { get, transform } from './conver';

const patientDetailConditionIcon = [
  {
    label: 'icon-a-Group1311',
    value: '',
    isShow: 'waitExamReportIndicator',
  },
  {
    label: 'icon-yangxing',
    value: '',
    isShow: 'skinTestPositiveIndicator',
  },

  {
    label: 'icon-guomin',
    value: 'patientAllergy',
    isShow: 'patientAllergyIndicator',
  },
  // 危
  {
    label: 'icon-bingwei1',
    labelZhong: 'icon-bingzhong',
    value: '',
    isShow: 'crisisIndicator',
    isShowZhong: 'seriousIndicator',
  },
  // 重
  // {
  //   label: 'icon-bingzhong',
  //   value: '',
  //   isShow: 'seriousIndicator',
  // },
  // 隔
  {
    label: 'icon-geli',
    value: 'isolationType',
    isShow: 'isolationIndicator',
  },
  {
    label: 'icon-weidayin',
    value: '',
    isShow: 'notPrintAppointExamOrderIndicator',
  },
  {
    label: 'icon-hulilujing',
    value: '',
    isShow: 'pathwayImportIndicator',
  },
  {
    label: 'icon-naiyaobiaoshi',
    value: 'drugResistanceName',
    isShow: 'drugResistanceType',
  },
];
//住院号
const inputString = '';
// 患者id
let inputString1 = '';

const Index = (props) => {
  const { routes } = props;
  const { user } = props?.stores || {};
  const { patientList, hospitalType, currsetBedListIdentPatient } = user || {};
  const [formData, setFormData] = useState<any>();
  // table选择当前患者inpVisitId/搜索输入住院号
  const [currentSelectPatientId, setCurrentSelectPatientId] = useState<any>();
  // 搜索输入displayId
  const [currentDisplayId, setCurrentDisplayId] = useState<any>();
  const [open, setOpen] = useState(false);
  // 床号
  const [modalOpen, setModalOpen] = useState(false);
  // 本病区
  const [checked, setChecked] = useState<boolean>(false);
  // 当前病人科室id，患者详情取
  const [department, setDepartment] = useState<any>();
  // 当前医疗组长所在组id
  const [groupId, setGroupId] = useState<any>();
  // 床号列表
  const [bedList, setBedList] = useState([]);
  // 选择床号
  const [bedListId, setBedListId] = useState<any>();
  // 本科室统计列表
  const [currentWardTotalList, setCurrentWardTotalList] = useState<any>([]);
  // 暂存身高
  const [tempHeight, setTempHeight] = useState<any>();
  // 暂存体重
  const [tempWeight, setTempWeight] = useState<any>();
  // 卧床状态
  const [tempStayInBed, setTempStayInBed] = useState<any>();
  // 责任护士
  const [nurseInCharge, setNurseInCharge] = useState<any>();
  // 质控护士
  const [controlQualityNurse, setControlQualityNurse] = useState<any>();
  const [inputChange, setInputChange] = useState<number>(1);
  const [inputChange1, setInputChange1] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasClear, setHasClear] = useState<boolean>(false);
  const [tableList, setTableList] = useState<any>();
  const currentApplication = storage.get('currentApplication');
  const _labelColWidth = isMinScreen ? labelColWidth[9] : labelColWidth[6];

  useEffect(() => {
    // 床卡跳转过来的
    // console.log(routes?.query?.bedId, hasClear, '===');

    if (routes?.query?.bedId) {
      setBedListId({
        id: routes?.query?.bedId,
        bedLabel: routes?.query?.bedLabel,
      });
      vsf?.refs?.hospitalizedInfo?.setFieldsValue({
        bedId: {
          id: routes?.query?.bedId,
          bedLabel: routes?.query?.bedLabel,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);
  // 住院号
  // const [inpVisitId, setInpVisitId] = useState<any>();
  useEffect(() => {
    getForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectPatientId, inputChange]);
  useEffect(() => {
    getFormByDisplayId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDisplayId, inputChange1]);
  // 入科table
  const onFetch = useCallback(async () => {
    if (!currentApplication?.department?.id) return {};
    const res =
      await vsf.services?.ClinicInpAdtController_getWaitTransferPatientVoList_a28128(
        {
          // 写死
          wardId: currentApplication?.department?.id,
          // wardId: 359,
        },
      );
    const _res1 = res?.data?.inpAdmissionApplyVoList?.map((item) => {
      return { ...item?.inpAppointDto, isInpAdmissionApplyVoList: true };
    });
    const _res2 = res?.data?.inpPreTransferApplyVoList?.map((item) => {
      return { ...item, isInpPreTransferApplyVoList: true };
    });
    setTableList(
      [...res?.data?.inpPatientInTransferVoList, ..._res1, ..._res2] ?? [],
    );
    return {
      data:
        [...res?.data?.inpPatientInTransferVoList, ..._res1, ..._res2] ?? [],
      // count: res?.data?.length ?? 0,
    };
  }, [currentApplication?.department?.id]);
  // form
  const getForm = async (inpVisitId?: any) => {
    if (!currentSelectPatientId && !inpVisitId) return;

    const res =
      await vsf.services?.ClinicInpVisitController_getTransferPatientInfoVoByInpVisitId_13efc4?.(
        {
          inpVisitId: inpVisitId ? inpVisitId : currentSelectPatientId,
        },
      );
    if (res?.code !== 200) {
      setCurrentSelectPatientId('');
    }
    // console.log(controlQualityNurse);

    // 当前病人科室id
    const _res = get(res);
    setDepartment(_res?.departmentId);
    setGroupId(_res?.groupId);
    vsf?.refs?.basicInfo?.setFieldsValue(_res);
    vsf?.refs?.hospitalizedInfo?.setFieldsValue({
      ..._res,
      // nurseInCharge: { ...nurseInCharge },
      // controlQualityNurse: { ...controlQualityNurse },
      // bedId: routes?.query?.bedId ? routes?.query?.bedLabel : _res?.bedId,
      bedId:
        routes?.query?.bedId && !hasClear
          ? {
              bedlabel: routes?.query?.bedLabel,
              id: routes?.query?.bedId,
            }
          : get(res)?.bedId,
    });
    vsf?.refs?.admissionSign?.setFieldsValue({
      ..._res,
      weight: tempWeight,
      height: tempHeight,
    });
    // setTempHeight(_res?.height);
    // setTempWeight(_res?.weight);
    setTempStayInBed(_res?.stayInBed ?? false);
    setFormData(res?.data);
    return res?.data;
  };
  // from displayid
  const getFormByDisplayId = async () => {
    if (!currentDisplayId) return;
    // 接口查
    // const res =
    //   await vsf.services?.ClinicInpVisitController_getTransferPatientInfoVoByDisplayId_2c5dc2?.(
    //     {
    //       // TODO: 请检查以下这部分传参是否符合需求
    //       displayId: currentDisplayId,
    //     },
    //   );
    // if (res?.code !== 200) {
    //   setCurrentDisplayId('');
    // }
    // 不走接口
    const res = tableList?.find((item) => {
      return item?.displayId == currentDisplayId;
    });

    if (res?.inpVisitId) {
      clearTemp();
      getForm(res?.inpVisitId);
    } else {
      message.info('未找到该患者');
      setCurrentDisplayId('');
    }

    // 当前病人科室id
    // setDepartment(get(res)?.departmentId);
    // setGroupId(get(res)?.groupId);
    // vsf?.refs?.basicInfo?.setFieldsValue(get(res));
    // vsf?.refs?.hospitalizedInfo?.setFieldsValue({
    //   ...get(res),
    //   bedId: routes?.query?.bedId
    //     ? {
    //         bedlabel: routes?.query?.bedLabel,
    //         id: routes?.query?.bedId,
    //       }
    //     : get(res)?.bedId,
    // });
    // vsf?.refs?.admissionSign?.setFieldsValue(get(res));
    // setFormData(res?.data);
    // return res?.data;
  };
  // 清屏
  const handelClear = () => {
    // window.location.reload();
    // handelDraw(false);
    // setInputChange(inputChange + 1);
    // setBedListId({
    //   id: null,
    //   bedLabel: null,
    // });
    clearTemp();
    setHasClear(true);
    setCurrentSelectPatientId(null);
    vsf?.refs?.hospitalizedInfo?.resetFields();
  };
  const clearTemp = () => {
    setControlQualityNurse(null);
    setNurseInCharge(null);
    setTempStayInBed(null);
    setTempWeight(null);
    setTempHeight(null);
  };
  //医疗组长
  const getGroupController = async (v) => {
    if (!department) return;
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // 差
          departmentId: department,
          // wardId: currentApplication?.department?.id,
          staffRole: '01',
          stopIndicator: false,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = (res?.data?.result ?? [])
      ?.map((item) => {
        return {
          ...item?.medicalGroupMemberIndexDtoList?.[0]?.staff,
          groupId: item?.id,
          // staffName:
          //   item?.medicalGroupMemberIndexDtoList?.[0]?.staff?.staffName +
          //   '-' +
          //   item?.groupName,
        };
      })
      .filter((item) => {
        return item?.id;
      });
    return _res;
  };
  //主治医师
  const getGroupController02 = async (v) => {
    if (!department) return;
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // departmentId: department,
          // staffRole: '02',

          stopIndicator: false,
          groupId: groupId,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result?.map((item) => {
      const _data = item?.medicalGroupMemberIndexDtoList.map((__item) => {
        return __item?.staff;
      });
      return _data;
    });
    const _data = _res?.flat().filter((item) => {
      return item?.id;
    });
    let resData: any = [];
    _data.map((item) => {
      if (v.staffName) {
        const record = askSearch(v.staffName, item, 'staffName');
        if (record) {
          resData.push(item);
        }
      } else {
        resData.push(item);
      }
    });
    // return _data ?? [];
    return resData;
  };
  // 质控护士
  const getPagedByUserStaff = async (v) => {
    if (!currentApplication?.department?.id) return;
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 200,
          jobClassCodeIs: 'A2',
          //staffDepartmentIdIs输入工作站病区departmentId-先不传
          staffDepartmentIdIs: currentApplication?.department?.id,
          //inputCodeLike输入模糊搜索码
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    return res?.data?.result ?? [];
  };
  // 责任护士
  const getPagedByUserStaff02 = async (v) => {
    if (!currentApplication?.department?.id) return;
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 50,
          jobClassCodeIs: 'A2',
          //staffDepartmentIdIs输入工作站病区departmentId-先不传
          staffDepartmentIdIs: currentApplication?.department?.id,
          //inputCodeLike输入模糊搜索码
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    return res?.data?.result ?? [];
  };
  // 床号
  const getPagefallByClinicBedWithPatient = useCallback(async (params) => {
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            bedStatusIs: '0',
            // 写死
            // wardIdIs: currentApplication?.department?.id,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
    // setBedList(res?.data?.result);
    let resData: any = [];
    res?.data?.result.map((item) => {
      if (params.bedLabel) {
        const record = askSearch(params.bedLabel, item, 'bedLabel');
        if (record) {
          resData.push({ ...item, bedLabel: item?.bedLabel + '床' });
        }
      } else {
        resData.push({ ...item, bedLabel: item?.bedLabel + '床' });
      }
      // return { ...item, bedLabel: item?.bedLabel + '床' };
    });
    return resData;
  }, []);
  // 本病区统计
  const getCurrentWardTotal = () => {
    const arr: any = [];
    const _arr = currentWardTotalData?.map((item) => {
      return vsf.services?.ClinicBedController_queryBedPatientStatistics_3cd3df(
        {
          statisticsCodes: [item?.value],
          qto: {
            // 写死
            // wardIdIs: item?.value === 'WARD' ? 359 : undefined,
            wardIdIs:
              item?.value === 'WARD'
                ? currentApplication?.department?.id
                : undefined,
          },
        },
      );
    });
    Promise.all(_arr)
      .then((res) => {
        const _totalList = res.map((item) => {
          return { ...item?.data?.result?.[0] };
        });
        const _data = _totalList.map((item) => {
          const res = currentWardTotalData?.find((_item) => {
            return item?.code === _item?.value;
          });
          return { ...item, label: res?.label };
          // return { ...item ,label:};
        });
        setCurrentWardTotalList(_data);
      })
      .catch((err) => {
        console.error();
      });
  };
  const handelSelectPatient = (value) => {
    if (value?.isInpPreTransferApplyVoList) {
      // 转科申请
      Modal.warning({
        title: '警告',
        content: '该患者为转科患者，请原病区护士执行转科操作!',
        okText: '关闭',
        centered: true,
      });
    } else if (value?.isInpAdmissionApplyVoList) {
      // 住院申请
      Modal.warning({
        title: '警告',
        content: '该患者为已预约入院患者，请先办理入院登记手续!',
        okText: '关闭',
        centered: true,
      });
    } else {
      setCurrentSelectPatientId(value?.inpVisitId);
      handelDraw(false);
      setInputChange(inputChange + 1);
    }
  };

  const handelDraw = (v) => {
    setOpen(v);
  };
  // 保存
  const handelSave = async () => {
    if (!formData?.inpVisitVo?.id) return;
    const validateRes =
      await vsf.refs.hospitalizedInfo?.validateFieldsReturnFormatValue();

    if (!validateRes) return;
    const res1 = vsf?.refs?.basicInfo?.getFieldsValue();
    const res2 = vsf?.refs?.hospitalizedInfo?.getFieldsValue();
    const res3 = vsf?.refs?.admissionSign?.getFieldsValue();

    const _transform = transform({
      ...res1,
      ...res2,
      ...res3,
      bedId: res2?.bedId?.id,
      stayInBed: res3?.stayInBed ? true : false,
    });

    const res =
      await vsf.services?.ClinicInpAdtController_transferPatientIn_b929c3({
        ..._transform,
      });
    if (res?.code === 200) {
      message.success('入科成功!');
      vsf.refs.waitTransferPatientTable.reload();
      handelClear();
      // location?.reload();
      // if (routes?.query?.bedId) {
      //   setHasClear(true);
      //   setCurrentSelectPatientId(null);
      //   vsf?.refs?.hospitalizedInfo?.resetFields();
      //   setBedListId(null);
      // }
    }
  };
  // 门诊诊断tag
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  const renderSearch = () => {
    return (
      <div className="nurse_admission_information_search_input">
        <div className="nurse_admission_information_search_input_item">
          <span className="nurse_admission_information_search_input_item_label icon18">
            患者ID
          </span>
          <Input
            className="icon16"
            onChange={(e) => {
              inputString1 = e?.target?.value;
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                setInputChange1(inputChange1 + 1);
                setCurrentDisplayId(inputString1);
                setCurrentSelectPatientId('');
                setFormData('');
              }
            }}
          />
        </div>
        {/* <div className="nurse_admission_information_search_input_item">
          <span className="nurse_admission_information_search_input_item_label">
            住院号
          </span>
          <Input
            height={32}
            width={140}
            onChange={(e) => {
              inputString = e?.target?.value;
            }}
            onKeyUp={(e) => {
              if (e.code === 'Enter') {
                setInputChange(inputChange + 1);
                setCurrentDisplayId('');
                setCurrentSelectPatientId(inputString);
                setFormData('');
              }
            }}
          />
        </div> */}
      </div>
    );
  };
  //床号字体颜色判断
  const handelbedNum = (item) => {
    const getTime = (value) => {
      return +new Date(value);
    };
    if (item?.preTransferIndicator) {
      // 待转科
      return 'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num1';
    } else if (
      // 当天新入科
      !item?.preTransferIndicator &&
      getTime(item?.admissionWardDateTime) >
        getTime(item?.expectedDischargeDate) &&
      dayjs(item?.admissionWardDateTime).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return 'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num2';
    } else if (
      // 今日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return 'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num4';
    } else if (
      // 明日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 00:00:00'),
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return 'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num3';
    } else {
      return 'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num5';
    }
  };
  // 码表转换
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    return _res?.[0]?.label;
  };
  // 选择床号
  const selectBedId = (v) => {
    setBedListId(v);
  };
  // 床号
  const renderBedNum = (value, item) => {
    return (
      <div
        className={cn(
          'nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text_num',
          handelbedNum(item),
        )}
      >
        {value}床
      </div>
    );
  };

  const renderIcon = (value) => {
    return (
      <div style={{ display: 'flex' }}>
        {patientDetailConditionIcon?.map((item, index) => {
          if (item?.isShow === 'crisisIndicator') {
            if (value?.[item?.isShow]) {
              return (
                <Icon
                  key={index}
                  size={16}
                  className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                  type={item?.label}
                />
              );
            } else if (value?.[item?.isShowZhong as string]) {
              return (
                <Icon
                  size={16}
                  key={index}
                  className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon "
                  type={item?.labelZhong as string}
                />
              );
            }
          } else if (item?.isShow === 'isolationIndicator') {
            return value?.[item?.isShow] ? (
              <Popover
                key="index"
                content={handelStopwatch(
                  value?.[item?.value],
                  vsf.stores.dbenums?.enums?.ISOLATION_TYPE_DICT,
                )}
              >
                <Icon
                  size={16}
                  className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon "
                  type={item?.label}
                  style={{
                    display: value?.[item?.isShow] ? 'block' : 'none',
                  }}
                />
              </Popover>
            ) : (
              ''
            );
          } else if (item?.isShow === 'drugResistanceName') {
            if (value?.drugResistanceName?.length === 1) {
              // console.log(1);
            } else if (value?.drugResistanceName?.length === 0) {
              // console.log(2);
            }
          } else {
            return item?.value ? (
              value?.[item?.isShow] ? (
                <Popover key="index" content={value?.[item?.value]}>
                  <Icon
                    size={16}
                    className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon "
                    type={item?.label}
                  />
                </Popover>
              ) : (
                ''
              )
            ) : value?.[item?.isShow] ? (
              <Icon
                size={16}
                className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon "
                type={item?.label}
              />
            ) : (
              ''
            );
          }
        })}
      </div>
    );
  };
  // 占床
  const occupyBed = (item) => {
    return (
      <div className="nurse_admission_information_modal_content_detail_item_content">
        {/* 护理级别码表缺字段 */}
        <div className="nurse_admission_information_modal_content_detail_item_content_topbgc nurse_admission_information_modal_content_detail_item_content_topbgc_yiji"></div>
        <div
          className="nurse_admission_information_modal_content_detail_item_content_body"
          style={{
            border: '2px solid transparent',
            borderTop: 'transparent',
          }}
        >
          <div className="nurse_admission_information_modal_content_detail_item_content_body_item1">
            <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_tag">
              <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_tag_text">
                {renderBedNum(item?.bedLabel, item)}
                <span>待转科</span>
              </div>
              <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_tag_icon">
                {renderIcon(item)}
              </div>
            </div>
            <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_info">
              <div>
                <span className="nurse_admission_information_modal_content_detail_item_content_body_item1_info_name">
                  张三
                </span>
                <Icon size={18} type="icon-nan" />
                {/* {item?.patientGender == 1 ? (
         <Icon size={18} type="icon-nan" />
       ) : (
         <Icon size={18} type="icon-nv" />
       )} */}
              </div>
              <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_info_name_age">
                {/* {item?.patientAge} */}
                20岁
              </div>
            </div>
            <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_type">
              <span>
                {/* {item?.patientId} */}
                10000111
              </span>
              <div className="nurse_admission_information_modal_content_detail_item_content_body_item1_type_text">
                {/* {handelStopwatch(
         item?.chargeType,
         vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
       )} */}
                自费
              </div>
              {/* <Tag bordered={false}>
   {handelChargeType(item?.chargeType)}
 </Tag> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  // 床位状态
  const renderBedStatus = (type, item) => {
    switch (type) {
      // 空床
      case '0':
        return (
          <div
            style={{
              position: 'relative',
              border: bedListId?.id === item?.id ? '1px solid #3276E8' : '',
              background: bedListId?.id === item?.id ? '#EBF2FF' : '',
            }}
            className="nurse_admission_information_modal_content_detail_item_content"
            onClick={() => {
              selectBedId({ id: item?.id, label: item?.bedLabel });
            }}
          >
            <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty">
              <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed custom_bed_width">
              <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_icon">
                <Icon size={44} type="icon-kongchuang" />
              </div>
              <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_name">
                空床
              </div>
            </div>
          </div>
        );
      //  占床
      case '1':
        return occupyBed(item);
      // 停用
      case '4':
        return (
          <div
            style={{ position: 'relative' }}
            className="nurse_admission_information_modal_content_detail_item_content"
          >
            <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty">
              <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_number">
                {item?.bedLabel}
              </div>
            </div>
            <div
              style={{
                background: 'rgba(0,0,0,.5)',
              }}
              className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed custom_bed_width"
            >
              <div className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_icon">
                <Icon size={44} type="icon-tingyong1" />
              </div>
              <div
                style={{ color: '#fff' }}
                className="nurse_admission_information_modal_content_detail_item_content_detail_card_empty_bed_name"
              >
                停用
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="nurse_admission_information">
      <div className="nurse_admission_information_left">
        <div className="nurse_admission_information_left_form_title">
          <div className="nurse_admission_information_left_form_title_tag"></div>
          患者信息
        </div>
        <div className="nurse_admission_information_table">
          <div style={{ marginBottom: 15 }}>{renderSearch()}</div>
          <VSPromiseTable
            // editable={{ editType: 'single' }} aspirin-table-row-background-color
            id="waitTransferPatientTable"
            pagination={false}
            onFetch={onFetch}
            className={classNames(
              'aspirin-table-nopaddingaspirin-table-header-background-color aspirin-table-row-hover-background-color aspirin-table-body-empty-transparent-background-color ',
              isMinScreen ? 'table_overflow_358' : 'table_overflow_328',
            )}
            rowClassName={(record, v) => {
              return cn(
                record?.isInpAdmissionApplyVoList ||
                  record?.isInpPreTransferApplyVoList
                  ? 'aspirin-table-row-background-color'
                  : '',
                currentSelectPatientId == record?.inpVisitId ||
                  currentDisplayId == record?.displayId
                  ? 'aspirin-table-row-select-background-color'
                  : '',
              );
            }}
            scroll={{ y: 0, x: 150 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  clearTemp();
                  setCurrentDisplayId('');
                  handelSelectPatient(record);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              editable={false}
              valueType="digit"
              fieldProps={{}}
              ellipsis
              width={isMinScreen ? 82 : 110}
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              editable={false}
              valueType="text"
              fieldProps={{}}
              width={isMinScreen ? 70 : 100}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              editable={false}
              valueType="select"
              fieldProps={{}}
              ellipsis
              width={isMinScreen ? 40 : 100}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            />
            <VSTableColumn
              dataIndex={['source']}
              title="来源"
              editable={false}
              valueType="text"
              fieldProps={{}}
              width={100}
              ellipsis
            />
            {/* <VSTableColumn
              // dataIndex={['source']}
              title="因"
              editable={false}
              valueType="text"
              fieldProps={{}}
            /> */}
          </VSPromiseTable>
        </div>
      </div>
      <div className="nurse_admission_information_right">
        {/* <div className="nurse_admission_information_search">
          {renderSearch()}
        </div> */}
        {currentSelectPatientId ||
        currentDisplayId ||
        (routes?.query?.bedId && !hasClear) ? (
          <div className="nurse_admission_information_form">
            <div className="nurse_admission_information_form_content">
              <Block
                title="基本信息"
                titleStyle={{ fontWeight: '600' }}
                mainStyle={{ padding: '0 16px 0 16px' }}
                style={{
                  border: '1px solid transparent',
                  height: 'auto',
                  paddingBottom: 0,
                }}
              >
                <VSForm labelAlign="left" readonly id="basicInfo">
                  <VSFormItem
                    name={['id']}
                    label="主键"
                    valueType="digit"
                    fieldProps={{}}
                    style={{ display: 'none' }}
                  />
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={['name']}
                      label="姓名"
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 60,
                        },
                      ]}
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['gender']}
                      label="性别"
                      valueType="select"
                      dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                      fieldProps={{
                        showSearch: true,
                      }}
                    />
                    <VSFormItem
                      name={['age']}
                      label="年龄"
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 10,
                        },
                      ]}
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['birthAddress']}
                      label="出生地"
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 10,
                        },
                      ]}
                      fieldProps={{}}
                      convertValue={(v) => {
                        if (v?.province) {
                          if (v?.city) {
                            if (v?.county) {
                              return (
                                v?.province + '/' + v?.city + '/' + v?.county
                              );
                            } else {
                              return v?.province + '/' + v?.city;
                            }
                          } else {
                            return v?.province;
                          }
                        } else {
                          return '';
                        }
                      }}
                    />
                    <VSFormItem
                      name={['identityCode']}
                      label="人员类别"
                      valueType="select"
                      dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 10,
                        },
                      ]}
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['chargeType']}
                      label="费别"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                      }}
                      dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    />
                    {/* 预交金todo */}
                    {/* <VSFormItem
                    name={[
                      'inpVisitVo',
                      'inpVisitExtensionBillingInfoDto',
                      'chargeType',
                    ]}
                    label="少预交金(元)"
                    valueType="select"
                    fieldProps={{
                      showSearch: true,
                    }}
                    dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                  /> */}
                    <VSFormItem
                      name={['contactPersonName']}
                      label="联系人姓名"
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 60,
                        },
                      ]}
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['contactPersonPhone']}
                      label="联系人电话"
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 20,
                        },
                      ]}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </VSForm>
              </Block>
              <Block
                title="入院信息"
                titleStyle={{ fontWeight: '600' }}
                mainStyle={{ padding: '0 16px 0 16px' }}
                style={{
                  border: '1px solid transparent',
                  height: 'auto',
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <VSForm
                  labelAlign="left"
                  id="hospitalizedInfo"
                  // disabled={!formData?.inpVisitVo?.id ? true : false}
                >
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={['admissionDateTime']}
                      label="入院时间"
                      valueType="date"
                      fieldProps={{
                        disabled: true,
                        showTime: true,
                        className: 'widthAutoFill',
                      }}
                    />
                    <VSFormItem
                      name={['patientClass']}
                      label="入院途径"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                        disabled: true,
                      }}
                      dataSource={vsf.stores.dbenums?.enums?.PATIENT_CLASS_DICT}
                    />
                    <VSFormItem
                      name={['admissionWardDateTime']}
                      label="入科时间"
                      valueType="date"
                      fieldProps={{
                        showTime: true,
                        className: 'widthAutoFill',
                        // disabled: true,
                        disabledDate: (current) => {
                          // console.log(formData, 'formData?.admissionDateTime');

                          return (
                            current &&
                            current <
                              dayjs(formData?.inpVisitVo.admissionDateTime)
                                .endOf('day')
                                .subtract(1, 'day')
                          );
                        },
                      }}
                      rules={[{ required: true }]}
                    />
                  </VSFormLayout>
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={['bedId']}
                      label="床号"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                        // onClick: () => {
                        //   setModalOpen(true);
                        //   getPagefallByClinicBedWithPatient();
                        // },
                        onSelect: async (v) => {
                          // console.log(v, '--==');
                          const res =
                            await vsf?.services?.ClinicBedController_getBedWithPrice_03a9ad?.(
                              { bedId: v?.value },
                            );
                          // console.log(res);
                          vsf?.refs?.hospitalizedInfo?.setFieldsValue({
                            bedCost: res?.data?.bedPrice,
                          });
                        },
                      }}
                      rules={[{ required: true }]}
                      fieldNames={{ label: 'bedLabel', value: 'id' }}
                      dataSource={getPagefallByClinicBedWithPatient}
                    />
                    <VSFormItem
                      name={['bedCost']}
                      label="床位费"
                      valueType="text"
                      fieldProps={{ disabled: true }}
                    />
                    {/* <VSFormDependency name={['bedId']}>
                      {(data) => {
                        return (
                          <VSFormItem
                            name={['bedCost']}
                            label="床位费"
                            valueType="text"
                            fieldProps={{ disabled: true }}
                          />
                        );
                      }}
                    </VSFormDependency> */}
                  </VSFormLayout>
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={[
                        'medicalGroupLeader',
                        // 'staffName',
                      ]}
                      label="医疗组长"
                      fieldNames={{
                        label: 'staffName',
                        value: 'id',
                        key: 'groupId',
                      }}
                      rules={[{ required: true }]}
                      valueType="select"
                      dataSource={getGroupController}
                      transform={(v) => {
                        // console.log(v, 'pop');
                        return {
                          inpVisitVo: {
                            inpVisitExtensionStaffDto: {
                              medicalGroupLeader: { ...v },
                            },
                          },
                        };
                      }}
                      fieldProps={{
                        showSearch: true,
                        onChange: (c) => {
                          // console.log(c, 'ppppppppppppo');
                          vsf.refs.hospitalizedInfo.setFieldsValue({
                            attendingDoctor: '',
                          });
                          setGroupId(c?.groupId);
                        },
                        onClear: () => {
                          vsf.refs.hospitalizedInfo.setFieldsValue({
                            attendingDoctor: '',
                          });
                        },
                      }}
                    />
                    <VSFormItem
                      name={['attendingDoctor']}
                      label="主治医师"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                      }}
                      fieldNames={{ label: 'staffName', value: 'id' }}
                      dataSource={getGroupController02}
                      transform={(v) => {
                        return {
                          attendingDoctor: {
                            ...v,
                          },
                        };
                      }}
                    />
                    <VSFormItem
                      name={['nurseInCharge']}
                      label="责任护士"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                      }}
                      fieldNames={{ label: 'staffName', value: 'id' }}
                      searchKey="inputCodeLike"
                      dataSource={getPagedByUserStaff02}
                      transform={(v) => {
                        // console.log(v, 'vv');
                        setNurseInCharge(v);
                        return {
                          nurseInCharge: {
                            ...v,
                          },
                        };
                      }}
                    />
                  </VSFormLayout>
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={['patientAdmissionCondition']}
                      label="入院情况"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                        disabled: true,
                      }}
                      dataSource={[
                        { label: '一般', value: 'NORMAL' },
                        { label: '急', value: 'EMERGENCY' },
                        { label: '危', value: 'CRISIS' },
                      ]}
                    />
                    <VSFormItem
                      name={['controlQualityNurse']}
                      label="质控护士"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                      }}
                      searchKey="inputCodeLike"
                      fieldNames={{ label: 'staffName', value: 'id' }}
                      dataSource={getPagedByUserStaff}
                      transform={(v) => {
                        setControlQualityNurse(v);
                        return {
                          controlQualityNurse: {
                            ...v,
                          },
                        };
                      }}
                    />
                    <VSFormItem
                      name={['diagnosisRecordVoList']}
                      label="诊断"
                      valueType="select"
                      fieldProps={{
                        disabled: true,
                        tagRender: tagRender,
                        mode: 'multiple',
                        allowClear: true,
                      }}
                      convertValue={(v) => {
                        return v?.map((item) => {
                          return item?.diagnosisDescription;
                        });
                      }}
                    />
                  </VSFormLayout>
                </VSForm>
              </Block>
              <Block
                title="入院体征"
                titleStyle={{ fontWeight: '600' }}
                mainStyle={{ padding: '0 16px 0 16px' }}
                style={{
                  border: '1px solid transparent',
                  height: 'auto',
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                <VSForm labelAlign="left" id="admissionSign">
                  {/* <VSFormLayout columnCount={3} labelCol={_labelColWidth}> */}
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                  >
                    <VSFormItem
                      name={['height']}
                      label="身高(cm)"
                      valueType="text"
                      fieldProps={{
                        disabled: tempStayInBed,
                        onChange: (v) => {
                          setTempHeight(v.target.value);
                        },
                      }}
                      rules={[{ pattern: height, message: heightMessage }]}
                    />
                    <VSFormItem
                      name={['weight']}
                      label="体重(kg)"
                      valueType="text"
                      fieldProps={{
                        disabled: tempStayInBed,
                        onChange: (v) => {
                          setTempWeight(v.target.value);
                        },
                      }}
                      rules={[{ pattern: height, message: heightMessage }]}
                    />
                    <VSFormItem
                      name={['stayInBed']}
                      label="卧床"
                      valueType="check"
                      initialValue={false}
                      rules={[{ required: true }]}
                      fieldProps={{
                        onChange: (v) => {
                          setTempStayInBed(v);
                          if (v) {
                            vsf?.refs?.admissionSign?.setFieldsValue({
                              height: '',
                              weight: '',
                            });
                          } else {
                            vsf?.refs?.admissionSign?.setFieldsValue({
                              height: tempHeight,
                              weight: tempWeight,
                            });
                          }
                        },
                      }}
                    />
                  </VSFormLayout>
                </VSForm>
              </Block>
              <Block
                titleStyle={{ fontWeight: '600' }}
                title="入院准备中心交班信息"
                mainStyle={{ padding: '0 16px 0 16px' }}
                style={{
                  border: '1px solid transparent',
                  height: 'auto',
                  paddingTop: 0,
                }}
              ></Block>
            </div>
            <div className="nurse_admission_information_form_bottom">
              <div
                className="nurse_admission_information_form_bottom_btn1 nurse_admission_information_form_bottom_btn"
                onClick={handelClear}
              >
                清屏
              </div>
              <div
                onClick={handelSave}
                className="nurse_admission_information_form_bottom_btn2 nurse_admission_information_form_bottom_btn"
              >
                入科
              </div>
            </div>
          </div>
        ) : (
          <div className="nurse_admission_information_form">
            <Empty />
          </div>
        )}
      </div>
      <Modal
        open={modalOpen}
        title="床卡视图"
        width={1294}
        bodyStyle={{ backgroundColor: '#f3f5f7', padding: '0 18px 0 36px' }}
        onOk={() => {
          // console.log(bedListId?.label);
          setModalOpen(false);
          vsf?.refs?.hospitalizedInfo?.setFieldsValue({
            bedId: bedListId?.label + '床',
          });
        }}
        onCancel={() => {
          setModalOpen(false);
          setBedListId('');
        }}
      >
        <div
          className="nurse_admission_information_modal"
          style={{ backgroundColor: '#f3f5f7' }}
        >
          <div className="nurse_admission_information_modal_total">
            {currentWardTotalList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="nurse_admission_information_modal_total_item"
                >
                  <span>{item?.label}：</span>
                  {item?.value} {item?.unit}
                </div>
              );
            })}
          </div>
          <div className="nurse_admission_information_modal_content">
            {bedList.map((item: any, index) => {
              return (
                <div
                  key={index}
                  className="nurse_admission_information_modal_content_detail_item"
                >
                  {renderBedStatus(item?.bedStatus, item)}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      {/* {!open && (
        <div
          className="nurse_admission_information_float_btn"
          style={{ right: open ? 658 : 0 }}
          onClick={() => {
            // getCurrentWardTotal();
            handelDraw(true);
          }}
        >
          <div className="nurse_admission_information_float_btn_icon">
            <Icon size={10} type="icon-zuo1" />
          </div>
          <div className="nurse_admission_information_float_btn_text">
            待入患者
          </div>
        </div>
      )} */}
      <Drawer
        className="aspirin-drawer"
        headerStyle={{ borderBottom: '1px solid #3276E8' }}
        bodyStyle={{ padding: '16px 24px' }}
        closeIcon={null}
        destroyOnClose={true}
        title="待入科患者"
        placement="right"
        width={658}
        onClose={() => {
          handelDraw(false);
        }}
        open={open}
      >
        <div
          className="nurse_admission_information_float_btn"
          style={{ right: 658, top: 200 }}
          onClick={() => {
            handelDraw(false);
          }}
        >
          <div className="nurse_admission_information_float_btn_icon">
            <Icon size={10} type="icon-you1" />
          </div>
          <div className="nurse_admission_information_float_btn_text">
            待入患者
          </div>
        </div>
        <div className="nurse_admission_information_table">
          <VSPromiseTable
            // editable={{ editType: 'single' }} aspirin-table-row-background-color
            id="waitTransferPatientTable"
            pagination={false}
            onFetch={onFetch}
            className="aspirin-table-header-fontweight aspirin-table-header-background-color "
            rowClassName={(record, v) => {
              // console.log(record, v);
              // record?.source === '新入' &&
              return cn(
                record?.isInpAdmissionApplyVoList ||
                  record?.isInpPreTransferApplyVoList
                  ? 'aspirin-table-row-background-color'
                  : '',
              );
            }}
            onRow={(record) => {
              return {
                onDoubleClick: () => {
                  // console.log(record);
                  handelSelectPatient(record);
                },
                onClick: () => {
                  // console.log(record);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              editable={false}
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            />
            <VSTableColumn
              dataIndex={['source']}
              title="来源"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              // dataIndex={['source']}
              title="因"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </div>
      </Drawer>
    </div>
  );
};
export default definePage(Index);

// 病区护士入科
