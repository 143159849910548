import React from 'react';
import { Button, Input } from '@vs/vsf-kit';

import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';

export default function certificateFooter(props) {
  const { active, add, printClick } = props;
  const button = [
    {
      text: '新增',
      // ordinaryKey: 'P',
      methodKey: 'altKey',
      disabled: false,
      onClick: () => {
        add();
      },
    },
    {
      text: '打印',
      // ordinaryKey: 'P',
      methodKey: 'altKey',
      disabled: !active,
      onClick: () => {
        printClick();
      },
    },
  ];
  return (
    <div className="aspirin-table-footer-button aspirin-table-footer-button-center">
      {button.map((item) => (
        <ButtonAndKeyDown key={item.text} {...item} type="primary">
          {item.text}
        </ButtonAndKeyDown>
      ))}
    </div>
  );
}
