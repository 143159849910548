import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationFunctionGroupDtoController_getFunctionGroupByApplicationId_ddcfcd:
    {
      method: 'post',
      url: '/api/application/get-function-group-by-application-id',
      parameterFomatter: (data?: { applicationId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationFunctionGroupWithFunctionVoApplicationEntranceWebVo[],
      ) => data,
    },
  ApplicationBOController_deleteFunctionGroup_e56168: {
    method: 'post',
    url: '/api/application/delete-function-group',
    parameterFomatter: (data?: {
      btoParam: DeleteFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_createFunctionGroup_b538da: {
    method: 'post',
    url: '/api/application/create-function-group',
    parameterFomatter: (data?: {
      btoParam: CreateFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
      btoList: FunctionGroupWithRoleEoApplicationPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_updateFunctionGroup_1f6261: {
    method: 'post',
    url: '/api/application/update-function-group',
    parameterFomatter: (data?: {
      btoParam: UpdateFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
      btoList: FunctionGroupWithRoleEoApplicationPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationFunctionBOController_deleteFunction_d7c670: {
    method: 'post',
    url: '/api/application-privilege/delete-function',
    parameterFomatter: (data?: {
      btoParam: DeleteFunctionBtoApplicationPrivilegeServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
