import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDispenseSummaryController_receiveAndCheck_2802ec: {
    method: 'post',
    url: '/api/drug-dispense-summary/receive-and-check',
    parameterFomatter: (data?: {
      eo: QueryDrugReceiveAndCheckEoDrugDispenseSummaryPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugReceiveAndCheckVoDrugDispenseSummaryEntranceWebVo,
    ) => data,
  },
});
