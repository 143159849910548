import { SyncOutlined } from '@vs/vsf-icons';
import {
  Button,
  compose,
  Empty,
  Image,
  Input,
  message,
  Spin,
  Tree,
  TreeNodeProps,
  TreeProps,
  withRef,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { DataNode } from 'rc-tree/lib/interface';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { DomKey } from './constants';
import ContextMenu, { ContextMenuConfigType } from './contextMenu';
import Icon from './icon';
import Loading from './Loading';
import LoadingImageApng from './loading_xs.png';
import LoadingImageWebp from './loading_xs.webp';
import { portalize } from './portal';
import { appendTreeLevel, buildTree, filterTreeData, highlight } from './utils';

type loadDataType = () => Promise<any>;
// type dtoResType = {
//   code?: number;
//   message?: string;
//   data?: any[];
// };

// type qtoResType = {
//   code?: number;
//   message?: string;
//   data?: {
//     result?: any[];
//     [key: string]: any;
//   };
// };

// type loadDataType = () => Promise<dtoResType | qtoResType>;

type FieldNames = {
  title?: string;
  /** @private Internal usage for `rc-tree-select`, safe to remove if no need */
  _title?: string[];
  key?: string;
  children?: string;
  pinyinSearch?: any;
};

export type TreeMenuRef = {
  handleReload: () => void;
};

type TreeMenuProps = Omit<
  TreeProps,
  'treeData' | 'fieldNames' | 'onSelect' | 'titleRender'
> & {
  /**
   * 是否默认选中第一个
   * 默认false
   */
  firstly?: boolean;
  /**
   * 区域标题
   */
  title?: string | React.ReactNode;
  /**
   * TreeMenu 根节点 classname
   */
  className?: string;
  /**
   * 数据源
   */
  treeData?: any[];
  /**
   * 动态加载数据
   */
  loadData?: loadDataType | loadDataType[];
  /**
   * 是否需要重载
   */
  reload?: boolean;
  /**
   * 头部自定义渲染
   * reload dom
   */
  headerRender?: (reload: React.ReactNode) => React.ReactNode;
  /**
   * 是否数据本身为tree (跳过build tree)
   */
  isTree?: boolean;
  /**
   * 自定义节点 title、key、children 的字段
   * @field [title, key, children]
   */
  fieldNames?: FieldNames;
  /**
   * tree title Render
   */
  titleRender?: (
    nodeData: DataNode,
    highlightTitle: string | React.ReactNode,
  ) => React.ReactNode;
  /**
   * tree 关系映射
   */
  dataRelationFieldNames?: {
    id: string;
    parentId: string;
  };
  /**
   * 选择回调
   */
  onSelect: (
    node: DataNode & any,
    treeData: any,
    selected?: boolean,
    selectedKeys?: any,
    oldSelectedKeys?: any,
  ) => void;
  /**
   * 行设置
   * @expand
   */
  row: {
    /**
     * 是否行选中时, 非叶子节点的项自动展开
     */
    expand?: boolean;
    /**
     * 右侧额外操作栏
     */
    extra?: (node: DataNode) => React.ReactNode;
    /**
     * 右侧额外操作栏 显示方式
     * 默认为 false
     */
    extraShow?: 'default' | 'select' | 'hover';
    /**
     * 取消选中
     * 默认为 false
     */
    cancelSelect?: boolean;
    /**
     * 行展开加载数据, 异步加载子列表
     */
    expandLoadData?: (data: any) => any;
  };
  /**
   * 搜索
   * @expand
   */
  search: {
    style?: any;
    /**
     * 是否需要搜索过滤
     */
    status?: boolean;
    /**
     * 提示语 placeholder
     */
    searchPlaceholder?: string;
    /**
     * 搜索过滤类型, 静态过滤 | 请求接口
     */
    searchType?: 'static' | 'request';
    /**
     * 自定义搜索关键字
     */
    searchKeyWord?: string | string[];
    /**
     * 自定义搜索提示
     */
    searchMessage?: string;
    /**
     * 自定义搜索函数
     */
    searchCallback?: (node: DataNode, keyword: string) => any;
    /**
     * 自定义搜索函数-异步的
     */
    onSearch?: (value?: string) => Promise<any>;
  };
  /**
   * 右键菜单
   * @expand
   */
  context?: {
    /**
     * 单行右键菜单
     */
    rowContextMenu: ContextMenuConfigType;
    /**
     * 空白处右键菜单
     */
    defaultContextMenu: ContextMenuConfigType;
  };
  /**
   * tree 组件 props
   * @component Tree
   * @componentProp omit [treeData, fieldNames]
   */
  treeProps?: TreeProps;
  treeNodeProps?: TreeNodeProps;
} & {
  ref?: React.Ref<TreeMenuRef>;
  id?: string;
};

const updateTreeData = (
  list: any[],
  key: string,
  children: any[],
  keyMap = 'key',
): any[] =>
  list.map((node) => {
    if (node?.[keyMap] === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children, keyMap),
      };
    }
    return node;
  });

/**
 * 树形菜单(阿司匹林)
 */
let TreeMenu = (props: TreeMenuProps, ref?: React.Ref<TreeMenuRef>) => {
  const {
    treeData,
    loadData,
    reload,
    headerRender,
    fieldNames,
    dataRelationFieldNames,
    isTree = false,
    search,
    onSelect,
    context,
    treeProps,
    className,
    title,
    row,
    titleRender,
    firstly = false,
    ...rest
  } = props;
  const { rowContextMenu, defaultContextMenu } = context || {};
  const {
    status,
    searchType = 'static',
    searchKeyWord,
    searchCallback,
    onSearch,
    searchPlaceholder = '请输入',
    searchMessage,
    style,
  } = search || {};
  const {
    expand: rowExpand,
    extra,
    extraShow = 'default',
    cancelSelect = false,
    expandLoadData,
  } = row || {};
  const portalRef = useRef();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const searchValue = useRef();

  const [adding, setAdding] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>();
  const [selectedParentKeys, setSelectedParentKeys] = useState<number[]>();
  let extraProps = {};
  useImperativeHandle(ref, () => ({
    handleReload,
    buildData,
    handleSetExpandedKeys,
    handleSearch,
  }));

  const handleSetExpandedKeys = (keys: string[]) => {
    setExpandedKeys(keys);
  };

  const onRightClick = ({ event, node }) => {
    event.stopPropagation();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={rowContextMenu} record={node} />,
      key: DomKey,
    });
  };

  const onBgRightClick = (event) => {
    event.preventDefault();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={defaultContextMenu} />,
      key: DomKey,
    });
  };

  const handleSearch = async (e) => {
    if (searchType === 'static') {
      const target = filterTreeData(dataSource, e?.target?.value, {
        fieldNames: fieldNames,
        keywords: searchKeyWord,
        callback: searchCallback,
      });
      searchValue.current = e?.target?.value;
      setInputValue(e?.target?.value);

      setData(target?.data);
      // if (searchMessage && target?.data?.length == 0) {
      //   message.info(searchMessage);
      // }
      if (e?.target?.value) {
        setExpandedKeys(target?.expandKeys);
      } else {
        setExpandedKeys([]);
      }
      return;
    }

    if (searchType === 'request') {
      setInputValue(e?.target?.value);
      searchValue.current = e?.target?.value;
      let res;
      if (e?.target?.value) {
        res = await onSearch?.(e?.target?.value);
      } else {
        res = await loadData?.(e?.target?.value);
      }
      const tempData = buildTree(
        res?.data ?? res ?? [],
        {
          parentId: dataRelationFieldNames?.parentId ?? '',
          id: dataRelationFieldNames?.id ?? '',
        },
        expandLoadData,
      );
      const target = filterTreeData(tempData, e?.target?.value, {
        fieldNames: fieldNames,
        keywords: searchKeyWord,
        callback: searchCallback,
      });
      setData(target?.data);
      setExpandedKeys(target?.expandKeys);
    }
  };

  const request = async (loadData) => {
    if (!loadData) return;
    if (Array.isArray(loadData)) {
      return await Promise.all(loadData?.map((item) => item?.()));
    }
  };

  const buildData = useCallback(
    async (treeData, loadData, isTree) => {
      let resData = treeData;
      if (loadData) {
        setLoading(true);
        let target: any;
        if (Array.isArray(loadData)) {
          target = await request(loadData);
          resData = target.flatMap((item: any) => item.data);
        } else {
          target = await loadData?.();
          resData = target?.data;
        }
      }
      // console.log(resData, 'resData', isTree);
      return !isTree
        ? buildTree(resData ?? [], dataRelationFieldNames, expandLoadData)
        : resData;
    },
    [dataRelationFieldNames, expandLoadData],
  );

  const getData = useCallback(async () => {
    const targetData = await buildData(treeData, loadData, isTree);
    const targetDataHasLevel = appendTreeLevel(targetData);
    setDataSource(targetDataHasLevel);
    setData(targetDataHasLevel);
    setLoading(false);
    return targetDataHasLevel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  // const container: any = document.getElementById('vsf-treemenu-content_dom');

  // container?.addEventListener('click', (event) => {
  //   const clickedElement: any = event.target;
  //   console.log(container, 'scrollTopscrollTop');
  //   if (container.contains(clickedElement)) {
  //     // 获取被点击的元素（这里假设是滚动容器的直接子元素或其子元素的子元素）
  //     const clickedElement = event.target;

  //     // 获取滚动容器的高度
  //     const containerHeight = container.scrollHeight; // 注意这里使用 scrollHeight 而不是 offsetHeight

  //     // 获取滚动容器的滚动位置(滚动得距离)
  //     const scrollTop = container.scrollTop;

  //     // 获取被点击元素的边界信息（包括位置、大小等）
  //     const rect = clickedElement.getBoundingClientRect();
  //     // 所以我们需要将其转换为相对于容器的位置(元素距离顶部得距离)
  //     const distanceFromTop = rect.top - container.getBoundingClientRect().top;

  //     // 注意：getBoundingClientRect返回的是相对于视口（viewport）的位置
  //     // 视口得高度
  //     const visibleHeight = container.clientHeight;
  //     // 计算被点击元素距离容器底部的距离
  //     // const distanceFromBottom =
  //     //   containerHeight - distanceFromTop - rect.height - visibleHeight;
  //     // const distanceFromBottom =
  //     //   containerHeight -
  //     //   scrollTop -
  //     //   distanceFromTop -
  //     //   (containerHeight - distanceFromTop - visibleHeight);
  //     const distanceFromBottom =
  //       containerHeight -
  //       scrollTop -
  //       distanceFromTop -
  //       (containerHeight - distanceFromTop - visibleHeight);
  //     console.log(
  //       distanceFromBottom,
  //       scrollTop,
  //       '被点击元素距离底部的距离:',

  //       rect.top,
  //       container.getBoundingClientRect().top,
  //       containerHeight,
  //       distanceFromTop,
  //       scrollTop,
  //       containerHeight,
  //       distanceFromTop,
  //       visibleHeight,
  //     );

  //     // 根据需要执行操作
  //     if (distanceFromBottom < 50) {
  //       console.log('被点击元素接近容器底部了！');
  //       // 在这里执行你的代码
  //       container.scrollTop = 150;
  //     }
  //   }
  // });

  const handleExpand = async (expandedKeys, { expanded, node }) => {
    setExpandedKeys(expandedKeys);
    if (expandLoadData) {
      const res: any = await expandLoadData({ expanded, node });
      const children = res?.data?.result ?? res?.data;
      const key = getKey(node);
      setData((origin) =>
        updateTreeData(origin, key, children, fieldNames?.key),
      );
    }
  };

  const handleSelect = async (
    selectKeys,
    { selected, selectedNodes, node, event },
  ) => {
    if (!node?.leafFlag && rowExpand) {
      if (!expandedKeys?.includes(node?.key)) {
        setExpandedKeys([...new Set([...(expandedKeys ?? []), node?.key])]);
      } else {
        setExpandedKeys(
          [...(expandedKeys ?? [])].filter((v) => v !== node?.key),
        );
      }
    }
    const state = await onSelect?.(
      {
        ...(node ?? {}),
      },
      dataSource,
      !selected,
      selectKeys,
      selectedKeys,
    );
    if (selectKeys?.[0] || cancelSelect) {
      setSelectedKeys(state !== void 0 ? state : selectKeys);
    }
    setAdding(false);
    // onSelect?.(
    //   {
    //     ...(node ?? {}),
    //   },
    //   dataSource,
    //   !selected,
    //   selectedKeys,
    // );
  };

  const renderTitleSign = () => {
    return (
      <div className="title-sign">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const getKey = useCallback(
    (node) => {
      return node?.[fieldNames?.key ?? 'key'];
    },
    [fieldNames],
  );

  const findSelectedNodeParentKeys = useCallback(
    (tree, targetLeafKey) => {
      const parentKeys: any[] = [];

      function traverse(node, path) {
        if (getKey(node) === targetLeafKey) {
          parentKeys.push(...path);
          return;
        }

        if (node.children) {
          for (const child of node.children) {
            traverse(child, [...path, getKey(node)]);
          }
        }
      }

      for (const node of tree) {
        traverse(node, []);
      }

      return parentKeys;
    },
    [getKey],
  );

  const handleReload = () => {
    getData();
    searchValue.current = undefined;
    setInputValue('');
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (selectedKeys && data) {
      const res = findSelectedNodeParentKeys(data, selectedKeys?.[0]);
      setSelectedParentKeys(res);
    }
  }, [selectedKeys, data, findSelectedNodeParentKeys]);

  if (rowContextMenu) {
    extraProps = {
      onRightClick: onRightClick,
    };
  }

  const reloadDom = (
    <Button icon={<SyncOutlined spin={loading} />} onClick={handleReload}>
      刷新
    </Button>
  );

  const isSelectedParent = (data, selectedKeys) => {
    return (data?.data?.children ?? [])?.some((item) =>
      selectedKeys?.includes(item?.id),
    );
  };
  useEffect(() => {
    if (data?.length >= 1 && firstly) return setSelectedKeys([data?.[0]?.id]);
  }, [data, firstly]);
  return (
    // @ts-ignore
    <div
      {...rest}
      {...(defaultContextMenu ? { onContextMenu: onBgRightClick } : {})}
      className={`vsf-treemenu-container ${className ?? ''}`}
    >
      <div className="vsf-treemenu-main">
        {(title || reload || headerRender) && (
          <div className="vsf-treemenu-header">
            {title && (
              <div className="title">
                {renderTitleSign()}
                <div className="word">{title}</div>
              </div>
            )}
            {(reload || headerRender) && (
              <div className="extra">
                {reload && reloadDom}
                {headerRender && headerRender(reloadDom)}
              </div>
            )}
          </div>
        )}
        {status && (
          <Input
            prefix={
              <Icon
                type="icon-a-Searchsousuo"
                size={18}
                style={{
                  color: '#85898D',
                }}
              />
            }
            style={{ ...style }}
            placeholder={searchPlaceholder}
            value={inputValue}
            className="vsf-treemenu-search"
            onChange={handleSearch}
          />
        )}
        <div
          id="vsf-treemenu-content_dom"
          className={classNames('vsf-treemenu-content', {
            'vsf-treemenu-content-center':
              loading || !data || data.length === 0,
          })}
        >
          {loading ? (
            <Loading size="small" />
          ) : (
            <>
              {data && data?.length > 0 ? (
                <Tree
                  {...extraProps}
                  // style={{
                  //   border: '1px solid red',
                  //   backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  //   opacity: 1,
                  // }}
                  rootClassName="vsf-treemenu-tree"
                  treeData={data}
                  fieldNames={fieldNames}
                  onExpand={handleExpand}
                  expandedKeys={expandedKeys}
                  selectedKeys={selectedKeys}
                  onSelect={handleSelect}
                  blockNode={true}
                  {...treeProps}
                  switcherIcon={
                    treeProps?.switcherIcon !== undefined
                      ? treeProps?.switcherIcon
                      : (data) => {
                          return (
                            <Icon
                              type={data?.expanded ? 'icon-shang' : 'icon-xia'}
                              size={22}
                              style={{
                                color:
                                  data?.selected ||
                                  isSelectedParent(data, selectedKeys)
                                    ? '#3276E8'
                                    : '#343B42',
                              }}
                            />
                          );
                        }
                  }
                  titleRender={(node) => {
                    let newLabel = highlight(
                      node,
                      searchValue.current,
                      searchKeyWord,
                      fieldNames,
                    );
                    if (titleRender) {
                      newLabel = titleRender?.(
                        node,
                        highlight(
                          node,
                          searchValue.current,
                          searchKeyWord,
                          fieldNames,
                        ),
                      );
                    }
                    return (
                      <div
                        className={classNames('aspirin-treemenu-title-render', {
                          'aspirin-treemenu-title-render-active':
                            selectedParentKeys?.includes(getKey(node)),
                        })}
                      >
                        <div style={{ width: '100%' }} className="label">
                          {newLabel}
                        </div>
                        {extra && (
                          <div className={`extra extra-${extraShow}`}>
                            {extra(node)}
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              ) : (
                <Empty description="暂无数据" />
              )}
            </>
          )}
        </div>
      </div>
      {/* {!!onAdd && (
        <div className="vsf-treemenu-footer">
          <div
            className={classNames('add-btn', {
              adding: adding,
            })}
            onClick={handleAdd}
          >
            <Icon type="icon-tianjia" size={30} />
          </div>
        </div>
      )} */}
    </div>
  );
};

// @ts-ignore
TreeMenu.displayName = 'TreeMenu';

// @ts-ignore
TreeMenu = React.forwardRef(TreeMenu);

export default compose(withRef())(TreeMenu) as typeof TreeMenu;
