import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useCallback, useRef, useState } from 'react';
import React from 'react';

import { NewDrawer } from '../../components';
const WardPendingReview = (props) => {
  const [open, setOpen] = useState(true);
  const tableRef = useRef<any>(null);
  const onReload = () => {
    tableRef?.current?.reload?.();
  };
  const onFetch = useCallback(
    async (params) => {
      if (open) {
        const res =
          await vsf.services?.InpDrugAuditController_getWaitAuditWardPatientCount_82cd2a?.(
            {},
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
      return {
        data: [],
        total: 0,
      };
    },
    [open],
  );
  return (
    <NewDrawer
      buttonTitle="病区待审核列表"
      buttonStyle={{
        top: '20%',
      }}
      style={{
        top: 104,
      }}
      drift="98px"
      width="20%"
      placement="left"
      open={open}
      onOpen={() => setOpen(!open)}
      mask
      title="病区待审核人数"
      footerStyle={{
        display: 'flex',
        justifyContent: 'center',
      }}
      footer={
        <Button type="primary" onClick={onReload}>
          刷新
        </Button>
      }
    >
      <VSPromiseTable
        id="tableWaitAuditWardPatGroup"
        ref={tableRef}
        onFetch={onFetch}
        onRow={(record) => ({
          onClick: () => {
            props?.onChange?.(record);
            setOpen(false);
          },
        })}
        pagination={false}
        // rowClassName={(node) => {
        //   return node?.id === select?.id
        //     ? 'aspirin-row-selected-box'
        //     : 'aspirin-row-select-box';
        // }}
      >
        <VSTableColumn dataIndex={['wardName']} title="病区" valueType="text" />
        <VSTableColumn
          dataIndex={['patientCount']}
          title="患者数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};

export default definePage(WardPendingReview);
