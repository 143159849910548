import '@/pages/Index/components/order_table/index.less';
import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Input,
  List,
  message,
  Popover,
  Radio,
  Table,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import { Select } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AspirinDateTimePicker } from '@/components';
import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import { Flex } from '@/pages/Index/components';
import { DosageUnit } from '@/pages/Index/components/dosage';
import { askFixed } from '@/pages/Index/components/func/ask';
import { useGroupCheck } from '@/pages/Index/components/func/hooks';
import {
  orderClassDataSource,
  repeatIndicatorDataSource,
} from '@/pages/Index/components/order/in_patient/table/datasource';
import { TableRefs } from '@/pages/Index/components/order_table';
import { UsageUnit } from '@/pages/Index/components/usage';
import { getScrollY } from '@/utils';

const Content = (props) => {
  const { onSubmit, open, onClose, value } = props;
  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};

  const tableRef = useRef<TableRefs>(null);

  // 渲染高危图标
  const renderHighriskIcon = (record) => {
    const { drugOrder } = record;
    const node = (
      <Icon
        type="icon-gaowei"
        size={20}
        style={{
          marginRight: 4,
        }}
      />
    );
    if (drugOrder?.drugDictionary?.dangerIndicator) {
      return node;
    } else {
      return null;
    }
  };

  // 渲染加急图标
  const renderUrgentIcon = (record) => {
    const { examOrder, labOrder, pathologyOrder, operationOrder } = record;
    const node = (
      <Icon
        type="icon-a-Frame1517"
        size={20}
        style={{
          marginRight: 4,
        }}
      />
    );
    if (
      examOrder?.emergencyIndicator ||
      labOrder?.emergencyIndicator ||
      operationOrder?.operationApplyType === 'EMERGENCY' ||
      pathologyOrder?.emergencyIndicator
    ) {
      return node;
    } else {
      return null;
    }
  };

  const render = useCallback((...args) => {
    const [, record] = args;
    let name = (
      <Popover
        title="详细信息"
        content={record?.orderText?.drugName ?? record?.orderText}
        placement="right"
      >
        <div className={classnames('aspirin-order-text', record?.groupName)}>
          {renderUrgentIcon(record)}
          {renderHighriskIcon(record)}
          <span>{record?.orderText?.drugName ?? record?.orderText}</span>
        </div>
      </Popover>
    );
    switch (record?.orderClass) {
      case 'EXAM':
        name = (
          <Popover
            placement="right"
            title={
              <div className="aspirin-title-popover-box">
                检查
                <span> {record?.examOrder?.examOrderDetail?.length} </span>
                项详情
              </div>
            }
            content={
              <List
                size="small"
                dataSource={record?.examOrder?.examOrderDetail}
                renderItem={(item: any, index) => {
                  return (
                    <List.Item
                      style={{
                        padding: '8px 0',
                        width: '100%',
                      }}
                    >
                      <Alert
                        type="info"
                        style={{
                          width: '100%',
                        }}
                        message={`${index + 1}. ${item?.examItemName}`}
                      />
                    </List.Item>
                  );
                }}
              />
            }
            trigger="hover"
          >
            <div
              className={classnames('aspirin-order-text', record?.groupName)}
            >
              {renderUrgentIcon(record)}

              <span>{record?.orderText?.drugName ?? record?.orderText}</span>
            </div>
          </Popover>
        );
        break;
      case 'HERB':
        // eslint-disable-next-line no-case-declarations
        const useWay = vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
          (item) => record?.herbOrder?.useWay == item?.value,
        )?.label;
        // eslint-disable-next-line no-case-declarations
        const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.find(
          (item) => record?.herbOrder?.frequency?.frequency == item?.value,
        )?.label;
        // eslint-disable-next-line no-case-declarations
        const useTimeDescription =
          vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
            (item) => item?.value == record?.herbOrder?.useTimeDescription,
          )?.label;
        name = (
          <Popover
            placement="right"
            overlayClassName="aspirin-herb-box"
            content={
              <List
                size="small"
                header={
                  <div className="aspirin-title-herb-popover-box">
                    <div className="aspirin-title-herb-header-box">
                      <div className="aspirin-title-herb-header-left-box">
                        <span>中药汤剂明细</span>
                        <span>
                          共 {record?.herbOrder?.herbOrderDetail?.length} 项
                        </span>
                      </div>
                      <div className="aspirin-title-herb-header-right-box">
                        <Icon
                          type="icon-zhongtangyaodanchuangbeijing"
                          style={{
                            color: 'rgb(232, 225, 217)',
                          }}
                          size={56}
                        />
                      </div>
                    </div>
                    <div className="aspirin-title-message-box">
                      <div>使用方法: {useWay}</div>
                      <div>服用时间: {useTimeDescription}</div>
                      <div>服用频次: {frequency}</div>
                      <div>付数: {record?.herbOrder?.herbRegimen}</div>
                      <div className="aspirin-title-message-memo-box">
                        备注: {record?.herbOrder?.memo}
                      </div>
                    </div>
                  </div>
                }
                dataSource={record?.herbOrder?.herbOrderDetail}
                className="aspirin-list-herb-popover-item-box"
                renderItem={(item: any, index) => {
                  const find =
                    vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT?.find(
                      ({ value }) => item?.useDescription === value,
                    );
                  const medicalInsuranceType = [
                    { label: '甲', value: 'ALPHA' },
                    { label: '乙', value: 'BETA' },
                    { label: '丙', value: 'GAMMA' },
                  ]?.find(
                    ({ value }) => value === item?.medicalInsuranceType,
                  )?.label;
                  return (
                    <Alert
                      type="warning"
                      message={
                        <div className="aspirin-title-herb-popover-item-box">
                          <span>{index + 1}</span>
                          <span>{item?.drugProductName}</span>
                          {find?.label && <span>{find?.label}</span>}
                          <span>
                            {askFixed(item?.price ?? 0, 2) +
                              '/' +
                              (medicalInsuranceType ?? '')}
                          </span>
                        </div>
                      }
                    />
                  );
                }}
              />
            }
            trigger="hover"
          >
            <div
              className={classnames('aspirin-order-text', record?.groupName)}
            >
              <span>{record?.orderText?.drugName ?? record?.orderText}</span>
            </div>
          </Popover>
        );
        break;
    }
    return name;
  }, []);

  const handleFilterValue = (data: any[]) => {
    const _res = data?.filter(
      (item) =>
        item?.orderStatus === 'PERFORM' &&
        item?.repeatIndicator &&
        !item?.stopOrderDateTime,
    );
    return _res ?? [];
  };

  const handleSubmit = () => {
    const time = getStopTime();
    const list = getValue();

    if (!time) {
      message.info('请选择停止时间');
    } else {
      onSubmit({
        time,
        list,
      });
    }
  };
  const [stopTime, setStopTime, getStopTime] = useGetState<any>();

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  }

  const { element, getValue } = useGroupCheck(handleFilterValue(value));
  return (
    <div className="aspirin-drawer-stop-order-container">
      <div className="aspirin-drawer-stop-order-main">
        <div className="header">
          <span className="label icon18">停止时间</span>
          <AspirinDateTimePicker
            value={stopTime}
            onChange={(v) => setStopTime(v)}
            format="YYYY-MM-DD HH:mm:ss"
            valueType="timestamp"
            datePickerProps={
              {
                disabledDate,
                disabledTime: (value) => {
                  // console.log(value);
                  return {
                    disabledHours: () => {
                      const currentHour = moment().hour();
                      return dayjs().diff(value) <= 0
                        ? []
                        : [...Array(currentHour).keys()];
                    },
                    disabledMinutes: (selectedHour) => {
                      if (selectedHour === moment().hour()) {
                        const currentMinute = moment().minute();
                        return dayjs().diff(value) <= 0
                          ? []
                          : [...Array(currentMinute).keys()];
                      }
                    },
                    disabledSeconds(selectedHour, selectedMinute) {
                      if (
                        selectedHour === moment().hour() &&
                        selectedMinute === moment().minute()
                      ) {
                        const currentSecond = moment().second();
                        return dayjs().diff(value) <= 0
                          ? []
                          : [...Array(currentSecond).keys()];
                      }
                    },
                  };
                },
              } as any
            }
          />
        </div>
        <div className="main">
          <VSTable
            scroll={{
              y: 0,
            }}
            ref={tableRef}
            className="aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-vs-table-row-box table_overflow_278"
            bordered
            value={handleFilterValue(value)}
            pagination={false}
          >
            <VSTableColumn {...(element as any)} />
            <VSTableColumn
              title="开始时间"
              dataIndex={['startPerformDateTime']}
              valueType="aspirinDateTimePicker"
              fieldProps={{
                format: 'YYYY/MM/DD HH:mm:ss',
                previewFormat: 'MM/DD HH:mm',
                valueType: 'timestamp',
              }}
              width="100px"
              render={(...[, record]) => {
                const { startPerformDateTime } = record || {};
                return (
                  <Flex justify="center">
                    {startPerformDateTime
                      ? dayjs(startPerformDateTime).format('MM/DD HH:mm')
                      : ''}
                  </Flex>
                );
              }}
            />
            <VSTableColumn
              title="长/临"
              dataIndex={['repeatIndicator']}
              valueType="select"
              dataSource={repeatIndicatorDataSource}
              width="50px"
              render={(...[, record]) => {
                return (
                  <Flex justify="center">
                    {
                      repeatIndicatorDataSource?.find(
                        (item) => item?.value === !!record?.repeatIndicator,
                      )?.label
                    }
                  </Flex>
                );
              }}
            />
            <VSTableColumn
              title="类别"
              dataIndex={['orderClass']}
              valueType="select"
              dataSource={orderClassDataSource}
              preview
              width="45px"
            />
            <VSTableColumn
              title="医嘱"
              dataIndex={['orderText']}
              valueType="text"
              render={render}
              width="286px"
              className="aspirin-table-order-cell"
            />
            <VSTableColumn
              title="剂量"
              dataIndex={['dosage']}
              valueType="dosage"
              width="70px"
              render={(...[, record]) => <DosageUnit value={record?.dosage} />}
            />
            <VSTableColumn
              title="用量"
              dataIndex={['usage']}
              valueType="usage"
              width="70px"
              render={(...[, record]) => <UsageUnit value={record?.usage} />}
            />
            <VSTableColumn
              dataIndex={['administration']}
              title="途径"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
              width={100}
            />
            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              valueType="select"
              width={100}
              dataSource={vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
                (item) => {
                  return {
                    ...item,
                    ...JSON?.parse(item?.extensionJson ?? '{}'),
                  };
                },
              )}
              render={(...[, record]) => {
                return (
                  record?.frequency?.frequency ?? record?.frequency?.label ?? ''
                );
              }}
              fieldNames={{
                label: 'frequency',
                value: 'frequency',
              }}
            />
            <VSTableColumn
              dataIndex={['memo']}
              title="备注"
              valueType="text"
              width={100}
            />

            <VSTableColumn
              dataIndex={['totalAmount']}
              title="数量"
              valueType="inputUnit"
              width={70}
              render={(...[, record]) => {
                const { orderClass } = record || {};
                if (['OTHER', 'TREAT']?.includes?.(orderClass))
                  return record?.totalAmount;
                if (orderClass === 'DRUG') {
                  return record?.drugOrder?.prescriptionIndicator ||
                    record?.drugOrder?.prescriptionId ? (
                    <UsageUnit value={record?.drugOrder?.totalAmount} />
                  ) : (
                    ''
                  );
                } else {
                  return Object?.keys(
                    record?.drugOrder?.totalAmount ?? {},
                  )?.includes('value')
                    ? Object?.values(record?.drugOrder?.totalAmount ?? {})
                    : record?.drugOrder?.totalAmount;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['duration']}
              title="持续时间"
              valueType="select"
              width={100}
              render={(...arr) => {
                const [, record] = arr;
                const { disposalOrder } = record || {};

                return disposalOrder?.duration
                  ? `${disposalOrder?.duration}${disposalOrder?.durationUnit}`
                  : '';
              }}
            />
          </VSTable>
        </div>
      </div>
      <div className="aspirin-drawer-stop-order-footer">
        <Button className="footer-btn" onClick={onClose}>
          关闭
        </Button>
        <Button className="footer-btn" type="primary" onClick={handleSubmit}>
          停止
        </Button>
      </div>
    </div>
  );
};

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>();
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = (v) => {
    setDataSource(v);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    onOpen,
    onClose,
  }));

  return (
    <Drawer
      className="aspirin-drawer-stop-order aspirin-drawer-stop-order1700"
      destroyOnClose
      title={<div className="icon18">停止医嘱</div>}
      placement="right"
      onClose={onClose}
      open={open}
      // width={isMinScreen ? 1240 : 1360}
      // rootClassName="width1360"
    >
      <Content
        {...props}
        open={open}
        onSubmit={onSubmit}
        onClose={onClose}
        value={dataSource}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
