import * as echarts from 'echarts';
import React, { useEffect, useRef } from 'react';

const Index = () => {
  const chartRef = useRef<any>();
  const init = () => {
    const chartDom = document.getElementById('center-chart');
    chartRef.current = echarts.init(chartDom);
    const option = {
      title: {
        text: 'Stacked Line',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      toolbox: {
        // feature: {
        //   saveAsImage: {},
        // },
      },
      textStyle: {
        fontFamily: 'DINPro',
        fontWeight: 'bold',
        color: '#343B42',
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
      yAxis: [
        {
          type: 'value',
          name: '{block|}  呼吸',
          nameTextStyle: {
            color: '#50BC35',
            padding: [0, 40, 0, 0],
            rich: {
              block: {
                backgroundColor: '#50BC35',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  脉搏',
          nameTextStyle: {
            color: '#3276E8',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#3276E8',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  体温',
          offset: 60,
          nameTextStyle: {
            color: '#FF7E7E',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#FF7E7E',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
      ],
      series: [
        {
          name: 'Email',
          type: 'line',
          stack: 'Total',
          data: [120, 132, 101, 134, 90, 230, 210],
          yAxisIndex: 0, // 使用第一个Y轴
        },
        {
          name: 'Union Ads',
          type: 'line',
          stack: 'Total',
          data: [220, 182, 191, 234, 290, 330, 310],
          yAxisIndex: 1, // 使用第一个Y轴
        },
        {
          name: 'Video Ads',
          type: 'line',
          stack: 'Total',
          data: [150, 232, 201, 154, 190, 330, 410],
          yAxisIndex: 2, // 使用第一个Y轴
        },
      ],
    };
    option && chartRef.current.setOption(option);
  };

  const resize = () => {
    if (chartRef.current) {
      chartRef.current?.resize();
    }
  };
  useEffect(() => {
    init();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
  return (
    <div
      id="center-chart"
      style={{
        width: '100%',
        height: '100%',
      }}
    ></div>
  );
};

export default Index;
