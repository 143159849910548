import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  Form,
  InputProps,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React, { useImperativeHandle, useRef } from 'react';

import { SelectCode } from '..';
import { useInputEnter } from '../dosage/utils';
import { askFixed } from '../func/ask';

/**
 * 住院医嘱途径选择
 */
let DescriptionSelect: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, required, ...rest } =
    props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const { orderClass } = record || {};
  const Ref = useRef<any>(null);
  const dataSource =
    orderClass === 'HERB'
      ? vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT
      : vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;
  useImperativeHandle(ref, () => {
    return {
      ...(Ref.current ?? {}),
      focus: () => {
        if (!required) {
          Ref.current?.focus();
        } else {
          Ref.current?.focus();
        }
      },
    };
  });

  return (
    <Select
      {...props}
      value={value}
      showSearch
      ref={Ref}
      dataSource={dataSource}
      onChange={onChange}
      {...fieldEnterProps}
      {...rest}
    />
  );
};

DescriptionSelect.displayName = 'descriptionSelect';

DescriptionSelect = React.forwardRef(DescriptionSelect);

export const WithPreviewComponent = definePage((props: any) => {
  const { orderClass } = props?.record || {};
  const target =
    orderClass === 'HERB'
      ? vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT
      : vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;
  return (
    <>
      {target?.find(
        (item: any) => item?.value == (props?.value?.value ?? props?.value),
      )?.label ??
        props?.value?.value ??
        props?.value}
    </>
  );
});

export default compose(
  withRef(),
  withField<any>({
    name: 'descriptionSelect',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: WithPreviewComponent,
    },
  ),
)(DescriptionSelect) as typeof DescriptionSelect;
