import React, { useContext, useEffect, useState, useRef } from 'react';
import './index.less';
import { CreateScreen } from '@/pages/Index/components/screen';
import { Screen } from '@/pages/Index/components';
import {
  VSForm,
  Row,
  VSTable,
  Radio,
  VSFormItem,
  Button,
  VSFormLayout,
  Tabs,
  VSTableColumn,
  VSPromiseTable,
  Space,
  message,
  Modal,
  Checkbox,
} from '@vs/vsf-kit';
import {
  convertGetCurrentApplicationVsStaffWithStaffVoRes2FormStaff,
  pharmaceutist,
  sendDrugReq,
} from './Service';
import DrawerCostom from './outdrawer';
import DeneralDrawer from './generalDrawer';
import { useRequest } from 'ahooks';
import { filterEmptyValues } from '@/utils/setDataUtil';
import GeneralTable from './GenerTable';
import Tip from '@/pages/Index/components/func/Tip';
function Main() {
  const [selectTab, setSelectTab] = useState(1);

  const [tableParam, setTableParam] = useState();

  const [sendDrugParam, setSendDrugParam] = useState();

  const generTableRef = useRef(null);

  const onScreen = useContext(CreateScreen);

  const { data: pharmaceutistData } = useRequest(pharmaceutist);

  const { runAsync } = useRequest(sendDrugReq, { manual: true });

  const { data: sendData } = useRequest(pharmaceutist);

  let templateSelectArr = [];

  const queryTableParam = () => {
    const param = vsf?.refs?.hempDetaill?.getFieldsValue();
    setTableParam(param?.flag?.inpVisitId);
  };

  const [wardId, setWardId] = useState();
  const [isSendSucess, setIsSendSucess] = useState(false);
  const onChange = (key) => {
    vsf?.refs?.formStaff?.resetFields();
    vsf?.refs?.hempDetaill?.resetFields();
    setSelectTab(key);
  };
  useEffect(() => {
    setTableParam(null);
    setSendDrugParam(null);
    setWardId(null);
  }, [selectTab]);

  //发药
  const sendDrug = () => {
    const waitDispenseMasterId = [];
    //不可供药品不可发药
    let supplyIndicatorArr = [];
    sendDrugParam?.map((item) => {
      if (item?.supplyIndicator === false) {
        supplyIndicatorArr.push(item);
      }
    });

    if (supplyIndicatorArr.length > 0) {
      Modal.warn({
        content: `[${supplyIndicatorArr[0]?.orderText}]药品不可供!`,
      });
      return;
    }

    sendDrugParam?.map((item) => {
      waitDispenseMasterId.push(item?.prescriptionWaitDispenseId);
    });
    const personData = filterEmptyValues(
      vsf?.refs?.formStaff?.getFieldsValue(),
    );

    // if (!personData?.prepareDrugStaffId?.prepareDrugStaffId) {
    //   message.error('配药人未填写');
    //   return;
    // }
    // if (!personData?.secondPrepareDrugStaffId?.secondPrepareDrugStaffId) {
    //   message.error('核对人未填写');
    //   return;
    // }
    runAsync({
      waitDispenseMasterId: waitDispenseMasterId,
      prepareDrugStaffId: personData?.prepareDrugStaffId?.prepareDrugStaffId,
      secondPrepareDrugStaffId:
        personData?.secondPrepareDrugStaffId?.secondPrepareDrugStaffId,
    }).then((res) => {
      if (res?.code === 200) {
        message.success('发药成功');
        setIsSendSucess(!isSendSucess);
        generTableRef?.current?.clearSelectRow();
        vsf?.refs?.formStaff?.resetFields();
      }
    });
  };

  const selectedChange = (data) => {
    setSendDrugParam(data);
  };

  const items = [
    {
      key: 1,
      label: '出院带药',
      children: (
        <GeneralTable
          wardId={wardId}
          tableParam={tableParam}
          queryTableParam={queryTableParam}
          selectedChange={(v) => selectedChange(v)}
          isSendSucess={isSendSucess}
          ref={generTableRef}
        />
      ),
    },
  ];

  return (
    <>
      <div className="inpatient-prescription-dispensing-layout">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <div className="btn-footer">
          <VSForm vsid="02769" id="formStaff">
            <VSFormLayout
              key="0"
              columnCount={4}
              colStyle={{ textAlign: 'end' }}
            >
              <VSFormItem
                name={['prepareDrugStaffId']}
                label="配药人"
                valueType="select"
                dataSource={pharmaceutistData}
                fieldProps={{
                  fieldNames: {
                    label: 'staffName',
                    value: 'prepareDrugStaffId',
                  },
                }}
              />

              <VSFormItem
                name={['secondPrepareDrugStaffId']}
                label="核对人"
                valueType="select"
                dataSource={sendData}
                fieldProps={{
                  loadData: async (params) => {
                    //
                    // Tips: 以下是你选择的请求函数
                    const res =
                      await vsf?.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
                        {
                          // TODO: 请检查以下这部分传参是否符合需求
                        },
                      ); // TODO: 你可能需要整理返回数据格式
                    return convertGetCurrentApplicationVsStaffWithStaffVoRes2FormStaff?.(
                      res,
                    );
                  },
                  fieldNames: {
                    label: 'staffName',
                    value: 'secondPrepareDrugStaffId',
                  },
                }}
              />
              <div></div>
              <Space size={12}>
                <Button children={'清屏'} onClick={onScreen}></Button>
                <Button children={'标签打印'} type="primary"></Button>
                <Button children={'打印预览'} type="primary"></Button>
                <Button
                  children={'发药确认1'}
                  type="primary"
                  onClick={() => {
                    Tip({
                      content: `是否确认发药`,
                      onOk: () => {
                        sendDrug();
                      },
                    });
                  }}
                ></Button>
              </Space>
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={0}></VSFormLayout>
          </VSForm>
        </div>
      </div>
      {selectTab === 1 ? (
        <DrawerCostom callback={(v) => setWardId(v)} />
      ) : (
        <DeneralDrawer callback={(v) => setWardId(v)} />
      )}
    </>
  );
}
const index = (props) => {
  return (
    <Screen>
      <Main {...props} />
    </Screen>
  );
};
export default index;
