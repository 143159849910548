import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getInpVisitInHospitalWithBedVoById_0824de: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-inp-visit-in-hospital-with-bed-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitInHospitalWithBedVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getInstitutionById_5594f2: {
    method: 'post',
    url: '/api/organization/get-institution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoClinicInpVisitEntranceWebVoInpvisitinhospitalwithbedvo,
    ) => data,
  },
});
