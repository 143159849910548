import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderTemplateMasterController_getOrderTemplateMaster_bfb379: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-master',
    parameterFomatter: (data?: {
      templateClass: OrderTemplateClassEnum;
      agreedPrescriptionIndicator: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
});
