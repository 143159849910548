import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Popover,
  Section,
  Tabs,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import { Ellipsis } from '@/pages/Index/components';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

import { convertQueryAllGreenWaitByErpVisitForPreExamPatientListQtoRes2ErpGreenWaitPatientList } from '../convert';

const Index = (props, ref) => {
  const { onChange, datas, cloumn, tabkey } = props;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any>();
  // 当前选中行id
  const [currentRow, setCurrentRow] = useState<any>();

  const [diseaseLevel, setDiseaseLevel] = useState<any>({});

  // 级别过滤
  const getDiseaseLevel = async () => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    if (res.code === 200) {
      const _res = (res?.data ?? [])?.reduce((acc, item) => {
        acc[item.diseaseLevelName] = item; // 或者 acc[item.id] = item.name; 如果你只想存储name
        return acc;
      }, {});
      setDiseaseLevel(_res);
    }
  };

  useEffect(() => {
    getDiseaseLevel();
  }, []);

  useEffect(() => {
    setData(datas);
  }, [datas]);

  const handelHide = (dataItem) => {
    const _arr = data?.map((i) => {
      return i?.id == dataItem?.id ? { ...i, show: !i?.show } : { ...i };
    });
    setData([..._arr]);
  };

  const renderRow = (i, index, item) => {
    const _value =
      item?.type && item?.type == 'select'
        ? item?.dataSource?.find((dataSourceItem) => {
            return dataSourceItem?.code == i?.[item.value];
          })?.label ?? i?.[item.value]
        : i?.[item.value];

    return (
      <div
        className={classNames(
          'custom_table_height custom_table_flex body_row_item text_ellipsis',
          item?.width,
        )}
        key={index}
        style={{
          // width: item?.width,
          // borderRight: index == 3 ? '' : '1px solid #d6d8d9',
          borderRight: '1px solid #d6d8d9',
          borderBottom: '1px solid #d6d8d9',
          flexShrink: 0,
          color: index == 0 ? diseaseLevel?.[_value]?.colorCode : '',
        }}
      >
        <Ellipsis content={_value}>{_value}</Ellipsis>
      </div>
    );
  };

  const flag = useRef(false);

  const renderItem = (dataItem) => {
    const now = dayjs();
    dataItem?.data?.map((item) => {
      if (item?.assessmentDateTime) {
        // 判断时间差
        const timer = dayjs(item?.assessmentDateTime[0]?.assessmentDateTime);
        const diff = now.diff(timer, 'minute');
        // console.log(diff, 'diff');
        // console.log(item.diseaseLevel.waitMinute, 'waitMinute');
        if (diff > item?.diseaseLevel?.waitMinute) {
          // if (diff > 12) {
          flag.current = true;
        } else {
          flag.current = false;
        }
      }
    });

    return (
      <div key={dataItem?.id} className="custom_table_body_item icon18">
        <div
          className="title_row"
          onClick={() => {
            handelHide(dataItem);
          }}
        >
          <div className="title_row_item icon18">{dataItem?.clinicLabel}</div>
          {tabkey == '3' && (
            // 要改
            <div className="title_row_item">
              {dataItem?.number ?? dataItem?.data?.length ?? 0}
              {dataItem?.centerText}
            </div>
          )}

          <div
            className="title_row_item"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {dataItem?.number ?? dataItem?.data?.length ?? 0}
            {dataItem?.rightText}
            {/* {tabkey == '1' && <div className="my_dot"></div>} */}
            {flag.current && tabkey == '1' && <div className="my_dot"></div>}
          </div>
        </div>
        {dataItem?.data?.map((i) => {
          return (
            <div
              key={i?.label}
              className={classNames(
                'body_row custom_table_height custom_table_flex body_row',
                currentRow == i?.spacicalId ? 'body_row_select' : '',
              )}
              style={{
                display: dataItem?.show ? '' : 'none',
                color: tabkey == '1' ? 'red' : '',
              }}
              onDoubleClick={() => {
                // console.log('7375_双击');
                if (currentRow && currentRow == i?.spacicalId) {
                  handelSetCurrentRow(null, null, null, null);
                  // setCurrentSpacicalId(null);
                  // 需要修改
                  vsf.stores.user.setErVisitInfo(null);
                  vsf.stores.user.setCurrentPatient(null);
                } else {
                  // 待诊特殊患者 spacicalIds
                  handelSetCurrentRow(
                    i?.displayId,
                    i?.spacicalId,
                    i?.patientId,
                    i?.spacicalIds,
                  );
                  // setCurrentSpacicalId(i?.spacicalId);
                  // spacicalId
                  // 需要修改
                  vsf.stores.user.setErVisitInfo(i);
                  vsf.stores.user.setCurrentPatient({
                    patientId: i?.patientId,
                  });
                }
              }}
            >
              {cloumn.map((item, index) => {
                return renderRow(i, index, item);
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const handelSetCurrentRow = (
    displayId?,
    spacicalId?,
    patientId?,
    spacicalIds?,
  ) => {
    setCurrentRow(spacicalId);
    onChange(displayId, { spacicalId, patientId, spacicalIds });
  };

  useImperativeHandle(ref, () => ({
    handelSetCurrentRow,
  }));

  return (
    <div className="custom_table icon18">
      <div className="custom_table_header custom_table_height custom_table_flex">
        {cloumn.map((item, index) => {
          return (
            <div key={index} className={item?.width} style={{ flexShrink: 0 }}>
              {item?.label}
            </div>
          );
        })}
      </div>
      <div className="custom_table_body hideScroll">
        {!loading ? (
          data?.length > 0 ? (
            data.map((dataItem) => {
              return renderItem(dataItem);
            })
          ) : (
            <Empty />
          )
        ) : (
          <Loading size="normal" />
        )}
      </div>
    </div>
  );
};
export default forwardRef(Index);
