import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPerformController_getJMSpecialDrugExecutedRecord_49abeb: {
    method: 'post',
    url: '/api/clinic-record-order-perform/get-j-m-special-drug-executed-record',
    parameterFomatter: (data?: {
      eo: QuerySpecialDrugExecutedRegisterEoClinicRecordOrderPerformPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformPlanRecordWithOrderPerformDrugDictionaryPatientBedVoClinicRecordOrderPerformEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderPerformController_getMainDisagnosisAndOperation_d8e46c: {
    method: 'post',
    url: '/api/clinic-record-order-perform/get-main-disagnosis-and-operation',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: string[]) => data,
  },
  ClinicRecordOrderPerformController_getDrugBatchByPerformPlanRecordId_3888cb: {
    method: 'post',
    url: '/api/clinic-record-order-perform/get-drug-batch-by-perform-plan-record-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExecutedDrugStockVoClinicRecordOrderPerformEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderPerformController_saveJMSpecialDrugExecutedRecord_ebc023: {
    method: 'post',
    url: '/api/clinic-record-order-perform/save-j-m-special-drug-executed-record',
    parameterFomatter: (data?: {
      eoList: SaveSpecialDrugPerformRecordEoClinicRecordOrderPerformPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformPlanRecordWithOrderPerformDrugDictionaryPatientBedVoClinicRecordOrderPerformEntranceWebVo[],
    ) => data,
  },
});
