import { getUUID } from '@/module/cpoe/medical/editor/utils';

import { convertOrderInField, convertOtherOrderInfo } from './convert';

export default function skin(value, dataSource) {
  const countablePreview: any = (data) => {
    const previewClassList = [
      { label: '治疗', value: 'TREAT' },
      { label: '手术', value: 'OPERATION' },
      { label: '麻醉', value: 'ANESTHESIA' },
      { label: '护理', value: 'NURSING' },
      { label: '膳食', value: 'MEAL' },
      { label: '营养', value: 'NUTRITION' },
      { label: '会诊', value: 'CONSULTATION' },
      { label: '用血', value: 'BLOOD' },
      { label: '其他', value: 'OTHER' },
    ];
    const value = previewClassList
      ?.map((item) => item?.value)
      .includes(data?.orderClass);
    return value;
  };
  /**
   * 查找最后一个组号补上
   */
  const last = (dataSource as unknown as any[])
    ?.map((item) => item?.drugOrder?.groupNumber)
    ?.filter(Boolean);
  const group = last?.length
    ? Math?.max(...(new Set(last) as unknown as any[]))
    : 0;
  const items = value?.map((item, index) => {
    const order = countablePreview({
      orderClass: item?.orderClass,
    })
      ? 'disposalOrder'
      : item?.orderClass?.toLocaleLowerCase() + 'Order';
    const data = {
      ...item,
      id: 'create' + Math?.random(),
      uuid: getUUID(),
      orderStatus: 'OPEN',
      orderText:
        order === 'disposalOrder'
          ? `${item?.orderText}(${value?.[0]?.orderText})`
          : item?.orderText +
            (item?.packageSpecification
              ? `(${item?.packageSpecification})`
              : ''),
      orderClass: item?.orderClass,
      performDepartment: item?.performDepartment,
      drugStorageId: item?.drugStorageId,
      ...(index === 0
        ? {
            relatedType: 'SKIN_TEST_VS_DRUG',
          }
        : {
            relatedType: 'SKIN_TEST_VS_DRUG',
            skinTestType: null,
          }),
      [order]: {
        ...item,
        ...(order === 'disposalOrder'
          ? {
              totalAmount: 1,
            }
          : {
              ...(index === 0
                ? {}
                : {
                    skinTestType: null,
                  }),
              groupNumber: item?.groupNumber ? group + 1 : null,
              groupSubNumber: item?.groupNumber ? item?.groupSubNumber : null,
            }),
      },
    };
    return data;
  });

  console.log(items, 'items');

  return items?.map((item, _index) => ({
    ...item,
    ...(_index === 0
      ? {
          _skin: item?.id,
          is_one: false,
        }
      : {
          repeatIndicator: false,
          parentRelatedUuid: items?.[0]?.uuid,
        }),
    /**
     * 皮试关联关系
     */
    _skinTest: items?.map((item) => item?.id),
  }));
}
