import vsf from '@vs/vsf-boot';
import {
  compose,
  Select,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useInputEnter } from '../dosage/utils';
import { askSearch } from '../func/ask';

export type PerformDepartmentProps = {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 值变化回调
   */
  onChange?: (value?: string) => void;
  /**
   * 当前行信息
   */
  record?: any;
};

const PerformDepartment = forwardRef((props: any, ref) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    onFieldEnter,
    onKeyUp,
    record,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const [value, setValue] = useControllableState<any>({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const [dataSource, setDataSource] = useState([]);
  const clinicItemId =
    record?.disposalOrder?.itemId ??
    record?.orderText?.detailInfo?.clinicItemId;
  const onGet = useCallback(async () => {
    const isValid = record?.orderText?.detailInfo;
    if (!isValid && clinicItemId && typeof record?.orderText === 'string') {
      const res =
        await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
          {
            clinicItemId,
          },
        );
      if (res?.code === 200) {
        if (res?.data?.performDepartmentId) {
          setValue({
            departmentName: res?.data?.performDepartmentName,
            id: res?.data?.performDepartmentId,
          });
        }
        setDataSource(
          res?.data?.clinicItemDictionaryVo?.clinicPerformDepartmentDtoList?.map(
            (item) => item?.performDepartment,
          ),
        );
      }
    } else {
      setDataSource(
        record?.orderText?.detailInfo?.clinicItemDictionaryVo?.clinicPerformDepartmentDtoList?.map(
          (item) => item?.performDepartment,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinicItemId,
    record?.orderText,
    record?.orderText?.detailInfo,
    record?.orderText?.detailInfo?.clinicItemDictionaryVo
      ?.clinicPerformDepartmentDtoList,
  ]);

  useEffect(() => {
    onGet();
  }, [onGet]);

  /** 编写组件逻辑 */
  return (
    <Select
      {...fieldEnterProps}
      {...rest}
      onChange={setValue}
      value={value}
      showSearch
      fieldNames={{
        label: 'departmentName',
        value: 'id',
      }}
      ref={ref}
      searchKey="nameLike"
      placeholder="请选择执行科室"
      dataSource={async (value) => {
        return value?.nameLike
          ? dataSource?.filter((item) =>
              askSearch(value?.nameLike, item, 'departmentName'),
            )
          : dataSource;
      }}
    />
  );
});
PerformDepartment.displayName = 'PerformDepartment';

export default compose(
  withField<string>({
    name: 'performDepartment',
    handleEnter: true,
  }),
  withPreview<PerformDepartmentProps>({
    renderPreview: (props: any) => {
      const { value } = props;
      /** 返回预览模式下的dom */
      return <span>{value?.departmentName}</span>;
    },
  }),
)(PerformDepartment);
