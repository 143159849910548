import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationPrivilegeController_queryApplicationVsStaffBaseVoByQueryStaffApplicationRoleQto_c8b849:
    {
      method: 'post',
      url: '/api/application-privilege/query-application-vs-staff-by-staff-id',
      parameterFomatter: (data?: {
        qto: QueryStaffApplicationRoleQtoApplicationPrivilegePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffBaseVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
  PrivilegeController_createOrUpdateApplicationStaffWithRole_0bd2f6: {
    method: 'post',
    url: '/api/application-privilege/create-or-update-application-staff-with-role',
    parameterFomatter: (data?: {
      btoList: CreateStaffWithRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_deleteApplicationStaffWithRole_7de462: {
    method: 'post',
    url: '/api/application-privilege/delete-application-staff-with-role',
    parameterFomatter: (data?: {
      bto: DeleteStaffWithRoleBtoApplicationPrivilegeServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_getAllRoleListOfStandardApplication_ce4076: {
    method: 'post',
    url: '/api/application-privilege/get-all-role-list-of-standard-application',
    parameterFomatter: (data?: { bizApplicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleDtoApplicationPrivilegeManagerDto[],
    ) => data,
  },
});
