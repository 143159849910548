import vsf from '@vs/vsf-boot';
import {
  Button,
  RadioGroup,
  VSTable,
  VSTableColumn,
  VSTableInstance,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import Container from '@/module/Container';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { Determine } from '@/pages/Index/components';
import Block from '@/pages/Index/components/block';
import { askSearch } from '@/pages/Index/components/func/ask';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';

type TableRef = VSTableInstance<any, 'text'> | null;
type Click =
  OrderPerformLabelSettingMasterWithItemVoClinicRecordOrderExtensionEntranceWebVo;
const Other = forwardRef((props: any, ref) => {
  const { getValue, setValue } = props;
  const usageRef = useRef<TableRef>(null);
  const editRef = useRef<TableRef>(null);
  const orderRef = useRef<TableRef>(null);
  const applicationRef = useRef<TableRef>(null);
  const [radio] = useOnChangeValue('type');
  const [click] = useOnChangeValue<Click>({});
  const [order] = useOnChangeValue<{
    orderClass?: string;
    id?: string | number;
  }>({});

  const {
    common: { currentApplication },
  } = vsf?.stores || {};

  useImperativeHandle(ref, () => ({
    onChange: click?.onChange,
    getValue: () => {
      return Promise?.all([
        orderRef?.current?.getEditForm?.()?.validateFieldsReturnFormatValue?.(),
        applicationRef?.current
          ?.getEditForm?.()
          ?.validateFieldsReturnFormatValue?.(),
        usageRef?.current?.getEditForm?.()?.validateFieldsReturnFormatValue?.(),
      ]);
    },
  }));

  const value = getValue?.();
  const DRUG =
    value?.[click?.value?.id ?? '']?.itemList?.find(
      (item) => item.id === order?.value?.id,
    )?.orderClass !== 'DRUG';

  const dataSource = [
    { label: '西药/中成药', value: 'DRUG' },
    { label: '中药', value: 'HERB' },
    { label: '检验', value: 'LAB' },
    { label: '检查', value: 'EXAM' },
    { label: '病理', value: 'PATHOLOGY' },
    { label: '治疗', value: 'TREAT' },
    { label: '手术', value: 'OPERATION' },
    { label: '麻醉', value: 'ANESTHESIA' },
    { label: '护理', value: 'NURSING' },
    { label: '膳食', value: 'MEAL' },
    { label: '营养', value: 'NUTRITION' },
    { label: '会诊', value: 'CONSULTATION' },
    { label: '用血', value: 'BLOOD' },
    { label: '其他', value: 'OTHER' },
  ];

  const is = useMemo(() => {
    return (
      currentApplication?.department?.clinicAttribute !== '2' ||
      getValue()[click?.value?.id ?? '']?.ward ||
      String(click?.value?.id ?? '')?.startsWith('create')
    );
  }, [
    click?.value?.id,
    currentApplication?.department?.clinicAttribute,
    getValue,
  ]);
  useEffect(() => {
    const a: any = click?.value;
    order?.onChange?.(a?.itemList?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [click?.value]);

  return (
    <Block
      style={{
        padding: 0,
        background: 'transparent',
      }}
      scroll={false}
    >
      <SearchContainer.Header
        title={
          <div className="aspirin-search-custom-box">
            <div>
              定义条件列表
              <RadioGroup
                {...radio}
                dataSource={[
                  {
                    label: '按医嘱类别',
                    value: 'type',
                  },
                  {
                    label: '按医嘱内容',
                    value: 'dict',
                  },
                ]}
              />
            </div>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <Determine
                condition={radio?.value === 'type'}
                fallback={
                  <Button
                    type="primary"
                    disabled={!click?.value?.id || !is}
                    onClick={() =>
                      editRef?.current?.add({
                        id: 'create_' + Math?.random(),
                      })
                    }
                  >
                    医嘱内容/新增
                  </Button>
                }
              >
                <Button
                  type="primary"
                  disabled={!click?.value?.id || !is}
                  onClick={() =>
                    orderRef?.current?.add({
                      id: 'create_' + Math?.random(),
                    })
                  }
                >
                  医嘱类别/新增
                </Button>
                <Button
                  type="primary"
                  disabled={!click?.value?.id || DRUG || !is}
                  onClick={() =>
                    applicationRef?.current?.add({
                      id: 'create_' + Math?.random(),
                    })
                  }
                >
                  用药途径/新增
                </Button>
                <Button
                  type="primary"
                  disabled={!click?.value?.id || DRUG || !is}
                  onClick={() =>
                    usageRef?.current?.add({
                      id: 'create_' + Math?.random(),
                    })
                  }
                >
                  用法/新增
                </Button>
              </Determine>
            </div>
          </div>
        }
        style={{
          paddingBottom: 20,
        }}
      />
      <Determine
        condition={radio?.value === 'type'}
        fallback={
          <VSTable
            editable={{
              editType: 'multiple',
              columnProps: {
                width: 50,
              },
              onCanDelete: () => false,
              onCanEdit: () => {
                /**
                 * 判断当前工作站是否为病区
                 */
                return is;
              },
              extraOperations: [
                {
                  children: is ? (
                    <Icon type="icon-youdishanchu1" size={24} />
                  ) : (
                    <Icon type="icon-a-shanchujinyong" size={24} />
                  ),
                  onClick: (...[, record]) => {
                    Tip({
                      content: `确定删除 {${record?.orderText ?? ''}}`,
                      onOk() {
                        const value = getValue?.();
                        const orderTextList = value[
                          click?.value?.id ?? ''
                        ]?.orderTextList?.filter(
                          (item) => item?.id !== record?.id,
                        );
                        setValue({
                          [click?.value?.id ?? '']: {
                            ...value[click?.value?.id ?? ''],
                            orderTextList,
                          },
                        });
                      },
                    });
                  },
                },
              ],
            }}
            ref={editRef}
            pagination={false}
            scroll={{
              y: getScrollY(400),
            }}
            value={value[click?.value?.id ?? '']?.orderTextList ?? []}
            onChange={(info) => {
              const value = getValue?.();
              setValue({
                [click?.value?.id ?? '']: {
                  ...value[click?.value?.id ?? ''],
                  orderTextList: info,
                },
              });
            }}
            className="aspirin-table-no-background-box"
            bordered
          >
            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱操作内容"
              valueType="text"
              fieldProps={{}}
              formItemProps={{
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              render={(...[, record]) => record?.orderText}
            />
          </VSTable>
        }
      >
        <Container
          layout={{
            items: [
              {
                block: '33%',
                gap: '10px',
              },
              {
                block: '33%',
                gap: '10px',
              },
              {
                block: '33%',
                gap: '10px',
              },
            ],
          }}
        >
          <VSTable
            editable={{
              editType: 'multiple',
              columnProps: {
                width: 50,
              },
              onCanDelete: () => false,
              onCanEdit: () => {
                /**
                 * 判断当前工作站是否为病区
                 */
                return is;
              },
              extraOperations: [
                {
                  children: is ? (
                    <Icon type="icon-youdishanchu1" size={24} />
                  ) : (
                    <Icon type="icon-a-shanchujinyong" size={24} />
                  ),
                  disabled: !is,
                  onClick: (...[, record]) => {
                    const orderClass =
                      dataSource?.find(
                        ({ value }) => record?.orderClass === value,
                      )?.label ?? '';
                    Tip({
                      content: `确定删除 {${orderClass ?? ''}}`,
                      onOk() {
                        const value = getValue?.();
                        const itemList = value[
                          click?.value?.id ?? ''
                        ]?.itemList?.filter((item) => item?.id !== record?.id);
                        setValue({
                          [click?.value?.id ?? '']: {
                            ...value[click?.value?.id ?? ''],
                            itemList,
                          },
                        });
                      },
                    });
                  },
                },
              ],
            }}
            ref={orderRef}
            pagination={false}
            scroll={{
              y: getScrollY(400),
            }}
            value={value[click?.value?.id ?? '']?.itemList ?? []}
            onChange={(info) => {
              const value = getValue?.();
              setValue({
                [click?.value?.id ?? '']: {
                  ...value[click?.value?.id ?? ''],
                  itemList: info,
                },
              });
            }}
            rowSelection={{
              selectType: 'single',
              onSelectedRowsChange: ([value]) => {
                order?.onChange?.(value);
              },
              hideColumn: true,
            }}
            rowClassName={(record) =>
              record?.id === order?.value?.id
                ? 'aspirin-row-selected-box'
                : 'aspirin-row-select-box'
            }
            rowClick={{
              rowClickType: 'select',
            }}
            className="aspirin-table-no-background-box"
            bordered
          >
            <VSTableColumn
              dataIndex={['orderClass']}
              title="医嘱类别"
              valueType="custom"
              renderFormItem={(...[schema]) => {
                return (
                  <Select
                    options={dataSource}
                    showSearch
                    placeholder="请选择医嘱类别"
                    filterOption={askSearch}
                    onChange={(info) => {
                      schema?.originProps?.onChange(info);
                    }}
                  />
                );
              }}
              render={(...[, record]) => {
                return (
                  dataSource?.find((item) => item?.value === record?.orderClass)
                    ?.label ?? ''
                );
              }}
              formItemProps={{
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
            />
          </VSTable>
          <VSTable
            editable={{
              editType: 'multiple',
              columnProps: {
                width: 50,
              },
              onCanDelete: () => false,
              onCanEdit: () => {
                /**
                 * 判断当前工作站是否为病区
                 */
                return is;
              },
              extraOperations: [
                {
                  children: is ? (
                    <Icon type="icon-youdishanchu1" size={24} />
                  ) : (
                    <Icon type="icon-a-shanchujinyong" size={24} />
                  ),
                  disabled: !is,
                  onClick: (...[, record]) => {
                    const orderClass =
                      (
                        vsf.stores.dbenums.enums.ADMINISTRATION_DICT ?? []
                      )?.find(({ value }) => record?.administration === value)
                        ?.label ?? '';
                    Tip({
                      content: `确定删除 {${orderClass ?? ''}}`,
                      onOk() {
                        const value = getValue?.();
                        const administrationList = value[
                          click?.value?.id ?? ''
                        ]?.administrationList?.filter(
                          (item) => item?.id !== record?.id,
                        );
                        setValue({
                          [click?.value?.id ?? '']: {
                            ...value[click?.value?.id ?? ''],
                            administrationList,
                          },
                        });
                      },
                    });
                  },
                },
              ],
            }}
            className="aspirin-table-no-background-box"
            bordered
            ref={applicationRef}
            pagination={false}
            scroll={{
              y: getScrollY(400),
            }}
            value={
              !DRUG
                ? value?.[click?.value?.id ?? '']?.administrationList ?? []
                : []
            }
            onChange={(info) => {
              const value = getValue?.();
              setValue({
                [click?.value?.id ?? '']: {
                  ...value[click?.value?.id ?? ''],
                  administrationList: info,
                },
              });
            }}
          >
            <VSTableColumn
              dataIndex={['administration']}
              title="给药途径"
              valueType="codeTableSelect"
              fieldProps={{
                showSearch: true,
              }}
              dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
              formItemProps={{
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              render={(...[, record]) => {
                return (
                  vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
                    (item) => item?.value === record?.administration,
                  )?.label ?? ''
                );
              }}
            />
          </VSTable>
          <VSTable
            editable={{
              editType: 'multiple',
              columnProps: {
                width: 50,
              },
              onCanDelete: () => false,
              onCanEdit: () => {
                /**
                 * 判断当前工作站是否为病区
                 */
                return is;
              },
              extraOperations: [
                {
                  children: is ? (
                    <Icon type="icon-youdishanchu1" size={24} />
                  ) : (
                    <Icon type="icon-a-shanchujinyong" size={24} />
                  ),
                  disabled: !is,
                  onClick: (...[, record]) => {
                    const orderClass =
                      (
                        vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT ??
                        []
                      )?.find(({ value }) => record?.useDescription === value)
                        ?.label ?? '';
                    Tip({
                      content: `确定删除 {${orderClass ?? ''}}`,
                      onOk() {
                        const useDescriptionList = value[
                          click?.value?.id ?? ''
                        ]?.useDescriptionList?.filter(
                          (item) => item?.id !== record?.id,
                        );
                        setValue({
                          [click?.value?.id ?? '']: {
                            ...value[click?.value?.id ?? ''],
                            useDescriptionList,
                          },
                        });
                      },
                    });
                  },
                },
              ],
            }}
            ref={usageRef}
            pagination={false}
            value={
              !DRUG
                ? value?.[click?.value?.id ?? '']?.useDescriptionList ?? []
                : []
            }
            scroll={{
              y: getScrollY(400),
            }}
            onChange={(info) => {
              setValue({
                [click?.value?.id ?? '']: {
                  ...value[click?.value?.id ?? ''],
                  useDescriptionList: info,
                },
              });
            }}
            className="aspirin-table-no-background-box"
            bordered
          >
            <VSTableColumn
              dataIndex={['useDescription']}
              title="用法"
              valueType="codeTableSelect"
              fieldProps={{
                showSearch: true,
              }}
              dataSource={vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT}
              formItemProps={{
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              render={(...[, record]) => {
                return (
                  vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT?.find(
                    (item) => item?.value === record?.useDescription,
                  )?.label ?? ''
                );
              }}
            />
            <VSTableColumn
              dataIndex={['includeIndicator']}
              title="包含"
              valueType="check"
              width={50}
              initialValue={false}
              fieldProps={{}}
            />
          </VSTable>
        </Container>
      </Determine>
    </Block>
  );
});

Other.displayName = 'Other';

export default Other;
