import './index.less';

import vsf from '@vs/vsf-boot';
import { Input, message, Radio, VSForm, VSFormItem } from '@vs/vsf-kit';
import { useAsyncEffect, useRequest } from 'ahooks';
import { Select } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { AsyncSuspense, DragDrop } from '@/pages/Index/components';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

const CommonMedicines = ({
  id: value,
  clinicItemName: label,
  index,
  changePosition,
  handelDelete,
  onCancelCollect,
  type,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'DragDropBox',
    hover: (item: any, monitor) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return; // 如果回到自己的坑，那就什么都不做
      changePosition(dragIndex, hoverIndex); // 调用传入的方法完成交换
      item.index = hoverIndex; // 将当前当前移动到Box的index赋值给当前拖动的box，不然会出现两个盒子疯狂抖动！
    },
    drop: (item, monitor) => {
      console.log('drop', item, monitor);
    },
  });

  const [{ isDragging }, drag]: any = useDrag({
    type: 'DragDropBox',
    item: { value, index },

    end: (_index, _indexmonitor) => {
      console.log(_index, _indexmonitor, '_index_index_index');

      if (index == _index?.index || !_index) return; // 如果回到自己的坑或者没有放到指定元素，那就什么都不做
      changePosition(index, _index?.index, _index?.value);
    },
    isDragging: (monitor) => {
      return index === monitor.getItem().index;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <div key={value} className="aspirin-common-item-box" ref={drag(drop(ref))}>
      <div>
        <Icon
          type="icon-tuodong"
          size={24}
          style={{
            cursor: 'move',
          }}
        />
        <span>{label}</span>
      </div>
      <div>
        <Icon
          type="icon-cuo"
          size={24}
          onClick={() => onCancelCollect(value)}
          style={{
            cursor: 'pointer',
          }}
        />
      </div>
    </div>
  );
};

export default CommonMedicines;
