import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_getAll_6ecb98: {
    method: 'post',
    url: '/api/dbenum/codetype/all/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeDtoBaseDictionaryManagerDto[],
    ) => data,
  },
  BaseDictionaryController_queryAllByBaseCodeTypeIndexQto_310527: {
    method: 'post',
    url: '/api/base-dictionary/query-all-by-base-code-type-index-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeTypeIndexQtoBaseDictionaryPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeTypeVoBaseDictionaryEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
