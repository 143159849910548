import classnames from 'classnames';
import React, { useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import { useHorizontalScroll } from '@/utils/hooks';

const mockMenuList = [
  { label: '患者概览' },
  { label: '就诊史' },
  { label: '病历文书' },
  { label: '医嘱信息' },
  { label: '报告表单' },
  { label: '既往史' },
  { label: '外来资料' },
  { label: '护理记录' },
  { label: '首页信息' },
  { label: '手麻记录' },
];

const Index = () => {
  const indexCls = getPrefixCls('index-center-menu');
  const [current, setCurrent] = useState<string>('');
  const menuRef = useRef<any>();
  useHorizontalScroll(menuRef);

  const handleSetActive = (item) => {
    setCurrent(item?.label);
  };
  return (
    <div className={`${indexCls} noscroll`} ref={menuRef}>
      {mockMenuList?.map((item) => (
        <div
          onClick={() => {
            handleSetActive(item);
          }}
          className={classnames('item', {
            'active-item': item?.label === current,
          })}
          key={item?.label}
        >
          {item?.label}
        </div>
      ))}
    </div>
  );
};

export default Index;
