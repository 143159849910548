import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpAdtController_queryErpPatientInTransferWaitInVoByErpPatientInTransferWaitInQto_fa817d:
    {
      method: 'post',
      url: '/api/clinic-erp-adt/query-erp-patient-in-transfer-wait-in-vo-by-erp-patient-in-transfer-wait-in-qto',
      parameterFomatter: (data?: {
        qto: ErpPatientInTransferWaitInQtoClinicErpAdtPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpPatientInTransferWaitInVoClinicErpAdtEntranceWebVo[],
      ) => data,
    },
});
