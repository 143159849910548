import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugImportExportController_deleteCustomizeImportExport_71acdd: {
    method: 'post',
    url: '/api/drug-storage/delete-customize-import-export',
    parameterFomatter: (data?: {
      bto: DeleteCustomizeImportExportBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_createOrUpdateCustomizeImportExport_b6ee0f: {
    method: 'post',
    url: '/api/drug-storage/create-or-update-customize-import-export',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateCustomizeImportExportBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_getCustomizedImportExportList_833872: {
    method: 'post',
    url: '/api/drug-storage/get-customized-import-export-list',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: CustomizeDrugExportImportListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_getCandidateDepartmentList_fc3671: {
    method: 'post',
    url: '/api/drug-storage/get-candidate-department-list',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: DepartmentDtoOrganizationManagerDto[]) =>
      data,
  },
  DrugImportExportController_getAllExportImportTypeDictionary_c468af: {
    method: 'post',
    url: '/api/drug-storage/get-all-export-import-type-dictionary',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ImportExportClassDictionaryVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
