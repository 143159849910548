import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getAllByDrugApplyMasterForExportQto_1cc884:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-all-by-drug-apply-master-for-export-qto',
      parameterFomatter: (data?: {
        qto: DrugApplyMasterForExportQtoDrugStorageImportExportPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugApplyMasterForExportVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugStorageImportExportController_invalidApplyMaster_d46bc9: {
    method: 'post',
    url: '/api/drug-storage-import/invalid-apply-master',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyMasterForExportVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
});
