import SelectForm from '@/pages/Index/components/select_form';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  Divider,
  VSFormLayout,
  Row,
  Col,
  Space,
  VSTable,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  Form,
} from '@vs/vsf-kit';
import React, {
  useCallback,
  useEffect,
  useState,
  Fragment,
  useMemo,
  memo,
} from 'react';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { useUpdateEffect } from 'ahooks';
import { useFrequencyMemo } from '@/pages/Index/components/func/hooks';
import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import { labelNumberWidth } from '@/utils';

const Table = memo(
  (props) => {
    const [department, setDepartment] = useState([]);
    const [branchInstitution, setBranchInstitution] = useState([]);
    const [institutionIdIs, setInstitutionIdIs] = useState('');
    const branchInstitutionIs = vsf.refs?.ClinicItemEdit?.getFieldValue(
      'branchInstitutionIdList',
    );
    const form = Form?.useFormInstance();
    const clinicPerformDepartmentBtoList =
      Form?.useWatch('clinicPerformDepartmentBtoList', form) ?? [];

    /**
     * 获取科室院区
     */
    const getBranchInstitution = useCallback(async () => {
      const res =
        await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.(
          {},
        );
      if (res.code === 200) {
        setInstitutionIdIs(res.data.id);
        setBranchInstitution(
          res.data.branchInstitutionList.map((item) => {
            return {
              label: item.institutionName,
              value: item.id,
            };
          }),
        );
      }
    }, []);

    const get = useCallback(async () => {
      if (branchInstitutionIs?.select) {
        const res =
          await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
            {
              qto: {
                institutionIdIs,
                stopIndicatorIs: false,
                branchInstitutionIdIn: branchInstitutionIs?.select,
              },
            },
          );
        if (res.code === 200) {
          const dataSource = res.data.map((item) => {
            const { id, ...rest } = item;
            return {
              ...rest,
              label: `${item?.departmentName ?? ''}(${
                item.branchInstitution?.institutionName ?? ''
              })`,
              value: id,
            };
          });
          setDepartment(dataSource);
        }
      } else {
        setDepartment([]);
      }
    }, [branchInstitutionIs?.select]);

    /**
     * 当院区发生改变请求执行科室院区
     */
    useUpdateEffect(() => {
      get();
    }, [get]);

    useUpdateEffect(() => {
      /**
       * 删除院区,也删除对应已选科室
       */
      const list = props?.value?.filter((item) => {
        const id =
          item?.branchInstitution?.id ??
          item?.performDepartment?.branchInstitution?.id;
        return branchInstitutionIs?.select?.includes(id) || !Boolean(id);
      });
      vsf?.refs?.ClinicItemEdit?.setFieldValue(
        'clinicPerformDepartmentBtoList',
        list,
      );
    }, [branchInstitutionIs?.select]);

    const onRemove = (id) => {
      vsf?.refs?.ClinicItemTable?.remove(id);
    };

    useEffect(() => {
      getBranchInstitution();
    }, []);

    const state = useFrequencyMemo(() => props.value, 1, [props.value]);

    const dataSource = (value) => {
      const list = department
        ?.filter((item) => {
          return !clinicPerformDepartmentBtoList
            ?.map((item) => item?.performDepartment?.value)
            ?.includes(item?.value);
        })
        ?.map((item) => {
          const index = state?.findIndex?.((value) => {
            return value?.performDepartment?.value === item?.value;
          });
          return {
            ...item,
            ...(![null, undefined, -1].includes(index)
              ? { id: state[index]?.id }
              : {}),
          };
        });
      return value?.label
        ? list?.filter((item) => askSearch(value?.label, item))
        : list;
    };

    // const status = Form?.Item?.useStatus();

    // useEffect(() => {
    //   if(status?.status === 'validating'){
    //     vsf?.refs?.ClinicItemTable?.getEditForm?.()?.validateFieldsReturnFormatValue?.();
    //   }
    // }, [status?.status])
    // console.log(props?.value);
    return (
      <VSTable
        className="aspirin-toolbar-title-box"
        editable={{
          editType: 'multiple',
          onFieldChange: (...arr) => {
            const [key, data, , form] = arr;
            form.setFieldValue(key, {
              ...data,
              branchInstitution: {
                label:
                  data?.performDepartment?.branchInstitution?.institutionName,
                value: data?.performDepartment?.branchInstitution?.id,
              },
            });
          },
          columnProps: {
            width: 70,
          },
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          onCanDelete: () => null,
          editText: <Icon type="icon-bianji" size={24} />,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              onClick: (...[, data]) => {
                /**
                 * 判断是否创建过，是就删除提醒，否则直接删除
                 */
                if (!String(data.id).startsWith('create')) {
                  Tip({
                    content: `确定删除{【${data.performDepartment.label}】}，删除后不可恢复!`,
                    onOk: () => onRemove(data),
                  });
                } else {
                  onRemove(data);
                }
              },
            },
          ],
        }}
        onChange={props?.onChange}
        vsid="28842"
        id="ClinicItemTable"
        onRecord={() => {
          return { id: 'create_' + Math.random() };
        }}
        value={props?.value}
        pagination={false}
        dragSort={{
          dragSortDataIndex: 'sort',
          onDragSortEnd: (sortedData) => {
            vsf.refs?.ClinicItemEdit?.setFieldValue(
              'clinicPerformDepartmentBtoList',
              sortedData,
            );
          },
        }}
      >
        <VSTableColumn
          dataIndex="sort"
          title="排序"
          width={50}
          editable={false}
        />
        <VSTableColumn
          dataIndex={['performDepartment']}
          title="科室名称"
          valueType="select"
          fieldProps={{
            dataSource,
            showSearch: true,
          }}
          fieldNames={{
            label: 'label',
            value: 'value',
          }}
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
        />
        <VSTableColumn
          dataIndex={['branchInstitution']}
          title="执行科室所属院区"
          valueType="select"
          dataSource={branchInstitution}
          preview
          width={labelNumberWidth(12)}
        />
        <VSTableColumn
          dataIndex={['index']}
          title="排序"
          valueType="index"
          width={70}
          fieldProps={{
            disabled: true,
          }}
        />
        <VSTableToolbar
          title={
            <Fragment>
              <Space className="aspirin-toolbar-title-box">
                <Space className="aspirin-toolbar-title-text-box">
                  执行科室
                </Space>
                本科室
                <VSFormItem
                  name={['includeCurrentDepartmentIndicator']}
                  style={{
                    height: '100%',
                    margin: 0,
                  }}
                  valueType="check"
                />
                包含并默认
              </Space>
            </Fragment>
          }
        >
          <VSTableAddition>新增</VSTableAddition>
        </VSTableToolbar>
      </VSTable>
    );
  },
  (oldProps, newProps) => Object?.is(oldProps, newProps),
);

const ClinicItemEdit = (props) => {
  const [dataSource, setDataSource] = useState([]);

  /**
   * 获取院区
   */
  const getDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );
    setDataSource(
      res.data.branchInstitutionList.map((item) => ({
        label: item.institutionName,
        value: item.id,
      })),
    );
  }, []);
  /**
   * 修改回显数据
   */
  const getValue = useCallback(async () => {
    try {
      const {
        data: {
          branchInstitutionIdList,
          clinicPerformDepartmentDtoList,
          ...value
        },
      } =
        await vsf?.services?.ClinicItemBaseController_getClinicItemById_97a99d?.(
          {
            id: props.value?.id,
          },
        );

      vsf.refs.ClinicItemEdit.setFieldsValue({
        ...value,
        branchInstitutionIdList: {
          select: branchInstitutionIdList,
        },
        clinicPerformDepartmentBtoList: clinicPerformDepartmentDtoList
          .map((item) => {
            const { id, departmentName, ...performDepartment } =
              item?.performDepartment;
            return {
              ...item,
              performDepartment: {
                ...performDepartment,
                label: departmentName,
                value: id,
              },
              branchInstitution: {
                value: item?.performDepartment?.branchInstitution?.id,
                label:
                  item?.performDepartment?.branchInstitution?.institutionName,
              },
            };
          })
          ?.reverse(),
      });
    } catch (error) {
      return [];
    }
  }, []);

  useEffect(() => {
    if (props.value) {
      getValue();
    } else {
      vsf.refs.ClinicItemEdit.setFieldsValue({
        itemClass: props.data?.parentId ?? props?.data?.id,
      });
    }
    getDataSource();
  }, []);

  const itemCode = useMemo(() => {
    return (
      new Date().toLocaleDateString().replaceAll('/', '') +
      (Math.random() * (99 * 10000)).toFixed(0)
    );
  }, []);

  const isValue = useMemo(() => props?.value, []);

  return (
    <VSControlledForm
      vsid="57089"
      id="ClinicItemEdit"
      onChange={(value) => {
        // console.log(value);
        props.onChange?.(value);
      }}
      labelAlign="left"
    >
      <Row justify="space-between" gutter={50}>
        <Col span={12}>
          <VSFormItem
            name={['id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormLayout
            key="1"
            columnCount={2}
            gutter={50}
            labelWidth={labelNumberWidth(8)}
          >
            <VSFormItem
              name={['itemClass']}
              label="项目类别"
              fieldProps={{ disabled: true }}
              valueType="select"
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
              ]}
            />

            <VSFormItem
              name={['itemCode']}
              label="项目代码"
              valueType="text"
              initialValue={itemCode}
              rules={[
                {
                  message: `项目代码不能超过20个字`,
                  max: 2000,
                  min: 0,
                },
                { required: true },
              ]}
              fieldProps={{
                disabled: isValue,
              }}
            />
            <VSFormItem
              name={['clinicItemName']}
              label="项目名称"
              valueType="text"
              rules={[
                {
                  message: '项目名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
                { required: true },
              ]}
              fieldProps={{
                onChange: ({ target }) => {
                  vsf.refs.ClinicItemEdit.setFieldsValue({
                    inputCode: askRomanAlphabet(target.value),
                  });
                },
              }}
            />

            <VSFormItem
              name={['inputCode']}
              label="拼音输入码"
              valueType="text"
              rules={[
                {
                  message: '拼音码最多100字符',
                  type: 'string',
                  min: 0,
                  max: 100,
                },
                { required: true },
                () => ({
                  validator(_, value) {
                    if (String(value).includes(',')) {
                      return Promise.reject('多音字待确认');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['limitGender']}
              label="限制性别"
              valueType="select"
              dataSource={[
                { label: '无限制', value: 'NO_LIMIT' },
                { label: '限男性', value: 'MALE_ONLY' },
                { label: '限女性', value: 'FEMALE_ONLY' },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['specialNeedIndicator']}
              label="是否特需"
              valueType="select"
              initialValue={false}
              dataSource={[
                {
                  label: '是',
                  value: true,
                },
                {
                  label: '否',
                  value: false,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout
            key="4"
            columnCount={1}
            gutter={50}
            labelWidth={labelNumberWidth(8)}
          >
            <VSFormItem
              name={['useScope']}
              label="使用范围"
              valueType="select"
              initialValue={'ALL'}
              dataSource={[
                { label: '全院', value: 'ALL' },
                { label: '住院', value: 'INP' },
                { label: '门诊', value: 'OUTP' },
              ]}
              rules={[
                {
                  required: true,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['memo']}
              label="备注"
              valueType="textarea"
              rules={[
                {
                  message: `备注不能超过200个字`,
                  max: 200,
                  min: 0,
                },
              ]}
              fieldProps={{
                rows: 4,
              }}
            />
            <VSFormItem
              name={['description']}
              label="项目说明"
              valueType="textarea"
              rules={[
                {
                  message: `项目说明不能超过2000个字`,
                  max: 2000,
                  min: 0,
                },
              ]}
              fieldProps={{
                rows: 4,
              }}
            />
            <VSFormItem
              name={['branchInstitutionIdList']}
              label="院区"
              valueType="custom"
              rules={[{ required: true, message: '请选择院区' }]}
            >
              <SelectForm
                dataSource={dataSource}
                selectAllText="全部院区"
                formProps={{
                  labelAlign: 'left',
                }}
                selectLayoutProps={{
                  columnCount: 1,
                  gutter: 50,
                }}
              />
            </VSFormItem>
          </VSFormLayout>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={10}>
          <Space direction="vertical">
            <VSFormItem
              name={['clinicPerformDepartmentBtoList']}
              valueType="custom"
              transform={(value) => {
                return {
                  clinicPerformDepartmentBtoList: value?.filter((item) => {
                    const { id, ...rest } = item;
                    return Object?.values(rest)?.length;
                  }),
                };
              }}
            >
              <Table />
            </VSFormItem>
          </Space>
        </Col>
      </Row>
    </VSControlledForm>
  );
};
export default definePage(ClinicItemEdit);
