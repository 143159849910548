import { createServices } from '@vs/vsf-boot';
export default createServices({
  TreatController_stopTreatClass_476e69: {
    method: 'post',
    url: '/api/treat/stop-treat-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TreatController_getAllByTreatClassQto_9c1625: {
    method: 'post',
    url: '/api/treat/get-all-by-treat-class-qto',
    parameterFomatter: (data?: { qto: TreatClassQtoTreatPersistQto }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo[]) =>
      data,
  },
});
