import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryPagedByDrugProductSpecificationVsStorageQto_172d45: {
    method: 'post',
    url: '/api/drug-drug/query-paged-by-drug-product-specification-vs-storage-qto',
    parameterFomatter: (data?: {
      qto: DrugProductSpecificationVsStorageQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugProductDictionaryNameFormStockPriceVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  CurrentDrugPriceItemController_getCurrentDrugPricesByDrugProductId_3d0b37: {
    method: 'post',
    url: '/api/drug-drug/get-current-drug-prices-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CurrentDrugPriceItemEoDrugDrugPersistEo[],
    ) => data,
  },
  DrugSpecificationVsStorageController_saveDrugSpecificationVsStorage_9773a6: {
    method: 'post',
    url: '/api/drug-storage/save-drug-specification-vs-storage',
    parameterFomatter: (data?: {
      item: DrugSpecificationVsStorageDtoDrugStorageManagerDto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugSpecificationVsStorageController_supplyByDrugProductId_4b5bb7: {
    method: 'post',
    url: '/api/drug-storage/supply-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
