import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

type ModalType = 'execute' | 'stop' | 'cancel' | 'invaild' | undefined;

const orderClassType = [
  { label: '西药', value: 'DRUG' },
  { label: '中药', value: 'HERB' },
  { label: '检验', value: 'LAB' },
  { label: '检查', value: 'EXAM' },
  { label: '病理', value: 'PATHOLOGY' },
  { label: '治疗', value: 'TREAT' },
  { label: '手术', value: 'OPERATION' },
  { label: '麻醉', value: 'ANESTHESIA' },
  { label: '护理', value: 'NURSING' },
  { label: '膳食', value: 'MEAL' },
  { label: '营养', value: 'NUTRITION' },
  { label: '会诊', value: 'CONSULTATION' },
  { label: '用血', value: 'BLOOD' },
  { label: '其他', value: 'OTHER' },
];

const ExecuteModal = (props, ref): any => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue, getValue] = useGetState<any>();
  const [type, setType, getType] = useGetState<string>();
  const [modalType, setModalType, getModalType] = useGetState<ModalType>();

  const renderContent = useCallback(() => {
    const data = [value?.format];
    return (
      <div className="content">
        <div className="first">
          <VSTable value={data} pagination={false}>
            <VSTableColumn
              valueType="text"
              dataIndex={['startPerformDateTime']}
              title="开始时间"
              preview
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱内容"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
      </div>
    );
  }, [value]);

  // 作废医嘱弹窗
  const renderInvaildForm = useCallback(
    (data) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
            }}
          >
            <VSFormLayout key="1" columnCount={3}>
              <VSFormItem
                name={['performResultDescription']}
                label="作废原因"
                valueType="select"
                dataSource={
                  vsf.stores.dbenums?.enums?.CANCEL_EXECUTED_REASON_DICT
                }
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 停止医嘱弹窗
  const renderStopDrugForm = useCallback(
    (data) => {
      const { origin } = data;
      const isDrug = origin?.order?.orderClass === 'DRUG';

      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
              performDosage: value?.format?.performDosage ?? 100,
            }}
          >
            <VSFormLayout key="1" columnCount={3}>
              <VSFormItem
                name={['planDateTime']}
                label="计划时间"
                readonly
                valueType="date"
                fieldProps={{ showTime: true, disabled: true }}
              />

              <VSFormItem
                name={['performResult']}
                label="执行结果"
                valueType="select"
                dataSource={[
                  { label: '部分执行', value: 'PARTIAL' },
                  { label: '全部执行', value: 'ALL' },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['performDateTime']}
                label="执行时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />
            </VSFormLayout>
            {isDrug ? (
              <VSFormLayout columnCount={3}>
                <VSFormItem
                  name={['performDosage']}
                  label="执行剂量"
                  valueType="slider"
                  fieldProps={{
                    renderValue: (v) => `${v}%`,
                    min: 0,
                    max: 100,
                  }}
                />
                {value?.format?.skinTestVsDrugIndicator && (
                  <VSFormItem
                    name={['skinTestResult']}
                    label="皮试结果"
                    valueType="select"
                    dataSource={vsf.stores.dbenums?.enums?.SKIN_RESULT}
                  />
                )}
                <VSFormItem
                  name={['stopDateTime']}
                  label="停止时间"
                  valueType="date"
                  fieldProps={{ showTime: true }}
                />
              </VSFormLayout>
            ) : (
              <VSFormLayout columnCount={3}>
                <VSFormItem
                  name={['duration']}
                  label="持续时间"
                  readonly
                  valueType="digit"
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['durationUnit']}
                  label="持续时间单位"
                  readonly
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 8,
                    },
                  ]}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['stopDateTime']}
                  label="停止时间"
                  valueType="date"
                  fieldProps={{ showTime: true }}
                />
              </VSFormLayout>
            )}
            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 检查、病理执行弹窗
  const renderExamForm = useCallback(
    (disabled) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
            }}
            disabled={disabled}
          >
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['examScheduleDateTime']}
                label="预约日期"
                readonly
                valueType="date"
                fieldProps={{ showTime: true }}
              />

              <VSFormItem
                name={['performDepartmentName']}
                label="检查科室"
                readonly
                valueType="text"
                rules={[
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['performDateTime']}
                label="确认时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 中药、会诊、其他执行弹窗
  const renderHerbForm = useCallback(
    (disabled) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
            }}
            disabled={disabled}
          >
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['planDateTime']}
                label="计划时间"
                readonly
                valueType="date"
                fieldProps={{ showTime: true, disabled: true }}
              />

              <VSFormItem
                name={['performResult']}
                label="执行结果"
                valueType="select"
                dataSource={[
                  { label: '部分执行', value: 'PARTIAL' },
                  { label: '全部执行', value: 'ALL' },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['performDateTime']}
                label="执行时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />

              <VSFormItem
                name={['duration']}
                label="持续时间"
                readonly
                valueType="digit"
                fieldProps={{}}
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 皮试执行弹窗
  const renderSkinForm = useCallback(
    (disabled) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
            }}
            disabled={disabled}
          >
            <VSFormLayout key="0" columnCount={3}>
              <VSFormItem
                name={['planDateTime']}
                label="计划时间"
                readonly
                valueType="date"
                fieldProps={{ showTime: true, disabled: true }}
              />

              {value?.format?.skinTestVsDrugIndicator && (
                <VSFormItem
                  name={['skinTestResult']}
                  label="皮试结果"
                  valueType="select"
                  dataSource={vsf.stores.dbenums?.enums?.SKIN_RESULT}
                  required
                />
              )}

              <VSFormItem
                name={['performResult']}
                label="执行结果"
                valueType="select"
                dataSource={[
                  { label: '部分执行', value: 'PARTIAL' },
                  { label: '全部执行', value: 'ALL' },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['performDateTime']}
                label="执行时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />

              <VSFormItem
                name={['duration']}
                label="持续时间"
                readonly
                valueType="digit"
                fieldProps={{}}
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 西药执行弹窗
  const renderDrugForm = useCallback(
    (disabled) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
              performDosage: value?.format?.performDosage ?? 100,
            }}
            disabled={disabled}
          >
            <VSFormLayout key="0" columnCount={3}>
              <VSFormItem
                name={['planDateTime']}
                label="计划时间"
                readonly
                valueType="date"
                fieldProps={{ showTime: true, disabled: true }}
              />

              {value?.format?.skinTestVsDrugIndicator && (
                <VSFormItem
                  name={['skinTestResult']}
                  label="皮试结果"
                  // readonly
                  valueType="select"
                  required
                  dataSource={vsf.stores.dbenums?.enums?.SKIN_RESULT}
                />
              )}

              <VSFormItem
                name={['performDateTime']}
                label="执行时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />

              <VSFormItem
                name={['performDosage']}
                label="执行剂量"
                valueType="slider"
                fieldProps={{
                  renderValue: (v) => `${v}%`,
                  min: 0,
                  max: 100,
                }}
              />

              <VSFormItem
                name={['performResult']}
                label="执行结果"
                valueType="select"
                dataSource={[
                  { label: '部分执行', value: 'PARTIAL' },
                  { label: '全部执行', value: 'ALL' },
                ]}
                fieldProps={{}}
                rules={[
                  {
                    // required: true,
                  },
                ]}
              />

              <VSFormItem
                name={['duration']}
                label="持续时间"
                readonly
                valueType="text"
                fieldProps={{}}
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  // 手术执行弹窗
  const renderOperationForm = useCallback(
    (disabled) => {
      return (
        <div className="form">
          <VSForm
            id="executeForm"
            initialValues={{
              ...value?.format,
            }}
            disabled={disabled}
          >
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['operationScheduleDateTime']}
                label="手术时间"
                readonly
                valueType="date"
                fieldProps={{ showTime: true }}
              />

              <VSFormItem
                name={['operationRoomNumber']}
                label="手术间"
                readonly
                valueType="text"
                rules={[
                  { message: '数据格式错误', type: 'string', min: 0, max: 10 },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationRoomdepartmentName']}
                label="手术室"
                readonly
                valueType="text"
                rules={[
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationSequence']}
                label="台次"
                readonly
                valueType="digit"
                fieldProps={{}}
              />

              <VSFormItem
                name={['performDateTime']}
                label="确认时间"
                valueType="date"
                fieldProps={{ showTime: true }}
              />
            </VSFormLayout>

            <VSFormLayout key="7" columnCount={1}>
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      );
    },
    [value],
  );

  const renderForm = useCallback(() => {
    if (modalType === 'execute' || modalType === 'cancel') {
      if (value?.format?.skinTestVsDrugIndicator) {
        return renderSkinForm(modalType === 'cancel');
      } else {
        switch (value?.origin?.order?.orderClass) {
          case 'DRUG':
            {
              return renderDrugForm(modalType === 'cancel');
            }
            break;
          case 'OPERATION':
            {
              return renderOperationForm(modalType === 'cancel');
            }
            break;
          case 'EXAM':
          case 'PATHOLOGY':
            {
              return renderExamForm(modalType === 'cancel');
            }
            break;

          default:
            {
              return renderHerbForm(modalType === 'cancel');
            }
            break;
        }
      }
    } else if (modalType === 'stop') {
      return renderStopDrugForm(value);
    } else if (modalType === 'invaild') {
      return renderInvaildForm(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType, value, renderStopDrugForm]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    const values =
      await vsf?.refs?.executeForm?.validateFieldsReturnFormatValue();
    const res = await props?.onSubmit({
      type: getType(),
      detail: getValue(),
      values: values,
    });
    if (res?.code === 200) {
      message.success('操作成功');
      onClose();
    } else {
      message.error('操作失败');
      onClose();
    }
  }, [getType, getValue, props, onClose]);

  const renderFooter = useCallback(() => {
    if (modalType === 'cancel') {
      return {
        footer: (
          <div>
            <Button type="primary" onClick={onSubmit}>
              取消执行
            </Button>
          </div>
        ),
      };
    } else {
      return {
        footer: (
          <div>
            <Button onClick={onClose}>取消</Button>
            <Button type="primary" onClick={onSubmit}>
              确定
            </Button>
          </div>
        ),
      };
    }
  }, [onClose, onSubmit, modalType]);

  const renderTitle = useCallback(() => {
    const targetLabel = orderClassType?.find(
      (item) => item?.value === value?.origin?.order?.orderClass,
    );
    if (modalType === 'execute') {
      return `${targetLabel?.label ?? ''}医嘱执行`;
    } else if (modalType === 'stop') {
      return `停止${targetLabel?.label ?? ''}医嘱执行`;
    } else if (modalType === 'invaild') {
      return `作废${targetLabel?.label ?? ''}医嘱执行`;
    } else if (modalType === 'cancel') {
      return `取消${targetLabel?.label ?? ''}医嘱执行`;
    }
  }, [value, modalType]);

  useImperativeHandle(ref, () => ({
    open: (v) => {
      console.log('当前弹窗的值：', v);
      setOpen(true);
      setValue(v?.data);
      setModalType(v?.modalType);
      setType(v?.type);
    },
  }));

  return (
    <Modal
      width={850}
      open={open}
      destroyOnClose={true}
      title={renderTitle()}
      onCancel={onClose}
      maskClosable={false}
      {...renderFooter()}
      className="aspirin-execute-order-execute-modal"
    >
      {renderContent()}
      {renderForm()}
    </Modal>
  );
};

export default forwardRef(ExecuteModal);
