import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_getChargeMemberList_04d0e1: {
    method: 'post',
    url: '/api/user-group/get-charge-member-list',
    parameterFomatter: (data?: {
      qto: ChargeGroupMemberInfoQtoUserGroupPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeMemberInfoDetailVoUserGroupEntranceWebVo[],
    ) => data,
  },
});
