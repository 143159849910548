import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSForm,
  Input,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import vsf, { definePage } from '@vs/vsf-boot';
import { isMinScreen } from '@/config';

import './index.less';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY, labelColWidth } from '@/utils';
import storage from '@/utils/storage';
import HeaderInfo from '@/pages/Index/components/head_info';
import {
  convertGetInpPreTransferPatientInfoVoByIdRes2InpPreTransferPatientInfo,
  convertGetListWaitInpPreTransferApplyVoByToWardIdRes2WaitInpPreTransferApplyList,
  convertInpPreTransferPatientInfo2TransferPatientOutReq,
} from './convert';

const labelWidth = 105;
let tempId = '';

const Index = (props) => {
  const { routes } = props;
  const {
    common: { currentApplication },
  } = props?.stores;
  const [selectId, setSelectId] = useState();
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState();
  const _labelColWidth = isMinScreen ? labelColWidth[11] : labelColWidth[6];

  // const currentApplication = storage.get('currentApplication');
  useEffect(() => {
    if (!selectId) return;
    getInpPreTransferPatientInfo();
  }, [selectId]);

  // 列表
  const getListWaitInpPreTransferList = useCallback(async (params) => {
    // console.log(props);
    const res =
      await vsf.services?.ClinicInpAdtController_getListWaitInpPreTransferApplyVoByToWardId_cfacc9?.(
        {
          fromWardId: currentApplication?.department?.id,
        },
      );
    const output =
      convertGetListWaitInpPreTransferApplyVoByToWardIdRes2WaitInpPreTransferApplyList(
        res ?? [],
      );
    if (props?.routes?.query?.displayId) {
      const _findItem = output?.find((item) => {
        return item?.displayId == props?.routes?.query?.displayId;
      });
      setSelectId(_findItem?.id);
    }
    // setSelectId(output?.[0]?.id);
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  // 详情
  const getInpPreTransferPatientInfo = async () => {
    // setLoading(true);
    // console.log(routes);
    const res =
      await vsf.services?.ClinicInpVisitController_getInpPreTransferPatientInfoVoById_101113?.(
        {
          id: selectId,
        },
      );
    // setLoading(false);
    if (res?.code == 200) {
      const _res =
        convertGetInpPreTransferPatientInfoVoByIdRes2InpPreTransferPatientInfo(
          res ?? {},
        );
      vsf.refs?.vSFormBedPatientInpDetail?.setFieldsValue(_res);
    } else {
      vsf.refs?.vSFormBedPatientInpDetail?.resetFields();
    }
  };
  // 清屏
  const handelClear = () => {
    // getInpPreTransferPatientInfo();
    setSelectId(null);
    vsf.refs?.vSFormBedPatientInpDetail?.resetFields();
  };
  // 保存
  const save = async () => {
    const params = vsf?.refs?.vSFormBedPatientInpDetail?.getFieldValue();
    // console.log(params);
    const output =
      convertInpPreTransferPatientInfo2TransferPatientOutReq(params); // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.ClinicInpAdtController_transferPatientOut_6a2980?.({
        // TODO: 请检查以下这部分传参是否符合需求
        ...output,
        extApiParam: {},
      }); // Tips: 需要返回提交的完整数据，和表单RO一致
    if (res?.code == 200) {
      // location.reload();
      vsf.refs.waitInpPreTransferApplyList.reload();
      handelClear();
    }
  };

  return (
    <div className="nurse_station_transfer">
      <div className="nurse_station_transfer_table">
        <div className="nurse_station_transfer_detail_content_form_title">
          <div className="nurse_station_transfer_detail_content_form_title_tag"></div>
          患者信息
        </div>
        <div className="nurse_station_transfer_table_content">
          <VSPromiseTable
            vsid="90797"
            id="waitInpPreTransferApplyList"
            className="
            aspirin-table-header-background-color
           aspirin-table-row-hover-background-color
           aspirin-table-nopadding
           aspirin-table-body-empty-transparent-background-color table_overflow_278
           "
            rowClassName={(v) => {
              return v?.id == selectId
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            pagination={false}
            onFetch={getListWaitInpPreTransferList}
            scroll={{ x: 100, y: 0 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectId(record?.id);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['bedLabel']}
              title="床号"
              valueType="text"
              fieldProps={{}}
              width={60}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
              }}
              width={isMinScreen ? 70 : 100}
              fieldProps={{}}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
              width={60}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="digit"
              fieldProps={{}}
              width={isMinScreen ? 80 : 100}
              ellipsis
            />
          </VSPromiseTable>
        </div>
      </div>
      <div className="nurse_station_transfer_detail">
        {/* 床号和ID搜索先隐藏 */}
        {/* <div className="nurse_station_transfer_detail_search">
          <div className="nurse_station_transfer_detail_search_item">
            <span className="nurse_station_transfer_detail_search_item_label">
              床号
            </span>
            <div className="nurse_station_transfer_detail_search_item_input">
              <Select width={240} showSearch={true} />
            </div>
          </div>
          <div className="nurse_station_transfer_detail_search_item">
            <span className="nurse_station_transfer_detail_search_item_label">
              患者ID
            </span>
            <div className="nurse_station_transfer_detail_search_item_input">
              <Input
                id="nurseStationTransferSearchId"
                onChange={(v) => {
                  tempId = v?.target?.value;
                  // setSelectId(v?.target?.value);
                }}
                // onKeyUp={(e) => {
                //   if (e.code === 'Enter') {
                //     if (tempId?.length <= 0) return;
                //     setSelectId(e?.target?.value);
                //   }
                // }}
                width={240}
              />
            </div>
          </div>
        </div> */}

        {
          // !loading ? (
          selectId ? (
            <div className="nurse_station_transfer_detail_content">
              <div className="nurse_station_transfer_detail_content_form hideScroll">
                <VSForm
                  readonly
                  labelAlign="left"
                  id="vSFormBedPatientInpDetail"
                  labelWrap
                >
                  <div className="nurse_station_transfer_detail_content_form_title">
                    <div className="nurse_station_transfer_detail_content_form_title_tag"></div>
                    基本信息
                  </div>
                  <div style={{ paddingLeft: 16 }}>
                    <VSFormLayout
                      key="3"
                      columnCount={3}
                      labelCol={_labelColWidth}
                    >
                      <VSFormItem
                        name={['bedLabel']}
                        label="床号"
                        valueType="text"
                        fieldProps={{}}
                        convertValue={(v) => {
                          if (!v) return;
                          return v + '床';
                        }}
                      />

                      <VSFormItem
                        name={['displayId']}
                        label="患者ID"
                        valueType="text"
                        rules={[
                          {
                            message: '用于显示的id长度不合法',
                            type: 'string',
                            min: 0,
                            max: 40,
                          },
                        ]}
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['name']}
                        label="姓名"
                        valueType="text"
                        rules={[
                          {
                            message: '姓名长度不合法',
                            type: 'string',
                            min: 0,
                            max: 60,
                          },
                        ]}
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['gender']}
                        label="性别"
                        valueType="select"
                        fieldProps={{}}
                        dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                      />

                      <VSFormItem
                        name={['birthday']}
                        label="年龄"
                        valueType="text"
                        fieldProps={{}}
                        convertValue={(v) => {
                          if (!v) return '';
                          return dayjs().diff(dayjs(v), 'years') + '岁';
                        }}
                      />
                      <VSFormItem
                        name={['identityCode']}
                        label="人员类别"
                        valueType="select"
                        fieldProps={{}}
                        dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
                      />
                      <VSFormItem
                        name={['chargeType']}
                        label="费别"
                        valueType="select"
                        fieldProps={{}}
                        dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                      />

                      <VSFormItem
                        name={['prePaymentAmount']}
                        label="预交金(元)"
                        valueType="digit"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['contactName']}
                        label="联系人"
                        valueType="text"
                        rules={[
                          {
                            message: '联系人姓名长度不合法',
                            type: 'string',
                            min: 0,
                            max: 60,
                          },
                        ]}
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['phoneNumber']}
                        label="联系人电话"
                        valueType="text"
                        rules={[
                          {
                            message: '联系人的联系电话长度不合法',
                            type: 'string',
                            min: 0,
                            max: 40,
                          },
                        ]}
                        fieldProps={{}}
                      />
                    </VSFormLayout>
                  </div>
                  <div className="nurse_station_transfer_detail_content_form_title">
                    <div className="nurse_station_transfer_detail_content_form_title_tag"></div>
                    在科信息
                  </div>
                  <div style={{ paddingLeft: 16 }}>
                    <VSFormLayout
                      key="3"
                      columnCount={3}
                      labelCol={_labelColWidth}
                    >
                      <VSFormItem
                        name={['departmentName']}
                        label="所在科室"
                        valueType="select"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['admissionDateTime']}
                        label="入科时间"
                        valueType="text"
                        fieldProps={
                          {
                            // showTime: true,
                          }
                        }
                        // dataFormat="YYYY-MM-DD HH:mm"
                        convertValue={(v) => {
                          if (!v) return;
                          return dayjs(v).format('YYYY-MM-DD HH:mm');
                        }}
                      />

                      <VSFormItem
                        name={['patientCondition']}
                        label="病情"
                        valueType="select"
                        fieldProps={{}}
                        dataSource={
                          vsf.stores.dbenums?.enums?.PATIENT_STATUS_DICT
                        }
                      />

                      <VSFormItem
                        name={['nursingClass']}
                        label="护理等级"
                        valueType="select"
                        fieldProps={{}}
                        dataSource={
                          vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT
                        }
                      />

                      <VSFormItem
                        name={['attendingDoctor']}
                        label="主治医师"
                        valueType="text"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['nurseInCharge']}
                        label="责任护士"
                        valueType="text"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['operationDateTime']}
                        label="手术时间"
                        valueType="date"
                        fieldProps={{}}
                        dataFormat="YYYY-MM-DD HH:mm"
                        convertValue={(v) => {
                          if (!v) return;
                          return dayjs(v).format('YYYY-MM-DD HH:mm');
                        }}
                      />

                      <VSFormItem
                        name={['diagnosisDescription']}
                        label="诊断"
                        valueType="textarea"
                        rules={[
                          {
                            message: '数据格式错误',
                            type: 'string',
                            min: 0,
                            max: 200,
                          },
                        ]}
                        fieldProps={{}}
                      />
                    </VSFormLayout>
                  </div>
                  <div className="nurse_station_transfer_detail_content_form_title">
                    <div className="nurse_station_transfer_detail_content_form_title_tag"></div>
                    转科信息
                  </div>
                  <div style={{ paddingLeft: 16 }}>
                    <VSFormLayout
                      key="3"
                      columnCount={3}
                      labelCol={_labelColWidth}
                    >
                      <VSFormItem
                        name={['toDepartment']}
                        label="转向科室"
                        valueType="select"
                        fieldProps={{ width: 240 }}
                        required
                        convertValue={(v) => {
                          return v?.departmentName;
                        }}
                      />

                      <VSFormItem
                        name={['toWard']}
                        label="转向病区"
                        valueType="select"
                        fieldProps={{}}
                        convertValue={(v) => {
                          return v?.departmentName;
                        }}
                      />

                      <VSFormItem
                        name={['createdAt']}
                        label="转科申请时间"
                        valueType="date"
                        fieldProps={{
                          // width: 300,
                          showTime: true,
                        }}
                        // readonly={false}
                      />
                    </VSFormLayout>
                    <VSFormLayout
                      key="4"
                      columnCount={1}
                      labelCol={_labelColWidth}
                    >
                      <VSFormItem
                        name={['transferReason']}
                        label="转科原因"
                        valueType="textarea"
                        fieldProps={{}}
                        readonly={false}
                      />
                    </VSFormLayout>
                    <VSFormLayout
                      key="5"
                      columnCount={3}
                      labelCol={_labelColWidth}
                    >
                      <VSFormItem
                        name={['notLabCount']}
                        label="随转临检数"
                        valueType="digit"
                        fieldProps={{}}
                      />
                      <VSFormItem
                        name={['notSubmitOrderCount']}
                        label="未发送医嘱"
                        valueType="digit"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['notExecutedExamCount']}
                        label="未执行完成检查"
                        valueType="digit"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['notSendDrugPrescription']}
                        label="未发药处方"
                        valueType="digit"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['notReturnDrugPrescription']}
                        label="未退药处方"
                        valueType="digit"
                        fieldProps={{}}
                      />

                      <VSFormItem
                        name={['waitSendDrugPrescription']}
                        label="待发药处方"
                        valueType="digit"
                        fieldProps={{}}
                      />
                      <VSFormItem
                        name={['notStopLongTimeOrder']}
                        label="未停长期医嘱"
                        valueType="digit"
                        fieldProps={{}}
                      />
                    </VSFormLayout>
                  </div>
                </VSForm>
              </div>
              <div className="nurse_station_transfer_detail_content_bottom">
                <Button
                  className="nurse_station_transfer_detail_content_bottom_delete"
                  onClick={handelClear}
                  style={{ color: '#3276E8', border: '1px solid #3276E8' }}
                >
                  清屏
                </Button>
                <Button
                  type="primary"
                  className="nurse_station_transfer_detail_content_bottom_save"
                  onClick={save}
                  // disabled={inpPreTransferApplyId || !inpVisitIds}
                  // disabled={inpPreTransferApplyId}
                >
                  确定
                </Button>
              </div>
            </div>
          ) : (
            <div className="nurse_station_transfer_detail_content">
              <Empty />
            </div>
          )
          // ) : (
          //   <div className="nurse_station_transfer_detail_content">
          //     <Loading />
          //   </div>
          // )
        }
      </div>
    </div>
  );
};

export default definePage(Index);
