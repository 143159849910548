import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Tag,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { mockDoctorId } from '@/mock';
import { cdssShow } from '@/mock';
import Icon from '@/module/cpoe/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';

import EditAllergy from './edit/edit_allergy';

const Index = (props) => {
  // outAllergy门诊过敏史 bedCardAllergy 床卡过敏史
  const {
    scrollYValue,
    type = 'outPrevious',
    currentBedPatient,
    nurseInVisitInfo,
    editModalWidth = 680,
    editModalHeight = 500,
  } = props;

  const {
    user: {
      getOutVisitTreatInfo,
      initKey,
      currentPatient,
      staffInfo,
      outVisitInfo,
      setInitKey,
    },
    common,
    cdss: { cdssAllergy },
  } = props?.stores ?? {};

  const _patientId =
    type == 'bedCardAllergy' ? currentBedPatient : currentPatient?.patientId;

  // 过敏史
  const getPatientAllergyList = async (params) => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getPatientAllergyByPatientId_576705?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  // 过敏类型
  const renderAllergyType = (dom, value) => {
    // const allergyTypeEnum = vsf.stores.dbenums.enums.ALLERGY_TYPE_DICT;
    console.log(value?.notAllergyIndicator, 'value');
    if (value?.notAllergyIndicator) {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_cure',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '01') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_medicine',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '02') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_food',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '03') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_environment',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '04') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_max',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '09') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_other',
          )}
        >
          {dom}
        </div>
      );
    }
  };
  // 不在过敏\疾病
  const renderAllergyText = (dom, value, detail?) => {
    const res = vsf.stores.dbenums.enums.ALLERGY_SYMPTOMS_DICT?.find(
      (item) => item?.value == detail?.allergySymptom,
    );
    return (
      <div style={{ display: 'flex', color: value ? '#85898D' : '#343B42' }}>
        {dom}
        {detail && (res ? '/' + res?.label : '')}
      </div>
    );
  };

  return (
    <VSPromiseTable
      className="aspirin-table-empty-background-color previous_history_list_tabel"
      id="previousHistory"
      scroll={{
        x: 600,
        // y: scrollYValue ?? 250,
        y: getScrollYRem(53),
      }}
      cardProps={{
        bodyStyle: {
          padding: 0,
        },
      }}
      onFetch={getPatientAllergyList}
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_updatePatientAllergy_260cc6?.(
            { btoParam: { ...(params ?? {}) }, extApiParam: {} },
          );
        if (res?.code == 200) {
          message.success('过敏史更新成功');
          if (cdssShow) {
            cdssAllergy(nurseInVisitInfo ?? outVisitInfo);
          }
        }
        vsf?.refs?.previousHistory?.reload();
        getOutVisitTreatInfo(outVisitInfo?.outpVisit?.id);
        return res?.data;
      }}
      updateLinkPage={{
        linkPage: (value) => {
          return (
            <EditAllergy
              title="update"
              recordDoctor={
                value?.recordDoctor?.id == staffInfo?.doctorDto?.staffId
              }
            />
          );
        },
        modalProps: {
          title: `编辑过敏史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },

        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.allergyForm?.validateFieldsReturnFormatValue();
            return ret;
          } catch (e) {
            return false;
          }
        },
      }}
      onAdd={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_createPatientAllergy_5b54a1?.(
            {
              btoParam: {
                ...(params ?? {}),
                patientId: _patientId,
                allergyNumber: 1,
                allergyLevel: params?.allergyLevel
                  ? params?.allergyLevel
                  : '01',
                // 床卡需要住院流水号，服务端还没做
                outpVisitId: outVisitInfo?.outpVisit?.id,
              },
              extApiParam: {},
            },
          );
        if (res?.code == 200) {
          message.success('过敏史新增成功');
          if (cdssShow) {
            cdssAllergy(nurseInVisitInfo ?? outVisitInfo);
          }
        }
        getOutVisitTreatInfo(outVisitInfo?.outpVisit?.id);
        vsf?.refs?.previousHistory?.reload();
        return res?.data;
      }}
      addLinkPage={{
        linkPage: () => <EditAllergy recordDoctor={true} />,
        modalProps: {
          title: `新增过敏史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.allergyForm?.validateFieldsReturnFormatValue();
            return ret;
          } catch (e) {
            return false;
          }
        },
      }}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-Frame-21" size={18} />,
        deleteText: <Icon type="icon-shanchuwudi" size={18} />,
        onCanDelete: (row) => {
          return row?.recordDoctor.id == staffInfo?.doctorDto?.staffId;
        },

        columnProps: {
          fixed: 'right',
          width: labelNumberWidth(5),
          // hideInTable: true,
        },
      }}
      onRemove={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_deletePatientAllergy_625a54?.(
            { btoParam: params, extApiParam: {} },
          );
        if (res?.code == 200) {
          message.success('敏史删除成功');
        }
        return res?.code === 200;
      }}
      pagination={false}
      form={{ labelAlign: 'left' }}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['allergyType']}
        title="类型"
        editable={false}
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.ALLERGY_TYPE_DICT}
        fieldProps={{}}
        // width={102}
        width={labelNumberWidth(8)}
        render={(dom, value) => {
          return renderAllergyType(dom, value);
        }}
      />
      <VSTableColumn
        dataIndex={['allergyName']}
        title="过敏原"
        ellipsis
        valueType="select"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 100 },
          ],
        }}
        dataSource={vsf.stores.dbenums.enums.DRUG_CLASS_DICT}
        fieldProps={{}}
        render={(dom, value) => {
          return renderAllergyText(dom, value?.notAllergyIndicator);
        }}
      />
      <VSTableColumn
        dataIndex={['allergyLevel']}
        title="过敏程度/过敏症状"
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.DRUG_ALLERGY_SEVERITY_DICT}
        fieldProps={{}}
        width={100}
        ellipsis
        render={(dom, value) => {
          // allergySymptom
          return renderAllergyText(dom, value?.notAllergyIndicator, value);
        }}
      />
      <VSTableColumn
        dataIndex={['startDate']}
        title="过敏时间"
        width={90}
        valueType="date"
        ellipsis
        fieldProps={{ format: 'YYYY-MM-DD' }}
        render={(dom, value) => {
          return renderAllergyText(dom, value?.notAllergyIndicator);
        }}
      />
      <VSTableColumn
        dataIndex={['recordDoctor', 'staffName']}
        title="记录医生"
        valueType="text"
        fieldProps={{}}
        render={(dom, value) => {
          return renderAllergyText(dom, value?.notAllergyIndicator);
        }}
      />
      <VSTableColumn
        dataIndex={['notAllergyIndicator']}
        title="信息来源"
        valueType="switch"
        fieldProps={{}}
        render={(_, value) => {
          if (value?.inHospitalIndicator === null) return '';
          return value?.inHospitalIndicator ? (
            <div
              style={{
                color: value?.notAllergyIndicator ? '#85898D' : '',
              }}
            >
              本院发生
            </div>
          ) : (
            <div
              style={{
                color: value?.notAllergyIndicator ? '#85898D' : '',
              }}
            >
              患者自述
            </div>
          );
        }}
      />
      <VSTableColumn
        dataIndex={['notAllergyIndicator']}
        title="当前状态"
        valueType="switch"
        fieldProps={{}}
        render={(_, value) => {
          if (value?.notAllergyIndicator === null) return '';
          return value?.notAllergyIndicator ? (
            <div
              style={{
                color: value?.notAllergyIndicator ? '#85898D' : '',
              }}
            >
              不再过敏
            </div>
          ) : (
            <div
              style={{
                color: value?.notAllergyIndicator ? '#85898D' : '',
              }}
            >
              继续过敏
            </div>
          );
        }}
      />
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title={
          <div className="previous_history_tabletoolbar">
            <div className="previous_history_tabletoolbar_div"></div>
            过敏史
          </div>
        }
      >
        <VSTableAddition children="新增" size="small" type="primary" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};

export default definePage(Index);
