import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getDrugStorageImportDetailListForFloat_9a6ac0:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-import-detail-list-for-float',
      parameterFomatter: (data?: { drugImportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugImportDetailListForFloatVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugStorageImportExportController_getDrugStorageImportDetailByDrugImportMasterId_0c8007:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-import-detail-by-drug-import-master-id',
      parameterFomatter: (data?: { drugImportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugImportDetailListVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
});
