import './index.less';

import { compose, withField, withPreview, withRef } from '@vs/vsf-kit';
import { useControllableValue, useMount } from 'ahooks';
import {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useImperativeHandle,
} from 'react';
import React from 'react';

import Icon from '@/module/Icon';
import Determine from '@/pages/Index/components/determine';

import { useFrequencyEffect } from '../func/hooks';
type ListItemValue = {
  key: '' | unknown;
};

type ListItemProps = {
  value: ListItemValue[];
  onChange: (value: ListItemValue[]) => void;
  defaultValue?: ListItemValue[];
  children: React.ReactNode;
  mode?: 'splice' | 'push';
  /**
   * @editStyle editStyle 样式
   */
  editStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
};

type ListItemOnChange = (
  func: (value: ListItemValue[]) => ListItemValue[],
) => ListItemValue[];

const ListItemContext = createContext<ListItemOnChange>(() => []);
const ListItem = forwardRef((props: ListItemProps, ref) => {
  const {
    value = [
      {
        key: 'create' + Math?.random(),
      },
    ],
    defaultValue,
    onChange,
    children,
    mode = 'push',
    editStyle = {},
    rowStyle = {},
  } = props;
  const [, setValue] = useControllableValue({
    value,
    defaultValue,
    onChange,
  });

  const onAdd = (index) => {
    if (mode === 'push') {
      setValue?.([
        ...value,
        {
          key: 'create' + Math?.random(),
        },
      ]);
    }
    if (mode === 'splice') {
      setValue?.(
        (value as any)?.toSpliced((index ?? 0) + 1, 0, {
          key: 'create' + Math?.random(),
        }),
      );
    }
  };

  useFrequencyEffect(
    () => {
      setValue(defaultValue as any);
    },
    1,
    [defaultValue],
  );

  const onRemove = (index: number) => {
    setValue?.((value as any)?.toSpliced(index, 1));
  };

  const onValue = (list: any, index: number) => {
    setValue?.(
      (value as any)?.toSpliced?.(index, 1, {
        ...value?.[index],
        ...list,
      }),
    );
  };

  useImperativeHandle(ref, () => ({
    onAdd,
    onRemove,
  }));

  const ONCHANGE = (func: (value: ListItemValue[]) => ListItemValue[]) => {
    if (typeof func === 'function') {
      setValue?.(func?.(value));
    }
    return [];
  };

  const state = value?.length
    ? value
    : [
        {
          value: '',
        },
      ];
  return (
    <ListItemContext.Provider value={ONCHANGE}>
      <div className="list-item-main-box">
        {state?.map((item: any, index: number) => {
          return (
            <div
              className="list-item-option-box"
              key={item?.id}
              style={rowStyle}
            >
              <div className="list-item-option-form-box">
                {cloneElement(
                  children as React.DetailedReactHTMLElement<any, HTMLElement>,
                  {
                    onChange: (value) => {
                      onValue?.(value, index);
                    },
                    value: item,
                    index,
                  },
                )}
              </div>
              <div className="list-item-option-edit-box" style={editStyle}>
                <Determine
                  condition={index + 1 !== state?.length || index !== 0}
                  fallback={
                    <Icon
                      type="icon-a-shanchujinyong"
                      size={24}
                      style={{
                        cursor: 'not-allowed',
                      }}
                    />
                  }
                >
                  <Icon
                    onClick={() => onRemove(index)}
                    type="icon-youdishanchu1"
                    size={24}
                  />
                </Determine>
                <Determine
                  condition={
                    mode === 'push' ? index + 1 === state?.length : true
                  }
                  fallback={
                    <span
                      style={{
                        height: 24,
                        width: 24,
                      }}
                    />
                  }
                >
                  <Icon
                    type="icon-tianjia"
                    onClick={() => onAdd(index)}
                    size={24}
                  />
                </Determine>
              </div>
            </div>
          );
        })}
      </div>
    </ListItemContext.Provider>
  );
});

ListItem.displayName = 'ListItem';
const useListItemOnChange = () => {
  const onChange = useContext(ListItemContext);
  return onChange;
};
(ListItem as any).useListItemOnChange = useListItemOnChange;
export default compose(
  withRef(),
  withField<any>({
    name: 'listItem',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      return <>{value}</>;
    },
  }),
)(ListItem) as typeof ListItem;
