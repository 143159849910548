type DetermineProps = {
  condition: boolean;
  children: React.ReactNode;
  fallback?: JSX.Element;
};

const Determine = (props: DetermineProps): JSX.Element => {
  const { condition = true, children, fallback } = props;
  if (fallback === void 0) {
    return (condition && children) as JSX.Element;
  }
  return (condition ? children : fallback) as JSX.Element;
};

export default Determine;
