import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_getIdentificaitonByIdentificationNumberAndIdentificationClass_c14a4e:
    {
      method: 'post',
      url: '/api/patient-main/get-by-identification-number-and-identification-class',
      parameterFomatter: (data?: {
        identificationNumberAndClass: PatientIdentificationIdentificationNumberIdentificationClassEoPatientMainPersistEo;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: PatientIdentificationVoPatientMainEntranceWebVo,
      ) => data,
    },
});
