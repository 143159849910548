import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const cpoe = defineStore({
  signSelection: storage.get('signSelection') || [],
  // 设置号别选择列表
  setSignSelection: (v) => {
    cpoe.signSelection = v;
    storage.set('signSelection', v);
  },

  departmentList: storage.get('departmentList') || [],
  // 获取就诊患者列表
  getDepartmentList: async () => {
    const res =
      await vsf?.services?.RegisterPatientController_allRegisterPatient_315489?.(
        {
          qto: {
            registerTypeIs: '生殖普通门诊',
            //  || user.medicalType?.[0]?.label || '生殖普通门诊',
          },
        },
      );
    cpoe.setDepartmentList(res?.data);
    return res?.data;
  },

  // 设置就诊患者列表
  setDepartmentList: (v) => {
    cpoe.departmentList = v;
    storage.set('departmentList', v);
  },
});

export default cpoe;
