import { createServices } from '@vs/vsf-boot';
export default createServices({
  StaffDetailDtoController_getDepartmentStaffList_8501c0: {
    method: 'post',
    url: '/api/user-staff/get-department-staff-list',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDepartmentVoUserStaffEntranceWebVo[],
    ) => data,
  },
});
