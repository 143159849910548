/* eslint-disable react-hooks/exhaustive-deps */
import vsf from '@vs/vsf-boot';
import { Button, Modal, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { mockPatientId } from '@/mock';

type Data = {
  drugProductName?: any;
  packageSpecification?: any;
  drugOrder?: any;
  performDepartment?: any;
  drugEo?: any;
  storageName?: any;
  storageId?: any;
  storageDepartmentId?: any;
  skinTestClass?: 'RAW_LIQUID' | 'NOT_RAW_LIQUID' | 'ADVISE';
  skinTestType?: 'SKIN_TEST_SOLVENT' | 'FORCE' | 'ADVISE';
  skinTestFlag?: 2 | 0;
  antibioticIndicator?: boolean;
  drugName?: string;
  toxicCode?: '1' | '6' | '2' | '7' | '5';
  drugItemId?: string | number;
  skinTestContinueUseIndicator?: boolean;
  detailInfo?: any;
};

export const toxicCode = {
  Normal: '1',
  Poison: '6',
  PsychotropicFirst: '2',
  PsychotropicSecond: '7',
  Narcotic: '5',
};

const withResolvers = () => {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve,
    reject,
  };
};

// 防抖
function throttle(
  func: any,
  delay: number,
): (...args: Parameters<any>) => void {
  let timerId: NodeJS.Timeout;
  return function (this: ThisParameterType<any>, ...args: Parameters<any>) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function useWithResolvers() {
  const [data, setData] = useState<Data>({});
  const [open, setOpen] = useState(false);
  const promise = useRef<any>((Promise as any).withResolvers());

  const onOpen = (value) => {
    if (value) {
      setData(value);
      setOpen?.(true);
    }

    return promise.current?.promise;
  };

  const onCancel = () => {
    setOpen(false);
    promise?.current?.resolve([]);
    promise.current = (Promise as any).withResolvers();
  };
  return {
    data,
    resolve: (data) => {
      const { detailInfo } = data || {};

      if (
        // 前端参数 -1.初步弹框不进行皮试相关弹框; 0.二次弹框, 如果满足皮试相关可以弹框; 1.不进行皮试弹框; 2.续用弹框
        detailInfo?.skinTestFlag === 0 &&
        (detailInfo?.skinTestType === 'FORCE' || // 强制皮试
          detailInfo?.skinTestType === 'ADVISE') // 建议皮试
      ) {
        setData(data);
      } else if (detailInfo?.skinTestFlag === 2) {
        setData(data);
      } else {
        let typeFlag: string | number | undefined;
        switch (data?.detailInfo?.skinTestFlag) {
          case undefined:
            // 续用
            typeFlag = 'CONTINUE_USE';
            break;
          case 0:
            typeFlag = '';
            break;
          case 1:
            typeFlag = '';
            break;
          default:
            break;
        }

        const drugData = Array?.isArray(data)
          ? data
          : [
              {
                ...data?.detailInfo,
                ...data,
                ...data?.detailInfo?.drugEo,
                orderText: data?.drugProductName,
                packageSpecification: data?.packageSpecification,
                relatedType: 'SKIN_TEST_VS_DRUG',
                skinTestType: typeFlag,
                performDepartment: {
                  id: data?.storageDepartmentId,
                  departmentName: data?.storageName,
                },
                drugStorageId: data?.storageId,
                skinTestContinueUseIndicator:
                  data?.skinTestContinueUseIndicator,
                skinTestContinueReason: data?.skinTestContinueReason,
              },
            ];

        promise.current?.resolve(drugData);
        promise.current = (Promise as any).withResolvers();
        setOpen(false);
      }
    },
    open,
    onOpen,
    onCancel,
  };
}

const SkinTest = forwardRef((props, ref) => {
  const [detailInfo, setDetailInfo] = useState<any>();
  const [drugEo, setDrugEo] = useState<any>({});

  const [isSkinTest, setIsSkinTest] = useState<boolean>(false);

  const [hasSkinTestRecord, setHasSkinTestRecord] = useState<boolean>();

  const { data, open, resolve, onOpen, onCancel } = useWithResolvers();

  const getSkinTestRecord = useCallback(async () => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_hasSkinTests_f977be?.(
        {
          qto: {
            from: 0,
            size: 20,
            patientId: mockPatientId,
            drugItemId: data?.drugItemId,
          },
        },
      );
    if (res?.code === 200) {
      setHasSkinTestRecord(res?.data);
    }
    return res?.data;
  }, [data]);

  const getSkinTestResult = useCallback(async () => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_isSkinTestsPositive_e42ea3?.(
        {
          qto: {
            from: 0,
            size: 20,
            patientId: mockPatientId,
            drugItemId: data?.drugItemId,
          },
        },
      );
    return res?.data;
  }, [data]);

  useEffect(() => {
    setDetailInfo(data?.detailInfo);
    setDrugEo(data?.detailInfo?.drugEo);
    setIsSkinTest(
      detailInfo?.skinTestFlag === 0 &&
        (detailInfo?.skinTestType === 'FORCE' || // 强制皮试
          detailInfo?.skinTestType === 'ADVISE'),
    );
  }, [data, detailInfo]);

  useEffect(() => {
    if (isSkinTest) {
      getSkinTestRecord();
    }
  }, [isSkinTest, getSkinTestRecord]);

  useEffect(() => {
    if (hasSkinTestRecord) {
      getSkinTestResult();
    }
  }, [hasSkinTestRecord, getSkinTestResult]);

  const onOk = useCallback(
    async (skinTestContinueUseIndicator: boolean) => {
      if (
        drugEo?.toxicCode === toxicCode?.Narcotic ||
        detailInfo?.antibioticIndicator
      ) {
        const values =
          await vsf?.refs?.drugValidateForm?.validateFieldsReturnFormatValue();
        const drugContinueFormValue =
          vsf?.refs?.drugContinueForm?.validateFieldsReturnFormatValue();
        const drugRecord = {
          ...data,
          ...values,
          ...drugContinueFormValue,
          skinTestContinueUseIndicator,
          detailInfo: {
            ...detailInfo,
            ...(detailInfo?.antibioticIndicator ||
            detailInfo?.skinTestFlag === -1
              ? {
                  skinTestFlag: 0,
                }
              : {}),
          },
        };
        resolve?.(drugRecord);
      }
      if (isSkinTest) {
        resolve?.({
          ...data,
          skinTestContinueUseIndicator,
          detailInfo: {
            ...detailInfo,
            skinTestFlag: 1,
          },
        });
      }
    },
    [drugEo?.toxicCode, detailInfo, isSkinTest, resolve, data],
  );

  // 弹框标题
  const renderTitle = useCallback(() => {
    if (drugEo?.toxicCode === toxicCode?.Narcotic) {
      return '麻醉药品用药目的';
    }
    if (
      detailInfo?.skinTestType === 'SKIN_TEST_SOLVENT' ||
      detailInfo?.skinTestFlag === 2
    ) {
      return '续用理由';
    }
    if (detailInfo?.antibioticIndicator) {
      return '抗生素使用登记表';
    }
    if (isSkinTest) {
      return '提示';
    }
  }, [drugEo, detailInfo, isSkinTest]);

  // 弹框内容
  const renderContent = useCallback(() => {
    if (detailInfo?.skinTestFlag === 2) {
      return (
        <VSForm id="drugContinueForm">
          <VSFormLayout columnCount={1} labelWidth={120}>
            <VSFormItem
              name={['skinTestContinueReason']}
              label="续用理由"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.ST_CONTINUT_REASON_DICT}
              fieldProps={{}}
              rules={[{ required: true }]}
            />
          </VSFormLayout>
        </VSForm>
      );
    } else if (isSkinTest) {
      return (
        <div>
          {drugEo?.drugName}为皮试药品,{' '}
          {detailInfo?.skinTestType == 'ADVISE'
            ? '建议皮试'
            : detailInfo?.skinTestType == 'FORCE'
            ? '强制皮试'
            : ''}
          , 请选择是否需要开立皮试医嘱
        </div>
      );
    } else {
      if (drugEo?.toxicCode === toxicCode?.Narcotic) {
        return (
          <VSForm id="drugValidateForm">
            <VSFormLayout columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['anesthesiaUsePurpose']}
                label="用药目的"
                valueType="select"
                dataSource={
                  vsf.stores.dbenums.enums.ANESTHETIC_USE_PURPOSE?.filter(
                    (i) => !i?.deprecated,
                  ) ?? []
                }
                fieldProps={{}}
                rules={[{ required: true }]}
              />
              <VSFormItem
                name={['useExplosivePainIndicator']}
                label="是否用于爆发药"
                valueType="radio"
                initialValue={false}
                dataSource={[
                  {
                    label: '是',
                    value: true,
                  },
                  {
                    label: '否',
                    value: false,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        );
      }
      if (detailInfo?.skinTestType === 'SKIN_TEST_SOLVENT') {
        return (
          <VSForm id="drugValidateForm">
            <VSFormLayout columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['allergySymptom']}
                label=""
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.ALLERGY_SYMPTOMS_DICT}
                fieldProps={{}}
                rules={[{ required: true }]}
              />
            </VSFormLayout>
          </VSForm>
        );
      }
      if (detailInfo?.antibioticIndicator) {
        return (
          <VSForm id="drugValidateForm">
            <VSFormLayout columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['antibioticRecord', 'useReason']}
                label="请选择使用理由"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.ANTI_SINFECTION_DICT}
                fieldProps={{
                  showSearch: true,
                }}
              />
            </VSFormLayout>
          </VSForm>
        );
      }
    }
  }, [drugEo, detailInfo, isSkinTest]);

  // 原液皮试/非原液皮试
  const getSkinTestOrder = useCallback(
    async (testTypeEnum: string) => {
      const res =
        await vsf.services?.ClinicRecordOrderDrugController_getSkinTestOrders_eb2e30?.(
          {
            testTypeEnum,
            drugItemId: data?.drugItemId,
          },
        );
      if (res?.code === 200) {
        let bindNumber: number;
        const target = [
          {
            orderDrugInfo: {
              ...data,
              ...data?.detailInfo?.drugEo,
              orderText: data?.drugProductName,
              packageSpecification: data?.packageSpecification,
              orderClass: 'DRUG',
              performDepartment: {
                id: data?.storageDepartmentId,
                departmentName: data?.storageName,
              },
              drugStorageId: data?.storageId,
              relatedType: testTypeEnum,
              skinTestType: testTypeEnum,
            },
          },
          ...(res?.data ?? []),
        ]?.map((item, index) => {
          const { orderDrugInfo = {}, orderDisposalInfo } = item;
          const disposalInfo = orderDisposalInfo?.itemId
            ? {
                ...orderDisposalInfo,
                orderText: orderDisposalInfo?.itemName,
              }
            : {};
          const drugInfo = orderDrugInfo?.drugProductName
            ? {
                ...orderDrugInfo,
                orderText: orderDrugInfo?.drugProductName,
                packageSpecification: orderDrugInfo?.packageSpecification,
                unitPrice: orderDrugInfo?.retailPrice, // 存疑
                dosage: {
                  value: orderDrugInfo?.dosage,
                  unit: orderDrugInfo?.dosageUnit,
                },
                usage: {
                  value: orderDrugInfo?.usage ?? 1,
                  unit: orderDrugInfo?.usageUnit,
                },
                totalAmount: {
                  value: orderDrugInfo?.totalAmount,
                  unit: orderDrugInfo?.packageUnit,
                },
              }
            : {};

          if (orderDrugInfo?.drugPriceItemId) {
            bindNumber = orderDrugInfo?.drugPriceItemId;
          }

          return {
            ...item,
            ...orderDrugInfo,
            ...disposalInfo,
            ...drugInfo,
            orderDrugDto: {
              ...drugInfo,
              groupNumber: 1,
              groupSubNumber: index + 1,
              bindNumber: orderDrugInfo?.drugPriceItemId ?? bindNumber,
            },
            ...(item?.orderClass === 'DRUG'
              ? {
                  performDepartment: {
                    id: data?.storageDepartmentId,
                    departmentName: data?.storageName,
                  },
                  drugStorageId: data?.storageId,
                }
              : {}),
          };
        });
        resolve(target);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  // 续用
  const onContinueReason = useCallback(
    async (v) => {
      if (v) {
        const startData = {
          ...data,
          detailInfo: {
            ...detailInfo,
            skinTestFlag: 2,
          },
          skinTestContinueUseIndicator: true,
        };
        resolve?.(startData);
      } else {
        const values =
          await vsf?.refs?.drugContinueForm?.validateFieldsReturnFormatValue();

        const { skinTestFlag, ...rest } = detailInfo;
        // console.log(
        //   values,
        //   {
        //     ...data,
        //     ...values,
        //     detailInfo: rest,
        //     skinTestContinueUseIndicator: true,
        //   },
        //   '======',
        // );
        resolve?.({
          ...data,
          ...values,
          detailInfo: rest,
          skinTestContinueUseIndicator: true,
        });
      }
    },
    [data, detailInfo, resolve],
  );

  // 非皮试
  const NonCuticularTest = throttle(() => {
    getSkinTestOrder?.('NOT_ORIGINAL_SKIN_TEST');
  }, 2000);

  // 皮试
  const skinTest = throttle(() => {
    getSkinTestOrder?.('ORIGINAL_SKIN_TEST');
  }, 2000);

  // 底部按钮
  const renderFooter = useCallback(() => {
    if (isSkinTest) {
      return {
        footer: (
          <div>
            <Button type="primary" onClick={() => onContinueReason(true)}>
              续用
            </Button>
            {detailInfo?.skinTestClass !== 'RAW_LIQUID' && (
              <Button
                type="primary"
                // throttle(, 1000)
                onClick={NonCuticularTest}
              >
                非原液皮试
              </Button>
            )}
            {detailInfo?.skinTestClass !== 'NOT_RAW_LIQUID' && (
              <Button type="primary" onClick={skinTest}>
                原液皮试
              </Button>
            )}
            {detailInfo?.skinTestType === 'ADVISE' && (
              <Button type="primary" onClick={() => onOk(false)}>
                不皮试
              </Button>
            )}
          </div>
        ),
      };
    } else if (detailInfo?.skinTestFlag === 2) {
      return {
        footer: (
          <Button type="primary" onClick={() => onContinueReason(false)}>
            确定
          </Button>
        ),
        // }else if(){
      };
    }
  }, [isSkinTest, detailInfo, getSkinTestOrder, onOk, onContinueReason]);

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Modal
      open={open}
      destroyOnClose={true}
      title={renderTitle()}
      onOk={() => onOk(false)}
      onCancel={onCancel}
      maskClosable={false}
      {...renderFooter()}
    >
      {renderContent()}
    </Modal>
  );
});

SkinTest.displayName = 'SkinTest';

export default SkinTest;
