import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  Divider,
  Tag,
  message,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import SpecimenEdit from './edit';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import SearchContainer from '@/module/Search';
import { getScrollY, getScrollYRem } from '@/utils';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { useFetch } from '@/pages/Index/components/func/hooks';
const SpecimenTable = (props) => {
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.SpecimenController_getAllBySpecimenWithPatternNewQtoBySpecimenWithPatternNewQto_8f8276?.(
        {
          qto: {
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const onAdd = useCallback(async (params) => {
    const res =
      await vsf?.services?.SpecimenBOController_createSpecimen_f83102?.({
        btoParam: { ...params },
        extApiParam: params?.extApiParam,
      });
    return res?.data;
  }, []);
  const onUpdate = useFetch({
    fetch: async (params) => {
      const res =
        await vsf?.services?.SpecimenBOController_updateSpecimen_b99bfd?.({
          btoParam: {
            ...params,
            patternId:
              params.value ?? params.patternId.value ?? params.patternId,
          },
          extApiParam: params?.extApiParam,
        });
      return res;
    },
    message: '修改标本',
    success: () => {
      vsf?.refs?.SpecimenTable?.reload();
    },
  });
  const onRemove = useCallback(async (params) => {
    try {
      const res =
        await vsf?.services?.SpecimenBOController_deleteSpecimen_e2a2f4?.({
          btoParam: params,
          extApiParam: params?.extApiParam,
        });
      if (res?.code === 200) {
        vsf.refs.SpecimenTable.reload();
        message.success('删除成功');
        return;
      }
    } catch {}
  }, []);

  return (
    <VSPromiseTable
      id="SpecimenTable"
      editable={{
        editType: 'single',
        extraOperations: [
          {
            children: <Icon type="icon-shanchu1" size={24} />,
            onClick: (event, data) => {
              Tip({
                title: '操作窗口',
                content: `确定删除{【${data.specimenName}】}？删除后不可恢复!`,
                onOk: () => onRemove(data),
              });
            },
            order: 1,
          },
        ],
        editText: <Icon type="icon-bianji" size={24} />,
        onCanDelete: () => false,
      }}
      className="aspirin-table aspirin-table-no-background-box"
      vsid="16317"
      onFetch={onFetch}
      scroll={{
        // y: getScrollY(400),
        y: getScrollYRem(26),
      }}
      pagination={false}
      onAdd={onAdd}
      onUpdate={onUpdate}
      searchFormRender={(props, dom) => (
        <div className="aspirin-table-search-inline-no">
          <SearchContainer mode="inline" formPadding="0 170px 0 100px">
            {dom}
          </SearchContainer>
          <Divider />
        </div>
      )}
      searchConfig={{
        labelWidth: '10%',
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        optionRender: (...arr) => {
          const [, , [other, search]] = arr;
          return (
            <SearchContainer.Header title="筛选">
              {[
                other,
                <ButtonAndKeyDown
                  methodKey="altKey"
                  ordinaryKey="Q"
                  {...search?.props}
                >
                  查询
                </ButtonAndKeyDown>,
              ]}
            </SearchContainer.Header>
          );
        },
      }}
      addLinkPage={{
        linkPage: () => <SpecimenEdit />,
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.SpecimenEdit?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        modalProps: {
          title: '标本维护',
          width: '50%',
          className: 'aspirin-linkpage',
        },
      }}
      updateLinkPage={{
        linkPage: () => <SpecimenEdit />,
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.SpecimenEdit?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        modalProps: {
          title: '标本维护',
          width: '50%',
          className: 'aspirin-linkpage',
        },
      }}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="标本代码"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['specimenName']}
        title="标本名称"
        valueType="text"
        search
        searchFieldProps={{
          placeholder: '请输入标本名称/输入码',
        }}
        searchConfig={
          {
            // transform(value) {
            //   return {
            //     // specimenNameLike: value,
            //     inputCodeLike: value,
            //     // inputCodeWubiLike: value
            //   }
            // }
          }
        }
        columnKey={'specimenNameLike'}
        formItemProps={{
          rules: [
            {
              message: 'specimen_name长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['inputCode']}
        title="拼音输入码"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'input_code长度不合法',
              type: 'string',
              min: 0,
              max: 10,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['inputCodeWubi']}
        title="五笔输入码"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'input_code_wb长度不合法',
              type: 'string',
              min: 0,
              max: 10,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['pattern', 'patternName']}
        title="模版名称"
        valueType="text"
        formItemProps={{
          rules: [{ message: '数据格式错误', type: 'string', min: 0, max: 50 }],
        }}
        render={(dom, data) => {
          return (
            <Tag
              color="blue"
              style={{
                borderColor: 'var(--blue)',
              }}
            >
              {data.pattern.patternName}
            </Tag>
          );
        }}
        fieldProps={{}}
      />

      <VSTableToolbar
        title="检验标本字典列表"
        className="aspirin-toolbar-padding-top-box"
      >
        <VSTableAddition children="添加" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(SpecimenTable);
