import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const SendModal = (props, ref): any => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const renderContent = useCallback(() => {
    return (
      <div className="content">
        <div className="first">
          <VSTable>
            <VSTableColumn valueType="index" title="" preview />

            <VSTableColumn
              valueType="text"
              dataIndex={['bed']}
              title="床号"
              preview
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['result']}
              title="发送结果"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
        <div className="second">
          <VSTable>
            <VSTableColumn
              valueType="text"
              dataIndex={['bed']}
              title="患者信息"
              preview
            />

            <VSTableColumn
              dataIndex={['name']}
              title="医嘱内容"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['result']}
              title="失败详情"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
      </div>
    );
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button type="primary" onClick={onClose}>
            取消
          </Button>
        </div>
      ),
    };
  }, [onClose]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  return (
    <Modal
      width={1168}
      open={open}
      destroyOnClose={true}
      title="医嘱发送结果"
      onCancel={onClose}
      {...renderFooter()}
      className="aspirin-execute-order-send-modal"
    >
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(SendModal);
