// 西药/中成药
export const westernMedicineAndChinesePatentMedicineFloatList = [
  {
    label: 'operateType',
    value: 1,
    time: 'operateTime',
    doctor: 'operatorName',
  },
  {
    label: 'operateType',
    value: 2,
    time: 'operateTime',
    doctor: 'operatorName',
  },
  {
    label: 'operateType',
    value: 3,
    time: 'operateTime',
    doctor: 'operatorName',
  },
  {
    label: 'operateType',
    value: 4,
    time: 'operateTime',
    doctor: 'operatorName',
  },
  {
    label: 'operateType',
    value: 5,
    time: 'operateTime',
    doctor: 'operatorName',
  },
  {
    label: 'operateType',
    value: 6,
    time: 'operateTime',
    doctor: 'operatorName',
  },
];
export const westernMedicineAndChinesePatentMedicineBasicInfo = [
  {
    label: '项目名称',
    value: 'orderText',
  },
  {
    label: '规格',
    value: 'packageSpecification',
  },
  {
    label: '开立医生',
    value: 'doctorName',
  },
  {
    label: '医嘱号',
    value: 'id',
  },
  {
    label: '诊断',
    value: 'diagnosis',
  },
];
// 检查
export const checkFloatList = [
  {
    label: '开立',
    value: 1,
    time: 'createDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '提交',
    value: 2,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '缴费',
    value: 3,
    time: 'submitDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '登记',
    value: 4,
    time: 'submitDateTime',
    doctor: 'createDoctorName',
  },
  // {
  //   label: '开始检查',
  //   value: 5,
  //   time: 'submitDateTime',
  //   doctor: 'createDoctorName',
  // },
  {
    label: '结束检查',
    value: 6,
    time: 'submitDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '初步报告',
    value: 7,
    time: 'submitDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '确认报告',
    value: 8,
    time: 'submitDateTime',
    doctor: 'createDoctorName',
  },
];
export const checkBasicInfo = [
  {
    label: '项目名称',
    value: 'orderText',
  },
  {
    label: '执行科室',
    value: 'performDepartmentName',
  },
  {
    label: '申请医生',
    value: 'doctorName',
  },
  {
    label: '医嘱号',
    value: 'id',
  },
  {
    label: '检查号',
    value: 'labItemId',
  },
  {
    label: '诊断',
    value: 'diagnosis',
  },
];
// 检验
export const examineFloatList = [
  {
    label: '开立',
    value: 1,
    time: 'createDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '提交',
    value: 2,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '发送',
    value: 3,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '标本采集',
    value: 4,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '标本核收',
    value: 5,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '检验计费',
    value: 6,
    time: 'settleDate',
    doctor: 'settleStaffName',
  },
  {
    label: '初步报告',
    value: 7,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '确认报告',
    value: 8,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
];
export const examineBasicInfo = [
  {
    label: '申请项目',
    value: 'orderText',
  },
  {
    label: '样本',
    value: 'performDepartmentId',
  },
  {
    label: '申请医生',
    value: 'doctorName',
  },
  {
    label: '检验号',
    value: 'labItemId',
  },
  {
    label: '标本条码',
    value: 'barcode',
  },
  {
    label: '诊断',
    value: 'diagnosis',
  },
];
// 中草药
export const herbsFloatList = [
  {
    label: '开立',
    value: 1,
    time: 'createDateTime',
    doctor: 'createDoctorName',
  },
  {
    label: '提交',
    value: 2,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
  {
    label: '计费',
    value: 3,
    time: 'settleDate',
    doctor: 'settleStaffName',
  },
  {
    label: '发药',
    value: 4,
    time: 'submitDateTime',
    doctor: 'submitDoctorName',
  },
];
export const herbsBasicInfo = [
  {
    label: '项目名称',
    value: 'orderText',
  },
  {
    label: '开立医生',
    value: 'doctorName',
  },

  {
    label: '医嘱号',
    value: 'id',
  },
  {
    label: '诊断',
    value: 'diagnosis',
  },
];
// 中汤药剂明细
export const herbsBasicDetail = [
  {
    label: '使用方法',
    value: 'useWay',
  },
  {
    label: '服用时间',
    value: 'useTimeDescription',
  },

  {
    label: '服用次数',
    value: 'frequency',
  },
  {
    label: '付数',
    value: 'herbRegimen',
  },
  {
    label: '备注',
    value: 'memo',
  },
];

export { default as Detail } from './detail';
