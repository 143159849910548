type ParamsType = {
  /**
   * 初始数组
   */
  oldState: never[];
  /**
   * 新数组
   */
  newState: never[];
  id: string;
};
/**
 * 用于一次性发送 添加-add 修改-update 删除-remove 的函数
 * @param params 参数
 * @returns 返回对象包含 add update remove
 */
function askJustOnce(params: ParamsType) {
  const { oldState, newState, id = 'id' } = params;
  const newly = (label: never) =>
    newState.map((item) => item[id]).includes(label);
  const old = (label: never) =>
    oldState.map((item) => item[id]).includes(label);
  /**
   * @const {add} 从新数组找出旧数组不存在的数据
   * @const {update} 从旧数组找出新数组存在的数据
   * @const {remove} 从旧数组找出新数组不存在的数据
   */
  const add = newState.filter((item) => !old(item?.[id]));
  const update = newState.filter((item) => old(item?.[id]));
  const remove = oldState.filter((item) => !newly(item?.[id]));
  return {
    add,
    update,
    remove,
  };
}

export default askJustOnce;
