// table
export const convertQueryByInpVisitHospitalWithPatientQtoRes2InpVisitHospitalList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.bedLabel = _.get(d, 'patientInHospital.bed.bedLabel');
        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        return obj;
      }) ?? []),
    );
    return output;
  };

// form
export const convertQueryNewbornRecordInfoVoByNewbornRecordInfoQtoRes2NewbornRecordForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {}; // TODO 请确认下行字段是否符合逻辑
    output.motherDisplayId = _.get(
      input,
      `data.[0].motherInpVisit.patient.displayId`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.babyCount = _.get(
      input,
      `data.[0].motherInpVisit.inpVisitExtensionGynaecologyInfo.babyCount`,
    ); // TODO 请确认下行字段是否符合逻辑
    output.name = _.get(input, `data.[0].motherInpVisit.patient.name`); // TODO 请确认下行字段是否符合逻辑
    output.gender = _.get(input, `data.[0].motherInpVisit.patient.gender`); // TODO 请确认下行字段是否符合逻辑
    output.birthday = _.get(input, `data.[0].motherInpVisit.patient.birthday`);
    output.birthWeek = _.get(input, `data.[0].birthWeek`);
    output.weight = _.get(input, `data.[0].weight`);
    output.height = _.get(input, `data.[0].height`);
    output.healthStatus = _.get(input, `data.[0].healthStatus`);
    output.birthType = _.get(input, `data.[0].birthType`);
    output.birthResult = _.get(input, `data.[0].birthResult`);
    output.birthPlaceType = _.get(input, `data.[0].birthPlaceType`);
    output.midwifeNurse = _.get(input, `data.[0].midwifeNurse`);
    output.birthPlace = {};
    output.birthPlace.birthPlaceProvince = _.get(
      input,
      `data.[0].birthPlaceProvince`,
    );
    output.birthPlace.birthPlaceProvinceCode = _.get(
      input,
      `data.[0].birthPlaceProvinceCode`,
    );
    output.birthPlace.birthPlaceCounty = _.get(
      input,
      `data.[0].birthPlaceCounty`,
    );
    output.birthPlace.birthPlaceCountyCode = _.get(
      input,
      `data.[0].birthPlaceCountyCode`,
    );
    output.birthPlace.birthPlaceCity = _.get(input, `data.[0].birthPlaceCity`);
    output.birthPlace.birthPlaceCityCode = _.get(
      input,
      `data.[0].birthPlaceCityCode`,
    );
    output.birthPlaceOther = _.get(input, `data.[0].birthPlaceOther`);
    output.birthInstitutionName = _.get(input, `data.[0].birthInstitutionName`);
    output.babyDirection = _.get(input, `data.[0].babyDirection`);
    output.apgarOneMinute = _.get(input, `data.[0].apgarOneMinute`);
    output.apgarFiveMinute = _.get(input, `data.[0].apgarFiveMinute`);
    output.motherName = _.get(input, `data.[0].motherName`);
    output.motherAge = _.get(input, `data.[0].motherAge`);
    output.motherCitizenship = _.get(input, `data.[0].motherCitizenship`);
    output.motherNation = _.get(input, `data.[0].motherNation`);
    output.fetusCount = _.get(input, `data.[0].fetusCount`);
    output.motherAddress = _.get(input, `data.[0].motherAddress`);

    output.motherIdentificationNumber = _.get(
      input,
      `data.[0].motherIdentificationNumber`,
    );

    output.motherIdentificationType = {
      IdentificationType: _.get(input, `data.[0].motherIdentificationType`),
      IdentificationNum: _.get(input, `data.[0].motherIdentificationNumber`),
    };

    output.pregnantCount = _.get(input, `data.[0].pregnantCount`);
    output.birthCount = _.get(input, `data.[0].birthCount`);
    output.fatherName = _.get(input, `data.[0].fatherName`);
    output.fatherAge = _.get(input, `data.[0].fatherAge`);
    output.fatherCitizenship = _.get(input, `data.[0].fatherCitizenship`);
    output.fatherNation = _.get(input, `data.[0].fatherNation`);
    output.fatherAddress = _.get(input, `data.[0].fatherAddress`);
    output.fatherIdentificationNumber = _.get(
      input,
      `data.[0].fatherIdentificationNumber`,
    );
    output.fatherIdentificationType = {
      identificationType: _.get(input, `data.[0].fatherIdentificationType`),
      identificationNum: _.get(input, `data.[0].fatherIdentificationNumber`),
    };
    output.birthCertificationNumber = _.get(
      input,
      `data.[0].birthCertificationNumber`,
    );
    output.dateOfPublish = _.get(input, `data.[0].dateOfPublish`);
    return output;
  };
// save
export const convertNewbornRecordForm2NewbornRecordFuncFlowExecutorReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.saveNewbornRecordBto = {}; // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.id = null; // Long 主键
  output.saveNewbornRecordBto.midwifeNurseId = _.get(input, 'midwifeNurse.id'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.newbornPatientId = null; // Long 新生儿患者ID
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.motherInpVisitId = null; // Long 妈妈住院流水号
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.babyNumber = null; // Long 婴儿序号
  output.saveNewbornRecordBto.birthPlaceProvinceCode = _.get(
    input,
    'birthPlace.birthPlaceProvinceCode',
  );
  output.saveNewbornRecordBto.birthPlaceProvince = _.get(
    input,
    'birthPlace.birthPlaceProvince',
  );
  output.saveNewbornRecordBto.birthPlaceCityCode = _.get(
    input,
    'birthPlace.birthPlaceCityCode',
  );
  output.saveNewbornRecordBto.birthPlaceCity = _.get(
    input,
    'birthPlace.birthPlaceCity',
  );
  output.saveNewbornRecordBto.birthPlaceCountyCode = _.get(
    input,
    'birthPlace.birthPlaceCountyCode',
  );
  output.saveNewbornRecordBto.birthPlaceCounty = _.get(
    input,
    'birthPlace.birthPlaceCounty',
  );
  output.saveNewbornRecordBto.birthPlaceOther = _.get(input, 'birthPlaceOther');
  output.saveNewbornRecordBto.birthWeek = _.get(input, 'birthWeek');
  output.saveNewbornRecordBto.healthStatus = _.get(input, 'healthStatus');
  output.saveNewbornRecordBto.weight = _.get(input, 'weight');
  output.saveNewbornRecordBto.height = _.get(input, 'height');
  output.saveNewbornRecordBto.birthType = _.get(input, 'birthType');
  output.saveNewbornRecordBto.birthResult = _.get(input, 'birthResult'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.motherPatientId = null; // Long 母亲标识号
  output.saveNewbornRecordBto.motherName = _.get(input, 'motherName');
  output.saveNewbornRecordBto.motherAge = _.get(input, 'motherAge');
  output.saveNewbornRecordBto.motherCitizenship = _.get(
    input,
    'motherCitizenship',
  );
  output.saveNewbornRecordBto.motherNation = _.get(input, 'motherNation');
  output.saveNewbornRecordBto.motherIdentificationNumber = _.get(
    input,
    'motherIdentificationType.identificationNum',
  );
  output.saveNewbornRecordBto.fatherName = _.get(input, 'fatherName');
  output.saveNewbornRecordBto.fatherAge = _.get(input, 'fatherAge');
  output.saveNewbornRecordBto.fatherCitizenship = _.get(
    input,
    'fatherCitizenship',
  );
  output.saveNewbornRecordBto.fatherNation = _.get(input, 'fatherNation');
  output.saveNewbornRecordBto.fatherIdentificationNumber = _.get(
    input,
    'fatherIdentificationType.identificationNum',
  );
  output.saveNewbornRecordBto.motherAddress = _.get(input, 'motherAddress');
  output.saveNewbornRecordBto.birthPlaceType = _.get(input, 'birthPlaceType'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.birthInstitutionCode = null; // String 接生医疗机构编码
  output.saveNewbornRecordBto.birthInstitutionName = _.get(
    input,
    'birthInstitutionName',
  );
  output.saveNewbornRecordBto.birthCertificationNumber = _.get(
    input,
    'birthCertificationNumber',
  );
  output.saveNewbornRecordBto.dateOfPublish = _.get(input, 'dateOfPublish'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.recordNurseId = null; // Long 录入人ID
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.recordDate = null; // Date 录入日期
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.inMotherRoom = null; // Boolean 母婴同室
  output.saveNewbornRecordBto.motherIdentificationType = _.get(
    input,
    'motherIdentificationType.identificationType',
  );
  output.saveNewbornRecordBto.fatherIdentificationType = _.get(
    input,
    'fatherIdentificationType.identificationType',
  );
  output.saveNewbornRecordBto.fatherAddress = _.get(input, 'fatherAddress');
  output.saveNewbornRecordBto.babyDirection = _.get(input, 'babyDirection'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.cesareanSectionIndication = null; // String 剖宫产指征
  output.saveNewbornRecordBto.fetusCount = _.get(input, 'fetusCount');
  output.saveNewbornRecordBto.pregnantCount = _.get(input, 'pregnantCount');
  output.saveNewbornRecordBto.birthCount = _.get(input, 'birthCount'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.birthOutCondition = null; // String 产出情况
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.dischargeCondition = null; // String 出院情况
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.newbornBirthWound = null; // String 新生儿产伤
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.rescueTimes = null; // String 抢救次数
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.rescueSuccessTimes = null; // String 成功次数
  // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.lastLabBillingDateTime = null; // Date 最近一次检验费用计价时间
  output.saveNewbornRecordBto.apgarOneMinute = _.get(input, 'apgarOneMinute');
  output.saveNewbornRecordBto.apgarFiveMinute = _.get(input, 'apgarFiveMinute'); // TODO 没有匹配到合适字段 output.saveNewbornRecordBto.apgar = null; // String Apgar评分
  output.createPatientBto = {};
  output.createPatientBto.displayId = _.get(input, 'motherDisplayId');
  output.createPatientBto.name = _.get(input, 'name'); // TODO 没有匹配到合适字段 output.createPatientBto.nameInputCode = null; // String 姓名输入码
  output.createPatientBto.gender = _.get(input, 'gender');
  output.createPatientBto.birthday = _.get(input, 'birthday'); // TODO 没有匹配到合适字段 output.createPatientBto.defaultChargeType = null; // Dbenum<CHARGE_TYPE_DICT> 默认费别
  output.createPatientBto.patientProfileBto = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.citizenship = null; // Dbenum<COUNTRY_DICT> 国籍
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nation = null; // Dbenum<NATION_DICT> 民族
  output.createPatientBto.patientProfileBto.nativeAddress = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.nativeAddress.postcode = null; // String 邮编
  output.createPatientBto.patientProfileBto.currentAddress = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.currentAddress.postcode = null; // String 邮编
  output.createPatientBto.patientProfileBto.registeredResidenceAddress = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.registeredResidenceAddress.postcode = null; // String 邮编
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.email = null; // String 电子邮件
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.securityLevel = null; // Long 保密等级
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.operator = null; // String 操作员
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.policeStationName = null; // String 警医共建单位编码
  output.createPatientBto.patientProfileBto.workUnit = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.name = null; // String 单位名称
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.phoneNumber = null; // String 单位联系电话
  output.createPatientBto.patientProfileBto.workUnit.address = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.workUnit.address.postcode = null; // String 邮编
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.unitInContract = null; // String 合同单位
  output.createPatientBto.patientProfileBto.contact = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.name = null; // String 联系人姓名
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.relationshipType = null; // Dbenum<RELATIONSHIP_DICT> 联系人关系类型
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.phoneNumber = null; // String 联系人的联系电话
  output.createPatientBto.patientProfileBto.contact.address = {}; // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.contact.address.postcode = null; // String 邮编
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.guardianName = null; // String 监护人姓名
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.emergencyPhone = null; // String 紧急联系电话
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.faxNumber = null; // String 传真号
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.specialPatientType = null; // String 特殊患者类型
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.externalPlatformId = null; // String 外部平台ID
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.creator = null; // String 创建人
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.marriageStatus = null; // Dbenum<MARITAL_STATUS_DICT> 婚姻状态
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.specialMedicalEndDate = null; // Date 专用病例过期时间
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.specialMedicalRecordNumber = null; // String 专用病历号
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.wechatAccount = null; // String 微信号
  // TODO 没有匹配到合适字段 output.createPatientBto.patientProfileBto.alipayAccount = null; // String 支付宝账号
  // TODO 没有匹配到合适字段 output.createPatientBto.identityCode = null; // Dbenum<IDENTITY_DICT> 人员类别编码
  output.createPatientBto.birthAddress = {}; // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.province = null; // String 省
  // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.city = null; // String 市
  // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.county = null; // String 区
  // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.detail = null; // String 详细地址
  // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.code = null; // Dbenum<AREA_DICT> 地区编码
  // TODO 没有匹配到合适字段 output.createPatientBto.birthAddress.postcode = null; // String 邮编
  // TODO 没有匹配到合适字段 output.createPatientBto.status = null; // String 状态码
  // TODO 没有匹配到合适字段 output.createPatientBto.creator = null; // String 创建人
  // TODO 没有匹配到合适字段 output.createPatientBto.idNumber = null; // String 身份证号
  // TODO 没有匹配到合适字段 output.createPatientBto.insuranceType = null; // Dbenum<INSUR_TYPE_DICT> 医保类型
  // TODO 没有匹配到合适字段 output.createPatientBto.insuranceNumber = null; // String 医保账号
  // TODO 没有匹配到合适字段 output.createPatientBto.insuranceCardNumber = null; // String 医保卡号
  // TODO 没有匹配到合适字段 output.createPatientBto.cellphone = null; // String 手机号
  // TODO 没有匹配到合适字段 output.createPatientBto.phoneNumber = null; // String 联系电话
  // TODO 没有匹配到合适字段 output.createPatientBto.operator = null; // String 操作人
  output.createPatientBto.patientIdentificationBtoList = [];
  output.createPatientBto.patientRelationshipBtoList = []; // TODO 没有匹配到合适字段 output.createPatientBto.unknownIndicator = null; // Boolean 无名患者标识
  // TODO 没有匹配到合适字段 output.createPatientBto.bloodCardIndicator = null; // Boolean 献血证标识
  // TODO 没有匹配到合适字段 output.createPatientBto.disabilityIndicator = null; // Boolean 残疾人证标识
  // TODO 没有匹配到合适字段 output.createPatientBto.vipIndicator = null; // Boolean VIP标识
  output.createPatientBto.label = {}; // TODO 没有匹配到合适字段 output.createPatientBto.label.id = null; // String 标签ID
  // TODO 没有匹配到合适字段 output.createPatientBto.label.name = null; // String 标签名称
  // TODO 没有匹配到合适字段 output.createPatientBto.label.parentId = null; // String 标签父ID
  // TODO 没有匹配到合适字段 output.createPatientBto.avatar = null; // String 患者头像
  return output;
};
