import vsf from '@vs/vsf-boot';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
export const getWardPagedBySearchCode = async (params) => {
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        searchCode: params?.searchCode,
        clinicType: params?.clinicType,
      },
    ); // TODO: 你可能需要整理返回数据格式
  return [{ departmentName: '全部', id: 'qb' }, ...res?.data?.result];
};
const convertGetAllByGetInpHerbWaitPrescriptionListQtoRes2TableInpHerbWaitPrescsRo =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.key = i;
        obj.prescriptionDate = _.get(d, 'prescriptionDate');
        obj.name = _.get(d, 'patient.name'); //患者姓名
        obj.wardName = _.get(d, 'wardName');
        obj.bedLabel = _.get(d, 'inpVisit.patientInHospital.bed.bedLabel'); //床号
        obj.herbType = _.get(d, 'herbType');
        obj.inpVisitId = _.get(d, 'inpVisit.id');
        obj.waitMasterId = _.get(d, 'id');
        obj.displayId = _.get(d, 'patient.displayId'); //患者id
        obj.gender = vsf.stores.dbenums?.enums?.SEX_DICT?.find?.(
          (f) => f?.code === _.get(d, 'patient.gender'),
        )?.cname; //性别
        obj.id = `${_.get(d, 'patient.id')}${i}`;
        obj.patientId = _.get(d, 'patient.id');
        return obj;
      }) ?? []),
    );
    return output.filter((f) => !isEmpty(f.bedLabel)); // 过虑未录入床位的患者；
  };

const getPatInfo = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.name = _.get(input, 'patient.name');
  output.gender = _.get(input, 'patient.gender');
  output.age = _.get(input, 'age');
  output.displayId = _.get(input, 'patient.displayId');
  output.workAddress = _.get(input, 'patient.patientProfileDto.workUnit.name');
  output.height = _.get(input, 'height');
  output.weight = _.get(input, 'weight');
  output.pressure = _.get(input, 'pressure');
  output.allergyName = _.get(input, 'allergyName');
  output.chargeTypeName = _.get(input, 'chargeTypeName');
  output.identityName = _.get(input, 'identityName');
  output.phoneNumber = _.get(input, 'patient.phoneNumber');
  output.haveRefundSettle = _.get(input, 'haveRefundSettle');
  return output;
};
const getEnumsLabel = (enumCode, type) => {
  return vsf.stores.dbenums?.enums?.[enumCode]?.find(({ code }) => type == code)
    ?.cname;
};
const convertGetHerbWaitDispenseDetailByMasterIdRes2FormInpHerbWaitDispense = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = {};
  const prescriptionSourceEnums = {
    OUTP: '门诊',
    INP: '住院',
    BILLING_WINDOW: '收费处',
    PHARMACY: '药房',
    INP_REFUND: '住院退药',
    ERP_GREEN_AREA: '急诊绿区',
    ERP_RED_AREA: '急诊红区',
    ERP_RED_AREA_REFUND: '急诊红区退药',
    DISCHARGE_REFUND: '出院患者退药',
  };
  output.prescriptionDate = dayjs(_.get(input, 'data.prescriptionDate')).format(
    'YYYY-MM-DD',
  );
  output.prescriptionSource =
    prescriptionSourceEnums?.[_.get(input, 'data.prescriptionSource')]; // TODO 请确认下行字段是否符合逻辑
  output.prescriptionNumber = _.get(
    input,
    'data.prescriptionInfo.prescriptionNumber',
  ); // TODO 请确认下行字段是否符合逻辑
  output.staffName = _.get(
    input,
    'data.prescriptionInfo.orderHerb.order.doctorInfo.staffName',
  );
  output.wardName = _.get(input, 'data.wardName');
  output.bedLabel = _.get(input, 'data.bedLabel'); // TODO 请确认下行字段是否符合逻辑
  output.agreedPrescriptionName = _.get(
    input,
    'data.prescriptionInfo.orderHerb.agreedPrescriptionName',
  );
  output.diagnosisName = _.get(input, 'data.diagnosisName'); // TODO 请确认下行字段是否符合逻辑
  output.herbType = getEnumsLabel(
    'CHINESE_MEDICINE_DICT',
    _.get(input, 'data.prescriptionInfo.orderHerb.herbType'),
  ); // TODO 请确认下行字段是否符合逻辑
  output.useWay = getEnumsLabel(
    'MEDICINE_USAGE_DICT',
    _.get(input, 'data.prescriptionInfo.orderHerb.useWay'),
  );
  output.herbRegimen = _.get(input, 'data.herbRegimen');
  output.averageCharge = _.get(input, 'data.averageCharge');
  output.totalCharge = _.get(input, 'data.totalCharge'); // TODO 请确认下行字段是否符合逻辑
  output.decoctMethod = getEnumsLabel(
    'MEDICINE_USAGE_DICT',
    _.get(input, 'data.prescriptionInfo.orderHerb.decoctMethod'),
  );
  return output;
};

const convertGetHerbWaitDispenseDetailByMasterIdRes2TableHerbDetail = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = []; // TODO 请确认下行字段是否符合逻辑
  output.push(
    ..._.get(input, 'data.drugPrescriptionWaitDispenseDetailList').map(
      (d, i) => {
        const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
        obj.drugProductName = _.get(
          d,
          'drugPriceItemInfo.drugProductInfo.drugProductName',
        );
        obj.amount = _.get(d, 'amount');
        obj.medicalInsuranceType = _.get(
          d,
          'outpBillingDetailInfo.medicalInsuranceType',
        );
        obj.amountPackageUnit = _.get(d, 'amountPackageUnit');
        obj.price = _.get(d, 'outpBillingDetailInfo.price');
        obj.charge = _.get(d, 'outpBillingDetailInfo.charge');
        obj.supplyIndicator = _.get(d, 'supplyIndicator');
        obj.charge = _.get(d, 'outpBillingDetailInfo.charge');
        obj.useDescription = _.get(d, 'orderHerbDetailInfo.useDescription');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.id = _.get(d, 'id');
        return obj;
      },
    ),
  );
  return output;
};

const convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispense = (
  input,
) => {
  return {
    secondPrepareDrugStaffId: input?.staff?.id,
    staffName: input?.staff?.staffName,
  };
};

export const getHerbWaitDispenseDetailByMasterId = async (params) => {
  // Tips: 函数入参包含通过表单params属性传入的额外参数(平铺)
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        ...params,
        // id: 16005,
      },
    ); // Tips: 需要返回内容数据，结构和表单RO一致
  if (res?.code !== 200 || !res?.data) return false;
  const output =
    convertGetHerbWaitDispenseDetailByMasterIdRes2FormInpHerbWaitDispense(res);

  return output;
};
export const PatientComplexSelectList = async (params) => {
  const res = await list(params);
  return res.reduce?.((acc, curr) => {
    const found = acc.find(
      (item) =>
        JSON.stringify(item.patientId) === JSON.stringify(curr.patientId),
    );
    if (!found) {
      acc.push(curr);
    }
    return acc;
  }, []);
};
export const list = async (params) => {
  // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
  // Tips: 以下是你选择的请求函数
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getAllByGetInpHerbWaitPrescriptionListQto_b97429?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          // from: params.pagination?.from ?? 0,
          // size: params.pagination?.size ?? 20,
          // orderList: params.orderList,
          // ...params.search,
          // ...params.filters,
          // ...(params?.extra ?? {}),
          // ...(params ?? {}),
          ...params,
        },
        ext: {},
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output =
    convertGetAllByGetInpHerbWaitPrescriptionListQtoRes2TableInpHerbWaitPrescsRo(
      res,
    );
  return output ?? [];
};

export const getDetailById = async (params) => {
  // console.log(params);
  if (!params?.id) {
    return {};
  }
  const res =
    await vsf.services?.ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd?.(
      {
        ...params,
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  return getPatInfo(res?.data);
};

export const getTableData = async (params) => {
  if (!params?.id) {
    return [];
  }
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935?.(
      {
        ...params,
        // id: 16005,
      },
    );
  const output =
    convertGetHerbWaitDispenseDetailByMasterIdRes2TableInpHerbDetail(res);
  return {
    data: output, // res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};

export const drugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispense(m),
  );
};

export const windowDetail = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getLocalDispenseWindowSetting_302b1f?.(
      {},
    );
  return res?.data;
};

export const useDispenseWindowNo = async (params) => {
  return await vsf.services?.DrugPrescriptionDispenseController_useDispenseWindowNo_5107a0?.(
    params,
  );
};

export const unUseDispenseWindowNo = async (params) => {
  return await vsf.services?.DrugPrescriptionDispenseController_unUseDispenseWindowNo_3fb802?.(
    params,
  );
};
export const getRefundState = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935?.(
      params,
    );
  return {
    data: convertGetHerbWaitDispenseDetailByMasterIdRes2TableInpHerbDetail?.(
      res,
    ),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetHerbWaitDispenseDetailByMasterIdRes2TableInpHerbDetail
 * @param {any} input
 * @returns {any}
 */
const convertGetHerbWaitDispenseDetailByMasterIdRes2TableInpHerbDetail = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  return (
    input?.data?.drugPrescriptionWaitDispenseDetailList?.map((m, i) => {
      const obj = {
        masterId: input?.data?.id,
        orderText: m.orderText,
        amountAndUnit: m.amountAndUnit,
        medicalInsuranceType: m.medicalInsuranceType,
        amountPackageUnit: m.amountPackageUnit,
        price: m.price,
        charge: m.charge,
        useDescription: m.orderHerbDetailInfo?.useDescription,
        supplyIndicator: m?.supplyIndicator,
        isStockInsufficiency: m?.isStockInsufficiency,
      };
      return obj;
    }) ?? []
  );
};
