import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateListByStaffId_9bf8e5:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-charge-template-list-by-staff-id',
      parameterFomatter: (data?: { staffId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeTemplateVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
  MedicalTechnologyChargeTemplateController_deleteMedicalTechnologyChargeTemplateById_bfedf9:
    {
      method: 'post',
      url: '/api/medical-technology/delete-medical-technology-charge-template-by-id',
      parameterFomatter: (data?: { templateId: number }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  MedicalTechnologyChargeTemplateController_saveMedicalTechnologyChargeTemplate_792178:
    {
      method: 'post',
      url: '/api/medical-technology/save-medical-technology-charge-template',
      parameterFomatter: (data?: {
        chargeTemplateBto: CreateOrUpdateMedicalTechnologyChargeTemplateBtoMedicalTechnologyServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
});
