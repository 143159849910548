import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Space,
  Button,
  Modal,
  Checkbox,
  message,
  Tooltip,
} from '@vs/vsf-kit';
import './index.less';
import { getScrollY, getScrollYRem } from '@/utils';
import React, { useCallback, useEffect, useState } from 'react';
import {
  convertGetInpBillingDetailByVisitIdRes2InpSettleBillingDetailList,
  prePayment,
  paymentSubmit,
} from './Servce';
import Hospitalization from './hospitalizationBillList';
import ParModal from './ParModal';
import LiquidationOnAccount from './liquidationOnACCount';
import { useMount, useRequest } from 'ahooks';
import { add, generateRandomNumber, subtract } from '@/utils/setDataUtil';
import { askFixed } from '@/pages/Index/components/func/ask';
import { isMinScreen } from '@/config';

const charge = (props) => {
  const {
    inpVisitId,
    selectDrawer,
    patientInformation,
    displayIdData,
    clearDrawer,
  } = props;
  const drugEnum = vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT;
  const [selectRow, setSelectRow] = useState();
  const [index, setIndex] = useState();
  const { data: drugList, run: drugListRun } = useRequest(
    async () => {
      if (drugEnum) {
        setSelectRow(null);
        setIndex(null);
        let arr = Array.from(drugEnum);
        if (selectDrawer || displayIdData) {
          const res =
            await vsf?.services?.InpSettleController_getInpBillingClassByVisitId_00b9ff?.(
              {
                inpVisitId:
                  displayIdData?.inpVisitId || selectDrawer.inpVisitId,
              },
            );

          await Array.from(drugEnum)?.map((item) => {
            res?.data?.map((obj) => {
              if (obj?.inpReceiptClass === item?.value) {
                arr = arr?.filter(
                  (item) => obj?.inpReceiptClass !== item?.value,
                );
                arr?.push(obj);
              }
            });
          });
          arr?.push(res?.data[res?.data?.length - 1]);
          const par = arr?.map((item) => {
            return {
              ...item,
              inpReceiptClass: item?.inpReceiptClass ?? item?.value,
            };
          });
          const param = par?.map((item, index) => {
            return { ...item, id: `id_${generateRandomNumber()}${index}` };
          });
          param[param.length - 1] = {
            ...param[param.length - 1],
            inpReceiptClass: '总计',
          };
          return {
            data: param ?? res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        } else {
          return {
            data: [
              ...arr?.map((item, index) => {
                return {
                  ...item,
                  id: `id_${index}`,
                  inpReceiptClass: item?.value,
                };
              }),
              { id: 'total', inpReceiptClass: '总计' },
            ],
          };
        }
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    drugListRun();
  }, [selectDrawer, displayIdData, drugEnum]);

  useMount(() => {
    drugListRun();
  });

  const { data: preData, runAsync: preRun } = useRequest(prePayment, {
    manual: true,
  });
  const { runAsync: paymentSubmitRun } = useRequest(paymentSubmit, {
    manual: true,
  });

  const [advanceRow, setAdvanceRow] = useState();

  const [open, setOpen] = useState(false);

  const [billOpen, setBillOpen] = useState(false);

  const [accountOpen, setAccountOpen] = useState(false);

  const { data: getDrugDetail, run: getDrugDetailRun } = useRequest(
    async () => {
      if (selectDrawer?.inpVisitId || displayIdData?.inpVisitId) {
        if (!selectRow || selectRow.accountantChargeClass === '总计') {
          const res =
            await vsf?.services?.InpSettleController_getInpBillingDetailByVisitId_b288eb?.(
              {
                inpVisitId:
                  selectDrawer?.inpVisitId ?? displayIdData?.inpVisitId,
              },
            );
          const output =
            convertGetInpBillingDetailByVisitIdRes2InpSettleBillingDetailList?.(
              res,
            );
          return { data: output ?? [], total: output?.length ?? 0 };
        } else {
          const res =
            await vsf?.services?.InpSettleController_getInpBillingDetailByVisitId_b288eb?.(
              {
                inpVisitId:
                  selectDrawer?.inpVisitId ?? displayIdData?.inpVisitId,
                inpReceiptClass: selectRow?.inpReceiptClass,
              },
            );
          const output =
            convertGetInpBillingDetailByVisitIdRes2InpSettleBillingDetailList?.(
              res,
            );
          return { data: output ?? [], total: output?.length ?? 0 };
        }
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    getDrugDetailRun();
  }, [selectDrawer, selectRow, displayIdData]);

  //确认结算
  const chageClick = () => {
    const param = {
      inpVisitId: displayIdData?.inpVisitId || selectDrawer?.inpVisitId,
      patientId: patientInformation?.patientId,
      creditIndicator: false,
    };
    if (selectDrawer || displayIdData) {
      preRun(param).then((item) => {
        if (item) {
          setOpen(true);
        }
      });
    }
  };

  // 挂账清理
  const liquidationClick = () => {
    const param = {
      inpVisitId: displayIdData?.inpVisitId || selectDrawer?.inpVisitId,
      patientId: patientInformation?.patientId,
      creditIndicator: true,
    };
    preRun(param).then((item) => {
      if (item) {
        setAccountOpen(true);
      }
    });
  };

  const submit = () => {
    const paymentDetail = [];
    let totalCharge = 0;
    const cashData =
      vsf?.refs?.hospitalizationParmentForm?.getFieldValue('totalCharge');
    const aggregate =
      vsf?.refs?.hospitalizationParmentForm?.getFieldValue('aggregate');

    const remaining =
      vsf?.refs?.hospitalizationParmentForm?.getFieldValue('remaining');

    if (cashData) {
      paymentDetail?.push({
        paymentType: 'CASH',
        paymentAmount: askFixed(cashData, 2),
      });
      totalCharge = add(cashData, totalCharge, 2);
    }
    if (aggregate) {
      paymentDetail?.push({
        paymentType: 'INTEGRATION_PAYMENT',
        paymentAmount: askFixed(aggregate, 2),
      });
      totalCharge = add(aggregate, totalCharge, 2);
    }
    const param = {
      inpVisitId: displayIdData?.inpVisitId || selectDrawer?.inpVisitId,
      patientId: patientInformation?.patientId,
      totalCost: preData?.inpSettleMasterVo?.totalCost,
      totalCharge: askFixed(preData?.inpSettleMasterVo?.totalCharge),
      electronicInvoiceIndicator: false,
      paymentDetail: paymentDetail,
      prePaymentMasterIdList:
        advanceRow &&
        advanceRow?.map((item) => {
          return {
            prePaymentId: item?.prePaymentId,
            serialNumber: item?.serialNumber,
          };
        }),
    };
    if (!advanceRow) {
      delete param.prePaymentMasterIdList;
    }
    paymentSubmitRun(param).then((item) => {
      if (item.code === 200) {
        message.success('结算成功');
        setOpen(false);
      }
    });
  };

  const accountSubmit = () => {
    const paymentDetail = [];

    const remaining =
      vsf?.refs?.hospitalizationParmentForm1?.getFieldValue('remaining');

    const cashData =
      vsf?.refs?.hospitalizationParmentForm1?.getFieldValue('totalCharge');
    paymentDetail.push({
      paymentType:
        vsf?.refs?.hospitalizationParmentForm1?.getFieldValue('paymentType'),
      paymentAmount: askFixed(cashData, 2),
    });
    let prePaymentMasterIdList = preData?.prePaymentMasterVoList
      ? preData?.prePaymentMasterVoList?.map((ele) => ({
          prePaymentId: ele?.id,

          serialNumber: ele?.prePaymentDetailBaseDtoList?.serialNumber,
        }))
      : [];

    const param = {
      // ...preData?.inpSettleMasterVo,
      inpVisitId: displayIdData?.inpVisitId || selectDrawer?.inpVisitId,
      patientId: patientInformation?.patientId,
      totalCost: preData?.inpSettleMasterVo?.totalCost,
      totalCharge: remaining
        ? subtract(askFixed(cashData, 2), remaining, 2)
        : askFixed(preData?.inpSettleMasterVo?.totalCharge, 2),
      paymentDetail: paymentDetail ?? preData?.paymentDetail,
      electronicInvoiceIndicator: false,
      prePaymentMasterIdList,
    };
    paymentSubmitRun(param).then((item) => {
      if (item.code === 200) {
        message.success('挂账清理成功');
        setAccountOpen(false);
      }
    });
  };
  return (
    <>
      <div className="hospitalization-charge">
        <div className="charge-left">
          <VSPromiseTable
            editable={{ editType: 'none' }}
            vsid="45410"
            id="inpSettleBillingInfoClass"
            onFetch={() => drugList}
            pagination={false}
            onRow={(node, index) => {
              return {
                onClick() {
                  // if (node.accountantChargeClass !== '总计') {
                  setSelectRow(node);
                  setIndex(index);
                  // }
                },
                onDoubleClick() {
                  // if (node.accountantChargeClass !== '总计') {
                  setSelectRow(node);
                  setIndex(index);
                  // }
                },
              };
            }}
            bordered
            rowClassName={(record, rowIndex) => {
              if (index === rowIndex) {
                return 'aspirin-row-selected-box';
              } else {
                return '';
              }
            }}
            scroll={{
              // y: getScrollY(isMinScreen ? 350 : 500)
              y: getScrollYRem(34),
            }}
            className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          >
            <VSTableColumn
              dataIndex={['accountantChargeClass']}
              title="分类"
              hideInTable
              valueType="select"
              dataSource={vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['inpReceiptClass']}
              title="分类"
              valueType="select"
              dataSource={vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['itemClass']}
              title="价表项目分类"
              hideInTable
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 10 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['reckoningClass']}
              title="核算分类"
              hideInTable
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 20 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['cost']}
              title="总金额"
              valueType="digit"
              fieldProps={{}}
              render={(_, v) => {
                if (v?.cost) {
                  return v?.cost;
                } else {
                  return '0.00';
                }
              }}
            />

            <VSTableColumn
              dataIndex={['totalSelfPay']}
              title="自费"
              valueType="digit"
              fieldProps={{}}
              render={(_, v) => {
                if (v?.totalSelfPay) {
                  return v?.totalSelfPay;
                } else {
                  return '0.00';
                }
              }}
            />
          </VSPromiseTable>
        </div>
        <div className="charge-right">
          <div id="inpSettleBillingDetailListTable">
            <VSPromiseTable
              editable={{ editType: 'none' }}
              vsid="09722"
              id="inpSettleBillingDetailList"
              pagination={false}
              onFetch={() => getDrugDetail}
              bordered
              scroll={{
                // y: getScrollY(isMinScreen ? 350 : 524 ),
                y: getScrollYRem(37),
                x: 1680,
              }}
              className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
            >
              <VSTableColumn
                dataIndex={['inpReceiptClass']}
                title="票据分类"
                valueType="text"
                order={0}
                columnKey={'inpReceiptClass'}
                hideInTable
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['itemName']}
                title="项目名称"
                valueType="text"
                width={250}
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 200,
                    },
                  ],
                }}
                fieldProps={{}}
                render={(_, v) => {
                  return (
                    <div className="text-overflow-omit">
                      <Tooltip title={v?.itemName}>{v?.itemName}</Tooltip>
                    </div>
                  );
                }}
              />

              <VSTableColumn
                dataIndex={['price']}
                title="项目单价"
                valueType="digit"
                fieldProps={{}}
                width={150}
                render={(_, v) => {
                  return v.itemName === '小计' ? '' : askFixed(v?.price);
                }}
              />

              <VSTableColumn
                dataIndex={['departmentName']}
                title="执行科室"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '科室名称长度不合法',
                      type: 'string',
                      min: 0,
                      max: 40,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['cost']}
                title="金额"
                valueType="digit"
                fieldProps={{}}
                width={100}
                render={(_, v) => {
                  return askFixed(v?.cost);
                }}
              />

              <VSTableColumn
                dataIndex={['amount']}
                title="数量"
                valueType="digit"
                fieldProps={{}}
                width={100}
              />
              <VSTableColumn
                dataIndex={['orderDate']}
                title="业务时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['insuranceCode']}
                title="医保编码"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 50,
                    },
                  ],
                }}
                fieldProps={{}}
                render={(_, v) => {
                  return (
                    <div className="text-overflow-omit">
                      <Tooltip title={v?.insuranceCode}>
                        {v?.insuranceCode}
                      </Tooltip>
                    </div>
                  );
                }}
              />

              <VSTableColumn
                dataIndex={['operateDate']}
                title="计价时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['staffName']}
                title="录入人"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: 'staff_name长度不合法',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ],
                }}
                fieldProps={{}}
              />
            </VSPromiseTable>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Space size={4} align={'center'}>
              <Button>清屏</Button>
              <Button>发票打印</Button>
              <Button type={'primary'}>医保费用重传</Button>
              <Button onClick={liquidationClick}>挂账清理</Button>
              <Button type={'primary'} onClick={() => setBillOpen(true)}>
                费用汇总清单
              </Button>
              <Button type={'primary'} onClick={chageClick}>
                确认结算
              </Button>
            </Space>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        title={<div style={{ color: '#3276E8' }}>支付确认</div>}
        onCancel={() => {
          setOpen(false);
        }}
        okText={'确定'}
        cancelText={'取消'}
        width={1000}
        footer={
          <div className="pay-confim-footer">
            <div>
              <Checkbox defaultValue>生成票据</Checkbox>
              <Checkbox>单位发票</Checkbox>
            </div>
            <div>
              <Space size={4}>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={submit}>
                  确认
                </Button>
              </Space>
            </div>
          </div>
        }
      >
        <ParModal
          payParamData={preData}
          patientInformation={patientInformation}
          callback={(param) => setAdvanceRow(param)}
        />
      </Modal>

      <Modal
        open={billOpen}
        title={<div style={{ color: '#3276E8' }}>住院病人费用明细清单</div>}
        onCancel={() => {
          setBillOpen(false);
        }}
        okText={'确定'}
        cancelText={'取消'}
        width={1400}
        footer={
          <Space size={4}>
            <Button
              onClick={() => {
                setBillOpen(false);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={() => {}}>
              导出
            </Button>
            <Button type="primary" onClick={() => {}}>
              打印汇总
            </Button>
            <Button type="primary" onClick={() => {}}>
              打印明细
            </Button>
          </Space>
        }
      >
        <Hospitalization selectDrawer={selectDrawer} />
      </Modal>

      <Modal
        open={accountOpen}
        title={<div style={{ color: '#3276E8' }}>支付确认</div>}
        onCancel={() => {
          setAccountOpen(false);
        }}
        okText={'确定'}
        cancelText={'取消'}
        width={1000}
        footer={
          <div className="pay-confim-footer">
            <div>
              <Checkbox defaultValue>生成票据</Checkbox>
              <Checkbox>单位发票</Checkbox>
            </div>
            <div>
              <Space size={4}>
                <Button
                  onClick={() => {
                    setAccountOpen(false);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={accountSubmit}>
                  确认
                </Button>
              </Space>
            </div>
          </div>
        }
      >
        <LiquidationOnAccount
          payParamData={preData}
          patientInformation={patientInformation}
        />
      </Modal>
    </>
  );
};
export default definePage(charge);
