import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { SyncOutlined } from '@vs/vsf-icons';
import { Button, Input, message, Modal, Popover, Tree } from '@vs/vsf-kit';
import cn from 'classnames';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { debounce, stubTrue } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { TreeMenu } from '@/components';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icons from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import askGetTreeWhole from '@/pages/Index/components/func/ask/askGetTreeWhole';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { height, heightMessage } from '@/pattern';
import { user } from '@/stores';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

const { TreeNode } = Tree;

const Index = (props) => {
  const { handelEditChief } = props;
  const [tempData, setTempData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 树选择key
  const [selectedKey, setSelectedKey] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  // 输入框值
  const [inputValue, setInputValue] = useState<any>(null);
  const [inputCodeValue, setInputCodeValue] = useState<any>(null);

  // 当前新增/编辑item
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [type, setType] = useState<any>('add');
  // const [isFocus, setIsFocus] = useState<any>(0);
  const isFocus = useRef(0);
  let tempExpandedKeys: any = [];

  const refObj1 = useRef<any>(null);
  const refObj2 = useRef<any>(null);

  useEffect(() => {
    onExpand([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const input1 = document.querySelector('#inputField');
  const input2 = document.querySelector('#inputCode');

  const isInput1Focused = false;
  const isInput2Focused = false;

  // 检查两个输入框是否都失焦
  const checkBothBlurred = useCallback(() => {
    if (!refObj1.current || !refObj2.current) return;
    if (isFocus.current == 0) {
      // 两个输入框都失焦了
      if (
        !refObj1.current ||
        !inputValue ||
        inputValue?.length <= 0 ||
        !refObj2.current ||
        !inputCodeValue ||
        inputCodeValue?.length <= 0
      ) {
        // 取消保存(删除/取消修改)
        setOpen(true);
      } else {
        // 保存
        onSave({
          ...currentItem,
          value: inputValue,
          defaultValue: inputValue,
          chiefComplaintName: inputValue,
          inputCode: inputCodeValue,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // isInput1Focused,
    // isInput2Focused,
    refObj1,
    refObj2,
    isFocus,
    inputValue,
    inputCodeValue,
    data,
    open,
  ]);

  const getTreeData = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.ChiefComplaintDictionaryController_getChiefComplainDictionary_dd55d3?.(
        {
          qto: {
            from: 0,
            size: 100,
          },
        },
      );
    const _data = res?.data?.map((item) => {
      return {
        ...item?.baseCodeVo,
        value: item?.baseCodeVo?.name,
        defaultValue: item?.baseCodeVo?.name,
        key: item?.baseCodeVo?.id,
        inputCode: '',
        parent: true,
        isEditable: false,
        chiefComplaintClassCode: item?.baseCodeVo?.code,
        children:
          item?.chiefComplaintDictionaryVoList?.map((i) => {
            return {
              ...i,
              parentId: item?.baseCodeVo?.id,
              value: i?.chiefComplaintName,
              chiefComplaintClassCode: item?.baseCodeVo?.code,
              defaultValue: i?.chiefComplaintName,
              key: i?.id,
              isEditable: false,
              parent: false,
            };
          }) ?? [],
      };
    });
    // props.onChange?.(_data?.[0]?.children?.[0]);
    setLoading(false);
    // tempData.current = _data;
    // onExpand([_data?.[0]?.key]);
    setExpandedKeys([_data?.[0]?.key]);
    setTempData(_data);
    setData(_data);

    return _data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpand = (expandedKey: any) => {
    tempExpandedKeys = expandedKey;
    setExpandedKeys(expandedKey);
  };
  useEffect(() => {
    // onExpand([]);
    getTreeData();
  }, [getTreeData]);

  const getTreeNode = (key, tree, callback?) => {
    if (tree == null) {
      return null;
    }

    for (let index = 0; index < tree.length; index++) {
      if (tree[index].key == key) {
        return tree[index];
      } else {
        const node = getTreeNode(key, tree[index].child);
        if (node != null) {
          return node;
        }
      }
    }
    return null;
  };
  // 遍历tree
  const handelTree = (data) => {
    const res = data?.find((item) => {
      return item?.isEditable
        ? item
        : item?.children?.length > 0
        ? handelTree(item?.children)
        : null;
    });
    return res;
  };
  // 新增
  const onAdd = (e) => {
    // 判断有没有处于编辑状态的
    const result = handelTree(data);
    if (
      result &&
      (!inputValue ||
        inputValue?.length <= 0 ||
        !inputCodeValue ||
        inputCodeValue?.length <= 0)
    ) {
      setOpen(true);
    } else {
      if (result) {
        onSave({
          ...currentItem,
          value: inputValue,
          defaultValue: inputValue,
          chiefComplaintName: inputValue,
          inputCode: inputCodeValue,
        });
      }
      setInputValue(null);
      setInputCodeValue(null);
      // if (result) return message.info('有未保存的主诉，请先保存后再操作');
      // 防止expandedKeys重复
      // Tip: Must have, expandedKeys should not be reduplicative
      if (expandedKeys.indexOf(e) === -1) {
        tempExpandedKeys.push(e);
      }
      addNode(e, data);
      setExpandedKeys(tempExpandedKeys);
    }
  };

  const addNode = (key, data) => {
    const _key = 'create_' + Math.random();
    const res1 = getTreeNode(key, data);
    res1.children.push({
      value: '',
      inputCode: '',
      defaultValue: '',
      key: _key, // 这个 key 应该是唯一的。 Tip: The key should be unique
      id: _key,
      parentId: key,
      isEditable: true,
      parent: false,
      chiefComplaintClassCode: res1?.code,
    });
    setCurrentItem({
      value: '',
      inputCode: '',
      defaultValue: '',
      key: _key, // 这个 key 应该是唯一的。 Tip: The key should be unique
      id: _key,
      parentId: key,
      isEditable: true,
      parent: false,
      chiefComplaintClassCode: res1?.code,
    });
    // setType('add');
    setData([...data]);
  };
  // 删除
  const onDelete = async (item) => {
    const { id, ...params } = item;
    if (!('' + id)?.includes('create_')) {
      const res =
        await vsf?.services?.ChiefComplaintDictionaryController_deleteChiefComplaintDictionary_a123cd?.(
          { btoParam: { id: item?.id }, extApiParam: {} },
        );
      if (res?.code == 200) {
        deleteNode(item, data);
        message.success('删除成功');
      }
    } else {
      deleteNode(item, data);
    }
  };

  const deleteNode = (item, data?) => {
    const res1 = getTreeNode(item?.parentId, data);
    res1.children = res1?.children.filter((i) => {
      return i?.key != item?.key;
    });
    setInputCodeValue(null);
    setInputValue(null);
    setData([...data]);
  };
  // 编辑
  const onEdit = (item) => {
    // 判断有没有处于编辑状态的
    const result = handelTree(data);

    if (
      result &&
      (!inputValue ||
        inputValue?.length <= 0 ||
        !inputCodeValue ||
        inputCodeValue?.length <= 0)
    ) {
      setOpen(true);
    } else {
      if (result) {
        onSave({
          ...currentItem,
          value: inputValue,
          defaultValue: inputValue,
          chiefComplaintName: inputValue,
          inputCode: inputCodeValue,
        });
      }
      setCurrentItem(item);
      editNode(item, data);
    }

    // setData(tempData);
  };

  const editNode = (item, data) => {
    const res1 = getTreeNode(item?.parentId, data);

    res1.children = res1?.children.map((i) => {
      if (i?.key == item?.key) {
        setInputCodeValue(item?.inputCode);
        setInputValue(item?.value);
      }
      return i?.key == item?.key
        ? {
            ...i,
            isEditable: true,
          }
        : {
            ...i,
            isEditable: false,
          };
    });
    // setType('edit');
    setData([...data]);
  };

  // 取消编辑
  const onClose = (item) => {
    closeNode(item, data);
  };

  const closeNode = (item, data) => {
    const res1 = getTreeNode(item?.parentId, data);
    res1.children = res1?.children.map((i) => {
      return item.key == i.key
        ? { ...i, isEditable: false, value: i?.defaultValue }
        : { ...i };
    });
    setInputCodeValue(null);
    setInputValue(null);
    setData([...data]);
  };
  // 保存
  const onSave = async (item) => {
    const { id, ...params } = item;
    // if (params?.value?.length <= 0) return message.info('请输入主诉名称');
    // if (params?.inputCode?.length <= 0) return message.info('请输入输入码');
    // inputValue
    // if (inputValue?.length <= 0) return message.info('请输入主诉名称');
    // if (inputCodeValue?.length <= 0) return message.info('请输入输入码');
    if (('' + id)?.includes('create_')) {
      // if (inputValue?.length <= 0)
      const res =
        await vsf?.services?.ChiefComplaintDictionaryController_createChiefComplaintDictionary_c6b3af?.(
          {
            btoParam: {
              ...params,
              value: inputValue,
              defaultValue: inputValue,
              chiefComplaintName: inputValue,
              inputCode: inputCodeValue,
            },
            extApiParam: {},
          },
        );
      if (res?.code == 200) {
        setInputCodeValue(null);
        setInputValue(null);
        saveNode(
          {
            ...item,
            ...res?.data,
            id: res?.data?.id,
            value: res?.data?.chiefComplaintName,
          },
          data,
        );
        props.onChange?.(res?.data);

        message.success('新增成功');
        setSelectedKey([res?.data?.id]);
        handelEditChief(true);
      }
      return res;
    } else {
      const res =
        await vsf?.services?.ChiefComplaintDictionaryController_updateChiefComplaintDictionary_ff80d6?.(
          {
            btoParam: {
              ...item,
              value: inputValue,
              defaultValue: inputValue,
              chiefComplaintName: inputValue,
              inputCode: inputCodeValue,
              chiefComplaintCode: item?.inputCode,
            },
            extApiParam: {},
          },
        );
      if (res?.code == 200) {
        saveNode({ ...item, ...res?.data, id: res?.data?.id }, data);
        // setInputValue(null);
        // setInputCodeValue(null);
        message.success('编辑成功');

        handelEditChief(true);
      }
      return res;
    }
  };

  const saveNode = (item, data) => {
    const res1 = getTreeNode(item?.parentId, data);

    res1.children = res1?.children.map((i) => {
      return item.key == i.key
        ? {
            ...i,
            isEditable: false,
            key: item?.id,
            id: item?.id,
            value: item?.chiefComplaintName,
            defaultValue: item?.chiefComplaintName,
            chiefComplaintCode: item?.inputCode,
            inputCode: item?.inputCode,
          }
        : { ...i };
    });
    setData([...data]);
  };

  // 刷新

  const handleReload = async (v) => {
    const result = handelTree(data);

    if (
      result &&
      (!inputValue ||
        inputValue?.length <= 0 ||
        !inputCodeValue ||
        inputCodeValue?.length <= 0)
    ) {
      setOpen(true);
    } else {
      if (result) {
        const res = await onSave({
          ...currentItem,
          value: inputValue,
          defaultValue: inputValue,
          chiefComplaintName: inputValue,
          inputCode: inputCodeValue,
        });
        if (res?.code == 200) {
          getTreeData();
        }
      } else {
        getTreeData();
      }
    }
  };

  const renderTreeNodes = (item: any) => {
    if (item.isEditable) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: 1, display: 'flex' }}>
            <Input
              ref={refObj1}
              style={{
                width: 150,
                backgroundColor: '#fff',
                border: '1px solid #ececec',
                marginRight: 4,
              }}
              className="inputField"
              id="inputField"
              onFocus={() => {
                isFocus.current = isFocus.current + 1;
              }}
              onBlur={() => {
                isFocus.current = isFocus.current - 1;
                setTimeout(() => {
                  checkBothBlurred();
                }, 500);
              }}
              autoComplete="off"
              value={inputValue}
              onChange={(e) => {
                setInputCodeValue(askRomanAlphabet(e.target.value));
                setInputValue(e.target.value);
              }}
              autoFocus
            />
            <Input
              style={{
                // width: 50,
                flex: 1,
                backgroundColor: '#fff',
                border: '1px solid #ececec',
              }}
              ref={refObj2}
              onFocus={() => {
                isFocus.current = isFocus.current + 1;
              }}
              onBlur={() => {
                isFocus.current = isFocus.current - 1;
                setTimeout(() => {
                  checkBothBlurred();
                }, 500);
              }}
              value={inputCodeValue}
              className="inputCode"
              id="inputCode"
              onChange={(e) => {
                setInputCodeValue(askRomanAlphabet(e.target.value));
              }}
            />
          </div>
          <div style={{ width: 60 }}>
            <Popover
              content={
                ('' + item?.id)?.includes('create_') ? '删除' : '取消重命名'
              }
            >
              <Icons
                size={16}
                type="icon-cuo"
                style={{ marginLeft: 10, color: '#3276e8' }}
                onClick={() => {
                  if (('' + item?.id)?.includes('create_')) {
                    onDelete(item);
                  } else {
                    onClose(item);
                  }
                }}
              />
            </Popover>

            {!item.parent && (
              <Popover content="保存">
                <Icons
                  size={16}
                  type="icon-wudibaocun"
                  style={{ marginLeft: 10 }}
                  onClick={() => onSave(item)}
                />
              </Popover>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="titleContainer">
          <span className="text_ellipsis" style={{ flex: 1 }}>
            {item.value}
          </span>
          <span className="operationField">
            {!item.parent && (
              <Popover content="重命名">
                <Icons
                  style={{ marginLeft: 10 }}
                  type="icon-wudibianji"
                  onClick={() => onEdit(item)}
                  size={16}
                />
              </Popover>
            )}
            {item.parent && (
              <Popover content="新增">
                <Icons
                  style={{ marginLeft: 10, color: '#3276e8' }}
                  type="icon-tianjia1"
                  size={16}
                  onClick={() => {
                    onAdd(item.key);
                  }}
                />
              </Popover>
            )}
            {!item.parent && (
              <Popover content="删除">
                <Icons
                  style={{ marginLeft: 10 }}
                  size={16}
                  type="icon-wudishanchu"
                  onClick={() => onDelete(item)}
                />
              </Popover>
            )}
          </span>
        </div>
      );
    }
  };

  const reloadDom = (
    <Button icon={<SyncOutlined />} onClick={handleReload}>
      刷新
    </Button>
  );

  const rendModal = () => {
    return (
      <Modal
        open={open}
        centered={true}
        onOk={(e) => {
          e.stopPropagation();
          if (('' + currentItem?.id)?.includes('create_')) {
            onDelete({
              ...currentItem,
              value: inputValue,
              defaultValue: inputValue,
              chiefComplaintName: inputValue,
              inputCode: inputCodeValue,
            });
          } else {
            onClose({
              ...currentItem,
              value: inputValue,
              defaultValue: inputValue,
              chiefComplaintName: inputValue,
              inputCode: inputCodeValue,
            });
          }
          setOpen(false);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
      >
        {!('' + currentItem?.id)?.includes('create_')
          ? '是否确认取消编辑，当前将恢复主诉名称，【确认】点击后，默认恢复名称，【取消】则继续编辑'
          : '是否确认取消编辑，当前将不会保存【确认】点击后，默认不保存，【取消】则继续编辑'}
      </Modal>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <TreeMenu
          id="maintenance_complaint"
          treeData={data}
          headerRender={(reload) => {
            return reloadDom;
          }}
          isTree
          fieldNames={{
            title: 'value',
            key: 'key',
            children: 'children',
            pinyinSearch: 'inputCode',
          }}
          dataRelationFieldNames={{
            id: 'id',
            parentId: 'parentId',
          }}
          onSelect={(_value, tree) => {
            // vsf.refs.departmentTable?.getSearchForm()?.resetFields();
            // if (!_value?.parent && !('' + _value?.id)?.includes('create_')) {
            // }

            props.onChange?.(_value);
          }}
          search={{
            status: true,
            searchPlaceholder: '请输入主诉或简拼',
            searchType: 'static',
            searchMessage: '输入有误，请重新输入',
          }}
          title="数据分类"
          reload={false}
          row={{
            // expand: true,
            extraShow: 'default',
          }}
          treeProps={{
            selectedKeys: selectedKey,
          }}
          titleRender={(v) => {
            return renderTreeNodes(v);
          }}
        ></TreeMenu>
      )}
      {rendModal()}
    </div>
  );
};
export default definePage(Index);

// 主诉维护 失焦
