import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_getByTypeId_fed7b5: {
    method: 'post',
    url: '/api/base-dictionary/get-by-type-id',
    parameterFomatter: (data?: { typeId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_createUpdateBaseCodeType_f84265: {
    method: 'post',
    url: '/api/base-dictionary/create-update-base-code-type',
    parameterFomatter: (data?: {
      btoParam: CreateUpdateBaseCodeTypeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
});
