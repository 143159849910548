import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getMedicalTechnologyChargeTemplateDetailList,
  getMedicalTechnologyChargeTemplateList,
} from './services';
import { TreeMenu } from '@/components';
import {
  Menu,
  Modal,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { getScrollY } from '@/utils';
import { onTableFieldEllipsis, onTableFieldNumber } from '@/utils/setDataUtil';
import { LeftOutlined } from '@ant-design/icons';
import './index.less';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import Tip from '@/pages/Index/components/func/Tip';
import { askFixed, askTotalAmount } from '@/pages/Index/components/func/ask';
import { isObject } from 'lodash';
// 收费模板
const TemplateModalContainer = (props) => {
  // 常量。
  const { ModalProps, setModalProps, onTemplateModalCallback, stores } = props,
    [MenuCurrent, setMenuCurrent] = useState('mail'),
    [template, setTemplate] = useState(),
    [containerMenuCurrent, setContainerMenuCurrent] = useState(true),
    [selectTableData, setSelectTableData] = useState([]);
  // 函数方法。
  const onClos = () => {
      setModalProps({ open: false });
      onChangeStates(true);
    },
    // 模板展示/导入。
    onSelectTemplatesDisplay = () => {
      if (selectTableData?.length <= 0) return message?.error('还未选择模板');
      setContainerMenuCurrent(false);
    },
    onChangeModalOk = async () => {
      if (containerMenuCurrent) return onSelectTemplatesDisplay();
      await vsf?.refs?.TemplateConfirmComponent.getEditForm().validateFieldsReturnFormatValue();
      onClos();
      const paraps = vsf?.refs?.TemplateConfirmComponent?.getValues()?.map(
        (ele) => {
          delete ele?.id;
          let params = {
            TheRowType: 'Temp', // 模板
            itemId: ele?.priceItemId, //项目ID
          };
          if (isObject(ele?.applyDepartmentName)) {
            params.applyDepartmentName =
              ele?.applyDepartmentName?.applyDepartmentName;
            params.applyDepartmentId =
              ele?.applyDepartmentName?.applyDepartmentId;
          }

          if (isObject(ele?.applyStaffName)) {
            params.applyStaffName = ele?.applyStaffName?.staffName;
            params.applyStaffId = ele?.applyStaffName?.staffId;
          }

          if (isObject(ele?.performStaffName)) {
            params.performStaffName = ele?.performStaffName?.staffName;
            params.performStaffId = ele?.performStaffName?.staffId;
          }

          return { ...ele, ...params };
        },
      );
      if (ModalProps?.onTemplateModalCallback) {
        ModalProps?.onTemplateModalCallback(paraps);
      } else {
        onTemplateModalCallback(paraps);
      }
    },
    onMenuChange = (event) => setMenuCurrent(event?.key),
    onChanegTreeMenuSelect = (node) => {
      if (node?.id === template?.id) return setTemplate();
      setTemplate(node);
    },
    onClickTemplateModalTitle = () => onChangeStates(true),
    onChangeStates = (ContainerMenuCurrent) => {
      setContainerMenuCurrent(ContainerMenuCurrent);
      setTemplate();
    };

  // 医生
  const { data: operationDoctorData, run: operationDoctorRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
          {
            nameLike: params?.nameLike ?? '',
          },
        );
      return res?.data?.result?.map?.((ele) => {
        return {
          staffId: ele?.id,
          staffName: ele?.staffName,
        };
      });
    },
    { manual: true },
  );
  useEffect(() => {
    if (ModalProps?.open) {
      return operationDoctorRun?.();
    }
  }, [ModalProps?.open]);
  return (
    <Modal
      title={
        <div id="TemplateModalTitle" onClick={onClickTemplateModalTitle}>
          {containerMenuCurrent ? (
            <span>收费模板</span>
          ) : (
            <>
              <LeftOutlined />
              <span>收费模板导入</span>
            </>
          )}
        </div>
      }
      open={ModalProps?.open}
      width={1369}
      onCancel={onClos}
      onOk={onChangeModalOk}
      okText="导入"
      cancelText="取消"
    >
      <div id="TemplateModalContainer" style={{ height: getScrollY(369) }}>
        <Components
          {...{
            containerMenuCurrent,
            onMenuChange,
            MenuCurrent,
            onChanegTreeMenuSelect,
            template,
            selectTableData,
            setSelectTableData,
            stores,
            operationDoctorData,
          }}
        />
      </div>
    </Modal>
  );
};
// 收费模板contaienr
const Components = (props) => {
  const {
    containerMenuCurrent,
    onMenuChange,
    MenuCurrent,
    onChanegTreeMenuSelect,
    template,
    selectTableData,
    setSelectTableData,
    stores,
    operationDoctorData,
  } = props;
  switch (containerMenuCurrent) {
    case true:
      return (
        <TemplateComponent
          {...{
            onMenuChange,
            MenuCurrent,
            onChanegTreeMenuSelect,
            template,
            selectTableData,
            setSelectTableData,
          }}
        />
      );
    case false:
      return (
        <TemplateConfirmComponent
          {...{ selectTableData, stores, operationDoctorData }}
        />
      );
  }
};
// 确定导入模板
const TemplateConfirmComponent = (props) => {
  // 常量
  const { stores, selectTableData, operationDoctorData } = props,
    { common, user } = stores ?? {},
    { staffInfo } = user ?? {},
    { applicationList, currentApplication } = common ?? {};
  // 方法
  const onTableValue = () =>
      selectTableData?.map((ele) => {
        return {
          ...ele,
          cost: askTotalAmount([[ele?.amount, ele?.price]], 2), // 应收金额
          orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
          applyStaffName: staffInfo?.staffName, // 操作人, // 开单人员（默认取医嘱的开单医生，可修改）
          applyStaffId: staffInfo?.id, // 开单人员id
          performStaffName: staffInfo?.staffName, // 员工姓名
          performStaffId: staffInfo?.id, // Long 录入人
          operatorStaffName: staffInfo?.staffName, // 操作人
          operatorStaffid: staffInfo?.id, // Long 操作人
          performDepartmentId: currentApplication?.department?.id, // 科室主键名称
          applyDepartmentId: currentApplication?.department?.id, // 主键
          applyDepartmentName: currentApplication?.department?.departmentName,
          performDepartmentName: currentApplication?.department?.departmentName, // 科室名称
        };
      }),
    onFieldChange = async (
      key,
      value,
      { amount, price },
      { setFieldValue },
    ) => {
      // 应收金额。
      if (Object.keys(value)?.includes('amount'))
        return setFieldValue?.(
          [key, 'cost'],
          askTotalAmount([[amount, price]], 2),
        );
    };
  return (
    <VSTable
      value={onTableValue}
      id="TemplateConfirmComponent"
      scroll={{ x: 2228 }}
      bordered
      editable={{
        onCanDelete: () => null,
        editType: 'multiple',
        extraOperations: [
          {
            children: <span>删除</span>,
            onClick: (_, node) => {
              Tip({
                content: '确定删除？',
                onOk() {
                  vsf?.refs?.TemplateConfirmComponent?.remove(node);
                },
              });
            },
          },
        ],
        onFieldChange: onFieldChange,
      }}
    >
      <VSTableColumn
        dataIndex={['itemClass']}
        title="类别"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['itemName']}
        title="项目名称"
        valueType="text"
        fieldProps={{}}
        render={(_, { itemName }) => onTableFieldEllipsis(itemName)}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['itemSpecification']}
        title="规格"
        valueType="text"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.CLINIC_ITEM_CLASS_DICT}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="aspirinDateTimePicker"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
      />

      <VSTableColumn
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '数量限制为正整数。',
              type: 'number',
              min: 1,
              max: undefined,
              required: true,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{}}
        preview
        renderFormItem={(_, { record }) => askFixed(record?.price, 2)}
      />

      <VSTableColumn
        dataIndex={['unit']}
        title="单位"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
        editable={false}
      />

      <VSTableColumn
        width={168}
        dataIndex={['cost']}
        title="应收金额"
        valueType="digit"
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['applyStaffName']}
        title="开单医生"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['applyDepartmentName']}
        title="开单科室"
        valueType="selectCode"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['performStaffName']}
        title="执行医生"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        editable={false}
      />
      <VSTableColumn
        dataIndex={['medicalInsuranceType']}
        title="医保等级"
        valueType="select"
        dataSource={[
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]}
        fieldProps={{}}
        editable={false}
      />
      <VSTableColumn
        dataIndex={['insuranceCode']}
        title="医保编码"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { insuranceCode }) => onTableFieldEllipsis(insuranceCode)}
      />
    </VSTable>
  );
};
// 选择模板
const TemplateComponent = (props) => {
  const {
    onMenuChange,
    MenuCurrent,
    onChanegTreeMenuSelect,
    template,
    selectTableData,
    setSelectTableData,
  } = props;
  return (
    <>
      <div className="left">
        <div className="Menu">
          <Menu
            onClick={onMenuChange}
            selectedKeys={[MenuCurrent]}
            mode="horizontal"
            items={[
              {
                label: '科室模板',
                key: 'mail',
              },
              {
                label: '个人模板',
                key: 'app',
              },
            ]}
          />
        </div>
        <div className="container">
          <TreeMenuContainer {...{ MenuCurrent, onChanegTreeMenuSelect }} />
        </div>
      </div>
      <div className="right">
        <div className="table">
          <TableContainer
            {...{ template, selectTableData, setSelectTableData }}
          />
        </div>
      </div>
    </>
  );
};
// 树状下拉选择列表
const TreeMenuContainer = (props) => {
  // 常量
  const { MenuCurrent, onChanegTreeMenuSelect } = props;
  // 常量函数
  const onTreeMenuFetch = async () => {
    const params = {
      personIndicator: MenuCurrent === 'app',
    };
    const res = await getMedicalTechnologyChargeTemplateList(params);
    return res;
  };
  switch (MenuCurrent) {
    case 'mail':
      return (
        <TreeMenuDepartment
          {...{ MenuCurrent, onTreeMenuFetch, onChanegTreeMenuSelect }}
        />
      );
    case 'app':
      return (
        <TreeMenuStaff
          {...{ MenuCurrent, onTreeMenuFetch, onChanegTreeMenuSelect }}
        />
      );
  }
};
// 科室树状下拉选择列表
const TreeMenuDepartment = (props) => {
  const { onTreeMenuFetch, onChanegTreeMenuSelect } = props;
  return (
    <TreeMenu
      loadData={[onTreeMenuFetch]}
      fieldNames={{ title: 'templateName', key: 'id' }}
      search={{
        status: true,
        searchPlaceholder: '请输入模板名称',
      }}
      row={{
        expand: true,
        cancelSelect: true,
      }}
      onSelect={onChanegTreeMenuSelect}
    />
  );
};
// 个人树状下拉选择列表
const TreeMenuStaff = (props) => {
  const { onTreeMenuFetch, onChanegTreeMenuSelect } = props;
  return (
    <TreeMenu
      loadData={[onTreeMenuFetch]}
      fieldNames={{ title: 'templateName', key: 'id' }}
      search={{
        status: true,
        searchPlaceholder: '请输入模板名称',
      }}
      row={{
        expand: true,
        cancelSelect: true,
      }}
      onSelect={onChanegTreeMenuSelect}
    />
  );
};
// 列表详情
const TableContainer = (props) => {
  // 常量
  const { template, selectTableData, setSelectTableData } = props;
  // 常量函数
  const onSelectedRowsChange = (data, keys) => setSelectTableData(data),
    onFetch = useCallback(async () => {
      setSelectTableData([]);
      if (!template?.id) return [];
      const res = getMedicalTechnologyChargeTemplateDetailList({
        templateId: template?.id,
      });
      return res;
    }, [template]);
  return (
    <VSPromiseTable
      editable={{ editType: 'none' }}
      vsid="76829"
      id="inpMedicalTechnologyChargeTemplateDetailList"
      rowSelection={{
        selectType: 'multiple',
        onSelectedRowsChange: onSelectedRowsChange,
        selectedRows: selectTableData,
      }}
      onFetch={onFetch}
      bordered
      pagination={false}
    >
      <VSTableColumn
        dataIndex={['itemClass']}
        title="项目分类"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
      />

      <VSTableColumn
        dataIndex={['itemName']}
        title="项目名称"
        valueType="text"
        fieldProps={{}}
        render={(_, { itemName }) => onTableFieldEllipsis(itemName)}
      />

      <VSTableColumn
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '数据格式错误',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['itemSpecification']}
        title="项目规格"
        valueType="text"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.CLINIC_ITEM_CLASS_DICT}
      />

      <VSTableColumn
        dataIndex={['price']}
        title="价格"
        valueType="digit"
        fieldProps={{}}
        render={(_, { price }) => onTableFieldNumber(price, 2)}
      />

      <VSTableColumn
        dataIndex={['unit']}
        title="单位"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
      />

      <VSTableColumn
        dataIndex={['brand']}
        title="品牌"
        valueType="text"
        fieldProps={{}}
        render={(_, { brand }) => onTableFieldEllipsis(brand)}
      />
    </VSPromiseTable>
  );
};

export default definePage(TemplateModalContainer);
