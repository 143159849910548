import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import {
  Button,
  Space,
  VSFormItem,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  Table,
  List,
  Row,
  Col,
  VSForm,
  Tooltip,
} from '@vs/vsf-kit';
import { getScrollY } from '@/utils';
import ComplexSelect from '@/pages/Index/components/complex_select';
import SkinTestVsDrugSettingList from './SkinTestVsDrugSettingList';
import { useMount, useRequest } from 'ahooks';
import Loading from '@/module/Loading';
function index() {
  const convertQueryAllByQuerySkinTestDrugQtoRes2TableSkinTestVsDrugSettingRo =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ..._.get(input, 'data.result').map((d) => {
          const obj = {};
          obj.drugProductName = _.get(d, 'drugProductName');
          obj.drugSpecification = _.get(d, 'drugSpecification');
          obj.firmName = _.get(d, 'drugFirmInfo.firmName');
          obj.drugProductId = _.get(d, 'id');
          // obj.drugProductName=_.get(d,)
          return obj;
        }),
      );
      return output;
    };

  const { data, loading, run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.SkinTestDrugManageController_queryAllByQuerySkinTestDrugQto_f7cbc3?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: { ...params },
            ext: {},
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // TODO: 你需要自己补充入参input来源及出参output用法
      const output =
        convertQueryAllByQuerySkinTestDrugQtoRes2TableSkinTestVsDrugSettingRo(
          res,
        );
      if (!data) {
        setTableParam(output[0]);
      }
      // if(!res?.result){
      //   return []
      // }
      return output;
    },
    {
      manual: true,
    },
  );

  useMount(() => {
    run();
  });

  const [selectFlag, setSelectFlag] = useState(0);

  const Selected = ({ recode, onChange, form, value, tableParamChange }) => {
    const [dataSource, setDataSource] = useState([]);

    useMount(() => {
      getDataSource();
    });

    const { loading, run: getDataSource } = useRequest(
      async (drugProductName) => {
        const res =
          await vsf.services?.SkinTestDrugManageController_queryAllByQuerySkinTestDrugQto_f7cbc3?.(
            {
              qto: {
                inputCodeLike: drugProductName,
              },
            },
          );

        setDataSource(
          res.data?.result?.map((item, index) => {
            return { key: index, ...item };
          }),
        );
      },
      {
        debounceWait: 500,
        manual: true,
      },
    );

    const columns = [
      {
        title: '药品名称',
        dataIndex: ['drugProductName'],
        key: 'drug_name',
      },
      {
        title: '包装规格',
        dataIndex: ['drugSpecification'],
        key: 'package_specification',
      },

      {
        title: '厂家',
        dataIndex: ['drugFirmInfo', 'firmName'],
        key: 'firm_name',
      },
    ];

    return (
      <ComplexSelect
        hideSelectIcon
        mode="radio"
        showLabel="drugName"
        value={value?.label ?? value}
        onChange={(value) => {
          onChange({
            label: value?.drugProductName,
            value: value?.id,
          });
        }}
        onSearch={(name) => {
          // if (name.trim()) {
          getDataSource(name);
          // }
        }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          width: 700,
        }}
        listHeight={1000}
      >
        <Table
          rowClassName="aspirin-table-row-box"
          columns={columns}
          loading={{
            indicator: (
              <Row
                justify="center"
                style={{
                  left: '40%',
                }}
              >
                <Col span={24}>
                  <Loading />
                </Col>
              </Row>
            ),
            spinning: loading,
          }}
          scroll={{
            y: 400,
          }}
          dataSource={dataSource}
          pagination={{ total: dataSource?.count }}
        />
      </ComplexSelect>
    );
  };
  const [tabaleParam, setTableParam] = useState();
  const tableParamChange = (p) => {
    setTableParam(p);
  };
  return (
    <div className="backing-layout">
      <div className="left">
        <div>
          <Space size={4}>
            <VSForm id="drugSelectForm">
              <VSFormItem
                name={'drugName'}
                valueType="costom"
                label="药品名"
                style={{ width: '20rem' }}
              >
                <Selected tableParamChange={tableParamChange} />
              </VSFormItem>
            </VSForm>
            <Button
              children="重置"
              onClick={() => {
                vsf?.refs?.drugSelectForm?.resetFields();
                run();
              }}
              type="primary"
            />

            <Button
              children="查询"
              type="primary"
              onClick={() => {
                const file = vsf?.refs?.drugSelectForm?.getFieldsValue(true);
                // tableParamChange(file)
                console.log(file);
                setTableParam({ drugProductId: file?.drugName?.value });
                run({ idIs: file?.drugName?.value });
              }}
            />
          </Space>
        </div>
        <List>
          <List.Item>
            <div className="belt-list-lable-font">药品名称</div>
            <div className="belt-list-lable-font">规格</div>
            <div className="belt-list-lable-font">厂家</div>
          </List.Item>
        </List>
        <div style={{ overflow: 'auto', height: '100%' }}>
          <List loading={loading}>
            <div style={{ overflow: 'auto' }}>
              {data &&
                data?.map((item, index) => (
                  <List.Item
                    onClick={() => {
                      setSelectFlag(index);
                      setTableParam(item);
                      //取消选中
                      // if (selectFlag === index) {
                      //   setSelectFlag(undefined);
                      // }
                    }}
                    style={{ paddingLeft: '5px' }}
                    className={index === selectFlag ? 'select' : ''}
                  >
                    <div className="list-cell">
                      <Tooltip title={item?.drugProductName}>
                        {item?.drugProductName}
                      </Tooltip>
                    </div>
                    <div className="list-cell">
                      <Tooltip title={item?.drugSpecification}>
                        {item?.drugSpecification}
                      </Tooltip>
                    </div>
                    <div className="list-cell">
                      <Tooltip title={item?.firmName}>{item?.firmName}</Tooltip>
                    </div>
                  </List.Item>
                ))}
            </div>
          </List>
        </div>
      </div>
      <div className="right">
        <SkinTestVsDrugSettingList tabaleParam={tabaleParam} />
      </div>
    </div>
  );
}

export default index;
