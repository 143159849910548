import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamController_getAllByExamClassItemClassQto_4b0f3f: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-item-class-qto',
    parameterFomatter: (data?: { qto: ExamClassItemClassQtoExamPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  ExamController_getAllByExamVsClinicItemDictionaryQto_52658d: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-vs-clinic-item-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ExamVsClinicItemDictionaryQtoExamPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamVsClinicItemDictionaryVoExamEntranceWebVo[],
    ) => data,
  },
  ExamController_getExamVsDepartmentByExamClassId_86c409: {
    method: 'post',
    url: '/api/exam/get-exam-vs-department-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamVsDepartmentVoExamEntranceWebVo[]) =>
      data,
  },
});
