import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Image,
  Input,
  RadioGroup,
  Select,
  Tooltip,
  VSForm,
  VSFormItem,
  VSFormLayout,
  message,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { debounce, throttle } from 'lodash';

import { Screen } from '@/pages/Index/components';
import { CreateScreen } from '@/pages/Index/components/screen';
import { filterEmptyValues } from '@/utils/setDataUtil';
import { items } from '@/pages/Index';
import Icon from '@/module/Icon';
import readCard from '@/assets/icon/readCard.png';
import vsf, { definePage } from '@vs/vsf-boot';

import { onFetchForm } from './service.ts';
import OutpDispenseRefundApplyTable from './table';

import './index.less';
import Tip from '@/pages/Index/components/func/Tip';

const RadioMenu = [
  {
    label: '全部',
    value: 'ALL',
  },
  {
    label: '待发药',
    value: 'NOT_DISPENSE',
  },
  {
    label: '已发药',
    value: 'FINISH_DISPENSE',
  },
];

const RefundDrugApply = definePage((props) => {
  const replaceInDispenseDetail = (parentArray, targetArray) => {
    return parentArray.map((parentItem) => {
      // 判断当前项是否有 dispenseDetail 属性
      if (
        parentItem.dispenseDetail.length > 0 &&
        Array.isArray(parentItem.dispenseDetail)
      ) {
        // 在 dispenseDetail 中过滤出等于 targetArray 的项并替换
        const updatedDispenseDetail = parentItem.dispenseDetail.map(
          (detailItem) => {
            return (
              targetArray.find(
                (targetItem) =>
                  JSON.stringify(targetItem) === JSON.stringify(detailItem),
              ) || detailItem
            );
          },
        );

        // 返回更新后的对象
        return { ...parentItem, dispenseDetail: updatedDispenseDetail };
      } else {
        // 如果没有 dispenseDetail 属性，直接返回原对象
        return parentItem;
      }
    });
  };
  const onSreen = useContext(CreateScreen);

  //主索引查询接口
  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
      }

      return res?.data?.result;
    },
    { manual: true },
  );
  const selectTableMyRef = useRef();
  const [activityDispenseStatus, setActivityDispenseStatus] = useState('ALL');
  const [fetchParams, setFetchParams] = useState();

  const selectParamChange = (param) => {
    selectTableMyRef.current = param;
  };

  const refundReasonRef = useRef();

  const refundChangeMyData = useRef();

  const handelSearch = async () => {
    const searchParams = await filterEmptyValues(
      vsf.refs?.RefundDrugApplySearchForm?.getFieldsValue?.(),
    );
    // console.log('--------------======liuyu');
    // console.log(searchParams);

    const param = {
      ...searchParams,
    };
    if (!isNaN(searchParams?.submitDateTimeBiggerThanEqual[0])) {
      if (searchParams?.submitDateTimeBiggerThanEqual) {
        param.submitDateTimeBiggerThanEqual = dayjs(
          searchParams?.submitDateTimeBiggerThanEqual[0],
        ).format('YYYY-MM-DD HH:mm:ss');

        param.submitDateTimeLessThan = dayjs(
          searchParams?.submitDateTimeBiggerThanEqual[1],
        ).format('YYYY-MM-DD HH:mm:ss');
      }
    } else {
      delete param?.submitDateTimeBiggerThanEqual;
    }
    if (searchParams?.prescriptionNumberIs) {
      param.prescriptionNumberIs = Number(searchParams?.prescriptionNumberIs);
    }

    if (searchParams?.patientIdIs) {
      patientDetallRun({
        displayIdIs: searchParams?.patientIdIs,
      }).then((item) => {
        param.patientIdIs = item[0]?.id;
        setFetchParams(filterEmptyValues(param));
      });
    } else {
      setFetchParams(filterEmptyValues(param));
    }
  };
  // debounce(, 1000)

  const [orderText, setOrderText] = useState('');

  const handelSubmit = async () => {
    if (!refundReasonRef.current?.value) return message.error('请填写退药原因');
    Tip({
      content: '是否确认退药！',
      onOk: async () => {
        const topTable = vsf.refs.tablePrescList?.getValues() ?? [];

        const arr = [];
        refundChangeMyData.current?.map((i) => {
          i?.dispenseDetail?.map((item) => {
            arr.push(item);
          });
        });

        const tableParam = topTable?.map((item) => {
          if (
            refundChangeMyData.current?.find(
              (obj) => obj?.masterId === item?.masterId,
            )
          ) {
            const dispenseDetail = item?.dispenseDetail?.map((item) => {
              return arr?.find((obj) => item?.id == obj?.id);
            });
            return { ...item, dispenseDetail: dispenseDetail };
          } else {
            return item;
          }
        });
        const param = (tableParam ?? topTable ?? [])
          .filter((m) =>
            (m.dispenseDetail ?? []).some((o) => o.refundAmount > 0),
          )
          .map((m, index) => {
            m.dispenseDetail = m.dispenseDetail.filter(
              (o) => o.refundAmount > 0,
            );
            m.refundReason = refundReasonRef.current?.value ?? undefined;
            return { ...m };
          });

        if (param.length <= 0) return message.error('请双击药品输入退药数量！');
        const res =
          await vsf.services?.DrugPrescriptionRefundDispenseController_applyRefund_775a7b?.(
            {
              applys: param,
            },
          );
        if (res && res.code === 200) {
          setOrderText('');
          refundReasonRef.current.value = '';
          message.success('退药申请成功');
        }
      },
    });
    return;
  };

  const onFetchForms = useCallback(
    () => onFetchForm(fetchParams),
    [fetchParams],
  );

  return (
    <div className="refund_drug_apply_layout">
      <div className="header">
        <div>
          <VSForm id="RefundDrugApplySearchForm" layout="inline">
            <VSFormItem
              name={['patientIdIs']}
              label="患者ID"
              valueType="text"
              fieldProps={{
                onPressEnter: (e) => handelSearch(), //1000014801
                className:'patientIdIs',
                suffix: (
                  <div
                    className="suffix-readCard"
                    onClick={() => {
                      message.success('读卡中');
                    }}
                  >
                    <div className="suffix-cardImg">
                      <Image
                        src={readCard}
                        className='suffix-cardImg-image'
                        preview={false}
                      ></Image>
                    </div>
                    <div className="suffix-cardText">读卡</div>
                  </div>
                ),
                placeholder: '请读卡',
              }}
            />
            <VSFormItem
              name={['prescriptionNumberIs']}
              label="处方号"
              valueType="text"
              fieldProps={{
                className: 'width240'
              }}
            />
            <VSFormItem
              name={['submitDateTimeBiggerThanEqual']}
              label="日期"
              valueType="dateRange"
              fieldProps={{
                className: 'width240',
                format: 'YYYY-MM-DD',
              }}
              initialValue={[dayjs().startOf('day'), dayjs().endOf('day')]}
            />
            <Button type="primary" onClick={handelSearch}>
              查询
            </Button>
          </VSForm>
        </div>
        <div>
          <VSForm
            vsid="81530"
            id="formPatInfo3"
            readonly={true}
            onFetch={onFetchForms}
          >
            <VSFormLayout key="0" columnCount={3}>
              <VSFormItem
                name={['displayId']}
                label="患者ID"
                valueType="text"
                rules={[
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['name']}
                label="姓名"
                valueType="text"
                rules={[
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['age']}
                label="年龄"
                valueType="text"
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={3}>
              <VSFormItem
                name={['cellphone']}
                label="手机号"
                valueType="text"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['idNumber']}
                label="身份证号"
                valueType="text"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    pattern:
                      /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['phoneNumber']}
                label="电话"
                valueType="text"
                rules={[
                  {
                    message: '联系电话长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      </div>
      <div className="center">
        <div className="filter_view">
          <RadioGroup
            value={activityDispenseStatus}
            optionType="button"
            dataSource={RadioMenu}
            onChange={(v) => setActivityDispenseStatus(v)}
          />
          <Tooltip title="药品名称输入完成后，请按下回车键">
            <Input
              width={280}
              placeholder="请输入药品名称"
              addonBefore={<Icon type="icon-sousuo" size={16} />}
              onKeyDown={(event) => {
                switch (event?.code) {
                  case 'Enter':
                    return setOrderText(event?.target?.value);
                  default:
                    return;
                }
              }}
            />
          </Tooltip>
        </div>
        <div className="content">
          <div className="left_table">
            <OutpDispenseRefundApplyTable
              dispenseStatus={activityDispenseStatus}
              searchParams={fetchParams}
              callback={(param) => {
                refundChangeMyData.current = param;
                // setChageData(param);
              }}
              selectParamChange={selectParamChange}
              orderText={orderText}
            />
          </div>
          <div className="right_text">
            <div className="label_header">
              <span className="title">
                <span className="icon_label">*</span>退药原因：
              </span>
              <span className="label">请在左侧列表输入退药数量</span>
            </div>
            <textarea
              ref={refundReasonRef}
              className="textarea_comp"
              // onChange={(e) => (refundReasonRef.current = e.target.value)}
            />
          </div>
        </div>
        <div className="footer">
          <Button
            onClick={onSreen}
            style={{
              color: '#3276E8',
              marginRight: 16,
              borderColor: '#3276E8',
            }}
          >
            清屏
          </Button>
          <Button type="primary" onClick={handelSubmit}>
            确认退药
          </Button>
        </div>
      </div>
    </div>
  );
});

const RefundDrugApplyScreen = (props) => {
  return (
    <Screen>
      <RefundDrugApply {...props} />
    </Screen>
  );
};

export default RefundDrugApplyScreen;
