import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import CommonTable from './CommonTable';
import { getScrollY } from '@/utils';
const TableModal = (props) => {
  const { TableModalProps, setTableModalProps } = props;
  const {
    onButtonProps,
    onButton,
    selectedRows,
    setSelectedRows,
    table__id,
    onFetch,
    applyDepartment,
  } = props; //TableModalProps;
  const onClose = () => {
      setTableModalProps({
        open: false,
      });
    },
    onResetData = () => {};
  const onTitle = () => {
    let str;
    if (TableModalProps?.title) str = `（${TableModalProps?.title}）`;
    return str;
  };
  return (
    <Modal
      title={
        <span style={{ color: 'rgba(50, 118, 232, 1)' }}>
          费用明细{onTitle()}
        </span>
      }
      open={TableModalProps?.open}
      onCancel={onClose}
      width={'70%'}
      footer=""
    >
      <CommonTable
        {...{
          onButtonProps,
          onButton,
          selectedRows,
          setSelectedRows,
          table__id,
          onFetch,
          applyDepartment,
          // yMaxHeight,
        }}
      />
      {/* 费用明细 */}
    </Modal>
  );
};

export default definePage(TableModal);
