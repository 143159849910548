import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderController_cancelSubmitOrder_28057d: {
    method: 'post',
    url: '/api/clinic-record-order/cancel-submit-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_deleteOrder_0de5cf: {
    method: 'post',
    url: '/api/clinic-record-order/delete-order',
    parameterFomatter: (data?: { orderId: number; deleteGroup: boolean }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_saveOrder_c9c63c: {
    method: 'post',
    url: '/api/clinic-record-order/save-order',
    parameterFomatter: (data?: {
      order: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_submitOrder_89360b: {
    method: 'post',
    url: '/api/clinic-record-order/submit-order',
    parameterFomatter: (data?: {
      order: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_getOrderDetailById_6f461b: {
    method: 'post',
    url: '/api/clinic-record-order/get-order-detail-by-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo,
    ) => data,
  },
});
