import {
  Divider,
  Form,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import { useSetState, useUpdateEffect } from 'ahooks';
import React, { useCallback, useEffect, useRef } from 'react';

import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';
import int from '../../components/int';
import ExamItemEdit from '../edit';
import Screen from '@/pages/Index/components/screen';
import TableRender from '../../components/ComparePrice';
import { debounce } from 'lodash';
import { askFixed, askFlatTree } from '@/pages/Index/components/func/ask';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { Flex } from '@/pages/Index/components';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';

import { getScrollYRem } from '@/utils';

const ExamItemFunc = (props) => {
  const [dataStore, , getDataStore, , , setDataStore] = useGetResetInitState({
    initialState: [],
    data: [],
    id: {
      stopIndicator: true,
      auditIndicator: true,
    },
    dataSource: [],
  });

  const [scrollTop, setScrollTop] = useSetState({ x: 2000 });

  const table = useRef(null);
  const stopIndicatorIs = Form.useWatch(
    'stopIndicatorIs',
    table.current?.getSearchForm?.(),
  );
  const auditIndicatorIs = Form.useWatch(
    'auditIndicatorIs',
    table.current?.getSearchForm?.(),
  );

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ClinicItemBaseController_getAllByClinicItemExamNewQtoByClinicItemExamNewQto_a2cd1a?.(
          {
            qto: {
              itemClassIs: 'PATHOLOGY',
              orderList: params.orderList, // 排序结果
              ...params.search, // 搜索结果
              ...(props.data?.id
                ? { classIdIn: askFlatTree([props.data], 'id') }
                : {}),
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              stopIndicatorIs,
              auditIndicatorIs,
            },
          },
        );
      if (res.code === 200) {
        const id = res?.data?.find(
          (item) => item?.id === getDataStore()?.id?.id,
        );
        if (id) {
          setDataStore({
            id,
          });
        }
      }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props.data, stopIndicatorIs, auditIndicatorIs],
  );

  const TableSubmit = async ({ params, url, bto, success }) => {
    try {
      const {
        examVsClinicItemDetailDto: {
          examItemExtensionBtoList: extension,
          examClass: { id: examClassId },
          examSubClass: { id: examSubClassId },
          examThirdClass: third,
          ...clinic
        },
        ...rest
      } = params;
      const res = await vsf?.services?.[url]?.({
        btoParam: {
          ...rest,
          itemClass: 'PATHOLOGY',
          branchInstitutionIdList: extension?.select ?? [],
        },
        [bto]: {
          ...clinic,
          examClassId,
          examSubClassId,
          examThirdClassId: third?.id ?? null,
          ...(extension.form
            ? {
                /**
                 * 院区
                 */
                examItemExtensionBtoList:
                  Object.values(extension?.form ?? [])?.map((item) => ({
                    ...item,
                    /**
                     * 判断有没有预约方式, 没有设为null
                     */
                    appointMethod: item.appointMethod ?? null,
                    branchInstitutionId: item?.value,
                  })) ?? [],
                /**
                 * 科室
                 */
                examItemVsDepartmentBtoList: extension?.form
                  ? Object.values(extension?.form)?.flatMap((item) => {
                      if (item?.examItemVsDepartmentBtoList) {
                        return (
                          item?.examItemVsDepartmentBtoList?.map(
                            (department) => ({
                              departmentId: department?.value ?? department,
                            }),
                          ) ?? []
                        );
                      }
                      return [];
                    })
                  : [],
              }
            : {}),
        },
      });
      if (res.code === 200) {
        message.success(success);
        vsf.refs.ExamItem.reload().then(() => {
          if (typeof res.data === 'number') {
            setDataStore({
              id: vsf.refs?.ExamItem?.getValues().filter(
                (item) => res.data === item.id,
              )?.[0],
            });
          }
        });
      }
      return res?.data;
    } catch (error) {
      console.error(error);
    }
  };

  const onAdd = (params) => {
    TableSubmit({
      params,
      url: 'ClinicItemBaseController_createClinicExamItem_dce650',
      bto: 'createExamItemBto',
      success: '添加病理项目成功',
    });
  };

  useUpdateEffect(() => {
    setDataStore({
      id: {
        stopIndicator: true,
        auditIndicator: true,
      },
    });
  }, [props.data]);

  const onUpdate = (params) => {
    TableSubmit({
      params,
      url: 'ClinicItemBaseController_updateClinicExamItem_dec283',
      bto: 'updateExamItemBto',
      success: '修改病理项目成功',
    });
  };
  const top = useRef(null);
  useEffect(() => {
    setScrollTop({
      y: top.current?.offsetHeight - 88,
    });
  }, [top]);

  return (
    <VSPromiseTable
      id="ExamItem"
      editable={{
        editType: 'single',
        extraOperations: [
          {
            children: (node) => {
              return (
                <Icon
                  type={
                    node.stopIndicator ? 'icon-a-bianjijinyong' : 'icon-bianji'
                  }
                  size={24}
                />
              );
            },
            show: (node) => node.stopIndicator,
            disabled: (node) => node.stopIndicator,
            order: 1,
          },
          {
            children: (node) => {
              return (
                <Icon
                  type={
                    node.stopIndicator
                      ? 'icon-a-jinyongjinyong'
                      : 'icon-tingyong'
                  }
                  size={24}
                />
              );
            },
            disabled: (node) => node.stopIndicator,
            onClick: (event, node) => {
              event.stopPropagation();
              Tip({
                content: `确定停用{【${node.clinicItemName}】}项目？停用后不可恢复`,
                onOk: async () => {
                  const res =
                    await vsf?.services?.ClinicItemBaseController_stopClinicItem_49a98d?.(
                      {
                        id: node.id,
                      },
                    );
                  if (res.code === 200) {
                    vsf.refs.ExamItem.reload();
                  }
                },
              });
            },
            order: 1,
          },
        ],
        editText: <Icon type="icon-bianji" size={24} />,
        onCanDelete: () => null,
        onCanEdit: (node) => !node.stopIndicator,
        columnProps: {
          width: 150,
        },
      }}
      ref={table}
      vsid="65825"
      onFetch={onFetch}
      style={{
        // maxHeight: '100%',
        maxHeight: getScrollYRem(16),
      }}
      scroll={scrollTop}
      pagination={false}
      rowClassName={(...arr) => {
        const [recode] = arr;
        return `aspirin-vs-table-row-box ${
          recode?.id === dataStore?.id?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box'
        }`;
      }}
      rowClick={{
        rowClickType: 'select',
        onRowClick: (data) => {
          setDataStore({
            id: data,
          });
        },
      }}
      className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
      searchFormRender={(props, dom) => (
        <div className="aspirin-table-search-inline-no">
          <SearchContainer mode="inline" formPadding="0 170px 0 100px">
            {dom}
          </SearchContainer>
          <Divider />
        </div>
      )}
      tableRender={(_, dom) => {
        return (
          <TableRender
            value={dataStore?.id}
            clinicItemId={dataStore.id?.examVsClinicItemDetailDto?.clinicItemId}
            // height={window.innerHeight - 410}
            height={getScrollYRem(24)}
            reload={vsf.refs?.ExamItem?.reload}
            onDragMove={debounce((value) => {
              setScrollTop({
                y: value - 108,
              });
            }, 500)}
          >
            <div className="aspirin-compare-price-top-box" ref={top}>
              {dom}
            </div>
          </TableRender>
        );
      }}
      searchConfig={{
        // labelWidth: 100,
        labelWidth: '20%',
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        optionRender: (...arr) => {
          const [, , [other, search]] = arr;
          return (
            <SearchContainer.Header title="筛选">
              {[
                other,
                <ButtonAndKeyDown
                  methodKey="altKey"
                  ordinaryKey="Q"
                  {...search?.props}
                >
                  查询
                </ButtonAndKeyDown>,
              ]}
            </SearchContainer.Header>
          );
        },
      }}
      onAdd={onAdd}
      onUpdate={onUpdate}
      addLinkPage={{
        linkPage: () => <ExamItemEdit data={props.value} active={props.data} />,
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.ExamForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        },
        modalProps: {
          title: '新增病理项目',
          className: 'aspirin-linkpage',
          width: '50%',
          bodyStyle: {
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
      }}
      updateLinkPage={{
        modalProps: {
          title: '编辑病理项目',
          className: 'aspirin-linkpage',
          width: '50%',
          bodyStyle: {
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
        linkPage: () => <ExamItemEdit data={props.value} active={props.data} />,
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.ExamForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
      }}
      rowSelection={{
        onSelectedRowsChange: (_value) => {
          props.onChange?.(_value);
        },
      }}
    >
      <VSTableColumn
        dataIndex={['examVsClinicItemDetailDto', 'examClass', 'examClassName']}
        title="病理大类"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={[
          'examVsClinicItemDetailDto',
          'examSubClass',
          'examClassName',
        ]}
        title="病理子类"
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />

      <VSTableColumn
        dataIndex={[
          'examVsClinicItemDetailDto',
          'examThirdClass',
          'examClassName',
        ]}
        title="病理小类"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['clinicItemName']}
        title="病理项目"
        valueType="text"
        search
        width="auto"
        order={-1}
        columnKey={'clinicItemNameLike'}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['costs']}
        title="合计金额"
        valueType="custom"
        fieldProps={{}}
        render={(...[, record]) => {
          return (
            <Flex justify="flex-end">
              {int(record?.clinicVsChargeDtoList)
                ? '实时计算'
                : askFixed(record?.costs)}
            </Flex>
          );
        }}
      />

      <VSTableColumn
        dataIndex={['branchInstitution']}
        title="院区"
        valueType="text"
        fieldProps={{}}
        width="auto"
        ellipsis={{
          showTitle: true,
        }}
      />

      <VSTableColumn
        dataIndex={['stopIndicator']}
        title="停用标记"
        valueType="select"
        search
        searchFieldProps={{
          dataSource: [
            {
              label: '已停用',
              value: true,
            },
            {
              label: '未停用',
              value: false,
            },
          ],
        }}
        order={-1}
        columnKey={'stopIndicatorIs'}
        render={(_, data) => {
          return (
            <span
              style={{ color: data.stopIndicator ? `var(--red)` : 'inherit' }}
            >
              {data.stopIndicator ? '已' : '未'}停用
            </span>
          );
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['auditIndicator']}
        title="审核标记"
        valueType="select"
        search
        order={-1}
        columnKey={'auditIndicatorIs'}
        render={(_, data) => {
          return (data.auditIndicator ? '已' : '未') + '审核';
        }}
        searchFieldProps={{
          dataSource: [
            {
              label: '已审核',
              value: true,
            },
            {
              label: '未审核',
              value: false,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['lastModifyStaff', 'staffName']}
        title="最近操作人"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['lastModifyDate']}
        title="最近操作日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['auditStaff', 'staffName']}
        title="审核人"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['auditDate']}
        title="审核日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableToolbar
        title="病理项目列表"
        className="aspirin-toolbar-padding-top-box"
      >
        <VSTableAddition
          children="添加"
          disabled={
            !(
              props.data &&
              props?.data?.parentExamClassId &&
              !props?.data?.children?.length
            )
          }
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};

const ExamItem = (props) => {
  return (
    <Screen>
      <ExamItemFunc {...props} />
    </Screen>
  );
};

export default definePage(ExamItem);
