import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Tag,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { mockDoctorId } from '@/mock';
import Icon from '@/module/cpoe/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import Tip from '@/pages/Index/components/func/Tip';
import AllergyTable from '@/pages/Index/cpoe/center/left/previous_history/allergy_table';
import DiseaseTable from '@/pages/Index/cpoe/center/left/previous_history/disease_table';
import FamilyTable from '@/pages/Index/cpoe/center/left/previous_history/family_table';
import OperationTable from '@/pages/Index/cpoe/center/left/previous_history/operation_table';
import { getScrollY } from '@/utils';

// 既往史
const blockTitleStyle = {
  color: '#343B42',
  fontWeight: 600,
};

const Index = (props) => {
  const { user, common } = props?.stores ?? {};
  const { initKey, currentPatient, staffInfo, outVisitInfo, setInitKey } =
    user ?? {};
  const _patientId = currentPatient?.patientId;
  const { setCurrentApplication, currentApplication, setApplicationList } =
    common || {};

  // 过敏史
  const getPatientAllergyList = async (params) => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getPatientAllergyByPatientId_576705?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  // 疾病史
  const getDiseasesHistoryList = async (params) => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getDiseaseHistoryByPatientId_81fac3?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  // 疾病史名称
  const getDiseaseName = async () => {
    const res =
      await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          qto: {
            from: 0,
            size: 100,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      const _res = res?.data?.result?.map((item) => {
        return { label: item?.diagnosisName, value: String(item?.id) };
      });
      return _res;
    }
  };
  // 手术史
  const getOperationList = async (params) => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getOperationHistoryByPatientId_cfbfba?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  // 家族史
  const getFamilyList = async () => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getFamilyHistoryByPatientId_58159e?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  const renderOption = (data) => {
    return (
      <div className="previous_history_option">
        <div className="previous_history_option_item">
          <Icon type="icon-Frame-21" size={18} />
        </div>
        <div>
          <Icon type="icon-shanchuwudi" size={18} />
        </div>
      </div>
    );
  };
  // 过敏类型
  const renderAllergyType = (dom, value) => {
    // const allergyTypeEnum = vsf.stores.dbenums.enums.ALLERGY_TYPE_DICT;
    if (value?.notAllergyIndicator) {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_cure',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '01') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_medicine',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '02') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_food',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '03') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_environment',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '04') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_max',
          )}
        >
          {dom}
        </div>
      );
    } else if (value?.allergyType === '09') {
      return (
        <div
          className={className(
            'previous_history_allergys_type',
            'previous_history_allergys_type_other',
          )}
        >
          {dom}
        </div>
      );
    }
  };
  // 不在过敏\疾病
  const renderAllergyText = (dom, value) => {
    return <div style={{ color: value ? '#85898D' : '#343B42' }}>{dom}</div>;
  };

  const renderDiseasType = (dom, value) => {
    if (!value?.chronicAcuteDisease) return;
    if (value?.cureIndicator) {
      return (
        <div
          className={className(
            'previous_history_diseas_type',
            'previous_history_diseas_type_cure',
          )}
        >
          {dom}
        </div>
      );
    } else {
      return (
        <div
          className={className(
            'previous_history_diseas_type',
            value?.chronicAcuteDisease === 'ACUTE_DISEASE'
              ? 'previous_history_diseas_type_anxious'
              : 'previous_history_diseas_type_slow',
          )}
        >
          {dom}
        </div>
      );
    }
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className="previous_history hideScroll"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // marginBottom: '16px',
          // height: '400px',
          height: '50%',
        }}
      >
        <div className="previous_history_list">
          <AllergyTable />
        </div>
        <div className="previous_history_list ">
          <DiseaseTable />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // height: '400px',
          height: '50%',
        }}
      >
        <div className="previous_history_list">
          <OperationTable />
        </div>
        <div style={{ marginBottom: '0px' }} className="previous_history_list">
          <FamilyTable />
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
