import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Tabs,
  VSForm,
  VSFormItem,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Container from '@/module/Container';
// import Modal from '@/pages/Index/manage/register_information/modal';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

import {
  convertQueryAllGreenWaitByErpVisitForPreExamPatientListQtoRes2ErpGreenWaitPatientList,
  convertQueryAllWaitTransferByErpVisitForPreExamPatientListQtoRes2ErpWaitTransferPatientList,
  convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList,
  convertQueryErpPreExamBedSearchWithPatientQtoRes2RescuePatientList,
  convertSpecialPatientMemoForm2UpdateErpExamRecordMasterForSpecialPatientReq,
} from '../convert';
import ListItem from './list_item';

const Index = (props, ref) => {
  const { rowOnChange, searchParams } = props;
  const [list, setList] = useState<any>();
  const [currentSelectPatient, setCurrentSelectPatient] = useState();
  // 当前tab
  const [currentTab, setCurrentTab] = useState('1');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // 刷新
  const [reload, setReload] = useState(0);
  //  待诊：当前选中行spacicalId特殊患者
  //  待入：当前选中行erpVisitId
  //  待转：当前选中行erpVisitId
  const [currentSpacicalId, setCurrentSpacicalId] = useState<any>();

  const searchRef = useRef<any>();

  // 待诊ok
  const cloumn1 = [
    {
      label: '级别',
      width: 'width60',
      value: 'diseaseLevelName',
      // type: 'select',
      // dataSource: vsf.stores.dbenums?.enums?.SEX_DICT ?? [],
    },
    {
      label: '姓名',
      width: 'width120',
      value: 'name',
    },
    {
      label: '患者ID',
      width: 'width140',
      value: 'displayId',
    },
    {
      label: '性别',
      width: 'width80',
      value: 'gender',
      type: 'select',
      dataSource: vsf.stores.dbenums?.enums?.SEX_DICT ?? [],
    },
  ];
  // 特殊(todo)
  const cloumn2 = [
    {
      label: '级别',
      width: 'width60',
      value: 'level',
    },
    {
      label: '姓名',
      width: 'width80',
      value: 'name',
    },
    {
      label: '患者ID',
      width: 'width80',
      value: 'id',
    },
    {
      label: '备注',
      width: 'width80',
      value: 'sex',
    },
  ];
  // 床位 ok
  const cloumn3 = [
    {
      label: '床号',
      width: 'width60',
      value: 'bedLabel',
    },
    {
      label: '姓名',
      width: 'width60',
      value: 'patientName',
    },
    {
      label: '年龄',
      width: 'width80',
      value: 'patientAge',
    },
    {
      label: '性别',
      width: 'width80',
      value: 'patientGender',
      type: 'select',
      dataSource: vsf.stores.dbenums?.enums?.SEX_DICT ?? [],
    },
    {
      label: '患者ID',
      width: 'width100',
      value: 'patientDisplayId',
    },
  ];
  // 待入ok
  const cloumn4 = [
    {
      label: '级别',
      width: 'width60',
      value: 'diseaseLevelName',
      // type: 'select',
      // dataSource: vsf.stores.dbenums?.enums?.SEX_DICT ?? [],
    },
    {
      label: '姓名',
      width: 'width120',
      value: 'name',
    },
    {
      label: '患者ID',
      width: 'width140',
      value: 'displayId',
    },
    {
      label: '性别',
      width: 'width80',
      value: 'gender',
      type: 'select',
      dataSource: vsf.stores.dbenums?.enums?.SEX_DICT ?? [],
    },
  ];
  // 待转区 ok
  const cloumn = [
    {
      label: '级别',
      width: 'width60',
      value: 'level',
    },
    {
      label: '姓名',
      width: 'width80',
      value: 'name',
    },
    {
      label: '患者ID',
      width: 'width80',
      value: 'displayId',
    },
    {
      label: '转入区域',
      width: 'width80',
      value: 'toTreatAreaCode',
      type: 'select',
      dataSource: vsf.stores.dbenums?.enums?.TREAT_AREA_DICT ?? [],
    },
    {
      label: '转区原因',
      width: 'width100',
      value: 'transferReason',
    },
  ];

  // useEffect(() => {
  //   searchRef.current?.handelSetCurrentRow(searchParams);
  // }, [searchParams]);

  const groupedByClinicLabelId = (output) => {
    // 使用reduce方法进行分类
    const _data = output.reduce((acc, item, index) => {
      // 查找是否已存在具有相同id的项
      const existingGroup = acc.find(
        (group) => group.clinicLabelId == item.clinicLabelId,
      );
      // 如果不存在，则创建一个新的组
      if (!existingGroup) {
        acc.push({
          id: index,
          clinicLabelId: item?.clinicLabelId,
          clinicLabel: item?.clinicLabel,
          show: true,
          data: [{ ...item }],
        });
      } else {
        // 如果存在，则将当前项添加到该组的arr数组中
        existingGroup.data.push(item);
      }
      // 返回累积的数组
      return acc;
    }, []);
    return _data;
  };

  // 待诊
  const getList1 = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.ClinicOutpVisitController_queryAllGreenWaitByErpVisitForPreExamPatientListQto_c982fb?.(
        {
          qto: {},
          ext: {},
        },
      ); // TODO: 请确认入参来源及出参用法
    setLoading(false);
    const output =
      convertQueryAllGreenWaitByErpVisitForPreExamPatientListQtoRes2ErpGreenWaitPatientList?.(
        res,
      );

    const response = groupedByClinicLabelId(output)?.map((i) => {
      return { ...i, show: true, rightText: '人待诊' };
    });
    // console.log(response, 'response');

    setList(response ?? []);
    // setList([
    //   {
    //     id: 1,
    //     clinicLabelId: 11,
    //     clinicLabel: '急诊区',
    //     show: true,
    //     rightText: '人待诊',
    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三',
    //         displayId: '10000083014',
    //         gender: '0',
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     clinicLabelId: 113,
    //     clinicLabel: '急诊区3',
    //     show: true,
    //     rightText: '人待诊',

    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三23',
    //         displayId: '100000830133',
    //         gender: '2',
    //       },
    //     ],
    //   },
    // ]);
  }, []);
  // 床位
  const getList3 = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.ClinicBedController_queryErpPreExamBedSearchWithPatientQto_ec5869?.(
        {
          qto: {},
          ext: {},
        },
      ); // TODO: 请确认入参来源及出参用法
    setLoading(false);
    // const output =
    //   convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList?.(
    //     res,
    //   );
    const res1 =
      convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList(
        res?.data?.recoveryAreaPatientList ?? [],
      );
    const res2 =
      convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList(
        res?.data?.remainingObservationPatientList ?? [],
      );
    const res3 =
      convertQueryErpPreExamBedSearchWithPatientQtoRes2RecoveryAreaPatientList(
        res?.data?.rescuePatientList ?? [],
      );
    setList([
      {
        id: 1,
        // clinicLabelId: 11,
        clinicLabel: '复苏区',
        rightText: '人待接收',
        centerText: '张空床',
        number: res?.data?.recoveryAreaWaitNumber ?? 0,
        show: true,
        data: [...res1],
      },
      {
        id: 2,
        // clinicLabelId: 113,
        clinicLabel: '留观区',
        rightText: '人待接收',
        centerText: '张空床',
        show: true,
        number: res?.data?.remainingObservationWaitNumber ?? 0,
        data: [...res2],
      },
      {
        id: 3,
        // clinicLabelId: 113,
        clinicLabel: '抢救区',
        rightText: '人待接收',
        centerText: '张空床',
        show: true,
        number: res?.data?.rescueWaitSerialNumber ?? 0,
        data: [...res3],
      },
    ]);
    // return output;
  }, []);
  // 待入患者
  const getList4 = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.ClinicOutpVisitController_queryAllRedAndYellowWaitByErpVisitForPreExamPatientListQto_7e5c08?.(
        {
          qto: {},
          ext: {},
        },
      ); // TODO: 请确认入参来源及出参用法
    setLoading(false);

    const output =
      convertQueryErpPreExamBedSearchWithPatientQtoRes2RescuePatientList?.(res);

    const response = groupedByClinicLabelId(output)?.map((i) => {
      return { ...i, show: true, rightText: '人待入' };
    });

    setList(response ?? []);
    // setList([
    //   {
    //     id: 1,
    //     clinicLabelId: 11,
    //     clinicLabel: '急诊区',
    //     show: true,
    //     rightText: '人待入',
    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三',
    //         displayId: '10000083014',
    //         gender: '0',
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     clinicLabelId: 113,
    //     clinicLabel: '急诊区3',
    //     show: true,
    //     rightText: '人待入',
    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三23',
    //         displayId: '100000830133',
    //         gender: '2',
    //       },
    //     ],
    //   },
    // ]);
  }, []);
  // 待转区
  const getList5 = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.ClinicOutpVisitController_queryAllWaitTransferByErpVisitForPreExamPatientListQto_fe26ea?.(
        {
          qto: {},
          ext: {},
        },
      ); // TODO: 请确认入参来源及出参用法
    setLoading(false);
    const output =
      convertQueryAllWaitTransferByErpVisitForPreExamPatientListQtoRes2ErpWaitTransferPatientList?.(
        res,
      );
    const response = groupedByClinicLabelId(output)?.map((i) => {
      return {
        ...i,
        show: true,
        rightText: '人待处理',
      };
    });
    // console.log(response, output, 'responseresponse');

    setList(response ?? []);
    // setList([
    //   {
    //     id: 1,
    //     clinicLabelId: 11,
    //     clinicLabel: '急诊区',
    //     show: true,
    //     rightText: '人待处理',
    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三',
    //         displayId: '10000083014',
    //         toTreatAreaCode: 'www',
    //         transferReason: '转w',
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     clinicLabelId: 113,
    //     clinicLabel: '急诊区3',
    //     show: true,
    //     rightText: '人待入',
    //     data: [
    //       {
    //         diseaseLevelName: '1及',
    //         name: '张三23',
    //         displayId: '100000830133',
    //         toTreatAreaCode: 'ww',
    //         transferReason: '转',
    //       },
    //     ],
    //   },
    // ]);
  }, []);

  useEffect(() => {
    if (currentTab == '1') {
      getList1();
    } else if (currentTab == '2') {
      setList([]);
    } else if (currentTab == '3') {
      getList3();
    } else if (currentTab == '4') {
      getList4();
    } else if (currentTab == '5') {
      getList5();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, reload]);

  const handelModal = (v = false) => {
    setOpen(v);
  };
  // 待转区者列表操作-打回医生
  const handelBackDoctor = async () => {
    const res =
      await vsf?.services?.ClinicErpAdtController_rejectErpTransferForSearchList_0b2544?.(
        {
          erpVisitId: currentSpacicalId?.spacicalId,
          patientId: currentSpacicalId?.patientId,
        },
      );
    if (res?.code == 200) {
      message.success('打回成功');
      setReload(reload + 1);
    }
  };
  // 待转区者列表操作-确认
  const handelConfirm = async () => {
    const res =
      await vsf?.services?.ClinicErpAdtController_confirmErpTransferForSearchList_111049?.(
        {
          erpVisitId: currentSpacicalId?.spacicalId,
          patientId: currentSpacicalId?.patientId,
        },
      );
    if (res?.code == 200) {
      message.success('确认成功');
      setReload(reload + 1);
    }
  };
  // 待入患者列表操作-取消确认
  const handelCancelConfirm = async () => {
    const res =
      await vsf?.services?.ClinicErpAdtController_cancelConfirmErpTransferForSearchList_afbd92?.(
        {
          erpVisitId: currentSpacicalId?.spacicalId,
        },
      );
    if (res?.code == 200) {
      message.success('取消确认成功');
      setReload(reload + 1);
    }
    return res?.data;
  };
  // 特殊状态列表操作-回退待诊
  const handelBack = async () => {
    // todo
  };
  // 待诊列表操作-特殊患者
  const handelSpecialMark = async () => {
    const res =
      vsf.refs?.specialMarkModal?.getFieldsValue()?.specialPatientMemo ?? '';

    const output =
      convertSpecialPatientMemoForm2UpdateErpExamRecordMasterForSpecialPatientReq?.(
        {
          id: currentSpacicalId?.spacicalIds?.[0]?.id,
          specialPatientMemo: res,
        },
      );

    const responde =
      await vsf?.services?.ClinicErpPreExamController_updateErpExamRecordMasterForSpecialPatient_6afd29?.(
        { ...output, extApiParam: {} },
      );
    if (responde?.code == 200) {
      if (res?.code == 200) {
        message.success('特殊患者备注成功');
      }
      setReload(reload + 1);
      handelModal(false);
    }
  };

  const handelReload = () => {
    setReload(reload + 1);
  };

  useImperativeHandle(ref, () => ({
    handelReload,
  }));

  const renderListItem = (cloumn, key) => {
    return !loading ? (
      <ListItem
        tabkey={key}
        onChange={(v, spacicalId) => {
          // v:displayId  spacicalId:{spacicalId,patientId}
          setCurrentSelectPatient(v);
          rowOnChange(v, spacicalId);
          setCurrentSpacicalId(spacicalId);
        }}
        ref={searchRef}
        cloumn={cloumn}
        datas={list}
      />
    ) : (
      <Loading />
    );
  };

  return (
    <div className="emergency_treatment_body_list_content">
      <div className="emergency_treatment_body_list_content_top">
        <Tabs
          style={{ height: '100%' }}
          centered
          destroyInactiveTabPane
          tabBarGutter={isMinScreen ? 20 : 24}
          tabBarStyle={{
            fontWeight: 'bolder',
            textAlign: 'center',
            display: 'flex',
          }}
          items={[
            {
              label: <span className="icon18">待诊</span>,
              key: '1',
              children: renderListItem(cloumn1, '1'),
            },
            {
              label: <span className="icon18">特殊状态</span>,
              key: '2',
              children: renderListItem(cloumn2, '2'),
            },
            {
              label: <span className="icon18">床位</span>,
              key: '3',
              children: renderListItem(cloumn3, '3'),
            },
            {
              label: <span className="icon18">待入患者</span>,
              key: '4',
              children: renderListItem(cloumn4, '4'),
            },
            {
              label: <span className="icon18">待转区者</span>,
              key: '5',
              children: renderListItem(cloumn, '5'),
            },
          ]}
          activeKey={currentTab}
          onChange={(v) => {
            searchRef?.current?.handelSetCurrentRow(null);
            rowOnChange(null);
            setCurrentTab(v);
          }}
        />
      </div>
      <div className="emergency_treatment_body_list_content_bottom">
        <Button ghost type="primary" size="small" onClick={handelReload}>
          刷新
        </Button>
        {currentTab == '1' && (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 15 }}
            disabled={!currentSelectPatient}
            onClick={() => {
              handelModal(true);
            }}
          >
            特殊患者
          </Button>
        )}
        {currentTab == '2' && (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 15 }}
            disabled={!currentSelectPatient}
            onClick={() => {
              handelBack();
            }}
          >
            回退待诊
          </Button>
        )}
        {currentTab == '4' && (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 15 }}
            disabled={!currentSelectPatient}
            onClick={() => {
              handelCancelConfirm();
            }}
          >
            取消确认
          </Button>
        )}
        {currentTab == '5' && (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 15 }}
            disabled={!currentSelectPatient}
            onClick={() => {
              handelConfirm();
            }}
          >
            确认
          </Button>
        )}
        {currentTab == '5' && (
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 15 }}
            disabled={!currentSelectPatient}
            onClick={() => {
              handelBackDoctor();
            }}
          >
            打回医生
          </Button>
        )}
      </div>
      {/* 特殊患者备注弹窗 */}
      <Modal
        centered
        open={open}
        title="特殊患者备注"
        onCancel={() => {
          handelModal(false);
        }}
        onOk={() => {
          handelSpecialMark();
        }}
        okText="确认"
        cancelText="取消"
      >
        <VSForm id="specialMarkModal">
          <VSFormItem
            name={['specialPatientMemo']}
            label=""
            fieldProps={{ placeholder: '请输入特殊患者备注' }}
          />
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
