import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';
import React, { useState } from 'react';
import './index.less';
import Menu from './Menu';
import TreeMenu from './TreeMenu';
import TableDetails from './TableDetails';
const MedicalTechnologyChargeTemplate = (props) => {
  const [MenuCurrent, setMenuCurrent] = useState('mail'),
    [department, setDepartment] = useState();
  return (
    <div className="MedicalTechnologyChargeTemplate-Container">
      <Menu {...{ MenuCurrent, setMenuCurrent, setDepartment }} />
      <div className="container">
        <Container
          layout={{
            items: [
              {
                block: '20%',
                bgColor: 'var(--background)',
                padding: '24px',
                gap: '2px',
                height: '100%',
              },
              {
                block: 1,
                bgColor: 'var(--background)',
                padding: '24px',
                height: '100%',
              },
            ],
          }}
        >
          <TreeMenu {...{ department, setDepartment, MenuCurrent }} />
          <TableDetails {...{ department, MenuCurrent }} />
        </Container>
      </div>
    </div>
  );
};
export default definePage(MedicalTechnologyChargeTemplate);
