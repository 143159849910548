import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitEncounterController_getOutpVisitEncounterByOutpVisitId_b8997c: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-outp-visit-encounter-by-outp-visit-id',
    parameterFomatter: (data?: { outpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo,
    ) => data,
  },
});
