import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import '../index.less';

const Card = ({ value, label, index, changePosition, handelDelete }) => {
  const ref = useRef(null);
  // 因为没有定义收集函数，所以返回值数组第一项不要
  const [, drop] = useDrop({
    accept: 'DragDropBox',
    hover: (item, monitor) => {
      if (!ref.current) return;
      let dragIndex = item.index;
      let hoverIndex = index;
      // if (dragIndex === hoverIndex) return; // 如果回到自己的坑，那就什么都不做
      // changePosition(dragIndex, hoverIndex); // 调用传入的方法完成交换
      item.index = hoverIndex; // 将当前当前移动到Box的index赋值给当前拖动的box，不然会出现两个盒子疯狂抖动！
    },
    drop: (item, monitor) => {},
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'DragDropBox',
    item: { value, index },
    end: (_index) => {
      if (index == _index?.index || !_index) return; // 如果回到自己的坑或者没有放到指定元素，那就什么都不做

      changePosition(index, _index?.index);
    },
    isDragging: (monitor) => {
      return index === monitor.getItem().index;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      //   className="patient_seting_body_item"
      className={classNames(
        'patient_seting_body_item',
        isDragging ? 'patient_seting_body_dragging' : '',
      )}
      ref={drag(drop(ref))} // 这样写可以让它即接收拖拽又实现拖拽
    >
      <div className="content text_ellipsis">
        <div>
          <Icon type="icon-tuodong" style={{ marginRight: '12px' }} />
          {label}
        </div>
        <Icon
          type="icon-cuo"
          onClick={() => {
            handelDelete(value);
          }}
        />
      </div>
      <div></div>
    </div>
  );
};
export default Card;
