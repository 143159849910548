import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2:
    {
      method: 'post',
      url: '/api/application/get-local-parameter-by-application-id',
      parameterFomatter: (data?: { applicationId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationLocalParameterVoApplicationEntranceWebVo[],
      ) => data,
    },
  ApplicationBOController_addParameterToApplication_9c766b: {
    method: 'post',
    url: '/api/application/add-parameter-to-application',
    parameterFomatter: (data?: {
      btoParam: AddParameterToApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_updateParameterVsApplication_7e4468: {
    method: 'post',
    url: '/api/application/update-parameter-vs-application',
    parameterFomatter: (data?: {
      btoParam: UpdateParameterVsApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_deleteParameterFromApplication_c87cde: {
    method: 'post',
    url: '/api/application/delete-parameter-from-application',
    parameterFomatter: (data?: {
      btoParam: DeleteParameterFromApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
});
