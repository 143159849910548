import vsf from '@vs/vsf-boot';

// **住院中药退药确认：执行退药确认
export const performInpHerbRefund = async (event) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_performInpHerbRefund_5ad70a?.(
      { refunds: event },
    );
  return res;
};
// 住院中药退药：获取可退药中药处方列表
export const getDispenseForInpHerbRefundApplyByMasterIds = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getDispenseForInpHerbRefundApplyByMasterIds_40e4cd?.(
      { id: params?.value?.drugPrescriptionDispenseMasterId },
    );
  return {
    data: convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableHerbDispenseDetailForInpRefundPerform?.(
      res,
    ),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableHerbDispenseDetailForInpRefundPerform
 * @param {any} input
 * @returns {any}
 */
const convertGetDispenseForInpHerbRefundApplyByMasterIdsRes2TableHerbDispenseDetailForInpRefundPerform =
  (input) => {
    const _ = window._ ?? {};
    const output: any = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionDataTime = _.get(
          d,
          'prescription.prescriptionDataTime',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.departmentName = _.get(
          d,
          'prescription.orderHerb.order.orderDepartmentInfo.departmentName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.staffName = _.get(
          d,
          'prescription.orderHerb.order.doctorInfo.staffName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionAttribute = _.get(
          d,
          'prescription.prescriptionAttribute',
        );
        obj.dispenseTime = _.get(d, 'dispenseTime');
        obj.herbRegimen = _.get(d, 'herbRegimen'); // TODO 请确认下行字段是否符合逻辑
        obj.agreedPrescriptionName = _.get(
          d,
          'prescription.orderHerb.agreedPrescriptionName',
        );
        obj.id = Math?.random() + _.get(d, 'id');
        obj.cid = _.get(d, 'id');
        obj.dispDetail = [];
        obj.dispDetail.push({
          id: _.get(d, 'id'),
          orderText: _.get(d, 'prescription.orderHerb.order.orderText'),
          allowRefundHerbRegimen: _.get(d, 'allowRefundHerbRegimen'),
          refundAmount: _.get(d, 'allowRefundHerbRegimen'),
          canRefundAmount: _.get(d, 'canRefundAmount'), // TODO 请确认下行字段是否符合逻辑
        });
        return obj;
      }) ?? []),
    );
    return output;
  };
// 住院中药退药：获取可退药患者
export const getHerbDispenseCountForInpRefundPerform = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getHerbDispenseCountForInpRefundPerform_770d75?.(
      { query: { ...params } },
    );
  return {
    data: convertGetHerbDispenseCountForInpRefundPerformRes2TablePatientCountForInpHerbRefundPerformRo?.(
      res,
    ),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetHerbDispenseCountForInpRefundPerformRes2TablePatientCountForInpHerbRefundPerformRo
 * @param {any} input
 * @returns {any}
 */
const convertGetHerbDispenseCountForInpRefundPerformRes2TablePatientCountForInpHerbRefundPerformRo =
  (input) => {
    const _ = window._ ?? {};
    const output: any = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = { ...d, id: _.get(d, 'inpVisitId') }; // TODO 请确认下行字段是否符合逻辑
        // obj.wardName = _.get(d, 'wardName'); // TODO 请确认下行字段是否符合逻辑
        // obj.bedLabel = _.get(d, 'bedLabel'); // TODO 请确认下行字段是否符合逻辑
        // obj.name = _.get(d, 'name'); // TODO 请确认下行字段是否符合逻辑
        // obj.drugPrescriptionDispenseMasterId = _.get(
        //   d,
        //   'drugPrescriptionDispenseMasterId',
        // );
        return obj;
      }) ?? []),
    );
    return output;
  };
// 查询当前院区病区（护理单元）列表
export const getWardPagedBySearchCode = async (param) => {
  const res =
    await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
      { searchCode: '' },
    );
  return convertGetWardPagedBySearchCodeRes2GetWardPagedBySearchCodeReq?.(res);
};
/**
 * convertGetWardPagedBySearchCodeRes2GetWardPagedBySearchCodeReq
 * @param {any} input
 * @returns {any}
 */
const convertGetWardPagedBySearchCodeRes2GetWardPagedBySearchCodeReq = (
  input,
) => {
  return (
    input?.data?.result?.map((m) => ({ id: m.id, name: m.departmentName })) ??
    []
  );
};
// 查询病区患者列表，瀑布流/分页，按床号排序
export const queryScrollWardPatientList = async (param) => {
  const res =
    await vsf?.services?.PatientInHosptialController_queryScrollWardPatientList_346269?.(
      {
        qto: {
          from: param?.pagination?.from ?? 0,
          size: param?.pagination?.size ?? 899,
          ...param,
        },
      },
    );
  return convertQueryScrollWardPatientListRes2QueryScrollWardPatientListReq?.(
    res,
  );
};
/**
 * convertQueryScrollWardPatientListRes2QueryScrollWardPatientListReq
 * @param {any} input
 * @returns {any}
 */
const convertQueryScrollWardPatientListRes2QueryScrollWardPatientListReq = (
  input,
) => {
  return (
    input?.data?.result?.map((m) => ({
      id: m.id,
      inpVisitId: m.inpVisitId,
      bedLabel: m.bed?.bedLabel,
      displayId: m.patient?.displayId,
      name: m.patient?.name,
    })) ?? []
  );
};

// 获取患者详情；
export const getPatientDetall = async (params) => {
  const res =
    await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
      { qto: { ...params, from: 0, size: 20 } },
    );
  if (res?.data?.result.length >= 1) return res?.data?.result[0]?.id;
  // message.error('未查到患者信息');
  return false;
};
