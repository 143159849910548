import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
/**
 * 定位至表单行位置
 * @param table_id 表单id
 * @param row_key 表行key
 * @param msg 弹出消息
 * @returns
 */
export const scrollTo = (table_id, row_key, msg) => {
  if (!row_key) return;
  vsf?.refs?.[table_id]?.scrollTo?.(row_key);
  msg && message.success(msg);
};
/**
 *
 * @param table_id 表单id
 * @returns
 */
export const getTableValues = (table_id) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.getValues?.();
};
/**
 *
 * @param table_id  表单id
 * @param values  数据{}
 * @returns
 */
export const updateTableValues = (table_id, values) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.update(values);
};

export const clearTable = (table_id) => {
  if (!table_id) return;
  vsf?.refs?.[table_id]
    ?.getValues?.()
    ?.forEach?.((d) => vsf?.refs?.[table_id]?.remove?.(d));
};
