import dayjs from 'dayjs';

import {
  administrationAmountConvert,
  administrationConvert,
  descriptionConvert,
  dischargeDrugConvert,
  distributeConvert,
  dosageConvert,
  dripSpeedConvert,
  drugBillingAttributeConvert,
  durationConvert,
  frequencyConvert,
  performDaysConvert,
  totalAmountConvert,
  usageConvert,
} from './fieldConvert';

export const convertOrderInField = (item) => {
  const dosage = dosageConvert(item);
  const usage = usageConvert(item);
  const administration = administrationConvert(item);
  const dripSpeed = dripSpeedConvert(item);
  const frequency = frequencyConvert(item);
  const totalAmount = totalAmountConvert(item);
  const useDescription = descriptionConvert(item);
  const performDays = performDaysConvert(item);
  const drugBillingAttribute = drugBillingAttributeConvert(item);
  const distribute = distributeConvert(item);
  const dischargeDrug = dischargeDrugConvert(item);
  const duration = durationConvert(item);
  const administrationAmount = administrationAmountConvert(item);
  return {
    dosage,
    usage,
    administration,
    dripSpeed,
    frequency,
    totalAmount,
    performDays,
    drugBillingAttribute,
    useDescription,
    distribute,
    dischargeDrug,
    duration,
    administrationAmount,
    groupNumber: item?.drugOrder?.groupNumber,
    groupSubNumber: item?.drugOrder?.groupSubNumber,
  };
};

export const convertOtherOrderInfo = (item) => {
  const {
    drugOrder,
    examOrder = {},
    antibioticRecord,
    labOrder = {},
    pathologyOrder = {},
    operationOrder = {},
    disposalOrder = {},
    herbOrder = {},
    patientSpecialInfo = [],
    ...rest
  } = item;
  const METHOD = {
    EXAM: () => {
      return {
        examOrder: {
          ...examOrder,
        },
        ...(Array?.isArray(patientSpecialInfo)
          ? {
              patientSpecialInfo: Object?.fromEntries?.(
                (patientSpecialInfo ?? [])?.map?.((item) => [
                  item?.infectClass,
                  item?.infectValue,
                ]) ?? [],
              ),
            }
          : patientSpecialInfo ?? {}),
      };
    },
    DRUG: () => {
      return {
        drugOrder: {
          ...drugOrder,
        },
        dischargeDrug: drugOrder?.dischargeDrugPrescription,
      };
    },
    OPERATION: () => {
      return {
        operationOrder: {
          ...operationOrder,
        },
        ...(Array?.isArray(patientSpecialInfo)
          ? {
              patientSpecialInfo: Object?.fromEntries?.(
                (patientSpecialInfo ?? [])?.map?.((item) => [
                  item?.infectClass,
                  item?.infectValue,
                ]) ?? [],
              ),
            }
          : patientSpecialInfo ?? {}),
      };
    },
    PATHOLOGY: () => {
      return {
        pathologyOrder: {
          ...pathologyOrder,
        },
      };
    },
    HERB: () => {
      return {
        herbOrder: {
          ...herbOrder,
        },
      };
    },
    LAB: () => {
      return {
        labOrder: {
          ...labOrder,
          emergencyIndicator: labOrder?.emergencyIndicator ?? false,
        },
      };
    },
  };

  return {
    ...rest,
    ...(METHOD[item?.orderClass]
      ? METHOD[item?.orderClass]?.()
      : {
          disposalOrder: {
            ...disposalOrder,
          },
        }),
  };
};

/**
 * 住院医嘱服务端数据进入住院医嘱表格操作前进行转换
 * @param data 服务端下发数据
 * @returns 住院医嘱表格数据
 */
export const convertOrderInTable = (data) => {
  return data?.map((item) => {
    return {
      ...convertOrderInField(item),
      ...convertOtherOrderInfo(item),
    };
  });
};

type visitInfoType = {
  outpVisitEncounterId?: number;
  inpVisitEncounterId?: number;
  inpVisitId?: number;
};

/**
 * 住院医嘱表格数据提交到服务端之前进行转换
 * @param data 住院医嘱表格数据
 * @returns 服务端上传数据
 */
export const convertOrderOutTable = (
  order,
  visitInfo?: visitInfoType,
  patientId?: number,
) => {
  return order
    ?.map((item, index) => {
      const {
        drugOrder,
        examOrder = {},
        labOrder = {},
        pathologyOrder = {},
        operationOrder = {},
        disposalOrder = {},
        herbOrder = {},
        orderText,
        ...rest
      } = item;
      if (String(rest.id).startsWith('create')) delete rest.id;
      const METHOD = {
        EXAM: () => {
          const patientSpecialInfo = Object?.keys(
            item?.patientSpecialInfo ?? {},
          );
          return {
            examOrder: {
              ...examOrder,
            },
            orderDiagnosisIdList: item?.orderDiagnosisIdList?.map(
              (item) => item?.id ?? item,
            ),
            patientSpecialInfo: patientSpecialInfo?.map((value: string) => ({
              infectClass: value,
              infectValue: item?.patientSpecialInfo?.[value],
            })),
          };
        },
        DRUG: () => {
          const data = orderText?.detailInfo?.drugEo instanceof Object;
          let drug = {};
          if (data) {
            const { groupNumber, groupSubNumber, ...drugItem } =
              item?.orderText?.detailInfo?.drugEo;
            drug = drugItem;
          }
          return {
            drugOrder: {
              ...(data
                ? {
                    ...drug,
                    drugProductName: item?.orderText?.drugName,
                  }
                : {}),
              ...drugOrder,
              drugPriceItemId:
                drugOrder?.drugPriceItemId ?? rest?.drugPriceItemId,
              drugName:
                drugOrder?.drugName ??
                item?.orderText?.drugName ??
                item?.orderText,
              drugProductName:
                drugOrder?.drugName ??
                item?.orderText?.drugName ??
                item?.orderText,
              dosage: rest?.dosage?.value,
              dosageUnit: rest?.dosage?.unit,
              usage: rest?.usage?.value,
              usageUnit:
                rest?.usage?.unit ??
                rest?.usageUnit ??
                item?.orderText?.usageUnit,
              totalAmount: rest?.totalAmount?.value ?? rest?.totalAmount,
              drugBillingAttribute: !rest?.drugBillingAttribute
                ? 'NORMAL'
                : 'SELF_PROVIDE',
              administrationAmount: rest?.administrationAmount,
              totalAmountUnit: rest?.totalAmount?.unit,
              administration: rest?.administration,
              useDescription: rest?.useDescription,
              dripSpeed: rest?.dripSpeed,
              // skinTestContinueUseIndicator: rest?.skinTestContinueUseIndicator,
              skinTestType: drugOrder?.skinTestType ?? rest?.skinTestType,
              frequency: (() => {
                const frequency = JSON.parse(
                  item?.frequency?.extensionJson ?? '{}',
                );
                return String(item?.id)?.startsWith('create')
                  ? Object?.keys(frequency)?.length
                    ? frequency
                    : item?.frequency
                  : item?.frequency;
              })(),
              ...(Object?.keys(item?.group ?? {})?.length ? item?.group : {}),
              performDays:
                rest?.performDays ??
                (Object?.keys(item?.group ?? {})?.length ? item?.group : {})
                  ?.performDays,
            },
            drugStorageId: item?.orderText?.storageId ?? item?.drugStorageId,
          };
        },
        OPERATION: () => {
          const patientSpecialInfo = Object?.keys(
            item?.patientSpecialInfo ?? {},
          );
          return {
            operationOrder: {
              ...operationOrder,
            },
            orderDiagnosisIdList: item?.orderDiagnosisIdList?.map(
              (item) => item?.id ?? item,
            ),
            patientSpecialInfo: patientSpecialInfo?.map((value: string) => ({
              infectClass: value,
              infectValue: item?.patientSpecialInfo?.[value],
            })),
          };
        },
        PATHOLOGY: () => {
          return {
            pathologyOrder: {
              ...pathologyOrder,
              examSubClassId:
                pathologyOrder?.examSubClassId?.id ??
                pathologyOrder?.examSubClassId,
              pathologyOrderDetail: pathologyOrder?.pathologyOrderDetail?.map(
                (item) => {
                  // if (String(item?.id).startsWith('create')) delete item?.id;
                  delete item?.id;
                  return item;
                },
              ),
            },
            orderDiagnosisIdList: item?.orderDiagnosisIdList?.map(
              (item) => item?.id ?? item,
            ),
          };
        },
        HERB: () => {
          return {
            herbOrder: {
              ...herbOrder,
              useTimeDescription:
                herbOrder?.useTimeDescription?.value ??
                herbOrder?.useTimeDescription,
              useWay: herbOrder?.useWay?.value ?? herbOrder?.useWay,
              herbOrderDetail: herbOrder?.herbOrderDetail?.map(
                (item, index) => {
                  if (String(item?.id)?.startsWith('create')) delete item?.id;
                  return {
                    ...item,
                    herbItemNumber: index + 1,
                    totalAmount: item?.totalAmount?.value ?? item?.totalAmount,
                  };
                },
              ),
            },
          };
        },
        LAB: () => {
          return {
            labOrder: {
              ...labOrder,
            },
          };
        },
      };

      const name = orderText?.drugProductName ?? orderText;
      const other = orderText?.packageSpecification
        ? `(${orderText?.packageSpecification})`
        : '';
      const text = `${name}${other}`;

      let disposalOrderAmount = rest?.totalAmount?.value ?? rest?.totalAmount;
      if (typeof disposalOrderAmount !== 'number') {
        disposalOrderAmount = 1;
      }

      return {
        ...rest,
        orderText: text,
        ...(METHOD?.[item?.orderClass]
          ? METHOD?.[item?.orderClass]?.()
          : {
              disposalOrder: {
                ...disposalOrder,
                amount: disposalOrderAmount,
                administration:
                  disposalOrder?.administration ?? rest?.administration,
                frequency:
                  disposalOrder?.frequency ?? (rest?.frequency || undefined),
              },
            }),
        // 暂定项
        repeatIndicator: rest?.repeatIndicator ?? false,
        // 写死项
        ...(visitInfo ?? {}),
        performDepartmentId:
          item?.performDepartment?.id ?? item?.orderText?.storageDepartmentId,
        submitDateTime: dayjs().valueOf(),
        patientId,
        sortNumber: index,
      };
    })
    ?.map((item) => ({
      ...item,
      orderStatus: 'SAVE',
    }));
};
