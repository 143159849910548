import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  Input,
  InputNumber,
  InputProps,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest, useUpdateEffect } from 'ahooks';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { user } from '@/stores';

import { askFixed } from '../func/ask';
import { useAvoidState, useFrequencyEffect } from '../func/hooks';
import { useInputEnter } from './utils';

/**
 * 合并单位的 Input
 */
let InputUnit: any = (props, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    dataSource,
    record: recode,
    path = {
      dosage: ['drugOrder', 'dosage'],
    },
    id,
    ...rest
  } = props;
  const { staffInfo } = user || {};
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const [current, setCurrent] = useGetState<any>('');
  const [unit, setUnit, getUnit] = useGetState<any>('');

  const valueRef = useRef<any>();

  useEffect(() => {
    if (typeof value === 'object') {
      setCurrent(value?.value);
      setUnit(value?.unit);
      valueRef.current = 1;
    } else {
      setCurrent(value);
      valueRef.current = 2;
    }
  }, [value, setCurrent, setUnit]);

  const form = Form?.useFormInstance();
  const record = form?.getFieldsValue(true);
  // const id = Object?.keys(record)?.at(-1) ?? '';
  const dosage = [id, ...path?.dosage];
  const [data, setData] = useAvoidState({
    dosagePerUnit: 0,
    amountPerPackage: 0,
    roundingType: 'BY_AMOUNT',
    packageUnit: 0,
  });

  const is = (drugItemId) => {
    const data = dataSource?.find?.((ele) => ele?.id == id);
    return (
      record?.[id]?.[drugItemId] ??
      record?.[id]?.drugOrder?.[drugItemId] ??
      record?.[id]?.orderText?.[drugItemId] ??
      record?.[id]?.orderText?.detailInfo?.drugEo?.[drugItemId] ??
      recode?.drugOrder?.[drugItemId] ??
      data?.drugOrder?.[drugItemId]
    );
  };

  const drugItemId: number = is('drugItemId') ?? is('drugPriceItemId');
  useRequest(
    async () => {
      if (drugItemId) {
        const res: any =
          await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
            {
              drugItemId,
              patientId: 1001,
              departmentId: 193,
              staffId: staffInfo?.id,
            },
          );
        if (res?.data?.errorMessage) return;
        setData(res?.data);
      }
    },
    {
      refreshDeps: [drugItemId],
      debounceWait: 500,
    },
  );
  const onChange = (value) => {
    const target = value?.value ?? value ?? 0;
    const int = askFixed(target * (data?.dosagePerUnit ?? 0), 2);
    form?.setFieldValue(dosage, {
      value: Number?.isNaN(Number(int)) ? 0 : int,
      unit: form?.getFieldValue(dosage)?.unit,
    });
    props?.onChange?.(value);
  };

  const handleChange = (v) => {
    const formatValue =
      valueRef?.current === 1
        ? {
            value: v,
            unit: getUnit(),
          }
        : v;
    onChange?.(formatValue);
  };

  return (
    <div>
      <InputNumber
        disabled={!data?.dosagePerUnit}
        {...rest}
        ref={ref}
        value={current}
        onChange={handleChange}
        addonAfter={
          vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
            (item) => item?.value == unit,
          )?.label ?? unit
        }
        {...fieldEnterProps}
        onFocus={(v) => {
          v?.currentTarget?.select();
        }}
      />
    </div>
  );
};

InputUnit.displayName = 'usage';

InputUnit = React.forwardRef(InputUnit);

export const UsageUnit = (props) => {
  const target = props?.value;
  if (typeof target === 'object') {
    return (
      <>
        {Object.values?.({
          value: props?.value?.value,
          unit:
            vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
              (item) => item?.value == target?.unit,
            )?.label ?? target?.unit,
        })}
      </>
    );
  } else {
    return <>{props?.value}</>;
  }
};

export default compose(
  withRef(),
  withField<any>({
    name: 'usage',
    handleEnter: true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        return <UsageUnit value={props?.value} />;
      },
    },
  ),
)(InputUnit) as typeof InputUnit;
