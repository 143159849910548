import vsf from '@vs/vsf-boot';
import { Button, Checkbox, message, RadioGroup, Select } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import Icon from '@/module/Icon';

import { useOnChangeValue } from '../../../func/hooks';
import { AuxiliaryRefs, Drug } from '..';
import { Refs, TableRefs } from '../table';
import { convertOrderInTable } from '../table/convert';

interface HeaderProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  table: {
    current: Drug & TableRefs & Refs;
  };
  // params: any;
  // onParamsChange: (value: any) => void;
}

export type HeaderRefs = {
  getState: () => any;
  onReload: () => Promise<any>;
};

const Header = forwardRef((props: HeaderProps, ref) => {
  const { auxiliary, table } = props;
  const {
    user: { inVisitInfo, getDoctorOrder },
  } = vsf?.stores;
  const onGet = useCallback(
    async (today?: any) => {
      const value = await getDoctorOrder({
        hospitalType: 'in',
        id: inVisitInfo?.inpVisitId,
        today,
      });
      table?.current?.setValue(
        table?.current?.onSliced?.(convertOrderInTable(value)),
      );
    },
    [getDoctorOrder, inVisitInfo?.inpVisitId, table],
  );

  const [state] = useOnChangeValue(
    {
      orderDeadline: 2,
      orderFilterType: '',
      orderFilterToday: true,
    },
    (value) => {
      onGet(value?.orderFilterToday);
      return value;
    },
  );

  useAsyncEffect(() => onGet(state?.value.orderFilterToday), []);

  const prescriptionPermission = async (func: () => void) => {
    const state = await vsf?.stores?.user?.getStaffInfo?.();
    const prescriptionPermission = state?.doctorDto?.prescriptionPermission;
    if (prescriptionPermission) {
      func?.();
    } else {
      message?.warning('当前登录医生无处方权，无法开立医嘱');
    }
  };

  const items = [
    {
      name: '药疗[1]',
      icon: 'icon-yaoliao',
      click: () => {
        prescriptionPermission?.(() => {
          table?.current?.onDrug?.();
        });
      },
    },
    {
      name: '中草药[2]',
      icon: 'icon-zhongcaoyao',
      click: async () => {
        prescriptionPermission?.(async () => {
          const state = await vsf?.stores?.user?.getStaffInfo?.();
          const herbPermission = state?.doctorDto?.herbPermission;
          if (!herbPermission) {
            message?.warning('没有开立中草药医嘱权限');
          } else {
            auxiliary?.current?.onHerb();
          }
        });
      },
    },
    {
      name: '检查[3]',
      icon: 'icon-jiancha',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onExam?.();
        });
      },
    },
    {
      name: '检验[4]',
      icon: 'icon-huayan',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onLab?.();
        });
      },
    },
    {
      name: '手术[5]',
      icon: 'icon-Frame-11',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onOperation?.();
        });
      },
    },
    {
      name: '病理[6]',
      icon: 'icon-a-Germsbingjun',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onPathology?.();
        });
      },
    },
    {
      name: '其他[7]',
      icon: 'icon-chuzhi1',
      click: () => {
        prescriptionPermission?.(() => {
          table?.current?.onOther?.();
        });
      },
    },
  ];

  const deadlineDataSource = [
    {
      label: '全部',
      value: 2,
    },
    {
      label: '长期',
      value: 1,
    },
    {
      label: '临时',
      value: 0,
    },
  ];

  useImperativeHandle(ref, () => ({
    getState: () => state?.value,
    onReload: () => onGet?.(state?.value.orderFilterToday),
  }));

  return (
    <div className="extra-operation aspirin-order-module-header">
      <div className="left-in">
        <div className="auxiliary-type-select">
          <div className="label">医嘱类型</div>
          <div>
            <Select
              allowClear
              // width={70}
              className="width70"
              onChange={(data: any) => {
                state?.onChange({
                  ...state.value,
                  orderFilterType: data,
                });
              }}
              value={state?.value?.orderFilterType}
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '病理', value: 'PATHOLOGY' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
              ]}
            />
          </div>
        </div>
        <div>
          <RadioGroup
            optionType="button"
            onChange={(data) => {
              state?.onChange({
                ...state.value,
                orderDeadline: data,
              });
            }}
            value={state?.value?.orderDeadline}
            dataSource={deadlineDataSource}
          />
        </div>
        <div>
          <Checkbox
            onChange={(data: any) => {
              state?.onChange({
                ...state.value,
                orderFilterToday: data,
              });
            }}
            value={state?.value?.orderFilterToday}
          >
            仅显示当日有效医嘱
          </Checkbox>
        </div>
      </div>
      <div
        className="right"
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {items
          ?.filter((item) => !!item)
          ?.map((item: any) => (
            <div onClick={item?.click} key={item?.icon}>
              <Icon
                size={item?.icon === 'icon-huzhi' ? 24 : 20}
                type={item?.icon}
              />
              <span>{item?.name}</span>
            </div>
          ))}
        <Button
          type="primary"
          // onClick={() =>
          //   prescriptionPermission?.(() => {
          //     auxiliary?.current?.onTemplate?.('1');
          //   })
          // }
        >
          医嘱模版导入
        </Button>
        <Button
          type="primary"
          // onClick={() =>
          //   prescriptionPermission?.(() => {
          //     auxiliary?.current?.onTemplate?.('2');
          //   })
          // }
        >
          历史医嘱导入
        </Button>
        {/* <div>
          <Cdss title="智能校验">
            <Image
              src={CdssImage}
              preview={false}
              className="cdss-image-robot"
            />
          </Cdss>
        </div> */}
      </div>
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
