import vsf from '@vs/vsf-boot';
export const getParentStorages = async () => {
  const res =
    await vsf.services?.DrugStorageController_getParentStorages_35c1c0?.({
      // TODO: 请检查以下这部分传参是否符合需求
    }); // TODO: 你可能需要整理返回数据格式
  return res?.data?.map((item) => ({
    label: item?.drugStorage.storageName,
    value: item?.drugStorage.id,
  }));
};
