import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryBedPatientStatistics_3cd3df: {
    method: 'post',
    url: '/api/clinic-bed/query-bed-patient-statistics',
    parameterFomatter: (data?: {
      statisticsCodes: string[];
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
      ext: {
        statisticsCode: string;
        withInpEscortRegister: boolean;
        withMealOrderInfo: boolean;
        erpIndicator: boolean;
        withInpAdmissionApplyInfo: boolean;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BedPatientStatisticsEoClinicBedPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
