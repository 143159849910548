import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordController_queryAllByDiagnosisRecordWithDiagnosisQto_f84607: {
    method: 'post',
    url: '/api/clinic-record/query-all-by-diagnosis-record-with-diagnosis-qto',
    parameterFomatter: (data?: {
      qto: DiagnosisRecordWithDiagnosisQtoClinicRecordPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisRecordWithDiagnosisVoClinicRecordEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicRecordController_saveDiagnosisRecordList_1c06ed: {
    method: 'post',
    url: '/api/clinic-record/save-diagnosis-record-list',
    parameterFomatter: (data?: {
      btoParamList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  ClinicRecordController_saveOverviewDiagnosisRecordList_a21903: {
    method: 'post',
    url: '/api/clinic-record/save-overview-diagnosis-record-list',
    parameterFomatter: (data?: {
      btoParamList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoClinicRecordEntranceWebVoDiagnosisrecordwithdiagnosisvo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
