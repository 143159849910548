import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_createInpBillingDetailForNurseStation_3910aa: {
    method: 'post',
    url: '/api/inp-billing/create-inp-billing-detail-for-nurse-station',
    parameterFomatter: (data?: {
      saveInpBillingDetailEoList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getInpBillingDetailByOrderId_2827f8: {
    method: 'post',
    url: '/api/inp-billing/get-inp-billing-detail-by-order-id',
    parameterFomatter: (data?: { inpVisitId: number; orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getOrderBillingInpBillingDetailGroupInfo_5b1e07: {
    method: 'post',
    url: '/api/inp-billing/get-order-billing-inp-billing-detail-group-info',
    parameterFomatter: (data?: { inpVisitId: number; orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailGroupForNurseStationVoInpBillingEntranceWebVo[],
    ) => data,
  },
  NurseBillingController_queryOrderInfoForNurseStation_c4461b: {
    method: 'post',
    url: '/api/clinic-record-order/query-order-info-for-nurse-station',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderClass: ClinicItemClassEnum;
      orderTextLike: string;
      repeatIndicator: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoForNurseSupplementBillingVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
