import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_getClinicBedWithPatientVoById_84c713: {
    method: 'post',
    url: '/api/clinic-bed/get-clinic-bed-with-patient-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicBedWithPatientVoClinicBedEntranceWebVo,
    ) => data,
  },
  ClinicBedController_getListClinicBedVoByWardId_662549: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-bed-vo-by-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (_, __, data?: ClinicBedVoClinicBedEntranceWebVo[]) =>
      data,
  },
  ClinicBedController_changeBedPatient_d31918: {
    method: 'post',
    url: '/api/clinic-bed/change-bed-patient',
    parameterFomatter: (data?: {
      first: ClinicBedWithPatientVoClinicBedEntranceWebVo;
      second: ClinicBedWithPatientVoClinicBedEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
