import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_getDrugProductDepartmentUsageByDrugProductId_cdc3d7: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-department-usage-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductDepartmentUsageVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_deleteDrugProductDepartmentUsage_e9a4a5: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product-department-usage',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_createOrUpdateDrugProductDepartmentUsage_e60de7: {
    method: 'post',
    url: '/api/drug-drug/create-or-update-drug-product-department-usage',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugProductDepartmentUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
