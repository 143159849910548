import './index.less';

import CSS from 'csstype';
import React, { useEffect } from 'react';

import { getPrefixCls } from '@/config';

type BlockPropsType = {
  children?: React.ReactNode;
  style?: CSS.Properties;
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  className?: string;
  title?: string;
  extra?: React.ReactNode;
  scroll?: boolean;
  titleStytle?: CSS.Properties;
  bodyStytle?: CSS.Properties;
  headerStyle?: CSS.Properties;
};

const Index = (props) => {
  const {
    children,
    style,
    width = '100%',
    height = '100%',
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    className,
    title,
    extra,
    scroll,
    isNeedTag = false,
    borderBottom,
    titleStytle,
    bodyStytle,
    headerStyle,
  } = props;
  const formTitleCls = getPrefixCls('index-form-title');

  return (
    <div
      className={`${formTitleCls} ${className}`}
      style={{
        ...style,
        minWidth,
        minHeight,
        maxWidth,
        maxHeight,
        width,
        height,
      }}
    >
      {title && (
        <div
          className="header"
          style={{
            borderBottom: borderBottom ? borderBottom : '',
            ...headerStyle,
          }}
        >
          <div className="left-block" style={{ ...titleStytle }}>
            {isNeedTag && <div className="left-tag"></div>}
            {title}
          </div>
          <div className="right-block">{extra}</div>
        </div>
      )}
      <div
        className="content"
        style={{
          overflow: scroll ? 'auto' : 'unset',
          ...bodyStytle,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Index;
