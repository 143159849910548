import React from 'react';

import { Page } from '@vs/vsf-kit';
import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';

import Menu from '../patient_main/patient_full_info';
import PatientMainSearch from './table';

const PatientMainSearchManager = (props) => {
  return (
    <Container
      layout={{
        // direction: 'vertical',
        items: [
          {
            block: 1,
            bgColor: 'transparent',
          },
        ],
      }}
    >
      <div className="patient_main_search_wapper">
        <PatientMainSearch />
      </div>
    </Container>
  );
};
export default definePage(PatientMainSearchManager);
