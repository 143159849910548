import { createServices } from '@vs/vsf-boot';
export default createServices({
  DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f: {
    method: 'post',
    url: '/api/base-common/get-all-by-disease-level-dictionary-qto',
    parameterFomatter: (data?: {
      qto: DiseaseLevelDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseLevelDictionaryVoBaseCommonEntranceWebVo[],
    ) => data,
  },
  DiseaseLevelDictionaryController_updateLevelDictionary_876a6d: {
    method: 'post',
    url: '/api/base-common/update-level-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateLevelDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseLevelDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
});
