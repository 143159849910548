import './index.less';

import {
  VSPromiseTable,
  VSTableColumn,
  VSTableInstance,
  VSTableToolbar,
} from '@vs/vs-table';
import vsf, { definePage } from '@vs/vsf-boot';
import { SyncOutlined } from '@vs/vsf-icons';
import {
  Button,
  compose,
  Empty,
  Image,
  Input,
  Spin,
  Tree,
  TreeProps,
  useControllableState,
  withRef,
} from '@vs/vsf-kit';
import { useControllableValue, useMount } from 'ahooks';
import { Form } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isMinScreen } from '@/config';
import SearchContainer from '@/module/Search';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import Mybutton from '@/pages/Index/components/my_button';
import { getScrollY } from '@/utils';

type PatientTable = {
  onChange?: (value: any) => void;
  type?: 'page' | 'drawer';
  statusDataSource?: any[];
  hideField: any;
  tableScroll: any;
  stores: any;
  value?: any;
  onDataSourceChange?: (value: any) => void;
  routes?: any;
  patientOpen?: boolean;
};

const PatientTable = (props: PatientTable) => {
  // type='page' 页面使用 type='draw'抽屉使用
  // statusDataSource 状态数据源
  const {
    patientOpen,
    routes,
    onChange,
    type,
    statusDataSource,
    hideField,
    tableScroll,
    value: oldValue,
    stores: {
      common: { currentApplication },
      user: {
        initKey,
        getOrderPatientList,
        currentOrderPatient,
        setCurrentOrderPatient,
      },
    },
  } = props;

  // console.log(getOrderPatientList, 'getOrderPatientList');

  const [value, setValue] = useControllableState({
    onChange,
    value: oldValue,
  });
  const [loading, setLoading] = useState<boolean>(true);

  // const [tableList, setTableList] = useState<any>();
  const wardId = currentApplication?.department?.id;
  const PatientRef = useRef<VSTableInstance<any, 'text'> | null>(null);
  const form = PatientRef?.current?.getSearchForm();
  const status = Form?.useWatch('status', form) ?? 'IN_DEPARTMENT';
  const bedGroupId = Form?.useWatch('bedGroupId', form)?.id;
  const startTime = Form?.useWatch('startTime', form);
  const endTime = Form?.useWatch('endTime', form);
  const signaturePatient = Form?.useWatch('signaturePatient', form);

  const onFetch = useCallback(
    async () => {
      // if (!patientOpen && type !== 'page') return;
      if (wardId) {
        setLoading(true);
        const res = await getOrderPatientList({
          signaturePatient,
          wardId,
          status,
          bedGroupId,
          startTime,
          endTime,
        });
        setLoading(false);
        // setTableList(res?.data);
        props?.onDataSourceChange?.(res);
        return res;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      wardId,
      signaturePatient,
      status,
      bedGroupId,
      startTime,
      endTime,
      patientOpen,
    ],
  );

  const getDataSource = useCallback(async () => {
    const res =
      await vsf.services?.ClinicBedController_getListBedGroupVoByWardId_532946?.(
        {
          wardId,
        },
      );
    return res?.data;
  }, [wardId]);

  const disabledDate = (current) => {
    return (
      current > dayjs().add(1, 'day').startOf('date') ||
      current < dayjs().subtract(1, 'month').startOf('date')
    );
  };
  const handelSelect = (node) => {
    setValue?.(node);
    setCurrentOrderPatient?.(node);
  };

  useEffect(() => {
    if (type == 'page') setCurrentOrderPatient(null);
  }, [type, setCurrentOrderPatient]);

  useEffect(() => {
    // 刷新
    handelRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initKey]);

  useMount(() => {
    setValue?.('');
  });

  const handelRefresh = () => {
    PatientRef.current?.getSearchForm()?.resetFields();
    PatientRef.current?.reload();
    setValue?.('');
  };

  return (
    <VSPromiseTable
      id="patientTable"
      onFetch={onFetch}
      ref={PatientRef}
      onRow={(node) => {
        return {
          onDoubleClick: () => handelSelect(node),
        };
      }}
      pagination={false}
      searchFormRender={(...[, table]) => {
        return (
          <div className="aspirin-table-form-search-hide-box">
            {type && type == 'page' && (
              <SearchContainer.Header
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                title="患者列表"
              >
                <Button
                  onClick={handelRefresh}
                  type="primary"
                  icon={<SyncOutlined spin={loading} />}
                >
                  刷新
                </Button>
              </SearchContainer.Header>
            )}
            {table}
          </div>
        );
      }}
      className={
        type == 'page'
          ? 'aspirin-table-body-empty-transparent-background-color aspirin-table-row-hover-background-color '
          : 'aspirin-table-body-empty-transparent-background-color-white aspirin-table-row-hover-background-color-white'
      }
      rowClassName={(record) => {
        if (type == 'page') {
          return record?.patient?.id == currentOrderPatient?.patient?.id
            ? 'aspirin-table-row-select-background-color'
            : '';
        } else {
          return record?.patient?.id == currentOrderPatient?.patient?.id
            ? 'aspirin-table-row-select-background-color-white'
            : '';
        }
      }}
      scroll={{
        y: tableScroll
          ? tableScroll
          : getScrollY(
              type === 'page'
                ? isMinScreen
                  ? 250
                  : 450
                : isMinScreen
                ? 220
                : 480,
            ),
      }}
    >
      <VSTableColumn
        dataIndex={['status']}
        valueType="radioButton"
        search
        order={2}
        columnKey={'status'}
        hideInTable
        dataSource={
          statusDataSource
            ? statusDataSource
            : [
                { label: '在科', value: 'IN_DEPARTMENT' },
                { label: '转出', value: 'TRANSFER_OR_LEAVE' },
                // { label: '未签名患者', value: 'signaturePatient' },
              ]
        }
        searchFieldProps={{
          style: {},
        }}
        fieldProps={{
          defaultValue: 'IN_DEPARTMENT',
        }}
      />
      <VSTableColumn
        dataIndex={['startTime']}
        title="开始时间"
        valueType="date"
        search={status === 'TRANSFER_OR_LEAVE'}
        order={0}
        columnKey={'startTime'}
        hideInTable
        initialValue={dayjs()
          .subtract(1, 'month')
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss')}
        fieldProps={{
          format: 'YYYY-MM-DD HH:mm:ss',
          disabledDate: disabledDate,
        }}
      />
      <VSTableColumn
        dataIndex={['endTime']}
        title="结束时间"
        valueType="date"
        search={status === 'TRANSFER_OR_LEAVE'}
        order={0}
        columnKey={'endTime'}
        hideInTable
        initialValue={dayjs()
          .add(1, 'day')
          .startOf('date')
          .format('YYYY-MM-DD HH:mm:ss')}
        fieldProps={{
          format: 'YYYY-MM-DD HH:mm:ss',
          disabledDate: disabledDate,
        }}
      />
      <VSTableColumn
        dataIndex={['bedGroupId']}
        title="分组"
        valueType="select"
        search={status === 'IN_DEPARTMENT'}
        order={0}
        dataSource={getDataSource as any}
        fieldNames={{
          label: 'groupName',
          value: 'id',
        }}
        columnKey={'bedGroupId'}
        hideInTable
        fieldProps={{
          showSearch: true,
        }}
      />
      {hideField?.includes('signaturePatient') ? null : (
        <VSTableColumn
          dataIndex={['signaturePatient']}
          valueType="check"
          search={status === 'IN_DEPARTMENT'}
          order={1}
          title="未签名"
          columnKey={'signaturePatient'}
          hideInTable
          className="aspirin-table-check-item-box"
          fieldProps={{}}
          initialValue={false}
          searchFieldProps={{
            style: {
              display: 'flex',
              gap: 10,
            },
          }}
        />
      )}
      {status == 'IN_DEPARTMENT' && (
        <VSTableColumn
          dataIndex={['bed', 'bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
        />
      )}
      {status == 'TRANSFER_OR_LEAVE' && (
        <VSTableColumn
          dataIndex={['dischargeDateTime']}
          title="日期"
          valueType="data"
          width={165}
          render={(dom, value) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {value?.patientRecordStatus == 'IN_TRANSFER' ? (
                  <div className="patient_list_sign">转</div>
                ) : value?.patientRecordStatus == 'LEAVE' ? (
                  <div className="patient_list_sign">出</div>
                ) : (
                  ''
                )}
                {value?.dischargeDateTime &&
                  dayjs(value?.dischargeDateTime).format('YYYY-MM-DD HH:mm')}
              </div>
            );
          }}
        />
      )}
      <VSTableColumn
        dataIndex={['patient', 'name']}
        title="姓名"
        valueType="text"
        fieldProps={{}}
      />
      {/* <VSTableToolbar title="患者列表" /> */}
    </VSPromiseTable>
  );
};
export default definePage(PatientTable);
