import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0: {
    method: 'post',
    url: '/api/application/get-sys-config-base-code-without-root',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  BaseParameterController_getParameterConfigWithChildVoById_0d9419: {
    method: 'post',
    url: '/api/base-parameter/get-parameter-config-with-child-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ParameterConfigWithChildVoBaseParameterEntranceWebVo,
    ) => data,
  },
});
