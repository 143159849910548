import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockSupplyController_getPagedByDrugSupplyLogQto_c72272: {
    method: 'post',
    url: '/api/drug-storage/get-paged-by-drug-supply-log-qto',
    parameterFomatter: (data?: {
      qto: DrugSupplyLogQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugSupplyLogVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
