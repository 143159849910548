import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Tabs,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import painLevel0 from '@/assets/pain_level/pain_level0.png';
import painLevel1 from '@/assets/pain_level/pain_level1.png';
import painLevel2 from '@/assets/pain_level/pain_level2.png';
import painLevel3 from '@/assets/pain_level/pain_level3.png';
import painLevel4 from '@/assets/pain_level/pain_level4.png';
import painLevel5 from '@/assets/pain_level/pain_level5.png';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

const Index = (props, ref) => {
  const array1 = Array(11)
    .fill(0)
    .map((_, index) => {
      return { [index]: index };
    })
    .reduce((obj, item) => {
      return { ...obj, ...item };
    }, {});

  const imgList = [
    painLevel0,
    painLevel1,
    painLevel2,
    painLevel3,
    painLevel4,
    painLevel5,
  ];

  const [open, setOpen] = useState(false);
  const [level, setLevel] = useState<any>();
  const handelOpen = (v = false, value?) => {
    setOpen(v);
    setLevel(value?.nrsScore);
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));
  return (
    <div className="pain_level_modal">
      <Modal
        centered
        width={690}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={<div style={{ color: '#3276E8' }}>疼痛评估</div>}
        onOk={() => {
          vsf.refs?.preExamMainForm?.setFieldsValue({
            nrsScore: level,
          });
          handelOpen(false);
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <div
          style={{
            height: 50,
            // border: '1px solid red',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {imgList?.map((item, index) => {
            return (
              <img
                src={item}
                key={index}
                onClick={() => {
                  setLevel(2 * index);
                }}
                style={{
                  backgroundColor: 'pink',
                  width: 36,
                  height: 36,
                  borderRadius: 36,
                  cursor: 'pointer',
                }}
              />
            );
          })}
        </div>
        <div style={{ padding: '0 13px' }} className="pain_level_modal_slider">
          <Slider
            defaultValue={0}
            marks={{ ...array1 }}
            step={null}
            max={10}
            trackStyle={{
              background: '#005FFF',
            }}
            className="icon18"
            value={level}
            onChange={(v) => {
              setLevel(v);
            }}
          ></Slider>
        </div>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 疼痛评估
