import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  VSTableAction,
  message,
  Button,
  Popover,
  Input,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React, { useCallback } from 'react';

import { getScrollY } from '@/utils';
import { askSearch } from '@/pages/Index/components/func/ask';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import PermissionMaintenance from './permissionMaintenance/index';
import CreateStaff from './edit';
import { isMinScreen } from '@/config';

const StaffList = (props) => {
  const onFetch = useCallback(async (params) => {
    console.log(params, 'params', Object.values(params.search));
    const searchValue = Object.values(params.search)?.filter(
      (i) => i?.length > 0,
    );
    // if (
    //   (!params?.extra?.id && Object.keys(params.search).length === 0) ||
    //   params?.extra.disabledAdd
    // )
    //   return [];
    // 院区+搜索条件
    if (
      (params?.extra.disabledAdd &&
        Object.keys(params.search).length != 0 &&
        searchValue?.length > 0) ||
      (params?.extra.id && !params?.extra.disabledAdd) ||
      (!params?.extra.id &&
        Object.keys(params.search).length != 0 &&
        searchValue?.length > 0)
    ) {
      const _params = params?.extra.disabledAdd
        ? { branchInstitutionIdIs: params?.extra?.key }
        : params?.extra.id && !params?.extra.disabledAdd
        ? {
            departmentIdIs: params?.extra.id,
          }
        : !params?.extra.id &&
          Object.keys(params.search).length != 0 &&
          searchValue?.length > 0
        ? {}
        : {};
      const res =
        await vsf?.services?.StaffDetailDtoController_queryPaged_39a7f7?.({
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            // ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            // departmentIdIs: !params?.extra.disabledAdd ? params?.extra.id : '',
            // branchInstitutionIdIs: params?.extra?.key,
            loginNameLike: params.search?.staffNameLike,
            staffNumberLike: params.search?.staffNameLike,
            jobClassCodeIs: params.search?.jobClassCode,
            staffNameLike: params.search?.staffNameLike,
            titleCodeIs: params.search?.titleCode,
            ..._params,
          },
        });
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return [];
    }
  }, []);
  console.log(props, 'pp');
  return (
    <VSPromiseTable
      scroll={{
        y: 0,
      }}
      className="aspirin-table aspirin-table-no-background-box table_overflow_435"
      searchConfig={{
        // labelWidth: isMinScreen ? 60 : 110,
        labelWidth: 120,
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        disableSearchButtonLoading: true,
        searchButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        resetButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        optionRender: (...arr) => {
          const [_, __, dom] = arr;
          return (
            <SearchContainer.Header title="筛选">{dom}</SearchContainer.Header>
          );
        },
      }}
      searchFormRender={(item, dom) => {
        return (
          <SearchContainer
          // mode="inline"
          // formPadding={isMinScreen ? '0 80px 0 40px' : '0 80px 0 180px'}
          >
            {dom}
          </SearchContainer>
        );
      }}
      // form={{
      //   labelAlign: 'left ',
      // }}
      id="StaffListTable"
      vsid="90545"
      onFetch={onFetch}
      addLinkPage={{
        linkPage: () => <CreateStaff departmentInfo={props.value} />,
        modalProps: {
          title: '新增人员',
          className: 'aspirin-linkpage',
          width: isMinScreen ? 956 : 1356,
          centered: true,
          bodyStyle: {
            // height: isMinScreen ? 130 : 700,
            overflowY: 'scroll',
            backgroundColor: '#f4f5f7',
          },
          className: 'modalHeight752',
        },
        onLinkPageValidate: async () => {
          try {
            const res =
              await vsf.refs.StaffForm?.validateFieldsReturnFormatValue();
            const response =
              await vsf?.services?.StaffBOController_createStaff_78ec6d?.({
                btoParam: {
                  ...(res ?? {}),
                  doctorBto: {
                    ...res?.doctorDto,
                    clinicDepartmentId: res?.staffDepartmentId,
                    tutorStaffId:
                      res?.doctorDto?.tutorStaffId?.staffId ??
                      res?.doctorDto?.tutorStaffId?.id,
                  },
                  stopIndicator: 'USING',
                  staffDepartmentId: res?.staffDepartment?.id,
                },
              });
            if (response?.code == 200) {
              message.success('新增成功');
              if (!props?.value?.id || props?.value?.disabledAdd) {
                vsf.refs.StaffListTable?.getSearchForm()?.setFieldsValue({
                  staffNameLike: response?.data?.staffName,
                });
                setTimeout(() => {
                  vsf.refs.StaffListTable?.getSearchForm()?.submit();
                }, 1000);
                return true;
              } else {
                setTimeout(() => {
                  vsf?.refs?.StaffListTable?.reload();
                }, 1000);
              }
              return true;
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        },
      }}
      onAdd={async (params) => {
        // const res = await vsf?.services?.StaffBOController_createStaff_78ec6d?.(
        //   {
        //     btoParam: {
        //       ...(params ?? {}),
        //       doctorBto: {
        //         ...params?.doctorDto,
        //         clinicDepartmentId: params?.staffDepartmentId,
        //       },
        //       stopIndicator: 'USING',
        //     },
        //   },
        // );
        // return res?.data;

        return params;
      }}
      editable={{
        editType: 'single',
        columnProps: {
          hideInTable: true,
        },
      }}
      extraParams={props.value}
    >
      <VSTableColumn
        dataIndex={['staffNumber']}
        title="员工编号"
        valueType="text"
        formItemProps={{
          rules: [
            { message: 'emp_no长度不合法', type: 'string', min: 0, max: 20 },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['nationCode']}
        title="国家编码"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '国家便面长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staffName']}
        title="员工姓名"
        valueType="text"
        search
        columnKey={'staffNameLike'}
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
          // className: 'width240',
          searchConfig: {},
        }}
        fieldProps={{
          placeholder: '请输入员工姓名/工号/登录名',
        }}
      />

      <VSTableColumn
        dataIndex={['staffDepartment', 'departmentName']}
        title="员工科室"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        // dataSource={vsf.stores.dbenums?.enums?.JOB_CLASS_DICT}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['jobClassCode']}
        title="工作类别"
        valueType="select"
        search
        fieldProps={{
          showSearch: true,
          allowClear: true,
        }}
        renderFormItem={() => (
          <Select
            // className="width240"
            options={vsf.stores.dbenums?.enums?.JOB_CLASS_DICT}
            filterOption={askSearch}
          />
        )}
        searchConfig={{}}
        dataSource={vsf.stores.dbenums?.enums?.JOB_CLASS_DICT}
      />

      <VSTableColumn
        dataIndex={['titleCode']}
        title="技术职务"
        valueType="select"
        search
        fieldProps={{
          showSearch: true,
          allowClear: true,
        }}
        renderFormItem={() => (
          <Select
            // className="width240"
            options={vsf.stores.dbenums?.enums?.TITLE_DICT}
            filterOption={askSearch}
          />
        )}
        dataSource={vsf.stores.dbenums?.enums?.TITLE_DICT}
      />

      <VSTableColumn
        dataIndex={['offDuty']}
        title="在岗标志"
        valueType="switch"
        fieldProps={{}}
        render={(item, props) => {
          return (
            <span
              type="text"
              style={{
                color: `var(--${!props.stopIndicator ? 'green' : 'red'})`,
                fontWeight: 500,
              }}
            >
              {props.offDuty ? '不在岗' : '在岗'}
            </span>
          );
        }}
      />

      <VSTableColumn
        title="操作"
        fieldProps={{}}
        dataIndex={['option']}
        valueType="custom"
        width={isMinScreen ? 190 : 260}
      >
        <VSTableAction
          linkPage={{
            updateTable: true,
            updateType: 'reload',
            linkPage: () => <CreateStaff departmentInfo={props.value} />,
            modalProps: {
              title: '人员维护',
              className: 'aspirin-linkpage',
              width: isMinScreen ? 956 : 1356,
              centered: true,
              bodyStyle: {
                // height: 700,
                overflowY: 'scroll',
                backgroundColor: '#f4f5f7',
              },
              className: 'modalHeight752',
              footerRender: (
                dom,
                { okButtonProps, cancelButtonProps },
                value,
                row,
              ) => {
                return (
                  <>
                    <Button
                      style={{
                        color: '#5D6268',
                        backgroundColor: '#f0f0f0',
                        border: 'none',
                      }}
                      {...cancelButtonProps}
                    >
                      取消
                    </Button>
                    <Button
                      onClick={async () => {
                        Tip({
                          content: `是否删除员工：${row?.staffName}`,
                          onOk: async () => {
                            const res =
                              await vsf?.services?.StaffBOController_deleteStaff_87868c?.(
                                {
                                  btoParam: { id: row?.id },
                                },
                              );
                            if (res.code === 200) {
                              vsf.refs.StaffListTable.reload();
                              message.open({
                                type: 'success',
                                content: `删除员工成功`,
                              });
                              cancelButtonProps.onClick();
                            }
                            return res?.data;
                          },
                          cancelText: '取消',
                        });
                      }}
                      style={{
                        color: 'red',
                        backgroundColor: '#f0f0f0',
                        border: 'none',
                      }}
                    >
                      删除
                    </Button>
                    <Button {...okButtonProps}>保存</Button>
                  </>
                );
              },
            },
            onLinkPageValidate: async () => {
              try {
                const params =
                  await vsf.refs.StaffForm?.validateFieldsReturnFormatValue();
                console.log(params, 'params');
                const res =
                  await vsf?.services?.StaffBOController_updateStaff_85164a?.({
                    btoParam: {
                      ...(params ?? {}),
                      doctorBto: {
                        ...params?.doctorDto,
                        tutorStaffId:
                          params?.doctorDto?.tutorStaffId?.staffId ??
                          params?.doctorDto?.tutorStaffId?.id,
                      },
                      stopIndicator: 'USING',
                      staffDepartmentId: params?.staffDepartment?.id,
                    },
                    extApiParam: {},
                  });
                if (res?.code == 200) {
                  // console.log(props, 'propsprops');
                  message.success('保存成功');

                  return true;
                } else {
                  return false;
                }
              } catch (e) {
                return false;
              }
            },
          }}
        >
          <Popover content="编辑">
            <Icon type="icon-bianji" size={24} />
          </Popover>
        </VSTableAction>
        <VSTableAction
          linkPage={{
            onLinkPageValidate: async (row) => {
              Tip({
                content: `确认重置用户：{${row?.staffName}的密码？}`,
                onOk: async () => {
                  const res =
                    await vsf?.services?.AccountController_resetPassword_68f7e8?.(
                      {
                        staffId: row?.id,
                      },
                    );
                  if (res.code === 200) {
                    vsf.refs.StaffListTable.reload();
                    message.open({
                      type: 'success',
                      content: `重置用户密码成功`,
                    });
                  }
                  return res?.data;
                },
                cancelText: '取消',
              });
            },
          }}
        >
          <Popover content="重置密码">
            <Icon type="icon-zhongzhimima" size={24} />
          </Popover>
        </VSTableAction>
        <VSTableAction
          linkPage={{
            updateTable: true,
            updateType: 'reload',

            linkPage: (v) => (
              <PermissionMaintenance departmentInfo={props.value} data={v} />
            ),
            modalProps: {
              title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '15px' }}>工作站维护</div>
                  {/* <Input
                    prefix={
                      <Icon style={{ color: '#85898D' }} type="icon-sousuo" />
                    }
                    style={{ width: '200px' }}
                    placeholder="请输入工作站名称"
                  /> */}
                </div>
              ),
              className: 'aspirin-linkpage width1376',
              width: 1200,
              bodyStyle: {
                // height: 600,
              },
              className: 'modalHeight680',
            },
            onLinkPageValidate: async (value) => {
              const params =
                vsf.refs.applicationStaffRoleList.getValues() ?? [];

              const _params = params?.map((i, index) => {
                return i?.application
                  ? {
                      ...i,
                      staffVsRoleBtoList: i?.staffVsRoleWithRoleVoList,
                      applicationId: i?.application?.id,
                      sortNumber: index,
                    }
                  : {
                      ...i,
                      sortNumber: index,
                      applicationId: i?.id,
                      staffId: value?.staff?.id ?? value?.id,
                      staffVsRoleBtoList: (
                        i?.staffVsRoleWithRoleVoList ?? []
                      )?.map((item) => {
                        return {
                          applicationId: i?.id,
                          roleId: item?.roleId,
                        };
                      }),
                    };
              });

              const res =
                await vsf.services.PrivilegeController_createOrUpdateApplicationStaffWithRole_0bd2f6(
                  {
                    staffId: value?.staff?.id ?? value?.id,
                    btoList: _params,
                  },
                );
              if (res?.code == 200) {
                message.success('保存成功');
                return true;
              } else {
                return false;
              }
            },
          }}
        >
          <Popover content="维护工作站">
            <Icon type="icon-weihugongzuozhan" size={24} />
          </Popover>
        </VSTableAction>
        <VSTableAction
          linkPage={{
            onLinkPageValidate: async (row) => {
              Tip({
                content: `是否删除员工：${row?.staffName}`,
                onOk: async () => {
                  const res =
                    await vsf?.services?.StaffBOController_deleteStaff_87868c?.(
                      {
                        btoParam: { id: row?.id },
                      },
                    );
                  if (res.code === 200) {
                    vsf.refs.StaffListTable.reload();
                    message.open({
                      type: 'success',
                      content: `删除员工成功`,
                    });
                  }
                  return res?.data;
                },
                cancelText: '取消',
              });
            },
          }}
        >
          <Popover content="删除">
            <Icon type="icon-shanchu1" size={24} />
          </Popover>
        </VSTableAction>
      </VSTableColumn>
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title="员工列表"
      >
        <VSTableAddition className="icon16" size="large" children="添加" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(StaffList);
