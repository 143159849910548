import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientJourneyController_getPatientJourneyDetail_09a7d6: {
    method: 'post',
    url: '/api/clinic-record-order/get-patient-journey-detail',
    parameterFomatter: (data?: {
      orderId: number;
      icon: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientJourneyDetailVoClinicRecordOrderEntranceWebVo,
    ) => data,
  },
});
