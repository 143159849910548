/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Col,
  Divider,
  message,
  Modal,
  Row,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import { values } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { getScrollY } from '@/utils';

function ParModal(props) {
  const { payParamData, currentPatient, preData } = props;
  //弹窗相关
  const chargeType = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;
  const identity = vsf.stores.dbenums.enums.IDENTITY_DICT;
  const [payWays, setPayWays] = useState<string>('INTEGRATION_PAYMENT');
  const [chargeData, setChargeData] = useState<any>();
  const [identityData, setIdentityData] = useState<any>();
  const formRef = useRef<any>();
  useEffect(() => {
    chargeType &&
      chargeType.map((item) => {
        if (item?.value === currentPatient?.outpVisit?.chargeTypeCode) {
          setChargeData(item);
        }
      });
    identity?.map((item) => {
      if (item?.value === payParamData?.identityType) {
        setIdentityData(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeType, identity, formRef]);
  useEffect(() => {
    preData &&
      vsf?.refs?.ClinicSettlementForm?.setFieldsValue({
        totalCharge: `${Number(
          preData?.outpSettleMasterVo?.totalCharge ?? 0,
        ).toFixed(2)}`,
      });
  }, [preData]);
  return (
    <div className="pay-layout-container" style={{ height: '550px' }}>
      <div className="pay-person-detaill">
        <Row style={{ height: '54px' }}>
          <Col span={6}>
            姓名：
            <span className="person-detaill">
              {currentPatient?.outpVisit.name}
            </span>
            {/* <span>{feibie}</span> */}
          </Col>
          <Col span={6}>
            费别：
            <span className="person-detaill">{chargeData?.label}</span>
          </Col>
          <Col span={6}>
            人员类别：
            <span className="person-detaill">
              {currentPatient?.outpVisit?.identityType}
            </span>
          </Col>
          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="pay-person-content">
        <div className="pay-person-content-left">
          <div>
            <Row>
              {preData?.outpSettleMasterVo?.outpSettleApportionItemDtoList?.map(
                (item) => {
                  return (
                    <>
                      <Col span={12}>
                        <span className="total-cost">
                          {item?.settleApportionName}：
                        </span>
                        <span className="total-number">
                          {Number(item?.apportionCharge ?? 0).toFixed(2)}元
                        </span>
                      </Col>
                    </>
                  );
                },
              )}
            </Row>
          </div>
        </div>
        <div className="pay-person-content-right">
          <div className="receivable-accounts">
            <div>应收款</div>
            <div className="receivable-number">
              {Number(preData?.outpSettleMasterVo?.totalCharge ?? 0).toFixed(2)}
              元
            </div>
          </div>
          <VSForm id="ClinicSettlementForm" ref={formRef} autoFocusFirstInput>
            <VSFormLayout
              key="1"
              columnCount={1}
              labelWidth={120}
              style={{ marginTop: '28px' }}
            >
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">聚合支付</div>}
                fieldProps={{
                  style: { height: '48px' },
                  placeholder: '请输入',
                  onChange: (item) => {
                    const remaining =
                      (Number(item.target.value) * 100 -
                        Number(payParamData?.selfPaymentTotalCost) * 100) /
                      100;
                    // vsf?.refs?.clinicOutpRegisterMainRegisterForm.setFieldsValue(
                    //   { remaining: remaining },
                    // );
                  },
                }}
                valueType="text"
                // rules={[
                //   {
                //     message: 'staff_name长度不合法',
                //     type: 'string',
                //     min: 0,
                //     max: 60,
                //   },
                // ]}
              />
            </VSFormLayout>
            <VSFormLayout key="2" columnCount={1} labelWidth={120}>
              {/* <VSFormItem
                name={['remaining']}
                label={<div className="receivable-form-title">找零</div>}
                fieldProps={{
                  style: { height: '48px' },
                  placeholder: '请输入找零',
                }}
                valueType="text"
                rules={[
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
              /> */}
            </VSFormLayout>
            <VSFormLayout key="3" columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['paymentDetail', 'paymentType']}
                valueType="radio"
                dataSource={[
                  { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
                  { label: '现金', value: 'CASH', disabled: true },
                  { label: '银联卡', value: '3', disabled: true },
                  { label: '支票', value: '4', disabled: true },
                ]}
                fieldProps={{
                  defaultValue: 'INTEGRATION_PAYMENT',
                  onChange: (item) => {
                    setPayWays(item);
                  },
                }}
              />
            </VSFormLayout>

            {payWays === 'INTEGRATION_PAYMENT' && (
              <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                <VSFormItem
                  name={['paymentDetail', 'paymentAmount']}
                  label={<div className="receivable-form-title">授权码</div>}
                  fieldProps={{
                    style: { height: '48px' },
                    placeholder: '请输入授权码',
                    autoFocus: true,
                  }}
                  transform={(v) => {
                    return { paymentAmount: Number(v ?? 0) };
                  }}
                  valueType="text"
                />
              </VSFormLayout>
            )}
            {payWays === '4' && (
              <>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={<div className="receivable-form-title">支票号</div>}
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入支票号',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行名称</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行名称',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="6" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行账户</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行账户',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
              </>
            )}
          </VSForm>
        </div>
      </div>
    </div>
  );
}
function List({ preData, currentPatient }) {
  const [chargeData, setChargeData] = useState<any>();
  const chargeType = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;
  const identity = vsf.stores.dbenums.enums.IDENTITY_DICT;
  useEffect(() => {
    chargeType.map((item) => {
      if (item?.value === currentPatient?.outpVisit?.chargeTypeCode) {
        setChargeData(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeType, identity]);
  return (
    <div className="pay-layout-container" style={{ height: '550px' }}>
      <div className="pay-person-detaill">
        <Row>
          <Col span={6}>
            姓名：
            <span className="person-detaill">
              {currentPatient?.outpVisit.name}
            </span>
          </Col>
          <Col span={6}>
            费别：
            <span className="person-detaill">{chargeData?.label}</span>
          </Col>
          <Col span={6}>
            人员类别：
            <span className="person-detaill">
              {currentPatient?.outpVisit?.identityType}
            </span>
          </Col>
          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="pay-person-content">
        <VSTable
          id="clinicMiddleSettleBillingDetail"
          value={preData?.outpSettleMasterVo?.outpBillingDetailDtoList}
          pagination={false}
          scroll={{ y: 500 }}
        >
          <VSTableColumn
            dataIndex={['orderDate']}
            title="开立时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.orderDate}>{value?.orderDate}</Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderText']}
            title="医嘱"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.orderText}>{value?.orderText}</Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderDoctorName']}
            title="开单人"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.orderDoctorName}>
                    {value?.orderDoctorName}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderDepartmentName']}
            title="开单科室"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.orderDepartmentName}>
                    {value?.orderDepartmentName}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['performDepartmentName']}
            title="执行科室"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.performDepartmentName}>
                    {value?.performDepartmentName}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['itemName']}
            title="项目"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.itemName}>{value?.itemName}</Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['price']}
            title="单价"
            valueType="digit"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.price}>
                    {Number(value?.price).toFixed(2)}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['amount']}
            title="数量"
            valueType="digit"
            fieldProps={{}}
            width={120}
            render={(_, value) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={value?.amount}>
                    {Number(value?.amount).toFixed(2)}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['tallyIndicator']}
            title="托收"
            valueType="switch"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['cost']}
            title="小记"
            valueType="digit"
            fieldProps={{}}
            render={(_, values) => {
              return Number(values?.cost).toFixed(2);
            }}
          />
        </VSTable>
      </div>
    </div>
  );
}
function index(props, ref) {
  const { user } = vsf?.stores ?? {};
  const { outVisitInfo } = user || {};
  const [isPayAndList, setIsPayList] = useState<string>('pay');

  const [open, setOpen] = useState<boolean>(false);

  // const [preData, setPreData] = useState<any>();

  const { data, run, runAsync } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpBillingController_queryAllUnsettledOutpVisitRecord_fad1e4?.(
          {
            patientId: params?.patientId,
            displayId: params?.displayId,
            outpVisitId: params?.outpVisitId,
            clinicMiddleSettleIndicator: params?.clinicMiddleSettleIndicator,
          },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const { data: preData, run: preRun } = useRequest<any, any>(
    async (params) => {
      const res = await vsf.services?.OutpSettleController_preSettle_f20115?.({
        patientId: params?.patientId,
        selfPaymentIndicator: params?.selfPaymentIndicator,
        outpVisitUnsettledRecordVos: params?.outpVisitUnsettledRecordVos,
      });
      return res?.data;
    },
    { manual: true },
  );

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    preData && setOpen(true);
  }, [preData]);

  const onOpen = (v) => {
    runAsync({
      patientId: outVisitInfo?.patient?.id,
      displayId: Number(outVisitInfo?.patient?.displayId),
      outpVisitId: outVisitInfo.outpVisit.id || outVisitInfo?.id,
      clinicMiddleSettleIndicator: true,
    }).then(async (item) => {
      const paramData = {
        patientId: outVisitInfo?.patient?.id,
        selfPaymentIndicator: false,
      };
      if (item.outpVisitUnsettledRecord[0]) {
        paramData['outpVisitUnsettledRecordVos'] = [
          item.outpVisitUnsettledRecord[0],
        ];
      } else {
        paramData['outpVisitUnsettledRecordVos'] = [];
      }

      await preRun(paramData);
    });
  };

  useImperativeHandle(ref, () => ({
    onOpen,
    onClose,
  }));

  const { run: settlementRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_confirmSettle_b06b45?.({
          parameterEo: params,
        });
      if (res?.code === 200) {
        message.success('结算成功');
        onClose();
        setTimeout(async () => {
          if (outVisitInfo) {
            const res =
              await vsf.services?.OutpBillingController_queryAllUnsettledOutpVisitRecord_fad1e4?.(
                {
                  patientId: params?.patientId,
                  displayId: Number(params?.patientDisplayId),
                  outpVisitId: outVisitInfo.outpVisit.id,
                  clinicMiddleSettleIndicator: true,
                },
              );
            if (res?.data?.outpVisitUnsettledRecord[0]) {
              Modal.confirm({
                content: '还有未结算费用，是否继续结算？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                  preRun({
                    patientId: outVisitInfo?.patient?.id,
                    selfPaymentIndicator: false,
                    outpVisitUnsettledRecordVos: [
                      res?.data.outpVisitUnsettledRecord[0],
                    ],
                  });
                },
                onCancel: () => {},
              });
            }
          }
        }, 2000);
      }
      return res?.data;
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    if (outVisitInfo?.outpVisit) {
      run({
        patientId: outVisitInfo?.patient?.id,
        displayId: Number(outVisitInfo?.patient?.displayId),
        outpVisitId: outVisitInfo.outpVisit.id,
        clinicMiddleSettleIndicator: true,
      });
    }
  }, [outVisitInfo]);

  const payModalConfim = () => {
    const parameterEo = {
      paymentDetail: [
        {
          paymentType: 'INTEGRATION_PAYMENT',
          paymentAmount: preData?.outpSettleMasterVo?.totalCharge,
        },
      ],
      tallyIndicator: false,
      patientDisplayId: outVisitInfo?.patient?.displayId,
      outpPreSettleTradeId: preData && preData.outpPreSettleTradeId,
      totalCost: preData?.outpSettleMasterVo?.totalCost,
      totalCharge: preData?.outpSettleMasterVo?.totalCharge,
      patientId: preData.outpSettleMasterVo.patientId,
      clinicMiddleSettleIndicator: true,
    };
    settlementRun(parameterEo);
  };

  const items: TabsProps['items'] = [
    {
      key: 'pay',
      label: '支付',
      children: <ParModal currentPatient={outVisitInfo} preData={preData} />,
    },
    {
      key: 'list',
      label: '费用明细',
      children: <List preData={preData} currentPatient={outVisitInfo} />,
    },
  ];
  return (
    <Modal
      open={open}
      title={<div style={{ color: '#3276E8' }}>诊间结算</div>}
      onCancel={() => {
        setOpen(false);
        setIsPayList('pay');
      }}
      okText="确定"
      cancelText="取消"
      width={1200}
      style={{ height: '80%' }}
      onOk={payModalConfim}
      okButtonProps={{
        disabled: isPayAndList === 'list' ? true : false,
      }}
    >
      <Tabs
        defaultActiveKey="isPayAndList"
        items={items}
        onChange={(v) => {
          setIsPayList(v);
        }}
      />
    </Modal>
  );
}

export default React.forwardRef(index);
