import './index.less';

import vsf from '@vs/vsf-boot';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';

import { Determine } from '@/pages/Index/components';

import InOrder from './in';
import OutOrder from './out';

const Index = (props) => {
  const { detail, id, visitRecordHistoryType } = props;

  const [isShowDetail, setIsShowDetail] = useState(true);
  const [currentReport, setCurrentReport] = useState<any>();
  const {
    hospitalType,
    currentPatient: { patientId },
  } = vsf?.stores?.user || {
    currentPatient: {},
  };
  const [arr, setArr] = useState([
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
    {
      show: false,
    },
  ]);

  const handelShow = (index?) => {
    const tempList = cloneDeep(arr);
    setArr(
      arr?.map((item, _idx) => ({
        show: _idx === index ? !item.show : item.show,
      })),
    );
  };

  return (
    <div className="doctor_advice">
      <Determine
        condition={visitRecordHistoryType === 'OUTP'}
        fallback={
          <InOrder
            id={id}
            visitRecordHistoryType={visitRecordHistoryType}
            patientId={patientId}
          />
        }
      >
        <OutOrder
          id={id}
          visitRecordHistoryType={visitRecordHistoryType}
          patientId={patientId}
        />
      </Determine>
    </div>
  );
};

export default Index;
