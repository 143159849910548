import React, { useState, useEffect, useCallback, cloneElement } from 'react';
import vsf from '@vs/vsf-boot';
import Icon from '@/module/Icon';
import { VSTableColumn, VSTable, Button, message } from '@vs/vsf-kit';
import classNames from 'classnames';

import './index.less';
import Tip from '@/pages/Index/components/func/Tip';
import SelectTag from '@/pages/Index/components/func/SelectTag';

const Index = (props) => {
  const { application } = props;
  const [currentRoleId, setCurrentRoleId] = useState();
  const [roleList, setRoleList] = useState([]);
  const [roleInfo, setRoleInfo] = useState([]);
  const [data, setData] = useState([]);
  const [DtoList, setDtoList] = useState([]);
  const [compare, setCompare] = useState([]);
  const getRoleList = async (id) => {
    const res =
      await vsf?.services?.ApplicationVsRoleDtoController_getByApplicationId_4a7f46(
        {
          applicationId: id,
        },
      );
    setCurrentRoleId(res?.data[0]?.id);
    setRoleList(res?.data || []);
  };
  const getRoleListFunc = async (id) => {
    const res =
      await vsf?.services?.FunctionController_getByPermissionFunctionList_c8a3dd(
        {
          applicationId: id,
        },
      );
    setData(res.data);
  };
  const getRoleDetailInfo = useCallback(
    async (id) => {
      if (id) {
        const res =
          await vsf?.services?.ApplicationVsRoleDtoController_getById_e96aaf?.({
            id,
          });
        setRoleInfo([res?.data]);
        setCompare(
          res?.data?.applicationRoleVsPermissionDtoList.map(
            (item) => item.functionVsPermissionId,
          ),
        );
      }
    },
    [currentRoleId],
  );

  const handleChooseRole = (data) => {
    setCurrentRoleId(data?.id);
  };
  useEffect(() => {
    if (application?.id) {
      getRoleListFunc(application?.id);
      getRoleList(application?.id);
    } else {
      // Tip({
      //   content: '请选择工作站分类'
      // })
    }
    setCurrentRoleId(null);
  }, [props]);

  useEffect(() => {
    if (currentRoleId) {
      getRoleDetailInfo(currentRoleId);
    }
  }, [currentRoleId]);
  const onClick = async () => {
    const res =
      await vsf?.services?.ApplicationVsRoleBOController_updateRolePermission_8badc8?.(
        {
          btoParam: {
            id: currentRoleId,
            applicationRoleVsPermissionBtoList: compare.map((item) => ({
              functionVsPermissionId: item,
            })),
          },
        },
      );
    if (res.code === 200) {
      message.success({
        content: '设置权限成功',
      });
    }
    return res?.data;
  };
  return (
    <div className="aspirin-application-privilege">
      <div className="left">
        <div className="aspirin-list">
          <div className="aspirin-block-title aspirin-list-title">角色</div>
          {roleList?.map((item) => (
            <div
              className={classNames('aspirin-list-item', {
                'aspirin-list-item-active': item?.id === currentRoleId,
              })}
              onClick={() => {
                handleChooseRole(item);
              }}
              key={item.id}
            >
              <div>{item?.roleName}</div>
              <Icon type="icon-you" size={24} />
            </div>
          ))}
        </div>
      </div>
      <div className="right">
        <div className="right-main">
          <VSTable
            className="table_overflow_340 aspirin-table aspirin-table-nopadding aspirin-table-no-background-box"
            vsid="91710"
            bordered
            rowSelection={{}}
            tableAlertRender={false}
            pagination={false}
            scroll={{ y: 0 }}
            value={data}
            editable={{
              // editType: 'multiple',
              columnProps: {
                hideInTable: true,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['name']}
              title="功能名称"
              valueType="text"
              width="30%"
              className="aspirin-application-privilege-name"
              formItemProps={{
                rules: [
                  {
                    message: '名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['functionVsPermissionDtoList', 'name']}
              title="权限名字"
              valueType="custom"
              render={(dom, data) => {
                return (
                  <SelectTag
                    dataSource={data.functionVsPermissionDtoList.map((item) => {
                      return { label: item.name, value: item.id };
                    })}
                    value={compare}
                    onChange={(selfValue) => {
                      if (compare.includes(selfValue.value)) {
                        setCompare(
                          compare.filter((item) => item !== selfValue.value),
                        );
                      } else {
                        setCompare([...compare, selfValue.value]);
                      }
                    }}
                  />
                );
              }}
              fieldProps={{
                mode: 'multiple',
              }}
            />
          </VSTable>
          <div className="aspirin-footer-box">
            <Button
              size="large"
              className="icon16"
              type="primary"
              onClick={onClick}
            >
              保存
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
