import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  VSFormItem,
  Select,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  Image,
  Button,
  VSTableAction,
  Space,
  message,
  Modal,
  VSForm,
  VSFormLayout,
  VSFormFooter,
  Form,
  Checkbox,
  Tooltip,
  VSTable,
} from '@vs/vsf-kit';
import './index.less';
import Del from '@/assets/icon/del.png';
import ParModal from './ParModal';
import { askFixed } from '../../components/func/ask';
import { numberToPercentage, subtract } from '@/utils/setDataUtil';
import { getPromiseHeight, removeObjectFalsy } from '@/utils';
import { isString } from 'lodash';
import { deepClone } from '@/module/cpoe/medical/editor/utils';
// const _ = require('lodash');
function MainTable({
  selectTable,
  isSelf,
  onResultDataChange,
  dataSorceChange,
  detailList,
  displayIdStatus,
  refresh,
  rowKeyChange,
  onTotalChange,
  onSelectTableChange,
}) {
  // 选择左侧的数据源
  const [selectTableData, setSelelctTableData] = useState();

  const [filterTempData, setFilterTempData] = useState();

  useEffect(() => {
    setSelelctTableData(selectTable);
  }, [selectTable]);

  const [preData, setPredata] = useState();
  const [open, setOpen] = useState(false);

  const settlement = async () => {
    const Params = deepClone(selectTable);
    const filterParam = Params?.filter(
      (item) => item?.outpBillingDetailList?.length !== 0,
    )?.map((ele) => {
      return {
        ...ele,
        outpBillingDetailList: ele?.outpBillingDetailList?.map((outp) => {
          return {
            ...outp,
            outpBillingDetailVoList: outp?.outpBillingDetailVoList?.map(
              (det) => {
                // 清空非托收id
                if (isString(det?.id)) det.id = null;
                return det;
              },
            ),
          };
        }),
      };
    });
    const res = await vsf.services?.OutpSettleController_preSettle_f20115?.({
      patientId: detailList?.patientId,
      selfPaymentIndicator: isSelf,
      outpVisitUnsettledRecordVos: filterParam,
    });
    if (res?.code === 200) {
      setOpen(true);
    }

    setPredata(res?.data);
    return res?.data;
  };
  useEffect(
    () =>
      vsf?.refs?.PayConfimFooterForm?.setFieldValue?.(
        'enterpriseInvoiceIndicator',
        preData?.enterpriseInvoiceIndicator,
      ),
    [preData?.enterpriseInvoiceIndicator],
  );
  const onChange = async (parent, record) => {
    vsf?.refs?.tableDispenseDetail?.update({
      ...record,
      hidden: true,
    });
    const { outpBillingDetailVoList: outp } = record;
    let i = 0;
    while (outp?.length > i) {
      vsf?.refs?.tableDispenseDetail?.update({
        ...outp?.[i],
        hidden: true,
      });
      i++;
    }
    let tempParent = [],
      outp_detail = parent?.outpBillingDetailList?.filter(
        (item) => item?.index !== record?.index,
      );
    if (outp_detail?.length) {
      tempParent?.push({
        ...parent,
        outpBillingDetailList: outp_detail,
      });
    }
    const data = selectTableData?.filter((item) => {
      return item?.id !== parent?.id;
    });
    onSelectTableChange([...data, ...tempParent]);
  };
  const onModalSave = async () => {
    const { totalCharge, remaining } =
        vsf?.refs?.clinicOutpRegisterMainRegisterFormResult?.getFieldsValue(),
      commonAmount = subtract(
        Number(totalCharge ?? 0),
        Number(remaining ?? 0),
        2,
      ),
      { enterpriseInvoiceIndicator, createOderNumber } =
        vsf?.refs?.PayConfimFooterForm?.getFieldsValue();

    const parameterEo = {
      paymentDetail: [
        {
          paymentType: 'CASH',
          paymentAmount: commonAmount,
        },
      ],
      tallyIndicator: false,
      patientDisplayId: displayIdStatus,
      outpPreSettleTradeId: preData?.outpPreSettleTradeId,
      totalCost: preData?.outpSettleMasterVo?.selfPaymentTotalCost,
      totalCharge: commonAmount,
      patientId: preData?.outpSettleMasterVo?.patientId,
      enterpriseInvoiceIndicator,
      createOderNumber,
    };
    const res = await vsf.services?.OutpSettleController_settle_b54c18?.({
      parameterEo: removeObjectFalsy(parameterEo),
    });

    if (res?.code === 200) {
      setOpen(false);
      message.success('结算成功');
      rowKeyChange([]);
      setSelelctTableData([]);
      onTotalChange([]);
      refresh();
    }
  };
  const clear = () => {
    dataSorceChange();
    onResultDataChange(undefined);
    setSelelctTableData(undefined);
    onTotalChange([]);
  };

  const onFetch = useCallback(
    async (params) => {
      if (!selectTableData) return { data: [] };
      let data = [],
        i = 0;
      while (selectTableData?.length > i) {
        let patient = selectTableData?.[i];
        let outp = patient?.outpBillingDetailList,
          o = 0;
        let outp__id;
        while (outp?.length > o) {
          outp__id =
            Math?.random() +
            patient?.outpVisitId +
            outp?.[o]?.receiptGroupId +
            o;
          data?.push({
            ...outp?.[o],
            id: outp__id,
            haeder: true,
            outpVisitId: patient?.outpVisitId,
            hidden: false,
          });
          let outpDetail = outp?.[o]?.outpBillingDetailVoList,
            d = 0;
          while (outpDetail?.length > d) {
            data?.push({
              ...outpDetail?.[d],
              hidden: false,
              outp__id,
            });
            d++;
          }
          o++;
        }
        i++;
      }
      return { data };
    },
    [selectTableData],
  );
  const [yMaxHeight, setYMaxHeight] = useState(609);

  getPromiseHeight('main')?.then((res) => {
    setYMaxHeight(res - 78);
  });

  const sharedOnCell = (value) => {
    if (value?.haeder) {
      return { colSpan: 0 };
    }

    return {};
  };
  return (
    <div className="content">
      <div className="main" id="main">
        <VSPromiseTable
          className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          scroll={{ x: 2000, y: yMaxHeight }}
          vsid="52859"
          id="tableDispenseDetail"
          expandable={{}}
          pagination={false}
          onFetch={onFetch}
          rowClassName={(node) => {
            let str = '';
            if (node?.haeder)
              str += ' table-title aspirin-table-row-background-box';
            if (node?.hidden) str += ' ele_hidden';
            return str;
          }}
          onRow={(node) => {
            return {
              onClick: async () => {
                if (node?.haeder) {
                  const outp = vsf?.refs?.tableDispenseDetail
                    ?.getValues()
                    ?.filter((ele) => ele?.outp__id == node?.id);
                  let i = 0;
                  while (outp?.length > i) {
                    let outpDetail = outp?.[i];
                    await vsf?.refs?.tableDispenseDetail?.update({
                      ...outpDetail,
                      hidden: !outpDetail?.hidden,
                    });
                    i++;
                  }
                }
              },
            };
          }}
          bordered
        >
          <VSTableColumn
            dataIndex={['itemName']}
            title="项目"
            valueType="text"
            width={300}
            fieldProps={{}}
            onCell={(value) => {
              return value?.haeder ? { colSpan: 7 } : { colSpan: 1 };
            }}
            render={(dom, node) => {
              return node?.haeder ? (
                <div className="haeder_title">
                  <span>{node?.receiptGroupId}</span>
                  <span>{node?.orderClass}</span>
                  <span>{node?.orderDate}</span>
                  <span>开单：{node?.orderDepartment}</span>
                  <span>{node?.orderDoctor}</span>
                </div>
              ) : (
                dom
              );
            }}
          />

          <VSTableColumn
            dataIndex={['price']}
            title="单价"
            valueType="text"
            fieldProps={{}}
            width={80}
            onCell={sharedOnCell}
            render={(_, { price }) => askFixed(price, 2)}
          />

          <VSTableColumn
            dataIndex={['amount']}
            title="数量"
            valueType="digit"
            fieldProps={{}}
            width={80}
            onCell={sharedOnCell}
          />

          <VSTableColumn
            dataIndex={['unit']}
            title="单位"
            valueType="select"
            fieldProps={{}}
            width={80}
            dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
            onCell={sharedOnCell}
          />
          <VSTableColumn
            onCell={sharedOnCell}
            dataIndex={['itemSpecification']}
            title="规格"
            valueType="text"
            render={(_, values) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={values.itemSpecification}>
                    {values.itemSpecification}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            onCell={sharedOnCell}
            dataIndex={['cost']}
            title="金额"
            valueType="text"
            width={100}
            fieldProps={{}}
            render={(_, values) => {
              return <>{askFixed(values?.cost, 2)}</>;
            }}
          />

          <VSTableColumn
            onCell={sharedOnCell}
            dataIndex={['totalSelfPaymentRatio']}
            title="自付比例"
            valueType="digit"
            width={80}
            render={(dom, value) => {
              if (value?.totalSelfPaymentRatio) {
                return numberToPercentage(value?.totalSelfPaymentRatio, 0);
              } else {
                return '0%';
              }
            }}
          />

          <VSTableColumn
            dataIndex={['performDepartmentName']}
            title="执行科室"
            valueType="digit"
            width={120}
            fieldProps={{}}
            render={(_, values) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={values.performDepartmentName}>
                    {values.performDepartmentName}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderDate']}
            title="业务时间"
            valueType="date"
            // fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
            fieldProps={{ format: 'YYYY-MM-DD' }}
            width={120}
            onCell={(value) => {
              return value?.haeder ? { colSpan: 2 } : { colSpan: 1 };
            }}
            render={(dom, node) => {
              return node?.haeder ? (
                <div className="haeder_title">
                  <span>{node?.tallyIndicator ? '[托收]' : '[非托收]'}</span>
                  <span>小计：{askFixed(node?.subTotal || 0, 2)}元</span>
                </div>
              ) : (
                dom
              );
            }}
          />

          <VSTableColumn
            onCell={sharedOnCell}
            dataIndex={['insuranceProvinceCode']}
            title="省码"
            valueType="listBox"
            fieldProps={{ children: <DateRangePicker /> }}
            render={(_, values) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={values.insuranceProvinceCode}>
                    {values.insuranceProvinceCode}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['insuranceCode']}
            title="国家码"
            valueType="listBox"
            fieldProps={{ children: <DateRangePicker /> }}
            render={(_, node) => {
              console.log(node.insuranceCode, 'node.insuranceCode');
              return node?.haeder ? (
                <Image
                  src={Del}
                  width={24}
                  preview={false}
                  onClick={(event) => {
                    event.stopPropagation();
                    let item = selectTableData?.find(
                      (ele) => ele?.outpVisitId === node?.outpVisitId,
                    );
                    onChange(item, node);
                  }}
                />
              ) : (
                <div className="text-overflow-omit">
                  <Tooltip placement="leftTop" title={node.insuranceCode}>
                    {node.insuranceCode}
                  </Tooltip>
                </div>
              );
            }}
          />
        </VSPromiseTable>
      </div>

      <div className="btn-bottm">
        <Space size={4}>
          <Button onClick={clear}>清屏</Button>
          <Button>其他操作</Button>
          <Button type="primary">一键核酸</Button>
          <Button type="primary">一键开单</Button>
          <Button type="primary">病种修改</Button>
          <Button type="primary" onClick={settlement}>
            结算
          </Button>
        </Space>
      </div>

      <Modal
        open={open}
        title={<div style={{ color: '#3276E8' }}>支付确认</div>}
        onCancel={() => {
          setOpen(false);
        }}
        okText={'确定'}
        cancelText={'取消'}
        width={1000}
        // wrapClassName='pay-confim-modal-wrap'
        footer={
          <div className="pay-confim-footer">
            {/* <div>
              <Checkbox defaultValue>生成票据</Checkbox>
              <Checkbox>单位发票</Checkbox>
            </div> */}
            <VSForm id="PayConfimFooterForm">
              <VSFormLayout columnCount={2}>
                <VSFormItem
                  style={{ margin: '0' }}
                  label="生成票据"
                  name={['createOderNumber']}
                  valueType="check"
                  fieldProps={{}}
                  initialValue={true}
                />
                <VSFormItem
                  style={{ margin: '0' }}
                  label="单位发票"
                  name={['enterpriseInvoiceIndicator']}
                  valueType="check"
                />
              </VSFormLayout>
            </VSForm>
            <div>
              <Space size={4}>
                <Button
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={onModalSave}>
                  确认
                </Button>
              </Space>
            </div>
          </div>
        }
      >
        <ParModal payParamData={preData} />
      </Modal>
    </div>
  );
}

export default MainTable;
