import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Tag } from '@vs/vsf-kit';
import React, { useState } from 'react';
import InstitutionTree from './tree';
import DictionaryList from './table';
import Container from '@/module/Container';
const FunctionManage = (props) => {
  /**
   * SystemConfig
   * @type {object}
   */
  const [systemConfig, setSystemConfig] = useState();

  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            block: 1,
            bgColor: 'var(--background)',
            padding: '24px',
          },
        ],
      }}
    >
      <InstitutionTree
        onChange={(_value) => {
          setSystemConfig(_value);
        }}
      />
      <DictionaryList value={systemConfig} />
    </Container>
  );
};
export default definePage(FunctionManage);
