import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitController_queryAllWaitTransferByErpVisitForPreExamPatientListQto_fe26ea:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/query-all-wait-transfer-by-erp-visit-for-pre-exam-patient-list-qto',
      parameterFomatter: (data?: {
        qto: ErpVisitForPreExamPatientListQtoClinicOutpVisitPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OutpVisitForPreExamPatientVoClinicOutpVisitEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicErpAdtController_rejectErpTransferForSearchList_0b2544: {
    method: 'post',
    url: '/api/clinic-erp-adt/reject-erp-transfer-for-search-list',
    parameterFomatter: (data?: { erpVisitId: number; patientId: number }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicErpAdtController_confirmErpTransferForSearchList_111049: {
    method: 'post',
    url: '/api/clinic-erp-adt/confirm-erp-transfer-for-search-list',
    parameterFomatter: (data?: { erpVisitId: number; patientId: number }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
