import vsf from '@vs/vsf-boot';
import {
  Alert,
  Checkbox,
  Form,
  InputNumber,
  Tag,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useSetState } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useRef, useState } from 'react';

import { CertificateSearch } from '@/components';
import { UsageUnit } from '@/components/InputUnit';
import Block from '@/module/Block';
import Container from '@/module/Container';
import { ButtonAndKeyDown, Determine, Flex } from '@/pages/Index/components';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import { getPromiseHeight, getScrollYRem } from '@/utils';

const onSpliced = (input) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'drugPrescriptionDispenseMasterVo.id');
      obj.prescriptionNumber = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.prescriptionNumber',
      );
      obj.doctorName = _.get(d, 'doctorName');
      obj.prescriptionAttribute = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.prescriptionAttribute',
      );
      obj.totalCharge = _.get(d, 'totalCharge');
      obj.orderDepartmentName = _.get(d, 'orderDepartmentName');
      obj.dispenseDate = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.dispenseDate',
      );
      obj.refundDrugDate = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.refundDrugDate',
      );
      obj.dispenseStaffName = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.dispenseStaff.staffName',
      );
      obj.dispenseWindowDescription = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.dispenseWindow.windowDescription',
      );
      obj.refundDrugStaffName = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.dispenseStaff.staffName',
      );
      obj.renfundDrugWindowDescription = _.get(
        d,
        'drugPrescriptionDispenseMasterVo.prescription.drugPrescriptionStatus.dispenseWindow.windowDescription',
      );
      obj.dailyDrugDispenseLogId = _.get?.(
        d,
        'drugPrescriptionDispenseMasterVo.drugPrescriptionDispenseBatchNumberRecord.dailyDrugDispenseLogId',
      );
      obj.refundDrugDetail = [];
      obj.refundDrugDetail.push(
        ...(_.get(
          d,
          'drugPrescriptionDispenseMasterVo.drugPrescriptionDispenseDetail',
        )?.map((d, i) => {
          const obj: any = {};
          obj.id = _.get(d, 'id');
          obj.orderText = _.get(d, 'order.orderText');
          obj.firmName = _.get(d, 'firm.firmName');
          obj.amount = _.get(d, 'amount');
          obj.packageUnit = _.get(d, 'packageUnit');
          obj.retailPrice = _.get(d, 'retailPrice');
          return obj;
        }) ?? []),
      );
      return obj;
    }) ?? []),
  );
  return output;
};

/**
 * 处方反退药
 */
const PrescriptionReturnMedicationDrug = (props) => {
  const [patient] = useOnChangeValue<any>('', async (patientDisplayId) => {
    const res =
      await vsf?.services?.ClinicOutpRegisterMainController_getPatientInfo_b111a4?.(
        {
          patientDisplayId,
        },
      );
    if (res?.code === 200) {
      setDataStore({
        费别: res?.data?.chargeTypeCode,
        性别: vsf.stores.dbenums.enums.SEX_DICT?.find(
          ({ value }) => value === res?.data?.gender,
        )?.label,
        姓名: res?.data?.name,
        工作单位: '',
        年龄: dayjs().diff(dayjs(res?.data?.birthday), 'year') + '岁',
      });
    }
    return res?.data?.patientId as unknown as string;
  });
  const [check] = useOnChangeValue<any[]>([]);
  const table = useRef<any>(null);
  const [dataStore, setDataStore] = useSetState({
    费别: '',
    性别: '',
    姓名: '',
    工作单位: '',
    年龄: '',
  });
  const onFetch = useCallback(async () => {
    if (check?.value?.length) check?.onChange([]);
    if (!patient?.value)
      return {
        data: [],
        total: 0,
      };
    const res =
      await vsf?.services?.DrugPrescriptionRefundDispenseController_getRefundDispenseForReturn_5b593b?.(
        {
          qto: {
            patientIdIs: patient?.value,
          },
        },
      );
    return {
      data: onSpliced?.(res),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.value]);

  const onPrescriptionReturnMedication = useFetch({
    fetch: async () => {
      const res =
        await vsf?.services?.DrugPrescriptionRefundDispenseController_returnRefundDispense_c77fa9?.(
          {
            drugPrescriptionDispenseMasterIds: check?.value?.map(
              (item) => item?.id,
            ),
          },
        );
      return res;
    },
    message: '处方反退药',
    success: () => {
      table?.current?.reload?.();
      check?.onChange?.([]);
    },
  });
  const [towTableHeight, setTowTableHeight] = useState(238);
  getPromiseHeight('aspirin-table-no-background-box-id').then((res) =>
    setTowTableHeight((res as number) - 50),
  );
  return (
    <Container
      layout={{
        items: [],
      }}
    >
      <Block
        scroll={false}
        style={{
          padding: 0,
        }}
      >
        <Flex
          vertical
          gap={15}
          style={{
            height: '100%',
          }}
        >
          <Flex
            style={{
              background: 'var(--background)',
              padding: '24px',
            }}
            gap={20}
            justify="space-between"
          >
            <Flex gap={10} align="center">
              <span>证件及编号: </span>
              <div>
                <CertificateSearch
                  width={450}
                  onEnter={(event: any) => {
                    const { identificationClassIn, identificationNumberIn } =
                      event;
                    if (Object?.is(patient?.value, identificationNumberIn)) {
                      table?.current?.reload();
                    } else {
                      patient?.onChange(identificationNumberIn);
                    }
                  }}
                />
              </div>
            </Flex>
            <Flex gap={20}>
              {Object.keys(dataStore)?.map((key) => {
                return (
                  <Flex
                    key={key}
                    gap={10}
                    align="center"
                    style={{ width: '100px' }}
                  >
                    <span>{key}: </span>
                    <div>{dataStore[key] || '空'}</div>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          <div
            style={{
              background: 'var(--background)',
              padding: '1.6rem',
              width: '100%',
              height: '45%',
            }}
          >
            <VSPromiseTable
              onFetch={onFetch}
              className="aspirin-table-no-background-box"
              pagination={false}
              bordered
              rowClassName={(record) => {
                return check?.value
                  ?.map((item) => item?.id)
                  ?.includes?.(record?.id)
                  ? 'aspirin-row-selected-box'
                  : '';
              }}
              ref={table}
              scroll={{ y: '20rem' }}
            >
              <VSTableColumn
                valueType="custom"
                render={(...[, record]) => {
                  const value = check?.value
                    ?.map((item) => item?.id)
                    ?.includes(record?.id);
                  return (
                    <Flex align="center" justify="center">
                      <Determine
                        condition={!record?.dailyDrugDispenseLogId}
                        fallback={<Checkbox disabled />}
                      >
                        <Checkbox
                          value={value}
                          onChange={(info) => {
                            if (info) {
                              check?.onChange?.([...check?.value, record]);
                            } else {
                              check?.onChange?.(
                                check?.value?.filter(
                                  (item) => item?.id !== record?.id,
                                ),
                              );
                            }
                          }}
                        />
                      </Determine>
                    </Flex>
                  );
                }}
                title={() => {
                  const dataSource = table?.current
                    ?.getValues?.()
                    ?.filter((record) => !record?.dailyDrugDispenseLogId);
                  return (
                    <Flex align="center" justify="center">
                      <Checkbox
                        indeterminate={
                          check?.value?.length > 0 &&
                          check?.value?.length < dataSource?.length
                        }
                        value={
                          dataSource?.length
                            ? check?.value?.length === dataSource?.length
                            : false
                        }
                        onChange={(info) => {
                          check?.onChange(info ? dataSource ?? [] : []);
                        }}
                      />
                    </Flex>
                  );
                }}
                width="3.33rem"
              />
              <VSTableColumn
                valueType="text"
                title="处方号"
                dataIndex={['prescriptionNumber']}
              />
              <VSTableColumn
                valueType="text"
                title="医生"
                dataIndex={['doctorName']}
              />
              <VSTableColumn
                valueType="select"
                title="处方类型"
                dataIndex={['prescriptionAttribute']}
                dataSource={vsf.stores.dbenums.enums.DRUG_TOXI_PROPERTY_DICT}
              />
              <VSTableColumn
                valueType="text"
                title="处方金额"
                dataIndex={['totalCharge']}
              />
              <VSTableColumn
                valueType="text"
                title="来源"
                dataIndex={['orderDepartmentName']}
              />
              <VSTableColumn
                valueType="text"
                title="发药时间"
                dataIndex={['dispenseDate']}
              />
              <VSTableColumn
                valueType="text"
                title="退药时间"
                dataIndex={['refundDrugDate']}
              />
              <VSTableColumn
                valueType="text"
                title="发药人"
                dataIndex={['dispenseStaffName']}
              />
              <VSTableColumn
                valueType="text"
                title="发药窗口号"
                dataIndex={['dispenseWindowDescription']}
              />
              <VSTableColumn
                valueType="text"
                title="退药人"
                dataIndex={['refundDrugStaffName']}
              />
              <VSTableColumn
                valueType="text"
                title="退药窗口号"
                dataIndex={['renfundDrugWindowDescription']}
              />
            </VSPromiseTable>
          </div>
          <Flex
            style={{
              background: 'var(--background)',
              padding: '1.6rem',
              height: '47%',
            }}
            vertical
          >
            <div
              style={{
                height: '95%',
              }}
              id="aspirin-table-no-background-box-id"
            >
              <VSTable
                className="aspirin-table-no-background-box"
                value={
                  check?.value?.flatMap(
                    (item) => item?.refundDrugDetail ?? [],
                  ) ?? []
                }
                bordered
                pagination={false}
                scroll={{ y: towTableHeight }}
              >
                <VSTableColumn
                  dataIndex={['orderText']}
                  title="医嘱正文"
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['firmName']}
                  title="厂商"
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['amount']}
                  title="数量"
                  valueType="digit"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['packageUnit']}
                  title="包装单位"
                  valueType="custom"
                  fieldProps={{}}
                  render={(...[, record]) => (
                    <UsageUnit value={{ unit: record?.packageUnit }} />
                  )}
                />

                <VSTableColumn
                  dataIndex={['retailPrice']}
                  title="金额"
                  valueType="digit"
                  fieldProps={{}}
                />
              </VSTable>
            </div>
            <Flex align="center" justify="center">
              <ButtonAndKeyDown
                type="primary"
                methodKey="altKey"
                ordinaryKey="M"
                disabled={!check?.value?.length}
                onClick={() => {
                  onPrescriptionReturnMedication?.();
                }}
              >
                反退药
              </ButtonAndKeyDown>
            </Flex>
          </Flex>
        </Flex>
      </Block>
    </Container>
  );
};

export default PrescriptionReturnMedicationDrug;
