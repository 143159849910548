import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  Tabs,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';

const { TabPane } = Tabs;

const WhiteLogList = (props) => {
  const onSupplyFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStockSupplyController_getPagedByDrugSupplyLogQto_c72272?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            drugProductIdIs: params?.extra?.drugProductId,
            drugStorageIdIs: params?.extra?.storageId,
            packageSpecificationIs: params?.extra?.packageSpecification,
            logTypeIs: 'LIMIT',
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    // TODO: 你需要自己补充入参input来源及出参output用法
    return {
      data:
        (res?.data?.result ?? res?.data ?? []).map((m) =>
          convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable588811Ro(m),
        ) ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onWhiteFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStockSupplyController_queryPagedByDrugProductWhitelistLogStaffPatientQto_e326d2?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            drugProductIdIs: params?.extra?.drugProductId,
            drugStorageIdIs: params?.extra?.storageId,
            packageSpecificationIs: params?.extra?.packageSpecification,
            orderList: params.orderList,
          },
          ext: {},
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    // TODO: 你需要自己补充入参input来源及出参output用法
    return {
      data: (res?.data?.result ?? res?.data ?? []).map((m) =>
        convertQueryPagedByDrugProductWhitelistLogStaffPatientQtoRes2VSPromiseTable571431Ro(
          m,
        ),
      ),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  /**
   * convertQueryPagedByDrugProductWhitelistLogStaffPatientQtoRes2VSPromiseTable571431Ro
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagedByDrugProductWhitelistLogStaffPatientQtoRes2VSPromiseTable571431Ro =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.logTime = _.get(input, 'logTime');
      output.operateType = _.get(input, 'operateType');
      output.content = _.get(input, 'content');
      output.staffName = _.get(input, 'operateStaff.staffName');
      return output;
    };
  /**
   * convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable588811Ro
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable588811Ro = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = {};
    output.handleDate = _.get(input, 'handleDate');
    output.handleValue = _.get(input, 'handleValue');
    output.reason = _.get(input, 'reason');
    output.staffName = _.get(input, 'operator.staffName');
    output.memo = _.get(input, 'memo');
    return output;
  };
  return (
    <Tabs defaultActiveKey="supply">
      <TabPane key="supply" tab="供应标识">
        <VSPromiseTable
          scroll={{
            y: 280,
          }}
          onFetch={onSupplyFetch}
          vsid="58881"
          id="vSPromiseTable588811"
          extraParams={props.value}
        >
          <VSTableColumn
            dataIndex={['handleDate']}
            title="操作时间"
            editable={false}
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['handleValue']}
            title="操作"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['reason']}
            title="变更理由"
            editable={false}
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_UNSUPPORT_REASON_DICT}
          />

          <VSTableColumn
            dataIndex={['staffName']}
            title="员工姓名"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 200 },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </TabPane>
      <TabPane key="white" tab="患者白名单">
        <VSPromiseTable
          scroll={{
            y: '18.7rem',
          }}
          onFetch={onWhiteFetch}
          vsid="57143"
          id="vSPromiseTable571431"
          extraParams={props.value}
        >
          <VSTableColumn
            dataIndex={['logTime']}
            title="日期"
            editable={false}
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['operateType']}
            title="操作"
            editable={false}
            valueType="select"
            dataSource={[
              { label: '新增', value: 'ADD' },
              { label: '删除', value: 'REMOVE' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['content']}
            title="内容"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['staffName']}
            title="操作员"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </TabPane>
    </Tabs>
  );
};
export default definePage(WhiteLogList);
