import {
  askRomanAlphabet,
  askJustOnce,
} from '@/pages/Index/components/func/ask';
import { getScrollY, getScrollYRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSTable,
  VSTableAddition,
  Button,
  Input,
  message,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { useFetch } from '@/pages/Index/components/func/hooks';
const ExamBody = (props) => {
  const [oldState, setOldState] = useState([]);
  const [newState, setNewState] = useState([]);
  const onFetch = useCallback(async (id) => {
    const res =
      await vsf?.services?.ExamBodyDictionaryDtoController_getByExamClassId_99e20a?.(
        { examClassId: id },
      );
    setOldState(res.data);
    setNewState(res.data.reverse());
  }, []);

  const onSubmit = useFetch({
    fetch: async () => {
      const { add, update, remove } = askJustOnce({
        oldState,
        newState: getNewState?.(),
        id: 'id',
      });
      const addBodyToExamClassBtoList = [
        {
          id: props.value.id,
          examBodyDictionaryBtoList: getNewState?.()?.filter((item, index) => {
            const id = add.map((item) => item.id).includes(item.id);
            if (id) {
              item.serialNumber = index + 1;
              delete item.id;
            }
            return id;
          }),
        },
      ];
      const updateExamBodyBtoList = update.length
        ? [
            {
              id: props.value.id,
              examBodyDictionaryBtoList: update,
            },
          ]
        : [];
      const removeBodyFromExamClassBtoList = remove.length
        ? [
            {
              id: props.value.id,
              examBodyDictionaryBtoList: remove,
            },
          ]
        : [];
      const res =
        await vsf?.services?.ExamClassDictionaryBOController_addAndUpdateExamBody_f95b77?.(
          {
            addBodyToExamClassBtoList,
            updateExamBodyBtoList,
            removeBodyFromExamClassBtoList,
          },
        );
      return res;
    },
    success: () => {
      onFetch(props?.value.id);
    },
    message: '保存病理部位',
  });

  useEffect(() => {
    if (props?.value?.id) {
      onFetch(props?.value.id);
    }
  }, [props?.value?.id]);

  return (
    <Block
      // height={getScrollY(240)}
      height={getScrollYRem(16)}
      className="aspirin-block-no-padding"
      footer={
        <div
          className="aspirin-table-footer-button"
          style={{ justifyContent: 'flex-end', paddingTop: 24 }}
        >
          <ButtonAndKeyDown
            onClick={async () => {
              const save = await vsf?.refs?.BodyTable?.saveEdit();
              if (save) {
                onSubmit?.();
              }
            }}
            type="primary"
            methodKey="altKey"
            ordinaryKey="S"
          >
            保存
          </ButtonAndKeyDown>
        </div>
      }
    >
      <VSTable
        id="BodyTable"
        editable={{
          editType: 'single',
          columnProps: {
            width: 150,
          },
          onFieldChange: (key, value, allValue, form) => {
            if (value.examBodyName) {
              form.setFieldValue(key, {
                ...allValue,
                inputCode: askRomanAlphabet(value.examBodyName),
              });
            }
          },
          saveOnClickOutside: true,
          onCanDelete: () => null,
          editText: <Icon type="icon-bianji" size={24} />,
          saveText: <Icon type="icon-a-Frame437" size={24} />,
          extraOperations: [
            {
              onClick: (_, data) => {
                if (oldState.map((item) => item.id).includes(data.id)) {
                  Tip({
                    title: '操作窗口',
                    content: `确定删除{【${data.examBodyName}】}，删除后不可恢复！`,
                    onOk: () => {
                      vsf.refs.BodyTable.remove(data);
                    },
                    centered: true,
                    okText: '确定',
                    cancelText: '取消',
                  });
                } else {
                  vsf.refs.BodyTable.remove(data);
                }
              },
              children: <Icon type="icon-shanchu" size={24} />,
              order: 1,
            },
          ],
        }}
        bordered
        pagination={false}
        rowClick={{
          rowClickType: {
            type: 'edit-save',
            action: 'click',
          },
        }}
        scroll={{
          // y: getScrollY(400),
          y: getScrollYRem(28),
        }}
        onChange={setNewState}
        className="aspirin-table-box-no"
        vsid="64527"
        value={newState}
        onRecord={async () => {
          const len = newState.length + 1;
          return {
            id: crypto.randomUUID?.(),
            examBodyCode: props.value?.inputCode + (len < 10 ? `0${len}` : len),
          };
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['examBodyCode']}
          title="病理部位编码"
          valueType="text"
          renderFormItem={(schema, recode, form) => {
            return (
              <Input
                onChange={schema.originProps.change}
                disabled={oldState
                  .map((item) => item.id)
                  .includes(recode.record.id)}
              />
            );
          }}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['examBodyName']}
          title="病理部位名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="拼音输入码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (String(value).includes(',')) {
                    return Promise.reject('多音字待确认');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ],
          }}
        />
        <VSTableToolbar title="病理部位列表">
          <VSTableAddition
            children="添加"
            onClick={() => {
              console.log(vsf?.refs?.BodyTable);
            }}
          />
        </VSTableToolbar>
      </VSTable>
    </Block>
  );
};
export default definePage(ExamBody);
