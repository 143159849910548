import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  InputProps,
  Row,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest } from 'ahooks';
import { Table } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import Loading from '@/module/Loading';

import ComplexSelect from '../complex_select';
import { useInputEnter } from '../dosage/utils';

/**
 * DrugSelect
 */
const DrugOrderSelect = forwardRef((props: any, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    record,
    subStorageId,
    onDrugFetch,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  // console.log(record);
  const form = Form?.useFormInstance();
  const key = record?.id;

  const columns = [
    {
      title: '药品名称',
      dataIndex: ['drugProductName'],
      key: 'drug_product_name',
    },
    {
      title: '包装规格',
      dataIndex: ['packageSpecification'],
      key: 'package_specification',
    },
    {
      title: '医保类型',
      dataIndex: ['medicalInsuranceType'],
      key: 'medical_insurance_type',
      render: () => {
        [
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ];
      },
    },
    {
      title: '生产厂家',
      dataIndex: ['firmName'],
      key: 'firm_name',
    },
    {
      title: '价格',
      dataIndex: ['retailPrice'],
      key: 'retail_pice',
    },
  ];

  const table = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    ...table?.current,
  }));

  const {
    loading,
    data: dataSource,
    run: onFetch,
  } = useRequest(onDrugFetch, {
    manual: true,
  });

  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="productName"
      value={value}
      ref={table}
      onChange={(value) => {
        form.setFieldValue(key, {
          ...form?.getFieldValue(key),
          ...value,
        });
        if (value?.stockList?.length) {
          form.setFieldValue(
            [key, 'packageUnit'],
            value?.stockList?.at(-1)?.packageUnit,
          );
        }
        onChange({
          label: value.productName,
          value: value.productId,
        });
      }}
      onSearch={(inputCode) => {
        if (inputCode?.trim()) {
          onFetch({
            inputCodeLike: inputCode,
          });
        }
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: 700,
      }}
      listHeight={1000}
      {...rest}
      {...fieldEnterProps}
      placeholder="请选择药品"
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={columns}
        pagination={false}
        size="middle"
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={24}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        scroll={{
          y: 400,
        }}
        dataSource={dataSource ?? ([] as any)}
      />
    </ComplexSelect>
  );
});

DrugOrderSelect.displayName = 'drugOrderSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'drugOrderSelect',
    handleEnter: true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        const { value } = props;
        return <>{value}</>;
      },
    },
  ),
)(DrugOrderSelect) as typeof DrugOrderSelect;
