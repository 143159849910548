import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  Todo,
  Checkbox,
  Button,
  VSTableToolbar,
  Modal,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';

import { useAvoidState, useFetch } from '@/pages/Index/components/func/hooks';
import { onExport } from '@/pages/Index/manage/execution_registration/util';
import { getScrollY, getScrollYRem } from '@/utils';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import CustomSelect from './custom_select';
import NotSupplyOrLimit from './not_supply_or_limit';
import SupplyLogList from './supply_log_list';
import Whitelist from './white_list';
import WhiteLogList from './white_log_list';
import { isObject } from 'lodash';
import { isMinScreen } from '@/config';

const StockSupplyList = (props) => {
  const [currentDrugInfo, setCurrentDrugInfo] = useState();
  const isUseSpecificationIsRef = useRef();
  const [isUseSpecificationIs, setIsUseSpecificationIs] = useState();
  const [notSupplyOrLimitModalType, setNotSupplyOrLimitModalType] = useState();

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    // TODO: 你需要自己补充入参input来源及出参output用法
    if (isObject(params?.search?.drugProductIdIs))
      params.search.drugProductIdIs = params?.search?.drugProductIdIs?.value;
    const res =
      await vsf.services?.DrugStockSupplyController_queryDrugStockSupply_25f6e9?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
            isUseSpecificationIs: isUseSpecificationIsRef.current
              ? true
              : undefined,
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    // TODO: 你需要自己补充入参input来源及出参output用法
    const list = (res?.data?.result ?? res?.data ?? []).map((m) =>
      convertQueryDrugStockSupplyRes2TableStockSupplyRo(m),
    );
    return {
      data: list,
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  /**
   * convertQueryDrugStockSupplyRes2TableStockSupplyRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryDrugStockSupplyRes2TableStockSupplyRo = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.drugProductName = _.get(input, 'drugProduct.drugProductName');
    output.drugSpecification = _.get(input, 'drugProduct.drugSpecification');
    output.packageSpecification = _.get(
      input,
      'drugPriceItem.packageSpecification',
    );
    output.packageUnit = _.get(input, 'drugPriceItem.packageUnit');
    output.price = _.get(input, 'drugPriceItem.currentPriceItem.price');
    output.firmName = _.get(input, 'drugProduct.drugFirm.firmName');
    output.amount = _.get(input, 'amount');
    output.supplyIndicator = _.get(input, 'supplyIndicator');
    output.limitIndicator = _.get(input, 'limitIndicator');
    output.drugProductId = _.get(input, 'drugProduct.id');
    output.storageId = _.get(input, 'storageId');
    output.subDrugStorageId = _.get(input, 'subDrugStorageId');
    output.haveProfile = _.get(input, 'haveProfile');
    return output;
  };
  /**
   * convertTableStockSupplyRo2UpdateDrugStockLimitReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableStockSupplyRo2UpdateDrugStockLimitReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.stock = {};
    output.stock.drugProduct = {};
    output.stock.drugProduct.id = _.get(input, 'drugProductId');
    output.stock.packageSpecification = _.get(input, 'packageSpecification');
    output.stock.limitIndicator = _.get(input, 'limitIndicator');
    output.stock.storageId = _.get(input, 'storageId');
    output.stock.drugSupplyLog = {};
    output.stock.drugSupplyLog.reason = _.get(input, 'reason');
    output.stock.drugSupplyLog.memo = _.get(input, 'memo');
    return output;
  };
  /**
   * convertTableStockSupplyRo2UpdateDrugStockSupplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableStockSupplyRo2UpdateDrugStockSupplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.stock = {};
    output.stock.drugProduct = {};
    output.stock.drugProduct.id = _.get(input, 'drugProductId');
    output.stock.packageSpecification = _.get(input, 'packageSpecification');
    output.stock.supplyIndicator = _.get(input, 'supplyIndicator');
    output.stock.limitIndicator = _.get(input, 'limitIndicator');
    output.stock.storageId = _.get(input, 'storageId');
    output.stock.drugSupplyLog = {};
    output.stock.drugSupplyLog.reason = _.get(input, 'reason');
    output.stock.drugSupplyLog.memo = _.get(input, 'memo');
    return output;
  };
  /**
   * convertQueryDrugStockSupplyRes2VSPromiseTable739611Ro
   * @param {any} input
   * @returns {any}
   */
  const convertQueryDrugStockSupplyRes2VSPromiseTable739611Ro = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'drugPriceItem.id');
    output.drugProductName = _.get(input, 'drugProduct.drugProductName');
    output.drugSpecification = _.get(input, 'drugProduct.drugSpecification');
    output.packageSpecification = _.get(
      input,
      'drugPriceItem.packageSpecification',
    );
    output.packageUnit = _.get(input, 'drugPriceItem.packageUnit');
    output.price = _.get(input, 'drugPriceItem.currentPriceItem.price');
    output.firmName = _.get(input, 'drugProduct.drugFirm.firmName');
    output.amount = _.get(input, 'amount');
    output.supplyIndicator = _.get(input, 'supplyIndicator');
    output.limitIndicator = _.get(input, 'limitIndicator');
    output.stockAmount = _.get(input, 'stockAmount');
    output.isUseSpecification = _.get(input, 'isUseSpecification');
    output.drugProductId = _.get(input, 'drugProduct.id');
    return output;
  };
  /**
   * convertVSPromiseTable739611Qro2QueryDrugStockSupplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSPromiseTable739611Qro2QueryDrugStockSupplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {}; // TODO output.qto.storageIdIs = null; // Long null
    output.drugProductIdIs = _.get(input, 'drugProductIdIs'); // TODO output.qto.packageSpecificationIs = null; // String null
    output.supplyIndicatorIs = _.get(input, 'supplyIndicatorIs');
    output.limitIndicatorIs = _.get(input, 'limitIndicatorIs');
    output.isUseSpecificationIs = _.get(input, 'isUseSpecificationIs');
    output.stockAmountIs = _.get(input, 'stockAmountIs'); // TODO output.qto.size = null; // Long null
    // TODO output.qto.from = null; // Long null
    // TODO output.qto.scrollId = null; // String null
    return output;
  };

  const handelFormDrugSelectLodaData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugStockController_queryDrugs_529e82?.({
        qry: {
          inputCodeLike,
        },
      });
    const data = response?.data?.result ?? [];

    return (
      data?.map((item) => ({
        ...item,
        key: item?.drugProductId,
        drugProductName: item?.drugProductName,
        drugSpecification: item?.drugSpecification,
        firmName: item?.firmName,
      })) ?? []
    );
  }, []);

  const handelSupplyIndicatorChange = async (supplyIndicator, record) => {
    setCurrentDrugInfo(record);
    if (supplyIndicator) {
      Modal.confirm({
        mask: false,
        content: '确认修改为可供？',
        title: '提示',
        onOk: async () => {
          const res =
            await vsf.services?.DrugStockSupplyController_updateDrugStockSupply_255a7d?.(
              convertTableStockSupplyRo2UpdateDrugStockSupplyReq({
                ...record,
                supplyIndicator,
              }),
            ); // TODO: 你可能需要整理返回数据格式
          if (res?.code === 200) {
            vsf.refs?.vSPromiseTable739611?.reload();
          }
          return res?.data;
        },
      });
    } else {
      setNotSupplyOrLimitModalType('supplyIndicator');
      vsf.openModal('NotSupplyOrLimitModal');
    }
  };

  const handelLimitIndicatorChange = async (limitIndicator, record) => {
    setCurrentDrugInfo(record);
    if (limitIndicator) {
      if (!record?.haveProfile) return message.error('该药品不存在库存定义');
      setNotSupplyOrLimitModalType('limitIndicator');
      vsf.openModal('NotSupplyOrLimitModal');
    } else {
      Modal.confirm({
        mask: false,
        content: '取消限量控制，将自动清空名单，是否取消？',
        title: '提示',
        cancelText: '取消',
        okText: '确定',
        onOk: async () => {
          const res =
            await vsf.services?.DrugStockSupplyController_updateDrugStockLimit_0ab0d8?.(
              convertTableStockSupplyRo2UpdateDrugStockSupplyReq({
                ...record,
                limitIndicator,
              }),
            ); // TODO: 你可能需要整理返回数据格式
          if (res?.code === 200) {
            message.success('已取消');
            vsf.refs?.vSPromiseTable739611?.reload();
          }
          return res?.data;
        },
      });
    }
  };

  const handelNotSupplySubmit = async () => {
    const formParams =
      await vsf.refs?.vSControlledForm618301?.validateFieldsReturnFormatValue();
    if (notSupplyOrLimitModalType === 'supplyIndicator') {
      const res =
        await vsf.services?.DrugStockSupplyController_updateDrugStockSupply_255a7d?.(
          convertTableStockSupplyRo2UpdateDrugStockSupplyReq({
            ...currentDrugInfo,
            supplyIndicator: false,
            ...formParams,
          }),
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        vsf.refs?.vSPromiseTable739611?.reload();
        vsf?.closeModal('NotSupplyOrLimitModal');
      }
      return res?.data;
    } else {
      const res =
        await vsf.services?.DrugStockSupplyController_updateDrugStockLimit_0ab0d8?.(
          convertTableStockSupplyRo2UpdateDrugStockLimitReq({
            ...currentDrugInfo,
            limitIndicator: true,
            ...formParams,
          }),
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        vsf.refs?.vSPromiseTable739611?.reload();
        vsf?.closeModal('NotSupplyOrLimitModal');
      }
      return res?.data;
    }
  };

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  const handelOptionRender = (_, __, dom) => {
    // const [, , [other, search]] = arr
    return (
      <SearchContainer.Header
        title={
          <div style={{ display: 'flex' }}>
            <div>筛选</div>
            <div className="search_header_check_view">
              <Checkbox
                value={isUseSpecificationIs}
                onChange={(e) => {
                  setIsUseSpecificationIs(e);
                  isUseSpecificationIsRef.current = e;
                }}
              >
                仅显示在用规格
              </Checkbox>
            </div>
          </div>
        }
      >
        {dom}
      </SearchContainer.Header>
    );
  };

  const onExportLoadDown = useFetch({
    fetch: async () => {
      const dataList = await vsf?.refs?.vSPromiseTable739611
        ?.getValues()
        ?.map((item, index) => {
          return {
            ...item,
            supplyIndicator: item?.supplyIndicatorIs ? '供应' : '停供',
            limitIndicator: item?.limitIndicator ? '限量' : '不限量',
            index: index + 1,
          };
        });
      try {
        onExport({
          title: '门诊药房供应标识维护',
          columns: [
            {
              header: '序号',
              key: 'index',
              width: 20,
            },
            {
              header: '药品名称',
              key: 'drugProductName',
              width: 40,
            },
            {
              header: '规格',
              key: 'drugSpecification',
              width: 10,
            },
            {
              header: '包装规格',
              key: 'packageSpecification',
              width: 20,
            },
            {
              header: '单位',
              key: 'packageUnit',
              width: 10,
            },
            {
              header: '零售价',
              key: 'price',
              width: 10,
            },
            {
              header: '厂家',
              key: 'firmName',
              width: 40,
            },

            {
              header: '库存量',
              key: 'amount',
              width: 10,
            },
            {
              header: '供应标识',
              key: 'supplyIndicator',
              width: 10,
            },
            {
              header: '限量标识',
              key: 'limitIndicator',
              width: 10,
            },
          ],
          value: dataList,
        });
        return {
          code: 200,
        };
      } catch (e) {
        return {
          code: 500,
        };
      }
    },
    message: {
      loading: '正在导出中',
      error: '导出失败',
      success: '导出成功',
    },
  });

  return (
    <>
      <VSPromiseTable
        vsid="73961"
        id="vSPromiseTable739611"
        className="aspirin-table"
        scroll={{
          x: '143.33rem',
          y: getScrollYRem(31.2),
        }}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true,
          },
        }}
        // pagination={false}
        onFetch={onFetch}
        rowKey="drugPriceItem?.drugItemId"
        searchFormRender={(props, dom) => (
          <SearchContainer>{dom}</SearchContainer>
        )}
        searchConfig={{
          className: 'vSPromiseTable739611',
          labelAlign: 'left',
          collapsed: false,
          collapseRender: false,
          optionRender: handelOptionRender,
          resetButtonProps: {
            onClick: async () => {
              isUseSpecificationIsRef.current = false;
              setIsUseSpecificationIs(false);
              await vsf.refs?.vSPromiseTable739611
                ?.getSearchForm()
                ?.resetFields();
              vsf.refs?.vSPromiseTable739611?.reload();
            },
          },
        }}
      >
        <VSTableToolbar
          title="药品供应信息列表"
          style={{ padding: '0 1.2rem' }}
        ></VSTableToolbar>

        <VSTableColumn
          formItemProps={{}}
          dataIndex={['drugProductIdIs']}
          title="药品名称"
          valueType="select"
          search
          order={0}
          columnKey={'drugProductIdIs'}
          hideInTable
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                allowClear
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width="33.33rem"
                placeholder="请输入药品名称"
                showLabel="drugProductName"
                loadData={handelFormDrugSelectLodaData}
                columns={[
                  {
                    title: '名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '规格',
                    dataIndex: 'drugSpecification',
                    key: 'drugSpecification',
                  },
                  {
                    title: '厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          dataIndex={['supplyIndicatorIs']}
          title="供应标识"
          valueType="select"
          search
          order={0}
          columnKey={'supplyIndicatorIs'}
          hideInTable
          fieldProps={{}}
          dataSource={[
            {
              label: '停供',
              value: false,
            },
            {
              label: '供应',
              value: true,
            },
          ]}
        />

        <VSTableColumn
          dataIndex={['limitIndicatorIs']}
          title="限量标识"
          valueType="select"
          search
          order={0}
          columnKey={'limitIndicatorIs'}
          hideInTable
          fieldProps={{}}
          dataSource={[
            {
              label: '不限量',
              value: false,
            },
            {
              label: '限量',
              value: true,
            },
          ]}
        />

        <VSTableColumn
          dataIndex={['stockAmountIs']}
          title="库存"
          valueType="select"
          search
          order={0}
          columnKey={'stockAmountIs'}
          hideInTable
          dataSource={[
            { label: '大于零', value: 'ONE' },
            { label: '小于等于零', value: 'ZERO' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          width="5.33rem"
          title="序号"
          valueType="text"
          render={(_, __, index) => index + 1}
          preview
        />

        <VSTableColumn
          width="20.66rem"
          dataIndex={['drugProductName']}
          title="商品名称"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['drugSpecification']}
          title="规格"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['packageSpecification']}
          title="包装规格"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '包装规格长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['packageUnit']}
          title="单位"
          editable={false}
          valueType="select"
          formItemProps={{
            rules: [
              {
                message: '包装单位长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['price']}
          title="零售价"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: 'price的值不合法',
                validator: (_, value) => {
                  if (value === undefined) return Promise.resolve();
                  if ((true && value >= 0) || (!true && value > 0)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('price的值不合法'));
                },
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="20.66rem"
          dataIndex={['firmName']}
          title="厂家"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['amount']}
          title="库存量"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '数量的值不合法',
                type: 'number',
                min: -100000,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8.66rem"
          dataIndex={['supplyIndicator']}
          title="供应标识"
          valueType="custom"
          fieldProps={{}}
          render={(_, record) => {
            return (
              <Checkbox
                value={record?.supplyIndicator}
                onChange={(e) => handelSupplyIndicatorChange(e, record)}
              />
            );
          }}
        />

        <VSTableColumn
          width="9.2rem"
          title="维护记录（可供）"
          formItemProps={{ labelCol: 4, wrapperCol: 16 }}
          linkPage={{ updateTable: true }}
        >
          <VSTableAction
            children="查看详情"
            linkPage={{
              updateTable: true,
              modalProps: {
                title: '维护历史记录',
                width: '69.8rem',
                bodyStyle: {
                  height: '27.5rem',
                  padding: '1.2rem 0 0 0',
                },
                cancelText: '关闭',
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  style: {
                    display: 'none',
                  },
                },
              },
              linkPage: () => <SupplyLogList />,
            }}
          />
        </VSTableColumn>

        <VSTableColumn
          width="8.66rem"
          dataIndex={['limitIndicator']}
          title="限量标识"
          valueType="switch"
          fieldProps={{}}
          render={(_, record) => {
            return (
              <Checkbox
                value={record?.limitIndicator}
                onChange={(e) => handelLimitIndicatorChange(e, record)}
              />
            );
          }}
        />
        <VSTableColumn
          width="9.33rem"
          title="白名单"
          formItemProps={{ labelCol: 4, wrapperCol: '1.06rem' }}
          linkPage={{ updateTable: true }}
        >
          <VSTableAction
            children="查看名单"
            disabled={(record) => !record?.haveProfile}
            linkPage={{
              modalProps: {
                title: '白名单维护',
                width: '43.6rem',
                bodyStyle: {
                  height: '26rem',
                  padding: '0 1.6rem',
                  paddingBottom: '0.8rem',
                },
                cancelText: '关闭',
                okText: '保存',
              },
              updateTable: true,
              linkPage: () => <Whitelist />,
              onLinkPageValidate: async (_, __, record) => {
                const rows =
                  (await vsf.refs?.vSPromiseTable365411?.getValues()) ?? [];
                const res =
                  await vsf.services?.DrugStockSupplyController_saveDrugProductWhitelists_e211c9?.(
                    {
                      // TODO: 请检查以下这部分传参是否符合需求
                      btos: rows
                        // ?.filter(item => (typeof item?.id === 'string' && `${item?.id}`?.includes('create_')) || item?.isUpdate)
                        ?.map((item) => ({
                          ...item,
                          id:
                            typeof item?.id === 'string' &&
                            `${item?.id}`?.includes('create_')
                              ? undefined
                              : item?.id,
                          // displayId: undefined
                        })),
                      drugProductId: record?.drugProductId,
                      packageSpecification: record?.packageSpecification,
                    },
                  ); // TODO: 你可能需要整理返回数据格式
                if (res?.code === 200) {
                  message.success('已保存');
                }

                return res?.code === 200;
              },
            }}
          />
        </VSTableColumn>

        <VSTableColumn
          width="10.6rem"
          title="维护记录（白名单）"
          formItemProps={{ labelCol: 4, wrapperCol: 16 }}
          linkPage={{ updateTable: true }}
        >
          <VSTableAction
            children="查看详情"
            linkPage={{
              updateTable: true,
              modalProps: {
                title: '维护历史记录',
                width: '71.6rem',
                bodyStyle: {
                  height: '30rem',
                  padding: '0 1.6rem',
                },
                cancelText: '关闭',
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  style: {
                    display: 'none',
                  },
                },
              },
              linkPage: () => <WhiteLogList />,
            }}
          />
        </VSTableColumn>
      </VSPromiseTable>

      <div className="footer">
        <Button style={{ borderColor: '#3276E8' }}>关闭</Button>
        <Button type="primary" onClick={onExportLoadDown}>
          导出
        </Button>
      </div>

      <Modal
        title="提示"
        cancelText="取消"
        okText="确定"
        id="NotSupplyOrLimitModal"
        width="34.66rem"
        bodyStyle={{
          height: '33.33rem',
        }}
        onOk={handelNotSupplySubmit}
      >
        <NotSupplyOrLimit
          warnTitle={
            notSupplyOrLimitModalType === 'limitIndicator'
              ? '启用限量控制后当前药品仅白名单中患者可使用，是否启用？'
              : '确定修改为不可供？'
          }
          drugInfo={currentDrugInfo}
        />
      </Modal>
    </>
  );
};
export default definePage(StockSupplyList);
