import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';

import storage from '@/utils/storage';
import {
  Divider,
  Modal,
  Section,
  Space,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useResetState } from 'ahooks';
import { useCallback } from 'react';

const Apply = (props) => {
  const { value, open, onCancel, onOk } = props;
  const [select, setSelect, resetSelect] = useResetState({});
  /**
   * 获取系统参数判断库存
   */
  const EXPORT_BATCH_BASED_ON_INVENT =
    storage
      ?.get('systemParameter')
      ?.find((item) => item?.name === 'EXPORT_BATCH_BASED_ON_INVENT') ?? {};

  const onFetch = useCallback(async () => {
    if (value?.id) {
      const res =
        await vsf.services?.DrugStorageImportExportController_getApplyDetailByDrugApplyMasterId_92fe0d?.(
          {
            drugApplyMasterId: value?.id,
            subStorageId: vsf?.refs?.ExportRecord?.getFieldValue([
              'subDrugStorageId',
              'id',
            ]),
          },
        );
      return res.data.map((item) => ({
        ...item,
        referenceAmount: item.retailAmount,
      }));
    }
  }, [value]);

  return (
    <Modal
      title="选择申请出库药品"
      open={open}
      centered
      width="80%"
      height="70%"
      onCancel={() => {
        onCancel?.();
        resetSelect?.();
      }}
      cancelText="取消"
      okText="确定"
      onOk={() => {
        Tip({
          content: `确定生成 1 个出库单，包含{ ${
            vsf.refs?.DrugExportApply?.getValues()?.length
          } }条记录?`,
          onOk: () => {
            onCancel?.();
            onOk?.({
              ...props.value,
              drugExportDetailBtoList: vsf.refs?.DrugExportApply?.getValues()
                .map((item) => {
                  return {
                    ...item,
                    drugStockList: item?.drugStockList?.filter((item) =>
                      Boolean(item.exportAmount),
                    ),
                  };
                })
                .filter((item) => Boolean(item?.drugStockList)),
            });
          },
        });
      }}
      className="aspirin-linkpage"
      destroyOnClose
    >
      <Space direction="vertical" align="baseline" size="large">
        <Section title="库存申请的药品">
          <Divider />
          <VSPromiseTable
            vsid="06568"
            onFetch={onFetch}
            id="DrugExportApply"
            rowClick={{
              rowClickType: 'select',
              onRowClick: (node) => {
                if (node?.drugStockList?.length) {
                  switch (EXPORT_BATCH_BASED_ON_INVENT?.systemValue) {
                    case '1':
                      const count = node?.drugStockList?.reduce(
                        (account, current) => account + current?.amount,
                        0,
                      );
                      const drugStockList = [...node.drugStockList];
                      drugStockList?.reduce(
                        (previous, current) => {
                          if (previous?.num) {
                            /**
                             * 计算插值
                             */
                            const num = Math.abs(
                              previous?.num - current.amount,
                            );
                            if (current?.amount > 0) {
                              /**
                               * 判断差值/出口值是否大于库存量
                               */
                              if (
                                (previous?.num ?? current?.exportAmount) >=
                                current?.amount
                              ) {
                                /**
                                 * 是就把出口数量修改为最大库存量
                                 */
                                current.exportAmount = current.amount;
                                /**
                                 * 记录下差值
                                 */
                                current.num = num;
                              } else {
                                /**
                                 * 如果大于差值直接等于差值
                                 */
                                current.exportAmount = previous?.num ?? 0;
                              }
                            } else {
                              current.exportAmount = 0;
                              current.num = previous.num;
                            }
                          }
                          return current;
                        },
                        {
                          num:
                            node?.referenceAmount >= count
                              ? count
                              : node?.referenceAmount,
                        },
                      );
                      setSelect({
                        ...node,
                        drugStockList,
                      });
                      break;
                    case '0':
                      setSelect(node);
                    default:
                      setSelect(node);
                  }
                } else {
                  setSelect(node);
                }
              },
            }}
            pagination={false}
            rowClassName={(node) => {
              const className = ['aspirin-vs-table-row-box'];
              if (node?.id === select?.id) {
                className.push('aspirin-row-selected-box');
                if (node.referenceAmount !== node.retailAmount) {
                  className.push('aspirin-table-row-red-box');
                }
              } else {
                className.push('aspirin-row-select-box');
              }
              return className.join(' ');
            }}
          >
            <VSTableColumn
              dataIndex={['applyNumber']}
              title="申请单号"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['drugProduct', 'drugProductName']}
              title="药品名称"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['packageSpecification']}
              title="包装规格"
              editable={false}
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 50 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['drugProduct', 'drugFirm', 'firmName']}
              title="厂家"
              editable={false}
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['packageUnit']}
              title="包装单位"
              editable={false}
              valueType="custom"
              render={(...[, record]) =>
                vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                  ({ value }) => value === record?.packageUnit,
                )?.label ??
                record?.packageUnit ??
                ''
              }
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['retailAmount']}
              title="申请数量"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['drugApplyMaster', 'planDate']}
              title="申请日期"
              editable={false}
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />
            <VSTableColumn
              dataIndex={['referenceAmount']}
              title="出库总量"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Section>
        <Section title="本地库存情况">
          <Divider />
          <VSTable
            vsid="44724"
            value={select?.drugStockList ?? []}
            pagination={false}
            editable={{
              editType: 'multiple',
              columnProps: {
                dataIndex: '__vs__table__edit',
                hideInTable: true,
              },
            }}
            scroll={{
              y: '66.66rem',
            }}
            onChange={(value) => {
              vsf.refs?.DrugExportApply.update({
                ...select,
                referenceAmount: value.reduce(
                  (account, thisValue) =>
                    (account ?? 0) + (thisValue.exportAmount ?? 0),
                  0,
                ),
                drugStockList: value.map((item) => ({
                  ...item,
                  amount: item?.amount / select?.amountPerPackage,
                })),
              });
            }}
            rowClassName="aspirin-vs-table-row-box"
          >
            <VSTableColumn
              dataIndex={['drugProduct', 'drugProductName']}
              title="药品名称"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['packageSpecification']}
              title="包装规格"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['drugProduct', 'drugFirm', 'firmName']}
              title="厂家"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['batchNumber']}
              title="批号"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['stockPrice']}
              title="进价"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['expirationDate']}
              title="效期"
              editable={false}
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['packageUnit']}
              title="包装单位"
              editable={false}
              valueType="custom"
              render={(...[, record]) =>
                vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                  ({ value }) => value === record?.packageUnit,
                )?.label ??
                record?.packageUnit ??
                ''
              }
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['amount']}
              title="库存量"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['exportAmount']}
              title="出库数量"
              valueType="digit"
              fieldProps={{
                width: '100%',
                min: 0,
              }}
            />
          </VSTable>
        </Section>
      </Space>
    </Modal>
  );
};

export default Apply;
