import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugFirmDictionaryBOController_deleteDrugFirmDictionary_07a09d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-firm-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugFirmDictionaryBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugFirmDictionaryBOController_updateDrugSupplier_b976fe: {
    method: 'post',
    url: '/api/drug-drug/update-drug-supplier',
    parameterFomatter: (data?: {
      updateDrugFirmDictionaryBto: UpdateDrugFirmDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugFirmDictionaryBOController_createDrugSupplier_139dad: {
    method: 'post',
    url: '/api/drug-drug/create-drug-supplier',
    parameterFomatter: (data?: {
      createDrugFirmDictionaryBto: CreateDrugFirmDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugFirmDictionaryDtoController_queryDrugSupplierDictionary_be8bc0: {
    method: 'post',
    url: '/api/drug-drug/query-drug-supplier-dictionary-list',
    parameterFomatter: (data?: {
      qto: DrugFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
});
