import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyInpChargeController_getNoOrderInpBillingDetailList_b0878c: {
    method: 'post',
    url: '/api/medical-technology/get-no-order-inp-billing-detail-list',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailGroupByOrderDateVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
  MedicalTechnologyInpChargeController_saveInpBillingDetails_dcf810: {
    method: 'post',
    url: '/api/medical-technology/save-inp-billing-details',
    parameterFomatter: (data?: {
      saveInpBillingDetailList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
