import React from 'react';

import { waitTimePeriod } from './common';

function askTimeBto(time: waitTimePeriod) {
  if (!time) {
    return '';
  }
  const { startTimePoint, endTimePoint } = time;
  if (!(startTimePoint || endTimePoint)) {
    return '';
  } else {
    const date = ['hour', 'minute'];
    const time = [];
    return (
      // 返回HH:mm:-HH:mm形式
      <>{`${
        startTimePoint.hour < 10
          ? `0${startTimePoint.hour}`
          : startTimePoint.hour
      }:${
        startTimePoint.minute < 10
          ? `0${startTimePoint.minute}`
          : startTimePoint.minute
      }-${
        endTimePoint.hour < 10 ? `0${endTimePoint.hour}` : endTimePoint.hour
      }:${
        endTimePoint.minute < 10
          ? `0${endTimePoint.minute}`
          : endTimePoint.minute
      }`}</>
    );
  }
}

export default askTimeBto;
