import React from 'react';

import Block, { BlockPropsType } from '@/module/Block';

const Index = (props: BlockPropsType) => {
  return (
    <Block
      style={{
        padding: 0,
      }}
      {...props}
    />
  );
};

export default Index;
