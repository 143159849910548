import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientOperateLogController_getAllByOperationLogPatientQto_9840af: {
    method: 'post',
    url: '/api/patient-extension/get-all-by-operation-log-patient-qto',
    parameterFomatter: (data?: {
      qto: OperationLogPatientQtoPatientExtensionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationLogPatientVoPatientExtensionEntranceWebVo[],
    ) => data,
  },
});
