import './index.less';

import { UserOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import { Popover } from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import Icon from '@/module/Icon';

type SiderMenuPropsType = {
  collapsed?: boolean;
  stores?: any;
  history?: any;
};

const OTHER_HEIGHT = 112;
const MARGIN_TOP = 12;
const ITEM_HEIGHT = 64;

const Index = (props: SiderMenuPropsType) => {
  const { collapsed } = props;
  const { common } = vsf?.stores || {};
  const { leftFunctionList, removeFunction } = common || {};
  const [active, setActive] = useState<any>('index');

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showList, setShowList] = useState<any[]>();
  const [moreList, setMoreList] = useState<any[]>();

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getData = useCallback(() => {
    const listHeight = windowHeight - OTHER_HEIGHT;
    const amount = Math.floor(listHeight / (ITEM_HEIGHT + MARGIN_TOP));
    const sList = leftFunctionList?.slice(
      0,
      leftFunctionList?.length > amount
        ? Math.floor(listHeight / (ITEM_HEIGHT + MARGIN_TOP)) - 1
        : Math.floor(listHeight / (ITEM_HEIGHT + MARGIN_TOP)),
    );

    const mList = leftFunctionList?.slice(
      Math.floor(listHeight / (ITEM_HEIGHT + MARGIN_TOP)) - 1,
      leftFunctionList?.length - 1,
    );
    setShowList(sList);
    setMoreList(mList);
  }, [windowHeight, leftFunctionList]);

  useEffect(() => {
    getData();
  }, [windowHeight, getData]);

  const renderMore = useCallback(() => {
    return (
      <div className="show-more-list">
        {moreList?.map((item) => (
          <div
            className={classnames('item', {
              'active-item': active === item?.id,
            })}
            key={item?.id}
          >
            <span className="icon">{item?.icon}</span>
            <span className="label">{item?.label}</span>
            <div className="remove">
              <Icon type="icon-a-Group1341" size={12} />
            </div>
          </div>
        ))}
      </div>
    );
  }, [moreList, active]);

  const handleRemoveFunction = (v) => {
    removeFunction(v);
  };

  const handleActiveFunction = (v) => {
    // setActive(v?.id);
    // console.log(v, 'v');
    if (v?.source) {
      vsf?.history?.push(v?.source);
    }
  };

  // const handleToggleRoute = useCallback(
  //   () => {
  //     const target = leftFunctionList?.find(
  //       (item) =>
  //         (?.history?.location?.pathname ?? '')?.indexOf(item?.source) >= 0,
  //     );
  //     if (target) {
  //       setActive(target?.id);
  //     }
  //   },
  //   [leftFunctionList],
  // );

  useEffect(() => {
    const target = leftFunctionList?.find(
      (item) => vsf?.history?.location?.pathname === item?.source,
    );
    if (target) {
      setActive(target?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.history?.location?.pathname, leftFunctionList]);

  return (
    <div
      className={classnames('sider-menu', {
        'sider-menu-collapsed': collapsed,
      })}
    >
      <div className="show-list">
        {showList?.map((item) => {
          return (
            <Popover
              className={classnames('item', {
                'active-item': active === item?.id,
              })}
              key={item?.id}
              content={() => item.name}
              trigger="hover"
              style={{ padding: '0 10px' }}
              placement="right"
            >
              <div
                onClick={() => {
                  handleActiveFunction(item);
                }}
              >
                <span className="icon">
                  <Icon type={item?.icon} size={20} />
                </span>
                <span className="label">{item?.name}</span>
                <div
                  className="remove"
                  onClick={() => {
                    handleRemoveFunction(item);
                  }}
                >
                  <Icon type="icon-a-Group1341" size={12} />
                </div>
              </div>
            </Popover>
          );
        })}
      </div>
      {(moreList ?? [])?.length > 0 && (
        <Popover
          placement="rightBottom"
          title="更多功能"
          content={renderMore}
          trigger="hover"
          overlayClassName="sider-menu-more"
          key={moreList?.length}
        >
          <div
            className={classnames('item', {
              'active-item': active === 'more',
            })}
            onClick={() => {
              handleActiveFunction({
                id: 'more',
              });
            }}
          >
            <span className="icon">
              <Icon type="icon-gengduo" size={20} />
            </span>
            <span className="label">更多</span>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default definePage(Index);
// export default Index;
