import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderCheckController_checkOrders_a6b510: {
    method: 'post',
    url: '/api/clinic-record-order/check-orders',
    parameterFomatter: (data?: {
      orders: OrderCheckEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientOrderCheckResultEoClinicRecordOrderPersistEo[],
    ) => data,
  },
});
