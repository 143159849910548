import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpAdtController_queryErpTransferCancelDetailVoByErpTransferCancelDetailQto_a7d2d9:
    {
      method: 'post',
      url: '/api/clinic-erp-adt/query-erp-transfer-cancel-detail-vo-by-erp-transfer-cancel-detail-qto',
      parameterFomatter: (data?: {
        qto: ErpTransferCancelDetailQtoClinicErpAdtPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpTransferCancelDetailVoClinicErpAdtEntranceWebVo[],
      ) => data,
    },
  ClinicErpAdtController_cancelTransferErpPatientOutFuncFlowExecutor_de9abb: {
    method: 'post',
    url: '/api/clinic-erp-adt/cancel-transfer-erp-patient-out-func-flow-executor',
    parameterFomatter: (data?: {
      erpTransferRecordId: number;
      cancelTransferErpPatientOutEo: CancelTransferErpPatientOutEoClinicErpAdtPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
