import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpDrugAuditController_getAllByGetRefundAuditQto_5648ad: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-all-by-get-refund-audit-qto',
    parameterFomatter: (data?: {
      qto: GetRefundAuditQtoDrugDispenseAuditPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugDispenseAuditDetailMasterAuditVoDrugDispenseAuditEntranceWebVo[],
    ) => data,
  },
});
