import { createServices } from '@vs/vsf-boot';
export default createServices({
  OperationDictionaryController_getPagedByOperationDictionaryPageQto_33ffa3: {
    method: 'post',
    url: '/api/base-common/get-paged-by-operation-dictionary-page-qto',
    parameterFomatter: (data?: {
      qto: OperationDictionaryPageQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OperationDictionaryVoBaseCommonEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OperationDictionaryController_updateOperationDictionary_b93514: {
    method: 'post',
    url: '/api/base-common/update-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_deleteOperationDictionary_f3b6eb: {
    method: 'post',
    url: '/api/base-common/delete-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_createOperationDictionary_3b9dcf: {
    method: 'post',
    url: '/api/base-common/create-operation-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateOperationDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_queryOperationDictionaryByOperationCode_7ff372:
    {
      method: 'post',
      url: '/api/base-common/query-operation-dictionary-by-operation-code',
      parameterFomatter: (data?: {
        operationSortQto: OperationSortQtoBaseCommonPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OperationDictionaryDtoBaseCommonManagerDto[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OperationDictionaryController_queryRepeatRecord_653e13: {
    method: 'post',
    url: '/api/base-common/query-repeat-record',
    parameterFomatter: (data?: {
      eo: OperationDictionaryCodeVersionOperationCodeOperationNameEoBaseCommonPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  OperationDictionaryController_batchCreateOperationDictionary_1b7a9b: {
    method: 'post',
    url: '/api/base-common/batch-create-operation-dictionary',
    parameterFomatter: (data?: {
      btoParamList: CreateOperationDictionaryBtoBaseCommonServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OperationDictionaryController_getAllByOperationAllDictionaryQto_7990f7: {
    method: 'post',
    url: '/api/base-common/get-all-by-operation-all-dictionary-qto',
    parameterFomatter: (data?: {
      qto: OperationAllDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationDictionaryVoBaseCommonEntranceWebVo[],
    ) => data,
  },
});
