import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_saveMedicalRecord_a12ec8: {
    method: 'post',
    url: '/api/clinic-inp-visit/save-medical-record',
    parameterFomatter: (data?: {
      updatePatientBto: UpdatePatientBtoPatientMainServiceBto;
      saveInpVisitForMedicalRecordBto: SaveInpVisitForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionStatisticsBto: SaveInpVisitExtensionStatisticsBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionNurseRecordForMedicalRecordBto: SaveInpVisitExtensionNurseRecordForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionBloodInfoBto: SaveInpVisitExtensionBloodInfoBtoClinicInpVisitServiceBto;
      createDiagnosisRecordBtoList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      saveOperationRecordBtoList: SaveOperationRecordBtoClinicInpVisitRecordServiceBto[];
      saveIcuRecordBtoList: SaveIcuRecordBtoClinicRecordServiceBto[];
      saveBloodTransfusionRecordBto: SaveBloodTransfusionRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
