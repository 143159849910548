import React from 'react';

import { TreeMenu } from '@/components';
import { definePage } from '@vs/vsf-boot';

const Tree = (props) => {
  console.log(props, 12);
  // const contvertTreeChildren = (data) => {
  //   return data?.map(item => ({
  //     ...item,
  //     children: item?.children?.length ? contvertTreeChildren(item?.children) : null
  //   }))
  // }
  const contvertTreeChildren = (data) => {
    return data?.map((item) => {
      const { children, ...other } = item;
      return children?.length > 0
        ? {
            ...other,
            children: contvertTreeChildren(item?.children),
          }
        : {
            code: item?.value,
            label: item?.label,
            parentCode: item?.parentCode,
          };
    });
  };

  return (
    <TreeMenu
      title="药理分类"
      isTree
      treeData={contvertTreeChildren(vsf.stores.dbenums.enums.DRUG_CLASS_DICT)}
      fieldNames={{ title: 'label', key: 'code', children: 'children' }}
      row={{
        cancelSelect: true,
      }}
      onSelect={(_value) => {
        props.onChange?.(_value);
      }}
    />
  );
};

export default definePage(Tree);
