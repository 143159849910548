import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAddition,
  VSTableToolbar,
  Modal,
  TimePicker,
  message,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useState } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem } from '@/utils';
const ClinicAppointPlatformTable = (props) => {
  const onSave = async (params) => {
    const data = vsf.refs.AppointPlatformTable.getValues();
    const formatData = data.map((d) => {
      const dict = { ...d, registerPlatformIndicator: false };
      if (('' + dict.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });

    const arr = [];
    formatData.map((item) => {
      arr.push(item.platformCode);
    });

    let newList = Array.from(new Set(arr));
    if (newList.length !== arr.length) {
      message.error('平台编号不允许重复，请修改重复的平台编号');
    } else {
      const res =
        await vsf?.services?.OutpRegisterDefineController_addUpdatePlatformDictionary_f592fa?.(
          { btoParamList: formatData, extApiParam: {} },
        );
      if (res.code === 200) {
        message.success('保存成功');
        vsf.refs.AppointPlatformTable.reload();
      }
    }
  };
  const onFetch = async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a?.(
        {
          qto: {
            // from: params.pagination?.from ?? 0,
            // size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            registerPlatformIndicatorIs: false,
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deletePlatformDictionary_16247a?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    if (res.code === 200) {
      message.success('删除成功');
      vsf.refs.AppointPlatformTable.reload();
    }
    return res?.code === 200;
  };
  return (
    <div className="layout-optpatient">
      <VSPromiseTable
        className="aspirin-table"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.platformName}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        vsid="24664"
        id="AppointPlatformTable"
        onFetch={onFetch}
        onRemove={onRemove}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        pagination={false}
        scroll={{
          // y: getScrollY(280),
          y: getScrollYRem(20),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformCode']}
          title="平台编号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 20,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformName']}
          title="平台名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 80,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['openDays']}
          title="预约周期(天数)"
          valueType="digit"
          fieldProps={{}}
          formItemProps={{
            rules: [
              {
                validator: (_, value) => {
                  if (value < 0) return Promise.reject('不可输入负数');
                  return Promise.resolve();
                },
              },
            ],
          }}
        />

        <VSTableColumn
          dataIndex={['openTime']}
          title="放号时间"
          valueType="text"
          renderFormItem={(...arr) => {
            const [key, recode, form] = arr;
            const onChange = (changeDate) => {
              form.setFieldValue(recode.recordKey, {
                ...recode.record,
                openTime: `${changeDate}`,
              });
            };
            return <TimePicker onChange={onChange} format={'HH:mm'} />;
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['sendMessageIndicator']}
          title="发送预约短信"
          valueType="switch"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['uploadInfoAddress']}
          title="上传信息地址"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['platformInstitutionId']}
          title="医院在平台的编码"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableToolbar
          title="预约平台字典维护"
          style={{ paddingLeft: '20px' }}
        >
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(ClinicAppointPlatformTable);
