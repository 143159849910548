import React, { memo, useEffect, useState } from 'react';

import { Badge, Tooltip } from '@vs/vsf-kit';
import snowflake from '@/assets/icon/snowflake.png';
import sun from '@/assets/icon/sun.png';

import './index.less';
import classNames from 'classnames';

const Cart = memo(
  (props) => {
    const { valuetype, tableData, tableRowData, checkCellData } = props;
    const visitTypeData = {
      OUTP: '门诊',
      ERP: '急诊',
      PHYSICAL_EXAM: '体检',
    };
    const visitColor = {
      OUTP: '#50bc35',
      ERP: '#FF7E7E',
      PHYSICAL_EXAM: '#3276E8',
    };
    const [dataSorce, setDataSorce] = useState();
    useEffect(() => {
      let list = tableRowData?.clinicRegisterScheduleDetailDetailDtoList.sort(
        (a, b) => {
          // 将上午排在下午前面
          if (a.timeDescription === 'AM') return -1;
          if (b.timeDescription === 'PM') return 1;
          return 0;
        },
      );
      if (list && list.length === 1 && list[0].timeDescription === 'PM') {
        list = [{}, list[0]];
      }
      list && setDataSorce(list);
    }, [tableRowData]);

    return valuetype === '1' ? (
      <div className={classNames('cart-main', props?.className)}>
        <Badge.Ribbon
          text={visitTypeData?.[tableData?.visitType]}
          color={visitColor?.[tableData?.visitType]}
        >
          <div className="cart-containter">
            <div>
              <div className="cart-icon"></div>
              <div className="cart-number">
                <div className="cart-number-type">
                  {tableData?.clinicRegisterType?.clinicTypeClass === 'common'
                    ? '普通号'
                    : '专家号'}
                </div>
                <div className="cart-doctor">
                  {tableData?.doctor?.staffName}
                </div>
              </div>
            </div>
            <div className="cart-outpatient">
              <Tooltip title={tableData?.clinicLabel}>
                {tableData?.clinicLabel}
              </Tooltip>
            </div>
            <div className="cart-departments">
              <div className="cart-departments-title">科室：</div>
              <div className="cart-departments-content">
                {tableData?.clinicDepartment?.departmentName}
              </div>
            </div>
            <div className="cart-departments" style={{ marginBottom: '16px' }}>
              <div className="cart-departments-title">专科：</div>
              <div className="cart-departments-content">
                {tableData?.outpSpecialClinic?.outpSpecialClinicName}
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      </div>
    ) : (
      <div className={classNames('cart-content')} data-id={tableRowData?.id}>
        {/* <div className="cart-am"> */}
        {dataSorce?.map((item, index) => {
          return (
            <div className="cart-am">
              {item?.timeDescription && (
                <div className="img-icon">
                  <div className="total-top">
                    <div className="cart-date">
                      {item?.timeDescription === 'AM' ? '上午' : '下午'}
                    </div>
                    <div className="cart-time">
                      {`${
                        item?.waitTimePeriod?.startTimePoint?.hour?.toString()
                          .length < 2
                          ? `0${item?.waitTimePeriod?.startTimePoint?.hour}`
                          : item?.waitTimePeriod?.startTimePoint?.hour
                      }:
                    ${
                      item?.waitTimePeriod?.startTimePoint?.minute?.toString()
                        .length < 2
                        ? `0${item?.waitTimePeriod?.startTimePoint?.minute?.toString()}`
                        : item?.waitTimePeriod?.startTimePoint?.minute
                    }
                       -${
                         item?.waitTimePeriod?.endTimePoint?.hour?.toString()
                           .length < 2
                           ? `0${item?.waitTimePeriod?.endTimePoint?.hour}`
                           : item?.waitTimePeriod?.endTimePoint?.hour
                       }:
                     ${
                       item?.waitTimePeriod?.endTimePoint?.minute?.toString()
                         .length < 2
                         ? `0${item?.waitTimePeriod?.endTimePoint?.minute?.toString()}`
                         : item?.waitTimePeriod?.endTimePoint?.minute
                     }`}
                    </div>
                    <div className="snow-sun">
                      <div className="cart-icon1">
                        {item?.summerIndicator ? (
                          <img src={sun} alt="" />
                        ) : (
                          <img src={snowflake} alt="" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="total-bottom">
                    <div className="total-bottom-total">
                      <div className="total-bottom-total-title ellipsis">
                        总量:
                      </div>
                      <div className="total-bottom-total-num ellipsis">
                        {item?.totalLimit}
                      </div>
                    </div>
                    <div className="total-bottom-total">
                      <div className="total-bottom-total-title ellipsis">
                        预约量:
                      </div>
                      <div className="total-bottom-total-num ellipsis">
                        {item?.appointLimit}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {/* </div> */}
      </div>
    );
  },
  (newly, old) => Object.is(newly, old),
);

export default Cart;
