import { Modal, VSPromiseTable, VSTableColumn, Pagination } from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import React, { useEffect, useCallback, useState, memo } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
function DrugModal(props) {
  const { drugSupplyData } = props;
  const [open, setOpen] = useState();
  const [subData, setSubData] = useState();
  const [subOpen, setSubOPen] = useState(false);
  useEffect(() => {
    const param = drugSupplyData?.filter((item) => !item?.supplyIndicator);

    if (param && param.length > 0) {
      Tip({
        content: '有药品处于已屏蔽(不可控)状态,需要修改供应标识吗？',
        onOk: () => {
          setOpen(true);
        },
      });
    }
  }, [drugSupplyData]);
  const onUpdate = async (params) => {
    {
      Tip({
        content: `确认修改为${params?.supplyIndicator ? '可供' : '不可供'}!`,
        onOk: async () => {
          const res =
            await vsf.services?.DrugStorageController_updateDrugStockSupplyIndicator_4b7b0b?.(
              {
                eo: params,
              },
            );
          // if()
          return res;
        },
      });
    }
  };
  const onFetch = (param) => {
    const data = drugSupplyData?.map((item, index) => {
      return { ...item, id: index + 1 };
    });
    return data?.filter((item) => !item?.supplyIndicator);
  };
  const onSubFetch = async (params) => {
    if (subData) {
      const res =
        await vsf.services?.DrugStorageController_getDrugSupplyLogList_4df404?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...subData,
            },
          },
        );

      return res?.data?.map?.((e) => ({
        ...e,
        supplyOrLimitIndicator: SupplyReflection(
          e?.logType,
          e?.supplyOrLimitIndicator,
        ),
      }));
    }
  };
  const SupplyReflection = (logType, supplyOrLimitIndicator) => {
    let response;
    switch (logType) {
      case 'SUPPLY':
        response = supplyOrLimitIndicator ? '可供' : '不可供';
        break;
      case 'LIMITED':
        response = supplyOrLimitIndicator ? '限量' : '不限量';
        break;
      default:
        response = supplyOrLimitIndicator ? '可供' : '不可供';
        break;
    }
    return response;
  };
  return (
    <div>
      <Modal
        title={'供应标志维护'}
        open={open}
        width="80%"
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
      >
        <VSPromiseTable
          onFetch={onFetch}
          id="mockTableId"
          vsid="49245123221"
          pagination={false}
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
          }}
          onRecord={() => ({ id: 'create_' + Math.random() })}
        >
          <VSTableColumn
            dataIndex={['drugId']}
            title="序号"
            hideInTable
            valueType="text"
            fieldProps={{
              disabled: true,
            }}
          />

          <VSTableColumn
            dataIndex={['drugProductName']}
            title="药品名称"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['details', 'packageSpecification']}
            title="包装规格"
            valueType="text"
            editable={false}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['amountPrePackage']}
            title="包装数量"
            valueType="text"
            editable={false}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['stockAmount']}
            title="库存量"
            valueType="text"
            editable={false}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['supplyIndicator']}
            title="供应标识"
            valueType="switch"
            fieldProps={({ drugPriceItemId }) => {
              return {
                onChange: (supplyIndicator) => {
                  onUpdate({
                    supplyIndicator,
                    drugPriceItemId,
                  });
                },
              };
            }}
          />
          <VSTableColumn
            dataIndex={['weihu']}
            title="维护记录"
            valueType="text"
            fieldProps={{}}
            renderFormItem={(schema, recode, form) => {
              return (
                <a
                  onClick={() => {
                    // setSubData(recode.record);
                    setSubData({
                      drugProductId: recode?.record?.drugProductId,
                      drugStorageId: recode?.record?.details?.drugItemId,
                      packageSpecification:
                        recode?.record?.details?.packageSpecification,
                    });
                    setSubOPen(!subOpen);
                  }}
                >
                  查看详情
                </a>
              );
            }}
          />
        </VSPromiseTable>
        <Modal
          title="维护历史记录"
          open={subOpen}
          width="50%"
          onCancel={() => {
            setSubOPen(false);
          }}
        >
          <VSPromiseTable onFetch={onSubFetch} id="mockTableId">
            <VSTableColumn
              dataIndex={['handleDate']}
              title="日期"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              width={186}
              preview
            />

            <VSTableColumn
              dataIndex={['supplyOrLimitIndicator']}
              title="操作"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['reason']}
              title="理由"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['operator', 'staffName']}
              title=" 操作人"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['memo']}
              title="备注"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSPromiseTable>
        </Modal>
      </Modal>
    </div>
  );
}

export default memo(DrugModal);
