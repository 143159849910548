import { createServices } from '@vs/vsf-boot';
export default createServices({
  ChargeSolutionDtoController_getChargeSolutionList_114af0: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-list',
    parameterFomatter: (data?: {
      qto: ChargeSolutionQtoFinanceBillingSettingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionVoFinanceBillingSettingEntranceWebVo[],
    ) => data,
  },
  ChargeSolutionItemDtoController_getChargeSolutionItemList_09f8cd: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-item-list',
    parameterFomatter: (data?: {
      qto: ChargeSolutionItemQtoFinanceBillingSettingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionItemVoFinanceBillingSettingEntranceWebVo[],
    ) => data,
  },
  ChargeSolutionItemBOController_saveChargeSolutionItemList_30322a: {
    method: 'post',
    url: '/api/finance-billing-setting/save-charge-solution-item-list',
    parameterFomatter: (data?: {
      btoList: CreateOrUpdateChargeSolutionItemBtoFinanceBillingSettingServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionItemVoFinanceBillingSettingEntranceWebVo[],
    ) => data,
  },
  ChargeSolutionItemBOController_createChargeSolutionItem_1e0429: {
    method: 'post',
    url: '/api/finance-billing-setting/create-charge-solution-item',
    parameterFomatter: (data?: {
      createBto: CreateOrUpdateChargeSolutionItemBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionItemBOController_stopChargeSolutionItemById_0a535a: {
    method: 'post',
    url: '/api/finance-billing-setting/stop-charge-solution-item-by-id',
    parameterFomatter: (data?: {
      chargeSolutionItemId: number;
      stopDate: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ChargeSolutionItemBOController_updateChargeSolutionItem_960e29: {
    method: 'post',
    url: '/api/finance-billing-setting/update-charge-solution-item',
    parameterFomatter: (data?: {
      createBto: CreateOrUpdateChargeSolutionItemBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionItemBOController_batchStopChargeSolutionItem_a24111: {
    method: 'post',
    url: '/api/finance-billing-setting/batch-stop-charge-solution-item',
    parameterFomatter: (data?: {
      chargeSolutionItemIdList: number[];
      stopDate: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListController_getPagedByPriceItemCurrentQto_22ee99: {
    method: 'post',
    url: '/api/price-list/get-paged-by-price-item-current-qto',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
      isIncludeDrugOrHerb: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemBaseVoPriceListEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
