const int = (value) => {
  const status = value?.filter((item) =>
    ['SINGLE_TOTAL', 'TAKE_HIGH']?.includes(item?.examCalculation),
  );
  const data = value?.filter(
    (item) => item?.priceItem?.examAttribute === 'VARIABLE',
  );
  return status?.length || data?.length;
};

export default int;
