import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getDrugImportRecordToBeAccount_20f690: {
    method: 'post',
    url: '/api/drug-storage-import/get-drug-import-record-to-be-account',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugStorageImportExportController_invalidDrugImportRecord_d16f0f: {
    method: 'post',
    url: '/api/drug-storage-import/invalid-drug-import-record',
    parameterFomatter: (data?: {
      bto: InvalidActiveDrugImportMasterRecordBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageImportExportController_convertToDrugImportInfo_22cd28: {
    method: 'post',
    url: '/api/drug-storage-import-export/convert-to-drug-import-info',
    parameterFomatter: (data?: { exportMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportInfoVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
});
