import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0: {
    method: 'post',
    url: '/api/application/get-sys-config-base-code-without-root',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8: {
    method: 'post',
    url: '/api/base-parameter/get-all-by-system-parameter-qto',
    parameterFomatter: (data?: {
      qto: SystemParameterQtoBaseParameterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigDtoBaseParameterManagerDto[],
    ) => data,
  },
  SystemParameterConfigBOController_createSystemParameter_704b54: {
    method: 'post',
    url: '/api/base_parameter/createSystemParameter',
    parameterFomatter: (data?: {
      btoParam: CreateSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  SystemParameterConfigBOController_updateSystemParameter_c23bcd: {
    method: 'post',
    url: '/api/base_parameter/updateSystemParameter',
    parameterFomatter: (data?: {
      btoParam: UpdateSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  SystemParameterConfigBOController_deleteSystemParameter_34c82b: {
    method: 'post',
    url: '/api/base_parameter/deleteSystemParameter',
    parameterFomatter: (data?: {
      btoParam: DeleteSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
