import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_performRefund_e2e545: {
    method: 'post',
    url: '/api/drug-prescription-dispense/perform-refund',
    parameterFomatter: (data?: {
      refundDetails: RefundApplyDetailEoDrugPrescriptionDispensePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugPrescriptionRefundDispenseController_getListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIds_5ae382:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-list-drug-prescription-refund-apply-master-detail-dispense-vo-by-ids',
      parameterFomatter: (data?: { id: number[] }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionRefundApplyMasterDetailDispenseVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/get-outp-visit-patient-vitalsign-allergy-vo-by-id',
      parameterFomatter: (data?: { id: number; queryDay: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: OutpVisitPatientVitalsignAllergyVoClinicOutpVisitEntranceWebVo,
      ) => data,
    },
});
