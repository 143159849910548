import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const settings = defineStore({
  /**
   * shortcuts 快捷按键
   */
  shortcuts: [
    {
      key: 'Drug',
      label: '打开药疗输入法',
      value: '⌥+1',
      keys: ['Alt', '1'],
    },
    {
      key: 'Chm',
      label: '打开中草药输入法',
      value: '⌥+2',
      keys: ['Alt', '2'],
    },
    { key: 'Exam', label: '打开检查输入法', value: '⌥+3', keys: ['Alt', '3'] },
    { key: 'Test', label: '打开检验输入法', value: '⌥+4', keys: ['Alt', '4'] },
    {
      key: 'Operation',
      label: '打开手术输入法',
      value: '⌥+5',
      keys: ['Alt', '5'],
    },
    {
      key: 'Pathology',
      label: '打开病理输入法',
      value: '⌥+6',
      keys: ['Alt', '6'],
    },
    {
      key: 'Consultation',
      label: '打开会诊输入法',
      value: '⌥+7',
      keys: ['Alt', '7'],
    },
    {
      key: 'Dispose',
      label: '打开处置输入法',
      value: '⌥+8',
      keys: ['Alt', '8'],
    },
    { key: 'Order', label: '打开医嘱模板', value: '⌥+T', keys: ['Alt', 'T'] },
    {
      key: 'History',
      label: '打开历史医嘱',
      value: '⌥+R',
      keys: ['Alt', 'R'],
    },
    { key: 'Close', label: '关闭页面', value: '⌥+C', keys: ['Alt', 'C'] },
    { key: 'Print', label: '打印', value: '⌥+P', keys: ['Alt', 'P'] },
    { key: 'Cancel', label: '取消发送', value: '⌥+J', keys: ['Alt', 'J'] },
    {
      key: 'Closeaccount',
      label: '诊间结算',
      value: '⌥+B',
      keys: ['Alt', 'B'],
    },
    { key: 'Save', label: '保存', value: '⌥+S', keys: ['Alt', 'S'] },
    { key: 'Submit', label: '发送', value: '⌥+W', keys: ['Alt', 'W'] },
    { key: 'Saveastpl', label: '存为模板', value: '⌥+G', keys: ['Alt', 'G'] },
  ],

  screenSize: 'normal',

  setShortcuts: (list) => {
    settings.shortcuts = list;
  },
  setScreenSize: (v) => {
    settings.screenSize = v;
  },
});

export default settings;
