import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpPreExamController_addCountErpAccidentEventRecord_a23090: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/add-count-erp-accident-event-record',
    parameterFomatter: (data?: {
      btoParam: AddCountErpAccidentEventRecordMasterBtoClinicErpPreExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpAccidentEventRecordMasterAddVoClinicErpPreExamEntranceWebVo,
    ) => data,
  },
});
