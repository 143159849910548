import {
  Image,
  VSForm,
  VSFormItem,
  VSFormLayout,
  message,
  Select,
  VSTableColumn,
  VSPromiseTable,
  Modal,
  Button,
  Tooltip,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useState, useCallback, useEffect } from 'react';
import readCard from '@/assets/icon/readCard.png';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';

function ClinicOutpVisitListForm({ callback, doubleClikChange, routes }) {
  const [identificationClass, setIdentificationClass] = useState();

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const [patientModalOpen, setPatientModalOpen] = useState(false);
  const { run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryUnRefundOutpSettleMaster_a0a382?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              ...params,
            },
          },
        );

      callback(res?.data);
      if (res?.code === 200 && res?.data?.result.length > 0) {
        vsf?.refs?.ClinicOutpVisitListForm?.setFieldsValue({
          ...res?.data?.result[0]?.outpSettleMasterVo,
          staffName:
            res?.data?.result[0]?.outpSettleMasterVo?.operatorStaffId
              ?.staffName,
        });

        if (
          ['ALL_REFUND', 'PARTIAL_REFUND'].includes(
            res?.data?.result[0]?.outpSettleMasterVo?.settleStatus,
          ) ||
          res?.data?.result[0]?.outpSettleMasterVo?.refundVsOutpSettleId
        ) {
          Modal.warn({
            title: '该结算记录已退费/已作废，不可再次操作，仅可查看',
          });
        }

        return res?.data;
      } else {
        message.error('当前未查到退费明细!');
        return [];
      }
    },
    { manual: true },
  );

  const { data: receiptData, run: receiptRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryAllOutpSettleMaster_246314?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...tableParam,
              // settleDateRangeIn: settleDateRangeIn,
            },
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    {
      manual: true,
    },
  );
  const [tableParam, setTableParam] = useState();

  useEffect(() => {
    receiptRun();
  }, [tableParam]);

  useEffect(() => {
    // 床卡跳转过来的
    if (routes?.query?.settleNumber) {
      run({ settleNumberIs: routes?.query?.settleNumber });
    }
  }, [routes]);

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryAllOutpSettleMaster_246314?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...tableParam,
            },
          },
        );

      console.log(res?.data?.result);
      const arr = res?.data?.result?.map((item) => {
        const payment = {
          ...item?.payment,
          paymentDetailDtoList: item?.payment?.paymentDetailDtoList[0] ?? {},
        };
        return { ...item, payment: payment };
      });
      return {
        data: (arr ?? res?.data ?? [])?.map((item) => ({
          ...item,
          outpReceiptRecordDtoList:
            item?.outpReceiptRecordDtoList?.[0] ?? undefined,
        })),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [tableParam],
  );
  const { run: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
        setPatientModalOpen(true);
        setTableParam({
          displayIdIs: res?.data?.result[0].displayId,
        });
      }
      return res?.data?.result;
    },
    { manual: true },
  );
  return (
    <div>
      <VSForm id="ClinicOutpVisitListForm">
        <VSFormLayout columnCount={2}>
          <VSFormLayout columnCount={2}>
            <VSFormItem
              name={'physicalReceiptNumber'}
              valueType="text"
              label={'票据号'}
              columnKey={'physicalReceiptNumberIs'}
              fieldProps={{
                onPressEnter: (v) => {
                  run({ physicalReceiptNumberIs: v.target.value });
                },
              }}
            />
            <VSFormItem
              valueType="text"
              label={'业务流水号'}
              name={'settleNumber'}
              columnKey={'settleNumberIs'}
              fieldProps={{
                onPressEnter: (e) => {
                  run({ settleNumberIs: e.target.value });
                },
              }}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={1}>
            <VSFormItem
              name={['patientIdIs']}
              label="证件及编号"
              valueType="text"
              fieldProps={{
                // style: { paddingLeft: '10px' },
                className: 'patientIdIs',
                addonBefore: (
                  <>
                    <Select
                      // style={{ width: '200px' }}
                      className="patientIdIs-select"
                      dataSource={certificateData}
                      fieldNames={{ label: 'name', value: 'code' }}
                      onChange={(v) => {
                        console.log(v);
                        if (v) {
                          setIdentificationClass(v.code);
                        } else {
                          setIdentificationClass(undefined);
                        }
                      }}
                      allowClear
                    />
                  </>
                ),

                suffix: (
                  <div
                    className="suffix-readCard"
                    onClick={() => {
                      message.success('读卡中');
                    }}
                  >
                    <div className="suffix-cardImg">
                      <Image
                        src={readCard}
                        // style={{ width: '20px', height: '20px' }}
                        className="suffix-cardImg-image"
                        preview={false}
                      ></Image>
                    </div>
                    <div className="suffix-cardText">读卡</div>
                  </div>
                ),
                placeholder: '请读卡',
                onPressEnter: (e) => {
                  if (!identificationClass) {
                    message.error('请选择证件类型');
                  }
                  if (identificationClass === '身份证' && e.target.value) {
                    patientDetallRun({
                      identificationNumberIn: [e.target.value],
                      identificationClassIn: [identificationClass],
                    });
                  } else if (
                    identificationClass === '患者ID' &&
                    e.target.value
                  ) {
                    patientDetallRun({
                      displayIdIs: e.target.value,
                    });
                  }
                },
              }}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout columnCount={6}>
          <VSFormItem
            name={['patient', 'displayId']}
            valueType="text"
            label={'患者ID'}
            readonly
          />
          <VSFormItem name={'name'} valueType="text" label={'姓名'} readonly />
          <VSFormItem
            name={'chargeType'}
            valueType="select"
            label={'费别'}
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            readonly
          />
          <VSFormItem
            name={'identityType'}
            valueType="select"
            label={'人员类别'}
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
            readonly
          />
          <VSFormItem
            name={'staffName'}
            valueType="text"
            label={'收款员'}
            readonly
          />
          <VSFormItem
            name={'settleDate'}
            valueType="text"
            label={'收款时间'}
            readonly
          />
        </VSFormLayout>
      </VSForm>
      <Modal
        title={<div style={{ color: '#3276E8' }}>结算记录</div>}
        open={patientModalOpen}
        onCancel={() => setPatientModalOpen(false)}
        width={'80%'}
        footer={
          <Button onClick={() => setPatientModalOpen(false)}>取消</Button>
        }
      >
        <VSPromiseTable
          vsid="42317"
          id="outpSettleMasterQueryList"
          scroll={{
            x: 1968,
            y: getScrollYRem(30),
          }}
          rowClick={{
            type: 'default',
            action: 'double-click',
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                if (record?.settleNumber) {
                  setPatientModalOpen(false);
                }
                if (record?.clinicClass === 'CLINIC_REGISTER') {
                  doubleClikChange({
                    outpSettleMasterVo: { settleNumber: record?.settleNumber },
                  });
                  const urlParam = record?.settleNumber;
                  vsf?.navigateTo(
                    `/index/utpatient-withdrawal?dispalyId=${urlParam}`,
                  );
                } else {
                  doubleClikChange({
                    outpSettleMasterVo: { settleNumber: record?.settleNumber },
                  });
                }
              },
            };
          }}
          pagination={{ defaultPageSize: 10 }}
          onFetch={onFetch}
        >
          <VSTableColumn
            dataIndex={['patient', 'displayId']}
            title="患者ID"
            valueType="text"
            //   search
            order={0}
            // columnKey={'displayIdIs'}
            hideInTable
            formItemProps={{
              rules: [
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['nameLike']}
            title="姓名"
            valueType="text"
            //   search
            order={0}
            columnKey={'nameLike'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['identificationClass']}
            title="证件类型"
            valueType="select"
            //   search
            order={0}
            columnKey={'identificationClass'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['identificationNumber']}
            title="证件号"
            valueType="text"
            //   search
            order={0}
            columnKey={'identificationNumber'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['chargeTypeIs']}
            title="费别"
            valueType="select"
            //   search
            order={0}
            columnKey={'chargeTypeIs'}
            hideInTable
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['staffName']}
            title="收款员"
            valueType="text"
            //   search
            order={0}
            // columnKey={'operatorStaffIdIs'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['settleDateRangeIn']}
            title="收费日期"
            valueType="dateRange"
            //   search
            order={0}
            columnKey={'settleDateRangeIn'}
            hideInTable
            searchConfig={{
              transform: (values) => {
                if (!Array.isArray(values)) return values;
                return {
                  settleDateRangeIn: {
                    begin: values[0] || dayjs().startOf('day').valueOf(),
                    end: values[1] || dayjs().endOf('day').valueOf(),
                    beginInclude: true,
                    endInclude: true,
                  },
                };
              },
            }}
            transform={(values) => {
              if (!Array.isArray(values)) return values;
              return ['settleDateRangeIn'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              );
            }}
            convertValue={(obj) => {
              if (Array.isArray(obj)) return obj;
              return [obj?.begin, obj?.end];
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['totalCostIs']}
            title="应收总费用"
            valueType="digit"
            //   search
            order={0}
            columnKey={'totalCostIs'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['totalChargeIs']}
            title="实收款"
            valueType="digit"
            //   search
            order={0}
            columnKey={'totalChargeIs'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['settleStatusIs']}
            title="交费状态"
            valueType="select"
            //   search
            order={0}
            columnKey={'settleStatusIs'}
            hideInTable
            dataSource={[
              { label: '未收费', value: 'NOT_CHARGE' },
              { label: '已收费', value: 'FINISH_CHARGE' },
              { label: '部分退费', value: 'PARTIAL_REFUND' },
              { label: '全部退费', value: 'ALL_REFUND' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['payTypeIs']}
            title="支付方式"
            valueType="select"
            //   search
            order={0}
            columnKey={'payTypeIs'}
            hideInTable
            dataSource={[
              { label: '现金', value: 'CASH' },
              { label: '支付宝', value: 'ALIPAY' },
              { label: '微信', value: 'WECHAT' },
              { label: '数字人民币', value: 'DIGITAL_RMB' },
              { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['settleTypeIs']}
            title="结算类型"
            valueType="select"
            //   search
            order={0}
            columnKey={'settleTypeIs'}
            hideInTable
            dataSource={[
              { label: '窗口', value: 'WINDOW' },
              { label: '自助机', value: 'SELF_MACHINE' },
              { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
              { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
              { label: '护士站结算', value: 'NURSE_STATION' },
              { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
              { label: '代煎收方', value: 'DECOCT' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['settleNumberIs']}
            title="结算号"
            valueType="text"
            //   search
            order={0}
            columnKey={'settleNumberIs'}
            hideInTable
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['insuranceCardNumberIs']}
            title="医保卡号"
            valueType="text"
            //   search
            order={0}
            columnKey={'insuranceCardNumberIs'}
            hideInTable
            formItemProps={{
              rules: [
                {
                  message: '医保卡号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 200,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['outpReceiptRecordDtoList', 'physicalReceiptNumber']}
            title="票据号"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['patient', 'displayId']}
            title="患者ID"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['chargeType']}
            title="费别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['identityType']}
            title="人员类别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['operatorStaffId', 'staffName']}
            title="收款员"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
            render={(_, values) => {
              return values?.operatorStaffId?.staffName;
            }}
          />

          <VSTableColumn
            dataIndex={['settleDate']}
            title="结算日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            render={(_, values) => {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={values?.settleDate}>
                    {values?.settleDate}
                  </Tooltip>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['totalCost']}
            title="应收总费用"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['totalCharge']}
            title="实收款"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['payment', 'paymentDetailDtoList', 'payType']}
            title="付款方式"
            fieldProps={{}}
            valueType="select"
            dataSource={[
              { label: '现金', value: 'CASH' },
              { label: '支付宝', value: 'ALIPAY' },
              { label: '微信', value: 'WECHAT' },
              { label: '数字人民币', value: 'DIGITAL_RMB' },
              { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
            ]}
          />

          <VSTableColumn
            dataIndex={['settleStatus']}
            title="交费状态"
            valueType="text"
            // dataSource={[
            //   { label: '未收费', value: 'NOT_CHARGE' },
            //   { label: '已收费1', value: 'FINISH_CHARGE' },
            //   { label: '部分退费', value: 'PARTIAL_REFUND' },
            //   { label: '全部退费', value: 'ALL_REFUND' },
            // ]}
            fieldProps={{}}
            render={(_, node) => {
              let statestr, style;
              if (node?.refundVsOutpSettleId) style = { color: 'red' };
              switch (node?.settleStatus) {
                case 'NOT_CHARGE':
                  statestr = '未收费';
                  break;
                case 'FINISH_CHARGE':
                  statestr = `已收费${
                    node?.refundVsOutpSettleId ? '[已退]' : ''
                  }`;
                  break;
                case 'PARTIAL_REFUND':
                  statestr = '部分退费';
                  break;
                case 'ALL_REFUND':
                  statestr = '全部退费';
                  break;
                default:
                  break;
              }
              return <span style={{ ...style }}>{statestr}</span>;
            }}
          />

          <VSTableColumn
            dataIndex={['outpReceiptRecordDtoList', 'receiptPrintStatus']}
            title="票据打印状态"
            valueType="select"
            // width={100}
            width={labelNumberWidth(8)}
            dataSource={[
              { label: '待打印', value: 'WAIT_PRINT' },
              { label: '已打印', value: 'PRINT' },
              { label: '作废', value: 'CANCEL' },
            ]}
            // fieldProps={{
            //   defaultValue: 'WAIT_PRINT',
            // }}
          />

          <VSTableColumn
            dataIndex={[
              'outpReceiptRecordDtoList',
              'printStaffId',
              'staffName',
            ]}
            title="打印操作员"
            valueType="text"
            formItemProps={{}}
            fieldProps={{}}
            // render={(_, values) => {
            //   console.log(values);
            //   return values?.operatorStaffId?.staffName ?? '';
            // }}
          />

          <VSTableColumn
            dataIndex={['outpReceiptRecordDtoList', 'printDate']}
            title="打印日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['refundSettleDate']}
            title="退结算时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['settleType']}
            title="结算途径"
            valueType="select"
            dataSource={[
              { label: '窗口', value: 'WINDOW' },
              { label: '自助机', value: 'SELF_MACHINE' },
              { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
              { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
              { label: '护士站结算', value: 'NURSE_STATION' },
              { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
              { label: '代煎收方', value: 'DECOCT' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['insuranceSettleType']}
            title="医保结算类型"
            width={150}
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['unitInContractName']}
            title="单位发票标记"
            valueType="text"
            width={labelNumberWidth(8)}
            formItemProps={{
              rules: [
                {
                  message: '合同单位长度不合法',
                  type: 'string',
                  min: 0,
                  max: 50,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Modal>
    </div>
  );
}

export default ClinicOutpVisitListForm;
