import vsf, { definePage } from '@vs/vsf-boot';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import { askGetTreeWhole } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip'
import { Button } from '@vs/vsf-kit';
const ExamClassTree = forwardRef((props, ref) => {
  const value = useRef(false);
  useImperativeHandle(ref, () => ({
    onChange: (state) => {
      value.current = state
    },
  }));


  return (
    <TreeMenu
      id="ExamClassTree"
      treeProps={{
        defaultExpandAll: true,
      }}
      title="检查项目分类"
      reload
      onSelect={async (...[_value, tree, , selectedKeys, oldSelectedKeys]) => {
        const get = () => {
         /**
         * 如果当前树级不是0和在 病理部位/方法就跳回病理项目 tab 页
         */
          if (['4', '3'].includes(props?.active) && _value.treeLevel !== 0) {
            props?.setActive('5')
          }
          value.current = false;
          props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
        }
        if(value?.current) {
          return new Promise((resolve) => {
            Tip({
              content: `当前分类未保存，是否确定切换`,
              onOk: () => {
                get?.();
                resolve?.(selectedKeys);
              },
              onCancel: () => {
                resolve(oldSelectedKeys);
              }
            })
          });
        }
        get();
        return selectedKeys;
      }}
      loadData={async () => {
        const res =
          await vsf?.services?.ExamClassDictionaryDtoController_getAllByExamClassQto_3b08d4?.(
            {
              qto: {
                stopIndicatorIs: false
              }
            },
          );
        return {
          ...res,
          data: res?.data?.filter((item) => item?.itemClass === 'EXAM')?.map((item) => ({
            ...item,
            sortNumber: item?.serialNumber,
          }))
        }
      }}
      fieldNames={{ title: 'examClassName', key: 'id', children: 'children' }}
      dataRelationFieldNames={{
        id: 'id',
        parentId: 'parentExamClassId',
      }}
      row={{
        extraShow: 'select',
        expand: true,
        extra: (node) => {
          return (
            <div className="aspirin-tree-button">
              {
                node.treeLevel !== 2 && <Icon
                  type="icon-tianjia"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onAdd(node)
                  }}
                  size={20}
                />
              }
              <Icon type="icon-tingyong" size={20} onClick={(e) => {
                e.stopPropagation();
                props.onStop(node)
              }} />
            </div>
          )
        },
      }}
      headerRender={() => {
        return <Button onClick={() => props.onAdd({})}>新增</Button>
      }}
    />
  );
});
export default ExamClassTree;