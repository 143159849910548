import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_getAll_6ecb98: {
    method: 'post',
    url: '/api/dbenum/codetype/all/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeDtoBaseDictionaryManagerDto[],
    ) => data,
  },
  BaseDictionaryController_deleteBaseCodeType_5624c7: {
    method: 'post',
    url: '/api/base-dictionary/delete-base-code-type',
    parameterFomatter: (data?: {
      btoParam: DeleteBaseCodeTypeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
});
