import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Empty,
  Form,
  message,
  Popover,
  RadioGroup,
  Select as KitSelect,
  Table,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import {
  useAsyncEffect,
  useGetState,
  useRequest,
  useResetState,
  useSetState,
} from 'ahooks';
import { List, Select, Tag } from 'antd';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import { Determine } from '@/pages/Index/components';
import AsyncSuspense from '@/pages/Index/components/async_suspense';
import Block from '@/pages/Index/components/block_no_padding';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import {
  useFrequencyEffect,
  useGetResetInitState,
  useOnChangeValue,
  useWithResolvers,
} from '@/pages/Index/components/func/hooks';
import TabCheck from '@/pages/Index/components/tab_check';
import { labelColWidth, labelNumberWidth } from '@/utils';
import { add } from '@/utils/setDataUtil';

const mock = [
  { label: '个人常用', value: 'PERSONAL' },
  { label: '科室常用', value: 'DEPARTMENT' },
];

const epidemicClassList = [
  {
    label: 'HbsAg',
    value: '1',
  },
  {
    label: '梅毒',
    value: '2',
  },
  {
    label: '艾滋',
    value: '3',
  },
  {
    label: '丙肝',
    value: '4',
  },
];

const Main = (props, ref) => {
  const { baseInfo, currentTab, data, allPrice, hospitalType, isTemplate } =
    props;
  const { user } = vsf?.stores || {};

  const [checkList, setCheckList, getCheckList, restCheckList] =
    useGetResetInitState<any[]>([]);

  const [search, setSearch] = useResetState([]);
  const [associatedId, setAssociatedId] = useSetState({
    examSubClassIdIs: '',
    examThirdClassIdIs: '',
  });

  const [radio] = useOnChangeValue<'PERSONAL' | 'DEPARTMENT'>(
    'PERSONAL',
    (value) => {
      getList({
        examSubClassIdIs: associatedId?.examSubClassIdIs,
        favoriteTypeIs: value,
      });
      return value;
    },
  );

  const [firstList, setFirstList, getFirstList] = useGetResetInitState([]);
  const [orderDiagnosisIdList] = useOnChangeValue([]);
  const [secondList, setSecondList] = useResetState([]);

  const [thirdList, setThirdList] = useResetState<any[]>([]);

  const [detailInfo, setDetailInfo, restDetailInfo] = useResetState<any>({
    modifyAnesthesiaIndicator: false,
  });

  // useEffect(() => {
  //   // ExamForm
  //   console.log(checkList);
  // }, [checkList]);

  const [collapse, setCollapse] = useState<boolean>(false);

  const handleCheckItem = (data) => {
    const value = checkList?.map((item) => item?.examItemId) || [];
    if (value?.includes(data?.examItemId)) {
      setCheckList(() => {
        const state = checkList?.filter(
          (v) => v?.examItemId != data?.examItemId,
        );
        onReset(state);
        return state;
      });
    } else {
      setCheckList((value) => {
        const state = [...(value ?? []), data];
        onReset(state);
        return state;
      });
    }
  };

  const { loading, runAsync: getList } = useRequest(
    async (value) => {
      const res =
        await vsf?.services?.ClinicItemController_allClinicItemDictionaryCharge_d97a65?.(
          {
            qto: {
              itemClassIs: 'EXAM',
              favoriteTypeIs: radio?.value,
              ...value,
            },
          },
        );
      const list = res?.data?.map((item, index) => {
        const { id, ...rest } = item;
        // console.log(item?.clinicVsChargeDtoList?.reduce((creent,next)=>{
        //   console.log(next)
        //   return  add(creent,(),0)
        // },0));
        return {
          ...rest,
          associatedId: value,
          examItemName: item?.clinicItemName,
          examItemId: id,
          clinicVsChargeDtoList: item?.clinicVsChargeDtoList,
        };
      });
      setThirdList(list);
    },
    {
      manual: true,
    },
  );
  /**
   * 计算总价
   */
  const [form] = Form.useForm();
  const reportType = Form.useWatch('reportType', form);
  useAsyncEffect(async () => {
    if (getCheckList()?.length && reportType) {
      const all =
        await vsf?.services?.ClinicRecordOrderExamController_calculateCost_b96118?.(
          {
            eo: {
              clinicItemIdList: getCheckList()?.map((item) => item?.examItemId),
              reportType,
            },
          },
        );
      allPrice?.onChange?.(all.data);
    } else {
      allPrice?.onChange?.(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPrice, checkList, thirdList, reportType]);

  const onGet = (examSubClassIdIs) => {
    console.log(examSubClassIdIs, 'Drawer_examSubClassIdIs');
    setAssociatedId({
      examSubClassIdIs,
      examThirdClassIdIs: '',
    });
    getPart(examSubClassIdIs);
    getList({
      examSubClassIdIs,
    });
  };

  const handleChooseFirst = (examSubClassIdIs) => {
    onGet?.(examSubClassIdIs);
  };

  const handleChooseSecond = (data) => {
    setAssociatedId({
      examThirdClassIdIs: data?.id,
    });
    console.log(associatedId, 'Drawer_associatedId');
    console.log(data, 'Drawer_data');
    getList({
      ...associatedId,
      examThirdClassIdIs: data?.id,
    });
  };

  const getPart = useCallback(
    async (parentClassId) => {
      if (!parentClassId) return;
      const res =
        await vsf?.services?.ExamOrderController_getExamItemClassByParentClassId_09c596?.(
          { parentClassId },
        );
      // console.log(res, 'Drawer_res');
      setSecondList(res?.data);
      if (data?.keys?.[2]) {
        setAssociatedId({
          examThirdClassIdIs: data?.keys?.[2],
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const find = useCallback(() => {
    const text: any = getFirstList()?.find(
      (item: any) => item?.id === associatedId?.examSubClassIdIs,
    );
    return text;
  }, [associatedId?.examSubClassIdIs, getFirstList]);

  const onSubmit = useCallback(async () => {
    const isCheckList = () => {
      if (getCheckList()?.length) {
        return Promise?.resolve();
      } else {
        message.info('需要至少一项检查项目');
        return Promise?.reject('需要检查项目');
      }
    };
    try {
      const [one, two] = await Promise.all([
        vsf.refs.ExamForm?.validateFieldsReturnFormatValue(),
        vsf.refs.examBaseForm?.validateFieldsReturnFormatValue(),
        isCheckList(),
      ]);
      const name = getCheckList()
        ?.map((item) => item?.examItemName)
        .join(',');

      return {
        ...one,
        ...two,
        orderText: `[${name}]`,
        examOrderDetail: getCheckList()?.map((item, index) => {
          return {
            ...item,
            examItemNumber: index,
          };
        }),
        orderDiagnosisIdList: orderDiagnosisIdList?.value,
      };
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCheckList, find, orderDiagnosisIdList.value]);

  const onContinueToOpen = useCallback(async () => {
    try {
      const value = await onSubmit();
      restCheckList();
      restDetailInfo();
      vsf?.refs?.ExamForm?.resetFields();
      return value;
    } catch (error) {
      return Promise.reject(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmit, associatedId]);

  useImperativeHandle(ref, () => ({
    onSubmit,
    onContinueToOpen,
    onResetValue: () => {
      restCheckList();
      vsf?.refs.ExamForm?.resetFields();
    },
  }));

  useAsyncEffect(async () => {
    if (currentTab) {
      const res =
        await vsf?.services?.ExamOrderController_getExamItemClassByParentClassId_09c596?.(
          { parentClassId: currentTab },
        );
      if (res?.code === 200) {
        if (res?.data?.length) {
          setFirstList(res?.data);
          // console.log(res?.data, 'Drawer_res.data');
          if ([null, void 0]?.includes(data)) {
            // console.log(res?.data?.[0]?.id, 'Drawer.data[0].id');
            handleChooseFirst(res?.data?.[0]?.id);
          }
        } else {
          setFirstList([]);
          setSecondList([]);
          setThirdList([]);
        }
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (data) {
      if (data?.uuid) {
        const {
          examOrderDetail,
          performDepartment,
          radiationFilmOption,
          examReason,
          reportType,
          appointMethod,
          anesthesiaAssessmentIndicator,
          emergencyIndicator,
          anesthesiaIndicator,
          memo,
          ...rest
        } = data || {};
        vsf?.refs?.examBaseForm?.setFieldsValue(rest);
        vsf?.refs?.ExamForm?.setFieldsValue({
          performDepartment,
          radiationFilmOption,
          examReason,
          reportType,
          appointMethod,
          anesthesiaAssessmentIndicator,
          emergencyIndicator,
          anesthesiaIndicator,
          memo,
        });
        // console.log(
        //   data?.examOrderDetail?.[0]?.associatedId?.examSubClassIdIs,
        //   'data',
        // );
        PageFallDiagnosisDictionary({
          size: 9999,
        }).then((res) => {
          const value = res?.filter((item) =>
            data?.orderDiagnosisIdList?.includes(item?.id),
          );
          orderDiagnosisIdList?.onChange(value);
        });
        handleChooseFirst(
          data?.examOrderDetail?.[0]?.associatedId?.examSubClassIdIs,
        );
        onReset(examOrderDetail);
        setCheckList(examOrderDetail);
      } else {
        if (data?.examOrderDetail?.[0]) {
          handleChooseFirst(
            data?.examOrderDetail?.[0]?.associatedId?.examSubClassIdIs,
          );
          handleCheckItem(data?.examOrderDetail?.[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onReset = useCallback(async (list) => {
    if (!list?.length) {
      vsf?.refs?.ExamForm?.resetFields?.();
      restDetailInfo();
    } else {
      const res =
        await vsf?.services?.ExamOrderController_getExamItemInfo_3da8d6?.({
          examVSClinicIds: list?.map((item) => item?.examItemId),
        });
      if (res?.code === 200) {
        const currentDepartmentList =
          await vsf.services?.DepartmentDtoController_getDepartmentListByIds_8b28f0?.(
            {
              ids: res?.data?.performDepartmentList,
            },
          );
        setDetailInfo({
          ...res?.data,
          reportType: res?.data?.radiationFilmOption,
          performDepartment: res?.data?.performDepartmentList?.[0],
          performDepartmentList: currentDepartmentList?.data?.map((item) => ({
            ...item,
            departmentName:
              item?.departmentName +
              (item?.branchInstitution?.institutionName
                ? `(${item?.branchInstitution?.institutionName})`
                : ''),
          })),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useFrequencyEffect(
  //   () => {
  //     onReset();
  //   },
  //   2,
  //   [onReset],
  //   'before',
  // );

  const dataSource = [
    {
      title: '注意事项:',
      content: detailInfo?.notice,
    },
    {
      title: '作用:',
      content: detailInfo?.effect,
    },
    {
      title: '适用:',
      content: detailInfo?.indicationDisease,
    },
  ];

  // eslint-disable-next-line react/display-name
  const EmptyMemo: React.FC<{ description: string }> = memo((props) => {
    const { description } = props;
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 60 }}
        description={description}
      />
    );
  });

  const empty = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const selected = getCheckList()?.map((item) => item?.examItemId);

  const onChange = (...[, value]) => {
    handleCheckItem(value);
    setSearch(value);
  };

  const columns = [
    {
      title: '收费项目名称',
      dataIndex: ['priceItem', 'itemName'],
    },
    {
      title: '单价（元）',
      dataIndex: ['priceItem', 'price'],
    },
    {
      title: '单位',
      dataIndex: ['priceItem', 'unit'],
    },
    {
      title: '数量',
      dataIndex: 'amount',
    },
    {
      title: '金额',
      dataIndex: 'sum',
      render: (...[, record]) =>
        askFixed((record?.amount ?? 0) * (record?.priceItem?.price ?? 0), 2),
    },
  ];

  const get = () => {
    return new Promise((resolve) => {
      if (detailInfo?.emptyAnesthesiaAssessmentIndicator) {
        vsf?.refs?.ExamForm?.getFieldValue(
          'anesthesiaAssessmentIndicator',
          true,
        );
      }
      resolve(true);
    })?.then(() => {
      if (detailInfo?.anesthesiaIndicator) {
        vsf?.refs?.ExamForm?.setFieldValue('anesthesiaIndicator', true);
      } else {
        vsf?.refs?.ExamForm?.setFieldValue('anesthesiaIndicator', false);
      }
    });
  };

  useFrequencyEffect(
    () => {
      get();
    },
    2,
    [
      detailInfo?.emptyAnesthesiaAssessmentIndicator,
      detailInfo?.anesthesiaIndicator,
    ],
    'before',
  );

  useFrequencyEffect(
    () => {
      vsf?.refs?.ExamForm?.setFieldValue(
        'performDepartment',
        detailInfo?.performDepartment,
      );
      if (isTemplate) {
        vsf?.refs?.ExamForm?.setFieldValue(
          'reportType',
          detailInfo?.reportType,
        );
      }
    },
    1,
    [detailInfo?.performDepartment, detailInfo?.reportType, isTemplate],
    'before',
  );

  const onCollect = async (params) => {
    const res =
      await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
        {
          favoriteType: 'PERSONAL',
          itemClass: 'EXAM',
          itemId: params?.examItemId,
        },
      );
    if (res?.code === 200) {
      message?.success('收藏成功', 1)?.then(() => getList(associatedId));
    }
    return res?.data;
  };

  const onCancelCollect = async (params) => {
    const res =
      await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0?.(
        {
          itemClass: 'EXAM',
          itemId: params?.examItemId,
        },
      );
    if (res?.code === 200) {
      message?.success('取消收藏成功', 1)?.then(() => getList(associatedId));
    }
    return res?.data;
  };

  /**
   * 获取临床特殊数据源
   */
  const PageFallDiagnosisDictionary = useCallback(async (value) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          // 所选检查单类型的ID
          qto: {
            from: 0,
            size: 20,
            ...value,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      return res?.data?.result;
    }
  }, []);

  const PatientSpecialInfo = baseInfo?.epidemicClass?.map((item) => {
    const name = epidemicClassList?.find(
      (eItem) => eItem?.value === item?.epidemicClassCode,
    )?.label;
    return {
      infectClass: name,
    };
  });

  return (
    <div className="cpoe-exam">
      {!isTemplate && (
        // 可收起内容
        <div className="header">
          <Block
            title="相关诊断"
            className="left"
            extra={
              <div
                className="cpoe-exam-extra"
                style={{ flex: 1, marginLeft: 10 }}
              >
                <KitSelect
                  style={{ flex: 1 }}
                  showSearch
                  searchKey="searchInputLike"
                  dataSource={PageFallDiagnosisDictionary}
                  mode="multiple"
                  fieldNames={{
                    label: 'diagnosisName',
                    value: 'id',
                  }}
                  {...orderDiagnosisIdList}
                />
                <div
                  className="collapse-btn"
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                >
                  <span className="label">{collapse ? '展开' : '收起'}</span>
                  <Icon type={collapse ? 'icon-xia' : 'icon-shang'} size={20} />
                </div>
              </div>
            }
            headerStyle={{
              marginBottom: 0,
            }}
          >
            <VSForm
              style={{
                height: collapse ? 0 : 'auto',
                marginTop: 12,
              }}
              id="examBaseForm"
              labelAlign="left"
            >
              <VSFormLayout
                key="0"
                columnCount={2}
                // labelCol={labelColWidth[6]}
                // labelCol={labelColWidth[6]}
                labelWidth={labelNumberWidth(8)}
                gutter={50}
              >
                {/* <VSFormItem
                name={['diagnosisInfoList']}
                label="相关诊断"
                valueType="select"
                fieldProps={{}}
              /> */}

                <VSFormItem
                  name={['briefDiseaseHistoryPhysicalExam']}
                  label="简要病史"
                  valueType="textarea"
                  rules={[
                    {
                      message: '简要病史长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['vitalsign']}
                  label="体格检查"
                  valueType="textarea"
                  rules={[
                    {
                      message: '体格检查长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                {/* <VSFormItem
              name={['epidemiologicalHistory']}
              label="流行病学史"
              valueType="select"
              fieldProps={{}}
            /> */}
              </VSFormLayout>
              <VSFormLayout
                columnCount={1}
                // labelCol={labelColWidth[6]}
                labelWidth={labelNumberWidth(8)}
                gutter={70}
              >
                <VSFormItem
                  name={['relatedLabResult']}
                  label="相关检查结果"
                  valueType="textarea"
                  rules={[
                    {
                      message: '相关检查结果长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />
              </VSFormLayout>
              <VSFormLayout
                columnCount={4}
                // labelCol={labelColWidth[6]}
                labelWidth={labelNumberWidth(8)}
                gutter={30}
              >
                {(PatientSpecialInfo ?? [])?.map((item) => (
                  <VSFormItem
                    key={item?.infectClass}
                    name={['patientSpecialInfo', item?.infectClass]}
                    label={item?.infectClass}
                    valueType="custom"
                    fieldProps={{}}
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                  >
                    <Select
                      options={
                        vsf.stores.dbenums.enums.OPERATION_INFECTIOUS_STATUS
                      }
                      showSearch
                      placeholder={`请选择${item?.infectClass}`}
                      filterOption={askSearch}
                    />
                  </VSFormItem>
                ))}
              </VSFormLayout>
            </VSForm>
          </Block>
        </div>
      )}
      <div className="main">
        {/* 标头 */}
        <Block
          title="项目列表"
          className="left"
          style={{
            background: '#fff',
            padding: 0,
          }}
          extra={
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: 20,
              }}
            >
              <Select
                placeholder="请输入项目名称或首字母"
                showSearch
                value={search}
                filterOption={(value, option) =>
                  askSearch(value, option, 'examItemName')
                }
                style={{
                  flex: '0 0 50%',
                }}
                fieldNames={{
                  label: 'examItemName',
                  value: 'examItemId',
                }}
                onChange={onChange}
                options={thirdList?.filter(
                  (item) => !selected?.includes(item.examItemId),
                )}
              />
              <RadioGroup
                optionType="button"
                {...radio}
                dataSource={mock}
                style={{
                  marginLeft: '12px',
                }}
              />
            </div>
          }
        >
          {/* 项目列表 */}
          <div className="left-container">
            <div className="first" style={firstList?.length ? {} : empty}>
              {firstList?.length ? (
                firstList?.map((item: any, index) => {
                  // console.log(item, 'Drawer_firstList.item');
                  // console.log(item, 'firstList_item');
                  return (
                    <div
                      key={index}
                      className={classnames('item', {
                        'active-item':
                          associatedId?.examSubClassIdIs === item?.id,
                      })}
                      onClick={() => {
                        // console.log(item, 'firstList_item');
                        handleChooseFirst(item?.id);
                        restCheckList();
                        restDetailInfo();
                      }}
                    >
                      <span className="label">{item?.examClassName}</span>
                      <Icon type="icon-you" size={22} />
                    </div>
                  );
                })
              ) : (
                <EmptyMemo description="该类下没有子类" />
              )}
            </div>
            {/* 二层列表 */}
            {secondList?.length ? (
              <div className="second">
                {secondList?.map((item: any, index) => {
                  const ask = checkList?.filter(({ associatedId }, index) => {
                    if (associatedId?.examThirdClassIdIs) {
                      return associatedId?.examThirdClassIdIs === item?.id;
                    } else {
                      const id: any = detailInfo?.examItemClassList?.[index];
                      return id?.examThirdClassId === item?.id;
                    }
                  });
                  return (
                    <div
                      key={index}
                      className={classnames('item', {
                        'active-item':
                          associatedId?.examThirdClassIdIs === item?.id,
                      })}
                      onClick={() => {
                        handleChooseSecond(item);
                      }}
                    >
                      <span className="label">{item?.examClassName}</span>
                      {ask?.length ? (
                        <Badge count={ask?.length} showZero color="#69a1ff" />
                      ) : null}
                      {/* <Icon type="icon-you" size={22} /> */}
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div
              className="third"
              style={loading ? empty : thirdList?.length ? {} : empty}
            >
              <AsyncSuspense
                fallback={<Loading size="normal" />}
                loading={loading}
              >
                {thirdList?.length ? (
                  thirdList?.map((item: any) => {
                    const value =
                      checkList?.findIndex(
                        ({ examItemId }) => examItemId === item?.examItemId,
                      ) >= 0;
                    return (
                      <div
                        key={item?.id}
                        className="item"
                        onClick={() => handleCheckItem(item)}
                      >
                        <div className="left">
                          <Checkbox value={value} />
                          <span className="label">{item?.examItemName}</span>
                        </div>
                        <div
                          className="right"
                          onClick={(event) => event?.stopPropagation()}
                        >
                          {item?.doctorFavorite ? (
                            <Icon
                              size={24}
                              style={{ color: '#ffa77e' }}
                              type="icon-shoucang1"
                              onClick={(event) => {
                                event?.stopPropagation();
                                onCancelCollect(item);
                              }}
                            />
                          ) : (
                            <Icon
                              type="icon-meiyoushoucang"
                              size={24}
                              style={{ color: 'rgb(174, 176, 179)' }}
                              onClick={(event) => {
                                event?.stopPropagation();
                                onCollect(item);
                              }}
                            />
                          )}
                          <Popover
                            color="#fff"
                            style={{
                              width: 'auto',
                            }}
                            trigger="hover"
                            title="收费详情"
                            content={
                              <Table
                                pagination={false}
                                columns={columns}
                                dataSource={item?.clinicVsChargeDtoList}
                              />
                            }
                          >
                            <Icon type="icon-Frame-14" size={18} />
                          </Popover>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <EmptyMemo description="该检查分类没有检查项目" />
                )}
              </AsyncSuspense>
            </div>
          </div>
        </Block>
        {/* 检查项目 */}
        <div className="right">
          <div className="first">
            <Block title="检查项目">
              <div className="check-list">
                {checkList?.length ? (
                  checkList?.map((item) => {
                    // console.log(item, 'check-list_item');
                    // console.log(item, 'Drawer_check-list_item');
                    return (
                      <Tag
                        key={item?.id}
                        closable
                        color="processing"
                        onClose={(event) => {
                          event?.stopPropagation();
                          handleCheckItem(item);
                        }}
                      >
                        {item?.examItemName}
                      </Tag>
                    );
                  })
                ) : (
                  <EmptyMemo description="未选检查项目" />
                )}
              </div>
              <VSControlledForm
                className="info-form"
                labelAlign="left"
                id="ExamForm"
                form={form}
              >
                {!isTemplate && (
                  <VSFormLayout columnCount={3} columnSpan={[4, 4, 4]}>
                    <VSFormItem
                      label="加急"
                      name={['emergencyIndicator']}
                      valueType="check"
                    />
                    <VSFormItem
                      label="麻醉"
                      name={['anesthesiaIndicator']}
                      valueType="check"
                      fieldProps={{
                        disabled:
                          detailInfo?.modifyAnesthesiaIndicator === null
                            ? false
                            : !detailInfo?.modifyAnesthesiaIndicator,
                      }}
                    />
                    <VSFormItem
                      label="麻醉评估"
                      name={['anesthesiaAssessmentIndicator']}
                      valueType="check"
                    />
                  </VSFormLayout>
                )}
                {!isTemplate ? (
                  // <VSFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                  <VSFormLayout
                    columnCount={1}
                    labelWidth={labelNumberWidth(7)}
                  >
                    {
                      <VSFormItem
                        name={['reportType']}
                        label="报告类型"
                        valueType="custom"
                        rules={[{ required: true }]}
                        fieldProps={{}}
                      >
                        <Select
                          // options={[
                          //   { label: '图文', value: 'IMAGE' },
                          //   { label: '胶片', value: 'RADIATION_FILM' },
                          //   { label: '云影像', value: 'CLOUD_IMAGE' },
                          // ]}
                          options={vsf.stores.dbenums.enums.RAD_FILM_OPTION?.filter(
                            (item) =>
                              (
                                detailInfo?.radiationFilmOptionList as any[]
                              )?.includes(item?.value),
                          )}
                          filterOption={askSearch}
                          placeholder="请选择报告类型"
                          showSearch
                        />
                      </VSFormItem>
                    }

                    <VSFormItem
                      name={['examReason']}
                      label="检查目的"
                      valueType="custom"
                      rules={[{ required: true }]}
                    >
                      <Select
                        options={vsf?.stores?.dbenums?.enums?.EXAM_CAUSE_DICT?.filter(
                          (item) => {
                            const examPurposeList = detailInfo?.examPurposeList;
                            if (examPurposeList) {
                              return examPurposeList?.includes(item?.value);
                            } else {
                              return true;
                            }
                          },
                        )}
                        filterOption={askSearch}
                        placeholder="请选择检查目的"
                        showSearch
                      />
                    </VSFormItem>

                    <VSFormItem
                      name={['performDepartment']}
                      label="执行科室"
                      valueType="custom"
                      fieldProps={{}}
                      rules={[{ required: true }]}
                      transform={(value) => {
                        const departmentName =
                          detailInfo?.performDepartmentList?.find(
                            (item) => item?.id === value,
                          );
                        return {
                          performDepartmentId: value,
                          performDepartment: {
                            departmentName: departmentName?.departmentName,
                            id: value,
                          },
                        };
                      }}
                    >
                      <Select
                        options={detailInfo?.performDepartmentList}
                        placeholder="请选择执行科室"
                        fieldNames={{
                          label: 'departmentName',
                          value: 'id',
                        }}
                        filterOption={(...arr) =>
                          askSearch(...arr, 'departmentName')
                        }
                        showSearch
                      />
                    </VSFormItem>
                  </VSFormLayout>
                ) : (
                  <VSFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                    <VSFormItem
                      name={['performDepartment']}
                      label="执行科室"
                      valueType="custom"
                      fieldProps={{}}
                      rules={[{ required: true }]}
                      transform={(value) => {
                        const departmentName =
                          detailInfo?.performDepartmentList?.find(
                            (item) => item?.id === value,
                          );
                        return {
                          performDepartmentId: value,
                          performDepartment: {
                            departmentName: departmentName?.departmentName,
                            id: value,
                          },
                        };
                      }}
                    >
                      <Select
                        options={detailInfo?.performDepartmentList}
                        placeholder="请选择执行科室"
                        fieldNames={{
                          label: 'departmentName',
                          value: 'id',
                        }}
                        filterOption={(...arr) =>
                          askSearch(...arr, 'departmentName')
                        }
                        showSearch
                      />
                    </VSFormItem>
                  </VSFormLayout>
                )}
                <Determine condition={hospitalType === 'in'}>
                  <VSFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                    <VSFormItem
                      valueType="custom"
                      name={['appointMethod']}
                      label="运送方式"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="请选择运送方式"
                        showSearch
                        options={
                          vsf?.stores?.dbenums?.enums?.TRANSFER_METHOD_DICT
                        }
                      />
                    </VSFormItem>
                    <VSFormItem
                      valueType="textarea"
                      name={['memo']}
                      label="备注"
                    />
                  </VSFormLayout>
                </Determine>
              </VSControlledForm>
              {!isTemplate && (
                <List
                  size="small"
                  bordered
                  dataSource={dataSource}
                  renderItem={(item) => (
                    <List.Item>
                      {item?.title}
                      {item?.content}
                    </List.Item>
                  )}
                />
              )}
            </Block>
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = React.forwardRef(Main);

const Index = (props, ref) => {
  const { onSubmit, hospitalType, isTemplate = false } = props;
  const [currentTab, setCurrentTab, restCurrentTab] = useResetState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [baseInfo, setBaseInfo] = useState<any>();
  const [defaultValue, setDefaultValue, restDefaultValue] = useResetState<any>(
    {},
  );

  const [allPrice] = useOnChangeValue(0);

  // 标头
  const [tabList, setTabList] = useGetState<any[]>([]);

  const onChange = (v, record) => {
    // console.log(v, 'Drawer_v');
    submit?.current?.onResetValue?.();
    setCurrentTab(v);
  };

  const onClose = () => {
    setOpen(false);
    restCurrentTab();
    restDefaultValue();
  };
  const onOpen = (v) => {
    setOpen(true);
    setDefaultValue(v ?? undefined);
  };

  type VALUE = {
    performDepartment: any;
    orderText: string;
    orderDiagnosisIdList: number[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    patientSpecialInfo: any;
    examOrderDetail: Array<{
      examItemName: any;
      clinicItemName: string;
    }>;
    memo: string;
  };

  type Method = {
    onSubmit?: () => Promise<VALUE>;
    onContinueToOpen?: () => Promise<VALUE>;
    onResetValue?: () => void;
  };

  const submit = useRef<Method>({});
  const count = useRef(false);
  const handleSubmit = async () => {
    if (submit?.current?.onSubmit) {
      const {
        orderText,
        orderDiagnosisIdList = [],
        patientSpecialInfo = [],
        ...value
      } = await submit?.current?.onSubmit();
      const text =
        tabList?.find((item) => item?.value === currentTab)?.label ?? '';

      const state = {
        orderText: text + orderText,
        uuid: defaultValue?.uuid ?? getUUID(),
        orderClass: 'EXAM',
        orderStatus: defaultValue?.orderStatus ?? 'OPEN',
        performDepartment: value?.performDepartment,
        submitDateTime: dayjs().valueOf(),
        orderDiagnosisIdList: orderDiagnosisIdList?.map(
          (item: any) => item?.id,
        ),
        patientSpecialInfo,
        outpVisitEncounterId: vsf.stores?.user?.outVisitInfo?.id,
        allPrice: allPrice?.value,
        memo: value?.memo,
        examOrder: {
          ...defaultValue,
          ...value,
        },
      };
      if (count.current) {
        resolve(state);
        onClose();
      } else {
        onSubmit?.(state);
        onClose();
      }
    }
  };

  const { resolve, promise } = useWithResolvers();

  useImperativeHandle(ref, () => ({
    onOpen,
    onAsyncOpen: (value) => {
      onOpen?.(value);
      count.current = true;
      return promise.then((res) => {
        count.current = false;
        return res;
      });
    },
  }));

  //
  const initTabList = async () => {
    const res =
      await vsf.services?.ExamOrderController_getExamBaseInfo_4de915?.({
        // 接诊? 挂号?
        outpVisitId: 101,
      });
    if (res?.code === 200) {
      const { examFirstClassList, ...rest } = res?.data;
      const target = (res?.data?.examFirstClassList ?? [])
        ?.filter((item) => item?.examClassName !== '病理')
        ?.map((item) => ({
          label: item?.examClassName,
          key: item?.examClassId,
          value: item?.examClassId,
          origin: item,
        }));
      setBaseInfo(rest);
      setTabList(target);
    }
  };

  useEffect(() => {
    initTabList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueToOpen = async () => {
    if (submit?.current?.onContinueToOpen) {
      const {
        orderText,
        orderDiagnosisIdList = [],
        ...value
      } = await submit?.current?.onContinueToOpen();
      const text =
        tabList?.find((item) => item?.value === currentTab)?.label ?? '';
      /**
       * 编辑第一条修改原本，之后新开医嘱
       */
      if (defaultValue?.uuid) {
        onSubmit({
          orderText: text + orderText,
          uuid: defaultValue?.uuid,
          orderClass: 'EXAM',
          orderStatus: defaultValue?.orderStatus ?? 'OPEN',
          performDepartment: value?.performDepartment,
          allPrice: allPrice?.value,
          memo: value?.memo,
          orderDiagnosisIdList,
          examOrder: {
            ...value,
          },
        });
        setDefaultValue(void 0);
      } else {
        onSubmit({
          orderText: text + orderText,
          uuid: getUUID(),
          orderClass: 'EXAM',
          orderStatus: 'OPEN',
          performDepartment: value?.performDepartment,
          allPrice: allPrice?.value,
          memo: value?.memo,
          orderDiagnosisIdList,
          examOrder: {
            ...value,
          },
        });
      }
    }
  };

  // 获取侧边栏title_tabs列表
  useAsyncEffect(async () => {
    if (defaultValue?.examOrderDetail?.length) {
      const [examVSClinicIds] = defaultValue?.examOrderDetail;
      const res =
        await vsf?.services?.ExamOrderController_getExamItemInfo_3da8d6?.({
          examVSClinicIds: [examVSClinicIds?.examItemId],
        });
      if (res?.code === 200) {
        setCurrentTab(res?.data?.examItemClassList?.[0]?.examClassId);
      }
    } else {
      setCurrentTab(tabList?.[0]?.value);
    }
  }, [defaultValue]);

  const Footer = () => {
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div className="info">
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥ {allPrice?.value}元</span>
        </div>
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button type="primary" onClick={onContinueToOpen} ghost>
          继续开立
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          确定
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      className="aspirin-drawer-border-bottom"
      title={
        <TabCheck value={currentTab} onChange={onChange} dataSource={tabList} />
      }
      placement="right"
      onClose={onClose}
      open={open}
      width={'70%'}
      destroyOnClose
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      footer={<Footer />}
    >
      {/* <Screen> */}
      <Content
        baseInfo={baseInfo}
        data={defaultValue}
        open={open}
        currentTab={currentTab}
        onSubmit={onSubmit}
        onClose={onClose}
        ref={submit}
        hospitalType={hospitalType}
        allPrice={allPrice}
        isTemplate={isTemplate}
      />
      {/* </Screen> */}
    </Drawer>
  );
};

export default React.forwardRef(Index);
