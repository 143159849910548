import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserStaffController_getStaffSimpleVoById_861e54: {
    method: 'post',
    url: '/api/user-staff/get-staff-simple-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: StaffSimpleVoUserStaffEntranceWebVo) =>
      data,
  },
  ClinicRecordOrderPerformController_orderSheetOverView_61cb03: {
    method: 'post',
    url: '/api/clinic-record-order-perform/order-sheet-over-view',
    parameterFomatter: (data?: {
      eo: QueryOrderSheetOverviewEoClinicRecordOrderPerformPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderWithPerformVoClinicRecordOrderPerformEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderPerformController_cancelSignature_171e54: {
    method: 'post',
    url: '/api/clinic-record-order-perform/cancel-signature',
    parameterFomatter: (data?: {
      eo: CancelPerformSignatureEoClinicRecordOrderPerformPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderVoClinicRecordOrderPerformEntranceWebVoOrderwithperformvo,
    ) => data,
  },
  ClinicRecordOrderPerformController_saveOrderSheetOverView_9dd8c7: {
    method: 'post',
    url: '/api/clinic-record-order-perform/save-order-sheet-over-view',
    parameterFomatter: (data?: {
      eoList: SaveOrderSheetOverviewEoClinicRecordOrderPerformPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderVoClinicRecordOrderPerformEntranceWebVoOrderwithperformvo[],
    ) => data,
  },
  AccountController_staffLoginWithoutResponseCookies_5fd9d3: {
    method: 'post',
    url: '/api/user-account/staff-login-without-cookies',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
