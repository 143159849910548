import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_getPriceItemListByDrugProductId_a88fc3: {
    method: 'post',
    url: '/api/drug-drug/get-price-item-list-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (_, __, data?: DrugPriceItemVoDrugDrugEntranceWebVo[]) =>
      data,
  },
});
