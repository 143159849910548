import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAction,
  message,
  Modal,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NewDrawer } from '@/pages/Index/components';

const DrugApplyMasterList = ({ onSelectChange }) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [activityBill, setActivityBill] = useState();
  const onceFetched = useRef(false);

  useEffect(() => {
    if (onceFetched.current && openDrawer) {
      vsf.refs?.tableDrugMasters?.reload();
    }
  }, [openDrawer]);

  const onFetch = useCallback(async (params) => {
    onceFetched.current = true;
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugApplyController_getCreatedApplyMasters_95e39c?.({
        // TODO: 请检查以下这部分传参是否符合需求
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    // TODO: 你需要自己补充入参input来源及出参output用法
    const output = convertGetCreatedApplyMastersRes2TableDrugMasters(res);
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  /**
   * convertGetCreatedApplyMastersRes2TableDrugMasters
   * @param {any} input
   * @returns {any}
   */
  const convertGetCreatedApplyMastersRes2TableDrugMasters = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ..._.get(input, 'data').map((d) => {
        const obj = {};
        obj.applyNumber = _.get(d, 'applyNumber');
        obj.applyType = _.get(d, 'applyType');
        obj.planDate = _.get(d, 'planDate');
        obj.storageName = _.get(d, 'provideStorageInfo.storageName');
        obj.storageId = _.get(d, 'provideStorageInfo.id');
        obj.id = _.get(d, 'id');
        obj.memo = _.get(d, 'memo');
        return obj;
      }),
    );
    return output;
  };

  const handelReload = async () => {
    vsf.refs?.tableDrugMasters?.reload();
  };

  const handelHide = () => {
    setOpenDrawer(false);
  };

  const handelRemove = async () => {
    if (!activityBill) return message.success('请选择申请单');
    Modal.confirm({
      title: '提示',
      content: '是否删除当前申请单？',
      okText: '是',
      cancelText: '否',
      onOk: async () => {
        // Tips: 函数入参结构和组件RO一致
        // Tips: 以下是你选择的请求函数
        const res =
          await vsf.services?.DrugApplyController_deleteDrugApplyMaster_d47f7f?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              btoParam: { id: activityBill?.id },
            },
          ); // Tips: 需要返回删除成功与否
        if (res.code === 200) {
          message.success('删除成功');
          handelReload();
        }
      },
    });
  };

  const handelDoubleClick = async (record) => {
    onSelectChange?.(activityBill);
    handelHide();
  };

  return (
    <NewDrawer
      open={openDrawer}
      width="45%"
      onOpen={() => setOpenDrawer(!openDrawer)}
      title={
        <div style={{ color: '#3276E8', fontWeight: 'bold' }}>未提交申请单</div>
      }
      buttonTitle="请领单据"
      backgroundColor="var(--blue)"
      footer={
        <div className="aspirin-table-footer-button">
          <Button type="primary" onClick={handelReload}>
            刷新
          </Button>
          <Button type="primary" onClick={handelHide}>
            隐藏
          </Button>
          <Button type="primary" danger onClick={handelRemove}>
            删除
          </Button>
        </div>
      }
      drift="98px"
      style={{
        top: 112,
      }}
      drawerClassName="drug_apply_wrapper_drawer"
    >
      <VSPromiseTable
        scroll={{
          y: 700,
        }}
        className="aspirin-table aspirin-table-nopadding"
        editable={{
          editType: 'single',
          deleteText: '',
          onCanEdit: () => false,
          columnProps: {
            hideInTable: true,
          },
        }}
        vsid="99009"
        id="tableDrugMasters"
        onFetch={onFetch}
        pagination={false}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
        }}
        onRow={(record) => {
          return {
            onClick: () => setActivityBill(record),
            onDoubleClick: () => handelDoubleClick(record),
          };
        }}
        rowClassName={(record) => {
          return record?.id === activityBill?.id
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box  aspirin-row-select-box'
            : 'aspirin-vs-table-row-box aspirin-row-select-box';
        }}
      >
        <VSTableColumn
          width={172}
          dataIndex={['applyNumber']}
          title="请领单号"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={126}
          dataIndex={['applyType']}
          title="请领单类型"
          editable={false}
          valueType="select"
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_IMPEXP_CLASS_DICT}
        />

        <VSTableColumn
          width={160}
          dataIndex={['planDate']}
          title="生成日期"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          width={150}
          dataIndex={['storageName']}
          title="上级库房"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};
export default definePage(DrugApplyMasterList);
