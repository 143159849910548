import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Todo } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import { TreeMenu } from '@/components';
import { getPinYinCode } from 'lancet-pywb/PY_WB';
import Icon from '@/module/Icon';
const StandardApplicationTree = (props) => {
  const { onAdd, onDel, onEdit } = props;

  return (
    <>
      <TreeMenu
        id="StandardApplicationTree"
        title="应用程序列表"
        reload
        loadData={[
          async () => {
            const res =
              await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
                {
                  codeType: '000400',
                },
              );
            return res;
          },
          async () => {
            const res =
              await vsf?.services?.ApplicationDtoController_getApplication_fbf818(
                {
                  template: true,
                },
              );
            return {
              ...res,
              data: res?.data
                ?.map?.((element) => ({
                  PinYinShouCode: getPinYinCode(element?.name)?.substring(0, 1),
                  ...element,
                }))
                ?.sort((a, b) =>
                  a?.PinYinShouCode?.toLowerCase().localeCompare(
                    b?.PinYinShouCode?.toLowerCase(),
                  ),
                ),
            };
          },
        ]}
        fieldNames={{ title: 'name', key: 'id', children: 'children' }}
        dataRelationFieldNames={{
          id: ['code', 'id'],
          parentId: 'applicationClass',
        }}
        search={{
          status: true,
        }}
        row={{
          expand: true,
          extra: (node) => {
            return node?.applicationClass ? (
              <>
                <Icon
                  type="icon-bianji"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit?.(node);
                  }}
                  size={24}
                />
                <Icon
                  style={{
                    marginLeft: '4px',
                  }}
                  type="icon-shanchu1"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDel?.(node);
                  }}
                  size={24}
                />
              </>
            ) : (
              <>
                <Icon
                  type="icon-tianjia"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAdd?.(node);
                  }}
                  size={24}
                />
              </>
            );
          },
          extraShow: 'hover',
        }}
        onSelect={(_value) => {
          props.onChange?.(_value?.applicationClass ? _value : undefined);
        }}
      />
    </>
  );
};
export default definePage(StandardApplicationTree);
