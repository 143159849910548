import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_createErpPatient_d16e18: {
    method: 'post',
    url: '/api/patient-main/create-erp-patient',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdatePatientBtoPatientMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PatientFullVoPatientMainEntranceWebVo) =>
      data,
  },
});
