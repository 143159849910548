import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { askFixed } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';

import HerbStorageDurgSelect from './components/herb_storage_durg_select';
import { getPlanDetailByPlanMasterId } from './service';
const Index = forwardRef((props: any, ref: any) => {
  const { orderProps, herbType } = props;
  const table = useRef<any>(null);
  useImperativeHandle(ref, () => {
    return {
      table: table?.current,
    };
  });
  const onFetch = useCallback(
    async (params) => {
      if (!orderProps?.id)
        return {
          data: [],
        };
      const res = await getPlanDetailByPlanMasterId({
        ...params,
        value: {
          ...orderProps,
        },
      });
      return res;
    },
    [orderProps],
  );
  const onChangeValue = useCallback(async () => {
    await vsf?.refs?.tableHerbPurchasePlanDetailList?.getValues();
    await props?.onChangeTotal();
  }, [props]);
  return (
    <VSPromiseTable
      ref={table}
      editable={{
        editType: 'multiple',
        onCanDelete: () => false,
        extraOperations: [
          {
            children: '删除',
            onClick: async (_, node) => {
              if (['save']?.includes?.(node?.type)) {
                Tip({
                  content: `确认删除药品 ${node?.drugProductName} ?`,
                  onOk: async () => {
                    await table?.current?.remove?.(node);
                    await onChangeValue?.();
                  },
                });
                return;
              }
              await table?.current?.remove?.(node);
              await onChangeValue?.();
            },
          },
        ],
        onFieldChange: async (key, value, record, form) => {
          await onChangeValue?.();
        },
      }}
      className="aspirin-table-no-background-box aspirin-table aspirin-table-search-padding-no"
      id="tableHerbPurchasePlanDetailList"
      onRecord={async () => {
        return {
          id: `TEMP_${Math?.random?.()}`,
          type: 'new',
          drugProductName: '',
          drugItemId: '',
        };
      }}
      onFetch={onFetch}
      pagination={false}
      scroll={{
        y: 'auto',
      }}
      rowClassName={(node) => {
        if (!node?.drugItemId)
          return 'aspirin-vs-table-row-box aspirin-row-select-box';
        return node?.drugItemId === props.drugItemId
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
          : 'aspirin-vs-table-row-box aspirin-row-select-box';
      }}
    >
      <VSTableColumn
        width={'20rem'}
        dataIndex={['drugProductName']}
        title="商品名称"
        valueType="custom"
        formItemProps={{
          rules: [
            {
              required: true,
              message: '必填',
              type: 'string',
            },
          ],
        }}
        fieldProps={{}}
        renderFormItem={(_, { record }, form) => {
          const { id, amount, ...rest } = record as { id; amount };
          return (
            <HerbStorageDurgSelect
              {...{
                placeholder: '请选择药品名称',
                herbType,
                allowClear: true,
                getChangeParams: async (params) => {
                  const formValue = {
                    [id]: {
                      ...params,
                      amount: amount ?? 1,
                    },
                  };
                  const updateValue = {
                    ...rest,
                    ...params,
                    id: id,
                    amount: amount ?? 1,
                  };
                  await form?.setFieldsValue(formValue);
                  await table?.current?.update(updateValue);
                  await onChangeValue?.();
                },
              }}
            />
          );
        }}
        ellipsis
      />

      <VSTableColumn
        width="6rem"
        dataIndex={['packageSpecification']}
        title="包装规格"
        // editable={false}
        valueType="text"
        formItemProps={{
          rules: [{ message: '数据格式错误', type: 'string', min: 0, max: 50 }],
        }}
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="16rem"
        dataIndex={['firmName']}
        title="厂家"
        // editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 100 },
          ],
        }}
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="6rem"
        dataIndex={['totalAmount']}
        title="全院库存"
        valueType="digit"
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="6rem"
        dataIndex={['storageAmount']}
        title="库存量"
        valueType="digit"
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="6rem"
        dataIndex={['consumeAmount']}
        title="消耗量"
        // editable={false}
        valueType="digit"
        formItemProps={{
          rules: [],
        }}
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="10rem"
        dataIndex={['amount']}
        title="采购数量"
        valueType="digit"
        formItemProps={{
          rules: [
            {
              required: true,
              message: '必填',
              type: 'number',
            },
          ],
        }}
        fieldProps={{
          min: 1,
        }}
      />

      <VSTableColumn
        width="6rem"
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        preview
        ellipsis
        renderFormItem={(_, { record }) => askFixed(record?.price, 2)}
      />

      <VSTableColumn
        dataSource={vsf?.stores?.dbenums?.enums?.MEASURES_DICT}
        width="6rem"
        dataIndex={['packageUnit']}
        title="包装单位"
        // editable={false}
        valueType="select"
        formItemProps={{
          rules: [],
        }}
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        width="5rem"
        dataIndex={['lowLevel']}
        title="下限"
        // editable={false}
        valueType="digit"
        fieldProps={{}}
        preview
        ellipsis
      />

      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '最大支持10个字符',
              type: 'string',
              min: 0,
              max: 10,
            },
          ],
        }}
        fieldProps={{
          placeholder: '最大支持10个字符',
        }}
      />
    </VSPromiseTable>
  );
});

Index.displayName = 'Table';
export default memo(Index);
