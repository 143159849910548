/* eslint-disable prettier/prettier */
import './index.less';

import {
  Button,
  compose,
  Image,
  message,
  Upload,
  UploadFile,
  UploadProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { concat } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { portalize } from './portal';

export type UploadOssParams = {
  /** 自定义类型字段 */
  dir: string;
  expire: string;
  host: string;
  accessKeyId: string;
  policy: string;
  signature: string;
};

export type FileUploadValue = UploadFile & {
  /** 自定义类型字段 */
  url?: string;
  name?: string;
};

export type getOssPolicyType = () => Promise<UploadOssParams>;

export type FileUploadProps = {
  /**
   * 默认值
   */
  defaultValue?: FileUploadValue[];
  /**
   * 值
   */
  value?: FileUploadValue[];
  /**
   * 值变化回调
   */
  onChange?: (value?: FileUploadValue[]) => void;
  /**
   * 上传参数
   */
  ossParams?: UploadOssParams;
  /**
   *  获取上传参数服务
   */
  getOssPolicy?: getOssPolicyType;
  /**
   * 上传文件类型
   */
  type: 'picture' | 'file';
  /**
   * upload属性
   */
  uploadProps?: UploadProps;
  /**
   * 自定义上传内容
   */
  children?: React.ReactNode;
};

const aTagDownload = (url, filename) => {
  const a = document.createElement('a'); // 创建 a 标签
  a.href = url; // 下载路径
  a.download = filename; // 下载属性，文件名
  a.style.display = 'none'; // 不可见
  document.body.appendChild(a); // 挂载
  a.click(); // 触发点击事件
  document.body.removeChild(a); // 移除
};

const download = (path, fileName) => {
  fetch(path)
    .then((res) => res.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
      aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
    })
    .catch((err) => {
      console.error();
    });
};

/**
 * 文件上传(阿司匹林)
 */
const FileUpload = (props: FileUploadProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    ossParams,
    uploadProps,
    type,
    getOssPolicy,
    children,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const [OSSData, setOSSData] = useState<UploadOssParams>();
  const portalRef = useRef<any>();
  const loadOssPolicy = useCallback(async () => {
    try {
      const _data = await getOssPolicy?.();
      setOSSData(_data);
      return _data;
    } catch (err) {
      message.error(`获取ossParams错误: ${err}`);
      console.error();
    }
  }, [getOssPolicy]);

  useEffect(() => {
    if (getOssPolicy) {
      loadOssPolicy();
    } else {
      setOSSData(ossParams);
    }
  }, [getOssPolicy, loadOssPolicy, ossParams]);

  const handleChange = async ({ fileList }) => {
    const reg = /(http|https):\/\/\S*/;
    const _value = fileList.map((item) => {
      return {
        ...item,
        url: reg.test(item.url) ? item.url : `${OSSData?.host}/${item.url}`,
      };
    });
    setValue([..._value]);
  };

  const onRemove = (file: UploadFile) => {
    const files = (value || []).filter((v) => v.url !== file.url);
    setValue([...files]);
  };

  const getExtraData = async (file) => {
    return {
      key: file.url,
      OSSAccessKeyId: OSSData?.accessKeyId,
      policy: OSSData?.policy,
      Signature: OSSData?.signature,
    };
  };

  const beforeUpload = async (file) => {
    if (!OSSData) {
      return;
    }
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = OSSData.dir + filename;
    return file;
  };

  const handlePreviewClose = (visible) => {
    if (!visible) {
      portalRef?.current?.close();
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (type === 'picture') {
      portalRef.current = portalize({
        component: (
          <Image
            style={{
              opacity: 0,
            }}
            width={200}
            src={file?.url || (file.preview as string)}
            preview={{
              visible: true,
              onVisibleChange: handlePreviewClose,
            }}
          />
        ),
      });
    } else if (type === 'file') {
      download(`${file.url}`, file.name);
    }
  };

  // 上传参数
  const uploadParams: UploadProps = {
    name: 'file',
    fileList: value,
    defaultFileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
    listType: type === 'picture' ? 'picture-card' : 'text',
    onPreview: handlePreview,
    ...uploadProps,
  };
  /** 编写组件逻辑 */
  return (
    <>
      {/* 这里必须把rest传递给根节点，不要删除 */}
      <div {...rest} className="aspirin-file-upload">
        {/* <>展示值：</> */}
        {/* @ts-ignore */}
        <Upload {...uploadParams}>
          {children ? (
            children
          ) : (
            <>
              {type && type === 'picture' ? (
                <div>点击上传</div>
              ) : (
                // @ts-ignore
                <Button>点击上传</Button>
              )}
            </>
          )}
        </Upload>
      </div>
    </>
  );
};

FileUpload.displayName = 'FileUpload';
FileUpload.download = download;

export default compose(
  withField<FileUploadValue>({
    name: 'FileUpload',
    shouldFieldUpdate: undefined,
  }),
  withPreview<FileUploadProps>({
    renderPreview: (props) => {
      const { value, type } = props;
      const aTagDownload = (url, filename) => {
        const a = document.createElement('a'); // 创建 a 标签
        a.href = url; // 下载路径
        a.download = filename; // 下载属性，文件名
        a.style.display = 'none'; // 不可见
        document.body.appendChild(a); // 挂载
        a.click(); // 触发点击事件
        document.body.removeChild(a); // 移除
      };

      const download = (path, fileName) => {
        fetch(path)
          .then((res) => res.blob())
          .then((blob) => {
            const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
            aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
          })
          .catch((err) => {
            console.error();
          });
      };
      /** 返回预览模式下的dom */
      return (
        <>
          {/* 预览值： */}
          {value?.map((item) => (
            <div key={item.url}>
              {type === 'picture' && (
                <>
                  <Image width={100} src={item.url || ''} />
                  <div>{item?.name}</div>
                </>
              )}
              {type === 'file' && (
                <a
                  onClick={() => {
                    download(item.url, item?.name);
                  }}
                  href={item.url}
                >
                  {item?.name}
                </a>
              )}
            </div>
          ))}
        </>
      );
    },
  }),
)(FileUpload);
