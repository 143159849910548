import {
  Section,
  Column,
  Columns,
  VSTable,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  Button,
  message,
} from '@vs/vsf-kit';
// import {
//   askDtoToBto,
//   askRomanAlphabet,
// } from '@/pages/Index/components/func/ask';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getScrollY } from '@/utils';
import { buildTree, appendTreeLevel } from '@/components/TreeMenu/utils';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import DepartmentEdit from './edit';
import { isMinScreen } from '@/config';

const basicFirstItemWidth = 300;
const expandIconWidth = 35;

const DepartmentTable = (props) => {
  const { value } = props;
  const listData = useRef();

  const [tableList, setTableList] = useState([]);
  const [selectedRows, setSelectedRows] = useState();

  useEffect(() => {
    setSelectedRows('');
  }, [value]);

  /**
   * departmentId
   * @type {number}
   */
  const onFetch = useCallback(async (params) => {
    const _params = params.extra?.upInstitutionId
      ? {
          institutionIdIs: params.extra?.upInstitutionId,
          branchInstitutionIdIs: params.extra.id,
        }
      : {
          institutionIdIs: params.extra.id,
        };
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentQto_1c2a91?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            // size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ..._params,
          },
        },
      );

    const _data = appendTreeLevel(
      buildTree(res?.data?.result ?? res?.data ?? [], {
        id: 'departmentCode',
        parentId: 'upDepartmentCode',
      }),
    );
    listData.current = _data;
    setTableList(res?.data?.result ?? res?.data ?? []);
    return {
      data: _data,
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const expandedRowRender = (record) => {
    const newWidth =
      basicFirstItemWidth - (record?.treeLevel + 1) * expandIconWidth;
    const dataSource = record?.children ?? [];

    return (
      <VSTable
        id="departmentTableRowExpandable"
        // bordered
        // className="aspirin-table aspirin-child-table aspirin-table-nopadding"
        // rowClassName={(v) => (v.id === selectedRows ? 'aspirin-table-bgc' : '')}
        //   className="
        //   aspirin-table-header-background-color
        //  aspirin-table-row-hover-background-color
        //  aspirin-table-body-empty-transparent-background-color
        //  aspirin-child-table
        //  departmentTableRowExpandable
        //  "
        className={cn(
          'aspirin-table-header-background-color aspirin-table-row-hover-background-coloraspirin-table-body-empty-transparent-background-color aspirin-child-table  departmentTableRowExpandable',
          isMinScreen
            ? 'aspirin-child-table-small-size'
            : 'aspirin-child-table',
        )}
        rowClassName={(v) => {
          return v.id == selectedRows
            ? 'aspirin-table-row-select-background-color'
            : '';
        }}
        value={dataSource}
        pagination={false}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          columnProps: {
            fixed: 'none',
            width: 100,
          },
        }}
        onUpdate={async (params) => {
          const res =
            await vsf?.services?.DepartmentBOController_updateDepartment_da3dee?.(
              { btoParam: { ...(params ?? {}) }, extApiParam: {} },
            );
          const currentItem = listData.current?.find(
            (item) => item?.id === params?.id,
          );
          // vsf.refs?.departmentTable.reload();
          // vsf.refs?.departmentTableRowExpandable.reload();
          return {
            ...currentItem,
            ...res?.data,
            children: currentItem?.children,
          };
        }}
        updateLinkPage={{
          linkPage: () => <DepartmentEdit title="update" />,
          modalProps: {
            title: `编辑科室${title}`,
            className: 'aspirin-linkpage',
            width: 1300,
            bodyStyle: {
              height: 500,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            footerRender: (
              dom,
              { okButtonProps, cancelButtonProps },
              value,
              row,
            ) => {
              return (
                <>
                  <Button
                    size="large"
                    className="icon16"
                    style={{
                      color: '#5D6268',
                      backgroundColor: '#f0f0f0',
                      border: 'none',
                      display: 'flex',
                    }}
                    {...cancelButtonProps}
                  >
                    取消
                  </Button>
                  <Button
                    onClick={async () => {
                      Tip({
                        content: `是否删除：${row?.departmentName}`,
                        onOk: async () => {
                          const res =
                            await vsf?.services?.DepartmentBOController_deleteDepartment_f91e76?.(
                              {
                                btoParam: { ...(row ?? {}) },
                                extApiParam: {},
                              },
                            );
                          if (res.code === 200) {
                            vsf.refs.departmentTable.reload();
                            message.open({
                              type: 'success',
                              content: `删除成功`,
                            });
                            cancelButtonProps.onClick();
                          }
                          return res?.data;
                        },
                        cancelText: '取消',
                      });
                    }}
                    size="large"
                    className="icon16"
                    style={{
                      color: 'red',
                      backgroundColor: '#f0f0f0',
                      border: 'none',
                    }}
                  >
                    删除
                  </Button>
                  <Button {...okButtonProps} size="large" className="icon16">
                    保存
                  </Button>
                </>
              );
            },
          },
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              return false;
            }
          },
        }}
        onChange={async (v, change) => {
          if (change.operation === 'remove') {
            const res =
              await vsf?.services?.DepartmentBOController_deleteDepartment_f91e76?.(
                {
                  btoParam: { ...(change?.data ?? {}) },
                  extApiParam: {},
                },
              );
            if (res?.code == 200) {
              message.success('删除成功');
            }
          }
        }}
        showHeader={false}
        rowClick={{
          rowClickType: 'single',
          onRowClick: (e) => {
            if (selectedRows && e?.id == selectedRows) {
              setSelectedRows(null);
            } else {
              setSelectedRows(e?.id);
            }
          },
        }}
        rowSelection={{
          selectType: 'single',

          onSelectedRowsChange: (v) => {
            setSelectedRows(v?.[0]?.id);
          },
          hideColumn: true,
          hideSelectAll: true,
        }}
        locale={{ emptyText: <div></div> }}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => {
            return record?.children?.length > 0;
          },
        }}
      >
        <VSTableColumn
          dataIndex={['departmentName']}
          title="科室名称"
          // width="17.8rem"
          width={newWidth}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['departmentCode']}
          title="科室代码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室代码长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['departmentAlias']}
          title="科室别名"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室别名长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['clinicAttribute']}
          title="临床科室属性"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_ATTR_DICT}
          fieldProps={{ showSearch: true }}
        />

        <VSTableColumn
          dataIndex={['outpOrInp']}
          title="门诊住院属性"
          valueType="select"
          formItemProps={{
            label: '科室标志',
          }}
          dataSource={vsf.stores.dbenums.enums.DEPT_OI_ATTR_DICT}
          fieldProps={{ showSearch: true }}
        />

        <VSTableColumn
          dataIndex={['internalOrSurgery']}
          title="内外科标志"
          valueType="select"
          formItemProps={{
            rules: [
              {
                message: '内外科标志长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ],
          }}
          dataSource={vsf.stores.dbenums.enums.DEPT_IS_ATTR_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['branchHospital']}
          title="院区"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '院区长度不合法',
                type: 'string',
                min: 0,
                max: 20,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="停用标识"
          valueType="select"
          fieldProps={{ showSearch: true }}
          render={(item, props) => {
            return (
              <span
                type="text"
                style={{
                  color: `var(--${!props.stopIndicator ? 'green' : 'red'})`,
                  fontWeight: 500,
                }}
              >
                {!props.stopIndicator ? '在用' : '停用'}
              </span>
            );
          }}
        />

        {/* <VSTableColumn title="操作" valueType="text" fieldProps={{}} /> */}
      </VSTable>
    );
  };

  const title = props.value ? `（${props.value.institutionName}）` : '';
  return (
    <VSPromiseTable
      scroll={{
        y: getScrollY(480),
      }}
      // bordered
      pagination={false}
      // className="aspirin-table aspirin-table-no-background-box"
      className={cn(
        'table_overflow_480 aspirin-table-header-background-color aspirin-table-row-hover-background-color aspirin-table-body-empty-transparent-background-color aspirin-table aspirin-table-search-no-background-box',
        isMinScreen ? 'table_overflow_500' : 'table_overflow_480',
      )}
      rowClassName={(v) => {
        return v.id == selectedRows
          ? 'aspirin-table-row-select-background-color'
          : '';
      }}
      searchConfig={{
        labelWidth: 120,
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        searchButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        resetButtonProps: {
          size: 'large',
          className: 'icon16',
        },
        optionRender: (...arr) => {
          const [_, __, dom] = arr;
          return (
            <SearchContainer.Header title="筛选">{dom}</SearchContainer.Header>
          );
        },
      }}
      searchFormRender={(item, dom) => {
        return <SearchContainer>{dom}</SearchContainer>;
      }}
      id="departmentTable"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        columnProps: {
          fixed: 'none',
          width: 100,
        },
      }}
      onFetch={onFetch}
      onRemove={async (params) => {
        const res =
          await vsf?.services?.DepartmentBOController_deleteDepartment_f91e76?.(
            {
              btoParam: { ...(params ?? {}) },
              extApiParam: {},
            },
          );
        if (res?.code == 200) {
          message.success('删除成功');
        }
        return res;
      }}
      addLinkPage={{
        linkPage: () => {
          return selectedRows ||
            value?.upInstitutionId ||
            value?.upInstitutionId ? (
            <DepartmentEdit
              selectDepartmentList={tableList}
              info={value}
              selectedRows={selectedRows}
            />
          ) : (
            vsf.showToast('请选择科室或院区', 'warn')
          );
        },
        modalProps: {
          title: `新增科室${title}`,
          className: 'aspirin-linkpage',
          width: 1300,
          bodyStyle: {
            height: 440,
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.DepartmentForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
      }}
      onAdd={async (params) => {
        if (selectedRows) {
          const res =
            await vsf?.services?.DepartmentBOController_createDepartment_af7d4c?.(
              {
                btoParam: {
                  ...({
                    ...params,
                    ...params.branchInstitution,
                    // 机构
                    institutionId: params.branchInstitution?.institutionId,
                    // 院区
                    branchInstitutionId:
                      params.branchInstitution?.branchInstitutionId,
                  } ?? {}),
                },
                extApiParam: {},
              },
            );
          if (res.code === 200) {
            message.success('新增成功');
            vsf.refs?.departmentTable.reload();
            vsf.refs?.departmentTableRowExpandable.reload();
          }
          return res?.data;
        } else {
          const res =
            await vsf?.services?.DepartmentBOController_createDepartment_af7d4c?.(
              {
                btoParam: {
                  ...({
                    ...params,
                    ...params.branchInstitution,
                    // 机构
                    institutionId: value?.upValue?.[0]?.id,
                    // 院区
                    branchInstitutionId: value?.id,
                    // 后端处理
                    // serialNumber: tableList?.length + 1,
                  } ?? {}),
                },
                extApiParam: {},
              },
            );
          if (res.code === 200) {
            message.success('新增成功');
            vsf.refs?.departmentTable.reload();
            vsf.refs?.departmentTableRowExpandable.reload();
          }
          return res?.data;
        }
      }}
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.DepartmentBOController_updateDepartment_da3dee?.(
            { btoParam: { ...(params ?? {}) }, extApiParam: {} },
          );
        const currentItem = listData.current?.find(
          (item) => item.id === params?.id,
        );
        return {
          ...currentItem,
          ...res?.data,
          children: currentItem?.children,
        };
      }}
      updateLinkPage={{
        linkPage: () => <DepartmentEdit title="update" />,
        modalProps: {
          title: `编辑科室${title}`,
          className: 'aspirin-linkpage',
          width: 1300,
          bodyStyle: {
            height: 440,
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
          footerRender: (
            dom,
            { okButtonProps, cancelButtonProps },
            value,
            row,
          ) => {
            return (
              <>
                <Button
                  style={{
                    color: '#5D6268',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                  }}
                  {...cancelButtonProps}
                >
                  取消
                </Button>
                <Button
                  onClick={async () => {
                    Tip({
                      content: `是否删除：${row?.departmentName}`,
                      onOk: async () => {
                        const res =
                          await vsf?.services?.DepartmentBOController_deleteDepartment_f91e76?.(
                            {
                              btoParam: { ...(row ?? {}) },
                              extApiParam: {},
                            },
                          );
                        if (res.code === 200) {
                          vsf.refs.departmentTable.reload();
                          message.open({
                            type: 'success',
                            content: `删除成功`,
                          });
                          cancelButtonProps.onClick();
                        }
                        return res?.data;
                      },
                      cancelText: '取消',
                    });
                  }}
                  style={{
                    color: 'red',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                  }}
                >
                  删除
                </Button>
                <Button {...okButtonProps}>保存</Button>
              </>
            );
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            await vsf.refs.DepartmentForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
      }}
      vsid="58291"
      extraParams={props.value}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => {
          return record?.children?.length > 0;
        },
        showExpandColumn: false,
      }}
      // tableViewRender={TableRender}
      tableRender={(props, dom) => {
        return dom;
      }}
      rowClick={{
        rowClickType: 'single',
        onRowClick: (e) => {
          if (selectedRows && e?.id == selectedRows) {
            setSelectedRows(null);
          } else {
            setSelectedRows(e?.id);
          }
        },
      }}
    >
      <VSTableColumn
        dataIndex={['departmentName']}
        title="科室名称"
        width={basicFirstItemWidth}
        valueType="text"
        search
        columnKey={'departmentNameLike'}
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        // width="17.8rem"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['departmentCode']}
        title="科室代码"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室代码长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['departmentAlias']}
        title="科室别名"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室别名长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['clinicAttribute']}
        title="临床科室属性"
        valueType="select"
        search
        columnKey={'clinicAttributeIs'}
        dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_ATTR_DICT}
        fieldProps={{ showSearch: true }}
      />

      <VSTableColumn
        dataIndex={['outpOrInp']}
        title="门诊住院属性"
        valueType="select"
        search
        columnKey={'outpOrInpIs'}
        // formItemProps={{
        //   label: '科室标志',
        // }}
        dataSource={vsf.stores.dbenums.enums.DEPT_OI_ATTR_DICT}
        fieldProps={{ showSearch: true }}
      />

      <VSTableColumn
        dataIndex={['internalOrSurgery']}
        title="内外科标识"
        valueType="select"
        search
        columnKey={'internalOrSurgeryIs'}
        dataSource={vsf.stores.dbenums.enums.DEPT_IS_ATTR_DICT}
        formItemProps={{
          rules: [
            {
              message: '内外科标志长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{ showSearch: true }}
      />

      <VSTableColumn
        dataIndex={['branchInstitution', 'institutionName']}
        title="院区"
        valueType="select"
        search
        dataSource={async () => {
          const res =
            await vsf.services.MedicalInstitutionDtoController_getAllByInstitutionQto_926a63(
              { qto: {} },
            );
          const _res = res.data
            .filter((_item) => {
              return _item?.upInstitutionId;
            })
            .map((item) => {
              return {
                label: item?.institutionName,
                value: item?.id,
              };
            });
          return _res;
        }}
        columnKey={'branchInstitutionIdIs'}
        formItemProps={{
          rules: [
            {
              message: '院区长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{ showSearch: true }}
      />
      <VSTableColumn
        dataIndex={['stopIndicator']}
        title="停用标识"
        valueType="select"
        search
        columnKey={'stopIndicatorIs'}
        fieldProps={{ showSearch: true }}
        dataSource={[
          { label: '在用', value: false },
          { label: '停用', value: true },
        ]}
        render={(item, props) => {
          return (
            <span
              type="text"
              style={{
                color: `var(--${!props.stopIndicator ? 'green' : 'red'})`,
                fontWeight: 500,
              }}
            >
              {!props.stopIndicator ? '在用' : '停用'}
            </span>
          );
        }}
      />
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title="科室列表"
      >
        <VSTableAddition
          size="large"
          className="icon16"
          disabled={!(selectedRows || value?.upInstitutionId)}
          children="新增"
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(DepartmentTable);
