import { AspirinTime } from '@/components';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSDataProvider,
  Select,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import './index.less';
import { useMount, useRequest } from 'ahooks';
import { debounce } from '@/module/cpoe/medical/editor/utils';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import { askSearch } from '@/pages/Index/components/func/ask';
import { labelNumberWidth } from '@/utils';
const ClinicRegisterDefineEdit = (props) => {
  const [checkboxData, setCheckboxData] = useState();
  const [status, setStatus] = useState(true);

  useMount(() => {
    (async (params) => {
      const res =
        await vsf?.services?.OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a?.(
          {
            qto: {
              registerPlatformIndicatorIs: 1,
            },
          },
        );
      setCheckboxData(
        res?.data?.map((v) => ({
          label: v.platformName,
          value: v.id,
        })),
      );
    })();
  });
  const { data: reservationData } = useRequest(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a?.(
        {
          qto: {
            registerPlatformIndicatorIs: 0,
          },
        },
      );
    return res?.data?.map((v) => ({
      label: v.platformName,
      value: v.id,
    }));
  });
  const { data, run } = useRequest(
    async (params) => {
      const res =
        await vsf?.services?.OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5?.(
          {
            qto: {},
          },
        );
      const file = JSON.parse(JSON.stringify(res?.data));
      if (params) {
        const data = file.filter((item) => {
          return item.includes(params);
        });
        return data;
      } else {
        return res?.data;
      }

      // const file = JSON.parse(JSON.stringify(res?.data));
      // if (params.nameLike) {
      //   const data = file.filter((item) => {
      //     return item.includes(params);
      //   });
      //   return file;
      // } else {
      //   return res?.data;
      // }
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    run();
    if (props.value) {
      vsf.services
        .OutpRegisterDefineController_getRegisterDefineById_e6faa3(props.value)
        .then((res) => {
          vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
            ...res.data,
            classification: res?.data.outpSpecialClinic?.outpSpecialClinicType,
            doctorJob: res?.data?.doctor?.titleCode,
          });
          if (res.data.averageVisitTime === 0) {
            vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
              averageVisitTime: null,
            });
            if (res.data.registerGenerateRule === 'BY_PATIENT_VISIT_TIME') {
              setStatus(false);
            }
          }
        });
    }
  }, []);

  const handelList1 = async (v) => {
    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: v?.nameLike ?? '',
        },
      );
    return res.data?.result;
  };
  const handelList = async (v) => {
    const res =
      await vsf.services.DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de(
        { ...v },
      );
    const _res = res?.data?.map((item) => {
      return {
        label: item?.departmentName,
        value: item?.id,
      };
    });
    return res?.data;
  };
  return (
    <VSControlledForm
      id="ClinicRegisterDefineForm"
      vsid="71726"
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormLayout key="1" columnCount={3} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['clinicLabel']}
          label="号别"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            { required: true },
          ]}
          fieldProps={{
            onChange: debounce(
              ({ target }) => {
                vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
                  inputCode: askRomanAlphabet(target.value),
                });
              },
              30,
              { trailing: true },
            ),
          }}
        />

        <VSFormItem
          name={['clinicRegisterType']}
          label="号类"
          valueType="select"
          fieldProps={{
            dataSource: async (params) => {
              const res =
                await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472?.(
                  {
                    qto: {},
                  },
                );
              return res?.data;
            },
            fieldNames: { label: 'clinicType', value: 'id' },
            showSearch: true,
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['clinicDepartment']}
          label="科室"
          valueType="select"
          fieldProps={{
            dataSource: handelList,
            fieldNames: { label: 'departmentName', value: 'id' },
            showSearch: true,
            searchKey: 'name',
          }}
          rules={[{ required: true }]}
        />
      </VSFormLayout>

      <VSFormLayout key="2" columnCount={3} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['visitType']}
          label="出诊类型"
          valueType="select"
          dataSource={[
            { label: '门诊', value: 'OUTP' },
            { label: '急诊', value: 'ERP' },
            { label: '体检', value: 'PHYSICAL_EXAM' },
          ]}
          fieldProps={{}}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['outpSpecialClinic']}
          label="门诊专科"
          valueType="select"
          dataSource={(value) => {
            // console.log(value);
            return value?.outpSpecialClinicName
              ? data?.filter((item) =>
                  askSearch(
                    value?.outpSpecialClinicName,
                    item,
                    'outpSpecialClinicName',
                  ),
                )
              : data;
          }}
          fieldProps={{
            fieldNames: { label: 'outpSpecialClinicName', value: 'id' },
            onChange: (value) => {
              run(value?.outpSpecialClinicName);
              // console.log(value);
              vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
                classification: value?.outpSpecialClinicType,
              });
            },
            showSearch: true,
            // searchKey: 'nameLike',
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          label="学科分类"
          name={['classification']}
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_TYPE_DICT}
          fieldProps={{
            disabled: true,
            showSearch: true,
          }}
        />
      </VSFormLayout>

      <VSFormLayout key="3" columnCount={3} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['encounterType']}
          label="接诊类型"
          valueType="select"
          dataSource={[
            { label: '院内普通门诊', value: 'INTERNAL' },
            { label: '云门诊', value: 'CLOUD' },
            { label: '远程会诊', value: 'REMOTE_CONSULTATION' },
          ]}
          fieldProps={{}}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['doctor']}
          label="医生"
          valueType="select"
          fieldProps={{
            dataSource: handelList1,
            fieldNames: { label: 'staffName', value: 'id' },
            onChange: (val) => {
              vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
                doctorJob: val?.titleCode,
              });
            },
            showSearch: true,
            searchKey: 'nameLike',
          }}
        />

        <VSFormItem
          label="职务"
          name={['doctorJob']}
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.TITLE_DICT}
          fieldProps={{
            disabled: true,
            showSearch: true,
          }}
        />
      </VSFormLayout>
      <VSFormLayout key="4" columnCount={4} labelWidth={labelNumberWidth(12)}>
        <VSFormItem
          name={['waitTimeDefine', 'winterAmTimePeriod']}
          label="冬令候诊时间上午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['waitTimeDefine', 'winterAmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['waitTimeDefine', 'winterAmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['waitTimeDefine', 'winterPmTimePeriod']}
          label="冬令候诊时间下午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['waitTimeDefine', 'winterPmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['waitTimeDefine', 'winterPmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['registerTimeDefine', 'winterAmTimePeriod']}
          label="冬令挂号时间上午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['registerTimeDefine', 'winterAmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['registerTimeDefine', 'winterAmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['registerTimeDefine', 'winterPmTimePeriod']}
          label="冬令挂号时间下午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['registerTimeDefine', 'winterPmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['registerTimeDefine', 'winterPmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />
      </VSFormLayout>

      <VSFormLayout key="5" columnCount={4} labelWidth={labelNumberWidth(12)}>
        <VSFormItem
          name={['waitTimeDefine', 'summerAmTimePeriod']}
          label="夏令候诊时间上午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['waitTimeDefine', 'summerAmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['waitTimeDefine', 'summerAmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['waitTimeDefine', 'summerPmTimePeriod']}
          label="夏令候诊时间下午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['waitTimeDefine', 'summerPmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['waitTimeDefine', 'summerPmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['registerTimeDefine', 'summerAmTimePeriod']}
          label="夏令挂号时间上午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['registerTimeDefine', 'summerAmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['registerTimeDefine', 'summerAmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['registerTimeDefine', 'summerPmTimePeriod']}
          label="夏令挂号时间下午"
          valueType="aspirinDateTimePicker"
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['registerTimeDefine', 'summerPmTimePeriod'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          fieldProps={{
            type: 'range',
            format: 'HH:mm',
            onChange: (v) => {
              if (!Object.values(v)?.filter((item) => !!item)?.length) {
                vsf.refs.ClinicRegisterDefineForm.setFieldValue(
                  ['registerTimeDefine', 'summerPmTimePeriod'],
                  undefined,
                );
              }
            },
          }}
          rules={[{ required: true }]}
        />
      </VSFormLayout>

      <VSFormLayout key="6" columnCount={3} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['registerGenerateRule']}
          label="号序生成类型"
          valueType="select"
          dataSource={[
            { label: '按人均就诊时长', value: 'BY_PATIENT_VISIT_TIME' },
            { label: '按分时段时长', value: 'BY_DIVISION_TIME' },
          ]}
          fieldProps={{
            onChange: (v) => {
              if (v === 'BY_DIVISION_TIME') {
                setStatus(true);
                vsf.refs.ClinicRegisterDefineForm.setFieldsValue({
                  averageVisitTime: null,
                });
              } else {
                setStatus(false);
              }
            },
          }}
          rules={[{ required: true }]}
        />

        <VSFormItem
          name={['averageVisitTime']}
          label="时长(分钟) "
          valueType="text"
          rules={[{ required: status }]}
          transform={(v) => {
            return { averageVisitTime: Number(v) };
          }}
          fieldProps={{}}
        />
        <div className="self-payment-indicator">
          <div className="sub">
            <VSFormItem
              name={['selfPaymentIndicator']}
              valueType="check"
              initialValue={false}
              fieldProps={{}}
            />
            <div className="title">自费号</div>
          </div>
          <div className="sub">
            <div>
              <VSFormItem
                name={['specialClinicIndicator']}
                valueType="check"
                initialValue={false}
                fieldProps={{}}
              />
            </div>
            <div className="title">专病</div>
          </div>
        </div>
      </VSFormLayout>

      <VSFormLayout key="7" columnCount={1} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['forbiddenAppointPlatformId']}
          label="禁止预约平台"
          valueType="checkbox"
          fieldProps={{
            dataSource: reservationData,
          }}
        />
      </VSFormLayout>

      <VSFormLayout key="8" columnCount={1} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['forbiddenRegisterPlatformId']}
          label="禁用挂号平台"
          valueType="checkbox"
          fieldProps={{
            dataSource: checkboxData,
          }}
        />
      </VSFormLayout>

      <VSFormLayout key="9" columnCount={3} labelWidth={labelNumberWidth(8)}>
        <VSFormItem
          name={['inputCode']}
          label="拼音码"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 50 }]}
          fieldProps={{}}
        />

        {/* 
        <VSFormItem
          name={['inputCodeWubi']}
          label="数字码"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 50 }]}
          fieldProps={{}}
        /> */}
        <VSFormItem
          name={['roomLocation']}
          label="位置"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 60 }]}
          fieldProps={{}}
        />
      </VSFormLayout>

      <VSFormLayout
        key="10"
        columnCount={0}
        labelWidth={labelNumberWidth(8)}
      ></VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(ClinicRegisterDefineEdit);
