import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateDetailList_7eb527:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-charge-template-detail-list',
      parameterFomatter: (data?: { templateId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeTemplateDetailWithPriceItemVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
  MedicalTechnologyInpChargeController_saveInpBillingDetails_dcf810: {
    method: 'post',
    url: '/api/medical-technology/save-inp-billing-details',
    parameterFomatter: (data?: {
      saveInpBillingDetailList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
