// import Detail from './detail';
import '../index.less';

import vsf from '@vs/vsf-boot';
import { Popover, Tag } from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import { handelEnums } from '@/utils/index';

import { Detail } from './timeLineDetail/index';

enum OrderTypeEnum {
  DRUG = 'DRUG',
  HERB = 'HERB',
  LAB = 'LAB',
  EXAM = 'EXAM',
  TREAT = 'TREAT',
  OPERATION = 'OPERATION',
  ANESTHESIA = 'ANESTHESIA',
  NURSING = 'NURSING',
  MEAL = 'MEAL',
  NUTRITION = 'NUTRITION',
  CONSULTATION = 'CONSULTATION',
  BLOOD = 'BLOOD',
  OTHER = 'OTHER',
}

enum ApplyStatusEnum {
  OPEN = 'OPEN',
  CONFIRM_REPORT = 'CONFIRM_REPORT',
}

const mock = {
  startTime: '2023-08-22 8:22',
  endTime: '2023-08-22 15:22',
  type: '1',
  name: '0.9%氯化钠注射液 (100ml:0.9g (0.9%/1袋)注射用呱拉西林钠 (3g/3瓶)',
  abnormal: 'D-二聚体6769 ng/ml',
  operator: '李华',
  status: '1',
};

const Index = (props) => {
  const { data, currentItem, onClick } = props;
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState();
  // const data = mock;
  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      getDetail();
    }
    setOpen(newOpen);
  };
  const getDetail = async () => {
    // console.log(1111);

    const res =
      await vsf?.services?.PatientJourneyController_getPatientJourneyDetail_09a7d6?.(
        {
          orderId: data?.orderId,
          icon: data?.icon,
        },
      );
    if (res.code === 200) {
      setDetail(res?.data);
    }
  };

  const handelType = (type) => {
    // 检验
    if (type === OrderTypeEnum.LAB) {
      return {
        icon: 'icon-jianyan',
        size: 38,
      };
    }
    // 检查
    if (type === OrderTypeEnum.EXAM) {
      return {
        icon: 'icon-Frame-3',
        size: 38,
      };
    }
    // 西药/中成药
    if (type === OrderTypeEnum.DRUG) {
      return {
        icon: 'icon-Frame-9',
        size: 48,
      };
    }
    // 中草药
    if (type === OrderTypeEnum.HERB) {
      return {
        icon: 'icon-zhongcaoyao',
        size: 34,
      };
    }
    // 处置
    if (type === '处置') {
      return {
        icon: 'icon-Frame-6',
        size: 32,
      };
    }
    // 文书
    if (type === '文书') {
      return {
        icon: 'icon-Frame-4',
        size: 24,
      };
    }
    // 手术
    if (type === OrderTypeEnum.OPERATION) {
      return {
        icon: 'icon-shoushu',
        size: 24,
      };
    }
    // 病理
    // 会诊
    // 用血
    if (type === OrderTypeEnum.BLOOD) {
      return {
        icon: 'icon-Frame-24',
        size: 24,
      };
    }
    // 膳食
    if (type === OrderTypeEnum.MEAL) {
      return {
        icon: '差图标',
        size: 24,
      };
    }
    // 营养
    // 麻醉
    // 申请
  };

  const renderTime = (type) => {
    return (
      <div className="time dinpro-bold">
        {data?.firstTime && (
          <span>{dayjs(data?.firstTime).format('YYYY-MM-DD HH:mm')}</span>
        )}
        {data?.secondTime && <span className="line"></span>}
        {data?.secondTime && (
          <span>{dayjs(data?.secondTime).format('YYYY-MM-DD HH:mm')}</span>
        )}
      </div>
    );
    // if (type === OrderTypeEnum.ASSAY || type === OrderTypeEnum.EXAMINE) {
    //   return (
    //     <div className="time dinpro-bold">
    //       <span>{dayjs(data?.createdAt).format('YYYY-MM-DD HH:mm')}</span>
    //       <span className="line"></span>
    //       <span>
    //         {dayjs(data?.orderApplyDto?.reportTime).format('YYYY-MM-DD HH:mm')}
    //       </span>
    //     </div>
    //   );
    // }
    // if (type === OrderTypeEnum.MEDICATION || type === OrderTypeEnum.HANDLE) {
    //   return (
    //     <div className="time dinpro-bold">
    //       <span>{dayjs(data?.orderTime).format('YYYY-MM-DD HH:mm')}</span>
    //     </div>
    //   );
    // }
    // if (type === OrderTypeEnum.OPERATION) {
    //   return (
    //     <div className="time dinpro-bold">
    //       <span>{dayjs(data?.orderTime).format('YYYY-MM-DD HH:mm')}</span>
    //     </div>
    //   );
    // }
  };

  const renderStatus = (data, value) => {
    return (
      <Tag shape="fill" color={'success'}>
        {/* 检查 */}
        {data?.icon == 'EXAM' &&
          handelEnums(vsf.stores.dbenums.enums.EXAM_RESULT_STATUS_DICT, value)}
        {/* 检验 */}
        {data?.icon == 'LAB' &&
          handelEnums(vsf.stores.dbenums.enums.LAB_RESULT_STATUS_DICT, value)}
      </Tag>
    );
  };

  const renderuseWay = () => {
    // if (data?.icon === 'DRUG') {
    //   return (
    //     <>
    //       <div className="center">{data?.firstParameter}</div>
    //       <div className="center">{data?.secondParameter}</div>
    //     </>
    //   );
    // }
    // if (data?.icon === 'HERB') {
    return (
      <>
        <div className="center">{data?.firstParameter}</div>
        <div className="center">{data?.secondParameter}</div>
      </>
    );
    // }
  };

  return (
    <Popover
      placement="leftTop"
      title="基本信息"
      content={<Detail type={data?.icon} detail={detail} />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      overlayClassName="timeline-item-popover"
    >
      <div
        style={{
          border:
            data?.orderId === currentItem
              ? '2px solid #3276e8'
              : '2px solid transparent',
        }}
        className="timeline-item"
        onClick={onClick}
      >
        <div className="first">
          {renderTime(data?.icon)}
          <div
            style={{
              visibility: data?.status ? 'visible' : 'hidden',
            }}
            className="status"
          >
            {renderStatus(data, data?.status)}
          </div>
        </div>
        <div className="second">
          <div className="left">
            <div className="icon-container">
              {/* 图标 */}
              <Icon
                type={handelType(data?.icon)?.icon as string}
                size={handelType(data?.icon)?.size}
              />
              <div
                className="danger"
                style={{
                  display: data?.crisisIndicator ? 'block' : 'none',
                }}
              >
                危
              </div>
            </div>
            <div className="content">
              <div>
                <span className="label">名称</span>
                <div style={{ display: 'flex', flex: 1 }}>
                  <div
                    style={{
                      width: '5px',
                      border: '2px solid #3276E8 ',
                      borderRight: 0,
                      margin: '10px 0',
                      display: data?.orderName?.length <= 1 ? 'none' : 'block',
                    }}
                  ></div>
                  <div className="right ">
                    {data?.orderName?.map((item, index) => {
                      return (
                        <span key={index} className="value">
                          {item}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div>
                <span className="label">异常</span>
                {data?.abnormal && (
                  <div className="right">
                    <span className="show-value abnormal">
                      <Tag color="error">{data?.abnormal}</Tag>
                    </span>
                  </div>
                )}
                {/* )} */}
              </div>
            </div>
          </div>
          {renderuseWay()}
          <div className="right">{data?.doctorName}</div>
        </div>
      </div>
    </Popover>
  );
};

export default Index;
