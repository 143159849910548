import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_getPatientsByIds_265269: {
    method: 'post',
    url: '/api/patient-main/get-patients-by-ids',
    parameterFomatter: (data?: { id: number[] }) => data,
    responseFormatter: (_, __, data?: PatientVoPatientMainEntranceWebVo[]) =>
      data,
  },
  PatientExtensionController_createPatientMerges_466fca: {
    method: 'post',
    url: '/api/patient-extension/create-patient-merges',
    parameterFomatter: (data?: {
      createPatientMergeBtos: CreatePatientMergeBtoPatientExtensionServiceBto[];
      extApiParam: {
        memo: string;
        batchId: string;
        patientRetainForAll: number;
        patientRetainDisplayForAll: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientMergeVoPatientMainEntranceWebVoPatientfullvo[],
    ) => data,
  },
  PatientExtensionController_deletePatientMerges_b92b3b: {
    method: 'post',
    url: '/api/patient-extension/delete-patient-merges',
    parameterFomatter: (data?: {
      patientRetainIds: number[];
      extApiParam: {
        memo: string;
        batchId: string;
        nonResultThrowException: boolean;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientMergeVoPatientMainEntranceWebVoPatientfullvo[],
    ) => data,
  },
});
