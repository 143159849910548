import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_getAllByGetInpHerbWaitPrescriptionListQto_b97429:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-all-by-get-inp-herb-wait-prescription-list-qto',
      parameterFomatter: (data?: {
        qto: GetInpHerbWaitPrescriptionListQtoDrugPrescriptionDispensePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpHerbWaitPrescriptionListVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
