import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpDrugAuditController_getWaitAuditWardPatientCount_82cd2a: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-wait-audit-ward-patient-count',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitDispenseAuditPatientCountGroupWardEoDrugDispenseAuditPersistEo[],
    ) => data,
  },
});
