// 左侧table
// export const convertGetChargeSolutionListRes2ChargeSolutionSimpleList = (
//   input,
// ) => {
//   const _ = window._ ?? {};
//   const output = [];
//   output.push(
//     ...(_.get(input, 'data')?.map((d, i) => {
//       const obj = {};
//       obj.id = _.get(d, 'id');
//       obj.chargeSolutionCode = _.get(d, 'chargeSolutionCode');
//       obj.chargeSolutionName = _.get(d, 'chargeSolutionName');
//       obj.solutionMemo = _.get(d, 'solutionMemo');
//       obj.reduceSequence = _.get(d, 'reduceSequence');
//       return obj;
//     }) ?? []),
//   );
//   return output;
// };
// 右侧table
export const convertQueryChargeSolutionPatientPageRes2ChargeSolutionPatientListRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.chargeSolutionId = _.get(d, 'chargeSolutionId');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.name = _.get(d, 'name');
        obj.identificationNumber = _.get(d, 'identificationNumber');
        obj.stopIndicator = _.get(d, 'stopIndicator');
        obj.createStaffName = _.get(d, 'creatorStaff.staffName');
        obj.createdAt = _.get(d, 'createdAt');
        obj.lastModifyStaffName = _.get(d, 'lastModifyStaff.staffName');
        obj.updatedAt = _.get(d, 'updatedAt');
        obj.gender = _.get(d, 'patient.gender');
        return obj;
      }) ?? []),
    );
    return output;
  };

// 新增保存
export const convertAddChargeSolutionPatientForm2CreateChargeSolutionPatientReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {}; // TODO 没有匹配到合适字段 output.btoParam.id = null; // Long 主键
    output.btoParam.chargeSolutionId = _.get(input, 'chargeSolutionId'); // TODO 没有匹配到合适字段 output.btoParam.patientId = null; // Long 患者id
    output.btoParam.identificationNumber = _.get(input, 'identificationNumber');
    output.btoParam.name = _.get(input, 'name');
    output.btoParam.stopIndicator = _.get(input, 'stopIndicator');
    output.btoParam.patientId = _.get(input, 'patientId');
    // output.btoParam.creatorStaffId = _.get(input, 'lastModifyStaffId'); // Long 创建操作员id
    // output.btoParam.lastModifyStaffId = _.get(input, 'lastModifyStaffId'); // Long 最近修改操作员id
    return output;
  };
// 编辑保存
export const convertEditChargeSolutionPatientForm2UpdateChargeSolutionPatientReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = _.get(input, 'id');
    output.btoParam.chargeSolutionId = _.get(input, 'chargeSolutionId'); // TODO 没有匹配到合适字段 output.btoParam.patientId = null; // Long 患者id
    output.btoParam.identificationNumber = _.get(input, 'identificationNumber');
    output.btoParam.name = _.get(input, 'name');
    output.btoParam.stopIndicator = _.get(input, 'stopIndicator'); // TODO 没有匹配到合适字段 output.btoParam.creatorStaffId = null; // Long 创建操作员id
    output.btoParam.patientId = _.get(input, 'patientId');
    // TODO 没有匹配到合适字段 output.btoParam.lastModifyStaffId = null; // Long 最近修改操作员id
    return output;
  };
