import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
  PriceListDtoController_getPriceItemById_c2c8d8: {
    method: 'post',
    url: '/api/price-list/get-price-item-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  DrugFirmDictionaryDtoController_queryDrugFirmDictionaryNew_617280: {
    method: 'post',
    url: '/api/drug-drug/query-drug-firm-dictionary-new',
    parameterFomatter: (data?: { name: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoPriceListEntranceWebVoPriceitemvo[],
    ) => data,
  },
});
