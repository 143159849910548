import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCommonController_createDiagnosisDictionary_6da403: {
    method: 'post',
    url: '/api/base-common/create-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_updateDiagnosisDictionary_6261de: {
    method: 'post',
    url: '/api/base-common/update-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_stopDiagnosisDictionary_4a19d2: {
    method: 'post',
    url: '/api/base-common/stop-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: StopDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_getAllDiagnosisDictionaryForExcel_65391c: {
    method: 'post',
    url: '/api/base-common/get-all-diagnosis-dictionary-for-excel',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryDtoBaseCommonManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseCommonController_importExcel_c025d5: {
    method: 'post',
    url: '/api/base-common/import-diagnosis-dictionarys',
    parameterFomatter: (data?: {
      items: CreateDiagnosisDictionaryBtoBaseCommonServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryErrorMessageVoBaseCommonEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
