import vsf, { definePage } from '@vs/vsf-boot';
import {
  Descriptions,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { DatePicker, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { getEnumsLabel, getIdentification } from './methods';
import { CertificateSearch } from '@/components';
import { useRequest } from 'ahooks';
import { getPatientDetall, getVisitRecordList } from './services';
import { getScrollY, removeObjectFalsy } from '@/utils';
import { omitBy } from 'lodash';
import dayjs from 'dayjs';

import '@/style/adaptation.less';

const UserForm = (props) => {
  const { setOutpVisitId } = props,
    { data: getVisitRecordListData, run: getVisitRecordListRun } = useRequest(
      getVisitRecordList,
      { manual: true },
    ),
    [PatientDetails, setPatientDetails] = useState();
  useEffect(() => {
    setOutpVisitId(PatientDetails?.id);
    vsf?.refs?.outpMedicalTechnologyChargeOutpVisitForm?.setFieldValue?.(
      'displayIdIs',
      PatientDetails?.displayId,
    );
  }, [PatientDetails]);
  const inquire = async (params) => {
    setPatientDetails();
    const inquireParams =
      vsf?.refs?.outpMedicalTechnologyChargeOutpVisitForm?.getFieldsValue?.();
    let { identification } = inquireParams,
      patient;
    if (identification) identification = getIdentification(identification);
    if (identification) patient = await getPatientDetall(identification);
    if (patient)
      patient = {
        patientIdIn: patient?.map((ele) => {
          return ele?.id;
        }),
      };
    delete inquireParams.identification;
    const runParams = omitBy(
      removeObjectFalsy({ ...inquireParams, ...patient }),
      (value) => value == '',
    );
    if (Object.keys(runParams)?.length <= 0 || patient == false) return;
    getVisitRecordListRun(runParams);
  };

  return (
    <div className="UserForm">
      <VSForm id="outpMedicalTechnologyChargeOutpVisitForm">
        <VSFormLayout columnCount={3}>
          <VSFormItem
            name={['displayIdIs']}
            label="患者ID"
            valueType="text"
            fieldProps={{
              onPressEnter: () => inquire(),
            }}
          />
          <VSFormItem
            name={['identification']}
            label="证件及编号"
            valueType="custom"
          >
            <CertificateSearch onEnter={() => inquire()} />
          </VSFormItem>
          <VSFormItem
            name={'visitDateRangeIn'}
            label="就诊日期"
            valueType="limitTime"
            fieldProps={{
              onChange: () => inquire(),
              day: 7,
              showTime: false,
              format: 'YYYY-MM-DD',
            }}
            initialValue={[
              dayjs().subtract(6, 'day').valueOf(),
              dayjs().valueOf(),
            ]}
          />
        </VSFormLayout>
      </VSForm>
      <Descriptions column={6} size="default" className="Descriptions">
        <Descriptions.Item label="姓   名：">
          {PatientDetails?.name ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="性   别：">
          {getEnumsLabel('SEX_DICT', PatientDetails?.gender) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="费   别：">
          {getEnumsLabel('CHARGE_TYPE_DICT', PatientDetails?.chargeTypeCode) ??
            ''}
        </Descriptions.Item>
        <Descriptions.Item label="人员类别：">
          {PatientDetails?.identityType ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="就诊科室：">
          {PatientDetails?.departmentName ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="入院日期：">
          {PatientDetails?.visitDate &&
            dayjs(PatientDetails?.visitDate).format('YYYY-MM-DD')}

          {PatientDetails?.visitDate &&
            `（${dayjs().diff(PatientDetails?.visitDate, 'day')}天）`}
        </Descriptions.Item>
      </Descriptions>
      <PatientListModel
        {...{
          PatientListData: getVisitRecordListData,
          setPatientDetails,
        }}
      />
    </div>
  );
};

// 患者接诊记录
const PatientListModel = (props) => {
  const { PatientListData, setPatientDetails } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (PatientListData?.length >= 2) return setOpen(true);
    setPatientDetails(PatientListData?.[0]);
  }, [PatientListData]);
  return (
    <Modal
      title={
        <span style={{ color: 'rgba(50, 118, 232, 1)' }}>患者接诊记录</span>
      }
      open={open}
      onCancel={() => setOpen(false)}
      width={1200}
      footer=""
    >
      <VSTable
        id="outpMedicalTechnologyChargePatientListModelList"
        scroll={{
          y: getScrollY(400),
        }}
        value={PatientListData}
        rowClick={{
          type: 'default',
          action: 'double-click',
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: false,
          showTotal: undefined,
        }}
        onRow={(node) => {
          return {
            onDoubleClick: () => {
              setOpen(false);
              setPatientDetails(node);
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={['visitType']}
          title="医疗类别"
          valueType="select"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: undefined,
                max: 20,
              },
            ],
          }}
          fieldProps={{}}
          dataSource={[
            { label: '门诊', value: 'OUTP' },
            { label: '住院', value: 'INP' },
            { label: '急诊', value: 'ERP' },
            { label: '体检', value: 'PHYSICAL_EXAM' },
            { label: '挂号', value: 'CLINIC_REGISTER' },
          ]}
        />

        <VSTableColumn
          dataIndex={['visitDate']}
          title="就诊日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD ' }}
        />

        <VSTableColumn
          dataIndex={['clinicLabel']}
          title="号别名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
        /> */}

        <VSTableColumn
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ],
          }}
          fieldProps={{}}
        />

        {/* <VSTableColumn
          dataIndex={['chargeTypeCode']}
          title="费别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
        /> */}

        <VSTableColumn
          dataIndex={['identityType']}
          title="人员类别"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
        />
      </VSTable>
    </Modal>
  );
};

export default definePage(UserForm);
