import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamController_getExamClassWithBodyMethodById_d56c5a: {
    method: 'post',
    url: '/api/exam/get-exam-class-with-body-method-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithBodyMethodVoExamEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
});
