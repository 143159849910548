import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_createBaseCode_0172bf: {
    method: 'post',
    url: '/api/base-dictionary/create-base-code',
    parameterFomatter: (data?: {
      btoParam: CreateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_updateBaseCode_c565bf: {
    method: 'post',
    url: '/api/base-dictionary/update-base-code',
    parameterFomatter: (data?: {
      btoParam: UpdateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_deleteBaseCode_e64f94: {
    method: 'post',
    url: '/api/base-dictionary/delete-base-code',
    parameterFomatter: (data?: {
      btoParam: DeleteBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_getByCodeType_a0a0ce: {
    method: 'post',
    url: '/api/base-dictionary/get-by-code-type',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27: {
    method: 'post',
    url: '/api/base-dictionary/query-all-by-base-code-index-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeIndexQtoBaseDictionaryPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeVoBaseDictionaryEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
