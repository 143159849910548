import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message } from '@vs/vsf-kit';
import React, { useState } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { debounce } from 'lodash';
const PriceItemCategoryTree = (props) => {
  const onStop = (node) => {
    Tip({
      content: `确定停用{【${node.name}】}？停用后不可恢复`,
      onOk: async () => {
        const res =
          await vsf?.services?.PriceListBOController_updatePriceItemCategoryStatus_2942fa?.(
            { id: node.id, stop: true },
          );
        if (res.code === 200) {
          vsf.refs.PriceTree.handleReload();
          vsf.refs.PriceTable.reload();
          message.open({
            type: 'success',
            content: `停用【${node.name}】项目分类成功`,
          });
        }
        return res?.data;
      },
      cancelText: '取消',
    });
  };
  const [dataSource, setDataSource] = useState([]);

  const [select, setSelect] = useState({});

  // useEffect(() => {
  //   vsf?.services?.BaseCodeDtoServiceController_getByCodeType_5f9695?.(
  //     { codeType: 'RECK_ITEM_CLASS_DICT' },
  //   ).then(({ data }) => {
  //     for (const item of data) {
  //       if (item.ext) {
  //         vsf.services.PriceListDtoController_getPriceItemCategory_77dde8({
  //           id: item.id,
  //         }).then((res) => {
  //           if (res.code === 200) {
  //             console.log(res);
  //           }
  //         })
  //       }
  //     }
  //   })
  // }, [])
  return (
    <TreeMenu
      title="价格项目分类"
      vsid="74671"
      id="PriceTree"
      loadData={async () => {
        const res =
          await vsf?.services?.BaseCodeDtoServiceController_getValidCodeList_3cb2b9?.(
            { codeType: 'RECK_ITEM_CLASS_DICT' },
          );
        // setDataSource(res.data.filter((item) => item.serialNumber)
        //   .map((item) => item.serialNumber)
        //   .sort((firstItem, secondItem) => {
        //     return firstItem - secondItem
        //   }));
        return res;
      }}
      fieldNames={{ title: 'name', key: 'id', children: 'children' }}
      dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
      reload
      headerRender={() => {
        return (
          <Button
            onClick={() => {
              // console.log(select);
              props.onAdd(select);
            }}
          >
            新增
          </Button>
        );
      }}
      row={{
        extraShow: 'select',
        expand: true,
        extra: (node) => {
          return (
            <div className="aspirin-tree-button">
              {node.treeLevel === 0 && (
                <Icon
                  type="icon-tianjia"
                  onClick={(event) => {
                    event.stopPropagation();
                    props.onAdd({
                      // serialNumber: dataSource.at(-1) + 1,
                      ...node,
                    });
                  }}
                  size={20}
                />
              )}
              <Icon
                type="icon-bianji"
                size={20}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onEdit(node);
                }}
              />
              <Icon
                type="icon-tingyong"
                size={20}
                onClick={(event) => {
                  event.stopPropagation();
                  onStop(node);
                }}
              />
            </div>
          );
        },
      }}
      onSelect={debounce((_value) => {
        props.onChange?.(_value);
        setSelect(_value);
      }, 1000)}
    />
  );
};
export default definePage(PriceItemCategoryTree);
