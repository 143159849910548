import {
  Section,
  VSFormItem,
  VSControlledForm,
  Todo,
  VSDataProvider,
  VSFormLayout,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import vsf, { definePage } from '@vs/vsf-boot';
import { useRequest } from 'ahooks';

const StandardApplicationParameterEdit = (props) => {
  const { applicationClass } = props;
  /**
   * ApplicationLocalParameter
   * @type {ApplicationLocalParameterVoApplicationEntranceWebVo}
   */
  const [applicationLocalParameter, setApplicationLocalParameter] = useState(
    props.value,
  );
  const [localParamList, setLocalParamList] = useState([]);
  const [currentLocalParam, setCurrentLocalParam] = useState();
  const [groupList, setGroupList] = useState({});

  const requestParamList = async (id) => {
    const res =
      await vsf?.services?.LocalParameterConfigDtoController_getAllByLocalParameterQto_f27ac9?.(
        {
          qto: {
            classIdIs: id,
          },
        },
      );
    res?.code === 200 && setLocalParamList(res?.data);
  };

  const requestGroupList = async (id) => {
    const res =
      await vsf?.services?.ApplicationVsLocalParameterDtoController_getLocalParameterGroupList_9dd4c3?.();
    res?.code === 200 && setGroupList(res?.data);
  };

  const handleFormChange = async (values) => {
    if (values?.applicationVsLocalParameterBtoList?.localConfigId) {
      const target = localParamList?.find(
        (item) =>
          item.id === values?.applicationVsLocalParameterBtoList?.localConfigId,
      );
      setCurrentLocalParam(target);
    }
  };

  const renderParamDesc = (data) => {
    const { scopeType } = data || {};
    if (scopeType === 'TEXT_TYPE') {
      return 'text';
    } else if (scopeType === 'BOOL_TYPE') {
      return 'switch';
    }
    return 'text';
  };

  const [paramFunc, setParamFunc] = useState();
  const paramFuncRequest = useRequest(async () => {
    const res =
      await vsf.services.LocalParameterConfigDtoController_getAllByLocalParameterQto_f27ac9(
        {
          qto: { size: 99999, orderList: [] },
        },
      );
    setParamFunc(
      res.data.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    );
  });

  useEffect(() => {
    requestGroupList();
    paramFuncRequest?.run();
  }, []);

  useEffect(() => {
    requestParamList(applicationClass);
  }, [applicationClass]);

  const { data: dataSource } = useRequest(async () => {
    const res =
      await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
        {
          codeType: '000400',
        },
      );
    return res?.data?.map((item) => ({
      value: item?.code,
      label: item?.name,
    }));
  });
  const disabled = useMemo(() => props?.value, []);

  return (
    <Section title="">
      {props.value?.id && (
        <VSDataProvider
          service={
            vsf.services
              .ApplicationVsLocalParameterDtoController_getApplicationVsLocalParameterById_bbd9aa
          }
          params={{
            id: props.value?.id,
          }}
          onDataLoaded={(_value) => {
            const {
              applicationLocalGroup,
              applicationLocalSubGroup,
              localConfig,
              localValue,
              localValueDescription,
              ...rest
            } = _value || {};
            const formatValue = {
              applicationLocalGroup: [applicationLocalGroup],
              applicationLocalSubGroup: [applicationLocalSubGroup],
              localConfigId: localConfig?.id,
              localValue,
              localValueDescription,
            };
            setApplicationLocalParameter({
              ...rest,
              applicationVsLocalParameterBtoList: formatValue,
            });
          }}
        />
      )}

      {/* <Todo
        type="block"
        content="applicationId需要取到然后传到bto的外层id"
        onService={async (params) => {
          const res =
            await vsf?.services?.ApplicationVsLocalParameterDtoController_getApplicationVsLocalParameterById_bbd9aa?.(
              { id: params?.id },
            );
          return res?.data; // todo 整理返回数据格式
        }}
      />

      <Todo
        type="block"
        content="选择参数字段变成select,数据来源getAllByLocalParameterQto。分组和子分组select获取接口getLocalParameterGroupList"
      /> */}

      <VSControlledForm
        vsid="50260"
        id="ParameterForm"
        value={applicationLocalParameter}
        onChange={(_value) => {
          props.onChange?.(_value);
        }}
        onValuesChange={handleFormChange}
        layout="horizontal"
        labelAlign="left"
      >
        <VSFormItem
          name={['applicationId']}
          label="应用程序标识号"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['applicationVsLocalParameterBtoList', 'id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout columnCount={2} gutter={[80]} labelWidth={100}>
          <VSFormItem
            name={['localConfig', 'classId']}
            label="参数分类"
            valueType="select"
            // dataSource={vsf.stores.dbenums.enums.SYS_CONFIG_TYPE}
            dataSource={dataSource}
            rules={[{ required: true }]}
            fieldProps={{ showSearch: true, disabled }}
          />

          <VSFormItem
            name={['applicationVsLocalParameterBtoList', 'localConfigId']}
            label="选择参数"
            valueType="select"
            dataSource={paramFunc}
            fieldProps={{ showSearch: true, disabled }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          {/* <VSFormItem
            name={['localConfig', 'cnName']}
            label="本地参数中文名称"
            fieldProps={{ disabled: true }}
            valueType="text"
            rules={[
              {
                message: 'local_config_cn_name长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ]}
          /> */}

          <VSFormItem
            name={[
              'applicationVsLocalParameterBtoList',
              'applicationLocalGroup',
            ]}
            label="参数分组"
            valueType="custom"
            fieldProps={{}}
          >
            <Select
              mode="tags"
              multiple={false}
              maxTagCount={1}
              optionFilterProp="label"
              style={{ width: '100%' }}
              placeholder="Tags Mode"
              options={(groupList?.groupList ?? [])?.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </VSFormItem>

          {/* <VSFormItem
            name={[
              'applicationVsLocalParameterBtoList',
              'applicationLocalSubGroup',
            ]}
            label="参数子分组"
            valueType="custom"
            fieldProps={{}}
          >
            <Select
              mode="tags"
              multiple={false}
              maxTagCount={1}
              optionFilterProp="label"
              style={{ width: '100%' }}
              placeholder="Tags Mode"
              options={(groupList?.subGroupList ?? [])?.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </VSFormItem> */}

          <VSFormItem
            name={[
              'applicationVsLocalParameterBtoList',
              'localValueDescription',
            ]}
            label="参数值说明"
            valueType="text"
            rules={[
              {
                message: 'local_value_des长度不合法',
                type: 'string',
                min: 0,
                max: 1000,
              },
              { required: true },
            ]}
            fieldProps={{ defaultValue: '参数值说明' }}
          />

          <VSFormItem
            name={['applicationVsLocalParameterBtoList', 'localValue']}
            label="参数值"
            valueType={renderParamDesc(currentLocalParam)}
            rules={[{ required: true }]}
            fieldProps={{ defaultValue: '参数值' }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(StandardApplicationParameterEdit);
