import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitController_queryOutpVisitHistory_65b7c4: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-outp-visit-history',
    parameterFomatter: (data?: {
      qto: OutVisitHistoryQtoSimpleClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicOutpRegisterMainController_registerPreRefund_d348ca: {
    method: 'post',
    url: '/api/clinic-outp-register-main/register-pre-refund',
    parameterFomatter: (data?: { outpVisitId: number; patientId: number }) =>
      data,
    responseFormatter: (_, __, data?: OutpPreRefundEoOutpBillingPersistEo) =>
      data,
  },
  ClinicOutpRegisterMainController_registerRefund_de78b8: {
    method: 'post',
    url: '/api/clinic-outp-register-main/register-refund',
    parameterFomatter: (data?: {
      outpPreRefundEo: OutpPreRefundEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
