import './index.less';

import { mock } from 'node:test';

import vsf from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { flatMapDeep, map } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { Determine } from '@/pages/Index/components';

const SendModal = (props, ref): any => {
  const { title, condition, message } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const renderContent = useCallback(() => {
    const failList = flatMapDeep(value, (parent) => {
      return map(parent.failOrderList, (child) => {
        return {
          ...child, // 保留child对象的所有信息
          ...parent,
        };
      });
    });
    return (
      <div className="content">
        <div className="first">
          <VSTable value={value} pagination={false}>
            <VSTableColumn valueType="index" title="" preview />

            <VSTableColumn
              valueType="text"
              dataIndex={['bedLabel']}
              title="床号"
              preview
            />

            <VSTableColumn
              dataIndex={['patientName']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['checkStatus']}
              title="发送结果"
              valueType="select"
              dataSource={[
                { label: '成功', value: 'SUCCESS' },
                { label: '失败', value: 'FAIL' },
                { label: '部分成功', value: 'PARTIAL_SUCCESS' },
              ]}
              fieldProps={{}}
            />
          </VSTable>
        </div>
        <div className="second">
          <VSTable value={failList} pagination={false}>
            <VSTableColumn
              valueType="text"
              dataIndex={['patientName']}
              title="患者信息"
              preview
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱内容"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['errorMessage']}
              title="失败详情"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSTable>
        </div>
      </div>
    );
  }, [value]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button type="primary" onClick={onClose}>
            取消
          </Button>
        </div>
      ),
    };
  }, [onClose]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  if (!open) {
    return null;
  }

  return (
    <Modal
      width={1168}
      open={open}
      destroyOnClose={true}
      title={title}
      onCancel={onClose}
      {...renderFooter()}
      className="aspirin-check-order-send-modal"
    >
      <Determine condition={condition}>
        <Alert
          type="warning"
          message={message}
          className="aspirin-alert-warning-box"
          style={{
            marginBottom: 20,
          }}
        />
      </Determine>
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(SendModal);
