import '../index';

import { DoubleRightOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Image,
  Modal,
  Timeline,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep, join } from 'lodash';
import React, { useEffect, useState } from 'react';

import ArrowsImg from '@/assets/arrows_img.png';
import Cdss from '@/module/cpoe/Cdss';
import Empty from '@/module/Empty';
import Block from '@/pages/Index/components/block_no_padding';

import {
  checkBasicInfo,
  checkFloatList,
  examineBasicInfo,
  examineFloatList,
  herbsBasicDetail,
  herbsBasicInfo,
  herbsFloatList,
  westernMedicineAndChinesePatentMedicineBasicInfo,
  westernMedicineAndChinesePatentMedicineFloatList,
} from '../../cpoe/center/right/timeline/timeLineDetail';
import { useOnChangeValue } from '../func/hooks';
import { AsyncSuspense, Determine, Flex, Grid } from '../index';
// import Tendency from '../tendency';

export const Base = ({ list }) => {
  const dataSource = [
    { label: '开立', value: 'SAVE' },
    { label: '提交', value: 'SUBMIT' },
    { label: '取消提交', value: 'CANCEL_SUBMIT' },
    { label: '作废', value: 'INVALID' },
    { label: '撤销', value: 'REVOKE' },
    { label: '停止', value: 'STOP' },
    { label: '护士校对', value: 'NURSE_CHECK' },
    { label: '取消校对', value: 'CANCEL_CHECK' },
    { label: '退费', value: 'REFUND' },
    { label: '收费', value: 'CHARGE' },
  ];
  return list?.map((item, index) => {
    const time = item?.operateTime;
    return (
      <div key={item?.id} className="item" style={{ width: '100%' }}>
        <div className="img">
          <div className="title">
            {dataSource?.find(({ value }) => value === item?.operateType)
              ?.label ?? ''}
          </div>
          <div className="time">
            {time ? dayjs(time)?.format('YYYY-MM-DD HH:mm:ss') : ''}
          </div>
          <div className="name">{item?.operatorName}</div>
        </div>
        <Determine condition={index !== list?.length - 1}>
          <div className="arrows">
            <Image preview={false} width={33} height={33} src={ArrowsImg} />
          </div>
        </Determine>
      </div>
    );
  });
};

// 西药/中成药
const Index = (props) => {
  const { detail, type } = props;
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};
  // 检查报告列表
  const [reportDetail, setReportDetail] = useState<any>();

  const _patientId = currentPatient?.patientId;

  useEffect(() => {
    const tempList = cloneDeep(detail?.patientJourneyOrderExamReportList);
    const _arr = tempList?.map((item) => {
      return {
        ...item,
        show: false,
      };
    });
    setReportDetail(_arr);
  }, [detail]);

  const handelHearder = (item, v) => {
    if (item?.value === 'orderDoctorName') {
      return detail?.orderDoctor?.name;
    } else if (item?.value === 'mainDiagnose') {
      return detail?.treat?.mainDiagnose;
    } else {
      return detail?.[item?.value];
    }
  };

  const handelBasic = (extar?) => {
    // 西药/中成药
    if (type === 'DRUG') {
      return {
        basicInfo: westernMedicineAndChinesePatentMedicineBasicInfo,
        floatList: westernMedicineAndChinesePatentMedicineFloatList,
      };
    } else if (type === 'HERB' && extar === 'detail') {
      return {
        basicInfo: herbsBasicDetail,
        floatList: [],
      };
    } else if (type === 'HERB') {
      // 中草药
      return {
        basicInfo: herbsBasicInfo,
        floatList: herbsFloatList,
      };
    } else if (type === 'LAB') {
      // 检验
      return {
        basicInfo: examineBasicInfo,
        floatList: examineFloatList,
      };
    } else if (type === 'EXAM') {
      // 检查
      return {
        basicInfo: checkBasicInfo,
        floatList: checkFloatList,
      };
    } else {
      return { basicInfo: [], floatList: [] };
    }
  };

  const handelShow = (index?) => {
    const tempList = cloneDeep(reportDetail);
    const _arr = tempList?.map?.((item, _idx) => {
      return {
        ...item,
        show: _idx === index ? !item.show : item.show,
      };
    });
    setReportDetail(_arr);
  };

  // const getAnalysisData = async (row, time?) => {
  //   console.log(_patientId);

  //   const res = await vsf?.services?.PatientJourneyController_analysis_61065d({
  //     patientId: _patientId,
  //     qto: {
  //       resultDateTimeRangeIn: {
  //         begin: time?.start,
  //         end: time?.end,
  //         beginInclude: true,
  //         endInclude: true,
  //       },
  //       reportItemCodeIs: row?.reportItemCode,
  //     },
  //   });
  //   if (res?.code === 200) {
  //     setAnalysisData(res?.data);
  //   }
  // };

  const renderMedicalInsuranceType = (type) => {
    if (type === 'ALPHA') {
      return '甲';
    }
    if (type === 'BETA') {
      return '乙';
    }
    if (type === 'GAMMA') {
      return '丙';
    }
  };

  const [show] = useOnChangeValue<any>({}, (value) => {
    loading?.onChange?.(true);
    setTimeout(() => {
      loading?.onChange?.(false);
    }, 500);
    return value;
  });

  useEffect(() => {
    if (detail?.orderLogWithStaffList?.length) {
      show?.onChange?.(detail?.orderLogWithStaffList?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.orderLogWithStaffList]);

  const dataSource = [
    { label: '西药', value: 'DRUG' },
    { label: '治疗', value: 'TREAT' },
    { label: '麻醉', value: 'ANESTHESIA' },
    { label: '护理', value: 'NURSING' },
    { label: '膳食', value: 'MEAL' },
    { label: '营养', value: 'NUTRITION' },
    { label: '会诊', value: 'CONSULTATION' },
    { label: '用血', value: 'BLOOD' },
    { label: '其他', value: 'OTHER' },
  ];

  const [loading] = useOnChangeValue(false);

  const render = {
    EXAM: () => {
      return reportDetail?.map((item, index) => {
        const bind = [
          {
            label: '检查所见',
            value: item?.description,
          },
          {
            label: '印象',
            value: item?.impression,
          },
          {
            label: '建议',
            value: item?.advise,
          },
          {
            label: '检查参数',
            value: item?.examParameter,
          },
          {
            label: '是否阳性',
            value: item?.positiveIndicator,
          },
          {
            label: '使用仪器',
            value: item?.device,
          },
          {
            label: '报告中图像编号',
            value: item?.useImage,
          },
        ];
        return (
          <Block
            titleBackgroundColor="#DBEDFF"
            title={'报告' + (index + 1)}
            titleStyle={{ marginLeft: 10 }}
            key={index}
            extra={
              <Flex
                style={{
                  paddingRight: 10,
                  width: '100%',
                }}
              >
                <div className="timeline-detail_checked_detail_view_img">
                  查看图像
                </div>
                <DoubleRightOutlined
                  className={`timeline-detail_checked_detail_double_${
                    item?.show ? 'up' : 'down'
                  }_outline`}
                  style={{ color: '#3276E8' }}
                  onClick={() => {
                    handelShow(index);
                    // setIsShowDetail(!isShowDetail);
                  }}
                />
              </Flex>
            }
          >
            <div
              style={{
                display: item?.show ? 'block' : 'none',
                padding: '0 35px',
              }}
            >
              {bind?.map((item, index) => (
                <div
                  className="timeline-detail_checked_detail_content"
                  key={index}
                >
                  <div className="timeline-detail_checked_detail_content_label">
                    {item?.label}
                  </div>
                  <div className="timeline-detail_checked_detail_content_content">
                    {item?.value}
                  </div>
                </div>
              ))}
            </div>
          </Block>
        );
      });
    },
    LAB: () => {
      return (
        <div
          className="items"
          style={{
            borderTop: '1px solid #d6d8d9',
          }}
        >
          <VSTable
            scroll={{
              y: 436,
            }}
            className="aspirin-table aspirin-table-nopadding"
            editable={{
              editType: 'none',
            }}
            value={detail?.patientJourneyOrderLabResultList}
            pagination={false}
          >
            <VSTableColumn
              valueType="index"
              fieldProps={{}}
              editable={false}
              width={40}
            />
            <VSTableColumn
              dataIndex={['reportItemName']}
              title="项目"
              valueType="text"
              fieldProps={{}}
              width={250}
            />

            <VSTableColumn
              dataIndex={['result']}
              title="结果"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['resultReference']}
              title="参考范围"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['unit']}
              title="单位"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['abnormalSignature']}
              title="异常结果"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return (
                  <div style={{ color: '#E34A4E' }}>
                    {data?.abnormalSignature}
                  </div>
                );
              }}
            />
            <VSTableColumn
              dataIndex={['a6']}
              title="操作"
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                const pattern = new RegExp('[\u4E00-\u9FA5]+');
                return !pattern.test(data?.result) ? (
                  <Cdss title="智能助手(检测报告)" placement="left" data={data}>
                    <Button
                      type="text"
                      style={{
                        color: '#3276E8',
                      }}
                      // onClick={() => {
                      //   setOpen(true);
                      // }}
                    >
                      分析
                    </Button>
                  </Cdss>
                ) : (
                  ''
                );
              }}
            />
          </VSTable>
        </div>
      );
    },
    HERB: () => {
      <div className="herbcontent">
        <div className="title">
          <span>中汤药剂明细</span>
          <span className="number">
            (共{detail?.patientJourneyOrderHerbDetailList?.length ?? 0}味药)
          </span>
        </div>
        <div className="info" style={{ border: 'none' }}>
          {handelBasic('detail')?.basicInfo?.map((v, _index) => {
            return (
              <div key={_index} className="item">
                <span className="label">{v?.label}</span>
                <span className="value">{detail?.[v?.value ?? '']}</span>
              </div>
            );
          })}
        </div>
        {/* detail?.patientJourneyOrderHerbDetailList?.length>0&& */}
        <div className="list">
          <Grid amount={2}>
            {detail?.patientJourneyOrderHerbDetailList?.map((item, index) => {
              return (
                <Alert
                  key={index}
                  type="warning"
                  message={
                    <div className="aspirin-title-herb-popover-item-box">
                      <span>{index + 1}</span>
                      <span>{item?.drugProductName}</span>
                    </div>
                  }
                />
              );
            })}
          </Grid>
        </div>
      </div>;
    },
  };

  return (
    <div className="timeline-detail">
      {/* 基础信息 */}
      {detail?.orderVoList?.map((item) => {
        return (
          <div key={item?.id} className="info">
            {handelBasic()?.basicInfo?.map((v, _index) => {
              return (
                <div key={_index} className="item">
                  <span className="label">{v?.label}</span>
                  <span className="value">{item?.[v?.value ?? '']}</span>
                </div>
              );
            })}
          </div>
        );
      })}
      <div className="content">
        {/* 流程图 */}
        <div
          style={{
            height: handelBasic()?.floatList?.length > 5 ? '300px' : '160px',
          }}
          className="flow_chart"
        >
          <Determine
            condition={
              dataSource
                ?.map((item) => item?.value)
                .includes(detail?.orderVoList?.[0]?.orderClass) &&
              ![null, void 0]?.includes(detail?.orderVoList?.[0]?.inpVisitId)
            }
            fallback={
              <Determine
                condition={Boolean(
                  detail?.orderLogWithStaffList?.[0]?.operationLogCommonVoList
                    ?.length,
                )}
                fallback={<Empty />}
              >
                <Grid amount={4}>
                  <Base
                    list={
                      detail?.orderLogWithStaffList?.[0]
                        ?.operationLogCommonVoList ?? []
                    }
                  />
                </Grid>
              </Determine>
            }
          >
            <Determine
              condition={detail?.orderLogWithStaffList?.length}
              fallback={<Empty />}
            >
              <Flex gap={20} style={{ width: '100%' }}>
                <div
                  style={{
                    width: '30%',
                  }}
                >
                  <Timeline>
                    {detail?.orderLogWithStaffList?.map((item) => (
                      <Timeline.Item key={item?.orderPerformPlanRecordVo?.id}>
                        <span
                          style={{
                            cursor: 'pointer',
                            color:
                              item?.orderPerformPlanRecordVo?.id ===
                              show?.value?.orderPerformPlanRecordVo?.id
                                ? 'var(--blue)'
                                : '',
                          }}
                          onClick={() => {
                            show?.onChange?.(item);
                          }}
                        >
                          {item?.orderPerformPlanRecordVo?.planDateTime}
                        </span>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
                <AsyncSuspense
                  loading={loading?.value}
                  dataSource={show?.value?.operationLogCommonVoList?.length}
                  empty={<Empty />}
                >
                  <Grid
                    style={{
                      width: '70%',
                    }}
                  >
                    <Base list={show?.value?.operationLogCommonVoList ?? []} />
                  </Grid>
                </AsyncSuspense>
              </Flex>
            </Determine>
          </Determine>
        </div>
        {render?.[type]?.()}
      </div>
    </div>
  );
};

export default definePage(Index);
