import vsf, { definePage } from '@vs/vsf-boot';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip'
import { Button } from '@vs/vsf-kit';
const ExamClassTree = forwardRef((props, ref) => {
  const value = useRef(false);
  useImperativeHandle(ref, () => ({
    onChange: (state) => {
      value.current = state
    },
  }));
  return (
    <TreeMenu
      id="ExamClassTree"
      treeProps={{
        defaultExpandAll: true,
      }}
      title="病理项目分类"
      reload
      isDisabled={() => value?.current}
      onSelect={async (...[_value, tree, , selectedKeys, oldSelectedKeys]) => {
        const get = () => {
         /**
         * 如果当前树级不是0和在 病理部位/方法就跳回病理项目 tab 页
         */
          if (['4', '3'].includes(props?.active) && _value.treeLevel !== 0) {
            props?.setActive('5')
          }
          value.current = false;
          props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
        }
        if(value?.current) {
          return new Promise((resolve) => {
            Tip({
              content: `当前分类未保存，是否确定切换`,
              onOk: () => {
                get?.();
                resolve?.(selectedKeys);
              },
              onCancel: () => {
                resolve(oldSelectedKeys);
              }
            })
          });
        }
        get();
        return selectedKeys;
      }}
      isTree
      loadData={async () => {
        const res =
          await vsf?.services?.ExamClassDictionaryDtoController_getAllByExamClassQto_3b08d4?.(
            {
              qto: {
                stopIndicatorIs: false,
              }
            },
          );
        const data = askTree(res?.data, {
          parentId: 'parentExamClassId',
          id: 'id',
        })?.filter((item) => item?.itemClass === 'PATHOLOGY');
        return {
          data,
        };
      }}
      fieldNames={{ title: 'examClassName', key: 'id', children: 'children' }}
      dataRelationFieldNames={{
        id: 'id',
        parentId: 'parentExamClassId',
      }}
      row={{
        extraShow: 'select',
        expand: true,
        extra: (node) => {
          return (
            <div className="aspirin-tree-button">
              {
                node.treeLevel !== 2 && <Icon
                  type="icon-tianjia"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onAdd(node)
                  }}
                  size={20}
                />
              }
              {
                node?.treeLevel !== 0 && 
                <Icon type="icon-tingyong" 
                size={20} 
                onClick={(e) => {
                  e.stopPropagation();
                  props.onStop(node)
                }} />
              }
            </div>
          )
        },
      }}
    />
  );
});
export default ExamClassTree;