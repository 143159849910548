import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_getListWaitInpPreTransferApplyVoByToWardId_cfacc9: {
    method: 'post',
    url: '/api/clinic-inp-adt/get-list-wait-inp-pre-transfer-apply-vo-by-to-ward-id',
    parameterFomatter: (data?: { toWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitInpPreTransferApplyVoClinicInpAdtEntranceWebVo[],
    ) => data,
  },
});
