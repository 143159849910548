import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugApplyController_deleteDrugApplyMaster_d47f7f: {
    method: 'post',
    url: '/api/drug-storage-import-export/delete-drug-apply-master',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugApplyMasterBtoDrugStorageImportExportServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugApplyController_getCreatedApplyMasters_95e39c: {
    method: 'post',
    url: '/api/drug-storage-import/get-created-apply-masters',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyMasterStorageVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
});
