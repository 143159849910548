import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getAllByPricePatternQto_8ee5de: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-pattern-qto',
    parameterFomatter: (data?: { qto: PricePatternQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PricePatternVoPriceListEntranceWebVo[]) =>
      data,
  },
});
