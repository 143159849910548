import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamOrderController_getExamBaseInfo_4de915: {
    method: 'post',
    url: '/api/exam/order/get-exam-base-info',
    parameterFomatter: (data?: { outpVisitId: number }) => data,
    responseFormatter: (_, __, data?: ExamOrderBaseInfoEoExamPersistEo) => data,
  },
  ExamOrderController_getExamItemClassByParentClassId_09c596: {
    method: 'post',
    url: '/api/exam/get-exam-item-class-by-parent-class-id',
    parameterFomatter: (data?: { parentClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamClassDictionaryDtoExamManagerDto[]) =>
      data,
  },
  ExamOrderController_getExamItemInfo_3da8d6: {
    method: 'post',
    url: '/api/exam/order/get-exam-item-info',
    parameterFomatter: (data?: { examV_sClinicIds: number[] }) => data,
    responseFormatter: (_, __, data?: ExamItemInfoEoExamPersistEo) => data,
  },
});
