import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  Button,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import Block from '@/module/Block';
import { getScrollY } from '@/utils';

import './index.less';

const StandardApplicationParameter = (props) => {
  const { application } = props;
  const { parameter } = props?.stores || {};

  const { getParameterWithApplication } = parameter || {};

  const [localParameterData, setLocalParameterData] = useState();
  const [formFieldData, setFormFieldData] = useState();
  const [formFieldValue, setFormFieldValue] = useState();

  const handleFetch = useCallback(async () => {
    if (!application?.id)
      return {
        data: [],
        total: 0,
      };
    const res =
      await vsf?.services?.ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2?.(
        {
          applicationId: application?.id,
        },
      );
    if (res?.code === 200) {
      const target = res?.data;
      setLocalParameterData(target);
      let obj = {};
      const valueObj = {};
      target?.map((item) => {
        if (obj?.[item?.applicationLocalGroup]) {
          obj[item?.applicationLocalGroup].push(item);
        } else {
          obj[item?.applicationLocalGroup] = [item];
        }
        valueObj[item?.localConfig?.name] = item?.localValue;
      });
      setFormFieldValue(valueObj);
      setFormFieldData(obj);
    }
  }, [application]);

  const handleSubmit = async () => {
    const params =
      (await vsf?.refs?.BusinessParameterForm?.validateFieldsReturnFormatValue()) ||
      {};
    const req = {
      id: application?.id,
      applicationVsLocalParameterBtoList: (localParameterData ?? [])?.map(
        (item) => {
          const { applicationId, ...rest } = item;
          return {
            ...rest,
            localValue: params[item?.localConfig?.name] ?? item?.localValue,
            localConfigId: item?.localConfig?.id,
          };
        },
      ),
    };
    const res =
      await vsf?.services?.ApplicationBOController_updateParameterVsApplication_7e4468?.(
        {
          btoParam: req,
          extApiParam: {},
        },
      );
    if (res?.code === 200) {
      getParameterWithApplication(application?.id);
      message?.success('保存成功');
    }
  };

  useEffect(() => {
    handleFetch();
  }, [application]);


  const getFormItemType = (v) => {
    const { scopeType, scopeList, storageLocation, valueType } =
      v?.localConfig || {};
    const itemProps = {};
    if (valueType === 'BOOL_TYPE') {
      itemProps.valueType = 'switch';
    } else if (valueType === 'STRING_TYPE') {
      if (scopeType === 'TEXT_TYPE') {
        itemProps.valueType = 'text';
      } else if (scopeType === 'ARRAY_TYPE') {
        itemProps.valueType = 'select';
        itemProps.dataSource = Array.isArray(scopeList)
          ? scopeList
          : [scopeList];
      }
    } else if (valueType === 'INTEGER_TYPE') {
      if (scopeType === 'TEXT_TYPE') {
        itemProps.valueType = 'digit';
        itemProps.precision = 0;
      } else if (scopeType === 'ARRAY_TYPE') {
        itemProps.valueType = 'select';
        itemProps.dataSource = Array.isArray(scopeList)
          ? scopeList
          : [scopeList];
      }
    } else if (valueType === 'FLOAT_TYPE') {
      if (scopeType === 'TEXT_TYPE') {
        itemProps.valueType = 'digit';
        itemProps.precision = 2;
      } else if (scopeType === 'ARRAY_TYPE') {
        itemProps.valueType = 'select';
        itemProps.dataSource = Array.isArray(scopeList)
          ? scopeList
          : [scopeList];
      }
    }

    return itemProps;
  };

  return (
    <Block
      scroll={{
        y: true,
      }}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type="primary" onClick={handleSubmit}>
            保存
          </Button>
        </div>
      }
      className="aspirin-business-application-parameter-block"
    >
      <div className="aspirin-business-application-parameter">
        <VSControlledForm
          id="BusinessParameterForm"
          onChange={(_value) => {
            props.onChange?.(_value);
          }}
          value={formFieldValue}
        >
          {formFieldData &&
            (Object.keys(formFieldData) ?? [])?.map((item) => {
              return (
                <div>
                  <div className="group-title">{
                    [undefined, null, 'null', 'undefined'].includes(item) ? '未分组' : item
                  }</div>
                  <>
                    {formFieldData?.[item]?.map((fItem) => {
                      return (
                        <VSFormLayout columnCount={1} labelWidth={250}>
                          <VSFormItem
                            label={fItem?.localConfig?.cnName}
                            name={[fItem?.localConfig?.name]}
                            {...getFormItemType(fItem)}
                          ></VSFormItem>
                        </VSFormLayout>
                      );
                    })}
                  </>
                </div>
              );
            })}
        </VSControlledForm>
      </div>
    </Block>
  );
};
export default definePage(StandardApplicationParameter);
