import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Popover,
  Row,
  Space,
  Steps,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { divide, set } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
const { RangePicker } = DatePicker;

const timeArr = [
  { label: '三个月', value: 3 },
  { label: '半年', value: 6 },
  { label: '一年', value: 12 },
];

const Index = (props) => {
  // console.log(props, 'props');

  const { analysisParams, handelConditionQuery } = props;

  // const chartTime = data?.createdAt;
  // const chartTitle = data?.[0]?.reportItemName;
  // 报告时间范围
  const [timeRage, setTimeRage] = useState<any>();
  const [timeRageValue, setTimeRageValue] = useState<any>();
  // 时间
  const [radioValue, setRadioValue] = useState<any>();
  const [searchList, setSearchList] = useState<any>();
  const [data, setData] = useState<any>();
  // const [currentTimeValue, setCurrentTimeValue] = useState<any>();
  // const min = data?.reportItem?.limitStart;
  // const max = data?.reportItem?.limitEnd;
  // const info = JSON.parse(data?.reportItem?.cdss || '[]');

  // const info1 = info?.filter(
  //   (item) => item?.title !== '临床意义' && item?.title !== '治疗建议',
  // );
  // const info2 = info?.filter(
  //   (item) => item?.title === '临床意义' || item?.title === '治疗建议',
  // );

  // const chartData = Array.from({ length: 4 })?.map((item, index) => {
  //   if (index === 0) {
  //     return {
  //       value: min - 1,
  //       satisfyCondition: false,
  //     };
  //   } else if (index === 3) {
  //     return {
  //       value: data?.reportItem?.result,
  //       satisfyCondition: false,
  //     };
  //   } else
  //     return {
  //       value: Math.floor(Math.random() * (max - min + 1)) + min,
  //       satisfyCondition: true,
  //     };
  // });
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const target = document.getElementById('echarts-demo');
    if (target) {
      if (!chartRef.current) {
        chartRef.current = echarts.init(target);
      }

      const xDataStart = dayjs(data?.[0]?.resultDateTime).format('YYYY-MM-DD');
      const xDataEnd = dayjs(data?.[data?.length - 1]?.resultDateTime).format(
        'YYYY-MM-DD',
      );
      const centerTime = dayjs(
        ((dayjs(xDataStart).unix() + dayjs(xDataEnd).unix()) / 2) * 1000,
      ).format('YYYY-MM-DD');

      const xData =
        data?.length >= 3
          ? [xDataStart, centerTime, xDataEnd]
          : data?.map((item) => {
              return item?.resultDateTime;
            });

      const yData = data?.map((item) => {
        return item?.result;
      });
      const yMinData = data?.map((item) => {
        return item?.resultReferenceLow;
      });
      const yMaxData = data?.map((item) => {
        return item?.resultReferenceHigh;
      });

      const option = {
        xAxis: {
          type: 'category',
          axisLine: {
            show: false, // 隐藏 x 轴线
          },
          // show: false,
          axisLabel: {
            show: true, // 显示 x 轴刻度标签
          },
          splitLine: {
            // type: 'dashed',
            show: false, // 隐藏 x 轴分隔线
          },
          axisTick: {
            show: false, // 隐藏底部的刻度线
          },
          // data: [
          //   // dayjs(chartTime).subtract(21, 'day').format('YYYY-MM-DD'),
          //   // dayjs(chartTime).subtract(14, 'day').format('YYYY-MM-DD'),
          //   // dayjs(chartTime).subtract(7, 'day').format('YYYY-MM-DD'),
          //   // dayjs(chartTime).format('YYYY-MM-DD'),
          // ],
          data: xData,
          // itemStyle: {
          //   color: function (params) {
          //     // 根据 satisfyCondition 属性的值来设置颜色
          //     if (params.data.satisfyCondition) {
          //       return 'blue'; // 满足条件的点为蓝色
          //     } else {
          //       return 'red'; // 不满足条件的点为红色
          //     }
          //   },
          // },
        },
        yAxis: {
          type: 'value',
          // axisLine: { onZero: false },
          axisLine: {
            show: true,
            lineStyle: {
              // color: '#5D6268',
              width: 1,
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        tooltip: {
          trigger: 'axis', // 使用 axis 触发器
          axisPointer: {
            type: 'line', // 设置为 'line' 类型
          },
        },
        grid: {
          bottom: 40, // 调整底部留白，确保 x 轴标签和刻度线完整显示
        },
        series: [
          {
            // data: chartData,
            data: yData,
            type: 'line',
            name: '检验结果',
          },
          {
            // data: chartData,
            data: yMinData,
            type: 'line',
            name: '最小值',
          },
          {
            // data: chartData,
            data: yMaxData,
            type: 'line',
            name: '最大值',
          },
        ],
      };
      chartRef.current && chartRef.current.setOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handelTime = (type) => {
    setSearchList({
      time: 'undefined',
      radioValue: dayjs().subtract(type, 'month').format('YYYY-MM-DD 00:00:00'),
    });
    setRadioValue(type);
  };
  const getAnalysisData = useCallback(async () => {
    if (!analysisParams?.patientId) return;
    const res = await vsf?.services?.PatientJourneyController_analysis_61065d({
      patientId: analysisParams?.patientId,
      qto: {
        resultDateTimeRangeIn: searchList?.radioValue
          ? {
              begin: searchList?.radioValue,
              beginInclude: true,
            }
          : searchList?.time?.start
          ? {
              begin: searchList?.time?.start,
              end: searchList?.time?.end,
              beginInclude: true,
              endInclude: true,
            }
          : {},
        reportItemCodeIs: analysisParams?.data?.reportItemCode,
      },
    });
    if (res?.code === 200) {
      setData(res?.data);
    }
  }, [analysisParams, searchList]);

  useEffect(() => {
    getAnalysisData();
  }, [getAnalysisData]);

  return (
    <div style={{ padding: '24px', paddingTop: '12px', width: 638 }}>
      <div className="box">检验结果趋势图({data?.[0]?.reportItemName})</div>
      <div style={{ marginTop: '24px' }}>
        <div
          style={{
            display: 'flex',
            paddingRight: '12px',
            alignItems: 'center',
          }}
        >
          {/* <Form layout={'inline'}>
          <Form.Item label="检验日期"> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '24px' }}>检验日期</span>
            <RangePicker
              style={{ width: '224px' }}
              placeholder={['开始时间', '结束时间']}
              onChange={(value) => {
                // console.log(value);

                setTimeRageValue(value);
                if (value?.[0]) {
                  // console.log(111);
                  setTimeRage({
                    start: value?.[0] && value?.[0],
                    end: value?.[0] && value?.[1],
                  });
                } else {
                  setTimeRage(null);
                }
              }}
              value={timeRageValue}
            />
          </div>
          {/* </Form.Item>
           <Form.Item> */}
          {/* <Space size={0}> */}
          <div
            className="chart-searchBtn"
            onClick={() => {
              setRadioValue(undefined);
              setSearchList({
                radioValue: undefined,
                time: {
                  start:
                    timeRage &&
                    dayjs(timeRage?.start).format('YYYY-MM-DD 00:00:00'),
                  end:
                    timeRage &&
                    dayjs(timeRage?.end).format('YYYY-MM-DD 23:59:59'),
                },
              });
            }}
          >
            查询
          </div>
          {timeArr.map((item) => {
            return (
              <div
                className="chart-searchBtn_btn-item"
                style={{
                  border:
                    radioValue === item?.value
                      ? '1px solid #3276e8'
                      : '1px solid #85898d',
                }}
                key={item?.value}
                onClick={() => {
                  setTimeRageValue(null);
                  handelTime(item?.value);
                }}
              >
                {item?.label}
              </div>
            );
          })}
          {/* </Space>
          </Form.Item>
        </Form> */}
        </div>
      </div>
      <div className="echars" id="echarts-demo"></div>
      {/* <div className="box">指标分析</div>
      {info1?.map((item, index) => (
        <div className="text-item" key={index}>
          <div className="text-item-title">{item?.title}</div>
          <div className="text-item-value">{item?.content}</div>
        </div>
      ))}
      {info2?.map((item, index) => (
        <div className="text-item-2" key={index}>
          <div className="text-item-title-2">{item?.title}</div>
          <div className="text-item-value-2">{item?.content}</div>
        </div>
      ))} */}
      {/* <div className="result">结果分析</div>
      <div className="ltfv">低于正常值:</div>
      <div className="ltfv">(1)病理性减少:</div>
      <div className="choose">
        <ul>
          <li>A.骨髓造血功能衰竭，如再生障碍性贫血、</li>
          <li>
            B.因造血物质缺乏或利用障碍所致的贫血，如缺铁性贫血、叶酸及维生素B12
            缺乏所致的巨幼细胞性贫血;
          </li>
          <li>
            C.因红细胞膜、酶溃传性的缺陷或外来因素所致红细胞破坏过多而导致的贫血，如遗传性球形红细胞增多症、海洋性贫血、阵发性睡眠性血红蛋白尿、异常血红蛋白病、免疫性溶血性贫血、心脏体外循环的大手术或某些生物性和化学性等因素所致的溶血性贫血以及某些急性或慢性失血所致的贫血。
          </li>
        </ul>
      </div>
      <div className="suggestion">治疗建议</div>
      <div className="suggestion-text">
        重度贫血。如无输血禁忌，应予输血治疗。
      </div> */}
    </div>
  );
};

export default Index;
