import vsf, { definePage } from '@vs/vsf-boot';
import {
  Table,
  VSTableColumn,
  VSPromiseTable,
  Button,
  Todo,
  VSTableToolbar,
  VSTableAddition,
  Row,
  Col,
  message,
  VSForm,
  VSFormLayout,
  VSFormItem,
  Space,
} from '@vs/vsf-kit';
import Loading from '@/module/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { useAntdTable, useRequest } from 'ahooks';
import IntegerRule from './index';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';

const DrugFormVsDispenseRoundingSettingList = (props) => {
  const { open } = props;
  useEffect(() => {
    console.log('-----');
    console.log(open);
  });
  /**
   * convertGetAllDrugVsDispenseRoundingSettingQtoRes2TableDrugVsDispenseRoundingSettingRo
   * @param {any} input
   * @returns {any}
   */
  const convertGetAllDrugVsDispenseRoundingSettingQtoRes2TableDrugVsDispenseRoundingSettingRo =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ..._.get(input, 'data').map((d) => {
          const obj = {};
          obj.drugName = _.get(d, 'drugProductInfo.drugInfo.drugName');
          obj.drugProductName = _.get(d, 'drugProductInfo.drugProductName');
          obj.drugSpecification = _.get(d, 'drugProductInfo.drugSpecification');
          obj.firmName = _.get(d, 'drugProductInfo.drugFirmInfo.firmName');
          obj.roundingTypeOutp = _.get(d, 'roundingTypeOutp');
          obj.roundingTypeInp = _.get(d, 'roundingTypeInp');
          obj.drugProductId = _.get(d, 'drugProductInfo.id');
          obj.id = _.get(d, 'id');
          obj.clinicType = _.get(d, 'clinicType');
          return obj;
        }),
      );
      return output;
    };
  /**
   * convertTableDrugVsDispenseRoundingSettingRo2SaveDrugVsDispenseRoundingSettingReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableDrugVsDispenseRoundingSettingRo2SaveDrugVsDispenseRoundingSettingReq =
    (input) => {
      const _ = window._ ?? {};
      const output =
        input?.map((d) => {
          const obj = {
            id: d.id,
            drugProductId: d.drugProductId,
            roundingTypeOutp: d.roundingTypeOutp,
            roundingTypeInp: d.roundingTypeInp,
          };
          return obj;
        }) ?? [];
      return output;
    };

  const convertQueryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQtoRes2TableDrugVsDispenseRoundingSettingRo =
    (input) => {
      const _ = window._ ?? {};
      const output = []; // TODO 请确认下行字段是否符合逻辑
      output.push(
        ..._.get(input, 'data.result').map((d, i) => {
          const obj = {}; // TODO 请确认下行字段是否符合逻辑
          obj.drugName = _.get(d, 'drugInfo.drugName');
          obj.drugProductName = _.get(d, 'drugProductName');
          obj.drugSpecification = _.get(d, 'drugSpecification');
          obj.firmName = _.get(d, 'drugFirmInfo.firmName');
          obj.drugProductId = _.get(d, 'id');
          return obj;
        }),
      );
      return output;
    };

  const Selected = ({ recode, onChange, form, value }) => {
    const [dataSource, setDataSource] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState();
    const [inputCodeLike, setInputCodeLike] = useState();
    const [scrollIdData, setScrollIdData] = useState();
    const [total, setTotal] = useState();
    const onPaginationChange = (pagination) => {
      console.log(pagination);
      setPaginationInfo(pagination);
    };
    useEffect(() => {
      getDataSource({
        paginationInfo: paginationInfo,
        inputCodeLike: inputCodeLike,
      });
      console.log(inputCodeLike);
    }, [paginationInfo, inputCodeLike]);
    const { loading, run: getDataSource } = useRequest(
      async (param) => {
        const qto = {
          from: param?.paginationInfo?.current || 0,
          size: 10,

          inputCodeLike: param?.inputCodeLike,
        };

        if (param?.inputCodeLike || param?.inputCodeLike === '') {
          delete qto?.scrollId;
          param?.inputCodeLike === '' && delete qto.inputCodeLike;
        } else {
          qto.scrollId = scrollIdData;
        }

        const res =
          await vsf.services?.DrugDrugController_queryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQto_7eafe8?.(
            {
              qto,
            },
          );
        setScrollIdData(res?.data?.scrollId);
        setTotal(res.data?.count);
        setDataSource(
          res.data?.result?.map((item, index) => {
            return { key: index, ...item };
          }),
        );
      },
      {
        debounceWait: 500,
        manual: true,
      },
    );
    const columns = [
      {
        title: '药品名称',
        dataIndex: ['drugInfo', 'drugName'],
        key: 'drug_name',
      },
      {
        title: '商品名称',
        dataIndex: ['drugProductName'],
        key: 'product_name',
      },
      {
        title: '包装规格',
        dataIndex: ['drugSpecification'],
        key: 'package_specification',
      },
      {
        title: '生产厂家',
        dataIndex: ['drugFirmInfo', 'firmName'],
        key: 'firm_name',
      },
    ];
    return (
      <ComplexSelect
        hideSelectIcon
        mode="radio"
        showLabel="drugName"
        value={value?.label ?? value}
        onChange={(value) => {
          form.setFieldValue(recode?.recordKey, {
            firmName: value?.drugFirmInfo?.firmName,
            drugProductName: value?.drugProductName,
            drugSpecification: value?.drugSpecification,
            drugProductId: value?.id,
          });
          onChange({
            label: value.drugInfo.drugName,
            value: value.id,
          });
        }}
        onSearch={(name) => {
          // if (name.trim()) {
          //   // getDataSource(name);
          //   setInputCodeLike(name);
          // }
          setInputCodeLike(name);
        }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          width: 700,
        }}
        listHeight={1000}
      >
        <Table
          rowClassName="aspirin-table-row-box"
          columns={columns}
          pagination={{ total: total }}
          loading={{
            indicator: (
              <Row
                justify="center"
                style={{
                  left: '40%',
                }}
              >
                <Col span={24}>
                  <Loading />
                </Col>
              </Row>
            ),
            spinning: loading,
          }}
          scroll={{
            y: 400,
          }}
          onChange={onPaginationChange}
          dataSource={dataSource}
          // {...tableProps}
        />
      </ComplexSelect>
    );
  };

  const Selected1 = ({ recode, onChange, form, value, tableParamChange }) => {
    const [dataSource, setDataSource] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState();
    const [inputCodeLike, setInputCodeLike] = useState();
    const [scrollIdData, setScrollIdData] = useState();
    const [total, setTotal] = useState();
    const onPaginationChange = (pagination) => {
      console.log(pagination);
      setPaginationInfo(pagination);
    };
    useEffect(() => {
      getDataSource({
        paginationInfo: paginationInfo,
        inputCodeLike: inputCodeLike,
      });
      console.log(inputCodeLike);
    }, [paginationInfo, inputCodeLike]);
    const { loading, run: getDataSource } = useRequest(
      async (param) => {
        const qto = {
          from: param?.paginationInfo?.current || 0,
          size: 10,

          inputCodeLike: param?.inputCodeLike,
        };

        if (param?.inputCodeLike || param?.inputCodeLike === '') {
          delete qto?.scrollId;
          param?.inputCodeLike === '' && delete qto.inputCodeLike;
        } else {
          qto.scrollId = scrollIdData;
        }

        const res =
          await vsf.services?.DrugDrugController_queryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQto_7eafe8?.(
            {
              qto,
            },
          );
        setScrollIdData(res?.data?.scrollId);
        setTotal(res.data?.count);
        setDataSource(
          res.data?.result?.map((item, index) => {
            return { key: index, ...item };
          }),
        );
      },
      {
        debounceWait: 500,
        manual: true,
      },
    );
    const columns = [
      {
        title: '药品名称',
        dataIndex: ['drugInfo', 'drugName'],
        key: 'drug_name',
      },
      {
        title: '商品名称',
        dataIndex: ['drugProductName'],
        key: 'product_name',
      },
      {
        title: '包装规格',
        dataIndex: ['drugSpecification'],
        key: 'package_specification',
      },
      {
        title: '生产厂家',
        dataIndex: ['drugFirmInfo', 'firmName'],
        key: 'firm_name',
      },
    ];
    return (
      <ComplexSelect
        hideSelectIcon
        mode="radio"
        showLabel="drugName"
        value={value?.label ?? value}
        onChange={(value) => {
          vsf?.refs?.integerSelectForm1?.setFieldValue(recode?.recordKey, {
            inputCodeLike: value?.drugFirmInfo?.firmName,
            // drugProductName: value?.drugProductName,
            // drugSpecification: value?.drugSpecification,
            // drugProductId: value?.id,
          });
          onChange({
            label: value.drugInfo.drugName,
            value: value.id,
          });
        }}
        onSearch={(name) => {
          // if (name.trim()) {
          //   // getDataSource(name);
          //   setInputCodeLike(name);
          // }
          setInputCodeLike(name);
        }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          width: 700,
        }}
        listHeight={1000}
        placeholder="请输入药品名称"
      >
        <Table
          rowClassName="aspirin-table-row-box"
          columns={columns}
          pagination={{ total: total }}
          loading={{
            indicator: (
              <Row
                justify="center"
                style={{
                  left: '40%',
                }}
              >
                <Col span={24}>
                  <Loading />
                </Col>
              </Row>
            ),
            spinning: loading,
          }}
          scroll={{
            y: 400,
          }}
          onChange={onPaginationChange}
          dataSource={dataSource}
          // {...tableProps}
        />
      </ComplexSelect>
    );
  };

  const onRemove = async (params) => {
    const res =
      await vsf.services?.DrugFormVsDispenseRoundingSettingController_deleteDrugVsDispenseRoundingSetting_6ae6b6?.(
        {
          deleteRounding: { ...params, clinicType: partial },
        },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      vsf?.refs?.tableDrugVsDispenseRoundingSetting?.reload();
    }
    return res?.code === 200;
  };
  const [flagStatus, setFlagStatus] = useState(1);
  const [partial, setPartial] = useState(props.open);
  const [dataSource, setDataSource] = useState();
  const [dosage, setDosage] = useState();
  const [roundingTypeIs, setRoundingTypeIs] = useState();
  const { data: drugNameDataSource, run } = useRequest(async (params) => {
    const res =
      await vsf.services?.DrugDrugController_queryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQto_7eafe8?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 10000,
          },
          ext: {},
        },
      );
    const output =
      convertQueryPagefallByQueryDrugProductDictionaryNameFormQtoByQueryDrugProductDictionaryNameFormQtoRes2TableDrugVsDispenseRoundingSettingRo(
        res,
      );
    // console.log(output);
    return output;
  });
  const onFetch = useCallback(
    async (params) => {
      let res =
        await vsf.services?.DrugFormVsDispenseRoundingSettingController_getAllDrugVsDispenseRoundingSettingQto_062dbf?.(
          {
            qto: {
              ...params?.search,
              roundingTypeIs: roundingTypeIs,
              inputCodeLike: dosage,
              clinicTypeIs: partial,
            },
          },
        );

      if (dosage) {
        const file = JSON.parse(JSON.stringify(res));
        const data = file.data.map((item) => {
          if (item?.drugProductInfo?.drugInfo?.drugName === dosage) {
            return item;
          }
        });
        const data1 = data.filter((item) => {
          return item;
        });
        res = { ...res, data: data1 };
      }

      const output =
        convertGetAllDrugVsDispenseRoundingSettingQtoRes2TableDrugVsDispenseRoundingSettingRo(
          res,
        );

      return {
        data: output,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [dosage, roundingTypeIs, partial],
  );

  const tableParamChange = (p) => {
    setTableParam(p);
  };
  return (
    <>
      {flagStatus ? (
        <div className="Integer-layout">
          <div className="table-content">
            <Row>
              <Col span={12}>
                <VSForm id="integerSelectForm1">
                  <VSFormLayout columnCount={5}>
                    <VSFormItem
                      name={'clinicTypeIs'}
                      valueType="radioButton"
                      dataSource={[
                        { label: '门诊', value: 'OUTP' },
                        { label: '住院', value: 'INP' },
                      ]}
                      fieldProps={{
                        defaultValue: partial,
                        onChange: (v) => {
                          setPartial(v);
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      name={'flag'}
                      valueType="radioButton"
                      dataSource={[
                        { label: '按剂型', value: 0 },
                        { label: '按药品', value: 1 },
                      ]}
                      fieldProps={{
                        value: flagStatus,
                        defaultValue: flagStatus,
                        onChange: (v) => {
                          setFlagStatus(v);
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label={'药品名称'}
                      name={'inputCodeLike'}
                      valueType="costom"
                      style={{ width: '300px' }}
                    >
                      <Selected1 tableParamChange={tableParamChange} />
                    </VSFormItem>
                    <VSFormItem
                      label={'摆药取整'}
                      name={'roundingTypeIs'}
                      valueType="select"
                      dataSource={[
                        { label: '按次取整', value: 'BY_TIMES' },
                        { label: '按天取整', value: 'BY_DAY' },
                        { label: '按量取整', value: 'BY_AMOUNT' },
                      ]}
                    ></VSFormItem>
                  </VSFormLayout>
                </VSForm>
              </Col>
              <Col span={12}>
                <div style={{ textAlign: 'right' }}>
                  <Space size={4}>
                    <Button
                      onClick={() => {
                        vsf?.refs?.integerSelectForm1?.resetFields();
                        setDosage(undefined);
                        setRoundingTypeIs(undefined);
                      }}
                    >
                      重置
                    </Button>
                    <Button
                      type="primary"
                      onClick={(v) => {
                        const dosageFormCodeIs =
                          vsf?.refs?.integerSelectForm1?.getFieldValue(
                            'inputCodeLike',
                          )?.label;
                        if (dosageFormCodeIs) {
                          setDosage(dosageFormCodeIs);
                        } else {
                          setDosage(undefined);
                        }

                        const roundingType =
                          vsf?.refs?.integerSelectForm1?.getFieldValue(
                            'roundingTypeIs',
                          );
                        if (roundingType) {
                          setRoundingTypeIs(roundingType);
                        } else {
                          setRoundingTypeIs(undefined);
                        }
                      }}
                    >
                      查询
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>

            <VSPromiseTable
              editable={{
                editType: 'single',
                onCanDelete: () => null,
                editText: <Icon type="icon-bianji" size={24} />,
                extraOperations: [
                  {
                    children: <Icon type="icon-shanchu1" size={24} />,
                    order: 1,
                    onClick: (event, data) => {
                      Tip({
                        content: `是否删除${data?.drugProductName}]`,
                        onOk: () => {
                          onRemove(data);
                        },
                      });
                    },
                  },
                ],
              }}
              vsid="05798"
              id="tableDrugVsDispenseRoundingSetting"
              onFetch={onFetch}
              pagination={false}
              onRecord={() => ({ id: 'create_' + Math.random() })}
              className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
            >
              <VSTableColumn
                dataIndex={['clinicType']}
                valueType="radioButton"
                // search
                order={0}
                hideInTable
                dataSource={[
                  { label: '门诊', value: 'OUTP' },
                  { label: '住院', value: 'INP' },
                ]}
                fieldProps={{
                  defaultValue: open || 'OUTP',
                  allowClear: false,
                  onChange: (v) => {
                    setPartial(v);
                  },
                }}
              />

              <VSTableColumn
                valueType="radioButton"
                // search
                order={0}
                columnKey={'clinicTypeIs'}
                hideInTable
                dataSource={[
                  { label: '按剂型', value: 0 },
                  { label: '按药品', value: 1 },
                ]}
                fieldProps={{
                  value: flagStatus,
                  onChange: (v) => {
                    setFlagStatus(v);
                  },
                }}
              />

              <VSTableColumn
                dataIndex={['drugName']}
                title="药品名称"
                valueType="select"
                // search
                order={0}
                columnKey={'drugProductIdIs'}
                renderFormItem={(...arr) => {
                  const [schema, recode, form] = arr;
                  return (
                    <Selected
                      recode={recode}
                      form={form}
                      onChange={schema.originProps.onChange}
                    />
                  );
                }}
              />
              {/* <VSTableColumn
                dataIndex={['inputCodeLike']}
                title="药品名称"
                valueType="text"
                // search
                hideInTable
                order={0}
                formItemProps={{}}
                fieldProps={{}}
              /> */}
              <VSTableColumn
                dataIndex={['roundingTypeIs']}
                title="摆药取整"
                valueType="select"
                // search
                order={0}
                columnKey={'roundingTypeIs'}
                hideInTable
                dataSource={[
                  { label: '按次取整', value: 'BY_TIMES' },
                  { label: '按天取整', value: 'BY_DAY' },
                  { label: '按量取整', value: 'BY_AMOUNT' },
                ]}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['drugProductId']}
                valueType="digit"
                hideInTable
              />

              <VSTableColumn
                dataIndex={['drugProductName']}
                title="商品名称"
                // preview

                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                fieldProps={{
                  disabled: true,
                }}
              />

              <VSTableColumn
                dataIndex={['drugSpecification']}
                title="规格"
                valueType="text"
                preview
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['firmName']}
                title="厂家"
                valueType="text"
                preview
                formItemProps={{}}
                fieldProps={{}}
              />

              {partial === 'OUTP' && (
                <VSTableColumn
                  dataIndex={['roundingTypeOutp']}
                  title="门诊摆药取整方式"
                  valueType="select"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  dataSource={[
                    { label: '按次取整', value: 'BY_TIMES' },
                    { label: '按天取整', value: 'BY_DAY' },
                    { label: '按量取整', value: 'BY_AMOUNT' },
                  ]}
                  fieldProps={{}}
                />
              )}
              {partial === 'INP' && (
                <VSTableColumn
                  dataIndex={['roundingTypeInp']}
                  title="住院摆药取整方式"
                  valueType="select"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  dataSource={[
                    { label: '按次取整', value: 'BY_TIMES' },
                    { label: '按天取整', value: 'BY_DAY' },
                    { label: '按量取整', value: 'BY_AMOUNT' },
                  ]}
                  fieldProps={{}}
                />
              )}

              <VSTableToolbar title="按药品取整规则配置菜单">
                <Button
                  children="保存"
                  onClick={async (params) => {
                    //
                    var output =
                      convertTableDrugVsDispenseRoundingSettingRo2SaveDrugVsDispenseRoundingSettingReq(
                        vsf.refs.tableDrugVsDispenseRoundingSetting.getValues(),
                      );

                    const formatData = output.map((d) => {
                      const dict = {
                        ...d,
                        clinicRegisterTypeId: props.value?.id,
                        priceItemId: d.priceItem?.id,
                        roundingTypeOutp: d.roundingTypeOutp || null,
                        roundingTypeInp: d?.roundingTypeInp || null,
                      };
                      if (('' + d.id).startsWith('create_')) {
                        const { id, ...rest } = dict;
                        return rest;
                      } else {
                        return dict;
                      }
                    });
                    const res =
                      await vsf.services?.DrugFormVsDispenseRoundingSettingController_saveDrugVsDispenseRoundingSetting_078431?.(
                        {
                          // TODO: 请检查以下这部分传参是否符合需求
                          saveRounding: formatData,
                          extApiParam: { isOutp: partial == 'OUTP' },
                        },
                      ); // TODO: 你可能需要整理返回数据格式西药房发药
                    if (res?.code === 200) {
                      message.success('保存成功');
                      vsf?.refs?.tableDrugVsDispenseRoundingSetting?.reload();
                    }
                    return res?.data;
                  }}
                />
                <VSTableAddition children="添加" position="top" />
              </VSTableToolbar>
            </VSPromiseTable>
          </div>
        </div>
      ) : (
        <IntegerRule open={partial} />
      )}
    </>
  );
};
export default definePage(DrugFormVsDispenseRoundingSettingList);
