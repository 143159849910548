import { createServices } from '@vs/vsf-boot';
export default createServices({
  FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6: {
    method: 'post',
    url: '/api/clinic-record-order-template/setting-favorite-order-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
      itemId: number;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0: {
    method: 'post',
    url: '/api/clinic-record-order-template/cancel-favorite-order-item-by-item-id',
    parameterFomatter: (data?: {
      itemClass: ClinicItemClassEnum;
      itemId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  FavoriteOrderItemSettingController_cancelFavoriteOrderItemById_1a3c12: {
    method: 'post',
    url: '/api/clinic-record-order-template/cancel-favorite-order-item-by-id',
    parameterFomatter: (data?: { favoriteOrderItemId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  FavoriteOrderItemSettingController_getFavoriteDrug_bffb8b: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-favorite-drug',
    parameterFomatter: (data?: { favoriteType: FavoriteTypeEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: FavoriteOrderItemSettingDrugVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  FavoriteOrderItemSettingController_updateFavoriteOrderItemSort_5056c7: {
    method: 'post',
    url: '/api/clinic-record-order-template/update-favorite-order-item-sort',
    parameterFomatter: (data?: {
      itemClass: ClinicItemClassEnum;
      itemId: number;
      favoriteType: FavoriteTypeEnum;
      sortNumber: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemController_getFavoriteClinicItem_fa660a: {
    method: 'post',
    url: '/api/clinic-item-base/get-favorite-clinic-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  FavoriteOrderItemSettingController_cancelFavoriteOrderItem_5db34a: {
    method: 'post',
    url: '/api/clinic-record-order-template-entrance-web/cancel-favorite-order-item',
    parameterFomatter: (data?: {
      itemClass: ClinicItemClassEnum;
      itemId: number;
      favoriteType: FavoriteTypeEnum;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
