import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleController_queryInpSettleMasterDetail_0051ee: {
    method: 'post',
    url: '/api/inp-billing/query-inp-settle-master-detail',
    parameterFomatter: (data?: {
      qto: InpSettleMasterListQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InpSettleMasterVoInpBillingEntranceWebVoInpsettlemasterdetailvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
