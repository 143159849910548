import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_auditClinicItem_a1c916: {
    method: 'post',
    url: '/api/clinic-item-base/audit-clinic-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_addAndUpdateClinicCharge_14cb6a: {
    method: 'post',
    url: '/api/clinic-item-base/add-and-update-clinic-charge',
    parameterFomatter: (data?: {
      addClinicChargeBtoList: AddClinicChargeBtoClinicItemBaseServiceBto[];
      updateClinicChargeBtoList: UpdateClinicChargeBtoClinicItemBaseServiceBto[];
      removeClinicChargeBtoList: RemoveClinicChargeBtoClinicItemBaseServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getChargeByClinicItemId_03609f: {
    method: 'post',
    url: '/api/clinic-item-base/get-charge-by-clinic-item-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemVsChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
});
