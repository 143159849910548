import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_getExpenseList_7847bb: {
    method: 'post',
    url: '/api/inp-billing/get-expense-list',
    parameterFomatter: (data?: {
      inpBillingDetailSimpleQto: InpBillingDetailSimpleQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpExpenseDetailListVoInpBillingEntranceWebVo[],
    ) => data,
  },
});
