import {
  Input,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import Loading from '@/module/Loading';

import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import ButtonAndKeyDown, {
  ButtonAndKeyDownProps,
} from '@/pages/Index/components/button_and_keydown';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { getScrollY } from '@/utils';

import {
  positive,
  positiveMessage,
  height,
  heightMessage,
  positiveInteger,
  positiveIntegerMessage,
} from '@/pattern';
import {
  convertQueryByInpVisitHospitalWithPatientQtoRes2InpVisitHospitalList,
  convertQueryNewbornRecordInfoVoByNewbornRecordInfoQtoRes2NewbornRecordForm,
  convertNewbornRecordForm2NewbornRecordFuncFlowExecutorReq,
} from './convert';
import Info from '@/pages/Index/cpoe/center/header/Info/in_info';

const Index = (props) => {
  const { stores } = props;
  const {
    user,
    common: { currentApplication },
  } = stores;
  const { inVisitInfo, initKey } = user;
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    onFetch_t1563();
  }, []);

  // table list
  const onFetch_t1563 = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ClinicInpVisitController_queryByInpVisitHospitalWithPatientQto_ab5a9c?.(
          {
            qto: {
              wardId: currentApplication?.department?.id,
            },
          },
        ); // TODO: 请确认入参来源及出参用法
      const output =
        convertQueryByInpVisitHospitalWithPatientQtoRes2InpVisitHospitalList?.(
          res,
        );
      return {
        data: output ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [currentApplication],
  );

  // form
  const getForm = useCallback(async (params) => {
    if (params?.length <= 0) {
      setIsEmpty(true);
      return vsf.refs?.newbornForm?.setFieldsValue(null);
    }
    setIsLoading(true);
    const res =
      await vsf?.services?.ClinicInpVisitRecordController_queryNewbornRecordInfoVoByNewbornRecordInfoQto_cc74f1?.(
        {
          qto: {
            motherInpVisitPatientDisplayIdIs: params,
            motherInpVisitPatientIdIs: params,
          },
        },
      );
    const output =
      convertQueryNewbornRecordInfoVoByNewbornRecordInfoQtoRes2NewbornRecordForm?.(
        res,
      );
    setIsLoading(false);

    if (res?.code == 200 && res?.data?.length > 0) {
      setIsEmpty(false);
      vsf.refs?.newbornForm?.setFieldsValue(output);
    } else {
      setIsEmpty(true);
    }
    return output;
  }, []);

  // 接生者
  const getUserStaff = async (param) => {
    const res =
      await vsf?.services?.UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d?.(
        {
          qto: {
            jobClassCodeIs: 'A2',
            inputCodeLike: param?.staffName,
            inputCodeWubiLike: param?.staffName,
            inputCodeLike: param?.staffName,
          },
        },
      );
    return res?.data;
  };

  // save
  const save = async (values) => {
    const _res = vsf.refs?.newbornDetailform?.getFieldsValue();
    // TODO: 请确认入参来源及出参用法
    const output =
      convertNewbornRecordForm2NewbornRecordFuncFlowExecutorReq?.(_res);
    console.log(_res, output);
    return;
    const res =
      await vsf?.services?.ClinicInpVisitRecordController_newbornRecordFuncFlowExecutor_216df0?.(
        {
          saveNewbornRecordBto: output?.saveNewbornRecordBto,
          createPatientBto: output?.createPatientBto,
        },
      );
    return res;
  };

  const Footer = () => {
    const button = [
      {
        children: '保存',
        methodKey: 'altKey',
        ordinaryKey: 'S',
        onClick: () => save(),
      },
      {
        children: '打印腕带',
        methodKey: 'altKey',
      },
      {
        children: '清屏',
        methodKey: 'altKey',
        ordinaryKey: 'R',
        onClick: () => vsf.refs?.newbornDetailform?.resetFields(),
      },
      {
        children: '上报妇幼',
        methodKey: 'altKey',
        ordinaryKey: 'W',
        onClick: () => vsf.refs?.newbornDetailform?.resetFields(),
      },
    ];
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {button?.map((item, index) => (
          <ButtonAndKeyDown
            className="global_ant_btn"
            {...item}
            key={index}
            type="primary"
          />
        ))}
      </div>
    );
  };

  return (
    <div className="newborn_registration ">
      <div className="newborn_registration_left golbal_background_color">
        <VSTable
          scroll={{
            y: 436,
          }}
          pagination={false}
          value={tableList}
          className="aspirin-table aspirin-table-nopadding"
          rowClassName={(v) =>
            v.id == currentRow?.id ? 'aspirin-collect-table-bgc' : ''
          }
          onRow={(record) => ({
            onDoubleClick: () => {
              setCurrentRow(record);
              getForm?.(record?.displayId);
            },
          })}
        >
          <VSTableColumn
            dataIndex={['bedLabel']}
            title="床号"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['displayId']}
            title="患者ID"
            valueType="text"
            fieldProps={{}}
          />
        </VSTable>
      </div>
      <div className="newborn_registration_right">
        <div className="newborn_registration_right_search golbal_background_color">
          <VSForm id="newbornForm" labelAlign="left">
            <VSFormLayout
              gutter={50}
              columnCount={2}
              className="form_label_width_100"
            >
              <VSFormItem
                valueType="text"
                label="母亲序号"
                name={['index']}
                fieldProps={{
                  onPressEnter: (v) => {
                    getForm(v?.target?.value);
                  },
                }}
              />
              <VSFormItem
                valueType="text"
                label="母亲ID"
                name={['id']}
                fieldProps={{
                  onPressEnter: (v) => {
                    getForm(v?.target?.value);
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div className="newborn_registration_right_content golbal_background_color">
          <div className="form">
            {/* {isLoading ? (
              <Loading />
            ) : !isEmpty ? ( */}
            {false ? (
              <Loading />
            ) : !false ? (
              <VSForm labelAlign="left" labelWrap id="newbornDetailform">
                <VSFormLayout
                  columnCount={4}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['motherDisplayId']}
                    label="母亲ID"
                    valueType="text"
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSFormItem
                    name={['babyCount']}
                    label="新生儿编号"
                    valueType="text"
                    fieldProps={{ disabled: true }}
                  />
                  <VSFormItem
                    name={['name']}
                    label="姓名"
                    valueType="text"
                    fieldProps={{ disabled: true }}
                  />

                  <VSFormItem
                    name={['gender']}
                    label="性别"
                    valueType="selectCode"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    required
                  />

                  <VSFormItem
                    name={['birthday']}
                    label="出生日期"
                    valueType="date"
                    fieldProps={{
                      width: '100%',
                      disabledDate: (current) => {
                        return current && current > moment();
                      },
                    }}
                    required
                  />

                  <VSFormItem
                    name={['birthWeek']}
                    label="孕周"
                    valueType="text"
                    fieldProps={{
                      addonAfter: '周',
                    }}
                    rules={[{ pattern: positive, message: positiveMessage }]}
                  />

                  <VSFormItem
                    name={['weight']}
                    label="体重"
                    valueType="text"
                    fieldProps={{
                      addonAfter: '克',
                    }}
                  />

                  <VSFormItem
                    name={['height']}
                    label="身长"
                    valueType="text"
                    fieldProps={{ addonAfter: '厘米' }}
                    rules={[{ pattern: height, message: heightMessage }]}
                  />

                  <VSFormItem
                    name={['healthStatus']}
                    label="健康状况"
                    valueType="selectCode"
                    dataSource={[
                      { label: '良好', value: 'GOOD' },
                      { label: '一般', value: 'GENERAL' },
                      { label: '差', value: 'POOR' },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['birthType']}
                    label="分娩方式"
                    valueType="selectCode"
                    fieldProps={{
                      required: true,
                    }}
                    dataSource={vsf.stores.dbenums?.enums?.BIRTH_TYPE_DICT}
                  />

                  <VSFormItem
                    name={['birthResult']}
                    label="分娩结果"
                    valueType="selectCode"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.BIRTH_RESULT_DICT}
                  />

                  <VSFormItem
                    name={['birthPlaceType']}
                    label="出生地分类"
                    valueType="selectCode"
                    dataSource={[
                      { label: '本院', value: 'INTERNAL' },
                      { label: '妇幼保健院', value: 'MATERNAL_CHILD_HOSPITAL' },
                      { label: '家庭', value: 'HOME' },
                      { label: '其它', value: 'OTHER' },
                    ]}
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['midwifeNurse']}
                    label="接生者"
                    valueType="select"
                    fieldProps={{ showSearch: true }}
                    fieldNames={{ label: 'staffName', value: 'id' }}
                    dataSource={getUserStaff}
                    required
                  />
                </VSFormLayout>
                <VSFormLayout
                  columnCount={2}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['birthPlace']}
                    label="出生地点"
                    valueType="address"
                    fieldProps={{
                      showDetail: true,
                    }}
                  />

                  {/* <VSFormItem
                    name={['birthPlaceOther']}
                    label="详细地址"
                    valueType="text"
                    fieldProps={{}}
                  /> */}
                  <VSFormItem
                    name={['birthInstitutionName']}
                    label="接生机构"
                    valueType="text"
                    fieldProps={{}}
                  />
                </VSFormLayout>
                <VSFormLayout
                  columnCount={4}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['babyDirection']}
                    label="婴儿去向"
                    valueType="selectCode"
                    fieldProps={{}}
                    required
                    dataSource={[
                      { label: '母婴同室病房', value: 'IN_MOTHER_ROOM' },
                      { label: '新生儿科病房', value: 'NEWBORN_ROOM' },
                      { label: '转院', value: 'TRANSFER_HOSPITAL' },
                      { label: '死亡', value: 'DEATH' },
                    ]}
                  />

                  <VSFormItem
                    name={['apgarOneMinute']}
                    label="apgar评分/1min"
                    valueType="text"
                    fieldProps={{ addonAfter: '分' }}
                    rules={[{ pattern: positive, message: positiveMessage }]}
                  />

                  <VSFormItem
                    name={['apgarFiveMinute']}
                    label="apgar评分/5min"
                    valueType="text"
                    fieldProps={{
                      addonAfter: '分',
                    }}
                    rules={[{ pattern: positive, message: positiveMessage }]}
                  />
                </VSFormLayout>
                <VSFormLayout
                  className="marginBottom24"
                  style={{ borderTop: '1px solid #3276e8' }}
                ></VSFormLayout>
                <VSFormLayout
                  columnCount={4}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['motherName']}
                    label="母亲姓名"
                    valueType="text"
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSFormItem
                    name={['motherAge']}
                    label="母亲年龄"
                    valueType="text"
                    fieldProps={{ disabled: true }}
                    rules={[{ pattern: positive, message: positiveMessage }]}
                  />

                  <VSFormItem
                    name={['motherCitizenship']}
                    label="母亲国籍"
                    valueType="selectCode"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.COUNTRY_DICT}
                  />

                  <VSFormItem
                    name={['motherNation']}
                    label="母亲民族"
                    valueType="selectCode"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.NATION_DICT}
                  />

                  <VSFormItem
                    name={['fetusCount']}
                    label="胎儿数"
                    valueType="text"
                    fieldProps={{}}
                    rules={[
                      {
                        pattern: positiveInteger,
                        message: positiveIntegerMessage,
                      },
                    ]}
                  />
                  <VSFormItem
                    name={['pregnantCount']}
                    label="孕"
                    valueType="text"
                    fieldProps={{ addonAfter: '次' }}
                    rules={[
                      {
                        pattern: positiveInteger,
                        message: positiveIntegerMessage,
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['birthCount']}
                    label="产"
                    valueType="text"
                    fieldProps={{
                      addonAfter: '次',
                    }}
                    rules={[
                      {
                        pattern: positiveInteger,
                        message: positiveIntegerMessage,
                      },
                    ]}
                  />
                </VSFormLayout>
                <VSFormLayout
                  columnCount={2}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['motherAddress']}
                    label="母亲地址"
                    valueType="text"
                    fieldProps={{}}
                    required
                  />

                  <VSFormItem
                    name={['motherIdentificationType']}
                    label="母亲有效证件类型"
                    valueType="certificateType"
                    initialValue={{
                      identificationType: '身份证',
                    }}
                  />
                  {/* <VSFormItem
                    name={['motherIdentificationNumber']}
                    label="母亲证件编号"
                    valueType="text"
                    fieldProps={{}}
                  /> */}
                </VSFormLayout>
                <VSFormLayout
                  columnCount={4}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['fatherName']}
                    label="父亲姓名"
                    valueType="text"
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['fatherAge']}
                    label="父亲年龄"
                    valueType="text"
                    fieldProps={{}}
                    rules={[{ pattern: positive, message: positiveMessage }]}
                  />
                  <VSFormItem
                    name={['fatherCitizenship']}
                    label="父亲国籍"
                    valueType="selectCode"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.COUNTRY_DICT}
                  />
                  <VSFormItem
                    name={['fatherNation']}
                    label="父亲民族"
                    valueType="selectCode"
                    dataSource={vsf.stores.dbenums?.enums?.NATION_DICT}
                  />
                </VSFormLayout>
                <VSFormLayout
                  columnCount={2}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['fatherAddress']}
                    label="父亲地址"
                    valueType="text"
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['fatherIdentificationType']}
                    label="父亲有效证件类型"
                    valueType="certificateType"
                    fieldProps={{}}
                    initialValue={{
                      identificationType: '身份证',
                    }}
                  />
                  {/* <VSFormItem
                    name={['fatherIdentificationNumber']}
                    label="父亲证件编号"
                    valueType="text"
                    fieldProps={{}}
                  /> */}
                </VSFormLayout>
                <VSFormLayout
                  columnCount={4}
                  className="form_label_width_150"
                  gutter={50}
                >
                  <VSFormItem
                    name={['birthCertificationNumber']}
                    label="出生证号"
                    valueType="text"
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['dateOfPublish']}
                    label="签发日期"
                    valueType="date"
                    fieldProps={{ width: '100%' }}
                  />
                </VSFormLayout>
              </VSForm>
            ) : (
              <Empty />
            )}
          </div>
          <div className="operation">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
