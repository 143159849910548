import vsf from '@vs/vsf-boot';
import {
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState, useKeyPress } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const maxIndex = 9;
const minIndex = 0;

const Index = (props) => {
  const { onSelect, open, showRepertory = true, herbType, searchValue } = props;
  const tableRef = useRef<any>();
  const [index, setIndex, getIndex] = useGetState<number>(-1);
  // const [selectedRows, setSelectedRows] = useState<any[]>();

  const [dataSource, setDataSource] = useState<any>();

  const handleSelect = useCallback(
    (data) => {
      if (open) {
        onSelect?.(data);
      }
    },
    [open, onSelect],
  );

  useKeyPress('downarrow', () => {
    if (index < maxIndex && open) {
      setIndex(getIndex() + 1);
    }
  });

  useKeyPress('uparrow', () => {
    if (index > minIndex && open) {
      setIndex(getIndex() - 1);
    }
  });

  useKeyPress('leftarrow', () => {
    if (open) {
      tableRef?.current?.gotoPreviousPage();
      setIndex(0);
    }
  });

  useKeyPress('rightarrow', () => {
    if (open) {
      tableRef?.current?.gotoNextPage();
      setIndex(0);
    }
  });

  const getCurrentData = useCallback(
    (index) => {
      const currentPage = tableRef?.current?.getCurrentPage();
      const v = currentPage ? currentPage - 1 : 0;
      // handleSelect(dataSource?.[v * 10 + index]);
      handleSelect(dataSource?.[index]);
    },
    [dataSource, handleSelect],
  );

  useKeyPress(
    '1',
    () => {
      if (open) {
        getCurrentData(0);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '2',
    () => {
      if (open) {
        getCurrentData(1);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '3',
    () => {
      if (open) {
        getCurrentData(2);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '4',
    () => {
      if (open) {
        getCurrentData(3);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '5',
    () => {
      if (open) {
        getCurrentData(4);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '6',
    () => {
      if (open) {
        getCurrentData(5);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '7',
    () => {
      if (open) {
        getCurrentData(6);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '8',
    () => {
      if (open) {
        getCurrentData(7);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '9',
    () => {
      if (open) {
        getCurrentData(8);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '0',
    () => {
      if (open) {
        getCurrentData(9);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    'enter',
    () => {
      if (open && index >= 0) {
        getCurrentData(getIndex());
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  const onFetch = useCallback(
    async (params) => {
      //
      // Tips: 以下是你选择的请求函数
      const res = await vsf.services?.DrugDrugController_queryHerbList_50f5a9?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
            storageId: [123123],
            herbType: herbType,
            inputCode: searchValue,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
      setDataSource(res?.data?.result ?? res?.data ?? []);
      return {
        data: res?.data?.result ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [herbType, searchValue],
  );

  return (
    <div className="dictionary-table">
      <VSPromiseTable
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        onFetch={onFetch}
        pagination={{
          defaultPageSize: 8,
        }}
        size="small"
        rowClassName={(record, rowIndex) => {
          return index === rowIndex ? 'ant-table-row-selected' : '';
        }}
        rowSelection={{
          selectType: 'single',
          // selectedRows: selectedRows,
          hideColumn: true,
          onSelect: (node, key, selected) => {
            handleSelect(node);
          },
          onSelectedRowsChange: (data, keys) => {
            handleSelect(data?.[0]);
          },
        }}
        rowClick={{
          rowClickType: 'select',
        }}
      >
        <VSTableColumn
          valueType="index"
          render={(v: any) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width={50}
        ></VSTableColumn>

        <VSTableColumn
          dataIndex={['drugName']}
          title="药品名称"
          valueType="text"
          fieldProps={{}}
          width={250}
        />

        <VSTableColumn
          dataIndex={['drugSpecification']}
          title="规格"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          valueType="text"
          dataIndex={['desc']}
          title="基数"
          render={() => '详情'}
        ></VSTableColumn>

        <VSTableColumn
          dataIndex={['retailPrice']}
          title="价格"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['medicalInsuranceType']}
          title="医保类型"
          valueType="select"
          dataSource={[
            { label: '甲', value: 'ALPHA' },
            { label: '乙', value: 'BETA' },
            { label: '丙', value: 'GAMMA' },
          ]}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </div>
  );
};

export default Index;
