import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getPriceItemHistory_dd7e9a: {
    method: 'post',
    url: '/api/price-list/get-price-item-history',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PriceItemHistoryVoPriceListEntranceWebVo[],
    ) => data,
  },
});
