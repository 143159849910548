import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  message,
  VSPromiseTable,
  VSTableColumn,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { forwardRef, Fragment, useCallback, useState } from 'react';

import ComplexSelect, {
  ComplexSelectProps,
  useComplexSelect,
} from '@/pages/Index/components/complex_select';
import { askFixed } from '@/pages/Index/components/func/ask';
import { user } from '@/stores';

import { useInputEnter } from '../dosage/utils';

// import { useInputEnter } from '../Dosage/utils';

const TableRender = (props) => {
  const { onFetch, ...rest } = props;
  const columns = [
    {
      title: '序号',
      dataIndex: ['index'],
      width: 50,
    },
    {
      title: '项目名称',
      dataIndex: ['itemName'],
      ellipsis: true,
    },
    {
      title: '规格',
      dataIndex: ['itemSpecification'],
    },
    {
      title: '费用类别',
      dataIndex: ['itemClass'],
      dataSource: vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT,
      valueType: 'select',
    },
    {
      title: '单价',
      dataIndex: ['price'],
    },
    {
      title: '品牌',
      dataIndex: ['brand'],
      ellipsis: true,
    },
    {
      title: '备注',
      dataIndex: ['memo'],
    },
  ];

  const style: React.CSSProperties = {
    fontSize: 14,
    color: '#aeb0b3',
    marginBottom: 10,
  };

  const method = useComplexSelect?.();
  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);

  return (
    <Fragment>
      <div style={style}>输入序号选择项目，键盘“← →”翻页</div>
      <VSPromiseTable
        onFetch={onFetch}
        id="TableRender"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 8,
        }}
        rowKey="key"
        rowClick={{
          rowClickType: 'select',
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          selectedRows: rest?.rowSelection?.selectedRowKeys,
          onSelectedRowsChange: (value: any) => {
            const [state] = value;
            console.log(state);
            method?.onChange({
              ...state,
            });
          },
        }}
        size="small"
      >
        {columns?.map((item, index) => {
          return <VSTableColumn key={index} {...item} />;
        })}
      </VSPromiseTable>
    </Fragment>
  );
};

enum SelectedOmit {
  onChange,
  value,
  showLabel,
  mode,
  dropdownMatchSelectWidth,
  dropdownStyle,
  hideSelectIcon,
  keyDown,
  keyDownDeploy,
  children,
  dataSource,
}

type SelectedProps = Omit<ComplexSelectProps, keyof typeof SelectedOmit> & {
  onChange?: (value: any) => void;
  value?: any;
  schema: any;
  config: any;
  form: any;
  dataSource: any[];
};

type DataValue = {
  label: string;
  value: number;
};

const ProjectSelect = forwardRef((props: SelectedProps, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, dataSource, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form?.useFormInstance();
  // const key: any = Object?.keys(form?.getFieldsValue())?.at(-1)?.toString();

  const key: any = Object?.keys(form?.getFieldsValue())?.at(-1);
  const record = form?.getFieldValue(key);
  const handleOnChange = async (value) => {
    const { staffInfo } = user || {};
    const key: any = Object?.keys(form?.getFieldsValue())?.at(-1);
    form?.setFieldsValue({
      [key]: {
        ...record,
        ...value,
        id: key,
        priceItemSpecification: value?.itemSpecification,
        brand: value?.brand,
        unit: value?.unit,
        amount: 1,
        billingStartDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(),
        selfPaymentIndicator: false,
        createDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(),
        operatorName: staffInfo?.staffName,
        // frequency: 'once',
      },
    });
    onChange?.({
      label: value?.itemName,
      value: value?.itemId,
    });
  };

  const [search, setSearch] = useState({
    inputCodeLike: '',
  });
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              ...search,
            },
            isIncludeDrugOrHerb: false,
          },
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        return res?.data?.result?.map((item, index) => ({
          ...item,
          index: index + 1,
          key: index + 1,
        }));
      }
    },
    [search],
  );
  const onSearch = (value) => {
    setSearch({
      inputCodeLike: value,
    });
  };
  return (
    <ComplexSelect
      onChange={handleOnChange}
      onSearch={onSearch}
      value={value}
      showLabel="drugName"
      ref={ref as any}
      mode="radio"
      dropdownMatchSelectWidth={900}
      dropdownStyle={{}}
      listHeight={500}
      hideSelectIcon
      keyDownDeploy={{
        ArrowLeft: () => {
          vsf?.refs?.TableRender?.gotoPreviousPage();
        },
        ArrowRight: () => {
          vsf?.refs?.TableRender?.gotoNextPage();
        },
      }}
      {...rest}
      {...fieldEnterProps}
    >
      <TableRender onFetch={onFetch} dataSource={dataSource} />
    </ComplexSelect>
  );
});

ProjectSelect.displayName = 'projectSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'projectSelect',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      if (typeof value === 'object') {
        return <>{value?.label}</>;
      }
      return <>{value}</>;
    },
  }),
)(ProjectSelect) as typeof ProjectSelect;
