import { Tooltip } from '@vs/vsf-kit';

const Big = require('big.js');
//递归删除obj里每一项带id的数据
export function removeIds(obj) {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      // 如果是数组，递归处理数组中的每个元素
      for (let i = 0; i < obj.length; i++) {
        obj[i] = removeIds(obj[i]);
      }
    } else {
      // 如果是对象，递归处理对象的每个属性
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          obj[key] = removeIds(obj[key]);
        }
      }
    }
  }

  // 删除对象的 id 属性
  if (obj && typeof obj === 'object' && obj.hasOwnProperty('id')) {
    delete obj.id;
  }

  return obj;
}

export function cyclicCopy(array, n) {
  const newArray = [];

  for (let i = 0; i < n; i++) {
    newArray.push(array[Math.floor(i / (n / array.length))]);
  }

  return newArray;
}

export function repeatArrayElements(arr, repeatCount) {
  const result = [];

  for (const num of arr) {
    for (let i = 0; i < repeatCount; i++) {
      result.push(num);
    }
  }

  return result;
}

//用于去除form,getValuse是value为为空时对其进行过滤
export function filterEmptyValues(obj) {
  const filteredEntries = Object.entries(obj).filter(
    ([key, value]) => value !== null && value !== undefined && value !== '',
  );
  return Object.fromEntries(filteredEntries);
}

//两个数字相加防损失精度
export function add(num1 = '0', num2 = '0', fix) {
  let addNum1 = new Big(num1);
  let addNum2 = new Big(num2);
  return Number(addNum1.plus(addNum2)).toFixed(fix);
}
//两个数字相减防损失精度
export function subtract(num1, num2, fix = 0) {
  try {
    let addNum1 = new Big(Number(num1));
    let addNum2 = new Big(Number(num2));
    return Number(addNum1.minus(addNum2)).toFixed(fix);
  } catch (error) {
    return 0;
  }
}

//根据出生日期算年龄
export function calculateAge(dateOfBirth) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

//生产随即5位数
export function generateRandomNumber() {
  // Generate a random number between 10000 and 99999 (inclusive)
  return Math.floor(Math.random() * 90000) + 10000;
}

//数字转百分比
export function numberToPercentage(number, decimalPlaces = 2) {
  if (typeof number !== 'number') {
    return 'Invalid input';
  }

  const percentage = (number * 100).toFixed(decimalPlaces) + '%';
  return percentage;
}

// 文字元素超出部分省略
export const onTableFieldEllipsis = (str) => (
  <Tooltip title={str}>
    <div className="ellipsis">{str}</div>
  </Tooltip>
);

// 整数浮点
export const onTableFieldNumber = (num, fix) => num.toFixed(fix);
