import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderController_getByInpVisitEncounterId_4acd8c: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-inp-visit-encounter-id',
    parameterFomatter: (data?: { inpVisitId: number; today: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_saveOrder_c9c63c: {
    method: 'post',
    url: '/api/clinic-record-order/save-order',
    parameterFomatter: (data?: {
      order: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_cancelOrder_40b666: {
    method: 'post',
    url: '/api/clinic-record-order/cancel-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_undoOrder_57be5d: {
    method: 'post',
    url: '/api/clinic-record-order/undo-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_stopOrder_69695f: {
    method: 'post',
    url: '/api/clinic-record-order/stop-order',
    parameterFomatter: (data?: { orderIdList: number[]; stopDate: string }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordOrderController_unstopOrder_753c9a: {
    method: 'post',
    url: '/api/clinic-record-order/unstop-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordOrderController_getOrderDetailById_6f461b: {
    method: 'post',
    url: '/api/clinic-record-order/get-order-detail-by-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_getListInpPreDischargePatientBaseVoByInpVisitId_f3ad3f:
    {
      method: 'post',
      url: '/api/clinic-inp-adt/get-list-inp-pre-discharge-patient-base-vo-by-inp-visit-id',
      parameterFomatter: (data?: { inpVisitId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpPreDischargePatientBaseVoClinicInpAdtEntranceWebVo[],
      ) => data,
    },
  ApplicationParameterController_getLocalParameterValues_df5905: {
    method: 'post',
    url: '/api/application/get-local-parameter-values',
    parameterFomatter: (data?: { names: string[] }) => data,
    responseFormatter: (_, __, data?: ParameterValueEoApplicationPersistEo[]) =>
      data,
  },
  TreatAndOtherItemController_queryOtherItemsForInpOrder_cf7ea2: {
    method: 'post',
    url: '/api/clinic-item-base/query-other-items-for-inp-order',
    parameterFomatter: (data?: {
      qto: ClinicItemForOtherInpOrderQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicItemDictionaryVsPriceEoClinicItemBasePersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpVisitController_getPatientInpVisitInfo_9055d6: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-patient-inp-visit-info',
    parameterFomatter: (data?: {
      inpVisitId: number;
      patientId: number;
      displayId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitPatientInfoVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
  UserStaffController_queryOperationDoctorListBySearchCode_3a0b41: {
    method: 'post',
    url: '/api/user-staff/query-operation-doctor-list-by-search-code',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PriceListDtoController_getPriceItemForClinicType_c67b85: {
    method: 'post',
    url: '/api/price-list/get-price-item-for-clinic-type',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
});
