/**
 * 床位对价表列表
 * @param {any} input
 * @returns {any}
 */
export const convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.id');
    output.sortNumber = _.get(input, 'data.sortNumber');
    output.bedClass = _.get(input, 'data.bedClass');
    output.name = _.get(input, 'data.bedClassInfo.name');
    output.itemId = _.get(input, 'data.priceItemInfo.itemId');
    output.itemName = _.get(input, 'data.priceItemInfo.itemName');
    output.price = Number(_.get(input, 'data.priceItemInfo.price'))?.toFixed(2);
    output.unit = _.get(input, 'data.priceItemInfo.unit');
    output.itemSpecification = _.get(
      input,
      'data.priceItemInfo.itemSpecification',
    );
    output.deprecateIndicator = _.get(
      input,
      'data.bedClassInfo.deprecateIndicator',
    );
    output.memo = _.get(input, 'data.memo');
    return output;
  };
/**
 * convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo
 * @param {any} input
 * @returns {any}
 */
export const convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.sortNumber =
          _.get(d, 'sortNumber') ?? _.get(d, 'bedClassInfo.sortNumber');
        obj.bedClass = _.get(d, 'bedClass');
        obj.name = _.get(d, 'bedClassInfo.name');
        obj.itemId = _.get(d, 'priceItemInfo.itemId');
        obj.itemName = _.get(d, 'priceItemInfo.itemName');
        obj.price = Number(_.get(d, 'priceItemInfo.price'))?.toFixed(2);
        obj.unit = _.get(d, 'priceItemInfo.unit');
        obj.itemSpecification = _.get(d, 'priceItemInfo.itemSpecification');
        obj.deprecateIndicator = _.get(d, 'bedClassInfo.deprecateIndicator');
        obj.memo = _.get(d, 'memo');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 价表对照编辑

export const convertGetBedClassVsChargeDetailVoByIdRes2VSFormBedClassVsCharge =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.id');
    output.sortNumber =
      _.get(input, 'data.sortNumber') ??
      _.get(input, 'data.bedClassInfo.sortNumber');
    output.bedClass = _.get(input, 'data.bedClass');
    output.name = _.get(input, 'data.bedClassInfo.name');
    output.priceItem = {};
    output.priceItem.itemName = _.get(input, 'data.priceItemInfo.itemName');
    output.priceItem.itemId = _.get(input, 'data.priceItemInfo.itemId');
    output.deprecateIndicator = _.get(
      input,
      'data.bedClassInfo.deprecateIndicator',
    );
    output.memo = _.get(input, 'data.memo');
    return output;
  };
