import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentBOController_createDepartment_af7d4c: {
    method: 'post',
    url: '/api/organization/createDepartment',
    parameterFomatter: (data?: {
      btoParam: CreateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentBOController_deleteDepartment_f91e76: {
    method: 'post',
    url: '/api/organization/deleteDepartment',
    parameterFomatter: (data?: {
      btoParam: DeleteDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DepartmentBOController_updateDepartment_da3dee: {
    method: 'post',
    url: '/api/organization/updateDepartment',
    parameterFomatter: (data?: {
      btoParam: UpdateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentDtoController_getById_3da0f7: {
    method: 'post',
    url: '/api/organization/getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  MedicalInstitutionBOController_createInstitution_c79457: {
    method: 'post',
    url: '/api/organization/create-institution',
    parameterFomatter: (data?: {
      btoParam: CreateInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionBOController_deleteInstitution_af4371: {
    method: 'post',
    url: '/api/organization/delete-institution',
    parameterFomatter: (data?: {
      btoParam: DeleteInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  MedicalInstitutionBOController_updateInstitution_83b664: {
    method: 'post',
    url: '/api/organization/update-institution',
    parameterFomatter: (data?: {
      btoParam: UpdateInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getInstitutionById_5594f2: {
    method: 'post',
    url: '/api/organization/get-institution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
  StaffDetailDtoController_queryPaged_39a7f7: {
    method: 'post',
    url: '/api/user-staff/staff-list/query',
    parameterFomatter: (data?: {
      qto: StaffListQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseCommonController_getByCategory_1fe731: {
    method: 'post',
    url: '/api/base-common/get-icon-list-by-category',
    parameterFomatter: (data?: { category: IconCategoryEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: IconRepositoryDtoBaseCommonManagerDto[],
    ) => data,
  },
});
