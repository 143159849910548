import { createServices } from '@vs/vsf-boot';
export default createServices({
  SkinTestDrugManageController_queryAllByQuerySkinTestDrugQto_f7cbc3: {
    method: 'post',
    url: '/api/drug-drug/query-all-by-query-skin-test-drug-qto',
    parameterFomatter: (data?: {
      qto: QuerySkinTestDrugQtoSimpleDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugProductDictionaryNameFormVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
