import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpVisitController_updateInpVisitExtensionStaff_c2a009: {
    method: 'post',
    url: '/api/clinic-inp-visit/update-inp-visit-extension-staff',
    parameterFomatter: (data?: {
      btoParam: UpdateInpVisitExtensionStaffBtoClinicInpVisitServiceBto;
      extApiParam: {
        cellphoneNumber: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitBaseWithStaffVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpVisitController_getInpVisitBaseWithDiagnosisVoById_6417ed: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-inp-visit-base-with-diagnosis-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitBaseWithDiagnosisVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  UserStaffController_getPagedByUserStaffQto_c82b82: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-user-staff-qto',
    parameterFomatter: (data?: { qto: UserStaffNewQtoUserStaffPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
