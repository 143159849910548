import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderDispenseController_orderDispensing_7cd9ab: {
    method: 'post',
    url: '/api/drug-order-dispense/order-dispensing',
    parameterFomatter: (data?: {
      param: OrderDispensingInputEoDrugOrderDispensePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDispensingReturnEoDrugOrderDispensePersistEo,
    ) => data,
  },
  OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99: {
    method: 'post',
    url: '/api/drug-order-dispense/get-wait-dispense-order-perform-plan-list',
    parameterFomatter: (data?: {
      query: WaitOrderDispensePlanDetailQueryEoDrugOrderDispensePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitOrderDispensePatientEoDrugOrderDispensePersistEo[],
    ) => data,
  },
  OrderDispenseController_getOrderWaitDispenseBedList_fc78bb: {
    method: 'post',
    url: '/api/drug-order-dispense/get-order-wait-dispense-bed-list',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitHospitalBedPatientVoDrugOrderDispenseEntranceWebVo[],
    ) => data,
  },
  OrderDispenseController_getWaitDispenseOrderPerformPlanWithDistinctDrug_afaace:
    {
      method: 'post',
      url: '/api/drug-order-dispense/get-wait-dispense-order-perform-plan-with-distinct-drug',
      parameterFomatter: (data?: {
        query: WaitOrderDispensePlanDetailQueryEoDrugOrderDispensePersistEo;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: WaitOrderDispenseCountOrderEoDrugOrderDispensePersistEo[],
      ) => data,
    },
  ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce:
    {
      method: 'post',
      url: '/api/application-privilege/get-current-application-vs-staff-with-staff-vo',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffWithStaffVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
});
