/* eslint-disable no-case-declarations */
import { MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import vsf from '@vs/vsf-boot';
import {
  Alert,
  Button,
  List,
  message,
  Popover,
  Tooltip,
  VSTable,
  VSTableColumn,
  VSTableEditable,
  VSTableInstance,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import Determine from '@/pages/Index/components/determine';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import { StateValueType } from '@/pages/Index/components/func/ask/askGroupMedicalOrders';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import onReload from '@/pages/Index/components/order_table/onReload';
import { insertBlock } from '@/pages/Index/demo/utils';
import { positiveInteger, positiveIntegerMessage } from '@/pattern';
import { getScrollY, getScrollYRem, removeObjectFalsy } from '@/utils';

import { DosageUnit } from '../../../dosage';
import Flex from '../../../flex';
import Usage, { UsageUnit } from '../../../usage';
import { dataSourceHerb } from '../../in_patient/table/datasource';
import { AuxiliaryRefs, BeIn } from '..';
import Auxiliary from '../assist/auxiliary';
import Header, { HeaderRefs } from '../assist/header';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import { orderClassDataSource } from './datasource';
export type TableRefs = VSTableInstance<any, 'text'> | null;

export interface Ref {
  onOpen: (value?: never) => void;
}

interface OutHospitalProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  header: {
    current: HeaderRefs | null;
  };
  prefixCls?: string;
  children?: React.ReactNode;
  // filterParams: any;
  operation?: any;
  footer: {
    current: {
      group(value: boolean): unknown;
      click: (data) => void;
    };
  };
  agreedPrescriptionIndicator?: boolean;
}

export interface Refs {
  setValue: (value) => never;
  getValue: () => never;
  onSliced: (dataSource) => any[];
  onGroup: (value?: any) => any[];
  cancelGroupDisabled: (value) => boolean;
  onCancelGroup: () => any[];
  countablePreview: (value: any) => void;
}

export type Value = SaveOrderEoClinicRecordOrderPersistEo &
  Omit<StateValueType, 'id'> & {
    uuid: string;
    groupIndex: number[];
    groupName: string;
    group: never;
  };

const Row = ({ children, transform: transformProps = {}, ...props }: any) => {
  const currentKey = props['data-row-key'];
  const { dragNode = {}, record = {} } = props;
  const { groupNumber: dragGroupNumber } = dragNode;
  // const dragGroupLength = dragNode?.groupIndex?.length;
  const {
    groupNumber: currentGroupNumber,
    groupSubNumber: currentgroupSubNumber,
  } = record;
  const currentGroupLength = record?.groupIndex?.length;
  const customStrategy = ({
    rects,
    activeNodeRect,
    activeIndex,
    overIndex,
    index,
  }) => {
    const resTransform = rectSortingStrategy({
      rects,
      activeIndex,
      overIndex,
      index,
      activeNodeRect,
    });

    // if (record?.groupName) {
    //   if (
    //     record?.groupName === '' ||
    //     record?.groupName === 'aspirin-order-text-group-first'
    //   ) {
    //     resTransform = null;
    //   } else {
    //     if (activeIndex > index) {
    //       const transformIndex = index - (currentgroupSubNumber - 1);
    //       if (overIndex <= transformIndex) {
    //         if (resTransform) {
    //           resTransform = {
    //             ...resTransform,
    //             y: resTransform?.y * (dragGroupLength ?? 1),
    //           };
    //         } else {
    //           resTransform = null;
    //         }
    //       } else {
    //         resTransform = null;
    //       }
    //     } else {
    //       const transformIndex =
    //         index + (currentGroupLength - currentgroupSubNumber);
    //       if (overIndex >= transformIndex) {
    //         if (resTransform) {
    //           resTransform = {
    //             ...resTransform,
    //             y: resTransform?.y * (dragGroupLength ?? 1),
    //           };
    //         } else {
    //           resTransform = null;
    //         }
    //       } else {
    //         resTransform = null;
    //       }
    //     }
    //   }
    // } else {
    //   if (dragGroupNumber) {
    //     if (resTransform) {
    //       resTransform = {
    //         ...resTransform,
    //         y: resTransform?.y * (dragGroupLength ?? 1),
    //       };
    //     } else {
    //       resTransform = null;
    //     }
    //   }
    // }

    return resTransform;
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: currentKey,
    strategy: customStrategy,
  });

  const getStyle = () => {
    let transformStyle = CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 },
    );
    let showStyle = {};
    if (isDragging) {
      showStyle = { position: 'relative', zIndex: 9999 };
    }
    if (dragGroupNumber) {
      if (
        record?.groupName === '' ||
        record?.groupName === 'aspirin-order-text-group-first'
      ) {
        if (dragGroupNumber === currentGroupNumber) {
          const newTransform = {
            ...transformProps,
            scaleX: 1,
            scaleY: 1,
          };
          transformStyle = CSS.Transform.toString(
            newTransform && { ...newTransform, scaleY: 1 },
          );
          if (Object.keys(transformProps)?.length > 0) {
            showStyle = {
              position: 'relative',
              zIndex: 9999,
              backgroundColor: '#fff',
            };
          }
        }
      }
    }

    return {
      transform: transformStyle,
      ...showStyle,
    };
  };

  const style: React.CSSProperties = {
    ...props.style,
    ...getStyle(),
    transition,
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (
          record?.groupName &&
          record?.groupName !== 'aspirin-order-text-group-first'
        ) {
          return child;
        }
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

/**
 * 门诊版医嘱套餐表格
 */
const OutHospitalTemplate: any = forwardRef((props: OutHospitalProps, ref) => {
  const { auxiliary, footer, operation, agreedPrescriptionIndicator } = props;
  const {
    common: { currentApplication },
  } = vsf?.stores;
  const [value, setValue, getValue] = useGetState<any[]>([]);
  const id = useMemo(() => getUUID(), []);
  const [select] = useOnChangeValue<any>({});
  const tableRef = useRef<TableRefs>(null);
  const edit = useRef<any>(null);
  const search = useRef<string[]>([]);

  /**
   * 成组类型数据是否预览判断，组内第一项为编辑态，非组内第一项为预览态
   * @param param
   * @returns
   */
  const groupPreview: any = (index) => {
    const value = tableRef?.current?.getRowValue(index);
    return (
      value?.drugOrder?.groupSubNumber && value?.drugOrder?.groupSubNumber !== 1
    );
  };

  /**
   * 医嘱数据是否预览判断，可数类型数据为编辑态，不可数类型数据为预览态
   * @param param
   * @returns
   */
  const countablePreview: any = (data) => {
    const previewClassList = [
      { label: '治疗', value: 'TREAT' },
      { label: '手术', value: 'OPERATION' },
      { label: '麻醉', value: 'ANESTHESIA' },
      { label: '护理', value: 'NURSING' },
      { label: '膳食', value: 'MEAL' },
      { label: '营养', value: 'NUTRITION' },
      { label: '会诊', value: 'CONSULTATION' },
      { label: '用血', value: 'BLOOD' },
      { label: '其他', value: 'OTHER' },
    ];
    const value = previewClassList
      ?.map((item) => item?.value)
      .includes(data?.orderClass);
    return value;
  };

  /**
   * 皮试的主医嘱编辑限制
   * @param data
   * @returns
   */
  const skinTestPreview = (record) => {
    return record?._skinTest;
  };

  /**
   * 当长/临下拉选择“临时”且药品勾选处方，该控件可编辑；未勾选处方则不可编辑 选择“长期”时，可编辑"
   * @param record
   * @param index
   * @returns {Boolean}
   */
  const frequencyPreview = (record, index): boolean => {
    return groupPreview(index) || countablePreview(record);
  };

  /**
   *  根据途径判断是否必填
   */
  const administrationAmountPreview = (record) => {
    /**
     * @const showDripSpeedIndicator
     */
    const showDripSpeedIndicator =
      vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map((item) => ({
        ...item,
        ...JSON?.parse(item?.extensionJson || '{}'),
      }))?.find(
        (item) => item?.value == (record as any)?.administration,
      )?.showDripSpeedIndicator;
    return !showDripSpeedIndicator;
  };

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'],
        ['dripSpeed'],
        ['frequency'],
        ['useDescription'],
        ['performDays'],
      ],
      tip({ onOk, onCancel }) {
        Tip({
          content: '当前医嘱与上一条医嘱不一致，是否成组',
          onOk,
          onCancel,
        });
      },
    }),
  );

  const fetch = useMemo(() => {
    return {
      onDrugFetch: async (params: any) => {
        const res =
          await vsf.services?.DrugDrugController_queryDrugListForTemp_2beee2?.({
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              inputCodeLike: params?.searchValue,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
            },
          });
        return res?.data?.result ?? [];
      },
      onDrugDetailsFetch: async (value) => {
        // const drugItemId = value?.drugItemId;
        // const res =
        //   await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        //     {
        //       drugItemId,
        //     },
        //   );
        // return res?.data;
        const drugItemId = value?.drugItemId;
        if (drugItemId) {
          const res: any =
            await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
              {
                drugItemId,
                patientId: 1001,
                departmentId: 193,
                staffId: vsf?.stores?.user?.staffInfo?.id,
              },
            );
          return res?.data;
        }
        return [];
      },
    };
  }, []);

  const onStart = (id) => {
    const start = tableRef?.current?.startEdit(id);
    edit.current = id;
    footer?.current?.group(!start);
  };

  const onSave = async () => {
    const save = await tableRef?.current?.saveEdit();
    edit.current = null;
    footer?.current?.group(Boolean(save));
    return save;
  };

  const onDrug = async (isOther?: boolean) => {
    const record = {
      id: 'create' + Math.random(),
      uuid: getUUID(),
      orderStatus: 'OPEN',
      is_one: true,
      isOther,
    };
    const save = await onSave();
    if (save) {
      tableRef?.current
        ?.add(record as any)
        ?.then(() => {
          // return tableRef?.current?.startEdit(record?.id);
          return onStart(record?.id);
        })
        .then(() => {
          // tableRef?.current?.scrollTo(record?.id);
          const table = document.querySelector(
            `[data-id="${id}"] .ant-table-body`,
          );
          table?.scrollTo(0, table?.scrollHeight);
          setTimeout(() => {
            tableRef?.current
              ?.getEditForm()
              ?.getFieldInstance([record?.id, 'orderText'])
              ?.focus();
          }, 200);
        });
    }
  };

  const getGroup = useCallback(async (data) => {
    const value = tableRef?.current?.getEditForm()?.getFieldsValue(true)?.[
      edit?.current ?? ''
    ];
    const __info__ = data ?? {
      id: edit?.current,
      ...(getValue()?.find((item) => item?.id === edit?.current) ?? {}),
      ...value,
    };
    const drugItemId =
      __info__?.drugOrder?.drugItemId ?? __info__?.orderText?.drugItemId;

    let res: any = {};
    if (drugItemId) {
      res =
        await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
          {
            drugItemId,
            patientId: 1001,
            departmentId: 193,
            staffId: vsf?.stores?.user?.staffInfo?.id,
          },
        );
    }
    const init = res?.data
      ? {
          amountPerPackage: res?.data?.amountPerPackage ?? 0,
          dosagePerUnit: res?.data?.dosagePerUnit ?? 0,
          roundingType: res?.data?.roundingType ?? 'BY_AMOUNT',
        }
      : {};
    return {
      ...__info__,
      ...init,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 成组
   * @param data
   */
  const onGroup = useCallback(
    async (data?: any) => {
      const form = tableRef?.current?.getEditForm();
      const [key] = Object?.keys(form?.getFieldsValue(true));
      const save = await form?.validateFields([
        [key, 'orderText'],
        [key, 'usage'],
        [key, 'dosage'],
      ]);
      if (save) {
        const value = await getGroup(data);
        const [or] = await group.current?.onGroup(value, getValue());
        if (or) {
          const slice = group.current?.onSliced(or);
          const edit = slice?.find((item) => item?.id == key);
          form?.setFieldValue(
            [key ?? '', 'totalAmount'],
            edit?.drugOrder?.totalAmount,
          );
          // for (const item of slice?.filter((item) =>
          //   and?.map((item) => item?.id)?.includes(item?.id),
          // )) {
          //   tableRef?.current?.update(item);
          // }
          setValue(slice);
          new Promise((resolve, reject) => {
            const save = tableRef?.current?.cancelEdit();
            save ? resolve(save) : reject(save);
          }).then(() => {
            form?.resetFields();
          });
        }
      }
    },
    [getGroup, getValue, setValue],
  );

  /**
   * 取消成组
   */
  const onCancelGroup = (value) => {
    const [, data = []] =
      group.current.onCancelGroup(getValue(), value ?? select?.value) || [];
    console.log(data);
    for (const item of data) {
      tableRef?.current?.update(item);
    }
  };

  /**
   * 插入一条数据
   */
  const onInsert = useCallback(
    async (_info?: any) => {
      if (await onSave()) {
        const list = getValue() as any;
        const index = list?.findIndex((item) => item?.id === _info?.id);
        const newly = list[index ?? ''];
        const data = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          /**
           * 记录这条数据是不是插入的
           */
          insert: true,
        };
        /**
         * 判断如果插入组内直接成组
         */
        if (
          newly?.drugOrder?.groupSubNumber &&
          _info?.drugOrder?.groupSubNumber !== 1
        ) {
          const value = list?.toSpliced(index, 0, {
            ...data,
            orderClass: 'DRUG',
          });
          const [and] = await group.current?.intoGroups(
            await getGroup(data),
            value,
            'splice',
          );
          if (and) {
            setValue(group.current?.onSliced(and));
            setTimeout(() => {
              onStart?.(data?.id);
            }, 200);
          }
        } else {
          setValue(list?.toSpliced(index, 0, data));
          setTimeout(() => {
            onStart?.(data?.id);
          }, 200);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getGroup, getValue, setValue],
  );

  /**
   * 表格行单/双击操作
   */
  const onTableRowOperation = {
    on_isSave: async () => {
      return await tableRef?.current?.saveEdit();
    },
    click: async (data) => {
      if (!(await onTableRowOperation?.on_isSave())) return;
      select?.onChange(data);
      footer.current.click?.(data);
    },
    doubleClick: async (target) => {
      if (!(await onTableRowOperation?.on_isSave())) return;
      const { examOrder, herbOrder, labOrder, orderClass } = target || {};
      if (target?.parentRelatedUuid || target?._skinTest) {
        message?.warning('皮试带药不可编辑');
        return;
      }
      const METHOD = {
        DRUG: async () => {
          onSave()?.then(() => onStart?.(target?.id));
        },
        HERB: () => {
          auxiliary?.current?.onHerb({
            ...herbOrder,
            orderText: target?.orderText,
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
          });
        },
        LAB: async () => {
          const res =
            await vsf?.services?.ClinicItemController_getClinicItemDictionaryLabById_ada097(
              {
                clinicItemId: labOrder?.labItemId,
              },
            );
          auxiliary?.current?.onLab({
            ...labOrder,
            labItemName: res?.data?.clinicItemName,
            clinicVsChargeDtoList: res?.data?.clinicVsChargeDetailDtoList,
            allPrice: askTotalAmount(
              (res?.data?.clinicVsChargeDetailDtoList ?? [])?.map((item) => [
                item?.priceItem?.price ?? 0,
                item?.amount ?? 0,
              ]),
              2,
            ),
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
          });
        },
        EXAM: () => {
          auxiliary?.current?.onExam({
            uuid: target?.uuid,
            ...examOrder,
            patientSpecialInfo: target?.patientSpecialInfo,
            performDepartment: target?.performDepartment,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
          });
        },
      };
      if (METHOD?.[orderClass]) {
        METHOD?.[orderClass]?.();
      } else {
        onSave().then(() => onStart?.(target?.id));
      }
    },
  };

  /**
   * 特殊复杂render函数集合
   */
  const render = {
    rowClassName: (record) => {
      let resClassName = '';
      if (select?.value?.id === record?.id) {
        resClassName = 'aspirin-table-row-active ';
      }
      const { orderStatus } = record ?? {};
      switch (orderStatus) {
        case 'SAVE':
          resClassName += 'aspirin-table-row-order-blue';
          break;
        case 'SUBMIT':
          resClassName += 'aspirin-table-row-order-green';
          break;
        case 'PERFORM':
          resClassName += 'aspirin-table-row-order-black';
          break;
        case 'STOP':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'CANCEL':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'AUDIT_FAIL':
          resClassName += 'aspirin-table-row-order-pink';
          break;
        default:
          resClassName += 'aspirin-table-row-order-blue';
          break;
      }
      return resClassName;
    },
    renderOrderText: (...args) => {
      const [, record] = args;
      const { examOrder, labOrder, operationOrder, pathologyOrder, drugOrder } =
        record || {};
      const content = record?.orderText?.drugProductName
        ? `${record?.orderText?.drugProductName}(${record?.orderText?.packageSpecification})`
        : record?.orderText;
      let name = (
        <Popover title="详细信息" content={content} placement="right">
          <div className={classnames('aspirin-order-text', record?.groupName)}>
            <Determine
              condition={Boolean(
                examOrder?.emergencyIndicator ||
                  labOrder?.emergencyIndicator ||
                  operationOrder?.operationApplyType === 'EMERGENCY' ||
                  pathologyOrder?.emergencyIndicator,
              )}
            >
              <Icon
                type="icon-a-Frame1517"
                size={20}
                style={{
                  marginRight: 4,
                }}
              />
            </Determine>
            <Determine condition={Boolean(drugOrder?.drug?.dangerIndicator)}>
              <Popover content="高危">
                <Icon
                  type="icon-gaowei"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              </Popover>
            </Determine>
            <span>{content}</span>
          </div>
        </Popover>
      );
      switch (record?.orderClass) {
        case 'EXAM':
          name = (
            <Popover
              placement="right"
              title={
                <div className="aspirin-title-popover-box">
                  检查
                  <span> {record?.examOrder?.examOrderDetail?.length} </span>
                  项详情
                </div>
              }
              content={
                <List
                  size="small"
                  dataSource={record?.examOrder?.examOrderDetail}
                  renderItem={(item: any, index) => {
                    return (
                      <List.Item
                        style={{
                          padding: '8px 0',
                          width: '100%',
                        }}
                      >
                        <Alert
                          type="info"
                          style={{
                            width: '100%',
                          }}
                          message={`${index + 1}. ${item?.examItemName}`}
                        />
                      </List.Item>
                    );
                  }}
                />
              }
              trigger="hover"
            >
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                <Determine
                  condition={Boolean(
                    examOrder?.emergencyIndicator ||
                      labOrder?.emergencyIndicator ||
                      operationOrder?.operationApplyType === 'EMERGENCY' ||
                      pathologyOrder?.emergencyIndicator,
                  )}
                >
                  <Icon
                    type="icon-a-Frame1517"
                    size={20}
                    style={{
                      marginRight: 4,
                    }}
                  />
                </Determine>

                <span>{content}</span>
              </div>
            </Popover>
          );
          break;
        case 'HERB':
          const use_way_label = record?.herbOrder?.useWay;
          const useWay = use_way_label?.label
            ? use_way_label?.label
            : vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
                (item) => use_way_label == item?.value,
              )?.label;
          const frequency = record?.herbOrder?.frequency?.frequency;
          const use_time_description = record?.herbOrder?.useTimeDescription;
          const useTimeDescription = use_time_description?.label
            ? use_time_description?.label
            : vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
                (item) => item?.value == use_time_description,
              )?.label;
          name = (
            <Popover
              placement="right"
              overlayClassName="aspirin-herb-box"
              content={
                <List
                  size="small"
                  header={
                    <div className="aspirin-title-herb-popover-box">
                      <div className="aspirin-title-herb-header-box">
                        <div className="aspirin-title-herb-header-left-box">
                          <span>中药汤剂明细</span>
                          <span>
                            共 {record?.herbOrder?.herbOrderDetail?.length} 项
                          </span>
                        </div>
                        <div className="aspirin-title-herb-header-right-box">
                          <Icon
                            type="icon-zhongtangyaodanchuangbeijing"
                            style={{
                              color: 'rgb(232, 225, 217)',
                            }}
                            size={56}
                          />
                        </div>
                      </div>
                      <div className="aspirin-title-message-box">
                        <div>使用方法: {useWay}</div>
                        <div>服用时间: {useTimeDescription}</div>
                        <div>服用频次: {frequency}</div>
                        <div>付数: {record?.herbOrder?.herbRegimen}</div>
                        <div className="aspirin-title-message-memo-box">
                          备注: {record?.memo}
                        </div>
                      </div>
                    </div>
                  }
                  dataSource={record?.herbOrder?.herbOrderDetail}
                  className="aspirin-list-herb-popover-item-box"
                  renderItem={(item: any, index) => {
                    const find =
                      vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT?.find(
                        ({ value }) => item?.useDescription === value,
                      );
                    const medicalInsuranceType = dataSourceHerb?.find(
                      ({ value }) => value === item?.medicalInsuranceType,
                    )?.label;
                    return (
                      <Alert
                        type="warning"
                        message={
                          <div className="aspirin-title-herb-popover-item-box">
                            <span>{index + 1}</span>
                            <span>{item?.drugProductName}</span>
                            <span>
                              {Object?.values(
                                {
                                  value: item?.totalAmount,
                                  unit:
                                    vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                                      ({ value }) => value == item?.packageUnit,
                                    )?.label ?? item.packageUnit,
                                } ?? {},
                              )}
                            </span>
                            {find?.label && <span>{find?.label}</span>}
                            <span>
                              {String?.(item?.id).startsWith('create')
                                ? askFixed(item?.price ?? 0, 2)
                                : askFixed(
                                    item?.price *
                                      (item?.totalAmount?.value ??
                                        item?.totalAmount),
                                    2,
                                  )}
                              {medicalInsuranceType
                                ? '/' + medicalInsuranceType
                                : ''}
                            </span>
                          </div>
                        }
                      />
                    );
                  }}
                />
              }
              trigger="hover"
            >
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                <span>{record?.orderText?.drugName ?? record?.orderText}</span>
              </div>
            </Popover>
          );
          break;
      }
      return name;
    },
  };

  useImperativeHandle(ref, () => ({
    onDrug,
    setValue: (data) => {
      setValue(data);
    },
    getValue,
    onGroup,
    onOther: () => onDrug?.(true),
    ...tableRef.current,
    onSliced: group.current?.onSliced,
    onCancelGroup,
    countablePreview,
    id: () => edit.current,
  }));

  const handleExtraSubmit = useContext(BeIn);

  const editable = useMemo(() => {
    const ret: VSTableEditable<any> = {
      editType: 'single',
      columnProps: {
        fixed: 'right',
        width: 50,
        hideInTable: true,
      },
      switchMode: {
        onRowFinish: async () => true,
        onColumnFinish: async () => true,
      },
    };
    return ret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dragNode, setDragNode] = useState<string | number>();
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setValue((previous: any) => {
      const newList = previous?.map((item) => {
        const { transform, ...rest } = item;
        return rest;
      });
      if (active.id !== over?.id) {
        const activeIndex = newList.findIndex((i) => i.id === active.id);
        const overIndex = newList.findIndex((i) => i.id === over?.id);
        const activeTarget = newList?.[activeIndex];
        const overTarget = newList?.[overIndex];

        if (
          !overTarget?.groupNumber ||
          (overTarget?.groupSubNumber === overTarget?.groupIndex?.length &&
            activeIndex < overIndex) ||
          (overTarget?.groupSubNumber === 1 && activeIndex > overIndex)
        ) {
          const newOverIndex =
            activeIndex < overIndex ? overIndex + 1 : overIndex;
          const activeLen = activeTarget?.groupIndex?.length ?? 1;
          return group?.current?.onSliced?.(
            insertBlock(newList, activeIndex, activeLen, newOverIndex),
          );
        } else {
          return group?.current?.onSliced?.(newList);
        }
      } else {
        return group?.current?.onSliced?.(newList);
      }
    });
  };

  const onDragStart = (event) => {
    const { active } = event;
    setDragNode(value?.find((item) => item?.id === active.id));
  };

  const onDragMove = (event) => {
    // 在这里同步更新多个元素的 transform
    // 例如，根据拖拽的元素 A 的位置来更新元素 B 的位置
    // 这里需要你自己的逻辑来决定如何同步更新
    const activeId = event.active.id;
    const deltaY = event.delta.y;
    const activeItem = value.find((item) => item.id === activeId);
    if (activeItem) {
      if (activeItem?.groupNumber) {
        setValue(
          group?.current?.onSliced?.(
            value.map((item) => {
              if (
                item.id !== activeId &&
                item?.groupNumber &&
                item?.groupNumber === activeItem?.groupNumber
              ) {
                return {
                  ...item,
                  transform: {
                    y: deltaY,
                    x: 0,
                  },
                };
              }
              return item;
            }),
          ),
        );
      }
    }
  };

  const state = useMemo(() => {
    const list = group?.current?.onSliced?.(value);
    // return list
    //   ?.filter(
    //     (item) =>
    //       item?.groupName === 'aspirin-order-text-group-first' ||
    //       !item?.groupName,
    //   )
    //   ?.map((item) =>
    //     list?.filter((...[, index]) => {
    //       if (item?.groupIndex?.length) {
    //         const i = item?.groupIndex?.includes?.(index);
    //         return i;
    //       }
    //       return true;
    //     }),
    //   );
    return list;
  }, [value]);

  const description = (recode) => {
    const record = tableRef.current?.getEditForm?.()?.getFieldsValue(true)?.[
      recode?.key ?? ''
    ];
    /**
     * @const useDescription
     */
    const useDescription = vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map(
      (item) => ({
        ...item,
        ...JSON?.parse(item?.extensionJson || '{}'),
      }),
    )?.find(
      (item) => item?.value == (record as any)?.administration,
    )?.useRequiredIndicator;
    return useDescription;
  };

  /**
   * 解决焦点会丢失
   */
  const dragging = useRef(false);
  const dragElement = useRef({ focus: () => {} });
  const onMouseup = useCallback(
    (e) => {
      if (dragging?.current) {
        const targetInput = e.target.closest('input');
        if (targetInput && targetInput !== dragElement?.current) {
          dragElement?.current?.focus?.();
        }
      }
    },
    [dragElement, dragging],
  );
  const onMousedown = useCallback(
    (e) => {
      if (e.target.closest('input')) {
        dragging.current = true;
        dragElement.current = e.target;
      }
    },
    [dragElement, dragging],
  );
  useEffect((): any => {
    document.addEventListener('mousedown', onMousedown);
    document.addEventListener('mouseup', onMouseup);
    return () => {
      window?.removeEventListener('mouseup', onMouseup);
      window.removeEventListener('mousedown', onMousedown);
    };
  }, [onMousedown, onMouseup]);
  /**
   * 解决焦点会丢失
   */
  return (
    <VSTable
      id="order_common_out_patient_template_table"
      value={state}
      pagination={false}
      editable={editable}
      data-id={id}
      scroll={{
        y: getScrollYRem(25),
        x: '133.33rem',
      }}
      onRow={(record) => ({
        onDoubleClick: async () => {
          onTableRowOperation?.doubleClick?.(record);
        },
        onClick: async () => {
          onTableRowOperation?.click(record);
        },
        record,
      })}
      rowKey="id"
      ref={tableRef}
      rowClassName={render?.rowClassName}
      bordered
      onChange={(value) => {
        setValue(group?.current?.onSliced(value));
      }}
      onRecord={async () => {
        const record = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          isOther: false,
          is_focus: true,
        };
        edit.current = record.id;
        setTimeout(() => {
          tableRef?.current
            ?.getEditForm()
            ?.getFieldInstance([record?.id, 'orderText'])
            ?.focus();
          const table = document.querySelector(
            `[data-id="${id}"] .ant-table-body`,
          );
          table?.scrollTo(0, table?.scrollHeight);
        }, 200);
        return record;
      }}
      className="aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-vs-table-row-box"
      components={{
        body: {
          row: (props) => {
            const { record } = props;
            return (
              <Row
                {...props}
                dragNode={dragNode}
                transform={record?.transform}
              />
            );
          },
        },
      }}
      tableViewRender={(props, dom) => {
        return (
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
          >
            <SortableContext
              // rowKey array
              items={value
                // ?.filter((item) => item?.canDrag)
                .map((i) => i.id)}
              strategy={verticalListSortingStrategy}
            >
              {dom}
            </SortableContext>
          </DndContext>
        );
      }}
    >
      <VSTableColumn
        dataIndex={['sort']}
        valueType="sort"
        fieldProps={{}}
        width="1.33rem"
        editable={false}
      />
      <VSTableColumn
        title="类别"
        dataIndex={['orderClass']}
        valueType="select"
        dataSource={orderClassDataSource}
        preview
        width="2.6rem"
      />
      <VSTableColumn
        title="医嘱"
        dataIndex={['orderText']}
        valueType="dictionary"
        render={render.renderOrderText}
        fieldProps={(...args) => {
          const [record] = args;
          const recode = value?.find((item) => item?.id === record?.id);
          return {
            width: '100%',
            handleExtraSubmit,
            onDrugFetch: fetch?.onDrugFetch,
            onDrugDetailsFetch: fetch?.onDrugDetailsFetch,
            skinTest: false,
            hospitalType: 'out',
            className: recode?.groupName,
            record: recode,
            showDepartment: false,
            showStorage: false,
            showRepertory: false,
            showStorageName: false,
            getDisabled: group?.current?.disabled,
            onChange: async (orderText) => {
              const form: any = tableRef?.current?.getEditForm() || {};
              const key = record?.id;
              const { id, detailInfo, ...rest } = orderText || {};
              const orderClass = rest?.orderClass ?? detailInfo?.orderClass;
              await form?.resetFields(
                [
                  ['dosage'],
                  ['usage'],
                  ['administration'],
                  ['dripSpeed'],
                  ['frequency'],
                  ['useDescription'],
                  ['performDays'],
                  ['totalAmount'],
                  ['drugBillingAttribute'],
                ]?.map((item) => [key, ...item]),
              );
              switch (orderClass) {
                case 'DRUG':
                  {
                    // eslint-disable-next-line no-case-declarations
                    const {
                      drugEo: { groupNumber, groupSubNumber, ...drug },
                    } = detailInfo || {};
                    const int = askFixed(
                      (drug?.dosage ?? 0) / (detailInfo?.dosagePerUnit ?? 0),
                      2,
                    );
                    const drugOrder = {
                      unitPrice: detailInfo?.retailPrice, // 存疑
                      dosage: {
                        value: drug?.dosage,
                        unit: drug?.dosageUnit ?? detailInfo?.unit,
                      },
                      usage: {
                        value: int,
                        unit: drug?.packageUnit,
                      },
                      totalAmount: {
                        unit:
                          vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                            (item) => item?.value == drug?.packageUnit,
                          )?.label ?? drug?.packageUnit,
                        value: 0,
                      },
                      skinTestContinueUseIndicator:
                        rest?.skinTestContinueUseIndicator,
                      skinTestContinueReason: rest?.skinTestContinueReason,
                      amountPerPackage: detailInfo?.amountPerPackage,
                      dosagePerUnit: detailInfo?.dosagePerUnit,
                      administration: drug?.administration,
                      frequency: drug?.frequency,
                      useDescription: drug?.useDescription,
                    };
                    form.setFieldsValue({
                      [key]: {
                        orderClass: 'DRUG',
                        drugOrder,
                        ...drugOrder,
                        drugPriceItemId: drug?.drugPriceItemId,
                        performDepartmentId: rest?.storageDepartmentId,
                        performDepartment: {
                          departmentName: rest?.storageName,
                          id: rest?.storageDepartmentId,
                        },
                        uuid: record?.uuid ?? getUUID(),
                      },
                    });
                  }
                  break;
                case 'TREAT':
                case 'OTHER': {
                  const data = {
                    orderClass,
                    orderText: rest?.clinicItemName,
                    disposalOrder: {
                      itemId: rest?.clinicItemId,
                      itemName: rest?.clinicItemName,
                    },
                    drugOrder: {
                      totalAmount: {
                        value: 1,
                        unit: undefined,
                      },
                      itemId: rest?.clinicItemId,
                      itemName: rest?.clinicItemName,
                    },
                  };
                  form?.setFieldsValue({
                    [key]: data,
                  });
                }
              }
            },
            dataSource: getValue(),
          };
        }}
        width="17.86rem"
        preview={(...[, record]) => {
          return skinTestPreview(record);
        }}
        className="aspirin-table-order-cell"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        title="剂量"
        dataIndex={['dosage']}
        valueType="dosage"
        width="6.66rem"
        formItemProps={{
          rules: [
            {
              required: true,
            },
            {
              validator: (...[, value]) => {
                if ([undefined, null].includes(value?.value)) {
                  return Promise?.reject('剂量不能为空');
                } else if ((value?.value ?? value) <= 0) {
                  return Promise?.reject('剂量不能小于0');
                } else {
                  return Promise?.resolve();
                }
              },
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            record: value?.find((item) => item?.id === record?.id),
            allowClear: true,
            dataSource: value,
            id: String(record?.id),
            path: {
              performDays: ['performDays'],
              totalAmount: ['totalAmount'],
              frequency: ['frequency'],
              usage: ['usage'],
            },
          };
        }}
        preview={(...[, record]) => countablePreview(record)}
        render={(...[, record]) => <DosageUnit value={record?.dosage} />}
      />
      <VSTableColumn
        title="用量"
        dataIndex={['usage']}
        valueType="usage"
        width="8rem"
        formItemProps={{
          rules: [
            {
              required: true,
            },
            {
              validator: (...[, value]) =>
                value?.value
                  ? Promise?.resolve()
                  : Promise?.reject('用量不能为空'),
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            allowClear: true,
            record: value?.find((item) => item?.id === record?.id),
            id: String(record?.id),
            path: {
              dosage: ['dosage'],
            },
          };
        }}
        preview={(...[, record]) => countablePreview(record)}
        render={(...[, record]) => <UsageUnit value={record?.usage} />}
      />
      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="administrationSelect"
        preview={(...[, record, index]) =>
          groupPreview(index) || countablePreview(record)
        }
        fieldProps={(record) => {
          return {
            record,
            allowClear: false,
            showSearch: true,
            placeholder: '请选择途径',
            onInputKeyDown: (event) => {
              search.current = [search?.current?.at(-1) ?? '', event?.key];
              const [one, two] = search?.current;
              if (one === '/' && two === 'Enter') {
                onGroup(void 0);
              }
            },
            onChange: () => {
              const form: any = tableRef?.current?.getEditForm() || {};
              const key = record?.id;
              form?.resetFields(
                [['dripSpeed'], ['administrationAmount']]?.map((item) => [
                  key,
                  item,
                ]),
              );
            },
          };
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        width="6.66rem"
      />
      <VSTableColumn
        dataIndex={['frequency']}
        title="频次"
        valueType="select"
        preview={(...[, record, index]) => {
          return frequencyPreview(record, index);
        }}
        width="6.66rem"
        dataSource={(option) => {
          const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
            (item) => {
              return {
                ...item,
                ...JSON?.parse(item?.extensionJson ?? '{}'),
              };
            },
          );
          const state = option?.frequency
            ? value?.filter((item) =>
                askSearch(option?.frequency, item, 'frequency'),
              )
            : value;
          return state;
        }}
        render={(...[, record]) => {
          return record?.frequency?.frequency ?? record?.frequency?.label ?? '';
        }}
        fieldNames={{
          label: 'frequency',
          value: 'frequency',
        }}
        fieldProps={(record) => {
          return {
            allowClear: false,
            placeholder: '请选择频次',
            showSearch: true,
          };
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['useDescription']}
        title="用法"
        valueType="descriptionSelect"
        fieldProps={(record) => {
          const useDescription = description?.({
            ...record,
            key: record?.id,
          });
          return {
            allowClear: true,
            showSearch: true,
            required: useDescription,
          };
        }}
        width="6.66rem"
        formItemProps={(...[, recode]) => {
          const useDescription = description?.(recode);
          return useDescription
            ? {
                rules: [
                  {
                    required: true,
                  },
                ],
              }
            : {};
        }}
        render={(...[, record]) => {
          const { orderClass } = record || {};
          const target =
            orderClass === 'HERB'
              ? vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT
              : vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;
          return (
            <>
              {
                target?.find(
                  (item: any) => item?.value === record?.useDescription,
                )?.label
              }
            </>
          );
        }}
        preview={(...[, record, index]) => {
          return groupPreview(index) || countablePreview(record);
        }}
      />
      <VSTableColumn
        dataIndex={['performDays']}
        title="天数"
        valueType="digit"
        width="5.33rem"
        fieldProps={{
          addonAfter: '天',
          // allowClear: false,
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
            {
              pattern: positiveInteger,
              message: positiveIntegerMessage,
            },
          ],
        }}
        preview={(...[, record, index]) =>
          groupPreview(index) || countablePreview(record)
        }
        render={(_, value) => {
          return value?.performDays ? value?.performDays + '天' : '';
        }}
      />
      <VSTableColumn
        dataIndex={['totalAmount']}
        title="数量"
        valueType="inputUnit"
        fieldProps={{
          allowClear: true,
          width: '100%',
        }}
        width="4.66rem"
        preview={(...[, record]) => !countablePreview(record)}
        render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
        formItemProps={{
          rules: [
            {
              required: true,
            },
            {
              validator: (...[, value]) => {
                if ([undefined, null].includes(value?.value)) {
                  return Promise?.reject('不能为空');
                } else if ((value?.value ?? value) <= 0) {
                  return Promise?.reject('不能小于0');
                } else {
                  return Promise?.resolve();
                }
              },
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        valueType="text"
        width="6.66rem"
      />
      <VSTableColumn
        dataIndex={['drugBillingAttribute']}
        title="自备"
        valueType="check"
        width="2.66rem"
        render={(...[, record]) => {
          return (
            <Determine condition={record?.orderClass === 'DRUG'}>
              <Flex justify="center">
                <Determine condition={record?.drugBillingAttribute === true}>
                  <Icon type="icon-dui" size={24} className="icon-color-dui" />
                </Determine>
              </Flex>
            </Determine>
          );
        }}
      />
      <VSTableColumn
        dataIndex={['edit']}
        title="操作"
        valueType="oper"
        renderFormItem={(...[, record]) => {
          return (
            <Button
              type="text"
              onClick={async () => {
                if (!record?.record?.is_one) {
                  tableRef?.current?.cancelEdit();
                } else {
                  if (record?.record?._skin) {
                    const tableData: any[] = getValue?.() ?? [];
                    setValue(
                      tableData?.filter(
                        (item) =>
                          !record?.record?._skinTest?.includes(item?.id),
                      ),
                    );
                  } else if (record?.record?.drugOrder?.groupSubNumber) {
                    /**
                     * 针对插入成组的行, 需要纠正 groupSubNumber
                     */
                    const array = group?.current?.onSliced(
                      getValue().filter(
                        (item) => item?.id !== record?.record?.id,
                      ),
                    );
                    const list = await group.current?.onCancelInsert(
                      array,
                      getValue().filter(
                        (item, index) =>
                          record?.record?.groupIndex?.includes(index) &&
                          record?.record?.id !== item?.id,
                      ),
                    );
                    setValue(list);
                  } else {
                    tableRef?.current?.remove(record?.record);
                  }
                }
                footer?.current?.group(true);
              }}
            >
              取消
            </Button>
          );
        }}
        render={(...[, record]) => {
          return (
            <div className="aspirin-table-operation-container">
              <Determine
                condition={
                  !['PERFORM', 'SUBMIT']?.includes(record?.orderStatus) &&
                  !record?.parentRelatedUuid
                }
              >
                <Determine condition={!agreedPrescriptionIndicator}>
                  <Tooltip placement="top" title="插入">
                    <Icon
                      type="icon-xiangshangcharu"
                      size={20}
                      onClick={() => onInsert(record)}
                    />
                  </Tooltip>
                </Determine>
                <Tooltip placement="top" title="删除">
                  <Icon
                    type="icon-wudishanchu"
                    size={20}
                    onClick={() => operation?.delete(record)}
                  />
                </Tooltip>
              </Determine>
            </div>
          );
        }}
        fixed="right"
        width="4.66rem"
      />
    </VSTable>
  );
});

OutHospitalTemplate.displayName = 'OutHospital';
OutHospitalTemplate.Header = Header;
OutHospitalTemplate.Auxiliary = Auxiliary;
export default OutHospitalTemplate;
