import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getOperationHistoryById_290262: {
    method: 'post',
    url: '/api/clinic-record-history/get-operation-history-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-operation-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallOperationDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OperationDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
