import { createServices } from '@vs/vsf-boot';
export default createServices({
  CdssController_cdssOrderOperate_6f218b: {
    method: 'post',
    url: '/api/external-cdss/cdss-order-operate',
    parameterFomatter: (data?: {
      patientId: number;
      operateBlockedOrderEo: OperateBlockedOrderEoExternalCdssPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssOrderResponse_bf4f9f: {
    method: 'post',
    url: '/api/external-cdss/cdss-order-response',
    parameterFomatter: (data?: {
      patientId: number;
      saveOrderEoList: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: FilterOrderVoExternalCdssEntranceWebVo) =>
      data,
  },
});
