import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  VSTable,
  Form,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2InpTransferRecordTable,
  convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo,
} from '../convert';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import IdentityDocuments from '@/pages/Index/components/identity_documents';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

const formItemWidth = 240;
const tempTrue = true;
const tempFalse = false;

const Index = (props) => {
  const { data } = props;

  // 获取总费用
  const getTotalCost = () => {
    return data?.filter((item) => {
      return item?.summaryMedicalRecordChargeClassName == '住院费用（元）';
    })?.[0];
  };

  const flattenTree = (tree) => {
    const flatList = [];
    const traverse = (node) => {
      if (node.subInpBillingDetail && node.subInpBillingDetail?.length > 0) {
        for (let child of node.subInpBillingDetail) {
          flatList.push(child);
          traverse(child);
        }
      }
    };
    traverse(tree);
    return flatList;
  };

  const renderItem = (item, index) => {
    return item?.map((i) => {
      return (
        <div
          key={i?.summaryMedicalRecordChargeClassStandardCode}
          className="list_item_right_item"
        >
          <div className="list_item_right_item_label">
            {i?.level &&
              i?.level == 'two' &&
              ` (${i?.summaryMedicalRecordChargeClassStandardCode})`}
            {i?.summaryMedicalRecordChargeClassName}：
          </div>
          <div className="list_item_right_item_value">
            {i?.cost?.toFixed(2)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="homepageinfo_cost_info hideScroll">
      <div className="total">
        <div className="total_left">
          {getTotalCost()?.summaryMedicalRecordChargeClassName}
        </div>
        <div className="total_right">
          <div className="total_right_item">
            <div className="total_right_item_label">
              {
                getTotalCost()?.subInpBillingDetail?.[0]
                  ?.summaryMedicalRecordChargeClassName
              }
              ：
            </div>
            <div className="total_right_item_value">
              {getTotalCost()?.subInpBillingDetail?.[0]?.cost?.toFixed(2)}
            </div>
          </div>
          <div className="total_right_item">
            <div className="total_right_item_label">
              {
                getTotalCost()?.subInpBillingDetail?.[1]
                  ?.summaryMedicalRecordChargeClassName
              }
              ：
            </div>
            <div className="total_right_item_value">
              {getTotalCost()?.subInpBillingDetail?.[1]?.cost?.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className="list">
        {data
          ?.filter((item) => {
            return (
              item?.summaryMedicalRecordChargeClassName !== '住院费用（元）'
            );
          })
          ?.map((item) => {
            return {
              ...item,
              summaryMedicalRecordChargeClassStandardCode:
                item?.summaryMedicalRecordChargeClassStandardCode?.slice(1),
            };
          })
          .sort(function (a, b) {
            return (
              a?.summaryMedicalRecordChargeClassStandardCode -
              b?.summaryMedicalRecordChargeClassStandardCode
            );
          })
          ?.map((item, index) => {
            //  二级加level: 'two'，（）
            const _item = item?.subInpBillingDetail?.map((i) => {
              return { ...i, level: 'two' };
            });
            const res = flattenTree({ ...item, subInpBillingDetail: _item });
            return (
              <div key={index} className="list_item">
                <div className="list_item_left">
                  {item?.summaryMedicalRecordChargeClassStandardCode}.
                  {item?.summaryMedicalRecordChargeClassName}：
                </div>
                <div className="list_item_right">{renderItem(res, index)}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default definePage(Index);
