import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_deletePatientAllergy_625a54: {
    method: 'post',
    url: '/api/clinic-record-history/delete-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: DeletePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_getPatientAllergyByPatientId_576705: {
    method: 'post',
    url: '/api/clinic-record-history/get-patient-allergy-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo[],
    ) => data,
  },
});
