import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSPromiseTable,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  Divider,
  message,
  Pagination,
} from '@vs/vsf-kit';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PriceListEdit from './edit';
import { getScrollY, labelColWidth } from '@/utils';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import Screen, { CreateScreen } from '@/pages/Index/components/screen';
import { askFlatTree } from '@/pages/Index/components/func/ask';
import { Flex } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { UsageUnit } from '@/pages/Index/components/usage';
import { labelNumberWidth, getScrollYRem } from '@/utils';

// import { Pagination } from 'antd'
const PriceListTable = (props) => {
  const onScreen = useContext(CreateScreen);
  const [dataSource, setDataSource] = useState([]);
  const [current] = useOnChangeValue(0);
  const [pageSize] = useOnChangeValue(100);
  const [total] = useOnChangeValue([]);
  const onFetch = useCallback(
    async (params) => {
      const { pagination, ...rest } = params;
      const ask = Object.values(rest)
        ?.map((item) => Boolean(Object?.keys(item)?.length))
        .includes(true);
      if (!props?.value?.code && !ask) {
        return {
          data: [],
          total: 0,
        };
      }
      const search = params.search
        ? Object?.keys(params.search)
            ?.filter(
              (key) => ![null, void 0, '']?.includes(params?.search?.[key]),
            )
            ?.map((key) => [key, params?.search?.[key]])
        : [];
      const res =
        await vsf?.services?.PriceListDtoController_getAllByPriceItemQueryNewQtoByPriceItemQueryNewQto_43676c?.(
          {
            qto: {
              orderList: params.orderList,
              ...(props.value
                ? {
                    itemClassIn: askFlatTree([props?.value], 'code'),
                  }
                : {}),
              from: current?.value,
              size: 100,
              ...Object?.fromEntries(search),
              ...params.filters,
            },
          },
        );
      if (res?.code === 200)
        total?.onChange(res?.data?.count ?? res?.data?.length ?? 0);
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props.value, current.value, pageSize?.value],
  );

  const getDataSource = async () => {
    const res =
      await vsf?.services?.BaseCodeDtoServiceController_getByCodeType_5f9695?.({
        codeType: 'RECK_ITEM_CLASS_DICT',
      });
    setDataSource(
      res.data?.map((item) => {
        return {
          label: item.name,
          value: item.code,
        };
      }),
    );
  };

  const onAdd = async (params) => {
    try {
      params.firmId = params.firm?.id;
      params.medicalRecordClass = params.medicalRecordClass.at(-1);
      const res =
        await vsf?.services?.PriceListBOController_createPriceItem_f7480e?.({
          btoParam: params,
          extApiParam: params?.extApiParam,
        });
      if (res.code === 200) {
        message.success('新增价格项目成功');
        vsf.refs.PriceTable.reload();
        vsf?.refs?.PriceTree?.handleReload();
      }
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdate = async (params) => {
    params.firmId = params.firm?.id;
    params.medicalRecordClass = params.medicalRecordClass.at(-1);
    const res =
      await vsf?.services?.PriceListBOController_updatePriceItem_a055ad?.({
        btoParam: params,
        extApiParam: params?.extApiParam,
      });
    if (res.code === 200) {
      message.success('修改价格项目成功');
      vsf.refs.PriceTable.reload();
    }
    return res?.data;
  };

  const onStop = (...arr) => {
    const [, data] = arr;
    Tip({
      onOk: async () => {
        const res = await vsf?.services?.[
          !data.stop
            ? 'PriceListBOController_stopPriceItem_66be27'
            : 'PriceListBOController_startPriceItem_3cfa75'
        ]?.({ id: data.id });
        if (res.code === 200) {
          message.open({
            type: 'success',
            content: `${data.stop ? '启' : '停'}用【${data.itemName}】已${
              data.stop ? '启' : '停'
            }用`,
          });
          vsf.refs.PriceTable.reload();
        }
        return res?.data;
      },
      cancelText: '取消',
      content: `确定${data.stop ? '启' : '停'}用{【${data.itemName}】}项目?`,
      okButtonProps: {
        danger: !data.stop,
      },
      title: '操作窗口',
    });
  };

  const onExport = () => {
    message.success('待做');
  };

  useEffect(() => {
    getDataSource();
  }, []);

  const Footer = () => {
    return (
      <div
        className="aspirin-table-footer-button"
        style={{
          justifyContent: 'space-between',
          padding: '0 24px 24px',
        }}
      >
        <Pagination
          current={current?.value}
          pageSize={pageSize?.value}
          defaultPageSize={1}
          total={total?.value}
          showSizeChanger
          onChange={(size, form) => {
            pageSize?.onChange?.(form);
            current?.onChange(size);
          }}
        />
        <Flex gap={10}>
          <ButtonAndKeyDown
            type="primary"
            onClick={onScreen}
            methodKey="altKey"
            ordinaryKey="R"
            ghost
          >
            清屏
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            type="primary"
            onClick={onExport}
            methodKey="altKey"
            ordinaryKey="E"
          >
            导出
          </ButtonAndKeyDown>
        </Flex>
      </div>
    );
  };

  return (
    <Block
      footer={<Footer />}
      className="aspirin-table-no-background-box"
      style={{
        padding: 0,
      }}
    >
      <VSPromiseTable
        className={[
          'aspirin-table',
          'aspirin-table-search-margin-no',
          'aspirin-table-list-toolbar-container',
        ]?.join(' ')}
        id="PriceTable"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          extraOperations: [
            {
              children: (node) => {
                return node.stop ? (
                  <Icon type="icon-a-bianjijinyong" size={24} />
                ) : (
                  <Icon type="icon-bianji" size={24} />
                );
              },
              show: (node) => node.stop,
              disabled: (node) => node.stop,
              order: 1,
            },
            {
              children: (node) => {
                return node.stop ? (
                  <Icon type="icon-a-Frame4372" size={24} />
                ) : (
                  <Icon type="icon-tingyong" size={24} />
                );
              },
              onClick: onStop,
              order: 1,
            },
          ],
          onCanDelete: () => null,
          onCanEdit: (node) => !node.stop,
          columnProps: {
            width: labelNumberWidth(10),
          },
        }}
        bordered
        rowClassName="aspirin-vs-table-row-box"
        searchFormRender={(props, dom) => (
          <>
            <SearchContainer>{dom}</SearchContainer>
            <Divider />
          </>
        )}
        searchConfig={{
          labelWidth: labelNumberWidth(5),
          labelAlign: 'left',
          collapsed: false,
          collapseRender: false,
          optionRender: (...arr) => {
            const [, , [other, search]] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {[
                  other,
                  <ButtonAndKeyDown
                    methodKey="altKey"
                    ordinaryKey="Q"
                    {...search?.props}
                  >
                    查询
                  </ButtonAndKeyDown>,
                ]}
              </SearchContainer.Header>
            );
          },
        }}
        pagination={false}
        scroll={{
          y: getScrollYRem(40),
          x: 3000,
        }}
        vsid="74671"
        onFetch={onFetch}
        onAdd={onAdd}
        onUpdate={onUpdate}
        addLinkPage={{
          linkPage: () => <PriceListEdit data={props.value} />,
          onLinkPageValidate: async () => {
            try {
              await vsf.refs.PriceListForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              return false;
            }
          },
          modalProps: {
            title: '新增价格项目',
            className: 'aspirin-linkpage',
            width: '50%',
          },
        }}
        updateLinkPage={{
          linkPage: () => <PriceListEdit />,
          onLinkPageValidate: async () => {
            try {
              await vsf.refs.PriceListForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              return false;
            }
          },
          modalProps: {
            title: '修改价格项目',
            className: 'aspirin-linkpage',
            width: '50%',
          },
          updateType: 'reload',
        }}
      >
        <VSTableColumn
          dataIndex={['stop']}
          title="状态标识"
          valueType="select"
          dataSource={[
            { label: '启用', value: false },
            { label: '停用', value: true },
          ]}
          width={labelNumberWidth(7)}
          render={(...[, record]) => {
            return (
              <Flex justify="center">
                {!record?.stop ? (
                  <Icon type="icon-dui" size={24} className="icon-color-dui" />
                ) : (
                  <Icon type="icon-cuo" size={24} className="icon-color-cuo" />
                )}
              </Flex>
            );
          }}
          fieldProps={{}}
          search
          columnKey="stop"
          searchFieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="拼音码"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['itemId']}
          title="项目ID"
          valueType="text"
          search
          order={-157}
          columnKey={'itemIdIs'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['standardCode']}
          title="标准编码"
          valueType="text"
          search
          order={-155}
          columnKey={'standardCodeLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['insuranceItemCode']}
          title="自编码"
          valueType="text"
          search
          order={-152}
          columnKey={'insuranceItemCodeLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['insuranceNationCode']}
          title="国家编码"
          valueType="text"
          search
          order={-153}
          columnKey={'insuranceNationCodeLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          search
          order={-151}
          columnKey={'itemNameLike'}
          width={300}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          dataSource={dataSource}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="custom"
          fieldProps={{}}
          width={labelNumberWidth(6)}
          ellipsis={{
            showTitle: true,
          }}
          render={(...[, record]) => (
            <UsageUnit value={{ unit: record?.unit }} />
          )}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          valueType="digit"
          search
          order={-158}
          columnKey={'priceIs'}
          width={labelNumberWidth(6)}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['biddingCode']}
          title="中标编码"
          valueType="text"
          search
          order={-156}
          columnKey={'biddingCodeLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['brand']}
          title="品牌"
          valueType="text"
          search
          order={-159}
          columnKey={'brandLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['outpUseIndicator']}
          title="门诊"
          valueType="switch"
          fieldProps={{}}
          width={labelNumberWidth(4)}
          render={(element) => <Flex justify="center">{element}</Flex>}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['inpUseIndicator']}
          title="住院"
          valueType="switch"
          fieldProps={{}}
          width={labelNumberWidth(4)}
          render={(element) => <Flex justify="center">{element}</Flex>}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['singleSettleIndicator']}
          title="独立结算"
          valueType="switch"
          fieldProps={{}}
          width={labelNumberWidth(6)}
          render={(element) => <Flex justify="center">{element}</Flex>}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['lastModifyStaff', 'staffName']}
          title="最近操作员"
          valueType="text"
          fieldProps={{}}
          width={labelNumberWidth(8)}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['lastModifyDate']}
          title="最近维护时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={labelNumberWidth(10)}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['reviewModifyStaff', 'staffName']}
          title="复核操作员"
          valueType="text"
          fieldProps={{}}
          width={labelNumberWidth(8)}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['reviewModifyDate']}
          title="复核时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['firm', 'firmName']}
          title="厂商"
          valueType="text"
          fieldProps={{}}
          width={200}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['branchInstitution']}
          title="院区字符串"
          valueType="text"
          fieldProps={{}}
          width={labelNumberWidth(8)}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['medicalRecordClass']}
          title="病案首页分类"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.MR_FEE_CLASS_DICT}
          fieldProps={{}}
          width={labelNumberWidth(10)}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="项目规格"
          valueType="text"
          search
          order={-154}
          columnKey={'itemSpecificationLike'}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          search
          order={-160}
          columnKey={'memoLike'}
          ellipsis={{
            showTitle: true,
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['stopDate']}
          title="停用时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableToolbar
          title="价格项目列表"
          className="aspirin-toolbar-padding-top-box"
        >
          <VSTableAddition children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Block>
  );
};

const PriceItem = (props) => {
  return (
    <Screen>
      <PriceListTable {...props} />
    </Screen>
  );
};

export default definePage(PriceItem);
