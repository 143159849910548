import React, { useEffect, useState } from 'react';

import { Section, Todo } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

const StandardApplicationTree = (props) => {
  const { onAdd, onDel, onEdit } = props;

  return (
    <>
      <TreeMenu
        id="StandardApplicationTree"
        title="数据分类"
        reload
        loadData={[
          async () => {
            const res =
              await vsf?.services?.ApplicationDtoController_getApplication_fbf818(
                {
                  template: true,
                },
              );
            return res;
          },
          async () => {
            const res =
              await vsf?.services?.ApplicationDtoController_getApplication_fbf818(
                {
                  template: false,
                },
              );
            return res;
          },
        ]}
        fieldNames={{ title: 'name', key: 'id', children: 'children' }}
        dataRelationFieldNames={{
          id: 'id',
          parentId: 'templateApplicationId',
        }}
        search={{
          status: true,
        }}
        row={{
          expand: true,
          extra: (node) => {
            return !node?.template ? (
              <>
                <Icon
                  type="icon-bianji"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit?.(node);
                  }}
                  size={24}
                />
                <Icon
                  style={{
                    marginLeft: '4px',
                  }}
                  type="icon-shanchu1"
                  onClick={(e) => {
                    e.stopPropagation();
                    Tip({
                      content: `是否删除：{【${node.name}】}?`,

                      onOk: () => {
                        onDel?.(node);
                      },
                    });
                  }}
                  size={24}
                />
              </>
            ) : (
              <>
                <Icon
                  type="icon-tianjia"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAdd?.(node);
                  }}
                  size={24}
                />
              </>
            );
          },
          extraShow: 'hover',
        }}
        onSelect={(_value) => {
          vsf.refs.BusinessTable?.getSearchForm()?.resetFields();
          _value?.template === false && props.onChange?.(_value);
        }}
      />
    </>
  );
};
export default definePage(StandardApplicationTree);
