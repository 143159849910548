import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpRegisterMainController_getPatientInfo_b111a4: {
    method: 'post',
    url: '/api/clinic-outp-register-main/get-patient-info',
    parameterFomatter: (data?: { patientDisplayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegisterPatientInfoEoClinicOutpRegisterMainPersistEo,
    ) => data,
  },
  ClinicOutpRegisterMainController_getRegisterPaymentInfo_8f478e: {
    method: 'post',
    url: '/api/clinic-outp-register-main/get-register-payment-info',
    parameterFomatter: (data?: {
      outpApportionPreSettleEo: OutpApportionPreSettleEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterPaymentInfoVoClinicOutpRegisterMainEntranceWebVo,
    ) => data,
  },
  ClinicOutpRegisterMainController_register_7c692c: {
    method: 'post',
    url: '/api/clinic-outp-register-main/register',
    parameterFomatter: (data?: {
      outpSettleInputParameterEo: OutpSettleInputParameterEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PatientMainController_getPatientWithProfileByDisplayId_40245d: {
    method: 'post',
    url: '/api/patient-main/get-patient-with-profile-by-display-id',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientWithProfileVoPatientMainEntranceWebVo,
    ) => data,
  },
});
