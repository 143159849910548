/* eslint-disable react-hooks/exhaustive-deps */
import './index.less';

import { Tabs } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

const Index = (props) => {
  const { dataSource, onChange, defaultValue, value, ...rest } = props;
  const [activeKey, setActiveKey] = useState<string>(dataSource?.[0]?.key);

  const [init, setInit] = useState<boolean>(false);

  const handleChange = useCallback(
    (v) => {
      const target = dataSource?.find((item) => item?.key === v);
      onChange?.(v, target);
    },
    [dataSource, onChange],
  );

  useEffect(() => {
    if (value) {
      setActiveKey(value);
    }
  }, [value]);
  // className="aspirin-tab-check"
  console.log(dataSource, 'Drawer_dataSource');
  console.log(activeKey, 'Drawer_activeKey');
  return (
    <div>
      <Tabs
        items={dataSource}
        // type="card"
        {...rest}
        activeKey={activeKey}
        onChange={handleChange}
      />
    </div>
  );
};

export default Index;
