import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSTable,
  VSTableAddition,
  Button,
  Input,
  message,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetState, useLockFn } from 'ahooks';
import { getScrollYRem } from '@/utils';
import Icon from '@/module/Icon';
import {
  askRomanAlphabet,
  askJustOnce,
} from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { useFetch } from '@/pages/Index/components/func/hooks';

const ExamMethod = (props) => {
  const [oldState, setOldState] = useState([]);
  const [newState, setNewState, getNewState] = useGetState([]);
  const onFetch = useCallback(async (id) => {
    const res =
      await vsf?.services?.ExamMethodDictionaryDtoController_getByExamClassId_994cf6?.(
        { examClassId: id },
      );
    setOldState(res.data);
    setNewState(res.data.reverse());
  }, []);

  const onSubmit = useFetch({
    fetch: async () => {
      if (props?.value?.id) {
        const { add, update, remove } = askJustOnce({
          oldState,
          newState: getNewState(),
          id: 'id',
        });
        const addMethodToExamClassBtoList = [
          {
            id: props?.value?.id,
            examMethodDictionaryBtoList: getNewState()?.filter(
              (item, index) => {
                const id = add.map((item) => item.id).includes(item.id);
                if (id) {
                  item.serialNumber = index + 1;
                  delete item.id;
                }
                return id;
              },
            ),
          },
        ];
        const updateExamMethodBtoList = update.length
          ? [
              {
                id: props?.value?.id,
                examMethodDictionaryBtoList: update,
              },
            ]
          : [];
        const removeMethodFromExamClassBtoList = remove.length
          ? [
              {
                id: props?.value?.id,
                examMethodDictionaryBtoList: remove,
              },
            ]
          : [];
        const res =
          await vsf?.services?.ExamClassDictionaryBOController_addAndUpdateExamMethod_bb5113?.(
            {
              addMethodToExamClassBtoList,
              updateExamMethodBtoList,
              removeMethodFromExamClassBtoList,
            },
          );
        return res;
      }
    },
    message: '保存检查方法',
    success: () => {
      onFetch(props.value.id);
    },
  });

  useEffect(() => {
    if (props?.value?.id) {
      onFetch(props.value.id);
    }
  }, [props?.value?.id]);
  const Footer = () => {
    return (
      <div
        className="aspirin-table-footer-button"
        style={{ justifyContent: 'flex-end', paddingTop: 24 }}
      >
        <ButtonAndKeyDown
          onClick={async () => {
            const save = await vsf?.refs?.MethodTable?.saveEdit();
            if (save) {
              onSubmit?.();
            }
          }}
          type="primary"
          methodKey="altKey"
          ordinaryKey="S"
        >
          保存
        </ButtonAndKeyDown>
      </div>
    );
  };
  return (
    <Block
      height={getScrollYRem(16)}
      className="aspirin-block-no-padding"
      footer={<Footer />}
    >
      <VSTable
        id="MethodTable"
        editable={{
          editType: 'single',
          columnProps: {
            width: 180,
          },
          onFieldChange: (key, value, allValue, form) => {
            if (value.examMethodName) {
              form.setFieldValue(key, {
                ...allValue,
                inputCode: askRomanAlphabet(value.examMethodName),
              });
            }
          },
          onCanDelete: () => null,
          editText: <Icon type="icon-bianji" size={24} />,
          saveText: <Icon type="icon-a-Frame437" size={24} />,
          extraOperations: [
            {
              onClick: (_, data) => {
                if (oldState.map((item) => item.id).includes(data.id)) {
                  Tip({
                    title: '操作窗口',
                    content: `确定删除{【${data.examMethodName}】}，删除后不可恢复！`,
                    onOk: () => {
                      vsf.refs.MethodTable.remove(data);
                    },
                    centered: true,
                    okText: '确定',
                    cancelText: '取消',
                  });
                } else {
                  vsf.refs.MethodTable.remove(data);
                }
              },
              children: <Icon type="icon-shanchu" size={24} />,
              order: 1,
            },
          ],
        }}
        vsid="97182"
        value={newState}
        scroll={{
          y: getScrollYRem(28),
        }}
        bordered
        className="aspirin-table-box-no aspirin-table-no-background-box"
        pagination={false}
        rowClick={{
          rowClickType: {
            type: 'edit-save',
            action: 'click',
          },
        }}
        onRecord={async () => {
          const len = newState.length;
          return {
            id: crypto.randomUUID?.(),
            examMethodCode:
              props.value?.inputCode + (len < 100 ? `00${len}` : len),
          };
        }}
        onChange={setNewState}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['examMethodCode']}
          title="检查方法编号"
          valueType="text"
          renderFormItem={(schema, recode, form) => {
            return (
              <Input
                onChange={schema.originProps.change}
                disabled={oldState
                  .map((item) => item.id)
                  .includes(recode.record.id)}
              />
            );
          }}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 20,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['examMethodName']}
          title="检查方法名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 100,
                required: true,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="拼音输入码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 50,
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (String(value).includes(',')) {
                    return Promise.reject('多音字待确认');
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ],
          }}
          fieldProps={{}}
        />
        <VSTableToolbar title="检查方法列表">
          <VSTableAddition children="添加" />
        </VSTableToolbar>
      </VSTable>
    </Block>
  );
};
export default definePage(ExamMethod);
