import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Dropdown,
  Input,
  message,
  Modal,
  Popover,
  Tabs,
} from '@vs/vsf-kit';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import TipModal from '@/pages/Index/components/func/TipModal';

type CpoeCollectDataSource = {
  label: string;
  value: string;
  amount: string | number;
};

export type CpoeCollectProps = {
  /**
   * 收藏列表
   */
  dataSource?: CpoeCollectDataSource[];
  /**
   * 收藏分类名
   */
  favoriteDescription?: string;
  /**
   * 值
   */
  childrens?: React.ReactNode;
  /**
   * 取消收藏
   */
  cancelCollect?: (value?: string) => void;
  /**
   * 收藏
   */
  collect?: (value?: string) => void;
  /**
   * 删除收藏
   */
  deleteCollect?: (e, value?: string) => void;
  onDropdownOpen?: (value?: any) => void;
  getCollectClassifyList?: any;
};

const items = [
  {
    label: '时间排序',
    key: 'time',
  },
  {
    label: '字母排序',
    key: 'letter',
  },
];

const Index = (props: CpoeCollectProps) => {
  const {
    dataSource = [],
    childrens,
    favoriteDescription,
    deleteCollect,
    cancelCollect,
    collect,
    onDropdownOpen,
    getCollectClassifyList,
  } = props;

  // 鼠标经过选中项
  const [currentItem, setCurrentItem] = useState();
  const [type, setType] = useState('look');
  const [open, setOpen] = useState(false);
  // 收藏列表
  const [dataList, setDataList] = useState([]);
  const [addValue, setAddValue] = useState<string>();
  useEffect(() => {
    setType('look');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpenChange = async (newOpen: boolean) => {
    setOpen(newOpen);
    if (newOpen) {
      const res = await getCollectClassifyList();
      setDataList(res);
    }
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const handelCollect = (value, type = 'change') => {
    if (type === 'add') {
      const _res = dataSource?.some((item) => {
        return item?.label === addValue;
      });
      if (_res) {
        message.info('已有相同分类的名称，请重新创建分类');
      } else {
        collect?.(value);
      }
    } else {
      collect?.(value);
    }
  };

  const handelCancelCollect = (value) => {
    cancelCollect?.();
  };

  const handelDeleteCollectClassify = (e, value) => {
    deleteCollect?.(value);
  };

  const renderContent = (item) => {
    return (
      <div
        onMouseOver={() => {
          setCurrentItem(item?.value);
        }}
        className={cn(
          currentItem === item?.value
            ? 'collect_classify_main_list_active'
            : '',
          'collect_classify_main_list',
        )}
      >
        <div style={{ flex: 1, marginRight: 8 }}>
          <Input
            style={{ backgroundColor: '#fff', height: '26px' }}
            bordered={false}
            readOnly={true}
            defaultValue={item?.label}
          />
        </div>
        {currentItem === item?.value && type === 'look' && (
          <div>
            <Button
              onClick={() => {
                if (favoriteDescription === item?.label) {
                  handelCancelCollect(item);
                } else {
                  handelCollect(item);
                }
              }}
              type="primary"
              size="small"
            >
              {favoriteDescription === item?.label ? '取消收藏' : '收藏'}
            </Button>
          </div>
        )}
        {currentItem === item?.value && type === 'setting' && (
          <div>
            <Button
              onClick={(e) => {
                handelDeleteCollectClassify(e, item);
              }}
              type="primary"
              size="small"
            >
              删除
            </Button>
          </div>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    // 查看
    if (type === 'look') {
      return (
        <div className="collect_classify_footer_space collect_classify_footer_setting">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setType('setting');
            }}
            style={{ display: 'flex', cursor: 'pointer' }}
          >
            <Icon
              style={{ color: '#3276E8', marginRight: 5 }}
              type="icon-shezhi"
              size={20}
            />
            <div>设置分类</div>
          </div>
          <Dropdown
            onOpenChange={(v) => {}}
            menu={{
              items,
              onClick: ({ key, keyPath, item, domEvent }) => {
                domEvent?.stopPropagation();
                // handelSort(key);
              },
            }}
          >
            <div
              style={{ display: 'flex', color: '#3276E8', cursor: 'pointer' }}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                排序方式
              </div>
              <Icon type="icon-xia" size={16} />
            </div>
          </Dropdown>
        </div>
      );
    }
    // 设置
    if (type === 'setting') {
      return (
        <div className="collect_classify_footer_text collect_classify_footer_space">
          <div
            onClick={() => {
              setType('add');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Icon
              style={{ marginRight: 5 }}
              size={16}
              type="icon-tianjiagongneng"
            />
            新增分类
          </div>
          <div>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setType('look');
              }}
            >
              取消
            </Button>
          </div>
        </div>
      );
    }
    // 新增
    if (type === 'add') {
      return (
        <div className="collect_classify_footer_add collect_classify_footer_space">
          <Input
            onChange={(e) => {
              setAddValue(e?.target?.value);
            }}
          />
          <div className="collect_classify_footer_add_btn">
            <Button
              onClick={() => {
                setType('setting');
              }}
              className="collect_classify_footer_add_cancel"
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handelCollect({ label: addValue }, 'add');
                setType('setting');
              }}
            >
              确定
            </Button>
          </div>
        </div>
      );
    }
  };

  const renderDropdown = () => {
    const data = dataSource?.length > 0 ? dataSource : dataList;

    return (
      <div className="collect_classify">
        <div className="collect_classify_header">
          <div className="collect_classify_header_title">
            请选择病例收藏分类
          </div>
          <div
            onClick={() => {
              setOpen(false);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Icon type="icon-shanchu1" size={20} />
          </div>
        </div>
        <div className="collect_classify_main">
          {data?.map((item, index) => {
            return <div key={index}>{renderContent(item)}</div>;
          })}
        </div>
        <div className="collect_classify_footer">{renderFooter()}</div>
      </div>
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown
        onOpenChange={handleOpenChange}
        open={open}
        trigger={['click']}
        dropdownRender={renderDropdown}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {childrens}
        </div>
      </Dropdown>
    </div>
  );
};

export default Index;
