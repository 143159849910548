import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-pagefall-by-fall-out-visit-detail-qto',
    parameterFomatter: (data?: {
      qto: FallOutVisitDetailQtoClinicOutpVisitPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
