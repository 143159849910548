import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  TabPane,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import Block from '@/pages/Index/components/block_no_padding';
import { idCard, idCardMessage, passport, passportMessages } from '@/pattern';
import { getScrollY } from '@/utils';

// import './index.less';

const Index = (props) => {
  const { open, handelOpenModal, list: lists } = props;
  const { user, common } = props?.stores ?? {};
  const { initKey, currentPatient, inVisitInfo } = user ?? {};
  const { currentApplication } = common || {};
  const [isConfirm, setIsConfirm] = useState(true);

  const [list, setList] = useState<any>([]);

  const tableRef = useRef<any>(null);

  const convertVSFormPatientFullInfo2CreateOrUpdatePatientReq = (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.btoParam = {};
    output.btoParam.id = currentPatient?.patientId;
    output.btoParam.patientIdentificationBtoList = input;
    output.extApiParam = {};
    return output;
  };

  // const getList = useCallback(async () => {
  //   if (!currentPatient?.patientId) return;
  //   const response =
  //     await vsf?.services?.PatientMainController_getIdentificationsByPatientId_af9e3b(
  //       {
  //         patientId: currentPatient?.patientId,
  //       },
  //     );
  //   const _response = response?.data?.filter((item) => {
  //     return (
  //       item?.identificationClass !== '身份证' &&
  //       item?.identificationClass !== '身份证-手输'
  //     );
  //   });
  //   console.log(_response);

  //   if (_response?.length > 0) {
  //     setList(_response);
  //   } else {
  //     setList([
  //       {
  //         id: `create_${Math.random()}`,
  //       },
  //     ]);
  //   }
  // }, [currentPatient]);
  const originList = cloneDeep(lists);

  useEffect(() => {
    if (!open) return;
    if (lists?.length > 0) {
      setList(lists);
    } else {
      setList([
        {
          id: `create_${Math.random()}`,
        },
      ]);
    }
    // setList(lists);
  }, [currentPatient?.patientId, lists, open]);

  const handelAdd = (row) => {
    setList([...list, { id: 'create_' + Math.random() }]);
  };

  const handelDelete = async (row) => {
    if (!String(row.id).startsWith('create_')) {
      Modal.confirm({
        title: '删除',
        content: `${row?.identificationClass}已保存，确认删除？`,
        centered: true,
        onOk: async () => {
          const res = await vsf.refs.identificationClass.remove(row);
          if (res) {
            const res1 = await vsf.refs.identificationClass?.getValues();
            if (res1?.length > 0) {
              setList(res1);
            } else {
              setList([
                {
                  id: `create_${Math.random()}`,
                },
              ]);
            }
          }
        },
        okText: '确定',
        cancelText: '取消',
      });
    } else {
      const res = await vsf.refs.identificationClass.remove(row);
      if (res) {
        const res1 = await vsf.refs.identificationClass?.getValues();
        if (res1?.length > 0) {
          setList(res1);
        } else {
          setList([
            {
              id: `create_${Math.random()}`,
            },
          ]);
        }
      }
    }
  };
  //校验选择的证件类型是否已经选过
  const handelSelectChange = (value, id) => {
    const valueIndex = list?.find((item) => {
      return item?.identificationClass == value && item?.id !== id;
    });
    if (valueIndex) {
      const _tempList = list?.map((item, index) => {
        return item?.id == id ? { id: item?.id } : { ...item };
      });
      setIsConfirm(false);
      // vsf?.refs?.identificationClass?.getEditForm()?.setFieldValue();
      return message.error('当前证件类型已存在，请勿重复添加');
    } else {
      setIsConfirm(true);
    }
  };

  // 保存弹窗
  const handelOk = () => {
    if (!isConfirm) return message.error('有重复证件');
    handelOpenModal(
      false,
      vsf?.refs?.identificationClass
        .getValues()
        ?.filter((i) => i?.identificationClass),
    );
  };

  return (
    <Modal
      title={<div className="modal_title_className">证件及编号信息</div>}
      open={open}
      centered
      width={680}
      bodyStyle={{
        minHeight: 300,
        maxHeight: 600,
      }}
      // destroyOnClose={false}
      onCancel={() => {
        console.log(
          vsf?.refs?.identificationClass.getValues(),
          '身份证',
          originList,
        );

        handelOpenModal(false, originList, 'cancel');
      }}
      onOk={handelOk}
      // onOk={async () => {
      //   const _validateFields = await vsf?.refs?.identificationClass
      //     ?.getEditForm()
      //     ?.validateFieldsReturnFormatValue();
      //   if (_validateFields) {
      //     const params =
      //       convertVSFormPatientFullInfo2CreateOrUpdatePatientReq(list);
      //     const res =
      //       await vsf.services?.PatientMainController_updateOtherPatientIdentification_904aa0?.(
      //         params,
      //       );
      //     if (res?.code == 200) {
      //       message.info('保存成功');
      //       handelOpenModal(false);
      //     }
      //   }
      // }}

      okText="确认"
      cancelText="取消"
    >
      <VSTable
        id="identificationClass"
        ref={tableRef}
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
        }}
        bordered
        pagination={false}
        value={list}
        scroll={{ y: getScrollY(500) }}
      >
        <VSTableColumn
          dataIndex={['identificationClass']}
          valueType="select"
          title="证件类型"
          width={200}
          dataSource={vsf?.stores?.dbenums?.enums?.IDENTIFICATION_CLASS_DICT}
          fieldProps={(row) => {
            return {
              showSearch: true,
              onSelect: (value) => {
                handelSelectChange(value?.value, row?.id);
              },
              onClear: () => {
                setIsConfirm(true);
              },
            };
          }}
          formItemProps={() => {
            return {
              rules: [{ message: '请选择证件类型', required: true }],
            };
          }}
        />
        <VSTableColumn
          dataIndex={['identificationNumber']}
          valueType="text"
          title="证件编号"
          width={320}
          formItemProps={(...args) => {
            const [form, other] = args;
            const data = form.getFieldValue([other?.rowKey?.[0] as string]);
            const _rules =
              data?.identificationClass == '身份证'
                ? {
                    pattern: idCard,
                    message: idCardMessage,
                  }
                : data?.identificationClass == '护照'
                ? {
                    pattern: passport,
                    message: passportMessages,
                  }
                : {};
            return {
              rules: [
                { message: '请输入证件编号', required: true },
                { ..._rules },
              ],
            };
          }}
        />
        <VSTableColumn
          title="操作"
          valueType="text"
          fieldProps={{}}
          width={100}
          renderFormItem={(dom, recode, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 10,
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    visibility:
                      dom?.index === list?.length - 1 ? 'visible' : 'hidden',
                  }}
                  onClick={() => {
                    handelAdd(recode?.record);
                  }}
                >
                  <Icon type="icon-tianjia" size={20} />
                </div>

                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handelDelete(recode?.record);
                  }}
                >
                  <Icon type="icon-youdishanchu1" size={20} />
                </div>
              </div>
            );
          }}
        />
      </VSTable>
    </Modal>
  );
};

export default definePage(Index);
