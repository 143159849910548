import './index.less';

import {
  AutoComplete,
  AutoCompleteProps as AntAutoCompleteProps,
  compose,
  SelectProps as AntSelectProps,
  TreeSelectProps as AntTreeSelectProps,
  useControllableState,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { Select, TreeSelect } from 'antd';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { askSearch, buildTree, convertData } from './utils';

type dtoResType = {
  code?: number;
  message?: string;
  data?: any[];
};

type qtoResType = {
  code?: number;
  message?: string;
  data?: {
    result?: any[];
    [key: string]: any;
  };
};

type loadDataType = () => Promise<dtoResType | qtoResType>;

type FieldNames = {
  label?: string;
  value?: string;
};

export type SelectRef = {
  // handleReload: () => void;
};

type SelectProps = Omit<AntSelectProps, 'fieldNames'> & {
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
  /**
   * 值变化回调
   */
  onChange?: (value?: any, options?: any) => void;
  /**
   * 动态加载数据
   */
  loadData?: loadDataType | loadDataType[];
  /**
   * 是否是树状下拉框
   */
  isTreeSelect?: boolean;
  /**
   * 是否瀑布流加载
   */
  lazy?: boolean;
  /**
   * 是否需要buildData处理数据
   */
  isNeedBuildData?: boolean;
  /**
   * tree 关系映射
   */
  dataRelationFieldNames?: {
    id: string;
    parentId: string;
  };
  /**
   * 动态加载数据的设置根数据源key
   */
  loadDataKey?: string;
  /**
   * 动态加载数据的根数据源设置之后, 设置是否需要合并的数据源key
   */
  combineDataKey?: string;
  /**
   * 静态数据
   */
  dataSource?: any[];
  /**
   * 新增数据配置
   */
  addItemOptions?: {
    status: boolean;
    mode: 'single' | 'multiple';
  };
  /**
   * 自定义节点 label, value 的字段
   * @field [label, value]
   */
  fieldNames?: FieldNames;
  /**
   * 自定义如何渲染一个item, 比fieldNames更加灵活
   * @field [node: 当前节点, rootData: 根节点信息(loadDataKey设置之后的本身信息), parentData: 父节点信息(combineDataKey设置之后的本身信息)]
   */
  customGenObj?: (node, parentData, rootData) => any;
  /**
   * 组件根节点 classname
   */
  className?: string;
  /**
   * select 组件 props
   * @component Select
   * @componentProp omit [fieldNames]
   */
  selectProps?: AntSelectProps;
  /**
   * treeSelect 组件 props
   * @component TreeSelect
   * @componentProp omit [fieldNames]
   */
  treeSelectProps?: AntTreeSelectProps;
  /**
   * autoComplete 组件 props
   */
  autoCompleteProps?: AntAutoCompleteProps;
} & {
  ref?: React.Ref<SelectRef>;
};

const defaultFieldNames = {
  label: 'label',
  value: 'value',
};

const defaultSelectProps = {
  fieldNames: {
    label: 'label',
    value: 'value',
    children: 'children',
  },
};

/**
 * 下拉框(阿司匹林)
 */
let AspirinSelect: any = (props: SelectProps, ref?: React.Ref<SelectRef>) => {
  const {
    className,
    selectProps = {},
    treeSelectProps = {},
    autoCompleteProps = {},
    dataRelationFieldNames,
    loadData,
    loadDataKey,
    lazy = false,
    isTreeSelect = false,
    isNeedBuildData = true,
    addItemOptions,
    fieldNames,
    combineDataKey,
    defaultValue,
    value: valueProp,
    customGenObj,
    dataSource: staticDataSource,
    onChange = () => {},
    ...rest
  } = props;

  const [value, setValue] = useControllableState({
    defaultValue: defaultValue,
    value: valueProp,
    onChange,
  });

  const [AutoCValue, setAutoCValue] = useState<any>();
  // console.log(value, AutoCValue, '_obj');

  const [open, setOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>();
  const [originDataSource, setOriginDataSource] = useState<any[]>();
  const [filterTotalData, setFilterTotalData] = useState<any[]>();

  const [init, setInit] = useState<boolean>(false);

  const searchRef = useRef<any>();

  const request = async (loadData) => {
    if (!loadData) return;
    if (Array.isArray(loadData)) {
      return await Promise.all(loadData?.map((item) => item?.()));
    }
  };

  const buildData = useCallback(
    async (staticDataSource, loadData) => {
      let resData = staticDataSource;
      if (loadData) {
        let target: any;
        if (Array.isArray(loadData)) {
          target = await request(loadData);
          resData = target.flatMap((item: any) => item.data);
        } else {
          target = await loadData?.();
          resData = target?.data?.result ?? target?.data;
        }
      }
      const target = isTreeSelect
        ? buildTree(resData ?? [], dataRelationFieldNames, null)
        : resData;
      setOriginDataSource(resData);
      return convertData({
        data: target,
        fieldNames,
        defaultFieldNames,
        loadDataKey,
        combineDataKey,
        customGenObj,
      });
    },
    [
      fieldNames,
      loadDataKey,
      combineDataKey,
      customGenObj,
      dataRelationFieldNames,
      isTreeSelect,
    ],
  );

  const getAutoCInputValue = (v) => {
    const target = filterTotalData?.find((item) => item?.realValue === v);
    // console.log(target, v, 'pppppppppppo');
    if (target) {
      return target?.label;
    } else {
      return v;
    }
  };

  const getData = useCallback(async () => {
    const targetData = isNeedBuildData
      ? await buildData(staticDataSource, loadData)
      : staticDataSource;
    const isAutoC = addItemOptions?.status && addItemOptions?.mode === 'single';

    const newDataSource = targetData?.map((item, index) => {
      // console.log(item, targetData, 'pppp11');

      if (isAutoC) {
        return {
          ...item,
          realValue: item?.value,
          value: item?.label,
          key: index,
        };
      } else {
        return item;
      }
    });
    setDataSource(newDataSource);
    if (isAutoC) {
      setFilterTotalData(newDataSource);
      setAutoCValue(getAutoCInputValue(value));
    }
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedBuildData, staticDataSource, loadData, buildData, addItemOptions]);

  const handleOpen = (v) => {
    setOpen(v);
  };

  const handleChange = (v, option) => {
    if (Array.isArray(v)) {
      setValue(v);
    } else {
      if (addItemOptions?.status && addItemOptions?.mode === 'single') {
        setAutoCValue(v);
        const target = filterTotalData?.find((item) => item?.value === v);
        onChange?.(target?.realValue ?? v);
      } else {
        setValue(v);
        setOpen(false);
      }
    }
  };

  const handleAutoCSelect = (v, option) => {
    onChange?.(option?.realValue);
  };

  const [autoCSearch, setAutoCSearch] = useState<string>('');

  const handleSearch = async (value) => {
    if (addItemOptions?.mode === 'single' && addItemOptions?.status) {
      // setValue([]);
      setOpen(true);
      setAutoCSearch(value);
    } else {
      selectProps?.onSearch?.(value);
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const defaultAddItemOptionsMode = addItemOptions?.mode ?? 'multiple';

  const addProps = addItemOptions?.status
    ? {
        mode: 'tags',
      }
    : {};

  const extraSelectProps = {
    ...(selectProps?.showSearch
      ? {
          optionFilterProp: 'label',
        }
      : {}),
  };

  const handleTreeSelectChange = (value, label, extra) => {
    setValue({
      value: value,
      originDataSource: originDataSource,
    });
  };

  if (isTreeSelect) {
    return (
      // @ts-ignore
      <TreeSelect
        // {...defaultSelectProps}
        {...treeSelectProps}
        ref={searchRef}
        treeData={dataSource}
        className={classNames('aspirin-tree-select', `${className}`)}
        value={value?.value}
        onChange={handleTreeSelectChange}
      ></TreeSelect>
    );
  }

  if (addItemOptions?.status && addItemOptions?.mode === 'single') {
    const formatDataSource = filterTotalData?.filter((item) => {
      return (
        `${item?.realValue}`?.indexOf(autoCSearch) >= 0 ||
        `${item?.label}`?.indexOf(autoCSearch) >= 0 ||
        askSearch(autoCSearch, item, fieldNames?.label ?? 'label')
      );
    });
    return (
      <AutoComplete
        {...autoCompleteProps}
        allowClear
        value={AutoCValue}
        ref={searchRef}
        options={formatDataSource}
        onSearch={handleSearch}
        onChange={handleChange}
        onSelect={handleAutoCSelect}
        onDropdownVisibleChange={handleOpen}
        open={open}
      />
    );
  }

  return (
    // @ts-ignore
    <Select
      {...defaultSelectProps}
      {...selectProps}
      {...extraSelectProps}
      {...addProps}
      allowClear={true}
      ref={searchRef}
      open={open}
      onDropdownVisibleChange={handleOpen}
      options={dataSource}
      className={classNames('aspirin-select', `${className}`, {
        'aspirin-select-tags-multiple':
          defaultAddItemOptionsMode === 'multiple',
        'aspirin-select-tags-single': defaultAddItemOptionsMode === 'single',
      })}
      value={value}
      onChange={handleChange}
      onSearch={handleSearch}
    ></Select>
  );
};

AspirinSelect = forwardRef<SelectRef, SelectProps>(AspirinSelect);
AspirinSelect.displayName = 'AspirinSelect';

export default compose(
  withRef(),
  withField<string>({
    name: AspirinSelect.displayName,
    valueType: 'aspirinSelect',
    // handleEnter: true,
    shouldFieldUpdate: (newly: any, old: any) => {
      return true;
    },
  }),
  withPreview<SelectProps>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      const _value = props?.dataSource?.find(
        (item) => item?.value == props?.value,
      )?.label;
      return <>{_value ?? props?.value}</>;
    },
  }),
)(AspirinSelect) as typeof AspirinSelect;
