import { definePage } from '@vs/vsf-boot';
import { Table } from '@vs/vsf-kit';
import { useAsyncEffect, useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import React from 'react';

import { ComplexSelect } from '@/pages/Index/components';

import {
  getByDrugPriceItemId,
  getPagedProfileDrugProduct,
} from '../../service';
const Index = (props) => {
  const {
    placeholder,
    width = '12rem',
    columns = [
      {
        width: '16rem',
        title: '药品名称',
        dataIndex: ['drugProductName'],
        key: 'drugProductName',
      },
      {
        width: '6rem',
        title: '包装规格',
        dataIndex: ['packageSpecification'],
        key: 'packageSpecification',
      },
      {
        width: '16rem',
        title: '生产厂家',
        dataIndex: ['firmName'],
        key: 'firmName',
      },
    ],
    allowClear = false,
  } = props;
  const {
    data: dataSource,
    run,
    loading,
  } = useRequest(
    async (params) => {
      const res = await getPagedProfileDrugProduct({
        ...params,
      });
      return res;
    },
    { manual: true },
  );
  useAsyncEffect(async () => {
    run?.({
      inputCodeLike: '',
      herbType: props?.herbType,
    });
  }, [run, props?.herbType]);
  return (
    <ComplexSelect
      listHeight={1000}
      mode="radio"
      placeholder={placeholder}
      hideSelectIcon
      showLabel="label"
      value={props?.value ?? ''}
      onChange={async (value) => {
        if (Object?.values(value)?.includes('')) {
          props?.onChange('');
          props?.getChangeParams?.('');
          return;
        }
        const res = await getByDrugPriceItemId({
          drugPriceItemId: value?.id,
        });
        props?.onChange?.(res?.drugProductName ?? '');
        props?.getChangeParams?.(res);
      }}
      onSearch={async (inputCodeLike) =>
        run?.({
          inputCodeLike,
          herbType: props?.herbType,
        })
      }
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: `${
          columns?.reduce((current, next) => {
            return (
              parseInt(String(current)) +
              parseInt(String(next?.width?.replace('rem', '')))
            );
          }, 0) + 8
        }rem`,
      }}
      allowClear={allowClear}
    >
      <Table
        className="halo-table drug_spec_info_table common_table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          y: 400,
        }}
        bordered
      />
    </ComplexSelect>
  );
};
Index.displayName = 'HerbStorageDurgSelect';
export default definePage(Index);
