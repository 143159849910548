import React from 'react';

const mock = ['放射[胸部CT平扫+肺结节重建]', '纵隔淋巴结肿大'];

const Index = (props) => {
  const { data } = props;

  const handelList = () => {
    return data?.filter((item) => {
      return item?.orderApplyDto?.abnormal;
    });
  };

  return (
    <div className="warning-block">
      {data?.map((item, index) => (
        <div className="item" key={index}>
          <div
            style={{
              marginRight: '40px',
              width: '225px',
            }}
          >
            {item?.examItemName}
          </div>
          <div>{item?.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Index;
