import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo,
  convertGetMedicalRecordVoByIdRes2DiagnosisInfoForm,
  convertGetMedicalRecordVoByIdRes2DiagnosisRecordTable,
  convertGetMedicalRecordVoByIdRes2OperationRecordTable,
  convertGetMedicalRecordVoByIdRes2OperationInfoForm,
  convertGetMedicalRecordVoByIdRes2MedicalRecordOtherInfoForm,
  convertGetMedicalRecordVoByIdRes2BillingInfoTable,
  convertGetMedicalRecordVoByIdRes2IcuRecordTable,
} from './convert';
import dayjs from 'dayjs';
import moment from 'moment';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import Detail from './content/index';
import PatientInfo from './content/patientInfo';

import './index.less';

const Index = (props) => {
  const { user, common } = props?.stores ?? {};
  const { initKey, currentPatient, inVisitInfo, setInitKey } = user ?? {};
  const { setCurrentApplication } = common || {};
  const [detail, setDetail] = useState();
  const [isDisabled, setIsdisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveKey] = useState('diagnostic-info');
  const [tabList, setTabList] = useState();

  useEffect(() => {
    getMedicalRecord();
  }, [inVisitInfo]);

  const getMedicalRecord = async () => {
    // activeTab.current = 'patient-info';
    setIsdisabled(true);
    setIsLoading(true);
    if (!inVisitInfo?.inpVisitId) {
      setIsLoading(false);
      setIsdisabled(true);
      return;
    }
    const res =
      await vsf?.services?.ClinicInpVisitController_getMedicalRecordVoById_150e6f?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          id: inVisitInfo?.inpVisitId,
        },
      );
    if (res?.code == 200) {
      setIsLoading(false);
      // 患者信息convert
      const _patientInfo =
        convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo?.(res);
      // 患者信息转科记录
      const inpTransferRecordForMedicalRecordDtoList =
        _patientInfo?.inpTransferRecordForMedicalRecordDtoList?.concat(
          Array(
            3 - _patientInfo?.inpTransferRecordForMedicalRecordDtoList.length,
          ).fill({
            admissionDateTime: '',
            departmentName: '',
          }),
        );
      // 诊断信息convert
      const _diagnosticInfo =
        convertGetMedicalRecordVoByIdRes2DiagnosisRecordTable?.(res);
      // 根据码表获取诊断类型
      const diagnosisList = vsf.stores.dbenums?.enums?.DIAGNOSIS_TYPE_DICT;
      // 门诊诊断、入院初诊、出院诊断固定的诊断在最前面
      const tempDiagnosisList = diagnosisList
        ?.filter((item) => {
          return item?.code == '1';
        })
        ?.concat(
          diagnosisList?.filter((item) => {
            return item?.code == '2';
          }),
        )
        ?.concat(
          diagnosisList?.filter((item) => {
            return item?.code == '3';
          }),
        )
        ?.concat(
          diagnosisList?.filter((item) => {
            return (
              item?.code !== '1' && item?.code !== '2' && item?.code !== '3'
            );
          }),
        );
      const _tempDiagnosisListArr = [];
      tempDiagnosisList?.forEach((item) => {
        const res = _diagnosticInfo?.filter((i) => {
          return i?.diagnosisType == item?.code;
        });

        if (res?.length > 0) {
          _tempDiagnosisListArr.push(res);
        } else {
          _tempDiagnosisListArr.push([
            {
              id: 'create_' + Math.random(),
              diagnosisType: item?.code,
            },
          ]);
        }
      });

      const _tempDiagnosisList = _tempDiagnosisListArr?.map((item) => {
        return item?.map((_item, _index) => {
          if (_index == 0) {
            return { ..._item, isDelete: false };
          } else {
            return { ..._item, isDelete: true };
          }
        });
      });

      //  诊断信息form
      const _diagnosticForm =
        convertGetMedicalRecordVoByIdRes2DiagnosisInfoForm?.(res);

      // 手术信息table
      const _operationInfo =
        convertGetMedicalRecordVoByIdRes2OperationRecordTable?.(res) ?? [];
      // 手术信息form
      const _operationInfoForm =
        convertGetMedicalRecordVoByIdRes2OperationInfoForm?.(res) ?? {};
      // 其他信息from
      const _otherInfoForm =
        convertGetMedicalRecordVoByIdRes2MedicalRecordOtherInfoForm?.(res) ??
        {};
      // 其他信息table
      const _otherInfoTable =
        convertGetMedicalRecordVoByIdRes2IcuRecordTable?.(res) ?? [];
      // 费用信息
      const _billingInfoForm = res?.data?.inpBillingDetail ?? [];
      setDetail({
        patientInfo: {
          ..._patientInfo,
          inpTransferRecordForMedicalRecordDtoList,
        },
        diagnosticInfo: _tempDiagnosisList?.flat(),
        diagnosticForm: _diagnosticForm,
        operationInfo: _operationInfo,
        operationInfoForm: _operationInfoForm,
        otherInfoForm: _otherInfoForm,
        otherInfoTable:
          _otherInfoTable?.length > 0
            ? _otherInfoTable
            : [{ id: 'create_' + Math.random() }],
        billingInfoForm: _billingInfoForm,
      });
      setIsdisabled(false);
    } else {
      setIsLoading(false);
      setIsdisabled(true);
    }
  };
  // console.log(detail?.patientInfo);
  const tabItems = [
    {
      label: '患者信息',
      key: 'patient-info',
      disabled: isDisabled,
      children: (
        <Detail
          detail1={detail?.patientInfo}
          value="patient-info"
          patientIdentificationList={
            detail?.patientInfo?.patientIdentificationDtoList
          }
          getMedicalRecord={getMedicalRecord}
        />
      ),
    },
    {
      label: '诊断信息',
      key: 'diagnostic-info',
      disabled: isDisabled,
      children: isDisabled ? (
        <Empty />
      ) : (
        <Detail
          detail2={detail?.diagnosticInfo}
          detail={detail}
          diagnosticForm={detail?.diagnosticForm}
          value="diagnostic-info"
          getMedicalRecord={getMedicalRecord}
          patientIdentificationList={
            detail?.patientInfo?.patientIdentificationDtoList
          }
        />
      ),
    },
    {
      label: '手术信息',
      key: 'operation-info',
      disabled: isDisabled,
      children: (
        <Detail
          detail3={detail?.operationInfo}
          operationForm={{
            ...detail?.operationInfoForm,
            admissionDateTime: detail?.patientInfo?.admissionDateTime,
          }}
          value="operation-info"
          getMedicalRecord={getMedicalRecord}
          patientIdentificationList={
            detail?.patientInfo?.patientIdentificationDtoList
          }
        />
      ),
    },
    {
      label: '其他信息',
      key: 'other-info',
      disabled: isDisabled,
      children: (
        <Detail
          detail4={detail?.otherInfoForm}
          otherInfoTable={detail?.otherInfoTable}
          value="other-info"
          getMedicalRecord={getMedicalRecord}
          patientIdentificationList={
            detail?.patientInfo?.patientIdentificationDtoList
          }
        />
      ),
    },
    {
      label: '费用信息',
      key: 'cost-info',
      disabled: isDisabled,
      children: (
        <Detail
          detail5={detail?.billingInfoForm}
          value="cost-info"
          getMedicalRecord={getMedicalRecord}
          patientIdentificationList={
            detail?.patientInfo?.patientIdentificationDtoList
          }
        />
      ),
    },
    // {
    //   label: '产妇信息',
    //   key: '1',
    //   disabled: isDisabled,
    //   children: <Detail detail={detail?.patientInfo} value="1" />,
    // },
    // {
    //   label: '新生儿',
    //   key: '2',
    //   disabled: isDisabled,
    //   children: <Detail detail={detail?.patientInfo} value="2" />,
    // },
  ];

  return (
    <div className="homePageInfomation">
      <DndProvider backend={HTML5Backend}>
        {!isLoading ? (
          <Tabs
            onTabClick={(key) => {
              setActiveKey(key);
            }}
            defaultActiveKey={activeTab}
            // defaultActiveKey="diagnostic-info"
            activeTab={activeTab}
            id="tabs"
            items={tabItems}
          />
        ) : (
          <Loading size="normal" />
        )}
      </DndProvider>
    </div>
  );
};

export default definePage(Index);
