import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  Input,
  InputNumber,
  InputProps,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React from 'react';

import { useInputEnter } from '../dosage/utils';

/**
 * 合并单位的 Input
 */
let DripSpeed: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  return (
    <div>
      <InputNumber
        {...rest}
        ref={ref}
        value={value}
        onChange={onChange}
        addonAfter="滴/分"
        {...fieldEnterProps}
      />
    </div>
  );
};

DripSpeed.displayName = 'dripSpeed';

DripSpeed = React.forwardRef(DripSpeed);

export default compose(
  withRef(),
  withField<any>({
    name: 'dripSpeed',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        console.log(props, 'props1');
        return <>{props?.value ? props?.value + '滴/分' : ''}</>;
      },
    },
  ),
)(DripSpeed) as typeof DripSpeed;
