import Block from '@/module/Block';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Button,
  message,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import DurgManger from './drugManger';
import DrugPriceItemManagerTable from './drugPriceItemManagerTable';
import DrugProductManger from './drugProductManger';
import DrugProductMangerTable from './drugProductMangerTable';
import './index.less';

const DrugDicEditor = (props) => {
  const { drugInfo, drugItem } = props;
  const [drugItemState, setDrugItemState] = useState();

  useEffect(() => {
    if (drugItem) {
      setDrugItemState(drugItem);
    }
  }, [drugItem]);

  const [activeProduct, setActiveProduct] = useState();

  const handelSubmit = async () => {
    try {
      const _formParams =
        await vsf.refs?.DrugMangerForm?.validateFieldsReturnFormatValue();
      const drugProductBtoList =
        await vsf?.refs?.DrugProductMangerTable?.getValues();
      const drugPriceItemBtoList =
        await vsf?.refs?.DrugPriceItemManagerTable?.getValues();
      let formParams = {};


      // console.log(_formParams,'drug_formParams')

      for (const key in _formParams) {
        if (Object.hasOwnProperty.call(_formParams, key)) {
          formParams[key] =
            _formParams[key] === undefined ? null : _formParams[key];
        }
      }

      var formParamsDrug;

      if (formParams?.drug?.drugName?.drugName === undefined) {
        // console.log('编辑');
        // console.log(formParams.drug, 'drug_formParams.drug');
        formParamsDrug = { ...formParams.drug };
        
        // formParamsDrug = { ...formParams.drug,dangerIndicator:Array.isArray(formParams?.drug?.dangerIndicator)?true:false };
      } else {
        // console.log('新增');
        formParamsDrug = {
          ...formParams.drug,
          drugName: formParams?.drug?.drugName?.drugName,
          // dangerIndicator:Array.isArray(formParams?.drug?.dangerIndicator)?true:false 
        };
      }



      var btoParam = {
        ...formParams,
        drugProductBtoList: drugProductBtoList?.map((item) => {
          return {
            ...item,
            drugFirmId: item?.drugFirm?.id ?? item?.drugFirm?.firmName,
            ...item?.drugSpecificationDictionary,
            drugSpecificationDictionary: undefined,
            drugFirm: undefined,
            drugPriceItemBtoList:
              drugPriceItemBtoList?.[item?.id]?.map((_price) => ({
                ..._price,
                id:
                  typeof _price?.id === 'string' &&
                  _price?.id?.includes('create_')
                    ? undefined
                    : _price?.id,
                index: undefined,
              })) ?? [],
            id:
              typeof item?.id === 'string' && item?.id?.includes('create_')
                ? undefined
                : item?.id,
          };
        }),
        drug: undefined,
        drugDictionaryExtensionBto: {
          ...formParams,
          drug: undefined,
          id: formParams?.id ? formParams?.id : undefined,
        },
        id: formParams?.id ? formParams?.id : undefined,
        ...formParamsDrug,
        dangerIndicator:formParams?.dangerIndicator?true:false
      };

      // console.log(formParams,'是否高危')
      // console.log(formParamsDrug,'药品基础信息')
      console.log(formParams?.drug?.dangerIndicator,'drug_dangerIndicator')
      console.log(btoParam,'提交数据')

      

      const response =
        await vsf?.services?.DrugController_createOrUpdateDrugAll_da9cc0?.({
          btoParam,
        });

      if (response && response.code === 200) {
        message.success('保存成功');
        setDrugItemState({
          ...drugItemState,
          drug: {
            ...drugItemState?.drug,
            id: response?.data,
          },
        });
        // handelReset()
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handelProductSelectedChange = (v) => {
    setActiveProduct(v);
  };

  const handelReset = () => {
    setActiveProduct(undefined);
    setDrugItemState(undefined);
    vsf?.refs?.DrugPriceItemManagerTable?.reset();
  };

  const handelDrugChange = (_drug) => {
    if (_drug && _drug?.id !== drugItemState?.id) {
      handelReset();
      setDrugItemState(_drug);
    }
  };

  return (
    <Block scroll={{ x: false, y: true }}
      className='drug_dic_editor_block'
    >
      <div
        className="drugDicEditor"
        style={{
          flex: 1,
          height: '95%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <DurgManger
          drugId={drugItemState?.drug?.id}
          onDrugChange={handelDrugChange}
        />
        <DrugProductMangerTable
          id="DrugProductMangerTable"
          drugInfo={drugItemState}
          onSelectChange={handelProductSelectedChange}
        />
        <DrugPriceItemManagerTable
          id="DrugPriceItemManagerTable"
          drugInfo={drugItemState}
          activeProduct={activeProduct}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{ marginRight: 8 }}
          onClick={() => {
            vsf?.refs?.DrugMangerForm?.resetFields?.();
            handelReset();
          }}
        >
          重置
        </Button>
        <Button type="primary" onClick={handelSubmit}>
          保存
        </Button>
      </div>
    </Block>
  );
};
export default definePage(DrugDicEditor);
