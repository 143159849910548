import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  message,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';

import Loading from '@/module/Loading';
import {
  ButtonAndKeyDown,
  ComplexSelect,
  Flex,
} from '@/pages/Index/components';
import { useFetch } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { CreateScreen } from '@/pages/Index/components/screen';
import { getScrollYRem } from '@/utils';

import { getPlanAndDispenseForInpRefundDrugApply } from './service';

const DrugSelect = (props) => {
  const { value, onChange } = props;

  const columns = [
    {
      dataIndex: ['drugProductInfo', 'drugProductName'],
      title: '药品商品名称',
    },
    {
      dataIndex: ['packageSpecification'],
      title: '规格',
    },
    {
      dataIndex: ['drugProductInfo', 'drugFirmInfo', 'firmName'],
      title: '厂家',
    },
  ];
  const {
    loading,
    data: dataSource,
    run: get,
  } = useRequest(
    async (inputCodeLike) => {
      const res =
        await vsf?.services?.DrugDrugController_queryProductWithCurrentPrice_4621c9?.(
          {
            qto: {
              inputCodeLike,
            },
          },
        );
      if (res?.code === 200) {
        return (res?.data?.result ?? [])?.map((item) => ({
          ...item,
          drugProductName: item?.drugProductInfo?.drugProductName,
          key: item?.id,
        }));
      }
    },
    {
      manual: true,
    },
  );

  const onSearch = (value) => {
    if (value?.trim()) {
      get?.(value);
    }
  };

  return (
    <ComplexSelect
      mode="radio"
      onSearch={onSearch}
      dropdownMatchSelectWidth={700}
      hideSelectIcon
      showLabel="drugProductName"
      value={value?.value ? value : ''}
      placeholder="请选择药品"
      allowClear
      onChange={(value) => {
        onChange?.({
          label: value?.drugProductName,
          value: value?.key,
        });
      }}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        showHeader={false}
        size="middle"
        loading={{
          indicator: (
            <Flex justify="center" align="center">
              <Loading />
            </Flex>
          ),
          spinning: loading,
        }}
        scroll={{
          y: 400,
        }}
      />
    </ComplexSelect>
  );
};

/**
 * 病区西药退药申请
 * @returns {JSX.Element}
 */
const ApplicationForWesternMedicineRefundInWard = (props) => {
  const onScreen = useContext(CreateScreen);
  const { inpVisitId } = props?.value || {},
    planDateTime = [
      dayjs()?.subtract(7, 'day')?.format('YYYY-MM-DD') + ' 00:00:00',
      dayjs()?.format('YYYY-MM-DD') + ' 23:59:59',
    ],
    performStatusIn = ['NOT_EXECUTED'],
    initialValue = {
      planDateTimeBiggerThanEqual: planDateTime?.[0],
      planDateTimeLessThan: planDateTime?.[1],
      performStatusIn,
    },
    search = useRef<any>(initialValue),
    table = useRef<any>(null),
    { data: InpStorageData, run: InpStorageRun } = useRequest(
      async () => {
        const res =
          await vsf?.services?.ClinicRecordOrderDrugController_getInpStorageIds_10643e?.(
            {
              temp: false,
            },
          );

        return res?.data ?? [];
      },
      { manual: true },
    );
  useEffect(() => InpStorageRun?.(), [InpStorageRun]);

  const onSave = useFetch({
    fetch: async () => {
      const input =
        vsf?.refs?.tableInpOrderApply
          ?.getValues?.()
          ?.filter((m) => m?.refundAmount > 0)
          .map((m) => ({
            planId: m?.orderPerformPlanRecordId,
            refundAmount: m?.refundAmount,
            drugPrescriptionDispenseDetailId:
              m?.drugPrescriptionDispenseDetailId,
          })) ?? [];
      if (input?.length <= 0) return message?.error('请输入退药数量再保存');
      const res: any =
        vsf?.services?.InpRefundDispenseController_saveInpDrugRefundApply_bf29f0?.(
          { applies: input },
        );
      return res;
    },
    message: '保存',
  });
  const { data, run, loading } = useRequest(
    async (params) => {
      if (!inpVisitId) {
        return {
          data: [],
          total: 0,
        };
      }
      const res = await getPlanAndDispenseForInpRefundDrugApply({
        inpVisitIdIs: inpVisitId,
        ...search?.current,
        ...params,
      });
      return res;
      // const res =
      //   await vsf?.services?.InpRefundDispenseController_getPlanAndDispenseForInpRefundDrugApply_f6c86d?.(
      //     {
      //       qto: {
      //         from: params?.pagination?.from ?? 0,
      //         size: params?.pagination?.size ?? 9999,
      //         ...params?.search,
      //         inpVisitIdIs: inpVisitId,
      //         ...search?.current,
      //         ...params,
      //       },
      //     },
      //   );
      // return {
      //   data: onSpliced?.(res),
      //   total: res?.data?.count ?? res?.data?.length ?? 0,
      // };
    },
    { manual: true },
  );
  useEffect(() => run?.({}), [inpVisitId, run]);

  return (
    <Flex
      vertical
      style={{
        height: '100%',
      }}
    >
      <Flex vertical>
        <div>
          <VSForm
            id="VSForm"
            labelAlign="left"
            onChange={(value) => {
              search.current = value;
            }}
          >
            {/* <VSFormLayout columnCount={4} labelCol={labelColWidth[7]}> */}
            <VSFormLayout columnCount={4}>
              <VSFormItem
                label="执行计划"
                valueType="limitTime"
                name={['planDateTimeBiggerThanEqual']}
                initialValue={planDateTime}
                fieldProps={{
                  day: 7,
                  format: 'YYYY-MM-DD',
                }}
                transform={(value) => {
                  const [planDateTimeBiggerThanEqual, planDateTimeLessThan] = (
                    value ?? []
                  )?.map((item) =>
                    dayjs?.(item)?.format('YYYY-MM-DD HH:mm:ss'),
                  );
                  return {
                    planDateTimeBiggerThanEqual,
                    planDateTimeLessThan,
                  };
                }}
              />
              <VSFormItem
                label="药品名称"
                valueType="custom"
                name={['drugPriceItemIdIs']}
                transform={(value) => {
                  return {
                    drugPriceItemIdIs: value?.value,
                  };
                }}
              >
                <DrugSelect />
              </VSFormItem>
              <VSFormItem
                label="执行状态"
                valueType="select"
                name={['performStatusIn']}
                fieldProps={{
                  mode: 'multiple',
                }}
                initialValue={performStatusIn}
                dataSource={
                  vsf?.stores?.dbenums?.enums?.ORDER_PERFORM_STATUS_ENUM ?? [
                    // vsf?.stores?.dbenums?.enums?.ORDER_PERFORM_PLAN_RECORD ?? [
                    { label: '未执行', value: 'NOT_EXECUTED' },
                    { label: '已执行', value: 'EXECUTED' },
                    { label: '执行中', value: 'PROCESSING' },
                    { label: '执行暂停', value: 'SUSPEND' },
                    { label: '执行作废', value: 'DEPRECATE' },
                  ]
                }
              />
              <VSFormItem
                label="发药库房"
                valueType="select"
                name={['performDepartmentIdIn']}
                fieldProps={{
                  mode: 'multiple',
                }}
                dataSource={InpStorageData}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <Flex gap={10} justify="flex-end">
          <Button type="primary" onClick={() => run?.({})}>
            查询
          </Button>
          <Button
            type="primary"
            ghost
            onClick={async () => {
              search.current = initialValue;
              await vsf?.refs?.VSForm?.resetFields?.();
              run?.({});
            }}
          >
            重置
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // search.current = {
              //   ...search.current,
              //   isGetStopPlanIs: true,
              // };
              // table?.current?.reload?.();
              run?.({ isGetStopPlanIs: true });
            }}
          >
            一键提取停医嘱未退药
          </Button>
          <Button
            type="primary"
            onClick={() => {
              // search.current = {
              //   ...search.current,
              //   isGetRefundApplyIs: true,
              // };
              // table?.current?.reload?.();
              run?.({ isGetRefundApplyIs: true });
            }}
          >
            提取已申请记录
          </Button>
        </Flex>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />
      <Flex flex={1} vertical justify="space-between">
        <VSTable
          loading={loading}
          value={data?.data}
          id="tableInpOrderApply"
          scroll={{
            // y: isMinScreen ? 400 : 550,
            y: getScrollYRem(36),
          }}
          // onFetch={onFetch}
          pagination={false}
          editable={{
            editType: 'multiple',
            onCanDelete: (data) => {
              return false;
            },
            columnProps: { hideInTable: true },
          }}
          ref={table}
          className="aspirin-table-no-background-box"
        >
          {/* <VSTableColumn
            width={'7.6rem'}
            dataIndex={['displayId']}
            title="患者ID"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />
          <VSTableColumn
            width={'4.5rem'}
            // width={68}
            dataIndex={['bedLabel']}
            title="床号"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />
          <VSTableColumn
            width={'5.8rem'}
            // width={88}
            dataIndex={['name']}
            title="患者姓名"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />
          <VSTableColumn
            width={'5.8rem'}
            // width={88}
            dataIndex={['gender']}
            title="性别"
            editable={false}
            valueType="select"
            fieldProps={{}}
            dataSource={vsf?.stores?.dbenums?.enums?.SEX_DICT}
            ellipsis
          />
          <VSTableColumn
            width={318}
            dataIndex={['orderText']}
            title="医嘱内容"
            editable={false}
            valueType="text"
            fieldProps={{}}
          /> */}
          {/* 药品医嘱 */}
          <VSTableColumn
            width={'21rem'}
            dataIndex={['orderText']}
            title="药品医嘱"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />

          {/* 长期/临时 */}
          <VSTableColumn
            width={'7rem'}
            dataIndex={['repeatIndicator']}
            title="长期/临时"
            editable={false}
            valueType="text"
            fieldProps={{}}
            render={(_, { repeatIndicator }) =>
              repeatIndicator ? '长期' : '临时'
            }
            ellipsis
          />

          {/* 包装规格 */}
          <VSTableColumn
            width={'11.2rem'}
            dataIndex={['packageSpecification']}
            title="包装规格"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />

          {/* 执行计划时间 */}
          <VSTableColumn
            width={'13rem'}
            dataIndex={['planDateTime']}
            title="执行计划时间"
            editable={false}
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            ellipsis
          />

          {/* 执行状态 */}
          <VSTableColumn
            width={'6rem'}
            dataIndex={['performStatus']}
            title="执行状态"
            editable={false}
            valueType="select"
            dataSource={
              // vsf?.stores?.dbenums?.enums?.ORDER_PERFORM_STATUS_ENUM ??
              [
                { label: '未执行', value: 'NOT_EXECUTED' },
                { label: '已执行', value: 'EXECUTED' },
                { label: '执行中', value: 'PROCESSING' },
                { label: '执行暂停', value: 'SUSPEND' },
                { label: '执行作废', value: 'DEPRECATE' },
              ]
            }
            fieldProps={{}}
            ellipsis
          />

          {/* 发药药房 */}
          <VSTableColumn
            width={'8rem'}
            dataIndex={['departmentName']}
            title="发药药房"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />

          {/* 可退药数量 */}
          <VSTableColumn
            width={'7.2rem'}
            dataIndex={['amountAndUnit']}
            // dataIndex={['billDetail']}
            title="可退药数量"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />

          {/* 退药数量 */}
          <VSTableColumn
            dataIndex={['refundAmount']}
            title="退药数量"
            width={'8rem'}
            valueType="digit"
            fieldProps={(record) => {
              return {
                // min: 1,
                addonAfter: record?.packageUnit,
              };
            }}
            formItemProps={(_, { entity }) => {
              const { id } = entity as { id };
              const record = vsf?.refs?.tableInpOrderApply
                ?.getValues()
                ?.find((e) => e?.id == id);
              const { amountAndUnit, packageUnit } = record as {
                amountAndUnit;
                packageUnit;
              };
              // 可退数量
              const refundadel = Number(
                amountAndUnit?.replace(packageUnit, ''),
              );
              return {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (value < 0) return Promise?.reject('数量不能为负数');
                      if (refundadel && value > refundadel)
                        return Promise?.reject('退药数量不能大于可退数量');
                      return Promise?.resolve();
                    },
                  },
                ],
              };
            }}
            ellipsis
          />

          {/* <VSTableColumn
            width={'6rem'}
            dataIndex={['frequency']}
            title="频次"
            editable={false}
            valueType="text"
            fieldProps={{}}
            ellipsis
          />
          <VSTableColumn
            width={'6rem'}
            dataIndex={['administration']}
            title="途径"
            editable={false}
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
            ellipsis
          /> */}
        </VSTable>
        <Divider
          style={{
            background: 'var(--blue)',
          }}
        />
        <Flex gap={10} justify="flex-end">
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="R"
            type="primary"
            ghost
            onClick={() => {
              onScreen();
            }}
          >
            清屏
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="P"
            type="primary"
            ghost
            onClick={() => {}}
          >
            打印
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            disabled={['', undefined, null, NaN]?.includes(inpVisitId)}
            methodKey="altKey"
            ordinaryKey="S"
            type="primary"
            onClick={() => {
              Tip({
                title: '提示',
                content: '确定生成待退药处方?',
                okText: '是',
                cancelText: '否',
                onOk: () => {
                  onSave?.();
                },
              });
            }}
          >
            保存
          </ButtonAndKeyDown>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default definePage(ApplicationForWesternMedicineRefundInWard);
