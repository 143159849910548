import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleController_getInpBillingDetailByVisitId_b288eb: {
    method: 'post',
    url: '/api/inp-billing/get-inp-billing-detail-by-visit-id',
    parameterFomatter: (data?: {
      inpVisitId: number;
      inpReceiptClass: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailDtoInpBillingManagerDto[],
    ) => data,
  },
});
