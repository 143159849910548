import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_updateDrugAll_231a53: {
    method: 'post',
    url: '/api/drug-drug/update-drug-all',
    parameterFomatter: (data?: {
      btoParam: UpdateDrugDictionaryBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_createDrugAll_cc9730: {
    method: 'post',
    url: '/api/drug-drug/create-drug-all',
    parameterFomatter: (data?: {
      btoParam: CreateDrugDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugProduct_8ced4d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugProductBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
