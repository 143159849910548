import './index.less';

import {
  compose,
  Drawer,
  DrawerProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

enum unit {
  px,
  cm,
  mm,
  in,
  pt,
  pc,
  em,
  rem,
  '%',
  vw,
  vh,
  ex,
  ch,
  vmin,
  vmax,
}

type isOtherT<T> = T extends NewDrawerProps['mask'] ? true : false;

export type NewDrawerProps = DrawerProps & {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 值变化回调
   */
  onChange?: (value?: string) => void;
  /**
   * 宽度在方向 left 和 right 时使用
   */
  width?: number | string;
  /**
   * 高度在方向 top 和 bottom 时使用
   */
  height?: number | string;
  /**
   * 内容
   */
  children: React.ReactNode;
  /**
   * 是否ESC关闭
   */
  keyboard?: boolean;
  /**
   * 是否点击区域其他关闭/在没有遮罩时用
   */
  isOther?: boolean;
  /**
   * 是否开启遮罩默认开启
   */
  mask?: boolean;
  /**
   * 抽屉的方向
   */
  placement?: 'top' | 'left' | 'right' | 'bottom';
  /**
   * 按钮的标题
   */
  buttonTitle?: string;
  /**
   * 侧边按钮的样式
   */
  buttonStyle?: React.CSSProperties;
  /**
   * 自定义按钮结构
   */
  buttonRender?: React.DetailedReactHTMLElement<any, HTMLElement>;
  /**
   * 是否打开的布尔值
   */
  open: boolean;
  /**
   * 传一个布尔的设置状态函数
   */
  onOpen: () => void;
  /**
   * 设置偏移屏幕多少
   */
  drift?: `${number}${keyof typeof unit}`;
  style: React.CSSProperties;
  /**
   * drawer容器className
   */
  drawerClassName?: string;
};

/**
 * 抽屉
 */
const NewDrawer = (props: NewDrawerProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    width = 378,
    height = 378,
    children,
    placement = 'left',
    keyboard = true,
    buttonRender,
    buttonTitle,
    open = false,
    isOther = false,
    mask = true,
    buttonStyle,
    drift = '0px',
    onOpen,
    style,
    drawerClassName,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const id = useMemo(() => getUUID(), []);
  // const main = useMemo(() => {
  //   const div = (
  //     <div
  //       className="drawer-mask-box"
  //       style={{
  //         ...style,
  //         [placement]: drift,
  //       }}
  //     />
  //   );
  // }, [drift, placement, style]);

  /**
   * 点击其他区域关闭函数
   */
  const other = useCallback(
    (event: any) => {
      if (open) {
        const drawer = document.querySelector(`[data-id="drawer-button"]`);
        /**
         * 判断 drawer 是否包含点击的 target 和是否打开
         * 和是否开启该功能 isOther
         */
        if (event.target.dataset?.id === 'drawer-button') {
          /**
           * 判断是否使用自定义按钮
           * 得出用哪个关闭函数
           */
          onOpen?.();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );
  /**
   * 键盘 ESC 函数
   */

  const Esc = useCallback(
    (event) => {
      /**
       * 判断是否是按键 esc 和 是否打开
       */
      if (event.key === 'Escape' && open) {
        /**
         * 判断是否使用自定义按钮
         * 得出用哪个关闭函数
         */
        onOpen?.();
      }
    },
    [onOpen, open],
  );
  useEffect(() => {
    /**
     * 按下 Esc 关闭
     */
    if (keyboard) {
      document.addEventListener('keydown', Esc);
      return () => {
        document.removeEventListener('keydown', Esc);
      };
    }
  }, [Esc, keyboard]);

  useEffect(() => {
    if (isOther) {
      /**
       * 点击其他区域关闭
       */
      document.addEventListener('click', other);
    }
    return () => {
      document.removeEventListener('click', other);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOther, other]);

  const ref = useRef(null);

  const PROPS = mask
    ? {
        className: open ? 'drawer-mask-open-box' : 'drawer-mask-close-box',
        style: {
          ...style,
          [placement]: drift,
          top: '6.5rem',
        },
        ref,
        onClick: (event) => {
          if (event.target === ref.current) {
            onOpen();
          }
        },
      }
    : {};
  const ButtonRender = () => {
    return (
      <div
        className={`drawer-button-${placement}`}
        data-id="drawer-button"
        style={buttonStyle}
        onClick={onOpen}
      >
        {buttonTitle ?? '按钮标题'}
      </div>
    );
  };
  return (
    <div {...PROPS}>
      {/* 这里必须把rest传递给根节点，不要删除 */}
      <Drawer
        placement={placement}
        closable={false}
        open
        {...rest}
        width="100%"
        height="100%"
        destroyOnClose
        keyboard={false}
        mask={false}
        data-id={id}
        className={`${
          open ? 'drawer-open-box' : 'drawer-close-box'
        } ${drawerClassName}`}
        style={{
          ...style,
          ...(['left', 'right'].includes(placement)
            ? {
                width,
                [placement === 'left' ? 'right' : 'left']: 'auto',
              }
            : {
                height,
              }),
          [placement]: ['left', 'right'].includes(placement)
            ? open
              ? drift
              : `calc(-${width} + ${drift})`
            : open
            ? 0
            : `-${height}`,
          transition: `${placement} 0.3s`,
          top: '6.5rem',
        }}
      >
        {children}
        {(buttonRender &&
          cloneElement(buttonRender, {
            className: `drawer-button-${placement}`,
            dataId: 'drawer-button',
            onClick: onOpen,
          })) ?? <ButtonRender />}
      </Drawer>
    </div>
  );
};
NewDrawer.displayName = 'NewDrawer';

export default compose(
  withField<string>({
    name: 'NewDrawer',
  }),
  withPreview<NewDrawerProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(NewDrawer) as typeof NewDrawer;
