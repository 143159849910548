import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleController_queryPatientsAwaitingDischarge_1b0e1e: {
    method: 'post',
    url: '/api/inp-billing/query-patients-awaiting-discharge',
    parameterFomatter: (data?: { displayId: string; wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpWaitSettlePatientVoInpBillingEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoInpBillingEntranceWebVoInpwaitsettlepatientvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
