import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getApplyDetailByDrugApplyMasterId_92fe0d: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-apply-detail-by-drug-apply-master-id',
    parameterFomatter: (data?: {
      drugApplyMasterId: number;
      subStorageId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyDetailWithMasterVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
});
