import vsf, { definePage } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';
import './index.less';
import UserInfo from './UserInfo';
import TableMenu from './TableMenu';
import '@/style/adaptation.less';
const BillingAudit = (props) => {
  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState(),
    // 存放患者信息
    [PatientDetails, setPatientDetails] = useState(),
    // 触发查询患者信息
    [inquireUser, setInquireUser] = useState(),
    resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);
  return (
    <div className="BillingAudit_container">
      <div className="container">
        <UserInfo
          {...{
            PatientDetails,
            setPatientDetails,
            inquireUser,
          }}
        />
        <TableMenu
          {...{
            PatientDetails,
            setInquireUser,
            setPatientDetails,
          }}
        />
      </div>
    </div>
  );
};
export default definePage(BillingAudit);
