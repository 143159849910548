import { getWubiCode } from 'lancet-pywb/PY_WB';
import { pinyin as getPinYinCode } from 'pinyin-pro';

type Type = {
  wubi: string;
  pinyin: string;
  all: [Type['pinyin'], Type['wubi']];
};

/**
 * 获取汉字罗马字母的函数
 * @param str 汉字
 * @param type 返回类型五笔/拼音/全部
 * @returns 返回根据 type 来
 */
export const askRomanAlphabet = (
  str: string,
  type: keyof Type = 'pinyin',
  character = true,
) => {
  const ins = getPinYinCode(str, {
    pattern: 'first',
    toneType: 'none',
    type: 'string',
  });
  const pinyin = ins?.replace(/\s/g, '');
  switch (type) {
    case 'all':
      return [pinyin, getWubiCode(str)];
    case 'pinyin':
      return pinyin;
    case 'wubi':
      return getWubiCode(str);
  }
};

export default function askSearch(value: string, option: any, label = 'label') {
  const [pinyin, wubi] = askRomanAlphabet(String(option?.[label]), 'all');
  const filter = (text) => {
    return String(text)
      ?.replace(/\s*/g, '')
      ?.toLocaleLowerCase()
      ?.includes(value?.toLocaleLowerCase()?.replace(/\s*/g, ''));
  };
  return (
    String(option?.[label]).includes(value) || filter(pinyin) || filter(wubi)
  );
}
