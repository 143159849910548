import React from 'react';

interface FlexProps {
  children: React.ReactNode;
  vertical?: boolean;
  wrap?: React.CSSProperties['flexWrap'];
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  gap?: React.CSSProperties['gap'];
  flex?: React.CSSProperties['flex'];
  className?: string;
  style?: React.CSSProperties;
}

export default function Flex(props: FlexProps) {
  const {
    children,
    vertical = false,
    wrap = 'nowrap',
    justify = 'normal',
    align = 'normal',
    gap = 'normal',
    flex = 'normal',
    className,
    style,
    ...rest
  } = props;
  return (
    <div
      className={className}
      {...rest}
      style={{
        display: 'flex',
        flexWrap: wrap,
        justifyContent: justify,
        alignItems: align,
        gap,
        flex,
        flexDirection: vertical ? 'column' : 'row',
        // width: '100%',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
