import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Cascader,
  Checkbox,
  CheckboxGroup,
  message,
  Modal,
  Section,
  Select,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import LabItem from '@/pages/Index/manage/finance/labItem/tabs/LabItem';
const Index = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [dataSource1, setDataSource1] = useState<any>([]);
  const [levelOne1, setLevelOne1] = useState<any>([]);
  const [levelTwo1, setLevelTwo1] = useState<any>([]);
  const [levelThree1, setLevelThree1] = useState<any>([]);

  const [dataSource, setDataSource] = useState<any>([]);
  // 当前选中级
  const [levelOne, setLevelOne] = useState<any>([]);
  const [levelTwo, setLevelTwo] = useState<any>([]);
  const [levelThree, setLevelThree] = useState<any>([]);
  const [threeChildrenData, setThreeChildrenData] = useState<any>([]);
  // 已选中得
  const [hasChecked, setHasChecked] = useState<any>([]);

  const handelOpen = (v = false, value?) => {
    setOpen(v);
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={600}
        open={open}
        bodyStyle={{
          height: 250,
        }}
        okText="确认 "
        cancelText="取消 "
        title={
          <div style={{ color: '#3276E8', fontWeight: 'bolder' }}>
            突发事件批量分诊信息
          </div>
        }
        onOk={() => {
          handelOpen(false);
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <VSForm labelAlign="left">
          <VSFormLayout labelWidth={100} columnCount={1}>
            <VSFormItem label="事件类型" />
            <VSFormItem label="事件人数" />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
