import React from 'react';

interface FlexProps {
  children: React.ReactNode;
  gap?: React.CSSProperties['gap'];
  className?: string;
  style?: React.CSSProperties;
  amount?: number;
}

export default function Grid(props: FlexProps) {
  const { children, gap = 'normal', className, style, amount = 3 } = props;
  return (
    <div
      className={className}
      style={{
        display: 'grid',
        // flexWrap: wrap,
        // justifyContent: justify,
        // alignItems: align,
        gap,
        // flex,
        // flexDirection: vertical ? 'column' : 'row',
        width: '100%',
        gridTemplateColumns: `repeat(${amount}, 1fr)`,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
