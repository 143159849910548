import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockController_queryDrugs_529e82: {
    method: 'post',
    url: '/api/drug-storage/query-drugs',
    parameterFomatter: (data?: {
      qry: DrugProductHaveStockAndPriceQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSplitVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockController_queryStocksByDrugProductId_acd759: {
    method: 'post',
    url: '/api/drug-storage/query-stocks-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStockSplitVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStockController_getSpecificationByDrugProductId_319349: {
    method: 'post',
    url: '/api/drug-storage/get-specification-by-drug-product-id',
    parameterFomatter: (data?: {
      drugProductId: number;
      packageSpecification: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStockSplitVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStockController_getDrugStockSplitUpdatedAmount_655ef3: {
    method: 'post',
    url: '/api/drug-storage/get-drug-stock-split-updated-amount',
    parameterFomatter: (data?: {
      item: DrugStockSplitVoDrugStorageEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugStockController_saveDrugStockSplit_332f9a: {
    method: 'post',
    url: '/api/drug-storage/save-drug-stock-split',
    parameterFomatter: (data?: {
      splits: DrugStockSplitVoDrugStorageEntranceWebVo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
