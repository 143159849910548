import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStoragePurchaseController_deleteSavePurchase_374f52: {
    method: 'post',
    url: '/api/drug-storage-purchase/delete-save-purchase',
    parameterFomatter: (data?: {
      btoParam: DeleteSavePurchaseBtoDrugStoragePurchaseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugStoragePurchaseController_getSavePurchasePlanList_32f393: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-save-purchase-plan-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SavePurchasePlanMasterListVoDrugStoragePurchaseEntranceWebVo[],
    ) => data,
  },
});
