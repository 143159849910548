import vsf, { definePage } from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { TreeMenu } from '@/components';
import { useAsyncEffect, useRequest } from 'ahooks';
import { SyncOutlined } from '@vs/vsf-icons';
import { debounce } from 'lodash';

const ClinicItemTree = (props) => {
  const [treeData, setTreeData] = useState([]);
  const set = useCallback((parent, list) => {
    return list?.filter((item) => item?.realParentId == parent?.id)?.map((item) => {
      return ({
        ...item,
        children: set(item, list)
      })
    })
  }, [])

  const { loading, run: get } = useRequest(async () => {
    const res =
      await vsf?.services?.ClinicItemBaseController_getClinicItemClassList_4252cc?.(
        {},
      );

    const treeClass = {};
    for (const item of res?.data) {
      const parentId = treeClass[item?.parentId];
      if (parentId) {
        parentId.push(item)
      } else {
        treeClass[item?.parentId ?? item?.id] = [item];
      }
    }
    const tree = res?.data?.filter((item) => !item.parentId)?.map((item, index) => {
      const [, ...other] = treeClass?.[item?.id];
      const first = other?.filter((item) => !item?.realParentId);
      return {
        ...item,
        key: item?.id + index,
        children: first?.map((item) => ({
          ...item,
          children: set(item, other)
        })),
      }
    });
    console.log(tree);
    setTreeData(tree);
  })

  useEffect(() => {
    get();
  }, [])


  return (
    <TreeMenu
      id="ClinicItemTree"
      title="临床诊疗项目分类"
      treeProps={{
        defaultExpandAll: true,
      }}
      row={{
        expand: true
      }}
      onSelect={debounce((value) => {
        props?.onChange?.(value)
      }, 500)}
      isTree
      treeData={treeData}
      headerRender={() => {
        return (
          <Button icon={<SyncOutlined spin={loading} />} onClick={get}>
            刷新
          </Button>
        );
      }}
      fieldNames={{ title: 'name', key: 'key', children: 'children' }}
    />
  );
};
export default definePage(ClinicItemTree);
