import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  LocalParameterConfigDtoController_getAllByLocalParameterQto_f27ac9: {
    method: 'post',
    url: '/api/base_parameter/getAllByLocalParameterQto',
    parameterFomatter: (data?: {
      qto: LocalParameterQtoBaseParameterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigVoBaseParameterEntranceWebVo[],
    ) => data,
  },
  LocalParameterConfigBOController_createLocalParameter_a54ed9: {
    method: 'post',
    url: '/api/base-parameter/create-local-parameter',
    parameterFomatter: (data?: {
      btoParam: CreateLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigDtoBaseParameterManagerDto,
    ) => data,
  },
  LocalParameterConfigBOController_updateLocalParameter_6e3d47: {
    method: 'post',
    url: '/api/base-parameter/update-local-parameter',
    parameterFomatter: (data?: {
      btoParam: UpdateLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigDtoBaseParameterManagerDto,
    ) => data,
  },
  LocalParameterConfigBOController_deleteLocalParameter_b70c63: {
    method: 'post',
    url: '/api/base_parameter/deleteLocalParameter',
    parameterFomatter: (data?: {
      btoParam: DeleteLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
