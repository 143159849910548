import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  Button,
  VSForm,
  VSFormLayout,
  VSFormItem,
  message,
  VSTableToolbar,
  VSTableAddition,
  Space,
  Row,
  Col,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import { getScrollY, getScrollYRem } from '@/utils';
import Icon from '@/module/Icon';
import DrugFormVsDispenseRoundingSettingList from './DrugFormVsDispenseRoundingSettingList';
const index = (props) => {
  const [flagStatus, setFlagStatus] = useState(0);
  const [partial, setPartial] = useState(props?.open || 'OUTP');
  const [dosage, setDosage] = useState();
  const [roundingTypeIs, setRoundingTypeIs] = useState();
  const [dosageFormCode, setDosageFormCode] = useState([]);
  useEffect(() => {});
  const onFetch = useCallback(
    async (params) => {
      setDosageFormCode([]);
      const res =
        await vsf.services?.DrugFormVsDispenseRoundingSettingController_getAllByQueryDrugFormVsDispenseRoundingSettingQto_6980f6?.(
          {
            qto: {
              ...params.search,
              roundingTypeIs: roundingTypeIs,
              dosageFormCodeIs: dosage,
            },
          },
        );

      setDosageFormCode(res?.data?.result ?? res?.data ?? []);
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [dosage, roundingTypeIs],
  );
  return (
    <>
      {!flagStatus ? (
        <div className="Integer-layout">
          <div className="table-content">
            <Row>
              <Col span={12}>
                <VSForm id="integerSelectForm">
                  <VSFormLayout columnCount={5}>
                    <VSFormItem
                      name={'clinicTypeIs'}
                      valueType="radioButton"
                      dataSource={[
                        { label: '门诊', value: 'OUTP' },
                        { label: '住院', value: 'INP' },
                      ]}
                      fieldProps={{
                        defaultValue: partial,
                        onChange: (v) => {
                          setPartial(v);
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      name={'flag'}
                      valueType="radioButton"
                      dataSource={[
                        { label: '按剂型', value: 0 },
                        { label: '按药品', value: 1 },
                      ]}
                      fieldProps={{
                        value: flagStatus,
                        defaultValue: 0,
                        onChange: (v) => {
                          setFlagStatus(v);
                        },
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label={'剂型'}
                      name={'dosageFormCodeIs'}
                      valueType="select"
                      //vsf.stores.dbenums.enums.DRUG_FORM_DICT
                      dataSource={dosageFormCode}
                      fieldProps={{
                        showSearch: true,
                      }}
                      fieldNames={{
                        label: 'dosageFormName',
                        value: 'dosageFormCode',
                      }}
                    ></VSFormItem>
                    <VSFormItem
                      label={'摆药取整'}
                      name={'roundingTypeIs'}
                      valueType="select"
                      dataSource={[
                        { label: '按次取整', value: 'BY_TIMES' },
                        { label: '按天取整', value: 'BY_DAY' },
                        { label: '按量取整', value: 'BY_AMOUNT' },
                      ]}
                    ></VSFormItem>
                  </VSFormLayout>
                </VSForm>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Space size={4}>
                  <Button
                    onClick={() => {
                      vsf?.refs?.integerSelectForm?.resetFields();
                      setDosage(undefined);
                      setRoundingTypeIs(undefined);
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type="primary"
                    onClick={(v) => {
                      const dosageFormCodeIs =
                        vsf?.refs?.integerSelectForm?.getFieldValue(
                          'dosageFormCodeIs',
                        )?.dosageFormCode;
                      if (dosageFormCodeIs) {
                        setDosage(dosageFormCodeIs);
                      } else {
                        setDosage(undefined);
                      }

                      const roundingType =
                        vsf?.refs?.integerSelectForm?.getFieldValue(
                          'roundingTypeIs',
                        );
                      if (roundingType) {
                        setRoundingTypeIs(roundingType);
                      } else {
                        setRoundingTypeIs(undefined);
                      }
                    }}
                  >
                    查询
                  </Button>
                </Space>
              </Col>
            </Row>

            <VSPromiseTable
              editable={{
                editType: 'multiple',
                // editText: <Icon type="icon-bianji" size={24} />,
                columnProps: {
                  hideInTable: true,
                },
              }}
              vsid="50296"
              id="tableDrugFormVsDispenseRoundingSetting"
              scroll={{
                y: getScrollYRem(24),
              }}
              pagination={false}
              onRecord={() => ({ id: 'create_' + Math.random() })}
              onFetch={onFetch}
              className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
            >
              <VSTableColumn
                dataIndex={['clinicTypeIs']}
                // title="诊疗类型"
                valueType="radioButton"
                // search
                order={0}
                columnKey={'clinicTypeIs'}
                hideInTable
                dataSource={[
                  { label: '门诊', value: 'OUTP' },
                  { label: '住院', value: 'INP' },
                ]}
                fieldProps={{
                  // defaultValue: props?.open || 'OUTP',
                  value: '住院',
                  onChange: (v) => {
                    // setPartial(v);
                    setFlagStatus(v);
                  },
                }}
              />

              <VSTableColumn
                dataIndex={['flag']}
                // title="诊疗类型"
                valueType="radioButton"
                // search
                order={0}
                columnKey={'clinicTypeIs'}
                hideInTable
                dataSource={[
                  { label: '按剂型', value: 0 },
                  { label: '按药品', value: 1 },
                ]}
                fieldProps={{
                  value: flagStatus,
                  onChange: (v) => {
                    // setFlagStatus(v);
                  },
                }}
              />

              <VSTableColumn
                dataIndex={['dosageFormCodeIs']}
                title="剂型"
                valueType="select"
                // search
                order={0}
                columnKey={'dosageFormCodeIs'}
                hideInTable
                dataSource={vsf.stores.dbenums.enums.DRUG_FORM_DICT}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['roundingTypeIs']}
                title="摆药取整"
                valueType="select"
                // search
                order={0}
                columnKey={'roundingTypeIs'}
                hideInTable
                dataSource={[
                  { label: '按次取整', value: 'BY_TIMES' },
                  { label: '按天取整', value: 'BY_DAY' },
                  { label: '按量取整', value: 'BY_AMOUNT' },
                ]}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['dosageFormName']}
                title="剂型"
                editable={false}
                valueType="text"
                fieldProps={{}}
              />

              {partial === 'OUTP' && (
                <VSTableColumn
                  dataIndex={['roundingTypeOutp']}
                  title="门诊摆药取整方式"
                  valueType="select"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  dataSource={[
                    { label: '按次取整', value: 'BY_TIMES' },
                    { label: '按天取整', value: 'BY_DAY' },
                    { label: '按量取整', value: 'BY_AMOUNT' },
                  ]}
                  fieldProps={{}}
                />
              )}
              {partial === 'INP' && (
                <VSTableColumn
                  dataIndex={['roundingTypeInp']}
                  title="住院摆药取整方式"
                  valueType="select"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  dataSource={[
                    { label: '按次取整', value: 'BY_TIMES' },
                    { label: '按天取整', value: 'BY_DAY' },
                    { label: '按量取整', value: 'BY_AMOUNT' },
                  ]}
                  fieldProps={{}}
                />
              )}

              <VSTableToolbar title="按剂型取整规则配置菜单">
                {/* <VSTableAddition children="添加" position='top' /> */}
                <Button
                  children="保存"
                  type="primary"
                  onClick={async (params) => {
                    await vsf?.refs?.tableDrugFormVsDispenseRoundingSetting
                      ?.getEditForm()
                      .validateFieldsReturnFormatValue();
                    const res =
                      await vsf.services?.DrugFormVsDispenseRoundingSettingController_saveDrugFormVsDispenseRoundingSetting_5be1bb?.(
                        {
                          bto: vsf.refs.tableDrugFormVsDispenseRoundingSetting.getValues(),
                        },
                      );
                    if (res?.code === 200) {
                      message.success('保存成功');
                    }
                    return res?.data;
                  }}
                />
              </VSTableToolbar>
            </VSPromiseTable>
          </div>
        </div>
      ) : (
        // <div className="table-content">
        <DrugFormVsDispenseRoundingSettingList open={partial} />
        // </div>
      )}
    </>

    // </div>
  );
};
export default definePage(index);
