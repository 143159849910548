import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpVisitController_getOutpVisitRegisterRecordDetailVoById_5d59cb: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-outp-visit-register-record-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitRegisterRecordDetailVoClinicOutpVisitEntranceWebVo,
    ) => data,
  },
});
