import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getVitalsignRecord_6931fd: {
    method: 'post',
    url: '/api/clinic-record/get-vitalsign-record',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: VitalsignRecordShowVoClinicRecordEntranceWebVo,
    ) => data,
  },
});
