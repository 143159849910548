import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getAllAvailableByClinicInpAdmissionQto_dcc286: {
    method: 'post',
    url: '/api/clinic-inp-admission/get-all-available-by-clinic-inp-admission-qto',
    parameterFomatter: (data?: {
      qto: ClinicInpAdmissionQtoClinicInpAdmissionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicInpAdmissionShowVoClinicInpAdmissionEntranceWebVo[],
    ) => data,
  },
});
