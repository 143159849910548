import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
} from '@vs/vsf-kit';
import React from 'react';
import Icon from '@/module/Icon';
import CreateOrUpdateSupplier from './add';
const Supplier = (props) => {
  const handelAdd = async (params) => {
    const res =
      await vsf?.services?.DrugFirmDictionaryBOController_createDrugSupplier_139dad?.(
        { createDrugFirmDictionaryBto: params },
      );
    return res?.data;
  };

  const handelRemove = async (params) => {
    const res =
      await vsf?.services?.DrugFirmDictionaryBOController_deleteDrugFirmDictionary_07a09d?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    return res?.code === 200;
  };

  const handelUpdate = async (params) => {
    const res =
      await vsf?.services?.DrugFirmDictionaryBOController_updateDrugSupplier_b976fe?.(
        { updateDrugFirmDictionaryBto: params },
      );
    return res?.data;
  };

  const handelFetch = async (params) => {
    const res =
      await vsf?.services?.DrugFirmDictionaryDtoController_queryDrugSupplierDictionary_be8bc0?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  return (
    <div className="common_lfc_style">
      <VSPromiseTable
        scroll={{ y: '51rem' }}
        vsid="08301"
        id="updateOrCreateSupplier"
        className="aspirin-table"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
        }}
        searchConfig={{
          className: 'updateOrCreateSupplier',
          optionButtons: ['search'],
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 11.33rem 0 6.66rem">
              {dom}
            </SearchContainer>
          );
        }}
        addLinkPage={{
          linkPage: () => <CreateOrUpdateSupplier />,
          modalProps: {
            width: '91.2rem',
            title: '供应商维护',
            bodyStyle: {
              height: '47.6rem',
              overflow: 'scroll',
              background: '#F9FAFC',
              boxSizing: 'border-box',
              padding: '0 2.4rem',
            },
          },
        }}
        updateLinkPage={{
          linkPage: () => <CreateOrUpdateSupplier />,
          modalProps: {
            width: '91.2rem',
            title: '供应商维护',
            bodyStyle: {
              height: '47.6rem',
              overflow: 'scroll',
              background: '#F9FAFC',
              boxSizing: 'border-box',
              padding: '0 2.4rem',
            },
          },
        }}
        onAdd={handelAdd}
        onRemove={handelRemove}
        onUpdate={handelUpdate}
        onFetch={handelFetch}
      >
        <VSTableColumn
          dataIndex={['firmId']}
          title="厂商标识/简称"
          valueType="text"
          search
          order={-8}
          columnKey={'firmIdLike'}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
            labelCol: {
              style: {
                width: '10rem',
              },
            },
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['firmName']}
          title="厂商"
          valueType="text"
          search
          order={-9}
          columnKey={'firmNameLike'}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="是否停用"
          valueType="switch"
          search
          order={-10}
          columnKey={'stopIndicatorIs'}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['firmDiscount']}
          title="扣率"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['firmAddressType']}
          title="地址类别"
          valueType="select"
          dataSource={[
            { label: '本地', value: 'LOCAL' },
            { label: '外地', value: 'NOT_LOCAL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['accountantNumber']}
          title="会计编号"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['documentWebType']}
          title="数据来源"
          valueType="select"
          dataSource={[
            { label: '非接口采购', value: 'NOT_INTERFACE_PURCHASE' },
            { label: '非平台采购', value: 'NOT_PLATFORM_PURCHASE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableToolbar
          title="生产商列表2"
          style={{ boxSizing: 'border-box', padding: '0 24px' }}
        >
          <VSTableAddition children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(Supplier);
