import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPathologyController_getOrderPathologyByOrderId_503ccd: {
    method: 'post',
    url: '/api/clinic-record-order-pathology/get-order-pathology-by-order-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPathologyVoClinicRecordOrderPathologyEntranceWebVo,
    ) => data,
  },
});
