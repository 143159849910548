import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentDtoController_getById_3da0f7: {
    method: 'post',
    url: '/api/organization/getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentBOController_updateDepartment_da3dee: {
    method: 'post',
    url: '/api/organization/updateDepartment',
    parameterFomatter: (data?: {
      btoParam: UpdateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
});
