import cn from 'classnames';
import dayjs from 'dayjs';

import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { message, Modal, Checkbox } from '@vs/vsf-kit';
import { getScrollY } from '@/utils';
import HeaderInfo from '@/pages/Index/components/head_info';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  convertSavePatientLabelConfigList2SavePatientLabelConfigListReq,
  convertGetListPatientLabelConfigVoByStaffIdRes2SavePatientLabelConfigList,
} from './convert';
import CardList from './card';

import '../index.less';

const obj = {
  HEIGHT: '身高',
  WEIGHT: '体重',
  BMI: 'BMI指数',
  BLOOD_PRESSURE: '血压',
  TEMPERATURE: '体温',
  BLOOD_GLUCOSE: '血糖',
  ALLERGY_HISTORY: '过敏史',
  NATION: '民族',
  MARRIAGE_STATUS: '婚姻状况',
  // BLOOD_TYPE: '血型',
  // BLOOD_TYPE_RH: 'RH血型',
};
const Index = (props, ref) => {
  const {} = props;
  const [open, setOpen] = useState(false);

  const [dataSource, setDataSource] = useState([
    { label: '身高', value: 'HEIGHT' },
    { label: '体重', value: 'WEIGHT' },
    { label: 'BMI指数', value: 'BMI' },
    { label: '血压', value: 'BLOOD_PRESSURE' },
    { label: '体温', value: 'TEMPERATURE' },
    { label: '血糖', value: 'BLOOD_GLUCOSE' },
    { label: '过敏史', value: 'ALLERGY_HISTORY' },
    { label: '民族', value: 'NATION' },
    {
      label: '婚姻状况',
      value: 'MARRIAGE_STATUS',
    },
    // { label: '血型', value: 'BLOOD_TYPE' },
    // {
    //   label: 'RH血型',
    //   value: 'BLOOD_TYPE_RH',
    // },
  ]);
  const [hasChecked, setHasChecked] = useState([]);

  const handeOpen = (value) => {
    setOpen(value);
  };
  // 拖动交换位置
  const handelChangePosition = (dragIndex, hoverIndex) => {
    let data = hasChecked.slice();
    let temp = data[dragIndex];
    // 交换位置
    data[dragIndex] = data[hoverIndex];
    data[hoverIndex] = temp;
    setHasChecked([...data]);
    // this.setState({ cardList: data });
  };
  // 删除已选
  const handelDelete = (value) => {
    const _data = hasChecked?.filter((i) => i?.value !== value);
    setHasChecked([..._data]);
  };
  // 查询已选
  const getListPatientLabelConfig = async () => {
    const res = await vsf.stores?.user?.getListPatientLabelConfig({
      staffId: vsf.stores.user.staffInfo?.doctorDto?.staffId,
    });
    const _data = (res?.data ?? [])?.map((i) => {
      return {
        label: obj?.[i?.labelCode],
        value: i?.labelCode,
      };
    });
    console.log(_data, res);
    setHasChecked([..._data]);
  };

  // 保存
  const save = async () => {
    const output = hasChecked?.map((i) => {
      return {
        staffId: vsf.stores.user.staffInfo?.doctorDto?.staffId,
        labelCode: i?.value,
      };
    });
    const res =
      await vsf?.services?.PatientLabelConfigController_savePatientLabelConfigList_25782e?.(
        { btoParamList: output, extApiParam: {} },
      );
    if (res?.code == 200) {
      message.success('保存成功');
      vsf.stores?.user?.getListPatientLabelConfig({
        staffId: vsf.stores.user.staffInfo?.doctorDto?.staffId,
      });
      handeOpen(false);
    }
  };

  useEffect(() => {
    if (!open) return false;
    getListPatientLabelConfig();
  }, [open]);

  useImperativeHandle(ref, () => ({
    handeOpen,
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        open={open}
        {...{
          width: 928,
          centered: true,
          cancelText: '取消',
          okText: '确认',
          maskClosable: true,
          destroyOnClose: true,
          preserve: false,
          bodyStyle: {
            height: 420,
            padding: '0 24px',
          },
        }}
        title={<div style={{ color: '#3276E8' }}>患者信息栏自定义设置</div>}
        onCancel={() => {
          handeOpen(false);
        }}
        onOk={() => {
          if (hasChecked?.length < 2) return message.info('至少选择2个');
          save();
        }}
      >
        <div className="patient_seting">
          <div className="patient_seting_all">
            <div className="patient_seting_title title">
              可选体征信息<span className="patient_seting_mark">(共11个)</span>
            </div>

            <div
              className="patient_seting_body"
              style={{ height: 162, padding: 16 }}
            >
              <Checkbox.Group
                onChange={(checkedValues) => {
                  if (checkedValues?.length > 8) {
                    message.info('最多可选8个');
                    setDataSource([
                      ...dataSource?.map((i) => {
                        return { ...i, disabled: true };
                      }),
                    ]);
                  } else {
                    setHasChecked([...checkedValues]);
                  }
                }}
                disabled={hasChecked?.length >= 8}
                fieldNames={{ label: 'label', value: 'value' }}
                dataSource={dataSource}
                value={hasChecked}
                dataSourceRender={(item) => {
                  return (
                    <div className="patient_seting_body_item1">
                      <div className="text_ellipsis">{item}</div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div className="patient_seting_select">
            <div className="patient_seting_title title">
              已选体征信息
              <span className="patient_seting_mark">(最多可选8个)</span>
            </div>
            <div className="patient_seting_body">
              {hasChecked?.map((item, index) => {
                return (
                  <CardList
                    key={item?.value}
                    changePosition={handelChangePosition}
                    index={index}
                    handelDelete={handelDelete}
                    {...item}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </DndProvider>
  );
};
export default forwardRef(Index);
