import Container from '@/module/Container';
import React, { useState } from 'react';
import Tree from './tree';
import Table from './Table';
function index() {
  const [selectTreeData, setSelectTreeData] = useState();
  const [courtyardParam, setCourtyardParam] = useState();
  return (
    <>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              block: '24%',
            },
            {
              block: 1,
              bgColor: '#fff',
            },
          ],
        }}
      >
        <Tree
          onChange={(item) => {
            setSelectTreeData(item);
          }}
        />
        <Table
          selectTreeData={selectTreeData}
          courtyardParam={courtyardParam}
          onChange={(cal) => setCourtyardParam(cal)}
        ></Table>
      </Container>
    </>
  );
}

export default index;
