import { definePage } from '@vs/vsf-boot';
import React from 'react';
import { Menu } from '@vs/vsf-kit';

const MenuDetails = (props) => {
  const { current, onChanegMenu } = props;
  return (
    <div className="MenuDetails">
      <Menu
        mode="horizontal"
        onClick={onChanegMenu}
        selectedKeys={[current]}
        items={[
          {
            label: '病区处方退药',
            key: 'mail',
          },
          {
            label: '退药汇总',
            key: 'app',
          },
        ]}
      />
    </div>
  );
};

export default definePage(MenuDetails);
