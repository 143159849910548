import {
  compose,
  useControllableState,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useState } from 'react';

const { RangePicker } = DatePicker;

type RangeValue = [Moment | null, Moment | null] | null;

const LimitTime: React.FC = (props: any) => {
  const {
    day = 2,
    showTime = {
      format: 'HH:mm:ss',
      defaultValue: [
        moment('00:00:00', 'HH:mm:ss'),
        moment('23:59:59', 'HH:mm:ss'),
      ],
    },
    format = 'YYYY-MM-DD HH:mm:ss',
  } = props;

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useControllableState({
    defaultValue: props?.defaultValue,
    value: props?.value ?? [
      moment().subtract(day, 'days').startOf('day'), // 前两天的00:00
      moment().endOf('day'), // 今天的23:59
    ],
    onChange: props?.onChange,
  });

  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > day - 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > day - 1;
    // 获取今天的日期
    const today = dayjs();
    // 将今天的日期设为最大可选日期
    const maxDate = today.endOf('day');
    return !!tooEarly || !!tooLate || current > maxDate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <RangePicker
      // {...props}
      value={dates || value?.map((item: any) => dayjs(item))}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={(value) => {
        setValue(
          value?.map((item: any) => dayjs(item).format('YYYY-MM-DD HH:mm:ss')),
        );
      }}
      onOpenChange={onOpenChange}
      showTime={showTime}
      format={format}
    />
  );
};

LimitTime.displayName = 'limitTime';

export default compose(
  withRef(),
  withField<any>({
    name: 'limitTime',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const target = props?.value;
      <>{props?.value}</>;
    },
  }),
)(LimitTime) as typeof LimitTime;
