import React, {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';

import Herb from '@/pages/Index/cpoe/center/main/chm';
import Dispose from '@/pages/Index/cpoe/center/main/dispose';
import Exam from '@/pages/Index/cpoe/center/main/exam';
import DefendTemplateApp from '@/pages/Index/cpoe/center/main/medical_order_template/DefendTemplateApp';
import Operation from '@/pages/Index/cpoe/center/main/operation';
import Pathology from '@/pages/Index/cpoe/center/main/pathology';
import Lab from '@/pages/Index/cpoe/center/main/test';

import { BeIn } from '..';
interface AuxiliaryProps {
  labProps?: any;
  examProps?: any;
  herbProps?: any;
  operationProps?: any;
  pathologyProps?: any;
  disposeProps?: any;
}

enum method {
  onExam,
  onLab,
  onHerb,
  onOperation,
  onPathology,
  onDispose,
}

export type AuxiliaryRef = {
  [key in keyof typeof method]: (value?: never) => void;
};

export interface Ref {
  onOpen: (value?: never) => void;
}

const Auxiliary = forwardRef((props: AuxiliaryProps, ref) => {
  const {
    labProps,
    herbProps,
    examProps,
    operationProps,
    pathologyProps,
    disposeProps,
  } = props;
  const labRef = useRef<Ref>(null);
  const examRef = useRef<Ref>(null);
  const herbRef = useRef<Ref>(null);
  const operationRef = useRef<Ref>(null);
  const pathologyRef = useRef<Ref>(null);
  const disposeRef = useRef<Ref>(null);

  useImperativeHandle(ref, () => ({
    onExam: (value) => examRef?.current?.onOpen(value),
    onLab: (value) => labRef?.current?.onOpen(value),
    onOperation: (value) => operationRef?.current?.onOpen(value),
    onPathology: (value) => pathologyRef?.current?.onOpen(value),
    onDispose: (value) => disposeRef?.current?.onOpen(value),
    onHerb: (value) => herbRef?.current?.onOpen(value),
  }));
  const onSubmit = useContext(BeIn) || {};

  return (
    <Fragment>
      <Lab ref={labRef} onSubmit={onSubmit} {...labProps} />
      <Exam
        ref={examRef}
        onSubmit={onSubmit}
        {...examProps}
        hospitalType="in"
      />
      <Herb ref={herbRef} onSubmit={onSubmit} {...herbProps} />
      <Operation
        ref={operationRef}
        onSubmit={onSubmit}
        {...operationProps}
        type="inp"
      />
      <Pathology ref={pathologyRef} onSubmit={onSubmit} {...pathologyProps} />
      <Dispose ref={disposeRef} onSubmit={onSubmit} {...disposeProps} />
    </Fragment>
  );
});

Auxiliary.displayName = 'Auxiliary';

export default Auxiliary;
