import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryDrugListForTemp_2beee2: {
    method: 'post',
    url: '/api/drug-drug/query-drug-list-for-temp',
    parameterFomatter: (data?: { qto: HerbListEoDrugDrugPersistEo }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
