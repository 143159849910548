import vsf from '@vs/vsf-boot';
import {
  compose,
  Select,
  SelectProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { forwardRef, useCallback, useState } from 'react';

import { askRomanAlphabet } from './askRomanAlphabet';

type NewSelectProps = Omit<SelectProps, 'options' | 'fieldNames'> & {
  dataSource: any[];
  fieldNames: { label: string; value: string };
  onFieldEnter: () => void;
  onKeyUp: () => void;
} & CodeTableSelectProps;

export type CodeTableSelectProps = {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 值变化回调
   */
  onChange?: (value?: string) => void;
};

const DefaultFieldNames = { label: 'label', value: 'value' };
const pinyinField = 'pinyin';
const wubiField = 'wubi';

let CodeTableSelect: any = (props: NewSelectProps, ref: any) => {
  const {
    defaultValue,
    value: valueProp,
    fieldNames = DefaultFieldNames,
    onChange,
    dataSource,
    ...rest
  } = props;

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // useLayoutEffect(() => {
  //   if (value && Array.isArray(dataSource)) {
  //     const effectiveItem = dataSource?.find((item) => !!item.type);
  //     if (effectiveItem?.type) {
  //       setValue(
  //         vsf.stores.dbenums?.enums?.[effectiveItem?.type]?.find(
  //           (item) => item?.value === value,
  //         ) ?? value,
  //       );
  //     }
  //   }
  // }, [value, dataSource, setValue, props]);

  const getLowerCaseStr = (str: string) => {
    try {
      return str.toLocaleLowerCase();
    } catch (error) {
      return str;
    }
  };

  const convertToCodeDict = (list) => {
    return list?.map((item) => ({
      code: item?.code,
      value: item?.code,
      label: item?.name,
      type: item?.codeType,
    }));
  };

  const queryAllByBaseCodeIndexQto = async (params): Promise<any> => {
    const response =
      await vsf.services?.BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27?.(
        {
          ...params,
        },
      );
    return response?.data?.result ?? [];
  };

  const getDataSource = useCallback(
    async (params) => {
      try {
        if (typeof dataSource === 'function') {
          return dataSource?.(params);
        }
        const searchKey = params?.[props.searchKey ?? 'label'];
        if (searchKey?.length) {
          const [pinyin, wubi] = askRomanAlphabet(searchKey, 'all');
          const _dataSource =
            /**
             * 通过码表返回字段“pinyin”筛选
             * 如果没有“pinyin”字段，则使用“label”字段转拼音后筛选
             */
            dataSource?.filter((record) => {
              return (
                getLowerCaseStr(
                  record?.[pinyinField] ??
                    askRomanAlphabet(record?.[fieldNames?.label], 'pinyin'),
                )?.includes(getLowerCaseStr(pinyin)) ||
                getLowerCaseStr(record?.[wubiField])?.includes(
                  getLowerCaseStr(wubi),
                ) ||
                String(record?.label).includes(searchKey)
              );
            }) ?? [];
          if (_dataSource?.length === 0 && dataSource?.length) {
            const effectiveItem = dataSource?.find((item) => !!item.type);
            if (effectiveItem?.type) {
              // 兜底方案从服务端查询
              const list = await queryAllByBaseCodeIndexQto({
                qto: {
                  codeTypeIs: effectiveItem?.type,
                  orInputCodeLike: pinyin?.toUpperCase(),
                  orNameLike: pinyin?.toUpperCase(),
                },
              });
              return convertToCodeDict(list);
            }
          }
          return _dataSource;
        }
        return dataSource;
      } catch (error) {
        console.error(error);
        if (typeof dataSource === 'function') {
          return dataSource?.(params);
        }

        return dataSource ?? [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource, props.searchKey, fieldNames, dropdownVisible],
  );

  const handelDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
    props?.onDropdownVisibleChange?.(visible);
  };

  const handelChange = (_value) => {
    props?.onChange?.(_value?.[fieldNames?.value]);
    setValue(_value?.[fieldNames?.value]);
    setDropdownVisible(false);
    props?.onDropdownVisibleChange?.(false);
  };

  /** 编写组件逻辑 */
  return (
    <Select
      allowClear
      onChange={handelChange}
      dataSource={dropdownVisible ? getDataSource : dataSource}
      fieldNames={fieldNames}
      value={value}
      ref={ref}
      showSearch
      onDropdownVisibleChange={handelDropdownVisibleChange}
      {...rest}
      onClick={() => {
        setDropdownVisible(true);
        props?.onDropdownVisibleChange?.(true);
      }}
    />
  );
};
CodeTableSelect = forwardRef<any, NewSelectProps>(CodeTableSelect);
CodeTableSelect.displayName = 'CodeTableSelect';

export default compose(
  withField<string>({
    name: 'CodeTableSelect',
    handleEnter: true,
    shouldFieldUpdate: (newly: any, old: any) => {
      return true;
    },
  }),
  withPreview<NewSelectProps>({
    renderPreview: (props) => {
      const { value, fieldNames = DefaultFieldNames } = props;
      /** 返回预览模式下的dom */
      return (
        <>
          {props?.dataSource?.find(
            (item) =>
              item?.[fieldNames?.value] ===
              (value?.[fieldNames?.value] ?? value),
          )?.[fieldNames?.label] ?? value}
        </>
      );
    },
  }),
)(CodeTableSelect);
