import storage from '@/utils/storage';

const convertQueryAllByQueryDrugPrescriptionWaitDispenseMasterPatientWindowQtoRes2TableWaitDispenseListRo =
  (input, flge) => {
    const _ = window._ ?? {};
    const output = [];
    if (input?.data?.count > 0) {
      output.push(
        ..._.get(input, 'data.result').map((d) => {
          // if (d?.dispenseWindow) {
          const obj = {};
          obj.createdAt = _.get(d, 'createdAt');
          obj.name = _.get(d, 'patient.name');
          obj.patientId = _.get(d, 'patient.displayId');
          obj.windowDescription = _.get(d, 'dispenseWindow.windowDescription');
          obj.windowId = _.get(d, 'dispenseWindow.id');
          obj.id = _.get(d, 'id');
          obj.outpVisitId = _.get(d, 'outpVisitId');
          obj.queryDay = _.get(d, 'queryDay');
          obj.patId = _.get(d, 'patient.id');
          return obj;
          // }
        }),
      );
      return output;
    } else {
      return [];
    }
  };

/**
 * convertGetWaitDispenseDetailsRes2TableDispenseDetailRo
 * @param {any} input
 * @returns {any}
 */
const convertGetWaitDispenseDetailsRes2TableDispenseDetailRo = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ..._.get(input, 'data').map((d) => {
      const obj = {};
      obj.prescriptionDate = _.get(d, 'prescriptionDate');
      obj.windowDescription = _.get(d, 'dispenseWindowInfo.windowDescription');
      obj.orderDepartmentName = _.get(d, 'orderDepartmentName');
      obj.orderDoctorName = _.get(d, 'orderDoctorName');
      obj.prescriptionAttributeName = _.get(d, 'prescriptionAttributeName');
      obj.settleDate = _.get(d, 'outpSettleInfo.settleDate');
      obj.totalCharge = _.get(d, 'totalCharge');
      obj.diagnosisName = _.get(d, 'diagnosisName');
      obj.details =
        (_.get(d, 'drugPrescriptionWaitDispenseDetailList') ?? []).map(
          (detail) => {
            const item = {
              detailId: _.get(detail, 'id'), // 主键
              orderText: _.get(detail, 'orderText'), // 医嘱内容
              amountPackageUnit: _.get(detail, 'amountPackageUnit'), // 数量包装单位
              usage: _.get(detail, 'orderDrugInfo.usage'), // 用量
              frequency: _.get(detail, 'orderDrugInfo.frequency.frequency'), // 执行频率描述
              administration: _.get(detail, 'orderDrugInfo.administration'), // 给药途径
              performDays: _.get(detail, 'orderDrugInfo.performDays'), // 执行天数
              dosageFormName: _.get(detail, 'dosageFormName'), // 剂型
              firmName: _.get(
                detail,
                'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
              ), // 厂商
              amount: _.get(detail, 'amount'), // 数量
            };
            return item;
          },
        ) ?? [];
      obj.id = _.get(d, 'id');
      return obj;
    }),
  );
  return output;
};
/**
 * convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo
 * @param {any} input
 * @returns {any}
 */
const convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.windowDescription = _.get(input, 'windowDescription');
  output.toDispenseWindowId = _.get(input, 'id');
  return output;
};
/**
 * convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription
 * @param {any} input
 * @returns {any}
 */
const convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription =
  (input) => {
    const _ = window._ ?? {};
    const output = {
      secondPrepareDrugStaffId: input?.staff?.id,
      staffName: input?.staff?.staffName,
    };
    return output;
  };
const getPatInfo = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.name = _.get(input, 'patient.name');
  output.gender = _.get(input, 'patient.gender');
  output.age = _.get(input, 'age');
  output.displayId = _.get(input, 'patient.displayId');
  output.workAddress = _.get(input, 'patient.patientProfileDto.workUnit.name');
  output.height = _.get(input, 'height');
  output.weight = _.get(input, 'weight');
  output.pressure = _.get(input, 'pressure');
  output.allergyName = _.get(input, 'allergyName');
  output.chargeTypeName = _.get(input, 'chargeTypeName');
  output.identityName = _.get(input, 'identityName');
  output.phoneNumber = _.get(input, 'patient.phoneNumber');
  output.haveRefundSettle = _.get(input, 'haveRefundSettle');
  return output;
};

//List列表
export const list = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_queryAllByQueryDrugPrescriptionWaitDispenseMasterPatientWindowQto_0fc31f?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          // orderList: params.orderList,
          // ...params.search,
          // ...params.filters,
          // ...(params?.extra ?? {}),
          // ...(params ?? {}),
          ...params,
        },
        ext: {},
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output =
    convertQueryAllByQueryDrugPrescriptionWaitDispenseMasterPatientWindowQtoRes2TableWaitDispenseListRo(
      res,
      params?.queryAllWindowIs,
    );
  return output || [];
};

export const getDetailById = async (params) => {
  //条件&&params?.pageDetail?.useStatus
  if (params?.id) {
    const res =
      await vsf.services?.ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd?.(
        {
          ...params,
        },
      );
    return getPatInfo(res?.data);
  } else {
    return [];
  }
};

export const toDispenseWindowList = async (params) => {
  //
  // Tips: 以下是你选择的请求函数
  const currentApplication = storage.get('currentApplication');
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getCurrentStorageDispenseWindowSettings_cb90a1?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
      },
    ); // TODO: 你可能需要整理返回数据格式
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output = (res?.data ?? []).map((m) =>
    convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo(m),
  );
  return output;
};

export const windowDetail = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getLocalDispenseWindowSetting_302b1f?.(
      {},
    );
  return res?.data;
};

export const prepareDrugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription(
      m,
    ),
  );
};

export const secondPrepareDrugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription(
      m,
    ),
  );
};
