import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const Index = (props, ref) => {
  const { handelDiedaofx } = props;

  const [isDiasabled, setIsDisabled] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handelOpen = (v = false, value?) => {
    // cramsScore
    console.log(v);

    if (v) {
      if (value?.need === false) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      console.log(value?.need === false);

      setTimeout(() => vsf.refs?.fallRiskModal?.setFieldsValue(value), 100);
    }
    setOpen(v);
  };

  const handelOk = () => {
    // handelOpen(false);
    handelDiedaofx?.(vsf.refs?.fallRiskModal?.getFieldsValue());
    handelOpen(false);
    // return vsf.refs?.fallRiskModal?.getFieldsValue();
  };

  const needChange = (v) => {
    if (!v) {
      setIsDisabled(true);
      vsf.refs?.fallRiskModal?.setFieldsValue({
        need: v,
        name: [],
        way: [],
      });
    } else {
      setIsDisabled(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
    handelOk,
  }));
  return (
    <div className="fall_risk">
      <Modal
        centered
        width={900}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={
          <div className="icon18" style={{ color: '#3276E8' }}>
            跌倒风险评估单
          </div>
        }
        onOk={() => {
          handelOk();
          // handelOpen(false);
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <VSForm id="fallRiskModal" labelAlign="left" colon={false}>
          <VSFormLayout columnCount={1} labelWidth={300}>
            <VSFormItem
              name={['need']}
              valueType="radio"
              label={
                <div className="fall_risk_form_label icon18">
                  是否存在跌倒危险因素评估的需求
                </div>
              }
              initialValue={true}
              fieldProps={{
                onChange: needChange,
              }}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={1} labelWidth={80}>
            <VSFormItem
              name={['name']}
              valueType="checkbox"
              label={
                <div className="fall_risk_form_label icon18">评估项目</div>
              }
              fieldNames={{ label: 'label', value: 'value' }}
              dataSource={vsf.stores.dbenums.enums.ERGREEN_FALL_ASSESSMENT_DICT}
              fieldProps={{
                style: {
                  display: 'grid',
                },
                disabled: isDiasabled,
              }}
            />
            <VSFormItem
              name={['way']}
              valueType="checkbox"
              label={
                <div className="fall_risk_form_label icon18">采取措施</div>
              }
              fieldNames={{ label: 'label', value: 'value' }}
              dataSource={vsf.stores.dbenums.enums.ERGREEN_FALL_ASSESSMENT_DICT}
              fieldProps={{
                style: {
                  display: 'grid',
                },
                disabled: isDiasabled,
              }}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={2} labelWidth={80} gutter={100}>
            <VSFormItem
              name={['evaluator']}
              label={<div className="fall_risk_form_label icon18">评估人</div>}
              valueType="text"
              fieldProps={{ disabled: true }}
              initialValue={vsf.stores?.user?.staffInfo?.staffName}
            />
            <VSFormItem
              name={['evaluateTime']}
              valueType="date"
              label={
                <div className="fall_risk_form_label icon18">评估时间</div>
              }
              dataFormat="YYYY-MM-DD HH:mm:ss"
              fieldProps={{ showTime: true }}
              initialValue={+new Date()}
            />
          </VSFormLayout>
          <VSFormItem
            name={['evaluatorId']}
            label={<div className="fall_risk_form_label icon18">评估人</div>}
            fieldProps={{ disabled: true }}
            initialValue={vsf.stores?.user?.staffInfo?.id}
            hidden
          />
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 跌倒风险评估
