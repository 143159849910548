import vsf from '@vs/vsf-boot';
import { Button, Checkbox, message, RadioGroup, Select } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import Icon from '@/module/Icon';

import { ButtonAndKeyDown } from '../../..';
import { useOnChangeValue } from '../../../func/hooks';
import { AuxiliaryRefs, Drug } from '..';
import { Refs, TableRefs } from '../table';
import { convertOrderInTable } from '../table/convert';

interface HeaderProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  table: {
    current: Drug & TableRefs & Refs;
  };
}

export type HeaderRefs = {
  getState: () => any;
  onReload: () => Promise<any>;
};

const Header = forwardRef((props: HeaderProps, ref) => {
  const { auxiliary, table } = props;

  const prescriptionPermission = async (func: () => void) => {
    const state = await vsf?.stores?.user?.getStaffInfo?.();
    const prescriptionPermission = state?.doctorDto?.prescriptionPermission;
    if (prescriptionPermission) {
      func?.();
    } else {
      message?.warning('当前登录医生无处方权，无法开立医嘱');
    }
  };

  const items = [
    {
      // name: '药疗[1]',
      name: '药疗',
      icon: 'icon-yaoliao',
      click: () => {
        prescriptionPermission?.(() => {
          table?.current?.onDrug?.();
        });
      },
      ordinaryKey: '1',
    },
    {
      // name: '中草药[2]',
      name: '中草药',
      icon: 'icon-zhongcaoyao',
      click: async () => {
        prescriptionPermission(async () => {
          const state = await vsf?.stores?.user?.getStaffInfo?.();
          const herbPermission = state?.doctorDto?.herbPermission;
          if (!herbPermission) {
            message?.warning('没有开立中草药医嘱权限');
          } else {
            auxiliary?.current?.onHerb();
          }
        });
      },
      ordinaryKey: '2',
    },
    {
      // name: '检查[3]',
      name: '检查',
      icon: 'icon-jiancha',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onExam?.();
        });
      },
      ordinaryKey: '3',
    },
    {
      // name: '检验[4]',
      name: '检验',
      icon: 'icon-huayan',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onLab?.();
        });
      },
      ordinaryKey: '4',
    },
    {
      // name: '手术[5]',
      name: '手术',
      icon: 'icon-Frame-11',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onOperation?.();
        });
      },
      ordinaryKey: '5',
    },
    {
      // name: '病理[6]',
      name: '病理',
      icon: 'icon-a-Germsbingjun',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onPathology?.();
        });
      },
      ordinaryKey: '6',
    },
    {
      // name: '处置[7]',
      name: '处置',
      icon: 'icon-chuzhi1',
      click: () => {
        prescriptionPermission?.(() => {
          auxiliary?.current?.onDispose?.();
        });
      },
      ordinaryKey: '7',
    },
  ];

  return (
    <div className="extra-operation aspirin-order-module-header">
      <div className="left">
        药品成组请在途径内输入“/” 后按回车，或点击下方成组按钮
      </div>
      <div className="right">
        {items
          ?.filter((item) => !!item)
          ?.map((item: any) => {
            return (
              // onClick={item?.click}
              <div key={item?.icon} onClick={item?.click}>
                <Icon
                  size={item?.icon === 'icon-huzhi' ? 24 : 20}
                  type={item?.icon}
                />
                {/* <span>{item?.name}</span> */}
                <ButtonAndKeyDown
                  {...{
                    ordinaryKey: item?.ordinaryKey,
                    style: { padding: 0, border: 'none' },
                    // onClick: () => item?.click(),
                    onClick({ params, event }) {},
                    methodKey: 'altKey',
                    children: item?.name,
                    key: item?.name,
                    ghost: true,
                    type: 'primary',
                  }}
                />
              </div>
            );
          })}
        <ButtonAndKeyDown
          type="primary"
          ordinaryKey="T"
          methodKey="altKey"
          onClick={() =>
            prescriptionPermission?.(() => {
              auxiliary?.current?.onTemplate?.('1');
            })
          }
        >
          医嘱模版导入
        </ButtonAndKeyDown>
        <ButtonAndKeyDown
          type="primary"
          methodKey="altKey"
          ordinaryKey="R"
          onClick={() =>
            prescriptionPermission?.(() => {
              auxiliary?.current?.onTemplate?.('2');
            })
          }
        >
          历史医嘱导入
        </ButtonAndKeyDown>
      </div>
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
