import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeController_getOrderOutpBillingDetailList_7d1312: {
    method: 'post',
    url: '/api/medical-technology/get-order-outp-billing-detail-list',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpBillingDetailStaffDepartmentVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
  MedicalTechnologyChargeController_saveOrderOutpBillingDetails_41c276: {
    method: 'post',
    url: '/api/medical-technology/save-order-outp-billing-details',
    parameterFomatter: (data?: {
      outpBillingDetailList: AdjustOutpBillingDetailEoMedicalTechnologyPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  MedicalTechnologyChargeController_cancelPerformOutpBillingDetails_5491d7: {
    method: 'post',
    url: '/api/medical-technology/cancel-perform-outp-billing-details',
    parameterFomatter: (data?: {
      outpBillingDetailList: AdjustOutpBillingDetailEoMedicalTechnologyPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
