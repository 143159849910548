import { findKey } from 'lodash';

import KeyCodes, { KeyCodesSignature } from './keyCodes';

export const keyReg = /^on(Alt|Ctrl|Meta|Shift){0,4}([A-Z][a-z0-9]*)+$/;

export const splitKey = (str: string): Array<string> | null => {
  const reg = /[A-Z0-9][a-z]*/g;
  return str.match(reg);
};

export const isSpecialKey = (key: string) => {
  return ['Alt', 'Ctrl', 'Meta', 'Shift', 'Control'].includes(key);
};

export const validateKey = (e: any, ename: string) => {
  // check、split
  const keys = splitKey(ename);
  if (!keys) return;
  for (let index = 0; index < keys.length; index++) {
    const key: string = keys[index];
    if (isSpecialKey(key)) {
      // Alt Ctrl Meta Shift
      if (!e[`${key.toLowerCase()}Key`]) return false;
    } else {
      // 0-9
      const v = e?.code?.charAt(e?.code?.length - 1);
      if (/\d/.test(v)) {
        return key === v;
      }
      // A-Z ESC
      if (e.keyCode !== KeyCodes[key.toUpperCase()]) return false;
    }
  }
  return true;
};

export const validPropName = (e: KeyboardEvent, keyName: string) => {
  if (keyReg.test(keyName) && validateKey(e, keyName)) {
    return true;
  }
  return false;
};

export const getSpecialKey = (e, isShow = true) => {
  const res: string[] = [];
  if (e?.ctrlKey) {
    res.push(isShow ? KeyCodesSignature?.CTRL : 'Ctrl');
  }
  if (e?.altKey) {
    res.push(isShow ? KeyCodesSignature?.ALT : 'Alt');
  }
  if (e?.metaKey) {
    res.push(isShow ? KeyCodesSignature?.META : 'Meta');
  }
  if (e?.shiftKey) {
    res.push(isShow ? KeyCodesSignature?.SHIFT : 'Shift');
  }
  return res;
};

export const getKeyStr = (e) => {
  const key: string = findKey(KeyCodes, (v) => v === e?.keyCode) ?? '';
  if (key && !isSpecialKey(e?.key)) {
    if (e?.keyCode >= 48 && e?.keyCode <= 90) {
      return [e?.code?.charAt(e?.code?.length - 1)];
    }
    return [KeyCodesSignature?.[key] ? `${KeyCodesSignature?.[key]}` : e?.key];
  } else {
    return [];
  }
};

export const getKeyName = (e) => {
  const specialShowKey = getSpecialKey(e);
  const specialKey = getSpecialKey(e, false);
  const currentKey = getKeyStr(e) ?? [];
  return {
    label: `on${specialShowKey}${currentKey}`,
    value: [...specialShowKey, ...currentKey].filter((v) => v)?.join('+'),
    keys: [...specialKey, ...currentKey].filter((v) => v),
  };
};
