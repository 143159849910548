import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugApplyController_getDrugApplyMasterStorageVoById_9b7a0f: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-drug-apply-master-storage-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyMasterStorageVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugApplyController_getListDrugApplyMasterDetailProductVoByDrugApplyMasterId_69a8ec:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-list-drug-apply-master-detail-product-vo-by-drug-apply-master-id',
      parameterFomatter: (data?: { drugApplyMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugApplyDetailProductPriceStockProfileVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugApplyController_deleteDrugApplyMaster_d47f7f: {
    method: 'post',
    url: '/api/drug-storage-import-export/delete-drug-apply-master',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugApplyMasterBtoDrugStorageImportExportServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugApplyController_saveDrugApply_999999: {
    method: 'post',
    url: '/api/drug-storage-import/save-drug-apply',
    parameterFomatter: (data?: {
      drugApplyMasterDetailVo: DrugApplyMasterDetailVoDrugStorageImportExportEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugApplyController_submitDrugApply_da8985: {
    method: 'post',
    url: '/api/drug-storage-import/submit-drug-apply',
    parameterFomatter: (data?: {
      drugApplyMasterDetailVo: DrugApplyMasterDetailVoDrugStorageImportExportEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugApplyController_createApplyDetails_23076c: {
    method: 'post',
    url: '/api/drug-storage-import/create-apply-details',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyDetailProductPriceStockProfileVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugApplyController_queryListDrugApplyDrop_3db394: {
    method: 'post',
    url: '/api/drug-storage-import/query-list-drug-apply-drop',
    parameterFomatter: (data?: {
      qto: DrugPriceItemForDrugApplyDropQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugApplyDetailProductPriceStockProfileVoDrugStorageImportExportEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStorageController_getParentStorages_35c1c0: {
    method: 'post',
    url: '/api/drug-storage/get-parent-storages',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
