import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  Input,
  InputNumber,
  InputProps,
  message,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest, useUpdateEffect } from 'ahooks';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import login from '@/module/login';
import { user } from '@/stores';

import { askFixed } from '../func/ask';
import { useAvoidState, useFrequencyEffect } from '../func/hooks';
import amount from './amount';
import { useInputEnter } from './utils';

/**
 * 合并单位的 Input
 */
let InputUnit: any = (props, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    dataSource,
    record: recode,
    path = {
      performDays: ['drugOrder', 'performDays'],
      totalAmount: ['drugOrder', 'totalAmount'],
      frequency: ['drugOrder', 'frequency'],
      usage: ['drugOrder', 'usage'],
    },
    id,
    table,
    ...rest
  } = props;
  const { staffInfo } = user || {};
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  // 频次单位有歧义，之前只会算周天，但是产品说这单位维护的时候可以随便填可能会造成代码问题（建议改成选的不可以自定义填）
  enum DAY {
    日,
    周,
    天,
  }
  type FREQUENCY_TYPE = {
    [id in keyof typeof DAY]: () => number;
  };
  const FREQUENCY = (): number => {
    const method: FREQUENCY_TYPE = {
      周: () => {
        return (
          ((extension?.frequencyInterval ?? 0) * 7) /
          extension?.frequencyInterval /
          extension?.frequencyCount
        );
      },
      日: () => {
        return extension?.frequencyCount / extension?.frequencyInterval;
      },
      天() {
        return this.日();
      },
    };
    return method[extension?.frequencyIntervalUnit]?.() ?? 0;
  };
  const [current, setCurrent] = useGetState<any>('');
  const [unit, setUnit, getUnit] = useGetState<any>('');

  const valueRef = useRef<any>();

  useEffect(() => {
    if (typeof value === 'object') {
      setCurrent(value?.value);
      setUnit(value?.unit);
      valueRef.current = 1;
    } else {
      setCurrent(value);
      valueRef.current = 2;
    }
  }, [value, setCurrent, setUnit]);

  const form = Form?.useFormInstance();
  const record = form?.getFieldsValue(true);
  // const [id] = Object?.keys(record) ?? '';
  const performDays =
    vsf?.stores?.user?.hospitalType === 'out'
      ? [id, ...path.performDays]
      : [id, 'repeatValidDays'];
  const totalAmount = [id, ...path.totalAmount];
  const usage = [id, ...path.usage];
  const frequency = [id, ...path.frequency];
  const [data, setData] = useAvoidState({
    dosagePerUnit: 0,
    amountPerPackage: 0,
    roundingType: 'BY_AMOUNT',
    packageUnit: 0,
    retailPrice: 0,
  });

  const i = (drugItemId) => {
    const data = dataSource?.find?.((ele) => ele?.id == id);
    return (
      record?.[id]?.[drugItemId] ??
      record?.[id]?.drugOrder?.[drugItemId] ??
      record?.[id]?.orderText?.[drugItemId] ??
      record?.[id]?.orderText?.detailInfo?.drugEo?.[drugItemId] ??
      recode?.drugOrder?.[drugItemId] ??
      data?.drugOrder?.[drugItemId]
    );
  };

  const drugItemId: number = i('drugItemId') ?? i('drugPriceItemId');
  useRequest(
    async () => {
      if (drugItemId) {
        const res: any =
          await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
            {
              drugItemId,
              patientId: 1001,
              departmentId: 193,
              staffId: staffInfo?.id,
            },
          );
        if (res?.data?.errorMessage)
          return message?.error(res?.data?.errorMessage);
        setData(res?.data);
      }
    },
    {
      refreshDeps: [drugItemId],
      debounceWait: 500,
    },
  );
  useEffect(() => {
    if (data?.dosagePerUnit) form.getFieldInstance([id, 'dosage'])?.focus?.();
  }, [data?.dosagePerUnit, id, form]);
  const onChange = (value) => {
    const int = askFixed((value?.value ?? 0) / (data?.dosagePerUnit ?? 0), 2);
    form?.setFieldValue(usage, {
      value: Number?.isNaN(Number(int)) ? 0 : Number(int),
      unit: form?.getFieldValue(usage)?.unit,
    });
    props?.onChange?.(value);
  };

  const handleChange = (v) => {
    // console.log(v);
    const formatValue =
      valueRef?.current === 1
        ? {
            value: v,
            unit: getUnit(),
          }
        : v;
    onChange?.(formatValue);
  };
  /**
   * 判断是否成组
   */
  const find = dataSource?.find((item) => item?.id == id);
  const is = (id: string) => find?.group?.[id];
  const count = Form?.useWatch(frequency, form);
  const extension = Object?.values(count ?? {})?.filter(Boolean)?.length
    ? count
    : is('frequency');
  const perform_days = Form?.useWatch(performDays, form);
  const performDaysWatch =
    vsf?.stores?.user?.hospitalType === 'out'
      ? perform_days ?? is('performDays')
      : record?.[id]?.repeatIndicator === true
      ? perform_days
      : record?.[id]?.prescriptionIndicator === true
      ? perform_days
      : extension?.frequencyCount;

  const ROUNDING = {
    BY_DAY: () => {
      /**
       * 按天取整：按天为一个取整单位
       * 不再汇总使用天数的总使用剂量
       * 而是先以每天的总用量向上取整1次
       * 然后再根据使用天数汇总应发药总量
       * 例如: 医嘱为 剂量20mg，频次BID(日频次数为2)，用药天数16天
       * 计算规则为: (20mg*2)/(10mg*30) = 0.133333；每天向上取整为1盒，16天累计发药量为1盒*16=16盒
       **/
      const DOSAGE = (value?.value ?? 0) * (FREQUENCY?.() ?? 0);
      const USAGE = (data?.dosagePerUnit ?? 0) * (data?.amountPerPackage ?? 0);
      return Math?.ceil(DOSAGE / USAGE) * (performDaysWatch ?? 0);
    },
    BY_AMOUNT: () => {
      /**
       * 按量取整：是一种最大节约用药的模式
       * 根据医生开立剂量、频次，使用天数，计算出使用天数内使用的全部用量
       * 然后与供应规格 1 个包装下的含量进行比较
       * 得到的数字向上取整就计算出应发药品数量
       * 例如: 医嘱为 剂量 20mg, 频次BID(日频次数为2), 用药天数16天
       * 计算规则为: (20mg*2*16) /(10mg*30) = 2.133333,向上取整为3盒
       */
      const DOSAGE =
        (value?.value ?? 0) * (FREQUENCY?.() ?? 0) * (performDaysWatch ?? 0);
      const USAGE = (data?.dosagePerUnit ?? 0) * (data?.amountPerPackage ?? 0);
      return Math?.ceil(DOSAGE / USAGE);
    },
    BY_TIMES: () => {
      /**
       * 按次取整：按每次使用作为一个取整单位
       * 不再汇总使用天数的使用剂量
       * 而是先以每次的用量向上取整
       * 然后再根据使用天数的总次数汇总应发药总量
       * 例如: 医嘱为 剂量20mg，频次BID(日频次数为2)
       * 用药天数16天
       * 计算规则为:20mg/(10mg*30) = 0.666667，每次向上取整为1盒
       * 每天累计2盒，16天累计发药量为1盒*16*2=32盒
       */
      const DOSAGE = value?.value ?? 0;
      const USAGE = (data?.dosagePerUnit ?? 0) * (data?.amountPerPackage ?? 0);
      return (
        Math?.ceil(DOSAGE / USAGE) *
        (performDaysWatch ?? 0) *
        (FREQUENCY?.() ?? 0)
      );
    },
  };
  useFrequencyEffect(
    () => {
      // 因为这个药品中没有相关取整信息导致代码不知道应该取那个整，已经和产品沟通如果为空默认按量取整
      const int = ROUNDING?.[data?.roundingType ?? 'BY_AMOUNT']?.();
      const value = Number?.isNaN(int) ? 0 : int ?? 0;
      form?.setFieldValue(totalAmount, {
        ...(form?.getFieldValue(totalAmount) ?? {}),
        value,
      });

      /**
       * 计算总价
       */
      form?.setFieldValue(
        [id, 'allPrice'],
        Number?.(askFixed((data?.retailPrice ?? 0) * value, 2)),
      );
      /**
       * 如果为已成组的第一条，需要把子医嘱一起修改
       */
      // console.log(recode);
      // if (recode?.groupName === 'aspirin-order-text-group-first') {
      //   const group = dataSource?.filter((...[, index]) =>
      //     recode?.groupIndex?.includes(index),
      //   );
      //   for (const item of group?.slice(1)) {
      //     table?.current?.update({
      //       ...item,
      //       totalAmount: {
      //         value: amount({
      //           performDays: performDaysWatch,
      //           dosage: item?.dosage?.value,
      //           dosagePerUnit: item?.dosage?.value / item?.usage?.value,
      //           amountPerPackage: item?.dosage?.value * item?.usage?.value,
      //           extension: extension,
      //           roundingType: data?.roundingType,
      //         }),
      //         unit: item?.totalAmount?.value,
      //       },
      //     });
      //   }
      // }
    },
    2,
    [performDaysWatch, extension, value?.value],
    'before',
  );
  return (
    <div>
      <InputNumber
        disabled={!data?.dosagePerUnit}
        {...rest}
        ref={ref}
        value={current}
        onChange={handleChange}
        addonAfter={
          vsf?.stores?.dbenums?.enums?.DOSAGE_UNITS_DICT?.find(
            (item) => item?.value == unit,
          )?.label ?? unit
        }
        {...fieldEnterProps}
        onFocus={(v) => {
          v?.currentTarget?.select();
        }}
      />
    </div>
  );
};

InputUnit.displayName = 'dosage';

InputUnit = React.forwardRef(InputUnit);

export const DosageUnit = (props) => {
  const target = props?.value;
  if (typeof target === 'object') {
    return (
      <>
        {Object.values?.({
          value: props?.value?.value,
          unit:
            vsf?.stores?.dbenums?.enums?.DOSAGE_UNITS_DICT?.find(
              (item) => item?.value == target?.unit,
            )?.label ?? target?.unit,
        })}
      </>
    );
  } else {
    return <>{props?.value}</>;
  }
};

export default compose(
  withRef(),
  withField<any>({
    name: 'dosage',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        return <DosageUnit value={props?.value} />;
      },
    },
  ),
)(InputUnit) as typeof InputUnit;
