import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpDrugAuditController_getOrderToAuditByWard_ed7357: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-order-to-audit-by-ward',
    parameterFomatter: (data?: {
      query: QueryWardPatientWaitAuditEoDrugDispenseAuditPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: QueryWardOrderWaitAuditReturnEoDrugDispenseAuditPersistEo[],
    ) => data,
  },
});
