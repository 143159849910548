import SelectForm from '@/pages/Index/components/select_form';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
const LabClassEdit = (props) => {
  const [dataSource, setDataSource] = useState([])
  const getValue = async () => {
    const res = await vsf.services.LabController_getLabClassById_f20312({ id: props.value.id })
    vsf.refs.LabEdit?.setFieldsValue({
      ...res.data,
      branchInstitutionIdList: {
        select: res.data.branchInstitutionIdList
      }
    })
  }
  const getDataSource = async () => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );
    setDataSource(res.data.branchInstitutionList.map((item) => ({ label: item.institutionName, value: item.id })))
  }

  useEffect(() => {
    if (props.value.id) {
      getValue();
    } else {
      vsf.refs?.LabEdit?.resetFields();
    }
  }, [props.value?.id]);


  useEffect(() => {
    getDataSource();
  }, []);

  const isValue = useMemo(() => props?.value?.id, []);

  useEffect(() => {
    if(!isValue) {
      vsf?.refs?.LabEdit?.setFieldValue(['labClassCode'], String(dayjs()?.valueOf())?.slice(0, 20))
    }
  }, [isValue]);

  return (
    <VSControlledForm vsid="24425" id="LabEdit" labelAlign="left">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={2} labelWidth={130} gutter={100}>
        <VSFormItem
          name={['labClassCode']}
          label="检验类别代码"
          valueType="text"
          rules={[
            { message: '检验类别代码不能超过20个', type: 'string', min: 0, max: 20 },
            { required: true },
          ]}
          fieldProps={{
            disabled: props.value?.id
          }}
        />

        <VSFormItem
          name={['labClassName']}
          label="检验类别名称"
          valueType="text"
          rules={[
            {
              message: `检验类别名称不能超过20个字`,
              max: 20,
              min: 0,
            },
            { required: true },
          ]}
          fieldProps={{
            onChange: (value) => {
              vsf?.refs?.LabEdit?.setFieldValue('inputCode', askRomanAlphabet(value.target.value))
            }
          }}
        />

        <VSFormItem
          name={['inputCode']}
          label="拼音输入码"
          valueType="text"
          rules={[
            {
              message: `拼音输入码不能超过10个字`,
              max: 10,
              min: 0,
            },
            () => ({
              validator(_, value) {
                if (String(value).includes(',')) {
                  return Promise.reject('多音字待确认')
                } else {
                  return Promise.resolve();
                }
              }
            })
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['branchInstitutionIdList']}
          valueType="custom"
        >
          <SelectForm
            dataSource={dataSource}
            label="使用院区"
            rules={[{ required: true, message: "请选择院区" }]}
            selectLayoutProps={{
              columnCount: 1,
              labelWidth: 130,
              gutter: 50
            }}
            selectAllText="全部院区"
          />
        </VSFormItem>
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(LabClassEdit);
