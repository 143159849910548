import './index.less';

import vsf from '@vs/vsf-boot';
import { Button, Drawer, Input, Radio, Tabs } from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ButtonAndKeyDown, Determine } from '@/pages/Index/components';
import {
  useOnChangeValue,
  useSignal,
} from '@/pages/Index/components/func/hooks';

import DefendHistory from './DefendHistory';
import DefendTemplate from './DefendTemplate';

interface DefendImportProps {
  onChange?: (value) => void;
  tableRef?: any;
}

interface Ref {
  onSubmit: () => Promise<any>;
}

const DefendTemplateApp = forwardRef((props: DefendImportProps, ref) => {
  const { onChange, tableRef } = props;

  const [open, setOpen] = useState<boolean>(false);
  const items = [
    {
      label: '医嘱模版',
      key: '1',
    },
    {
      label: '历史医嘱',
      key: '2',
    },
  ];

  const history = useRef<Ref>(null);
  const template = useRef<Ref>(null);
  const [tab] = useOnChangeValue('1');

  const onOpen = useCallback(
    (value) => {
      setOpen(!open);
      if (value) tab?.onChange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  useImperativeHandle(ref, () => {
    return {
      onOpen,
    };
  });

  const onClose = () => {
    setOpen(false);
  };

  const Footer = () => {
    const onSubmit = async () => {
      const take =
        tab?.value === '1'
          ? template?.current?.onSubmit
          : history?.current?.onSubmit;
      const value = await take?.();
      console.log(value, 'value111');

      onChange?.(value?.data);
      onClose?.();
    };
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <ButtonAndKeyDown methodKey="altKey" ordinaryKey="C" onClick={onClose}>
          取消
        </ButtonAndKeyDown>
        <ButtonAndKeyDown
          type="primary"
          methodKey="altKey"
          ordinaryKey="E"
          onClick={onSubmit}
        >
          确定
        </ButtonAndKeyDown>
      </div>
    );
  };
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={
        <Tabs
          items={items}
          type="card"
          onChange={tab?.onChange}
          activeKey={tab.value}
        />
      }
      width="70%"
      closeIcon={null}
      className="aspirin-drawer-border-bottom"
      bodyStyle={{
        overflow: 'hidden',
      }}
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      footer={<Footer />}
      destroyOnClose
    >
      <Determine
        condition={tab?.value === '1'}
        fallback={<DefendHistory ref={history} />}
      >
        <DefendTemplate ref={template} tableRef={tableRef} />
      </Determine>
    </Drawer>
  );
});

DefendTemplateApp.displayName = 'DefendTemplateApp';

export default DefendTemplateApp;
