import TransferExpand from '@/pages/Index/components/transfer_expand/index.jsx';
import { getScrollY, getScrollYRem } from '@/utils';
import { Button, message } from '@vs/vsf-kit';
import { useLockFn } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import {
  Department,
  RenderLeft,
  RenderRight,
} from '../../components/department';

const PerformDepartment = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const getInstitution = useCallback(async (params) => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );

    return res.data;
  }, []);

  const getDataSource = useCallback(async (branchInstitutionIdIn) => {
    const institution = await getInstitution();
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
        {
          qto: {
            stopIndicatorIs: false,
            institutionIdIs: institution?.id,
            branchInstitutionIdIn,
          },
        },
      );
    setDataSource(
      res.data.map((item) => {
        return {
          ...item,
          departmentId: item.id,
          departmentName: `${item.departmentName}(${item.branchInstitution?.institutionName})`,
        };
      }),
    );
  }, []);

  const getData = useCallback(async (id) => {
    const res =
      await vsf?.services?.ExamClassDictionaryDtoController_getExamClassWithDepartmentById_9db767?.(
        { id },
      );
    getDataSource(
      res?.data?.examClassDictionaryExtensionDtoList?.map(
        (item) => item.branchInstitution.id,
      ),
    );
    res.data.examVsDepartmentDtoList = res.data.examVsDepartmentDtoList.map(
      (item) => ({
        ...item,
        id: item.department.id,
        _id: item.id,
        departmentName: item.department.departmentName,
      }),
    );
    setData(res.data);
  }, []);

  useEffect(() => {
    if (props.value?.id) {
      getData(props.value.id);
    }
  }, [props.value?.id]);
  const TransferProps = {
    showSelectAll: false,
    dataSource,

    style: {
      // height: getScrollYRem(18),
      height: 'calc(-118rem + 100vh) !important',
    },
    listStyle: ({ direction }) => {
      const dict = {
        left: {
          flex: 0,
          flexBasis: '25%',
          borderTop: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        right: {
          flex: 0,
          flexBasis: '75%',
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
      };
      return dict[direction];
    },
  };
  const onSubmit = useLockFn(async (params) => {
    try {
      await vsf?.refs?.PerformDepartment?.getEditForm()?.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.ExamClassDictionaryBOController_addDepartmentToExamClass_e05bca?.(
          {
            btoParam: {
              id: props.value.id,
              examVsDepartmentBtoList: params?.length
                ? params?.map((item, index) => {
                    const dict = {
                      departmentId: item.id,
                      priority: index + 1,
                    };
                    if (item._id) dict.id = item._id;
                    return dict;
                  })
                : [],
            },
            extApiParam: params?.extApiParam,
          },
        );
      if (res.code === 200) {
        message.success('保存执行科室成功');
        getData(props.value.id);
      }
    } catch (error) {
      return error;
    }
  });

  return (
    <TransferExpand
      {...TransferProps}
      left={<RenderLeft />}
      right={<RenderRight />}
      data={data?.examVsDepartmentDtoList}
      labelKey="id"
      search={{
        left: {
          render: (
            <Department
              getDataSource={data?.examClassDictionaryExtensionDtoList?.flatMap(
                (item) => {
                  return item.branchInstitution;
                },
              )}
            />
          ),
        },
      }}
      footer={(data) => (
        <Button
          onClick={() => onSubmit(data)}
          disabled={!props?.value?.id}
          type="primary"
        >
          保存
        </Button>
      )}
    />
  );
};

export default PerformDepartment;
