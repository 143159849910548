import { createServices } from '@vs/vsf-boot';
export default createServices({
  NurseBillingController_getInpOrderCost_c86d04: {
    method: 'post',
    url: '/api/clinic-record-order/get-inp-order-cost',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderIdList: number[];
      includeTodayCancel: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: NurseInpOrderCostInfoVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
