import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpSettleController_preSettle_f20115: {
    method: 'post',
    url: '/api/outp-billing/pre-settle',
    parameterFomatter: (data?: {
      patientId: number;
      selfPaymentIndicator: boolean;
      outpVisitUnsettledRecordVos: OutpVisitUnsettledRecordVoOutpBillingEntranceWebVo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpPreSettleVoOutpBillingEntranceWebVo,
    ) => data,
  },
});
