// 诊断列表查询
export const convertGetDiagnosisRecordByInpRes2DiagnosisRecord = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.isMainDiagnosis = _.get(d, 'isMainDiagnosis');
      obj.diagnosisId = _.get(d, 'diagnosis.id');
      obj.prefix = _.get(d, 'prefix');
      obj.diagnosisDescription = _.get(d, 'diagnosisDescription');
      obj.diagnosisType = _.get(d, 'diagnosisType');
      obj.suffix = _.get(d, 'suffix');
      return obj;
    }) ?? []),
  );
  return output;
};
// 诊断删除
export const convertDiagnosisRecord2DeleteDiagnosisRecordReq = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.bto = {};
  output.bto.id = _.get(input, 'id');
  return output;
};
// 保存诊断
export const convertDiagnosisRecordList2SaveDiagnosisRecordListReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoParamList = [];
  output.btoParamList.push(
    ...(input?.map((d, i) => {
      const obj = {};
      // obj.id = _.get(d, `id`);
      obj.patientId = _.get(d, `patientId`); // Long 患者标识
      obj.diagnosisType = _.get(d, `diagnosisType`);
      obj.diagnosisDescription = _.get(d, `diagnosisDescription`);
      obj.diagnosisId = _.get(d, `diagnosisId`); // Long 诊断代码
      obj.inpVisitId = _.get(d, `inpVisitId`);
      return obj;
    }) ?? []),
  );
  output.extApiParam = {};
  return output;
};
// 用药记录列表
export const convertGetMedicineRecordRes2MedicineRecord = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.orderText = _.get(d, 'orderText');
      obj.startPerformDateTime = _.get(d, 'startPerformDateTime');
      obj.groupNumber = _.get(d, 'orderDrug.groupNumber');
      obj.groupSubNumber = _.get(d, 'orderDrug.groupSubNumber');
      obj.dosage = _.get(d, 'orderDrug.dosage');
      obj.dosageUnit = _.get(d, 'orderDrug.dosageUnit');
      obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
      return obj;
    }) ?? []),
  );
  return output;
};
// 检查列表
export const convertGetInpOverviewExamOrLabRes2ExamReport = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'orderWithExamVo.id');
      obj.orderText = _.get(d, 'orderWithExamVo.orderText');
      obj.submitDateTime = _.get(d, 'orderWithExamVo.submitDateTime');
      obj.resultStatus = _.get(d, 'orderWithExamVo.orderExamDto.resultStatus');
      return obj;
    }) ?? []),
  );
  return output;
};
// 检验列表
export const convertGetInpOverviewExamOrLabRes2LabReport = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'orderWithLabVo.id');
      obj.orderText = _.get(d, 'orderWithLabVo.orderText');
      obj.submitDateTime = _.get(d, 'orderWithLabVo.submitDateTime');
      obj.labStatus = _.get(d, 'orderWithLabVo.orderLabOrderDto.labStatus');
      return obj;
    }) ?? []),
  );
  return output;
};
