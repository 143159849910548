import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpSettleController_queryAllOutpSettleMaster_246314: {
    method: 'post',
    url: '/api/outp-billing/query-all-outp-settle-master',
    parameterFomatter: (data?: {
      qto: OutpSettleMasterNewQtoOutpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpSettleMasterWithPaymentInfoDtoOutpBillingManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OutpSettleController_createZeroBillingDetail_33ddf8: {
    method: 'post',
    url: '/api/outp-billing/create-zero-billing-detail',
    parameterFomatter: (data?: { outpSettleMasterId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
