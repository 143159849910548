import vsf from '@vs/vsf-boot';
import { Checkbox, VSTable, VSTableColumn, VSTableProps } from '@vs/vsf-kit';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import Icon from '@/module/Icon';
import { getScrollY, getScrollYRem } from '@/utils';

import Determine from '../../../determine';
import Flex from '../../../flex';
import { askSearch } from '../../../func/ask';
import { useAvoidState } from '../../../func/hooks';
import { UsageUnit } from '../../../usage';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import {
  drugBillingAttributeDataSource,
  orderClassDataSource,
} from './datasource';

interface OutHospitalTemplateImportProps {
  rowSelection: {
    onSelectedRowsChange: (key: any) => void;
    selectedRows: [];
  };
}

const OutHospitalTemplateImport = forwardRef((props: any, ref) => {
  const { rowSelection } = props;

  const [value, setValue] = useAvoidState([]);

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'],
        ['dripSpeed'],
        ['frequency'],
        ['useDescription'],
        ['performDays'],
      ],
      tip() {},
    }),
  );
  useImperativeHandle(ref, () => {
    return {
      setValue: (value) => {
        setValue(group?.current?.onSliced?.(value));
      },
    };
  });
  return (
    <VSTable
      value={value}
      pagination={false}
      scroll={{
        y: getScrollYRem(20),
        x: '66.7rem',
      }}
      bordered
    >
      <VSTableColumn
        dataIndex={['check']}
        valueType="custom"
        render={(...[, record]) => {
          const selectedRows = rowSelection?.selectedRows as any[];
          const state = selectedRows?.map((item) => item?.id);
          const _value = state?.includes(record?.id);
          let render: any = null;
          if (record?.groupName === 'aspirin-order-text-group-first') {
            render = (
              <Checkbox
                value={_value}
                onChange={(info) => {
                  const group = value?.filter((...[, index]) =>
                    record?.groupIndex?.includes(index),
                  );
                  if (info) {
                    rowSelection?.onSelectedRowsChange?.(
                      [
                        ...selectedRows?.filter(
                          (item) =>
                            !group
                              ?.map((item: any) => item?.id)
                              ?.includes(item?.id),
                        ),
                        ...group,
                      ],
                      [],
                    );
                  } else {
                    rowSelection?.onSelectedRowsChange?.(
                      selectedRows?.filter(
                        (item) =>
                          !group
                            ?.map((item: any) => item?.id)
                            ?.includes(item?.id),
                      ),
                      [],
                    );
                  }
                }}
              />
            );
          }
          if (!record?.groupName) {
            render = (
              <Checkbox
                value={_value}
                onChange={(info) => {
                  if (info) {
                    rowSelection?.onSelectedRowsChange?.(
                      [...selectedRows, record],
                      [],
                    );
                  } else {
                    rowSelection?.onSelectedRowsChange?.(
                      selectedRows?.filter((item) => item?.id !== record?.id),
                      [],
                    );
                  }
                }}
              />
            );
          }
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {render}
            </div>
          );
        }}
        title={() => {
          const selectedRows: any = rowSelection;
          const _value: any =
            selectedRows?.selectedRows?.length === value?.length &&
            selectedRows?.selectedRows?.length !== 0 &&
            value?.length !== 0;
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Checkbox
                value={_value}
                indeterminate={
                  selectedRows?.selectedRows?.length > 0 &&
                  selectedRows?.selectedRows?.length < value.length
                }
                onChange={(info) => {
                  if (info) {
                    selectedRows?.onSelectedRowsChange?.(value, []);
                  } else {
                    selectedRows?.onSelectedRowsChange?.([], []);
                  }
                }}
              />
            </div>
          );
        }}
        editable={false}
        hideInTable={!rowSelection}
        fixed="left"
        width="2rem"
      />
      <VSTableColumn
        title="类别"
        dataIndex={['orderClass']}
        valueType="select"
        dataSource={orderClassDataSource}
        preview
        width="2.6rem"
      />
      <VSTableColumn
        title="医嘱"
        dataIndex={['orderText']}
        valueType="custom"
        width="14.7rem"
        className="aspirin-table-order-cell"
        render={(element, record) => {
          return (
            <div
              className={classNames('aspirin-order-text', record?.groupName)}
            >
              {record?.orderText}
            </div>
          );
        }}
      />
      <VSTableColumn
        title="剂量"
        dataIndex={['dosage']}
        valueType="dosage"
        width="3.4rem"
      />
      <VSTableColumn
        title="用量"
        dataIndex={['usage']}
        valueType="usage"
        width="3.4rem"
      />
      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="administrationSelect"
        width="3.4rem"
      />
      <VSTableColumn
        dataIndex={['frequency']}
        title="频次"
        valueType="select"
        width="5rem"
        dataSource={(option) => {
          const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
            (item) => {
              return {
                ...item,
                ...JSON?.parse(item?.extensionJson ?? '{}'),
              };
            },
          );
          const state = option?.frequency
            ? value?.filter((item) =>
                askSearch(option?.frequency, item, 'frequency'),
              )
            : value;
          return state;
        }}
        render={(...[, record]) => {
          return record?.frequency?.frequency ?? record?.frequency?.label ?? '';
        }}
      />
      <VSTableColumn
        dataIndex={['useDescription']}
        title="用法"
        valueType="select"
        fieldProps={{
          allowClear: true,
          showSearch: true,
        }}
        width="3.4rem"
        dataSource={vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT}
      />
      <VSTableColumn
        dataIndex={['performDays']}
        title="天数"
        valueType="digit"
        width="3rem"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['totalAmount']}
        title="数量"
        valueType="custom"
        fieldProps={{
          allowClear: true,
          width: '100%',
        }}
        render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
        width="4rem"
      />
      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        valueType="text"
        width="3.4rem"
      />
      <VSTableColumn
        dataIndex={['drugBillingAttribute']}
        title="自备"
        valueType="check"
        width="3rem"
        render={(...[, record]) => {
          return (
            <Determine condition={record?.orderClass === 'DRUG'}>
              <Flex justify="center">
                <Determine
                  condition={record?.drugBillingAttribute === 'SELF_PROVIDE'}
                >
                  <Icon type="icon-dui" size={24} className="icon-color-dui" />
                </Determine>
              </Flex>
            </Determine>
          );
        }}
      />
    </VSTable>
  );
});

OutHospitalTemplateImport.displayName = 'OutHospitalTemplateImport';

export default OutHospitalTemplateImport;
