import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryInpOrderDrugList_d5d0a5: {
    method: 'post',
    url: '/api/drug-drug/query-inp-order-drug-list',
    parameterFomatter: (data?: {
      qto: DrugDictionaryDrugListQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
