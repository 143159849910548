import React, { useState } from 'react';

import { Page, Column, Columns, Tag } from '@vs/vsf-kit';
import Container from '@/module/Container';
import SelectTag from '@/pages/Index/components/func/SelectTag';
import vsf, { definePage } from '@vs/vsf-boot';

import FunctionTable from './table';
import SystemConfigTypeTree from './tree';

const FunctionManage = (props) => {
  /**
   * SystemConfig
   * @type {object}
   */
  const [systemConfig, setSystemConfig] = useState();

  return (
    <Container
      layout={{
        items: [
          {
            itemClassName: 'marginRight16',
            gap: '16px',
            // block: '392px',
            block: '24%',

            bgColor: 'var(--background)',
          },
          {
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <SystemConfigTypeTree
        onChange={(_value) => {
          setSystemConfig({ ..._value });
        }}
      />
      <FunctionTable
        handelResert={() => {
          setSystemConfig(undefined);
        }}
        value={systemConfig}
      />
    </Container>
  );
};
export default definePage(FunctionManage);
