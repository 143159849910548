import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Divider,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
  Placeholder,
  VSForm,
  Popover,
  VSPromiseTable,
  VSTableColumn,
  VSTable,
} from '@vs/vsf-kit';
import './index.less';
import { askFixed } from '@/pages/Index/components/func/ask';
import { add, subtract } from '@/utils/setDataUtil';
import { number } from 'echarts';
function liquidationOnACCount(props) {
  const { payParamData, appointmentStatus, patientInformation } = props;

  //弹窗相关
  const chargeType = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;
  const identity = vsf.stores.dbenums.enums.IDENTITY_DICT;
  const [payWays, setPayWays] = useState();

  const [selectAdvanceData, setSelectSelectAdvanceData] = useState();

  const [advanceTotal, setAdvanceTotal] = useState();
  const [chargeData, setChargeData] = useState();
  const [identityData, setIdentityData] = useState();

  const [paymentWays, setPaymentWays] = useState();

  useEffect(() => {
    // chargeType.map((item) => {
    //   if (item?.value === payParamData?.outpSettleMasterVo?.chargeType) {
    //     setChargeData(item);
    //   }
    // });
    // identity.map((item) => {
    //   if (item?.value === payParamData?.outpSettleMasterVo?.identityType) {
    //     setIdentityData(item);
    //   }
    // });
  }, [chargeType, identity]);

  useEffect(() => {
    // console.log(payParamData);
    if (payParamData) {
      vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
        'prePaymentAmount',
        payParamData?.prePaymentAmount,
      );
      // vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
      //   'totalCharge',
      //   payParamData?.inpSettleMasterVo?.totalCharge,
      // );
      vsf?.refs?.hospitalizationParmentForm1?.setFieldsValue({
        totalCharge: payParamData?.inpSettleMasterVo?.totalCharge,
        paymentType: payParamData?.paymentDetail?.[0]?.paymentType,
      });
      if (payParamData?.prePaymentMasterVoList) {
        const data = payParamData?.prePaymentMasterVoList.map((item) => {
          return {
            id: item?.id,
            prePaymentAmount: item?.prePaymentAmount,
            prePaymentDate: item?.prePaymentDate,
            physicalReceiptNumber:
              item?.inpReceiptRecordBaseDtoList[0]?.physicalReceiptNumber ?? '',
            receiptNumber:
              item?.inpReceiptRecordBaseDtoList[0]?.receiptNumber ?? '',
            paymentType: item?.prePaymentDetailBaseDtoList[0]?.paymentType,
          };
        });
        setPaymentWays(
          Array.from(new Set(data?.map((item) => item?.paymentType))),
        );
      }
    }
  }, [payParamData]);

  useEffect(() => {
    let tatal = 0.0;
    selectAdvanceData?.map((item) => {
      tatal = add(tatal, item?.prePaymentAmount, 2);
    });
    setAdvanceTotal(tatal);
    if (!tatal) {
      // vsf?.refs?.hospitalizationParmentForm?.setFieldValue(
      //   'totalCharge',
      //   payParamData?.inpSettleMasterVo?.totalCharge,
      // );
    }
  }, [selectAdvanceData]);

  useEffect(() => {}, [paymentWays]);

  const advance = () => {
    return (
      <div style={{ width: '700px' }}>
        <VSTable
          editable={{ editType: 'none' }}
          vsid="15941"
          id="advanceMoneyHospitalizationTable"
          rowSelection={{
            selectType: 'multiple',
            onSelect: (node, key, selected) => {},
            onSelectedRowsChange: (data, keys) => {
              setSelectSelectAdvanceData(data);
            },
          }}
          value={() => {
            const data = (payParamData?.prePaymentMasterVoList ?? []).map(
              (item) => {
                return {
                  id: item?.id,
                  prePaymentAmount: item?.prePaymentAmount,
                  prePaymentDate: item?.prePaymentDate,
                  physicalReceiptNumber:
                    item?.inpReceiptRecordBaseDtoList[0].physicalReceiptNumber,
                  receiptNumber:
                    item?.inpReceiptRecordBaseDtoList[0].receiptNumber,
                  paymentType: item?.prePaymentDetail[0]?.paymentType,
                };
              },
            );
            return data;
          }}
          rowKey={'id'}
          pagination={false}
        >
          <VSTableColumn
            dataIndex={['prePaymentDate']}
            title="支付日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['prePaymentAmount']}
            title="预交金数额"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['physicalReceiptNumber']}
            title="票据号"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['receiptNumber']}
            title="支票号"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['paymentType']}
            title="付款方式"
            valueType="select"
            dataSource={[
              { label: '现金', value: 'CASH' },
              { label: '支付宝', value: 'ALIPAY' },
              { label: '微信', value: 'WECHAT' },
              { label: '数字人民币', value: 'DIGITAL_RMB' },
              { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
            ]}
            fieldProps={{}}
          />
        </VSTable>
      </div>
    );
  };

  return (
    <div className="pay-layout-container">
      <div className="pay-person-detaill">
        <Row>
          <Col span={6}>
            姓名：
            <span className="person-detaill">
              {patientInformation?.name ?? ''}
            </span>
            {/* <span>{feibie}</span> */}
          </Col>
          <Col span={6}>
            费别：
            <span className="person-detaill">
              {patientInformation?.chargeType &&
                (vsf.stores.dbenums.enums.CHARGE_TYPE_DICT?.find(
                  (item) => item?.value === patientInformation?.chargeType,
                )?.label ??
                  '')}
            </span>
          </Col>
          <Col span={6}>
            人员类别：
            <span className="person-detaill">
              {patientInformation?.identityCode &&
                (vsf.stores.dbenums.enums.IDENTITY_DICT?.find(
                  (item) => item?.value === patientInformation?.identityCode,
                )?.label ??
                  '')}
            </span>
          </Col>
          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="pay-person-content">
        <div className="pay-person-content-left">
          <div>
            <Row>
              {payParamData?.inpSettleApportionItemVoList?.map((item) => {
                return (
                  <>
                    <Col span={12}>
                      <span className="total-cost">
                        {item?.settleApportionName}:
                      </span>
                      <span className="total-number">
                        {askFixed(item?.apportionCharge, 2)}元
                      </span>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="pay-person-content-right">
          <div className="receivable-accounts" style={{ marginBottom: '12px' }}>
            <div>应收款</div>
            <div className="receivable-number">
              {askFixed(payParamData?.inpSettleMasterVo?.totalCharge, 2)}元
            </div>
          </div>
          <div className="receivable-accounts" style={{ marginBottom: '12px' }}>
            <div>预交金</div>
            <div className="receivable-number">
              <a style={{ textDecoration: 'underline' }}>
                {advanceTotal === 0
                  ? askFixed(payParamData?.prePaymentAmount, 2)
                  : askFixed(advanceTotal, 2)}
                元
              </a>
            </div>
          </div>

          <VSForm id="hospitalizationParmentForm1">
            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['totalCharge']}
                label={
                  <div className="receivable-form-title">
                    {payParamData?.paymentDetail?.[0]?.paymentType ===
                    'PATIENT_DEBT'
                      ? '病人欠款'
                      : '病人余款'}
                  </div>
                }
                valueType="digit"
                fieldProps={{
                  style: { height: '48px', lineHeight: '48px', fontSize: 16 },
                  width: '100%',
                  precision: 2,
                  // defaultValue: payParamData?.inpSettleMasterVo?.totalCharge,
                  onChange: (value) => {
                    // console.log(value);
                    if (value) {
                      vsf.refs.hospitalizationParmentForm1?.setFieldValue(
                        'remaining',
                        subtract(
                          value,
                          askFixed(
                            payParamData?.inpSettleMasterVo?.totalCharge,
                            2,
                          ),
                          2,
                        ),
                      );
                    } else {
                      vsf.refs.hospitalizationParmentForm1?.setFieldValue(
                        'remaining',
                        askFixed(
                          payParamData?.inpSettleMasterVo?.totalCharge,
                          2,
                        ),
                      );
                    }
                  },
                }}
              />
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['remaining']}
                label={<div className="receivable-form-title">找零</div>}
                valueType="digit"
                fieldProps={{
                  style: { height: '48px', lineHeight: '48px', fontSize: 16 },
                  width: '100%',
                  precision: 2,
                }}
              />
            </VSFormLayout>
            {/* 
            <VSFormLayout columnCount={1} labelWidth={120} gutter={50}>
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">找零</div>}
                valueType="text"
                fieldProps={{
                  style: { height: '48px' },
                }}
              />
            </VSFormLayout> */}

            <VSFormLayout columnCount={1}>
              <VSFormItem
                name={['paymentType']}
                valueType="radio"
                dataSource={[
                  payParamData?.paymentDetail?.[0]?.paymentType ===
                  'PATIENT_DEBT'
                    ? { label: '病人欠款', value: 'PATIENT_DEBT' }
                    : { label: '病人余款', value: 'PATIENT_SURPLUS' },
                ]}
                fieldProps={{
                  onChange: (item) => {
                    setPayWays(item);
                  },
                  // defaultValue: payParamData?.paymentDetail?.[0]?.paymentType,
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
      </div>
    </div>
  );
}

export default liquidationOnACCount;
