import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getByPricePatternByPricePatternMasterId_0dd128: {
    method: 'post',
    url: '/api/price-list/get-by-price-pattern-by-price-pattern-masterId',
    parameterFomatter: (data?: { pricePatternMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternDetailWithPriceItemVoPriceListEntranceWebVo[],
    ) => data,
  },
});
