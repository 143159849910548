import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserStaffController_queryPagedByStaffSignatureQto_b42a04: {
    method: 'post',
    url: '/api/user-staff/query-paged-by-staff-signature-qto',
    parameterFomatter: (data?: {
      qto: StaffSignatureQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithSignatureVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryAllStaffOfSignatureCapableBySimpleStaffListQto_02021f:
    {
      method: 'post',
      url: '/api/user-staff/query-all-signature-capable-by-simple-staff-list-qto',
      parameterFomatter: (data?: {
        qto: SimpleStaffListQtoSimpleUserStaffPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: StaffSimpleVoUserStaffEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  UserStaffController_queryAllByStaffSignatureDepartmentListQto_57342d: {
    method: 'post',
    url: '/api/user-staff/query-all-by-staff-signature-department-list-qto',
    parameterFomatter: (data?: {
      qto: StaffSignatureDepartmentListQtoSimpleUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSignatureWithDepartmentVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_createOrUpdateSignature_47012a: {
    method: 'post',
    url: '/api/user-staff/create-or-update-signature',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateSignatureBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithSignatureVoUserStaffEntranceWebVo,
    ) => data,
  },
  UserStaffController_queryAllByStaffSignatureQto_d7e43d: {
    method: 'post',
    url: '/api/user-staff/query-all-by-staff-signature-qto',
    parameterFomatter: (data?: {
      qto: StaffSignatureQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithSignatureVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryAllByQueryCapableStaffListWithSignatureQto_7b7f75: {
    method: 'post',
    url: '/api/user-staff/query-all-by-query-capable-staff-list-with-signature-qto',
    parameterFomatter: (data?: {
      qto: QueryCapableStaffListWithSignatureQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryAllByQueryCapableDepartmentListWithSignatureQto_e3193b:
    {
      method: 'post',
      url: '/api/user-staff/query-all-by-query-capable-department-list-with-signature-qto',
      parameterFomatter: (data?: {
        qto: QueryCapableDepartmentListWithSignatureQtoUserStaffPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: StaffSignatureWithDepartmentVoUserStaffEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
