import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamOrderController_getExamItemInfo_3da8d6: {
    method: 'post',
    url: '/api/exam/order/get-exam-item-info',
    parameterFomatter: (data?: { examV_sClinicIds: number[] }) => data,
    responseFormatter: (_, __, data?: ExamItemInfoEoExamPersistEo) => data,
  },
  ExamOrderController_getExamClassInfoByClinicItemId_c82623: {
    method: 'post',
    url: '/api/exam/get-exam-class-info-by-clinic-item-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (_, __, data?: ExamVsClinicItemBaseDtoExamManagerDto) =>
      data,
  },
  DepartmentDtoController_getDepartmentListByIds_8b28f0: {
    method: 'post',
    url: '/api/organization/get-department-list-by-ids',
    parameterFomatter: (data?: { ids: number[] }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
