import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_getOrderBillingInpBillingDetailGroupInfo_5b1e07: {
    method: 'post',
    url: '/api/inp-billing/get-order-billing-inp-billing-detail-group-info',
    parameterFomatter: (data?: { inpVisitId: number; orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailGroupForNurseStationVoInpBillingEntranceWebVo[],
    ) => data,
  },
});
