import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

const Index = (props) => {
  // console.log(props);
  const {
    children = '保存',
    height,
    buttonProps,
    type = 'primary',
    size = 'small',
  } = props;
  return (
    <div className="my_custom_button">
      {/* height:28 */}
      {size == 'small' && (
        <Button
          {...buttonProps}
          // style={{ height: 28, lineHeight: '15px', marginLeft: 12 }}
          type={type}
          className='small_btm'
        >
          {children}
        </Button>
      )}
      {/* height:32 */}
      {size == 'middle' && (
        <Button
          {...buttonProps}
          // style={{ height: 32, marginLeft: 12 }}
          className='middle_btn'
          type={type}
        >
          {children}
        </Button>
      )}
    </div>
  );
};

export default Index;
