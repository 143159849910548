import vsf from '@vs/vsf-boot';
import { Button, Drawer, Input, Radio, Tabs } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import { template } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Block from '@/module/Block';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import { Determine } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import OutHospitalTemplate from '@/pages/Index/components/order/out_patient_template/table';
import { convertOrderInTable } from '@/pages/Index/components/order/out_patient_template/table/convert';
import OutHospitalTemplateImport from '@/pages/Index/components/order/out_patient_template/table/template';
import OrderTable, {
  OrderTableRef,
} from '@/pages/Index/components/order_table';
import { getScrollY } from '@/utils';

import DefendTree from './DefendTree';

interface DefendImportProps {
  onChange?: (value) => void;
  agreedPrescriptionIndicator?: boolean;
  showRadio?: boolean;
}

const DefendImport = forwardRef((props: DefendImportProps, ref) => {
  const { onChange, agreedPrescriptionIndicator, showRadio } = props;

  const table = useRef<OrderTableRef>(null);
  const [open, setOpen] = useState<boolean>(false);
  const items = [
    {
      label: agreedPrescriptionIndicator ? '协定处方模板' : '门诊医嘱模板',
      key: '1',
    },
  ];
  const [template, setTemplate] = useState('PERSONAL_TEMPLATE');
  const [orderTemplate, setOrderTemplate] = useState<any>({});
  const [data, setData] = useState([]);
  const onOpen = useCallback(() => {
    setOpen(!open);
    setData([]);
  }, [open]);

  const [search] = useOnChangeValue('');

  useImperativeHandle(
    ref,
    () => {
      return {
        onOpen,
      };
    },
    [onOpen],
  );

  const onSelect = (value) => {
    setOrderTemplate(value);
  };

  useAsyncEffect(async () => {
    if (orderTemplate?.id) {
      const res =
        await vsf.services?.OrderTemplateItemController_getOrderTemplateItemDetailByTemplateId_6c1e5e?.(
          {
            templateId: orderTemplate?.id,
          },
        );
      const value = res?.data?.map((item) => {
        Object.keys(item).forEach((option) => {
          const replace = option.replace(/^orderTemplate(.*)Dto$/, (...arr) => {
            const [, name] = arr;
            return name?.toLocaleLowerCase() + 'Order';
          });
          item[replace] = item[option];
          if (Object?.keys(item[option] ?? {})?.length) {
            Object?.keys(item[option] ?? {})?.forEach((option) => {
              const title = option.replace(
                /^orderTemplate(.*)DetailDtoList$/,
                (...[, name]) => {
                  return `${name?.toLocaleLowerCase()}OrderDetail`;
                },
              );
              if (Array?.isArray(item[replace][option]))
                item[replace][title] = item[replace][option];
            });
          }
        });
        return item;
      });
      table?.current?.setValue(convertOrderInTable(value));
    }
  }, [orderTemplate?.id]);

  const onSubmit = () => {
    onChange?.(data);
    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const Footer = () => {
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 50,
        }}
      >
        <div className="aspirin-footer-len-box">
          已选: <span>{data?.length}</span>
        </div>
        <div className="aspirin-footer-button-box">
          <Button className="cancel" onClick={onClose}>
            取消
          </Button>
          <Button type="primary" onClick={onSubmit}>
            确定
          </Button>
        </div>
      </div>
    );
  };
  const tree = useRef({
    onSearch: (value: string) => void 0,
  });
  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      title={<Tabs items={items} type="card" defaultActiveKey="1" />}
      width="70%"
      closeIcon={null}
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      style={{
        overflow: 'hidden',
      }}
      className="aspirin-drawer-border-bottom"
      footer={<Footer />}
    >
      <div className="aspirin-medical-order-template-defend-box">
        <nav>
          <div className="aspirin-medical-order-template-defend-left-box">
            <Determine condition={Boolean(showRadio)}>
              <Radio.Group
                buttonStyle="solid"
                value={template}
                onChange={setTemplate}
                optionType="button"
                dataSource={[
                  {
                    value: 'PERSONAL_TEMPLATE',
                    label: '个人',
                  },
                  {
                    value: 'DEPARTMENT_TEMPLATE',
                    label: '科室',
                  },
                ]}
              />
            </Determine>
            <Input
              width="20rem"
              onChange={(event) =>
                tree?.current?.onSearch(event?.target?.value)
              }
              placeholder="搜索模板名称"
              prefix={<Icon type="icon-sousuo" />}
            />
          </div>
        </nav>
        <Container
          layout={{
            items: [
              {
                block: '20%',
                gap: '1.33rem',
              },
            ],
          }}
          style={{
            marginTop: '1.6rem',
          }}
        >
          <DefendTree
            template={template}
            onChange={onSelect}
            agreedPrescriptionIndicator={agreedPrescriptionIndicator}
            isEdit={false}
            search={search?.value}
            ref={tree}
          />
          <Block
            style={{
              background: '#F4F5F7',
              height: '100%',
            }}
          >
            <OutHospitalTemplateImport
              ref={table}
              rowSelection={{
                selectedRows: data,
                onSelectedRowsChange: (value) => {
                  setData(value as never[]);
                },
              }}
            />
          </Block>
        </Container>
      </div>
    </Drawer>
  );
});

DefendImport.displayName = 'DefendImport';

export default DefendImport;
