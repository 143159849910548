import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getInpAdmissionApplyWithPatientAndDepartmentDetailInfoVoById_b7f8b0:
    {
      method: 'post',
      url: '/api/clinic-inp-admission/get-inp-admission-apply-with-patient-and-department-detail-info-vo-by-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpAdmissionApplyWithPatientAndDepartmentDetailInfoVoClinicInpAdmissionEntranceWebVo,
      ) => data,
    },
});
