import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleController_queryInpSettleMasterDetail_0051ee: {
    method: 'post',
    url: '/api/inp-billing/query-inp-settle-master-detail',
    parameterFomatter: (data?: {
      qto: InpSettleMasterListQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InpSettleMasterVoInpBillingEntranceWebVoInpsettlemasterdetailvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  InpSettleController_preRefund_92acd9: {
    method: 'post',
    url: '/api/inp-billing/pre-refund',
    parameterFomatter: (data?: { inpSettleMasterId: number }) => data,
    responseFormatter: (_, __, data?: InpPreRefundVoInpBillingEntranceWebVo) =>
      data,
  },
  InpSettleController_refund_d561b5: {
    method: 'post',
    url: '/api/inp-billing/refund',
    parameterFomatter: (data?: {
      inpSettleMasterId: number;
      chargeInPrePaymentIndicator: boolean;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
