import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpAdtController_queryErpTransferCancelListVoByErpTransferCancelListQto_86148c:
    {
      method: 'post',
      url: '/api/clinic-erp-adt/query-erp-transfer-cancel-list-vo-by-erp-transfer-cancel-list-qto',
      parameterFomatter: (data?: {
        qto: ErpTransferCancelListQtoClinicErpAdtPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpTransferCancelListVoClinicErpAdtEntranceWebVo[],
      ) => data,
    },
});
