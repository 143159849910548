import { ButtonAndKeyDown } from '@/pages/Index/components';
import { getPromiseHeight, getScrollYRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Menu,
  Popover,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import {
  bedAndMiscBillDetailManualPricing,
  createBedAndMiscBillingDetailForBillingStation,
  createInpBillingDetailForBillingStation,
  createInpBillingDetailForBillingStation2,
  createOtherBillingDetailForBillingStation,
  getBedAndMiscBillingDetailByInpVisitId,
  getBedAndMiscBillingDetailGroupInfo,
  getInpBillingDetailByInpVisitId,
  getInpBillingDetailByOrderId,
  getOrderBillingInpBillingDetailGroupInfo,
  getOtherInpBillingDetail,
  patientBillingAuditForBillingStation,
  queryOrderInfoForNurseStation,
} from './services';
import { includes, isNumber, isObject, omitBy } from 'lodash';
import { CheckCircleTwoTone } from '@ant-design/icons';
import CommonTable from './CommonTable';
import TableModal from './TableModal';
import Tip from '@/pages/Index/components/func/Tip';
import { billingAttributeEnums, orderClassEnums } from './Data';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import classNames from 'classnames';

import '@/style/adaptation.less';

const TableMenu = (props) => {
  const { PatientDetails, setInquireUser, setPatientDetails } = props,
    [MailMsgProps, setMailMsgProps] = useState(),
    [TableModalProps, setTableModalProps] = useState({
      open: false,
    }),
    [selectedRows, setSelectedRows] = useState(),
    [MenuCurrent, setMenuCurrent] = useState('mail'),
    MenuItems = [
      {
        label: '医嘱费用',
        key: 'mail',
      },
      {
        label: '床位杂费',
        key: 'app',
      },
      {
        label: '其他费用',
        key: 'other',
      },
      {
        label: '费用明细',
        key: 'detail',
      },
    ];
  // 常量函数
  const onChanegMenu = async (e) => {
      setMenuCurrent(e.key);
      onRestState();
    },
    // 需要重置的
    onRestState = () => {
      setMailMsgProps();
    },
    onBtnDisabled = () => !PatientDetails?.inpVisitId,
    // 按钮
    onButton = (button) => {
      return (
        <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
          {button.map((item) => (
            <ButtonAndKeyDown key={item.text} {...item} type="primary">
              {item.text}
            </ButtonAndKeyDown>
          ))}
        </div>
      );
    },
    onChanegMenuTableReal = async () => {
      switch (MenuCurrent) {
        case 'mail':
          return vsf?.refs?.billingAuditOrderDetailList;
        case 'app':
          return await vsf?.refs?.billAuditBedAndMiscDetailList;
        case 'other':
          return await vsf?.refs?.billingAuditOtherBillingDetailList;
        case 'detail':
          return await vsf?.refs?.billAuditAllBillingDetailList;
      }
    }, //按钮disabled
    onBtnDltDis = () => {
      let isRowDis = false,
        i = 0;
      while (selectedRows?.length > i) {
        if (['Sava'].includes(selectedRows[i]?.TheRowType)) {
          isRowDis = true;
          break;
        }
        i++;
      }
      return isRowDis;
    },
    // 按钮props
    onButtonProps = {
      btnDetail: {
        disabled: onBtnDisabled(),
        text: '费用明细',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: () => setTableModalProps({ open: true }),
      },
      btnDelete: {
        ghost: true,
        disabled: onBtnDisabled() || onBtnDltDis(),
        text: '删除',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealSelectedRowValues;
          TableReal = await onChanegMenuTableReal();
          TableRealSelectedRowValues = await TableReal?.getSelectedRowValues();
          if (TableRealSelectedRowValues?.length <= 0)
            return message?.error('请选择可删除的费用');
          let i = 0,
            r = 0;
          while (TableRealSelectedRowValues?.length > i) {
            if (
              ['New', 'Temp'].includes(TableRealSelectedRowValues[i].TheRowType)
            ) {
              await TableReal?.remove(TableRealSelectedRowValues[i]);
              r++;
            }
            i++;
          }
          setSelectedRows([]);
          r > 0 && message?.success('删除成功');
        },
      },
      btnAdd: {
        disabled: onBtnDisabled(),
        text: '新增',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealValues;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.add({});
          TableRealValues = await TableReal?.getValues();
          TableReal?.scrollTo(
            TableRealValues?.[TableRealValues?.length - 1]?.id,
          );
        },
      },
      btnSava: {
        disabled: onBtnDisabled(),
        text: '保存',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.getEditForm()?.validateFieldsReturnFormatValue();
          const request = async (params) => {
            if (params.length <= 0)
              return message?.error('请新增费用或输入调整数量');
            switch (MenuCurrent) {
              case 'mail':
                return await createInpBillingDetailForBillingStation2?.(
                  params?.map((ele) => ({
                    ...ele,
                    orderId: MailMsgProps?.orderId,
                  })),
                );
              case 'app':
                return await createBedAndMiscBillingDetailForBillingStation?.(
                  params,
                );
              case 'other':
                return await createOtherBillingDetailForBillingStation?.(
                  params,
                );
              case 'detail':
                return await createInpBillingDetailForBillingStation?.(params);

              default:
                return {};
            }
          };
          const res = await request?.(
            TableReal?.getValues()
              ?.filter(
                (ele) =>
                  ['New', 'Temp'].includes(ele?.TheRowType) ||
                  isNumber(ele?.adjustAmount),
              )
              ?.map((ele) => ({
                ...ele,
                patientId: PatientDetails?.patientId,
                inpVisitId: PatientDetails?.inpVisitId,
              })),
          );
          if (res?.code !== 200) return;
          message?.success('保存成功');
          TableReal?.reload?.();
          !['detail', 'mail'].includes(MenuCurrent) &&
            setPatientDetails({ ...PatientDetails, reset: Math?.random() });
          ['mail'].includes(MenuCurrent) &&
            vsf?.refs?.billingExpenseDetailList?.reload();
        },
      },
    };
  const onResetInquireUser = () => {
    onChanegMenu({ key: 'mail' });
    return setInquireUser(Math?.random());
  };
  return (
    <div className="TableMenu">
      <Menu
        mode="horizontal"
        onClick={onChanegMenu}
        selectedKeys={[MenuCurrent]}
        items={MenuItems}
      />
      <div className="Components">
        <Components
          {...{
            ...props,
            MenuCurrent,
            onButtonProps,
            onButton,
            PatientDetails,
            selectedRows,
            setSelectedRows,
            TableModalProps,
            setTableModalProps,
            MailMsgProps,
            setMailMsgProps,
          }}
        />
        {onButton([
          {
            disabled: onBtnDisabled(),
            text: '划价',
            methodKey: 'altKey',
            ordinaryKey: '',
            onClick: async () => {
              Tip({
                title: '提示',
                content: '今日床位是否计价？',
                okText: '是',
                cancelText: '否',
                onOk: async () => {
                  const res = await bedAndMiscBillDetailManualPricing({
                    inpVisitId: PatientDetails?.inpVisitId,
                    includeToday: true,
                  });

                  if (!res) return;
                  message?.success('划价成功');
                  onResetInquireUser();
                },
                onCancel: async () => {
                  const res = await bedAndMiscBillDetailManualPricing({
                    inpVisitId: PatientDetails?.inpVisitId,
                    includeToday: false,
                  });

                  if (!res) return;
                  onResetInquireUser();
                },
              });
            },
          },
          {
            disabled: true,
            text: '审核',
            methodKey: 'altKey',
            ordinaryKey: '',
          },
          {
            disabled: onBtnDisabled(),
            text: '审核确认',
            methodKey: 'altKey',
            ordinaryKey: '',
            onClick: async () => {
              Tip({
                title: '提示',
                content: '是否通过审核？',
                okText: '通过',
                cancelText: '不通过',
                onOk: async () => {
                  const res = await patientBillingAuditForBillingStation({
                    inpVisitId: PatientDetails?.inpVisitId,
                    successIndicator: true,
                  });
                  if (!res) return;
                  message?.success('审核通过');
                  onResetInquireUser();
                },
                onCancel: async () => {
                  const res = await patientBillingAuditForBillingStation({
                    inpVisitId: PatientDetails?.inpVisitId,
                    successIndicator: false,
                  });

                  if (!res) return;
                  onResetInquireUser();
                },
              });
            },
          },
          {
            disabled: true,
            text: '关闭',
            methodKey: 'altKey',
            ordinaryKey: '',
          },
        ])}
      </div>
    </div>
  );
};
// 组件分叉
const Components = (props) => {
  const { MenuCurrent } = props;
  switch (MenuCurrent) {
    case 'mail':
      return <MailComponent {...props} />;
    case 'app':
      return <AppComponent {...props} />;
    case 'other':
      return <OtherComponent {...props} />;
    case 'detail':
      return <DetailComponent {...props} />;
    default:
      return;
  }
};
// 医嘱费用
const MailComponent = (props) => {
  return (
    <div className="MailComponent">
      <MailMsg {...{ ...props }} />
      <Expense {...{ ...props }} />
    </div>
  );
};
// 医嘱信息
const MailMsg = (props) => {
  const { PatientDetails, MailMsgProps, setMailMsgProps } = props,
    [yMaxHeight, setYMaxHeight] = useState(296),
    [totalData, setTotalData] = useState({}),
    [inquireSource, setInquireSource] = useState([]);

  getPromiseHeight('MailMsg')?.then((res) => {
    setYMaxHeight(res - 83);
  });
  const { data, run } = useRequest(
    async (params) => {
      if (!PatientDetails?.inpVisitId) return { data: [] };
      const { inpVisitId } = PatientDetails;
      const res = await queryOrderInfoForNurseStation({
        ...params,
        inpVisitId,
      });
      inquireSource?.length <= 0 &&
        setInquireSource(
          Array?.from(new Set(res?.data?.map((ele) => ele?.orderText)))?.map(
            (ele) => ({
              orderText: ele,
              orderId: ele,
            }),
          ),
        );
      setTotalData(res?.totalData);
      return { ...res, data: group?.onSliced(res?.data) };
    },
    { manual: true },
  );
  useEffect(async () => {
    await vsf?.refs?.billingAuditOrderInfoForm?.resetFields();
    run?.();
  }, [PatientDetails]);

  const inquire = async () => {
    setMailMsgProps();
    let inquireParams =
      await vsf?.refs?.billingAuditOrderInfoForm?.getFieldsValue();
    if (isObject(inquireParams?.orderTextLike))
      inquireParams.orderTextLike = inquireParams?.orderTextLike?.orderText;
    inquireParams = omitBy(inquireParams, (value) =>
      [undefined, null, NaN, ''].includes(value),
    );
    run?.(inquireParams);
  };

  // 处理成组
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip({ onOk, onCancel }) {},
  });
  return (
    <div className="MailMsg" id="MailMsg">
      <div className="title-container">
        <p className="title">
          医嘱信息
          <span> 正常计价：{totalData?.totalNormalBilling ?? ''}</span>
          <span> 手工计价：{totalData?.totalManualBilling ?? ''}</span>
          <span> 不计价：{totalData?.totalNotBilling ?? ''}</span>
        </p>
        <VSForm className="title_btn" id="billingAuditOrderInfoForm">
          <VSFormLayout columnCount={5}>
            <VSFormItem
              style={{ width: '238px' }}
              name="orderTextLike"
              fieldProps={{
                placeholder: '请选择医嘱正文',
                allowClear: true,
              }}
              valueType="selectCode"
              dataSource={inquireSource}
              fieldNames={{
                label: 'orderText',
                value: 'orderId',
              }}
            />
            <VSFormItem
              name="orderClass"
              fieldProps={{
                width: 136,
                placeholder: '医嘱类别',
                allowClear: true,
              }}
              valueType="selectCode"
              dataSource={orderClassEnums}
            />
            <VSFormItem
              name="repeatIndicator"
              fieldProps={{
                width: 136,
                placeholder: '医嘱长临',
                allowClear: true,
              }}
              valueType="selectCode"
              dataSource={[
                { label: '长期', value: true },
                { label: '临期', value: false },
              ]}
            />
            <VSFormItem
              name="billingAttribute"
              fieldProps={{
                width: 136,
                placeholder: '计价属性',
                allowClear: true,
              }}
              valueType="select"
              dataSource={billingAttributeEnums}
            />
            <ButtonAndKeyDown
              {...{
                children: '查询',
                methodKey: '',
                ordinaryKey: '',
                onClick: inquire,
                type: 'primary',
                disabled: !PatientDetails?.inpVisitId,
              }}
            />
          </VSFormLayout>
        </VSForm>
      </div>

      <VSTable
        vsid="67105"
        id="billingAuditOrderInfoList"
        scroll={{ y: yMaxHeight, x: 2269 }}
        // scroll={{ y: getScrollYRem(51), x: 2269 }}
        // scroll={{ y: getScrollYRem(53), x: 2269 }}
        className="common_table"
        value={data?.data}
        pagination={false}
        bordered
        rowClassName={(node) => {
          return node?.orderId === MailMsgProps?.orderId
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
            : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
        }}
        onRow={(node) => {
          return {
            onClick: () => {
              if (node?.orderId === MailMsgProps?.orderId)
                return setMailMsgProps();

              setMailMsgProps({
                ...node,
                orderIdList: [node?.orderId],
                // node?.groupIndex?.length
                //   ? node?.groupIndex?.map((ele) => {
                //       return data?.data?.[ele]?.orderId;
                //     })
                //   : [node?.orderId],
              });
            },
          };
        }}
      >
        <VSTableColumn
          width="526px"
          ellipsis
          dataIndex={['orderText']}
          title="医嘱正文"
          valueType="text"
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <Popover content={record?.orderText}>
                <div
                  className={classNames(
                    'text_ellipsis2',
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  {record?.orderText}
                </div>
              </Popover>
            );
          }}
        />

        <VSTableColumn
          width="126px"
          dataIndex={['dosage']}
          title="剂量"
          ellipsis
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          width="126px"
          dataIndex={['administration']}
          title="途径"
          ellipsis
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSTableColumn
          width="106px"
          ellipsis
          dataIndex={['frequency']}
          title="频次"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          width="56px"
          ellipsis
          dataIndex={['repeatIndicator']}
          title="长临"
          valueType="text"
          fieldProps={{}}
          render={(_, { repeatIndicator }) => (repeatIndicator ? '长' : '临')}
        />

        <VSTableColumn
          width="136px"
          ellipsis
          dataIndex={['performSchedulePlan']}
          title="执行计划"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          width="126px"
          ellipsis
          dataIndex={['billingAttribute']}
          title="计价"
          valueType="select"
          dataSource={billingAttributeEnums}
          fieldProps={{}}
        />

        <VSTableColumn
          width="126px"
          ellipsis
          dataIndex={['drugBillingAttribute']}
          title="摆药"
          valueType="select"
          dataSource={[
            { label: '正常', value: 'NORMAL' },
            { label: '自备药', value: 'SELF_PROVIDE' },
            { label: '不摆药', value: 'NOT_DISPENSE' },
            { label: '药柜摆药', value: 'CABINET_DISPENSE' },
            { label: '不计价', value: 'NOT_BILLING' },
          ]}
          fieldProps={{}}
          render={(dom, record) => {
            if (includes(['SELF_PROVIDE'], record?.drugBillingAttribute))
              return (
                <CheckCircleTwoTone
                  style={{
                    fontSize: '20px',
                  }}
                  twoToneColor="rgba(48, 163, 19, 1)"
                />
              );
            return dom;
          }}
        />

        <VSTableColumn
          dataIndex={['startPerformDateTime']}
          title="开始时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['lastPerformPlanDateTime']}
          title="最后执行时间"
          valueType="date"
          ellipsis
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['lastBillingDateTime']}
          title="最后计价时间"
          valueType="date"
          ellipsis
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['stopOrderDateTime']}
          title="结束时间"
          valueType="date"
          ellipsis
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSTable>
    </div>
  );
};
// 费用信息
const Expense = (props) => {
  const {
      onButtonProps,
      onButton,
      MailMsgProps,
      PatientDetails,
      selectedRows,
      setSelectedRows,
    } = props,
    { btnDetail } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(296);
  getPromiseHeight('Expense')?.then((res) => {
    setYMaxHeight(res - 78);
  });
  // const { data, run } = useRequest(
  // async (params) => {
  //   if (!PatientDetails?.inpVisitId || !MailMsgProps?.id) return { data: [] };
  //   const res = await getOrderBillingInpBillingDetailGroupInfo({
  //     inpVisitId: PatientDetails?.inpVisitId,
  //     orderIdList: MailMsgProps?.orderIdList,
  //   });
  //   return res;
  // },
  //   { manual: true },
  // );
  // useEffect(() => {
  //   run?.();
  // }, [PatientDetails, MailMsgProps]);
  const onTabelFetch = useCallback(
    async (params) => {
      if (!PatientDetails?.inpVisitId || !MailMsgProps?.id) return { data: [] };
      const res = await getOrderBillingInpBillingDetailGroupInfo({
        inpVisitId: PatientDetails?.inpVisitId,
        orderIdList: MailMsgProps?.orderIdList,
      });
      return res;
    },
    [PatientDetails, MailMsgProps],
  );
  const onFetch = useCallback(
    async (params) => {
      if (!PatientDetails) return [];
      const res = await getInpBillingDetailByOrderId({
        inpVisitId: PatientDetails?.inpVisitId,
        orderIdList: MailMsgProps?.orderIdList,
        orderId: MailMsgProps?.orderId,
      });
      return res;
    },
    [PatientDetails, MailMsgProps],
  );

  const [TableModalProps, setTableModalProps] = useState({
    open: false,
  });
  return (
    <div className="Expense" id="Expense">
      <TableModal
        {...{
          setTableModalProps,
          TableModalProps,
          onFetch,
          onButtonProps,
          onButton,
          selectedRows,
          setSelectedRows,
          table__id: 'billingAuditOrderDetailList',
          applyDepartment: MailMsgProps?.orderDepartment,
        }}
      />
      <div className="title-container">
        <p className="title">费用信息</p>
        {onButton([
          {
            ...btnDetail,
            disabled: !MailMsgProps?.id,
            onClick: () => {
              setTableModalProps({ open: true, title: '费用信息' });
            },
          },
        ])}
      </div>

      <VSPromiseTable
        id="billingExpenseDetailList"
        scroll={{ y: getScrollYRem(53) }}
        className="common_table"
        onFetch={onTabelFetch}
        pagination={false}
        bordered
      >
        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          formItemProps={{}}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          width={69}
          dataIndex={['unit']}
          title="单位"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['totalAmount']}
          title="实际计价总数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </div>
  );
};
// 床位杂费
const AppComponent = (props) => {
  const {
      onButtonProps,
      onButton,
      PatientDetails,
      selectedRows,
      setSelectedRows,
    } = props,
    { btnDetail } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(296);
  getPromiseHeight('AppComponent')?.then((res) => {
    setYMaxHeight(res - 88);
  });

  const { data, run } = useRequest(
    async (params) => {
      if (!PatientDetails) return [];
      const res = getBedAndMiscBillingDetailGroupInfo({
        inpVisitId: PatientDetails?.inpVisitId,
      });
      return res;
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (!PatientDetails) return;
    run?.();
  }, [PatientDetails]);

  const onFetch = useCallback(
    async (params) => {
      if (!PatientDetails) return [];
      const res = await getBedAndMiscBillingDetailByInpVisitId({
        inpVisitId: PatientDetails?.inpVisitId,
      });
      return res;
    },
    [PatientDetails],
  );
  const [TableModalProps, setTableModalProps] = useState({ open: false });
  return (
    <div className="AppComponent" id="AppComponent">
      <TableModal
        {...{
          TableModalProps,
          setTableModalProps,
          onFetch,
          onButtonProps,
          onButton,
          selectedRows,
          setSelectedRows,
          table__id: 'billAuditBedAndMiscDetailList',
          applyDepartment: PatientDetails?.department,
        }}
      />
      <div className="title-container">
        <p className="title">床位杂费</p>
        {onButton([
          {
            ...btnDetail,
            disabled: !PatientDetails?.inpVisitId,
            onClick: () => {
              setTableModalProps({
                open: true,
                title: '床位杂费',
              });
            },
          },
        ])}
      </div>
      <VSTable
        vsid="20288"
        id="billingAuditBedAndMiscDetailGroupList"
        scroll={{ y: yMaxHeight }}
        // scroll={{ y: getScrollYRem(32) }}
        className="common_table"
        value={data?.data}
        // value={[
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        //   {
        //     bedLabel: 1,
        //   },
        // ]}
        pagination={false}
        bordered
      >
        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['startDate']}
          title="开始日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['endDate']}
          title="结束日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          formItemProps={{}}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['totalAmount']}
          title="计价数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSTable>
    </div>
  );
};
// 其他费用
const OtherComponent = (props) => {
  const {
      onButtonProps,
      onButton,
      PatientDetails,
      selectedRows,
      setSelectedRows,
    } = props,
    { btnDetail } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(296);

  getPromiseHeight('OtherComponent')?.then((res) => {
    setYMaxHeight(res - 78);
  });
  const { data, run } = useRequest(
    async (params) => {
      if (!PatientDetails) return [];
      const res =
        await vsf?.services?.InpBillingDetailController_getOtherInpBillingDetailGroupInfo_d3c3df?.(
          { inpVisitId: PatientDetails?.inpVisitId },
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (!PatientDetails) return;
    run?.();
  }, [PatientDetails]);
  const onFetch = useCallback(
    async (params) => {
      if (!PatientDetails) return [];
      const res = await getOtherInpBillingDetail({
        inpVisitId: PatientDetails?.inpVisitId,
      });
      return res;
    },
    [PatientDetails],
  );
  const [TableModalProps, setTableModalProps] = useState({ open: false });
  return (
    <div className="OtherComponent" id="OtherComponent">
      <TableModal
        {...{
          TableModalProps,
          setTableModalProps,
          onFetch,
          onButtonProps,
          onButton,
          yMaxHeight,
          selectedRows,
          setSelectedRows,
          table__id: 'billingAuditOtherBillingDetailList',
          applyDepartment: PatientDetails?.department,
        }}
      />
      <div className="title-container">
        <p className="title">其他费用</p>
        {onButton([
          {
            ...btnDetail,
            disabled: !PatientDetails?.inpVisitId,
            onClick: () => {
              setTableModalProps({
                open: true,
                title: '其他费用',
              });
            },
          },
        ])}
      </div>

      <VSTable
        vsid="43636"
        id="billingAuditOtherBillingDetailGroupList"
        scroll={{ y: getScrollYRem(32) }}
        className="common_table"
        value={data?.data}
        // value={[
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        //   {
        //     itemClass: 1,
        //   },
        // ]}
        pagination={false}
        bordered
      >
        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums.enums.BILL_ITEM_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="项目规格"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['totalAmount']}
          title="计价数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSTable>
    </div>
  );
};
// 费用明细
const DetailComponent = (props) => {
  const {
      onButtonProps,
      onButton,
      PatientDetails,
      selectedRows,
      setSelectedRows,
    } = props,
    { btnDetail } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(296);

  getPromiseHeight('DetailComponent')?.then((res) => {
    setYMaxHeight(res - 108);
  });

  const onFetch = useCallback(async () => {
    if (!PatientDetails) return [];
    const res = await getInpBillingDetailByInpVisitId({
      inpVisitId: PatientDetails?.inpVisitId,
    });
    return res;
  }, [PatientDetails]);
  return (
    <div className="DetailComponent" id="DetailComponent">
      <CommonTable
        {...{
          yMaxHeight,
          table__id: 'billAuditAllBillingDetailList',
          onButtonProps,
          PatientDetails,
          onButton,
          selectedRows,
          setSelectedRows,
          onFetch,
          applyDepartment: PatientDetails?.department,
        }}
      />
    </div>
  );
};

export default definePage(TableMenu);
