import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
  message,
  Badge,
  Tooltip,
} from '@vs/vsf-kit';
import {
  drugStaffList,
  getDetailById,
  getTableData,
  unUseDispenseWindowNo,
  useDispenseWindowNo,
  windowDetail,
  getRefundState,
} from './service';
import { useMount, useRequest } from 'ahooks';
import { debounce, isObject } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { definePage } from '@vs/vsf-boot';
import { getScrollY } from '@/utils';
import Tip from '@/pages/Index/components/func/Tip';
import vsf from '@vs/vsf-boot';

import { toDispenseWindowList } from '../pharmacyDispensesMedicine/Service';
import DrugInfo from './drug_info';
import UserInfo from './UserInfo';

const InfoAndList = ({ tabaleParam, callback, qureyList, ...props }) => {
  const { staffInfo } = props?.stores?.user;
  useEffect(() => onSetPrepareDrugPeopleForm(), [staffInfo]);
  const onSetPrepareDrugPeopleForm = () => {
    vsf?.refs?.centreMedicineDispensingForm?.setFieldsValue?.({
      prepareDrugStaffId: staffInfo?.id,
      secondPrepareDrugStaffId: staffInfo?.id,
    });
  };
  const [activityRow, setActivityRow] = useState();
  const [transfer, setTransfer] = useState();

  const { data: detailData, run } = useRequest(getDetailById, {
    manual: true,
  });
  const { data: staffList } = useRequest(drugStaffList);

  const { data: pageDetail, run: pageDetailRun } = useRequest(windowDetail, {
    manual: true,
  });
  // toDispenseWindowList
  const { data: onLineData } = useRequest(toDispenseWindowList);

  const { data: refundState, run: refundStateRun } = useRequest(
    getRefundState,
    {
      manual: true,
    },
  );
  //获取queryDayIs天数,不要删除这个接口
  const { data: sysData } = useRequest(async () => {
    const res =
      await vsf?.services?.SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8(
        {
          qto: {},
        },
      );
    const file = res?.data?.find((item) => {
      return item?.name === 'QUERY_WAIT_DISPENSE_DAY';
    });
    vsf?.refs?.drugSelectForm?.setFieldsValue({
      queryDayIs: file?.systemValue,
    });
    return res?.data;
  });
  useEffect(() => {
    const file =
      vsf?.refs?.centreDispensesMedicineSearchForm?.getFieldsValue(true);
    run({
      id: tabaleParam?.outpVisitId,
      queryDay: Number(file?.queryDayIs ?? 1),
    });
    refundStateRun({
      id: tabaleParam?.id,
    });
  }, [tabaleParam]);

  useMount(() => {
    pageDetailRun();
  });

  const onTableFetch = useCallback(async () => {
    return getTableData({ id: tabaleParam?.id });
  }, [tabaleParam]);

  const handelConfirmDistribute = async (params) => {
    const tableData = vsf?.refs?.centreMedicineDispensingListTable?.getValues();
    console.log(tableData);
    if (tableData?.length <= 0) return message?.warning('暂无选择的处方药品！');
    await vsf?.refs?.PrepareDrugPeopleForm?.validateFieldsReturnFormatValue();
    Tip({
      content: '是否确认发药',
      onOk: async () => {
        var input =
          await vsf.refs?.centreMedicineDispensingForm?.getFieldsValue();
        const param = {};
        param.waitDispenseMasterId = tabaleParam?.id; // Tips: 以下是你选择的请求函数
        param.prepareDrugStaffId = isObject(input?.prepareDrugStaffId)
          ? input?.prepareDrugStaffId?.secondPrepareDrugStaffId
          : input?.prepareDrugStaffId;
        param.secondPrepareDrugStaffId = isObject(
          input?.secondPrepareDrugStaffId,
        )
          ? input?.secondPrepareDrugStaffId?.secondPrepareDrugStaffId
          : input?.secondPrepareDrugStaffId;
        const res =
          await vsf.services?.DrugPrescriptionDispenseController_herbDispensingPrescriptions_c0fd95?.(
            {
              dispensingParam: param,
            },
          );
        if (res?.code === 200) {
          message.success('发药成功');
        }
        qureyList();
        vsf?.refs?.centreMedicineDispensingListTable?.reload();
        onSetPrepareDrugPeopleForm();
      },
    });
  };
  // debounce(, 1000);

  const modalConfirm = async (param) => {
    if (!pageDetail?.useStatus) {
      const res = await useDispenseWindowNo?.({
        bto: {
          id: pageDetail?.id,
          toDispenseWindowId: transfer,
          useStaffId: pageDetail?.useStaffId,
        },
      });

      if (res?.code === 200) {
        message.success('窗口联机成功');
        callback();
        pageDetailRun();
        vsf?.closeModal('centreMedicineDispensingForm');
      }
    } else {
      //窗口签退
      const res = await unUseDispenseWindowNo?.({
        bto: {
          id: pageDetail?.id,
          useStaffId: pageDetail?.useStaffId,
          toDispenseWindowId: transfer,
        },
      });
      if (res?.code === 200) {
        message.success('窗口签退成功');
        callback();
        pageDetailRun();
        vsf?.closeModal('centreMedicineDispensingForm');
      }
    }
  };

  return (
    <>
      <div className="info">
        <div className="patient_info">
          <Badge.Ribbon
            color={refundState?.data && 'red'}
            text={refundState?.data && '该患者有退费'}
            style={!refundState?.data && { visibility: 'hidden' }}
          >
            <div>
              <Row style={{ flex: '1' }} justify={'space-between'}>
                窗口状态:
              </Row>
              <UserInfo {...props} outpVisitId={tabaleParam?.outpVisitId} />
            </div>
          </Badge.Ribbon>
        </div>
        <div className="split_line" />
        <DrugInfo id={tabaleParam?.id} />
      </div>
      <div className="list">
        <VSPromiseTable
          vsid="99845"
          scroll={{
            x: 1200,
            y: getScrollY(450),
          }}
          id="centreMedicineDispensingListTable"
          onFetch={onTableFetch}
          className="aspirin-table row_font_size"
          rowClassName="row_font_size"
          rowClick={{
            rowClickType: {
              action: 'click',
            },
            onRowClick: (record) => {
              setActivityRow(record);
            },
          }}
          // rowClassName={(record) =>
          //   record?.id === activityRow?.id ? 'activity_row_class' : ''
          // }
          pagination={false}
        >
          <VSTableColumn
            width={282}
            dataIndex={['drugProductName']}
            title="名称"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              ],
            }}
            fieldProps={{}}
            render={(_, values) => {
              if (!values?.supplyIndicator) {
                return (
                  <div
                    className="text-overflow-omit"
                    style={{ color: '#E34A4E' }}
                  >
                    <Tooltip title={values.drugProductName}>
                      {values.drugProductName}
                    </Tooltip>
                  </div>
                );
              } else {
                return (
                  <div className="text-overflow-omit">
                    <Tooltip title={values.drugProductName}>
                      {values.drugProductName}
                    </Tooltip>
                  </div>
                );
              }
            }}
          />

          <VSTableColumn
            width={160}
            dataIndex={['amount']}
            title="单贴量"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '数量的值不合法',
                  type: 'number',
                  min: 0,
                  max: undefined,
                },
              ],
            }}
            fieldProps={{}}
            render={(_, value) => {
              return (
                <>
                  {value?.amount + value?.amountPackageUnit.replace(/\d/g, '')}
                </>
              );
            }}
          />

          <VSTableColumn
            width={120}
            dataIndex={['medicalInsuranceType']}
            title="医保"
            valueType="select"
            dataSource={[
              { label: '甲', value: 'ALPHA' },
              { label: '乙', value: 'BETA' },
              { label: '丙', value: 'GAMMA' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            width={160}
            dataIndex={['amountPackageUnit']}
            title="给药数量"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            width={160}
            align="right"
            dataIndex={['price']}
            title="单价"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return <div>{Number(value?.price || 0).toFixed(2)}</div>;
            }}
          />

          <VSTableColumn
            width={160}
            align="right"
            dataIndex={['charge']}
            title="金额"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            width={220}
            dataIndex={['useDescription']}
            title="处理方法"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_DECOCTION_DICT}
          />
        </VSPromiseTable>
        <div className="footer">
          <VSForm vsid="84627" id="centreMedicineDispensingForm">
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['prepareDrugStaffId']}
                label="配药人1"
                valueType="select"
                rules={[]}
                dataSource={staffList}
                fieldProps={{
                  width: 160,
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'secondPrepareDrugStaffId',
                }}
              />

              <VSFormItem
                name={['secondPrepareDrugStaffId']}
                label="配药人2"
                valueType="select"
                rules={[]}
                dataSource={staffList}
                fieldProps={{
                  width: 160,
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'secondPrepareDrugStaffId',
                }}
              />
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={0}></VSFormLayout>
          </VSForm>
          <div>
            <Space size={4}>
              <Button
                type="primary"
                onClick={() => {
                  vsf?.openModal('centreMedicineDispensingForm');
                }}
              >
                窗口联机/签退
              </Button>
              <Button type="primary"> 打印</Button>
              <Button type="primary">问题反馈</Button>
              <Button type="primary" onClick={handelConfirmDistribute}>
                发药确定
              </Button>
            </Space>
          </div>
        </div>
      </div>

      <Modal
        id="centreMedicineDispensingForm"
        title={<div className="ant-modal-dispense-title">窗口设置(中药房)</div>}
        onCancel={() => {
          vsf?.closeModal('centreMedicineDispensingForm');
        }}
        footer={
          <Space size={4}>
            <Button
              onClick={() => {
                vsf?.closeModal('centreMedicineDispensingForm');
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              disabled={!pageDetail?.useStatus}
              onClick={modalConfirm}
            >
              窗口签退
            </Button>
            <Button
              type="primary"
              disabled={pageDetail?.useStatus}
              onClick={modalConfirm}
            >
              窗口联机
            </Button>
          </Space>
        }
      >
        <Row>
          <Col span={6} className="modal-dispense-font">
            药房：
          </Col>
          <Col span={6} className="modal-dispense-font-content">
            {pageDetail?.dispenseDrugStorageInfo?.storageName}
          </Col>
        </Row>
        <Row>
          <Col span={6} className="modal-dispense-font">
            当前窗口：
          </Col>
          <Col span={6} className="modal-dispense-font-content">
            {pageDetail?.windowDescription}
          </Col>
        </Row>
        <Row>
          <Col span={6} className="modal-dispense-font">
            联机状态：
          </Col>
          <Col span={6} className="modal-dispense-font-content">
            {pageDetail?.useStatusName || '离线'}
          </Col>
        </Row>
        {pageDetail?.useStatus && (
          <Row>
            <Col span={6} className="modal-dispense-font">
              处方转移:
            </Col>
            <Col span={12}>
              <Select
                width={200}
                dataSource={onLineData}
                fieldNames={{
                  label: 'windowDescription',
                  value: 'toDispenseWindowId',
                }}
                onSelect={(v) => {
                  setTransfer(v?.value);
                }}
              />
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default definePage(InfoAndList);
