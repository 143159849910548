import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getAvailableByClinicInpAdmissionQto_405065: {
    method: 'post',
    url: '/api/clinic-inp-admission/get-available-by-clinic-inp-admission-qto',
    parameterFomatter: (data?: {
      qto: ClinicInpAdmissionQtoClinicInpAdmissionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicInpAdmissionShowVoClinicInpAdmissionEntranceWebVo,
    ) => data,
  },
  ClinicInpAdmissionController_saveClinicInpAdmission_b04761: {
    method: 'post',
    url: '/api/clinic-inp-admission/save-clinic-inp-admission',
    parameterFomatter: (data?: {
      clinicInpAdmissionShowVo: ClinicInpAdmissionShowVoClinicInpAdmissionEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
});
