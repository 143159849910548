import vsf from '@vs/vsf-boot';
import { Form, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { Fragment, useCallback, useState } from 'react';

import ComplexSelect, {
  useComplexSelect,
} from '@/pages/Index/components/complex_select';
import { useInputEnter } from '@/pages/Index/components/usage/utils';
import { askTotalAmount } from '@/pages/Index/components/func/ask';
// import { useInputEnter } from '../Dosage/utils';
const TableRender = (props) => {
  const { onFetch, ...rest } = props;
  const columns = [
    {
      title: '序号',
      dataIndex: ['index'],
      width: 50,
    },
    {
      title: '项目名称',
      dataIndex: ['itemName'],
      ellipsis: true,
    },
    {
      title: '规格',
      dataIndex: ['itemSpecification'],
      ellipsis: true,
    },
    {
      title: '费用类别',
      dataIndex: ['itemClass'],
      dataSource: vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT,
      valueType: 'select',
      ellipsis: true,
    },
    {
      title: '单价',
      dataIndex: ['price'],
      ellipsis: true,
    },
    {
      title: '品牌',
      dataIndex: ['brand'],
      ellipsis: true,
    },
    {
      title: '备注',
      dataIndex: ['memo'],
      ellipsis: true,
    },
  ];

  const style = {
    fontSize: 14,
    color: '#aeb0b3',
    marginBottom: 10,
  };

  const method = useComplexSelect?.();
  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);

  return (
    <Fragment>
      <div style={style}>输入序号选择项目，键盘“← →”翻页</div>
      <VSPromiseTable
        onFetch={onFetch}
        id="TableRender"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 8,
          showQuickJumper: false,
          showSizeChanger: false,
        }}
        rowKey="key"
        rowClick={{
          rowClickType: 'select',
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          onSelectedRowsChange: (value) => {
            const [state] = value;
            rest?.rowSelection?.onChange(void 0, [
              {
                ...state,
              },
            ]);
          },
        }}
        size="small"
      >
        {columns?.map((item, index) => {
          return <VSTableColumn key={index} {...item} />;
        })}
      </VSPromiseTable>
    </Fragment>
  );
};

const ProjectNameSelect = (props) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    dataSource,
    form,
    record,
    recordKey,
    table_id,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const handleOnChange = async (value) => {
    const values = {
      ...record,
      id: recordKey, // 主键
      unit: value?.unit,
      insuranceCode: value?.insuranceNationCode, // 医保编码
      itemClass: value?.billingItem, // 价表项目分类
      itemName: {
        label: value?.itemName, // 项目名称
        value: value?.itemId, // 项目编码
      },
      itemId: value?.itemId,
      itemSpecification: value?.itemSpecification, // 项目规格
      unit: value?.unit, // 单位
      // memo: value?.memo, // 备注
      medicalInsuranceType: value?.insuranceItemCode, //InsuranceChargeTypeEnum, // 医保项目类别
      price: value?.price, // 项目单价
      cost: askTotalAmount([[1, value?.price]], 2),
      amount: 1,
    };
    form?.setFieldValue(recordKey, values);
    vsf?.refs?.[table_id]?.update(values);
    onChange?.({
      label: value?.itemName,
      value: value?.itemId,
    });
  };

  const [search, setSearch] = useState({
    inputCodeLike: '',
  });
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 999999,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              inputCodeLike: search?.inputCodeLike,
            },
            isIncludeDrugOrHerb: false,
          },
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        return {
          data: res?.data?.result?.map((item, index) => ({
            ...item,
            index: index + 1,
            key: index + 1,
          })),
          total: res?.data?.count,
        };
      }
    },
    [search?.inputCodeLike],
  );
  const onSearch = async (value) => {
    await vsf?.refs?.TableRender?.setCurrentPage(1);
    setSearch({
      inputCodeLike: value,
    });
  };
  return (
    <ComplexSelect
      onChange={handleOnChange}
      onSearch={onSearch}
      value={value}
      showLabel="itemName"
      mode="radio"
      dropdownMatchSelectWidth={900}
      dropdownStyle={{}}
      listHeight={500}
      hideSelectIcon
      keyDownDeploy={{
        ArrowLeft: () => {
          vsf?.refs?.TableRender?.gotoPreviousPage();
        },
        ArrowRight: () => {
          vsf?.refs?.TableRender?.gotoNextPage();
        },
      }}
    >
      <TableRender onFetch={onFetch} />
    </ComplexSelect>
  );
};
export default ProjectNameSelect;
