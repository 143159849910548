import { createServices } from '@vs/vsf-boot';
export default createServices({
  NurseBillingController_getWardPatientOrderInfo_a2d811: {
    method: 'post',
    url: '/api/clinic-record-order/nurse/get-ward-patient-order-info',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrderInfoForNurseVoClinicRecordOrderEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
