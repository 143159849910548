import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamClassDictionaryBOController_stopExamClass_d5da55: {
    method: 'post',
    url: '/api/exam/stop-exam-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamClassDictionaryDtoController_getAllByExamClassQto_3b08d4: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-qto',
    parameterFomatter: (data?: { qto: ExamClassQtoExamPersistQto }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
});
