import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getRegisterDefineById_e6faa3: {
    method: 'post',
    url: '/api/outp-register-define/get-clinic-register-define-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-type-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterTypeDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-outp-special-clinic-dictionary-qto',
    parameterFomatter: (data?: {
      qto: OutpSpecialClinicDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpSpecialClinicDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-platform-dictionary-qto',
    parameterFomatter: (data?: {
      qto: PlatformDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlatformDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentQto_1c2a91: {
    method: 'post',
    url: '/api/organization/get-all-by-department-qto',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  UserStaffController_getDoctorStaffListOfInstitution_ae1040: {
    method: 'post',
    url: '/api/user-staff/get-doctor-staff-list-of-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo[]) =>
      data,
  },
});
