import {
  compose,
  Input,
  Select,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

export type SelectValue = {
  /** 自定义类型字段 */
  prefix: string;
  selectValue: string;
  suffix: string;
  data: any;
};
import './index.less';
export type AspirinSelectInputProps = {
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
  /**
   * 值变化回调
   */
  onChange?: (value?: any) => void;
  selectprops: any;
  attributeName: string;
  style: any;
};

/**
 * 诊断手术控件
 */
const AspirinSelectInput = (props: AspirinSelectInputProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    selectprops,
    attributeName,
    style,
  } = props;
  // console.log(props, 'propsprops');

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const [isEdit, setIEdit] = useState(true);

  // true Select, false Input
  const [open, setOpen] = useState<any>(true);

  // 监听光标位置
  const inputElement: any = document.getElementById('myInput');
  document.addEventListener('selectionchange', function () {
    const cursorPositionStart = inputElement?.selectionStart;
    const cursorPositionEnd = inputElement?.selectionEnd;

    // const { prefix, selectValue, suffix } = value;
    const prefixLength = value?.prefix?.length;
    const selectValueLength = value?.selectValue?.length;
    const suffixLength = value?.suffix?.length;
    const activeElement = document.activeElement;

    if (
      cursorPositionStart <= prefixLength ||
      cursorPositionStart >= prefixLength + selectValueLength
    ) {
      setIEdit(true);
    } else {
      setIEdit(false);
    }
    // if (activeElement === inputElement) {
    //   // 光标位置发生改变的处理逻辑
    //   const cursorPosition = inputElement?.selectionStart;
    //   // console.log(111);
    // }
  });

  useEffect(() => {
    handleOpen(!value?.selectValue || value?.selectValue?.length <= 0);
  }, [value?.selectValue]);

  const handleOpen = (v) => {
    setOpen(v);
  };

  const handelChange = (v) => {
    if (!v) return handleOpen(true);

    setValue({
      ...value,
      ...v,
      prefix: '',
      selectValue: v?.[attributeName],
      suffix: '',
    });
    handleOpen(false);
    onChange?.({
      ...value,
      ...v,
      prefix: '',
      selectValue: v?.[attributeName],
      suffix: '',
    });
  };

  /** 编写组件逻辑 */
  return (
    <div style={{ ...style }}>
      {open ? (
        <Select
          {...props}
          onChange={handelChange}
          allowClear
          value={
            !value?.selectValue
              ? ''
              : (value?.prefix ? value?.prefix : '') +
                value?.selectValue +
                (value?.suffix ? value?.suffix : '')
          }
        ></Select>
      ) : (
        <Input
          style={{ imeMode: 'disabled' }}
          id="myInput"
          data-id="1"
          autoComplete="off"
          allowClear
          defaultValue={
            (value?.prefix ? value?.prefix : '') +
            value?.selectValue +
            (value?.suffix ? value?.suffix : '')
          }
          value={
            (value?.prefix ? value?.prefix : '') +
            value?.selectValue +
            (value?.suffix ? value?.suffix : '')
          }
          // onClick={(v: any) => {
          //   const { prefix, selectValue, suffix } = value;
          //   const selectionStart = v.target.selectionStart;
          //   const selectionEnd = v.target.selectionEnd;

          //   if (
          //     selectionStart > prefix?.length + 1 ||
          //     selectionStart < prefix?.length + selectValue?.length - 1
          //   ) {
          //     const inputElement: any = document.getElementById('myInput');
          //     inputElement.setSelectionRange(0, 0);
          //   }
          // }}
          onChange={(v: any) => {
            console.log(v, value);

            const _value = v?.target?.value;
            if (!_value || _value?.length <= 0) {
              setValue(null);
              setOpen(false);
              onChange?.({ rowId: value?.rowId });
              return;
            }
            const position = v?.target?.selectionStart;
            const textLength = _value?.length;
            const res = textLength - position;
            let positions = -1;

            if (res <= value?.suffix?.length) {
              positions = 1;
            } else if (
              res <
              value?.suffix?.length + value?.selectValue?.length
            ) {
              positions = 0;
            } else {
              positions = -1;
            }

            if (positions == 0) {
              setValue({
                ...value,
                prefix: value?.prefix,
                selectValue: value?.selectValue,
                suffix: value?.suffix,
              });
              onChange?.({
                ...value,
                prefix: value?.prefix,
                selectValue: value?.selectValue,
                suffix: value?.suffix,
              });
            } else if (positions == 1) {
              setValue({
                ...value,
                prefix: value?.prefix,
                selectValue: value?.selectValue,
                suffix: _value?.substring(
                  value?.prefix?.length + value?.selectValue?.length,
                ),
              });
              onChange?.({
                ...value,
                prefix: value?.prefix,
                selectValue: value?.selectValue,
                suffix: _value?.substring(
                  value?.prefix?.length + value?.selectValue?.length,
                ),
              });
            } else {
              setValue({
                ...value,
                prefix: _value?.substring(
                  0,
                  textLength -
                    (value?.suffix?.length + value?.selectValue?.length),
                ),
                selectValue: value?.selectValue,
                suffix: value?.suffix,
              });
              onChange?.({
                ...value,
                prefix: _value?.substring(
                  0,
                  textLength -
                    (value?.suffix?.length + value?.selectValue?.length),
                ),
                selectValue: value?.selectValue,
                suffix: value?.suffix,
              });
            }
          }}
        />
      )}
    </div>
  );
};
AspirinSelectInput.displayName = 'AspirinSelectInput';

export default compose(
  withField<AspirinSelectInputProps>({
    name: 'AspirinSelectInput',
  }),
  withPreview<AspirinSelectInputProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return (
        <>
          {!value?.selectValue
            ? ''
            : (value?.prefix ? value?.prefix : '') +
              value?.selectValue +
              (value?.suffix ? value?.suffix : '')}
        </>
      );
    },
  }),
)(AspirinSelectInput);
