import React from 'react';
import { Button, Input } from '@vs/vsf-kit';

export default function checkFooter(props) {
  const { onCancel, onSore, disabledFlag } = props;

  return (
    <div className="footer">
      <div className="footerInput">
        <p className="tip_icon">*</p>
        <p className="tip_title">回退原因</p>
        <p className="tip_text">请在上方列表勾选要退回的项目,并填写退回原因</p>
        <Input
          className="width560"
          type="text"
          placeholder="退回原因"
          id="refundReason"
        ></Input>
      </div>
      <div className="footerBtn">
        <Button className="btn" onClick={() => onCancel()}>
          清空
        </Button>
        <Button
          className="btn"
          type="primary"
          shape="default"
          size="medium"
          onClick={() => onSore()}
          disabled={disabledFlag}
        >
          确认回退
        </Button>
      </div>
    </div>
  );
}
