import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27: {
    method: 'post',
    url: '/api/base-dictionary/query-all-by-base-code-index-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeIndexQtoBaseDictionaryPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeVoBaseDictionaryEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
