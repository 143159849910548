import askFixed from './askFixed';

/**
 * 计算总价
 * @param priceList [[金额,数量]]
 * @param accuracy 保留多少位小数点,默认 2
 * @returns 总价钱
 */
const askTotalAmount = (priceList: number[][], accuracy = 2) => {
  const total = priceList?.map((num: number[]) => {
    const [price, amount] = num;
    const count = Number(price ?? 0) * Number(amount ?? 0);
    return count;
  });
  return askFixed(
    total?.reduce((accumulator: number, currentValue: number) => {
      return accumulator + currentValue;
    }, 0),
    accuracy,
  );
};

export default askTotalAmount;
