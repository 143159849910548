import { useSetState } from 'ahooks';
import React, {
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type option = {
  memo: {
    [key: string]: [() => void, React.DependencyList];
  };
};

const Soften = forwardRef((props: any, ref) => {
  const { children } = props;
  const [state, set] = useSetState({});
  useImperativeHandle(ref, () => ({
    set,
  }));
  return <div>{children?.(state)}</div>;
});

Soften.displayName = 'Soften';

export default function useSoften() {
  const ref = useRef(null);
  return {
    Soften,
    ref,
  };
}
