import { useGetState } from 'ahooks';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Section, Todo, Tree, VSControlledForm, VSFormItem } from '@vs/vsf-kit';
import vsf, { definePage } from '@vs/vsf-boot';

import Transfer from './transfer';

const StandardApplicationFuncitonTransfer = (props) => {
  const { application, value } = props;
  const [roleList, setRoleList] = useState([]);
  const [roleListStatus, setRoleListStatus] = useState(false);
  const [detail, setDetail] = useState();

  const transferValues = useRef();
  const getRoleList = async (id) => {
    const res =
      await vsf?.services?.ApplicationDtoController_getApplicationWithRoleById_cb1f5b(
        {
          id,
        },
      );
    setRoleList(res?.data.roleList || []);
    setRoleListStatus(true);
  };

  const formatData = (data) => {
    const {
      name,
      applicationFunctionVsGroupWithFunctionDtoList: functionList,
    } = data;
    const res = {
      ...data,
      name,
      applicationFunctionVsGroupWithFunctionDtoList: (functionList || [])?.map(
        (item) => {
          const { function: functionData, ...rest } = item;
          const { id, roleInfo, ...fRest } = functionData || {};
          const roleList = roleInfo?.roleList;
          return {
            ...rest,
            ...fRest,
            functionId: id,
            functionRoleBtoList: roleList?.map((item) => item?.id),
          };
        },
      ),
    };
    return res;
  };

  const getDetail = useCallback(async () => {
    if (value?.id) {
      const res =
        await vsf?.services?.ApplicationFunctionGroupWithFunctionDtoController_getGroupWithFunctionById_7e6558(
          {
            id: value?.id,
          },
        );
      if (res?.code === 200) {
        const target = formatData(res?.data);
        setDetail(target);
      }
    } else {
      setDetail({});
    }    
  }, [value?.id]);
  
  useEffect(() => {
    if (application) {
      getRoleList(application?.id);
    }
  }, [application]);

  const onTransferChange = (v) => {
    transferValues.current = v;
  };

  useEffect(() => {
    getDetail();
  }, [getDetail]);

  if (!detail || !roleListStatus) return null;

  return (
    <VSControlledForm
      id="FunctionForm"
      value={detail}
      onChange={(_value) => {
        console.log(_value, 'value');
        props.onChange?.({
          ..._value,
          // applicationFunctionVsGroupWithFunctionDtoList:
          //   transferValues?.current ?? [],
        });
      }}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormItem
        name={['name']}
        label="功能分组名称"
        valueType="text"
        rules={[{ required: true }]}
      />
      <VSFormItem
        name={['applicationFunctionVsGroupWithFunctionDtoList']}
        valueType="custom"
        fieldProps={{}}
      >
        <Transfer
          roleList={roleList}
          application={application}
          // onTransferChange={onTransferChange}
        />
      </VSFormItem>
    </VSControlledForm>
  );
};
export default definePage(StandardApplicationFuncitonTransfer);
