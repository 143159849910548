import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_updateOtherPatientIdentification_904aa0: {
    method: 'post',
    url: '/api/patient-main/update-other-patient-identification',
    parameterFomatter: (data?: {
      btoParam: UpdateOtherPatientIdentificationBtoPatientMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PatientMainController_createOrUpdatePatient_d8b932: {
    method: 'post',
    url: '/api/patient-main/create-or-update-patient',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdatePatientBtoPatientMainServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PatientFullVoPatientMainEntranceWebVo) =>
      data,
  },
  PatientMainController_getIdentificationsByPatientId_af9e3b: {
    method: 'post',
    url: '/api/patient-main/get-identifications-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientIdentificationVoPatientMainEntranceWebVoPatientfullvo[],
    ) => data,
  },
  ClinicInpVisitController_saveMedicalRecord_a12ec8: {
    method: 'post',
    url: '/api/clinic-inp-visit/save-medical-record',
    parameterFomatter: (data?: {
      updatePatientBto: UpdatePatientBtoPatientMainServiceBto;
      saveInpVisitForMedicalRecordBto: SaveInpVisitForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionStatisticsBto: SaveInpVisitExtensionStatisticsBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionNurseRecordForMedicalRecordBto: SaveInpVisitExtensionNurseRecordForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionBloodInfoBto: SaveInpVisitExtensionBloodInfoBtoClinicInpVisitServiceBto;
      createDiagnosisRecordBtoList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      saveOperationRecordBtoList: SaveOperationRecordBtoClinicInpVisitRecordServiceBto[];
      saveIcuRecordBtoList: SaveIcuRecordBtoClinicRecordServiceBto[];
      saveBloodTransfusionRecordBto: SaveBloodTransfusionRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicInpVisitController_getMedicalRecordVoById_150e6f: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-medical-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  BaseCommonController_getServerTime_371274: {
    method: 'post',
    url: '/api/base-common/get-server-time',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
