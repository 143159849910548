import { useEventListener } from 'ahooks';
import React, {
  forwardRef,
  Fragment,
  useContext,
  useImperativeHandle,
  useRef,
} from 'react';

import Herb from '@/pages/Index/cpoe/center/main/chm';
import Dispose from '@/pages/Index/cpoe/center/main/dispose';
import Exam from '@/pages/Index/cpoe/center/main/exam';
import DefendImport from '@/pages/Index/cpoe/center/main/medical_order_template/DefendImport';
import DefendTemplateApp from '@/pages/Index/cpoe/center/main/medical_order_template/DefendTemplateApp';
import Lab from '@/pages/Index/cpoe/center/main/test';

import Determine from '../../../determine';
import { BeIn } from '..';
interface AuxiliaryProps {
  labProps?: any;
  examProps?: any;
  herbProps?: any;
  operationProps?: any;
  pathologyProps?: any;
  disposeProps?: any;
  onChange: (value: any) => void;
  agreedPrescriptionIndicator: boolean;
}

enum method {
  onExam,
  onLab,
  onHerb,
  onDispose,
  onTemplate,
}

export type AuxiliaryRef = {
  [key in keyof typeof method]: (value?: never) => void;
};

export interface Ref {
  onOpen: (value?: never) => void;
}

const Auxiliary = forwardRef((props: AuxiliaryProps, ref) => {
  const {
    labProps,
    agreedPrescriptionIndicator,
    examProps,
    disposeProps,
    onChange,
  } = props;

  const labRef = useRef<Ref>(null);
  const examRef = useRef<Ref>(null);
  const herbRef = useRef<Ref>(null);
  const disposeRef = useRef<Ref>(null);
  const templateRef = useRef<Ref>(null);
  useImperativeHandle(ref, () => ({
    onExam: (value) => examRef?.current?.onOpen(value),
    onLab: (value) => labRef?.current?.onOpen(value),
    onDispose: (value) => disposeRef?.current?.onOpen(value),
    onHerb: (value) => herbRef?.current?.onOpen(value),
    onTemplate: () => templateRef?.current?.onOpen?.(),
  }));
  const onSubmit = useContext(BeIn) || {};

  return (
    <Fragment>
      {/* isShoweno:false,isShowEmergencyIndicator:false */}
      <Lab
        ref={labRef}
        onSubmit={onSubmit}
        {...labProps}
        isShowMeno={false}
        isShowEmergencyIndicator={false}
      />
      <Exam
        ref={examRef}
        onSubmit={onSubmit}
        {...examProps}
        isTemplate={true}
      />
      <Herb
        ref={herbRef}
        onSubmit={onSubmit}
        show={true}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
      />
      <Dispose ref={disposeRef} onSubmit={onSubmit} {...disposeProps} />
      <DefendImport
        onChange={onChange}
        ref={templateRef}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        showRadio
      />
    </Fragment>
  );
});

Auxiliary.displayName = 'Auxiliary';

export default Auxiliary;
