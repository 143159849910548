import { createServices } from '@vs/vsf-boot';
export default createServices({
  RefundOrderController_getCanRefundOrders_dbe730: {
    method: 'post',
    url: '/api/clinic-record-order/get-can-refund-orders',
    parameterFomatter: (data?: {
      qto: OrderCanRefundQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderCanRefundVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  PatientMainController_getPatientWithProfileByDisplayId_40245d: {
    method: 'post',
    url: '/api/patient-main/get-patient-with-profile-by-display-id',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientWithProfileVoPatientMainEntranceWebVo,
    ) => data,
  },
  OutpOrderRefundApplyController_refundApplyOrders_d3ab4c: {
    method: 'post',
    url: '/api/clinic-record-order-extension-entrance-web/refund-apply-orders',
    parameterFomatter: (data?: { orderIds: number[]; refundReason: string }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
