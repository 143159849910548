import './index.less';

import vsf from '@vs/vsf-boot';
import { Input, message, Radio, VSForm, VSFormItem } from '@vs/vsf-kit';
import { useAsyncEffect, useRequest } from 'ahooks';
import { Select } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { AsyncSuspense, DragDrop } from '@/pages/Index/components';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

import Item from './list_item';

const CommonMedicines = () => {
  const onResetFields = (id) => vsf?.refs?.[id]?.resetFields();
  const [click] = useOnChangeValue('DRUG');
  const [template] = useOnChangeValue('PERSONAL');
  const [drag] = useOnChangeValue<any>([]);
  const [dataSource, setDataSource, , , getInitDataSource] =
    useGetResetInitState([]);
  const ref = useRef<any>(null);

  const menu = [
    {
      name: '药疗',
      label: 'DRUG',
      icon: 'icon-yaoliao',
    },
    {
      name: '检查',
      label: 'EXAM',
      icon: 'icon-jiancha',
    },
    {
      name: '检验',
      label: 'LAB',
      icon: 'icon-jianyan1',
    },
    {
      name: '治疗',
      label: 'TREAT',
      icon: 'icon-zhiliao',
    },
  ];

  const { loading } = useRequest(
    async () => {
      if (click?.value === 'DRUG') {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_getFavoriteDrug_bffb8b?.(
            {
              favoriteType: template?.value,
            },
          );
        drag?.onChange(
          res?.data?.map((item) => ({
            clinicItemName: item?.drug?.drugName,
            clinicItemId: item?.drug?.id,
            id: item?.id,
          })),
        );
      } else {
        const res =
          await vsf.services?.ClinicItemController_getFavoriteClinicItem_fa660a?.(
            {
              favoriteType: template?.value,
              itemClass: click?.value,
            },
          );
        drag?.onChange(res?.data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      refreshDeps: [click?.value, template?.value],
    },
  );

  const onCollect = async (item) => {
    const res =
      await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
        {
          favoriteType: template?.value,
          itemClass: click?.value,
          itemId: item?.clinicItemId,
        },
      );
    if (res?.code === 200) {
      // 返回删除结果
      drag?.onChange([
        ...drag?.value,
        {
          ...item,
          [click?.value == 'DRUG' ? 'clinicItemId' : 'id']: res?.data,
        },
      ]);
      message?.success('收藏成功', 1);
    }
    return res?.data;
  };
  // console.log(drag, 'dragdrag');

  const onCancelCollect = (value) => {
    // console.log(value);

    Tip({
      content: `确定删除 {【${value?.clinicItemName}】}?`,
      onOk: async () => {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItem_5db34a?.(
            {
              itemClass: click?.value,
              itemId: click?.value == 'DRUG' ? value?.clinicItemId : value?.id,
              favoriteType: template?.value,
            },
          );
        // console.log(drag?.value, value, click?.value, 'drag?.value');

        if (res?.code == 200) {
          if (click?.value == 'DRUG') {
            drag?.onChange(
              drag?.value?.filter(
                (item) => item?.clinicItemId != value?.clinicItemId,
              ),
            );
          } else {
            drag?.onChange(
              drag?.value?.filter((item) => item?.id != value?.id),
            );
          }
          message?.success('取消收藏成功');
        }
        return res?.data;
      },
    });
  };

  const updateFavoriteOrderItemSort = async (id, sortNumber) => {
    const res =
      await vsf?.services?.FavoriteOrderItemSettingController_updateFavoriteOrderItemSort_5056c7?.(
        {
          itemClass: click?.value,
          itemId: id,
          favoriteType: template?.value,
          sortNumber: sortNumber,
        },
      );
    return res;
  };

  const handelChangePosition = async (dragIndex, hoverIndex, value?) => {
    const reorderedItems = drag.value?.slice();
    const [removed] = reorderedItems.splice(dragIndex, 1);
    reorderedItems.splice(hoverIndex, 0, removed);
    if (!value) {
      drag?.onChange(reorderedItems);
    }
    if (value) {
      const res = await updateFavoriteOrderItemSort(value, hoverIndex);
      if (res?.code == 200) {
        message.success('位置更新成功');
      } else {
        // 还原位置
        drag?.onChange(reorderedItems);
      }
    }
  };

  useAsyncEffect(async () => {
    // console.log(click.value, '7215_click.value');
    if (click?.value === 'DRUG') {
      const res =
        await vsf?.services?.DrugController_queryByDrugDictionaryBaseQtoWaterfall_02480b(
          {
            qto: {
              from: 0,
              size: 999999,
            },
          },
        );
      // console.log(res.data, '7124_data');
      if (res?.code === 200) {
        const data: any = res?.data?.result?.map((item) => ({
          clinicItemName: item?.drugName,
          clinicItemId: item?.id,
        }));
        // console.log(data, 'data');
        setDataSource(data);
      }

      // const res =
      //   await vsf?.services?.DrugDrugController_queryPagefallByDrugDictionaryDrugListQto_270f4c(
      //     {
      //       qto: {
      //         storageIdIn: [123123],
      //       },
      //     },
      //   );
      // console.log(res, '7215_res');
      // const data: any = Array?.from(
      //   new Set(res?.data?.result?.map((ele) => ele?.drugId)),
      // )
      //   ?.map((drugId) => {
      //     return res?.data?.result?.find((ele) => ele?.drugId === drugId);
      //   })
      //   ?.map((item) => ({
      //     clinicItemName: item?.drugName,
      //     clinicItemId: item?.drugId,
      //   }));
      // setDataSource(data);
    } else {
      const res =
        await vsf?.services?.ClinicItemController_allClinicItemDictionaryCharge_d97a65?.(
          {
            qto: {
              from: 0,
              size: 999,
              itemClassIs: click?.value,
            },
          },
        );

      setDataSource(
        res?.data
          ?.filter(
            (item) =>
              !drag?.value?.map((item) => item?.id)?.includes?.(item?.id),
          )
          ?.map((item) => ({
            clinicItemName: item?.clinicItemName,
            clinicItemId: item?.id,
            id: item?.id,
          })),
      );
    }
  }, [click?.value, drag?.value]);
  return (
    <div className="aspirin-common-medicines-box">
      <DndProvider backend={HTML5Backend}>
        <nav>
          <SearchContainer.Header
            mode="inline"
            title="开单常用（药-检查-检验-治疗）"
          />
        </nav>
        <main>
          <div className="aspirin-common-medicines-main-menu-box">
            {menu?.map((item) => (
              <div
                className={classNames(
                  'aspirin-common-medicines-main-menu-item-box',
                  item?.label === click?.value ? 'active' : '',
                )}
                key={item?.name}
                onClick={() => {
                  onResetFields('clinicItemForm');
                  click.onChange(item?.label);
                }}
              >
                <Icon type={item?.icon} size={24} />
                <span>{item?.name}</span>
              </div>
            ))}
          </div>
          <div className="aspirin-common-medicines-main-content-box">
            <nav>
              <Radio.Group
                buttonStyle="solid"
                value={template?.value}
                onChange={(value) => {
                  onResetFields('clinicItemForm');
                  template?.onChange(value);
                }}
                optionType="button"
                dataSource={[
                  {
                    value: 'PERSONAL',
                    label: '个人',
                  },
                  {
                    value: 'DEPARTMENT',
                    label: '科室',
                  },
                ]}
              />
              <VSForm id="clinicItemForm">
                <VSFormItem
                  style={{ width: '300px' }}
                  valueType="selectCode"
                  name="clinicItemId"
                  dataSource={dataSource}
                  fieldProps={{
                    allowClear: true,
                    onSelect: (_, value) => onCollect(value),
                    placeholder: '搜索相关字段进行添加',
                  }}
                  fieldNames={{
                    label: 'clinicItemName',
                    value: 'clinicItemId',
                  }}
                />
              </VSForm>
            </nav>
            <div className="aspirin-common-main-body-box">
              <AsyncSuspense loading={loading}>
                {drag?.value?.map((item: any, index) => {
                  return (
                    <Item
                      key={index}
                      index={index}
                      {...item}
                      id={
                        click?.value === 'DRUG' ? item?.clinicItemId : item?.id
                      }
                      changePosition={handelChangePosition}
                      onCancelCollect={() => onCancelCollect(item)}
                    />
                  );
                })}
              </AsyncSuspense>
            </div>
          </div>
        </main>
      </DndProvider>
    </div>
  );
};

export default CommonMedicines;
