import React, { useEffect, useRef } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/Icon';
import { useHorizontalScroll } from '@/utils/hooks';

type InfoPropsType = {
  data: any;
  color?: string;
};

const Item = (props: InfoPropsType) => {
  const { data } = props;
  return (
    <div className="info-item">
      <div
        className="info-bar"
        style={{
          backgroundColor: data?.color,
        }}
      ></div>
      <div className="info-content">
        {(data?.data || [])?.map((item, index) => {
          const labelChar = (item?.label || '')?.split('');
          return (
            <div key={index} className="item-row">
              <span
                className="label"
                style={{
                  width: data?.labelWidth,
                }}
              >
                {labelChar?.map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
              </span>
              <span className="value">{item?.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mock = [
  {
    color: '#3276E8',
    labelWidth: 64,
    data: [
      { label: '类型', value: '自费' },
      { label: '床号', value: '感染31床' },
    ],
  },
  {
    color: '#69A1FF',
    labelWidth: 80,
    data: [
      { label: '病区', value: '感染科病房' },
      { label: '经治医生', value: '薛照' },
    ],
  },
  {
    color: '#50BC35',
    labelWidth: 64,
    data: [
      { label: '入院', value: '2023-07-17' },
      { label: '已住院', value: '10天' },
    ],
  },
  {
    color: '#FF7E7E',
    labelWidth: 96,
    data: [
      { label: '已出费用', value: '￥10200' },
      { label: '预交金金额', value: '￥31410' },
    ],
  },
  {
    color: '#FFA57E',
    labelWidth: 64,
    data: [
      { label: '过敏史', value: '地塞米松磷酸钠地塞米松磷酸钠' },
      { label: '诊断', value: '药物性肝损害' },
    ],
  },
];

const Index = () => {
  const indexCls = getPrefixCls('index-center-info');
  const infoRef = useRef<any>();
  useHorizontalScroll(infoRef);
  return (
    <div className={indexCls}>
      <div className="main">
        <div className="first">
          <span className="name">许跃新</span>
          <span className="gender">
            <Icon type="icon-nan" size={20} />
          </span>
          <span className="age">64岁</span>
        </div>
        <div className="second">
          <span className="label">住院号</span>
          <span className="value">10001336</span>
        </div>
      </div>
      <div className="info noscroll" ref={infoRef}>
        {mock?.map((item, index) => (
          <Item key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default Index;
