import {
  Section,
  VSTableColumn,
  VSTableToolbar,
  VSTableAddition,
  VSPromiseTable,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import Block from '@/module/Block';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

const DrugStocktaking = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const handelFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf?.services?.DrugStocktakingController_getDrugStocktakingClassList_28161f?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handelAdd = useCallback(async (params) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf?.services?.DrugStocktakingController_createDrugStocktakingClass_bfbdee?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          createBtoList: [
            {
              ...params,
              id: undefined,
              extensionJson: JSON.stringify({
                pharmacy: 'A73x1x702',
                drugStocktakingType: params?.extensionJson,
              }),
            },
          ],
        },
      ); // Tips: 需要返回完整数据结构，和该组件RO一致
    setRefresh(!refresh);
    return params;
  }, []);

  const handelUpdate = useCallback(async (params) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf?.services?.DrugStocktakingController_updateDrugStocktakingClass_85e160?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          updateBtoList: [
            {
              ...params,
              extensionJson: JSON.stringify({
                pharmacy: 'A73x1x702',
                drugStocktakingType: params?.extensionJson,
              }),
            },
          ],
        },
      ); // Tips: 需要返回完整数据结构，和该组件RO一致
    setRefresh(!refresh);
    return params;
  }, []);

  const handelRemove = useCallback(async (params) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf?.services?.DrugStocktakingController_deleteDrugStocktakingClass_e40876?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          id: params?.id,
        },
      ); // Tips: 需要返回删除成功与否
    return res?.code === 200;
  }, []);
  /**
   *  显示就不要传函数了
   */
  const getDataSource = useCallback(async (params) => {
    const res =
      await vsf?.services?.DrugStocktakingController_getDrugStocktakingTypeSelect_e2b8da?.(
        {},
      );
    setDataSource(
      res?.data?.map((item) => ({
        label: item?.drugStocktakingTypeName,
        value: item?.drugStocktakingTypeValue,
      })),
    );
  });

  useEffect(() => {
    getDataSource();
  }, []);

  const handelInputChange = (e) => {
    const v = e.target.value ?? '';
    if (v) {
      const ask = askRomanAlphabet(v);
      console.log(ask);
    }
  };

  return (
    <VSPromiseTable
      id="DrugStocktakingTable"
      vsid="72176"
      extraParams={{ refresh }}
      pagination={false}
      className="aspirin-table drug_stocktaking"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        onFieldChange: (key, value, record, form) => {
          if (value.name) {
            form.setFieldValue(key, {
              ...record,
              inputCode: askRomanAlphabet(value.name),
            });
          }
        },
      }}
      onFetch={handelFetch}
      onAdd={handelAdd}
      onRecord={() => {
        return { id: Math.random() };
      }}
      onUpdate={handelUpdate}
      onRemove={handelRemove}
      style={{ overflow: 'scroll' }}
    >
      <VSTableColumn
        dataIndex={['name']}
        title="盘点分类名称"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入盘点分类名称' },
            { message: 'cname长度不合法', type: 'string', min: 0, max: 32 },
          ],
        }}
        fieldProps={{
          onChange: handelInputChange,
        }}
      />

      <VSTableColumn
        dataIndex={['inputCode']}
        title="输入码"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入输入码' },
            { message: '输入码长度不合法', type: 'string', min: 0, max: 32 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['extensionJson']}
        title="类型"
        valueType="select"
        formItemProps={{
          rules: [
            { required: true, message: '请选择类型' },
            {
              message: '类型字段长度不合法',
              type: 'string',
              min: 0,
              max: 2048,
            },
          ],
        }}
        fieldProps={{
          dataSource,
        }}
      />

      <VSTableToolbar
        title="药品盘点分类列表"
        style={{ boxSizing: 'border-box', padding: '0 24px' }}
      >
        <VSTableAddition children="添加" position="top" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(DrugStocktaking);
