import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { Button, Modal, Row, Space, Tag, message, Col } from '@vs/vsf-kit';
import Icon from '@/module/Icon';

import ClinicOutpVisitList from './form';
import ModalTable from './ModalTable';
import DetailTable from './table';

import './index.less';

function index({ routes, stores, ...reset }) {
  const outpData = vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT;
  //记录信息查询
  const { run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryUnRefundOutpSettleMaster_a0a382?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              settleNumberIs: params,
            },
          },
        ); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200 && res?.data?.result.length > 0) {
        vsf?.refs?.ClinicOutpVisitListForm?.setFieldsValue({
          ...res?.data?.result[0]?.outpSettleMasterVo,
          staffName:
            res?.data?.result[0]?.outpSettleMasterVo?.operatorStaffId
              ?.staffName,
        });
        setDataSorce(res?.data);
        setSelectedData(undefined);
        console.log(res?.data);
        if (
          ['ALL_REFUND', 'PARTIAL_REFUND'].includes(
            res?.data?.result[0]?.outpSettleMasterVo?.settleStatus,
          ) ||
          res?.data?.result[0]?.outpSettleMasterVo?.refundVsOutpSettleId
        ) {
          Modal.warn({
            title: '该结算记录已退费/已作废，不可再次操作，仅可查看',
            okText: '确定',
            cancelText: '取消',
          });
        }
        return res?.data;
      } else {
        message.error('当前未查到退费明细');
        return [];
      }
    },
    { manual: true },
  );
  //条件查询出来的数据源
  const [dataSorce, setDataSorce] = useState();

  //弹窗相关
  const [open, setOpen] = useState(true);

  //全部退费弹窗
  const [voidOpen, setVoidOpen] = useState(false);

  //作废交易
  const [fullRefundOpen, setFullRefundOpen] = useState(false);

  //双击弹窗带来的Row数据
  const [doubleClikParam, setDoubleClikParam] = useState();

  const [fullRefundInfo, setFullRefundInfo] = useState();

  //双击弹窗带来的Row数据回调
  const doubleClikChange = (param) => {
    setOpen(false);
    setDoubleClikParam(param);
    run(param?.outpSettleMasterVo?.settleNumber);
  };
  //从table里选择出来的数据
  const [selecteData, setSelectedData] = useState();

  const selecteDataChange = (param) => {
    setSelectedData(param);
  };

  // const [amountPerform, setAmountPerform] = useState(false);
  // const [operateDate, setOperateDate] = useState(false);
  const [isAllRefunds, setIsAllRefunds] = useState(true);
  const { data: PAY_WAY_DICT, run: PAY_WAY_DICT_RUN } = useRequest(
    async () => {
      const res =
        await vsf.services?.BaseDictionaryController_getByCodeType_a0a0ce?.({
          codeType: 'PAY_WAY_DICT',
        });
      return res?.data;
    },
    { manual: true },
  );
  useEffect(() => {
    PAY_WAY_DICT_RUN?.();
  }, []);
  const PayTheBillDetalis = (props) => {
    const { outpSettleApportionItemDtoList, payment } = {
      ...props?.dataSorce,
    };

    return (
      <>
        <div
          className="list-layout"
          style={{
            borderBottom: '1px #ccc solid ',
          }}
        >
          {outpSettleApportionItemDtoList?.map((ele) => (
            <p>
              {ele?.settleApportionName}:
              <span className="money-font">{ele?.apportionCharge}</span>
            </p>
          ))}
        </div>
        <div className="list-layout">
          {payment?.paymentDetailDtoList?.map((ele) => (
            <p>
              {PAY_WAY_DICT?.find(({ code }) => ele?.payType === code)?.name}:
              <span className="money-font">{ele?.paymentAmount}</span>
            </p>
          ))}
        </div>
      </>
    );
  };
  const paramData = (param) => {
    const arr = [];
    // param &&
    //   param.map((item) => {
    //     item?.outpBillingDetailVoList?.map((item) => {
    //       arr.push({
    //         outpBillingDetailId: item?.id,
    //         amountRefund: item?.amountRefund,
    //       });
    //     });
    //   });
    param &&
      param.map((item) => {
        arr.push({
          outpBillingDetailId: item?.id,
          amountRefund: item?.amountRefund,
        });
      });
    return arr;
  };
  //作废交易
  const voidClick = async () => {
    // 校验内容：OUTP_SETTLE_MASTER.branch_institution_id 为当前院区
    if (
      stores?.common?.currentApplication?.department?.branchInstitution?.id !==
      dataSorce?.result[0]?.outpSettleMasterVo?.branchInstitution?.id
    )
      return message?.error('该结算记录非本院区结算，请回结算院区进行退费！');

    // 校验内容：OUTP_SETTLE_MASTER.refund_vs_outp_settle_id 不为空 refund_settle_date 不为空
    if (
      !dataSorce?.result[0]?.outpSettleMasterVo?.settleStatus ===
        'FINISH_CHARGE' ||
      dataSorce?.result[0]?.outpSettleMasterVo?.refundVsOutpSettleId
    )
      return message.error('该结算记录已退费/作废，不可再次退费/作废交易。');
    // 校验内容：OUTP_SETTLE_MASTER.operator_staff_id 是否为当前操作员
    const { id: operatorStaffId } =
        dataSorce?.result[0]?.outpSettleMasterVo?.operatorStaffId ?? {},
      { id: currentStaffId } = stores?.user?.staffInfo;
    if (operatorStaffId !== currentStaffId)
      return message.error(
        '作废交易需原收款员作废，请使用反交易-部分退费方式退费！',
      );
    // 校验内容：OUTP_BILLING_DETAIL.amount_perform 执行数量不为0时为有已执行费用明细
    const selecteData = vsf?.refs?.settleMasterDetailListTable
      ?.getValues()
      ?.filter((ele) => !ele?.headerRow);
    let i = 0;
    while (selecteData?.length >= i) {
      if (selecteData?.[i]?.amountPerform >= 1)
        return message?.error('已有费用已执行，不能作废。');
      i++;
    }
    const settleDate =
      vsf?.refs?.ClinicOutpVisitListForm?.getFieldValue('settleDate');
    if (!dayjs().isSame(dayjs(settleDate), 'day')) {
      message.error(
        '该结算记录非当天结算，不能作废交易，请使用全部退费/部分退费',
      );
      return;
    }

    const res = await vsf.services?.OutpSettleController_preRefund_2f0280?.({
      outpSettleMasterId: dataSorce?.result[0]?.outpSettleMasterVo?.id,
      patientId: dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
      // partialRefundOutpBillingDetailEoList: paramData(selecteData),
      cancelIndicator: true,
    });
    if (res?.code == 200) {
      Modal.confirm({
        title: '请回收发票',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          // setFullRefundOpen(true);
          setVoidOpen(true);
          setFullRefundInfo(res?.data);
        },
      });
    }
  };

  //部分退费
  const partialRefund = async () => {
    // 校验内容：OUTP_SETTLE_MASTER.branch_institution_id 为当前院区
    if (
      stores?.common?.currentApplication?.department?.branchInstitution?.id !==
      dataSorce?.result[0]?.outpSettleMasterVo?.branchInstitution?.id
    )
      return message?.error('该结算记录非本院区结算，请回结算院区进行退费！');

    // 校验内容：OUTP_SETTLE_MASTER.refund_vs_outp_settle_id 不为空 refund_settle_date 不为空
    if (
      !dataSorce?.result[0]?.outpSettleMasterVo?.settleStatus ===
        'FINISH_CHARGE' ||
      dataSorce?.result[0]?.outpSettleMasterVo?.refundVsOutpSettleId
    )
      return message.error('该结算记录已退费/作废，不可再次退费/作废交易。');

    await vsf?.refs?.settleMasterDetailListTable
      .getEditForm()
      .validateFieldsReturnFormatValue();
    const _selectData = vsf?.refs?.settleMasterDetailListTable
      ?.getValues()
      ?.filter((ele) => ele?.amountRefund >= 1);

    // 校验内容:不能所有费用明细的退费数量都为空
    if (_selectData?.length <= 0)
      return message?.error('请输入需要退费的数量。');

    const res = await vsf.services?.OutpSettleController_preRefund_2f0280?.({
      outpSettleMasterId: dataSorce?.result[0]?.outpSettleMasterVo?.id,
      patientId: dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
      partialRefundOutpBillingDetailEoList: paramData(_selectData),
    });
    if (res?.code === 200) {
      Modal.confirm({
        title: '部分退费需要反交易，然后再重收，是否退费？',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          setIsAllRefunds(false);
          setFullRefundOpen(true);
          setFullRefundInfo(res?.data);
        },
      });
    }
  };

  //全部退费
  const allRefund = async () => {
    // 校验内容：OUTP_SETTLE_MASTER.branch_institution_id 为当前院区
    if (
      stores?.common?.currentApplication?.department?.branchInstitution?.id !==
      dataSorce?.result[0]?.outpSettleMasterVo?.branchInstitution?.id
    )
      return message?.error('该结算记录非本院区结算，请回结算院区进行退费！');

    // 校验内容：OUTP_SETTLE_MASTER.refund_vs_outp_settle_id 不为空 refund_settle_date 不为空
    if (
      !dataSorce?.result[0]?.outpSettleMasterVo?.settleStatus ===
        'FINISH_CHARGE' ||
      dataSorce?.result[0]?.outpSettleMasterVo?.refundVsOutpSettleId
    )
      return message.error('该结算记录已退费/作废，不可再次退费/作废交易。');
    // 校验内容：OUTP_BILLING_DETAIL.amount_perform 执行数量不为0时为有已执行费用明细
    const flatSelectData = vsf?.refs?.settleMasterDetailListTable
      ?.getValues()
      ?.filter((ele) => !ele?.headerRow);
    let i = 0;
    while (flatSelectData?.length >= i) {
      if (flatSelectData?.[i]?.amountPerform >= 1)
        return message?.error('已有费用已执行，不能全部退费。');
      i++;
    }
    Modal.confirm({
      title: '请回收发票，全退后结算记录及医嘱均作废',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const res = await vsf.services?.OutpSettleController_preRefund_2f0280?.(
          {
            outpSettleMasterId: dataSorce?.result[0]?.outpSettleMasterVo?.id,
            patientId: dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
          },
        );
        if (res?.code === 200) {
          setIsAllRefunds(true);
          setFullRefundOpen(true);
          setFullRefundInfo(res?.data);
        }
      },
    });
  };

  // console.log(dataSorce, 'dataSorce========== ');
  return (
    <div className="outpatient-refunds-layout">
      <div className="top">
        <ClinicOutpVisitList
          callback={(v) => setDataSorce(v)}
          doubleClikChange={doubleClikChange}
          routes={routes}
        />
      </div>
      <div className="bootom">
        <div className="left">
          <div className="text-before-weight">付费详情</div>
          <PayTheBillDetalis
            dataSorce={dataSorce?.result[0]?.outpSettleMasterVo}
          />
        </div>
        <div className="right">
          <div className="refunds-content-title">
            <div className="text-before-weight">费用分类</div>
            <div className="refunds-tags">
              {dataSorce?.result &&
                dataSorce?.result[0]?.outpSettleMasterVo?.outpReceiptClassDetailDtoList?.map(
                  (item, index) => {
                    return (
                      <Tag
                        color="processing"
                        className='refunds-tags-item'
                      >
                        {/* TODO  outpReceiptClassDetailDtoList*/}
                        {/* vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT
                         */}
                        {item?.receiptClassCode &&
                          (vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT?.find(
                            (items) => {
                              return items?.value === item?.receiptClassCode;
                            },
                          )?.label ??
                            '')}
                        :{/* :{item?.receiptClassName ?? '未知'}: */}
                        {Number(item?.cost).toFixed(2)}元
                      </Tag>
                    );
                  },
                )}
            </div>
          </div>
          <div className="refunds-content">
            <div className="text-before-weight">收费明细</div>
            {dataSorce?.result[0]?.outpBillingDetailList && (
              <DetailTable
                selectRows={selecteData}
                values={dataSorce?.result[0]?.outpBillingDetailList}
                selecteDataChange={selecteDataChange}
              />
            )}
          </div>
          <div className="refunds-btn">
            <Space size={4}>
              <Button
                onClick={() => {
                  console.log(vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT);
                }}
                type="primary"
                ghost
              >
                补打收费凭条
              </Button>
              <Button type="primary" ghost>
                票据预览
              </Button>
              <Button type="primary" onClick={voidClick}>
                作废交易
              </Button>
              <Button type="primary" onClick={partialRefund}>
                部分退费
              </Button>
              <Button type="primary" onClick={allRefund}>
                全部退费
              </Button>
            </Space>
          </div>
        </div>
      </div>
      {/* <Modal
        title={<div style={{ color: '#3276E8' }}>结算记录</div>}
        open={open}
        onCancel={() => setOpen(false)}
        width={1200}
        footer={() => {
          <Button onClick={() => setOpen(false)}>取消</Button>;
        }}
      >
        <ModalTable doubleClikChange={doubleClikChange} />
      </Modal> */}
      <Modal
        title={<div style={{ color: '#3276E8' }}>确认退费</div>}
        open={fullRefundOpen || voidOpen}
        onCancel={() => {
          setFullRefundOpen(false);
          setVoidOpen(false);
        }}
        onOk={async () => {
          const _selectData = vsf?.refs?.settleMasterDetailListTable
            ?.getValues()
            ?.filter((ele) => !ele?.headerRow);
          let request;
          if (voidOpen) {
            const res =
              await vsf.services?.OutpSettleController_preRefund_2f0280?.({
                outpSettleMasterId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.id,
                patientId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
              });
            request =
              await vsf.services?.OutpSettleController_outpRefund_01ded5?.({
                outpPreRefundEo: { ...res?.data, cancelIndicator: true },
              });

            if (request?.code === 200) {
              message.success('作废成功');
              setFullRefundOpen(false);
              setVoidOpen(false);
            }
            return setDataSorce();
          } else if (!isAllRefunds) {
            const res =
              await vsf.services?.OutpSettleController_preRefund_2f0280?.({
                outpSettleMasterId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.id,
                patientId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
                partialRefundOutpBillingDetailEoList: paramData(
                  _selectData?.filter((ele) => ele?.amountRefund >= 1),
                ),
              });
            if (res?.code === 200) {
              request =
                await vsf.services?.OutpSettleController_partRefund_f5d07c?.({
                  // TODO: 请检查以下这部分传参是否符合需求
                  outpPreRefundEo: res?.data,
                }); // TODO: 你可能需要整理返回数据格式
              if (request?.code === 200) {
                message.success('部分退费成功');
                setFullRefundOpen(false);
                Modal.confirm({
                  // icon: null,
                  title: '退费成功，是否直接跳转到收费界面收取未退费用？',
                  onOk: () => {
                    vsf?.navigateTo(
                      `/index/emergency-charge?patient=${JSON.stringify(
                        dataSorce?.result?.[0]?.outpSettleMasterVo?.patient,
                      )}`,
                    );
                  },
                  onText: '确认',
                  cancelText: '取消',
                });
              }
            }

            return;
          } else {
            const res =
              await vsf.services?.OutpSettleController_preRefund_2f0280?.({
                outpSettleMasterId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.id,
                patientId:
                  dataSorce?.result[0]?.outpSettleMasterVo?.patient?.id,
              });
            if (res?.code === 200) {
              request =
                await vsf.services?.OutpSettleController_outpRefund_01ded5?.({
                  outpPreRefundEo: res?.data,
                });
              if (request?.code === 200) {
                message.success('全部退费成功');
                setFullRefundOpen(false);
                setVoidOpen(false);
              }
            }
            return setDataSorce();
          }
        }}
        okText="确定"
        cancelText="取消"
      >
        <div>
          <div className="madal-layout-return-top">
            <Row>
              <Col span={12} className="modal-div-font-style">
                票据类型:{' '}
                {`${
                  fullRefundInfo?.electronicInvoiceIndicator
                    ? '电子票据'
                    : '纸质票据'
                }`}
              </Col>
              <Col span={12} className="modal-div-font-style">
                NO: {fullRefundInfo?.physicalReceiptNumber ?? ''}
              </Col>
            </Row>
          </div>
          <div className="madal-layout-return" style={{ marginTop: '12px' }}>
            <div className="madal-layout-return-icon">退</div>
            <div className="font">现金</div>
            <div className="money">
              {dataSorce?.result[0]?.outpSettleMasterVo?.totalCharge}
            </div>
            <div className="font">元</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default index;
