import storage from '@/utils/storage';

//获取患者信息
export const getPatientInHospitalInfo = async (params) => {
  // console.log(params);
  const res =
    await vsf.services?.ClinicInpVisitController_getPatientInpVisitInfo_9055d6?.(
      {
        ...params,
      },
    );
  return res?.data?.[0];
};

//床号列表数据源
export const getListClinicPatientBedSimpleVoByBedWard = async () => {
  const currentApplication = storage.get('currentApplication');
  const res =
    await vsf.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
      {
        bedWardId: currentApplication.department.id,
      },
    );

  return res?.data?.map((item) => {
    return { ...item, bedLabel: `${item?.bedLabel}${item?.patientName}` };
  });
};

//医生医嘱列表
export const getWardPatientOrderInfo = async (params) => {
  const res =
    await vsf.services?.NurseBillingController_getWardPatientOrderInfo_a2d811?.(
      {
        inpVisitId: params,
      },
    );
  return {
    data: res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
//保存
export const saveNurseCost = async (param) => {
  // console.log(param);
  const res = await vsf.services?.InpNurseCostController_addNurseCost_cace91?.({
    addInpNurseCostBvo: param,
  });
  return res;
};
//作废
export const cancelInpNurseCost = async (param) => {
  const res =
    await vsf.services?.InpNurseCostController_cancelInpNurseCost_6c5288?.({
      inpNurseCostId: param,
    });
  return res;
};
//停止计费
export const nurseCostStopBilling = async (param) => {
  const res =
    await vsf.services?.InpNurseCostController_nurseCostStopBilling_9888f2?.({
      nurseCostIdList: param?.nurseCostIdList,
      billingStopDate: param?.billingStopDate,
    });
  return res;
};
