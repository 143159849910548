import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox } from '@vs/vsf-kit';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/Icon';

const mock = [
  {
    label: '门诊',
    value: 'OUTP',
  },
  {
    label: '住院',
    value: 'INP',
  },
  {
    label: '急诊',
    value: 'ERP',
  },
  {
    label: '体检',
    value: 'PHYSICAL_EXAM',
  },
  {
    label: '本科室',
    value: 5,
  },
];

const Index = (props) => {
  const { style = {}, handelConditionQuery, stores } = props;
  const {
    common: { currentApplication },
  } = stores || {};

  const target = mock;
  const [keys, setKeys] = useState<any[]>(['OUTP']);
  const [departmentNameIs, setDepartmentNameIs] = useState<any>();
  console.log(
    currentApplication.department,
    'currentApplicationcurrentApplication',
  );

  useEffect(() => {
    setDepartmentNameIs(currentApplication?.department?.id);
  }, [currentApplication]);

  const handleSelect = (item: any) => {
    const isSelect = keys?.includes(item?.value);

    if (isSelect) {
      setKeys(cloneDeep(keys)?.filter((v) => v !== item?.value));
      handelConditionQuery({
        keys: cloneDeep(keys)
          ?.filter((v) => v !== item?.value)
          .filter((item) => item !== 5),
        departmentNameIs: cloneDeep(keys)
          ?.filter((v) => v !== item?.value)
          .some((item) => item == 5)
          ? departmentNameIs
          : undefined,
      });
      console.log(departmentNameIs, 'departmentNameIs');
    } else {
      setKeys([...keys, item?.value]);
      console.log(departmentNameIs, 'departmentNameIs');

      handelConditionQuery({
        keys: [...keys, item?.value].filter((item) => item !== 5),
        departmentNameIs: [...keys, item?.value].some((item) => item == 5)
          ? departmentNameIs
          : undefined,
      });
    }
  };

  return (
    <div className="cpoe-check-block" style={style}>
      {target?.map((item, index) => {
        return (
          <div
            className={classnames('item', {
              'check-item': keys?.includes(item?.value),
            })}
            key={index}
            onClick={() => {
              handleSelect(item);
            }}
          >
            {item?.label}
            {keys?.includes(item?.value) && (
              <div className="check-item-checked ">
                <Icon className="icon12" type="icon-xuanze" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default definePage(Index);
