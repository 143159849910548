import React, { Fragment, useEffect } from 'react';
import { VSFormItem, VSForm, VSFormLayout, Divider } from '@vs/vsf-kit';

import { Determine, Flex } from '@/pages/Index/components';

import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';

import { debounce } from 'lodash';

function modalCom({ data, extendedData, list }) {
  console.log(data, 'data_tabber');
  console.log(extendedData, 'data_extendedData');
  console.log(list, 'data_list');

  useEffect(() => {
    if (data) {
      vsf?.refs?.DictionaryEditForm?.setFieldsValue(data);
      vsf?.refs?.extendedDataForm?.setFieldsValue(
        JSON.parse(data?.extensionJson),
      );
    }
  }, [data]);
  const valueType = {
    Boolean: 'switch',
    String: 'text',
    Long: 'digit',
    long: 'digit',
    Int: 'digit',
  };
  return (
    <Fragment>
      <VSForm id="DictionaryEditForm" labelAlign="left">
        <VSFormItem
          label="主键"
          name={['id']}
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout key="1" columnCount={3} labelWidth={100}>
          <VSFormItem
            label="编码ID"
            name={['code']}
            valueType="text"
            rules={[
              { message: '编码长度不合法', type: 'string', min: 0, max: 16 },
              {
                required: true,
              },
            ]}
            fieldProps={{
              disabled: data ? true : false,
            }}
          />
          {/* <VSFormItem
          name={['sortNumber']}
          label="排序号"
          valueType="digit"
          // rules={[
          //   { message: 'serialNumber', type: 'string', min: 0, max: 32 },
          // ]}
          fieldProps={{
            width: '100%'
          }}
        /> */}
          <VSFormItem
            label="编码名称"
            name={['name']}
            valueType="text"
            rules={[
              {
                message: '编码名称长度不能超过32个字',
                type: 'string',
                min: 0,
                max: 32,
              },
              {
                required: true,
              },
            ]}
            fieldProps={{
              onChange: debounce(
                ({ target }) => {
                  console.log(vsf.refs.DictionaryEditForm, 'tab_vsf');
                  console.log(askRomanAlphabet(target.value), 'tab_target');
                  vsf.refs.DictionaryEditForm.setFieldsValue({
                    inputCode: askRomanAlphabet(target.value),
                  });
                },
                30,
                { trailing: true },
              ),
            }}
          />
          <VSFormItem
            label="上级编码"
            name={['parentCode']}
            valueType="codeTableSelect"
            fieldProps={{
              showSearch: true,
              disabled: data,
            }}
            dataSource={list.current ?? []}
            fieldNames={{
              label: 'name',
              value: 'code',
            }}
          />
          <VSFormItem
            label="停用标识"
            name={['deprecateIndicator']}
            valueType="radio"
            initialValue={false}
            dataSource={[
              {
                label: '停用',
                value: true,
              },
              {
                label: '在用',
                value: false,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            label="码表分类"
            name={['codeTypeClass']}
            valueType="radio"
            dataSource={[
              { label: '用户定义', value: 'USER' },
              { label: '系统定义', value: 'SYSTEM' },
            ]}
            fieldProps={{
              defaultValue: 'USER',
            }}
          />

          <VSFormItem
            label="输入码"
            name={['inputCode']}
            valueType="text"
            rules={[
              {
                message: '输入码不能超过32个字',
                type: 'string',
                min: 0,
                max: 32,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSForm>
      <Determine condition={!!extendedData?.length}>
        扩展字段
        <Divider />
        <VSForm id="extendedDataForm" labelAlign="left">
          {extendedData?.length &&
            extendedData?.map((item) => {
              return (
                <VSFormItem
                  name={item?.fieldName}
                  label={`扩展字段${item?.fieldName}`}
                  valueType={valueType[item.fieldType]}
                  // rules={[
                  //   { message: '父项长度不合法', type: 'string', min: 0, max: 16 },
                  // ]}
                  fieldProps={
                    {
                      // placeholder:item?.fieldName
                    }
                  }
                />
              );
            })}
        </VSForm>
      </Determine>
    </Fragment>
  );
}

export default modalCom;
