import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal } from '@vs/vsf-kit';
import React, { Fragment, useState } from 'react';
import PricePatternTree from './tree';
import PricePatternTable from './table';
import Container from '@/module/Container';
const PricePatternManage = (props) => {
  /**
   * DataList
   * @type {any}
   */
  const [dataList, setDataList] = useState([]);

  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
            },
          ],
        }}
      >
        <PricePatternTree
          onChange={(_value) => {
            setDataList(_value);
          }}
        />
        <PricePatternTable value={dataList} />
      </Container>
    </Fragment>
  );
};
export default definePage(PricePatternManage);
