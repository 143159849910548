import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPriceAdjustController_getAllByDrugPriceAdjustMasterQto_3a7109: {
    method: 'post',
    url: '/api/drug-price-adjust/get-all-by-drug-price-adjust-master-qto',
    parameterFomatter: (data?: {
      qto: DrugPriceAdjustMasterQtoDrugPriceAdjustPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPriceAdjustMasterVoDrugPriceAdjustEntranceWebVo[],
    ) => data,
  },
});
