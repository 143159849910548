import React, { useEffect, useState } from 'react';
import { VSTable, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';

import '@/style/adaptation.less';

export default function certificateContent(props) {
  const { active, setActive, dataStore, setMedicalFlag } = props;
  return (
    <VSTable
      value={dataStore}
      rowSelection={{
        onSelectedRowsChange: (_value) => {
          props.onChange?.(_value);
        },
      }}
      rowClick={{
        rowClickType: {
          type: 'default',
          action: 'double-click',
        },
      }}
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            setActive(record);
            setMedicalFlag(true);
          },
          onClick: () => {
            if (active && active.id == record.id) {
              setActive();
            } else {
              setActive(record);
            }
          },
        };
      }}
      //   选中高亮
      rowClassName={(...arr) => {
        const [recode] = arr;
        return `aspirin-vs-table-row-box ${
          recode?.id === active?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box'
        }`;
      }}
      bordered
      scroll={{
        y: getScrollYRem(19.5),
      }}
      pagination={false}
      id="listTable"
    >
      <VSTableColumn
        title="序号"
        valueType="index"
        width={80}
        render={(v, index, n) => {
          return n + 1;
        }}
      />
      <VSTableColumn
        title="患者ID"
        dataIndex={['patient', 'displayId']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="姓名"
        dataIndex={['patient', 'name']}
        valueType="text"
        fieldProps={{}}
        width={'6rem'}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="性别"
        dataIndex={['patient', 'gender']}
        valueType="select"
        fieldProps={{}}
        width={'4rem'}
        dataSource={vsf.stores.dbenums.enums.SEX_DICT}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="年龄"
        dataIndex={['patientBirthday']}
        valueType="text"
        fieldProps={{}}
        width={'4rem'}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="就诊科室"
        dataIndex={['visitDepartment', 'departmentName']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="接诊医生"
        dataIndex={['encounterDoctor', 'staffName']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="诊断"
        dataIndex={['diagnosisName']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="工作单位或地址"
        dataIndex={['serviceAgency']}
        valueType="text"
        width={labelNumberWidth(10)}
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
        // dataSource={vsf.stores.dbenums.enums.AREA_DICT}
      />
      <VSTableColumn
        title="接诊时间"
        dataIndex={['visitTime']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="就诊类别"
        dataIndex={['clinicType']}
        valueType="select"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
        dataSource={[
          {
            label: '住院',
            value: 'INP',
          },
          {
            label: '门诊',
            value: 'OUTP',
          },
        ]}
      />
      <VSTableColumn
        title="备注"
        dataIndex={['certificationRecord', 'memo']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="打印次数"
        dataIndex={['certificationRecord', 'printTimes']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="开立日期"
        dataIndex={['certificationRecord', 'createdAt']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="开立医师"
        dataIndex={['certificationRecord', 'doctor', 'staffName']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
    </VSTable>
  );
}
