import { createServices } from '@vs/vsf-boot';
export default createServices({
  StaffDetailDtoController_queryDoctorList_7b5e02: {
    method: 'post',
    url: '/api/user-staff/query-doctor-list',
    parameterFomatter: (data?: {
      qto: DoctorListQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StaffBOController_updateDoctorInfo_907e11: {
    method: 'post',
    url: '/api/user-staff/update-doctor-info',
    parameterFomatter: (data?: {
      btoParam: UpdateDoctorInfoBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffVoUserStaffEntranceWebVo) => data,
  },
});
