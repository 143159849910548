import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Checkbox,
  compose,
  Dropdown,
  Input,
  Modal,
  RadioGroup,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const mockDoctorId = 1603;

import Table from './table';

/**
 * 字典查询
 */
let Dictionary: any = (props: any, ref) => {
  const { value, onChange, currentTab } = props;

  const [current, setCurrent] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState();

  const selectStatus = useRef<boolean>(false);

  const [init, setInit] = useState<boolean>(false);

  const searchRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    focus: onFocus,
    blur: onBlur,
  }));

  const onFocus = () => {
    setDropdownOpen(true);
    setTimeout(() => {
      searchRef?.current?.focus();
    }, 0);
  };

  const onBlur = () => {
    // setDropdownOpen(false);
  };

  const getSelectDetail = async (value) => {
    const drugItemId = value?.drugItemId;
    // const formatValue =
    if (drugItemId) {
      const res: any =
        await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
          {
            drugItemId: drugItemId,
            patientId: 1001,
            departmentId: 193,
            staffId: mockDoctorId,
          },
        );
      return res?.data;
    }

    return null;
  };

  const onSelect = async (v) => {
    const detailInfo = await getSelectDetail(v);
    selectStatus.current = false;
    setDropdownOpen(false);
    setCurrent(v);
    onChange?.({
      ...v,
      detailInfo: detailInfo,
    });

    props?.onFieldEnter?.();
  };

  useEffect(() => {
    if (current) {
      if (typeof current === 'string') {
        setCurrentValue(current);
      } else {
        setCurrentValue(current?.drugName);
      }
    }
  }, [current]);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  return (
    <div className="aspirin-dictionary-chm">
      <Dropdown
        menu={{
          items: [],
        }}
        // onOpenChange={(v) => {
        //   setDropdownOpen(v);
        // }}
        dropdownRender={(menu) => (
          <div
            className="aspirin-dictionary-chm-dropdown-container"
            onClick={(event) => {
              event.stopPropagation();
            }}
            onDoubleClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="header">
              <div className="first">输入序号选择项目，键盘“← →”翻页</div>
              <div className="second">
                <div className="left">
                  <Input
                    ref={searchRef}
                    value={searchValue}
                    onBlur={onBlur}
                    onInput={(e: any) => {
                      const value = e.target.value;
                      const regex = /^[^0-9]*$/; // 正则表达式，只允许非数字字符
                      if (regex.test(value)) {
                        setInit(true);
                        setSearchValue(value);
                      }
                    }}
                    width={280}
                  />
                </div>
              </div>
            </div>
            <Table
              key="MEDICATION"
              onSelect={onSelect}
              open={dropdownOpen}
              searchValue={searchValue}
              herbType={currentTab}
            />
          </div>
        )}
        open={dropdownOpen}
        // open={true}
      >
        <Input
          ref={ref}
          value={currentValue}
          onFocus={onFocus}
          autoFocus
          disabled={true}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Dropdown>
    </div>
  );
};

Dictionary.displayName = 'Dictionary';

Dictionary = React.forwardRef(Dictionary);

export default compose(
  withRef(),
  withField<any>({
    name: 'chmDictionary',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      return <>{props?.value}</>;
    },
  }),
)(Dictionary);
