import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-type-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterTypeDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterType_dc5486: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-type',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterTypeBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateClinicRegisterType_9b25f7: {
    method: 'post',
    url: '/api/outp-register-define/add-update-clinic-register-type',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateClinicRegisterTypeBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
});
