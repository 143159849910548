import {
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
  Switch,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import { askSearch } from '@/pages/Index/components/func/ask';
import Block from '@/module/Block';
import ClinicRegisterDefineEdit from '@/pages/Index/manage/out_patient_number_schedule/numberType/ClinicRegisterDefineEdit';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

const ClinicRegisterDefineTable = (props) => {
  const { data: dataSource } = useRequest(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472?.(
        {
          qto: {},
        },
      );
    return res?.data;
  });
  const { data } = useRequest(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5?.(
        {
          qto: {},
        },
      );
    const file = JSON.parse(JSON.stringify(res?.data));
    if (params) {
      const data = file.filter((item) => {
        return item.includes(params);
      });
      return data;
    } else {
      return res?.data;
    }

    // const file = JSON.parse(JSON.stringify(res?.data));
    // if (params.nameLike) {
    //   const data = file.filter((item) => {
    //     return item.includes(params);
    //   });
    //   return file;
    // } else {
    //   return res?.data;
    // }
  });

  const { data: allByClinicRegister } = useRequest(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterDefineNewQto_3be984?.(
        {
          qto: { ...params },
        },
      );
    return res?.data;
  });
  const handelList = async (v) => {
    const res =
      await vsf.services.DepartmentDtoController_getPagedBySearchCode_32d6e0({
        nameLike: v?.nameLike ?? '',
        outpOrInp: '0,2',
        clinicAttribute: '0',
      });
    return res?.data?.result;
  };
  const handelList1 = async (v) => {
    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: v?.nameLike ?? '',
        },
      );
    return res.data?.result;
  };
  const onFetch = async (params) => {
    const formData = {};
    if (Object?.keys(params.search).length > 0) {
      Object?.keys(params.search).map((item) => {
        formData[item] = params.search[item]?.id;
      });
      // formData.idIs = formData?.clinicLabelLike;
    }
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterDefineQto_04b11a?.(
        {
          qto: {
            orderList: params.orderList,
            ...formData,
            ...params.filters,
            ...(params?.extra ?? {}),
          },
        },
      );

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };
  const onAdd = async (params) => {
    params.clinicRegisterTypeId = params.clinicRegisterType.id;
    params.doctorId = params?.doctor?.id;
    params.clinicRegisterTypeId = params?.clinicRegisterType?.id;
    params.clinicDepartmentId = params?.clinicDepartment?.id;
    params.outpSpecialClinicId = params?.outpSpecialClinic?.id;
    params.stopIndicator = false;

    if (!params?.averageVisitTime) {
      params.averageVisitTime = 0;
    }
    const res =
      await vsf?.services?.OutpRegisterDefineController_createClinicRegisterDefine_54ab7e?.(
        {
          btoParam: params,
          extApiParam: params?.extApiParam,
        },
      );
    vsf?.refs?.deleteClinicRegisterDefineTable?.reload();
    message.success('添加成功');
    return res?.data;
  };
  const onUpdate = async (params) => {
    // console.log('=======');
    message.success('修改成功');
    // console.log(params);
    // params.id = params?.id;
    params.doctorId = params?.doctor?.id || null;
    params.clinicRegisterTypeId = params?.clinicRegisterType?.id;
    params.clinicDepartmentId = params?.clinicDepartment?.id;
    params.outpSpecialClinicId = params?.outpSpecialClinic?.id;
    const res =
      await vsf?.services?.OutpRegisterDefineController_updateClinicRegisterDefine_7ab8b4?.(
        {
          btoParam: params,
          extApiParam: params?.extApiParam,
        },
      );

    return res?.data;
  };
  const onRemove = async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteClinicRegisterDefine_58468d?.(
        {
          btoParam: params,
          extApiParam: params?.extApiParam,
        },
      );
    if (res?.code === 200) {
      message.success('删除成功');
    }
    vsf?.refs?.deleteClinicRegisterDefineTable?.reload();

    return res?.code === 200;
  };
  return (
    <Block
      style={{
        padding: '1.5rem',
      }}
    >
      <VSPromiseTable
        scroll={{
          // y: getScrollY(420),
          y: getScrollYRem(30),
          x: 1500,
        }}
        className="aspirin-table"
        id="deleteClinicRegisterDefineTable"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: '是否删除该号别！',
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
          columnProps: {
            width: 75,
          },
        }}
        onFetch={onFetch}
        onAdd={onAdd}
        onUpdate={onUpdate}
        addLinkPage={{
          linkPage: () => <ClinicRegisterDefineEdit />,
          modalProps: {
            title: '新增号别',
            className: 'aspirin-linkpage',
            bodyStyle: {
              backgroundColor: '#F9FAFC',
            },
          },
          onLinkPageValidate: async () => {
            try {
              const params =
                await vsf.refs.ClinicRegisterDefineForm?.validateFieldsReturnFormatValue();
              if (
                allByClinicRegister?.some(
                  (item) => item?.clinicLabel === params?.clinicLabel,
                )
              ) {
                message.error('号别名称重复');
                return false;
              }
              return true;
            } catch (e) {
              message.error('请输入必填项');
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <ClinicRegisterDefineEdit />,
          modalProps: {
            title: '修改号别',
            className: 'aspirin-linkpage',
            bodyStyle: {
              backgroundColor: '#F9FAFC',
            },
          },
          onLinkPageValidate: async (a) => {
            // message.success('修改成功');
            try {
              await vsf.refs.ClinicRegisterDefineForm?.validateFieldsReturnFormatValue();
              return true;
            } catch (e) {
              message.error('请输入必填项');
              return false;
            }
          },
          updateType: 'reload',
        }}
        searchConfig={{
          // labelwidth: 100,
          labelwidth: '20%',
          optionButtons: ['search'],
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 170px 0 2%">
              {dom}
            </SearchContainer>
          );
        }}
        labelAlign="left"
        pagination={false}
      >
        <VSTableColumn
          dataIndex={['clinicLabel']}
          title="号别"
          valueType="selectCode"
          width={150}
          search
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fixed={true}
          fieldProps={{
            dataSource: allByClinicRegister,
            fieldNames: { label: 'clinicLabel', value: 'id' },
            showSearch: true,
            searchKey: 'clinicLabelLike',
            allowClear: true,
          }}
          columnKey={'idIs'}
        />

        <VSTableColumn
          dataIndex={['clinicRegisterType', 'clinicType']}
          title="号类"
          valueType="select"
          width={90}
          search
          order={-8}
          columnKey={'clinicRegisterTypeIdIs'}
          dataSource={(value) => {
            // console.log(value);
            return value?.clinicType
              ? dataSource?.filter((item) =>
                  askSearch(value?.clinicType, item, 'clinicType'),
                )
              : dataSource;
          }}
          fieldProps={{
            fieldNames: { label: 'clinicType', value: 'id' },
            showSearch: true,
          }}
        />

        {/* <VSTableColumn
        dataIndex={['clinicDepartment', 'departmentName']}
        width={150}
        title="门诊科室"
        valueType="text"
        search
        order={-7}
      /> */}

        <VSTableColumn
          dataIndex={['clinicDepartment', 'departmentName']}
          width={150}
          title="门诊科室"
          valueType="select"
          search
          order={-7}
          columnKey={'clinicDepartmentIdIs'}
          fieldProps={{
            dataSource: handelList,
            fieldNames: { label: 'departmentName', value: 'id' },
            showSearch: true,
            placeholder: '请输入门诊科室',
            searchKey: 'name',
          }}
          searchKey="nameLike"
        />

        <VSTableColumn
          dataIndex={['outpSpecialClinic', 'outpSpecialClinicType']}
          title="学科分类"
          width={150}
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_TYPE_DICT}
        />

        <VSTableColumn
          dataIndex={['outpSpecialClinic']}
          title="门诊专科"
          width={230}
          valueType="select"
          // search
          columnKey={'outpSpecialClinicIdIs'}
          fieldProps={{
            dataSource: async (value) => {
              return value?.outpSpecialClinicName
                ? data?.filter((item) =>
                    askSearch(
                      value?.outpSpecialClinicName,
                      item,
                      'outpSpecialClinicName',
                    ),
                  )
                : data;
            },
            fieldNames: { label: 'outpSpecialClinicName', value: 'id' },
            showSearch: true,
            // searchKey: 'nameLike',
          }}
        />
        <VSTableColumn
          dataIndex={['outpSpecialClinic']}
          title="专科"
          width={200}
          valueType="select"
          search
          hideInTable
          columnKey={'outpSpecialClinicIdIs'}
          fieldProps={{
            dataSource: async (value) => {
              return value?.outpSpecialClinicName
                ? data?.filter((item) =>
                    askSearch(
                      value?.outpSpecialClinicName,
                      item,
                      'outpSpecialClinicName',
                    ),
                  )
                : data;
            },
            fieldNames: { label: 'outpSpecialClinicName', value: 'id' },
            showSearch: true,
            // searchKey: 'nameLike',
          }}
        />

        <VSTableColumn
          dataIndex={['id']}
          width={200}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['visitType']}
          width={100}
          title="出诊类型"
          valueType="select"
          dataSource={[
            { label: '门诊', value: 'OUTP' },
            { label: '急诊', value: 'ERP' },
            { label: '体检', value: 'PHYSICAL_EXAM' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSTableColumn
          dataIndex={['doctor']}
          title="医生"
          valueType="select"
          width={100}
          search
          order={-10}
          columnKey={'doctorIdIs'}
          fieldProps={{
            dataSource: handelList1,
            fieldNames: { label: 'staffName', value: 'id' },
            showSearch: true,
            searchKey: 'nameLike',
          }}
        />

        <VSTableColumn
          dataIndex={['doctor', 'titleCode']}
          title="职称"
          valueType="select"
          width={120}
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.TITLE_DICT,
          }}
        />

        <VSTableColumn
          dataIndex={['allowAppointIndicator']}
          title="支持预约"
          valueType="switch"
          // width={240}
          width={labelNumberWidth(6)}
          fieldProps={{}}
        />
        {/* //TODO */}

        <VSTableColumn
          dataIndex={['selfPaymentIndicator']}
          title="自费号"
          valueType="switch"
          width={120}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['stopIndicator']}
          title="启用"
          valueType="custom"
          width={80}
          fieldProps={{}}
          render={(text, record) => {
            return (
              <Switch
                defaultChecked={!record?.stopIndicator}
                onChange={async (val) => {
                  const res =
                    await vsf?.services?.OutpRegisterDefineController_updateClinicRegisterDefine_7ab8b4?.(
                      {
                        btoParam: { id: record?.id, stopIndicator: !val },
                      },
                    );
                  console.log(res, '5500_res');
                  if (res.code !== 200) {
                    // console.log(res, '5500_res');
                    vsf?.refs?.deleteClinicRegisterDefineTable?.reload();
                  } else {
                    val && message.success('启用成功');
                    !val && message.success('停用成功');
                  }
                }}
              />
            );
          }}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="拼音码"
          width={100}
          ellipsis
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['roomLocation']}
          title="诊室位置"
          width={200}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          // width={150}
          width={labelNumberWidth(12)}
          dataIndex={[
            'registerTimeDefine',
            'winterAmTimePeriod',
            'endTimePoint',
          ]}
          title="上午挂号截止时间"
          valueType="aspirinDateTimePicker"
          fieldProps={{
            type: 'single',
            format: 'HH:mm',
            allowEmpty: [true, true],
          }}
        />

        <VSTableColumn
          // width={150}
          width={labelNumberWidth(12)}
          dataIndex={[
            'registerTimeDefine',
            'winterPmTimePeriod',
            'endTimePoint',
          ]}
          title="下午挂号截止时间"
          valueType="aspirinDateTimePicker"
          fieldProps={{
            type: 'single',
            format: 'HH:mm',
            allowEmpty: [true, true],
          }}
        />

        <VSTableToolbar title="号别管理" style={{ paddingLeft: '25px' }}>
          <VSTableAddition children="添加" style={{ marginRight: '20px' }} />
        </VSTableToolbar>
      </VSPromiseTable>
    </Block>
  );
};
export default definePage(ClinicRegisterDefineTable);
