import React, { useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import Container from '@/module/Container';
import Tree from './tree';
import Table from './table';

import './index.less';

const DrugSpecificationStorage = (props) => {
  const [currentTreeItem, setCurrentTreeItem] = useState();

  const handelTreeChange = (value) => {
    if (currentTreeItem?.code === value?.code) return setCurrentTreeItem();
    setCurrentTreeItem(value);
  };

  return (
    <Container
      layout={{
        items: [
          {
            gap: '1rem',
            block: '20%',
          },
          {
            block: 1,
            bgColor: '#fff',
          },
        ],
      }}
      className="drug_specification_storage_wrapper common_lfc_style"
    >
      <Tree onChange={handelTreeChange} />
      <Table currentTreeItem={currentTreeItem} />
    </Container>
  );
};

export default definePage(DrugSpecificationStorage);
