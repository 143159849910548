import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_printWardExecuteOrder_75fe4c: {
    method: 'post',
    url: '/api/clinic-inp-adt/print-ward-execute-order',
    parameterFomatter: (data?: {
      eo: PrintWardOrderExecutedEoClinicInpAdtPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
