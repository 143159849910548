import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getPatientInpVisitInfo_9055d6: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-patient-inp-visit-info',
    parameterFomatter: (data?: {
      inpVisitId: number;
      patientId: number;
      displayId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitPatientInfoVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
});
