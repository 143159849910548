import vsf from '@vs/vsf-boot';
import { Button, Drawer, Form } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

import Content from './content';
import ContentInp from './content_inp';

const Index = (props, ref) => {
  const { onSubmit, type = 'outp' } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    setCurrent(v);
    setOpen(true);
  };
  const submit = useRef<any>(null);
  const handleSubmit = async () => {
    const values =
      await vsf.refs.orderOperationForm?.validateFieldsReturnFormatValue();
    const time = dayjs(values?.scheduleDateTime).format('YYYY-MM-DD');
    const text = values?.operationOrderDetail
      ?.map(
        (item) =>
          `${item?.operation ?? item?.operationName} ${
            item?.operationBodypartName || item?.operationBodypart
          }`,
      )
      .join(',');
    const orderText = `拟于 ${time} 行 ${text}`;
    onSubmit?.({
      orderStatus: 'OPEN',
      orderClass: 'OPERATION',
      orderText,
      uuid: current?.uuid ?? getUUID(),
      performDepartment: values?.operationRoomDepartment,
      submitDateTime: dayjs().valueOf(),
      memo: values?.memo,
      patientSpecialInfo: values?.patientSpecialInfo,
      operationOrder: {
        ...values,
        operationOrderDetail: values?.operationOrderDetail?.map(
          (item, index: number) => ({
            ...item,
            operationNumber: index,
          }),
        ),
      },
      orderDiagnosisIdList:
        values?.orderDiagnosisIdList?.map((item) => item?.id) ?? [],
    });
    onClose();
  };

  const Footer = () => {
    return (
      <div className="footer">
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{
            marginLeft: 12,
          }}
        >
          确定
        </Button>
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer"
      title="手术医嘱"
      placement="right"
      onClose={onClose}
      open={open}
      width={'70%'}
      destroyOnClose
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      footer={<Footer />}
    >
      {type === 'outp' ? (
        <Content
          data={current}
          onSubmit={handleSubmit}
          onClose={onClose}
          ref={submit}
        />
      ) : (
        <ContentInp
          data={current}
          onSubmit={handleSubmit}
          onClose={onClose}
          ref={submit}
        />
      )}
    </Drawer>
  );
};

export default React.forwardRef(Index);
