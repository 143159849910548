// 退费/明细
export const convertGetInpExpenseListByConditionRes2InpExpenseDetailListRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...((_.get(input, 'data') ?? [])?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.bedLabel = _.get(d, 'inpVisit.patientInHospitalBedDto.bed.bedLabel');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.name = _.get(d, 'inpVisit.inpVisitExtensionPatientInfoDto.name');
      obj.departmentName = _.get(d, 'orderWard.departmentName');
      obj.itemClass = _.get(d, 'itemClass');
      obj.itemName = _.get(d, 'itemName');
      obj.itemSpecification = _.get(d, 'itemSpecification');
      obj.unit = _.get(d, 'unit');
      obj.price = _.get(d, 'price');
      obj.amount = _.get(d, 'amount');
      obj.cost = _.get(d, 'cost');
      obj.performDepartmentName = _.get(d, 'performDepartment.departmentName');
      obj.memo = _.get(d, 'memo');
      obj.operator = _.get(d, 'operator.staffName');
      return obj;
    }) ?? []),
  );
  return output;
};
// 汇总
export const convertGetInpExpenseListForSummaryRes2InpExpenseSummaryListRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.wardName = _.get(d, 'wardName');
      obj.bedLabel = _.get(d, 'bedLabel');
      obj.displayId = _.get(d, 'displayId');
      obj.name = _.get(d, 'name');
      obj.outpTallyAmount = _.get(d, 'outpTallyAmount');
      obj.drugFee = _.get(d, 'drugFee');
      obj.medicineFee = _.get(d, 'medicineFee');
      obj.herbFee = _.get(d, 'herbFee');
      obj.bedFee = _.get(d, 'bedFee');
      obj.diagnosisFee = _.get(d, 'diagnosisFee');
      obj.treatFee = _.get(d, 'treatFee');
      obj.examFee = _.get(d, 'examFee');
      obj.labFee = _.get(d, 'labFee');
      obj.operationFee = _.get(d, 'operationFee');
      obj.materialFee = _.get(d, 'materialFee');
      obj.nursingFee = _.get(d, 'nursingFee');
      obj.otherFee = _.get(d, 'otherFee');
      obj.vaccineFee = _.get(d, 'vaccineFee');
      obj.diagnosisAndTreatFee = _.get(d, 'diagnosisAndTreatFee');
      obj.totalSubFee = _.get(d, 'totalSubFee'); // Bigdecimal 日期区间合计
      obj.totalFee = _.get(d, 'totalFee'); // Bigdecimal 累计合计
      return obj;
    }) ?? []),
  );
  return output;
};
