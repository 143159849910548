import SearchContainer from '@/module/Search';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Divider,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

import { labelNumberWidth } from '@/utils';

const PricePatternEdit = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const getDepartment = async () => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9(
        {
          qto: {
            stopIndicatorIs: false,
          },
        },
      );
    setDataSource(
      res.data.map((item) => ({
        value: item.id,
        label:
          item?.departmentName + item.branchInstitution?.institutionName
            ? `(${item.branchInstitution?.institutionName})`
            : '',
      })),
    );
  };
  useEffect(() => {
    getDepartment();
  }, []);
  return (
    <VSControlledForm
      vsid="14235"
      id="PricePattern"
      labelAlign="left"
      switchMode
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout
        key="1"
        columnCount={3}
        gutter={70}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['patternName']}
          label="模版名称"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            { required: true },
          ]}
          labelAlign="left"
          fieldProps={{
            onChange: ({ target }) => {
              vsf.refs.PricePattern.setFieldsValue({
                inputCode: askRomanAlphabet(target.value),
              });
            },
          }}
        />
        <VSFormItem
          name={['inputCode']}
          label="输入码"
          valueType="text"
          labelAlign="left"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            () => ({
              validator(_, value) {
                if (String(value).includes(',')) {
                  return Promise.reject('多音字待确认');
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['total']}
          label="合计"
          valueType="digit"
          labelAlign="left"
          fieldProps={{
            disabled: true,
            placeholder: '',
            width: '100%',
            addonAfter: '元',
          }}
        />
        <VSFormItem
          name={['createStaffId']}
          label="创建人"
          valueType="digit"
          labelAlign="left"
          fieldProps={{
            disabled: true,
            placeholder: '',
            width: '100%',
          }}
        />
        <VSFormItem
          name={['patternRangeType']}
          label="模板分类"
          valueType="select"
          rules={[{ required: true }]}
          labelAlign="left"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.BILL_PATTERN_CLASS_DICT}
        />

        <VSFormItem
          name={['departmentId']}
          label="模板应用科室"
          valueType="select"
          labelAlign="left"
          fieldProps={{
            dataSource,
          }}
        />
      </VSFormLayout>
      <VSFormLayout
        columnCount={3}
        gutter={70}
        labelWidth={labelNumberWidth(8)}
      >
        <VSFormItem
          name={['memo']}
          label="备注"
          valueType="text"
          labelAlign="left"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 200 },
          ]}
          fieldProps={{}}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(PricePatternEdit);
