import {
  Section,
  Todo,
  Tree,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  VSTableAddition,
  Modal,
} from '@vs/vsf-kit';
import React, { useState, useEffect, useCallback } from 'react';

import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const StandardApplicationFuncitonEdit = (props) => {
  const { open, value, handleClose, handleCloseWithReload } = props;
  const [bizApplication, setBizApplication] = useState({});
  const [targetKeys, setTargetKeys] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const onChange = (keys) => {
    setTargetKeys(keys);
  };

  const getApplication = async () => {
    const res =
      await vsf?.services?.ApplicationDtoController_getApplication_fbf818({
        template: true,
      });
    return res?.data ?? [];
  };

  const getInfo = useCallback(async () => {
    if (value?.template) {
      setBizApplication({
        templateApplicationId: {
          label: value?.name,
          value: value?.id,
        },
      });
    } else {
      const StandardApplication = await getApplication();
      const res =
        await vsf.services.ApplicationDtoController_getApplicationById_1c4e15?.(
          {
            id: value?.id,
          },
        );
      const target = res?.data;

      setBizApplication({
        id: target?.id,
        templateApplicationId: {
          label: StandardApplication?.find(
            (item) => item?.id === target?.templateApplicationId,
          )?.name,
          value: target?.templateApplicationId,
        },
        name: target?.name,
        departmentId: {
          id: target?.department?.id,
          departmentName: target?.department?.departmentName,
        },
      });
    }
  }, [value]);

  const onSubmit = async () => {
    const values = await vsf?.refs?.BusinessApplicationForm?.validateFields();

    const formatValues = {
      ...values,
      templateApplicationId: values?.templateApplicationId?.value,
    };
    const funcName = values?.id
      ? 'ApplicationBOController_updateApplication_62596e'
      : 'ApplicationBOController_createApplication_c83033';
    const res = await vsf?.services?.[funcName]?.({
      btoParam: {
        ...formatValues,
        departmentId:
          formatValues?.departmentId?.id ?? formatValues?.departmentId,
        template: false,
      },
      extApiParam: {},
    });
    if (res?.code === 200) {
      handleCloseWithReload?.();
    }
  };

  useEffect(() => {
    if (open) {
      getInfo();
    }
  }, [getInfo, open]);

  return (
    <Modal
      open={open}
      title={value?.template === true ? '新增业务工作站' : '编辑业务工作站'}
      className="aspirin-linkpage aspirin-linkpage-static-height"
      onOk={onSubmit}
      onCancel={handleClose}
    >
      <VSControlledForm id="BusinessApplicationForm" value={bizApplication}>
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['templateApplicationId']}
          label="标准工作台"
          valueType="select"
          fieldProps={{
            disabled: true,
          }}
        />

        <VSFormItem
          name={['name']}
          label="工作台名称"
          valueType="text"
          rules={[{ required: true }]}
          fieldProps={{}}
        />

        {/* <VSFormItem
          name={['departmentId']}
          label="选择科室"
          valueType="aspirinSelect"
          fieldProps={{
            loadData: async (params) => {
              const res =
                await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.();
              return res;
            },
            loadDataKey: 'branchInstitutionList',
            combineDataKey: 'departmentDtoList',
            fieldNames: {
              label: 'departmentName',
              value: 'id',
            },
            selectProps: {
              showSearch: true,
            },
            customGenObj: (node, parentData, rootData) => {
              return {
                label: `${node?.departmentName}(${parentData?.institutionName})`,
                value: node?.id,
              };
            },
          }}
        /> */}
        <VSFormItem
          name={['departmentId']}
          label="选择科室"
          valueType="codeTableSelect"
          dataSource={async (option) => {
            const res =
              await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.();

            const _res = res?.data?.branchInstitutionList
              ?.map((item) => {
                return {
                  ...item,
                  departmentDtoList: item?.departmentDtoList?.map((i) => {
                    return {
                      ...i,
                      institutionName: item?.institutionName,
                      departmentName: `${i?.departmentName}(${item?.institutionName})`,
                    };
                  }),
                };
              })
              .map((i) => i?.departmentDtoList)
              .flat();
            const _data = option?.departmentName
              ? _res?.filter((i) =>
                  askSearch(option.departmentName, i, 'departmentName'),
                )
              : _res;
            return _data ?? [];
          }}
          fieldNames={{
            label: 'departmentName',
            value: 'id',
          }}
          fieldProps={{
            showSearch: true,
          }}
        />
      </VSControlledForm>
    </Modal>
  );
};
export default definePage(StandardApplicationFuncitonEdit);
