import vsf, { definePage } from '@vs/vsf-boot';
import { Section } from '@vs/vsf-kit';
import React from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
const SystemConfigTypeTree = (props) => {
    return (
      <TreeMenu
          treeData={[
            { id: 1, name: '号类维护', code: '01' },
            { id: 3, name: '证件分类维护', code: '02' },
            { id: 4, name: '门诊专科维护', code: '03' },
            { id: 5, name: '预约平台字典维护', code: '04' },
            { id: 6, name: '挂号平台字典维护', code: '05' },
            { id: 8, name: '请假原因维护', code: '06' },
          ]}
          fieldNames={{ title: 'name', key: 'id', children: 'children' }}
          onSelect={(_value) => {
              props.onChange?.(_value);
          }}
          title="字典类别"
          reload={false}
      />
    );
};
export default definePage(SystemConfigTypeTree);
