import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_deleteClinicRegisterSchedule_4a853c: {
    method: 'post',
    url: '/api/outp-register/delete-clinic-register-schedule',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterScheduleBtoOutpRegisterServiceBto;
      extApiParam: {
        endDate: number;
      };
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4: {
    method: 'post',
    url: '/api/organization/get-all-by-department-branch-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentBranchInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterDefineWithScheduleQto_ca0524:
    {
      method: 'post',
      url: '/api/outp-register-define/get-all-by-clinic-register-define-with-schedule-qto',
      parameterFomatter: (data?: {
        qto: ClinicRegisterDefineWithScheduleQtoOutpRegisterDefinePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRegisterDefineWithRegisterScheduleVoOutpRegisterDefineEntranceWebVo[],
      ) => data,
    },
});
