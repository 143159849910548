import Block from '@/module/Block';
import SearchContainer from '@/module/Search';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import SelectForm, {
  askSelectFormInitValue,
} from '@/pages/Index/components/select_form';
import Tip from '@/pages/Index/components/func/Tip';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import { useDataStore, useFetch } from '@/pages/Index/components/func/hooks';
import { getScrollYRem, labelNumberWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Divider,
  message,
  Form,
  Modal,
} from '@vs/vsf-kit';
import { useUpdateEffect, useAsyncEffect } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const Extension = (props) => {
  const { form, formKey } = props;

  useUpdateEffect(() => {
    if (!props.value?.appointIndicator) {
      form.setFieldValue([...formKey, 'appointMethod'], undefined);
      form.setFieldValue([...formKey, 'needAppointIndicator'], false);
    }
  }, [props.value?.appointIndicator]);

  return (
    <>
      <VSFormItem
        name={['branchInstitutionId']}
        valueType="text"
        style={{
          display: 'none',
        }}
      />
      <VSFormItem
        name={['examClassId']}
        valueType="text"
        style={{
          display: 'none',
        }}
      />

      <VSFormLayout
        columnCount={3}
        gutter={50}
        labelWidth={labelNumberWidth(9)}
      >
        <VSFormItem
          name={['appointIndicator']}
          label="需预约"
          valueType="check"
          initialValue={false}
          rules={[{ required: true }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['appointMethod']}
          label="预约方式"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.APPOINT_METHOD_DICT}
          fieldProps={{
            disabled: !props?.value?.appointIndicator,
          }}
          rules={[
            {
              required: props?.value?.appointIndicator,
            },
          ]}
        />

        <VSFormItem
          name={['defaultRadiationFilmOption']}
          label="默认胶片选项"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.RAD_FILM_OPTION}
          fieldProps={{}}
        />
      </VSFormLayout>

      <VSFormLayout
        columnCount={1}
        gutter={50}
        labelWidth={labelNumberWidth(9)}
      >
        <VSFormItem
          name={['notice']}
          label="注意事项"
          valueType="textarea"
          rules={[
            {
              message: `注意事项不能超过65536个字`,
              max: 65536,
              min: 0,
            },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />

        <VSFormItem
          name={['simpleNotice']}
          label="简版注意事项"
          valueType="textarea"
          rules={[
            {
              message: `简版注意事项不能超过65536个字`,
              max: 65536,
              min: 0,
            },
          ]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />

        <VSFormItem
          name={['guideInfo']}
          label="导引单信息"
          valueType="textarea"
          rules={[{ message: `导引单信息不能超过500字`, max: 500 }]}
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 5 },
          }}
        />
      </VSFormLayout>
    </>
  );
};

const ExamCategoryDetails = (props) => {
  const [dataSource, setDataSource] = useState([]);

  const onSubmit = useFetch({
    fetch: async (params) => {
      const funcName =
        props.value.title === 'update' // 条件，id是否存在，也可以改成其它条件
          ? 'ExamClassDictionaryBOController_updateExamClass_1907ab'
          : 'ExamClassDictionaryBOController_createExamClass_2817f0';
      const { examClassDictionaryExtensionBtoList } = params;
      const res = await vsf?.services?.[funcName]?.({
        btoParam: {
          ...({
            ...params,
            itemClass: 'EXAM',
            examClassDictionaryExtensionBtoList:
              examClassDictionaryExtensionBtoList
                ? examClassDictionaryExtensionBtoList?.select?.map((key) => {
                    const { value, ...item } =
                      examClassDictionaryExtensionBtoList?.form?.[
                        `form_${key}`
                      ];
                    return {
                      ...item,
                      branchInstitutionId: value ?? key,
                      appointMethod: item?.appointMethod ?? null,
                      needAppointIndicator: item?.needAppointIndicator
                        ? true
                        : false,
                    };
                  })
                : [],
          } ?? {}),
        },
      });
      return res;
    },
    message: `${props.value.title === 'update' ? '修改' : '添加'}检查分类成功`,
    success: () => {
      vsf.refs.ExamClassTree.handleReload();
      props?.onDetailChange?.(false);
      // vsf.refs.ClassEdit?.resetFields();
    },
  });

  const fetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );

    if (res.code === 200) {
      setDataSource(
        res.data.branchInstitutionList.map((item) => ({
          label: item.institutionName,
          value: item.id,
        })),
      );
    }
  }, []);

  const getValue = async () => {
    const { data } =
      (await vsf.services.ExamClassDictionaryWithInstitutionDtoController_getExamClassWithInstitutionById_acabd3(
        {
          id: props.value.id,
        },
      )) || {};
    const state = askSelectFormInitValue({
      array: data?.examClassDictionaryExtensionDtoList?.toReversed(),
      select: ['branchInstitution', 'id'],
    });
    vsf.refs.ClassEdit.setFieldsValue({
      ...data,
      parentExamClassName: data?.parentExamClassName ?? '顶级',
      examClassDictionaryExtensionBtoList: state,
    });
    props?.onDetailChange?.(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  const onReset = () => {
    vsf.refs.ClassEdit?.resetFields();
    switch (props.value?.title) {
      case 'update':
        getValue();
        break;
      case 'add':
        vsf.refs.ClassEdit?.setFieldsValue({
          parentExamClassId: props.value.id ?? null,
          parentExamClassName: props.value.examClassName ?? '顶级',
        });
        break;
    }
  };

  useAsyncEffect(async () => {
    onReset?.();
  }, [props.value.id]);

  const footer = (
    <div
      className="aspirin-table-footer-button"
      style={{ justifyContent: 'flex-end', paddingTop: 24 }}
    >
      <ButtonAndKeyDown
        methodKey="altKey"
        ordinaryKey="S"
        type="primary"
        onClick={async () => {
          const value =
            await vsf?.refs?.ClassEdit?.validateFieldsReturnFormatValue?.();
          onSubmit?.(value);
        }}
      >
        保存
      </ButtonAndKeyDown>
    </div>
  );
  return (
    <Block
      height={getScrollYRem(16)}
      className="aspirin-block-no-padding"
      scroll={{ y: true }}
      footer={footer}
    >
      <VSControlledForm
        onSubmit={onSubmit}
        id="ClassEdit"
        // form={form}
        labelAlign="left"
        onChange={() => {
          props?.onDetailChange?.(true);
        }}
      >
        <SearchContainer.Header title="全院区通用">
          <Divider />
          <VSFormItem
            name={['id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />
          <VSFormItem
            name={['parentExamClassId']}
            label="上级类别ID"
            valueType="digit"
            style={{ display: 'none' }}
            fieldProps={{}}
          />
          <VSFormLayout
            key="1"
            columnCount={3}
            labelWidth={labelNumberWidth(9)}
          >
            <VSFormItem
              name={['parentExamClassName']}
              label="上级类别名称"
              valueType="text"
              initialValue={props.value?.parentExamClassName ?? '顶级'}
              fieldProps={{
                disabled: true,
              }}
            />
            <VSFormItem
              name={['examClassCode']}
              label="类别编码"
              valueType="text"
              fieldProps={{
                disabled: props.value?.title === 'update',
              }}
            />
            <VSFormItem
              name={['examClassName']}
              label="类别名称"
              valueType="text"
              fieldProps={{
                onChange: ({ target }) => {
                  vsf?.refs?.ClassEdit?.setFieldsValue({
                    inputCode: askRomanAlphabet(target.value),
                  });
                },
              }}
              rules={[{ required: true }]}
            />
          </VSFormLayout>
          <VSFormItem
            name={['examClassDictionaryExtensionBtoList', 'id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />
          <VSFormLayout
            key="2"
            columnCount={2}
            gutter={50}
            labelWidth={labelNumberWidth(9)}
          >
            <VSFormItem
              name={['inputCode']}
              label="拼音输入码"
              valueType="text"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    if (String(value).includes(',')) {
                      return Promise.reject('多音字待确认');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
                {
                  max: 20,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['radiationFilmOptionList']}
              label="胶片选项范围"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.RAD_FILM_OPTION}
              fieldProps={{ mode: 'multiple' }}
            />
          </VSFormLayout>
          <VSFormLayout
            key="3"
            columnCount={1}
            gutter={50}
            labelWidth={labelNumberWidth(9)}
          >
            <VSFormItem
              name={['notice']}
              label="注意事项"
              valueType="textarea"
              rules={[
                {
                  message: `注意事项不能超过65536个字`,
                  max: 4000,
                  min: 0,
                },
              ]}
              fieldProps={{
                autoSize: { minRows: 3, maxRows: 5 },
              }}
            />
            <VSFormItem
              name={['simpleNotice']}
              label="简版注意事项"
              valueType="textarea"
              rules={[
                {
                  message: `简版注意事项不能超过65536个字`,
                  max: 4000,
                  min: 0,
                },
              ]}
              fieldProps={{
                autoSize: { minRows: 3, maxRows: 5 },
              }}
            />
          </VSFormLayout>
        </SearchContainer.Header>
        <SearchContainer.Header title="各院区使用">
          <Divider />
          <VSFormLayout
            key="5"
            columnCount={1}
            gutter={50}
            labelWidth={labelNumberWidth(9)}
          >
            <VSFormItem
              name={['examClassDictionaryExtensionBtoList']}
              valueType="custom"
            >
              <SelectForm
                label="院区"
                formProps={{
                  labelAlign: 'left',
                }}
                selectLayoutProps={{
                  columnCount: 1,
                  // labelWidth: 130,
                  // labelWidth: '5%',
                  labelWidth: labelNumberWidth(9),
                  gutter: 50,
                }}
                selectAllText="全部院区"
                dataSource={dataSource}
                rules={[{ required: true }]}
              >
                <Extension />
              </SelectForm>
            </VSFormItem>
          </VSFormLayout>
        </SearchContainer.Header>
      </VSControlledForm>
    </Block>
  );
};
export default definePage(ExamCategoryDetails);
