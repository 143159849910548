// 就诊历史检查报告
export const CheckReportDetailBasicInfo = [
  {
    label: '检查单号',
    value: 'orderExamId',
  },
  {
    label: '开立时间',
    value: 'createDateTime',
  },
  {
    label: '预约时间',
    value: 'scheduleDateTime',
  },
  {
    label: '检查时间',
    value: 'examDateTime',
  },
  {
    label: '报告时间',
    value: 'reportDateTime',
  },
  {
    label: '审核时间',
    value: 'auditDateTime',
  },
  {
    label: '检查医师',
    value: 'reportDoctorId',
  },
  {
    label: '审核医生',
    value: 'auditDoctorId',
  },
];
//检查报告
export const CheckReportBasicInfo = [
  {
    label: '申请医师',
    value: 'doctorName',
  },
  {
    label: '检查时间',
    value: 'examDateTime',
  },
  {
    label: '报告时间',
    value: 'reportDateTime',
  },
];
// 就诊历史检验报告,没有用
export const ExamineReportDetailBasicInfo = [
  {
    label: '检查单号',
    value: 'orderExamId',
  },
  {
    label: '开立时间',
    value: 'createDateTime',
  },
  {
    label: '预约时间',
    value: 'scheduleDateTime',
  },
  {
    label: '采样时间',
    value: 'specimenSampleDateTime',
  },
  {
    label: '接收时间',
    value: 'specimenReceiveDateTime',
  },
  {
    label: '报告时间',
    value: 'reportDateTime',
  },
  {
    label: '样本类型',
    value: '差字段',
  },
  {
    label: '审核医生',
    value: 'auditDoctorId',
  },
  {
    label: '检验医生',
    value: '差字段',
  },
];
//检验报告
export const ExamineReportBasicInfo = [
  {
    label: '申请项目',
    value: 'orderText',
  },
  {
    label: '申请医师',
    value: 'doctorName',
  },
  {
    label: '申请时间',
    value: 'submitDateTime',
  },
  {
    label: '检验号',
    value: 'labItemId',
  },
  {
    label: '采样时间',
    value: 'specimenSampleDateTime',
  },
  {
    label: '标本条码',
    value: 'specimenBarcode',
  },
  {
    label: '检验者',
    value: 'performDoctorName',
  },
  {
    label: '报告时间',
    value: 'reportDateTime',
  },
  {
    label: '状态',
    value: 'labStatus',
  },
  {
    label: '诊断',
    value: 'diagnosis',
  },

  {
    label: '审核时间',
    value: 'auditTime',
  },
  {
    label: '样本',
    value: 'specimenName',
  },
  {
    label: '采样',
    value: 'specimenSampleNurseName',
  },
  {
    label: '审核',
    value: 'auditDoctorName',
  },
];
