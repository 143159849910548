import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React from 'react';

import FlexBlock from '@/module/cpoe/FlexBlock';
import Empty from '@/module/Empty';
import Block from '@/pages/Index/components/block_no_padding';

import Abnormal from './abnormal';
import Exam from './exam';
import Warning from './warning';

enum OrderTypeEnum {
  MEDICATION = 'MEDICATION',
  HANDLE = 'HANDLE',
  HERBS = 'HERBS',
  EXAMINE = 'EXAMINE',
  ASSAY = 'ASSAY',
  OPERATION = 'OPERATION',
  PATHOLOGY = 'PATHOLOGY',
}

const Index = (props) => {
  const { detail, type } = props;

  const handelType = (type) => {
    return detail?.orderItemDtoList?.filter((item) => {
      return item?.orderType === type;
    });
  };

  return (
    <div className="detail-block overview">
      <FlexBlock
        gap={['12px']}
        style={{
          paddingBottom: '12px',
        }}
        colSpan={[1, 1]}
      >
        <Block
          title="用药记录"
          titleStyle={{ fontWeight: '600' }}
          headerStyle={{ marginBottom: '16px' }}
          // style={{ paddingBottom: '20px', border: '1px solid red' }}
        >
          <VSPromiseTable
            scroll={{
              y: 210,
            }}
            size="small"
            className="hideScroll aspirin-table aspirin_history_tabel_bg_style_promise_table"
            id="1"
            // bordered
            onFetch={async (params) => {
              return {
                data:
                  detail?.patientVisitHistoryDetailOverview
                    ?.patientVisitHistoryDetailOrderList ?? [],
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              ellipsis
              dataIndex={['orderVo', 'orderText']}
              title="医嘱"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['orderDrugVo', 'dosage']}
              title="剂量"
              valueType="digit"
              fieldProps={{}}
              render={(_, value) => {
                return (
                  <div>
                    {value?.orderDrugVo?.dosage}
                    {value?.orderDrugVo?.dosageUnit}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['orderDrugVo', 'administration']}
              title="途径"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['orderDrugVo', 'frequency', 'frequency']}
              title="频次"
              valueType="text"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Block>
        <div className="other-block">
          <Block
            headerStyle={{ marginBottom: '16px' }}
            title="检验异常值"
            titleStyle={{ fontWeight: '600' }}
            scroll={{ y: true }}
            mainStyle={{ maxHeight: '250px' }}
          >
            <Abnormal
              data={
                detail?.patientVisitHistoryDetailOverview
                  ?.patientVisitHistoryDetailLabDetailList
              }
            />
            <div className="other-block_text">检验</div>

            <Exam
              type="ASSAY"
              data={
                detail?.patientVisitHistoryDetailOverview
                  ?.patientVisitHistoryDetailLabList
              }
            />
          </Block>
          {/* <Block title="检验" titleStyle={{ fontWeight: '600' }}>
            <Exam type="ASSAY" data={handelType(OrderTypeEnum.ASSAY)} />
          </Block> */}
          {/* <Block title="检验" titleStyle={{ fontWeight: '600' }}> */}

          {/* </Block> */}
        </div>
      </FlexBlock>
      <FlexBlock
        gap={['12px']}
        style={{
          paddingBottom: '12px',
        }}
        colSpan={[1, 1]}
      >
        <Block
          headerStyle={{ marginBottom: '16px' }}
          title="治疗处置"
          titleStyle={{ fontWeight: '600' }}
        >
          <VSPromiseTable
            scroll={{
              y: 210,
            }}
            size="small"
            className="aspirin-table aspirin_history_tabel_bg_style_promise_table"
            id="overviewDisposal"
            onFetch={async (params) => {
              // return { data: handelType(OrderTypeEnum.HANDLE) };
              return {
                data: detail?.overviewList ?? [],
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title="主键"
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['administration']}
              title="途径"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
            />

            <VSTableColumn
              dataIndex={['performDays']}
              title="天数"
              valueType="digit"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Block>

        <div className="other-block">
          <Block
            headerStyle={{ marginBottom: '16px' }}
            title="检查异常信息"
            titleStyle={{ fontWeight: '600' }}
            scroll={{ y: true }}
            mainStyle={{ maxHeight: '250px' }}
            // mainStyle={{
            //   overflowY: 'scroll',
            //   border: '1px solid red',
            //   height: '100%',
            // }}
          >
            <Warning
              data={
                detail?.patientVisitHistoryDetailOverview
                  ?.examDetailAbnormalList
              }
            />
            <div className="other-block_text">检查</div>
            <Exam
              type="EXAMINE"
              data={detail?.patientVisitHistoryDetailOverview?.examDetailList}
              // className="exam-block-alone"
            />
          </Block>
          {/* <Block title="检查" titleStyle={{ fontWeight: '600' }}> */}

          {/* </Block> */}
        </div>
      </FlexBlock>
      <FlexBlock gap={['12px']}>
        <Block
          headerStyle={{ marginBottom: '16px' }}
          title="手术"
          titleStyle={{ fontWeight: '600' }}
        >
          <VSPromiseTable
            scroll={{
              y: 210,
            }}
            size="small"
            className="aspirin-table aspirin_history_tabel_bg_style_promise_table"
            id="1"
            onFetch={async (params) => {
              return {
                data: detail?.operationList ?? [],
                total: detail?.operationList?.length ?? 0,
              };
            }}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['startDateTime']}
              title="手术时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="手术名称"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['operationLevel']}
              title="等级"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.OPERATION_SCALE_DICT}
            />

            <VSTableColumn
              dataIndex={['staffName']}
              title="主刀医生"
              valueType="text"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Block>
        <Block
          title="输血"
          headerStyle={{ marginBottom: '16px' }}
          titleStyle={{ fontWeight: '600' }}
        >
          <VSPromiseTable
            scroll={{
              y: 210,
            }}
            size="small"
            className="aspirin-table aspirin_history_tabel_bg_style_promise_table"
            id="1"
            onFetch={async (params) => {
              return {
                data: [],
                total: 0,
              };
            }}
            // showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['title']}
              title="时间"
              editable={false}
              valueType="digit"
            />

            <VSTableColumn
              dataIndex={['quantity']}
              title="输血医嘱"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['time']}
              title="护士"
              editable={false}
              valueType="text"
            />
          </VSPromiseTable>
        </Block>
      </FlexBlock>
    </div>
  );
};

export default Index;
