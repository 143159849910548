import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  DepartmentBOController_updateDepartment_da3dee: {
    method: 'post',
    url: '/api/organization/updateDepartment',
    parameterFomatter: (data?: {
      btoParam: UpdateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_deleteApplication_cc9a1c: {
    method: 'post',
    url: '/api/application/deleteApplication',
    parameterFomatter: (data?: {
      btoParam: DeleteApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ApplicationBOController_createApplication_c83033: {
    method: 'post',
    url: '/api/application/createApplication',
    parameterFomatter: (data?: {
      btoParam: CreateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationDtoController_getByApplicationClass_ac6d7e: {
    method: 'post',
    url: '/api/application/getByApplicationClass',
    parameterFomatter: (data?: { applicationClass: string }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto[]) =>
      data,
  },
});
