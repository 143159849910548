import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_queryByDrugDictionaryBaseQtoWaterfall_02480b: {
    method: 'post',
    url: '/api/drug-drug/query-by-drug-dictionary-base-qto-waterfall',
    parameterFomatter: (data?: {
      qto: DrugDictionaryBaseQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugDictionaryBaseVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
