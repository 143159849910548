import vsf from '@vs/vsf-boot';
import { definePage } from '@vs/vsf-boot';
import { useMount, useRequest } from 'ahooks';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
  message,
  Badge,
  Tooltip,
} from '@vs/vsf-kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce, isObject } from 'lodash';
import {
  drugStaffList,
  getDetailById,
  getTableData,
  unUseDispenseWindowNo,
  useDispenseWindowNo,
  windowDetail,
  getRefundState,
} from './service';
import DrugInfo from './drug_info';
import { toDispenseWindowList } from '../pharmacyDispensesMedicine/Service';
import { getScrollY } from '@/utils';
import UserInfo from './UserInfo';
import Tip from '@/pages/Index/components/func/Tip';

const InfoAndList = ({ tabaleParam, qureyList, ...props }) => {
  const { staffInfo } = props?.stores?.user;
  useEffect(() => onSetPrepareDrugPeopleForm(), [staffInfo]);
  const onSetPrepareDrugPeopleForm = () => {
    vsf?.refs?.centreMedicineDispensingForm?.setFieldsValue?.({
      prepareDrugStaffId: staffInfo?.id,
      secondPrepareDrugStaffId: staffInfo?.id,
    });
  };
  const { data: detailData, run } = useRequest(getDetailById, {
    manual: true,
  });
  const { data: staffList } = useRequest(drugStaffList);

  const onTableFetch = useCallback(async () => {
    return getTableData({ id: tabaleParam?.waitMasterId });
  }, [tabaleParam]);
  //debounce(, 1000)
  const handelConfirmDistribute = async (params) => {
    let supplyIndicatorArr = vsf?.refs?.centreMedicineDispensingListTable
      ?.getValues()
      ?.filter((f) => !f.supplyIndicator);
    if (supplyIndicatorArr?.length >= 1) {
      let supplyIndicatorStr = '该药品：';
      supplyIndicatorArr?.forEach((element) => {
        if (!element.supplyIndicator)
          supplyIndicatorStr += `${element.orderText} `;
      });
      supplyIndicatorStr += `已被屏蔽，不可发药`;
      message.error(supplyIndicatorStr);
      return;
    }
    var input = await vsf.refs?.centreMedicineDispensingForm?.getFieldsValue();
    if (!tabaleParam?.waitMasterId) return message.error('请选中患者');
    if (!input?.prepareDrugStaffId) return message.error('请选择配药人1');
    if (!input?.secondPrepareDrugStaffId) return message.error('请选择配药人2');
    Tip({
      content: '是否确认发药',
      onOk: async () => {
        const param = {};
        param.waitDispenseMasterId = tabaleParam?.waitMasterId; // Tips: 以下是你选择的请求函数
        param.prepareDrugStaffId = isObject(input?.prepareDrugStaffId)
          ? input?.prepareDrugStaffId?.secondPrepareDrugStaffId
          : input?.prepareDrugStaffId;
        param.secondPrepareDrugStaffId = isObject(
          input?.secondPrepareDrugStaffId,
        )
          ? input?.secondPrepareDrugStaffId?.secondPrepareDrugStaffId
          : input?.secondPrepareDrugStaffId;
        const res =
          await vsf.services?.DrugPrescriptionDispenseController_dispensingInpHerbPrescriptions_cca5a4?.(
            {
              dispensingParam: param,
            },
          );
        if (res?.code === 200) message.success('发药成功');
        qureyList();
        vsf?.refs?.centreMedicineDispensingListTable?.reload();
      },
    });
  };
  return (
    <>
      <div className="info">
        <div className="patient_info">
          <UserInfo {...props} inpVisitId={tabaleParam?.inpVisitId} />
        </div>
        <div className="split_line" />
        <DrugInfo id={tabaleParam?.waitMasterId} />
      </div>
      <div className="list">
        <VSPromiseTable
          vsid="99845"
          scroll={{
            x: 1200,
            y: getScrollY(450),
          }}
          id="centreMedicineDispensingListTable"
          onFetch={onTableFetch}
          className="aspirin-table"
          rowClick={{
            rowClickType: {
              action: 'click',
            },
          }}
          pagination={false}
        >
          <VSTableColumn
            width={282}
            dataIndex={['orderText']}
            title="名称"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              ],
            }}
            fieldProps={{}}
            render={(_, values) => {
              if (values?.isStockInsufficiency && !values?.supplyIndicator) {
                return (
                  <div
                    className="text-overflow-omit"
                    style={{ color: '#E34A4E' }}
                  >
                    <Tooltip title={values.orderText}>
                      {values.orderText}
                    </Tooltip>
                  </div>
                );
              } else {
                return (
                  <div className="text-overflow-omit">
                    <Tooltip title={values.orderText}>
                      {values.orderText}
                    </Tooltip>
                  </div>
                );
              }
            }}
          />

          <VSTableColumn
            width={160}
            dataIndex={['amountAndUnit']}
            title="单贴量"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '数量的值不合法',
                  type: 'number',
                  min: 0,
                  max: undefined,
                },
              ],
            }}
            fieldProps={{}}
            // render={(_, value) => {
            //   return (
            //     <>
            //       {value?.amount + value?.amountPackageUnit.replace(/\d/g, '')}
            //     </>
            //   );
            // }}
          />

          <VSTableColumn
            width={120}
            dataIndex={['medicalInsuranceType']}
            title="医保"
            valueType="select"
            dataSource={[
              { label: '甲', value: 'ALPHA' },
              { label: '乙', value: 'BETA' },
              { label: '丙', value: 'GAMMA' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            width={160}
            dataIndex={['amountPackageUnit']}
            title="给药数量"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            width={160}
            align="right"
            dataIndex={['price']}
            title="单价"
            valueType="digit"
            fieldProps={{}}
            render={(_, value) => {
              return <div>{Number(value?.price || 0).toFixed(2)}</div>;
            }}
          />

          <VSTableColumn
            width={160}
            align="right"
            dataIndex={['charge']}
            title="金额"
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            width={220}
            dataIndex={['useDescription']}
            title="处理方法"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_DECOCTION_DICT}
          />
        </VSPromiseTable>
        <div className="footer">
          <VSForm vsid="84627" id="centreMedicineDispensingForm">
            <VSFormLayout key="0" columnCount={2}>
              <VSFormItem
                name={['prepareDrugStaffId']}
                label="配药人1"
                valueType="select"
                rules={[
                  {
                    required: true,
                  },
                ]}
                dataSource={staffList}
                fieldProps={{
                  width: 160,
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'secondPrepareDrugStaffId',
                }}
              />

              <VSFormItem
                name={['secondPrepareDrugStaffId']}
                label="配药人2"
                valueType="select"
                rules={[
                  {
                    required: true,
                  },
                ]}
                dataSource={staffList}
                fieldProps={{
                  width: 160,
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'secondPrepareDrugStaffId',
                }}
              />
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={0}></VSFormLayout>
          </VSForm>
          <div>
            <Space size={4}>
              <Button type="primary" onClick={handelConfirmDistribute}>
                发药确定
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </>
  );
};

export default definePage(InfoAndList);
