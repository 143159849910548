import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_getBillingDetailByCostId_5be372: {
    method: 'post',
    url: '/api/inp-billing/get-billing-detail-by-cost-id',
    parameterFomatter: (data?: {
      inpVisitId: number;
      costId: number;
      orderCostIndicator: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailSimpleVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_adjustBillingDetailAmount_df7221: {
    method: 'post',
    url: '/api/inp-billing/adjust-billing-detail-amount',
    parameterFomatter: (data?: {
      adjustBillingDetailEo: AdjustBillingDetailEoInpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
