import {
  Section,
  VSDataProvider,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSForm,
} from '@vs/vsf-kit';
import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { labelNumberWidth } from '@/utils';

const DepartmentEdit = (props) => {
  const { selectDepartmentList } = props;
  /**
   * info
   * @type {any}
   */
  /**
   * hasId
   * @param {any} data
   * @returns {any}
   */
  const hasId = (data) => {
    return data?.id;
  };

  const [departmentList, setDepartmentList] = useState();
  const [branchInstitutionId, setBranchInstitutionId] = useState();

  useEffect(() => {
    if (props.title === 'update') {
      vsf.services
        .DepartmentDtoController_getById_3da0f7({
          id: props?.value?.id,
        })
        .then((res) => {
          vsf.refs.DepartmentForm.setFieldsValue(res.data);
          handelList();
        });
    } else {
      if (props?.selectedRows) {
        vsf.services
          .DepartmentDtoController_getById_3da0f7({
            id: props?.selectedRows,
          })
          .then((res) => {
            const { branchInstitution, departmentCode } = res.data;
            handelList(
              branchInstitution?.upInstitutionId,
              branchInstitution?.id,
            );
            vsf.refs.DepartmentForm?.setFieldsValue({
              upDepartmentCode: departmentCode,
              branchInstitution: {
                upInstitutionName: branchInstitution?.upInstitutionName,
                institutionName: branchInstitution?.institutionName,
                institutionId: branchInstitution?.upInstitutionId,
                branchInstitutionId: branchInstitution?.id,
              },
            });
          });
      } else {
        handelList(props?.info?.upValue?.[0]?.id, props?.info?.id);
        vsf.refs?.DepartmentForm?.setFieldsValue({
          branchInstitution: {
            upInstitutionName: props?.info?.upValue?.[0]?.institutionName,
            institutionName: props?.info?.institutionName,
          },
        });
      }
    }

    // handelList();
  }, []);

  const handelList = async (upInstitutionId, id) => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentQto_1c2a91?.(
        {
          qto: {
            institutionIdIs: upInstitutionId,
            branchInstitutionIdIs: id,
            // from: params.pagination?.from ?? 0,
            // // size: params.pagination?.size ?? 20,
            // orderList: params.orderList, // 排序结果
            // ...params.search, // 搜索结果
            // ...params.filters, // 过滤器结果
            // ..._params,
            // institutionIdIs: params.extra?.upInstitutionId ?? params.extra.id,
            // branchInstitutionIdIs: params.extra?.upInstitutionId
            //   ? params.extra.id
            //   : null,
          },
        },
      );
    const _res = res?.data?.map((item) => {
      return {
        label: item?.departmentName,
        value: item?.departmentCode,
      };
    });

    setDepartmentList(_res);
  };

  return (
    <VSControlledForm
      id="DepartmentForm"
      vsid="49532"
      // value={props.value}
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
      labelAlign="left"
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormItem
        name={['branchInstitution', 'institutionId']}
        label="institutionId"
        style={{ display: 'none' }}
        valueType="text"
        fieldProps={{}}
      />
      <VSFormItem
        name={['branchInstitution', 'branchInstitutionId']}
        label="branchInstitutionId"
        style={{ display: 'none' }}
        valueType="text"
        fieldProps={{}}
      />
      <VSFormItem
        name={['serialNumber']}
        label="serialNumber"
        style={{ display: 'none' }}
        valueType="text"
        fieldProps={{}}
      />
      <VSFormLayout
        labelWidth={labelNumberWidth(7)}
        columnCount={3}
        gutter={[75]}
      >
        <VSFormItem
          name={['upDepartmentCode']}
          label="上级科室"
          valueType="select"
          rules={[
            {
              message: '上级科室长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ]}
          fieldProps={{
            disabled: props.title === 'update',
            showSearch: true,
          }}
          dataSource={departmentList}
        />

        <VSFormItem
          name={['branchInstitution', 'upInstitutionName']}
          label="医疗机构"
          valueType="text"
          rules={[{ required: true }]}
          fieldProps={{
            disabled: true,
          }}
          // transform={(value) => {
          //   return { branchInstitutionId: value };
          // }}
        />

        <VSFormItem
          name={['branchInstitution', 'institutionName']}
          label="院区"
          valueType="text"
          rules={[
            {
              message: 'institution_name长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
            { required: true },
          ]}
          fieldProps={{ disabled: true }}
        />

        <VSFormItem
          name={['departmentCode']}
          label="科室代码"
          valueType="text"
          rules={[
            {
              message: '科室代码长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
            { required: true },
          ]}
          fieldProps={{ disabled: props.title === 'update' }}
        />

        <VSFormItem
          name={['departmentName']}
          label="科室名称"
          valueType="text"
          rules={[
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 200,
            },
            { required: true },
          ]}
          fieldProps={{
            onChange: debounce(
              ({ target }) => {
                vsf.refs.DepartmentForm.setFieldsValue({
                  inputCode: askRomanAlphabet(target.value),
                });
              },
              30,
              { trailing: true },
            ),
          }}
        />
        <VSFormItem
          name={['inputCode']}
          label="输入码"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            { required: true },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['departmentAlias']}
          label="科室别名"
          valueType="text"
          rules={[
            {
              message: '科室别名长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['clinicAttribute']}
          label="临床科室属性"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_ATTR_DICT}
          fieldProps={{ showSearch: true }}
        />

        <VSFormItem
          name={['outpOrInp']}
          label="门诊住院属性"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DEPT_OI_ATTR_DICT}
          fieldProps={{ showSearch: true }}
        />

        <VSFormItem
          name={['internalOrSurgery']}
          label="内外科标识"
          valueType="select"
          rules={[
            {
              message: '内外科标志长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ]}
          dataSource={vsf.stores.dbenums.enums.DEPT_IS_ATTR_DICT}
          fieldProps={{ showSearch: true }}
        />

        <VSFormItem
          name={['departmentAddress']}
          label="科室位置"
          valueType="text"
          rules={[
            {
              message: '科室位置长度不合法',
              type: 'string',
              min: 0,
              max: 80,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['stopIndicator']}
          label="状态"
          valueType="radio"
          initialValue={false}
          fieldProps={{}}
          dataSource={[
            { label: '在用', value: false },
            { label: '停用', value: true },
          ]}
        />
      </VSFormLayout>
      <VSFormLayout labelWidth={labelNumberWidth(7)} columnCount={1}>
        {/* <div className="antd-formitem-contnent-border"> */}
        <VSFormItem
          className="antd-formitem-contnent-border"
          name={['memo']}
          label="备注"
          valueType="textarea"
          rules={[
            { message: '备注长度不合法', type: 'string', min: 0, max: 1000 },
          ]}
          fieldProps={{
            height: 220,
          }}
        />
        {/* </div> */}
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(DepartmentEdit);
