import vsf, { definePage } from '@vs/vsf-boot';
import {
  Column,
  Columns,
  Section,
  Todo,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React from 'react';
import './index.less';
import { isMinScreen } from '@/config';
import SearchContainer from '@/module/Search';

const PatientOperateLogSearch = (props) => {
  const onFetch = async (params) => {
    const res =
      await vsf?.services?.PatientOperateLogController_getAllByOperationLogPatientQto_9840af?.(
        {
          qto: {
            sourceIdIs: params?.search?.sourceId,
            sceneIs: 'PATIENT_MODIFY',
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  /**
   * 行点击信息转换为变更后的患者主索引修改信息
   * @param {object} params
   * @returns {any}
   */
  const convertTopatientModifyInfoAfter = (params) => {
    console?.log(params?.patientInfoAfter);
    return params?.patientInfoAfter;
  };
  /**
   * 点击的行信息转换为变更前的患者主索引修改信息
   * @param {object} params
   * @returns {any}
   */
  const convertToPatientModifyInfoBefore = (params) => {
    return params?.patientInfoBefore;
  };
  /**
   * convertGetListOperationLogPatientVoBySourceIdAndSceneAndOperateTypeRes2VSPromiseTablePatientOperateLogRo
   * @param {any} input
   * @returns {any}
   */
  const convertGetListOperationLogPatientVoBySourceIdAndSceneAndOperateTypeRes2VSPromiseTablePatientOperateLogRo =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ...(_.get(input, 'data')?.map((d) => {
          const obj = {};
          obj.updatedAt = _.get(d, 'updatedAt'); // 修改日期
          obj.operator = _.get(d, 'operator'); // 操作人
          return obj;
        }) ?? []),
      );
      return output;
    };
  return (
    <Section>
      {/* <Todo
        type="block"
        content="表单中的diff字段的颜色，需要判断表单中的字段名称是否在隐藏字段changeFieldList数组值中， 如果存在，设置为红色"
      /> */}

      <div className="vSPromiseTablePatientOperateLog">
        <Columns columns={10} style={{ height: '100%' }}>
          <Column colSpan={4} style={{ height: '100%', width: '100%' }}>
            <VSPromiseTable
              vsid="24548"
              id="vSPromiseTablePatientOperateLog"
              className="aspirin-table
              aspirin-table-header-background-color
              aspirin-table-row-hover-background-color
              aspirin-table-body-empty-transparent-background-color
              patient_update_log_table table_overflow_384
              "
              onFetch={onFetch}
              rowClick={{
                onRowClick: (rowData) => {
                  console?.log('rowData', rowData);
                  vsf?.refs?.vSFormPatientModifyDetailBefore?.setFieldsValue?.(
                    rowData?.patientInfoBefore,
                  );
                  vsf?.refs?.vSFormPatientModifyDetailAfter?.setFieldsValue?.(
                    rowData?.patientInfoAfter,
                  );
                },
              }}
              searchConfig={{
                labelWidth: 60,
                labelAlign: 'left',
                span: 12,
                labelWidth: 90,
                collapsed: false,
                collapseRender: false,
                searchButtonProps: {
                  size: 'large',
                  className: 'icon16',
                },
                resetButtonProps: {
                  size: 'large',
                  className: 'icon16',
                },
              }}
              scroll={{
                y: 0,
              }}
            >
              <VSTableColumn
                dataIndex={['sourceId']}
                title="患者ID"
                valueType="text"
                search
                order={0}
                columnKey={'sourceId'}
                hideInTable
                fieldProps={{
                  className: 'width160',
                  // labelWidth: 50,
                }}
              />

              <VSTableColumn
                dataIndex={['operateTime']}
                title="操作时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              />

              <VSTableColumn
                dataIndex={['operator']}
                title="操作员"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '操作人长度不合法',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ],
                }}
                fieldProps={{}}
              />
            </VSPromiseTable>
          </Column>
          <Column
            colSpan={3}
            style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}
          >
            <VSForm
              vsid="49843"
              id="vSFormPatientModifyDetailBefore"
              readonly={true}
              onFetch={async (params) => {}}
            >
              <VSFormLayout key="0" columnCount={1}>
                <VSFormItem
                  name={['displayId']}
                  label="患者显示ID"
                  valueType="text"
                  rules={[
                    {
                      message: '患者显示ID长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['externalId']}
                  label="外部患者ID"
                  valueType="text"
                  rules={[
                    {
                      message: '外部患者ID长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['name']}
                  label="姓名"
                  valueType="text"
                  rules={[
                    {
                      message: '姓名长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['gender']}
                  label="性别"
                  valueType="select"
                  rules={[
                    {
                      message: '性别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                />

                <VSFormItem
                  name={['birthday']}
                  label="出生日期"
                  valueType="date"
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['idNumber']}
                  label="身份证号"
                  valueType="text"
                  rules={[
                    {
                      message: '身份证号长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['chargeType']}
                  label="费别"
                  valueType="select"
                  rules={[
                    {
                      message: '费别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{
                    dataSource: vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                  }}
                />

                <VSFormItem
                  name={['identityType']}
                  label="人员类别"
                  valueType="select"
                  rules={[
                    {
                      message: '人员类别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
                />

                <VSFormItem
                  name={['inpVisitNumber']}
                  label="住院号"
                  valueType="text"
                  rules={[
                    {
                      message: '住院号长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['insuranceType']}
                  label="医保类型"
                  valueType="select"
                  rules={[
                    {
                      message: '医保类型长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.INSUR_TYPE_DICT}
                />

                <VSFormItem
                  name={['insuranceCode']}
                  label="医保编码"
                  valueType="text"
                  rules={[
                    {
                      message: '医保编码长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['cellphone']}
                  label="手机号码"
                  valueType="text"
                  rules={[
                    {
                      message: '手机号码长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['phoneNumber']}
                  label="联系电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['contactPhone']}
                  label="联系人电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系人电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['relationship']}
                  label="关系"
                  valueType="select"
                  rules={[
                    {
                      message: '关系长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.RELATIONSHIP_DICT}
                />

                <VSFormItem
                  name={['postcode']}
                  label="现住址邮编"
                  valueType="text"
                  rules={[
                    {
                      message: '邮编长度不合法',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['currentAddress']}
                  label="现住址详情"
                  valueType="address"
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['citizenship']}
                  label="国籍"
                  valueType="text"
                  rules={[
                    {
                      message: '国籍长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />
              </VSFormLayout>
            </VSForm>
          </Column>
          <Column
            colSpan={3}
            style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}
          >
            <VSForm
              vsid="62541"
              id="vSFormPatientModifyDetailAfter"
              readonly={true}
            >
              <VSFormLayout key="0" columnCount={1}>
                <VSFormItem
                  name={['displayId']}
                  label="患者ID"
                  valueType="text"
                  rules={[
                    {
                      message: '患者显示ID长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['externalId']}
                  label="外部患者ID"
                  valueType="text"
                  rules={[
                    {
                      message: '外部患者ID长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['name']}
                  label="姓名"
                  valueType="text"
                  rules={[
                    {
                      message: '姓名长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['gender']}
                  label="性别"
                  valueType="select"
                  rules={[
                    {
                      message: '性别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                />

                <VSFormItem
                  name={['birthday']}
                  label="出生日期"
                  valueType="date"
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['idNumber']}
                  label="身份证号"
                  valueType="text"
                  rules={[
                    {
                      message: '身份证号长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['chargeType']}
                  label="费别"
                  valueType="select"
                  rules={[
                    {
                      message: '费别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                />

                <VSFormItem
                  name={['identityType']}
                  label="人员类别"
                  valueType="select"
                  rules={[
                    {
                      message: '人员类别长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
                />

                <VSFormItem
                  name={['inpVisitNumber']}
                  label="住院号"
                  valueType="text"
                  rules={[
                    {
                      message: '住院号长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['insuranceType']}
                  label="医保类型"
                  valueType="select"
                  rules={[
                    {
                      message: '医保类型长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.INSUR_TYPE_DICT}
                />

                <VSFormItem
                  name={['insuranceCode']}
                  label="医保编码"
                  valueType="text"
                  rules={[
                    {
                      message: '医保编码长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['cellphone']}
                  label="手机号码"
                  valueType="text"
                  rules={[
                    {
                      message: '手机号码长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['phoneNumber']}
                  label="联系电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['contactPhone']}
                  label="联系人电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系人电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['relationship']}
                  label="关系"
                  valueType="select"
                  rules={[
                    {
                      message: '关系长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                  dataSource={vsf.stores.dbenums?.enums?.RELATIONSHIP_DICT}
                />

                <VSFormItem
                  name={['postcode']}
                  label="现住地址邮编"
                  valueType="text"
                  rules={[
                    {
                      message: '邮编长度不合法',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['currentAddress']}
                  label="现住址详情"
                  valueType="address"
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['citizenship']}
                  label="国籍"
                  valueType="text"
                  rules={[
                    {
                      message: '国籍长度不合法',
                      type: 'string',
                      min: 0,
                      max: 1024,
                    },
                  ]}
                  fieldProps={{}}
                />
              </VSFormLayout>
            </VSForm>
          </Column>
        </Columns>
      </div>
    </Section>
  );
};
export default definePage(PatientOperateLogSearch);
