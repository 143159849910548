import { disposalOrderClassDataSource } from './datasource';

/**
 * 剂量转换
 * @param data
 * @returns
 */
export const dosageConvert = (data) => {
  const { orderClass, drugOrder, herbOrder } = data;

  const CLASS = {
    DRUG: () => {
      return {
        value: drugOrder?.dosage,
        unit: drugOrder?.dosageUnit,
      };
    },
    HERB: () => {
      return {
        value: herbOrder?.herbDosage,
        unit: herbOrder?.herbDosage ? 'ml' : '',
      };
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 用量转换
 * @param data
 * @returns
 */
export const usageConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return {
        value: drugOrder?.usage,
        unit: drugOrder?.usageUnit,
      };
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 途径转换
 * @param data
 * @returns
 */
export const administrationConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.administration;
    },
    HERB: () => {
      return herbOrder?.useWay;
    },
  };

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  if (isDisposalOrder) {
    return disposalOrder?.administration;
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 滴速转换
 * @param data
 * @returns
 */
export const dripSpeedConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.dripSpeed;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 频次转换
 * @param data
 * @returns
 */
export const frequencyConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.frequency;
    },
    HERB: () => {
      return herbOrder?.frequency;
    },
  };

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  if (isDisposalOrder) {
    return disposalOrder?.frequency;
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 数量转换
 * @param data
 * @returns
 */
export const totalAmountConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, disposalOrder } = data;

  const CLASS = {
    DRUG: () => {
      return {
        value: drugOrder?.totalAmount,
        unit: drugOrder?.packageUnit,
      };
    },
    HERB: () => {
      return {
        value: herbOrder?.totalAmount,
        unit: herbOrder?.dosageUnit,
      };
    },
  };

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  if (isDisposalOrder) {
    return disposalOrder?.amount;
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 用法转换
 * @param data
 * @returns
 */
export const descriptionConvert = (data) => {
  const { orderClass, drugOrder, herbOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.useDescription;
    },
    HERB: () => {
      return herbOrder?.useDescription;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 有效期转换
 * @param data
 * @returns
 */
export const repeatValidDaysConvert = (data) => {
  const { orderClass, drugOrder, herbOrder, repeatIndicator } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.repeatValidDays;
    },
    HERB: () => {
      return herbOrder?.repeatValidDays;
    },
  };

  if (repeatIndicator || drugOrder?.prescriptionId) {
    return drugOrder?.performDays || '';
  } else {
    return CLASS[orderClass]?.();
  }
};

/**
 * 摆药方式转换
 * @param data
 * @returns
 */
export const drugBillingAttributeConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.drugBillingAttribute;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 配置转换
 * @param data
 * @returns
 */
export const distributeConvert = (data) => {
  const { orderClass, drugOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.distribute;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 出院带药转换
 * @param data
 * @returns
 */
export const dischargeDrugConvert = (data) => {
  const { orderClass, drugOrder, herbOrder } = data;

  const CLASS = {
    DRUG: () => {
      return drugOrder?.dischargeDrugPrescription;
    },
    HERB: () => {
      return herbOrder?.dischargeDrugPrescription;
    },
  };

  return CLASS[orderClass]?.();
};

/**
 * 持续时间转换
 * @param data
 * @returns
 */
export const durationConvert = (data) => {
  const { orderClass, disposalOrder } = data;

  const isDisposalOrder = disposalOrderClassDataSource?.find(
    (item) => item?.value === orderClass,
  );

  if (isDisposalOrder) {
    return {
      value: disposalOrder?.duration ?? '',
      unit: disposalOrder?.durationUnit ?? '日',
    };
  } else {
    return '';
  }
};
