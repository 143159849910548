import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_getListInpPreTransferApplyToDepartmentVoByInpVisitId_3eb0b7:
    {
      method: 'post',
      url: '/api/clinic-inp-adt/get-list-inp-pre-transfer-apply-to-department-vo-by-inp-visit-id',
      parameterFomatter: (data?: { inpVisitId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpPreTransferApplyToDepartmentVoClinicInpAdtEntranceWebVo[],
      ) => data,
    },
});
