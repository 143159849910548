import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentDtoController_getAll_d6e9c4: {
    method: 'post',
    url: '/api/organization/getAll',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
