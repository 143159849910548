import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableToolbar,
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAddition,
  Table,
  Row,
  Col,
  message,
  Tooltip,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import Loading from '@/module/Loading';
import React, { useCallback, useState } from 'react';
import ComplexSelect from '@/pages/Index/components/complex_select';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { values } from 'lodash';
import { getScrollYRem } from '@/utils';
const SkinTestVsDrugSettingList = (props) => {
  //VP转换
  const convertTableSkinTestVsDrugSettingDetail2SaveSkinTestVsDrugSettingsReq =
    (input) => {
      const _ = window._ ?? {};
      const output = input?.map((d) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.skinTestDrugPriceItemId = _.get(d, 'drugPriceItemId'); // Long 对应皮试药品价格编码
        obj.skinTestDrugAmount = _.get(d, 'skinTestDrugAmount');
        obj.memo = _.get(d, 'memo');
        obj.skinTestDrugType = _.get(d, 'skinTestDrugType');
        obj.drugProductId = _.get(d, 'drugProductId');

        if (!obj?.drugProductId) {
          obj.drugProductId = d?.drugProductInfo?.id;
        }
        if (!obj?.skinTestDrugPriceItemId) {
          obj.skinTestDrugPriceItemId = d?.UUid;
        }
        return obj;
      });
      return output;
    };

  //VP转换
  const convertGetSkinTestVsDrugSettingByDrugProductIdRes2TableSkinTestVsDrugSettingDetail =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ..._.get(input, 'data').map((d) => {
          const obj = {};
          obj.drugProductName = _.get(
            d,
            'skinTestDrugPriceItem.drugProductInfo.drugProductName',
          );
          obj.packageSpecification = _.get(
            d,
            'skinTestDrugPriceItem.packageSpecification',
          );
          obj.price = _.get(d, 'skinTestDrugPriceItem.priceItemInfo.price');
          obj.packageUnit = _.get(d, 'skinTestDrugPriceItem.packageUnit');
          obj.skinTestDrugAmount = _.get(d, 'skinTestDrugAmount');
          obj.firmName = _.get(
            d,
            'skinTestDrugPriceItem.drugProductInfo.drugFirmInfo.firmName',
          );
          obj.skinTestDrugType = _.get(d, 'skinTestDrugType');
          obj.memo = _.get(d, 'memo');
          obj.id = _.get(d, 'id');
          obj.drugPriceItemId = _.get(d, 'skinTestDrugPriceItem.id');
          obj.drugProductId = _.get(d, 'drugProductId');
          return obj;
        }),
      );
      return output;
    };
  const Selected = ({ recode, onChange, form, value }) => {
    const [dataSource, setDataSource] = useState([]);
    const { loading, run: getDataSource } = useRequest(
      async (drugProductName) => {
        const res =
          await vsf.services?.DrugDrugController_queryProductWithCurrentPrice_4621c9?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              qto: {
                inputCodeLike: drugProductName,
              },
            },
          ); // TODO: 你可能需要整理返回数据格式

        console.log(res.data?.result);
        setDataSource(
          res.data?.result?.map((item, index) => {
            return { key: index, ...item, UUid: item?.id };
          }),
        );
      },
      {
        debounceWait: 500,
        manual: true,
      },
    );
    const columns = [
      {
        title: '商品名称',
        dataIndex: ['drugProductInfo', 'drugProductName'],
        key: 'drug_name',
      },
      {
        title: '包装规格',
        dataIndex: ['packageSpecification'],
        key: 'package_specification',
      },
      {
        title: '单价',
        dataIndex: ['priceItemInfo', 'price'],
        key: 'prece',
      },
      {
        title: '单位',
        dataIndex: ['packageUnit'],
        key: 'packageUnit',
      },

      {
        title: '厂家',
        dataIndex: ['drugProductInfo', 'drugFirmInfo', 'firmName'],
        key: 'firm_name',
      },
    ];
    return (
      <ComplexSelect
        hideSelectIcon
        mode="radio"
        // showLabel="drugProductName"
        value={value}
        onChange={(values) => {
          console.log('------');
          console.log(values);
          let value = values;
          delete value.id;

          form.setFieldValue(recode?.recordKey, {
            ...value,
            packageSpecification: value?.packageSpecification,
            price: value?.priceItemInfo?.price,
            packageUnit: value?.packageUnit,
            firmName: value?.drugProductInfo?.drugFirmInfo?.firmName,
            drugItemId: value?.drugProductInfo?.id,
            onlyId: values.UUid,
          });
          onChange({
            label: value.drugProductInfo.drugProductName,
            value: value?.drugProductInfo?.id,
          });
        }}
        onSearch={(name) => {
          if (name.trim()) {
            getDataSource(name);
          }
        }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{
          width: '46.6rem',
        }}
        listHeight={1000}
      >
        <Table
          rowClassName="aspirin-table-row-box"
          columns={columns}
          loading={{
            indicator: (
              <Row
                justify="center"
                style={{
                  left: '40%',
                }}
              >
                <Col span={24}>
                  <Loading />
                </Col>
              </Row>
            ),
            spinning: loading,
          }}
          scroll={{
            y: 400,
          }}
          dataSource={dataSource}
        />
      </ComplexSelect>
    );
  };

  const { tabaleParam } = props;

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf.services?.SkinTestVsDrugSettingManageController_getSkinTestVsDrugSettingByDrugProductId_c7d468?.(
          {
            drugProductId: tabaleParam.drugProductId,
          },
        );
      const output =
        convertGetSkinTestVsDrugSettingByDrugProductIdRes2TableSkinTestVsDrugSettingDetail(
          res,
        );
      return {
        data: output,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [tabaleParam],
  );

  const onRemove = async (params) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.SkinTestVsDrugSettingManageController_deleteSkinTestVsDrugSetting_d1a58a?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          btoParam: { id: params?.id },
          extApiParam: params?.extApiParam,
        },
      ); // Tips: 需要返回删除成功与否
    if (res?.code === 200) {
      message.success('删除成功');
      vsf?.refs?.tableSkinTestVsDrugSettingDetail?.reload();
    }

    return res?.code === 200;
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      <div style={{ flex: '1' }}>
        <VSPromiseTable
          editable={{
            editType: 'single',
            onCanDelete: () => null,
            editText: <Icon type="icon-bianji" size={24} />,
            extraOperations: [
              {
                children: <Icon type="icon-shanchu1" size={24} />,
                order: 1,
                onClick: (event, data) => {
                  Tip({
                    content: `是否删除${data?.drugProductName}]`,
                    onOk: () => {
                      onRemove(data);
                    },
                  });
                },
              },
            ],
          }}
          onFetch={onFetch}
          pagination={false}
          vsid="92927"
          onRecord={() => ({ id: 'create_' + Math.random() })}
          id="tableSkinTestVsDrugSettingDetail"
          scroll={{ y: getScrollYRem(22) }}
        >
          <VSTableColumn
            dataIndex={['index']}
            title="序号"
            valueType="index"
            formItemProps={{}}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['drugProductName']}
            title="商品名称"
            valueType="select"
            columnKey={'drugProductName'}
            formItemProps={{}}
            renderFormItem={(...arr) => {
              const [schema, recode, form] = arr;
              return (
                <Selected
                  recode={recode}
                  form={form}
                  onChange={schema.originProps.onChange}
                />
              );
            }}
            // render={(_, values) => {
            //   return (
            //     <Tooltip title={values?.drugProductName}>
            //       <div className="text-overflow-omit">
            //         {values?.drugProductName}
            //       </div>
            //     </Tooltip>
            //   );
            // }}
          />

          <VSTableColumn
            dataIndex={['drugProductId']}
            title="包装规格"
            valueType="text"
            hideInTable
            formItemProps={{}}
            fieldProps={{}}
            render={(_, values) => {
              return (
                <Tooltip title={values?.drugProductId}>
                  <div className="text-overflow-omit">
                    {values?.drugProductId}
                  </div>
                </Tooltip>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['packageSpecification']}
            title="包装规格"
            valueType="text"
            preview
            formItemProps={{}}
            fieldProps={{}}
            render={(_, values) => {
              return (
                <Tooltip title={values?.packageSpecification}>
                  <div className="text-overflow-omit">
                    {values?.packageSpecification}
                  </div>
                </Tooltip>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['price']}
            title="单价"
            preview
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: 'price的值不合法',
                  validator: (_, value) => {
                    if (value === undefined) return Promise.resolve();
                    if ((true && value >= 0) || (!true && value > 0)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('price的值不合法'));
                  },
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['packageUnit']}
            title="单位"
            preview
            valueType="select"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 8 },
              ],
            }}
            fieldProps={{}}
            // MEASURES_DICT
            dataSource={vsf?.stores?.dbenums?.enums?.MEASURES_DICT}
          />

          <VSTableColumn
            dataIndex={['skinTestDrugAmount']}
            title="基数"
            valueType="digit"
            formItemProps={{
              rules: [{ required: true }],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['firmName']}
            title="厂家"
            preview
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              ],
            }}
            fieldProps={{}}
            render={(_, values) => {
              return (
                <Tooltip title={values?.firmName}>
                  <div className="text-overflow-omit">{values?.firmName}</div>
                </Tooltip>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['skinTestDrugType']}
            title="类型"
            valueType="select"
            dataSource={[
              { label: '非原液皮试剂', value: 'SKIN_TEST_DRUG' },
              { label: '溶媒', value: 'SOLVENT' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableToolbar title="皮试带药明细" style={{ paddingLeft: '25px' }}>
            <VSTableAddition children="添加" style={{ marginRight: '20px' }} />
          </VSTableToolbar>
        </VSPromiseTable>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '0px',
          right: '10px',
          zIndex: '20',
        }}
      >
        <Button
          type="primary"
          children="保存"
          onClick={async (params) => {
            console.log(vsf.refs.tableSkinTestVsDrugSettingDetail.getValues());

            const formatData = vsf.refs.tableSkinTestVsDrugSettingDetail
              .getValues()
              .map((d) => {
                const dict = {
                  ...d,
                  clinicRegisterTypeId: props.value?.id,
                  priceItemId: d.priceItem?.id,
                  drugProductId: tabaleParam.drugProductId,
                  skinTestDrugPriceItemId: d?.id,
                };
                if (('' + d.id).startsWith('create_')) {
                  const { id, ...rest } = dict;
                  return rest;
                } else {
                  return dict;
                }
              });
            console.log(formatData);
            const output =
              convertTableSkinTestVsDrugSettingDetail2SaveSkinTestVsDrugSettingsReq(
                formatData,
              );
            const res =
              await vsf.services?.SkinTestVsDrugSettingManageController_saveSkinTestVsDrugSettings_be6a8a?.(
                {
                  btos: output,
                },
              );
            if (res?.code === 200) {
              message.success('保存成功');
            }
            return res?.data;
          }}
        />
      </div>
    </div>
  );
};
export default SkinTestVsDrugSettingList;
