import { createServices } from '@vs/vsf-boot';
export default createServices({
  PrePaymentWarningDictionaryController_getPrePaymentWarningDictionary_769a37: {
    method: 'post',
    url: '/api/finance-billing-record/get-pre-payment-warning-dictionary',
    parameterFomatter: (data?: {
      qto: PrePaymentWarningDictionaryWithWardQtoFinanceBillingRecordPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PrePaymentWarningDictionaryWithWardVoFinanceBillingRecordEntranceWebVo[],
    ) => data,
  },
  PrePaymentWarningDictionaryController_deletePrePaymentWarningDictionary_684cf3:
    {
      method: 'post',
      url: '/api/finance-billing-record/delete-pre-payment-warning-dictionary',
      parameterFomatter: (data?: {
        bto: DeletePrePaymentWarningDictionaryBtoFinanceBillingRecordServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  PrePaymentWarningDictionaryController_savePrePaymentWarningDictionary_cc1385:
    {
      method: 'post',
      url: '/api/finance-billing-record/save-pre-payment-warning-dictionary',
      parameterFomatter: (data?: {
        bto: CreatePrePaymentWarningDictionaryBtoFinanceBillingRecordServiceBto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: PrePaymentWarningDictionaryWithWardVoFinanceBillingRecordEntranceWebVo,
      ) => data,
    },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoFinanceBillingRecordEntranceWebVoPrepaymentwarningdictionarywithwardvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
