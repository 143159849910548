import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_getDispenseListForRefund_3705e8: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-dispense-list-for-refund',
    parameterFomatter: (data?: {
      qto: QuerySubmitByPatientOrderDepartmentQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AllowRefundPrescriptionDispenseVoDrugPrescriptionDispenseEntranceWebVo,
    ) => data,
  },
  DrugPrescriptionRefundDispenseController_applyRefund_775a7b: {
    method: 'post',
    url: '/api/drug-prescription-dispense/apply-refund',
    parameterFomatter: (data?: {
      applys: RefundApplyEoDrugPrescriptionDispensePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
