import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903: {
    method: 'post',
    url: '/api/clinic-bed/query-pagefall-by-clinic-bed-with-patient-qto',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
      ext: {
        statisticsCode: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedWithPatientVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getListBedGroupVoByWardId_532946: {
    method: 'post',
    url: '/api/clinic-bed/get-list-bed-group-vo-by-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (_, __, data?: BedGroupVoClinicBedEntranceWebVo[]) =>
      data,
  },
});
