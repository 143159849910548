import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import './SavedPurchasePlanDetailList.less';
import { CreateScreen } from '@/pages/Index/components/screen';
import { getScrollY, getScrollYRem } from '@/utils';
import { includes } from 'lodash';
import PurchasePlanComplexSelect from './PurchasePlanComplexSelect';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { scrollTo, getTableValues, clearTable } from './methods';
import Tip from '@/pages/Index/components/func/Tip';
const { confirm } = Modal;
const SavedPurchasePlanDetailList = (props) => {
  // 存放毒理分类
  const [onToxicCode, setToxicCode] = useState('');
  // onScreen 清屏 需要import useContext CreateScreen
  const onScreen = useContext(CreateScreen);
  console.log(CreateScreen);
  const button = [
    {
      text: '清屏',
      // ordinaryKey: 'R',
      methodKey: 'altKey',
      disabled: false,
      ghost: true,
      onClick: onScreen,
    },
    {
      text: '打印',
      // ordinaryKey: 'P',
      methodKey: 'altKey',
      disabled: false,
      onClick: () => {
        print();
      },
    },
    {
      text: '提交',
      // ordinaryKey: 'D',
      methodKey: 'altKey',
      // disabled: true,
      onClick: (event) => {
        confirm({
          title: '提交后不可修改，确认提交吗?',
          icon: <ExclamationCircleFilled />,
          content: '',
          onOk() {
            vsf?.refs?.tablePurchasePlanCreate?.saveEdit().then((d) => {
              if (vsf?.refs?.tablePurchasePlanCreate?.getValues?.().length <= 0)
                return message.error('请新增或生成采购计划。');

              const master = {
                ...props?.planMaster,
                memo: vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue(
                  'memo',
                ),
                planType:
                  vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue(
                    'planType',
                  ),
                drugPurchasePlanDetailBtoList:
                  vsf?.refs?.tablePurchasePlanCreate
                    ?.getValues?.()
                    ?.map((d) => {
                      let id = d?.id;
                      delete d?.id;
                      d.drugProductName = d?.drugProductName?.label;
                      if (typeof id === 'number') d.id = id;
                      return Object.assign({}, d);
                    }) ?? [],
              };
              const res =
                vsf?.services?.DrugStoragePurchaseController_submitPurchasePlan_a9b0a3?.(
                  { btoParam: master },
                );
              res.then((r) => {
                if (r?.code !== 200)
                  return message.error('请检查信息是否填写完整！');
                // 清空列表 清空选中状态；
                props?.setSelectrow(undefined);
                props?.setPlanMaster(undefined);
                vsf?.refs?.formPurchasePlanCreate?.resetFields();
                clearTable('tablePurchasePlanCreate');
                message.success('提交成功');
              });
            });
          },
          onCancel() {
            console.log('关闭提交');
          },
        });

        // return res?.data;
      },
    },
    {
      text: '保存',
      // ordinaryKey: 'S',
      methodKey: 'altKey',
      onClick: (event) => {
        vsf?.refs?.tablePurchasePlanCreate?.saveEdit().then((d) => {
          if (vsf?.refs?.tablePurchasePlanCreate?.getValues?.().length <= 0)
            return message.error('请新增或生成采购计划');
          const master = {
            ...props?.planMaster,
            memo: vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue(
              'memo',
            ),
            planType:
              vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue(
                'planType',
              ),
            toxicCode:
              vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue(
                'toxicCode',
              ),
            drugPurchasePlanDetailBtoList:
              vsf?.refs?.tablePurchasePlanCreate?.getValues?.()?.map((d) => {
                delete d?.id;
                d.drugProductName = d?.drugProductName?.label;
                return d;
              }) ?? [],
          };
          const res =
            vsf?.services?.DrugStoragePurchaseController_savePurchasePlan_df26d5?.(
              { btoParam: master },
            );
          res.then(({ code, data }) => {
            if (code !== 200)
              return message.error('保存失败，请检查信息是否填写完整！');
            message.success('保存成功');
            props?.setSelectrow(
              Object.assign({ ...props?.selectrow }, { id: data }),
            );
            props?.setPlanMaster(
              Object.assign({ ...props?.planMaster }, { id: data }),
            );
            vsf?.refs?.tableSavedPurchasePlan?.reload();
            vsf?.refs?.tablePurchasePlanCreate?.reload();
            setToxicCode(master?.toxicCode);
          });
        });
      },
    },
  ];
  const footer = () => {
    return (
      <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };
  /**
   * convertGetPagedProfileDrugProductRes2TablePurchasePlanCreate
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedProfileDrugProductRes2TablePurchasePlanCreate = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.drugProductName = _.get(d, 'drugProduct.drugProductName');
        obj.packageSpecification = _.get(d, 'packageSpecification');
        obj.firmName = _.get(d, 'drugProduct.drugFirmInfo.firmName');
        return obj;
      }) ?? []),
    );
    return output;
  };
  /**
   * convertCreatePurchasePlansRes2TablePurchasePlanCreate
   * @param {any} input
   * @returns {any}
   */
  const convertCreatePurchasePlansRes2TablePurchasePlanCreate = (input) => {
    const filterList = vsf?.refs?.tablePurchasePlanCreate
      ?.getValues?.()
      ?.map((m) => m.drugPriceItemId);
    const _ = window._ ?? {};
    const output = [];
    let i = 1;
    output.push(
      ...(_.get(input, 'data')
        ?.filter((f) => !includes(filterList, _.get(f, 'drugPriceItem.id'))) // 过虑已存在药品；
        .map((d, i) => {
          const obj = {}; // TODO 请确认下行字段是否符合逻辑
          obj.drugIndicator = _.get(
            d,
            'drugPriceItem.drugProduct.drugInfo.drugIndicator',
          ); // TODO 请确认下行字段是否符合逻辑
          obj.drugSpecification = _.get(
            d,
            'drugPriceItem.drugProduct.drugSpecification',
          ); // TODO 请确认下行字段是否符合逻辑
          obj.packageSpecification = _.get(
            d,
            'drugPriceItem.packageSpecification',
          ); // TODO 请确认下行字段是否符合逻辑
          obj.firmName = _.get(
            d,
            'drugPriceItem.drugProduct.drugFirmInfo.firmName',
          );
          obj.totalAmount = _.get(d, 'totalAmount');
          obj.storageAmount = _.get(d, 'storageAmount');
          obj.consumeAmount = _.get(d, 'consumeAmount');
          obj.amount = _.get(d, 'amount'); // TODO 请确认下行字段是否符合逻辑
          obj.packageRatio = _.get(d, 'drugPriceItem.drugProduct.packageRatio'); // TODO 请确认下行字段是否符合逻辑
          obj.packageUnit = _.get(d, 'drugPriceItem.packageUnit');
          obj.lowLevel = _.get(d, 'lowLevel'); // TODO 请确认下行字段是否符合逻辑
          obj.drugPriceItemId = _.get(d, 'drugPriceItem.id');
          obj.drugProductName = _.get(
            d,
            'drugPriceItem.drugProduct.drugProductName',
          );
          obj.price = _.get(d, 'drugPriceItem.price');
          return obj;
        }) ?? []),
    );
    return output;
  };
  /**
   * convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate = (
    input,
  ) => {
    return input?.data?.result?.map((m) => ({
      id: m.id,
      drugProductName: m.drugProduct?.drugProductName,
      firmName: m.drugProduct?.drugFirmInfo?.firmName,
      packageSpecification: m.packageSpecification,
    }));
  };
  /**
   * convertGetPlanDetailByPlanMasterIdRes2TablePurchasePlanCreate
   * @param {any} input
   * @returns {any}
   */
  const convertGetPlanDetailByPlanMasterIdRes2TablePurchasePlanCreate = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id'); // TODO 请确认下行字段是否符合逻辑
        obj.drugProductName = _.get(
          d,
          'drugPriceItem.drugProduct.drugProductName',
        );
        obj.drugIndicator = _.get(
          d,
          'drugPriceItem.drugProduct.drugInfo.drugIndicator',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.drugSpecification = _.get(
          d,
          'drugPriceItem.drugProduct.drugSpecification',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.packageSpecification = _.get(
          d,
          'drugPriceItem.packageSpecification',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.firmName = _.get(
          d,
          'drugPriceItem.drugProduct.drugFirmInfo.firmName',
        );
        obj.totalAmount = _.get(d, 'totalAmount');
        obj.storageAmount = _.get(d, 'storageAmount');
        obj.consumeAmount = _.get(d, 'consumeAmount');
        obj.amount = _.get(d, 'amount'); // TODO 请确认下行字段是否符合逻辑
        obj.packageRatio = _.get(d, 'drugPriceItem.drugProduct.packageRatio'); // TODO 请确认下行字段是否符合逻辑
        obj.packageUnit = _.get(d, 'drugPriceItem.packageUnit');
        obj.lowLevel = _.get(d, 'lowLevel'); // TODO 请确认下行字段是否符合逻辑
        obj.drugPriceItemId = _.get(d, 'drugPriceItem.id');
        obj.price = _.get(d, 'drugPriceItem.price');
        obj.isSave = true;
        return obj;
      }) ?? []),
    );
    return output;
  };
  // tablePurchasePlanCreate onFetch
  const tablePurchasePlanCreateOnFetch = useCallback(
    async (params) => {
      if (!props?.planMaster?.id) return;
      const res =
        await vsf?.services?.DrugStoragePurchaseController_getPlanDetailByPlanMasterId_e03c43?.(
          { planMasterId: props?.planMaster?.id },
        );
      return {
        data: convertGetPlanDetailByPlanMasterIdRes2TablePurchasePlanCreate?.(
          res,
        ),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props?.planMaster],
  );

  // 生成采购计划；
  const onCreatePurchasePlansClick = async (event) => {
    if (props?.planMaster?.id) {
      vsf?.refs?.formPurchasePlanCreate?.setFieldValue?.('purchaseAccount', 0);
      clearTable('tablePurchasePlanCreate');
    }
    if (onToxicCode) clearTable('tablePurchasePlanCreate');
    const eo = {
      toxicCode:
        vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue('toxicCode'),
      planType:
        vsf?.refs?.formPurchasePlanCreate?.getFieldFormatValue('planType'),
    };
    const res =
      await vsf?.services?.DrugStoragePurchaseController_createPurchasePlans_935974?.(
        { eo },
      );
    // if (
    //   convertCreatePurchasePlansRes2TablePurchasePlanCreate?.(res).length <= 0
    // )
    //   return message.warning('药品已存在或该毒理分类下需手动新增药品');
    vsf?.refs?.tablePurchasePlanCreate
      ?.add(convertCreatePurchasePlansRes2TablePurchasePlanCreate?.(res))
      .then((_) => message.success('药品采购计划生成成功'));
  };
  // 设置需要滚动定位key；
  const [scrollToKey, setScrollToKey] = useState('');
  // 选中药品名称事件
  const onChangePurchaseName = function (target) {
    if (getTablePurchasePlanCreateRowD(target.id)?.length <= 0)
      return message.success('未查询到该药品！');
    const { drugPriceItemId, id } = getTablePurchasePlanCreateRowD(
      target.id,
    )[0];
    // 设置正行高亮；
    props.setDrugProductNameValue(drugPriceItemId);
    // // 滚动定位至选中行；
    vsf?.refs?.tablePurchasePlanCreate?.scrollTo?.(id);
    message.success('药品定位成功');
  };
  // 表头下拉选项onFetch formPurchasePlanCreate onFetch
  const fromPurchasePlanCreateOnFetch = useCallback(
    async (params) => {
      if (!props?.planMaster?.id) return;
      const res =
        await vsf?.services?.DrugStoragePurchaseController_getByMasterId_0d5695?.(
          { masterId: props?.planMaster?.id },
        );
      return {
        ...res?.data,
        toxicCode: onToxicCode == '' ? null : onToxicCode,
      };
    },
    [props?.planMaster],
  );
  // 请选中药品下拉框数据储存/设置
  const [drugData, setDrugData] = useState([]);
  // 获取下拉框请选中药品数据
  const getPurchaseDrugData = async () => {
    const res =
      await vsf?.services?.DrugDrugController_getPagedProfileDrugProduct_9f4f4f?.(
        { query: { inputCodeLike: '' } },
      );
    return convertGetPagedProfileDrugProductRes2FormPurchasePlanCreate?.(res);
  };
  // 获取 tablePurchasePlanCreate row 详情
  const getTablePurchasePlanCreateRowD = (drugPriceItemId) => {
    return vsf?.refs?.tablePurchasePlanCreate
      ?.getValues?.()
      ?.filter((f) => f?.drugPriceItemId === drugPriceItemId);
  };
  // 计算采购总价格
  const onPurchaseAccount = useCallback(({ price, id, value }) => {
    price = price ? parseFloat(price) : 0;
    value = value ? parseFloat(value) : 0;
    let rowAccount = parseFloat(price) * parseFloat(value);
    return parseFloat(
      vsf?.refs?.tablePurchasePlanCreate
        ?.getValues?.()
        ?.filter((f) => id !== f.id)
        .reduce((c, { amount, price }, i) => {
          amount = amount ? parseFloat(amount) : 0;
          price = price ? parseFloat(price) : 0;
          let account = c + parseFloat(amount) * parseFloat(price);
          return account;
        }, 0) + rowAccount,
    ).toFixed(2);
  }, []);

  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <Section style={{ width: '100%', height: 'calc(100% - 3rem)' }}>
          <VSForm
            vsid="96078"
            id="formPurchasePlanCreate"
            onFetch={fromPurchasePlanCreateOnFetch}
          >
            <VSFormLayout key="0" columnCount={4}>
              <VSFormItem
                name={['toxicCode']}
                label="毒理分类"
                valueType="select"
                fieldProps={{}}
                dataSource={
                  vsf?.stores?.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT
                }
              />

              <VSFormItem
                name={['planType']}
                label="采购类型"
                valueType="select"
                dataSource={[
                  { label: '药械平台采购', value: 'PLATFORM_PURCHASE' },
                  { label: '普通采购', value: 'NORMAL_PURCHASE' },
                ]}
                fieldProps={{}}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="text"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 20,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['purchaseAccount']}
                label="采购金额"
                readonly
                valueType="digit"
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout key="1" columnCount={0}></VSFormLayout>
          </VSForm>
          <VSPromiseTable
            scroll={{ x: '80rem', y: getScrollYRem(28) }}
            className="aspirin-table-no-background-box aspirin-table aspirin-table-search-padding-no"
            editable={{
              extraOperations: [
                {
                  children: '删除',
                  onClick: (...[, record]) => {
                    if (record?.isSave) {
                      Tip({
                        content: `确认删除药品 ${record?.drugProductName} ?`,
                        onOk() {
                          vsf.refs.tablePurchasePlanCreate.remove({
                            id: record?.id,
                          });
                        },
                      });
                      return;
                    }
                    vsf.refs.tablePurchasePlanCreate.remove({ id: record?.id });
                  },
                },
              ],
              editType: 'multiple',
              onCanDelete: () => false,
              onFieldChange: async (key, value, { price, amount }, form) => {
                vsf?.refs?.formPurchasePlanCreate?.setFieldValue?.(
                  'purchaseAccount',
                  onPurchaseAccount({ price, id: key, value: amount }),
                );
              },
            }}
            vsid="97344"
            id="tablePurchasePlanCreate"
            onFetch={tablePurchasePlanCreateOnFetch}
            onRecord={async () => {
              return {
                id: `TEMP_${Math?.random?.()}`,
                drugProductName: '请选择药品',
                amount: 0,
              };
            }}
            onRow={(...args) => {
              return {};
            }}
            rowClassName={(node) => {
              if (!node?.drugPriceItemId)
                return 'aspirin-vs-table-row-box aspirin-row-select-box';
              return node?.drugPriceItemId === props.drugProductNameValue
                ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
                : 'aspirin-vs-table-row-box aspirin-row-select-box';
            }}
            pagination={false}
          >
            <VSTableToolbar title="">
              <Button
                type="primary"
                children="生成采购计划"
                onClick={onCreatePurchasePlansClick}
              />
              <Button
                type="primary"
                children="新增"
                onClick={() =>
                  vsf?.refs?.tablePurchasePlanCreate
                    ?.add?.({})
                    .then((d) =>
                      scrollTo(
                        'tablePurchasePlanCreate',
                        getTableValues('tablePurchasePlanCreate')?.[
                          getTableValues('tablePurchasePlanCreate').length - 1
                        ]?.id,
                        '',
                      ),
                    )
                }
              />
            </VSTableToolbar>

            <VSTableColumn
              width="19rem"
              dataIndex={['drugProductName']}
              title="商品名称"
              valueType="select"
              formItemProps={{}}
              fieldProps={(record) => ({
                record,
                id: String(record?.id),
              })}
              renderFormItem={(...args) => {
                const [schema, recode, form] = args;
                return (
                  <>
                    <PurchasePlanComplexSelect
                      recode={recode}
                      form={form}
                      value={recode.record.drugProductName}
                      type="row"
                      table_id="tablePurchasePlanCreate"
                    />
                  </>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['drugIndicator']}
              title="药品类型"
              // editable={false}
              preview
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.DRUG_INDICATOR_DICT}
              fieldProps={{
                dataSource: vsf.stores.dbenums.enums.DRUG_INDICATOR_DICT,
              }}
            />

            <VSTableColumn
              dataIndex={['drugSpecification']}
              title="规格"
              // editable={false}
              preview
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['packageSpecification']}
              title="包装规格"
              // editable={false}
              preview
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 50 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width="14.4rem"
              dataIndex={['firmName']}
              title="厂家"
              preview
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['totalAmount']}
              title="全院库存"
              preview
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['storageAmount']}
              title="库存量"
              preview
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              width="7rem"
              dataIndex={['consumeAmount']}
              title="全院消耗量"
              preview
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['amount']}
              title="采购数量"
              valueType="number"
              fieldProps={({ amount, price, id }) => {
                return {};
              }}
            />

            <VSTableColumn
              dataIndex={['packageRatio']}
              title="每件数量"
              preview
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataSource={vsf?.stores?.dbenums?.enums?.MEASURES_DICT}
              dataIndex={['packageUnit']}
              title="单位"
              preview
              valueType="select"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 8 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              preview
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['lowLevel']}
              title="下限"
              preview
              valueType="digit"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </Section>

        <div style={{ width: '100%', position: 'absolute', zIndex: 9 }}>
          <div className="display-flex">
            <VSForm id="lookpurchase">
              <VSFormLayout>
                <VSFormItem
                  style={{ width: '13.33rem' }}
                  name={['drugProductName']}
                  label="选择药品"
                  valueType="custom"
                >
                  <PurchasePlanComplexSelect
                    recode={''}
                    form={''}
                    value={''}
                    type="look"
                    setDrugProductNameValue={props.setDrugProductNameValue}
                  />
                </VSFormItem>
              </VSFormLayout>
            </VSForm>

            <Block footer={footer()} style={{ padding: '0px' }}></Block>
          </div>
        </div>
      </div>
    </>
  );
};
export default definePage(SavedPurchasePlanDetailList);
