import { createServices } from '@vs/vsf-boot';
export default createServices({
  NurseBillingController_queryOrderInfoForNurseStation_c4461b: {
    method: 'post',
    url: '/api/clinic-record-order/query-order-info-for-nurse-station',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderClass: ClinicItemClassEnum;
      orderTextLike: string;
      repeatIndicator: boolean;
      billingAttribute: OrderBillingAttributeEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoForNurseSupplementBillingVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  NurseBillingController_queryOrderInfoForBillingStation_23262f: {
    method: 'post',
    url: '/api/clinic-record-order/query-order-info-for-billing-station',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderClass: ClinicItemClassEnum;
      orderTextLike: string;
      repeatIndicator: boolean;
      billingAttribute: OrderBillingAttributeEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderInfoForNurseSupplementBillingVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getInpBillingDetailByOrderId_2827f8: {
    method: 'post',
    url: '/api/inp-billing/get-inp-billing-detail-by-order-id',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderId: number;
      itemId: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getOrderBillingInpBillingDetailGroupInfo_5b1e07: {
    method: 'post',
    url: '/api/inp-billing/get-order-billing-inp-billing-detail-group-info',
    parameterFomatter: (data?: { inpVisitId: number; orderIdList: number[] }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailGroupForNurseStationVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_createInpBillingDetailForBillingStation_52012b: {
    method: 'post',
    url: '/api/inp-billing/create-inp-billing-detail-for-billing-station',
    parameterFomatter: (data?: {
      saveInpBillingDetailEoList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-page-user-staff-by-input-code-qto',
    parameterFomatter: (data?: {
      qto: StaffWithDoctorQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDoctorVoUserStaffEntranceWebVo[],
    ) => data,
  },
});
