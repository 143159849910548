import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpVisitController_getAllByErpVisitQtoFor72Hours_16126e: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-all-by-erp-visit-qto-for-72-hours',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicErpVisitWithPatientInfoVoClinicErpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicErpVisitController_getAllByErpVisitQtoFor48Hours_6115a2: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-all-by-erp-visit-qto-for-48-hours',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicErpVisitWithPatientInfoVoClinicErpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicErpVisitController_getNewErpPreExamMainVoByDisplayId_adbc63: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-new-erp-pre-exam-main-vo-by-display-id',
    parameterFomatter: (data?: { displayId: string; isNewErp: boolean }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ErpPreExamMainVoClinicErpVisitEntranceWebVo,
    ) => data,
  },
  ClinicErpVisitController_getAllByErpVisitQtoForPreExam_4fb0ed: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-all-by-erp-visit-qto-for-pre-exam',
    parameterFomatter: (data?: { qto: ErpVisitQtoClinicErpVisitPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ClinicErpVisitWithPatientInfoVoClinicErpVisitEntranceWebVo[],
    ) => data,
  },
});
