import './index.less';

import classnames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';

type SearchPropsType = {
  children?: React.ReactNode;
  mode?: 'inline' | 'normal';
  formPadding?: string | number;
};

const SearchContainer = (props: SearchPropsType) => {
  const { children, mode = 'normal', formPadding = 0 } = props;
  const searchContainerRef = useRef<any>();

  const setFormPadding = useCallback(() => {
    const target = searchContainerRef?.current.getElementsByTagName('form');

    if (target?.[0]) {
      target[0].style.padding = formPadding;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPadding]);

  useEffect(() => {
    setFormPadding();
  }, [setFormPadding]);

  return (
    <div
      className={classnames('aspirin-table-search', {
        'aspirin-table-search-inline': mode === 'inline',
      })}
      ref={searchContainerRef}
    >
      {children}
    </div>
  );
};

const Header = (
  props: SearchPropsType & {
    title?: string | React.ReactNode;
    style?: React.CSSProperties;
  },
) => {
  const { children, title, style } = props;
  return (
    <div className="aspirin-table-search-item-box" style={style}>
      {
        <div
          className={
            title
              ? 'aspirin-block-title icon18'
              : 'aspirin-block-title-no icon18'
          }
        >
          {title}
        </div>
      }
      <div className="aspirin-table-search-button-box">{children}</div>
    </div>
  );
};

SearchContainer.Header = Header;

export default SearchContainer;
