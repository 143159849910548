import '../common_medicines/index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Input, Radio } from '@vs/vsf-kit';
import { useResetState, useUpdateEffect } from 'ahooks';
import React, { useContext, useRef, useState } from 'react';

import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { Determine } from '@/pages/Index/components';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { OutHospitalTemplates } from '@/pages/Index/components/order';
import Screen, { CreateScreen } from '@/pages/Index/components/screen';
import storage from '@/utils/storage';

import DefendEdit from './DefendEdit';
import DefendTable, { DefendTableProps } from './DefendTable';
import DefendTree from './DefendTree';

export interface MedicalOrderTemplateDefendProps {
  /**
   * 是不是协定方
   */
  agreedPrescriptionIndicator?: boolean;
  /**
   * 是否展示 个人、科室切换
   */
  showRadio?: boolean;
  eliminates?: ['herb', 'drug', 'exam'];
  eliminateButtons?: string[];
}

const MedicalOrderTemplateDefend = (props: MedicalOrderTemplateDefendProps) => {
  const {
    agreedPrescriptionIndicator = false,
    showRadio = true,
    eliminates,
    eliminateButtons,
  } = props;

  const [show, setShow] = useState(false);
  const [orderTemplate, setOrderTemplate, restOrderTemplate] =
    useResetState<any>(null);
  // const [search, setSearch] = useState('');
  const tree = useRef({
    onReload: () => void 0,
    onSearch: (value: string) => void 0,
  });
  const onScreen = useContext(CreateScreen);

  const [template, setTemplate] = useState('PERSONAL_TEMPLATE');
  const onShow = () => {
    setShow(true);
  };

  const onChange = (value) => {
    setOrderTemplate(value);
  };

  const currentApplication = storage?.get('currentApplication');
  useUpdateEffect(() => {
    onScreen(() => void 0);
  }, [currentApplication?.id]);

  const table = useRef({
    onReload: async () => [],
  });
  const [reset, setReset] = useState(0);
  return (
    <div className="aspirin-common-medicines-box">
      <nav
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Determine condition={showRadio}>
            <Radio.Group
              buttonStyle="solid"
              value={template}
              onChange={(value) => {
                setTemplate(value);
                restOrderTemplate();
              }}
              optionType="button"
              dataSource={[
                {
                  value: 'PERSONAL_TEMPLATE',
                  label: '个人',
                },
                {
                  value: 'DEPARTMENT_TEMPLATE',
                  label: '科室',
                },
              ]}
            />
          </Determine>
          <Input
            width="20rem"
            onChange={(event) =>
              tree?.current?.onSearch?.(event?.target?.value)
            }
            placeholder="搜索模板名称"
            prefix={<Icon type="icon-sousuo" />}
          />
        </div>
        <div className="aspirin-medical-order-template-defend-right-box">
          <ButtonAndKeyDown
            onClick={onShow}
            ordinaryKey="A"
            methodKey="altKey"
            type="primary"
          >
            {agreedPrescriptionIndicator ? '新建协定处方模板' : '新建医嘱模版'}
          </ButtonAndKeyDown>
        </div>
      </nav>
      <main style={{ height: 'calc(100% - 4rem)' }}>
        <div className="aspirin-common-medicines-main-menu-box">
          <DefendTree
            template={template}
            onChange={onChange}
            agreedPrescriptionIndicator={agreedPrescriptionIndicator}
            ref={tree}
            onReload={table?.current?.onReload}
            reset={() => {
              setReset(Math?.random());
            }}
          />
        </div>
        <div
          className="aspirin-common-medicines-main-content-box"
          style={{
            padding: '0 1.6rem',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <Container layout={{ items: [] }}>
            <Determine
              condition={
                orderTemplate?.folderOrTemplateIndicator === 'TEMPLATE'
              }
              fallback={<Empty desc="当前选择为文件夹，无法展示相关模板~" />}
            >
              <div
                className="aspirin-cpoe-center-mainContainer"
                style={{
                  padding: 0,
                }}
              >
                <OutHospitalTemplates
                  {...{ restOrderTemplate, reset }}
                  orderTemplate={orderTemplate}
                  eliminateButtons={eliminateButtons}
                  agreedPrescriptionIndicator={agreedPrescriptionIndicator}
                  eliminates={eliminates}
                />
              </div>
            </Determine>
          </Container>
        </div>
      </main>
      <DefendEdit
        open={show}
        onCancel={() => setShow(false)}
        agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        onReload={tree?.current?.onReload}
        value={orderTemplate}
        tree={tree}
      />
    </div>
  );
};

const Main = (props) => (
  <Screen>
    <MedicalOrderTemplateDefend {...props} />
  </Screen>
);

export default Main;
