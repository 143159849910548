type ArrayType = {
  children: ArrayType[];
  id: string | number;
  treeLevel: string | number;
};

type FieldNames = {
  children: string;
  id: string;
  treeLevel: string;
};

/**
 * 获取 tree menu 的上级
 * @param key 当前唯一值 id 或者 反正是唯一的就行
 * @param dataSource tree 整条树形结构
 * @returns 当前这条树形结构[一级, 二级, 三级...]
 */
export default function askGetTreeWhole(
  key: number | string,
  dataSource: ArrayType[],
  fieldNames: FieldNames = {
    children: 'children',
    id: 'id',
    treeLevel: 'treeLevel',
  },
) {
  const { children, id, treeLevel } = fieldNames;

  /**
   * 递归 ID
   * @param array 子级
   * @returns 返回平坦的树形数组
   * 比如 [一级, 二级（1）]
   */
  const get = (array: ArrayType[]) => {
    return array.map((item) => {
      const tree = [item];
      if (item[children].length) {
        const child = get(item[children]);
        tree.push(...child.flat());
      }
      return tree;
    });
  };
  const tree = [] as ArrayType[];
  for (const item of dataSource) {
    get(item[children]).forEach((child) => {
      const pk = child.findIndex((item) => item[id] === key);
      if (pk !== -1) {
        const newly = child.filter((item) => {
          return (
            item?.[treeLevel] !== child?.[pk]?.[treeLevel] ||
            item?.[id] === child[pk]?.[id]
          );
        });
        newly.unshift(item);
        tree.push(...newly);
      }
    });
  }
  return tree;
}
