import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugApplyController_getListDrugApplyMasterDetailProductVoByDrugApplyMasterId_69a8ec:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-list-drug-apply-master-detail-product-vo-by-drug-apply-master-id',
      parameterFomatter: (data?: { drugApplyMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugApplyDetailProductPriceStockProfileVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugApplyController_getCreatedDeptApplyMasters_932a90: {
    method: 'post',
    url: '/api/drug-storage-import/get-created-dept-apply-masters',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyMasterStorageVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugApplyController_getParentStorages_660b1f: {
    method: 'post',
    url: '/api/drug-storage-import/get-parent-storages',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugApplyMasterStorageVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugApplyController_queryDeptDrugApplyDrop_1ff628: {
    method: 'post',
    url: '/api/drug-storage-import/query-dept-drug-apply-drop',
    parameterFomatter: (data?: {
      qto: DrugPriceItemForDrugApplyDropQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugApplyDetailProductPriceStockProfileVoDrugStorageImportExportEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugApplyController_saveDrugApply_999999: {
    method: 'post',
    url: '/api/drug-storage-import/save-drug-apply',
    parameterFomatter: (data?: {
      drugApplyMasterDetailVo: DrugApplyMasterDetailVoDrugStorageImportExportEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugApplyController_submitDrugApply_da8985: {
    method: 'post',
    url: '/api/drug-storage-import/submit-drug-apply',
    parameterFomatter: (data?: {
      drugApplyMasterDetailVo: DrugApplyMasterDetailVoDrugStorageImportExportEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
