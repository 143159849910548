import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_patientListForTransferOrInDepartment_3544c7: {
    method: 'post',
    url: '/api/clinic-inp-adt/patient-list-for-transfer-or-in-department',
    parameterFomatter: (data?: {
      eo: PatientTransferOrInDepartmentEoClinicInpAdtPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpTransferRecordWithVisitAndBedVoClinicInpAdtEntranceWebVo[],
    ) => data,
  },
});
