import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Icon,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  Tree,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { random } from 'lodash';
import { cloneDeep, isNaN } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icons from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import { askFixed, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

import {
  convertGetChiefComplaintVsJudgeRes2ChiefComplaintJudgeDictionarVoyRo,
  convertGetJudgeByChiefComplaintRes2ChiefComplaintJudgeCheckVoRo,
} from './convert';

let tempArr: any = [];
const Index = (props, ref) => {
  const { chiefItem } = props;
  const [isAdd, setIsAdd] = useState(false);
  // 选中行
  const [selectedRows, setSelectedRows] = useState<any>([]);
  // 搜索params
  const [search, setSearch] = useState<any>();
  // 搜索框清空使用
  const [initKey, setInitKey] = useState<any>(0);
  // 导入完成使用
  const [initKeys, setInitKeys] = useState<any>(0);
  // 全部主诉判定依据
  const [allData, setAllData] = useState<any>([]);

  useEffect(() => {
    setIsAdd(false);
    setSearch(null);
  }, [chiefItem]);

  useEffect(() => {
    if (selectedRows?.length <= 0) return;
    const _temp = selectedRows?.map((i) => {
      return { ...i, new: false };
    });
    setSelectedRows([..._temp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initKeys]);

  // useEffect(() => {
  //   handelSearch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);
  useEffect(() => {}, [selectedRows]);
  //全部主诉判定依据
  const getAllList = useCallback(async () => {
    if (!chiefItem?.id) {
      setSelectedRows([]);
      setAllData([]);
      return { data: [], total: 0 };
    }
    const res =
      await vsf?.services?.ChiefComplaintDictionaryController_getJudgeByChiefComplaint_8dd6f7?.(
        { qto: { chiefIdIs: chiefItem?.id, inputCodeLike: search } },
      );
    const _res =
      convertGetJudgeByChiefComplaintRes2ChiefComplaintJudgeCheckVoRo?.(res);
    // 查全部，不带搜索
    const res1 =
      await vsf?.services?.ChiefComplaintDictionaryController_getJudgeByChiefComplaint_8dd6f7?.(
        { qto: { chiefIdIs: chiefItem?.id } },
      );
    const _res1 =
      convertGetJudgeByChiefComplaintRes2ChiefComplaintJudgeCheckVoRo?.(res1);
    const _hasCheck = _res1
      ?.filter((i) => i?.checkStatus)
      .map((i) => {
        return { ...i, new: false };
      });

    setSelectedRows([..._hasCheck]);
    setAllData([..._res]);
    // return {
    //   data: [..._res],
    //   total: res?.data?.count ?? res?.data?.length ?? 0,
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chiefItem, initKeys]);
  // [chiefItem, search, initKey, initKeys]
  useEffect(() => {
    getAllList();
  }, [getAllList]);

  // 搜索
  const handelSearch = () => {
    if (!search || search?.length == 0) return allData ?? [];

    const res = allData?.filter((item) => {
      const _item =
        item?.chiefComplaintJudgeName.includes(search) ||
        item?.inputCode.includes(search);
      return _item ? _item : askSearch(search, item, 'chiefComplaintJudgeName');
    });
    return res;
  };

  const getChiefComplaintVsJudge = useCallback(async () => {
    if (!chiefItem?.id) return { data: [], total: 0 };
    const res =
      await vsf.services?.ChiefComplaintDictionaryController_getChiefComplaintVsJudge_95ede4(
        { chiefId: chiefItem?.id },
      );
    return {
      data:
        convertGetChiefComplaintVsJudgeRes2ChiefComplaintJudgeDictionarVoyRo?.(
          res,
        ) ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chiefItem, initKeys]);

  // 删除
  const handelDelete = async (value) => {
    const res =
      await vsf?.services?.ChiefComplaintDictionaryController_deleteChiefComplaintVsJudge_b943f6?.(
        { bto: { id: value?.chiefVsJudgeId } },
      );
    if (res?.code == 200) {
      setInitKeys(initKeys + Math.random());
      message.success('删除成功');
    }
  };
  const handelAdd = (v = false) => {
    setIsAdd(v);
  };

  useImperativeHandle(ref, () => ({
    handelAdd,
  }));

  return (
    <div className="maintenance_complaint_content">
      <div className="bottom">
        <div className="right">
          <div className="table">
            <div className="header_right">
              <Input
                className="icon18"
                id="searchInput"
                autoComplete="off"
                disabled={!isAdd || !chiefItem?.id}
                prefix={
                  <Icons type="icon-sousuo" style={{ color: '#c3c3c3' }} />
                }
                placeholder="请输入判定依据或简拼"
                width={isMinScreen ? 200 : 300}
                onKeyUp={(v: any) => {
                  if (v?.key == 'Enter') {
                    setSearch(v?.target?.value);
                    setInitKey(initKey + 1);
                  }
                }}
                onChange={(v) => {
                  if (v?.target.value?.length <= 0) {
                    setInitKey(initKey + Math.random());
                    setSearch(null);
                  }
                }}
                allowClear
              />
              <Button
                disabled={isAdd || !chiefItem?.id}
                type="primary"
                className="custom_btn"
                onClick={() => {
                  setIsAdd(true);
                }}
              >
                编辑
              </Button>
              <Button
                disabled={!isAdd || !chiefItem?.id}
                type="primary"
                className="custom_btn"
                onClick={async () => {
                  const _data = vsf?.refs?.chiefComplaintJudgeCheckVo
                    ?.getSelectedRowValues()
                    ?.map((i) => {
                      return {
                        ...i,
                        chiefComplaintJudgeId: i?.id,
                        chiefComplaintId: chiefItem?.id,
                      };
                    });

                  const res =
                    await vsf?.services?.ChiefComplaintDictionaryController_saveChiefComplaintVsJudge_f26fa8?.(
                      {
                        eo: {
                          createBtoList: _data,
                          chiefComplaintId: chiefItem?.id,
                        },
                      },
                    );
                  if (res?.code == 200) {
                    message.success('导入成功');
                    setIsAdd(false);
                    setSearch(null);
                    setInitKeys(initKeys + Math.random());
                    tempArr = [];
                  }
                }}
              >
                完成
              </Button>
            </div>
            <VSPromiseTable
              id="chiefComplaintJudgeCheckVo"
              className="
                table_overflow_294
                aspirin-table-header-background-color
               aspirin-table-row-hover-background-color1
               aspirin-table-nopadding
               aspirin-table-body-empty-transparent-background-color
               "
              extraParams={{ search, initKeys, initKey }}
              rowClassName={(v, c) => {
                const _data = vsf?.refs?.chiefComplaintJudgeCheckVo
                  ?.getSelectedRowValues()
                  ?.map((item) => {
                    return item?.id;
                  });

                return _data?.includes(v?.id)
                  ? 'aspirin-table-row-select-background-color1'
                  : '';
              }}
              scroll={{ y: 0 }}
              pagination={false}
              rowSelection={{
                selectType: 'multiple',
                selectedRows: selectedRows,
                onSelect: (v: any) => {
                  tempArr.push('' + v?.id);
                },

                onSelectedRowsChange: (formData, keys) => {
                  const _selectRow = formData?.map((i) => {
                    if (tempArr.includes('' + i?.id)) {
                      return { ...i, new: true };
                    } else {
                      return {
                        ...i,
                        new: false,
                      };
                    }
                  });

                  setSelectedRows([..._selectRow]);
                },
                getCheckboxProps: (record) => {
                  return {
                    disabled: !isAdd,
                  };
                },
              }}
              // onFetch={getAllList}
              onFetch={async () => {
                return { data: handelSearch() ?? [] };
              }}
            >
              <VSTableColumn
                dataIndex={['chiefComplaintJudgeName']}
                title="判定依据"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['patientType']}
                title="适用患者"
                valueType="select"
                fieldProps={{}}
                width={150}
                dataSource={vsf.stores.dbenums?.enums?.PRE_EXAM_PATIENT_TYPE}
              />

              <VSTableColumn
                dataIndex={['checkStatus']}
                title="选中状态"
                valueType="switch"
                fieldProps={{}}
                hideInTable
              />

              <VSTableColumn
                dataIndex={['colorCode']}
                title="RGB色值"
                valueType="text"
                hideInTable
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['diseaseLevelName']}
                title="病情分级"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                fieldProps={{}}
                width={120}
                render={(dom, value) => {
                  return (
                    <div style={{ color: value?.colorCode ?? 'red' }}>
                      {dom}级
                    </div>
                  );
                }}
              />

              <VSTableColumn
                dataIndex={['id']}
                title="主键"
                valueType="digit"
                fieldProps={{}}
                hideInTable
              />
            </VSPromiseTable>
          </div>
          {/* <div className="import">
            {
              <Button
                type="primary"
                onClick={async () => {
                  const _data = vsf?.refs?.chiefComplaintJudgeCheckVo
                    ?.getSelectedRowValues()
                    ?.map((i) => {
                      return {
                        ...i,
                        chiefComplaintJudgeId: i?.id,
                        chiefComplaintId: chiefItem?.id,
                      };
                    });

                  const res =
                    await vsf?.services?.ChiefComplaintDictionaryController_saveChiefComplaintVsJudge_f26fa8?.(
                      {
                        eo: {
                          createBtoList: _data,
                          chiefComplaintId: chiefItem?.id,
                        },
                      },
                    );
                  if (res?.code == 200) {
                    message.success('导入成功');
                    setIsAdd(false);
                  }
                }}
              >
                导入
              </Button>
            }
          </div> */}
        </div>
        <div className="left">
          <div className="header_left">
            <div
              style={{
                marginRight: 4,
                width: 4,
                height: 16,
                backgroundColor: '#3276e8',
              }}
            ></div>
            <div className="icon20">已有判定依据</div>
          </div>
          <VSPromiseTable
            id="chiefComplaintJudgeDictionarVoy"
            className="
            table_overflow_294
          aspirin-table-header-background-color
         aspirin-table-row-hover-background-color
         aspirin-table-nopadding
         aspirin-table-body-empty-transparent-background-color
         "
            scroll={{ y: 0 }}
            pagination={false}
            // onFetch={getChiefComplaintVsJudge}
            onFetch={async () => {
              return {
                data: [...selectedRows],
              };
            }}
            rowClassName={(v) => {
              return v?.new ? 'aspirin-table-row-color' : '';
            }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title="主键"
              valueType="digit"
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['chiefComplaintJudgeName']}
              title="判定依据"
              valueType="text"
              width={200}
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['patientType']}
              title="适用患者"
              valueType="select"
              fieldProps={{}}
              width={150}
              dataSource={vsf.stores.dbenums?.enums?.PRE_EXAM_PATIENT_TYPE}
            />

            <VSTableColumn
              dataIndex={['diseaseLevelName']}
              title="病情分级"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              width={120}
              fieldProps={{}}
              render={(dom, value) => {
                return <div style={{ color: value?.colorCode }}>{dom}级</div>;
              }}
            />

            <VSTableColumn
              dataIndex={['colorCode']}
              title="RGB色值"
              valueType="text"
              hideInTable
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 20 },
                ],
              }}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['chiefVsJudgeId']}
              title="操作"
              width={50}
              valueType="text"
              fieldProps={{}}
              render={(_, value) => {
                return value?.chiefVsJudgeId ? (
                  <div
                    onClick={() => {
                      handelDelete(value);
                    }}
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                  >
                    <Icons size={16} type="icon-wudishanchu" />
                  </div>
                ) : null;
              }}
            />
          </VSPromiseTable>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Index);
