import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAddition,
  Button,
  Todo,
  Modal,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React from 'react';
import { getScrollY, getScrollYRem } from '@/utils';
import Tip from '@/pages/Index/components/func/Tip';
import './index.less';
const FurloughReasonTable = (props) => {
  const onFetch = async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByFurloughReasonQto_0cf93d?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteFurloughReason_361495?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      vsf.refs?.FurloughReasonTable?.reload();
    }
    return res?.code === 200;
  };

  const onSave = async (params) => {
    const data = vsf.refs.FurloughReasonTable.getValues();
    const formatData = data.map((d) => {
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = d;
        return rest;
      } else {
        return d;
      }
    });
    const res =
      await vsf?.services?.OutpRegisterDefineController_addUpdateFurloughReason_29a627?.(
        { btoParamList: formatData, extApiParam: {} },
      );
    if (res?.code === 200) {
      Modal.success({
        title: '保存成功',
      });
    }
    vsf.refs.FurloughReasonTable.reload();
    return res?.data; // todo 整理返回数据格式
  };

  return (
    <div className="ful-oug-layout">
      <VSPromiseTable
        className="aspirin-table"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.name}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        vsid="85478"
        id="FurloughReasonTable"
        onFetch={onFetch}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        pagination={false}
        scroll={{
          // y: getScrollY(280),
          y: getScrollYRem(20),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '名称长度不合法', type: 'string', min: 0, max: 1024 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['otherReasonDetail']}
          title="其他原因详情"
          valueType="switch"
          fieldProps={{}}
        />
        <VSTableToolbar title="请假原因维护" style={{ paddingLeft: '20px' }}>
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(FurloughReasonTable);
