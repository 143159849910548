import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpSettleController_preSettle_5135f4: {
    method: 'post',
    url: '/api/inp-billing/pre-settle',
    parameterFomatter: (data?: {
      patientId: number;
      inpVisitId: number;
      newbornIds: number[];
      creditIndicator: boolean;
    }) => data,
    responseFormatter: (_, __, data?: InpPreSettleVoInpBillingEntranceWebVo) =>
      data,
  },
});
