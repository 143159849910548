import './index.less';

import classnames from 'classnames';
import CSS from 'csstype';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import useDragLine from './utils';

const MINSIZE = 20;

type LinePropsType = {
  container?: any;
  style?: CSS.Properties;
  direction?: 'vertical' | 'horizontal';
  color?: string;
};

let DragLine: any = (props: LinePropsType, ref) => {
  const { direction, color, container, style } = props;

  const size = container?.current?.getBoundingClientRect();

  const staticStyle =
    direction === 'vertical'
      ? {
          width: size?.width,
        }
      : {
          height: size?.height,
        };

  return (
    <div
      id="aspirin-drag-line"
      className={classnames('aspirin-drag-line', {
        'aspirin-drag-line-horizontal': direction === 'horizontal',
        'aspirin-drag-line-vertical': direction === 'vertical',
      })}
      ref={ref}
      style={{
        ...staticStyle,
        ...style,
      }}
    >
      <div className="show-line-background">
        <div
          className="show-line"
          style={{
            backgroundColor: color,
          }}
        ></div>
      </div>
    </div>
  );
};

type ContainerPropsType = {
  children?: any;
  style?: CSS.Properties;
  direction?: 'vertical' | 'horizontal';
  minSize?: number;
  /**
   * 打开拖动变化
   * @returns {void}
   */
  onDragMove?: (value: number | string) => void;
  onDragEnd?: (value: number | string) => void;
};

const Container = (props: ContainerPropsType) => {
  const {
    children,
    style = {},
    direction = 'vertical',
    minSize = MINSIZE,
    onDragMove,
    onDragEnd,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const firstRef = useRef<HTMLDivElement>(null);

  const handleMove = (v) => {
    if (firstRef?.current) {
      if (direction === 'vertical') {
        firstRef.current.style.height = `${v}px`;
        firstRef.current.style.maxHeight = `${v}px`;
        firstRef.current.style.minHeight = `${v}px`;
      } else {
        firstRef.current.style.width = `${v}px`;
        firstRef.current.style.maxWidth = `${v}px`;
        firstRef.current.style.minWidth = `${v}px`;
      }
    }
    onDragMove?.(v);
  };

  useDragLine({
    container: containerRef,
    line: lineRef,
    callback: handleMove,
    direction: direction,
    canDrag: true,
    minSize,
  });

  const [markChildren, setMarkChildren] = useState<any>(null);

  const init = useCallback(() => {
    setTimeout(() => {
      const markedChild = React.Children.map(children, (child, index) => {
        // 判断子元素是否符合标记条件
        if (child?.type === DragLine) {
          return React.cloneElement(child, {
            ref: lineRef,
            direction: direction,
            container: containerRef,
          });
        } else if (index === 0) {
          return React.cloneElement(child, {
            ref: firstRef,
          });
        }
        return child;
      });
      setMarkChildren(markedChild);
    }, 0);
  }, [children, direction]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div
      ref={containerRef}
      style={{
        ...style,
        flexDirection: direction === 'vertical' ? 'column' : 'row',
      }}
      className="aspirin-drag-line-container"
    >
      {markChildren ?? children}
    </div>
  );
};

DragLine = React.forwardRef(DragLine);
DragLine.Container = Container;

export default DragLine;
