import React, { useEffect, useState } from 'react';

import {
  VSTableColumn,
  VSTable,
  message,
  InputNumber,
  Checkbox,
} from '@vs/vsf-kit';
import { askFixed } from '@/pages/Index/components/func/ask';
import { getScrollY, getScrollYRem } from '@/utils';
import { numberToPercentage } from '@/utils/setDataUtil';
import Icon from '@/module/Icon';

function DetailTable({ values, selectRows, selecteDataChange }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData([
      ...values
        ?.map((i) => {
          const { outpBillingDetailVoList, ..._i } = i,
            id = Math.random();
          return {
            ...i,
            outpBillingDetailVoList: [
              {
                ..._i,
                headerRow: true,
                id: id,
              },
              ...outpBillingDetailVoList?.map((ele) => ({
                ...ele,
                parentId: id,
              })),
            ],
          };
        })
        ?.map((item) => item?.outpBillingDetailVoList)
        .flat(),
    ]);
  }, [values]);

  const sharedOnCell = (value) => {
    if (value?.headerRow) {
      return { colSpan: 0 };
    }

    return {};
  };
  const onChangeAmountRefund = async (node, value) => {
      await vsf?.refs?.settleMasterDetailListTable
        .getEditForm()
        .setFieldValue([node?.id, 'amountRefund'], value);
      await vsf?.refs?.settleMasterDetailListTable?.update?.({
        ...node,
        amountRefund: value,
      });
    },
    onChangeCheckbox = async (params) => {
      const { event, node } = params;
      await data
        ?.filter((ele) => ele?.parentId === node?.id)
        ?.map((ele) => {
          if (!event) return onChangeAmountRefund(ele, 0);
          return onChangeAmountRefund(ele, ele?.amount - ele?.amountPerform);
        });
    };
  return (
    <VSTable
      id="settleMasterDetailListTable"
      // scroll={{ y: 300, x: 1300 }}
      scroll={{ y: getScrollYRem(10), x: 1300 }}
      value={data}
      rowClassName={(node) => {
        return node?.headerRow ? 'aspirin-table-row-background-box' : '';
      }}
      className="aspirin-table-no-background-box"
      // rowSelection={{
      //   selectType: 'multiple',
      //   selectedRows: selectRows,
      // onSelectedRowsChange: (data, keys) => {
      // selecteDataChange(data);
      // hideInTable: true;
      //TODO
      // const copySelectData = data?.map((item) => {
      //   if (item?.orderClass === '中药') {
      //     const data = item?.outpBillingDetailVoList?.map((res) => {
      //       return {
      //         ...res,
      //         amountRefund: res?.amount - res?.amountPerform,
      //       };
      //     });
      //     return { ...item, outpBillingDetailVoList: data };
      //   }
      // });
      // if(copySelectData){
      //   selecteDataChange(copySelectData);
      // }else{
      //   selecteDataChange(data);
      // }
      // },
      // }}
      // rowKey={'receiptGroupId'}
      pagination={false}
      bordered
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onCanEdit: (node) => !node?.headerRow,
      }}
    >
      <VSTableColumn
        dataIndex={['itemClass']}
        title="类别"
        valueType="select"
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        fieldProps={{
          dataSource: vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT,
        }}
        preview
        render={(dom, value) => {
          return value?.headerRow ? value?.receiptGroupId : dom;
        }}
        // onCell={sharedOnCell}
      />
      <VSTableColumn
        dataIndex={['totalSelfPaymentRatio']}
        title="比例"
        valueType="text"
        preview
        width={120}
        render={(dom, value) => {
          return value?.headerRow
            ? value?.orderClass
            : value?.totalSelfPaymentRatio
            ? numberToPercentage(value?.totalSelfPaymentRatio, 0)
            : '';
        }}
      />

      <VSTableColumn
        dataIndex={['itemName']}
        title="项目"
        valueType="text"
        preview
        width={180}
        render={(dom, value) => {
          return value?.headerRow ? (
            <Icon type="icon-chengzu" style={{ color: '#3276e8' }} />
          ) : (
            dom
          );
        }}
      />

      <VSTableColumn
        dataIndex={['itemSpecification']}
        title="规格"
        valueType="text"
        preview
      />

      <VSTableColumn
        dataIndex={['price']}
        title="单价"
        valueType="text"
        preview
        onCell={(value, index) => {
          return value?.headerRow ? { colSpan: 4 } : { colSpan: 1 };
        }}
        render={(_, value) => {
          return value?.headerRow ? (
            <div style={{ color: '#3276E8' }}>
              开单:{value?.orderDepartment}
            </div>
          ) : value?.price ? (
            Number(value?.price ?? 0).toFixed(2)
          ) : (
            ''
          );
        }}
      />

      <VSTableColumn
        dataIndex={['amount']}
        title="数量"
        valueType="text"
        preview
        onCell={sharedOnCell}
      />

      <VSTableColumn
        dataIndex={['unit']}
        title="单位"
        valueType="select"
        preview
        onCell={sharedOnCell}
        dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
      />

      <VSTableColumn
        dataIndex={['cost']}
        title="金额"
        valueType="text"
        preview
        onCell={sharedOnCell}
      />
      <VSTableColumn
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="text"
        preview
        render={(dom, value) => {
          // orderDoctor
          return value?.headerRow ? value?.orderDoctor : dom;
        }}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="date"
        preview
        fieldProps={{ format: 'YYYY-MM-DD' }}
        render={(dom, value) => {
          return value?.headerRow ? '' : dom;
        }}
      />

      <VSTableColumn
        dataIndex={['amountPerform']}
        title="执行数量"
        valueType="text"
        preview
        fieldProps={{
          disabled: true,
        }}
        onCell={(value, index) => {
          return value?.headerRow ? { colSpan: 1 } : { colSpan: 1 };
        }}
        // subTotal
        render={(dom, value) => {
          // orderDoctor
          return value?.headerRow ? (
            <div>
              小计：{value?.subTotal ? askFixed(value?.subTotal, 2) : '0.00'}元
            </div>
          ) : (
            dom
          );
        }}
      />
      <VSTableColumn
        dataIndex={['amountRefund']}
        title="退费数量"
        valueType="digit"
        onCell={(value, index) => ({ colSpan: 1 })}
        fieldProps={(row, type) => {
          if (row?.headerRow) return {};
          let confing = {};
          if (row?.amountPerform === row?.amount) confing.disabled = true;
          return confing;
        }}
        render={(dom, node) =>
          node?.headerRow ? (
            <Checkbox onChange={(event) => onChangeCheckbox({ event, node })} />
          ) : (
            dom
          )
        }
        formItemProps={(_, { entity }) => {
          return {
            rules: [
              {
                validator(_, value) {
                  if ([undefined, null].includes(value)) {
                    return Promise.reject('退费数量不能为空'); //onChangeAmountRefund(entity, 0);
                  } else if (value < 0) {
                    return Promise.reject('退费数量不能小于0');
                  } else if (entity?.amount - entity?.amountPerform < value) {
                    return Promise.reject(
                      '退费数量大于可退数量，请重新确认退费数量',
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ],
          };
        }}
      />
      {/* <VSTableColumn
        dataIndex={['receiptGroupId']}
        title="类别"
        valueType="select"
        fieldProps={{}}
        width={150}
        onCell={(_, index) => ({
          colSpan: 1,
        })}
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8' }}>{values?.receiptGroupId}</div>
          );
        }}
      />

      <VSTableColumn
        dataIndex={['totalSelfPaymentRatio']}
        title="比例"
        valueType="text"
        fieldProps={{}}
        onCell={(_, index) => ({
          colSpan: 2,
        })}
        renderFormItem={(_, { record }) => {
          return record?.totalSelfPaymentRatio
            ? numberToPercentage(record?.totalSelfPaymentRatio, 0)
            : '';
        }}
      />

      <VSTableColumn
        dataIndex={['orderDepartment']}
        title="项目"
        valueType="text"
        fieldProps={{}}
        onCell={(_, index) => ({
          colSpan: 2,
        })}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8', textAlign: 'center' }}>
              开单:{values?.orderDepartment}
            </div>
          );
        }}
      />
      <VSTableColumn
        // dataIndex={['orderDepartment1']}
        title="规格"
        valueType="text"
        fieldProps={{}}
        // render={(_,values)=>{
        //   return <div style={{color:'#3276E8'}}>{values?.orderDepartment}</div>
        // }}
      />

      <VSTableColumn
        dataIndex={['orderDoctor']}
        title="单价"
        valueType="text"
        fieldProps={{}}
        render={(_, values) => {
          return <div style={{ color: '#3276E8' }}>{values?.orderDoctor}</div>;
        }}
      />

      <VSTableColumn
        dataIndex={['tallyIndicator']}
        title="数量"
        valueType="switch"
        fieldProps={{}}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8' }}>{values?.tallyIndicator}</div>
          );
        }}
      />

      <VSTableColumn
        dataIndex={['subTotal']}
        title="单位"
        valueType="digit"
        fieldProps={{}}
        onCell={(_, index) => ({
          colSpan: 2,
        })}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8', textAlign: 'center' }}>
              合计:{askFixed(values?.subTotal, 2)}
            </div>
          );
        }}
      />

      <VSTableColumn
        // dataIndex={['orderDate']}
        title="金额"
        valueType="text"
        onCell={(_, index) => ({
          colSpan: 0,
        })}
      />

      <VSTableColumn
        dataIndex={['diseaseType']}
        title="业务时间"
        valueType="text"
        fieldProps={{}}
        render={(_, values) => {
          return <div style={{ color: '#3276E8' }}>{values?.diseaseType}</div>;
        }}
        onCell={(_, index) => ({
          colSpan: 0,
        })}
      />
      <VSTableColumn
        dataIndex={['diseaseType']}
        title="执行科室"
        valueType="text"
        fieldProps={{}}
        render={(_, values) => {
          return <div style={{ color: '#3276E8' }}>{values?.diseaseType}</div>;
        }}
        onCell={(_, index) => ({
          colSpan: 0,
        })}
      />

      <VSTableColumn
        dataIndex={['diseaseTypeCode']}
        title="执行数量	"
        valueType="text"
        fieldProps={{}}
        onCell={(_, index) => ({
          colSpan: 0,
        })}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8' }}>{values?.diseaseTypeCode}</div>
          );
        }}
      />

      <VSTableColumn
        dataIndex={['singleSettleIndicator']}
        title="退费数量"
        valueType="switch"
        fieldProps={{}}
        render={(_, values) => {
          return (
            <div style={{ color: '#3276E8' }}>
              {values?.singleSettleIndicator}
            </div>
          );
        }}
      /> */}
    </VSTable>
  );
}

export default DetailTable;
