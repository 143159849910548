import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal, message } from '@vs/vsf-kit';
import React, { Fragment, useState, useRef } from 'react';
import ExamClassTree from './tree';
import Container from '@/module/Container';
import Tab from './tab';
import Tip from '@/pages/Index/components/func/Tip';
const ExamItemManage = (props) => {
  /**
   * DataList
   * @type {any}
   */
  const [dataList, setDataList] = useState([]);
  const [addNode, setAddNode] = useState(false);
  const [active, setActive] = useState('5');
  const ref = useRef(null);
  const handleAdd = (node) => {
    setAddNode({
      title: 'add',
      ...node,
    });
    setActive('1');
  };
  const handleStop = (node) => {
    Tip({
      title: '提示',
      content: `确定停用{【${node.examClassName}】}？该分类及子分类下的检查项目将全部停用，停用后不可恢复！`,
      onOk: async () => {
        const res =
          await vsf?.services?.ExamClassDictionaryBOController_stopExamClass_d5da55?.(
            { id: node.id },
          );
        if (res.code === 200) {
          message.success('停用成功');
          vsf.refs.ExamClassTree?.handleReload();
        }
        return res?.data;
      },
    });
  };
  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              padding: '12px 24px',
              bgColor: 'var(--background)',
            },
          ],
        }}
      >
        <ExamClassTree
          onChange={(_value, tree) => {
            setAddNode({
              title: 'update',
              ..._value,
            });
            setDataList([_value, tree]);
          }}
          active={active}
          setActive={setActive}
          onAdd={handleAdd}
          onStop={handleStop}
          ref={ref}
        />
        <Tab
          value={dataList}
          data={addNode}
          tab={active}
          onDetailChange={ref.current?.onChange}
          onChange={(value) => {
            setActive(value);
          }}
        />
      </Container>
    </Fragment>
  );
};
export default definePage(ExamItemManage);
