import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Dropdown,
  Menu,
  message,
  Modal,
  Section,
  Select,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import { divide, random } from 'lodash';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CertificateSearch } from '@/components';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import ActionChief from '@/pages/Index/emergency_treatment/preview_triage/modal/action_chief';
import { getScrollY } from '@/utils';

import table from '../../manage/department/table';
import {
  convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2DeleteCountErpAccidentEventRecordReq,
  convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2SaveSingleErpAccidentEventRecordFuncFlowExecutorReq,
  convertErpAccidentEventRecordSearchQtoForm2DeleteErpAccidentEventRecordReq,
  convertQueryErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchQtoRes2ErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable,
} from './convert';
import AddPatientModal from './modal/add_patient_modal';
import CreateEventModal from './modal/create_event_modal';
import PreviewTriageModal from './modal/preview_triage_modal';
import UserVerification from './modal/user_verification';

const Index = (props) => {
  const {
    user: { staffLoginWithoutResponseCookies },
    common: { currentApplication },
  } = props?.stores || {};

  const [search, setSearch] = useState(0);
  // table list
  const [tableList, setTableList] = useState<any>([]);
  // 待分诊
  const [toBeTriageList, setToBeTriageList] = useState<any>([]);
  // 已分诊
  const [triageList, setTriageList] = useState<any>([]);
  // 待评估
  const [toBeAssessmentList, setToBeAssessmentList] = useState<any>([]);
  // 已就诊
  const [assessmentList, setAssessmentList] = useState<any>([]);
  // 已退号
  const [withDrawList, setWithDrawList] = useState<any>([]);
  // 绿区
  const [greenAreaList, setGreenAreaList] = useState<any>([]);
  // 红/黄
  const [edOrYellowAreaList, setEdOrYellowAreaList] = useState<any>([]);
  // 分级list
  const [levelList, setLevelList] = useState<any>([]);

  // 当前点击选择的table行数据
  const [currentRow, setCurrentRow] = useState<any>();
  // 新增患者
  const addPatientModalRef = useRef<any>();
  // 新建事件
  const createEventModalRef = useRef<any>();
  // 查看详情-预检分诊
  const peviewTriageModalRef = useRef<any>();

  const [formValue, setFormValue] = useState<any>(null);
  // 删除患者/事件验证
  const userVerificationModalRef = useRef<any>();

  const actionCiefRef = useRef<any>();

  const [totalEmun, setTotalEmun] = useState<any>('ALL');

  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

  const totalNumberList = [
    { label: '待分诊', value: 'TO_BE_TRIAG' },
    { label: '已分诊', value: 'TRIAG' },
    { label: '待评估', value: 'TO_BE_ASSESSMEN' },
    { label: '已就诊', value: 'TENDIN' },
    { label: '已退号', value: 'WITHDRAW' },
    { label: '绿区', value: 'REEN_ARE' },
    { label: '红/黄', value: 'ED_OR_YELLOW_ARE' },
  ];

  const oneKeyList = [
    { label: '一键评估', key: 'oneKey' },
    { label: '意识状态', key: 'itemValue23' },
    { label: '判定依据', key: 'patientChiefComplaintVsJudgeResult' },
    { label: '实际分级', key: 'diseaseLevel' },
    { label: '病人去向', key: 'treatAreaCode' },
    { label: '分科科室', key: 'clinicRegister' },
  ];

  useEffect(() => {
    getLevel1();
  }, []);

  useEffect(() => {
    handelValue(totalEmun);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableList, totalEmun]);

  // table
  const onFetch_t5524 = useCallback(async () => {
    if (!formValue) return [];
    const {
      eventRecordEventNameIs,
      eventRecordEventCodeIs,
      createDateTime,
      certificate,
    } = vsf?.refs?.batchTriageForm?.getFieldsValue();
    const res =
      await vsf?.services?.ClinicErpPreExamController_queryErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchQto_b1a675?.(
        {
          qto: {
            eventRecordEventNameIs:
              eventRecordEventNameIs?.eventName ?? eventRecordEventNameIs,
            eventRecordEventCodeIs:
              eventRecordEventCodeIs?.eventCode ?? eventRecordEventCodeIs,
            eventRecordCreateDateTimeIs:
              createDateTime?.createDateTime ?? createDateTime,
          },
        },
      );

    const output: any =
      convertQueryErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchQtoRes2ErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable?.(
        res,
      );

    const _data =
      [...output]?.map((i) => {
        return {
          ...i,
          onCanEdit: true,
          rowKeyId: Math.random(),
          clinicRegister: i?.clinicRegister?.id
            ? {
                ...i?.clinicRegister,
                label:
                  i?.clinicRegister?.clinicRegisterDefine?.clinicDepartment
                    ?.departmentName,
                value: i?.clinicRegister?.id,
              }
            : null,
          itemValue23: i?.itemValue23 ? Number(i?.itemValue23) : null,
          patientChiefComplaintVsJudgeDictionaryDtoList: (
            i?.patientChiefComplaintVsJudgeDictionaryDtoList ?? []
          )?.map((i) => {
            return {
              ...i,
              secondId: i?.chiefComplaint?.id,
              secondName: i?.chiefComplaint?.chiefComplaintName,
              id: i?.chiefComplaintJudge?.id,
              chiefComplaintJudgeName:
                i?.chiefComplaintJudge?.chiefComplaintJudgeName,
              chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
            };
          }),
        };
      }) ?? [];

    setTableList([..._data]);
    // handelFilter(_data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    onFetch_t5524();
  }, [onFetch_t5524, search]);

  // 参考/实际分级
  const getLevel = async (params?) => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    const _res = res?.data?.map((i) => {
      return {
        ...i,
        label: i?.diseaseLevelName,
        value: i?.diseaseLevelCode,
      };
    });
    const output = params?.label
      ? _res?.filter((item) =>
          askSearch(params?.label, item, 'diseaseLevelName'),
        )
      : _res;

    return output ?? [];
  };

  // 参考/实际分级（初始获取）
  const getLevel1 = async () => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    setLevelList(res?.data);
  };

  // 分科
  const getTodayErpClinicRegister = async (params?) => {
    const res =
      await vsf?.services?.OutpRegisterController_getTodayErpClinicRegister_c4b2c6?.(
        {},
      );
    const _res = res?.data?.map((i) => {
      return {
        ...i,
        label: i?.clinicRegister?.clinicRegisterDefine.clinicLabel,
        value: i?.clinicRegister?.id,
      };
    });
    const output = params?.label
      ? _res?.filter((item) => askSearch(params?.label, item, 'label'))
      : _res;

    return output ?? [];
  };

  // 单行保存
  const handelSave = async (record) => {
    const selectTreatArea = vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.find(
      (i) => {
        return record?.treatAreaCode == i?.code;
      },
    );
    const list = [
      {
        item: 23,
        itemValue: record?.itemValue23?.score ?? record?.itemValue23,
      },
    ];
    const output =
      convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2SaveSingleErpAccidentEventRecordFuncFlowExecutorReq?.(
        {
          ...record,
          list,
          treatAreaSortCode:
            selectTreatArea &&
            selectTreatArea?.extensionJson &&
            JSON.parse(selectTreatArea?.extensionJson)?.treatAreaSortCode,
        },
      );

    const res =
      await vsf?.services?.ClinicErpPreExamController_saveSingleErpAccidentEventRecordFuncFlowExecutor_585f54?.(
        {
          createOrUpdatePatientBto: output?.createOrUpdatePatientBto,
          saveErpVisitForPreExamBto: output?.saveErpVisitForPreExamBto,
          createErpPreExamRecordBto: output?.createErpPreExamRecordBto,
          createPatientChiefComplaintVsJudgeBtoList:
            output?.createPatientChiefComplaintVsJudgeBtoList?.map((i) => {
              return {
                chiefComplaintId: i?.secondId,
                chiefComplaintJudgeId: i?.id,
                chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
              };
            }),
          saveErpAccidentEventRecordMasterBto: { id: formValue?.eventId },
          saveErpAccidentEventRecordDetailBto:
            output?.saveErpAccidentEventRecordDetailBto,
        },
      );
    if (res?.code == 200) {
      const _tableList = tableList?.map((i) => {
        return i?.id == record?.id
          ? {
              ...i,
              erpId: res?.data,
              rowKeyId: Math.random(),
              displayId: res?.data,
            }
          : i;
      });

      setTableList([..._tableList]);
      // handelFilter(_tableList);
      message.success('保存成功');
    }
    return res?.data;
  };

  // 清空
  const clearRowData = async (dom, record, form) => {
    const tempTableList = tableList?.map((i) => {
      if (record?.id == i?.id) {
        return {
          id: i?.id,
          rowKeyId: Math.random(),
          patientChiefComplaintVsJudgeDictionaryDtoList: [],
          onCanEdit: true,
        };
      } else {
        return { ...i };
      }
    });

    setTableList(tempTableList);
  };

  // 递归
  const recursion = (index: number, i: number): number | undefined => {
    if (activeIndex && index <= activeIndex) {
      // 检查是否超出数组边界
      if (index - i < 0) {
        return undefined;
      }
      if (tableList[index - i]?.erpId) {
        return i;
      } else {
        return recursion(index, i + 1);
      }
    } else {
      i;
    }
  };

  // 一键评估
  const handelKeyEvaluate = async (type) => {
    const { key } = type;

    const res =
      await vsf?.services?.OutpRegisterController_getTodayErpClinicRegister_c4b2c6?.(
        {},
      );

    let _tableList = [];
    if (key === 'oneKey') {
      _tableList = tableList?.map((i, index) => {
        // 拷贝的数据
        let obj = {};
        // 分科科室
        let clinicRegister;
        let n;
        if (activeIndex && index <= activeIndex) {
          n = recursion(index, 1);
        }
        if (i?.id == currentRow?.id) {
          // 判断复制过来的科室信息是否为最新的
          res?.data?.map((item) => {
            if (
              tableList[index - n]?.clinicRegister?.clinicRegisterDefine?.id ===
              item?.clinicRegister?.clinicRegisterDefine?.id
            ) {
              clinicRegister = {
                ...item,
                // id: item?.clinicRegister?.id,
                label: item?.clinicRegister?.clinicRegisterDefine?.clinicLabel,
                value: item?.clinicRegister?.id,
              };
            }
          });
          obj = {
            ...i,
            rowKeyId: Math.random(),
            itemValue23: tableList[index - n]?.itemValue23
              ? Number(tableList[index - n]?.itemValue23)
              : null,
            patientChiefComplaintVsJudgeResult:
              tableList[index - n]?.patientChiefComplaintVsJudgeResult,
            patientChiefComplaintVsJudgeDictionaryDtoList:
              tableList[index - n]
                ?.patientChiefComplaintVsJudgeDictionaryDtoList,
            referenceDiseaseLevel: tableList[index - n]?.referenceDiseaseLevel,
            diseaseLevel: tableList[index - n]?.diseaseLevel?.id
              ? {
                  ...tableList[index - n]?.diseaseLevel,
                  label: tableList[index - n]?.diseaseLevel?.diseaseLevelName,
                  value: tableList[index - n]?.diseaseLevel?.id,
                }
              : null,
            treatAreaCode: tableList[index - n]?.treatAreaCode,
            // clinicRegister: tableList[index - 1]?.clinicRegister,
            clinicRegister: clinicRegister,
          };
        } else {
          obj = { ...i };
        }
        return obj;
      });
    } else {
      if (key === 'itemValue23') {
        _tableList = tableList?.map((i, index) => {
          if (i?.id == currentRow?.id) {
            const _itemValue23 = tableList[index - 1]?.itemValue23
              ? {
                  ...i,
                  rowKeyId: Math.random(),
                  itemValue23: Number(tableList[index - 1]?.itemValue23),
                }
              : null;

            return { ...i, ..._itemValue23 };
          } else {
            return { ...i };
          }
        });
      } else if (key === 'patientChiefComplaintVsJudgeResult') {
        _tableList = tableList?.map((i, index) => {
          if (i?.id == currentRow?.id) {
            const _res = tableList[index - 1]?.referenceDiseaseLevel;
            const _patientChiefComplaintVsJudgeResult =
              tableList?.[index - 1]
                ?.patientChiefComplaintVsJudgeDictionaryDtoList?.length > 0
                ? {
                    ...i,
                    rowKeyId: Math.random(),
                    patientChiefComplaintVsJudgeResult:
                      tableList[index - 1]?.patientChiefComplaintVsJudgeResult,
                    patientChiefComplaintVsJudgeDictionaryDtoList:
                      tableList[index - 1]
                        ?.patientChiefComplaintVsJudgeDictionaryDtoList,
                    // itemValue23: Number(tableList[index - 1]?.itemValue23),
                    referenceDiseaseLevel: _res?.id
                      ? {
                          ..._res,
                          label: _res?.diseaseLevelName,
                          value: _res.id,
                        }
                      : null,
                    diseaseLevel: _res?.id
                      ? {
                          ..._res,
                          label: _res?.diseaseLevelName,
                          value: _res.id,
                        }
                      : null,
                  }
                : null;
            return {
              ...i,
              rowKeyId: Math.random(),
              ..._patientChiefComplaintVsJudgeResult,
            };
          } else {
            return { ...i };
          }
        });
      } else {
        _tableList = tableList?.map((i, index) => {
          return i?.id == currentRow?.id
            ? {
                ...i,
                rowKeyId: Math.random(),
                [key]: tableList[index - 1]?.[key],
              }
            : { ...i };
        });
      }
    }

    // 判断选中行是否已经有填过值（是否维护过）
    const _current = tableList?.find((i) => i?.id == currentRow?.id);

    if (
      (key == 'oneKey' &&
        (_current?.itemValue23 ||
          _current?.patientChiefComplaintVsJudgeResult ||
          _current?.diseaseLevel ||
          _current?.referenceDiseaseLevel ||
          _current?.treatAreaCode ||
          _current?.clinicRegister)) ||
      (key != 'oneKey' && _current?.[key])
    ) {
      Modal.confirm({
        centered: true,
        title: '提示',
        content: '所选患者存在已填写信息，是否强制同步所有信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          setTableList(_tableList);
        },
      });
    } else {
      setTableList(_tableList);
    }
  };

  // 用户验证
  const verification = async (doubleSignatureFormData) => {
    const res = await staffLoginWithoutResponseCookies(doubleSignatureFormData);
    return res;
    // if (res?.code == 200) {
    //   deletePatient();
    // }
  };

  // 删除患者
  const deletePatient = async () => {
    const output =
      convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2DeleteCountErpAccidentEventRecordReq?.(
        { id: currentRow?.id },
      );
    const res =
      await vsf?.services?.ClinicErpPreExamController_deleteCountErpAccidentEventRecord_48654f?.(
        { ...output },
      );
    if (res?.code == 200) {
      const _tableList = tableList?.filter((i) => i?.id != currentRow?.id);
      setTableList([..._tableList]);
      setCurrentRow(null);
      // handelFilter(_tableList);
      message.success('删除患者成功');
    }
    return;
  };

  // 删除事件
  const deleteEvent = async () => {
    const res =
      await vsf?.services?.ClinicErpPreExamController_deleteErpAccidentEventRecord_4f6d91?.(
        {
          btoParam: {
            id: formValue?.eventId,
          },
          extApiParam: {},
        },
      );
    if (res?.code == 200) {
      handelFilter([]);
      setCurrentRow(null);
      setTableList([]);
      handelChangeEvent();

      setTableList([]);
      message.success('删除事件成功');
    }
  };

  // 增加患者
  const addPatient = (value) => {
    const _value = value?.map((i) => {
      return { id: i, rowKeyId: Math.random() };
    });
    // handelFilter([...tableList, ..._value]);
    setTableList([...tableList, ..._value]);
  };

  // 新增事件
  const addEvent = async (v) => {
    if (v) {
      const res =
        await vsf?.services?.ClinicErpPreExamController_queryErpAccidentEventRecordMasterSearchVoByErpAccidentEventRecordMasterEventNameQto_93a2df?.(
          {
            qto: {
              eventCodeIs: v?.eventCode,
            },
          },
        );
      const _res = res?.data?.find((i) => i?.eventName === v?.eventName);

      vsf.refs?.batchTriageForm?.setFieldsValue({
        createDateTime: _res?.createDateTime,
        eventRecordEventNameIs: v?.eventName,
        eventId: _res?.id,
        eventRecordEventCodeIs: v?.eventCode,
      });

      setFormValue({
        createDateTime: _res?.createDateTime,
        eventRecordEventNameIs: v?.eventName,
        eventId: _res?.id,
        eventRecordEventCodeIs: v?.eventCode,
      });
      setTimeout(() => {
        setSearch(search + 1);
      }, 100);
    }
  };

  // 统计分类
  const handelFilter = (list) => {
    const _arr1: any = [];
    const _arr2: any = [];
    const _arr3: any = [];
    const _arr4: any = [];
    const _arr5: any = [];
    const _arr6: any = [];
    const _arr7: any = [];
    list?.forEach((i) => {
      if (!i?.displayId || (i?.displayId && !i?.erpId)) {
        _arr1.push(i);
      }
      if (i?.displayId && i?.erpId) {
        _arr2.push(i);
      }
      if (!i?.vitalsignAssessmentIndicator) {
        _arr3.push(i);
      }
      if (i?.outpVisit?.visitStatus == 'ENCOUNTER') {
        _arr4.push(i);
      }
      if (i?.outpVisit?.visitStatus == 'REFUND') {
        _arr5.push(i);
      }
      if (i?.preExamRecord?.treatAreaSortCode == '03') {
        _arr6.push(i);
      }
      if (
        i?.preExamRecord?.treatAreaSortCode == '02' ||
        i?.preExamRecord?.treatAreaSortCode == '01'
      ) {
        _arr7.push(i);
      }
    });
    // setToBeTriageList([...toBeTriageList, { ...i }]);
    // setTriageList([...triageList, { ...i }]);
    // setToBeAssessmentList([...toBeAssessmentList, { ...i }]);
    // setAssessmentList([...assessmentList, { ...i }]);
    // setWithDrawList([...withDrawList, { ...i }]);
    // setGreenAreaList([...greenAreaList, { ...i }]);
    // setEdOrYellowAreaList([...edOrYellowAreaList, { ...i }]);
    setToBeTriageList([..._arr1]);
    setTriageList([..._arr2]);
    setToBeAssessmentList([..._arr3]);
    setAssessmentList([..._arr4]);
    setWithDrawList([..._arr5]);
    setGreenAreaList([..._arr6]);
    setEdOrYellowAreaList([..._arr7]);
  };
  useEffect(() => {
    console.log(tableList, 'tableList');

    handelFilter(tableList);
  }, [tableList]);

  // 统计分类
  const handelValue = (type) => {
    if (type == 'ALL') {
      return tableList;
    } else if (type == 'TO_BE_TRIAG') {
      return toBeTriageList;
    } else if (type == 'TRIAG') {
      return triageList;
    } else if (type == 'TO_BE_ASSESSMEN') {
      return toBeAssessmentList;
    } else if (type == 'TENDIN') {
      return assessmentList;
    } else if (type == 'WITHDRAW') {
      return withDrawList;
    } else if (type == 'REEN_ARE') {
      return greenAreaList;
    } else if (type == 'ED_OR_YELLOW_ARE') {
      return edOrYellowAreaList;
    }
  };

  // 判断等级
  const judgeLevel = (items) => {
    if (!items || items?.length <= 0) return null;
    const maxItem = (items ?? []).reduce((prev, current) => {
      return prev.diseaseLevel?.diseaseLevel <
        current.diseaseLevel?.diseaseLevel
        ? prev
        : current;
    });
    return maxItem;
  };

  // 删除事件，或者事件类型修改时，事件名称和创建时间清空
  const handelChangeEvent = () => {
    vsf.refs?.batchTriageForm?.setFieldsValue({
      createDateTime: null,
      eventRecordEventNameIs: null,
      eventId: null,
    });
  };

  return (
    <div className="batch_triage">
      <div className="batch_triage_search">
        <VSForm labelAlign="left" id="batchTriageForm">
          <VSFormLayout
            columnCount={5}
            labelWidth={90}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <VSFormItem
              label="事件类型"
              valueType="selectCode"
              name={['eventRecordEventCodeIs']}
              dataSource={vsf.stores.dbenums.enums.EMERGENCY_EVENT_DICT}
              style={{ marginBottom: '10px' }}
              fieldProps={{
                showSearch: true,
                allowClear: true,
                style: {
                  width: 200,
                },
                onChange: (v) => handelChangeEvent(),
              }}
              required
            />
            <VSFormItem
              label="事件类型"
              valueType="text"
              name={['eventId']}
              style={{ marginBottom: '10px' }}
              fieldProps={{
                width: 200,
              }}
              hidden
            />
            <VSFormItem
              label="事件名称"
              valueType="select"
              name={['eventRecordEventNameIs']}
              style={{ marginBottom: '10px' }}
              required
              fieldProps={{
                showSearch: true,
                width: 200,
                allowClear: true,
                onChange: (v) => {
                  vsf.refs?.batchTriageForm?.setFieldsValue({
                    createDateTime: v?.createDateTime,
                    eventRecordEventCodeIs: v?.eventCode,
                    eventId: v?.id,
                  });
                },
              }}
              dataSource={async (param) => {
                if (
                  !vsf?.refs?.batchTriageForm?.getFieldsValue()
                    ?.eventRecordEventCodeIs &&
                  !param?.eventName
                )
                  return [];
                const res =
                  await vsf?.services?.ClinicErpPreExamController_queryErpAccidentEventRecordMasterSearchVoByErpAccidentEventRecordMasterEventNameQto_93a2df?.(
                    {
                      qto: {
                        eventCodeIs:
                          vsf?.refs?.batchTriageForm?.getFieldsValue()
                            ?.eventRecordEventCodeIs,
                        eventNameLike: param?.eventName,
                      },
                    },
                  );
                // const _data = (res?.data ?? [])?.sort((a: any, b: any) => {
                //   return (
                //     new Date(b?.createDateTime).getTime() -
                //     new Date(a?.createDateTime).getTime()
                //   );
                // });
                return res?.data ?? [];
              }}
              fieldNames={{ label: 'eventName', value: 'id' }}
            />
            <VSFormItem
              valueType="select"
              name={['createDateTime']}
              style={{ marginBottom: '10px' }}
              label="创建时间"
              required
              fieldProps={{
                allowClear: true,
                width: 200,
                onChange: (v) => {
                  vsf.refs?.batchTriageForm?.setFieldsValue({
                    eventRecordEventNameIs: v?.eventName,
                    eventRecordEventCodeIs: v?.eventCode,
                    eventId: v?.id,
                  });
                },
                showSearch: true,
              }}
              dataSource={async (param) => {
                if (
                  !vsf?.refs?.batchTriageForm?.getFieldsValue()
                    ?.eventRecordEventCodeIs
                )
                  return [];
                const { eventRecordEventNameIs, eventRecordEventCodeIs } =
                  vsf?.refs?.batchTriageForm?.getFieldsValue();
                const res =
                  await vsf?.services?.ClinicErpPreExamController_queryErpAccidentEventRecordMasterSearchVoByErpAccidentEventRecordMasterEventNameQto_93a2df?.(
                    {
                      qto: {
                        eventCodeIs:
                          eventRecordEventCodeIs?.eventCode ??
                          eventRecordEventCodeIs,
                        eventNameIs:
                          eventRecordEventNameIs?.eventName ??
                          eventRecordEventNameIs,
                      },
                    },
                  );
                return res?.data;
              }}
              fieldNames={{ label: 'createDateTime', value: 'id' }}
            />
            <Button
              // style={{ margin: '10px 30px 20px 10px', display: 'flex' }}
              // className="global_ant_btn"
              type="primary"
              // size="large"
              // className="icon16"
              onClick={async () => {
                await vsf?.refs?.batchTriageForm?.validateFieldsReturnFormatValue();
                setFormValue(vsf.refs?.batchTriageForm?.getFieldsValue());
                setTimeout(() => {
                  setSearch(search + 1);
                }, 100);
              }}
            >
              查询
            </Button>
            <VSFormItem
              name={['certificate']}
              label="证件及编号"
              valueType="custom"
              style={{ marginBottom: '10px' }}
              fieldProps={{}}
            >
              <CertificateSearch
                onEnter={(event) => {
                  const {
                    identificationClassIn,
                    identificationNumberIn,
                    patientInfo,
                  } = event;
                  if (!patientInfo?.displayId) return;
                  // 有选中行，选中行没有保存过
                  if (currentRow && !currentRow?.erpId) {
                    const res = tableList?.map((i) => {
                      return i?.id == currentRow?.id
                        ? {
                            ...i,
                            displayId: patientInfo?.displayId,
                            name: patientInfo?.name,
                            gender: patientInfo?.gender,
                            rowKeyId: Math.random(),
                            onCanEdit: false,
                          }
                        : { ...i, rowKeyId: Math.random() };
                    });

                    setTableList([...res]);
                  } else if (!currentRow) {
                    // 如果没有选中行，就找空白行，（没有修改过的）
                    // onCanEdit
                    const _find = tableList?.find(
                      (i) => !i?.erpId && i?.onCanEdit,
                    );
                    const res = tableList?.map((i) => {
                      return i?.id == _find?.id
                        ? {
                            ...i,
                            displayId: patientInfo?.displayId,
                            name: patientInfo?.name,
                            gender: patientInfo?.gender,
                            rowKeyId: Math.random(),
                            onCanEdit: false,
                          }
                        : { ...i, rowKeyId: Math.random() };
                    });

                    setTableList([...res]);
                  }
                }}
                // style={{
                //   height: 20,
                // }}
                inputProps={{
                  width: 520,
                }}
                selectProps={
                  {
                    // className: 'width280',
                  }
                }
              />
            </VSFormItem>
          </VSFormLayout>
        </VSForm>
      </div>
      <div className="batch_triage_body">
        <div className="batch_triage_body_total">
          <div
            className="left"
            onClick={() => {
              setTotalEmun('ALL');
            }}
          >
            总人数 {tableList?.length ?? 0}
          </div>
          <div className="right">
            {totalNumberList?.map((i, index) => {
              return (
                <div
                  className="item"
                  key={index}
                  style={{
                    color:
                      totalEmun == i?.value
                        ? index == 5
                          ? '#30A313'
                          : index == 6
                          ? '#E34A4E'
                          : '#3276e8'
                        : '',
                  }}
                  onClick={() => {
                    setTotalEmun(i?.value);
                  }}
                >
                  <div
                    className="content"
                    style={{
                      padding: index == 5 || index == 6 ? '0 14px' : '',
                      backgroundColor:
                        index == 5
                          ? 'rgba(80, 188, 53, 0.1)'
                          : index == 6
                          ? 'rgba(255, 126, 126, 0.1)'
                          : '',
                      // color:
                      //   index == 5 ? '#30A313' : index == 6 ? '#E34A4E' : '',
                    }}
                  >
                    <span style={{ marginRight: 5 }}>{i?.label}</span>
                    <span>{handelValue(i?.value)?.length}</span>
                  </div>
                  <div
                    style={{
                      alignContent: 'flex-end',
                      width: 1,
                      height: 8,
                      background: index == 6 ? 'transpartent' : '#D6D8D9',
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="batch_triage_body_VSTable">
          <VSTable
            id="batchTriage"
            className={classnames(
              'aspirin-table-nopadding aspirin-table-header-background-color  aspirin-table-row-hover-background-color aspirin-table-body-empty-transparent-background-color  table_overflow_384',
              isMinScreen ? 'table_overflow_510' : 'table_overflow_450',
            )}
            pagination={false}
            scroll={{ y: 0, x: isMinScreen ? 1600 : 1980 }}
            editable={{
              editType: 'multiple',
              onCanEdit: (row) => !row.erpId,
              columnProps: {
                hideInTable: true,
                fixed: 'right',
              },
              onFieldChange: debounce(
                (key, value, record) => {
                  const _res = tableList?.map((i) => {
                    return key == i?.rowKeyId
                      ? { ...i, ...value, onCanEdit: false }
                      : i;
                  });
                  console.log(_res, tableList, value, ';;;;;;;');

                  // debounce(
                  //   () => {
                  //     setTableList([..._res]);
                  //   },
                  //   500,
                  //   { leading: false, trailing: true },
                  // );
                  setTableList([..._res]);

                  // setTimeout(() => {
                  //   setTableList([..._res]);
                  // }, 1000);
                },
                500,
                { leading: false, trailing: true },
              ),
            }}
            rowClassName={(v) => {
              return v?.id == currentRow?.id
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            rowKey="rowKeyId"
            value={handelValue(totalEmun)}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  setActiveIndex(index);

                  if (currentRow && currentRow?.id == record?.id) {
                    setCurrentRow(null);
                  } else {
                    setCurrentRow(record);
                  }
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['patientId']}
              title="patientId"
              valueType="text"
              hideInTable
              editable={false}
              width={60}
            />
            <VSTableColumn
              dataIndex={['erpId']}
              title="erpId"
              valueType="text"
              hideInTable
              editable={false}
              width={60}
            />
            <VSTableColumn
              dataIndex={['id']}
              title="id"
              valueType="text"
              hideInTable
              editable={false}
              width={60}
            />
            <VSTableColumn
              // dataIndex={['status']}
              title="序号"
              valueType="index"
              editable={false}
              ellipsis
              width={isMinScreen ? 40 : 60}
            />

            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              ellipsis
              fieldProps={(record) => {
                return {
                  autoComplete: 'off',
                  allowClear: true,
                  onKeyUp: async (v: any) => {
                    // patientId需要接口查
                    if (v?.key == 'Enter') {
                      const response =
                        await vsf?.services?.PatientMainController_getPatientWithProfileByDisplayId_40245d?.(
                          {
                            displayId: v?.target?.value,
                          },
                        );
                      if (
                        response?.code == 200 &&
                        response?.data?.patientProfileDto?.patientId
                      ) {
                        const _tableList = tableList?.map((i) => {
                          return i?.id == record?.id
                            ? {
                                ...i,
                                displayId: response?.data?.displayId,
                                name: response?.data?.name,
                                gender: response?.data?.gender,
                                patientId:
                                  response?.data?.patientProfileDto?.patientId,
                                rowKeyId: Math.random(),
                              }
                            : i;
                        });
                        console.log(_tableList, tableList, response?.data);

                        setTableList([..._tableList]);
                      } else if (
                        response?.code == 200 &&
                        !response?.data?.patientProfileDto?.patientId
                      ) {
                        message.info('未查询到患者信息');
                      }
                    }
                  },
                  onClick: () => {
                    if (!currentRow || currentRow?.id != record?.id) {
                      setCurrentRow(record);
                    }
                  },
                };
              }}
              width={isMinScreen ? 60 : 100}
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              ellipsis
              valueType="text"
              fieldProps={(record) => {
                return {
                  autoComplete: 'off',
                  required: true,
                  allowClear: true,
                  onClick: () => {
                    if (!currentRow || currentRow?.id != record?.id) {
                      setCurrentRow(record);
                    }
                  },
                };
              }}
              width={isMinScreen ? 60 : 120}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
            />

            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              valueType="codeTableSelect"
              ellipsis
              fieldProps={{
                showSearch: true,
                allowClear: true,
              }}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT?.map((i) => {
                return { ...i, inputCode: i?.pinyin };
              })}
              width={isMinScreen ? 60 : 120}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
            />

            <VSTableColumn
              dataIndex={['itemValue23']}
              title="意识状态"
              ellipsis
              valueType="codeTableSelect"
              fieldNames={{ label: 'label', value: 'score' }}
              width={isMinScreen ? 60 : 160}
              fieldProps={{
                showSearch: true,
                allowClear: true,
              }}
              dataSource={vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.filter(
                (i) => i?.code == '21',
              )?.[0]?.children?.map((i) => {
                return {
                  ...i,
                  score: i?.extensionJson
                    ? JSON.parse(i?.extensionJson)?.score
                    : '',
                };
              })}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
              render={(_, record) => {
                const _data =
                  vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.filter(
                    (i) => i?.code == '21',
                  )?.[0]
                    ?.children?.map((i) => {
                      return {
                        ...i,
                        value: i?.extensionJson
                          ? JSON.parse(i?.extensionJson)?.score
                          : '',
                      };
                    })
                    ?.find(
                      (i) =>
                        i?.value ==
                        (record?.itemValue23?.score ?? record?.itemValue23),
                    );

                return <div>{_data?.label}</div>;
              }}
            />
            <VSFormItem
              name={['patientChiefComplaintVsJudgeDictionaryDtoList']}
              label="主诉及判定依据"
              valueType="select"
              // rules={[
              //   {
              //     message: '参考病情分级的值不合法',
              //     type: 'number',
              //     min: 0,
              //     max: undefined,
              //   },
              // ]}
              hidden
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['patientChiefComplaintVsJudgeResult']}
              title="主诉及判定依据"
              valueType="text"
              ellipsis
              fieldProps={(record) => {
                return {
                  allowClear: true,

                  onClick: (v) => {
                    if (!currentRow || currentRow?.id != record?.id) {
                      setCurrentRow(record);
                    }
                    actionCiefRef.current?.handelOpen(
                      true,
                      record?.patientChiefComplaintVsJudgeDictionaryDtoList,
                      record?.id,
                    );
                  },
                };
              }}
              width={isMinScreen ? 170 : 210}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
            />
            <VSTableColumn
              dataIndex={['referenceDiseaseLevel']}
              title="参考分级"
              ellipsis
              valueType="select"
              fieldProps={(row) => {
                return {
                  showSearch: true,
                  disabled: true,
                  allowClear: true,
                  style: {
                    color: row?.referenceDiseaseLevel?.colorCode,
                  },
                };
              }}
              fieldNames={{ label: 'label', value: 'value' }}
              width={isMinScreen ? 60 : 100}
              editable={false}
              dataSource={async (param) => {
                const res = getLevel(param);
                return res;
              }}
              render={(_, record) => {
                const res: any = [];
                const _item = levelList?.find(
                  (i) => i?.id == record?.referenceDiseaseLevel?.id,
                );

                return (
                  <div style={{ color: _item?.colorCode }}>
                    {record?.referenceDiseaseLevel?.diseaseLevelName}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['diseaseLevel']}
              title="实际分级"
              ellipsis
              valueType="codeTableSelect"
              fieldNames={{ label: 'label', value: 'value' }}
              fieldProps={(record) => {
                return {
                  showSearch: true,
                  allowClear: true,
                  onChange: (v, config) => {
                    const _res = levelList?.find(
                      (i) => i?.diseaseLevelCode == v,
                    );
                    const _tableList = tableList?.map((i) => {
                      return i?.id == record?.id
                        ? {
                            ...i,
                            rowKeyId: Math.random(),
                            treatAreaCode: _res?.treatArea,
                            diseaseLevel: {
                              ..._res,
                              label: _res?.diseaseLevelName,
                              value: v,
                            },
                          }
                        : i;
                    });
                    setTableList([..._tableList]);
                  },
                };
              }}
              width={isMinScreen ? 60 : 100}
              dataSource={async (param) => {
                const res = getLevel(param);
                return res;
              }}
              formItemProps={{
                rules: [{ required: true }],
              }}
              render={(_, record) => {
                const res: any = [];
                const _item = levelList?.find(
                  (i) => i?.id == record?.diseaseLevel?.id,
                );

                return (
                  <div style={{ color: _item?.colorCode }}>
                    {record?.diseaseLevel?.diseaseLevelName}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['treatAreaCode']}
              title="病人去向"
              valueType="codeTableSelect"
              ellipsis
              fieldProps={{ showSearch: true, allowClear: true }}
              dataSource={vsf.stores.dbenums?.enums?.TREAT_AREA_DICT}
              width={isMinScreen ? 64 : 140}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
            />

            <VSTableColumn
              dataIndex={['clinicRegister']}
              title="分科科室"
              ellipsis
              valueType="select"
              fieldProps={{ allowClear: true, showSearch: true }}
              width={isMinScreen ? 60 : 140}
              fieldNames={{ label: 'label', value: 'value' }}
              dataSource={getTodayErpClinicRegister}
              formItemProps={() => {
                return { rules: [{ required: true }] };
              }}
              render={(_, record) => {
                return record?.clinicRegister?.label;
              }}
            />
            <VSTableColumn
              dataIndex={['operation1']}
              title="操作按钮"
              valueType="custom"
              width={isMinScreen ? 160 : 300}
              fixed="right"
              align="center"
              // editable={false}
              renderFormItem={(dom, entity, form, action) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Button
                      type="link"
                      disabled={entity?.record?.erpId}
                      onClick={async (event) => {
                        event.stopPropagation();

                        const key = String(entity?.record?.rowKeyId);
                        const res = await form.validateFields([
                          [key, 'name'],
                          [key, 'gender'],
                          [key, 'itemValue23'],
                          [key, 'patientChiefComplaintVsJudgeResult'],
                          [key, 'diseaseLevel'],
                          [key, 'treatAreaCode'],
                          [key, 'clinicRegister'],
                        ]);
                        handelSave(entity?.record);
                      }}
                    >
                      保存
                    </Button>
                    <Button
                      disabled={entity?.record?.erpId}
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        // form?.setFieldsValue({
                        //   [entity?.record?.rowKeyId]: {
                        //     id: entity?.record?.id,
                        //     displayId: null,
                        //     name: null,
                        //     gender: null,
                        //     itemValue23: null,
                        //     itemValue231: null,
                        //     referenceDiseaseLevel: null,
                        //     diseaseLevel: null,
                        //     treatAreaCode: null,
                        //     clinicRegister: null,
                        //   },
                        // });

                        clearRowData(dom, entity?.record, form);
                      }}
                    >
                      清空
                    </Button>
                    <Button
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        message.info('todo');
                      }}
                    >
                      打印腕带
                    </Button>
                    <Button
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        message.info('todo');
                      }}
                    >
                      打印预检单
                    </Button>
                  </div>
                );
              }}
              render={(dom, record, form) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <Button
                      className="icon18"
                      disabled={record?.erpId}
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        handelSave(record);
                      }}
                    >
                      保存
                    </Button>
                    <Button
                      className="icon18"
                      type="link"
                      disabled={record?.erpId}
                      onClick={(event) => {
                        event.stopPropagation();
                        // form?.setFieldsValue({
                        //   [entity?.record?.rowKeyId]: {
                        //     id: entity?.record?.id,
                        //     displayId: null,
                        //     name: null,
                        //     gender: null,
                        //     itemValue23: null,
                        //     itemValue231: null,
                        //     referenceDiseaseLevel: null,
                        //     diseaseLevel: null,
                        //     treatAreaCode: null,
                        //     clinicRegister: null,
                        //   },
                        // });

                        clearRowData(dom, record, form);
                      }}
                    >
                      清空
                    </Button>
                    <Button
                      className="icon18"
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        message.info('todo');
                      }}
                    >
                      打印腕带
                    </Button>
                    <Button
                      className="icon18"
                      type="link"
                      onClick={(event) => {
                        event.stopPropagation();
                        message.info('todo');
                      }}
                    >
                      打印预检单
                    </Button>
                  </div>
                );
              }}
              // formItemProps={{
              //   className: 'width240',
              // }}
              // fieldProps={{
              //   className: 'width240',
              // }}
            />
            <VSTableColumn
              dataIndex="operation2"
              title="患者状态"
              align="center"
              valueType="text"
              width={isMinScreen ? 45 : 80}
              fixed="right"
              editable={false}
              render={() => {
                return (
                  <div>
                    <Button
                      type="link"
                      className="icon18"
                      onClick={(event) => {
                        event.stopPropagation();
                        message.info('todo');
                      }}
                    >
                      查看
                    </Button>
                  </div>
                );
              }}
            />
            <VSTableColumn
              dataIndex="operation3"
              title="预检信息"
              align="center"
              valueType="text"
              width={isMinScreen ? 45 : 80}
              fixed="right"
              editable={false}
              render={() => {
                return (
                  <div>
                    <Button
                      type="link"
                      className="icon18"
                      onClick={() => {
                        peviewTriageModalRef?.current?.handelOpen(true);
                      }}
                    >
                      查看详情
                    </Button>
                  </div>
                );
              }}
            />
          </VSTable>
        </div>

        <div className="batch_triage_bottom">
          <Button
            type="primary"
            size="large"
            className="icon16"
            ghost
            disabled={!currentRow?.id}
            onClick={() => {
              if (currentRow?.erpId) {
                userVerificationModalRef?.current?.handelOpen(true, 'patient');
              } else {
                deletePatient();
              }
            }}
          >
            删除患者
          </Button>
          <Button
            size="large"
            className="icon16"
            type="primary"
            ghost
            disabled={!formValue}
            onClick={() => {
              const _findAlsoSave = tableList?.some((i) => i?.erpId);
              if (_findAlsoSave)
                return message.info(
                  '有已分诊患者，无法删除事件，请先删除患者！',
                );

              userVerificationModalRef?.current?.handelOpen(true, 'event');
            }}
          >
            删除事件
          </Button>
          <Button
            size="large"
            className="icon16"
            type="primary"
            onClick={() => {
              message.info('todo');
            }}
          >
            导出患者
          </Button>
          <Button
            type="primary"
            onClick={() => {
              message.info('todo');
            }}
            size="large"
            className="icon16"
          >
            导入患者
          </Button>
          <Button
            size="large"
            className="icon16"
            type="primary"
            ghost
            // disabled={!currentRow?.erpId}
            disabled={!currentRow?.id || currentRow?.id == tableList?.[0]?.id}
          >
            <span
              onClick={() => {
                handelKeyEvaluate({ key: 'oneKey' });
              }}
            >
              一键评估
            </span>

            <Dropdown
              // disabled={!currentRow?.erpId}
              disabled={!currentRow?.id || currentRow?.id == tableList?.[0]?.id}
              dropdownRender={() => {
                return (
                  <Menu>
                    {oneKeyList?.map((i) => {
                      return (
                        <Menu.Item key={i?.key} onClick={handelKeyEvaluate}>
                          {i?.label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                );
              }}
            >
              <Icon type="icon-shang" style={{ color: '#3276E8' }} />
            </Dropdown>
          </Button>
          {/* <Dropdown
            disabled={!currentRow?.id || currentRow?.id == tableList?.[0]?.id}
            dropdownRender={() => {
              return (
                <Menu>
                  {oneKeyList?.map((i) => {
                    return (
                      <Menu.Item key={i?.key} onClick={handelKeyEvaluate}>
                        {i?.label}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              );
            }}
          >
            <Button
              size="small"
              type="primary"
              ghost
              disabled={!currentRow?.erpId}
            >
              一键评估
              <Icon type="icon-shang" style={{ color: '#3276E8' }} />
            </Button>
          </Dropdown> */}
          <Button
            size="large"
            className="icon16"
            type="primary"
            onClick={() => {
              message.info('todo');
            }}
          >
            就诊史
          </Button>
          <Button
            size="large"
            className="icon16"
            type="primary"
            disabled={!formValue}
            onClick={() => {
              addPatientModalRef?.current?.handelOpen(true, formValue);
            }}
          >
            增加患者
          </Button>
          <Button
            size="large"
            className="icon16"
            type="primary"
            onClick={() => {
              addPatientModalRef?.current?.handelOpen(true);
            }}
          >
            新建事件
          </Button>
        </div>
        <AddPatientModal
          ref={addPatientModalRef}
          addPatient={addPatient}
          addEvent={addEvent}
        />
        <CreateEventModal ref={createEventModalRef} />
        <PreviewTriageModal ref={peviewTriageModalRef} />
        <UserVerification
          ref={userVerificationModalRef}
          onOk={verification}
          deletePatient={deletePatient}
          deleteEvent={deleteEvent}
        />
        {/* 主诉及判定依据 */}
        <ActionChief
          ref={actionCiefRef}
          handelOk={(list, text, id) => {
            // 设置参考类型
            const _res = judgeLevel(list);
            const res: any = [];

            const _tableList = tableList?.map((i) => {
              const _item = levelList?.find(
                (current) =>
                  _res?.diseaseLevel?.diseaseLevelCode ==
                  current?.diseaseLevelCode,
              );
              return i?.id == id
                ? {
                    ...i,
                    patientChiefComplaintVsJudgeDictionaryDtoList: list,
                    patientChiefComplaintVsJudgeResult: text,
                    referenceDiseaseLevel: {
                      ..._res?.diseaseLevel,
                      label: _res?.diseaseLevel?.diseaseLevelName,
                      value: _res?.diseaseLevel?.diseaseLevelCode,
                    },
                    diseaseLevel: {
                      ..._res?.diseaseLevel,
                      label: _res?.diseaseLevel?.diseaseLevelName,
                      value: _res?.diseaseLevel?.diseaseLevelCode,
                    },
                    treatAreaCode: _item?.treatArea,
                    rowKeyId: Math.random(),
                  }
                : i;
            });
            setTableList([..._tableList]);
            // vsf.refs.preExamMainForm.setFieldsValue({
            //   patientChiefComplaintVsJudgeDictionaryDtoList: list,
            //   patientChiefComplaintVsJudgeResult: text,
            // });
          }}
        />
      </div>
    </div>
  );
};
export default definePage(Index);
