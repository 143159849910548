import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStoragePurchaseController_getPagefallSupplier_40a286: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-pagefall-supplier',
    parameterFomatter: (data?: {
      qto: PageFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugFirmDictionaryVoDrugStoragePurchaseEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStoragePurchaseController_getWaitAuditPlanDetail_92b242: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-wait-audit-plan-detail',
    parameterFomatter: (data?: { planMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPurchasePlanOutVoDrugStoragePurchaseEntranceWebVo[],
    ) => data,
  },
  DrugStoragePurchaseController_createPurchase_313b34: {
    method: 'post',
    url: '/api/drug-storage-purchase/update-supplier',
    parameterFomatter: (data?: {
      btoParam: UpdateSupplierBtoDrugStoragePurchaseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStoragePurchaseController_deleteSubmitPurchase_58af6a: {
    method: 'post',
    url: '/api/drug-storage-purchase/delete-submit-purchase',
    parameterFomatter: (data?: {
      btoParam: DeleteSavePurchaseBtoDrugStoragePurchaseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugStoragePurchaseController_deleteSubmitPurchaseDetail_1f94b8: {
    method: 'post',
    url: '/api/drug-storage-purchase/delete-submit-purchase-detail',
    parameterFomatter: (data?: { detailId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
