import React, { useState, useEffect, useRef, useCallback } from 'react';
import vsf from '@vs/vsf-boot';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { message } from '@vs/vsf-kit';

import CheckTitle from './components/checkTitle';
import CheckTontext from './components/checkcontext';
import CheckFooter from './components/checkFooter';
import dayjs from 'dayjs';

import './index.less';
import '@/style/adaptation.less';

// 获取年龄
const getAge = (user) => {
  const birthDate = dayjs(user.birthday);
  const newToday = dayjs();
  let age = newToday.diff(birthDate, 'year');
  if (
    newToday.month() < birthDate.month() ||
    (newToday.month() === birthDate.month() &&
      newToday.date() < birthDate.date())
  ) {
    age--;
  }
  return age;
};

export default function Check_check_rollback() {
  const [activeList, setactiveList] = useState([]);
  const [searchInp, setSearchInp] = useState();
  // 搜索日期
  const [timer, setTimer] = useState([
    dayjs().subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().format('YYYY-MM-DD HH:mm:ss'),
  ]);
  // 接口接收数据
  const [dataAll, setDataAll] = useState([]);
  // tab项
  const [tabValue] = useOnChangeValue('EXAM');

  // 关闭
  const onCancel = () => {
    // vsf?.history?.go(-1);
    vsf?.refs?.ExportRecord?.resetFields();
    setDataAll([]);
    vsf?.refs?.ExportRecord?.setFieldsValue();
    message.success('清空成功');
  };

  // 确认回退
  const onSore = async () => {
    const refundReason = vsf?.refs?.refundReason?.input?.value;
    if (!!refundReason) {
      const orderIds = activeList.map((item) => item.id);
      await vsf?.services?.OutpOrderRefundApplyController_refundApplyOrders_d3ab4c(
        { orderIds, refundReason },
      );
    } else {
      message.info('请填写退回原因');
    }
  };

  // 获取检查/检验列表
  const getCheckList = async (type = tabValue) => {
    // 患者ID
    const patientIdIs = vsf?.refs?.ExportRecord?.getFieldValue().id;
    if (!!patientIdIs) {
      let orderClassIn;
      if (!!type?.value) {
        orderClassIn = type?.value === 'EXAM' ? ['EXAM', 'PATHOLOGY'] : ['LAB'];
      } else {
        orderClassIn = type === 'EXAM' ? ['EXAM', 'PATHOLOGY'] : ['LAB'];
      }

      // 开始时间
      let submitDateTimeBiggerThanEqual = timer[0];
      // 结束时间
      let submitDateTimeLessThan = timer[1];
      // // 获取列表数据
      const res =
        await vsf?.services?.RefundOrderController_getCanRefundOrders_dbe730?.({
          qto: {
            orderClassIn, // 检验 ：[LAB] 检查：[EXAM,PATHOLOGY]
            patientIdIs, // 患者ID
            submitDateTimeBiggerThanEqual, //开始时间
            submitDateTimeLessThan, // 结束时间
          },
        });

      setDataAll(res?.data);
    } else {
      // message.error('患者ID不能为空');
      vsf?.refs?.ExportRecord?.resetFields();
      setDataAll([]);
      vsf?.refs?.ExportRecord?.setFieldsValue();
    }
  };

  // 切换时间
  const tabTimer = async () => {
    // 患者ID
    const patientIdIs = vsf?.refs?.ExportRecord?.getFieldValue().id;
    if (!!patientIdIs) {
      // 获取tab参数
      let orderClassIn =
        tabValue?.value === 'EXAM' ? ['EXAM', 'PATHOLOGY'] : ['LAB'];

      const submitDateTimeBiggerThanEqual = timer[0];
      const submitDateTimeLessThan = timer[1];
      // // 获取列表数据
      const res =
        await vsf?.services?.RefundOrderController_getCanRefundOrders_dbe730?.({
          qto: {
            orderClassIn, // 检验 ：[LAB] 检查：[EXAM,PATHOLOGY]
            patientIdIs, // 患者ID
            submitDateTimeBiggerThanEqual, //开始时间
            submitDateTimeLessThan, // 结束时间
          },
        });
      setDataAll(res?.data);
    } else {
      vsf?.refs?.ExportRecord?.resetFields();
      setDataAll([]);
      vsf?.refs?.ExportRecord?.setFieldsValue();
    }
  };

  // 查询患者信息
  const getPatientID = async (patientID) => {
    // 患者ID
    if (!!patientID) {
      const res =
        await vsf?.services?.PatientMainController_getPatientWithProfileByDisplayId_40245d?.(
          {
            displayId: patientID,
          },
        );
      if (Object.keys(res?.data).length === 0) {
        vsf?.refs?.ExportRecord?.resetFields();
        setDataAll([]);
        vsf?.refs?.ExportRecord?.setFieldsValue();
        message.error('患者ID输入错误');
      }
      // 添加年龄
      const age = getAge(res.data);
      res.data.age = age;
      // 覆盖患者数据
      vsf?.refs?.ExportRecord?.setFieldsValue(res.data);
    } else {
      vsf?.refs?.ExportRecord?.resetFields();
      setDataAll([]);
      vsf?.refs?.ExportRecord?.setFieldsValue();
      message.error('请输入患者ID');
    }
  };

  // 过滤
  const data = useCallback(() => {
    const timers = vsf?.refs?.ExportRecord.getFieldValue()?.submitDateTime;
    const events = dataAll?.filter((item) => {
      const startTime = dayjs(item.submitDateTime);
      return startTime.isAfter(timers[0]) && startTime.isBefore(timers[1]);
    });
    return events;
  }, [dataAll]);

  // 确认回退禁用判断
  const [disabledFlag, setDisabledFlag] = useState(false);
  useEffect(() => {
    activeList.length === 0 ? setDisabledFlag(true) : setDisabledFlag(false);
  }, [activeList]);

  return (
    <div className="check-check-rollback">
      <CheckTitle
        getPatientID={getPatientID}
        getCheckList={getCheckList}
        tabValue={tabValue}
        tabTimer={tabTimer}
        timer={timer}
        setTimer={setTimer}
        setSearchInp={setSearchInp}
      />
      <CheckTontext
        onFetch={getPatientID}
        data={data}
        setactiveList={setactiveList}
        tabValue={tabValue}
      />
      <CheckFooter
        onSore={onSore}
        onCancel={onCancel}
        disabledFlag={disabledFlag}
      />
    </div>
  );
}
