import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, message } from '@vs/vsf-kit';
import React, { Fragment, useState, useRef } from 'react';
import Tree from './tree';
import Container from '@/module/Container';
import Tab from './tab';
import Tip from '@/pages/Index/components/func/Tip';
const TreatItemManage = (props) => {
  /**
   * DataList
   * @type {any}
   */
  const [dataList, setDataList] = useState([]);
  const [addNode, setAddNode] = useState(false);
  const [active, setActive] = useState('3');

  const ref = useRef(null);

  const handleAdd = (node) => {
    setAddNode({
      title: 'add',
      ...node,
    });
    setActive('1');
  };
  const handleStop = (node) => {
    Tip({
      title: '操作窗口',
      content: `确定停用{【${node.treatClassName}】}？该分类及子分类下的检查项目将全部停用，停用后不可恢复！`,
      onOk: async (params) => {
        const res =
          await vsf?.services?.TreatController_stopTreatClass_476e69?.({
            id: node.id,
          });
        if (res.code === 200) {
          message.success('停用成功');
          vsf?.refs?.TreatClassTree?.handleReload();
        }
        return res?.data;
      },
      centered: true,
      okText: '确定',
      cancelText: '取消',
    });
  };

  const onChange = (key) => {
    setActive(key);
  };

  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '12px 24px',
            },
          ],
        }}
      >
        <Tree
          onChange={(_value, tree) => {
            setAddNode({
              title: 'update',
              ..._value,
            });
            setDataList([_value, tree]);
          }}
          onAdd={handleAdd}
          onStop={handleStop}
          ref={ref}
        />
        <Tab
          value={dataList}
          data={addNode}
          onChange={onChange}
          tab={active}
          onDetailChange={ref.current?.onChange}
        />
      </Container>
    </Fragment>
  );
};
export default definePage(TreatItemManage);
