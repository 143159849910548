import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Image,
  message,
  Select,
  VSFormItem,
  Modal,
  VSForm,
  VSFormLayout,
  Space,
  Button,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { cloneDeep } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';

import { filterEmptyValues } from '@/utils/setDataUtil';
import Icon from '@/module/cpoe/Icon';
import readCard from '@/assets/icon/readCard.png';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

const PrimaryIndexModal = ({ callback }) => {
  /**
   * convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq
   * @param {any} input
   * @returns {any}
   */
  const [fullInfo, setFullInfo] = useState();
  const [formData, setFormData] = useState();

  const insuranceTypeIsRef = useRef();
  const firstFetch = useRef(true);

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.qto = {};
      const search = input.search;
      output.qto.displayIdIs = `${_.get(search, 'displayIdIs')}`?.length
        ? _.get(search, 'displayIdIs')
        : undefined;
      output.qto.nameLike = `${_.get(search, 'nameLike')}`?.length
        ? _.get(search, 'nameLike')
        : undefined;
      output.qto.nameInputCodeLike = `${_.get(search, 'nameInputCodeLike')}`
        ?.length
        ? _.get(search, 'nameInputCodeLike')
        : undefined;
      output.qto.genderIs = _.get(search, 'genderIs');
      output.qto.cellphoneIs = `${_.get(search, 'cellphoneIs')}`?.length
        ? _.get(search, 'cellphoneIs')
        : undefined;
      const birthdayBegin = _.get(search, 'birthdayRangeIn.begin');
      const birthdayEnd = _.get(search, 'birthdayRangeIn.end');
      if (birthdayBegin || birthdayEnd) {
        output.qto.birthdayRangeIn = {};
        output.qto.birthdayRangeIn.begin = birthdayBegin;
        output.qto.birthdayRangeIn.end = birthdayEnd;
        output.qto.birthdayRangeIn.beginInclude = _.get(
          search,
          'birthdayRangeIn.beginInclude',
        );
        output.qto.birthdayRangeIn.endInclude = _.get(
          search,
          'birthdayRangeIn.endInclude',
        );
      }
      const ageBegin = _.get(search, 'ageRangeIn.begin');
      const ageEnd = _.get(search, 'ageRangeIn.end');
      if (ageBegin || ageEnd) {
        output.qto.ageRangeIn = {};
        output.qto.ageRangeIn.begin = ageBegin;
        output.qto.ageRangeIn.end = ageEnd;
        output.qto.ageRangeIn.beginInclude = _.get(
          search,
          'ageRangeIn.beginInclude',
        );
        output.qto.ageRangeIn.endInclude = _.get(
          search,
          'ageRangeIn.endInclude',
        );
      }
      if (insuranceTypeIsRef.current) {
        output.qto.identificationClassIn = [insuranceTypeIsRef.current];
      }
      if (input?.search?.identNumber) {
        output.qto.identificationNumberIn = [input?.search?.identNumber];
      }
      output.qto.size = input.pagination?.size ?? 20;
      output.qto.from = input.pagination?.from ?? 0;
      output.ext = {};
      return output;
    };

  const convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo =
    (input) => {
      const convertResult = input?.map((item) => ({
        id: item?.id,
        displayId: item?.displayId,
        name: item?.name,
        cellphone: item?.cellphone,
        gender: item?.gender,
        birthday: item?.birthday,
        age: item?.age,
        birthAddress:
          (item?.birthAddress?.province ?? '') +
          (item?.birthAddress?.city ?? '') +
          (item?.birthAddress?.county ?? '') +
          (item?.birthAddress?.detail ?? ''),
        currentAddress:
          (item?.currentAddress?.province ?? '') +
          (item?.currentAddress?.city ?? '') +
          (item?.currentAddress?.county ?? '') +
          (item?.currentAddress?.detail ?? ''),
        contactName: item?.patientProfileDto?.contact?.name ?? '',
        contactPhoneNumber: item?.patientProfileDto?.contact?.phoneNumber ?? '',
        contactRelationshipType:
          item?.patientProfileDto?.contact?.relationshipType ?? '',
        patientMergeWithProfileDtoList:
          item?.patientMergeWithProfileDtoList ?? [],
        isMerged: item?.isMerged,
      }));
      return convertResult;
    };

  const onFetch = useCallback(
    async (params) => {
      console.log(formData);
      try {
        if (!firstFetch.current) {
          // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
          // TODO: 你需要自己补充入参input来源及出参output用法
          console.debug(params);
          const output =
            convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq(
              params,
            ); // Tips: 以下是你选择的请求函数
          const res =
            await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
              {
                qto: {
                  ...formData,
                  from: 0,
                  size: 1000,
                },
              },
            ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
          // TODO: 你需要自己补充入参input来源及出参output用法
          const customResult =
            convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo(
              res?.data?.result ?? [],
            );

          const originData = (customResult ?? res?.data ?? [])?.map(
            (item, _idx) => ({ ...item, customIndex: _idx + 1 }),
          );
          const realData = cloneDeep(originData);
          originData?.forEach((item, _idx) => {
            if (item?.patientMergeWithProfileDtoList?.length) {
              realData.splice(
                realData?.findIndex((v) => v?.id === item?.id) + 1,
                0,
                ...(convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo(
                  item?.patientMergeWithProfileDtoList?.map((v) => ({
                    ...v?.patientRelated,
                    isMerged: true,
                  })),
                ) ?? []),
              );
            }
          });
          return {
            data: realData,
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }
        firstFetch.current = false;
      } catch (error) {
        console.error(error);
      }
    },
    [formData],
  );

  const handelRowClick = async (record) => {
    const response =
      await vsf?.services?.PatientMainController_getFullInfoById_6ee318?.({
        id: record?.id,
      });
    if (response && response.code === 200) {
      setFullInfo(response?.data);
      callback(response?.data);
    }
  };

  return (
    <>
      <VSForm labelAlign="left" id="IndexSelectForm">
        <VSFormLayout>
          <VSFormItem
            name={['identNumber']}
            label="证件及编号"
            valueType="text"
            fieldProps={{
              //   width: '578px',
              addonBefore: (
                <>
                  <Select
                    style={{ width: '150px' }}
                    dataSource={certificateData}
                    fieldNames={{ label: 'name', value: 'code' }}
                  />
                </>
              ),

              suffix: (
                <div
                  className="suffix-readCard"
                  onClick={() => {
                    message.success('读卡中');
                  }}
                >
                  <div className="suffix-cardImg">
                    <Image
                      src={readCard}
                      style={{ width: '20px', height: '20px' }}
                      preview={false}
                    ></Image>
                  </div>
                  <div className="suffix-cardText">读卡</div>
                </div>
              ),
              placeholder: '请读卡',
            }}
          />
          <VSFormItem name={'nameLike'} label="姓名" valueType="text" />
          <VSFormItem
            name={'nameInputCodeLike'}
            label="姓名拼音"
            valueType="text"
          />
          <VSFormItem
            name={'gender'}
            label="性别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          />
        </VSFormLayout>
        <VSFormLayout>
          <VSFormItem name={'birthday'} label="出生日期" valueType="date" />
          <VSFormItem
            name={'ageRangeIn'}
            label="年龄"
            valueType="digitRange"
            // searchConfig={{
            //   transform: (values) => {
            //     if (!Array.isArray(values)) return values;
            //     return {
            //       ageRangeIn: {
            //         begin: values[0],
            //         end: values[1],
            //         beginInclude: true,
            //         endInclude: true,
            //       },
            //     };
            //   },
            // }}
          />
          <VSFormItem name={'displayIdIs'} label="患者ID" valueType="text" />
          <VSFormItem name={'cellphoneIs'} label="手机号" valueType="text" />
        </VSFormLayout>
        <VSFormLayout>
          <div style={{ textAlign: 'right', marginBottom: '12px' }}>
            <Space>
              <Button
                onClick={() => {
                  vsf?.refs?.IndexSelectForm?.resetFields();
                }}
              >
                重置
              </Button>
              <Button
                onClick={() => {
                  const data = filterEmptyValues(
                    vsf?.refs?.IndexSelectForm?.getFieldsValue(),
                  );
                  setFormData(data);
                }}
                type="primary"
              >
                查询
              </Button>
            </Space>
          </div>
        </VSFormLayout>
      </VSForm>
      <VSPromiseTable
        vsid="92658"
        scroll={{
          x: 1700,
          y: 450,
        }}
        className="aspirin-table patient_main_search"
        id="vSPromiseTablePatientMainSearch"
        searchConfig={{
          labelWidth: 120,
          labelAlign: 'left',
        }}
        pagination={false}
        onFetch={onFetch}
        rowClick={{
          rowClickType: {
            action: 'double-click',
          },
          onRowClick: handelRowClick,
        }}
        rowClassName={(record) => (record?.isMerged ? 'merge_row' : '')}
      >
        <VSTableColumn
          width={60}
          dataIndex={['customIndex']}
          title="序号"
          valueType="text"
        />

        <VSTableColumn
          width={180}
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          //   search
          order={-5}
          columnKey={'displayIdIs'}
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div style={{ display: 'flex' }}>
                {dom}
                {record?.patientMergeWithProfileDtoList?.length ? (
                  <div style={{ color: '#3F76C8' }}>
                    <Icon type="icon-chengzulianjie" size={16} />
                  </div>
                ) : null}
              </div>
            );
          }}
        />

        <VSTableColumn
          width={140}
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          //   search
          order={-2}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ],
            label: '姓名',
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={154}
          dataIndex={['cellphone']}
          title="手机号"
          valueType="text"
          //   search
          order={-6}
          columnKey={'cellphoneIs'}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
            label: '手机号',
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={80}
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          //   search
          order={-4}
          columnKey={'genderIs'}
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          fieldProps={{}}
          formItemProps={{ label: '性别' }}
        />

        <VSTableColumn
          width={154}
          dataIndex={['birthday']}
          title="出生日期"
          valueType="date"
          //   search
          order={-7}
          columnKey={'birthdayRangeIn'}
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          formItemProps={{}}
        />

        <VSTableColumn
          width={100}
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          width={240}
          dataIndex={['birthAddress']}
          title="出生地"
          valueType="address"
          fieldProps={{}}
        />

        <VSTableColumn
          width={300}
          dataIndex={['currentAddress']}
          title="现住址"
          valueType="address"
          fieldProps={{}}
          formItemProps={{}}
        />

        <VSTableColumn
          width={108}
          dataIndex={['contactName']}
          title="联系人"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '联系人姓名长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
            label: undefined,
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={154}
          dataIndex={['contactPhoneNumber']}
          title="联系人电话"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '联系人的联系电话长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
            label: undefined,
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={86}
          dataIndex={['contactRelationshipType']}
          title="关系"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
          fieldProps={{}}
          formItemProps={{}}
        />

        <VSTableColumn
          // search
          hideInTable
          dataIndex={['ident']}
          title="证件及编号"
          valueType="custom"
          fieldProps={{}}
          formItemProps={{
            style: {
              width: 700,
            },
          }}
          renderFormItem={() => {
            return (
              <VSFormItem
                name={['identNumber']}
                label=""
                valueType="text"
                fieldProps={{
                  //   width: '578px',
                  addonBefore: (
                    <>
                      <Select
                        style={{ width: '150px' }}
                        dataSource={certificateData}
                        fieldNames={{ label: 'name', value: 'code' }}
                      />
                    </>
                  ),

                  suffix: (
                    <div
                      className="suffix-readCard"
                      onClick={() => {
                        message.success('读卡中');
                      }}
                    >
                      <div className="suffix-cardImg">
                        <Image
                          src={readCard}
                          style={{ width: '20px', height: '20px' }}
                          preview={false}
                        ></Image>
                      </div>
                      <div className="suffix-cardText">读卡</div>
                    </div>
                  ),
                  placeholder: '请读卡',
                }}
              />
            );
          }}
        />

        <VSTableColumn
          dataIndex={['nameInputCodeLike']}
          title="姓名拼音"
          valueType="text"
          //   search
          order={-3}
          columnKey={'nameInputCodeLike'}
          hideInTable
          formItemProps={{
            rules: [
              {
                message: '姓名拼音长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          //   search
          order={-8}
          hideInTable
          columnKey={'ageRangeIn'}
          valueType="digitRange"
          title="年龄"
          searchConfig={{
            transform: (values) => {
              if (!Array.isArray(values)) return values;
              return {
                ageRangeIn: {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              };
            },
          }}
        />
      </VSPromiseTable>
    </>
  );
};
export default definePage(PrimaryIndexModal);
