import React from 'react';
import { Modal, Button, Row, Col, Space, message } from '@vs/vsf-kit';
import ModalComTemplate from './modalComTemplate';
import { getTableValues, scrollTo, addTableRow } from './methods';
// 计费模板导入
const NurseEntryModalComTemplate = (props) => {
  const modalFooter = () => {
    return (
      <Row>
        <Col
          span={12}
          style={{ textAlign: 'left' }}
          className="modal-fee-revivw-memo"
        >
          说明：明细中红色字体为价表已经停用的费用明细
        </Col>
        <Col span={12}>
          <Space>
            <Button onClick={() => props.setModalTemplateOpen(false)}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                addTableRow('expenseDetailsTableCreate', props.templates).then(
                  (d) =>
                    scrollTo(
                      'expenseDetailsTableCreate',
                      getTableValues('expenseDetailsTableCreate')?.[
                        getTableValues('expenseDetailsTableCreate').length - 1
                      ]?.id,
                      '',
                    ),
                );
                message.success('导入成功!');
                props.setModalTemplateOpen(false);
              }}
            >
              导入
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Modal
        title={<div className="modal-fee-revivw">计费模板</div>}
        open={props.modalTemplateOpen}
        footer={modalFooter()}
        onCancel={() => {
          props.setModalTemplateOpen(false);
        }}
        width={1246}
      >
        <ModalComTemplate setTemplates={props.setTemplates} />
      </Modal>
    </>
  );
};
export default NurseEntryModalComTemplate;
