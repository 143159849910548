import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import { isObject, omitBy } from 'lodash';
// 	查询当前院区病区（护理单元）列表
export const getWardPagedBySearchCode = async (param) => {
  const res =
    await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
      { searchCode: param?.searchCode },
    );
  return res?.data?.result;
};
//查询床位病区的在床患者简单信息列表
export const getListClinicPatientBedSimpleVoByBedWardId = async (param) => {
  if (!param?.bedWardId) return [];
  const res =
    await vsf?.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
      {
        bedWardId: param?.bedWardId,
      },
    );
  return res?.data;
};
//获取患者信息
export const getInpVisitInfo = async (params) => {
  const res =
    await vsf?.services?.FinanceBillingRecordController_getInpVisitInfo_a4e217?.(
      { inpVisitId: params?.inpVisitId },
    );
  const output = convertGetInpVisitInfoRes2BillingAduitInpVisitInfo?.(res);
  return output;
};
/**
 * convertGetInpVisitInfoRes2BillingAduitInpVisitInfo
 * @param {any} input
 * @returns {any}
 */
const convertGetInpVisitInfoRes2BillingAduitInpVisitInfo = (input) => {
  const _ = window._ ?? {};
  const output: any = {}; // TODO 请确认下行字段是否符合逻辑
  output.id = _.get(input, 'data.inpVisit.id');
  output.patientId = _.get(input, 'data.patientId'); // TODO 请确认下行字段是否符合逻辑
  output.wardId = _.get(
    input,
    'data.inpVisit.patientInHospitalBaseDto.ward.id',
  ); // TODO 请确认下行字段是否符合逻辑
  output.bedId = _.get(input, 'data.inpVisit.patientInHospitalBaseDto.bed.id'); // TODO 请确认下行字段是否符合逻辑
  output.department = _.get(
    input,
    'data.inpVisit.patientInHospitalBaseDto.bed.department',
  ); // TODO 请确认下行字段是否符合逻辑
  output.name = _.get(
    input,
    'data.inpVisit.inpVisitExtensionPatientInfoDto.name',
  ); // TODO 请确认下行字段是否符合逻辑
  output.gender = _.get(
    input,
    'data.inpVisit.inpVisitExtensionPatientInfoDto.gender',
  ); // TODO 请确认下行字段是否符合逻辑
  output.chargeType = _.get(
    input,
    'data.inpVisit.inpVisitExtensionBillingInfoDto.chargeType',
  ); // TODO 请确认下行字段是否符合逻辑
  output.identityCode = _.get(
    input,
    'data.inpVisit.inpVisitExtensionBillingInfoDto.identityCode',
  ); // TODO 请确认下行字段是否符合逻辑
  output.admissionDateTime = _.get(input, 'data.inpVisit.admissionDateTime');
  output.sealAccountantIndicator = _.get(input, 'data.sealAccountantIndicator')
    ? '已封账'
    : '未封账'; // TODO 没有匹配到合适字段
  output.auditStatus = _.get(input, 'data.auditStatus'); // String 患者ID
  output.auditSuccessIndicator = _.get(input, 'data.auditSuccessIndicator');
  output.inpVisitId = _.get(input, 'data.inpVisit.id');
  output.department = _.get(
    input,
    'data.inpVisit.patientInHospitalBaseDto.department',
  );
  // output.inpVisitRecord = {};
  // TODO 没有匹配到合适字段 output.inpVisitRecord.inpVisitId = null; // Long 住院主记录id
  // TODO 没有匹配到合适字段 output.inpVisitRecord.admissionDateTime = null; // Date 入院时间
  return output;
};
// 获取患者信息。
export const queryPagedByPatientFullQto = async (params) => {
  const res =
    await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
      { qto: { ...params, from: 0, size: 20 } },
    );
  if (res?.data?.result?.length <= 0) return message?.error('未查询到患者');
  return res?.data?.result;
};
// 查询患者未结算住院主记录
export const getNoSettleInpVisitList = async (param) => {
  const res =
    await vsf?.services?.FinanceBillingRecordController_getNoSettleInpVisitList_52aaed?.(
      {
        displayId: param?.displayId,
      },
    );
  return res?.data;
};
// 	护士站补退费查询医嘱信息
export const queryOrderInfoForNurseStation = async (params) => {
  const res =
    await vsf?.services?.NurseBillingController_queryOrderInfoForBillingStation_23262f?.(
      {
        inpVisitId: params?.inpVisitId,
        orderClass: params?.orderClass,
        repeatIndicator: params?.repeatIndicator,
        orderTextLike: params?.orderTextLike,
        billingAttribute: params?.billingAttribute,
      },
    );
  const output =
    convertQueryOrderInfoForNurseStationRes2BillingAuditOrderInfoListRo?.(res);
  return {
    data: output ?? [],
    total: output?.length ?? 0,
    totalData: {
      totalManualBilling: res?.data?.[0]?.totalManualBilling,
      totalNormalBilling: res?.data?.[0]?.totalNormalBilling,
      totalNotBilling: res?.data?.[0]?.totalNotBilling,
    },
  };
};
/**
 * convertQueryOrderInfoForNurseStationRes2BillingAuditOrderInfoListRo
 * @param {any} input
 * @returns {any}
 */
const convertQueryOrderInfoForNurseStationRes2BillingAuditOrderInfoListRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data[0].orderInfo')
      ?.map((d, i) => {
        const obj: any = {};
        obj.id = Math?.random() + '_' + _.get(d, 'id');
        obj.orderId = _.get(d, 'id');
        obj.orderText = _.get(d, `orderText`);
        obj.repeatIndicator = _.get(d, `repeatIndicator`);
        obj.billingAttribute = _.get(d, `billingAttribute`);
        obj.patientId = _.get(d, `patientId`);
        obj.orderClass = _.get(d, `orderClass`);
        obj.dosage = _.get(d, `dosage`);
        obj.administration = _.get(d, `administration`);
        obj.frequency = _.get(d, `frequency`);
        obj.drugBillingAttribute = _.get(
          d,
          `orderDrugDto.drugBillingAttribute`,
        );
        obj.performSchedulePlan = _.get(
          d,
          `orderPerformDto.performSchedulePlan`,
        );
        obj.lastBillingDateTime = _.get(
          d,
          `orderPerformDto.lastBillingDateTime`,
        );
        obj.lastPerformPlanDateTime = _.get(
          d,
          `orderPerformDto.lastPerformPlanDateTime`,
        );
        obj.startPerformDateTime = _.get(d, `startPerformDateTime`);
        obj.stopOrderDateTime = _.get(d, `stopOrderDateTime`);
        obj.groupNumber = _.get(d, `groupNumber`);
        obj.groupSubNumber = _.get(d, `groupSubNumber`);
        obj.orderClass = _.get(d, `orderClass`);
        obj.orderDepartment = _.get(d, `orderDepartment`);
        return obj;
      })
      ?.map((ele) => ({ ...ele, drugOrder: ele })) ?? []),
  );
  return output;
};
// 查询医嘱计费项目分组信息
export const getOrderBillingInpBillingDetailGroupInfo = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getOrderBillingInpBillingDetailGroupInfo_5b1e07?.(
      { inpVisitId: params?.inpVisitId, orderIdList: params?.orderIdList },
    );
  return {
    data: res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
// 费用审核按照床位分组查询患者住院记录床位费、杂费
export const getBedAndMiscBillingDetailGroupInfo = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getBedAndMiscBillingDetailGroupInfo_5246ca?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetBedAndMiscBillingDetailGroupInfoRes2BillingAuditBedAndMiscDetailGroupList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetBedAndMiscBillingDetailGroupInfoRes2BillingAuditBedAndMiscDetailGroupList
 * @param {any} input
 * @returns {any}
 */
const convertGetBedAndMiscBillingDetailGroupInfoRes2BillingAuditBedAndMiscDetailGroupList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.startDate = _.get(d, 'startDate');
        obj.endDate = _.get(d, 'endDate');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemId = _.get(d, 'itemId');
        obj.itemName = _.get(d, 'itemName');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.unit = _.get(d, 'unit');
        obj.price = _.get(d, 'price');
        obj.totalAmount = _.get(d, 'totalAmount');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 查询患者本次就诊记录未结算费用明细
export const getInpBillingDetailByInpVisitId = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getInpBillingDetailByInpVisitId_f676a5?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetInpBillingDetailByInpVisitIdRes2BillAuditAllBillingDetailList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetInpBillingDetailByInpVisitIdRes2BillAuditAllBillingDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetInpBillingDetailByInpVisitIdRes2BillAuditAllBillingDetailList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.id = Math?.random() + '_' + _.get(d, 'id');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemName = _.get(d, 'itemName');
        obj.itemId = _.get(d, 'itemId');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.brand = _.get(d, 'brand');
        obj.price = _.get(d, 'price');
        obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
        obj.adjustAmount = null; // Bigdecimal 调整数量
        // obj.memo = _.get(d, 'memo');
        obj.unit = _.get(d, 'unit');
        obj.cost = _.get(d, 'cost');
        obj.orderDate = _.get(d, 'orderDate');
        obj.applyDepartment = _.get(d, 'applyDepartment');
        obj.performDepartment = _.get(d, 'performDepartment');
        obj.operateDate = _.get(d, 'operateDate');
        // obj.operator = _.get(d, 'operator');
        obj.operatorStaffName = _.get(d, 'operator.staffName');
        obj.operatorStaffId = _.get(d, 'operator.id');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.patientId = _.get(d, 'patientId');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        return obj;
      }) ?? []),
    );
    return output;
  };

// 保存费用明细
export const createInpBillingDetailForBillingStation = async (event) => {
  const output =
    convertBillingAuditOrderDetailList2CreateInpBillingDetailForBillingStationReq?.(
      event,
    );
  const res =
    await vsf?.services?.InpBillingDetailController_createInpBillingDetailForBillingStation_52012b?.(
      output,
    );
  return res;
};
/**
 * convertBillingAuditOrderDetailList2CreateInpBillingDetailForBillingStationReq
 * @param {any} input
 * @returns {any}
 */
export const convertBillingAuditOrderDetailList2CreateInpBillingDetailForBillingStationReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailEoList = [];
    output.saveInpBillingDetailEoList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          obj.id = _.get(d, `id`)?.split('_')?.[1]; // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`);
        }
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`); // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartment.id`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartment.id`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          ['', null, undefined, NaN].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
// 获取其他费用
export const getOtherInpBillingDetail = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getOtherInpBillingDetail_165b8e?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetOtherInpBillingDetailRes2BillingAuditOtherBillingDetailList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetOtherInpBillingDetailRes2BillingAuditOtherBillingDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetOtherInpBillingDetailRes2BillingAuditOtherBillingDetailList = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.TheRowType = 'Sava';
      obj.id = Math?.random() + '_' + _.get(d, 'id');
      obj.inpVisitId = _.get(d, 'inpVisitId');
      obj.itemClass = _.get(d, 'itemClass');
      obj.itemId = _.get(d, 'itemId');
      obj.itemName = _.get(d, 'itemName');
      obj.itemSpecification = _.get(d, 'itemSpecification');
      obj.unit = _.get(d, 'unit');
      obj.brand = _.get(d, 'brand');
      obj.price = _.get(d, 'price');
      obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
      obj.adjustAmount = null; // Bigdecimal 调整数量
      // obj.memo = _.get(d, 'memo');
      obj.cost = _.get(d, 'cost');
      obj.orderDate = _.get(d, 'orderDate'); // TODO 请确认下行字段是否符合逻辑
      obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName'); // TODO 请确认下行字段是否符合逻辑
      obj.applyDepartmentId = _.get(d, 'applyDepartment.id');
      obj.patientId = _.get(d, 'patientId'); // TODO 请确认下行字段是否符合逻辑
      obj.performDepartmentId = _.get(d, 'performDepartment.id'); // TODO 请确认下行字段是否符合逻辑
      obj.performDepartmentName = _.get(d, 'performDepartment.departmentName');
      obj.operateDate = _.get(d, 'operateDate'); // TODO 请确认下行字段是否符合逻辑
      obj.operatorStaffName = _.get(d, 'operator.staffName');
      obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
      return obj;
    }) ?? []),
  );
  return output;
};
// 保存其他费用
export const createOtherBillingDetailForBillingStation = async (event) => {
  // TODO: 请确认入参来源及出参用法
  const output =
    convertBillingAuditOtherBillingDetailList2CreateOtherBillingDetailForBillingStationReq?.(
      event,
    );
  const res =
    await vsf?.services?.InpBillingDetailController_createOtherBillingDetailForBillingStation_9efff4?.(
      output,
    );
  return res;
};

/**
 * convertBillingAuditOtherBillingDetailList2CreateOtherBillingDetailForBillingStationReq
 * @param {any} input
 * @returns {any}
 */
const convertBillingAuditOtherBillingDetailList2CreateOtherBillingDetailForBillingStationReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailEoList = [];
    output.saveInpBillingDetailEoList.push(
      ...(input?.map((d, i) => {
        const obj: any = {}; // TODO 没有匹配到合适字段
        if (['Sava'].includes(d?.TheRowType)) {
          obj.id = _.get(d, `id`)?.split('_')?.[1]; // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`);
        }
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`); // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartment.id`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartment.id`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          ['', null, undefined, NaN].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
// 获取床位杂费
export const getBedAndMiscBillingDetailByInpVisitId = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getBedAndMiscBillingDetailByInpVisitId_3458e2?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetBedAndMiscBillingDetailByInpVisitIdRes2BillAuditBedAndMiscDetailList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetBedAndMiscBillingDetailByInpVisitIdRes2BillAuditBedAndMiscDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetBedAndMiscBillingDetailByInpVisitIdRes2BillAuditBedAndMiscDetailList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.id = Math?.random() + '_' + _.get(d, 'id');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemName = _.get(d, 'itemName');
        obj.itemId = _.get(d, 'itemId');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.brand = _.get(d, 'brand');
        obj.price = _.get(d, 'price');
        obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
        obj.adjustAmount = null; // Bigdecimal 调整数量
        // obj.memo = _.get(d, 'memo');
        obj.unit = _.get(d, 'unit');
        obj.cost = _.get(d, 'cost');
        obj.orderDate = _.get(d, 'orderDate');
        obj.applyDepartment = _.get(d, 'applyDepartment');
        obj.performDepartment = _.get(d, 'performDepartment');
        obj.operateDate = _.get(d, 'operateDate');
        obj.operator = _.get(d, 'operator');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 保存床位杂费
export const createBedAndMiscBillingDetailForBillingStation = async (event) => {
  // TODO: 请确认入参来源及出参用法
  const output =
    convertBillAuditBedAndMiscDetailList2CreateBedAndMiscBillingDetailForBillingStationReq?.(
      event,
    );
  const res =
    await vsf?.services?.InpBillingDetailController_createBedAndMiscBillingDetailForBillingStation_e59dc8?.(
      output,
    );
  return res;
};
/**
 * convertBillAuditBedAndMiscDetailList2CreateBedAndMiscBillingDetailForBillingStationReq
 * @param {any} input
 * @returns {any}
 */
const convertBillAuditBedAndMiscDetailList2CreateBedAndMiscBillingDetailForBillingStationReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailEoList = [];
    output.saveInpBillingDetailEoList.push(
      ...(input?.map((d, i) => {
        const obj: any = {}; // TODO 没有匹配到合适字段
        if (['Sava'].includes(d?.TheRowType)) {
          obj.id = _.get(d, `id`)?.split('_')?.[1]; // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`);
        }
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`); // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartment.id`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartment.id`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          ['', null, undefined, NaN].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
// 获取费用信息 明细
export const getInpBillingDetailByOrderId = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getInpBillingDetailByOrderId_2827f8?.(
      {
        inpVisitId: params?.inpVisitId,
        orderId: params?.orderId,
        orderIdList: params?.orderIdList,
      },
    );
  const output =
    convertGetInpBillingDetailByOrderIdRes2BillingAuditOrderDetailList?.(res);
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetInpBillingDetailByOrderIdRes2BillingAuditOrderDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetInpBillingDetailByOrderIdRes2BillingAuditOrderDetailList = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.TheRowType = 'Sava';
      obj.id = Math?.random() + '_' + _.get(d, 'id');
      obj.itemClass = _.get(d, 'itemClass');
      obj.itemName = _.get(d, 'itemName');
      obj.itemId = _.get(d, 'itemId');
      obj.itemSpecification = _.get(d, 'itemSpecification');
      obj.brand = _.get(d, 'brand');
      obj.price = _.get(d, 'price');
      obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
      obj.adjustAmount = null; // Bigdecimal 调整数量
      // obj.memo = _.get(d, 'memo');
      obj.unit = _.get(d, 'unit');
      obj.cost = _.get(d, 'cost');
      obj.orderDate = _.get(d, 'orderDate');
      obj.applyDepartment = _.get(d, 'applyDepartment');
      obj.performDepartment = _.get(d, 'performDepartment');
      obj.operateDate = _.get(d, 'operateDate');
      obj.operator = _.get(d, 'operator');
      obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
      return obj;
    }) ?? []),
  );
  return output;
};
// 保存费用信息 明细
export const createInpBillingDetailForBillingStation2 = async (event) => {
  const output =
    convertBillingAuditOrderDetailList2CreateInpBillingDetailForBillingStationReq2?.(
      event,
    );
  const res =
    await vsf?.services?.InpBillingDetailController_createInpBillingDetailForBillingStation_52012b?.(
      output,
    );
  return res;
};
const convertBillingAuditOrderDetailList2CreateInpBillingDetailForBillingStationReq2 =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.saveInpBillingDetailEoList = [];
    output.saveInpBillingDetailEoList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          obj.id = _.get(d, `id`)?.split('_')?.[1]; // Long 住院费用明细id
          obj.adjustAmount = _.get(d, `adjustAmount`);
        }
        obj.inpVisitId = _.get(d, `inpVisitId`); // Long 住院主记录id
        obj.itemId = _.get(d, `itemId`); // String 价表项目ID
        obj.patientId = _.get(d, `patientId`); // Long 患者id
        obj.itemName = isObject(_.get(d, `itemName`))
          ? _.get(d, `itemName`)?.label
          : _.get(d, `itemName`); // String 项目名称
        obj.amount = _.get(d, `amount`); // Bigdecimal 数量
        obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
        obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
        obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
        obj.memo = _.get(d, `memo`); // String 补退说明
        obj.operatorId = _.get(d, `operatorStaffId`); // Long 录入人
        obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
        obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
        obj.applyDepartmentId = _.get(d, `applyDepartment.id`); // Long 开单科室
        obj.applyStaffId = _.get(d, `applyStaffId`); // Long 开单医生
        obj.performDepartmentId = _.get(d, `performDepartment.id`); // Long 执行科室
        obj.orderId = _.get(d, `orderId`); // Long 医嘱id
        obj.performStaffId = _.get(d, `performStaffId`); // Long 执行人
        return omitBy(obj, (value) =>
          ['', null, undefined, NaN].includes(value),
        );
      }) ?? []),
    );
    return output;
  };
// 划价
export const bedAndMiscBillDetailManualPricing = async (params) => {
  const res =
    await vsf?.services?.FinanceBillingController_bedAndMiscBillDetailManualPricing_2d2e72?.(
      {
        inpVisitId: params?.inpVisitId,
        includeToday: params?.includeToday,
      },
    );
  return res?.data;
};
// 审核
export const patientBillingAuditForBillingStation = async (params) => {
  const res =
    await vsf?.services?.FinanceBillingRecordController_patientBillingAuditForBillingStation_8cc57e?.(
      {
        inpVisitId: params?.inpVisitId,
        successIndicator: params?.successIndicator,
      },
    );
  return res?.data;
};
