import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpRefundDispenseController_getInpRefundApplyPatientCount_c54706: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-refund-apply-patient-count',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitDispenseAuditPatientCountGroupWardEoDrugDispenseAuditPersistEo[],
    ) => data,
  },
});
