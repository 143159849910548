// 列表查询
export const convertGet = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output?.push(
    ...(_.get(input, 'data.result')?.map((d) => {
      const obj = {};
      obj.bedLabel = _.get(d, 'bedLabel');
      obj.bedStatus = _.get(d, 'bedStatus');
      obj.wardName = _.get(d, 'wardName');
      obj.newDoctorOrderIndicator = _.get(
        d,
        'clinicPatientBedDto.newDoctorOrderIndicator',
      );
      obj.preTransferIndicator = _.get(
        d,
        'clinicPatientBedDto.preTransferIndicator',
      );
      obj.skinTestPositiveIndicator = _.get(
        d,
        'clinicPatientBedDto.skinTestPositiveIndicator',
      );
      obj.skinTestDrugName = _.get(d, 'clinicPatientBedDto.skinTestDrugName');
      obj.patientAllergyIndicator = _.get(
        d,
        'clinicPatientBedDto.patientAllergyIndicator',
      );
      obj.notPrintAppointExamOrderIndicator = _.get(
        d,
        'clinicPatientBedDto.notPrintAppointExamOrderIndicator',
      ); // 已预约未检查
      obj.nursingClass = _.get(d, 'clinicPatientBedDto.nursingClass');
      // obj.patientAllergy = (
      //   _.get(d, 'clinicPatientBedDto.patientAllergy') ?? []
      // ).map(
      //   (pa) =>
      //     pa.allergyDrugClassName ||
      //     pa.allergyDrugName ||
      //     pa.allergyDrugProductName ||
      //     pa.allergyName,
      // );
      obj.patientAllergy = (
        _.get(d, 'clinicPatientBedDto.patientAllergy') ?? []
      ).map(
        (pa) =>
          pa.allergyName ||
          pa.allergyDrugProductName ||
          pa.allergyDrugName ||
          pa.allergyDrugClassName,
      );
      obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');
      obj.crisisIndicator = _.get(d, 'clinicPatientBedDto.crisisIndicator');
      obj.seriousIndicator = _.get(d, 'clinicPatientBedDto.seriousIndicator');
      obj.waitExamReportIndicator = _.get(
        d,
        'clinicPatientBedDto.waitExamReportIndicator',
      );
      obj.pathwayImportIndicator = _.get(
        d,
        'clinicPatientBedDto.pathwayImportIndicator',
      );
      obj.drugResistanceType = _.get(
        d,
        'clinicPatientBedDto.drugResistanceType',
      );
      obj.drugResistanceName = _.get(
        d,
        'clinicPatientBedDto.drugResistanceName',
      );
      obj.expectedDischargeDate = _.get(
        d,
        'clinicPatientBedDto.expectedDischargeDate',
      );
      obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
      obj.displayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
      obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
      obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
      obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
      obj.attendingDoctorName = _.get(
        d,
        'clinicPatientBedDto.attendingDoctorName',
      );
      obj.chargeType = _.get(d, 'clinicPatientBedDto.chargeType');
      obj.admissionWardDateTime = _.get(
        d,
        'clinicPatientBedDto.admissionWardDateTime',
      );
      obj.admissionDateTime = _.get(d, 'clinicPatientBedDto.admissionDateTime'); // 入院时间
      obj.admissionDays = _.get(d, 'clinicPatientBedDto.admissionDays'); // 入院天数
      obj.needPayAmount = _.get(d, 'clinicPatientBedDto.needPayAmount'); // 欠费金额
      obj.dischargeVisitType = _.get(
        d,
        'clinicPatientBedDto.dischargeVisitType',
      ); // 出院类别
      obj.medicalGroupName = _.get(d, 'clinicPatientBedDto.medicalGroupName'); //诊疗组名称
      obj.operationEndDateTime = _.get(
        d,
        'clinicPatientBedDto.operationEndDateTime',
      );
      obj.vteIndicator = _.get(d, 'clinicPatientBedDto.vteIndicator');
      obj.vteScore = _.get(d, 'clinicPatientBedDto.vteScore');
      obj.catheterIndicator = _.get(d, 'clinicPatientBedDto.catheterIndicator');
      obj.catheterScore = _.get(d, 'clinicPatientBedDto.catheterScore');
      obj.pressureIndicator = _.get(d, 'clinicPatientBedDto.pressureIndicator');
      obj.pressureScore = _.get(d, 'clinicPatientBedDto.pressureScore');
      obj.painIndicator = _.get(d, 'clinicPatientBedDto.painIndicator');
      obj.painScore = _.get(d, 'clinicPatientBedDto.painScore');
      obj.fallIndicator = _.get(d, 'clinicPatientBedDto.fallIndicator');
      obj.fallScore = _.get(d, 'clinicPatientBedDto.fallScore');
      obj.id = _.get(d, 'id');
      obj.attendingDoctorId = _.get(d, 'clinicPatientBedDto.attendingDoctorId');
      obj.inChargeDoctorId = _.get(d, 'clinicPatientBedDto.inChargeDoctorId');
      obj.inChargeNurseId = _.get(d, 'clinicPatientBedDto.inChargeNurseId');
      obj.inChargeNurseName = _.get(d, 'clinicPatientBedDto.inChargeNurseName');
      obj.admissionWardDays = _.get(d, 'clinicPatientBedDto.admissionWardDays');
      obj.beforeDischargeDays = _.get(
        d,
        'clinicPatientBedDto.beforeDischargeDays',
      );
      obj.afterOperationDays = _.get(
        d,
        'clinicPatientBedDto.afterOperationDays',
      );
      obj.diagnosisDescription = _.get(
        d,
        'clinicPatientBedDto.diagnosisDescription',
      );
      obj.isolationIndicator = _.get(
        d,
        'clinicPatientBedDto.isolationIndicator',
      );
      output.isolationType = _.get(
        input,
        'data.isolationIndicator.isolationType',
      );
      obj.dischargeWay = _.get(d, 'clinicPatientBedDto.dischargeWay'); //出
      return obj;
    }) ?? []),
  );
  return output;
};
// 换床详情转换
export const convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo =
  (input) => {
    const _ = window._ ?? {};
    const d = _.get(input, 'data');
    const obj = {};
    obj.bedLabel = _.get(d, 'bedLabel');
    obj.patientBedRecordId = _.get(d, 'clinicPatientBedDto.id');
    obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
    obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
    obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
    obj.attendingDoctorName = _.get(
      d,
      'clinicPatientBedDto.attendingDoctorName',
    );
    obj.patientAllergy = _.get(d, 'clinicPatientBedDto.patientAllergy')?.map(
      (pa) =>
        pa.allergyName ||
        pa.allergyDrugProductName ||
        pa.allergyDrugName ||
        pa.allergyDrugClassName,
    );

    // obj.patientAllergy = _.get(d, 'clinicPatientBedDto.patientAllergy')?.map(
    //   (pa) => {
    //     if (pa.allergyDrugProductName) {
    //       return pa.allergyDrugProductName;
    //     } else if (pa.allergyDrugName) {
    //       return pa.allergyDrugName;
    //     } else {
    //       return pa.allergyDrugClassName;
    //     }
    //   },
    // );
    obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
    obj.admissionWardDays = _.get(d, 'clinicPatientBedDto.admissionWardDays');
    obj.id = _.get(d, 'id');
    obj.wardId = _.get(d, 'wardId');
    obj.bedStatus = _.get(d, 'bedStatus');
    obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');
    obj.bedWardId = _.get(d, 'clinicPatientBedDto.bedWardId');
    obj.patientBedLabel = _.get(d, 'clinicPatientBedDto.bedLabel');
    obj.bedId = _.get(d, 'clinicPatientBedDto.bedId');
    obj.attendingDoctorId = _.get(d, 'clinicPatientBedDto.attendingDoctorId');
    console.debug('obj', obj);
    obj.expectedDischargeDate = _.get(
      d,
      'clinicPatientBedDto.expectedDischargeDate',
    );
    obj.preTransferIndicator = _.get(
      d,
      'clinicPatientBedDto.preTransferIndicator',
    );
    obj.admissionWardDateTime = _.get(
      d,
      'clinicPatientBedDto.admissionWardDateTime',
    );
    obj.afterOperationDays = _.get(d, 'clinicPatientBedDto.afterOperationDays');
    obj.chargeType = _.get(d, 'clinicPatientBedDto.chargeType');
    obj.inChargeNurseName = _.get(d, 'clinicPatientBedDto.inChargeNurseName');

    const output = [];
    output.push(obj);
    console.debug('output', output);
    return output;
  };
// 患者详情
export const convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail =
  (input) => {
    console.log(input.data.inpVisitExtensionStaffDto.doctorInCharge);
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.id');
    output.displayId = _.get(input, 'data.patient.displayId');
    output.name = _.get(input, 'data.inpVisitExtensionPatientInfoDto.name');
    output.age = _.get(input, 'data.patient.age');
    output.gender = _.get(input, 'data.inpVisitExtensionPatientInfoDto.gender');
    output.nation = _.get(input, 'data.patient.patientProfileDto.nation');
    output.birthday = _.get(input, 'data.patient.birthday');
    output.identityCode = _.get(
      input,
      'data.inpVisitExtensionBillingInfoDto.identityCode',
    );
    output.chargeType = _.get(
      input,
      'data.inpVisitExtensionBillingInfoDto.chargeType',
    );
    output.marriageStatus = _.get(
      input,
      'data.inpVisitExtensionPatientInfoDto.marriageStatus',
    );
    output.citizenship = _.get(
      input,
      'data.patient.patientProfileDto.citizenship',
    );
    output.job = _.get(input, 'data.inpVisitExtensionPatientInfoDto.job');
    output.cellphoneNumber = _.get(
      input,
      'data.inpVisitExtensionPatientInfoDto.cellphoneNumber',
    );
    output.birthAddress = _.get(
      input,
      'data.patient.patientProfileDto.currentAddress',
    );
    output.nativeAddress = _.get(
      input,
      'data.patient.patientProfileDto.currentAddress',
    );
    output.idNumber = _.get(input, 'data.patient.idNumber');
    output.currentAddress = _.get(
      input,
      'data.patient.patientProfileDto.currentAddress',
    );
    output.registeredResidenceAddress = _.get(
      input,
      'data.patient.patientProfileDto.currentAddress',
    );
    output.workUnitName = _.get(
      input,
      'data.patient.patientProfileDto.workUnit.name',
    );
    output.workUnitAddress = _.get(
      input,
      'data.patient.patientProfileDto.workUnit.address',
    );
    output.workUnitPhoneNumber = _.get(
      input,
      'data.patient.patientProfileDto.workUnit.phoneNumber',
    );
    output.contactName = _.get(
      input,
      'data.patient.patientProfileDto.contact.name',
    );
    output.contactRelationshipType = _.get(
      input,
      'data.patient.patientProfileDto.contact.relationshipType',
    );
    output.contactPhoneNumber = _.get(
      input,
      'data.patient.patientProfileDto.contact.phoneNumber',
    );
    output.contactAddress = _.get(
      input,
      'data.patient.patientProfileDto.contact.address',
    );
    output.admissionDateTime = _.get(input, 'data.admissionDateTime');
    output.admissionWardDateTime = _.get(
      input,
      'data.patientInHospitalBaseDto.admissionWardDateTime',
    );
    output.wardId = _.get(input, 'data.patientInHospitalBaseDto.ward.id');
    output.wardName = _.get(
      input,
      'data.patientInHospitalBaseDto.ward.departmentName',
    );
    output.departmentId = _.get(
      input,
      'data.patientInHospitalBaseDto.department.id',
    );
    output.departmentName = _.get(
      input,
      'data.patientInHospitalBaseDto.department.departmentName',
    );
    output.patientClass = _.get(input, 'data.patientClass');
    // output.medicalGroupLeaderId = _.get(
    //   input,
    //   'data.inpVisitExtensionStaffDto.medicalGroupLeader.id',
    // );
    output.medicalGroupLeader = _.get(
      input,
      'data.inpVisitExtensionStaffDto.medicalGroupLeader',
    );
    output.attendingDoctor = _.get(
      input,
      'data.inpVisitExtensionStaffDto.attendingDoctor',
    );
    output.doctorInChargeId = _.get(
      input,
      'data.inpVisitExtensionStaffDto.doctorInCharge',
    );
    output.nurseInchargeId = _.get(
      input,
      'data.inpVisitExtensionStaffDto.nurseInCharge',
    );
    output.controlQualityNurseId = _.get(
      input,
      'data.inpVisitExtensionStaffDto.controlQualityNurse',
    );
    output.nursingClass = _.get(
      input,
      'data.patientInHospitalBaseDto.nursingClass',
    );
    output.patientCondition = _.get(
      input,
      'data.patientInHospitalBaseDto.patientCondition',
    );
    output.fastingIndicator = _.get(
      input,
      'data.patientInHospitalBaseDto.fastingIndicator',
    );
    output.diagnosisDescription = _.get(
      input,
      'data.patientInHospitalBaseDto.diagnosisRecord.diagnosisDescription',
    );
    output.medicalSyndromeName = _.get(
      input,
      'data.patientInHospitalBaseDto.diagnosisRecord.medicalSyndromeName',
    );
    output.diagnosisType = _.get(
      input,
      'data.patientInHospitalBaseDto.diagnosisRecord.diagnosisType',
    );
    output.isolationType = _.get(
      input,
      'data.patientInHospitalBaseDto.isolationType',
    );
    output.bedPrice = _.get(input, 'data.patientInHospitalBaseDto.bedPrice'); // 床位费
    return output;
  };

// 保存
export const convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq =
  (input) => {
    console.log(input.cellphoneNumber);
    const _ = window._ ?? {};
    const btoParam = {};
    btoParam.id = _.get(input, 'id');
    btoParam.inpVisitExtensionStaffBto = {};
    btoParam.inpVisitExtensionStaffBto.medicalGroupLeaderId =
      _.get(input, 'medicalGroupLeader.id') ?? null; // Long 医疗组长ID
    btoParam.inpVisitExtensionStaffBto.attendingDoctorId =
      _.get(input, 'attendingDoctor.id') ?? null; // Long 主治医师ID
    btoParam.inpVisitExtensionStaffBto.doctorInChargeId =
      _.get(input, 'doctorInChargeId.id') ?? null; // Long 住院医师ID
    btoParam.inpVisitExtensionStaffBto.controlQualityNurseId =
      _.get(input, 'controlQualityNurseId.id') ?? null; // Long 质控护士
    btoParam.inpVisitExtensionStaffBto.nurseInChargeId =
      _.get(input, 'nurseInchargeId.id') ?? null; // Long 责任护士
    // btoParam.inpVisitExtensionStaffBto.cellphoneNumber = _.get(
    //   input,
    //   'cellphoneNumber',
    // );
    return btoParam;
  };
// 患者详情-医疗组长
export const convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq = (
  input,
) => {
  console.debug('input', input);
  const _ = window._ ?? {};
  const output = {};
  output.eo = {};
  output.eo.departmentId = _.get(input, 'departmentId'); // Long 科室id
  output.eo.stopIndicator = false; // Boolean 停用标志
  output.eo.staffRole = '01'; // String 成员角色
  return output;
};
// 换床first
export const convertFirstBedData2FirstReqParam = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.id = _.get(input, 'id');
  output.bedLabel = _.get(input, 'bedLabel');
  output.wardId = _.get(input, 'wardId');
  output.bedStatus = _.get(input, 'bedStatus');
  output.clinicPatientBedDto = {};
  output.clinicPatientBedDto.id = _.get(input, 'patientBedRecordId');
  output.clinicPatientBedDto.bedId = _.get(input, 'bedId');
  output.clinicPatientBedDto.inpVisitId = _.get(input, 'inpVisitId');
  output.clinicPatientBedDto.bedLabel = _.get(input, 'patientBedLabel');
  output.clinicPatientBedDto.bedWardId = _.get(input, 'bedWardId');
  output.clinicPatientBedDto.patientId = _.get(input, 'patientId');
  return output;
};
// 换床second
export const convertSecondBedData2SecondReqParam = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.id = _.get(input, 'id');
  output.bedLabel = _.get(input, 'bedLabel');
  output.wardId = _.get(input, 'wardId');
  output.bedStatus = _.get(input, 'bedStatus');
  output.clinicPatientBedDto = {};
  output.clinicPatientBedDto.id = _.get(input, 'patientBedRecordId');
  output.clinicPatientBedDto.bedId = _.get(input, 'bedId');
  output.clinicPatientBedDto.inpVisitId = _.get(input, 'inpVisitId');
  output.clinicPatientBedDto.bedLabel = _.get(input, 'patientBedLabel');
  output.clinicPatientBedDto.bedWardId = _.get(input, 'bedWardId');
  output.clinicPatientBedDto.patientId = _.get(input, 'patientId');
  return output;
};
//统计全部
export const totalData = [
  { label: '全部床位', value: 'BED_COUNT' },
  { label: '全科', value: 'WARD' },
  { label: '今日入科', value: 'ADMISSION_WARD_TODAY' },
  { label: '特级护理', value: 'NURSING_CLASS_LEVEL0' },
  { label: '一级护理', value: 'NURSING_CLASS_LEVEL1' },
  { label: '二级护理', value: 'NURSING_CLASS_LEVEL2' },
  { label: '三级护理', value: 'NURSING_CLASS_LEVEL3' },
  { label: '病重', value: 'SERIOUS_INDICATOR' },
  { label: '病危', value: 'CRISIS_INDICATOR' },
  { label: '今日手术', value: 'OPERATION_TODAY' },
  { label: '明日手术', value: 'OPERATION_TOMORROW' },
  { label: '今日出院', value: 'DISCHARGE_TODAY' },
  { label: '明日出院', value: 'DISCHARGE_TOMORROW' },
  { label: '体温单满页', value: 'ADMISSION_WARD_7_DAYS' },
  { label: '冻账患者', value: 'CLOSE_ACCOUNT_INDICATOR' },
  { label: '过敏患者', value: 'PATIENT_ALLERGY_INDICATOR' },
  { label: '隔离患者', value: 'ISOLATION_INDICATOR' },
  { label: '护理路径', value: 'PATHWAY_IMPORT_INDICATOR' },
];
//统计本病区
export const currentWardTotalData = [
  { label: '病危人数', value: 'CRISIS_INDICATOR' },
  { label: '病重人数', value: 'SERIOUS_INDICATOR' },
  { label: '明日手术', value: 'OPERATION_TOMORROW' },
  // 病区人数
  { label: '病区人数', value: 'WARD' },
  { label: '床位数', value: 'BED_COUNT' },
];
// 床卡icon
export const patientDetailConditionIcon = [
  {
    label: '皮试阳性',
    icon: 'icon-yangxing',
    content: '新开医嘱',
    isShow: 'newDoctorOrderIndicator',
    type: 'string',
  },
  // 过敏
  {
    label: '过敏史',
    icon: 'icon-guomin',
    content: 'patientAllergy',
    isShow: 'patientAllergyIndicator',
    type: 'arr',
  },
  // 危
  {
    label: '病危',
    icon: 'icon-bingwei1',
    content: '病危',
    isShow: 'crisisIndicator',
    type: 'string',
  },
  // 重
  {
    label: '病重',
    icon: 'icon-bingzhong',
    content: '病重',
    isShow: 'seriousIndicator',
    type: 'string',
  },
  // 隔
  {
    label: '隔离',
    icon: 'icon-geli',
    content: 'isolationType',
    isShow: 'isolationIndicator',
    type: 'arr',
  },
  {
    label: '存在已预约未打印的检查',
    icon: 'icon-weidayin',
    content: '存在已预约未打印的检查',
    isShow: 'notPrintAppointExamOrderIndicator',
    type: 'string',
  },
  {
    label: '护理路径',
    icon: 'icon-hulilujing',
    content: '护理路径',
    isShow: 'pathwayImportIndicator',
    type: 'string',
  },
  // drugResistanceName
  {
    label: '耐药标识',
    icon: 'icon-naiyaobiaoshi',
    content: 'drugResistanceName',
    isShow: 'drugResistanceType',
    type: 'arr',
  },
];
// 标记：跌、疼
export const markData = [
  {
    label: '压',
    value: 'pressureScore',
    className:
      'my_bed_card_list_detail_item_content_body_item2_content_type_ya',
    isShow: 'pressureIndicator',
  },
  {
    label: '跌',
    value: 'fallScore',
    className:
      'my_bed_card_list_detail_item_content_body_item2_content_type_die',
    isShow: 'fallIndicator',
  },
  {
    label: '疼',
    value: 'painScore',
    className:
      'my_bed_card_list_detail_item_content_body_item2_content_type_teng',
    isShow: 'painIndicator',
  },
  {
    label: '滑',
    value: 'catheterScore',
    className:
      'my_bed_card_list_detail_item_content_body_item2_content_type_hua',
    isShow: 'catheterIndicator',
  },
  {
    label: 'VTE',
    value: 'vteScore',
    className:
      'my_bed_card_list_detail_item_content_body_item2_content_type_vte',
    isShow: 'vteIndicator',
  },
];
// 占床右键属性
export const occupyBedRightContextMenuData = [
  { label: '入科', value: 'ruke', isDisabled: false },
  { label: '转科', value: 'zhuanke', isDisabled: false },
  { label: '出院', value: 'outHospital', isDisabled: false },
  // { label: '离床', value: 'outBed', isDisabled: false },
  { label: '医嘱校对', value: 'orderCheck', isDisabled: false },
  { label: '医嘱执行单', value: 'orderExecute', isDisabled: false },
  { label: '单病人管理', value: 'singlePatientManagement', isDisabled: false },
  { label: '护理文书', value: 'nursingDocuments', isDisabled: false },
  { label: '患者详情', value: 'detail', isDisabled: false },
  { label: '日费用审核管理', value: 'costAuditManagement', isDisabled: false },
  { label: '费用查询', value: 'costQuery', isDisabled: false },
  { label: '人脸认证', value: 'faceAuthentication', isDisabled: false },
  { label: '护理路径', value: 'carePathway', isDisabled: false },
  { label: '过敏史', value: 'allergyHistory', isDisabled: false },
];
// 耐药图标
export const renderDrugResistance = (value) => {
  if (value === 'M') {
    return 'icon-naijiayangxilinjinhuangseputaoqiujun';
  } else if (value === 'S') {
    return 'icon-youchuanranxingdemeidu';
  } else if (value === 'C') {
    return 'icon-naitanqingmeixichangganjunkexijun';
  } else if (value === 'E') {
    return 'icon-a-chanESBLschaoguangpu-neixiananmeidegelanshiyinxingjun';
  } else if (value === 'A') {
    return 'icon-naitanqingmeixicusuangai-baomanbudongganjun';
  } else if (value === 'V') {
    return 'icon-naigumeisuchangqiujun';
  } else if (value === 'B') {
    return 'icon-a-yigandaxiaosanyang';
  } else if (value === 'P') {
    return 'icon-quannaiyaoxijun';
  } else if (value === 'H') {
    return 'icon-aizi';
  } else if (value === '乙') {
    return 'icon-yigan';
  } else if (value === '丙') {
    return 'icon-binggan';
  } else if (value === 'T') {
    return 'icon-jiehe';
  } else if (value === 'G') {
    return 'icon-yunchanfuBqunlianqiujunyangxing';
  } else {
    return '';
  }
};
