import { ComplexSelect } from '@/pages/Index/components';
import React, { useEffect, useState } from 'react';
import { Row, Col, message, Table } from '@vs/vsf-kit';
import Loading from '@/module/Loading';
import { useAsyncEffect, useRequest } from 'ahooks';
import { PatientComplexSelectList } from './service';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
// patientIdIs
// :
// {name: "李明华", displayId: "1000007901", gender: "1", id: 7901}
const PatientComplexSelect = (props) => {
  const { onLeftLisrQureyParams, PatientInquire } = props;
  const { data, loading, run } = useRequest(PatientComplexSelectList, {
    manual: true,
  });
  useEffect(() => run(onLeftLisrQureyParams), [PatientInquire]);

  const [dataSource, setDataSource, , , getInitDataSource] =
    useGetResetInitState([]);
  useAsyncEffect(async () => {
    data && setDataSource(data);
  }, [data]);

  return (
    <ComplexSelect
      mode="radio"
      dropdownMatchSelectWidth={false}
      placeholder="选择患者"
      hideSelected={true}
      hideSelectIcon={true}
      dropdownStyle={{
        width: 468,
      }}
      listHeight={1000}
      value={onLeftLisrQureyParams?.label}
      onSearch={(value, filterOption) => {
        console.log(value, getInitDataSource());
        if (value?.trim()) {
          setDataSource(filterOption(value, getInitDataSource()));
        } else {
          setDataSource(getInitDataSource());
        }
      }}
      onChange={(params) => {
        const FromParams = props?.from?.getFieldsValue?.(),
          QureyParams = {
            ...onLeftLisrQureyParams,
            label: params?.name ?? '',
          };
        props?.from?.resetFields?.();
        props?.from?.setFieldsValue?.({ ...FromParams, ...params });
        props?.setLeftListQureyParams?.(QureyParams);
      }}
      allowClear={true}
    >
      <Table
        columns={[
          { title: '床号', dataIndex: ['bedLabel'], key: 'bedLabel' },
          { title: '患者ID', dataIndex: ['displayId'], key: 'displayId' },
          { title: '姓名', dataIndex: ['name'], key: 'name' }, //vsf.stores.dbenums?.enums?.SEX_DICT
          {
            title: '性别',
            dataIndex: ['gender'],
            key: 'gender',
          },
        ]}
        dataSource={dataSource}
        pagination={false}
        size="middle"
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={24}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        scroll={{
          y: 400,
        }}
      ></Table>
    </ComplexSelect>
  );
};

export default PatientComplexSelect;
