import {
  Section,
  Column,
  Columns,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
} from '@vs/vsf-kit';
import React, { useState, useCallback } from 'react';

import { TreeMenu } from '@/components';
import { getScrollY } from '@/utils';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import SystemParameterEdit from './edit';
import { useFetch } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

const SystemParameterTreeTable = (props) => {
  /**
   * 系统参数
   * @type {object}
   */
  const [codeType, setCodeType] = useState();
  /**
   * 转换
   * @param {object} codeType
   * @returns {object}
   */
  const codeTypeToClassId = (codeType) => {
    if (!codeType) {
      return {};
    }
    return { classIdIs: codeType.code };
  };
  const onFetch = useCallback(async (params) => {
    // 获取参数名搜索关键词
    const parameterNameKeyWord = params?.search?.nameLike;
    const res =
      await vsf?.services?.BaseParameterController_queryPagedByParameterConfigQto_8e5359?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            nameLike: parameterNameKeyWord,
            inputCodeLike: parameterNameKeyWord,
            codeLike: parameterNameKeyWord,
            configTypeIs: 'SYSTEM',
            parentConfigIdIsNullOrNot: true, // 过滤掉子参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onRemove = useFetch({
    fetch: async (id) => {
      const res =
        await vsf?.services?.SystemParameterConfigBOController_deleteSystemParameter_34c82b?.(
          {
            btoParam: {
              id,
            },
          },
        );
      return res;
    },
    success: () => {
      vsf.refs?.parameter?.reload();
    },
    message: '删除',
  });

  const onAdd = useFetch({
    fetch: async (params) => {
      const res =
        await vsf?.services?.SystemParameterConfigBOController_createSystemParameter_704b54?.(
          { btoParam: { ...(params ?? {}) } },
        );
      return res;
    },
    message: '添加参数',
    success() {
      vsf.refs?.parameter?.reload();
    },
  });

  const onUpdate = useFetch({
    fetch: async (params) => {
      const res =
        await vsf?.services?.SystemParameterConfigBOController_updateSystemParameter_c23bcd?.(
          { btoParam: { ...(params ?? {}) } },
        );
      return res;
    },
    message: '修改参数',
    success() {
      vsf.refs?.parameter?.reload();
    },
  });

  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            gap: '0',
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <TreeMenu
        loadData={async () => {
          const res =
            await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
              { codeType: '000400' },
            );
          return res;
        }}
        fieldNames={{ title: 'name', key: 'id', children: 'children' }}
        dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
        onSelect={(_value) => {
          setCodeType({ ..._value });
        }}
        search={{ status: true, placeholder: '请输入医疗机构名称' }}
        title="数据分类"
        reload
      />
      <VSPromiseTable
        id="parameter"
        scroll={{
          y: getScrollY(469),
        }}
        pagination={{
          size: 9999,
          from: 1,
        }}
        className="table_overflow_435 aspirin-table aspirin-table-no-background-box"
        searchConfig={{
          labelWidth: 90,
          collapsed: false,
          collapseRender: false,
          labelAlign: 'left',
          searchButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          resetButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          optionRender: (...arr) => {
            const [, , dom] = arr;
            return (
              <SearchContainer.Header title="筛选" mode="inline">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 70px 0 50px">
              {dom}
            </SearchContainer>
          );
        }}
        form={{
          labelAlign: 'left ',
        }}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          columnProps: {
            width: 80,
          },
          onCanDelete: () => null,
          extraOperations: [
            {
              order: 1,
              children: <Icon type="icon-shanchu1" size={24} />,
              onClick: (...[, record]) => {
                Tip({
                  content: `确定要删除参数{${record?.name}}`,
                  onOk: () => {
                    onRemove?.(record?.id);
                  },
                });
              },
            },
          ],
        }}
        onFetch={onFetch}
        addLinkPage={{
          linkPage: () => <SystemParameterEdit />,
          modalProps: {
            title: '新增参数',
            className: 'aspirin-linkpage',
            width: 902,
            bodyStyle: {
              // height: 568,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            className: 'modalHeight640',
          },
          onLinkPageValidate: async () => {
            try {
              const ret =
                await vsf.refs.SystemParameterForm?.validateFieldsReturnFormatValue();
              const res = await onAdd?.(ret);
              return res.code === 200;
            } catch (e) {
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <SystemParameterEdit />,
          modalProps: {
            title: '编辑参数',
            className: 'aspirin-linkpage',
            width: 902,
            bodyStyle: {
              // height: 568,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            className: 'modalHeight640',
          },
          onLinkPageValidate: async () => {
            try {
              const ret =
                await vsf.refs.SystemParameterForm?.validateFieldsReturnFormatValue();
              const res = await onUpdate?.(ret);
              return res.code === 200;
            } catch (e) {
              return false;
            }
          },
        }}
        extraParams={codeTypeToClassId(codeType)}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="参数名"
          valueType="text"
          search
          columnKey={'nameLike'}
          ellipsis={{
            showTitle: true,
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['cnName']}
          title="参数中文名"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['description']}
          title="参数描述"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['systemValue']}
          title="参数值"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['valueDescription']}
          title="参数值描述"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['scopeList']}
          title="范围值"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />

        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          title="参数列表"
        >
          <VSTableAddition size="large" className="icon16" children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Container>
  );
};
export default definePage(SystemParameterTreeTable);
