import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDispenseSummaryController_getBeDeliverySummaryCount_e5a20c: {
    method: 'post',
    url: '/api/drug-dispense-summary/get-be-delivery-summary-count',
    parameterFomatter: (data?: {
      qto: DrugPrescriptionDispenseSummaryListQtoDrugDispenseSummaryPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugSummaryBeDeliveryVoDrugDispenseSummaryEntranceWebVo[],
    ) => data,
  },
  DrugDispenseSummaryController_getDispenseSummaryMajor_2b1ec7: {
    method: 'post',
    url: '/api/drug-dispense-summary/get-dispense-summary-major',
    parameterFomatter: (data?: {
      qto: DrugPrescriptionDispenseSummaryListQtoDrugDispenseSummaryPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DispenseSummaryMajorVoDrugDispenseSummaryEntranceWebVo[],
    ) => data,
  },
  DrugDispenseSummaryController_getDispenseSummaryDetail_9ca21d: {
    method: 'post',
    url: '/api/drug-dispense-summary/get-dispense-summary-detail',
    parameterFomatter: (data?: {
      eo: QueryDispenseSummaryDetailEoDrugDispenseSummaryPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DispenseSummaryDetailVoDrugDispenseSummaryEntranceWebVo[],
    ) => data,
  },
  DrugDispenseSummaryController_drugDelivery_f7be4a: {
    method: 'post',
    url: '/api/drug-dispense-summary/drug-delivery',
    parameterFomatter: (data?: {
      btoParam: CreateDrugDispenseDeliveryBtoDrugDispenseSummaryServiceBto;
      eoList: QueryDispenseSummaryDetailEoDrugDispenseSummaryPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
