import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  InputProps,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { useInputEnter } from '../dosage/utils';
import Flex from '../flex';
import { repeatIndicatorDataSource } from '../order/in_patient/table/datasource';

/**
 * 住院医嘱途径选择
 */
let RepeatIndicatorSelect: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const refs = useRef<any>(null);
  useImperativeHandle(ref, () => refs.current);

  useEffect(() => {
    refs?.current?.focus?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      value={value}
      ref={refs}
      onChange={onChange}
      defaultOpen={record?.orderStatus === 'OPEN'}
      {...fieldEnterProps}
      {...rest}
    />
  );
};

RepeatIndicatorSelect.displayName = 'repeatIndicatorSelect';

RepeatIndicatorSelect = React.forwardRef(RepeatIndicatorSelect);

export const WithPreviewComponent = definePage((props: any) => {
  const { value } = props || {};
  return (
    <Flex justify="center">
      {repeatIndicatorDataSource?.find((item) => item?.value === value)
        ?.label ?? value}
    </Flex>
  );
});

export default compose(
  withRef(),
  withField<any>({
    name: 'repeatIndicatorSelect',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: WithPreviewComponent,
    },
  ),
)(RepeatIndicatorSelect) as typeof RepeatIndicatorSelect;
