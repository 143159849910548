import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_getFullInfoById_6ee318: {
    method: 'post',
    url: '/api/patient-main/get-fullinfo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PatientFullVoPatientMainEntranceWebVo) =>
      data,
  },
});
