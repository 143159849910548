import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_queryPatientInHospitalBedVoByPatientInHospitalBedQto_213e12:
    {
      method: 'post',
      url: '/api/clinic-inp-visit/query-patient-in-hospital-bed-vo-by-patient-in-hospital-bed-qto',
      parameterFomatter: (data?: {
        qto: PatientInHospitalBedQtoClinicInpVisitPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: PatientInHospitalBedVoClinicInpVisitEntranceWebVo[],
      ) => data,
    },
});
