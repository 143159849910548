import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Dropdown,
  message,
  Modal,
  Radio,
  RadioGroup,
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { debounce, divide } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { __rest } from 'tslib';

import { AspirinDateTimePicker, AspirinTime, FileUpload } from '@/components';
import { cdssShow } from '@/mock';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import login from '@/module/login';
import Collect from '@/pages/Index/components/collect';
import Tip from '@/pages/Index/components/func/Tip';
import TipModal from '@/pages/Index/components/func/TipModal';
import storage from '@/utils/storage';

import CollectModal from './collectModal.jsx';
import RenderTable from './wait_list';

const mock1 = [
  {
    label: '未诊',
    value: 'NOT_TREAT',
  },
  {
    label: '暂挂',
    value: 'HANG_UP',
  },
  {
    label: '已诊',
    value: 'HAS_TREAT',
  },
];

const mock2 = [
  {
    label: '简表',
    value: 'simple',
  },
  {
    label: '详表',
    value: 'detail',
  },
];

const Index = (props) => {
  const {
    handelTab,
    data,
    onSelect,
    inputValue,
    inputChange,
    onDropdownOpen,
    stores,
    handelSearch,
    handelSetEnter,
    enterType,
    dropdownOpen,
    handeClearInput,
    handelClear,
    notDiagnosisPatient,
  } = props;
  const {
    user,
    common,
    cpoe: { signSelection },
    cdss: { cdssVisit, cdssCancelVisits },
  } = props?.stores ?? {};

  const { initKey, currentPatient, staffInfo, outVisitInfo, setInitKey } =
    user ?? {};
  // 未诊/已诊/暂挂
  const [status, setStatus] = useState('NOT_TREAT');
  // 详表简表
  const [type, setType] = useState('simple');
  // 我的患者/本科患者/我的收藏
  const [tab, setTab] = useState('mine');
  // 当前click患者
  const [patient, setPatient] = useState<any>();

  const [tableList, setTableList] = useState<any>();
  //未诊
  const [undiagnosedList, setUndiagnosed] = useState<any[]>();
  //暂挂
  const [unHangUp, setUnHangUp] = useState<any[]>();
  //已诊
  const [diagnosedList, setDiagnosed] = useState<any[]>();
  // enter/clickTab
  const [clickType, setClickType] = useState<any>('clickTab');

  const [selectedRows, setSelectedRows] = useState<any>();
  const [clinicRegisterDetailId, setClinicRegisterDetailId] = useState<
    TodayClinicRegisterVoOutpRegisterEntranceWebVo[]
  >([]);
  const [isShow, setIsShow] = useState<boolean>(false);
  // ====
  const [open, setOpen] = useState<boolean>(false);
  const [collectClassifyList, setCollectClassifyList] = useState([]);
  // 当前科室信息
  // const [currentApplication, setCurrentApplication] = useState<any>();
  // 取消接诊弹窗
  const [visitRefundMadal, setVisitRefundMadal] = useState<any>(false);
  // 诊结弹窗
  const [visitEndMadal, setVisitEndMadal] = useState<any>(false);

  const tableRef = useRef<any>();
  // ====
  // useEffect(() => {
  //   setCurrentApplication(storage.get('currentApplication'));
  // }, []);

  // 未诊/已诊/暂挂切换
  const onChangeStatus = (v) => {
    setStatus(v);
    handelClear();
  };
  // 详表/简表切换
  const onChangeType = (v) => {
    setType(v);
  };
  // 未诊患者列表(数量)
  const getNotTreatList = async () => {
    const res =
      await vsf.services?.OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538(
        {
          qto: {
            size: 5,
            clinicRegisterDetailIdIn: signSelection.map(
              (item) => item?.clinicRegisterDetailId,
            ),
            clinicDepartmentIdIs: tab === 'department' ? -1 : '',
            nameLike: inputValue?.length > 0 ? inputValue : undefined,
          },
        },
      );
    setUndiagnosed(res?.data?.count);
    return {
      data: res?.data?.result ?? [],
      count: res?.data?.count ?? [],
    };
  };
  // 暂挂患者列表(数量)
  const getNotHangUp = async () => {
    const res =
      await vsf.services?.OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a(
        {
          qto: {
            clinicEncounterStatusIn: ['START_VISIT', 'PENDING'],
            visitDepartmentIdIs: tab === 'department' ? -1 : '',
            size: 5,
          },
        },
      );
    setUnHangUp(res?.data?.count ?? 0);
    return {
      data: res?.data?.result ?? [],
      count: res?.data?.count ?? [],
    };
  };
  // 已诊患者列表(数量)
  const getHasTreat = async () => {
    const res =
      await vsf.services?.OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a(
        {
          qto: {
            clinicEncounterStatusIn: ['END_VISIT', 'REFUND'],
            visitDepartmentIdIs: tab === 'department' ? -1 : '',
            size: 5,
          },
        },
      );
    setDiagnosed(res?.data?.count ?? 0);
    return {
      data: res?.data?.result ?? [],
      count: res?.data?.count ?? [],
    };
  };

  // 我的收藏患者列表
  const getCollectList = useCallback(async () => {
    const res =
      await vsf.services?.DoctorFavoriteMedicalRecordController_getDoctorFavoriteMedicalRecord_8e162b?.(
        {
          var: {
            doctorId: staffInfo?.doctorDto?.staffId,
            clinicVisitType: 'OUTP',
          },
        },
      );

    return { data: res?.data ?? [], count: res?.data?.length ?? 0 };
  }, [staffInfo]);

  useEffect(() => {
    if (!dropdownOpen) return;
    tableRef?.current?.clearScrollId();
    if (tab === 'collect') {
      getCollectList();
    } else {
      getNotTreatList();
      getNotHangUp();
      getHasTreat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, status, dropdownOpen]);

  // 单击患者
  const handelClickTableRow = (row) => {
    setPatient(row);
  };
  // 双击患者
  const handleSelect = useCallback(
    (data, type) => {
      onSelect?.(data, type);
      // console.log(isClose, '接诊1');

      // if (isClose) {
      //   console.log(isClose, '接诊');

      //   onDropdownOpen(false);
      // }
    },
    [onSelect],
  );
  // 点击退诊
  const handalCheckout = async () => {
    setVisitRefundMadal(true);
  };
  // 退诊接口
  const handelvisitRefund = async () => {
    const res =
      await vsf.services?.OutpVisitEncounterController_visitRefund_5da754?.({
        // TODO: 请检查以下这部分传参是否符合需求
        outpVisitEncounterId: patient?.id,
      }); // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      setVisitRefundMadal(false);
      tableRef?.current?.clearScrollId();
      setTimeout(() => {
        // 候诊数量
        notDiagnosisPatient();
        getNotTreatList();
        getNotHangUp();
        getHasTreat();
        vsf.refs.otherTable?.reload();
      }, 1000);

      if (cdssShow) {
        cdssCancelVisits(outVisitInfo ?? patient);
      }
      message.open({
        type: 'success',
        content: `退诊成功!`,
      });
    }
  };
  // 点击诊结
  const handelDiagnosis = async () => {
    setVisitEndMadal(true);
  };
  // 诊结接口
  const handelvisitEnd = async () => {
    const res =
      await vsf.services?.OutpVisitEncounterController_visitEnd_f34b60?.({
        // TODO: 请检查以下这部分传参是否符合需求
        outpVisitEncounterId: patient?.id,
      }); // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      if (cdssShow) {
        cdssVisit(outVisitInfo ?? patient);
      }
      setVisitEndMadal(false);
      tableRef?.current?.clearScrollId();
      setTimeout(() => {
        getNotTreatList();
        getNotHangUp();
        getHasTreat();
        vsf.refs.otherTable?.reload();
      }, 1000);
    }
  };

  const renderChild = (key) => {
    return (
      <div className="content">
        <div className="header">
          {key !== 'collect' && (
            <RadioGroup
              optionType="button"
              onChange={onChangeStatus}
              // defaultValue="NOT_TREAT"
              value={status}
              dataSource={[
                {
                  label: `未诊(${undiagnosedList ?? 0})`,
                  value: 'NOT_TREAT',
                },
                {
                  label: `暂挂(${unHangUp ?? 0})`,
                  value: 'HANG_UP',
                },
                {
                  label: `已诊(${diagnosedList ?? 0})`,
                  value: 'HAS_TREAT',
                },
              ]}
            ></RadioGroup>
          )}
          <RadioGroup
            optionType="button"
            onChange={onChangeType}
            value={type}
            dataSource={mock2}
            style={{
              marginLeft: tab !== 'collect' ? '24px' : '',
            }}
          ></RadioGroup>
        </div>
        <div className="main" style={{ width: type === 'simple' ? 478 : 1400 }}>
          {/* {renderPromiseTable(key)} */}
          <RenderTable
            inputValue={inputValue}
            inputChange={inputChange}
            handelClick={handelClickTableRow}
            handleSelect={handleSelect}
            tab={tab}
            status={status}
            type={type}
            tableRef={tableRef}
            dropdownOpen={dropdownOpen}
            onDropdownOpen={onDropdownOpen}
          />
        </div>
        <div className="footer">
          <Button
            onClick={() => {
              handalCheckout();
            }}
            disabled={status === 'NOT_TREAT' || !patient?.id}
          >
            退诊
          </Button>
          <Button
            disabled={status !== 'HANG_UP' || !patient?.id}
            onClick={() => {
              handelDiagnosis();
            }}
          >
            诊结
          </Button>
          <Button
            onClick={() => {
              tableRef?.current?.clearScrollId();
              getNotTreatList();
              getNotHangUp();
              getHasTreat();
              if (status == 'NOT_TREAT') {
                vsf?.refs?.notTreatTable?.reload();
              } else if (status == 'HANG_UP' || status == 'HAS_TREAT') {
                vsf?.refs?.otherTable?.reload();
              } else {
                vsf?.refs?.collectTable?.reload();
              }
            }}
            type="primary"
            icon={<Icon type="icon-Frame-51" />}
          >
            刷新
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="cpoe-patient-list"
      // style={{ width: type === 'simple' ? 550 : 1400 }}
    >
      <Tabs
        onChange={(e) => {
          setPatient('');
          setTab(e);
          handeClearInput?.();
        }}
        className="aspirin-tab"
        activeKey={tab}
        // onTabClick={(v) => {
        //   // if (clickType !== 'clickTab') {
        //   //   setClickType('clickTab');
        //   // }
        //   // 切换tab清空inputValue
        // }}
      >
        <TabPane tab="我的患者" key="mine">
          {renderChild('mine')}
        </TabPane>
        <TabPane tab="本科患者" key="department">
          {renderChild('department')}
        </TabPane>
        <TabPane tab="我的收藏" key="collect">
          {renderChild('collect')}
        </TabPane>
      </Tabs>
      {/* 取消退诊弹窗 */}
      {
        <TipModal
          content={`确定对{[${patient?.outpVisit?.name}]}进行退诊吗？`}
          open={visitRefundMadal}
          onOk={handelvisitRefund}
          onCancel={() => {
            setVisitRefundMadal(false);
          }}
        />
      }
      {/* 诊结弹窗handelvisitEnd */}
      {
        <TipModal
          content={`确定对{[${patient?.outpVisit?.name}]}进行诊结吗？`}
          open={visitEndMadal}
          onOk={handelvisitEnd}
          onCancel={() => {
            setVisitEndMadal(false);
          }}
        />
      }
    </div>
  );
};

export default definePage(Index);
