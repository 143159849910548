import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpVisitController_getErpPreExamMainVo_da8a4e: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-erp-pre-exam-main-vo',
    parameterFomatter: (data?: { erpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpPreExamMainVoClinicErpVisitEntranceWebVo,
    ) => data,
  },
  ClinicErpVisitController_getNewErpPreExamMainVoByDisplayId_adbc63: {
    method: 'post',
    url: '/api/clinic-erp-visit/get-new-erp-pre-exam-main-vo-by-display-id',
    parameterFomatter: (data?: { displayId: string; isNewErp: boolean }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ErpPreExamMainVoClinicErpVisitEntranceWebVo,
    ) => data,
  },
  ChiefComplaintDictionaryController_getChiefComplaintVsJudgeDiseaseFull_b4a4e9:
    {
      method: 'post',
      url: '/api/base-common/get-chief-complaint-vs-judge-disease-full',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: ChiefComplaintVsJudgeDiseaseFullVoBaseCommonEntranceWebVo[],
      ) => data,
    },
  DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f: {
    method: 'post',
    url: '/api/base-common/get-all-by-disease-level-dictionary-qto',
    parameterFomatter: (data?: {
      qto: DiseaseLevelDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseLevelDictionaryVoBaseCommonEntranceWebVoChiefcomplaintvsjudgediseasefullvo[],
    ) => data,
  },
  ClinicErpPreExamController_saveErpPreExamFuncFlowExecutor_a6c909: {
    method: 'post',
    url: '/api/clinic-erp-pre-exam/save-erp-pre-exam-func-flow-executor',
    parameterFomatter: (data?: {
      saveErpVisitForPreExamBto: SaveErpVisitForPreExamBtoClinicErpVisitServiceBto;
      createErpPreExamRecordBto: CreateErpPreExamRecordBtoClinicErpPreExamServiceBto;
      createPatientChiefComplaintVsJudgeBtoList: CreatePatientChiefComplaintVsJudgeBtoClinicErpRecordServiceBto[];
      saveVitalsignMeasureRecordBto: SaveVitalsignMeasureRecordBtoClinicErpPreExamServiceBto;
      createVitalsignRecordBtoList: CreateVitalsignRecordBtoClinicRecordServiceBto[];
      saveErpPreExamFuncFlowApiParam: {
        patientId: number;
        erpVisitId: number;
        erpPreExamRecordMasterId: number;
        saveErpPreExamFunctionEnum: SaveErpPreExamFunctionEnum;
        clinicRegisterIdList: number;
        clinicRegisterDetailIdList: number;
      };
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  OutpRegisterController_getTodayErpClinicRegister_c4b2c6: {
    method: 'post',
    url: '/api/outp-register/get-today-erp-clinic-register',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDetailWithRegisterVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
});
