import '../index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Popover,
  Select,
  Tooltip,
  VSPromiseTable,
  VSTableAddition,
  VSTableAdditionPosition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import FlexBlock from '@/module/cpoe/FlexBlock';
import Icon from '@/module/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';

import Chart from './chart';
import {
  convertDiagnosisRecord2DeleteDiagnosisRecordReq,
  convertDiagnosisRecordList2SaveDiagnosisRecordListReq,
  convertGetDiagnosisRecordByInpRes2DiagnosisRecord,
  convertGetInpOverviewExamOrLabRes2ExamReport,
  convertGetInpOverviewExamOrLabRes2LabReport,
  convertGetMedicineRecordRes2MedicineRecord,
} from './convert';

enum ReportTimeEnum {
  RECENT_WEEK = 'RECENT_WEEK',
  RECENT_MONTH = 'RECENT_MONTH',
  ALL = 'ALL',
}
const reportTimeDataSource = [
  {
    label: '全部',
    value: ReportTimeEnum?.ALL,
  },
  {
    label: '一周内',
    value: ReportTimeEnum?.RECENT_WEEK,
  },
  {
    label: '一个月内',
    value: ReportTimeEnum?.RECENT_MONTH,
  },
];

// 住院概览
const Index = (props) => {
  const { user, common } = props?.stores ?? {};
  const { initKey, treatInfo, currentPatient, inVisitInfo } = user ?? {};
  const { setCurrentApplication, getServerTime } = common || {};

  const [currentTime, setCurrentTime] = useState<any>();

  const [list, setList] = useState<any[]>([]);
  const [allOrderItem, setAllOrderItem] = useState<any[]>([]);
  // 检查筛选项
  const [exam, setExam] = useState<any>('ALL');
  const [lab, setLab] = useState<any>('ALL');
  // 体征信息
  const [signsInformation, setSignsInformation] = useState<any>();
  // 诊断列表
  const [diagnosisList, setDiagnosisList] = useState<any>();
  const tableRef = useRef<any>(null);
  // 处理成组
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip({ onOk, onCancel }) {},
  });

  // 获取当前时间
  const getTime = useCallback(async () => {
    const res = await getServerTime?.();
    console.log(res, dayjs(res ?? +new Date()).format('YYYY-MM-DD 00:00'));
    setCurrentTime(dayjs(res ?? +new Date()).format('YYYY-MM-DD 00:00'));
  }, [getServerTime]);

  useEffect(() => {
    getTime();
  }, [getTime]);

  // 诊断列表查询
  const getDiagnosisRecordByInp = useCallback(async () => {
    if (!inVisitInfo?.inpVisitId) return [];
    const res =
      await vsf?.services?.VisitHistoryController_getDiagnosisRecordByInp_7c3a6f?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          inpVisitId: inVisitInfo?.inpVisitId,
        },
      );
    const output = convertGetDiagnosisRecordByInpRes2DiagnosisRecord?.(res);
    const _mainDiagnosis = output?.filter((item) => {
      return item?.diagnosisType == '3';
    });

    const sort = (
      _mainDiagnosis?.length <= 0
        ? [
            {
              inpVisitId: inVisitInfo?.inpVisitId,
              patientId: inVisitInfo?.patientId,
              diagnosisType: '3',
              id: 'create_' + Math.random(),
            },
          ]
        : _mainDiagnosis
    ).concat(
      output?.filter((item) => {
        return item?.diagnosisType !== '3';
      }),
    );
    console.log(output, sort);
    setDiagnosisList(sort);
    return {
      data: sort ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [inVisitInfo]);

  useEffect(() => {
    getDiagnosisRecordByInp();
  }, [inVisitInfo, getDiagnosisRecordByInp]);

  // 保存诊断
  const saveDiagnosisRecord = async (data) => {
    const tableData = vsf?.refs?.diagnosisVSPromiseTable?.getValues();
    const { id, ..._data } = data;
    console.log(tableData, data);

    const eoParams = {
      patientId: inVisitInfo?.patientId,
      inpVisitId: inVisitInfo?.inpVisitId,
    };

    const tempItemIndex = tableData?.findIndex((item) => {
      return item?.id == data?.id;
    });

    if (tempItemIndex !== -1) {
      tableData[tempItemIndex] = { ...data, ...eoParams };
    } else {
      tableData?.push({
        ..._data,
      });
    }

    const _params = convertDiagnosisRecordList2SaveDiagnosisRecordListReq(
      tableData
        ?.map((item) => {
          return {
            ...item,
            ...eoParams,
            diagnosisDescription:
              item?.diagnosisDescription?.diagnosisName ??
              item?.diagnosisDescription,
            diagnosisId: item?.diagnosisDescription?.id ?? item?.diagnosisId,
          };
        })
        .filter((i) => {
          return i?.diagnosisDescription;
        }),
    );
    const res =
      await vsf?.services?.ClinicRecordController_saveOverviewDiagnosisRecordList_a21903(
        {
          ..._params,
          extApiParam: {
            inpVisitId: inVisitInfo?.inpVisitId,
          },
        },
      );
    if (res?.code == 200) {
      getDiagnosisRecordByInp();
    }
    return { ...eoParams, id: id };
  };
  // 删除诊断
  const onRemove = async (entity) => {
    if (!String(entity.id).startsWith('create_')) {
      Tip({
        title: '提示',
        content: `该条诊断信息已保存，确定删除？`,
        onOk: async () => {
          const res =
            await vsf?.services?.VisitHistoryController_deleteDiagnosisRecord_b9d3a4?.(
              convertDiagnosisRecord2DeleteDiagnosisRecordReq?.(entity),
            );
          if (res?.code == 200) {
            getDiagnosisRecordByInp();
            return res?.code === 200;
          }
        },
        centered: true,
        okText: '确定',
        cancelText: '取消',
      });
    } else {
      const tableValue = vsf.refs.diagnosisVSPromiseTable.getValues();
      const _tableValue = tableValue?.filter((i) => {
        return i?.id !== entity.id;
      });
      setDiagnosisList(_tableValue);
      return vsf.refs.diagnosisVSPromiseTable.remove(entity);
      // const res = await vsf.refs.diagnosisVSPromiseTable.remove(entity);
    }
  };

  // 新增诊断
  const addDiagnosisRecord = async () => {
    const validate = await vsf?.refs?.diagnosisVSPromiseTable?.saveEdit();
    if (!validate) return;
    const item = {
      id: 'create_' + Math.random(),
      diagnosisType: 'A',
    };
    setDiagnosisList([...diagnosisList, item]);
    setTimeout(async () => {
      await vsf?.refs?.diagnosisVSPromiseTable?.startEdit(item?.id);
    }, 20);
  };

  // 用药信息
  const getMedicineRecord = useCallback(
    async (params?) => {
      if (!inVisitInfo?.inpVisitId) return [];
      const res =
        await vsf?.services?.VisitHistoryController_getMedicineRecord_c2259a?.({
          inpVisitId: inVisitInfo?.inpVisitId,
        });
      const output = convertGetMedicineRecordRes2MedicineRecord?.(res)?.map(
        (item) => {
          return {
            ...item,
            drugOrder: {
              ...item,
            },
            orderClass: 'DRUG',
          };
        },
      );
      const _data = group?.onSliced(output);
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inVisitInfo],
  );

  // 检查
  const getInpOverviewExam = useCallback(
    async (params) => {
      if (!inVisitInfo?.inpVisitId) return [];
      const query = {
        // TODO: 请检查以下这部分传参是否符合需求
        inpVisitId: inVisitInfo?.inpVisitId,
        timeStatus: exam,
        orderClass: 'EXAM',
      };
      const res =
        await vsf?.services?.VisitHistoryController_getInpOverviewExamOrLab_8110e1?.(
          { eo: query },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // TODO: 你需要自己补充入参input来源及出参output用法
      const output = convertGetInpOverviewExamOrLabRes2ExamReport?.(res);
      return {
        data: output,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [exam, inVisitInfo],
  );

  // 检验
  const getInpOverviewLab = useCallback(
    async (params) => {
      if (!inVisitInfo?.inpVisitId) return [];
      const query = {
        // TODO: 请检查以下这部分传参是否符合需求
        inpVisitId: inVisitInfo?.inpVisitId,
        timeStatus: lab,
        orderClass: 'LAB',
      };
      const res =
        await vsf?.services?.VisitHistoryController_getInpOverviewExamOrLab_8110e1?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            eo: query,
          },
        );
      return {
        data: convertGetInpOverviewExamOrLabRes2LabReport?.(res),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [lab, inVisitInfo],
  );

  // 体征信息列表
  const getVitalsignRecord = useCallback(async () => {
    if (!inVisitInfo?.inpVisitId) return;
    const res =
      await vsf?.services?.VisitHistoryController_getVitalsignRecord_6931fd?.({
        // TODO: 请检查以下这部分传参是否符合需求
        inpVisitId: inVisitInfo?.inpVisitId,
      });
    if (res?.code == 200) {
      setSignsInformation(res?.data);
      // setSignsInformation(res?.data);
    } else {
      setSignsInformation('error');
    }
  }, [inVisitInfo]);

  useEffect(() => {
    getVitalsignRecord();
  }, [getVitalsignRecord]);

  return (
    <div className="info patient_overview_info">
      <FlexBlock
        // style={{
        //   alignItems: 'flex-start',
        //   justifyContent: 'flex-start',
        //   flex: 1,
        //   // border: '1px solid red',
        //   marginBottom: '15px',
        // }}
        // direction="row"
        // gap={['48px']}
        // colSpan={['400px']}
        className="patient_overview_info__block"
      >
        <div style={{ height: '100%' }}>
          <Block
            title="主诉"
            style={{ height: 30 }}
            headerStyle={{ marginBottom: 10 }}
            // mainStyle={{ height: 0, padding: 0, border: '1px solid red' }}
            main={false}
            // desc={treatInfo?.id ? '未避孕未孕1年，要求AIH' : ''}
            desc="未避孕未孕1年，要求AIH"
          ></Block>

          <VSPromiseTable
            scroll={{
              y: getScrollY(800),
            }}
            ref={tableRef}
            rowKey="id"
            className="aspirin-table-transparent"
            id="diagnosisVSPromiseTable"
            editable={{
              editType: 'single',
              editText: <Icon type="icon-Frame-21" size={18} />,
              deleteText: <Icon type="icon-shanchuwudi" size={18} />,
              saveText: <Icon type="icon-wudibaocun" size={18} />,
              cancelText: <Icon type="icon-shanchuwudi" size={18} />,
              onCanDelete: (v) => {
                return v?.diagnosisType !== '3' || !inVisitInfo?.inpVisitId;
              },
              onCanEdit: () => {
                return inVisitInfo?.inpVisitId;
              },
              saveOnClickOutside: false,
              columnProps: {
                width: 64,
                align: 'center',
              },
            }}
            onUpdate={(data) => {
              return saveDiagnosisRecord(data);
            }}
            onFetch={async () => {
              return { data: diagnosisList };
            }}
            onRemove={onRemove}
            shouldLoading={() => {
              return true;
            }}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title="主键"
              valueType="digit"
              fieldProps={{}}
              hideInTable
            />
            <VSTableColumn
              dataIndex={['diagnosisDescription']}
              title="诊断描述"
              valueType="select"
              width={250}
              // ellipsis={true}
              // searchKey="searchInputLike"
              // formItemProps={{
              //   rules: [{ required: true }],
              // }}

              fieldProps={{
                showSearch: true,
              }}
              fieldNames={{ label: 'diagnosisName', value: 'id' }}
              dataSource={async (params) => {
                const res =
                  await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
                    {
                      qto: {
                        from: params?.pagination?.from ?? 0,
                        size: params?.pagination?.size ?? 2000,
                        searchInputLike: params?.diagnosisName,
                      },
                      ext: {},
                    },
                  );
                return res?.data?.result;
              }}
              render={(_, value) => {
                return (
                  <div>
                    {value?.prefix}
                    <span contentEditable="false">
                      {value?.diagnosisDescription}
                    </span>
                    {value?.suffix}
                  </div>
                );
              }}
            />
            <VSTableColumn
              dataIndex={['diagnosisType']}
              title="是否为主诊断"
              valueType="select"
              editable={false}
              width={75}
              dataSource={[
                {
                  label: '主要诊断',
                  value: '3',
                },
                {
                  label: '其他诊断',
                  value: 'A',
                },
              ]}
            />
            <VSTableToolbar
              className="ant-pro-table-list-toolbar-padding"
              title={
                <div
                  className="patient_overview_info_tabletoolbar"
                  style={{ fontWeight: 500, fontSize: 20 }}
                >
                  <div className="patient_overview_info_tabletoolbar_div"></div>
                  诊断
                </div>
              }
            >
              <VSTableAddition
                // className="previous_history_tabletoolbar_addBtn"
                children="新增"
                size="small"
                type="primary"
                onClick={addDiagnosisRecord}
                // style={{ backgroundColor: 'transparent', color: '#3276e8' }}
                // icon=""
              />
            </VSTableToolbar>
          </VSPromiseTable>
        </div>
        <Block
          title="体征"
          // height={456}
          height="100%"
          headerStyle={{ marginBottom: 0 }}
        >
          <FlexBlock
            // colSpan={[1, '200px']}
            gap={['16px']}
            className="patient_overview_info__block__tizheng"
          >
            <Chart currentTime={currentTime} data={signsInformation} />
            <Block
              // style={{ marginTop: '-6px' }}
              className="other"
              title="今日体征"
            >
              <div className="day-sign">
                {signsInformation?.todayList?.map((item, index) => (
                  <div className="item" key={index}>
                    <span>{item?.vitalsignItem?.vitalsignItemName}</span>
                    <span>
                      {item?.vitalsignValue}
                      {item?.unit}
                    </span>
                  </div>
                ))}
              </div>
            </Block>
          </FlexBlock>
        </Block>
      </FlexBlock>
      <FlexBlock
        style={{
          alignItems: 'flex-start',
          flex: 1,
          marginBottom: '8px',
        }}
        gap={['48px', '48px']}
      >
        <Block
          title="用药记录"
          // height={400}
          height="100%"
          headerStyle={{ marginBottom: '14px' }}
        >
          <VSPromiseTable
            className="aspirin-table-transparent"
            id="1"
            scroll={{
              y: getScrollY(745),
            }}
            onFetch={getMedicineRecord}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title="主键"
              valueType="digit"
              fieldProps={{}}
              hideInTable
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱正文"
              valueType="text"
              fieldProps={{}}
              width="100%"
              render={(dom, record) => {
                return (
                  <Popover content={record?.orderText}>
                    <div
                      className={classNames(
                        'text_ellipsis2',
                        'aspirin-order-text',
                        record?.groupName,
                      )}
                    >
                      {record?.orderText}
                    </div>
                  </Popover>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['groupNumber']}
              title="医嘱组号"
              valueType="digit"
              formItemProps={{
                rules: [
                  {
                    message: '医嘱组号的值不合法',
                    type: 'number',
                    min: 0,
                    max: undefined,
                  },
                ],
              }}
              fieldProps={{}}
              hideInTable
            />

            <VSTableColumn
              dataIndex={['groupSubNumber']}
              title="医嘱序号"
              valueType="digit"
              fieldProps={{}}
              hideInTable
            />

            <VSTableColumn
              dataIndex={['dosage']}
              title="药品一次使用剂量"
              valueType="text"
              fieldProps={{}}
              render={(_, record) => {
                return record?.dosage + record?.dosageUnit;
              }}
              width={55}
            />

            <VSTableColumn
              dataIndex={['dosageUnit']}
              title="剂量单位"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '剂量单位长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ],
              }}
              fieldProps={{}}
              hideInTable
            />

            <VSTableColumn
              dataIndex={['frequency']}
              title="执行频率"
              valueType="select"
              fieldProps={{}}
              width={45}
              dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
            />
            <VSTableColumn
              dataIndex={['startPerformDateTime']}
              title="开始执行日期及时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
              width={135}
            />
          </VSPromiseTable>
        </Block>
        <Block
          title="检查"
          // height={240}
          height="100%"
          headerStyle={{ marginBottom: '14px' }}
          extra={
            <Select
              showSearch
              dataSource={reportTimeDataSource}
              width={150}
              value={exam}
              onSelect={(v) => {
                setExam(v?.value);
              }}
            />
          }
        >
          <VSPromiseTable
            scroll={{
              y: getScrollY(745),
            }}
            className="aspirin-table-transparent"
            id="1"
            onFetch={getInpOverviewExam}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱正文"
              valueType="text"
              fieldProps={{}}
              width="100%"
              render={(dom, record) => {
                return (
                  <Popover content={record?.orderText}>
                    <div className="text_ellipsis2">{record?.orderText}</div>
                  </Popover>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['submitDateTime']}
              title="医嘱提交时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
              width={135}
            />

            <VSTableColumn
              dataIndex={['resultStatus']}
              title="检查结果状态"
              valueType="select"
              fieldProps={{}}
              width={75}
              dataSource={vsf.stores.dbenums?.enums?.EXAM_RESULT_STATUS_DICT}
            />
          </VSPromiseTable>
        </Block>
        <Block
          title="检验"
          // height={240}
          height="100%"
          headerStyle={{ marginBottom: '14px' }}
          extra={
            <Select
              showSearch
              dataSource={reportTimeDataSource}
              width={150}
              value={lab}
              onSelect={(v) => {
                setLab(v?.value);
              }}
            />
          }
        >
          <VSPromiseTable
            className="aspirin-table-transparent"
            id="1"
            scroll={{
              y: getScrollY(745),
            }}
            onFetch={getInpOverviewLab}
            showHeader={false}
            pagination={false}
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title="主键"
              valueType="digit"
              fieldProps={{}}
              hideInTable
            />

            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱正文"
              valueType="text"
              fieldProps={{}}
              width="100%"
              render={(dom, record) => {
                return (
                  <Popover content={record?.orderText}>
                    <div className="text_ellipsis2">{record?.orderText}</div>
                  </Popover>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['submitDateTime']}
              title="医嘱提交时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
              width={135}
            />

            <VSTableColumn
              dataIndex={['labStatus']}
              title="检验状态"
              valueType="select"
              fieldProps={{}}
              width={75}
              dataSource={vsf.stores.dbenums?.enums?.LAB_RESULT_STATUS_DICT}
            />
          </VSPromiseTable>
        </Block>
      </FlexBlock>
    </div>
  );
};

export default definePage(Index);
