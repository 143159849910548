import vsf from '@vs/vsf-boot';
// 	查询医技科室或个人模板。
export const getMedicalTechnologyChargeTemplateList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateList_e7f380?.(
      { personIndicator: params?.personIndicator },
    );
  return {
    data: res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
// 查询医技科室或个人模板列表详情。
export const getMedicalTechnologyChargeTemplateDetailList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateDetailList_7eb527?.(
      {
        templateId: params?.templateId,
      },
    );
  return {
    data: res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
