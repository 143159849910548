import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPathologyController_queryPathologySpecimenExtensionDeliveryPrintVoByCondition_3a5628:
    {
      method: 'post',
      url: '/api/clinic-record-order-pathology/query-pathology-specimen-extension-delivery-print-vo-by-condition',
      parameterFomatter: (data?: {
        printed: boolean;
        wardId: number;
        receiptPrintDateTimeBiggerThanEqual: string;
        receiptPrintDateTimeLessThanEqual: string;
        deliveryReceiptNumberIs: string;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: PathologySpecimenExtensionDeliveryPrintVoClinicRecordOrderPathologyEntranceWebVo[],
      ) => data,
    },
  ClinicRecordOrderPathologyController_printPathologyForDelivery_8d4dab: {
    method: 'post',
    url: '/api/clinic-record-order-pathology/print-pathology-for-delivery',
    parameterFomatter: (data?: {
      btoParam: PrintPathologyForDeliveryBtoClinicRecordOrderPathologyServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PathologySpecimenExtensionPrintVoClinicRecordOrderPathologyEntranceWebVo,
    ) => data,
  },
});
