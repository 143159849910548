import vsf, { definePage } from '@vs/vsf-boot';
import {
  Input,
  VSTableColumn,
  VSPromiseTable,
  VSTableAddition,
  Button,
  VSTableToolbar,
  Modal,
  message,
  Table,
  VSForm,
  VSFormItem,
  Form,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useCallback, useState, useRef, useEffect } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import ComplexSelect from '@/pages/Index/components/complex_select';
import './index.less';
import { getScrollY, getScrollYRem } from '@/utils';
import {
  useAsyncEffect,
  useRequest,
  useUnmount,
  useUpdateEffect,
} from 'ahooks';
import { AspirinSelect } from '@/components';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
const HospitalSelect = (props) => {
  const [
      hospitalDataSource,
      setHospitalDataSource,
      ,
      ,
      getInitHospitalDataSource,
    ] = useGetResetInitState([]),
    { dataSource } = props;
  useAsyncEffect(
    async () =>
      dataSource?.length >= 1 &&
      setHospitalDataSource(
        dataSource?.map((dataEle) => ({
          ...dataEle,
          outpSpecialClinicType:
            vsf.stores.dbenums.enums.DEPT_CLINIC_TYPE_DICT?.find(
              (ele) => ele?.code == dataEle?.outpSpecialClinicType,
            )?.cname,
        })),
      ),
    [dataSource],
  );
  return (
    <ComplexSelect
      mode="radio"
      placeholder="请选择门诊专科"
      hideSelectIcon
      showLabel="label"
      allowClear
      value={props?.value ?? ''}
      onChange={(params) => {
        props?.onChange(params?.outpSpecialClinicName);
      }}
      dropdownMatchSelectWidth={700}
      listHeight={1000}
      onSearch={(value, filterOption) => {
        if (value?.trim()) {
          setHospitalDataSource(
            filterOption(value, getInitHospitalDataSource()),
          );
        } else {
          setHospitalDataSource(getInitHospitalDataSource());
        }
      }}
    >
      <Table
        columns={[
          {
            dataIndex: ['outpSpecialClinicName'],
            title: '门诊专科',
          },
          {
            dataIndex: ['outpSpecialClinicType'],
            title: '学科分类',
          },
          {
            dataIndex: ['branchInstitution', 'institutionName'],
            title: '院区',
          },
        ]}
        dataSource={hospitalDataSource}
        pagination={false}
        size="middle"
        scroll={{
          y: 300,
        }}
      />
    </ComplexSelect>
  );
};
const OutpSpecialClinicDictionaryTable = (props) => {
  const [form] = Form.useForm();
  const [serachParam, setSerachParam] = useState();
  const [status, setStatus] = useState();
  const [dataSource, setDataSource] = useState();

  const [searchParam, setSearchParam] = useState();

  const [newDataSorce, setNewDataSorce] = useState();

  function hasDuplicates(arr, property1, property2) {
    const seen = new Set();
    for (const obj of arr) {
      const key = `${obj[property1]}-${obj[property2]}`;
      if (seen.has(key)) {
        console.log(obj);
        return true; // Duplicate found
      }
      seen.add(key);
    }
    return false; // No duplicates
  }
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5?.(
          {
            qto: {
              outpSpecialClinicNameLike:
                vsf?.refs?.outpSpecialClinicName?.getFieldValue?.(
                  'outpSpecialClinicName',
                ),
            },
          },
        );
      const result = (res?.data?.result ?? res?.data ?? [])?.map((item) => {
        return { ...item, outpSpecialClinicName: item?.id };
      });
      setDataSource(res?.data?.result ?? res?.data ?? []);
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [status],
  );

  const onSave = async (params) => {
    const data = vsf.refs.OutpSpecialClinicDictionaryTable.getValues();
    const formatData = data.map((d) => {
      const dict = { ...d, branchInstitutionId: d.branchInstitution?.id };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const paramData = formatData?.map((item) => {
      if (typeof item?.outpSpecialClinicName === 'object') {
        return {
          ...item,
          outpSpecialClinicName:
            item?.outpSpecialClinicName?.outpSpecialClinicName,
        };
      } else {
        return { ...item };
      }
    });

    const hasDuplicatesFound = hasDuplicates(
      paramData,
      'branchInstitutionId',
      'outpSpecialClinicName',
    );
    if (hasDuplicatesFound) {
      message.error('门诊专科名称和院区不能同时相等');
      return;
    }
    const res =
      await vsf?.services?.OutpRegisterDefineController_addUpdateOutpSpecialClinic_798b56?.(
        { btoParamList: paramData, extApiParam: {} },
      );
    if (res.code === 200) {
      message.success('保存成功');
      vsf.refs?.OutpSpecialClinicDictionaryTable?.reload();
    }
    return res?.data;
  };
  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteOutpSpecialClinic_db4f60?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      vsf.refs?.OutpSpecialClinicDictionaryTable?.reload();
    }
    return res?.code === 200;
  };

  return (
    <div className="layout-optpatient">
      <VSPromiseTable
        pagination={false}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.outpSpecialClinicName}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        vsid="58036"
        id="OutpSpecialClinicDictionaryTable"
        onFetch={onFetch}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        onRemove={onRemove}
        searchFormRender={(tableProps, defaultDom) => {
          return <div className="aspirin-table-form-box">{defaultDom}</div>;
        }}
        className="aspirin-table"
        scroll={{
          // y: getScrollY(280),
          y: getScrollYRem(20),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['sortNumber']}
          title="排序号"
          valueType="digit"
          fieldProps={{}}
          formItemProps={{
            rules: [{ required: true }],
          }}
        />

        <VSTableColumn
          dataIndex={['outpSpecialClinicName']}
          title="门诊专科"
          valueType="aspirinSelect"
          // valueType="select"
          order={-13}
          columnKey={'outpSpecialClinicNameLike'}
          fieldProps={{
            showSearch: true,
            fieldNames: { label: 'outpSpecialClinicName', value: 'id' },
            onSearch: (v) => {
              setSearchParam(v);
            },
            selectProps: {
              showSearch: true,
              placeholder: '请选择门诊专科',
            },
            addItemOptions: {
              status: true,
              mode: 'single',
            },
          }}
          dataSource={dataSource}
        />
        <VSTableColumn
          dataIndex={['outpSpecialClinicType']}
          title="学科分类"
          valueType="codeTableSelect"
          dataSource={vsf.stores.dbenums.enums.DEPT_CLINIC_TYPE_DICT}
          fieldProps={{
            showSearch: true,
          }}
          formItemProps={{
            rules: [{ required: true }],
          }}
        />

        <VSTableColumn
          dataIndex={['specialClinicIndicator']}
          title="是否专病"
          valueType="switch"
          transform={(v) => {
            if (v) {
              return { specialClinicIndicator: 1 };
            } else {
              return { specialClinicIndicator: 0 };
            }
          }}
          fieldProps={{
            showSearch: true,
          }}
        />
        <VSTableColumn
          dataIndex={['branchInstitution']}
          title="院区"
          valueType="select"
          fieldProps={{
            dataSource: async (params) => {
              const res =
                await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
                  {},
                );
              return res?.data?.branchInstitutionList;
            },
            fieldNames: { label: 'institutionName', value: 'id' },
            showSearch: true,
          }}
        />
        <VSTableToolbar
          title={
            <div className="toolbar-layout">
              <div>门诊专科字典</div>
              <div className="toolbar-content" style={{ minWidth: '200px' }}>
                <VSForm id="outpSpecialClinicName">
                  <VSFormItem
                    valueType="custom"
                    name={['outpSpecialClinicName']}
                    fieldProps={{}}
                  >
                    <HospitalSelect dataSource={dataSource} />
                  </VSFormItem>
                </VSForm>
              </div>
            </div>
          }
          style={{ paddingLeft: '20px' }}
        >
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" children="保存" onClick={onSave} />
          <Button
            type="primary"
            children="查询"
            onClick={(v) => {
              setStatus(!status);
            }}
          />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(OutpSpecialClinicDictionaryTable);
