import React, { useState } from 'react';

import ClinicAppointPlatform from '@/pages/Index/manage/out_patient_number_schedule/dictionary/clinicAppointPlatform';
import ClinicRegisterPlatformTable from '@/pages/Index/manage/out_patient_number_schedule/dictionary/clinicRegisterPlatformTable';
import ClinicRegisterTypeTable from '@/pages/Index/manage/out_patient_number_schedule/dictionary/numberMaintenance';
import Container from '@/module/Container';
import FurloughReason from '@/pages/Index/manage/out_patient_number_schedule/dictionary/furloughReason';
import Identification from '@/pages/Index/manage/out_patient_number_schedule/dictionary/identification';
import Outpatient from '@/pages/Index/manage/out_patient_number_schedule/dictionary/outpatient';
import SelectTag from '@/pages/Index/components/func/SelectTag';
import vsf, { definePage } from '@vs/vsf-boot';

import SystemConfigTypeTree from './tree';

const FunctionManage = (props) => {
  /**
   * SystemConfig
   * @type {object}
   */
  const [systemConfig, setSystemConfig] = useState({ code: '01' });
  const tableSort = (code) => {
    if (code === '01') {
      return <ClinicRegisterTypeTable />;
    }
    if (code === '02') {
      return <Identification />;
    }
    if (code === '03') {
      return <Outpatient />;
    }
    if (code === '04') {
      return <ClinicAppointPlatform />;
    }
    if (code === '05') {
      return <ClinicRegisterPlatformTable />;
    }
    if (code === '06') {
      return <FurloughReason />;
    }
  };
  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '20%',
            bgColor: 'rgba(255, 255, 255, 0.5',
          },
          {
            block: 1,
            bgColor: 'rgba(255, 255, 255, 0.5',
            padding: '10px 16px 0 24px',
          },
        ],
      }}
    >
      <SystemConfigTypeTree
        onChange={(_value) => {
          setSystemConfig(_value);
        }}
      />
      {tableSort(systemConfig?.code)}
    </Container>
  );
};
export default definePage(FunctionManage);
