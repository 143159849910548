import '../index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Drawer,
  Empty,
  Input,
  message,
  Popover,
  Radio,
  Table,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import {
  useAsyncEffect,
  useGetState,
  useRequest,
  useUpdateEffect,
} from 'ahooks';
import { Select } from 'antd';
import classnames from 'classnames';
import { cloneDeep, join } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Icon from '@/module/cpoe/Icon';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import ICON from '@/module/Icon';
import { AsyncSuspense, Determine, Flex } from '@/pages/Index/components';
import Block from '@/pages/Index/components/block_no_padding';
import {
  askFixed,
  askRomanAlphabet,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import {
  useGetResetInitState,
  useOnChangeValue,
  useWithResolvers,
} from '@/pages/Index/components/func/hooks';
import TabCheck from '@/pages/Index/components/tab_check';
import { labelColWidth } from '@/utils';

const Content = forwardRef((props: any, ref) => {
  const {
    currentTab,
    defaultValue,
    showLab = true,
    allPrice,
    isShowMeno = true,
    isShowEmergencyIndicator = true,
  } = props;

  const { user } = vsf?.stores || {};
  const { hospitalType } = user || {};
  const [currentInfo, setCurrentInfo] = useState<any>();
  const [infoDetail, setInfoDetail, getInfoDetail] = useGetState<any>();
  const [formValues, setFormValues, getFormValues] = useGetState<any>({});
  const [checkList, setCheckList, getCheckList, restCheckList] =
    useGetResetInitState<any[]>([]);
  const [radio] = useOnChangeValue('PERSONAL', (value) => {
    get(value)?.then((res) => {
      setItems(res);
    });
    return value;
  });
  const [search] = useOnChangeValue<string>('');

  const [items, setItems] = useState([]);

  const all = (list) => {
    return askTotalAmount(
      list.flatMap((item) => {
        return (item?.clinicVsChargeDtoList ?? [])?.map((item) => [
          item?.priceItem?.price,
          item?.amount,
        ]);
      }),
      2,
    );
  };

  const handleCheckItem = (data) => {
    const target = getCheckList();
    const v = target?.find(
      (item: any) => item?.clinicItemId === data?.clinicItemId,
    );
    if (!v) {
      setCheckList(() => {
        const list = [...target, data];
        getCurrentInfoDetail(data?.clinicItemId);
        allPrice?.onChange?.(all(list));
        return list;
      });
    } else {
      setCheckList(() => {
        const list = [...target]?.filter(
          (v: any) => v?.clinicItemId !== data?.clinicItemId,
        );
        allPrice?.onChange?.(all(list));
        return list;
      });
    }
    setOptions(
      (getInitOptions() ?? [])?.filter((item) => {
        const state = getCheckList()?.map((item: any) => item?.clinicItemId);
        return !state.includes((item as any)?.clinicItemId);
      }),
    );
  };

  const handleDelItem = (data) => {
    handleCheckItem(data);
    setFormValues({
      ...(getFormValues() ?? {}),
      [data?.clinicItemId]: {},
    });
  };

  const handleSetCurrent = (item) => {
    setCurrentInfo(item);
  };

  const getInitInfoDetail = async (clinicItemId) => {
    if (clinicItemId) {
      const res =
        await vsf.services?.ClinicItemController_getClinicItemDictionaryLabById_ada097?.(
          {
            clinicItemId,
          },
        );
      setInfoDetail({
        ...(getInfoDetail() ?? {}),
        [clinicItemId]: {
          ...(res?.data ?? {}),
        },
      });
      return res;
    }
  };

  const getCurrentInfoDetail = useCallback(
    async (clinicItemId) => {
      const res = await getInitInfoDetail(clinicItemId);
      const target = cloneDeep(getFormValues());
      const {
        labVsClinicItemClinicDto: {
          labItemVsSpecimenDtoList = [],
          labItemVsSampleLocationDtoList = [],
          labItemVsDepartmentDtoList = [],
          labItemVsPhysiologicalCycleDtoList = [],
        },
      } = res?.data || { labVsClinicItemClinicDto: {} };
      const department =
        labItemVsDepartmentDtoList?.find((item) => item?.defaultIndicator) ??
        labItemVsDepartmentDtoList[0];
      target[clinicItemId] = target?.[clinicItemId] ?? {
        labOrder: {
          emergencyIndicator: false,
          specimenId: (
            labItemVsSpecimenDtoList?.find((item) => item?.defaultIndicator) ??
            labItemVsSpecimenDtoList?.[0]
          )?.specimenId,
          sampleLocation: (
            labItemVsSampleLocationDtoList?.find(
              (item) => item?.defaultIndicator,
            ) ?? {}
          )?.sampleLocationCodeId,
          department: {
            departmentName:
              department?.departmentName +
              (department?.institutionName
                ? `(${department?.institutionName})`
                : ''),
            id: department?.departmentId,
          },
          physiologicalCycle: (
            labItemVsPhysiologicalCycleDtoList?.find(
              (item) => item?.defaultIndicator,
            ) ?? {}
          )?.physiologicalCycleCodeId,
        },
      };
      setFormValues(target);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentInfo, isShowEmergencyIndicator],
  );

  const handleClearAll = () => {
    setCheckList([]);
  };

  const onValuesChange = (value, record) => {
    setFormValues({
      ...(getFormValues() ?? {}),
      [currentInfo?.clinicItemId]: record,
    });
  };

  const getItemList = useCallback(async (value) => {
    const res =
      await vsf?.services?.ClinicItemController_allClinicItemDictionaryCharge_d97a65?.(
        {
          qto: {
            from: 0,
            size: 999,
            itemClassIs: 'LAB',
            ...value,
          },
        },
      );
    return res?.data?.map((item) => ({
      clinicItemId: item?.id,
      ...item,
    }));
  }, []);

  const get = useCallback(async (favoriteType) => {
    const res =
      await vsf.services?.ClinicItemController_getFavoriteClinicItem_fa660a?.({
        favoriteType,
        itemClass: 'LAB',
      });
    return res?.data?.map((item) => ({
      clinicItemId: item?.id,
      ...item,
    }));
  }, []);

  const { loading, run: getDataSource } = useRequest(
    async () => {
      if (currentTab) {
        if (currentTab === '常用项目') {
          const value = await get(radio?.value);
          setItems(value);
        } else {
          const value = await getItemList({
            labClassIdIs: currentTab,
          });
          setItems(value);
        }
      }
    },
    {
      manual: true,
      refreshDeps: [currentTab],
    },
  );

  useEffect(() => {
    getDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const [inspectList, setInspectList] = useState<any>([]);

  const InspectionForm = useCallback(() => {
    const list: any[] = [];
    for (const item of checkList) {
      const { labOrder } = formValues?.[(item as any)?.clinicItemId] ?? {};
      const value = Object?.values({
        specimenId: labOrder?.specimenId,
        department: labOrder?.department ?? labOrder?.departmentId,
      });
      list?.push({
        inspect:
          value?.includes(null as any) || value?.includes(undefined as any),
        id: (item as any)?.clinicItemId,
      });
    }
    return list;
  }, [checkList, formValues]);

  const handleSubmit = useCallback(async () => {
    const is = InspectionForm();
    setInspectList(is);
    if (
      !Object?.values(is)
        ?.map((item) => item?.inspect)
        ?.includes(true)
    ) {
      if (defaultValue?.uuid) {
        const value = vsf?.refs?.testForm?.getFieldsValue();
        return {
          performDepartment: value?.labOrder?.department,
          performDepartmentId: value?.labOrder?.department?.id,
          vesselType: currentTab,
          uuid: defaultValue?.uuid,
          labOrder: {
            emergencyIndicator: false,
            ...(value?.labOrder || {}),
            labItemName: defaultValue?.labItemName,
            labItemId: defaultValue?.labItemId,
          },
          orderStatus: defaultValue?.orderStatus,
          orderClass: 'LAB',
          orderText: defaultValue?.labItemName,
        };
      } else {
        return checkList?.map((item: any) => {
          const allPrice = (item?.clinicVsChargeDtoList ?? [])?.reduce(
            (sum: any, currentValue: any) => {
              return (
                sum +
                (currentValue?.amount ?? 0) *
                  (currentValue?.priceItem?.price ?? 0)
              );
            },
            0,
          );
          const currentInfoDetail = infoDetail?.[item?.clinicItemId] ?? {};
          const currentFormValues = formValues?.[item?.clinicItemId] ?? {};
          return {
            orderStatus: 'OPEN',
            orderClass: 'LAB',
            orderText:
              item?.clinicItemName +
              `[${
                infoDetail?.[
                  item?.clinicItemId
                ]?.labVsClinicItemClinicDto?.labItemVsSpecimenDtoList?.find(
                  (item) =>
                    item?.specimenId ===
                    currentFormValues?.labOrder?.specimenId,
                )?.specimenName
              }]`,
            uuid: getUUID(),
            performDepartment: currentFormValues?.labOrder?.department,
            performDepartmentId: currentFormValues?.labOrder?.department?.id,
            vesselType: currentTab,
            allPrice,
            labOrder: {
              emergencyIndicator: false,
              ...(currentFormValues?.labOrder || {}),
              labItemId: item?.clinicItemId,
              labItemName: item?.clinicItemName,
              origin: {
                ...item,
                infoDetail: currentInfoDetail,
                formValues: currentFormValues,
              },
            },
          };
        });
      }
    }
  }, [
    InspectionForm,
    checkList,
    currentTab,
    defaultValue?.labItemId,
    defaultValue?.labItemName,
    defaultValue?.orderStatus,
    defaultValue?.uuid,
    formValues,
    infoDetail,
  ]);

  useAsyncEffect(async () => {
    if (defaultValue) {
      const value = {
        ...defaultValue,
        clinicItemName: defaultValue?.labItemName,
        clinicItemId: defaultValue?.labItemId,
        department: defaultValue?.performDepartment,
      };
      if (defaultValue?.uuid) {
        setCheckList([value]);
        setCurrentInfo(value);
        getInitInfoDetail?.(defaultValue?.labItemId);
        vsf?.refs?.testForm?.setFieldsValue({
          labOrder: value,
        });

        setFormValues({
          [value?.clinicItemId]: {
            labOrder: value,
          },
        });
      } else {
        const [value] = await getItemList({
          idIn: [defaultValue?.labItemId],
        });
        handleCheckItem(value);
      }
      allPrice?.onChange?.(defaultValue?.allPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    setCurrentInfo(checkList?.[0]);
  }, [defaultValue, checkList]);

  const dataSource = [
    { label: '个人常用', value: 'PERSONAL' },
    { label: '科室常用', value: 'DEPARTMENT' },
  ];

  const onCollect = async (params) => {
    const res =
      await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
        {
          // favoriteType: radio?.value,
          favoriteType: 'PERSONAL',

          itemClass: 'LAB',
          itemId: params?.clinicItemId,
        },
      );
    if (res?.code === 200) {
      message?.success('收藏成功', 1)?.then(() => getDataSource());
    }
    return res?.data;
  };

  const onCancelCollect = async (params) => {
    const res =
      await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0?.(
        {
          itemClass: 'LAB',
          itemId: params?.clinicItemId,
        },
      );
    if (res?.code === 200) {
      message?.success('取消收藏成功', 1)?.then(() => getDataSource());
    }
    return res?.data;
  };

  const [options, setOptions, , , getInitOptions] = useGetResetInitState([]);

  useAsyncEffect(async () => {
    const res =
      await vsf?.services?.ClinicItemController_allClinicItemDictionaryCharge_d97a65?.(
        {
          qto: {
            from: 0,
            size: 999,
            itemClassIs: 'LAB',
          },
        },
      );
    setOptions(
      res?.data?.map((item) => ({
        ...item,
        clinicItemName: item?.clinicItemName,
        clinicItemId: item?.id,
      })),
    );
  }, []);

  const columns = [
    {
      title: '收费项目名称',
      dataIndex: ['priceItem', 'itemName'],
    },
    {
      title: '单价（元）',
      dataIndex: ['priceItem', 'price'],
    },
    {
      title: '单位',
      dataIndex: ['priceItem', 'unit'],
    },
    {
      title: '数量',
      dataIndex: 'amount',
    },
    {
      title: '金额',
      dataIndex: 'sum',
      render: (...[, record]) =>
        askFixed((record?.amount ?? 0) * (record?.priceItem?.price ?? 0), 2),
    },
  ];

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const array = (
    vsf.stores.dbenums.enums.LAB_PHYSIOLOGICAL_CYCLE_DICT ?? []
  )?.filter((item) =>
    infoDetail?.[
      currentInfo?.clinicItemId
    ]?.labVsClinicItemClinicDto?.labItemVsPhysiologicalCycleDtoList
      ?.map((item) => item?.physiologicalCycleCodeId)
      ?.includes?.(item?.value),
  );

  const departmentDataSource = infoDetail?.[
    currentInfo?.clinicItemId
  ]?.labVsClinicItemClinicDto?.labItemVsDepartmentDtoList?.map((item) => ({
    departmentName:
      item?.departmentName +
      (item?.institutionName ? `(${item?.institutionName})` : ''),
    id: item?.departmentId,
  }));

  return (
    <div className="cpoe-test">
      <div className="main">
        <div className="left">
          <Flex gap={10}>
            <Determine condition={currentTab === '常用项目'}>
              <Radio.Group
                dataSource={dataSource}
                {...radio}
                optionType="button"
                disabled={defaultValue?.uuid}
              />
            </Determine>
            <Select
              style={{
                flex: '0 0 50%',
              }}
              placeholder="请输入项目名称或首字母"
              onSearch={(e: any) => {
                search?.onChange(e?.target?.value);
              }}
              showSearch
              fieldNames={{
                label: 'clinicItemName',
                value: 'clinicItemId',
              }}
              options={options}
              onChange={(...[, value]) => {
                handleCheckItem(value);
              }}
              filterOption={(value, option) =>
                askSearch(value, option, 'clinicItemName')
              }
              disabled={defaultValue?.uuid}
            />
          </Flex>
          <div
            className={classnames(
              'list',
              defaultValue?.uuid ? 'aspirin-disabled-box' : '',
            )}
          >
            <AsyncSuspense loading={loading} dataSource={items?.length}>
              {items?.map((item: any, index) => (
                <div
                  className="item"
                  key={index}
                  {...(defaultValue?.uuid
                    ? {}
                    : {
                        onClick: () => handleCheckItem(item),
                      })}
                >
                  <div>
                    <div>
                      <Checkbox
                        value={checkList
                          ?.map(({ clinicItemId }) => clinicItemId)
                          ?.includes(item?.clinicItemId)}
                      />
                    </div>
                    <div>{item?.clinicItemName}</div>
                  </div>
                  <Flex align="center">
                    <div>
                      <Determine
                        condition={item?.doctorFavorite}
                        fallback={
                          <ICON
                            type="icon-meiyoushoucang"
                            size={24}
                            style={{ color: 'rgb(174, 176, 179)' }}
                            onClick={(event) => {
                              event?.stopPropagation();
                              onCollect(item);
                            }}
                          />
                        }
                      >
                        <ICON
                          size={24}
                          style={{ color: '#ffa77e' }}
                          type="icon-shoucang1"
                          onClick={(event) => {
                            event?.stopPropagation();
                            onCancelCollect(item);
                          }}
                        />
                      </Determine>
                    </div>
                    <div>
                      <Icon type="icon-Frame1" size={20} />
                    </div>
                    <div>
                      <Popover
                        color="#fff"
                        style={{
                          width: 'auto',
                        }}
                        trigger="hover"
                        title="收费详情"
                        content={
                          <Table
                            pagination={false}
                            columns={columns}
                            dataSource={item?.clinicVsChargeDtoList ?? []}
                          />
                        }
                      >
                        <Icon type="icon-Frame-14" size={18} />
                      </Popover>
                    </div>
                  </Flex>
                </div>
              ))}
            </AsyncSuspense>
          </div>
        </div>
        <div className="right">
          <div className="first">
            <Block
              title="检验项目"
              scroll
              extra={
                <Determine condition={!defaultValue?.uuid}>
                  <div className="clear-all" onClick={handleClearAll}>
                    <Icon type="icon-shanchuwudi" />
                    <span onClick={() => restCheckList()}>清空</span>
                  </div>
                </Determine>
              }
            >
              <div className="check-list">
                <Determine
                  condition={Boolean(checkList?.length)}
                  fallback={
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{ height: 60 }}
                      description="未选择检验项目"
                    />
                  }
                >
                  {checkList?.map((v: any, index) => {
                    const showPrice = (v?.clinicVsChargeDtoList ?? [])?.reduce(
                      (sum: any, currentValue: any) => {
                        return (
                          sum +
                          (currentValue?.amount ?? 0) *
                            (currentValue?.priceItem?.price ?? 0)
                        );
                      },
                      0,
                    );
                    const inspect = inspectList?.find(
                      (home) => home?.id === v?.clinicItemId,
                    );
                    return (
                      <div
                        className={classnames('item', {
                          'active-item':
                            v?.clinicItemId === currentInfo?.clinicItemId,
                          'inspect-item': inspect?.inspect,
                        })}
                        key={index}
                        onClick={() => {
                          handleSetCurrent(v);
                        }}
                      >
                        <div className="content">
                          <div className="label">{v?.clinicItemName}</div>
                          <div className="value">
                            ¥ {askFixed(showPrice || defaultValue?.allPrice, 2)}
                          </div>
                        </div>
                        {!defaultValue?.uuid && (
                          <div
                            className="oper"
                            onClick={() => {
                              handleDelItem(v);
                            }}
                          >
                            <Icon type="icon-shanchuwudi" />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Determine>
              </div>
            </Block>
          </div>
          <Determine condition={showLab}>
            <div className="second">
              <Block title="标本信息">
                <VSControlledForm
                  className="info-form"
                  {...(!defaultValue?.uuid
                    ? {
                        value: formValues?.[currentInfo?.clinicItemId],
                        onValuesChange,
                        key: currentInfo?.clinicItemId,
                      }
                    : {})}
                  labelAlign="left"
                  id="testForm"
                >
                  <VSFormLayout columnCount={1} labelCol={labelColWidth[6]}>
                    {isShowEmergencyIndicator && (
                      <VSFormItem
                        name={['labOrder', 'emergencyIndicator']}
                        label="加急"
                        valueType="switch"
                        fieldProps={{
                          disabled:
                            !infoDetail?.[currentInfo?.clinicItemId]
                              ?.labVsClinicItemClinicDto?.emergencyIndicator,
                        }}
                        rules={[
                          {
                            required:
                              infoDetail?.[currentInfo?.clinicItemId]
                                ?.labVsClinicItemClinicDto?.emergencyIndicator,
                          },
                        ]}
                        initialValue={false}
                      />
                    )}

                    <VSFormItem
                      name={['labOrder', 'specimenId']}
                      label="标本"
                      valueType="select"
                      rules={[{ required: true }]}
                      fieldProps={{}}
                      dataSource={infoDetail?.[
                        currentInfo?.clinicItemId
                      ]?.labVsClinicItemClinicDto?.labItemVsSpecimenDtoList?.map(
                        (item) => ({
                          label: item?.specimenName,
                          value: item?.specimenId,
                        }),
                      )}
                    />

                    <VSFormItem
                      name={['labOrder', 'department']}
                      label="科室"
                      valueType="select"
                      rules={[{ required: true }]}
                      fieldNames={{
                        label: 'departmentName',
                        value: 'id',
                      }}
                      dataSource={departmentDataSource}
                    />

                    <VSFormItem
                      name={['labOrder', 'physiologicalCycle']}
                      label="生理周期"
                      valueType="select"
                      fieldProps={{
                        disabled: array?.length === 0 || !array,
                      }}
                      dataSource={array}
                    />

                    <VSFormItem
                      name={['labOrder', 'sampleLocation']}
                      label="采集部位"
                      valueType="select"
                      fieldProps={{
                        disabled:
                          infoDetail?.[currentInfo?.clinicItemId]
                            ?.labVsClinicItemClinicDto
                            ?.labItemVsSampleLocationDtoList?.length === 0,
                      }}
                      dataSource={(
                        vsf.stores.dbenums.enums.LAB_COLLECTION_LOCATION_DICT ??
                        []
                      )?.filter(
                        (item) =>
                          infoDetail?.[
                            currentInfo?.clinicItemId
                          ]?.labVsClinicItemClinicDto?.labItemVsSampleLocationDtoList?.findIndex(
                            (lItem) =>
                              lItem?.sampleLocationCodeId === item?.value,
                          ) >= 0,
                      )}
                    />

                    {isShowMeno && (
                      <VSFormItem
                        name={['labOrder', 'memo']}
                        label="备注"
                        valueType="textarea"
                        fieldProps={{
                          className: 'textarea',
                        }}
                      />
                    )}
                  </VSFormLayout>
                </VSControlledForm>
              </Block>
            </div>
          </Determine>
        </div>
      </div>
    </div>
  );
});

Content.displayName = 'Content';

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [currentTab] = useOnChangeValue('常用项目');
  // const [currentTabInfo, setCurrentTabInfo] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<any>();

  const [testItems, setTestItems] = useState<any>([]);
  const onClose = () => {
    setOpen(false);
    allPrice?.onChange(0);
  };
  const onOpen = (v) => {
    if (v) {
      // console.log(v, 'v');
      setDefaultValue(v ?? undefined);
      currentTab?.onChange(v?.categoryType);
    } else {
      // currentTab?.onChange(testItems?.[0]?.value);
      setDefaultValue(v);
    }
    setOpen(true);
  };

  useAsyncEffect(async () => {
    const res = await vsf.services?.LabController_getAllByLabClassQto_a6f1c8?.({
      qto: {
        from: 0,
        size: 999,
        stopIndicatorIs: false,
        institutionIdIs: 501,
      },
    });
    if (res?.code === 200) {
      const target = (res?.data ?? [])?.map((item) => ({
        label: item?.labClassName,
        key: String(item?.id),
        value: item?.id,
        origin: item,
      }));
      setTestItems(target);
    }
  }, []);

  const { resolve, promise } = useWithResolvers();
  const count = useRef(false);
  useImperativeHandle(ref, () => ({
    onOpen,
    onAsyncOpen: (value) => {
      onOpen?.(value);
      count.current = true;
      return promise.then((res) => {
        count.current = false;
        return res;
      });
    },
  }));

  const dataSource = [
    { label: '常用项目', key: '常用项目', disabled: defaultValue?.uuid },
    ...testItems?.map((item) => ({
      ...item,
      disabled: defaultValue?.uuid,
    })),
  ];

  const [allPrice] = useOnChangeValue(0.0);
  const labRef = useRef<{ handleSubmit: () => Promise<unknown[]> }>(null);

  useEffect(() => {
    if (open) currentTab?.onChange?.('常用项目');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const Footer = () => {
    return (
      <div
        className="footer"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div
          className="info"
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <span className="label">费用合计</span>
          <span className="value dinpro-medium">¥ {allPrice?.value}元</span>
        </div>
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            const value = await labRef?.current?.handleSubmit?.();
            if (value) {
              if (count.current) {
                resolve(value);
              } else {
                onSubmit(value);
              }
              onClose?.();
            }
          }}
        >
          确定
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      className="aspirin-drawer-border-bottom"
      destroyOnClose
      title={<TabCheck dataSource={dataSource} {...currentTab} />}
      placement="right"
      onClose={onClose}
      open={open}
      width={'70%'}
      footer={<Footer />}
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Content
        {...props}
        defaultValue={defaultValue}
        open={open}
        ref={labRef}
        currentTab={currentTab?.value}
        allPrice={allPrice}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
