import React, { useEffect, useState } from 'react';
import './index.less';
import { DatePicker, Input, Modal, message } from '@vs/vsf-kit';
import { TableCartTitle } from './components/TableCartTitle';
import { SelectedCart, TableCart } from './components/TableCart';
import Icon from '@/module/Icon';
import dayjs, { Dayjs } from 'dayjs';
import RegistratuonForm from './components/RegistratuonForm';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import { useMount, useRequest } from 'ahooks';
import data from '@/components/Address/data';
var isBetween = require('dayjs/plugin/isBetween');

//应用插件
dayjs.extend(isBetween);

function index(props) {
  //时间选择框格式化
  const weekFormat = 'MM/DD';
  const { routes } = props;
  const { data: registerListData, run } = useRequest(
    async () => {
      const res =
        await vsf?.services?.OutpRegisterController_getClinicDetailForAppoint_724f16?.(
          {},
        );
      return res.data;
    },
    {
      manual: true,
    },
  );

  const { data: dataRangeData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a?.(
        {
          qto: {
            registerPlatformIndicatorIs: false,
          },
        },
      );
    return res?.data?.find((item) => item?.id === 101)?.openDays - 1;
  });
  const [isSelectStatus, setIsSelectStatus] = useState(false);
  const [selectData, setSelectData] = useState();
  //周时间
  const [filterList, setFilterList] = useState();
  const [numberData, setNumberData] = useState();
  const [weekDaysList, setWeekDaysList] = useState([]);
  const [filterRegisterListData, setFilterRegisterListData] = useState();

  const [subFlushStatus, setSubFlushStatus] = useState();

  //保存锚点的唯一id(索引)
  const [indexing, setIndexing] = useState(0);
  const [weekList, setWeekList] = useState();
  const [selectweekList, setSelectWeekList] = useState();

  //内控卡片选择状态
  const [selectIndex, setSelectIndex] = useState();
  const selectChange = (p) => {
    setSelectIndex(p);
  };

  const weekObj = ['日', '一', '二', '三', '四', '五', '六'];

  //获取某天之后七天集合标头
  const getWeekList = (currentDate) => {
    const weekDays = Array.from({ length: 7 }, (_, i) => {
      return currentDate.add(i, 'day');
    });
    const weekDaysArr = weekDays?.map((item) => {
      return `${item.format('YYYY-MM-DD')}(${weekObj[item.day()]})`;
    });
    setWeekList(weekDaysArr);
    const arr = weekDays?.map((item) => {
      return item.format('YYYY-MM-DD');
    });
    setSelectWeekList(arr);
    return arr;
  };

  useMount(() => {
    // 获取当前日期
    const currentDate = dayjs();
    setWeekDaysList(getWeekList(currentDate));
    run();
  });
  const disabledDate = (current) => {
    // 当前日期
    const currentDate = dayjs();

    // 今天之前的日期都禁用
    if (current && current < currentDate.startOf('day')) {
      return true;
    }

    // 14天后的日期都禁用
    if (
      current &&
      current > currentDate.add(dataRangeData || 0, 'day').endOf('day')
    ) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    //当点击时间选择框的时候，列表会过滤到7天内且排序
    if (registerListData) {
      const filter = registerListData.map((item) => {
        const arr = item.clinicRegisterList?.map((item) => {
          const weekArr = Array(7).fill({});

          const list = item?.clinicRegisterDetailByDateList.map((item) => {
            if (
              !(
                dayjs(item.clinicDate).isBefore(dayjs(weekDaysList[0])) ||
                dayjs(item.clinicDate).isAfter(
                  dayjs(weekDaysList[weekDaysList.length - 1]),
                )
              )
            ) {
              selectweekList.map((item1) => {
                if (
                  dayjs(item1).isSame(
                    dayjs(dayjs(item.clinicDate).format('YYYY-MM-DD')),
                  )
                ) {
                  let list = item?.clinicRegisterDetailVoList.sort((a, b) => {
                    // 将上午排在下午前面
                    if (a.timeDescription === 'AM') return -1;
                    if (b.timeDescription === 'PM') return 1;
                    return 0;
                  });
                  if (list.length === 1 && list[0].timeDescription === 'PM') {
                    list = [{}, list[0]];
                  }
                  const file = JSON.parse(JSON.stringify(item));
                  file.clinicRegisterDetailVoList = list;
                  weekArr[selectweekList?.indexOf(item1)] = { ...file };
                }
              });
            }
            return weekArr;
          });
          return { ...item, clinicRegisterDetailByDateList: weekArr };
        });
        return { ...item, clinicRegisterList: arr };
      });

      setFilterRegisterListData(filter);
    }
  }, [weekDaysList, registerListData]);

  useEffect(() => {
    if (selectData) {
      message.destroy();
      message.success('选择成功');
    }
  }, [selectData]);

  const RefreshTable = () => {
    setRegisterListData(undefined);
    // run();
    location.reload();
  };
  const subFlushStatusChange = (val) => {
    setSubFlushStatus();
  };

  const setIsSelectStatusChange = (val) => {
    setIsSelectStatus(val);
  };
  const scrollToAnchor = (id) => {
    const element = document.getElementById(id);
    setIndexing(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const selectCardChange = (value) => {
    return setSelectData(value);
  };

  const selectNumChange = (numberData) => {
    return setNumberData(numberData);
  };

  const inputChange = (e) => {
    const list = filterRegisterListData?.map((item) => {
      const clinicRegisterArr = item?.clinicRegisterList?.filter((item) => {
        return item?.clinicLabel?.includes(e.target.value);
      });
      return { ...item, clinicRegisterList: clinicRegisterArr };
    });
    if (e.target.value) {
      setFilterList(list);
    } else {
      setFilterList(undefined);
    }
  };

  return (
    <div className="appointment-container-layout">
      <div className="register-cart-top">
        <div className="register-cart-top-left">
          <div className="medical-steps">
            <ul>
              {registerListData &&
                (filterRegisterListData || registerListData).map(
                  (item, index) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <li
                        className={indexing !== index ? 'selected' : 'unselect'}
                        index={index}
                      >
                        <a
                          href={'#index' + index}
                          onClick={() => scrollToAnchor(index)}
                        >
                          {item?.outpSpecialName}
                        </a>
                      </li>
                    );
                  },
                )}
            </ul>
          </div>
        </div>
        <div className="register-cart-top-right">
          <div className="register-cart-serach">
            <div className="serach-input">
              <Input
                className="serach-input-inp"
                placeholder="请输入号别"
                prefix={<Icon type="icon-sousuo" />}
                onChange={inputChange}
              />
            </div>
            <div className="select-cart-show">
              <div className="select-cart-show-title">选中号别：</div>
              <div
                className="select-cart-show-content"
                onClick={() => {
                  selectData && setSelectData(undefined);
                  numberData && setNumberData(undefined);
                }}
              >
                {selectData && (
                  <SelectedCart
                    selectData={selectData}
                    numberData={numberData}
                  />
                )}
              </div>
            </div>
            <div className="bill-number">
              时间：
              <div className="select-cart-farewell">
                <DatePicker
                  defaultValue={dayjs()}
                  locale={locale}
                  disabledDate={disabledDate}
                  onSelect={(value) => {
                    setWeekDaysList(getWeekList(dayjs(value)));
                  }}
                  allowClear={false}
                />
              </div>
            </div>
          </div>
          <div
            className="register-cart-main-label"
            style={{ marginBottom: '10px' }}
          >
            <div className="table-title-layout">
              <div
                style={{
                  textAlign: 'left',
                  placeSelf: 'center',
                  minWidth: '164px',
                  marginLeft: '20px',
                }}
              >
                号别信息
              </div>
              {weekList &&
                weekList.map((item, index) => {
                  return (
                    <div
                      style={{
                        textAlign: 'center',
                        minWidth: '200px',
                        paddingRight: '6px',
                      }}
                    >
                      <p className="week">{item}</p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="register-cart-main">
            <div className="register-cart-main-container">
              {registerListData &&
                (filterList || filterRegisterListData || registerListData).map(
                  (items, index2) => {
                    return (
                      <div>
                        <div className="register-cart-main-title">
                          <div
                            className="register-cart-main-title-before"
                            id={'index' + index2}
                          >
                            {items?.outpSpecialName}
                          </div>
                        </div>
                        {items?.clinicRegisterList?.map((item, index1) => {
                          return (
                            <div className="register-cart-main-content">
                              <TableCartTitle
                                data={item}
                                outpSpecialClinicName={items?.outpSpecialName}
                              ></TableCartTitle>

                              {item?.clinicRegisterDetailByDateList?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {item.clinicRegisterDetailVoList ? (
                                        <div
                                          className={'appointment-cart-content'}
                                        >
                                          <TableCart
                                            isSelectStatus={isSelectStatus}
                                            setIsSelectStatusChange={
                                              setIsSelectStatusChange
                                            }
                                            indexId={`${index2}${index1}${index}`}
                                            selectIndex={selectIndex}
                                            selectChange={selectChange}
                                            data={item}
                                            selectCardChange={selectCardChange}
                                            selectNumChange={selectNumChange}
                                            subFlushStatusChange={
                                              subFlushStatusChange
                                            }
                                            subFlushStatus={subFlushStatus}
                                            selectData={selectData}
                                          ></TableCart>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </>
                                  );
                                },
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="register-serach-bottom">
        <RegistratuonForm
          selectData={selectData}
          numberData={numberData}
          RefreshTable={RefreshTable}
          callBack={() => {
            run();
            selectChange(undefined);
            setSelectData(undefined);
          }}
          routes={routes}
        />
      </div>
    </div>
  );
}

export default index;
