import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import { getEnumsLabel } from './methods';

export const getVisitRecordList = async (params) => {
  if (params?.visitDateRangeIn)
    params.visitDateRangeIn = {
      begin: params.visitDateRangeIn[0],
      end: params.visitDateRangeIn[1],
      beginInclude: true,
      endInclude: true,
    };
  const res =
    await vsf?.services?.OutpVisitController_getVisitRecordList_034435?.({
      qto: {
        // from: params?.pagination?.from ?? 0,
        // size: params?.pagination?.size ?? 20,
        ...params?.search,
        ...params?.extra,
        ...(params ?? {}),
      },
    }); // TODO: 请确认入参来源及出参用法

  if (res?.data?.length <= 0) return message.error('未查询到数据');
  const output =
    convertGetVisitRecordListRes2OutpMedicalTechnologyChargeOutpVisitForm?.(
      res,
    );
  return output;
};
/**
 * convertGetVisitRecordListRes2OutpMedicalTechnologyChargeOutpVisitForm
 * @param {any} input
 * @returns {any}
 */
const convertGetVisitRecordListRes2OutpMedicalTechnologyChargeOutpVisitForm = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = []; // TODO 没有匹配到合适字段 output.displayIdIs = null; // String undefined
  // output.visitDateRangeIn = {};
  // TODO 没有匹配到合适字段 output.visitDateRangeIn.begin = null; // Date undefined
  // TODO 没有匹配到合适字段 output.visitDateRangeIn.end = null; // Date undefined
  // TODO 没有匹配到合适字段 output.visitDateRangeIn.beginInclude = null; // Boolean undefined
  // TODO 没有匹配到合适字段 output.visitDateRangeIn.endInclude = null; // Boolean undefined

  output?.push(
    ...(_.get(input, 'data')?.map((ele, i) => {
      return {
        id: _.get(ele, `id`),
        name: _.get(ele, `name`),
        gender: _.get(ele, `gender`),
        chargeTypeCode: _.get(ele, `chargeTypeCode`),
        identityType: _.get(ele, `identityType`), // TODO 请确认下行字段是否符合逻辑
        departmentName: _.get(
          ele,
          `clinicRegisterDefine.clinicDepartment.departmentName`,
        ),
        visitDate: _.get(ele, `visitDate`),
        visitType: _.get(ele, 'visitType'),
        outpVisitId: _.get(ele, 'id'),
        clinicLabel: _.get(ele, 'clinicRegisterDefine.clinicLabel'),
        displayId: _.get(ele, 'patient.displayId'),
        age: _.get(ele, 'age'),
      };
    }) ?? []),
  );

  return output;
};

// 获取患者详情；
export const getPatientDetall = async (params) => {
  const res =
    await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
      { qto: { ...params, from: 0, size: 20 } },
    );
  if (res?.data?.result.length >= 1) return res?.data?.result;
  // message.error('未查到患者信息');
  return false;
};

export const getOrderListByOutpVisitId = async (params) => {
  const { outpVisitId } = params;
  if (!outpVisitId) return [];
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_getOrderListByOutpVisitId_c52096?.(
      { outpVisitId: outpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  // TODO: 请确认入参来源及出参用法
  const output =
    convertGetOrderListByOutpVisitIdRes2OutpMedicalTechnologyChargeOrderList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetOrderListByOutpVisitIdRes2OutpMedicalTechnologyChargeOrderList
 * @param {any} input
 * @returns {any}
 */
const convertGetOrderListByOutpVisitIdRes2OutpMedicalTechnologyChargeOrderList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.clinicClass = _.get(d, 'clinicClass');
        obj.applyStaffName = _.get(d, 'doctor.staffName');
        obj.applyStaffNameId = _.get(d, 'doctor.id');
        obj.id = _.get(d, 'id');
        obj.outpVisitId = _.get(d, 'outpVisitId');
        obj.patientId = _.get(d, 'patientId');
        obj.orderText = _.get(d, 'orderText');
        obj.dosage = _.get(d, 'dosage');
        obj.administration = _.get(d, 'administration');
        obj.frequency = _.get(d, 'frequency');
        obj.orderStatus = _.get(d, 'orderStatus');
        obj.createDateTime = _.get(d, 'createDateTime');
        obj.orderClass = _.get(d, 'orderClass');
        obj.chargeStatus = _.get(d, 'chargeStatus');
        return obj;
      }) ?? []),
    );
    return output;
  };

export const getOrderOutpBillingDetailList = async (params) => {
  if (!params?.orderId) return [];
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_getOrderOutpBillingDetailList_7d1312?.(
      { orderId: params?.orderId },
    );
  const output =
    convertGetOrderOutpBillingDetailListRes2OutpMedicalTechnologyChargeOrderBillingDetailList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetOrderOutpBillingDetailListRes2OutpMedicalTechnologyChargeOrderBillingDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetOrderOutpBillingDetailListRes2OutpMedicalTechnologyChargeOrderBillingDetailList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.itemName = _.get(d, 'itemName');
        obj.id = _.get(d, 'id');
        obj.orderId = _.get(d, 'orderId');
        obj.outpVisitId = _.get(d, 'outpVisitId');
        obj.patientId = _.get(d, 'patientId');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.price = _.get(d, 'price');
        obj.materialCode = _.get(d, 'materialCode');
        obj.amount = _.get(d, 'amount');
        obj.amountPerform = _.get(d, 'amountPerform'); // TODO 没有匹配到合适字段 obj.cancelPerformAmount = null; // Bigdecimal 取消执行数量
        obj.cost = _.get(d, 'cost');
        obj.performStaffName =
          _.get(d, 'performStaff.staffName') ??
          vsf?.stores?.user?.staffInfo?.id;
        obj.performStaffId =
          _.get(d, 'performStaff.id') ?? vsf?.stores?.user?.staffInfo?.id;
        obj.performDepartmentName = _.get(
          d,
          'performDepartment.departmentName',
        );
        obj.performDepartmentId = _.get(d, 'performDepartment.id');
        obj.applyStaffName = _.get(d, 'applyStaff.staffName');
        obj.applyStaffId = _.get(d, 'applyStaff.id');
        obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName');
        obj.applyDepartmentId = _.get(d, 'applyDepartment.id');
        obj.itemId = _.get(d, 'itemId'); // TODO 没有匹配到合适字段
        obj.operateDate = _.get(d, 'operateDate'); // Date 计费日期
        obj.orderDate = _.get(d, 'orderDate');
        obj.operatorStaffName = _.get(d, 'operator.staffName');
        obj.insuranceAuditNumber = _.get(d, 'insuranceAuditNumber');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.billingStatus = _.get(d, 'billingStatus');
        return obj;
      }) ?? []),
    );
    return output;
  };

export const getNoOrderOutpBillingDetailGroupList = async (params) => {
  if (!params?.outpVisitId) return [];
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_getNoOrderOutpBillingDetailGroupList_d47375?.(
      { outpVisitId: params?.outpVisitId },
    );
  const data = res?.data?.result ?? res?.data ?? [];
  return {
    data: data?.map((ele) => {
      return {
        ...ele,
        id: `TEMP_${Math?.random?.()}`,
      };
    }),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};

/**
 * convertGetNoOrderOutpBillingDetailGroupListRes2OutpMedicalTechnologyChargeNoOrderChargeBillingDetailList
 * @param {any} input
 * @returns {any}
 */
export const convertGetNoOrderOutpBillingDetailGroupListRes2OutpMedicalTechnologyChargeNoOrderChargeBillingDetailList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        obj.TheRowType = 'Sava';
        obj.itemName = _.get(d, 'itemName');
        obj.id = _.get(d, 'id');
        obj.orderId = _.get(d, 'orderId');
        obj.outpVisitId = _.get(d, 'outpVisitId');
        obj.patientId = _.get(d, 'patientId');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.price = _.get(d, 'price');
        obj.materialCode = _.get(d, 'materialCode');
        obj.amount = _.get(d, 'amount');
        obj.amountPerform = _.get(d, 'amountPerform'); // TODO 没有匹配到合适字段 obj.cancelPerformAmount = null; // Bigdecimal 取消执行数量
        obj.cost = _.get(d, 'cost');
        obj.performStaffName = _.get(d, 'performStaff.staffName');
        obj.performStaffId = _.get(d, 'performStaff.id');
        obj.performDepartmentName = _.get(
          d,
          'performDepartment.departmentName',
        );
        obj.performDepartmentId = _.get(d, 'performDepartment.id');
        obj.applyStaffName = _.get(d, 'applyStaff.staffName');
        obj.applyStaffId = _.get(d, 'applyStaff.id');
        obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName');
        obj.applyDepartmentId = _.get(d, 'applyDepartment.id');
        obj.itemId = _.get(d, 'itemId'); // TODO 没有匹配到合适字段
        obj.operateDate = _.get(d, 'operateDate'); // Date 计费日期
        obj.orderDate = _.get(d, 'orderDate');
        obj.operatorStaffName = _.get(d, 'operator.staffName');
        obj.insuranceAuditNumber = _.get(d, 'insuranceAuditNumber');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.billingStatus = _.get(d, 'billingStatus');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 医嘱保存
export const saveOrderOutpBillingDetails = async (event) => {
  if (event?.length <= 0) return;
  // TODO: 请确认入参来源及出参用法
  const output =
    convertOutpMedicalTechnologyChargeOrderBillingDetailList2SaveOrderOutpBillingDetailsReq?.(
      // vsf?.refs?.outpMedicalTechnologyChargeOrderBillingDetailList?.getSelectedRowValues?.(),
      event,
    );
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_saveOrderOutpBillingDetails_41c276?.(
      output,
    );
  return res;
};
/**
 * convertOutpMedicalTechnologyChargeOrderBillingDetailList2SaveOrderOutpBillingDetailsReq
 * @param {any} input
 * @returns {any}
 */
const convertOutpMedicalTechnologyChargeOrderBillingDetailList2SaveOrderOutpBillingDetailsReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.outpBillingDetailList = [];
    output.outpBillingDetailList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          obj.outpBillingDetailId = _.get(d, 'id'); // Long 明细id
          obj.billingStatus = _.get(d, 'billingStatus'); // Boolean 计费状态
          obj.cancelPerformAmount = _.get(d, 'cancelPerformAmount'); // Bigdecimal 取消执行数量
        }
        obj.orderId = _.get(d, 'orderId'); // String 项目ID
        obj.itemId = _.get(d, 'itemId'); // String 项目ID
        obj.outpVisitId = _.get(d, 'outpVisitId'); // Long 门诊就诊记录id
        obj.amount = _.get(d, 'amount'); // Bigdecimal 数量
        obj.applyStaffId = _.get(d, 'applyStaffId'); // Long 开单医生id
        obj.orderDate = _.get(d, 'orderDate'); // Date 计费日期
        obj.applyDepartmentId = _.get(d, 'applyDepartmentId'); // Long 开单科室id
        obj.performStaffId = _.get(d, 'performStaffId'); // Long 执行医生id
        obj.performDepartmentId = _.get(d, 'performDepartmentId'); // Long 执行科室id
        return obj;
      }) ?? []),
    );
    return output;
  };
export const cancelPerformOutpBillingDetails = async (event) => {
  // TODO: 请确认入参来源及出参用法
  const output =
    convertOutpMedicalTechnologyChargeOrderBillingDetailList2CancelPerformOutpBillingDetailsReq?.(
      event,
    );
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_cancelPerformOutpBillingDetails_5491d7?.(
      output,
    );
  return res;
};
/**
 * convertOutpMedicalTechnologyChargeOrderBillingDetailList2CancelPerformOutpBillingDetailsReq
 * @param {any} input
 * @returns {any}
 */
const convertOutpMedicalTechnologyChargeOrderBillingDetailList2CancelPerformOutpBillingDetailsReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.outpBillingDetailList = [];
    output.outpBillingDetailList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        obj.outpBillingDetailId = _.get(d, 'id'); // Long 明细id
        obj.itemId = _.get(d, 'itemId'); // String 项目ID
        obj.outpVisitId = _.get(d, 'outpVisitId'); // Long 门诊就诊记录id
        obj.amount = _.get(d, 'amount'); // Bigdecimal 数量
        obj.cancelPerformAmount = _.get(d, 'cancelPerformAmount'); // Bigdecimal 取消执行数量
        obj.applyStaffId = _.get(d, 'applyStaffId'); // Long 开单医生id
        obj.orderDate = _.get(d, 'orderDate'); // Date 计费日期
        obj.applyDepartmentId = _.get(d, 'applyDepartmentId'); // Long 开单科室id
        obj.performStaffId = _.get(d, 'performStaffId'); // Long 执行医生id
        obj.performDepartmentId = _.get(d, 'performDepartmentId'); // Long 执行科室id
        obj.billingStatus = _.get(d, 'billingStatus'); // Boolean 计费状态
        return obj;
      }) ?? []),
    );
    return output;
  };

// 非医嘱保存
export const saveNoOrderOutpBillingDetails = async (event) => {
  // TODO: 请确认入参来源及出参用法
  const output =
    convertOutpMedicalTechnologyChargeNoOrderChargeBillingDetailList2SaveNoOrderOutpBillingDetailsReq?.(
      event,
    );
  const res =
    await vsf?.services?.MedicalTechnologyChargeController_saveNoOrderOutpBillingDetails_4689cb?.(
      output,
    );
  return res;
};
/**
 * convertOutpMedicalTechnologyChargeNoOrderChargeBillingDetailList2SaveNoOrderOutpBillingDetailsReq
 * @param {any} input
 * @returns {any}
 */
const convertOutpMedicalTechnologyChargeNoOrderChargeBillingDetailList2SaveNoOrderOutpBillingDetailsReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = {};
    output.outpBillingDetailList = [];
    output.outpBillingDetailList.push(
      ...(input?.map((d, i) => {
        const obj: any = {};
        if (['Sava'].includes(d?.TheRowType)) {
          obj.outpBillingDetailId = _.get(d, 'id'); // Long 明细id
          obj.billingStatus = _.get(d, 'billingStatus'); // Boolean 计费状态
          obj.cancelPerformAmount = _.get(d, 'cancelPerformAmount'); // Bigdecimal 取消执行数量
        }
        obj.itemId = _.get(d, 'itemId'); // String 项目ID
        obj.outpVisitId = _.get(d, 'outpVisitId'); // Long 门诊就诊记录id
        obj.amount = _.get(d, 'amount'); // Bigdecimal 数量
        obj.applyStaffId = _.get(d, 'applyStaffId'); // Long 开单医生id
        obj.orderDate = _.get(d, 'orderDate'); // Date 计费日期
        obj.applyDepartmentId = _.get(d, 'applyDepartmentId'); // Long 开单科室id
        obj.performStaffId = _.get(d, 'performStaffId'); // Long 执行医生id
        obj.performDepartmentId = _.get(d, 'performDepartmentId'); // Long 执行科室id
        return obj;
      }) ?? []),
    );
    return output;
  };
