import vsf from '@vs/vsf-boot';
import {
  Checkbox,
  Select,
  VSTable,
  VSTableColumn,
  VSTableProps,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import { getScrollY, labelNumberWidth } from '@/utils';

import { DosageUnit } from '../../../dosage';
import { StorageSelect } from '../../../drug';
import Flex from '../../../flex';
import { askSearch } from '../../../func/ask';
import { UsageUnit } from '../../../usage';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import { orderClassDataSource } from './datasource';

const OutHospitalTemplateImported = forwardRef((props: any, ref) => {
  const { rowSelection } = props;

  const [value, setValue, onValue] = useGetState<any>([]);
  const getValue: any = () => {
    const list = vsf.refs.aaa?.getEditForm()?.getFieldsValue(true);
    return group?.current?.onSliced?.(
      onValue?.()?.map((item) => {
        const option = list?.[item?.id ?? ''];
        return {
          ...item,
          ...option,
        };
      }),
    );
  };
  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'],
        ['dripSpeed'],
        ['frequency'],
        ['useDescription'],
        ['performDays'],
      ],
      tip() {},
    }),
  );
  useImperativeHandle(ref, () => {
    return {
      setValue: (value) => {
        setValue(group?.current?.onSliced?.(value));
      },
    };
  });
  return (
    <div className="paddingTop24">
      <VSTable
        id="aaa"
        value={value}
        pagination={false}
        scroll={{
          y: getScrollY(isMinScreen ? 190 : 280),
        }}
        bordered
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
          onCanEdit: (record: any) =>
            [1, null, void 0]?.includes(record?.drugOrder?.groupSubNumber),
        }}
        className="aspirin-table"
        onChange={(v) => {
          setValue([...v]);
        }}
        rowClassName={(data: any) => {
          const d =
            vsf.refs.aaa?.getEditForm()?.getFieldValue([data.id]) ?? data;
          if (d?.orderClass === 'DRUG') {
            return d?.supplyIndicator === false
              ? 'aspirin-table-row-red-box-all'
              : '';
          }
          return '';
        }}
      >
        <VSTableColumn
          dataIndex={['check']}
          valueType="custom"
          renderFormItem={(...[, { record: recode }]) => {
            const selectedRows = rowSelection?.selectedRows as any[];
            const state = selectedRows?.map((item) => item?.id);
            const record = getValue()?.find((item) => item?.id === recode?.id);
            const _value = state?.includes(record?.id);
            let render: any = null;
            if (record?.groupName === 'aspirin-order-text-group-first') {
              render = (
                <Checkbox
                  value={_value}
                  onChange={(info) => {
                    const group = getValue()?.filter((...[, index]) =>
                      record?.groupIndex?.includes(index),
                    );
                    if (info) {
                      rowSelection?.onSelectedRowsChange?.(
                        [
                          ...selectedRows?.filter(
                            (item) =>
                              !group
                                ?.map((item: any) => item?.id)
                                ?.includes(item?.id),
                          ),
                          ...group,
                        ],
                        [],
                      );
                    } else {
                      rowSelection?.onSelectedRowsChange?.(
                        selectedRows?.filter(
                          (item) =>
                            !group
                              ?.map((item: any) => item?.id)
                              ?.includes(item?.id),
                        ),
                        [],
                      );
                    }
                  }}
                />
              );
            }
            if (!record?.groupName) {
              render = (
                <Checkbox
                  value={_value}
                  onChange={(info) => {
                    if (info) {
                      rowSelection?.onSelectedRowsChange?.(
                        [...selectedRows, record],
                        [],
                      );
                    } else {
                      rowSelection?.onSelectedRowsChange?.(
                        selectedRows?.filter((item) => item?.id !== record?.id),
                        [],
                      );
                    }
                  }}
                />
              );
            }
            if (
              typeof record?.supplyIndicator === 'boolean' &&
              record?.supplyIndicator === false
            ) {
              render = <Checkbox disabled />;
            }
            return <Flex justify="center">{render}</Flex>;
          }}
          title={() => {
            const selectedRows: any = rowSelection;
            const state = value?.filter(
              (item: any) => item?.supplyIndicator !== false,
            );
            const _value: any =
              selectedRows?.selectedRows?.length === state?.length &&
              selectedRows?.selectedRows?.length !== 0 &&
              state?.length !== 0;
            return (
              <Flex justify="center">
                <Checkbox
                  value={_value}
                  disabled={!state?.length}
                  indeterminate={
                    selectedRows?.selectedRows?.length > 0 &&
                    selectedRows?.selectedRows?.length < state.length
                  }
                  onChange={(info) => {
                    if (info) {
                      selectedRows?.onSelectedRowsChange?.(state, []);
                    } else {
                      selectedRows?.onSelectedRowsChange?.([], []);
                    }
                  }}
                />
              </Flex>
            );
          }}
          preview
          hideInTable={!rowSelection}
          fixed="left"
          // width={30}
          width={labelNumberWidth(3)}
        />
        <VSTableColumn
          title="类别"
          dataIndex={['orderClass']}
          valueType="select"
          dataSource={orderClassDataSource}
          preview
          // renderFormItem={(...[, record]) => {
          //   return (
          //     orderClassDataSource?.find(
          //       (item) => item?.value === record?.record?.orderClass,
          //     )?.label ?? record?.record?.orderClass
          //   );
          // }}
          // width={23}

          width={labelNumberWidth(4)}
        />
        <VSTableColumn
          title="医嘱"
          dataIndex={['orderText']}
          valueType="custom"
          // width="220px"
          width={labelNumberWidth(10)}
          className="aspirin-table-order-cell"
          render={(element, record) => {
            return (
              <div
                className={classNames('aspirin-order-text', record?.groupName)}
              >
                {record?.orderText}
              </div>
            );
          }}
          editable={false}
        />
        <VSTableColumn
          title="剂量"
          dataIndex={['dosage']}
          valueType="inputUnit"
          // width="50px"
          width={labelNumberWidth(4)}
          editable={false}
          render={(...[, record]) => <DosageUnit value={record?.dosage} />}
        />
        <VSTableColumn
          title="用量"
          dataIndex={['usage']}
          valueType="inputUnit"
          // width="50px"
          width={labelNumberWidth(4)}
          render={(...[, record]) => <UsageUnit value={record?.usage} />}
          editable={false}
        />
        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          valueType="administrationSelect"
          // width={50}
          width={labelNumberWidth(4)}
          editable={false}
          fieldProps={(record) => {
            return { record };
          }}
        />
        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          valueType="select"
          // width={50}
          width={labelNumberWidth(4)}
          dataSource={(option) => {
            const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
              (item) => {
                return {
                  ...item,
                  ...JSON?.parse(item?.extensionJson ?? '{}'),
                };
              },
            );
            const state = option?.frequency
              ? value?.filter((item) =>
                  askSearch(option?.frequency, item, 'frequency'),
                )
              : value;
            return state;
          }}
          editable={false}
          render={(...[, record]) => {
            return (
              record?.frequency?.frequency ?? record?.frequency?.label ?? ''
            );
          }}
        />
        <VSTableColumn
          dataIndex={['useDescription']}
          title="用法"
          valueType="select"
          fieldProps={{
            allowClear: true,
            showSearch: true,
          }}
          // width={50}
          width={labelNumberWidth(4)}
          dataSource={vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT}
          editable={false}
        />
        <VSTableColumn
          dataIndex={['performDays']}
          title="天数"
          valueType="digit"
          // width={30}
          width={labelNumberWidth(4)}
          editable={false}
          fieldProps={{
            addonAfter: '天',
            // allowClear: false,
          }}
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
          render={(_, value) => {
            return value?.performDays ? value?.performDays + '天' : '';
          }}
        />
        <VSTableColumn
          dataIndex={['totalAmount']}
          title="数量"
          valueType="inputUnit"
          fieldProps={{
            allowClear: true,
            width: '100%',
          }}
          render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
          editable={false}
          // width={30}
          width={labelNumberWidth(4)}
        />
        <VSTableColumn
          dataIndex={['supplyIndicator']}
          title="标记"
          valueType="custom"
          hideInTable
          preview
          // width={30}

          width={labelNumberWidth(4)}
        />
        <VSTableColumn
          dataIndex={['performDepartment']}
          title="执行科室"
          valueType="custom"
          renderFormItem={(schema, { record }) => {
            return record?.orderClass === 'DRUG' ? (
              <StorageSelect
                {...schema?.originProps}
                record={record}
                id={String?.(record?.id)}
                group={value
                  ?.filter((...[, index]) =>
                    record?.groupIndex?.includes(index),
                  )
                  ?.map((item) => String(item?.id))}
              />
            ) : (
              record?.performDepartment?.departmentName ?? ''
            );
          }}
          // width={100}

          width={labelNumberWidth(7)}
        />
      </VSTable>
    </div>
  );
});

OutHospitalTemplateImported.displayName = 'OutHospitalTemplateImported';

export default OutHospitalTemplateImported;
