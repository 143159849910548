import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAddition,
  VSTableToolbar,
  Modal,
  message,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useCallback } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem } from '@/utils';
import './index.less';
const IdentificationClassTable = (props) => {
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteIdentificationClassDictionary_e88c28?.(
        { id: params?.id },
      );
    if (res?.code === 200) {
      vsf.refs.IdentificationClassTable.reload();
      message.success('删除成功');
    }
    return res?.code === 200; // 返回成功与否
  };

  const onSave = async (params) => {
    const data = vsf.refs.IdentificationClassTable.getValues();
    const formatData = data.map((d) => {
      const dict = {
        ...d,
        clinicRegisterTypeId: props.value?.id,
        priceItemId: d.priceItem?.id,
      };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const res =
      await vsf?.services?.OutpRegisterDefineController_addUpdateIdentificationClassDictionary_70e37e?.(
        { btoParamList: formatData, extApiParam: {} },
      );
    if (res?.code === 200) {
      Modal.success({
        title: '证件分类字典已保存',
      });
    }
    vsf.refs.IdentificationClassTable.reload();
    return res?.data; // todo 整理返回数据格式
  };
  return (
    <div className="layout-ident">
      <VSPromiseTable
        className="aspirin-table"
        pagination={false}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.name}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        id="IdentificationClassTable"
        vsid="64558"
        onFetch={onFetch}
        onRemove={onRemove}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        scroll={{
          // y: getScrollY(280),
          y: getScrollYRem(20),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="id"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['serialNumber']}
          title="排序号"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '排序号的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="分类名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '分类名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['extension', 'classType']}
          title="分类类型"
          valueType="select"
          dataSource={[
            { label: '身份证', value: 'IDENTITY_CARD' },
            { label: '医保账号', value: 'INSURANCE' },
            { label: '其他', value: 'OTHER' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSTableColumn
          dataIndex={['extension', 'defaultIdentity']}
          title="默认人员类别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSTableColumn
          dataIndex={['extension', 'defaultChargeType']}
          title="默认费别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="输入码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'input_code长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['extension', 'memo']}
          title="备注"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '备注长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableToolbar title="证件分类维护" style={{ paddingLeft: '20px' }}>
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(IdentificationClassTable);
