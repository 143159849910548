import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_getPatientPreDischargeNotifyStatus_02790e: {
    method: 'post',
    url: '/api/clinic-inp-adt/get-patient-pre-discharge-notify-status',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordOrderDrugController_getDeliveryDepartmentList_bc5ead: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-delivery-department-list',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: PerformDepartmentVoClinicRecordOrderDrugEntranceWebVo[],
    ) => data,
  },
});
