import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_createMedicalGroup_f4b186: {
    method: 'post',
    url: '/api/user-group/create-medical-group',
    parameterFomatter: (data?: {
      btoParam: CreateMedicalGroupBtoUserGroupServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserGroupController_updateMedicalGroup_75af28: {
    method: 'post',
    url: '/api/user-group/update-medical-group',
    parameterFomatter: (data?: {
      btoParam: UpdateMedicalGroupBtoUserGroupServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-page-user-staff-by-input-code-qto',
    parameterFomatter: (data?: {
      qto: StaffWithDoctorQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDoctorVoUserStaffEntranceWebVo[],
    ) => data,
  },
});
