import { isEmpty } from 'lodash';

// 下调了2px
const baseSize = 14;
function calculateFontSize(deviceWidth, designWidth = 2048, baseFontSize = 14) {
  if (deviceWidth > designWidth) {
    return baseFontSize;
  }
  // 假设设计稿的宽度是2048px，对应的font-size是16px
  // 那么，在其他设备上，我们可以根据设备的实际宽度与设计宽度的比例来计算font-size
  const scale = deviceWidth / designWidth;
  // 注意：这里我们直接使用了比例，没有考虑其他因素（如最小字体大小限制等）
  // 在实际应用中，你可能需要添加一些额外的逻辑来处理这些情况
  const fontSize = baseFontSize * scale;
  // 设置最小分辨率的字体为12
  // if (fontSize < 12) {
  //   return 12.45;
  // }
  // console.log(fontSize, 'fontSize');
  // 返回计算得到的font-size（单位：px）
  return fontSize;
}
const setRem = () => {
  const deviceWidth = document?.documentElement?.clientWidth;
  const element_body = document?.querySelector('body');
  const is_element_body_width = isEmpty(element_body.style.width);
  if (is_element_body_width && deviceWidth < 1440) {
    element_body.style.width = '1440px';
    element_body.style.overflowX = 'scroll';
  } else {
    element_body.style.width = '';
    element_body.style.overflowX = '';
  }
  const calculatedFontSize = calculateFontSize(deviceWidth);
  document.documentElement.style.fontSize = `${calculatedFontSize}px`;
};

setRem();

window.onresize = function () {
  setRem();
};
