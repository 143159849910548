export default function askFlatTree(list: any[], id: string) {
  const arr: any[] = [];
  list?.forEach((item) => {
    arr.push(item?.[id]);
    if (item?.children?.length) {
      arr.push(...askFlatTree(item?.children, id));
    }
  });
  return arr;
}
