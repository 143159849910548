import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpVisitController_queryErpVisitPreDischargeVoByErpVisitPreDischargeQto_c793f6:
    {
      method: 'post',
      url: '/api/clinic-erp-visit/query-erp-visit-pre-discharge-vo-by-erp-visit-pre-discharge-qto',
      parameterFomatter: (data?: {
        qto: ErpVisitPreDischargeQtoClinicErpVisitPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpVisitPreDischargeVoClinicErpVisitEntranceWebVo[],
      ) => data,
    },
  ClinicErpAdtController_savePreDischargePatient_ec6360: {
    method: 'post',
    url: '/api/clinic-erp-adt/save-pre-discharge-patient',
    parameterFomatter: (data?: {
      savePreDischargePatientBto: SavePreDischargePatientBtoClinicErpAdtServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicErpAdtController_deletePreDischargePatient_0d1e4b: {
    method: 'post',
    url: '/api/clinic-erp-adt/delete-pre-discharge-patient',
    parameterFomatter: (data?: {
      deleteErpPreDischargePatientBto: DeleteErpPreDischargePatientBtoClinicErpAdtServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
