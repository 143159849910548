import './index.less';

import { Button, Input, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React from 'react';

import { getPrefixCls } from '@/config';

import Block from '../components/block';
import Chart from './chart';
import Info from './info';
import MenuList from './menu';

const mockDaySign = [
  {
    label: '身高',
    value: '175cm',
  },
  {
    label: '体重',
    value: '75kg',
  },
  {
    label: '血压',
    value: '100mmHg',
  },
  {
    label: '血糖',
    value: '5.1mmol/L',
  },
  {
    label: 'BMI',
    value: '/',
  },
  {
    label: '呼吸',
    value: '/',
  },
  {
    label: '脉搏',
    value: '/',
  },
  {
    label: '体温',
    value: '/',
  },
];

const Index = () => {
  const centerCls = getPrefixCls('index-center');

  const handleShowMore = () => {};
  return (
    <div className={centerCls}>
      <Block className="block1">
        <Info />
      </Block>
      <Block className="block2">
        <MenuList />
      </Block>
      <div className="main-container">
        <div className="first">
          <div className="left">
            <Block className="block3" title="主诉">
              <div className="text">
                <Input value="发现肝功能异常1月，皮疹1周" />
              </div>
            </Block>
            <Block className="block4" title="诊断" scroll>
              <VSPromiseTable
                id="1"
                onFetch={async (params) => {
                  return {
                    data: [
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                      {
                        title: '5%葡萄糖注射液(5GS)',
                        quantity: '250ml',
                        time: '2023-07-27',
                      },
                    ],
                    total: 0,
                  };
                }}
                showHeader={false}
                pagination={false}
                cardProps={{
                  bodyStyle: {
                    padding: 0,
                  },
                }}
              >
                <VSTableColumn
                  dataIndex={['title']}
                  title="组件"
                  editable={false}
                  valueType="digit"
                  width={170}
                />

                <VSTableColumn
                  dataIndex={['quantity']}
                  title="是否被锁定"
                  editable={false}
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['time']}
                  title="锁定时间"
                  editable={false}
                  valueType="date"
                  width={100}
                />
              </VSPromiseTable>
            </Block>
          </div>
          <div className="right">
            <Block className="main-chart block5" title="体征">
              <Chart />
            </Block>
            <Block className="other block5" title="今日体征">
              <div className="day-sign">
                {mockDaySign?.map((item) => (
                  <div className="item" key={item?.label}>
                    <span>{item?.label}</span>
                    <span>{item?.value}</span>
                  </div>
                ))}
              </div>
            </Block>
          </div>
        </div>
        <div className="second">
          <Block
            className="block6"
            title="用药记录"
            extra={
              <Button
                className="nopadding"
                type="link"
                onClick={handleShowMore}
              >
                更多
              </Button>
            }
            scroll
          >
            <VSPromiseTable
              id="1"
              onFetch={async (params) => {
                return {
                  data: [
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                  ],
                  total: 0,
                };
              }}
              showHeader={false}
              pagination={false}
              cardProps={{
                bodyStyle: {
                  padding: 0,
                },
              }}
            >
              <VSTableColumn
                dataIndex={['title']}
                title="组件"
                editable={false}
                valueType="digit"
                width={170}
              />

              <VSTableColumn
                dataIndex={['quantity']}
                title="是否被锁定"
                editable={false}
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['time']}
                title="锁定时间"
                editable={false}
                valueType="date"
                width={100}
              />
            </VSPromiseTable>
          </Block>
          <Block className="block6" title="检查" scroll>
            <VSPromiseTable
              id="1"
              onFetch={async (params) => {
                return {
                  data: [
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                  ],
                  total: 0,
                };
              }}
              showHeader={false}
              pagination={false}
              cardProps={{
                bodyStyle: {
                  padding: 0,
                },
              }}
            >
              <VSTableColumn
                dataIndex={['title']}
                title="组件"
                editable={false}
                valueType="digit"
                width={170}
              />

              <VSTableColumn
                dataIndex={['quantity']}
                title="是否被锁定"
                editable={false}
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['time']}
                title="锁定时间"
                editable={false}
                valueType="date"
                width={100}
              />
            </VSPromiseTable>
          </Block>
          <Block className="block6" title="检验" scroll>
            <VSPromiseTable
              id="1"
              onFetch={async (params) => {
                return {
                  data: [
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                    {
                      title: '5%葡萄糖注射液(5GS)',
                      quantity: '250ml',
                      time: '2023-07-27',
                    },
                  ],
                  total: 0,
                };
              }}
              showHeader={false}
              pagination={false}
              cardProps={{
                bodyStyle: {
                  padding: 0,
                },
              }}
            >
              <VSTableColumn
                dataIndex={['title']}
                title="组件"
                editable={false}
                valueType="digit"
                width={170}
              />

              <VSTableColumn
                dataIndex={['quantity']}
                title="是否被锁定"
                editable={false}
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['time']}
                title="锁定时间"
                editable={false}
                valueType="date"
                width={100}
              />
            </VSPromiseTable>
          </Block>
        </div>
      </div>
    </div>
  );
};

export default Index;
