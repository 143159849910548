import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const parameter = defineStore({
  // 当前业务工作站对应的参数列表 (源数据)
  originParameterList: [],

  // 当前业务工作站对应的参数对象 (当前值)
  parameterValue: {},

  getParameterWithApplication: async (id) => {
    const res =
      await vsf?.services?.ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2?.(
        {
          applicationId: id,
        },
      );
    parameter.setOriginParameterList(res?.data);
    parameter.setParameterValue(res?.data);
  },

  setOriginParameterList: (data) => {
    parameter.originParameterList = data;
  },

  setParameterValue: (data) => {
    const valueObj = {};
    data?.map((item) => {
      valueObj[item?.localConfig?.name] = item?.localValue;
    });
    parameter.parameterValue = valueObj;
  },
});

export default parameter;
