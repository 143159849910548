/**
 * 转换后端的字段 Dto 成 Bto
 * 一般用于设置默认值 /(ㄒoㄒ)/~~
 * @param dict 转换对象
 * @returns 返回转换后的对象
 */

export default function askDtoToBto(dict) {
  const bto = {};
  Object.keys(dict).forEach((item) => {
    bto[item.replace('Dto', 'Bto')] = dict[item];
  });
  return bto;
}
