import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugFirmDictionaryBOController_createOrUpdateDrugFirmList_4a4641: {
    method: 'post',
    url: '/api/drug-drug/create-or-update-drug-firm-list',
    parameterFomatter: (data?: {
      createBtoList: CreateDrugFirmDictionaryBtoDrugDrugServiceBto[];
      updateBtoList: UpdateDrugFirmDictionaryBtoDrugDrugServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugFirmDictionaryDtoController_queryDrugFirmDictionary_25e16e: {
    method: 'post',
    url: '/api/drug-drug/query-drug-firm-dictionary',
    parameterFomatter: (data?: {
      qto: DrugFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugFirmDictionaryBOController_deleteDrugFirmDictionary_07a09d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-firm-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugFirmDictionaryBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
