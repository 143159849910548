import {
  Space,
  VSForm,
  VSFormItem,
  Button,
  List,
  Tooltip,
  VSFormLayout,
  Divider,
  Select,
  Image,
  message,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import { omitBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelColWidthRem,
  removeObjectFalsy,
} from '@/utils';
import readCard from '@/assets/icon/readCard.png';
import storage from '@/utils/storage';

import { list, toDispenseWindowList, windowDetail } from './Service';
import TableWaitDispenseList from './TableWaitDispenseList';

import './index.less';
import { CertificateSearch } from '@/components';

function index(props) {
  const [tabaleParam, setTableParam] = useState();
  const { staffInfo } = props?.stores?.user;
  const { data, loading, run } = useRequest(
    async (params) => {
      const res = await list(params);
      if (res?.length >= 1) setTableParam(res[0]); //
      return res;
    },
    {
      manual: true,
    },
  );
  const { data: pageDetail } = useRequest(windowDetail);

  //获取queryDayIs天数,不要删除这个接口
  const { data: sysData } = useRequest(async () => {
    const res =
      await vsf?.services?.SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8(
        {
          qto: {},
        },
      );
    const file = res?.data?.find((item) => {
      return item?.name === 'QUERY_WAIT_DISPENSE_DAY';
    });
    vsf?.refs?.drugSelectForm?.setFieldsValue({
      queryDayIs: file?.systemValue,
    });
    return res?.data;
  });

  //主索引查询接口
  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (res?.data?.result.length >= 1) return res?.data?.result[0];
      // message.error('未查到患者信息');
      return false;
    },
    { manual: true },
  );
  useEffect(() => {
    const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
    run(file);
  }, [pageDetail, sysData]);
  const [selectFlag, setSelectFlag] = useState();
  const [queryAllWindow, setQueryAllWindow] = useState(false);
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });
  const getIdentification = ({
    identificationClassIn,
    identificationNumberIn,
  }) => {
    if (!identificationClassIn || !identificationNumberIn) return false;
    switch (identificationClassIn) {
      case '患者ID':
        return { displayIdIs: identificationNumberIn };
      case '身份证':
        return { idNumberIs: identificationNumberIn };
      case '智慧医保':
        return { insuranceNumberIs: identificationNumberIn };
      default:
        return {
          identificationClassIn: [identificationClassIn],
          identificationNumberIn: [identificationNumberIn],
        };
    }
  };
  const queryList = async () => {
    setSelectFlag();
    setTableParam();
    const { identification, ...reset } =
        vsf?.refs?.drugSelectForm?.getFieldsValue(),
      identificationDetals = getIdentification(identification ?? {});
    let patient = false;
    if (identificationDetals)
      patient = await patientDetallRun(identificationDetals);
    run(
      omitBy(
        removeObjectFalsy({ ...reset, patientIdIs: patient?.id }),
        (value) => value === '',
      ),
    );
  };
  return (
    <div className="dispenses-medicine-layout">
      <div className="left">
        <div style={{ width: '100%' }}>
          <Space size={4} style={{ width: '100%' }}>
            <VSForm id="drugSelectForm">
              <VSFormLayout
                columnCount={1}
                labelCol={labelColWidthRem('6.6rem')}
              >
                <VSFormItem
                  name={'identification'}
                  valueType="custom"
                  label="证件及编号"
                  fieldProps={{}}
                >
                  <CertificateSearch onEnter={queryList} />
                </VSFormItem>
              </VSFormLayout>
              <VSFormLayout
                columnCount={1}
                labelCol={labelColWidthRem('6.6rem')}
              >
                <VSFormItem
                  name={'nameLike'}
                  valueType="text"
                  label="姓名"
                  fieldProps={{
                    onPressEnter: queryList,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout
                columnCount={1}
                labelCol={labelColWidthRem('6.6rem')}
              >
                <VSFormItem
                  name={'queryDayIs'}
                  valueType="text"
                  label="查询天数"
                  rules={[
                    {
                      message: '请输入大于0的正整数',
                      type: 'string',
                      pattern: /^[1-9]\d*$/,
                    },
                  ]}
                  fieldProps={{
                    onPressEnter: queryList,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout
                columnCount={3}
                labelCol={labelColWidthRem('6.6rem')}
              >
                <VSFormItem
                  name={'queryAllWindowIs'}
                  valueType="check"
                  label="全部窗口"
                  style={{ width: '20rem' }}
                  fieldProps={{
                    defaultValue: false,
                    value: queryAllWindow,
                    onChange: (v) => {
                      setQueryAllWindow(v);
                      queryList();
                    },
                  }}
                />
                <VSFormItem
                  name={'drugName'}
                  valueType="check"
                  label="显示未报到"
                  fieldProps={{
                    onChange: (v) => queryList(),
                  }}
                  style={{ width: '20rem' }}
                />
                <div style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={queryList}>
                    查询
                  </Button>
                </div>
              </VSFormLayout>
            </VSForm>
          </Space>
          <Divider className="aspirin-divider-style" />
        </div>

        <VSTable
          className="common_table"
          value={data}
          pagination={false}
          scroll={{
            x: '34rem',
            y: getScrollYRem(28.6),
          }}
          id="PatientDetailsTable"
          rowClassName={(node) => {
            return node?.id === tabaleParam?.id
              ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
              : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
          }}
          onRow={(node) => {
            return {
              onClick: () => {
                if (node?.id === tabaleParam?.id) return setTableParam();
                setTableParam(node);
              },
            };
          }}
        >
          <VSTableColumn
            width="6.8rem"
            dataIndex={['createdAt']}
            valueType="text"
            title="日期"
          />
          <VSTableColumn
            width="3.46rem"
            dataIndex={['name']}
            valueType="text"
            title="姓名"
          />

          <VSTableColumn
            width="4.8rem"
            dataIndex={['patientId']}
            valueType="text"
            title="患者ID"
          />

          <VSTableColumn
            width="6.8rem"
            dataIndex={['windowDescription']}
            valueType="select"
            title="窗口号"
            dataSource={vsf.stores.dbenums.enums.CHINESE_MEDICINE_DICT}
          />
        </VSTable>
      </div>
      <div className="right">
        <TableWaitDispenseList
          tabaleParam={tabaleParam}
          callback={() => {
            run({});
          }}
          queryAllWindow={queryAllWindow}
          pageDetailWindow={pageDetail}
          queryList={queryList}
          staffInfo={staffInfo}
        />
      </div>
    </div>
  );
}

export default index;
