import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicOutpRegisterAppointController_getPatientInfo_2bdd24: {
    method: 'post',
    url: '/api/clinic-outp-appoint-register/get-patient-info',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegisterAppointPatientInfoEoClinicOutpRegisterAppointPersistEo,
    ) => data,
  },
  ClinicOutpRegisterAppointController_appointClinicRegister_5258b4: {
    method: 'post',
    url: '/api/clinic-outp-appoint-register/appoint-clinic-register',
    parameterFomatter: (data?: {
      registerAppointPatientInfoEo: RegisterAppointPatientInfoEoClinicOutpRegisterAppointPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: RegisterAppointPatientInfoEoClinicOutpRegisterAppointPersistEo,
    ) => data,
  },
});
