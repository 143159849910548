import vsf from '@vs/vsf-boot';
/**
 * 获取已提交调价单
 * @param params
 */
export const getAllByDrugPriceAdjustMasterQto = async (params) => {
  const res =
    await vsf?.services?.DrugPriceAdjustController_getAllByDrugPriceAdjustMasterQto_3a7109?.(
      {
        qto: {
          ...params?.search,
          ...params?.extra,
          ...(params ?? {}),
        },
      },
    );
  return {
    data: res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
export const getAllByDrugPriceAdjustMasterQtoDetails = async (params) => {
  const res: any = await getAllByDrugPriceAdjustMasterQto(params);
  return {
    ...res,
    data: res?.data
      ?.map((item: any) => item?.drugPriceAdjustDetailDtoList)
      .flat()
      ?.map((item) => {
        return { ...item, auditOpinion: JSON.parse(item?.auditOpinion) };
      }),
  };
};
/**
 * 更新单据
 * @param params
 * @returns
 */
export const updateDrugPriceAdjustMaster = async (params) => {
  const res =
    await vsf?.services?.DrugPriceAdjustController_updateDrugPriceAdjustMaster_1a082d?.(
      { btoParam: params, extApiParam: {} },
    );
  return res?.data;
};
