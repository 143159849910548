import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  convertMedicalTechnologySaveChargeTemplateRo2SaveMedicalTechnologyChargeTemplateReq,
  deleteMedicalTechnologyChargeTemplateById,
  getMedicalTechnologyChargeTemplateListByDepartmentId,
  getMedicalTechnologyChargeTemplateListByStaffId,
  getMedicalTechnologyChargeTemplateWithDetailList,
  saveMedicalTechnologyChargeTemplate,
} from './services';
import {
  Modal,
  Tooltip,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { getScrollY, getScrollYRem, removeObjectFalsy } from '@/utils';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import { color } from 'echarts';
import { useRequest } from 'ahooks';
import { cloneDeep, omitBy, throttle } from 'lodash';
import Tip from '@/pages/Index/components/func/Tip';
import { onTableFieldEllipsis } from '@/utils/setDataUtil';
import InquireHospitalSelect from '../InquireHospitalSelect';
import { useScroll } from '@/pages/Index/components/func/hooks';
import { askRomanAlphabet } from '@/components/CodeTableSelect/askRomanAlphabet';

import '@/style/adaptation.less';

const TableDetails = (props) => {
  const { department, MenuCurrent } = props,
    onChanegMenuTableReal = async () => {
      switch (MenuCurrent) {
        // 科室模板'mail'
        case 'mail':
          return await vsf?.refs?.medicalTechnologyDepartmentChargeTemplateList;
        case 'app':
          // 个人模板‘app’
          return await vsf?.refs?.staffChargeTemplateList;
        default:
          return 'default';
      }
    },
    [modalProps, setModalProps] = useState({
      open: false,
    });
  return (
    <div className="Table">
      <div className="button" data-title={department?.name ?? ''}>
        <Button {...{ department, MenuCurrent, setModalProps }} />
      </div>
      <div className="container">
        <Table
          {...{ department, MenuCurrent, setModalProps, onChanegMenuTableReal }}
        />
      </div>
      <ModalContainer
        {...{
          department,
          modalProps,
          setModalProps,
          MenuCurrent,
          onChanegMenuTableReal,
        }}
      />
    </div>
  );
};
// 医技收费模板
const ModalContainer = (props) => {
  // 常量
  const {
      modalProps,
      setModalProps,
      department,
      MenuCurrent,
      onChanegMenuTableReal,
    } = props,
    ModalType = {
      add: '新增',
      edit: '编辑',
    },
    // 已添加默认选中
    initialSaveData = useRef([]),
    table__id = 'medicalTechnologySaveChargeTemplate',
    [selectTableData, setSelectTableData] = useState([]);
  const [itemNameInp, setItemNameInp] = useState();
  const [pageNo, setPageNo] = useState(1);
  const { id } = useScroll(
    () => {
      if (pageNo * 20 < originData.current?.length) {
        setPageNo(pageNo + 1);
      }
    },
    { lowerThreshold: 100 },
  );

  const originData = useRef();

  const getLowerCaseStr = (str) => {
    try {
      return str.toLocaleLowerCase();
    } catch (error) {
      return str;
    }
  };

  // 常量函数
  const onClos = () => {
      setSelectTableData([]);
      setModalProps({
        open: false,
      });
      originData.current = null;
      setPageNo(1);
      setItemNameInp(undefined);
    },
    onFetch = useCallback(
      async (params) => {
        const inquireParams =
          vsf?.refs?.medicalTechnologySaveChargeTemplateForm?.getFieldsValue();
        const isEdit = modalProps?.type === 'edit';
        if (isEdit) {
          vsf?.refs?.medicalTechnologySaveChargeTemplateForm?.setFieldValue(
            'templateName',
            modalProps?.node?.templateName,
          );
          params.templateId = modalProps?.node?.id;
        }
        const selecteds = vsf.refs.medicalTechnologySaveChargeTemplate
          .getSelectedRowValues()
          ?.map((item) => item?.id);
        if (params?.extra?.itemNameInp?.length) {
          const [pinyin] = askRomanAlphabet(params?.extra?.itemNameInp, 'all');
          return {
            data:
              cloneDeep(originData.current)?.filter((record) => {
                return (
                  getLowerCaseStr(
                    askRomanAlphabet(record?.itemName, 'pinyin'),
                  )?.includes(getLowerCaseStr(pinyin)) ||
                  selecteds?.includes(record?.id)
                );
              }) ?? [],
          };
        }
        if (originData.current?.length) {
          return {
            data: cloneDeep(originData.current)?.splice(
              0,
              params?.extra?.pageNo * 20,
            ),
          };
        } else {
          const res = await getMedicalTechnologyChargeTemplateWithDetailList(
            omitBy({ ...params, ...inquireParams }, (value) =>
              [undefined, null, NaN, ''].includes(value),
            ),
          );
          initialSaveData.current = res?.data?.filter((ele) => ele?.index);
          setSelectTableData(initialSaveData.current);
          originData.current = res?.data;
          return {
            ...res,
            data: cloneDeep(res?.data)?.splice(0, 20),
            // data: res?.data,
          };
        }
      },
      [modalProps],
    ),
    // 保存
    onChangeModalOk = async () => {
      await vsf?.refs?.medicalTechnologySaveChargeTemplateForm?.validateFieldsReturnFormatValue();
      await vsf?.refs?.medicalTechnologySaveChargeTemplate
        .getEditForm()
        .validateFieldsReturnFormatValue();
      const SelectedRowValues = vsf?.refs?.[table__id]
        ?.getSelectedRowValues?.()
        ?.filter((ele) => !['', undefined, null].includes(ele?.amount));
      if (SelectedRowValues?.length <= 0)
        return message?.error('请选择项目，填写需要保存的数量。');
      const chargeTemplateBto = {
        templateName:
          vsf?.refs?.medicalTechnologySaveChargeTemplateForm.getFieldValue(
            'templateName',
          ) ?? '', //' 测试模板3',
      };
      // 编辑时
      if (modalProps?.type === 'edit') {
        chargeTemplateBto.id = modalProps?.node?.id;
      }
      chargeTemplateBto[department?.type] = department?.id;
      // 个人模板时
      // if (department?.type == 'staffId') {
      //   chargeTemplateBto.departmentId = department?.staffDepartment?.id;
      // }
      const params = {
        chargeTemplateBto: {
          ...chargeTemplateBto,
          medicalTechnologyChargeTemplateDetailBtoList:
            convertMedicalTechnologySaveChargeTemplateRo2SaveMedicalTechnologyChargeTemplateReq(
              SelectedRowValues,
            ),
        },
      };
      const res = await saveMedicalTechnologyChargeTemplate(params);
      if (res?.code !== 200) return;

      message?.success('保存成功。');
      const TableReal = await onChanegMenuTableReal();
      await TableReal?.reload();
      onClos();
    },
    // 全选/反选
    onSelectedRowsChange = async (data, keys) => {
      // 反选项
      const CancelSelects = selectTableData?.filter(
        (ele) => !keys?.includes(ele?.id) && !ele?.authorityIndicator,
      );
      // ?.map((ele) => ele?.id);
      CancelSelects?.length && onCancelSelects(CancelSelects);
      onChangeSelectedRowsAmount(data);
      setSelectTableData([
        // 过虑已添加
        ...data,
        // ?.filter((ele) => !ele?.authorityIndicator),
        // 已添加默认选中
        // ...initialSaveData?.current,
      ]);
    },
    // 反选数量置空
    onCancelSelects = async (CancelSelects) => {
      if (CancelSelects?.length <= 0) return;
      let i = 0;
      while (CancelSelects?.length > i) {
        await onChangeFieldEdit(CancelSelects?.[i], 'amount', null);
        i++;
      }
    },
    // 选中数量置为1
    onChangeSelectedRowsAmount = async (data) => {
      let i = 0;
      while (data?.length > i) {
        if (
          [null, undefined, ''].includes(data?.[i]?.amount) &&
          !data?.[i]?.authorityIndicator
        )
          await onChangeFieldEdit(data?.[i], 'amount', 1);
        i++;
      }
    },
    onChangeFieldEdit = async (data, Field, value) => {
      await vsf?.refs?.[table__id]
        ?.getEditForm()
        ?.setFieldValue([data?.id, Field], value);
      await vsf?.refs?.[table__id]?.update({
        ...data,
        amount: value,
      });
    };

  const handelItemNameChange = throttle((e) => {
    setItemNameInp(e?.target?.value);
  }, 500);
  useEffect(() => setSelectTableData([]), [MenuCurrent]);

  return (
    <Modal
      title={
        <span style={{ color: 'rgba(50, 118, 232, 1)', fontSize: 18 }}>
          医技收费模板—{department?.name}
          {`( ${ModalType?.[modalProps?.type]} )`}
        </span>
      }
      open={modalProps?.open}
      width={1369}
      onCancel={onClos}
      onOk={onChangeModalOk}
      okText="保存"
      cancelText="取消"
    >
      <div className="ModalContainer" style={{ height: getScrollY(369) }}>
        <div className="Form">
          <div className="container">
            <VSForm id="medicalTechnologySaveChargeTemplateForm">
              <VSFormLayout labelCol={2}>
                <VSFormItem
                  name={['templateName']}
                  label="模板名称"
                  valueType="text"
                  rules={[{ required: true, message: '模板名称不能为空!' }]}
                />
                <VSFormItem
                  name={['itemName']}
                  label="项目名称"
                  valueType="text"
                  fieldProps={{
                    onChange: handelItemNameChange,
                  }}
                >
                  {/* <InquireHospitalSelect
                    {...{
                      dataSource: drugDatas,
                      columns: [
                        {
                          dataIndex: ['itemName'],
                          title: '项目名称',
                        },
                        {
                          dataIndex: ['itemSpecification'],
                          title: '项目规格',
                          width: 169,
                        },
                        {
                          dataIndex: ['price'],
                          title: '价格',
                          width: 106,
                        },
                      ],
                      onChange: async (params) => {
                        if (!Object.keys(params).includes('id')) return;
                        const { id, itemName } = params;
                        await vsf?.refs?.medicalTechnologySaveChargeTemplateForm?.setFieldValue(
                          'itemName',
                          itemName,
                        );
                        await vsf.refs.medicalTechnologySaveChargeTemplate.scrollTo(
                          id,
                        );

                        message?.success('项目定位成功。');
                      },
                    }}
                  /> */}
                </VSFormItem>
              </VSFormLayout>
            </VSForm>
          </div>

          <div className="button"></div>
        </div>
        <div className="Table">
          <VSPromiseTable
            editable={{
              editType: 'single',
              columnProps: {
                hideInTable: true,
              },
              saveOnClickOutside: true,
              onFieldChange: (...args) => {},
            }}
            vsid="71134"
            data-id={id}
            id={table__id}
            onFetch={onFetch}
            rowSelection={{
              selectType: 'multiple',
              onSelectedRowsChange: onSelectedRowsChange,
              selectedRows: selectTableData,
            }}
            scroll={{ y: getScrollY(469) }}
            bordered
            onRow={({ id }) => {
              return {
                // onClick: ,
                onDoubleClick: async () => {
                  await vsf?.refs?.[table__id]?.saveEdit(id);
                  await vsf?.refs?.[table__id]?.startEdit(id);
                },
              };
            }}
            rowClick={{}}
            pagination={false}
            extraParams={{ pageNo, itemNameInp }}
          >
            <VSTableColumn
              dataIndex={['itemName']}
              title="项目名称"
              valueType="text"
              fieldProps={{}}
              preview
              editable={false}
              render={(_, { itemName }) => onTableFieldEllipsis(itemName)}
            />

            <VSTableColumn
              width={156}
              dataIndex={['itemClass']}
              title="费用类别"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
              preview
              editable={false}
              // render={(_, { itemClass }) => onTableFieldEllipsis(itemClass)}
            />

            <VSTableColumn
              width={136}
              dataIndex={['itemSpecification']}
              title="项目规格"
              valueType="text"
              fieldProps={{}}
              preview
              editable={false}
              render={(_, { itemSpecification }) =>
                onTableFieldEllipsis(itemSpecification)
              }
            />

            <VSTableColumn
              dataIndex={['brand']}
              title="品牌"
              valueType="text"
              fieldProps={{}}
              preview
              editable={false}
              render={(_, { brand }) => onTableFieldEllipsis(brand)}
            />

            <VSTableColumn
              width={69}
              dataIndex={['unit']}
              title="单位"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
              preview
              editable={false}
            />

            <VSTableColumn
              width={106}
              dataIndex={['price']}
              title="价格"
              valueType="digit"
              fieldProps={{}}
              render={(_, { price }) => price?.toFixed(2)}
              preview
              editable={false}
            />

            <VSTableColumn
              width={106}
              dataIndex={['amount']}
              title="数量"
              valueType="digit"
              formItemProps={{
                rules: [
                  {
                    message: '数量需要大于0',
                    type: 'number',
                    min: 1,
                    max: undefined,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width={106}
              dataIndex={['authorityIndicator']}
              title="授权状态"
              valueType="text"
              fieldProps={{}}
              render={(_, { authorityIndicator }) => {
                let style = { color: 'rgba(227, 74, 78, 1)' },
                  str = '未添加';
                if (authorityIndicator) {
                  style.color = 'rgba(48, 163, 19, 1)';
                  str = '已添加';
                }
                return <span style={style}>{str}</span>;
              }}
              preview
              editable={false}
            />
          </VSPromiseTable>
        </div>
      </div>
    </Modal>
  );
};
// 按钮
const Button = (props) => {
  const { department, MenuCurrent, setModalProps } = props,
    onChanegMenuBtnDisabled = () => {
      switch (MenuCurrent) {
        case 'mail':
          return !department?.id;
        case 'app':
          return !department?.id;
        default:
          return true;
      }
    },
    button = [
      {
        disabled: onChanegMenuBtnDisabled(),
        text: '新增',
        methodKey: 'altKey',
        ordinaryKey: 'q',
        onClick: async () =>
          setModalProps({
            open: true,
            type: 'add',
          }),
      },
    ];
  return (
    <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
      {button.map((item) => (
        <ButtonAndKeyDown key={item.text} {...item} type="primary">
          {item.text}
        </ButtonAndKeyDown>
      ))}
    </div>
  );
};
// 表格
const Table = (props) => {
  const { department, MenuCurrent, setModalProps, onChanegMenuTableReal } =
    props;
  switch (MenuCurrent) {
    // 科室模板'mail'
    case 'mail':
      return (
        <TableDepartment
          {...{ department, setModalProps, onChanegMenuTableReal }}
        />
      );
    // 个人模板‘app’
    case 'app':
      return (
        <TableStaff {...{ department, setModalProps, onChanegMenuTableReal }} />
      );
  }
};
// 科室模板'mail'
const TableDepartment = (props) => {
  const { department, setModalProps, onChanegMenuTableReal } = props;

  const onFetch = useCallback(async () => {
    if (!department?.id) return [];
    const res = await getMedicalTechnologyChargeTemplateListByDepartmentId({
      departmentId: department?.id,
    });
    return res;
  }, [department]);
  return (
    <VSPromiseTable
      vsid="23199"
      id="medicalTechnologyDepartmentChargeTemplateList"
      onFetch={onFetch}
      bordered
      pagination={false}
      // scroll={{ y: getScrollY(482) }}
      scroll={{ y: getScrollYRem(25) }}
      editable={{
        editType: 'multiple',
        onCanDelete: () => false,
        extraOperations: [
          {
            children: <span>编辑</span>,
            onClick: (_, node) => {
              setModalProps({
                open: true,
                type: 'edit',
                node,
              });
            },
          },
          {
            children: <span>删除</span>,
            onClick: (_, node) => {
              Tip({
                title: <span style={{ fontSize: 18 }}>提示</span>,
                content: `请确认是否删除 ${node?.templateName} 模板?`,
                onOk: async () => {
                  const res = await deleteMedicalTechnologyChargeTemplateById({
                    templateId: node?.id,
                  });
                  if (res?.code !== 200) return;
                  message?.success('删除成功');
                  const TableReal = await onChanegMenuTableReal();
                  await TableReal?.reload();
                },
              });
            },
          },
        ],
      }}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="模板编号"
        valueType="digit"
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { id }) => onTableFieldEllipsis(id)}
      />

      <VSTableColumn
        dataIndex={['templateName']}
        title="模板名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '数据格式错误',
              type: 'string',
              min: 0,
              max: 50,
            },
          ],
        }}
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { templateName }) => onTableFieldEllipsis(templateName)}
      />

      <VSTableColumn
        dataIndex={['departmentName']}
        title="科室名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { departmentName }) => onTableFieldEllipsis(departmentName)}
      />

      {/* <VSTableColumn
        dataIndex={['staffName']}
        title="员工姓名"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
        preview
      /> */}

      <VSTableColumn
        dataIndex={['createStaffName']}
        title="创建人"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['createdAt']}
        title="创建日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        preview
      />
    </VSPromiseTable>
  );
};
// 个人模板‘app’
const TableStaff = (props) => {
  const { department, setModalProps, onChanegMenuTableReal } = props,
    onFetch = useCallback(async () => {
      if (!department?.id) return [];
      const res = getMedicalTechnologyChargeTemplateListByStaffId({
        staffId: department?.id,
      });

      return res;
    }, [department]);
  return (
    <VSPromiseTable
      vsid="88421"
      id="staffChargeTemplateList"
      onFetch={onFetch}
      bordered
      pagination={false}
      scroll={{ y: getScrollY(482) }}
      editable={{
        editType: 'multiple',
        onCanDelete: () => false,
        extraOperations: [
          {
            children: <span>编辑</span>,
            onClick: (_, node) => {
              setModalProps({
                open: true,
                type: 'edit',
                node,
              });
            },
          },
          {
            children: <span>删除</span>,
            onClick: (_, node) => {
              Tip({
                content: `请确认是否删除 ${node?.templateName} 模板?`,
                onOk: async () => {
                  const res = await deleteMedicalTechnologyChargeTemplateById({
                    templateId: node?.id,
                  });
                  if (res?.code !== 200) return;
                  message?.success('删除成功');
                  const TableReal = await onChanegMenuTableReal();
                  await TableReal?.reload();
                },
              });
            },
          },
        ],
      }}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="模板编号"
        valueType="digit"
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { id }) => onTableFieldEllipsis(id)}
      />

      <VSTableColumn
        dataIndex={['templateName']}
        title="模板名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '数据格式错误',
              type: 'string',
              min: 0,
              max: 50,
            },
          ],
        }}
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { templateName }) => onTableFieldEllipsis(templateName)}
      />

      <VSTableColumn
        dataIndex={['departmentName']}
        title="科室名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        fieldProps={{}}
        preview
        editable={false}
        render={(_, { departmentName }) => onTableFieldEllipsis(departmentName)}
      />

      <VSTableColumn
        dataIndex={['staffName']}
        title="员工姓名"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['createStaffName']}
        title="创建人"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['createdAt']}
        title="创建日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        preview
      />
    </VSPromiseTable>
  );
};

export default definePage(TableDetails);
