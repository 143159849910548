import {
  Section,
  Column,
  Columns,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import { TreeMenu } from '@/components';
import { getScrollY } from '@/utils';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import LocalParameterEdit from './edit';

const LocalParameterTreeTable = (props) => {
  /**
   * codeType
   * @type {BaseCodeDtoBaseDictionaryManagerDto}
   */
  const [codeType, setCodeType] = useState();
  /**
   * codeTypeToClassId
   * @param {BaseCodeDtoBaseDictionaryManagerDto} codeType
   * @returns {object}
   */
  const codeTypeToClassId = (codeType) => {
    if (!codeType) {
      return {};
    }
    return { classIdIs: codeType.code };
  };
  const onFetch = useCallback(async (params) => {
    // 获取参数名搜索关键词
    const parameterNameKeyWord = params?.search?.nameLike;
    const res =
      await vsf?.services?.BaseParameterController_queryPagedByParameterConfigQto_8e5359?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            nameLike: parameterNameKeyWord,
            inputCodeLike: parameterNameKeyWord,
            codeLike: parameterNameKeyWord,
            configTypeIs: 'BUSINESS',
            parentConfigIdIsNullOrNot: true, // 过滤掉子参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            gap: '0',
            block: 1,
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <TreeMenu
        loadData={async () => {
          const res =
            await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
              { codeType: '000400' },
            );
          return res;
        }}
        fieldNames={{ title: 'name', key: 'id', children: 'children' }}
        dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
        onSelect={(_value) => {
          setCodeType({ ..._value });
        }}
        search={{ status: true, placeholder: '请输入医疗机构名称' }}
        title="数据分类"
        reload
      />
      <VSPromiseTable
        scroll={{
          y: getScrollY(480),
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 140px 0 70px">
              {dom}
            </SearchContainer>
          );
        }}
        className="table_overflow_435 aspirin-table aspirin-table-no-background-box"
        searchConfig={{
          labelWidth: 90,
          collapsed: false,
          collapseRender: false,
          labelAlign: 'left',
          searchButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          resetButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        form={{
          labelAlign: 'left ',
        }}
        vsid="69235"
        onFetch={onFetch}
        onAdd={async (params) => {
          const res =
            await vsf?.services?.LocalParameterConfigBOController_createLocalParameter_a54ed9?.(
              { btoParam: { ...(params ?? {}) } },
            );
          return res?.data;
        }}
        onUpdate={async (params) => {
          const res =
            await vsf?.services?.LocalParameterConfigBOController_updateLocalParameter_6e3d47?.(
              { btoParam: { ...(params ?? {}) } },
            );
          return res?.data;
        }}
        onRemove={async (params) => {
          const res =
            await vsf?.services?.LocalParameterConfigBOController_deleteLocalParameter_b70c63?.(
              { btoParam: { ...(params ?? {}) } },
            );
          return res?.code === 200;
        }}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
        }}
        addLinkPage={{
          linkPage: () => <LocalParameterEdit />,
          modalProps: {
            title: '新增参数',
            className: 'aspirin-linkpage',
            width: 902,
            bodyStyle: {
              // height: 568,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            className: 'modalHeight640',
          },
          onLinkPageValidate: async () => {
            try {
              const ret =
                await vsf.refs.LocalParameterForm?.validateFieldsReturnFormatValue();
              return ret;
            } catch (e) {
              vsf.showToast(JSON.stringify(e), 'error');
              return false;
            }
          },
        }}
        updateLinkPage={{
          linkPage: () => <LocalParameterEdit />,
          modalProps: {
            title: '编辑参数',
            className: 'aspirin-linkpage',
            width: 902,
            bodyStyle: {
              // height: 568,
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
            className: 'modalHeight640',
          },
          onLinkPageValidate: async () => {
            try {
              const ret =
                await vsf.refs.LocalParameterForm?.validateFieldsReturnFormatValue();
              return ret;
            } catch (e) {
              vsf.showToast(JSON.stringify(e), 'error');
              return false;
            }
          },
        }}
        extraParams={codeTypeToClassId(codeType)}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="参数名"
          valueType="text"
          columnKey={'nameLike'}
          fieldProps={{}}
          search
          formItemProps={{
            label: '参数名称',
          }}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['cnName']}
          title="参数中文名"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['valueType']}
          title="值类型"
          valueType="select"
          dataSource={[
            { label: 'Boolean', value: 'BOOL_TYPE' },
            { label: 'Integer', value: 'INTEGER_TYPE' },
            { label: 'FLoat', value: 'FLOAT_TYPE' },
            { label: 'String', value: 'STRING_TYPE' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['scopeType']}
          title="范围类型"
          valueType="select"
          dataSource={[
            { label: '纯文字描述', value: 'TEXT_TYPE' },
            { label: '是/否', value: 'BOOL_TYPE' },
            { label: 'SQL语句（多项选择）', value: 'SQL_MULTIPLE' },
            { label: 'SQL语句（单项选择）', value: 'SQL_SINGLE' },
          ]}
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['scopeList']}
          title="范围值"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['description']}
          title="应用参数描述"
          valueType="text"
          fieldProps={{}}
          ellipsis={{
            showTitle: true,
          }}
        />
        <VSTableColumn
          dataIndex={['storageLocation']}
          title="存放位置"
          valueType="select"
          search
          dataSource={[
            { label: '服务器', value: 'SERVER' },
            { label: '应用', value: 'LOCAL' },
            { label: '人员', value: 'PERSON' },
          ]}
          ellipsis={{
            showTitle: true,
          }}
          // columnKey={'nameLike'}
          fieldProps={{
            showSearch: true,
          }}
        />

        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          title="参数列表"
        >
          <VSTableAddition size="large" className="icon16" children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </Container>
  );
};
export default definePage(LocalParameterTreeTable);
