import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitController_getOutpVisitOverviewByVisitNumber_69d34e: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-outp-visit-overview',
    parameterFomatter: (data?: { visitNumber: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitOverviewVoClinicOutpVisitEntranceWebVo,
    ) => data,
  },
  OutpVisitController_getOutpVisitOverviewById_d464cf: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-outp-visit-overview-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitOverviewVoClinicOutpVisitEntranceWebVo,
    ) => data,
  },
});
