import {
  Space,
  VSForm,
  VSFormItem,
  Button,
  List,
  Tooltip,
  VSFormLayout,
  Divider,
  message,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

import { filterEmptyValues } from '@/utils/setDataUtil';

import { list, toDispenseWindowList, windowDetail } from './Service';
import TableWaitConfirm from './table';

import './index.less';
import { use } from 'echarts';
import { CertificateSearch } from '@/components';
import { removeObjectFalsy } from '@/utils';
import { omitBy } from 'lodash';

function index() {
  const [selectFlag, setSelectFlag] = useState();
  const [tabaleParam, setTableParam] = useState();
  const { data, loading, run } = useRequest(list, {
    manual: true,
  });
  //主索引查询接口
  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
      }

      return res?.data?.result?.[0];
    },
    { manual: true },
  );

  useEffect(() => {
    const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
    run(file);
  }, []);
  useEffect(() => {
    if (data) {
      setTableParam(data?.[0]);
      setSelectFlag(0);
    }
  }, [data]);
  const getIdentification = ({
    identificationClassIn,
    identificationNumberIn,
  }) => {
    if (!identificationClassIn || !identificationNumberIn) return false;
    switch (identificationClassIn) {
      case '患者ID':
        return { displayIdIs: identificationNumberIn };
      case '身份证':
        return { idNumberIs: identificationNumberIn };
      case '智慧医保':
        return { insuranceNumberIs: identificationNumberIn };
      default:
        return {
          identificationClassIn: [identificationClassIn],
          identificationNumberIn: [identificationNumberIn],
        };
    }
  };
  return (
    <div className="confim-medicine-layout">
      <div className="left">
        <div>
          <VSForm id="drugSelectForm">
            <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
              <VSFormItem
                name={'patientIdIs'}
                valueType="custom"
                label="患者id"
                fieldProps={
                  {
                    // onPressEnter: (v) => {
                    //   const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
                    //   if (file?.patientIdIs) {
                    //     patientDetallRun({
                    //       displayIdIs: file?.patientIdIs,
                    //     }).then((item) => {
                    //       file.patientIdIn = [item[0]?.id];
                    //       run(filterEmptyValues(file));
                    //     });
                    //   }
                    // },
                    // onChange: (c) => {
                    //   const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
                    //   !c.target.value && run(file);
                    // },
                  }
                }
              >
                <CertificateSearch
                  onEnter={async (identification) => {
                    // console.log(getIdentification(identification))
                    const identificationDetals = getIdentification(
                      identification ?? {},
                    );
                    let patient = false;
                    if (identificationDetals)
                      patient = await patientDetallRun(identificationDetals);
                    if (!patient?.id) return;
                    run(
                      omitBy(
                        removeObjectFalsy({
                          patientIdIn: patient?.id ? [patient?.id] : '',
                        }),
                        (value) => value === '',
                      ),
                    );
                  }}
                />
              </VSFormItem>
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
              <VSFormItem
                name={'nameLike'}
                valueType="text"
                label="姓名"
                fieldProps={{
                  onPressEnter: (v) => {
                    const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
                    run(filterEmptyValues(file));
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <Divider className="aspirin-divider-style" />
        <List>
          <List.Item style={{ backgroundColor: '#e5f2ff' }}>
            <div className="belt-list-lable-classname">日期</div>
            <div className="belt-list-lable-classname">姓名</div>
            <div className="belt-list-lable-classname">申请医师</div>
            <div className="belt-list-lable-classname">申请单号</div>
          </List.Item>
        </List>
        <div style={{ overflow: 'auto', height: '100%' }}>
          <List>
            <div style={{ overflow: 'auto' }}>
              {data &&
                data.length > 0 &&
                data?.map(
                  (item, index) =>
                    item && (
                      <List.Item
                        onClick={() => {
                          setSelectFlag(index);
                          setTableParam(item);

                          if (selectFlag === index) {
                            setSelectFlag(undefined);
                            setTableParam(undefined);
                          }
                        }}
                        style={{ paddingLeft: '5px' }}
                        className={
                          index === selectFlag
                            ? 'select belt-list-lable-row-classname'
                            : 'un-select belt-list-lable-row-classname'
                        }
                      >
                        <div className="list-cell belt-list-lable-row-classname">
                          <Tooltip title={item?.returnApplyDate}>
                            {item?.returnApplyDate}
                          </Tooltip>
                        </div>
                        <div className="list-cell belt-list-lable-row-classname">
                          <Tooltip title={item?.name}>{item?.name}</Tooltip>
                        </div>
                        <div className="list-cell belt-list-lable-row-classname">
                          <Tooltip title={item?.staffName}>
                            {item?.staffName}
                          </Tooltip>
                        </div>
                        <div className="list-cell belt-list-lable-row-classname">
                          <Tooltip title={item?.refundDrugApplyNumber}>
                            {item?.refundDrugApplyNumber}
                          </Tooltip>
                        </div>
                      </List.Item>
                    ),
                )}
            </div>
          </List>
        </div>
      </div>
      <div className="right">
        <TableWaitConfirm
          tabaleParam={tabaleParam}
          onChangeRun={() => {
            setTableParam(undefined);
            const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
            run(filterEmptyValues(file));
          }}
          callback={() => {
            setSelectFlag(undefined);
            setTableParam(undefined);
          }}
        />
      </div>
    </div>
  );
}

export default index;
