import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_stopClinicItem_49a98d: {
    method: 'post',
    url: '/api/clinic-item-base/stop-clinic-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getAllByClinicItemExamQto_ec23a4: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-exam-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemExamQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_updateClinicExamItem_dec283: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-exam-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateExamItemBto: UpdateExamItemBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
