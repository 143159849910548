import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugDrugController_queryHerbList_50f5a9: {
    method: 'post',
    url: '/api/drug-drug/query-herb-list',
    parameterFomatter: (data?: {
      qto: HerbListQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-order-drug-and-valid',
    parameterFomatter: (data?: {
      drugItemId: string;
      patientId: number;
      departmentId: string;
      staffId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo,
    ) => data,
  },
  ClinicRecordOrderDrugController_getHerbStorages_5deb50: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-herb-storages',
    parameterFomatter: (data?: { isOutp: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageDepartmentVoClinicRecordOrderDrugEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderHerbController_getByOrderId_b76e41: {
    method: 'post',
    url: '/api/clinic-record-order-herb/get-by-order-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderHerbAndDetailVoClinicRecordOrderHerbEntranceWebVo,
    ) => data,
  },
  ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
