import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitRecordController_queryNewbornRecordInfoVoByNewbornRecordInfoQto_cc74f1:
    {
      method: 'post',
      url: '/api/clinic-inp-visit-record/query-newborn-record-info-vo-by-newborn-record-info-qto',
      parameterFomatter: (data?: {
        qto: NewbornRecordInfoQtoClinicInpVisitRecordPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: NewbornRecordInfoVoClinicInpVisitRecordEntranceWebVo[],
      ) => data,
    },
  ClinicInpVisitRecordController_newbornRecordFuncFlowExecutor_216df0: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/newborn-record-func-flow-executor',
    parameterFomatter: (data?: {
      saveNewbornRecordBto: SaveNewbornRecordBtoClinicInpVisitRecordServiceBto;
      createPatientBto: CreatePatientBtoPatientMainServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
