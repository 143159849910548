import { createServices } from '@vs/vsf-boot';
export default createServices({
  ChiefComplaintDictionaryController_getChiefComplaintVsJudge_95ede4: {
    method: 'post',
    url: '/api/base-common/get-chief-complaint-vs-judge',
    parameterFomatter: (data?: { chiefId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintVsJudgeWithJudgeDiseaseVoBaseCommonEntranceWebVo[],
    ) => data,
  },
  ChiefComplaintDictionaryController_deleteChiefComplaintVsJudge_b943f6: {
    method: 'post',
    url: '/api/base-common/delete-chief-complaint-vs-judge',
    parameterFomatter: (data?: {
      bto: DeleteChiefComplaintVsJudgeBtoBaseCommonServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintVsJudgeWithJudgeDiseaseVoBaseCommonEntranceWebVo,
    ) => data,
  },
});
