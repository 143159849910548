import { Transfer } from '@vs/vsf-kit';
import React, {
  Fragment,
  cloneElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useInfiniteScroll } from 'ahooks';
import { getScrollY, getScrollYRem } from '@/utils';
import '@/style/adaptation.less';

/***
 * 粗糙的穿梭框
 * 不受控
 */
const TransferExpand = forwardRef((props, ref) => {
  const {
    left,
    right,
    labelKey,
    dataSource: initDataSource,
    data: rightData,
    footer,
    onSubmit,
    search,
    ...rest
  } = props;
  /**
   * 左边
   */
  const [dataSource, setDataSource] = useState([]);
  /**
   * 右边
   */
  const [data, setData] = useState([]);
  /**
   * 过滤右边已选项
   */
  useEffect(() => {
    const id = rightData?.map((item) => item?.[labelKey]);
    setDataSource(
      initDataSource.filter((item) => {
        return !id.includes(item?.[labelKey]);
      }),
    );
    setData(rightData);
  }, [initDataSource, rightData]);

  const onChange = (value) => {
    setData(value);
    right?.props?.onChange?.(value);
  };

  /**
   * 部分穿梭框设置
   */
  const TransferProps = {
    ...rest,
    showSelectAll: false,
    dataSource,
    targetKeys: data?.map((item) => item[labelKey]),
    className: 'aspirin-transfer',
    onChange: (left, direction, right) => {
      const options = direction === 'right' ? dataSource : data;
      const leftList = options.filter(
        (item) => !right.includes(item[labelKey]),
      );
      const rightList = options.filter((item) =>
        right.includes(item[labelKey]),
      );
      if (direction === 'right') {
        setDataSource(leftList);
        // setData([...data, ...rightList]);
        // right?.props?.onChange?.([...data, ...rightList]);
        onChange?.([...data, ...rightList]);
      } else {
        setDataSource([...dataSource, ...rightList]);
        onChange?.(leftList);
        // setData(leftList);
        // right?.props?.onChange?.(leftList);
      }
    },
  };
  const filterDataSource = (func) => {
    if (typeof func === 'function') {
      const array = initDataSource.filter(
        (item) =>
          !data?.map((item) => item?.[labelKey]).includes(item?.[labelKey]),
      );
      setDataSource(func(array));
    }
  };
  const render = (props) => {
    const {
      direction,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
    } = props;
    const rowSelection = {
      selectType: 'multiple',
      selectedRows: (direction === 'left' ? dataSource : data)?.filter(
        (item) => {
          return listSelectedKeys?.includes(item[labelKey]);
        },
      ),
      onSelectAll(...[, key, selected]) {
        if (!selected) {
          listSelectedKeys.length = 0;
        }
        onItemSelectAll(key, selected);
      },
      onSelect(...[, key, selected]) {
        onItemSelect(key, selected);
      },
    };
    if (direction === 'left') {
      return (
        <Fragment>
          {search?.left?.render &&
            cloneElement(search.left.render, { filterDataSource })}
          {cloneElement(left, {
            rowSelection,
            value: dataSource,
            className: 'table_overflow_420',
          })}
        </Fragment>
      );
    } else {
      return cloneElement(right, {
        rowSelection,
        value: data,
        onChange,
        rowClick: {
          rowClickType: 'select',
        },

        scroll: {
          // y: getScrollY(400),
          y: getScrollYRem(40),
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return data;
    },
  }));
  return (
    <div className="aspirin-transfer-box">
      <Transfer
        // className="table_overflow_358"
        {...TransferProps}
      >
        {render}
      </Transfer>
      <div className="aspirin-transfer-button-box">{footer?.(data)}</div>
    </div>
  );
});

export default TransferExpand;
