import './index.less';
import './convert';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  Input,
  message,
  Section,
  // Select,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import CodeTableSelect from '@/components/CodeTableSelect';
import Icon from '@/module/cpoe/Icon';
import FormTitle from '@/pages/Index/components/form_title';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import { positiveInteger, positiveIntegerMessage } from '@/pattern';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY, labelNumberWidth } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import { add } from '@/utils/setDataUtil';
import storage from '@/utils/storage';

import { convertGetChargeSolutionDetailByIdRes2EditChargeSolutionControlledForm } from './convert';

const Index = (props) => {
  const { value } = props;

  const [judgeWay, setJudgeWay] = useState();
  const [searchValue, setSearchValue] = useState<any>();

  // console.log(props, 'propsprops');
  //   编辑
  const getDetail = useCallback(async () => {
    const res =
      await vsf?.services?.ChargeSolutionDtoController_getChargeSolutionDetailById_41f387?.(
        { chargeSolutionId: value?.id },
      );
    let output =
      convertGetChargeSolutionDetailByIdRes2EditChargeSolutionControlledForm?.(
        res,
      );
    if (output?.judgeWay == 'PATIENT_BASE_INFO') {
      output = {
        ...output,
        chargeSolutionJudgeConditionBtoList: output?.judgeConditionList,
      };
    } else if (output?.judgeWay == 'CHARGE_TYPE') {
      const _data = output?.judgeConditionList?.reduce((acc, item, index) => {
        // 查找是否已存在具有相同id的项
        const existingGroup = acc.find(
          (group) => group.limitChargeType == item.limitChargeType,
        );
        // 如果不存在，则创建一个新的组
        if (!existingGroup) {
          acc.push({
            limitChargeType: item?.limitChargeType,
            limitIdentityType: [item?.limitIdentityType],
            limitIdentityList: handelIdentity(item?.limitChargeType),
          });
        } else {
          // 如果存在，则将当前项添加到该组的arr数组中
          existingGroup.limitIdentityType.push(item?.limitIdentityType);
        }
        // 返回累积的数组
        return acc;
      }, []);
      output = { ...output, judgeConditionList: _data };
    }
    console.log(output, 'output1');

    vsf.refs.createChargeSolutionForm.setFieldsValue({ ...output });
    setJudgeWay(output?.judgeWay);
    return output;
  }, [value?.id]);
  // 设置人员类别
  const handelIdentity = (code) => {
    const res = vsf.stores.dbenums.enums.IDENTITY_DICT?.filter((item) => {
      return (
        item?.extensionJson &&
        JSON.parse(item?.extensionJson)?.chargeTypeCode == code
      );
    });

    if (res && res?.length > 0) {
      return res;
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (value?.id) {
      getDetail();
    }
  }, [value?.id, getDetail]);

  return (
    <div>
      <VSForm id="createChargeSolutionForm" labelAlign="left">
        <FormTitle style={{ padding: 0 }} title="收费方案信息" isNeedTag={true}>
          <VSFormItem
            name={['id']}
            label="id"
            valueType="text"
            hidden
            fieldProps={{}}
          />
          <VSFormItem
            name={['chargeSolutionCode']}
            label="收费方案代码"
            valueType="text"
            hidden
            fieldProps={{}}
          />
          <VSFormLayout
            key="0"
            columnCount={3}
            // labelCol={labelColWidth[13]}
            labelWidth={labelNumberWidth(10)}
          >
            <VSFormItem
              name={['chargeSolutionName']}
              label="方案名称"
              valueType="text"
              rules={[{ required: true }]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['startDate']}
              label="启用日期"
              valueType="date"
              rules={[{ required: true }]}
              fieldProps={{
                className: 'widthAutoFill',
                disabledDate: (current) => {
                  return current && current < dayjs().endOf('day');
                },
                onChange: (v) => {
                  if (
                    v &&
                    v >
                      vsf.refs?.createChargeSolutionForm?.getFieldsValue()
                        ?.stopDate
                  ) {
                    vsf.refs?.createChargeSolutionForm?.setFieldsValue({
                      stopDate: null,
                    });
                  }
                },
              }}
              initialValue={+new Date()}
            />
            <VSFormItem
              name={['stopDate']}
              label="停用日期"
              valueType="date"
              fieldProps={{
                className: 'widthAutoFill',
                disabledDate: (current) => {
                  return (
                    current &&
                    current <
                      vsf.refs?.createChargeSolutionForm?.getFieldsValue()
                        ?.startDate
                  );
                },
              }}
            />
            <VSFormItem
              name={['priority']}
              label="优先级"
              valueType="digit"
              rules={[
                { required: true },
                { pattern: positiveInteger, message: positiveIntegerMessage },
              ]}
              fieldProps={{
                className: 'widthAutoFill',
              }}
            />

            <VSFormItem
              name={['reduceSolutionIndicator']}
              label="是否是减免收费方案"
              valueType="radio"
              rules={[{ required: true }]}
              fieldProps={{}}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
            />
            <VSFormItem
              name={['stackableIndicator']}
              label="是否可叠加"
              valueType="radio"
              rules={[{ required: true }]}
              fieldProps={{}}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
            />
          </VSFormLayout>
          <VSFormLayout
            key="1"
            columnCount={1}
            // labelCol={labelColWidth[13]}
            labelWidth={labelNumberWidth(10.5)}
          >
            <VSFormItem
              name={['solutionMemo']}
              label="方案说明"
              valueType="text"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 200 },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle style={{ padding: 0 }} title="判定方式" isNeedTag={true}>
          <VSFormLayout
            key="2"
            columnCount={3}
            // labelCol={labelColWidth[13]}
            labelWidth={labelNumberWidth(10.5)}
          >
            <VSFormItem
              name={['judgeWay']}
              label="判定方式"
              valueType="codeTableSelect"
              dataSource={[
                { label: '患者基本信息', value: 'PATIENT_BASE_INFO' },
                { label: '名单', value: 'PATIENT_LIST' },
                { label: '费别', value: 'CHARGE_TYPE' },
              ]}
              rules={[{ required: true }]}
              fieldProps={{
                onChange: (v) => {
                  setJudgeWay(v);
                  console.log(
                    vsf.refs.createChargeSolutionForm.getFieldsValue(),
                    'pppo',
                  );
                },
              }}
            />
          </VSFormLayout>
          <VSFormLayout
            key="3"
            columnCount={1}
            // labelCol={labelColWidth[13]}
            labelWidth={labelNumberWidth(10.5)}
          >
            {/* 基本信息 */}
            {judgeWay == 'PATIENT_BASE_INFO' ? (
              <VSFormItem
                name={['chargeSolutionJudgeConditionBtoList']}
                label="判定条件"
                valueType="custom"
                required
                rules={[
                  {
                    validator(rule, value, callback) {
                      if (value?.length <= 0 || !value)
                        callback('请选择判定条件');
                      value?.forEach((i) => {
                        if (!i) {
                          callback('请选择判定条件');
                        } else {
                          if (!i?.judgeConditionType) {
                            callback('请选择判定条件');
                          } else {
                            if (i?.judgeConditionType == 'AGE') {
                              if (!i?.limitAgeSymbol) {
                                callback('请选择限定符号');
                              } else if (!i?.limitAgeNumber) {
                                callback('请输入限定年龄');
                              } else if (!i?.limitAgeUnit) {
                                callback('请选择限定年龄单位');
                              } else {
                                callback();
                              }
                            } else if (i?.judgeConditionType == 'GENDER') {
                              if (!i?.limitGender) {
                                callback('请选择性别');
                              } else {
                                callback();
                              }
                            }
                          }
                        }
                      });
                    },
                  },
                ]}
                customComponent={(record, v) => {
                  const _value =
                    record?.value && record?.value?.length > 0
                      ? record?.value
                      : [{ id: Math.random() }];
                  return (
                    <div>
                      {_value?.map((i, index) => {
                        return (
                          <Space.Compact
                            key={index}
                            className="widthAutoFill marginBottom16"
                          >
                            <CodeTableSelect
                              value={i?.judgeConditionType}
                              allowClear
                              dataSource={[
                                { label: '年龄', value: 'AGE' },
                                { label: '性别', value: 'GENDER' },
                              ]}
                              showSearch
                              className="width220"
                              onChange={(v) => {
                                const _findValue = _value?.find(
                                  (valueItem) =>
                                    valueItem?.judgeConditionType == v,
                                );
                                console.log(
                                  _findValue,
                                  _value,
                                  v,
                                  '_value_value1',
                                );

                                if (_findValue || !v) {
                                  const _changeValue = _value?.map((item) => {
                                    return i?.id == item?.id
                                      ? { ...item, judgeConditionType: null }
                                      : item;
                                  });
                                  record?.onChange(_changeValue);
                                  return !v
                                    ? ''
                                    : message.info('请勿重复选择判定条件');
                                }

                                const _changeValue = _value?.map((item) => {
                                  return i?.id == item?.id
                                    ? { ...item, judgeConditionType: v }
                                    : item;
                                });
                                record?.onChange(_changeValue);
                              }}
                            />
                            {i?.judgeConditionType == 'AGE' && (
                              <>
                                <CodeTableSelect
                                  value={i?.limitAgeSymbol}
                                  dataSource={[
                                    { label: '大于', value: 'GT' },
                                    { label: '大于等于', value: 'GTE' },
                                    { label: '等于', value: 'EQUAL' },
                                    { label: '小于', value: 'LT' },
                                    { label: '小于等于', value: 'LTE' },
                                  ]}
                                  showSearch
                                  placeholder="选择限定符号"
                                  className="width100"
                                  allowClear
                                  onChange={(v) => {
                                    const _changeValue = _value?.map((item) => {
                                      return i?.id == item?.id
                                        ? { ...item, limitAgeSymbol: v }
                                        : item;
                                    });
                                    record?.onChange(_changeValue);
                                  }}
                                />
                                <Input
                                  type="digit"
                                  value={i?.limitAgeNumber}
                                  placeholder="输入限定年龄"
                                  className="width100"
                                  allowClear
                                  onChange={(v) => {
                                    const _changeValue = _value?.map((item) => {
                                      return i?.id == item?.id
                                        ? {
                                            ...item,
                                            limitAgeNumber: v?.target?.value,
                                          }
                                        : item;
                                    });
                                    record?.onChange(_changeValue);
                                  }}
                                />
                                <CodeTableSelect
                                  placeholder="选择限定年龄单位"
                                  value={i?.limitAgeUnit}
                                  dataSource={[
                                    { label: '年', value: 'YEAR' },
                                    { label: '月', value: 'MONTH' },
                                    { label: '日', value: 'DAY' },
                                    { label: '周', value: 'WEEK' },
                                  ]}
                                  showSearch
                                  className="width100"
                                  allowClear
                                  onChange={(v) => {
                                    const _changeValue = _value?.map((item) => {
                                      return i?.id == item?.id
                                        ? { ...item, limitAgeUnit: v }
                                        : item;
                                    });
                                    record?.onChange(_changeValue);
                                  }}
                                />
                              </>
                            )}
                            {i?.judgeConditionType == 'GENDER' && (
                              <CodeTableSelect
                                value={i?.limitGender}
                                dataSource={[
                                  { label: '无限制', value: 'NO_LIMIT' },
                                  { label: '限男性', value: 'MALE_ONLY' },
                                  { label: '限女性', value: 'FEMALE_ONLY' },
                                ]}
                                showSearch
                                className="width190"
                                onChange={(v) => {
                                  console.log(v);

                                  const _changeValue = _value?.map((item) => {
                                    return i?.id == item?.id
                                      ? { ...item, limitGender: v }
                                      : item;
                                  });
                                  record?.onChange(_changeValue);
                                }}
                              />
                            )}
                            <Icon
                              type="icon-youdishanchu1"
                              className="icon20 marginLeft16"
                              onClick={(v) => {
                                const _changeValue =
                                  _value?.length == 1
                                    ? [{ id: Math.random() }]
                                    : _value?.filter(
                                        (item) => i?.id != item?.id,
                                      );
                                record?.onChange([..._changeValue]);
                              }}
                            />
                            <Icon
                              type="icon-tianjia"
                              className="icon20 marginLeft16"
                              onClick={(v) => {
                                record?.onChange([
                                  ..._value,
                                  { id: Math.random() },
                                ]);
                              }}
                            />
                          </Space.Compact>
                        );
                      })}
                    </div>
                  );
                }}
              />
            ) : (
              <></>
            )}
            {/* 费别 */}
            {judgeWay == 'CHARGE_TYPE' ? (
              <VSFormItem
                name={['judgeConditionList']}
                label="选择费别"
                valueType="custom"
                required
                customComponent={(record) => {
                  const _value = (
                    record?.value && record?.value?.length > 0
                      ? record?.value
                      : [
                          {
                            id: Math.random(),
                          },
                        ]
                  )?.map((i) => {
                    return {
                      ...i,
                      limitIdentityList: handelIdentity(i?.limitChargeType),
                    };
                  });
                  return (
                    <div>
                      {_value?.map((i, index) => {
                        return (
                          <Space.Compact
                            key={index}
                            className="widthAutoFill marginBottom16"
                          >
                            <CodeTableSelect
                              dataSource={
                                vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT
                              }
                              value={i?.limitChargeType}
                              showSearch
                              placeholder="请选择费别"
                              className="width190"
                              onChange={(v, config) => {
                                const _findValue = _value?.find(
                                  (valueItem) =>
                                    valueItem?.limitChargeType == v,
                                );
                                // if (_findValue)
                                //   return message.info('请勿重复请选择费别');

                                if (_findValue || !v) {
                                  const _changeValue = _value?.map((item) => {
                                    return i?.id == item?.id
                                      ? { ...item, limitChargeType: null }
                                      : item;
                                  });
                                  record?.onChange(_changeValue);
                                  return !v
                                    ? ''
                                    : message.info('请勿重复请选择费别');
                                }

                                const _limitIdentityList = handelIdentity(
                                  config?.value,
                                );

                                const _changeValue = _value?.map((item) => {
                                  return i?.id == item?.id
                                    ? {
                                        ...item,
                                        limitChargeType: v,
                                        limitIdentityType: [],
                                        limitIdentityList: _limitIdentityList,
                                      }
                                    : item;
                                });
                                record?.onChange(_changeValue);
                              }}
                            />
                            {i?.limitChargeType && (
                              <Select
                                mode="multiple"
                                showSearch
                                value={i?.limitIdentityType ?? []}
                                options={i?.limitIdentityList}
                                className="widthAutoFill"
                                placeholder="请选择人员类别"
                                onSearch={(v) => {
                                  // console.log(v, i?.limitIdentityList, 'ppp');
                                  setSearchValue(v);
                                }}
                                onChange={(v) => {
                                  const _changeValue = _value?.map((item) => {
                                    return i?.id == item?.id
                                      ? {
                                          ...item,
                                          limitIdentityType: v,
                                        }
                                      : item;
                                  });
                                  record?.onChange(_changeValue);
                                }}
                                filterOption={askSearch}
                              />
                            )}
                            <Icon
                              type="icon-youdishanchu1"
                              className="icon20 marginLeft16"
                              onClick={(v) => {
                                const _changeValue =
                                  _value?.length == 1
                                    ? [{ id: Math.random() }]
                                    : record?.value?.filter(
                                        (item) => i?.id != item?.id,
                                      );
                                record?.onChange([..._changeValue]);
                              }}
                            />
                            <Icon
                              type="icon-tianjia"
                              className="icon20 marginLeft16"
                              onClick={(v) => {
                                console.log(_value, record);

                                record?.onChange([
                                  ..._value,
                                  { id: Math.random() },
                                ]);
                              }}
                            />
                          </Space.Compact>
                        );
                      })}
                    </div>
                  );
                }}
                rules={[
                  {
                    validator(rule, value, callback) {
                      console.log(rule, value, callback, 'ppp1');
                      if (value?.length <= 0 || !value) callback('请选择费别');
                      value?.forEach((i) => {
                        if (!i) {
                          callback('请选择费别');
                        } else {
                          if (!i?.limitChargeType) {
                            callback('请选择费别');
                          } else {
                            if (i?.limitIdentityType?.length <= 0) {
                              callback('请选择人员类别');
                            } else {
                              callback();
                            }
                          }
                        }
                      });
                    },
                  },
                ]}
              />
            ) : (
              <></>
            )}
          </VSFormLayout>
        </FormTitle>
        <FormTitle style={{ padding: 0 }} title="减免相关" isNeedTag={true}>
          <VSFormLayout
            key="4"
            columnCount={1}
            labelWidth={labelNumberWidth(10.5)}
          >
            <VSFormItem
              name={['applicableScope']}
              label="适用范围"
              valueType="checkbox"
              rules={[{ required: true }]}
              dataSource={[
                { label: '挂号', value: 'REGISTER' },
                { label: '门急诊结算', value: 'OUTP_SETTLE' },
                { label: '留观结算', value: 'ERP_SETTLE' },
                { label: '住院结算', value: 'INP_SETTLE' },
              ]}
              fieldProps={
                {
                  // mode: 'multiple',
                }
              }
            />
            <VSFormItem
              name={['reduceSequence']}
              label="减免顺序"
              valueType="radio"
              dataSource={[
                { label: '医保前', value: 'BEFORE_INSURANCE' },
                { label: '医保后', value: 'AFTER_INSURANCE' },
              ]}
              rules={[{ required: true }]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
        {/* <VSFormFooter>
          <VSFormFooterSubmit children="提交" />

          <VSFormFooterReset children="重置" />
        </VSFormFooter> */}
      </VSForm>
    </div>
  );
};

export default Index;
