import vsf from '@vs/vsf-boot';
import {
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState, useKeyPress } from 'ahooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const mockDoctorId = 1603;

const maxIndex = 9;
const minIndex = 0;

const Index = (props) => {
  const {
    onSelect,
    open,
    showRepertory = true,
    storageId,
    searchValue,
  } = props;
  const tableRef = useRef<any>();
  const [index, setIndex, getIndex] = useGetState<number>(-1);
  // const [selectedRows, setSelectedRows] = useState<any[]>();

  const [dataSource, setDataSource] = useState<any>();

  const handleSelect = useCallback(
    (data) => {
      if (open) {
        onSelect?.(data);
      }
    },
    [open, onSelect],
  );

  const getCurrentData = useCallback(
    (index) => {
      const currentPage = tableRef?.current?.getCurrentPage();
      const v = currentPage ? currentPage - 1 : 0;
      // handleSelect(dataSource?.[v * 10 + index]);
      handleSelect(dataSource?.[index]);
    },
    [dataSource, handleSelect],
  );

  useKeyPress('downarrow', () => {
    if (index < maxIndex && open) {
      setIndex(getIndex() + 1);
    }
  });

  useKeyPress('uparrow', () => {
    if (index > minIndex && open) {
      setIndex(getIndex() - 1);
    }
  });

  useKeyPress('leftarrow', () => {
    if (open) {
      tableRef?.current?.gotoPreviousPage();
      setIndex(0);
    }
  });

  useKeyPress('rightarrow', () => {
    if (open) {
      tableRef?.current?.gotoNextPage();
      setIndex(0);
    }
  });

  useKeyPress(
    '1',
    () => {
      if (open) {
        getCurrentData(0);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '2',
    () => {
      if (open) {
        getCurrentData(1);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '3',
    () => {
      if (open) {
        getCurrentData(2);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '4',
    () => {
      if (open) {
        getCurrentData(3);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '5',
    () => {
      if (open) {
        getCurrentData(4);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '6',
    () => {
      if (open) {
        getCurrentData(5);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '7',
    () => {
      if (open) {
        getCurrentData(6);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '8',
    () => {
      if (open) {
        getCurrentData(7);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '9',
    () => {
      if (open) {
        getCurrentData(8);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    '0',
    () => {
      if (open) {
        getCurrentData(9);
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    'enter',
    () => {
      if (open && index >= 0) {
        getCurrentData(getIndex());
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  const onFetch = useCallback(
    async (params) => {
      if (!open) return [];
      const res =
        await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
          {
            qto: {
              from: 0,
              size: 1000,
              searchInputLike: searchValue,
            },
            ext: {},
          },
        ); // TODO: 你可能需要整理返回数据格式

      setDataSource(res?.data?.result ?? res?.data ?? []);
      return {
        data: res?.data?.result ?? [],
        total: res?.data?.count ?? 0,
      };
    },
    [searchValue, open],
  );

  return (
    <div className="dictionary-table">
      <VSPromiseTable
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        onFetch={onFetch}
        pagination={{
          defaultPageSize: 10,
        }}
        size="small"
        scroll={{ y: 500 }}
        rowClassName={(record, rowIndex) => {
          return index === rowIndex ? 'ant-table-row-selected' : '';
        }}
        rowSelection={{
          selectType: 'single',
          // selectedRows: selectedRows,
          hideColumn: true,
          onSelect: (node, key, selected) => {
            handleSelect(node);
          },
          onSelectedRowsChange: (data, keys) => {
            handleSelect(data?.[0]);
          },
        }}
        rowClick={{
          rowClickType: 'select',
        }}
      >
        <VSTableColumn
          dataIndex={['operationName']}
          title="手术名称"
          valueType="text"
          fieldProps={{}}
          width={180}
          preview
        />
        <VSTableColumn
          dataIndex={['threePublicHandleType']}
          title="三公操作类别"
          valueType="text"
          fieldProps={{}}
          width={180}
          preview
        />
        <VSTableColumn
          dataIndex={['levelFourIndicator']}
          title="三公四级"
          valueType="select"
          dataSource={[
            { label: '三公四级', value: true },
            { label: '', value: false },
          ]}
          width={180}
          preview
        />
        <VSTableColumn
          dataIndex={['minimalInvasiveIndicator']}
          title="三公微创"
          valueType="select"
          dataSource={[
            { label: '三公微创', value: true },
            { label: '', value: false },
          ]}
          fieldProps={{}}
          width={180}
          preview
        />
      </VSPromiseTable>
    </div>
  );
};

export default Index;
