import { useCallback } from 'react';

export const useInputEnter = (
  props: Pick<React.DOMAttributes<any>, 'onKeyUp'> & Pick<any, 'onFieldEnter'>,
) => {
  const { onKeyUp, onFieldEnter } = props;

  const onKeyUpRet: React.DOMAttributes<any>['onKeyUp'] = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onFieldEnter?.();
      }
      onKeyUp?.(e);
    },
    [onFieldEnter, onKeyUp],
  );

  return {
    onKeyUp: onKeyUpRet,
  };
};
