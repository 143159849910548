import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  RadioGroup,
  Section,
  VSControlledForm,
  VSDataProvider,
  VSForm,
  VSFormFooter,
  VSFormFooterReset,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { askSearch } from '@/pages/Index/components/func/ask';

export type AnaphylactogenItem = {
  label: string;
  value: string;
};
export type AnaphylactogenList = {
  good: AnaphylactogenItem[];
  environment: AnaphylactogenItem[];
  mixture: AnaphylactogenItem[];
  other: AnaphylactogenItem[];
};

const enumValue = vsf.stores.dbenums?.enums?.ALLERGEN_DICT ?? [];

const Edit = (props) => {
  const { recordDoctor } = props ?? {};

  // const { user, common } = stores ?? {};
  // const { initKey, currentPatient, staffInfo, outVisitInfo, setInitKey } =
  //   user ?? {};
  // console.log(props);

  const { title, value } = props;
  // 过敏原类型
  const [allergyTypes, setAllergyTypes] = useState<any>();
  // 药品类型
  const [drugTypes, setDrugTypes] = useState();
  // 过敏原
  const [anaphylactogen, setAnaphylactogen] = useState<any>();

  const [allergyName, setAllergyName] = useState<any>();
  useEffect(() => {
    handelEnumValue();
  }, []);

  useEffect(() => {
    hasId(value) &&
      vsf.services
        ?.ClinicRecordHistoryController_getPatientAllergyById_ba748b?.({
          // TODO: 请检查以下这部分传参是否符合需求
          id: value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            setAllergyTypes(res?.data?.allergyType);
            setDrugTypes(res?.data?.drugType);
            vsf.refs?.allergyForm?.setFieldsValue({
              ...res.data,
            });
          }
        });
    setAllergyTypes('01');
  }, [value]);

  const hasId = (data) => {
    return data?.id;
  };
  // 药品
  const getDrug = async (v?) => {
    const res =
      await vsf.services?.DrugController_queryDrugNameDictionary_32035a?.({
        ...v,
      });
    if (res?.code === 200) {
      const _res = res?.data?.map((item) => {
        return {
          label: item?.drug?.drugName,
          value: item?.drug?.id,
        };
      });
      setAnaphylactogen(_res);
      return _res;
    } else {
      return [];
    }
  };

  // 药品商品
  const getDrugProduct = useCallback(async (v?) => {
    const res =
      await vsf.services?.DrugDrugController_queryPagefallByDrugProductNameIndexQto_9a2599?.(
        {
          qto: {
            // ...v,
            // drugCodeLike: v?.drugInputCodeLike,
            drugNameLike: v?.drugInputCodeLike,
            size: 100,
            from: 0,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      const _res = res?.data?.result?.map((item) => {
        return {
          label: item?.drugProductName,
          value: String(item?.id),
        };
      });
      setAnaphylactogen(_res);
      return _res;
    } else {
      return [];
    }
  }, []);

  const getAllergyName = (v) => {
    if (drugTypes === 'DRUG_PRODUCT') {
      return getDrugProduct(v);
    } else {
      return getDrug(v);
    }
  };

  const handelEnumValue = () => {
    const arr: AnaphylactogenList = {
      good: [],
      environment: [],
      mixture: [],
      other: [],
    };
    vsf.stores.dbenums?.enums?.ALLERGEN_DICT?.forEach((item: any) => {
      if (item?.data?.code.indexOf('2') === 0) {
        arr.good.push({
          label: item?.data?.cname,
          value: item?.data?.code,
        });
      }
      if (item?.data?.code.indexOf('3') === 0) {
        arr.environment.push({
          label: item?.data?.cname,
          value: item?.data?.code,
        });
      }
      if (item?.data?.code.indexOf('4') === 0) {
        arr.mixture.push({
          label: item?.data?.cname,
          value: item?.data?.code,
        });
      }
      if (item?.data?.code.indexOf('9') === 0) {
        arr.other.push({ label: item?.data?.cname, value: item?.data?.code });
      }
    });
    return arr;
  };

  // 过敏原
  const getAllergenDict = useCallback(() => {
    if (allergyTypes === '02') {
      setAnaphylactogen(handelEnumValue()?.good);
    }
    if (allergyTypes === '03') {
      setAnaphylactogen(handelEnumValue()?.environment);
    }
    if (allergyTypes === '04') {
      setAnaphylactogen(handelEnumValue()?.mixture);
    }
    if (allergyTypes == '09') {
      setAnaphylactogen(handelEnumValue()?.other);
    }
    if (allergyTypes == '01') {
      if (drugTypes === 'DRUG') {
        getDrug();
      }
      if (drugTypes === 'DRUG_CLASS') {
        setAnaphylactogen(vsf.stores.dbenums.enums.DRUG_CLASS_DICT);
      }
      if (drugTypes === 'DRUG_PRODUCT') {
        getDrugProduct();
      }
    }
  }, [allergyTypes, drugTypes, getDrugProduct]);

  useEffect(() => {
    getAllergenDict();
  }, [allergyTypes, drugTypes, getAllergenDict]);

  return (
    <VSControlledForm id="allergyForm" labelAlign="left">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormLayout key="4" labelWidth={100} columnCount={1}>
        {/* 01 默认人为药物过敏 */}
        <VSFormItem
          className="allergy_type_bgc"
          name={['allergyType']}
          label="过敏原类型"
          initialValue="01"
          valueType="radioButton"
          dataSource={vsf.stores.dbenums.enums.ALLERGY_TYPE_DICT}
          fieldProps={{
            disabled: title === 'update',
            buttonStyle: 'solid',
            onChange: (v) => {
              setAllergyTypes(v);
              vsf.refs?.allergyForm?.setFieldsValue({
                allergyName: null,
              });
            },
          }}
          rules={[{ required: true }]}
        />
        <VSFormItem
          name={['drugType']}
          label="药品类型"
          valueType="select"
          dataSource={[
            { label: '药品', value: 'DRUG' },
            { label: '药学分类', value: 'DRUG_CLASS' },
            { label: '药品商品', value: 'DRUG_PRODUCT' },
          ]}
          fieldProps={{
            disabled: title === 'update',
            showSearch: true,
            onChange: (v) => {
              setDrugTypes(v);
            },
          }}
          rules={[{ required: allergyTypes == '01' ? true : false }]}
          style={{ display: allergyTypes == '01' ? 'block' : 'none' }}
        />

        {allergyTypes == '01' ? (
          drugTypes === 'DRUG_CLASS' ? (
            <VSFormItem
              name={['allergyName']}
              label="过敏药品"
              valueType="custom"
              // dataSource={vsf.stores.dbenums.enums.DRUG_CLASS_DICT}
              fieldProps={{ showSearch: true }}
            >
              <Select
                options={vsf.stores.dbenums.enums.DRUG_CLASS_DICT}
                showSearch
                placeholder="请选择过敏药品"
                allowClear
                filterOption={askSearch}
                // style={{ width: formItemWidth }}
              />
            </VSFormItem>
          ) : (
            <VSFormItem
              name={['allergyName']}
              label="过敏药品"
              valueType="select"
              dataSource={getAllergyName}
              fieldNames={{
                label: 'label',
                value: 'value',
              }}
              searchKey={
                drugTypes === 'DRUG_PRODUCT' ? 'drugInputCodeLike' : 'input'
              }
              fieldProps={{
                showSearch: true,
                disabled: title === 'update',
              }}
              rules={[{ required: true }]}
              transform={(v) => {
                return drugTypes === 'DRUG_PRODUCT'
                  ? {
                      allergyName: v?.label,
                      allergyDrugProductId: v?.value,
                    }
                  : drugTypes === 'DRUG'
                  ? {
                      allergyName: v?.label,
                      allergyDrugId: v?.value,
                    }
                  : {
                      allergyName: v?.label,
                    };
              }}
            />
          )
        ) : (
          <VSFormItem
            name={['allergyName']}
            label="过敏原"
            valueType="select"
            rules={[
              // { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              { required: true },
            ]}
            fieldProps={{
              disabled: title === 'update',
              showSearch: true,
              onSelect: (v) => {
                setAllergyName(v?.label);
              },
            }}
            dataSource={anaphylactogen}
            transform={(v) => {
              return { allergyName: allergyName };
            }}
          />
        )}

        <VSFormItem
          name={['allergyLevel']}
          label="过敏程度"
          valueType="radio"
          dataSource={vsf.stores.dbenums.enums.DRUG_ALLERGY_SEVERITY_DICT}
          fieldProps={{
            defaultValue: { label: '轻', value: '01' },
            disabled: !recordDoctor,
          }}
        />
        <VSFormItem
          name={['allergySymptom']}
          label="过敏症状"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.ALLERGY_SYMPTOMS_DICT}
          fieldProps={{
            showSearch: true,
            disabled: !recordDoctor,
          }}
        />
        <VSFormItem
          name={['startDate']}
          label="开始日期"
          valueType="date"
          initialValue={+new Date()}
          fieldProps={{
            disabled: !recordDoctor,
            defaultValue: +new Date(),
            disabledDate: (current) => {
              return current && current > dayjs().endOf('day');
            },
          }}
        />

        <VSFormItem
          name={['notAllergyIndicator']}
          label="当前状态"
          fieldProps={{}}
          valueType="radio"
          initialValue={false}
          dataSource={[
            {
              label: '继续过敏',
              value: false,
            },
            {
              label: '不再过敏',
              value: true,
            },
          ]}
        />
        <VSFormItem
          name={['selfComplaintIndicator']}
          label="信息来源"
          valueType="radio"
          dataSource={[
            {
              label: '患者自述',
              value: true,
            },
            {
              label: '本院发生',
              value: false,
            },
          ]}
          initialValue={true}
          fieldProps={{
            disabled: !recordDoctor,
          }}
          transform={(v) => {
            return {
              selfComplaintIndicator: v ? true : false,
              inHospitalIndicator: v ? false : true,
            };
          }}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(Edit);
