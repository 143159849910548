import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugFirmDictionaryDtoController_queryDrugFirmDictionary_25e16e: {
    method: 'post',
    url: '/api/drug-drug/query-drug-firm-dictionary',
    parameterFomatter: (data?: {
      qto: DrugFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
});
