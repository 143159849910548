/* eslint-disable prefer-const */
/* eslint-disable simple-import-sort/imports */
import './index.less';
import '@/style/adaptation.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import { askSearch } from '@/components/AspirinSelect/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AspirinSelectInput } from '@/components';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import {
  convertFirstBedData2FirstReqParam,
  convertGet,
  convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo,
  convertSecondBedData2SecondReqParam,
  currentWardTotalData,
  totalData,
} from '@/pages/Index/manage/bed_card/convert';
import { height, heightMessage } from '@/pattern';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import storage from '@/utils/storage';

import {
  convertErpPatientInTransferAcceptForm2AcceptErpPatientFuncFlowExecutorReq,
  convertQueryErpPatientInTransferAcceptVoByErpVisitIdRes2ErpPatientInTransferAcceptForm,
  convertQueryErpPatientInTransferWaitInVoByErpPatientInTransferWaitInQtoRes2ErpPatientInTransferWaitInList,
} from './convert';

const Index = (props) => {
  const {
    stores: {
      common: { currentApplication },
    },
  } = props;

  // table当前选中行;
  const [currentErpVisit, setCurrentErpVisit] = useState<any>();
  // isempty
  const [isEmpty, setIsEmpty] = useState<any>(true);

  const [levelList, setLevelList] = useState<any>();

  const [diagnosisList, setDiagnosisList] = useState<any>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // 参考/实际分级（初始获取）
  const getLevel = async () => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    setLevelList(res?.data);
  };

  useEffect(() => {
    getLevel();
  }, []);

  // 床位
  const getPagefallByClinicBedWithPatient = useCallback(async (params) => {
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_0429ae?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            bedStatusIs: '0', // 床卡是否占用 0空床|1占床
            wardIdIs: 3, // 病区ID
            // bedLabelIs: params?.bedLabel, // 输入查询标号
          },
          ext: {},
        },
      );
    // TODO: 你可能需要整理返回数据格式
    let resData: any = [];
    // setBedList(res?.data?.result);
    res?.data?.result.map((item: any) => {
      // return { ...item, bedLabel: item?.bedLabel + '床' };
      // 判断是否输入
      if (params.bedLabel) {
        // 条件判断
        const record = askSearch(params.bedLabel, item, 'bedLabel');
        if (record) {
          //   record.bedLabel = record.bedLabel + '床';
          //   return { ...record, bedLabel: record?.bedLabel };
          // 添加符合搜索条件
          resData.push({ ...item, bedLabel: item?.bedLabel + '床' });
        }
      } else {
        // 默认回显全部
        resData.push({ ...item, bedLabel: item?.bedLabel + '床' });
      }
    });
    return resData;
  }, []);

  // 主治医师
  const getGroupController = async (v) => {
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          wardId: currentApplication?.department?.id,
          stopIndicator: false,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    return [];
  };

  // 主治医师
  const operationDoctorDataSource = useCallback(async (params) => {
    // console.log(params);

    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: params?.staffName ?? '',
        },
      );
    // console.log(res?.data.result, 'result');
    return res?.data?.result;
  }, []);
  // table
  const onFetch_t3131 = useCallback(async (params?) => {
    const res =
      await vsf?.services?.ClinicErpAdtController_queryErpPatientInTransferWaitInVoByErpPatientInTransferWaitInQto_fa817d?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
          },
        },
      ); // TODO: 请确认入参来源及出参用法

    const output =
      convertQueryErpPatientInTransferWaitInVoByErpPatientInTransferWaitInQtoRes2ErpPatientInTransferWaitInList?.(
        res,
      )?.map((i) => {
        return i?.treatAreaFrom ? { ...i } : { ...i, treatAreaFrom: 'NEW_IN' };
      });
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  // form
  const getDetail = useCallback(
    async (params?) => {
      if (!params?.erpVisitId && !currentErpVisit?.id) return;
      setLoading(true);
      const res =
        await vsf?.services?.ClinicErpAdtController_queryErpPatientInTransferAcceptVoByErpVisitId_3aff8d?.(
          { erpVisitId: params?.erpVisitId ?? currentErpVisit?.erpVisitId },
        ); // TODO: 请确认入参来源及出参用法
      setLoading(false);
      const output =
        convertQueryErpPatientInTransferAcceptVoByErpVisitIdRes2ErpPatientInTransferAcceptForm?.(
          res,
        );

      if (res?.code == 200) {
        const diagnosisList = output?.diagnosisRecordWithDiagnosisVoList;
        const _diagnosisList =
          diagnosisList && diagnosisList?.length > 0
            ? diagnosisList
            : [{ rowId: Math.random() }];

        setIsEmpty(false);
        setDiagnosisList(_diagnosisList);
        const record = {
          ...output,
          diagnosisRecordWithDiagnosisVoList: _diagnosisList,
          prePaymentBalance: Number(output?.prePaymentBalance ?? 0).toFixed(2),
          isolationIndicator: output?.isolationIndicator
            ? output?.isolationIndicator
            : false,
          fastingIndicator: output?.fastingIndicator
            ? output?.fastingIndicator
            : false,
        };
        if (!record.nurseAcceptDateTime) {
          record.nurseAcceptDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
          // console.log('不存在接收时间');
        }
        // 根据出生日期计算年龄
        const age = dayjs().diff(dayjs(output.birthday), 'year');
        if (!record.age) record.age = age;
        vsf.refs.erpPatientInTransferAcceptForm.setFieldsValue(record);
      } else {
        setIsEmpty(true);
        // setDiagnosisList([]);
        // vsf.refs.erpPatientInTransferAcceptForm.setFieldsValue(null);
      }
      return output;
    },
    [currentErpVisit],
  );
  // 保存
  const handelSave = async () => {
    const _diagnosisList = (diagnosisList ?? [])?.map((i) => {
      return {
        diagnosisDescription: i?.diagnosisDescription,
        diagnosisId: i?.id,
        Prefix: i?.id,
        suffix: i?.id,
      };
    });
    // console.log(_diagnosisList, 'diagnosisListdiagnosisList');
    // return;
    await vsf.refs.erpPatientInTransferAcceptForm.validateFieldsReturnFormatValue();

    const values = vsf.refs.erpPatientInTransferAcceptForm.getFieldsValue();

    const output =
      convertErpPatientInTransferAcceptForm2AcceptErpPatientFuncFlowExecutorReq?.(
        values,
      );
    const res =
      await vsf?.services?.ClinicErpAdtController_acceptErpPatientFuncFlowExecutor_52cb5e?.(
        {
          createDiagnosisRecordBtoList: _diagnosisList,
          updateErpVisitForTransferBto: output?.updateErpVisitForTransferBto,
          // createOrUpdatePatientBillingRecordBto:
          //   output?.createOrUpdatePatientBillingRecordBto,
        },
      );
    if (res?.code == 200) {
      message.success('保存成功');
      handelClear();
    }
  };
  // 刷新
  const handelClear = () => {
    setCurrentErpVisit(null);
    setIsEmpty(true);
    // onFetch_t3131();
    vsf.refs?.waitTransferPatientTable?.reload();
  };

  useEffect(() => {
    getDetail();
  }, [getDetail, currentErpVisit]);

  const renderSearch = () => {
    return (
      <div className="patient_administrantion_search_input">
        <div className="patient_administrantion_search_input_item">
          <span className="patient_administrantion_search_input_item_label">
            患者ID
          </span>
          <Input
            height={32}
            width={200}
            style={{
              fontSize: '14px',
            }}
            onKeyUp={async (e: any) => {
              if (e.code === 'Enter') {
                setLoading(true);
                const res = await onFetch_t3131();
                setLoading(false);
                const _item = res?.data?.find(
                  (i) => i?.displayId == e?.target?.value,
                );
                if (_item) {
                  getDetail({ erpVisitId: _item?.erpVisitId });
                } else {
                  setIsEmpty(true);
                  message.info('未找到该患者');
                }
              }
            }}
          />
        </div>
      </div>
    );
  };

  // console.log(vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE, 'vsf');

  const dataSource = vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE?.map(
    (i: any, index) => {
      // 返回得值对应PRE_EXAM_SCORE_TYPE码表的某项
      // 父子关系
      const __arr = (vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT ?? [])?.filter(
        (item) => {
          return (
            item?.extensionJson &&
            JSON.parse(item?.extensionJson)?.preExamScoreTypeCode == i.code
          );
        },
      );
      // console.log(__arr, '__arr');
      // console.log(i, 'i');
      return {
        ...i,
        children: __arr,
      };
    },
  )
    ?.map((i) => {
      return i?.children?.length > 0 ? i?.children : i;
    })
    .flat()
    ?.map((i) => {
      return i?.children?.length > 0 ? i?.children : i;
    })
    .flat()
    ?.sort((a, b) => {
      return a?.order - b?.order;
    });

  return (
    <div className="patient_administrantion">
      <div className="patient_administrantion_left">
        <div className="patient_administrantion_left_form_title">
          <div className="patient_administrantion_left_form_title_tag"></div>
          患者信息
        </div>
        <div className="patient_administrantion_table">
          <VSPromiseTable
            // editable={{ editType: 'single' }} aspirin-table-row-background-color
            id="waitTransferPatientTable"
            pagination={false}
            onFetch={onFetch_t3131}
            className="
                aspirin-table-nopadding
              aspirin-table-header-background-color
               aspirin-table-row-hover-background-color
               aspirin-table-body-empty-transparent-background-color
              "
            rowClassName={(record, v) => {
              return cn(
                currentErpVisit?.id == record?.id
                  ? 'aspirin-table-row-select-background-color'
                  : '',
              );
            }}
            // scroll={{ y: getScrollY(280) }}
            scroll={{ y: getScrollYRem(18) }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (currentErpVisit?.erpVisitId == record?.erpVisitId) {
                    setCurrentErpVisit(null);
                  } else {
                    setCurrentErpVisit(record);
                  }
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              fieldProps={{}}
              width={labelNumberWidth(8)}
              ellipsis
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
              ellipsis
            />

            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              valueType="select"
              fieldProps={
                {
                  // dataSource: vsf.stores.dbenums.enums.TREAT_AREA_DICT,
                }
              }
              ellipsis
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            />

            <VSTableColumn
              dataIndex={['treatAreaFrom']}
              title="来源"
              valueType="select"
              fieldProps={
                {
                  // dataSource: vsf.stores.dbenums.enums.TREAT_AREA_DICT,
                }
              }
              dataSource={[
                ...vsf.stores.dbenums?.enums?.TREAT_AREA_DICT,
                { label: '新入', value: 'NEW_IN' },
              ]}
            />
          </VSPromiseTable>
        </div>
      </div>
      <div className="patient_administrantion_right">
        <div className="patient_administrantion_search">{renderSearch()}</div>
        {loading ? (
          <Loading size="normal" />
        ) : !isEmpty ? (
          <div className="patient_administrantion_form">
            <div className="patient_administrantion_form_content">
              <VSForm labelAlign="left" id="erpPatientInTransferAcceptForm">
                <VSFormItem
                  name={['id']}
                  label="主键"
                  readonly
                  valueType="digit"
                  fieldProps={{}}
                  style={{ display: 'none' }}
                />

                <VSFormItem
                  name={['erpVisitId']}
                  label="主键"
                  readonly
                  valueType="digit"
                  fieldProps={{}}
                  style={{ display: 'none' }}
                />
                <Block
                  title="基本信息"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                    gutter={80}
                  >
                    <VSFormItem
                      name={['name']}
                      label="姓名"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['displayId']}
                      label="患者ID"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['gender']}
                      label="性别"
                      readonly
                      valueType="select"
                      fieldProps={
                        {
                          // dataSource: vsf.stores.dbenums.enums.NURSING_CLASS_DICT,
                        }
                      }
                      dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    />

                    <VSFormItem
                      name={['age']}
                      label="年龄"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['birthAddress']}
                      label="出生地"
                      readonly
                      valueType="address"
                      fieldProps={{
                        showDetail: true,
                      }}
                    />

                    <VSFormItem
                      name={['chargeTypeCode']}
                      label="费别编码"
                      readonly
                      valueType="select"
                      fieldProps={
                        {
                          // dataSource: vsf.stores.dbenums.enums.NURSING_CLASS_DICT,
                        }
                      }
                      dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    />

                    <VSFormItem
                      name={['prePaymentBalance']}
                      label="预交金余额"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['contactPersonName']}
                      label="联系人姓名"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['contactPersonPhone']}
                      label="联系人电话"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </Block>
                <Block
                  title="患者接收信息"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    columnCount={3}
                    labelWidth={labelNumberWidth(6)}
                    gutter={80}
                  >
                    <VSFormItem
                      name={['arriveHospitalDateTime']}
                      label="到院时间"
                      readonly
                      valueType="date"
                      required
                      fieldProps={{ disabled: true, showTime: true }}
                    />

                    <VSFormItem
                      name={['arriveHospitalType']}
                      label="到院方式"
                      readonly
                      valueType="select"
                      required
                      fieldProps={{ disabled: true }}
                      dataSource={vsf.stores.dbenums?.enums?.ARRI_PATTERN_DICT}
                    />

                    <VSFormItem
                      name={['nurseAcceptDateTime']}
                      label="接收时间"
                      valueType="date"
                      required
                      fieldProps={{
                        width: '100%',
                        showTime: true,
                      }}
                    />

                    <VSFormItem
                      name={['bed']}
                      label="床号"
                      valueType="select"
                      fieldProps={{
                        allowClear: true,
                        showSearch: true,
                        onChange: async (v) => {
                          const res =
                            await vsf?.services?.ClinicBedController_getBedWithPrice_03a9ad?.(
                              { bedId: v.id },
                            );
                          vsf.refs.erpPatientInTransferAcceptForm.setFieldsValue(
                            {
                              bedPrice: Number(
                                res?.data?.bedPrice ?? 0,
                              ).toFixed(2),
                            },
                          );
                        },
                      }}
                      required
                      fieldNames={{ label: 'bedLabel', value: 'id' }}
                      dataSource={getPagefallByClinicBedWithPatient}
                      // dataSource={[
                      //   {
                      //     id: 40701,
                      //     bedClass: '1',
                      //     bedLabel: '12345',
                      //     bedRoomId: null,
                      //     bedStatus: '0',
                      //     bedType: '9',
                      //     createdAt: '2024-06-27 17:36:14',
                      //     creator: null,
                      //     deletedAt: null,
                      //     departmentId: 48,
                      //     memo: null,
                      //     operator: null,
                      //     patternCode: null,
                      //     rentByDepartmentId: null,
                      //     sortNumber: 2,
                      //     treatAreaCode: '01',
                      //     updatedAt: '2024-07-30 17:52:25',
                      //     bedGroupId: null,
                      //     wardId: 3,
                      //     clinicPatientBedDto: null,
                      //     wardName: '急诊抢救护理单元',
                      //     appointKeepBedIndicator: null,
                      //     planPatientId: null,
                      //     planAdmissionDate: null,
                      //     bedSettingType: '0',
                      //     inpEscortRegisterList: null,
                      //     inpAdmissionApply: null,
                      //     treatAreaSortCode: '01',
                      //   },
                      // ]}
                    />

                    {/* <VSFormItem
                      name={['bed']}
                      label="床号"
                      valueType="codeTableSelect"
                      fieldProps={{
                        allowClear: true,
                        showSearch: true,
                        onChange: async (v) => {
                          console.log(v, 'ppppp');
                          const res =
                            await vsf?.services?.ClinicBedController_getBedWithPrice_03a9ad?.(
                              { bedId: v.id },
                            );
                          vsf.refs.erpPatientInTransferAcceptForm.setFieldsValue(
                            {
                              bedPrice: Number(
                                res?.data?.bedPrice ?? 0,
                              ).toFixed(2),
                            },
                          );
                        },
                      }}
                      required
                      fieldNames={{ label: 'bedLabel', value: 'id' }}
                      dataSource={getPagefallByClinicBedWithPatient}
                    /> */}

                    <VSFormItem
                      name={['bedPrice']}
                      label="床位费"
                      readonly
                      valueType="text"
                      required
                      fieldProps={{ disabled: true }}
                    />

                    <VSFormItem
                      name={['nursingClass']}
                      label="护理等级"
                      valueType="codeTableSelect"
                      fieldProps={{
                        showSearch: true,
                        allowClear: true,
                        // dataSource: vsf.stores.dbenums.enums.NURSING_CLASS_DICT,
                      }}
                      required
                      dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                    />

                    <VSFormItem
                      name={['patientCondition']}
                      label="病情"
                      valueType="codeTableSelect"
                      fieldProps={{
                        showSearch: true,
                        allowClear: true,
                        // dataSource:
                        // vsf.stores.dbenums.enums.ISOLATION_TYPE_DICT,
                      }}
                      required
                      dataSource={
                        vsf.stores.dbenums?.enums?.PATIENT_STATUS_DICT
                      }
                    />

                    <VSFormItem
                      name={['isolationIndicator']}
                      label="隔离"
                      valueType="check"
                      initialValue={false}
                      fieldProps={{
                        defaultValue: false,
                        // dataSource:
                        // vsf.stores.dbenums.enums.ISOLATION_TYPE_DICT,
                      }}
                    />

                    <VSFormItem
                      name={['fastingIndicator']}
                      label="禁食"
                      valueType="check"
                      initialValue={false}
                      fieldProps={{ defaultValue: false }}
                    />

                    <VSFormItem
                      name={['attendingDoctor']}
                      label="主治医师"
                      valueType="select"
                      required
                      fieldNames={{ label: 'staffName', value: 'id' }}
                      dataSource={operationDoctorDataSource}
                      fieldProps={{ showSearch: true, allowClear: true }}
                    />

                    <VSFormItem
                      name={['diagnosisRecordWithDiagnosisVoList']}
                      label="入室诊断"
                      valueType="custom"
                      // transform={(v, b) => {
                      //   console.log(v, b, 'ppp');
                      //   const renderValue = v
                      //     ?.map((i) => i?.diagnosisDescription)
                      //     ?.join('；');
                      //   console.log(v, renderValue, 'renderValue');

                      //   return renderValue;
                      // }}
                      customComponent={(props) => {
                        return (
                          <Popover
                            trigger="click"
                            placement="bottom"
                            open={open}
                            onOpenChange={(v) => {
                              if (!v) {
                                setDiagnosisList([
                                  ...(props?.value ?? [])?.filter(
                                    (i) => i?.diagnosisDescription,
                                  ),
                                ]);
                                setOpen(false);
                              }
                            }}
                            content={
                              <div style={{ padding: 15 }}>
                                {props?.value?.map((i, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 60,
                                        width: 400,
                                      }}
                                    >
                                      <AspirinSelectInput
                                        searchKey="searchInputLike"
                                        style={{ width: '100%' }}
                                        fieldProps={{
                                          showSearch: true,
                                          searchKey: 'searchInputLike',
                                        }}
                                        showSearch={true}
                                        dataSource={async (params) => {
                                          const res =
                                            await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
                                              {
                                                qto: {
                                                  from:
                                                    params?.pagination?.from ??
                                                    0,
                                                  size:
                                                    params?.pagination?.size ??
                                                    2000,
                                                  orderList: params?.orderList,
                                                  ...params?.search,
                                                  ...params?.filters,
                                                  ...params?.extra,
                                                  ...(params ?? {}),
                                                },
                                                ext: {},
                                              },
                                            ); // TODO: 你可能需要整理返回数据格式
                                          return res?.data?.result?.map(
                                            (item) => {
                                              return {
                                                ...item,
                                                diagnosisDescription:
                                                  item?.diagnosisName,
                                              };
                                            },
                                          );
                                        }}
                                        fieldNames={{
                                          label: 'diagnosisDescription',
                                          value: 'id',
                                        }}
                                        attributeName="diagnosisDescription"
                                        value={{
                                          ...i,
                                          prefix: i?.prefix,
                                          suffix: i?.suffix,
                                          selectValue: i?.selectValue,
                                        }}
                                        onChange={(v) => {
                                          // console.log(v, diagnosisList, 'pppk');
                                          const res = props?.value?.map((i) => {
                                            return i?.rowId == v?.rowId
                                              ? { ...v, diagnosisId: v?.id }
                                              : i;
                                          });
                                          props?.onChange([...res]);
                                          // setDiagnosisList([...res]);
                                        }}
                                      />
                                      <Icon
                                        style={{
                                          margin: '0 10px',
                                          color: '#3276e8',
                                        }}
                                        type="icon-cuo"
                                        size={16}
                                        onClick={() => {
                                          // console.log(props?.value);
                                          if (props?.value?.length <= 1) {
                                            // 清空
                                            props?.onChange([
                                              { rowId: Math.random() },
                                            ]);
                                          } else {
                                            props?.onChange(
                                              [...props?.value]?.filter(
                                                (v) => i?.rowId != v?.rowId,
                                              ),
                                            );
                                          }
                                          // setDiagnosisList([
                                          //   ...props?.value,
                                          //   _creatRowItem,
                                          // ]);
                                        }}
                                      />
                                      <Icon
                                        style={{ color: '#3276e8' }}
                                        type="icon-tianjia1"
                                        size={16}
                                        onClick={() => {
                                          // console.log(props?.value);
                                          const _creatRowItem = {
                                            rowId: Math.random(),
                                          };
                                          props?.onChange([
                                            ...props?.value,

                                            _creatRowItem,
                                          ]);
                                          // setDiagnosisList([
                                          //   ...props?.value,
                                          //   _creatRowItem,
                                          // ]);
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            }
                          >
                            <Input
                              disabled
                              addonAfter={
                                <Icon
                                  onClick={() => {
                                    setOpen(true);
                                  }}
                                  type="icon-wudibianji"
                                />
                              }
                              value={diagnosisList
                                ?.map(
                                  (i) =>
                                    (i?.prefix ?? '') +
                                    (i?.diagnosisDescription ?? '') +
                                    (i?.suffix ?? ''),
                                )
                                ?.join('；')}
                            />
                          </Popover>
                        );
                      }}
                    />
                  </VSFormLayout>
                </Block>
                <Block
                  title="预检评估"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormItem
                    name={['erpPreExamRecordMasterWithDetailVoList']}
                    valueType="custom"
                    customComponent={(record) => {
                      // console.log(
                      //   record.value?.[0]
                      //     ?.erpPreExamRecordDetailWithVitalsignDictionaryList,
                      //   '7459_value',
                      // );

                      // console.log(
                      //   vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE,
                      //   '7459_enums',
                      // );
                      // console.log(record.value?.[0], '7459_record');

                      // console.log(record?.value?.[0], 'record.value');

                      // console.log(
                      //   vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE?.map(
                      //     (i: any, index) => {
                      //       // 返回得值对应PRE_EXAM_SCORE_TYPE码表的某项
                      //       // 父子关系
                      //       const __arr = (
                      //         vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT ?? []
                      //       )?.filter((item) => {
                      //         return (
                      //           item?.extensionJson &&
                      //           JSON.parse(item?.extensionJson)
                      //             ?.preExamScoreTypeCode == i.code
                      //         );
                      //       });
                      //       // console.log(__arr, '__arr');
                      //       // console.log(i, 'i');
                      //       return {
                      //         ...i,
                      //         children: __arr,
                      //       };
                      //     },
                      //   )
                      //     ?.map((i) => {
                      //       return i?.children?.length > 0 ? i?.children : i;
                      //     })
                      //     .flat()
                      //     ?.map((i) => {
                      //       return i?.children?.length > 0 ? i?.children : i;
                      //     })
                      //     .flat()
                      //     ?.sort((a, b) => {
                      //       return a?.order - b?.order;
                      //     }),
                      //   '过滤',
                      // );

                      return (
                        <div>
                          <div className="table_title">
                            <div className="table_title_item">
                              <span className="label">病情分级:</span>
                              <span
                                style={{
                                  color: levelList?.find(
                                    (i) =>
                                      i?.id ==
                                      record?.value?.[0]?.diseaseLevelName?.id,
                                  )?.colorCode,
                                }}
                              >
                                {
                                  record?.value?.[0]?.diseaseLevelName
                                    ?.diseaseLevelName
                                }
                                级
                              </span>
                            </div>
                            <div className="table_title_item">
                              <span className="label">GCS:</span>
                              <span>{record?.value?.[0]?.gcsScore ?? 0}分</span>
                            </div>
                            <div className="table_title_item">
                              <span className="label">NRS:</span>
                              <span>{record?.value?.[0]?.nrsScore ?? 0}分</span>
                            </div>
                            <div className="table_title_item">
                              <span className="label">
                                {record?.value?.[0]?.pewsScore
                                  ? 'PEWS'
                                  : 'MEWS'}
                              </span>
                              <span>
                                {record?.value?.[0]?.pewsScore
                                  ? record?.value?.[0]?.pewsScore
                                  : record?.value?.[0]?.nrsScore}
                                分
                              </span>
                            </div>
                          </div>
                          <VSTable
                            className="
                        aspirin-table-nopadding

                        aspirin-table-body-empty-transparent-background-color
                      "
                            scroll={{ y: 400 }}
                            // bordered
                            showHeader={false}
                            // title={() => {
                            //   return <div className="table_title">哈哈哈</div>;
                            // }}
                            value={
                              record?.value?.[0]
                                ?.erpPreExamRecordDetailWithVitalsignDictionaryList ??
                              []
                            }
                            pagination={false}
                            rowClassName={(record, index) => {
                              // if (record?.id === row?.value?.id) {
                              //   return 'aspirin-row-selected-box';
                              // }

                              return index % 2 == 0
                                ? 'table_content_row_bgc'
                                : '';
                            }}
                          >
                            <VSTableColumn
                              dataIndex={['item']}
                              title=""
                              valueType="select"
                              fieldProps={{}}
                              // dataSource={dataSource}
                              dataSource={
                                vsf?.stores?.dbenums?.enums?.PRE_EXAM_ITEM_DICT
                              }
                            />
                            <VSTableColumn
                              dataIndex={['itemValue']}
                              title=""
                              valueType="text"
                              fieldProps={{}}
                            />
                          </VSTable>
                        </div>
                      );
                    }}
                  />
                </Block>
              </VSForm>
            </div>
            <div className="patient_administrantion_form_bottom">
              <div
                className="patient_administrantion_form_bottom_btn1 patient_administrantion_form_bottom_btn"
                onClick={handelClear}
              >
                刷新
              </div>
              <div
                onClick={handelSave}
                className="patient_administrantion_form_bottom_btn2 patient_administrantion_form_bottom_btn"
              >
                保存
              </div>
            </div>
          </div>
        ) : (
          <div className="patient_administrantion_form">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
