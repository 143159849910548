import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  VSTable,
  VSTableColumn,
  VSTableInstance,
} from '@vs/vsf-kit';
import { useSetState } from 'ahooks';
import update from 'immutability-helper';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import Block from '@/module/Block';
import Container from '@/module/Container';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { ButtonAndKeyDown, Flex } from '@/pages/Index/components';
import { askJustOnce } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { CreateScreen } from '@/pages/Index/components/screen';
import { getScrollY } from '@/utils';

import Other from './other';
type TableRef = VSTableInstance<any, 'text'> | null;

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const restrict = (modifiers) => {
  const { offset, ...rest } = modifiers;
  console.log(modifiers, 'modifiers');
  return {
    ...rest,
    offset: (args) => {
      const result = offset(args);

      // 添加自定义的修饰器逻辑
      // ...

      return result;
    },
    // 添加其他自定义修饰器函数
    // ...
  };
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
  const { children } = props;
  const currentApplication = vsf.stores?.common?.currentApplication;
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const [recode] = React.Children.toArray(children) || [];
  const record = (recode as any)?.props?.record;
  const style: React.CSSProperties = {
    ...props.style,
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };
  if (
    currentApplication?.department?.clinicAttribute !== '2' ||
    record?.ward ||
    String(record?.id)?.startsWith('create')
  ) {
    style.transform = (CSS as any)?.Translate?.toString?.(transform);
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child: any) => {
        const { record } = child?.props;
        if (
          currentApplication?.department?.clinicAttribute !== '2' ||
          record?.ward ||
          String(record?.id)?.startsWith('create')
        ) {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <Flex justify="center">
                  <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: 'none', cursor: 'move' }}
                    {...listeners}
                  />
                </Flex>
              ),
            });
          }
        }
        return child;
      })}
    </tr>
  );
};

const Deploy = (props) => {
  const deploy = useRef<TableRef>(null);
  const otherRef = useRef<any>(null);

  const {
    stores: {
      common: { currentApplication },
    },
  } = props;
  const id = useMemo(() => getUUID(), []);
  const _value = useRef({});
  const [state, setState] = useSetState({});
  const _click = useRef({});
  const [value, _setValue] = useSetState({});
  const getValue = () => _value?.current;
  const onChange = useCallback((info: any[]) => {
    _value.current = Object.fromEntries(info?.map((item) => [item?.id, item]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setValue = useCallback(
    (info) => {
      const _info = {
        ..._value?.current,
        ...info,
      };
      _value.current = _info;
      console.log(_info);

      _setValue(info);
    },
    [_setValue],
  );
  const click: any = {
    value: _click?.current,
    onChange: (info) => {
      console.log(info);
      _click.current = info;
      const dom = document?.querySelector(`[data-row-key="${info?.id}"]`);
      new Promise((resolve, reject) => {
        try {
          dom?.parentNode
            ?.querySelector('.aspirin-row-selected-box')
            ?.classList?.remove('aspirin-row-selected-box');
          resolve(true);
        } catch (error) {
          reject(error);
        }
      })?.then(() => {
        dom?.classList?.add('aspirin-row-selected-box');
      });
      otherRef?.current?.onChange?.(info);
    },
  };

  const onFetch = useCallback(async () => {
    const res =
      await vsf.services?.ClinicRecordOrderExtensionController_getAllByQueryAllQto_c20981?.(
        {
          qto: {
            from: 0,
            size: 100,
          },
        },
      );
    const dict = Object.fromEntries(
      res?.data?.map((item, index: number) => {
        const {
          orderPerformLabelSettingItemList,
          orderPerformLabelSettingItemDtoList,
        } = item;
        const [administrationList] = orderPerformLabelSettingItemList || [];
        return [
          item?.id,
          {
            ...item,
            sortNumber: item?.ward ? item?.sortNumber : -1,
            itemList: (orderPerformLabelSettingItemList ?? [])?.map((item) => ({
              id: 'create' + Math?.random?.(),
              ...item,
            })),
            administrationList:
              administrationList?.orderPerformLabelSettingItemVoList?.filter(
                (item) => item?.administration,
              ) ?? [],
            useDescriptionList:
              administrationList?.orderPerformLabelSettingItemVoList?.filter(
                (item) => item?.useDescription,
              ) ?? [],
            orderTextList: orderPerformLabelSettingItemDtoList,
          },
        ];
      }),
    );
    console.log(dict, 'dictdictdict');

    _value.current = dict;
    setState(dict);
    _setValue(dict);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onScreen = useContext(CreateScreen);
  const onSave = useFetch({
    fetch: async (eo) => {
      const res =
        await vsf?.services?.ClinicRecordOrderExtensionController_saveLabelSettingMaster_6a1c2d?.(
          {
            eo,
          },
        );
      return res;
    },
    message: {
      loading: '确认中',
      success: '确认成功',
      error: '确认失败',
    },
    success: async () => {
      onFetch();
      // await vsf.refs.executionOrderList?.reload();

      onScreen?.(() => void 0);
    },
  });

  const Footer = () => {
    const onSubmit = async () => {
      await Promise?.all([
        deploy?.current?.getEditForm?.()?.validateFieldsReturnFormatValue?.(),
        otherRef?.current?.getValue(),
      ]);
      const { add, update, remove } = askJustOnce({
        newState: Object?.values(_value?.current ?? {})?.filter((item: any) => {
          return (
            currentApplication?.department?.clinicAttribute !== '2' ||
            item?.ward ||
            String(item?.id)?.startsWith('create')
          );
        }) as any,
        oldState: Object?.values(state ?? {})?.filter((item: any) => {
          return (
            currentApplication?.department?.clinicAttribute !== '2' ||
            item?.ward ||
            String(item?.id)?.startsWith('create')
          );
        }) as any,
        id: 'id',
      });
      const tr = (list: any[]) => {
        return list?.map((item) => {
          if (String(item?.id)?.startsWith('create')) delete item?.id;
          /**
           * 保存时，如果当前处于“按医嘱类型”模式，则只写入order_class、administration、user_description、include_indicator这四个字段
           * 按医嘱类型”保存时，（order_class、administration）与（order_class、user_description
           */
          const orderType = item?.itemList?.flatMap(({ id, ...option }) => {
            const list = [item?.administrationList, item?.useDescriptionList];
            if (option?.orderClass === 'DRUG') {
              if (list?.filter((item) => item?.length)?.length) {
                return list?.flatMap((value) =>
                  value?.map(({ id, ...rest }) => ({
                    ...option,
                    ...rest,
                  })),
                );
              } else {
                return [option];
              }
            } else {
              return [option];
            }
          });
          const orderText = item?.orderTextList?.map(({ id, ...item }) => item);
          return {
            ...item,
            itemList: [...(orderType ?? []), ...(orderText ?? [])]?.map(
              (item, index: number) => ({
                ...item,
                itemNumber: index + 1,
              }),
            ),
          };
        });
      };
      onSave({
        createList: tr(add),
        updateList: tr(update),
      });
    };
    return (
      <div className="aspirin-table-footer-button-box">
        <ButtonAndKeyDown
          methodKey="altKey"
          ordinaryKey="A"
          onClick={onSubmit}
          type="primary"
        >
          确认
        </ButtonAndKeyDown>
      </div>
    );
  };

  useEffect(() => {
    onFetch?.();
  }, [onFetch]);

  const func = (record) => {
    return (
      currentApplication?.department?.clinicAttribute !== '2' ||
      record?.ward ||
      String(record?.id)?.startsWith('create')
    );
  };

  const onDragEnd = (a: any) => {
    console.log(_value.current, a, 'active, over');
    const { active, over } = a;
    const list = ((Object?.values(_value.current) ?? []) as any)?.toSorted(
      (a: any, b: any) => {
        return a?.sortNumber - b.sortNumber;
      },
    );
    const activeIndex = list.find((record) => record.id === active?.id);
    console.log(activeIndex);

    const res = update(list, {
      $splice: [
        [active?.data?.current?.sortable?.index, 1],
        [over?.data?.current?.sortable?.index, 0, activeIndex],
      ],
    });
    const state = Object.fromEntries(
      res?.map((item, index) => [
        item?.id,
        {
          ...item,
          sortNumber: index + 1,
        },
      ]),
    );
    console.log(res, state, list, activeIndex, 'active, over');
    setValue(state);
    // if (active.id !== over?.id) {
    //   const list = ((Object?.values(_value.current) ?? []) as any)?.toSorted(
    //     (a: any, b: any) => {
    //       return a?.sortNumber - b.sortNumber;
    //     },
    //   );
    //   const activeIndex = list.findIndex((record) => record.id === active?.id);
    //   const overIndex = list.findIndex((record) => record.id === over?.id);
    //   if (func?.(list[activeIndex]) && func?.(list[overIndex])) {
    //     const newly: any = arrayMove(list, activeIndex, overIndex);
    //     const state = Object.fromEntries(
    //       newly?.map((item, index) => [
    //         item?.id,
    //         {
    //           ...item,
    //           sortNumber: index + 1,
    //         },
    //       ]),
    //     );
    //     console.log(res, list, activeIndex, state, 'active, over1');

    //     setValue(state);
    //   }
    // }
  };

  const onRemove = async (record) => {
    if (typeof record?.id === 'string' && record?.id.includes('create')) {
      vsf?.refs.executionOrderList?.remove(record);
      return;
    }
    const res =
      await vsf?.services?.ClinicRecordOrderExtensionController_saveLabelSettingMaster_6a1c2d?.(
        {
          eo: {
            deleteList: [{ id: record?.id }],
          },
        },
      );

    if (res?.code === 200) {
      message.info('删除成功');
      onFetch();
    }
  };
  console.log(value, Object?.values(value), 'dictdictdict');

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: 'var(--background)',
            padding: '24px 24px 0',
          },
        ],
      }}
    >
      <Block
        title="医嘱执行单配置"
        footer={<Footer />}
        scroll={false}
        style={{
          padding: 0,
        }}
      >
        <Container
          layout={{
            items: [
              {
                block: '45%',
                gap: '24px',
              },
              {
                block: 1,
              },
            ],
          }}
        >
          <Block
            style={{
              padding: 0,
            }}
            scroll={false}
          >
            <SearchContainer.Header
              title={
                <div className="aspirin-search-custom-box">
                  执行单列表
                  <Button
                    type="primary"
                    onClick={() => {
                      deploy?.current?.add({
                        id: 'create_' + Math?.random(),
                        sortNumber: Object?.values(_value?.current ?? {})
                          ?.length,
                      });
                      // execution-order-list
                      setTimeout(() => {
                        const table = document.querySelector(
                          `[data-id="${id}"] .ant-table-body`,
                        );
                        table?.scrollTo(0, table?.scrollHeight);
                      }, 100);
                    }}
                  >
                    新增
                  </Button>
                </div>
              }
              style={{
                paddingBottom: 20,
              }}
            />
            <VSTable
              data-id={id}
              editable={{
                editType: 'multiple',
                columnProps: {
                  width: 50,
                },
                onCanDelete: () => false,
                onCanEdit: (record) => {
                  /**
                   * 判断当前工作站是否为病区
                   */
                  return (
                    currentApplication?.department?.clinicAttribute !== '2' ||
                    record?.ward ||
                    String(record?.id)?.startsWith('create')
                  );
                },
                extraOperations: [
                  {
                    children: (record) => {
                      return currentApplication?.department?.clinicAttribute !==
                        '2' ||
                        record?.ward ||
                        String(record?.id)?.startsWith('create') ? (
                        <Icon type="icon-youdishanchu1" size={24} />
                      ) : (
                        <Icon type="icon-a-shanchujinyong" size={24} />
                      );
                    },
                    disabled: (record) =>
                      !(
                        currentApplication?.department?.clinicAttribute !==
                          '2' ||
                        record?.ward ||
                        String(record?.id)?.startsWith('create')
                      ),
                    onClick: (...[, record]) => {
                      Tip({
                        content: `确定删除 {${record?.labelName ?? ''}}`,
                        onOk() {
                          delete value[record?.id];
                          _value.current = value;
                          _setValue(value);
                          onRemove(record);
                        },
                      });
                    },
                  },
                ],
              }}
              ref={deploy}
              value={[
                ...((Object?.values(value) ?? []) as any)?.toSorted(
                  (a: any, b: any) => {
                    return a?.sortNumber - b.sortNumber;
                  },
                ),
              ]?.reduce((prev, cur) => {
                if (
                  cur.sortNumber == -1 ||
                  !prev.some((item) => item.sortNumber == cur.sortNumber)
                ) {
                  prev.push(cur);
                }
                return prev;
              }, [])}
              pagination={false}
              onChange={onChange}
              onRow={(value) => ({
                onClick: () => {
                  click?.onChange?.(value);
                },
              })}
              scroll={{
                y: getScrollY(400),
              }}
              // dragSort={{
              //   dragSortDataIndex: 'sort',
              //   onDragSortEnd: (info) => {
              //     setValue(
              //       Object.fromEntries(
              //         info?.map((item, index) => [
              //           item?.id,
              //           {
              //             ...item,
              //             sortNumber: index + 1,
              //           },
              //         ]),
              //       ),
              //     );
              //   },
              // }}
              components={{
                body: {
                  row: Row,
                },
              }}
              className="aspirin-table-no-background-box"
              rowClassName={(record) =>
                record?.id === click?.value?.id
                  ? 'aspirin-row-selected-box'
                  : 'aspirin-row-select-box'
              }
              bordered
              tableViewRender={(...[, element]) => {
                const dataSource = (
                  (Object?.values(_value?.current) ?? []) as any
                )?.toSorted((a: any, b: any) => {
                  return a?.sortNumber - b.sortNumber;
                });
                return (
                  <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                  >
                    <SortableContext
                      items={dataSource.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {element}
                    </SortableContext>
                  </DndContext>
                );
              }}
              id="executionOrderList"
            >
              <VSTableColumn
                dataIndex={['sort']}
                title="排序"
                fieldProps={{}}
                width={50}
                editable={false}
                render={(element) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {element}
                  </div>
                )}
                renderFormItem={(element) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {element}
                  </div>
                )}
              />
              <VSTableColumn
                dataIndex={['sortNumber']}
                // dataIndex={['id']}
                title="排序号"
                valueType="index"
                render={(element) => (
                  <Flex justify="center" align="center">
                    {element}
                  </Flex>
                )}
                width={60}
                editable={false}
              />

              <VSTableColumn
                dataIndex={['labelName']}
                title="执行单名称"
                valueType="text"
                fieldProps={(record) => {
                  return {
                    onFocus: () => {
                      click?.onChange?.(record);
                    },
                  };
                }}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              />

              <VSTableColumn
                dataIndex={['ward', 'departmentName']}
                title="应用护理单元"
                valueType="text"
                render={(...[, record]) => {
                  if (
                    currentApplication?.department?.clinicAttribute === '2' &&
                    (record?.ward || String(record?.id)?.startsWith('create'))
                  ) {
                    return (
                      record?.ward?.departmentName ??
                      currentApplication?.department?.departmentName
                    );
                  } else {
                    return '全护理单元通用';
                  }
                }}
                editable={false}
                fieldProps={(record) => {
                  return {
                    onFocus: () => {
                      click?.onChange?.(record);
                    },
                  };
                }}
              />

              <VSTableColumn
                dataIndex={['wardId']}
                title="主键"
                valueType="digit"
                fieldProps={{}}
                hideInTable
              />

              <VSTableColumn
                dataIndex={['defaultShowIndicator']}
                title="默认显示"
                valueType="check"
                fieldProps={{
                  style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
                width={75}
              />

              <VSTableColumn
                dataIndex={['pdaPerformType']}
                title="执行方式"
                valueType="select"
                dataSource={[
                  { label: '扫码执行', value: 'BARCODE' },
                  { label: '手动执行', value: 'MANUAL' },
                  { label: '手动和扫码执行', value: 'BARCODE_MANUAL' },
                ]}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                fieldProps={(record) => {
                  return {
                    onFocus: () => {
                      click?.onChange?.(record);
                    },
                    showSearch: true,
                  };
                }}
              />
            </VSTable>
          </Block>
          <Other ref={otherRef} getValue={getValue} setValue={setValue} />
        </Container>
      </Block>
    </Container>
  );
};
export default definePage(Deploy);
