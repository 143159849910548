import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamOrderController_getExamItemClassBase_ca7a63: {
    method: 'post',
    url: '/api/exam/get-exam-item-class-base',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  ExamOrderController_getExamItemClassByParentClassId_09c596: {
    method: 'post',
    url: '/api/exam/get-exam-item-class-by-parent-class-id',
    parameterFomatter: (data?: { parentClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamClassDictionaryDtoExamManagerDto[]) =>
      data,
  },
  ClinicItemController_allClinicItemDictionaryCharge_d97a65: {
    method: 'post',
    url: '/api/clinic-item-base/all-clinic-item-dictionary-charge',
    parameterFomatter: (data?: {
      qto: ClinicItemDictionaryQtoSimpleClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
});
