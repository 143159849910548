import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderDrugController_getAdjuvantHerb_7d386c: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-adjuvant-herb',
    parameterFomatter: (data?: { storageId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo[],
    ) => data,
  },
});
