import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpAdmissionController_getInpInpAdmissionByClinicInpAdmissionQto_ea6e57:
    {
      method: 'post',
      url: '/api/clinic-inp-admission/get-inp-inp-admission-by-clinic-inp-admission-qto',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: InpInpAdmissionVoClinicInpAdmissionEntranceWebVo,
      ) => data,
    },
  ClinicInpAdmissionController_saveInpInpAdmission_a469d6: {
    method: 'post',
    url: '/api/clinic-inp-admission/save-inp-inp-admission',
    parameterFomatter: (data?: {
      saveInpInpAdmissionApplyBto: SaveInpInpAdmissionApplyBtoClinicInpAdmissionServiceBto;
      saveInpVisitForAdmissionBto: SaveInpVisitForAdmissionBtoClinicInpVisitServiceBto;
      updatePatientBto: UpdatePatientBtoPatientMainServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058: {
    method: 'post',
    url: '/api/organization/get-paged-by-department-id-and-search-code',
    parameterFomatter: (data?: { searchCode: string; departmentId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpVisitController_getTransferPatientInfoVoByDisplayId_2c5dc2: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-transfer-patient-info-vo-by-display-id',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: TransferPatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  BaseCommonController_queryPagedBySearchDiagnosisDictionaryQto_837a98: {
    method: 'post',
    url: '/api/base-common/query-paged-by-search-diagnosis-dictionary-qto',
    parameterFomatter: (data?: {
      qto: SearchDiagnosisDictionaryQtoBaseCommonPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryVoClinicInpAdmissionEntranceWebVoDiagnosisshowvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
