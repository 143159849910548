import './index.less';

import {
  Cascader,
  CascaderProps,
  Col,
  compose,
  DataType,
  Input,
  InputProps,
  Row,
  RowProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { useMemo } from 'react';

import getData from './getData';

export type AddressOptions = {
  /** 自定义类型字段 */
  code?: string;
  name?: string;
  children?: AddressOptions[];
};

export type AddressValue = {
  /** 自定义类型字段 */
  province?: string;
  city?: string;
  district?: string;
  detail?: string;
  county?: string;
};

export type AddressProps = {
  /**
   * 默认值
   */
  defaultValue?: AddressValue;
  /**
   * 值
   */
  value?: AddressValue;
  /**
   * 值变化回调
   */
  onChange?: (value?: AddressValue) => void;
  /**
   *  Cascader属性
   */
  cascaderProps: CascaderProps | DataType[];
  cascaderStyle: any;
  /**
   * Input属性
   */
  inputProps: InputProps;
  /**
   * 是否需要详细地址
   */
  showDetail?: boolean;
  /**
   * 占位文案
   */
  placeholder?: {
    cascader?: string;
    input?: string;
  };
};

const data: AddressOptions[] = getData();

/**
 * Address
 */
const Address = (props: AddressProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    cascaderProps,
    inputProps,
    showDetail = false,
    placeholder: placeholderProp,
    cascaderStyle,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const placeholder: AddressProps['placeholder'] =
    typeof placeholderProp === 'object' ? placeholderProp : undefined;

  const handelChange = (v) => {
    setValue({
      ...value,
      province: v?.[0]?.name,
      city: v?.[1]?.name,
      district: v?.[2]?.name,
      county: v?.[2]?.name,
    });
  };

  const handleInputChange = (v: string) => {
    setValue({ ...value, detail: v });
  };

  const filter = (inputValue, path) =>
    path.some((option) => option.label?.indexOf(inputValue) > -1);

  const formatValue = useMemo(() => {
    // console.log(value, 'format');

    if (value?.county) {
      return [value?.province, value?.city, value?.district ?? value?.county];
    }
    return [];
  }, [value]);

  const cascader = (
    <Cascader
      className="cascader_wrap"
      style={{
        ...cascaderStyle,
      }}
      placeholder={placeholder?.cascader || '请选择省市区'}
      fieldNames={{
        label: 'name',
        value: 'name',
        children: 'children',
      }}
      dataSource={data}
      showSearch={{ filter }}
      value={formatValue}
      onChange={handelChange}
      {...cascaderProps}
    />
  );

  if (!showDetail) {
    return <div {...rest}>{cascader}</div>;
  }

  return (
    <>
      <Row justify="start" wrap={false} {...rest}>
        <Col span={8}>{cascader}</Col>
        <Col span={16}>
          <Input
            placeholder={placeholder?.input || '请输入详细地址'}
            value={value?.detail}
            onChange={(e) => handleInputChange(e.target.value)}
            {...inputProps}
          />
        </Col>
      </Row>
    </>
  );
};
Address.displayName = 'Address';

export default compose(
  withField<AddressProps>({
    name: 'Address',
  }),
  withPreview<AddressProps>({
    renderPreview: (props) => {
      const { value, showDetail } = props;
      /** 返回预览模式下的dom */

      return (
        <div>
          <span>
            {value?.province}
            {value?.province ? '-' : ''}
            {value?.city}
            {value?.city ? '-' : ''}
            {value?.district ?? value?.county}
          </span>
          {showDetail && value?.detail && ' ' + value?.detail}
        </div>
      );
    },
  }),
)(Address);
