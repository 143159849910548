import { createServices } from '@vs/vsf-boot';
export default createServices({
  GreenChannelAuditRecordController_getGreenChannelAuditRecord_b17a1c: {
    method: 'post',
    url: '/api/finance-billing-record/get-green-channel-audit-record',
    parameterFomatter: (data?: {
      eo: QueryGreenChannelAuditRecordEoFinanceBillingRecordPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: GreenChannelAuditRecordErpInpDataVoFinanceBillingRecordEntranceWebVo[],
    ) => data,
  },
  GreenChannelAuditRecordController_createGreenChannelAuditRecord_9679a1: {
    method: 'post',
    url: '/api/finance-billing-record/create-green-channel-audit-record',
    parameterFomatter: (data?: {
      btoParam: CreateGreenChannelAuditRecordBtoFinanceBillingRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: GreenChannelAuditRecordErpInpDataVoFinanceBillingRecordEntranceWebVo,
    ) => data,
  },
  ClinicErpVisitController_queryByErpVisitPatientQto_c66198: {
    method: 'post',
    url: '/api/clinic-erp-visit/query-by-erp-visit-patient-qto',
    parameterFomatter: (data?: {
      qto: ErpVisitPatientQtoClinicErpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpVisitPatientVoClinicErpVisitEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitController_queryByInpVisitHospitalWithPatientQto_ab5a9c: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-by-inp-visit-hospital-with-patient-qto',
    parameterFomatter: (data?: {
      qto: InpVisitHospitalWithPatientQtoClinicInpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitHospitalWithPatientVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
});
