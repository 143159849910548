import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_queryWaitDispenseDrugDetailList_aed110: {
    method: 'post',
    url: '/api/drug-prescription-dispense/query-wait-dispense-drug-detail-list',
    parameterFomatter: (data?: {
      qto: InpWaitDispenseDetailQtoDrugPrescriptionDispensePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InpWaitDispenseDetailVoDrugPrescriptionDispenseEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugPrescriptionDispenseController_queryBedDropListQto_4de602: {
    method: 'post',
    url: '/api/drug-prescription-dispense/query-all-by-bed-drop-list-qto',
    parameterFomatter: (data?: {
      qto: BedDropListQtoDrugPrescriptionDispensePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BedDropVoDrugPrescriptionDispenseEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugPrescriptionDispenseController_dispensingInpPrescriptions_ce193f: {
    method: 'post',
    url: '/api/drug-prescription-dispense/dispensing-inp-prescriptions',
    parameterFomatter: (data?: {
      dispensingParam: DispensingPrescriptionEoDrugPrescriptionDispensePersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce:
    {
      method: 'post',
      url: '/api/application-privilege/get-current-application-vs-staff-with-staff-vo',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffWithStaffVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
});
