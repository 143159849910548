import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  Modal,
  VSFormLayout,
  message,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { askSearch } from '@/pages/Index/components/func/ask';

const NewForm = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const getDataSource = async () => {
    const res =
      await vsf?.services?.BaseCodeDtoServiceController_getValidCodeList_3cb2b9?.(
        { codeType: 'RECK_ITEM_CLASS_DICT' },
      );
    setDataSource(res.data);
  };

  useEffect(() => {
    switch (props.value?.title) {
      case 'add':
        setTimeout(() => {
          vsf.refs?.PriceClass?.setFieldValue('parentCode', {
            label: props.value.name,
            value: props.value.code,
          });
        }, 200);
        break;
      case 'update':
        vsf.services
          .PriceListDtoController_getPriceItemCategory_77dde8({
            id: props.value.id,
          })
          .then((res) => {
            if (res.code === 200) {
              vsf.refs.PriceClass.setFieldsValue(res.data);
            }
          });
        break;
    }
    getDataSource();
  }, [props?.value]);

  return (
    <VSControlledForm vsid="11489" id="PriceClass" labelAlign="left">
      <VSFormItem
        name={['id']}
        label="id"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormLayout columnCount={2} gutter={50} labelWidth={'auto'}>
        <VSFormItem
          name={['code']}
          label="编码"
          valueType="text"
          rules={[
            { message: 'code长度不合法', type: 'string', min: 0, max: 20 },
            {
              required: true,
            },
            () => ({
              validator(_, value) {
                if (props.value?.title === 'update') {
                  return Promise.resolve();
                }
                if (
                  !value.trim() ||
                  !dataSource.map((item) => item.code).includes(value.trim())
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('编码已经存在'));
              },
            }),
          ]}
          fieldProps={{
            max: 20,
            disabled: props.value?.title === 'update',
          }}
        />

        <VSFormItem
          name={['codeName']}
          label="编码名称"
          valueType="text"
          rules={[
            {
              message: '编码类别长度不合法',
              type: 'string',
              min: 0,
              max: 1024,
            },
            { required: true },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['parentCode']}
          label="上级编码"
          valueType="custom"
          transform={(value) => ({
            parentCode: value?.value ?? value,
          })}
        >
          <Select
            options={dataSource
              .filter((item) => !item.parentCode)
              .map((item) => ({
                label: item.name,
                value: item.code,
              }))}
            disabled={
              props.value?.title === 'update' && props?.value?.treeLevel === 0
            }
            placeholder={
              props.value?.title === 'update' && props?.value?.treeLevel === 0
                ? ''
                : '请选择上级编码'
            }
            filterOption={askSearch}
            width={'100%'}
            showSearch
            allowClear
          />
        </VSFormItem>
        <VSFormItem
          name={['ext', 'billItem']}
          label="价表项目"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
            placeholder="请选择价表项目"
            filterOption={askSearch}
            width={'100%'}
            showSearch
          />
        </VSFormItem>

        <VSFormItem
          name={['ext', 'inpRcptFee']}
          label="住院收费票据"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT}
            placeholder="请选择住院收费票据"
            filterOption={askSearch}
            width={'100%'}
            showSearch
          />
        </VSFormItem>

        <VSFormItem
          name={['ext', 'outpRcptFee']}
          label="门诊收费票据"
          valueType="custom"
          rules={[{ required: true }]}
          fieldProps={{
            showSearch: true,
          }}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT}
            placeholder="请选择会计科目"
            filterOption={askSearch}
            width={'100%'}
            showSearch
          />
        </VSFormItem>
        <VSFormItem
          name={['ext', 'tallySubject']}
          label="会计科目"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.TALLY_SUBJECT_DICT}
            placeholder="请选择会计科目"
            filterOption={askSearch}
            width={'100%'}
            showSearch
          />
        </VSFormItem>

        <VSFormItem
          name={['ext', 'bonusCalculation']}
          label="奖金核算"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.BONUS_CALCULATION_DICT}
            placeholder="请选择奖金核算"
            filterOption={askSearch}
            width={'100%'}
            showSearch
          />
        </VSFormItem>

        <VSFormItem
          name={['ext', 'costKeeping']}
          label="成本核算"
          valueType="custom"
          rules={[{ required: true }]}
        >
          <Select
            options={vsf.stores.dbenums?.enums?.COST_KEEPING_DICT}
            placeholder="请选择成本核算"
            filterOption={(value, option) => {
              return askSearch(value, option);
            }}
            width={'100%'}
            showSearch
          />
        </VSFormItem>
      </VSFormLayout>
    </VSControlledForm>
  );
};

const PriceItemEdit = (props) => {
  const onSubmit = async () => {
    vsf.refs.PriceClass.validateFieldsReturnFormatValue().then(async () => {
      const funcName =
        props.value?.title === 'update'
          ? 'PriceListBOController_updatePriceItemCategory_f52851'
          : 'PriceListBOController_createPriceItemCategory_337e94';
      const priceItemCategoryEo = vsf.refs.PriceClass.getFieldsValue();
      const res = await vsf?.services?.[funcName]?.({
        priceItemCategoryEo: {
          ...vsf.refs.PriceClass.getFieldsValue(),
          parentCode:
            priceItemCategoryEo?.parentCode?.value ??
            priceItemCategoryEo?.parentCode,
        },
      });
      if (res.code === 200) {
        message.success('保存分类成功');
        props.handleCloseWithReload();
      }
    });
  };
  return (
    <Modal
      open={props.open}
      title={`${props.value?.title === 'update' ? '编辑' : '新增'}价格项目分类`}
      className="aspirin-linkpage aspirin-linkpage-static-height"
      onOk={onSubmit}
      onCancel={props.handleClose}
      width="40%"
      okText="确定"
      cancelText="取消"
    >
      <NewForm {...props} />
    </Modal>
  );
};
export default definePage(PriceItemEdit);
