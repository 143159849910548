/* eslint-disable react-hooks/exhaustive-deps */
import './index.less';

import { definePage } from '@vs/vsf-boot';
import { useUpdateEffect } from 'ahooks';
import React, { useContext, useEffect, useRef } from 'react';

import { getPrefixCls } from '@/config';
import Block from '@/module/Block';
import KeyEvent from '@/module/Keyevent';
import { isSpecialKey } from '@/module/Keyevent/utils';
import ErInfo from '@/pages/Index/cpoe/center/header/Info/er_info';
import InInfo from '@/pages/Index/cpoe/center/header/Info/in_info';
import OutInfo from '@/pages/Index/cpoe/center/header/Info/out_info';

import { Screen } from '../../components';
import { CreateScreen } from '../../components/screen';
import Left from './left';
import Right from './right';

const Index = (props) => {
  const { user, settings, common } = props?.stores || {};
  const { shortcuts } = settings || {};
  const { outVisitInfo, hospitalType } = user || {};
  const centerCls = getPrefixCls('cpoe-center');
  console.log(hospitalType);

  const mainRef = useRef();
  // console.log(props?.routes?.query?.type, 'props');

  const getEvents = () => {
    const obj: any = {};
    const target = shortcuts ?? [];
    target?.forEach((item) => {
      if (item?.keys?.length >= 2 && isSpecialKey(item?.keys?.[0])) {
        const fc = () => {
          // console.log('no function');
        };
        obj[`on${item?.keys?.join('')}`] =
          mainRef?.current?.[`on${item?.key}`] ?? fc;
      }
    });
    return obj;
  };
  // const onScreen = useContext(CreateScreen);
  // useUpdateEffect(() => {
  //   onScreen(() => void 0);
  // }, [user?.hospitalType]);

  // console.log(props, 'data_props');

  // useEffect(() => {
  //   // 清空患者信息
  //   setTimeout(() => {
  //     user.clearPatientData();
  //   }, 1000);
  // }, []);
  // console.log(hospitalType, 'hospitalType');
  return (
    <KeyEvent
      key={shortcuts}
      events={getEvents()}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div className={centerCls}>
        {/* <Block height={150}> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {hospitalType == 'in' ? (
            <InInfo pageType={props?.routes?.query?.type} />
          ) : hospitalType == 'out' ? (
            <OutInfo pageType={props?.routes?.query?.type} />
          ) : hospitalType == 'er' ? (
            <ErInfo pageType={props?.routes?.query?.type} />
          ) : null}
        </div>
        {/* </Block> */}

        <div
          className="main-container"
          // todo 这个逻辑太片面了, 之后统一做处理吧
          // onClick={(event) => {
          //   if (outVisitInfo?.id) return;
          //   event.preventDefault(); // 阻止默认行为
          //   event.stopPropagation();
          //   message.info('请先选择患者后再进行操作');
          // }}
        >
          <Left
            pageType={props?.routes?.query?.type}
            prefixCls={centerCls}
            mainRef={mainRef}
          />
          {/* 患者旅程 */}
          <Right prefixCls={centerCls} />

          {/* <MenuList prefixCls={centerCls} /> */}
          {/* <Main prefixCls={centerCls} ref={mainRef} /> */}
        </div>

        {/* <div className="main-container">
          <MenuList prefixCls={centerCls} />
          <Main prefixCls={centerCls} ref={mainRef} />
        </div> */}
        {/* <div className="main-container"> */}
        {/* <FlexBlock
            style={{
              justifyContent: 'flex-start',
            }}
            gap={['16px', '16px']}
            colSpan={['1240px', '1412px', '608px']}
          >
            <Left prefixCls={centerCls} />
            <Main prefixCls={centerCls} ref={mainRef} />
            <Right prefixCls={centerCls} />
          </FlexBlock> */}
        {/* </div> */}
      </div>
    </KeyEvent>
  );
};

// const Main = definePage(Index);
// // eslint-disable-next-line react/display-name
// export default (props) => {
//   return (
//     <Screen>
//       <Main {...props} />
//     </Screen>
//   );
// };

export default definePage(Index);
