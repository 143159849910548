import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdmissionController_getPagefallBySearchApplyTestQto_9d44ce: {
    method: 'post',
    url: '/api/clinic-inp-admission/get-pagefall-by-search-apply-test-qto',
    parameterFomatter: (data?: {
      qto: SearchApplyTestQtoClinicInpAdmissionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ApplyListTestVoClinicInpAdmissionEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
