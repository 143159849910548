import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_getHerbWaitDispenseDetailByMasterId_48c935:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-herb-wait-dispense-detail-by-master-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionWaitDispenseMasterForDispenseDetailVoDrugPrescriptionDispenseEntranceWebVo,
      ) => data,
    },
  DrugPrescriptionDispenseController_dispensingInpHerbPrescriptions_cca5a4: {
    method: 'post',
    url: '/api/drug-prescription-dispense/dispensing-inp-herb-prescriptions',
    parameterFomatter: (data?: {
      dispensingParam: HerbDispensingPrescriptionEoDrugPrescriptionDispensePersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce:
    {
      method: 'post',
      url: '/api/application-privilege/get-current-application-vs-staff-with-staff-vo',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationVsStaffWithStaffVoApplicationPrivilegeEntranceWebVo[],
      ) => data,
    },
});
