import vsf, { defineStore } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import { mockOutpVisitEncounterId } from '@/mock';
import storage from '@/utils/storage';

import common from './common';
import cpoe from './cpoe';

const cdss = defineStore({
  // 登录成功之后调
  cdssLogin: async () => {
    await vsf?.services?.CdssController_cdssLogin_a9c7a3?.({
      loginCdssParamEo: {},
    });
  },
  // 保存过敏史成功之后调
  cdssAllergy: async (outVisitInfo) => {
    await vsf.services.CdssController_cdssAllergy_486d60({
      patientId: outVisitInfo?.patient?.id,
      outpVisitId: outVisitInfo?.outpVisit?.id,
      inpVisitId: outVisitInfo?.inpVisit?.id,
    });
  },
  // 门诊诊结成功之后调
  cdssVisit: async (outVisitInfo) => {
    await vsf.services.CdssController_cdssVisit_3de904({
      patientId: outVisitInfo?.patient?.id,
      outpVisitId: outVisitInfo?.outpVisit?.id,
    });
  },
  // 取消接诊成功之后调
  cdssCancelVisits: async (outVisitInfo) => {
    await vsf.services.CdssController_cdssCancelVisits_ba8b7c({
      outpVisitId: outVisitInfo?.outpVisit?.id,
    });
  },
  //   打开门诊病历
  cdssOpenTask: async (value) => {
    await vsf.services.CdssController_cdssOpenTask_2ba6c9({
      patientId: value?.patientId,
      outpVisitId: value?.outpVisitId,
      inpVisitId: value?.inpVisitId,
    });
  },
  //   医嘱每一行生成时
  cdssFocus: async (patientInfo, value) => {
    await vsf.services.CdssController_cdssFocus_f9f934({
      ...patientInfo,
      saveOrderEo: { ...value },
    });
  },
  // 医嘱提交强制执行/修改（关闭弹窗时）
  cdssOrderOperate: async (value) => {
    await vsf.services.CdssController_cdssOrderOperate_6f218b({
      ...value,
    });
  },
  // 医嘱提交之前
  cdssOrderResponse: async (value) => {
    const res = await vsf.services.CdssController_cdssOrderResponse_bf4f9f({
      ...value,
    });
    return res;
  },
  //  暂时不用
  cdssVitalSign: async (value) => {
    const res = await vsf.services.CdssController_cdssVitalSign_7e85ec({
      patientId: value?.patientId,
    });
    return res;
  },
});

export default cdss;
