import { createServices } from '@vs/vsf-boot';
export default createServices({
  ChargeSolutionDtoController_getChargeSolutionList_114af0: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-list',
    parameterFomatter: (data?: {
      qto: ChargeSolutionQtoFinanceBillingSettingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionVoFinanceBillingSettingEntranceWebVo[],
    ) => data,
  },
  ChargeSolutionPatientListDtoController_getChargeSolutionPatientById_f59b29: {
    method: 'post',
    url: '/api/finance-billing-setting/get-charge-solution-patient-by-id',
    parameterFomatter: (data?: { chargeSolutionPatientListId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeSolutionPatientListVoFinanceBillingSettingEntranceWebVo,
    ) => data,
  },
  ChargeSolutionPatientListBOController_createChargeSolutionPatient_7f550b: {
    method: 'post',
    url: '/api/finance-billing-setting/create-charge-solution-patient',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateChargeSolutionPatientListBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionPatientListBOController_updateChargeSolutionPatient_a97869: {
    method: 'post',
    url: '/api/finance-billing-setting/update-charge-solution-patient',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateChargeSolutionPatientListBtoFinanceBillingSettingServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ChargeSolutionPatientListBOController_updateChargeSolutionPatientStopIndicator_d47602:
    {
      method: 'post',
      url: '/api/finance-billing-setting/update-charge-solution-patient-stop-indicator',
      parameterFomatter: (data?: { chargeSolutionPatientId: number }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  ChargeSolutionPatientListDtoController_queryChargeSolutionPatientPage_d68140:
    {
      method: 'post',
      url: '/api/finance-billing-setting/query-charge-solution-patient-page',
      parameterFomatter: (data?: {
        qto: ChargeSolutionPatientListQtoFinanceBillingSettingPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ChargeSolutionPatientListVoFinanceBillingSettingEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  PatientMainController_getPatientByIdNumber_7b5fb1: {
    method: 'post',
    url: '/api/patient-main/get-patient-by-id-number',
    parameterFomatter: (data?: { idNumber: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientVoFinanceBillingSettingEntranceWebVoChargesolutionpatientlistvo[],
    ) => data,
  },
});
