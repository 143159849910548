import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPathologyController_queryOrderPathologyDetailLabelPrintVoByCondition_4f8306:
    {
      method: 'post',
      url: '/api/clinic-record-order-pathology/query-order-pathology-detail-label-print-vo-by-condition',
      parameterFomatter: (data?: {
        printStatus: boolean;
        printDateTime: string;
        wardId: number;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderPathologyDetailLabelPrintVoClinicRecordOrderPathologyEntranceWebVo[],
      ) => data,
    },
});
