import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamController_getAllByExamClassItemClassNewQto_006cfc: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-item-class-new-qto',
    parameterFomatter: (data?: {
      qto: ExamClassItemClassNewQtoExamPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  PatientJourneyController_getPatientJourney_95f0ff: {
    method: 'post',
    url: '/api/clinic-record-order/get-patient-journey',
    parameterFomatter: (data?: {
      qto: PatientJourneyOrderNewQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientJourneyVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
