import React, { useEffect } from 'react';
import './index.less';
import {
  Col,
  Row,
  VSForm,
  VSFormItem,
  VSFormLayout,
  Divider,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { getSimpleMedicalRecordById } from './Servce';
function patientHome(props) {
  const { data, run } = useRequest(getSimpleMedicalRecordById, {
    manual: true,
  });
  useEffect(() => run(props), [props]);
  useEffect(
    () => data && vsf?.refs?.inpSettleMedicalRecord?.setFieldsValue(data),
    // () => console.log(data),
    [data],
  );
  return (
    <div className="hos-settlement-home">
      <VSForm vsid="52502" id="inpSettleMedicalRecord">
        <VSFormLayout key="0" columnCount={4}>
          <VSFormItem
            readonly
            name={['displayId']}
            label="患者ID"
            valueType="text"
            rules={[
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['name']}
            label="姓名"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['gender']}
            label="性别"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
          />

          <VSFormItem
            readonly
            name={['age']}
            label="年龄"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="1" columnCount={4}>
          <VSFormItem
            readonly
            name={['birthday']}
            label="出生日期"
            valueType="date"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['birthAddress']}
            label="出生地"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['idNumber']}
            label="身份证号"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['citizenship']}
            label="国籍"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.COUNTRY_DICT}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={4}>
          <VSFormItem
            readonly
            name={['nation']}
            label="民族"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.NATION_DICT}
          />

          <VSFormItem
            readonly
            name={['job']}
            label="职业"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.OCCUPATION_DICT}
          />

          <VSFormItem
            readonly
            name={['marriageStatus']}
            label="婚姻"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.MARITAL_STATUS_DICT}
          />

          <VSFormItem
            readonly
            name={['phoneNumber']}
            label="联系电话"
            valueType="text"
            rules={[
              {
                message: '联系电话长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="3" columnCount={4}>
          <VSFormItem
            readonly
            name={['cellphoneNumber']}
            label="手机号"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['chargeType']}
            label="费别"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
          />

          <VSFormItem
            readonly
            name={['identityCode']}
            label="人员类别"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
          />

          <VSFormItem
            readonly
            name={['nativeAddress']}
            label="籍贯"
            valueType="custom"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="4" columnCount={4}>
          <VSFormItem
            readonly
            name={['currentAddress']}
            label="现住地址"
            valueType="custom"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['registeredResidenceAddress']}
            label="户口地址"
            valueType="custom"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['securityType']}
            label="医保类型"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['securityNumber']}
            label="医保帐号"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={4}>
          <VSFormItem
            readonly
            name={['unitInContract']}
            label="合同单位"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 11 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['workUnitPhoneNumber']}
            label="单位电话"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['medicalType']}
            label="医疗类别"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['insuranceDisease']}
            label="病种列表"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="6" columnCount={4}>
          <VSFormItem
            readonly
            name={['workUnit']}
            label="工作单位"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['workStatus']}
            label="在职状态"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.JOB_STATE_DICT}
          />

          <VSFormItem
            readonly
            name={['workUnitAddress']}
            label="单位地址"
            valueType="custom"
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['workUnitPostcode']}
            label="单位地址邮编"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 6 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="7" columnCount={4}>
          <VSFormItem
            readonly
            name={['contactPersonName']}
            label="联系人"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['contactPersonRelationship']}
            label="关系"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.RELATIONSHIP_DICT}
          />

          <VSFormItem
            readonly
            name={['contactPersonPhone']}
            label="联系人电话"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['contactPersonAddress']}
            label="联系人住址"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="8" columnCount={4}>
          <VSFormItem
            readonly
            name={['patientClass']}
            label="入院方式"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.PATIENT_CLASS_DICT}
          />

          <VSFormItem
            readonly
            name={['patientAdmissionCondition']}
            label="入院病情"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.ADMISSION_CONDITION_DICT}
          />

          <VSFormItem
            readonly
            name={['admissionPurpose']}
            label="住院目的"
            valueType="select"
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT,
            }}
            dataSource={vsf.stores.dbenums?.enums?.ADMISSION_CAUSE_DICT}
          />

          <VSFormItem
            readonly
            name={['admissionDateTime']}
            label="入院日期"
            valueType="date"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="9" columnCount={4}>
          <VSFormItem
            readonly
            name={['wardDepartmentName']}
            label="病区名称"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['admissionDepartmentName']}
            label="入院科室"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['encounterDoctorName']}
            label="接诊医生"
            valueType="text"
            rules={[
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            readonly
            name={['visitStartDate']}
            label="接诊时间"
            valueType="date"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="10" columnCount={1}>
          <VSFormItem
            readonly
            name={['dischargeDiagnosisDescription']}
            label="出院诊断"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSForm>
    </div>
  );
}

export default patientHome;
