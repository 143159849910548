import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal } from '@vs/vsf-kit';
import React, { Fragment, useState } from 'react';
import PriceItemCategoryTree from './tree';
import PriceListTable from './table';
import ModalEdit from './itemEdit';
import Container from '@/module/Container';
const PriceListManage = (props) => {
  /**
   * DataList
   * @type {any}
   */
  const [dataList, setDataList] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [addNode, setAddNode] = useState(false);

  const handleAdd = (node) => {
    setAddNode({
      title: 'add',
      ...node,
    });
    setAddOpen(true);
  };

  const handleEdit = (node) => {
    setAddNode({
      title: 'update',
      ...node,
    });
    setAddOpen(true);
  };

  const handleCloseWithReload = () => {
    vsf?.refs?.PriceTree?.handleReload();
    handleClose();
  };

  const handleClose = () => {
    setAddOpen(false);
  };
  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
            },
          ],
        }}
      >
        <PriceItemCategoryTree
          onChange={(_value) => {
            setDataList(_value);
          }}
          onAdd={handleAdd}
          onEdit={handleEdit}
        />
        <PriceListTable value={dataList} />
      </Container>
      <ModalEdit
        open={addOpen}
        value={addNode}
        handleClose={handleClose}
        handleCloseWithReload={handleCloseWithReload}
      />
    </Fragment>
  );
};
export default definePage(PriceListManage);
