import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getById_3da0f7: {
    method: 'post',
    url: '/api/organization/getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  ClinicBedConfigController_queryByBedClassVsChargeQtoPaged_dae380: {
    method: 'post',
    url: '/api/clinic-bed-config/query-by-bed-class-vs-charge-qto-paged',
    parameterFomatter: (data?: {
      qto: BedClassVsChargeQtoClinicBedPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BedClassVsChargeDetailVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getClinicBedDetailVoById_2c9af4: {
    method: 'post',
    url: '/api/clinic-bed/get-clinic-bed-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicBedDetailVoClinicBedEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27: {
    method: 'post',
    url: '/api/base-dictionary/query-all-by-base-code-index-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeIndexQtoBaseDictionaryPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeVoBaseDictionaryEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
