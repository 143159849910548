import Block from '@/module/Block';
import { getScrollYRem, labelColWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSTableColumn,
  VSPromiseTable,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import Icon from '@/module/Icon';

import './index.less';

const NotSupplyOrLimit = (props) => {
  const { drugInfo, warnTitle } = props;

  const onFetch = useCallback(
    async (params) => {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      if (drugInfo?.drugProductId) {
        const res =
          await vsf.services?.DrugStockSupplyController_getOtherStockAmount_8737b8?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                storageId: params?.extra?.storageId,
                drugProductId: drugInfo?.drugProductId,
                packageSpecification: drugInfo?.packageSpecification,
                orderList: params.orderList,
                ...params.search,
                ...params.filters,
                ...(params?.extra ?? {}),
                ...(params ?? {}),
              },
            },
          ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
        // TODO: 你需要自己补充入参input来源及出参output用法
        return {
          data: (res?.data?.result ?? res?.data ?? []).map((m) =>
            convertGetOtherStockAmountRes2VSPromiseTable251331Ro(m),
          ),
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [drugInfo],
  );
  /**
   * convertGetOtherStockAmountRes2VSPromiseTable251331Ro
   * @param {any} input
   * @returns {any}
   */
  const convertGetOtherStockAmountRes2VSPromiseTable251331Ro = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.storageName = _.get(input, 'storage.storageName');
    output.amount = _.get(input, 'amount');
    return output;
  };
  return (
    <Block style={{ padding: '0' }} className="not_supply_or_limit_block">
      <div className="warn_info">
        <div className="icon">
          <Icon type="icon-jingshi" size={16} />
        </div>
        <span>{warnTitle}</span>
      </div>
      <VSControlledForm
        vsid="61830"
        id="vSControlledForm618301"
        value={props.value}
      >
        <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[6]}>
          <VSFormItem
            labelAlign="left"
            name={['reason']}
            label="理由"
            valueType="select"
            rules={[{ required: true, message: '请选择理由' }]}
            fieldProps={{
              showSearch: true,
              dataSource: vsf.stores.dbenums.enums.DRUG_UNSUPPORT_REASON_DICT,
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="1" columnCount={1} labelCol={labelColWidth[6]}>
          <VSFormItem
            labelAlign="left"
            name={['memo']}
            label="备注"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={1}>
          <VSFormItem labelAlign="left" label="其他库房库存" valueType="custom">
            <VSPromiseTable
              scroll={{ y: '13.6rem' }}
              onFetch={onFetch}
              id="vSPromiseTable251331"
              vsid="25133"
              extraParams={props.value}
            >
              <VSTableColumn
                width="3rem"
                dataIndex={['storageName']}
                title="库房名称"
                editable={false}
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 40,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="2rem"
                dataIndex={['amount']}
                title="库存"
                editable={false}
                valueType="digit"
                formItemProps={{
                  rules: [
                    {
                      message: '数量的值不合法',
                      type: 'number',
                      min: -100000,
                      max: undefined,
                    },
                  ],
                }}
                fieldProps={{}}
              />
            </VSPromiseTable>
          </VSFormItem>
        </VSFormLayout>
      </VSControlledForm>
    </Block>
  );
};
export default definePage(NotSupplyOrLimit);
