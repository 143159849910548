import 'dayjs/locale/zh-cn';

import vsf from '@vs/vsf-boot';
import { Badge, Card, DatePicker, RadioGroup, Select } from '@vs/vsf-kit';
import { useAsyncEffect, useGetState, useRequest } from 'ahooks';
import { Timeline } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import Container from '@/module/Container';
import { AsyncSuspense } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import OutHospitalHistoryImported from '@/pages/Index/components/order/out_patient/assist/history';
import { convertOrderInTable } from '@/pages/Index/components/order/out_patient/table/convert';
import OrderTable, {
  OrderTableRef,
} from '@/pages/Index/components/order_table';
import { user } from '@/stores';
import storage from '@/utils/storage';

type SelectValue = {
  id: number;
};

const DefendHistory = forwardRef((props, ref) => {
  const { getDoctorOrder } = user || {};
  const [active] = useOnChangeValue('');
  const [time] = useOnChangeValue<(string | number)[]>([]);
  const [radio] = useOnChangeValue(5);
  const [select] = useOnChangeValue<SelectValue[]>([]);
  const order = useRef<OrderTableRef>(null);
  const [data, setData, getData] = useGetState([]);

  const patientIdIs = storage?.get('treatInfo')?.patientProfileVo?.patientId;
  const onFetch = useCallback(async () => {
    const value = await getDoctorOrder({
      hospitalType: 'out',
      id: active?.value,
    });
    if (Array.isArray(value)) {
      order?.current?.setValue(convertOrderInTable(value));
    } else {
      order?.current?.setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active?.value]);

  const {
    data: dataSource,
    loading,
    run: getDataSource,
  } = useRequest(
    async () => {
      const res =
        await vsf.services?.OutpVisitEncounterController_queryOutpVisitEncounterHistory_06bf7b?.(
          {
            qto: {
              patientIdIs,
              from: 0,
              size: radio?.value,
              ...(time?.value?.length
                ? {
                    visitStartDateRangeIn: {
                      begin: (time?.value as any)[0]?.replaceAll('/', '-'),
                      end: (time?.value as any)[1]?.replaceAll('/', '-'),
                    },
                  }
                : {}),
              ...(select?.value?.length
                ? {
                    diagnosisIdIn: select?.value?.map((item) => item?.id),
                  }
                : {}),
            },
          },
        );
      return res?.data?.result;
    },
    {
      refreshDeps: [patientIdIs, time?.value, select?.value, radio?.value],
    },
  );

  useAsyncEffect(onFetch, [onFetch]);
  useAsyncEffect(async () => getDataSource(), [getDataSource]);
  const count = [
    {
      label: '5次',
      value: 5,
    },
    {
      label: '10次',
      value: 10,
    },
    {
      label: '15次',
      value: 15,
    },
    {
      label: '20次',
      value: 20,
    },
    {
      label: '全部',
      value: 1000,
    },
  ];

  /**
   * 获取临床特殊数据源
   */
  const getPageFallDiagnosisDictionary = useCallback(async (value) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          // 所选检查单类型的ID
          qto: {
            from: 0,
            size: 20,
            ...value,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      return res?.data?.result;
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      onSubmit: async () => ({
        data: getData()?.map((item: any) => ({
          ...item,
          id: 'create' + Math.random(),
          orderStatus: 'SAVE',
        })),
      }),
    };
  });

  return (
    <div className="aspirin-medical-order-template-defend-box">
      <nav
        style={{
          justifyContent: 'flex-start',
          gap: 20,
        }}
      >
        <DatePicker.RangePicker
          value={time?.value?.map((item) => dayjs(item)?.valueOf())}
          locale={locale}
          onChange={(value) => {
            if ((value as unknown as number[])?.includes(NaN)) {
              time?.onChange([]);
            } else {
              time?.onChange(
                value?.map((item) =>
                  dayjs(item)?.format('YYYY/MM/DD HH:mm:ss'),
                ),
              );
            }
          }}
        />
        <RadioGroup
          dataSource={count}
          optionType="button"
          buttonStyle="solid"
          {...radio}
        />
        <div className="aspirin-medical-order-template-defend-nav-select-box">
          <span>疾病筛选</span>
          <Select
            dataSource={getPageFallDiagnosisDictionary}
            showSearch
            width={300}
            searchKey="searchInputLike"
            placeholder="请选择要筛选的疾病"
            {...select}
            mode="multiple"
            fieldNames={{
              label: 'diagnosisName',
              value: 'id',
            }}
          />
        </div>
      </nav>
      <Container
        layout={{
          items: [
            {
              block: '20%',
              gap: '20px',
            },
          ],
        }}
        className="paddingTop24"
      >
        <div
          className="aspirin-medical-order-template-defend-left-grid-box"
          style={{
            overflowY: 'auto',
            paddingRight: 10,
          }}
        >
          <AsyncSuspense loading={loading} dataSource={dataSource?.length}>
            <Timeline>
              {dataSource?.map((item: any) => (
                <Timeline.Item key={item?.id}>
                  <Badge.Ribbon
                    text={item?.outpVisit?.clinicRegisterDefine?.clinicLabel}
                    color="green"
                  >
                    <Card
                      title={item?.visitStartDate}
                      size="small"
                      className={classNames(
                        'aspirin-medical-order-template-defend-card-box',
                        item?.id === active?.value ? 'active' : '',
                      )}
                      actions={[
                        item?.diagnosisRecordWithDiagnosisDtoList?.map(
                          (item) => item.diagnosis?.diagnosisName,
                        ),
                      ]}
                      onClick={() => {
                        active?.onChange(item?.id);
                      }}
                    >
                      {item?.visitDepartment?.departmentName}
                    </Card>
                  </Badge.Ribbon>
                </Timeline.Item>
              ))}
            </Timeline>
          </AsyncSuspense>
        </div>
        <div
          style={{
            background: '#F4F5F7',
            height: '100%',
            padding: '24px 0',
          }}
        >
          <OutHospitalHistoryImported
            ref={order}
            rowSelection={{
              selectedRows: data,
              onSelectedRowsChange: (value) => {
                setData(value as never[]);
              },
            }}
          />
        </div>
      </Container>
    </div>
  );
});

DefendHistory.displayName = 'DefendHistory';

export default DefendHistory;
