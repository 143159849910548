import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrganizationController_getPagefallByInstitutionListQto_48aa0c: {
    method: 'post',
    url: '/api/organization/get-pagefall-by-institution-list-qto',
    parameterFomatter: (data?: {
      qto: InstitutionListQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalInstitutionVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugImportExportController_getStorageDepartmentByInstitutionId_054093: {
    method: 'post',
    url: '/api/drug-storage/get-storage-department-by-institution-id',
    parameterFomatter: (data?: { institutionId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_getCustomizedImportExportList_833872: {
    method: 'post',
    url: '/api/drug-storage/get-customized-import-export-list',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: CustomizeDrugExportImportListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
