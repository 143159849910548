import { definePage } from '@vs/vsf-boot';
import React, { Fragment } from 'react';

import Container from '@/module/Container';

import { useOnChangeValue } from '../../components/func/hooks';
import WardPendingReview from './review';
import WardTable from './table';
const ReviewOfInpatientMedicationOrders = () => {
  const [select] = useOnChangeValue({});
  return (
    <Fragment>
      <WardPendingReview {...select} />
      <Container
        layout={{
          items: [
            {
              bgColor: 'var(--background)',
            },
          ],
        }}
      >
        <WardTable value={select?.value} />
      </Container>
    </Fragment>
  );
};

export default definePage(ReviewOfInpatientMedicationOrders);
