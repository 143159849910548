import vsf from '@vs/vsf-boot';
import {
  Button,
  Form,
  message,
  Modal,
  Progress,
  Table,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useGetState } from 'ahooks';
import React, { Fragment, useCallback, useRef } from 'react';

import { isMinScreen } from '@/config';
import Block from '@/module/Block';
import Container from '@/module/Container';
import {
  ButtonAndKeyDown,
  ComplexSelect,
  Determine,
} from '@/pages/Index/components';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import {
  useAvoidState,
  useFetch,
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';

type DrugBeInHospitalOrderProps = {
  value: {
    wardId: number | null;
  };
};

const HospitalSelect = (props: any) => {
  const [
    hospitalDataSource,
    setHospitalDataSource,
    ,
    ,
    getInitHospitalDataSource,
  ] = useGetResetInitState([]);

  const wardId = props?.wardId;
  useAsyncEffect(async () => {
    if (wardId) {
      const res =
        await vsf.services?.InpDrugAuditController_getWaitAuditPatientByWard_e5c3c3?.(
          {
            wardId,
          },
        );
      if (res?.code === 200) {
        setHospitalDataSource(
          res?.data?.map((item) => ({
            ...item,
            label: item?.patient?.name,
            value: item?.patient?.id,
            key: item?.patient?.id,
            gender: vsf.stores.dbenums.enums.SEX_DICT?.find(
              ({ value }) => value === item?.patient?.gender,
            )?.label,
          })),
        );
      }
    }
  }, [wardId]);

  return (
    <ComplexSelect
      mode="radio"
      placeholder="请选择病区患者"
      hideSelectIcon
      showLabel="label"
      allowClear
      value={props?.value ?? ''}
      onChange={props?.onChange}
      dropdownMatchSelectWidth={700}
      listHeight={1000}
      onSearch={(value, filterOption) => {
        if (value?.trim()) {
          setHospitalDataSource(
            filterOption(value, getInitHospitalDataSource()),
          );
        } else {
          setHospitalDataSource(getInitHospitalDataSource());
        }
      }}
    >
      <Table
        columns={[
          {
            dataIndex: ['bed', 'bedLabel'],
            title: '床号',
          },
          {
            dataIndex: ['patient', 'displayId'],
            title: '患者ID',
          },
          {
            dataIndex: ['patient', 'name'],
            title: '姓名',
          },
          {
            dataIndex: ['gender'],
            title: '性别',
          },
        ]}
        dataSource={hospitalDataSource}
        pagination={false}
        size="middle"
        scroll={{
          y: 300,
        }}
      />
    </ComplexSelect>
  );
};

const Footer = (props) => {
  const { value, tableRef, order } = props || {};
  const [form] = Form.useForm();
  const [prepareDrugStaffDataSource, setPrepareDrugStaffDataSource] =
    useAvoidState([]);

  useAsyncEffect(async () => {
    const res =
      await vsf?.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
        {},
      );
    console.log(res.data);
    setPrepareDrugStaffDataSource(res?.data?.map((item) => item?.staff));
  }, []);

  const onDispensing = useFetch({
    fetch: async () => {
      const res =
        await vsf?.services?.OrderDispenseController_orderDispensing_7cd9ab({
          param: {
            wardId: value?.wardId,
            orderPlan: tableRef.current
              ?.getValues()
              .filter((item) => !item?.is)
              ?.flatMap((item) => {
                const is =
                  order ===
                  'OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99';
                // orderDispensingInput
                if (is) {
                  return item?.orderPerformPlanRecordId?.map(
                    (orderPerformPlanRecordId) => ({
                      orderId: item?.orderId,
                      patientId: item?.patientId,
                      orderPerformPlanRecordId,
                    }),
                  );
                } else {
                  return item?.orderDispensingInput;
                }
              }),
            prepareDrugStaffId: form?.getFieldValue('prepareDrugStaffId')?.id,
            auditStaffId: form?.getFieldValue('auditStaffId')?.id,
          },
        });
      return res;
    },
    message: {
      loading: '发药中',
      error: '发药失败',
    },
    success: (value) => {
      tableRef?.current?.reload();
      if (value?.data?.errorMessage) {
        message.error(value?.data?.errorMessage);
      } else {
        message?.success('摆药发送成功');
      }
    },
  });

  const [percent] = useOnChangeValue(0);

  // const onDispensing = () => {
  //   Modal.success({
  //     icon: null,
  //     title: '发药进行中',
  //     content: (
  //       <Progress
  //         percent={percent?.value}
  //         status="active"
  //         strokeColor={{ from: '#108ee9', to: '#87d068' }}
  //       />
  //     ),
  //   });
  //   const res =
  //     await vsf?.services?.OrderDispenseController_orderDispensing_7cd9ab({
  //       param: {
  //         wardId: value?.wardId,
  //         orderPlan: tableRef.current
  //           ?.getValues()
  //           .filter((item) => !item?.is)
  //           ?.flatMap((item) => {
  //             const is =
  //               order ===
  //               'OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99';
  //             // orderDispensingInput
  //             if (is) {
  //               return item?.orderPerformPlanRecordId?.map(
  //                 (orderPerformPlanRecordId) => ({
  //                   orderId: item?.orderId,
  //                   patientId: item?.patientId,
  //                   orderPerformPlanRecordId,
  //                 }),
  //               );
  //             } else {
  //               return item?.orderDispensingInput;
  //             }
  //           }),
  //         prepareDrugStaffId: form?.getFieldValue('prepareDrugStaffId')?.id,
  //         auditStaffId: form?.getFieldValue('auditStaffId')?.id,
  //       },
  //     });
  // };

  return (
    <div
      className="aspirin-table-footer-button-box"
      style={{
        background: 'var(--background)',
        justifyContent: 'space-between',
        padding: 24,
      }}
    >
      <div
        style={{
          width: '30%',
        }}
      >
        <VSForm form={form}>
          <VSFormLayout key="0" columnCount={2}>
            <VSFormItem
              name={['auditStaffId']}
              label="核对人"
              valueType="select"
              dataSource={prepareDrugStaffDataSource}
              fieldNames={{ label: 'staffName', value: 'id' }}
            />

            <VSFormItem
              name={['prepareDrugStaffId']}
              label="配药人"
              valueType="select"
              dataSource={prepareDrugStaffDataSource}
              fieldNames={{ label: 'staffName', value: 'id' }}
            />
          </VSFormLayout>
        </VSForm>
      </div>
      <div
        style={{
          display: 'flex',
          gap: 10,
        }}
      >
        <Button type="primary" ghost>
          清屏
        </Button>
        <Button
          type="primary"
          onClick={() => {
            Tip({
              content: '是否确认发药',
              onOk: () => onDispensing?.(),
            });
          }}
        >
          发药
        </Button>
        <Button type="primary" disabled>
          包药审核
        </Button>
      </div>
    </div>
  );
};

const DrugBeInHospitalOrder = (props: DrugBeInHospitalOrderProps) => {
  const { value } = props;
  const [, setComplex, getComplex] = useGetState({});
  const [order] = useOnChangeValue(
    'OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99',
  );
  const [form] = Form.useForm();
  const [hospitalDataSource, setHospitalDataSource] = useAvoidState([]);
  const tableRef = useRef<any>(null);
  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['drugOrder', 'administration'],
        ['drugOrder', 'dripSpeed'],
        ['drugOrder', 'frequency'],
        ['drugOrder', 'useDescription'],
        ['repeatValidDays'],
      ],
      tip() {},
    }),
  );

  const toSpliced = (list): any[] => {
    const items = {
      OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99:
        () => {
          return list?.flatMap((item) => {
            const dispenseOrderDetail = item?.dispenseOrderDetail?.map(
              (item) => ({
                ...item,
                drugOrder: item,
                orderClass: 'DRUG',
                orderText: [
                  item?.orderText,
                  // vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
                  //   ({ value }) => value == item?.administration,
                  // )?.label,
                  // item?.frequency?.frequency,
                ]?.join(' '),
              }),
            );

            return [
              {
                ...item,
                wardName: item?.wardName,
                is: true,
                pharmacyLastCheckDate: item?.bedLabel + '床',
                orderText: [
                  item?.displayId,
                  item?.name,
                  vsf.stores.dbenums.enums.SEX_DICT?.find(
                    ({ value }) => value === item?.gender,
                  )?.label ?? '',
                  item?.age ? item?.age + '岁' : '',
                ],
                dispenseOrderDetail,
              },
              ...dispenseOrderDetail,
            ];
          });
        },
      OrderDispenseController_getWaitDispenseOrderPerformPlanWithDistinctDrug_afaace:
        () => {
          return list;
        },
    };
    return items?.[order?.value]?.();
  };
  const onFetch = useCallback(async () => {
    if (value?.wardId) {
      const { repeatIndicator, coldIndicator, inpVisitId } =
        getComplex() as any;
      const res = await vsf?.services?.[order?.value]?.({
        query: {
          wardId: value?.wardId,
          repeatIndicator:
            repeatIndicator === 'null' ? undefined : repeatIndicator,
          coldIndicator: coldIndicator === 'null' ? undefined : coldIndicator,
          inpVisitId,
        },
      });
      return {
        data: toSpliced(res?.data),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
    return {
      data: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.wardId, order?.value]);

  useAsyncEffect(async () => {
    if (value?.wardId) {
      const resolve =
        await vsf.services?.OrderDispenseController_getOrderWaitDispenseBedList_fc78bb?.(
          {
            wardId: value?.wardId,
          },
        );
      if (resolve?.code === 200)
        setHospitalDataSource(
          resolve?.data?.map((item) => ({
            key: item?.id,
            label: item?.patient.name,
            ...item,
            gender: vsf.stores.dbenums.enums.SEX_DICT?.find(
              ({ value }) => value === item?.patient?.gender,
            )?.label,
            bedLabel: item?.patientInHospitalBedDto?.bed?.bedLabel,
          })),
        );
    }
  }, [value?.wardId]);

  const hideInTable =
    order?.value !==
    'OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99';

  return (
    <Container
      layout={{
        items: [
          {
            // bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <Block
        style={{
          padding: 0,
        }}
        scroll={false}
        footer={
          <Footer value={value} tableRef={tableRef} order={order?.value} />
        }
      >
        <VSPromiseTable
          className="aspirin-table-no-background-box"
          pagination={false}
          rowClassName={(node: any) =>
            node?.is ? 'aspirin-table-row-background-box' : ''
          }
          bordered
          ref={tableRef}
          tableRender={(...[, table]) => {
            return (
              <Fragment>
                <div
                  className="aspirin-table-search-form-box"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 24,
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                    }}
                  >
                    <VSForm
                      onValuesChange={(...[, value]) => {
                        setComplex?.(value);
                      }}
                      form={form}
                    >
                      <VSFormLayout columnCount={3}>
                        <VSFormItem
                          valueType="custom"
                          name={['inpVisitId']}
                          label="患者"
                          transform={(value) => {
                            return {
                              inpVisitId: value?.id,
                            };
                          }}
                        >
                          <HospitalSelect wardId={props?.value?.wardId} />
                        </VSFormItem>

                        <VSFormItem
                          name={['repeatIndicator']}
                          label="长/临"
                          valueType="select"
                          dataSource={[
                            {
                              label: '全部',
                              value: 'null',
                            },
                            {
                              label: '长期',
                              value: true,
                            },
                            {
                              label: '临时',
                              value: false,
                            },
                          ]}
                          fieldProps={{}}
                        />

                        <VSFormItem
                          name={['coldIndicator']}
                          label="是否冷藏"
                          valueType="select"
                          fieldProps={{}}
                          dataSource={[
                            {
                              label: '全部',
                              value: 'null',
                            },
                            {
                              label: '是',
                              value: true,
                            },
                            {
                              label: '否',
                              value: false,
                            },
                          ]}
                        />
                      </VSFormLayout>
                    </VSForm>
                  </div>
                  <ButtonAndKeyDown
                    methodKey="altKey"
                    ordinaryKey="Q"
                    type="primary"
                    onClick={() => {
                      tableRef?.current?.reload();
                    }}
                  >
                    提取
                  </ButtonAndKeyDown>
                </div>
                <div
                  className="aspirin-table-inter-no-box"
                  style={{
                    padding: 24,
                  }}
                >
                  <Tabs
                    items={[
                      {
                        label: '摆药医嘱',
                        key: 'OrderDispenseController_getWaitDispenseOrderPerformPlanList_4d6f99',
                      },
                      {
                        label: '发药汇总单',
                        key: 'OrderDispenseController_getWaitDispenseOrderPerformPlanWithDistinctDrug_afaace',
                      },
                    ]}
                    {...order}
                  />
                  <div>{table}</div>
                </div>
              </Fragment>
            );
          }}
          onFetch={onFetch}
          scroll={{
            y: isMinScreen ? 400 : 550,
            x: 2000,
          }}
        >
          <VSTableColumn
            dataIndex={['neonate']}
            title="新生儿标记"
            valueType="text"
            fieldProps={{}}
            hideInTable={hideInTable}
            width={120}
          />
          <VSTableColumn
            dataIndex={['pharmacyLastCheckDate']}
            title="复核时间"
            valueType="text"
            fieldProps={{}}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['orderText']}
            title="医嘱内容"
            valueType="text"
            fieldProps={{}}
            width={400}
            render={(dom, record) => (
              <Determine
                condition={Array?.isArray(record?.orderText)}
                fallback={dom as JSX.Element}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 50,
                  }}
                >
                  {record?.orderText?.map?.((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              </Determine>
            )}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['drugPriceItemId']}
            title="编号"
            valueType="text"
            hideInTable={!hideInTable}
            width={100}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['drugProductName']}
            title="药品名称"
            valueType="text"
            hideInTable={!hideInTable}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['packageSpecification']}
            title="规格"
            valueType="text"
            hideInTable={!hideInTable}
            fieldProps={{}}
            width={100}
          />
          <VSTableColumn
            dataIndex={['dosageAndUnit']}
            title="剂量"
            valueType="text"
            fieldProps={{}}
            width={100}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['frequency', 'frequency']}
            title="频次"
            valueType="text"
            fieldProps={{}}
            width={100}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['administration']}
            title="途径"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
            fieldProps={{}}
            width={100}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['totalAmount']}
            title={hideInTable ? '数量' : '摆药数量'}
            valueType="digit"
            fieldProps={{}}
            width={100}
            // hideInTable={hideInTable}
          />

          <VSTableColumn
            dataIndex={['packageUnit']}
            title={hideInTable ? '单位' : '包装单位'}
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
            width={100}
            // hideInTable={hideInTable}
          />

          <VSTableColumn
            dataIndex={['firmName']}
            title="厂家"
            valueType="text"
            fieldProps={{}}
            // hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['orderCount']}
            title="医嘱条数"
            valueType="text"
            hideInTable={!hideInTable}
            fieldProps={{}}
            width={100}
          />
          <VSTableColumn
            dataIndex={['location']}
            title="货位"
            valueType="text"
            hideInTable={!hideInTable}
            fieldProps={{}}
          />
          <VSTableColumn
            width={150}
            dataIndex={['lastDispenseEndDateTime']}
            title="上次发药时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            width={150}
            dataIndex={['lastDispenseEndDateTime']}
            title="停止时间"
            valueType="text"
            fieldProps={{}}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            width={150}
            dataIndex={['performSchedulePlan']}
            title="执行时间"
            valueType="text"
            fieldProps={{}}
            hideInTable={hideInTable}
          />
          <VSTableColumn
            dataIndex={['errorMessage']}
            title="错误信息"
            valueType="text"
            fieldProps={{}}
            width={240}
          />
        </VSPromiseTable>
      </Block>
    </Container>
  );
};

export default DrugBeInHospitalOrder;
