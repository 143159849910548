import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getPriceItemByClinicRegisterTypeId_daf5db: {
    method: 'post',
    url: '/api/outp-register-define/get-price-item-by-clinic-register-type-id',
    parameterFomatter: (data?: { clinicRegisterTypeId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterPriceItemVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterPriceItem_5c1f6c: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-price-item',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterPriceItemBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateClinicRegisterPriceItem_705179: {
    method: 'post',
    url: '/api/outp-register-define/add-update-clinic-register-price-item',
    parameterFomatter: (data?: {
      btoParam: AddUpdateClinicRegisterPriceItemBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  PriceListDtoController_getAllByPriceItemQueryQto_273108: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-item-query-qto',
    parameterFomatter: (data?: { qto: PriceItemQueryQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
});
