import { definePage } from '@vs/vsf-boot';
import { Dropdown, Select } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

const Index = (props) => {
  const { dataSource, value, onChange } = props;
  const { common } = props?.stores || {};
  const { setCurrentApplication } = common || {};

  const applicationItems: any[] = (dataSource ?? []).map((item) => ({
    key: item?.id,
    label: item?.name,
    value: item?.id,
  }));

  const handleSelect = (v) => {
    if (v?.key) {
      const target = dataSource?.find((item) => item?.id == v?.key);
      onChange(target);
    }
  };

  return (
    <Select
      className="toggle-application-select"
      // style={{ width: 200, color: '#fff' }}
      value={value}
      bordered={false}
      showSearch
      dataSource={applicationItems}
      onSelect={handleSelect}
    />
  );
};

export default definePage(Index);
