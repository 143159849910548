import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getOperationHistoryByPatientId_cfbfba: {
    method: 'post',
    url: '/api/clinic-record-history/get-operation-history-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationHistoryVoClinicRecordHistoryEntranceWebVo[],
    ) => data,
  },
  ClinicRecordHistoryController_deleteOperationHistory_1525cc: {
    method: 'post',
    url: '/api/clinic-record-history/delete-operation-history',
    parameterFomatter: (data?: {
      btoParam: DeleteOperationHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createOperationHistory_8c5602: {
    method: 'post',
    url: '/api/clinic-record-history/create-operation-history',
    parameterFomatter: (data?: {
      btoParam: CreateOperationHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_updateOperationHistory_fc754e: {
    method: 'post',
    url: '/api/clinic-record-history/update-operation-history',
    parameterFomatter: (data?: {
      btoParam: UpdateOperationHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
});
