import TransferExpand from '@/pages/Index/components/transfer_expand/index.jsx';
import SearchContainer from '@/module/Search';
import { getScrollY, getScrollYRem } from '@/utils';
import {
  VSTable,
  VSTableColumn,
  Button,
  message,
  VSForm,
  VSFormItem,
} from '@vs/vsf-kit';
import { useLockFn } from 'ahooks';
import { useEffect, useState, memo, useCallback } from 'react';
import {
  Department,
  RenderLeft,
  RenderRight,
} from '../../components/department';

const PerformDepartment = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const getInstitution = useCallback(async () => {
    const res =
      await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
        {},
      );
    return res.data;
  }, []);

  const getDataSource = useCallback(async (branchInstitutionIdIn) => {
    const institution = await getInstitution();
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
        {
          qto: {
            stopIndicatorIs: false,
            institutionIdIs: institution?.id,
            branchInstitutionIdIn,
          },
        },
      );
    setDataSource(
      res.data.map((item) => ({
        ...item,
        departmentName: `${item.departmentName}(${item.branchInstitution?.institutionName})`,
      })),
    );
  }, []);

  const getData = useCallback(async () => {
    const res =
      await vsf?.services?.TreatController_getTreatClassWithDepartmentById_6f9190?.(
        { id: props.value.id },
      );
    if (res.code === 200) {
      res.data.treatVsDepartmentDtoList =
        res.data?.treatVsDepartmentDtoList.map((item) => ({
          ...item,
          id: item.department.id,
          _id: item.id,
          departmentName: item.department.departmentName,
        }));
      setData(res.data);
      getDataSource(
        res?.data?.treatClassDictionaryExtensionDtoList?.map(
          (item) => item.branchInstitution.id,
        ),
      );
    }
  }, [props.value?.id]);

  const getDepartment = async () => {
    if (props.value?.id) {
      const resolve =
        await vsf.services.TreatController_getTreatClassWithInstitutionById_552f69(
          {
            id: props.value.id,
          },
        );
      return resolve.data.treatClassDictionaryExtensionDtoList.flatMap(
        (item) => item.branchInstitution,
      );
    }
    return [];
  };

  useEffect(() => {
    if (props.value?.id) {
      getData();
    }
  }, [props.value]);

  const TransferProps = {
    showSelectAll: false,
    dataSource,
    style: {
      // height: getScrollY(300),
      height: getScrollYRem(19),
    },
    listStyle: ({ direction }) => {
      const dict = {
        left: {
          flex: 0,
          flexBasis: '25%',
          borderTop: 'none',
          borderLeft: 'none',
          borderBottom: 'none',
        },
        right: {
          flex: 0,
          flexBasis: '75%',
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        },
      };
      return dict[direction];
    },
    search: {
      left: {
        render: <Department dataSource={getDepartment} />,
      },
    },
  };
  const onSubmit = useLockFn(async (params) => {
    try {
      await vsf?.refs?.PerformDepartment?.getEditForm()?.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.TreatController_addDepartmentToTreatClass_fb220e?.(
          {
            btoParam: {
              id: props.value.id,
              treatVsDepartmentBtoList: params?.length
                ? params.map((item, index) => {
                    const dict = {
                      departmentId: item.id,
                      priority: index + 1,
                    };
                    if (item._id) dict.id = item._id;
                    return dict;
                  })
                : [],
            },
            extApiParam: params?.extApiParam,
          },
        );
      if (res.code === 200) {
        message.success('保存执行科室成功');
        getData();
      }
    } catch (error) {
      return error;
    }
  });
  return (
    <TransferExpand
      {...TransferProps}
      left={<RenderLeft />}
      right={<RenderRight />}
      data={data?.treatVsDepartmentDtoList ?? []}
      labelKey="id"
      search={{
        left: {
          render: (
            <Department
              getDataSource={data?.treatClassDictionaryExtensionDtoList?.flatMap(
                (item) => {
                  return item.branchInstitution;
                },
              )}
            />
          ),
        },
      }}
      footer={(data) => (
        <Button onClick={() => onSubmit(data)} type="primary">
          保存
        </Button>
      )}
    />
  );
};

export default PerformDepartment;
