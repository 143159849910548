import vsf from '@vs/vsf-boot';
import React, { Fragment } from 'react';

import { useOnChangeValue } from '@/pages/Index/components/func/hooks';

import DrugBeInHospitalOrder from './drug';
import DrugBeInHospitalDispensingWard from './ward';
const DrugBeInHospitalDispensing = () => {
  const [ward] = useOnChangeValue({
    wardId: null,
  });
  return (
    <Fragment>
      <DrugBeInHospitalDispensingWard onChange={ward?.onChange} />
      <DrugBeInHospitalOrder value={ward?.value} />
    </Fragment>
  );
};

export default DrugBeInHospitalDispensing;
