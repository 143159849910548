import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_returnHerbRefundDispense_6ae5f3: {
    method: 'post',
    url: '/api/drug-prescription-dispense/return-herb-refund-dispense',
    parameterFomatter: (data?: {
      drugPrescriptionDispenseMasterIds: number[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugPrescriptionRefundDispenseController_getHerbRefundDispenseForReturn_efa5c2:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-herb-refund-dispense-for-return',
      parameterFomatter: (data?: {
        qto: QueryDrugRefundDispenseForReturnQtoDrugPrescriptionDispensePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionDispenseMasterForReturnRefundVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
});
