import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugImportRecordCreateController_getDrugImportMasterWithDetail_19192c: {
    method: 'post',
    url: '/api/drug-storage-import/get-drug-import-master-with-detail',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugStorageController_getDrugStockBatchNumberOfDrugProduct_8303b8: {
    method: 'post',
    url: '/api/drug-storage/get-drug-stock-batch-number-of-drug-product',
    parameterFomatter: (data?: {
      eo: DrugStockStorageIdDrugProductIdPackageSpecificationEoDrugStoragePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BatchNumberVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
