import {
  Button,
  Checkbox,
  Col,
  Form,
  Divider,
  Input,
  Row,
  Tabs,
  Select,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { debounce } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';

import { cdssShow } from '@/mock';
import BgImage from '@/assets/login/bg.png';
import Icon from '@/module/Icon';
import message from '@/module/message';
import vsf, { definePage } from '@vs/vsf-boot';

import services from './services';

import './index.less';

const { TabPane } = Tabs;

const Login = (props) => {
  const {
    common,
    user,
    cdss: { cdssLogin },
  } = props?.stores || {};
  const { setCurrentApplication, currentApplication, setApplicationList } =
    common || {};
  const { setAccountInfo } = user || {};
  const [form] = Form.useForm();

  const [application, setApplication] = useState();
  const selectedApplication = useRef();

  const onLogin = async (values) => {
    const res = await vsf?.services?.AccountController_staffLogin_ed24bb(
      values,
    );
    message(res);
    if (res.code === 200) {
      const _currentApplication = application?.find(
        (item) => item?.id === selectedApplication.current,
      );
      if (_currentApplication) {
        setCurrentApplication(_currentApplication);
        setApplicationList(application);
        setAccountInfo({ accountInfo: values, id: res?.data });
        vsf?.history?.push('/index/center');
      }
      if (cdssShow) {
        console.log('打印成功');
        cdssLogin();
      }
    }
  };

  const getApplicationList = debounce(async (loginId) => {
    const res =
      await vsf?.services?.PrivilegeController_getAuthorizedApplicationList_3e355f(
        {
          loginId,
        },
      );
    if (res?.code === 200) {
      setApplication(res?.data);
      form?.resetFields?.(['applicationId']);
    }
  }, 500);

  const handelApplicationSelect = (v) => {
    selectedApplication.current = v;
  };

  const handelFormChange = (v) => {
    if (v?.username) {
      getApplicationList(v?.username);
    }
  };

  return (
    <div className="login-container">
      <div className="login-block">
        <div className="login-image-2"></div>
        <div className="title dinpro">Aspirin HIS</div>
        <VSForm
          form={form}
          name="normal_login"
          className="login-form"
          onFinish={onLogin}
          onValuesChange={handelFormChange}
          switchMode
        >
          <VSFormItem
            valueType="text"
            name="username"
            rules={[{ required: true, message: '用户名不能为空' }]}
            fieldProps={{
              prefix: (
                <Icon
                  type="icon-zhanghao"
                  size={25}
                  style={{ color: '#AEB0B3' }}
                />
              ),
              placeholder: '请输入用户名',
              size: 'large',
              allowClear: true,
            }}
          />
          <VSFormItem
            valueType="password"
            name="password"
            rules={[{ required: true, message: '密码不能为空!' }]}
            fieldProps={{
              prefix: (
                <Icon type="icon-mima" size={25} style={{ color: '#AEB0B3' }} />
              ),
              placeholder: '请输入密码',
              size: 'large',
              allowClear: true,
            }}
          />
          <VSFormItem
            valueType="select"
            name="applicationId"
            rules={[{ required: true, message: '请选择工作站' }]}
            fieldProps={{
              placeholder: '选择工作站',
              onChange: handelApplicationSelect,
              // suffixIcon: (
              //   <Icon
              //     type="icon-xuanzegongzuozhan"
              //     size={25}
              //     style={{ color: '#AEB0B3' }}
              //   />
              // ),
              showSearch: true,
            }}
            dataSource={application?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
          {/* <div className="extra-oper">
            <Checkbox>记住密码</Checkbox>
            <Button type="link">忘记密码</Button>
          </div> */}
          <VSFormLayout
            columnCount={2}
            style={{
              flexDirection: 'row-reverse',
            }}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
                size="large"
              >
                登录
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="login-form-button-reset"
                style={{ width: '100%' }}
                size="large"
                onClick={() => {
                  form?.resetFields?.();
                }}
              >
                重置
              </Button>
            </Form.Item>
          </VSFormLayout>
        </VSForm>
        {/* <div className="other-method">
          <span>还没有账号?</span>
          <Button type="link">立即创建</Button>
        </div>
        <Divider className="login-divider" dashed orientationMargin={25}>
          其他登录方式
        </Divider> */}
      </div>
    </div>
  );
};

export default definePage(Login);
