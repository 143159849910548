import vsf, { definePage } from '@vs/vsf-boot';
import {
  Divider,
  Form,
  Icon,
  message,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useMount } from 'ahooks';
import { Select } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { Fragment, useCallback, useEffect } from 'react';

import Block from '@/module/Block';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import { ButtonAndKeyDown, Determine } from '@/pages/Index/components';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  useAvoidState,
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import { getScrollY } from '@/utils';

import order from '../medical_orders_show/order';

const DischargeMedicationExecution = (props) => {
  const { currentApplication } = props?.stores?.common || {};

  // const { currentApplication?.department?.id } = props?.stores?.user || {};
  // console.log(
  //   currentApplication?.department?.id,
  //   props?.routes,
  //   'setDataSourcesetDataSource',
  // );

  const [dataSource, setDataSource] = useAvoidState([]);
  const [order] = useOnChangeValue({});
  const [form] = Form.useForm();

  const onMap = (input) => {
    const _: any = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        const totalAmount = _.get(d, 'orderDrug.totalAmount') ?? '';
        const packageUnit = _.get(d, 'orderDrug.packageUnit') ?? '';
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.createDateTime = _.get(d, 'createDateTime');
        obj.orderText = _.get(d, 'orderText');
        obj.totalAmount = `${totalAmount}${packageUnit}`;
        obj.packageUnit = _.get(d, 'orderDrug.packageUnit');
        obj.usage = _.get(d, 'orderDrug.usage');
        obj.dosage =
          (_.get(d, 'orderDrug.dosage') ?? '') +
          (_.get(d, 'orderDrug.dosageUnit') ?? '');
        obj.performDays = _.get(d, 'orderDrug.performDays');
        obj.frequency = _.get(d, 'orderDrug.frequency');
        obj.usageUnit = _.get(d, 'orderDrug.usageUnit');
        obj.administration = _.get(d, 'orderDrug.administration');
        obj.userDescription = _.get(d, 'orderDrug.userDescription');
        obj.memo = _.get(d, 'memo');
        obj.performPrintDateTime = _.get(d, 'performPrintDateTime');
        obj.herbDecoctionIndicator = _?.get(
          d,
          'orderHerb.herbDecoctionIndicator',
        );
        obj.drugBillingAttribute = _?.get(d, 'orderDrug.drugBillingAttribute');
        obj.staffName = _.get(
          d,
          'orderDrug.prescription.drugPrescriptionStatus.dispenseStaff.staffName',
        );
        obj.id = _.get(d, 'id');
        return obj;
      }) ?? []),
    );
    return output;
  };

  const [select] = useOnChangeValue([]);

  const onSpliced = (list) => {
    const count = {};
    const array: any[] = [];
    for (const item of list) {
      count[item?.patient?.id] = [...(count[item?.patient?.id] ?? []), item];
    }
    for (const item of Object?.values(count)) {
      if ((item as never[])?.length) {
        const slice = (item as never[])?.slice(1);
        array?.push(
          ...[
            (item as never[])[0],
            // eslint-disable-next-line @typescript-eslint/ban-types
            ...slice?.map((item) => {
              const { bedLabel, patient, ...rest } = item as any;
              return rest;
            }),
          ],
        );
      } else {
        array?.push(...(item as never[]));
      }
    }
    return array;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createDateTimeRangeIn = Form.useWatch(
    'createDateTimeRangeIn',
    form,
  ) || [dayjs()?.valueOf(), dayjs()?.valueOf()];

  const performPrintDateTimeIsNullOrNot =
    Form.useWatch('performPrintDateTimeIsNullOrNot', form) == '1'
      ? false
      : true;
  const bedLabelIs = Form.useWatch('bedLabelIs', form);
  // console.log(
  //   performPrintDateTimeIsNullOrNot,
  //   'performPrintDateTimeIsNullOrNot',
  // );

  const onFetch = useCallback(
    async (params) => {
      // console.log(
      //   performPrintDateTimeIsNullOrNot,
      //   createDateTimeRangeIn,
      //   'createDateTimeRangeIncreateDateTimeRangeIn',
      // );

      const res =
        await vsf.services?.OrderDischargeDrugController_getOrderDischargeDrug_07a55d?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              createDateTimeRangeIn: {
                startTime: createDateTimeRangeIn?.[0],
                endTime: createDateTimeRangeIn?.[1],
              },
              performPrintDateTimeIsNullOrNot,
              bedLabelIs: props?.routes?.query?.value ?? bedLabelIs,
            },
          },
        );
      const output = onMap({
        data: onSpliced(res?.data ?? []),
      });
      return {
        data: output ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [
      bedLabelIs,
      props?.routes,
      createDateTimeRangeIn,
      performPrintDateTimeIsNullOrNot,
    ],
  );

  useAsyncEffect(async () => {
    if (currentApplication?.department?.id) {
      const res =
        await vsf?.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
          {
            bedWardId: currentApplication?.department?.id,
          },
        );

      const _res = res?.data?.map((i) => {
        return {
          ...i,
          label: i?.bedLabel + '-' + i?.patientName,
          value: i?.bedLabel,
        };
      });
      setDataSource(_res);
    }
  }, [currentApplication?.department?.id]);

  const onPrint = useFetch({
    fetch: (orderIds) =>
      vsf?.services?.OrderDischargeDrugController_updatePerformPrintTime_5fac0a?.(
        {
          orderIds,
        },
      ),
    message: '打印',
    success: () => {
      vsf?.refs?.orderDischargeDrug?.reload();
    },
  });

  const Footer = () => {
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          background: 'var(--background)',
        }}
      >
        <ButtonAndKeyDown
          methodKey="altKey"
          ordinaryKey="P"
          onClick={() => {
            onPrint(select?.value?.map((item: any) => item?.id));
          }}
          preventDefault
          type="primary"
          disabled={!select?.value?.length}
        >
          打印
        </ButtonAndKeyDown>
      </div>
    );
  };

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
          },
        ],
      }}
    >
      <Block
        height={getScrollY(130)}
        style={{
          padding: 0,
        }}
        scroll={false}
        footer={<Footer />}
      >
        <VSPromiseTable
          id="orderDischargeDrug"
          onFetch={onFetch}
          className="aspirin-table-no-background-box"
          bordered
          tableRender={(...args) => {
            const [, table] = args;
            return (
              <Fragment>
                <div
                  style={{
                    padding: 24,
                  }}
                  className="aspirin-table-search-form-box"
                >
                  <VSForm form={form} id="orderDischargeDrugForm">
                    <VSFormLayout columnCount={5}>
                      <VSFormItem
                        label="起始时间"
                        valueType="aspirinDateTimePicker"
                        name={'createDateTimeRangeIn'}
                        fieldProps={{
                          type: 'range',
                          valueType: 'timestamp',
                          format: 'YYYY/MM/DD',
                        }}
                        initialValue={[dayjs()?.valueOf(), dayjs()?.valueOf()]}
                        transform={(value) => {
                          const [startTime, endTime] = value;
                          return {
                            createDateTimeRangeIn: {
                              startTime,
                              endTime,
                            },
                          };
                        }}
                      />
                      <VSFormItem
                        label="是否已打印"
                        valueType="radio"
                        name={'performPrintDateTimeIsNullOrNot'}
                        initialValue="2"
                        dataSource={[
                          { label: '已打印', value: '1' },
                          { label: '未打印', value: '2' },
                        ]}
                        // dataSource={[
                        //   { label: '已打印', value: 'hasPrint' },
                        //   { label: '未打印', value: 'noPrint' },
                        // ]}
                      />
                      <VSFormItem
                        valueType="selectCode"
                        name={'bedLabelIs'}
                        label="床号"
                        initialValue={props?.routes?.query?.value}
                        dataSource={dataSource}
                        fieldProps={{
                          style: {
                            minWidth: 200,
                          },
                          placeholder: '请输入床号',
                          allowClear: true,
                        }}
                      />
                    </VSFormLayout>
                  </VSForm>
                </div>
                <div
                  className="aspirin-table-inter-box"
                  style={{
                    padding: 24,
                  }}
                >
                  <div>{table}</div>
                </div>
              </Fragment>
            );
          }}
          rowKey="id"
          rowClick={{
            rowClickType: 'select',
          }}
          rowSelection={{
            selectType: 'multiple',
            selectedRows: select?.value,
            onSelectedRowsChange: select?.onChange,
          }}
          pagination={false}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            width={120}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['displayId']}
            title="患者ID"
            valueType="text"
            width={100}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['createDateTime']}
            title="开立时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={170}
          />

          <VSTableColumn
            dataIndex={['orderText']}
            title="药品名称及规格"
            valueType="text"
            fieldProps={{}}
            render={(...[, record]) => {
              return (
                <div>
                  {record?.herbDecoctionIndicator ? '【代煎】' : ''}
                  {record?.drugBillingAttribute === 'SELF_PROVIDE'
                    ? '(自备)'
                    : ''}
                  {record?.orderText}
                </div>
              );
            }}
          />
          {/* 
          <VSTableColumn
            dataIndex={['packageUnit']}
            title="包装单位"
            valueType="text"
            fieldProps={{}}
            width={80}
          /> */}

          <VSTableColumn
            dataIndex={['dosage']}
            title="剂量"
            valueType="digit"
            width={100}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['totalAmount']}
            title="总量"
            valueType="digit"
            fieldProps={{}}
            width={120}
          />
          <VSTableColumn
            dataIndex={['performDays']}
            title="天数"
            valueType="text"
            width={50}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['frequency', 'frequency']}
            title="频次"
            valueType="text"
            width={50}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['administration']}
            title="给药途径"
            valueType="select"
            width={100}
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
          />

          <VSTableColumn
            dataIndex={['userDescription']}
            title="用法描述"
            valueType="select"
            fieldProps={{}}
            width={100}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_USING_DESCRIPTION_DICT}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['staffName']}
            title="发药人"
            valueType="text"
            width={100}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['todo']}
            title="家属签字"
            valueType="text"
            width={100}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['performPrintDateTime']}
            title="执行单打印时间"
            valueType="date"
            width={200}
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />
        </VSPromiseTable>
      </Block>
    </Container>
  );
};
export default definePage(DischargeMedicationExecution);
