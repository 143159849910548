import vsf from '@vs/vsf-boot';
export const getAllByDepartmentInCurrentInstitution = async (params) => {
  const res =
    await vsf.services.DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de(
      // { ...v },
      { qto: { ...params } },
    );
  return res?.data;
};
// 号类
export const getAllByClinicRegisterTypeDictionaryQto = async (params) => {
  const res =
    await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472?.(
      {
        qto: {},
      },
    );
  return res?.data;
};
