import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
/**
 *
 * @param table_id 表单id
 * @returns
 */
export const getTableValues = (table_id) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.getValues?.();
};
/**
 *
 * @param table_id  表单id
 * @param values  数据{}
 * @returns
 */
export const updateTableValues = (table_id, values) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.update(values);
};
export const addTableRow = (table_id, values) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.add(values);
};
export const removeTableRow = (table_id, value) => {
  if (!table_id) return;
  return vsf?.refs?.[table_id]?.remove(value);
};
export const clearTable = (table_id) => {
  if (!table_id) return;
  vsf?.refs?.[table_id]
    ?.getValues?.()
    ?.forEach?.((d) => vsf?.refs?.[table_id]?.remove?.(d));
};
export const scrollTo = (table_id, row_key, msg) => {
  if (!row_key) return;
  vsf?.refs?.[table_id]?.scrollTo?.(row_key);
  msg && message.success(msg);
};
export const getSelectedRowValues = (table_id) => {
  if (!table_id) return;
  return vsf.refs?.[table_id].getSelectedRowValues().filter((f) => f);
};
export const setMaxHeight = (nodeId, subtract = 0) => {
  const element: any = document?.getElementById(nodeId);
  return `${element?.offsetHeight - Number(subtract) - 55}px`;
};
