import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  Input,
  VSTableAction,
  Button,
  Checkbox,
  CheckboxGroup,
  message,
  Modal,
  Tooltip,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState, useEffect } from 'react';

import { getScrollY, getScrollYRem, isLessThanclientWidth1600 } from '@/utils';
import { useAvoidState, useFetch } from '@/pages/Index/components/func/hooks';
import { onExport } from '@/pages/Index/manage/execution_registration/util';
import Icon from '@/module/Icon';
import Screen from '@/pages/Index/components/screen';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import CustomSelect from './custom_select';
import DrugStocktakingTypeList from './drug_stocktaking_type_list';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { modalize } from '@/module/Modalize';
const DrugStorageProfileTable = (props) => {
  // 盘点分类list
  const [StocktakingList, setStocktakingList] = useState();

  const Stocktaking = useRequest(
    async () => {
      const res =
        await vsf?.services?.DrugStocktakingTypeController_getDrugStocktakingList_9a0124?.();

      setStocktakingList(res?.data);
    },
    {
      manual: true,
    },
  );
  useEffect(() => Stocktaking?.run(), []);
  const [warningNumberRequired, setWarningNumberRequired] = useState(false);

  const isStockIsRef = useRef();
  const currentDrugRef = useRef();
  const highLevelRef = useRef();
  const lowLevelRef = useRef();
  const canFetchRef = useRef(true);

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    if (canFetchRef.current) {
      params.search.drugProductIdIs = params?.search?.drugProductIdIs?.value;
      const res =
        await vsf.services?.DrugStorageProfileManageController_queryPagefallByQueryProfileProductWithInputQto_fa7f60?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params.pagination?.from ?? 0,
              size: 1000000,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              classCodeIn: params?.search?.classCodeIn?.value ?? undefined,
              isStockIs: isStockIsRef.current ? true : undefined,
            },
            ext: {},
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // TODO: 你需要自己补充入参input来源及出参output用法
      return {
        data: (res?.data?.result ?? res?.data ?? []).map((m) =>
          convertQueryPagefallByQueryProfileProductWithInputQtoRes2VSDrugStorageProfileRo(
            m,
          ),
        ),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);

  /**
   * convertVSDrugStorageProfileRo2SaveDrugStorageProfileReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSDrugStorageProfileRo2SaveDrugStorageProfileReq = (input) => {
    const _ = window._ ?? {};
    const output = {}; // TODO output.btoParams = []; // List<DrugStorageProfileDto> undefined
    output.highLevel = _.get(input, 'highLevel');
    output.lowLevel = _.get(input, 'lowLevel');
    output.warningNumber = _.get(input, 'warningNumber');
    output.warningIndicator = _.get(input, 'warningIndicator');
    output.location = _.get(input, 'location');
    output.subDrugStorageId = _.get(input, 'subStorageId');
    output.drugProductId = _.get(input, 'drugProductId');
    output.applicationCount = _.get(input, 'applicationCount');
    output.warningNumber = _.get(input, 'warningNumber');
    output.warningIndicator = _.get(input, 'warningIndicator');
    output.stocktakingIndicator = _.get(input, 'stocktakingIndicator');
    output.profileExpirationDate = _.get(input, 'profileExpirationDate');
    output.amountPerPackage = _.get(input, 'amountPerPackage');
    output.id = `${_.get(input, 'id')}`?.includes('create_')
      ? undefined
      : _.get(input, 'id');
    output.stocktakingCode = _.get(input, 'stocktakingCode');
    return output;
  };
  /**
   * convert2
   * @param {any} input
   * @returns {any}
   */
  const convert2 = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParams = {}; // TODO output.btoParams.id = null; // Long 主键
    // TODO output.btoParams.drugStorageProfileBtoList = []; // List<DrugStorageProfileBto> null
    // TODO output.btoParams.drugStockSplitBtoList = []; // List<DrugStockSplitBto> null
    output.btoParams.id = input?.subStorageId;
    return output;
  };
  /**
   * convertQueryPagefallByQueryProfileProductWithInputQtoRes2SaveDrugStorageProfileReq
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagefallByQueryProfileProductWithInputQtoRes2SaveDrugStorageProfileReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {}; // TODO output.btoParams = []; // List<DrugStorageProfileDto> null
      output.btoParams = [];
      params.forEach((input) => {
        const item = {};
        item.drugStorageId = input?.subDrugStorageInfo?.drugStorage?.id;
        item.subDrugStorageId = input?.subDrugStorageInfo?.id;
        item.drugProductId = input?.drugProductInfo?.id;
        item.highLevel = input?.highLevel;
        item.lowLevel = input?.lowLevel;
        item.location = input?.location;
        item.warningIndicator = input?.warningIndicator;
        item.profileExpirationDate = input?.profileExpirationDate;
        item.stocktakingIndicator = input?.stocktakingIndicator;
        item.applicationCount = input?.applicationCount;
        item.warningNumber = input?.warningNumber;
        output.btoParams.push(item);
      });
      return output;
    };
  /**
   * convertQueryPagefallByQueryProfileProductWithInputQtoRes2VSDrugStorageProfileRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagefallByQueryProfileProductWithInputQtoRes2VSDrugStorageProfileRo =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.drugName = _.get(input, 'drugProductInfo.drugInfo.drugName');
      output.drugProductName = _.get(input, 'drugProductInfo.drugProductName');
      output.drugSpecification = _.get(
        input,
        'drugProductInfo.drugSpecification',
      );
      output.unit = _.get(input, 'drugProductInfo.unit');
      output.firmName = _.get(input, 'drugProductInfo.drugFirmInfo.firmName');
      output.amountPerPackage = _.get(input, 'amountPerPackage');
      output.packageSpecification = _.get(input, 'packageSpecification');
      output.packageUnit = _.get(input, 'packageUnit');
      output.amount = _.get(input, 'amount');
      output.highLevel = _.get(input, 'highLevel');
      output.lowLevel = _.get(input, 'lowLevel');
      output.warningNumber = _.get(input, 'warningNumber');
      output.warningIndicator = _.get(input, 'warningIndicator');
      output.location = _.get(input, 'location');
      output.profileExpirationDate = _.get(input, 'profileExpirationDate');
      output.stocktakingIndicator = _.get(input, 'stocktakingIndicator');
      output.applicationCount = _.get(input, 'applicationCount'); // TODO
      output.stocktakingCode = _.get(input, 'stocktakingCode'); // String 盘点类型编码
      output.id = _.get(input, 'id');
      output.subStorageId = _.get(input, 'subDrugStorageInfo.id');
      output.drugProductId = _.get(input, 'drugProductInfo.id');
      output.classCode = _.get(input, 'drugProductInfo.drugInfo.classCode');
      output.drugIndicator = _.get(
        input,
        'drugProductInfo.drugInfo.drugIndicator',
      );
      output.toxicCode = _.get(input, 'drugProductInfo.drugInfo.toxicCode');
      output.inputCode = _.get(
        input,
        'drugProductInfo.drugInfo.drugNameDictionary.inputCode',
      ); // TODO output.isStock = null; // Boolean 仅显示可供
      return output;
    };

  const handelRemove = async (params) => {
    // Tips: 函数入参结构和组件RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStorageProfileManageController_deleteDrugStorageProfile_b7627e?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          id: params?.id,
        },
      ); // Tips: 需要返回删除成功与否
    if (res?.code === 200) {
      message.success('已删除');
      canFetchRef.current = true;
      vsf?.refs?.vSDrugStorageProfile?.reload();
    }
    return res?.code === 200;
  };

  const handelRecord = () => {
    return {
      id: `create_${Math.random()}`,
    };
  };

  const handelFormDrugSelectLodaData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugStorageProfileManageController_queryPagefallByQueryProfileProductWithInputQto_fa7f60?.(
        {
          qto: {
            inputCodeLike,
          },
        },
      );
    const data = response?.data?.result ?? [];
    return (
      data?.map((item) => ({
        ...item,
        key: item?.drugProductInfo?.id,
        drugProductName: item?.drugProductInfo?.drugProductName,
        drugSpecification: item?.drugProductInfo?.drugSpecification,
        firmName: item?.drugProductInfo?.drugFirmInfo?.firmName,
      })) ?? []
    );
  }, []);

  const handelTableDrugSelectLoadData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugStorageProfileManageController_queryDrugProductWithNotProfiles_3aafb4?.(
        {
          qto: {
            inputCodeLike,
          },
        },
      );
    const data = response?.data?.result ?? [];

    return (
      data?.map((item) => ({
        ...item,
        key: item?.drugProductInfo?.id,
        drugName: item?.drugProductInfo?.drugInfo?.drugName,
        drugProductName: item?.drugProductInfo?.drugProductName,
        drugSpecification: item?.drugProductInfo?.drugSpecification,
        firmName: item?.drugProductInfo?.drugFirmInfo?.firmName,
      })) ?? []
    );
  }, []);

  const handelDrugNameSelectChange = (v) => {
    currentDrugRef.current = v;
    // from ID为 vSDrugStorageProfile 表单
  };

  const handelSaveClick = async (params) => {
    const values = await vsf.refs?.vSDrugStorageProfile?.getValues?.();
    const editValues = await vsf?.refs?.vSDrugStorageProfile
      ?.getEditForm()
      ?.getFieldFormatValue();
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStorageProfileManageController_saveDrugStorageProfile_f45669?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          btoParams: (values ?? [])
            ?.filter(
              (item) =>
                item?.isUpdate ||
                (typeof item?.id === 'string' && item?.id?.includes('create_')),
            )
            .map((m) =>
              convertVSDrugStorageProfileRo2SaveDrugStorageProfileReq(
                typeof m?.id === 'string' && m?.id.includes('create_')
                  ? editValues?.[m?.id]
                  : m,
              ),
            ),
        },
      ); // TODO: 你需要自己补充入参input来源及出参output用法
    // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      message.success('已保存');
      currentDrugRef.current = undefined;
      highLevelRef.current = undefined;
      lowLevelRef.current = undefined;
      canFetchRef.current = true;
      vsf?.refs?.vSDrugStorageProfile?.reload();
    }
    return res?.data;
  };

  const onStockingClick = (record) => {
    modalize({
      content: (
        <Modal
          open
          title="盘点与库位码设置"
          width="54.26rem"
          cancelText="取消"
          okText="确认"
          bodyStyle={{
            height: '31.46rem',
            padding: 0,
          }}
        >
          <DrugStocktakingTypeList
            value={record}
            StocktakingList={StocktakingList}
          />
        </Modal>
      ),
      onOk: async () => {
        const values = await vsf?.refs?.vSPromiseTable615191?.getValues();
        // Tips: 以下是你选择的请求函数
        const res =
          await vsf.services?.DrugStocktakingTypeController_saveDrugStocktakingTypes_f2d3f1?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              drugStocktakingTypeDtos: values?.map((item) => ({
                ...item,
                id:
                  typeof item?.id === 'string' && item?.id?.includes('create_')
                    ? undefined
                    : item?.id,
                drugStorageProfileId: record?.id,
              })),
            },
          ); // TODO: 你可能需要整理返回数据格式
        if (res?.code === 200) vsf?.refs?.vSDrugStorageProfile?.reload();
        return;
      },
    });
  };

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  const handelOptionRender = (_, __, dom) => {
    // const [, , [other, search]] = arr
    return (
      <SearchContainer.Header
        title={
          <div style={{ display: 'flex' }}>
            <div>筛选</div>
            <div className="search_header_check_view">
              <Checkbox
                onChange={(e) => {
                  isStockIsRef.current = e;
                }}
              >
                仅显示可供药品
              </Checkbox>
            </div>
          </div>
        }
      >
        {dom}
      </SearchContainer.Header>
    );
  };

  const onExportLoadDown = useFetch({
    fetch: async () => {
      const dataList = await vsf?.refs?.vSDrugStorageProfile?.getValues();

      try {
        onExport({
          title: '药品库存定义',
          columns: [
            {
              header: '自编码',
              key: 'drugProductId',
              width: 20,
            },
            {
              header: '药品名称',
              key: 'drugProductName',
              width: 40,
            },
            {
              header: '规格',
              key: 'drugSpecification',
              width: 10,
            },
            {
              header: '单位',
              key: 'unit',
              width: 10,
            },
            {
              header: '厂家',
              key: 'firmName',
              width: 40,
            },
            {
              header: '包装数量',
              key: 'amountPerPackage',
              width: 10,
            },
            {
              header: '包装规格',
              key: 'packageSpecification',
              width: 20,
            },
            {
              header: '包装单位',
              key: 'packageUnit',
              width: 10,
            },
            {
              header: '库存量',
              key: 'amount',
              width: 10,
            },
            {
              header: '上限',
              key: 'highLevel',
              width: 10,
            },
            {
              header: '下限',
              key: 'lowLevel',
              width: 10,
            },
            {
              header: '报警数量',
              key: 'warningNumber',
              width: 10,
            },
            // 少
            // {
            //   header: '消耗量',
            //   key: 'showAmount',
            //   width: 10,
            // },
            // 少
            // {
            //   header: '下限/消耗量',
            //   key: 'batchNumber',
            //   width: 10,
            // },
            {
              header: '货位',
              key: 'location',
              width: 10,
            },
          ],
          value: dataList,
        });
        return {
          code: 200,
        };
      } catch (e) {
        console.log(e);
        return {
          code: 500,
        };
      }
    },
    message: {
      loading: '正在导出中',
      error: '导出失败',
      success: '导出成功',
    },
  });
  return (
    <Screen>
      <VSPromiseTable
        vsid="46339"
        id="vSDrugStorageProfile"
        className="aspirin-table"
        scroll={{
          x: '180rem',
          y: getScrollYRem(30),
        }}
        editable={{
          editType: 'multiple',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          columnProps: {
            width: '7rem',
            hideInTable: true,
          },
          onFieldChange: async (key, value, record, form) => {
            const _record = {
              ...record,
              drugProductName:
                currentDrugRef.current?.drugProductInfo?.drugProductName,
              drugSpecification:
                currentDrugRef.current?.drugProductInfo?.drugSpecification,
              unit: currentDrugRef.current?.drugProductInfo?.unit,
              firmName:
                currentDrugRef.current?.drugProductInfo?.drugFirmInfo?.firmName,
              amountPerPackage: currentDrugRef.current?.amountPerPackage,
              packageSpecification:
                currentDrugRef.current?.packageSpecification,
              packageUnit: currentDrugRef.current?.packageUnit,
              amount: currentDrugRef.current?.amount,
              // profileExpirationDate: currentDrugRef.current?.profileExpirationDate,
              drugProductId: currentDrugRef.current?.drugProductInfo?.id,
              drugName:
                currentDrugRef.current?.drugProductInfo?.drugInfo?.drugName,
            };

            canFetchRef.current = false;
            if (value?.drugName) {
              const tableValues =
                await vsf.refs?.vSDrugStorageProfile?.getValues();
              if (
                tableValues?.find(
                  (item) => item?.drugProductId === value?.drugName,
                )
              ) {
                form.setFieldValue(key, {
                  ..._record,
                  drugName: null,
                });
                return message.error('库存定义中存在该药品，请重新选择！');
              }
              form.setFieldValue(key, {
                ..._record,
              });
            }

            if (value?.warningIndicator) {
              form.setFieldValue(key, {
                ...record,
                warningIndicator: false,
              });
              Modal.confirm({
                mask: false,
                title: '提示',
                content: '确认启用自动禁用？',
                onOk: () => {
                  // setWarningNumberRequired(true)
                  form.setFieldValue(key, {
                    ...record,
                    warningIndicator: true,
                  });
                },
              });
            } else {
              // setWarningNumberRequired(false)
            }
          },
          onFieldBlur: () => {
            if (highLevelRef.current < lowLevelRef.current) {
              message.error('[上限]不能小于或等于[下限]');
            }
          },
        }}
        pagination={false}
        onFetch={onFetch}
        onRemove={handelRemove}
        searchFormRender={(props, dom) => (
          <SearchContainer>{dom}</SearchContainer>
        )}
        searchConfig={{
          className: 'vSDrugStorageProfile',
          labelAlign: 'left',
          collapsed: false,
          collapseRender: false,
          optionRender: handelOptionRender,
          searchButtonProps: {
            onClick: () => {
              canFetchRef.current = true;
              vsf?.refs?.vSDrugStorageProfile?.reload();
            },
          },
        }}
        onRecord={handelRecord}
        onUpdate={(params) => ({
          ...params,
          isUpdate: true,
        })}
        rowClick={{
          rowClickType: {
            action: 'double-click',
            type: 'edit',
          },
        }}
      >
        <VSTableToolbar title="药品库存定义" style={{ padding: '0 1.6rem' }}>
          <VSTableAddition children="新增" position="top" />
        </VSTableToolbar>

        <VSTableColumn
          width="5.33rem"
          title="序号"
          valueType="index"
          render={(_, __, index) => index + 1}
          preview
        />

        <VSTableColumn
          dataIndex={['drugProductIdIs']}
          title="药品名称"
          valueType="select"
          search
          order={0}
          columnKey={'drugProductIdIs'}
          hideInTable
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width="33.33rem"
                placeholder="请输入药品名称"
                showLabel="drugProductName"
                handelDrugNameSelectChange={handelDrugNameSelectChange}
                loadData={handelFormDrugSelectLodaData}
                columns={[
                  {
                    title: '名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '规格',
                    dataIndex: 'drugSpecification',
                    key: 'drugSpecification',
                  },
                  {
                    title: '厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          dataIndex={['classCodeIn']}
          title="药理分类"
          valueType="aspirinSelect"
          search
          order={-1}
          columnKey={'classCodeIn'}
          hideInTable
          searchFieldProps={{ mode: 'multiple' }}
          searchConfig={{
            transform: (values) => ({
              classCodeIn: !values?.length ? null : values,
            }),
          }}
          dataSource={vsf.stores?.dbenums?.enums?.DRUG_CLASS_DICT}
          fieldProps={{
            isTreeSelect: true,
            isNeedBuildData: false,
            treeSelectProps: {
              treeCheckable: true,
              placeholder: '请选择药理分类',
            },
          }}
        />

        <VSTableColumn
          dataIndex={['toxicCodeIs']}
          title="毒理分类"
          valueType="select"
          search
          order={-2}
          columnKey={'toxicCodeIs'}
          hideInTable
          fieldProps={{
            showSearch: true,
            dataSource: vsf.stores.dbenums.enums.DRUG_TOXI_PROPERTY_DICT,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
        />

        <VSTableColumn
          dataIndex={['drugIndicatorIs']}
          title="药品类别"
          valueType="select"
          search
          order={-3}
          columnKey={'drugIndicatorIs'}
          hideInTable
          fieldProps={{
            showSearch: true,
            dataSource: vsf.stores.dbenums.enums.DRUG_INDICATOR_DICT,
          }}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_INDICATOR_DICT}
        />

        <VSTableColumn
          width="13.7rem"
          dataIndex={['drugName']}
          title="药品名称"
          valueType="select"
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请选择药品',
              },
            ],
          }}
          preview={(_, row) => !!row?.id}
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;

            return (
              <Selected
                Detection={async (v) => {
                  return await vsf.refs.vSDrugStorageProfile
                    .getValues()
                    ?.find(
                      (ele) =>
                        ele?.drugProductId === v?.drugProductInfo?.id ||
                        ele?.drugName?.value === v?.drugProductInfo?.id,
                    );
                }}
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width="58rem"
                placeholder="请输入药品名称"
                showLabel="drugName"
                handelDrugNameSelectChange={handelDrugNameSelectChange}
                loadData={handelTableDrugSelectLoadData}
                isPreview={
                  schema?.entity?.id &&
                  !`${schema?.entity?.id}`?.includes('create_')
                }
                columns={[
                  {
                    title: '药品名称',
                    dataIndex: 'drugName',
                    key: 'drugName',
                  },
                  {
                    title: '商品名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '规格',
                    dataIndex: 'drugSpecification',
                    key: 'drugSpecification',
                  },
                  {
                    title: '厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          width="13.33rem"
          dataIndex={['drugProductName']}
          title="商品名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['drugSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['unit']}
          title="单位"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
          preview
        />

        <VSTableColumn
          width="10.66rem"
          dataIndex={['firmName']}
          title="厂家"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['amountPerPackage']}
          title="包装数量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['packageSpecification']}
          title="包装规格"
          valueType="text"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['packageUnit']}
          title="包装单位"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
          preview
        />

        <VSTableColumn
          width="9.33rem"
          dataIndex={['amount']}
          title="库存量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['highLevel']}
          title="上限"
          valueType="digit"
          fieldProps={{
            onChange: (e) => (highLevelRef.current = e),
          }}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['lowLevel']}
          title="下限"
          valueType="digit"
          fieldProps={{
            onChange: (e) => (lowLevelRef.current = e),
          }}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['warningNumber']}
          title="报警数量"
          valueType="digit"
          fieldProps={{}}
          initialValue={0}
          formItemProps={{
            rules: [
              {
                message: '请输入合法值',
                type: 'number',
                min: 0,
              },
            ],
          }}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['warningIndicator']}
          title="自动禁用标识"
          valueType="check"
          fieldProps={{}}
          render={(dom, row) => {
            if (row?.warningIndicator) return <CheckOutlined />;

            return <CloseOutlined />;
          }}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['location']}
          title="货位"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="9.33rem"
          dataIndex={['profileExpirationDate']}
          title="有效期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD' }}
          // preview
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['stocktakingIndicator']}
          title="停止盘点"
          valueType="check"
          fieldProps={{}}
          render={(dom, row) => {
            if (row?.stocktakingIndicator) return <CheckOutlined />;

            return <CloseOutlined />;
          }}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['applicationCount']}
          title="请领数量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          className="DrugStorageProfile-stocktaking_set"
          width="9.33rem"
          fixed="right"
          title="盘点分类设置"
          valueType="custom"
          preview
          renderFormItem={(_, { record }) => (
            <div
              style={{ cursor: 'pointer', color: '#3276e8' }}
              onClick={() => onStockingClick(record)}
            >
              盘点分类设置
            </div>
          )}
        ></VSTableColumn>

        <VSTableColumn
          width="20rem"
          dataIndex={['stocktakingCode']}
          title="盘点分类货位"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
          preview
          ellipsis
          renderFormItem={({ index }) => (
            <Tooltip
              title={
                vsf?.refs?.vSDrugStorageProfile?.getRowValue?.(index)
                  ?.stocktakingCode
              }
            >
              {
                vsf?.refs?.vSDrugStorageProfile?.getRowValue?.(index)
                  ?.stocktakingCode
              }
            </Tooltip>
          )}
        />
      </VSPromiseTable>
      <div className="footer">
        <Button
          onClick={onExportLoadDown}
          style={{ borderColor: '#3276E8', color: '#3276E8' }}
        >
          导出
        </Button>
        <Button type="primary" onClick={handelSaveClick}>
          保存
        </Button>
      </div>
    </Screen>
  );
};

export default definePage(DrugStorageProfileTable);
