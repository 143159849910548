import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';
import { message, Modal, Section, TextArea } from '@vs/vsf-kit';
import React, { memo, useRef } from 'react';
import Icon from '@/module/cpoe/Icon';
import PaitentMergeSearch from './patient_merge_search';
import PaitentMergeDetail from './patient_merge_detail';
import Block from '@/module/Block';
import { cloneDeep } from 'lodash';

import './index.less';

const PatientMerge = (props) => {
  const memoRef = useRef('');

  const handelRightClick = async () => {
    const selectedRows =
      await vsf?.refs?.vSPromiseTablePatientList?.getSelectedRowValues();
    const tableValue = await vsf.refs.vSPromiseTablePatientList?.getValues();

    const originData = cloneDeep(
      selectedRows?.filter((item) => !item?.isMerged),
    );
    const deleteData = cloneDeep(selectedRows);
    const realData = cloneDeep(originData);
    originData?.forEach((item, _idx) => {
      if (item?.patientMergeWithProfileDtoList?.length) {
        realData.splice(
          realData?.findIndex((v) => v?.id === item?.id) + 1,
          0,
          ...convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
            item?.patientMergeWithProfileDtoList?.map((v) => ({
              ...v?.patientRelated,
              isMerged: true,
            })),
          ),
        );
        deleteData.splice(
          realData?.findIndex((v) => v?.id === item?.id) + 1,
          0,
          ...convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
            item?.patientMergeWithProfileDtoList?.map((v) => ({
              ...v?.patientRelated,
              isMerged: true,
              rowKey: tableValue?.find((vv) => vv?.id === v?.patientRelated?.id)
                ?.rowKey,
            })),
          ),
        );
      }
    });

    if (realData?.length) {
      const detailRows = (
        (await vsf.refs.VSTablePatientMergeDetail.getValues()) ?? []
      )?.map((item) => item?.id);

      const addData = realData?.filter(
        (item) => !detailRows?.includes(item?.id),
      );
      if (addData?.length) {
        await vsf?.refs?.VSTablePatientMergeDetail?.add(addData);
        await vsf.refs?.PaitentMergeDetail?.setSelectRows(originData);
        vsf?.refs?.vSPromiseTablePatientList?.remove(deleteData);
      }
    }
  };

  const handelLeftClick = async () => {
    const selectedRows =
      await vsf.refs.VSTablePatientMergeDetail.getSelectedRowValues();

    const originData = cloneDeep(
      selectedRows?.filter((item) => !item?.isMerged),
    );
    const deleteData = cloneDeep(selectedRows);
    const realData = cloneDeep(originData);
    originData?.forEach((item, _idx) => {
      if (item?.patientMergeWithProfileDtoList?.length) {
        realData.splice(
          realData?.findIndex((v) => v?.id === item?.id) + 1,
          0,
          ...convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
            item?.patientMergeWithProfileDtoList?.map((v) => ({
              ...v?.patientRelated,
              isMerged: true,
              rowKey: Math.random(),
            })),
          ),
        );
        deleteData.splice(
          realData?.findIndex((v) => v?.id === item?.id) + 1,
          0,
          ...convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo(
            item?.patientMergeWithProfileDtoList?.map((v) => ({
              ...v?.patientRelated,
              isMerged: true,
            })),
          ),
        );
      }
    });

    if (realData?.length) {
      const selectedRows = (
        (await vsf.refs.vSPromiseTablePatientList.getValues()) ?? []
      )?.map((item) => item?.id);

      const addData = realData?.filter(
        (item) => !selectedRows?.includes(item?.id),
      );
      if (addData?.length) {
        await vsf?.refs?.vSPromiseTablePatientList?.add(addData);
        await vsf.refs?.PaitentMergeSearch?.setSelectRows(originData);
        vsf?.refs?.VSTablePatientMergeDetail?.remove(deleteData);
      }
    }
  };
  const handelTextareaChange = (e) => {
    memoRef.current = e.target?.value;
  };

  const handelMergeClick = async (params) => {
    console.debug('合并点击');
    const detailValue = await vsf.refs.VSTablePatientMergeDetail?.getValues();
    if (!detailValue?.length) {
      message.error('请选择主索引移入右侧再进行操作');
      return;
    }
    const selectedRows =
      await vsf.refs.VSTablePatientMergeDetail.getSelectedRowValues();
    const retainedId = await vsf.refs?.PaitentMergeDetail?.getRetainedId();
    const patientRetainDisplay =
      await vsf.refs?.PaitentMergeDetail?.getPatientRetainDisplay();
    if (!retainedId) {
      message.error('请选择合并保留项');
      return;
    }
    console.debug(selectedRows);
    console.debug(retainedId);
    const patientRetain = retainedId;
    const createPatientMerges = [];
    selectedRows?.forEach((row) => {
      const createPatientMerge = {};
      const patientId = row.id;
      const patientDisplayId = row.displayId;
      if (patientRetain !== patientId) {
        createPatientMerge.patientRetain = patientRetain;
        createPatientMerge.patientRelated = patientId;
        createPatientMerge.patientRetainDisplay = patientRetainDisplay;
        createPatientMerge.patientRelatedDisplay = patientDisplayId;
        createPatientMerges.push(createPatientMerge);
      }
    });
    console.debug(createPatientMerges);
    const _extApiParam = params?.extApiParam ?? {};
    _extApiParam.memo = memoRef.current;
    // console.debug(extApiParam);
    const res =
      await vsf.services?.PatientExtensionController_createPatientMerges_466fca?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          createPatientMergeBtos: createPatientMerges,
          extApiParam: _extApiParam,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res && res.code === 200) {
      message.success('合并成功');
      vsf.refs.VSTablePatientMergeDetail.remove(
        await vsf.refs.VSTablePatientMergeDetail?.getValues(),
      );
      setTimeout(() => {
        vsf.refs?.PaitentMergeSearch?.refresh();
      }, 500);
    }
    return res?.data;
  };

  const handelCancelClick = async (params) => {
    const detailValue = await vsf.refs.VSTablePatientMergeDetail?.getValues();
    if (!detailValue?.length) {
      message.error('请选择主索引移入右侧再进行操作');
      return;
    }
    Modal.confirm({
      title: '提示',
      content: '是否取消右侧列表的合并关系?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const selectedRows =
          vsf.refs.VSTablePatientMergeDetail.getSelectedRowValues();
        console.debug(selectedRows);
        const patientRetainIds = [];
        selectedRows?.forEach((row) => {
          const patientRetainId = row.id;
          if (patientRetainId) {
            patientRetainIds.push(patientRetainId);
          }
        });
        console.debug(patientRetainIds);
        const _extApiParam = params?.extApiParam ?? {};
        _extApiParam.memo = memoRef.current;
        const res =
          await vsf.services?.PatientExtensionController_deletePatientMerges_b92b3b?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              patientRetainIds: patientRetainIds,
              extApiParam: _extApiParam,
            },
          );
        if (res && res.code === 200) {
          message.success('取消合并成功');
          vsf.refs.VSTablePatientMergeDetail.remove(
            await vsf.refs.VSTablePatientMergeDetail?.getValues(),
          );
          setTimeout(() => {
            vsf.refs?.PaitentMergeSearch?.refresh();
          }, 500);
        }
        return res?.data;
      },
    });
  };

  /**
   * convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientListRo = (
    input,
  ) => {
    const _ = window._ ?? {};
    const customResult = input.map((item) => ({
      id: _.get(item, 'id'),
      displayId: _.get(item, 'displayId'),
      name: _.get(item, 'name'),
      gender: _.get(item, 'gender'),
      age:
        (_.get(item, 'age.year') ?? 0) +
        '岁' +
        (_.get(item, 'age.month') ?? 0) +
        '月' +
        (_.get(item, 'age.day') ?? 0) +
        '天',
      cellphone: _.get(item, 'cellphone'),
      idNumber: _.get(item, 'idNumber'),
      patientMergeWithProfileDtoList: _.get(
        item,
        'patientMergeWithProfileDtoList',
      ),
      isMerged: _.get(item, 'isMerged'),
      rowKey: _.get(item, 'rowKey'),
      birthAddress: _.get(item, 'birthAddress'),
    }));
    return customResult;
  };

  return (
    <Container
      layout={{
        direction: 'vertical patient_merge_wapper',
        items: [
          {
            block: 1,
            bgColor: '#fff',
            padding: '0 24px',
            height: '100%',
            overflow: 'hidden',
          },
        ],
      }}
    >
      <div style={{ overflow: 'hidden' }}>
        <div className="main">
          <Block
            style={{
              width: 0,
              flex: 1,
              padding: 0,
            }}
            className="patient_merge_main_left"
          >
            <PaitentMergeSearch id="PaitentMergeSearch" />
          </Block>
          <div className="center">
            <div className="right_icon" onClick={handelRightClick}>
              <Icon type="icon-you1" size={16} />
            </div>
            <div className="left_icon" onClick={handelLeftClick}>
              <Icon type="icon-zuo1" size={16} />
            </div>
          </div>
          <Block
            className="patient_merge_detail_block"
            style={{
              width: 0,
              flex: 1,
              padding: 0,
            }}
          >
            <PaitentMergeDetail id="PaitentMergeDetail" />
            <div className="detail_remark">
              <div className="label">备注</div>
              <TextArea
                className="textareaComp"
                onChange={handelTextareaChange}
              />
            </div>
          </Block>
        </div>
        <div className="footer">
          <div className="normal">清屏</div>
          <div className="normal" onClick={handelCancelClick}>
            取消合并
          </div>
          <div className="normal primary" onClick={handelMergeClick}>
            合并
          </div>
        </div>
      </div>
    </Container>
  );
};
export default definePage(PatientMerge);
