import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Image, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import Block from '@/pages/Index/components/block_no_padding';

import Detail from './detail';
import List from './list';
import Search from './search';

// 检验

const Index = (props) => {
  const {
    currentRecordItem,
    medicalHistoryDetailType,
    showSearch = true,
    stores,
  } = props;
  const { user } = stores ?? {};
  const { initKey, currentPatient, outVisitInfo } = user ?? {};
  const _patientId = currentPatient?.patientId;
  const _outpVisitIdIs = outVisitInfo?.outpVisit?.id;

  // 检查报告列表
  const [reportList, setReportList] = useState<any[]>([]);
  // 报告详情
  const [reportDetail, setReportDetail] = useState();
  // 当前查看报告id
  const [currentReport, setCurrentReport] = useState();
  //报告状态
  const [applyStatusIs, setApplyStatusIs] = useState<boolean>();
  // 是否本次就诊
  const [thisVisit, setThisVisit] = useState<boolean>();
  // 查询条件
  const [applyCategoryIn, setApplyCategoryIn] = useState<string[]>();
  //
  const [searchList, setSearchList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (reportList.length > 0) {
      getExamineDetail(reportList?.[0]?.orderId);
      setCurrentReport(reportList?.[0]?.orderId);
    } else {
      setReportDetail(undefined);
    }
  }, [reportList]);

  console.log(_patientId, currentPatient, '_patientId_patientId_patientId');
  const getExamineList = useCallback(async () => {
    setLoading(true);
    if (!_patientId) {
      setReportList([]);
      getExamineDetail(undefined);
      setCurrentReport(undefined);
      setReportDetail(undefined);
      setLoading(false);
    } else {
      if (
        medicalHistoryDetailType &&
        medicalHistoryDetailType !== 'EXAM_REPORT'
      )
        return;
      // 区分住院或门诊outpVisitIdIs/inpVisitIdIs,currentRecordItem?.id史就诊史得逻辑
      const currentIdType = currentRecordItem?.id
        ? {
            ...(currentRecordItem.visitRecordHistoryType === 'OUTP'
              ? {
                  outpVisitIdIs: currentRecordItem?.id,
                }
              : {
                  inpVisitIdIs: currentRecordItem?.id,
                }),
          }
        : {
            ...(user?.hospitalType === 'in'
              ? {
                  inpVisitIdIs:
                    !searchList || searchList?.thisVisit
                      ? user?.inVisitInfo?.inpVisitId
                      : undefined,
                }
              : {
                  outpVisitIdIs:
                    !searchList || searchList?.thisVisit
                      ? _outpVisitIdIs
                      : undefined,
                }),
          };
      console.log(currentIdType, currentRecordItem, user?.hospitalType);

      const res =
        await vsf.services?.PatientJourneyController_getPatientJourney_95f0ff?.(
          {
            qto: {
              orderClassIn: ['EXAM'],
              patientIdIs: _patientId,
              ...currentIdType,
              isReport: searchList?.isReportValue ?? false,
              examClassId:
                searchList?.selectValue?.includes('all') ||
                searchList?.selectValue?.includes('全部')
                  ? []
                  : searchList?.selectValue,
              // labClassId: '',
              isPatientJourneyIs: false,
              ...(searchList?.radioValue
                ? {
                    submitDateTimeRangeIn: searchList?.radioValue
                      ? {
                          begin: searchList?.radioValue,
                          // beginInclude: true,
                        }
                      : {
                          begin: searchList?.reportTimeRage?.start,
                          end: searchList?.reportTimeRage?.end,
                          // beginInclude: true,
                          // endInclude: true,
                        },
                  }
                : {}),
            },
          },
        );

      setLoading(false);
      if (res.code === 200) {
        setReportList(res.data);
        // if (res?.data.length > 0) {
        //   getExamineDetail(res.data?.[0]?.orderId);
        //   setCurrentReport(res.data?.[0]?.orderId);
        // } else {
        //   setReportDetail(undefined);
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    medicalHistoryDetailType,
    currentRecordItem,
    _patientId,
    searchList,
    user?.hospitalType,
    user?.inVisitInfo?.inVisitId,
    _outpVisitIdIs,
  ]);

  useEffect(() => {
    getExamineList();
  }, [getExamineList]);

  const getExamineDetail = async (id?) => {
    if (!id) {
      setReportDetail(undefined);
    } else {
      const res =
        await vsf?.services?.PatientJourneyController_getPatientJourneyDetail_09a7d6(
          {
            orderId: id,
            icon: 'EXAM',
          },
        );
      if (res.code == 200) {
        setReportDetail(res.data);
      }
    }
  };

  // const handelSearch = (v) => {
  //   setApplyStatusIs(v?.isReportValue);
  //   setThisVisit(v?.thisVisit);
  //   setApplyCategoryIn(v?.selectValue);
  // };
  const handelSearch = (v) => {
    setSearchList(v);
  };

  return (
    <div className="checked">
      {showSearch && (
        <Search
          currentPatient={currentPatient}
          handelConditionQuery={handelSearch}
        />
      )}
      <div className="checked_main">
        {loading ? (
          <Loading size="normal" />
        ) : (
          !reportList ||
          (reportList?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <List
                value={currentReport}
                dataSource={reportList}
                // dataSource={[1, 2, 3, 4]}
                onChange={(item) => {
                  setCurrentReport(item?.orderId);
                  getExamineDetail(item?.orderId);
                }}
              />
              {reportList && reportList?.length > 0 && (
                <Detail reportDetail={reportDetail} />
              )}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
