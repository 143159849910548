import Container from "@/module/Container";
import vsf, { definePage } from "@vs/vsf-boot";
import { Button, Toolbar, VSTableAddition, VSTableToolbar } from "@vs/vsf-kit";
import React, { useState } from "react";
import styled from 'styled-components'
import ProductDefaultUsageEditor from "./productDefaultUsageEditor";
import ProductDepartmentUsageEditor from "./productDepartmentUsageEditor";


const TABS_MENU = [
  {
    label: '默认',
    value: 'default'
  },
  {
    label: '按科室',
    value: 'department'
  }
]


const ProductDefaultUsageRoot = props => {

  const { drugInfo } = props

  const [activityTabsKey, setActivityTabsKey] = useState('default')

  const handelTabsClick = (key) => {
    setActivityTabsKey(key)
  }

  const handelAddClick = async () => {
    const _id = `create_${Math.random()}`
    if (activityTabsKey === 'default') {
      await vsf.refs?.ProductDefaultUsageEditorTable?.add({ id: _id }, 'top')
      await vsf.refs?.ProductDefaultUsageEditorTable?.startEdit(_id)
    } else {
      await vsf.refs?.ProductDepartmentUsageEditorTable?.add({ id: _id }, 'top')
      await vsf.refs?.ProductDepartmentUsageEditorTable?.startEdit(_id)
    }
  }

  const renderToolBar = () => {
    return (
      <div className="ToolBarContainer">
        <div style={{ display: 'flex' }}>
          {
            TABS_MENU?.map(item => (
              <div key={item?.value}
                onClick={() => handelTabsClick(item?.value)}
                className="custom_tabs_item"
              >
                {item?.label}
                {
                  activityTabsKey === item?.value && (
                    <div className="ActivityTabsLine" />
                  )
                }
              </div>
            ))
          }
        </div>
        {/* <Button
          type='primary'
          style={{ marginBottom: 6 }}
          onClick={handelAddClick}
        >+ 新增</Button> */}
      </div>
    )
  }
  console.log(activityTabsKey,'product_activityTabsKey')
  return (
    <>
      {renderToolBar()}
      {/* 默认 */}
      <div
        style={{
          display: activityTabsKey === 'default' ? 'block' : 'none'
        }}
      >
        <ProductDefaultUsageEditor id="ProductDefaultUsageEditor" drugInfo={drugInfo} />
      </div>
      {/* 按科室 */}
      <div
        style={{
          display: activityTabsKey === 'department' ? 'block' : 'none'
        }}
      >
        <ProductDepartmentUsageEditor id="ProductDepartmentUsageEditor" drugInfo={drugInfo} />
      </div>
    </>
  )
}

export default definePage(ProductDefaultUsageRoot)