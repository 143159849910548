import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAddition,
  VSTableToolbar,
  message,
  Select,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import { getScrollY, getScrollYRem } from '@/utils';
import React, { useCallback, useEffect, useState } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import { useRequest } from 'ahooks';

function Table(props) {
  // const [courtyard, setCourtyard] = useState();
  const { data, run } = useRequest(
    async () => {
      const res =
        await vsf?.services?.DrugImportExportController_getCandidateDepartmentList_fc3671?.(
          { code: props?.courtyardParam },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    props.courtyardParam && run();
  }, [props.courtyardParam]);

  useEffect(() => {
    // console.log('刘欢');
    // console.log(props?.selectTreeData);
  }, [props?.selectTreeData]);

  const onFetch = useCallback(
    async (params) => {
      if (props?.selectTreeData?.code) {
        const res =
          await vsf?.services?.DrugImportExportController_getCustomizedImportExportList_833872?.(
            { code: props?.selectTreeData?.code },
          );
        return res?.data;
      }
    },
    [props?.selectTreeData],
  );

  const onSave = async (params) => {
    const data = vsf.refs.RelationshipFormTable?.getValues();

    const formatData = data.map((d) => {
      const dict = {
        ...d,
        clinicRegisterTypeId: props.value?.id,
        priceItemId: d.priceItem?.id,
      };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const filter = formatData.map((item) => {
      return {
        id: item?.id,
        eventType: props?.selectTreeData?.code,
        branchInstitutionCode:
          props.courtyardParam || item?.branchInstitutionCode,
        optionDrugStorageId:
          item?.optionDrugStorage?.departmentName?.id ||
          item?.optionDrugStorage?.id,
      };
    });
    const applicationVsDrugStorageBtoList = data.map((item) => {
      return item?.optionDrugStorage?.departmentName;
    });
    const bto = {
      applicationVsDrugStorageBtoList,
    };
    const res =
      await vsf?.services?.DrugImportExportController_createOrUpdateCustomizeImportExport_b6ee0f?.(
        { bto: { applicationVsDrugStorageBtoList: filter } },
      );
    vsf?.refs?.RelationshipFormTable?.reload();
    return res?.data;
  };

  const onRemove = async (params) => {
    // console.log(params);
    const data = vsf.refs.RelationshipFormTable?.getValues();
    const res =
      await vsf?.services?.DrugImportExportController_deleteCustomizeImportExport_71acdd?.(
        {
          id: params?.id,
        },
      );
    vsf?.refs?.RelationshipFormTable?.reload();
    if (res?.code === 200) {
      message.success('删除成功');
    }
  };
  return (
    <div>
      <VSPromiseTable
        className="aspirin-table"
        pagination={false}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.departmentName}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
          onFieldChange: async (key, value, allValue, form) => {
            if (allValue?.optionDrugStorage?.departmentName) {
              form.setFieldValue(key, {
                ...allValue,
                optionDrugStorage: {
                  departmentCode:
                    allValue?.optionDrugStorage?.departmentName?.departmentCode,
                  departmentName: allValue?.optionDrugStorage?.departmentName,
                },
              });
            }
          },
        }}
        onFetch={onFetch}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        id="RelationshipFormTable"
        scroll={{
          y: getScrollYRem(18),
        }}
        // onUpdate={onUpdate}
        // onAdd={() => {}}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['branchInstitutionCode']}
          title="院区"
          valueType="select"
          // dataSource={vsf.stores.dbenums.enums.BRANCH_HOSP_DICT}
          // fieldProps={{
          //   onSelect: (val) => {
          //     props.onChange(val?.value);
          //   },
          // }}
          fieldProps={{
            dataSource: async (param) => {
              const res =
                await vsf?.services?.OrganizationController_getPagefallByInstitutionListQto_48aa0c?.(
                  {
                    qto: {
                      from: param?.pagination?.from ?? 0,
                      size: param?.pagination?.size ?? 8,
                      ...param?.search,
                      ...param?.extra,
                      ...(param ?? {}),
                    },
                  },
                );
              return res?.data?.result;
            },
            fieldNames: { label: 'institutionName', value: 'id' },
            searchKey: 'searchInputCode',
            showSearch: true,
            allowClear: true,
          }}
        />
        <VSTableColumn
          dataIndex={['optionDrugStorage', 'departmentCode']}
          title="科室代码"
          valueType="select"
          fieldProps={{
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
        />
        <VSTableColumn
          dataIndex={['optionDrugStorage', 'departmentName']}
          title="科室名称"
          valueType="select"
          dataSource={data}
          fieldProps={{
            // dataSource: async () => {
            //   const res =
            //     await vsf?.services?.DrugImportExportController_getCandidateDepartmentList_fc3671?.(
            //       { code: props?.courtyardParam },
            //     );
            //   return res?.data;
            // },
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
        />
        <VSTableToolbar
          title="药房药库列表"
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <VSTableAddition type="dashed" children="添加" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
}

export default Table;
