import { forwardRef, useImperativeHandle } from 'react';
import React from 'react';

import { Determine, Flex } from '@/pages/Index/components';
import { askTotalAmount } from '@/pages/Index/components/func/ask';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';

const ShowAmount = forwardRef((props, ref) => {
  const [amount] = useOnChangeValue<any>([]);

  useImperativeHandle(ref, () => {
    return {
      onChange: amount?.onChange,
    };
  });
  return (
    <Flex>
      合计:
      <span style={{ color: 'var(--blue)' }}>
        <Determine
          condition={Array.isArray(amount?.value)}
          fallback={amount?.value}
        >
          {askTotalAmount(
            amount?.value?.map?.((item) => [
              item?.priceItem?.price,
              item.amount,
            ]) ?? [],
          )}
        </Determine>
      </span>
    </Flex>
  );
});

ShowAmount.displayName = 'ShowAmount';

export default ShowAmount;
