import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageProfileManageController_queryPagefallByQueryProfileProductWithInputQto_fa7f60:
    {
      method: 'post',
      url: '/api/drug-storage/query-pagefall-by-query-profile-product-with-input-qto',
      parameterFomatter: (data?: {
        qto: QueryProfileProductWithInputQtoDrugStoragePersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugStorageProfileProductStockVoDrugStorageEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  DrugStorageProfileManageController_deleteDrugStorageProfile_b7627e: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-storage-profile',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageProfileManageController_queryDrugProductWithNotProfiles_3aafb4: {
    method: 'post',
    url: '/api/drug-storage/query-drug-product-with-not-profiles',
    parameterFomatter: (data?: {
      qto: QueryProfileProductWithInputQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStorageProfileProductStockVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStorageProfileManageController_saveDrugStorageProfile_f45669: {
    method: 'post',
    url: '/api/drug-storage/save-drug-storage-profile',
    parameterFomatter: (data?: {
      btoParams: DrugStorageProfileVoDrugStorageEntranceWebVo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
