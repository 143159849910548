import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationDtoController_getAllByApplicationQto_f4e56e: {
    method: 'post',
    url: '/api/application/get-all-by-application-qto',
    parameterFomatter: (data?: { qto: ApplicationQtoApplicationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVoApplicationEntranceWebVo[],
    ) => data,
  },
});
