import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  DrugController_deleteDrugDictionary_d5ed98: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-dictionary',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugProductUsage_2c8f6a: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product-usage',
    parameterFomatter: (data?: {
      bto: DeleteDrugProductUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_createOrUpdateDrugAll_da9cc0: {
    method: 'post',
    url: '/api/drug-drug/create-or-update-drug-all',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateDrugDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugProduct_8ced4d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugProductBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_getDrugNameDictionaryListByDrugId_aa03ab: {
    method: 'post',
    url: '/api/drug-drug/get-drug-name-dictionary-list-by-durg-id',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugNameDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_queryDrugNameDictionary_32035a: {
    method: 'post',
    url: '/api/drug-drug/query-drug-name-dictionary',
    parameterFomatter: (data?: { input: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryWithDrugDictionaryDtoDrugDrugManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
