import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamMethodDictionaryDtoController_getByExamClassId_994cf6: {
    method: 'post',
    url: '/api/exam/get-method-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamMethodVoExamEntranceWebVo[]) => data,
  },
  ExamClassDictionaryBOController_addAndUpdateExamMethod_bb5113: {
    method: 'post',
    url: '/api/exam/add-and-update-exam-method',
    parameterFomatter: (data?: {
      addMethodToExamClassBtoList: AddMethodToExamClassBtoExamServiceBto[];
      updateExamMethodBtoList: UpdateExamMethodBtoExamServiceBto[];
      removeMethodFromExamClassBtoList: RemoveMethodFromExamClassBtoExamServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
