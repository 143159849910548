import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageController_getDrugPharmacyListByDrugStorageId_c0c9d4: {
    method: 'post',
    url: '/api/drug-storage/get-drug-pharmacy-list-by-drug-storage-id',
    parameterFomatter: (data?: { drugStorageId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_createOrUpdateDrugPharmacyList_ae7231: {
    method: 'post',
    url: '/api/drug-storage/create-drug-pharmacy-list',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugPharmacyBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_getCandicateDrugPharmacyList_3ef044: {
    method: 'post',
    url: '/api/drug-storage/get-candicate-drug-pharmacy-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_deleteDrugPharmacy_f42203: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-pharmacy',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugPharmacyBtoDrugStorageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_getDrugPharmacyListByDepartmentId_7b6a3b: {
    method: 'post',
    url: '/api/drug-storage/get-drug-pharmacy-list-by-department-id',
    parameterFomatter: (data?: { departmentId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_getAllExportImportTypeDictionary_c468af: {
    method: 'post',
    url: '/api/drug-storage/get-all-export-import-type-dictionary',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ImportExportClassDictionaryVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
