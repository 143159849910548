import classnames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

enum TreatTypeEnum {
  OUTP = 'OUTP',
  INP = 'INP',
  ERP = 'ERP',
  PHYSICAL_EXAM = 'PHYSICAL_EXAM',
}

const Index = (props) => {
  const { value, dataSource, onChange } = props;

  const handelTreatType = (type) => {
    if (type === TreatTypeEnum.OUTP) {
      return {
        label: '门诊',
        color: '#3276E8',
      };
    } else if (type === TreatTypeEnum.INP) {
      return {
        label: '住院',
        color: '#FF7D45',
      };
    } else if (type === TreatTypeEnum.ERP) {
      return {
        label: '急诊',
        color: '#F54141',
      };
    } else if (type === TreatTypeEnum.PHYSICAL_EXAM) {
      return {
        label: '体检',
        color: '#30A313',
      };
    }
  };

  return (
    <div className="medical-list hideScroll">
      {dataSource?.map((item, index) => {
        return (
          <div
            key={index}
            className={classnames('item', {
              'active-item': item?.id === value,
            })}
            onClick={() => {
              onChange?.(item);
            }}
          >
            <div className="type" style={{}}>
              <div
                className="bg"
                style={{
                  backgroundColor: handelTreatType(item?.visitRecordHistoryType)
                    ?.color,
                }}
              ></div>
              <div
                className="word"
                style={{
                  color: handelTreatType(item?.visitRecordHistoryType)?.color,
                }}
              >
                {handelTreatType(item?.visitRecordHistoryType)?.label}
              </div>
            </div>
            <div className="content">
              <div className="header">
                <div className="time dinpro-medium">
                  <span>
                    {dayjs(item?.visitTimeDivision).format('YYYY-MM-DD')}
                  </span>
                  {/* <span className="history_time_line"></span>
                  <span>
                    {dayjs(item?.visitTimeDivision).format('YYYY-MM-DD')}
                  </span> */}
                </div>
                <div className="department">{item?.departmentName}</div>
              </div>
              <div className="word">{item?.diagnosis}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
