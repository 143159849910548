import {
  Section,
  VSFormItem,
  VSForm,
  VSDataProvider,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  Checkbox,
  VSPromiseTable,
  Select,
  Tag,
} from '@vs/vsf-kit';
import {
  appendTreeLevel,
  buildTree,
  filterTreeData,
  highlight,
} from '@/components/TreeMenu/utils';
import { getPinYinCode } from 'lancet-pywb/PY_WB';
import { includes, isMatchWith } from 'lodash';
import { async } from 'q';
import React, { useEffect, useState, useCallback } from 'react';
import DragTableRow from '@/pages/Index/components/drag_table_row';
import update from 'immutability-helper';
import { getScrollY } from '@/utils';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TreeMenu } from '@/components';
import Block from '@/pages/Index/components/block';
import FormTitle from '@/pages/Index/components/form_title';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { isMinScreen } from '@/config';

const treeData = [
  {
    title: 'Node1',
    value: '0-0',
    key: '0-0',
    children: [
      {
        title: 'Child Node1',
        value: '0-0-0',
        key: '0-0-0',
      },
    ],
  },
  {
    title: 'Node2',
    value: '0-1',
    key: '0-1',
    children: [
      {
        title: 'Child Node3',
        value: '0-1-0',
        key: '0-1-0',
      },
      {
        title: 'Child Node4',
        value: '0-1-1',
        key: '0-1-1',
      },
      {
        title: 'Child Node5',
        value: '0-1-2',
        key: '0-1-2',
      },
    ],
  },
];

const PermissionMaintenance = (props) => {
  const { data, departmentInfo } = props;
  console.log(data);
  // 工作站列表查询
  // 二级
  const [applicationList, setApplicationList] = useState([]);
  // 一级
  const [applicationList1, setApplicationList1] = useState([]);
  // 已选择的工作站,左侧选择
  const [selectApplicationList, setSelectApplicationList] = useState([]);
  // 右侧table
  const [tableList, setTableList] = useState([]);
  // 未授权
  const [checked, setChecked] = useState(false);
  const [currentEditRow, setCurrentEditRow] = useState();
  const onChangeShow = (checked) => {
    console.log(checked);
    setChecked(checked);
  };
  // 角色下拉选项dataSource
  const getRoleList = async (applicationId) => {
    const res =
      await vsf.services.PrivilegeController_getAllRoleListOfStandardApplication_ce4076(
        {
          bizApplicationId: applicationId,
        },
      );
    return res?.data
      ? res?.data?.map((item) => ({
          ...item,
          roleName: item?.roleName,
          roleId: item?.id,
        }))
      : [];
  };

  useEffect(() => {
    const params = applicationList?.map((i) => {
      return {
        ...i,
        disabled: (tableList ?? [])?.find(
          (item) => i?.id == (item?.application?.id ?? item?.id),
        )
          ? true
          : false,
      };
    });
    const _params = checked ? params?.filter((i) => !i?.disabled) : params;
    setApplicationList([..._params]);
  }, [tableList, selectApplicationList]);
  console.log(currentEditRow, 'currentEditRowcurrentEditRow');
  // 右侧table
  const getTableList = async () => {
    const res =
      await vsf?.services?.ApplicationPrivilegeController_queryApplicationVsStaffBaseVoByQueryStaffApplicationRoleQto_c8b849(
        {
          qto: { staffIdIs: data?.id },
        },
      );
    const _res = res?.data
      ?.map((i) => {
        return { ...i, name: i?.application?.name, oldData: true };
      })
      ?.map((i) => {
        return {
          ...i,
          staffVsRoleWithRoleVoList: i?.staffVsRoleWithRoleVoList?.map(
            (item) => {
              return {
                ...item,
                roleId: item?.role?.id ?? item?.roleId,
                roleName: item?.role?.roleName ?? item?.roleName,
              };
            },
          ),
        };
      });
    if (res?.code == 200) {
      // 一级
      const res1 =
        await vsf?.services?.ApplicationDtoController_getApplication_fbf818({
          template: true,
        });

      setApplicationList1(res1?.data);
      // 二级
      const res2 =
        await vsf?.services?.ApplicationDtoController_getApplication_fbf818({
          template: false,
        });
      setApplicationList(
        res2?.data?.map((i) => {
          return {
            ...i,
            disabled: (_res ?? [])?.find(
              (item) => i?.id == item?.application?.id,
            )
              ? true
              : false,
          };
        }),
      );

      setSelectApplicationList(
        res2?.data
          ?.filter((i) => {
            return (_res ?? [])?.find(
              (item) => i?.id == (item?.application?.id ?? item?.id),
            );
          })
          ?.map((i) => i?.id),
      );
    }
    setTableList([..._res]?.sort((a, b) => a?.sortNumber - b?.sortNumber));

    return _res?.map((i) => {
      return {
        ...i,
        staffVsRoleWithRoleVoList: i?.staffVsRoleWithRoleVoList?.map((item) => {
          return {
            ...item,
            roleId: item?.role?.id ?? item?.roleId,
            roleName: item?.role?.roleName ?? item?.roleName,
          };
        }),
      };
    });
  };

  useEffect(() => {
    getTableList();
  }, [data?.staffDepartment?.id, checked]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  // 移动
  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const res = await vsf?.refs?.applicationStaffRoleList?.saveEdit();
      if (res) {
        const tableValue = vsf?.refs?.applicationStaffRoleList.getValues();
        const dragRow = tableValue[dragIndex];

        setTableList(
          update(tableValue, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
      }
    },
    [tableList],
  );

  const components = {
    body: {
      row: DragTableRow,
    },
  };

  return (
    <div className="permission_maintenance">
      <DndProvider backend={HTML5Backend}>
        <div className="label">
          <Block style={{ paddingTop: 0 }} title="工作站列表">
            <div className="content">
              <div className="tree">
                <TreeMenu
                  id="permissionMaintenanceTree"
                  treeData={[...applicationList1, ...applicationList]}
                  fieldNames={{
                    title: 'name',
                    key: 'id',
                    children: 'children',
                  }}
                  dataRelationFieldNames={{
                    id: 'id',
                    parentId: 'templateApplicationId',
                  }}
                  search={{
                    status: true,
                    searchPlaceholder: '请输入工作站名称',
                    style: { border: '1px solid #dddddd' },
                  }}
                  treeProps={{
                    checkable: true,
                    onCheck: (_value, v) => {
                      console.log(_value, v);
                      if (v?.checked) {
                        setSelectApplicationList([
                          ...selectApplicationList,

                          v?.node?.id,
                          ...(v?.node?.children ?? [])?.map((i) => {
                            return i?.id;
                          }),
                        ]);
                      } else {
                        const _cancel = [
                          v?.node?.id,
                          ...(v?.node?.children ?? [])?.map((i) => {
                            return i?.id;
                          }),
                        ].flat();
                        console.log(_cancel, selectApplicationList);
                        const filter = selectApplicationList?.filter((i) => {
                          const res = _cancel?.find((item) => item == i);

                          return res ? false : true;
                        });
                        console.log(filter, _cancel, 'filter');
                        setSelectApplicationList([...filter]);
                      }
                    },
                    checkedKeys: [...selectApplicationList],
                  }}
                  treeNodeProps={(v) => {
                    console.log(v);
                  }}
                  onSelect={(_value) => {
                    props.onChange?.(
                      _value?.applicationClass ? _value : undefined,
                    );
                  }}
                />
              </div>
              <div className="tip">
                <Checkbox checked={checked} onChange={onChangeShow}>
                  仅显示未授权的工作站
                </Checkbox>
              </div>
            </div>
          </Block>
        </div>
        <div className="icon">
          <Icon
            type="icon-you1"
            size={30}
            onClick={() => {
              const filtered = applicationList?.filter((item) =>
                selectApplicationList.includes(item?.id),
              );
              let _arr = tableList;
              console.log(tableList, filtered, applicationList, 'setChecked');
              filtered.forEach((item) => {
                const _a = tableList?.find(
                  (i) => (i?.application?.id ?? i?.id) == item?.id,
                );
                if (!_a) {
                  _arr = [item, ..._arr];
                  console.log(_arr, _arr, 'setChecked');
                } else {
                  _arr = [..._arr];
                }
              });
              const _filter = _arr?.filter((item) => {
                const _a = selectApplicationList?.find(
                  (i) => i == (item?.application?.id ?? item?.id),
                );
                return _a;
              });
              console.log(_filter, _arr, selectApplicationList);
              setTableList([..._filter]);
            }}
          />
        </div>
        <div className="permission">
          <Block
            style={{
              paddingTop: 0,
              color: '#343B42',
              fontSize: '20px',
              flex: 1,
            }}
            title="已有用户权限"
          >
            <VSTable
              editable={{
                editType: 'single',
                editText: <Icon type="icon-bianji" size={24} />,
                deleteText: <Icon type="icon-shanchu1" size={24} />,
                saveText: <Icon type="icon-a-Frame437" size={24} />,
                cancelText: <Icon type="icon-shanchu1" size={24} />,
                columnProps: {
                  width: 80,
                },
              }}
              className={
                isMinScreen ? 'table_overflow_500' : 'table_overflow_435'
              }
              pagination={false}
              id="applicationStaffRoleList"
              value={tableList}
              onRemove={async (data) => {
                if (!data?.oldData) {
                  // vsf?.refs?.applicationStaffRoleList?.remove(data?.id);
                  setSelectApplicationList([
                    ...selectApplicationList?.filter((i) => i != data?.id),
                  ]);
                  setTableList([
                    ...tableList?.filter(
                      (i) => (i?.application?.id ?? i?.id) != data?.id,
                    ),
                  ]);
                  return true;
                } else {
                  const res =
                    await vsf?.services?.PrivilegeController_deleteApplicationStaffWithRole_7de462?.(
                      { bto: { id: data?.id } },
                    );
                  setTableList([
                    ...tableList?.filter(
                      (i) =>
                        (i?.application?.id ?? i?.id) != data?.application?.id,
                    ),
                  ]);
                  setSelectApplicationList([
                    ...selectApplicationList?.filter(
                      (i) => i != data?.application?.id,
                    ),
                  ]);
                  return res?.code === 200;
                }
              }}
              onRow={(record, index) => {
                const attr = {
                  index,
                  moveRow,
                  onClick: () => {
                    console.log(record, 'record');
                    setCurrentEditRow(record);
                  },
                };
                return attr;
              }}
              scroll={{ x: 500, y: getScrollY(450) }}
              // row={(record) => {
              //   return {
              //     onClick: () => {
              //       console.log(record, 'record');
              //       setCurrentEditRow(record);
              //     },
              //   };
              // }}
              onChange={setTableList}
              components={components}
            >
              <VSTableColumn
                dataIndex={['sortNumber']}
                title="序号"
                valueType="index"
                fieldProps={{}}
                width={50}
              />
              <VSTableColumn
                dataIndex={['name']}
                title="工作站名称"
                valueType="text"
                editable={false}
                formItemProps={{
                  rules: [
                    {
                      message: 'name长度不合法',
                      type: 'string',
                      min: 0,
                      max: 50,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['staffVsRoleWithRoleVoList']}
                title="用户权限"
                valueType="select"
                mode="multiple"
                fieldProps={(record) => {
                  return {
                    mode: 'multiple',
                    dataSource: () => {
                      return getRoleList(record?.application?.id ?? record?.id);
                    },
                  };
                }}
                fieldNames={{ label: 'roleName', value: 'roleId' }}
                dataSource={(_, row) => {
                  return getRoleList(row?.application?.id ?? row?.id);
                }}
                render={(dom, record) => {
                  const target = record?.staffVsRoleWithRoleVoList ?? [];
                  console.log(target, 'targettarget');
                  return target?.map((item) => {
                    return (
                      <Tag color="blue">
                        {item?.role?.roleName ?? item?.roleName}
                      </Tag>
                    );
                  });
                }}
              />
            </VSTable>
          </Block>
        </div>
      </DndProvider>
    </div>
  );
};
export default definePage(PermissionMaintenance);
