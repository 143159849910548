import vsf from '@vs/vsf-boot';

import { askTotalAmount } from '@/pages/Index/components/func/ask';

export enum OrderClass {
  EXAM,
  HERB,
  LAB,
  DRUG,
  DISPOSE,
}

interface Option {
  labOrder: any;
  disposalOrder: any;
  disposeOrder: any;
  orderClass: keyof typeof OrderClass;
  drugOrder: any;
  herbOrder: any;
}
export default function FetchOrder(option: Option) {
  const { orderClass } = option || {};
  const METHOD = {
    DRUG: async (params) => {
      if (params?.drugOrder?.drugItemId) {
        const res =
          await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
            {
              drugItemId: params?.drugOrder?.drugItemId,
            },
          );
        return {
          ...option,
          drugOrder: {
            ...res?.data,
            ...res?.data?.drugEo,
            ...option?.drugOrder,
          },
          ...res?.data?.drugEo,
          ...res?.data,
          detailInfo: {
            ...res?.data,
          },
        };
      }
    },
    TREAT: async (params: any) => {
      const res =
        await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
          {
            clinicItemId: params?.disposalOrder?.itemId,
          },
        );
      return {
        ...option,
        disposalOrder: {
          ...res?.data,
          ...option?.disposalOrder,
          itemName: res?.data?.clinicItemDictionaryVo?.clinicItemName,
        },
      };
    },
    LAB: async (params) => {
      const res =
        await vsf?.services?.ClinicItemController_allClinicItemDictionaryCharge_d97a65?.(
          {
            qto: {
              idIn: [params?.labOrder?.labItemId],
              itemClassIs: 'LAB',
            },
          },
        );
      const [record] = Array?.isArray(res?.data) ? res?.data : [{}];
      return {
        ...option,
        allPrice: askTotalAmount(
          (record?.clinicVsChargeDtoList ?? [])?.map((item) => [
            item?.priceItem?.price ?? 0,
            item?.amount ?? 0,
          ]),
          2,
        ),
        labOrder: {
          ...option?.labOrder,
          labItemName: record?.clinicItemName,
          clinicVsChargeDtoList: record?.clinicVsChargeDtoList,
        },
      };
    },
  };
  if (orderClass) return METHOD?.[orderClass]?.(option) ?? option;
}
