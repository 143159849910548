import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCommonController_existDiagnosisCode_37e076: {
    method: 'post',
    url: '/api/base-common/exist-diagnosis-code',
    parameterFomatter: (data?: {
      qto: IsUniqueDiagnosisQtoBaseCommonPersistQtoSimple;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
