import { createServices } from '@vs/vsf-boot';
export default createServices({
  TreatController_getDepartmentByTreatItemId_e29acb: {
    method: 'post',
    url: '/api/treat/get-department-by-treat-item-id',
    parameterFomatter: (data?: { treatItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatItemVsDepartmentVoTreatEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de: {
    method: 'post',
    url: '/api/organization/get-all-by-department-in-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
