import { labelColWidth, labelColWidthRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Button,
  VSTableToolbar,
  VSTableAddition,
  message,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useCallback, useContext, useRef, useState } from 'react';
import CustomSelect from './custom_select';
import { useRequest } from 'ahooks';
import { CreateScreen } from '@/pages/Index/components/screen';

const DeptApplyDetail = definePage((props) => {
  const { activityBill } = props;
  const [disabled, setDisabled] = useState(false);

  const currentDrugRef = useRef();

  const onScreen = useContext(CreateScreen);

  const { data: storageList } = useRequest(async (params) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugApplyController_getParentStorages_660b1f?.({
        // TODO: 请检查以下这部分传参是否符合需求
      }); // TODO: 你可能需要整理返回数据格式
    return res?.data?.map((item) => ({
      label: item?.provideStorageInfo?.storageName,
      value: item?.provideStorageInfo?.id,
    }));
  });

  const onFetch = useCallback(async (params) => {
    if (params?.extra?.activityBill?.id) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugApplyController_getListDrugApplyMasterDetailProductVoByDrugApplyMasterId_69a8ec?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugApplyMasterId: params?.extra?.activityBill?.id,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // TODO: 你需要自己补充入参input来源及出参output用法
      const output =
        convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDeptApplyDetail(
          res,
        );
      if (res?.data?.length) {
        setDisabled(true);
      }
      return {
        data: output, // res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);
  /**
   * convertQueryDeptDrugApplyDropRes2TableDeptApplyDetail
   * @param {any} input
   * @returns {any}
   */
  const convertQueryDeptDrugApplyDropRes2TableDeptApplyDetail = (data) => {
    const _ = window._ ?? {};
    return data?.map((d) => {
      const obj = {};
      obj.drugProductName = _.get(
        d,
        'drugPriceItemInfo.drugProductInfo.drugProductName',
      );
      obj.packageSpecification = _.get(
        d,
        'drugPriceItemInfo.packageSpecification',
      );
      obj.firmName = _.get(
        d,
        'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
      );
      obj.packageUnit = _.get(d, 'drugPriceItemInfo.packageUnit');
      obj.retailAmount = _.get(d, 'retailAmount');
      obj.key = `random_${Math.random()}`;
      obj.applyNumber = _.get(d, 'applyNumber');
      obj.drugPriceItemId = _.get(d, 'drugPriceItemInfo.id');
      return obj;
    });
  };
  /**
   * convertFormDeptApplyMaster2SubmitDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertFormDeptApplyMaster2SubmitDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = activityBill?.id;
    output.applyNumber = activityBill?.applyNumber; // TODO output.drugApplyMasterDetailVo.applyNumber = null; // String 申请单号
    output.applyType = _.get(input, 'form.applyType'); // TODO output.drugApplyMasterDetailVo.provideStorageId = null; // Long 发放库房
    output.memo = _.get(input, 'form.memo'); // TODO output.drugApplyMasterDetailVo.drugApplyDetailList = []; // List<DrugApplyDetailVo> 主键
    output.provideStorageId = _.get(input, 'form.storageId');
    return output;
  };
  /**
   * convertFormDeptApplyMaster2SaveDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertFormDeptApplyMaster2SaveDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = activityBill?.id;
    output.applyNumber = activityBill?.applyNumber; // TODO output.drugApplyMasterDetailVo.applyNumber = null; // String 申请单号
    output.applyType = _.get(input, 'form.applyType'); // TODO output.drugApplyMasterDetailVo.provideStorageId = null; // Long 发放库房
    output.memo = _.get(input, 'form.memo'); // TODO output.drugApplyMasterDetailVo.drugApplyDetailList = []; // List<DrugApplyDetailVo> 主键
    output.provideStorageId = _.get(input, 'form.storageId');
    return output;
  };
  /**
   * convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDeptApplyDetail
   * @param {any} input
   * @returns {any}
   */
  const convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDeptApplyDetail =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ..._.get(input, 'data').map((d) => {
          const obj = {};
          obj.id = `create_${Math.random()}`;
          obj.drugProductName = _.get(
            d,
            'drugPriceItemInfo.drugProductInfo.drugProductName',
          );
          obj.packageSpecification = _.get(
            d,
            'drugPriceItemInfo.packageSpecification',
          );
          obj.firmName = _.get(
            d,
            'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
          );
          obj.packageUnit = _.get(d, 'drugPriceItemInfo.packageUnit');
          obj.retailAmount = _.get(d, 'retailAmount');
          obj.id = _.get(d, 'id');
          obj.applyNumber = _.get(d, 'applyNumber');
          obj.drugPriceItemId = _.get(d, 'drugPriceItemInfo.id');
          return obj;
        }),
      );
      return output;
    };
  /**
   * convertTableDeptApplyDetail2SaveDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableDeptApplyDetail2SaveDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output =
      input?.map((d) => {
        const obj = {};
        obj.id =
          typeof d.id === 'string' && `${d.id}`.includes('create_')
            ? undefined
            : d.id;
        obj.itemNumber = d.itemNumber; // 项目序号
        obj.retailAmount = d.retailAmount; // 零售数量
        obj.drugPriceItemId = d.drugPriceItemId; // 药品价表编码
        obj.drugProductName = d.drugProductName; // 商品名称
        return obj;
      }) ?? [];
    return output;
  };
  /**
   * convertTableDeptApplyDetail2SubmitDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableDeptApplyDetail2SubmitDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output =
      input?.map((d) => {
        const obj = {};
        obj.id =
          typeof d.id === 'string' && `${d.id}`.includes('create_')
            ? undefined
            : d.id;
        obj.itemNumber = d.itemNumber; // 项目序号
        obj.retailAmount = d.retailAmount; // 零售数量
        obj.drugPriceItemId = d.drugPriceItemId; // 药品价表编码
        obj.drugProductName = d.drugProductName; // 商品名称
        return obj;
      }) ?? [];
    return output;
  };

  const handelDrugNameSelectChange = (v) => {
    currentDrugRef.current = v;
  };

  const handelTableDrugSelectLoadData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugApplyController_queryDeptDrugApplyDrop_1ff628?.({
        qto: {
          inputCodeLike,
        },
      });
    const data = response?.data?.result ?? [];

    return convertQueryDeptDrugApplyDropRes2TableDeptApplyDetail(data);
  }, []);

  const handelFieldChange = (key, value, record, form) => {
    if (value?.drugProductName) {
      form?.setFieldValue(key, {
        ...record,
        packageSpecification: currentDrugRef.current?.packageSpecification,
        firmName: currentDrugRef.current?.firmName,
        packageUnit: currentDrugRef.current?.packageUnit,
        drugPriceItemId: currentDrugRef?.current?.drugPriceItemId,
        drugProductName: currentDrugRef?.current?.drugProductName,
      });
    }
  };

  const handelSubmit = async (params) => {
    const formParam =
      await vsf.refs?.formDeptApplyMaster?.validateFieldsReturnFormatValue();
    await vsf.refs?.tableDeptApplyDetail.getEditForm().validateFields();
    const rows = await vsf.refs?.tableDeptApplyDetail?.getValues();
    // TODO: 你需要自己补充入参input来源及出参output用法
    const output = convertFormDeptApplyMaster2SubmitDrugApplyReq({
      form: formParam,
    }); // TODO: 你需要自己补充入参input来源及出参output用法
    output.drugApplyDetailList =
      convertTableDeptApplyDetail2SubmitDrugApplyReq(rows);
    console.debug(JSON.stringify(output)); // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugApplyController_submitDrugApply_da8985?.({
        // TODO: 请检查以下这部分传参是否符合需求
        drugApplyMasterDetailVo: output,
      }); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      message.success('提交成功！');
      vsf.refs?.tableDeptApplyDetail?.reload();
    }
    return res?.data;
  };

  const handelSave = async (params) => {
    const formParam =
      await vsf.refs?.formDeptApplyMaster?.validateFieldsReturnFormatValue();
    const rows = await vsf.refs?.tableDeptApplyDetail?.getValues();
    if (!rows?.length) return;
    await vsf.refs?.tableDeptApplyDetail.getEditForm().validateFields();
    //
    // TODO: 你需要自己补充入参input来源及出参output用法
    const output = convertFormDeptApplyMaster2SaveDrugApplyReq({
      form: formParam,
    }); // TODO: 你需要自己补充入参input来源及出参output用法
    output.drugApplyDetailList =
      convertTableDeptApplyDetail2SaveDrugApplyReq(rows);
    console.debug(JSON.stringify(output)); // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.DrugApplyController_saveDrugApply_999999?.({
      // TODO: 请检查以下这部分传参是否符合需求
      drugApplyMasterDetailVo: output,
    }); // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      message.success('保存成功!');
      vsf.refs?.tableDeptApplyDetail?.reload();
    }
    return res?.data;
  };

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  console.log(activityBill, '==========activityBill');

  return (
    <>
      <VSControlledForm
        vsid="56335"
        id="formDeptApplyMaster"
        value={props.value}
        labelAlign="left"
      >
        <VSFormLayout
          key="0"
          columnCount={4}
          labelCol={labelColWidthRem('7rem')}
        >
          <VSFormItem
            name={['applyType']}
            label="申请单类型"
            valueType="select"
            initialValue={'16'}
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT,
              defaultValue: '16',
              width: 240,
              disabled: true,
            }}
          />

          <VSFormItem
            name={['storageId']}
            label="上级库房"
            valueType="select"
            fieldProps={{
              width: 240,
              disabled: disabled,
            }}
            dataSource={storageList}
            rules={[
              {
                required: true,
                message: '请选择上级库房',
              },
            ]}
          />

          <VSFormItem
            name={['memo']}
            label="备注"
            valueType="text"
            fieldProps={{
              width: 240,
              placeholder: '最大支持20汉字',
              maxLength: 20,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>

      <VSPromiseTable
        pagination={false}
        className="aspirin-table"
        editable={{
          editType: 'multiple',
          columnProps: {
            width: 80,
            // hideInTable: true
          },
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          onFieldChange: handelFieldChange,
        }}
        vsid="14814"
        id="tableDeptApplyDetail"
        extraParams={{ activityBill }}
        onRecord={() => ({ id: `create_${Math.random()}` })}
        onFetch={onFetch}
      >
        <VSTableToolbar title="药品列表" style={{ padding: '0 24px' }}>
          <VSTableAddition children="新增" />
        </VSTableToolbar>

        <VSTableColumn
          width={80}
          dataIndex={'__idx'}
          title="序号"
          valueType="text"
          preview
          render={(_, __, index) => index + 1}
          renderFormItem={({ index }) => index + 1}
        />

        <VSTableColumn
          width={280}
          dataIndex={['drugProductName']}
          title="药品名称"
          valueType="select"
          formItemProps={{
            rules: [{ required: true, message: '请选择药品' }],
          }}
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width={500}
                placeholder="请输入药品名称"
                showLabel="drugProductName"
                handelDrugNameSelectChange={handelDrugNameSelectChange}
                loadData={handelTableDrugSelectLoadData}
                // isPreview={schema?.entity?.id && !`${schema?.entity?.id}`?.includes('create_')}
                columns={[
                  {
                    title: '药品名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '包装规格',
                    dataIndex: 'packageSpecification',
                    key: 'packageSpecification',
                  },
                  {
                    title: '生产厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          width={130}
          dataIndex={['packageSpecification']}
          title="包装规格"
          preview
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={208}
          dataIndex={['firmName']}
          title="厂家"
          preview
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={90}
          dataIndex={['packageUnit']}
          title="单位"
          preview
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ],
          }}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          width={110}
          dataIndex={['retailAmount']}
          title="请领数量"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请领数量的值不合法',
                type: 'number',
                min: 1,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          title=""
          valueType="custom"
          width={782}
          render={() => null}
        />
      </VSPromiseTable>

      <div className="footer">
        <Button
          style={{
            borderColor: '#3276E8',
            color: '#3276E8',
          }}
          onClick={onScreen}
        >
          清屏
        </Button>
        <Button
          style={{
            borderColor: '#3276E8',
            color: '#3276E8',
          }}
        >
          打印
        </Button>
        <Button type="primary" onClick={handelSubmit}>
          提交
        </Button>
        <Button type="primary" onClick={handelSave}>
          保存
        </Button>
      </div>

      {/* <Button
        children="保存"
        onClick={async (params) => {
          //
          // TODO: 你需要自己补充入参input来源及出参output用法
          const output = convertFormDeptApplyMaster2SaveDrugApplyReq(
            vsf.refs.formDeptApplyMaster.getFieldsFormatValue(),
          ); // TODO: 你需要自己补充入参input来源及出参output用法
          output.drugApplyDetailList =
            convertTableDeptApplyDetail2SaveDrugApplyReq(
              vsf.refs.tableDeptApplyDetail.getValues(),
            );
          console.debug(JSON.stringify(output)); // Tips: 以下是你选择的请求函数
          const res =
            await vsf.services?.DrugApplyController_saveDrugApply_999999?.({
              // TODO: 请检查以下这部分传参是否符合需求
              drugApplyMasterDetailVo: output,
            }); // TODO: 你可能需要整理返回数据格式
          return res?.data;
        }}
      />

      <Button
        children="提交"
        onClick={async (params) => {
          //
          // TODO: 你需要自己补充入参input来源及出参output用法
          const output = convertFormDeptApplyMaster2SubmitDrugApplyReq(
            vsf.refs.formDeptApplyMaster.getFieldsFormatValue(),
          ); // TODO: 你需要自己补充入参input来源及出参output用法
          output.drugApplyDetailList =
            convertTableDeptApplyDetail2SubmitDrugApplyReq(
              vsf.refs.tableDeptApplyDetail.getValues(),
            );
          console.debug(JSON.stringify(output)); // Tips: 以下是你选择的请求函数
          const res =
            await vsf.services?.DrugApplyController_submitDrugApply_da8985?.({
              // TODO: 请检查以下这部分传参是否符合需求
              drugApplyMasterDetailVo: output,
            }); // TODO: 你可能需要整理返回数据格式
          return res?.data;
        }}
      /> */}
    </>
  );
});

export default DeptApplyDetail;
