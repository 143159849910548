import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Section,
  Todo,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import Icon from '@/module/Icon';
import { pinyin as getPinYinCode } from 'pinyin-pro';
import './index.less';
import { getScrollY, getScrollYRem } from '@/utils';
import { ButtonAndKeyDown } from '@/pages/Index/components';
const Firm = (props) => {
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.DrugFirmDictionaryDtoController_queryDrugFirmDictionary_25e16e?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handelAdd = async (params) => {
    try {
      delete params.row__type;
      const requestParam = {
        ...params,
        stopIndicator: params?.hasOwnProperty('stopIndicator')
          ? params?.stopIndicator
          : false,
        id: undefined,
      };
      const response =
        await vsf.services?.DrugFirmDictionaryBOController_createOrUpdateDrugFirmList_4a4641?.(
          {
            createBtoList: [requestParam],
            updateBtoList: [],
          },
        );
      if (response && response.code === 200) {
        return params;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdate = async (params) => {
    try {
      const requestParam = {
        ...params,
        row__type: 'Updata',
      };
      return requestParam;
    } catch (error) {
      console.error(error);
    }
  };
  const onSetTableItmeInputCode = async (id, code, value, ...params) =>
      vsf?.refs?.drugFirmDictionaryTable
        ?.getEditForm?.()
        ?.setFieldValue?.([id, code], value),
    onAdd = async () => {
      const TableReal = await vsf?.refs?.drugFirmDictionaryTable;
      await TableReal?.add({});
      const TableRealValues = await TableReal?.getValues();
      TableReal?.scrollTo(TableRealValues?.[TableRealValues?.length - 1]?.id);
    },
    onSave = async () => {
      await vsf?.refs?.drugFirmDictionaryTable
        ?.getEditForm()
        ?.validateFieldsReturnFormatValue();
      const requestParam = vsf?.refs?.drugFirmDictionaryTable
        ?.getValues()
        ?.filter((ele) => ele?.row__type === 'Updata');
      if (requestParam?.length <= 0) return message?.error('未操作修改');
      const response =
        await vsf.services?.DrugFirmDictionaryBOController_createOrUpdateDrugFirmList_4a4641?.(
          {
            createBtoList: [],
            updateBtoList: requestParam,
          },
        );
      if (response && response.code === 200) {
        message?.success('保存成功。');
        vsf?.refs?.drugFirmDictionaryTable?.reload();
        return params;
      }
    };

  return (
    <div className="firm_container">
      <div className="table">
        <VSPromiseTable
          scroll={{ y: getScrollYRem(28.8) }}
          id="drugFirmDictionaryTable"
          vsid="61667"
          className="aspirin-table common_table"
          bordered
          editable={{
            editType: 'multiple',
            editText: <Icon type="icon-bianji" size={24} />,
            deleteText: <Icon type="icon-shanchu1" size={24} />,
            onFieldChange: (id, { firmNameLike }, _, from) => {
              firmNameLike &&
                onSetTableItmeInputCode(
                  id,
                  'inputCode',
                  getPinYinCode(firmNameLike, {
                    pattern: 'first',
                    toneType: 'none',
                    type: 'string',
                  })
                    ?.toLocaleUpperCase()
                    ?.split(' ')
                    ?.join(''),
                );
            },
          }}
          searchConfig={{
            // labelWidth: 100,
            optionButtons: ['search'],
            optionRender: (...arr) => {
              const [_, __, dom] = arr;
              return (
                <SearchContainer.Header title="筛选">
                  {dom}
                </SearchContainer.Header>
              );
            },
          }}
          searchFormRender={(item, dom) => {
            return (
              <SearchContainer mode="inline" formPadding="0 170px 0 100px">
                {dom}
              </SearchContainer>
            );
          }}
          onRecord={() => {
            return {
              id: Math.random(),
              row__type: 'New',
            };
          }}
          onAdd={handelAdd}
          onUpdate={handelUpdate}
          onFetch={onFetch}
          onRemove={async (params) => {
            const res =
              await vsf?.services?.DrugFirmDictionaryBOController_deleteDrugFirmDictionary_07a09d?.(
                { btoParam: params, extApiParam: params?.extApiParam },
              );
            return res?.code === 200;
          }}
        >
          <VSTableColumn
            dataIndex={['firmId']}
            title="厂商标识/简称"
            valueType="text"
            search
            order={-14}
            columnKey={'firmIdLike'}
            formItemProps={{
              rules: [
                {
                  message: '数据格式错误',
                  type: 'string',
                  min: 0,
                  max: 20,
                  required: true,
                },
              ],
              labelCol: {
                style: {
                  width: '10rem',
                },
              },
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['firmName']}
            title="厂商"
            valueType="text"
            search
            order={-15}
            columnKey={'firmNameLike'}
            formItemProps={{
              rules: [
                {
                  message: '数据格式错误',
                  type: 'string',
                  min: 0,
                  max: 100,
                  required: true,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['inputCode']}
            title="拼音输入码"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '必填',
                  type: 'string',
                  min: 0,
                  max: 10,
                },
              ],
            }}
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            dataIndex={['stopIndicator']}
            title="是否停用"
            valueType="switch"
            search
            order={-16}
            columnKey={'stopIndicatorIs'}
            fieldProps={{}}
          />

          <VSTableToolbar
            title="生产商列表"
            style={{ boxSizing: 'border-box', padding: '0 24px' }}
          >
            {/* <VSTableAddition children="添加" position="top" /> */}
            {/*<Button children="修改" type="primary" onClick={onSave} /> */}
          </VSTableToolbar>
        </VSPromiseTable>
      </div>
      <div className="footer">
        <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
          <ButtonAndKeyDown
            {...{
              type: 'primary',
              disabled: false,
              text: '添加',
              children: '添加',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => onAdd(),
            }}
          />
          <ButtonAndKeyDown
            {...{
              type: 'primary',
              disabled: false,
              text: '修改',
              children: '修改',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => onSave(),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default definePage(Firm);
