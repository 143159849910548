import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPathologyController_getOrderPathologyByOrderId_503ccd: {
    method: 'post',
    url: '/api/clinic-record-order-pathology/get-order-pathology-by-order-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPathologyVoClinicRecordOrderPathologyEntranceWebVo,
    ) => data,
  },
  ExamController_getExamBodyDictionaryByExamClassId_22696a: {
    method: 'post',
    url: '/api/exam/get-exam-body-dictionary-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamBodyVoExamEntranceWebVo[]) => data,
  },
  ExamController_getExamMethodDictionaryByExamClassId_83dd5c: {
    method: 'post',
    url: '/api/exam/get-exam-method-dictionary-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamMethodVoExamEntranceWebVo[]) => data,
  },
  ExamController_getAllByExamClassItemClassQto_4b0f3f: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-item-class-qto',
    parameterFomatter: (data?: { qto: ExamClassItemClassQtoExamPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  ExamController_getAllByExamVsClinicItemDictionaryQto_52658d: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-vs-clinic-item-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ExamVsClinicItemDictionaryQtoExamPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamVsClinicItemDictionaryVoExamEntranceWebVo[],
    ) => data,
  },
});
