import vsf, { definePage } from '@vs/vsf-boot';
import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import { askGetTreeWhole } from '@/pages/Index/components/func/ask';
import Tip from '@/pages/Index/components/func/Tip';
import { Button } from '@vs/vsf-kit';
const TreatClassTree = forwardRef((props, ref) => {
  const value = useRef(false);
  useImperativeHandle(ref, () => ({
    onChange: (state) => {
      value.current = state
    },
  }));
  return (
    <TreeMenu
      id="TreatClassTree"
      title="治疗项目分类"
      reload
      onSelect={async (...[_value, tree, , selectedKeys, oldSelectedKeys]) => {
        const get = () => {
          value.current = false;
          props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
        }
        if(value?.current) {
          return new Promise((resolve) => {
            Tip({
              content: `当前分类未保存，是否确定切换`,
              onOk: () => {
                get?.();
                resolve?.(selectedKeys);
              },
              onCancel: () => {
                resolve(oldSelectedKeys);
              }
            })
          });
        }
        get();
        return selectedKeys;
      }}
      loadData={async (params) => {
        const res =
          await vsf?.services?.TreatController_getAllByTreatClassQto_9c1625?.(
            {
              qto: {
                stopIndicatorIs: false
              }
            },
          );
        return res;
      }}
      fieldNames={{ title: 'treatClassName', key: 'id', children: 'children' }}
      dataRelationFieldNames={{
        title: 'treatClassName',
        id: 'id',
        parentId: 'parentTreatClassId',
        children: 'children',
      }}
      row={{
        extraShow: 'select',
        expand: true,
        extra: (node, _) => {
          return (
            <div className="aspirin-tree-button">
              {
                !node.parentTreatClassId && <Icon
                  type="icon-tianjia"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onAdd(node)
                  }}
                  size={20}
                />
              }
              <Icon type="icon-tingyong" size={20} onClick={(e) => {
                e.stopPropagation();
                props.onStop(node)
              }} />
            </div>
          )
        }
      }}
      headerRender={() => {
        return <Button onClick={() => props.onAdd({})}>新增大类</Button>
      }}
    />
  );
});
export default TreatClassTree;