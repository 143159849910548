import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeController_getOrderListByOutpVisitId_c52096: {
    method: 'post',
    url: '/api/medical-technology/get-order-list-by-outp-visit-id',
    parameterFomatter: (data?: { outpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderWithDrugAndDisposalAndDoctorVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
});
