import {
  Image,
  VSForm,
  VSFormItem,
  VSFormLayout,
  message,
  Select,
  VSTableColumn,
  VSPromiseTable,
  Modal,
  Button,
  Tooltip,
  VSTable,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useState, useCallback, useEffect } from 'react';
import readCard from '@/assets/icon/readCard.png';

import vsf, { definePage } from '@vs/vsf-boot';
import { getScrollY } from '@/utils';
import dayjs from 'dayjs';
import { fetchData } from './Service';
import { Flex } from '@/pages/Index/components';
import { askFixed } from '@/pages/Index/components/func/ask';
import { isMinScreen } from '@/config';

function ClinicOutpVisitListForm({ callback, doubleClikChange, routers }) {
  const payTypeEnum = vsf?.stores?.dbenums.enums?.PAY_WAY_DICT?.reduce(
    (cerrent, next) => {
      cerrent[next?.code] = next?.cname;
      return { ...cerrent };
    },
    {},
  );
  const [identificationClass, setIdentificationClass] = useState();

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const [patientModalOpen, setPatientModalOpen] = useState(false);

  const [selectRowData, setSelectRowData] = useState(false);

  const { data, run } = useRequest(fetchData, { manual: true });

  useEffect(() => {
    if (routers) {
      run({ settleNumberIs: routers });
      vsf?.refs?.cancelHospitalizationSettlementForm?.setFieldsValue({
        settleNumber: routers,
      });
    }
  }, [routers]);

  useEffect(() => {
    data && callback(data);

    if (data) {
      if (data?.length == 0) {
        // vsf?.refs?.cancelHospitalizationSettlementForm?.setFieldsValue({
        //   physicalReceiptNumber: null,
        //   displayId: null,
        //   identityCode: null,
        //   staffName: null,
        //   settleDate: null,
        //   chargeTypeCode: null,
        //   name: null,
        //   patientIdIs: null,
        // });
        // setIdentificationClass(null);
      } else {
        if (data?.length > 1) {
          setPatientModalOpen(true);
        }
        vsf?.refs?.cancelHospitalizationSettlementForm?.setFieldsValue({
          ...data,
        });
      }
    } else {
      vsf?.refs?.cancelHospitalizationSettlementForm?.setFieldsValue(null);
    }
  }, [data]);

  const { data: receiptData, run: receiptRun } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryAllOutpSettleMaster_246314?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...tableParam,
              // settleDateRangeIn: settleDateRangeIn,
            },
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    {
      manual: true,
    },
  );
  const [tableParam, setTableParam] = useState();

  useEffect(() => {
    receiptRun();
  }, [tableParam]);

  const { run: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
        setTableParam({
          displayIdIs: res?.data?.result[0].displayId,
        });
      }
      return res?.data?.result;
    },
    { manual: true },
  );
  useEffect(() => {
    if (selectRowData) {
      run({ settleNumberIs: selectRowData?.inpSettleMasterVo?.settleNumber });
      setPatientModalOpen(false);
    }
  }, [selectRowData]);
  return (
    <div>
      <VSForm id="cancelHospitalizationSettlementForm">
        <VSFormLayout columnCount={2}>
          <VSFormLayout columnCount={2}>
            <VSFormItem
              name={'physicalReceiptNumber'}
              valueType="text"
              label={'票据号'}
              columnKey={'physicalReceiptNumberIs'}
              fieldProps={{
                onPressEnter: (v) => {
                  if (v?.target?.value?.length) {
                    run({ physicalReceiptNumberIs: v.target.value });
                  }
                },
              }}
            />
            <VSFormItem
              valueType="text"
              label={'业务流水号'}
              name={'settleNumber'}
              columnKey={'settleNumberIs'}
              fieldProps={{
                onPressEnter: (e) => {
                  run({ settleNumberIs: e.target.value });
                },
              }}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={1}>
            <VSFormItem
              name={['patientIdIs']}
              label="证件及编号"
              valueType="text"
              width={500}
              fieldProps={{
                style: { paddingLeft: '10px' },
                addonBefore: (
                  <>
                    <Select
                      style={{ width: '200px' }}
                      dataSource={certificateData}
                      fieldNames={{ label: 'name', value: 'code' }}
                      onChange={(v) => {
                        console.log(v);
                        if (v) {
                          setIdentificationClass(v.code);
                        } else {
                          setIdentificationClass(undefined);
                        }
                      }}
                      allowClear
                    />
                  </>
                ),

                suffix: (
                  <div
                    className="suffix-readCard"
                    onClick={() => {
                      message.success('读卡中');
                    }}
                  >
                    <div className="suffix-cardImg">
                      <Image
                        src={readCard}
                        style={{ width: '20px', height: '20px' }}
                        preview={false}
                      ></Image>
                    </div>
                    <div className="suffix-cardText">读卡</div>
                  </div>
                ),
                placeholder: '请读卡',
                onPressEnter: (e) => {
                  if (!identificationClass) {
                    message.error('请选择证件类型');
                  }
                  if (identificationClass === '身份证' && e.target.value) {
                    // setPatientModalOpen(true);
                    // setTableParam({
                    //   identificationClass: identificationClass,
                    //   identificationNumber: e.target.value,
                    // });
                    patientDetallRun({
                      identificationNumberIn: [e.target.value],
                      identificationClassIn: [identificationClass],
                    });
                  } else if (
                    identificationClass === '患者ID' &&
                    e.target.value
                  ) {
                    run({
                      displayIdIs: e.target.value,
                    });
                  }
                },
              }}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout columnCount={6}>
          <VSFormItem
            name={['displayId']}
            valueType="text"
            label={'患者ID'}
            readonly
          />
          <VSFormItem name={'name'} valueType="text" label={'姓名'} readonly />
          <VSFormItem
            name={'chargeTypeCode'}
            valueType="select"
            label={'费别'}
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            readonly
          />
          <VSFormItem
            name={'identityCode'}
            valueType="select"
            label={'人员类别'}
            dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
            readonly
          />
          <VSFormItem
            name={'staffName'}
            valueType="text"
            label={'收款员'}
            readonly
          />
          <VSFormItem
            name={'settleDate'}
            valueType="text"
            label={'收款时间'}
            readonly
          />
        </VSFormLayout>
      </VSForm>
      <Modal
        title={<div style={{ color: '#3276E8' }}>结算记录</div>}
        open={patientModalOpen}
        onCancel={() => setPatientModalOpen(false)}
        width="82%"
        okText="确认"
        cancelText="取消"
        bodyStyle={{
          minHeight: 400,
        }}
      >
        <VSTable
          vsid="42317"
          id="outpSettleMasterQueryList"
          rowClick={{
            type: 'default',
            action: 'double-click',
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setSelectRowData(record);
              },
              onClick: () => {
                setSelectRowData(record);
              },
            };
          }}
          pagination={{ defaultPageSize: 10 }}
          // onFetch={() =>
          // data?.sort(
          //   (a, b) =>
          //     dayjs(b?.inpSettleMasterVo?.settleDate)?.valueOf() -
          //     dayjs(a?.inpSettleMasterVo?.settleDate)?.valueOf(),
          // )
          // }
          value={
            data?.length
              ? data?.sort(
                  (a, b) =>
                    dayjs(b?.inpSettleMasterVo?.settleDate)?.valueOf() -
                    dayjs(a?.inpSettleMasterVo?.settleDate)?.valueOf(),
                )
              : []
          }
          scroll={{
            y: getScrollY(400),
            x: 1500,
          }}
        >
          <VSTableColumn
            title="序号"
            valueType="index"
            fieldProps={{}}
            width={50}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'inpReceiptRecordBaseDtoList']}
            title="票据号"
            valueType="custom"
            fieldProps={{}}
            render={(...[, record]) =>
              record?.inpSettleMasterVo?.inpReceiptRecordBaseDtoList?.[0]
                ?.physicalReceiptNumber
            }
            width={120}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'patient', 'displayId']}
            title="患者ID"
            valueType="text"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'name']}
            title="姓名"
            valueType="text"
            fieldProps={{}}
            width={150}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'chargeTypeCode']}
            title="费别"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores?.dbenums?.enums?.CHARGE_TYPE_DICT}
            width={120}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'identityCode']}
            title="人员类别"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores?.dbenums?.enums?.IDENTITY_DICT}
            width={120}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'totalCost']}
            title="总金额"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(...[, record]) =>
              askFixed(record?.inpSettleMasterVo?.totalCost)
            }
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'totalCharge']}
            title="实收金额"
            valueType="text"
            fieldProps={{}}
            width={120}
            render={(...[, record]) =>
              askFixed(record?.inpSettleMasterVo?.totalCharge)
            }
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'payment', 'paymentDetailDtoList']}
            title="付款方式"
            valueType="custom"
            render={(...[, record]) => {
              let str;
              str = record?.inpSettleMasterVo?.payment?.paymentDetailDtoList
                ?.map((item) => {
                  return `[${payTypeEnum?.[item?.payType]}${askFixed(
                    item?.paymentAmount,
                  )}]`;
                })
                ?.join('，');
              return (
                <Tooltip title={str}>
                  <div className="text-overflow-omit">{str}</div>
                </Tooltip>
              );
            }}
            fieldProps={{}}
          />
          {/* <VSTableColumn
            dataIndex={['drugName']}
            title="住院次数"
            valueType="text"
            fieldProps={{}}
            width={150}
            render={(_,v)=>{
              return '3'
            }}
          /> */}
          {/* <VSTableColumn
            dataIndex={['drugName']}
            title="入院时间"
            valueType="text"
            fieldProps={{}}
            width={150}
          /> */}
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'settleStatusName']}
            title="交费状态"
            valueType="text"
            render={(...[, record]) => {
              const settleStatusName =
                record?.inpSettleMasterVo?.settleStatusName;
              return settleStatusName?.includes?.('废') ||
                settleStatusName === '退费' ? (
                <span style={{ color: 'var(--red)' }}>{settleStatusName}</span>
              ) : (
                settleStatusName
              );
            }}
            // dataSource={[
            //   { label: '未收费', value: 'NOT_CHARGE' },
            //   { label: '已收费', value: 'FINISH_CHARGE' },
            //   { label: '部分退费', value: 'PARTIAL_REFUND' },
            //   { label: '全部退费', value: 'ALL_REFUND' },
            // ]}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'operatorStaff', 'staffName']}
            title="收款员"
            valueType="text"
            fieldProps={{}}
            width={120}
          />
          <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'settleDate']}
            title="收费时间"
            valueType="text"
            fieldProps={{}}
          />
          {/* <VSTableColumn
            dataIndex={['inpSettleMasterVo', 'settleNumber']}
            title="入院时间"
            hideInTable
            valueType="text"
            fieldProps={{}}
            width={150}
            render={(_,v)=>{
              return '2024-01-23 12:00:12:00'
            }}
          /> */}
        </VSTable>
      </Modal>
    </div>
  );
}

export default ClinicOutpVisitListForm;
