import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
  message,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { log } from 'util';

import { getScrollY } from '@/utils';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import vsf, { definePage } from '@vs/vsf-boot';

import DoctorExtInfoEditor from './edit';

import './index.less';

const DoctorInfo = (props) => {
  const { stores } = props;
  const {
    common: { currentApplication },
    user: { setInitKey, initKey },
  } = stores;

  const [departmentNameList, setDepartmentNameList] = useState();
  const [rowId, setRowId] = useState();

  useEffect(() => {
    getDepartmentNameList();
  }, []);

  const fetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.StaffDetailDtoController_queryDoctorList_7b5e02?.({
        qto: {
          from: params.pagination?.from ?? 0,
          size: params.pagination?.size ?? 20,
          orderList: params.orderList, // 排序结果
          ...params.search, // 搜索结果
          ...params.filters, // 过滤器结果
          ...(params?.extra ?? {}),
          employeeNumberIs: params?.search?.employeeNumberIs
            ? params?.search?.employeeNumberIs
            : null,
        },
      });

    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  const getDepartmentNameList = async () => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentQto_1c2a91?.(
        { qto: { departmentNameLike: 1 } },
      );
    const _res = res?.data?.map((item) => {
      return {
        label: item?.departmentName,
        value: item?.id,
      };
    });
    console.log(_res);
    setDepartmentNameList(_res);
    return _res; // todo 整理返回数据格式
  };
  const save = async (params) => {
    const { doctorDto, ..._params } = params;

    const res =
      await vsf?.services?.StaffBOController_updateDoctorInfo_907e11?.({
        btoParam: {
          ..._params,
          doctorBto: {
            ...params?.doctorDto,
            clinicDepartmentId: params?.doctorDto.clinicDepartment?.id,
          },
          staffInfoExtensionBto: {
            staffPhoto: params?.staffInfoNoteDto?.staffPhoto,
          },
        },
      });
    if (res?.code === 200) {
      vsf?.refs?.DoctorList?.reload();
      message.success('保存成功');
      setTimeout(() => {
        setRowId(params?.id);
      }, 500);
      return res?.data;
    }
  };
  const handleReset = () => {
    setInitKey();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: 'transparent',
      }}
    >
      <div className="doctor_info_content">
        <VSPromiseTable
          className="aspirin-table  aspirin-table-header-background-color
          aspirin-table-row-hover-background-color
          aspirin-table-body-empty-transparent-background-color table_overflow_500"
          vsid="14736"
          id="DoctorList"
          bordered
          onFetch={fetch}
          rowClick={{
            rowClickType: 'single',
            onRowClick: (_value) => {
              setRowId(_value?.id);
            },
          }}
          rowClassName={(v) =>
            v.id == rowId ? 'aspirin-table-row-select-background-color' : ''
          }
          searchConfig={{
            labelWidth: 80,
            labelAlign: 'left',
            collapsed: false,
            collapseRender: false,
            searchButtonProps: {
              size: 'large',
              className: 'icon16',
            },
            resetButtonProps: {
              size: 'large',
              className: 'icon16',
            },
            optionRender: (...arr) => {
              const [_, __, dom] = arr;
              return (
                <SearchContainer.Header title="筛选">
                  {dom}
                </SearchContainer.Header>
              );
            },
          }}
          searchFormRender={(item, dom) => {
            return (
              <SearchContainer mode="inline" formPadding="0 170px 0 120px">
                {dom}
              </SearchContainer>
            );
          }}
          tableRender={(props, dom) => {
            return (
              <div
                style={{
                  display: 'flex',
                  height: 620,
                  // backgroundColor: '#fff',
                }}
              >
                <div style={{ flex: 1 }} {...props}>
                  {dom}
                </div>
                <div style={{ padding: '20px 0' }}>
                  <div
                    style={{
                      border: '1px solid #d6d8d9',
                      padding: '20px 0',
                      height: '100%',
                    }}
                  ></div>
                </div>
                <div className="doctorInfo_detail">
                  <DoctorExtInfoEditor rowId={rowId} />
                </div>
              </div>
            );
          }}
          pagination={{
            defaultPageSize: 10,
          }}
          scroll={{ y: getScrollY(500) }}
        >
          <VSTableColumn
            dataIndex={['id']}
            title="id"
            valueType="text"
            search
            columnKey={'idIs'}
            hideInTable
            formItemProps={{
              rules: [
                {
                  message: 'emp_no长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
              ],
            }}
            searchConfig={{
              title: '医生ID',
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['staffNumber']}
            title="工号"
            valueType="text"
            search
            columnKey={'employeeNumberIs'}
            formItemProps={{
              rules: [
                {
                  message: 'emp_no长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
              ],
            }}
            searchConfig={{
              title: '医生工号',
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['staffName']}
            title="姓名"
            valueType="text"
            search
            columnKey={'staffNameLike'}
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            searchConfig={{
              title: '医生姓名',
            }}
            fieldProps={{}}
            order={1}
          />
          <VSTableColumn
            dataIndex={['gender']}
            title="性别"
            valueType="select"
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['titleCode']}
            title="职称"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.TITLE_DICT}
          />
          <VSTableColumn
            dataIndex={['doctorDto', 'clinicDepartment', 'departmentName']}
            title="所在临床科室"
            valueType="select"
            dataSource={departmentNameList}
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['descriptionInfo']}
            title="简介"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '简介长度不合法',
                  type: 'string',
                  min: 0,
                  max: 512,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['doctorDto', 'specialityDescription']}
            title="特长"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'speciality_desc长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableToolbar
            className="ant-pro-table-list-toolbar-padding"
            title="医生信息列表"
          >
            <VSTableAddition size="large" className="icon16" children="新增" />
          </VSTableToolbar>
        </VSPromiseTable>
      </div>
      <div className="doctorInfo_bottom">
        <Button
          type="default"
          size="large"
          className="icon16"
          onClick={() => {
            handleReset();
          }}
        >
          清屏
        </Button>
        <Button
          disabled={!rowId}
          type="primary"
          size="large"
          className="icon16"
          onClick={async () => {
            const params = vsf?.refs?.doctorInfoForm?.getFieldValue();
            const params2 =
              await vsf.refs.doctorInfoForm?.validateFieldsReturnFormatValue();

            save({ ...params, ...params2 });
          }}
        >
          保存
        </Button>
      </div>
    </div>
  );
};
export default definePage(DoctorInfo);
