import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_queryPagedByPatientFullQto_1c8a93: {
    method: 'post',
    url: '/api/patient-main/query-paged-by-patient-full-qto',
    parameterFomatter: (data?: {
      qto: PatientFullQtoPatientMainPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientFullVoPatientMainEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
