import { createServices } from '@vs/vsf-boot';
export default createServices({
  LabController_stopLabClass_6f16ed: {
    method: 'post',
    url: '/api/lab/stop-lab-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  LabController_getAllByLabClassQto_a6f1c8: {
    method: 'post',
    url: '/api/lab/get-all-by-lab-class-qto',
    parameterFomatter: (data?: { qto: LabClassQtoLabPersistQto }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo[]) => data,
  },
});
