import './index.less';

import { DoubleRightOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import { divide } from 'lodash';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import Cdss from '@/module/cpoe/Cdss';
import Icon from '@/module/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import { ExamineReportBasicInfo } from '@/pages/Index/cpoe/center/left/history/detail/index';
import { handelEnums } from '@/utils/index';

const examineList = [
  {
    label: '申请项目',
    value: 'applyItemName',
  },
  {
    label: '申请医生',
    value: 'orderDoctor',
  },
  {
    label: '申请时间',
    value: 'orderTime',
  },
  {
    label: '检验号',
    value: 'id',
  },
  {
    label: '采样时间',
    value: 'collectionTime',
  },
  {
    label: '标本条码',
    value: 'sampleNum',
  },
  {
    label: '检验者',
    value: 'check',
  },
  {
    label: '报告时间',
    value: 'reportTime',
  },
  {
    label: '状态',
    value: 'applyStatus',
  },
  {
    label: '诊断',
    value: 'treat',
  },
  {
    label: '审核时间',
    value: 'reportTime',
  },
  {
    label: '样本',
    value: 'sample',
  },
  {
    label: '采样',
    value: 'sampling',
  },
  {
    label: '审核',
    value: 'reportDoctor',
  },
];

const Index = (props) => {
  const { reportDetail } = props;
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient } = user ?? {};
  const [arr, setArr] = useState<any>();
  // // 分析数据
  // const [analysisData, setAnalysisData] = useState<any>();

  const _patientId = currentPatient?.patientId;

  useEffect(() => {
    setArr(
      reportDetail?.orderLabReportBacteriaResultList?.map((item) => {
        return { ...item, show: true };
      }),
    );
  }, [reportDetail]);

  const handelShow = (index?) => {
    const tempList = cloneDeep(arr);
    setArr(
      tempList?.map((item, _idx) => {
        console.log(tempList, 'tempListtempList', _idx === index);
        return {
          ...item,
          show: _idx === index ? !item.show : item.show,
        };
      }),
    );
  };

  // const getAnalysisData = async (row, time?) => {
  //   console.log(_patientId);
  //   const res = await vsf?.services?.PatientJourneyController_analysis_61065d({
  //     patientId: _patientId,
  //     qto: {
  //       resultDateTimeRangeIn: {
  //         begin: time?.start,
  //         end: time?.end,
  //         beginInclude: true,
  //         endInclude: true,
  //       },
  //       reportItemCodeIs: row?.reportItemCode,
  //     },
  //   });
  //   if (res?.code === 200) {
  //     setAnalysisData(res?.data);
  //   }
  // };

  const renderReportDetails = (item) => {
    if (item.value === 'applyStatus') {
      if (reportDetail?.[item?.value] === 'CONFIRM_REPORT') {
        return '已出报告';
      }
    } else if (item.value === 'orderDoctor') {
      return reportDetail?.orderDoctor?.name;
    } else if (item.value === 'reportDoctor') {
      return reportDetail?.reportDoctor?.name;
    } else if (item.value === 'sampling') {
      return '张华';
    } else if (item.value === 'check') {
      return '丽莎';
    } else if (item.value === 'treat') {
      return reportDetail?.treat?.mainDiagnose;
    } else {
      return reportDetail?.[item?.value];
    }
  };

  return reportDetail?.orderLabReportBacteriaResultList?.length === 0 ? (
    <div className="examine_detail">
      <div className="examine_detail_header">
        {ExamineReportBasicInfo.map((item, index) => {
          return (
            <div key={index} className="examine_detail_header_item">
              <div className="examine_detail_header_item_label">
                {item?.label}：
              </div>
              {item?.value == 'labStatus' ? (
                <div className="examine_detail_header_item_value">
                  {handelEnums(
                    vsf.stores.dbenums.enums.LAB_RESULT_STATUS_DICT,
                    reportDetail?.orderVoList?.[0]?.[item?.value],
                  )}
                </div>
              ) : (
                <div className="examine_detail_header_item_value">
                  {reportDetail?.orderVoList?.[0]?.[item?.value]}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="examine_detail_tabel">
        <VSPromiseTable
          className="examine_detail_tabel_promise_table"
          pagination={false}
          onFetch={async (params) => {
            return {
              data: reportDetail?.patientJourneyOrderLabResultList,
            };
          }}
          scroll={{
            y: 465,
          }}
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
        >
          <VSTableColumn
            title=""
            valueType="text"
            fieldProps={{}}
            render={(_, __, index) => {
              return index + 1;
            }}
          />
          <VSTableColumn
            dataIndex={['reportItemName']}
            title="项目"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['result']}
            title="结果"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['resultReference']}
            title="参考范围"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['unit']}
            title="单位"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['abnormalSignature']}
            title="异常结果"
            valueType="text"
            fieldProps={{}}
            render={(dom, value) => {
              return (
                <div style={{ color: value?.abnormalSignature ? 'red' : '' }}>
                  {value?.abnormalSignature}
                </div>
              );
            }}
          />
          <VSTableColumn
            dataIndex={['itemResult']}
            title="危急值"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['a6']}
            title="操作"
            valueType="text"
            fieldProps={{}}
            render={(_, data) => {
              const pattern = new RegExp('[\u4E00-\u9FA5]+');

              return !pattern.test(data?.result) ? (
                <Cdss
                  title="智能助手(检测报告)"
                  placement="left"
                  // data={analysisData}
                  analysisParams={{ data, patientId: _patientId }}
                >
                  <Button
                    type="text"
                    style={{
                      color: '#3276E8',
                    }}
                    onClick={() => {
                      // getAnalysisData(data);
                    }}
                  >
                    分析
                  </Button>
                </Cdss>
              ) : (
                ''
              );
            }}
          />
        </VSPromiseTable>
      </div>
    </div>
  ) : (
    <div className="examine_detail_microorganism">
      <div className="examine_detail_microorganism_title">
        {reportDetail?.patientJourneyOrderList?.[0]?.orderText}
      </div>
      {arr?.map((item, index) => {
        return (
          <Block
            key={index}
            style={{ marginBottom: '25px' }}
            titleBackgroundColor="#DBEDFF"
            headerStyle={{
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'space-between',
            }}
            title={
              <div
                onClick={() => {
                  handelShow(index);
                }}
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                培养结果：{item?.bacteriaName}
              </div>
            }
            extra={
              <div
                onClick={() => {
                  handelShow(index);
                }}
                style={{
                  display: 'flex',
                  paddingRight: '10px',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {/* <div className="checked_detail_view_img">查看图像</div> */}
                <DoubleRightOutlined
                  className={classnames(
                    item?.show
                      ? 'checked_reoprt_detail_right_double_up_outlined'
                      : 'checked_reoprt_detail_right_double_down_outlined',
                  )}
                  style={{ color: '#3276E8' }}
                />
              </div>
            }
          >
            <div style={{ display: item?.show ? 'block' : 'none' }}>
              <VSPromiseTable
                // editable={{ editType: 'single' }}
                id="89774"
                className="aspirin-table
                 aspirin-table-nopadding
                 aspirin-table-order
                 examine_detail_tabel_promise_table"
                onFetch={async (params) => {
                  return {
                    data: reportDetail?.orderLabReportBacteriaResultList?.[
                      index
                    ]?.orderLabBacteriaDrugResistanceResultDtoList,
                  };
                }}
                pagination={false}
                scroll={{ y: 400 }}
              >
                <VSTableColumn
                  dataIndex={['antibioticName']}
                  title=""
                  valueType="text"
                  fieldProps={{}}
                  render={(_, __, index) => {
                    return index + 1;
                  }}
                  width={50}
                />
                <VSTableColumn
                  dataIndex={['antibioticName']}
                  title="药敏名称"
                  valueType="text"
                  fieldProps={{}}
                  width={260}
                />

                <VSTableColumn
                  dataIndex={['resultMethod']}
                  title="检验方法"
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['quantifyResult']}
                  title="定量结果"
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['unit']}
                  title="单位"
                  valueType="text"
                  fieldProps={{}}
                />

                <VSTableColumn
                  dataIndex={['drugResistanceResult']}
                  title="敏感性"
                  valueType="text"
                  fieldProps={{}}
                  render={(dom, value) => {
                    return (
                      <div>
                        {value?.drugResistanceResultName}({dom})
                      </div>
                    );
                  }}
                />

                {/* <VSTableColumn
                  dataIndex={['drugResistanceResultName']}
                  title="敏感性结果中文"
                  valueType="text"
                  fieldProps={{}}
                /> */}

                <VSTableColumn
                  dataIndex={['testRange']}
                  title="折点"
                  valueType="text"
                  fieldProps={{}}
                />
              </VSPromiseTable>
              <div className="examine_detail_text">
                <div className="item">
                  耐药类型：
                  <span className="value">{item?.drugResistanceResult}</span>
                </div>
                <div className="item">
                  临床提示：<span className="value">{item?.expertNotice}</span>
                </div>
              </div>
            </div>
          </Block>
        );
      })}
    </div>
  );
};

export default definePage(Index);
