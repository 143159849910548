import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getMedicalRecordVoById_150e6f: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-medical-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
});
