import './index.less';

import {
  compose,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React from 'react';

import { askSearch } from './utils';

/**
 * 诊断符合情况
 */

let SelectList: any = (props, ref) => {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    dataMenu,
    selectDataSource,
    ...rest
  } = props;
  // console.log(props);

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  return (
    <div className="custom_selectlist">
      {dataMenu?.length > 0 &&
        dataMenu?.map((item, index) => {
          const res = value?.find((_item) => {
            return _item?.diagnosisCompareGroupCode == item?.code;
          });
          return (
            <div className="custom_selectlist_item" key={index}>
              <div className="custom_selectlist_item_label">
                {index + 1}、{item?.label}
              </div>
              <div className="custom_selectlist_item_select">
                <Select
                  style={{ width: 200 }}
                  onChange={(_, v) => {
                    const res = value?.find((_item) => {
                      return _item?.diagnosisCompareGroupCode == item?.code;
                    });
                    if (res) {
                      const _res = value?.map((i) => {
                        return i?.diagnosisCompareGroupCode ==
                          res?.diagnosisCompareGroupCode
                          ? {
                              diagnosisCompareGroupCode: item?.code,
                              diagnosisCorrespondStatus: v?.value,
                            }
                          : { ...i };
                      });
                      setValue([..._res]);
                    } else {
                      setValue([
                        ...value,
                        {
                          diagnosisCompareGroupCode: item?.code,
                          diagnosisCorrespondStatus: v?.value,
                        },
                      ]);
                    }
                  }}
                  defaultValue={res?.diagnosisCorrespondStatus ?? ''}
                  fieldNames={{ label: 'label', value: 'value' }}
                  showSearch
                  options={selectDataSource}
                  // width={200}
                  filterOption={askSearch}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

SelectList.displayName = 'SelectList';

SelectList = React.forwardRef(SelectList);

export default compose(
  withField<any>({
    name: 'SelectList',
  }),
  withPreview({
    renderPreview: (props) => {
      // console.log(props, 'pppppppp');
      return <>预览值：{props?.value}</>;
    },
  }),
)(SelectList) as typeof SelectList;
