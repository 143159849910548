import { Popover } from '@vs/vsf-kit';
import React from 'react';
const Ellipsis = (props) => {
  return (
    <Popover title={props?.title} content={props?.content} trigger="hover">
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {props?.children}
      </div>
    </Popover>
  );
};

export default Ellipsis;
