import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_queryPagefallByClinicBedWithPatientQto_0429ae: {
    method: 'post',
    url: '/api/clinic-bed/query-pagefall-by-clinic-bed-with-patient-qto',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedWithPatientVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getBedWithPrice_03a9ad: {
    method: 'post',
    url: '/api/clinic-bed/get-bed-with-price',
    parameterFomatter: (data?: { bedId: number }) => data,
    responseFormatter: (_, __, data?: ClinicBedWithPriceEoClinicBedPersistEo) =>
      data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicErpAdtController_acceptErpPatientFuncFlowExecutor_52cb5e: {
    method: 'post',
    url: '/api/clinic-erp-adt/accept-erp-patient-func-flow-executor',
    parameterFomatter: (data?: {
      createDiagnosisRecordBtoList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      updateErpVisitForTransferBto: UpdateErpVisitForTransferBtoClinicErpVisitServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicErpAdtController_queryErpPatientInTransferAcceptVoByErpVisitId_3aff8d: {
    method: 'post',
    url: '/api/clinic-erp-adt/query-erp-patient-in-transfer-accept-vo-by-erp-visit-id',
    parameterFomatter: (data?: { erpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpPatientInTransferAcceptVoClinicErpAdtEntranceWebVo,
    ) => data,
  },
});
