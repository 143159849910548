import './index.less';
import './convert';
import '@/style/adaptation.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isMinScreen } from '@/config';
import SearchContainer from '@/module/Search';
import {
  ButtonAndKeyDown,
  Determine,
  Ellipsis,
  Flex,
  NewDrawer,
} from '@/pages/Index/components';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelNumberWidth,
} from '@/utils';
import storage from '@/utils/storage';

import {
  convertGetInpExpenseListByConditionRes2InpExpenseDetailListRo,
  convertGetInpExpenseListForSummaryRes2InpExpenseSummaryListRo,
} from './convert';

const Index = (props) => {
  const {
    common: { currentApplication },
    user,
  } = props?.stores || {};
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [search, setSearch] = useState(0);
  const [bedList, setBedList] = useState([]);
  const [dateIsRequired, setDateIsRequired] = useState(false);
  const [type, setType] = useState('DETAIL');

  // 明细/退费
  const onFetch_t2068 = useCallback(
    async (params) => {
      setSelectedRows([]);
      if (type == 'SUMMARY') return [];
      const {
        wardInfo,
        patientBed,
        orderDateRangeIn,
        queryTypeIs,
        inHospitalIndicatorIs,
        displayIdIs,
      } = vsf.refs.listPrintForm.getFieldsValue();

      if (!wardInfo?.id && !displayIdIs) {
        return [];
      }

      const res =
        await vsf?.services?.InpExpenseListController_getInpExpenseListByCondition_fcc4fb?.(
          {
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              ...params?.search,
              ...params?.extra,
              ...(params ?? {}),
              // ...vsf.refs.listPrintForm.getFieldsValue(),
              inpVisitIdIn: patientBed?.inpVisitId
                ? [patientBed?.inpVisitId]
                : null,
              queryTypeIs,
              displayIdIs,
              orderWardIdIs: wardInfo?.id,
              inHospitalIndicatorIs:
                inHospitalIndicatorIs?.length > 0
                  ? inHospitalIndicatorIs?.[0]
                  : false,

              ...(orderDateRangeIn &&
              orderDateRangeIn?.length > 0 &&
              orderDateRangeIn?.[0]
                ? {
                    createdAtRangeIn: {
                      begin: orderDateRangeIn?.[0],
                      end: orderDateRangeIn?.[1],
                      beginInclude: true,
                      endInclude: false,
                    },
                  }
                : {}),
            },
          },
        ); // TODO: 请确认入参来源及出参用法

      const output =
        convertGetInpExpenseListByConditionRes2InpExpenseDetailListRo?.(res);
      if (res?.code == 200) {
        return { data: output ?? [], total: output?.length ?? 0 };
      } else {
        return { data: [], total: 0 };
      }
    },
    [type],
  );

  // 汇总
  const onFetch_t1101 = useCallback(async (params) => {
    setSelectedRows([]);
    const {
      wardInfo,
      patientBed,
      orderDateRangeIn,
      queryTypeIs,
      inHospitalIndicatorIs,
      displayIdIs,
    } = vsf.refs.listPrintForm.getFieldsValue();

    if (!wardInfo?.id && !displayIdIs) {
      return [];
    }

    const res =
      await vsf?.services?.InpExpenseListController_getInpExpenseListForSummary_99fc69?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
            patientBed: patientBed?.bedId,
            inpVisitIdIn: patientBed?.inpVisitId
              ? [patientBed?.inpVisitId]
              : null,
            queryTypeIs,
            displayIdIs,
            orderWardIdIs: wardInfo?.id,
            inHospitalIndicatorIs:
              inHospitalIndicatorIs?.length > 0
                ? inHospitalIndicatorIs?.[0]
                : false,

            ...(orderDateRangeIn &&
            orderDateRangeIn?.length > 0 &&
            orderDateRangeIn?.[0]
              ? {
                  createdAtRangeIn: {
                    begin: orderDateRangeIn?.[0],
                    end: orderDateRangeIn?.[1],
                    beginInclude: true,
                    endInclude: false,
                  },
                }
              : {}),
          },
        },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertGetInpExpenseListForSummaryRes2InpExpenseSummaryListRo?.(res);
    return { data: output ?? [], total: output?.length ?? 0 };
  }, []);

  const getBedList = async (option) => {
    if (!vsf.refs.listPrintForm.getFieldsValue()?.wardInfo?.id) return [];
    const res =
      await vsf?.services?.ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47?.(
        {
          bedWardId: vsf.refs.listPrintForm.getFieldsValue()?.wardInfo?.id,
        },
      );
    const selectValueList = res?.data?.map((i) => {
      return {
        ...i,
        patientName: i?.bedLabel + '-' + i?.patientName,
      };
    });
    const _data = option?.patientName
      ? selectValueList?.filter(
          (i) =>
            askSearch(option.patientName, i, 'inputCode') ||
            askSearch(option.patientName, i, 'patientName'),
        )
      : selectValueList;

    return _data ?? [];
  };

  const renderPrice = (price) => {
    return Number(price ?? 0).toFixed(2);
  };

  return (
    <div className="list_print">
      <div className="list_print_search">
        <VSForm id="listPrintForm" labelAlign="left">
          <VSFormLayout
            columnCount={7}
            // labelCol={labelColWidth[4]}
            labelWidth={labelNumberWidth(4)}
            className="widthAutoFill"
          >
            <VSFormItem
              name={['wardInfo']}
              label="病区"
              valueType="select"
              fieldProps={{
                className: 'width190',
                showSearch: true,
                disabled:
                  currentApplication?.department?.clinicAttribute == '2' ||
                  !currentApplication?.department,
                onChange: () => {
                  vsf.refs.listPrintForm.setFieldsValue({
                    patientBed: null,
                  });
                },
              }}
              initialValue={
                currentApplication?.department?.clinicAttribute == '2'
                  ? {
                      id: currentApplication?.department?.id,
                      departmentName:
                        currentApplication?.department?.departmentName,
                    }
                  : null
              }
              fieldNames={{ label: 'departmentName', value: 'id' }}
              dataSource={async (param) => {
                const res =
                  await vsf?.services?.DepartmentDtoController_getWardPagedBySearchCode_0a57cd?.(
                    {
                      searchCode: param?.departmentName,
                      clinicType: param?.clinicType,
                    },
                  );
                return res?.data?.result;
              }}
            />
            <VSFormItem
              name={['patientBed']}
              label="床号"
              valueType="select"
              fieldProps={{ showSearch: true, className: 'width190' }}
              fieldNames={{ label: 'patientName', value: 'bedId' }}
              searchKey="patientName"
              dataSource={getBedList}
            />
            <VSFormItem
              name={['displayIdIs']}
              label="患者ID"
              valueType="text"
              fieldProps={{}}
            />
            <VSFormItem
              name={['orderDateRangeIn']}
              label="日期"
              valueType="dateRange"
              initialValue={[
                dayjs().startOf('day').valueOf(),
                dayjs().endOf('day').valueOf(),
              ]}
              fieldProps={{
                disabled: dateIsRequired,
              }}
            />
            <VSFormItem
              name={['inHospitalIndicatorIs']}
              label=""
              valueType="checkbox"
              fieldProps={{
                onChange: (v) => {
                  if (v?.length > 0 && v?.[0]) {
                    setDateIsRequired(true);
                    vsf.refs.listPrintForm.setFieldsValue({
                      orderDateRangeIn: [],
                    });
                  } else {
                    setDateIsRequired(false);
                  }
                },
              }}
              dataSource={[{ label: '住院全部费用', value: true }]}
            />
            <VSFormItem
              name={['queryTypeIs']}
              label=""
              valueType="radio"
              fieldProps={{
                onChange: (v) => {
                  setType(v);
                },
              }}
              initialValue="DETAIL"
              dataSource={[
                { label: '明细', value: 'DETAIL' },
                { label: '退费', value: 'REFUND' },
                { label: '汇总', value: 'SUMMARY' },
              ]}
            />
            <ButtonAndKeyDown
              methodKey="altKey"
              ordinaryKey="Q"
              type="primary"
              onClick={() => {
                const { wardInfo, displayIdIs } =
                  vsf.refs.listPrintForm.getFieldsValue();
                if (!wardInfo?.id && !displayIdIs) {
                  message.info('请输入患者ID、病区ID、床号查询');
                  return [];
                }
                setSearch(search + 1);
              }}
            >
              查询
            </ButtonAndKeyDown>
          </VSFormLayout>
        </VSForm>
      </div>
      <div className="list_print_table">
        {/* 明细/退费 */}
        {type != 'SUMMARY' && (
          <VSPromiseTable
            // editable={{ editType: 'single' }}

            id="inpExpenseDetailList"
            className="
              aspirin-table-header-background-color
             aspirin-table-row-hover-background-color
             aspirin-table-nopadding
             aspirin-table-body-empty-transparent-background-color
             "
            pagination={false}
            searchConfig={{
              labelAlign: 'left',
              autoSearch: true,
            }}
            rowSelection={{
              selectType: 'multiple',
              selectedRows: selectedRows,
              onSelectedRowsChange: setSelectedRows,
            }}
            rowClick={{
              rowClickType: 'select',
            }}
            onFetch={onFetch_t2068}
            scroll={{
              x: 1600,
              // y: getScrollY(isMinScreen ? 250 : 350),
              y: getScrollYRem(23),
            }}
            extraParams={{ search, type }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title=""
              valueType="text"
              fieldProps={{}}
              hideInTable
            />
            <VSTableColumn
              dataIndex={['bedLabel']}
              title="床位号"
              valueType="text"
              fieldProps={{}}
              width={labelNumberWidth(5)}
            />
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{}}
              width={labelNumberWidth(8)}
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              ellipsis
              // width={100}
              width={labelNumberWidth(5)}
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 60 },
                ],
              }}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['departmentName']}
              title="病区"
              valueType="text"
              ellipsis
              width={labelNumberWidth(7)}
              formItemProps={{
                rules: [
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['itemClass']}
              title="类别"
              valueType="select"
              fieldProps={{}}
              width={labelNumberWidth(4)}
              ellipsis
              dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
            />
            <VSTableColumn
              dataIndex={['itemName']}
              title="项目"
              valueType="text"
              // width={120}
              width={labelNumberWidth(5)}
              ellipsis
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 200 },
                ],
              }}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['itemSpecification']}
              title="规格"
              valueType="text"
              width={labelNumberWidth(4)}
              ellipsis
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['unit']}
              title="单位"
              valueType="select"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 20 },
                ],
              }}
              dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
              fieldProps={{}}
              width={labelNumberWidth(4)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              valueType="digit"
              fieldProps={{}}
              render={(_, record) => {
                return renderPrice(record?.price);
              }}
              width={labelNumberWidth(5)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['amount']}
              title="数量"
              valueType="digit"
              fieldProps={{}}
              width={labelNumberWidth(5)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['cost']}
              title="金额"
              valueType="digit"
              fieldProps={{}}
              render={(_, record) => {
                return renderPrice(record?.cost);
              }}
              ellipsis
              width={labelNumberWidth(5)}
            />

            <VSTableColumn
              dataIndex={['performDepartmentName']}
              title="执行科室"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              // width={120}
              width={labelNumberWidth(6)}
              ellipsis
              fieldProps={{}}
            />
            {type == 'REFUND' && (
              <VSTableColumn
                dataIndex={['operator']}
                title="退费工号"
                valueType="text"
                fieldProps={{}}
                ellipsis
                width={labelNumberWidth(8)}
              />
            )}
            <VSTableColumn
              dataIndex={['memo']}
              title="备注"
              valueType="text"
              // width={100}
              width={labelNumberWidth(6)}
              ellipsis
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
            />
          </VSPromiseTable>
        )}
        {/* 汇总 */}
        {type == 'SUMMARY' && (
          <VSPromiseTable
            // editable={{ editType: 'single' }}
            className="
              aspirin-table-header-background-color
             aspirin-table-row-hover-background-color
             aspirin-table-nopadding
             aspirin-table-body-empty-transparent-background-color
             "
            id="inpExpenseSummaryList"
            pagination={false}
            onFetch={onFetch_t1101}
            scroll={{
              x: 1900,
              y: getScrollY(isMinScreen ? 250 : 350),
            }}
            rowClick={{
              rowClickType: 'select',
            }}
            rowSelection={{
              selectType: 'multiple',
              selectedRows: selectedRows,
              onSelectedRowsChange: setSelectedRows,
            }}
            extraParams={{ search }}
          >
            <VSTableColumn
              dataIndex={['id']}
              title=""
              valueType="text"
              fieldProps={{}}
              hideInTable
            />
            <VSTableColumn
              dataIndex={['wardName']}
              title="病区"
              valueType="text"
              fieldProps={{}}
              // width={120}
              width={labelNumberWidth(7)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['bedLabel']}
              title="床位号"
              valueType="text"
              fieldProps={{}}
              // width={80}
              width={labelNumberWidth(5)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(8)}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
              ellipsis
              // width={100}
              width={labelNumberWidth(5)}
            />
            <VSTableColumn
              dataIndex={['outpTallyAmount']}
              title="院前欠费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.outpTallyAmount);
              }}
            />
            <VSTableColumn
              dataIndex={['drugFee']}
              title="西药费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.drugFee);
              }}
            />
            <VSTableColumn
              dataIndex={['medicineFee']}
              title="中成药费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.medicineFee);
              }}
            />
            <VSTableColumn
              dataIndex={['herbFee']}
              title="中药饮片"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.herbFee);
              }}
            />
            <VSTableColumn
              dataIndex={['bedFee']}
              title="床位费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.bedFee);
              }}
            />
            <VSTableColumn
              dataIndex={['diagnosisFee']}
              title="诊察费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.diagnosisFee);
              }}
            />
            <VSTableColumn
              dataIndex={['treatFee']}
              title="治疗费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.treatFee);
              }}
            />
            <VSTableColumn
              dataIndex={['examFee']}
              title="检查费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.examFee);
              }}
            />
            <VSTableColumn
              dataIndex={['labFee']}
              title="化验费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.labFee);
              }}
            />
            <VSTableColumn
              dataIndex={['operationFee']}
              title="手术费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.operationFee);
              }}
            />
            <VSTableColumn
              dataIndex={['materialFee']}
              title="卫生材料费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(7)}
              render={(_, record) => {
                return renderPrice(record?.materialFee);
              }}
            />
            <VSTableColumn
              dataIndex={['nursingFee']}
              title="护理费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.nursingFee);
              }}
            />
            <VSTableColumn
              dataIndex={['otherFee']}
              title="其他住院费用"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(7)}
              render={(_, record) => {
                return renderPrice(record?.otherFee);
              }}
            />

            <VSTableColumn
              dataIndex={['vaccineFee']}
              title="疫苗费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(5)}
              render={(_, record) => {
                return renderPrice(record?.vaccineFee);
              }}
            />
            <VSTableColumn
              dataIndex={['diagnosisAndTreatFee']}
              title="一般诊疗费"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(7)}
              render={(_, record) => {
                return renderPrice(record?.diagnosisAndTreatFee);
              }}
            />
            <VSTableColumn
              dataIndex={['totalSubFee']}
              title="日期区间合计"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(7)}
              render={(_, record) => {
                return renderPrice(record?.totalSubFee);
              }}
            />
            <VSTableColumn
              dataIndex={['totalFee']}
              title="累计合计"
              valueType="digit"
              fieldProps={{}}
              // width={100}
              width={labelNumberWidth(6)}
              render={(_, record) => {
                return renderPrice(record?.totalFee);
              }}
            />
          </VSPromiseTable>
        )}
      </div>
      <div className="list_print_bottom">
        <Button
          disabled={selectedRows?.length <= 0}
          onClick={() => {
            // 明细/退费
            vsf.refs?.inpExpenseDetailList?.getSelectedRowValues();
            // 汇总
            vsf.refs?.inpExpenseSummaryList?.getSelectedRowValues();
            message.info('todo');
          }}
          type="primary"
          children="打印"
        />
      </div>
    </div>
  );
};

export default definePage(Index);
