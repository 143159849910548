import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  RadioGroup,
  Section,
  VSControlledForm,
  VSDataProvider,
  VSForm,
  VSFormFooter,
  VSFormFooterReset,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { debounce } from 'lodash';
import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';

const Edit = (props) => {
  const { title, value } = props;
  const [operationDictionaryName, setOperationDictionaryName] = useState();
  const [searchOperationDictionaryName, setSearchOperationDictionaryName] =
    useState<any>();
  const [operationDictionaryList, setOperationDictionaryList] = useState();

  useEffect(() => {
    // getDiseaseName();

    hasId(value) &&
      vsf.services
        ?.ClinicRecordHistoryController_getOperationHistoryById_290262?.({
          // TODO: 请检查以下这部分传参是否符合需求
          id: value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            vsf.refs?.operationForm?.setFieldsValue({
              ...res.data,
            });
          }
        });
  }, [value]);

  const getOperationDictionary = useCallback(async (v?) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            ...v,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式

    const _res = res?.data?.result?.map((item) => {
      return { label: item?.operationName, value: item?.id };
    });
    setOperationDictionaryList(_res);
    return _res;
  }, []);

  useEffect(() => {
    // getOperationDictionary();
  }, [getOperationDictionary]);

  const hasId = (data) => {
    return data?.id;
  };

  return (
    <VSControlledForm labelAlign="left" id="operationForm">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={1} labelWidth={120}>
        {/* 下拉 */}
        <VSFormItem
          name={['operationName']}
          label="手术名称"
          valueType="select"
          rules={[{ required: true }]}
          fieldProps={{
            disabled: title === 'update',
            showSearch: true,
            // onSelect: (v) => {
            //   setOperationDictionaryName(v?.label);
            // },
            // onSearch: (v) => {
            //   setSearchOperationDictionaryName(v);
            // },
          }}
          searchKey="searchInputLike"
          fieldNames={{ label: 'label', value: 'value' }}
          dataSource={getOperationDictionary}
          transform={(v) => {
            return { operationName: v?.label };
          }}
        />

        <VSFormItem
          name={['operationDate']}
          label="手术日期"
          valueType="date"
          fieldProps={
            {
              // defaultValue: +new Date(),
            }
          }
          initialValue={+new Date()}
        />

        <VSFormItem
          name={['selfComplaintIndicator']}
          label="信息来源"
          valueType="radio"
          fieldProps={{}}
          dataSource={[
            {
              label: '患者自述',
              value: true,
            },
            {
              label: '本院发生',
              value: false,
            },
          ]}
          initialValue={true}
          transform={(v) => {
            return {
              selfComplaintIndicator: v ? true : false,
              inHospitalIndicator: v ? false : true,
            };
          }}
        />

        {/* <VSFormItem
          name={['selfComplaintIndicator']}
          label="自述标识"
          valueType="switch"
          initialValue={false}
          fieldProps={{}}
        /> */}
      </VSFormLayout>
    </VSControlledForm>
  );
};

export default definePage(Edit);
