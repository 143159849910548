import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import React from 'react';
const DrugProductManger = (props) => {
  return (
    <Section title="DrugProductManger">
      <VSControlledForm vsid="82034">
        <VSFormLayout key="0" columnCount={1}>
          <VSFormItem
            name={['drugSpecificationDictionary', 'drugSpecification']}
            label="规格"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugSpecificationDictionary', 'unit']}
            label="单位"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugSpecificationDictionary', 'dosagePerUnit']}
            label="最小单位剂量"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugSpecificationDictionary', 'dosageUnit']}
            label="剂量单位"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugFirmId']}
            label="厂家供货商ID"
            valueType="digit"
            rules={[
              {
                message: '厂家供货商ID的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drug', 'drugName']}
            label="药品名称"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugProductName']}
            label="商品名称"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['stopIndicator']}
            label="全院停用标记"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['qualificationCode']}
            label="批准文号"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['baseDrugClass']}
            label="基药类型"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['ddd']}
            label="DDD计算值"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['centralPurchaseIndicator']}
            label="是否集采"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['outpProfessionalIndicator']}
            label="门专"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['outpSpecialIndicator']}
            label="门特"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['nationNegotiateIndicator']}
            label="国谈"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['storeCondition']}
            label="贮藏条件"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.STORE_CONDITION_DICT}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugProductCode']}
            label="商品编码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['source']}
            label="来源"
            valueType="select"
            dataSource={[
              { label: '医院药品', value: 'IN_HOSPITAL' },
              { label: '自备药', value: 'SELF_PROVIDE' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['maxDosage']}
            label="最高单次用量"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['biddingId']}
            label="招标ID"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['dayMaxDosage']}
            label="日最高计量"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['maxDay']}
            label="最大开药天数"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['useDescription']}
            label="用法描述"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugRatioIndicator']}
            label="药比标志"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['specialAttribute']}
            label="特殊属性"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['donationIndicator']}
            label="捐赠标志"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['conversionRatioNumerator']}
            label="颗粒剂转换比-分子"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['conversionRatioDenominator']}
            label="颗粒剂转换比-分母"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['prescriptionRatio']}
            label="开单系数"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['selfCode']}
            label="老系统自编码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['supplier']}
            label="供货单位"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['insuranceNationCode']}
            label="医保国家编码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['medicalRecordClass']}
            label="对应的病案首页费用分类"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['packageRatio']}
            label="供应商包装系数"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['supplierAdjustBasis']}
            label="供货商调整依据"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['dayMaxAmount']}
            label="当日开立数量上限"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['twentyOneDayMaxAmount']}
            label="21日开立数量上限"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['singleSettleIndicator']}
            label="独立结算标记"
            valueType="switch"
            initialValue={false}
            fieldProps={{}}
          />

          <VSFormItem
            name={['purchasePlatform']}
            label="采购平台"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['drugStandardCode']}
            label="药品标准码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(DrugProductManger);
