import { createServices } from '@vs/vsf-boot';
export default createServices({
  FinancePrePaymentController_getPrePaymentInfoByInpVisitId_8461f1: {
    method: 'post',
    url: '/api/finance-payment/get-pre-payment-info-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PrePaymentInfoVoFinancePaymentEntranceWebVo,
    ) => data,
  },
});
