import vsf, { definePage } from '@vs/vsf-boot';
import {
  Table
} from '@vs/vsf-kit';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { useState } from 'react';
import { debounce } from 'lodash';

const CustomSelect = ({
  value,
  onChange,
  handelDrugNameSelectChange,
  placeholder,
  addItemOptions,
  loadData,
  columns,
  showLabel,
  width,
  isPreview,
  tableProps
}) => {

  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)

  const handelFormSearch = debounce(async (v) => {
    try {
      if (v) {
        setLoading(true)
        const _data = await loadData?.(v)

        setLoading(false)
        setDataSource(_data)
        return _data
      }
    } catch (error) {
      console.error(error)
    }
  }, 500)


  return (
    <ComplexSelect
      mode="radio"
      value={value}
      showLabel={showLabel}
      hideSelectIcon
      dropdownMatchSelectWidth={false}
      addItemOptions={addItemOptions}
      onSearch={handelFormSearch}
      dropdownStyle={{
        // width,
      }}
      onChange={v => {
        handelDrugNameSelectChange?.(v);
        onChange(v?.key)
      }}
      placeholder={placeholder}
      isPreview={isPreview}
    >
      <Table
        scroll={{
          x: width
        }}
        loading={loading}
        dataSource={dataSource}
        rowClassName="aspirin-table-row-box"
        columns={columns}
        {...tableProps}
      />
    </ComplexSelect>
  )
}

export default CustomSelect
