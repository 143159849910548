import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpSettleController_queryUnRefundOutpSettleMaster_a0a382: {
    method: 'post',
    url: '/api/outp-billing/query-un-refund-outp-settle-master',
    parameterFomatter: (data?: {
      qto: OutpSettleMasterNewQtoOutpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpSettleMasterDetailVoOutpBillingEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
