import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSDataProvider,
  VSForm,
} from '@vs/vsf-kit';
import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { debounce } from 'lodash';
import { async } from 'q';
import React, { useState, useEffect } from 'react';

import RoleEditableTable from '@/pages/Index/components/func/RoleEditableTable';
import vsf, { definePage } from '@vs/vsf-boot';

const PatientGroupEdit = (props) => {
  const { departmentId } = props;
  /**
   * param
   * @type {object}
   */
  const [userStaff, setUserStaff] = useState();
  const [userGroupController, setUserGroupController] = useState();
  const [wardByDepartmentId, setAllWardByDepartmentId] = useState();

  console.log(vsf.stores.dbenums?.enums?.EMP_ROLE);

  useEffect(() => {
    hasId(props.value) &&
      vsf.services
        ?.UserGroupController_getById_bfaf32?.({
          id: props?.value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            vsf.refs?.OperateGroupDepartmentBranch?.setFieldsValue({
              ...res.data,
            });
          }
        });
  }, [props.value]);
  useEffect(() => {
    // getAllWardByDepartmentId();
    // getPagedByUserStaff();
    // UserStaffController();
  }, []);
  console.log(vsf.stores.dbenums?.enums?.EMP_ROLE);
  const hasId = (data) => {
    return data?.id;
  };
  // 成员名称
  const getPagedByUserStaff = async (v) => {
    console.log(v);
    console.log(v);
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82({
        qto: {
          from: 0,
          size: 50,
          staffDepartmentIdIs: departmentId,
          // inputCodeLike: v?.inputCodeIs,
          ...v,
        },
      });

    return res?.data?.result ?? [];
  };
  // DRGS
  const UserStaffController = async (v) => {
    const res =
      await vsf.services?.UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d(
        {
          qto: {
            from: 0,
            size: 100,
            staffDepartmentIdIs: departmentId,
            ...v,
          },
        },
      );
    const _res = res?.data?.map((item) => {
      return { label: item?.staffName, value: item?.id };
    });
    return res?.data;
  };
  // 病区
  const getAllWardByDepartmentId = async () => {
    const res =
      await vsf.services?.DepartmentDtoController_getAllWardByDepartmentId_f4a99a(
        {
          departmentId: departmentId,
        },
      );

    return res?.data;
  };

  const NewRoleEditableTable = (newProps) => {
    return (
      <RoleEditableTable
        // value={props?.value?.functionVsPermissionDtoList}
        {...newProps}
      >
        <VSFormItem
          name={['staff']}
          label="成员名称"
          valueType="select"
          rules={[
            { required: true },
            // { message: '权限编码长度不合法', type: 'string', min: 0, max: 20 },
          ]}
          searchKey="inputCodeLike"
          fieldNames={{ label: 'staffName', value: 'id' }}
          dataSource={getPagedByUserStaff}
          fieldProps={{ showSearch: true }}
        />
        <VSFormItem
          name={['staffRole']}
          label="成员角色"
          valueType="select"
          rules={[{ required: true }]}
          fieldProps={{ showSearch: true }}
          dataSource={vsf.stores.dbenums?.enums?.EMP_ROLE}
        />
        <VSFormItem
          name={['department']}
          label="病区"
          valueType="select"
          fieldNames={{ label: 'departmentName', value: 'id' }}
          dataSource={getAllWardByDepartmentId}
          fieldProps={{ showSearch: true }}
        />
      </RoleEditableTable>
    );
  };

  /**
   * haveId
   * @param {any} data
   * @returns {any}
   */

  return (
    <VSForm
      id="OperateGroupDepartmentBranch"
      vsid="07769"
      // onSubmit={async (params) => {
      //   const funcName = params.id // 条件，id是否存在，也可以改成其它条件
      //     ? 'UserGroupController_createMedicalGroup_f4b186' // todo 替换为你的更新接口
      //     : 'UserGroupController_updateMedicalGroup_75af28'; // todo 替换为你的创建接口
      //   const res = await vsf?.services?.[funcName]?.({
      //     btoParam: { ...(params ?? {}) },
      //   });
      //   return params;
      // }}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormItem
        name={['drgsAuditor', 'id']}
        label="组件"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="2" columnCount={3}>
        <VSFormItem
          name={['groupName']}
          label="主诊组名称"
          valueType="text"
          rules={[
            { required: true },
            // { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{
            onChange: debounce(
              ({ target }) => {
                vsf.refs.OperateGroupDepartmentBranch.setFieldsValue({
                  inputCode: askRomanAlphabet(target.value),
                });
              },
              30,
              { trailing: true },
            ),
          }}
        />

        {/* <VSFormItem
            name={['medicalGroupMemberIndexDtoList']}
            label="主键"
            valueType="listBox"
            fieldProps={{ children: <OperateGroupDepartmentTable /> }}
          /> */}

        <VSFormItem
          name={['inputCode']}
          label="拼音输入码"
          valueType="text"
          rules={[
            { required: true },
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['drgsAuditor']}
          label="DRGS审核专员"
          valueType="select"
          dataSource={UserStaffController}
          fieldNames={{ label: 'staffName', value: 'id' }}
          fieldProps={{ showSearch: true }}
          searchKey="inputCodeLike"
          // rules={[{ required: true }]}
          transform={(v) => {
            return { drgsAuditorId: v?.id };
          }}
        />
      </VSFormLayout>
      <VSFormItem
        name={['medicalGroupMemberIndexDtoList']}
        valueType="custom"
        customComponent={(_props) => <NewRoleEditableTable {..._props} />}
        fieldProps={{}}
        rules={[{ required: true, message: '请选添加员名称' }]}
        // transform={(v) => {

        //   return {
        //     medicalGroupMemberBtoList: {
        //       ...v,
        //       starffId: v?.starff,
        //     },
        //   };
        // }}
      />
    </VSForm>
  );
};
export default definePage(PatientGroupEdit);
