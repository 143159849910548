import React, { useEffect, useRef, useState } from 'react';
import CertificateTitle from './component/certificateTitle';
import CertificateContent from './component/certificateContent';
import CertificateFooter from './component/certificateFooter';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import { Button, message, Modal } from '@vs/vsf-kit';
import CertificateModel from './component/certificateModel';

import '@/style/adaptation.less';
import './index.less';
import dayjs from 'dayjs';

const Index = () => {
  // 选中数据
  const [active, setActive] = useState();
  // 默认数据
  const [dataStore, setDataStore] = useState([]);
  // 弹框开关
  const [medicalFlag, setMedicalFlag] = useState(false);

  const [day, setDay] = useState('4');

  // 查询
  const inquire = async () => {
    const inpValue = vsf?.refs?.listPrintForm?.getFieldValue();
    const beginTimer = dayjs(inpValue?.timer[0]).format('YYYY-MM-DD HH:mm:ss');
    const endTimer = dayjs(inpValue?.timer[1]).format('YYYY-MM-DD HH:mm:ss');
    if (!inpValue?.timer[0] || !inpValue?.timer[1]) {
      return message.error('请选择就诊日期');
    }
    if (!inpValue?.patientIdIs) {
      return message.error('请输入患者ID');
    }

    const qtoParams = {
      patientDisplayIdIs: inpValue?.patientIdIs, // 患者ID
      visitDepartmentIdIs: inpValue?.patientBed?.id, // 就诊科室
      visitTimeBiggerThanEqual: beginTimer, // 就诊时间 - 开始
      visitTimeLessThan: endTimer, // 就诊时间 - 结束
    };

    const res =
      await vsf.services?.MedicalCertificationRecordController_getClinicRecordWithCertification_1b3b3c?.(
        { qto: qtoParams },
      );

    if (res?.code == 200) {
      res?.data?.map((item) => {
        const now = dayjs();
        item.patientBirthday = now.diff(dayjs(item?.patient?.birthday), 'year');
        // 工作地址
        let address = item?.patient?.patientProfileDto?.workUnit?.address;
        if (address?.province) {
          item.serviceAgency =
            address?.province +
            address?.city +
            address?.county +
            address?.detail;
        } else {
          item.serviceAgency = '';
        }
        // 籍贯
        let nativePlace = item?.patient?.patientProfileDto?.nativeAddress;
        if (nativePlace?.province) {
          item.nativePlace =
            nativePlace.province +
            nativePlace.city +
            nativePlace.county +
            nativePlace.detail;
        }
        item.timer = dayjs().format('YYYY-MM-DD HH:mm:ss');
      });

      setDataStore(res?.data);
    }
  };

  // 新增
  const add = async () => {
    if (active) {
      setMedicalFlag(true);
    } else {
      message.error('请选择患者');
    }
  };
  // 打印
  const printClick = () => {
    message.info('todo');
    print();
  };

  // 一键复制
  const onCopy = () => {
    const vs = vsf?.refs?.medicalCertificate?.getFieldFormatValue();
    // 休息单位
    vs.restDurationUnit = '4';
    // 休息时间
    vs.restDuration = null;
    // 初步诊断
    vs.medicalDiagnosis = [];
    // 处理意见
    vs.memo = '';
    setDay('4');
    // 证明时间
    vs.timer = dayjs().format('YYYY-MM-DD HH:mm:ss');
    vsf?.refs?.medicalCertificate?.setFieldsValue({ ...vs });
  };

  // 弹框确认
  const onOk = async () => {
    // 患者信息
    const patient = vsf?.refs?.medicalCertificate?.getFieldFormatValue();
    if (!patient.medicalDiagnosis) {
      return message.error('请选择初步诊断');
    }
    if (!patient.restDuration) {
      return message.error('请选择休息时间');
    }
    if (!patient.restDurationUnit) {
      return message.error('请选择休息单位');
    }
    if (!patient.memo) {
      return message.error('请输入处理意见');
    }
    const params = {
      ...patient,
      medicalDiagnosis: patient.medicalDiagnosis,
      restDuration: patient.restDuration,
      restDurationUnit: day,
      outpVisitId: patient.clinicType === 'OUTP' ? patient?.id : '',
      inpVisitId: patient.clinicType === 'INP' ? patient?.diagnosisId : '',
    };
    const res =
      await vsf?.services?.MedicalCertificationRecordController_createCertificationRecord_17d142?.(
        { btoParam: params },
      );
    if (res.code == 200) {
      message.success('新增医学证明成功');
      setMedicalFlag(false);
      inquire();
    }
  };

  return (
    <div className="certificate">
      <CertificateTitle inquire={inquire} />
      <CertificateContent
        dataStore={dataStore}
        setMedicalFlag={setMedicalFlag}
        setDataStore={setDataStore}
        setActive={setActive}
        active={active}
      />
      <CertificateFooter
        active={active}
        dataStore={dataStore}
        printClick={printClick}
        add={add}
      />
      <Modal
        id="modal"
        open={medicalFlag}
        title="医学证明"
        className="modal_box"
        footer={
          <div>
            <Button
              onClick={() => {
                setMedicalFlag(false);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={onCopy}>
              一键复制
            </Button>
            <Button type="primary" onClick={onOk}>
              确定
            </Button>
          </div>
        }
        defaultOpen={false}
      >
        <CertificateModel active={active} day={day} setDay={setDay} />
      </Modal>
    </div>
  );
};
export default Index;
