import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import {
  convertVSPromiseTableBedInfoList2SaveBedReq,
  convertQueryClinicBedDetailVoByClinicBedDetailQtoPagedRes2VSPromiseTableBedInfoListRo,
  convertVSPromiseTableBedInfoListQro2QueryClinicBedDetailVoByClinicBedDetailQtoPagedReq,
} from './convert';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import Block from '@/pages/Index/components/block_no_padding';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import storage from '@/utils/storage';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import BedInfoEdit from './edit';

import './index.less';

const Index = (props) => {
  const {
    common: { currentApplication },
  } = props?.stores || {};

  const onFetch_t8472 = useCallback(async (params) => {
    // 组装筛选条件
    const qtoSearchParams =
      convertVSPromiseTableBedInfoListQro2QueryClinicBedDetailVoByClinicBedDetailQtoPagedReq?.(
        params?.search,
      ); // 分页查询床位详情列表
    const res =
      await vsf?.services?.ClinicBedController_queryClinicBedDetailVoByClinicBedDetailQtoPaged_14c1f7?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...qtoSearchParams,
          },
        },
      ); // 组装床位详细列表查询结果数据
    const queryResultData =
      convertQueryClinicBedDetailVoByClinicBedDetailQtoPagedRes2VSPromiseTableBedInfoListRo?.(
        res,
      );
    return {
      data: queryResultData ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const bedStatusColor = {
    0: '#3276E8',
    1: '#E34A4E',
    2: '#3276E8',
    3: '#3276E8',
  };

  return (
    <Container
      layout={{
        items: [
          {
            bgColor: 'var(--background)',
            block: '100%',
            height: '100%',
          },
        ],
      }}
    >
      <VSPromiseTable
        editable={{
          editType: 'single',
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          editText: <Icon type="icon-bianji" size={24} />,
          columnProps: {
            width: 100,
          },
          deleteConfirmProps: {
            title: '确认删除该床位吗？',
            okText: '删除',
            cancelText: '取消',
            trigger: undefined,
            okButtonProps: '{undefined}',
            placement: 'topLeft',
          },
        }}
        // pagination={false}
        vsid="82366"
        id="vSPromiseTableBedInfoList"
        className="aspirin-table-header-background-color
        aspirin-table-row-hover-background-color
        aspirin-table-body-empty-transparent-background-color
        aspirin-table aspirin-table-search-no-background-box
       "
        scroll={{
          y: getScrollY(500),
        }}
        searchConfig={{
          labelWidth: 100,
          collapsed: false,
          labelAlign: 'left',
          collapseRender: false,
          disableSearchButtonLoading: true,
          searchGutter: 50,
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="搜索">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return <SearchContainer>{dom}</SearchContainer>;
        }}
        onFetch={onFetch_t8472}
        updateLinkPage={{
          linkPage: () => <BedInfoEdit />,
          modalProps: {
            title: '床位维护-编辑',
            className: 'aspirin-linkpage',
            okText: '保存',
            cancelText: '取消',
            confirmLoading: true,
            width: 800,
          },
          onLinkPageValidate: async (value, values, row) => {
            await vsf.refs.vSFormBedInfo?.validateFieldsReturnFormatValue();

            // 组装请求参数
            const reqParams = convertVSPromiseTableBedInfoList2SaveBedReq?.({
              ...value,
              ward: {
                id: value?.ward?.id ?? value?.wardId,
                departmentName: value?.ward?.departmentName ?? value?.wardName,
              },
            }); // 请求保存床位信息接口
            const res =
              await vsf?.services?.ClinicBedController_saveBed_20d0bb?.(
                reqParams,
              );
            if (res?.code == 200) {
              vsf.refs?.vSPromiseTableBedInfoList?.reload();
              message.success('编辑成功');
              return true;
            } else {
              return false;
            }
          },
        }}
        addLinkPage={{
          linkPage: () => <BedInfoEdit />,
          modalProps: {
            title: `床位维护 - 新增`,
            className: 'aspirin-linkpage',
            okText: '保存',
            cancelText: '取消',
            confirmLoading: true,
            width: 800,
          },
          onLinkPageValidate: async (value, values, row) => {
            await vsf.refs.vSFormBedInfo?.validateFieldsReturnFormatValue();

            // 组装请求参数
            const reqParams = convertVSPromiseTableBedInfoList2SaveBedReq?.({
              ...value,
              ward: {
                id: value?.ward?.id ?? value?.wardId,
                departmentName: value?.ward?.departmentName ?? value?.wardName,
              },
            }); // 请求保存床位信息接口
            const res =
              await vsf?.services?.ClinicBedController_saveBed_20d0bb?.(
                reqParams,
              );
            if (res?.code == 200) {
              vsf.refs?.vSPromiseTableBedInfoList?.reload();
              message.success('编辑成功');
              return true;
            } else {
              return false;
            }
          },
        }}
        onRemove={async (data) => {
          const res =
            await vsf?.services?.ClinicBedController_deleteBed_9a64f6?.({
              btoParam: { id: data?.id },
              extApiParam: {},
            });
          return res?.code === 200;
        }}
      >
        <VSTableColumn
          dataIndex={['wardIdIs']}
          title="护理单元"
          valueType="select"
          search
          order={0}
          columnKey={'wardIdIs'}
          hideInTable
          fieldProps={{
            dataSource: async (param) => {
              const res =
                await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                  {
                    qto: {
                      clinicAttributeIs: '2',
                      stopIndicatorIs: false,
                      stopIndicatorIsNullOrNot: true,
                      departmentNameLike: param?.searchKey,
                      inputCodeLike: param?.searchKey,
                      inputCodeWubiLike: param?.searchKey,
                    },
                  },
                );
              return res?.data;
            },
            showSearch: true,
            searchKey: 'searchKey',
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
        />

        <VSTableColumn
          dataIndex={['departmentIdIs']}
          title="所属科室"
          valueType="select"
          search
          order={0}
          columnKey={'departmentIdIs'}
          hideInTable
          fieldProps={{
            dataSource: async (param) => {
              const res =
                await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                  {
                    qto: {
                      clinicAttributeIs: '0',
                      stopIndicatorIs: false,
                      stopIndicatorIsNullOrNot: true,
                      departmentNameLike: param?.searchKey,
                      inputCodeLike: param?.searchKey,
                      inputCodeWubiLike: param?.searchKey,
                    },
                  },
                );
              return res?.data;
            },
            showSearch: true,
            searchKey: 'searchKey',
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
        />

        <VSTableColumn
          dataIndex={['bedClassIs']}
          title="床位等级"
          // valueType="select"
          valueType="codeTableSelect"
          search
          order={0}
          columnKey={'bedClassIs'}
          hideInTable
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.BED_CLASS_DICT,
            showSearch: true,
            allowClear: true,
          }}
        />

        <VSTableColumn
          dataIndex={['bedTypeIs']}
          title="床位类型"
          // valueType="select"
          valueType="codeTableSelect"
          search
          order={0}
          columnKey={'bedTypeIs'}
          hideInTable
          fieldProps={{
            showSearch: true,
            allowClear: true,
            dataSource: vsf.stores.dbenums.enums.BED_TYPE_DICT,
          }}
        />

        <VSTableColumn
          dataIndex={['bedStatusIs']}
          title="床位状态"
          // valueType="select"
          valueType="codeTableSelect"
          search
          order={0}
          columnKey={'bedStatusIs'}
          hideInTable
          fieldProps={{
            showSearch: true,
            allowClear: true,
            dataSource: vsf.stores.dbenums.enums.BED_STATUS_DICT,
          }}
        />

        <VSTableColumn
          dataIndex={['bedSettingType']}
          title="编制类型"
          // valueType="select"
          valueType="codeTableSelect"
          search
          order={0}
          columnKey={'bedSettingTypeIs'}
          hideInTable
          fieldProps={{
            showSearch: true,
            allowClear: true,
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
          }}
        />

        <VSTableColumn
          dataIndex={['wardBranchInstitutionIdIs']}
          title="院区"
          // valueType="select"
          valueType="codeTableSelect"
          search
          order={0}
          columnKey={'wardBranchInstitutionIdIs'}
          hideInTable
          fieldNames={{
            label: 'institutionName',
            value: 'id',
          }}
          fieldProps={{
            showSearch: true,
            allowClear: true,
            dataSource: async (param) => {
              const searchKey = param?.institutionName;
              const res =
                await vsf?.services?.OrganizationController_getAllByInstitutionListQto_a59f48?.(
                  {
                    qto: {
                      upInstitutionIdIs:
                        currentApplication?.department?.branchInstitution
                          ?.upInstitutionId,
                      // institutionNameLike: searchKey,
                      // institutionCodeLike: searchKey,
                    },
                  },
                );
              const _data = searchKey
                ? (res?.data ?? [])?.filter((item) =>
                    askSearch(searchKey, item, 'institutionName'),
                  )
                : res?.data ?? [];
              return _data;
            },
          }}
        />

        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          search
          order={0}
          columnKey={'bedLabelLike'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['sortNumber']}
          title="排序号"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedRoomId']}
          title="房间号"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['wardName']}
          title="护理单元"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['departmentName']}
          title="所属科室"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedClassName']}
          title="床位等级"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedClassPrice']}
          title="床位价格"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedType']}
          title="床位类型"
          valueType="select"
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
          }}
          dataSource={vsf.stores.dbenums?.enums?.BED_TYPE_DICT}
        />

        <VSTableColumn
          dataIndex={['bedStatus']}
          title="床位状态"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.BED_STATUS_DICT}
          render={(dom, record) => {
            return (
              <div
                style={{
                  color: record?.bedStatus == '1' ? '#E34A4E' : '#3276E8',
                }}
              >
                {dom}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['bedSettingType']}
          title="编制类型"
          valueType="select"
          fieldProps={{
            dataSource: vsf.stores.dbenums.enums.BED_APPROVED_TYPE_DICT,
          }}
          dataSource={vsf.stores.dbenums?.enums?.BED_APPROVED_TYPE_DICT}
        />

        <VSTableColumn
          dataIndex={['patternName']}
          title="计费模板"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          title="全院床位列表"
        >
          <VSTableAddition children="新增" />
        </VSTableToolbar>
        {/* <VSTableAddition children="添加" /> */}
      </VSPromiseTable>
      {/* <Button
        children="按钮"
        onClick={async (event) => {
          // TODO: 请确认入参来源及出参用法
          const output = convertPriceItemAdjustRo2SavePriceItemAdjustReq?.(
            vsf?.refs?.priceItemAdjust?.getSelectedRowValues?.(),
          );
          const res =
            await vsf?.services?.PriceListController_savePriceItemAdjust_9c56eb?.(
              { btoList: output?.btoList },
            );
          return res?.data;
        }}
      /> */}
    </Container>
  );
};
export default definePage(Index);
