import './index.less';

import {
  compose,
  Input,
  InputNumber,
  InputProps,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useInputEnter } from './utils';

/**
 * 合并单位的 Input
 */
let InputUnitDynamic: any = (props, ref) => {
  const { value, onChange, onFieldEnter, onKeyUp, dataSource, width, ...rest } =
    props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });

  const [current, setCurrent, getCurrent] = useGetState<any>('');
  const [unit, setUnit, getUnit] = useGetState<any>('日');

  const valueRef = useRef<any>();

  const handleChange = (v) => {
    const formatValue = {
      value: v,
      unit: getUnit(),
    };
    onChange?.(formatValue);
  };

  useEffect(() => {
    setCurrent(value?.value);
    setUnit(value?.unit ?? '日');
    valueRef.current = 1;
  }, [value, setCurrent, setUnit]);

  // console.log('dataSource', dataSource);

  return (
    <div>
      <InputNumber
        {...rest}
        ref={ref}
        value={current}
        onChange={handleChange}
        addonAfter={
          <Select
            className="aspirin-inputUnitDynamic-select"
            width={width ?? 40}
            value={unit}
            onChange={(v) => {
              setUnit(v);
            }}
            dataSource={dataSource}
          ></Select>
        }
        {...fieldEnterProps}
      />
    </div>
  );
};

InputUnitDynamic.displayName = 'InputUnitDynamic';

InputUnitDynamic = React.forwardRef(InputUnitDynamic);

export default compose(
  withRef(),
  withField<any>({
    name: 'inputUnitDynamic',
    handleEnter: true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        const target = props?.value;
        if (typeof target === 'object') {
          return (
            <>
              {props?.value?.value}
              {props?.value?.unit}
            </>
          );
        } else {
          return <>{props?.value}</>;
        }
      },
    },
  ),
)(InputUnitDynamic) as typeof InputUnitDynamic;
