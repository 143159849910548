import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903: {
    method: 'post',
    url: '/api/clinic-bed/query-pagefall-by-clinic-bed-with-patient-qto',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
      ext: {
        statisticsCode: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedWithPatientVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getBedWithPrice_03a9ad: {
    method: 'post',
    url: '/api/clinic-bed/get-bed-with-price',
    parameterFomatter: (data?: { bedId: number }) => data,
    responseFormatter: (_, __, data?: ClinicBedWithPriceEoClinicBedPersistEo) =>
      data,
  },
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserGroupController_getById_bfaf32: {
    method: 'post',
    url: '/api/user-group/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  ClinicInpVisitController_getTransferPatientInfoVoByInpVisitId_13efc4: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-transfer-patient-info-vo-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TransferPatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_transferPatientIn_b929c3: {
    method: 'post',
    url: '/api/clinic-inp-adt/transfer-patient-in',
    parameterFomatter: (data?: {
      saveInpVisitForTransferBto: SaveInpVisitForTransferBtoClinicInpVisitServiceBto;
      transferPatientInParameterEo: TransferPatientInParameterEoClinicInpAdtPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  UserStaffController_getPagedByUserStaffQto_c82b82: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-user-staff-qto',
    parameterFomatter: (data?: { qto: UserStaffNewQtoUserStaffPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
