import { createServices } from '@vs/vsf-boot';
export default createServices({
  DepartmentPriceController_getOrderWithDoctorByOutpVisitEncounterId_52cfba: {
    method: 'post',
    url: '/api/clinic-record-order/get-order-with-doctor-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderWithDoctorVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderExamController_calculateCost_b96118: {
    method: 'post',
    url: '/api/clinic-record-order-exam/calculate-cost',
    parameterFomatter: (data?: {
      eo: CalculateExamCostEoClinicRecordOrderExamPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
});
