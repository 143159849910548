import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedConfigController_getBedClassVsChargeDetailVoById_6d8e13: {
    method: 'post',
    url: '/api/clinic-bed-config/get-bed-class-vs-charge-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BedClassVsChargeDetailVoClinicBedEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_queryByBaseCodeBaseEntityQtoPaged_ddabd6: {
    method: 'post',
    url: '/api/base-dictionary/query-by-base-code-base-entity-qto-paged',
    parameterFomatter: (data?: {
      qto: BaseCodeBaseEntityQtoBaseDictionaryPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeBaseVoClinicBedEntranceWebVoBedclassvschargedetailvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PriceListController_getPagedByPriceItemCurrentNewQto_9a1b8a: {
    method: 'post',
    url: '/api/price-list/get-paged-by-price-item-current-new-qto',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemBaseVoClinicBedEntranceWebVoBedclassvschargedetailvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
