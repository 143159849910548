import vsf, { definePage } from '@vs/vsf-boot';
import {
  Table
} from '@vs/vsf-kit';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

const CustomSelect = ({
  value,
  onChange,
  onSelectChange,
  placeholder,
  addItemOptions,
  loadData,
  columns,
  showLabel,
  width,
  isPreview,
  immediatelyLoad = false, // 是否进入即加载一次
  tableProps
}) => {

  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (immediatelyLoad) {
      handelFormSearch('')
    }
  }, [])

  const handelFormSearch = debounce(async (v) => {
    try {
      console.log('dasdasdas=')
      setLoading(true)
      const _data = await loadData?.(v)

      setLoading(false)
      setDataSource(_data)
      return _data
    } catch (error) {
      console.error(error)
    }
  }, 500)

  return (
    <ComplexSelect
      mode="radio"
      allowClear
      value={value}
      showLabel={showLabel}
      hideSelectIcon
      dropdownMatchSelectWidth={false}
      addItemOptions={addItemOptions}
      onSearch={handelFormSearch}
      dropdownStyle={{
        width,
        // height: 250
      }}
      onChange={v => {
        onSelectChange?.(v);
        onChange(v)
      }}
      placeholder={placeholder}
      isPreview={isPreview}
    >
      <Table
        loading={loading}
        dataSource={dataSource}
        // rowClassName="aspirin-table-row-box"
        columns={columns}
        {...tableProps}
      />
    </ComplexSelect>
  )
}

export default CustomSelect
