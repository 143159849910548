import { useGetState, useSize } from 'ahooks';
import classnames from 'classnames';
import cn from 'classnames';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Checkbox, Image, message, Select } from '@vs/vsf-kit';
import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
// import Icon from '@/module/cpoe/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const mock = [
  {
    id: 1,
    label: '泌尿普通门诊',
    type: '普通号',
    time: '上午',
  },
  {
    id: 3,
    label: '生殖普通门诊',
    type: '普通号',
    time: '上午',
  },
];

const mock1 = [
  {
    id: 2,
    label: '泌尿专家门诊',
    type: '普通号',
    time: '上午',
  },
  {
    id: 4,
    label: '生殖专家门诊',
    type: '普通号',
    time: '上午',
  },
];

const mock2 = [
  {
    id: 1,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#FF7E7E',
  },
  {
    id: 2,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#5494FF',
  },
  {
    id: 3,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#FF7E7E',
  },
  {
    id: 4,
    type: '危急值',
    label: '23床孔瑞，血常规自动分析[中性粒细胞89.7 ]',
    operator: '李梅',
    time: '2023/08/11 10:52',
    status: '确认',
    color: '#5494FF',
  },
];

const weekList = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const Index = (props) => {
  const { user } = props?.stores || {};
  const { hospitalType } = user || {};
  const [checkList, setCheckList, getCheckList] = useGetState([]);

  const [isAll, setIsAll] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);

  const [list, setList] = useState([]);
  const [selectList, setSelectList] = useState();
  // 是否全选
  const [allSelect, setAllSellect] = useState(false);
  // 显示本科全部挂号
  const [myDepartment, setMyDepartment] = useState(false);

  const size = useSize(document.body);

  useEffect(() => {
    getList();
  }, [myDepartment]);

  const handleCheck = (data) => {
    const target = getCheckList();
    const v = target?.find((item) => item === data?.id);
    if (!v) {
      setCheckList([...target, data?.id]);
    } else {
      setCheckList([...target]?.filter((v) => v !== data?.id));
    }
  };

  const target = isShowAll ? [...mock, ...mock1] : mock;

  const handleToDetail = () => {
    if (checkList?.length > 0 || hospitalType === 'in') {
      vsf?.stores?.user?.setMedicalType(
        checkList?.map((item) => {
          const value = target?.find((citem) => citem?.id === item);
          return value ?? {};
        }),
      );

      vsf?.navigateTo('/index/cpoe/center');
    } else {
      message.info('请选择需要出诊的号别');
    }
  };

  const handelSort = (list) => {
    const current = [];
    const other = [];
    list?.forEach((item) => {
      if (item?.timeDescription === dayjs().format('A')) {
        current.push(item);
      } else {
        other.push(item);
      }
    });
    return [...current, ...other];
  };

  const getList = async () => {
    const res =
      await vsf?.services?.OutpRegisterController_getTodayClinicRegister_c256f2?.(
        {
          showDeptAll: myDepartment,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    if (res.code === 200) {
      if (allSelect) {
        const _res = res?.data?.map((item) => {
          return {
            ...item,
            isSelect: true,
          };
        });
        handelSort(_res);
        setList(handelSort(_res));
      } else {
        const _res = res?.data?.map((item) => {
          return {
            ...item,
            isSelect: item?.timeDescription === dayjs().format('A'),
          };
        });
        setList(handelSort(_res));
      }
    }
  };

  const handelTimeDescription = (value) => {
    if (value === 'AM') {
      return '上午';
    } else {
      return '下午';
    }
  };

  const handelClinicTypeClass = (value) => {
    if (value === 'COMMON') {
      return '普通号';
    } else {
      return '专家号';
    }
  };

  const handelSelect = (value) => {
    const res = list.map((item) => {
      if (item?.clinicRegisterDetailId == value?.clinicRegisterDetailId) {
        return {
          ...item,
          isSelect: !item?.isSelect,
        };
      } else {
        return item;
      }
    });
    setList(res);
  };

  const handelAllSelect = (isSelect) => {
    const res = list.map((item) => {
      return {
        ...item,
        isSelect: isSelect ? true : false,
      };
    });
    setList(res);
  };

  const renderListItem = (item) => {
    return (
      <div
        className={cn('list_item', item?.isSelect ? 'list_item_select' : '')}
        onClick={() => {
          handelSelect(item);
        }}
      >
        <div className="list_item_left">
          <Checkbox
            defaultValue={item?.timeDescription === dayjs().format('A')}
            checked={item?.isSelect}
          />
        </div>
        <div className="list_item_center">
          <div className="list_item_center_name">{item?.clinicLabel}</div>
          <div className="list_item_center_type">
            {handelClinicTypeClass(item?.clinicTypeClass)}
          </div>
        </div>
        <div className="list_item_right">
          {handelTimeDescription(item?.timeDescription)}
        </div>
      </div>
    );
  };

  const renderList = (data) => {
    const sum = size?.width <= 2961 ? data?.length % 3 : 0;
    const newData =
      sum === 0
        ? [...data]
        : [...data, ...Array.from({ length: 3 - sum }).fill({ hidden: true })];

    return newData.map((item) => {
      if (item?.hidden) {
        return <div className="list_item list_item_hidden"></div>;
      } else {
        return renderListItem(item);
      }
    });
  };

  return (
    <div className="right-container">
      {/* <div className="login-ab-bg"></div> */}
      {hospitalType === 'out' ? (
        <Block
          title={size?.width <= 2961 ? '' : '今日出诊'}
          footer={
            <div className="submit-oper">
              <div className="choose">
                <Checkbox
                  onChange={(value) => {
                    handelAllSelect(value);
                    setAllSellect(value);
                  }}
                >
                  全选
                </Checkbox>
                <Checkbox
                  onChange={(value) => {
                    setMyDepartment(value);
                  }}
                >
                  显示本科全部号别
                </Checkbox>
              </div>
              <div
                className="btn"
                onClick={() => {
                  const res = list.filter((item) => {
                    return item?.isSelect;
                  });
                  if (res?.length > 0) {
                    vsf?.navigateTo('/index/cpoe/patient-list');
                    vsf?.stores?.cpoe?.setSignSelection(res);
                    setSelectList(res);
                  } else {
                    message.info('请选择需要出诊的号别');
                  }
                }}
              >
                进入我的诊室
              </div>
            </div>
          }
        >
          {/* <div className="title">请选择今日要出诊的号别</div> */}
          <div className="sign_selection">
            <div className="right size">
              <div className="title">请选择今日出诊的号别</div>
              <div
                className={cn(
                  'list noscroll hideScroll',
                  list?.length > 0 ? '' : 'empty',
                )}
              >
                {list?.length > 0 ? renderList(list) : <Empty />}
              </div>
            </div>
          </div>
        </Block>
      ) : (
        <Block
          title="待办任务"
          extra={
            <div className="extra-order-type">
              <span className="label">任务类型</span>
              <Select width={300} />
            </div>
          }
          footer={
            <div className="submit-oper submit-oper-signle">
              <div className="btn" onClick={handleToDetail}>
                进入病房
              </div>
            </div>
          }
        >
          <div className="main-list-in">
            {mock2?.map((item, index) => (
              <div
                key={index}
                className={classnames('item', {
                  'active-item': checkList?.includes(item?.id),
                })}
                style={{
                  borderColor: item?.color ?? 'transparent',
                }}
                onClick={() => {
                  handleCheck(item);
                }}
              >
                <div className="left">
                  <div className="type">{item?.type}</div>
                  <div className="label">{item?.label}</div>
                  <div className="extra">
                    <div className="name">{item.operator}</div>
                    <div className="time">{item.time}</div>
                  </div>
                </div>
                <div className="right">
                  <div className="more">查看更多</div>
                  <div className="oper">
                    <span className="status">{item?.status}</span>
                    <span className="icon">
                      <Icon type="icon-you" />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Block>
      )}
    </div>
  );
};

export default definePage(Index);
