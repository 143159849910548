import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_getRefundDispenseForReturn_5b593b: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-all-by-query-drug-refund-dispense-for-return-qto',
    parameterFomatter: (data?: {
      qto: QueryDrugRefundDispenseForReturnQtoDrugPrescriptionDispensePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPrescriptionDispenseMasterForReturnRefundVoDrugPrescriptionDispenseEntranceWebVo[],
    ) => data,
  },
  DrugPrescriptionRefundDispenseController_returnRefundDispense_c77fa9: {
    method: 'post',
    url: '/api/drug-prescription-dispense/return-refund-dispense',
    parameterFomatter: (data?: {
      drugPrescriptionDispenseMasterIds: number[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
