import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpSettleController_settle_b54c18: {
    method: 'post',
    url: '/api/outp-billing/settle',
    parameterFomatter: (data?: {
      parameterEo: OutpSettleInputParameterEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpSettleController_preSettle_f20115: {
    method: 'post',
    url: '/api/outp-billing/pre-settle',
    parameterFomatter: (data?: {
      patientId: number;
      selfPaymentIndicator: boolean;
      outpVisitUnsettledRecordVos: OutpVisitUnsettledRecordVoOutpBillingEntranceWebVo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpPreSettleVoOutpBillingEntranceWebVo,
    ) => data,
  },
  OutpBillingController_queryAllUnsettledOutpVisitRecord_fad1e4: {
    method: 'post',
    url: '/api/outp-billing/query-all-unsettled-outp-visit-record',
    parameterFomatter: (data?: {
      patientId: number;
      displayId: string;
      outpVisitId: number;
      clinicMiddleSettleIndicator: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpPatientUnsettledRecordVoOutpBillingEntranceWebVo,
    ) => data,
  },
});
