import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableToolbar,
  VSTableColumn,
  VSPromiseTable,
  VSTable,
  Modal,
  Button,
  message,
  Search,
} from '@vs/vsf-kit';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import ModalCom from './modalCom';
import { useRequest } from 'ahooks';
import { getScrollY } from '@/utils';
import { filterEmptyValues } from '@/utils/setDataUtil';
import { buildTree, appendTreeLevel } from '@/components/TreeMenu/utils';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { Flex } from '@/pages/Index/components';
import Button_and_keydown from '../../components/button_and_keydown';
const DictionaryList = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();

  const [search] = useOnChangeValue([]);
  // const [tableList, setTableList] = useState([]);
  const listData = useRef();
  const { data: ListData, run } = useRequest(
    async () => {
      const res =
        await vsf.services?.BaseDictionaryController_getEoClassByTypeId_d570f0?.(
          {
            typeId: props.value?.id,
          },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );

  // 添加 - 弹框 - 确认
  const onSave = async () => {
    // const file1 =
    //   vsf?.refs?.DictionaryEditForm?.validateFieldsReturnFormatValue();
    // const extendedDataForm2 =
    //   vsf?.refs?.extendedDataForm?.validateFieldsReturnFormatValue();

    const [file, extendedDataForm, fetch] = await Promise.all([
      vsf?.refs?.DictionaryEditForm?.validateFieldsReturnFormatValue(),
      vsf?.refs?.extendedDataForm?.validateFieldsReturnFormatValue(),
      onFetch(),
    ]);
    const extended = ListData?.length
      ? JSON?.stringify(filterEmptyValues(extendedDataForm))
      : '{}';

    if (file?.id) {
      const res =
        await vsf.services?.BaseDictionaryController_updateBaseCode_c565bf?.({
          btoParam: {
            ...file,
            codeType: props.value?.code,
            extensionJson: extended,
          },
        });

      if (res?.code === 200) {
        message.success('编辑成功');
        setOpen(false);
        setData(void 0);
        vsf?.refs?.stopwatchTable?.reload();
      }
    } else {
      // console.log(fetch, 'table_fetch');
      // console.log(fetch.data, 'table_data');
      // console.log(fetch.total, 'table_total');
      // console.log(fetch.total - 1, 'table_total');
      // console.log(fetch?.data[fetch.data.length - 1], 'table_fetch.total');
      const res =
        await vsf.services?.BaseDictionaryController_createBaseCode_0172bf?.({
          btoParam: {
            ...file,
            sortNumber: fetch.total - 1,
            // 码表类型
            codeType: props.value?.code,
            extensionJson: extended,
          },
        });
      if (res?.code === 200) {
        message.success('新增成功');
        setOpen(false);
        setData(void 0);
        vsf?.refs?.stopwatchTable?.reload();
      }
    }
  };

  // 删除
  const onRemove = async (data) => {
    const res =
      await vsf.services?.BaseDictionaryController_deleteBaseCode_e64f94?.({
        btoParam: { id: data?.id },
      });

    if (res?.code === 200) {
      message.success('删除成功');
      setData(void 0);
      vsf?.refs?.stopwatchTable?.reload();
    }
  };

  // const [dataSource] = useOnChangeValue([]);

  // 首次获取数据
  const onFetch = useCallback(async () => {
    if (search?.value?.length) {
      return {
        data: search?.value,
      };
    }
    if (props.value?.id && !open) {
      const res =
        await vsf.services?.BaseDictionaryController_getByCodeType_a0a0ce?.({
          codeType: props.value?.code,
        });
      const _data = appendTreeLevel(
        buildTree(res?.data?.result ?? res?.data ?? [], {
          id: 'code',
          parentId: 'parentCode',
        }),
      );
      listData.current = _data;
      // setTableList(res?.data?.result ?? res?.data ?? []);
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  }, [props.value?.id, search?.value]);

  useEffect(() => {
    props.value?.id && run();
  }, [props.value?.id]);

  // 拖拽
  const onSort = async (newly, array) => {
    const list = newly
      ?.map((item, index) => ({
        ...item,
        sortNumber: index + 1,
      }))
      .filter((item, index) => {
        return array?.[index]?.id !== item?.id;
      });
    if (list.length) {
      const res = await Promise.all(
        list?.map((item) => {
          const res =
            vsf.services?.BaseDictionaryController_updateBaseCode_c565bf?.({
              btoParam: {
                ...item,
              },
            });
          return res;
        }),
      );

      if (res?.length) {
        // message.success('排序成功');
        vsf?.refs?.stopwatchTable?.reload();
      }
    }
  };

  const expandedRowRender = (record) => {
    return (
      <VSTable
        editable={{
          editType: 'single',
          onCanEdit: () => null,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-bianji" size={24} />,
              order: 1,
              onClick: (event, data) => {
                setOpen(true);
                setData(data);
              },
            },
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: '是否删除该码表！',
                  onOk: () => {
                    // setData(data);
                    onRemove(data);
                  },
                });
              },
            },
          ],
          columnProps: {
            width: 90,
          },
        }}
        pagination={false}
        id="CodeTable"
        // value={record?.children}
        showHeader={false}
        value={record?.children}
        // extraParams={props.value}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => {
            return record?.children?.length > 0;
          },
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          hideSelectAll: true,
        }}
        className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
        // bordered
        dragSort={{
          dragSortDataIndex: ['sort'],
          onDragSortEnd: (newly) => {
            const array = listData.current?.filter(
              (item) => item?.code === newly?.[0]?.parentCode,
            );
            if (array?.length) {
              onSort(newly, array?.[0]?.children);
            }
          },
        }}
      >
        <VSTableColumn valueType="sort" dataIndex={['sort']} width={50} />
        <VSTableColumn
          title="序列号"
          dataIndex={['sortNumber']}
          valueType="digit"
          // width={newWidth}
          width={100}
          fieldProps={{}}
        />

        <VSTableColumn
          title="显示名"
          dataIndex={['name']}
          valueType="text"
          fieldProps={{
            defaultValue: data?.name,
          }}
        />

        <VSTableColumn
          title="是否停用"
          dataIndex={['deprecateIndicator']}
          valueType="switch"
          fieldProps={{}}
          width={120}
        />

        <VSTableColumn
          title="输入码"
          dataIndex={['inputCode']}
          valueType="text"
          fieldProps={{}}
          width={120}
        />

        <VSTableColumn
          title="编码"
          dataIndex={['code']}
          valueType="text"
          fieldProps={{}}
          width={120}
        />
      </VSTable>
    );
  };

  const onSearch = async (params) => {
    const searchKeyword = params;
    if (!searchKeyword) {
      search?.onChange?.([]);
      return;
    }
    const res =
      await vsf.services?.BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27?.(
        {
          qto: {
            orNameLike: searchKeyword,
            orInputCodeLike: searchKeyword,
          },
          ext: {},
        },
      );
    const queryResult = res?.data?.result;
    if (queryResult) {
      search?.onChange?.(queryResult);
    }
    return;
  };

  // 添加
  const onAdd = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Flex vertical gap={10}>
        <Flex justify="space-between">
          <Flex gap={10} align="center">
            <span className="icon18">码表关键字:</span>
            <Search
              id="baseCodeSearch"
              width="300px"
              placeholder="请输入码表名称或拼音首字母"
              bordered={true}
              onSearch={onSearch}
            />
          </Flex>
          <Button
            size="large"
            className="icon16"
            onClick={() => onAdd()}
            type="primary"
            disabled={props?.value?.children?.length}
            icon={<Icon type="icon-tianjia1" />}
          >
            添加
          </Button>
        </Flex>
        <VSPromiseTable
          editable={{
            editType: 'single',
            onCanEdit: () => null,
            onCanDelete: () => null,
            columnProps: {
              width: 90,
            },
            extraOperations: [
              {
                children: <Icon type="icon-bianji" size={24} />,
                order: 1,
                onClick: (event, data) => {
                  setOpen(true);
                  setData(data);
                },
              },
              {
                children: <Icon type="icon-shanchu1" size={24} />,
                order: 1,
                onClick: (event, data) => {
                  Tip({
                    content: '是否删除该码表！',
                    onOk: () => {
                      // setData(data);
                      onRemove(data);
                    },
                  });
                },
              },
            ],
          }}
          pagination={false}
          // bordered
          id="stopwatchTable"
          scroll={{
            y: getScrollY(280),
          }}
          onFetch={onFetch}
          extraParams={props.value}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => {
              return record?.children?.length > 0;
            },
          }}
          className="table_overflow_300 aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          dragSort={{
            dragSortDataIndex: ['sort'],
            onDragSortEnd: (newly) => {
              onSort(newly, listData.current);
            },
          }}
        >
          <VSTableColumn valueType="sort" dataIndex={['sort']} width={50} />
          <VSTableColumn
            title="序列号"
            dataIndex={['sortNumber']}
            valueType="digit"
            fieldProps={{}}
            width={100}
          />
          <VSTableColumn
            title="显示名"
            dataIndex={['name']}
            valueType="text"
            fieldProps={{
              defaultValue: data?.name,
            }}
          />

          <VSTableColumn
            title="是否停用"
            dataIndex={['deprecateIndicator']}
            valueType="switch"
            fieldProps={{}}
            width={120}
          />

          <VSTableColumn
            title="输入码"
            dataIndex={['inputCode']}
            valueType="text"
            fieldProps={{}}
            width={120}
          />

          <VSTableColumn
            title="编码"
            dataIndex={['code']}
            valueType="text"
            fieldProps={{}}
            width={120}
          />
        </VSPromiseTable>
      </Flex>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
          setData(void 0);
        }}
        width="70%"
        title="码表维护"
        onOk={onSave}
        cancelText="取消"
        okText="确定"
      >
        <ModalCom data={data} extendedData={ListData} list={listData} />
      </Modal>
    </Fragment>
  );
};
export default definePage(DictionaryList);
