import './index.less';

import { definePage } from '@vs/vsf-boot';
import React from 'react';

import Container from '@/module/Container';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';

import ApplicationForWesternMedicineRefundInWard from './application';
import Search from './search';
/**
 * 病区西药退药申请
 * @returns {JSX.Element}
 */
const Major = () => {
  const [select] = useOnChangeValue({});

  return (
    <Container
      layout={{
        items: [
          {
            block: '21%',
            bgColor: 'var(--background)',
            padding: '24px',
            gap: '1.34rem',
          },
          {
            block: 1,
            bgColor: 'var(--background)',
            padding: '1.6rem',
          },
        ],
      }}
      className="ApplicationForWesternMedicineRefundInWard"
    >
      <Search onChange={select?.onChange} />
      <ApplicationForWesternMedicineRefundInWard value={select?.value} />
    </Container>
  );
};

export default definePage(Major);
