import { createServices } from '@vs/vsf-boot';
export default createServices({
  DailyInpSettleMasterController_queryHospitalChargesDaily_198b34: {
    method: 'post',
    url: '/api/inp-billing/query-hospital-charges-daily',
    parameterFomatter: (data?: {
      qto: InpSettleMasterWithPaymentAndItemQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DailyInpSettleMasterCollectVoInpBillingEntranceWebVo,
    ) => data,
  },
});
