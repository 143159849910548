/* eslint-disable no-case-declarations */
import vsf from '@vs/vsf-boot';
import { Alert, List, message, Popover, Tooltip } from '@vs/vsf-kit';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import Icon from '@/module/Icon';
import { day } from '@/pattern';

import Determine from '../../../determine';
import Flex from '../../../flex';
import { askFixed } from '../../../func/ask';
import { dataSourceHerb } from '../../in_patient/table/datasource';
import { orderStatusDataSource } from './datasource';

/**
 * 医嘱数据是否预览判断，可数类型数据为编辑态，不可数类型数据为预览态
 * @param param
 * @returns
 */
export const countablePreview: any = (data) => {
  const previewClassList = [
    { label: '治疗', value: 'TREAT' },
    { label: '手术', value: 'OPERATION' },
    { label: '麻醉', value: 'ANESTHESIA' },
    { label: '护理', value: 'NURSING' },
    { label: '膳食', value: 'MEAL' },
    { label: '营养', value: 'NUTRITION' },
    { label: '会诊', value: 'CONSULTATION' },
    { label: '用血', value: 'BLOOD' },
    { label: '其他', value: 'OTHER' },
  ];
  const value = previewClassList
    ?.map((item) => item?.value)
    .includes(data?.orderClass);
  return value;
};

const render = ({ select, getValue, onCancelGroup }) => {
  return {
    // 状态颜色
    rowClassNames: (record) => {
      let resClassName = '';
      if (select?.value?.id === record?.id) {
        resClassName = 'aspirin-table-row-active ';
      }
      const { orderStatus, chargeStatus } = record ?? {};
      switch (orderStatus) {
        case 'SAVE':
          resClassName += 'aspirin-table-row-order-blue';
          break;
        case 'SUBMIT':
        case 'PERFORM':
          if (chargeStatus !== 'ALL_REFUND')
            resClassName += 'aspirin-table-row-order-green';
          break;
        // case 'PERFORM':
        //   resClassName += 'aspirin-table-row-order-black';
        //   break;
        case 'STOP':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'CANCEL':
          resClassName += 'aspirin-table-row-order-red';
          break;
        case 'AUDIT_FAIL':
          resClassName += 'aspirin-table-row-order-pink';
          break;
        default:
          resClassName += 'aspirin-table-row-order-blue';
          break;
      }
      return resClassName;
    },
    // 行颜色
    rowClassName: (record) => {
      let resClassName = '';
      if (select?.value?.id === record?.id) {
        resClassName = 'aspirin-table-row-active ';
      }
      const { orderStatus, chargeStatus } = record ?? {};
      switch (orderStatus) {
        // 提交、执行
        case 'SUBMIT':
        case 'PERFORM':
          if (chargeStatus !== 'ALL_REFUND')
            resClassName += 'aspirin-table-row-order-black';
          break;
        // 开立
        case 'OPEN':
        case 'SAVE':
          resClassName += 'aspirin-table-row-order-blue';
          break;
      }
      return resClassName;
    },
    renderOrderText: (...args) => {
      const [, record] = args;
      const { examOrder, labOrder, operationOrder, pathologyOrder, drugOrder } =
        record || {};
      const content = record?.orderText?.drugProductName
        ? `${record?.orderText?.drugProductName}(${record?.orderText?.packageSpecification})`
        : record?.orderText;
      let name = (
        <Popover title="详细信息" content={content} placement="right">
          <div className={classnames('aspirin-order-text', record?.groupName)}>
            <Determine
              condition={record?.groupName === 'aspirin-order-text-group-first'}
            >
              <div
                style={{
                  position: 'absolute',
                  left: '-5px',
                  top:
                    record?.groupIndex?.length === 2
                      ? '70%'
                      : record?.groupIndex?.length * 45 +
                        record?.groupIndex?.length +
                        '%',
                  zIndex: 9999,
                  cursor: 'pointer',
                  background:
                    select?.value?.id === record?.id
                      ? '#cce5ff'
                      : 'rgb(241, 246, 250)',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="aspirin-group-icon-box"
                onClick={(event) => {
                  event?.stopPropagation();
                  if (!['OPEN', 'SAVE'].includes(record?.orderStatus)) {
                    message?.warning('只有保存和开立状态才能取消成组');
                    return;
                  }
                  if (record?.parentRelatedUuid) {
                    message?.warning('皮试药品不能取消成组');
                    return;
                  }
                  const list = getValue()?.filter((...[, index]) =>
                    record?.groupIndex?.includes(index),
                  );
                  const row = list?.map((item) =>
                    document.querySelector(`[data-row-key="${item?.id}"]`),
                  );
                  for (const item of row) {
                    item.classList?.add('aspirin-group-text-box');
                  }
                  setTimeout(() => {
                    onCancelGroup?.(record);
                  }, 100);
                }}
              >
                <Icon
                  type="icon-chengzulianjie"
                  size={26}
                  style={{ color: 'var(--blue)' }}
                />
              </div>
            </Determine>
            <Determine
              condition={Boolean(
                examOrder?.emergencyIndicator ||
                  labOrder?.emergencyIndicator ||
                  operationOrder?.operationApplyType === 'EMERGENCY' ||
                  pathologyOrder?.emergencyIndicator,
              )}
            >
              <Popover content="加急">
                <Icon
                  type="icon-a-Frame1517"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              </Popover>
            </Determine>
            <Determine condition={Boolean(drugOrder?.drug?.dangerIndicator)}>
              <Popover content="高危">
                <Icon
                  type="icon-gaowei"
                  size={20}
                  style={{
                    marginRight: 4,
                  }}
                />
              </Popover>
            </Determine>
            <span style={{ zIndex: 10000 }}>{content}</span>
          </div>
        </Popover>
      );
      switch (record?.orderClass) {
        case 'EXAM':
          name = (
            <Popover
              placement="right"
              title={
                <div className="aspirin-title-popover-box">
                  检查
                  <span> {record?.examOrder?.examOrderDetail?.length} </span>
                  项详情
                </div>
              }
              content={
                <List
                  size="small"
                  dataSource={record?.examOrder?.examOrderDetail}
                  renderItem={(item: any, index) => {
                    return (
                      <List.Item
                        style={{
                          padding: '8px 0',
                          width: '100%',
                        }}
                      >
                        <Alert
                          type="info"
                          style={{
                            width: '100%',
                          }}
                          message={`${index + 1}. ${item?.examItemName}`}
                        />
                      </List.Item>
                    );
                  }}
                />
              }
              trigger="hover"
            >
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                <Determine
                  condition={Boolean(
                    examOrder?.emergencyIndicator ||
                      labOrder?.emergencyIndicator ||
                      operationOrder?.operationApplyType === 'EMERGENCY' ||
                      pathologyOrder?.emergencyIndicator,
                  )}
                >
                  <Popover content="加急">
                    <Icon
                      type="icon-a-Frame1517"
                      size={20}
                      style={{
                        marginRight: 4,
                      }}
                    />
                  </Popover>
                </Determine>

                <span>{content}</span>
              </div>
            </Popover>
          );
          break;
        case 'HERB':
          const use_way_label = record?.herbOrder?.useWay;
          const useWay = use_way_label?.label
            ? use_way_label?.label
            : vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
                (item) => use_way_label == item?.value,
              )?.label;
          const frequency = record?.herbOrder?.frequency?.frequency;
          const use_time_description = record?.herbOrder?.useTimeDescription;
          const useTimeDescription = use_time_description?.label
            ? use_time_description?.label
            : vsf.stores.dbenums.enums.HERB_ADMINISTRATION_DICT?.find(
                (item) => item?.value == use_time_description,
              )?.label;
          name = (
            <Popover
              placement="right"
              overlayClassName="aspirin-herb-box"
              content={
                <List
                  size="small"
                  header={
                    <div className="aspirin-title-herb-popover-box">
                      <div className="aspirin-title-herb-header-box">
                        <div className="aspirin-title-herb-header-left-box">
                          <span>中药汤剂明细</span>
                          <span>
                            {record?.herbOrder?.herbOrderDetail?.length} 味药
                          </span>
                        </div>
                        <div className="aspirin-title-herb-header-right-box">
                          <Icon
                            type="icon-zhongtangyaodanchuangbeijing"
                            style={{
                              color: 'rgb(232, 225, 217)',
                            }}
                            size={56}
                          />
                        </div>
                      </div>
                      <div className="aspirin-title-message-box">
                        <div>使用方法: {useWay}</div>
                        <div>服用时间: {useTimeDescription}</div>
                        <div>服用频次: {frequency}</div>
                        <div>付数: {record?.herbOrder?.herbRegimen}</div>
                        <div className="aspirin-title-message-memo-box">
                          备注: {record?.memo}
                        </div>
                      </div>
                    </div>
                  }
                  dataSource={record?.herbOrder?.herbOrderDetail}
                  className="aspirin-list-herb-popover-item-box"
                  renderItem={(item: any, index) => {
                    const find =
                      vsf?.stores?.dbenums?.enums?.DRUG_DECOCTION_DICT?.find(
                        ({ value }) => item?.useDescription === value,
                      );
                    const medicalInsuranceType = dataSourceHerb?.find(
                      ({ value }) => value === item?.medicalInsuranceType,
                    )?.label;
                    const price = String?.(item?.id).startsWith('create')
                      ? askFixed(item?.price ?? 0, 2)
                      : askFixed(
                          item?.price *
                            (item?.totalAmount?.value ?? item?.totalAmount),
                          2,
                        );
                    return (
                      <Alert
                        type="warning"
                        message={
                          <div className="aspirin-title-herb-popover-item-box">
                            <span>{index + 1}</span>
                            <span>{item?.drugProductName}</span>
                            <span>
                              {Object?.values(
                                {
                                  value: item?.totalAmount,
                                  unit:
                                    vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                                      ({ value }) => value == item?.packageUnit,
                                    )?.label ?? item.packageUnit,
                                } ?? {},
                              )}
                            </span>
                            {find?.label && <span>{find?.label}</span>}
                            <span>
                              {price ? price + '元' : ''}
                              {medicalInsuranceType
                                ? '/' + medicalInsuranceType
                                : ''}
                            </span>
                          </div>
                        }
                      />
                    );
                  }}
                />
              }
              trigger="hover"
            >
              <div
                className={classnames('aspirin-order-text', record?.groupName)}
              >
                <span>{record?.orderText?.drugName ?? record?.orderText}</span>
              </div>
            </Popover>
          );
          break;
      }
      return name;
    },
    renderOrderStatus: (...args) => {
      const [, record] = args;
      // console.log(record, args);

      const chargeStatus = [
        {
          value: 'NOT_CHARGE',
          label: '提交',
        },
        {
          value: 'FINISH_CHARGE',
          label: '已收费',
        },
        {
          value: 'PARTIAL_REFUND',
          label: '部分退费',
        },
        {
          value: 'ALL_REFUND',
          label: '已退费',
        },
      ];
      let orderStatus: any = null;
      switch (record?.orderClass) {
        case 'DRUG':
        case 'HERB':
          switch (record?.dispenseHandleType) {
            case 'ALL_REFUND':
              orderStatus = (
                <Tooltip title="全部退药" trigger="hover">
                  <Icon type="icon-yituiyao" size={24} />
                </Tooltip>
              );
              break;
            case 'PARTIAL_REFUND':
              orderStatus = (
                <Tooltip title="部分退药" trigger="hover">
                  <Icon type="icon-bufentuiyao" size={24} />
                </Tooltip>
              );
              break;
            case 'CANCEL_REFUND':
              orderStatus = (
                <Tooltip title="反退药" trigger="hover">
                  <Icon type="icon-yidengji" size={24} />
                </Tooltip>
              );
              break;
            case 'DISPENSE':
              orderStatus = (
                <Tooltip title="发药" trigger="hover">
                  <Icon type="icon-yifayao" size={24} />
                </Tooltip>
              );
          }
          break;
        case 'EXAM':
          switch (record?.examOrder?.resultStatus) {
            case '8':
              orderStatus = (
                <Tooltip title="已登记" trigger="hover">
                  <Icon type="icon-yidengji" size={24} />
                </Tooltip>
              );
              break;
            case '3':
              orderStatus = (
                <Tooltip title="初步报告" trigger="hover">
                  <Icon type="icon-chububaogao" size={24} />
                </Tooltip>
              );
              break;
            case '4':
              orderStatus = (
                <Tooltip title="确认报告" trigger="hover">
                  <Icon type="icon-querenbaogao" size={24} />
                </Tooltip>
              );
          }
          break;
        case 'LAB':
          switch (record?.labOrder?.labStatus) {
            case '7':
              orderStatus = (
                <Tooltip title="已登记" trigger="hover">
                  <Icon type="icon-yidengji" size={24} />
                </Tooltip>
              );
              break;
            case '3':
              orderStatus = (
                <Tooltip title="初步报告" trigger="hover">
                  <Icon type="icon-chububaogao" size={24} />
                </Tooltip>
              );
              break;
            case '4':
              orderStatus = (
                <Tooltip title="确认报告" trigger="hover">
                  <Icon type="icon-querenbaogao" size={24} />
                </Tooltip>
              );
          }
      }
      if (['SUBMIT', 'PERFORM']?.includes(record?.orderStatus)) {
        // console.log(chargeStatus, record?.orderStatus, 'record?.orderStatus');
        // 保存蓝色，提交绿色，其他未黑色
        return (
          <Flex justify="space-between">
            {/* 已收费为黑色，其他为绿色 */}
            <div
              style={{
                color: record?.chargeStatus == 'ALL_REFUND' ? 'black' : 'green',
              }}
            >
              {
                chargeStatus?.find(
                  (item) => item?.value === record?.chargeStatus,
                )?.label
              }
            </div>
            <div style={{ color: 'green' }}>{orderStatus ?? ''}</div>
          </Flex>
        );
      } else if (['SAVE', 'OPEN']?.includes(record?.orderStatus)) {
        return (
          <div style={{ color: '#3276E8' }}>
            {
              orderStatusDataSource?.find(
                (item) => item?.value === record?.orderStatus,
              )?.label
            }
          </div>
        );
      } else {
        return (
          <div style={{ color: 'black' }}>
            {
              orderStatusDataSource?.find(
                (item) => item?.value === record?.orderStatus,
              )?.label
            }
          </div>
        );
      }
    },
    renderRetailPrice: (...[, record]) => {
      if (countablePreview(record)) {
        return askFixed(record?.retailPrice, 2) + '元';
      }
      const price: any = record?.allPrice ?? 0;
      const new_price = askFixed(price / record?.totalAmount?.value, 2);
      switch (record?.orderClass) {
        case 'DRUG':
        case 'HERB':
          return Number?.isNaN(new_price) || !record?.allPrice
            ? ''
            : new_price + '元';
        default:
          return Number?.isNaN(price) || !record?.allPrice
            ? ''
            : askFixed(record?.allPrice ?? 0, 2) + '元';
      }
    },
  };
};

export default render;
