import './index.less';

import classNames from 'classnames';
import { render, unmount } from 'rc-util/lib/React/render';
import React, { useCallback, useState } from 'react';

import { DomKey } from './constants';

export type ContextMenuConfigType = {
  items: ContextMenuItemType[]; // 菜单项
  trigger?: 'click' | 'hover'; // context menu 触发方式
};

/**
 * ContextMenu类型
 */
type ContextMenuType = {
  config?: ContextMenuConfigType;
  record?: any;
};

type ContextMenuListType = ContextMenuConfigType & {
  /**
   * 树状结构当前项信息
   */
  record?: any;
  /**
   * 自定义类名
   */
  className?: string;
};

type ContextMenuItemType = {
  /**
   * 名称
   */
  label?: string;
  /**
   * key
   */
  key?: string;
  /**
   * 子菜单
   */
  children?: any;
  /**
   * 菜单单项是否隐藏
   */
  hidden?: boolean;
  /**
   * 菜单单项点击是否关闭 (默认非叶子节点点击不会关闭菜单)
   */
  close?: boolean;
  /**
   * 菜单单项点击回调
   * @param node 树状结构当前项信息
   * @returns void;
   */
  callback?: (node: any) => void;
};

const List = (props: ContextMenuListType) => {
  const { items, record, className, trigger } = props;
  const [showChildKey, setShowChlidKey] = useState<string>();

  const handleSetShowChlidKey = (item) => {
    setShowChlidKey(item?.key);
  };

  return (
    <div className={`context-list ${className ?? ''}`}>
      {items &&
        (items || [])?.map(
          (item) =>
            !item?.hidden && (
              <Item
                key={item?.key}
                data={item}
                record={record}
                trigger={trigger}
                showChildKey={showChildKey}
                onShowChild={handleSetShowChlidKey}
              />
            ),
        )}
    </div>
  );
};

const Item = (props: {
  data: ContextMenuItemType;
  record?: any[];
  onClick?: (v) => void;
  trigger?: string;
  showChildKey?: string;
  onShowChild?: (v) => void;
}) => {
  const { data, record, showChildKey, onShowChild, trigger } = props;

  const onClick = (target) => {
    if (!!target?.callback && typeof target?.callback === 'function') {
      target?.callback(record);
    }
    if (target?.close === true || isLeaf(target)) {
      // dom handle
      if ((window as any).__VP_PORTAL_EVENT__) {
        unmount((window as any).__VP_PORTAL_EVENT__[DomKey]);
      }
    }
  };

  const renderChildContent = useCallback(
    (data) => {
      const value = data?.children;
      if (!value) return null;
      if (Array.isArray(value)) {
        return (
          <List
            items={value}
            record={record}
            className={`context-children-list ${
              showChildKey === data?.key ? 'context-children-list-show' : ''
            }`}
          />
        );
      }
    },
    [showChildKey, record],
  );

  const isLeaf = (data) => {
    return !data?.children || data?.children?.length === 0;
  };

  return (
    <div
      className="context-item"
      key={data?.key}
      onClick={(event) => {
        event.stopPropagation();
        onClick(data);
        if (trigger === 'click') {
          onShowChild?.(data);
        }
      }}
      onMouseEnter={() => {
        if (trigger === 'hover') {
          onShowChild?.(data);
        }
      }}
    >
      {data?.label}
      {renderChildContent(data)}
    </div>
  );
};

const Index = (props: ContextMenuType) => {
  const { config, record } = props;
  const { trigger = 'hover' } = config || {};

  const getItems = (data, value) => {
    if (data && typeof data === 'function') {
      return data(value);
    } else {
      return data;
    }
  };

  const items = record ? getItems(config?.items, record) : config?.items;
  return (
    <div className="tree-menu-context-container">
      <List items={items} record={record} trigger={trigger} />
    </div>
  );
};

export default Index;
