import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getClinicItemById_97a99d: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
});
