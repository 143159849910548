import { useCallback, useState } from 'react';

import { VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import vsf, { definePage } from '@vs/vsf-boot';

import { onDrugInfoFetch } from './service';

const DrugInfo = ({ id }) => {
  const [dataSource, setDataSource] = useState();
  const onFetch = useCallback(async () => {
    if (!id) return setDataSource();
    const result = await onDrugInfoFetch?.({
      id,
    });
    setDataSource(result);
    return result;
  }, [id]);
  // 是否代煎
  const getHerbDecoctIndicator = (Decoct) => {
    switch (Decoct) {
      case 'HOSPITAL_DECOCT':
        return <div style={{ fontSize: '26px', color: 'red' }}>代煎</div>;
      default:
        return '';
    }
  };
  return (
    <VSForm vsid="92510" id="formHerb" onFetch={onFetch}>
      <VSFormLayout key="0" columnCount={4}>
        <VSFormItem
          name={['createdAt']}
          label="处方日期"
          valueType="date"
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['prescriptionSource']}
          label="处方来源"
          valueType="select"
          dataSource={[
            { label: '门诊', value: 'OUTP' },
            { label: '住院', value: 'INP' },
            { label: '收费处', value: 'BILLING_WINDOW' },
            { label: '药房', value: 'PHARMACY' },
            { label: '住院退药', value: 'INP_REFUND' },
            { label: '急诊绿区', value: 'ERP_GREEN_AREA' },
            { label: '急诊红区', value: 'ERP_RED_AREA' },
            { label: '急诊红区退药', value: 'ERP_RED_AREA_REFUND' },
            { label: '出院患者退药', value: 'DISCHARGE_REFUND' },
          ]}
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['prescriptionNumber']}
          label="处方号"
          valueType="text"
          rules={[
            {
              message: '数据格式错误',
              type: 'string',
              min: undefined,
              max: 20,
            },
          ]}
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['staffName']}
          label="开单医生"
          valueType="text"
          rules={[
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ]}
          fieldProps={{}}
          readonly
        />
      </VSFormLayout>
      <VSFormLayout key="1" columnCount={4}>
        <VSFormItem
          name={['departmentName']}
          label="开单科室"
          valueType="text"
          rules={[
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ]}
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['agreedPrescriptionName']}
          label="协定方名称"
          valueType="text"
          rules={[
            {
              message: '协定方名称长度不合法',
              type: 'string',
              min: 0,
              max: 1024,
            },
          ]}
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['diagnosisName']}
          label="诊断"
          valueType="text"
          fieldProps={{}}
          readonly
        />
      </VSFormLayout>
      <VSFormLayout key="2" columnCount={4}>
        <VSFormItem
          name={['herbType']}
          label=" 处方类型"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.CHINESE_MEDICINE_DICT}
          readonly
        />

        <VSFormItem
          name={['useWay']}
          label="用法描述"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEDICINE_USAGE_DICT}
          readonly
        />

        <VSFormItem
          name={['herbRegimen']}
          label="付数"
          valueType="digit"
          fieldProps={{}}
          readonly
        />
      </VSFormLayout>
      <VSFormLayout key="3" columnCount={4}>
        <VSFormItem
          name={['averageCharge']}
          label="均贴价"
          valueType="digit"
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['totalCharge']}
          label="总金额"
          valueType="digit"
          fieldProps={{}}
          readonly
        />

        <VSFormItem
          name={['decoctMethod']}
          label="煎药方式"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_DECOCTION_DICT}
          readonly
        />
        {getHerbDecoctIndicator(dataSource?.herbDecoctIndicator)}
      </VSFormLayout>
    </VSForm>
  );
};

export default definePage(DrugInfo);
