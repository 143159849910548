import { Modal, Section, VSTableColumn, VSPromiseTable } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

function OrderBilleDetails({ activityRow }) {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      title={<div className="modal-fee-revivw">医嘱计费详情</div>}
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      width={1246}
      okText={'确定'}
      cancelText={'取消'}
    >
      <VSPromiseTable
        editable={{ editType: 'single', hideEditColumn: true }}
        vsid="64460"
        id="orderBillingDetailList"
        onFetch={async (params) => {
          const res =
            await vsf.services?.InpBillingDetailController_getBillingDetailByCostId_5be372?.(
              {
                inpVisitId: params?.inpVisitId,
                costId: params?.costId,
                orderCostIndicator: params?.orderCostIndicator,
              },
            );
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
        onUpdate={async (params) => {
          const res =
            await vsf.services?.InpBillingDetailController_adjustBillingDetailAmount_df7221?.(
              {
                adjustBillingDetailEo: params?.adjustBillingDetailEo,
              },
            );
          return res?.data;
        }}
      >
        <VSTableColumn
          dataIndex={['billingStatus']}
          title="计费状态"
          valueType="select"
          dataSource={[
            { label: '未计费', value: 'UNBILLING' },
            { label: '已计费', value: 'BILLING' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemClass']}
          title="价表项目分类"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="项目规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['brand']}
          title="品牌"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 80 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="项目单价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['adjustAmount']}
          title="调整数量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['cost']}
          title="应收费用"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderDate']}
          title="业务实际执行时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['selfPaymentIndicator']}
          title="强制自费标记"
          valueType="switch"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['medicalInsuranceType']}
          title="医保项目类别"
          valueType="select"
          dataSource={[
            { label: '甲', value: 'ALPHA' },
            { label: '乙', value: 'BETA' },
            { label: '丙', value: 'GAMMA' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderDoctorName']}
          title="开单医生"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderDepartmentName']}
          title="开单科室"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['performDepartmentName']}
          title="执行科室"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operateDate']}
          title="录入时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['operatorName']}
          title="录入人"
          valueType="text"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Modal>
  );
}

export default OrderBilleDetails;
