import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  InputProps,
  // Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import React, { useImperativeHandle, useRef, useState } from 'react';

import { askSearch } from '@/components/AspirinSelect/utils';
import { user } from '@/stores';

import { SelectCode } from '..';
import { useInputEnter } from '../dosage/utils';

/**
 * 住院医嘱途径选择
 */
let AdministrationSelect: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const { orderClass } = record || {};
  const dataSource = orderClass
    ? orderClass === 'DRUG'
      ? vsf.stores.dbenums.enums.ADMINISTRATION_DICT
      : orderClass === 'HERB'
      ? vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT
      : []
    : [];
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Select
      open={open}
      {...props}
      value={value}
      showSearch
      ref={ref}
      onChange={(value) => {
        onChange(value);
        setOpen(false);
      }}
      {...fieldEnterProps}
      {...rest}
      options={dataSource}
      filterOption={askSearch}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
    />
    // <Select
    //   {...props}
    //   value={value}
    //   showSearch
    //   ref={ref}
    //   onChange={onChange}
    //   {...fieldEnterProps}
    //   {...rest}
    //   dataSource={dataSource}
    //   // filterOption={askSearch}
    // />
  );
};

AdministrationSelect.displayName = 'administrationSelect';

AdministrationSelect = React.forwardRef(AdministrationSelect);

const withPreviewComponent = definePage((props: any) => {
  const { orderClass } = props?.record || {};
  const target = orderClass
    ? orderClass === 'DRUG'
      ? vsf.stores.dbenums.enums.ADMINISTRATION_DICT
      : vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT
    : [];
  return (
    <>
      {target?.find(
        (item: any) => item?.value == (props?.value?.value ?? props?.value),
      )?.label ??
        props?.value?.value ??
        props?.value}
    </>
  );
});

export default compose(
  withRef(),
  withField<any>({
    name: 'administrationSelect',
    handleEnter: true,
    // shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: withPreviewComponent,
    },
  ),
)(AdministrationSelect) as typeof AdministrationSelect;
