// table
export const convertGetChargeSolutionListRes2ChargeSolutionListRo = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.chargeSolutionCode = _.get(d, 'chargeSolutionCode');
      obj.chargeSolutionName = _.get(d, 'chargeSolutionName');
      obj.startDate = _.get(d, 'startDate');
      obj.stopDate = _.get(d, 'stopDate');
      obj.createStaffName = _.get(d, 'creatorStaff.staffName');
      obj.createdAt = _.get(d, 'createdAt');
      obj.lastModifyStaffName = _.get(d, 'lastModifyStaff.staffName');
      obj.updatedAt = _.get(d, 'updatedAt');
      obj.solutionMemo = _.get(d, 'solutionMemo');
      return obj;
    }) ?? []),
  );
  return output;
};
// 编辑
export const convertGetChargeSolutionDetailByIdRes2EditChargeSolutionControlledForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.id');
    output.chargeSolutionCode = _.get(input, 'data.chargeSolutionCode');
    output.chargeSolutionName = _.get(input, 'data.chargeSolutionName');
    output.startDate = _.get(input, 'data.startDate');
    output.stopDate = _.get(input, 'data.stopDate');
    output.stackableIndicator = _.get(input, 'data.stackableIndicator');
    output.reduceSolutionIndicator = _.get(
      input,
      'data.reduceSolutionIndicator',
    );
    output.priority = _.get(input, 'data.priority');
    output.solutionMemo = _.get(input, 'data.solutionMemo');
    output.judgeWay = _.get(input, 'data.judgeWay');
    output.judgeConditionList = _.get(input, 'data.judgeConditionList');
    // output.chargeSolutionJudgeConditionBtoList = _.get(
    //   input,
    //   'data.judgeConditionList',
    // );
    output.applicableScope = _.get(input, 'data.applicableScope');
    output.reduceSequence = _.get(input, 'data.reduceSequence');

    return output;
  };
// 编辑/新增保存
export const convertCreateChargeSolutionForm2CreateChargeSolutionReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoParam = {}; // TODO 没有匹配到合适字段 output.btoParam.id = null; // Long 主键
  output.btoParam.id = _.get(input, 'id');
  output.btoParam.chargeSolutionCode = _.get(input, 'chargeSolutionCode');
  output.btoParam.solutionMemo = _.get(input, 'solutionMemo');
  output.btoParam.chargeSolutionName = _.get(input, 'chargeSolutionName');
  output.btoParam.startDate = _.get(input, 'startDate');
  output.btoParam.stopDate = _.get(input, 'stopDate'); // TODO 没有匹配到合适字段 output.btoParam.creatorStaffId = null; // Long 创建人id
  output.btoParam.applicableScope = _.get(input, 'applicableScope');
  output.btoParam.reduceSequence = _.get(input, 'reduceSequence');
  output.btoParam.judgeWay = _.get(input, 'judgeWay'); // TODO 没有匹配到合适字段 output.btoParam.lastModifyStaffId = null; // Long 最后修改人id
  output.btoParam.priority = _.get(input, 'priority'); // TODO 没有匹配到合适字段 output.btoParam.lastModifyStaffId = null; // Long 最后修改人id
  output.btoParam.reduceSolutionIndicator = _.get(
    input,
    'reduceSolutionIndicator',
  ); // TODO 没有匹配到合适字段 output.btoParam.lastModifyStaffId = null; // Long 最后修改人id
  output.btoParam.stackableIndicator = _.get(input, 'stackableIndicator'); // TODO 没有匹配到合适字段 output.btoParam.lastModifyStaffId = null; // Long 最后修改人id
  // output.btoParam.chargeSolutionJudgeConditionBtoList = _.get(
  //   input,
  //   'chargeSolutionJudgeConditionBtoList',
  // );
  output.btoParam.chargeSolutionJudgeConditionBtoList = _.get(
    input,
    'chargeSolutionJudgeConditionBtoList',
  );
  output.btoParam.judgeConditionList = _.get(input, 'judgeConditionList');
  return output;
};
