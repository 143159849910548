import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Image } from '@vs/vsf-kit';
import classnames from 'classnames';
import { async } from 'q';
import React, { useCallback, useEffect, useState } from 'react';

import Empty from '@/module/Empty';
import Loading from '@/module/Loading';

import Detail from './detail/Detail';
import List from './list';
import Search from './search';

const Index = (props) => {
  const { user } = props?.stores || {};

  const { currentPatient, outVisitInfo } = user || {};
  // 列表item
  const [currentRecordItem, setCurrentRecordItem] = useState<any>();
  // 列表item id
  const [currentRecord, setCurrentRecord] = useState();
  // 就诊历史列表
  const [historyList, setHistoryList] = useState<any[]>([]);
  // 就诊历史详情
  const [medicalHistoryDetail, setMedicalHistoryDetail] = useState<any>();
  // 查询条件
  const [searchList, setSearchList] = useState<any>();
  // 就诊历史详情类型
  const [medicalHistoryDetailType, setMedicalHistoryDetailType] = useState();

  const [loading, setLoading] = useState<boolean>(false);

  const _patientId = currentPatient?.patientId;
  // 手术
  const convertGetPatientVisitHistoryDetailRes2OrderOperation = (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(
        input,
        'data.patientVisitHistoryDetailOverview.orderOperationVoList',
      )?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.startDateTime = _.get(d, 'startDateTime');
        obj.orderText = _.get(d, 'order.orderText');
        obj.operationLevel = _.get(
          d,
          `orderOperationDetailDtoList.[${0}].operationLevel`,
        );
        obj.staffName = _.get(d, 'operatorDoctor.staffName');
        return obj;
      }) ?? []),
    );
    return output;
  };
  // 处置
  const convertGetPatientVisitHistoryDetailRes2Overview = (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(
        input,
        'data.patientVisitHistoryDetailOverview.orderVoList',
      )?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.orderText = _.get(d, 'orderText');
        obj.administration = _.get(d, 'orderDisposalDto.administration');
        obj.frequency = _.get(d, 'orderDisposalDto.frequency.frequency');
        obj.performDays = _.get(d, 'orderDisposalDto.performDays');
        return obj;
      }) ?? []),
    );
    return output;
  };
  // 计价信息
  const convertGetPatientVisitHistoryDetailRes2VisitHistoryInpOrderRo = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data.patientVisitHistoryDetailOrderList')?.map(
        (d, i) => {
          const obj: any = {};
          obj.createDateTime = _.get(d, 'orderVo.createDateTime');
          obj.stopOrderDateTime = _.get(d, 'orderVo.stopOrderDateTime');
          obj.repeatIndicator = _.get(d, 'orderVo.repeatIndicator');
          obj.orderClass = _.get(d, 'orderVo.orderClass');
          obj.orderText = _.get(d, 'orderVo.orderText');
          obj.dosage = _.get(d, 'dosage');
          obj.dosageUnit = _.get(d, 'dosageUnit');
          obj.frequency = _.get(d, 'frequency');
          obj.performDays = _.get(d, 'performDays');
          obj.administration = _.get(d, 'administration');
          return obj;
        },
      ) ?? []),
    );
    return output;
  };
  // 详情
  const getMedicalHistoryDetail = useCallback(
    async (id?) => {
      if (
        currentRecord &&
        currentRecordItem &&
        _patientId &&
        medicalHistoryDetailType
      ) {
        if (
          medicalHistoryDetailType == 'EXAM_REPORT' ||
          medicalHistoryDetailType == 'LAB_REPORT'
        )
          return;
        const res =
          await vsf?.services?.VisitHistoryController_getPatientVisitHistoryDetail_d961fc?.(
            {
              eo: {
                id: currentRecord,
                patientId: _patientId,
                detailType: medicalHistoryDetailType ?? 'OVERVIEW',
                visitRecordHistoryType:
                  currentRecordItem?.visitRecordHistoryType,
              },
            },
          );
        const operationList =
          convertGetPatientVisitHistoryDetailRes2OrderOperation(res);
        const overviewList =
          convertGetPatientVisitHistoryDetailRes2Overview(res);
        const priceInfoList = res?.data?.priceInfoList?.map((item) => ({
          ...item.outpBillingDetailVo,
          ...item.orderVo,
        }));

        if (res?.code === 200) {
          setMedicalHistoryDetail({
            ...res?.data,
            operationList: operationList,
            overviewList: overviewList,
            priceInfo: priceInfoList,
          });
        }
      } else {
        setMedicalHistoryDetail(null);
      }
    },
    [medicalHistoryDetailType, _patientId, currentRecordItem, currentRecord],
  );
  // 列表
  const medicalHistoryList = useCallback(async () => {
    setLoading(true);
    const res =
      await vsf?.services?.VisitHistoryController_getPatientVisitHistory_6e55dc?.(
        {
          qto: {
            patientIdIs: _patientId,
            // 需要修改
            ...(searchList?.radioValue
              ? {}
              : searchList?.reportTimeRage?.start ||
                searchList?.reportTimeRage?.end
              ? {
                  visitDateRangeIn: searchList?.radioValue
                    ? {}
                    : {
                        begin: searchList?.reportTimeRage?.start,
                        end: searchList?.reportTimeRage?.end,
                        ...(searchList?.reportTimeRage?.start
                          ? {
                              beginInclude: true,
                            }
                          : {}),
                        ...(searchList?.reportTimeRage?.end
                          ? {
                              endInclude: true,
                            }
                          : {}),
                      },
                }
              : {}),
            historyTypeList:
              searchList?.checkBlockValue?.keys.length > 0
                ? searchList?.checkBlockValue?.keys
                : ['OUTP'],
            // 当前科室id,写死
            departmentId: searchList?.checkBlockValue?.departmentNameIs,
            // 查询条件  需要修改
            diagnosisIdList:
              searchList?.selectValue?.[0] === 'all'
                ? undefined
                : searchList?.selectValue,
            count:
              searchList?.radioValue === 'all'
                ? undefined
                : searchList?.radioValue,
          },
        },
      );
    setLoading(false);
    if (res?.code === 200) {
      setHistoryList(res?.data);
      if (res?.data?.length > 0) {
        setCurrentRecord(res?.data?.[0]?.id);
        setCurrentRecordItem(res?.data?.[0]);
      }
    } else {
      setMedicalHistoryDetail(undefined);
    }
  }, [searchList, _patientId]);

  useEffect(() => {
    medicalHistoryList();
  }, [medicalHistoryList]);

  useEffect(() => {
    console.log('哈哈哈哈哈');

    getMedicalHistoryDetail();
  }, [getMedicalHistoryDetail, currentRecord, searchList]);

  const getistoryDetailType = (v) => {
    setMedicalHistoryDetailType(v);
  };

  const handelSearch = (v) => {
    setSearchList(v);
  };
  return (
    <div className="medical-history">
      <Search handelSearch={handelSearch} />
      <div className="main">
        {loading ? (
          <Loading size="normal" />
        ) : (
          !historyList ||
          (historyList?.length === 0 ? (
            <Empty
              wordStyle={{
                top: '260px',
              }}
            />
          ) : (
            <>
              <List
                value={currentRecord}
                dataSource={historyList}
                onChange={(item) => {
                  console.log(item, 'ppppppppppppppppppppo');

                  setCurrentRecordItem(item);
                  setCurrentRecord(item?.id);
                  // getMedicalHistoryDetail(item?.id);
                }}
              />
              {historyList && historyList?.length > 0 && (
                <Detail
                  medicalHistoryDetailType={medicalHistoryDetailType}
                  currentRecordItem={currentRecordItem}
                  getistoryDetailType={getistoryDetailType}
                  detail={medicalHistoryDetail}
                />
              )}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
