import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTableToolbar,
  VSTableAddition,
  withRef,
  VSTableAction,
  Checkbox,
} from '@vs/vsf-kit';
import Decimal from 'decimal.js';

import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';

const DrugPriceItemManagerTable = React.forwardRef((props, ref) => {
  const { activeProduct } = props;

  const [priceList, setPriceList] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      activeProduct?.id &&
      !(
        typeof activeProduct?.id === 'string' &&
        activeProduct?.id?.includes('create_')
      )
    ) {
      onFetch?.();
    }
  }, [activeProduct]);

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return Promise.resolve(priceList);
      },
      reset: () => {
        setPriceList({});
      },
    };
  });

  const onFetch = useCallback(async () => {
    if (activeProduct?.id) {
      setLoading(true);
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugController_getPriceItemListByDrugProductId_a88fc3?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugProductId: activeProduct?.id,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }

      const _data = res?.data?.result ?? res?.data ?? [];
      setPriceList({
        ...priceList,
        [activeProduct?.id]: _data,
      });
      setLoading(false);
    }
  }, [activeProduct]);

  const handelAdd = (params) => {
    setPriceList({
      ...priceList,
      [activeProduct?.id]: [params, ...(priceList[activeProduct?.id] ?? [])],
    });
    console.log(params, 'drug_params');
    return params;
  };

  const handelStopDateChange = (isCheck, record, n) => {
    // setPriceList({
    //   ...priceList,
    //   [record?.drugProductId]: priceList?.[record?.drugProductId]?.map(
    //     (item) => ({
    //       ...item,
    //       stopDate: isCheck ? dayjs()?.format('YYYY-MM-DD HH:mm:ss') : null,
    //     }),
    //   ),
    // });
    
    setPriceList({
      ...priceList,
      [record?.drugProductId]: priceList?.[record?.drugProductId]?.map(
        (item,i) => ({
          ...item,
          stopDate: isCheck ?i===n? dayjs()?.format('YYYY-MM-DD HH:mm:ss'):null : null,
        }),
      ),
    });

  };

  const getDosageUnit = (code) => {
    return vsf.stores.dbenums.enums.DOSAGE_UNITS_DICT?.find(
      (item) => item?.code === code,
    );
  };

  const [priceFlag, setPriceFlag] = useState(true);

  // 是否选择规格信息
  useEffect(() => {
    if (activeProduct?.id) {
      setPriceFlag(false);
    } else {
      setPriceFlag(true);
    }
  }, [activeProduct]);

  // 限制商品价格信息条数（2）
  // useEffect(() => {
  //   const str = priceList?.[activeProduct?.id];
  //   if (str === undefined) {
  //     setPriceFlag(true);
  //   } else {
  //     switch (str.length) {
  //       case 0:
  //         setPriceFlag(false);
  //         break;
  //       case 1:
  //         setPriceFlag(false);
  //         break;
  //       default:
  //         setPriceFlag(true);
  //         break;
  //     }
  //   }
  // }, [priceList]);

  return (
    <VSPromiseTable
      className="aspirin-table aspirin-table-nopadding aspirin-table-autoheight"
      editable={{
        editType: 'single',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {
          const specificationRecord = priceList?.[activeProduct?.id]?.find(
            (item) => item?.specificationType,
          );

          if (specificationRecord?.id) {
            if (record?.amountPerPackage) {
              form.setFieldValue(
                [key, 'retailPrice'],
                Number(
                  specificationRecord?.retailPrice / record?.amountPerPackage,
                )?.toFixed(2),
              );
            } else {
              form.setFieldValue([key, 'retailPrice'], '');
            }
          }

          var flag = false;

          // priceList?.[activeProduct?.id].forEach((item, index) => {
          //   // console.log(item, 'priceList_item');
          //   if (item.specificationType) {
          //     flag = true;
          //   }
          // });

          // // 更改零售价格

          if (priceList?.[activeProduct?.id]?.length >= 1) {
            var str = priceList?.[activeProduct?.id][0];
            const number1 =
              Number(str?.retailPrice) / Number(str?.amountPerPackage);
            const number2 = number1 * record?.amountPerPackage;

            console.log(number2, 'drug_零售价格');

            form.setFieldValue([key, 'retailPrice'], number2);
          }

          // 匹配包装规格
          if ('amountPerPackage' in value && !isNaN(value?.amountPerPackage)) {
            const number1 = new Decimal(
              activeProduct?.drugSpecificationDictionary?.dosagePerUnit ?? 0,
            );
            const number2 = new Decimal(value?.amountPerPackage ?? 0);
            const res = number1.times(number2);

            const unitLabel =
              getDosageUnit(
                activeProduct?.drugSpecificationDictionary?.dosageUnit,
              )?.label ?? '';

            // console.log(
            //   `${number1.times(1)}${unitLabel}*${number2}`,
            //   'table_number1',
            // );

            form.setFieldValue(
              [key, 'packageSpecification'],
              number2 > 1
                ? `${number1.times(1)}${unitLabel}*${number2}`
                : `${res}${unitLabel}`,
            );
          }
        },
        
      }}
      scroll={{ x: 1700 }}
      extraParams={{ activeProduct }}
      vsid="06203"
      onFetch={async () => {
        return priceList?.[activeProduct?.id] ?? [];
      }}
      onAdd={handelAdd}
      onRecord={() => {
        console.log('drug_record')
        return {
          id: `create_${Math.random()}`,
          startDate:Date.now(),
        };
      }}
    >
      <VSTableToolbar title="商品价格信息" style={{ padding: '0 24px' }}>
        <VSTableAddition
          children="新增"
          loading={loading}
          position="top"
          disabled={priceFlag}
        />
      </VSTableToolbar>

      <VSTableColumn
        title="*包装单位"
        width={108}
        dataIndex={['packageUnit']}
        valueType="codeTableSelect"
        dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
        formItemProps={{
          rules: [{ required: true, message: '请选择包装单位' }],
        }}
        fieldProps={{
          showSearch: true,
        }}
      />
      <VSTableColumn
        title="*包装数量"
        width={108}
        dataIndex={['amountPerPackage']}
        valueType="digit"
        fieldProps={{}}
        formItemProps={{
          rules: [{ required: true, message: '请输入包装数量' }],
        }}
      />
      <VSTableColumn
        title="*包装规格"
        width={272}
        dataIndex={['packageSpecification']}
        valueType="text"
        formItemProps={{
          rules: [{ required: true, message: '请输入包装规格' }],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        title="*零售价格"
        width={108}
        dataIndex={['retailPrice']}
        valueType="digit"
        fieldProps={{}}
        formItemProps={{
          rules: [{ required: true, message: '请输入零售价格' }],
        }}
      />
      <VSTableColumn
        title="启用时间"
        width={272}
        initialValue={Date.now()}
        dataIndex={['createDate']}
        valueType="aspirinDateTimePicker"
        fieldProps={{
          type: 'single',
          format: 'YYYY-MM-DD HH:mm:ss',
          valueType: 'date',
        }}
        formItemProps={{
          rules: [{ required: true, message: '请选择启用时间' }],
        }}
      />
      <VSTableColumn
        title="条形码"
        width={272}
        dataIndex={['tradeCode']}
        valueType="text"
        formItemProps={{
          rules: [{ message: '数据格式错误', type: 'string', min: 0, max: 20 }],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        title="停价"
        width={108}
        dataIndex={['stopDate']}
        valueType="custom"
        render={(_, record, n) => {
          return (
            <Checkbox
              value={!!record?.stopDate}
              onChange={(e) => handelStopDateChange(e, record, n)}
            />
          );
        }}
      />
      <VSTableColumn
        title="标准规格"
        width={108}
        dataIndex={['specificationType']}
        valueType="check"
        fieldProps={{
          disabled: priceList?.[activeProduct?.id]?.some(
            (item) => item?.specificationType,
          ),
        }}
      />

      <VSTableColumn
        title="医保属性维护"
        width={212}
        valueType="option"
        name="action"
        fixed="right"
      >
        <VSTableAction>维护</VSTableAction>
      </VSTableColumn>
    </VSPromiseTable>
  );
});
DrugPriceItemManagerTable.displayName = 'DrugPriceItemManagerTable';
export default withRef()(DrugPriceItemManagerTable);
