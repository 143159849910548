import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import { useAvoidState } from '@/pages/Index/components/func/hooks';

const OrderSheetOverviewOrderDetail = (props) => {
  const { orderId, ...rest } = props;
  const [name, setName] = useAvoidState('');
  const onFetch = useCallback(async () => {
    const res =
      await vsf.services?.ClinicRecordOrderPerformController_getPagedByQueryOrderPerformPlanRecordQto_79ca66?.(
        {
          qto: {
            from: 0,
            size: 20,
            orderIdIs: orderId,
          },
        },
      );
    const state = {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    setName(state?.data?.[0]?.order?.orderText);
    return state;
  }, [orderId, setName]);

  return (
    <Modal
      title={'医嘱: ' + name}
      open={props?.open}
      width="70%"
      bodyStyle={{
        minHeight: '50%',
      }}
      {...rest}
      footer={null}
    >
      <VSPromiseTable id="orderSheetOverviewOrderPerformPlan" onFetch={onFetch}>
        <VSTableColumn
          dataIndex={['performType']}
          title="执行方式标识"
          valueType="select"
          dataSource={[
            { label: '手工录入', value: 'MANUAL' },
            { label: '扫码', value: 'BARCODE' },
            { label: 'PDA', value: 'PDA' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['performDateTime']}
          title="执行时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['performNurseName']}
          title="执行护士姓名"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['checkDateTime']}
          title="双签核对时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['checkNurseName']}
          title="双签核对护士"
          valueType="text"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Modal>
  );
};
export default definePage(OrderSheetOverviewOrderDetail);
