import storage from '@/utils/storage';

/**List
 * convertGetAllByQueryPrescriptionRefundApplyToPerformQtoRes2TableHerbDispenseRo
 * @param {any} input
 * @returns {any}
 */
const convertGetAllByQueryPrescriptionRefundApplyToPerformQtoRes2TableHerbDispenseRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ..._.get(input, 'data').map((d, i) => {
        const obj = {};
        obj.returnApplyDate = _.get(d, 'returnApplyDate');
        obj.name = _.get(d, 'patient.name');
        obj.staffName = _.get(d, 'applyStaff.staffName');
        obj.refundDrugApplyNumber = _.get(d, 'refundDrugApplyNumber');
        obj.refundMasterId = _.get(d, 'refundMasterId');
        obj.outpVisitId = _.get(
          d,
          'drugPrescriptionDispenseMaster.outpVisitId',
        );
        return obj;
      }),
    );
    return output;
  };

/**
 * convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo
 * @param {any} input
 * @returns {any}
 */
const convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.windowDescription = _.get(input, 'windowDescription');
  output.toDispenseWindowId = _.get(input, 'id');
  return output;
};
/**
 * convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription
 * @param {any} input
 * @returns {any}
 */
const convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription =
  (input) => {
    const _ = window._ ?? {};
    const output = {
      secondPrepareDrugStaffId: input?.staff?.id,
      staffName: input?.staff?.staffName,
    };
    return output;
  };
const convertGetOutpVisitPatientVitalsignAllergyVoByIdRes2FormPatientInfo1 = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.name = _.get(input, 'data.name');
  output.gender = _.get(input, 'data.gender');
  output.age = _.get(input, 'data.age'); // TODO 请确认下行字段是否符合逻辑
  output.displayId = _.get(input, 'data.patient.displayId'); // TODO 请确认下行字段是否符合逻辑
  output.workUnit = _.get(
    input,
    'data.patient.patientProfileDto.workUnit.name',
  );
  output.height = _.get(input, 'data.height');
  output.weight = _.get(input, 'data.weight');
  output.pressure = _.get(input, 'data.pressure');
  output.allergyName = _.get(input, 'data.allergyName');
  output.chargeTypeName = _.get(input, 'data.chargeTypeName');
  output.identityName = _.get(input, 'data.identityName'); // TODO 请确认下行字段是否符合逻辑
  output.phoneNumber = _.get(input, 'data.patient.phoneNumber');
  return output;
};

//List列表
export const list = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionRefundDispenseController_getAllByQueryPrescriptionRefundApplyToPerformQto_0f2bfa?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          orderClassIs: 'HERB',
          orderList: params.orderList,
          ...params.search,
          ...params.filters,
          ...(params?.extra ?? {}),
          ...(params ?? {}),
        },
      },
    );
  const output =
    convertGetAllByQueryPrescriptionRefundApplyToPerformQtoRes2TableHerbDispenseRo(
      res,
    );
  // return {
  //   data: output,
  //   total: res?.data?.count ?? res?.data?.length ?? 0,
  // };
  return output;
};

export const getDetailById = async (params) => {
  if (params) {
    const res =
      await vsf.services?.ClinicOutpVisitForDispenseController_getOutpVisitPatientVitalsignAllergyVoById_5bfddd?.(
        {
          id: params.outpVisitId,
        },
      );
    return convertGetOutpVisitPatientVitalsignAllergyVoByIdRes2FormPatientInfo1(
      res,
    );
  } else {
    return [];
  }
};

export const toDispenseWindowList = async (params) => {
  //
  // Tips: 以下是你选择的请求函数
  const currentApplication = storage.get('currentApplication');
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getCurrentStorageDispenseWindowSettings_cb90a1?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
      },
    ); // TODO: 你可能需要整理返回数据格式
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output = (res?.data ?? []).map((m) =>
    convertGetCurrentStorageDispenseWindowSettingsRes2FormWindowNo(m),
  );
  return output;
};

export const windowDetail = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionDispenseController_getLocalDispenseWindowSetting_302b1f?.(
      {},
    );
  return res?.data;
};

export const prepareDrugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription(
      m,
    ),
  );
};

export const secondPrepareDrugStaffList = async (params) => {
  const res =
    await vsf.services?.ApplicationPrivilegeController_getCurrentApplicationVsStaffWithStaffVo_2b56ce?.(
      {},
    );
  return (res?.data ?? []).map((m) =>
    convertGetCurrentApplicationVsStaffWithStaffVoRes2FormDispensingPrescription(
      m,
    ),
  );
};
