import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getExportMasterWithDetailById_c96935: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-export-master-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugStorageController_queryStockForExportByName_513ee9: {
    method: 'post',
    url: '/api/drug-storage/query-stock-for-export-by-name',
    parameterFomatter: (data?: { inputCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductStockForExportSearchVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
