import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCommonController_importExcel_c025d5: {
    method: 'post',
    url: '/api/base-common/import-diagnosis-dictionarys',
    parameterFomatter: (data?: {
      items: CreateDiagnosisDictionaryBtoBaseCommonServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryErrorMessageVoBaseCommonEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
