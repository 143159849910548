import { createServices } from '@vs/vsf-boot';
export default createServices({
  NurseBillingController_getInpOrderCost_c86d04: {
    method: 'post',
    url: '/api/clinic-record-order/get-inp-order-cost',
    parameterFomatter: (data?: {
      inpVisitId: number;
      orderIdList: number[];
      includeTodayCancel: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: NurseInpOrderCostInfoVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  InpNurseCostController_addNurseCost_cace91: {
    method: 'post',
    url: '/api/inp-billing/add-nurse-cost',
    parameterFomatter: (data?: {
      addInpNurseCostBvo: AddInpNurseCostBvoInpBillingEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  InpNurseCostController_cancelInpNurseCost_6c5288: {
    method: 'post',
    url: '/api/inp-billing/cancel-inp-nurse-cost',
    parameterFomatter: (data?: { inpNurseCostId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpNurseCostController_nurseCostStopBilling_9888f2: {
    method: 'post',
    url: '/api/inp-billing/nurse-cost-stop-billing',
    parameterFomatter: (data?: {
      nurseCostIdList: number[];
      billingStopDate: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListController_getPagedByPriceItemCurrentQto_22ee99: {
    method: 'post',
    url: '/api/price-list/get-paged-by-price-item-current-qto',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
      isIncludeDrugOrHerb: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemBaseVoPriceListEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
