import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderTemplateExamController_getOrderTemplateExamWithDrugMaster_bd7486: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-exam-with-drug-master',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateMasterExamWithDrugVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  OrderTemplateExamController_saveOrderTemplateExam_893770: {
    method: 'post',
    url: '/api/clinic-record-order-template/save-order-template-exam',
    parameterFomatter: (data?: {
      btoList: CreateOrderTemplateMasterBtoClinicRecordOrderTemplateServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OrderTemplateExamController_removeOrderTemplateExam_19f38a: {
    method: 'post',
    url: '/api/clinic-record-order-template/remove-order-template-exam',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
