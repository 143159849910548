import Container from '@/module/Container';
import React, { useState } from 'react';
import RelationshipTree from './RelationshipTree';
import RelationshipTable from './RelationshipTable';
function index() {
  const [pharmacyData, setPharmacyData] = useState();
  return (
    <>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              block: '24%',
            },
            {
              block: 1,
              bgColor: '#fff',
            },
          ],
        }}
      >
        <RelationshipTree
          onChange={(item) => {
            setPharmacyData(item);
          }}
        />
        <RelationshipTable pharmacyDataSource={pharmacyData} />
      </Container>
    </>
  );
}

export default index;
