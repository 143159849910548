/**
 * 防止浮点数精准丢失
 * @param num 数字
 * @param precision 保留
 */
export default function askFixed(num: number | string, precision = 2) {
  const int: any = +num + 'e' + precision;
  const flat = Math.round(int) / Math.pow(10, precision);
  const [one, split] = String(flat)?.split('.');

  if (split?.length < 2 || split === undefined) {
    return split?.length === 1
      ? Number?.isNaN(one)
        ? 0
        : one +
          '.' +
          split +
          Array?.from({ length: precision - 1 })
            ?.map(() => '0')
            ?.join('')
      : Number?.isNaN(flat)
      ? 0
      : flat +
        '.' +
        Array?.from({ length: precision })
          ?.map(() => '0')
          ?.join('');
  }
  return flat;
}
