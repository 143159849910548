import {
  Button,
  Dropdown,
  message,
  Modal,
  Radio,
  RadioGroup,
  TabPane,
  Tabs,
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import {
  useFetch,
  useGetResetInitState,
  useOnChangeValue,
  useScroll,
} from '@/pages/Index/components/func/hooks';
import dayjs from 'dayjs';
import { debounce, divide } from 'lodash';

import TipModal from '@/pages/Index/components/func/TipModal';

import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  forwardRef,
} from 'react';
import { __rest } from 'tslib';

import { AspirinDateTimePicker, AspirinTime, FileUpload } from '@/components';
import { user } from '@/stores';
import Empty from '@/module/Empty';
import Icon from '@/module/cpoe/Icon';
import login from '@/module/login';
import vsf, { definePage } from '@vs/vsf-boot';
import Collect from '@/pages/Index/components/collect';
import Tip from '@/pages/Index/components/func/Tip';
import storage from '@/utils/storage';
// import './index.less';

const Index = (props, ref) => {
  const {
    tab,
    status,
    type,
    stores,
    handelClick,
    handleSelect,
    inputValue,
    inputChange,
    tableRef,
    dropdownOpen,
    onDropdownOpen,
  } = props;

  const {
    cpoe: { signSelection },
    user: { staffInfo },
    common: { currentApplication },
  } = stores;
  // tab：mine collect department
  // status：NOT_TREAT HANG_UP HAS_TREAT

  const [row, setRow] = useState();
  const [scrollId] = useOnChangeValue(null);
  const [scroll] = useOnChangeValue(null);
  const [collectClassifyList, setCollectClassifyList] = useState([]);
  // 取消收藏弹窗
  const [openCancelCollectModal, setCancelCollextModal] = useState(false);
  // 收藏弹窗
  const [openCollectModal, setCollextModal] = useState(false);
  // 取消收藏/收藏弹窗参数
  const [collectParams, setCollectParams] = useState();
  const tempData = useRef([]);
  const { id } = useScroll(() => {
    scroll?.onChange?.(scrollId?.value);
  });
  useEffect(() => {
    tableRef.current = { clearScrollId: clearScrollId };
  }, []);
  useEffect(() => {
    clearScrollId();
  }, [tab, status, dropdownOpen]);

  const clearScrollId = () => {
    scrollId?.onChange?.(null);
    scroll?.onChange?.(null);
    tempData.current = [];
  };
  // 未诊患者列表

  const getNotTreatList = useCallback(
    async (params) => {
      // console.log(dropdownOpen, status, tab, scroll?.value, '接诊');
      if (!dropdownOpen) return;
      if (status !== 'NOT_TREAT' || tab == 'collect') return;
      const res =
        await vsf.services?.OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 50,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              scrollId: scroll?.value,
              clinicRegisterDetailIdIn: signSelection.map(
                (item) => item?.clinicRegisterDetailId,
              ),
              clinicDepartmentIdIs: tab === 'department' ? -1 : '',
              nameLike: inputValue?.length > 0 ? inputValue : undefined,
            },
          },
        );
      if (inputValue?.length >= 0 && res?.data?.result?.length <= 0) {
        message.info('未找到挂号患者');
        return {
          data: [],
          count: 0,
        };
      }
      if (res?.code == 200) {
        // setTempData([...tempData, ...res?.data?.result]);
        tempData.current = [...tempData?.current, ...(res?.data?.result ?? [])];
        // if (res?.data?.hasMore) {
        scrollId?.onChange?.(res?.data?.scrollId);
        // }
        if (res?.data === null) {
          // 本科室挂号
          Modal.confirm({
            title: '提示',
            icon: null,

            centered: true,
            content: '需要调挂本科室号接口，暂时还没有',
            onOk: async () => {},
            onCancel() {},
            width: 520,
          });
        }
        if (res?.data?.result?.length === 1 && inputValue?.length >= 0) {
          const record = res?.data?.result?.[0];
          // setRow(record);
          // handleSelect(record, 'NOT_TREAT');
          if (
            currentApplication?.department?.id ==
            record?.clinicRegisterDefine?.clinicDepartment?.id
          ) {
            // handelSetEnter(false);
            // const res =
            //   await vsf.services?.OutpVisitEncounterController_createOutpVisitEncounter_a27cb7?.(
            //     {
            //       // TODO: 请检查以下这部分传参是否符合需求
            //       outpVisitId: record?.id,
            //     },
            //   ); // TODO: 你可能需要整理返回数据格式
            // if (res?.code === 200) {
            //   // vsf.refs.collectTable?.reload();
            //   // vsf.refs.otherTable?.reload();
            //   setRow(res?.data);
            //   handleSelect(res?.data, 'NOT_TREAT');
            //   // onDropdownOpen();
            //   vsf.refs.notTreatTable?.reload();
            // }
            setRow(record);
            handleSelect(record, 'NOT_TREAT');
          } else {
            Modal.confirm({
              title: '提示',
              // TipIcon: null,
              icon: null,
              // closeIcon: <Icon type="icon-shanchu1" />,
              centered: true,
              content: (
                <div style={{ display: 'flex' }}>
                  <div style={{ margin: '2px 5px 0 0' }}>
                    <Icon type="icon-jingshi" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      fontSize: '16px',
                    }}
                  >
                    <div>
                      该患者挂号科室为：
                      <span style={{ color: '#3276E8' }}>
                        【
                        {
                          record?.clinicRegisterDefine?.clinicDepartment
                            ?.departmentName
                        }
                        】 科室，
                      </span>
                      {/* 进行退诊吗？ */}
                    </div>
                    <div style={{ margin: '5px 0' }}>
                      挂号为：
                      <span style={{ color: '#3276E8' }}>
                        {record?.clinicRegisterDefine?.doctor?.staffName
                          ? '【' +
                            record?.clinicRegisterDefine?.doctor?.staffName +
                            '】'
                          : ''}
                      </span>
                      {record?.clinicRegisterDefine?.clinicLabel}，
                    </div>
                    <div>如需就诊请提醒该患者。</div>
                  </div>
                </div>
              ),
              onOk: async () => {
                // handelSetEnter(false);
                // const res =
                //   await vsf.services?.OutpVisitEncounterController_createOutpVisitEncounter_a27cb7?.(
                //     {
                //       // TODO: 请检查以下这部分传参是否符合需求
                //       outpVisitId: record?.id,
                //     },
                //   ); // TODO: 你可能需要整理返回数据格式
                // if (res?.code === 200) {
                //   // vsf.refs.collectTable?.reload();
                //   // vsf.refs.otherTable?.reload();
                //   setRow(res?.data);
                //   handleSelect(res?.data, 'NOT_TREAT');
                //   onDropdownOpen();
                //   vsf.refs.notTreatTable?.reload();
                // }
                setRow(res?.data);
                handleSelect(record, 'NOT_TREAT');
                onDropdownOpen();
                vsf.refs.notTreatTable?.reload();
              },
              onCancel() {
                // handelSetEnter(false);
              },
              okText: '确定',
              cancelText: '取消',
              width: 520,
            });
          }
        }
      }

      return {
        // data:
        //   [...tempData.current]?.reduce((prev, cur) => {
        //     if (!prev.some((item) => item.id === cur.id)) {
        //       prev.push(cur);
        //     }
        //     return prev;
        //   }, []) ?? [],
        data: res?.data?.result,
        count: res?.data?.count ?? [],
      };
    },
    // dropdownOpen
    [status, tab, scroll?.value, dropdownOpen, inputValue],
  );
  // 暂挂患者列表
  const getNotHangUp = useCallback(
    async (params) => {
      if (status !== 'HANG_UP' || tab == 'collect') return;
      const res =
        await vsf.services?.OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a(
          {
            qto: {
              clinicEncounterStatusIn: ['START_VISIT', 'PENDING'],
              visitDepartmentIdIs: tab === 'department' ? -1 : '',
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 50,
              nameLike: inputValue?.length > 0 ? inputValue : undefined,
              scrollId: scroll?.value,
            },
          },
        );
      // if (res?.code == 200) {
      //   tempData.current = [...tempData?.current, ...(res?.data?.result ?? [])];

      //   scrollId?.onChange?.(res?.data?.scrollId);

      // }

      return {
        // data:
        //   [...tempData.current]?.reduce((prev, cur) => {
        //     if (!prev.some((item) => item.id === cur.id)) {
        //       prev.push(cur);
        //     }
        //     return prev;
        //   }, []) ?? [],
        data: res?.data?.result ?? [],
        count: res?.data?.count ?? [],
      };
    },
    [status, tab, scroll?.value, inputValue, dropdownOpen],
  );
  // 已诊患者列表
  const getHasTreat = useCallback(
    async (params) => {
      if (status !== 'HAS_TREAT' || tab == 'collect') return;
      const res =
        await vsf.services?.OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a(
          {
            qto: {
              clinicEncounterStatusIn: ['END_VISIT', 'REFUND'],
              visitDepartmentIdIs: tab === 'department' ? -1 : '',
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 50,
              nameLike: inputValue?.length > 0 ? inputValue : undefined,
              scrollId: scroll?.value,
            },
          },
        );
      if (res?.code == 200) {
        // setTempData([...tempData, ...res?.data?.result]);
        tempData.current = [...tempData?.current, ...(res?.data?.result ?? [])];
        // if (res?.data?.hasMore) {
        scrollId?.onChange?.(res?.data?.scrollId);
        // }
      }

      return {
        // data:
        //   [...tempData.current]?.reduce((prev, cur) => {
        //     if (!prev.some((item) => item.id === cur.id)) {
        //       prev.push(cur);
        //     }
        //     return prev;
        //   }, []) ?? [],
        data: res?.data?.result,
        count: res?.data?.count ?? [],
      };
    },
    [status, tab, dropdownOpen, inputValue],
  );
  // 我的收藏患者列表
  const getCollectList = useCallback(
    async (params) => {
      if (tab === 'collect') {
        const res =
          await vsf.services?.DoctorFavoriteMedicalRecordController_getDoctorFavoriteMedicalRecord_8e162b?.(
            {
              var: {
                doctorId: staffInfo?.doctorDto?.staffId,
                clinicVisitType: 'OUTP',
                nameLike: inputValue?.length > 0 ? inputValue : undefined,
              },
            },
          );

        return { data: res?.data ?? [], count: res?.data?.length ?? 0 };
      }
    },
    [tab, inputValue],
  );

  // 收藏相关
  // 收藏分类列表
  const getCollectClassifyList = async () => {
    const res =
      await vsf.services?.DoctorFavoriteDescriptionController_getDoctorFavoriteDescription_9014bf?.(); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.map((item) => {
      return {
        label: item?.medicalRecordDescription,
        value: item?.id,
        amount: item?.amount,
      };
    });
    // setCollectClassifyList(_res);
    return _res ?? [];
  };
  // 添加收藏
  const handelCollect = (row, collectDescription, isMySelect = 'other') => {
    setCollextModal(true);
    setCollectParams({
      row,
      collectDescription,
      isMySelect,
    });
  };
  // 取消收藏
  const handelCancelCollect = (row, isMySelect = 'other') => {
    setCancelCollextModal(true);
    setCollectParams({
      row,
      isMySelect,
    });
  };
  // 删除
  const deleteCollect = async (value) => {
    const content =
      value?.amount > 0
        ? `该分类有${value?.amount ?? ''}条病例，删除后，将其从收藏病例里移出?`
        : '确定删除该分类?';
    Tip({
      content: content,
      onOk: async () => {
        const res =
          await vsf.services?.DoctorFavoriteDescriptionController_deleteDoctorFavoriteDescription_14100e?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              btoParam: {
                medicalRecordDescription: value?.label,
              },
              extApiParam: {},
            },
          ); // TODO: 你可能需要整理返回数据格式
        // 打开列表
        // openDropdown();
        onDropdownOpen(true);
        if (res?.code === 200) {
          // getCollectClassifyList();
          // vsf.refs.collectTable?.reload();
          // vsf.refs.otherTable?.reload();
          // vsf.refs.notTreatTable?.reload();
          message.open({
            type: 'success',
            content: `删除成功!`,
          });
        }
      },
      onCancel: () => {
        // 打开列表
        openDropdown();
      },
    });
  };

  // 我的收藏table
  const renderCollectTable = () => {
    return (
      <VSPromiseTable
        id="collectTable"
        className="aspirin-table hideScroll"
        onFetch={getCollectList}
        // onFetch={handelSearchList}
        pagination={false}
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        scroll={{ y: 455 }}
        rowClassName={(v) =>
          v.id === row?.id ? 'aspirin-collect-table-bgc' : ''
        }
        editable={{
          columnProps: {
            width: 100,
          },
        }}
        rowClick={{
          rowClickType: {
            type: 'default',
            action: 'double-click',
          },
        }}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              onDropdownOpen();
              setRow(record);
              handleSelect(record, 'COLLECT');
            },
            onClick: () => {
              setRow(record);
              // handleSelect(record);
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={[
            'outpVisit',
            'clinicRegisterDetailTimeDivisionId',
            'timeDescription',
          ]}
          title="午别"
          valueType="select"
          dataSource={[
            { label: '上午', value: 'AM' },
            { label: '下午', value: 'PM' },
          ]}
          fieldProps={{}}
          hideInTable={true}
        />
        <VSTableColumn
          dataIndex={[
            'outpVisit',
            'clinicRegisterDetailTimeDivisionId',
            'serialNumber',
          ]}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={(_, value) => {
            const _value =
              value?.outpVisit?.clinicRegisterDetailTimeDivision
                ?.timeDescription === 'AM'
                ? '上午'
                : '下午';
            return (
              <div>
                {_value}
                {'\u00A0'} {'\u00A0'}
                {
                  value?.outpVisit?.clinicRegisterDetailTimeDivision
                    ?.serialNumber
                }
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'gender']}
          title="性别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'age']}
          title="年龄"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['patient', 'displayId']}
          title="患者ID"
          valueType="text"
          width={110}
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'clinicRegisterDefine', 'clinicLabel']}
          title="号别"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['visitDepartment', 'departmentName']}
          title="就诊科室"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {record?.visitDepartment?.departmentName}
              </div>
            );
          }}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'chargeTypeCode']}
          title="费别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'identityType']}
          title="身份"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['encounterDoctor', 'staffName']}
          title="医师"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {record?.encounterDoctor?.staffName}
              </div>
            );
          }}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['diagnosis']}
          title="诊断"
          valueType="text"
          hideInTable={type === 'simple'}
          render={(_, value) => {
            return value?.diagnosisRecordWithDiagnosisDtoList
              ?.map((item) => {
                return item?.diagnosis?.diagnosisName;
              })
              ?.join(',');
          }}
          width={130}
        />
        <VSTableColumn
          dataIndex={['visitStartDate']}
          title="就诊日期"
          valueType="date"
          hideInTable={type === 'simple'}
          render={(_, data) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {dayjs(data?.visitStartDate).format('MM-DD')}
              </div>
            );
          }}
        />
        <VSTableColumn
          title="收藏"
          // name="option"
          valueType="option"
          dataIndex={['doctorFavoriteDescription']}
          hideInTable={type === 'simple'}
          render={(_, v) => {
            return (
              <Collect
                value={v}
                favoriteDescription={v?.doctorFavoriteDescription}
                cancelCollect={(value) => {
                  handelCancelCollect(v);
                }}
                collect={(value) => {
                  handelCollect(v, value, 'other');
                }}
                deleteCollect={(value) => {
                  deleteCollect(value);
                }}
                getCollectClassifyList={getCollectClassifyList}
                childrens={
                  <div style={{ cursor: 'pointer' }}>
                    {v?.doctorFavoriteDescription ? (
                      <Icon
                        size={24}
                        style={{ color: '#ffa77e' }}
                        type="icon-shoucang1"
                      />
                    ) : (
                      <Icon size={24} type="icon-meiyoushoucang" />
                    )}
                  </div>
                }
              />
            );
          }}
          //   render={(_, v) => {
          //     return (
          //       <Collect
          //         // menu={{ items: [] }}
          //         dataSource={collectClassifyList}
          //         favoriteDescription={v?.doctorFavoriteDescription}
          //         cancelCollect={(value) => {
          //           handelCancelCollect(v);
          //         }}
          //         collect={(value) => {
          //           handelCollect(v, value);
          //         }}
          //         deleteCollect={(value) => {
          //           deleteCollect(value);
          //         }}
          //         childrens={
          //           <div
          //             style={{ cursor: 'pointer' }}
          //             // onClick={() => {
          //             //   setOpen(true);
          //             // }}
          //           >
          //             {v?.doctorFavoriteDescription ? (
          //               <Icon
          //                 size={24}
          //                 style={{ color: '#ffa77e' }}
          //                 type="icon-shoucang1"
          //               />
          //             ) : (
          //               <Icon
          //                 size={24}
          //                 style={{ color: '#AEB0B3' }}
          //                 type="icon-meiyoushoucang"
          //               />
          //             )}
          //           </div>
          //         }
          //       />
          //     );
          //   }}
        ></VSTableColumn>
      </VSPromiseTable>
    );
  };
  // 未诊table
  const renderNotTreatTable = () => {
    return (
      <VSPromiseTable
        id="notTreatTable"
        className="aspirin-table hideScroll"
        onFetch={getNotTreatList}
        // extraParams={{ inputChange }}
        pagination={false}
        scroll={{ y: 455 }}
        data-id={id}
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        editable={{
          columnProps: {
            width: 10,
          },
        }}
        rowClassName={(v) => {
          return v?.id === row?.id ? 'aspirin-collect-table-bgc' : '';
        }}
        rowClick={{
          rowClickType: {
            type: 'default',
            action: 'double-click',
          },
        }}
        onRow={(record) => {
          return {
            onDoubleClick: async () => {
              if (
                currentApplication?.department?.id ===
                record?.clinicRegisterDefine?.clinicDepartment?.id
              ) {
                // const res =
                //   await vsf.services?.OutpVisitEncounterController_createOutpVisitEncounter_a27cb7?.(
                //     {
                //       // TODO: 请检查以下这部分传参是否符合需求
                //       outpVisitId: record?.id,
                //     },
                //   ); // TODO: 你可能需要整理返回数据格式
                // if (res?.code === 200) {
                //   setRow('');
                //   handleSelect(res?.data);
                //   setTimeout(() => {
                //     vsf.refs.notTreatTable?.reload();
                //   }, 1000);
                // }
                setRow('');
                handleSelect(record, 'NOT_TREAT');
                setTimeout(() => {
                  vsf.refs.notTreatTable?.reload();
                }, 1000);
              } else {
                Modal.confirm({
                  title: '提示',
                  // TipIcon: null,
                  icon: null,
                  // closeIcon: <Icon type="icon-shanchu1" />,
                  centered: true,
                  content: (
                    <div style={{ display: 'flex' }}>
                      <div style={{ margin: '2px 5px 0 0' }}>
                        <Icon type="icon-jingshi" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          fontSize: '16px',
                        }}
                      >
                        <div>
                          该患者挂号科室为：
                          <span style={{ color: '#3276E8' }}>
                            【
                            {
                              record?.clinicRegisterDefine?.clinicDepartment
                                ?.departmentName
                            }
                            】 科室，
                          </span>
                          {/* 进行退诊吗？ */}
                        </div>
                        <div style={{ margin: '5px 0' }}>
                          挂号为：
                          <span style={{ color: '#3276E8' }}>
                            【{record?.clinicRegisterDefine?.doctor?.staffName}
                            】
                          </span>
                          {record?.clinicRegisterDefine?.clinicLabel}，
                        </div>
                        <div>如需就诊请提醒该患者。</div>
                      </div>
                    </div>
                  ),
                  onOk: async () => {
                    // const res =
                    //   await vsf.services?.OutpVisitEncounterController_createOutpVisitEncounter_a27cb7?.(
                    //     {
                    //       // TODO: 请检查以下这部分传参是否符合需求
                    //       outpVisitId: record?.id,
                    //     },
                    //   ); // TODO: 你可能需要整理返回数据格式
                    // if (res?.code === 200) {
                    // vsf.refs.collectTable?.reload();
                    // vsf.refs.otherTable?.reload();
                    //   onDropdownOpen();
                    handleSelect(record, 'NOT_TREAT');
                    setRow('');
                    vsf.refs.notTreatTable?.reload();
                    // }
                  },
                  onCancel() {},
                  width: 520,
                });
              }
            },
            onClick: () => {
              setRow(record);
              // handleSelect(record);
            },
          };
        }}
      >
        {/* <VSTableColumn
            dataIndex={['clinicRegisterDetailTimeDivision', 'timeDescription']}
            title="午别"
            valueType="select"
            dataSource={[
              { label: '上午', value: 'AM' },
              { label: '下午', value: 'PM' },
            ]}
            fieldProps={{}}
            hideInTable
          /> */}
        <VSTableColumn
          dataIndex={['clinicRegisterDetailTimeDivision', 'serialNumber']}
          title="序号"
          valueType="text"
          fieldProps={{}}
          render={(_, value) => {
            const _value =
              value?.clinicRegisterDetailTimeDivision?.timeDescription === 'AM'
                ? '上午'
                : '下午';
            return (
              <div>
                {_value}
                {'\u00A0'} {'\u00A0'}
                {value?.clinicRegisterDetailTimeDivision?.serialNumber}
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 30,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 10,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['patient', 'displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          width={110}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['clinicRegisterDefine', 'clinicLabel']}
          title="号别"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 50,
              },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={[
            'clinicRegisterDefine',
            'clinicDepartment',
            'departmentName',
          ]}
          title="就诊科室"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {record?.clinicRegisterDefine?.clinicLabel}
              </div>
            );
          }}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['chargeTypeCode']}
          title="费别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 50,
              },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['identityType']}
          title="身份"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 30,
              },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['clinicRegisterDefine', 'doctor', 'staffName']}
          title="医师"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['diagnosis']}
          title="诊断"
          valueType="text"
          hideInTable={type === 'simple'}
          render={(_, value) => {
            return value?.diagnosisRecordWithDiagnosisDtoList
              ?.map((item) => {
                return item?.diagnosis?.diagnosisName;
              })
              ?.join(',');
          }}
          width={130}
        />
        <VSTableColumn
          dataIndex={['visitDate']}
          title="就诊日期"
          valueType="date"
          // fieldProps={{ format: 'YYYY-MM-DD' }}
          hideInTable={type === 'simple'}
          render={(_, data) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {dayjs(data?.visitDate).format('MM-DD')}
              </div>
            );
          }}
        />
      </VSPromiseTable>
    );
  };
  // 已诊、暂挂table
  const renderOtherTable = () => {
    return (
      <VSPromiseTable
        id="otherTable"
        className="aspirin-table hideScroll"
        onFetch={status === 'HAS_TREAT' ? getHasTreat : getNotHangUp}
        pagination={false}
        data-id={id}
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        scroll={{ y: 455 }}
        rowClassName={(v) =>
          v.id === row?.id ? 'aspirin-collect-table-bgc' : ''
        }
        editable={{
          columnProps: {
            width: 100,
          },
        }}
        rowClick={{
          rowClickType: {
            type: 'default',
            action: 'double-click',
          },
        }}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              setRow(record);
              handleSelect(record, 'HAS_TREAT');
              // if (
              //   record?.encounterDoctor?.id == staffInfo?.doctorDto?.staffId
              // ) {
              //   setRow(record);
              //   handleSelect(record, 'HAS_TREAT');
              //   // onDropdownOpen(false);
              // } else {
              //   Modal.confirm({
              //     title: '提示',
              //     icon: null,
              //     maskClosable: true,
              //     centered: true,
              //     content: '是否新建病例？',
              //     onOk: async (e) => {
              //       console.log('新建病例');
              //       setRow(record);
              //       handleSelect(record, 'HAS_TREAT');
              //       // onDropdownOpen(false);
              //     },
              //     //   onCancel: () => {
              //     //     onDropdownOpen(false);
              //     //   },
              //     width: 520,
              //   });
              // }
            },
            onClick: () => {
              setRow(record);
              handelClick(record);
              // handleSelect(record);
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={[
            'outpVisit',
            'clinicRegisterDetailTimeDivisionId',
            'timeDescription',
          ]}
          title="午别"
          valueType="select"
          dataSource={[
            { label: '上午', value: 'AM' },
            { label: '下午', value: 'PM' },
          ]}
          fieldProps={{}}
          hideInTable={true}
        />
        <VSTableColumn
          dataIndex={[
            'outpVisit',
            'clinicRegisterDetailTimeDivisionId',
            'serialNumber',
          ]}
          title="序号"
          valueType="digit"
          fieldProps={{}}
          render={(_, value) => {
            const _value =
              value?.outpVisit?.clinicRegisterDetailTimeDivision
                ?.timeDescription === 'AM'
                ? '上午'
                : '下午';
            return (
              <div>
                {_value}
                {'\u00A0'} {'\u00A0'}
                {
                  value?.outpVisit?.clinicRegisterDetailTimeDivision
                    ?.serialNumber
                }
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'gender']}
          title="性别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.SEX_DICT}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'age']}
          title="年龄"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['patient', 'displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '用于显示的id长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          width={110}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'clinicRegisterDefine', 'clinicLabel']}
          title="号别"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['visitDepartment', 'departmentName']}
          title="就诊科室"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {record?.visitDepartment?.departmentName}
              </div>
            );
          }}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'chargeTypeCode']}
          title="费别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['outpVisit', 'identityType']}
          title="身份"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 30 },
            ],
          }}
          fieldProps={{}}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['encounterDoctor', 'staffName']}
          title="医师"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {record?.encounterDoctor?.staffName}
              </div>
            );
          }}
          hideInTable={type === 'simple'}
        />
        <VSTableColumn
          dataIndex={['diagnosis']}
          title="诊断"
          valueType="text"
          hideInTable={type === 'simple'}
          render={(_, value) => {
            return value?.diagnosisRecordWithDiagnosisDtoList
              ?.map((item) => {
                return item?.diagnosis?.diagnosisName;
              })
              ?.join(',');
          }}
          width={130}
        />
        <VSTableColumn
          dataIndex={['visitStartDate']}
          title="就诊日期"
          valueType="date"
          hideInTable={type === 'simple'}
          render={(_, data) => {
            return (
              <div
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {dayjs(data?.visitStartDate).format('MM-DD')}
              </div>
            );
          }}
        />
        <VSTableColumn
          title="收藏"
          // name="option"
          valueType="option"
          dataIndex={['doctorFavoriteDescription']}
          hideInTable={type === 'simple'}
          render={(_, v) => {
            return (
              <Collect
                value={v}
                favoriteDescription={v?.doctorFavoriteDescription}
                cancelCollect={(value) => {
                  handelCancelCollect(v);
                }}
                collect={(value) => {
                  handelCollect(v, value, 'other');
                }}
                deleteCollect={(value) => {
                  deleteCollect(value);
                }}
                getCollectClassifyList={getCollectClassifyList}
                childrens={
                  <div style={{ cursor: 'pointer' }}>
                    {v?.doctorFavoriteDescription ? (
                      <Icon
                        size={24}
                        style={{ color: '#ffa77e' }}
                        type="icon-shoucang1"
                      />
                    ) : (
                      <Icon size={24} type="icon-meiyoushoucang" />
                    )}
                  </div>
                }
              />
            );
          }}
        ></VSTableColumn>
      </VSPromiseTable>
    );
  };
  const rendelCollectModal = (params) => {
    const { isMySelect, row, collectDescription } = params;
    return (
      <TipModal
        content={`${
          (
            isMySelect === 'other'
              ? row?.doctorFavoriteDescription
              : row?.medicalRecordDescription
          )
            ? '是否更改收藏目录?'
            : '确认收藏?'
        }`}
        open={openCollectModal}
        onCancel={() => {
          setCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_createDoctorFavoriteMedicalRecord_f15c7f?.(
              {
                btoParam: {
                  // TODO: 请检查以下这部分传参是否符合需求
                  outpVisitEncounterId: row?.id,

                  medicalRecordDescription: collectDescription?.label,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCollextModal(false);
            message.open({
              type: 'success',
              content: `收藏成功`,
            });
            clearScrollId();
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
            }, 100);
          }
        }}
      />
    );
  };
  const rendelCancelCollectModal = (params) => {
    const { isMySelect, row } = params;
    return (
      <TipModal
        content="是否取消收藏"
        open={openCancelCollectModal}
        onCancel={() => {
          setCancelCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_deleteDoctorFavoriteMedicalRecord_bc0e79?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: {
                  outpVisitEncounterId:
                    isMySelect === 'other'
                      ? row?.id
                      : row?.outpVisitEncounter?.id,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCancelCollextModal(false);
            message.open({
              type: 'success',
              content: `成功取消收藏`,
            });
            clearScrollId();
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
              // getCollectClassifyList();
            }, 100);
          }
        }}
      />
    );
  };

  return (
    <div>
      {tab === 'collect' && renderCollectTable()}
      {tab !== 'collect' && status === 'NOT_TREAT' && renderNotTreatTable()}
      {tab !== 'collect' && status !== 'NOT_TREAT' && renderOtherTable()}
      {/* 收藏二次确认弹窗 */}
      {openCollectModal && rendelCollectModal(collectParams)}
      {/* 取消收藏二次确认弹窗 */}
      {openCancelCollectModal && rendelCancelCollectModal(collectParams)}
    </div>
  );
};

export default definePage(Index);
