import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Tag,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { mockDoctorId } from '@/mock';
import { cdssShow } from '@/mock';
import Icon from '@/module/cpoe/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';

import EditDisease from './edit/edit_disease';

const Index = (props) => {
  // outAllergy门诊过敏史 bedCardAllergy 床卡过敏史
  const {
    scrollYValue,
    type = 'outPrevious',
    currentBedPatient,
    nurseInVisitInfo,
    editModalWidth = 680,
    editModalHeight = 500,
  } = props;

  const {
    user: { currentPatient, staffInfo, outVisitInfo },
  } = props?.stores ?? {};

  const _patientId = currentPatient?.patientId;

  // 疾病史
  const getDiseasesHistoryList = async (params) => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getDiseaseHistoryByPatientId_81fac3?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  // 疾病史名称
  const getDiseaseName = async () => {
    const res =
      await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          qto: {
            from: 0,
            size: 100,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      const _res = res?.data?.result?.map((item) => {
        return { label: item?.diagnosisName, value: String(item?.id) };
      });
      return _res;
    }
  };

  const renderDiseasType = (dom, value) => {
    if (!value?.chronicAcuteDisease) return;
    if (value?.cureIndicator) {
      return (
        <div
          className={className(
            'previous_history_diseas_type',
            'previous_history_diseas_type_cure',
          )}
        >
          {dom}
        </div>
      );
    } else {
      return (
        <div
          className={className(
            'previous_history_diseas_type',
            value?.chronicAcuteDisease === 'ACUTE_DISEASE'
              ? 'previous_history_diseas_type_anxious'
              : 'previous_history_diseas_type_slow',
          )}
        >
          {dom}
        </div>
      );
    }
  };
  // 不在过敏\疾病
  const renderAllergyText = (dom, value) => {
    return <div style={{ color: value ? '#85898D' : '#343B42' }}>{dom}</div>;
  };

  return (
    <VSPromiseTable
      id="diseaseTable"
      className="previous_history_list_tabel aspirin-table-empty-background-color"
      pagination={false}
      scroll={{
        // y: 250,
        y: getScrollYRem(53),
      }}
      onFetch={getDiseasesHistoryList}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-Frame-21" size={18} />,
        deleteText: <Icon type="icon-shanchuwudi" size={18} />,
        columnProps: {
          fixed: 'right',
          width: 60,
          // hideInTable: true,
        },
        onCanDelete: (row) => {
          return row?.recordDoctorId === staffInfo?.doctorDto?.staffId;
        },
      }}
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_updateDiseaseHistory_38e949?.(
            {
              btoParam: {
                ...(params ?? {}),
              },
              extApiParam: {},
            },
          );
        if (res?.code == 200) {
          message.success('疾病史更新成功');
        }
        vsf?.refs?.diseaseTable?.reload();
        return res?.data;
      }}
      updateLinkPage={{
        linkPage: () => <EditDisease title="update" />,
        modalProps: {
          title: `编辑疾病史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.diseaseForm?.validateFieldsReturnFormatValue();
            return ret;
          } catch (e) {
            return false;
          }
        },
      }}
      onAdd={async (params) => {
        // const res =
        //   await vsf?.services?.ClinicRecordHistoryController_createDiseaseHistory_71c74d?.(
        //     {
        //       btoParam: {
        //         ...(params ?? {}),
        //         patientId: _patientId,
        //         outpVisitId: outVisitInfo?.outpVisit?.id,
        //         // allergyNumber: 1,
        //       },
        //       extApiParam: {},
        //     },
        //   );
        // if (res?.code == 200) {
        //   message.success('新增成功');
        // }
        // return res?.data;
        return params;
      }}
      addLinkPage={{
        linkPage: () => <EditDisease />,
        modalProps: {
          title: `新增疾病史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.diseaseForm?.validateFieldsReturnFormatValue();
            const res =
              await vsf?.services?.ClinicRecordHistoryController_createDiseaseHistory_71c74d?.(
                {
                  btoParam: {
                    ...(ret ?? {}),
                    patientId: _patientId,
                    outpVisitId: outVisitInfo?.outpVisit?.id,
                  },
                  extApiParam: {},
                },
              );
            if (res?.code == 200) {
              message.success('疾病史新增成功');
            }
            vsf?.refs?.diseaseTable?.reload();
            return ret && res?.code == 200;
          } catch (e) {
            return false;
          }
        },
      }}
      onRemove={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_deleteDiseaseHistory_8e7d75?.(
            { btoParam: params, extApiParam: {} },
          );
        if (res?.code == 200) {
          message.success('疾病史删除成功');
        }
        return res?.code === 200;
      }}
      cardProps={{
        bodyStyle: {
          padding: 0,
        },
      }}
    >
      <VSTableColumn
        dataIndex={['chronicAcuteDisease']}
        title="类型"
        valueType="select"
        dataSource={[
          { label: '慢病', value: 'CHRONIC_DISEASE' },
          { label: '急症', value: 'ACUTE_DISEASE' },
        ]}
        width={labelNumberWidth(5)}
        fieldProps={{}}
        render={(dom, value) => {
          return renderDiseasType(dom, value);
        }}
      />

      <VSTableColumn
        dataIndex={['diseaseName']}
        title="疾病名称"
        valueType="select"
        dataSource={getDiseaseName}
        ellipsis
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 100 },
          ],
        }}
        fieldProps={{}}
        render={(dom, value) => {
          return renderAllergyText(dom, value?.cureIndicator);
        }}
      />
      <VSTableColumn
        dataIndex={['durationTime']}
        title="持续时间/治疗情况"
        valueType="select"
        dataSource={[
          { label: '月', value: 'MONTH' },
          { label: '年', value: 'YEAR' },
        ]}
        fieldProps={{}}
        ellipsis
        render={(dom, value) => {
          console.log(value, 'value');
          const _obj = { MONTH: '月', YEAR: '年' };
          return (
            <div
              className="treatDescription"
              style={{
                color: value?.cureIndicator ? '#85898D' : '',
              }}
            >
              {value?.durationTime ?? 0}
              {value?.durationTimeUnit ? _obj?.[value?.durationTimeUnit] : ''}
              {value?.treatDescription ? '/' : ''}
              {value?.treatDescription}
            </div>
          );
        }}
      />
      <VSTableColumn
        dataIndex={['startDate']}
        title="发病时间"
        valueType="date"
        fieldProps={{
          format: 'YYYY-MM-DD',
        }}
        render={(dom, value) => {
          return renderAllergyText(dom, value?.cureIndicator);
        }}
      />

      <VSTableColumn
        dataIndex={['inHospitalIndicator']}
        title="信息来源"
        valueType="switch"
        fieldProps={{}}
        render={(_, value) => {
          if (value?.inHospitalIndicator === null) return '';
          return value?.inHospitalIndicator ? (
            <div
              style={{
                color: value?.cureIndicator ? '#85898D' : '',
              }}
            >
              本院发生
            </div>
          ) : (
            <div
              style={{
                color: value?.cureIndicator ? '#85898D' : '',
              }}
            >
              患者自述
            </div>
          );
        }}
      />

      <VSTableColumn
        dataIndex={['cureIndicator']}
        title="当前状态"
        valueType="switch"
        fieldProps={{}}
        render={(_, value) => {
          if (value?.cureIndicator === null) return '';
          return (
            <div>
              {value?.cureIndicator ? (
                <div
                  style={{
                    color: value?.cureIndicator ? '#85898D' : '',
                  }}
                >
                  治愈
                </div>
              ) : (
                <div>未治愈</div>
              )}
            </div>
          );
        }}
      />
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title={
          <div className="previous_history_tabletoolbar">
            <div className="previous_history_tabletoolbar_div"></div>
            疾病史
          </div>
        }
      >
        <VSTableAddition
          // className="previous_history_tabletoolbar_addBtn"
          children="新增"
          size="small"
          type="primary"
          // style={{ backgroundColor: 'transparent', color: '#3276e8' }}
          // icon=""
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};

export default definePage(Index);
