import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRegisterLeaveRecordDtoController_queryClinicRegisterLeaveRecordDetailWaterfall_5a6c6a:
    {
      method: 'post',
      url: '/api/outp-register/query-clinic-register-leave-record-detail-waterfall',
      parameterFomatter: (data?: {
        qto: ClinicRegisterLeaveRecordDetailQtoOutpRegisterPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: ClinicRegisterLeaveRecordDetailVoOutpRegisterEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicRegisterLeaveRecordBOController_cancelClinicRegisterLeaveRecord_38c56a:
    {
      method: 'post',
      url: '/api/outp-register/cancel-clinic-register-leave-record',
      parameterFomatter: (data?: { clinicRegisterLeaveRecordId: number }) =>
        data,
      responseFormatter: (_, __, data?: number) => data,
    },
  ClinicRegisterLeaveRecordBOController_createClinicRegisterLeaveRecords_ac2729:
    {
      method: 'post',
      url: '/api/outp-register/create-clinic-register-leave-records',
      parameterFomatter: (data?: {
        createClinicRegisterLeaveRecordList: CreateClinicRegisterLeaveRecordEoOutpRegisterPersistEo[];
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  ClinicRegisterLeaveRecordBOController_updateLeaveReason_13b9a9: {
    method: 'post',
    url: '/api/outp-register/update-leave-reason',
    parameterFomatter: (data?: {
      clinicRegisterLeaveRecordId: number;
      reason: string;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
