import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientJourneyController_getPatientJourney_95f0ff: {
    method: 'post',
    url: '/api/clinic-record-order/get-patient-journey',
    parameterFomatter: (data?: {
      qto: PatientJourneyOrderNewQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientJourneyVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ExamController_getAllByExamClassItemClassNewQto_006cfc: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-item-class-new-qto',
    parameterFomatter: (data?: {
      qto: ExamClassItemClassNewQtoExamPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  LabController_getAllByLabClassQto_a6f1c8: {
    method: 'post',
    url: '/api/lab/get-all-by-lab-class-qto',
    parameterFomatter: (data?: { qto: LabClassQtoLabPersistQto }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo[]) => data,
  },
});
