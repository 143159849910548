import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  Tooltip,
} from '@vs/vsf-kit';
import React, { useState } from 'react';
const DetailTable = (props) => {
  const [index, setIndex] = useState();
  const [selectRow, setSelectRow] = useState();
  const payTypeData = [
    { label: '现金', value: 'CASH' },
    { label: '支付宝', value: 'ALIPAY' },
    { label: '微信', value: 'WECHAT' },
    { label: '数字人民币', value: 'DIGITAL_RMB' },
    { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
  ];
  return (
    <VSPromiseTable
        vsid="43095"
        id="inpSettleMasterDetailList"
        bordered
        className="aspirin-table-no-background-box"
        onFetch={() => props.value}
        onRow={(node, index) => {
          return {
            onClick() {
              console.log(node);
              setSelectRow(node);
              setIndex(index);
              props?.selecteDataChange(node);
            },
            onDoubleClick() {
              setSelectRow(node);
              setIndex(index);
              props?.selecteDataChange(node);
            },
          };
        }}
        rowClassName={(record, rowIndex) => {
          if (index === rowIndex) {
            return 'aspirin-row-selected-box';
          } else {
            return '';
          }
        }}
        pagination={false}
      >
        <VSTableColumn
          dataIndex={['prePaymentDate']}
          title="业务时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={150}
        />

        <VSTableColumn
          dataIndex={['inpVisitId']}
          hideInTable
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['physicalReceiptNumber']}
          title="收据号"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['prePaymentStatus']}
          title="业务类型"
          valueType="select"
          dataSource={[
            { label: '(收)-收预交金', value: 'CASH_IN_RECEIVE_PRE_PAYMENT' },
            { label: '(支)-退预交金', value: 'CASH_OUT_REFUND_PRE_PAYMENT' },
            { label: '(收)-退医疗款', value: 'CASH_IN_REFUND_PAYMENT' },
            { label: '(支)-支付医疗款', value: 'CASH_OUT_PAYMENT' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['prePaymentAmount']}
          title="金额"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['prePaymentDetailBaseDtoList']}
          title="付款方式"
          valueType="text"
          render={(_, values) => {
            console.log(values);
            let payTypeString = '';
            values?.prePaymentDetailBaseDtoList?.map((item) => {
              const payType = payTypeData.find(
                (val) => item?.paymentType === val?.value,
              );
              payTypeString = payTypeString.concat(
                `[${payType?.label}:${item?.paymentAmount}]`,
              );
            });
            return (
              <Tooltip title={payTypeString}>
                <div className="text-overflow-omit">{payTypeString}</div>
              </Tooltip>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['operatorStaff', 'staffName']}
          title="打印操作员"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['paymentDetailDtoList']}
          title="打印状态"
          valueType="text"
          // fieldProps={{ children: <DateRangePicker /> }}
          render={(_, v) => {
            return '未打印';
          }}
        />

        <VSTableColumn
          dataIndex={['inpReceiptRecordBaseDtoList', 'printDate']}
          title="打印日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSPromiseTable>
  );
};
export default definePage(DetailTable);
