import { createServices } from '@vs/vsf-boot';
export default createServices({
  ComplaintJudgeDictionaryController_createComplaintJudgeDictionary_cbf96f: {
    method: 'post',
    url: '/api/base-common/create-complaint-judge-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateComplaintJudgeDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintJudgeDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
});
