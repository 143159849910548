import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.less';
import { VSTableColumn, VSPromiseTable, message } from '@vs/vsf-kit';
//医嘱费用/护士录入
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import ProjectNameSelect from './ProjectNameSelect';
import {
  getTableValues,
  scrollTo,
  addTableRow,
  getSelectedRowValues,
  clearTable,
  setMaxHeight,
} from './methods';
import { isNumber, isObject, includes, isNull, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { getScrollY } from '@/utils';
// 护士录入
const NurseEntry = (props) => {
  const { item, setItem } = props;
  const { common, user } = props?.stores || {};
  const { staffInfo } = user || {};
  const { currentApplication } = common || {};
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => setSelectedRows(null), [props?.onUserInfo, item]);
  /**
   * convertNurseRecordBillingDetailList2CreateInpBillingDetailForNurseStationReq
   * @param {any} input
   * @returns {any}
   */
  const convertNurseRecordBillingDetailList2CreateInpBillingDetailForNurseStationReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.saveInpBillingDetailEoList = [];
      output.saveInpBillingDetailEoList.push(
        ...(input?.map((d, i) => {
          const obj = {};
          isNumber(_.get(d, `id`)) && (obj.id = _.get(d, `id`)); // Long 住院费用明细id
          obj.inpVisitId = props?.onUserInfo?.inpVisitId; // 就诊流水号
          obj.patientId = props?.onUserInfo?.patientId; // Long 患者id
          if (includes(['New', 'Pattern'], d?.TheRowType)) {
            // TODO 没有匹配到合适字段 obj.inpVisitId = null; // Long 住院主记录id
            obj.itemId = _.get(d, `itemId`); // String 价表项目ID
            // TODO 没有匹配到合适字段

            obj.itemName = isObject(_.get(d, `itemName`))
              ? _.get(d, `itemName`).label
              : _.get(d, `itemName`); // String 项目名称
            obj.itemId = isObject(_.get(d, `itemName`))
              ? _.get(d, `itemName`).value
              : _.get(d, `itemId`); // String 项目id
            obj.amount = _.get(d, `amount`); // Bigdecimal 数量
            obj.itemClass = _.get(d, `itemClass`); // String 价表项目分类
            obj.orderDate = _.get(d, `orderDate`); // Date 业务时间
            obj.operateDate = _.get(d, `operateDate`); // Date 录入时间
            obj.operatorId = _.get(d, `operatorId`); // Long 录入人
            obj.cost = _.get(d, `cost`); // Bigdecimal 计价金额
            obj.charge = _.get(d, `charge`); // Bigdecimal 应收金额
            obj.applyDepartmentId = _.get(d, `applyDepartmentId`); // Long 开单科室
            obj.applyStaffId = _.get(d, `operatorId`); // Long 开单医生
            obj.performDepartmentId = _.get(d, `performDepartmentId`); // Long 执行科室
            // TODO 没有匹配到合适字段
            obj.orderId = null; // Long 医嘱id
            obj.performStaffId = _.get(d, `operatorId`); // Long 执行人
            obj.insuranceCode = _.get(d, `insuranceCode`); // Long 医保编码
            obj.medicalInsuranceType = _.get(d, `medicalInsuranceType`); // Long 医保项目类别
          }
          !d?.new && (obj.adjustAmount = _.get(d, `adjustAmount`)); // Bigdecimal 调整数量
          obj.memo = _.get(d, `memo`); // String 补退说明

          return obj;
        }) ?? []),
      );
      return output;
    };
  /**
   * convertCreateInpBillingDetailForNurseStationRes2NurseRecordBillingDetailList
   * @param {any} input
   * @returns {any}
   */
  const convertCreateInpBillingDetailForNurseStationRes2NurseRecordBillingDetailList =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ...(_.get(input, 'data')?.map((d, i) => {
          const obj = {};
          obj.id = _.get(d, 'id');
          obj.itemClass = _.get(d, 'itemClass');
          obj.itemName = _.get(d, 'itemName');
          obj.itemSpecification = _.get(d, 'itemSpecification');
          obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
          obj.adjustAmount = null; // Bigdecimal 调整数量
          obj.unit = _.get(d, 'unit');
          obj.memo = _.get(d, 'memo');
          obj.cost = _.get(d, 'cost');
          obj.charge = _.get(d, 'charge');
          obj.orderDate = _.get(d, 'orderDate');
          obj.inpVisitId = _.get(d, 'inpVisitId');
          obj.insuranceCode = _.get(d, 'insuranceCode');
          obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
          obj.price = _.get(d, 'price'); // TODO 请确认下行字段是否符合逻辑
          obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName'); // TODO 请确认下行字段是否符合逻辑
          obj.performDepartmentName = _.get(
            d,
            'performDepartment.departmentName',
          ); // TODO 请确认下行字段是否符合逻辑
          obj.staffName = _.get(d, 'operator.staffName');
          obj.operateDate = _.get(d, 'operateDate');
          return obj;
        }) ?? []),
      );
      return output;
    };
  /**
   * convertGetInpBillingDetailByItemIdRes2NurseRecordBillingDetailList
   * @param {any} input
   * @returns {any}
   */
  const convertGetInpBillingDetailByItemIdRes2NurseRecordBillingDetailList = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.TheRowType = 'Save';
        obj.id = _.get(d, 'id');
        obj.itemClass = _.get(d, 'itemClass');
        // obj.itemId = _.get(d, 'itemId');
        obj.itemName = {
          label: _.get(d, 'itemName'),
          value: _.get(d, 'itemId'),
        };
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.amount = _.get(d, 'amount'); // TODO 没有匹配到合适字段
        obj.adjustAmount = null; // Bigdecimal 调整数量
        obj.unit = _.get(d, 'unit');
        obj.memo = _.get(d, 'memo');
        obj.cost = _.get(d, 'cost');
        obj.charge = _.get(d, 'charge');
        obj.orderDate = _.get(d, 'orderDate');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.medicalInsuranceType = _.get(d, 'medicalInsuranceType');
        obj.price = _.get(d, 'price'); // TODO 请确认下行字段是否符合逻辑
        obj.applyDepartmentName = _.get(d, 'applyDepartment.departmentName');
        obj.applyDepartmentId = _.get(d, 'applyDepartment.id'); // TODO 请确认下行字段是否符合逻辑
        obj.performDepartmentName = _.get(
          d,
          'performDepartment.departmentName',
        ); // TODO 请确认下行字段是否符合逻辑
        obj.performDepartmentId = _.get(d, 'performDepartment.id');
        obj.staffName = _.get(d, 'operator.staffName');
        obj.operatorId = _.get(d, 'operator.id');
        obj.operateDate = _.get(d, 'operateDate');
        return obj;
      }) ?? []),
    );
    return output;
  };
  /**
   * convertGetInpBillingDetailGroupInfoForNurseBillingRes2NurseRecordGroupBillingDetailListRo
   * @param {any} input
   * @returns {any}
   */
  const convertGetInpBillingDetailGroupInfoForNurseBillingRes2NurseRecordGroupBillingDetailListRo =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ...(_.get(input, 'data')?.map((d, i) => {
          const obj = {};
          obj.itemClass = _.get(d, 'itemClass');
          obj.itemId = _.get(d, 'itemId');
          obj.itemName = _.get(d, 'itemName');
          obj.itemSpecification = _.get(d, 'itemSpecification');
          obj.price = _.get(d, 'price');
          obj.unit = _.get(d, 'unit');
          obj.insuranceCode = _.get(d, `insuranceCode`); // Long 医保编码
          obj.medicalInsuranceType = _.get(d, `medicalInsuranceType`); // Long 医保项目类别
          return obj;
        }) ?? []),
      );
      return output;
    };
  const expenseDetailsTableCreateOnFetch = useCallback(
    async (params) => {
      if (!props?.onUserInfo?.inpVisitId) return;

      let param = { inpVisitId: props?.onUserInfo?.inpVisitId };
      item?.itemId && (param.itemId = item?.itemId);

      const res =
        await vsf?.services?.InpBillingDetailController_getInpBillingDetailByItemId_ac57fa?.(
          param,
        ); // TODO: 请确认入参来源及出参用法
      // TODO: 请确认入参来源及出参用法
      const output =
        convertGetInpBillingDetailByItemIdRes2NurseRecordBillingDetailList?.(
          res,
        );
      return { data: output ?? [], total: output?.length ?? 0 };
    },
    [props?.onUserInfo?.inpVisitId, item],
  );
  const leftListOnFetch = useCallback(
    async (params) => {
      setItem(null);
      if (!props?.onUserInfo?.inpVisitId) return;
      const res =
        await vsf?.services?.InpBillingDetailController_getInpBillingDetailGroupInfoForNurseBilling_dbd63f?.(
          {
            inpVisitId: props?.onUserInfo?.inpVisitId,
            itemName: params?.search?.itemName,
          },
        ); // TODO: 请确认入参来源及出参用法
      const output =
        convertGetInpBillingDetailGroupInfoForNurseBillingRes2NurseRecordGroupBillingDetailListRo?.(
          res,
        );
      return { data: output ?? [], total: output?.length ?? 0 };
    },
    [props?.onUserInfo?.inpVisitId],
  );
  // 列表宽度/高度
  const leftSubtractRef = useRef(null);
  const ComputeLeftTableScroll = () => ({
    y: setMaxHeight('left-table', leftSubtractRef?.current?.offsetHeight),
  });
  const ComputeRightListScroll = () => ({
    y: setMaxHeight('right-table'),
    x: 2280,
  });
  return (
    <>
      <div className="NurseEntry">
        <div className="left">
          <div className="top">
            <tr>
              <td className="title">计费项目列表</td>
            </tr>
          </div>
          <div className="table" id="left-table">
            <VSPromiseTable
              vsid="95097"
              id="chargingTableCreate"
              scroll={ComputeLeftTableScroll()}
              onFetch={leftListOnFetch}
              pagination={false}
              searchFormRender={(...[, table]) => {
                return (
                  <div
                    className="chargingTableCreate-table-search"
                    ref={leftSubtractRef}
                  >
                    {table}
                  </div>
                );
              }}
              rowClassName={(node) => {
                return node?.itemId === item?.itemId
                  ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
                  : 'aspirin-vs-table-row-box aspirin-row-select-box';
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (record?.itemId === item?.itemId) {
                      setItem(null);
                      return;
                    }
                    setItem(record);
                  },
                };
              }}
            >
              <VSTableColumn
                dataIndex={['itemName']}
                title="项目名称"
                valueType="text"
                search
                order={0}
                columnKey={'itemName'}
                hideInTable
                fieldProps={{ placeholder: '请输入项目名称' }}
              />
              <VSTableColumn
                dataIndex={['itemClass']}
                title="类别"
                valueType="select"
                fieldProps={{}}
                dataSource={vsf.stores.dbenums?.enums?.CLINIC_ITEM_CLASS_DICT}
              />

              <VSTableColumn
                dataIndex={['itemName']}
                title="项目内容"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['itemSpecification']}
                title="规格"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                dataIndex={['price']}
                title="单价"
                valueType="digit"
                fieldProps={{}}
              />
            </VSPromiseTable>
          </div>
        </div>
        <div className="right">
          <div className="top">
            <tr>
              <td className="title">费用明细表</td>
              <td className="button">
                <td>
                  <ButtonAndKeyDown
                    onClick={() =>
                      addTableRow('expenseDetailsTableCreate', {}).then((d) =>
                        scrollTo(
                          'expenseDetailsTableCreate',
                          getTableValues('expenseDetailsTableCreate')?.[
                            getTableValues('expenseDetailsTableCreate').length -
                              1
                          ]?.id,
                          '',
                        ),
                      )
                    }
                    type="primary"
                    children="新增"
                    methodKey="altKey"
                    ordinaryKey=""
                    disabled={props?.onUserInfo?.inpVisitId ? false : true}
                  />
                </td>
                <td>
                  <ButtonAndKeyDown
                    onClick={() => {
                      let i = 0,
                        data =
                          vsf?.refs?.expenseDetailsTableCreate?.getSelectedRowValues();
                      while (data.length > i) {
                        if (includes(['Save'], data?.[i]?.TheRowType))
                          return message.error(
                            '删除项包含不可删除的项目，请重新选中。',
                          );
                        i++;
                      }
                      data?.forEach((row) => {
                        includes(['New', 'Pattern'], row?.TheRowType) &&
                          vsf?.refs?.expenseDetailsTableCreate?.remove({
                            id: row?.id,
                          });
                      });
                      message.success('删除成功。');
                    }}
                    type="primary"
                    children="删除"
                    methodKey="altKey"
                    ordinaryKey=""
                    disabled={props?.onUserInfo?.inpVisitId ? false : true}
                  />
                </td>
              </td>
            </tr>
          </div>
          <div className="table" id="right-table">
            <VSPromiseTable
              scroll={ComputeRightListScroll()}
              rowSelection={{
                selectType: 'multiple',
                selectedRows,
                onSelectedRowsChange: (data) => {
                  setSelectedRows(data);
                },
              }}
              editable={{
                editType: 'multiple',
                columnProps: {
                  hideInTable: true,
                },
                onFieldChange: (key, value, record, form) => {
                  const therow = vsf?.refs?.expenseDetailsTableCreate
                    .getValues()
                    ?.find((element) => element.id === key);
                  if (
                    includes(['New', 'Pattern'], therow?.TheRowType) &&
                    therow?.price
                  )
                    form?.setFieldValue?.(
                      [key, 'cost'],
                      (
                        parseFloat(record?.amount) * parseFloat(therow?.price)
                      ).toFixed(2),
                    );
                },
              }}
              onChange={() => {}}
              id="expenseDetailsTableCreate"
              onRecord={async () => {
                let params = {
                  TheRowType: 'New', // 新增标识
                  itemName: '请选择项目名称',
                  amount: 1, // 数量
                  cost: 0, // 计价费用
                  charge: 0, // 应收金额
                  orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
                  operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
                  staffName: staffInfo?.staffName, // 员工姓名
                  operatorId: staffInfo?.id, // Long 录入人
                  performDepartmentId: currentApplication?.department?.id, // 科室主键名称
                  applyDepartmentId: currentApplication?.department?.id, // 主键
                  applyDepartmentName:
                    currentApplication?.department?.departmentName,
                  performDepartmentName:
                    currentApplication?.department?.departmentName, // 科室名称
                  ...props?.item,
                };
                if (props?.item?.itemId) {
                  params.itemName = {
                    label: item?.itemName, // 项目名称
                    value: item?.itemId, // 项目编码
                  };
                  params.cost = props?.item?.price;
                }
                return {
                  id: `TEMP_${Math?.random?.()}`,
                  ...params,
                };
              }}
              onFetch={expenseDetailsTableCreateOnFetch}
              pagination={false}
            >
              <VSTableColumn
                dataIndex={['itemClass']}
                title="类别"
                valueType="select"
                fieldProps={{}}
                dataSource={vsf.stores.dbenums?.enums?.CLINIC_ITEM_CLASS_DICT}
                preview
              />

              <VSTableColumn
                dataIndex={['itemName']}
                title="项目名称"
                valueType="select"
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      type: 'object',
                    },
                  ],
                }}
                width={250}
                fieldProps={{
                  placeholder: '请选择',
                  dataSource: props?.getValue?.(),
                }}
                renderFormItem={(...args) => {
                  let [schema, rows, form] = args,
                    component = null;
                  if (
                    includes(['New'], rows?.record?.TheRowType) &&
                    !item?.itemId
                  ) {
                    const props = Object.assign(
                      { ...schema?.originProps },
                      { ...rows, form },
                      { table_id: 'expenseDetailsTableCreate' },
                    );
                    component = <ProjectNameSelect {...props} />;
                  } else {
                    component = <span> {rows?.record?.itemName?.label}</span>;
                  }
                  return component;
                }}
              />

              <VSTableColumn
                dataIndex={['itemSpecification']}
                title="规格"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['amount']}
                title="数量"
                valueType="digit"
                fieldProps={{}}
                preview={(value, record, i) =>
                  !includes(['New', 'Pattern'], record?.TheRowType)
                }
                formItemProps={(_) => {
                  return {
                    rules: [
                      {
                        required: true,
                        message: '必填',
                        type: 'number',
                      },
                    ],
                  };
                }}
              />

              <VSTableColumn
                width={150}
                dataIndex={['adjustAmount']}
                title="调整数量"
                valueType="digit"
                fieldProps={{}}
                formItemProps={(form, config) => {
                  config = Object.assign(config, {
                    entity: getTableValues('expenseDetailsTableCreate').find(
                      (f) => f.id === config?.entity?.id,
                    ),
                  });
                  return {
                    rules: [
                      {
                        validator(record, value) {
                          if (value && !Number.isInteger(value)) {
                            return Promise.reject('数量必须是整数');
                          } else if (
                            value &&
                            value < 0 &&
                            config?.entity?.amount < Math.abs(value)
                          ) {
                            return Promise.reject(
                              '调整数量的绝对值不能大于数量',
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ],
                  };
                }}
                preview={(value, record, i) =>
                  !includes(['Save'], record?.TheRowType)
                }
              />

              <VSTableColumn
                dataIndex={['unit']}
                title="单位"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['memo']}
                title="补退说明"
                valueType="text"
                formItemProps={(from, record) => {
                  const { entity } = record;
                  return {
                    rules: [
                      {
                        validator: (_, value) => {
                          const { adjustAmount, TheRowType } =
                            vsf?.refs?.expenseDetailsTableCreate
                              ?.getValues?.()
                              ?.find((element) => element?.id === entity?.id);
                          const methods = {
                            // 新增时
                            Add: () => {
                              if (isEmpty(value))
                                return Promise.reject('请填写补退说明。');
                              return Promise.resolve();
                            },
                            // 保存时
                            Save: () => {
                              // 调整数量为空是 补退费 可不填
                              if (isNull(adjustAmount))
                                return Promise.resolve();

                              // 调整数量为空是 补退费 为必填
                              if (adjustAmount && isEmpty(value))
                                return Promise.reject('请填写补退说明。');
                              return Promise.resolve();
                            },
                          };

                          return methods?.[
                            includes(['Save'], TheRowType) ? 'Save' : 'Add'
                          ]();
                        },
                      },
                    ],
                  };
                }}
                fieldProps={{}}
                width={128}
                ellipsis
              />

              <VSTableColumn
                dataIndex={['cost']}
                title="计价金额"
                valueType="digit"
                fieldProps={{}}
                preview
                renderFormItem={(_, { record }) =>
                  parseFloat(record?.cost).toFixed(2)
                }
              />

              <VSTableColumn
                dataIndex={['charge']}
                title="应收金额"
                valueType="digit"
                fieldProps={{}}
                preview
                renderFormItem={(_, { record }) =>
                  parseFloat(record?.charge).toFixed(2)
                }
              />

              <VSTableColumn
                dataIndex={['orderDate']}
                title="业务时间"
                valueType="aspirinDateTimePicker"
                fieldProps={{
                  format: 'YYYY-MM-DD HH:mm:ss',
                  valueType: 'timestamp',
                }}
                width={182}
                preview={(value, record, i) =>
                  !includes(['New', 'Pattern'], record?.TheRowType)
                }
                formItemProps={{
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              />

              <VSTableColumn
                dataIndex={['insuranceCode']}
                title="医保编码"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['medicalInsuranceType']}
                title="医保项目类别"
                valueType="select"
                dataSource={[
                  { label: '甲', value: 'ALPHA' },
                  { label: '乙', value: 'BETA' },
                  { label: '丙', value: 'GAMMA' },
                ]}
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['price']}
                title="项目单价"
                valueType="digit"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['applyDepartmentName']}
                title="开单科室"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['performDepartmentName']}
                title="执行科室"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['staffName']}
                title="录入人"
                valueType="text"
                fieldProps={{}}
                preview
              />

              <VSTableColumn
                dataIndex={['operateDate']}
                title="录入时间"
                valueType="date"
                fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                width={168}
                preview
                formItemProps={{
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
              />
            </VSPromiseTable>
          </div>
          <div className="footer">
            <tr>
              <td>
                <ButtonAndKeyDown
                  children="模板导入"
                  onClick={() => props.setModalTemplateOpen(true)}
                  type="primary"
                  methodKey="altKey"
                  ordinaryKey=""
                  disabled={
                    props?.onUserInfo?.inpVisitId
                      ? props?.item
                        ? true
                        : false
                      : true
                  }
                />
              </td>
              <td>
                <ButtonAndKeyDown
                  children="保存"
                  onClick={async (event) => {
                    await vsf?.refs?.expenseDetailsTableCreate
                      ?.getEditForm()
                      ?.validateFields();
                    // TODO: 请确认入参来源及出参用法
                    // TODO: 请确认入参来源及出参用法
                    const input =
                      convertNurseRecordBillingDetailList2CreateInpBillingDetailForNurseStationReq?.(
                        vsf?.refs?.expenseDetailsTableCreate?.getValues(),
                      );

                    const res =
                      await vsf?.services?.InpBillingDetailController_createInpBillingDetailForNurseStation_3910aa?.(
                        input,
                      );

                    if (res?.code === 200) {
                      setSelectedRows([]);
                      res?.data?.forEach(
                        ({ id, amountRefund, amount, price }) => {
                          let showAmount =
                            parseInt(amount) - parseInt(amountRefund);
                          let cost = (
                            parseInt(showAmount) * parseFloat(price)
                          ).toFixed(2);
                          if (amountRefund > 0)
                            vsf?.refs?.expenseDetailsTableCreate
                              ?.getEditForm()
                              ?.setFieldsValue({
                                [id]: {
                                  amount: showAmount,
                                  cost: cost,
                                  charge: cost,
                                },
                              });
                        },
                      );
                      clearTable('expenseDetailsTableCreate');
                      vsf?.refs?.expenseDetailsTableCreate?.reload();
                      vsf?.refs?.chargingTableCreate?.reload();
                      return message.success('保存成功');
                    }
                  }}
                  type="primary"
                  methodKey="altKey"
                  ordinaryKey=""
                  disabled={props?.onUserInfo?.inpVisitId ? false : true}
                />
              </td>
              <td>
                <ButtonAndKeyDown
                  children="费别切换"
                  onClick={() => {}}
                  disabled
                  type="primary"
                  methodKey="altKey"
                  ordinaryKey=""
                />
              </td>
            </tr>
          </div>
        </div>
      </div>
    </>
  );
};

export default NurseEntry;
