import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  message,
  VSPromiseTable,
  VSTableColumn,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React, { forwardRef, Fragment, useCallback, useState } from 'react';

import ComplexSelect, {
  ComplexSelectProps,
  useComplexSelect,
} from '@/pages/Index/components/complex_select';
import { askFixed } from '@/pages/Index/components/func/ask';

import { onDrugInfoFetch } from '../../manage/drug/centre_medicine_dispensing/service';
import { useInputEnter } from '../dosage/utils';
import { useOnChangeValue } from '../func/hooks';

const VSPromiseTableNew: any = VSPromiseTable;

const TableRender = (props) => {
  const { onFetch, list, ...rest } = props;
  const columns = [
    {
      title: '序号',
      dataIndex: ['index'],
      width: 50,
    },
    {
      title: '药品名称',
      dataIndex: ['drugProductName'],
    },
    {
      title: '规格',
      dataIndex: ['drugSpecification'],
    },
    {
      title: '基数',
      dataIndex: ['desc'],
    },
    {
      title: '价格',
      dataIndex: ['retailPrice'],
    },
    {
      title: '医保类型',
      dataIndex: ['medicalInsuranceType'],
      render: (...[, record]) => {
        const name = [
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]?.find((item) => item?.value === record?.medicalInsuranceType)?.label;
        return name ? name + '类' : '';
      },
      shouldCellUpdate: undefined,
    },
  ];

  const style: React.CSSProperties = {
    fontSize: 14,
    color: '#aeb0b3',
    marginBottom: 10,
  };
  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);

  return (
    <div>
      <div style={style}>输入序号选择项目，键盘“← →”翻页</div>
      <VSPromiseTable
        onFetch={onFetch}
        id="TableRender"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 8,
        }}
        rowKey="key"
        rowClick={{
          rowClickType: 'select',
        }}
        rowClassName={(record) => {
          return rest?.rowSelection?.selectedRowKeys?.includes?.(record?.key)
            ? 'ant-table-row-selected'
            : '';
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          onSelectedRowsChange: (value: any) => {
            const [state] = value;
            if (
              list
                ?.map((item) => item?.drugName?.value)
                .includes(state?.drugPriceItemId)
            ) {
              message?.warning('药品重复选择');
              return;
            }
            onDrugDetailsFetch(state?.drugItemId)?.then((signal) => {
              rest?.rowSelection?.onChange(void 0, [
                {
                  ...state,
                  ...signal,
                },
              ]);
            });
          },
        }}
        size="small"
      >
        {columns?.map((item, index) => {
          return <VSTableColumn key={index} {...item} />;
        })}
      </VSPromiseTable>
    </div>
  );
};

enum SelectedOmit {
  onChange,
  value,
  showLabel,
  mode,
  dropdownMatchSelectWidth,
  dropdownStyle,
  hideSelectIcon,
  keyDown,
  keyDownDeploy,
  children,
  dataSource,
}

type SelectedProps = Omit<ComplexSelectProps, keyof typeof SelectedOmit> & {
  onChange?: (value: any) => void;
  value?: any;
  schema: any;
  config: any;
  form: any;
  record: any;
  dataSource: any[];
  storage: any;
  herbType: any;
};

type DataValue = {
  label: string;
  value: number;
};

const DrugHerbSelect = forwardRef((props: SelectedProps, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    dataSource,
    storage,
    herbType,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form?.useFormInstance();
  const [key] = (rest?.id ?? '')?.split('_');
  const record = form?.getFieldValue(key);
  const [data] = useOnChangeValue([]);
  const handleOnChange = async (value) => {
    if (
      dataSource
        ?.map((item) => item?.drugName?.value)
        .includes(value?.drugPriceItemId)
    ) {
      message?.warning('药品重复选择');
      return;
    }
    const unit =
      vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
        (item) => item?.value == value?.drugEo?.packageUnit,
      )?.label ?? value?.drugEo?.packageUnit;
    form?.setFieldValue(key, {
      ...record,
      drugName: {
        label: value?.drugProductName,
        value: value?.drugPriceItemId,
      },
      drugProductName: value?.drugProductName,
      drugPriceItemId: value?.drugPriceItemId,
      orderClass: 'HERB',
      price: value?.prescriptionRatio
        ? value?.prescriptionRatio * value?.retailPrice
        : 0,
      retailPrice: value?.retailPrice,
      drugItemId: value?.drugItemId,
      medicalInsuranceType: value?.medicalInsuranceType,
      prescriptionRatio: value?.prescriptionRatio,
      amountPerPackage: value?.amountPerPackage,
      maxDosage: value?.maxDosage,
      totalAmount: {
        value: value?.prescriptionRatio,
        // unit: value?.drugEo?.packageUnit,
        unit,
      },
    });
    onChange?.({
      label: value?.drugName,
      value: value?.drugPriceItemId,
    });
    onFieldEnter?.();
  };

  const [search, setSearch] = useState({
    inputCodeLike: '',
  });

  const onFetch = useCallback(
    async (params) => {
      if (storage?.id) {
        const res =
          await vsf.services?.DrugDrugController_queryHerbList_50f5a9?.({
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              storageIdIn: [storage?.storageId],
              herbTypeIn: [herbType],
              ...search,
            },
            ext: {},
          });
        if (res?.code === 200) {
          const list = res?.data?.result?.map((item, index) => ({
            ...item,
            index: index + 1,
            key: index + 1,
          }));
          data?.onChange(list);
          return list;
        }
      } else {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, storage, herbType],
  );

  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);
  const onSearch = async (value) => {
    const num = value?.replace(/[^0-9]/gi, '');
    if (typeof Number(num) === 'number') {
      if (value) {
        if (data?.value?.[num - 1]) {
          const state = await onDrugDetailsFetch?.(
            (data?.value?.[num - 1] as any)?.drugItemId,
          );
          handleOnChange?.({
            ...(data as any)?.value?.[num - 1],
            ...state,
          });
        } else {
          setSearch({
            inputCodeLike: value,
          });
        }
      } else {
        setSearch({
          inputCodeLike: '',
        });
      }
    }
  };
  return (
    <ComplexSelect
      onChange={handleOnChange}
      onSearch={onSearch}
      value={value}
      showLabel="drugName"
      ref={ref as any}
      mode="radio"
      dropdownMatchSelectWidth={700}
      dropdownStyle={{}}
      listHeight={500}
      hideSelectIcon
      dataSource={data?.value}
      keyDownDeploy={{
        ArrowLeft: () => {
          vsf?.refs?.TableRender?.gotoPreviousPage();
        },
        ArrowRight: () => {
          vsf?.refs?.TableRender?.gotoNextPage();
        },
        async Enter(value: any) {
          const state = await onDrugDetailsFetch?.(value?.drugItemId);
          handleOnChange?.({
            ...value,
            ...state,
          });
        },
      }}
      {...rest}
      {...fieldEnterProps}
    >
      <TableRender onFetch={onFetch} list={dataSource} />
    </ComplexSelect>
  );
});

DrugHerbSelect.displayName = 'drugHerbSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'drugHerbSelect',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      return <>{value}</>;
    },
  }),
)(DrugHerbSelect) as typeof DrugHerbSelect;
