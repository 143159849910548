import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-order-drug-and-valid',
    parameterFomatter: (data?: {
      drugItemId: string;
      patientId: number;
      departmentId: string;
      staffId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo,
    ) => data,
  },
});
