import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserStaffController_queryAnesthesiaDoctorListBySearchCode_0f5004: {
    method: 'post',
    url: '/api/user-staff/query-anesthesia-doctor-list-by-search-code',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryOperationDoctorListBySearchCode_3a0b41: {
    method: 'post',
    url: '/api/user-staff/query-operation-doctor-list-by-search-code',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpVisitController_saveMedicalRecord_a12ec8: {
    method: 'post',
    url: '/api/clinic-inp-visit/save-medical-record',
    parameterFomatter: (data?: {
      updatePatientBto: UpdatePatientBtoPatientMainServiceBto;
      saveInpVisitForMedicalRecordBto: SaveInpVisitForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionStatisticsBto: SaveInpVisitExtensionStatisticsBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionNurseRecordForMedicalRecordBto: SaveInpVisitExtensionNurseRecordForMedicalRecordBtoClinicInpVisitServiceBto;
      saveInpVisitExtensionBloodInfoBto: SaveInpVisitExtensionBloodInfoBtoClinicInpVisitServiceBto;
      createDiagnosisRecordBtoList: CreateDiagnosisRecordBtoClinicRecordServiceBto[];
      saveDiagnosisCompareRecordBtoList: SaveDiagnosisCompareRecordBtoClinicRecordServiceBto[];
      saveOperationRecordBtoList: SaveOperationRecordBtoClinicInpVisitRecordServiceBto[];
      saveIcuRecordBtoList: SaveIcuRecordBtoClinicRecordServiceBto[];
      saveBloodTransfusionRecordBto: SaveBloodTransfusionRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicInpVisitController_getMedicalRecordVoById_150e6f: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-medical-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalRecordVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
