import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageProfileManageController_queryDrugProductWithNotProfiles_3aafb4: {
    method: 'post',
    url: '/api/drug-storage/query-drug-product-with-not-profiles',
    parameterFomatter: (data?: {
      qto: QueryProfileProductWithInputQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStorageProfileProductStockVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
