import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderController_getListOrderStatisticsNotChargeOperationVoByInpVisitId_80b777:
    {
      method: 'post',
      url: '/api/clinic-record-order/get-list-order-statistics-not-charge-operation-vo-by-inp-visit-id',
      parameterFomatter: (data?: { inpVisitId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderStatisticsNotChargeOperationVoClinicRecordOrderEntranceWebVo[],
      ) => data,
    },
});
