import { compose, withField, withPreview, withRef } from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import { Form, InputNumber, InputProps } from 'antd';
import React, { forwardRef, useEffect, useRef } from 'react';

import { useInputEnter } from '../dosage/utils';
import { UsageUnit } from '../usage';

const HerbTotalAmount = forwardRef((props: any, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, id, ...rest } = props;

  const [unit, setUnit, getUnit] = useGetState<any>('');
  const [current, setCurrent] = useGetState<any>('');
  const valueRef = useRef<any>();
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form.useFormInstance();
  const totalAmount = form?.getFieldValue([id, 'totalAmount', 'value']);
  const prescriptionRatio = form?.getFieldValue([id, 'prescriptionRatio']);

  useEffect(() => {
    if (typeof value === 'object') {
      setCurrent(value?.value);
      setUnit(value?.unit);
      valueRef.current = 1;
    } else {
      setCurrent(value);
      valueRef.current = 2;
    }
  }, [value, setCurrent, setUnit]);

  const handleChange = (v) => {
    const formatValue =
      valueRef?.current === 1
        ? {
            value: v,
            unit: getUnit(),
          }
        : v;
    onChange?.(formatValue);
  };

  return (
    <InputNumber
      keyboard={false}
      ref={ref as any}
      value={current}
      onChange={handleChange}
      {...fieldEnterProps}
      {...rest}
      addonAfter={unit}
      placeholder="请输入剂量"
      onKeyDown={(event) => {
        if (event?.code === 'ArrowUp') {
          event?.preventDefault();
          if (prescriptionRatio) {
            if (totalAmount % prescriptionRatio !== 0) {
              const num = Math.abs(totalAmount % prescriptionRatio);
              handleChange?.(totalAmount - num + prescriptionRatio);
              return;
            }
            const num = prescriptionRatio + totalAmount;
            handleChange?.(num);
          } else {
            handleChange?.(totalAmount + 1);
          }
        }
        if (event?.code === 'ArrowDown') {
          event?.preventDefault();
          if (prescriptionRatio) {
            if (totalAmount % prescriptionRatio !== 0) {
              const num = Math.abs(
                (totalAmount % prescriptionRatio) - totalAmount,
              );
              handleChange?.(num);
              return;
            }
            const num = totalAmount - prescriptionRatio;
            if (num >= prescriptionRatio) handleChange?.(num);
          } else {
            handleChange?.(totalAmount - 1);
          }
        }
      }}
      onFocus={(event) => {
        event?.target?.select?.();
      }}
    />
  );
});

HerbTotalAmount.displayName = 'herbTotalAmount';
export default compose(
  withRef(),
  withField<any>({
    name: 'herbTotalAmount',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        return <UsageUnit value={props?.value} />;
      },
    },
  ),
)(HerbTotalAmount) as typeof HerbTotalAmount;
