import {
  Space,
  VSForm,
  VSFormItem,
  Button,
  List,
  Tooltip,
  VSFormLayout,
  Divider,
  message,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import { omitBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { filterEmptyValues } from '@/utils/setDataUtil';
import { removeObjectFalsy } from '@/utils';

import { list, toDispenseWindowList, windowDetail } from './Service';
import TableWaitConfirm from './table';

import './index.less';

function index() {
  const { data, loading, run } = useRequest(list, {
    manual: true,
  });
  //主索引查询接口
  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (res?.data?.result.length >= 1) return res?.data?.result[0];
      // message.error('未查到患者信息');
      return false;
    },
    { manual: true },
  );

  useEffect(() => {
    const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
    run(file);
  }, []);

  const confirmSucess = () => {
    // run
  };
  const [selectFlag, setSelectFlag] = useState();
  const [tabaleParam, setTableParam] = useState();
  const getIdentification = ({
    identificationClassIn,
    identificationNumberIn,
  }) => {
    if (!identificationClassIn || !identificationNumberIn) return false;
    switch (identificationClassIn) {
      case '患者ID':
        return { displayIdIs: identificationNumberIn };
      case '身份证':
        return { idNumberIs: identificationNumberIn };
      case '智慧医保':
        return { insuranceNumberIs: identificationNumberIn };
      default:
        return {
          identificationClassIn: [identificationClassIn],
          identificationNumberIn: [identificationNumberIn],
        };
    }
  };
  const queryList = async () => {
    setSelectFlag();
    setTableParam();
    const { identification, ...reset } =
        vsf?.refs?.drugSelectForm?.getFieldsValue(),
      identificationDetals = getIdentification(identification ?? {});
    let patient = false;
    if (identificationDetals)
      patient = await patientDetallRun(identificationDetals);
    run(
      omitBy(
        removeObjectFalsy({
          ...reset,
          patientIdIn: patient?.id ? [patient?.id] : '',
        }),
        (value) => value === '',
      ),
    );
  };
  return (
    <div className="confim-medicine-layout">
      <div className="left">
        <div>
          <VSForm id="drugSelectForm">
            <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
              <VSFormItem
                name={'identification'}
                valueType="certificateSearch"
                label="患者id"
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
              <VSFormItem
                name={'nameLike'}
                valueType="text"
                label="姓名"
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout columnCount={3}>
              <VSFormItem
                name={'queryAllWindowIs'}
                valueType="check"
                label="全部窗口"
                style={{ width: '300px' }}
                fieldProps={{}}
                hidden
              />
              <VSFormItem
                name={'drugName'}
                valueType="check"
                label="显示未报到"
                fieldProps={{}}
                style={{ width: '300px' }}
                hidden
              />
              <div style={{ textAlign: 'right', marginBottom: '24px' }}>
                <Button type="primary" onClick={queryList}>
                  查询
                </Button>
              </div>
            </VSFormLayout>
          </VSForm>
          <Divider className="aspirin-divider-style" />
        </div>

        <div style={{ overflow: 'auto', flex: '1' }}>
          <VSTable
            className="common_table"
            scroll={{
              x: '42rem',
            }}
            pagination={false}
            value={data}
            rowClassName={(node) => {
              return node?.id === tabaleParam?.id
                ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
                : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
            }}
            onRow={(node) => {
              return {
                onClick: () => {
                  if (node?.id === tabaleParam?.id)
                    return setTableParam(undefined);
                  setTableParam(node);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['returnApplyDate']}
              title="日期"
              valueType="text"
            />
            <VSTableColumn
              width="7rem"
              dataIndex={['name']}
              title="姓名"
              valueType="text"
            />
            <VSTableColumn
              width="7rem"
              dataIndex={['staffName']}
              title="申请医师"
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['refundDrugApplyNumber']}
              title="申请单号"
              valueType="text"
            />
          </VSTable>
        </div>
      </div>
      <div className="right">
        <TableWaitConfirm
          tabaleParam={tabaleParam}
          setTableParam={setTableParam}
          setSelectFlag={setSelectFlag}
          indexListRun={run}
        />
      </div>
    </div>
  );
}

export default index;
