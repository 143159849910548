import {
  Button,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  List,
  Tooltip,
  Divider,
  message,
  Select,
  Image,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import { useEffect, useState } from 'react';

import { filterEmptyValues } from '@/utils/setDataUtil';
import { getScrollY, removeObjectFalsy } from '@/utils';
import readCard from '@/assets/icon/readCard.png';
import vsf, { definePage } from '@vs/vsf-boot';

import InfoAndList from './info_and_list';
import { list } from './service';

import './index.less';
import { omitBy } from 'lodash';
import { CertificateSearch } from '@/components';

const CentreMedicineDispensing = (props) => {
  const [tabaleParam, setTableParam] = useState();

  const { data, loading, run } = useRequest(list, {
    manual: true,
  });
  useEffect(() => {
    if (data?.length >= 1) {
      setTableParam(data?.[0]);
    }
  }, [data]);
  //获取queryDayIs天数,不要删除这个接口
  const { data: sysData } = useRequest(async () => {
    const res =
      await vsf?.services?.SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8(
        {
          qto: {},
        },
      );
    const file = res?.data?.find((item) => {
      return item?.name === 'QUERY_WAIT_DISPENSE_DAY';
    });
    vsf?.refs?.centreDispensesMedicineSearchForm?.setFieldsValue({
      queryDayIs: file?.systemValue,
    });
    return res?.data;
  });

  //主索引查询接口
  const { runAsync: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (res?.data?.result.length >= 1) return res?.data?.result[0];
      // message.error('未查到患者信息');
      return false;
    },
    { manual: true },
  );

  useEffect(() => {
    const file = vsf?.refs?.centreDispensesMedicineSearchForm?.getFieldsValue();
    run(file);
  }, [sysData]);

  //查询按钮
  // const qureyList = () => {
  //   setTableParam(undefined);
  //   const file =
  //     vsf?.refs?.centreDispensesMedicineSearchForm?.getFieldsValue(true);
  //   if (file?.patientIdIs) {
  //     patientDetallRun({
  //       displayIdIs: file?.patientIdIs,
  //     }).then((item) => {
  //       // file.patientIdIs = item[0]?.id;
  //       run(
  //         filterEmptyValues({
  //           ...file,
  //           patientIdIs: item[0]?.id,
  //         }),
  //       );
  //     });
  //   } else {
  //     run(
  //       filterEmptyValues({
  //         ...file,
  //       }),
  //     );
  //   }
  // };
  const getIdentification = ({
    identificationClassIn,
    identificationNumberIn,
  }) => {
    if (!identificationClassIn || !identificationNumberIn) return false;
    switch (identificationClassIn) {
      case '患者ID':
        return { displayIdIs: identificationNumberIn };
      case '身份证':
        return { idNumberIs: identificationNumberIn };
      case '智慧医保':
        return { insuranceNumberIs: identificationNumberIn };
      default:
        return {
          identificationClassIn: [identificationClassIn],
          identificationNumberIn: [identificationNumberIn],
        };
    }
  };
  const qureyList = async () => {
    setTableParam(undefined);
    const { identification, ...reset } =
        vsf?.refs?.centreDispensesMedicineSearchForm?.getFieldsValue?.(),
      identificationDetals = getIdentification(identification ?? {});
    let patient = false;
    if (identificationDetals)
      patient = await patientDetallRun(identificationDetals);
    run(
      omitBy(
        removeObjectFalsy({
          ...reset,
          patientIdIs: patient?.id ? patient?.id : '',
        }),
        (value) => value === '',
      ),
    );
  };
  return (
    <div className="centre-dispenses-medicine-layout">
      <div className="left">
        <div style={{ width: '100%' }}>
          <Space size={4}>
            <VSForm id="centreDispensesMedicineSearchForm">
              <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
                <VSFormItem
                  name={'identification'}
                  valueType="custom"
                  label="患者id"
                >
                  <CertificateSearch onEnter={qureyList} />
                </VSFormItem>
              </VSFormLayout>
              <VSFormLayout columnCount={1} labelCol={120} gutter={70}>
                <VSFormItem
                  name={'nameLike'}
                  valueType="text"
                  label="姓名"
                  fieldProps={{
                    onPressEnter: qureyList,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout columnCount={1}>
                <VSFormItem
                  name={'queryDayIs'}
                  valueType="text"
                  label="查询天数"
                  // style={{ width: '300px' }}
                  rules={[
                    {
                      message: '请输入大于0的正整数',
                      type: 'string',
                      pattern: /^[1-9]\d*$/,
                    },
                  ]}
                  fieldProps={{
                    onPressEnter: qureyList,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout columnCount={3}>
                <VSFormItem
                  name={'queryAllWindowIs'}
                  valueType="check"
                  label="全部窗口"
                  style={{ width: '300px' }}
                  fieldProps={{
                    onChange: qureyList,
                  }}
                />
                <VSFormItem
                  name={'drugName'}
                  valueType="check"
                  label="显示未报到"
                  style={{ width: '300px' }}
                  fieldProps={{
                    onChange: qureyList,
                  }}
                />
                <div style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={qureyList}>
                    查询
                  </Button>
                </div>
              </VSFormLayout>
            </VSForm>
          </Space>
          <Divider className="aspirin-divider-style" />
        </div>

        <div style={{ flex: '1' }} className="list">
          <VSTable
            value={data}
            pagination={false}
            scroll={{
              x: 720,
              y: getScrollY(36),
            }}
            size="small"
            id="PatientDetailsTable"
            className="row_font_size"
            rowClassName={(node) => {
              return node?.id === tabaleParam?.id
                ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
                : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
            }}
            onRow={(node) => {
              return {
                onClick: () => {
                  if (node?.id === tabaleParam?.id) return setTableParam();
                  setTableParam(node);
                },
              };
            }}
          >
            <VSTableColumn
              width={126}
              dataIndex={['name']}
              title="姓名"
              valueType="text"
            />
            <VSTableColumn
              width={126}
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
            />
            <VSTableColumn
              width={86}
              dataIndex={['herbType']}
              title="类型"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.CHINESE_MEDICINE_DICT}
            />
            <VSTableColumn
              width={186}
              dataIndex={['windowDescription']}
              title="窗口号"
              valueType="text"
            />
            <VSTableColumn
              dataIndex={['createdAt']}
              title="日期"
              valueType="text"
            />
          </VSTable>
        </div>
      </div>
      <div className="right">
        <InfoAndList
          tabaleParam={tabaleParam}
          callback={run}
          qureyList={qureyList}
        />
      </div>
    </div>
  );
};

export default definePage(CentreMedicineDispensing);
