import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable } from '@vs/vsf-kit';
import React, { useCallback } from 'react';
const SupplyLogList = (props) => {
  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    console.debug(JSON.stringify(params));
    const res =
      await vsf.services?.DrugStockSupplyController_getPagedByDrugSupplyLogQto_c72272?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            drugProductIdIs: params?.extra?.drugProductId,
            drugStorageIdIs: params?.extra?.storageId,
            packageSpecificationIs: params?.extra?.packageSpecification,
            logTypeIs: 'SUPPLY',
            orderList: params.orderList,
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    // TODO: 你需要自己补充入参input来源及出参output用法
    return {
      data: (res?.data?.result ?? res?.data ?? []).map((m) =>
        convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable017371Ro(m),
      ),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  /**
   * convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable017371Ro
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedByDrugSupplyLogQtoRes2VSPromiseTable017371Ro = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = {};
    output.handleDate = _.get(input, 'handleDate');
    output.handleValue = _.get(input, 'handleValue');
    output.reason = vsf.stores.dbenums.enums.DRUG_UNSUPPORT_REASON_DICT?.find(
      (item) => item?.value === _.get(input, 'reason'),
    )?.label;
    output.staffName = _.get(input, 'operator.staffName');
    output.memo = _.get(input, 'memo');
    return output;
  };
  return (
    <VSPromiseTable
      scroll={{
        y: '17.4rem',
      }}
      className="aspirin-table"
      onFetch={onFetch}
      id="vSPromiseTable017371"
      vsid="01737"
      extraParams={props.value}
    >
      {/* <VSTableColumn
        dataIndex={['drugProductIdIs']}
        title="表格项"
        valueType="digit"
        search
        order={0}
        columnKey={'drugProductIdIs'}
        hideInTable
        formItemProps={{
          rules: [
            {
              message: '药品商品编码的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      /> */}

      <VSTableColumn
        dataIndex={['handleDate']}
        title="日期"
        editable={false}
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['handleValue']}
        title="操作"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['reason']}
        title="理由"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [{ message: '数据格式错误', type: 'string', min: 0, max: 20 }],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staffName']}
        title="操作人"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 200 },
          ],
        }}
        fieldProps={{}}
      />
    </VSPromiseTable>
  );
};
export default definePage(SupplyLogList);
