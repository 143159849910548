import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSPromiseTable, VSTableColumn, Todo, VSTableAddition, VSTableToolbar, withRef } from '@vs/vsf-kit';
import React, { useCallback, useImperativeHandle, useRef } from 'react';
import Icon from '@/module/Icon';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';

const DurgAliasManage = React.forwardRef((props, ref) => {

  const { drugInfo } = props
  const dataSourceRef = useRef([])

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    if (drugInfo?.id) {
      const res =
        await vsf.services?.DrugController_getDrugNameDictionaryListByDrugId_aa03ab?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugId: drugInfo?.drug?.id,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, [drugInfo])

  const handelRemove = async (params) => {
    const res =
      await vsf?.services?.DrugController_deleteDrugNameAlias_548a12?.({
        btoParam: {
          id: drugInfo?.drug?.id,
          drugNameDictionaryBtoList: [{
            id: params?.id
          }]
        },
        extApiParam: params?.extApiParam,
      });
    return res?.code === 200;
  }

  const handelInputChange = e => {
    const v = e.target.value ?? ''
    if (v) {
      const ask = askRomanAlphabet(v)
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return Promise.resolve(dataSourceRef.current ?? [])
      }
    }
  })

  return (
    <VSPromiseTable
      className='aspirin-table durg_alias_manage_table'
      editable={{
        editType: 'single',
        editText: <Icon
          type="icon-bianji"
        />,
        deleteText: <Icon
          type="icon-shanchu1"
        />,
        onFieldChange: (key, value, record, form) => {
          if (value.drugName) {
            form.setFieldValue(key, {
              ...record,
              inputCode: askRomanAlphabet(value.drugName)
            })
          }
        },
        onValuesChange: (record, _dataSource) => {
          dataSourceRef.current = _dataSource
        }
      }}
      vsid="79214"
      onRemove={handelRemove}
      onFetch={onFetch}
      onRecord={() => ({
        id: `create_${Math.random()}`
      })}
    >

      <VSTableToolbar title='药品别名列表'>
        <VSTableAddition
          position='top'
        >
          新增
        </VSTableAddition>
      </VSTableToolbar>

      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['drugId']}
        title="药品ID"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['drugName']}
        title="*名称"
        valueType="text"
        formItemProps={{
          rules: [
            { required: true, message: '请输入名称' },
            { message: '数据格式错误', type: 'string', min: 0, max: 300 },
          ],
        }}
        fieldProps={{
          onChange: handelInputChange
        }}
      />

      <VSTableColumn
        dataIndex={['nameType']}
        title="*类型"
        valueType="select"
        dataSource={[
          { label: '通用名', value: 'COMMON' },
          { label: '化学名', value: 'CHEMISTRY' },
          { label: '英文名', value: 'ENGLISH' },
          { label: '商品名', value: 'TRADE' },
          { label: '别名', value: 'ALIAS' },
        ]}
        formItemProps={{
          rules: [
            {
              required: true,
              message: '请选择类型'
            }
          ]
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['inputCode']}
        title="*输入码"
        valueType="text"
        formItemProps={{
          rules: [
            // {required: true, message: ''},
            { message: '数据格式错误', type: 'string', min: 0, max: 20 },
          ],
        }}
        fieldProps={{
          readOnly: true
        }}
      />

      {/* <VSTableColumn
          dataIndex={['inputCodeWubi']}
          title="五笔输入码"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        /> */}
    </VSPromiseTable>
  );
})
DurgAliasManage.displayName = "DurgAliasManage"
export default withRef()(DurgAliasManage)
