import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getAllByClinicItemTreatQto_ebfc19: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-treat-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemTreatQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_createClinicTreatItem_de96a6: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-treat-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      createTreatItemBto: CreateTreatItemBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_updateClinicTreatItem_5f6500: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-treat-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateTreatItemBto: UpdateTreatItemBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
