import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Button,
  Image,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useState, useRef } from 'react';
import SearchContainer from '@/module/Search';

import { getScrollY } from '@/utils';
import { getExportEntityForExcel } from './util';
import Block from '@/module/Block';

const StaffSignatureList = (props) => {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(undefined);
  const tableRef = useRef(null);

  /**
   * 当前选中的签名记录
   * @type {Array.<object>}
   */
  const [selectedSignatureItem, setSelectedSignatureItem] = useState();
  /**
   * 是否选中
   * @param {Array.<object>} item
   * @returns {boolean}
   */
  const hasNoneSelected = (item) => {
    return !(item && item.length > 0);
  };
  const onFecth = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.UserStaffController_queryPagedByStaffSignatureQto_b42a04?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
          ext: {},
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file === undefined) return;

    // 增加文件大小限制
    if (file.size > 50 * 1024) {
      vsf.showToast('文件大小超过50K限制', 'error');
      return;
    }

    // 读取选择的这个文件，把它转换成base64格式的字符串
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const base64 = reader.result;
      //上传图片
      const res =
        await vsf.services.UserStaffController_createOrUpdateSignature_47012a?.(
          {
            btoParam: {
              id: selectedSignatureItem[0].id,
              staffSignatureBto: {
                signatureImage: base64,
                imageName: file.name,
              },
            },
            extApiParam: {},
          },
        );
      if (res.code === 200) {
        vsf.showToast('签名图样修改成功', 'success');
        vsf?.refs.signatureTable.reload();
      }
      // 清空input的值，以便下次选择同一个文件时也能触发onChange事件
      event.target.value = null;
    };
  };

  const handleExportExcel = async () => {
    const hide = message.loading('正在导出Excel');

    const res =
      await vsf.services.UserStaffController_queryAllByStaffSignatureQto_d7e43d?.(
        { qto: {} },
      );
    if (res.code === 200) {
      const data = res.data.result;
      const value = data.map((item) => {
        return {
          staffNumber: item.staffNumber,
          staffName: item.staffName,
          departmentName: item.staffDepartment.departmentName,
          signatureImage: item.staffSignatureDto?.signatureImage,
        };
      });
      getExportEntityForExcel(value)
        .then()
        .catch((err) => {
          message.error(`导出失败：${err}`);
        })
        .finally(hide);
    } else {
      message.error('签名记录为空');
    }
  };

  return (
    <div className="aspirin-department-signature">
      <div style={{ flex: 1 }}>
        <VSPromiseTable
          scroll={{
            y: getScrollY(490),
          }}
          className="table_overflow_490
          aspirin-table-header-background-color
         aspirin-table-row-hover-background-color
         aspirin-table-nopadding
         aspirin-table-body-empty-transparent-background-color
         "
          ref={tableRef}
          id="signatureTable"
          onFetch={onFecth}
          rowSelection={{
            selectType: 'single',
            hideColumn: true,
            canUnSelectRadio: true,
            selectedRows: selectedSignatureItem,
            onSelectedRowsChange: (_value) => {
              setSelectedSignatureItem([..._value]);
            },
          }}
          rowClick={{ rowClickType: 'select' }}
          pagination={{ defaultPageSize: 20 }}
          searchConfig={{
            autoSearch: true,
            resetButtonProps: {
              onClick: () => {
                // 清空单独管理的state，留样人列表可以正确刷新
                setSelectedDepartmentId(undefined);

                // TODO：需要清空search form的值
                tableRef.current?.getSearchForm()?.resetFields();

                // 默认行为没有了，手动刷新列表数据
                tableRef.current?.reload();
              },
            },
            optionRender: (...arr) => {
              const [_, __, dom] = arr;
              return (
                <SearchContainer.Header title="人员签名信息">
                  {dom}
                </SearchContainer.Header>
              );
            },
          }}
          searchFormRender={(item, dom) => {
            return (
              <SearchContainer mode="inline" formPadding="0 170px 0 120px">
                {dom}
              </SearchContainer>
            );
          }}
        >
          <VSTableColumn
            dataIndex={['staffNumber']}
            title="工号"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: 'emp_no长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['staffName']}
            title="留样人"
            editable={false}
            valueType="select"
            search
            order={-1}
            columnKey={'staffNameLike'}
            formItemProps={{
              rules: [
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{
              dataSource: async (params) => {
                if (selectedDepartmentId !== undefined) {
                  params.search = {
                    ...params.search,
                    departmentIdIs: selectedDepartmentId,
                  };
                }

                if (params.label !== undefined) {
                  params.search = {
                    ...params.search,
                    staffNameLike: params.label,
                  };
                }

                //
                // Tips: 以下是你选择的请求函数
                const res =
                  await vsf.services?.UserStaffController_queryAllByQueryCapableStaffListWithSignatureQto_7b7f75?.(
                    {
                      // TODO: 请检查以下这部分传参是否符合需求
                      qto: {
                        from: params.pagination?.from ?? 0,
                        size: params.pagination?.size ?? 20,
                        orderList: params.orderList,
                        ...params.search,
                        ...params.filters,
                        ...(params?.extra ?? {}),
                        ...(params ?? {}),
                      },
                      ext: {},
                    },
                  ); // 数据convert
                const result = [];
                if (res.data?.result) {
                  res.data.result.forEach((item) => {
                    result.push({
                      label: item.staffName,
                      value: item.staffName,
                    });
                  });
                }
                return result;
              },
              fieldNames: undefined,
              showSearch: true,
            }}
          />

          <VSTableColumn
            dataIndex={['staffSignatureDto', 'signatureImage']}
            title="签名留样"
            editable={false}
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
              ],
            }}
            fieldProps={{}}
            render={(text, record) => {
              const imageData = record.staffSignatureDto?.signatureImage
                ? record.staffSignatureDto?.signatureImage
                : '';
              return <Image width={100} src={imageData} preview={false} />;
            }}
          />

          <VSTableColumn
            dataIndex={['staffDepartment', 'departmentName']}
            title="科室"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['staffDepartment', 'id']}
            title="科室"
            editable={false}
            valueType="select"
            search
            order={0}
            columnKey={'departmentIdIs'}
            fieldProps={{
              dataSource: async (params) => {
                if (params.label !== undefined) {
                  params.search = {
                    ...params.search,
                    departmentNameLike: params.label,
                  };
                }

                // Tips: 以下是你选择的请求函数
                const res =
                  await vsf.services?.UserStaffController_queryAllByQueryCapableDepartmentListWithSignatureQto_e3193b?.(
                    {
                      // TODO: 请检查以下这部分传参是否符合需求
                      qto: {
                        from: params.pagination?.from ?? 0,
                        size: params.pagination?.size ?? 200,
                        orderList: params.orderList,
                        ...params.search,
                        ...params.filters,
                        ...(params?.extra ?? {}),
                        ...(params ?? {}),
                      },
                      ext: {},
                    },
                  );
                if (res?.code == 200) {
                  const _res = res.data.result
                    ?.map((i) => {
                      return {
                        label: i?.staffDepartment?.departmentName,
                        value: i?.staffDepartment?.id,
                      };
                    })
                    ?.filter((i) => i?.value && i?.label);
                  return _res;
                } else {
                  return [];
                }
              },
              // fieldNames: undefined,
              onSelect: (item) => {
                setSelectedDepartmentId(item.value);
              },
              showSearch: true,
            }}
            hideInTable={true}
          />

          <VSTableColumn
            search
            order={-2}
            hideInTable
            columnKey={'imageNameIsNullOrNot'}
            valueType="select"
            title="签名留样"
            fieldProps={{
              showSearch: true,
              dataSource: [
                { label: '已留样', value: false },
                { label: '未留样', value: true },
              ],
            }}
          />

          <VSTableColumn
            dataIndex={['staffSignatureDto', 'imageName']}
            title="签名留样"
            editable={false}
            valueType="select"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 40 },
              ],
            }}
            fieldProps={{
              dataSource: [
                { label: '已留样', value: true },
                { label: '未留样', value: false },
              ],
            }}
            hideInTable={true}
          />
        </VSPromiseTable>
      </div>
      <div className="signature-operation">
        <input
          type="file"
          accept={'.jpg,.png,.jpeg'}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          children="修改留样"
          disabled={hasNoneSelected(selectedSignatureItem)}
          onClick={handleButtonClick}
        />
        <Button
          children="导出Excel"
          type="primary"
          onClick={handleExportExcel}
        />
      </div>
    </div>
  );
};
export default definePage(StaffSignatureList);
