import { askFixed } from '@/pages/Index/components/func/ask';
import { getScrollYRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
const prePayment = ({ inpVisitId }) => {
  /**
   * convertGetPrePaymentInfoByInpVisitIdRes2InpPrePaymentList
   * @param {any} input
   * @returns {any}
   */
  const convertGetPrePaymentInfoByInpVisitIdRes2InpPrePaymentList = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.prePaymentMasterVoList')?.map((d, i) => {
        const obj = {};
        obj.prePaymentMasterId = _.get(d, 'id');
        obj.patientId = _.get(d, 'patientId');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.prePaymentSource = _.get(d, 'prePaymentSource');
        obj.prePaymentDate = _.get(d, 'prePaymentDate');
        obj.prePaymentType = _.get(d, 'prePaymentType');
        obj.prePaymentStatus = _.get(d, 'prePaymentStatus');
        obj.prePaymentAmount = _.get(d, 'prePaymentAmount');
        obj.operatorStaffId = _.get(d, 'operatorStaffId');
        obj.operationDate = _.get(d, 'operationDate');
        obj.branchInstitutionId = _.get(d, 'branchInstitutionId');
        obj.chargeGroupId = _.get(d, 'chargeGroupId');
        obj.memo = _.get(d, 'memo');
        obj.prePaymentDetailBaseDtoList = _.get(
          d,
          'prePaymentDetailBaseDtoList',
        );
        obj.outpVisitId = _.get(d, 'outpVisitId');
        obj.prePaymentStatusName = _.get(d, 'prePaymentStatusName');
        obj.receiptPrintStatus = _.get(d, 'receiptPrintStatus');
        obj.staffName = _.get(d, 'operatorStaff.staffName');
        obj.printStaffId = _.get(d, 'printStaffId');
        obj.printDate = _.get(d, 'printDate');
        obj.physicalReceiptNumber = _.get(d, 'physicalReceiptNumber');
        obj.checkNumber = _.get(
          d,
          'prePaymentDetailBaseDtoList[0].checkNumber',
        );
        obj.cardNumber = _.get(d, 'prePaymentDetailBaseDtoList[0].cardNumber');
        obj.cashMachineNumber = _.get(
          d,
          'prePaymentDetailBaseDtoList[0].cashMachineNumber',
        );
        obj.posSearchNumber = _.get(
          d,
          'prePaymentDetailBaseDtoList[0].posSearchNumber',
        );
        obj.paymentType = _.get(
          d,
          'prePaymentDetailBaseDtoList[0].paymentType',
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
  const onFetch = useCallback(async () => {
    if (inpVisitId) {
      const res =
        await vsf?.services?.FinancePrePaymentController_getPrePaymentInfoByInpVisitId_8461f1?.(
          {
            inpVisitId: inpVisitId,
          },
        );
      const output =
        convertGetPrePaymentInfoByInpVisitIdRes2InpPrePaymentList?.(res);
      return { data: output ?? [], total: output?.length ?? 0 };
    }
  }, [inpVisitId]);

  return (
    <VSPromiseTable
      editable={{ editType: 'none' }}
      vsid="15941"
      id="inpPrePaymentList"
      onFetch={onFetch}
      pagination={false}
      bordered
      className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
      scroll={{
        y: getScrollYRem(33),
      }}
    >
      <VSTableColumn
        dataIndex={['prePaymentDate']}
        title="业务时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        ellipsis
      />
      <VSTableColumn
        dataIndex={['physicalReceiptNumber']}
        title="票据号"
        valueType="text"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['prePaymentStatus']}
        title="业务类型"
        valueType="select"
        fieldProps={{}}
        dataSource={[
          { label: '(收)-收预交金', value: 'CASH_IN_RECEIVE_PRE_PAYMENT' },
          { label: '(支)-退预交金', value: 'CASH_OUT_REFUND_PRE_PAYMENT' },
          { label: '(收)-退医疗款', value: 'CASH_IN_REFUND_PAYMENT' },
          { label: '(支)-支付医疗款', value: 'CASH_OUT_PAYMENT' },
        ]}
      />
      <VSTableColumn
        dataIndex={['prePaymentAmount']}
        title="金额"
        valueType="custom"
        fieldProps={{}}
        render={(...[, record]) => {
          return askFixed(record?.prePaymentAmount);
        }}
      />
      <VSTableColumn
        dataIndex={['paymentType']}
        title="付款方式"
        valueType="select"
        fieldProps={{}}
        dataSource={[
          { label: '现金', value: 'CASH' },
          { label: '支付宝', value: 'ALIPAY' },
          { label: '微信', value: 'WECHAT' },
          { label: '数字人民币', value: 'DIGITAL_RMB' },
          { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
        ]}
      />
      <VSTableColumn
        dataIndex={['receiptPrintStatus']}
        title="打印状态"
        valueType="select"
        dataSource={[
          { label: '待打印', value: 'WAIT_PRINT' },
          { label: '已打印', value: 'PRINT' },
          { label: '作废', value: 'CANCEL' },
        ]}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['staffName']}
        title="打印操作员"
        valueType="text"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['printDate']}
        title="打印日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      <VSTableColumn
        dataIndex={['posSearchNumber']}
        title="刷卡检索参考号"
        valueType="text"
      />
      <VSTableColumn dataIndex={['cardNumber']} title="卡号" valueType="text" />
      <VSTableColumn
        dataIndex={['cashMachineNumber']}
        title="终端号"
        valueType="text"
      />
      <VSTableColumn
        dataIndex={['staffName']}
        title="收款员"
        valueType="text"
      />
      <VSTableColumn
        dataIndex={['prePaymentSource']}
        title="来源"
        valueType="select"
        dataSource={[
          {
            label: '窗口',
            value: 'WINDOW',
          },
          {
            label: '自助机',
            value: 'SELF_SERVICE_MACHINE',
          },
          {
            label: '微信公众服务号',
            value: 'WATCH',
          },
          {
            label: '支付宝生活号',
            value: 'ALIPAY',
          },
          {
            label: '入院准备中心',
            value: 'INP_PRE_ADMISSION',
          },
          {
            label: '护士站',
            value: 'NURSE_STATION',
          },
        ]}
      />
      <VSTableColumn
        dataIndex={['checkNumber']}
        title="支票号"
        valueType="text"
      />
      {/* <VSTableColumn
        dataIndex={['posSearchNumber']}
        title="支付日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['prePaymentType']}
        title="预交金类型"
        valueType="select"
        dataSource={[
          { label: '门诊预交金', value: 'OUTP' },
          { label: '住院预交金', value: 'INP' },
          { label: '其他类型预交金', value: 'OTHER' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['prePaymentStatus']}
        title="支付状态"
        valueType="select"
        dataSource={[
          { label: '(收)-收预交金', value: 'CASH_IN_RECEIVE_PRE_PAYMENT' },
          { label: '(支)-退预交金', value: 'CASH_OUT_REFUND_PRE_PAYMENT' },
          { label: '(收)-退医疗款', value: 'CASH_IN_REFUND_PAYMENT' },
          { label: '(支)-支付医疗款', value: 'CASH_OUT_PAYMENT' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['prePaymentAmount']}
        title="预交金数额"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['operatorStaffId']}
        title="操作员编码"
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '操作员编码的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 200 },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['prePaymentStatusName']}
        title="业务状态"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['receiptPrintStatus']}
        title="打印状态"
        valueType="select"
        dataSource={[
          { label: '待打印', value: 'WAIT_PRINT' },
          { label: '已打印', value: 'PRINT' },
          { label: '作废', value: 'CANCEL' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['printStaffId']}
        title="打印操作员"
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['printDate']}
        title="打印日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['physicalReceiptNumber']}
        title="票据号"
        valueType="text"
        fieldProps={{}}
      /> */}
    </VSPromiseTable>
  );
};
export default definePage(prePayment);
