import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  VSTable,
  Form,
  VSTableAction,
  VSTableAddition,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2InpTransferRecordTable,
  convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo,
} from '../convert';
import { Select } from 'antd';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

import { AspirinSelect } from '@/components';
import { askSearch } from '@/pages/Index/components/func/ask';
import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelNumberWidth,
} from '@/utils';
import { dayOneMessage, dayOne } from '@/pattern';
import AspirinSelectInput from '@/components/AspirinSelectInput';
import Block from '@/pages/Index/components/block_no_padding';
import DragTableRow from '@/pages/Index/components/drag_table_row';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import IdentityDocuments from '@/pages/Index/components/identity_documents';
import Loading from '@/module/Loading';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { isMinScreen } from '@/config';

const formItemWidth = '100%';
const tempTrue = true;
const tempFalse = false;

const Index = (props) => {
  const { data, operationForm } = props;
  const { user, common } = props?.stores ?? {};
  const { initKey, currentPatient, inVisitInfo } = user ?? {};
  const { setCurrentApplication, currentApplication } = common || {};
  const [tableData, setTableData] = useState();
  // 双击table 行id
  const [currentRowId, setCurrentRowId] = useState();
  // 手术专科默认值
  const [list, setList] = useState();
  // 住院天数
  const [inHosipitalDay, setInHosipitalDay] = useState();
  // 手术名称isShow
  const [showReturnOperationName, setShowReturnOperationName] = useState();

  useEffect(() => {
    if (data?.length <= 0) {
      const _id = 'create_' + Math.random();
      setTableData([
        {
          id: _id,
          mainOperationIndicator: true,
        },
      ]);
      vsf?.refs?.operationRecordTable?.startEdit(_id);
    } else {
      const _data = data
        ?.filter((item) => {
          return item?.mainOperationIndicator;
        })
        .concat(
          data?.filter((item) => {
            return !item?.mainOperationIndicator;
          }),
        );

      setTableData(_data);
    }
    setShowReturnOperationName(
      operationForm?.returnOperationIndicator ?? false,
    );
    getDepartment();
  }, [data, operationForm]);

  useEffect(() => {
    calculateEarliestDate(data);
  }, []);

  // 手术专科dataSource
  const getDepartment = async () => {
    const res =
      await vsf?.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
        {
          nameLike: '',
          outpOrInp: '1,2',
          branchInstitutionId:
            currentApplication?.department?.branchInstitution?.id,
        },
      );
    setList(res?.data?.result ?? []);
    return res?.data?.result ?? [];
  };

  // 手术名称
  const getOperationDictionary = async (params) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
        {
          qto: {
            from: 0,
            size: 1000,
            ...params?.search,
            ...params?.filters,
            ...params?.extra,
            ...(params ?? {}),
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式

    return res?.data?.result;
  };
  // 点击行新增
  const onAddAndEdit = async (index, entity) => {
    const res = await vsf?.refs?.operationRecordTable?.saveEdit();
    const tableValue = vsf?.refs?.operationRecordTable.getValues();
    if (!res) return;
    const id = {
      id: 'create_' + Math.random(),
      mainOperationIndicator: false,
    };
    setTableData(tableValue.toSpliced(index + 1, 0, id));
    setTimeout(async () => {
      await vsf?.refs?.operationRecordTable?.startEdit(id?.id);
    }, 20);
  };
  // 点击行删除
  const onRemove = async (entity) => {
    vsf.refs.operationRecordTable.remove(entity);
  };
  // 移动
  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const res = await vsf?.refs?.operationRecordTable?.saveEdit();
      if (res) {
        const tableValue = vsf?.refs?.operationRecordTable.getValues();
        const dragRow = tableValue[dragIndex];

        setTableData(
          update(tableData, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
      }
    },
    [tableData],
  );
  // 计算手术日期最早的手术
  const calculateEarliestDate = (data) => {
    const dates = data
      ?.filter((item) => {
        return item?.operationDate;
      })
      .map((i) => i?.operationDate);
    if (dates?.length <= 0 || !operationForm?.admissionDateTime) return 0;
    // 转换日期字符串为Date对象，并找出最早的时间
    let earliestDate = new Date(dates[0]);
    for (let i = 1; i < dates.length; i++) {
      const currentDate = new Date(dates[i]);
      if (currentDate > earliestDate) {
        earliestDate = currentDate;
      }
    }
    const _date = dayjs(earliestDate).format('YYYY-MM-DD HH:mm:ss'); // 输出最早的日期时间
    const data1 = dayjs(_date).diff(operationForm?.admissionDateTime, 'day');
    vsf.refs.operationInfoForm?.setFieldsValue({
      beforeOperationDays: data1,
    });
    return data1;
  };

  const getOperationInfoForm = useCallback(
    async (params) => {
      return {
        ...operationForm,
        beforeOperationDays: operationForm?.beforeOperationDays
          ? operationForm?.beforeOperationDays
          : calculateEarliestDate(data),
      };
    },
    [operationForm],
  );

  const components = {
    body: {
      row: DragTableRow,
    },
  };

  return (
    <div className="homepageinfo_operation_info hideScroll">
      <div className="homepageinfo_operation_info_table">
        <VSTable
          editable={{
            editType: 'single',
            saveOnClickOutside: true,
            columnProps: {
              dataIndex: '__vs__table__edit',
              hideInTable: true,
              fixed: 'right',
            },
            onFieldChange: (key, value, record, form) => {
              if ('operationDescriptions' in value) {
                console.log('手术名称没有了', record, value);
                form.setFieldValue(
                  [key, 'operationCode'],
                  value?.operationDescriptions?.operationCode,
                );
                form.setFieldValue(
                  [key, ''],
                  value?.operationDescriptions?.operationInfoType,
                );
                form.setFieldValue(
                  [key, 'operationLevel'],
                  value?.operationDescriptions?.operationLevel,
                );
                form.setFieldValue([key, 'operationDescriptions'], {
                  ...value?.operationDescriptions,
                  prefix: value?.operationDescriptions?.prefix,
                  selectValue:
                    value?.operationDescriptions?.operationName ??
                    value?.operationDescriptions?.selectValue,
                  suffix: value?.operationDescriptions?.suffix,
                });
                form.setFieldValue(
                  [key, 'operationItemId'],
                  value?.operationDescriptions?.id,
                );
              }
              if ('operator' in value) {
                form.setFieldValue([key, 'department'], {
                  departmentName:
                    value?.operator?.doctorDto?.clinicDepartment
                      ?.departmentName,
                  id: value?.operator?.doctorDto?.clinicDepartment?.id,
                });
              }
              if ('operationDate' in value) {
                const tableData = vsf.refs.operationRecordTable.getValues();
                const _tableData = tableData?.map((item) => {
                  return item?.id == key
                    ? { ...item, operationDate: value?.operationDate }
                    : { ...item };
                });

                calculateEarliestDate(_tableData);
              }
            },
          }}
          rowClick={{
            rowClickType: {
              type: 'edit-save',
              action: 'double-click',
            },
          }}
          rowClassName={(v) => {
            return v.id === currentRowId
              ? 'aspirin-table-row-select-background-color'
              : '';
          }}
          onRow={(record, index) => {
            const attr = {
              index,
              moveRow,
              currentRowId,
              onClick: (e) => {
                e.stopPropagation();
                setCurrentRowId(record?.id);
              },
            };
            return attr;
          }}
          pagination={false}
          id="operationRecordTable"
          className="
                    aspirin-table-header-background-color
                   aspirin-table-row-hover-background-color
                   aspirin-table-nopadding
                   aspirin-table-body-empty-transparent-background-color
          "
          // scroll={{ x: 2500, y: getScrollY(isMinScreen ? 370 : 642) }}
          scroll={{
            x: 2500,
            y: getScrollYRem(43.5),
          }}
          value={tableData}
          onChange={setTableData}
          components={components}
        >
          <VSTableColumn
            width={180}
            dataIndex={['operationItemId']}
            title="手术名称id"
            preview
            hideInTable
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{
              width: formItemWidth,
            }}
          />
          <VSTableColumn
            width={180}
            dataIndex={['operationCode']}
            title="手术编码"
            preview
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{ width: formItemWidth }}
          />

          {/* <VSTableColumn
            width={180}
            dataIndex={['operationBodypart']}
            title="手术部位"
            valueType="select"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{
              showSearch: true,
              fieldNames: {
                label: 'label',
                value: 'value',
              },
            }}
            dataSource={vsf.stores.dbenums.enums.OPERATIVE_SITE}
          /> */}
          {/* <VSTableColumn
            width={180}
            dataIndex={['operationBodypart']}
            title="手术部位"
            valueType="aspirinSelect"
            dataSource={vsf.stores.dbenums.enums.OPERATIVE_SITE}
            renderFormItem={(schema, record) => {
              return (
                <NewSelect
                  schema={schema}
                  record={record?.record}
                  examBodyPartList={vsf.stores.dbenums.enums.OPERATIVE_SITE}
                />
              );
            }}
          /> */}
          <VSTableColumn
            width={180}
            dataIndex={['operationBodypart']}
            title="手术部位"
            valueType="aspirinSelect"
            // dataSource={vsf.stores.dbenums.enums.OPERATIVE_SITE}
            addItemOptions={{
              status: true,
              mode: 'single',
            }}
            fieldProps={{
              dataSource: vsf.stores.dbenums.enums.OPERATIVE_SITE,
              addItemOptions: { status: true, mode: 'single' },
              fieldNames: {
                label: 'label',
                value: 'value',
              },
              selectProps: {
                showSearch: true,
              },
              autoCompleteProps: {
                placeholder: '请选择/输入手术部位',
              },
              customGenObj: (node, parentData, rootData) => {
                return {
                  label: node?.label,
                  value: node?.value,
                };
              },
              showSearch: true,
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operationType']}
            title="手术类别"
            valueType="select"
            fieldProps={{
              showSearch: true,
            }}
            dataSource={[
              { label: '急诊', value: 'EMERGENCY' },
              { label: '择期', value: 'SCHEDULE' },
            ]}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operateInfoType']}
            title="手术类型"
            valueType="select"
            dataSource={[
              { label: '手术', value: 'OPERATION' },
              { label: '介入', value: 'INTERVENTIONAL' },
              { label: '诊断性操作', value: 'DIAGNOSTIC_PROCEDURE' },
              { label: '治疗性操作', value: 'THERAPEUTIC_PROCEDURE' },
            ]}
            fieldProps={{ showSearch: true }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operationDate']}
            title="手术日期"
            valueType="date"
            fieldProps={{
              format: 'YYYY-MM-DD',
              disabledDate: (current) => {
                return (
                  current &&
                  current <
                    moment(operationForm?.admissionDateTime)
                      .endOf('day')
                      .subtract(1, 'day')
                );
              },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operationLevel']}
            title="手术等级"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.OPERATION_SCALE_DICT}
            fieldProps={{ showSearch: true }}
          />

          {/* <VSTableColumn
            width={180}
            dataIndex={['operationDescription']}
            title="手术名称"
            valueType="dictionary"
            searchKey="searchInputLike"
            fieldNames={{ label: 'operationName', value: 'id' }}
            formItemProps={{
              // rules: [{ required: true }],
              allowClear: true,
            }}
            fieldProps={{
              tableClassify: 'OPERATIONLIST',
              allowClear: true,
            }}
            allowClear={true}
          /> */}
          {/* <VSTableColumn
            width={180}
            dataIndex={['operationDescription']}
            title="手术名称"
            valueType="select"
            searchKey="searchInputLike"
            fieldNames={{ label: 'operationName', value: 'id' }}
            formItemProps={{
              // rules: [{ required: true }],
              allowClear: true,
            }}
            fieldProps={{
              showSearch: true,
              dataSource: getOperationDictionary,
            }}
            allowClear={true}
          /> */}
          <VSTableColumn
            width={180}
            dataIndex={['operationDescriptions']}
            title="手术名称"
            valueType="aspirinSelectInput"
            searchKey="searchInputLike"
            fieldNames={{ label: 'operationName', value: 'id' }}
            formItemProps={{
              allowClear: true,
            }}
            fieldProps={{
              showSearch: true,
              dataSource: getOperationDictionary,
              attributeName: 'operationName',
            }}
            allowClear={true}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operator']}
            title="手术医师"
            valueType="select"
            fieldProps={{
              showSearch: true,
              fieldNames: { label: 'staffName', value: 'id' },
            }}
            searchKey="searchCode"
            dataSource={async (v) => {
              const res =
                await vsf?.services?.UserStaffController_queryOperationDoctorListBySearchCode_3a0b41?.(
                  {
                    // TODO: 请检查以下这部分传参是否符合需求
                    searchCode: v?.searchCode ?? '',
                  },
                ); // TODO: 你可能需要整理返回数据格式
              const _res = res?.data?.result?.map((item) => {
                return {
                  ...item,
                  staffName:
                    item?.staffName +
                    '-' +
                    (item?.doctorDto?.clinicDepartment?.departmentName ?? ''),
                };
              });
              return _res ?? [];
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['department']}
            title="手术专科"
            valueType="select"
            searchKey="nameLike"
            // initialValue={list}
            fieldProps={{
              showSearch: true,
              dataSource: async (params, v) => {
                const res =
                  await vsf?.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
                    {
                      nameLike: v?.nameLike ?? '',
                      outpOrInp: '1,2',
                      branchInstitutionId:
                        currentApplication?.department?.branchInstitution?.id,
                    },
                  );
                return res?.data?.result ?? [];
              },
              fieldNames: { label: 'departmentName', value: 'id' },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['ward']}
            title="手术病区"
            valueType="select"
            searchKey="nameLike"
            fieldProps={{
              showSearch: true,
              fieldNames: { label: 'departmentName', value: 'id' },
              dataSource: async (params, v) => {
                const res =
                  await vsf?.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
                    {
                      nameLike: v?.nameLike ?? '',
                      outpOrInp: '1,2',
                      branchInstitutionId:
                        currentApplication?.department?.branchInstitution?.id,
                      clinicAttribute: '2',
                    },
                  );
                return res?.data?.result ?? [];
              },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['firstAssistant']}
            title="I助"
            valueType="select"
            searchKey="searchCode"
            fieldProps={{
              showSearch: true,
              dataSource: async (params, v) => {
                const res =
                  await vsf?.services?.UserStaffController_queryOperationDoctorListBySearchCode_3a0b41?.(
                    {
                      searchCode: params?.searchCode ?? '',
                    },
                  );
                const _res = res?.data?.result?.map((item) => {
                  return {
                    ...item,
                    staffName:
                      item?.staffName +
                      '-' +
                      (item?.doctorDto?.clinicDepartment?.departmentName ?? ''),
                  };
                });
                return _res ?? [];
              },
              fieldNames: { label: 'staffName', value: 'id' },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['secondAssistant']}
            title="II助"
            valueType="select"
            searchKey="searchCode"
            fieldProps={{
              showSearch: true,
              fieldNames: { label: 'staffName', value: 'id' },
              dataSource: async (params, v) => {
                const res =
                  await vsf?.services?.UserStaffController_queryOperationDoctorListBySearchCode_3a0b41?.(
                    {
                      searchCode: params?.searchCode ?? '',
                    },
                  );
                const _res = res?.data?.result?.map((item) => {
                  return {
                    ...item,
                    staffName:
                      item?.staffName +
                      '-' +
                      (item?.doctorDto?.clinicDepartment?.departmentName ?? ''),
                  };
                });
                return _res ?? [];
              },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['incisionLevelCode']}
            title="切口等级"
            valueType="select"
            fieldProps={{
              showSearch: true,
            }}
            dataSource={vsf.stores.dbenums?.enums?.WOUND_GRADE_DICT}
            renderFormItem={() => (
              <Select
                showSearch
                options={vsf.stores.dbenums?.enums?.WOUND_GRADE_DICT}
                filterOption={askSearch}
              />
            )}
          />

          <VSTableColumn
            width={180}
            dataIndex={['incisionCure']}
            title="切口愈合情况"
            valueType="select"
            fieldProps={{
              showSearch: true,
            }}
            dataSource={vsf.stores.dbenums?.enums?.HEAL_DICT}
            renderFormItem={() => (
              <Select
                showSearch
                options={vsf.stores.dbenums?.enums?.HEAL_DICT}
                filterOption={askSearch}
              />
            )}
          />

          <VSTableColumn
            width={180}
            dataIndex={['anesthesiaMethod']}
            title="麻醉方式"
            valueType="select"
            fieldProps={{
              showSearch: true,
            }}
            dataSource={vsf.stores.dbenums?.enums?.ANAESTHESIA_DICT}
            renderFormItem={() => (
              <Select
                showSearch
                options={vsf.stores.dbenums?.enums?.ANAESTHESIA_DICT}
                filterOption={askSearch}
              />
            )}
          />

          <VSTableColumn
            width={180}
            dataIndex={['asaLevel']}
            title="ASA麻醉分级"
            valueType="select"
            dataSource={[
              { label: 'ASA P1级', value: 'P_1' },
              { label: 'ASA P2级', value: 'P_2' },
              { label: 'ASA P3级', value: 'P_3' },
              { label: 'ASA P4级', value: 'P_4' },
              { label: 'ASA P5级', value: 'P_5' },
              { label: 'ASA P6级', value: 'P_6' },
            ]}
            fieldProps={{ showSearch: true }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['anesthesiaDoctor']}
            title="麻醉医师"
            valueType="select"
            searchKey="searchCode"
            fieldProps={{
              showSearch: true,
              fieldNames: { label: 'staffName', value: 'id' },
              dataSource: async (v) => {
                const res =
                  await vsf?.services?.UserStaffController_queryAnesthesiaDoctorListBySearchCode_0f5004?.(
                    {
                      searchCode: v?.searchCode ?? '',
                    },
                  );
                const _res = res?.data?.result?.map((item) => {
                  return {
                    ...item,
                    staffName:
                      item?.staffName +
                      '-' +
                      (item?.doctorDto?.clinicDepartment?.departmentName ?? ''),
                  };
                });
                return _res ?? [];
              },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['recoveryRoomDoctor']}
            title="复苏室医师"
            valueType="select"
            searchKey="searchCode"
            fieldProps={{
              showSearch: true,
              dataSource: async (v) => {
                //
                // Tips: 以下是你选择的请求函数
                const res =
                  await vsf?.services?.UserStaffController_queryAnesthesiaDoctorListBySearchCode_0f5004?.(
                    {
                      // TODO: 请检查以下这部分传参是否符合需求
                      searchCode: v?.searchCode ?? '',
                    },
                  ); // TODO: 你可能需要整理返回数据格式
                const _res = res?.data?.result?.map((item) => {
                  return {
                    ...item,
                    staffName:
                      item?.staffName +
                      '-' +
                      (item?.doctorDto?.clinicDepartment?.departmentName ?? ''),
                  };
                });
                return _res ?? [];
              },
              fieldNames: { label: 'staffName', value: 'id' },
            }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['analgesiaIndicator']}
            title="是否镇痛"
            valueType="select"
            fieldProps={{
              showSearch: true,
            }}
            dataSource={[
              { label: '是', value: tempTrue },
              { label: '否', value: tempFalse },
            ]}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operationRecoveryScore']}
            title="苏醒评分"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            width={180}
            dataIndex={['analgesiaMethod']}
            title="镇痛方式"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            width={180}
            dataIndex={['nnisLevel']}
            title="NNIS分级"
            valueType="select"
            dataSource={[
              { label: '0级', value: 'LEVEL_ZERO' },
              { label: '1级', value: 'LEVEL_ONE' },
              { label: '2级', value: 'LEVEL_TWO' },
              { label: '3级', value: 'LEVEL_THREE' },
            ]}
            fieldProps={{ showSearch: true }}
          />

          <VSTableColumn
            width={180}
            dataIndex={['operationDuration']}
            title="手术持续时间"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            width={180}
            dataIndex={['mainOperationIndicator']}
            title="是否主要手术"
            valueType="select"
            fieldProps={{}}
            preview
            dataSource={[
              { label: '是', value: tempTrue },
              { label: '否', value: tempFalse },
            ]}
          />

          <VSTableColumn
            width={180}
            dataIndex={['notPlanOperationAgainIndicator']}
            title="是否非计划再次手术"
            valueType="select"
            fieldProps={{ showSearch: true }}
            dataSource={[
              { label: '是', value: tempTrue },
              { label: '否', value: tempFalse },
            ]}
          />
          <VSTableColumn
            dataIndex={['operation']}
            width={100}
            title="操作"
            fixed="right"
            renderFormItem={(dom, entity, form) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 10,
                  }}
                >
                  <Icon
                    type="icon-tianjia1"
                    style={{ color: '#3276e8' }}
                    size={18}
                    onClick={(event) => {
                      event.stopPropagation();
                      onAddAndEdit(dom?.index, entity?.record);
                    }}
                  />

                  <Icon
                    type="icon-wudishanchu"
                    size={18}
                    onClick={(event) => {
                      event.stopPropagation();
                      /**
                       * 如果只有一条数据，点击第一行只清空数据，不删除行isDelete为true，不是第一行
                       */
                      if (tableData?.length == 1 && dom?.index == 0) {
                        // form?.resetFields();
                        form?.setFieldValue([entity?.record?.id], null);
                      } else {
                        onRemove(entity?.record);
                        // form?.setFieldValue([entity?.record?.id], null);
                      }
                    }}
                  />
                </div>
              );
            }}
            render={(_, value, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 10,
                  }}
                >
                  <Icon
                    type="icon-tianjia1"
                    style={{ color: '#3276e8' }}
                    size={18}
                    onClick={(event) => {
                      event.stopPropagation();
                      onAddAndEdit(index, value);
                    }}
                  />

                  <Icon
                    type="icon-wudishanchu"
                    size={18}
                    onClick={(event) => {
                      event.stopPropagation();

                      /**
                       * 如果只有一条数据，点击第一行只清空数据，不删除行isDelete为true，不是第一行
                       */
                      if (tableData?.length == 1 && index == 0) {
                        // vsf?.refs?.operationRecordTable?.startEdit(value?.id);
                        // vsf?.refs?.operationRecordTable
                        //   ?.getEditForm()
                        //   ?.resetFields();
                        setTableData([
                          {
                            id: value?.id,
                            mainOperationIndicator: true,
                          },
                        ]);
                      } else {
                        onRemove(value);
                      }
                    }}
                  />
                </div>
              );
            }}
          ></VSTableColumn>
        </VSTable>
      </div>
      <div className="homepageinfo_operation_info_form">
        <VSForm
          vsid="91613"
          id="operationInfoForm"
          labelAlign="left"
          labelWrap
          onFetch={getOperationInfoForm}
        >
          {/* <VSFormLayout key="0" columnCount={3} labelCol={labelColWidth[8]}> */}
          <VSFormLayout key="0" columnCount={3} labelCol={labelNumberWidth(12)}>
            <VSFormItem
              name={['dayOperationIndicator']}
              label="是否日间手术"
              valueType="select"
              fieldProps={{ showSearch: true }}
              dataSource={[
                { label: '是', value: tempTrue },
                { label: '否', value: tempFalse },
              ]}
            />
            <VSFormItem
              name={['beforeOperationDays']}
              label="术前住院天数"
              valueType="text"
              // initialValue={calculateEarliestDate(data)}
              rules={[
                {
                  pattern: dayOne,
                  message: dayOneMessage,
                },
              ]}
              fieldProps={{
                addonAfter: (
                  <div
                    style={{
                      // fontSize: 14,
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    天
                  </div>
                ),
              }}
            />
            <VSFormItem
              name={['afterOperationDiagnosisCorrespond']}
              label="手术前后诊断符合情况"
              valueType="select"
              dataSource={[
                { label: '未做', value: 'WAIT_HANDLE' },
                { label: '符合', value: 'CORRESPOND' },
                { label: '不符合', value: 'NOT_CORRESPOND' },
                { label: '不肯定', value: 'UNKNOWN' },
              ]}
              fieldProps={{ showSearch: true }}
            />
            <VSFormItem
              name={['typeOfOperationPatient']}
              label="手术患者类型"
              valueType="select"
              dataSource={[
                { label: '急诊', value: 'EMERGENCY' },
                { label: '择期', value: 'SCHEDULE' },
              ]}
              fieldProps={{ showSearch: true }}
            />
            <VSFormItem
              name={['selectiveOperationConcurrence']}
              label="择期手术并发"
              valueType="select"
              fieldProps={{ showSearch: true }}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
            <VSFormItem
              name={['selectiveOperationComplicationId']}
              label="择期手术并发症类型"
              valueType="custom"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ]}
              fieldProps={{ showSearch: true }}
              // dataSource={vsf.stores.dbenums?.enums?.COM_OF_ELECTIVE_OP_LIST}
            >
              <Select
                options={vsf.stores.dbenums?.enums?.COM_OF_ELECTIVE_OP_LIST}
                showSearch
                placeholder="请选择择期手术并发症类型"
                filterOption={askSearch}
              />
            </VSFormItem>
            <VSFormItem
              name={['otherComplicationName']}
              label="其他手术或操作的并发症名称"
              valueType="select"
              fieldNames={{ label: 'diagnosisName', value: 'id' }}
              searchKey="searchInputLike"
              fieldProps={{
                showSearch: true,

                dataSource: async (params, v) => {
                  const res =
                    await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
                      {
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 2000,
                          orderList: params?.orderList,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                          ...v,
                        },
                        ext: {},
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result;
                },
              }}
            />
            <VSFormItem
              name={['returnOperationIndicator']}
              label="手术重返"
              valueType="select"
              fieldProps={{
                width: formItemWidth,
                showSearch: true,
                onChange: (value) => {
                  setShowReturnOperationName(value);
                },
              }}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
            {/* {showReturnOperationName && (
              <VSFormItem
                width={formItemWidth}
                name={['returnOperationName']}
                label="手术名称"
                valueType="aspirinSelectInput"
                // rules={[
                //   { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                // ]}
                // fieldProps={{ showSearch: true, width: formItemWidth }}
                fieldNames={{ label: 'operationName', value: 'id' }}
                fieldProps={{
                  allowClear: true,
                  showSearch: true,
                  attributeName: 'operationName',
                }}
                dataSource={getOperationDictionary}
              />
            )} */}
            {showReturnOperationName && (
              <VSFormItem
                width={formItemWidth}
                name={['returnOperationName']}
                label="手术名称"
                valueType="select"
                // rules={[
                //   { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                // ]}
                // fieldProps={{ showSearch: true, width: formItemWidth }}
                fieldNames={{ label: 'operationName', value: 'id' }}
                fieldProps={{
                  allowClear: true,
                  showSearch: true,
                }}
                searchKey="searchInputLike"
                dataSource={getOperationDictionary}
                // transform={(v) => {
                //   console.log(v);
                //   return {
                //     returnOperationName: v?.operationName,
                //     returnOperationId: v?.id,
                //   };
                // }}
              />
            )}
            <VSFormItem
              name={['foreignObjectLeaveInOperation']}
              label="手术过程中异物遗留"
              valueType="select"
              fieldProps={{ showSearch: true, width: formItemWidth }}
              initialValue={tempFalse}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
            <VSFormItem
              name={['iatrogenicPneumothoraxIndicator']}
              label="医源性气胸"
              valueType="select"
              fieldProps={{ showSearch: true, width: formItemWidth }}
              initialValue={tempFalse}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
            <VSFormItem
              name={['iatrogenicAccidentIndicator']}
              label="医源性意外(穿刺伤或撕裂)"
              valueType="select"
              fieldProps={{
                showSearch: true,
                width: '100%',
              }}
              initialValue={tempFalse}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
          </VSFormLayout>
        </VSForm>
      </div>
    </div>
  );
};
export default definePage(Index);
