import vsf from '@vs/vsf-boot';
import { Form, Select } from '@vs/vsf-kit';
import { useMount } from 'ahooks';
import React, { useRef } from 'react';

import { AspirinSelect } from '@/components';
import { askSearch } from '@/pages/Index/components/func/ask';
import { useFrequencyEffect } from '@/pages/Index/components/func/hooks';

const Index = (props) => {
  const {
    value = {
      operation: void 0,
      operationId: void 0,
      incisionLevelCode: void 0,
      operationLevel: void 0,
      operationBodypart: void 0,
      operationBodypartName: void 0,
    },
    onChange,
  } = props;
  const handleOperationChange = (target) => {
    const { operation, operationId, incisionLevelCode, operationLevel } =
      target;
    onChange?.({
      operationBodypart: void 0,
      ...value,
      operation,
      operationId,
      incisionLevelCode: incisionLevelCode ?? '',
      operationLevel: operationLevel ?? '',
    });
  };

  const twoHandDataSource = vsf.stores.dbenums.enums.OPERATIVE_SITE;
  const form = Form?.useFormInstance();
  const operationDetailList = Form?.useWatch('operationDetailList', form);

  const handleOperationBodypartChange = (target) => {
    const data = Array.isArray(target) ? target[0] : target;
    const name =
      twoHandDataSource?.find((item) => item?.value == data)?.label ?? '';

    onChange?.({
      ...value,
      operation: value?.operation ?? void 0,
      operationId: value?.operationId ?? void 0,
      operationBodypart: data || '',
      operationBodypartName: name ?? data ?? '',
    });
  };

  const options = async (value) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
        {
          qto: {
            from: 0,
            size: 20,
            ...value,
          },
          ext: {},
        },
      );
    return res?.data?.result
      ?.filter((item) => {
        return !operationDetailList
          ?.map((item) => item?.operationId)
          .includes(item?.id);
      })
      ?.map((item) => ({
        ...item,
        operation: item?.operationName,
        operationId: item?.id,
      }));
  };
  const select = useRef<any>(null);

  useMount(() => {
    if (!value?.operationBodypartName && value?.operationBodypart) {
      handleOperationBodypartChange?.(value?.operationBodypart);
    }
  });
  console.log('twoHandDataSource', twoHandDataSource);

  return (
    <div className="cpoe-operation-select">
      <Select
        value={value?.operationId ? value : void 0}
        onChange={handleOperationChange}
        dataSource={options}
        ref={select}
        searchKey="searchInputLike"
        showSearch
        placeholder="请输入手术名称/首字母搜索"
        fieldNames={{
          label: 'operation',
          value: 'operationId',
        }}
      />
      <AspirinSelect
        value={value?.operationBodypart ?? void 0}
        onChange={handleOperationBodypartChange}
        dataSource={twoHandDataSource}
        filterOption={askSearch}
        autoCompleteProps={{
          showSearch: true,
          placeholder: '请选择或输入手术部位',
        }}
        addItemOptions={{
          status: true,
          mode: 'single',
        }}
      />
      <Select
        value={value?.incisionLevelCode}
        disabled
        dataSource={vsf.stores.dbenums.enums.WOUND_GRADE_DICT}
        placeholder="手术切口等级"
      />
      <Select
        value={value?.operationLevel}
        disabled
        dataSource={vsf.stores.dbenums.enums.OPERATION_SCALE_DICT}
        placeholder="手术等级"
      />
    </div>
  );
};

export default Index;
