import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_getWardWaitDispWithDischargePatientCount_45050c:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-ward-wait-disp-with-discharge-patient-count',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: WaitDispenseAuditPatientCountGroupWardEoDrugDispenseAuditPersistEo[],
      ) => data,
    },
});
