import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListController_getPriceItemAdjust_3924d0: {
    method: 'post',
    url: '/api/price-list/get-price-item-adjust',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PriceItemAdjustVoPriceListEntranceWebVo[],
    ) => data,
  },
  PriceListController_savePriceItemAdjust_9c56eb: {
    method: 'post',
    url: '/api/price-list/save-price-item-adjust',
    parameterFomatter: (data?: {
      btoList: AdjustPriceItemBtoPriceListServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
