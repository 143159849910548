import {
  Button,
  Input,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useState } from 'react';

import Icon from '@/module/Icon';

export default function RoleEditableTable(props) {
  const { children, index = 0 } = props;

  return (
    <VSTable
      className="aspirin-table aspirin-table-nopadding"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" />,
        deleteText: <Icon type="icon-shanchu1" />,
        onCanDelete: (v: any) => {
          console.log(v);
          return !(v?.staffRole == '01');
        },
      }}
      onRecord={async () => ({ id: Math.random() })}
      value={props.value}
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
      pagination={false}
    >
      <VSTableColumn title="序号" valueType="index" fieldProps={{}} />
      {children.map((item) => {
        return (
          <VSTableColumn
            dataIndex={[...item.props.name]}
            title={item.props.label}
            valueType={item.props?.valueType}
            key={item.props.label}
            {...item.props}
            formItemProps={{ rules: item?.props?.rules }}
          />
        );
      })}

      <VSTableAddition type="primary" children="添加" />
    </VSTable>
  );
}
