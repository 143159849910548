import { createServices } from '@vs/vsf-boot';
export default createServices({
  UserGroupController_createChargeGroup_e98873: {
    method: 'post',
    url: '/api/user-group/create-charge-group',
    parameterFomatter: (data?: {
      createChargeGroupBvo: CreateChargeGroupBvoUserGroupEntranceWebBvo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupDetailVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserGroupController_deleteChargeGroupById_536d1f: {
    method: 'post',
    url: '/api/user-group/delete-charge-group-by-id',
    parameterFomatter: (data?: { chargeGroupId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserGroupController_getChargeGroupList_7ffb2f: {
    method: 'post',
    url: '/api/user-group/get-charge-group-list',
    parameterFomatter: (data?: {
      qto: ChargeGroupDetailQtoUserGroupPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupDetailVoUserGroupEntranceWebVo[],
    ) => data,
  },
  UserGroupController_updateChargeGroup_6414ca: {
    method: 'post',
    url: '/api/user-group/update-charge-group',
    parameterFomatter: (data?: {
      btoParam: UpdateChargeGroupBtoUserGroupServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupDetailVoUserGroupEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoUserGroupEntranceWebVoChargegroupdetailvo,
    ) => data,
  },
});
