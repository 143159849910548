import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getDrugExportMasterList_0cb031: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-drug-export-maste-list',
    parameterFomatter: (data?: {
      qto: DrugExportMasterQtoDrugStorageImportExportPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterListVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
});
