import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Drawer,
  Input,
  message,
  Modal,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { isMinScreen } from '@/config';
import Icon from '@/module/cpoe/Icon';
import Icon1 from '@/module/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import TabCheck from '@/pages/Index/components/tab_check';
import { getScrollY } from '@/utils';

import Add from './charge_template';

const tabItems = [
  { label: '全部收费', value: 'allPrice', key: 'allPrice' },
  { label: '医嘱收费', value: 'orderPrice', key: 'orderPrice' },
];

const data = Array.from({ length: 20 })
  .fill({
    label: '24小时尿蛋白定量',
  })
  .map((item: any, index) => ({
    label: `${item?.assayName}${index}`,
    value: index,
  }));

const Content = (props) => {
  const { onSubmit, currentTab, open, onClose, defaultValue } = props;
  const { user, common } = vsf?.stores || {};
  const { currentApplication } = common || {};
  const { hospitalType, outVisitInfo } = user || {};
  // 是否用模板
  // const [isTemplate, setIstemplate] = useState(false);
  const isTemplate = useRef(false);
  // 全部收费
  const [allOrderTableList, setAllOrderTableList] = useState<any>();
  //医嘱收费-列表-行id
  const [currentRowId, setCurrentRowId] = useState<number>();
  const [currentRowPerformDepartmentName, setCurrentRowPerformDepartmentName] =
    useState<any>();
  //医嘱收费明细
  const [orderDetailTableList, setOrderDetailTableList] = useState<any>();
  //医嘱收费明细-选择行
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // 模板select
  const [
    selectedRowsTemplate,
    setSelectedRowsTemplate,
    getSelectedRowTemplate,
  ] = useGetState<any>();

  const [items, setItems] = useState([]);
  const tableRef = useRef<any>();
  const selectedRowsTemplateRef = useRef<any>();

  const showFooter = currentTab === 'allPrice' ? 'none' : 'block';

  // 全部收费
  const getAllOrderTableList = useCallback(async () => {
    if (!outVisitInfo?.id || currentTab !== 'allPrice') return;
    const res =
      await vsf.services?.DepartmentPriceController_getDepartmentPrice_78944b?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          outpVisitEncounterId: outVisitInfo?.id,
          // outpVisitEncounterId: 103,
          // orderId: params?.orderId,
        },
      );
    // 医嘱相同时，医嘱名只显示第一个
    const formatData = res?.data?.map((item, index, self) => {
      if (self?.length > 1 && index !== 0) {
        if (item?.orderId == self?.[index - 1]?.orderId) {
          return { ...item, orderName: '', submitDateTime: '', orderClass: '' };
        } else {
          return { ...item };
        }
      } else {
        return { ...item };
      }
    });
    console.log(formatData, '=======');

    setAllOrderTableList(formatData ?? []);
    return res?.data ?? [];
  }, [outVisitInfo, currentTab]);

  useEffect(() => {
    getAllOrderTableList();
  }, [getAllOrderTableList, currentTab]);

  // 医嘱收费-医嘱列表
  const getOrderTableList = useCallback(async () => {
    if (!outVisitInfo?.id || currentTab === 'allPrice') return;
    const res =
      await vsf.services?.DepartmentPriceController_getOrderWithDoctorByOutpVisitEncounterId_52cfba?.(
        {
          outpVisitEncounterId: outVisitInfo?.id,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    if (res?.code === 200) {
      setCurrentRowId(res?.data?.[0]?.id);
      setCurrentRowPerformDepartmentName({
        departmentName: res?.data?.[0]?.performDepartmentName,
        id: res?.data?.[0]?.performDepartmentId,
      });
    }
    return res?.data;
  }, [outVisitInfo, currentTab]);

  // 医嘱收费-医嘱明细列表
  const getDepartmentPrice = useCallback(async () => {
    if (!currentRowId) return [];
    const res =
      await vsf.services?.DepartmentPriceController_getDepartmentPrice_78944b?.(
        {
          outpVisitEncounterId: outVisitInfo?.id,
          orderId: currentRowId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    setOrderDetailTableList(res?.data?.result ?? res?.data ?? []);
    return {
      data: res?.data?.map((item) => {
        return {
          ...item,
          btnType: item?.tallyIndicator ? 'change' : 'cancel',
          // orderDepartmentName: {
          //   orderDepartmentName: item?.orderDepartmentName,
          //   id: item?.orderDepartmentId,
          // },
          performDepartmentName: {
            performDepartmentName: item?.performDepartmentName,
            id: item?.performDepartmentId,
          },
        };
      }),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [currentRowId, outVisitInfo]);

  useEffect(() => {
    getDepartmentPrice();
  }, [getDepartmentPrice]);

  // vsf?.refs?.orderPriceMainDetailTable?.saveEdit();
  // useCallback(() => {
  //   getAllOrderTableDetailList();
  //   console.log(currentRowId);
  // }, [currentRowId, getAllOrderTableDetailList]);

  // 医嘱收费-医嘱明细

  // 保存/未保存时删除
  const handleDeleteSaveItem = async (params) => {
    const regexp = /^temp/;
    const _res = regexp.test(params?.id);
    if (_res) {
      tableRef?.current?.remove(params);
    } else {
      Modal.confirm({
        title: '提示',
        // TipIcon: null,
        // icon: null,
        // closeIcon: <Icon type="icon-shanchu1" />,
        centered: true,
        content: '确定删除该条明细吗?',
        onOk: async () => {
          const res =
            await vsf.services?.DepartmentPriceController_deleteOutpOrderCost_b94210?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: { id: params?.id },
                extApiParam: {},
              },
            ); // Tips: 需要返回删除成功与否
          if (res.code === 200) {
            message.info('删除成功');
            getDepartmentPrice();
            vsf?.refs?.orderPriceMainDetailTable?.reload();
          }
        },
        onCancel() {},
        okText: '确定',
        cancelText: '取消',
        width: 520,
      });
    }
  };

  // 保存
  const save = async () => {
    const res = await tableRef?.current?.saveEdit();
    if (!res) return;
    Modal.confirm({
      title: '提示',
      centered: true,
      content: '确定保存吗?',
      onOk: async () => {
        const res = await tableRef?.current?.saveEdit();
        const addList = tableRef?.current.getValues();
        const regexp = /^temp/;
        const _addList = addList?.filter((item) => {
          return regexp.test(item?.id);
        });
        const a = addList?.filter((i) => {
          return (
            i?.source == 'DEPARTMENT_PRICE' && i?.chargeStatus == 'NOT_CHARGE'
          );
        });
        const _params = _addList.map((item) => {
          const {
            id,
            source,
            medicalInsuranceType,
            createdAt,
            updatedAt,
            deletedAt,
            ..._item
          } = item;
          console.log(item, _item, _addList);

          return regexp.test(item?.id)
            ? {
                ..._item,
                orderId: currentRowId,
                performDepartmentId: _item?.performDepartmentName?.id,
              }
            : { ...item, orderId: currentRowId };
        });

        if (res) {
          const response =
            await vsf.services?.DepartmentPriceController_createOutpOrderCost_4229c3?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                list: [..._params, ...a],
              },
            ); // Tips: 需要返回完整数据结构，和该组件RO一致
          if (response?.code === 200) {
            message.success('保存成功');
            getDepartmentPrice();
            vsf?.refs?.orderPriceMainDetailTable?.reload();
          } else {
            message.success(res?.message);
          }
        }
      },
      onCancel() {},
      okText: '确定',
      cancelText: '取消',
      width: 520,
    });
  };
  // 取消托收
  const handelCancelTally = async () => {
    const selectedRows = tableRef.current?.getSelectedRowValues();
    console.log(
      selectedRows,
      tableRef.current?.getSelectedRowValues(),
      'selectedRowsselectedRows',
    );
    Modal.confirm({
      title: '提示',
      centered: true,
      content: '确定要取消托收吗?',
      onOk: async () => {
        const _params = selectedRows.map((item) => {
          return item?.id;
        });
        const res =
          await vsf.services?.OutpBillingController_cancelTally_99dec3?.({
            // TODO: 请检查以下这部分传参是否符合需求
            outpOrderCostId: _params,
          }); // TODO: 你可能需要整理返回数据格式
        if (res.code === 200) {
          message.info('取消托收成功');
          getDepartmentPrice();
          vsf?.refs?.orderPriceMainDetailTable?.reload();
        }
        return res?.data;
      },
      onCancel() {},
      okText: '确定',
      cancelText: '取消',
      width: 520,
    });
  };

  // 托收
  const handelChangeTally = async () => {
    const selectedRows = tableRef.current?.getSelectedRowValues();
    console.log(selectedRows, tableRef.current?.getSelectedRowValues());
    Modal.confirm({
      title: '提示',
      centered: true,
      content: '确定要转为托收项目吗?',
      onOk: async () => {
        const _params = selectedRows.map((item) => {
          return item?.id;
        });
        const res =
          await vsf.services?.OutpBillingController_changeTally_6a9877?.({
            // TODO: 请检查以下这部分传参是否符合需求
            outpOrderCostId: _params,
          }); // TODO: 你可能需要整理返回数据格式
        if (res.code === 200) {
          message.info('托收成功');
          getDepartmentPrice();
          vsf?.refs?.orderPriceMainDetailTable?.reload();
        }
        return res?.data;
      },
      onCancel() {},
      okText: '确定',
      cancelText: '取消',
      width: 520,
    });
  };

  // 切换费别
  const handeChangeMedicalInsuranceType = async () => {
    const selectedRows = tableRef.current?.getSelectedRowValues();
    // console.log(selectedRows, 'ppp');
    if (selectedRows?.length > 1 || selectedRows?.length === 0) {
      return message.info('请选择一条费用明细进行费别切换');
    }
    const res =
      await vsf.services?.DepartmentPriceController_changeMedicalInsuranceType_88cd3f?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          id: selectedRows?.[0]?.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      message.info('费别切换成功');
      getDepartmentPrice();
      vsf?.refs?.orderPriceMainDetailTable?.reload();
    }
    return res?.data;
  };
  // 费用明细-开单科室-科室计价下拉
  const handelList = async (v) => {
    const res =
      await vsf.services.DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de(
        { name: v?.departmentName },
      );
    return res?.data;
  };

  const getTemplateValue = (v) => {
    console.log(v, 'ppp接收的值');
    const _v = v?.map((item, index) => {
      const _id = `temp${Math.random()}`;
      return { ...item?.pricePatternDetailVo, ...item?.priceItemVo, id: _id };
    });
    // console.log(_vv, 'vv_vv');

    // const _v = { ...v?.pricePatternDetailVo, ...v?.priceItemVo, id: _id };
    // const { id, ...params } = _v;
    console.log(v.current?.getSelectedRowValues(), '_cddddddd', _v);
    selectedRowsTemplateRef.current = _v;
    // setSelectedRowsTemplate(_v);
  };

  const renderAllPriceMain = () => {
    return (
      <div className="allPrice-main">
        <div className="allPrice-main_table">
          <VSPromiseTable
            editable={{
              editType: 'single',
              columnProps: {
                fixed: 'right',
                width: 100,
                hideInTable: true,
              },
            }}
            bordered={true}
            style={{ width: 0 }}
            onFetch={() => {
              return allOrderTableList;
            }}
            scroll={{ y: 0, x: 1600 }}
            pagination={false}
            className={
              isMinScreen ? 'table_overflow_210' : 'table_overflow_240'
            }
          >
            <VSTableColumn
              dataIndex={['submitDateTime']}
              title="开立时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              width={150}
              render={(_, v: any) => {
                return v?.submitDateTime ? v?.submitDateTime : <div></div>;
              }}
            />
            <VSTableColumn
              dataIndex={['orderClass']}
              title="医嘱类别"
              valueType="select"
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
                { label: '病理', value: 'PATHOLOGY' },
              ]}
              fieldProps={{}}
              width={80}
            />
            <VSTableColumn
              dataIndex={['orderName']}
              title="医嘱"
              valueType="text"
              fieldProps={{}}
              width={250}
            />
            <VSTableColumn
              dataIndex={['doctorName']}
              title="开立医生"
              valueType="text"
              fieldProps={{}}
              width={80}
            />
            <VSTableColumn
              dataIndex={['orderOrderDepartmentName']}
              title="开立科室"
              valueType="text"
              fieldProps={{}}
              width={120}
            />
            <VSTableColumn
              dataIndex={['chargeStatus']}
              title="状态"
              valueType="select"
              dataSource={[
                { label: '已收费', value: 'FINISH_CHARGE' },
                { label: '未收费', value: 'NOT_CHARGE' },
                { label: '托收', value: 'TALLY' },
              ]}
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['itemClass']}
              title="项目类别"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT}
              fieldProps={{}}
              width={100}
              render={(dom) => {
                return <div style={{ color: '#3276E8' }}>{dom}</div>;
              }}
            />
            <VSTableColumn
              dataIndex={['priceItemName']}
              title="项目名称"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 200 },
                ],
              }}
              fieldProps={{}}
              width={150}
            />
            <VSTableColumn
              dataIndex={['priceItemSpecification']}
              title="规格"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              valueType="digit"
              formItemProps={{
                rules: [
                  {
                    message: '价格的值不合法',
                    validator: (_, value) => {
                      if (value === undefined) return Promise.resolve();
                      if ((true && value >= 0) || (!true && value > 0)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('价格的值不合法'));
                    },
                  },
                ],
              }}
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['amount']}
              title="数量"
              valueType="digit"
              fieldProps={{}}
              width={60}
            />

            <VSTableColumn
              dataIndex={['charge']}
              title="已收金额"
              valueType="digit"
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['cost']}
              title="计价金额"
              valueType="digit"
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['orderDepartmentName']}
              title="开单科室"
              valueType="text"
              fieldProps={{}}
              width={120}
            />
            <VSTableColumn
              dataIndex={['performDepartmentName']}
              title="执行科室"
              valueType="text"
              fieldProps={{}}
              width={120}
            />
            <VSTableColumn
              dataIndex={['tallyIndicator']}
              title="托收"
              valueType="switch"
              fieldProps={{}}
              width={60}
            />
            <VSTableColumn
              dataIndex={['medicalInsuranceType']}
              title="医保"
              valueType="select"
              dataSource={[
                { label: '甲', value: 'ALPHA' },
                { label: '乙', value: 'BETA' },
                { label: '丙', value: 'GAMMA' },
              ]}
              fieldProps={{}}
              width={100}
            />
            <VSTableColumn
              dataIndex={['orderDate']}
              title="业务时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
              width={140}
            />
            <VSTableColumn
              dataIndex={['createDate']}
              title="录入时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
              width={140}
            />
            <VSTableColumn
              dataIndex={['operatorName']}
              title="录入人"
              valueType="text"
              fieldProps={{}}
              width={100}
              fixed="right"
            />
          </VSPromiseTable>
        </div>
      </div>
    );
  };

  const renderOrderPriceMain = () => {
    return (
      <div className="orderPrice-main">
        <div className="orderPrice-main_list">
          <VSPromiseTable
            // ref={tableRef}
            className="table_overflow_700 aspirin-table aspirin-table-nopadding aspirin-table-row-hover-background-color"
            id="orderPriceMainListTable"
            scroll={{
              y: 0,
              // x: 1538,
              // y: getScrollY(650),
            }}
            bordered={true}
            onFetch={getOrderTableList}
            pagination={false}
            form={{ labelAlign: 'left' }}
            rowClassName={(v) =>
              v.id === currentRowId
                ? 'aspirin-table-row-select-background-color'
                : ''
            }
            onRow={(record) => {
              return {
                // onDoubleClick: () => {
                //   onDropdownOpen();
                //   setPatientId(record);
                //   handleSelect(record);
                // },
                onClick: () => {
                  setCurrentRowId(record?.id);
                  setSelectedRows([]);
                  setCurrentRowPerformDepartmentName({
                    departmentName: record?.performDepartmentName,
                    id: record?.performDepartmentId,
                  });
                  // handleSelect(record);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['submitDateTime']}
              title="开立时间"
              valueType="text"
              dataSource={vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['orderClass']}
              title="类别"
              valueType="select"
              dataSource={[
                { label: '西药', value: 'DRUG' },
                { label: '中药', value: 'HERB' },
                { label: '检验', value: 'LAB' },
                { label: '检查', value: 'EXAM' },
                { label: '治疗', value: 'TREAT' },
                { label: '手术', value: 'OPERATION' },
                { label: '麻醉', value: 'ANESTHESIA' },
                { label: '护理', value: 'NURSING' },
                { label: '膳食', value: 'MEAL' },
                { label: '营养', value: 'NUTRITION' },
                { label: '会诊', value: 'CONSULTATION' },
                { label: '用血', value: 'BLOOD' },
                { label: '其他', value: 'OTHER' },
                { label: '病理', value: 'PATHOLOGY' },
              ]}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 1000,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['doctor', 'staffName']}
              title="开单医师"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['performDepartmentName']}
              title="执行科室"
              valueType="text"
              fieldProps={{}}
            />
          </VSPromiseTable>
        </div>
        <div className="orderPrice-main_detail">
          {/* tableRef.current?.getSelectedRowValues() */}
          <VSPromiseTable
            ref={tableRef}
            id="orderPriceMainDetailTable"
            className="table_overflow_730 aspirin-table aspirin-table-nopadding aspirin-table-row-hover-background-color"
            scroll={{
              // y: getScrollY(650),
              y: 0,
              x: 1538,
            }}
            bordered={true}
            onFetch={() => {
              return orderDetailTableList;
            }}
            // onAdd={(data) => {
            //   setOrderDetailTableList([...orderDetailTableList, data]);
            //   console.log(data);

            //   return data;
            // }}
            rowKey="id"
            onUpdate={async (data) => {
              const index = orderDetailTableList.findIndex(
                (d) => d.id === data.id,
              );
              if (index > -1) {
                const newData = [...orderDetailTableList];
                newData.splice(index, 1, data);
                setOrderDetailTableList(newData);
              }
              return data;
            }}
            // extraParams={{ currentRowId }}
            addLinkPage={{
              linkPage: () => <Add handelTableSelect={getTemplateValue} />,
              modalProps: {
                title: `请选择医嘱收费模板`,
                className: 'aspirin-linkpage',
                width: 1246,
                bodyStyle: {
                  height: 600,
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  padding: 0,
                },
              },

              onLinkPageValidate: async (params) => {
                // const select = getSelectedRowTemplate();
                const select = selectedRowsTemplateRef?.current;
                console.log(selectedRowsTemplate, 'pp');
                select.forEach(async (item) => {
                  const _id = `temp${Math.random()}`;
                  await tableRef.current?.add({
                    ...item,
                    id: _id,
                    priceItemName: item?.itemName,
                    priceItemId: item?.itemId,
                    priceItemSpecification: item?.itemSpecification,
                    charge: 0,
                    cost: (item?.amount ?? 0) * (item?.price ?? 0),
                    tallyIndicator: false,
                    performDepartmentName: currentRowPerformDepartmentName,
                    orderDepartmentName:
                      currentApplication?.department?.departmentName,
                    // amount: selectedRowsTemplate?.amount,
                  });
                });
                // await tableRef.current?.startEdit(_id)
                return true;
              },
            }}
            editable={{
              editType: 'single',
              saveOnClickOutside: true,
              // deleteText: <Icon type="icon-shanchuwudi" size={18} />,
              onFieldChange: (key, value: any, record: any, form) => {
                // 选择项目名称带入的值
                if ('priceItemName' in value) {
                  console.log(value, 'oioi');

                  form.setFieldValue(
                    [key, 'priceItemName'],
                    value?.priceItemName?.itemName,
                  );
                  form.setFieldValue(
                    [key, 'itemClass'],
                    value?.priceItemName?.itemClass,
                  );
                  form.setFieldValue(
                    [key, 'priceItemSpecification'],
                    value?.priceItemName?.itemSpecification,
                  );
                  form.setFieldValue(
                    [key, 'price'],
                    value?.priceItemName?.price,
                  );
                  form.setFieldValue([key, 'unit'], value?.priceItemName?.unit);
                  form.setFieldValue(
                    [key, 'priceItemId'],
                    value?.priceItemName?.itemId,
                  );
                  form.setFieldValue(
                    [key, 'medicalInsuranceType'],
                    value?.priceItemName?.insuranceItemCode?.length > 0
                      ? value?.priceItemName?.insuranceItemCode
                      : null,
                  );
                  // 单价变化，计价金额变化
                  form.setFieldValue(
                    [key, 'cost'],
                    (record?.amount ?? 0) * (value?.priceItemName?.price ?? 0),
                  );
                  form.setFieldValue(
                    [key, 'singleSettleIndicator'],
                    value?.priceItemName?.singleSettleIndicator,
                  );

                  // singleSettleIndicator
                }
                // 数量变化，计价金额变化
                if ('amount' in value) {
                  const number1 = new Decimal(value?.amount ?? 0);
                  const number2 = new Decimal(record?.price ?? 0);
                  const res = number1.times(number2);
                  console.log(number1, number2, res.toString());

                  // form.setFieldValue([key, 'cost'], number1.times(number2));
                  form.setFieldValue([key, 'cost'], res.toString());
                }

                // if ('titleIn' in value) {
                //   const title = titleEnum.find(
                //     (t) => t.value === value.titleIn,
                //   )?.label;
                //   form.setFieldValue([key, 'test-editable'], title);
                //   form.setFieldValue([key, 'test-preview'], title);
                // }
              },
              onCanEdit: (v) => {
                const regexp = /^temp/;
                const _res = regexp.test(v?.id);
                return (
                  (v?.source == 'DEPARTMENT_PRICE' &&
                    v?.chargeStatus == 'NOT_CHARGE') ||
                  _res
                );
              },
              columnProps: {
                fixed: 'right',
                width: 100,
                hideInTable: true,
              },
            }}
            rowSelection={{
              selectType: 'multiple',
              getCheckboxProps: (record) => {
                const regexp = /^temp/;
                const _res = regexp.test(record?.id);
                return { disabled: _res ? true : false };
              },
              selectedRows,
            }}
            rowClick={{
              rowClickType: {
                type: 'edit-save',
                action: 'double-click',
              },
            }}
            pagination={false}
            form={{
              labelAlign: 'left',
            }}
          >
            <VSTableColumn
              dataIndex={['priceItemClass']}
              title="类别"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.BILL_ITEM_CLASS_DICT}
              fieldProps={{
                disabled: true,
              }}
              // fixed="left"
              width={130}
            />
            <VSTableColumn
              dataIndex={['priceItemName']}
              title="项目名称"
              valueType="dictionary"
              formItemProps={{
                rules: [{ required: true }],
              }}
              // fixed="left"
              fieldProps={{
                tableClassify: 'ORDERPRICE',
              }}
              width={180}
            />
            <VSTableColumn
              dataIndex={['priceItemSpecification']}
              title="规格"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ],
              }}
              fieldProps={{ disabled: true }}
              width={130}
            />
            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '价格的值不合法',
                    validator: (_, value) => {
                      if (value === undefined) return Promise.resolve();
                      if ((true && value >= 0) || (!true && value > 0)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('价格的值不合法'));
                    },
                  },
                ],
              }}
              width={130}
              fieldProps={{ disabled: true }}
            />
            <VSTableColumn
              dataIndex={['unit']}
              title="单位"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
              fieldProps={{ disabled: true }}
              width={130}
            />
            <VSTableColumn
              dataIndex={['amount']}
              title="数量"
              valueType="text"
              formItemProps={{
                rules: [
                  { required: true },
                  // {
                  //   min: 1,
                  //   max: 100000,
                  // },
                ],
              }}
              width={130}
              initialValue={1}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['charge']}
              title="收费"
              valueType="text"
              fieldProps={{ disabled: true, defaultValue: 0 }}
              width={130}
              initialValue={0}
              preview
              // render={(_, value) => {
              //   return value?.amount * value?.price;
              // }}
            />

            <VSTableColumn
              dataIndex={['cost']}
              title="计价金额"
              valueType="text"
              fieldProps={{ disabled: true }}
              width={130}
              preview
              initialValue={0}
            />

            <VSTableColumn
              dataIndex={['orderDepartmentName']}
              title="开单科室"
              valueType="text"
              fieldProps={{ disabled: true }}
              width={130}
              initialValue={currentApplication?.department?.departmentName}
              preview
            />
            <VSTableColumn
              dataIndex={['tallyIndicator']}
              title="托收"
              valueType="select"
              fieldProps={{ disabled: true }}
              width={130}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
              initialValue={false}
              preview
            />
            <VSTableColumn
              dataIndex={['performDepartmentName']}
              title="执行科室"
              valueType="select"
              fieldNames={{ label: 'departmentName', value: 'id' }}
              // searchKey="name"
              initialValue={{
                ...currentRowPerformDepartmentName,
              }}
              formItemProps={{
                rules: [{ required: true }],
              }}
              dataSource={handelList}
              fieldProps={{
                showSearch: true,
              }}
              width={130}
            />
            <VSTableColumn
              dataIndex={['medicalInsuranceType']}
              title="医保"
              valueType="select"
              dataSource={[
                { label: '甲', value: 'ALPHA' },
                { label: '乙', value: 'BETA' },
                { label: '丙', value: 'GAMMA' },
              ]}
              preview
              fieldProps={{ disabled: true }}
              width={130}
            />
            <VSTableColumn
              dataIndex={['orderDate']}
              title="业务时间"
              valueType="date"
              fieldProps={{
                disabled: true,
                format: 'YYYY-MM-DD HH:mm',
              }}
              preview
              width={130}
            />
            <VSTableColumn
              dataIndex={['createDate']}
              title="录入时间"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm',
                disabled: true,
              }}
              preview
              width={130}
              // render={(_, value) => {
              //   return dayjs(value?.createDate).format('YYYY-MM-DD HH:mm');
              // }}
            />
            <VSTableColumn
              dataIndex={['operatorName']}
              title="录入人"
              valueType="digit"
              preview
              fieldProps={{ disabled: true }}
              width={130}
            />
            {/* 已收费/已托收的费用不可删除,隐藏删除按钮 */}
            <VSTableColumn
              title="操作"
              renderFormItem={(dom, config: any) => {
                console.log(config);
                const regexp = /^temp/;
                const _res = regexp.test(config?.record?.id);
                const forbidDel =
                  config?.record?.source == 'DEPARTMENT_PRICE' &&
                  config?.record?.chargeStatus == 'NOT_CHARGE';
                return _res ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleDeleteSaveItem(config?.record);
                    }}
                  >
                    <Icon type="icon-shanchuwudi" size={18} />
                  </Button>
                ) : forbidDel ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleDeleteSaveItem(config?.record);
                    }}
                  >
                    <Icon type="icon-shanchuwudi" size={18} />
                  </Button>
                ) : (
                  ''
                );
              }}
              render={(dom, record) => {
                const regexp = /^temp/;
                const _res = regexp.test(record?.id);
                // const forbidDel = record?.chargeStatus === 'NOT_CHARGE';
                const forbidDel =
                  record?.source == 'DEPARTMENT_PRICE' &&
                  record?.chargeStatus == 'NOT_CHARGE';
                console.log(forbidDel, _res);

                return _res ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleDeleteSaveItem(record);
                    }}
                  >
                    <Icon type="icon-shanchuwudi" size={18} />
                  </Button>
                ) : forbidDel ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleDeleteSaveItem(record);
                    }}
                  >
                    <Icon type="icon-shanchuwudi" size={18} />
                  </Button>
                ) : (
                  ''
                );
              }}
              fixed="right"
              width={80}
            />
            <VSTableToolbar
              className="ant-pro-table-list-toolbar-padding"
              style={{ backgroundColor: '#fff' }}
              title={
                <div className="orderPrice-main_detail_tabletoolbar">
                  <div className="orderPrice-main_detail_tabletoolbar_div"></div>
                  费用明细
                </div>
              }
            >
              {/* <div
                className="orderPrice-main_detail_add_price"
                onClick={async (p) => {
                  const res = await tableRef?.current?.saveEdit();
                  if (!res) return;
                  isTemplate.current = false;
                  const _id = `temp${Math.random()}`;
                  await tableRef.current?.add({
                    id: _id,
                  });
                  await tableRef.current?.startEdit(_id);
                  // }
                }}
              >
                <Icon type="icon-tianjiagongneng" />
                <span>新增收费</span>
              </div> */}

              <Button
                type="primary"
                icon={<Icon1 type="icon-tianjiagongneng" />}
                onClick={async (p) => {
                  const res = await tableRef?.current?.saveEdit();
                  if (!res) return;
                  isTemplate.current = false;
                  const _id = `temp${Math.random()}`;
                  await tableRef.current?.add({
                    id: _id,
                  });
                  await tableRef.current?.startEdit(_id);
                  // }
                }}
              >
                新增收费
              </Button>
              <VSTableAddition
                // children="收费模板"
                size="small"
                type="primary"
                icon=""
                onClick={async () => {
                  const res = await tableRef?.current?.saveEdit();
                  isTemplate.current = true;
                  // const addList = tableRef?.current.getValues();
                  // console.log(addList, 'addListaddList');
                  // setIstemplate(true);
                }}
              >
                <Icon1
                  // style={{ marginTop: '4px' }}
                  size={16}
                  type="icon-shoufeimoban"
                />
                <span style={{ marginLeft: '2px' }}>收费模板</span>
              </VSTableAddition>
            </VSTableToolbar>
          </VSPromiseTable>
        </div>
      </div>
    );
  };

  return (
    <div className="cpoe-department-price">
      <div className="cpoe-department-price_main">
        {currentTab === 'allPrice'
          ? renderAllPriceMain()
          : renderOrderPriceMain()}
      </div>
      <div className="footer">
        <Button
          onClick={async () => {
            const res = await tableRef?.current?.saveEdit();
            const addList = tableRef?.current?.getValues();

            const regexp = /^temp/;
            const _addList = addList?.filter((item) => {
              return regexp.test(item?.id);
            });
            if (_addList?.length > 0) {
              Modal.confirm({
                title: '提示',
                centered: true,
                content: '还有未保存的费用明细,确认是否取消?',
                onOk: () => {
                  onClose();
                },
                onCancel() {},
                okText: '确定',
                cancelText: '取消',
                width: 520,
              });
            } else {
              onClose();
            }
          }}
          className="cancel"
        >
          取消
        </Button>
        <Button
          onClick={handelCancelTally}
          style={{ display: showFooter }}
          type="primary"
        >
          取消托收
        </Button>
        <Button
          onClick={handelChangeTally}
          style={{ display: showFooter }}
          type="primary"
        >
          托收
        </Button>
        <Button
          onClick={handeChangeMedicalInsuranceType}
          style={{ display: showFooter }}
          type="primary"
          // disabled={
          //   tableRef.current?.getSelectedRowValues()?.length == 1 ? false : true
          // }
        >
          费别切换
        </Button>
        <Button
          onClick={async () => {
            const res = await tableRef?.current?.saveEdit();
            const selectRows = tableRef.current?.getSelectedRowValues();
            save();
          }}
          style={{ display: showFooter }}
          type="primary"
        >
          保存
        </Button>
      </div>
    </div>
  );
};

const Index = (props, ref) => {
  const { onSubmit } = props;
  // 当前选中tab
  const [currentTab, setCurrentTab] = useState('all');
  // const [currentTabInfo, setCurrentTabInfo] = useState();
  const [open, setOpen] = useState<boolean>(false);
  // 医嘱行id
  const [defaultValue, setdefaultValue] = useState<any>();

  const [testItems, setTestItems] = useState<any>();
  // 切换tab
  const onChange = (v, record) => {
    vsf?.refs?.orderPriceMainDetailTable?.saveEdit();
    const _listLength = vsf?.refs?.orderPriceMainDetailTable?.getValues();
    const regexp = /^temp/;
    const _addList = _listLength?.filter((item) => {
      return regexp.test(item?.id);
    });

    if (_addList?.length > 0) {
      Modal.confirm({
        title: '提示',
        centered: true,
        content: '还有未保存的费用明细,确认是否取消?',
        onOk: () => {
          setCurrentTab(v);
        },
        onCancel() {},
        okText: '确定',
        cancelText: '取消',
        width: 520,
      });
    } else {
      setCurrentTab(v);
    }
  };
  // setCurrentTabInfo(record);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = (v) => {
    if (v && v !== -1) {
      // v:医嘱行id
      setdefaultValue(v);
      setCurrentTab('orderPrice');
    } else {
      setCurrentTab('allPrice');
    }
    setOpen(true);
  };

  const initTestItems = useCallback(async () => {
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.LabController_getAllByLabClassQto_a6f1c8?.({
      // TODO: 请检查以下这部分传参是否符合需求
      qto: {
        from: 0,
        size: 999,
        stopIndicatorIs: false,
        institutionIdIs: 501,
      },
    }); // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      const target = (res?.data ?? [])?.map((item) => ({
        label: item?.labClassName,
        key: item?.id,
        value: item?.id,
        origin: item,
      }));
      setTestItems(target);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer-border-bottom aspirin-department-price1700"
      title={
        <TabCheck
          onChange={onChange}
          dataSource={tabItems}
          value={currentTab}
        />
      }
      destroyOnClose
      placement="right"
      onClose={onClose}
      open={open}
      width={1586}
    >
      <Content
        defaultValue={defaultValue}
        open={open}
        currentTab={currentTab}
        onSubmit={onSubmit}
        onClose={onClose}
        onChangeTab={onChange}
      />
    </Drawer>
  );
};

export default React.forwardRef(Index);
