import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSDataProvider,
  Todo,
  Image,
  VSFormLayout,
  Button,
  VSForm,
} from '@vs/vsf-kit';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState, useEffect, useRef } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import vsf, { definePage } from '@vs/vsf-boot';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const DoctorExtInfoEditor = (props) => {
  const { rowId, defaultRowId, stores } = props;
  const {
    common: { currentApplication },
    user: { setInitKey, initKey },
  } = stores;
  /**
   * 员工id
   * @type {StaffVoUserStaffEntranceWebVo}
   */
  const [staffId, setStaffId] = useState();
  const [departmentNameList, setDepartmentNameList] = useState();
  const [fileList, setFileList] = useState([1]);
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  // useEffect(() => {
  //   getDepartmentNameList();
  // }, []);

  useEffect(() => {
    rowId && getDetail();
  }, [rowId, initKey]);

  const beforeUpload = (file) => {
    console.log(file);
    // return;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size < 4000;
    if (!isLt2M) {
      vsf.showToast('文件大小超过限制', 'error');
    }
    if (isJpgOrPng && isLt2M) {
      getBase64(file, (url) => {
        // if (url?.length > 4000) return message.info('图片超过限制大小');
        // let str = url.replace('data:image/png;base64,', '');
        // const equalIndex = str.indexOf('=');
        // if (str.indexOf('=') > 0) {
        //   str = str.substring(0, equalIndex);
        //   const fileLength = parseInt(str?.length - (str?.length / 8) * 2);
        //   console.log(fileLength, url?.length);
        // }
        // setLoading(false);
        setImageUrl((v) => {
          vsf.refs.doctorInfoForm.setFieldsValue({
            staffInfoNoteDto: {
              staffPhoto: url,
            },
          });
          return url;
        });
      });
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    console.log(info, 'uploadChange');
    if (!info.file.originFileObj) return;
    getBase64(info.file.originFileObj ?? info.file, (url) => {
      // if (url?.length > 4000) return message.info('图片超过限制大小');
      // let str = url.replace('data:image/png;base64,', '');
      // const equalIndex = str.indexOf('=');
      // if (str.indexOf('=') > 0) {
      //   str = str.substring(0, equalIndex);
      //   const fileLength = parseInt(str?.length - (str?.length / 8) * 2);
      //   console.log(fileLength, url?.length);
      // }
      setLoading(false);
      setImageUrl((v) => {
        vsf.refs.doctorInfoForm.setFieldsValue({
          staffInfoNoteDto: {
            staffPhoto: url,
          },
        });
        return url;
      });
    });
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const getDepartmentNameList = async (v) => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
        {
          qto: {
            ...v,
            departmentNameLike: v?.inputCodeLike,
            inputCodeWubiLike: v?.inputCodeLike,
          },
        },
      );
    const _res = res?.data?.map((item) => {
      return {
        label: item?.departmentName,
        value: item?.id,
      };
    });
    setDepartmentNameList(_res);
    return _res; // todo 整理返回数据格式
  };

  const getDetail = async () => {
    const res = await vsf.services.StaffDetailDtoController_getById_6ecdf0({
      id: rowId,
    });

    vsf.refs.doctorInfoForm.setFieldsValue(res.data);
    setImageUrl(res.data?.staffInfoNoteDto?.staffPhoto ?? '');
  };

  return (
    <div
      style={{
        flex: 1,
        overflowY: 'scroll',
        height: 0,
      }}
    >
      <VSControlledForm id="doctorInfoForm" labelAlign="left" disabled={!rowId}>
        <VSFormItem
          name={['id']}
          label="组件"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <div
          style={{
            padding: '24px 4px 24px 24px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <div className="basic_information">
            <div className="img">
              <VSFormItem
                className="upload"
                label="头像"
                name={['staffInfoNoteDto', 'staffPhoto']}
                valueType="custom"
                onChange={(event) => {
                  const file = event.target.files[0];
                  // if (file === undefined) return;
                  // // 增加文件大小限制
                  // if (file.size > 4000) {
                  //   vsf.showToast('文件大小超过限制', 'error');
                  //   return;
                  // }
                  console.log('上传onchange', event, imageUrl);
                  return file.size <= 4000 && file;
                }}
                width={50}
              >
                <ImgCrop
                  rotationSlider
                  modalWidth={500}
                  beforeCrop={(v) => {
                    console.log(v);
                    return v?.size <= 4000;
                  }}
                  // resize={false} //裁剪是否可以调整大小
                >
                  <Upload
                    name="avatar"
                    accept={'.jpg,.png,.jpeg'}
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    // showUploadList={{
                    //   showPreviewIcon: true,
                    //   showRemoveIcon: true,
                    // }}
                    // onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: '100%', height: '100%' }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </VSFormItem>
            </div>
          </div>
          <div>
            <VSFormLayout
              style={{ marginBottom: 15 }}
              columnCount={2}
              labelWidth={80}
            >
              <VSFormItem
                name={['staffDepartment', 'id']}
                label="主键"
                style={{ display: 'none' }}
                valueType="digit"
                fieldProps={{}}
              />

              <VSFormItem
                name={['staffDepartment', 'branchInstitution', 'id']}
                label="主键"
                style={{ display: 'none' }}
                valueType="digit"
                fieldProps={{}}
              />
              <VSFormItem
                name={['staffName']}
                label="姓名"
                valueType="text"
                // readonly
                className="info_height"
                rules={[
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
                fieldProps={{ disabled: true }}
              />
              <VSFormItem
                className="info_height"
                name={['gender']}
                label="性别"
                valueType="select"
                dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                fieldProps={{ disabled: true }}
                // readonly
              />
              <VSFormItem
                className="info_height"
                name={['titleCode']}
                label="职称"
                valueType="select"
                dataSource={vsf.stores.dbenums?.enums?.TITLE_DICT}
                // readonly
                fieldProps={{ disabled: true }}
              />
              <VSFormItem
                className="info_height"
                name={['staffNumber']}
                label="工号"
                valueType="text"
                fieldProps={{ disabled: true }}
                // readonly
              />
            </VSFormLayout>
            <VSFormLayout columnCount={1} labelWidth={80}>
              <VSFormItem
                name={[
                  'doctorDto',
                  'clinicDepartment',
                  'branchInstitution',
                  'institutionName',
                ]}
                label="所在院区"
                valueType="select"
                fieldProps={
                  {
                    // disabled: true,
                  }
                }
              />
              <VSFormItem
                name={['doctorDto', 'clinicDepartment', 'departmentName']}
                label="临床科室"
                valueType="select"
                fieldProps={{
                  // fieldNames: { label: 'departmentName', value: 'id' },
                  showSearch: true,
                }}
                fieldNames={{ label: 'label', value: 'value' }}
                searchKey="inputCodeLike"
                // dataSource={getDepartmentNameList}
                // transform={(v) => {
                //   console.log(v, 'pppoo');
                //   return {
                //     doctorDto: {
                //       clinicDepartment: {
                //         departmentName: v?.label
                //           ? v?.label
                //           : staffId?.doctorDto?.clinicDepartment
                //               ?.departmentName,
                //         id: v?.value ? v?.value : staffId?.doctorDto?.id,
                //       },
                //     },
                //   };
                // }}
              />
              <VSFormItem
                name={['descriptionInfo']}
                label="简介"
                valueType="textarea"
                rules={[
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
                fieldProps={{
                  // height: 130,
                  autoSize: { minRows: 3, maxRows: 5 },
                  disabled: true,
                }}
              />
              <VSFormItem
                name={['doctorDto', 'specialityDescription']}
                label="特长"
                valueType="textarea"
                fieldProps={{
                  // height: 130,
                  autoSize: { minRows: 3, maxRows: 5 },
                  disabled: true,
                }}
                rules={[
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ]}
              />
            </VSFormLayout>
          </div>
        </div>
      </VSControlledForm>
    </div>
  );
};
export default definePage(DoctorExtInfoEditor);
