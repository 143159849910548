import {
  Col,
  Row,
  VSFormItem,
  Input,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  VSTable,
  Button,
  VSTableAction,
  Space,
  message,
  Modal,
  VSForm,
  VSFormLayout,
  VSFormFooter,
  Form,
  Tooltip,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { subtract } from '@/utils/setDataUtil';
import { getScrollY } from '@/utils';
import { filterEmptyValues } from '@/utils/setDataUtil';

import UserInfo from './UserInfo';
import { getDetailById } from './Service';
const Big = require('big.js');

export default function TableWaitConfirm(props) {
  const { tabaleParam, setTableParam, indexListRun, setSelectFlag } = props;
  const [expandedRows, setExpandedRows] = useState();
  /**
   * convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391
   * @param {any} input
   * @returns {any}
   */
  const convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391 =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.returnDrugReason = _.get(input, `data.[0].returnDrugReason`);
      return output;
    };
  const { data: detailData, run } = useRequest(getDetailById, {
    manual: true,
  });
  const { data: withdrawalReasonData, run: reasonRun } = useRequest(
    async (p) => {
      if (!tabaleParam?.refundMasterId) return { data: '' };
      const res =
        await vsf.services?.DrugPrescriptionRefundDispenseController_getListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIds_5ae382?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求?? [2502], //TODO test
            id: tabaleParam?.refundMasterId,
          },
        ); // Tips: 需要返回内容数据，结构和表单RO一致
      return convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2VSForm742391(
        res,
      );
    },
  );

  const convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2TableOrderDrugDispenseDetail =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ..._.get(input, 'data').map((d, i) => {
          const obj = {};
          obj.id = _.get(d, 'id'); // TODO 请确认下行字段是否符合逻辑
          obj.prescriptionDataTime = _.get(
            d,
            'prescriptionDispense.prescription.prescriptionDataTime',
          );
          obj.orderDepartmentName = _.get(d, 'orderDepartmentName');
          obj.orderDoctorName = _.get(d, 'orderDoctorName'); // TODO 请确认下行字段是否符合逻辑
          obj.prescriptionAttribute = _.get(
            d,
            'prescriptionDispense.prescription.prescriptionAttribute',
          ); // TODO 请确认下行字段是否符合逻辑
          obj.settleDate = _.get(
            d,
            'prescriptionDispense.outpSettle.settleDate',
          );
          obj.totalCharge = _.get(d, 'totalCharge');
          obj.refundApplyDetailList = []; // TODO 请确认下行字段是否符合逻辑
          obj.refundApplyDetailList.push(
            ..._.get(d, 'drugPrescriptionRefundApplyDetailDtoList').map(
              (d, i) => {
                const obj = {}; // TODO 请确认下行字段是否符合逻辑
                obj.orderText = _.get(d, 'dispenseDetail.order.orderText'); // TODO 请确认下行字段是否符合逻辑
                obj.amountUnit = _.get(d, 'amountUnit'); // TODO 请确认下行字段是否符合逻辑
                obj.usageUnit = _.get(d, 'usageUnit'); // TODO 请确认下行字段是否符合逻辑
                obj.frequency = _.get(
                  d,
                  'dispenseDetail.orderDrug.frequency.frequency',
                ); // TODO 请确认下行字段是否符合逻辑
                obj.administration = _.get(
                  d,
                  'dispenseDetail.orderDrug.administration',
                ); // TODO 请确认下行字段是否符合逻辑
                obj.performDays = _.get(
                  d,
                  'dispenseDetail.orderDrug.performDays',
                ); // TODO 请确认下行字段是否符合逻辑
                obj.price = _.get(d, 'dispenseDetail.outpBillingDetail.price'); // TODO 请确认下行字段是否符合逻辑
                obj.applyAmount = _.get(d, 'applyAmount'); // TODO 请确认下行字段是否符合逻辑
                obj.actualAmount = _.get(d, 'actualAmount'); // TODO 请确认下行字段是否符合逻辑
                obj.refundAmount = _.get(d, 'refundAmount');
                obj.id = _.get(d, 'id');
                return obj;
              },
            ),
          );
          return obj;
        }),
      );
      return output;
    };
  /**
   * convertTableOrderDrugDispenseDetail2PerformRefundReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableOrderDrugDispenseDetail2PerformRefundReq = (input) => {
    const output = [];
    (input ?? []).forEach((item) => {
      const obj = {
        detailId: item.id,
        refundAmount: item.refundAmount || 0,
        orderText: item.orderText,
      };
      output.push(obj);
    });
    return (output ?? []).filter((m) => m.refundAmount > 0);
  };
  const [prescriptionCount, setPrescriptionCount] = useState();

  const [selectTableData, setSelectTableData] = useState([]);
  const [paramData, setParamData] = useState([]);
  useEffect(() => {
    // if (tabaleParam) {
    setPrescriptionCount(0);
    run(tabaleParam);
    reasonRun(tabaleParam);
    // }
  }, [tabaleParam]);

  const expandedRowRender = (record) => {
    return (
      <VSTable
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
          onFieldChange: (key, _, { refundAmount }, from) => {
            if (selectTableData.length <= 0) return;
            setParamData(
              paramData?.map((d) => {
                if (d.detailId == parseInt(key)) d.refundAmount = refundAmount;
                return d;
              }),
            );
          },
        }}
        id="tableOrderDrugDispenseDetail-refundApplyDetailList"
        showHeader={false}
        pagination={false}
        bordered
        value={record?.refundApplyDetailList}
        onChange={(v) => {}}
      >
        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width="24rem"
          preview
          render={(_, values) => {
            return (
              <div style={{ textAlign: 'left', paddingLeft: '12px' }}>
                {values?.orderText}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['amountUnit']}
          title="数量"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width="6.66rem"
          preview
        />

        <VSTableColumn
          dataIndex={['usageUnit']}
          title="用量"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width="6.66rem"
          preview
        />

        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          editable={false}
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
          width="6.66rem"
          preview
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="给药途径"
          editable={false}
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
          width="10rem"
          preview
        />

        <VSTableColumn
          dataIndex={['performDays']}
          title="医嘱天数"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          width="6.66rem"
          preview
          render={(_, values) => {
            return `${values?.performDays}天`;
          }}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          width="6.66rem"
          render={(_, values) => {
            return Number(values?.price).toFixed(2);
          }}
          preview
        />

        <VSTableColumn
          dataIndex={['applyAmount']}
          title="医生申请数量 "
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '医生申请退药数量 的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
          width="6.66rem"
          preview
        />

        <VSTableColumn
          dataIndex={['actualAmount']}
          title="已退数量"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '实际退药数量的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          width="6.66rem"
          fieldProps={{}}
          render={(_, v) => {
            return v.actualAmount ?? 0;
          }}
          preview
        />

        <VSTableColumn
          dataIndex={['refundAmount']}
          title="药师退药数量"
          valueType="digit"
          formItemProps={(from, { entry }) => {
            return {
              rules: [
                {
                  validator(_, value) {
                    if ([undefined, null].includes(value)) {
                      return Promise.reject('请输入数量');
                    } else if (!Number.isInteger(value)) {
                      return Promise.reject('数量必须是整数');
                    } else if (value < 0) {
                      return Promise.reject('数量必须是正整数');
                    } else if (value === 0) {
                      return Promise.reject('数量不能为0');
                    } else if (value > entry?.applyAmount) {
                      return Promise.reject('退药数量不可大于申请数量');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ],
            };
          }}
          width="10rem"
        />
      </VSTable>
    );
  };

  const onFetch = useCallback(
    async (params) => {
      if (!tabaleParam?.refundMasterId) return [];
      setSelectTableData([]);
      if (tabaleParam) {
        const res =
          await vsf.services?.DrugPrescriptionRefundDispenseController_getListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIds_5ae382?.(
            {
              id: tabaleParam?.refundMasterId,
            },
          );
        const output =
          convertGetListDrugPrescriptionRefundApplyMasterDetailDispenseVoByIdsRes2TableOrderDrugDispenseDetail(
            res,
          );
        setPrescriptionCount(res?.data?.count ?? res?.data?.length ?? 0);
        const param = output?.map((item) => {
          const dataSorce = item?.refundApplyDetailList?.map((item) => {
            return {
              ...item,
              refundAmount: subtract(item?.applyAmount, item?.actualAmount),
            };
          });
          return { ...item, refundApplyDetailList: dataSorce };
        });
        setExpandedRows(param);
        setSelectTableData(param);
        return {
          data: param,
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      } else {
        return {};
      }
    },
    [tabaleParam],
  );

  useEffect(() => {
    if (selectTableData) {
      let arr = [];
      selectTableData.map((item) => {
        arr = [...arr, ...item?.refundApplyDetailList];
      });
      const details = convertTableOrderDrugDispenseDetail2PerformRefundReq(
        arr ?? [],
      );
      setParamData(details);
    }
  }, [selectTableData]);
  const confirmDistribute = async () => {
    if (paramData.length <= 0) {
      return message.warning('没有选中药品!');
    }
    if (selectTableData.length <= 0) {
      return message.warning('没有选中药品!');
    }

    const res =
      await vsf.services?.DrugPrescriptionRefundDispenseController_performRefund_e2e545?.(
        {
          refundDetails: paramData,
        },
      );
    // if (res?.code === 200) {}
    // 左侧列表选中状态；
    setSelectFlag(undefined);
    // 左侧选中列表项详情；触发右侧请求；
    setTableParam(undefined);
    const file = vsf?.refs?.drugSelectForm?.getFieldsValue();
    // 更新左侧列表；
    indexListRun(filterEmptyValues(file));
    message.success('退药成功');
  };
  return (
    <>
      <div className="top">
        <UserInfo {...props} outpVisitId={tabaleParam?.outpVisitId} />
      </div>
      <div className="bottom">
        <div className="total-table-prescription">
          <span className="total-chager-chufang">
            退药明细 共{prescriptionCount || 0}个处方
          </span>
        </div>
        <VSPromiseTable
          vsid="98204"
          id="tableOrderDrugDispenseDetail"
          rowSelection={{
            selectType: 'multiple',
            onSelectedRowsChange: (data, keys) => {
              setSelectTableData(data);
            },
            selectedRows: selectTableData,
          }}
          expandable={{
            expandedRows,
            expandDataIndex: ['refundApplyDetailList'],
            expandedRowRender,
            onExpandedRowsChange: (rows) => setExpandedRows(rows),
          }}
          rowClassName={(...[, rowIndex]) => {
            return 'aspirin-table-title-background-box';
          }}
          onFetch={onFetch}
          showHeader={false}
          pagination={false}
          scroll={{ x: 1100 }}
        >
          <VSTableColumn
            dataIndex={['prescriptionDataTime']}
            title="处方日期"
            valueType="index"
            width="6.66rem"
            fieldProps={
              {
                // width:'150'
              }
            }
          />

          <VSTableColumn
            dataIndex={['prescriptionDataTime']}
            title="处方日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
          />

          <VSTableColumn
            dataIndex={['orderDepartmentName']}
            title="开单科室"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['orderDoctorName']}
            title="开单医生"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['prescriptionAttribute']}
            title="处方类型"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
          />

          <VSTableColumn
            dataIndex={['settleDate']}
            title="收费日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width="13.33rem"
          />

          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            render={(_, v) => {
              return <>{Number(v.totalCharge ?? 0).toFixed(2)}元</>;
            }}
          />
          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width="6.66rem"
            render={(_, values) => {
              return '申请数量';
            }}
          />
          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width="6.66rem"
            render={(_, values) => {
              return '已退数量';
            }}
          />
          <VSTableColumn
            dataIndex={['totalCharge']}
            title="处方金额"
            valueType="digit"
            fieldProps={{}}
            width="10rem"
            render={(_, values) => {
              return '退药数量';
            }}
          />
        </VSPromiseTable>
      </div>
      <div className="center-dec">
        <Input
          placeholder="请输入退药原因"
          bordered={false}
          value={`退药原因:${withdrawalReasonData?.returnDrugReason || ''}`}
          onChange={(v) => {
            setWithdrawalReason(v.target.value);
          }}
          disabled
        />
      </div>
      <div className="center">
        <div></div>
        <div>
          <Space size={4}>
            <Button type="primary"> 打印</Button>
            <Button type="primary" onClick={confirmDistribute}>
              退药确认
            </Button>
          </Space>
        </div>
      </div>
    </>
  );
}
