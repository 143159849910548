import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSForm,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import { phoneNumber, phoneNumberMessage } from '@/pattern';
import FormTitle from '@/pages/Index/components/form_title';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';
import {
  convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail,
  convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq,
  convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq,
} from '../convert';
const Index = (props, ref) => {
  const {} = props;

  const [open, setOpen] = useState(false);
  // 详情
  const [detail, setDetail] = useState();
  const [list, setList] = useState();
  const [search, setSearch] = useState();
  //   useEffect(() => {
  //     getInpVisitBaseWithDiagnosisVoById();
  //   }, [bedCardId]);
  //   useEffect(() => {
  //     getGroupController();
  //   }, [detail?.currentDepartmentId]);

  useEffect(() => {
    getPagedByUserStaffQto();
  }, [search, detail?.departmentId]);

  useImperativeHandle(
    ref,
    () => ({
      handelModal,
    }),
    [],
  );

  const modalProps = {
    open: open,
    title: (
      <div style={{ fontSize: 20, fontWeight: 500, color: '#3276E8' }}>
        患者详情
      </div>
    ),
    cancelText: '取消',
    okText: '确认',
    width: 1356,

    centered: true,
  };
  //查看患者详情
  const getInpVisitBaseWithDiagnosisVoById = async (bedCardId) => {
    if (!bedCardId?.inpVisitId && !open) return;
    const res =
      await vsf.services?.ClinicInpVisitController_getInpVisitBaseWithDiagnosisVoById_6417ed?.(
        {
          id: bedCardId?.inpVisitId,
        },
      );
    const resultData =
      convertGetInpVisitBaseWithDiagnosisVoByIdRes2VSFormBedPatientInpDetail(
        res,
      ); // Tips: 需要返回内容数据，结构和表单RO一致
    vsf.refs?.vSFormBedPatientInpDetail?.setFieldsValue({
      ...resultData,
      bedPrice: Number(resultData?.bedPrice ?? 0).toFixed(2),
    });

    if (res?.code == 200) {
      setDetail(resultData);
      //   getGroupController(resultData?.currentDepartmentId);
    }
  };

  const handelModal = (value, bedCardId) => {
    setOpen(value);
    if (value) {
      getPagedByUserStaffQto();
      getInpVisitBaseWithDiagnosisVoById(bedCardId);
    }
  };
  //医疗组长
  const getGroupController = async (i) => {
    if (!detail?.departmentId) return;
    const input = {};
    //   当前患者所在科室id
    input.departmentId = detail?.departmentId;
    const reqParams =
      convertVSFormBedPatientInpDetail2QueryAllByMedicalGroupReq(input);
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        eo: reqParams?.eo,
      });
    const _res = res?.data?.result
      ?.map((item) => {
        return item?.medicalGroupMemberIndexDtoList?.[0]?.staff;
      })
      .filter((item) => {
        return item?.id;
      });
    return _res ?? [];
  };
  // 主治医师/住院医师
  const getPagedByUserStaffQto = async (v) => {
    if (!detail?.departmentId) return;
    console.log(v, search);
    const searchParams = {};
    searchParams.staffDepartmentIdIs = detail?.departmentId;
    searchParams.jobClassCodeIs = 'A1';
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        qto: {
          from: 0,
          size: 2000,
          ...searchParams,
          inputCodeLike: search ? search : v?.inputCodeIs,
        },
      });
    const selectItems = [];
    res?.data?.result?.forEach((dataItem) => {
      const selectItem = {};
      selectItem.staffName = dataItem?.staffName;
      selectItem.id = dataItem?.id;
      selectItems.push(selectItem);
    });
    setList(selectItems);
    return res?.data?.result;
  };
  // 责任护士/质控护士searchKey="inputCodeLike"
  const getPagedByUserStaffQto1 = async (v) => {
    const searchParams = {};
    searchParams.staffDepartmentIdIs = detail?.wardId;
    searchParams.jobClassCodeIs = 'A2'; // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 200,
          ...searchParams,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式

    return res?.data?.result ?? [];
  };

  // 保存
  const save = async () => {
    const params = vsf.refs.vSFormBedPatientInpDetail.getFieldsValue();
    const params1 = vsf.refs.vSFormBedPatientInpDetail.getFieldValue();
    console.log(params, params1);
    const btoParams =
      convertVSFormBedPatientInpDetail2UpdateInpVisitExtensionStaffReq({
        ...params,
        id: params1?.id,
      }); // Tips: 以下是你选择的请求函数

    const res =
      await vsf.services?.ClinicInpVisitController_updateInpVisitExtensionStaff_c2a009?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          btoParam: btoParams,
          extApiParam: {
            ...params?.extApiParam,
            cellphoneNumber: params?.cellphoneNumber,
          },
        },
      );
    if (res?.code == 200) {
      handelModal(false);
      message.success('保存成功!');
    }
  };

  return (
    <div className="bed_card_detail_modal">
      <Modal
        {...modalProps}
        onCancel={() => {
          handelModal(false);
        }}
        onOk={save}
        bodyStyle={{
          padding: '12px 24px',
          backgroundColor: '#f4f5f7',
          maxHeight: 636,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <VSForm id="vSFormBedPatientInpDetail">
          <div className="bed_card_detail_modal_form_title">
            <div className="bed_card_detail_modal_form_title_tag"></div>患者信息
          </div>
          <VSFormLayout key="0" columnCount={4}>
            <VSFormItem
              name={['name']}
              label="姓名"
              readonly
              valueType="text"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 60 },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['displayId']}
              label="患者ID"
              readonly
              valueType="text"
              rules={[
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['age']}
              label="年龄"
              readonly
              valueType="text"
              fieldProps={{}}
            />

            <VSFormItem
              name={['gender']}
              label="性别"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.SEX_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['nation']}
              label="民族"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.NATION_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['birthday']}
              label="出生日期"
              readonly
              valueType="date"
              fieldProps={{}}
            />

            <VSFormItem
              name={['identityCode']}
              label="身份"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['chargeType']}
              label="费别"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['marriageStatus']}
              label="婚姻"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.MARITAL_STATUS_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['citizenship']}
              label="国籍"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.COUNTRY_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['job']}
              label="职业"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.OCCUPATION_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['cellphoneNumber']}
              label="电话"
              // readonly
              valueType="text"
              rules={[
                {
                  message: phoneNumberMessage,
                  pattern: phoneNumber,
                },
              ]}
            />
          </VSFormLayout>
          <VSFormLayout key="3" columnCount={4}>
            <VSFormItem
              name={['birthAddress']}
              label="出生地"
              readonly
              valueType="address"
              fieldProps={{}}
            />

            <VSFormItem
              name={['nativeAddress']}
              label="籍贯"
              readonly
              valueType="address"
              fieldProps={{}}
            />

            <VSFormItem
              name={['idNumber']}
              label="身份证号"
              readonly
              valueType="text"
              rules={[
                {
                  message: '数据格式错误',
                  type: 'string',
                  pattern:
                    /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="4" columnCount={4}>
            <VSFormItem
              name={['currentAddress']}
              label="现住址"
              readonly
              valueType="address"
              fieldProps={{}}
            />

            <VSFormItem
              name={['registeredResidenceAddress']}
              label="户口地址"
              readonly
              valueType="address"
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="5" columnCount={4}>
            <VSFormItem
              name={['workUnitName']}
              label="工作单位"
              readonly
              valueType="text"
              rules={[
                {
                  message: '单位名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 200,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['workUnitAddress']}
              label="单位地址"
              readonly
              valueType="address"
              fieldProps={{}}
            />

            <VSFormItem
              name={['workUnitPhoneNumber']}
              label="单位电话"
              readonly
              valueType="text"
              rules={[
                {
                  message: '单位联系电话长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="6" columnCount={4}>
            <VSFormItem
              name={['contactName']}
              label="联系人"
              readonly
              valueType="text"
              rules={[
                {
                  message: '联系人姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['contactRelationshipType']}
              label="关系"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['contactPhoneNumber']}
              label="联系人电话"
              readonly
              valueType="text"
              rules={[
                {
                  message: '联系人的联系电话长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['contactAddress']}
              label="联系人地址"
              readonly
              valueType="address"
              fieldProps={{}}
            />
          </VSFormLayout>
          <div className="bed_card_detail_modal_form_title">
            <div className="bed_card_detail_modal_form_title_tag"></div>在科信息
          </div>
          <VSFormLayout key="7" columnCount={4}>
            <VSFormItem
              name={['admissionDateTime']}
              label="入院时间"
              readonly
              valueType="aspirinDateTimePicker"
              fieldProps={{}}
            />

            <VSFormItem
              name={['admissionWardDateTime']}
              label="入科时间"
              readonly
              valueType="aspirinDateTimePicker"
              fieldProps={{}}
            />

            <VSFormItem
              name={['departmentName']}
              label="入院科室"
              readonly
              valueType="text"
              rules={[
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['patientClass']}
              label="入院方式"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.PATIENT_CLASS_DICT}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="8" gutter={140} columnCount={3}>
            <VSFormItem
              name={['medicalGroupLeader']}
              label="医疗组长"
              valueType="select"
              rules={[{ required: true }]}
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              fieldNames={{ label: 'staffName', value: 'id' }}
              dataSource={getGroupController}
            />
            <VSFormItem
              name={['attendingDoctor']}
              label="主治医师"
              valueType="select"
              searchKey="inputCodeIs"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              fieldNames={{ label: 'staffName', value: 'id' }}
              dataSource={getPagedByUserStaffQto}
            />
            <VSFormItem
              name={['doctorInChargeId']}
              label="住院医师"
              valueType="select"
              searchKey="inputCodeIs"
              fieldNames={{ label: 'staffName', value: 'id' }}
              fieldProps={{
                showSearch: true,
                onSearch: (v) => {
                  setSearch(v);
                },
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              dataSource={list}
            />
          </VSFormLayout>
          <VSFormLayout key="9" gutter={140} columnCount={3}>
            <VSFormItem
              name={['nurseInchargeId']}
              label="责任护士"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              fieldNames={{ label: 'staffName', value: 'id' }}
              dataSource={getPagedByUserStaffQto1}
              searchKey="inputCodeLike"
            />

            <VSFormItem
              name={['controlQualityNurseId']}
              label="质控护士"
              valueType="select"
              fieldProps={{
                showSearch: true,
              }}
              fieldNames={{ label: 'staffName', value: 'id' }}
              dataSource={getPagedByUserStaffQto1}
              searchKey="inputCodeLike"
            />
          </VSFormLayout>
          <VSFormLayout key="10" columnCount={4}>
            <VSFormItem
              name={['nursingClass']}
              label="护理等级"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.NURSING_CLASS_DICT}
              fieldProps={{}}
              readonly
            />

            <VSFormItem
              name={['patientCondition']}
              label="病情"
              readonly
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.PATIENT_STATUS_DICT}
              fieldProps={{}}
            />

            <VSFormItem
              name={['fastingIndicator']}
              label="禁食"
              readonly
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />

            <VSFormItem
              name={['diagnosisDescription']}
              label="诊断"
              readonly
              valueType="text"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 200 },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
          <VSFormLayout key="11" columnCount={4}>
            <VSFormItem
              name={['medicalSyndromeName']}
              label="中医诊断"
              readonly
              valueType="text"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 200 },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['diagnosisType']}
              label="诊断症型"
              readonly
              valueType="select"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ]}
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.DIAGNOSIS_TYPE_DICT}
            />

            <VSFormItem
              name={['isolationType']}
              label="隔离"
              readonly
              valueType="select"
              fieldProps={{ mode: 'multiple' }}
              dataSource={vsf.stores.dbenums?.enums?.ISOLATION_TYPE_DICT}
            />

            <VSFormItem
              name={['bedPrice']}
              label="床位费"
              readonly
              addonAfter="元"
              valueType="text"
              fieldProps={{}}
            />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
// export default Index;
export default React.forwardRef(Index);
