import vsf, { definePage } from '@vs/vsf-boot';
import { Table } from '@vs/vsf-kit';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import ComplexSelect from '@/pages/Index/components/complex_select';

const CustomSelect = (props) => {
  const { className, tableList, rowData, extraSearchParams = {} } = props;
  const {
    isFilter = false,
    value,
    allowClear,
    onChange,
    placeholder,
    queryType,
    onTableChange,
  } = props;

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromState, setFromState] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const tableData = useRef({
    total: 0,
    data: [],
  });

  const handelFormSearch = (v) => {
    setFromState(0);
    setSearchValue(v);
  };

  const columns = useMemo(() => {
    return [
      {
        title: '项目名称',
        dataIndex: 'itemName',
        key: 'item_name',
        width: 100,
        ellipsis: true,
      },
      {
        title: '规格',
        dataIndex: 'itemSpecification',
        key: 'item_specification',
        width: 100,
        ellipsis: true,
      },
      {
        title: '标准编码',
        dataIndex: 'standardCode',
        key: 'standard_code',
        width: 100,
        ellipsis: true,
      },
    ];
  }, []);

  // 药品列表
  const getPagedByPriceItem = useCallback(
    async (param) => {
      if (searchValue && searchValue?.length > 0) {
        const res =
          await vsf?.services?.PriceListController_getPagedByPriceItemCurrentQto_22ee99?.(
            {
              qto: {
                from: param?.pagination?.from ?? 0,
                size: param?.pagination?.size ?? 30,
                ...param?.search,
                ...param?.extra,
                ...(param ?? {}),
                inputCodeLike: searchValue,
                inputCodeWubiLike: searchValue,
              },
              isIncludeDrugOrHerb: true,
            },
          );
        setDataSource(res?.data?.result ?? []);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue],
  );

  useEffect(() => {
    getPagedByPriceItem();
  }, [getPagedByPriceItem]);

  return (
    <ComplexSelect
      mode="radio"
      value={value}
      allowClear={allowClear}
      showLabel="itemName"
      className={className}
      hideSelectIcon
      dropdownMatchSelectWidth={false}
      onSearch={handelFormSearch}
      style={{ width: 120 }}
      dropdownStyle={{
        width: 1000,
      }}
      onChange={(v) => {
        if (isFilter) {
          const _isFind = tableList?.find(
            (i) => v?.jiageid == i?.jiageid && rowData?.id != i?.id,
          );
          if (_isFind) {
            vsf.showToast('相同的药品已经存在');
            setOpenModal(true);
            onChange(null);
          } else {
            onChange(v);
            setOpenModal(false);
          }
        } else {
          onChange(v);
          setOpenModal(false);
        }
      }}
      placeholder={placeholder}
    >
      <Table
        id="projectSelect"
        rowKey="id"
        scroll={{ y: 'auto' }}
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={columns?.map((column) => ({ ...column, ellipsis: true }))}
      />
    </ComplexSelect>
  );
};

export default CustomSelect;
