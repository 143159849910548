import vsf, { definePage } from '@vs/vsf-boot';

export const RenderUseDescription = definePage((props) => {
  const { value } = props;
  const dataSource = vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;
  return dataSource?.find((item) => item?.value === value)?.label ?? '';
});

export const RenderDistribute = definePage((props) => {
  const { value } = props;
  const dataSource = vsf?.stores?.dbenums?.enums?.DRUG_DISTRIBUTION_DICT;
  return dataSource?.find((item) => item?.value === value)?.label ?? '';
});
