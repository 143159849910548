import vsf from '@vs/vsf-boot';
import {
  Checkbox,
  Select,
  VSTable,
  VSTableColumn,
  VSTableProps,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import { Flex } from '@/pages/Index/components';
import { getScrollY, labelNumberWidth } from '@/utils';

import { DosageUnit } from '../../../dosage';
import { UsageUnit } from '../../../usage';
import askGroupMedicalOrders from '../table/askGroupMedicalOrders';
import { orderClassDataSource } from '../table/datasource';

const OutHospitalHistoryImported = forwardRef((props: any, ref) => {
  const { rowSelection } = props;

  const [value, setValue, onValue] = useGetState<any>([]);
  const getValue: any = () => {
    const list = vsf.refs.aaa?.getEditForm()?.getFieldsValue(true);
    return group?.current?.onSliced?.(
      onValue?.()?.map((item) => {
        const option = list?.[item?.id ?? ''];
        return {
          ...item,
          ...option,
        };
      }),
    );
  };
  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'],
        ['dripSpeed'],
        ['frequency'],
        ['useDescription'],
        ['performDays'],
      ],
      tip() {},
    }),
  );
  useImperativeHandle(ref, () => {
    return {
      setValue: (value) => {
        setValue(group?.current?.onSliced?.(value));
      },
    };
  });
  return (
    <VSTable
      id="aaa"
      value={value}
      pagination={false}
      scroll={{
        y: getScrollY(isMinScreen ? 190 : 280),
      }}
      bordered
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
      }}
      className="aspirin-table"
      onChange={(v) => {
        setValue([...v]);
      }}
    >
      <VSTableColumn
        dataIndex={['check']}
        valueType="custom"
        renderFormItem={(...[, { record: recode }]) => {
          const selectedRows = rowSelection?.selectedRows as any[];
          const state = selectedRows?.map((item) => item?.id);
          const record = getValue()?.find((item) => item?.id === recode?.id);
          const _value = state?.includes(record?.id);
          let render: any = null;
          if (record?.groupName === 'aspirin-order-text-group-first') {
            render = (
              <Checkbox
                value={_value}
                onChange={(info) => {
                  const group = getValue()?.filter((...[, index]) =>
                    record?.groupIndex?.includes(index),
                  );
                  if (info) {
                    rowSelection?.onSelectedRowsChange?.(
                      [
                        ...selectedRows?.filter(
                          (item) =>
                            !group
                              ?.map((item: any) => item?.id)
                              ?.includes(item?.id),
                        ),
                        ...group,
                      ],
                      [],
                    );
                  } else {
                    rowSelection?.onSelectedRowsChange?.(
                      selectedRows?.filter(
                        (item) =>
                          !group
                            ?.map((item: any) => item?.id)
                            ?.includes(item?.id),
                      ),
                      [],
                    );
                  }
                }}
              />
            );
          }
          if (!record?.groupName) {
            render = (
              <Checkbox
                value={_value}
                onChange={(info) => {
                  if (info) {
                    rowSelection?.onSelectedRowsChange?.(
                      [...selectedRows, record],
                      [],
                    );
                  } else {
                    rowSelection?.onSelectedRowsChange?.(
                      selectedRows?.filter((item) => item?.id !== record?.id),
                      [],
                    );
                  }
                }}
              />
            );
          }
          if (
            typeof record?.supplyIndicator === 'boolean' &&
            record?.supplyIndicator === false
          ) {
            render = <Checkbox disabled />;
          }
          return <Flex justify="center">{render}</Flex>;
        }}
        title={() => {
          const selectedRows: any = rowSelection;
          const state = value;
          const _value: any =
            selectedRows?.selectedRows?.length === state?.length &&
            selectedRows?.selectedRows?.length !== 0 &&
            state?.length !== 0;
          return (
            <Flex justify="center">
              <Checkbox
                value={_value}
                disabled={!state?.length}
                indeterminate={
                  selectedRows?.selectedRows?.length > 0 &&
                  selectedRows?.selectedRows?.length < state.length
                }
                onChange={(info) => {
                  if (info) {
                    selectedRows?.onSelectedRowsChange?.(state, []);
                  } else {
                    selectedRows?.onSelectedRowsChange?.([], []);
                  }
                }}
              />
            </Flex>
          );
        }}
        preview
        hideInTable={!rowSelection}
        fixed="left"
        // width={30}

        width={labelNumberWidth(3)}
      />
      <VSTableColumn
        title="类别"
        dataIndex={['orderClass']}
        valueType="select"
        dataSource={orderClassDataSource}
        preview
        // width={25}
        width={labelNumberWidth(4)}
      />
      <VSTableColumn
        title="医嘱"
        dataIndex={['orderText']}
        valueType="custom"
        // width="220px"

        width={labelNumberWidth(10)}
        className="aspirin-table-order-cell"
        render={(element, record) => {
          return (
            <div
              className={classNames('aspirin-order-text', record?.groupName)}
            >
              {record?.orderText}
            </div>
          );
        }}
        editable={false}
      />
      <VSTableColumn
        title="剂量"
        dataIndex={['dosage']}
        valueType="inputUnit"
        // width="50px"

        width={labelNumberWidth(4)}
        editable={false}
        render={(...[, record]) => <DosageUnit value={record?.dosage} />}
      />
      <VSTableColumn
        title="用量"
        dataIndex={['usage']}
        valueType="inputUnit"
        // width="50px"

        width={labelNumberWidth(4)}
        render={(...[, record]) => <UsageUnit value={record?.usage} />}
        editable={false}
      />
      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="administrationSelect"
        // width={50}

        width={labelNumberWidth(4)}
        editable={false}
        fieldProps={(record) => {
          return { record };
        }}
      />
      <VSTableColumn
        dataIndex={['frequency', 'frequency']}
        title="频次"
        valueType="text"
        // width={50}

        width={labelNumberWidth(4)}
        editable={false}
        render={(...[, record]) => {
          return record?.frequency?.frequency ?? record?.frequency?.label ?? '';
        }}
      />
      <VSTableColumn
        dataIndex={['useDescription']}
        title="用法"
        valueType="select"
        fieldProps={{
          allowClear: true,
          showSearch: true,
        }}
        // width={50}

        width={labelNumberWidth(4)}
        dataSource={vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT}
        editable={false}
      />
      <VSTableColumn
        dataIndex={['performDays']}
        title="天数"
        valueType="digit"
        // width={30}

        width={labelNumberWidth(4)}
        editable={false}
        fieldProps={{
          addonAfter: '天',
          // allowClear: false,
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        render={(_, value) => {
          return value?.performDays ? value?.performDays + '天' : '';
        }}
      />
      <VSTableColumn
        dataIndex={['totalAmount']}
        title="数量"
        valueType="inputUnit"
        fieldProps={{
          allowClear: true,
          width: '100%',
        }}
        render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
        editable={false}
        // width={30}

        width={labelNumberWidth(4)}
      />
      <VSTableColumn
        dataIndex={['performDepartment', 'departmentName']}
        title="执行科室"
        valueType="text"
        // width={100}

        width={labelNumberWidth(7)}
        preview
      />
    </VSTable>
  );
});

OutHospitalHistoryImported.displayName = 'OutHospitalHistoryImported';

export default OutHospitalHistoryImported;
