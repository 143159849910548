import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeController_getNoOrderOutpBillingDetailGroupList_d47375:
    {
      method: 'post',
      url: '/api/medical-technology/get-no-order-outp-billing-detail-group-list',
      parameterFomatter: (data?: { outpVisitId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: OutpBillingDetailGroupByOrderDateVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
});
