import { createServices } from '@vs/vsf-boot';
export default createServices({
  FinancePrePaymentController_getPrePaymentInfo_aa8a03: {
    method: 'post',
    url: '/api/finance-payment/get-pre-payment-info',
    parameterFomatter: (data?: {
      displayId: string;
      patientId: number;
      visitId: number;
      prePaymentType: PrePaymentTypeEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PrePaymentInfoVoFinancePaymentEntranceWebVo,
    ) => data,
  },
});
