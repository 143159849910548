import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpExpenseListController_getInpExpenseListByCondition_fcc4fb: {
    method: 'post',
    url: '/api/inp-billing/get-inp-expense-list-by-condition',
    parameterFomatter: (data?: {
      qto: InpBillingDetailFullNewEntityQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailFullVoInpBillingEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoInpBillingEntranceWebVoInpbillingdetailfullvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
});
