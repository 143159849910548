import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrderDischargeDrugController_getOrderDischargeDrug_07a55d: {
    method: 'post',
    url: '/api/clinic-record-order/get-order-discharge-drug',
    parameterFomatter: (data?: {
      qto: DischargeDrugQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDischargeDrugVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  OrderDischargeDrugController_updatePerformPrintTime_5fac0a: {
    method: 'post',
    url: '/api/clinic-record-order/update-perform-print-time',
    parameterFomatter: (data?: { orderIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
});
