import React, { useEffect } from 'react';
import './index.less';
import snowflake from '@/assets/icon/snowflake.png';
import sun from '@/assets/icon/sun.png';
import { Tooltip, Badge } from '@vs/vsf-kit';
export function TableCartTitle(props) {
  const { outpSpecialClinicName, data } = props;
  const visitTypeData={
    OUTP:'门诊',
    ERP:'急诊',
    PHYSICAL_EXAM:'体检'
  }
  const visitColor = {
    OUTP: '#50bc35',
    ERP: '#FF7E7E',
    PHYSICAL_EXAM: '#3276E8',
  };
  console.log(data);
  return (
    <div className="appointment-cart-main">
      <Badge.Ribbon
        text={visitTypeData?.[data?.visitType]}
        color={visitColor?.[data?.visitType]}
      >
        <div className="cart-containter">
          <div className="cart-icon">
            {/* <div className="cart-bg">
              <div className="cart-bg-title">
                {visitTypeData[data?.visitType]}
              </div>
            </div> */}
          </div>
          <div className="cart-number">
            <div className="cart-number-type">{data?.clinicType}</div>
            <div className="cart-doctor">{data?.doctor?.staffName}</div>
          </div>
          <div className="cart-outpatient">{data?.clinicLabel}</div>
          <div className="cart-departments">
            <div className="cart-departments-title">科室：</div>
            <div className="cart-departments-content">
              {data?.departmentName}
              {/* <Tooltip title={data?.departmentName}>{data?.departmentName}</Tooltip> */}
            </div>
          </div>
          <div className="cart-departments" style={{ marginBottom: '16px' }}>
            <div className="cart-departments-title">专科：</div>
            <div className="cart-departments-content">
              <Tooltip title={outpSpecialClinicName}>
                {' '}
                {outpSpecialClinicName}
              </Tooltip>
            </div>
          </div>
        </div>
      </Badge.Ribbon>
    </div>
  );
}
