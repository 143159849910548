import React, { Fragment, useEffect, useState, useContext } from 'react';
import { VSFormItem, VSForm, VSFormLayout, Divider } from '@vs/vsf-kit';

import { Determine, Flex } from '@/pages/Index/components';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';

function modalCom({ data, id, extendedData, list }) {
  const [departmentNameList, setDepartmentNameList] = useState();
  const [extendedDataForm, setExtendedDataForm] = useState();
  const [nameValue, setNameValue] = useState();
  const [codeValue, setCodeValue] = useState();
  const [codeVersion, setCodeVersion] = useState();

  const [showRecordData, setShowRecordData] = useState();

  useEffect(() => {
    getDepartmentNameList();
    if (data) {
      vsf?.refs?.operationDictionaryFrom?.setFieldsValue(data);
    }
  }, [data]);

  useEffect(() => {
    if (codeValue || nameValue || codeVersion) {
      queryRepeatRecord();
    }
  }, [codeValue, nameValue, codeVersion]);

  const queryRepeatRecord = async () => {
    const res =
      await vsf?.services?.OperationDictionaryController_queryRepeatRecord_653e13?.(
        {
          eo: {
            operationCode: codeValue,
            operationName: nameValue,
            codeVersion: codeVersion,
          },
        },
      );

    setShowRecordData(res?.data);
  };

  const getDepartmentNameList = async () => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentQto_1c2a91?.(
        { qto: { branchInstitutionIdIs: id } },
      );
    const _res = res?.data?.map((item) => {
      return {
        label: item?.departmentName,
        value: item?.departmentCode,
      };
    });
    setDepartmentNameList(_res);
    return _res;
  };
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              paddingBottom: '20px',
            }}
          >
            <div
              style={{
                width: '4px',
                height: '20px',
                backgroundColor: '#206bdd',
                marginRight: '15px',
                marginTop: '2px',
              }}
            ></div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              编辑条码编辑区
            </div>
          </div>
          <VSForm id="operationDictionaryFrom" labelAlign="left">
            <VSFormItem
              name={['id']}
              label="主键"
              style={{ display: 'none' }}
              valueType="digit"
              fieldProps={{}}
            />
            <VSFormLayout key="1" columnCount={3} labelWidth={100}>
              <VSFormItem
                name={['operationName']}
                label="手术名称"
                valueType="text"
                rules={[
                  {
                    required: true,
                  },
                ]}
                fieldProps={{
                  onChange: (event) => {
                    setNameValue(event?.target?.value);

                    vsf?.refs?.operationDictionaryFrom?.setFieldValue(
                      'inputCode',
                      askRomanAlphabet(event?.target?.value),
                    );
                  },
                }}
              />

              <VSFormItem
                name={['codeVersion']}
                label="编码体系"
                valueType="select"
                rules={[
                  {
                    required: true,
                  },
                ]}
                dataSource={[
                  { label: 'ICD-10', value: 'ICD-10' },
                  { label: 'ICD-9', value: 'ICD-9' },
                  { label: 'TCD', value: 'TCD' },
                ]}
                fieldProps={{
                  showSearch: true,
                  onChange: (value) => {
                    setCodeVersion(value);
                  },
                }}
              />

              <VSFormItem
                name={['daySurgeryIndicator']}
                label="院内日间"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationCode']}
                label="手术编码"
                valueType="text"
                rules={[
                  {
                    required: true,
                  },
                ]}
                fieldProps={{
                  onChange: (event) => {
                    setCodeValue(event?.target?.value);
                  },
                }}
              />

              <VSFormItem
                name={['operationDepartmentCode']}
                label="执行科室"
                valueType="select"
                fieldProps={{ showSearch: true }}
                dataSource={departmentNameList}
              />

              <VSFormItem
                name={['forceBloodIndicator']}
                label="是否强制备血"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationDescription']}
                label="描述"
                valueType="text"
                fieldProps={{}}
              />
              <VSFormItem
                name={['incisionLevelCode']}
                label="切口等级"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.WOUND_GRADE_DICT}
                fieldProps={{ showSearch: true }}
              />
              <VSFormItem
                name={['restrictiveTechnologyIndicator']}
                label="是否国家级限"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['inputCode']}
                label="拼音码"
                valueType="text"
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationInfoType']}
                label="操作信息"
                valueType="select"
                dataSource={[
                  { label: '手术', value: 'OPERATION' },
                  { label: '介入', value: 'INTERVENTIONAL' },
                  { label: '诊断性操作', value: 'DIAGNOSTIC_PROCEDURE' },
                  { label: '治疗性操作', value: 'THERAPEUTIC_PROCEDURE' },
                  { label: '操作', value: 'PROCEDURE' },
                ]}
                fieldProps={{ showSearch: true }}
              />

              <VSFormItem
                name={['needTransportIndicator']}
                label="是否需要运送"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['standardName']}
                label="标准名称"
                valueType="text"
                fieldProps={{}}
              />

              <VSFormItem
                name={['operationLevel']}
                label="手术等级"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.OPERATION_SCALE_DICT}
                fieldProps={{ showSearch: true }}
              />
              <VSFormItem
                name={['transportAllowModify']}
                label="是否允许修改"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['insuranceNationCode']}
                label="国家编码"
                valueType="text"
                fieldProps={{}}
              />

              <VSFormItem
                name={['transportTool']}
                label="默认运输工具"
                valueType="select"
                dataSource={vsf?.stores?.dbenums?.enums?.TRANSFER_METHOD_DICT}
                fieldProps={{ showSearch: true }}
              />
              <VSFormItem
                name={['notNeedArrange']}
                label="不能被安排"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['threePublicHandleType']}
                label="三公操作类别"
                valueType="select"
                dataSource={vsf.stores.dbenums.enums.ANESTHETIC_USE_PURPOSE}
                fieldProps={{ showSearch: true }}
              />
              <VSFormItem
                name={['levelFourIndicator']}
                label="三公四级"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['minimalInvasiveIndicator']}
                label="三公微创"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['standardCode']}
                label="标准编码"
                valueType="switch"
                initialValue={false}
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div
          style={{
            borderLeft: '1px solid gray',
            width: '25%',
            paddingLeft: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              paddingBottom: '20px',
            }}
          >
            <div
              style={{
                width: '4px',
                height: '20px',
                backgroundColor: '#206bdd',
                marginRight: '15px',
                marginTop: '2px',
              }}
            ></div>
            <div
              style={{
                fontSize: '16px',
                fontWeight: '700',
              }}
            >
              重码显示区
            </div>
          </div>

          {showRecordData && (
            <>
              <div
                style={{
                  color: 'red',
                  fontSize: '16px',
                  marginTop: '20px',
                  marginLeft: '20px',
                }}
              >
                {showRecordData?.operationName}({showRecordData?.operationCode})
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default modalCom;
