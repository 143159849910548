import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import AllergyTable from '@/pages/Index/cpoe/center/left/previous_history/allergy_table';
import DiseaseTable from '@/pages/Index/cpoe/center/left/previous_history/disease_table';
import FamilyTable from '@/pages/Index/cpoe/center/left/previous_history/family_table';
import OperationTable from '@/pages/Index/cpoe/center/left/previous_history/operation_table';

const Index = (props, ref) => {
  const [open, setOpen] = useState(false);

  const handelOpen = (v = false, value?) => {
    // gcsScore
    setOpen(v);
  };

  const handelData = () => {
    // const _data = [
    //   {
    //     label: '睁眼反应',
    //     formItemName: 'openEyeResponse',
    //     children: vsf.stores.dbenums.enums.OPEN_EYE_REACT_DICT,
    //   },
    //   {
    //     label: '语言反应',
    //     formItemName: 'languageResponse',
    //     children: vsf.stores.dbenums.enums.LANGUAGE_REACT_DICT,
    //   },
    //   {
    //     label: '意识反应',
    //     formItemName: 'consciousResponse',
    //     children: vsf.stores.dbenums.enums.CONSCIOUS_REACT_DICT,
    //   },
    // ];
    // return _data ?? [];
    try {
      const res = vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.filter((i) => {
        // 过滤preExamScoreTypeCode为01，未停用
        return (
          i?.extensionJson &&
          JSON.parse(i?.extensionJson)?.preExamScoreTypeCode == '01' &&
          !i?.deprecated
        );
      })?.map((item) => {
        return {
          ...item,
          children:
            item?.children?.length > 0
              ? item?.children
                  ?.filter((childrenItem) => {
                    // 过滤未停用
                    return !childrenItem?.deprecated;
                  })
                  ?.map((childrenItem) => {
                    //   拼接分数
                    return {
                      ...childrenItem,
                      itemScroe: childrenItem?.extensionJson
                        ? JSON.parse(childrenItem?.extensionJson)?.score
                        : null,
                      label: childrenItem?.extensionJson
                        ? JSON.parse(childrenItem?.extensionJson)?.score +
                          '.' +
                          childrenItem?.label
                        : childrenItem?.label,
                      labelName: item?.pinyin + item?.value,
                    };
                  })
                  .sort((a: any, b: any) => {
                    return b?.itemScroe - a?.itemScroe;
                  })
              : [],
        };
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={900}
        bodyStyle={{
          maxHeight: 500,
          overflowY: 'scroll',
        }}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={<div style={{ color: '#3276E8' }}>既往史</div>}
        // onOk={() => {
        //   const formValue = vsf.refs?.gcsModal?.getFieldsValue();
        //   const res = Object.values(formValue)?.reduce((pre: any, cur: any) => {
        //     console.log(pre, cur?.itemScroe, Object.values(formValue));

        //     return Number(pre) + Number(cur?.itemScroe ?? 0);
        //   }, 0);

        //   vsf.refs?.preExamMainForm?.setFieldsValue({
        //     gcsScore: res,
        //   });
        //   handelOpen(false);
        // }}
        onCancel={() => {
          handelOpen(false);
        }}
        footer={
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => {
                handelOpen(false);
              }}
            >
              关闭
            </Button>
          </div>
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AllergyTable editModalWidth={900} />
          <DiseaseTable editModalWidth={900} />
          <OperationTable editModalWidth={900} />
          <FamilyTable editModalWidth={900} />
        </div>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// GCS code=19 vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT
