import vsf, { definePage } from '@vs/vsf-boot';
import {
  Row,
  Col,
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTable,
  VSForm,
  VSFormLayout,
  VSFormItem,
  Space,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { values } from 'lodash';
import React, { useEffect } from 'react';
import { chargeRecordQuery } from './Service';
import { getScrollYRem } from '@/utils';

import '@/style/adaptation.less';

const hospitalizationBillList = ({ selecteData, dataSorce }) => {
  /**
   * convertGetExpenseListRes2InpExpenseGroupList
   * @param {any} input
   * @returns {any}
   */
  const convertGetExpenseListRes2InpExpenseGroupList = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.inpReceiptClass = _.get(d, 'inpReceiptClass');
        obj.subTotal = _.get(d, 'subTotal');
        obj.inpBillingDetailVoList = _.get(d, 'inpBillingDetailVoList');
        obj.id = i;
        return obj;
      }) ?? []),
    );
    return output;
  };
  /**
   * convertGetExpenseListRes2InpExpenseList
   * @param {any} input
   * @returns {any}
   */
  const convertGetExpenseListRes2InpExpenseList = (input) => {
    const _ = window._ ?? {};
    const output = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {}; // TODO 请确认下行字段是否符合逻辑
        obj.tmpId = i;
        obj.inpVisitId = _.get(d, `inpBillingDetailVoList.[${i}].inpVisitId`); // TODO 请确认下行字段是否符合逻辑
        obj.startDate = _.get(d, `inpBillingDetailVoList.[${i}].startDate`); // TODO 请确认下行字段是否符合逻辑
        obj.endDate = _.get(d, `inpBillingDetailVoList.[${i}].endDate`); // TODO 请确认下行字段是否符合逻辑
        obj.patientId = _.get(d, `inpBillingDetailVoList.[${i}].patientId`); // TODO 请确认下行字段是否符合逻辑
        obj.itemId = _.get(d, `inpBillingDetailVoList.[${i}].itemId`); // TODO 请确认下行字段是否符合逻辑
        obj.itemName = _.get(d, `inpBillingDetailVoList.[${i}].itemName`); // TODO 请确认下行字段是否符合逻辑
        obj.amount = _.get(d, `inpBillingDetailVoList.[${i}].amount`); // TODO 请确认下行字段是否符合逻辑
        obj.unit = _.get(d, `inpBillingDetailVoList.[${i}].unit`); // TODO 请确认下行字段是否符合逻辑
        obj.price = _.get(d, `inpBillingDetailVoList.[${i}].price`); // TODO 请确认下行字段是否符合逻辑
        obj.cost = _.get(d, `inpBillingDetailVoList.[${i}].cost`); // TODO 请确认下行字段是否符合逻辑
        obj.orderDate = _.get(d, `inpBillingDetailVoList.[${i}].orderDate`); // TODO 请确认下行字段是否符合逻辑
        obj.totalSelfPay = _.get(
          d,
          `inpBillingDetailVoList.[${i}].totalSelfPay`,
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
  const { data: list, run } = useRequest(chargeRecordQuery, { manual: true });

  useEffect(() => {
    dataSorce && run({ inpVisitIdIs: dataSorce?.inpVisitId });
    console.log(dataSorce);
  }, [dataSorce]);
  return (
    <div>
      <div>
        <Row>
          <Col span={6}>病人姓名：{dataSorce?.name}</Col>
          <Col span={6}>住院号：</Col>
          <Col span={6}>床号：</Col>
          <Col span={6}>费用病区：</Col>
        </Row>
        <Row style={{ marginTop: '24px' }}>
          <Col span={16}>
            <VSForm>
              <VSFormLayout columnCount={2}>
                <VSFormItem
                  name={'inpSettleIdIsNullOrNot'}
                  valueType="select"
                  label={'结算类型'}
                  dataSource={[
                    { label: '未结算', value: true },
                    { label: '已结算', value: false },
                  ]}
                  fieldProps={{
                    // onPressEnter: (v) => {

                    // }
                    onSelect: (v) => {
                      run({
                        inpVisitId: dataSorce?.inpVisitId,
                        inpSettleIdIsNullOrNot: v.value,
                      });
                    },
                  }}
                />
                <VSFormItem
                  name={'orderDateRangeIn'}
                  valueType="dateRange"
                  label={'费用起止日期'}
                  fieldProps={{
                    onChange: (v) => {
                      const param = {
                        begin: v[0],
                        end: v[1],
                        beginInclude: true,
                        endInclude: true,
                      };
                      run({
                        inpVisitId: dataSorce?.inpVisitId,
                        orderDateRangeIn: param,
                      });
                    },
                  }}
                />
              </VSFormLayout>
            </VSForm>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}></Col>
        </Row>
      </div>
      <VSPromiseTable
        vsid="25053"
        id="inpExpenseGroupList"
        onFetch={() => list}
        pagination={false}
        bordered
        scroll={{
          y: getScrollYRem(35),
        }}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="病人姓名"
          valueType="text"
          // search
          order={0}
          columnKey={'name'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inpVisitId']}
          title="住院号"
          valueType="text"
          // search
          order={0}
          columnKey={'inpVisitId'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          // search
          order={0}
          columnKey={'bedLabel'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['wardName']}
          title="费用病区"
          valueType="text"
          // search
          order={0}
          columnKey={'wardName'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inpSettleIdIsNullOrNot']}
          title="结算类型"
          valueType="switch"
          // search
          order={0}
          columnKey={'inpSettleIdIsNullOrNot'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderDateRangeIn']}
          title="费用起止时间"
          valueType="dateRange"
          // search
          order={0}
          columnKey={'orderDateRangeIn'}
          hideInTable
          searchConfig={{
            transform: (values) => {
              if (!Array.isArray(values)) return values;
              return {
                orderDateRangeIn: {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              };
            },
          }}
          transform={(values) => {
            if (!Array.isArray(values)) return values;
            return ['orderDateRangeIn'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              {
                begin: values[0],
                end: values[1],
                beginInclude: true,
                endInclude: true,
              },
            );
          }}
          convertValue={(obj) => {
            if (Array.isArray(obj)) return obj;
            return [obj?.begin, obj?.end];
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['startDate']}
          title="开始时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          render={(dom, values) => {
            if (values.inpReceiptClass) {
              vsf.stores.dbenums?.enums?.INP_RCPT_FEE_DICT?.find(
                (item) => item?.value === values.inpReceiptClass,
              )?.label ?? '';
            } else {
              return dom;
            }
          }}
        />

        <VSTableColumn
          dataIndex={['endDate']}
          title="终止时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          render={(dom, values) => {
            if (values.subTotal) {
              return `费用小记:${Number(values.subTotal).toFixed(2)}`;
            } else {
              return dom;
            }
          }}
        />

        <VSTableColumn
          dataIndex={['itemId']}
          title="项目编码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '项目编码长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 20,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="项目单价"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['cost']}
          title="应收费用"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['totalSelfPay']}
          title="医保自费金额"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </div>
  );
};

export default definePage(hospitalizationBillList);
