import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  InputNumber,
  InputProps,
  Row,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest, useUpdateEffect } from 'ahooks';
import { Table } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { mockDoctorId } from '@/mock';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';

import ComplexSelect from '../complex_select';
import Determine from '../determine';
import { useInputEnter } from '../dosage/utils';
import Flex from '../flex';
import { askFixed } from '../func/ask';
import { useFrequencyEffect, useUpdateAsyncEffect } from '../func/hooks';

/**
 * DrugSelect
 */
const StorageSelect = forwardRef((props: any, ref: any) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    id: key,
    record,
    group,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });

  const form = Form?.useFormInstance();
  const { data } = useRequest(async () => {
    const res =
      await vsf?.services?.ClinicRecordOrderDrugController_getStorageIds_82d8b5?.();
    const list = res?.data?.map((item) => ({
      departmentName: item?.storageName,
      id: item?.storageDepartmentId,
      storageId: item?.id,
    }));
    return list;
  });

  const get = useCallback(async (params) => {
    const res =
      await vsf?.services?.DrugDrugController_queryPagefallByDrugDictionaryDrugListQto_270f4c?.(
        {
          qto: {
            ...params,
            form: 0,
            size: 1,
            doctorIdIs: vsf?.stores?.user?.staffInfo?.id,
          },
        },
      );
    if (res?.code === 200 && res?.data?.result?.length) {
      return [res?.data?.result?.[0]?.amount > 0, res?.data?.result?.[0]];
    } else {
      return [false];
    }
  }, []);

  useUpdateAsyncEffect(async () => {
    if (data?.length) {
      const [value] = data;
      if (group?.length) {
        for (const item of group?.slice(1)) {
          form.setFieldValue([item, 'performDepartment'], value);
        }
      } else {
        const [supplyIndicator, one] = await get?.({
          storageIdIn: [value?.storageId],
          drugItemIdIn: [record?.drugOrder?.drugItemId],
        });

        form.setFieldValue([key, 'supplyIndicator'], supplyIndicator);
        form.setFieldValue([key, 'retailPrice'], one?.retailPrice);
        form?.setFieldValue(
          [key, 'allPrice'],
          one?.retailPrice * record?.totalAmount?.value,
        );
      }
      onChange?.(value);
    }
  }, [data]);

  return (
    <Flex justify="space-between" gap={10}>
      <Determine condition={record?.supplyIndicator === false}>
        <Icon type="icon-que" size={24} />
      </Determine>
      <Select
        ref={ref}
        min={0}
        value={value}
        style={{
          minWidth: '70%',
        }}
        fieldNames={{
          label: 'departmentName',
          value: 'id',
        }}
        dataSource={data}
        onChange={async (value) => {
          const [supplyIndicator, one] = await get?.({
            storageIdIn: [value?.id],
            drugItemIdIn: [record?.drugOrder?.drugItemId],
          });
          form.setFieldValue([key, 'supplyIndicator'], supplyIndicator);
          form.setFieldValue([key, 'retailPrice'], one?.retailPrice);
          form?.setFieldValue(
            [key, 'allPrice'],
            one?.retailPrice * record?.totalAmount?.value,
          );
          onChange?.(value);
        }}
        {...rest}
        {...fieldEnterProps}
      />
    </Flex>
  );
});

StorageSelect.displayName = 'StorageSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'storageSelect',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      return <>{value}</>;
    },
  }),
)(StorageSelect) as typeof StorageSelect;
