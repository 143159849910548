import vsf, { definePage } from '@vs/vsf-boot';
import { useEventListener } from 'ahooks';
import { useEffect, useMemo, useRef } from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

/**
 * 滚定监听表格
 * @returns
 */
type useScrollType = {
  target: HTMLElement;
};

export default function useScroll(func: () => void, params?) {
  const id = useMemo(() => getUUID(), []);
  const table = document.querySelector(`[data-id="${id}"] .ant-table-body`);
  const time = useRef<any>(0);
  const lowerThreshold = params?.lowerThreshold ?? 50;

  useEventListener(
    'scroll',
    ({ target }) => {
      const { offsetHeight, scrollHeight, scrollTop } = target;

      // if (scrollHeight - scrollTop <= offsetHeight) {
      if (scrollHeight - scrollTop - offsetHeight <= lowerThreshold) {
        clearTimeout(time.current);
        time.current = setTimeout(() => {
          func?.();
          // table?.scrollTo({
          //   top: target.scrollTop - 100,
          // });
        }, params?.ms ?? 500);
      } else {
        clearTimeout(time.current);
      }
    },
    {
      target: table,
    },
  );
  return {
    id,
  };
}
