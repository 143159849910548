// 列表查询入参
export const convertVSPromiseTableBedInfoListQro2QueryClinicBedDetailVoByClinicBedDetailQtoPagedReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.wardIdIs = _.get(input, 'wardIdIs')?.id; // 护理单元
    output.bedClassIs = _.get(input, 'bedClassIs'); // 床位等级
    output.bedStatusIs = _.get(input, 'bedStatusIs'); // 床位状态
    output.wardBranchInstitutionIdIs = _.get(
      input,
      'wardBranchInstitutionIdIs',
    ); // 院区
    output.bedSettingTypeIs = _.get(input, 'bedSettingTypeIs'); // 编制类型
    output.bedLabelLike = _.get(input, 'bedLabelLike'); // 床号
    output.departmentIdIs = _.get(input, 'departmentIdIs')?.id; // 所属科室
    output.bedTypeIs = _.get(input, 'bedTypeIs'); // 床位类型
    return output;
  };
// table
export const convertQueryClinicBedDetailVoByClinicBedDetailQtoPagedRes2VSPromiseTableBedInfoListRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.sortNumber = _.get(d, 'sortNumber');
        obj.bedRoomId = _.get(d, 'bedRoomId');
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.wardId = _.get(d, 'ward.id');
        obj.wardName = _.get(d, 'ward.departmentName');
        obj.departmentId = _.get(d, 'department.id');
        obj.departmentName = _.get(d, 'department.departmentName');
        obj.bedClass = _.get(d, 'bedClass');
        obj.bedClassName = _.get(d, 'bedClassName');
        var bedClassPrice = _.get(d, 'bedClassPrice');
        obj.bedClassPrice = bedClassPrice
          ? Number(bedClassPrice).toFixed(2)
          : '';
        obj.bedType = _.get(d, 'bedType');
        obj.bedStatus = _.get(d, 'bedStatus');
        obj.bedSettingType = _.get(d, 'bedSettingType');
        obj.patternName = _.get(d, 'patternName');
        obj.memo = _.get(d, 'memo');
        return obj;
      }) ?? []),
    );
    return output;
  };
//   保存
export const convertVSPromiseTableBedInfoList2SaveBedReq = (input) => {
  // console.log(input, 'ppp');

  const _ = window._ ?? {};
  const output = {};
  output.btoParam = {};
  output.btoParam.sortNumber = _.get(input, 'sortNumber'); // 床位序号
  output.btoParam.bedClass = _.get(input, 'bedClass'); // 床位等级
  output.btoParam.bedStatus = _.get(input, 'bedStatus'); // 床位状态
  output.btoParam.bedType = _.get(input, 'bedType'); // 床位类型
  output.btoParam.departmentId = _.get(input, 'department.id'); // 所属科室
  output.btoParam.bedRoomId = _.get(input, 'bedRoomId'); // 房间号
  output.btoParam.patternCode = _.get(input, 'patternCode'); // 计费模板
  output.btoParam.memo = _.get(input, 'memo'); // 备注
  output.btoParam.bedSettingType = _.get(input, 'bedSettingType');
  output.btoParam.wardId = _.get(input, 'ward.id'); // 护理单元
  output.btoParam.wardName = _.get(input, 'ward.departmentName'); // 护理单元
  output.btoParam.bedLabel = _.get(input, 'bedLabel'); // 床标号
  output.btoParam.id = _.get(input, 'id'); // 主键ID
  output.btoParam.bedSettingType = _.get(input, 'bedSettingType'); // 床位编制类型
  output.extApiParam = {}; // 额外参数
  return output;
};
// 编辑
export const convertGetClinicBedDetailVoByIdRes2VSFormBedInfo = (input) => {
  // console.log(input, 'ppp');
  const _ = window._ ?? {};
  const output = {};
  output.id = _.get(input, 'data.id'); // 主键
  output.sortNumber = _.get(input, 'data.sortNumber'); // 床位序号
  output.bedLabel = _.get(input, 'data.bedLabel'); // 床标号
  output.bedRoomId = _.get(input, 'data.bedRoomId'); // 房间号
  output.ward = {}; // 护理单元
  output.ward.id = _.get(input, 'data.ward.id'); // 护理单元ID
  output.ward.departmentName = _.get(input, 'data.ward.departmentName'); // 护理单元名称
  output.wardId = _.get(input, 'data.ward.id'); // 护理单元ID
  output.department = {}; // 所属科室
  output.department.id = _.get(input, 'data.department.id'); // 所属科室ID
  output.department.departmentName = _.get(
    input,
    'data.department.departmentName',
  ); // 所属科室名称
  output.departmentId = _.get(input, 'data.department.id'); // 所属科室ID
  output.bedClass = _.get(input, 'data.bedClass'); // 床位等级
  const bedClassPrice = _.get(input, 'data.bedClassPrice'); // 床位价格
  output.bedClassPrice = bedClassPrice ? Number(bedClassPrice).toFixed(2) : ''; // 床位价格
  output.bedType = _.get(input, 'data.bedType'); // 床位类型
  output.bedStatus = _.get(input, 'data.bedStatus'); // 床位状态
  output.bedSettingType = _.get(input, 'data.bedSettingType'); // 床位编制类型
  output.pattern = {}; // 计费模板
  output.pattern.patternCode = _.get(input, 'data.patternCode'); // 计费模板编码
  output.pattern.patternName = _.get(input, 'data.patternName'); // 计费模板名称
  output.patternCode = _.get(input, 'data.patternCode');
  output.memo = _.get(input, 'data.memo'); // 备注
  return output;
};
