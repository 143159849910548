import SelectForm, {
  askSelectFormInitValue,
} from '@/pages/Index/components/select_form';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf from '@vs/vsf-boot';
import {
  VSFormItem,
  VSControlledForm,
  VSTableColumn,
  VSFormLayout,
  Row,
  Col,
  VSTable,
  VSTableAddition,
  Space,
  Divider,
  Section,
  VSTableToolbar,
  Form,
} from '@vs/vsf-kit';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { useMount, useUpdateEffect } from 'ahooks';
import { useFrequencyMemo } from '@/pages/Index/components/func/hooks';
import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import { labelNumberWidth } from '@/utils';

const Table = memo(
  (props) => {
    const [department, setDepartment] = useState([]);
    const [branchInstitution, setBranchInstitution] = useState([]);
    const [institutionIdIs, setInstitutionIdIs] = useState('');
    const branchInstitutionIs = vsf.refs?.TreatForm?.getFieldValue(
      'treatItemExtensionBtoList',
    );
    const clinicPerformDepartmentBtoList =
      Form?.useWatch('clinicPerformDepartmentBtoList') ?? [];

    /**
     * 获取科室院区
     */
    const getBranchInstitution = useCallback(async () => {
      const res =
        await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.(
          {},
        );
      if (res.code === 200) {
        setInstitutionIdIs(res.data.id);
        setBranchInstitution(
          res.data.branchInstitutionList.map((item) => {
            return {
              label: item.institutionName,
              value: item.id,
            };
          }),
        );
      }
    }, []);

    const get = useCallback(async () => {
      if (branchInstitutionIs?.select) {
        const res =
          await vsf?.services?.DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9?.(
            {
              qto: {
                institutionIdIs,
                stopIndicatorIs: false,
                branchInstitutionIdIn: branchInstitutionIs?.select,
              },
            },
          );
        if (res.code === 200) {
          const dataSource = res.data.map((item) => {
            const { id, ...rest } = item;
            return {
              ...rest,
              label: `${item?.departmentName}(${item.branchInstitution?.institutionName})`,
              value: id,
            };
          });
          setDepartment(dataSource);
        }
      } else {
        setDepartment([]);
      }
    }, [branchInstitutionIs?.select]);

    /**
     * 当院区发生改变请求执行科室院区
     */
    useUpdateEffect(() => {
      get();
    }, [get]);

    useUpdateEffect(() => {
      /**
       * 删除院区,也删除对应已选科室
       */
      const list = clinicPerformDepartmentBtoList?.filter((item) => {
        const id =
          item?.branchInstitution?.id ??
          item?.performDepartment?.branchInstitution?.id;
        return branchInstitutionIs?.select?.includes(id) || !Boolean(id);
      });

      vsf.refs.TreatForm.setFieldValue('clinicPerformDepartmentBtoList', list);
    }, [branchInstitutionIs?.select]);

    const onRemove = (id) => {
      vsf?.refs?.TreatTable?.remove(id);
    };

    useEffect(() => {
      getBranchInstitution();
    }, []);

    const state = useFrequencyMemo(() => props.value, 2, [props.value]);

    const dataSource = (value) => {
      const list = department
        ?.filter((item) => {
          return !clinicPerformDepartmentBtoList
            ?.map?.((item) => item?.performDepartment?.value)
            ?.includes(item?.value);
        })
        ?.map?.((item) => {
          const index = state?.findIndex?.((value) => {
            return value?.performDepartment?.value === item?.value;
          });
          return {
            ...item,
            ...(![null, void 0, -1].includes(index)
              ? { id: state[index]?.id }
              : {}),
          };
        });
      return value?.label
        ? list?.filter((item) => askSearch(value?.label, item))
        : list;
    };

    return (
      <VSTable
        className="aspirin-toolbar-title-box"
        editable={{
          editType: 'multiple',
          onFieldChange: (...arr) => {
            const [key, data, , form] = arr;
            form.setFieldValue(key, {
              ...data,
              branchInstitution: {
                label:
                  data?.performDepartment?.branchInstitution?.institutionName,
                value: data?.performDepartment?.branchInstitution?.id,
              },
            });
          },
          columnProps: {
            width: 70,
          },
          deleteText: <Icon type="icon-shanchu1" size={24} />,
          onCanDelete: () => null,
          editText: <Icon type="icon-bianji" size={24} />,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              onClick: (event, data) => {
                /**
                 * 判断是否创建过，是就删除提醒，否则直接删除
                 */
                if (!String(data.id).startsWith('create')) {
                  Tip({
                    content: `确定删除{【${
                      data.performDepartment?.label ?? ''
                    }】}，删除后不可恢复!`,
                    onOk: () => onRemove(data),
                  });
                } else {
                  onRemove(data);
                }
              },
            },
          ],
        }}
        onChange={props?.onChange}
        vsid="28842"
        id="TreatTable"
        onRecord={() => {
          return { id: 'create_' + Math.random() };
        }}
        value={props?.value}
        pagination={false}
        dragSort={{
          dragSortDataIndex: 'sort',
          onDragSortEnd: (sortedData) => {
            props?.onChange?.({
              ...vsf.refs.TreatForm?.getFieldsValue(),
              clinicPerformDepartmentBtoList: sortedData,
            });
            vsf.refs.TreatForm?.setFieldValue(
              'clinicPerformDepartmentBtoList',
              sortedData,
            );
          },
        }}
      >
        <VSTableColumn
          dataIndex="sort"
          title="排序"
          width={35}
          editable={false}
        />
        <VSTableColumn
          dataIndex={['performDepartment']}
          title="科室名称"
          valueType="select"
          fieldProps={{
            dataSource,
            showSearch: true,
          }}
          width={'30%'}
          shouldCellUpdate={() => true}
          fieldNames={{
            label: 'label',
            value: 'value',
          }}
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
        />
        <VSTableColumn
          dataIndex={['branchInstitution']}
          title="执行科室所属院区"
          valueType="select"
          dataSource={branchInstitution}
          width={'30%'}
          fieldProps={{
            disabled: true,
          }}
          shouldCellUpdate={() => true}
          preview
        />
        <VSTableColumn
          dataIndex={['index']}
          title="排序"
          valueType="index"
          width={70}
          fieldProps={{
            disabled: true,
          }}
        />
        <VSTableToolbar
          title={
            <Fragment>
              <Space className="aspirin-toolbar-title-box">
                <Space className="aspirin-toolbar-title-text-box">
                  执行科室
                </Space>
                本科室
                <VSFormItem
                  name={['includeCurrentDepartmentIndicator']}
                  style={{
                    height: '100%',
                    margin: 0,
                  }}
                  valueType="check"
                />
                包含并默认
              </Space>
            </Fragment>
          }
        >
          <VSTableAddition>新增</VSTableAddition>
        </VSTableToolbar>
      </VSTable>
    );
  },
  (oldProps, newProps) => Object.is(oldProps, newProps),
);

const Edit = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const isValue = useMemo(() => props.value, []);
  /**
   * 获取自身的院区
   */
  const getDataSource = useCallback(async () => {
    const res =
      await vsf?.services?.TreatController_getTreatClassWithInstitutionById_552f69(
        {
          id: isValue
            ? props?.value?.treatVsClinicItemBaseDto?.directTreatClassId
            : props?.data[0]?.id,
        },
      );
    if (res.code === 200) {
      setDataSource(
        res.data?.treatClassDictionaryExtensionDtoList.map((item) => ({
          label: item.branchInstitution?.institutionName,
          value: item.branchInstitution.id,
        })),
      );
    }
  }, []);

  /**
   * 获取已选执行科室
   */
  const getPerformDepartmentBtoList = async (treatItemId) => {
    return vsf?.services?.TreatController_getDepartmentByTreatItemId_e29acb?.({
      treatItemId,
    });
  };
  /**
   * 修改状态时数据回显
   */
  const getValue = useCallback(async () => {
    const [
      {
        data: {
          treatVsClinicItemBaseDto: {
            treatItemExtensionDtoList: treatItemExtensionBtoList,
            ...treatVsClinicItemBaseDto
          },
          ...value
        },
      },
      { data: clinicPerformDepartmentBtoList },
    ] = await Promise.all([
      vsf?.services?.ClinicItemBaseController_getTreatClinicItemDetailById_9d3b21?.(
        { id: props?.value.id },
      ),
      getPerformDepartmentBtoList(props.value?.treatVsClinicItemBaseDto?.id),
    ]);

    vsf.refs?.TreatForm?.resetFields();
    vsf.refs?.TreatForm?.setFieldsValue({
      ...value,
      treatVsClinicItemBaseDto,
      treatItemExtensionBtoList: askSelectFormInitValue({
        array: treatItemExtensionBtoList,
        select: ['branchInstitution', 'id'],
      }),
      clinicPerformDepartmentBtoList: clinicPerformDepartmentBtoList
        ?.map((item) => {
          const { id, departmentName, ...performDepartment } =
            item?.performDepartment;
          return {
            ...item,
            performDepartment: {
              ...performDepartment,
              label: departmentName,
              value: id,
            },
            branchInstitution: item?.performDepartment?.branchInstitution?.id,
          };
        })
        ?.reverse(),
    });
  }, []);

  useEffect(() => {
    if (isValue) {
      getValue();
    } else {
      const [treatSubClass, treatClass] = props.data;
      const len = props?.data?.filter((item) => item).length;
      vsf?.refs?.TreatForm?.setFieldsValue({
        treatVsClinicItemBaseDto: {
          ...(len > 1
            ? {
                treatSubClass: {
                  treatClassName: treatSubClass?.treatClassName,
                  id: treatSubClass?.id,
                },
                treatClass: {
                  treatClassName: treatClass?.treatClassName,
                  id: treatClass?.id,
                },
              }
            : {
                treatClass: treatSubClass,
              }),
          directTreatClassId: treatSubClass?.id,
        },
      });
    }
  }, []);

  useMount(() => {
    getDataSource();
  });

  const itemCode = useMemo(() => {
    return (
      new Date().toLocaleDateString().replaceAll('/', '') +
      (Math.random() * (99 * 10000)).toFixed(0)
    );
  }, []);

  return (
    <VSControlledForm
      vsid="25275"
      id="TreatForm"
      labelAlign="left"
      onChange={(_value) => {
        console.log(_value);
        props.onChange?.(_value);
      }}
    >
      <Row justify="space-between" gutter={50}>
        <Col span={12}>
          <Section title="全院区通用">
            <Divider />
            <VSFormItem
              name={['id']}
              label="主键"
              style={{ display: 'none' }}
              valueType="digit"
              fieldProps={{}}
            />
            <VSFormItem
              name={['treatVsClinicItemBaseDto', 'id']}
              label="主键"
              style={{ display: 'none' }}
              valueType="digit"
              fieldProps={{}}
            />

            <VSFormItem
              name={['treatVsClinicItemBaseDto', 'directTreatClassId']}
              label="直接关联主键"
              style={{ display: 'none' }}
              valueType="digit"
              fieldProps={{}}
            />
            <VSFormLayout
              key="3"
              columnCount={2}
              gutter={50}
              labelWidth={labelNumberWidth(7)}
            >
              <VSFormItem
                name={[
                  'treatVsClinicItemBaseDto',
                  'treatClass',
                  'treatClassName',
                ]}
                label="治疗大类"
                fieldProps={{ disabled: true }}
                valueType="text"
              />
              <VSFormItem
                name={[
                  'treatVsClinicItemBaseDto',
                  'treatSubClass',
                  'treatClassName',
                ]}
                label="治疗子类"
                fieldProps={{ disabled: true }}
                valueType="text"
              />
              <VSFormItem
                name={['treatVsClinicItemBaseDto', 'treatClass', 'id']}
                label="治疗大类ID"
                valueType="digit"
                style={{
                  display: 'none',
                }}
                fieldProps={{}}
              />
              <VSFormItem
                name={['treatVsClinicItemBaseDto', 'treatSubClass', 'id']}
                label="治疗子类ID"
                valueType="digit"
                style={{
                  display: 'none',
                }}
                fieldProps={{}}
              />
              <VSFormItem
                name={['itemCode']}
                label="项目代码"
                valueType="text"
                initialValue={itemCode}
                fieldProps={{
                  disabled: isValue,
                }}
              />
              <VSFormItem
                name={['clinicItemName']}
                label="治疗项目名称"
                valueType="text"
                rules={[
                  {
                    message: '项目名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                  { required: true },
                ]}
                fieldProps={{
                  onChange: ({ target }) => {
                    vsf.refs.TreatForm?.setFieldValue(
                      'inputCode',
                      askRomanAlphabet(target.value),
                    );
                  },
                }}
              />
              <VSFormItem
                name={['inputCode']}
                label="拼音码"
                valueType="text"
                rules={[
                  {
                    message: `拼音码不能超过10个字`,
                    max: 4000,
                    min: 0,
                  },
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (String(value).includes(',')) {
                        return Promise.reject('多音字待确认');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['limitGender']}
                label="限制性别"
                valueType="select"
                dataSource={[
                  { label: '无限制', value: 'NO_LIMIT' },
                  { label: '限男性', value: 'MALE_ONLY' },
                  { label: '限女性', value: 'FEMALE_ONLY' },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['specialNeedIndicator']}
                label="是否特需"
                valueType="select"
                initialValue={false}
                dataSource={[
                  {
                    label: '是',
                    value: true,
                  },
                  {
                    label: '否',
                    value: false,
                  },
                ]}
                fieldProps={{
                  rows: 4,
                }}
              />
            </VSFormLayout>
            <VSFormLayout
              key="12"
              columnCount={1}
              gutter={50}
              labelWidth={labelNumberWidth(7)}
            >
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                rules={[
                  {
                    message: `备注不能超过200个字`,
                    max: 200,
                    min: 0,
                  },
                ]}
                fieldProps={{
                  rows: 4,
                }}
              />

              <VSFormItem
                name={['description']}
                label="项目说明"
                valueType="textarea"
                rules={[
                  {
                    message: `项目说明不能超过2000个字`,
                    max: 2000,
                    min: 0,
                  },
                ]}
                fieldProps={{
                  rows: 4,
                }}
              />
            </VSFormLayout>
          </Section>
          <Section title="各院区使用">
            <Divider />
            <VSFormItem name={['treatItemExtensionBtoList']} valueType="custom">
              <SelectForm
                dataSource={dataSource}
                label="院区"
                selectAllText="全部院区"
                rules={[
                  {
                    required: true,
                    message: '请选择院区',
                  },
                ]}
                selectLayoutProps={{
                  columnCount: 1,
                  gutter: 50,
                  labelWidth: labelNumberWidth(7),
                }}
              >
                <VSFormLayout
                  columnCount={1}
                  gutter={50}
                  labelWidth={labelNumberWidth(7)}
                >
                  <VSFormItem
                    name={['id']}
                    label="主键"
                    style={{ display: 'none' }}
                    valueType="digit"
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['branchInstitutionId']}
                    label="院区"
                    valueType="digit"
                    style={{
                      display: 'none',
                    }}
                  />
                  <VSFormItem
                    name={['useScope']}
                    label="使用范围"
                    valueType="select"
                    initialValue="ALL"
                    dataSource={[
                      { label: '住院', value: 'INP' },
                      { label: '门诊', value: 'OUTP' },
                      { label: '全院', value: 'ALL' },
                    ]}
                    rules={[{ required: true }]}
                    fieldProps={{}}
                  />
                  <VSFormItem
                    name={['guideInfo']}
                    label="导引信息"
                    valueType="textarea"
                    rules={[
                      {
                        message: `导引信息不能超过500个字`,
                        max: 500,
                        min: 0,
                      },
                    ]}
                    fieldProps={{
                      rows: 4,
                    }}
                  />
                </VSFormLayout>
              </SelectForm>
            </VSFormItem>
          </Section>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={10}>
          <Space direction="vertical">
            <VSFormItem
              name={['clinicPerformDepartmentBtoList']}
              valueType="custom"
              transform={(value) => {
                return {
                  clinicPerformDepartmentBtoList: value?.filter?.((item) => {
                    const { id, ...rest } = item;
                    return Object?.values(rest)?.length;
                  }),
                };
              }}
            >
              <Table />
            </VSFormItem>
          </Space>
        </Col>
      </Row>
    </VSControlledForm>
  );
};

export default Edit;
