import vsf from '@vs/vsf-boot';
import { Button, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';

import { NewDrawer } from '@/pages/Index/components';

type DrugBeInHospitalWardProps = {
  onChange: (value: any) => void;
};

const DrugBeInHospitalWard = (props: DrugBeInHospitalWardProps) => {
  const [open, setOpen] = useState(false);
  const table = useRef<any>(null);
  const onFetch = useCallback(async () => {
    if (open) {
      const res =
        await vsf?.services?.OrderDispenseController_getWardOrderWaitDispensePatientCount_6fc9bf?.(
          {},
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  }, [open]);

  const Footer = () => {
    return <div></div>;
  };
  return (
    <NewDrawer
      open={open}
      width="20%"
      onOpen={() => setOpen(!open)}
      title="未发药病区"
      buttonTitle="未发药病区"
      // backgroundColor="var(--blue)"
      footer={<Footer />}
      isOther
      buttonStyle={{
        top: '20%',
      }}
      style={{
        top: 104,
      }}
      drift="98px"
    >
      <VSPromiseTable
        onFetch={onFetch}
        pagination={false}
        ref={table}
        onRow={(record) => {
          return {
            onClick: () => {
              props?.onChange?.(record);
              setOpen(false);
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={['wardName']}
          title="病区"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'ward_name长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['patientCount']}
          title="患者人数"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: 'patient_count的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};

export default DrugBeInHospitalWard;
