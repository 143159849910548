import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionBOController_createInstitution_c79457: {
    method: 'post',
    url: '/api/organization/create-institution',
    parameterFomatter: (data?: {
      btoParam: CreateInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
});
