import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_queryHerbWaitDispenseMasterList_f7df6f: {
    method: 'post',
    url: '/api/drug-prescription-dispense/query-herb-wait-dispense-master-list',
    parameterFomatter: (data?: {
      qto: QueryDrugPrescriptionWaitDispenseMasterPatientWindowQtoSimpleDrugPrescriptionDispensePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugPrescriptionWaitDispenseMasterPatientWindowVoDrugPrescriptionDispenseEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
