import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_getInpBillingDetailByInpVisitId_f676a5: {
    method: 'post',
    url: '/api/inp-billing/get-inp-billing-detail-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_createInpBillingDetailForBillingStation_52012b: {
    method: 'post',
    url: '/api/inp-billing/create-inp-billing-detail-for-billing-station',
    parameterFomatter: (data?: {
      saveInpBillingDetailEoList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-page-user-staff-by-input-code-qto',
    parameterFomatter: (data?: {
      qto: PageUserStaffByInputCodeNewQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
