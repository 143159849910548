import '../medical_orders_show/index.less';

import React from 'react';

import Container from '@/module/Container';
import Screen from '@/pages/Index/components/screen';

import WardOrderExecutedOrderList from './table';
const MedicalOrderExecute = () => {
  return (
    <Screen>
      <Container
        layout={{
          items: [{}],
        }}
      >
        <WardOrderExecutedOrderList />
      </Container>
    </Screen>
  );
};

export default MedicalOrderExecute;
