import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionDispenseController_queryAllByQueryDrugPrescriptionWaitDispenseMasterPatientWindowQto_0fc31f:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/query-all-by-query-drug-prescription-wait-dispense-master-patient-window-qto',
      parameterFomatter: (data?: {
        qto: QueryDrugPrescriptionWaitDispenseMasterPatientWindowQtoSimpleDrugPrescriptionDispensePersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugPrescriptionWaitDispenseMasterPatientWindowVoDrugPrescriptionDispenseEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
