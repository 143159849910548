import '../index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Popover,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import { cdssShow } from '@/mock';
import Icon from '@/module/cpoe/Icon';
import AspirinIcon from '@/module/Icon';
import Collect from '@/pages/Index/components/collect';
import Tip from '@/pages/Index/components/func/Tip';
import TipModal from '@/pages/Index/components/func/TipModal';
import EditAllergy from '@/pages/Index/cpoe/center/left/previous_history/edit/edit_allergy';
import { handelSexIcon } from '@/pages/Index/manage/bed_card/content/bedCardList';
import PatientMainSearch from '@/pages/Index/manage/patient_main_manager/patient_main_search/table.jsx';
import { getScrollY } from '@/utils';
import { useHorizontalScroll } from '@/utils/hooks';
import { handleSex } from '@/utils/index';
import storage from '@/utils/storage';

import Oper from '../oper';
import PatientInfoSetting from '../setting/patientInfoSetting';

const renderHospitalIcon = [
  {
    icon: (
      <AspirinIcon
        type="icon-a-yigandaxiaosanyang"
        style={{
          color: '#3276E8',
          cursor: 'pointer',
        }}
        size={20}
      />
    ),
    value: 'drugResistanceName',
    other: 'drugResistanceType',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-linchuanglujing"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '临床路径',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-linchuangshiyan"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '临床试验',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-lvsetongdao"
        style={{ color: '#30A313', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: '绿色通道',
  },
  {
    icon: (
      <AspirinIcon
        type="icon-DRG"
        style={{ color: '#3276E8', cursor: 'pointer' }}
        size={20}
      />
    ),
    value: 'DRGS',
  },
];

type InfoPropsType = {
  data: any;
  color?: string;
  info?: any;
  type?: 'in' | 'out';
};

const Index = (props) => {
  const {
    user,
    cdss: { cdssAllergy },
  } = props?.stores || {};
  const {
    outVisitInfo,
    outVisitTreatInfo,
    hospitalType,
    // getPatientList,
    getOutVisitTreatInfo,
    currentPatient,
    inVisitInfo,
    inVisitTreatInfo,
    getInVisitTreatInfo,
    staffInfo,
    patientLabelConfig,
    getListPatientLabelConfig,
    outRegisterInfo,
  } = user || {};
  const indexCls = getPrefixCls('cpoe-index-center-info');
  const [openModal, setOpenModal] = useState<boolean>();
  // 取消收藏弹窗
  const [openCancelCollectModal, setCancelCollextModal] = useState(false);
  // 收藏弹窗
  const [openCollectModal, setCollextModal] = useState<boolean>(false);
  // 取消收藏/收藏弹窗参数
  const [collectParams, setCollectParams] = useState<any>();
  const [mockOut, setMockOut] = useState<any>();
  // const [settingModal, setSettingModal] = useState<boolean>(false);
  const infoRef = useRef<any>();
  // 设置弹窗
  const settingModal = useRef<any>();

  useHorizontalScroll(infoRef);

  useEffect(() => {
    if (outRegisterInfo?.id && hospitalType == 'out') {
      getOutVisitTreatInfo(outRegisterInfo?.id);
      getListPatientLabelConfig({
        staffId: staffInfo?.doctorDto?.staffId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalType, outRegisterInfo, getOutVisitTreatInfo]);

  useEffect(() => {
    handelPatientLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientLabelConfig]);

  const obj = {
    HEIGHT: { label: '身高', value: 'height' },
    WEIGHT: { label: '体重', value: 'weight' },
    BMI: { label: 'BMI指数', value: 'bmi' },
    BLOOD_PRESSURE: { label: '血压', value: 'bloodPressure' },
    TEMPERATURE: { label: '体温', value: 'temperature' },
    BLOOD_GLUCOSE: { label: '血糖', value: 'bloodGlucose' },
    ALLERGY_HISTORY: { label: '过敏史', value: 'patientAllergyVoList' },
    NATION: { label: '民族', value: 'nation' },
    MARRIAGE_STATUS: { label: '婚姻状况', value: 'marriageHistory' },
    // BLOOD_TYPE: { label: '血型', value: 'weight' },
    // BLOOD_TYPE_RH: { label: 'RH血型', value: 'height' },
  };
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    if (_res?.length > 0) {
      return _res?.[0]?.label;
    } else {
      return '';
    }
  };
  // 转为二维数组
  const convertTo2DArray = (arr = [], size = 2) => {
    const _arr: any = [];
    const residue = arr?.length % 2;
    const length = residue == 0 ? arr?.length / 2 : (arr?.length - 1) / 2;
    for (let i = 0; i < length; i++) {
      _arr.push([arr[i], arr[i + length + residue]]);
    }
    if (residue != 0) {
      _arr.push([arr?.[length]]);
    }

    return _arr;
  };
  const handelPatientLabel = async () => {
    // const res = await getListPatientLabelConfig({
    //   staffId: staffInfo?.doctorDto?.staffId,
    // });
    const _res = patientLabelConfig?.map((i) => {
      return {
        label: obj?.[i?.labelCode]?.label,
        field: obj?.[i?.labelCode]?.value,
        value: '-',
      };
    });
    const _tempres = convertTo2DArray(_res, 2)?.map((i, index) => {
      const color =
        index == 0
          ? '#3276E8'
          : index == 1
          ? '#FFA57E'
          : index == 2
          ? '#69A1FF'
          : '#69A1FF';
      return { color, data: [...i] };
    });
    // console.log(_tempres, 'opopopop');

    setMockOut([..._tempres]);
  };
  // 收藏列表
  const getCollectClassifyList = async () => {
    const res =
      await vsf.services?.DoctorFavoriteDescriptionController_getDoctorFavoriteDescription_9014bf?.(); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.map((item) => {
      return {
        label: item?.medicalRecordDescription,
        value: item?.id,
        amount: item?.amount,
      };
    });
    // setCollectClassifyList(_res);
    return _res ?? [];
  };
  // 添加收藏
  const handelCollect = (row, collectDescription, isMySelect = 'other') => {
    setCollextModal(true);
    setCollectParams({
      outVisitInfo,
      collectDescription,
      isMySelect,
    });
  };
  // 取消收藏
  const handelCancelCollect = (row, isMySelect = 'other') => {
    setCancelCollextModal(true);
    setCollectParams({
      outVisitInfo,
      isMySelect,
    });
  };
  // 删除
  const deleteCollect = async (value) => {
    const content =
      value?.amount > 0
        ? `该分类有${value?.amount ?? ''}条病例，删除后，将其从收藏病例里移出?`
        : '确定删除该分类?';
    Tip({
      content: content,
      onOk: async () => {
        const res =
          await vsf.services?.DoctorFavoriteDescriptionController_deleteDoctorFavoriteDescription_14100e?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              btoParam: {
                medicalRecordDescription: value?.label,
              },
              extApiParam: {},
            },
          ); // TODO: 你可能需要整理返回数据格式
        // 打开列表
        // openDropdown();
        // onDropdownOpen(true);
        if (res?.code === 200) {
          // getCollectClassifyList();
          // vsf.refs.collectTable?.reload();
          // vsf.refs.otherTable?.reload();
          // vsf.refs.notTreatTable?.reload();
          message.open({
            type: 'success',
            content: `删除成功!`,
          });
        }
      },
      onCancel: () => {
        // 打开列表
        // openDropdown();
      },
    });
  };
  // hover过敏史
  const renderAllergyList = (list) => {
    return (
      <VSPromiseTable
        style={{
          width: 550,
        }}
        editable={{
          editType: 'single',
          onCanDelete: () => {
            return false;
          },
          columnProps: {
            width: 50,
          },
        }}
        pagination={false}
        onFetch={() => {
          return list;
        }}
        scroll={{ y: getScrollY(500) }}
        updateLinkPage={{
          linkPage: (value) => {
            return (
              <EditAllergy
                title="update"
                recordDoctor={
                  value?.recordDoctor?.id == staffInfo?.doctorDto?.staffId
                }
              />
            );
          },
          modalProps: {
            title: `编辑过敏史`,
            className: 'aspirin-linkpage',
            width: 680,
            centered: true,
            bodyStyle: {
              overflowY: 'scroll',
              overflowX: 'hidden',
            },
          },

          onLinkPageValidate: async (params) => {
            try {
              const ret =
                await vsf.refs.allergyForm?.validateFieldsReturnFormatValue();
              return ret;
            } catch (e) {
              return false;
            }
          },
        }}
        onUpdate={async (params) => {
          const res =
            await vsf?.services?.ClinicRecordHistoryController_updatePatientAllergy_260cc6?.(
              { btoParam: { ...(params ?? {}) }, extApiParam: {} },
            );
          if (res?.code == 200) {
            message.success('过敏史更新成功');
            if (cdssShow) {
              cdssAllergy(outVisitInfo);
            }
          }
          vsf?.refs?.previousHistory?.reload();
          return res?.data;
        }}
      >
        <VSTableColumn
          title="过敏原"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.DRUG_CLASS_DICT}
          dataIndex={['allergyName']}
          width={150}
          ellipsis
        />
        <VSTableColumn
          title="过敏程度/过敏症状"
          valueType="text"
          dataIndex={['allergyLevel']}
          ellipsis
          width={150}
          render={(_, value) => {
            const res = vsf.stores.dbenums.enums.ALLERGY_SYMPTOMS_DICT?.find(
              (item) => item?.value == value?.allergySymptom,
            );
            const res1 =
              vsf.stores.dbenums.enums.DRUG_ALLERGY_SEVERITY_DICT?.find(
                (item) => item?.value == value?.allergyLevel,
              );
            return (
              <div>
                {res1?.label}
                {res ? '/' + res?.label : ''}
              </div>
            );
          }}
        />
        <VSTableColumn
          title="过敏时间"
          valueType="date"
          dataIndex={['startDate']}
          fieldProps={{ format: 'YYYY-MM-DD' }}
          width={150}
        />
      </VSPromiseTable>
    );
  };
  // 门诊信息栏
  const Item = (props: InfoPropsType) => {
    const { data, info, type = 'out' } = props;
    const _info = {
      ...info,
      height: info?.outpMedicalRecordVo?.height,
      weight: info?.outpMedicalRecordVo?.weight,
      marriageStatus: info?.patientProfileVo?.marriageStatus,
    };

    return (
      <div
        className="info-item"
        style={{
          width: data?.data?.[0]?.field === 'admissionDateTime' ? 200 : '',
        }}
      >
        <div
          className="info-bar"
          style={{
            backgroundColor: data?.color,
          }}
        ></div>

        <div className="info-content">
          {(data?.data || [])?.map((item, index) => {
            // 过敏史
            if (item?.field == 'patientAllergyVoList') {
              const data = _info?.patientAllergyVoList?.map((i) => {
                const _allergyName =
                  vsf.stores.dbenums.enums.DRUG_CLASS_DICT?.find(
                    (_i) => _i?.value == i?.allergyName,
                  );
                return _allergyName ? _allergyName?.label : i?.allergyName;
              });
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover
                    content={renderAllergyList(_info?.patientAllergyVoList)}
                    key={index}
                  >
                    <div className="value">
                      {_info?.patientAllergyVoList?.length > 0
                        ? data.join(';')
                        : '暂无'}
                    </div>
                  </Popover>
                </div>
              );
            } else if (item?.field == 'marriageHistory') {
              // 婚姻
              const _data = _info?.marriageHistory
                ? handelStopwatch(
                    _info?.marriageHistory,
                    vsf.stores.dbenums.enums.MARITAL_STATUS_DICT,
                  )
                : item?.value;
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover content={_data} key={index}>
                    <div className="value">{_data}</div>
                  </Popover>
                </div>
              );
              // vsf.stores.dbenums.enums.MARITAL_STATUS_DICT
            } else {
              // 其他
              const _data = _info?.[item?.field]
                ? _info?.[item?.field]
                : item?.value;
              return (
                <div className="item-row " key={index}>
                  <div className="label">
                    <span>{item?.label}</span>
                  </div>
                  <Popover content={_data} key={index}>
                    <div className="value">{_data}</div>
                  </Popover>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const renderHeader = (type = 'out') => {
    if (type === 'out') {
      return (
        <div className="header-right-container">
          <div className="item">
            <Icon
              type="icon-weiji"
              style={{
                color: '#F36969',
              }}
            />
            <div className="label">危急</div>
          </div>
          <div
            className="item"
            style={{ cursor: 'pointer', marginTop: '-5px' }}
          >
            <Collect
              favoriteDescription={outVisitInfo?.doctorFavoriteDescription}
              cancelCollect={(value) => {
                handelCancelCollect(outVisitInfo?.doctorFavoriteDescription);
              }}
              collect={(value) => {
                handelCollect(outVisitInfo, value, 'other');
              }}
              deleteCollect={(value) => {
                deleteCollect(value);
              }}
              getCollectClassifyList={getCollectClassifyList}
              childrens={
                // <div className="item">
                <div style={{ cursor: 'pointer' }}>
                  {outVisitInfo?.doctorFavoriteDescription ? (
                    <Icon
                      type="icon-shoucang1"
                      className="icon16"
                      style={{ color: '#ffa57e' }}
                    />
                  ) : (
                    <Icon
                      className="icon16"
                      type="icon-meiyoushoucang"
                      style={{ color: '#343B42' }}
                    />
                  )}
                </div>
                // </div>
              }
            />
            {/* {outVisitInfo?.doctorFavoriteDescription &&
            outVisitInfo?.doctorFavoriteDescription?.length > 0 ? (
              <Icon
              type="icon-shoucang1"
              size={16}
              style={{ color: '#ffa57e' }}
              />
              ) : (
                <Icon
                size={16}
                type="icon-meiyoushoucang"
                style={{ color: '#343B42' }}
                />
              )} */}
            <div className="label" style={{ marginTop: '-3px' }}>
              收藏
            </div>
          </div>
          <div className="item">
            <Icon type="icon-diedao" />
            <div className="label">跌倒</div>
          </div>
          <div
            className="item"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              settingModal.current?.handeOpen(true);
            }}
          >
            <Icon type="icon-shezhi" />
            <div className="label">设置</div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  };
  // 主索引
  const handelmodal = (v) => {
    setOpenModal(v);
  };
  // 收藏弹窗
  const rendelCollectModal = (params) => {
    const { isMySelect, outVisitInfo: row, collectDescription } = params;
    return (
      <TipModal
        content={`${
          (
            isMySelect === 'other'
              ? row?.doctorFavoriteDescription
              : row?.medicalRecordDescription
          )
            ? '是否更改收藏目录?'
            : '确认收藏?'
        }`}
        open={openCollectModal}
        onCancel={() => {
          setCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_createDoctorFavoriteMedicalRecord_f15c7f?.(
              {
                btoParam: {
                  // TODO: 请检查以下这部分传参是否符合需求
                  outpVisitEncounterId: row?.id,

                  medicalRecordDescription: collectDescription?.label,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCollextModal(false);
            message.open({
              type: 'success',
              content: `收藏成功`,
            });
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
            }, 100);
          }
        }}
      />
    );
  };
  // 取消收藏弹窗
  const rendelCancelCollectModal = (params) => {
    const { isMySelect, outVisitInfo: row } = params;
    return (
      <TipModal
        content="是否取消收藏"
        open={openCancelCollectModal}
        onCancel={() => {
          setCancelCollextModal(false);
        }}
        onOk={async () => {
          const res =
            await vsf.services?.DoctorFavoriteMedicalRecordController_deleteDoctorFavoriteMedicalRecord_bc0e79?.(
              {
                // TODO: 请检查以下这部分传参是否符合需求
                btoParam: {
                  outpVisitEncounterId:
                    isMySelect === 'other'
                      ? row?.id
                      : row?.outpVisitEncounter?.id,
                },
                extApiParam: {},
              },
            ); // TODO: 你可能需要整理返回数据格式
          // 打开列表
          // onDropdownOpen(true);

          if (res?.code === 200) {
            setCancelCollextModal(false);
            message.open({
              type: 'success',
              content: `成功取消收藏`,
            });
            setTimeout(() => {
              vsf.refs.collectTable?.reload();
              vsf.refs.otherTable?.reload();
              vsf.refs.notTreatTable?.reload();
              // getCollectClassifyList();
            }, 100);
          }
        }}
      />
    );
  };
  const onOper = () => {
    if (props?.common_components) return;
    return <Oper />;
  };
  return (
    <div className={indexCls}>
      {onOper()}
      {/* <Oper /> */}
      {hospitalType === 'er' ? (
        <div className="aspirin-header-user-info">
          <div className="bg"></div>
          <div className="user-info-container">
            <div
              className="number"
              onClick={() => {
                handelmodal(true);
              }}
            >
              <Icon type="icon-Frame2" className="icon30" />
            </div>
            <div
              className="main"
              style={{
                overflow: 'hidden',
                display: 'flex',
              }}
            >
              <div className="first">
                <Popover content={outVisitTreatInfo?.outpVisitVo?.name}>
                  <div
                    className="name"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {outVisitTreatInfo?.outpVisitVo?.name}
                  </div>
                </Popover>
                <Popover
                  content={
                    handleSex(
                      outVisitTreatInfo?.outpVisitVo?.gender,
                      vsf.stores.dbenums?.enums?.SEX_DICT,
                    )?.label
                  }
                >
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    className="gender"
                  >
                    {handelSexIcon(outVisitTreatInfo?.outpVisitVo?.gender)}
                  </div>
                </Popover>
                <Popover content={outVisitTreatInfo?.outpVisitVo?.age}>
                  <div className="age">
                    {outVisitTreatInfo?.outpVisitVo?.age}
                  </div>
                </Popover>
                <Popover content={outVisitTreatInfo?.patientVo?.displayId}>
                  <div className="displayId">
                    {outVisitTreatInfo?.patientVo?.displayId}
                  </div>
                </Popover>
              </div>
              <div
                className="second"
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div className="phoneNumber">
                  <Icon type="icon-zhongjiao" className="icon16" />
                  <Popover
                    content={
                      outVisitTreatInfo?.patientVo?.phoneNumber ?? '暂无'
                    }
                  >
                    {outVisitTreatInfo?.patientVo?.phoneNumber ?? '暂无'}
                  </Popover>
                </div>
                <div className="currentAddress">
                  <Icon
                    className="icon16"
                    type="icon-weizhi"
                    style={{ marginRigth: 2 }}
                  />
                  <Popover
                    content={
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.province +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.city +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.county +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.detail
                    }
                  >
                    {outVisitTreatInfo?.patientProfileVo?.currentAddress
                      ?.province +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.city +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.county +
                      outVisitTreatInfo?.patientProfileVo?.currentAddress
                        ?.detail || '-'}
                  </Popover>
                </div>
              </div>
            </div>
            <div className="info noscroll" ref={infoRef}>
              {mockOut?.map((item, index) => (
                <Item
                  key={index}
                  data={item}
                  info={{
                    ...cloneDeep(outVisitTreatInfo),
                    ...(outVisitTreatInfo?.outpMedicalRecordVo ?? {}),
                  }}
                />
              ))}
            </div>
          </div>
          {renderHeader('out')}
        </div>
      ) : null}
      {/* 主索引 */}
      {openModal && (
        <PatientMainSearch
          patientId={currentPatient?.patientId}
          handelmodal={handelmodal}
          showTable={false}
        />
      )}
      {/* 收藏二次确认弹窗 */}
      {openCollectModal && rendelCollectModal(collectParams)}
      {/* 取消收藏二次确认弹窗 */}
      {openCancelCollectModal && rendelCancelCollectModal(collectParams)}
      {/* 点击设置 */}
      <PatientInfoSetting ref={settingModal} />
    </div>
  );
};

export default definePage(Index);
