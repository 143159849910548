import { createServices } from '@vs/vsf-boot';
export default createServices({
  SongBOServiceController_songUpdate_178623: {
    method: 'post',
    url: '/api/test_one/8665e87d-77cc-440f-a010-af92cd6796bc/SongBOService-songUpdate',
    parameterFomatter: (data?: { btoParam: SongUpdateBtoTestOneServiceBto }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SongBOServiceController_songDelete_c100c1: {
    method: 'post',
    url: '/api/test_one/d1fdfd87-7583-4942-80d4-09f5dd3bf202/SongBOService-songDelete',
    parameterFomatter: (data?: { btoParam: SongDeleteBtoTestOneServiceBto }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PlaylistDtoServiceController_queryAll_680925: {
    method: 'post',
    url: '/api/test_one/400b6ced-66c4-4576-9722-0b8ff1c88aff/PlaylistDtoService-queryAll',
    parameterFomatter: (data?: {
      qto: PlaylistQueryQtoTestOnePersistQtoSimple;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PlaylistDtoTestOneManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PlaylistDtoServiceController_getByIds_a3b2e0: {
    method: 'post',
    url: '/api/test_one/e733c210-3a84-4af9-b2cc-7022dd62f87a/PlaylistDtoService-getByIds',
    parameterFomatter: (data?: { id: number[] }) => data,
    responseFormatter: (_, __, data?: PlaylistDtoTestOneManagerDto[]) => data,
  },
  SongDtoServiceController_getByPlaylistId_c3a008: {
    method: 'post',
    url: '/api/test_one/75cbb59b-bfaa-433e-9e3e-3a2b9f5023d2/SongDtoService-getByPlaylistId',
    parameterFomatter: (data?: { playlistId: number }) => data,
    responseFormatter: (_, __, data?: SongDtoTestOneManagerDto[]) => data,
  },
  SongDtoServiceController_queryPaged_d0566a: {
    method: 'post',
    url: '/api/test_one/dc43bc6e-b84b-4211-bb3b-95698431364d/SongDtoService-queryPaged',
    parameterFomatter: (data?: {
      qto: SongQueryQtoTestOnePersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SongDtoTestOneManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SongDtoServiceController_getById_c93c11: {
    method: 'post',
    url: '/api/test_one/ff7e2120-036b-461f-a600-916af7258528/SongDtoService-getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SongDtoTestOneManagerDto) => data,
  },
  PlaylistBOServiceController_playlistCreate_3a18a6: {
    method: 'post',
    url: '/api/test_one/94ef4e14-0e98-40ef-9a87-120903e199b5/PlaylistBOService-playlistCreate',
    parameterFomatter: (data?: {
      btoParam: PlaylistCreateBtoTestOneServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SongBOServiceController_songCreate_34d2c3: {
    method: 'post',
    url: '/api/test_one/154002cd-e751-43c8-a92b-8e567f2c007e/SongBOService-songCreate',
    parameterFomatter: (data?: { btoParam: SongCreateBtoTestOneServiceBto }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
