import { createServices } from '@vs/vsf-boot';
export default createServices({
  SkinTestVsDrugSettingManageController_deleteSkinTestVsDrugSetting_d1a58a: {
    method: 'post',
    url: '/api/drug-billing-setting/delete-skin-test-vs-drug-setting',
    parameterFomatter: (data?: {
      btoParam: DeleteSkinTestVsDrugSettingBtoDrugBillingSettingServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  SkinTestVsDrugSettingManageController_getSkinTestVsDrugSettingByDrugProductId_c7d468:
    {
      method: 'post',
      url: '/api/drug-billing-setting/get-skin-test-vs-drug-setting-by-drug-product-id',
      parameterFomatter: (data?: { drugProductId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: SkinTestVsDrugSettingPriceProductFormDictionaryVoDrugBillingSettingEntranceWebVo[],
      ) => data,
    },
  SkinTestVsDrugSettingManageController_saveSkinTestVsDrugSettings_be6a8a: {
    method: 'post',
    url: '/api/drug-billing-setting/save-skin-test-vs-drug-settings',
    parameterFomatter: (data?: {
      btos: SaveSkinTestVsDrugSettingBtoDrugBillingSettingServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugDrugController_queryPagefallByDrugPriceItemDictionaryNameProductFormQtoByDrugPriceItemDictionaryNameProductFormQto_f82105:
    {
      method: 'post',
      url: '/api/drug-drug/query-pagefall-by-drug-price-item-dictionary-name-product-form-qto-by-drug-price-item-dictionary-name-product-form-qto',
      parameterFomatter: (data?: {
        qto: DrugPriceItemDictionaryNameProductFormQtoDrugDrugPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugPriceItemDictionaryNameProductFormVoDrugDrugEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  DrugDrugController_queryProductWithCurrentPrice_4621c9: {
    method: 'post',
    url: '/api/drug-drug/query-product-with-current-price',
    parameterFomatter: (data?: {
      qto: DrugPriceItemDictionaryNameProductFormQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugPriceItemDictionaryNameProductFormVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
