// 保存
export const convertErpObservationApplyForm2SaveErpObservationApplyFuncFlowExecutorReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.saveErpObservationApplyBto = {};
    //  output.saveErpObservationApplyBto.patientId = null; // Long 患者ID
    output.saveErpObservationApplyBto.erpVisitId = _.get(input, 'erpVisitId'); // Long 就诊流水号
    // output.saveErpObservationApplyBto.treatAreaSortCode = null; // Dbenum<TREAT_AREA_SORT_DICT> 急诊分区代码
    output.saveErpObservationApplyBto.marriageStatus = _.get(
      input,
      'marriageStatus',
    );
    output.saveErpObservationApplyBto.branchInstitutionId = _.get(
      input,
      'branchInstitutionId.id',
    );
    output.saveErpObservationApplyBto.patientAdmissionCondition = _.get(
      input,
      'patientAdmissionCondition',
    );
    output.saveErpObservationApplyBto.admissionToDepartmentId = _.get(
      input,
      'admissionToDepartment.id',
    );
    output.saveErpObservationApplyBto.doctorId = _.get(input, 'doctor.id');
    output.saveErpObservationApplyBto.diagnosisDescription = _.get(
      input,
      'diagnosisDescription',
    );
    output.saveErpObservationApplyBto.wardId = _.get(input, 'ward.id');
    output.saveErpObservationApplyBto.admissionReason = _.get(
      input,
      'admissionReason',
    );
    output.saveErpObservationApplyBto.firstPrePayment = _.get(
      input,
      'firstPrePayment',
    );
    output.saveErpObservationApplyBto.bedClass = _.get(input, 'bedClass');
    output.saveErpObservationApplyBto.appointAdmissionDate = _.get(
      input,
      'appointAdmissionDate',
    );
    output.saveErpObservationApplyBto.applyDate = _.get(input, 'applyDate');
    // output.saveErpObservationApplyBto.printIndicator = null; // Boolean 是否打印
    // output.saveErpObservationApplyBto.effectIndicator = null; // Boolean 是否有效
    output.saveErpObservationApplyBto.encounterId = 108501; // Long ??? 病历ID
    output.updatePatientForObservationApplyBto = {};
    //  output.updatePatientForObservationApplyBto.id = null; // Long 主键
    output.updatePatientForObservationApplyBto.cellphone = _.get(
      input,
      'cellphone',
    );
    output.updatePatientForObservationApplyBto.birthAddress = _.get(
      input,
      'birthAddress',
    );
    output.updatePatientForObservationApplyBto.patientProfileBto = {};
    // output.updatePatientForObservationApplyBto.patientProfileBto.id = null; // Long 主键
    output.updatePatientForObservationApplyBto.patientProfileBto.marriageStatus =
      null; // Dbenum<MARITAL_STATUS_DICT> 婚姻状态
    output.updatePatientForObservationApplyBto.patientProfileBto.currentAddress =
      _.get(input, 'currentAddress');
    return output;
  };
// 查询
export const convertGetErpObservationApplyWithPatientVoByErpObservationApplyWithPatientQtoRes2ErpObservationApplyForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.erpVisit.id');
    output.displayId = _.get(input, 'data.patient.displayId');
    output.name = _.get(input, 'data.patient.name');
    output.gender = _.get(input, 'data.patient.gender');
    output.age = _.get(input, 'data.patient.age');
    output.idNumber = _.get(input, 'data.patient.idNumber');
    output.defaultChargeType = _.get(input, 'data.patient.defaultChargeType');
    output.marriageStatus = _.get(
      input,
      'data.patient.patientProfileVo.marriageStatus',
    );
    output.cellphone = _.get(input, 'data.patient.cellphone');
    output.currentAddress = _.get(
      input,
      'data.patient.patientProfileVo.currentAddress',
    );
    output.birthAddress = _.get(input, 'data.patient.birthAddress'); // TODO 没有匹配到合适字段 output.patientAdmissionCondition = null; // Dbenum<PAT_ADM_CONDITION_DICT> 病情
    output.branchInstitutionId = _.get(input, 'data.branchInstitutionId');
    output.admissionToDepartment = _.get(input, 'data.admissionToDepartment');
    output.diagnosisDescription = _.get(input, 'data.diagnosisDescription');
    output.ward = _.get(input, 'data.ward');
    output.admissionReason = _.get(input, 'data.admissionReason');
    output.firstPrePayment = _.get(input, 'data.firstPrePayment');
    output.bedClass = _.get(input, 'data.bedClass');
    output.appointAdmissionDate = _.get(input, 'data.appointAdmissionDate');
    output.doctor = _.get(input, 'data.doctor');
    output.applyDate = _.get(input, 'data.applyDate');
    return output;
  };
