import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  RadioGroup,
  Section,
  VSControlledForm,
  VSDataProvider,
  VSForm,
  VSFormFooter,
  VSFormFooterReset,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import { debounce } from 'lodash';
import { any } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';

const Edit = (props) => {
  const { title, value } = props;

  const [diagnosisDictionaryList, setDiagnosisDictionaryList] = useState();
  const [diagnosisDictionaryName, setDiagnosisDictionaryName] = useState();
  console.log(vsf.stores.dbenums.enums.RELATIONSHIP_DICT);

  useEffect(() => {
    // getDiseaseName();
    hasId(value) &&
      vsf.services
        ?.ClinicRecordHistoryController_getFamilyHistoryById_d003a3?.({
          // TODO: 请检查以下这部分传参是否符合需求
          id: value?.id,
        })
        .then((res) => {
          if (res.code === 200) {
            vsf.refs?.familyForm?.setFieldsValue({
              ...res.data,
            });
          }
        });
  }, [value]);

  // useEffect(() => {
  //   getDiagnosisDictionary();
  // }, []);

  const hasId = (data) => {
    return data?.id;
  };
  const getDiseaseName = async (v?) => {
    const res =
      await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          qto: {
            from: 0,
            size: 100,
            ...v,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      const _res = res?.data?.result?.map((item) => {
        return { label: item?.diagnosisName, value: item?.id };
      });
      return _res;
      // setDiseaseNameList(_res);
    }
  };
  console.log(vsf.stores.dbenums.enums.FAMILY_RELATIONS_DICT);

  return (
    <VSControlledForm labelAlign="left" id="familyForm">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={1} labelWidth={120}>
        <VSFormItem
          name={['familyName']}
          label="疾病名称"
          valueType="select"
          fieldProps={{
            showSearch: true,
            disabled: title === 'update',
            // onSelect: (v) => {
            //   setDiagnosisDictionaryName(v?.label);
            // },
            // onSearch: (v) => {
            //   getDiagnosisDictionary(v);
            // },
          }}
          searchKey="searchInputLike"
          fieldNames={{ label: 'label', value: 'value' }}
          rules={[{ required: true }]}
          dataSource={getDiseaseName}
          transform={(v) => {
            return { familyName: v?.label };
          }}
        />

        <VSFormItem
          name={['familyRelationship']}
          label="患者家属"
          valueType="custom"
          // dataSource={vsf.stores.dbenums.enums.FAMILY_RELATIONS_DICT}
          rules={[{ required: true }]}
          fieldProps={{
            showSearch: true,
            disabled: title === 'update',
          }}
        >
          <Select
            options={vsf.stores.dbenums.enums.FAMILY_RELATIONS_DICT}
            showSearch
            placeholder="请选择患者家属"
            filterOption={askSearch}
            allowClear
          />
        </VSFormItem>
        <VSFormItem
          name={['startDate']}
          label="发病时间"
          valueType="date"
          initialValue={+new Date()}
          fieldProps={{
            defaultValue: +new Date(),
          }}
        />

        <VSFormItem
          name={['memo']}
          label="备注"
          valueType="text"
          rules={[
            { message: '数据格式错误', type: 'string', min: 0, max: 4000 },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['selfComplaintIndicator']}
          label="信息来源"
          valueType="radio"
          fieldProps={{}}
          dataSource={[
            {
              label: '患者自述',
              value: true,
            },
            {
              label: '本院发生',
              value: false,
            },
          ]}
          initialValue={true}
          transform={(v) => {
            return {
              selfComplaintIndicator: v ? true : false,
              inHospitalIndicator: v ? false : true,
            };
          }}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};

export default definePage(Edit);
