import { VSPromiseTable, VSTableColumn, VSTableInstance } from '@vs/vs-table';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import { useAsyncEffect, useGetState, useResetState } from 'ahooks';
import { Modal } from 'antd';
import { Tabs } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Block from '@/module/Block';
import {
  ButtonAndKeyDown,
  ComplexSelect,
  Determine,
} from '@/pages/Index/components';
import { ButtonAndKeyDownProps } from '@/pages/Index/components/button_and_keydown';
import {
  askDialog,
  askGroupMedicalOrders,
} from '@/pages/Index/components/func/ask';
import {
  useAvoidState,
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';

import Dialog from './dialog';
import OrderSheetOverviewOrderDetail from './show';

type Order = 'long' | 'temporary' | 'arrange';
const OrderTable = (props) => {
  const {
    value,
    onChange,
    stores: {
      user,
      common: {
        currentApplication: {
          department: { id: wardId },
        },
      },
    },
  } = props;

  const [row] = useOnChangeValue<any>({});
  const dataSource = [
    {
      label: '长期医嘱',
      key: 'long',
    },
    {
      label: '临时医嘱',
      key: 'temporary',
    },
  ];
  const [open, setOpen] = useState(false);
  const dialog = useRef<any>(null);
  const [orderId, setOrderId] = useState('');
  const OrderRef = useRef<VSTableInstance<any, 'text'> | null>(null);
  const [order] = useOnChangeValue<string>('long');
  const [form] = Form?.useForm();
  const [bed] = useOnChangeValue({
    label: '',
    value: '',
  });
  const [display] = useOnChangeValue('');
  const [list, setList, resetList] = useResetState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [signatureIndicator] = useOnChangeValue(true);

  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency', 'frequencyCount'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip() {},
  });

  // const onSave = useFetch({
  //   fetch: async (array) => {
  //     const res =
  //       await vsf.services?.ClinicRecordOrderPerformController_saveOrderSheetOverView_9dd8c7?.(
  //         {
  //           eoList: (array ?? list)?.map((item) => ({
  //             performDateTime: (item?.orderPerformDto as any)
  //               ?.firstPerformDateTime,
  //             id: item?.id,
  //             checkDateTime: item?.checkDateTime,
  //             checkNurseId: item?.checkNurseId,
  //           })),
  //         },
  //       );
  //     return res;
  //   },
  //   message: '保存',
  //   success() {
  //     OrderRef?.current?.reload?.();
  //   },
  //   error(error) {
  //     console.log(error);
  //   },
  // });

  const getStaff = useCallback(async () => {
    const res =
      await vsf.services?.UserStaffController_getStaffSimpleVoById_861e54?.({
        id: row?.value?.doctor?.id,
      });
    form?.setFieldsValue(res?.data);
  }, [form, row?.value?.doctor?.id]);

  const Footer = () => {
    const button: Array<ButtonAndKeyDownProps> = [
      {
        children: '打印',
        methodKey: 'altKey',
        ordinaryKey: 'P',
        disabled: false,
        onClick: () => {},
      },
      // {
      //   children: '保存',
      //   methodKey: 'altKey',
      //   ordinaryKey: 'S',
      //   disabled: !list?.length,
      //   onClick: async () => {
      //     await OrderRef?.current?.saveEdit?.();
      //     if (list?.map((item) => item?.isDoubleSignature)?.includes(true)) {
      //       const value = await dialog?.current?.onOpen?.({
      //         value: 'double',
      //       });
      //       onSave?.(
      //         list?.map((item) => {
      //           if (item?.isDoubleSignature) {
      //             return {
      //               ...item,
      //               ...value,
      //             };
      //           }
      //           return item;
      //         }),
      //       );
      //     } else {
      //       onSave();
      //     }
      //   },
      // },
      // {
      //   children: '取消签名',
      //   methodKey: 'altKey',
      //   ordinaryKey: 'D',
      //   onClick: () => {
      //     if (
      //       row?.value?.orderPerformDto?.id ==
      //       user?.staffInfo?.doctorDto?.staffId
      //     )
      //       return vsf.showToast('双签名的用户不能是当前登陆用户');
      //     dialog?.current?.onOpen?.({
      //       value: 'cancel',
      //       // loginName: row?.value?.orderPerformDto?.checkNurse?.staffName,
      //       loginName: row?.value?.orderPerformDto?.checkNurse?.loginName,
      //       performId: row?.value?.orderPerformDto?.id,
      //     });
      //     getStaff();
      //   },
      //   preventDefault: true,
      //   disabled:
      //     !row?.value?.doctor?.id &&
      //     !row?.value?.orderPerformDto?.checkNurse?.loginName,
      // },
      {
        children: '刷新',
        methodKey: 'altKey',
        ordinaryKey: 'R',
        onClick: () => {
          OrderRef?.current?.reload?.();
        },
      },
    ];
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          background: 'var(--background)',
        }}
      >
        {button?.map((item) => {
          return (
            <ButtonAndKeyDown
              {...item}
              key={item?.children as string}
              type="primary"
            >
              {item?.children}
            </ButtonAndKeyDown>
          );
        })}
      </div>
    );
  };

  const onOpen = (id) => {
    setOpen(true);
    setOrderId(id);
  };
  // 处理剂量，用量，途径，频次。
  const order_class = {
      DRUG: 'orderDrugVo',
      HERB: 'orderHerbVo',
      TREAT: 'orderDisposalVo',
      ANESTHESIA: 'orderDisposalVo',
      NURSING: 'orderDisposalVo',
      MEAL: 'orderDisposalVo',
      NUTRITION: 'orderDisposalVo',
      OTHER: 'orderDisposalVo',
    },
    order_class_method = {
      // 西药
      orderDrugVo: (data) => {
        return {
          // 剂量:dosage
          dosage: `${data?.dosage}${data?.dosageUnit}`,
          // 用量:usage
          usage: `${data?.usage}${data?.usageUnit}`,
          // 途径:administration
          administration:
            vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.find(
              (e) => e?.code == data?.administration,
            )?.label ?? data?.administration,
          //频次frequency
          frequency: data?.frequency?.frequency,
        };
      },
      // 中药
      orderHerbVo: (data) => {
        return {
          // 用量:usage
          usage: `${data?.herbDosage}ml`,
          // 途径:administration
          administration:
            vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT?.find(
              (e) => e?.code == data?.useWay,
            )?.label ?? data?.useWay,
          //频次frequency
          frequency: data?.frequency?.frequency,
        };
      },
      // 处置
      orderDisposalVo: (data) => {
        return {
          //频次frequency
          frequency: data?.frequency?.frequency,
        };
      },
    };

  const onFetch = useCallback(async () => {
    if (value?.inpVisit?.id) {
      const res =
        await vsf.services?.ClinicRecordOrderPerformController_orderSheetOverView_61cb03(
          {
            eo: {
              inpVisitId: value?.inpVisit?.id,
              repeatIndicator: order?.value === 'long',
              signatureIndicator: signatureIndicator?.value,
            },
          },
        );
      resetList();
      return {
        data: group?.onSliced(
          (res?.data?.result ?? res?.data ?? [])?.map((item) => {
            const type = order_class[item?.orderVo?.orderClass];
            const data = order_class_method[type](item[type]);
            return {
              ...item?.orderVo,
              isDoubleSignature: item?.isDoubleSignature,
              orderPerformDto: {
                ...item?.orderVo?.orderPerformDto,
                firstPerformDateTime: item?.lastPerformDateTime,
              },
              drugOrder: item?.orderDrugVo,
              time: item?.lastPerformDateTime,
              ...data,
            };
          }),
        ),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value?.inpVisit?.id,
    order?.value,
    signatureIndicator?.value,
    value?.patient?.displayId,
    value?.bed?.bedLabel,
  ]);

  useEffect(() => {
    display?.onChange(value?.patient?.displayId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.initKey, value?.bed?.bedLabel, value?.patient?.displayId]);

  const info = {
    床号: (
      <ComplexSelect
        mode="radio"
        placeholder="请选择床号"
        hideSelectIcon
        showLabel="label"
        allowClear
        dropdownMatchSelectWidth={700}
        listHeight={1000}
        showSearch
        // value={(bed?.value ?? {}) as any}
        filterOption={true}
        onSearch={(value) => {
          setSearchValue(value);
        }}
        value={
          {
            label: value?.bed?.bedLabel + value?.patient?.name || '',
            value: value?.bed?.id || '',
          } as any
        }
        onChange={(value) => {
          // console.log(value);
          onChange?.(value);
        }}
        style={{
          minWidth: 200,
        }}
      >
        <Table
          columns={[
            {
              dataIndex: ['bed', 'bedLabel'],
              title: '床号',
            },
            {
              dataIndex: ['patient', 'displayId'],
              title: '患者ID',
            },
            {
              dataIndex: ['patient', 'name'],
              title: '姓名',
            },
            {
              dataIndex: ['gender'],
              title: '性别',
            },
          ]}
          dataSource={props?.dataSource
            ?.filter(
              (item) =>
                item?.patient?.displayId?.includes(searchValue) ||
                item?.patient?.name?.includes(searchValue) ||
                item?.bed?.bedLabel?.includes(searchValue) ||
                (searchValue === '男' && item?.patient?.gender?.includes(1)) ||
                (searchValue === '女' && item?.patient?.gender?.includes(2)),
            )
            ?.map((item) => ({
              key: item?.bed?.id,
              label: item?.bed?.bedLabel + item?.patient?.name,
              gender:
                vsf.stores.dbenums.enums.SEX_DICT?.find(
                  ({ value }) => value === item?.patient?.gender,
                )?.label ?? '',
              ...item,
            }))}
          pagination={false}
          size="middle"
          scroll={{
            y: 300,
          }}
        />
      </ComplexSelect>
    ),
    患者ID: value?.patient?.displayId,
    名字: value?.patient?.name,
    '': (
      <RadioGroup
        {...signatureIndicator}
        dataSource={[
          {
            label: '未签名',
            value: false,
          },
          {
            label: '全部',
            value: true,
          },
        ]}
      />
    ),
    性别:
      vsf.stores.dbenums.enums.SEX_DICT?.find(
        (item) => item?.value === value?.patient?.gender,
      )?.label ?? '',
    年龄: value?.age ? value?.age + '岁' : '',
  };

  return (
    <Fragment>
      <Block
        height={getScrollY(isMinScreen ? 80 : 140)}
        footer={<Footer />}
        scroll={false}
        style={{
          padding: 0,
          justifyContent: 'space-between',
        }}
      >
        <VSPromiseTable
          ref={OrderRef}
          onFetch={onFetch}
          className="aspirin-table-no-background-box"
          tableRender={(...args) => {
            const [, table] = args;
            return (
              <div className="aspirin-table-inter-no-box">
                <Tabs items={dataSource} {...order} />
                <div>{table}</div>
              </div>
            );
          }}
          searchFormRender={() => {
            return (
              <div className="aspirin-table-search-form-order-box">
                {Object?.keys(info)?.map((item: string) => {
                  return (
                    <div
                      key={item}
                      className="aspirin-table-search-form-order-item-box"
                    >
                      <span>{item ? item + ':' : ''}</span>
                      <span>{info[item]}</span>
                    </div>
                  );
                })}
              </div>
            );
          }}
          // editable={{
          //   editType: 'single',
          //   columnProps: {
          //     hideInTable: true,
          //   },
          //   saveOnClickOutside: true,
          // }}
          // onRow={(record) => ({
          //   onDoubleClick: () => {
          //     const date = dayjs()?.valueOf();
          //     // 医嘱执行时间不能小于医嘱开始时间，不能大于停止时间;
          //     if (
          //       date <= dayjs?.(record?.createDateTime).valueOf() ||
          //       date >= dayjs?.(record?.stopOrderDateTime)?.valueOf()
          //     ) {
          //       // Tip({
          //       //   content:
          //       //     '以下医嘱执行时间异常，执行时间不能在停止时间后之后！（点击详情查看异常医嘱）',
          //       // });
          //       OrderRef?.current
          //         ?.saveEdit?.()
          //         ?.then(() => OrderRef?.current?.startEdit(record?.id));
          //       return void 0;
          //     }
          //     if (
          //       !record?.orderPerformDto?.firstPerformDateTime ||
          //       list?.map((item) => item?.id)?.includes(record?.id)
          //     ) {
          //       const value = {
          //         ...record,
          //         orderPerformDto: {
          //           ...record?.orderPerformDto,
          //           firstPerformDateTime: date,
          //           performNurse: {
          //             staffName: user?.staffInfo?.staffName,
          //           },
          //         },
          //       };
          //       OrderRef?.current
          //         ?.saveEdit?.()
          //         ?.then(() =>
          //           OrderRef?.current
          //             ?.update(value)
          //             ?.then(() => OrderRef?.current?.startEdit(record?.id)),
          //         );
          //       setList([
          //         ...list?.filter((item) => item?.id !== record?.id),
          //         value,
          //       ]);
          //     }
          //   },
          //   onClick: () => {
          //     row?.onChange(record);
          //   },
          // })}
          scroll={{
            y: getScrollY(isMinScreen ? 230 : 400),
            x: 2400,
          }}
          pagination={false}
          // rowClassName={(record) => {
          //   if (record?.id === row?.value?.id) {
          //     return 'aspirin-row-selected-box';
          //   }
          //   return 'aspirin-row-select-box';
          // }}
          bordered
        >
          <VSTableColumn
            dataIndex={['inpVisitId']}
            title="住院主记录id"
            valueType="digit"
            search
            order={0}
            columnKey={'inpVisitId'}
            hideInTable
            formItemProps={{
              rules: [
                {
                  message: '数据异常,住院主记录id不存在',
                  type: 'number',
                  min: 0,
                  max: undefined,
                },
                { required: true },
              ],
            }}
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['repeatIndicator']}
            title="长期医嘱标识"
            valueType="switch"
            search
            order={0}
            columnKey={'repeatIndicator'}
            hideInTable
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['startPerformDateTime']}
            title="开始时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            preview
          />
          <VSTableColumn
            dataIndex={['orderText']}
            title="医嘱内容"
            valueType="text"
            fieldProps={{}}
            width={260}
            render={(...[, record]) => {
              return (
                <div
                  className={classNames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  {record?.orderText}
                </div>
              );
            }}
            preview
          />
          <VSTableColumn
            width={100}
            dataIndex={['dosage']}
            title="剂量"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            width={100}
            dataIndex={['usage']}
            title="用量"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            width={100}
            dataIndex={['administration']}
            title="途径"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            width={100}
            dataIndex={['frequency']}
            title="频次"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['doctor', 'staffName']}
            title="医生签名"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['orderPerformDto', 'firstPerformDateTime']}
            title="执行时间"
            valueType="aspirinDateTimePicker"
            fieldProps={(record) => {
              return {
                format: 'YYYY-MM-DD HH:mm:ss',
                disabledDate: () => true,
                allowClear: false,
                valueType: 'timestamp',
                onChange: (value) => {
                  // 医嘱执行时间不能小于医嘱开始时间，不能大于停止时间;
                  const create = dayjs?.(record?.createDateTime).valueOf();
                  const last = dayjs?.(record?.stopOrderDateTime)?.valueOf();
                  const start = value <= create;
                  const end = value >= last;
                  if (start || end) {
                    const firstPerformDateTime = list?.find(
                      (item) => item?.id === record?.id,
                    )?.orderPerformDto?.firstPerformDateTime;
                    Tip({
                      content: `以下医嘱执行时间异常，执行时间${
                        start
                          ? '不能早于开始时间之前'
                          : end
                          ? '不能在停止时间后之后'
                          : ''
                      }！（点击详情查看异常医嘱）`,
                      onFinally() {
                        const today = dayjs(firstPerformDateTime)?.valueOf();
                        OrderRef?.current
                          ?.getEditForm()
                          ?.setFieldValue(
                            [
                              record?.id,
                              'orderPerformDto',
                              'firstPerformDateTime',
                            ],
                            today <= create && today >= last ? today : void 0,
                          );
                      },
                    });
                    return;
                  }
                  const state = {
                    ...record,
                    orderPerformDto: {
                      ...record?.orderPerformDto,
                      firstPerformDateTime: value,
                      performNurse: {
                        staffName: user?.staffInfo?.staffName,
                      },
                    },
                  };
                  OrderRef?.current
                    ?.saveEdit?.()
                    ?.then(() =>
                      OrderRef?.current
                        ?.update(value)
                        ?.then(() => OrderRef?.current?.startEdit(record?.id)),
                    );
                  setList([
                    ...list?.filter((item) => item?.id !== record?.id),
                    state,
                  ]);
                },
              };
            }}
            preview={(...[, record]) => {
              return record?.time;
            }}
          />
          <VSTableColumn
            dataIndex={['orderPerformDto', 'checkNurse', 'staffName']}
            title="校对护士"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['orderPerformDto', 'performNurse', 'staffName']}
            title="护士签名"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['performDepartment', 'departmentName']}
            title="执行科室"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['orderPerformDto', 'stopOrderCheckTime']}
            title="停止校对时间"
            valueType="date"
            hideInTable={order?.value !== 'long'}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm:ss',
              // disabledDate: () => true,
              allowClear: false,
            }}
            preview
          />
          <VSTableColumn
            dataIndex={['stopOrderDoctor', 'staffName']}
            title="停止医生"
            valueType="text"
            fieldProps={{}}
            hideInTable={order?.value !== 'long'}
            preview
          />
          <VSTableColumn
            dataIndex={['orderPerformDto', 'stopOrderNurse', 'staffName']}
            title="停止护士"
            valueType="text"
            hideInTable={order?.value !== 'long'}
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            dataIndex={['stopOrderDateTime']}
            title="停止时间"
            valueType="aspirinDateTimePicker"
            hideInTable={order?.value !== 'long'}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm:ss',
              disabledDate: () => true,
              allowClear: false,
            }}
            preview
          />
          {/* formItemProps=
          {{
            style: { color: '#3276E8' },
          }} */}
          <VSTableColumn
            dataIndex={['orderPerformDto']}
            title="查看详情"
            valueType="text"
            width={80}
            fixed="right"
            renderFormItem={(...[, record]) => (
              <span
                onClick={(event) => {
                  event?.stopPropagation();
                  onOpen(record?.record?.id);
                }}
                className="aspirin-tip-blue"
              >
                查看详情
              </span>
            )}
            render={(...[, record]) => (
              <span
                onClick={(event) => {
                  event?.stopPropagation();
                  onOpen(record?.id);
                }}
                className="aspirin-tip-blue"
              >
                查看详情
              </span>
            )}
          />
        </VSPromiseTable>
      </Block>
      <OrderSheetOverviewOrderDetail
        open={open}
        orderId={orderId}
        onCancel={() => setOpen(false)}
      />
      <Dialog row={row} ref={dialog} OrderRef={OrderRef} />
    </Fragment>
  );
};

export default definePage(OrderTable);
