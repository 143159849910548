import './index.less';

import { definePage } from '@vs/vsf-boot';
import { Input } from '@vs/vsf-kit';
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import KeyEvent from '@/module/Keyevent';

const Item = (props) => {
  const { label, value, current, onChange } = props;
  const [shortcutsValue, setShortcutsValue] = useState<string>();

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const handleDealValue = (value) => {
    setShortcutsValue(value);
  };

  useEffect(() => {
    handleDealValue(value);
  }, [value]);

  useEffect(() => {
    if (isFocus && current?.value?.length >= 2) {
      handleDealValue(current?.value ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div className="shortcuts-item">
      <div className="label">{label}</div>

      <Input
        value={shortcutsValue}
        className="value"
        onBlur={() => {
          if (current?.value?.indexOf('+') >= 0) {
            onChange?.(label);
          }
          setIsFocus(false);
        }}
        onFocus={() => {
          setIsFocus(true);
        }}
      />
    </div>
  );
};

type BlockPropsType = {
  title?: string;
  data?: {
    label?: string;
    value?: string | string[];
  }[];
  current?: any;
  onChange?: (v: string) => void;
};

const Block = (props: BlockPropsType) => {
  const { title, data, current, onChange } = props;
  return (
    <div>
      <div className="title">{title}</div>
      <div className="list">
        {data?.map((item) => (
          <Item
            key={item?.label}
            label={item?.label}
            value={item?.value}
            current={current}
            onChange={(label) => {
              onChange?.(label);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Index = (props) => {
  const { settings } = props?.stores || {};
  const { shortcuts, setShortcuts } = settings || {};
  const [currentInfo, setCurrentInfo] = useState<any>();

  const getKeydownInfo = (v) => {
    setCurrentInfo(v);
  };

  const handleChange = (v) => {
    const target = cloneDeep(shortcuts);
    const newData = target?.map((item) => {
      if (item?.label === v) {
        return {
          ...item,
          value: currentInfo?.value,
          keys: currentInfo?.keys,
        };
      } else {
        if (isEqual(currentInfo?.value, item?.value)) {
          return {
            ...item,
            value: '',
            keys: [],
          };
        } else {
          return item;
        }
      }
    });
    setShortcuts(newData);
  };

  return (
    <div className="aspirin-settings-main-shortcuts">
      <KeyEvent
        events={{
          watch: getKeydownInfo,
        }}
      >
        <Block
          title="医嘱"
          data={shortcuts}
          current={currentInfo}
          onChange={handleChange}
        />
      </KeyEvent>
    </div>
  );
};

export default definePage(Index);
