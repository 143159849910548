import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugFormVsDispenseRoundingSettingController_saveDrugFormVsDispenseRoundingSetting_5be1bb:
    {
      method: 'post',
      url: '/api/drug-billing-setting/save-drug-form-vs-dispense-rounding-setting',
      parameterFomatter: (data?: {
        bto: SaveDrugFormVsDispenseRoundingSettingBtoDrugBillingSettingServiceBto[];
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  DrugFormVsDispenseRoundingSettingController_getAllByQueryDrugFormVsDispenseRoundingSettingQto_6980f6:
    {
      method: 'post',
      url: '/api/drug-billing-setting/get-all-by-query-drug-form-vs-dispense-rounding-setting-qto',
      parameterFomatter: (data?: {
        qto: QueryDrugFormVsDispenseRoundingSettingQtoDrugBillingSettingPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugFormVsDispenseRoundingSettingVoDrugBillingSettingEntranceWebVo[],
      ) => data,
    },
});
