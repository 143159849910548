import {
  compose,
  SelectProps,
  Tag,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { useGetState, useMap } from 'ahooks';
import { Select } from 'antd';
import { getPinYinCode } from 'lancet-pywb/PY_WB';
import { isBoolean } from 'lodash';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import type { Key, MutableRefObject } from 'react';
import React, {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

import { askRomanAlphabet } from '../func/ask';

interface ComplexSelectedMethod {
  onChange: (value?: any) => void;
}

const ComplexSelected = createContext<ComplexSelectedMethod>({
  onChange: () => void 0,
});

enum ordinary {
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
}

enum method {
  Shift,
  Ctrl,
  Alt,
  Backspace,
  Del,
  Home,
  End,
  Num,
  Esc,
  Fn,
  Ins,
  F1,
  F2,
  F3,
  F4,
  F5,
  F6,
  F7,
  F8,
  F9,
  F10,
  F11,
  F12,
  Tab,
  Caps,
}

enum math {
  zero = '0',
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
  six = '6',
  seven = '7',
  eight = '8',
  nine = '9',
}

type ComplexSelectValueCheckbox = Key[];
type Ref = {
  focus: () => void;
  blur: () => void;
};
type ComplexSelectValueRadio =
  | (string | number)
  | {
      label: string | number;
      value: string | number | boolean;
    };

type ComplexSelectValue<T> = T extends 'checkbox' | 'radio'
  ? ComplexSelectValueCheckbox
  : ComplexSelectValueRadio;

type KeyDownDeploy = {
  [key in keyof typeof ordinary | keyof typeof method | math]?: (
    event: KeyboardEvent,
    setShow: (state) => void,
  ) => void;
};

enum DeleteSelectProps {
  value,
  defaultValue,
  children,
  open,
  mode,
  tagRender,
  onSearch,
}

type SelectPropsNew = Omit<SelectProps, keyof typeof DeleteSelectProps>;

export type ComplexSelectProps = SelectPropsNew & {
  /**
   * 设置默认值
   */
  defaultValue?: ComplexSelectValue<ComplexSelectProps['mode']>;
  /**
   * 值
   */
  value?: ComplexSelectValue<ComplexSelectProps['mode']>;
  /**
   * 值变化回调
   */
  onChange?: (value?: ComplexSelectValue<ComplexSelectProps['mode']>) => void;
  /**
   * 选择类型单选和多选
   */
  mode: 'checkbox' | 'radio';
  /**
   * 用于选中后输入框显示的内容
   * 默认是表格的 key
   */
  showLabel?: string;
  /**
   * 传一个表格组件
   */
  children: JSX.Element;
  /**
   * 是否隐藏选中项
   */
  hideSelected?: boolean;
  /**
   * 隐藏选择图标
   */
  hideSelectIcon?: boolean;
  /**
   * 定义自定义搜索
   */
  onSearch?: null | ((value: string, filterOption) => void);
  /**
   * 是否开启按键选择
   */
  keyDown?: boolean;
  /**
   * 配置按键选择/按键
   */
  keyDownDeploy?: KeyDownDeploy & { Enter?: (value: unknown) => any };
  /**
   * 是否收起下拉框
   * 注意如果外部控制，组件内部不会帮你做任何收起或者展开操作
   */
  open?: boolean;
  ref?: React.MutableRefObject<any>;
  /**
   * 可以向表格传也可以往这里传，主要是兼容请求表格的按键需求
   */
  dataSource?: any[];
};
/**
 * 复杂选择框
 */
const ComplexSelect = (props: ComplexSelectProps, ref?: any) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    mode = 'checkbox',
    showLabel,
    children,
    hideSelected = false,
    keyDown = true,
    hideSelectIcon = false,
    keyDownDeploy: { Enter, ...keyDownDeploy } = {},
    open,
    onSearch,
    onKeyUp,
    allowClear,
    dataSource: newDataSource = [],
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    value: valueProp,
    defaultValue,
    onChange,
  });
  /**
   * 用于判断多选单选
   */
  const isMode = mode === 'checkbox';
  /**
   * @data 收集
   * 判断用于设置多选和单选情况下的数据类型
   * 和默认值
   * @hide 展开收起程序
   * @input 存取 input 节点的
   */
  const [hide, setHide] = useState<boolean>(false);
  const [select, setSelect] = useState<any>(null);
  /**
   * 用于键盘选择显示用的
   */
  const [show, setShow] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(0);
  const id = useMemo(() => getUUID(), []);
  const dataSource = children?.props?.dataSource?.length
    ? children?.props?.dataSource
    : newDataSource;
  const list = dataSource ?? [];
  const findLabel = (state) => {
    try {
      if (!isMode) {
        const index = list?.findIndex((item) => item.key === state?.key);
        return {
          value: state,
          label: showLabel ? list?.[index]?.[showLabel] : state,
        };
      } else {
        /**
         * 判断传的默认值的类型是不是数组，不然抛出错误
         */
        if (!Array.isArray(state)) {
          throw '选择类型是多选, 默认值类型不是数组';
        }
        return state;
      }
    } catch {
      return state;
    }
  };

  const onFocus = (event) => {
    if (!select) setSelect(event.target);
    setHide(true);
  };

  const onBlur = () => {
    setHide(false);
  };

  /**
   * 设置默认值
   * 不受控时用
   */
  useEffect(() => {
    if (defaultValue) findLabel(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 查找匹配选中的 showLabel 用于多选 tag
   * @param value
   */
  const showFindLabel = ({ value }) => {
    return dataSource.filter((item: any) => item.key === value)[0];
  };
  /**
   * 自定义渲染多选标签函数
   * @param tagProps 选择厚度值
   * @returns tag
   */
  const tagRender = (tagProps: CustomTagProps) => {
    if (Array.isArray(value)) {
      const { closable, value } = tagProps;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      const onNewClose = () => {
        setValue(value.filter((item) => item !== value));
      };
      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onNewClose}
          style={{ marginRight: 3 }}
        >
          {showLabel ? showFindLabel(tagProps)[showLabel] : tagProps.value}
        </Tag>
      );
    }
  };
  /**
   * 收起下拉框
   */
  const close = () => {
    select?.blur();
  };
  /**
   * 用于搜索只保留列数据
   */
  const filterOtherColumns = (dataSource: { [x: string]: any }) => {
    const columns = children.props.columns.map(
      (item: { dataIndex: any }) => item.dataIndex,
    );
    const _ = window._ ?? {};
    return columns
      .map((element: any) => {
        return _.get(dataSource, element?.join('.'));
      })
      .filter((item) => item !== undefined && item !== null);
  };

  const onSelect = (node) => {
    if (!isMode) {
      setValue(node);
      close();
    } else {
      if (value?.includes(node.key)) {
        setValue(value?.filter((item) => item !== node.key));
      } else {
        if (value) setValue([...value, node.key]);
      }
    }
  };

  /**
   * 部分表格配置项
   */
  const newTableProps: any = {
    rowSelection: {
      type: mode,
      ...(hideSelectIcon
        ? {
            hideSelectAll: true,
            renderCell: () => null,
            columnWidth: 0,
          }
        : {}),
      selectedRowKeys: show.length
        ? show
        : Array?.isArray(value)
        ? value?.map((item: any) => item?.value ?? item)
        : [(value as any)?.value ?? value],
      onChange: (...[, selectedRows]) => {
        if (!isMode) {
          setValue(selectedRows[0]);
          close();
        } else {
          setValue(selectedRows);
        }
      },
    },
    onRow: (node) => {
      return {
        onClick: () => onSelect(node),
      };
    },
    'data-id': id,
  };

  // if (hideSelected) {
  //   newTableProps.dataSource = options.filter((item) => {
  //     if (Array.isArray(data)) {
  //       return data?.includes(item.key);
  //     } else {
  //       return data?.value !== item?.key;
  //     }
  //   });
  // }
  const table = document.querySelector(`[data-id="${id}"] .ant-table-body`);
  const tbody = document.querySelectorAll<HTMLAreaElement>(
    `[data-id="${id}"] .ant-table-body tbody .ant-table-row`,
  );
  const MethodKeyDown: KeyDownDeploy & { Enter?: (value: unknown) => any } = {
    ...(isMode
      ? {
          Backspace: (event: any) => {
            /**
             * 当输入框没有值的时候
             * 按退格删除选择项
             */
            if (!event?.target?.value && value?.length) {
              const state = value as any;
              setValue(state?.toSpliced(0, value?.length - 1));
            }
          },
        }
      : {}),
    ArrowUp: () => {
      const top = index - 1;
      if (top >= 0) {
        setIndex(top);
        setShow([dataSource[top]?.key]);
        if (table) {
          table.scrollTo({
            top: tbody[top]?.offsetTop - 50,
            behavior: 'smooth',
          });
        }
      }
    },
    ArrowDown: () => {
      const bottom = index + 1;
      if (bottom < dataSource?.length) {
        setIndex(bottom);
        setShow([dataSource?.[bottom]?.key]);
        if (table) {
          table.scrollTo({
            top: tbody?.[bottom]?.offsetTop - 50,
            behavior: 'smooth',
          });
        }
      }
    },
    Enter: async (event) => {
      if (Enter) {
        if (dataSource?.length) {
          Enter?.(dataSource[index]);
          close();
          onKeyUp?.(event as any);
        }
        return;
      }
      if (dataSource?.length) {
        setValue(dataSource[index]);
        close();
        onKeyUp?.(event as any);
      }
    },
    ...keyDownDeploy,
  };

  useEffect(() => {
    const dataSource = children?.props?.dataSource?.length
      ? children?.props?.dataSource
      : newDataSource;
    const num = dataSource?.findIndex((item) => value === item?.key);
    setIndex(num === -1 ? 0 : num);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, value]);

  const onInputKeyDown = (event) => {
    MethodKeyDown?.[event?.key]?.(event, setShow);
  };

  const filterOption = (value, dataSource) => {
    const filter = (text) => {
      return String(text)
        .replace(/\s*/g, '')
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase().replace(/\s*/g, ''));
    };
    // console.log(dataSource);
    return dataSource?.filter((item) => {
      const list = filterOtherColumns(item).map((item: any) => {
        return [item, ...getPinYinCode(String(item))?.split(',')];
      });
      // console.log(filterOtherColumns(item), 'filterOtherColumns');
      for (const [text, pinyin] of list) {
        const isShow = filter(text) || filter(pinyin);
        if (isShow) {
          return isShow;
        } else {
          continue;
        }
      }
    });
  };

  const SelectProps = {
    showSearch: true,
    ...rest,
    value: (value as any)?.label
      ? value
      : (value as any)?.value ?? value
      ? findLabel((value as any)?.value ?? value)
      : null,
    ...(typeof open === 'boolean'
      ? {}
      : {
          onFocus,
          onBlur,
        }),
    filterOption: false,
    onSearch: (value: string) => {
      onSearch?.(value, filterOption);
    },
    ...(keyDown
      ? {
          onInputKeyDown,
        }
      : {}),
    style: {
      width: '100%',
      ...rest?.style,
    },
    options: [
      {
        /**
         * 使用选择器分组功能实现
         */
        label: cloneElement(children, newTableProps),
      },
    ],
    open: typeof open === 'boolean' ? open : hide,
    ...(allowClear
      ? {
          allowClear: true,
          onClear: () => {
            setValue((isMode ? [] : { value: '' }) as any);
          },
        }
      : {}),
  };

  useEffect(() => {
    /**
     * @const mode 判断单选多选
     * @const tagRender 自定义多选标签
     * @const Backspace 多选退格清除选项
     */
    if (isMode) {
      SelectProps['mode'] = 'multiple';
      SelectProps['tagRender'] = tagRender;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMode]);

  return (
    <ComplexSelected.Provider
      value={{
        onChange: (value) => {
          setValue(value);
          if (typeof open !== 'boolean') select?.blur();
        },
      }}
    >
      <Select {...SelectProps} {...(ref ? { ref } : {})} />
    </ComplexSelected.Provider>
  );
};

ComplexSelect.displayName = 'ComplexSelect';

export function useComplexSelect() {
  const method = useContext(ComplexSelected);
  return method;
}

ComplexSelect.useComplexSelect = useComplexSelect;

export default compose(
  withField<ComplexSelectProps>({
    name: 'ComplexSelect',
  }),
  withPreview<ComplexSelectProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <>{value ?? ''}</>;
    },
  }),
)(forwardRef(ComplexSelect)) as typeof ComplexSelect;
