import { createServices } from '@vs/vsf-boot';
export default createServices({
  OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4: {
    method: 'post',
    url: '/api/organization/get-all-by-department-branch-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentBranchInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-operation-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallOperationDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OperationDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5:
    {
      method: 'post',
      url: '/api/base-common/query-page-fall-by-search-page-fall-diagnosis-dictionary-qto',
      parameterFomatter: (data?: {
        qto: SearchPageFallDiagnosisDictionaryQtoBaseCommonPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DiagnosisDictionaryVoBaseCommonEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  ClinicRecordOrderOperationController_getOperationInfo_f7ce32: {
    method: 'post',
    url: '/api/clinic-record-order-operation/get-operation-info',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OperationApplyInfoEoClinicRecordOrderOperationPersistEo,
    ) => data,
  },
  StaffDetailDtoController_queryDoctorList_7b5e02: {
    method: 'post',
    url: '/api/user-staff/query-doctor-list',
    parameterFomatter: (data?: {
      qto: DoctorListQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryDoctorListBySearchCode_d60b63: {
    method: 'post',
    url: '/api/user-staff/query-doctor-list-by-search-code',
    parameterFomatter: (data?: {
      qto: QueryDoctorListQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_queryOperationDoctorListBySearchCode_3a0b41: {
    method: 'post',
    url: '/api/user-staff/query-operation-doctor-list-by-search-code',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
