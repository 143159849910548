import React, { useContext, useState } from "react";
import vsf, { definePage } from "@vs/vsf-boot";
import Container from "@/module/Container";
import Screen, { CreateScreen } from '@/pages/Index/components/screen';

import DeptDrugApplyTable from './table'
import MasterList from "./master_list";

import './index.less'


const DeptDrugApply = props => {
  const { activityBill } = props

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: '#fff'
          }
        ]
      }}
      className='dept_drug_apply_wrapper'
    >

      <DeptDrugApplyTable activityBill={activityBill} />
    </Container>
  )
}

const DeptDrugApplyContainer = props => {

  const [activityBill, setActivityBill] = useState()

  const handelSelectChange = v => {
    setActivityBill(v)
  }

  return (
    <>
     <MasterList onSelectChange={handelSelectChange} />
      <DeptDrugApply activityBill={activityBill} {...props} />
    </>
  )
}

const DeptDrugApplyScreen = (props) => {
  return (
    <Screen>
      <DeptDrugApplyContainer {...props} />
    </Screen>
  )
}

export default definePage(DeptDrugApplyScreen)