import vsf from '@vs/vsf-boot';
import { message, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import { useGetState, useKeyPress } from 'ahooks';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Icon from '@/module/Icon';
import { askFixed } from '@/pages/Index/components/func/ask';

import { useFetch, useOnChangeValue } from '../func/hooks';

const maxIndex = 9;
const minIndex = 0;

const Index = (props) => {
  const { onSelect, open, searchValue, hospitalType, favoriteType } = props;
  const tableRef = useRef<any>(null);
  const [index, setIndex, getIndex] = useGetState<number>(-1);
  const [dataSource, setDataSource] = useState<any>();

  useEffect(() => {
    tableRef?.current?.setCurrentPage(1);
  }, [searchValue]);

  const handleSelect = useCallback(
    (data) => {
      if (open) {
        onSelect?.(data);
      }
    },
    [open, onSelect],
  );

  const getCurrentData = useCallback(
    (index) => {
      const currentPage = tableRef?.current?.getCurrentPage();
      const v = currentPage ? currentPage - 1 : 0;
      handleSelect(dataSource?.[index]);
    },
    [dataSource, handleSelect],
  );

  useEffect(() => {
    const keyup = {
      ArrowDown: () => {
        if (index < maxIndex && open) {
          setIndex(getIndex() + 1);
        }
      },
      ArrowUp: () => {
        if (index > minIndex && open) {
          setIndex(getIndex() - 1);
        }
      },
      ArrowLeft: () => {
        if (open) {
          tableRef?.current?.gotoPreviousPage();
          setIndex(0);
        }
      },
      ArrowRight: () => {
        if (open) {
          tableRef?.current?.gotoNextPage();
          setIndex(0);
        }
      },
    };
    const up = (event) => {
      keyup?.[event?.key]?.();
    };
    document.addEventListener('keyup', up);
    return () => {
      document?.removeEventListener('keyup', up);
    };
  }, [getIndex, index, open, setIndex]);

  const int = Array?.from({ length: 10 })?.map(
    (...[, index]) => index + 1 + '',
  );

  useKeyPress(
    (event) => int?.includes(event?.key),
    (event) => {
      if (open) {
        const num = Number(event?.key);
        if (num < 9) {
          getCurrentData(num - 1);
        } else {
          getCurrentData(0);
        }
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    'enter',
    () => {
      if (open && index >= 0) {
        getCurrentData(getIndex());
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  const onFetch = useCallback(
    async (params) => {
      let res;
      if (hospitalType === 'in') {
        res =
          await vsf.services?.TreatAndOtherItemController_queryOtherItemsForInpOrder_cf7ea2?.(
            {
              qto: {
                clinicItemNameLike: searchValue.slice(1),
                from: params?.pagination?.from ?? 0,
                size: params?.pagination?.size ?? 8,
                favoriteTypeIs: favoriteType,
              },
            },
          );
      } else {
        res =
          await vsf.services?.TreatAndOtherItemController_queryTreatAndOtherItems_22e81a?.(
            {
              qry: {
                inputCodeLike: searchValue.slice(1),
                from: params?.pagination?.from ?? 0,
                size: params?.pagination?.size ?? 8,
                favoriteTypeIs: favoriteType,
                itemClassIn: ['TREAT', 'EXAM', 'LAB'],
              },
            },
          );
      }
      setDataSource(res?.data?.result ?? res?.data ?? []);
      const reqData = res?.data?.result ?? res?.data;
      return {
        data: Array.isArray(reqData) ? reqData : [],
        total: Array.isArray(reqData) ? reqData?.length : 0,
      };
    },
    [searchValue, hospitalType, favoriteType],
  );

  const Price = ({ recode }) => {
    const [favorite] = useOnChangeValue(false);
    const onCollect = useFetch({
      fetch: async (item) => {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
            {
              favoriteType: 'PERSONAL',
              itemClass: 'TREAT',
              itemId: item?.clinicItemId,
            },
          );
        return res;
      },
      success: () => {
        favorite?.onChange?.(true);
      },
      message: '收藏',
    });
    const onCancelCollect = useFetch({
      fetch: async (value) => {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0?.(
            {
              favoriteType: 'PERSONAL',
              itemClass: 'TREAT',
              itemId: value?.clinicItemId,
            },
          );
        return res;
      },
      message: '取消收藏',
      success() {
        favorite?.onChange?.(false);
      },
    });
    useEffect(() => {
      favorite?.onChange(recode?.isFavorite);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recode?.isFavorite]);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{askFixed(recode?.price, 2)}</span>
        {favorite?.value ? (
          <Icon
            size={24}
            style={{ color: '#ffa77e' }}
            type="icon-shoucang1"
            onClick={(event) => {
              event?.stopPropagation();
              onCancelCollect(recode);
            }}
          />
        ) : (
          <Icon
            type="icon-meiyoushoucang"
            size={24}
            style={{ color: 'rgb(174, 176, 179)' }}
            onClick={(event) => {
              event?.stopPropagation();
              onCollect(recode);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="dictionary-table">
      <VSPromiseTable
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        onFetch={onFetch}
        pagination={{
          defaultPageSize: 8,
        }}
        size="small"
        rowClassName={(_, rowIndex) => {
          return index === rowIndex ? 'ant-table-row-selected' : '';
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          onSelect: (node, key, selected) => {
            handleSelect(node);
          },
          onSelectedRowsChange: (data, keys) => {
            handleSelect(data?.[0]);
          },
        }}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true,
          },
        }}
        rowClick={{
          rowClickType: 'select',
        }}
      >
        <VSTableColumn
          valueType="index"
          render={(v: any) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width={50}
        />

        <VSTableColumn
          dataIndex={['clinicItemName']}
          title="名称"
          valueType="text"
          fieldProps={{}}
          width={250}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="价格"
          valueType="custom"
          fieldProps={{}}
          width={220}
          render={(...[, recode]) => {
            return <Price recode={recode} />;
          }}
        />
      </VSPromiseTable>
    </div>
  );
};

export default Index;
