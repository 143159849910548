import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListDtoController_getAllByPriceItemQueryQto_273108: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-item-query-qto',
    parameterFomatter: (data?: { qto: PriceItemQueryQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListController_getPricePatternMasterDetailById_9a4b90: {
    method: 'post',
    url: '/api/price-list/get-price-pattern-master-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListController_removePriceDetailFromMaster_040237: {
    method: 'post',
    url: '/api/price-list/remove-price-detail-from-master',
    parameterFomatter: (data?: {
      btoParam: RemovePriceDetailFromMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
});
