import { Modal, ModalFuncProps } from 'antd';
import React from 'react';

import Icon from '@/module/Icon';

import Flex from '../flex';

type TipProps = ModalFuncProps & {
  titleStyle?: React.CSSProperties;
  /**
   * {} 包裹字符可以添加高亮蓝色
   */
  content: string;
  TipIcon?: React.ReactNode;
  onFinally?: () => void;
};

export default function Tip(props: TipProps) {
  const {
    TipIcon = null,
    icon = <Icon type="icon-a-tishijingshi" />,
    closeIcon = <Icon type="icon-shanchu1" />,
    centered = true,
    title = '提示',
    okButtonProps = {
      danger: true,
    },
    cancelText = '取消',
    okText = '确定',
    onFinally = () => void 0,
    onOk,
    onCancel,
    ...rest
  } = props;

  const reg = /{(.*?)}/;

  const content = (props.content ?? '').replaceAll(
    /{(.*?)}/gi,
    (str: string) => {
      const newStr = reg.exec(str);
      return `<span class="aspirin-tip-blue">${newStr?.[1] ?? ''}</span>`;
    },
  );

  Modal.confirm({
    ...rest,
    icon: TipIcon,
    title: (
      <Flex justify="space-between" align="center">
        <span>{title}</span>
        <Icon
          type="icon-shanchu1"
          size={24}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            Modal.destroyAll();
          }}
        />
      </Flex>
    ),
    centered,
    okButtonProps,
    onOk: () => {
      onOk?.();
      onFinally?.();
    },
    onCancel: () => {
      onCancel?.();
      onFinally?.();
    },
    cancelText,
    okText,
    zIndex: 1002,
    content: (
      <div className="aspirin-tip-box">
        {icon}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    ),
  });
}
