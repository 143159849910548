import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_getTodayClinicDetailForRegister_7896c6: {
    method: 'post',
    url: '/api/outp-register/get-today-clinic-detail-for-register',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: TodayClinicForRegisterVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
});
