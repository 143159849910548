import Root from '@/layouts';
import Login from '@/module/login';
import Index from '@/pages/Index';
import Demo from '@/pages/Index/demo';

import indexRouter from './IndexRoute';

const routes = [
  {
    path: '/login',
    component: Login,
  },

  {
    path: '/',
    component: Root,
    redirect: '/index/center',
    routes: [
      {
        path: '/index',
        component: Index,
        routes: indexRouter,
        redirect: '/index/center',
      },
      {
        path: '/demo',
        exact: true,
        component: Demo,
      },
    ],
  },
];

export default routes;
