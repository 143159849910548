import { createServices } from '@vs/vsf-boot';
export default createServices({
  FinancePrePaymentController_getPrePaymentInfo_aa8a03: {
    method: 'post',
    url: '/api/finance-payment/get-pre-payment-info',
    parameterFomatter: (data?: {
      displayId: string;
      patientId: number;
      visitId: number;
      prePaymentType: PrePaymentTypeEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PrePaymentInfoVoFinancePaymentEntranceWebVo,
    ) => data,
  },
  FinancePrePaymentController_prePaymentPreRefund_b971d1: {
    method: 'post',
    url: '/api/finance-payment/pre-payment-pre-refund',
    parameterFomatter: (data?: { prePaymentMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: SavePrePaymentMasterBvoFinancePaymentEntranceWebBvo,
    ) => data,
  },
  FinancePrePaymentController_refundPrePaymentMaster_a09098: {
    method: 'post',
    url: '/api/finance-payment/refund-pre-payment-master',
    parameterFomatter: (data?: {
      savePrePaymentMasterBvo: SavePrePaymentMasterBvoFinancePaymentEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FinancePrePaymentController_savePrePaymentMaster_59f4c5: {
    method: 'post',
    url: '/api/finance-payment/save-pre-payment-master',
    parameterFomatter: (data?: {
      savePrePaymentMasterBvo: SavePrePaymentMasterBvoFinancePaymentEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  FinancePrePaymentController_cancelPrePaymentMaster_85130c: {
    method: 'post',
    url: '/api/finance-payment/cancel-pre-payment-master',
    parameterFomatter: (data?: {
      savePrePaymentMasterBvo: SavePrePaymentMasterBvoFinancePaymentEntranceWebBvo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicBedController_getListClinicBedWithPatientVoByWardId_683677: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-bed-with-patient-vo-by-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicBedWithPatientVoClinicBedEntranceWebVo[],
    ) => data,
  },
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoOrganizationEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  FinanceBillingRecordController_unsealAccountById_f73ea8: {
    method: 'post',
    url: '/api/finance-billing-record/unseal-account-by-id',
    parameterFomatter: (data?: { patientBillingRecordId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
