import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getFamilyHistoryByPatientId_58159e: {
    method: 'post',
    url: '/api/clinic-record-history/get-family-history-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FamilyHistoryVoClinicRecordHistoryEntranceWebVo[],
    ) => data,
  },
  ClinicRecordHistoryController_deleteFamilyHistory_6f04c5: {
    method: 'post',
    url: '/api/clinic-record-history/delete-family-history',
    parameterFomatter: (data?: {
      btoParam: DeleteFamilyHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FamilyHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createFamilyHistory_a03440: {
    method: 'post',
    url: '/api/clinic-record-history/create-family-history',
    parameterFomatter: (data?: {
      btoParam: CreateFamilyHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FamilyHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_updateFamilyHistory_6ebedd: {
    method: 'post',
    url: '/api/clinic-record-history/update-family-history',
    parameterFomatter: (data?: {
      btoParam: UpdateFamilyHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FamilyHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
});
