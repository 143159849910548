import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  VSForm,
  VSFormItem,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useCallback, useRef, useState } from 'react';

import Icon from '@/module/Icon';
import { Flex, SelectCode } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { getScrollYRem } from '@/utils';

import { queryScrollWardPatientList } from './service';
const onSpliced = (input) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data.result')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'id');
      obj.inpVisitId = _.get(d, 'inpVisitId');
      obj.bedLabel = _.get(d, 'bed.bedLabel');
      obj.name = _.get(d, 'patient.name');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.haveRefundApply = _.get(d, 'haveRefundApply');
      obj.haveStopOrderPlan = _.get(d, 'haveStopOrderPlan');
      return obj;
    }) ?? []),
  );
  return output;
};

const Search = (props) => {
  // const [search] = useOnChangeValue('', (value: any) => {
  //   patient.current = value?.id;
  //   return value?.id;
  // });
  const [patientId, setPatientId] = useState<any>();
  const [select] = useOnChangeValue<any>({});
  const table = useRef<any>(null);
  // const patient = useRef('');
  const onFetch = useCallback(async () => {
    const res =
      await vsf?.services?.PatientInHosptialController_queryPagedWardPatientList_e6e95f?.(
        {
          qto: {
            // from: params?.pagination?.from ?? 0,
            // size: params?.pagination?.size ?? 20,
            patientId: patientId,
          },
        },
      );
    return {
      data: onSpliced?.(res),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);
  // 患者信息
  const { data: patientList } = useRequest(queryScrollWardPatientList);
  return (
    <Flex vertical>
      <Flex vertical>
        <div>
          <VSForm id="VSForm" labelAlign="left">
            <VSFormItem label="患者" valueType="custom">
              <SelectCode
                {...{
                  allowClear: true,
                  dataSource: patientList,
                  onChange: (value) => {
                    setPatientId(value);
                  },
                }}
              />
            </VSFormItem>
          </VSForm>
        </div>
        <Flex gap={10} justify="flex-end">
          <Button
            type="primary"
            onClick={() => {
              table?.current?.reload();
            }}
          >
            查询
          </Button>
        </Flex>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />
      <VSPromiseTable
        onFetch={onFetch}
        className="aspirin-table-no-background-box left-Table"
        rowClassName={(record) => {
          return record?.id === select?.value?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box';
        }}
        pagination={false}
        scroll={{
          // x: 328,
          // y: isMinScreen ? 400 : 550,
          y: getScrollYRem(25),
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.id === select?.value?.id) {
              select?.onChange?.();
              props?.onChange?.();
              return;
            }
            select?.onChange?.(record);
            props?.onChange?.(record);
          },
        })}
        ref={table}
        // bordered
      >
        <VSTableColumn
          // width={86}
          width={'4rem'}
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          width={'7rem'}
          // width={126}
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          // width={'7rem'}
          // width={106}
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          fieldProps={{}}
          ellipsis
        />

        <VSTableColumn
          width={'4rem'}
          // width={'7rem'}
          // width={106}
          dataIndex={['haveRefundApply']}
          title="标识"
          valueType="text"
          fieldProps={{}}
          ellipsis
          render={(_, { haveRefundApply, haveStopOrderPlan }) => {
            // 标识（标识，是否有停止医嘱未退药处方）
            let str: any;
            // console.log(haveStopOrderPlan, 'haveStopOrderPlan');
            // console.log(haveRefundApply, 'haveRefundApply');
            if (haveStopOrderPlan && !haveRefundApply)
              str = <Icon size={18} type="icon-a-jingshihong" />;
            else {
              return undefined;
            }
          }}
        />
      </VSPromiseTable>
      <Flex style={{ marginTop: '0.3rem', color: '#999' }}>
        <div>
          <Icon size={18} type="icon-a-jingshihong" />
          指患者有停止医嘱未退药处方
        </div>
      </Flex>
    </Flex>
  );
};

export default definePage(Search);
