import vsf from '@vs/vsf-boot';
import { omitBy } from 'lodash';
export const getMedicalTechnologyChargeTemplateListByDepartmentId = async (
  params,
) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateListByDepartmentId_92eead?.(
      { departmentId: params?.departmentId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetMedicalTechnologyChargeTemplateListByDepartmentIdRes2MedicalTechnologyDepartmentChargeTemplateList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetMedicalTechnologyChargeTemplateListByDepartmentIdRes2MedicalTechnologyDepartmentChargeTemplateList
 * @param {any} input
 * @returns {any}
 */
const convertGetMedicalTechnologyChargeTemplateListByDepartmentIdRes2MedicalTechnologyDepartmentChargeTemplateList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.templateName = _.get(d, 'templateName');
        obj.departmentName = _.get(d, 'department.departmentName');
        obj.staffName = _.get(d, 'staff.staffName');
        obj.createStaffName = _.get(d, 'createStaff.staffName'); // TODO 没有匹配到合适字段 obj.createAt = null; // Date 创建日期
        obj.createdAt = _.get(d, 'createdAt');
        return obj;
      }) ?? []),
    );
    return output;
  };
export const getMedicalTechnologyDepartmentList = async (params) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyDepartmentList_59899b?.(
      {},
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetMedicalTechnologyDepartmentListRes2DepartmentChargeTemplateDepartmentList?.(
      res,
    );
  // console.log({ ...res, data: output });
  // return { data: output ?? [], total: output?.length ?? 0 };
  return { ...res, data: output };
};
/**
 * convertGetMedicalTechnologyDepartmentListRes2DepartmentChargeTemplateDepartmentList
 * @param {any} input
 * @returns {any}
 */
const convertGetMedicalTechnologyDepartmentListRes2DepartmentChargeTemplateDepartmentList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.name = _.get(d, 'departmentName');
        obj.id = _.get(d, 'id');
        obj.type = 'departmentId';
        return obj;
      }) ?? []),
    );
    return output;
  };
// 获取模板信息科室
export const getMedicalTechnologyChargeTemplateWithDetailList = async (
  params,
) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateWithDetailList_e192d7?.(
      {
        templateId: params?.search?.id,
        itemName: params?.search?.itemName,
        ...params,
      },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetMedicalTechnologyChargeTemplateWithDetailListRes2MedicalTechnologySaveChargeTemplateRo?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetMedicalTechnologyChargeTemplateWithDetailListRes2MedicalTechnologySaveChargeTemplateRo
 * @param {any} input
 * @returns {any}
 */
const convertGetMedicalTechnologyChargeTemplateWithDetailListRes2MedicalTechnologySaveChargeTemplateRo =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(
        input,
        'data.medicalTechnologyChargeTemplateDetailBaseDtoList',
      )?.map((d, i) => {
        const obj: any = {};
        obj.index = _.get(d, 'authorityIndicator') ? 1 : 0;
        obj.id = _.get(d, 'id') ?? 'create_' + Math.random(); //'create_' + Math?.random?.() + i;
        obj.templateId = _.get(d, 'templateId');
        obj.priceItemId = _.get(d, 'priceItemId');
        obj.itemName = _.get(d, 'itemName');
        obj.itemClass = _.get(d, 'itemClass');
        obj.itemSpecification = _.get(d, 'itemSpecification');
        obj.brand = _.get(d, 'brand');
        obj.unit = _.get(d, 'unit');
        obj.price = _.get(d, 'price');
        obj.amount = _.get(d, 'amount');
        obj.authorityIndicator = _.get(d, 'authorityIndicator');
        return obj;
      }) ?? []),
    );
    return output?.sort((a, b) => b?.index - a?.index);
  };
// 保存医技科室模板及明细
export const saveMedicalTechnologyChargeTemplate = async (event) => {
  // TODO: 请确认入参来源及出参用法
  // const output =
  //   convertMedicalTechnologySaveChargeTemplateRo2SaveMedicalTechnologyChargeTemplateReq?.(
  //     event,
  //   );
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_saveMedicalTechnologyChargeTemplate_792178?.(
      event,
    );
  return res;
};
/**
 * convertMedicalTechnologySaveChargeTemplateRo2SaveMedicalTechnologyChargeTemplateReq
 * @param {any} input
 * @returns {any}
 */
export const convertMedicalTechnologySaveChargeTemplateRo2SaveMedicalTechnologyChargeTemplateReq =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    // output.chargeTemplateBto = {}; // output.chargeTemplateBto.id = null; // 模板id 取外层查询模板id
    // TODO 没有匹配到合适字段 output.chargeTemplateBto.templateName = null; // String 模板名称
    // TODO 没有匹配到合适字段 output.chargeTemplateBto.departmentId = null; // Long 科室编码
    // TODO 没有匹配到合适字段 output.chargeTemplateBto.staffId = null; // Long 员工ID
    // TODO 没有匹配到合适字段 output.chargeTemplateBto.createStaffId = null; // Long 创建人ID
    // output.chargeTemplateBto.medicalTechnologyChargeTemplateDetailBtoList = [];
    output.push(
      ...(input?.map((d, i) => {
        const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
        obj.id = String(_.get(d, `id`)).includes('create_')
          ? null
          : _.get(d, `id`); // TODO 请确认下行字段是否符合逻辑
        obj.priceItemId = _.get(d, `priceItemId`); // TODO 请确认下行字段是否符合逻辑
        obj.amount = _.get(d, `amount`);
        return omitBy(obj, (value) =>
          [undefined, null, NaN, ''].includes(value),
        );
      }) ?? []),
    );
    return output;
  };

export const getDepartmentStaffList = async (params) => {
  const res =
    await vsf?.services?.StaffDetailDtoController_getDepartmentStaffList_8501c0?.(
      {},
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetDepartmentStaffListRes2StaffChargeTemplateStaffList?.(res);
  return { data: output ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetDepartmentStaffListRes2StaffChargeTemplateStaffList
 * @param {any} input
 * @returns {any}
 */
const convertGetDepartmentStaffListRes2StaffChargeTemplateStaffList = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'departmentId');
      obj.name = _.get(d, 'departmentName');
      obj.parent = true;
      obj.children = _.get(d, 'staffVo')?.map((ele) => {
        return {
          type: 'staffId',
          name: _.get(ele, 'staffName'),
          id: _.get(ele, 'id'),
          staffDepartment: _.get(ele, 'staffDepartment'),
        };
      });
      return obj;
    }) ?? []),
  );
  return output;
};
// 获取模板信息科室
export const getMedicalTechnologyChargeTemplateListByStaffId = async (
  params,
) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateListByStaffId_9bf8e5?.(
      { staffId: params?.staffId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetMedicalTechnologyChargeTemplateListByStaffIdRes2StaffChargeTemplateList?.(
      res,
    );
  return { data: output ?? [], total: output?.length ?? 0 };
};
/**
 * convertGetMedicalTechnologyChargeTemplateListByStaffIdRes2StaffChargeTemplateList
 * @param {any} input
 * @returns {any}
 */
const convertGetMedicalTechnologyChargeTemplateListByStaffIdRes2StaffChargeTemplateList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.templateName = _.get(d, 'templateName');
        obj.staffName = _.get(d, 'staff.staffName');
        obj.departmentName = _.get(d, 'department.departmentName');
        obj.createStaffName = _.get(d, 'createStaff.staffName');
        obj.createdAt = _.get(d, 'createdAt');
        return obj;
      }) ?? []),
    );
    return output;
  };

// 删除医技科室收费模板
export const deleteMedicalTechnologyChargeTemplateById = async (event) => {
  const res =
    await vsf?.services?.MedicalTechnologyChargeTemplateController_deleteMedicalTechnologyChargeTemplateById_bfedf9?.(
      {
        templateId: event?.templateId,
      },
    );
  return res;
};
