import { createServices } from '@vs/vsf-boot';
export default createServices({
  PurchaseImportController_getWaitImportPurchaseMasterList_173a66: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-wait-import-purchase-master-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPurchaseMasterStaffSupplierVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  PurchaseImportController_cancelPurchaseMaster_076872: {
    method: 'post',
    url: '/api/drug-storage-import-export/cancel-purchase-master',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
