import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Image, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import Empty from '@/module/Empty';
import Loading from '@/module/Loading';

import Detail from './detail';
import List from './list';
import Search from './search';

const Index = (props) => {
  const {
    stores,
    currentRecordItem,
    showSearch = true,
    medicalHistoryDetailType,
  } = props;
  const { user } = stores ?? {};
  const { initKey, currentPatient, outVisitInfo } = user ?? {};
  const _patientId = currentPatient?.patientId;
  const _outpVisitIdIs = outVisitInfo?.outpVisit?.id;
  // 检验报告列表
  const [reportList, setReportList] = useState<any[]>([]);
  // 报告详情
  const [reportDetail, setReportDetail] = useState();
  // 当前查看报告id
  const [currentReport, setCurrentReport] = useState();
  const [searchList, setSearchList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (reportList.length > 0) {
      getExamineDetail(reportList?.[0]?.orderId);
      setCurrentReport(reportList?.[0]?.orderId);
    } else {
      setReportDetail(undefined);
    }
  }, [reportList]);

  console.log(searchList, medicalHistoryDetailType, 'searchList');
  const getExamineList = useCallback(async () => {
    setLoading(true);
    if (!_patientId) {
      setReportList([]);
      getExamineDetail(undefined);
      setCurrentReport(undefined);
      setReportDetail(undefined);
      setLoading(false);
    } else {
      if (medicalHistoryDetailType && medicalHistoryDetailType !== 'LAB_REPORT')
        return;
      // 区分住院或门诊outpVisitIdIs/inpVisitIdIs,currentRecordItem?.id史就诊史得逻辑
      const currentIdType = currentRecordItem?.id
        ? {
            ...(currentRecordItem.visitRecordHistoryType === 'OUTP'
              ? {
                  outpVisitIdIs: currentRecordItem?.id,
                }
              : {
                  inpVisitIdIs: currentRecordItem?.id,
                }),
          }
        : {
            ...(user?.hospitalType === 'in'
              ? {
                  inpVisitIdIs:
                    !searchList || searchList?.thisVisit
                      ? user?.inVisitInfo?.inpVisitId
                      : undefined,
                }
              : {
                  outpVisitIdIs:
                    !searchList || searchList?.thisVisit
                      ? _outpVisitIdIs
                      : undefined,
                }),
          };
      const res =
        await vsf.services?.PatientJourneyController_getPatientJourney_95f0ff?.(
          {
            qto: {
              orderClassIn: ['LAB'],
              patientIdIs: _patientId,
              ...currentIdType,
              isReport: searchList?.isReportValue ?? false,

              labClassId:
                searchList?.selectValue?.includes('all') ||
                searchList?.selectValue?.includes('全部')
                  ? []
                  : searchList?.selectValue,
              // labClassId: '',
              isPatientJourneyIs: false,
              ...(searchList?.radioValue
                ? {
                    submitDateTimeRangeIn: searchList?.radioValue
                      ? {
                          begin: searchList?.radioValue,
                          // beginInclude: true,
                        }
                      : {
                          begin: searchList?.reportTimeRage?.start,
                          end: searchList?.reportTimeRage?.end,
                          // beginInclude: true,
                          // endInclude: true,
                        },
                  }
                : {}),
            },
          },
        );
      setLoading(false);
      if (res.code === 200) {
        setReportList(res.data);
        // if (res?.data.length > 0) {
        //   getExamineDetail(res.data?.[0]?.orderId);
        //   setCurrentReport(res.data?.[0]?.orderId);
        // } else {
        //   setReportDetail(undefined);
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    medicalHistoryDetailType,
    currentRecordItem,
    _patientId,
    user?.hospitalType,
    user?.inVisitInfo?.inVisitId,
    searchList,
    _outpVisitIdIs,
  ]);

  useEffect(() => {
    getExamineList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExamineList]);

  const getExamineDetail = async (id) => {
    if (!id) {
      setReportDetail(undefined);
    } else {
      const res =
        await vsf?.services?.PatientJourneyController_getPatientJourneyDetail_09a7d6(
          {
            orderId: id,
            icon: 'LAB',
          },
        );
      if (res.code == 200) {
        setReportDetail(res.data);
      }
    }
  };

  const handelSearch = (v) => {
    setSearchList(v);
    // setApplyStatusIs(v?.isReportValue);
    // setThisVisit(v?.thisVisit);
    // setApplyCategoryIn(v?.selectValue);
  };

  return (
    <div className="examine">
      {showSearch && (
        <Search
          currentPatient={currentPatient}
          handelConditionQuery={handelSearch}
        />
      )}
      <div className="examine_main">
        {loading ? (
          <Loading size="normal" />
        ) : (
          !reportList ||
          (reportList?.length === 0 ? (
            <Empty />
          ) : (
            <>
              <List
                value={currentReport}
                dataSource={reportList}
                onChange={(item) => {
                  setCurrentReport(item?.orderId);
                  getExamineDetail(item?.orderId);
                }}
              />
              {reportList && reportList?.length > 0 && (
                <Detail
                  currentRecordItem={currentRecordItem}
                  reportDetail={reportDetail}
                />
              )}
            </>
          ))
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
