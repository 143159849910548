import vsf from '@vs/vsf-boot';
import {
  Button,
  Modal,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import React, { Fragment, useCallback } from 'react';

import { Flex } from '@/pages/Index/components';
import { DosageUnit } from '@/pages/Index/components/dosage';
import { askFixed } from '@/pages/Index/components/func/ask';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import useSoften from '@/pages/Index/components/func/hooks/useSoften';
import { convertOrderInTable } from '@/pages/Index/components/order/out_patient/table/convert';
import { orderClassDataSource } from '@/pages/Index/components/order/out_patient/table/datasource';
import { UsageUnit } from '@/pages/Index/components/usage';
import { getScrollY } from '@/utils';

import Detail from './detail';

const OutOrder = (props) => {
  const { id, visitRecordHistoryType, patientId } = props;

  // 处理成组
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip({ onOk, onCancel }) {},
  });

  const onFetch = useCallback(async () => {
    const res =
      await vsf?.services?.VisitHistoryController_getPatientVisitHistoryDetail_d961fc?.(
        {
          eo: {
            id,
            visitRecordHistoryType,
            detailType: 'ORDER',
            patientId,
          },
        },
      );

    const _convert = convertOrderInTable(
      (res?.data?.patientVisitHistoryDetailOrderList ?? [])?.map((item) => ({
        ...item,
        ...item?.orderVo,
        orderId: item?.orderVo?.id,
        [item?.orderVo?.orderClass?.toLocaleLowerCase() + 'Order']: {
          ...item,
          ...item?.orderVo,
          ...(item?.[
            'order' +
              item?.orderVo?.orderClass?.[0] +
              item?.orderVo?.orderClass?.slice(1)?.toLocaleLowerCase() +
              'Vo'
          ] ?? {}),
        },
        // ...item?.['order']
      })),
    );
    const _data = group?.onSliced(_convert);
    return _data;
  }, [id, patientId, visitRecordHistoryType, group]);

  const patientJourney = async (value) => {
    const res =
      await vsf?.services?.PatientJourneyController_getPatientJourneyDetail_09a7d6?.(
        {
          ...value,
        },
      );
    return res;
  };

  const { ref, Soften } = useSoften();

  return (
    <Fragment>
      <VSPromiseTable
        onFetch={onFetch}
        pagination={false}
        scroll={{
          y: getScrollY(280),
        }}
        bordered
        editable={{
          editType: 'none',
        }}
        className="
        aspirin-table-header-background-color
       aspirin-table-row-hover-background-color
       aspirin-table-nopadding
       aspirin-table-body-empty-transparent-background-color
"
      >
        <VSTableColumn
          title="类别"
          dataIndex={['orderClass']}
          valueType="select"
          dataSource={orderClassDataSource}
          preview
          width={60}
        />
        <VSTableColumn
          title="医嘱"
          dataIndex={['orderText']}
          valueType="custom"
          width="220px"
          className="aspirin-table-order-cell"
          render={(element, record) => {
            return (
              <div
                className={classNames('aspirin-order-text', record?.groupName)}
              >
                {record?.orderText}
              </div>
            );
          }}
          editable={false}
        />
        <VSTableColumn
          title="剂量"
          dataIndex={['dosage']}
          valueType="inputUnit"
          width="50px"
          editable={false}
          render={(...[, record]) => <DosageUnit value={record?.dosage} />}
        />
        <VSTableColumn
          title="用量"
          dataIndex={['usage']}
          valueType="inputUnit"
          width="50px"
          render={(...[, record]) => <UsageUnit value={record?.usage} />}
          editable={false}
        />
        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          valueType="administrationSelect"
          width={50}
          editable={false}
          fieldProps={(record) => {
            return { record };
          }}
        />
        <VSTableColumn
          dataIndex={['frequency', 'frequency']}
          title="频次"
          valueType="frequencySelect"
          width={50}
          editable={false}
          render={(...[, record]) => {
            console.log(record?.frequency, 'record?.frequency');

            return (
              record?.frequency?.frequency ??
              record?.frequency?.frequency?.label ??
              ''
            );
          }}
        />
        <VSTableColumn
          dataIndex={['totalAmount']}
          title="数量"
          valueType="inputUnit"
          fieldProps={{
            allowClear: true,
            width: '100%',
          }}
          render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
          editable={false}
          width={60}
        />
        <VSTableColumn
          dataIndex={['costs']}
          title="总价"
          valueType="text"
          fieldProps={(...args) => {
            const [record] = args;
            return {
              record,
            };
          }}
          width={80}
          preview
          render={(...[, record]) => {
            const price = askFixed(record?.costs ?? 0, 2);
            return Number?.isNaN(price) || !record?.costs ? '' : price + '元';
          }}
        />
        <VSTableColumn
          dataIndex={['orderVo', 'doctor', 'staffName']}
          title="开单医生"
          valueType="text"
          fieldProps={(...args) => {
            const [record] = args;
            return {
              record,
            };
          }}
          width={100}
          preview
        />
        {/* <VSTableColumn
          dataIndex={['performDepartment', 'departmentName']}
          title="执行科室"
          valueType="text"
          width={100}
          preview
        /> */}
        <VSTableColumn
          dataIndex={['edit']}
          title="操作"
          valueType="custom"
          fieldProps={{}}
          render={(...[, record]) => {
            return (
              <Flex
                // justify="center"
                style={{ color: 'var(--blue)', cursor: 'pointer' }}
              >
                <span
                  onClick={async () => {
                    const detail = await patientJourney({
                      orderId: record?.orderId,
                      icon: record?.orderClass,
                    });
                    (ref.current as any)?.set({
                      open: true,
                      detail: detail?.data,
                    });
                  }}
                >
                  查看详情
                </span>
              </Flex>
            );
          }}
          width={60}
          preview
        />
      </VSPromiseTable>
      <Soften ref={ref}>
        {(state) => {
          return (
            <Modal
              open={state?.open}
              title="拆分计划"
              width="50%"
              onCancel={() => {
                (ref?.current as any)?.set({
                  open: false,
                });
              }}
              footer={
                <Flex justify="flex-end">
                  <Button
                    onClick={() => {
                      (ref?.current as any)?.set({
                        open: false,
                      });
                    }}
                  >
                    取消
                  </Button>
                </Flex>
              }
            >
              <Detail detail={state?.detail} />
            </Modal>
          );
        }}
      </Soften>
    </Fragment>
  );
};

export default OutOrder;
