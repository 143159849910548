import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getPatientVisitHistory_6e55dc: {
    method: 'post',
    url: '/api/clinic-record/get-patient-visit-history',
    parameterFomatter: (data?: {
      qto: QueryOutpVisitHistoryListNewQtoClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientVisitHistoryVoClinicRecordEntranceWebVo[],
    ) => data,
  },
});
