import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpDrugAuditController_getOrderToAuditByWard_ed7357: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-order-to-audit-by-ward',
    parameterFomatter: (data?: {
      query: QueryWardPatientWaitAuditEoDrugDispenseAuditPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPerformForDispenseAuditGroupPatientVoDrugDispenseAuditEntranceWebVo[],
    ) => data,
  },
  InpDrugAuditController_refundOrders_b565a6: {
    method: 'post',
    url: '/api/drug-dispense-audit/refund-orders',
    parameterFomatter: (data?: {
      bto: CreateDrugDispenseAuditMasterAndDetailBtoDrugDispenseAuditServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpDrugAuditController_getCreateRefundAuditVoById_8269f2: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-create-refund-audit-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CreateRefundAuditVoDrugDispenseAuditEntranceWebVo,
    ) => data,
  },
  InpDrugAuditController_getOrderProblemByClassCode_dc8536: {
    method: 'post',
    url: '/api/drug-dispense-audit/get-list-order-problem-dictionary-vo-by-problem-class-code',
    parameterFomatter: (data?: { problemClassCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderProblemDictionaryVoDrugDispenseAuditEntranceWebVo[],
    ) => data,
  },
});
