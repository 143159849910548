import React from 'react';

import { Section } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const InstitutionTree = (props) => {
  const handleAddWithParent = (node) => {
    props.onChange?.({
      upInstitutionId: node?.id,
      upInstitutionName: node?.institutionName,
    });
  };
  return (
    <>
      <TreeMenu
        id="InstitutionTree"
        loadData={async () => {
          const res =
            await vsf.services.MedicalInstitutionDtoController_getAllByInstitutionQto_926a63(
              { qto: {} },
            );
          return res;
        }}
        fieldNames={{
          title: 'institutionName',
          key: 'id',
          children: 'children',
          // 服务端未提供拼音搜索，暂时先不做
          // pinyinSearch: 'institutionAlias',
        }}
        dataRelationFieldNames={{
          id: 'id',
          parentId: 'upInstitutionId',
        }}
        onSelect={(_value) => {
          props.onChange?.(_value);
        }}
        search={{ status: true, placeholder: '请输入医疗机构名称' }}
        title="数据分类"
        reload
        row={{
          expand: false,
          extra: (node) => {
            return node?.upInstitutionId ? null : (
              <div>
                <Icon
                  type="icon-tianjia"
                  size={24}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddWithParent(node);
                  }}
                />
              </div>
            );
          },
          extraShow: 'default',
        }}
        context={{
          defaultContextMenu: {
            items: [
              {
                label: '新增医疗机构',
                key: 'addInstitution',
                hidden: false,
                close: true,
                callback: (node) => {
                  props.onChange?.({});
                },
              },
            ],
            trigger: 'click',
          },
        }}
      />
    </>
  );
};
export default definePage(InstitutionTree);
