import './index.less';

import vsf from '@vs/vsf-boot';
import React from 'react';

import { Determine, PatientJourney } from '@/pages/Index/components';
import storage from '@/utils/storage';

import Timeline from './timeline';
const Index = (props) => {
  const { prefixCls } = props;
  const hospitalType = vsf?.stores?.user?.hospitalType;
  return (
    <div className={`${prefixCls}-rightContainer`}>
      <Determine
        condition={hospitalType === 'out'}
        fallback={<PatientJourney />}
      >
        <Timeline />
      </Determine>
    </div>
  );
};

export default Index;
