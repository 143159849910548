import './index.less';

import vsf from '@vs/vsf-boot';
import {
  compose,
  Input,
  message,
  Select,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';

import DeleteIcon from './delete.png';

interface PatientIdentificationVoPatientMainEntranceWebVoPatientfullvo {
  /**
   * 主键
   * @format int64
   */
  id?: number;

  /** 身份识别号码 */
  identificationNumber?: string;

  /** 身份识别信息类型id */
  identificationClass?: string;
}

export type PatientIdentificationsProps = {
  /**
   * 默认值
   */
  defaultValue?: PatientIdentificationVoPatientMainEntranceWebVoPatientfullvo[];
  /**
   * 值
   */
  value?: PatientIdentificationVoPatientMainEntranceWebVoPatientfullvo[];
  /**
   * 值变化回调
   */
  onChange?: (
    value?: PatientIdentificationVoPatientMainEntranceWebVoPatientfullvo[],
  ) => void;

  /**
   * 特定参数用于外部按钮点击
   */
  addNums?: number;
};

/**
 * PatientIdentifications
 */
const PatientIdentifications = (props: PatientIdentificationsProps) => {
  const { defaultValue, value: valueProp, addNums = 0, onChange } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const handelSelectChange = (e, index) => {
    if (value?.find((item) => item?.identificationClass === e)) {
      setValue(
        cloneDeep(value ?? [])?.map((item, _idx) => ({
          ...item,
          identificationClass:
            index === _idx ? undefined : item?.identificationClass,
        })),
      );
      return message.error('当前证件类型已存在，请勿重复添加');
    }
    setValue(
      cloneDeep(value ?? [])?.map((item, _idx) => ({
        ...item,
        identificationClass: index === _idx ? e : item?.identificationClass,
      })),
    );
  };

  const handelInputChange = (e, index) => {
    setValue(
      cloneDeep(value ?? [])?.map((item, _idx) => ({
        ...item,
        identificationNumber:
          index === _idx ? e.target?.value : item?.identificationNumber,
      })),
    );
  };

  const handelRemoveClick = (index) => {
    setValue(cloneDeep(value ?? [])?.filter((_, _idx) => _idx !== index));
  };

  /** 编写组件逻辑 */
  return (
    <div className="patient_identification_wapper">
      {value?.map((item, index) => (
        <div key={index}>
          <Select
            showSearch
            value={item?.identificationClass}
            dataSource={vsf?.stores?.dbenums?.enums?.IDENTIFICATION_CLASS_DICT}
            onChange={(e) => handelSelectChange(e, index)}
          />
          <div className="identification_number">
            <Input
              value={item?.identificationNumber}
              onChange={(e) => handelInputChange(e, index)}
            />
          </div>
          <img src={DeleteIcon} onClick={() => handelRemoveClick(index)} />
        </div>
      ))}
    </div>
  );
};
PatientIdentifications.displayName = 'PatientIdentifications';

export default compose(
  withField<string>({
    name: 'PatientIdentifications',
  }),
  withPreview<PatientIdentificationsProps>({
    renderPreview: (props) => {
      const { value } = props;
      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(PatientIdentifications);
