import vsf, { definePage } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';

import Container from '@/module/Container';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';

import InpHerbDispenseRefundApplyDetailForPerform from './application';
import Search from './search';
import './index.less';
const ApplicationInpHerbRefundPerform = (props) => {
  const [select] = useOnChangeValue({});
  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState(0);
  const resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);
  return (
    <Container
      className="ApplicationInpHerbRefundPerform"
      layout={{
        items: [
          {
            block: '24%',
            bgColor: 'var(--background)',
            padding: '1.6rem',
            gap: '1.3rem',
          },
          {
            block: 1,
          },
        ],
      }}
    >
      <Search {...{ select }} />
      <InpHerbDispenseRefundApplyDetailForPerform value={select?.value} />
    </Container>
  );
};
export default definePage(ApplicationInpHerbRefundPerform);
