import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Divider,
  VSControlledForm,
  VSFormLayout,
  VSFormItem,
  Placeholder,
} from '@vs/vsf-kit';
import './index.less';
import { askFixed } from '../../components/func/ask';
import { subtract } from '@/utils/setDataUtil';
import { labelColWidth } from '@/utils';
function ParModal(props) {
  const { payParamData, appointmentStatus } = props;
  //弹窗相关
  const chargeType = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;
  const identity = vsf.stores.dbenums.enums.IDENTITY_DICT;
  const [payWays, setPayWays] = useState();
  const [chargeData, setChargeData] = useState();
  const [identityData, setIdentityData] = useState();
  useEffect(() => {
    chargeType?.map((item) => {
      if (item?.value === payParamData?.outpSettleMasterVo?.chargeType) {
        setChargeData(item);
      }
    });
    identity.map((item) => {
      if (item?.value === payParamData?.outpSettleMasterVo?.identityType) {
        setIdentityData(item);
      }
    });
  }, [chargeType, identity]);
  useEffect(() => {
    if (payParamData) {
      vsf?.refs?.clinicOutpRegisterMainRegisterFormResult.setFieldsValue({
        totalCharge: payParamData?.outpSettleMasterVo?.totalCharge,
      });
    }
  }, [payParamData]);
  return (
    <div className="pay-layout-container">
      <div className="pay-person-detaill">
        <Row>
          <Col span={6}>
            姓名：
            <span className="person-detaill">
              {payParamData?.outpSettleMasterVo?.name}
            </span>
            {/* <span>{feibie}</span> */}
          </Col>
          <Col span={6}>
            费别：
            <span className="person-detaill">{chargeData?.label}</span>
          </Col>
          <Col span={6}>
            人员类别：
            <span className="person-detaill">
              {payParamData?.outpSettleMasterVo?.identityType &&
                (vsf.stores.dbenums?.enums?.IDENTITY_DICT?.find(
                  (item) =>
                    item?.value ===
                    payParamData?.outpSettleMasterVo?.identityType,
                )?.label ??
                  '')}
            </span>
          </Col>
          <Col span={6}>
            票据NO：<span className="person-detaill"></span>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="pay-person-content">
        <div className="pay-person-content-left">
          <div>
            <Row>
              {payParamData?.outpSettleMasterVo?.outpSettleApportionItemDtoList?.map(
                (item) => {
                  return (
                    <>
                      <Col span={12}>
                        <span className="total-cost">
                          {item?.settleApportionName}：
                        </span>
                        <span className="total-number">
                          {askFixed(item?.apportionCharge, 2)}元
                        </span>
                      </Col>
                    </>
                  );
                },
              )}
            </Row>
          </div>
        </div>
        <div className="pay-person-content-right">
          <div className="receivable-accounts">
            <div>应收款</div>
            <div className="receivable-number">
              {askFixed(
                payParamData?.outpSettleMasterVo?.selfPaymentTotalCost,
                2,
              )}
              元
            </div>
          </div>
          <VSControlledForm id="clinicOutpRegisterMainRegisterFormResult">
            <VSFormLayout
              key="1"
              columnCount={1}
              labelWidth={labelColWidth[8]}
              style={{ marginTop: '28px' }}
            >
              <VSFormItem
                name={['totalCharge']}
                label={<div className="receivable-form-title">现金</div>}
                fieldProps={{
                  className:'eceivable-form-height',
                  placeholder: '请输入现金',
                  onChange: (item) => {
                    let remaining = subtract(
                      item.target.value,
                      payParamData?.outpSettleMasterVo?.selfPaymentTotalCost,
                      2,
                    );
                    if (!item.target.value) {
                      remaining = '0.00';
                    }
                    vsf?.refs?.clinicOutpRegisterMainRegisterFormResult.setFieldsValue(
                      { remaining: remaining },
                    );
                  },
                }}
                valueType="text"
                rules={[
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
              />
            </VSFormLayout>
            <VSFormLayout key="2" columnCount={1} labelWidth={labelColWidth[8]}>
              <VSFormItem
                name={['remaining']}
                label={<div className="receivable-form-title">找零</div>}
                fieldProps={{
                  // style: { height: '48px' },
                  className: 'eceivable-form-height',
                  placeholder: '请输入找零',
                }}
                valueType="text"
                rules={[
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ]}
              />
            </VSFormLayout>
            <VSFormLayout key="3" columnCount={1} labelWidth={120}>
              <VSFormItem
                name={['paymentDetail', 'paymentType']}
                valueType="radio"
                dataSource={[
                  { label: '聚合支付', value: '1', disabled: true },
                  { label: '现金', value: 'CASH' },
                  { label: '银联卡', value: '3', disabled: true },
                  { label: '支票', value: '4', disabled: true },
                ]}
                fieldProps={{
                  defaultValue: 'CASH',
                  onChange: (item) => {
                    setPayWays(item);
                  },
                }}
              />
            </VSFormLayout>

            {payWays === 'cash' && (
              <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                <VSFormItem
                  name={['paymentDetail', 'paymentAmount']}
                  label={<div className="receivable-form-title">授权码</div>}
                  fieldProps={{
                    style: { height: '48px' },
                    placeholder: '请输入授权码',
                  }}
                  transform={(v) => {
                    return { paymentAmount: Number(v) };
                  }}
                  valueType="text"
                />
              </VSFormLayout>
            )}
            {payWays === '4' && (
              <>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={<div className="receivable-form-title">支票号</div>}
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入支票号',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="5" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行名称</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行名称',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
                <VSFormLayout key="6" columnCount={1} labelWidth={120}>
                  <VSFormItem
                    name={['clinicRegisterDefine', 'doctor', 'staffName']}
                    label={
                      <div className="receivable-form-title">银行账户</div>
                    }
                    fieldProps={{
                      style: { height: '48px' },
                      placeholder: '请输入银行账户',
                    }}
                    valueType="text"
                  />
                </VSFormLayout>
              </>
            )}
          </VSControlledForm>
        </div>
      </div>
    </div>
  );
}

export default ParModal;
