import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_saveInpPreTransferApply_0c4ee0: {
    method: 'post',
    url: '/api/clinic-inp-adt/save-inp-pre-transfer-apply',
    parameterFomatter: (data?: {
      btoParam: SaveInpPreTransferApplyBtoClinicInpAdtServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreTransferApplyToDepartmentVoClinicInpAdtEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_deleteInpPreTransferApply_75b615: {
    method: 'post',
    url: '/api/clinic-inp-adt/delete-inp-pre-transfer-apply',
    parameterFomatter: (data?: {
      btoParam: DeleteInpPreTransferApplyBtoClinicInpAdtServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreTransferApplyToDepartmentVoClinicInpAdtEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_getAllByInpTransferApplyByVisitIdAndStatusQto_d0e747: {
    method: 'post',
    url: '/api/clinic-inp-adt/get-all-by-inp-transfer-apply-by-visit-id-and-status-qto',
    parameterFomatter: (data?: {
      qto: InpTransferApplyByVisitIdAndStatusQtoClinicInpAdtPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreTransferApplyToDepartmentVoClinicInpAdtEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitController_getInpVisitInHospitalWithBedVoById_0824de: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-inp-visit-in-hospital-with-bed-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitInHospitalWithBedVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  MedicalInstitutionDtoController_getInstitutionById_5594f2: {
    method: 'post',
    url: '/api/organization/get-institution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoClinicInpAdtEntranceWebVoInppretransferapplytodepartmentvo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoClinicInpAdtEntranceWebVoInppretransferapplytodepartmentvo[],
    ) => data,
  },
});
