import Block from '@/module/Block';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  Button,
  VSDataProvider,
  VSTable,
  VSTableColumn,
  Select,
  VSTableToolbar,
  withRef,
  VSPromiseTable,
} from '@vs/vsf-kit';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useCallback } from 'react';

const SpecificationEdit = React.forwardRef((props, ref) => {
  /**
   * 商品信息
   * @type {object}
   */
  const [item, setItem] = useState();
  const [selectList, setSelectList] = useState([]);
  const currentSelect = useRef();
  const [currentSelectState, setCurrentSelectState] = useState();

  useImperativeHandle(ref, () => {
    return {
      getValues: async () => {
        const values =
          await vsf.refs?.tableDrugSpecificationVsStoragetable?.getValues();
        return Promise.resolve([
          {
            ...values?.[0],
            drugPriceItemId: currentSelect.current,
          },
        ]);
      },
    };
  });

  useEffect(() => {
    laodSelectData();
  }, []);

  const laodSelectData = async (params) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.CurrentDrugPriceItemController_getCurrentDrugPricesByDrugProductId_3d0b37?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          drugProductId: props?.value?.drugProductId,
        },
      ); // TODO: 你可能需要整理返回数据格式
    setSelectList(
      res?.data?.map((item) => ({
        label: item?.packageSpecification,
        value: item?.id,
      })),
    );
    return res?.data;
  };
  /**
   * convertFormDrugSpecificationVsStorageEdit2UpdateDrugStockSupplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertFormDrugSpecificationVsStorageEdit2UpdateDrugStockSupplyReq = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = {};
    output.stock = {};
    output.stock.supplyIndicator = false; // Boolean 供应标志
    output.stock.storageId = _.get(input, 'storageId'); // Long 库房ID
    output.stock.packageSpecification = _.get(input, 'packageSpecification'); // String 包装规格
    output.stock.drugProduct = {};
    output.stock.drugProduct.id = _.get(input, 'drugProductId');
    return output;
  };
  /**
   * 选中行数据
   * @param {object} params
   * @returns {object}
   */
  const getCurrentDrugProductId = (params) => {
    return { qto: { idIs: params?.drugProductId } };
  };
  /**
   * convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2FormDrugSpecificationVsStorageEdit
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2FormDrugSpecificationVsStorageEdit =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      const list = input?.result ?? [];
      if (list.length > 0) {
        const item = list[0];
        output.drugName = _.get(item, 'drugInfo.drugName');
        output.drugProductName = _.get(item, 'drugProductName');
        const prices = _.get(item, 'drugPriceItemList') ?? [];
        if (prices.length > 0) {
          output.packageSpecification = _.get(
            prices[0],
            'packageSpecification',
          );
        }
        output.storageName = _.get(item, 'storageName');
        const vsStorages =
          _.get(item, 'drugSpecificationVsStorageDtoList') ?? [];
        if (vsStorages.length > 0) {
          output.drugPriceItemId = _.get(vsStorages[0], 'id');
        }
        output.drugProductId = _.get(item, 'id');
        output.storageId = _.get(item, 'storageId');
      }
      return output;
    };
  /**
   * convertFormDrugSpecificationVsStorageEdit2SaveDrugSpecificationVsStorageReq
   * @param {any} input
   * @returns {any}
   */
  const convertFormDrugSpecificationVsStorageEdit2SaveDrugSpecificationVsStorageReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {
        drugProductId: input?.drugProductId,
        drugPriceItemId: input?.drugPriceItemId,
        drugStorageId: input?.storageId,
      }; // TODO output.item.id = null; // Long 主键
      // TODO output.item.drugStorageId = null; // Long 库存管理单位代码
      // TODO output.item.drugPriceItemId = null; // Long 价表项目编码
      // TODO output.item.drugProductId = null; // Long 商品编码
      return output;
    };

  const onFetch = useCallback(async () => {
    const res =
      await vsf.services?.DrugDrugController_queryPagedByDrugProductSpecificationVsStorageQto_172d45(
        getCurrentDrugProductId(props.value),
      );

    const _data =
      convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2FormDrugSpecificationVsStorageEdit(
        res?.data,
      );
    _data?.drugPriceItemId && setCurrentSelectState(_data?.drugPriceItemId);
    return {
      data: [_data],
      total: 1,
    };
  }, []);

  return (
    <Block style={{ padding: 0 }}>
      <VSPromiseTable
        onFetch={onFetch}
        id="tableDrugSpecificationVsStoragetable"
        // value={convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2FormDrugSpecificationVsStorageEdit(item)}
        pagination={false}
      >
        <VSTableToolbar
          title="药品在用规格列表"
          style={{ fontWeight: 'bold' }}
        />

        <VSTableColumn
          title="通用名"
          dataIndex={['drugName']}
          valueType="text"
        />
        <VSTableColumn
          title="商品名"
          dataIndex={['drugProductName']}
          valueType="text"
        />
        <VSTableColumn
          title="包装规格"
          dataIndex={['packageSpecification']}
          valueType="text"
        />
        <VSTableColumn
          title="库房"
          dataIndex={['storageName']}
          valueType="text"
        />
        <VSTableColumn
          title="拆分规格"
          dataIndex={['drugPriceItemId']}
          valueType="custom"
          fieldProps={{
            showSearch: true,
          }}
          render={(_, record) => {
            return (
              <Select
                showSearch
                width="10.66rem"
                value={currentSelectState}
                dataSource={selectList}
                placeholder="请选择拆分规格"
                onChange={(e) => {
                  currentSelect.current = e;
                  setCurrentSelectState(e);
                }}
              />
            );
          }}
        />
      </VSPromiseTable>
    </Block>
  );
});
SpecificationEdit.displayName = 'SpecificationEditRef';
export default withRef()(SpecificationEdit);
