import { createServices } from '@vs/vsf-boot';
export default createServices({
  ComplaintJudgeDictionaryController_createComplaintJudgeDictionary_cbf96f: {
    method: 'post',
    url: '/api/base-common/create-complaint-judge-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateComplaintJudgeDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintJudgeDictionaryVoBaseCommonEntranceWebVo,
    ) => data,
  },
  ComplaintJudgeDictionaryController_updateComplaintJudgeDictionary_8355bb: {
    method: 'post',
    url: '/api/base-common/update-complaint-judge-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateComplaintJudgeDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintVsJudgeWithJudgeDiseaseVoBaseCommonEntranceWebVo,
    ) => data,
  },
  ComplaintJudgeDictionaryController_deleteComplaintJudgeDictionary_f68b79: {
    method: 'post',
    url: '/api/base-common/delete-complaint-judge-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteComplaintJudgeDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChiefComplaintVsJudgeWithJudgeDiseaseVoBaseCommonEntranceWebVo,
    ) => data,
  },
  ComplaintJudgeDictionaryController_getAllByChiefComplaintJudgeDictionaryNewQto_989929:
    {
      method: 'post',
      url: '/api/base-common/get-all-by-chief-complaint-judge-dictionary-new-qto',
      parameterFomatter: (data?: {
        qto: ChiefComplaintJudgeDictionaryNewQtoBaseCommonPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ChiefComplaintJudgeDictionaryVoBaseCommonEntranceWebVo[],
      ) => data,
    },
});
