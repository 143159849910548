import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordHistoryController_getDiseaseHistoryByPatientId_81fac3: {
    method: 'post',
    url: '/api/clinic-record-history/get-disease-history-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseHistoryVoClinicRecordHistoryEntranceWebVo[],
    ) => data,
  },
  ClinicRecordHistoryController_deleteDiseaseHistory_8e7d75: {
    method: 'post',
    url: '/api/clinic-record-history/delete-disease-history',
    parameterFomatter: (data?: {
      btoParam: DeleteDiseaseHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createDiseaseHistory_71c74d: {
    method: 'post',
    url: '/api/clinic-record-history/create-disease-history',
    parameterFomatter: (data?: {
      btoParam: CreateDiseaseHistoryBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiseaseHistoryVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
});
