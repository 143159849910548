import vsf from '@vs/vsf-boot';
// 科室
export const getPagedBySearchCode = async (param) => {
  const res =
    await vsf?.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.({
      nameLike: '',
      outpOrInp: '1',
      branchInstitutionId: null,
      clinicAttribute: '0',
    });
  return res?.data?.result;
};
// 医生
export const getPagedByPageUserStaffByInputCodeQto = async (param) => {
  const res =
    await vsf?.services?.UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d?.(
      {
        qto: {
          from: param?.pagination?.from ?? 0,
          size: param?.pagination?.size ?? 99999999,
          ...param?.search,
          ...param?.extra,
          ...(param ?? {}),
        },
      },
    );
  return res?.data?.result;
};
