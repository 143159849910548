import { createServices } from '@vs/vsf-boot';
export default createServices({
  ApplicationFunctionGroupDtoController_getFunctionGroupById_b79d49: {
    method: 'post',
    url: '/api/application/get-function-group-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationFunctionGroupVoApplicationEntranceWebVo,
    ) => data,
  },
});
