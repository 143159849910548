import React, { useEffect, useState } from 'react';
import './index.less';
import List from './List';
import {
  Form,
  Row,
  VSForm,
  VSFormItem,
  VSFormLayout,
  Select,
  Image,
  Col,
  Checkbox,
  Spin,
} from '@vs/vsf-kit';
import readCard from '@/assets/icon/readCard.png';
import { useRequest } from 'ahooks';
import MainTable from './MainTable';
import { askFixed } from '../../components/func/ask';
import { definePage } from '@vs/vsf-boot';

import '@/style/adaptation.less';

function index(props) {
  const { routes } = props,
    { patient } = routes?.query,
    { displayId } = patient ? JSON.parse(patient) : { displayId: undefined },
    [PageType, setPageType] = useState('');
  useEffect(() => {
    if (displayId) {
      run(displayId);
      setDisplayIdStatus(displayId);
      vsf?.refs?.outpatientChargesForm.setFieldValue('displayId', displayId);
      setPageType('routes');
    }
  }, [displayId]);
  const [displayIdStatus, setDisplayIdStatus] = useState();

  //选择左侧的数据源
  const [selectTable, setSelectTable] = useState();

  const [isSelf, setIsSelf] = useState(false);
  const [resultData, setResultData] = useState();
  const [dataSorce, setDataSorce] = useState();
  const [sexObj, setSexObj] = useState({});

  const sex = vsf.stores.dbenums?.enums?.SEX_DICT;

  const [total, setTotal] = useState();

  const onTotalChange = (param) => {
    setTotal(param);
  };

  const [standingType, setStandingType] = useState();

  const sortTimeStrings = (timeStrings, orderKey) => {
    if (!timeStrings) return [];
    return timeStrings?.sort((a, b) => {
      const timeA = new Date(a?.[orderKey]);
      const timeB = new Date(b?.[orderKey]);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const onChange = (param) => {
    rowKeyChange(param);
    setSelectTable(
      sortTimeStrings(param, 'registerDate')?.map((item) => ({
        ...item,
        outpBillingDetailList: sortTimeStrings(
          item?.outpBillingDetailList,
          'orderDate',
        ),
      })),
    );
  };
  const onResultDataChange = (param) => {
    setResultData(param);
  };

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const { data: detailList, run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpBillingController_queryAllUnsettledOutpVisitRecord_fad1e4?.(
          {
            // TODO: 后续加
            // patientId: params?.patientId,
            displayId: params,
          },
        );
      const result = res.data?.outpVisitUnsettledRecord?.map((item) => {
        const outpBillingDetailList = item?.outpBillingDetailList?.map(
          (item1, index) => {
            return {
              ...item1,
              index,
              outpBillingDetailVoList: item1?.outpBillingDetailVoList?.map(
                (ele) => ({
                  ...ele,
                  // 非托收创建id
                  id: ele?.id ?? '' + Math?.random() + item1?.receiptGroupId,
                }),
              ),
            };
          },
        );
        return { ...item, outpBillingDetailList: outpBillingDetailList };
      });
      return { ...res.data, outpVisitUnsettledRecord: result } ?? [];
    },
    {
      manual: true,
    },
  );
  const { run: patientDetallRun } = useRequest(
    async (output) => {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          { qto: { ...output, from: 0, size: 20 } },
        );
      if (!res?.data?.result[0]) {
        message.error('未查到患者信息');
      } else {
        vsf?.refs?.outpatientChargesForm.setFieldsValue({
          ...res?.data?.result[0],
          patientDisplayId: res?.data?.result[0].displayId,
        });
        run(res?.data?.result[0].displayId);
        onPressEnter(res?.data?.result[0].displayId);
      }

      return res?.data?.result;
    },
    { manual: true },
  );

  const [rowKeys, setRowKeys] = useState([]);

  const refresh = () => {
    setDataSorce(undefined);
    const file = vsf?.refs?.outpatientChargesForm.getFieldValue('displayId');
    run(file);
    setDisplayIdStatus(file);
  };
  const rowKeyChange = (param) => {
    setRowKeys(param);
  };
  useEffect(() => {
    detailList && setDataSorce(detailList);
  }, [detailList]);
  const dataSorceChange = () => {
    setDataSorce(undefined);
    vsf?.refs?.outpatientChargesForm?.resetFields();
  };
  return (
    <div className="charge-layout">
      <div className="top">
        <Row className="row_class">
          <VSForm id="outpatientChargesForm">
            <VSFormLayout columnCount={2}>
              <VSFormItem
                name={'displayId'}
                label={'患者ID'}
                fieldProps={{
                  className: 'displayId',
                  onKeyPress: (e) => {
                    const sexObj = {};
                    sex?.map((item) => {
                      sexObj[item.value] = item.label;
                    });
                    setSexObj(sexObj);
                    if (e.key === 'Enter') {
                      onResultDataChange(undefined);
                      setDataSorce(undefined);
                      setSelectTable([]);
                      setRowKeys([]);
                      const file =
                        vsf?.refs?.outpatientChargesForm.getFieldValue(
                          'displayId',
                        );
                      run(file);
                      setDisplayIdStatus(file);
                    }
                  },
                }}
              ></VSFormItem>
              <VSFormItem
                name={['patientIdIs']}
                label="证件及编号"
                valueType="text"
                fieldProps={{
                  className: 'patientIdIs',
                  addonBefore: (
                    <>
                      <Select
                        className="patientIdIs-select"
                        dataSource={certificateData}
                        fieldNames={{ label: 'name', value: 'code' }}
                        onChange={(v) => {
                          setStandingType(v.name);
                        }}
                      />
                    </>
                  ),

                  suffix: (
                    <div
                      className="suffix-readCard"
                      onClick={() => {
                        message.success('读卡中');
                      }}
                    >
                      <div className="suffix-cardImg">
                        <Image
                          src={readCard}
                          className="suffix-readCard-image"
                          preview={false}
                        ></Image>
                      </div>
                      <div className="suffix-cardText">读卡</div>
                    </div>
                  ),
                  placeholder: '请读卡',
                  onPressEnter: (e) => {
                    if (standingType && e.target.value) {
                      patientDetallRun({
                        identificationNumberIn: [e.target.value],
                        identificationClassIn: [standingType],
                      });
                    }
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </Row>
        <div className="info_view">
          <div className="info_view__content">
            <div>姓名:</div>
            <div className="left20">{dataSorce?.name}</div>

            <div className="left40">性别：</div>
            <div className="left20">{sexObj[dataSorce?.gender]}</div>

            <div className="left40">年龄：:</div>
            <div className="left20">{dataSorce?.age}</div>

            <div className="left100">
              <Checkbox></Checkbox>
            </div>
            <div className="left10">单位发票</div>

            <div className="left100">
              <Checkbox
                onChange={(v) => {
                  setIsSelf(v);
                }}
              ></Checkbox>
            </div>
            <div className="left10">自费结算</div>

            <div style={{ textAlign: 'right' }}></div>
          </div>
          <div className="info_view__right" style={{ flex: '1' }}>
            <Row>
              <Col span={6} offset={12}>
                医嘱单数：
                {selectTable
                  ? selectTable.reduce(
                      (sum, obj) => sum + obj?.outpBillingDetailList?.length,
                      0,
                    )
                  : '0'}
                张
              </Col>
              <Col span={6}>
                合计：
                {selectTable
                  ? selectTable.reduce(
                      (sum, obj) =>
                        sum +
                        obj?.outpBillingDetailList?.reduce(
                          (total, item) => total + item?.subTotal,
                          0,
                        ),
                      0,
                    )
                  : '0.00'}
                元
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <List
            detailList={dataSorce}
            onChange={onChange}
            onResultDataChange={onResultDataChange}
            rowKeys={rowKeys}
            rowKeyChange={rowKeyChange}
            PageType={PageType}
          />
        </div>
        <div className="right">
          <MainTable
            selectTable={selectTable}
            isSelf={isSelf}
            onResultDataChange={onResultDataChange}
            dataSorceChange={dataSorceChange}
            detailList={detailList}
            displayIdStatus={displayIdStatus}
            rowKeys={rowKeys}
            refresh={refresh}
            rowKeyChange={rowKeyChange}
            onTotalChange={onTotalChange}
            onSelectTableChange={onChange}
          ></MainTable>
        </div>
      </div>
    </div>
  );
}

export default definePage(index);
