import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal, message } from '@vs/vsf-kit';
import React, { Fragment, useEffect, useState } from 'react';
import LabClassTree from './tree';
import Container from '@/module/Container';
import Tab from './tab';
import Edit from './edit';
import Tip from '@/pages/Index/components/func/Tip';
const LabItemManage = (props) => {
  /**
   * DataList
   * @type {any}
   */
  const [dataList, setDataList] = useState(null);
  const [title, setTitle] = useState('新增检验项目分类');
  const [addNode, setAddNode] = useState(false);
  const [open, setOpen] = useState(false);
  const handleAdd = (node) => {
    setOpen(true);
    setTitle('新增检验项目分类');
    setAddNode({});
  };
  const handleStop = (node) => {
    Tip({
      title: '操作窗口',
      content: `确定停用{【${node.labClassName}】}？该分类下的检验项目将全部停用，停用后不可恢复！`,
      onOk: async () => {
        const res = await vsf?.services?.LabController_stopLabClass_6f16ed?.({
          id: node.id,
        });
        if (res.code === 200) {
          vsf.refs.LabClassTree.handleReload();
          message.success('停用成功');
        }
        return res?.data;
      },
      centered: true,
      okText: '确定',
      cancelText: '取消',
    });
  };
  /**
   * 分类添加
   * @returns 数据
   */
  const onOk = async () => {
    try {
      await vsf.refs.LabEdit.validateFieldsReturnFormatValue();
      const params = vsf.refs.LabEdit.getFieldsValue();
      const funcName = params.id
        ? 'LabController_updateLabClass_2620ef'
        : 'LabController_createLabClass_07c440';
      const res = await vsf?.services?.[funcName]?.({
        btoParam:
          {
            ...params,
            branchInstitutionIdList: params.branchInstitutionIdList.select,
          } ?? {},
      });
      if (res.code === 200) {
        vsf.refs.LabClassTree.handleReload();
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = (node) => {
    setOpen(true);
    setAddNode(node);
    setTitle('修改检验项目分类');
  };
  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '12px 24px',
            },
          ],
        }}
      >
        <LabClassTree
          onChange={(_value) => {
            setDataList(_value);
          }}
          onAdd={handleAdd}
          onStop={handleStop}
          onUpdate={handleUpdate}
        />
        <Tab
          value={dataList}
          data={addNode}
          onChange={(value) => {
            setActive(value);
          }}
        />
      </Container>
      <Modal
        title={title}
        open={open}
        className="aspirin-linkpage aspirin-linkpage-static-height"
        width="40%"
        okText="保存"
        cancelText="取消"
        onOk={onOk}
        destroyOnClose
        onCancel={() => setOpen(false)}
      >
        <Edit value={addNode} />
      </Modal>
    </Fragment>
  );
};
export default definePage(LabItemManage);
