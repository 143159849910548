import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpRefundDispenseController_getInpRefundApplyToPerform_1a5feb: {
    method: 'post',
    url: '/api/drug-prescription-dispense/get-inp-refund-apply-to-perform',
    parameterFomatter: (data?: {
      qto: GetInpRefundApplyToPerformQtoDrugPrescriptionDispensePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPrescriptionWaitDispenseMasterForInpRefundPerformVoDrugPrescriptionDispenseEntranceWebVo[],
    ) => data,
  },
  InpRefundDispenseController_performInpDrugRefund_2060dd: {
    method: 'post',
    url: '/api/drug-prescription-dispense/perform-inp-drug-refund',
    parameterFomatter: (data?: { waitMasterIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PatientInHosptialController_queryScrollWardPatientList_346269: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-scroll-ward-patient-list',
    parameterFomatter: (data?: {
      qto: PageWardPatientQueryEoClinicInpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientInHospitalBedWardPatientVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
