import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpSettleController_queryAllOutpSettleMaster_246314: {
    method: 'post',
    url: '/api/outp-billing/query-all-outp-settle-master',
    parameterFomatter: (data?: {
      qto: OutpSettleMasterNewQtoOutpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpSettleMasterWithPaymentInfoDtoOutpBillingManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserGroupController_getOutpChargeGroupMemberList_0cd8c3: {
    method: 'post',
    url: '/api/user-group/get-outp-charge-group-member-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupMemberDetailVoUserGroupEntranceWebVo[],
    ) => data,
  },
});
