import { createServices } from '@vs/vsf-boot';
export default createServices({
  PurchaseImportController_getSavedImportMaster_54a84d: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-save-purchase-import-master',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterSupplierStorageDepartmentVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  PurchaseImportController_getDetailByMasterId_422e69: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-detail-by-master-id',
    parameterFomatter: (data?: { drugImportMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportDetailPriceProductFirmDictionaryVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  PurchaseImportController_cancelImportMaster_062cb8: {
    method: 'post',
    url: '/api/drug-storage-import-export/cancel-import-master',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PurchaseImportController_getPurchaseDetailByMasterId_9d4b44: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-purchase-detail-by-master-id',
    parameterFomatter: (data?: { purchaseMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportDetailPriceProductFirmDictionaryVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  PurchaseImportController_getWaitImportPurchaseMaster_384ae9: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-wait-import-purchase-master',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterSupplierStorageDepartmentVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  PurchaseImportController_getBatchNumberByDrugPriceItemId_e26c6c: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-batch-number-by-drug-price-item-id',
    parameterFomatter: (data?: { drugPriceItemId: number }) => data,
    responseFormatter: (_, __, data?: string[]) => data,
  },
  PurchaseImportController_savePurchaseImport_b097b5: {
    method: 'post',
    url: '/api/drug-storage-import-export/save-purchase-import',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugStorageImportMasterWithDetailBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PurchaseImportController_accountPurchaseImport_74699c: {
    method: 'post',
    url: '/api/drug-storage-import-export/account-purchase-import',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugStorageImportMasterWithDetailBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugDrugController_getPagedProfileDrugProduct_9f4f4f: {
    method: 'post',
    url: '/api/drug-drug/query-paged-profile-drug-product',
    parameterFomatter: (data?: {
      query: DrugPriceItemForDrugApplyDropEoDrugDrugPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugPriceItemProductFirmDictionaryVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStoragePurchaseController_getPagefallSupplier_40a286: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-pagefall-supplier',
    parameterFomatter: (data?: {
      qto: PageFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugFirmDictionaryVoDrugStorageImportExportEntranceWebVoDrugimportmastersupplierstoragedepartmentvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStorageController_getSubStorageList_cb7e95: {
    method: 'post',
    url: '/api/drug-storage/get-sub-storage-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SubDrugStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_getCandicateDrugPharmacyList_3ef044: {
    method: 'post',
    url: '/api/drug-storage/get-candicate-drug-pharmacy-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
