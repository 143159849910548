import {
  Section,
  VSFormItem,
  VSForm,
  VSDataProvider,
  VSFormLayout,
  VSFormDependency,
  // Select,
} from '@vs/vsf-kit';
import {
  appendTreeLevel,
  buildTree,
  filterTreeData,
  highlight,
} from '@/components/TreeMenu/utils';
import { Select } from 'antd';
import { async } from 'q';
import React, { useEffect, useState, useCallback } from 'react';

import { askSearch } from '@/pages/Index/components/func/ask';
import aspirinDateTimePicker from '@/components/AspirinDateTimePicker';
import FormTitle from '@/pages/Index/components/form_title';
import vsf, { definePage } from '@vs/vsf-boot';
import { labelColWidth } from '@/utils';

const formItemWitdh = 215;
const formTitleBgc = '#f4f5f7';

const CreateStaff = (props) => {
  const { departmentInfo } = props;
  const [departmentId, setDepartmentId] = useState();
  console.log(props, '========');
  const hasId = (data) => {
    return data?.id;
  };
  /**
   * 员工详情
   * @type {StaffVoUserStaffEntranceWebVo}
   */
  const [staffDetail, setStaffDetail] = useState();
  const [staffList, setStaffList] = useState();
  console.log(departmentInfo, 'departmentInfo');
  useEffect(() => {
    hasId(props.value) &&
      vsf.services
        .StaffDetailDtoController_getById_6ecdf0({
          id: props?.value?.id,
        })
        .then((res) => {
          vsf.refs.StaffForm?.setFieldsValue({
            ...res.data,
            staffDepartmentId: res?.data?.staffDepartment?.id,
            offDuty: res?.data?.offDuty ? res?.data?.offDuty : false,
            staffDepartment: {
              departmentName:
                res?.data?.staffDepartment?.departmentName +
                '-' +
                res?.data?.staffDepartment?.branchInstitution?.institutionName,
              id: res?.data?.staffDepartment?.id,
            },
            doctorDto: {
              ...res?.data?.doctorDto,
              tutorStaffId: {
                staffName: res?.data?.tutorStaffName,
                staffId: res?.data?.doctorDto?.tutorStaffId,
              },
            },
          });
          setDepartmentId(res.data?.staffDepartment?.id);
        });
  }, [props.value]);

  // 实习导师
  const UserStaffController = async (v) => {
    console.log(v);
    const res =
      await vsf.services?.UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d(
        {
          qto: {
            from: 0,
            size: 100,
            staffDepartmentIdIs: !departmentInfo?.disabledAdd
              ? departmentInfo?.id
              : departmentId,

            ...v,
          },
        },
      );
    const _res = res?.data?.map((item) => {
      return { label: item?.staffName, value: item?.id };
    });
    return res?.data;
  };

  const handelList = async (v) => {
    // let _dept = {};
    // if (!departmentInfo) {
    //   // 查当前机构下的员工科室
    //   _dept = {};
    // } else if (departmentInfo && departmentInfo?.disabledAdd) {
    //   // 查当前院区 departmentCode
    //   _dept = { branchInstitutionIdIn: [departmentInfo?.departmentCode] };
    // } else if (departmentInfo) {
    //   // 查当前科室 id
    //   _dept = { upDepartmentCodeIs: departmentInfo?.departmentCode };
    // }

    const res =
      await vsf.services.DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de(
        {
          qto: {
            ...v,
            // ..._dept,
          },
        },
      );
    const _res = (res?.data ?? [])
      ?.map((item) => {
        return {
          departmentName:
            item?.departmentName +
            '-' +
            item?.branchInstitution?.institutionName,
          id: item?.id,
        };
      })
      ?.filter((i) => !i?.stopIndicator);
    return _res;
  };

  return (
    <Section title="">
      <VSForm
        labelAlign="left"
        vsid="87273"
        id="StaffForm"
        labelWrap
        // onChange={(_value) => {
        //   props.onChange?.(_value);
        // }}
      >
        <VSFormItem
          name={['createStaffId']}
          valueType="text"
          style={{ display: 'none' }}
        />
        <VSFormItem
          name={['id']}
          valueType="text"
          style={{ display: 'none' }}
        />
        <FormTitle
          style={{ backgroundColor: formTitleBgc, padding: 0 }}
          title="基本信息"
          isNeedTag={true}
        >
          <VSFormLayout labelCol={labelColWidth[9]} columnCount={3}>
            <VSFormItem
              name={['staffNumber']}
              label="员工编号"
              valueType="text"
              rules={[
                {
                  message: 'emp_no长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
                { required: true },
              ]}
              fieldProps={{
                disabled: hasId(props.value),
                onChange: (v) => {
                  if (!hasId(props.value)) {
                    console.log(v);
                    vsf.refs.StaffForm?.setFieldsValue({
                      loginName: v?.target?.value,
                    });
                  }
                },
              }}
            />
            <VSFormItem
              name={['staffName']}
              label="员工姓名"
              valueType="text"
              rules={[
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
                { required: true },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['loginName']}
              label="登录名"
              valueType="text"
              rules={[
                {
                  message: 'staff_name长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
                { required: true },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['staffDepartment']}
              label="员工科室"
              valueType="select"
              rules={[
                // {
                //   message: '员工科室的值不合法',
                //   type: 'number',
                //   min: 0,
                //   max: null,
                // },
                { required: true },
              ]}
              initialValue={
                !departmentInfo
                  ? null
                  : departmentInfo && departmentInfo?.disabledAdd
                  ? null
                  : departmentInfo && !departmentInfo?.disabledAdd
                  ? {
                      departmentName:
                        departmentInfo?.departmentName +
                        '-' +
                        departmentInfo?.branchInstitution?.institutionName,
                      id: departmentInfo?.id,
                    }
                  : null
              }
              fieldProps={{
                showSearch: true,
                placement: 'bottomLeft',

                // getPopupContainer: (triggerNode) => {
                //   return triggerNode.parentElement;
                // },
                // onSearch: (v) => {
                //   handelList(v);
                // },
              }}
              dataSource={handelList}
              fieldNames={{ label: 'departmentName', value: 'id' }}
              searchKey="inputCodeLike"
              transform={(v) => {
                // console.log(v, 'ppp');
                setDepartmentId(v?.value);
                return {
                  staffDepartment: {
                    departmentName: v?.departmentName,
                    id: v?.id,
                  },
                  staffDepartmentId: v?.value,
                };
              }}
            />
            <VSFormItem
              name={['jobClassCode']}
              label="工作类别"
              valueType="custom"
              rules={[{ required: true }]}
              // dataSource={vsf.stores.dbenums?.enums?.JOB_CLASS_DICT}
              fieldProps={{
                showSearch: true,
              }}
            >
              <Select
                options={vsf.stores.dbenums?.enums?.JOB_CLASS_DICT}
                filterOption={askSearch}
                placeholder="请选择工作类别"
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentElement;
                }}
                placement="bottomLeft"
                showSearch
              />
            </VSFormItem>
            <VSFormItem
              name={['titleCode']}
              label="技术职务"
              valueType="custom"
              rules={[{ required: true }]}
              // dataSource={vsf.stores.dbenums?.enums?.TITLE_DICT}
              fieldProps={{
                showSearch: true,
              }}
            >
              <Select
                options={vsf.stores.dbenums?.enums?.TITLE_DICT}
                filterOption={askSearch}
                placeholder="请选择技术职务"
                showSearch
              />
            </VSFormItem>

            {/* <VSFormItem
              valueType="custom"
              rules={[{ required: true }]}
              name={['titleCode']}
              label="技术职务"
            >
              <Select
                options={vsf.stores.dbenums?.enums?.TITLE_DICT}
                placeholder="技术职务"
                filterOption={askSearch}
                width={'100%'}
                showSearch
              />
            </VSFormItem> */}

            <VSFormItem
              name={['offDuty']}
              label="在岗标志"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              initialValue={false}
              dataSource={[
                { label: '在岗', value: false },
                { label: '不在岗', value: true },
              ]}
              rules={[{ required: true }]}
            />
            <VSFormItem
              name={['idNumber']}
              label="身份证号"
              valueType="text"
              rules={[
                {
                  message: '身份证号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1000,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['nationCode']}
              label="国家编码"
              valueType="text"
              rules={[
                {
                  message: '国家便面长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['certificationNumberForNurse']}
              label="执业证书"
              valueType="text"
              rules={[
                {
                  message: '国家便面长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['validPeriod']}
              label="竞聘时间"
              // valueType="aspirinDateTimePicker"
              valueType="date"
              // type="single"
              // format="YYYY/MM/DD"
              fieldProps={{
                // format: 'YYYY/MM/DD',
                width: '100%',
              }}
            />
            <VSFormItem
              name={['gender']}
              label="性别"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              rules={[{ required: true }]}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: formTitleBgc, padding: 0 }}
          title="医生信息"
          isNeedTag={true}
        >
          <VSFormLayout labelCol={labelColWidth[9]} columnCount={3}>
            <VSFormItem
              name={['doctorDto', 'specialityDescription']}
              label="专业特长描述"
              valueType="text"
              rules={[
                {
                  message: 'speciality_desc长度不合法',
                  type: 'string',
                  min: 0,
                  max: 255,
                },
              ]}
              fieldProps={{
                showSearch: true,
              }}
            />

            <VSFormItem
              name={['doctorDto', 'certificationNumber']}
              label="资格证号"
              valueType="text"
              rules={[
                {
                  message: 'identification_no长度不合法',
                  type: 'string',
                  min: 0,
                  max: 30,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'professionalLevel']}
              label="医生职级"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              dataSource={vsf.stores.dbenums?.enums?.PROFESSIONAL_LEVEL}
            />
            <VSFormItem
              name={['doctorDto', 'internshipIndicator']}
              label="实习标志"
              valueType="select"
              dataSource={[
                { label: '正式', value: 'OFFICIAL' },
                { label: '实习', value: 'INTERSHIP' },
                { label: '进修', value: 'FURTHER_EDUCATION' },
              ]}
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
            />

            <VSFormItem
              name={['doctorDto', 'tutorStaffId']}
              label="实习导师"
              valueType="select"
              dataSource={UserStaffController}
              fieldNames={{ label: 'staffName', value: 'id' }}
              fieldProps={{ showSearch: true }}
              searchKey="inputCodeLike"
            />
          </VSFormLayout>
          <VSFormLayout labelCol={labelColWidth[9]} columnCount={3}>
            <VSFormItem
              name={['doctorDto', 'antibioticPermission']}
              label="抗生素权限/住"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              // rules={[{ required: true }]}
              dataSource={vsf.stores.dbenums?.enums?.DRUG_ANTIBIOTIC_TYPE_DICT}
            />
            <VSFormItem
              name={['doctorDto', 'antibioticPermissionOutp']}
              label="抗生素权限/门"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              dataSource={vsf.stores.dbenums?.enums?.DRUG_ANTIBIOTIC_TYPE_DICT}
            />
            <VSFormItem
              name={['doctorDto', 'drugAntitumorLevel']}
              label="抗肿瘤权限"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              dataSource={vsf.stores.dbenums?.enums?.DRUG_ANTITUMOR_LEVEL}
            />
            <VSFormItem
              name={['doctorDto', 'prescriptionPermission']}
              label="处方权限"
              initialValue={false}
              valueType="switch"
              // rules={[{ required: true }]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'toxicPermission']}
              label="毒麻药权限"
              valueType="switch"
              initialValue={false}
              // rules={[{ required: true }]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'antibioticAuditPermission']}
              label="抗生素审核"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'operationPermission']}
              label="手术权限"
              valueType="switch"
              initialValue={false}
              // rules={[{ required: true }]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'operationLevel']}
              label="手术等级"
              valueType="select"
              fieldProps={{
                showSearch: true,
                getPopupContainer: (triggerNode) => {
                  return triggerNode.parentElement;
                },
              }}
              dataSource={vsf.stores.dbenums?.enums?.OPERATION_SCALE_DICT}
            />
            <VSFormItem
              name={['doctorDto', 'anesthesiaPermission']}
              label="麻醉权限"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'daySurgeryPermission']}
              label="日间申请权限"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'specialDiseasePermission']}
              label="门诊特殊病权"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'herbPermission']}
              label="中药权限"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
            <VSFormItem
              name={['doctorDto', 'viewMedicalRecordPermission']}
              label="查看全院病例"
              valueType="switch"
              initialValue={false}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: formTitleBgc, padding: 0 }}
          title="扩展信息"
          isNeedTag={true}
        >
          <VSFormLayout labelCol={labelColWidth[9]} columnCount={3}>
            <VSFormItem
              name={['birthday']}
              label="出生日期"
              valueType="date"
              fieldProps={{ width: '100%' }}
            />
            <VSFormItem
              name={['cellphone']}
              label="手机号"
              valueType="text"
              rules={[
                {
                  message: 'phone_number长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['cellphoneCornet']}
              label="手机短号"
              valueType="text"
              rules={[
                {
                  message: 'mobile_phone_cornet长度不合法',
                  type: 'string',
                  min: 0,
                  max: 20,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['wechatId']}
              label="微信号"
              valueType="text"
              rules={[
                {
                  message: 'wechat_id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['dingdingId']}
              label="钉钉号"
              valueType="text"
              rules={[
                {
                  message: 'dingding_id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
      </VSForm>
    </Section>
  );
};
export default definePage(CreateStaff);
