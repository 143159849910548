import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageProfileManageController_queryPagefallByQueryProfileProductWithInputQto_fa7f60:
    {
      method: 'post',
      url: '/api/drug-storage/query-pagefall-by-query-profile-product-with-input-qto',
      parameterFomatter: (data?: {
        qto: QueryProfileProductWithInputQtoDrugStoragePersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugStorageProfileProductStockVoDrugStorageEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
