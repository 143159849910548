import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockSupplyController_queryDrugStockSupply_25f6e9: {
    method: 'post',
    url: '/api/drug-storage/query-drug-stock-supply',
    parameterFomatter: (data?: {
      qto: QueryDrugStockSupplyQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSupplyVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_getOtherStockAmount_8737b8: {
    method: 'post',
    url: '/api/drug-storage/get-other-stock-amount',
    parameterFomatter: (data?: {
      qto: DrugStockStorageIdDrugProductIdPackageSpecificationEoDrugStoragePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStockWithStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
});
