export const CLASS_PREFIX = 'aspirin';
export const ICON_URL = '/offline/iconfont.js';
export const ICON_URL_CPOE = '/offline/iconfont_cpoe.js';
export const getPrefixCls = (
  suffixCls?: string,
  customizePrefixCls?: string,
) => {
  if (customizePrefixCls) return customizePrefixCls;
  return suffixCls ? `${CLASS_PREFIX}-${suffixCls}` : CLASS_PREFIX;
};

export const STORAGE_KEY_PREFIX = 'aspirin-admin';

export const isMinScreen = document?.documentElement?.clientWidth < 1700;

export default {};
