import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientOperateLogController_getPagedByOperationLogPatientQto_5a3e28: {
    method: 'post',
    url: '/api/patient-extension/get-paged-by-operation-log-patient-qto',
    parameterFomatter: (data?: {
      qto: OperationLogPatientQtoPatientExtensionPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OperationLogPatientVoPatientExtensionEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
