import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

import Detail from './details/detail';
// import Detail from './details_copy/detail';
import List from './lists';
import Search from './search';

const Index = (props) => {
  // 当前选中行
  const [currentRowId, setCurrentRowId] = useState<any>();
  // 搜索id
  const [searchId, setSearchId] = useState<any>();

  const [init, setInit] = useState<any>();
  const initRef = useRef(0);
  const listRef = useRef<any>();
  // 清屏
  const handelClear = () => {
    // setSarchParams(null);
    setCurrentRowId(null);
  };

  const handelSaveSuccess = () => {
    listRef?.current?.handelReload();
  };

  return (
    <div className="emergency_treatment">
      <div className="emergency_treatment_search">
        <Search
          searchOnChange={(id) => {
            initRef.current = initRef.current + 1;
            // console.log(initRef, 'initRef');
            setCurrentRowId({ id, type: 'search', init: initRef.current });
            // setSarchParams(id);
          }}
        />
      </div>
      <div className="emergency_treatment_body">
        <div className="emergency_treatment_body_list">
          <List
            ref={listRef}
            rowOnChange={(id, objId) => {
              setCurrentRowId({ id, type: 'doubleClick', ...objId });
              setInit(init + 1);
            }}
          />
        </div>
        <div className="emergency_treatment_body_detail">
          <Detail
            init={init}
            clearScreen={() => {
              handelClear();
            }}
            handelReloadList={listRef?.current?.handelReload}
            currentRowId={currentRowId}
            handelSaveSuccess={handelSaveSuccess}
          />
        </div>
      </div>
    </div>
  );
};
export default Index;
// 预检分诊
