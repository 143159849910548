import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d: {
    method: 'post',
    url: '/api/outp-register-define/get-all-identification-class-dictionary',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: IdentificationClassDictionaryEoOutpRegisterDefinePersistEo[],
    ) => data,
  },
  OutpRegisterDefineController_deleteIdentificationClassDictionary_e88c28: {
    method: 'post',
    url: '/api/outp-register-define/delete-identification-class-dictionary',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_addUpdateIdentificationClassDictionary_70e37e: {
    method: 'post',
    url: '/api/outp-register-define/add-update-identification-class-dictionary',
    parameterFomatter: (data?: {
      btoParamsList: IdentificationClassDictionaryEoOutpRegisterDefinePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
