import React, { useState } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { Menu } from '@vs/vsf-kit';
const MenuIndex = (props) => {
  const { MenuCurrent, setMenuCurrent, setDepartment } = props,
    MenuItems = [
      {
        label: '科室模板',
        key: 'mail',
      },
      {
        label: '个人模板',
        key: 'app',
      },
    ],
    onClick = (e) => {
      setMenuCurrent(e.key);
      setDepartment();
    };
  return (
    <div className="Menu">
      <Menu
        onClick={onClick}
        selectedKeys={[MenuCurrent]}
        mode="horizontal"
        items={MenuItems}
      />
    </div>
  );
};

export default definePage(MenuIndex);
