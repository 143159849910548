import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageController_createOrUpdateSubDrugStorage_73ec27: {
    method: 'post',
    url: '/api/drug-storage/create-or-update-sub-drug-storage',
    parameterFomatter: (data?: {
      btoList: UpdateSubStorageBtoDrugStorageServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_deleteSubDrugStorage_9e49ce: {
    method: 'post',
    url: '/api/drug-storage/delete-sub-drug-storage',
    parameterFomatter: (data?: {
      bto: DeleteSubStorageBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugDrugController_queryPagefallByDrugDictionaryDrugListQto_270f4c: {
    method: 'post',
    url: '/api/drug-drug/query-pagefall-by-drug-dictionary-drug-list-qto',
    parameterFomatter: (data?: {
      qto: DrugDictionaryDrugListQtoDrugDrugPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryDrugListEoDrugDrugPersistEo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
