import vsf, { definePage } from '@vs/vsf-boot';
import {
  Checkbox,
  Form,
  List,
  Popover,
  RadioGroup,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useAsyncEffect, useGetState, useMount, useRequest } from 'ahooks';
import classNames from 'classnames';
import { pinyin as getPinYinCode } from 'pinyin-pro';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Block from '@/module/Block';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import { getScrollY } from '@/utils';

import {
  AsyncSuspense,
  ButtonAndKeyDown,
  ComplexSelect,
  Determine,
} from '../../components';
import { ButtonAndKeyDownProps } from '../../components/button_and_keydown';
import { askGroupMedicalOrders } from '../../components/func/ask';
import {
  useAvoidState,
  useFetch,
  useGetResetInitState,
  useOnChangeValue,
} from '../../components/func/hooks';
import Tip from '../../components/func/Tip';
import Audit from './audit';

const HospitalSelect = (props: any) => {
  const [
    hospitalDataSource,
    setHospitalDataSource,
    ,
    ,
    getInitHospitalDataSource,
  ] = useGetResetInitState([]);

  const wardId = props?.wardId;
  useAsyncEffect(async () => {
    if (wardId) {
      const res =
        await vsf.services?.InpDrugAuditController_getWaitAuditPatientByWard_e5c3c3?.(
          {
            wardId,
          },
        );
      if (res?.code === 200) {
        setHospitalDataSource(
          res?.data?.map((item) => ({
            ...item,
            label: item?.patient?.name,
            value: item?.patient?.id,
            key: item?.patient?.id,
            gender: vsf.stores.dbenums.enums.SEX_DICT?.find(
              ({ value }) => value === item?.patient?.gender,
            )?.label,
          })),
        );
      }
    }
  }, [wardId]);

  return (
    <ComplexSelect
      mode="radio"
      placeholder="请选择病区患者"
      hideSelectIcon
      showLabel="label"
      allowClear
      value={props?.value ?? ''}
      onChange={props?.onChange}
      dropdownMatchSelectWidth={700}
      listHeight={1000}
      onSearch={(value, filterOption) => {
        if (value?.trim()) {
          setHospitalDataSource(
            filterOption(value, getInitHospitalDataSource()),
          );
        } else {
          setHospitalDataSource(getInitHospitalDataSource());
        }
      }}
    >
      <Table
        columns={[
          {
            dataIndex: ['bed', 'bedLabel'],
            title: '床号',
          },
          {
            dataIndex: ['patient', 'displayId'],
            title: '患者ID',
          },
          {
            dataIndex: ['patient', 'name'],
            title: '姓名',
          },
          {
            dataIndex: ['gender'],
            title: '性别',
          },
        ]}
        dataSource={hospitalDataSource}
        pagination={false}
        size="middle"
        scroll={{
          y: 300,
        }}
      />
    </ComplexSelect>
  );
};

const BackContent = (props) => {
  // const [value, setValue] = useAvoidState([]);
  const [rejectDataSource, setRejectDataSource] = useAvoidState<any[]>([]);
  const { loading, data: value } = useRequest(
    async () => {
      if (props?.orderIdIs) {
        const res =
          await vsf.services?.InpDrugAuditController_getAllByGetRefundAuditQto_5648ad?.(
            {
              qto: {
                orderIdIs: props?.orderIdIs,
              },
            },
          );
        if (res?.code === 200) {
          return res?.data;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      refreshDeps: [props?.orderIdIs],
    },
  );
  // console.log(value);
  useAsyncEffect(async () => {
    const res =
      await vsf.services?.InpDrugAuditController_getOrderProblemByClassCode_dc8536?.(
        {
          problemClassCode: '1',
        },
      );
    setRejectDataSource(
      res?.data?.map((item) => {
        return {
          label: item?.problemDescription,
          value: item?.id,
        };
      }),
    );
  }, []);

  return (
    <div
      className="aspirin-review-reject-main-box"
      style={{
        maxHeight: 200,
        overflow: 'auto',
      }}
    >
      <AsyncSuspense loading={loading} dataSource={Boolean(value?.length)}>
        {value?.map((item: any, index) => (
          <div key={index}>
            <div>
              <span>驳回时间: </span>
              <span>{item?.auditMaster?.auditTime ?? ''}</span>
            </div>
            <div>
              <span>驳回人: </span>
              <span>{item?.auditMaster?.auditor?.staffName ?? ''}</span>
            </div>
            <div>
              <span>问题:</span>
              <span>
                {rejectDataSource?.find(
                  ({ value }) => value === item?.auditMaster?.rejectProblemId,
                )?.label ?? ''}
              </span>
            </div>
            <div>
              <span>问题等级: </span>
              <span>
                {
                  vsf.stores.dbenums.enums.ORDER_PROBLEM_GRADE_DICT?.find(
                    ({ value }) =>
                      value === item?.auditMaster?.rejectProblemLevel,
                  )?.label
                }
              </span>
            </div>
            <div>
              <span>描述:</span>
              <span>{item?.auditMaster?.auditMemo}</span>
            </div>
          </div>
        ))}
      </AsyncSuspense>
    </div>
  );
};

const WardTable = (props) => {
  const tableRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [select] = useOnChangeValue<any>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [complex] = useOnChangeValue<any>({
    label: '',
    value: '',
  });
  const [hospitalDataSource, setHospitalDataSource] = useAvoidState([]);
  const [radio] = useOnChangeValue<
    keyof typeof DrugRationalUseStatusEnum | null
  >('NOT_AUDIT');

  const success = () => {
    (tableRef?.current as any)?.reload?.();
    if (radio?.value) {
      select?.onChange([]);
    }
  };

  const onConfirm = useFetch({
    fetch: async () => {
      const res =
        await vsf.services?.InpDrugAuditController_auditToSuccess_d620a4?.({
          orderIds: select?.value?.map((item) => item?.orderId),
        });
      return res;
    },
    message: {
      loading: '用药审核中',
      error: '用药审核失败',
      success: '用药审核成功',
    },
    success,
  });
  const onBack = () => {
    // Tip({
    //   content: '确定回退医嘱',
    //   onOk() {
    //     setOpen(true);
    //   },
    // });
    setOpen(true);
  };

  const onCancel = useFetch({
    fetch: async () => {
      const res =
        await vsf.services?.InpDrugAuditController_cancelAudit_099e55?.({
          orderIds: select?.value?.map((item) => item?.orderId),
        });
      return res;
    },
    message: {
      loading: '取消用药审核中',
      error: '取消用药审核失败',
      success: '取消用药审核成功',
    },
    success,
  });

  const onMandatoryReview = useFetch({
    fetch: async () => {
      const res =
        await vsf.services?.InpDrugAuditController_auditForceSuccess_3f847d?.({
          orderIds: select?.value?.map((item) => item?.orderId),
        });
      return res;
    },
    message: {
      loading: '强制用药审核中',
      error: '强制用药审核失败',
      success: '强制用药审核成功',
    },
    success,
  });

  const Footer = () => {
    const button: ButtonAndKeyDownProps[] = [
      {
        children: '用药审核确认',
        methodKey: 'altKey',
        ordinaryKey: 'S',
        onClick: () => onConfirm(),
        disabled: (select?.value as any[])
          ?.map((item) => item?.drugStorageRationalUseStatus)
          ?.includes('REJECT'),
      },
      {
        children: '回退医嘱',
        methodKey: 'altKey',
        ordinaryKey: 'R',
        onClick: () => onBack(),
        ghost: true,
      },
      {
        children: '取消审核',
        methodKey: 'altKey',
        ordinaryKey: 'O',
        onClick: () => onCancel(),
        ghost: true,
        danger: true,
      },
      {
        children: '强制审核通过',
        methodKey: 'altKey',
        ordinaryKey: 'T',
        onClick: () => onMandatoryReview(),
      },
    ];
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{
          justifyContent: 'flex-end',
        }}
      >
        {button?.map((item) => (
          <ButtonAndKeyDown {...item} key={item?.ordinaryKey} type="primary" />
        ))}
      </div>
    );
  };

  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip() {},
  });

  const onFetch = useCallback(
    async () => {
      const wardId = complex?.value?.wardId || props?.value?.wardId;
      if (wardId) {
        const res =
          await vsf.services?.InpDrugAuditController_getOrderToAuditByWard_ed7357?.(
            {
              query: {
                pharmacyCheckStatus: radio?.value,
                ...complex.value,
                wardId,
              },
            },
          );
        const data = group?.onSliced(
          res?.data?.flatMap((item) => {
            const orderPerformVoList = item?.orderDetail?.map(
              ({ displayId, ...item }) => ({
                ...item,
                drugOrder: item,
                amountAndUnit:
                  item?.repeatIndicator === true
                    ? null
                    : item?.prescriptionId
                    ? item?.amountAndUnit
                    : null,
                orderClass: 'DRUG',
              }),
            );
            return [
              {
                ...item,
                drugStorageRationalUseStatus: item?.wardName,
                checkDateTime: item?.bedLabel + '床',
                startPerformDateTime: item?.displayId,
                repeatIndicator: item?.name,
                prescriptionId:
                  vsf.stores.dbenums.enums.SEX_DICT?.find(
                    ({ value }) => value === item?.gender,
                  )?.label ?? '',
                orderText: item?.age ? item?.age + '岁' : '',
                orderPerformVoList,
                id: 'create' + Math?.random(),
              },
              ...orderPerformVoList,
            ];
          }),
        );
        if (radio?.value === 'NOT_AUDIT') {
          select?.onChange(data?.filter((item) => item?.performDepartmentName));
          // count.current = 2;
        }
        setDataSource(data?.map((item) => item?.dangerIndicator));
        return {
          data,
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
      return {
        data: [],
        total: [],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props?.value?.wardId,
      vsf.stores.dbenums.enums.SEX_DICT,
      radio?.value,
      complex?.value,
    ],
  );

  useEffect(() => {
    if (radio?.value !== 'NOT_AUDIT') select?.onChange([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radio?.value]);

  const render = [
    {
      label: '未审核',
      value: 'NOT_AUDIT',
      icon: <Icon type="icon-daishen" size={18} />,
    },
    {
      label: '审核通过',
      value: 'AUDIT_SUCCESS',
      icon: <Icon type="icon-chenggong" size={18} />,
    },
    {
      label: '驳回',
      value: 'REJECT',
      icon: <Icon type="icon-huitui" size={18} />,
    },
    {
      label: '审核异常',
      value: 'AUDIT_ERROR',
      icon: <Icon type="icon-huitui" />,
    },
    {
      label: '审核失败',
      value: 'AUDIT_FAIL',
      icon: <Icon type="icon-huitui" />,
    },
    {
      label: '全部',
      value: null,
      icon: <Icon type="icon-daishen" size={18} />,
    },
  ];

  return (
    <Fragment>
      <Audit
        open={open}
        onCancel={() => setOpen(false)}
        value={select?.value}
        reload={(tableRef?.current as any)?.reload}
      />
      <Block
        scroll={false}
        footer={<Footer />}
        className="review_of_inpatient_medication_orders_block"
      >
        <VSPromiseTable
          onFetch={onFetch}
          pagination={false}
          className="aspirin-table-no-background-box"
          bordered
          ref={tableRef}
          rowClassName={(node: any) =>
            !node?.performDepartmentName
              ? 'aspirin-table-row-background-box'
              : ''
          }
          rowKey="id"
          scroll={{
            y: isMinScreen ? 430 : 550,
            x: 1600,
          }}
          tableRender={(...[, table]) => {
            return (
              <div className="aspirin-review-of-inpatient-medication-orders-box">
                <div
                  className="aspirin-search-form-box"
                  style={{ width: '50%' }}
                >
                  <VSForm
                    onValuesChange={(...[, value]) => {
                      complex?.onChange?.(value);
                    }}
                  >
                    <VSFormLayout
                      columnCount={3}
                      // columnSpan={[8, 4, 4]}
                      // gutter={50}
                    >
                      <VSFormItem
                        valueType="custom"
                        name={['inHospitalId']}
                        label="患者"
                        fieldProps={{}}
                        transform={(value) => {
                          return {
                            inHospitalId: value?.id,
                            patientId: value?.patient?.id,
                            wardId: value?.wardId,
                          };
                        }}
                      >
                        <HospitalSelect wardId={props?.value?.wardId} />
                      </VSFormItem>
                      <VSFormItem
                        label="仅显示已屏蔽的医嘱"
                        valueType="check"
                        name={['supplyIndicator']}
                      />
                      <VSFormItem
                        label="仅显示被限量的医嘱"
                        valueType="check"
                        name={['limitIndicator']}
                      />
                    </VSFormLayout>
                  </VSForm>
                </div>
                <div className="top-box">
                  <div className="left-box">
                    <div>当前医嘱</div>
                    <div>
                      {dataSource?.includes(true)
                        ? `本次复核数据中存在高危药品，请核对病人姓名、药品名称、剂量、给药途径`
                        : ''}
                    </div>
                  </div>
                  <div className="right-box">
                    <RadioGroup<any> dataSource={render} {...radio} />
                  </div>
                </div>
                <div>{table}</div>
              </div>
            );
          }}
        >
          <VSTableColumn
            dataIndex={['check']}
            title={() => {
              const value = (tableRef?.current as any)
                ?.getValues?.()
                ?.filter((item) => item?.performDepartmentName);
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    onChange={(info) => {
                      if (info) {
                        select?.onChange?.(
                          group?.onSliced(
                            (tableRef?.current as any)
                              ?.getValues?.()
                              ?.filter((item) => item?.performDepartmentName),
                          ),
                        );
                      } else {
                        select?.onChange([]);
                      }
                    }}
                    indeterminate={
                      select?.value?.length > 0 &&
                      select?.value?.length < value.length
                    }
                    value={
                      select?.value?.length === value?.length &&
                      select?.value?.length !== 0 &&
                      value?.length !== 0
                    }
                  />
                </div>
              );
            }}
            valueType="custom"
            render={(...[, record]) => {
              const value = select?.value
                ?.map((item: any) => item?.id)
                .includes(record?.id);

              let _render: any = '';
              if (record?.groupName === 'aspirin-order-text-group-first') {
                _render = (
                  <Checkbox
                    value={value}
                    onChange={(info) => {
                      if (record?.groupIndex?.length) {
                        const state = (tableRef?.current as any)
                          ?.getValues?.()
                          ?.filter((...[, index]) =>
                            record?.groupIndex?.includes(index),
                          );
                        if (info) {
                          select?.onChange([...select?.value, ...state]);
                        } else {
                          select?.onChange?.(
                            select?.value.filter(
                              (item) =>
                                !state
                                  ?.map((item) => item?.id)
                                  ?.includes(item?.id),
                            ),
                          );
                        }
                      } else {
                        if (info) {
                          select?.onChange([...select?.value, record]);
                        } else {
                          select?.onChange?.(
                            select?.value.filter(
                              (item) => item?.id !== record?.id,
                            ),
                          );
                        }
                      }
                    }}
                  />
                );
              }
              if (!record?.groupName) {
                _render = (
                  <Checkbox
                    value={value}
                    onChange={(info) => {
                      if (info) {
                        select?.onChange([...select?.value, record]);
                      } else {
                        select?.onChange(
                          select?.value?.filter(
                            (item) => item?.id !== record?.id,
                          ),
                        );
                      }
                    }}
                  />
                );
              }
              const main = render?.find(
                (item) =>
                  item?.value === record?.drugStorageRationalUseStatus ?? '',
              );
              if (!main) {
                const orderPerformVoList = record?.orderPerformVoList?.filter(
                  (item) => !String(item?.id)?.startsWith('create'),
                );
                const state = orderPerformVoList?.map((item) => item?.id);
                const value = select?.value?.filter((item) =>
                  state?.includes(item?.id),
                );
                _render = (
                  <Checkbox
                    value={value?.length === state?.length}
                    indeterminate={
                      state?.length > 0 && state?.length < value?.length
                    }
                    onChange={(info) => {
                      if (info) {
                        select?.onChange(
                          group?.onSliced([
                            ...select?.value?.filter(
                              (item) => !state?.includes(item?.id),
                            ),
                            ...(orderPerformVoList ?? []),
                          ]),
                        );
                      } else {
                        select?.onChange(
                          select?.value?.filter(
                            (item) =>
                              !orderPerformVoList
                                ?.map((item) => item?.id)
                                .includes(item?.id),
                          ),
                        );
                      }
                    }}
                  />
                );
              }
              return (
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  {_render}
                </div>
              );
            }}
            width={50}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['drugStorageRationalUseStatus']}
            title="药房审核标志"
            valueType="text"
            render={(...[, record]) => {
              const main = render?.find(
                (item) =>
                  item?.value === record?.drugStorageRationalUseStatus ?? '',
              );
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {main ? (
                    <Popover
                      placement="right"
                      content={<BackContent orderIdIs={record?.orderId} />}
                      title="回退记录"
                    >
                      {main?.icon}
                    </Popover>
                  ) : (
                    record?.drugStorageRationalUseStatus
                  )}
                </div>
              );
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['id']}
            title="主键"
            valueType="digit"
            fieldProps={{}}
            hideInTable={true}
          />

          <VSTableColumn
            dataIndex={['checkDateTime']}
            title="校对时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            render={(...[, value]) => {
              return value?.checkDateTime;
            }}
            width={170}
          />

          <VSTableColumn
            dataIndex={['startPerformDateTime']}
            title="开始时间"
            valueType="date"
            render={(...[, record]) => record?.startPerformDateTime}
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            width={170}
          />

          <VSTableColumn
            dataIndex={['repeatIndicator']}
            title="长/临"
            valueType="text"
            render={(...[, record]) => {
              if (typeof record?.repeatIndicator === 'boolean') {
                return record?.repeatIndicator ? '长期' : '临时';
              }
              return record?.repeatIndicator;
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['prescriptionId']}
            title="处方号"
            valueType="text"
            render={(...[, record]) => record?.prescriptionId || ''}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['orderText']}
            title="医嘱内容"
            valueType="text"
            fieldProps={{}}
            width={500}
            render={(...[, record]) => {
              // console.log(record);
              return (
                <div
                  className={classNames(
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                  style={{
                    color:
                      typeof record?.supplyIndicator === 'boolean' &&
                      !record?.supplyIndicator
                        ? 'rgb(227, 74, 78)'
                        : 'inherit',
                  }}
                >
                  <Determine
                    condition={Boolean(
                      record?.performDepartmentName && record?.dangerIndicator,
                    )}
                  >
                    <span>
                      <Icon type="icon-gaowei" />
                    </span>
                  </Determine>
                  <Determine
                    condition={
                      ![undefined, null, 0].includes(record?.prescriptionId) &&
                      Boolean(record?.performDepartmentName)
                    }
                    fallback={record?.orderText}
                  >
                    {record?.dischargeDrugPrescription
                      ? `[出院带药]`
                      : '[处方]'}
                    {record?.orderText}
                  </Determine>
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['amountAndUnit']}
            title="数量"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['usageAndUnit']}
            title="剂量"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['administration']}
            title="途径"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
          />

          <VSTableColumn
            dataIndex={['frequency']}
            title="频次"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.PERFORM_FREQ_DICT}
            fieldProps={{}}
            render={(...[, record]) => {
              return (
                <Popover
                  content={
                    <List
                      dataSource={[
                        {
                          label: '详情',
                          value: record?.frequency?.frequencyDetail,
                        },
                        {
                          label: '总数',
                          value: record?.frequency?.frequencyCount,
                        },
                        {
                          label: '间隔',
                          value: record?.frequency?.frequencyInterval,
                        },
                        {
                          label: '间隔单位',
                          value: record?.frequency?.frequencyIntervalUnit,
                        },
                        {
                          label: '频次',
                          value: record?.frequency?.frequency,
                        },
                      ]}
                      renderItem={(item) => {
                        return (
                          <List.Item
                            style={{
                              display: 'flex',
                              gap: 10,
                            }}
                          >
                            <span>{item?.label}</span>
                            <span>{item?.value}</span>
                          </List.Item>
                        );
                      }}
                      bordered
                    />
                  }
                  title="频次详情"
                  placement="right"
                >
                  <span>{record?.frequency?.frequency}</span>
                </Popover>
              );
            }}
            editable={false}
            fieldNames={{
              value: 'frequency',
              label: 'frequency',
            }}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['drugBillingAttribute']}
            title="自备标识"
            valueType="select"
            dataSource={[
              { label: '正常', value: 'NORMAL' },
              { label: '自备药', value: 'SELF_PROVIDE' },
              { label: '不摆药', value: 'NOT_DISPENSE' },
              { label: '药柜摆药', value: 'CABINET_DISPENSE' },
              { label: '不计价', value: 'NOT_BILLING' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['checkNurseName']}
            title="校对护士"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['doctorName']}
            title="开单医生"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['performDepartmentName']}
            title="执行药房"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Block>
    </Fragment>
  );
};

export default definePage(WardTable);
