import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReceiptUseRecordController_getAllByReceiptUseRecordQto_34c13d: {
    method: 'post',
    url: '/api/finance-receipt/get-all-by-receipt-use-record-qto',
    parameterFomatter: (data?: {
      qto: ReceiptUseRecordQtoFinanceReceiptPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo[],
    ) => data,
  },
  ReceiptUseRecordController_createReceiptRecord_3a82b8: {
    method: 'post',
    url: '/api/finance-receipt/create-receipt-record',
    parameterFomatter: (data?: {
      btoParam: CreateUseRecordBtoFinanceReceiptServiceBto;
      extApiParam: {
        id: number;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo,
    ) => data,
  },
  ReceiptUseRecordController_updateReceiptRecord_db895e: {
    method: 'post',
    url: '/api/finance-receipt/update-receipt-record',
    parameterFomatter: (data?: { recordId: number; staffId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo,
    ) => data,
  },
});
