// 左侧table
export const convertGetChargeSolutionListRes2ChargeSolutionSimpleList = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.chargeSolutionCode = _.get(d, 'chargeSolutionCode');
      obj.chargeSolutionName = _.get(d, 'chargeSolutionName');
      obj.solutionMemo = _.get(d, 'solutionMemo');
      obj.reduceSequence = _.get(d, 'reduceSequence');
      return obj;
    }) ?? []),
  );
  return output;
};
// 右侧table
export const convertGetChargeSolutionItemListRes2ChargeSolutionItemListRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.chargeSolutionId = _.get(d, 'chargeSolutionId');
      obj.itemId = _.get(d, 'itemId');
      obj.itemClass = _.get(d, 'itemClass');
      obj.itemName = _.get(d, 'itemName');
      obj.itemSpecification = _.get(d, 'itemSpecification');
      obj.price = _.get(d, 'price');
      obj.calculationWay = _.get(d, 'calculationWay');
      obj.chargeRatio = _.get(d, 'chargeRatio');
      obj.specialPrice = _.get(d, 'specialPrice');
      obj.freeLimit = _.get(d, 'freeLimit');
      obj.freeNumber = _.get(d, 'freeNumber');
      obj.startDate = _.get(d, 'startDate');
      obj.stopDate = _.get(d, 'stopDate');
      obj.lastModifyStaffName = {
        label: _.get(d, 'lastModifyStaff.staffName'),
        value: _.get(d, 'lastModifyStaff.id'),
      };
      obj.lastModifyDate = _.get(d, 'lastModifyDate');
      return obj;
    }) ?? []),
  );
  return output;
};

// 保存
export const convertChargeSolutionItemListRo2SaveChargeSolutionItemListReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoList = [];
  output.btoList.push(
    ...(input?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, `id`);
      obj.chargeSolutionId = _.get(d, `chargeSolutionId`);
      obj.itemId = _.get(d, `itemId`);
      obj.chargeRatio = _.get(d, `chargeRatio`);
      obj.calculationWay = _.get(d, `calculationWay`);
      obj.freeLimit = _.get(d, `freeLimit`);
      obj.freeNumber = _.get(d, `freeNumber`);
      obj.specialPrice = _.get(d, `specialPrice`);
      obj.startDate = _.get(d, `startDate`);
      // obj.stopDate = _.get(d, `stopDate`); // TODO 没有匹配到合适字段 obj.memo = null; // String 备注
      obj.lastModifyStaffId = _.get(d, `lastModifyStaffName.value`); // Long 最后修改人
      // TODO 没有匹配到合适字段 obj.lastModifyDate = null; // Date 最后修改时间
      // TODO 没有匹配到合适字段 obj.creatorStaffId = null; // Long 创建人id
      return obj;
    }) ?? []),
  );
  console.log(output, input);
  return output;
};
