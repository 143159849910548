import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import React, { useEffect, useRef, useState } from 'react';

import Empty from '@/module/Empty';
// import { Empty } from '@vs/vsf-kit';
import Loading from '@/module/Loading';

const dataList = [];
const Index = (props) => {
  const { user, common } = props?.stores ?? {};
  const { getServerTime } = common || {};
  const { data, currentTime } = props;
  const [echartData, setEchartData] = useState<any>();
  const [isShowLoading, setIsShowLoading] = useState<boolean>(true);

  const chartRef = useRef<any>();

  const init = (data) => {
    // 呼吸respirationList
    const tempRespirationList =
      data?.respirationList?.map((item) => {
        return [
          dayjs(item?.time).format('YYYY-MM-DD HH:mm'),
          item?.vitalsignValue,
        ];
      }) ?? [];
    // 脉搏pulseList
    const tempPulseList =
      data?.pulseList?.map((item) => {
        return [
          dayjs(item?.time).format('YYYY-MM-DDTHH:mm:ss'),
          item?.vitalsignValue,
        ];
      }) ?? [];
    // 体温temperatureList
    const tempTemperatureList =
      data?.temperatureList?.map((item) => {
        return [
          dayjs(item?.time).format('YYYY-MM-DD HH:mm'),
          item?.vitalsignValue,
        ];
      }) ?? [];
    const chartDom = document.getElementById('center-chart');
    chartRef.current = echarts.init(chartDom, null, { devicePixelRatio: 2.8 });

    const option = {
      // visualMap: [
      //   {
      //     show: false,
      //     type: 'continuous',
      //     seriesIndex: 0,
      //     min: 0,
      //     max: 400,
      //   },
      // ],
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        // data: ['脉搏', '呼吸', '体温'],
        data: [
          {
            name: '呼吸',
            textStyle: {
              color: '#50BC35',
            },
            itemStyle: {
              color: '#50BC35',
            },
          },
          {
            name: '脉搏',
            // color: 'red',
            textStyle: {
              color: '#3276E8',
            },
            itemStyle: {
              color: '#3276E8',
            },
          },
          {
            name: '体温',
            // color: 'red',
            textStyle: {
              color: '#FF7E7E',
            },
            itemStyle: {
              color: '#FF7E7E',
            },
          },
        ],
      },
      grid: {
        left: '3%',
        right: '6%',
        bottom: '3%',
        top: '11%',
        containLabel: true,
      },

      textStyle: {
        fontFamily: 'DINPro',
        fontWeight: 'bold',
        color: '#343B42',
      },
      xAxis: {
        // category
        type: 'time',
        boundaryGap: false,

        axisLabel: {
          interval: 0, // 显示所有刻度
          // interval: 1, // 设置为1表示每天一个标签

          formatter: function (value) {
            // 使用 echarts 的内置函数 formatTime 来格式化日期
            return echarts.format.formatTime('MM-dd 00:00', value);
          },
        },
        splitLine: {
          show: true,
        },
        minInterval: 24 * 1000 * 3600,
      },
      yAxis: [
        {
          type: 'value',
          name: '{block|}  呼吸',
          show: true,
          min: 10,
          max: 50,
          interval: 10,
          nameTextStyle: {
            color: '#50BC35',
            padding: [0, 40, 0, 0],
            rich: {
              block: {
                backgroundColor: '#50BC35',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  脉搏',
          min: 30,
          max: 230,
          interval: 50,
          nameTextStyle: {
            color: '#3276E8',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#3276E8',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
        {
          type: 'value',
          name: '{block|}  体温',
          offset: 50,
          min: 34,
          max: 42,
          interval: 2,
          nameTextStyle: {
            color: '#FF7E7E',
            padding: [0, 0, 0, 25],
            rich: {
              block: {
                backgroundColor: '#FF7E7E',
                width: 8,
                height: 8,
                borderRadius: 4,
                align: 'center',
                fontSize: 0, // 设置为0，使得圆块不占用额外的高度
              },
            },
          },
        },
      ],
      series: [
        {
          name: '呼吸',
          type: 'line',
          data: [
            [
              dayjs(currentTime)
                .subtract(5, 'day')
                .format('YYYY-MM-DT00:00:00'),
            ],
            ...tempRespirationList,

            [dayjs(currentTime).add(1, 'day').format('YYYY-MM-DT00:00:01')],
          ],
          yAxisIndex: 0, //  使用第一个Y轴
          color: '#50BC35',
          lineStyle: {
            color: '#50BC35',
          },
        },
        {
          name: '脉搏',
          type: 'line',
          data: [
            [
              dayjs(currentTime)
                .subtract(5, 'day')
                .format('YYYY-MM-DT00:00:00'),
            ],
            ...tempPulseList,

            [dayjs(currentTime).add(1, 'day').format('YYYY-MM-DT00:00:01')],
          ],

          color: '#3276E8',
          yAxisIndex: 1, // 使用第二个Y轴
        },
        {
          name: '体温',
          type: 'line',
          data: [
            [
              dayjs(currentTime)
                .subtract(5, 'day')
                .format('YYYY-MM-DT00:00:00'),
            ],
            ...tempTemperatureList,

            [dayjs(currentTime).add(1, 'day').format('YYYY-MM-DT00:00:01')],
          ],
          yAxisIndex: 2, // 使用第三个Y轴
          color: '#FF7E7E',
        },
      ],
    };
    option && chartRef.current.setOption(option);
  };

  const resize = () => {
    if (chartRef.current) {
      chartRef.current?.resize();
    }
  };
  useEffect(() => {
    if (data == 'error') {
      setIsShowLoading(false);
      return;
    }
    init(data);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return !isShowLoading ? (
    <div
      id="center-chart"
      style={{
        width: '100%',
        height: '100%',
      }}
    ></div>
  ) : (
    <div
      id="center-chart"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Loading />
    </div>
  );
};

export default definePage(Index);
