import './index.less';
import './convert';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { positive, positiveMessage } from '@/pattern';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import { add } from '@/utils/setDataUtil';
import storage from '@/utils/storage';

import { Ellipsis } from '../../components';
import {
  convertGetInpChargeGroupMemberListRes2GetInpChargeGroupMemberListRes,
  convertQueryByInpSettleMasterWithPaymentDetailQtoPagedRes2InpSettleMasterListRo,
} from './convert';

const Index = (props) => {
  const {
    common,
    user: { staffInfo },
    parameter,
  } = props?.stores || {};
  const payWay = [
    { label: '现金', value: 'CASH' },
    { label: '支付宝', value: 'ALIPAY' },
    { label: '微信', value: 'WECHAT' },
    { label: '数字人民币', value: 'DIGITAL_RMB' },
    { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
    { label: '预交金', value: 'PRE_PAYMENT' },
    { label: '病人余款', value: 'PATIENT_SURPLUS' },
    { label: '病人欠款', value: 'PATIENT_DEBT' },
    { label: '核销', value: 'WRITE_OFF' },
    { label: '产科减免', value: 'OBSTETRIC_DISCOUNT' },
    { label: '银联卡', value: 'UNION_PAY' },
  ];

  // table
  const onFetch_t9854 = useCallback(async (params) => {
    // if (!params?.search?.settleDateBiggerThanEqual?.[0]) return [];
    const res =
      await vsf?.services?.InpSettleMasterController_queryByInpSettleMasterWithPaymentDetailQtoPaged_bcdd07?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
            // 开始
            // settleDateBiggerThanEqual: dayjs(
            //   params?.search?.settleDateBiggerThanEqual?.[0],
            // )
            //   .format('YYYY-MM-DD 00:00:00')
            //   .valueOf(),
            // // 结束
            // settleDateLessThanEqual: dayjs(
            //   params?.search?.settleDateBiggerThanEqual?.[1],
            // )
            //   .add(1, 'day')
            //   .format('YYYY-MM-DD 00:00:00')
            //   .valueOf(),

            // 开始
            settleDateBiggerThanEqual: new Date(
              dayjs(params?.search?.settleDateBiggerThanEqual?.[0]).format(
                'YYYY-MM-DD 00:00:00',
              ),
            ).valueOf(),
            // 结束
            settleDateLessThanEqual: new Date(
              dayjs(params?.search?.settleDateBiggerThanEqual?.[1])
                .add(1, 'day')
                .format('YYYY-MM-DD 00:00:00'),
            ).valueOf(),
            operatorStaffIdIs:
              params?.search?.operatorStaff?.id ??
              params?.search?.operatorStaff,
            chargeStatusIs:
              params?.search?.chargeStatus?.code ??
              params?.search?.chargeStatus,
          },
        },
      ); // TODO: 请确认入参来源及出参用法
    // console.log(res, 'res');
    const output =
      convertQueryByInpSettleMasterWithPaymentDetailQtoPagedRes2InpSettleMasterListRo?.(
        res,
      );
    // console.log(output, 'output');
    return output;
  }, []);

  return (
    <div className="inpSettleMasterList">
      <div className="inpSettleMasterList_body">
        <div className="inpSettleMasterList_body_table">
          <VSPromiseTable
            id="inpSettleMasterList"
            searchConfig={{
              // labelWidth: 120,
              labelWidth: labelNumberWidth(6),
              labelAlign: 'left',
              span: 6,
              searchGutter: 40,
            }}
            form={{}}
            onFetch={onFetch_t9854}
            scroll={{
              x: 3000,
              // y: getScrollY(510),
              y: getScrollYRem(33),
            }}
            onRow={(record) => ({
              // /index/cancel-hospitalization-settlement
              onDoubleClick: () => {
                vsf?.navigateTo(
                  `/index/cancel-hospitalization-settlement?settleNumber=${record?.settleNumber}`,
                );
              },
            })}
            className="
              aspirin-table-header-background-color
             aspirin-table-row-hover-background-color
             aspirin-table-nopadding
             aspirin-table-body-empty-transparent-background-color
             "
            // searchFormRender={(props, defaultDom, option) => {
            //   console.log(
            //     props,
            //     defaultDom,
            //     option,
            //     'props, defaultDom, option',
            //   );
            // }}
          >
            <VSTableColumn
              dataIndex={['physicalReceiptNumberIs']}
              title="票据号"
              valueType="text"
              search
              // order={0}
              columnKey={'physicalReceiptNumberIs'}
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['patientDisplayIdIs']}
              title="患者ID"
              valueType="text"
              search
              // order={0}
              columnKey={'patientDisplayIdIs'}
              hideInTable
              fieldProps={{}}
            />
            <VSTableColumn
              search
              hideInTable
              dataIndex={['ident']}
              title="证件及编号"
              valueType="certificateSearch"
              formItemProps={{
                style: {
                  // width: 865,
                },
              }}
              fieldProps={{
                selectProps: {
                  width: 290,
                },
              }}
            />

            <VSTableColumn
              dataIndex={['nameLike']}
              title="患者姓名"
              valueType="text"
              search
              // order={0}
              columnKey={'nameLike'}
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['chargeTypeCodeIs']}
              title="费别"
              // valueType="codeTableSelect"
              valueType="select"
              search
              // order={0}
              columnKey={'chargeTypeCodeIs'}
              dataSource={vsf?.stores?.dbenums?.enums?.CHARGE_TYPE_DICT}
              hideInTable
              fieldProps={{
                showSearch: true,
                // dataSource: vsf?.stores?.dbenums?.enums?.CHARGE_TYPE_DICT,
              }}
            />
            <VSTableColumn
              dataIndex={['settleDateBiggerThanEqual']}
              title="收费日期"
              valueType="dateRange"
              search
              // order={0}
              columnKey={'settleDateBiggerThanEqual'}
              hideInTable
              fieldProps={{ format: 'YYYY-MM-DD' }}
              initialValue={[+new Date(), +new Date()]}
            />

            <VSTableColumn
              dataIndex={['operatorStaff']}
              title="收款员"
              // valueType="codeTableSelect"
              valueType="select"
              search
              // order={0}
              columnKey={'operatorStaff'}
              hideInTable
              fieldNames={{
                label: 'staffName',
                value: 'id',
              }}
              dataSource={async (param) => {
                const res =
                  await vsf?.services?.UserGroupController_getInpChargeGroupMemberList_61f49d?.(
                    {},
                  ); // TODO: 请确认入参来源及出参用法
                const output =
                  convertGetInpChargeGroupMemberListRes2GetInpChargeGroupMemberListRes?.(
                    res,
                  );
                return output;
              }}
              initialValue={{
                staffName: staffInfo?.staffName,
                id: staffInfo?.doctorDto?.staffId,
              }}
              fieldProps={{
                showSearch: true,
                // initialValue: {
                //   staffName: staffInfo?.staffName,
                //   id: staffInfo?.doctorDto?.staffId,
                // },
                // dataSource: async (param) => {
                //   const res =
                //     await vsf?.services?.UserGroupController_getInpChargeGroupMemberList_61f49d?.(
                //       {},
                //     ); // TODO: 请确认入参来源及出参用法
                //   const output =
                //     convertGetInpChargeGroupMemberListRes2GetInpChargeGroupMemberListRes?.(
                //       res,
                //     );
                //   return output;
                // },
              }}
            />

            <VSTableColumn
              dataIndex={['chargeStatus']}
              title="缴费状态"
              // valueType="codeTableSelect"
              valueType="select"
              search
              // order={0}
              columnKey={'chargeStatus'}
              hideInTable
              dataSource={[
                { name: '收费', code: 'CHARGE' },
                { name: '退费', code: 'REFUND' },
                { name: '收费[作废]', code: 'CANCEL' },
                { name: '收费[已退]', code: 'CHARGE_REFUND' },
              ]}
              fieldNames={{ label: 'name', value: 'code' }}
              fieldProps={{
                // searchKey: 'name',
                showSearch: true,
                // dataSource: [
                //   { name: '收费', code: 'CHARGE' },
                //   { name: '退费', code: 'REFUND' },
                //   { name: '收费[作废]', code: 'CANCEL' },
                //   { name: '收费[已退]', code: 'CHARGE_REFUND' },
                // ],
              }}
            />
            <VSTableColumn
              dataIndex={['payTypeIs']}
              title="支付方式"
              valueType="select"
              search
              // order={0}
              columnKey={'payTypeIs'}
              hideInTable
              dataSource={payWay}
              fieldProps={{
                // dataSource: payWay,
                showSearch: true,
              }}
            />
            <VSTableColumn
              dataIndex={['settleTypeIs']}
              title="结算途径"
              // valueType="codeTableSelect"
              valueType="select"
              search
              // order={0}
              columnKey={'settleTypeIs'}
              hideInTable
              dataSource={[
                { label: '窗口', value: 'WINDOW' },
                { label: '自助机', value: 'SELF_MACHINE' },
                { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
                { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
                { label: '护士站结算', value: 'NURSE_STATION' },
                { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
                { label: '代煎收方', value: 'DECOCT' },
              ]}
              fieldProps={{
                // dataSource: [
                //   { label: '窗口', value: 'WINDOW' },
                //   { label: '自助机', value: 'SELF_MACHINE' },
                //   { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
                //   { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
                //   { label: '护士站结算', value: 'NURSE_STATION' },
                //   { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
                //   { label: '代煎收方', value: 'DECOCT' },
                // ],
                showSearch: true,
              }}
            />

            <VSTableColumn
              dataIndex={['totalCostIs']}
              title="总金额"
              valueType="digit"
              search
              // order={0}
              columnKey={'totalCostIs'}
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['totalChargeIs']}
              title="实收金额"
              valueType="digit"
              search
              // order={0}
              columnKey={'totalChargeIs'}
              hideInTable
              fieldProps={{}}
            />

            {/* <VSTableColumn
          dataIndex={['identityCodeIs']}
          title="人员类别"
          valueType="text"
          search
          order={0}
          columnKey={'identityCodeIs'}
          hideInTable
          fieldProps={{}}
        /> */}
            <VSTableColumn
              // dataIndex={['physicalReceiptNumber']}
              title="序号"
              valueType="index"
              fieldProps={{}}
              width={60}
            />
            <VSTableColumn
              dataIndex={['physicalReceiptNumber']}
              title="票据号"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 20 },
                ],
              }}
              fieldProps={{}}
              width={100}
            />

            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{}}
              width={100}
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 60 },
                ],
              }}
              fieldProps={{}}
              width={100}
            />

            <VSTableColumn
              dataIndex={['chargeTypeCode']}
              title="费别"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
              //   fieldProps={{ dataSource: vsf.stores.dbenums.enums.IDENTITY_DICT }}
              width={100}
            />

            <VSTableColumn
              dataIndex={['identityCode']}
              title="人员类别"
              valueType="select"
              width={120}
              dataSource={vsf.stores.dbenums.enums.IDENTITY_DICT}
              //   fieldProps={{ dataSource: vsf.stores.dbenums.enums.IDENTITY_DICT }}
            />

            <VSTableColumn
              dataIndex={['totalCost']}
              title="总金额"
              valueType="digit"
              fieldProps={{}}
              width={120}
              render={(_, record) => {
                return Number(record?.totalCost ?? 0).toFixed(2);
              }}
            />

            <VSTableColumn
              dataIndex={['totalCharge']}
              title="实收金额"
              valueType="digit"
              fieldProps={{}}
              width={120}
              render={(_, record) => {
                return Number(record?.totalCharge ?? 0).toFixed(2);
              }}
            />
            {/* payType */}
            <VSTableColumn
              dataIndex={['paymentDetailVoList']}
              title="付款方式"
              valueType="select"
              ellipsis
              width={120}
              //   fieldProps={{ children: <DateRangePicker /> }}
              render={(_, record) => {
                return (
                  <Ellipsis
                    content={record?.paymentDetailVoList
                      ?.map((i) => {
                        const _payWay = payWay?.find(
                          (item) => item?.value == i?.payType,
                        );
                        return `[${_payWay?.label}${Number(
                          i?.paymentAmount ?? 0,
                        ).toFixed(2)}]`;
                      })
                      .join('，')}
                  >
                    {record?.paymentDetailVoList
                      ?.map((i) => {
                        const _payWay = payWay?.find(
                          (item) => item?.value == i?.payType,
                        );
                        return `[${_payWay?.label}${Number(
                          i?.paymentAmount ?? 0,
                        ).toFixed(2)}]`;
                      })
                      .join('，')}
                  </Ellipsis>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['settleStatus']}
              title="交费状态"
              valueType="select"
              width={120}
              dataSource={[
                { label: '未收费', value: 'NOT_CHARGE' },
                { label: '已收费', value: 'FINISH_CHARGE' },
                { label: '部分退费', value: 'PARTIAL_REFUND' },
                { label: '全部退费', value: 'ALL_REFUND' },
              ]}
              fieldProps={{}}
              render={(dom, record) => {
                // console.log(record, '7457_record');
                // console.log(dom, '7457_dom');
                return (
                  <div>
                    {dom}
                    {/* {record?.refundVsInpSettleId && record?.settleStatus == 'FINISH_CHARGE' */}
                    {record?.refundVsInpSettleId !== null &&
                    record?.refundVsInpSettleId !== record?.id &&
                    record?.settleStatus === 'FINISH_CHARGE'
                      ? '[已退]'
                      : ''}
                  </div>
                );
              }}
            />

            <VSTableColumn
              dataIndex={['staffName']}
              title="收款员"
              valueType="text"
              formItemProps={{}}
              width={120}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['admissionDateTime']}
              title="入院日期"
              valueType="date"
              width={150}
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['dischargeDateTime']}
              title="出院时间"
              valueType="date"
              width={150}
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['settleDate']}
              title="收费时间"
              valueType="date"
              width={150}
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['receiptPrintStatus']}
              title="票据状态"
              valueType="select"
              width={120}
              dataSource={[
                { label: '未打印', value: 'WAIT_PRINT' },
                { label: '已打印', value: 'PRINT' },
                { label: '作废', value: 'CANCEL' },
              ]}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['printStaffName']}
              title="打印操作员"
              valueType="text"
              width={120}
              formItemProps={{
                rules: [
                  {
                    message: 'staff_name长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['refundSettleDate']}
              title="退费时间"
              valueType="date"
              width={120}
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              ellipsis
            />

            <VSTableColumn
              dataIndex={['creditCleanStatus']}
              title="结算标识 "
              valueType="select"
              width={120}
              dataSource={[
                { label: '否', value: 'NOT' },
                { label: '欠款', value: 'DEBT' },
                { label: '余款', value: 'BALANCE' },
              ]}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['settleType']}
              title="结算途径"
              valueType="select"
              width={120}
              dataSource={[
                { label: '窗口', value: 'WINDOW' },
                { label: '自助机', value: 'SELF_MACHINE' },
                { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
                { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
                { label: '护士站结算', value: 'NURSE_STATION' },
                { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
                { label: '代煎收方', value: 'DECOCT' },
              ]}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['insuranceSettleType']}
              title="医保结算类别"
              valueType="digit"
              width={120}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['middleSettleIndicator']}
              title="结算类型"
              valueType="select"
              fieldProps={{}}
              width={120}
              dataSource={[
                { label: '正常结算', value: false },
                { label: '中途结算', value: true },
              ]}
            />
          </VSPromiseTable>
        </div>
        {/* <div className="inpSettleMasterList_body_bottom"></div> */}
      </div>
    </div>
  );
};

export default definePage(Index);
// 收费方案维护
