import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryPagefallByClinicBedWithPatientForDoctorQto_d563e0: {
    method: 'post',
    url: '/api/clinic-bed/query-pagefall-by-clinic-bed-with-patient-for-doctor-qto',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientForDoctorQtoClinicBedPersistQto;
      ext: {
        statisticsCodes: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedWithPatientVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
