import React from 'react';

import { portalize } from '../Portal';

type PromiseType = (...args: any[]) => Promise<any>;

type ModalizeProps = {
  content?: React.ReactElement | any;
  onOk?: ((...args: any[]) => boolean | void) | PromiseType;
  onCancel?: (...args: any[]) => void;
  key?: string;
};

export const modalize = ({
  content = '',
  onOk,
  onCancel,
  key = 'VSF',
}: ModalizeProps) => {
  let ref: any = null;
  let dom: any = null;

  const onFinish = async (...args: any[]) => {
    const res = await onOk?.(...args);
    if (res !== false) {
      ref?.close();
    }
  };

  const onFinishCancel = (...args: any[]) => {
    onCancel?.(...args);
    ref?.close();
  };

  if (typeof content === 'string') {
    dom = <div>{content}</div>;
  } else {
    dom = React.cloneElement(content, {
      ...content?.props,
      onOk: onFinish,
      onCancel: onFinishCancel,
    });
  }

  ref = portalize({
    component: dom,
    key,
  });
  return ref;
};
