import { NewDrawer } from '@/pages/Index/components';
import { definePage } from '@vs/vsf-boot';
import { Button, VSTable, VSTableColumn } from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useEffect } from 'react';
import { getInpRefundApplyPatientCount } from './services';
const Drawer = (props) => {
  const { openDrawer, setOpenDrawer, wardInfos, setWardInfos } = props;
  const onClose = () => setOpenDrawer(false);
  const { loading, data, run } = useRequest(getInpRefundApplyPatientCount, {
    manual: true,
  });
  useEffect(() => {
    if (openDrawer) run(data);
  }, [openDrawer]);
  const onDoubleClick = (node) => {
    setWardInfos(node);
    onClose();
  };
  return (
    <NewDrawer
      open={openDrawer}
      width="20%"
      onOpen={() => setOpenDrawer(!openDrawer)}
      title={<div style={{ color: '#3276E8' }}>待退药病区</div>}
      buttonTitle="待退药病区"
      backgroundColor="var(--blue)"
      footer={
        <div className="aspirin-table-footer-button">
          <Button type="primary" onClick={run}>
            刷新
          </Button>
          <Button onClick={onClose} type="primary">
            隐藏
          </Button>
        </div>
      }
      isOther={true}
      buttonStyle={{
        top: '40%',
      }}
      drift="98px"
      style={{
        top: 104,
      }}
    >
      <VSTable
        loading={loading}
        className="common_table"
        vsid="72788"
        id="tableInpRefundApplyPatientCount"
        onRow={(node) => {
          return {
            onDoubleClick() {
              // if (node?.wardId === wardInfos?.wardId) return onDoubleClick();
              onDoubleClick(node);
            },
          };
        }}
        rowClassName={(node) => {
          return node?.wardId === wardInfos?.wardId
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box';
        }}
        value={data}
        pagination={false}
        bordered
      >
        <VSTableColumn
          dataIndex={['wardName']}
          title="病区"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'ward_name长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['patientCount']}
          title="患者人数"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: 'patient_count的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
      </VSTable>
    </NewDrawer>
  );
};
export default definePage(Drawer);
