import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitHistoryController_getPatientVisitHistory_6e55dc: {
    method: 'post',
    url: '/api/clinic-record/get-patient-visit-history',
    parameterFomatter: (data?: {
      qto: QueryOutpVisitHistoryListNewQtoClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientVisitHistoryVoClinicRecordEntranceWebVo[],
    ) => data,
  },
  VisitHistoryController_getPatientVisitHistoryDetail_d961fc: {
    method: 'post',
    url: '/api/clinic-record/get-patient-visit-history-detail',
    parameterFomatter: (data?: {
      eo: QueryPatientVisitHistoryDetailEoClinicRecordPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientVisitHistoryDetailVoClinicRecordEntranceWebVo,
    ) => data,
  },
  VisitHistoryController_getDiagnosisDictionaryByPatientId_82a0ca: {
    method: 'post',
    url: '/api/clinic-record/get-diagnosis-dictionary-by-patient-id',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientDiagnosisDictionaryVoClinicRecordEntranceWebVo[],
    ) => data,
  },
});
