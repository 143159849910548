import './index.less';

import { definePage } from '@vs/vsf-boot';
import React from 'react';

import Center from './center';
import Left from './left';
import Right from './right/index.jsx';

const Index = (props) => {
  return (
    <div className="cpoe-login">
      <div className="left">
        <Left {...props} />
      </div>
      <div className="main-box">
        <div className="center">
          <Center />
        </div>
        <div className="right">
          <Right {...props} />
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);
