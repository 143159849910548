import { Button, Drawer } from '@vs/vsf-kit';
import React, { useImperativeHandle, useRef, useState } from 'react';

import { getUUID } from '@/module/cpoe/medical/editor/utils';

import Content from './content';

const Index = (props, ref) => {
  const { onSubmit } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();

  const onClose = () => {
    setOpen(false);
  };
  const onOpen = (v) => {
    setCurrent(v ?? undefined);
    setOpen(true);
  };
  const submit = useRef<{ Submit: () => Promise<any> }>(null);
  const handleSubmit = async () => {
    const value = await submit?.current?.Submit();
    if (value) {
      onSubmit?.({
        orderStatus: 'OPEN',
        orderClass: 'PATHOLOGY',
        orderText: value.examSubClassName,
        uuid: current?.uuid ?? getUUID(),
        performDepartment: value?.performDepartment,
        pathologyOrder: {
          ...value,
          pathologyOrderDetail: value?.pathologyOrderDetail?.map(
            (item, index: number) => {
              return {
                ...item,
                examItemId: String(item?.id)?.startsWith('create')
                  ? item?.examItemId?.id ?? item?.examItemId
                  : item?.id,
                vesselNumber: index + 1,
              };
            },
          ),
        },
        orderDiagnosisIdList: value?.orderDiagnosisIdList,
      });
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    onOpen,
  }));

  return (
    <Drawer
      className="aspirin-drawer"
      title="病理医嘱"
      placement="right"
      onClose={onClose}
      open={open}
      width={'70%'}
      destroyOnClose
      footerStyle={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      footer={
        <div className="footer">
          <Button className="cancel" onClick={onClose}>
            取消
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              marginLeft: 12,
            }}
          >
            确定
          </Button>
        </div>
      }
    >
      <Content ref={submit} data={current} />
    </Drawer>
  );
};

export default React.forwardRef(Index);
