import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugController_addOrUpdateDrugNameAlias_b2a389: {
    method: 'post',
    url: '/api/drug-drug/add-or-update-drug-name-alias',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugNameAliasBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugNameAlias_548a12: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-name-alias',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugNameAliasBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_getDrugNameDictionaryListByDrugId_aa03ab: {
    method: 'post',
    url: '/api/drug-drug/get-drug-name-dictionary-list-by-durg-id',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugNameDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
});
