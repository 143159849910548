import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeTemplateController_saveMedicalTechnologyChargeTemplate_792178:
    {
      method: 'post',
      url: '/api/medical-technology/save-medical-technology-charge-template',
      parameterFomatter: (data?: {
        chargeTemplateBto: CreateOrUpdateMedicalTechnologyChargeTemplateBtoMedicalTechnologyServiceBto;
      }) => data,
      responseFormatter: (_, __, data?: number) => data,
    },
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateWithDetailList_e192d7:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-charge-template-with-detail-list',
      parameterFomatter: (data?: { templateId: number; itemName: string }) =>
        data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeTemplateWithDetailVoMedicalTechnologyEntranceWebVo,
      ) => data,
    },
});
