import { definePage } from '@vs/vsf-boot';
import vsf from '@vs/vsf-boot';
import {
  Button,
  Form,
  message,
  Select,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { Fragment, useCallback } from 'react';

import Block from '@/module/Block';
import Container from '@/module/Container';
import Loading from '@/module/Loading';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';

import { ButtonAndKeyDown, ComplexSelect } from '../../components';
import { useAvoidState, useFetch } from '../../components/func/hooks';
import { onExport } from './util';

const DrugSelect = (props) => {
  const { value, onChange, form } = props;
  // const [dataSource, setDataSource] = useAvoidState([]);
  const convertGetDrugProductByNameOrInputCodeRes2DrugInfo = (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.drugProductName = _.get(d, 'drugProductName');
        obj.drugProductCode = _.get(d, 'drugProductCode');
        obj.drugSpecification = _.get(d, 'drugSpecification');
        return obj;
      }) ?? []),
    );
    return output;
  };
  const {
    loading,
    data: dataSource,
    run: getDataSource,
  } = useRequest(
    async (input) => {
      const res =
        await vsf?.services?.DrugController_getDrugProductByNameOrInputCode_1b2090?.(
          {
            input,
          },
        );
      return convertGetDrugProductByNameOrInputCodeRes2DrugInfo?.(res)?.map(
        (item) => ({
          key: item?.id,
          ...item,
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      manual: true,
    },
  );

  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="drugProductName"
      placeholder="请选择药品信息"
      value={value}
      onChange={(value) => {
        onChange(
          value?.key
            ? {
                label: value.drugProductName,
                value: value.key,
              }
            : value,
        );
      }}
      onSearch={(inputCode) => {
        if (inputCode?.trim()) {
          getDataSource(inputCode);
        }
      }}
      style={{
        minWidth: 200,
        width: '100%',
      }}
      allowClear
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: 700,
      }}
      listHeight={1000}
      width="100%"
    >
      <Table
        size="middle"
        columns={[
          {
            dataIndex: ['drugProductName'],
            title: '商品名称',
          },
          {
            dataIndex: ['drugProductCode'],
            title: '商品编码',
          },
          {
            dataIndex: ['drugSpecification'],
            title: '商品规格',
          },
          // {
          //   dataIndex: ['drugProductName'],
          //   title: '商品名称',
          // },
          // {
          //   dataIndex: ['drugProductName'],
          //   title: '商品名称',
          // },
        ]}
        dataSource={dataSource}
        scroll={{
          y: 200,
        }}
        loading={{
          indicator: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Loading />
            </div>
          ),
          spinning: loading,
        }}
      />
    </ComplexSelect>
  );
};

const ExecutionRegistrationOfSpecialDrugsForRefinedAndToxicSubstances = (
  props,
) => {
  const {
    stores: {
      common: { currentApplication },
    },
  } = props;
  const execute = (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.patientId = _.get(d, 'patient.id');
        obj.orderId = _.get(d, 'order.id');
        obj.orderPerformPlanRecordId = _.get(d, 'id');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.id = _.get(d, 'specialDrugPerformRecordDto.id');
        obj.prescriptionId = _.get(d, 'orderDrug.prescriptionId');
        obj.performDateTime = _.get(d, 'performDateTime');
        obj.name = _.get(d, 'patient.name');
        obj.bedLabel = _.get(d, `patient.clinicPatientBedDtoList.[0].bedLabel`);
        obj.bedId = _.get(d, `patient.clinicPatientBedDtoList.[0].bedId`);
        obj.diseaseOperationName = _.get(
          d,
          'specialDrugPerformRecordDto.diseaseOperationName',
        );
        const packageSpecification = _.get(d, 'orderDrug.packageSpecification')
          ? `${_.get(d, 'orderDrug.packageSpecification')}`
          : '';
        obj.drugProductName =
          (_.get(d, 'orderDrug.drugProductName') ?? '') + packageSpecification;
        obj.packageSpecification = _.get(d, 'orderDrug.packageSpecification');
        obj.packageUnit = _.get(d, 'orderDrug.packageUnit');
        obj.dosage =
          (_.get(d, 'orderDrug.dosage') + _.get(d, 'orderDrug.dosageUnit') ??
            '') +
          `(${_.get(d, 'orderDrug.usage')}${_.get(d, 'orderDrug.usageUnit')})`;
        obj.dosageUnit = _.get(d, 'orderDrug.dosageUnit');
        obj.administration = _.get(d, 'orderDrug.administration');
        obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
        obj.showAmount = _.get(d, 'showAmount');
        obj.usageUnit = _.get(d, 'orderDrug.usageUnit');
        obj.batchNumber = _.get(d, 'specialDrugPerformRecordDto.batchNumber');
        obj.doctorName = _.get(d, 'order.doctor.staffName');
        obj.selfProvideDrugMemo = _.get(
          d,
          'specialDrugPerformRecordDto.selfProvideDrugMemo',
        );
        obj.performName = _.get(d, 'performNurse.staffName');
        obj.checkName = _.get(d, 'checkNurse.staffName');
        obj.surplusLiquidHandle = _.get(
          d,
          'specialDrugPerformRecordDto.surplusLiquidHandle',
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
  const departmentId = currentApplication?.department?.id;
  const [form] = Form?.useForm();
  const onFetch = useCallback(async () => {
    if (departmentId) {
      const {
        startTime: [startTime, endTime],
        displayId,
        drugProductId: drug,
        UNEXECUTED,
        EXECUTED,
        administration,
      } = form?.getFieldsValue() || {};
      const { value: drugProductId } = drug || {};
      const res =
        await vsf?.services?.ClinicRecordOrderPerformController_getJMSpecialDrugExecutedRecord_49abeb?.(
          {
            eo: {
              departmentId,
              startTime,
              endTime,
              displayId,
              ...(drugProductId
                ? {
                    drugProductId,
                  }
                : {}),
              isUnexecuted: UNEXECUTED,
              isExecuted: EXECUTED,
              ...(administration
                ? {
                    administration,
                  }
                : {}),
            },
          },
        );
      const output = execute?.(res);
      if (!res?.data?.length) {
        message.info('无精麻执行计划信息');
        return {
          data: [],
          total: 0,
        };
      }
      return {
        data: output?.map((item) => {
          return {
            ...item,
            id: 'create' + Math.random(),
          };
        }),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return {
        data: [],
        total: 0,
      };
    }
  }, [departmentId, form]);

  const getOperation = useCallback(async (id) => {
    const res =
      await vsf?.services?.ClinicRecordOrderPerformController_getMainDisagnosisAndOperation_d8e46c?.(
        {
          id,
        },
      );
    return res?.data ?? [];
  }, []);

  const onSave = useFetch({
    fetch: async () => {
      await vsf?.refs?.specialDrugExecute
        ?.getEditForm?.()
        ?.validateFieldsReturnFormatValue();
      const voList = vsf?.refs?.specialDrugExecute?.getValues();
      const poList: any[] = Object?.values(
        vsf?.refs?.specialDrugExecute?.getEditForm?.()?.getFieldsValue(true) ||
          {},
      );
      const eoList: any[] = [];

      for (const i in voList) {
        eoList.push({
          ...(poList[i] ?? {}),
          ...(voList[i] ?? {}),
        });
      }
      const res =
        await vsf?.services?.ClinicRecordOrderPerformController_saveJMSpecialDrugExecutedRecord_ebc023?.(
          {
            eoList: eoList?.map(({ id, showAmount, ...item }) => ({
              amount: showAmount,
              ...item,
            })),
          },
        );
      return res;
    },
    message: '保存',
    success() {
      vsf?.refs?.specialDrugExecute?.reload();
    },
  });

  const PlanRecord = useCallback(async (id) => {
    const res =
      await vsf?.services?.ClinicRecordOrderPerformController_getDrugBatchByPerformPlanRecordId_3888cb?.(
        {
          id,
        },
      );
    return (
      res?.data?.map((item) => ({
        value: item?.batchNumber,
        label: item?.batchNumber,
      })) ?? []
    );
  }, []);

  const onExportLoadDown = useFetch({
    fetch: async () => {
      const eoList = await vsf?.refs?.specialDrugExecute?.getValues();
      try {
        onExport({
          title: '精毒特殊药品执行登记',
          columns: [
            {
              header: '处方号',
              key: 'prescriptionId',
              width: 20,
            },
            {
              header: '执行时间',
              key: 'performDateTime',
              width: 70,
            },
            {
              header: '患者姓名',
              key: 'name',
              width: 70,
            },
            {
              header: '床号',
              key: 'bedLabel',
              width: 70,
            },
            {
              header: '疾病（手术）名称',
              key: 'diseaseOperationName',
              width: 70,
            },
            {
              header: '药品名称及规格',
              key: 'drugProductName',
              width: 70,
            },
            {
              header: '包装规格',
              key: 'packageSpecification',
              width: 70,
            },
            {
              header: '包装单位',
              key: 'packageUnit',
              width: 70,
            },
            {
              header: '用量',
              key: 'dosage',
              width: 70,
            },
            {
              header: '用量单位',
              key: 'dosageUnit',
              width: 70,
            },
            {
              header: '给药途径',
              key: 'administration',
              width: 70,
            },
            {
              header: '执行频率',
              key: 'frequency',
              width: 70,
            },
            {
              header: '数量',
              key: 'showAmount',
              width: 70,
            },
            {
              header: '药品一次使用单位',
              key: 'usageUnit',
              width: 70,
            },
            {
              header: '批号',
              key: 'batchNumber',
              width: 70,
            },
            {
              header: '处方医师',
              key: 'doctorName',
              width: 70,
            },
            {
              header: '备注',
              key: 'selfProvideDrugMemo',
              width: 70,
            },
            {
              header: '执行人',
              key: 'performName',
              width: 70,
            },
            {
              header: '核对人',
              key: 'checkName',
              width: 70,
            },
          ],
          value: eoList,
        });
        return {
          code: 200,
        };
      } catch {
        return {
          code: 500,
        };
      }
    },
    message: {
      loading: '正在导出中',
      error: '导出失败',
      success: '导出成功',
    },
  });

  const Footer = () => {
    return (
      <div
        className="aspirin-table-footer-button-box"
        style={{ background: 'var(--background)' }}
      >
        <Button type="primary" onClick={onSave}>
          保存
        </Button>
        <Button type="primary" onClick={onExportLoadDown}>
          导出
        </Button>
      </div>
    );
  };

  return (
    <Container
      layout={{
        items: [
          // {
          //   bgColor: 'var(--background)',
          // },
        ],
      }}
    >
      <Block
        scroll={false}
        style={{
          padding: 0,
        }}
        footer={<Footer />}
      >
        <VSPromiseTable
          id="specialDrugExecute"
          onFetch={onFetch}
          className="aspirin-table-no-background-box"
          pagination={false}
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
          }}
          tableRender={(...[, table]) => {
            return (
              <Fragment>
                <div
                  style={{
                    padding: 24,
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth: '70vw',
                  }}
                  className="aspirin-table-search-form-box"
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <VSForm form={form}>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          gap: 20,
                        }}
                      >
                        <VSFormItem
                          label="计划执行时间"
                          valueType="aspirinDateTimePicker"
                          name={'startTime'}
                          initialValue={[
                            dayjs()?.format('YYYY-MM-DD'),
                            dayjs()?.format('YYYY-MM-DD'),
                          ]}
                          fieldProps={{
                            format: 'YYYY-MM-DD',
                            valueType: 'date',
                            type: 'range',
                            width: '100%',
                            // dateRangePickerProps: {
                            //   disabledDate: (current) =>
                            //     (current && current > moment().endOf('day')) ||
                            //     current <
                            //       moment().subtract(1, 'days').startOf('day'),
                            // },
                          }}
                        />
                        <VSFormItem
                          label="患者ID"
                          valueType="digit"
                          name={'displayId'}
                          fieldProps={{
                            width: '100%',
                          }}
                        />

                        <VSFormItem
                          label="药品"
                          valueType="custom"
                          name={'drugProductId'}
                        >
                          <DrugSelect />
                        </VSFormItem>

                        <VSFormItem
                          label="给药途径"
                          valueType="selectCode"
                          name={'administration'}
                          dataSource={
                            vsf.stores.dbenums.enums.ADMINISTRATION_DICT
                          }
                          fieldProps={{
                            width: '100%',
                            style: {
                              minWidth: 120,
                            },
                            allowClear: true,
                            placeholder: '请选择给药途径',
                          }}
                        />

                        <VSFormItem
                          name={['EXECUTED']}
                          label="已执行"
                          valueType="check"
                          initialValue={true}
                          fieldProps={{}}
                        />
                        <VSFormItem
                          name={['UNEXECUTED']}
                          label="未执行"
                          valueType="check"
                          initialValue={true}
                          fieldProps={{}}
                        />
                      </div>
                    </VSForm>
                  </div>
                  <ButtonAndKeyDown
                    methodKey="altKey"
                    ordinaryKey="Q"
                    onClick={() => {
                      vsf?.refs?.specialDrugExecute?.reload();
                    }}
                    type="primary"
                  >
                    查询
                  </ButtonAndKeyDown>
                </div>
                <div
                  className="aspirin-table-inter-box"
                  style={{
                    padding: 24,
                  }}
                >
                  <div>{table}</div>
                </div>
              </Fragment>
            );
          }}
          scroll={{
            // y: getScrollY(400),
            y: getScrollYRem(30),
          }}
        >
          {/* <VSTableColumn
            dataIndex={['id']}
            title="主键"
            valueType="digit"
            fieldProps={{}}
            preview
          /> */}

          <VSTableColumn
            dataIndex={['prescriptionId']}
            title="处方号"
            valueType="digit"
            fieldProps={{}}
            // render={(...[, record]) => record?.prescriptionId ?? ''}
            preview
            width={labelNumberWidth(8)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['performDateTime']}
            title="执行时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            preview
            ellipsis
          />

          <VSTableColumn
            dataIndex={['name']}
            title="患者姓名"
            valueType="text"
            preview
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['bedLabel']}
            title="床号"
            valueType="text"
            preview
            fieldProps={{}}
            width={labelNumberWidth(4)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['diseaseOperationName']}
            title="疾病（手术）名称"
            valueType="select"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              ],
            }}
            renderFormItem={(...[, record]) => {
              return (
                <Select
                  dataSource={() => getOperation(record?.record?.inpVisitId)}
                />
              );
            }}
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['drugProductName']}
            title="药品名称及规格"
            valueType="text"
            preview
            // render={(...[, record]) => {
            //   return (
            //     record?.drugName +
            //     (record?.packageSpecification !== 'null'
            //       ? record?.packageSpecification
            //       : '')
            //   );
            // }}
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis
          />

          {/* <VSTableColumn
            dataIndex={['packageSpecification']}
            title="包装规格"
            valueType="text"
            preview
            fieldProps={{}}
          /> */}

          {/* <VSTableColumn
            dataIndex={['packageUnit']}
            title="包装单位"
            valueType="text"
            preview
            fieldProps={{}}
          /> */}

          <VSTableColumn
            dataIndex={['dosage']}
            title="用量"
            valueType="digit"
            preview
            fieldProps={{}}
            ellipsis
          />

          {/* <VSTableColumn
            dataIndex={['dosageUnit']}
            title="用量单位"
            valueType="text"
            preview
            fieldProps={{}}
          /> */}

          <VSTableColumn
            dataIndex={['administration']}
            title="给药途径"
            valueType="select"
            preview
            dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
            width={labelNumberWidth(6)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['frequency']}
            title="频次"
            preview
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
            width={labelNumberWidth(4)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['showAmount']}
            title="数量"
            valueType="digit"
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis
          />
          {/* 
          <VSTableColumn
            dataIndex={['usageUnit']}
            title="药品一次使用单位"
            valueType="text"
            preview
            fieldProps={{}}
          /> */}

          <VSTableColumn
            dataIndex={['batchNumber']}
            title="批号"
            valueType="select"
            renderFormItem={(...[, record]) => {
              return (
                <Select
                  dataSource={() =>
                    PlanRecord(record?.record?.orderPerformPlanRecordId)
                  }
                />
              );
            }}
            fieldProps={{}}
            width={labelNumberWidth(7)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['doctorName']}
            title="处方医师"
            valueType="text"
            preview
            fieldProps={{}}
            width={labelNumberWidth(7)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['selfProvideDrugMemo']}
            title="备注"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis
          />
          <VSTableColumn
            dataIndex={['surplusLiquidHandle']}
            title="残余液量及处置"
            valueType="text"
            fieldProps={{}}
            width={labelNumberWidth(10)}
            ellipsis
          />
          <VSTableColumn
            dataIndex={['performName']}
            title="执行人"
            valueType="text"
            preview
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis
          />

          <VSTableColumn
            dataIndex={['checkName']}
            title="核对人"
            valueType="text"
            preview
            fieldProps={{}}
            width={labelNumberWidth(5)}
            ellipsis
          />
        </VSPromiseTable>
      </Block>
    </Container>
  );
};

export default definePage(
  ExecutionRegistrationOfSpecialDrugsForRefinedAndToxicSubstances,
);
