import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockController_queryDrugs_529e82: {
    method: 'post',
    url: '/api/drug-storage/query-drugs',
    parameterFomatter: (data?: {
      qry: DrugProductHaveStockAndPriceQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSplitVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_queryDrugStockSupply_25f6e9: {
    method: 'post',
    url: '/api/drug-storage/query-drug-stock-supply',
    parameterFomatter: (data?: {
      qto: QueryDrugStockSupplyQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSupplyVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_updateDrugStockSupply_255a7d: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stock-supply',
    parameterFomatter: (data?: {
      stock: DrugStockSupplyVoDrugStorageEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStockSupplyController_updateDrugStockLimit_0ab0d8: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stock-limit',
    parameterFomatter: (data?: {
      stock: DrugStockSupplyVoDrugStorageEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStockSupplyController_getPagedByDrugSupplyLogQto_c72272: {
    method: 'post',
    url: '/api/drug-storage/get-paged-by-drug-supply-log-qto',
    parameterFomatter: (data?: {
      qto: DrugSupplyLogQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugSupplyLogVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_queryPagedByDrugProductWhitelistLogStaffPatientQto_e326d2:
    {
      method: 'post',
      url: '/api/drug-storage/query-paged-by-drug-product-whitelist-log-staff-patient-qto',
      parameterFomatter: (data?: {
        qto: DrugProductWhitelistLogStaffPatientQtoDrugStoragePersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: DrugProductWhitelistLogStaffPatientVoDrugStorageEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
});
