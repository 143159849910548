import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Dropdown, Input, message, Popover } from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import Ellipsis from '@/pages/Index/components/ellipsis';
import Bed from '@/pages/Index/manage/doctor_station_bed_card';
import storage from '@/utils/storage';

// import Bed from './inpatient_bed_card';
// import List from './lists';
import List from './lists';

let inputString = '';
const Index = (props) => {
  const { stores } = props;
  const {
    user: {
      getOutPatientNumber,
      patientNumber,
      patientList,
      hospitalType,
      outVisitInfo,
      inVisitInfo,
      outRegisterInfo,
    },
    common: { currentApplication },
  } = stores || {};

  const [inputValue, setInputValue] = useState<any>();
  const [inputChange, setInputChange] = useState<number>(1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [enter, setEnterType] = useState(false);
  const [count, setCount] = useState();
  const [aa, setAa] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      notDiagnosisPatient();
      // getOutPatientNumber();
    }, 1000);
    handelClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen]);

  // 查询未诊数量
  const notDiagnosisPatient = async () => {
    const res =
      await vsf.services?.OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538(
        {
          qto: {
            clinicRegisterDetailIdIn: stores?.cpoe?.signSelection.map(
              (item) => item?.clinicRegisterDetailId,
            ),
            size: 5,
          },
        },
      );
    setCount(res?.data?.count ?? 0);
    // return res?.data?.count ?? '';
    return 0;
  };

  // 选择门诊患者
  const onSelect = (v, type) => {
    vsf?.stores?.user?.setCurrentPatient({
      id: v?.id,
      patientId: v?.patient?.id,
      displayId: v?.patient?.displayId,
    });
    if (type == 'NOT_TREAT') {
      // 存挂号信息
      vsf?.stores?.user?.setOutRegisterInfo(v);
      vsf?.stores?.user?.setOutVisitInfo(null);
    } else {
      // 接诊信息
      vsf?.stores?.user?.setOutVisitInfo(v);
      // 存挂号信息
      vsf?.stores?.user?.setOutRegisterInfo(v?.outpVisit);
    }
    setDropdownOpen(false);
  };
  // 双击住院床卡
  const onSelectBed = (v) => {
    // console.log('双击住院床卡');
    vsf?.stores?.user?.setInVisitInfo(v);
    vsf?.stores?.user?.setCurrentPatient({
      id: v?.id,
      displayId: v?.displayId,
      patientId: v?.patientId,
    });
    setDropdownOpen(false);
  };
  const handelSetEnter = (v) => {
    setEnterType(v);
  };
  const onDropdownOpen = (v = false) => {
    // setDropdownOpen(false);
    setDropdownOpen(v);
  };

  // 切换tab清空inputValue inputString
  const handeClearInput = () => {
    setInputValue(null);
    setAa(null);
  };
  // 切换状态tab清空inputValue
  const handelClear = () => {
    setInputValue(null);
  };

  useEffect(() => {
    const _params = hospitalType === 'out' ? outRegisterInfo : inVisitInfo;
    if (_params?.id) {
      setDropdownOpen(false);
    } else {
      setTimeout(() => {
        setDropdownOpen(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalType, currentApplication, inVisitInfo, outVisitInfo]);
  // 急诊先用门诊的
  return (
    <div
      className={classnames({
        oper: hospitalType === 'out' || hospitalType === 'er',
        'oper-in': hospitalType === 'in',
      })}
    >
      {hospitalType === 'out' || hospitalType === 'er' ? (
        <div className="first">
          <Button
            disabled={!outVisitInfo?.id}
            icon={<Icon type="icon-zhongjiao" className="icon20" />}
          >
            重叫
          </Button>
          <Button
            disabled={!outVisitInfo?.id}
            icon={<Icon type="icon-guohao" className="icon20" />}
          >
            过号
          </Button>
          <Button
            disabled={!outVisitInfo?.id}
            icon={<Icon type="icon-xiayige" className="icon20" />}
          >
            下一个
          </Button>
          <Button disabled={!outVisitInfo?.id}>结束就诊</Button>
        </div>
      ) : (
        <div className="first">
          <Button>
            <Ellipsis content={inVisitInfo?.wardName}>
              <span>{inVisitInfo?.wardName ?? '暂无'}</span>
            </Ellipsis>
          </Button>
          <Button type="primary">{inVisitInfo?.bedLabel}号</Button>
        </div>
      )}

      {hospitalType === 'out' || hospitalType === 'er' ? (
        <Dropdown
          autoAdjustOverflow={false}
          menu={{
            items: [],
          }}
          // destroyPopupOnHide={true}
          onOpenChange={(v) => {
            // if (outVisitInfo?.id) {
            //   setDropdownOpen(v);
            // } else {
            //   message.info('请先选择患者后再进行操作');
            //   setDropdownOpen(true);
            // }
            setDropdownOpen(v);
          }}
          dropdownRender={(menu) => (
            <List
              handeClearInput={handeClearInput}
              handelClear={handelClear}
              inputValue={inputValue}
              inputChange={inputChange}
              data={patientList}
              onSelect={onSelect}
              onDropdownOpen={onDropdownOpen}
              // enterType={enter}
              handelSetEnter={handelSetEnter}
              dropdownOpen={dropdownOpen}
              notDiagnosisPatient={notDiagnosisPatient}
            />
          )}
          open={hospitalType === 'er' ? false : dropdownOpen}
          trigger={['click']}
        >
          <div
            onClick={(e) => {
              // e.stopPropagation();
            }}
            className="second"
            style={{ padding: 2 }}
          >
            <div
              className="left"
              onClick={(e) => {
                // e.stopPropagation();
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <Icon type="icon-xia" className="icon20" />
              <Icon type="icon-a-Useryonghu" className="icon18" />
              <span className="label icon16">候诊</span>
              <span className="value icon16">{count}</span>
            </div>
            <div
              onClick={(e) => {
                // e.stopPropagation();
                setDropdownOpen(true);
              }}
              className="input"
            >
              <Input
                placeholder="请输入患者ID或姓名"
                onChange={(v) => {
                  inputString = v?.target?.value;
                  setAa(v?.target?.value);
                  if (v?.target?.value?.length === 0) {
                    setInputValue(undefined);
                    setInputChange(inputChange + 1);
                  }
                }}
                value={aa}
                onKeyUp={(e) => {
                  if (e.code === 'Enter') {
                    setInputChange(inputChange + 1);
                    setTimeout(() => {
                      setInputValue(inputString);
                      setDropdownOpen(true);
                      setEnterType(true);
                    }, 200);
                  }
                }}
              />
            </div>
            <div
              onClick={(e) => {
                // e.stopPropagation();
              }}
              className="scan"
            >
              <Icon type="icon-a-Scan-codesaoma" className="icon24" />
            </div>
            <div
              onClick={(e) => {
                // e.stopPropagation();
              }}
              className="list"
            >
              <Icon type="icon-a-List-viewliebiaoshitu" className="icon24" />
            </div>
          </div>
        </Dropdown>
      ) : (
        <Dropdown
          // arrow
          menu={{
            items: [],
          }}
          onOpenChange={(v) => {
            // if (outVisitInfo?.id) {
            //   setDropdownOpen(v);
            // } else {
            //   message.info('请先选择患者后再进行操作');
            //   setDropdownOpen(true);
            // }
            setDropdownOpen(v);
          }}
          destroyPopupOnHide
          overlayClassName="in_hospitalType_dropdown"
          autoAdjustOverflow={false}
          dropdownRender={(menu) => (
            <Bed dropdownOpen={dropdownOpen} onSelect={onSelectBed} />
          )}
          open={dropdownOpen}
          trigger={['click']}
        >
          <div className="second-in">
            <Button
              type="primary"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon type="icon-Frame-32" className="icon30" />
              <span>切换床卡</span>
              <Icon type="icon-xia" />
            </Button>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export default definePage(Index);
