// table
export const convertQueryErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchQtoRes2ErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.patientId = _.get(d, 'patient.id');
        obj.name = _.get(d, 'patient.name');
        obj.gender = _.get(d, 'patient.gender');
        obj.itemValue23 = _.get(d, 'itemValue23');
        obj.referenceDiseaseLevel = _.get(
          d,
          'preExamRecord.referenceDiseaseLevel',
        );
        obj.erpId = _.get(d, 'patient.displayId');
        obj.diseaseLevel = _.get(d, 'preExamRecord.diseaseLevel');
        obj.treatAreaCode = _.get(d, 'preExamRecord.treatAreaCode');
        obj.clinicRegister = _.get(d, 'preExamRecord.erpVisit.clinicRegister');
        obj.patientChiefComplaintVsJudgeResult = _.get(
          d,
          'patientChiefComplaintVsJudgeResult',
        );

        obj.patientChiefComplaintVsJudgeDictionaryDtoList =
          _.get(
            d,
            'preExamRecord.patientChiefComplaintVsJudgeDictionaryVoList',
          ) ?? [];
        obj.outpVisit = _.get(d, 'preExamRecord.erpVisit.outpVisit');
        obj.preExamRecord = _.get(d, 'preExamRecord');
        obj.vitalsignAssessmentIndicator = _.get(
          d,
          'vitalsignAssessmentIndicator',
        );
        return obj;
      }) ?? []),
    );
    // console.log(output);
    return output;
  };
// table单行保存患者
export const convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2SaveSingleErpAccidentEventRecordFuncFlowExecutorReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.createOrUpdatePatientBto = {}; // TODO 没有匹配到合适字段 output.createOrUpdatePatientBto.id = null; // Long 主键
    // output.createOrUpdatePatientBto.displayId = _.get(input, 'displayId');
    output.createOrUpdatePatientBto.name = _.get(input, 'name');
    output.createOrUpdatePatientBto.gender = _.get(input, 'gender');
    output.createOrUpdatePatientBto.id = _.get(input, 'patientId');
    output.createOrUpdatePatientBto.birthAddress = {};
    output.createOrUpdatePatientBto.patientExpressAddressBtoList = [];
    output.createOrUpdatePatientBto.patientRelationshipBtoList = [];
    output.createOrUpdatePatientBto.patientIdentificationBtoList = [];
    output.createOrUpdatePatientBto.patientProfileBto = {};
    output.createOrUpdatePatientBto.patientProfileBto.nativeAddress = {};
    output.createOrUpdatePatientBto.patientProfileBto.currentAddress = {};
    output.createOrUpdatePatientBto.patientProfileBto.registeredResidenceAddress =
      {};
    output.createOrUpdatePatientBto.patientProfileBto.workUnit = {};
    output.createOrUpdatePatientBto.patientProfileBto.workUnit.address = {};
    output.createOrUpdatePatientBto.patientProfileBto.contact = {};
    output.createOrUpdatePatientBto.patientProfileBto.contact.address = {};
    output.createOrUpdatePatientBto.label = {};
    output.saveErpVisitForPreExamBto = {}; // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.id = null; // Long 主键
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.patientId = null; // Long 患者ID
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.arriveHospitalDateTime = null; // Date 到院时间
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.arriveHospitalType = null; // Dbenum<ARRI_PATTERN_DICT> 到院方式代码
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.arriveHospitalPatternMemo = null; // String 到院方式备注
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.departmentClinicType = null; // Dbenum<DEPT_CLINIC_TYPE_DICT> 分科代码
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.treatAreaCode = null; // Dbenum<TREAT_AREA_DICT> 诊疗区域代码
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.treatAreaSortCode = null; // Dbenum<TREAT_AREA_SORT_DICT> 急诊分区代码
    output.saveErpVisitForPreExamBto.clinicRegisterId = _.get(
      input,
      'clinicRegister.value',
    );
    // 待确认，先不传

    output.saveErpVisitForPreExamBto.clinicRegisterDetailId = _.get(
      input,
      'clinicRegister.id',
    );
    output.saveErpVisitForPreExamBto.treatAreaCode = _.get(
      input,
      'treatAreaCode',
    );
    output.saveErpVisitForPreExamBto.treatAreaSortCode = _.get(
      input,
      'treatAreaSortCode',
    );
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.clinicStatus = null; // ErpClinicStatusEnum 就诊状态
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.nurseAcceptDateTime = null; // Date 护士接收日期及时间
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.patientType = null; // Dbenum<EMERGENCY_VISIT_PATIENT_TYPE> 对应患者类别
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.ohcaIndicator = null; // Boolean 院外心博停止
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.visitDepartmentId = null; // Long 就诊科室代码
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.notPlanReturnRescueIndicator = null; // Boolean 是否72小时内非计划重返抢救室
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.ambulanceTypeCode = null; // Dbenum<DRIVE_TYPE_DICT> 120类型
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.adtStatus = null; // ErpAdtStatusEnum 在院流转状态
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.vitalsignStatus = null; // VitalsignStatusEnum 体征状态
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.diseaseLevelId = null; // Long 病情分级ID
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.preExamPatientType = null; // PreExamPatientTypeEnum 预检患者类别
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.payAfterClinicReason = null; // Dbenum<ERP_PAY_AFTER_VISIT_REASON> 医后付理由
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.visitDateTime = null; // Date 就诊日期
    // TODO 没有匹配到合适字段 output.saveErpVisitForPreExamBto.outpVisitId = null; // Long 门诊就诊记录ID
    output.createErpPreExamRecordBto = {}; // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.id = null; // Long 主键
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.erpVisitId = null; // Long 急诊就诊ID
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.patientId = null; // Long 患者ID
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.disciplineCode = null; // Dbenum<DEPT_CLINIC_TYPE_DICT> 分科代码
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.treatAreaSortCode = null; // Dbenum<TREAT_AREA_SORT_DICT> 急诊分区代码
    output.createErpPreExamRecordBto.treatAreaCode = _.get(
      input,
      'treatAreaCode',
    );
    output.saveErpVisitForPreExamBto.treatAreaSortCode = _.get(
      input,
      'treatAreaSortCode',
    );
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.assessmentDateTime = null; // Date 评估日期及时间
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.assessmentStaffId = null; // Long 评估人员编码
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.gcsScore = null; // Long 格拉斯哥评分得分
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.gcsLevel = null; // String 格拉斯哥评分等级
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.rtsScore = null; // Long 患者外伤评分得分
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.rtsLevel = null; // String 患者外伤评分等级
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.nrsScore = null; // Long 患者疼痛评分得分
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.nrsLevel = null; // String 患者疼痛评分等级
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.cramsScore = null; // String 创伤评分得分
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.cramsLevel = null; // String 创伤评分等级
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.mewsScore = null; // Long MEWS评分
    output.createErpPreExamRecordBto.referenceDiseaseLevelId = _.get(
      input,
      'referenceDiseaseLevel.id',
    );
    output.createErpPreExamRecordBto.diseaseLevelId = _.get(
      input,
      'diseaseLevel.id',
    ); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.diseaseLevelChangeReason = null; // String 病情分级修改理由
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.diseaseLevelReasonCode = null; // Dbenum<PRE_LEVEL_CHANGE_DICT> 病情分级修改理由ID
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.directionChangeReason = null; // String 病人去向修改理由
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.directionChangeReasonCode = null; // Dbenum<PRE_LEVEL_CHANGE_DICT> 病人去向修改理由ID
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.reasonToBack = null; // String 退号理由
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.visitPatientType = null; // Dbenum<PRE_EXAM_PATIENT_TYPE> 就诊患者类型
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.specialPatientMemo = null; // Dbenum<SPECIAL_PATIENT_REASON> 特殊患者备注
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.recordType = null; // ErpPreExamRecordTypeEnum 预检主记录类型
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.recordNumber = null; // Long 同一个visitNo的第几条评估记录
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.cancelIndicator = null; // Boolean 评估记录作废标识
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.epidemiologyHistory = null; // Dbenum<EPIDEMIOLOGY_HISTORY_DICT> 流行病学史
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.epidemiologyHistoryMemo = null; // String 流行病学史备注
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.otherAssessmentType = null; // Dbenum<PRE_EXAM_OTHER_ASSESSMENT_TYPE> 其他评估类型
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.carAccidentLocation = null; // String 车祸地址（废弃））
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.carAccidentPolice = null; // String 受理交警（废弃））
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.ambulanceTypeMemo = null; // String 120类型备注
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.wristbandCode = null; // String ？？？ 腕带编码
    // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.vitalsignStatus = null; // VitalsignStatusEnum 体征状态
    output.createErpPreExamRecordBto.erpPreExamRecordDetailBtoList = _.get(
      input,
      'list',
    ); // TODO 没有匹配到合适字段 output.createErpPreExamRecordBto.pewsScore = null; // Long PEWS评分
    output.createPatientChiefComplaintVsJudgeBtoList = _.get(
      input,
      'patientChiefComplaintVsJudgeDictionaryDtoList',
    );
    output.saveErpAccidentEventRecordMasterBto = {}; // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.id = null; // Long 主键
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.eventCode = null; // Dbenum<EMERGENCY_EVENT_DICT> 事件编码
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.createDateTime = null; // Date 创建时间
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.createNurseOneId = null; // Long 创建护士工号1
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.createNurseTwoId = null; // Long 创建护士工号2
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.eventPatientCount = null; // Long 事件人数
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.autoGeneratePatientIdCount = null; // Long 事件发生日期自动生成患者id个数
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordMasterBto.eventName = null; // String 事件名称
    output.saveErpAccidentEventRecordDetailBto = {};
    output.saveErpAccidentEventRecordDetailBto.id = _.get(input, 'id'); // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.eventRecordId = null; // Long 事件记录流水号
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.preExamRecordId = null; // Long 预检流水号
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.patientId = null; // Long 患者ID
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.ambulanceArriveDateTime = null; // Date 救护车到达时间
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.vitalsignAssessmentIndicator = null; // Boolean 体征评估状态标识
    // TODO 没有匹配到合适字段 output.saveErpAccidentEventRecordDetailBto.avpu = null; // String undefined
    return output;
  };
// 删除患者
export const convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2DeleteCountErpAccidentEventRecordReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = _.get(input, 'id');
    return output;
  };
// 删除事件
export const convertErpAccidentEventRecordSearchQtoForm2DeleteErpAccidentEventRecordReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = _.get(input, 'eventRecordId');
    output.extApiParam = {};
    return output;
  };
// 增加患者
export const convertAddCountErpAccidentEventRecordForm2AddCountErpAccidentEventRecordReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = _.get(input, 'id');
    output.btoParam.eventPatientCount = _.get(input, 'eventPatientCount');
    output.btoParam.addCount = _.get(input, 'addCount');
    output.extApiParam = {};
    return output;
  };
