import './index.less';

import { Image } from '@vs/vsf-kit';
import CSS from 'csstype';
import React, { useCallback, useEffect, useState } from 'react';

import NoFind from './404.png';
import NoContent from './content.png';
import NoData from './data.png';
import NoDepartment from './department.png';
import NoDrug from './drug.png';
import NoMedical from './medical.png';
import NoNetwork from './network.png';
import NoStaff from './staff.png';

const sourceObj = {
  404: NoFind,
  content: NoContent,
  data: NoData,
  department: NoDepartment,
  drug: NoDrug,
  medical: NoMedical,
  network: NoNetwork,
  staff: NoStaff,
};

type SourceType =
  | '404'
  | 'content'
  | 'data'
  | 'department'
  | 'staff'
  | 'medical'
  | 'drug'
  | 'network';

type EmptyPropsType = {
  type?: SourceType;
  imgStyle?: CSS.Properties;
  className?: string;
  desc?: string;
  wordStyle?: CSS.Properties;
};

const Index = (props: EmptyPropsType) => {
  const [source, setSource] = useState<any>();
  const {
    type = 'content',
    imgStyle = {
      // width: 180,
    },
    className = '',
    desc = '当前页面没有内容哦~',
    wordStyle,
  } = props;

  const handleSetSource = useCallback(
    async (v: SourceType) => {
      if (v) {
        const target = sourceObj?.[type];
        setSource(target);
      }
    },
    [type],
  );

  useEffect(() => {
    handleSetSource(type);
  }, [type, handleSetSource]);

  return (
    <div className="aspirin-empty-container-main-box">
      <div className={`aspirin-empty-container-box ${className}`}>
        <Image src={source} preview={false} style={imgStyle} />
        <div style={wordStyle} className="word">
          {desc}
        </div>
      </div>
    </div>
  );
};

export default Index;
