import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Divider, Form, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import { useAsyncEffect, useMount, useSetState } from 'ahooks';
import moment from 'moment';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { mockOutpVisitEncounterId } from '@/mock';
import FormBlock from '@/module/cpoe/FormBlock';
import { askSearch } from '@/pages/Index/components/func/ask';
import { labelColWidth, labelNumberWidth } from '@/utils';
import storage from '@/utils/storage';

import OperationSelect from './operationSelect';

const yesOrNo = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];

const epidemicClassList = [
  {
    label: 'HbsAg',
    value: '1',
  },
  {
    label: '梅毒',
    value: '2',
  },
  {
    label: '艾滋',
    value: '3',
  },
  {
    label: '丙肝',
    value: '4',
  },
];

const Operation = (props: any) => {
  const { data, stores } = props;
  const { common } = stores || {};
  const { currentApplication } = common || {};
  // const currentApplication = useMemo(
  //   () => storage.get('currentApplication') || {},
  //   [],
  // );
  const { department } = currentApplication;
  const [baseInfo, setBaseInfo] = useSetState<any>({});
  const [operationRoomList, setOperationRoomList] = useState<any[]>();
  const [form] = Form?.useForm();

  const onRoom = useCallback(async () => {
    /**
     * 获取当前工作站的本地参数
     */
    const { data: room } =
      (await vsf?.services?.ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2(
        {
          applicationId: currentApplication?.id,
        },
      )) as any;
    const ApplyCanChooseOperationRoom = room?.find(
      (item) => item?.localConfig?.name === 'apply_can_choose_operation_room',
    );
    const id = JSON.parse(ApplyCanChooseOperationRoom?.localValue ?? '[]')?.map(
      (item) => Number(item),
    );
    return {
      room,
      id,
    };
  }, [currentApplication?.id]);

  /**
   * 获取科室
   */
  const getOperationRoomList = useCallback(
    async (nameLike) => {
      if (nameLike) {
        const res =
          await vsf.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
            {
              stopIndicatorIs: false,
              outpOrInp: '1,2',
              nameLike,
              branchInstitutionIdIs:
                currentApplication?.department?.branchInstitution?.id,
            },
          );
        if (res?.code === 200) {
          setOperationRoomList(res?.data?.result ?? []);
          return res?.data?.result;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    [currentApplication?.department?.branchInstitution?.id],
  );

  const getValue = useCallback(async (outpVisitEncounterId) => {
    const res =
      await vsf.services?.ClinicRecordOrderOperationController_getOperationInfo_f7ce32?.(
        {
          outpVisitEncounterId,
        },
      );
    if (res?.code === 200) {
      setBaseInfo({
        ...res?.data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getValue(mockOutpVisitEncounterId);
  }, [getValue]);

  // useEffect(() => {
  //   getOperationRoomList();
  // }, [getOperationRoomList]);

  useAsyncEffect(async () => {
    const { id, room } = await onRoom();

    const currentDepartmentList =
      await vsf.services?.OrganizationController_getDepartmentByIds_c5e4b4?.({
        id,
      });
    const operationList =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
        {
          qto: {
            from: 0,
            size: 20,
          },
          ext: {},
        },
      );
    setBaseInfo({
      ...room,
      operationRoomDepartmentList: currentDepartmentList?.data,
      // currentDoctorList: currentDoctorList?.data?.result,
      operationList: operationList?.data?.result,
    });
  }, []);

  /**
   * 获取临床特殊数据源
   */
  const PageFallDiagnosisDictionary = useCallback(async (value) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          // 所选检查单类型的ID
          qto: {
            from: 0,
            size: 20,
            ...value,
          },
          ext: {},
        },
      );
    if (res?.code === 200) {
      return res?.data?.result;
    }
  }, []);
  useMount(() => {
    if (data?.orderDiagnosisIdList?.length) {
      PageFallDiagnosisDictionary({
        size: 9999,
      })?.then((res) => {
        form?.setFieldValue(
          'orderDiagnosisIdList',
          res?.filter((item) => data?.orderDiagnosisIdList?.includes(item?.id)),
        );
      });
    }
  });

  useEffect(() => {
    form?.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form]);

  const oneHandDataSource = useCallback(async (value) => {
    const res =
      await vsf.services?.UserStaffController_queryOperationDoctorListBySearchCode_3a0b41(
        {
          searchCode: value?.label ?? '',
        },
      );
    return res?.data?.result?.map((item) => ({
      ...item,
      staffName: `${item?.staffName ?? ''}-${
        item?.staffDepartment?.departmentName ?? ''
      }`,
    }));
  }, []);

  const PatientSpecialInfo = baseInfo?.epidemicClass?.map((item) => {
    const name = epidemicClassList?.find(
      (eItem) => eItem?.value === item?.epidemicClassCode,
    )?.label;
    return {
      infectClass: name,
    };
  });

  // useImperativeHandle(ref, () => ({
  //   onSubmit: () =>
  //     vsf.refs.orderOperationForm?.validateFieldsReturnFormatValue(),
  // }));

  return (
    <div className="cpoe-operation">
      <div className="main">
        <VSForm id="orderOperationForm" labelAlign="left" form={form}>
          <FormBlock title="手术信息">
            <VSFormLayout
              key="0"
              columnCount={3}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={50}
            >
              <VSFormItem
                name={['operationRoomDepartment']}
                label="手术室"
                valueType="select"
                rules={[{ required: true }]}
                fieldProps={{
                  showSearch: true,
                }}
                transform={(value) => {
                  // const state = baseInfo?.operationRoomDepartmentList?.find(
                  //   (item) => item?.id === value,
                  // );
                  return {
                    operationRoomDepartment: {
                      departmentName: value?.departmentName,
                      id: value?.id,
                    },
                    operationRoomDepartmentId: value?.id,
                  };
                }}
                fieldNames={{
                  label: 'departmentName',
                  value: 'id',
                }}
                searchKey="label"
                dataSource={(input) => {
                  const list = baseInfo?.operationRoomDepartmentList;
                  return input?.label
                    ? list?.filter((item) =>
                        askSearch(input?.label, item, 'departmentName'),
                      )
                    : list;
                }}
              />
              <VSFormItem
                name={['anesthesiaIndicator']}
                label="麻醉"
                valueType="radioButton"
                rules={[{ required: true }]}
                initialValue={'LOCAL_ANESTHESIA'}
                dataSource={[
                  { label: '全麻', value: 'GENERAL_ANESTHESIA' },
                  { label: '局麻', value: 'LOCAL_ANESTHESIA' },
                ]}
              />
              <VSFormItem
                name={['anesthesiaAssessmentIndicator']}
                label="麻醉评估"
                valueType="radioButton"
                initialValue={false}
                searchKey="label"
                dataSource={yesOrNo}
              />

              <VSFormItem
                name={['operationDepartment']}
                label="手术科室"
                valueType="select"
                rules={[{ required: true }]}
                fieldProps={{
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'departmentName',
                  value: 'id',
                }}
                initialValue={department ? department : undefined}
                searchKey="label"
                transform={(value) => {
                  return {
                    operationDepartment: value,
                    operationDepartmentId: value?.id,
                  };
                }}
                // dataSource={async (value) => {
                //   const dataSource = operationRoomList ?? [];
                //   return value?.label
                //     ? dataSource?.filter((item) =>
                //         askSearch(value?.label, item, 'departmentName'),
                //       )
                //     : dataSource;
                // }}
                dataSource={async (value) => {
                  return (getOperationRoomList?.(value?.label) as any) ?? [];
                }}
              />

              <VSFormItem
                name={['operatorDoctor']}
                label="手术医师"
                valueType="select"
                rules={[{ required: true }]}
                fieldProps={{
                  showSearch: true,
                  placeholder: '请输入姓名/首字母/工号',
                }}
                searchKey="label"
                fieldNames={{
                  label: 'staffName',
                  value: 'id',
                }}
                dataSource={oneHandDataSource}
                transform={(value) => ({
                  operatorDoctorId: value?.id,
                })}
              />
              <VSFormItem
                name={['firstAssistant']}
                label="助手1"
                valueType="select"
                fieldProps={{
                  showSearch: true,
                  placeholder: '请输入姓名/首字母/工号',
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'id',
                }}
                searchKey="label"
                dataSource={oneHandDataSource}
                transform={(value) => ({
                  firstAssistantId: value?.id,
                })}
              />

              <VSFormItem
                name={['secondAssistant']}
                label="助手2"
                valueType="select"
                fieldProps={{
                  showSearch: true,
                  placeholder: '请输入姓名/首字母/工号',
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'id',
                }}
                searchKey="label"
                dataSource={oneHandDataSource}
                transform={(value) => ({
                  secondAssistantId: value?.id,
                })}
              />

              <VSFormItem
                name={['thirdAssistant']}
                label="助手3"
                valueType="select"
                fieldProps={{
                  showSearch: true,
                  placeholder: '请输入姓名/首字母/工号',
                }}
                fieldNames={{
                  label: 'staffName',
                  value: 'id',
                }}
                searchKey="label"
                dataSource={oneHandDataSource}
                transform={(value) => ({
                  thirdAssistantId: value?.id,
                })}
              />
              <VSFormItem
                name={['operationLevel']}
                label="申请单等级"
                valueType="radioButton"
                rules={[{ required: true, message: '请选择申请单等级' }]}
                fieldProps={{}}
                dataSource={vsf.stores.dbenums?.enums?.OPERATION_SCALE_DICT}
              />

              <VSFormItem
                name={['operationApplyType']}
                label="手术类型"
                valueType="radioButton"
                dataSource={[
                  { label: '急诊', value: 'EMERGENCY' },
                  { label: '择期', value: 'ANY_DAY' },
                ]}
                rules={[{ required: true, message: '请选择手术类型' }]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['scheduleDateTime']}
                label="手术日期"
                valueType="date"
                fieldProps={{
                  format: 'YYYY-MM-DD',
                  disabledDate: (current: any) => {
                    return (current &&
                      current < moment().subtract(1, 'day')) as any;
                  },
                }}
                rules={[{ required: true }]}
              />

              <VSFormItem
                name={['estimateTime']}
                label="预计时长"
                valueType="digit"
                fieldProps={{
                  addonAfter: '小时',
                  precision: 2,
                  width: '100%',
                  min: 0,
                }}
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              />
            </VSFormLayout>
            <VSFormLayout
              key="5"
              columnCount={1}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={50}
            >
              <VSFormItem
                name={['orderDiagnosisIdList']}
                label="术前诊断"
                valueType="select"
                searchKey="searchInputLike"
                fieldProps={{
                  showSearch: true,
                  placeholder: '请输入诊断名称/首字母搜索',
                  mode: 'multiple',
                }}
                fieldNames={{
                  label: 'diagnosisName',
                  value: 'id',
                }}
                rules={[{ required: true }]}
                dataSource={PageFallDiagnosisDictionary}
              />
            </VSFormLayout>
            <VSFormLayout
              key="6"
              columnCount={1}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={50}
            >
              <VSFormItem
                name={['operationOrderDetail']}
                label="手术"
                valueType="listItem"
                rules={[
                  { required: true },
                  {
                    validator(...[, value]) {
                      const resolve = value
                        ?.map((item) => {
                          const {
                            incisionLevelCode,
                            operationLevel,
                            operationBodypartName,
                            ...rest
                          } = item?.value ?? item;
                          if (!Object?.values(item?.value ?? item)?.length) {
                            return true;
                          }
                          if (Object?.values(rest)?.length === 1) return true;
                          return Object.values(rest)?.includes(undefined);
                        })
                        ?.includes(true);

                      return resolve ? Promise?.reject() : Promise?.resolve();
                    },
                  },
                ]}
                fieldProps={{
                  children: (
                    <OperationSelect dataSource={baseInfo?.operationList} />
                  ),
                  mode: 'splice',
                }}
                transform={(value) => ({
                  operationOrderDetail: value?.map(
                    (item) => item?.value ?? item,
                  ),
                })}
                required={true}
              />
            </VSFormLayout>
          </FormBlock>
          <Divider />
          <FormBlock title="其他">
            <VSFormLayout
              key="7"
              columnCount={3}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={50}
            >
              <VSFormItem
                name={['needFrozenExamIndicator']}
                label="快速病理"
                valueType="radioButton"
                dataSource={yesOrNo}
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['externalExpertIndicator']}
                label="外请标志"
                valueType="radioButton"
                dataSource={yesOrNo}
                initialValue={false}
                fieldProps={{}}
              />
              <VSFormItem
                name={['externalEquipmentIndicator']}
                label="外来器械"
                valueType="radioButton"
                dataSource={yesOrNo}
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['unexpectedIndicator']}
                label="非预期手术"
                valueType="radioButton"
                dataSource={yesOrNo}
                initialValue={false}
                fieldProps={{}}
              />

              <VSFormItem
                name={['specialInfectIndicator']}
                label="特殊感染"
                valueType="radioButton"
                dataSource={yesOrNo}
                initialValue={false}
                fieldProps={{}}
              />
            </VSFormLayout>
            <VSFormLayout
              columnCount={2}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={70}
            >
              {(PatientSpecialInfo ?? [])?.map((item) => (
                <VSFormItem
                  key={item?.infectClass}
                  name={['patientSpecialInfo', item?.infectClass]}
                  label={item?.infectClass}
                  valueType="radioButton"
                  dataSource={
                    vsf.stores.dbenums.enums.OPERATION_INFECTIOUS_STATUS
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              ))}
            </VSFormLayout>
            <VSFormLayout
              key="9"
              columnCount={1}
              // labelCol={labelColWidth[6]}
              labelWidth={labelNumberWidth(7)}
              gutter={50}
            >
              <VSFormItem
                name={['memo']}
                label="备注"
                valueType="textarea"
                rules={[
                  {
                    message: 'memo长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
            {/* <VSFormItem
                name={['epidemicExamResult']}
                label="流行病检查结果"
                valueType="listBox"
                fieldProps={{ children: <Input /> }}
              /> */}
          </FormBlock>
        </VSForm>
      </div>
    </div>
  );
};

Operation.displayName = 'Operation';
export default definePage(Operation);
