import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateList_e7f380:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-charge-template-list',
      parameterFomatter: (data?: { personIndicator: boolean }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeTemplateVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
  MedicalTechnologyChargeTemplateController_getMedicalTechnologyChargeTemplateDetailList_7eb527:
    {
      method: 'post',
      url: '/api/medical-technology/get-medical-technology-charge-template-detail-list',
      parameterFomatter: (data?: { templateId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: MedicalTechnologyChargeTemplateDetailWithPriceItemVoMedicalTechnologyEntranceWebVo[],
      ) => data,
    },
});
