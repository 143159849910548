import {
  Switch,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  Button,
  Space,
  DatePicker,
  Modal,
  Row,
  Col,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { getScrollY, getScrollYRem } from '@/utils';
import Block from '@/module/Block';
import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';
import locale from '@vs/vsf-kit/es/locale/zh_CN';
import dayjs from 'dayjs';

import TableCart from './components/tableCart';
import AddTable from './addTable';

import './components/index.less';
import './components/index.less';
import { useRequest } from 'ahooks';
import {
  getAllByClinicRegisterTypeDictionaryQto,
  getAllByDepartmentInCurrentInstitution,
} from './service';

const ClinicRegisterSchedule = (props) => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteParam, setDeleteParam] = useState();
  const [checkCellData, setCheckCellData] = useState();
  const [switchOpen, setSwitchOpen] = useState(true);

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterDefineWithScheduleQto_ca0524?.(
          {
            qto: {
              ...params.search,
              clinicDepartmentIdIs: params?.search?.clinicDepartmentIdIs?.id,
              clinicLabelLike: params?.search?.clinicLabelLike?.clinicLabel,
              clinicRegisterTypeIdIs:
                params?.search?.clinicRegisterTypeIdIs?.id,
              doctorIdIs: params?.search?.doctorIdIs?.id,
            },
          },
        );
      const file = res?.data?.filter((item) => {
        return !item?.stopIndicator;
      });
      if (res) {
        let arr = [];
        file?.map((item, index) => {
          let clinicRegisterScheduleWithDetailDtoListData =
            item?.clinicRegisterScheduleWithDetailDtoList?.map((item1) => {
              const data =
                item1?.clinicRegisterScheduleDetailDetailDtoList.filter(
                  (item) => {
                    return switchOpen
                      ? item?.summerIndicator
                      : !item?.summerIndicator;
                  },
                );
              return {
                ...item1,
                clinicRegisterScheduleDetailDetailDtoList: data,
              };
            });

          arr.push({
            ...item,
            clinicRegisterScheduleWithDetailDtoList:
              clinicRegisterScheduleWithDetailDtoListData,
          });
        });
        arr = arr?.map((item) => {
          return {
            ...item,
            id: item?.id + Math.floor(Math.random() * 900) + 100,
          };
        });
        return {
          data: arr,
        };
      }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [switchOpen],
  );

  const openDrawer = () => {
    setOpen(true);
  };
  const cancelDrawer = () => {
    setCheckCellData(undefined);
    vsf.refs.ClinicRegisterScheduleDetailForm.setFieldsValue({
      clinicRegisterScheduleDetailDetailDtoList1: {},
    });
    setOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const cancelModal = () => {
    setModalOpen(false);
  };
  const modalConfim = () => {
    onDelete(deleteParam);
    setModalOpen(false);
  };

  const handelCancel = () => {
    setCheckCellData(undefined);
  };
  const { data: clinicTypeList } = useRequest(
    getAllByClinicRegisterTypeDictionaryQto,
  );
  const { data: handelList } = useRequest(
    getAllByDepartmentInCurrentInstitution,
  );
  // async (v) => {
  //   const res =
  //     await vsf.services.DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de(
  //       // { ...v },
  //       { qto: { ...v } },
  //     );
  //   return res?.data;
  // };
  const deleteParamChange = (value) => {
    setDeleteParam(value);
  };

  const onDelete = async (params) => {
    const res =
      await vsf?.services?.OutpRegisterController_deleteClinicRegisterSchedule_4a853c?.(
        {
          btoParam: checkCellData,
          extApiParam: { endDate: params },
        },
      );
    if (res?.code === 200) {
      message.success('删除成功!');
      vsf?.refs?.clinicRegisterScheduleTable?.reload();
    }
    return res?.data;
  };

  const handelList1 = async (v) => {
    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: v?.nameLike ?? '',
        },
      );
    return res.data?.result;
  };
  const iSClick = (Arr, isSummer) => {
    if (Arr.length > 0) {
      const flagArr = Arr.map((item) => {
        if (!item.summerIndicator) {
          return false;
        } else {
          return true;
        }
      });
      const flag = flagArr.includes(isSummer);
      return flag;
    }
  };

  const enums = useMemo(
    () => ({
      1: '一',
      2: '二',
      3: '三',
      4: '四',
      5: '五',
      6: '六',
      7: '日',
    }),
    [],
  );
  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            bgColor: '#fff',
          },
        ],
      }}
    >
      <Block>
        <div className="clinic-register-schedule-main">
          <VSPromiseTable
            className="aspirinMainTable"
            pagination={false}
            // vsid="51623"
            id="clinicRegisterScheduleTable"
            onFetch={onFetch}
            searchFormRender={(tableProps, defaultDom) => {
              return <div className="aspirin-table-form-box">{defaultDom}</div>;
            }}
            searchConfig={{}}
            scroll={{
              // y: getScrollY(450),
              y: getScrollYRem(30),
              x: 1000,
            }}
          >
            <VSTableColumn
              width={'260px'}
              dataIndex={['clinicRegisterType', 'clinicType']}
              title="号别信息"
              valueType="custom"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 12 },
                ],
              }}
              render={(item, props) => {
                // return '';
                // console.log(props, 'props');
                return <TableCart valuetype="1" tableData={props} />;
              }}
              fieldProps={
                {
                  // style: { minWidth: '270px' },
                }
              }
            />

            <VSTableColumn
              dataIndex={['clinicDepartment', 'departmentName']}
              columnKey={'clinicDepartmentIdIs'}
              title="门诊科室"
              search
              valueType="selectCode"
              hideInTable={true}
              formItemProps={{
                rules: [
                  {
                    message: '科室名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{
                dataSource: handelList,
                fieldNames: { label: 'departmentName', value: 'id' },
                showSearch: true,
                placeholder: '请输入门诊科室',
                searchKey: 'name',
              }}
            />

            <VSTableColumn
              dataIndex={['clinicRegisterType', 'clinicType']}
              columnKey={'clinicRegisterTypeIdIs'}
              title="号类"
              valueType="selectCode"
              hideInTable={true}
              search
              fieldProps={{
                dataSource: clinicTypeList,
                fieldNames: { label: 'clinicType', value: 'id' },
                showSearch: true,
              }}
            />

            <VSTableColumn
              dataIndex={['doctor']}
              title="医生"
              valueType="select"
              hideInTable={true}
              search
              order={-10}
              columnKey={'doctorIdIs'}
              fieldProps={{
                dataSource: handelList1,
                fieldNames: { label: 'staffName', value: 'id' },
                showSearch: true,
                searchKey: 'nameLike',
              }}
            />

            <VSTableColumn
              dataIndex={['clinicLabel', 'id']}
              title="号别名称"
              search
              columnKey={'clinicLabelLike'}
              valueType="select"
              hideInTable={true}
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 12 },
                ],
              }}
              fieldProps={{
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterDefineNewQto_3be984?.(
                      {
                        qto: { ...params },
                      },
                    );
                  return res?.data;
                },
                fieldNames: { label: 'clinicLabel', value: 'id' },
                showSearch: true,
                searchKey: 'clinicLabelLike',
              }}
            />
            {Object.keys(enums)?.map((key) => {
              return (
                <VSTableColumn
                  width={'200px'}
                  dataIndex={['doctor', 'staffName']}
                  title={`周${enums[key]}`}
                  key={enums[key]}
                  valueType="text"
                  render={(...[, record]) => {
                    const tableRowData =
                      record?.clinicRegisterScheduleWithDetailDtoList?.find(
                        (item) => item?.dayOfWeek == key,
                      );
                    return (
                      tableRowData?.clinicRegisterScheduleDetailDetailDtoList
                        ?.length > 0 && (
                        <TableCart
                          tableRowData={tableRowData}
                          checkCellData={checkCellData}
                        />
                      )
                    );
                  }}
                  fieldProps={{}}
                  onCell={(record, index) => ({
                    onClick: (event) => {
                      const tableRowData =
                        record?.clinicRegisterScheduleWithDetailDtoList?.find(
                          (item) => item?.dayOfWeek == key,
                        );
                      if (
                        tableRowData &&
                        tableRowData.clinicRegisterScheduleDetailDetailDtoList
                          .length > 0
                      ) {
                        const all = document.querySelectorAll(`.cart-content`);
                        for (const item of all) {
                          if (item?.dataset?.id != tableRowData?.id) {
                            item?.classList?.remove('cart-content-active');
                          }
                        }
                        const row = document.querySelector(
                          `[data-id="${tableRowData?.id}"]`,
                        );
                        if (!row?.className?.includes('cart-content-active')) {
                          row?.classList?.add('cart-content-active');
                          setCheckCellData(tableRowData);
                        } else {
                          row?.classList?.remove('cart-content-active');
                          setCheckCellData(null);
                        }
                        // , tableRowData?.id ===  checkCellData?.id ? 'cart-content-active' : ''
                      }
                    },
                    onDoubleClick: () => {
                      const tableRowData =
                        record?.clinicRegisterScheduleWithDetailDtoList?.find(
                          (item) => item?.dayOfWeek == key,
                        );
                      if (
                        tableRowData &&
                        tableRowData.clinicRegisterScheduleDetailDetailDtoList
                          .length > 0
                      ) {
                        setCheckCellData(tableRowData);
                        tableRowData && openDrawer();
                      }
                    },
                  })}
                />
              );
            })}
            <VSTableToolbar
              title={
                <div className="all-save">
                  <div className="table-title-switch">
                    <div>周排班计划</div>
                    &nbsp;
                    <div>
                      <Switch
                        defaultChecked
                        open={switchOpen}
                        style={{ backgroundColor: '#4290f7' }}
                        checkedChildren={'夏'}
                        unCheckedChildren={'冬'}
                        onChange={(checked) => {
                          setSwitchOpen(checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            >
              <Space size={5} style={{ paddingRight: '20px' }}>
                <Button
                  type="primary"
                  children={checkCellData ? '编辑' : '新增'}
                  onClick={openDrawer}
                />
                {/* {
                  checkCellData && 
                  <Button
                    type="primary"
                    children="取消"
                    onClick={handelCancel}
                  />
                }            */}
                <Button
                  type="primary"
                  children="删除"
                  disabled={!checkCellData}
                  onClick={openModal}
                />
              </Space>
            </VSTableToolbar>
          </VSPromiseTable>

          {/* 抽屉 */}
          <AddTable
            checkCellData={checkCellData}
            open={open}
            onCancel={cancelDrawer}
          ></AddTable>

          {/* 删除弹框 */}
          <Modal
            title={'排班计划生效截止时间'}
            open={modalOpen}
            onCancel={cancelModal}
            onOk={modalConfim}
          >
            <Row>
              <Col span={2}>至</Col>
              <Col>
                <DatePicker
                  disabledDate={(current) => {
                    // Can not select days before today and today
                    return current && current < dayjs().endOf('day');
                  }}
                  locale={locale.DatePicker}
                  onChange={deleteParamChange}
                />
              </Col>
            </Row>
          </Modal>
        </div>
      </Block>
    </Container>
  );
};
export default definePage(ClinicRegisterSchedule);
