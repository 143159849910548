/* eslint-disable no-prototype-builtins */
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message, Tooltip, VSTableColumn } from '@vs/vsf-kit';
import { useAsyncEffect, useMount, useUpdateEffect } from 'ahooks';
import React, {
  createContext,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import Block from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';

import { ButtonAndKeyDown } from '../..';
import { ButtonAndKeyDownProps } from '../../button_and_keydown';
import Flex from '../../flex';
import { AuxiliaryRef } from './assist/auxiliary';
import { HeaderRefs } from './assist/header';
import OutHospital, { Refs, TableRefs, Value } from './table';
import OutHospitalTemplate from './table';
import {
  convertOrderInField,
  convertOrderInTable,
  convertOrderOutTable,
  convertOtherOrderInfo,
} from './table/convert';

export const BeIn = createContext((value) => {});

export type Drug = {
  onDrug: (value?: never) => void;
  onOther: () => void;
};
export type AuxiliaryRefs = AuxiliaryRef;

const OutHospitalTemplates = (props) => {
  const {
    prefixCls = 'aspirin-cpoe-center',
    orderTemplate,
    eliminateButtons,
    eliminates,
    agreedPrescriptionIndicator,
    reset,
    restOrderTemplate,
  } = props;
  const {
    user: { outVisitInfo },
  } = vsf?.stores;

  const auxiliary = useRef<AuxiliaryRefs | null>(null);
  const table = useRef<Drug & TableRefs & Refs & { id: () => string | number }>(
    null,
  );
  const header = useRef<HeaderRefs>(null);
  const footer = useRef<{ click: () => void }>();

  useEffect(() => {
    if (reset) {
      table?.current?.setValue([]);
      restOrderTemplate();
    }
  }, [reset, restOrderTemplate]);
  const onReload = useCallback(async () => {
    if (orderTemplate?.id) {
      const res =
        await vsf.services?.OrderTemplateItemController_getOrderTemplateItemDetailByTemplateId_6c1e5e?.(
          {
            templateId: orderTemplate?.id,
          },
        );
      const value = res?.data?.map((item) => {
        Object.keys(item).forEach((option) => {
          const replace = option.replace(/^orderTemplate(.*)Dto$/, (...arr) => {
            const [, name] = arr;
            return name?.toLocaleLowerCase() + 'Order';
          });
          item[replace] = item[option];
          if (Object?.keys(item[option] ?? {})?.length) {
            Object?.keys(item[option] ?? {})?.forEach((option) => {
              const title = option.replace(
                /^orderTemplate(.*)DetailDtoList$/,
                (...[, name]) => {
                  return `${name?.toLocaleLowerCase()}OrderDetail`;
                },
              );
              if (Array?.isArray(item[replace][option]))
                item[replace][title] = item[replace][option];
            });
          }
        });
        return {
          ...item,
          // uuid: getUUID(),
        };
      });
      table?.current?.setValue(
        table?.current?.onSliced(
          convertOrderInTable(
            value?.map((item) => ({
              ...item,
              uuid: getUUID(),
            })),
          ),
        ),
      );
    }
  }, [orderTemplate?.id]);

  const Footer = forwardRef((props: any, ref) => {
    const [click] = useOnChangeValue<any>({});
    const [group] = useOnChangeValue(true);
    const onSubmit = useCallback(async () => {
      const orderTemplateItems = table?.current?.getValue?.() as any;
      const value = convertOrderOutTable(orderTemplateItems)?.map((item) => {
        Object.keys(item).forEach((option) => {
          if (/^(.*)Order$/?.test(option)) {
            const replace = option.replace(/^(.*)Order$/, (...[, name]) => {
              return `orderTemplate${
                name?.charAt(0)?.toUpperCase() + name?.slice(1)
              }Bto`;
            });
            if (Object?.keys(item[option] ?? {})?.length) {
              item[replace] = item[option];
              Object?.keys(item[option] ?? {})?.forEach((option) => {
                const title = option.replace(
                  /^(.*)OrderDetail$/,
                  (...[, name]) => {
                    return `orderTemplate${
                      name?.charAt(0)?.toUpperCase() + name?.slice(1)
                    }DetailBtoList`;
                  },
                );
                if (Array?.isArray(item[replace][option]))
                  item[replace][title] = item[replace][option];
              });
            }
          }
        });
        return item;
      });
      return value;
    }, []);

    const onSave = useFetch({
      fetch: async () => {
        const save: boolean = await (table.current as any)?.saveEdit?.();
        if (save) {
          const value = await onSubmit();
          return (vsf.services?.OrderTemplateItemController_saveOrderTemplateItem_eca058?.(
            {
              orderTemplateItems: value?.map((item) => ({
                ...item,
                orderTemplateHerbBto: {
                  ...item?.orderTemplateHerbBto,
                  herbPrescriptionName: item?.herbPrescriptionName,
                },
                orderTemplateId: orderTemplate?.id,
              })),
            },
          ) ?? {
            code: 500,
          }) as any;
        }
      },
      message: '保存套餐医嘱',
      success() {
        header?.current?.onReload?.();
      },
    });

    useImperativeHandle(ref, () => ({
      click: click?.onChange,
      group: group?.onChange,
    }));

    const button: ButtonAndKeyDownProps[] = [
      {
        type: 'primary',
        ghost: true,
        children: '刷新',
        onClick: () => {
          console.log('刷新', header);
          onReload();
          // header?.current?.onReload();
        },
        methodKey: 'altKey',
        ordinaryKey: 'Q',
      },
      {
        type: 'primary',
        children: '成组',
        onClick: () => table?.current?.onGroup?.(),
        disabled: group?.value,
        methodKey: 'altKey',
        ordinaryKey: 'V',
      },
      {
        type: 'primary',
        ghost: true,
        children: '取消成组',
        onClick: () => table?.current?.onCancelGroup?.(),
        /**
         * 执行和提交状态，不可以取消成组
         * 并且有关联的医嘱不可以取消成组
         */
        disabled:
          click?.value?.parentRelatedUuid ||
          ['PERFORM', 'SUBMIT']?.includes(click?.value?.orderStatus) ||
          !click?.value?.groupIndex?.length,
        methodKey: 'altKey',
        ordinaryKey: 'R',
      },
      {
        type: 'primary',
        children: '保存',
        onClick: async () => {
          const save = await table?.current?.saveEdit?.();
          if (save) {
            onSave();
          }
        },
        methodKey: 'altKey',
        ordinaryKey: 'S',
      },
    ];
    return (
      <Flex justify="flex-end" gap={10}>
        {button
          ?.filter((item) =>
            eliminateButtons?.length
              ? eliminateButtons?.includes?.(item?.children)
              : true,
          )
          ?.map((item, index) => {
            return <ButtonAndKeyDown {...item} key={index} />;
          })}
      </Flex>
    );
  });
  Footer.displayName = 'Footer';

  /**
   * 抽屉操作: 提交数据到单行
   */
  const onDrawerSubmit = useCallback((value: Value) => {
    const target = (table?.current?.getValue() ?? []) as Value[] as any;
    const id = 'create' + Math?.random();
    const self = target?.find((item) => item?.id === table?.current?.id());
    if (self?.insert) {
      const is = Array?.isArray(value);
      const index = target?.findIndex(
        (item) => item?.id === table?.current?.id(),
      );
      table?.current?.setValue(
        target?.toSpliced?.(
          index,
          1,
          ...(is
            ? value?.map((item) => {
                return {
                  id: 'create' + Math?.random(),
                  uuid: getUUID(),
                  orderStatus: 'OPEN',
                  ...item,
                };
              })
            : [{ id, ...value }]),
        ),
      );
      return;
    }
    const index = (target ?? [])?.findIndex(
      (item) => item?.uuid === value?.uuid,
    );
    if (Array?.isArray(value)) {
      if (
        value
          ?.map?.((item) =>
            Object?.keys(item).some((value) => /^(.*)Order$/.test(value)),
          )
          ?.includes(true)
      ) {
        table?.current?.setValue(
          convertOrderInTable([
            ...target,
            ...value?.map((item) => {
              return {
                id: 'create' + Math?.random(),
                uuid: getUUID(),
                orderStatus: 'OPEN',
                ...item,
              };
            }),
          ]),
        );
      } else {
        /**
         * 查找最后一个组号补上
         */
        const last = (table?.current?.getValue() as unknown as any[])
          ?.map((item) => item?.drugOrder?.groupNumber)
          ?.filter(Boolean);
        const group = last?.length
          ? Math?.max(...(new Set(last) as unknown as any[]))
          : 0;
        const items = value?.map((item, index) => {
          const order = table?.current?.countablePreview({
            orderClass: item?.orderClass,
          })
            ? 'disposalOrder'
            : item?.orderClass?.toLocaleLowerCase() + 'Order';
          const data = {
            ...item,
            id: 'create' + Math?.random(),
            uuid: getUUID(),
            orderStatus: 'OPEN',
            orderText:
              order === 'disposalOrder'
                ? `${item?.orderText}(${value?.[0]?.orderText})`
                : item?.orderText,
            orderClass: item?.orderClass,
            performDepartment: item?.performDepartment,
            drugStorageId: item?.drugStorageId,
            ...(index === 0
              ? {
                  relatedType: 'SKIN_TEST_VS_DRUG',
                }
              : {
                  relatedType: 'SKIN_TEST_VS_DRUG',
                  skinTestType: null,
                }),
            [order]: {
              ...item,
              ...(order === 'disposalOrder'
                ? {
                    totalAmount: 1,
                  }
                : {
                    ...(index === 0
                      ? {}
                      : {
                          skinTestType: null,
                        }),
                    groupNumber: item?.groupNumber ? group + 1 : null,
                    groupSubNumber: item?.groupNumber
                      ? item?.groupSubNumber
                      : null,
                  }),
            },
          };
          return {
            ...convertOrderInField(data),
            ...convertOtherOrderInfo(data),
          };
        });

        table?.current?.setValue(
          target?.toSpliced(
            index,
            1,
            ...table?.current?.onSliced(
              convertOrderInTable(
                items?.map((item, _index) => ({
                  ...item,
                  ...(_index === 0
                    ? {
                        _skin: item?.id,
                        is_one: true,
                      }
                    : {
                        repeatIndicator: false,
                        // parentRelatedUuid: items?.[0]?.uuid,
                        parentRelatedUuid: items?.[0]?.uuid,
                      }),
                  /**
                   * 皮试关联关系
                   */
                  _skinTest: items?.map((item) => item?.id),
                })),
              ),
            ),
          ),
        );
        new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(table?.current?.startEdit?.(items?.[0]?.id));
            } catch (error) {
              reject(error);
            }
          }, 500);
        }).then(() => {
          table?.current
            ?.getEditForm()
            ?.getFieldInstance([items?.[0]?.id, 'drugOrder', 'dosage'])
            ?.focus();
        });
      }
    } else {
      if (index !== -1) {
        table?.current?.setValue(
          target?.toSpliced(index, 1, {
            ...target[index],
            ...value,
          }),
        );
      } else {
        table?.current?.setValue(
          convertOrderInTable([...target, { id, ...value }]),
        );
      }
    }
  }, []);

  const onRemove = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.OrderTemplateItemController_deleteOrderTemplateItem_02f761?.(
          {
            orderTemplateItemId: params?.id,
            deleteGroup: params?.deleteGroup ?? false,
          },
        );
      if (res?.code === 200) {
        table?.current?.setValue((value) => {
          return table?.current?.onSliced(
            value?.filter(
              (item, index) =>
                item?.id !== params?.id &&
                item?.parentRelatedUuid !== params?.uuid &&
                (params?.deleteGroup
                  ? !params?.groupIndex?.includes?.(index)
                  : true),
            ),
          );
        });
      }
      return res;
    },
    message: '删除套餐医嘱',
    success: () => {
      onReload();
    },
  });

  const onChange = (value) => {
    /**
     * 导入的成组组号需要重新赋值
     */
    const OLD_VALUE = table?.current?.getValue() ?? [];
    const LAST_GROUP_NUMBER = OLD_VALUE.filter(
      (item: any) => item?.drugOrder?.groupNumber,
    )?.map((item: any) => item?.drugOrder?.groupNumber);
    const GROUP_NUMBER = Math?.max(
      ...(new Set(
        LAST_GROUP_NUMBER?.length ? LAST_GROUP_NUMBER : [0],
      ) as unknown as any[]),
    );
    const STATIC = table?.current?.onSliced(value ?? []) ?? [];
    const GROUP: any =
      STATIC.filter(
        (item) => item?.groupName === 'aspirin-order-text-group-last',
      ) ?? [];
    const GROUP_VALUE = GROUP?.map((item) => {
      return value?.filter((...[, index]) => item?.groupIndex?.includes(index));
    });
    GROUP.forEach((...[, index]) => {
      const start = GROUP?.[index]?.groupIndex?.[0];
      const len = GROUP?.[index]?.groupIndex?.length;
      const NEW_GROUP_VALUE = GROUP_VALUE?.[index]?.map((item) => {
        return {
          ...item,
          drugOrder: {
            ...item?.drugOrder,
            groupNumber: GROUP_NUMBER + (index + 1),
          },
        };
      });
      value?.splice?.(start, len, ...NEW_GROUP_VALUE);
    });
    const list = (value ?? [])?.map?.((item) => {
      return {
        ...convertOrderInField(item),
        ...convertOtherOrderInfo(item),
        orderStatus: 'OPEN',
        uuid: getUUID(),
      };
    });
    table?.current?.setValue((value = []) =>
      table?.current?.onSliced([...value, ...list]),
    );
  };

  const on = {
    delete(_value) {
      const remove = () => {
        table?.current?.setValue((value) =>
          table?.current?.onSliced(
            value?.filter((item) => item?.id !== _value?.id),
          ),
        );
      };

      if (_value?.groupName) {
        Tip({
          title: '是否删除整组医嘱?',
          content: '你删除的医嘱附带成组数据,是否删除与当前医嘱成组的所有数据',
          onCancel() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              table?.current?.setValue((value) =>
                table?.current?.onSliced(
                  value?.filter(
                    (...[, index]) => !_value?.groupIndex?.includes?.(index),
                  ),
                ),
              );
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: true,
                groupIndex: _value?.groupIndex,
              });
            }
          },
          onOk() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              remove?.();
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: false,
              });
            }
          },
          okButtonProps: {
            danger: false,
            type: 'primary',
          },
          okText: '删除单条医嘱',
          cancelText: '删除整组医嘱',
        });
      } else if (!_value?.id || String(_value?.id)?.startsWith('create')) {
        remove?.();
      } else {
        Tip({
          content: `确定删除医嘱 {${_value?.orderText}} ?`,
          onOk() {
            onRemove?.(_value);
          },
        });
      }
    },
  };

  useUpdateEffect(() => {
    header?.current?.onReload?.();
  }, [outVisitInfo?.id]);

  useAsyncEffect(onReload, [onReload]);
  return (
    <BeIn.Provider value={onDrawerSubmit}>
      <Block
        footer={<Footer ref={footer} />}
        scroll={false}
        style={{
          padding: '0 0 1.6rem',
        }}
      >
        <OutHospitalTemplate.Header
          auxiliary={auxiliary}
          ref={header}
          table={table}
          eliminates={eliminates}
        />
        <OutHospitalTemplate
          auxiliary={auxiliary}
          ref={table}
          operation={on}
          header={header}
          footer={footer}
          agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        />
        <OutHospitalTemplate.Auxiliary
          ref={auxiliary}
          onChange={onChange}
          agreedPrescriptionIndicator={agreedPrescriptionIndicator}
        />
      </Block>
    </BeIn.Provider>
  );
};

export default definePage(OutHospitalTemplates);
