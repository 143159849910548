import vsf, { definePage } from '@vs/vsf-boot';
import { useEffect, useState } from 'react';
import {
  Button,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  List,
  Tooltip,
  Divider,
  message,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';

import InfoAndList from './info_and_list';
import { getWardPagedBySearchCode, list } from './service';
import './index.less';
import { filterEmptyValues } from '@/utils/setDataUtil';
import PatientComplexSelect from './PatientComplexSelect';
import { getScrollY, getScrollYRem, labelColWidth } from '@/utils';
const HerbPrescriptionDispense = (props) => {
  const [tabaleParam, setTableParam] = useState();
  const [onLeftLisrQureyParams, setLeftListQureyParams] = useState({
    label: '',
  });
  const [PatientInquire, setPatientInquire] = useState('');
  const { data, loading, run } = useRequest(list, {
    manual: true,
  });
  const { data: wardPaged, run: wardPagedRun } = useRequest(
    getWardPagedBySearchCode,
    {
      manual: true,
    },
  );
  // 监听左侧列表接口请求入参
  useEffect(() => {
    run(onLeftLisrQureyParams);
    wardPagedRun();
  }, []);
  useEffect(() => {
    let index = 0;
    if (!data?.[index]) return;
    setTableParam(data?.[index]);
    vsf?.refs?.PatientDetailsTable?.scrollTo?.(data?.[index]?.id);
  }, [data]);
  //查询按钮
  const qureyList = () => {
    setTableParam(undefined);
    const file =
      vsf?.refs?.centreDispensesMedicineSearchForm?.getFieldsValue(true);
    run({
      wardIdIs: file?.wardIdIs?.id == 'qb' ? '' : file?.wardIdIs?.id,
      patientIdIs: file?.patientId,
      label: file?.name,
    });
  };
  const onChangeWardIdIs = (params) => {
    // console.log(params);
    const param = {
      label: '',
    };
    vsf?.refs?.centreDispensesMedicineSearchForm?.resetFields();
    if (params?.id) {
      vsf?.refs?.centreDispensesMedicineSearchForm?.setFieldValue('wardIdIs', {
        id: params?.id,
        departmentName: params?.label,
      });
      param.wardIdIs = params?.id == 'qb' ? '' : params?.id;
    }
    setLeftListQureyParams(param);
  };
  return (
    <div className="centre-dispenses-medicine-layout common_lfc_style">
      <div className="left">
        <div style={{ width: '100%' }}>
          <Space size={8}>
            <VSForm
              id="centreDispensesMedicineSearchForm"
              style={{ marginBottom: '12px' }}
            >
              <VSFormLayout columnCount={1} labelCol={labelColWidth[5]}>
                <VSFormItem
                  name={['wardIdIs']}
                  label="病区"
                  valueType="selectCode"
                  dataSource={wardPaged}
                  fieldProps={{
                    fieldNames: { label: 'departmentName', value: 'id' },
                    defaultValue: '全部',
                    onChange: onChangeWardIdIs,
                    showSearch: true,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout columnCount={1} labelCol={labelColWidth[5]}>
                <VSFormItem
                  // style={{ width: '428px' }}
                  name={'patientIdIs'}
                  valueType="custom"
                  label="患者"
                  // fieldProps={{
                  //   onChange: (v) => {},
                  //   classNames: 'patientIdIs'
                  // }}
                  onClick={() => setPatientInquire('' + Math?.random())}
                >
                  <PatientComplexSelect
                    onChange={(callback) => callback()}
                    from={vsf?.refs?.centreDispensesMedicineSearchForm}
                    onLeftLisrQureyParams={onLeftLisrQureyParams}
                    setLeftListQureyParams={setLeftListQureyParams}
                    PatientInquire={PatientInquire}
                  />
                </VSFormItem>
              </VSFormLayout>

              <VSFormLayout columnCount={1}>
                <div style={{ textAlign: 'right' }}>
                  <Button type="primary" onClick={qureyList}>
                    查询
                  </Button>
                </div>
              </VSFormLayout>
            </VSForm>
          </Space>
          <Divider className="aspirin-divider-style" />
        </div>

        <div style={{ overflow: 'auto', flex: '1' }} className="list">
          <VSTable
            value={data}
            pagination={false}
            scroll={{
              x: 720,
              y: getScrollYRem(27.2),
            }}
            id="PatientDetailsTable"
            rowClassName={(node) => {
              return node?.id === tabaleParam?.id
                ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
                : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
            }}
            onRow={(node) => {
              return {
                onClick: () => {
                  if (node?.id === tabaleParam?.id) return setTableParam();
                  setTableParam(node);
                },
              };
            }}
          >
            <VSTableColumn
              width={126}
              dataIndex={['name']}
              valueType="text"
              title="姓名"
            />

            <VSTableColumn
              width={126}
              dataIndex={['bedLabel']}
              valueType="text"
              title="床号"
            />
            <VSTableColumn
              width={86}
              dataIndex={['herbType']}
              valueType="select"
              title="类型"
              dataSource={vsf.stores.dbenums.enums.CHINESE_MEDICINE_DICT}
            />
            <VSTableColumn
              width={126}
              dataIndex={['wardName']}
              valueType="text"
              title="病区"
            />
            <VSTableColumn
              dataIndex={['prescriptionDate']}
              valueType="text"
              title="日期"
            />
          </VSTable>
        </div>
      </div>
      <div className="right">
        <InfoAndList
          tabaleParam={tabaleParam}
          callback={run}
          qureyList={qureyList}
        />
      </div>
    </div>
  );
};

export default definePage(HerbPrescriptionDispense);
