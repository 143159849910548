import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Input,
  Tabs,
  TabsProps,
  Tooltip,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon1 from '@/module/Icon';
import Visible from '@/module/Visible';
import storage from '@/utils/storage';

import Main from '../main/index';
import AdmissionApplicationForm from './admission_application_form';
import ApplicationObservation from './application_observation';
import Checked from './checked';
import Examine from './examine';
import History from './history';
import HomePageInformation from './home_page_information';
import Info from './info';
import Medical from './medical';
import MedicalIframe from './medical/medical_iframe';
import PreviousHistory from './previous_history/';
import Reproduction from './reproduction';

const Index = (props) => {
  const { prefixCls, mainRef, pageType } = props;
  const { user } = props?.stores || {};
  const {
    hospitalType,
    outVisitInfo,
    inVisitInfo,
    outRegisterInfo,
    setOutVisitInfo,
    setOutRegisterInfo,
  } = user || {};

  const [currentKey, setCurrentKey] = useState<string>(
    hospitalType === 'in' ? 'order' : '2',
  );

  useEffect(() => {
    window.addEventListener('message', (e) => {
      // console.log(e);
      try {
        const { type, medicalRecordStatus } = JSON.parse(
          JSON.stringify(e?.data),
        );

        if (type === 'MedicalSaveSuccess' && medicalRecordStatus === 'SAVE') {
          handelItemClick({ key: 'order' });
        }
      } catch (x) {
        console.error(x);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentKey('2');
  }, [outRegisterInfo]);

  useEffect(() => {
    setCurrentKey(hospitalType === 'in' ? 'order' : '2');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalType]);

  useEffect(() => {
    if (!pageType) return;
    setCurrentKey(pageType);
  }, [pageType]);
  // 门诊
  const items = [
    {
      key: '2',
      label: '病历',
      icon: <Icon1 type="icon-bingli1" className="icon30" />,
      children: <MedicalIframe />,
    },
    {
      key: 'order',
      label: '医嘱',
      icon: <Icon type="icon-bingli" className="icon30" />,
      children: <Main prefixCls={prefixCls} ref={mainRef} />,
    },
    // {
    //   key: '5',
    //   label: '生殖病历',
    //   icon: <Icon type="icon-shengzhike" className="icon30" />,
    //   children: <Reproduction />,
    // },
    {
      key: '3',
      label: '检验报告',
      icon: <Icon type="icon-jianyan" className="icon30" />,
      children: <Examine />,
    },
    {
      key: '11',
      label: '检查报告',
      icon: <Icon type="icon-jiancha1" className="icon30" />,
      children: <Checked />,
    },
    {
      key: '4',
      label: '手术记录',
      icon: <Icon type="icon-Frame-5" className="icon34" />,
      children: <Empty desc="当前页面没有内容哦~" />,
    },
    {
      key: '8',
      label: '就诊史',
      icon: <Icon type="icon-jiuzhenshi" className="icon30" />,
      children: <History />,
    },
    {
      key: '9',
      label: '既往史',
      icon: <Icon type="icon-jiwangshi" className="icon30" />,
      children: <PreviousHistory />,
    },
    {
      key: '10',
      label: '外来资料',
      icon: <Icon type="icon-menzhenzhuyuanwailaiziliao" className="icon30" />,
      children: <Empty desc="当前页面没有内容哦~" />,
    },
    {
      key: 'zhuyuanshenqingdan',
      label: '住院申请单',
      icon: <Icon type="icon-zhuyuanshenqingdan" className="icon30" />,
      children: (
        <AdmissionApplicationForm
          prefixCls={prefixCls}
          currentKey={currentKey}
          ref={mainRef}
        />
      ),
    },
  ];
  // 住院
  const inItems = [
    {
      key: '1',
      label: '概览',
      icon: <Icon type="icon-zhuyuangaishu" className="icon30" />,
      children: <Info />,
    },
    {
      key: '2',
      label: '病历',
      icon: <Icon1 type="icon-bingli1" className="icon30" />,
      children: <MedicalIframe />,
    },
    {
      key: 'order',
      label: '医嘱',
      icon: <Icon type="icon-bingli" className="icon30" />,
      children: <Main prefixCls={prefixCls} ref={mainRef} />,
    },
    // {
    //   key: '5',
    //   label: '生殖病历',
    //   icon: <Icon type="icon-shengzhike" className="icon30" />,
    //   children: <Reproduction />,
    // },
    {
      key: 'report',
      label: '检验报告',
      icon: <Icon type="icon-jianyan" className="icon30" />,
      children: <Examine />,
    },
    {
      key: '11',
      label: '检查报告',
      icon: <Icon type="icon-jiancha1" className="icon30" />,
      children: <Checked />,
    },
    {
      key: '4',
      label: '手术记录',
      icon: <Icon type="icon-Frame-5" size={34} />,
      children: <Empty desc="当前页面没有内容哦~" />,
    },
    {
      key: '8',
      label: '就诊史',
      icon: <Icon type="icon-jiuzhenshi" className="icon30" />,
      children: <History />,
    },
    {
      key: 'history',
      label: '既往史',
      icon: <Icon type="icon-jiwangshi" className="icon30" />,
      children: <PreviousHistory />,
    },
    // {
    //   key: '10',
    //   label: '外来资料',
    //   icon: <Icon type="icon-menzhenzhuyuanwailaiziliao" className="icon30" />,
    //   children: <Empty desc="当前页面没有内容哦~" />,
    // },
    // {
    //   key: 'zhuyuanshenqingdan',
    //   label: '住院申请单',
    //   icon: <Icon type="icon-zhuyuanshenqingdan" className="icon30" />,
    //   children: (
    //     <AdmissionApplicationForm
    //       prefixCls={prefixCls}
    //       currentKey={currentKey}
    //       ref={mainRef}
    //     />
    //   ),
    // },
    {
      key: '10',
      label: '病案首页',
      icon: <Icon type="icon-binganshouye1" className="icon30" />,
      children: <HomePageInformation />,
    },
  ];
  // 急诊
  const erItems = [
    {
      key: '10',
      label: '留观申请单',
      icon: <Icon type="icon-liuguanshenqingdan" className="icon30" />,
      children: <ApplicationObservation />,
    },
  ];

  const handelItemClick = async (record) => {
    // if (record?.key === 'order' || record?.key === 'zhuyuanshenqingdan') {
    // if (outVisitInfo?.id) {
    //   setOutRegisterInfo(res?.data?.outpVisit);
    //   setOutVisitInfo(res?.data);
    //   }
    if (outVisitInfo?.id) return setCurrentKey(record?.key);
    if (hospitalType === 'out') {
      const res =
        await vsf.services?.OutpVisitEncounterController_getOutpVisitEncounterByOutpVisitId_b8997c?.(
          {
            outpVisitId: outRegisterInfo?.id,
          },
        );
      if (!res?.data) return;
      setOutRegisterInfo(res?.data?.outpVisit);
      setOutVisitInfo(res?.data);
    }
    // }
    setCurrentKey(record?.key);
  };

  const target =
    hospitalType === 'in'
      ? inItems
      : hospitalType === 'out'
      ? items
      : hospitalType === 'er'
      ? erItems
      : [];

  const targetContent = target?.find((item) => item?.key === currentKey);

  return (
    <div className={`${prefixCls}-leftContainer`}>
      <div className={`${prefixCls}-leftContainer-bg`} />
      <div className="tab-icon">
        <div className="tab-line" />
        <div className="tab-icon-container">
          {target?.map((item, index) => {
            return (
              <Tooltip
                title={
                  <span
                    style={{
                      color: '#3276E8',
                    }}
                  >
                    {item?.label}
                  </span>
                }
                color="#F1F6FF"
                key={index}
              >
                <div
                  className={classnames('tab-icon-block', {
                    'tab-icon-block-active': item?.key === currentKey,
                  })}
                  key={index}
                  onClick={() => handelItemClick(item)}
                >
                  {item?.icon}
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div
        style={{ padding: targetContent?.key == '10' ? '16px 0 0' : '' }}
        className={`main main-${currentKey}`}
      >
        {targetContent?.children}
        {/* {target?.map((item) => {
            return (
              <Visible key={item?.key} value={currentKey === item?.key}>
                {item?.children}
              </Visible>
            );
          })} */}
      </div>
    </div>
  );
};

export default definePage(Index);
