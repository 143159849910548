import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpRefundDispenseController_getDispenseForInpHerbRefundApplyByMasterIds_40e4cd:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-dispense-for-inp-herb-refund-apply-by-master-ids',
      parameterFomatter: (data?: { id: number[] }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionDispenseMasterForInpHerbRefundApplyVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  InpRefundDispenseController_performInpHerbRefund_5ad70a: {
    method: 'post',
    url: '/api/drug-prescription-dispense/perform-inp-herb-refund',
    parameterFomatter: (data?: {
      refunds: RefundApplyDetailEoDrugPrescriptionDispensePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
