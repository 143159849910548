import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientMainController_getIdentificationWithPatientByIdentificationNumbersAndClasses_c1bcd3:
    {
      method: 'post',
      url: '/api/patient-main/get-identification-with-patient-by-identification-numbers-and-classes',
      parameterFomatter: (data?: {
        patientIdentificationIdentificationNumberIdentificationClassEo: PatientIdentificationIdentificationNumberIdentificationClassEoPatientMainPersistEo[];
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: PatientIdentificationWithPatientVoPatientMainEntranceWebVo[],
      ) => data,
    },
  PatientMainController_getFullInfoById_6ee318: {
    method: 'post',
    url: '/api/patient-main/get-fullinfo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PatientFullVoPatientMainEntranceWebVo) =>
      data,
  },
});
