import vsf, { definePage } from '@vs/vsf-boot';
import dayjs from 'dayjs';
import { isNull, isUndefined, omitBy } from 'lodash';

export const labelColWidthRem = (width: string) => ({
  style: {
    width,
  },
});

export const getScrollY = (extraHeight: number) => {
  return `calc(100vh - ${extraHeight ?? 0}px)`;
};
export const timeHoursStemp = () => {
  const numberArray: number[] = [];
  for (let i = 0; i <= 24; i += 2) {
    numberArray.push(i);
  }
  const resultObject: { [key: number]: string } = {};
  numberArray.forEach((num) => {
    resultObject[num] = num.toString().padStart(2, '0');
  });
  return resultObject;
};
export const timeMinStemp = () => {
  const numberArray: number[] = [];
  for (let i = 0; i <= 60; i += 5) {
    numberArray.push(i);
  }
  const resultObject: { [key: number]: string } = {};
  numberArray.forEach((num) => {
    resultObject[num] = num.toString().padStart(2, '0');
  });
  return resultObject;
};

const labelStyle = (v) => {
  const base = document.documentElement?.clientWidth < 1600 ? 5 : 15;
  return {
    style: {
      width: base * (v - 1) + 30,
    },
  };
};

export const labelColWidth = {
  2: labelStyle(2),
  3: labelStyle(3),
  4: labelStyle(4),
  5: labelStyle(5),
  6: labelStyle(6),
  7: labelStyle(7),
  7.5: labelStyle(7.5),
  8: labelStyle(8),
  9: labelStyle(9),
  10: labelStyle(10),
  14: labelStyle(14),
  15: labelStyle(15),
  20: labelStyle(20),
};

export const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};

export const handleSex = (value, data) => {
  const _res = data?.filter((item) => {
    return item?.value == value;
  });
  if (_res?.length > 0) {
    return _res?.[0];
  } else {
    return '';
  }
};

export const removeObjectFalsy = (obj) => {
  return omitBy(obj, (value) => isUndefined(value) || isNull(value));
};

export const valueAndUnit = (value, unit) => {
  return value ? (unit ? `${value}${unit}` : value) : null;
};
export const handelEnums = (dataSource, value) => {
  const res = dataSource?.find((item) => {
    return item?.value == value;
  });
  return res?.label;
};

// 元素高度；
export const getPromiseHeight = (node) => {
  return new Promise((resolve, reject) => {
    let element;
    const timer = setInterval(() => {
      if (!element) {
        element =
          typeof node == 'string' ? document?.getElementById(node) : node;
        return;
      }
      resolve(element?.offsetHeight);
      clearInterval(timer);
    });
  });
};
//证件及编号处理；
export const getIdentification = (params) => {
  if (!params) return false;
  const { identificationClassIn, identificationNumberIn } = params;
  if (!identificationClassIn || !identificationNumberIn) return false;
  switch (identificationClassIn) {
    case '患者ID':
      return { displayIdIs: identificationNumberIn };
    // case '智慧医保':
    //   return { insuranceNumberIs: identificationNumberIn };
    default:
      return {
        identificationClassIn: [identificationClassIn],
        identificationNumberIn: [identificationNumberIn],
      };
  }
};
export const getEnumsLabel = (enumCode, type) => {
  return vsf.stores.dbenums?.enums?.[enumCode]?.find(({ code }) => type == code)
    ?.cname;
};
export const getScrollYRem = (extraHeight: number) => {
  return `calc(100vh - ${extraHeight ?? 0}rem)`;
};

// 适配宽度
export const labelNumberWidth = (n: number) => {
  return document.documentElement?.clientWidth < 1600 ? 12 * n : 15 * n;
};
