import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
