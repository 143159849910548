import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugPrescriptionRefundDispenseController_getAllByQueryPrescriptionRefundApplyToPerformQto_0f2bfa:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-all-by-query-prescription-refund-apply-to-perform-qto',
      parameterFomatter: (data?: {
        qto: QueryPrescriptionRefundApplyToPerformQtoDrugPrescriptionDispensePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugPrescriptionRefundApplyMasterDispensePatientStaffVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
});
