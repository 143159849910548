import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPerformController_getListOrderPerformSuspendWithStaffVoByRelatedPerformPlanId_be0f00:
    {
      method: 'post',
      url: '/api/clinic-record-order-perform/get-list-order-perform-suspend-with-staff-vo-by-related-perform-plan-id',
      parameterFomatter: (data?: { relatedPerformPlanId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderPerformSuspendWithStaffVoClinicRecordOrderPerformEntranceWebVo[],
      ) => data,
    },
});
