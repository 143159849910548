import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpExpenseListController_getInpExpenseListForSummary_99fc69: {
    method: 'post',
    url: '/api/inp-billing/get-inp-expense-list-for-summary',
    parameterFomatter: (data?: {
      qto: InpBillingDetailFullNewEntityQtoInpBillingPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailSummaryVoInpBillingEntranceWebVo[],
    ) => data,
  },
});
