import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message } from '@vs/vsf-kit';
import React, { Fragment, useEffect, useState } from 'react';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { useMount } from 'ahooks';
function RelationshipTree(props) {
  const [dataSource, setDataSource] = useState();
  useMount(() => {
    (async () => {
      const res =
        await vsf?.services?.DrugStorageController_getCandicateDrugPharmacyList_3ef044?.(
          {},
        );

      setDataSource(res?.data);
    })();
  });
  return (
    <TreeMenu
      title="药库"
      // vsid="74671"
      // id="PriceTree"
      // treeData={dataSource}
      loadData={async () => {
        const res =
          await vsf?.services?.DrugStorageController_getDrugStorageList_ec7299?.(
            {},
          );
        return res;
      }}
      fieldNames={{ title: 'departmentName', key: 'id', children: 'children' }}
      // fieldNames={{ title: 'departmentName', key: 'id'}}
      // dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
      // reload
      // headerRender={() => {
      //   return <Button onClick={() => props.onAdd({
      //     // serialNumber: dataSource.at(-1) + 1,
      //   })}>新增</Button>
      // }}
      // row={{
      //   extraShow: 'select',
      //   expand: true,
      //   extra: (node) => {
      //     return (
      //       <div className="aspirin-tree-button">
      //         {
      //           node.treeLevel === 0 ? (
      //             <Icon
      //               type="icon-tianjia"
      //               onClick={(event) => {
      //                 event.stopPropagation();
      //                 props.onAdd({
      //                   // serialNumber: dataSource.at(-1) + 1,
      //                   ...node,
      //                 })
      //               }}
      //               size={20}
      //             />) : (
      //             <Icon type="icon-bianji" size={20} onClick={(event) => {
      //               event.stopPropagation();
      //               props.onEdit(node)
      //             }} />
      //           )
      //         }
      //         {
      //           <Icon type="icon-tingyong" size={20} onClick={(event) => {
      //             event.stopPropagation();
      //             onStop(node)
      //           }} />
      //         }
      //       </div>
      //     )
      //   },
      // }}
      onSelect={(_value) => {
        props.onChange?.(_value);
      }}
    />
  );
}

export default RelationshipTree;
