import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedConfigController_queryByBedClassVsChargeQtoPaged_dae380: {
    method: 'post',
    url: '/api/clinic-bed-config/query-by-bed-class-vs-charge-qto-paged',
    parameterFomatter: (data?: {
      qto: BedClassVsChargeQtoClinicBedPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BedClassVsChargeDetailVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedConfigController_saveBedClassVsCharge_8bd901: {
    method: 'post',
    url: '/api/clinic-bed-config/save-bed-class-vs-charge',
    parameterFomatter: (data?: {
      btoParam: SaveBedClassVsChargeBtoClinicBedServiceBto;
      extApiParam: {
        deprecateOperation: boolean;
      };
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicBedConfigController_getBedClassVsChargeDetailVoById_6d8e13: {
    method: 'post',
    url: '/api/clinic-bed-config/get-bed-class-vs-charge-detail-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BedClassVsChargeDetailVoClinicBedEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27: {
    method: 'post',
    url: '/api/base-dictionary/query-all-by-base-code-index-qto',
    parameterFomatter: (data?: {
      qto: BaseCodeIndexQtoBaseDictionaryPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: BaseCodeVoBaseDictionaryEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
