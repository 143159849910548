import { createServices } from '@vs/vsf-boot';
export default createServices({
  PriceListDtoController_getAllByPriceItemQueryQto_273108: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-item-query-qto',
    parameterFomatter: (data?: { qto: PriceItemQueryQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListBOController_createPriceItem_f7480e: {
    method: 'post',
    url: '/api/price-list/create-price-item',
    parameterFomatter: (data?: {
      btoParam: CreatePriceItemBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  PriceListBOController_updatePriceItem_a055ad: {
    method: 'post',
    url: '/api/price-list/update-price-item',
    parameterFomatter: (data?: {
      btoParam: UpdatePriceItemBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  PriceListDtoController_getAllByPriceItemReviewNewQtoByPriceItemReviewNewQto_f9d7e6:
    {
      method: 'post',
      url: '/api/price-list/get-all-by-price-item-review-new-qto-by-price-item-review-new-qto',
      parameterFomatter: (data?: {
        qto: PriceItemReviewNewQtoPriceListPersistQto;
      }) => data,
      responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
        data,
    },
  PriceListDtoController_getAllByPriceItemQueryNewQtoByPriceItemQueryNewQto_43676c:
    {
      method: 'post',
      url: '/api/price-list/get-all-by-price-item-query-new-qto-by-price-item-query-new-qto',
      parameterFomatter: (data?: {
        qto: PriceItemQueryNewQtoPriceListPersistQto;
      }) => data,
      responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
        data,
    },
});
