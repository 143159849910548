import {
  Section,
  Column,
  Columns,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTableAddition,
  VSTableToolbar,
  Tabs,
  TabPane,
  VSTableAction,
  Table,
  Modal,
  message,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { TreeMenu } from '@/components';
import { getScrollY, getScrollYRem, labelNumberWidth } from '@/utils';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import ComplexSelect from '@/pages/Index/components/complex_select';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import CustomSelect from '../drugDicEditor/CustomSelect';
import DrugDicEditor from '../drugDicEditor';
import DrugProductExtensionManage from './drugProductExtensionManage';
import DurgAliasManage from './durgAliasManage';
import DurgManage from './durgManage';
import ProductDefaultUsageEditor from './productDefaultUsageEditor';
import ProductDefaultUsageRoot from './ProductDefaultUsageRoot';

import './reset.less';
import { Screen } from '@/pages/Index/components';
import { CreateScreen } from '@/pages/Index/components/screen';
import { isMinScreen } from '@/config';
import '@/style/adaptation.less';

const DrugDicManager = (props) => {
  const [activeTabsKey, setActiveTabsKey] = useState('table');
  const [currentItem, setCurrentItem] = useState();

  const [currentMenu, setCurrentMenu] = useState();
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [defaultUsageVisible, setDefaultUsageVisible] = useState(false);
  const [extensionManageVisible, setExtensionManageVisible] = useState(false);
  const doubleClickCount = useRef(0);
  const onScreen = useContext(CreateScreen);

  // const [current, setCurrent] = useState(0);
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(30);
  const page = useRef(0);
  const pageSize = useRef(30);

  const onFetch = useCallback(
    async (params) => {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res = await vsf.services?.DrugController_queryProductList_3e7233?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          // from: page?.current,
          // size: pageSize?.current,
          from: params.pagination?.from ?? 0,
          size: params.pagination?.size ?? 20,
          orderList: params.orderList,
          classCodeIn: currentMenu
            ? currentMenu?.children?.length
              ? currentMenu?.children?.map((item) => item?.code)
              : [currentMenu?.code]
            : undefined,
          ...params.search,
          ...params.filters,
          ...(params?.extra ?? {}),
          ...(params ?? {}),
          drugIdIs:
            params?.search?.drugIdIs?.drug?.id ??
            params?.search?.drugIdIs?.key ??
            undefined,
        },
        ext: {},
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // console.log(res?.data);
      // console.log(res?.data, '7213_onFetch');
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    // [current, page, pageSize, currentMenu],
    [],
  );

  const handelAddClick = () => {
    setCurrentItem(undefined);
    setActiveTabsKey('form');
  };

  const handelEditClick = (_, record) => {
    setCurrentItem(record);
    setActiveTabsKey('form');
  };

  // 删除
  const handelRemove = (record) => {
    Modal.confirm({
      title: '提示',
      centered: true,
      content: '是否确认删除?',
      onOk: async () => {
        // console.log(record?.drug, 'record_drug');
        if (!!record?.drug?.drugProductId) {
          return Modal.error({
            title: '警告',
            content: '当前选中商品存在业务数据,不允许删除!',
          });
        }

        // const res =
        //   await vsf?.services?.DrugController_deleteDrugDictionary_d5ed98?.({
        //     drugId: record?.drug?.id,
        //   });
        const res =
          await vsf?.services?.DrugController_deleteDrugProduct_8ced4d?.({
            btoParam: {
              id: record?.id,
              drugProductBtoList: [{ id: record?.drug?.id }],
            },
          });

        vsf?.refs?.DrugListTable?.reload?.();
        if (res?.code == 200) {
          message.success('删除成功');
        }
        // return res?.code === 200;
      },
    });
  };

  const Selected = ({ recode, onChange, form, value, placeholder }) => {
    return (
      <CustomSelect
        allowClear
        isDictionary
        placeholder={placeholder}
        onChange={onChange}
        // value={value}
      />
    );
  };

  const handelRenameSubmit = async () => {
    const drugNameDictionaryBtoList =
      await vsf?.refs?.DurgAliasManage?.getValues();
    const response =
      await vsf?.services?.DrugController_addOrUpdateDrugNameAlias_b2a389?.({
        bto: {
          id: currentItem?.drug?.id,
          drugNameDictionaryBtoList: drugNameDictionaryBtoList?.map((item) => ({
            ...item,
            index: undefined,
            id:
              typeof item?.id === 'string' && item?.id?.includes('create_')
                ? undefined
                : item?.id,
          })),
        },
      });
    if (response && response.code === 200) {
      setRenameModalVisible(false);
    }
  };

  // 药品默认用法维护保存
  const handelDefaultSubmit = async () => {
    try {
      const drugProductUsageBtoList =
        await vsf?.refs?.ProductDefaultUsageEditor?.getValues();
      const drugProductDepartmentUsageBtoList =
        await vsf?.refs?.ProductDepartmentUsageEditor?.getValues();
      let defaultResponse, departmentResponse;

      console.log(drugProductUsageBtoList, 'drug_drugProductUsageBtoList');
      console.log(
        drugProductDepartmentUsageBtoList,
        'drug_drugProductDepartmentUsageBtoList',
      );
      // console.log(
      //   drugProductDepartmentUsageBtoList,
      //   'drug_drugProductDepartmentUsageBtoList',
      // );

      if (drugProductUsageBtoList && drugProductUsageBtoList?.length) {
        console.log('drugProductUsageBtoList 存在且不等于0');

        defaultResponse =
          await vsf?.services?.DrugController_updateOrCreateDrugUsageByProductId_48b45e?.(
            {
              bto: {
                id: currentItem?.drug?.id,
                drugProductBtoList: [
                  {
                    id: currentItem?.id,
                    drugProductUsageBtoList: drugProductUsageBtoList?.map(
                      (item) => {
                        // console.log(item,'btoList_item')
                        return {
                          ...item,
                          index: undefined,
                          id:
                            typeof item.id === 'string' &&
                            item?.id?.includes('create_')
                              ? undefined
                              : item?.id,
                          attribute: undefined,
                        };
                      },
                    ),
                  },
                ],
              },
            },
          );
      }

      // 科室添加
      if (
        drugProductDepartmentUsageBtoList &&
        drugProductDepartmentUsageBtoList?.length
      ) {
        console.log('drugProductDepartmentUsageBtoList 存在且不等于0');
        // console.log(
        //   drugProductDepartmentUsageBtoList,
        //   'btoList_drugProductDepartmentUsageBtoList ',
        // );
        departmentResponse =
          await vsf.services?.DrugController_createOrUpdateDrugProductDepartmentUsage_e60de7?.(
            {
              bto: {
                id: currentItem?.drug?.id,
                drugProductBtoList: [
                  {
                    id: currentItem?.id,
                    drugProductDepartmentUsageBtoList:
                      drugProductDepartmentUsageBtoList?.map((item) => {
                        console.log(item, 'item');
                        return {
                          ...item,
                          index: undefined,
                          id:
                            typeof item.id === 'string' &&
                            item?.id?.includes('create_')
                              ? undefined
                              : item?.id,
                          attribute: item.attribute,
                          erpIndicator: null,
                          inpIndicator: null,
                          departmentId: item.department.id,
                        };
                      }),
                  },
                ],
              },
            },
          );
      }

      if (defaultResponse?.code === 200 || departmentResponse?.code === 200) {
        setDefaultUsageVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelExtensionManageSubmit = async () => {
    try {
      const formParam =
        await vsf?.refs?.DrugProductExtensionManage?.validateFieldsReturnFormatValue();
      const res =
        await vsf?.services?.DrugController_updateDrugProductExtension_0df600?.(
          {
            btoParam: {
              id: currentItem?.drug?.id,
              drugProductBtoList: [
                {
                  id: currentItem?.id,
                  drugProductExtensionBto: {
                    ...formParam,
                  },
                },
              ],
            },
          },
        );
      if (res && res.code === 200) {
        setExtensionManageVisible(false);
      }
      return res?.data; // todo 整理返回数据格式
    } catch (error) {
      console.error(error);
    }
  };

  const handelRowClick = (data) => {
    doubleClickCount.current = doubleClickCount.current + 1;
    setTimeout(() => {
      doubleClickCount.current = 0;
    }, 300);
    // setCurrentItem(data);
    if (doubleClickCount.current === 2) {
      handelEditClick(_, data);
    }
    // handelEditClick(_, data)
  };

  const handelResetClick = () => {
    setCurrentMenu(undefined);
    vsf?.refs?.DrugListTable?.getSearchForm()?.resetFields();
    if (!currentMenu) {
      vsf?.refs?.DrugListTable?.reload();
    }
  };

  const button = [
    {
      text: '新增',
      ordinaryKey: 'N',
      methodKey: 'altKey',
      disabled: false,
      onClick: handelAddClick,
    },
    {
      text: '清屏',
      ordinaryKey: 'L',
      methodKey: 'altKey',
      disabled: false,
      onClick: onScreen,
    },
    {
      text: '导出',
      ordinaryKey: 'E',
      methodKey: 'altKey',
      disabled: false,
      onClick: () => {},
    },
    // {
    //   text: '关闭',
    //   ordinaryKey: 'C',
    //   methodKey: 'altKey',
    //   disabled: false,
    //   onClick: () => { }
    // },
  ];

  const footer = () => {
    return (
      <div className="aspirin-table-footer-button">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            // block: isMinScreen ? '192px' : '392px',
            block: '20%',
          },
          {
            block: 1,
          },
        ],
      }}
      className="drug_dictionary_wrapper"
    >
      {/*
      <Todo
        type="block"
        content="药品分类编码来自搜索接口"
        onService={async (params) => {
          const res =
            await vsf?.services?.DrugController_queryDrugNameDictionary_32035a?.(
              {
                qto: {
                  from: params.pagination?.from ?? 0,
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList, // 排序结果
                  ...params.search, // 搜索结果
                  ...params.filters, // 过滤器结果
                  ...(params?.extra ?? {}), // 传入的额外参数
                },
                ext: params?.ext,
              },
            );
          return res?.data; // todo 整理返回数据格式
        }}
      /> */}

      <TreeMenu
        title="药理分类"
        treeProps={{
          defaultExpandAll: true,
          selectedKeys: [currentMenu]
            ?.filter((item) => item)
            ?.map((item) => item.id),
        }}
        loadData={async (params) => {
          const res =
            await vsf?.services?.BaseCodeDtoServiceController_getByCodeType_5f9695?.(
              { codeType: 'DRUG_CLASS_DICT' },
            );
          return res; // todo 整理返回数据格式
        }}
        fieldNames={{ title: 'name', key: 'id', children: 'children' }}
        dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
        onSelect={(record) => {
          if (record) {
            setCurrentMenu(record);
          }
        }}
      />
      <Tabs
        activeKey={activeTabsKey}
        onChange={(_v) => {
          setActiveTabsKey(_v);
        }}
        style={{
          background: '#fff',
          boxSizing: 'border-box',
          padding: '0 1.6rem',
          // borderBottom: '1px solid #3276E8'
        }}
      >
        <TabPane key="table" tab="药品查询">
          <Modal
            width={isMinScreen ? 800 : 1368}
            okText="保存"
            cancelText="取消"
            bodyStyle={{
              padding: '0 36px',
              height: isMinScreen ? 400 : 604,
              overflow: 'scroll',
            }}
            title="药品别名维护"
            open={renameModalVisible}
            onCancel={() => setRenameModalVisible(false)}
            onOk={handelRenameSubmit}
          >
            <DurgAliasManage id="DurgAliasManage" drugInfo={currentItem} />
          </Modal>
          <Modal
            width={isMinScreen ? 800 : 1368}
            wrapClassName="product_default_usage_root_wrapper"
            okText="保存"
            cancelText="取消"
            bodyStyle={{
              padding: '0 36px',
              height: isMinScreen ? 400 : 604,
              overflow: 'scroll',
            }}
            title="药品默认用法维护"
            open={defaultUsageVisible}
            onCancel={() => setDefaultUsageVisible(false)}
            onOk={handelDefaultSubmit}
          >
            <ProductDefaultUsageRoot drugInfo={currentItem} />
          </Modal>
          <Modal
            okText="保存"
            cancelText="取消"
            // width={isMinScreen ? 800 : 1368}
            width="91.2rem"
            bodyStyle={{
              // height: isMinScreen ? 400 : 604,
              height: '40.26rem',
              overflow: 'scroll',
              background: '#F4F5F7',
            }}
            title="药品拓展属性"
            open={extensionManageVisible}
            onCancel={() => setExtensionManageVisible(false)}
            onOk={handelExtensionManageSubmit}
          >
            <DrugProductExtensionManage drugInfo={currentItem} />
          </Modal>
          <Block footer={footer()}>
            <VSPromiseTable
              id="DrugListTable"
              form={{
                labelAlign: 'left',
              }}
              scroll={{
                // x: '80rem',
                // y: '27.8rem'
                y: getScrollYRem(42.5),
              }}
              // className='aspirin-table aspirin-table-nopadding'isMinScreen ? 270 : 400
              editable={{
                editType: 'single',
                columnProps: { hideInTable: true },
              }}
              vsid="62700"
              rowClassName={(record) =>
                record?.id === currentItem?.id
                  ? 'aspirin-table-row-select-background-color'
                  : ''
              }
              rowClick={{
                rowClickType: {
                  action: 'click',
                },
                onRowClick: handelRowClick,
              }}
              searchConfig={{
                resetButtonProps: {
                  onClick: handelResetClick,
                },
              }}
              onFetch={onFetch}
              // pagination={{
              //   defaultPageSize: pageSize.current,
              //   // defaultCurrent: current,
              //   pageSizeOptions: [10, 20, 50, 100],
              //   onChange: (n, pageSize) => {
              //     page.current = n;
              //     // console.log(page, 'page');
              //   },
              //   onShowSizeChange: (current, size) => {
              //     // console.log(size, 'size');
              //     pageSize.current = size;
              //   },
              // }}
            >
              <VSTableToolbar title="药品列表">
                <VSTableAddition children="新增" onClick={handelAddClick} />
              </VSTableToolbar>

              <VSTableColumn
                width="7.2rem"
                title="序号"
                // render={(_, __, index) => index + 1}
                valueType="index"
              />

              <VSTableColumn
                dataIndex={['drug', 'id']}
                title="药品名称"
                hideInTable={true}
                valueType="select"
                search
                order={-68}
                columnKey={'drugIdIs'}
                renderFormItem={(...arr) => {
                  const [schema, recode, form] = arr;
                  return (
                    <Selected
                      recode={recode}
                      form={form}
                      onChange={(v) => {
                        schema.originProps.onChange?.(v);
                      }}
                      placeholder="请输入药品名称"
                    />
                  );
                }}
              />

              <VSTableColumn
                // width="18.13rem"
                width="24.13rem"
                ellipsis
                dataIndex={['drug', 'drugName']}
                title="通用名"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="24.13rem"
                dataIndex={['drugProductName']}
                ellipsis
                title="商品名"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 100,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drugSpecificationDictionary', 'dosagePerUnit']}
                title="单位含量"
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drugSpecificationDictionary', 'dosageUnit']}
                title="含量单位"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 8,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drugSpecificationDictionary', 'drugSpecification']}
                title="最小规格"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 50,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="4.8rem"
                dataIndex={['drugSpecificationDictionary', 'unit']}
                title="单位"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 8,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="18.13rem"
                dataIndex={['firmId']}
                title="厂家"
                valueType="text"
                ellipsis
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['retailPrice']}
                title="零售价格"
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                width="8.8rem"
                dataIndex={['formCode']}
                ellipsis
                title="剂型"
                valueType="select"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ],
                }}
                fieldProps={{}}
                dataSource={vsf.stores.dbenums?.enums?.DRUG_FORM_DICT}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['ddd']}
                title="DDD计算值"
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['centralPurchaseIndicator']}
                title="是否带量"
                valueType="switch"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['baseDrugClass']}
                title="是否基药"
                valueType="switch"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['insuranceNationCode']}
                title="国家编码"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 40,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="10.26rem"
                dataIndex={['selfCode']}
                title="老系统自编码"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ],
                }}
                fieldProps={{}}
              />

              <VSTableColumn
                width="6rem"
                dataIndex={['stopIndicator']}
                title="使用状态"
                valueType="select"
                search
                order={-69}
                columnKey={'stopIndicatorIs'}
                fieldProps={{
                  dataSource: [
                    {
                      label: '启用',
                      value: false,
                    },
                    {
                      label: '停用',
                      value: true,
                    },
                  ],
                }}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['maxDosage']}
                title="最高用量"
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drugSpecificationDictionary', 'dosageUnit']}
                title="用量单位"
                valueType="text"
                fieldProps={{}}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drug', 'antibioticTypeCode']}
                title="抗菌药级别"
                hideInTable={true}
                valueType="select"
                search
                order={-68}
                columnKey={'antibioticTypeCodeIs'}
                dataSource={vsf.stores.dbenums.enums.DRUG_ANTIBIOTIC_TYPE_DICT}
                fieldProps={{}}
                formItemProps={{
                  labelCol: {
                    style: {
                      width: '7rem',
                    },
                  },
                }}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drug', 'coldIndicator']}
                title="冷藏药标志"
                hideInTable={true}
                valueType="check"
                search
                order={-100}
                columnKey={'coldIndicatorIs'}
                fieldProps={{}}
                formItemProps={{
                  labelCol: {
                    style: {
                      width: '7rem',
                    },
                  },
                }}
              />

              <VSTableColumn
                width="7.2rem"
                dataIndex={['drug', 'skinTestType']}
                title="皮试属性"
                hideInTable={true}
                valueType="select"
                search
                order={-69}
                columnKey={'skinTestTypeIs'}
                dataSource={[
                  { label: '强制皮试', value: 'FORCE' },
                  { label: '建议皮试', value: 'ADVISE' },
                ]}
                fieldProps={{}}
              />

              <VSTableColumn
                // width='7.2rem'
                dataIndex={['drug', 'dangerIndicator']}
                title="是否高危药"
                hideInTable={true}
                valueType="check"
                search
                order={-71}
                columnKey={'dangerIndicatorIs'}
                fieldProps={{}}
                formItemProps={{
                  labelCol: {
                    style: {
                      width: '7rem',
                    },
                  },
                }}
              />
              <VSTableColumn
                dataIndex={['id']}
                title="主键"
                hideInTable={true}
                valueType="digit"
                fieldProps={{}}
              />

              <VSTableColumn
                width="4.8rem"
                title="别名"
                render={(_, record) => {
                  return (
                    <Icon
                      type="icon-bianji"
                      onClick={() => {
                        setCurrentItem(record);
                        setRenameModalVisible(true);
                      }}
                    />
                  );
                }}
              />
              <VSTableColumn
                width="7.2rem"
                title="默认用量"
                render={(_, record) => {
                  return (
                    <Icon
                      type="icon-bianji"
                      onClick={() => {
                        setCurrentItem(record);
                        setDefaultUsageVisible(true);
                      }}
                    />
                  );
                }}
              />
              <VSTableColumn
                width="7.2rem"
                title="其他属性"
                render={(_, record) => {
                  return (
                    <Icon
                      type="icon-bianji"
                      onClick={() => {
                        setCurrentItem(record);
                        setExtensionManageVisible(true);
                      }}
                    />
                  );
                }}
              />

              <VSTableColumn
                title="操作"
                valueType="option"
                name={'option'}
                width={130}
              >
                <VSTableAction onClick={handelEditClick}>
                  <Icon type="icon-bianji" />
                </VSTableAction>
                <VSTableAction
                  disableLoading
                  onClick={(_, record) => handelRemove(record)}
                >
                  <Icon type="icon-shanchu1" />
                </VSTableAction>
              </VSTableColumn>
            </VSPromiseTable>
          </Block>
        </TabPane>
        <TabPane key="form" tab="药品维护">
          <DrugDicEditor drugInfo={currentMenu} drugItem={currentItem} />
        </TabPane>
      </Tabs>
    </Container>
  );
};

const DrugDicManagerScreen = (props) => {
  return (
    <Screen>
      <DrugDicManager {...props} />
    </Screen>
  );
};

export default definePage(DrugDicManagerScreen);
