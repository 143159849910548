import vsf, { definePage } from '@vs/vsf-boot';
import {
  compose,
  Form,
  InputProps,
  Select,
  SelectProps,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { join } from 'lodash';
import React, { useImperativeHandle, useRef } from 'react';

import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import { user } from '@/stores';

import { SelectCode } from '..';
import { useInputEnter } from '../dosage/utils';

/**
 * 住院医嘱途径选择
 */
let FrequencySelect: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const refs = useRef<any>(null);
  const form = Form.useFormInstance();
  // useImperativeHandle(ref, () => {
  //   return {
  //     ...(refs?.current ?? {}),
  //     focus: () => {
  //       refs?.current?.focus?.();
  //     },
  //   };
  // });
  return (
    <Select
      {...props}
      value={value}
      showSearch
      ref={ref}
      onChange={onChange}
      {...fieldEnterProps}
      {...rest}
      dataSource={(option) => {
        const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
          (item) => {
            return {
              ...item,
              ...JSON?.parse(item?.extensionJson ?? '{}'),
            };
          },
        );
        const state = option?.frequency
          ? value?.filter((item) =>
              askSearch(option?.frequency, item, 'frequency'),
            )
          : value;
        return state;
      }}
    />
  );
};

FrequencySelect.displayName = 'frequencySelect';

FrequencySelect = React.forwardRef(FrequencySelect);

const withPreviewComponent = definePage((props: any) => {
  const { orderClass } = props?.record || {};
  const target = orderClass
    ? orderClass === 'DRUG'
      ? vsf.stores.dbenums.enums.ADMINISTRATION_DICT
      : vsf.stores.dbenums.enums.MEDICINE_USAGE_DICT
    : [];
  // console.log(props?.value?.value ?? props?.value);
  return (
    <>
      {
        target?.find(
          (item: any) => item?.value === (props?.value?.value ?? props?.value),
        )?.label
      }
    </>
  );
});

export default compose(
  withRef(),
  withField<any>({
    name: 'frequencySelect',
    handleEnter: true,
    // shouldFieldUpdate: () => true,
  }),
  withPreview<
    SelectProps & { value: { unit: string; value: string | number } }
  >({
    renderPreview: withPreviewComponent,
  }),
)(FrequencySelect) as typeof FrequencySelect;
