import { debounce } from 'lodash';
import { useState } from 'react';

import { Table } from '@vs/vsf-kit';
import ComplexSelect from '@/pages/Index/components/complex_select';
import vsf, { definePage } from '@vs/vsf-boot';

const CustomSelect = ({
  value,
  allowClear,
  onChange,
  handelDrugNameSelectChange,
  placeholder,
  addItemOptions,
  isDictionary = false,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const handelFormSearch = async (v) => {
    try {
      if (v) {
        setLoading(true);
        const response =
          await vsf?.services?.DrugController_queryDrugNameDictionary_32035a?.({
            input: v,
          });

        const data = response?.data ?? [];
        setLoading(false);
        if (data && data?.length) {
          setDataSource(
            data?.map((item) => ({
              ...item,
              key: item?.drug?.id,
              rowKey: Math.random(),
            })),
          );
        } else {
          if (!isDictionary) {
            setDataSource([
              {
                drugName: v,
                key: Math.random(),
                rowKey: Math.random(),
              },
            ]);
          } else {
            setDataSource([]);
          }
        }
        // return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <ComplexSelect
      mode="radio"
      allowClear={allowClear}
      value={value}
      showLabel="drugName"
      hideSelectIcon
      dropdownMatchSelectWidth={false}
      addItemOptions={addItemOptions}
      onSearch={handelFormSearch}
      dropdownStyle={{
        width: 400,
      }}
      onChange={(v) => {
        handelDrugNameSelectChange?.(v);
        onChange(v);
      }}
      placeholder={placeholder}
    >
      <Table
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        rowClassName="aspirin-table-row-box"
        rowKey={'rowKey'}
        columns={[
          {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: '名称',
            dataIndex: 'drugName',
            key: 'drugName',
          },
        ]}
      />
    </ComplexSelect>
  );
};

export default CustomSelect;
