import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { NewDrawer } from '@/pages/Index/components';
import Tip from '@/pages/Index/components/func/Tip';

import {
  deleteSavePurchase,
  getHerbSavePurchasePlanMasterList,
} from './service';

const Index = (props) => {
  const [open, setOpen] = useState<any>(true);
  const onFetch = useCallback(async (params) => {
    const res = getHerbSavePurchasePlanMasterList({
      ...params,
    });
    return res;
  }, []);
  useEffect(() => {
    open && vsf?.refs?.tableSavedHerbPurchasePlanList?.reload();
  }, [open]);
  return (
    <NewDrawer
      open={open}
      width="40%"
      onOpen={() => setOpen(!open)}
      title={<div style={{ color: '#3276E8' }}>已保存未提交采购计划单</div>}
      buttonTitle="采购计划单"
      // backgroundColor="var(--blue)"
      footer={
        <div
          className="aspirin-table-footer-button"
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            type="primary"
            onClick={() => vsf?.refs?.tableSavedHerbPurchasePlanList?.reload()}
          >
            刷新
          </Button>
          <Button
            type="primary"
            disabled={!props?.orderProps?.id}
            onClick={async () => {
              Tip({
                title: '是否删除当前选中的单据？',
                content: `采购计划单号：${props?.orderProps?.purchasePlanNumber}`,
                onOk: async () => {
                  const res = await deleteSavePurchase(props?.orderProps?.id);
                  if (res?.code != 200) return;
                  props?.setOrderProps?.();
                  message?.success('单据删除成功');
                  vsf?.refs?.tableSavedHerbPurchasePlanList?.reload();
                },
              });
            }}
          >
            删除单据
          </Button>
        </div>
      }
      buttonStyle={{
        top: '18%',
      }}
      drift="98px"
      style={{
        top: 104,
      }}
      isOther
    >
      <VSPromiseTable
        id="tableSavedHerbPurchasePlanList"
        onFetch={onFetch}
        pagination={false}
        onRow={(node) => {
          return {
            onClick: () => {
              if (node?.id == props?.orderProps?.id)
                return props?.setOrderProps('');
              props?.setOrderProps(node);
            },
            onDoubleClick: () => {
              props?.setOrderProps(node);
              setOpen(!open);
            },
          };
        }}
        rowClassName={(node) => {
          return node?.id === props?.orderProps?.id
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
            : 'aspirin-vs-table-row-box aspirin-row-select-box';
        }}
      >
        <VSTableColumn
          dataIndex={['purchasePlanNumber']}
          title="采购计划单号"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['planCreateDate']}
          title="生成日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['herbType']}
          title="中药类型"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.CHINESE_MEDICINE_DICT}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};
export default definePage(Index);
