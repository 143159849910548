import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  Drawer,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Steps,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

export type MyStepsDataSourceProps = {
  // 标题
  title?: string;
  // 唯一值
  value?: string | number;
};

export type MyStepsProps = {
  // 配置选项内容
  dataSource?: MyStepsDataSourceProps[];
  // 当前选中
  current?: string | number;
  // 线两边的宽度
  lineWidth?: string;
};

const Index = (props) => {
  const { lineWidth = 120, dataSource, current } = props;

  // current在dataSource的索引
  const handelFinished = () => {
    const res = dataSource?.findIndex((item, index) => item?.value === current);

    return res;
  };

  return (
    <div className="mySteps">
      <div className="mySteps_step">
        <div
          style={{ width: lineWidth }}
          className="mySteps_step_line mySteps_step_line_left"
        ></div>
        {dataSource?.map((item, index) => {
          return (
            <>
              <div
                className="mySteps_step_centerline"
                style={{
                  width: 2 * lineWidth,
                  display: index == 0 ? 'none' : '',
                }}
              ></div>
              <div
                className="mySteps_step_line_icon"
                // style={{ marginBottom: index <= handelFinished() ? 4 : 0 }}
              >
                {index <= handelFinished() ? (
                  <Icon type="icon-a-tishichenggong" size={16} />
                ) : (
                  <Icon
                    type="icon-a-Ellipse17"
                    size={16}
                    style={{ color: '#D6D8D9 ' }}
                  />
                )}
              </div>
            </>
          );
        })}
        <div
          className="mySteps_step_line mySteps_step_line_right"
          style={{ width: lineWidth }}
        ></div>
      </div>
      <div className="mySteps_content">
        {dataSource?.map((item, index) => {
          return (
            <div
              style={{ width: lineWidth * 2 }}
              className={cn(
                // 'mySteps_content_unfinished',
                index <= handelFinished()
                  ? 'mySteps_content_finished'
                  : 'mySteps_content_unfinished',
              )}
              key={item?.value}
            >
              {item?.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
