import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterController_getClinicRegisterScheduleWithDetailById_ab9458: {
    method: 'post',
    url: '/api/outp-register/get-clinic-register-schedule-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterScheduleWithDetailVoOutpRegisterEntranceWebVo,
    ) => data,
  },
  OutpRegisterController_createUpdateClinicRegisterSchedule_e56057: {
    method: 'post',
    url: '/api/outp-register/create-update-clinic-register-schedule',
    parameterFomatter: (data?: {
      btoParamList: CreateUpdateClinicRegisterScheduleBtoOutpRegisterServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterController_getClinicRegisterScheduleWithDetailByDefineIdAndDayOfWeek_b3f6b6:
    {
      method: 'post',
      url: '/api/outp-register/get-clinic-register-schedule-with-detail-by-define-id-and-day-of-week',
      parameterFomatter: (data?: {
        clinicRegisterDefineId: number;
        dayOfWeekList: number[];
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRegisterScheduleWithDetailVoOutpRegisterEntranceWebVo,
      ) => data,
    },
});
