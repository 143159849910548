import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  VSTableToolbar,
  Button,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useContext, useRef, useState } from 'react';

import { Screen } from '@/pages/Index/components';
import { CreateScreen } from '@/pages/Index/components/screen';
import { getScrollY, getScrollYRem, labelColWidth } from '@/utils';
import Block from '@/module/Block';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import CustomSelect from './custom_select';
import SpecificationEdit from './specification_edit';

const DrugSpecificationVsStorageTable = definePage((props) => {
  const { currentTreeItem } = props;

  const onScreen = useContext(CreateScreen);

  const unSavedRef = useRef(true);

  const onFetch = useCallback(
    async (params) => {
      // console.log(params);
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      let checkParams = {};
      params?.search?.checkGroupValue?.forEach((item) => {
        checkParams[item] = true;
      });
      params.search.idIs = params?.search?.idIs?.value;
      const res =
        await vsf.services?.DrugDrugController_queryPagedByDrugProductSpecificationVsStorageQto_172d45?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              // from: params.pagination?.from ?? 0,
              // size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              // ...(params ?? {}),
              drugSpecificationVsStorageIdIsNullOrNot:
                params.search?.drugSpecificationVsStorageIdIsNullOrNot == 'ALL'
                  ? null
                  : params?.search?.drugSpecificationVsStorageIdIsNullOrNot,
              classCodeIs: currentTreeItem?.code,
              checkGroupValue: undefined,
              ...checkParams,
              size: 99999,
            },
            ext: {},
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      const list = res?.data?.result ?? res?.data ?? [];
      return {
        data: list.map((m) =>
          convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2TableSpecificationVsStorageRo(
            m,
          ),
        ),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [currentTreeItem],
  );
  /**
   * convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2TableSpecificationVsStorageRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryPagedByDrugProductSpecificationVsStorageQtoRes2TableSpecificationVsStorageRo =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.biddingId = _.get(input, 'biddingId');
      output.drugName = _.get(input, 'drugInfo.drugName');
      output.drugProductName = _.get(input, 'drugProductName');
      output.drugSpecification = _.get(input, 'drugSpecification');
      output.firmName = _.get(input, 'drugFirmInfo.firmName');
      output.drugProductId = _.get(input, 'id');
      output.storageId = _.get(input, 'storageId');
      output.storageName = _.get(input, 'storageName');
      output.supplyIndicator = _.get(input, 'supplyIndicator');
      const prices = _.get(input, 'drugPriceItemList') ?? [];
      if (prices.length > 0) {
        const price = prices[0];
        output.packageUnit = _.get(price, 'packageUnit');
        output.packageSpecification = _.get(price, 'packageSpecification');
        output.amountPerPackage = _.get(price, 'amountPerPackage');
        output.drugPriceItemId = _.get(price, 'id');
      }
      const drugSpecificationVsStorageDtoList =
        _.get(input, 'drugSpecificationVsStorageDtoList') ?? [];
      output.usePackageSpecification = _.get(
        drugSpecificationVsStorageDtoList.length > 0
          ? drugSpecificationVsStorageDtoList[0]
          : {},
        'packageSpecification',
      );
      return output;
    };
  /**
   * convertQueryDrugStocksRes2TableDrugSpecificationVsStorageRo
   * @param {any} input
   * @returns {any}
   */
  const convertQueryDrugStocksRes2TableDrugSpecificationVsStorageRo = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = {};
    output.biddingId = _.get(input, 'drugProduct.biddingId');
    output.drugName = _.get(input, 'drugProduct.drugInfo.drugName');
    output.drugProductName = _.get(input, 'drugProduct.drugProductName');
    output.drugSpecification = _.get(input, 'drugProduct.drugSpecification');
    output.firmName = _.get(input, 'drugProduct.drugFirmInfo.firmName');
    output.packageUnit = _.get(input, 'currentDrugPriceItem.packageUnit');
    output.packageSpecification = _.get(input, 'packageSpecification');
    output.amount = _.get(input, 'amount');
    output.usePackageSpecification = _.get(
      input,
      'drugSpecificationVsStorage.packageSpecification',
    );
    return output;
  };

  const handelOpenSpecification = async ({ drugProductId }, onCancel) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugSpecificationVsStorageController_supplyByDrugProductId_4b5bb7?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          drugProductId,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      message.success('拆分规格启用成功');
      vsf.refs?.tableSpecificationVsStorage?.reload();
      unSavedRef.current = true;
      onCancel();
    }
    return res?.data;
  };

  const handelFormDrugSelectLodaData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugStockController_queryDrugs_529e82?.({
        qry: {
          inputCodeLike,
        },
      });
    const data = response?.data?.result ?? [];

    return (
      data?.map((item) => ({
        ...item,
        key: item?.drugProductId,
        drugProductName: item?.drugProductName,
        drugSpecification: item?.drugSpecification,
        firmName: item?.firmName,
      })) ?? []
    );
  }, []);

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  const renderFooter = (defaultDom, _, __, record) => {
    const onCancel = defaultDom?.props?.children?.[0]?.props?.onClick;
    return [
      <Button
        type="primary"
        onClick={() => handelOpenSpecification(record, onCancel)}
        disabled={unSavedRef.current}
      >
        启用拆分规格供应
      </Button>,
      defaultDom,
    ];
  };

  return (
    <>
      <VSPromiseTable
        bordered
        className="aspirin-table common_table"
        vsid="51778"
        id="tableSpecificationVsStorage"
        scroll={{
          // x: '86.66rem',
          y: getScrollYRem(28.6),
        }}
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true,
          },
        }}
        searchFormRender={(item, dom) => {
          return <SearchContainer>{dom}</SearchContainer>;
        }}
        searchConfig={{
          className: 'drugInventoryManage-specification',
          optionButtons: ['search'],
          labelAlign: 'left',
          collapsed: false,
          collapseRender: false,
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        onFetch={onFetch}
        pagination={false}
      >
        <VSTableToolbar
          title="药品规格管理信息"
          style={{ padding: '0 1.6rem' }}
        ></VSTableToolbar>

        <VSTableColumn
          dataIndex={['idIs']}
          title="药品名称"
          valueType="select"
          search
          order={0}
          columnKey={'idIs'}
          hideInTable
          formItemProps={{}}
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                allowClear
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width="33.4rem"
                placeholder="请输入药品名称"
                showLabel="drugProductName"
                loadData={handelFormDrugSelectLodaData}
                columns={[
                  {
                    title: '名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '规格',
                    dataIndex: 'drugSpecification',
                    key: 'drugSpecification',
                  },
                  {
                    title: '厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          dataIndex={['drugSpecificationVsStorageIdIsNullOrNot']}
          title="是否维护拆分规格"
          valueType="radio"
          search
          order={-1}
          columnKey={'drugSpecificationVsStorageIdIsNullOrNot'}
          hideInTable
          formItemProps={{
            labelCol: {
              style: {
                width: '10rem',
              },
            },
          }}
          dataSource={[
            {
              label: '是',
              value: true,
            },
            {
              label: '否',
              value: false,
            },
          ]}
        />

        <VSTableColumn
          dataIndex={['checkGroupValue']}
          title=""
          valueType="checkbox"
          search
          order={-2}
          columnKey={'checkGroupValue'}
          hideInTable
          fieldProps={{}}
          formItemProps={{}}
          dataSource={[
            {
              label: '冷藏',
              value: 'coldIndicatorIs',
            },
            {
              label: '高危药',
              value: 'dangerIndicatorIs',
            },
            {
              label: '皮试',
              value: 'skinTestTypeIsNullOrNot',
            },
          ]}
        />

        <VSTableColumn
          width="5.33rem"
          title="序号"
          valueType="text"
          preview
          render={(_, __, index) => index + 1}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['biddingId']}
          title="招标ID"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="10.66rem"
          dataIndex={['drugName']}
          title="通用名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="10.66rem"
          dataIndex={['drugProductName']}
          title="商品名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="10.66rem"
          dataIndex={['drugSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="10.66rem"
          dataIndex={['firmName']}
          title="厂家"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['packageUnit']}
          title="包装单位"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ],
          }}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['packageSpecification']}
          title="包装规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['amountPerPackage']}
          title="包装数量"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          width="8rem"
          dataIndex={['usePackageSpecification']}
          title="拆分规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width="10.66rem"
          title="规格管理"
          formItemProps={{ labelCol: 4, wrapperCol: 16 }}
          linkPage={{ updateTable: true }}
        >
          <VSTableAction
            style={{ color: '#222' }}
            children="··········"
            linkPage={{
              updateTable: true,
              modalProps: {
                title: '药品规格管理',
                width: '70rem',
                bodyStyle: {
                  height: '26.66rem',
                  padding: '0 1.6rem',
                },
                okText: '保存',
                cancelText: '关闭',
                cancelButtonProps: {
                  style: {
                    borderColor: '#3276E8',
                    color: '#3276E8',
                  },
                },
                footerRender: renderFooter,
              },
              linkPage: () => <SpecificationEdit id="SpecificationEditRef" />,
              onLinkPageValidate: async (_, __, record) => {
                const params =
                  await vsf.refs?.SpecificationEditRef?.getValues();
                const _item = params?.[0];
                if (!_item?.drugPriceItemId) {
                  message.error('请选择拆分规格');
                  return false;
                }
                const res =
                  await vsf.services?.DrugSpecificationVsStorageController_saveDrugSpecificationVsStorage_9773a6?.(
                    {
                      // TODO: 请检查以下这部分传参是否符合需求
                      item: {
                        drugProductId: _item?.drugProductId,
                        drugPriceItemId: _item?.drugPriceItemId,
                        drugStorageId: _item?.storageId,
                      },
                    },
                  ); // TODO: 你可能需要整理返回数据格式
                if (res.code === 200) {
                  message.success('保存成功，请启用拆分规格供应');
                  unSavedRef.current = false;
                }
              },
            }}
          />
        </VSTableColumn>
      </VSPromiseTable>
      <div className="footer">
        <Button
          style={{
            borderColor: '#3276E8',
            color: '#3276E8',
            cursor: 'pointer',
          }}
          onClick={() => {
            vsf?.openModal('aaaassss');
          }}
        >
          关闭
        </Button>
        <Button type="primary" onClick={onScreen} style={{ cursor: 'pointer' }}>
          清屏
        </Button>
      </div>
    </>
  );
});

const DrugSpecificationVsStorageTableScreen = (props) => {
  return (
    <Screen>
      <DrugSpecificationVsStorageTable {...props} />
    </Screen>
  );
};

export default DrugSpecificationVsStorageTableScreen;
