import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import vsf from '@vs/vsf-boot';
import {
  Button,
  TabPane,
  Tabs,
  VSForm,
  VSFormItem,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Table } from 'antd';
import { cloneDeep, concat, slice } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { AspirinDateTimePicker, AspirinTime, FileUpload } from '@/components';
import AspirinSelect from '@/components/AspirinSelect';
import Empty from '@/module/Empty';
import KeyEvent from '@/module/Keyevent';
import Loading from '@/module/Loading';

import SelectTag from '../components/func/SelectTag';
import SkinTest from '../components/order/out_patient_template/table/validate_drug';
import { insertBlock } from './utils';

const Row = ({ children, transform: transformProps = {}, ...props }: any) => {
  const currentKey = props['data-row-key'];
  const { dragNode = {}, record = {} } = props;
  const { groupLength: dragGroupLength, groupNumber: dragGroupNumber } =
    dragNode;
  const {
    groupNumber: currentGroupNumber,
    groupSubNumber: currentgroupSubNumber,
    groupIndex,
  } = record;
  const currentGroupLength = groupIndex?.length;
  const customStrategy = ({
    rects,
    activeNodeRect,
    activeIndex,
    overIndex,
    index,
  }) => {
    let resTransform = rectSortingStrategy({
      rects,
      activeIndex,
      overIndex,
      index,
      activeNodeRect,
    });

    if (currentGroupNumber) {
      if (dragGroupNumber === currentGroupNumber) {
        resTransform = null;
      } else {
        if (activeIndex > index) {
          const transformIndex = index - (currentgroupSubNumber - 1);
          if (overIndex <= transformIndex) {
            if (resTransform) {
              resTransform = {
                ...resTransform,
                y: resTransform?.y * (dragGroupLength ?? 1),
              };
            } else {
              resTransform = null;
            }
          } else {
            resTransform = null;
          }
        } else {
          const transformIndex =
            index + (currentGroupLength - currentgroupSubNumber);
          if (overIndex >= transformIndex) {
            if (resTransform) {
              resTransform = {
                ...resTransform,
                y: resTransform?.y * (dragGroupLength ?? 1),
              };
            } else {
              resTransform = null;
            }
          } else {
            resTransform = null;
          }
        }
      }
    } else {
      if (dragGroupNumber) {
        if (resTransform) {
          resTransform = {
            ...resTransform,
            y: resTransform?.y * (dragGroupLength ?? 1),
          };
        } else {
          resTransform = null;
        }
      }
    }

    return resTransform;
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: currentKey,
    strategy: customStrategy,
  });

  const getStyle = () => {
    let transformStyle = CSS.Transform.toString(
      transform && { ...transform, scaleY: 1 },
    );
    let showStyle = {};
    if (isDragging) {
      showStyle = { position: 'relative', zIndex: 9999 };
    }
    if (dragGroupNumber) {
      if (currentGroupNumber && currentgroupSubNumber !== 1) {
        if (dragGroupNumber === currentGroupNumber) {
          const newTransform = {
            ...transformProps,
            scaleX: 1,
            scaleY: 1,
          };
          transformStyle = CSS.Transform.toString(
            newTransform && { ...newTransform, scaleY: 1 },
          );
          if (Object.keys(transformProps)?.length > 0) {
            showStyle = {
              position: 'relative',
              zIndex: 9999,
              backgroundColor: '#fff',
            };
          }
        }
      }
    }

    return {
      transform: transformStyle,
      ...showStyle,
    };
  };

  const style: React.CSSProperties = {
    ...props.style,
    ...getStyle(),
    transition,
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (currentgroupSubNumber && currentgroupSubNumber !== 1) {
          return child;
        }
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const Index = () => {
  const [timeValue, setTimeValue] = useState<any>({
    hour: 12,
    minute: 24,
    second: 0,
  });
  const [dateValue, setDateValue] = useState<any>({
    hour: 12,
    minute: 24,
    second: 0,
    year: 2023,
    month: 12,
    day: 30,
  });
  const [timeRangeValue, setTimeRangeValue] = useState<any>({
    startTimePoint: {
      hour: 12,
      minute: 24,
      second: 0,
    },
    endTimePoint: {
      hour: 12,
      minute: 24,
      second: 0,
    },
  });
  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startTimePoint: {
      hour: 12,
      minute: 24,
      second: 0,
      year: 2023,
      month: 12,
      day: 29,
    },
    endTimePoint: {
      hour: 12,
      minute: 24,
      second: 0,
      year: 2023,
      month: 12,
      day: 30,
    },
  });

  const handleAspirinTimeChange = (v) => {
    setTimeValue(v);
  };

  const handleAspirinDateChange = (v) => {
    setDateValue(v);
  };
  const handleAspirinDateRangeChange = (v) => {
    setDateRangeValue(v);
  };

  const handleAspirinTimeRangeChange = (v) => {
    setTimeRangeValue(v);
  };

  const onCtrlB = () => {
    console.log('onCtrlB');
  };

  const [open, setOpen] = useState<boolean>(false);

  const [state, setState] = useState([]);

  const tableRef = useRef<any>();

  const columns = [
    {
      key: 'sort',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
  ];

  /**--------------------------- */
  const [dragNode, setDragNode] = useState<string | number>();
  const [dragValue, setDragValue] = useState<any[]>([
    { key: 1, name: '单行1' },
    { key: 2, name: '单行2' },
    {
      key: 3,
      name: '多行3(group 1) 1',
      groupNumber: 1,
      groupSubNumber: 1,
      groupIndex: [1, 2],
    },
    {
      key: 4,
      name: '多行4(group 1) 2',
      groupNumber: 1,
      groupSubNumber: 2,
      groupIndex: [1, 2],
    },
    { key: 5, name: '单行5' },
    {
      key: 6,
      name: '多行6(group 2) 1',
      groupNumber: 2,
      groupSubNumber: 1,
      groupIndex: [1, 2, 3],
    },
    {
      key: 7,
      name: '多行7(group 2) 2',
      groupNumber: 2,
      groupSubNumber: 2,
      groupIndex: [1, 2, 3],
    },
    {
      key: 8,
      name: '多行8(group 2) 3',
      groupNumber: 2,
      groupSubNumber: 3,
      groupIndex: [1, 2, 3],
    },
  ]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    setDragValue((previous: any) => {
      const newList = previous?.map((item) => {
        const { transform, ...rest } = item;
        return rest;
      });
      if (active.id !== over?.id) {
        const activeIndex = newList.findIndex((i) => i.key === active.id);
        const overIndex = newList.findIndex((i) => i.key === over?.id);
        const activeTarget = newList?.[activeIndex];
        const overTarget = newList?.[overIndex];

        if (
          !overTarget?.groupNumber ||
          (overTarget?.groupSubNumber === overTarget?.groupIndex?.length &&
            activeIndex < overIndex) ||
          (overTarget?.groupSubNumber === 1 && activeIndex > overIndex)
        ) {
          const newOverIndex =
            activeIndex < overIndex ? overIndex + 1 : overIndex;
          const activeLen = activeTarget?.groupIndex?.length ?? 1;
          return insertBlock(newList, activeIndex, activeLen, newOverIndex);
        } else {
          return newList;
        }
      } else {
        return newList;
      }
    });
  };

  const onDragStart = (event) => {
    const { active } = event;
    setDragNode(dragValue?.find((item) => item?.key === active.id));
  };

  const onDragMove = (event) => {
    // 在这里同步更新多个元素的 transform
    // 例如，根据拖拽的元素 A 的位置来更新元素 B 的位置
    // 这里需要你自己的逻辑来决定如何同步更新
    const activeId = event.active.id;
    const deltaY = event.delta.y;
    const activeItem = dragValue.find((item) => item.key === activeId);
    if (activeItem) {
      if (activeItem?.groupNumber) {
        setDragValue(
          dragValue.map((item) => {
            if (
              item.id !== activeId &&
              item?.groupNumber &&
              item?.groupNumber === activeItem?.groupNumber
            ) {
              return {
                ...item,
                transform: {
                  y: deltaY,
                  x: 0,
                },
              };
            }
            return item;
          }),
        );
      }
    }
  };

  const skin = useRef<any>(null);

  return (
    <div
      style={{
        padding: 24,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs
        style={{
          minWidth: 500,
        }}
        defaultActiveKey="dragRowVSTable"
      >
        <TabPane key="dragRowAntd" tab="dragRowAntd">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragMove={onDragMove}
          >
            <SortableContext
              // rowKey array
              items={dragValue
                // ?.filter((item) => item?.canDrag)
                .map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{
                  body: {
                    row: (props) => {
                      const { record } = props;
                      return (
                        <Row
                          {...props}
                          dragNode={dragNode}
                          transform={record?.transform}
                        />
                      );
                    },
                  },
                }}
                rowKey="key"
                columns={columns}
                dataSource={dragValue}
                // @ts-ignore
                onRow={(record) => {
                  return {
                    record,
                  };
                }}
              />
            </SortableContext>
          </DndContext>
        </TabPane>
        <TabPane key="dragRowVSTable" tab="dragRowVSTable">
          <VSTable
            components={{
              body: {
                row: (props) => {
                  const { record } = props;
                  return (
                    <Row
                      {...props}
                      dragNode={dragNode}
                      transform={record?.transform}
                    />
                  );
                },
              },
            }}
            rowKey="key"
            // columns={columns}
            value={dragValue}
            // @ts-ignore
            onRow={(record) => {
              return {
                record,
              };
            }}
            tableViewRender={(props, dom) => {
              return (
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}
                  onDragMove={onDragMove}
                >
                  <SortableContext
                    // rowKey array
                    items={dragValue
                      // ?.filter((item) => item?.canDrag)
                      .map((i) => i.key)}
                    strategy={verticalListSortingStrategy}
                  >
                    {dom}
                  </SortableContext>
                </DndContext>
              );
            }}
          >
            <VSTableColumn>
              <VSTableColumn
                dataIndex={['sort']}
                // title="手柄列"
                valueType="digit"
                fieldProps={{}}
                width={100}
              />
              <VSTableColumn
                dataIndex={['name']}
                title="名称"
                valueType="text"
                fieldProps={{}}
                width={100}
              />
            </VSTableColumn>
          </VSTable>
        </TabPane>
        {/* <TabPane key="ValidateDrug" tab="ValidateDrug">
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            测试
          </Button>
          <ValidateDrug
            open={open}
            data={{
              detailInfo: {
                drugEo: {
                  toxicCode: '1',
                  skinTestType: 'SKIN_TEST_SOLVENT',
                },
              },
            }}
            onSubmit={(v) => {
              setOpen(false);
            }}
            onClose={(v) => {
              setOpen(false);
            }}
          />
        </TabPane> */}
        <TabPane key="AspirinSelect" tab="AspirinSelect">
          <AspirinSelect
            // loadData={async () => {
            //   const res =
            //     await vsf?.services?.ApplicationDtoController_getApplication_fbf818(
            //       {
            //         template: true,
            //       },
            //     );
            //   return res;
            // }}
            loadData={[
              async () => {
                const res =
                  await vsf?.services?.ApplicationDtoController_getApplication_fbf818(
                    {
                      template: true,
                    },
                  );
                console.log('res', res);
                const target = {
                  data: {
                    result: res?.data,
                  },
                };
                return target;
              },
            ]}
            // dataSource={[{ label: '123', value: '123' }]}
            // fieldNames={{ label: 'name', value: 'id', children: 'children' }}
            dataRelationFieldNames={{
              id: 'id',
              parentId: 'templateApplicationId',
            }}
            selectProps={{
              showSearch: true,
            }}
            // addItemOptions={{
            //   status: true,
            //   mode: 'single',
            // }}
            // isTreeSelect={true}
            // treeSelectProps={{
            //   multiple: true,
            //   treeCheckable: true,
            // }}
          />
        </TabPane>
        <TabPane key="AspirinTime" tab="AspirinDateTimePicker">
          <AspirinDateTimePicker
            value={undefined}
            onChange={handleAspirinDateChange}
            format="YYYY-MM-DD HH:mm"
          ></AspirinDateTimePicker>
          <AspirinDateTimePicker
            value={undefined}
            valueType="timestamp"
            format="YYYY-MM-DD HH:mm"
          ></AspirinDateTimePicker>
          <AspirinDateTimePicker
            value={undefined}
            type="range"
            valueType="date"
            format="YYYY-MM-DD HH:mm"
          ></AspirinDateTimePicker>
          <AspirinDateTimePicker
            value={dateValue}
            onChange={handleAspirinDateChange}
            format="YYYY-MM-DD HH:mm"
          ></AspirinDateTimePicker>
          <AspirinDateTimePicker
            value={dateRangeValue}
            type="range"
            onChange={handleAspirinDateRangeChange}
            format="YYYY-MM-DD HH:mm"
          ></AspirinDateTimePicker>
          <VSTable
            editable={{ editType: 'single' }}
            vsid="86392"
            //@ts-ignore
            onRecord={() => ({ id: 'create_' + Math.random() })}
            // value={[
            //   {
            //     demo: {
            //       startTimePoint: {
            //         hour: 12,
            //         minute: 24,
            //         second: 0,
            //         year: 2023,
            //         month: 12,
            //         day: 29,
            //       },
            //       endTimePoint: {
            //         hour: 12,
            //         minute: 24,
            //         second: 0,
            //         year: 2023,
            //         month: 12,
            //         day: 30,
            //       },
            //     },
            //   },
            // ]}
          >
            <VSTableColumn
              dataIndex={['demo']}
              title="时间"
              valueType="aspirinDateTimePicker"
              fieldProps={{
                type: 'range',
                format: 'HH:mm:ss',
                allowEmpty: [true, true],
              }}
            ></VSTableColumn>
            <VSTableColumn
              dataIndex={['demo2']}
              title="时间"
              valueType="aspirinDateTimePicker"
              fieldProps={{
                type: 'single',
                format: 'HH:mm',
                allowEmpty: [true, true],
              }}
            ></VSTableColumn>
            <VSTableAddition children="添加" />
          </VSTable>
          <VSForm
          // onValuesChange={(v) => {

          // }}
          >
            {/* <VSFormItem
              name={['time']}
              valueType="aspirinDateTimePicker"
              fieldProps={{}}
            ></VSFormItem> */}
            <VSFormItem
              name={['range']}
              valueType="aspirinDateTimePicker"
              fieldProps={{
                type: 'range',
                format: 'YYYY/MM/DD HH:mm:ss',
                allowEmpty: [true, true],
              }}
            ></VSFormItem>
            <VSFormItem
              name={['range2']}
              valueType="aspirinDateTimePicker"
              fieldProps={{
                type: 'range',
                format: 'HH:mm:ss',
                allowEmpty: [true, true],
              }}
            ></VSFormItem>
          </VSForm>
        </TabPane>
        {/* <TabPane key="dragLine" tab="DragLine">
          <DragLine.Container direction="horizontal">
            <div>123123</div>
            <DragLine color="red" />
            <div>456</div>
          </DragLine.Container>
          <DragLine.Container
            style={{
              height: 300,
              width: 300,
            }}
            direction="vertical"
          >
            <div>123123</div>
            <DragLine />
            <div>456</div>
          </DragLine.Container>
        </TabPane> */}
        <TabPane key="other" tab="Loading&Empty">
          <Empty />
          <Loading />
          <Loading size="small" />
        </TabPane>
        <TabPane key="upload" tab="Upload">
          <FileUpload />
        </TabPane>
        <TabPane key="keyevent" tab="Keyevent">
          <KeyEvent
            className="App"
            events={{
              watch: (e) => {
                console.log(e);
              },
              onCtrl1: () => {
                console.log('onCtrl1');
              },
              onCtrlB: () => {
                console.log('onCtrlB');
              },
            }}
          >
            <div>123</div>
          </KeyEvent>
        </TabPane>
        <TabPane key="vi" tab="vi">
          <SkinTest ref={skin} />
          <Button
            onClick={() => {
              skin?.current?.onOpen?.({
                skinTestClass: 'NOT_RAW_LIQUID',
              });
            }}
          >
            点击
          </Button>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Index;
