import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  VSTable,
  Form,
  Input,
  InputNumber,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2InpTransferRecordTable,
  convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo,
} from '../convert';
import { Select } from 'antd';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import { result } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
// import { DndProvider, useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

import { dayOneMessage, dayOne } from '@/pattern';
import { numberAndNegative, hour, minute } from '@/pattern';
import { askSearch } from '@/pages/Index/components/func/ask';
import Block from '@/pages/Index/components/block_no_padding';
import DragTableRow from '@/pages/Index/components/drag_table_row';
import Empty from '@/module/Empty';
import FormTitle from '@/pages/Index/components/form_title';
import Icon from '@/module/Icon';
import IdentityDocuments from '@/pages/Index/components/identity_documents';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { labelColWidth, labelNumberWidth } from '@/utils';

const formItemWidth = '100%';
const tempTrue = true;
const tempFalse = false;

const Index = (props) => {
  const { data, otherInfoTable } = props;
  console.log(data, otherInfoTable, 'data, otherInfoTable');
  const [currentRowId, setCurrentRowId] = useState();
  // 重症列表
  const [tableData, setTableData] = useState();
  // 压疮
  const [isShow, setIsShow] = useState(data?.pressureSore);

  useEffect(() => {
    if (otherInfoTable?.length <= 0) {
      const _id = Math.random();
      setTableData([
        {
          id: _id,
        },
      ]);
      // vsf?.refs?.otherInfoTable?.startEdit(_id);
    } else {
      console.log('重症监护ICU', otherInfoTable, data);
      setTableData(otherInfoTable);
    }
  }, [data]);

  // 重症监护室
  const getAllByDepartment = async (params) => {
    const res =
      await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            orderList: params?.orderList,
            specialTypeIs: 'ICU',
            ...params?.search,
            ...params?.filters,
            ...params?.extra,
            ...(params ?? {}),
          },
        },
      ); // TODO: 你可能需要整理返回数据格式
    return res?.data ?? [];
  };

  // 点击行新增
  const onAddAndEdit = async (index, entity) => {
    const res = await vsf?.refs?.otherInfoTable?.saveEdit();
    const tableValue = vsf?.refs?.otherInfoTable.getValues();
    if (!res) return;
    const id = {
      id: 'create_' + Math.random(),
    };
    setTableData(tableValue.toSpliced(index + 1, 0, id));

    setTimeout(async () => {
      await vsf?.refs?.otherInfoTable?.startEdit(id?.id);
    }, 20);
  };
  // 点击行删除
  const onRemove = async (entity) => {
    // if (!String(entity.id).startsWith('create_')) {
    //   Tip({
    //     title: '操作窗口',
    //     content: `确定删除{【${entity?.priceItemForClinic?.itemName?.label}】}，删除后不可恢复！`,
    //     onOk: async () => {
    //       const res =
    //         await vsf?.services?.PriceListController_removePriceDetailFromMaster_040237?.(
    //           {
    //             btoParam: {
    //               id: vsf.refs.PricePattern.getFieldValue('id'),
    //               pricePatternDetailBtoList: [{ id: entity.id }],
    //             },
    //           },
    //         );
    //       if (res.code === 200) {
    //         message.success('删除收费模板成功');
    //         onFetch();
    //       }
    //     },
    //     centered: true,
    //     okText: '确定',
    //     cancelText: '取消',
    //   });
    // } else {

    vsf.refs.otherInfoTable.remove(entity);

    // }
  };

  // 移动
  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const res = await vsf?.refs?.otherInfoTable?.saveEdit();
      const tableValue = vsf?.refs?.otherInfoTable.getValues();
      const dragRow = tableValue[dragIndex];
      setTableData(
        update(tableData, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [tableData],
  );
  // 交换位置
  const handelChangePosition = async (
    currentIndex,
    changeIndex,
    type = 'up',
  ) => {
    const res = await vsf?.refs?.otherInfoTable?.saveEdit();
    const tableValue = await vsf?.refs?.otherInfoTable?.getValues();
    let tempTableValue = cloneDeep(tableValue);

    if (currentIndex == tableValue?.length - 1 && type == 'down') return;

    let temp = tableValue[currentIndex];
    tempTableValue[currentIndex] = tempTableValue[changeIndex];
    tempTableValue[changeIndex] = temp;

    setTableData([...tempTableValue]);
  };

  const components = {
    body: {
      row: DragTableRow,
    },
  };

  const getFormData = useCallback(
    async (params) => {
      return data;
    },
    [data],
  );

  return (
    <div className="homepageinfo_other_info">
      <VSForm
        id="otherInfoForm"
        labelAlign="left"
        labelWrap
        onFetch={getFormData}
      >
        <FormTitle
          style={{ backgroundColor: 'transparent', padding: 0 }}
          title="过敏信息"
          isNeedTag={true}
          borderBottom="1px solid #D6D8D9 "
          height=""
        >
          <VSFormLayout
            key="0"
            columnCount={2}
            // labelCol={labelColWidth[10]}
            labelWidth={labelNumberWidth(8)}
            gutter={80}
          >
            <VSFormItem
              name={['allergyDrug']}
              label="过敏药物"
              valueType="textarea"
              fieldProps={{
                disabled: true,
                autoSize: { minRows: 3, maxRows: 4 },
              }}
            />
            <VSFormItem
              name={['notDrugAllergy']}
              label="非药物过敏"
              valueType="textarea"
              fieldProps={{
                disabled: true,
                autoSize: { minRows: 3, maxRows: 4 },
              }}
            />
          </VSFormLayout>
          <VSFormLayout
            key="1"
            columnCount={3}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(10)}
            gutter={80}
          >
            <VSFormItem
              name={['infusion']}
              label="是否输液"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 2 },
              ]}
              fieldProps={{}}
              dataSource={[
                { label: '是', value: '1' },
                { label: '否', value: '2' },
              ]}
            />

            <VSFormItem
              name={['infusionReaction']}
              label="输液反应"
              valueType="select"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 2 },
              ]}
              dataSource={[
                { label: '有', value: '1' },
                { label: '无', value: '2' },
              ]}
              fieldProps={{
                defaultValue: '2',
                showSearch: true,
              }}
            />
          </VSFormLayout>
          <VSFormLayout
            key="2"
            columnCount={3}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(10)}
            gutter={80}
          >
            <VSFormItem
              name={['bloodType']}
              label="血型"
              valueType="custom"
              fieldProps={{
                showSearch: true,
              }}
              // dataSource={vsf.stores.dbenums?.enums?.BLOOD_ABO_TYPE_DICT}
            >
              <Select
                options={vsf.stores.dbenums?.enums?.BLOOD_ABO_TYPE_DICT}
                filterOption={askSearch}
                placeholder="请选择血型"
                showSearch
              />
            </VSFormItem>

            <VSFormItem
              name={['bloodTypeRh']}
              label="RhD"
              valueType="custom"
              fieldProps={{ showSearch: true }}
              // dataSource={vsf.stores.dbenums?.enums?.BLOOD_RH_TYPE_DICT}
            >
              <Select
                options={vsf.stores.dbenums?.enums?.BLOOD_RH_TYPE_DICT}
                filterOption={askSearch}
                placeholder="请选择"
                showSearch
              />
            </VSFormItem>

            <VSFormItem
              name={['hbsagIndicator']}
              label="HBsAg"
              valueType="select"
              dataSource={[
                { label: '未做', value: 'NOT_DONE' },
                { label: '阴性', value: 'NEGATIVE' },
                { label: '阳性', value: 'POSITIVE' },
              ]}
              fieldProps={{ showSearch: true }}
            />

            <VSFormItem
              name={['hcvabIndicator']}
              label="HCV-Ab"
              valueType="select"
              dataSource={[
                { label: '未做', value: 'NOT_DONE' },
                { label: '阴性', value: 'NEGATIVE' },
                { label: '阳性', value: 'POSITIVE' },
              ]}
              fieldProps={{ showSearch: true }}
            />

            <VSFormItem
              name={['hivabIndicator']}
              label="HIV-Ab"
              valueType="select"
              dataSource={[
                { label: '未做', value: 'NOT_DONE' },
                { label: '阴性', value: 'NEGATIVE' },
                { label: '阳性', value: 'POSITIVE' },
              ]}
              fieldProps={{ showSearch: true }}
            />

            <VSFormItem
              name={['bloodTransfusionReaction']}
              label="输血反应"
              valueType="select"
              dataSource={[
                { label: '未输血', value: 'NOT_TRANSFUSION' },
                { label: '有输血反应', value: 'HAVE_TRANSFUSION_REACTION' },
                { label: '无输血反应', value: 'NONE_TRANSFUSION_REACTION' },
              ]}
              fieldProps={{ showSearch: true }}
            />

            <VSFormItem
              name={['erythrocyteSuspension']}
              label="红细胞"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    单位
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['plateletMachine']}
              label="血小板"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    单位
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['bloodPlasma']}
              label="血浆"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    ml
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['wholeBlood']}
              label="全血"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    ml
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['autotransfusion']}
              label="自体回收"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    ml
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['other']}
              label="其它"
              valueType="digit"
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    ml
                  </div>
                ),
              }}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: 'transparent', padding: 0 }}
          title="护理信息"
          isNeedTag={true}
          borderBottom="1px solid #D6D8D9 "
          height=""
        >
          <VSFormLayout
            key="3"
            columnCount={3}
            labelCol={labelColWidth[8]}
            gutter={80}
          ></VSFormLayout>
          <VSFormLayout
            key="4"
            columnCount={3}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(12)}
            gutter={80}
          >
            <VSFormItem
              name={['inHospitalFallDown']}
              label="医院内跌倒/坠床"
              valueType="select"
              fieldProps={{
                showSearch: true,
                defaultValue: '2',
              }}
              dataSource={[
                { label: '有', value: '1' },
                { label: '无', value: '2' },
              ]}
            />

            <VSFormItem
              name={['pressureSore']}
              label="压疮"
              valueType="select"
              dataSource={[
                { label: '无', value: 'NONE' },
                { label: '住院期间发生', value: 'IN_HOSPITAL' },
                { label: '入院前已有', value: 'BEFORE_ADMISSION' },
              ]}
              fieldProps={{
                showSearch: true,
                onChange: (v) => {
                  setIsShow(v);
                },
              }}
            />
            {/* 条件显示 */}
            {isShow !== 'NONE' && (
              <VSFormItem
                name={['pressureSoreSource']}
                label="入院前已有压疮病人来源"
                valueType="select"
                dataSource={[
                  { label: '自家庭入住时存在', value: 'FAMILY_CHECK_IN' },
                  { label: '自养老院入住时存在', value: 'NURSING_CHECK_IN' },
                  {
                    label: '自其他医院转入时存在',
                    value: 'OTHER_HOSPITAL_TRANSFER_IN',
                  },
                  {
                    label: '自其他来源录入时存在',
                    value: 'OTHER_SOURCE_ENTER',
                  },
                ]}
                fieldProps={{ showSearch: true }}
              />
            )}
            <VSFormItem
              className="hideItem"
              name={['specialLevelNurseDays']}
              label="特级护理"
              valueType="digit"
              rules={[
                {
                  pattern: dayOne,
                  message: dayOneMessage,
                },
              ]}
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    天
                  </div>
                ),
              }}
            />
            <VSFormItem
              name={['firstLevelNurseDays']}
              label="一级护理"
              valueType="digit"
              rules={[
                {
                  pattern: dayOne,
                  message: dayOneMessage,
                },
              ]}
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    天
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['secondLevelNurseDays']}
              label="二级护理"
              valueType="digit"
              rules={[
                {
                  pattern: dayOne,
                  message: dayOneMessage,
                },
              ]}
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    天
                  </div>
                ),
              }}
            />

            <VSFormItem
              name={['thirdLevelNurseDays']}
              label="三级护理"
              valueType="digit"
              rules={[
                {
                  pattern: dayOne,
                  message: dayOneMessage,
                },
              ]}
              fieldProps={{
                width: '100%',
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    天
                  </div>
                ),
              }}
            />
            {isShow !== 'NONE' && (
              <VSFormItem
                name={['pressureSoreLevel']}
                label="压疮分级"
                valueType="select"
                dataSource={[
                  { label: '一级', value: 'LEVEL_ONE' },
                  { label: '二级', value: 'LEVEL_TWO' },
                  { label: '三级', value: 'LEVEL_THREE' },
                  { label: '四级', value: 'LEVEL_FOUR' },
                ]}
                fieldProps={{ showSearch: true }}
              />
            )}

            {isShow !== 'NONE' && (
              <VSFormItem
                name={['pressureSoreBodypart']}
                label="压疮发生部位"
                valueType="select"
                dataSource={[
                  { label: '单个部位', value: 'SINGLE' },
                  { label: '多个（两个及以上）部位', value: 'MULTIPLE' },
                ]}
                fieldProps={{ showSearch: true }}
              />
            )}
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: 'transparent', padding: 0 }}
          title="质量信息"
          isNeedTag={true}
          borderBottom="1px solid #D6D8D9 "
          height=""
        >
          <VSFormLayout
            key="5"
            columnCount={3}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(10)}
            gutter={80}
          >
            <VSFormItem
              name={['crisisCase']}
              label="危重病例"
              valueType="radio"
              dataSource={[
                { label: '否', value: 'NON' },
                { label: '重', value: 'SERIOUS' },
                { label: '危', value: 'CRISIS' },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['difficultCase']}
              label="疑难病例"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 10 },
              ]}
              fieldProps={{}}
              dataSource={[
                { label: '是', value: '1' },
                { label: '否', value: '0' },
              ]}
              initialValue="0"
            />

            <VSFormItem
              name={['mdtCaseHistory']}
              label="MDT病历"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 10 },
              ]}
              fieldProps={{}}
              initialValue="0"
              dataSource={[
                { label: '是', value: '2' },
                { label: '否', value: '0' },
              ]}
            />

            <VSFormItem
              name={['singleDiseaseManage']}
              label="单病种病例"
              valueType="radio"
              fieldProps={{}}
              dataSource={[
                { label: '是', value: tempTrue },
                { label: '否', value: tempFalse },
              ]}
              initialValue="0"
            />
            <VSFormItem
              name={['dayWardCase']}
              label="日间病房病例"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 10 },
              ]}
              fieldProps={{}}
              dataSource={[
                { label: '是', value: '4' },
                { label: '否', value: '0' },
              ]}
            />

            <VSFormItem
              name={['dayOperationCase']}
              label="日间手术病例"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 10 },
              ]}
              dataSource={[
                { label: '是', value: '5' },
                { label: '否', value: '0' },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['teachWardCheck']}
              label="教学查房病历"
              valueType="radio"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 10 },
              ]}
              dataSource={[
                { label: '是', value: '6' },
                { label: '否', value: '0' },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['clinicalPathwayManage']}
              label="临床路径管理"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.CP_MANAGE_DICT}
              fieldProps={{
                showSearch: true,
              }}
            />
          </VSFormLayout>
          <VSFormLayout
            key="6"
            columnCount={2}
            columnSpan={[8, 16]}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(10)}
            gutter={80}
          >
            <VSFormLayout
              key="10"
              columnCount={1}
              labelCol={labelColWidth[8]}
              gutter={80}
            >
              <VSFormItem
                name={['followUpClinic']}
                label="随诊"
                valueType="select"
                dataSource={[
                  { label: '限期', value: 'LIMIT_TERM' },
                  { label: '否', value: 'NON' },
                  { label: '长期', value: 'LONG_TERM' },
                ]}
                fieldProps={{ showSearch: true, width: '100%' }}
              />
            </VSFormLayout>
            <VSFormLayout
              // columnSpan={[10, 8]}
              key="7"
              columnCount={1}
              labelCol={labelColWidth[8]}
              gutter={0}
            >
              <VSFormItem
                name={['followPeriod']}
                label="随诊期限"
                valueType="custom"
                customComponent={(props) => {
                  const { value } = props;
                  return (
                    <div style={{ display: 'flex' }}>
                      <VSFormLayout
                        key="10"
                        columnCount={4}
                        labelWidth={0}
                        gutter={0}
                        columnSpan={[4, 4, 4, 12]}
                      >
                        <VSFormItem
                          name={['followUpWeeks']}
                          label=""
                          valueType="text"
                          fieldProps={{
                            placeholder: '',
                            // width: 110,
                            onChange: (v) => {
                              console.log(v);
                              const _followUpWeeks = v?.target?.value;
                              vsf.refs.otherInfoForm.setFieldsValue({
                                followPeriod: {
                                  ...value,
                                  followUpWeeks: _followUpWeeks,
                                },
                              });
                            },
                            addonAfter: (
                              <div
                                style={{
                                  color: '#343B42;',
                                  cursor: 'pointer',
                                }}
                                className="icon14"
                              >
                                周
                              </div>
                            ),
                          }}
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                if (!value) return;
                                const pattern = /^\d{0,2}?$/;
                                if (!pattern.test(value)) {
                                  callback('最大不超过2位数');
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                        />
                        <VSFormItem
                          name={['followUpMonths']}
                          // label="随诊期限(月)"
                          valueType="text"
                          fieldProps={{
                            // width: 110,
                            placeholder: '',
                            labelWidth: 0,
                            onChange: (v) => {
                              const _followUpMonths = v?.target.value;
                              vsf.refs.otherInfoForm.setFieldsValue({
                                followPeriod: {
                                  ...value,
                                  followUpMonths: _followUpMonths,
                                },
                              });
                            },
                            addonAfter: (
                              <div
                                style={{
                                  color: '#343B42;',
                                  cursor: 'pointer',
                                }}
                                className="icon14"
                              >
                                月
                              </div>
                            ),
                          }}
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                if (!value) return;
                                const pattern = /^\d{0,2}?$/;
                                if (!pattern.test(value)) {
                                  callback('最大不超过2位数');
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                          // style={{ marginLeft: '38px' }}
                        />

                        <VSFormItem
                          name={['followUpYears']}
                          // label="随诊期限(年)"
                          valueType="text"
                          fieldProps={{
                            placeholder: '',
                            // width: 110,
                            labelWidth: 0,
                            onChange: (v) => {
                              const _followUpYears = v?.target.value;
                              vsf.refs.otherInfoForm.setFieldsValue({
                                followPeriod: {
                                  ...value,
                                  followUpYears: _followUpYears,
                                },
                              });
                            },
                            addonAfter: (
                              <div
                                style={{
                                  color: '#343B42;',
                                  cursor: 'pointer',
                                }}
                                className="icon14"
                              >
                                年
                              </div>
                            ),
                          }}
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                if (!value) return;
                                const pattern = /^\d{0,2}?$/;
                                if (!pattern.test(value)) {
                                  callback('最大不超过2位数');
                                } else {
                                  callback();
                                }
                              },
                            },
                          ]}
                          // style={{ marginLeft: '7px' }}
                        />
                        <div className="form_item_text" style={{}}>
                          (请填写整数，例:“3周”或“3日、月”，最大不超过2位数)
                        </div>
                      </VSFormLayout>
                    </div>
                  );
                }}
                fieldProps={{
                  style: {
                    paddiing: '0 0 0 0',
                  },
                }}
              ></VSFormItem>

              {/* <VSFormItem
                name={['followUpWeeks']}
                label="随诊期限"
                valueType="digit"
                fieldProps={{
                  placeholder: '请输入',
                  width: 120,
                  addonAfter: (
                    <div
                      style={{
                        
                        color: '#343B42;',
                        cursor: 'pointer',
                      }}
                    >
                      周
                    </div>
                  ),
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (!value) return;
                      const pattern = /^\d{0,2}?$/;
                      if (!pattern.test(value)) {
                        callback('最大不超过2位数');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              />

              <VSFormItem
                name={['followUpMonths']}
                // label="随诊期限(月)"
                valueType="digit"
                fieldProps={{
                  width: 120,
                  labelWidth: 0,
                  addonAfter: (
                    <div
                      style={{
                        
                        color: '#343B42;',
                        cursor: 'pointer',
                      }}
                    >
                      月
                    </div>
                  ),
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (!value) return;
                      const pattern = /^\d{0,2}?$/;
                      if (!pattern.test(value)) {
                        callback('最大不超过2位数');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                style={{ marginLeft: '38px' }}
              />

              <VSFormItem
                name={['followUpYears']}
                // label="随诊期限(年)"
                valueType="digit"
                fieldProps={{
                  width: 120,
                  labelWidth: 0,
                  addonAfter: (
                    <div
                      style={{
                        
                        color: '#343B42;',
                        cursor: 'pointer',
                      }}
                    >
                      年
                    </div>
                  ),
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (!value) return;
                      const pattern = /^\d{0,2}?$/;
                      if (!pattern.test(value)) {
                        callback('最大不超过2位数');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                style={{ marginLeft: '7px' }}
              /> */}
            </VSFormLayout>
            {/* <VSFormLayout key="100" columnCount={1} labelWidth={0} gutter={0}>
              <div className="form_item_text" style={{ marginLeft: '-10px' }}>
                (请填写整数，例:“3周”或“3日、月”，最大不超过2位数)
              </div>
            </VSFormLayout> */}
          </VSFormLayout>
          <VSFormLayout labelWidth={145} key="8" columnCount={3} gutter={80}>
            <VSFormLayout
              VSFormLayout
              key="9"
              columnCount={1}
              // labelWidth={250}
              labelWidth={labelNumberWidth(15)}
              gutter={80}
            >
              <VSFormItem
                name={['firstCaseIndicator']}
                label="手术、治疗、检查、诊断是否本院第一例"
                valueType="radio"
                initialValue={false}
                dataSource={[
                  { label: '是', value: tempTrue },
                  { label: '否', value: tempFalse },
                ]}
                fieldProps={{ showSearch: true }}
              />
            </VSFormLayout>
            <VSFormLayout
              VSFormLayout
              key="10"
              columnCount={1}
              // labelCol={labelColWidth[8]}
              labelWidth={labelNumberWidth(8)}
              gutter={80}
            >
              <VSFormItem
                name={['teachMedicalRecord']}
                label="示教病例"
                valueType="radio"
                rules={[
                  {
                    message: '示教病例长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ]}
                dataSource={[
                  { label: '是', value: '1' },
                  { label: '否', value: '2' },
                ]}
                fieldProps={{ showSearch: true }}
              />
            </VSFormLayout>
            <VSFormLayout
              key="10"
              columnCount={1}
              labelCol={labelColWidth[8]}
              gutter={80}
            >
              <VSFormItem
                name={['singleDiseaseName']}
                label="单病种名称"
                valueType="custom"
                fieldProps={{
                  showSearch: true,
                }}
                // dataSource={vsf.stores.dbenums?.enums?.SINGLE_DISEASE_CLINIC_LIST}
              >
                <Select
                  options={
                    vsf.stores.dbenums?.enums?.SINGLE_DISEASE_CLINIC_LIST
                  }
                  filterOption={askSearch}
                  placeholder="请选择单病种名称"
                  showSearch
                />
              </VSFormItem>
            </VSFormLayout>
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: 'transparent', padding: 0 }}
          title="人员信息"
          isNeedTag={true}
          borderBottom="1px solid #D6D8D9 "
          height=""
        >
          <VSFormLayout
            VSFormLayout
            key="20"
            columnCount={3}
            gutter={80}
            // labelCol={labelColWidth[8]}
            labelWidth={labelNumberWidth(10)}
          >
            <VSFormItem
              name={['departmentLeader']}
              label="科主任"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    );
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['chiefDoctor']}
              label="主(副主)任医师"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['attendingDoctor']}
              label="主治医师"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 50,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['doctorInCharge']}
              label="住院医师"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                fieldNames: { label: 'staffName', value: 'id' },
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
              }}
            />

            <VSFormItem
              name={['medicalGroupLeader', 'staffName']}
              label="医疗组长"
              valueType="select"
              fieldProps={{
                disabled: true,
              }}
            />

            <VSFormItem
              name={['advanceStudyDoctor']}
              label="进修医师"
              valueType="select"
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['nurseInCharge']}
              label="责任护士"
              valueType="select"
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                fieldNames: { label: 'staffName', value: 'id' },
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          jobClassCodeIs: 'A2',
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
              }}
            />

            <VSFormItem
              name={['internshipDoctor']}
              label="实习医师"
              valueType="select"
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['catalogStaff']}
              label="编目员"
              valueType="select"
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />

            <VSFormItem
              name={['medicalRecordQuality']}
              label="病案质量"
              valueType="select"
              // required
              dataSource={vsf.stores.dbenums.enums.MR_QUALITY_DICT}
              fieldProps={{
                showSearch: true,
              }}
            />

            <VSFormItem
              name={['controlQualityDoctor']}
              label="质控医师"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />
            <VSFormItem
              name={['controlQualityNurse']}
              label="质控护士"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          jobClassCodeIs: 'A2',
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />
            <VSFormItem
              name={['controlQualityDate']}
              label="质控日期"
              valueType="date"
              // required
              fieldProps={{
                style: { width: '100%' },
              }}
            />

            <VSFormItem
              name={['recordDoctor']}
              label="填报医师"
              valueType="select"
              // required
              searchKey="inputCodeLike"
              fieldProps={{
                showSearch: true,
                dataSource: async (params) => {
                  //
                  // Tips: 以下是你选择的请求函数
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 20,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle
          style={{ backgroundColor: 'transparent', padding: 0 }}
          title="重症监护列表"
          isNeedTag={true}
          borderBottom="1px solid #D6D8D9 "
          height=""
        >
          {/* <DndProvider backend={HTML5Backend}> */}
          <VSTable
            editable={{
              editType: 'single',
              saveOnClickOutside: true,
              columnProps: {
                dataIndex: '__vs__table__edit',
                fixed: 'right',
                width: 100,
                hideInTable: true,
              },
            }}
            id="otherInfoTable"
            className="
            aspirin-table-header-background-color
           aspirin-table-row-hover-background-color
           aspirin-table-nopadding
           aspirin-table-body-empty-transparent-background-color
  "
            rowClick={{
              rowClickType: {
                type: 'edit-save',
                action: 'double-click',
              },
            }}
            rowClassName={(v) => {
              return v.id === currentRowId
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            onRow={(record, index) => {
              const attr = {
                index,
                moveRow,
                currentRowId,
                onClick: (e) => {
                  e.stopPropagation();
                  setCurrentRowId(record?.id);
                },
              };
              return attr;
            }}
            components={components}
            pagination={false}
            tableStyle={{ width: 1000 }}
            value={tableData}
            onChange={setTableData}
            scroll={{ y: 180 }}
          >
            <VSTableColumn
              dataIndex={['department']}
              title="重症监护室"
              valueType="select"
              fieldProps={{
                showSearch: true,
                fieldNames: { label: 'departmentName', value: 'id' },
                // dataSource: async (params) => {
                //   const res =
                //     await vsf?.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                //       {
                //         qto: {
                //           from: params?.pagination?.from ?? 0,
                //           size: params?.pagination?.size ?? 20,
                //           orderList: params?.orderList,
                //           specialTypeIs: 'ICU',
                //           ...params?.search,
                //           ...params?.filters,
                //           ...params?.extra,
                //           ...(params ?? {}),
                //         },
                //       },
                //     ); // TODO: 你可能需要整理返回数据格式
                //   return res?.data ?? [];
                // },
              }}
              dataSource={getAllByDepartment}
            />

            <VSTableColumn
              dataIndex={['inIcuDateTime']}
              title="进入重症监护室时间"
              valueType="date"
              fieldProps={{
                showTime: true,
                format: 'YYYY-MM-DD HH:mm:ss',
                width: 280,
              }}
              formItemProps={{ showTime: true, showSearch: true, width: 345 }}
            />

            <VSTableColumn
              dataIndex={['outIcuDateTime']}
              title="转出重症监护室时间"
              valueType="date"
              fieldProps={{
                showTime: true,
                format: 'YYYY-MM-DD HH:mm:ss',
                width: 280,
              }}
            />
            <VSTableColumn
              dataIndex={['outIcuDateTime']}
              title="操作"
              // valueType="option"
              width={100}
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              renderFormItem={(dom, entity, form) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Icon
                      type="icon-tianjia1"
                      style={{ color: '#3276e8' }}
                      size={14}
                      onClick={(event) => {
                        event.stopPropagation();
                        onAddAndEdit(dom?.index, entity?.record);
                      }}
                    />

                    <Icon
                      type="icon-wudishanchu"
                      size={14}
                      onClick={(event) => {
                        event.stopPropagation();
                        /**
                         * 如果只有一条数据，点击第一行只清空数据，
                         */
                        if (tableData?.length == 1 && dom?.index == 0) {
                          form?.resetFields();
                        } else {
                          onRemove(entity?.record);
                        }
                      }}
                    />
                    <Icon
                      type="icon-wudishangyi"
                      size={14}
                      style={{ color: '#3276E8' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (dom?.index == 0) return;
                        handelChangePosition(dom?.index, dom?.index - 1);
                      }}
                    />
                    <Icon
                      type="icon-wudixiayi"
                      size={14}
                      style={{ color: '#3276E8' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handelChangePosition(
                          dom?.index,
                          dom?.index + 1,
                          'down',
                        );
                      }}
                    />
                  </div>
                );
              }}
              render={(_, value, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Icon
                      type="icon-tianjia1"
                      style={{ color: '#3276e8' }}
                      size={14}
                      onClick={(event) => {
                        event.stopPropagation();
                        onAddAndEdit(index, value);
                      }}
                    />

                    <Icon
                      type="icon-wudishanchu"
                      size={14}
                      onClick={(event) => {
                        event.stopPropagation();
                        // console.log(entity, form);
                        /**
                         * 如果只有一条数据，点击第一行只清空数据，不删除行isDelete为true，不是第一行
                         */

                        if (tableData?.length == 1 && index == 0) {
                          vsf?.refs?.otherInfoTable
                            ?.getEditForm()
                            ?.resetFields();
                        } else {
                          onRemove(value);
                        }
                      }}
                    />
                    <Icon
                      type="icon-wudishangyi"
                      size={14}
                      style={{ color: '#3276E8' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (index == 0) return;
                        handelChangePosition(index, index - 1);
                      }}
                    />
                    <Icon
                      type="icon-wudixiayi"
                      size={14}
                      style={{ color: '#3276E8' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handelChangePosition(index, index + 1, 'down');
                      }}
                    />
                  </div>
                );
              }}
            />
          </VSTable>
          {/* </DndProvider> */}
          <div style={{ marginTop: 20 }} className="other_info_date_view">
            {/* 呼吸机使用时间 */}
            <VSFormLayout key="22" columnCount={3}>
              <VSFormLayout
                key="23"
                columnCount={4}
                // labelCol={labelColWidth[10]}
                labelWidth={labelNumberWidth(15)}
              >
                <div className="other_info_date_view_text">
                  呼吸机使用时间 :
                </div>
                {/* <VSFormItem
                  name={['respiratorUseDay']}
                  label="呼吸机使用时间"
                  valueType="text"
                  fieldProps={{
                    placeholder: '',
                    allowClear: false,
                    className: 'respiratorUseDay',
                  }}
                  addonAfter="天"
                  rules={[
                    {
                      pattern: numberAndNegative,
                      message: '呼吸机使用时间格式错误',
                    },
                  ]}
                /> */}

                <VSFormItem
                  name={['respiratorUseDay']}
                  valueType="text"
                  fieldProps={{
                    placeholder: '',
                    allowClear: false,
                    className: 'other_info_date_view_inp',
                  }}
                  addonAfter={<div>天</div>}
                  rules={[
                    {
                      pattern: numberAndNegative,
                      message: '呼吸机使用时间格式错误',
                    },
                  ]}
                />

                <VSFormItem
                  name={['respiratorUseHour']}
                  // label="呼吸机使用时间（时）"
                  valueType="text"
                  fieldProps={{
                    placeholder: '',
                    allowClear: false,
                    className: 'other_info_date_view_inp',
                  }}
                  addonAfter={<div>时</div>}
                  rules={[
                    {
                      pattern: hour,
                      message: '呼吸机使用时间格式错误',
                    },
                  ]}
                />
                <VSFormItem
                  name={['respiratorUseMinute']}
                  // label="呼吸机使用时间（分）"
                  valueType="text"
                  fieldProps={{
                    placeholder: '',
                    allowClear: false,
                    className: 'other_info_date_view_inp',
                  }}
                  addonAfter={<div>分</div>}
                  rules={[
                    {
                      pattern: minute,
                      message: '呼吸机使用时间格式错误',
                    },
                  ]}
                />
              </VSFormLayout>
            </VSFormLayout>
          </div>
        </FormTitle>
      </VSForm>
    </div>
  );
};
export default definePage(Index);
