import vsf from '@vs/vsf-boot';
import {
  compose,
  Input,
  InputNumber,
  InputProps,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useInputEnter } from './utils';

/**
 * 合并单位的 Input
 */
let InputUnit: any = (props, ref) => {
  const { value, onChange, onFieldEnter, onKeyUp, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });

  const [current, setCurrent, getCurrent] = useGetState<any>('');
  const [unit, setUnit, getUnit] = useGetState<any>('');

  const valueRef = useRef<any>();

  const handleChange = (v) => {
    const formatValue =
      valueRef?.current === 1
        ? {
            value: v,
            unit: getUnit(),
          }
        : v;
    onChange?.(formatValue);
  };

  useEffect(() => {
    if (typeof value === 'object') {
      setCurrent(value?.value);
      setUnit(value?.unit);
      valueRef.current = 1;
    } else {
      setCurrent(value);
      valueRef.current = 2;
    }
  }, [value, setCurrent, setUnit]);

  return (
    <div>
      <InputNumber
        {...rest}
        ref={ref}
        value={current}
        onChange={handleChange}
        {...(unit ? { addonAfter: unit } : {})}
        {...fieldEnterProps}
        onFocus={(v) => {
          v?.currentTarget?.select();
        }}
      />
    </div>
  );
};

InputUnit.displayName = 'InputUnit';

InputUnit = React.forwardRef(InputUnit);

export const DosageUnit = (props) => {
  const target = props?.value;
  if (typeof target === 'object') {
    return (
      <>
        {Object.values?.({
          value: props?.value?.value,
          unit:
            vsf?.stores?.dbenums?.enums?.DOSAGE_UNITS_DICT?.find(
              (item) => item?.value == target?.unit,
            )?.label ?? target?.unit,
        })}
      </>
    );
  } else {
    return <>{props?.value}</>;
  }
};

export const UsageUnit = (props) => {
  const target = props?.value;
  if (typeof target === 'object') {
    return (
      <>
        {Object.values?.({
          value: props?.value?.value,
          unit:
            vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
              (item) => item?.value == target?.unit,
            )?.label ?? target?.unit,
        })}
      </>
    );
  } else {
    return <>{props?.value}</>;
  }
};

export default compose(
  withRef(),
  withField<any>({
    name: 'inputUnit',
    handleEnter: true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        const target = props?.value;
        return props?.is === 'usage' ? (
          <UsageUnit value={target} />
        ) : (
          <DosageUnit value={target} />
        );
      },
    },
  ),
)(InputUnit) as typeof InputUnit;
