import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugImportExportController_deleteImportExportClass_3f381f: {
    method: 'post',
    url: '/api/drug-storage/delete-import-export-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_getExportImportTypeDictionary_063cf2: {
    method: 'post',
    url: '/api/drug-storage/get-export-import-type-dictionary',
    parameterFomatter: (data?: { isImport: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: ImportExportClassDictionaryVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_createOrUpdateImportExportTypeDictionary_488414: {
    method: 'post',
    url: '/api/drug-storage/create-or-update-import-export-type-dictionary',
    parameterFomatter: (data?: {
      paramList: CreateOrUpdateExportImportTypeDictionaryParameterEoDrugStoragePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
