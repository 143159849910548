import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Cascader,
  CascaderProps,
  Col,
  compose,
  DataType,
  Input,
  InputProps,
  Row,
  RowProps,
  // Select,
  SelectProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import { number } from 'echarts';
import React, { useMemo } from 'react';

import CodeTableSelect from '../CodeTableSelect';
import askSearch from '../CodeTableSelect/askRomanAlphabet';
import getData from './getData';

export type CertificateTypeValueProps = {
  identificationType?: any;
  identificationNum?: any;
};
export type CertificateTypeProps = {
  /**
   * 默认值
   */
  defaultValue?: CertificateTypeValueProps;
  /**
   * 值
   */
  value?: CertificateTypeValueProps;
  /**
   * 值变化回调
   */
  onChange?: (value?: CertificateTypeValueProps) => void;

  /**
   * Select属性
   */
  selectProps?: any;

  /**
   * Input属性
   */
  inputProps?: InputProps;
  /**
   * Select属性
   */
  placeholderInput?: string;
  /**
   * Input属性
   */
  placeholderSelect?: string;
};

/**
 * Address
 */
const CertificateType = (props: CertificateTypeProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    selectProps,
    inputProps,
    placeholderSelect = '请选择证件类型',
    placeholderInput = '请输入证件编号',
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const handleSelectChange = (_, v) => {
    console.log(_, value);
    if (!v) {
      setValue({ ...value, identificationType: null });
    } else {
      setValue({ ...value, identificationType: v?.value });
    }
  };

  const handleInputChange = (v) => {
    if (!v || v?.length <= 0) {
      setValue({ ...value, identificationNum: null });
    } else {
      setValue({ ...value, identificationNum: v });
    }
  };

  return (
    <>
      <Row justify="start" wrap={false} {...rest}>
        <Col span={8}>
          <Select
            placeholder={placeholderSelect || '请选择证件类型'}
            options={vsf.stores.dbenums?.enums?.IDENTIFICATION_CLASS_DICT}
            value={value?.identificationType}
            onChange={handleSelectChange}
            showSearch
            allowClear
            filterOption={askSearch}
            fieldNames={{ label: 'label', value: 'value' }}
            // {...selectProps}
          />
        </Col>
        <Col span={16}>
          <Input
            placeholder={placeholderInput || '请输入证件编号'}
            {...inputProps}
            value={value?.identificationNum}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};
CertificateType.displayName = 'CertificateType';

export default compose(
  withField<CertificateTypeProps>({
    name: 'CertificateType',
  }),
  withPreview<CertificateTypeProps>({
    renderPreview: (props) => {
      const { value } = props;
      /** 返回预览模式下的dom */

      return <div>{value?.identificationType}</div>;
    },
  }),
)(CertificateType);
