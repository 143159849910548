import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryErpPreExamBedSearchWithPatientQto_ec5869: {
    method: 'post',
    url: '/api/clinic-bed/query-erp-pre-exam-bed-search-with-patient-qto',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ErpPreExamBedSearchVoClinicBedEntranceWebVo,
    ) => data,
  },
});
