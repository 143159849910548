import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  VSTableAddition,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getScrollY } from '@/utils';
import Icon from '@/module/Icon';
import StandardApplicationParameterEdit from './edit';
import Tip from '@/pages/Index/components/func/Tip';
import { useFetch } from '@/pages/Index/components/func/hooks';
const StandardApplicationParameter = (props) => {
  const ref = useRef(null);

  /**
   * 转换
   * @param {object} value
   * @returns {object}
   */
  const valueToApplicationId = (value) => {
    if (!value) {
      return {};
    }
    return { applicationId: value?.id };
  };
  const [state, setState] = useState({});
  const handleFetch = useCallback(async (params) => {
    if (!params.extra?.applicationId) {
      return {
        data: [],
        total: 0,
      };
    } else {
      const res =
        await vsf?.services?.ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2?.(
          params.extra,
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);
  const onRemove = useFetch({
    fetch: async (params) => {
      const res =
        await vsf?.services?.ApplicationBOController_deleteParameterFromApplication_c87cde?.(
          {
            btoParam: {
              id: params.applicationId,
              applicationVsLocalParameterBtoList: [{ id: params.id }],
            },
          },
        );
      return res;
    },
    message: '删除',
    success: () => {
      ref?.current?.reload();
    },
  });
  return (
    <VSPromiseTable
      scroll={{
        y: getScrollY(569),
      }}
      className="table_overflow_340 aspirin-table aspirin-table-nopadding aspirin-table-no-background-box"
      searchConfig={{
        labelWidth: 90,
        collapsed: false,
        collapseRender: false,
      }}
      ref={ref}
      form={{
        labelAlign: 'left ',
      }}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        onCanDelete: () => false,
        extraOperations: [
          {
            children: <Icon type="icon-shanchu" size={24} />,
            order: 1,
            onClick: (event, data) => {
              Tip({
                content: `是否删除数据：{【${data.localConfig.name}】}`,
                onOk: () => {
                  onRemove(data);
                },
              });
            },
          },
        ],
      }}
      vsid="76426"
      onFetch={handleFetch}
      onAdd={async (params) => {
        const res =
          await vsf?.services?.ApplicationBOController_addParameterToApplication_9c766b?.(
            {
              btoParam: {
                applicationVsLocalParameterBtoList: [
                  {
                    ...params.applicationVsLocalParameterBtoList,
                    applicationLocalGroup:
                      params.applicationVsLocalParameterBtoList
                        ?.applicationLocalGroup?.[0],
                    applicationLocalSubGroup:
                      params.applicationVsLocalParameterBtoList
                        ?.applicationLocalSubGroup?.[0],
                  },
                ],
                id: props?.application.id,
              },
            },
          );
        return res?.data;
      }}
      pagination={false}
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.ApplicationBOController_updateParameterVsApplication_7e4468?.(
            {
              btoParam: {
                applicationVsLocalParameterBtoList: [
                  {
                    ...params.applicationVsLocalParameterBtoList,
                    id: params?.id,
                    applicationLocalGroup:
                      params.applicationVsLocalParameterBtoList
                        ?.applicationLocalGroup?.[0],
                    applicationLocalSubGroup:
                      params.applicationVsLocalParameterBtoList
                        ?.applicationLocalSubGroup?.[0],
                  },
                ],
                id: props?.application.id,
              },
            },
          );
        return res?.data;
      }}
      addLinkPage={{
        linkPage: () => (
          <StandardApplicationParameterEdit
            applicationClass={props?.application?.applicationClass}
          />
        ),
        modalProps: {
          title: '新增参数',
          className: 'aspirin-linkpage',
          width: '40%',
          footer: <div>123</div>,
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.ParameterForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        updateType: 'reload',
      }}
      updateLinkPage={{
        linkPage: () => (
          <StandardApplicationParameterEdit
            applicationClass={props?.application?.applicationClass}
          />
        ),
        modalProps: {
          title: '编辑参数',
          className: 'aspirin-linkpage',
          width: '50%',
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.ParameterForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        updateType: 'reload',
      }}
      rowSelection={{
        onSelectedRowsChange: (_value) => {
          props.onChange?.(_value);
        },
      }}
      extraParams={valueToApplicationId(props.application)}
    >
      <VSTableColumn
        title="序号"
        valueType="index"
        fieldProps={{}}
        width={68}
        render={(_, __, index) => index + 1}
      />
      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        editable={false}
        valueType="digit"
        fieldProps={{}}
        hideInTable
      />
      <VSTableColumn
        dataIndex={['localConfig', 'name']}
        title="参数代码"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'local_config_name长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
          ],
        }}
        fieldProps={{}}
        width={286}
      />

      <VSTableColumn
        dataIndex={['localConfig', 'cnName']}
        title="参数名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'local_config_cn_name长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['localValue']}
        title="参数值"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'local_value长度不合法',
              type: 'string',
              min: 0,
              max: 1000,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['localValueDescription']}
        title="参数值说明"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'local_value_des长度不合法',
              type: 'string',
              min: 0,
              max: 1000,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['applicationLocalGroup']}
        title="参数分组名"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'application_local_group长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['applicationLocalSubGroup']}
        title="参数子分组名"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'application_local_sub_group长度不合法',
              type: 'string',
              min: 0,
              max: 100,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableToolbar title="参数设置">
        <VSTableAddition size="large" className="icon16" children="添加" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(StandardApplicationParameter);
