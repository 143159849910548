/* eslint-disable react-hooks/exhaustive-deps */
import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  message,
  VSPromiseTable,
  VSTableColumn,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';

import ComplexSelect, {
  ComplexSelectProps,
  useComplexSelect,
} from '@/pages/Index/components/complex_select';
import { askFixed } from '@/pages/Index/components/func/ask';
import { user } from '@/stores';

import { useInputEnter } from '../dosage/utils';

// import { useInputEnter } from '../Dosage/utils';

const TableRender = (props) => {
  const { onFetch, ...rest } = props;
  const columns = [
    {
      title: '床号',
      dataIndex: ['bedLabel'],
    },
    {
      title: '住院号',
      dataIndex: ['inpVisitNumber'],
      width: 200,
    },
    {
      title: '姓名',
      dataIndex: ['name'],
      // dataSource: vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT,
      valueType: 'text',
    },
    {
      title: '患者ID',
      dataIndex: ['displayId'],
    },
    {
      title: '性别',
      dataIndex: ['select'],
      dataSource: vsf.stores.dbenums?.enums?.SEX_DICT,
    },
  ];

  const style: React.CSSProperties = {
    fontSize: 14,
    color: '#aeb0b3',
    marginBottom: 10,
  };

  const method = useComplexSelect?.();
  const onDrugDetailsFetch = useCallback(async (drugItemId) => {
    const res =
      await vsf.services?.ClinicRecordOrderDrugController_getOrderDrugForTemp_e41d51?.(
        {
          drugItemId,
        },
      );
    return res?.data;
  }, []);

  return (
    <Fragment>
      <div style={style}>输入序号选择项目，键盘“← →”翻页</div>
      <VSPromiseTable
        onFetch={onFetch}
        id="TableRender"
        pagination={{
          position: ['bottomRight'],
          defaultPageSize: 8,
        }}
        rowKey="key"
        rowClick={{
          rowClickType: 'select',
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          selectedRows: rest?.rowSelection?.selectedRowKeys,
          onSelectedRowsChange: (value: any) => {
            const [state] = value;
            console.log(state);
            method?.onChange({
              ...state,
            });
          },
        }}
        size="small"
      >
        {columns?.map((item, index) => {
          return <VSTableColumn key={index} {...item} />;
        })}
      </VSPromiseTable>
    </Fragment>
  );
};

enum SelectedOmit {
  onChange,
  value,
  showLabel,
  mode,
  dropdownMatchSelectWidth,
  dropdownStyle,
  hideSelectIcon,
  keyDown,
  keyDownDeploy,
  children,
  dataSource,
}

type SelectedProps = Omit<ComplexSelectProps, keyof typeof SelectedOmit> & {
  onChange?: (value: any) => void;
  value?: any;
  schema: any;
  config: any;
  form: any;
  dataSource: any[];
  dataSourceParams: any;
};

type DataValue = {
  label: string;
  value: number;
};

const PatientSelect = forwardRef((props: SelectedProps, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    dataSource,
    dataSourceParams,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form?.useFormInstance();
  const key = Object?.keys(form?.getFieldsValue())?.at(-1) as string;
  const record = form?.getFieldValue(key);

  const handleOnChange = async (value) => {
    if (value?.value === '') {
      form?.resetFields();
    } else {
      form?.setFieldsValue({
        [key]: {
          ...record,
          ...value,
          id: value?.id,
          inpVisitIdIs: value?.bedLabel,
        },
      });
      onChange?.({
        label: value?.name,
        value: value?.inpVisit?.id,
      });
    }
  };

  const [search, setSearch] = useState({
    inputCodeLike: '',
  });
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.DrugPrescriptionDispenseController_queryDischargeBedDropListQto_3852ec?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 8,
              wardIdIs: dataSourceParams,
            },
            ext: {},
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      if (res?.code === 200) {
        return res?.data?.result?.map((item, index) => ({
          ...item,
          inpVisitId: item?.inpVisit?.id,
          bedLabel: item?.inpVisit?.patientInHospital.bed?.bedLabel,
          bedId: item?.inpVisit?.patientInHospital.bed?.id,
          inpVisitNumber: item?.inpVisit?.inpVisitNumber,
          name: item?.patient?.name,
          displayId: item?.patient?.displayId,
          gender: item?.patient?.gender,
          index: index + 1,
          key: index + 1,
        }));
      }
    },
    [dataSourceParams],
  );
  const onSearch = (value) => {
    setSearch({
      inputCodeLike: value,
    });
  };
  return (
    <ComplexSelect
      onChange={handleOnChange}
      onSearch={onSearch}
      value={value}
      showLabel="drugName"
      ref={ref as any}
      mode="radio"
      dropdownMatchSelectWidth={500}
      dropdownStyle={{}}
      listHeight={500}
      hideSelectIcon
      allowClear={true}
      keyDownDeploy={{
        ArrowLeft: () => {
          vsf?.refs?.TableRender?.gotoPreviousPage();
        },
        ArrowRight: () => {
          vsf?.refs?.TableRender?.gotoNextPage();
        },
      }}
      {...rest}
      {...fieldEnterProps}
    >
      <TableRender onFetch={onFetch} dataSource={dataSource} />
    </ComplexSelect>
  );
});

PatientSelect.displayName = 'patientSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'patientSelect',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      if (typeof value === 'object') {
        return <>{value?.label}</>;
      }
      return <>{value}</>;
    },
  }),
)(PatientSelect) as typeof PatientSelect;
