// 查询列表入参
export const convertVSPromiseTableBedPrintPreviewListQro2QueryPagefallByClinicBedWithPatientQtoReq =
  (input) => {
    const _ = window._ ?? {};
    const reqParamSearch = {};
    reqParamSearch.wardIdIs = input?.wardId;
    reqParamSearch.bedStatusIs = '1';
    const printStatus = _.get(input, 'status');
    if (printStatus == 'UN_PRINT') {
      reqParamSearch.printIndicatorIs = false;
    } else if (printStatus == 'PRINTED') {
      reqParamSearch.printIndicatorIs = true;
    }
    return reqParamSearch;
  };
//   查询列表返回
export const convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewListRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ..._.get(input, 'data.result').map((d, i) => {
        const obj = {};
        obj.bedId = _.get(d, 'id');
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
        obj.patientDisplayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
        obj.wardId = _.get(d, 'wardId');
        return obj;
      }),
    );
    return output;
  };
// 预览列表:床头卡,姓名小卡
export const convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewCard =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.bedId = _.get(d, 'id');
        obj.wardId = _.get(d, 'wardId');
        obj.wardName = _.get(d, 'wardName');
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
        obj.patientDisplayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
        obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
        obj.patientGender = _.get(d, 'clinicPatientBedDto.patientGender');
        obj.patientAge = _.get(d, 'clinicPatientBedDto.patientAge');
        obj.admissionWardDateTime = _.get(
          d,
          'clinicPatientBedDto.admissionWardDateTime',
        );
        obj.nursingClass = _.get(d, 'clinicPatientBedDto.nursingClass');
        var patientAllergyNamesList = [];
        patientAllergyNamesList.push(
          ...(_.get(d, 'clinicPatientBedDto.patientAllergy')?.map((pa, i) => {
            var patientAllergyNames = [
              _.get(pa, 'allergyDrugProductName'),
              _.get(pa, 'allergyDrugName'),
              _.get(pa, 'allergyDrugClassName'),
            ];
            return patientAllergyNames.find((e) => !!e);
          }) ?? []),
        );
        obj.patientAllergy = patientAllergyNamesList
          .filter((e) => !!e)
          .join('|');
        obj.diagnosisDescription = _.get(
          d,
          'clinicPatientBedDto.diagnosisDescription',
        );
        obj.attendingDoctorName = _.get(
          d,
          'clinicPatientBedDto.attendingDoctorName',
        );
        obj.medicalGroupName = _.get(d, 'clinicPatientBedDto.medicalGroupName');
        obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');
        obj.inpEscortRegisterList = [];
        obj.inpEscortRegisterList.push(
          ...(_.get(d, 'inpEscortRegisterList')?.map((ier, i) => {
            const ierObj = {};
            ierObj.id = _.get(ier, 'id');
            ierObj.inpVisitId = _.get(ier, 'inpVisitId');
            ierObj.age = _.get(ier, 'age');
            ierObj.escortIdentityNumber = _.get(ier, 'escortIdentityNumber');
            ierObj.escortName = _.get(ier, 'escortName');
            ierObj.escortPhoneNumber = _.get(ier, 'escortPhoneNumber');
            ierObj.gender = _.get(ier, 'gender');
            ierObj.printTimes = _.get(ier, 'printTimes');
            return ierObj;
          }) ?? []),
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
// 预览列表:陪护腕带
export const convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewInpEscortRegisterManage =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.bedId = _.get(d, 'id');
        obj.bedLabel = _.get(d, 'bedLabel');
        obj.patientId = _.get(d, 'clinicPatientBedDto.patientId');
        obj.patientName = _.get(d, 'clinicPatientBedDto.patientName');
        obj.patientDisplayId = _.get(d, 'clinicPatientBedDto.patientDisplayId');
        obj.inpVisitId = _.get(d, 'clinicPatientBedDto.inpVisitId');
        obj.inpEscortRegisterList = [];
        obj.inpEscortRegisterList.push(
          ...(_.get(d, 'inpEscortRegisterList')?.map((ier, i) => {
            const ierObj = {};
            ierObj.id = _.get(ier, 'id');
            ierObj.inpVisitId = _.get(ier, 'inpVisitId');
            ierObj.age = _.get(ier, 'age');
            ierObj.escortIdentityNumber = _.get(ier, 'escortIdentityNumber');
            ierObj.escortName = _.get(ier, 'escortName');
            ierObj.escortPhoneNumber = _.get(ier, 'escortPhoneNumber');
            ierObj.gender = _.get(ier, 'gender');
            ierObj.printTimes = _.get(ier, 'printTimes');
            return ierObj;
          }) ?? []),
        );
        return obj;
      }) ?? []),
    );
    // console.debug('resultTableData', output);
    return output;
  };

// 打印
export const convertVSPromiseTableBedPrintPreviewCard2UpdatePatientInHospitalListReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoList = [];
    output.btoList.push(
      ...(input?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'inpVisitId');
        obj.patientInHospitalBto = {};
        obj.patientInHospitalBto.printDate = +new Date(); // Date 床头卡打印时间
        obj.patientInHospitalBto.printWardId = _.get(d, 'wardId'); // Long 床头卡打印病区编码
        obj.patientInHospitalBto.printBedId = _.get(d, 'bedId'); // Long 床头卡打印床号
        return obj;
      }) ?? []),
    );
    return output;
  };

// 姓名小卡list
export const nameCardList = [
  { label: '床号', value: 'bedLabel' },
  { label: '住院号', value: 'inpVisitId' },
  { label: '姓名', value: 'patientName' },
  { label: '年龄', value: 'patientAge' },
  { label: '性别', value: 'patientGender' },
  { label: '诊断', value: 'diagnosisDescription' },
  { label: '主诊组', value: 'medicalGroupName' },
  { label: '入科时间', value: 'admissionWardDateTime' },
];
// 床头卡list
export const bedsideCardList = [
  { label: '病区', value: 'wardName' },
  { label: '床号', value: 'bedLabel' },
  { label: '性别', value: 'patientGender' },
  { label: '年龄', value: 'patientAge' },
  { label: '入科时间', value: 'admissionWardDateTime' },
];
// 陪护腕带信息
export const accompanyingWristband = [
  { label: '陪护人姓名', value: 'escortName' },
  { label: '陪护人性别', value: 'gender' },
  { label: '陪护人年龄', value: 'age' },
  { label: '身份证号码', value: 'escortIdentityNumber' },
  { label: '电话号码', value: 'escortPhoneNumber' },
  { label: '打印次数', value: 'printTimes' },
];
