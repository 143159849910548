import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemController_getClinicItemDictionaryLabById_ada097: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-lab-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryLabVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b: {
    method: 'post',
    url: '/api/clinic-item-base/get-item-and-price-by-clinic-item-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemPricePerformDepartmentVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ExamOrderController_getExamItemInfo_3da8d6: {
    method: 'post',
    url: '/api/exam/order/get-exam-item-info',
    parameterFomatter: (data?: { examV_sClinicIds: number[] }) => data,
    responseFormatter: (_, __, data?: ExamItemInfoEoExamPersistEo) => data,
  },
  ClinicRecordOrderController_getOrderDetailById_6f461b: {
    method: 'post',
    url: '/api/clinic-record-order/get-order-detail-by-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo,
    ) => data,
  },
});
