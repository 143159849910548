import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSForm,
  Input,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';
import HeaderInfo from '@/pages/Index/components/head_info';
import { convertGetListWaitInpPreTransferApplyVoByToWardIdRes2WaitInpPreTransferApplyList } from './convert';

const Index = (props, ref) => {
  const { modalProps, content } = props;
  const [open, setOpen] = useState(false);
  // console.log(modalProps);
  const handeOpen = (value) => {
    setOpen(value);
  };
  useImperativeHandle(ref, () => ({
    handeOpen,
  }));
  // console.log(modalProps, '7412_modalProps');
  return (
    <Modal
      open={open}
      {...{
        centered: true,
        cancelText: '取消',
        maskClosable: true,
        destroyOnClose: true,
        preserve: false,
        ...modalProps,
      }}
      title={
        <div className="nurse_station_out_hospital_modal_title">
          {modalProps?.title}
        </div>
      }
      onCancel={() => {
        handeOpen(false);
      }}
      footer={[
        <Button
          type="primary"
          ghost
          onClick={() => {
            handeOpen(false);
          }}
        >
          取消
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
};
export default forwardRef(Index);
