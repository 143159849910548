// 列表
export const convertQueryAllByQueryCancelInAndOutTransferQtoRes2CancelInAndOutTransferPatientList =
  (input) => {
    const _ = window._ ?? {};
    const outputs = [];
    outputs.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const output = {};
        output.groupId = _.get(input, `data.result.[${i}].groupId`);

        output.id = _.get(input, `data.result.[${i}].inpVisitVo.id`);
        output.name = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.name`,
        );
        output.displayId = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.displayId`,
        );
        output.patientId = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.id`,
        );
        output.inpVisitId = _.get(input, `data.result.[${i}].inpVisitVo.id`);
        output.gender = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.gender`,
        );
        output.age = _.get(input, `data.result.[${i}].inpVisitVo.patient.age`);
        output.birthday = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.birthday`,
        );
        output.identityCode = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpVisitExtensionBillingInfoDto.identityCode`,
        );
        output.chargeType = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpVisitExtensionBillingInfoDto.chargeType`,
        );
        output.prePaymentAmount = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientBillingRecordBaseDto.prePaymentAmount`,
        );
        output.contact = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.patientProfileDto.contact`,
        );
        output.phoneNumber = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patient.phoneNumber`,
        );
        output.admissionDepartmentName = _.get(
          input,
          `data.result.[${i}].inpVisitVo.admissionToDepartment.departmentName`,
        );
        output.admissionDateTime = _.get(
          input,
          `data.result.[${i}].inpVisitVo.admissionDateTime`,
        );
        output.admissionDeptDateTime = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList.[${0}].admissionDateTime`,
        );
        output.inpTransferRecordId = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList.[${0}].id`,
        );
        output.transferDepartmentName = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList.[${0}].transferToDepartment.departmentName`,
        );
        output.transferDepartmentId = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList.[${0}].department.id`,
        );
        output.dischargeDeptDateTime = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList[${0}].dischargeDateTime`,
        );
        output.transferDateTime = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList[${0}].transferDateTime`,
        );
        output.dischargeDepartmentName = _.get(
          input,
          `data.result.[${i}].inpVisitVo.dischargeFromDepartment.departmentName`,
        );
        output.inpTransferRecordForMedicalRecordDtoList = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpTransferRecordForMedicalRecordDtoList`,
        );
        output.dischargeDateTime = _.get(
          input,
          `data.result.[${i}].inpVisitVo.dischargeDateTime`,
        );
        output.dischargeWay = _.get(
          input,
          `data.result.[${i}].inpVisitVo.dischargeWay`,
        );
        output.totalCost = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientBillingRecordBaseDto.totalCost`,
        );
        output.auditStatus = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientBillingRecordBaseDto.auditStatus`,
        );
        output.settleIndicator = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientBillingRecordBaseDto.settleIndicator`,
        );
        output.bed = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientInHospitalBedDto.bed`,
        );
        output.nursingClass = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientInHospitalBedDto.nursingClass`,
        );
        output.patientCondition = _.get(
          input,
          `data.result.[${i}].inpVisitVo.patientInHospitalBedDto.patientCondition`,
        );
        output.attendingDoctor = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor`,
        );
        output.medicalGroupLeader = _.get(
          input,
          `data.result.[${i}].inpVisitVo.inpVisitExtensionStaffDto.medicalGroupLeader`,
        );
        output.status = _.get(input, `data.result.[${i}].status`);
        return output;
      }) ?? []),
    );
    return outputs;
  };

// 保存
export const convertCancelInAndOutTransferForm2CancelInAndOutTransferPatientReq =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.saveInpVisitForCancelInAndOutTransferBto = {};
    output.saveInpVisitForCancelInAndOutTransferBto.id = _.get(
      input,
      'inpVisitId',
    );
    output.saveInpVisitForCancelInAndOutTransferBto.dischargeDateTime = _.get(
      input,
      'dischargeDateTime',
    );
    output.saveInpVisitForCancelInAndOutTransferBto.dischargeWay = _.get(
      input,
      'dischargeWay',
    );
    output.saveInpVisitForCancelInAndOutTransferBto.inpVisitExtensionStaffBto =
      {}; // TODO 请确认下行字段是否符合逻辑
    output.saveInpVisitForCancelInAndOutTransferBto.inpVisitExtensionStaffBto.attendingDoctorId =
      _.get(input, 'attendingDoctor.id');
    output.saveInpVisitForCancelInAndOutTransferBto.inpVisitExtensionStaffBto.medicalGroupLeaderId =
      _.get(input, 'medicalGroupLeader.id');

    output.saveInpVisitForCancelInAndOutTransferBto.patientInHospitalBto = {}; // TODO 请确认下行字段是否符合逻辑
    output.saveInpVisitForCancelInAndOutTransferBto.patientInHospitalBto.bedId =
      _.get(input, 'bed.id') ?? _.get(input, 'bed'); // TODO 请确认下行字段是否符合逻辑
    output.saveInpVisitForCancelInAndOutTransferBto.patientInHospitalBto.nursingClass =
      _.get(input, 'nursingClass');
    console.log(input, 'input');
    output.updateInpTransferRecordBto =
      _.get(input, 'inpTransferRecordForMedicalRecordDtoList.[0]') ?? {};
    // output.updateInpTransferRecordBto = {}; // TODO 没有匹配到合适字段 output.updateInpTransferRecordBto.patientId = null; // Long 病人标识号
    output.updateInpTransferRecordBto.patientId = _.get(input, 'patientId');
    // output.updateInpTransferRecordBto.admissionDateTime = _.get(
    //   input,
    //   'admissionDeptDateTime',
    // );
    // output.updateInpTransferRecordBto.dischargeDateTime = _.get(
    //   input,
    //   'dischargeDeptDateTime',
    // );
    // output.updateInpTransferRecordBto.id = _.get(input, 'inpTransferRecordId');
    output.cancelInAndOutTransferEnum = _.get(input, 'status');
    return output;
  };
