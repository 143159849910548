import { createServices } from '@vs/vsf-boot';
export default createServices({
  ReceiptUseRecordController_getAllByReceiptUseRecordQto_34c13d: {
    method: 'post',
    url: '/api/finance-receipt/get-all-by-receipt-use-record-qto',
    parameterFomatter: (data?: {
      qto: ReceiptUseRecordQtoFinanceReceiptPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo[],
    ) => data,
  },
  ReceiptUseRecordController_createUseRecord_2de2df: {
    method: 'post',
    url: '/api/finance-receipt/create-use-record',
    parameterFomatter: (data?: {
      btoParam: CreateUseRecordBtoFinanceReceiptServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo,
    ) => data,
  },
  ReceiptUseRecordController_deleteUseRecord_42d908: {
    method: 'post',
    url: '/api/finance-receipt/delete-use-record',
    parameterFomatter: (data?: {
      btoParam: DeleteUseRecordBtoFinanceReceiptServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ReceiptUseRecordVoFinanceReceiptEntranceWebVo,
    ) => data,
  },
});
