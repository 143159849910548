/**
 * 医嘱状态默认值
 */
export const orderStatusDefaultValue = 'OPEN';

/**
 * 医嘱状态数据源
 */
export const orderStatusDataSource = [
  { label: '执行', value: 'PERFORM' },
  { label: '停止', value: 'STOP' },
  { label: '作废', value: 'CANCEL' },
  { label: '审核未通过', value: 'AUDIT_FAIL' },
  { label: '待停', value: 'WAIT_STOP' },
  { label: '保存', value: 'SAVE' },
  { label: '提交', value: 'SUBMIT' },
  { label: '开立', value: 'OPEN' },
];

/**
 * 医嘱类型数据源
 */
export const orderClassDataSource = [
  { label: '西药', value: 'DRUG' },
  { label: '中药', value: 'HERB' },
  { label: '检验', value: 'LAB' },
  { label: '检查', value: 'EXAM' },
  { label: '病理', value: 'PATHOLOGY' },
  { label: '治疗', value: 'TREAT' },
  { label: '手术', value: 'OPERATION' },
  { label: '麻醉', value: 'ANESTHESIA' },
  { label: '护理', value: 'NURSING' },
  { label: '膳食', value: 'MEAL' },
  { label: '营养', value: 'NUTRITION' },
  { label: '会诊', value: 'CONSULTATION' },
  { label: '用血', value: 'BLOOD' },
  { label: '其他', value: 'OTHER' },
];

/**
 * 处置医嘱类型数据源
 */
export const disposalOrderClassDataSource = [
  { label: '治疗', value: 'TREAT' },
  { label: '麻醉', value: 'ANESTHESIA' },
  { label: '护理', value: 'NURSING' },
  { label: '膳食', value: 'MEAL' },
  { label: '营养', value: 'NUTRITION' },
  { label: '会诊', value: 'CONSULTATION' },
  { label: '用血', value: 'BLOOD' },
  { label: '其他', value: 'OTHER' },
];

/**
 * 长期医嘱类型数据源
 */
export const continuousOrderClassDataSource = [
  { label: '治疗', value: 'TREAT' },
  { label: '麻醉', value: 'ANESTHESIA' },
  { label: '护理', value: 'NURSING' },
  { label: '膳食', value: 'MEAL' },
  { label: '营养', value: 'NUTRITION' },
  { label: '其他', value: 'OTHER' },
];

/**
 * 长期、临时数据源
 */
export const repeatIndicatorDataSource = [
  {
    label: '长期',
    value: true,
  },
  {
    label: '临时',
    value: false,
  },
];

/**
 * 摆药方式数据源
 */

export const drugBillingAttributeDataSource = [
  {
    label: '正常',
    value: 'NORMAL',
  },
  {
    label: '自备药',
    value: 'SELF_PROVIDE',
  },
  // AS-4197 摆药只有三个
  // {
  //   label: '不摆药',
  //   value: 'NOT_DISPENSE',
  // },
  // {
  //   label: '药柜摆药',
  //   value: 'CABINET_DISPENSE',
  // },
  {
    label: '不计价',
    value: 'NOT_BILLING',
  },
];

export const drugUseDescriptionDataSource = [
  {
    code: '1',
    pinyinShortcut: 'CQ',
    deprecated: false,
    cname: '餐前',
    type: 'DRUG_USING_DESCRIPTION_DICT',
    pinyin: 'CQ',
    order: 1,
    label: '餐前',
    value: '1',
    children: [],
    data: {
      code: '1',
      pinyinShortcut: 'CQ',
      deprecated: false,
      cname: '餐前',
      type: 'DRUG_USING_DESCRIPTION_DICT',
      pinyin: 'CQ',
      order: 1,
    },
    isLeaf: false,
  },
];

/**
 * 必填form校验规则
 */
export const requiredRule = {
  rules: [
    {
      required: true,
    },
  ],
};

export const tableDataIndexList = [
  {
    key: 'orderText',
    defaultValue: '',
  },
  'dosage',
  'usage',
  'administration',
  'dripSpeed',
  'frequency',
  'useDescription',
  'repeatValidDays',
  'totalAmount',
  'drugBillingAttribute',
  'distribute',
  'dischargeDrug',
  'duration',
  'stopOrderDateTime',
  'stopOrderDoctor',
  'submitDateTime',
  'performDepartment',
  'allPrice',
  'memo',
  'doctor',
  'scheduleDateTime',
];

export const dataSourceHerb = [
  { label: '甲类', value: 'ALPHA' },
  { label: '乙类', value: 'BETA' },
  { label: '丙类', value: 'GAMMA' },
];
