interface FieldNames {
  parentId?: string;
  id?: string;
}

export default function askTree(list, fieldNames?: FieldNames) {
  const { parentId = 'parentId', id = 'id' } = fieldNames || {};
  const set = (parent?: any) => {
    return list
      ?.filter((item) => item?.[parentId] == parent?.[id])
      ?.map((item) => {
        return {
          ...item,
          children: set(item),
        };
      });
  };
  return set();
}
