import './index.less';

import { Image } from '@vs/vsf-kit';
import CSS from 'csstype';
import React from 'react';

import LoadingImageApng from './loading.png';
import LoadingImageApngXS from './loading_xs.png';

type LoadingPropsType = {
  size?: 'normal' | 'small';
  className?: string;
  style?: CSS.Properties;
  textStyle?: CSS.Properties;
  lineStyle?: CSS.Properties;
};

const Index = (props: LoadingPropsType) => {
  const {
    size = 'normal',
    style = {
      width: size === 'normal' ? 200 : 90,
    },
    className = '',
    textStyle = { marginTop: size === 'small' ? '-20px' : '' },
    lineStyle,
  } = props;

  const source = size === 'normal' ? LoadingImageApng : LoadingImageApngXS;

  return (
    <div className="my_loading">
      <Image className={className} src={source} preview={false} style={style} />
      {size === 'normal' && (
        <div style={lineStyle} className="my_loading_line"></div>
      )}
      <div style={textStyle}>加载中...</div>
    </div>
  );
};

export default Index;
