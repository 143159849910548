import vsf from '@vs/vsf-boot';
import { includes } from 'lodash';

const convertGetDispenseListForRefundRes2TableDispenseListRo = (input) => {
  const _ = window._ ?? {};
  const output: any = []; // TODO 请确认下行字段是否符合逻辑
  output.push(
    ..._.get(input, 'data').map((d, i) => {
      const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
      obj.prescriptionNumber = _.get(d, 'prescriptionNumber'); // TODO 请确认下行字段是否符合逻辑
      obj.prescriptionDate = _.get(d, 'prescriptionDate'); // TODO 请确认下行字段是否符合逻辑
      obj.prescriptionAttribute = _.get(d, 'prescriptionAttribute'); // TODO 请确认下行字段是否符合逻辑
      obj.orderClass = _.get(d, 'orderClass'); // TODO 请确认下行字段是否符合逻辑
      obj.dispenseStatus = _.get(d, 'dispenseStatus'); // TODO 请确认下行字段是否符合逻辑
      obj.orderDepartmentName = _.get(d, 'orderDepartmentName'); // TODO 请确认下行字段是否符合逻辑
      obj.orderDoctorName = _.get(d, 'orderDoctorName'); // TODO 请确认下行字段是否符合逻辑
      obj.diagnosisName = _.get(d, 'diagnosisName'); // TODO 请确认下行字段是否符合逻辑
      obj.masterId = _.get(d, 'masterId'); // TODO 请确认下行字段是否符合逻辑
      obj.dispenseDetail = _.get(d, 'dispenseDetail');
      return obj;
    }),
  );
  return output;
};

const convertGetDispenseListForRefundRes2FormPatInfo3 = (input) => {
  const _: any = window._ ?? {};
  const output: any = {}; // TODO 请确认下行字段是否符合逻辑
  output.displayId = _.get(input, 'data.patientVo.displayId'); // TODO 请确认下行字段是否符合逻辑
  output.name = _.get(input, 'data.patientVo.name'); // TODO 请确认下行字段是否符合逻辑
  output.age = _.get(input, 'data.patientVo.age'); // TODO 请确认下行字段是否符合逻辑
  output.cellphone = _.get(input, 'data.patientVo.cellphone'); // TODO 请确认下行字段是否符合逻辑
  output.idNumber = _.get(input, 'data.patientVo.idNumber'); // TODO 请确认下行字段是否符合逻辑
  output.phoneNumber = _.get(input, 'data.patientVo.phoneNumber');
  return output;
};

const convertGetDispenseListForRefundRes2TablePrescListRo = (
  input,
  { dispenseStatus, orderText },
) => {
  const _ = window._ ?? {};
  const output: any = []; // TODO 请确认下行字段是否符合逻辑
  output.push(
    ..._.get(input, 'data.prescriptionDispense')
      ?.filter((ele) =>
        dispenseStatus === 'ALL'
          ? true
          : ele?.dispenseStatus === dispenseStatus,
      )
      ?.filter((ele) =>
        orderText === ''
          ? true
          : ele?.dispenseDetail?.find((ele) =>
              ele?.orderText?.includes(orderText),
            ),
      )
      .map((d, i) => {
        const obj: any = {}; // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionNumber = _.get(d, 'prescriptionNumber'); // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionDate = _.get(d, 'prescriptionDate'); // TODO 请确认下行字段是否符合逻辑
        obj.prescriptionAttribute = _.get(d, 'prescriptionAttribute'); // TODO 请确认下行字段是否符合逻辑
        obj.orderClass = _.get(d, 'orderClass'); // TODO 请确认下行字段是否符合逻辑
        obj.dispenseStatus = _.get(d, 'dispenseStatus'); // TODO 请确认下行字段是否符合逻辑
        obj.orderDepartmentName = _.get(d, 'orderDepartmentName'); // TODO 请确认下行字段是否符合逻辑
        obj.orderDoctorName = _.get(d, 'orderDoctorName'); // TODO 请确认下行字段是否符合逻辑
        obj.diagnosisName = _.get(d, 'diagnosisName'); // TODO 请确认下行字段是否符合逻辑
        obj.masterId = _.get(d, 'masterId'); // TODO 请确认下行字段是否符合逻辑
        obj.dispenseDetail = d?.dispenseDetail
          ?.filter((ele) =>
            orderText === '' ? true : ele?.orderText?.includes(orderText),
          )
          ?.map((item) => {
            return {
              ...item,
              id: Number(`${d.masterId}${item?.detailId}`),
              ids: Number(`${d.masterId}`),
            };
          });
        return obj;
      }),
  );
  return output;
};

export const onFetchTable = async (params) => {
  const res =
    await vsf.services?.DrugPrescriptionRefundDispenseController_getDispenseListForRefund_3705e8?.(
      {
        qto: {
          ...params,
          patientIdIs: Number(params?.patientIdIs),
          extra: {},
          filters: {},
          orderList: [],
          pagination: { current: 1, size: 20, from: 0 },
          search: {},
        },
      },
    ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
  // TODO: 你需要自己补充入参input来源及出参output用法
  // TODO: 你需要自己补充入参input来源及出参output用法
  const output = convertGetDispenseListForRefundRes2TablePrescListRo(
    res,
    params,
  );
  return {
    data: output, //res?.data?.result ?? res?.data ?? [],
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};

export const onFetchForm = async (patientIdIs) => {
  const res =
    await vsf.services?.DrugPrescriptionRefundDispenseController_getDispenseListForRefund_3705e8?.(
      {
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          ...patientIdIs,
          extra: {},
          filters: {},
          orderList: [],
          pagination: { current: 1, size: 20, from: 0 },

          search: {},
        },
      },
    ); // Tips: 需要返回内容数据，结构和表单RO一致
  return convertGetDispenseListForRefundRes2FormPatInfo3(res);
};
