import React from 'react';

import { Section, VSTableColumn, VSTable, DateRangePicker } from '@vs/vsf-kit';
import { getScrollY, getScrollYRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';

const PrePaymentMasterList = ({ onChange, dataSource, selectTableData }) => {
  const arr1 = {
    WINDOW: '窗口',
    SELF_SERVICE_MACHINE: '自助机',
    WECHAT: '微信公众服务号',
    ALIPAY: '支付宝生活号',
    INP_PRE_ADMISSION: '入院准备中心',
    NURSE_STATION: '护士站',
  };
  return (
    <VSTable
      id="prePaymentMasterList"
      onSelect={() => {}}
      rowSelection={{
        selectType: 'single',
        hideColumn: true,
        onSelect: (node, key, selected) => {
          console.log(key);
        },
        onSelectedRowsChange: ([data], keys) => {
          // console.log(data);
          if (data?.id == selectTableData?.id) return onChange();
          onChange(data);
        },
      }}
      value={dataSource}
      rowClick={{
        rowClickType: 'select',
      }}
      scroll={{
        // y: getScrollY(580),
        y: getScrollYRem(28),
      }}
      rowClassName={(node) => {
        return node?.id == selectTableData?.id ? 'selected-row' : '';
      }}
      pagination={false}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        hideInTable
        valueType="digit"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['patientId']}
        title="病人标识号"
        valueType="digit"
        hideInTable
        formItemProps={{
          rules: [
            {
              message: '病人标识号的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['inpVisitId']}
        title="患者住院记录ID"
        valueType="digit"
        hideInTable
        formItemProps={{
          rules: [
            {
              message: '患者门诊流水号的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['prePaymentDate']}
        title="支付时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      <VSTableColumn
        dataIndex={['id']}
        title="业务流水号"
        valueType="text"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['physicalReceiptNumber']}
        title="票据号"
        valueType="text"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['operatorStaffId']}
        title="操作员编码"
        hideInTable
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '操作员编码的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['prePaymentStatus']}
        title="支付状态"
        valueType="select"
        dataSource={[
          { label: '(收)-收预交金', value: 'CASH_IN_RECEIVE_PRE_PAYMENT' },
          { label: '(支)-退预交金', value: 'CASH_OUT_REFUND_PRE_PAYMENT' },
          { label: '(收)-退医疗款', value: 'CASH_IN_REFUND_PAYMENT' },
          { label: '(支)-支付医疗款', value: 'CASH_OUT_PAYMENT' },
        ]}
        fieldProps={{}}
        render={(dom, value) => {
          if (
            value?.prePaymentStatus == 'CASH_IN_RECEIVE_PRE_PAYMENT' &&
            value?.refundPrePaymentId &&
            value?.refundPrePaymentId !== value?.id
          ) {
            return <div style={{ color: '#E34A4E' }}>(收)-收预交金[已退]</div>;
          } else if (
            value?.prePaymentStatus == 'CASH_IN_RECEIVE_PRE_PAYMENT' &&
            value?.refundPrePaymentId &&
            value?.refundPrePaymentId === value?.id
          ) {
            return (
              <div style={{ color: '#E34A4E' }}>(收)-收预交金[已作废]</div>
            );
          } else {
            return dom;
          }
        }}
      />
      <VSTableColumn
        dataIndex={['prePaymentAmount']}
        title="金额"
        valueType="digit"
        fieldProps={{}}
        render={(_, v) => {
          return Number(v.prePaymentAmount).toFixed(2);
        }}
      />
      <VSTableColumn
        dataIndex={['prePaymentDetailBaseDtoList']}
        title="付款途径"
        valueType="text"
        render={(_, record) => {
          let data = '';
          record?.prePaymentDetailBaseDtoList?.map((item) => {
            if (item?.paymentType === 'CASH') {
              data = `[${item?.paymentType === 'CASH' && '现金'} ${
                Number(item?.paymentAmount).toFixed(2) || '0.00'
              }]`;
            } else if (item?.paymentType === 'INTEGRATION_PAYMENT') {
              data = data.concat(
                `[聚合支付 ${
                  Number(item?.paymentAmount).toFixed(2) || '0.00'
                }]`,
              );
            }
          });
          return data;
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['receiptPrintStatus']}
        title="打印状态"
        valueType="select"
        dataSource={[
          { label: '待打印', value: 'WAIT_PRINT' },
          { label: '已打印', value: 'PRINT' },
          { label: '作废', value: 'CANCEL' },
        ]}
        fieldProps={{
          defaultValue: 'WAIT_PRINT',
        }}
      />
      <VSTableColumn
        dataIndex={['printStaffId']}
        title="打印操作员"
        valueType="digit"
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['printDate']}
        title="打印日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      //TODO
      {/* <VSTableColumn
        dataIndex={['posSearchNumber']}
        title="刷卡检索参考号"
        valueType="text"
      />
      <VSTableColumn dataIndex={['printDate']} title="卡号" valueType="text" />
      <VSTableColumn
        dataIndex={['printDate']}
        title="终端号"
        valueType="text"
      /> */}
      <VSTableColumn
        dataIndex={['operatorStaff', 'staffName']}
        title="收款员"
        valueType="text"
      />
      <VSTableColumn
        dataIndex={['prePaymentSource']}
        title="来源"
        valueType="select"
        dataSource={[
          { label: '窗口', value: 'WINDOW' },
          { label: '自助机', value: 'SELF_SERVICE_MACHINE' },
          { label: '微信公众服务号', value: 'WECHAT' },
          { label: '支付宝生活号', value: 'ALIPAY' },
          { label: '入院准备中心', value: 'INP_PRE_ADMISSION' },
          { label: '护士站', value: 'NURSE_STATION' },
        ]}
      />
      <VSTableColumn
        dataIndex={['receiptNumber']}
        title="支票号"
        valueType="text"
      />
      <VSTableColumn
        dataIndex={['refundPrePaymentId']}
        title="取消支付号"
        valueType="digit"
        hideInTable
        formItemProps={{
          rules: [
            {
              message: '取消支付号的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      //TODO
      <VSTableColumn
        dataIndex={['refundPrePaymentDate']}
        title="取消支付时间"
        valueType="date"
        hideInTable
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      <VSTableColumn
        dataIndex={['paymentMasterId']}
        title="预交金支付号"
        valueType="digit"
        hideInTable
        formItemProps={{
          rules: [
            {
              message: '预交金支付号的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['paymentDate']}
        title="预交金支付时间"
        valueType="date"
        hideInTable
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      <VSTableColumn
        dataIndex={['operationDate']}
        title="操作日期"
        hideInTable
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />
      <VSTableColumn
        dataIndex={['chargeGroupId']}
        title="收费组编码"
        hideInTable
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '收费组编码的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['depositType']}
        title="押金类型"
        hideInTable
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.DEPOSIT_TYPE_DICT}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        hideInTable
        valueType="text"
        formItemProps={{
          rules: [
            { message: '数据格式错误', type: 'string', min: 0, max: 200 },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['prePaymentDetailBaseDtoList']}
        title="主键"
        hideInTable
        valueType="listBox"
        fieldProps={{ children: <DateRangePicker /> }}
      />
      <VSTableColumn
        dataIndex={['outpVisitId']}
        title="患者门诊就诊记录id"
        hideInTable
        valueType="digit"
        formItemProps={{
          rules: [
            {
              message: '患者门诊就诊记录id的值不合法',
              type: 'number',
              min: 0,
              max: undefined,
            },
          ],
        }}
        fieldProps={{}}
      />
      <VSTableColumn
        dataIndex={['inpReceiptRecordBaseDtoList']}
        hideInTable
        title="主键"
        valueType="listBox"
        fieldProps={{ children: <DateRangePicker /> }}
      />
    </VSTable>
  );
};
export default definePage(PrePaymentMasterList);
