import vsf from '@vs/vsf-boot';
import {
  compose,
  Form,
  Input,
  InputNumber,
  InputProps,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import React from 'react';

import { useInputEnter } from '../dosage/utils';
import { askFixed } from '../func/ask';
import { UsageUnit } from '../usage';

/**
 * 合并单位的 Input
 */
let Amount: any = (props, ref) => {
  const { value, onFieldEnter, onKeyUp, onChange, record, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form.useFormInstance();
  return (
    <InputNumber
      {...rest}
      ref={ref}
      value={value?.value ?? value}
      onChange={(value) => {
        onChange?.(value);
        form.setFieldValue(
          [record?.id, 'allPrice'],
          askFixed(value ? Number(value) * Number(record?.retailPrice) : 0, 2),
        );
      }}
      {...fieldEnterProps}
    />
  );
};

Amount.displayName = 'Amount';

Amount = React.forwardRef(Amount);

export default compose(
  withRef(),
  withField<any>({
    name: 'amount',
    handleEnter: true,
    shouldFieldUpdate: () => true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        return <UsageUnit value={props?.value} />;
      },
    },
  ),
)(Amount) as typeof Amount;
