import vsf from '@vs/vsf-boot';
import { Button, Checkbox, message, RadioGroup, Select } from '@vs/vsf-kit';
import { useAsyncEffect, useEventListener } from 'ahooks';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import Icon from '@/module/Icon';

import Determine from '../../../determine';
import { useOnChangeValue } from '../../../func/hooks';
import { AuxiliaryRefs, Drug } from '..';
import { Refs, TableRefs } from '../table';
import { convertOrderInTable } from '../table/convert';

interface HeaderProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  table: {
    current: Drug & TableRefs & Refs;
  };
  eliminates?: any[];
}

export type HeaderRefs = {
  getState: () => any;
  onReload: () => Promise<any>;
};

const Header = forwardRef((props: HeaderProps, ref) => {
  const { auxiliary, table, eliminates = [] } = props;
  const {
    user: { outVisitInfo, getDoctorOrder },
  } = vsf?.stores;
  const items = [
    {
      name: '药疗[1]',
      icon: 'icon-yaoliao',
      key: 'drug',
      click: () => {
        table?.current?.onDrug?.();
      },
    },
    {
      name: '中草药[2]',
      icon: 'icon-zhongcaoyao',
      key: 'herb',
      click: async () => {
        auxiliary?.current?.onHerb();
      },
    },
    {
      name: '检查[3]',
      icon: 'icon-jiancha',
      key: 'exam',
      click: () => {
        auxiliary?.current?.onExam?.();
      },
    },
    {
      name: '检验[4]',
      icon: 'icon-huayan',
      key: 'lab',
      click: () => {
        auxiliary?.current?.onLab?.();
      },
    },
    {
      name: '处置[5]',
      icon: 'icon-chuzhi1',
      key: 'dispose',
      click: () => {
        auxiliary?.current?.onDispose?.();
      },
    },
  ];

  useEventListener(
    'keydown',
    (event) => {
      if (event?.altKey) {
        switch (event?.key) {
          case '1':
            event?.preventDefault();
            table?.current?.onDrug();
            break;
          case '2':
            event?.preventDefault();
            auxiliary?.current?.onHerb?.();
            break;
          case '3':
            event?.preventDefault();
            auxiliary?.current?.onExam?.();
            break;
          case '4':
            event?.preventDefault();
            auxiliary?.current?.onLab?.();
            break;
          case '5':
            event?.preventDefault();
            auxiliary?.current?.onDispose?.();
            break;
        }
      }
    },
    {
      target: document,
    },
  );

  return (
    <div className="extra-operation aspirin-order-module-header">
      <Determine
        condition={eliminates?.length ? eliminates?.includes('drug') : true}
        fallback={<div></div>}
      >
        <div className="left">
          药品成组请在途径内输入“/” 后按回车，或点击下方成组按钮
        </div>
      </Determine>
      <div className="right">
        {items
          ?.filter((item) =>
            eliminates?.length ? eliminates?.includes?.(item?.key) : true,
          )
          ?.map((item: any) => (
            <div onClick={item?.click} key={item?.icon}>
              <Icon
                size={item?.icon === 'icon-huzhi' ? 24 : 20}
                type={item?.icon}
              />
              <span>{item?.name}</span>
            </div>
          ))}
        <Button
          type="primary"
          onClick={() => auxiliary?.current?.onTemplate?.()}
        >
          医嘱模版导入
        </Button>
      </div>
    </div>
  );
});

Header.displayName = 'Header';

export default Header;
