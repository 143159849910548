import vsf, { definePage } from '@vs/vsf-boot';
import {
  Divider,
  Form,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import { useSetState, useUpdateEffect } from 'ahooks';
import React, { useCallback, useEffect, useRef } from 'react';
import Icon from '@/module/Icon';
import TreatItemEdit from '../edit';
import Tip from '@/pages/Index/components/func/Tip';
import SearchContainer from '@/module/Search';
import TableRender from '../../components/ComparePrice';
import { debounce } from 'lodash';
import { askFixed, askFlatTree } from '@/pages/Index/components/func/ask';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { Flex } from '@/pages/Index/components';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import int from '../../components/int';
import { getScrollYRem } from '@/utils';
const TreatItemFunc = (props) => {
  const table = useRef(null);
  const stopIndicatorIs = Form.useWatch(
    'stopIndicatorIs',
    table.current?.getSearchForm?.(),
  );
  const auditIndicatorIs = Form.useWatch(
    'auditIndicatorIs',
    table.current?.getSearchForm?.(),
  );

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ClinicItemBaseController_getAllByClinicItemTreatNewQtoByClinicItemTreatNewQto_93ae7e?.(
          {
            qto: {
              orderList: params.orderList, // 排序结果
              ...(props.data
                ? { classIdIn: askFlatTree([props?.data], 'id') }
                : {}),
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              stopIndicatorIs,
              auditIndicatorIs,
            },
          },
        );
      if (res.code === 200) {
        const id = res?.data?.find(
          (item) => item?.id === getDataStore()?.id?.id,
        );
        if (id) {
          setDataStore({
            id,
          });
        }
      }
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props.data, stopIndicatorIs, auditIndicatorIs],
  );

  const [dataStore, , getDataStore, , , setDataStore] = useGetResetInitState({
    initialState: [],
    data: [],
    id: {
      stopIndicator: true,
      auditIndicator: true,
    },
    dataSource: [],
  });

  const onStop = async (id) => {
    const res =
      await vsf?.services?.ClinicItemBaseController_stopClinicItem_49a98d?.({
        id,
      });
    if (res.code === 200) {
      vsf.refs.TreatItem.reload();
    }
  };
  const TableSubmit = async ({ params, url, bto, success }) => {
    const {
      clinicPerformDepartmentBtoList,
      treatItemExtensionBtoList,
      treatVsClinicItemBaseDto: {
        treatClass: { id: treatClassId },
        treatSubClass,
        ...treatVsClinicItem
      },
      ...btoParam
    } = params;
    const res = await vsf?.services?.[url]?.({
      btoParam: {
        ...btoParam,
        branchInstitutionIdList: treatItemExtensionBtoList?.select ?? [],
      },
      [bto]: {
        ...treatVsClinicItem,
        treatClassId,
        treatSubClassId: treatSubClass?.id ?? null,
        treatItemExtensionBtoList: Object.values(treatItemExtensionBtoList.form)
          ? Object.values(treatItemExtensionBtoList.form).map(
              (item, index) => ({
                ...item,
                branchInstitutionId:
                  item.value ?? treatItemExtensionBtoList?.select[index],
              }),
            )
          : [],
        treatItemVsDepartmentBtoList: clinicPerformDepartmentBtoList
          ? clinicPerformDepartmentBtoList?.map((item, index) => {
              if (String(item.id).startsWith('create')) delete item.id;
              return {
                ...item,
                ...(item?.performDepartment?.id
                  ? { id: item?.performDepartment?.id }
                  : {}),
                performDepartmentId: item.performDepartment?.value,
                sortNumber: index + 1,
              };
            })
          : [],
      },
      extApiParam: params?.extApiParam,
    });
    if (res.code === 200) {
      vsf.refs.TreatItem.reload().then(() => {
        if (typeof res.data === 'number') {
          setDataStore({
            id: vsf.refs?.TreatItem?.getValues().filter(
              (item) => res.data === item.id,
            )?.[0],
          });
        }
      });
      message.success(success);
    }
    return {};
  };

  const [scrollTop, setScrollTop] = useSetState({ x: 2000 });
  const onAdd = (params) => {
    TableSubmit({
      params,
      url: 'ClinicItemBaseController_createClinicTreatItem_de96a6',
      bto: 'createTreatItemBto',
      success: '新增治疗项目成功',
    });
  };
  const onUpdate = (params) => {
    TableSubmit({
      params,
      url: 'ClinicItemBaseController_updateClinicTreatItem_5f6500',
      bto: 'updateTreatItemBto',
      success: '修改治疗项目成功',
    });
  };

  useUpdateEffect(() => {
    if (Object.keys(dataStore.id).length) {
      setDataStore({
        id: {
          stopIndicator: true,
        },
      });
    }
  }, [props.data]);

  const top = useRef(null);
  useEffect(() => {
    setScrollTop({
      y: top.current?.offsetHeight - 88,
    });
  }, [top]);

  return (
    <VSPromiseTable
      id="TreatItem"
      ref={table}
      editable={{
        editType: 'single',
        extraOperations: [
          {
            children: (node) => {
              return node.stopIndicator ? (
                <Icon type="icon-a-bianjijinyong" size={24} />
              ) : (
                <Icon type="icon-bianji" size={24} />
              );
            },
            show: (node) => node.stopIndicator,
            disabled: (node) => node.stopIndicator,
            order: 1,
          },
          {
            children: (node) => {
              return node.stopIndicator ? (
                <Icon type="icon-a-jinyongjinyong" size={24} />
              ) : (
                <Icon type="icon-tingyong" size={24} />
              );
            },
            disabled: (node) => node.stopIndicator,
            onClick: (event, node) => {
              Tip({
                onOk: () => {
                  onStop(node.id);
                },
                cancelText: '取消',
                content: `确定停用{【${node.clinicItemName}】}项目?`,
              });
            },
            order: 1,
          },
        ],
        editText: <Icon type="icon-bianji" size={24} />,
        columnProps: {
          width: 150,
        },
        onCanDelete: () => null,
        onCanEdit: (node) => !node.stopIndicator,
      }}
      vsid="65825"
      rowClassName={(...arr) => {
        const [recode] = arr;
        return recode.id === dataStore.id.id
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
          : 'aspirin-vs-table-row-box aspirin-row-select-box';
      }}
      rowClick={{
        rowClickType: 'select',
        onRowClick: (data) => {
          setDataStore({
            id: data,
          });
        },
      }}
      onFetch={onFetch}
      style={{
        maxHeight: getScrollYRem(16),
        // maxHeight: '100%',
      }}
      scroll={scrollTop}
      pagination={false}
      className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
      onAdd={onAdd}
      searchFormRender={(props, dom) => (
        <div className="aspirin-table-search-inline-no">
          <SearchContainer mode="inline" formPadding="0 170px 0 100px">
            {dom}
          </SearchContainer>
          <Divider />
        </div>
      )}
      tableRender={(_, dom) => {
        return (
          <TableRender
            value={dataStore?.id}
            clinicItemId={dataStore.id?.treatVsClinicItemBaseDto?.clinicItemId}
            // height={window.innerHeight - 410}
            height={getScrollYRem(27)}
            reload={vsf.refs?.TreatItem?.reload}
            onDragMove={debounce((value) => {
              setScrollTop({
                y: value - 88,
              });
            }, 500)}
          >
            <div className="aspirin-compare-price-top-box" ref={top}>
              {dom}
            </div>
          </TableRender>
        );
      }}
      searchConfig={{
        // labelWidth: 100,
        labelWidth: '20%',
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        optionRender: (...arr) => {
          const [, , [other, search]] = arr;
          return (
            <SearchContainer.Header title="筛选">
              {[
                other,
                <ButtonAndKeyDown
                  methodKey="altKey"
                  ordinaryKey="Q"
                  {...search?.props}
                >
                  查询
                </ButtonAndKeyDown>,
              ]}
            </SearchContainer.Header>
          );
        },
      }}
      onUpdate={onUpdate}
      addLinkPage={{
        linkPage: () => <TreatItemEdit data={props.active} />,
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.TreatForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        },
        modalProps: {
          title: '新增治疗项目',
          className: 'aspirin-linkpage',
          width: '70%',
          bodyStyle: {
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
      }}
      updateLinkPage={{
        modalProps: {
          title: '编辑治疗项目',
          className: 'aspirin-linkpage',
          width: '70%',
          bodyStyle: {
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          },
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.TreatForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        linkPage: () => <TreatItemEdit />,
      }}
      rowSelection={{
        onSelectedRowsChange: (_value) => {
          props.onChange?.(_value);
        },
      }}
    >
      <VSTableColumn
        dataIndex={['treatVsClinicItemBaseDto', 'treatClass', 'treatClassName']}
        title="治疗大类"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={[
          'treatVsClinicItemBaseDto',
          'treatSubClass',
          'treatClassName',
        ]}
        title="治疗子类"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['clinicItemName']}
        title="治疗项目"
        valueType="text"
        search
        order={-1}
        columnKey={'clinicItemNameLike'}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['costs']}
        title="合计金额"
        valueType="custom"
        fieldProps={{}}
        render={(...[, record]) => {
          return (
            <Flex justify="flex-end">
              {int(record?.clinicVsChargeDtoList)
                ? '实时计算'
                : askFixed(record?.costs)}
            </Flex>
          );
        }}
        width={80}
      />

      <VSTableColumn
        dataIndex={['branchInstitution']}
        title="院区"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['stopIndicator']}
        title="停用标记"
        valueType="select"
        search
        order={-1}
        columnKey={'stopIndicatorIs'}
        render={(_, data) => {
          return (
            <span
              style={{ color: data.stopIndicator ? `var(--red)` : 'inherit' }}
            >
              {data.stopIndicator ? '已' : '未'}停用
            </span>
          );
        }}
        // width={80}
        searchFieldProps={{
          dataSource: [
            {
              label: '已停用',
              value: true,
            },
            {
              label: '未停用',
              value: false,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['auditIndicator']}
        title="审核标记"
        valueType="select"
        search
        // width={80}
        order={-1}
        searchFieldProps={{
          dataSource: [
            {
              label: '已审核',
              value: true,
            },
            {
              label: '未审核',
              value: false,
            },
          ],
        }}
        render={(_, data) => {
          return (data.auditIndicator ? '已' : '未') + '审核';
        }}
        columnKey={'auditIndicatorIs'}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['lastModifyStaff', 'staffName']}
        title="最近操作人"
        valueType="text"
        width={100}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['lastModifyDate']}
        title="最近操作日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        width={170}
      />

      <VSTableColumn
        dataIndex={['auditStaff', 'staffName']}
        title="审核人"
        valueType="text"
        // width={80}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['auditDate']}
        title="审核日期"
        valueType="date"
        width={170}
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableToolbar
        title="治疗项目列表"
        className="aspirin-toolbar-padding-top-box"
      >
        <VSTableAddition
          children="添加"
          disabled={!props?.data || props?.data?.children?.length}
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};

// const TreatItem = (props) => {
//   return (
//     <Screen>
//       <TreatItemFunc {...props} />
//     </Screen>
//   )
// }

export default definePage(TreatItemFunc);
