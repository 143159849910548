import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderLabController_printOrderLab_901f40: {
    method: 'post',
    url: '/api/clinic-record-order-lab/print-order-lab',
    parameterFomatter: (data?: { orderLabIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicRecordOrderLabController_queryOrderLabPrintVoByOrderLabPrintQto_b9cbc7:
    {
      method: 'post',
      url: '/api/clinic-record-order-lab/query-order-lab-print-vo-by-order-lab-print-qto',
      parameterFomatter: (data?: {
        qto: OrderLabPrintQtoClinicRecordOrderLabPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderLabPrintVoClinicRecordOrderLabEntranceWebVo[],
      ) => data,
    },
});
