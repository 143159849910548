import {
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSTableAction,
  Section,
  VSFormItem,
  VSForm,
  VSFormLayout,
} from '@vs/vsf-kit';
import {
  convertGetBedClassVsChargeDetailVoByIdRes2VSPromiseTableBedClassVsCharge,
  convertQueryByBedClassVsChargeQtoPagedRes2VSPromiseTableBedClassVsChargeRo,
} from './convert';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import Block from '@/pages/Index/components/block_no_padding';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import storage from '@/utils/storage';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import { convertGetBedClassVsChargeDetailVoByIdRes2VSFormBedClassVsCharge } from './convert';

import './index.less';

const Index = (props) => {
  // params是来自table的单行数据的value
  // 没有id，为创建，否则为编辑
  const onFetch_t7809 = useCallback(async (params) => {
    if (!params?.id) {
      return {};
    } // 编辑时，请求回显数据
    const res =
      await vsf?.services?.ClinicBedConfigController_getBedClassVsChargeDetailVoById_6d8e13?.(
        { id: params?.id },
      );
    const resultData =
      convertGetBedClassVsChargeDetailVoByIdRes2VSFormBedClassVsCharge?.(res);
    console.log(resultData, 'resultDataresultData');
    return resultData;
  }, []);

  return (
    <VSForm
      id="vSFormBedClassVsCharge"
      onFetch={onFetch_t7809}
      params={props?.value}
      onChange={props?.onChange}
      labelAlign="left"
    >
      <VSFormLayout key="0" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          required
          name={['sortNumber']}
          label="序号"
          valueType="digit"
          fieldProps={{ allowClear: true, width: '100%', required: true }}
        />

        <VSFormItem
          required
          name={['bedClass']}
          label="编码"
          valueType="text"
          fieldProps={{ allowClear: true, maxLength: 20, required: true }}
        />
      </VSFormLayout>
      <VSFormLayout key="1" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          required
          name={['name']}
          label="编码名称"
          valueType="text"
          fieldProps={{ required: true, allowClear: true }}
        />

        <VSFormItem
          required
          name={['priceItem']}
          label="价表项目"
          valueType="select"
          fieldProps={{
            showSearch: true,
            required: true,
            allowClear: true,
            dataSource: async (param) => {
              console.log(param, 'paramparam');
              // 请求获取床位费相关的码表code结果
              const bedItemClassResult =
                await vsf?.services?.BaseDictionaryController_queryByBaseCodeBaseEntityQtoPaged_ddabd6?.(
                  {
                    qto: {
                      from: 0,
                      size: 200,
                      codeTypeIs: 'RECK_ITEM_CLASS_DICT',
                      extensionJsonBillItemIs: 'J',
                    },
                  },
                ); // 组装请求参数：itemClassIn
              const baseCodeList = bedItemClassResult?.data?.result;
              var itemClassIn = [];
              if (baseCodeList) {
                itemClassIn = baseCodeList?.map?.((baseCode) => baseCode?.code);
              } // 请求价表项目查询接口，获取床位价表相关项目列表
              const keyword = param?.priceItemKeyword;
              const priceItemResult =
                await vsf?.services?.PriceListController_getPagedByPriceItemCurrentNewQto_9a1b8a?.(
                  {
                    qto: {
                      from: 0,
                      size: 200,
                      itemClassIn: itemClassIn,
                      inputCodeLike: keyword,
                      itemNameLike: keyword,
                      inputCodeWubiLike: keyword,
                    },
                  },
                );
              return priceItemResult?.data?.result;
            },
            fieldNames: { label: 'itemName', value: 'itemId' },
          }}
          searchKey="priceItemKeyword"
        />
      </VSFormLayout>
      <VSFormLayout key="2" columnCount={2} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['deprecateIndicator']}
          label="是否停用"
          valueType="switch"
          initialValue={false}
          fieldProps={{ required: true }}
          required
        />
      </VSFormLayout>
      {/* <VSFormLayout key="3" columnCount={1} labelWidth={120} gutter={50}>
        <VSFormItem
          name={['memo']}
          label="备注"
          valueType="textarea"
          fieldProps={{
            autoSize: { minRows: 3, maxRows: 4 },
          }}
        />
      </VSFormLayout> */}
    </VSForm>
  );
};

export default Index;
