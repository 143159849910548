import React, { useEffect, useState } from 'react';
import { Row, Col, Badge } from '@vs/vsf-kit';
import { values } from 'lodash';
import askTimeBto from '@/pages/Index/components/func/ask/askTimeBto';
import { useMount, useRequest } from 'ahooks';
function Cart({
  dataSource,
  data,
  index,
  updateChange,
  updateSelectStatus,
  selectStatus,
}) {
  const [chargeData, setChargeData] = useState();
  const [sexName, setSexName] = useState();
  const sex = vsf.stores.dbenums?.enums?.SEX_DICT;
  const charge = vsf.stores.dbenums.enums.CHARGE_TYPE_DICT;

  useEffect(() => {
    charge &&
      charge.map((item) => {
        if (item?.value === data?.chargeTypeCode) {
          setChargeData(item?.label);
        }
      });
    sex &&
      sex.map((item) => {
        if (item?.value === data?.gender) {
          setSexName(item?.label);
        }
      });
  }, [charge, sex]);
  const statu = {
      REGISTER: '挂号',
      TRIAGE: '分诊',
      ENCOUNTER: '接诊',
      REFUND: '退号',
    },
    visitTypes = {
      OUTP: '门诊',
      INP: '住院',
      ERP: '急诊',
      PHYSICAL_EXAM: '体检',
      CLINIC_REGISTER: '挂号',
    };

  return (
    <div>
      {/* <Badge.Ribbon text={data?.visitType}> */}
      <div
        onClick={() => {
          if (data.visitStatus === 'REGISTER') {
            updateSelectStatus(index);
            updateChange(data);
            if (selectStatus === index) {
              updateSelectStatus(undefined);
            }
          } else {
            updateSelectStatus(undefined);
          }
        }}
        className={
          data.visitStatus === 'REGISTER'
            ? selectStatus === index
              ? 'backing-cart-container-unselect'
              : 'backing-cart-container'
            : 'backing-cart-disable'
        }
      >
        {' '}
        <div className="backing-title">
          <div className="backing-title-layout">
            <div className="child">
              {data?.clinicRegisterDefine?.clinicLabel}
            </div>
            <div className="child">
              就诊日期：
              <span className='span_label'>
                {data?.visitDate?.split(' ')[0] ?? ''}
              </span>
            </div>
            <div className="child">
              票据号：
              <span className='span_label'>
                {data?.physicalReceiptNumber}
              </span>
            </div>
            <div className="child">
              <span style={{ color: '#E34A4E' }} className='span_label'>
                {statu[data?.visitStatus]}
              </span>
            </div>
            <div className="backing-title-lable">
              {visitTypes?.[data?.visitType]}
            </div>
          </div>
        </div>
        <div className="backing-content">
          <div className="backing-content-main">
            <Row>
              <Col span={5}>
                姓名：<p>{data?.name}</p>
              </Col>
              <Col span={5}>
                年龄: <p>{data?.age}</p>
              </Col>
              <Col span={5}>
                患者ID：<p>{data?.patient?.displayId}</p>
              </Col>
              <Col span={5}>
                费别：<p>{chargeData}</p>
              </Col>
              <Col span={4}>
                人员类别：<p>自费病员</p>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                科室：
                <p>
                  {data?.clinicRegisterDefine?.clinicDepartment?.departmentName}
                </p>
              </Col>
              <Col span={5}>
                号类：
                <p>
                  {
                    dataSource?.find(
                      (item) =>
                        item.id ===
                        data?.clinicRegisterDefine?.clinicRegisterTypeId,
                    )?.clinicType
                  }
                </p>{' '}
              </Col>
              <Col span={5}>
                就诊时间：
                <>
                  {data?.clinicRegisterDetailTimeDivision?.timeDescription ===
                  'AM'
                    ? '上午'
                    : '下午'}
                  {data?.clinicRegisterDetailTimeDivision?.serialNumber}号
                  {askTimeBto(
                    data?.clinicRegisterDetailTimeDivision?.waitTimePeriod,
                  )}
                </>
              </Col>
              <Col span={5}>
                就诊流水号：<p>{data?.visitNumber}</p>
              </Col>
              <Col span={4}>
                性别：<p>{sexName}</p>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                窗口途径：<p>{data?.appointWay}</p>
              </Col>
              <Col span={5}>
                看诊医生：
                <p>{data?.clinicRegisterDefine?.doctor?.staffName}</p>{' '}
              </Col>
              <Col span={5}>医疗类别：</Col>
              <Col span={5}>
                挂号时间：<p>{data?.createdAt}</p>
              </Col>
            </Row>
          </div>
          <div className="backing-content-total-cost">
            <div className="cost-content">
              <div>合计</div>
              <div>{Number(data?.totalCost).toFixed(2)}元</div>
            </div>
          </div>
        </div>
      </div>
      {/* </Badge.Ribbon> */}
    </div>
  );
}

export default Cart;
