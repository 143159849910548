import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Form,
  Input,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import {
  useAsyncEffect,
  useGetState,
  useMount,
  useRequest,
  useUpdate,
  useUpdateEffect,
} from 'ahooks';
import { Select } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AspirinSelect } from '@/components';
import FormBlock from '@/module/cpoe/FormBlock';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import {
  useFrequencyEffect,
  useUpdateAsyncEffect,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { labelColWidth, labelNumberWidth } from '@/utils';
import storage from '@/utils/storage';

const InputRender = (props) => {
  const { schema, form, formKey, examBodyPartList, pathologyList, ...rest } =
    props;
  const { sampleBodypart, examItemId } = formKey?.record || {};
  useUpdateEffect(() => {
    const sampleBodyPartName = examBodyPartList?.find(
      (item) => item.id == sampleBodypart,
    );
    const clinicItemName = pathologyList?.find(
      (item) => item?.id === (examItemId?.id ?? examItemId),
    )?.clinicItemName;
    const examItemName = `${
      (sampleBodyPartName?.examBodyName || sampleBodypart) ?? ''
    }${clinicItemName ?? ''}`;
    form?.setFieldValue([formKey?.recordKey, 'examItemName'], examItemName);
    rest?.onChange?.(examItemName);
  }, [sampleBodypart, examItemId]);

  return <Input {...rest} />;
};

const NewSelect = (props) => {
  const { examBodyPartList, record, value, schema, onChange } = props;
  return (
    <AspirinSelect
      addItemOptions={{
        status: true,
        mode: 'single',
      }}
      autoCompleteProps={{
        showSearch: true,
        placeholder: '请选择/输入采集部位',
      }}
      dataSource={examBodyPartList}
      fieldNames={{
        label: 'examBodyName',
        value: 'id',
      }}
      value={
        examBodyPartList?.find((item) => item?.id == value)?.examBodyName ??
        value
      }
      onChange={onChange}
    />
  );
};

const Index = (props, ref) => {
  const { data } = props;
  const currentApplication = storage?.get('currentApplication');
  type SelectValue = {
    id: any;
    examBodyName: any;
    label: string;
    value: number | string;
    notice: null | string;
  };

  const [pathologyId, setPathologyId] = useState<any>();

  // const [examClassList, setExamClassList] = useState<SelectValue[]>();

  const [departmentList, setDepartmentList] = useState<SelectValue[]>();
  const [examCategoryList, setExamCategoryList] = useState<SelectValue[]>();
  const [examMethodList, setExamMethodList] = useState<SelectValue[]>();
  const [examBodyPartList, setExamBodyPartList] = useState<SelectValue[]>();
  const [parentExamClass, setParentExamClass] = useGetState<any>(null);
  // const [pathologyList, setPathologyList] = useState<SelectValue[]>();

  const tableRef = useRef<any>();

  /**
   * 回显数据
   */
  const [form] = Form?.useForm();
  const getValue = async (orderId) => {
    const res =
      await vsf.services?.ClinicRecordOrderPathologyController_getOrderPathologyByOrderId_503ccd?.(
        {
          orderId,
        },
      );
    return res?.data;
  };

  /**
   * 获取检查单数据源
   */
  const { data: examClassList } = useRequest(async () => {
    const res =
      await vsf.services?.ExamController_getAllByExamClassItemClassQto_4b0f3f?.(
        {
          qto: {
            itemClassIs: 'PATHOLOGY',
            stopIndicatorIs: false,
            institutionIdIs: currentApplication?.institutionId,
          },
        },
      );
    if (res?.code === 200) {
      const [parentExamClassIdIs] = res?.data?.filter(
        (item) => !item?.parentExamClassId,
      );
      setParentExamClass?.(parentExamClassIdIs);
      setPathologyId(parentExamClassIdIs?.id);
      const data =
        await vsf.services?.ExamController_getAllByExamClassItemClassQto_4b0f3f?.(
          {
            qto: {
              itemClassIs: 'PATHOLOGY',
              parentExamClassIdIs: parentExamClassIdIs?.id,
              stopIndicatorIs: false,
              institutionIdIs: currentApplication?.institutionId,
            },
          },
        );
      return (data?.data ?? [])?.map((item) => ({
        ...item,
        origin: item,
      }));
    }
  });

  /**
   * 获取检查类别数据源
   */
  const examSubClassCode = Form?.useWatch('examSubClass', form)?.id;
  useUpdateAsyncEffect(async () => {
    if (examSubClassCode) {
      const res =
        await vsf.services?.ExamController_getAllByExamClassItemClassQto_4b0f3f?.(
          {
            qto: {
              from: 0,
              size: 20,
              parentExamClassIdIs: examSubClassCode,
              stopIndicatorIs: false,
            },
          },
        );
      if (res?.code === 200) {
        setExamCategoryList(
          (res?.data ?? [])?.map((item) => {
            return {
              ...item,
              origin: item,
            };
          }),
        );
      }
    }
  }, [examSubClassCode]);
  /**
   * 获取临床特殊数据源
   */
  const { data: PageFallDiagnosisDictionary } = useRequest(async (value) => {
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
        {
          // 所选检查单类型的ID
          qto: {
            from: 0,
            size: 20,
            ...value,
          },
          ext: {},
        },
      );
    // console.log(res?.data?.result);
    if (res?.code === 200) {
      return res?.data?.result ?? [];
    }
  });
  // const PageFallDiagnosisDictionary = useCallback(async (value) => {
  //   const res =
  //     await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
  //       {
  //         // 所选检查单类型的ID
  //         qto: {
  //           from: 0,
  //           size: 20,
  //           ...value,
  //         },
  //         ext: {},
  //       },
  //     );
  //   console.log(res?.data?.result);
  //   if (res?.code === 200) {
  //     return res?.data?.result ?? [];
  //   }
  // }, []);

  /**
   * 获取采集方式数据源
   */
  const examCategory = Form?.useWatch('examCategory', form)?.id;

  /**
   * 获取采集部位数据源
   */
  useUpdateAsyncEffect(async () => {
    if (pathologyId) {
      const res =
        await vsf.services?.ExamController_getExamBodyDictionaryByExamClassId_22696a?.(
          {
            examClassId: pathologyId,
          },
        );
      if (res?.code === 200) {
        setExamBodyPartList(
          (res?.data ?? [])?.map((item) => {
            return {
              ...item,
              origin: item,
            };
          }),
        );
      }
    }
  }, [pathologyId]);

  /**
   * 获取病理标本数据源
   */
  const { data: pathologyList, run: getPathologyList } = useRequest(
    async (value) => {
      if (Object?.values(value)?.filter(Boolean)?.length) {
        const res =
          await vsf.services?.ExamController_getAllByExamVsClinicItemDictionaryQto_52658d?.(
            {
              //检测类别选择时，examThirdClassIdIs传入检测类别选项对应的EXAM_CLASS_CODE
              //检测类别未选择时，examSubClassIdIs传入检查单类型选项对应的EXAM_CLASS_CODE
              qto: {
                from: 0,
                size: 20,
                ...value,
              },
            },
          );
        if (res?.code === 200) {
          if (data?.orderId) {
            const id = (res?.data ?? [])?.find(
              (item) =>
                item?.clinicItemId?.id ===
                data?.pathologyOrderDetail?.[0]?.examItemId,
            );
            if (id?.examThirdClassId) {
              form?.setFieldValue('examCategory', id?.examThirdClassId);
            }
          }
          return (res?.data ?? [])?.map((item) => {
            return {
              clinicItemName: item?.clinicItemId?.clinicItemName,
              id: item?.clinicItemId?.id,
              origin: item,
            };
          });
        }
      }
    },
    {
      manual: true,
    },
  );

  useAsyncEffect(async () => {
    if (pathologyId) {
      const method =
        await vsf.services?.ExamController_getExamMethodDictionaryByExamClassId_83dd5c?.(
          {
            examClassId: pathologyId,
          },
        );
      setExamMethodList(method?.data ?? []);
    }
  }, [pathologyId]);

  useEffect(() => {
    if (examCategory || examSubClassCode) {
      getPathologyList(
        examCategory
          ? {
              examThirdClassIdIs: examCategory,
            }
          : {
              examSubClassIdIs: examSubClassCode,
            },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examCategory, examSubClassCode]);

  useEffect(() => {
    if (examSubClassCode) {
      const notice = examCategoryList?.find(
        (item) => item?.id === examCategory,
      )?.notice;
      form?.setFieldValue(['notice'], notice ?? parentExamClass?.notice);
    } else if (parentExamClass) {
      form?.setFieldValue(['notice'], parentExamClass?.notice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examSubClassCode, parentExamClass]);

  useFrequencyEffect(
    () => {
      form?.setFieldValue(['pathologyOrderDetail'], []);
    },
    2,
    [examSubClassCode],
    'before',
  );

  const onAdd = () => {
    tableRef?.current?.add({
      id: 'create' + Math.random(),
    });
  };

  const onRemove = (data) => {
    tableRef?.current?.remove(data);
  };

  const tumourIndicator = Form?.useWatch('tumourIndicator', form);
  const gynaecologyIndicator = Form?.useWatch('gynaecologyIndicator', form);

  const operationDoctorDataSource = useCallback(async (params) => {
    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: params?.nameLike ?? '',
        },
      );
    return res?.data?.result;
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!tableRef?.current?.getValues()?.length) {
      await tableRef?.current?.add({
        id: 'create' + Math?.random(),
      });
    }
    try {
      const [value] = await Promise.all([
        vsf.refs.orderPathologyForm?.validateFieldsReturnFormatValue(),
        tableRef?.current?.getEditForm()?.validateFieldsReturnFormatValue(),
      ]);
      console.log(value, 'value-------');
      const list = value?.pathologyOrderDetail
        ?.map((item) => {
          return `${item?.examItemName ?? ''}`;
        })
        .join(',');
      const title = value?.examCategory
        ? (examCategoryList as any)?.find(
            (item) => item?.id === value?.examCategory,
          )?.examClassName
        : value?.examSubClass?.examClassName ?? '';
      const examSubClassName = `${title ?? ''} 【${list}】`;
      return {
        ...value,
        examSubClassId: value?.examSubClass?.id,
        examSubClassName,
      };
    } catch (error) {
      return Promise?.reject(error);
    }
  }, [examCategoryList]);

  useImperativeHandle(
    ref,
    () => ({
      Submit: handleSubmit,
    }),
    [handleSubmit],
  );
  useAsyncEffect(async () => {
    console.log(data);
    if (data?.orderId) {
      const res = await PageFallDiagnosisDictionary({
        size: 9999,
      });
      const value = res?.filter((item) =>
        data?.orderDiagnosisIdList?.includes(item?.id),
      );
      form?.setFieldsValue({
        ...data,
        examSubClass: data?.examSubClassId,
        pathologyOrderDetail: data?.pathologyOrderDetail?.map((item) => ({
          id: item.examItemId,
          ...item,
        })),
        orderDiagnosisIdList: value,
      });
    } else {
      form?.setFieldsValue(data);
      setTimeout(() => {
        form?.setFieldValue?.('notice', data?.notice);
      }, 100);
    }
  }, [data]);

  const pastExamPathology = Form?.useWatch('pastExamPathology', form);
  const PastExamPathology = pastExamPathology === 'EMPTY';

  const pathologyOrderDetail = useCallback(async (examVSClinicIds) => {
    if (examVSClinicIds?.filter(Boolean)?.length) {
      const res =
        await vsf?.services?.ExamOrderController_getExamItemInfo_3da8d6?.({
          examVSClinicIds: examVSClinicIds?.filter(Boolean),
        });
      if (res?.code === 200) {
        form?.setFieldValue?.('notice', res?.data?.notice);
      }
    } else {
      form?.setFieldValue?.('notice', void 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cpoe-pathology">
      <div className="main">
        <VSForm id="orderPathologyForm" labelAlign="left" form={form}>
          <FormBlock>
            <VSFormLayout
              key="1"
              columnCount={2}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(10)}
              gutter={70}
            >
              <VSFormItem
                name={['examSubClass']}
                label="检查单类型"
                valueType="select"
                rules={[
                  {
                    required: true,
                    message: '请选择检查单类型',
                  },
                ]}
                searchKey="label"
                fieldNames={{
                  label: 'examClassName',
                  value: 'id',
                }}
                dataSource={async (value) => {
                  return value?.label
                    ? (examClassList as any[])?.filter((item) =>
                        askSearch(value?.label, item, 'examClassName'),
                      )
                    : (examClassList as any[]);
                }}
                transform={(value) => ({
                  examSubClassId: value?.id,
                  examSubClass: value,
                })}
                fieldProps={{
                  placeholder: '请选择检查单类型',
                  showSearch: true,
                  onChange: async (value) => {
                    const examSubClassCode = value?.id;
                    if (examSubClassCode) {
                      const res =
                        await vsf.services?.ExamController_getExamVsDepartmentByExamClassId_86c409?.(
                          {
                            examClassId: examSubClassCode,
                          },
                        );
                      if (res?.code === 200) {
                        const num = res?.data?.map(
                          (item) => item?.priority ?? 0,
                        );
                        const max = res?.data.find(
                          (item) => item?.priority === Math.min(...num),
                        );
                        form.setFieldValue('performDepartment', {
                          id: max?.department?.id,
                          departmentName: max?.department?.departmentName,
                        });
                        setDepartmentList(
                          res?.data?.map((item) => ({
                            ...item,
                            departmentName: item?.department?.departmentName,
                            id: item?.department?.id,
                          })) ?? [],
                        );
                      }
                    }
                  },
                }}
              />

              {/* <VSFormLayout columnCount={3} labelCol={labelColWidth[7]}> */}
              <VSFormLayout columnCount={3} labelWidth={labelNumberWidth(3)}>
                <VSFormItem
                  name={['emergencyIndicator']}
                  label="加急"
                  valueType="switch"
                  initialValue={false}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['tumourIndicator']}
                  label="肿瘤"
                  valueType="switch"
                  initialValue={false}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['gynaecologyIndicator']}
                  label="妇科"
                  valueType="switch"
                  initialValue={false}
                  fieldProps={{}}
                />
              </VSFormLayout>
            </VSFormLayout>
            <VSFormLayout
              key="2"
              columnCount={2}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(10)}
              gutter={70}
            >
              <VSFormItem
                name={['performDepartment']}
                label="执行科室"
                valueType="select"
                rules={[
                  {
                    required: true,
                  },
                ]}
                fieldProps={{
                  placeholder: '请选择科室',
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'departmentName',
                  value: 'id',
                }}
                transform={(value) => {
                  return {
                    performDepartmentId: value?.id,
                    performDepartment: value,
                  };
                }}
                dataSource={departmentList}
              />

              <VSFormItem
                name={['sampleLocation']}
                label="采样地点"
                valueType="custom"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={vsf.stores.dbenums.enums.SAMPLE_PLACE_DICT}
                  showSearch
                  placeholder="请选择采样地点"
                  filterOption={askSearch}
                />
              </VSFormItem>
            </VSFormLayout>
            <VSFormLayout key="3" columnCount={4} gutter={70}>
              {/* <VSFormLayout columnCount={1} labelCol={labelColWidth[7]}> */}
              <VSFormLayout columnCount={1} labelWidth={labelNumberWidth(10)}>
                <VSFormItem
                  name={['height']}
                  label="身高"
                  valueType="digit"
                  rules={[
                    {
                      validator: (...[, value]) => {
                        if ([undefined, null]?.includes(value))
                          return Promise?.resolve();
                        if (value < 1) return Promise?.reject('身高必须是正数');
                        return String(value)?.split('.')[1]?.length > 2
                          ? Promise?.reject('最多两位小数')
                          : Promise?.resolve();
                      },
                    },
                  ]}
                  fieldProps={{
                    addonAfter: 'cm',
                    // min: 1,
                    precision: 2,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout columnCount={1}>
                <VSFormItem
                  name={['weight']}
                  label="体重"
                  valueType="digit"
                  fieldProps={{
                    addonAfter: 'kg',
                    // min: 1,
                    precision: 2,
                  }}
                  rules={[
                    {
                      validator: (...[, value]) => {
                        if ([undefined, null]?.includes(value))
                          return Promise?.resolve();
                        if (value < 1) return Promise?.reject('体重必须是正数');
                        return String(value)?.split('.')[1]?.length > 2
                          ? Promise?.reject('最多两位小数')
                          : Promise?.resolve();
                      },
                    },
                  ]}
                />
              </VSFormLayout>
              <VSFormLayout columnCount={1} labelCol={labelColWidth[7]}>
                {/* <VSFormLayout columnCount={1} labelWidth={labelNumberWidth(10)}> */}
                <VSFormItem
                  name={['emptyStomachBloodSugar']}
                  label="空腹血糖"
                  valueType="digit"
                  fieldProps={{
                    addonAfter: 'mmol/l',
                    // min: 1,
                    precision: 2,
                  }}
                  rules={[
                    {
                      validator: (...[, value]) => {
                        if ([undefined, null]?.includes(value))
                          return Promise?.resolve();
                        if (value < 1)
                          return Promise?.reject('空腹血糖必须是正数');
                        return String(value)?.split('.')[1]?.length > 2
                          ? Promise?.reject('最多两位小数')
                          : Promise?.resolve();
                      },
                    },
                  ]}
                />
              </VSFormLayout>
              {/* <VSFormLayout columnCount={1} labelWidth={70}> */}
              <VSFormLayout columnCount={1} labelWidth={labelNumberWidth(3)}>
                <VSFormItem
                  name={['sense']}
                  label="神志"
                  valueType="radioButton"
                  dataSource={vsf.stores.dbenums.enums.SENSE_DICT}
                  fieldProps={{}}
                />
              </VSFormLayout>
            </VSFormLayout>

            <VSFormLayout
              key="4"
              columnCount={2}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(10)}
              gutter={70}
            >
              <VSFormItem
                name={['briefDiseaseHistoryPhysicalExam']}
                label="简要病史及体检"
                valueType="textarea"
                rules={[
                  {
                    required: true,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['relatedLabResult']}
                label="检验检查"
                valueType="textarea"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 1000,
                  },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['notice']}
                label="注意事项"
                valueType="textarea"
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 1000,
                  },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['orderDiagnosisIdList']}
                label="临床诊断"
                valueType="select"
                rules={[
                  {
                    required: true,
                  },
                ]}
                fieldProps={{
                  showSearch: true,
                  mode: 'multiple',
                }}
                fieldNames={{
                  label: 'diagnosisName',
                  value: 'id',
                }}
                searchKey="searchInputLike"
                dataSource={PageFallDiagnosisDictionary}
              />
            </VSFormLayout>
            <VSFormLayout
              key="5"
              columnCount={1}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(10)}
              gutter={70}
            >
              <VSFormItem
                name={['specialInfect']}
                label="特殊说明"
                valueType="textarea"
                rules={[
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['operationResult']}
                label="手术所见及诊断"
                valueType="textarea"
                rules={[
                  {
                    required: examSubClassCode == '3200',
                  },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['sendHospital']}
                label="送检医院"
                valueType="text"
                rules={[
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ]}
                initialValue={
                  currentApplication?.department?.branchInstitution
                    ?.institutionName
                }
                fieldProps={{}}
              />
            </VSFormLayout>
          </FormBlock>
          <Divider />
          <FormBlock title="既往病理">
            <VSFormLayout
              key="10"
              columnCount={2}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(7)}
              gutter={70}
            >
              <VSFormLayout columnCount={2}>
                <VSFormItem
                  name={['pastExamPathology']}
                  label="曾做病理"
                  valueType="radioButton"
                  dataSource={[
                    { label: '无', value: 'EMPTY' },
                    { label: '本院', value: 'CURRENT_HOSPITAL' },
                    { label: '外院', value: 'EXTERNAL_HOSPITAL' },
                  ]}
                  fieldProps={{}}
                  initialValue="EMPTY"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />

                <VSFormItem
                  name={['pastExamNumber']}
                  label="病理号"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ]}
                  fieldProps={{
                    disabled: PastExamPathology,
                  }}
                />
              </VSFormLayout>

              <VSFormItem
                name={['pastExamInstitution']}
                label="检查单位"
                valueType="text"
                rules={[
                  { message: '数据格式错误', type: 'string', min: 0, max: 50 },
                ]}
                fieldProps={{
                  disabled: PastExamPathology,
                }}
              />
            </VSFormLayout>
            <VSFormLayout
              key="11"
              columnCount={2}
              // labelCol={labelColWidth[7]}
              labelWidth={labelNumberWidth(7)}
              gutter={70}
            >
              <VSFormLayout columnCount={2}>
                <VSFormItem
                  name={['pastExamDate']}
                  label="检查日期"
                  valueType="date"
                  fieldProps={{
                    disabled: PastExamPathology,
                  }}
                />

                <VSFormItem
                  name={['pastBiopsyNumber']}
                  label="切片号"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 20,
                    },
                  ]}
                  fieldProps={{
                    disabled: PastExamPathology,
                  }}
                />
              </VSFormLayout>

              <VSFormItem
                name={['pastDiagnosis']}
                label="病理诊断"
                valueType="text"
                rules={[
                  { message: '数据格式错误', type: 'string', min: 0, max: 100 },
                ]}
                fieldProps={{
                  disabled: PastExamPathology,
                }}
              />
            </VSFormLayout>
          </FormBlock>
          {examSubClassCode == '3200' && (
            <>
              <Divider />
              <FormBlock title="手术信息">
                <VSFormLayout
                  // labelCol={labelColWidth[7]}
                  labelWidth={labelNumberWidth(10)}
                  gutter={70}
                  columnCount={2}
                >
                  <VSFormItem
                    name={['operationDoctor']}
                    label="手术医生"
                    valueType="select"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    fieldNames={{
                      label: 'staffName',
                      value: 'id',
                    }}
                    searchKey="nameLike"
                    dataSource={operationDoctorDataSource}
                    fieldProps={{
                      showSearch: true,
                    }}
                    transform={(value) => {
                      return {
                        operationDoctorId: (value as any)?.id,
                        operationDoctor: value,
                      };
                    }}
                  />

                  <VSFormItem
                    name={['operationDate']}
                    label="手术日期"
                    valueType="date"
                    fieldProps={{}}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  />

                  <VSFormItem
                    name={['operationRoom']}
                    label="手术间"
                    valueType="select"
                    rules={[
                      {
                        // required: true,
                      },
                    ]}
                    fieldProps={{}}
                    transform={(value) => {
                      return {
                        operationRoom: value,
                        operationRoomId: value?.id,
                      };
                    }}
                  />

                  <VSFormItem
                    name={['operationRoomPhone']}
                    label="手术间电话"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 20,
                      },
                    ]}
                    fieldProps={{}}
                  />
                </VSFormLayout>
              </FormBlock>
            </>
          )}
          {tumourIndicator && (
            <>
              <Divider />
              <FormBlock title="肿瘤信息">
                <VSFormLayout
                  key="1"
                  columnCount={4}
                  // labelCol={labelColWidth[7]}
                  labelWidth={labelNumberWidth(7)}
                  gutter={70}
                >
                  <VSFormItem
                    name={['tumourGrowTime']}
                    label="肿瘤生长时间"
                    valueType="aspirinDateTimePicker"
                    fieldProps={{
                      valueType: 'timestamp',
                      format: 'YYYY/MM/DD',
                    }}
                  />

                  <VSFormItem
                    name={['tumourLymphSwollen']}
                    label="何处淋巴结肿大"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['tumourLocation']}
                    label="肿瘤位置"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['tumourActivity']}
                    label="肿瘤活动度"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />
                </VSFormLayout>
                <VSFormLayout
                  key="2"
                  columnCount={2}
                  // labelCol={labelColWidth[7]}
                  labelWidth={labelNumberWidth(7)}
                  gutter={70}
                >
                  <VSFormItem
                    name={['tumourTransfer']}
                    label="有无转移性"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['tumourSize']}
                    label="肿瘤大小"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />
                </VSFormLayout>
              </FormBlock>
            </>
          )}
          {gynaecologyIndicator && (
            <>
              <Divider />
              <FormBlock title="妇科信息">
                <VSFormLayout
                  key="1"
                  columnCount={2}
                  // labelWidth={150}
                  labelWidth={labelNumberWidth(10)}
                  gutter={70}
                >
                  <VSFormItem
                    name={['menstruationPeriodMemo']}
                    label="经期及持续时间"
                    valueType="select"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    dataSource={
                      vsf?.stores?.dbenums?.enums
                        ?.MENSTRUAL_PERIOD_DURATION_DICT
                    }
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['lastMenstruationDate']}
                    label="末次月经日期"
                    valueType="aspirinDateTimePicker"
                    fieldProps={{
                      valueType: 'timestamp',
                      format: 'YYYY/MM/DD',
                    }}
                  />

                  <VSFormItem
                    name={['hormonotherapyMemo']}
                    label="激素治疗日期及剂量"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 200,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['dilatationCurettageDate']}
                    label="刮宫日期"
                    valueType="aspirinDateTimePicker"
                    fieldProps={{
                      valueType: 'timestamp',
                      format: 'YYYY/MM/DD',
                    }}
                  />
                </VSFormLayout>
              </FormBlock>
            </>
          )}
          <Divider />
          <FormBlock>
            <SearchContainer.Header
              title={
                <div className="aspirin-search-custom-box">
                  <span>标本信息</span>
                  <Button type="primary" onClick={onAdd}>
                    新增
                  </Button>
                </div>
              }
              style={{
                paddingBottom: 20,
              }}
            />
            <VSFormLayout
              columnCount={2}
              labelCol={labelColWidth[7]}
              gutter={70}
            >
              <VSFormItem
                name={['examCategory']}
                label="检测类别"
                valueType="select"
                fieldProps={{
                  placeholder: '请选择检测类别',
                  showSearch: true,
                  onChange: () => {
                    form?.setFieldValue('pathologyOrderDetail', []);
                  },
                }}
                transform={(value) => {
                  return {
                    examCategory: value,
                  };
                }}
                fieldNames={{
                  label: 'examClassName',
                  value: 'id',
                }}
                dataSource={examCategoryList}
              />
              <VSFormItem
                name={['sampleMethod']}
                label="采集方式"
                valueType="select"
                fieldProps={{
                  placeholder: '请选择采集方式',
                  showSearch: true,
                }}
                fieldNames={{
                  label: 'examMethodName',
                  value: 'id',
                }}
                dataSource={examMethodList}
                transform={(value) => {
                  return {
                    sampleMethodId: value?.id,
                    sampleMethod: value,
                  };
                }}
              />
            </VSFormLayout>
            <VSFormItem name={['pathologyOrderDetail']} valueType="custom">
              <VSTable
                ref={tableRef}
                editable={{
                  editType: 'multiple',
                  onCanDelete: () => false,
                  columnProps: {
                    width: 70,
                  },
                  extraOperations: [
                    {
                      children: <Icon type="icon-shanchu" size={24} />,
                      onClick: (...[, data]) => {
                        if (String(data?.id)?.startsWith('create')) {
                          onRemove(data);
                        } else {
                          Tip({
                            content: `确定删除病理标本{${data.examItemName}}?`,
                            onOk() {
                              onRemove(data);
                            },
                          });
                        }
                      },
                    },
                  ],
                }}
                pagination={false}
                onChange={(value) => {
                  pathologyOrderDetail?.(
                    value?.map((item) => item?.examItemId?.id),
                  );
                }}
              >
                <VSTableColumn
                  dataIndex={['vesselNumber']}
                  title="容器号"
                  editable={false}
                  valueType="index"
                  fieldProps={{}}
                  width={70}
                />

                <VSTableColumn
                  dataIndex={['examItemId']}
                  title="病理标本"
                  valueType="selectCode"
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  fieldNames={{
                    label: 'clinicItemName',
                    value: 'id',
                  }}
                  fieldProps={{
                    showSearch: true,
                    onChange: (value) => {
                      if (value?.origin?.examThirdClassId) {
                        form?.setFieldValue(
                          'examCategory',
                          value?.origin?.examThirdClassId,
                        );
                      }
                    },
                  }}
                  dataSource={pathologyList}
                />

                <VSTableColumn
                  dataIndex={['sampleBodypart']}
                  title="采集部位"
                  valueType="custom"
                  formItemProps={{
                    rules: [
                      {
                        min: 0,
                        max: 100,
                      },
                      {
                        required: true,
                      },
                    ],
                  }}
                  renderFormItem={(schema, record) => {
                    return (
                      <NewSelect
                        schema={schema}
                        record={record?.record}
                        examBodyPartList={examBodyPartList}
                        onChange={schema?.originProps?.onChange}
                      />
                    );
                  }}
                />

                <VSTableColumn
                  dataIndex={['examItemName']}
                  title="标本名称"
                  valueType="custom"
                  fieldProps={{}}
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  }}
                  renderFormItem={(...[schema, formKey, form]) => {
                    return (
                      <InputRender
                        schema={schema}
                        form={form}
                        formKey={formKey}
                        pathologyList={pathologyList}
                        onChange={schema?.originProps?.onChange}
                        examBodyPartList={examBodyPartList}
                      />
                    );
                  }}
                />

                <VSTableColumn
                  dataIndex={['amount']}
                  title="数量"
                  valueType="digit"
                  initialValue={1}
                  fieldProps={{
                    min: 1,
                    width: '100%',
                  }}
                  formItemProps={{
                    rules: [
                      {
                        required: true,
                      },
                      {
                        validator: (...[, value]) => askIsInteger(value),
                      },
                    ],
                  }}
                />

                <VSTableColumn
                  dataIndex={['fixTime']}
                  title="标本固定时间"
                  valueType="aspirinDateTimePicker"
                  fieldProps={{
                    valueType: 'timestamp',
                  }}
                />

                <VSTableColumn
                  dataIndex={['takeOutTime']}
                  title="标本取出时间"
                  valueType="aspirinDateTimePicker"
                  fieldProps={{
                    valueType: 'timestamp',
                  }}
                />
                <VSTableColumn
                  dataIndex={['frozenIndicator']}
                  title="冰冻已送"
                  valueType="check"
                  fieldProps={{}}
                  // width={80}
                  width={labelNumberWidth(8)}
                />

                {/* <VSTableColumn
                dataIndex={['pathologyOrderDetail', 'frozenIndicator']}
                title="不计费(缺失)"
                valueType="check"
                fieldProps={{}}
              /> */}
              </VSTable>
            </VSFormItem>
          </FormBlock>
        </VSForm>
      </div>
      {/* <div className="footer">
        <Button className="cancel" onClick={onClose}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{
            marginLeft: 12,
          }}
        >
          确定
        </Button>
      </div> */}
    </div>
  );
};

export default forwardRef(Index);
