import { createServices } from '@vs/vsf-boot';
export default createServices({
  FinanceBillingRecordController_getInpVisitInfo_a4e217: {
    method: 'post',
    url: '/api/finance-billing-record/get-inp-visit-info',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientBillingRecordWithInpVisitSimpleVoFinanceBillingRecordEntranceWebVo,
    ) => data,
  },
  FinanceBillingRecordController_getNoSettleInpVisitList_52aaed: {
    method: 'post',
    url: '/api/finance-billing-record/get-no-settle-inp-visit-list',
    parameterFomatter: (data?: { displayId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpVisitSimpleVoFinanceBillingRecordEntranceWebVo[],
    ) => data,
  },
  FinanceBillingController_bedAndMiscBillDetailManualPricing_2d2e72: {
    method: 'post',
    url: '/api/finance-billing-record/bed-and-misc-bill-detail-manual-pricing',
    parameterFomatter: (data?: { inpVisitId: number; includeToday: boolean }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  FinanceBillingRecordController_patientBillingAuditForBillingStation_8cc57e: {
    method: 'post',
    url: '/api/finance-billing-record/patient-billing-audit-for-billing-station',
    parameterFomatter: (data?: {
      inpVisitId: number;
      successIndicator: boolean;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DepartmentDtoController_getWardPagedBySearchCode_0a57cd: {
    method: 'post',
    url: '/api/organization/get-ward-paged-by-search-code',
    parameterFomatter: (data?: { searchCode: string; clinicType: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentVoFinanceBillingRecordEntranceWebVoPatientbillingrecordwithinpvisitsimplevo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_getListClinicPatientBedSimpleVoByBedWardId_671c47: {
    method: 'post',
    url: '/api/clinic-bed/get-list-clinic-patient-bed-simple-vo-by-bed-ward-id',
    parameterFomatter: (data?: { bedWardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicPatientBedSimpleVoClinicBedEntranceWebVo[],
    ) => data,
  },
});
