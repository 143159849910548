import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpRefundDispenseController_getHerbDispenseCountForInpRefundDrugApply_7d4f29:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-herb-dispense-count-for-inp-refund-drug-apply',
      parameterFomatter: (data?: {
        query: QueryInpHerbDispenseForRefundApplyInputEoDrugPrescriptionDispensePersistEo;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: QueryInpHerbDispenseForRefundApplyOutEoDrugPrescriptionDispensePersistEo[],
      ) => data,
    },
  PatientInHosptialController_queryScrollWardPatientList_346269: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-scroll-ward-patient-list',
    parameterFomatter: (data?: {
      qto: PageWardPatientQueryEoClinicInpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientInHospitalBedWardPatientVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
