import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-outp-special-clinic-dictionary-qto',
    parameterFomatter: (data?: {
      qto: OutpSpecialClinicDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpSpecialClinicDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deleteOutpSpecialClinic_db4f60: {
    method: 'post',
    url: '/api/outp-register-define/delete-outp-special-clinic',
    parameterFomatter: (data?: {
      btoParam: DeleteOutpSpecialClinicBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpSpecialClinicDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateOutpSpecialClinic_798b56: {
    method: 'post',
    url: '/api/outp-register-define/add-update-outp-special-clinic',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateOutpSpecialClinicBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
});
