import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseCommonController_getAllByVitalsignItemDictionaryQto_1cee6b: {
    method: 'post',
    url: '/api/base-common/get-all-by-vitalsign-item-dictionary-qto',
    parameterFomatter: (data?: {
      qto: VitalsignItemDictionaryQtoBaseCommonPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: VitalsignItemDictionaryVoBaseCommonEntranceWebVo[],
    ) => data,
  },
});
