import React, { useCallback, useState } from 'react';
import NewDrawer from '@/pages/Index/components/new_drawer';
import {
  Section,
  VSPromiseTable,
  VSTableColumn,
  Button,
  Popover,
} from '@vs/vsf-kit';

function outdrawer({ selectTableParamAction, outBusinessType }) {
  const [cancellationData, setCancellationData] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const onHide = () => {
    setOpenDrawer(false);
  };
  const reload = () => {
    vsf?.refs?.ImportOutDrawerTable?.reload();
  };
  const onFetch = useCallback(async () => {
    if (openDrawer && outBusinessType) {
      const res =
        await vsf.services?.DrugStorageImportExportController_getDrugExportMasterList_0cb031(
          { qto: {} },
        );
      return res?.data;
    }
  }, [openDrawer, outBusinessType]);
  return (
    <>
      <NewDrawer
        open={openDrawer}
        width="40%"
        onOpen={() => setOpenDrawer(!openDrawer)}
        title={<div style={{ color: '#3276E8' }}>药品出库单</div>}
        buttonTitle="药品出库单"
        backgroundColor="var(--blue)"
        footer={
          <div className="aspirin-table-footer-button">
            <Button type="primary" onClick={reload}>
              刷新
            </Button>
            <Button type="primary" onClick={onHide}>
              隐藏
            </Button>
          </div>
        }
        buttonStyle={{
          top: '50%',
        }}
        drift="98px"
        style={{
          top: 104,
        }}
        isOther
      >
        <VSPromiseTable
          vsid="86592"
          id="ImportOutDrawerTable"
          onFetch={onFetch}
          rowClassName={(node) => {
            return node?.drugProvideNoticeId ===
              cancellationData?.drugProvideNoticeId
              ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
              : 'aspirin-vs-table-row-box aspirin-row-select-box';
          }}
          pagination={false}
          onRow={(node) => {
            return {
              onClick() {
                setCancellationData(node);
              },
              onDoubleClick() {
                // console.log(node,'出库单')
                let obj = node;
                obj.flagType = true;
                selectTableParamAction(obj);
                onHide();
              },
            };
          }}
        >
          <VSTableColumn
            dataIndex={['exportImportCode']}
            title="出单类型"
            valueType="select"
            fieldProps={{
              onChange: (v) => {
                console.log(v);
              },
            }}
            dataSource={vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT}
            render={(_, val) => {
              return (
                outBusinessType?.find(
                  (item) => item?.value === val?.exportImportCode,
                )?.label ?? ''
              );
            }}
          />

          <VSTableColumn
            dataIndex={['exportDate']}
            title="出库日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={[
              'drugProvideNoticeDto',
              'provideStorageDepartment',
              'storageName',
            ]}
            title="供应单位"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 40 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['documentNumber']}
            title="出库单号"
            valueType="text"
          />
        </VSPromiseTable>
      </NewDrawer>
    </>
  );
}

export default outdrawer;
