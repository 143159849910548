import { getEnumsLabel, getScrollY } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { useCallback, useEffect, useState } from 'react';
import { Descriptions } from 'antd';
import {
  getInpVisitInfo,
  getListClinicPatientBedSimpleVoByBedWardId,
  getNoSettleInpVisitList,
  getWardPagedBySearchCode,
  queryPagedByPatientFullQto,
} from './services';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { isArray, set } from 'lodash';
import AwaitDrawer from './AwaitDrawer';

import '@/style/adaptation.less';

const auditStatus = {
  NOT_AUDIT: '未审核',
  AUDIT_BY_NURSE: '护士审核',
  AUDIT_BY_BILLING_OFFICE: '收费处审核',
};
const UserInfo = (props) => {
  const { PatientDetails, setPatientDetails, inquireUser } = props,
    // 	查询当前院区病区（护理单元）列表
    { data: WardPageData } = useRequest(async () => {
      const res = await getWardPagedBySearchCode();
      return res;
    }),
    //查询床位病区的在床患者简单信息列表
    { data: PatientBedSimpleData, run: PatientBedSimpleRun } = useRequest(
      async (params) => {
        const res = await getListClinicPatientBedSimpleVoByBedWardId(params);
        return res?.map((ele) => ({
          ...ele,
          bedLabelAndPatientName: ele?.bedLabel + '-' + ele?.patientName,
        }));
      },
      { manual: true },
    ),
    //查询患者未结算住院主记录
    { data: PatientInpVisitInfoData, run: PatientInpVisitInfoRun } = useRequest(
      async (params) => {
        setPatientDetails();
        const {
          displayId,
          patientName: name,
          identityCode,
          patientId,
          ...rest
        } = params;
        vsf?.refs?.billingAduitInpVisitInfo?.setFieldValue(
          'displayId',
          displayId,
        );
        const res = await getNoSettleInpVisitList({ displayId });
        if (res?.length <= 0) message.error('未查询到患者住院记录');
        return res?.map((ele) => ({
          ...ele,
          displayId,
          name,
          identityCode,
          patientId,
        }));
      },
      { manual: true },
    );
  // 常量函数
  const inquire = useCallback(async () => {
      setPatientDetails();
      const inquireParams =
        vsf?.refs?.billingAduitInpVisitInfo?.getFieldValue('displayId');
      let patient;
      if (inquireParams)
        patient = await queryPagedByPatientFullQto({
          displayIdIs: inquireParams,
        });
      if (!isArray(patient)) return;
      const {
        displayId,
        name: patientName,
        id: patientId,
        identityCode,
      } = patient?.[0];
      return PatientInpVisitInfoRun({
        displayId,
        patientId,
        patientName,
        identityCode,
      });
    }, []),
    AwaitDrawerCallback = async (params) => {
      await vsf?.refs?.billingAduitInpVisitInfo?.setFieldValue(
        'displayId',
        params?.displayId,
      );
      inquire();
    };
  useEffect(() => {
    if (PatientDetails?.id) {
      PatientBedSimpleRun({ bedWardId: PatientDetails?.wardId });
      vsf?.refs?.billingAduitInpVisitInfo?.setFieldsValue({
        wardInfo: PatientDetails?.wardId,
        bedInfo: PatientDetails?.bedId,
      });
    }
  }, [PatientDetails]);
  useEffect(() => inquireUser && inquire(), [inquireUser]);
  return (
    <div className="UserInfo">
      <VSForm vsid="44332" id="billingAduitInpVisitInfo">
        <VSFormLayout key="0" columnCount={3}>
          <VSFormItem
            name={['wardInfo']}
            label="病区"
            valueType="selectCode"
            fieldProps={{
              fieldNames: { label: 'departmentName', value: 'id' },
              placeholder: '请选择病区',
              onChange: (params) =>
                PatientBedSimpleRun({
                  bedWardId: params?.id,
                }),
            }}
            dataSource={WardPageData}
          />
          <VSFormItem
            name={['bedInfo']}
            label="床位"
            valueType="selectCode"
            fieldProps={{
              fieldNames: {
                label: 'bedLabelAndPatientName',
                value: 'bedId',
              },
              placeholder: '请选择床位',
              onChange: ({ patientDisplayId: displayId, ...rest }) =>
                PatientInpVisitInfoRun({ displayId, ...rest }),
            }}
            dataSource={PatientBedSimpleData}
          />
          <VSFormItem
            name={['displayId']}
            label="患者ID"
            valueType="text"
            rules={[]}
            fieldProps={{
              onPressEnter: inquire,
            }}
          />
        </VSFormLayout>
      </VSForm>
      <Descriptions column={8} size="default" className="Descriptions">
        <Descriptions.Item label="姓   名">
          {PatientDetails?.name ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="性   别">
          {getEnumsLabel('SEX_DICT', PatientDetails?.gender) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="费   别">
          {getEnumsLabel('CHARGE_TYPE_DICT', PatientDetails?.chargeType) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="人员类别">
          {getEnumsLabel('IDENTITY_DICT', PatientDetails?.identityCode) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="冻结状态">
          {PatientDetails?.sealAccountantIndicator ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="审核状态">
          {auditStatus[PatientDetails?.auditStatus] ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="入院日期">
          {PatientDetails?.admissionDateTime &&
            dayjs(PatientDetails?.admissionDateTime).format(
              'YYYY-MM-DD HH:mm:ss',
            )}

          {PatientDetails?.admissionDateTime &&
            `（${dayjs().diff(PatientDetails?.admissionDateTime, 'day')}天）`}
        </Descriptions.Item>
      </Descriptions>
      <PatientListModel
        {...{ PatientListData: PatientInpVisitInfoData, setPatientDetails }}
      />

      <AwaitDrawer {...{ AwaitDrawerCallback }} />
    </div>
  );
};

// 患者接诊记录
const PatientListModel = (props) => {
  const { PatientListData, setPatientDetails } = props;
  const [open, setOpen] = useState(false),
    { run: InpVisitInfoRun } = useRequest(
      async (params) => {
        if (!params?.inpVisitId) return setPatientDetails({});
        const res = await getInpVisitInfo(params);
        if (['', null, undefined, NaN].includes(res?.bedId))
          message?.error('该入院记录，患者已出院没有床号');
        setPatientDetails(res);
        return res;
      },
      { manual: true },
    );
  useEffect(() => {
    if (!PatientListData) return;
    if (PatientListData?.length >= 2) return setOpen(true);
    InpVisitInfoRun?.({ inpVisitId: PatientListData?.[0]?.inpVisitId });
  }, [PatientListData]);
  return (
    <Modal
      title={
        <span style={{ color: 'rgba(50, 118, 232, 1)' }}>患者接诊记录</span>
      }
      open={open}
      onCancel={() => setOpen(false)}
      width={1200}
      footer=""
    >
      <VSTable
        className="common_table"
        scroll={{
          y: getScrollY(400),
        }}
        value={PatientListData}
        rowClick={{
          type: 'default',
          action: 'double-click',
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: false,
          showTotal: undefined,
        }}
        onRow={(node) => {
          return {
            onDoubleClick: () => {
              setOpen(false);
              InpVisitInfoRun?.({ inpVisitId: node?.inpVisitId });
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={['admissionDateTime']}
          title="入院日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['patientId']}
          title="病人ID"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['identityCode']}
          title="人员类别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
        />
      </VSTable>
    </Modal>
  );
};
export default definePage(UserInfo);
