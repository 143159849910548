import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderPathologyController_getOrderPathologyByOrderId_503ccd: {
    method: 'post',
    url: '/api/clinic-record-order-pathology/get-order-pathology-by-order-id',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderPathologyVoClinicRecordOrderPathologyEntranceWebVo,
    ) => data,
  },
  UserStaffController_queryDoctorListBySearchCode_d60b63: {
    method: 'post',
    url: '/api/user-staff/query-doctor-list-by-search-code',
    parameterFomatter: (data?: {
      qto: QueryDoctorListQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithDoctorDetailDtoUserStaffManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
