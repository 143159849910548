import { useRef } from 'react';

const withResolvers = () => {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve,
    reject,
  };
};

export default function useWithResolvers() {
  const promise = useRef(withResolvers());
  return {
    promise: promise.current.promise,
    resolve: (value) => {
      promise.current.resolve(value);
      promise.current = withResolvers();
    },
    reject: promise?.current.reject,
  };
}
