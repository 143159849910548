import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal } from '@vs/vsf-kit';
import React, { Fragment, useState } from 'react';
import ClinicTable from './table'
import Container from '@/module/Container';
import Block from '@/module/Block';
const SpecimenManage = (props) => {
  return (
    <Fragment>
      <Container layout={{
        items: [
          {
            block: 1,
            bgColor: 'var(--background)'
          },
        ],
      }}>
        <Block className="aspirin-table-search-padding-no">
          <ClinicTable />
        </Block>
      </Container>
    </Fragment>
  );
};
export default definePage(SpecimenManage);
