import { createServices } from '@vs/vsf-boot';
export default createServices({
  BaseDictionaryController_createBaseCode_0172bf: {
    method: 'post',
    url: '/api/base-dictionary/create-base-code',
    parameterFomatter: (data?: {
      btoParam: CreateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_updateBaseCode_c565bf: {
    method: 'post',
    url: '/api/base-dictionary/update-base-code',
    parameterFomatter: (data?: {
      btoParam: UpdateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_getEoClassByTypeId_d570f0: {
    method: 'post',
    url: '/api/base-dictionary/get-eo-class-by-type-id',
    parameterFomatter: (data?: { typeId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExtensionClassVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
});
