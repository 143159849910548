import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  InputNumber,
  InputProps,
  Row,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest, useUpdateEffect } from 'ahooks';
import { Table } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { mockDoctorId } from '@/mock';
import Loading from '@/module/Loading';

import ComplexSelect from '../complex_select';
import { useInputEnter } from '../dosage/utils';
import { askFixed } from '../func/ask';
import { useFrequencyEffect } from '../func/hooks';

/**
 * DrugSelect
 */
const DrugInputNumber = forwardRef((props: any, ref: any) => {
  const { value, onFieldEnter, onKeyUp, onChange, id: key, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const form = Form?.useFormInstance();
  const record = form?.getFieldValue(key);

  // useUpdateEffect(() => {
  //   input?.current?.focus();
  // }, [record?.stockId]);

  return (
    <InputNumber
      ref={ref}
      width="100%"
      min={0}
      value={value}
      onChange={(value: number) => {
        /**
         * 计算总价
         */
        form.setFieldValue(
          [key, 'purchaseAmount'],
          askFixed((record?.purchasePrice ?? 0) * (value ?? 0), 2),
        );
        onChange(value);
      }}
      addonAfter={
        vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
          (item) => item?.value == record?.packageUnit,
        )?.label ?? record?.packageUnit
      }
      {...rest}
      {...fieldEnterProps}
    />
  );
});

DrugInputNumber.displayName = 'DrugInputNumber';

export default compose(
  withRef(),
  withField<any>({
    name: 'drugInputNumber',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      return <>{value}</>;
    },
  }),
)(DrugInputNumber) as typeof DrugInputNumber;
