import askRomanAlphabet from './askRomanAlphabet';

export default function askSearch(value: string, option: any, label = 'label') {
  // console.log(value, option, label, '====');
  const [pinyin, wubi] = askRomanAlphabet(String(option?.[label]), 'all');
  const filter = (text) => {
    return String(text)
      ?.replace(/\s*/g, '')
      ?.toLocaleLowerCase()
      ?.includes(value?.toLocaleLowerCase()?.replace(/\s*/g, ''));
  };
  return (
    String(option?.[label]).includes(value) || filter(pinyin) || filter(wubi)
  );
}
/**
 * 高亮显示
 * @returns <span class="aspirin-conspicuous-box">xxx</span>
 */
function conspicuous() {
  return '';
}

askSearch.conspicuous = conspicuous;
