import {
  Input,
  VSForm,
  VSFormLayout,
  Button,
  VSFormItem,
  Checkbox,
  Search,
  Space,
  message,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import data from '@/components/Address/data';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';

import PayModal from './components/ParModal';
import RegistratuonForm from './components/RegistratuonForm';
import { TableCart, SelectTableCart, CardList } from './components/TableCart';

import './index.less';
const _ = require('lodash');

const index = (props) => {
  const [register, setRegister] = useState([]);
  const [amFarewell, setAmFarewell] = useState(true);
  const [pmFarewell, setPmFarewell] = useState(true);
  const [selectNumberType, setSelectNumberType] = useState();
  const [selectDatasource, setSelectDatasource] = useState();
  //保存锚点的唯一id(索引)
  const [indexing, setIndexing] = useState(0);
  const [checkboxDisable, setCheckboxDisable] = useState();
  //用来保存index
  const [cheackCart, setCheackCart] = useState();

  const [appointmentData, setAppointmentData] = useState();
  const { routes } = props;
  const selectStyle = {
    background: '#EBF2FF',
    border: ' 2px solid #3276E8',
    borderRadius: '4px',
    transition: 'background 0.3s ease',
  };
  const unSelectStyle = {
    background: '#fff',
    border: '2px solid #e7e7e7',
    borderRadius: '6px',
  };
  const { data: registerListData, run } = useRequest(
    async (param) => {
      const res =
        await vsf?.services?.OutpRegisterController_getTodayClinicDetailForRegister_7896c6?.(
          param,
        );
      const sortData = res.data?.map((item) => {
        let list = item?.clinicRegisterList.sort((a, b) => {
          // 将上午排在下午前面
          if (a.timeDescription === 'AM') return -1;
          if (b.timeDescription === 'PM') return 1;
          return 0;
        });
        return { ...item, clinicRegisterList: list };
      });
      return sortData;
    },
    {
      manual: true,
    },
  );
  useMount(() => {
    run({});
  });
  useEffect(() => {
    if (dayjs().hour() >= 12) {
      setCheckboxDisable(true);
      setPmFarewell(true);
      setAmFarewell(false);
    }

    if (registerListData) {
      // console.log(registerListData);

      if (!amFarewell) {
        const data = registerListData?.map((item) => {
          return {
            ...item,
            clinicRegisterList: item?.clinicRegisterList.filter((item) => {
              return item?.timeDescription === 'PM';
            }),
          };
        });
        setRegister(data);
      } else if (!pmFarewell) {
        const data = registerListData?.map((item) => {
          return {
            ...item,
            clinicRegisterList: item?.clinicRegisterList.filter((item) => {
              return item?.timeDescription === 'AM';
            }),
          };
        });
        setRegister(data);
      } else {
        setRegister(registerListData);
      }
    }
  }, [pmFarewell, amFarewell, registerListData]);

  useEffect(() => {
    if (appointmentData && selectDatasource)
      setCheackCart(
        `${selectDatasource?.outpSpecialId}${appointmentData[0]?.id}`,
      );
  }, [appointmentData, selectDatasource]);

  const appointmentCallback = (val) => {
    setSelectNumberType(undefined);
    setCheackCart(undefined);
    const arrOutpSpecial = [];
    register.map((valuse) => {
      val?.clinicRegisterAppointInfoList.map((item) => {
        if (item.outpSpecialClinicId === valuse.outpSpecialId) {
          arrOutpSpecial.push(valuse);
          return { ...valuse };
        }
      });
    });

    arrOutpSpecial.map((item) => {
      setSelectDatasource(item);
      if (item?.clinicRegisterList.length) {
        const arr = [];

        val?.clinicRegisterAppointInfoList.map((value) => {
          const findClinicRegisterDetailId = item?.clinicRegisterList.find(
            (item) => {
              return item?.id === value?.clinicRegisterDetailId;
            },
          );

          arr.push(findClinicRegisterDetailId);
        });
        setAppointmentData(arr);

        return { ...item, clinicRegisterList: arr };
      } else {
        return { ...item };
      }
    });
  };

  const onChangeCartItem = (item, datasource) => {
    message.destroy();
    setSelectNumberType(item);
    item && message.success('号别选择成功');
    setSelectDatasource(datasource);
  };

  const scrollToAnchor = (id) => {
    const element = document.getElementById(id);
    setIndexing(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const InputValueChange = (e) => {
    const field = JSON.parse(JSON.stringify(register));
    const fiy = field.filter((item) => {
      const numberType = item?.clinicRegisterList?.map((data) => {
        console.log('---=====-------');
        console.log(data?.clinicRegister?.clinicRegisterDefine?.clinicLabel);
        console.log(e.target.value);
        console.log(
          data?.clinicRegister?.clinicRegisterDefine?.clinicLabel?.includes(
            e.target.value,
          ),
        );
        // data?.clinicRegister?.clinicRegisterDefine?.clinicLabel?.includes(
        //   e.target.value,
        // )

        if (
          data?.clinicRegister?.clinicRegisterDefine?.clinicLabel?.includes(
            e.target.value,
          ) ||
          askRomanAlphabet(
            data?.clinicRegister?.clinicRegisterDefine?.clinicLabel,
            'pinyin',
          )?.includes(e.target.value?.toUpperCase())
        ) {
          console.log('1223232332222222');
          console.log(data);
          return data;
        }
      });

      if (numberType.length > 0) {
        if (_.isEqual(item?.clinicRegisterList, numberType)) {
          return true;
        }
      }
    });
    // console.log('liuhuan123')
    // console.log(fiy)
    if (!e.target.value) {
      run({});
    } else {
      // setRegister(fiy);
      run({ searchCode: e.target.value });
    }
  };

  return (
    <div className="register-container-layout">
      <div className="register-cart-top">
        <div className="register-cart-top-left">
          <div className="medical-steps">
            <ul>
              {register
                ?.filter((item) => item.clinicRegisterList.length !== 0)
                .map((item, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <li
                      className={indexing !== index ? 'selected' : 'unselect'}
                      index={index}
                    >
                      <a
                        href={'#index' + index}
                        onClick={() => scrollToAnchor(index)}
                      >
                        {item?.outpSpecialName}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div className="register-cart-top-right">
          <div className="register-cart-serach">
            <div className="serach-input">
              <Input
                placeholder="请输入号别"
                width={200}
                onChange={InputValueChange}
                prefix={<Icon type="icon-sousuo"></Icon>}
              />
            </div>
            <div className="select-cart-show">
              <div className="select-cart-show-title">选中号别：</div>
              <div
                className="select-cart-show-content"
                onClick={() => {
                  selectNumberType && setSelectNumberType(undefined);
                  selectNumberType && setCheackCart(undefined);
                  appointmentData && setAppointmentData(undefined);

                  setCheackCart(undefined);
                }}
              >
                {selectNumberType && (
                  <SelectTableCart
                    selectNumberType={selectNumberType}
                    datasource={selectDatasource}
                  />
                )}
                {appointmentData &&
                  appointmentData.map((item) => {
                    return (
                      item && (
                        <SelectTableCart
                          selectNumberType={selectNumberType}
                          datasource={selectDatasource}
                          appointmentData={item}
                        />
                      )
                    );
                  })}
              </div>
            </div>
            <div className="bill-number">
              <div className="select-cart-number">票据NO：</div>
              <div className="select-cart-farewell">
                <Checkbox
                  value={amFarewell}
                  disabled={checkboxDisable}
                  onChange={(item) => {
                    // item && setPmFarewell(false);
                    setAmFarewell(item);
                  }}
                >
                  上午
                </Checkbox>
                <Checkbox
                  value={pmFarewell}
                  onChange={(item) => {
                    setPmFarewell(item);
                    // item && setAmFarewell(false);
                  }}
                >
                  下午
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="register-cart-main">
            <div className="register-cart-main-container">
              {register
                ?.filter((item) => item.clinicRegisterList.length !== 0)
                ?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="register-cart-main-title">
                        <div
                          className="register-cart-main-title-before"
                          id={'index' + index}
                        >
                          {item?.outpSpecialName}
                        </div>
                      </div>
                      <div className="register-cart-main-content">
                        {item?.clinicRegisterList?.map((data, index1) => {
                          return (
                            <div
                              key={`${index}${index1}`}
                              style={
                                cheackCart ===
                                `${item?.outpSpecialId}${data?.id}`
                                  ? selectStyle
                                  : unSelectStyle
                              }
                              onClick={() => {
                                // selectNumberType &&
                                // setSelectNumberType(undefined);
                                selectNumberType && setCheackCart(undefined);
                                setAppointmentData(undefined);
                                setCheackCart(
                                  `${item?.outpSpecialId}${data?.id}`,
                                );

                                if (
                                  cheackCart ===
                                  `${item?.outpSpecialId}${data?.id}`
                                ) {
                                  setCheackCart(undefined);
                                  onChangeCartItem(undefined, undefined);
                                }
                              }}
                            >
                              <TableCart
                                data={data}
                                datasource={item}
                                onChangeCartItem={onChangeCartItem}
                                wasSelect={
                                  `${index}${index1}` === cheackCart
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              {register.length === 0 && (
                <div className="register-empty">
                  <Empty />
                </div>
              )}
              {/* <div className='register-loadding'>{loading&&<Loading />}</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="register-serach-bottom">
        <RegistratuonForm
          selectNumberType={selectNumberType}
          appointmentCallback={appointmentCallback}
          onChange={() => {
            run();
          }}
          routes={routes}
        />
      </div>
    </div>
  );
};

export default index;
