import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitRecordController_deleteInpEscortRegister_831020: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/delete-inp-escort-register',
    parameterFomatter: (data?: {
      btoParam: DeleteInpEscortRegisterBtoClinicInpVisitRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicInpVisitRecordController_getAllByInpEscortRegisterActiveQto_0999ae: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/get-all-by-inp-escort-register-active-qto',
    parameterFomatter: (data?: {
      qto: InpEscortRegisterActiveQtoClinicInpVisitRecordPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpEscortRegisterVoClinicInpVisitRecordEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitRecordController_saveInpEscortRegister_01cd8c: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/save-inp-escort-register',
    parameterFomatter: (data?: {
      btoParam: SaveInpEscortRegisterBtoClinicInpVisitRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpEscortRegisterVoClinicInpVisitRecordEntranceWebVo,
    ) => data,
  },
});
