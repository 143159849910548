import {
  Input,
  VSForm,
  VSFormLayout,
  Button,
  VSFormItem,
  Checkbox,
  Modal,
  Space,
  message,
  Image,
  Select,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

import readCard from '@/assets/icon/readCard.png';
import SerachIcon from '@/assets/icon/Search.png';

import PrimaryIndexModal from './PrimaryIndexModal';
import { getScrollYRem, labelNumberWidth } from '@/utils';

import './index.less';

function RegistratuonForm(props) {
  const { selectData, numberData, callBack, routes } = props;
  const [registerData, setRegisterData] = useState();
  const [indexOpen, setIndexOpen] = useState(false);
  const [identificationClass, setiDentificationClass] = useState('身份证');
  const [indexData, setIndexData] = useState();
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  useEffect(() => {
    indexData && onPressEnter(indexData?.displayId);
  }, [indexData]);
  useEffect(() => {
    if (routes?.query?.displayId) {
      onPressEnter(routes?.query?.displayId);
    }
  }, [routes]);
  const onSubmit = async (params) => {
    message.destroy();
    const filed = JSON.parse(
      JSON.stringify(vsf?.refs?.PatientFullByDisplayForm?.getFieldsValue()),
    );
    const formData = {
      ...filed,
      ...numberData,
      // patientId: filed?.id,
      identificationClass: identificationClass?.code || identificationClass,
      // serialNumber: numberData?.serialNumber,
      clinicRegisterDetailId:
        numberData?.clinicRegisterDetailId || selectData?.id,
      clinicRegisterDetailTimeDivisionId:
        numberData?.clinicRegisterDetailTimeDivisionId || numberData?.id,
    };

    if (!filed?.displayId) {
      message.error('请输入患者ID');
      return;
    }

    if (!numberData) {
      message.error('请选择号别');

      return;
    }
    const res =
      await vsf.services?.ClinicOutpRegisterAppointController_appointClinicRegister_5258b4?.(
        {
          registerAppointPatientInfoEo: formData,
        },
      );
    if (res?.code === 200) {
      callBack();
      message.success('预约成功');
      // location?.reload();
    }
  };

  const onPressEnter = async (displayId) => {
    const res = await vsf.services
      ?.ClinicOutpRegisterAppointController_getPatientInfo_2bdd24?.({
        displayId: displayId,
      })
      .then((res) => {
        vsf?.refs?.PatientFullByDisplayForm.setFieldsValue({ ...res.data });
        setiDentificationClass(res?.data?.identificationClass);
        return res?.data;
      });
    if (!res) {
      vsf?.refs?.PatientFullByDisplayForm.resetFields();
      message.warn('请去主索引完善信息');
    }
  };

  return (
    <div className="patientfull-bydisplay-form">
      <VSForm
        id="PatientFullByDisplayForm"
        onSubmit={onSubmit}
        labelWidth={labelNumberWidth(6)}
      >
        <VSFormItem
          name={['patientId']}
          label="患者id"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['serialNumber']}
          label="号序"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        {/* <VSFormItem
          name={['clinicRegisterDetailId']}
          label="门诊排班明细id"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        /> */}

        <VSFormItem
          name={['platformId']}
          label="平台id"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormItem
          name={['clinicRegisterDetailTimeDivisionId']}
          label="号序id"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormItem
          name={['remindInfo']}
          label="提醒信息"
          style={{ display: 'none' }}
          valueType="todo"
          fieldProps={{}}
        />

        <VSFormItem
          name={['controlType']}
          label="控制类型"
          style={{ display: 'none' }}
          valueType="select"
          dataSource={[
            { label: '强控', value: 'FORCE' },
            { label: '提醒', value: 'REMIND' },
          ]}
          fieldProps={{}}
        />
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout key="1" columnCount={3} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['displayId']}
            label="患者ID"
            valueType="text"
            fieldProps={{
              onPressEnter: (e) => {
                onPressEnter(e.target.value);
              },
              suffix: (
                <div>
                  <Image
                    src={SerachIcon}
                    style={{ width: '10px', height: '10px', cursor: 'pointer' }}
                    preview={false}
                    onClick={() => {
                      setIndexOpen(true);
                    }}
                  ></Image>
                </div>
              ),
              allowClear: false,
            }}
          />
          <VSFormItem
            // name={['identificationNumber']}
            label="证件及编号"
            valueType="text"
            fieldProps={{
              style: { paddingLeft: '10px' },
              addonBefore: (
                <>
                  <Select
                    style={{ width: '200px' }}
                    dataSource={certificateData}
                    fieldNames={{ label: 'name', value: 'code' }}
                    // defaultValue={'身份证'}
                    onChange={(val) => {
                      setiDentificationClass(val);
                    }}
                  />
                </>
              ),
              suffix: (
                <div
                  className="suffix-readCard"
                  onClick={() => {
                    message.success('读卡中');
                  }}
                >
                  <div className="suffix-cardImg">
                    <Image
                      src={readCard}
                      style={{ width: '20px', height: '20px' }}
                      preview={false}
                    ></Image>
                  </div>
                  <div className="suffix-cardText">读卡</div>
                </div>
              ),
              placeholder: '请读卡',
            }}
          />
          {/* //TODO */}
          <VSFormItem
            name={['defaultChargeType']}
            label="最近就诊"
            fieldProps={{ disabled: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            rules={[
              {
                message: '显示费别长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
          />
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={4} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['name']}
            label="姓名"
            valueType="text"
            rules={[
              { message: '姓名长度不合法', type: 'string', min: 0, max: 1024 },
              { required: true },
            ]}
            fieldProps={{}}
          />
          {/* <VSFormItem
            name={['gender']}
            label="性别"
            rules={[
              { message: '性别长度不合法', type: 'string', min: 0, max: 1024 },
              { required: true },
            ]}
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
          /> */}
          <VSFormItem
            name={['gender']}
            label="性别"
            fieldProps={{ disabled: true }}
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
          />
          <VSFormItem
            name={['birthday']}
            label="出生日期"
            // fieldProps={{ disabled: true }}
            valueType="date"
            // width={600}
            fieldProps={{
              style: { width: '100%' },
            }}
            rules={[
              {
                required: true,
              },
            ]}
          />

          <VSFormItem
            name={['cellphone']}
            label="手机号"
            valueType="text"
            rules={[
              {
                required: true,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={3} labelWidth={labelNumberWidth(7)}>
          <VSFormItem
            name={['patientProfileDto', 'currentAddress', 'code']}
            label="现住地址"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums.enums.AREA_DICT}
          />
          <VSFormItem
            name={['patientProfileDto', 'currentAddress', 'detail']}
            label="详细地址"
            valueType="text"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums.enums.AREA_DICT}
          />

          <VSFormItem
            name={['identificationNumber']}
            label="证件号"
            valueType="text"
            fieldProps={{
              style: { paddingLeft: '10px' },
              addonBefore: (
                <>
                  <Select
                    style={{ width: '200px' }}
                    dataSource={certificateData}
                    fieldNames={{ label: 'name', value: 'code' }}
                    // defaultValue={'身份证'}
                    value={identificationClass}
                    onChange={(val) => {
                      setiDentificationClass(val);
                    }}
                  />
                </>
              ),

              placeholder: '请读入',
            }}
          />
          {/* <VSFormItem
            name={['patientProfileDto', 'currentAddress']}
            label="最近就诊"
            valueType="text"
            fieldProps={{}}
          /> */}
        </VSFormLayout>

        <VSFormLayout key="1" columnCount={2} labelWidth={labelNumberWidth(7)}>
          <a
            onClick={() => {
              vsf?.navigateTo(
                `/index/patient-main-add?type=${'appointmentRegister'}`,
              );
            }}
          >
            新建主索引
          </a>
          <div style={{ textAlign: 'right' }}>
            <Space>
              {/* <Button>取消</Button> */}
              <Button
                onClick={() => {
                  vsf?.refs?.PatientFullByDisplayForm?.resetFields();
                }}
              >
                刷新
              </Button>
              <Button type="primary">修改预约记录</Button>
              <Button
                type="primary"
                onClick={() => {
                  vsf?.refs?.PatientFullByDisplayForm?.submit();
                }}
                shape="default"
                size="medium"
              >
                预约
              </Button>
            </Space>
          </div>
        </VSFormLayout>
      </VSForm>
      <Modal
        open={indexOpen}
        title={'主索引查询'}
        width={getScrollYRem(-40)}
        onCancel={() => {
          setIndexOpen(false);
        }}
      >
        <PrimaryIndexModal
          callback={(param) => {
            setIndexData(param);
            setRegisterData(param);
            setIndexOpen(false);
          }}
        ></PrimaryIndexModal>
      </Modal>
    </div>
  );
}

export default RegistratuonForm;
