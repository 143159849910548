import React, { useContext, useEffect, useState } from 'react';
import './index.less';
import {
  Row,
  Col,
  Space,
  Button,
  VSForm,
  VSFormLayout,
  VSFormItem,
  Select,
  Image,
  Modal,
  message,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import readCard from '@/assets/icon/readCard.png';
import Cart from './Cart';
import dayjs, { Dayjs } from 'dayjs';
import { min } from 'lodash';
import { idCard } from '@/pattern';
import routes from '@/router/IndexRoute';
import { Screen } from '@/pages/Index/components';
import { CreateScreen } from '@/pages/Index/components/screen';
import { labelNumberWidth } from '@/utils';
function Index(props) {
  const [iDentificationClass, setiDentificationClass] = useState();
  const [payNumber, setPayNumber] = useState();
  const { data: dataSource, runAsync: questChange } = useRequest(
    async (params) => {
      let visitDateRangeIn;
      if (params?.visitDateRangeIn) {
        visitDateRangeIn = {
          begin: params?.visitDateRangeIn[0],
          end: params?.visitDateRangeIn[1],
          beginInclude: true,
          endInclude: true,
        };
      }
      const res =
        await vsf?.services?.OutpVisitController_queryOutpVisitHistory_65b7c4?.(
          {
            qto: {
              ...params,
              visitDateRangeIn,
            },
          },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const { run } = useRequest(
    async (params) => {
      const res =
        await vsf?.services?.ClinicOutpRegisterMainController_registerPreRefund_d348ca?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            outpVisitId: params?.id,
            patientId: params?.patient?.id,
          },
        );
      setPayNumber(res?.data);
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const updateChange = (param) => {
    setBackData(param);
  };

  const [backData, setBackData] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [open, setOpen] = useState(false);

  const onScreen = useContext(CreateScreen);

  const { data: numberClassData } = useRequest(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472?.(
        {
          qto: {},
        },
      );
    console.log(res?.data);
    return res?.data;
  });
  useMount(() => {
    // console.log(vsf?.refs?.backingSerachForm);
    const { routes } = props;
    console.log('liuhuan');
    console.log(routes?.query);
    if (routes?.query?.dispalyId) {
      vsf?.refs?.backingSerachForm.setFieldsValue({
        visitNumberIs: routes?.query?.dispalyId,
        visitDateRangeIn: '0000',
      });
      questChange({
        visitNumberIs: routes?.query?.dispalyId,
        visitDateRangeIn: undefined,
      });
    } else {
      questChange({
        visitDateRangeIn: [
          dayjs().startOf('day').valueOf(),
          dayjs().endOf('day').valueOf(),
        ],
      });
    }
  });
  const returnNumber = (params) => {
    setOpen(true);
    run(params);
    // console.log(params);
  };
  const updateSelectStatus = (v) => {
    console.log(v);
    setSelectStatus(v);
  };

  const onOk = async () => {
    const res =
      await vsf.services?.ClinicOutpRegisterMainController_registerRefund_de78b8?.(
        {
          outpPreRefundEo: payNumber,
        },
      );
    if (res?.code === 200) {
      message.success('退号成功');
      setOpen(false);
      setSelectStatus(undefined);
      vsf?.refs?.backingSerachForm?.resetFields();
      questChange({
        visitDateRangeIn: [
          dayjs().startOf('day').valueOf(),
          dayjs().endOf('day').valueOf(),
        ],
      });
    }
  };
  useEffect(() => {
    const file = vsf?.refs?.backingSerachForm?.getFieldValue('displayIdIs');
  });
  return (
    <div className="backing-layout">
      <div className="backing-container-layout">
        <div className="backing-serach">
          <VSForm id="backingSerachForm">
            <VSFormLayout columnCount={5} labelWidth={labelNumberWidth(6)}>
              <VSFormItem
                name={['visitDateRangeIn']}
                label="就诊日期"
                valueType="dateRange"
                fieldProps={{
                  allowClear: false,
                  onChange: (e) => {
                    if (e.length === 2) {
                      const param =
                        vsf?.refs?.backingSerachForm.getFieldsValue();
                      if (!param.visitNumberIs) {
                        delete param.visitNumberIs;
                      }
                      if (!param.displayIdIs) {
                        delete param.displayIdIs;
                      }
                      questChange(param);
                    }
                  },
                }}
                initialValue={[dayjs(), dayjs()]}
              />
              <VSFormItem
                name={['visitNumberIs']}
                label="流水号"
                valueType="text"
                fieldProps={{
                  onPressEnter: (e) => {
                    const param = vsf?.refs?.backingSerachForm.getFieldsValue();
                    if (!param.displayIdIs) {
                      delete param.displayIdIs;
                    }

                    if (!param?.visitNumberIs) {
                      questChange();
                    } else {
                      questChange(param);
                    }
                  },
                  onChange: (e) => {
                    vsf?.refs?.backingSerachForm.setFieldsValue({
                      visitDateRangeIn: undefined,
                    });
                  },
                }}
              />
              <VSFormItem
                name={['displayIdIs']}
                label="患者ID"
                valueType="text"
                columnCount={2}
                fieldProps={{
                  onPressEnter: (e) => {
                    const param = vsf?.refs?.backingSerachForm.getFieldsValue();
                    if (!param.visitNumberIs) {
                      delete param.visitNumberIs;
                    }

                    if (!param?.displayIdIs) {
                      questChange();
                    } else {
                      questChange(param);
                    }
                  },
                  // onPressEnter: (e) => {
                  //   vsf?.refs?.backingSerachForm.setFieldsValue({
                  //     visitDateRangeIn: undefined,
                  //   });
                  //   const param = vsf?.refs?.backingSerachForm.getFieldsValue();
                  //   if (!param.visitNumberIs) {
                  //     delete param.visitNumberIs;
                  //   }
                  //   questChange(param);
                  // },
                  onChange: (e) => {
                    if (e.target.value) {
                      vsf?.refs?.backingSerachForm.setFieldsValue({
                        visitDateRangeIn: undefined,
                      });
                    }
                  },
                }}
              />
              <VSFormItem
                name={['patientIdIs']}
                label="证件及编号"
                valueType="text"
                rules={[
                  {
                    pattern: idCard,
                    message: '身份证格式不正确',
                  },
                ]}
                fieldProps={{
                  className: 'backing-serach-patientIdIs',
                  addonBefore: (
                    <>
                      <Select
                        className="backing-serach-patientIdIs-select"
                        dataSource={certificateData}
                        fieldNames={{ label: 'name', value: 'code' }}
                        // defaultValue={'身份证'}
                        onChange={(val) => {
                          vsf?.refs?.backingSerachForm.setFieldsValue({
                            visitDateRangeIn: undefined,
                          });
                          setiDentificationClass(val);
                        }}
                      />
                    </>
                  ),

                  onKeyPress: async (e) => {
                    vsf?.refs?.backingSerachForm.setFieldsValue({
                      visitDateRangeIn: undefined,
                    });
                    const param = vsf?.refs?.backingSerachForm.getFieldsValue();
                    if (iDentificationClass) {
                      param.iDentificationClass = iDentificationClass;
                    }
                    // if (!param.visitDateRangeIn?.begin) {
                    //   param.visitDateRangeIn = [
                    //     dayjs().startOf('day').valueOf(),
                    //     dayjs().endOf('day').valueOf(),
                    //   ];
                    // }
                    if (!param.visitNumberIs) {
                      delete param.visitNumberIs;
                    }

                    if (e.key === 'Enter') {
                      if (param?.patientIdIs && iDentificationClass) {
                        // patientDetallRun();
                        const res =
                          await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
                            {
                              qto: {
                                ...{
                                  identificationNumberIn: [param?.patientIdIs],
                                  identificationClassIn: [
                                    iDentificationClass.name,
                                  ],
                                },
                                from: 0,
                                size: 20,
                              },
                            },
                          );
                        if (res?.data.result[0]) {
                          console.log(console.log('=========='));
                          delete param.patientIdIs;
                          vsf?.refs?.backingSerachForm.setFieldsValue({
                            displayIdIs: res?.data?.result[0].displayId,
                          });
                          param['displayIdIs'] = res?.data?.result[0].displayId;
                          questChange(param);
                        }
                      } else {
                        message.error('未找到患者信息');
                      }
                    }
                  },
                  suffix: (
                    <div
                      className="suffix-readCard"
                      onClick={() => {
                        message.success('读卡中');
                      }}
                    >
                      <div className="suffix-cardImg">
                        <Image
                          src={readCard}
                          className="suffix-readCard-image"
                          preview={false}
                        ></Image>
                      </div>
                      <div className="suffix-cardText">读卡</div>
                    </div>
                  ),
                  placeholder: '请读卡',
                  onChange: (v) => {
                    console.log(v);
                    if (!v.target.value) {
                      const param =
                        vsf?.refs?.backingSerachForm.getFieldsValue();
                      questChange(param);
                    }
                  },
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div className="backing-center">
          {dataSource?.map((item, index) => {
            return (
              <Cart
                dataSource={numberClassData}
                data={item}
                index={index}
                updateChange={updateChange}
                selectStatus={selectStatus}
                updateSelectStatus={updateSelectStatus}
              />
            );
          })}
        </div>

        <div className="backing-btn">
          <Space>
            <Button onClick={onScreen}>清屏</Button>
            <Button type="primary">医保单边退号</Button>
            <Button
              type="primary"
              onClick={() => {
                backData && returnNumber(backData);
              }}
              disabled={selectStatus || selectStatus === 0 ? false : true}
            >
              退号
            </Button>
          </Space>
        </div>
      </div>
      <Modal
        title={<div style={{ color: '#3276E8' }}>确认退费</div>}
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={onOk}
      >
        <div className="madal-layout-return">
          <div className="madal-layout-return-icon">退</div>
          <div className="font">现金</div>
          <div className="money">
            {payNumber?.refundPaymentDetail[0]?.paymentAmount}
          </div>
          <div className="font">元</div>
        </div>
      </Modal>
    </div>
  );
}

const IndexScreen = (props) => {
  return (
    <Screen>
      <Index {...props} />
    </Screen>
  );
};

export default IndexScreen;
