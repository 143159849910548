import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageController_getCandicateDrugPharmacyList_3ef044: {
    method: 'post',
    url: '/api/drug-storage/get-candicate-drug-pharmacy-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_getSubStorageListByDrugStorageId_8b4549: {
    method: 'post',
    url: '/api/drug-storage/get-sub-storage-list-by-drug-storage-id',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SubDrugStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStocktakingController_createDrugStocktakingClass_bfbdee: {
    method: 'post',
    url: '/api/drug-storage/create-drug-stocktaking-class',
    parameterFomatter: (data?: {
      createBtoList: CreateBaseCodeBtoBaseDictionaryServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_getDrugStorageList_ec7299: {
    method: 'post',
    url: '/api/drug-storage/get-drug-storage-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_deleteDrugPharmacy_f42203: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-pharmacy',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugPharmacyBtoDrugStorageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_getDrugPharmacyListByDrugStorageId_c0c9d4: {
    method: 'post',
    url: '/api/drug-storage/get-drug-pharmacy-list-by-drug-storage-id',
    parameterFomatter: (data?: { drugStorageId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_createOrUpdateCustomizeImportExport_b6ee0f: {
    method: 'post',
    url: '/api/drug-storage/create-or-update-customize-import-export',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateCustomizeImportExportBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_getSubDrugStorageListByDrugStorageId_f79b0b: {
    method: 'post',
    url: '/api/drug-storage/get-sub-storage-by-drug-storage-id',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SubDrugStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  DrugImportExportController_getCandidateDepartmentList_fc3671: {
    method: 'post',
    url: '/api/drug-storage/get-candidate-department-list',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: DepartmentDtoOrganizationManagerDto[]) =>
      data,
  },
  DrugStocktakingController_updateDrugStocktakingClass_85e160: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stocktaking-class',
    parameterFomatter: (data?: {
      updateBtoList: UpdateBaseCodeBtoBaseDictionaryServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_deleteSubDrugStorage_9e49ce: {
    method: 'post',
    url: '/api/drug-storage/delete-sub-drug-storage',
    parameterFomatter: (data?: {
      bto: DeleteSubStorageBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_deleteImportExportClass_3f381f: {
    method: 'post',
    url: '/api/drug-storage/delete-import-export-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_createOrUpdateSubDrugStorage_73ec27: {
    method: 'post',
    url: '/api/drug-storage/create-or-update-sub-drug-storage',
    parameterFomatter: (data?: {
      btoList: UpdateSubStorageBtoDrugStorageServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_invalidDrugImportRecord_98e5a2: {
    method: 'post',
    url: '/api/drug-storage/invalid-drug-import-record',
    parameterFomatter: (data?: {
      bto: InvalidActiveDrugImportMasterRecordBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugImportExportController_deleteCustomizeImportExport_71acdd: {
    method: 'post',
    url: '/api/drug-storage/delete-customize-import-export',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStocktakingController_getDrugStocktakingClassList_28161f: {
    method: 'post',
    url: '/api/drug-storage/get-drug-stocktaking-class-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  DrugStocktakingController_deleteDrugStocktakingClass_e40876: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-stocktaking-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageController_createOrUpdateDrugPharmacyList_ae7231: {
    method: 'post',
    url: '/api/drug-storage/create-drug-pharmacy-list',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugPharmacyBtoDrugStorageServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugStorageVsPharmacyListVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  BaseCommonController_existDiagnosisCode_37e076: {
    method: 'post',
    url: '/api/base-common/exist-diagnosis-code',
    parameterFomatter: (data?: {
      qto: IsUniqueDiagnosisQtoSimpleBaseCommonPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  BaseCommonController_importExcel_c025d5: {
    method: 'post',
    url: '/api/base-common/import-diagnosis-dictionarys',
    parameterFomatter: (data?: {
      items: CreateDiagnosisDictionaryBtoBaseCommonServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryErrorMessageVoBaseCommonEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseCommonController_existDiagnosisName_0b4f64: {
    method: 'post',
    url: '/api/base-common/exist-diagnosis-name',
    parameterFomatter: (data?: {
      qto: IsUniqueDiagnosisQtoSimpleBaseCommonPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  BaseCommonController_getDiagnosisDictionaryById_7224c5: {
    method: 'post',
    url: '/api/base-common/get-diagnosis-dictionary-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DiagnosisDictionaryVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo,
    ) => data,
  },
  BaseCommonController_stopDiagnosisDictionary_4a19d2: {
    method: 'post',
    url: '/api/base-common/stop-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: StopDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_updateDiagnosisDictionary_6261de: {
    method: 'post',
    url: '/api/base-common/update-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_getAllDiagnosisDictionaryForExcel_65391c: {
    method: 'post',
    url: '/api/base-common/get-all-diagnosis-dictionary-for-excel',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DiagnosisDictionaryDtoBaseCommonManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  BaseCommonController_createDiagnosisDictionary_6da403: {
    method: 'post',
    url: '/api/base-common/create-diagnosis-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateDiagnosisDictionaryBtoBaseCommonServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  BaseCommonController_getByCategory_1fe731: {
    method: 'post',
    url: '/api/base-common/get-icon-list-by-category',
    parameterFomatter: (data?: { category: IconCategoryEnum }) => data,
    responseFormatter: (
      _,
      __,
      data?: IconRepositoryDtoBaseCommonManagerDto[],
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de: {
    method: 'post',
    url: '/api/organization/get-all-by-department-in-current-institution',
    parameterFomatter: (data?: {
      qto: DepartmentInstitutionNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentBOController_deleteDepartment_f91e76: {
    method: 'post',
    url: '/api/organization/deleteDepartment',
    parameterFomatter: (data?: {
      btoParam: DeleteDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DepartmentDtoController_getAllWardByDepartmentId_f4a99a: {
    method: 'post',
    url: '/api/organization/get-all-ward-by-department-id',
    parameterFomatter: (data?: { departmentId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo,
    ) => data,
  },
  MedicalInstitutionBOController_createInstitution_c79457: {
    method: 'post',
    url: '/api/organization/create-institution',
    parameterFomatter: (data?: {
      btoParam: CreateInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo,
    ) => data,
  },
  DepartmentDtoController_getById_3da0f7: {
    method: 'post',
    url: '/api/organization/getById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9: {
    method: 'post',
    url: '/api/organization/get-all-by-department-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentDtoController_getAll_d6e9c4: {
    method: 'post',
    url: '/api/organization/getAll',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  MedicalInstitutionDtoController_getAllByInstitutionQto_926a63: {
    method: 'post',
    url: '/api/organization/get-all-by-institution-qto',
    parameterFomatter: (data?: { qto: InstitutionQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo[],
    ) => data,
  },
  MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a: {
    method: 'post',
    url: '/api/organization/get-current-institution-with-department',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionWithDepartmentVoOrganizationEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentQto_1c2a91: {
    method: 'post',
    url: '/api/organization/get-all-by-department-qto',
    parameterFomatter: (data?: { qto: DepartmentQtoOrganizationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  MedicalInstitutionBOController_deleteInstitution_af4371: {
    method: 'post',
    url: '/api/organization/delete-institution',
    parameterFomatter: (data?: {
      btoParam: DeleteInstitutionBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrganizationController_getAllByDepartmentBranchInstitutionQto_4fb0e4: {
    method: 'post',
    url: '/api/organization/get-all-by-department-branch-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentBranchInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  DepartmentBOController_updateDepartment_da3dee: {
    method: 'post',
    url: '/api/organization/updateDepartment',
    parameterFomatter: (data?: {
      btoParam: UpdateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  MedicalInstitutionDtoController_getInstitutionById_5594f2: {
    method: 'post',
    url: '/api/organization/get-institution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo,
    ) => data,
  },
  DepartmentBOController_createDepartment_af7d4c: {
    method: 'post',
    url: '/api/organization/createDepartment',
    parameterFomatter: (data?: {
      btoParam: CreateDepartmentBtoOrganizationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: DepartmentVoOrganizationEntranceWebVo) =>
      data,
  },
  OutpRegisterController_getTodayClinicRegister_c256f2: {
    method: 'post',
    url: '/api/outp-register/get-today-clinic-register',
    parameterFomatter: (data?: { showDeptAll: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: TodayClinicRegisterVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
  OutpRegisterController_deleteClinicRegisterSchedule_4a853c: {
    method: 'post',
    url: '/api/outp-register/delete-clinic-register-schedule',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterScheduleBtoOutpRegisterServiceBto;
      extApiParam: {
        endDate: number;
      };
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterController_createUpdateClinicRegisterSchedule_e56057: {
    method: 'post',
    url: '/api/outp-register/create-update-clinic-register-schedule',
    parameterFomatter: (data?: {
      btoParamList: CreateUpdateClinicRegisterScheduleBtoOutpRegisterServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterController_getClinicRegisterScheduleWithDetailById_ab9458: {
    method: 'post',
    url: '/api/outp-register/get-clinic-register-schedule-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterScheduleWithDetailVoOutpRegisterEntranceWebVo,
    ) => data,
  },
  OutpRegisterController_getTodayClinicDetailForRegister_7896c6: {
    method: 'post',
    url: '/api/outp-register/get-today-clinic-detail-for-register',
    parameterFomatter: (data?: { searchCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: TodayClinicForRegisterVoOutpRegisterEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByOutpSpecialClinicDictionaryQto_3cc0c5: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-outp-special-clinic-dictionary-qto',
    parameterFomatter: (data?: {
      qto: OutpSpecialClinicDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpSpecialClinicDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByFurloughReasonQto_0cf93d: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-furlough-reason-qto',
    parameterFomatter: (data?: {
      qto: FurloughReasonQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FurloughReasonVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_addUpdateFurloughReason_29a627: {
    method: 'post',
    url: '/api/outp-register-define/add-update-furlough-reason',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateFurloughReasonBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_getAllByClinicWaitRegisterTimeDescriptionQto_919fa3:
    {
      method: 'post',
      url: '/api/outp-register-define/get-all-by-clinic-wait-register-time-description-qto',
      parameterFomatter: (data?: {
        qto: ClinicWaitRegisterTimeDescriptionQtoOutpRegisterDefinePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicWaitRegisterTimeDescriptionVoOutpRegisterDefineEntranceWebVo[],
      ) => data,
    },
  OutpRegisterDefineController_deleteIdentificationClassDictionary_e88c28: {
    method: 'post',
    url: '/api/outp-register-define/delete-identification-class-dictionary',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_getRegisterDefineById_e6faa3: {
    method: 'post',
    url: '/api/outp-register-define/get-clinic-register-define-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo,
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterPriceItem_5c1f6c: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-price-item',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterPriceItemBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterType_dc5486: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-type',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterTypeBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateClinicRegisterPriceItem_705179: {
    method: 'post',
    url: '/api/outp-register-define/add-update-clinic-register-price-item',
    parameterFomatter: (data?: {
      btoParam: AddUpdateClinicRegisterPriceItemBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateClinicRegisterType_9b25f7: {
    method: 'post',
    url: '/api/outp-register-define/add-update-clinic-register-type',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateClinicRegisterTypeBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterDefine_58468d: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo,
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-type-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterTypeDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getPriceItemByClinicRegisterTypeId_daf5db: {
    method: 'post',
    url: '/api/outp-register-define/get-price-item-by-clinic-register-type-id',
    parameterFomatter: (data?: { clinicRegisterTypeId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterPriceItemVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicWaitTimeDescription_0d9913: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-wait-time-description',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicWaitTimeDescriptionBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicWaitRegisterTimeDescriptionVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_deleteOutpSpecialClinic_db4f60: {
    method: 'post',
    url: '/api/outp-register-define/delete-outp-special-clinic',
    parameterFomatter: (data?: {
      btoParam: DeleteOutpSpecialClinicBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpSpecialClinicDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_createClinicRegisterDefine_54ab7e: {
    method: 'post',
    url: '/api/outp-register-define/create-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: CreateClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo,
    ) => data,
  },
  OutpRegisterDefineController_deleteFurloughReason_361495: {
    method: 'post',
    url: '/api/outp-register-define/delete-furlough-reason',
    parameterFomatter: (data?: {
      btoParam: DeleteFurloughReasonBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterDefineQto_04b11a: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-define-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterDefineQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo[],
    ) => data,
  },
  OutpRegisterDefineController_addUpdateOutpSpecialClinic_798b56: {
    method: 'post',
    url: '/api/outp-register-define/add-update-outp-special-clinic',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateOutpSpecialClinicBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_updateClinicRegisterDefine_7ab8b4: {
    method: 'post',
    url: '/api/outp-register-define/update-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoClinicOutpVisitEntranceWebVoOutpvisitencounterqueryvo,
    ) => data,
  },
  OutpRegisterDefineController_addUpdateClinicWaitTimeDescription_7c8040: {
    method: 'post',
    url: '/api/outp-register-define/add-update-clinic-wait-time-description',
    parameterFomatter: (data?: {
      btoParamList: AddUpdateClinicWaitTimeDescriptionBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterDefineWithScheduleQto_ca0524:
    {
      method: 'post',
      url: '/api/outp-register-define/get-all-by-clinic-register-define-with-schedule-qto',
      parameterFomatter: (data?: {
        qto: ClinicRegisterDefineWithScheduleQtoOutpRegisterDefinePersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRegisterDefineWithRegisterScheduleVoOutpRegisterDefineEntranceWebVo[],
      ) => data,
    },
  OutpRegisterDefineController_getAllByPlatformDictionaryQto_6b944a: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-platform-dictionary-qto',
    parameterFomatter: (data?: {
      qto: PlatformDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlatformDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_deletePlatformDictionary_16247a: {
    method: 'post',
    url: '/api/outp-register-define/delete-platform-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeletePlatformDictionaryBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PlatformDictionaryVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d: {
    method: 'post',
    url: '/api/outp-register-define/get-all-identification-class-dictionary',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: IdentificationClassDictionaryEoOutpRegisterDefinePersistEo[],
    ) => data,
  },
  OutpRegisterDefineController_addUpdateIdentificationClassDictionary_70e37e: {
    method: 'post',
    url: '/api/outp-register-define/add-update-identification-class-dictionary',
    parameterFomatter: (data?: {
      btoParamsList: IdentificationClassDictionaryEoOutpRegisterDefinePersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpRegisterDefineController_addUpdatePlatformDictionary_f592fa: {
    method: 'post',
    url: '/api/outp-register-define/add-update-platform-dictionary',
    parameterFomatter: (data?: {
      btoParamList: AddUpdatePlatformDictionaryBtoOutpRegisterDefineServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ApplicationBOController_createApplication_c83033: {
    method: 'post',
    url: '/api/application/createApplication',
    parameterFomatter: (data?: {
      btoParam: CreateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationFunctionGroupDtoController_getFunctionGroupByApplicationId_ddcfcd:
    {
      method: 'post',
      url: '/api/application/get-function-group-by-application-id',
      parameterFomatter: (data?: { applicationId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationFunctionGroupWithFunctionVoApplicationEntranceWebVo[],
      ) => data,
    },
  ApplicationBOController_createFunctionGroup_b538da: {
    method: 'post',
    url: '/api/application/create-function-group',
    parameterFomatter: (data?: {
      btoParam: CreateFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
      btoList: FunctionGroupWithRoleEoApplicationPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationDtoController_getApplicationWithRoleById_cb1f5b: {
    method: 'post',
    url: '/api/application/get-application-with-role-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationWithRoleVoApplicationEntranceWebVo,
    ) => data,
  },
  ApplicationBOController_updateFunctionGroup_1f6261: {
    method: 'post',
    url: '/api/application/update-function-group',
    parameterFomatter: (data?: {
      btoParam: UpdateFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
      btoList: FunctionGroupWithRoleEoApplicationPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationVsLocalParameterDtoController_getLocalParameterByApplicationId_a270e2:
    {
      method: 'post',
      url: '/api/application/get-local-parameter-by-application-id',
      parameterFomatter: (data?: { applicationId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationLocalParameterVoApplicationEntranceWebVo[],
      ) => data,
    },
  ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0: {
    method: 'post',
    url: '/api/application/get-sys-config-base-code-without-root',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  ApplicationDtoController_getAllByApplicationQto_f4e56e: {
    method: 'post',
    url: '/api/application/get-all-by-application-qto',
    parameterFomatter: (data?: { qto: ApplicationQtoApplicationPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVoApplicationEntranceWebVo[],
    ) => data,
  },
  ApplicationBOController_updateApplication_62596e: {
    method: 'post',
    url: '/api/application/updateApplication',
    parameterFomatter: (data?: {
      btoParam: UpdateApplicationBtoApplicationServiceBto;
      extApiParam: any;
      roleList: CreateApplicationRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationFunctionGroupDtoController_getFunctionGroupById_b79d49: {
    method: 'post',
    url: '/api/application/get-function-group-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationFunctionGroupVoApplicationEntranceWebVo,
    ) => data,
  },
  ApplicationBOController_updateParameterVsApplication_7e4468: {
    method: 'post',
    url: '/api/application/update-parameter-vs-application',
    parameterFomatter: (data?: {
      btoParam: UpdateParameterVsApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_deleteApplication_cc9a1c: {
    method: 'post',
    url: '/api/application/deleteApplication',
    parameterFomatter: (data?: {
      btoParam: DeleteApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ApplicationDtoController_getApplicationById_1c4e15: {
    method: 'post',
    url: '/api/application/getApplicationById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto) =>
      data,
  },
  ApplicationVsLocalParameterDtoController_getApplicationVsLocalParameterById_bbd9aa:
    {
      method: 'post',
      url: '/api/application/get-application-vs-local-parameter-by-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationLocalParameterVoApplicationEntranceWebVo,
      ) => data,
    },
  ApplicationFunctionGroupWithFunctionDtoController_getGroupWithFunctionById_7e6558:
    {
      method: 'post',
      url: '/api/application/get-group-wiith-function-by-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ApplicationFunctionGroupWithFunctionVoApplicationEntranceWebVo,
      ) => data,
    },
  ApplicationBOController_deleteParameterFromApplication_c87cde: {
    method: 'post',
    url: '/api/application/delete-parameter-from-application',
    parameterFomatter: (data?: {
      btoParam: DeleteParameterFromApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_addParameterToApplication_9c766b: {
    method: 'post',
    url: '/api/application/add-parameter-to-application',
    parameterFomatter: (data?: {
      btoParam: AddParameterToApplicationBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationVsLocalParameterDtoController_getLocalParameterGroupList_9dd4c3: {
    method: 'post',
    url: '/api/application/get-local-parameter-group-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationLocalParameterGroupVoApplicationEntranceWebVo,
    ) => data,
  },
  ApplicationBOController_deleteFunctionGroup_e56168: {
    method: 'post',
    url: '/api/application/delete-function-group',
    parameterFomatter: (data?: {
      btoParam: DeleteFunctionGroupBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationBOController_addFunctionToGroup_e926f2: {
    method: 'post',
    url: '/api/application/add-function-to-group',
    parameterFomatter: (data?: {
      btoParam: AddFunctionToGroupBtoApplicationServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ApplicationVoApplicationEntranceWebVo) =>
      data,
  },
  ApplicationDtoController_getByApplicationClass_ac6d7e: {
    method: 'post',
    url: '/api/application/getByApplicationClass',
    parameterFomatter: (data?: { applicationClass: string }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto[]) =>
      data,
  },
  ApplicationDtoController_getApplication_fbf818: {
    method: 'post',
    url: '/api/application/get-application',
    parameterFomatter: (data?: { template: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVoApplicationEntranceWebVo[],
    ) => data,
  },
  TreatController_getAllByTreatClassQto_9c1625: {
    method: 'post',
    url: '/api/treat/get-all-by-treat-class-qto',
    parameterFomatter: (data?: { qto: TreatClassQtoTreatPersistQto }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo[]) =>
      data,
  },
  TreatController_getTreatClassWithDepartmentById_6f9190: {
    method: 'post',
    url: '/api/treat/get-treat-class-with-department-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClassWithDepartmentVoTreatEntranceWebVo,
    ) => data,
  },
  TreatController_updateTreatClassDictionary_c37450: {
    method: 'post',
    url: '/api/treat/update-treat-class-dictionary',
    parameterFomatter: (data?: {
      btoParam: UpdateTreatClassDictionaryBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo) =>
      data,
  },
  TreatController_getTreatClassWithInstitutionById_552f69: {
    method: 'post',
    url: '/api/treat/get-treat-class-with-instution-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClassWithInstitutionVoTreatEntranceWebVo,
    ) => data,
  },
  TreatController_getDepartmentByTreatItemId_e29acb: {
    method: 'post',
    url: '/api/treat/get-department-by-treat-item-id',
    parameterFomatter: (data?: { treatItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatItemVsDepartmentVoClinicItemBaseEntranceWebVoTreatclinicitemdetailvo[],
    ) => data,
  },
  TreatController_addDepartmentToTreatClass_fb220e: {
    method: 'post',
    url: '/api/treat/add-department-to-treat-class',
    parameterFomatter: (data?: {
      btoParam: AddDepartmentToTreatClassBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClassWithDepartmentVoTreatEntranceWebVo,
    ) => data,
  },
  TreatController_stopTreatClass_476e69: {
    method: 'post',
    url: '/api/treat/stop-treat-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TreatController_createTreatClassDictionary_f6601d: {
    method: 'post',
    url: '/api/treat/create-treat-class-dictionary',
    parameterFomatter: (data?: {
      btoParam: CreateTreatClassDictionaryBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: TreatClassListVoTreatEntranceWebVo) =>
      data,
  },
  DrugController_createDrugAll_cc9730: {
    method: 'post',
    url: '/api/drug-drug/create-drug-all',
    parameterFomatter: (data?: {
      btoParam: CreateDrugDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugFirmDictionaryBOController_createDrugSupplier_139dad: {
    method: 'post',
    url: '/api/drug-drug/create-drug-supplier',
    parameterFomatter: (data?: {
      createDrugFirmDictionaryBto: CreateDrugFirmDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_deleteDrugProduct_8ced4d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugProductBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_getDrugNameDictionaryListByDrugId_aa03ab: {
    method: 'post',
    url: '/api/drug-drug/get-drug-name-dictionary-list-by-durg-id',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugNameDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_getDrugExtensionByDrugId_e6769c: {
    method: 'post',
    url: '/api/drug-drug/get-durg-extension-by-drug-id',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugDictionaryExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_deleteDrugProductUsage_2c8f6a: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-product-usage',
    parameterFomatter: (data?: {
      bto: DeleteDrugProductUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_updateDrugProductExtension_0df600: {
    method: 'post',
    url: '/api/drug-drug/update-drug-product-extension',
    parameterFomatter: (data?: {
      btoParam: UpdateDrugProductExtensionBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_getDrugListForImportStorageDetail_e9c707: {
    method: 'post',
    url: '/api/drug-drug/get-drug-list-for-import-storage-detail',
    parameterFomatter: (data?: { name: string }) => data,
    responseFormatter: (_, __, data?: DrugForDrugImportEoDrugDrugPersistEo[]) =>
      data,
  },
  DrugController_getDrugExtentionByDrugId_08e4d1: {
    method: 'post',
    url: '/api/drug-drug/get-drug-extension-by-drug-id',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugDictionaryExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_createOrUpdateDrugAll_da9cc0: {
    method: 'post',
    url: '/api/drug-drug/create-or-update-drug-all',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateDrugDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugFirmDictionaryBOController_deleteDrugFirmDictionary_07a09d: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-firm-dictionary',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugFirmDictionaryBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_updateDrugAll_231a53: {
    method: 'post',
    url: '/api/drug-drug/update-drug-all',
    parameterFomatter: (data?: {
      btoParam: UpdateDrugDictionaryBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_queryDrugNameDictionary_32035a: {
    method: 'post',
    url: '/api/drug-drug/query-drug-name-dictionary',
    parameterFomatter: (data?: { input: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugNameDictionaryWithDrugDictionaryDtoDrugDrugManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugController_getPriceItemListByDrugProductId_a88fc3: {
    method: 'post',
    url: '/api/drug-drug/get-price-item-list-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (_, __, data?: DrugPriceItemVoDrugDrugEntranceWebVo[]) =>
      data,
  },
  DrugFirmDictionaryBOController_createOrUpdateDrugFirmList_4a4641: {
    method: 'post',
    url: '/api/drug-drug/create-or-update-drug-firm-list',
    parameterFomatter: (data?: {
      createBtoList: CreateDrugFirmDictionaryBtoDrugDrugServiceBto[];
      updateBtoList: UpdateDrugFirmDictionaryBtoDrugDrugServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_getProductUsageListByDrugProductId_82d6a2: {
    method: 'post',
    url: '/api/drug-drug/get-product-usage-list-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductUsageWithProductVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_updateOrCreateDrugUsageByProductId_48b45e: {
    method: 'post',
    url: '/api/drug-drug/update-or-create-drug-usage-by-product-id',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugProductUsageBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugFirmDictionaryBOController_updateDrugSupplier_b976fe: {
    method: 'post',
    url: '/api/drug-drug/update-drug-supplier',
    parameterFomatter: (data?: {
      updateDrugFirmDictionaryBto: UpdateDrugFirmDictionaryBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugFirmDictionaryDtoController_queryDrugSupplierDictionary_be8bc0: {
    method: 'post',
    url: '/api/drug-drug/query-drug-supplier-dictionary-list',
    parameterFomatter: (data?: {
      qto: DrugFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_getDrugPorductExtensionByDrugProductId_e17bca: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-extension-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductExtensionVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugFirmDictionaryDtoController_getFirmDirectoryById_789fef: {
    method: 'post',
    url: '/api/drug-drug/get-firm-directory-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo,
    ) => data,
  },
  DrugController_getDrugProductDepartmentUsageByDrugProductId_cdc3d7: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-department-usage-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductDepartmentUsageVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_addOrUpdateDrugNameAlias_b2a389: {
    method: 'post',
    url: '/api/drug-drug/add-or-update-drug-name-alias',
    parameterFomatter: (data?: {
      bto: CreateOrUpdateDrugNameAliasBtoDrugDrugServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugController_deleteDrugNameAlias_548a12: {
    method: 'post',
    url: '/api/drug-drug/delete-drug-name-alias',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugNameAliasBtoDrugDrugServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugFirmDictionaryDtoController_queryDrugFirmDictionary_25e16e: {
    method: 'post',
    url: '/api/drug-drug/query-drug-firm-dictionary',
    parameterFomatter: (data?: {
      qto: DrugFirmDictionaryQtoDrugDrugPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugFirmDictionaryVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  DrugController_getDrugPriceItemByDrugProductId_99c752: {
    method: 'post',
    url: '/api/drug-drug/get-drug-price-item-list-by-drug-product-id',
    parameterFomatter: (data?: { drugProductId: number }) => data,
    responseFormatter: (_, __, data?: DrugPriceItemVoDrugDrugEntranceWebVo[]) =>
      data,
  },
  DrugController_getProductListToEdit_216b79: {
    method: 'post',
    url: '/api/drug-drug/get-drug-product-list-to-edit',
    parameterFomatter: (data?: { drugId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugProductListEditVoDrugDrugEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_getAllByClinicItemExamQto_ec23a4: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-exam-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemExamQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_getAllByClinicItemTreatQto_ebfc19: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-treat-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemTreatQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_createClinicItem_477596: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicItemBaseController_addAndUpdateClinicCharge_14cb6a: {
    method: 'post',
    url: '/api/clinic-item-base/add-and-update-clinic-charge',
    parameterFomatter: (data?: {
      addClinicChargeBtoList: AddClinicChargeBtoClinicItemBaseServiceBto[];
      updateClinicChargeBtoList: UpdateClinicChargeBtoClinicItemBaseServiceBto[];
      removeClinicChargeBtoList: RemoveClinicChargeBtoClinicItemBaseServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getAllByClinicItemLabQto_f468d1: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-lab-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemLabQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_getLabClinicItemDetailById_cae31e: {
    method: 'post',
    url: '/api/clinic-item-base/get-lab-clinic-item-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_getTreatClinicItemDetailById_9d3b21: {
    method: 'post',
    url: '/api/clinic-item-base/get-treat-clinic-item-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TreatClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_createClinicLabItem_7d0d43: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-lab-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      createLabItemBto: CreateLabItemBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicItemBaseController_getAllByClinicItemQto_e2d337: {
    method: 'post',
    url: '/api/clinic-item-base/get-all-by-clinic-item-qto',
    parameterFomatter: (data?: {
      qto: ClinicItemQtoClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDetailVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_updateClinicExamItem_dec283: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-exam-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateExamItemBto: UpdateExamItemBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_updateClinicLabItem_0a1f35: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-lab-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateLabItemBto: UpdateLabItemBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_createClinicExamItem_dce650: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-exam-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      createExamItemBto: CreateExamItemBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicItemBaseController_updateClinicItem_6d8e3f: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getChargeByClinicItemId_03609f: {
    method: 'post',
    url: '/api/clinic-item-base/get-charge-by-clinic-item-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemVsChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_getClinicItemById_97a99d: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_auditClinicItem_a1c916: {
    method: 'post',
    url: '/api/clinic-item-base/audit-clinic-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getExamClinicItemDetailById_f60f06: {
    method: 'post',
    url: '/api/clinic-item-base/get-exam-clinic-item-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClinicItemDetailVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemBaseController_createClinicTreatItem_de96a6: {
    method: 'post',
    url: '/api/clinic-item-base/create-clinic-treat-item',
    parameterFomatter: (data?: {
      btoParam: CreateClinicItemBtoClinicItemBaseServiceBto;
      createTreatItemBto: CreateTreatItemBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ClinicItemBaseController_stopClinicItem_49a98d: {
    method: 'post',
    url: '/api/clinic-item-base/stop-clinic-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicItemBaseController_getClinicItemClassList_4252cc: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-class-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemClassVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemBaseController_updateClinicTreatItem_5f6500: {
    method: 'post',
    url: '/api/clinic-item-base/update-clinic-treat-item',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicItemBtoClinicItemBaseServiceBto;
      updateTreatItemBto: UpdateTreatItemBtoTreatServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListBOController_updatePriceItem_a055ad: {
    method: 'post',
    url: '/api/price-list/update-price-item',
    parameterFomatter: (data?: {
      btoParam: UpdatePriceItemBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  PriceListController_queryCurrentPriceItemByKeyword_658ffe: {
    method: 'post',
    url: '/api/price-list/query-current-price-item-by-keyword',
    parameterFomatter: (data?: { keyword: string }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListBOController_updatePriceItemCategory_f52851: {
    method: 'post',
    url: '/api/price-list/update-price-item-category',
    parameterFomatter: (data?: {
      priceItemCategoryEo: PriceItemCategoryEoPriceListPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListBOController_createPriceItemCategory_337e94: {
    method: 'post',
    url: '/api/price-list/create-price-item-category',
    parameterFomatter: (data?: {
      priceItemCategoryEo: PriceItemCategoryEoPriceListPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListDtoController_queryAllByPriceItemQto_1e3c39: {
    method: 'post',
    url: '/api/price-list/query-all-by-price-item-qto',
    parameterFomatter: (data?: {
      qto: PriceItemQtoSimplePriceListPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemVoPriceListEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PriceListBOController_createPriceItem_f7480e: {
    method: 'post',
    url: '/api/price-list/create-price-item',
    parameterFomatter: (data?: {
      btoParam: CreatePriceItemBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  PriceListBOController_stopPriceItem_66be27: {
    method: 'post',
    url: '/api/price-list/stop-price-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListDtoController_getAllByPriceItemQueryQto_273108: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-item-query-qto',
    parameterFomatter: (data?: { qto: PriceItemQueryQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListController_getAllByPricePatternQto_8ee5de: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-pattern-qto',
    parameterFomatter: (data?: { qto: PricePatternQtoPriceListPersistQto }) =>
      data,
    responseFormatter: (_, __, data?: PricePatternVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListDtoController_getAllByPriceItemReviewQto_39771a: {
    method: 'post',
    url: '/api/price-list/get-all-by-price-item-review-qto',
    parameterFomatter: (data?: {
      qto: PriceItemReviewQtoPriceListPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo[]) =>
      data,
  },
  PriceListBOController_updatePriceItemCategoryStatus_2942fa: {
    method: 'post',
    url: '/api/price-list/update-price-item-category-status',
    parameterFomatter: (data?: { id: number; stop: boolean }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListController_createPricePatternMaster_f783fc: {
    method: 'post',
    url: '/api/price-list/create-price-pattern-master',
    parameterFomatter: (data?: {
      btoParam: CreatePricePatternMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListController_updatePricePatternMaster_321135: {
    method: 'post',
    url: '/api/price-list/update-price-pattern-master',
    parameterFomatter: (data?: {
      btoParam: UpdatePricePatternMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListBOController_startPriceItem_3cfa75: {
    method: 'post',
    url: '/api/price-list/start-price-item',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListBOController_reviewPriceItem_f0247d: {
    method: 'post',
    url: '/api/price-list/review-price-item',
    parameterFomatter: (data?: {
      btoParamList: ReviewPriceItemBtoPriceListServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListDtoController_getPriceItemById_c2c8d8: {
    method: 'post',
    url: '/api/price-list/get-price-item-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PriceItemVoPriceListEntranceWebVo) =>
      data,
  },
  PriceListController_deletePricePatternMaster_9c4aa9: {
    method: 'post',
    url: '/api/price-list/delete-price-pattern-master',
    parameterFomatter: (data?: {
      btoParam: DeletePricePatternMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PriceListController_getPricePatternMasterDetailById_9a4b90: {
    method: 'post',
    url: '/api/price-list/get-price-pattern-master-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListController_removePriceDetailFromMaster_040237: {
    method: 'post',
    url: '/api/price-list/remove-price-detail-from-master',
    parameterFomatter: (data?: {
      btoParam: RemovePriceDetailFromMasterBtoPriceListServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternMasterWithDetailVoPriceListEntranceWebVo,
    ) => data,
  },
  PriceListDtoController_getPriceItemCategory_77dde8: {
    method: 'post',
    url: '/api/price-list/get-price-item-category',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PriceItemCategoryEoPriceListPersistEo) =>
      data,
  },
  ExamClassDictionaryDtoController_getExamClassWithDepartmentById_9db767: {
    method: 'post',
    url: '/api/exam/get-exam-class-with-department-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithDepartmentVoExamEntranceWebVo,
    ) => data,
  },
  ExamMethodDictionaryDtoController_getByExamClassId_994cf6: {
    method: 'post',
    url: '/api/exam/get-method-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamMethodVoExamEntranceWebVo[]) => data,
  },
  ExamClassDictionaryBOController_createExamClass_2817f0: {
    method: 'post',
    url: '/api/exam/create-exam-class',
    parameterFomatter: (data?: {
      btoParam: CreateExamClassBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo) => data,
  },
  ExamClassDictionaryBOController_addAndUpdateExamMethod_bb5113: {
    method: 'post',
    url: '/api/exam/add-and-update-exam-method',
    parameterFomatter: (data?: {
      addMethodToExamClassBtoList: AddMethodToExamClassBtoExamServiceBto[];
      updateExamMethodBtoList: UpdateExamMethodBtoExamServiceBto[];
      removeMethodFromExamClassBtoList: RemoveMethodFromExamClassBtoExamServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamClassDictionaryBOController_stopExamClass_d5da55: {
    method: 'post',
    url: '/api/exam/stop-exam-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamBodyDictionaryDtoController_getByExamClassId_99e20a: {
    method: 'post',
    url: '/api/exam/get-body-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamBodyVoExamEntranceWebVo[]) => data,
  },
  ExamClassDictionaryBOController_updateExamClass_1907ab: {
    method: 'post',
    url: '/api/exam/update-exam-class',
    parameterFomatter: (data?: {
      btoParam: UpdateExamClassBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo) => data,
  },
  ExamClassDictionaryBOController_addDepartmentToExamClass_e05bca: {
    method: 'post',
    url: '/api/exam/add-department-to-exam-class',
    parameterFomatter: (data?: {
      btoParam: AddDepartmentToExamClassBtoExamServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithDepartmentVoExamEntranceWebVo,
    ) => data,
  },
  ExamController_getExamClassWithBodyMethodById_d56c5a: {
    method: 'post',
    url: '/api/exam/get-exam-class-with-body-method-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExamClassDictionaryWithBodyMethodVoExamEntranceWebVo,
    ) => data,
  },
  ExamClassDictionaryWithInstitutionDtoController_getExamClassWithInstitutionById_acabd3:
    {
      method: 'post',
      url: '/api/exam/get-exam-class-with-instution-by-id',
      parameterFomatter: (data?: { id: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: ExamClassWithInstitutionVoExamEntranceWebVo,
      ) => data,
    },
  ExamClassDictionaryBOController_addAndUpdateExamBody_f95b77: {
    method: 'post',
    url: '/api/exam/add-and-update-exam-body',
    parameterFomatter: (data?: {
      addBodyToExamClassBtoList: AddBodyToExamClassBtoExamServiceBto[];
      updateExamBodyBtoList: UpdateExamBodyBtoExamServiceBto[];
      removeBodyFromExamClassBtoList: RemoveBodyFromExamClassBtoExamServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ExamClassDictionaryDtoController_getAllByExamClassQto_3b08d4: {
    method: 'post',
    url: '/api/exam/get-all-by-exam-class-qto',
    parameterFomatter: (data?: { qto: ExamClassQtoExamPersistQto }) => data,
    responseFormatter: (_, __, data?: ExamClassListVoExamEntranceWebVo[]) =>
      data,
  },
  LocalParameterConfigDtoController_getAllByLocalParameterQto_f27ac9: {
    method: 'post',
    url: '/api/base_parameter/getAllByLocalParameterQto',
    parameterFomatter: (data?: {
      qto: LocalParameterQtoBaseParameterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigVoBaseParameterEntranceWebVo[],
    ) => data,
  },
  LocalParameterConfigDtoController_queryLocalParameter_464399: {
    method: 'post',
    url: '/api/base-parameter/query-local-parameter-by-name-paged',
    parameterFomatter: (data?: {
      qto: LocalParameterNameClassQtoBaseParameterPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: LocalParameterConfigVoBaseParameterEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SystemParameterConfigDtoController_querySystemParameterByName_ee78a5: {
    method: 'post',
    url: '/api/base-parameter/query-system-parameter-by-name-paged',
    parameterFomatter: (data?: {
      qto: SystemParameterNameClassQtoBaseParameterPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SystemParameterConfigDtoBaseParameterManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ParameterConfigCacheController_getSystemParameterByInstitutionIdAndNames_5ea922:
    {
      method: 'post',
      url: '/api/base_parameter/get-system-parameter-by-institution-id-and-names',
      parameterFomatter: (data?: { institutionId: number; names: string[] }) =>
        data,
      responseFormatter: (
        _,
        __,
        data?: SystemParameterConfigVoBaseParameterEntranceWebVo[],
      ) => data,
    },
  SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8: {
    method: 'post',
    url: '/api/base-parameter/get-all-by-system-parameter-qto',
    parameterFomatter: (data?: {
      qto: SystemParameterQtoBaseParameterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigDtoBaseParameterManagerDto[],
    ) => data,
  },
  LocalParameterConfigBOController_deleteLocalParameter_b70c63: {
    method: 'post',
    url: '/api/base_parameter/deleteLocalParameter',
    parameterFomatter: (data?: {
      btoParam: DeleteLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  LocalParameterConfigDtoServiceController_getLocalParameterById_c782e0: {
    method: 'post',
    url: '/api/base_parameter/getLocalParameterById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  LocalParameterConfigBOController_updateLocalParameter_6e3d47: {
    method: 'post',
    url: '/api/base-parameter/update-local-parameter',
    parameterFomatter: (data?: {
      btoParam: UpdateLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigDtoBaseParameterManagerDto,
    ) => data,
  },
  SystemParameterConfigBOController_updateSystemParameter_c23bcd: {
    method: 'post',
    url: '/api/base_parameter/updateSystemParameter',
    parameterFomatter: (data?: {
      btoParam: UpdateSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  SystemParameterConfigBOController_createSystemParameter_704b54: {
    method: 'post',
    url: '/api/base_parameter/createSystemParameter',
    parameterFomatter: (data?: {
      btoParam: CreateSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  LocalParameterConfigBOController_createLocalParameter_a54ed9: {
    method: 'post',
    url: '/api/base-parameter/create-local-parameter',
    parameterFomatter: (data?: {
      btoParam: CreateLocalParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: LocalParameterConfigDtoBaseParameterManagerDto,
    ) => data,
  },
  SystemParameterConfigBOController_deleteSystemParameter_34c82b: {
    method: 'post',
    url: '/api/base_parameter/deleteSystemParameter',
    parameterFomatter: (data?: {
      btoParam: DeleteSystemParameterBtoBaseParameterServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  SystemParameterConfigDtoController_getSystemParameterById_860609: {
    method: 'post',
    url: '/api/base_parameter/getSystemParameterById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: SystemParameterConfigVoBaseParameterEntranceWebVo,
    ) => data,
  },
  LocalParameterConfigDtoController_queryLocalParameterByClass_f5bc2c: {
    method: 'post',
    url: '/api/base-parameter/query-local-parameter-by-class',
    parameterFomatter: (data?: {
      qto: LocalParameterClassQtoBaseParameterPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: LocalParameterConfigDtoBaseParameterManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SystemParameterConfigDtoController_querySystemParameterByClass_e5408e: {
    method: 'post',
    url: '/api/base-parameter/query-system-parameter-by-class',
    parameterFomatter: (data?: {
      qto: SystemParameterClassQtoBaseParameterPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SystemParameterConfigDtoBaseParameterManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  LabController_getAllByLabClassQto_a6f1c8: {
    method: 'post',
    url: '/api/lab/get-all-by-lab-class-qto',
    parameterFomatter: (data?: { qto: LabClassQtoLabPersistQto }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo[]) => data,
  },
  LabController_getLabWithDepartmentById_4053dd: {
    method: 'post',
    url: '/api/lab/get-lab-with-department-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClassDictionaryWithDepartmentVoLabEntranceWebVo,
    ) => data,
  },
  LabController_createLabClass_07c440: {
    method: 'post',
    url: '/api/lab/create-lab-class',
    parameterFomatter: (data?: {
      btoParam: CreateLabClassBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo) => data,
  },
  LabController_updateLabClass_2620ef: {
    method: 'post',
    url: '/api/lab/update-lab-class',
    parameterFomatter: (data?: {
      btoParam: UpdateLabClassBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo) => data,
  },
  LabController_addDepartmentToLabClass_8b9e30: {
    method: 'post',
    url: '/api/lab/add-department-to-lab-class',
    parameterFomatter: (data?: {
      btoParam: AddDepartmentToLabClassBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo) => data,
  },
  LabController_stopLabClass_6f16ed: {
    method: 'post',
    url: '/api/lab/stop-lab-class',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  LabController_getLabClassById_f20312: {
    method: 'post',
    url: '/api/lab/get-lab-class-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo) => data,
  },
  BaseDictionaryController_deleteBaseCode_e64f94: {
    method: 'post',
    url: '/api/base-dictionary/delete-base-code',
    parameterFomatter: (data?: {
      btoParam: DeleteBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_createUpdateBaseCodeType_f84265: {
    method: 'post',
    url: '/api/base-dictionary/create-update-base-code-type',
    parameterFomatter: (data?: {
      btoParam: CreateUpdateBaseCodeTypeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_getByTypeId_fed7b5: {
    method: 'post',
    url: '/api/base-dictionary/get-by-type-id',
    parameterFomatter: (data?: { typeId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_getByCodeTypeAndCode_0d9770: {
    method: 'post',
    url: '/api/base-dictionary/get-by-code-type-and-code',
    parameterFomatter: (data?: {
      var: BaseCodeCodeTypeCodeEoBaseDictionaryPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseDictionaryController_updateBaseCodeType_4a1ed5: {
    method: 'post',
    url: '/api/base-dictionary/update-base-code-type',
    parameterFomatter: (data?: {
      btoParam: UpdateBaseCodeTypeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeType_5f9695: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeType',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseCodeDtoServiceController_getByCodeTypeAndParentCode_27dfb1: {
    method: 'post',
    url: '/api/base_dictionary/getByCodeTypeAndParentCode',
    parameterFomatter: (data?: {
      var: BaseCodeCodeTypeParentCodeEoBaseDictionaryPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeDtoBaseDictionaryManagerDto[]) =>
      data,
  },
  BaseDictionaryController_getById_d22746: {
    method: 'post',
    url: '/api/base-dictionary/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo,
    ) => data,
  },
  BaseDictionaryController_updateBaseCode_c565bf: {
    method: 'post',
    url: '/api/base-dictionary/update-base-code',
    parameterFomatter: (data?: {
      btoParam: UpdateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  BaseCodeTypeDtoController_getAllByBaseCodeNameCodeQto_d9e7d9: {
    method: 'post',
    url: '/api/base-dictionary/get-all-by-base-code-name-code-qto',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseDictionaryController_getByCodeType_a0a0ce: {
    method: 'post',
    url: '/api/base-dictionary/get-by-code-type',
    parameterFomatter: (data?: { codeType: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseCodeTypeDtoController_getAllByBaseCodeTypeQto_240b91: {
    method: 'post',
    url: '/api/base-dictionary/get-all-by-base-code-type-qto',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeVoBaseDictionaryEntranceWebVo[],
    ) => data,
  },
  BaseDictionaryController_createBaseCode_0172bf: {
    method: 'post',
    url: '/api/base-dictionary/create-base-code',
    parameterFomatter: (data?: {
      btoParam: CreateBaseCodeBtoBaseDictionaryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: BaseCodeVoBaseDictionaryEntranceWebVo) =>
      data,
  },
  ClinicRecordHistoryController_updatePatientAllergy_260cc6: {
    method: 'post',
    url: '/api/clinic-record-history/update-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: UpdatePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_createPatientAllergy_5b54a1: {
    method: 'post',
    url: '/api/clinic-record-history/create-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: CreatePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  ClinicRecordHistoryController_deletePatientAllergy_625a54: {
    method: 'post',
    url: '/api/clinic-record-history/delete-patient-allergy',
    parameterFomatter: (data?: {
      btoParam: DeletePatientAllergyBtoClinicRecordHistoryServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientAllergyVoClinicRecordHistoryEntranceWebVo,
    ) => data,
  },
  UserGroupController_createMedicalGroup_f4b186: {
    method: 'post',
    url: '/api/user-group/create-medical-group',
    parameterFomatter: (data?: {
      btoParam: CreateMedicalGroupBtoUserGroupServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserGroupController_updateMedicalGroupStopIndicator_019d99: {
    method: 'post',
    url: '/api/user-group/update-medical-group-stop-indicator',
    parameterFomatter: (data?: {
      btoParam: UpdateMedicalGroupStopIndicatorBtoUserGroupServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserGroupController_queryAllByMedicalGroup_169563: {
    method: 'post',
    url: '/api/user-group/query-all-by-medical-group',
    parameterFomatter: (data?: {
      eo: MedicalGroupWithMemberEoUserGroupPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MedicalGroupWithMemberVoUserGroupEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserGroupController_updateMedicalGroup_75af28: {
    method: 'post',
    url: '/api/user-group/update-medical-group',
    parameterFomatter: (data?: {
      btoParam: UpdateMedicalGroupBtoUserGroupServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  UserGroupController_getById_bfaf32: {
    method: 'post',
    url: '/api/user-group/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalGroupWithMemberVoUserGroupEntranceWebVo,
    ) => data,
  },
  OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/query-pagefall-by-fall-outp-visit-encounter-qto',
      parameterFomatter: (data?: {
        qto: FallOutpVisitEncounterQtoClinicOutpVisitPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  DoctorFavoriteDescriptionController_getDoctorFavoriteDescription_9014bf: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-doctor-favorite-description',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DoctorFavoriteDescriptionVoClinicOutpVisitEntranceWebVo[],
    ) => data,
  },
  DoctorFavoriteMedicalRecordController_createDoctorFavoriteMedicalRecord_f15c7f:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/create-doctor-favorite-medical-record',
      parameterFomatter: (data?: {
        btoParam: CreateDoctorFavoriteMedicalRecordBtoClinicOutpVisitServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  OutpVisitEncounterController_visitRefund_5da754: {
    method: 'post',
    url: '/api/clinic-outp-visit/visit-refund',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OutpMedicalRecordController_createOutpMedicalRecord_8ff0f6: {
    method: 'post',
    url: '/api/clinic-outp-visit/create-outp-medical-record',
    parameterFomatter: (data?: {
      btoParam: CreateOutpMedicalRecordBtoClinicOutpVisitServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpVisitController_queryPagefallByFallOutVisitDetailQto_4e5538: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-pagefall-by-fall-out-visit-detail-qto',
    parameterFomatter: (data?: {
      qto: FallOutVisitDetailQtoClinicOutpVisitPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OutpVisitController_queryOutVisitDetailByPatient_3bc295: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-out-visit-detail-by-patient',
    parameterFomatter: (data?: { patientInfo: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitQueryVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DoctorFavoriteMedicalRecordController_deleteDoctorFavoriteMedicalRecord_bc0e79:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/delete-doctor-favorite-medical-record',
      parameterFomatter: (data?: {
        btoParam: DeleteDoctorFavoriteMedicalRecordBtoClinicOutpVisitServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (_, __, data?: boolean) => data,
    },
  DoctorFavoriteMedicalRecordController_getDoctorFavoriteMedicalRecord_8e162b: {
    method: 'post',
    url: '/api/clinic-outp-visit/get-doctor-favorite-medical-record',
    parameterFomatter: (data?: {
      var: DoctorFavoriteMedicalRecordDoctorIdClinicVisitTypeEoClinicOutpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo[],
    ) => data,
  },
  OutpVisitEncounterController_visitEnd_f34b60: {
    method: 'post',
    url: '/api/clinic-outp-visit/visit-end',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DoctorFavoriteDescriptionController_deleteDoctorFavoriteDescription_14100e: {
    method: 'post',
    url: '/api/clinic-outp-visit/delete-doctor-favorite-description',
    parameterFomatter: (data?: {
      btoParam: DeleteDoctorFavoriteDescriptionBtoClinicOutpVisitServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_queryApplicationStaffWithRoleList_8aadd4: {
    method: 'post',
    url: '/api/application-privilege/query-application-staff-with-role-list',
    parameterFomatter: (data?: {
      qto: ApplicationVsStaffQtoApplicationPrivilegePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationVsStaffDetailVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  FunctionController_getByPermissionFunctionList_c8a3dd: {
    method: 'post',
    url: '/api/application-privilege/get-by-permission-function-list',
    parameterFomatter: (data?: { applicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  ApplicationFunctionDtoController_getAllFunctionListWithRole_3d0e3a: {
    method: 'post',
    url: '/api/application-privilege/get-all-function-list-with-role',
    parameterFomatter: (data?: { applicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionWithRoleVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  ApplicationFunctionDtoController_getAllByFunctionQto_233f12: {
    method: 'post',
    url: '/api/application-privilege/get-all-by-function-qto',
    parameterFomatter: (data?: {
      qto: FunctionQtoApplicationPrivilegePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  ApplicationVsRoleDtoController_getById_e96aaf: {
    method: 'post',
    url: '/api/application-privilege/get-role-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleWithPermissionVoApplicationPrivilegeEntranceWebVo,
    ) => data,
  },
  FunctionController_getAssignedRoleListOfApplication_cee617: {
    method: 'post',
    url: '/api/application-privilege/get-assigned-role-list-of-application',
    parameterFomatter: (data?: {
      staffId: number;
      applicationIdList: number[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffRoleDetailVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  ApplicationRoleVsPermissionDtoController_getByApplicationRoleId_25a1f4: {
    method: 'post',
    url: '/api/application-privilege/get-permission-list-by-role',
    parameterFomatter: (data?: { applicationRoleId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleVsPermissionVoApplicationPrivilegeEntranceWebVoApplicationrolewithpermissionvo[],
    ) => data,
  },
  PrivilegeController_searchCandidateStaff_e2616c: {
    method: 'post',
    url: '/api/application-privilege/search-candidate-staff',
    parameterFomatter: (data?: {
      departmentId: number;
      name: string;
      applicationId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDepartmentDtoUserStaffManagerDto[],
    ) => data,
  },
  ApplicationFunctionDtoController_getById_0d38bf: {
    method: 'post',
    url: '/api/application-privilege/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo,
    ) => data,
  },
  PrivilegeController_deleteApplicationStaffWithRole_7de462: {
    method: 'post',
    url: '/api/application-privilege/delete-application-staff-with-role',
    parameterFomatter: (data?: {
      bto: DeleteStaffWithRoleBtoApplicationPrivilegeServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_createOrUpdateApplicationStaffWithRole_0bd2f6: {
    method: 'post',
    url: '/api/application-privilege/create-or-update-application-staff-with-role',
    parameterFomatter: (data?: {
      btoList: CreateStaffWithRoleBtoApplicationPrivilegeServiceBto[];
      staffId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PrivilegeController_getAuthorizedApplicationList_3e355f: {
    method: 'post',
    url: '/api/application-privilege/get-authorized-application-list',
    parameterFomatter: (data?: { loginId: string }) => data,
    responseFormatter: (_, __, data?: ApplicationDtoApplicationManagerDto[]) =>
      data,
  },
  ApplicationVsRoleBOController_updateRolePermission_8badc8: {
    method: 'post',
    url: '/api/application-privilege/update-role-permission',
    parameterFomatter: (data?: {
      btoParam: UpdateRolePermissionBtoApplicationPrivilegeServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  PrivilegeController_getAllRoleListOfStandardApplication_ce4076: {
    method: 'post',
    url: '/api/application-privilege/get-all-role-list-of-standard-application',
    parameterFomatter: (data?: { bizApplicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleDtoApplicationPrivilegeManagerDto[],
    ) => data,
  },
  FunctionController_getByFunctionClassList_20f558: {
    method: 'post',
    url: '/api/application-privilege/get-by-function-class-list',
    parameterFomatter: (data?: { functionClzList: string[] }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  FunctionController_assignRoleListToApplication_0e0c07: {
    method: 'post',
    url: '/api/application-privilege/assign-role-list-to-application',
    parameterFomatter: (data?: {
      roleList: CreateStaffWithRoleBtoApplicationPrivilegeServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ApplicationFunctionBOController_createApplicationFunction_cd0986: {
    method: 'post',
    url: '/api/application-privilege/create-application-function',
    parameterFomatter: (data?: {
      btoParam: CreateApplicationFunctionBtoApplicationPrivilegeServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo,
    ) => data,
  },
  ApplicationVsRoleDtoController_getByApplicationId_4a7f46: {
    method: 'post',
    url: '/api/application-privilege/get-by-application-id',
    parameterFomatter: (data?: { applicationId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ApplicationRoleWithPermissionVoApplicationPrivilegeEntranceWebVo[],
    ) => data,
  },
  ApplicationFunctionBOController_updateApplicationFunction_f37290: {
    method: 'post',
    url: '/api/application-privilege/update-application-function',
    parameterFomatter: (data?: {
      btoParam: UpdateApplicationFunctionBtoApplicationPrivilegeServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: FunctionVoApplicationPrivilegeEntranceWebVo,
    ) => data,
  },
  ApplicationFunctionBOController_deleteFunction_d7c670: {
    method: 'post',
    url: '/api/application-privilege/delete-function',
    parameterFomatter: (data?: {
      btoParam: DeleteFunctionBtoApplicationPrivilegeServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  SpecimenBOController_deleteSpecimen_e2a2f4: {
    method: 'post',
    url: '/api/specimen/delete-specimen',
    parameterFomatter: (data?: {
      btoParam: DeleteSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenController_getAllBySpecimenWithPatternQto_47cbca: {
    method: 'post',
    url: '/api/specimen/get-all-by-specimen-with-pattern-qto',
    parameterFomatter: (data?: {
      qto: SpecimenWithPatternQtoSpecimenPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo[]) =>
      data,
  },
  SpecimenBOController_createSpecimen_f83102: {
    method: 'post',
    url: '/api/specimen/create-specimen',
    parameterFomatter: (data?: {
      btoParam: CreateSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenDtoController_getById_7a0084: {
    method: 'post',
    url: '/api/specimen/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenBOController_updateSpecimen_b99bfd: {
    method: 'post',
    url: '/api/specimen/update-specimen',
    parameterFomatter: (data?: {
      btoParam: UpdateSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  DrugStorageImportExportController_invalidDrugImportRecord_d16f0f: {
    method: 'post',
    url: '/api/drug-storage-import/invalid-drug-import-record',
    parameterFomatter: (data?: {
      bto: InvalidActiveDrugImportMasterRecordBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageImportExportController_getDrugImportRecordToBeAccount_20f690: {
    method: 'post',
    url: '/api/drug-storage-import/get-drug-import-record-to-be-account',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportMasterVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugImportRecordCreateController_deleteDrugStorageImportRecordDetail_534970: {
    method: 'post',
    url: '/api/drug-storage-import/delete-drug-storage-import-record-detail',
    parameterFomatter: (data?: {
      bto: DeleteDrugStorageDetailBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StaffDetailDtoController_getByStaffDepartmentId_69c0a1: {
    method: 'post',
    url: '/api/user-staff/get-by-staff-department-id',
    parameterFomatter: (data?: { staffDepartmentId: number }) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo[]) =>
      data,
  },
  StaffBOController_updateStaff_85164a: {
    method: 'post',
    url: '/api/user-staff/update-staff',
    parameterFomatter: (data?: {
      btoParam: UpdateStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo) =>
      data,
  },
  StaffBOController_createStaff_78ec6d: {
    method: 'post',
    url: '/api/user-staff/create-staff',
    parameterFomatter: (data?: {
      btoParam: CreateStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo) =>
      data,
  },
  StaffBOController_updateDoctorInfo_907e11: {
    method: 'post',
    url: '/api/user-staff/update-doctor-info',
    parameterFomatter: (data?: {
      btoParam: UpdateDoctorInfoBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffVoClinicItemBaseEntranceWebVoExamclinicitemdetailvo,
    ) => data,
  },
  StaffDetailDtoController_getById_6ecdf0: {
    method: 'post',
    url: '/api/user-staff/get-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffVoClinicItemBaseEntranceWebVoExamclinicitemdetailvo,
    ) => data,
  },
  StaffBOController_deleteStaff_87868c: {
    method: 'post',
    url: '/api/user-staff/delete-staff',
    parameterFomatter: (data?: {
      btoParam: DeleteStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  UserStaffController_getDoctorStaffListOfInstitution_ae1040: {
    method: 'post',
    url: '/api/user-staff/get-doctor-staff-list-of-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo[]) =>
      data,
  },
  UserStaffController_queryPagedByStaffSignatureQto_b42a04: {
    method: 'post',
    url: '/api/user-staff/query-paged-by-staff-signature-qto',
    parameterFomatter: (data?: {
      qto: StaffSignatureQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffWithSignatureVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_getPagedByUserStaffQto_c82b82: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-user-staff-qto',
    parameterFomatter: (data?: { qto: UserStaffNewQtoUserStaffPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffSimpleVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserStaffController_getPagedByPageUserStaffByInputCodeQto_207a1d: {
    method: 'post',
    url: '/api/user-staff/get-paged-by-page-user-staff-by-input-code-qto',
    parameterFomatter: (data?: {
      qto: StaffWithDoctorQtoUserStaffPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StaffWithDoctorVoUserStaffEntranceWebVo[],
    ) => data,
  },
  StaffDetailDtoController_queryPaged_39a7f7: {
    method: 'post',
    url: '/api/user-staff/staff-list/query',
    parameterFomatter: (data?: {
      qto: NewQueryStaffListQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffListVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OutpSettleController_preSettle_f20115: {
    method: 'post',
    url: '/api/outp-billing/pre-settle',
    parameterFomatter: (data?: {
      patientId: number;
      selfPaymentIndicator: boolean;
      outpVisitUnsettledRecordVos: OutpVisitUnsettledRecordVoOutpBillingEntranceWebVo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OutpPreSettleVoOutpBillingEntranceWebVo,
    ) => data,
  },
  OutpSettleController_settle_b54c18: {
    method: 'post',
    url: '/api/outp-billing/settle',
    parameterFomatter: (data?: {
      parameterEo: OutpSettleInputParameterEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpBillingController_createOutpSettleMaster_b6ff2f: {
    method: 'post',
    url: '/api/outp-billing/create-outp-settle-master',
    parameterFomatter: (data?: {
      btoParam: CreateOutpSettleMasterBtoOutpBillingServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OutpBillingController_createOutpBillingDetail_ee6542: {
    method: 'post',
    url: '/api/outp-billing/create-outp-billing-detail',
    parameterFomatter: (data?: {
      btoParam: CreateOutpBillingDetailBtoOutpBillingServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  AccountController_resetPassword_68f7e8: {
    method: 'post',
    url: '/api/aspirin-user-account/reset-passowrd',
    parameterFomatter: (data?: { staffId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageImportExportController_getDrugExportDetailListByDrugExportMasterId_30f5f3:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-export-detail-list',
      parameterFomatter: (data?: { drugExportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugExportDetailVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugStorageImportExportController_getDrugStorageImportDetailListForFloat_9a6ac0:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-import-detail-list-for-float',
      parameterFomatter: (data?: { drugImportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugImportDetailListForFloatVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  DrugStorageImportExportController_getDrugExportDetailListForFloat_2691cc: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-drug-export-detail-list-for-float',
    parameterFomatter: (data?: { drugExportMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportDetailForFloatVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugStorageImportExportController_getDrugStorageImportDetailByDrugImportMasterId_0c8007:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/get-drug-import-detail-by-drug-import-master-id',
      parameterFomatter: (data?: { drugImportMasterId: number }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugImportDetailListVoDrugStorageImportExportEntranceWebVo[],
      ) => data,
    },
  ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a: {
    method: 'post',
    url: '/api/clinic-record-order-drug/get-order-drug-and-valid',
    parameterFomatter: (data?: {
      drugItemId: string;
      patientId: number;
      departmentId: string;
      staffId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDrugValidateResultVoClinicRecordOrderDrugEntranceWebVo,
    ) => data,
  },
  ClinicRecordOrderController_deleteOrder_0de5cf: {
    method: 'post',
    url: '/api/clinic-record-order/delete-order',
    parameterFomatter: (data?: { orderId: number; deleteGroup: boolean }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_getByOutpVisitEncounterId_f90c63: {
    method: 'post',
    url: '/api/clinic-record-order/get-by-outp-visit-encounter-id',
    parameterFomatter: (data?: { outpVisitEncounterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_cancelSubmitOrder_28057d: {
    method: 'post',
    url: '/api/clinic-record-order/cancel-submit-order',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicRecordOrderController_submitOrder_89360b: {
    method: 'post',
    url: '/api/clinic-record-order/submit-order',
    parameterFomatter: (data?: {
      order: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderDetailVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  AccountController_staffLogin_ed24bb: {
    method: 'post',
    url: '/api/aspirin-user-account-entrance-web/staff-login',
    parameterFomatter: (data?: { username: string; password: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  BaseDictionaryController_getAll_6ecb98: {
    method: 'post',
    url: '/api/dbenum/codetype/all/list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: BaseCodeTypeDtoBaseDictionaryManagerDto[],
    ) => data,
  },
  ClinicOutpRegisterMainController_register_7c692c: {
    method: 'post',
    url: '/api/clinic-outp-register-main/register',
    parameterFomatter: (data?: {
      outpSettleInputParameterEo: OutpSettleInputParameterEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicOutpRegisterMainController_getRegisterPaymentInfo_8f478e: {
    method: 'post',
    url: '/api/clinic-outp-register-main/get-register-payment-info',
    parameterFomatter: (data?: {
      outpApportionPreSettleEo: OutpApportionPreSettleEoOutpBillingPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterPaymentInfoVoClinicOutpRegisterMainEntranceWebVo,
    ) => data,
  },
});
