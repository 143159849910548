import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSTable } from '@vs/vsf-kit';
import React from 'react';
const PatientIdentifications = (props) => {
  return (
    <Section title="证件信息">
      <VSTable
        editable={{ editType: 'single' }}
        vsid="19672"
        id="vSTablePatientIdentification"
      >
        <VSTableColumn
          dataIndex={['identificationClass']}
          title="身份识别类型"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.IDENTIFICATION_CLASS_DICT}
          fieldProps={{}}
          formItemProps={{}}
        />

        <VSTableColumn
          dataIndex={['identificationNumber']}
          title="身份识别号码"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '身份识别号码长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          fieldProps={{}}
        />
      </VSTable>
    </Section>
  );
};
export default definePage(PatientIdentifications);
