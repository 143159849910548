import './index.less';

import { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect } from 'react';

import InInfo from '@/pages/Index/cpoe/center/header/Info/in_info';
import OutInfo from '@/pages/Index/cpoe/center/header/Info/out_info';
const Index = (props) => {
  /** 患者信息 */
  const {
    getOutVisitTreatInfo,
    getListPatientLabelConfig,
    staffInfo,
    setOutVisitInfo,
    setCurrentPatient,
    setPatientLabelConfig,
    setHospitalType,
    getInVisitTreatInfo,
    setInVisitInfo,
    setInVisitTreatInfo,
    setOutRegisterInfo,
  } = props?.stores?.user;
  // 门诊
  const setOutUserInfo = useCallback(
    async (params: any) => {
      if (!params) {
        setOutVisitInfo();
        setCurrentPatient();
        setPatientLabelConfig();
        setOutRegisterInfo();
        return;
      }
      const TreatInfo = await getOutVisitTreatInfo(params?.infoParam),
        Info = TreatInfo
          ? Object.assign({}, TreatInfo, {
              id: TreatInfo?.outpVisitVo?.id,
            })
          : undefined,
        CurrentPatient = Info
          ? Object.assign(
              {},
              {
                id: Info?.id,
                patientId: Info?.outpVisitVo?.patientId,
                displayId: Info?.patientVo?.displayId,
              },
            )
          : undefined;
      setOutVisitInfo(Info);
      setOutRegisterInfo(Info?.outpVisitVo);
      setCurrentPatient(CurrentPatient);
      Info && getListPatientLabelConfig(params?.configParam);
    },
    [
      getListPatientLabelConfig,
      getOutVisitTreatInfo,
      setCurrentPatient,
      setOutVisitInfo,
      setPatientLabelConfig,
      setOutRegisterInfo,
    ],
  );
  // 住院
  const setInpUserInfo = useCallback(
    async (params: any) => {
      if (!params) {
        setInVisitInfo();
        setInVisitTreatInfo();
        setHospitalType('out');
        return;
      }
      const ele__hospitalType_dropdown = document.querySelector(
        '.in_hospitalType_dropdown',
      );
      const InVisitTreatInfo = await getInVisitTreatInfo(params);

      setInVisitInfo(InVisitTreatInfo?.result?.[0]?.clinicPatientBedDto);

      setHospitalType('in');
      ele__hospitalType_dropdown && ele__hospitalType_dropdown?.remove();
    },
    [setHospitalType, getInVisitTreatInfo, setInVisitInfo, setInVisitTreatInfo],
  );
  useEffect(() => {
    // 门诊
    if (props?.outpVisitId) {
      setOutUserInfo(false);
      setOutUserInfo({
        infoParam: props?.outpVisitId,
        configParam: {
          staffId: staffInfo?.doctorDto?.staffId,
        },
      });
    }
    // 住院
    if (props?.inpVisitId) {
      setInpUserInfo(props?.inpVisitId);
    }
    return () => {
      setOutUserInfo(false);
      setInpUserInfo(false);
    };
  }, [
    props?.inpVisitId,
    props?.outpVisitId,
    setOutUserInfo,
    setInpUserInfo,
    staffInfo?.doctorDto?.staffId,
  ]);
  const Inp__out_info = ({ common_components }) => {
    // console.log(props);
    return props?.outpVisitId ? (
      <OutInfo common_components={common_components} />
    ) : (
      <InInfo common_components={common_components} />
    );
  };
  /** 患者信息 */
  return (
    <div className="components_user_info">
      <Inp__out_info common_components={true} />
    </div>
  );
};

export default definePage(Index);
