import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Button,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const SendModal = (props, ref): any => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  const convertGetListOrderPerformSuspendWithStaffVoByRelatedPerformPlanIdRes2VSPromiseTableOrderPerformSuspendList =
    (input) => {
      const _ = window._ ?? {};
      const resultTableData: any[] = [];
      resultTableData.push(
        ...(_.get(input, 'data')?.map((d, i) => {
          const obj: any = {};
          obj.id = _.get(d, 'id');
          obj.operatorId = _.get(d, 'operator.id');
          const suspendIndicator = _.get(d, 'suspendIndicator');
          obj.suspendIndicator = suspendIndicator;
          obj.operatorType = '未知'; // 操作类型
          if (suspendIndicator == true) {
            obj.operatorType = '暂停';
          } else if (suspendIndicator == false) {
            obj.operatorType = '取消暂停';
          }
          obj.operateDate = _.get(d, 'operateDate');
          obj.operatorName = _.get(d, 'operator.staffName');
          obj.reason = _.get(d, 'reason');
          return obj;
        }) ?? []),
      );
      return resultTableData;
    };

  const fetch = useCallback(async () => {
    const res =
      await vsf?.services?.ClinicRecordOrderPerformController_getListOrderPerformSuspendWithStaffVoByRelatedPerformPlanId_be0f00?.(
        {
          relatedPerformPlanId: value,
        },
      );
    const resultTableData =
      convertGetListOrderPerformSuspendWithStaffVoByRelatedPerformPlanIdRes2VSPromiseTableOrderPerformSuspendList?.(
        res,
      );
    return {
      data: resultTableData ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [value]);

  const renderContent = useCallback(() => {
    // 暂停日志
    return (
      <div className="content">
        <div className="first">
          <VSPromiseTable onFetch={fetch}>
            <VSTableColumn valueType="index" title="" preview />

            <VSTableColumn
              dataIndex={['operatorType']}
              valueType="text"
              title="操作类型"
              preview
            />

            <VSTableColumn
              dataIndex={['operateDate']}
              title="暂停时间"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['operatorName']}
              title="暂停人员"
              valueType="text"
              fieldProps={{}}
              preview
            />

            <VSTableColumn
              dataIndex={['reason']}
              title="暂停原因"
              valueType="text"
              fieldProps={{}}
              preview
            />
          </VSPromiseTable>
        </div>
      </div>
    );
  }, [fetch]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderFooter = useCallback(() => {
    return {
      footer: (
        <div>
          <Button onClick={onClose}>关闭</Button>
        </div>
      ),
    };
  }, [onClose]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOpen(true);
      setValue(data);
    },
  }));

  return (
    <Modal
      width={1168}
      open={open}
      destroyOnClose={true}
      title="暂停记录"
      onCancel={onClose}
      {...renderFooter()}
      className="aspirin-execute-order-fail-modal"
    >
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(SendModal);
