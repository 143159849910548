import './index.less';

import vsf from '@vs/vsf-boot';
import {
  Checkbox,
  compose,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  RadioGroup,
  Select,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useEventListener, useMount } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import isTodays from 'dayjs/plugin/isToday';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import amount from '../dosage/amount';
import Tip from '../func/Tip';
import OperationTable from './operationTable';
import Table from './table';
import Table2 from './table2';
import Table3 from './table3';
import ValidateDrug from './validate';
import SkinTest from './validate_drug';

const mockDoctorId = 1603;

dayjs.extend(isTodays);
export interface DictionaryProps {
  /**
   * 药品接口
   * @returns 药品列表
   */
  onDrugFetch?: (params) => Promise<any[]>;
  handleExtraSubmit?: (params) => void;
  onFieldEnter?: () => void;
  onDrugDetailsFetch?: (params) => Promise<{
    validateType: string;
    errorMessage: string;
    antibioticIndicator: boolean;
    drugEo: {
      toxicCode: string;
    };
  }>;
  /**
   * 是否显示科室
   */
  showDepartment?: boolean;
  /**
   * 是否显示药房
   */
  showStorage?: boolean;
  /**
   * 是否显示库存
   */
  showStorageName?: boolean;
  /**
   * 是否带出皮试
   */
  skinTest?: boolean;
  /**
   * 初始化的默认表格 ( isOther: 处置输入法 )
   */
  isOther?: boolean;
  /**
   * 工作站类型 in: 住院 out: 门诊
   */
  hospitalType?: string;
  repeatIndicator?: boolean;
  disabled?: boolean;
  record?: any;
  className?: string;
  startPerformDateTime?: any;
  getDisabled?: any;
}

type DictionaryPropsType = DictionaryProps & {
  value?: string;
  onChange?: (v) => void;
  groupIndexInfo: string;
  tableClassify: string;
  dataSource: any[];
  isCoder?: boolean;
};

const ToxiCode = {
  Normal: '1',
  Poison: '6',
  PsychotropicFirst: '2',
  PsychotropicSecond: '7',
  Narcotic: '5',
};

const renderPriview = (value) => {
  return (
    <div className={classnames('aspirin-order-text', value?.record?.groupName)}>
      <span>{value?.drugProductName ?? value}</span>
    </div>
  );
};

/**
 * 字典查询
 */
let Dictionary: any = (props: DictionaryPropsType, ref) => {
  const {
    value,
    onChange,
    handleExtraSubmit,
    groupIndexInfo,
    tableClassify,
    onDrugFetch,
    onDrugDetailsFetch,
    showDepartment = true,
    showStorage = true,
    showStorageName = true,
    dataSource = [],
    skinTest = true,
    isOther,
    hospitalType,
    repeatIndicator,
    startPerformDateTime,
    disabled,
    record,
    className,
    isCoder = false,
  } = props;
  const [current, setCurrent] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [medicineType, setMedicineType] = useState<
    'PERSONAL' | 'DEPARTMENT' | null
  >('PERSONAL');

  const [showRepertory, setShowRepertory] = useState<boolean>(showStorage);
  const [tableType, setTableType] = useState<string>('MEDICATION');

  const [init, setInit] = useState<boolean>(false);

  const searchRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    focus: onFocus,
    // blur: onBlur,
  }));

  const onFocus = () => {
    setMedicineType('PERSONAL');
    setDropdownOpen(true);
    getStorageList();
    setTimeout(() => {
      searchRef?.current?.focus();
    }, 0);
  };

  const getSelectShortcut = async (value) => {
    const clinicItemId = value?.clinicItemId;

    if (clinicItemId) {
      const res =
        await vsf.services?.TreatAndOtherItemController_getItemAndPriceByClinicItemId_6b022b?.(
          {
            clinicItemId,
          },
        );
      return res?.data;
    }

    return null;
  };

  const onPushData = async (data) => {
    const handelOk = (data) => {
      if (['', undefined, null].includes(data)) return setDropdownOpen(false);
      const { detailInfo, ...rest } = data;
      setDropdownOpen(false);
      setCurrent(rest);
      onChange?.(data);
      props?.onFieldEnter?.();
    };
    if (Array.isArray(data)) {
      handleExtraSubmit?.(data);
    } else {
      if (data?.clinicItemClass) {
        if (record?.groupName) {
          if (data?.clinicItemClass !== 'DRUG')
            message?.warning('药品不能与其他类型医嘱成组');
        } else {
          handelOk(data);
        }
      } else {
        if (record?.groupName) {
          if (
            await props?.getDisabled(
              {
                ...data,
                ...data?.detailInfo,
                ...data?.detailInfo?.drugEo,
                id: record?.id,
                orderClass: 'DRUG',
                repeatIndicator,
                performDepartmentId: data?.storageDepartmentId,
                performDepartment: {
                  departmentName: data?.storageDepartmentName,
                  id: data?.storageDepartmentId,
                },
                drugOrder: {
                  ...data,
                  ...data?.detailInfo,
                  ...data?.detailInfo?.drugEo,
                },
              },
              dataSource,
            )
          ) {
            handelOk(data);
          }
        } else {
          handelOk(data);
        }
      }
    }
  };
  // 1.7 重复药品
  const onHandleRepeatDrug = useCallback(
    (data) => {
      return new Promise((resolve, reject) => {
        /**
         * 一天内 同种类药品
         *  */
        const includes = dataSource?.find((item) => {
          // 药品编码
          const drug_item_id =
              item?.drugOrder?.drugItemId ?? item?.orderText?.drugItemId,
            data_time = item?.createDateTime ?? item?.startPerformDateTime;

          return (
            drug_item_id === data?.drugItemId && dayjs(data_time).isToday()
          );
        });
        const include = dataSource?.find((item) => {
          // 药品id
          const drug_id = item?.drugOrder?.drug?.id ?? item?.drugOrder?.drugId,
            data_time = item?.createDateTime ?? item?.startPerformDateTime;
          return drug_id === data?.drugId && dayjs(data_time).isToday();
        });
        if (data?.detailInfo?.orderClass === 'DRUG' && (includes || include)) {
          Tip({
            title: data?.drugProductName,
            content: includes
              ? // ? `该病人开过同类药品 {${includes?.drugOrder?.drugProductName}}`
                `该病人今日开过 ${includes?.drugOrder?.drugProductName} ，不能继续开立`
              : '该病人今日开过同种药品',
            onOk: () => {
              if (includes) return reject(false);
              resolve(true);
            },
          });
          return;
        }

        return resolve(true);
      });
    },
    [dataSource],
  );

  const onSelect = async (v) => {
    const detailInfo: any = await onDrugDetailsFetch?.(v);
    const target = {
      detailInfo,
      ...v,
    };
    setDropdownOpen(false);
    if (detailInfo?.skinTestType && isCoder) {
      await onHandleRepeatDrug(target);
      /**
       * 成组然后有皮试不可添加第二条皮试
       */

      let i = 0,
        skin;
      while (record?.groupIndex?.length > i) {
        const group_index = record?.groupIndex?.[i];
        if (
          group_index !== record?.drugOrder?.groupSubNumber &&
          dataSource?.[group_index]?.drugOrder?.skinTestType
        ) {
          skin = dataSource?.[group_index];
          break;
        }
        i++;
      }
      if (
        skin &&
        record?.groupName &&
        ['SAVE', 'OPEN'].includes(record?.orderStatus)
      ) {
        message?.warning(`当前组内已有 ${skin?.orderText} 需要皮试，无法成组`);
        return;
      }
      if (
        record?.groupName &&
        !(await props?.getDisabled(
          {
            ...target,
            ...target?.detailInfo,
            ...target?.detailInfo?.drugEo,
            id: record?.id,
            orderClass: 'DRUG',
            repeatIndicator,
            performDepartmentId: target?.storageDepartmentId,
            performDepartment: {
              departmentName: target?.storageDepartmentName,
              id: target?.storageDepartmentId,
            },
            drugOrder: {
              ...target,
              ...target?.detailInfo,
              ...target?.detailInfo?.drugEo,
            },
          },
          dataSource,
        ))
      ) {
        return;
      }

      if (detailInfo?.validateType === 'ERROR') {
        return Modal.error({
          title: '提示',
          content: detailInfo?.errorMessage,
        });
      } else if (
        detailInfo?.validateType === 'WARNING' ||
        detailInfo?.drugEo?.toxicCode === ToxiCode.Narcotic || // 麻醉药品
        detailInfo?.antibioticIndicator || // 抗生素
        !!detailInfo?.skinTestType
      ) {
        const array = await validate?.current?.onOpen?.({
          ...target,
          repeatIndicator,
          startPerformDateTime,
          prescriptionIndicator: target?.prescriptionIndicator,
          detailInfo: {
            ...detailInfo,
            skinTestFlag:
              detailInfo?.skinTestType && !detailInfo?.antibioticIndicator
                ? 0
                : -1, // 前端参数 -1.初步弹框不进行皮试相关弹框; 0.二次弹框, 如果满足皮试相关可以弹框; 1.不进行皮试弹框
          },
        });
        // console.log(detailInfo, 'target', validate);
        onPushData?.(array);
      } else {
        if (detailInfo?.errorMessage) {
          return Modal.info({
            title: '提示',
            content: detailInfo?.errorMessage,
          });
        }
        onPushData(target);
      }
    } else {
      onPushData(target);
    }
  };

  const onSelectTable3 = (value) => {
    setCurrentDrug(value);
    onPushData({
      ...value,
    });
    setDropdownOpen(false);
  };

  const onSelectTable2 = async (v) => {
    const detailInfo = !['EXAM', 'LAB']?.includes(v?.clinicItemClass)
      ? await getSelectShortcut(v)
      : v;
    const method = {
      EXAM: async () => {
        const res =
          await vsf.services.ExamOrderController_getExamClassInfoByClinicItemId_c82623?.(
            {
              clinicItemId: v.clinicItemId,
            },
          );
        if (res?.code === 200) {
          return {
            orderClass: 'EXAM',
            orderStatus: 'OPEN',
            examOrderDetail: [
              {
                examItemName: v?.clinicItemName,
                examItemId: v.clinicItemId,
                associatedId: {
                  examClassId: res?.data?.examClassId,
                  examSubClassIdIs: res?.data?.examSubClassId,
                  examThirdClassIdIs: res?.data?.examThirdClassId,
                },
              },
            ],
          };
        }
        return {};
      },
      LAB: async () => {
        const allPrice = (v?.currentPriceItem ?? [])?.reduce(
          (sum: any, currentValue: any) => {
            return (
              sum +
              (currentValue?.amount ?? 0) *
                (currentValue?.currentPriceItem?.price ?? 0)
            );
          },
          0,
        );
        return {
          orderClass: 'LAB',
          orderStatus: 'OPEN',
          labItemId: v?.clinicItemId,
          labItemName: v?.clinicItemName,
          labOrder: {
            labItemId: v?.clinicItemId,
            labItemName: v?.clinicItemName,
          },
          allPrice,
        };
      },
    };
    const target = {
      ...v,
      orderText: v?.clinicItemName,
      detailInfo: detailInfo,
      ...((await method?.[v?.clinicItemClass]?.()) ?? {}),
    };
    setCurrentDrug(target);
    onPushData({
      ...target,
      orderClass: v?.clinicItemClass,
    });
  };
  const onSelectoperationTable = (value) => {
    setCurrentDrug(value);
    // onPushData({
    //   ...value,
    // });
    // setCurrent(value);
    setCurrentDrug(value);
    onChange?.(value);
    // props?.onFieldEnter?.();
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (![void 0, null].includes(current)) {
      if (['LAB', 'EXAM'].includes(current?.clinicItemClass)) {
        setCurrentValue('');
        return;
      }
      if (typeof current === 'string') {
        setCurrentValue(current ?? '');
      } else {
        if (tableClassify && tableClassify === 'ORDERPRICE') {
          setCurrentValue(current?.itemName ?? '');
        } else if (tableClassify && tableClassify === 'DAYPRICEAUDIT') {
          setCurrentValue(current?.itemName ?? '');
        } else if (tableClassify && tableClassify === 'OPERATIONLIST') {
          setCurrentValue(current?.operationName ?? '');
        } else {
          setCurrentValue(
            current?.prescriptionDefaultIndicator && !repeatIndicator
              ? `[处方]${current?.drugProductName}${
                  current?.packageSpecification
                    ? `(${current?.packageSpecification})`
                    : ''
                }`
              : `${current?.drugProductName}${
                  current?.packageSpecification
                    ? `(${current?.packageSpecification})`
                    : ''
                }` ?? '',
          );
        }
      }
    }
  }, [current, tableClassify, repeatIndicator]);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  useLayoutEffect(() => {
    if (isOther) {
      setInit(true);
      setSearchValue('.');
    }
  }, [isOther]);

  useLayoutEffect(() => {
    init && setTableType(searchValue?.[0] === '.' ? '' : 'MEDICATION');
  }, [searchValue, init]);

  const getStorageList = useCallback(async () => {
    if (tableClassify === 'ORDERPRICE') return;
    if (hospitalType === 'out') {
      const res =
        await vsf?.services?.ClinicRecordOrderDrugController_getStorageIds_82d8b5?.();

      if (res?.code === 200) {
        setStorageList(res?.data);
      }
    } else {
      const res =
        await vsf?.services?.ClinicRecordOrderDrugController_getInpStorageIds_10643e?.(
          {
            temp: !repeatIndicator,
          },
        );
      if (res?.code === 200) {
        setStorageList(res?.data);
      }
    }
  }, [hospitalType, repeatIndicator, tableClassify]);

  const [storageId, setStorageId] = useState<any>();
  const [storageList, setStorageList] = useState<any>();

  const [validateOpen, setValidateOpen] = useState<boolean>(false);
  const [currentDrug, setCurrentDrug] = useState<any>();

  const validate = useRef<any>(null);

  // const onBlur = () => {
  //   setDropdownOpen(false);
  // };
  /**
   * 点击其他地方收起弹框
   */

  useEventListener(
    'click',
    (event: any) => {
      const select = document?.querySelector(
        '.aspirin-dictionary-dropdown-container',
      );
      if (!event?.target?.contains(select) && dropdownOpen) {
        setDropdownOpen(false);
      }
    },
    {
      target: document,
    },
  );

  let flag = false;

  if (disabled) {
    return renderPriview(value);
  }

  return (
    <div
      className={classnames('aspirin-order-text', className)}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <SkinTest ref={validate} />
      <Dropdown
        menu={{
          items: [],
        }}
        // onOpenChange={(v) => {
        //   console.log('click', v);
        //   setDropdownOpen(v);
        // }}
        dropdownRender={(menu) => (
          <div
            className="aspirin-dictionary-dropdown-container"
            onClick={(event) => {
              event.stopPropagation();
            }}
            onDoubleClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="header">
              <div className="first">输入序号选择项目，键盘“← →”翻页</div>

              <div className="second">
                {tableClassify && tableClassify === 'ORDERPRICE' ? (
                  <div>
                    <Input
                      ref={searchRef}
                      // value={searchValue}
                      defaultValue={isOther ? '.' : void 0}
                      // onBlur={() => {
                      //   setDropdownOpen(false);
                      // }}
                      onInput={(e: any) => {
                        if (!flag) {
                          const value = e.target.value;
                          // const regex = /^[^0-9]*$/; // 正则表达式，只允许非数字字符
                          // if (regex.test(value)) {
                          setInit(true);
                          setSearchValue(value);
                          // }
                        }
                      }}
                      onCompositionStart={() => {
                        flag = true;
                      }}
                      onCompositionEnd={(event: any) => {
                        flag = false;
                        setSearchValue(event?.target?.value);
                      }}
                      // width={280}
                      className="width280"
                    />
                  </div>
                ) : (
                  <div className="left">
                    <Input
                      ref={searchRef}
                      // value={searchValue}
                      // onBlur={() => {
                      //   setDropdownOpen(false);
                      // }}
                      defaultValue={isOther ? '.' : void 0}
                      onInput={(e: any) => {
                        console.log(e, '====1');

                        if (!flag) {
                          const value = e.target.value;
                          const regex = /^[^0-9]*$/; // 正则表达式，只允许非数字字符

                          if (regex.test(value)) {
                            setInit(true);
                            setSearchValue(value);
                          }
                          // if (value?.[0] === '.') {
                          //   setMedicineType(null);
                          // }
                        }
                      }}
                      onCompositionStart={() => {
                        flag = true;
                      }}
                      onCompositionEnd={(event: any) => {
                        flag = false;
                        setInit(true);
                        setSearchValue(event?.target?.value);
                      }}
                      // width={280}
                      className="width280"
                    />
                    {tableType !== '' &&
                    tableClassify !== 'OPERATIONLIST' &&
                    showDepartment ? (
                      <Select
                        // width={140}
                        className="width140"
                        dataSource={storageList?.map((item) => ({
                          label: item?.storageName,
                          value: item?.id,
                        }))}
                        value={storageId}
                        onChange={(v) => {
                          setStorageId(v);
                        }}
                        allowClear
                      />
                    ) : null}
                    {!(
                      tableClassify &&
                      (tableClassify === 'ORDERPRICE' ||
                        tableClassify === 'DAYPRICEAUDIT' ||
                        tableClassify === 'OPERATIONLIST')
                    ) &&
                      tableType !== '' &&
                      showStorage && (
                        <Checkbox
                          onChange={(v) => {
                            setShowRepertory(!!v);
                          }}
                          value={showRepertory}
                        >
                          显示库存
                        </Checkbox>
                      )}
                  </div>
                )}

                {!(
                  tableClassify &&
                  (tableClassify === 'ORDERPRICE' ||
                    tableClassify === 'DAYPRICEAUDIT' ||
                    tableClassify === 'OPERATIONLIST')
                ) && (
                  <div className="right">
                    <RadioGroup
                      optionType="button"
                      onChange={(v) => {
                        setMedicineType(v);
                      }}
                      value={medicineType as any}
                      dataSource={[
                        {
                          label: `个人常用${
                            tableType === 'MEDICATION' ? '药' : ''
                          }`,
                          value: 'PERSONAL',
                        },
                        {
                          label: `科室常用${
                            tableType === 'MEDICATION' ? '药' : ''
                          }`,
                          value: 'DEPARTMENT',
                        },
                      ]}
                      style={{
                        marginLeft: '0.8rem',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {tableClassify !== 'OPERATIONLIST' &&
              tableClassify !== 'ORDERPRICE' &&
              (tableClassify && tableClassify === 'DAYPRICEAUDIT' ? (
                <></>
              ) : tableType === 'MEDICATION' ? (
                <Table
                  key="MEDICATION"
                  onSelect={onSelect}
                  open={dropdownOpen}
                  showRepertory={showRepertory}
                  storageId={storageId}
                  storageList={storageList}
                  searchValue={searchValue}
                  onDrugFetch={onDrugFetch}
                  favoriteType={medicineType as any}
                  hospitalType={hospitalType}
                  repeatIndicator={repeatIndicator}
                  showStorageName={showStorageName}
                />
              ) : (
                <Table2
                  hospitalType={hospitalType}
                  searchValue={searchValue}
                  favoriteType={medicineType}
                  onSelect={onSelectTable2}
                  open={dropdownOpen}
                />
              ))}
            {tableClassify !== 'OPERATIONLIST' &&
              tableClassify &&
              tableClassify !== 'DAYPRICEAUDIT' &&
              (tableClassify === 'ORDERPRICE' ? (
                <Table3
                  onSelect={onSelectTable3}
                  open={dropdownOpen}
                  // showRepertory={showRepertory}
                  // storageId={storageId}
                  searchValue={searchValue}
                />
              ) : tableType === 'MEDICATION' ? (
                <Table
                  key="MEDICATION"
                  onSelect={onSelect}
                  open={dropdownOpen}
                  showRepertory={showRepertory}
                  showStorageName={showStorageName}
                  storageId={storageId}
                  storageList={storageList}
                  searchValue={searchValue}
                  onDrugFetch={onDrugFetch}
                  favoriteType={medicineType as any}
                  hospitalType={hospitalType}
                  repeatIndicator={repeatIndicator}
                />
              ) : (
                <Table2
                  hospitalType={hospitalType}
                  searchValue={searchValue}
                  onSelect={onSelectTable2}
                  favoriteType={medicineType}
                  open={dropdownOpen}
                />
              ))}
            {tableClassify && tableClassify === 'OPERATIONLIST' && (
              <OperationTable
                searchValue={searchValue}
                onSelect={onSelectoperationTable}
                open={dropdownOpen}
              />
            )}
          </div>
        )}
        trigger={['click']}
        // placement="top"
        open={dropdownOpen}
        // open={true}
      >
        <Input
          ref={ref}
          value={currentValue}
          onFocus={onFocus}
          disabled={true}
          onClick={(e) => {
            e.stopPropagation();
          }}
          // autoFocus
          // onBlur={onBlur}
          // onInput={(e: any) => {
          //   setSearchValue(e?.target?.value);
          //   setDropdownOpen(true);
          // }}
        />
      </Dropdown>
    </div>
  );
};

Dictionary.displayName = 'Dictionary';

Dictionary = React.forwardRef(Dictionary);

export default compose(
  withRef(),
  withField<any>({
    name: 'dictionary',
    handleEnter: true,
    shouldFieldUpdate: (newly: any, old: any) => {
      return true;
    },
  }),
  withPreview<any>({
    renderPreview: (props) => {
      /** 返回预览模式下的dom */
      return renderPriview(props?.value);
    },
  }),
)(Dictionary);
