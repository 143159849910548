import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSTableColumn, VSPromiseTable, Button } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import NewDrawer from '@/pages/Index/components/new_drawer';
const SavedPurchasePlanList = (props) => {
  /**
   * convertGetSavePurchasePlanListRes2TableSavedPurchasePlan
   * @param {any} input
   * @returns {any}
   */
  const convertGetSavePurchasePlanListRes2TableSavedPurchasePlan = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = Object.assign({}, d);
        return obj;
      }) ?? []),
    );
    return output;
  };
  // 获取全局事件
  const _ = window._ ?? {};
  // 提示框是否显示;
  const [modal1Open, setModal1Open] = useState(false);
  // onFetch
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.DrugStoragePurchaseController_getSavePurchasePlanList_32f393?.(
        {},
      );
    return {
      data: convertGetSavePurchasePlanListRes2TableSavedPurchasePlan?.(res),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  useEffect(
    () => props?.openDrawer && vsf?.refs?.tableSavedPurchasePlan?.reload(),
    [props?.openDrawer],
  );
  return (
    <Section>
      <Modal
        title="提示"
        style={{ top: '28%' }}
        open={modal1Open}
        onOk={
          // asyncawait
          (event) => {
            const input = props?.selectrow?.id;
            if (input?.length <= 0) return;
            const res =
              vsf?.services?.DrugStoragePurchaseController_deleteSavePurchase_374f52?.(
                { btoParam: { id: input } },
              );

            res.then(({ code }) => {
              if (code === 200) {
                setModal1Open(false);
                vsf?.refs?.tableSavedPurchasePlan?.reload();
              }
            });
          }
        }
        onCancel={() => setModal1Open(false)}
      >
        <p>是否删除当前选中的单据？</p>
        <p>采购计划单号：{props?.selectrow?.purchasePlanNumber}</p>
      </Modal>
      <NewDrawer
        open={props?.openDrawer}
        width="40%"
        onOpen={() => {
          props?.setOpenDrawer(!props?.openDrawer);
        }}
        title={<div style={{ color: '#3276E8' }}>采购计划单</div>}
        buttonTitle="采购计划单"
        backgroundColor="var(--blue)"
        footer={
          <div
            className="aspirin-table-footer-button"
            style={{ justifyContent: 'flex-end' }}
          >
            <Button
              type="primary"
              onClick={() => vsf?.refs?.tableSavedPurchasePlan?.reload()}
            >
              刷新
            </Button>
            <Button
              type="primary"
              disabled={!props?.selectrow}
              onClick={() => setModal1Open(true)}
            >
              删除单据
            </Button>
          </div>
        }
        buttonStyle={{
          top: '18%',
        }}
        drift="98px"
        style={{
          top: 104,
        }}
        isOther
      >
        <VSPromiseTable
          vsid="55712"
          id="tableSavedPurchasePlan"
          onFetch={onFetch}
          onRow={(node) => {
            return {
              onClick: () => props?.setSelectrow(node),
              onDoubleClick: () => {
                props?.setPlanMaster(node);
                props?.setOpenDrawer(!props?.openDrawer);
                props?.setDrugProductNameValue('');
                vsf?.refs?.tablePurchasePlanCreate?.reload();
              },
            };
          }}
          rowClassName={(node) => {
            return node?.id === props?.selectrow?.id
              ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
              : 'aspirin-vs-table-row-box aspirin-row-select-box';
          }}
          pagination={false}
        >
          <VSTableColumn
            dataIndex={['purchasePlanNumber']}
            title="采购计划单号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '采购计划单号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['planCreateDate']}
            title="生成日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['planType']}
            title="采购类型"
            valueType="select"
            dataSource={[
              { label: '药械平台采购', value: 'PLATFORM_PURCHASE' },
              { label: '普通采购', value: 'NORMAL_PURCHASE' },
              { label: '中草药采购', value: 'HERB_PURCHASE' },
            ]}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </NewDrawer>
    </Section>
  );
};
export default definePage(SavedPurchasePlanList);
