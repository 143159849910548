import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  Input,
  message,
  Modal,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { random } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CertificateSearch } from '@/components';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { isMinScreen } from '@/config';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, labelColWidth } from '@/utils';
import storage from '@/utils/storage';

import {
  convertGetInpInpAdmissionByClinicInpAdmissionQtoRes2InpInpAdmissionForm,
  convertInpInpAdmissionForm2SaveInpInpAdmissionReq,
} from './conver';
import MyModal from './modal';

const Index = (props) => {
  const { user } = props?.stores || {};

  const [inpInpAdmissionVo, setInpInpAdmissionVo] = useState<any>();
  // 当前患者信息-table双击选择
  const [currentRegisterPatientId, setCurrentRegisterPatientId] =
    useState<any>();
  // 选择的入院科室id
  const [department, setDepartment] = useState<any>();
  //变化的index,清屏后，重新输入id,id没变时form没有重新刷新
  const [otherIndex, setOtherIndex] = useState<any>();
  // 搜索患者id
  const [searchDisplayId, setSearchDisplayId] = useState<any>();
  // table list
  const [tableList, setTableList] = useState<any>();
  const [chargeTypeCode, setChargeTypeCode] = useState<any>();
  const [chargeTypeData, setChargeTypeData] = useState<any>();
  const modalRef = useRef<any>();
  const identificationClass = useRef();
  const identificationNumber = useRef();

  useEffect(() => {
    if (!currentRegisterPatientId) return;
    vsf.services
      .ClinicInpAdmissionController_getInpInpAdmissionByClinicInpAdmissionQto_ea6e57(
        {
          // id: 2701
          id: currentRegisterPatientId,
        },
      )
      .then((res) => {
        const _res =
          convertGetInpInpAdmissionByClinicInpAdmissionQtoRes2InpInpAdmissionForm(
            res,
          );
        vsf?.refs?.inpInpAdmissionForm?.setFieldsValue({
          ..._res,
          admissionDateTime:
            _res?.admissionDateTime ?? dayjs().format('YYYY-MM-DD HH:mm:ss'),
          // diagnosisList: [],
          unitInContract: _res?.unitInContract ? _res?.unitInContract : false,
        });
        setChargeTypeCode(_res?.chargeType);
        setInpInpAdmissionVo(_res);
        setDepartment({
          id: _res?.admissionToDepartment?.id,
          departmentName: _res?.admissionToDepartment?.departmentName,
          departmentCode: _res?.admissionToDepartment?.departmentCode,
        });
      });
    // return res?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegisterPatientId, otherIndex]);

  useEffect(() => {
    if (!searchDisplayId || searchDisplayId?.length == 0) return;
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDisplayId, otherIndex]);

  useEffect(() => {
    handelFilter(chargeTypeCode);
  }, [chargeTypeCode]);

  // table
  const getTableList = async () => {
    if (searchDisplayId?.length <= 0) return [];
    const res =
      await vsf.services?.ClinicInpAdmissionController_getAllAvailableByClinicInpAdmissionQto_dcc286?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            patientIdIs: searchDisplayId,
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    const _res = res?.data?.map((item, index) => {
      return { ...item, id: index };
    });
    if (res?.code === 200 && res?.data?.length === 1) {
      modalRef?.current?.handleSelect(res?.data?.[0]);
    }
    if (res?.code === 200 && res?.data?.length > 1) {
      modalRef?.current?.handelModal?.(true);
    }
    if (res?.code !== 200 || res?.data?.length == 0) {
      modalRef?.current?.handelModal?.(false);
      // vsf?.refs?.inpInpAdmissionForm?.resetFields();
    }
    setTableList({
      data: _res ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
      code: res?.code,
    });
    return {
      data: _res ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
      code: res?.code,
    };
  };

  const handelCopy = (content) => {
    const isSuccess = copy(content);
    if (isSuccess) {
      message.success(`复制成功：${content}`);
    } else {
      message.error('复制失败!');
    }
  };
  // 保存
  const handelSave = async () => {
    if (!currentRegisterPatientId) return;
    const res4 =
      await vsf?.refs?.inpInpAdmissionForm?.validateFieldsReturnFormatValue();
    const res = vsf?.refs?.inpInpAdmissionForm?.getFieldsValue();

    Modal.confirm({
      title: '提示',
      content: '是否保存?',
      centered: true,
      onOk: async () => {
        const res = vsf?.refs?.inpInpAdmissionForm?.getFieldsValue();
        const _res = convertInpInpAdmissionForm2SaveInpInpAdmissionReq({
          ...inpInpAdmissionVo,
          ...res,
          birthAddress: {
            ...res?.birthAddress,
            county: res?.birthAddress?.district
              ? res?.birthAddress?.district
              : res?.birthAddress?.county,
          },
          currentAddress: {
            ...res?.currentAddress,
            county: res?.currentAddress?.district
              ? res?.currentAddress?.district
              : res?.currentAddress?.county,
          },
          nativeAddress: {
            ...res?.nativeAddress,
            county: res?.nativeAddress?.district
              ? res?.nativeAddress?.district
              : res?.nativeAddress?.county,
          },
          contactPersonAddress: {
            ...res?.contactPersonAddress,
            county: res?.contactPersonAddress?.district
              ? res?.contactPersonAddress?.district
              : res?.contactPersonAddress?.county,
          },
          workUnit: {
            ...res?.workUnit,
            address: {
              ...res?.workUnit?.address,
              county: res?.workUnit?.address?.district
                ? res?.workUnit?.address?.district
                : res?.workUnit?.address?.county,
            },
          },
        });
        const response =
          await vsf.services.ClinicInpAdmissionController_saveInpInpAdmission_a469d6(
            {
              ..._res,
            },
          );
        if (response?.code === 200) {
          message.success('保存成功!');
        }
      },
      okText: '确定',
      cancelText: '取消',
      onCancel() {},
    });
  };
  // 清屏
  const handelClear = () => {
    const inpAppointLabel = vsf?.refs?.inpInpAdmissionForm?.getNamePaths();
    const inpAppointLabelFilter = inpAppointLabel.filter((item) => {
      return (
        item?.[0] !== 'name' &&
        item?.[0] !== 'gender' &&
        item?.[0] !== 'birthday' &&
        item?.[0] !== 'unitInContract' &&
        item?.[0] !== 'visitStartDate' &&
        item?.[0] !== 'diagnosisId'
      );
    });
    vsf?.refs?.inpInpAdmissionForm?.resetFields(inpAppointLabelFilter);
  };
  // 入院科室
  const getPagedBySearchCode = async (v) => {
    const res =
      await vsf.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          nameLike: v?.nameLike ?? '',
          outpOrInp: '1',
        },
      ); // TODO: 你可能需要整理返回数据格式
    return res?.data?.result ?? [];
  };
  // 护理单元
  const getPagedByDepartmentCode = async (v) => {
    if (!department?.id) return;
    const res =
      await vsf.services?.DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          departmentId: department?.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    return res?.data?.result ?? [];
  };
  // 住院主管医生
  const getUserGroupController = async () => {
    if (!department?.id) return;
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          departmentId: department?.id,
          staffRole: '01',
          stopIndicator: false,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result?.map((item) => {
      return item?.medicalGroupMemberIndexDtoList?.[0]?.staff;
    });
    return _res;
  };

  const getPatientInfo = (v) => {
    setCurrentRegisterPatientId(v);
  };

  // 搜索患者id
  const handelSearch = (e, value?) => {
    if (e.code === 'Enter') {
      if (e?.target?.value?.length > 0) {
        const _num = Math.random();
        // modalRef?.current?.handelModal(true);
        modalRef?.current?.getSearchKey(value ?? e?.target?.value);
        setOtherIndex(_num);
        setSearchDisplayId(value ?? e?.target?.value);
      }
    }
  };
  // const handelSearch = (value?) => {
  //   const _num = Math.random();
  //   // modalRef?.current?.handelModal(true);
  //   modalRef?.current?.getSearchKey(value);
  //   setOtherIndex(_num);
  //   setSearchDisplayId(value);
  // };
  // 证件类型
  const handelSelectChange = (e) => {
    identificationClass.current = e;
  };
  // 证件类型输入
  const handelInputChange = (e) => {
    identificationNumber.current = e.target.value;
  };
  const handelSearchIdentificationClass = async (e) => {
    if (identificationClass?.current == '患者ID') {
      handelSearch(e);
    } else {
      const res =
        await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
          {
            qto: {
              size: 20,
              from: 0,
              identificationClassIn: identificationClass?.current
                ? [identificationClass?.current]
                : [],
              identificationNumberIn: identificationNumber?.current
                ? [identificationNumber?.current]
                : [],
            },
            ext: {},
          },
        );
      if (res?.code == 200) {
        if (res?.data?.result?.length == 0) {
          message.error('未查到患者信息');
          vsf?.refs?.inpInpAdmissionForm?.resetFields();
        } else {
          handelSearch(e, res?.data?.result?.[0].displayId);
        }
      }
    }
  };
  // const handelSearchIdentificationClass = async (
  //   identificationClassIn,
  //   identificationNumberIn,
  // ) => {
  //   if (identificationClassIn == '患者ID') {
  //     handelSearch(identificationNumberIn);
  //   } else {
  //     const res =
  //       await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
  //         {
  //           qto: {
  //             size: 20,
  //             from: 0,
  //             identificationClassIn: identificationClassIn
  //               ? [identificationClassIn]
  //               : [],
  //             identificationNumberIn: identificationNumberIn
  //               ? [identificationNumberIn]
  //               : [],
  //           },
  //           ext: {},
  //         },
  //       );
  //     if (res?.code == 200) {
  //       if (res?.data?.result?.length == 0) {
  //         message.error('未查到患者信息');
  //         vsf?.refs?.inpInpAdmissionForm?.resetFields();
  //       } else {
  //         handelSearch(res?.data?.result?.[0].displayId);
  //       }
  //     }
  //   }
  // };

  // 过滤人员类别
  const handelFilter = (data) => {
    const res = vsf.stores.dbenums?.enums?.IDENTITY_DICT?.filter((item) => {
      return (
        item?.extensionJson &&
        JSON.parse(item?.extensionJson)?.chargeTypeCode == data
      );
    });
    if (res && res?.length > 0) {
      setChargeTypeData(res);
      return res;
    } else {
      setChargeTypeData([]);
      return [];
    }
  };

  // 门诊诊断tag
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        // color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const renderSearch = () => {
    return (
      <div className="register_information_search_input icon18">
        <div className="register_information_search_input_item">
          <span className="icon18 register_information_search_input_item_label">
            患者ID
          </span>
          <Input onKeyUp={handelSearch} className="width240" />
        </div>
        <div className="register_information_search_input_item">
          <span className="icon18 register_information_search_input_item_label">
            证件及编号
          </span>
          <Select
            className="width240"
            dataSource={vsf.stores.dbenums?.enums?.IDENTIFICATION_CLASS_DICT}
            showSearch={true}
            onChange={handelSelectChange}
          />
          <Input
            className="width470"
            onChange={handelInputChange}
            onKeyUp={(e: any) => {
              if (e.code === 'Enter') {
                if (e?.target?.value?.length > 0)
                  handelSearchIdentificationClass(e);
              }
            }}
            addonAfter={
              <div className="register_information_search_input_item_addonAfter">
                <Icon size={20} type="icon-duka" />
                <span className="register_information_search_input_item_addonAfter_text">
                  读卡
                </span>
              </div>
            }
          />

          {/* <CertificateSearch
            onEnter={(event) => {
              const { identificationClassIn, identificationNumberIn } = event;
              handelSearchIdentificationClass(
                identificationClassIn,
                identificationNumberIn,
              );
            }}
            inputProps={{
              width: 710,
            }}
            selectProps={{
              width: 240,
            }}
          /> */}
        </div>
        <div className="register_information_search_input_item">
          <span className="register_information_search_input_item_label">
            NO:
          </span>
          <Input
            placeholder=""
            readOnly={true}
            bordered={false}
            height={36}
            width={240}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="register_information">
      <div className="register_information_search">{renderSearch()}</div>
      <div className="register_information_form">
        <div className="register_information_form_content">
          <Block
            titleStyle={{ fontWeight: '600' }}
            title="登记信息"
            mainStyle={{ padding: '0 16px 0 40px' }}
            style={{ border: '1px solid transparent' }}
          >
            <VSControlledForm
              id="inpInpAdmissionForm"
              labelAlign="left"
              disabled={!currentRegisterPatientId}
              labelWrap
            >
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="0"
                columnCount={4}
                labelCol={labelColWidth[6]}
              >
                <VSFormItem
                  name={['name']}
                  label="姓名"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  rules={[
                    {
                      message: '姓名长度不合法',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ]}
                />

                <VSFormItem
                  name={['gender']}
                  label="性别"
                  fieldProps={{ disabled: true }}
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                />

                <VSFormItem
                  name={['birthday']}
                  label="出生日期"
                  fieldProps={{
                    disabled: true,
                    // width: 395
                    width: '100%',
                  }}
                  valueType="date"
                />

                <VSFormItem
                  name={['birthAddress']}
                  label="出生地"
                  valueType="address"
                  fieldProps={{
                    onChange: (v) => {
                      if (!inpInpAdmissionVo?.nativeAddress?.county) {
                        vsf.refs.inpInpAdmissionForm.setFieldsValue({
                          nativeAddress: { ...v },
                        });
                      }
                    },
                  }}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />

                <VSFormItem
                  name={['nativeAddress']}
                  label="籍贯"
                  valueType="address"
                  // fieldProps={{ width: '100%' }}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />

                <VSFormItem
                  name={['idNumber']}
                  label="身份证号"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      pattern:
                        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                    },
                  ]}
                  fieldProps={{}}
                />

                <VSFormItem
                  name={['citizenship']}
                  label="国籍"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  dataSource={vsf.stores.dbenums.enums.COUNTRY_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}
                />

                <VSFormItem
                  name={['nation']}
                  label="民族"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  dataSource={vsf.stores.dbenums.enums.NATION_DICT}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['job']}
                  label="职业"
                  valueType="select"
                  fieldProps={{ showSearch: true }}
                  dataSource={vsf.stores.dbenums?.enums?.OCCUPATION_DICT}
                />
                <VSFormItem
                  name={['marriageStatus']}
                  label="婚姻"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.MARITAL_STATUS_DICT}
                  fieldProps={{ showSearch: true }}
                />

                {/* <VSFormItem
                  name={['phoneNumber']}
                  label="联系电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 40,
                    },
                  ]}
                  fieldProps={{}}
                /> */}

                <VSFormItem
                  name={['cellphone']}
                  label="手机号"
                  valueType="text"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  fieldProps={{}}
                />
              </VSFormLayout>
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="1"
                columnCount={2}
                labelCol={labelColWidth[6]}
              >
                <VSFormLayout
                  gutter={isMinScreen ? 40 : 100}
                  key="5"
                  columnCount={2}
                  labelCol={labelColWidth[6]}
                >
                  <VSFormItem
                    name={['currentAddress']}
                    label="现住址"
                    valueType="address"
                    fieldProps={{
                      width: '100%',
                    }}
                    convertValue={(v) => {
                      return {
                        ...v,
                        district: v?.district ? v?.district : v?.county,
                      };
                    }}
                  />
                  <VSFormItem
                    name={['currentAddress', 'detail']}
                    style={{ marginLeft: isMinScreen ? '-40px' : '-100px' }}
                    valueType="text"
                    fieldProps={{
                      width: '100%',
                      suffix: (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const formInfo =
                              vsf?.refs?.inpInpAdmissionForm?.getFieldsValue();
                            const _address = formInfo?.currentAddress
                              ? formInfo?.currentAddress?.province +
                                formInfo?.currentAddress?.city +
                                formInfo?.currentAddress?.district
                              : '';
                            const _detail = formInfo?.durationTimeUnit ?? '';
                            const address = _address + _detail;
                            handelCopy(address);
                          }}
                        >
                          <Icon size={18} type="icon-fuzhi"></Icon>
                        </div>
                      ),
                    }}
                  />
                </VSFormLayout>
                <VSFormLayout
                  gutter={isMinScreen ? 40 : 100}
                  key="1"
                  columnCount={2}
                  labelCol={labelColWidth[6]}
                >
                  <VSFormItem
                    name={['currentAddress', 'postcode']}
                    label="邮编"
                    valueType="text"
                    fieldProps={{
                      suffix: (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const formInfo =
                              vsf?.refs?.inpInpAdmissionForm?.getFieldsValue();
                            // ?.durationTimeUnit;city district province

                            const copyCellphone = formInfo?.cellphone;
                            handelCopy(copyCellphone ?? '');
                          }}
                        >
                          <Icon size={18} type="icon-fuzhi"></Icon>
                        </div>
                      ),
                    }}
                  />
                </VSFormLayout>
              </VSFormLayout>
              <div className="register_information_form_content_divider_style"></div>
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="1"
                columnCount={4}
                labelCol={labelColWidth[6]}
              >
                <VSFormItem
                  name={['chargeType']}
                  label="费别"
                  valueType="select"
                  fieldProps={{
                    showSearch: true,
                    onClear: () => {
                      vsf.refs.inpInpAdmissionForm.setFieldsValue({
                        identityCode: '',
                      });
                    },
                    onChange: (v) => {
                      setChargeTypeCode(v);
                      vsf.refs.inpInpAdmissionForm.setFieldsValue({
                        identityCode: handelFilter(v)?.[0]?.value,
                      });
                    },
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                />
                <VSFormItem
                  name={['identityCode']}
                  label="人员类别"
                  valueType="select"
                  fieldProps={{ showSearch: true }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  // dataSource={handelFilter(chargeTypeCode)}
                  dataSource={chargeTypeData}
                />
                {/* <VSFormDependency name={['chargeType']}>
                  {(data) => {
                    return (
                      <VSFormItem
                        name={['identityCode']}
                        label="人员类别"
                        valueType="select"
                        fieldProps={{ showSearch: true }}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        dataSource={handelFilter(data?.chargeType)}
                      />
                    );
                  }}
                </VSFormDependency> */}

                <VSFormItem
                  name={['visitType']}
                  label="住院类型"
                  valueType="select"
                  dataSource={[
                    { label: '普通', value: 'NORMAL' },
                    { label: '预住院', value: 'PRE_IN_HOSPITAL' },
                    { label: '日间手术', value: 'DAY_SURGERY' },
                  ]}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['unitInContract']}
                  label="合同单位"
                  fieldProps={{ disabled: true }}
                  valueType="check"
                  initialValue={false}
                  rules={[{ required: true }]}
                />
                <VSFormItem
                  name={['workUnit', 'name']}
                  label="工作单位"
                  fieldProps={{
                    disabled:
                      inpInpAdmissionVo?.unitInContract ||
                      !currentRegisterPatientId
                        ? true
                        : false,
                  }}
                />
                <VSFormItem
                  name={['workUnit', 'phoneNumber']}
                  label="单位电话"
                  fieldProps={{
                    disabled:
                      inpInpAdmissionVo?.unitInContract ||
                      !currentRegisterPatientId
                        ? true
                        : false,
                  }}
                />
                <VSFormItem
                  name={['workUnit', 'address']}
                  label="单位地址"
                  valueType="address"
                  fieldProps={{
                    disabled:
                      inpInpAdmissionVo?.unitInContract ||
                      !currentRegisterPatientId
                        ? true
                        : false,
                  }}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />
                <VSFormItem
                  name={['contactPersonName']}
                  label="陪护姓名"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ]}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['contactPersonRelationship']}
                  label="与患者关系"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['contactPersonPhone']}
                  label="陪护电话"
                  valueType="text"
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['contactPersonAddress']}
                  label="陪护人地址"
                  valueType="address"
                  fieldProps={{}}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />
                <VSFormItem
                  style={{ marginLeft: isMinScreen ? '-40px' : '-100px' }}
                  name={['contactPersonAddress', 'detail']}
                  valueType="text"
                  fieldProps={{}}
                />
              </VSFormLayout>
              <div className="register_information_form_content_divider_style"></div>
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="2"
                columnCount={4}
                labelCol={labelColWidth[6]}
              >
                <VSFormItem
                  name={['patientClass']}
                  label="入院途径"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.PATIENT_CLASS_DICT}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  fieldProps={{ showSearch: true }}
                />

                <VSFormItem
                  name={['admissionPurpose']}
                  label="目的"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  dataSource={vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['patientAdmissionCondition']}
                  label="病情"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  dataSource={vsf.stores.dbenums.enums.PAT_ADM_CONDITION_DICT}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['admissionDateTime']}
                  label="入院时间"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  valueType="date"
                  dataFormat="YYYY-MM-DD HH:mm:ss"
                  // initialValue={dayjs().format('YYYY-MM-DD HH:mm:ss')}
                  fieldProps={{
                    width: '100%',
                    showTime: true,
                    disabledDate: (time) => {
                      return (
                        // time < dayjs(new Date(inpInpAdmissionVo?.applyDate)) ||
                        // time > dayjs().endOf('day')
                        time > dayjs().add(1, 'day').startOf('date') ||
                        time < dayjs(inpInpAdmissionVo?.applyDate)
                        // time > dayjs().endOf('day')
                        // // time < dayjs(inpInpAdmissionVo?.applyDate)
                      );
                    },
                    onChange: (time) => {
                      if (time) {
                        if (time <= dayjs(inpInpAdmissionVo?.applyDate)) {
                          message.info('当前选择时间小于入院申请时间');
                          vsf?.refs?.inpInpAdmissionForm?.setFieldsValue({
                            admissionDateTime: '',
                          });
                          return false;
                        }
                      }
                    },

                    // onOk: (time) => {
                    //   console.log(time);
                    //   if (time <= dayjs(inpInpAdmissionVo?.applyDate)) {
                    //     message.info('当前选择时间大于入院申请时间');
                    //   }
                    // },
                    // width: 395,
                  }}
                />
                <VSFormItem
                  name={['admissionToDepartment']}
                  label="入院科室"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  fieldProps={{
                    showSearch: true,
                    onClear: () => {
                      vsf.refs.inpInpAdmissionForm.setFieldsValue({
                        ward: '',
                        medicalGroupLeader: '',
                      });
                    },
                    onSelect: (v, p) => {
                      // setDepartment(v);
                      vsf?.refs?.inpInpAdmissionForm?.setFieldsValue({
                        ward: '',
                        medicalGroupLeader: '',
                      });
                    },
                  }}
                  fieldNames={{ label: 'departmentName', value: 'id' }}
                  searchKey="nameLike"
                  dataSource={getPagedBySearchCode}
                  transform={(v) => {
                    setDepartment(v);
                    return {
                      admissionFromDepartment: {
                        ...v,
                      },
                    };
                  }}
                />
                <VSFormItem
                  name={['ward']}
                  label="护理单元"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  searchKey="searchCode"
                  fieldNames={{ label: 'departmentName', value: 'id' }}
                  dataSource={getPagedByDepartmentCode}
                  fieldProps={{
                    showSearch: true,
                  }}
                  transform={(v) => {
                    return {
                      ward: { ...v },
                    };
                  }}
                />
                <VSFormItem
                  name={['medicalGroupLeader']}
                  label="医疗组长"
                  fieldProps={{ showSearch: true }}
                  dataSource={getUserGroupController}
                  valueType="select"
                  fieldNames={{ label: 'staffName', value: 'id' }}
                  transform={(v) => {
                    return { medicalGroupLeader: { ...v } };
                    // return {
                    // medicalGroupLeader: {
                    //   id:
                    //     v?.value ??
                    //     AdmissionInfo?.inpAdmissionApplyVo?.medicalGroupLeader
                    //       ?.id,
                    //   staffName:
                    //     v?.label ??
                    //     inpInpAdmissionVo?.inpAdmissionApplyVo?.medicalGroupLeader
                    //       ?.staffName,
                  }}
                />
                <VSFormItem
                  name={['virtualInHospitalIndication']}
                  label="虚拟住院"
                  valueType="check"
                  // initialValue={false}
                  fieldProps={{}}
                />
              </VSFormLayout>
              <div className="register_information_form_content_divider_style"></div>
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="3"
                columnCount={4}
                labelCol={labelColWidth[6]}
              >
                <VSFormItem
                  name={['applyDoctor', 'staffName']}
                  label="接诊医生"
                  valueType="text"
                  // fieldNames={{ label: 'staffName', value: 'id' }}
                  fieldProps={{
                    disabled: true,
                  }}
                />
                <VSFormItem
                  name={['visitStartDate']}
                  label="接诊时间"
                  fieldProps={{
                    disabled: true,
                    width: '100%',
                  }}
                  valueType="date"
                  rules={[
                    {
                      // required: true,
                    },
                  ]}
                />
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="虚拟住院类型"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SIMULATION_TYPE_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}
                />

                {/* <VSFormItem
                  name={['virtualInHospitalType']}
                  label="少医疗类别"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SIMULATION_TYPE_DICT}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="少病种列表"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SIMULATION_TYPE_DICT}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="少担保人"
                  valueType="text"
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="少担保人关系"
                  valueType="text"
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="少担保人电话"
                  valueType="text"
                  fieldProps={{}}
                /> */}
              </VSFormLayout>
              <VSFormLayout
                gutter={isMinScreen ? 40 : 100}
                key="20"
                columnCount={4}
                labelCol={labelColWidth[6]}
              >
                <VSFormItem
                  name={['diagnosisList']}
                  label="门诊诊断"
                  valueType="select"
                  fieldProps={{
                    disabled: true,
                    tagRender: tagRender,
                    mode: 'multiple',
                    allowClear: true,
                  }}
                  convertValue={(v) => {
                    console.log(v);

                    return (
                      v?.map((item) => {
                        return item?.diagnosis?.diagnosisName;
                      }) ?? []
                    );
                  }}
                />
              </VSFormLayout>
            </VSControlledForm>
          </Block>
        </div>
        <div className="register_information_form_bottom">
          <Button
            onClick={() => {
              // handelClear();
              // setCurrentRegisterPatientId('');
              // setSearchDisplayId('');
              // vsf?.refs?.inpInpAdmissionForm.resetFields();
              // console.log(111);

              window.location.reload();
            }}

            // className="register_information_form_bottom_btn1 register_information_form_bottom_btn"
          >
            清屏
          </Button>
          <Button
            className="marginLeft16"
            type="primary"
            // className="register_information_form_bottom_btn2 register_information_form_bottom_btn"
          >
            打印腕带
          </Button>
          <Button
            className="marginLeft16"
            type="primary"
            onClick={handelSave}
            // className="register_information_form_bottom_btn2 register_information_form_bottom_btn"
          >
            入院登记
          </Button>
        </div>
      </div>
      <MyModal
        ref={modalRef}
        getPatientInfo={getPatientInfo}
        tableList={tableList}
      />
    </div>
  );
};
export default definePage(Index);

// 登记信息
