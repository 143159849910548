import vsf, { definePage } from '@vs/vsf-boot';
import { Tabs, message } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import StandardApplicationTree from './tree';
import ModalEdit from './edit';
import StandardApplicationParameter from './parameter';
import StandardApplicationStaff from './staff/table';
import Container from '@/module/Container';
import '../index.less';
import { useFetch } from '@/pages/Index/components/func/hooks';
const StandardApplication = (props) => {
  /**
   * application
   * @type {object}
   */
  const [application, setApplication] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [addNode, setAddNode] = useState(false);

  const onChange = () => {};

  const handleAdd = (node) => {
    setAddNode(node);
    setAddOpen(true);
  };

  const handleDel = useFetch({
    fetch: async (node) => {
      const res =
        await vsf?.services?.ApplicationBOController_deleteApplication_cc9a1c({
          btoParam: {
            id: node?.id,
          },
          extApiParam: {},
        });
      return res;
    },
    success: () => {
      vsf?.refs?.StandardApplicationTree?.handleReload?.()
    },
    message: {
      loading: '删除中',
      error: '删除失败',
      success: '删除成功',
    }
  });

  const handleEdit = (node, dataSource) => {

    setAddNode(node);
    setAddOpen(true);
  };

  const handleCloseWithReload = () => {
    vsf?.refs?.StandardApplicationTree?.handleReload();
    handleClose();
  };

  const handleClose = () => {
    setAddOpen(false);
  };

  const items = [
    {
      key: 'function',
      label: `人员设置`,
      children: <StandardApplicationStaff application={application} />,
    },
    {
      key: 'parameter',
      label: `参数设置`,
      children: <StandardApplicationParameter application={application} />,
    },
  ];

  return (
    <>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              block: '392px',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '12px 24px',
            },
          ],
        }}
      >
        <StandardApplicationTree
          onChange={(_value) => {
            setApplication({ ..._value });
          }}
          onAdd={handleAdd}
          onDel={handleDel}
          onEdit={handleEdit}
        />
        <Tabs
          className="aspirin-tab aspirin-application-tab aspirin-tabs-style-box"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Container>
      <ModalEdit
        open={addOpen}
        value={addNode}
        handleClose={handleClose}
        handleCloseWithReload={handleCloseWithReload}
      />
    </>
  );
};
export default definePage(StandardApplication);
