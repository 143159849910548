import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903: {
    method: 'post',
    url: '/api/clinic-bed/query-pagefall-by-clinic-bed-with-patient-qto',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
      ext: {
        statisticsCode: string;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedWithPatientVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicInpVisitRecordController_updateInpEscortRegisterPrintInfo_fa160a: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/update-batch-inp-escort-register-print-info',
    parameterFomatter: (data?: { idList: number[]; extApiParam: any }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpEscortRegisterVoClinicBedEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitRecordController_saveInpEscortRegister_01cd8c: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/save-inp-escort-register',
    parameterFomatter: (data?: {
      btoParam: SaveInpEscortRegisterBtoClinicInpVisitRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpEscortRegisterVoClinicBedEntranceWebVo,
    ) => data,
  },
  ClinicInpVisitRecordController_getAllByInpEscortRegisterActiveQto_0999ae: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/get-all-by-inp-escort-register-active-qto',
    parameterFomatter: (data?: {
      qto: InpEscortRegisterActiveQtoClinicInpVisitRecordPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpEscortRegisterVoClinicBedEntranceWebVo[],
    ) => data,
  },
  ClinicInpVisitRecordController_deleteInpEscortRegister_831020: {
    method: 'post',
    url: '/api/clinic-inp-visit-record/delete-inp-escort-register',
    parameterFomatter: (data?: {
      btoParam: DeleteInpEscortRegisterBtoClinicInpVisitRecordServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
