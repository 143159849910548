import vsf from '@vs/vsf-boot';
import dayjs from 'dayjs';
// 获取药品详情
export const getByDrugPriceItemId = async (params) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_getByDrugPriceItemId_8fd8db?.(
      { drugPriceItemId: params?.drugPriceItemId },
    );
  const _ = window._ ?? {};
  // return res?.data?.map((e) => {});
  return {
    drugProductName: _.get(
      res?.data,
      'drugPriceItem.drugProduct.drugProductName',
    ), //商品名称
    packageSpecification: _.get(
      res?.data,
      'drugPriceItem.packageSpecification',
    ), //包装规格
    firmName: _.get(
      res?.data,
      'drugPriceItem.drugProduct.drugFirmInfo.firmName',
    ), //厂家
    totalAmount: _.get(res?.data, 'totalAmount'), // 全院库存
    storageAmount: _.get(res?.data, 'storageAmount'), // 库存量
    consumeAmount: _.get(res?.data, 'consumeAmount'), // 消耗量
    amount: _.get(res?.data, 'amount'), // 采购数量
    packageUnit: _.get(res?.data, 'drugPriceItem.packageUnit'), // 包装单位
    lowLevel: _.get(res?.data, 'lowLevel'), // 下限
    memo: _.get(res?.data, 'memo'), // 备注
    price: _.get(res?.data, 'drugPriceItem.price'), // 单价
    drugItemId: _.get(res?.data, 'drugPriceItem.drugItemId'), // 药品项目id
    drugProductId: _.get(res?.data, 'drugPriceItem.drugProduct.id'), // 药品产品id
    drugPriceItemId: _.get(res?.data, 'drugPriceItem.id'), // 药品价格id
  };
};
// 提交
export const submitPurchasePlan = async (event) => {
  // const master = {
  //   ...props?.value,
  //   memo: vsf?.refs?.formHerbPurchasePlanDetailList?.getFieldFormatValue(
  //     'memo',
  //   ),
  //   planType:
  //     vsf?.refs?.formHerbPurchasePlanDetailList?.getFieldFormatValue(
  //       'planType',
  //     ),
  //   drugPurchasePlanDetail:
  //     vsf?.refs?.tableHerbPurchasePlanDetailList?.getValues?.() ?? [],
  // };
  const res =
    await vsf?.services?.DrugStoragePurchaseController_submitPurchasePlan_a9b0a3?.(
      { btoParam: { planType: 'HERB_PURCHASE', ...event } },
    );
  return res;
};
// 保存
export const savePurchasePlan = async (event) => {
  // const master = {
  //   ...props?.value,
  //   memo: vsf?.refs?.formHerbPurchasePlanDetailList?.getFieldFormatValue(
  //     'memo',
  //   ),
  //   planType:
  //     vsf?.refs?.formHerbPurchasePlanDetailList?.getFieldFormatValue(
  //       'planType',
  //     ),
  //   drugPurchasePlanDetail:
  //     vsf?.refs?.tableHerbPurchasePlanDetailList?.getValues?.() ?? [],
  // };
  const res =
    await vsf?.services?.DrugStoragePurchaseController_savePurchasePlan_df26d5?.(
      { btoParam: { planType: 'HERB_PURCHASE', ...event } },
    );
  return res;
};
/**
 * 	药品采购计划生成：获取保存状态的药品采购计划明细
 *  */
export const getPlanDetailByPlanMasterId = async (params) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_getPlanDetailByPlanMasterId_e03c43?.(
      { planMasterId: params?.value?.id },
    );
  return convertGetPlanDetailByPlanMasterIdRes2TableHerbPurchasePlanDetailList?.(
    res,
  );
};
/**
 * convertGetPlanDetailByPlanMasterIdRes2TableHerbPurchasePlanDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetPlanDetailByPlanMasterIdRes2TableHerbPurchasePlanDetailList = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.type = 'save';
      obj.id = _.get(d, 'id'); // TODO 请确认下行字段是否符合逻辑
      obj.drugPriceItemId = _.get(d, 'drugPriceItem.id'); // TODO 请确认下行字段是否符合逻辑
      obj.drugProductName = _.get(
        d,
        'drugPriceItem.drugProduct.drugProductName',
      ); // TODO 请确认下行字段是否符合逻辑
      obj.packageSpecification = _.get(d, 'drugPriceItem.packageSpecification'); // TODO 请确认下行字段是否符合逻辑
      obj.firmName = _.get(
        d,
        'drugPriceItem.drugProduct.drugFirmInfo.firmName',
      );
      obj.totalAmount = _.get(d, 'totalAmount');
      obj.storageAmount = _.get(d, 'storageAmount');
      obj.consumeAmount = _.get(d, 'consumeAmount');
      obj.amount = _.get(d, 'amount'); // TODO 请确认下行字段是否符合逻辑
      obj.packageUnit = _.get(d, 'drugPriceItem.packageUnit');
      obj.lowLevel = _.get(d, 'lowLevel');
      obj.memo = _.get(d, 'memo');
      obj.price = _.get(d, 'drugPriceItem.price');
      obj.drugItemId = _.get(d, 'drugPriceItem.drugItemId');
      return obj;
    }) ?? []),
  );
  return output;
};
/**
 *
 * * 按库房获取有库存定义、有库存数据的有效的已维护在用规格的药品信息,支持分页 * 药品价表、商品、厂家、库存定义
 *  */
export const getPagedProfileDrugProduct = async (param) => {
  const res =
    await vsf?.services?.DrugDrugController_getPagedProfileDrugProduct_9f4f4f?.(
      {
        query: {
          // from: param?.pagination?.from ?? 0,
          // size: param?.pagination?.size ?? 8,
          // inputCodeLike: '',
          // herbType:
          //   vsf?.refs?.formHerbPurchasePlanDetailList?.getFieldFormatValue?.(
          //     'herbType',
          //   ),
          ...param,
        },
      },
    );
  return convertGetPagedProfileDrugProductRes2TableHerbPurchasePlanDetailList?.(
    res,
  );
};
/**
 * convertGetPagedProfileDrugProductRes2TableHerbPurchasePlanDetailList
 * @param {any} input
 * @returns {any}
 */
const convertGetPagedProfileDrugProductRes2TableHerbPurchasePlanDetailList = (
  input,
) => {
  return (
    input?.data?.result?.map((m) => ({
      id: m.id,
      drugProductName: m.drugProduct?.drugProductName, //药品名称
      firmName: m.drugProduct?.drugFirmInfo?.firmName, //厂家
      packageSpecification: m.packageSpecification, //规格
      // packageUnit: m.packageUnit, //单位
      // lowLevel: m.lowLevel, //下限
    })) ?? []
  );
};
// **药品采购计划生成：获取采购计划主记录
export const getByMasterId = async (params) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_getByMasterId_0d5695?.({
      masterId: params?.value?.id,
    });
  return res;
};

// 生成采购计划
export const createPurchasePlans = async (event) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_createPurchasePlans_935974?.(
      {
        eo: {
          ...event,
        },
      },
    );
  if (res?.code != 200) return false;
  return convertCreatePurchasePlansRes2TableHerbPurchasePlanDetailList?.(res);
};
/**
 * convertCreatePurchasePlansRes2TableHerbPurchasePlanDetailList
 * @param {any} input
 * @returns {any}
 */
const convertCreatePurchasePlansRes2TableHerbPurchasePlanDetailList = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.type = 'new';
      // obj.id = _.get(d, 'id');
      obj.drugProductName = _.get(
        d,
        'drugPriceItem.drugProduct.drugProductName',
      );
      obj.packageSpecification = _.get(d, 'drugPriceItem.packageSpecification');
      obj.firmName = _.get(
        d,
        'drugPriceItem.drugProduct.drugFirmInfo.firmName',
      );
      obj.totalAmount = _.get(d, 'totalAmount');
      obj.storageAmount = _.get(d, 'storageAmount');
      obj.consumeAmount = _.get(d, 'consumeAmount');
      obj.amount = _.get(d, 'amount');
      obj.packageUnit = _.get(d, 'drugPriceItem.packageUnit');
      obj.lowLevel = _.get(d, 'lowLevel');
      obj.memo = _.get(d, 'memo');
      obj.price = _.get(d, 'drugPriceItem.price');
      obj.drugItemId = _.get(d, 'drugPriceItem.drugItemId');
      obj.drugPriceItemId = _.get(d, 'drugPriceItem.id'); // TODO 请确认下行字段是否符合逻辑
      return obj;
    }) ?? []),
  );
  return output;
};

// 获取草药采购单保存记录
export const getHerbSavePurchasePlanMasterList = async (params) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_getHerbSavePurchasePlanMasterList_e4f9ee?.(
      {},
    );
  []?.sort;
  return {
    data: convertGetHerbSavePurchasePlanMasterListRes2TableSavedHerbPurchasePlanList?.(
      res,
    )?.sort(
      (a, b) =>
        dayjs?.(b?.planCreateDate)?.valueOf?.() -
        dayjs?.(a?.planCreateDate)?.valueOf?.(),
    ),
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetHerbSavePurchasePlanMasterListRes2TableSavedHerbPurchasePlanList
 * @param {any} input
 * @returns {any}
 */
const convertGetHerbSavePurchasePlanMasterListRes2TableSavedHerbPurchasePlanList =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.purchasePlanNumber = _.get(d, 'purchasePlanNumber');
        obj.planCreateDate = _.get(d, 'planCreateDate');
        obj.herbType = _.get(d, 'herbType');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 删除采购单
export const deleteSavePurchase = async (event) => {
  const res =
    await vsf?.services?.DrugStoragePurchaseController_deleteSavePurchase_374f52?.(
      { btoParam: { id: event } },
    );
  return res;
};
