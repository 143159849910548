import vsf from '@vs/vsf-boot';
import { Modal, VSForm, VSFormItem } from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import Icon from '@/module/Icon';

import { askRomanAlphabet, askTree } from '../../../func/ask';
import { useFetch } from '../../../func/hooks';
const SaveAsTemplate = forwardRef((props, ref) => {
  const { outVisitInfo } = vsf.stores?.user || {};

  const [open, setOpen] = useState(false);

  const { data: dataSource, run: onFetch } = useRequest(
    async () => {
      if (open) {
        const res =
          await vsf.services?.OrderTemplateMasterController_getOrderTemplateMaster_bfb379?.(
            {
              templateClass: 'PERSONAL_TEMPLATE',
              agreedPrescriptionIndicator: false,
            },
          );
        if (res?.data?.length) {
          const arr = res?.data?.map((item) => ({
            icon:
              item?.folderOrTemplateIndicator === 'TEMPLATE' ? (
                <Icon type="icon-wenjian" size={15} />
              ) : (
                <Icon type="icon-wenjianjia" size={15} />
              ),
            ...item,
            upTemplateId: item?.upTemplateId === 1 ? null : item?.upTemplateId,
          }));
          return askTree(arr, { parentId: 'upTemplateId', id: 'id' });
        }
        return [
          {
            title: '顶级目录',
            id: null,
            upTemplate: null,
            key: '<top>',
          },
        ];
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    onFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onOk = useFetch({
    fetch: async () => {
      const value =
        await vsf?.refs?.TemplateTable?.validateFieldsReturnFormatValue();
      const res =
        await vsf.services?.OrderTemplateController_saveOrderAsTemplate_47eede?.(
          {
            ...value,
            outpVisitEncounterId: outVisitInfo?.id,
          },
        );
      return res;
    },
    message: '存为模板',
    success: () => {
      setOpen(false);
    },
  });

  useImperativeHandle(ref, () => ({
    onOpen: () => {
      setOpen(true);
    },
  }));

  return (
    <Modal
      open={open}
      title="存为模版"
      onCancel={() => setOpen(false)}
      onOk={onOk}
      cancelText="取消"
      okText="确定"
    >
      <VSForm id="TemplateTable">
        <VSFormItem
          valueType="treeSelect"
          name="upTemplateId"
          label="模版分类"
          dataSource={dataSource}
          fieldNames={{
            label: 'title',
            value: 'id',
          }}
          transform={(value) => ({
            upTemplateId: value?.id,
          })}
          fieldProps={{
            allowClear: true,
            treeDefaultExpandAll: true,
            showSearch: true,
            treeIcon: true,
          }}
        />
        <VSFormItem
          valueType="text"
          name="templateName"
          label="模版名称"
          fieldProps={{
            onChange: (event) => {
              vsf?.refs?.TemplateTable?.setFieldValue(
                'inputCode',
                askRomanAlphabet(event?.target?.value),
              );
            },
          }}
        />
        <VSFormItem
          valueType="text"
          name="inputCode"
          label="拼音输入码"
          hidden
        />
      </VSForm>
    </Modal>
  );
});

SaveAsTemplate.displayName = 'SaveAsTemplate';

export default SaveAsTemplate;
