import { getPinYinCode, getWubiCode } from 'lancet-pywb/PY_WB';
import { cloneDeep, get } from 'lodash';

/**
 * 从 field 中获取 指定 key 的值
 * @param key
 * @param fieldNames 映射
 * @param defaultFieldNames 默认
 * @returns
 */
export const getFieldNamesKey = (key, fieldNames, defaultFieldNames) => {
  if (!defaultFieldNames?.[key]) {
    return;
  }
  return fieldNames?.[key] ?? defaultFieldNames?.[key];
};

/**
 * 从数据源中获取 fieldnames 映射的值
 * @param data
 * @param key
 * @returns
 */
export const getValueWithFieldNames = (data, key) => {
  return get(data, key);
};

/**
 * 数据转换
 * @param data 数据源
 * @param fieldNames 映射select fieldNames
 * @param defaultFieldNames 默认select fieldNames
 * @param loadDataKey 指定数据源需要返回的数据key
 * @param customGenObj 自定义如何渲染一个item, 比fieldNames更加灵活
 * @field [node: 当前节点, rootData: 根节点信息(loadDataKey设置之后的本身信息), parentData: 父节点信息(combineDataKey设置之后的本身信息)]
 * @returns
 */
export const convertData = ({
  data,
  fieldNames,
  defaultFieldNames,
  loadDataKey,
  combineDataKey,
  customGenObj,
}) => {
  let target = cloneDeep(data);
  let targetWithRootData = cloneDeep(data);
  if (!Array.isArray(data) && loadDataKey) {
    target = getValueWithFieldNames(cloneDeep(data), loadDataKey);
    if (!Array.isArray(target)) {
      return [];
    } else {
      const newData = cloneDeep(data);
      delete newData?.[loadDataKey];
      targetWithRootData = target?.map((item) => ({
        ...item,
        rootData: newData,
      }));
    }
  }

  let res = cloneDeep(data);

  if (combineDataKey) {
    res = targetWithRootData.reduce((result, obj) => {
      if (obj?.[combineDataKey]) {
        return result.concat(
          obj?.[combineDataKey]?.map((item) => {
            const newObj = cloneDeep(obj);
            delete newObj?.[combineDataKey];
            const { rootData, ...rest } = newObj;
            return {
              ...item,
              parentData: rest,
              rootData,
            };
          }),
        );
      }
      return result;
    }, []);
  }

  const fieldLabel = getFieldNamesKey('label', fieldNames, defaultFieldNames);
  const fieldValue = getFieldNamesKey('value', fieldNames, defaultFieldNames);

  const renderItem = (res) => {
    return cloneDeep(res || [])?.map((item) => {
      const { rootData, parentData, ...rest } = item;
      if (customGenObj) {
        return customGenObj(rest, parentData, rootData);
      }
      return {
        label: getValueWithFieldNames(item, fieldLabel),
        value: `${getValueWithFieldNames(item, fieldValue)}`,
        origin: item,
        children: renderItem(item?.children),
      };
    });
  };

  const formatData = renderItem(res);

  return formatData;
};

type Type = {
  wubi: string;
  pinyin: string;
  all: [Type['pinyin'], Type['wubi']];
};
/**
 * 获取汉字罗马字母的函数
 * @param str 汉字
 * @param type 返回类型五笔/拼音/全部
 * @returns 返回根据 type 来
 */
export function askRomanAlphabet(
  str: string,
  type: keyof Type = 'pinyin',
  character = true,
) {
  const int: string = getPinYinCode(str);
  const pinyin = character ? int?.split(',')?.[0] ?? '' : int;
  switch (type) {
    case 'all':
      return [pinyin, getWubiCode(str)];
    case 'pinyin':
      return pinyin;
    case 'wubi':
      return getWubiCode(str);
  }
}

export function askSearch(value: string, option: any, label = 'label') {
  const [pinyin, wubi] = askRomanAlphabet(String(option?.[label]), 'all');
  const filter = (text) => {
    return String(text)
      ?.replace(/\s*/g, '')
      ?.toLocaleLowerCase()
      ?.includes(value?.toLocaleLowerCase()?.replace(/\s*/g, ''));
  };
  return (
    String(option?.[label]).includes(value) || filter(pinyin) || filter(wubi)
  );
}
/**
 * 高亮显示
 * @returns <span class="aspirin-conspicuous-box">xxx</span>
 */
function conspicuous() {
  return '';
}

askSearch.conspicuous = conspicuous;

export const getMapKeyValue = (data, keyMap) => {
  if (Array.isArray(keyMap)) {
    const result = keyMap
      ?.map((key) => data?.[key])
      ?.filter((v) => v !== undefined);
    return result?.[0];
  } else {
    return data?.[keyMap];
  }
};

const isFalsy = (v) => {
  return v === undefined || v === null;
};

/**
 * 一维对象数组 => 树状结构
 * @param arr
 * @param parentId
 * @param keyMap id parentId 映射
 * @returns
 */
export const buildTree = (
  data,
  keyMap = {
    id: 'id',
    parentId: 'parentId',
  },
  isExpandLoadData,
) => {
  const tree = {};
  const map = {};
  const otherArr: any[] = [];

  data.forEach((item) => {
    const id = getMapKeyValue(item, keyMap?.id);
    const parentId = getMapKeyValue(item, keyMap?.parentId);
    // const { [keyMap?.id]: id, [keyMap?.parentId]: parentId } = item;
    const isLeaf = isExpandLoadData ? false : true;
    if (!isFalsy(id)) {
      map[id] = { ...item, isLeaf, children: [] };
    } else {
      otherArr.push(item);
    }
  });

  [...Object.values(map), ...otherArr].forEach((item) => {
    const parentId = getMapKeyValue(item, keyMap?.parentId);
    const id = getMapKeyValue(item, keyMap?.id);
    // const parentId = item?.[keyMap?.parentId];
    // 是否有parentId, 且排除自身id跟parentId相同的情况
    const isValidParentId = parentId && parentId !== id;
    if (isValidParentId) {
      if (map[parentId]) {
        map[parentId].children.push(item);
        map[parentId].isLeaf = false;
      } else {
        console.error('找不到父元素');
      }
    } else {
      tree[getMapKeyValue(item, keyMap?.id)] = item;
    }
  });

  return Object.values(tree);
};
