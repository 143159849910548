import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Menu, VSPromiseTable, VSTable, VSTableColumn } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { debounce, isObject } from 'lodash';
import { any } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { user } from '@/stores';

const ModalComTemplate = (props) => {
  const { onChange, handelTableSelect } = props;
  const [patternName, setPatternName] = useState<any>([]);
  const [currentSelectKey, setCurrentSelectKey] = useState<any>();
  const [tableList, setTableList] = useState<any>();
  //模板-选择行
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [currentTableRow, setCurrentTableRow] = useState<any>();
  const templateTableRef = useRef<any>();
  useEffect(() => {
    getAllByPricePattern();
  }, []);

  useEffect(() => {
    if (!currentSelectKey) return;
    handelSelect(currentSelectKey);
  }, [currentSelectKey]);

  const getAllByPricePattern = async () => {
    const res =
      await vsf.services?.PriceListController_getAllByPricePatternQto_8ee5de?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          patternRangeTypeIn: [],
        },
      });
    if (res?.data?.length > 0 && res?.code === 200) {
      const _res = res?.data?.map((item) => {
        return { key: item?.id, label: item?.patternName };
      });
      setCurrentSelectKey(_res?.[0]?.key);
      setPatternName(_res);
    } else {
      setPatternName([]);
    }
  };
  // table
  const handelSelectTableRow = useCallback(
    (v) => {
      handelTableSelect(v);
      // handelTableSelect(templateTableRef);
      // setCurrentTableRow(v);
    },
    [handelTableSelect],
  );

  const getTableList = useCallback(async () => {
    if (!currentSelectKey) return [];
    const res =
      await vsf.services?.PriceListController_getByPricePatternByPricePatternMasterId_0dd128?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          pricePatternMasterId: currentSelectKey,
        },
      ); // TODO: 你可能需要整理返回数据格式};
    if (res?.code === 200) {
      // setCurrentTableRow(res?.data?.[0]);
      // handelSelectTableRow(res?.data?.[0]);
      const _res = res?.data?.map((item, index) => {
        return { id: currentSelectKey + index, ...item };
      });
      // handelSelectTableRow(res?.data?.[0]);
      setTableList(_res ?? []);
      return _res;
    }
  }, [currentSelectKey]);

  // useEffect(() => {
  //   getTableList();
  // }, [getTableList, currentSelectKey]);
  // menu
  const handelSelect = (v) => {
    setSelectedRows([]);
    setCurrentSelectKey(v);
  };

  return (
    <div className="charge_template_table">
      <VSPromiseTable
        ref={templateTableRef}
        className="aspirin-table aspirin-table-row-hover-background-color"
        scroll={{ y: 300 }}
        pagination={false}
        tableRender={(_, dom) => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 600,
            }}
          >
            <div
              className="hideScroll department_price_menu"
              style={{
                overflow: 'scroll',
                width: 327,
                height: '100%',
                borderRight: '1px solid #D6D8D9',
              }}
            >
              <Menu
                defaultOpenKeys={['sub1']}
                defaultSelectedKeys={[currentSelectKey]}
                mode="inline"
                selectedKeys={[currentSelectKey]}
                onSelect={(v) => {
                  handelSelect(v?.key);
                }}
                items={[
                  {
                    key: 'sub1',
                    label: (
                      <span>
                        <span style={{ fontSize: '16px' }}>住院收费使用</span>
                      </span>
                    ),
                    children: [...patternName],
                  },
                ]}
              />
            </div>
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                height: '100%',
                padding: 24,
                backgroundColor: '#f3f5f7',
              }}
            >
              {dom}
            </div>
          </div>
        )}
        onFetch={getTableList}
        // rowClassName={(v) => {
        //   return v?.priceItemVo?.id === currentTableRow?.priceItemVo?.id
        //     ? 'aspirin-table-row-select-background-color'
        //     : '';
        // }}
        rowClick={{
          rowClickType: 'select',
          // onRowClick: (v) => {
          //   handelSelectTableRow(v);
          // },
        }}
        rowSelection={{
          selectType: 'multiple',
          selectedRows,
          onSelectedRowsChange: (data) => {
            props.setTemplates(
              data.map((m, i) => {
                return {
                  unit: m?.priceItemVo?.unit, // 单位
                  amount: m?.pricePatternDetailVo?.amount, // 数量
                  insuranceCode: m?.priceItemVo?.insuranceNationCode, // 医保编码
                  itemClass: m?.priceItemVo?.billingItem, // 价表项目分类
                  itemName: {
                    label: m?.priceItemVo?.itemName, // 项目名称
                    value: m?.priceItemVo?.itemId, // 项目编码
                  },
                  itemSpecification: m?.priceItemVo?.itemSpecification, // 项目规格
                  cost: (
                    parseFloat(m?.priceItemVo?.price) *
                    parseInt(m?.pricePatternDetailVo?.amount)
                  ).toFixed(2), // 应收费用
                  medicalInsuranceType: 'ALPHA', //InsuranceChargeTypeEnum, // 医保项目类别
                  price: m?.priceItemVo?.price, // 项目单价
                  TheRowType: 'Pattern', // 标识
                };
              }),
            );
          },
        }}
      >
        <VSTableColumn
          dataIndex={['priceItemVo', 'id']}
          title="主键"
          valueType="digit"
          fieldProps={{}}
          hideInTable={true}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'item_name长度不合法',
                type: 'string',
                min: 0,
                max: 200,
              },
            ],
          }}
          width={200}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'item_spec长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'brand']}
          title="品牌"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'brand长度不合法', type: 'string', min: 0, max: 80 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'itemClass']}
          title="费用分类"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.RECK_ITEM_CLASS_DICT}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'units长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['priceItemVo', 'price']}
          title="单价"
          valueType="digit"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['pricePatternDetailVo', 'amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </div>
  );
};
export default ModalComTemplate;
