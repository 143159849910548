import React from 'react';

import { Section } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import askGetTreeWhole from '@/pages/Index/components/func/ask/askGetTreeWhole';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const InstitutionTree = (props) => {
  const handleAddWithParent = (node) => {
    props.onChange?.({
      upInstitutionId: node?.id,
      upInstitutionName: node?.institutionName,
    });
  };
  return (
    <TreeMenu
      id="InstitutionTree"
      expand
      loadData={async (v) => {
        const res =
          await vsf.services.OrganizationController_getAllByInstitutionListQto_a59f48(
            {
              qto: {
                institutionNameLike: v,
                institutionCodeLike: v,
              },
            },
          );
        return res;
      }}
      filterTreeNode
      fieldNames={{
        title: 'institutionName',
        key: 'id',
        children: 'children',
        // 服务端未提供拼音搜索，暂时先不做
        // pinyinSearch: 'institutionCode',
      }}
      dataRelationFieldNames={{
        id: 'id',
        parentId: 'upInstitutionId',
      }}
      onSelect={(_value, tree) => {
        vsf.refs.departmentTable?.getSearchForm()?.resetFields();
        props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
      }}
      search={{
        status: true,
        placeholder: '请输入医疗机构名称',
        searchType: 'static',
        // searchType: 'request',
        // searchKeyWord: 'inputCode',
      }}
      title="数据分类"
      reload
      row={{
        // expand: true,
        extraShow: 'default',
      }}
    />
  );
};
export default definePage(InstitutionTree);
