import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamOrderController_getExamItemInfo_3da8d6: {
    method: 'post',
    url: '/api/exam/order/get-exam-item-info',
    parameterFomatter: (data?: { examV_sClinicIds: number[] }) => data,
    responseFormatter: (_, __, data?: ExamItemInfoEoExamPersistEo) => data,
  },
  ExamOrderController_getExamItemClassByParentClassId_09c596: {
    method: 'post',
    url: '/api/exam/get-exam-item-class-by-parent-class-id',
    parameterFomatter: (data?: { parentClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamClassDictionaryDtoExamManagerDto[]) =>
      data,
  },
  ExamOrderController_getExamBaseInfo_4de915: {
    method: 'post',
    url: '/api/exam/order/get-exam-base-info',
    parameterFomatter: (data?: { outpVisitId: number }) => data,
    responseFormatter: (_, __, data?: ExamOrderBaseInfoEoExamPersistEo) => data,
  },
  ClinicItemController_getFavoriteClinicItem_fa660a: {
    method: 'post',
    url: '/api/clinic-item-base/get-favorite-clinic-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemController_allClinicItemDictionaryCharge_d97a65: {
    method: 'post',
    url: '/api/clinic-item-base/all-clinic-item-dictionary-charge',
    parameterFomatter: (data?: {
      qto: ClinicItemDictionaryQtoSimpleClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
});
