import {
  Button,
  Modal,
  Row,
  Space,
  Tag,
  message,
  Col,
  Statistic,
  Checkbox,
  Divider,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

import { AsyncSuspense, Determine, Flex, Grid } from '@/pages/Index/components';
import { askFixed } from '@/pages/Index/components/func/ask';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';

import ClinicOutpVisitList from './form';
import Hospitalization from './hospitalizationBillList';
import ModalTable from './ModalTable';
import { refund, preRefund, payTypeEnum } from './Service';
import DetailTable from './table';

import './index.less';

function index(props) {
  const payTypeEnum = vsf?.stores?.dbenums.enums?.PAY_WAY_DICT?.reduce(
    (cerrent, next) => {
      cerrent[next?.code] = next?.cname;
      return { ...cerrent };
    },
    {},
  );
  const outpData = vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT;
  //记录信息查询
  const { run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpSettleController_queryUnRefundOutpSettleMaster_a0a382?.(
          {
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              settleNumberIs: params,
            },
          },
        );
      if (res?.code === 200 && res?.data?.result.length > 0) {
        vsf?.refs?.ClinicOutpVisitListForm?.setFieldsValue({
          ...res?.data?.result[0]?.outpSettleMasterVo,
        });
        setDataSorce(res?.data);
        return res?.data;
      } else {
        setDataSorce([]);
        message.error('当前未查到退费明细');
        return [];
      }
    },
    { manual: true },
  );

  const { runAsync: refundRun } = useRequest(refund, { manual: true });

  const { data: preData, runAsync: preRun } = useRequest(preRefund, {
    manual: true,
  });

  const [chargeInPrePaymentIndicator, setChargeInPrePaymentIndicator] =
    useState(true);

  //条件查询出来的数据源
  const [dataSorce, setDataSorce] = useState();

  //弹窗相关
  const [open, setOpen] = useState(true);

  //全部退费弹窗
  const [fullRefundOpen, setFullRefundOpen] = useState(false);

  //双击弹窗带来的Row数据
  const [doubleClikParam, setDoubleClikParam] = useState();

  const [billOpen, setBillOpen] = useState(false);

  //双击弹窗带来的Row数据回调
  const doubleClikChange = (param) => {
    setOpen(false);
    setDoubleClikParam(param);
    run(param?.outpSettleMasterVo?.settleNumber);
  };

  //从table里选择出来的数据
  const [selecteData, setSelectedData] = useState();

  const selecteDataChange = (param) => {
    setSelectedData(param);
  };

  const paramData = (param) => {
    const arr = [];
    param &&
      param.map((item) => {
        item?.outpBillingDetailVoList?.map((item) => {
          arr.push({
            outpBillingDetailId: item?.id,
            amountRefund: item?.amountRefund,
          });
        });
      });
    return arr;
  };

  //全部退费
  const allRefund = async () => {
    preRun(dataSorce?.id).then((res) => {
      if (res) {
        Modal.confirm({
          okText: '确定',
          cancelText: '取消',
          title: '请回收发票',
          onOk: async () => {
            setFullRefundOpen(true);
          },
        });
      }
    });
  };
  // console.log(props, 'pppppppppClinicOutpVisitList');
  return (
    <div className="cancel-hospitalization-refunds-layout">
      <div className="top">
        <ClinicOutpVisitList
          callback={(v) => setDataSorce(v)}
          doubleClikChange={doubleClikChange}
          routers={props?.routes.query.settleNumber ?? null}
        />
      </div>
      <Container
        layout={{
          items: [
            {
              block: '15%',
              bgColor: 'var(--background)',
              padding: '24px',
              gap: 15,
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '24px',
            },
          ],
        }}
      >
        <Flex vertical>
          <SearchContainer.Header title="付费详情" />
          <Flex vertical>
            <Flex>
              <AsyncSuspense
                loading={false}
                dataSource={Boolean(
                  dataSorce?.inpSettleApportionItemBaseDtoList?.length,
                )}
              >
                <div className="list-layout">
                  <Flex style={{ fontSize: 16 }} align="center" gap={7}>
                    <span>总费用:</span>
                    <span className="money-font">{dataSorce?.totalCost}元</span>
                  </Flex>
                  <Determine
                    condition={
                      !!dataSorce?.inpSettleApportionItemBaseDtoList?.length
                    }
                  >
                    {dataSorce?.inpSettleApportionItemBaseDtoList?.map(
                      (item) => {
                        return (
                          <Flex style={{ fontSize: 16 }} align="center" gap={7}>
                            <span>
                              {
                                vsf.stores?.dbenums?.enums?.SETTLE_APPORTION_TYPE?.find(
                                  ({ value }) =>
                                    value === item?.settleApportionCode,
                                )?.label
                              }
                              :
                            </span>
                            <span className="money-font">
                              {askFixed(item?.apportionCharge)}
                            </span>
                          </Flex>
                        );
                      },
                    )}
                  </Determine>
                </div>
              </AsyncSuspense>
            </Flex>
            <Divider />
            <Flex vertical>
              {dataSorce?.payment?.paymentDetailDtoList?.map((item) => {
                return (
                  <div className="list-layout" key={item?.id}>
                    {payTypeEnum[item?.payType]}:
                    <span className="money-font" style={{ marginLeft: 7 }}>
                      {item?.paymentAmount}元
                    </span>
                  </div>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          vertical
          flex={1}
          style={{
            height: '100%',
          }}
        >
          <Flex
            vertical
            style={{
              minHeight: 120,
            }}
          >
            <SearchContainer.Header title="费用分类" />
            <Flex gap={15}>
              {(dataSorce?.inpReceiptClassDetailBaseDtoList ?? [])?.map(
                (item, index) => {
                  const lab =
                    vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT?.find(
                      (items) => {
                        return items?.value === item?.receiptClassCode;
                      },
                    )?.label;
                  return (
                    <Tag
                      color="processing"
                      style={{
                        height: '32px',
                        fontSize: '20px',
                        lineHeight: '32px',
                        marginTop: '12px',
                      }}
                    >
                      {item?.receiptClassCode && (lab ? lab + ':' : '')}
                      {askFixed(item?.cost)}
                    </Tag>
                  );
                },
              )}
            </Flex>
          </Flex>
          <Divider />
          <Flex
            vertical
            flex={1}
            style={{
              height: '100%',
            }}
          >
            <Flex vertical flex={1}>
              <SearchContainer.Header title="预交金" />
              <DetailTable
                value={dataSorce?.prePaymentMasterVoList ?? []}
                selecteDataChange={selecteDataChange}
              />
            </Flex>
            <Flex justify="flex-end" gap={10}>
              <Button
                onClick={() => {
                  console.log(vsf.stores.dbenums?.enums?.OUTP_RCPT_FEE_DICT);
                }}
              >
                补打收费凭条
              </Button>
              <Button>票据预览</Button>
              <Button type="primary">患者费用查询</Button>
              {/* <Button type="primary" onClick={voidClick}>
                  费用汇总清单
                </Button> */}
              <Button
                type="primary"
                onClick={() => {
                  setBillOpen(true);
                }}
              >
                费用汇总清单
              </Button>
              <Button type="primary" onClick={allRefund}>
                全部退费
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Container>

      <Modal
        open={billOpen}
        title={<div style={{ color: '#3276E8' }}>住院病人费用明细清单</div>}
        onCancel={() => {
          setBillOpen(false);
        }}
        okText={'确定'}
        cancelText={'取消'}
        width={'80%'}
        footer={
          <Space size={4}>
            <Button
              onClick={() => {
                setBillOpen(false);
              }}
            >
              取消
            </Button>
            <Button type="primary" onClick={() => {}}>
              导出
            </Button>
            <Button type="primary" onClick={() => {}}>
              打印汇总
            </Button>
            <Button type="primary" onClick={() => {}}>
              打印明细
            </Button>
          </Space>
        }
      >
        <Hospitalization selecteData={selecteData} dataSorce={dataSorce} />
      </Modal>

      <Modal
        title={<div style={{ color: '#3276E8' }}>确认退费</div>}
        open={fullRefundOpen}
        okText="确定"
        cancelText="取消"
        footer={
          <Flex justify="space-between">
            <Checkbox
              onChange={(e) => {
                setChargeInPrePaymentIndicator(e);
              }}
              value={chargeInPrePaymentIndicator}
            >
              取消结算冲入预交款
            </Checkbox>
            <Flex gap={5}>
              <Button
                onClick={() => {
                  setFullRefundOpen(false);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  const param = {
                    inpSettleMasterId: dataSorce?.id,
                    chargeInPrePaymentIndicator: chargeInPrePaymentIndicator,
                  };
                  refundRun(param).then((res) => {
                    if (res?.code === 200) {
                      message.success('已退费');
                      setFullRefundOpen(false);
                    }
                  });
                }}
              >
                确定
              </Button>
            </Flex>
          </Flex>
        }
        bodyStyle={{ background: 'var(--ash--background)' }}
      >
        <Flex vertical gap={20}>
          <Grid style={{ fontSize: '16px' }} justify="space-between" amount={2}>
            <Flex>
              <span>票据类型:</span>
            </Flex>
            <Flex>
              <span>NO:</span>
            </Flex>
          </Grid>
          <Flex vertical>
            {preData?.paymentDetailDtoList?.map((item) => {
              return (
                <Flex gap={7} align="center">
                  <Icon type="icon-tuikuan" size={24} />
                  <div className="madal-layout-return">
                    <div className="font" style={{ width: '80px' }}>
                      {payTypeEnum[item?.payType]
                        ? payTypeEnum[item?.payType] + ':'
                        : ''}
                    </div>
                    <Statistic
                      value={item?.paymentAmount}
                      precision={2}
                      formatter={(value) => (
                        <CountUp
                          decimals={2}
                          end={value}
                          // separator=","
                          style={{
                            color: 'var(--blue)',
                            fontSize: '36px',
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="font">元</div>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
}

export default index;
