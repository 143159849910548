import askTimeBto from '@/pages/Index/components/func/ask/askTimeBto';
import { Modal, Pagination, Space, Button, message } from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import React, { useEffect, useState } from 'react';

const selectStyle1 = {
  background: '#3276E8',
  transition:' background 0.3s ease',
  color: '#fff !important',
};
const unSelectStyle1 = {
  background: '#f4f5f7',
};

function NumberOrderModal(props) {
  const {
    open,
    cancelModal,
    openModa,
    numOrderParam,
    dafalueNum,
    dafalueNumChange,
  } = props;
  const {
    data: numberOrderList,
    run: getNumberOrderList,
    loading,
  } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.OutpRegisterController_getPagedByClinicRegisterDetailTimeDivisionQto_b378a7?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            detailTimeDivisionQto: params,
          },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const [current, setCurrent] = useState(1);
  const [selectNumberStatus, setSelectNumberStatus] = useState(false);
  const [selectNumberCart, setSelectNumberCart] = useState();

  const modalSubmit = (param) => {
    dafalueNumChange(selectNumberCart);
    cancelModal();
    message.success('选择号序成功');
  };

  const onChange = (page) => {
    setCurrent(page);
    setSelectNumberStatus(undefined);
    console.log(page);
  };

  useEffect(() => {
    if (numOrderParam && dafalueNum && open) {
      const formData = {
        clinicRegisterDetailIdIs: numOrderParam?.id,
        //没有作废
        cancelIndicatorIs: false,
        useByPlatformIdIs: null,
        useByHisIndicatorIs: false,
        from: (current - 1) * 100 > 0 ? (current - 1) * 100 : 0,
        size: 100,
      };
      getNumberOrderList(formData);
    }
  }, [numOrderParam, dafalueNum, open, current]);

  // useMount(() => {
  //   if (numOrderParam && dafalueNum && open) {
  //     const formData = {
  //       clinicRegisterDetailIdIs: numOrderParam?.id,
  //       //没有作废
  //       cancelIndicatorIs: false,
  //       useByPlatformIdIs: null,
  //       useByHisIndicatorIs: false,
  //       from: current * 10 || 0,
  //       size: 10,
  //     };
  //     getNumberOrderList(formData);
  //   }
  // });
  return (
    <div>
      {' '}
      <Modal
        title={<div style={{ color: '#3276E8' }}>选择号序</div>}
        open={open}
        onCancel={cancelModal}
        width={800}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Pagination
              current={current}
              onChange={onChange}
              total={numberOrderList?.count || 0}
              pageSize={100}
              showSizeChanger={false}
            />
            <Space size={4}>
              <Button onClick={cancelModal}>取消</Button>
              <Button type="primary" onClick={modalSubmit}>
                确认
              </Button>
            </Space>
          </div>
        }
      >
        <div className="number-order-container">
          {numberOrderList?.result.map((item, index) => {
            return (
              <div
                // className="grid-item"
                className={(item?.cancelIndicator ||
                  item?.useByHisIndicator ||
                  item?.disable)?'grid-item-disable':'grid-item'}
                // grid-item-disable
                style={
                  selectNumberStatus === index
                      ? selectStyle1:{}
                }
                onClick={() => {
                  if (
                    item?.cancelIndicator ||
                    item?.useByHisIndicator ||
                    item?.disable
                  ) {
                    message.error('当前号序不可选，请重新选择！');
                    return;
                  }
                  setSelectNumberStatus(index);
                  setSelectNumberCart(item);
                  if (selectNumberStatus === index) {
                    setSelectNumberStatus(undefined);
                  }
                }}
              >
                <div className="grid-item-first">{item?.serialNumber}</div>
                <div className="grid-item-last">
                  {askTimeBto(item?.waitTimePeriod)}
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}

export default NumberOrderModal;
