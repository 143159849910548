import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_generateNewDischargeNotice_e1c133: {
    method: 'post',
    url: '/api/clinic-inp-adt/generate-new-discharge-notice',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpPreDischargePatientWithPatientVoClinicInpAdtEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_dischargeNotice_5c9f9b: {
    method: 'post',
    url: '/api/clinic-inp-adt/discharge-notice',
    parameterFomatter: (data?: {
      createInpPreDischargePatientBto: CreateInpPreDischargePatientBtoClinicInpAdtServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
  ClinicInpAdtController_cancelDischargeNotice_9097a3: {
    method: 'post',
    url: '/api/clinic-inp-adt/cancel-discharge-notice',
    parameterFomatter: (data?: {
      deleteInpPreDischargePatientBto: DeleteInpPreDischargePatientBtoClinicInpAdtServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
