import './index.less';

import {
  Button,
  Dropdown,
  Input,
  message,
  Modal,
  Popover,
  Tabs,
} from '@vs/vsf-kit';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';

export type CpoeCollectProps = {
  /**
   * 出发菜单右键元素的详情
   */
  detail?: string;
  /**
   * 菜单选项
   */
  dataSource?: [];
  /**
   * 菜单选项底部下划线index,dataSource的索引值
   */
  borderBottom?: [];
  /**
   * 右键菜单唯一标识
   */
  id?: number | string;
  /**
   * MenuItem点击事件
   */
  handelClickMenuItem: () => void;
};

const Index = (props) => {
  const {
    detail,
    dataSource,
    borderBottom = [],
    id,
    handelClickMenuItem,
  } = props;
  // console.log(id, 'id');

  const renderBorderBottom = (index) => {
    if (borderBottom?.length === 0) {
      return '';
    } else {
      const res = borderBottom?.findIndex((item) => {
        return item == index;
      });
      if (res === -1) {
        return '';
      } else {
        return 'mine_context_menu_menuitem_border_bottom';
      }
    }
  };
  // console.log(dataSource, '=dataSource');

  return (
    <ContextMenu
      style={{ zIndex: 999999 }}
      id={id}
      className="mine_context_menu"
    >
      {dataSource?.map((item, index) => {
        return (
          <MenuItem
            key={index}
            data={item?.value}
            className={cn(
              'mine_context_menu_menuitem_normal mine_context_menu_menuitem',
              renderBorderBottom(index),
            )}
            onClick={(e) => {
              e.stopPropagation();
              handelClickMenuItem?.(item, detail);
            }}
          >
            <Button
              style={{ width: '100%', height: '100%', textAlign: 'left' }}
              type="text"
              disabled={item?.isDisabled}
              block
            >
              {item?.label}
            </Button>
          </MenuItem>
        );
      })}
    </ContextMenu>
  );
};

export default Index;
