import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  Button,
  VSTableToolbar,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';

import { getScrollYRem, labelColWidth } from '@/utils';
import Block from '@/module/Block';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import CustomSelect from './custom_select';

import './index.less';
import { isObject } from 'lodash';

const StockList = (props) => {
  const currentSplitDrugSpecification = useRef();
  const canFetchRef = useRef(true);

  const onFetch = useCallback(async (params) => {
    if (!params?.search?.drugProductIdIs) return;
    if (canFetchRef.current) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugStockController_queryStocksByDrugProductId_acd759?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugProductId: params?.search?.drugProductIdIs?.drugProductId,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      return { data: res?.data?.result ?? res?.data ?? [] };
    }
  }, []);

  const handelFormDrugSelectLodaData = useCallback(async (inputCodeLike) => {
    if (!inputCodeLike) return;
    const response =
      await vsf?.services?.DrugStockController_queryDrugs_529e82?.({
        qry: {
          inputCodeLike,
        },
      });
    const data = response?.data?.result ?? [];

    return (
      data?.map((item) => ({
        ...item,
        key: item?.drugProductId,
        drugProductName: item?.drugProductName,
        drugSpecification: item?.drugSpecification,
        firmName: item?.firmName,
      })) ?? []
    );
  }, []);

  const loadSplitDrugSpecification = async (record) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStockController_getSpecificationByDrugProductId_319349?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          drugProductId: record?.drugProductId,
          packageSpecification: record?.packageSpecification,
        },
      ); // TODO: 你可能需要整理返回数据格式
    return res?.data?.map((item, _idx) => ({
      ...item,
      key: item?.packageSpecification,
      splitUnit: vsf.stores.dbenums?.enums?.MEASURES_DICT?.find?.(
        (ele) => ele?.code === item?.splitUnit,
      )?.label,
    }));
  };

  const handelSave = async (params) => {
    const splits = (
      (await vsf?.refs?.vSPromiseTable464361?.getValues()) ?? []
    )?.filter((item) => item?.isUpdate);
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugStockController_saveDrugStockSplit_332f9a?.({
        // TODO: 请检查以下这部分传参是否符合需求
        splits,
      }); // TODO: 你可能需要整理返回数据格式
    if (res?.code === 200) {
      message.success('已保存');
      canFetchRef.current = true;
      vsf?.refs?.vSPromiseTable464361?.reload();
    }
    return res?.data;
  };

  const handelFieldChange = async (key, value, record, form) => {
    canFetchRef.current = false;

    if (record?.splitDrugSpecification) {
      record.splitDrugSpecification = onHandleFieldObjToStr(
        record?.splitDrugSpecification,
        'splitDrugSpecification',
      );
      form.setFieldValue(key, {
        ...record,
        splitUnit: currentSplitDrugSpecification.current?.splitUnit,
        splitPrice: currentSplitDrugSpecification.current?.splitPrice,
        splitAmountPerPackage:
          currentSplitDrugSpecification.current?.splitAmountPerPackage,
      });
    }
    if (record?.splitAmount && record?.splitDrugSpecification) {
      const current = (
        (await vsf?.refs?.vSPromiseTable464361?.getValues()) ?? []
      )?.find((item) => `${item?.id}` === key);
      const res =
        await vsf.services?.DrugStockController_getDrugStockSplitUpdatedAmount_655ef3?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            item: {
              ...current,
              splitDrugSpecification: record?.splitDrugSpecification,
              splitAmount: record?.splitAmount,
              splitUnit: currentSplitDrugSpecification.current?.splitUnit,
              splitPrice: currentSplitDrugSpecification.current?.splitPrice,
              splitAmountPerPackage: onHandleFieldObjToStr(
                currentSplitDrugSpecification.current?.splitAmountPerPackage,
                'splitAmountPerPackage',
              ),
            },
          },
        ); // TODO: 你可能需要整理返回数据格式
      form.setFieldValue(key, {
        ...record,
        updatedAmount: res.data,
      });
    }
  };
  const onHandleFieldObjToStr = (params, path) =>
    isObject(params) ? params?.[path] : params;
  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  return (
    <Container
      layout={{
        items: [
          {
            block: 1,
            padding: 0,
          },
        ],
      }}
      className="drug_stock_split_wapper"
    >
      <Block style={{ padding: 0 }}>
        <VSPromiseTable
          pagination={false}
          vsid="46436"
          id="vSPromiseTable464361"
          className="aspirin-table"
          editable={{
            editType: 'single',
            columnProps: {
              hideInTable: true,
            },
            saveOnClickOutside: true,
            onFieldChange: handelFieldChange,
          }}
          onFetch={onFetch}
          scroll={{
            x: '113.33rem',
            y: getScrollYRem(25),
          }}
          searchConfig={{
            filterType: 'inline',
            optionButtons: ['search'],
            searchButtonProps: {
              onClick: () => {
                canFetchRef.current = true;
                vsf?.refs?.vSPromiseTable464361?.reload();
              },
            },
          }}
          searchFormRender={(_, defaultDom) => {
            return (
              <div className="ant-pro-table-search-custom">
                <div className="aspirin-block-title">筛选</div>
                {defaultDom}
              </div>
            );
          }}
          rowClick={{
            rowClickType: {
              action: 'click',
              type: 'edit-save',
            },
          }}
          onUpdate={(params) => {
            return {
              ...params,
              isUpdate: true,
            };
          }}
        >
          <VSTableColumn
            dataIndex={['drugProductIdIs']}
            title="药品名称"
            valueType="select"
            search
            order={0}
            columnKey={'drugProductIdIs'}
            hideInTable
            formItemProps={{
              labelCol: labelColWidth[6],
              labelAlign: 'left',
              style: {
                width: '18.66rem',
              },
            }}
            renderFormItem={(...arr) => {
              const [schema, recode, form] = arr;
              return (
                <Selected
                  immediatelyLoad
                  tableProps={{
                    pagination: false,
                    scroll: {
                      y: '12rem',
                    },
                  }}
                  recode={recode}
                  form={form}
                  onChange={schema.originProps.onChange}
                  width="43.33rem"
                  placeholder="请输入药品名称"
                  showLabel="drugProductName"
                  loadData={handelFormDrugSelectLodaData}
                  columns={[
                    {
                      title: '药品名称',
                      dataIndex: 'drugProductName',
                      key: 'drugProductName',
                    },
                    {
                      title: '包装规格',
                      dataIndex: 'drugSpecification',
                      key: 'drugSpecification',
                    },
                    {
                      title: '生产厂家',
                      dataIndex: 'firmName',
                      key: 'firmName',
                    },
                  ]}
                />
              );
            }}
          />

          <VSTableToolbar
            title="药品供应信息列表"
            style={{ padding: '0 1.6rem' }}
          />

          <VSTableColumn
            width="6rem"
            title="序号"
            valueType="text"
            render={(_, __, index) => index + 1}
            renderFormItem={({ index }) => index + 1}
            preview
          />

          <VSTableColumn
            width="13.86rem"
            dataIndex={['drugProductName']}
            title="药品名称"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            width="8rem"
            dataIndex={['drugSpecification']}
            title="规格"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            width="8rem"
            dataIndex={['packageSpecification']}
            title="包装规格"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            width="6.66rem"
            dataIndex={['packageUnit']}
            title="单位"
            editable={false}
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
          />

          <VSTableColumn
            width="10.66rem"
            dataIndex={['firmName']}
            title="厂家"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            width="6rem"
            dataIndex={['batchNumber']}
            title="批号"
            editable={false}
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 40 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            width="12.4rem"
            dataIndex={['expirationDate']}
            title="有效期"
            editable={false}
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            width="9.33rem"
            dataIndex={['stockPrice']}
            title="库存价"
            editable={false}
            valueType="digit"
            formItemProps={{}}
            fieldProps={{}}
          />

          <VSTableColumn
            width="9.33rem"
            dataIndex={['amount']}
            title="库存"
            editable={false}
            valueType="digit"
            formItemProps={{}}
            fieldProps={{}}
          />

          <VSTableColumn
            width="6.66rem"
            dataIndex={['splitDrugSpecification']}
            title="分装规格"
            valueType="select"
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请选择分装规格',
                },
              ],
            }}
            renderFormItem={(...arr) => {
              const [schema, recode, form] = arr;
              return (
                <Selected
                  tableProps={{
                    pagination: false,
                  }}
                  immediatelyLoad
                  recode={recode}
                  form={form}
                  onChange={schema.originProps.onChange}
                  width="13.33rem"
                  placeholder="请输入药品名称"
                  showLabel="packageSpecification"
                  loadData={() => loadSplitDrugSpecification(schema?.entity)}
                  onSelectChange={(v) =>
                    (currentSplitDrugSpecification.current = v)
                  }
                  columns={[
                    {
                      title: '包装规格',
                      dataIndex: 'packageSpecification',
                      key: 'packageSpecification',
                    },
                    {
                      title: '单位',
                      dataIndex: 'splitUnit',
                      key: 'splitUnit',
                    },
                  ]}
                />
              );
            }}
          />

          <VSTableColumn
            width="6.66rem"
            dataIndex={['splitAmount']}
            title="分装数量"
            valueType="digit"
            fieldProps={{}}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '请输入分装数量',
                },
                {
                  type: 'number',
                  min: 1,
                  max: 999999,
                  message: '请输入合法数量',
                },
              ],
            }}
          />

          <VSTableColumn
            width={1}
            dataIndex={['splitAmountPerPackage']}
            title=""
            valueType="custom"
            render={() => ''}
          />

          <VSTableColumn
            width="9.33rem"
            dataIndex={['splitUnit']}
            title="分装单位"
            valueType="select"
            fieldProps={{}}
            dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
            preview
          />

          <VSTableColumn
            width="9.33rem"
            dataIndex={['splitPrice']}
            title="分装价格"
            valueType="digit"
            fieldProps={{}}
            preview
          />

          <VSTableColumn
            width="9.33rem"
            dataIndex={['updatedAmount']}
            title="原包装新库存"
            valueType="digit"
            fieldProps={{}}
            preview
          />
        </VSPromiseTable>

        <div className="footer">
          <Button
            style={{
              borderColor: '#3276E8',
              color: '#3276E8',
              marginRight: '1.06rem',
            }}
          >
            关闭
          </Button>
          <Button type="primary" style={{ marginRight: '1.06rem' }}>
            导出
          </Button>
          <Button type="primary" onClick={handelSave}>
            保存
          </Button>
        </div>
      </Block>
    </Container>
  );
};
export default definePage(StockList);
