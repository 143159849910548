import { ProFormInstance } from '@vs/pro-form';
import vsf, { definePage } from '@vs/vsf-boot';
import type { DataType } from '@vs/vsf-kit';
import {
  Checkbox,
  message,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem } from '@/utils';

enum WindowTypeEnum {
  NORMAL = 'NORMAL',
  DISCHARGE_DRUG = 'DISCHARGE_DRUG',
}

const DictionaryTable = ({ getEditValues }) => {
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.DispenseWindowSettingController_getDispenseWindowSettingPrescriptionCountList_b440b4?.();
    return {
      data: res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onRemove = useCallback(async (params) => {
    const { count, dispenseWindowSettingVo } = params ?? {};
    if (count) return;

    if (
      typeof dispenseWindowSettingVo?.id === 'string' &&
      dispenseWindowSettingVo?.id.includes('create_')
    ) {
      vsf.refs.WindowDictionaryTable.remove(params);
      return;
    }

    try {
      const res =
        await vsf?.services?.DispenseWindowSettingController_removeDispenseWindowSetting_44044e?.(
          {
            id: dispenseWindowSettingVo?.id,
          },
        );
      if (res?.code === 200) {
        vsf.refs.WindowDictionaryTable.reload();
        message.success('删除成功');
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <VSPromiseTable
      id="WindowDictionaryTable"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-wudibianji" />,
        onCanDelete: () => false,
        extraOperations: [
          {
            children: <Icon type="icon-shanchuwudi" />,
            onClick: (_event, data) => {
              Tip({
                title: '提示',
                content: data?.count
                  ? '该窗口已配置，不允许删除！'
                  : `是否删除【${data?.dispenseWindowSettingVo?.windowDescription}】窗口？`,
                onOk: () => onRemove(data),
              });
            },
            order: 0,
          },
        ],
        onValuesChange: (
          record: DataType,
          _dataSource,
          form: ProFormInstance,
        ) => {
          getEditValues(record, form);
        },
      }}
      rowKey={(data) => data?.dispenseWindowSettingVo?.id}
      className="aspirin-table aspirin-table-no-background-box "
      onFetch={onFetch}
      scroll={{
        x: '106rem',
        y: getScrollYRem(26.7),
      }}
      pagination={false}
      onRecord={async () => {
        return Promise.resolve({
          count: '',
          dispenseWindowSettingVo: {
            id: 'create_' + Math.random(),
            windowDescription: '',
            dispenseMachineIndicator: false,
            toxicCode: false,
          },
        });
      }}
      rowClick={{
        rowClickType: {
          action: 'click',
          type: 'edit',
        },
      }}
    >
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'serialNumber']}
        title="序号"
        valueType="digit"
        width="4.53rem"
        formItemProps={{
          rules: [
            {
              type: 'number',
              min: 0,
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'windowType']}
        title="窗口类型"
        valueType="select"
        width="11.2rem"
        columnKey={'windowType'}
        dataSource={[
          {
            label: '普通发药窗口',
            value: WindowTypeEnum.NORMAL,
          },
          {
            label: '出院带药发药窗口',
            value: WindowTypeEnum.DISCHARGE_DRUG,
          },
        ]}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'windowDescription']}
        title="窗口名称"
        width="11.2rem"
        valueType="text"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'windowPurpose']}
        title="中药窗口类型"
        width="13.33rem"
        valueType="select"
        dataSource={vsf.stores.dbenums?.enums?.DRUG_WINDOW_PURPOSE_DICT}
        fieldProps={(record) => ({
          defaultValue:
            record?.dispenseWindowSettingVo?.windowPurpose ?? '非中药',
        })}
      />
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'dispenseRange']}
        title="发药范围"
        width="13.33rem"
        valueType="select"
        dataSource={vsf.stores.dbenums?.enums?.WINDOW_DISPENSING_RANGE_DICT}
      />
      <VSTableColumn
        width="6.73rem"
        dataIndex={['dispenseWindowSettingVo', 'dispenseMachineIndicator']}
        title="启动发药机"
        valueType="check"
        render={(_, record) => (
          <Checkbox
            defaultValue={
              record?.dispenseWindowSettingVo?.dispenseMachineIndicator
            }
          />
        )}
      />
      <VSTableColumn
        width="6rem"
        dataIndex={['dispenseWindowSettingVo', 'toxicCode']}
        title="麻精窗口"
        valueType="check"
        render={(_, record) => (
          <Checkbox defaultValue={record?.dispenseWindowSettingVo?.toxicCode} />
        )}
      />
      <VSTableColumn
        dataIndex={['dispenseWindowSettingVo', 'memo']}
        title="备注"
        valueType="text"
        width="20rem"
      />
      <VSTableColumn
        preview
        dataIndex={['dispenseWindowSettingVo', 'useStaff', 'staffName']}
        title="当前联机工号"
        valueType="text"
        width="8rem"
      />
      <VSTableColumn
        preview
        dataIndex={['count']}
        title="当前处方数量"
        valueType="text"
        width="8rem"
      />

      <VSTableToolbar
        title="窗口字典维护"
        className="aspirin-toolbar-padding-top-box"
      >
        <VSTableAddition children="新增" />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(DictionaryTable);
