// table
export const convertQueryByInpSettleMasterWithPaymentDetailQtoPagedRes2InpSettleMasterListRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.branchInstitutionId = _.get(d, 'branchInstitutionId');
        obj.inpVisitId = _.get(d, 'inpVisitId'); // TODO 请确认下行字段是否符合逻辑
        obj.physicalReceiptNumber = _.get(
          d,
          `inpReceiptRecordWithStaffInfoVoList.[0].physicalReceiptNumber`,
        ); // TODO 请确认下行字段是否符合逻辑
        obj.displayId = _.get(d, 'patient.displayId');
        obj.name = _.get(d, 'name');
        obj.chargeTypeCode = _.get(d, 'chargeTypeCode');
        obj.identityCode = _.get(d, 'identityCode');
        obj.totalCost = _.get(d, 'totalCost');
        obj.totalCharge = _.get(d, 'totalCharge'); // TODO 请确认下行字段是否符合逻辑
        obj.paymentDetailVoList = _.get(d, 'payment.paymentDetailVoList');
        obj.settleStatus = _.get(d, 'settleStatus'); // TODO 请确认下行字段是否符合逻辑
        obj.staffName = _.get(d, 'operatorStaff.staffName'); // TODO 没有匹配到合适字段 obj.admissionDateTime = null; // Date 入院日期
        // TODO 没有匹配到合适字段 obj.dischargeDateTime = null; // Date 出院日期
        obj.settleDate = _.get(d, 'settleDate'); // TODO 请确认下行字段是否符合逻辑
        obj.receiptPrintStatus = _.get(
          d,
          `inpReceiptRecordWithStaffInfoVoList.[0].receiptPrintStatus`,
        ); // TODO 请确认下行字段是否符合逻辑
        obj.printStaffName = _.get(
          d,
          `inpReceiptRecordWithStaffInfoVoList.[0].printStaff.staffName`,
        );
        obj.refundSettleDate = _.get(d, 'refundSettleDate');
        obj.creditCleanStatus = _.get(d, 'creditCleanStatus');
        obj.settleType = _.get(d, 'settleType');
        obj.insuranceSettleType = _.get(d, 'insuranceSettleType');
        obj.middleSettleIndicator = _.get(d, 'middleSettleIndicator');
        obj.settleNumber = _.get(d, 'settleNumber');
        obj.refundVsInpSettleId = _.get(d, 'refundVsInpSettleId');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 收款员
export const convertGetInpChargeGroupMemberListRes2GetInpChargeGroupMemberListRes =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'staff.id');
        obj.staffName = _.get(d, 'staff.staffName');
        return obj;
      }) ?? []),
    );
    return output;
  };
