import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemController_getClinicItemDictionaryLabById_ada097: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-lab-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryLabVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemController_allClinicItemDictionaryCharge_d97a65: {
    method: 'post',
    url: '/api/clinic-item-base/all-clinic-item-dictionary-charge',
    parameterFomatter: (data?: {
      qto: ClinicItemDictionaryQtoSimpleClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemController_getClinicItemDictionaryChargeById_5fd2a1: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-charge-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemController_getFavoriteClinicItem_fa660a: {
    method: 'post',
    url: '/api/clinic-item-base/get-favorite-clinic-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  OrderTemplateItemController_saveOrderTemplateItem_eca058: {
    method: 'post',
    url: '/api/clinic-record-order-template/save-order-template-item',
    parameterFomatter: (data?: {
      orderTemplateItems: CreateTemplateItemBtoClinicRecordOrderTemplateServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateItemInfoVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  OrderTemplateItemController_getOrderTemplateItemDetailByTemplateId_6c1e5e: {
    method: 'post',
    url: '/api/clinic-record-order-template/get-order-template-item-detail-by-template-id',
    parameterFomatter: (data?: { templateId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateItemInfoVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  OrderTemplateItemController_deleteOrderTemplateItem_02f761: {
    method: 'post',
    url: '/api/clinic-record-order-template/delete-order-template-item',
    parameterFomatter: (data?: {
      orderTemplateItemId: number;
      deleteGroup: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderTemplateItemInfoVoClinicRecordOrderTemplateEntranceWebVo[],
    ) => data,
  },
  OrderTemplateController_saveOrderAsTemplate_47eede: {
    method: 'post',
    url: '/api/clinic-record-order-template/save-order-as-template',
    parameterFomatter: (data?: {
      upTemplateId: number;
      templateName: string;
      outpVisitEncounterId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
