import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableAddition,
  VSTableColumn,
  VSPromiseTable,
  VSTableToolbar,
  Button,
  message,
} from '@vs/vsf-kit';
import { getScrollY, getScrollYRem } from '@/utils';
import React, { useState, useCallback } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Block from '@/module/Block';
const index = (props) => {
  const onFetch = useCallback(async (params) => {
    // console.log(Object.keys(params.search).length);
    const res =
      await vsf.services?.DrugImportExportController_getExportImportTypeDictionary_063cf2?.(
        {
          // ...params.search
          isImport: Object.keys(params.search).length
            ? params.search.isImport
              ? true
              : false
            : true,
        },
      );
    return res?.data;
  }, []);
  const onSave = async (params) => {
    const data = vsf?.refs?.drugStorgeDicionaryTable?.getValues();
    const formatData = data.map((d) => {
      const dict = {
        ...d,
      };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });
    const res =
      await vsf.services?.DrugImportExportController_createOrUpdateImportExportTypeDictionary_488414?.(
        {
          paramList: formatData,
        },
      );
    if (res?.code === 200) {
      message.success('保存成功');
    }
    return res?.data;
  };
  const onRemove = async (params) => {
    const res =
      await vsf.services?.DrugImportExportController_deleteImportExportClass_3f381f?.(
        {
          id: params?.id,
        },
      );
    if (res.code === 200) {
      vsf?.refs?.drugStorgeDicionaryTable?.reload();
      message.success('删除成功');
    }
    return res?.data;
  };

  return (
    <Block
      style={{
        background: '#fff',
      }}
    >
      <VSPromiseTable
        className="aspirin-table"
        id="drugStorgeDicionaryTable"
        onFetch={onFetch}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: `是否删除{【${data?.name}】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        searchConfig={{
          labelWidth: 'auto',
          optionButtons: ['search'],
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer mode="inline" formPadding="0 170px 0 100px">
              {dom}
            </SearchContainer>
          );
        }}
        labelAlign="left"
        pagination={false}
        scroll={{
          y: getScrollYRem(25.7),
        }}
      >
        <VSTableColumn
          dataIndex={['json', 'importIndicator']}
          title="类型"
          valueType="select"
          dataSource={[
            { label: '出库', value: false },
            { label: '入库', value: true },
          ]}
          // search
          fieldProps={{}}
        />
        <VSTableColumn
          title="出入库分类"
          dataIndex={'isImport'}
          valueType="select"
          hideInTable="true"
          dataSource={[
            { label: '出库', value: false },
            { label: '入库', value: true },
          ]}
          search
          fieldProps={{
            defaultValue: true,
          }}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="类型名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: 'cname长度不合法', type: 'string', min: 0, max: 32 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="输入码"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '输入码长度不合法', type: 'string', min: 0, max: 32 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['json', 'externalInstitutionType']}
          title="对应外部机构"
          valueType="select"
          dataSource={[
            { label: '内部科室', value: 'INTERNAL_DEPARTMENT' },
            { label: '内部库房', value: 'INTERNAL_STORAGE' },
            { label: '外部供应商', value: 'EXTERNAL_SUPPLIER' },
            { label: '自定义', value: 'CUSTOMIZE' },
            { label: '全部', value: 'ALL' },
            { label: '实验项目', value: 'EXPERIMENTAL_ITEM' },
          ]}
          //   formItemProps={{
          //     rules: [
          //       {
          //         message: '扩展字段长度不合法',
          //         type: 'string',
          //         min: 0,
          //         max: 2048,
          //       },
          //     ],
          //   }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['deprecateIndicator']}
          title="停用"
          valueType="select"
          dataSource={[
            { label: '停用', value: true },
            { label: '未停用', value: false },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['code']}
          title="编码"
          hideInTable={true}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableToolbar
          title="药房出入库类型"
          style={{ boxSizing: 'border-box', padding: '0 24px' }}
        >
          <VSTableAddition type="dashed" children="添加" position="top" />
          <Button type="primary" onClick={onSave}>
            保存
          </Button>
        </VSTableToolbar>
      </VSPromiseTable>
    </Block>
  );
};
export default definePage(index);
