import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicErpAdtController_getErpObservationApplyWithPatientVoByErpObservationApplyWithPatientQto_087f20:
    {
      method: 'post',
      url: '/api/clinic-erp-adt/get-erp-observation-apply-with-patient-vo-by-erp-observation-apply-with-patient-qto',
      parameterFomatter: (data?: {
        qto: ErpObservationApplyWithPatientQtoClinicErpAdtPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ErpObservationApplyWithPatientVoClinicErpAdtEntranceWebVo,
      ) => data,
    },
  ClinicErpAdtController_saveErpObservationApplyFuncFlowExecutor_a3b04d: {
    method: 'post',
    url: '/api/clinic-erp-adt/save-erp-observation-apply-func-flow-executor',
    parameterFomatter: (data?: {
      saveErpObservationApplyBto: SaveErpObservationApplyBtoClinicErpAdtServiceBto;
      updatePatientForObservationApplyBto: UpdatePatientForObservationApplyBtoPatientMainServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
});
