import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTableAddition,
  VSTableToolbar,
  VSTableAction,
} from '@vs/vsf-kit';
import React, { useCallback, useRef } from 'react';
import Icon from '@/module/Icon';

import CustomSelect from './custom_select';

const Whitelist = (props) => {
  const currentPatient = useRef();

  const onFetch = useCallback(async (params) => {
    // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
    // Tips: 以下是你选择的请求函数
    console.debug(JSON.stringify(params));
    const res =
      await vsf.services?.DrugStockSupplyController_getByDrugProductIdAndPackageSpecificationAndDrugStorageId_7821c4?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          var: {
            drugProductId: params?.extra?.drugProductId,
            packageSpecification: params?.extra?.packageSpecification,
            drugStorageId: params?.extra?.storageId,
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    const data = res?.data?.result ?? res?.data ?? []; // TODO: 你需要自己补充入参input来源及出参output用法
    const output = data.map((d) =>
      convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2VSPromiseTable365411Ro(
        d,
      ),
    );
    return {
      data: output,
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  /**
   * convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2VSPromiseTable365411Ro
   * @param {any} input
   * @returns {any}
   */
  const convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2VSPromiseTable365411Ro =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.id = _.get(input, 'id');
      output.patientId = _.get(input, 'patient.id');
      output.name = _.get(input, 'patient.name');
      output.gender = _.get(input, 'patient.gender');
      output.drugProductId = _.get(input, 'drugProductId');
      output.packageSpecification = _.get(input, 'packageSpecification');
      output.drugStorageId = _.get(input, 'drugStorageId');
      output.displayId = _.get(input, 'patient.displayId');
      return output;
    };
  /**
   * convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2SaveDrugProductWhitelistsReq
   * @param {any} input
   * @returns {any}
   */
  const convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2SaveDrugProductWhitelistsReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {}; // TODO output.btos = []; // List<SaveDrugProductWhitelistBto> null
      output.drugStorageId = input?.drugStorageId;
      output.patientId = input?.patient?.id;
      output.packageSpecification = input?.packageSpecification;
      output.drugProductId = input?.drugProductId;
      output.id = input?.id;
      return output;
    };
  /**
   * convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2DeleteDrugProductWhitelistReq
   * @param {any} input
   * @returns {any}
   */
  const convertGetByDrugProductIdAndPackageSpecificationAndDrugStorageIdRes2DeleteDrugProductWhitelistReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output = {};
      output.id = _.get(input, 'data.id');
      return output;
    };
  /**
   * convertVSPromiseTable365411Ro2SaveDrugProductWhitelistsReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSPromiseTable365411Ro2SaveDrugProductWhitelistsReq = (
    input,
  ) => {
    const _ = window._ ?? {};
    const output = {}; // TODO output.btos = []; // List<SaveDrugProductWhitelistBto> null
    output.btos = [];
    const item = {};
    item.id = input?.id;
    item.drugStorageId = input?.drugStorageId;
    item.patientId = input?.patientId;
    item.packageSpecification = input?.packageSpecification;
    item.drugProductId = input?.drugProductId;
    output.btos.push(item);
    return output;
  };

  const handelLoadPatientData = async (displayIdIs) => {
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.PatientMainController_getPagefallBySearchPatientQto_013fbc?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 10000,
            nameInputCodeLike: displayIdIs,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
    const _data = res?.data?.result ?? res?.data ?? [];
    return _data?.map((item) => ({
      ...item,
      key: item?.displayId,
    }));
  };

  const handelFieldChange = async (key, value, record, form) => {
    if (value?.displayId) {
      form.setFieldValue(key, {
        ...record,
        name: currentPatient.current.name,
        gender: currentPatient.current.gender,
        patientId: currentPatient.current?.id,
      });
    }
  };

  const handelSelectChange = (v) => {
    currentPatient.current = v;
  };

  const handelRemove = async (_, record) => {
    vsf?.refs?.vSPromiseTable365411?.remove(record);
  };

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  return (
    <VSPromiseTable
      scroll={{
        y: '14.7rem',
      }}
      className="aspirin-table aspirin-table-nopadding"
      editable={{
        editType: 'single',
        // onCanEdit: () => false,
        columnProps: {
          width: 80,
          hideInTable: true,
        },
        saveOnClickOutside: true,
        onFieldChange: handelFieldChange,
      }}
      onFetch={onFetch}
      vsid="36541"
      id="vSPromiseTable365411"
      onUpdate={(params) => ({ ...params, isUpdate: true })}
      extraParams={props.value}
      rowClick={{
        rowClickType: {
          action: 'double-click',
          type: 'edit-save',
        },
      }}
      rowSelection={{
        selectType: 'multiple',
      }}
      onRecord={() => {
        return {
          id: `create_${Math.random()}`,
        };
      }}
    >
      <VSTableToolbar title="患者白名单">
        <VSTableAddition children="新增" />
      </VSTableToolbar>

      <VSTableColumn
        width={80}
        title="序号"
        render={(_, __, index) => index + 1}
        preview
      />

      <VSTableColumn
        width={1}
        dataIndex={['patientId']}
        title=""
        valueType="custom"
        render={() => null}
      />

      <VSTableColumn
        width={120}
        dataIndex={['displayId']}
        title="患者ID"
        valueType="select"
        formItemProps={{
          rules: [
            {
              required: true,
              message: '请选择患者',
            },
          ],
        }}
        renderFormItem={(...arr) => {
          const [schema, recode, form] = arr;
          return (
            <Selected
              tableProps={{
                pagination: false,
              }}
              recode={recode}
              form={form}
              onChange={schema.originProps.onChange}
              width={392}
              placeholder="请输入"
              showLabel="displayId"
              handelSelectChange={handelSelectChange}
              loadData={handelLoadPatientData}
              columns={[
                {
                  title: '患者ID',
                  dataIndex: 'displayId',
                  key: 'displayId',
                },
                {
                  title: '患者姓名',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: '患者性别',
                  dataIndex: 'gender',
                  key: 'gender',
                },
              ]}
            />
          );
        }}
      />

      <VSTableColumn
        width={120}
        dataIndex={['name']}
        title="患者姓名"
        valueType="text"
        formItemProps={{
          rules: [
            { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
          ],
        }}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        width={100}
        dataIndex={['gender']}
        title="性别"
        valueType="select"
        dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
        preview
      />

      <VSTableColumn width={80} title="操作" valueType="custom">
        <VSTableAction disableLoading onClick={handelRemove}>
          <Icon type="icon-shanchu1" size={24} />
        </VSTableAction>
      </VSTableColumn>
    </VSPromiseTable>

    // <Todo
    //   type="block"
    //   onService={async (params) => {
    //     //
    //     // Tips: 以下是你选择的请求函数
    //     const res =
    //       await vsf.services?.DrugStockSupplyController_saveDrugProductWhitelists_e211c9?.(
    //         {
    //           // TODO: 请检查以下这部分传参是否符合需求
    //           btos: params?.btos,
    //         },
    //       ); // TODO: 你可能需要整理返回数据格式
    //     return res?.data;
    //   }}
    //   content="新增、修改接口"
    // />
  );
};
export default definePage(Whitelist);
