import React, { useState } from 'react';

import { Column, Columns, Page } from '@vs/vsf-kit';
import Container from '@/module/Container';
import vsf, { definePage } from '@vs/vsf-boot';

import DepartmentTable from './table';
import InstitutionTree from './tree';

const DepartmentManage = (props) => {
  /**
   * 组织机构
   * @type {object}
   */
  const [selectedInstitution, setSelectedInstitution] = useState();

  return (
    <Container
      layout={{
        items: [
          {
            gap: '16px',
            block: '24%',
            bgColor: 'var(--background)',
          },
          {
            bgColor: 'var(--background)',
          },
        ],
      }}
    >
      <InstitutionTree
        onChange={(_value, upValue) => {
          setSelectedInstitution({ ..._value, upValue: { ...upValue } });
        }}
      />
      <DepartmentTable value={selectedInstitution} />
    </Container>
  );
};
export default definePage(DepartmentManage);
