import classnames from 'classnames';
import React, { useState } from 'react';

import { Tooltip } from '@vs/vsf-kit';
import Icon from '@/module/cpoe/Icon';
import Icon1 from '@/module/Icon';

import Charge from './charge';
import InpExpenseForm from './form';
import DrawerCostom from './outdrawer';
import PatientHome from './patientHome';
import PrePayment from './prePayment';

import './index.less';

function index() {
  const prefixCls = 'aspirin-cpoe-center';
  const [currentKey, setCurrentKey] = useState('1');
  const [selectDrawer, setSelectDrawer] = useState();
  const selectDrawerDataChange = (param) => {
    setSelectDrawer(param);
  };
  const clearDrawer = () => {
    setSelectDrawer(undefined);
  };
  const [patientInformation, setPatientInformation] = useState();

  const [displayIdData, setDisplayIdData] = useState();
  const onChangedisplayIdData = (param) => {
    setDisplayIdData(param);
  };
  const target = [
    {
      key: '1',
      label: '费用',
      icon: <Icon type="icon-feiyong" />,
      children: (
        <Charge
          selectDrawer={selectDrawer}
          patientInformation={patientInformation}
          displayIdData={displayIdData}
        />
      ),
    },
    {
      key: '2',
      label: '预交金',
      icon: <Icon1 type="icon-yujiaojin" />,
      children: (
        <PrePayment
          inpVisitId={selectDrawer?.inpVisitId ?? displayIdData?.inpVisitId}
        />
      ),
    },
    {
      key: 'order',
      label: '变床记录',
      icon: <Icon type="icon-bianchuangjilu" />,
      // children: <Main prefixCls={prefixCls} ref={mainRef} />,
    },
    {
      key: '5',
      label: '病案首页',
      icon: <Icon type="icon-binganshouye1" />,
      children: (
        <PatientHome
          inpVisitId={selectDrawer?.inpVisitId ?? displayIdData?.inpVisitId}
        />
      ),
    },
  ];

  const targetContent = target?.find((item) => item?.key === currentKey);

  return (
    <>
      <div className="hospitalization-settlement-layout">
        <div className="patient-information">
          <InpExpenseForm
            selectDrawerData={selectDrawer}
            selectDrawerDataChange={selectDrawerDataChange}
            callback={(param) => setPatientInformation(param)}
            onChangedisplayIdData={onChangedisplayIdData}
            clearDrawer={clearDrawer}
          />
        </div>
        <div className="tag-subject">
          <div className={`${prefixCls}-leftContainer`}>
            <div className={`${prefixCls}-leftContainer-bg`}></div>
            <div className="tab-icon">
              <div className="tab-line"></div>
              <div className="tab-icon-container">
                {target?.map((item, index) => {
                  return (
                    <Tooltip
                      title={
                        <span
                          style={{
                            color: '#3276E8',
                          }}
                        >
                          {item?.label}
                        </span>
                      }
                      color="#F1F6FF"
                      key={index}
                    >
                      <div
                        className={classnames('tab-icon-block', {
                          'tab-icon-block-active': item?.key === currentKey,
                        })}
                        key={index}
                        onClick={() => {
                          setCurrentKey(item?.key);
                        }}
                      >
                        {item?.icon}
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            </div>
            <div
              style={{ padding: targetContent?.key == '10' ? '16px 0 0' : '' }}
              className={`main main-${currentKey}`}
            >
              {targetContent?.children}
            </div>
          </div>
        </div>
      </div>
      <DrawerCostom selectDrawerDataChange={selectDrawerDataChange} />
    </>
  );
}

export default index;
