import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import { askRomanAlphabet } from '@/pages/Index/components/func/ask';
import vsf, { definePage } from '@vs/vsf-boot';
import { VSFormItem, VSControlledForm, VSFormLayout } from '@vs/vsf-kit';
import React, { useEffect } from 'react';
const SpecimenEdit = (props) => {
  /**
   * 标本
   * @type {SpecimenVoSpecimenEntranceWebVo}
   */
  const getValue = async () => {
    const resolve = await vsf.services.SpecimenDtoController_getById_7a0084({
      id: props.value.id,
    });
    vsf.refs.SpecimenEdit.setFieldsValue({
      ...resolve.data,
      patternId: {
        label: resolve.data.pattern.patternName,
        value: resolve.data.pattern.id,
      },
    });
  };
  /**
   * 获取机构
   * @returns
   */
  const getInstitution = async () => {
    const res =
      await vsf.services.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a();
    return res.data;
  };
  const getDataSource = async (value) => {
    const institutionIdIs = await getInstitution();
    const res =
      await vsf?.services?.PriceListController_getAllByPricePatternQto_8ee5de?.(
        {
          qto: {
            institutionIdIs: institutionIdIs.id,
          },
        },
      );
    const list = res.data.map((item) => {
      return {
        label: item.patternName,
        value: item.id,
      };
    });
    return value?.label
      ? list?.filter((item) => askSearch(value?.label, item))
      : list;
  };
  useEffect(() => {
    if (props.value) {
      getValue();
    }
  }, []);

  return (
    <VSControlledForm
      vsid="74671"
      id="SpecimenEdit"
      labelAlign="left"
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />

      <VSFormLayout key="1" columnCount={2} gutter={70}>
        <VSFormItem
          name={['specimenName']}
          label="标本名称"
          valueType="text"
          rules={[
            {
              message: 'specimen_name长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
            { required: true },
          ]}
          fieldProps={{
            onChange: (str) => {
              const [inputCode, inputCodeWubi] = askRomanAlphabet(
                str.target.value,
                'all',
              );
              vsf.refs.SpecimenEdit.setFieldsValue({
                inputCode,
                inputCodeWubi,
              });
            },
          }}
        />

        <VSFormItem
          name={['inputCode']}
          label="拼音输入码"
          valueType="text"
          fieldProps={{}}
          rules={[
            () => ({
              validator(_, value) {
                if (String(value).includes(',')) {
                  return Promise.reject('多音字待确认');
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        />

        <VSFormItem
          name={['inputCodeWubi']}
          label="五笔输入码"
          valueType="text"
          fieldProps={{}}
        />

        <VSFormItem
          name={['patternId']}
          label="计费模版"
          valueType="select"
          dataSource={getDataSource}
          rules={[
            {
              required: true,
            },
          ]}
          fieldProps={{
            showSearch: true,
          }}
        />
      </VSFormLayout>
    </VSControlledForm>
  );
};
export default definePage(SpecimenEdit);
