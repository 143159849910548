// 患者信息form
export const convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.name = _.get(input, 'data.inpVisitVo.patient.name');
  output.gender = _.get(input, 'data.inpVisitVo.patient.gender');
  output.birthday = _.get(input, 'data.inpVisitVo.patient.birthday');
  output.age = _.get(input, 'data.inpVisitVo.patient.age');
  output.underOneYear = _.get(input, 'data.underOneYear');
  output.under28Day = _.get(input, 'data.under_28Day');
  output.firstNewbornWeight = _.get(input, 'data.firstNewbornWeight');
  output.secondNewbornWeight = _.get(input, 'data.secondNewbornWeight');
  output.newbornBirthWeight = _.get(input, 'data.newbornBirthWeight');
  output.newbornAdmissionWeight = _.get(input, 'data.newbornAdmissionWeight');
  output.newbornAdmissionType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.newbornAdmissionType',
  );
  output.citizenship = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.citizenship',
  );
  output.birthAddress = _.get(input, 'data.inpVisitVo.patient.birthAddress');
  output.nativeAddress = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.nativeAddress',
  );
  output.nation = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.nation',
  );
  output.idNumber = _.get(input, 'data.inpVisitVo.patient.idNumber');
  output.job = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.job',
  );
  // output.marriageStatus = _.get(
  //   input,
  //   'data.inpVisitVo.patient.patientProfileDto.marriageStatus',
  // );
  output.marriageStatus = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.marriageStatus',
  );
  output.identityNumberUnknownReason = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.identityNumberUnknownReason',
  );
  output.identityNumberUnknownReasonDetail = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.identityNumberUnknownReasonDetail',
  );
  output.currentAddress = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.currentAddress',
  );
  output.cellphone = _.get(input, 'data.inpVisitVo.patient.cellphone');
  output.registeredResidenceAddress = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.registeredResidenceAddress',
  );
  output.workUnit = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.workUnit',
  );
  output.contact = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.contact',
  );
  output.patientClass = _.get(input, 'data.inpVisitVo.patientClass');
  output.patientAdmissionCondition = _.get(
    input,
    'data.inpVisitVo.patientAdmissionCondition',
  );
  output.caseType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.caseType',
  );
  output.rescueTimes = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.rescueTimes',
  );
  output.rescueSuccessTimes = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.rescueSuccessTimes',
  );
  output.admissionDateTime = _.get(input, 'data.inpVisitVo.admissionDateTime');
  output.admissionDepartmentName = _.get(
    input,
    'data.inpVisitVo.admissionToDepartment.departmentName',
  );
  output.admissionToWardName = _.get(input, 'data.admissionToWardName');
  output.transferDepartmentName = _.get(
    input,
    'data.inpVisitVo.patientInHospitalForMedicalRecordDto.department.departmentName',
  );
  output.dischargeDateTime = _.get(input, 'data.inpVisitVo.dischargeDateTime');
  output.dischargeDepartmentName = _.get(
    input,
    'data.inpVisitVo.dischargeFromDepartment.departmentName',
  );
  // output.dischargeWardDepartmentName = _.get(
  //   input,
  //   'data.inpVisitVo.patientInHospitalForMedicalRecordDto.department.departmentName',
  // );
  output.dischargeWardDepartmentName = _.get(
    input,
    'data.inpVisitVo.patientInHospitalForMedicalRecordDto.ward.departmentName',
  );
  output.actualHospitalDay = _.get(input, 'data.actualHospitalDay');
  output.inpTransferRecordForMedicalRecordDtoList = _.get(
    input,
    'data.inpVisitVo.inpTransferRecordForMedicalRecordDtoList',
  );
  output.patientIdentificationDtoList = _.get(
    input,
    'data.inpVisitVo.patient.patientIdentificationDtoList',
  );
  output.patientIdentificationDtoList = _.get(
    input,
    'data.inpVisitVo.patient.patientIdentificationDtoList',
  );
  output.applyDoctorName = _.get(
    input,
    'data.inpVisitVo.admissionApply.applyDoctor.staffName',
  );
  return output;
};
// 患者信息转科记录查询
export const convertGetMedicalRecordVoByIdRes2InpTransferRecordTable = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(
      input,
      'data.inpVisitVo.inpTransferRecordForMedicalRecordDtoList',
    )?.map((d, i) => {
      const obj = {};
      obj.admissionDateTime = _.get(d, 'admissionDateTime');
      obj.departmentName = _.get(d, 'department.departmentName');
      return obj;
    }) ?? []),
  );
  return output;
};
// 诊断信息table
export const convertGetMedicalRecordVoByIdRes2DiagnosisRecordTable = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  // console.log(input.data.diagnosisRecordVoList, '诊断列表');
  output.push(
    ...(_.get(input, 'data.diagnosisRecordVoList')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.diagnosisType = _.get(d, 'diagnosisType');
      obj.prefix = _.get(d, 'prefix');
      // obj.diagnosisDescription = _.get(d, 'diagnosisDescription');
      obj.suffix = _.get(d, 'suffix');
      obj.diagnosisId = _.get(d, 'diagnosis.id');
      obj.diagnosis = {};
      obj.diagnosis.id = _.get(d, 'diagnosis.id');
      obj.diagnosis.diagnosisCode = _.get(d, 'diagnosis.diagnosisCode');
      obj.admissionCondition = _.get(d, 'admissionCondition');
      obj.treatOutcome = _.get(d, 'treatOutcome');
      obj.diagnosisDescriptions = {
        ...d,
        id: _.get(d, 'id'),
        prefix: _.get(d, 'prefix'),
        selectValue: _.get(d, 'diagnosisDescription'),
        suffix: _.get(d, 'suffix'),
        diagnosis: {
          ...d?.diagnosis,
          diagnosisDescription: d?.diagnosis?.diagnosisName,
        },
      };
      return obj;
    }) ?? []),
  );
  return output;
};
// 诊断信息form
export const convertGetMedicalRecordVoByIdRes2DiagnosisInfoForm = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.haveInternalHospitalInfect = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.haveInternalHospitalInfect',
  );
  output.internalHospitalInfectDate = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.internalHospitalInfectDate',
  );
  output.internalHospitalInfectDays = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.internalHospitalInfectDays',
  );
  output.topDiagnosisBasis = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.topDiagnosisBasis',
  );
  output.haveAdmissionAgainPlan = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.haveAdmissionAgainPlan',
  );
  output.admissionAgainPurpose = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.admissionAgainPurpose',
  );
  output.returnAdmission = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.returnAdmission',
  );
  output.pathologyNumber = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.pathologyNumber',
  );
  output.havePathologyReportIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.havePathologyReportIndicator',
  );
  output.dischargeWay = _.get(input, 'data.inpVisitVo.dischargeWay');
  output.tumourDifferentiation = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.tumourDifferentiation',
  );
  output.beforeAdmissionComaDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.beforeAdmissionComaDays',
  );
  output.beforeAdmissionComaHour = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.beforeAdmissionComaHour',
  );
  output.beforeAdmissionComaMinute = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.beforeAdmissionComaMinute',
  );
  output.afterAdmissionComaDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.afterAdmissionComaDays',
  );
  output.afterAdmissionComaHour = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.afterAdmissionComaHour',
  );
  output.afterAdmissionComaMinute = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.afterAdmissionComaMinute',
  );
  output.diagnosisCompareRecordDtoList = [];
  output.diagnosisCompareRecordDtoList.push(
    ...(_.get(input, 'data.inpVisitVo.diagnosisCompareRecordDtoList')?.map(
      (d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.diagnosisCompareGroupCode = _.get(d, 'diagnosisCompareGroupCode');
        obj.diagnosisCorrespondStatus = _.get(d, 'diagnosisCorrespondStatus');
        return obj;
      },
    ) ?? []),
  );
  return output;
};
// 手术信息table
export const convertGetMedicalRecordVoByIdRes2OperationRecordTable = (
  input,
) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(
      input,
      'data.inpVisitVo.operationRecordForMedicalRecordDtoList',
    )?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.operationCode = _.get(d, 'operationItem.operationCode');
      obj.operationItemId = _.get(d, 'operationItem.id');
      obj.operationBodypart = _.get(d, 'operationBodypart');
      obj.operationType = _.get(d, 'operationType');

      obj.operateInfoType = _.get(d, 'operateInfoType');
      obj.operationDate = _.get(d, 'operationDate');
      obj.operationLevel = _.get(d, 'operationLevel');
      // obj.prefix = _.get(d, 'prefix');
      // obj.operationDescription = _.get(d, 'operationDescription');
      // obj.suffix = _.get(d, 'suffix');
      obj.operationDescriptions = {
        ...d,
        prefix: _.get(d, 'prefix'),
        selectValue: _.get(d, 'operationDescription'),
        suffix: _.get(d, 'suffix'),
        // operationCode: _.get(d, 'operationItem.operationCode'),
        // operationItemId: _.get(d, 'operationItem.id'),
        // operationType: _.get(d, 'operationType'),
        // operateInfoType: _.get(d, 'operateInfoType'),
        // operationLevel: _.get(d, 'operationLevel'),
      };
      obj.operator = _.get(d, 'operator');
      obj.department = _.get(d, 'department');
      obj.ward = _.get(d, 'ward');
      obj.firstAssistant = _.get(d, 'firstAssistant');
      obj.secondAssistant = _.get(d, 'secondAssistant');
      obj.incisionLevelCode = _.get(d, 'incisionLevelCode');
      obj.incisionCure = _.get(d, 'incisionCure');
      obj.anesthesiaMethod = _.get(d, 'anesthesiaMethod');
      obj.asaLevel = _.get(d, 'asaLevel');
      obj.anesthesiaDoctor = _.get(d, 'anesthesiaDoctor');
      obj.recoveryRoomDoctor = _.get(d, 'recoveryRoomDoctor');
      obj.analgesiaIndicator = _.get(d, 'analgesiaIndicator');
      obj.operationRecoveryScore = _.get(d, 'operationRecoveryScore');
      obj.analgesiaMethod = _.get(d, 'analgesiaMethod');
      obj.nnisLevel = _.get(d, 'nnisLevel');
      obj.operationDuration = _.get(d, 'operationDuration');
      obj.mainOperationIndicator = _.get(d, 'mainOperationIndicator');
      obj.notPlanOperationAgainIndicator = _.get(
        d,
        'notPlanOperationAgainIndicator',
      );
      return obj;
    }) ?? []),
  );
  return output;
};
// 手术信息form
export const convertGetMedicalRecordVoByIdRes2OperationInfoForm = (input) => {
  const _ = window._ ?? {};
  const output = {};
  output.dayOperationIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.dayOperationIndicator',
  );
  output.beforeOperationDays = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.beforeOperationDays',
  );
  output.afterOperationDiagnosisCorrespond = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.afterOperationDiagnosisCorrespond',
  );
  output.typeOfOperationPatient = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.typeOfOperationPatient',
  );
  output.selectiveOperationConcurrence = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.selectiveOperationConcurrence',
  );
  output.selectiveOperationComplicationId = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.selectiveOperationComplicationId',
  );
  output.otherComplicationId = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.otherComplication.id',
  );
  output.otherComplicationName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.otherComplication.diagnosisName',
  );
  output.returnOperationIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.returnOperationIndicator',
  );
  output.returnOperationId = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.returnOperationId',
  );
  output.returnOperationName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.returnOperationName',
  );
  output.foreignObjectLeaveInOperation =
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.foreignObjectLeaveInOperation',
    ) ?? false;
  output.iatrogenicPneumothoraxIndicator =
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.iatrogenicPneumothoraxIndicator',
    ) ?? false;
  output.iatrogenicAccidentIndicator =
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.iatrogenicAccidentIndicator',
    ) ?? false;

  return output;
};
// 其他信息 form
export const convertGetMedicalRecordVoByIdRes2MedicalRecordOtherInfoForm = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.allergyDrug = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.allergyDrug',
  );
  output.notDrugAllergy = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.notDrugAllergy',
  );
  output.infusion = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.infusion',
  );
  output.infusionReaction = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.infusionReaction',
  );
  output.clinicalPathwayManage = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.clinicalPathwayManage',
  );
  output.bloodType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.bloodType',
  );
  output.bloodTypeRh = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.bloodTypeRh',
  );
  output.hbsagIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.hbsagIndicator',
  );

  output.hcvabIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.hcvabIndicator',
  );
  output.hivabIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.hivabIndicator',
  );
  output.bloodTransfusionReaction = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBloodInfoDto.bloodTransfusionReaction',
  );
  output.erythrocyteSuspension = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.erythrocyteSuspension',
  );
  output.plateletMachine = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.plateletMachine',
  );
  output.bloodPlasma = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.bloodPlasma',
  );
  output.wholeBlood = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.wholeBlood',
  );
  output.autotransfusion = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.autotransfusion',
  );
  output.other = _.get(
    input,
    'data.inpVisitVo.bloodTransfusionRecordDto.other',
  );
  output.inHospitalFallDown = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionNurseRecordDto.inHospitalFallDown',
  );
  output.pressureSore =
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionNurseRecordDto.pressureSore',
    ) ?? 'NONE';
  output.pressureSoreSource = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionNurseRecordDto.pressureSoreSource',
  );
  output.pressureSoreLevel = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionNurseRecordDto.pressureSoreLevel',
  );
  output.pressureSoreBodypart = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionNurseRecordDto.pressureSoreBodypart',
  );
  output.specialLevelNurseDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.specialLevelNurseDays',
  );
  output.firstLevelNurseDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.firstLevelNurseDays',
  );
  output.secondLevelNurseDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.secondLevelNurseDays',
  );
  // output.followPeriod = {

  // }
  output.thirdLevelNurseDays = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.thirdLevelNurseDays',
  );
  output.crisisCase = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.crisisCase',
  );
  output.difficultCase = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.difficultCase',
  );
  output.mdtCaseHistory = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.mdtCaseHistory',
  );
  output.singleDiseaseManage = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.singleDiseaseManage',
  );
  output.followUpClinic = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.followUpClinic',
  );
  // 随诊周期
  // console.log(input.data.inpVisitVo.inpVisitExtensionBaseDto, '刷新');
  output.followPeriod = {
    followUpWeeks: _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.followUpWeeks',
    ),
    followUpMonths: _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.followUpMonths',
    ),
    followUpYears: _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionBaseDto.followUpYears',
    ),
  };
  output.followUpWeeks = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.followUpWeeks',
  );
  output.followUpMonths = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.followUpMonths',
  );
  output.followUpYears = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.followUpYears',
  );
  output.firstCaseIndicator = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.firstCaseIndicator',
  );
  output.teachMedicalRecord = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.teachMedicalRecord',
  );
  output.singleDiseaseName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.singleDiseaseName',
  );
  output.departmentLeader = {};
  output.departmentLeader.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.departmentLeader.id',
  );
  output.departmentLeader.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.departmentLeader.staffName',
  );
  output.chiefDoctor = {};
  output.chiefDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.chiefDoctor.id',
  );
  output.chiefDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.chiefDoctor.staffName',
  );
  output.medicalRecordQuality = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.medicalRecordQuality',
  );
  output.attendingDoctor = {};
  output.attendingDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.id',
  );
  output.attendingDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor.staffName',
  );
  output.doctorInCharge = {};
  output.doctorInCharge.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.doctorInCharge.id',
  );
  output.doctorInCharge.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.doctorInCharge.staffName',
  );
  output.medicalGroupLeader = {};
  output.medicalGroupLeader.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.medicalGroupLeader.id',
  );
  output.medicalGroupLeader.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.medicalGroupLeader.staffName',
  );
  output.advanceStudyDoctor = {};
  output.advanceStudyDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.advanceStudyDoctor.id',
  );
  output.advanceStudyDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.advanceStudyDoctor.staffName',
  );
  output.nurseInCharge = {};
  output.nurseInCharge.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.nurseInCharge.id',
  );
  output.nurseInCharge.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.nurseInCharge.staffName',
  );
  output.internshipDoctor = {};
  output.internshipDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.internshipDoctor.id',
  );
  output.internshipDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.internshipDoctor.staffName',
  );
  output.catalogStaff = {};
  output.catalogStaff.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.catalogStaff.id',
  );
  output.catalogStaff.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.catalogStaff.staffName',
  );
  output.controlQualityNurse = {};
  output.controlQualityNurse.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityNurse.id',
  );
  output.controlQualityNurse.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityNurse.staffName',
  );
  output.controlQualityDoctor = {};
  output.controlQualityDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityDoctor.id',
  );
  output.controlQualityDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityDoctor.staffName',
  );
  output.recordDoctor = {};
  output.recordDoctor.id = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.recordDoctor.id',
  );
  output.recordDoctor.staffName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.recordDoctor.staffName',
  );
  output.controlQualityDate = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityDate',
  );
  output.respiratorUseDay = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.respiratorUseDay',
  );
  output.respiratorUseHour = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.respiratorUseHour',
  );
  output.respiratorUseMinute = _.get(
    input,
    'data.inpVisitExtensionStatisticsVo.respiratorUseMinute',
  );
  output.dayWardCase = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.dayWardCase',
  );
  output.dayOperationCase = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.dayOperationCase',
  );
  output.teachWardCheck = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBaseDto.teachWardCheck',
  );
  return output;
};
// 其他信息table
export const convertGetMedicalRecordVoByIdRes2IcuRecordTable = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data.inpVisitVo.icuRecordDtoList')?.map((d, i) => {
      const obj = {};
      obj.id = _.get(d, 'id');
      obj.department = _.get(d, 'department');
      obj.inIcuDateTime = _.get(d, 'inIcuDateTime');
      obj.outIcuDateTime = _.get(d, 'outIcuDateTime');

      return obj;
    }) ?? []),
  );
  return output;
};
// 费用信息
export const convertGetMedicalRecordVoByIdRes2BillingInfoTable = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data.inpBillingDetail')?.map((d, i) => {
      const obj = {};
      obj.mainClassStandardCode = _.get(d, 'mainClassStandardCode');
      obj.mainClassStandardName = _.get(d, 'mainClassStandardName');
      obj.summaryMedicalRecordChargeClassStandardCode = _.get(
        d,
        'summaryMedicalRecordChargeClassStandardCode',
      );
      obj.summaryMedicalRecordChargeClassName = _.get(
        d,
        'summaryMedicalRecordChargeClassName',
      );
      obj.cost = _.get(d, 'cost');
      return obj;
    }) ?? []),
  );
  return output;
};
// 保存
export const convertSaveMedicalRecordRes2SaveMedicalRecordReq = (input) => {
  // console.log(input, 'inputinput');
  const _ = window._ ?? {};
  const output = {};
  output.diagnosticInfoTable = [];
  output.operationRecordTable = [];
  output.otherInfoTable = [];
  output.updatePatientBto = {};
  output.updatePatientBto.name = _.get(input, 'name');
  output.updatePatientBto.gender = _.get(input, 'gender');
  output.updatePatientBto.birthday = _.get(input, 'birthday');
  output.updatePatientBto.patientProfileBto = {};
  output.updatePatientBto.patientProfileBto.citizenship = _.get(
    input,
    'citizenship',
  );
  output.updatePatientBto.patientProfileBto.nation = _.get(input, 'nation');
  output.updatePatientBto.patientProfileBto.nativeAddress = _.get(
    input,
    'nativeAddress',
  );
  output.updatePatientBto.patientProfileBto.currentAddress = _.get(
    input,
    'currentAddress',
  );
  output.updatePatientBto.cellphone = _.get(input, 'cellphone');
  output.updatePatientBto.patientProfileBto.registeredResidenceAddress = _.get(
    input,
    'registeredResidenceAddress',
  );
  output.updatePatientBto.patientProfileBto.workUnit = _.get(input, 'workUnit');
  output.updatePatientBto.patientProfileBto.contact = _.get(input, 'contact');
  output.updatePatientBto.patientProfileBto.marriageStatus = _.get(
    input,
    'marriageStatus',
  );
  output.updatePatientBto.birthAddress = _.get(input, 'birthAddress');
  output.updatePatientBto.idNumber = _.get(input, 'idNumber');
  // output.updatePatientBto.patientIdentificationBtoList = [];
  output.updatePatientBto.patientRelationshipBtoList = [];
  output.updatePatientBto.patientExpressAddressBtoList = [];
  output.updatePatientBto.label = {};
  output.saveInpVisitForMedicalRecordBto = {};
  output.saveInpVisitForMedicalRecordBto.patientClass = _.get(
    input,
    'patientClass',
  );
  output.saveInpVisitForMedicalRecordBto.patientAdmissionCondition = _.get(
    input,
    'patientAdmissionCondition',
  );
  output.saveInpVisitForMedicalRecordBto.dischargeWay = _.get(
    input,
    'dischargeWay',
  );
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto = {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.job =
    _.get(input, 'job');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress =
    {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.province =
    _.get(input, 'birthAddress.province');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.city =
    _.get(input, 'birthAddress.city');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.county =
    _.get(input, 'birthAddress.county');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.detail =
    _.get(input, 'birthAddress.detail');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.code =
    _.get(input, 'birthAddress.code');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress.postcode =
    _.get(input, 'birthAddress.postcode');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress =
    {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.province =
    _.get(input, 'birthAddress.province');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.city =
    _.get(input, 'birthAddress.city');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.county =
    _.get(input, 'birthAddress.county');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.detail =
    _.get(input, 'birthAddress.detail');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.code =
    _.get(input, 'birthAddress.code');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress.postcode =
    _.get(input, 'birthAddress.postcode');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.workUnitAddress =
    {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionPatientInfoBto.contactPersonAddress =
    {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto = {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.advanceStudyDoctorId =
    _.get(input, 'advanceStudyDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.internshipDoctorId =
    _.get(input, 'internshipDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.controlQualityDoctorId =
    _.get(input, 'controlQualityDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.controlQualityNurseId =
    _.get(input, 'controlQualityNurse.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.controlQualityDate =
    _.get(input, 'controlQualityDate');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.catalogStaffId =
    _.get(input, 'catalogStaff.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.departmentLeaderId =
    _.get(input, 'departmentLeader.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.attendingDoctorId =
    _.get(input, 'attendingDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.doctorInChargeId =
    _.get(input, 'doctorInCharge.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.chiefDoctorId =
    _.get(input, 'chiefDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.nurseInChargeId =
    _.get(input, 'nurseInCharge.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.medicalGroupLeaderId =
    _.get(input, 'medicalGroupLeader.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionStaffBto.recordDoctorId =
    _.get(input, 'recordDoctor.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto = {};
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.identityNumberUnknownReason =
    _.get(input, 'identityNumberUnknownReason');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.identityNumberUnknownReasonDetail =
    _.get(input, 'identityNumberUnknownReasonDetail');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.caseType = _.get(
    input,
    'caseType',
  );
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.newbornAdmissionType =
    _.get(input, 'newbornAdmissionType');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.haveAdmissionAgainPlan =
    _.get(input, 'haveAdmissionAgainPlan');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.admissionAgainPurpose =
    _.get(input, 'admissionAgainPurpose');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.haveInternalHospitalInfect =
    _.get(input, 'haveInternalHospitalInfect');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.internalHospitalInfectDate =
    _.get(input, 'internalHospitalInfectDate');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.topDiagnosisBasis =
    _.get(input, 'topDiagnosisBasis');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.returnAdmission =
    _.get(input, 'returnAdmission');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.internalHospitalInfectDays =
    Number(
      _.get(input, 'internalHospitalInfectDays')
        ? _.get(input, 'internalHospitalInfectDays')
        : null,
    );
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.tumourDifferentiation =
    _.get(input, 'tumourDifferentiation');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.havePathologyReportIndicator =
    _.get(input, 'havePathologyReportIndicator');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.pathologyNumber =
    _.get(input, 'pathologyNumber');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.dayOperationIndicator =
    _.get(input, 'dayOperationIndicator');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.beforeOperationDays =
    Number(_.get(input, 'beforeOperationDays'));
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.afterOperationDiagnosisCorrespond =
    _.get(input, 'afterOperationDiagnosisCorrespond');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.typeOfOperationPatient =
    _.get(input, 'typeOfOperationPatient');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.selectiveOperationConcurrence =
    _.get(input, 'selectiveOperationConcurrence');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.returnOperationIndicator =
    _.get(input, 'returnOperationIndicator');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.returnOperationName =
    _.get(input, 'returnOperationName.operationName');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.returnOperationId =
    _.get(input, 'returnOperationName.id');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.foreignObjectLeaveInOperation =
    _.get(input, 'foreignObjectLeaveInOperation');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.iatrogenicAccidentIndicator =
    _.get(input, 'iatrogenicAccidentIndicator');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.iatrogenicPneumothoraxIndicator =
    _.get(input, 'iatrogenicPneumothoraxIndicator');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.teachMedicalRecord =
    _.get(input, 'teachMedicalRecord');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.allergyDrug =
    _.get(input, 'allergyDrug');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.medicalRecordQuality =
    _.get(input, 'medicalRecordQuality');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.notDrugAllergy =
    _.get(input, 'notDrugAllergy');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.crisisCase =
    _.get(input, 'crisisCase');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.difficultCase =
    _.get(input, 'difficultCase');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.mdtCaseHistory =
    _.get(input, 'mdtCaseHistory');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.dayWardCase =
    _.get(input, 'dayWardCase');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.dayOperationCase =
    _.get(input, 'dayOperationCase');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.teachWardCheck =
    _.get(input, 'teachWardCheck');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.singleDiseaseName =
    _.get(input, 'singleDiseaseName');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.singleDiseaseManage =
    _.get(input, 'singleDiseaseManage');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.firstCaseIndicator =
    _.get(input, 'firstCaseIndicator');
  output.saveInpVisitExtensionStatisticsBto = {};
  output.saveInpVisitExtensionStatisticsBto.rescueTimes = _.get(
    input,
    'rescueTimes',
  );
  output.saveInpVisitExtensionStatisticsBto.rescueSuccessTimes = _.get(
    input,
    'rescueSuccessTimes',
  );
  output.saveInpVisitExtensionStatisticsBto.beforeAdmissionComaDays = _.get(
    input,
    'beforeAdmissionComaDays',
  );
  output.saveInpVisitExtensionStatisticsBto.beforeAdmissionComaHour = _.get(
    input,
    'beforeAdmissionComaHour',
  );
  output.saveInpVisitExtensionStatisticsBto.beforeAdmissionComaMinute = _.get(
    input,
    'beforeAdmissionComaMinute',
  );
  output.saveInpVisitExtensionStatisticsBto.afterAdmissionComaDays = _.get(
    input,
    'afterAdmissionComaDays',
  );
  output.saveInpVisitExtensionStatisticsBto.afterAdmissionComaHour = _.get(
    input,
    'afterAdmissionComaHour',
  );
  output.saveInpVisitExtensionStatisticsBto.afterAdmissionComaMinute = _.get(
    input,
    'afterAdmissionComaMinute',
  );
  output.saveInpVisitExtensionStatisticsBto.specialLevelNurseDays = _.get(
    input,
    'specialLevelNurseDays',
  );
  output.saveInpVisitExtensionStatisticsBto.firstLevelNurseDays = _.get(
    input,
    'firstLevelNurseDays',
  );
  output.saveInpVisitExtensionStatisticsBto.secondLevelNurseDays = _.get(
    input,
    'secondLevelNurseDays',
  );
  output.saveInpVisitExtensionStatisticsBto.thirdLevelNurseDays = _.get(
    input,
    'thirdLevelNurseDays',
  );
  output.saveInpVisitExtensionStatisticsBto.respiratorUseDay = _.get(
    input,
    'respiratorUseDay',
  );
  output.saveInpVisitExtensionStatisticsBto.respiratorUseHour = _.get(
    input,
    'respiratorUseHour',
  );
  output.saveInpVisitExtensionStatisticsBto.respiratorUseMinute = _.get(
    input,
    'respiratorUseMinute',
  );
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto = {};
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto.inHospitalFallDown =
    _.get(input, 'inHospitalFallDown');
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto.pressureSore =
    _.get(input, 'pressureSore');
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto.pressureSoreSource =
    _.get(input, 'pressureSoreSource');
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto.pressureSoreLevel =
    _.get(input, 'pressureSoreLevel');
  output.saveInpVisitExtensionNurseRecordForMedicalRecordBto.pressureSoreBodypart =
    _.get(input, 'pressureSoreBodypart');
  output.saveInpVisitExtensionBloodInfoBto = {};
  output.saveInpVisitExtensionBloodInfoBto.bloodType = _.get(
    input,
    'bloodType',
  );
  output.saveInpVisitExtensionBloodInfoBto.bloodTypeRh = _.get(
    input,
    'bloodTypeRh',
  );
  output.saveInpVisitExtensionBloodInfoBto.hbsagIndicator = _.get(
    input,
    'hbsagIndicator',
  );
  output.saveInpVisitExtensionBloodInfoBto.hcvabIndicator = _.get(
    input,
    'hcvabIndicator',
  );
  output.saveInpVisitExtensionBloodInfoBto.hivabIndicator = _.get(
    input,
    'hivabIndicator',
  );
  output.saveInpVisitExtensionBloodInfoBto.bloodTransfusionReaction = _.get(
    input,
    'bloodTransfusionReaction',
  );
  output.saveBloodTransfusionRecordBto = {};
  output.saveBloodTransfusionRecordBto.wholeBlood = _.get(input, 'wholeBlood');
  output.saveBloodTransfusionRecordBto.erythrocyteSuspension = _.get(
    input,
    'erythrocyteSuspension',
  );
  output.saveBloodTransfusionRecordBto.plateletMachine = _.get(
    input,
    'plateletMachine',
  );
  output.saveBloodTransfusionRecordBto.bloodPlasma = _.get(
    input,
    'bloodPlasma',
  );
  output.saveBloodTransfusionRecordBto.other = _.get(input, 'other');
  output.saveBloodTransfusionRecordBto.autotransfusion = _.get(
    input,
    'autotransfusion',
  );
  output.saveOperationRecordBtoList = [];
  // console.log(
  //   input?.operationRecordTable,
  //   'operationRecordTableoperationRecordTable',
  // );
  output.saveOperationRecordBtoList.push(
    ...(input?.operationRecordTable?.map((a, i) => {
      const { operationDescriptions: d } = a;

      const _temp = { ...a?.operationDescriptions, ...a };
      // console.log(_temp, a, a?.operationDescriptions, '啊哈哈');
      const obj = {};
      // obj.id = _.get(_temp, `id`);
      obj.operationItemId = _.get(_temp, 'operationItemId');
      obj.operationDescription = _.get(_temp, `selectValue`);
      obj.incisionLevelCode = _.get(_temp, `incisionLevelCode`);
      obj.incisionCure = _.get(_temp, `incisionCure`);
      obj.operationDate = _.get(_temp, `operationDate`);
      obj.anesthesiaMethod = _.get(_temp, `anesthesiaMethod`);
      obj.operateInfoType = _.get(_temp, `operateInfoType`);
      obj.operationType = _.get(_temp, `operationType`);
      obj.operationLevel = _.get(_temp, `operationLevel`);
      obj.prefix = _.get(_temp, `prefix`);
      obj.suffix = _.get(_temp, `suffix`);
      obj.anesthesiaDoctorId = _.get(_temp, `anesthesiaDoctor.id`);
      obj.secondAssistantId = _.get(_temp, `secondAssistant.id`);
      obj.firstAssistantId = _.get(_temp, `firstAssistant.id`);
      obj.operatorId = _.get(_temp, `operator.id`);
      obj.analgesiaMethod = _.get(_temp, `analgesiaMethod`);
      obj.operationBodypart = _.get(_temp, `operationBodypart`);
      obj.operationRecoveryScore = _.get(_temp, `operationRecoveryScore`);
      obj.analgesiaIndicator = _.get(_temp, `analgesiaIndicator`);
      obj.nnisLevel = _.get(_temp, `nnisLevel`);
      obj.operationDuration = _.get(_temp, `operationDuration`);
      obj.mainOperationIndicator = _.get(_temp, `mainOperationIndicator`);
      obj.notPlanOperationAgainIndicator = _.get(
        _temp,
        `notPlanOperationAgainIndicator`,
      );
      obj.operationCode = _.get(_temp, `operationCode`);
      obj.asaLevel = _.get(_temp, `asaLevel`);
      obj.recoveryRoomDoctorId = _.get(_temp, `recoveryRoomDoctor.id`);
      obj.departmentId = _.get(_temp, `department.id`);
      obj.wardId = _.get(_temp, `ward.id`);

      return obj;
    }) ?? []),
  );
  output.saveIcuRecordBtoList = input?.otherInfoTable ?? [];
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.selectiveOperationComplicationId =
    _.get(input, 'selectiveOperationComplicationId');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.otherComplicationId =
    _.get(input, 'otherComplicationName.id');

  output.saveBloodTransfusionRecordBto = {};
  output.createDiagnosisRecordBtoList = [];
  // console.log(input?.diagnosticInfoTable, '诊断diagnosticInfoTable');
  output.createDiagnosisRecordBtoList.push(
    ...(input?.diagnosticInfoTable?.map((d, i) => {
      let obj = {};
      // if (d?.diagnosisDescriptions?.id) {
      obj.id = _.get(d, 'id');
      obj.diagnosisId = _.get(d, 'diagnosisDescriptions.diagnosis.id');
      obj.diagnosisType = _.get(d, 'diagnosisType');
      obj.diagnosis = _.get(d, 'diagnosisDescriptions.diagnosis');
      // obj.diagnosis = {};
      // obj.diagnosis.id = _.get(d, 'diagnosisDescriptions.id');
      // obj.diagnosisDescription = _.get(
      //   d,
      //   'diagnosisDescriptions?.diagnosisName',
      // );
      // obj.diagnosis.diagnosisCode = _.get(
      //   d,
      //   'diagnosisDescriptions?.diagnosisCode',
      // );
      obj.diagnosisDescription = _.get(
        d,
        'diagnosisDescriptions.diagnosis.diagnosisDescription',
      );
      obj.admissionCondition = _.get(d, 'admissionCondition');
      obj.prefix = _.get(d, 'diagnosisDescriptions.prefix') ?? '';
      obj.suffix = _.get(d, 'diagnosisDescriptions.suffix') ?? '';
      obj.treatOutcome = _.get(d, 'treatOutcome');
      // } else {
      //   obj = d;
      // }
      // console.log(obj, '诊断');
      return obj;
    }) ?? []),
  );
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.clinicalPathwayManage =
    _.get(input, 'clinicalPathwayManage');
  output.saveDiagnosisCompareRecordBtoList = _.get(
    input,
    'diagnosisCompareRecordDtoList',
  );
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.followUpClinic =
    _.get(input, 'followUpClinic');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.followUpWeeks =
    _.get(input, 'followPeriod.followUpWeeks');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.followUpMonths =
    _.get(input, 'followPeriod.followUpMonths');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.followUpYears =
    _.get(input, 'followPeriod.followUpYears');
  output.saveInpVisitForMedicalRecordBto.inpVisitExtensionBto.infusion = _.get(
    input,
    'infusion',
  );
  output.updatePatientBto.patientIdentificationBtoList =
    input.patientIdentificationBtoList ?? [];
  // console.log(input);
  // output.updatePatientBto.patientIdentificationBtoList.push(
  //   ...(_.get(
  //     input.patientIdentificationBtoList,
  //     'patientIdentificationDtoList',
  //   )?.map((d, i) => {
  //     const obj = {};
  //     // TODO 请确认下行字段是否符合逻辑
  //     obj.identificationNumber = _.get(d, 'identificationNumber');
  //     // TODO 请确认下行字段是否符合逻辑
  //     obj.identificationClass = _.get(d, 'identificationClass');
  //     // TODO 没有匹配到合适字段 obj.operator = null; // String 操作人
  //     // TODO 没有匹配到合适字段 obj.creator = null; // String 创建人
  //     return obj;
  //   }) ?? []),
  // );
  return output;
};
