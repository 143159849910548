import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicRecordOrderLabController_queryOrderLabOrderReturnVoByOrderLabOrderReturnQto_ff9fa6:
    {
      method: 'post',
      url: '/api/clinic-record-order-lab/query-order-lab-order-return-vo-by-order-lab-order-return-qto',
      parameterFomatter: (data?: {
        qto: OrderLabOrderReturnQtoClinicRecordOrderLabPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderLabOrderReturnVoClinicRecordOrderLabEntranceWebVo[],
      ) => data,
    },
  ClinicRecordOrderExamController_queryOrderExamOrderReturnVoByOrderExamOrderReturnQto_0bd5e4:
    {
      method: 'post',
      url: '/api/clinic-record-order-exam/query-order-exam-order-return-vo-by-order-exam-order-return-qto',
      parameterFomatter: (data?: {
        qto: OrderExamOrderReturnQtoClinicRecordOrderExamPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: OrderExamOrderReturnVoClinicRecordOrderExamEntranceWebVo[],
      ) => data,
    },
  ClinicRecordOrderExtensionController_createOutpOrderRefundApply_e3b1eb: {
    method: 'post',
    url: '/api/clinic-record-order-extension/create-outp-order-refund-apply',
    parameterFomatter: (data?: {
      btoParam: CreateOutpOrderRefundApplyBtoClinicRecordOrderExtensionServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
});
