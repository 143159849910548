// table
export const convertQueryErpPatientInTransferWaitInVoByErpPatientInTransferWaitInQtoRes2ErpPatientInTransferWaitInList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.displayId = _.get(d, 'patient.displayId');
        obj.name = _.get(d, 'patient.name');
        obj.gender = _.get(d, 'patient.gender');
        obj.treatAreaFrom = _.get(d, 'treatAreaFrom');
        obj.id = _.get(d, 'id');
        obj.erpVisitId = _.get(d, 'erpVisitId');
        return obj;
      }) ?? []),
    );
    return output;
  };
//   form

export const convertQueryErpPatientInTransferAcceptVoByErpVisitIdRes2ErpPatientInTransferAcceptForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.id');
    output.erpVisitId = _.get(input, 'data.erpVisit.id');
    output.name = _.get(input, 'data.patient.name');
    output.displayId = _.get(input, 'data.patient.displayId');
    output.gender = _.get(input, 'data.patient.gender');
    output.age = _.get(input, 'data.patient.age');
    output.birthAddress = _.get(input, 'data.patient.birthAddress');
    output.chargeTypeCode = _.get(
      input,
      'data.erpVisit.outpVisit.chargeTypeCode',
    );
    output.diseaseLevelName = _.get(input, 'data.erpVisit.diseaseLevel');
    output.birthday = _.get(input, 'data.patient.birthday'); // 出生日期
    output.prePaymentBalance = _.get(input, 'data.erpVisit.prePaymentBalance');
    output.contactPersonName = _.get(input, 'data.erpVisit.contactPersonName'); // 联系人姓名
    // 联系人电话
    output.contactPersonPhone = _.get(
      input,
      'data.erpVisit.contactPersonPhone',
    );
    output.arriveHospitalDateTime = _.get(
      input,
      'data.erpVisit.arriveHospitalDateTime',
    );
    output.arriveHospitalType = _.get(
      input,
      'data.erpVisit.arriveHospitalType',
    );
    output.nurseAcceptDateTime = _.get(
      input,
      'data.erpVisit.nurseAcceptDateTime',
    );
    output.bed = _.get(input, 'data.erpVisit.bed');
    output.bedPrice = _.get(input, 'data.erpVisit.bed.bedPrice');
    output.nursingClass = _.get(input, 'data.erpVisit.nursingClass');
    output.patientCondition = _.get(input, 'data.erpVisit.patientCondition');
    output.isolationType = _.get(input, 'data.erpVisit.isolationType');
    output.fastingIndicator = _.get(input, 'data.erpVisit.fastingIndicator');
    output.attendingDoctor = _.get(input, 'data.erpVisit.attendingDoctor');
    output.diagnosisRecordWithDiagnosisVoList = _.get(
      input,
      'data.erpVisit.outpVisit.diagnosisRecordWithDiagnosisVoList',
    );
    // output.diagnosisRecordWithDiagnosisVoList = [
    //   {
    //     id: 1,
    //     prefix: '浅醉',
    //     suffix: '后最',
    //     selectValue: '诊断的',
    //     diagnosisDescription: '诊断的描述',

    //     diagnosisCode: '01',
    //     diagnosisName: '诊断',
    //   },
    // ];
    output.isolationIndicator = _.get(
      input,
      'data.erpVisit.isolationIndicator',
    );
    output.erpPreExamRecordMasterWithDetailVoList = [
      {
        ..._.get(
          input,
          'data.erpVisit.erpPreExamRecordMasterWithDetailVoList',
        )?.[0],
        diseaseLevelName: {
          ..._.get(input, 'data.erpVisit.diseaseLevel'),
        },
      },
    ];
    return output;
  };
// form 保存
export const convertErpPatientInTransferAcceptForm2AcceptErpPatientFuncFlowExecutorReq =
  (input) => {
    console.log(input, 'inputinput');
    const _ = window._ ?? {};
    const output = {};
    output.createDiagnosisRecordBtoList = [];
    output.updateErpVisitForTransferBto = {};
    output.updateErpVisitForTransferBto.id = _.get(input, 'erpVisitId');
    output.updateErpVisitForTransferBto.nurseAcceptDateTime = _.get(
      input,
      'nurseAcceptDateTime',
    );
    output.updateErpVisitForTransferBto.bedId =
      _.get(input, 'bed.id') ?? _.get(input, 'bed');
    output.updateErpVisitForTransferBto.nursingClass = _.get(
      input,
      'nursingClass',
    );
    output.updateErpVisitForTransferBto.isolationIndicator = _.get(
      input,
      'isolationIndicator',
    );
    output.updateErpVisitForTransferBto.fastingIndicator = _.get(
      input,
      'fastingIndicator',
    );
    output.updateErpVisitForTransferBto.patientCondition = _.get(
      input,
      'patientCondition',
    );
    output.updateErpVisitForTransferBto.attendingDoctorId = _.get(
      input,
      'attendingDoctor.id',
    );
    output.createOrUpdatePatientBillingRecordBto = {};
    // output.createOrUpdatePatientBillingRecordBto.prePaymentAmount = _.get(
    //   input,
    //   'prePaymentBalance',
    // ); // Bigdecimal 预交金金额
    return output;
  };
