import { createServices } from '@vs/vsf-boot';
export default createServices({
  LabController_getLabWithDepartmentById_4053dd: {
    method: 'post',
    url: '/api/lab/get-lab-with-department-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: LabClassDictionaryWithDepartmentVoLabEntranceWebVo,
    ) => data,
  },
  LabController_addDepartmentToLabClass_8b9e30: {
    method: 'post',
    url: '/api/lab/add-department-to-lab-class',
    parameterFomatter: (data?: {
      btoParam: AddDepartmentToLabClassBtoLabServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabClassListVoLabEntranceWebVo) => data,
  },
  DepartmentDtoController_getAllByDepartmentInstitutionQto_9aefb9: {
    method: 'post',
    url: '/api/organization/get-all-by-department-institution-qto',
    parameterFomatter: (data?: {
      qto: DepartmentInstitutionQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
});
