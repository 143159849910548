import React, { useEffect } from 'react';
import vsf, { definePage } from '@vs/vsf-boot';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import { getPinYinCode } from 'lancet-pywb/PY_WB';
import {
  getDepartmentStaffList,
  getMedicalTechnologyDepartmentList,
} from './services';
import { useRequest } from 'ahooks';
import { buildTree, filterTreeData } from '@/components/TreeMenu/utils';
const TreeMenuIndex = (props) => {
  const { department, setDepartment, MenuCurrent } = props;
  return (
    <div className="TreeMenu">
      <Tree {...{ department, setDepartment, MenuCurrent }} />
    </div>
  );
};
const Tree = (props) => {
  const { department, MenuCurrent, setDepartment } = props;
  switch (MenuCurrent) {
    // 科室模板'mail'
    case 'mail':
      return <TreeDepartment {...{ department, setDepartment }} />;
    // 个人模板‘app’
    case 'app':
      return <TreeStaff {...{ MenuCurrent, department, setDepartment }} />;
  }
};
// 科室模板'mail'
const TreeDepartment = (props) => {
  const { department, setDepartment } = props;
  const { data, run } = useRequest(
    async () => {
      const res = await getMedicalTechnologyDepartmentList();
      const data = buildTree(res?.data);
      setDepartment(data?.[0]);
      return data;
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    run();
  }, []);
  return (
    <TreeMenu
      treeData={data ?? []}
      fieldNames={{ title: 'name', key: 'id' }}
      search={{
        status: true,
      }}
      row={{
        expand: true,
        cancelSelect: true,
      }}
      onSelect={(_value) => {
        if (department?.id == _value?.id) return setDepartment();
        setDepartment(_value);
      }}
      firstly={true}
    />
  );
};
// 个人模板'app'
const TreeStaff = (props) => {
  const { department, setDepartment } = props;
  return (
    <TreeMenu
      isTree={true}
      loadData={[getDepartmentStaffList]}
      fieldNames={{ title: 'name', key: 'id', children: 'children' }}
      search={{
        status: true,
      }}
      row={{
        expand: true,
        cancelSelect: true,
      }}
      onSelect={(_value) => {
        if (department?.id == _value?.id || _value?.parent)
          return setDepartment();
        setDepartment(_value);
      }}
    />
  );
};
export default definePage(TreeMenuIndex);
