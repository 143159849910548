import { createServices } from '@vs/vsf-boot';
export default createServices({
  SpecimenController_getAllBySpecimenWithPatternQto_47cbca: {
    method: 'post',
    url: '/api/specimen/get-all-by-specimen-with-pattern-qto',
    parameterFomatter: (data?: {
      qto: SpecimenWithPatternQtoSpecimenPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo[]) =>
      data,
  },
  SpecimenBOController_createSpecimen_f83102: {
    method: 'post',
    url: '/api/specimen/create-specimen',
    parameterFomatter: (data?: {
      btoParam: CreateSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenBOController_updateSpecimen_b99bfd: {
    method: 'post',
    url: '/api/specimen/update-specimen',
    parameterFomatter: (data?: {
      btoParam: UpdateSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenBOController_deleteSpecimen_e2a2f4: {
    method: 'post',
    url: '/api/specimen/delete-specimen',
    parameterFomatter: (data?: {
      btoParam: DeleteSpecimenBtoSpecimenServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo) => data,
  },
  SpecimenController_getAllBySpecimenWithPatternNewQtoBySpecimenWithPatternNewQto_8f8276:
    {
      method: 'post',
      url: '/api/specimen/get-all-by-specimen-with-pattern-new-qto-by-specimen-with-pattern-new-qto',
      parameterFomatter: (data?: {
        qto: SpecimenWithPatternNewQtoSpecimenPersistQto;
      }) => data,
      responseFormatter: (_, __, data?: SpecimenVoSpecimenEntranceWebVo[]) =>
        data,
    },
});
