import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Tag,
  VSPromiseTable,
  VSTableAction,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import className from 'classnames';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { mockDoctorId } from '@/mock';
import { cdssShow } from '@/mock';
import Icon from '@/module/cpoe/Icon';
import Block from '@/pages/Index/components/block_no_padding';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY, getScrollYRem } from '@/utils';

import EditFamily from './edit/edit_family';

const Index = (props) => {
  const { editModalWidth = 680, editModalHeight = 500 } = props;
  const {
    user: { currentPatient, staffInfo, outVisitInfo },
  } = props?.stores ?? {};
  const _patientId = currentPatient?.patientId;

  // 家族史
  const getFamilyList = async () => {
    if (!_patientId) return {};
    const res =
      await vsf.services?.ClinicRecordHistoryController_getFamilyHistoryByPatientId_58159e?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          patientId: _patientId,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  return (
    <VSPromiseTable
      className="previous_history_list_tabel aspirin-table-empty-background-color"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-Frame-21" size={18} />,
        deleteText: <Icon type="icon-shanchuwudi" size={18} />,
        columnProps: {
          fixed: 'right',
          width: 60,
          // hideInTable: true,
        },
        onCanDelete: (row) => {
          return row?.recordDoctorId === staffInfo?.doctorDto?.staffId;
        },
      }}
      // locale={{
      //   emptyText: (dom) => {
      //     return <div>{dom}</div>;
      //     return '点击新增按钮添加相关家族史';
      //   },
      // }}
      pagination={false}
      scroll={{
        // y: 250,
        y: getScrollYRem(56),
      }}
      onFetch={getFamilyList}
      cardProps={{
        bodyStyle: {
          padding: 0,
        },
      }}
      id="familyTable"
      onUpdate={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_updateFamilyHistory_6ebedd?.(
            { btoParam: { ...(params ?? {}) }, extApiParam: {} },
          );
        if (res?.code == 200) {
          message.success('家族史更新成功');
        }
        vsf?.refs?.familyTable?.reload();
        return res;
      }}
      updateLinkPage={{
        linkPage: () => <EditFamily title="update" />,
        modalProps: {
          title: `编辑家族史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.familyForm?.validateFieldsReturnFormatValue();
            return ret;
          } catch (e) {
            return false;
          }
        },
      }}
      onAdd={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_createFamilyHistory_a03440?.(
            {
              btoParam: {
                ...(params ?? {}),
                patientId: _patientId,
                outpVisitId: outVisitInfo?.outpVisit?.id,
                // allergyNumber: 1,
              },
              extApiParam: {},
            },
          );
        if (res?.code == 200) {
          message.success('家族史新增成功');
        }
        vsf?.refs?.familyTable?.reload();
        return res;
      }}
      addLinkPage={{
        linkPage: () => <EditFamily />,
        modalProps: {
          title: `新增家族史`,
          className: 'aspirin-linkpage',
          width: editModalWidth,
          centered: true,
          bodyStyle: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: editModalHeight,
          },
        },
        onLinkPageValidate: async (params) => {
          try {
            const ret =
              await vsf.refs.familyForm?.validateFieldsReturnFormatValue();
            return ret;
          } catch (e) {
            return false;
          }
        },
      }}
      onRemove={async (params) => {
        const res =
          await vsf?.services?.ClinicRecordHistoryController_deleteFamilyHistory_6f04c5?.(
            { btoParam: params, extApiParam: {} },
          );
        if (res?.code == 200) {
          message.success('家族史删除成功');
        }
        return res?.code === 200;
      }}
    >
      <VSTableColumn
        dataIndex={['familyRelationship']}
        title="家属关系"
        editable={false}
        valueType="select"
        dataSource={vsf.stores.dbenums.enums.FAMILY_RELATIONS_DICT}
        fieldProps={{}}
        render={(dom) => {
          return <div style={{ color: '#3276E8' }}>{dom}</div>;
        }}
      />

      <VSTableColumn
        dataIndex={['familyName']}
        title="疾病名称"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '数据格式错误',
              type: 'string',
              min: 0,
              max: 4000,
            },
          ],
        }}
        fieldProps={{}}
      />

      {/* <VSTableColumn
      dataIndex={['memo']}
      title="备注"
      editable={false}
      valueType="text"
      formItemProps={{
        rules: [
          {
            message: '数据格式错误',
            type: 'string',
            min: 0,
            max: 4000,
          },
        ],
      }}
      fieldProps={{}}
    /> */}

      <VSTableColumn
        dataIndex={['startDate']}
        title="发病时间"
        editable={false}
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD' }}
      />

      {/* <VSTableColumn
      dataIndex={['selfComplaintIndicator']}
      title="自述标识"
      editable={false}
      valueType="switch"
      fieldProps={{}}
      render={(dom, value) => {
        return <div>{}</div>;
      }}
    /> */}

      <VSTableColumn
        dataIndex={['inHospitalIndicator']}
        title="信息来源"
        editable={false}
        valueType="switch"
        fieldProps={{}}
        render={(dom, value) => {
          if (value?.inHospitalIndicator == null) return '';
          return (
            <div>{value?.inHospitalIndicator ? '本院发生' : '患者自述'}</div>
          );
        }}
      />
      <VSTableToolbar
        className="ant-pro-table-list-toolbar-padding"
        title={
          <div className="previous_history_tabletoolbar">
            <div className="previous_history_tabletoolbar_div"></div>
            家族史
          </div>
        }
      >
        <VSTableAddition
          // className="previous_history_tabletoolbar_addBtn"
          children="新增"
          size="small"
          type="primary"
          // style={{ backgroundColor: 'transparent', color: '#3276e8' }}
          // icon=""
        />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};

export default definePage(Index);
