import React from 'react';

import { Section } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const SystemConfigTypeTree = (props) => {
  
  const handleAddWithParent = (node) => {
    props.onChange?.({
      upInstitutionId: node?.id,
      upInstitutionName: node?.institutionName,
    });
  };

  return (
    <TreeMenu
      id="functionTree"
      loadData={async () => {
        const res =
          await vsf.services.ApplicationDtoController_getSysConfigBaseCodeWithoutRoot_b887c0(
            {
              codeType: '000400',
            },
          );
        return res;
      }}
      fieldNames={{ title: 'name', key: 'id', children: 'children' }}
      dataRelationFieldNames={{ id: 'code', parentId: 'parentCode' }}
      onSelect={(_value) => {
        props.onChange?.(_value);
      }}
      search={{ status: true, placeholder: '请输入医疗机构名称' }}
      title="数据分类"
      reload
      row={{
        // expand: true,
        extraShow: 'default',
      }}
      // context={{
      //   defaultContextMenu: {
      //     items: [
      //       {
      //         label: '新增医疗机构',
      //         key: 'addInstitution',
      //         hidden: false,
      //         close: true,
      //         callback: (node) => {
      //           props.onChange?.({});
      //         },
      //       },
      //     ],
      //     trigger: 'click',
      //   },
      // }}
    />
  );
};
export default definePage(SystemConfigTypeTree);
