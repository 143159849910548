import vsf, { vsService } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';

import storage from '@/utils/storage';

import { common } from './stores';

vsService.interceptors.response.use(
  (res) => {
    // console.log(window?.Cookies?.get('U_TOKEN'));
    // if (!window?.Cookies?.get('U_TOKEN')) {
    //   // storage.remove('staffInfo');
    //   // vsf.navigateTo(`/login?from=${window.location.pathname}`);
    // }
    if (res?.data?.code === 401 && res?.data?.errMsg === 'Not logged in!')
      return res;

    if (res.status !== 200 || (res?.data?.code && res?.data?.code !== 200)) {
      message.error(res?.data?.message || '请求错误');
    }
    return res;
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          storage.remove('staffInfo');
          vsf.navigateTo(`/login?from=${window.location.pathname}`);
      }
    }
    return Promise.reject(err);
  },
);

vsService.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    const userData = storage.get('userData');
    const currentApplication = common?.currentApplication;
    if (userData) {
      config.headers['Authorization'] = `Bearer ${userData}`;
    }

    config.headers['APPLICATION-ID'] = currentApplication?.id ?? null;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default vsService;
