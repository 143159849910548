import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpRegisterDefineController_getAllByClinicRegisterDefineQto_04b11a: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-define-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterDefineQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_createClinicRegisterDefine_54ab7e: {
    method: 'post',
    url: '/api/outp-register-define/create-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: CreateClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_updateClinicRegisterDefine_7ab8b4: {
    method: 'post',
    url: '/api/outp-register-define/update-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: UpdateClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_deleteClinicRegisterDefine_58468d: {
    method: 'post',
    url: '/api/outp-register-define/delete-clinic-register-define',
    parameterFomatter: (data?: {
      btoParam: DeleteClinicRegisterDefineBtoOutpRegisterDefineServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo,
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-type-dictionary-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterTypeDictionaryQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterTypeDictionaryVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
  OutpRegisterDefineController_getAllByClinicRegisterDefineNewQto_3be984: {
    method: 'post',
    url: '/api/outp-register-define/get-all-by-clinic-register-define-new-qto',
    parameterFomatter: (data?: {
      qto: ClinicRegisterDefineNewQtoOutpRegisterDefinePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicRegisterDefineVoOutpRegisterDefineEntranceWebVo[],
    ) => data,
  },
});
