import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  Todo,
  Tree,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  VSTableAddition,
  Modal,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useState, useEffect, useCallback } from 'react';

const RoleEditableTable = (props) => {
  return (
    <VSTable
      className="aspirin-table aspirin-table-nopadding"
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" />,
        deleteText: <Icon type="icon-shanchu1" />,
      }}
      onRecord={async () => ({ id: Math.random() })}
      value={props.value}
      onChange={(_value) => {
        props.onChange?.(_value);
      }}
      pagination={false}
    >
      <VSTableColumn title="序号" valueType="index" fieldProps={{}} />

      <VSTableColumn
        dataIndex={['roleCode']}
        title="角色编码"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['roleName']}
        title="角色名称"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableAddition type="primary" children="添加" />
    </VSTable>
  );
};

const StandardApplicationFuncitonEdit = (props) => {
  const { open, value, handleClose, handleCloseWithReload } = props;
  const [funcition, setFuncition] = useState({});
  const [targetKeys, setTargetKeys] = useState([]);
  const onChange = (keys) => {
    setTargetKeys(keys);
  };

  const getInfo = useCallback(async () => {
    if (value?.applicationClass) {
      const res =
        await vsf?.services?.ApplicationDtoController_getApplicationWithRoleById_cb1f5b(
          {
            id: value?.id,
          },
        );
      const { applicationVo, roleList, ...rest } = res?.data || {};
      const { name, applicationClass, id } = applicationVo || {};
      setFuncition({
        id,
        applicationClass,
        name,
        roleList,
      });
    } else {
      setFuncition({
        applicationClass: value?.code,
      });
    }
  }, [value]);

  const onSubmit = async () => {
    const values = await vsf?.refs?.FunctionForm?.validateFields();
    const { roleList, ...rest } = values;
    const funcName = rest?.id
      ? 'ApplicationBOController_updateApplication_62596e'
      : 'ApplicationBOController_createApplication_c83033';
    const res = await vsf?.services?.[funcName]?.({
      btoParam: { ...rest, template: true },
      extApiParam: {},
      roleList: roleList?.map((item) => {
        const { id, ...rest } = item;
        return rest;
      }),
    });
    if (res?.code === 200) {
      handleCloseWithReload?.();
    }
  };

  useEffect(() => {
    if (open) {
      getInfo();
    }
  }, [value, open]);

  return (
    <Modal
      open={open}
      title={value?.applicationClass ? '编辑标准工作站' : '新增标准工作站'}
      className="aspirin-linkpage aspirin-linkpage-static-height"
      onOk={onSubmit}
      onCancel={handleClose}
      width={876}
    >
      <VSControlledForm id="FunctionForm" value={funcition}>
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout columnCount={2}>
          <VSFormItem
            name={['name']}
            label="工作站名称"
            valueType="text"
            rules={[
              { message: 'name长度不合法', type: 'string', min: 0, max: 50 },
              { required: true },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['applicationClass']}
            label="工作站分类"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.SYS_CONFIG_TYPE}
            rules={[{ required: true }]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <div className="aspirin-form-self-label">工作站角色</div>
        <VSFormItem
          name={['roleList']}
          valueType="custom"
          customComponent={(props) => <RoleEditableTable {...props} />}
          fieldProps={{}}
        />
      </VSControlledForm>
    </Modal>
  );
};
export default definePage(StandardApplicationFuncitonEdit);
