import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSDataProvider,
} from '@vs/vsf-kit';
import {
  askDtoToBto,
  askRomanAlphabet,
} from '@/pages/Index/components/func/ask';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

import { labelColWidth } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';

const FormLayoutTitle = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #343b42;
  border-bottom: 1px solid #d6d8db;
  margin-bottom: 24px;
`;

const CreateOrUpdateSupplier = (props) => {
  /**
   * 产商字典
   * @type {DrugFirmDictionaryVoDrugDrugEntranceWebVo}
   */
  const [firmDirectoryVo, setFirmDirectoryVo] = useState();
  /**
   * 从vo中获取到id作为参数
   * @param {any} vo
   * @returns {object}
   */
  const getIdFromVo = (vo) => {
    return { id: vo?.id };
  };

  return (
    <Section>
      {props?.value?.id && (
        <VSDataProvider
          service={
            vsf.services
              .DrugFirmDictionaryDtoController_getFirmDirectoryById_789fef
          }
          params={getIdFromVo(props.value)}
          onDataLoaded={(_value) => {
            setFirmDirectoryVo({ ..._value });
          }}
        />
      )}

      <VSControlledForm
        vsid="70329"
        id="firmManageForm"
        value={firmDirectoryVo}
        onChange={(_value) => {
          props.onChange?.(_value);
        }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['firmClass']}
          label="厂商类别"
          style={{ display: 'none' }}
          valueType="select"
          dataSource={[
            { label: '生产商', value: 'PRODUCER' },
            { label: '供应商', value: 'SUPPLIER' },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['firmType']}
          label="厂商属性"
          style={{ display: 'none' }}
          valueType="select"
          dataSource={[
            { label: '药品', value: 'DRUG' },
            { label: '材料', value: 'MATERIAL' },
            { label: '设备', value: 'DEVICE' },
          ]}
          fieldProps={{}}
        />

        <FormLayoutTitle children="基本信息" />

        <VSFormLayout columnCount={3} labelCol={labelColWidth[10]}>
          <VSFormItem
            name={['firmId']}
            label="厂商标识/简称"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              { required: true },
            ]}
            fieldProps={{}}
            labelCol={{
              style: {
                width: '10rem',
              },
            }}
          />

          <VSFormItem
            name={['firmName']}
            label="厂商"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ]}
            fieldProps={{
              onChange: debounce(
                ({ target }) => {
                  vsf.refs.firmManageForm.setFieldsValue({
                    inputCode: askRomanAlphabet(target.value),
                  });
                },
                30,
                { trailing: true },
              ),
            }}
          />

          <VSFormItem
            name={['inputCode']}
            label="拼音输入码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 10 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['stopIndicator']}
            label="是否停用"
            valueType="switch"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmDiscount']}
            label="扣率"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmAddressType']}
            label="地址类别"
            valueType="select"
            dataSource={[
              { label: '本地', value: 'LOCAL' },
              { label: '外地', value: 'NOT_LOCAL' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['accountantNumber']}
            label="会计编号"
            valueType="digit"
            fieldProps={{}}
          />

          <VSFormItem
            name={['documentWebType']}
            label="数据来源"
            valueType="select"
            dataSource={[
              { label: '非接口采购', value: 'NOT_INTERFACE_PURCHASE' },
              { label: '非平台采购', value: 'NOT_PLATFORM_PURCHASE' },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['uscc']}
            label="配送企业社会信用代码"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
            labelCol={{
              style: {
                width: '12rem',
              },
            }}
          />
        </VSFormLayout>

        <FormLayoutTitle children="详细信息" />
        <VSFormLayout
          columnCount={3}
          labelCol={{
            style: {
              width: '8rem',
            },
          }}
        >
          <VSFormItem
            name={['firmPhone']}
            label="厂商电话"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmTaxNumber']}
            label="厂商税号"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmAccount']}
            label="厂商帐号"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmBank']}
            label="开户银行"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmContactPerson']}
            label="联系人"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmCellphone']}
            label="移动电话"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 60 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmEmailAddress']}
            label="电子邮箱"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['firmAddress']}
            label="厂商地址"
            valueType="address"
            fieldProps={{}}
          />

          <VSFormItem
            name={['memo']}
            label="备注"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 255 },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(CreateOrUpdateSupplier);
