import { getPromiseHeight } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Menu,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import {
  getNoOrderInpBillingDetailList,
  getOrderInpBillingDetailList,
  getOrderInpOrderCostGroupList,
  getOrderListByInpVisitId,
  saveInpBillingDetails,
  saveOrderInpBillingDetails,
} from './services';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import TemplateModalContainer from '../TemplateModalContainer';
import dayjs from 'dayjs';
import { includes, isNumber, isObject } from 'lodash';
import ProjectNameSelect from '../ProjectNameSelect';
import { askFixed, askTotalAmount } from '@/pages/Index/components/func/ask';
import { onTableFieldEllipsis } from '@/utils/setDataUtil';

const TableMenu = (props) => {
  // 常量
  const { inpVisitPatient } = props,
    [current, setCurrent] = useState('mail'),
    [ModalProps, setModalProps] = useState({
      open: false,
    }),
    [selectedRows, setSelectedRows] = useState([]),
    [Mistake_leftTabel_reloda, setMistake_leftTabel_reloda] = useState(),
    [ProjectProps, setProjectProps] = useState({}),
    [MsgOrderProps, setMsgOrderProps] = useState({}),
    [inpBillingDetailList, setInpBillingDetailList] = useState();
  // 常量函数
  const onChanegMenu = async (e) => {
      setCurrent(e.key);
      onRestState();
    },
    // 按钮
    onButton = (button) => {
      return (
        <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
          {button.map((item) => (
            <ButtonAndKeyDown key={item.text} {...item} type="primary">
              {item.text}
            </ButtonAndKeyDown>
          ))}
        </div>
      );
    },
    //收费弹窗回调
    onTemplateModalCallback = async (params) => {
      const TableReal = await onChanegMenuTableReal();
      await TableReal?.add(params);
      const TableRealValues = await TableReal?.getValues();
      await TableReal?.scrollTo(
        TableRealValues?.[TableRealValues?.length - 1]?.id,
      );
      message?.success('模板导入成功。');
    },
    //按钮disabled
    onBtnDltDis = () => {
      let isRowDis = false,
        i = 0;
      while (selectedRows?.length > i) {
        if (['Sava'].includes(selectedRows[i]?.TheRowType)) {
          isRowDis = true;
          break;
        }
        i++;
      }
      return isRowDis;
    },
    onBtnDisabled = () => !inpVisitPatient?.inpVisitId,
    //列表实例
    onChanegMenuTableReal = async () => {
      let TableReal;
      if (includes(['mail'], current))
        // 医嘱费用
        TableReal = await vsf?.refs
          ?.inpMedicalTechnologyOrderChargeOrderBillingDetailList;
      if (includes(['app'], current))
        // 非医嘱费用
        TableReal = await vsf?.refs
          ?.inpMedicalTechnologyChargeNoOrderChargeDetailList;
      return TableReal ?? {};
    },
    onChanegUpdata = async (ele) => {
      let params = {};
      if (isObject(ele?.applyDepartmentName)) {
        params.applyDepartmentName =
          ele?.applyDepartmentName?.applyDepartmentName;
        params.applyDepartmentId = ele?.applyDepartmentName?.applyDepartmentId;
      }

      if (isObject(ele?.applyStaffName)) {
        params.applyStaffName = ele?.applyStaffName?.staffName;
        params.applyStaffId = ele?.applyStaffName?.staffId;
      }

      if (isObject(ele?.performStaffName)) {
        params.performStaffName = ele?.performStaffName?.staffName;
        params.performStaffId = ele?.performStaffName?.staffId;
      }
      return { ...ele, ...params };
    },
    // 按钮props
    onButtonProps = {
      btnDelete: {
        ghost: true,
        disabled: onBtnDisabled() || onBtnDltDis(),
        text: '删除',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealSelectedRowValues;
          TableReal = await onChanegMenuTableReal();
          TableRealSelectedRowValues = await TableReal?.getSelectedRowValues();
          if (TableRealSelectedRowValues?.length <= 0)
            return message?.error('请选择可删除的费用');
          let i = 0,
            r = 0;
          while (TableRealSelectedRowValues?.length > i) {
            if (
              ['New', 'Temp'].includes(TableRealSelectedRowValues[i].TheRowType)
            ) {
              await TableReal?.remove(TableRealSelectedRowValues[i]);
              r++;
            }
            i++;
          }
          setSelectedRows([]);
          r > 0 && message?.success('删除成功');
        },
      },
      btnAdd: {
        disabled: onBtnDisabled(),
        text: '新增',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealValues;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.add({});
          TableRealValues = await TableReal?.getValues();
          TableReal?.scrollTo(
            TableRealValues?.[TableRealValues?.length - 1]?.id,
          );
        },
      },
      btnTemplate: {
        disabled: onBtnDisabled(),
        text: '收费模板',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => setModalProps({ open: true }),
      },
      btnSava: {
        disabled: onBtnDisabled(),
        text: '保存',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.getEditForm()?.validateFieldsReturnFormatValue();
          const request = async (params) => {
            if (params.length <= 0)
              return message?.error('请新增费用或输入调整数量');
            switch (current) {
              // 医嘱
              case 'mail':
                return await saveOrderInpBillingDetails?.(params);
              // 非医嘱
              case 'app':
                return await saveInpBillingDetails?.(params);

              default:
                return {};
            }
          };
          const res = await request?.(
            TableReal?.getValues()
              ?.filter(
                (ele) =>
                  ['New', 'Temp'].includes(ele?.TheRowType) ||
                  isNumber(ele?.adjustAmount) ||
                  !ele?.billingStatus,
              )
              ?.map?.((ele) => ({
                ...ele,
                patientId: inpVisitPatient?.patientId,
              })),
          );
          if (res?.code !== 200 || ![''].includes(res?.message)) return;
          message?.success('保存成功');
          // if (['mail'].includes(current))
          TableReal?.reload?.();
          // 非医嘱
          if (['app'].includes(current))
            return setMistake_leftTabel_reloda(Math?.random?.());
        },
      },
    },
    onRestState = () => {
      setProjectProps({});
      setMsgOrderProps({});
      setInpBillingDetailList();
    };

  useEffect(() => {
    onRestState();
  }, [inpVisitPatient]);
  return (
    <div className="TableMenu">
      <Menu
        mode="horizontal"
        onClick={onChanegMenu}
        selectedKeys={[current]}
        items={[
          {
            label: '医嘱费用',
            key: 'mail',
          },
          {
            label: '非医嘱费用',
            key: 'app',
          },
        ]}
      />
      <div className="Components">
        <Components
          {...{
            current,
            inpVisitPatient,
            onButton,
            onButtonProps,
            selectedRows,
            setSelectedRows,
            onChanegUpdata,
            ...props,
            Mistake_leftTabel_reloda,
            setModalProps,
            ProjectProps,
            setProjectProps,
            MsgOrderProps,
            setMsgOrderProps,
            inpBillingDetailList,
            setInpBillingDetailList,
          }}
        />
        <TemplateModalContainer
          {...{ ModalProps, setModalProps, onTemplateModalCallback }}
        />
      </div>
    </div>
  );
};
// 组件分叉；
const Components = (props) => {
  const { current, MsgOrderProps, setMsgOrderProps } = props;

  switch (current) {
    // 医嘱费用
    case 'mail':
      return (
        <div className="contianer-table">
          <Message {...{ ...props, MsgOrderProps, setMsgOrderProps }} />
          <Project {...{ ...props, MsgOrderProps }} />
        </div>
      );
    // 非医嘱费用
    case 'app':
      return (
        <div className="contianer-table">
          <Mistake {...props} />
        </div>
      );
  }
};
// 医嘱费用
const Message = (props) => {
  return (
    <div className="Message" id="Message">
      <p className="title" id="MessageTitle">
        医嘱信息
      </p>
      <MessageTable {...props} />
    </div>
  );
};
// 医嘱信息
const MessageTable = (props) => {
  const { inpVisitPatient, MsgOrderProps, setMsgOrderProps, setProjectProps } =
      props,
    [yMaxHeight, setYMaxHeight] = useState(220),
    { data, run } = useRequest(
      async (params) => {
        if (!params?.inpVisitId) return { data: [] };
        const res = await getOrderListByInpVisitId(params);
        if (res?.total <= 0) message?.success('未查询到患者医嘱信息');
        return res;
      },
      { manual: true },
    );
  getPromiseHeight('Message')?.then((res) => {
    setYMaxHeight(res - 55);
  });
  useEffect(
    () => inpVisitPatient && run?.({ inpVisitId: inpVisitPatient?.inpVisitId }),
    [inpVisitPatient],
  );
  return (
    <VSTable
      pagination={false}
      className="common_table"
      scroll={{
        y: yMaxHeight,
      }}
      value={data?.data}
      // value={[
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      // ]}
      bordered
      rowClassName={(node) => {
        return node?.id === MsgOrderProps?.id
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
          : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
      }}
      onRow={(node) => {
        return {
          onClick: () => {
            setProjectProps();
            if (node?.id === MsgOrderProps?.id) return setMsgOrderProps();
            setMsgOrderProps(node);
          },
        };
      }}
    >
      <VSTableColumn
        dataIndex={['clinicClass']}
        title="医疗类别"
        valueType="select"
        dataSource={[
          { label: '门诊', value: 'OUTP' },
          { label: '住院', value: 'INP' },
          { label: '急诊', value: 'ERP' },
          { label: '体检', value: 'PHYSICAL_EXAM' },
          { label: '挂号', value: 'CLINIC_REGISTER' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staffName']}
        title="开单医生"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['orderText']}
        title="医嘱正文"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['dosage']}
        title="剂量"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
      />

      <VSTableColumn
        dataIndex={['frequency']}
        title="频次"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['repeatIndicator']}
        title="长临"
        valueType="text"
        fieldProps={{}}
        render={(_, { repeatIndicator }) => (repeatIndicator ? '长' : '临')}
      />

      <VSTableColumn
        dataIndex={['orderClass']}
        title="医嘱类别"
        valueType="select"
        dataSource={[
          { label: '西药', value: 'DRUG' },
          { label: '中药', value: 'HERB' },
          { label: '检验', value: 'LAB' },
          { label: '检查', value: 'EXAM' },
          { label: '治疗', value: 'TREAT' },
          { label: '手术', value: 'OPERATION' },
          { label: '麻醉', value: 'ANESTHESIA' },
          { label: '护理', value: 'NURSING' },
          { label: '膳食', value: 'MEAL' },
          { label: '营养', value: 'NUTRITION' },
          { label: '会诊', value: 'CONSULTATION' },
          { label: '用血', value: 'BLOOD' },
          { label: '其他', value: 'OTHER' },
          { label: '病理', value: 'PATHOLOGY' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['createDateTime']}
        title="开单时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['stopOrderDateTime']}
        title="结束时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        dataIndex={['orderStatus']}
        title="医嘱状态"
        valueType="select"
        dataSource={[
          { label: '保存', value: 'SAVE' },
          { label: '提交', value: 'SUBMIT' },
          { label: '执行', value: 'PERFORM' },
          { label: '停止', value: 'STOP' },
          { label: '作废', value: 'CANCEL' },
          { label: '审核未通过', value: 'AUDIT_FAIL' },
        ]}
        fieldProps={{}}
      />
    </VSTable>
  );
};
// 医嘱费用--项目
const Project = (props) => {
  const {
      onButton,
      onButtonProps,
      MsgOrderProps,
      setModalProps,
      inpVisitPatient,
      ProjectProps,
      setProjectProps,
    } = props,
    { btnDelete, btnTemplate, btnAdd, btnSava } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(312);
  getPromiseHeight('Project')?.then((res) => {
    setYMaxHeight(res - 76);
  });
  // 收费弹窗导入按钮回调
  const onTemplateModalCallback = async (params) => {
      const res = await saveOrderInpBillingDetails?.(
        params?.map((ele) => ({
          ...ele,
          orderId: MsgOrderProps?.id,
          inpVisitId: inpVisitPatient?.inpVisitId,
          patientId: inpVisitPatient?.patientId,
          operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
        })),
      );
      if (res?.code !== 200) return;
      await vsf?.refs?.inpMedicalTechnologyOrderChargeOrderCostList?.reload();
      await vsf?.refs?.inpMedicalTechnologyOrderChargeOrderBillingDetailList?.reload();
      message?.success('保存成功');
    },
    onBtnDis = () => !MsgOrderProps?.id;
  return (
    <div className="Project" id="Project">
      <div className="left">
        <div className="title-container">
          <p className="title">计费项目</p>
          <div className="btn">
            {onButton([
              {
                ...btnTemplate,
                onClick: async () => {
                  setModalProps({
                    open: true,
                    onTemplateModalCallback: onTemplateModalCallback,
                  });
                },
                disabled: onBtnDis(),
              },
              {
                ...btnAdd,
                disabled: onBtnDis(),
                onClick: async () => {
                  let TableReal, TableRealValues;
                  TableReal =
                    vsf?.refs?.inpMedicalTechnologyOrderChargeOrderCostList;
                  await TableReal?.add({});
                  TableRealValues = await TableReal?.getValues();
                  TableReal?.scrollTo(
                    TableRealValues?.[TableRealValues?.length - 1]?.id,
                  );
                },
              },
            ])}
          </div>
        </div>
        <Project_leftTable
          {...{ yMaxHeight, MsgOrderProps, ProjectProps, setProjectProps }}
        />
      </div>
      <div className="right">
        <div className="title-container">
          <p className="title">计费项目明细</p>
          <div className="btn">
            {onButton([
              btnDelete,
              { ...btnAdd, disabled: !ProjectProps?.itemId },
              btnSava,
            ])}
          </div>
        </div>
        <Propsject_rightTable
          {...{ ...props, yMaxHeight, MsgOrderProps, ProjectProps }}
        />
      </div>
    </div>
  );
};
// 计费项目
const Project_leftTable = (props) => {
  const { yMaxHeight, MsgOrderProps, ProjectProps, setProjectProps } = props;
  const onFetch = useCallback(
    async (params) => {
      setProjectProps();
      if (!MsgOrderProps?.id) return [];
      const res = await getOrderInpOrderCostGroupList({
        orderId: MsgOrderProps?.id,
      });
      return res;
    },
    [MsgOrderProps?.id],
  );

  // const onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //     ],
  //   };
  // });

  return (
    <VSPromiseTable
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {},
      }}
      id="inpMedicalTechnologyOrderChargeOrderCostList"
      pagination={false}
      className="common_table"
      scroll={{ y: yMaxHeight }}
      onFetch={onFetch}
      bordered
      rowClassName={(node) => {
        const therow = vsf?.refs?.inpMedicalTechnologyOrderChargeOrderCostList
          ?.getValues()
          ?.find((ele) => ele.id === node?.id);
        return therow?.itemId === ProjectProps?.itemId
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
          : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
      }}
      onRow={(node) => {
        return {
          onClick: async () => {
            const therow =
              vsf?.refs?.inpMedicalTechnologyOrderChargeOrderCostList
                ?.getValues()
                ?.find((ele) => ele.id === node?.id);
            if (therow?.itemId === ProjectProps?.itemId)
              return setProjectProps();
            setProjectProps({
              ...therow,
              itemName: isObject(therow?.itemName)
                ? therow?.itemName?.label
                : therow?.itemName,
            });
          },
        };
      }}
      onRecord={async () => {
        let params = {
          TheRowType: 'New', // 新增标识
          itemId: '',
          itemClass: '/',
          itemSpecification: '/',
          itemName: '请选择项目名称',
        };
        return {
          id: `TEMP_${Math?.random?.()}`,
          ...params,
        };
      }}
    >
      <VSTableColumn
        dataIndex={['itemClass']}
        title="项目分类"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        preview
      />

      <VSTableColumn
        dataIndex={['itemName']}
        title="项目名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        width={250}
        fieldProps={{
          placeholder: '请选择',
        }}
        renderFormItem={(schema, rows, form) => {
          let component = onTableFieldEllipsis(rows?.record?.itemName ?? '/');
          if (includes(['New'], rows?.record?.TheRowType))
            component = (
              <ProjectNameSelect
                {...{
                  ...schema?.originProps,
                  ...rows,
                  form,
                  table_id: 'inpMedicalTechnologyChargeNoOrderChargeDetailList',
                }}
              />
            );
          return component;
        }}
      />

      <VSTableColumn
        dataIndex={['itemSpecification']}
        title="项目规格"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />
    </VSPromiseTable>
  );
};
// 计费项目明细
const Propsject_rightTable = (props) => {
  const {
      yMaxHeight,
      MsgOrderProps,
      ProjectProps,
      inpVisitPatient,
      selectedRows,
      setSelectedRows,
      operationDoctorData,
      onChanegUpdata,
    } = props,
    { common, user } = props?.stores || {},
    { staffInfo } = user || {},
    { applicationList, currentApplication } = common || {};
  const onRecord = async () => {
    let params = {
      ...ProjectProps,
      TheRowType: 'New', // 新增标识
      amount: 1, // 数量
      adjustAmount: null, //调整数量
      cost: askTotalAmount([[ProjectProps?.price, 1]]), // 计价费用
      charge: 0, // 应收金额
      orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
      operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
      applyStaffName: MsgOrderProps?.doctor?.staffName, // 操作人, // 开单人员（默认取医嘱的开单医生，可修改）
      applyStaffId: MsgOrderProps?.doctor?.id, // 开单人员id // 开单人员（默认取医嘱的开单医生，可修改）
      performStaffName: staffInfo?.staffName, // 员工姓名
      performStaffId: staffInfo?.id, // Long 录入人
      operatorStaffName: staffInfo?.staffName, // 操作人
      operatorStaffId: staffInfo?.id, // Long 操作人
      performDepartmentId: currentApplication?.department?.id, // 科室主键名称
      performDepartmentName: currentApplication?.department?.departmentName, // 科室名称
      applyDepartmentId: MsgOrderProps?.orderDepartment?.id, // 主键 开单科室
      applyDepartmentName: MsgOrderProps?.orderDepartment?.departmentName, //  开单科室
      inpVisitId: inpVisitPatient?.inpVisitId,
      patientId: inpVisitPatient?.patientId,
      orderId: MsgOrderProps?.id,
    };
    return {
      ...params,
      id: `TEMP_${Math?.random?.()}`,
    };
  };
  const onFetch = useCallback(
    async (params) => {
      if (!MsgOrderProps?.id || !ProjectProps?.itemId) return { data: [] };
      const res = await getOrderInpBillingDetailList({
        orderId: MsgOrderProps?.id,
        itemId: ProjectProps?.itemId,
      });
      if (res?.length <= 0) return [await onRecord()];
      return res;
    },
    [MsgOrderProps?.id, ProjectProps?.itemId],
  );
  // const onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //     ],
  //   };
  // });
  return (
    <VSPromiseTable
      rowSelection={{
        selectType: 'multiple',
        selectedRows,
        onSelectedRowsChange: (data) => setSelectedRows(data),
      }}
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {
          const therow =
            vsf?.refs?.inpMedicalTechnologyOrderChargeOrderBillingDetailList
              ?.getValues()
              ?.find((ele) => ele.id === key);
          if (
            Object.keys(value)?.includes('amount') &&
            includes(['New', 'Temp'], therow?.TheRowType)
          ) {
            form?.setFieldValue?.(
              [key, 'cost'],
              askTotalAmount([[therow?.price, record?.amount]]),
            );
          }
        },
      }}
      id="inpMedicalTechnologyOrderChargeOrderBillingDetailList"
      pagination={false}
      className="common_table"
      scroll={{ y: yMaxHeight, x: 2928 }}
      bordered
      onFetch={onFetch}
      onRecord={onRecord}
      onUpdate={async (data) => {
        return await onChanegUpdata(data);
      }}
    >
      <VSTableColumn
        dataIndex={['itemName']}
        title="名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        width={250}
        fieldProps={{
          placeholder: '请选择',
        }}
        renderFormItem={(schema, rows, form) =>
          onTableFieldEllipsis(rows?.record?.itemName ?? '/')
        }
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="aspirinDateTimePicker"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
        formItemProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        className="number_input"
        width={108}
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        fieldNames={{
          style: { textAlign: 'left' },
        }}
        fieldProps={{}}
        formItemProps={{
          rules: [
            {
              type: 'number',
              min: 1,
            },
          ],
        }}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        width={108}
        dataIndex={['adjustAmount']}
        title="调整数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          return {
            rules: [
              {
                validator: (_, value) => {
                  if (value === 0) return Promise.reject('不能为0');
                  // 使用正则表达式来匹配可能的输入：负号开头，后面跟着0个或多个数字
                  const regex = /^(-?\d*)$/;
                  if (value && !regex.test(value))
                    return Promise.reject('只能输入“-”负号');
                  if (
                    value &&
                    String(value).startsWith('-') &&
                    Math.abs(value) > entry?.amount
                  )
                    return Promise.reject('调整数量不可大于数量');
                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        preview={(_, node) =>
          !includes(['Sava'], node?.TheRowType) ||
          node?.amount == 0 ||
          !node?.billingStatus
        }
      />

      <VSTableColumn
        width={108}
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{}}
        preview
        renderFormItem={(_, { record }) => askFixed(record?.price, 2)}
      />

      <VSTableColumn
        width={68}
        dataIndex={['unit']}
        title="单位"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
        formItemProps={{}}
        preview
      />

      <VSTableColumn
        width={108}
        dataIndex={['cost']}
        title="应收金额"
        valueType="digit"
        fieldProps={{}}
        renderFormItem={(_, { record }) => askFixed(record?.cost, 2)}
        preview
      />

      <VSTableColumn
        width={168}
        dataIndex={['materialCode']}
        title="材料条码"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        dataIndex={['performStaffName']}
        title="执行医生"
        valueType="selectCode"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        preview={(_, node) => {
          let is = true;
          if (includes(['New', 'Temp'], node?.TheRowType)) is = false;
          if (['Sava'].includes(node?.TheRowType) && !node?.billingStatus)
            is = false;
          return is;
        }}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="selectCode"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        dataIndex={['applyStaffName']}
        title="开单医生"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['applyDepartmentName']}
        title="	开单科室"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />
      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        dataIndex={['operatorStaffName']}
        title="操作人"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />
      <VSTableColumn
        dataIndex={['billingStatus']}
        title="状态"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { billingStatus }) => {
          let str = '未划价',
            style = { color: 'rgba(48, 163, 19, 1)' };
          if (billingStatus) {
            str = '已划价';
            style = { color: 'rgba(50, 118, 232, 1)' };
          }
          return <sapn style={style}>{str}</sapn>;
        }}
        preview
      />
      <VSTableColumn
        dataIndex={['operateDate']}
        title="计费时间"
        valueType="date"
        formItemProps={{}}
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        preview
      />
      <VSTableColumn
        dataIndex={['medicalInsuranceType']}
        title="医保等级"
        valueType="select"
        dataSource={[
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['insuranceCode']}
        title="医保编码"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />
      <VSTableColumn
        dataIndex={['insuranceAuditNumber']}
        title="审批备案号"
        valueType="text"
        fieldProps={{}}
        preview
      />
    </VSPromiseTable>
  );
};
// 非医嘱费用
const Mistake = (props) => {
  // 常量
  const {
      onButton,
      onButtonProps,
      inpBillingDetailList,
      setInpBillingDetailList,
    } = props,
    { btnDelete, btnTemplate, btnAdd, btnSava } = onButtonProps,
    [yMaxHeight, setYMaxHeight] = useState(600);
  getPromiseHeight('Mistake')?.then((res) => {
    setYMaxHeight(res - 108);
  });
  // 常量函数
  const onChange = () => {};
  return (
    <div className="Mistake" id="Mistake">
      <div className="MistakeTable">
        <div className="left">
          <p className="title">计费日期</p>
          <Mistake_leftTabel
            {...{
              yMaxHeight,
              inpBillingDetailList,
              setInpBillingDetailList,
              ...props,
            }}
          />
        </div>
        <div className="right">
          <p className="title">费用明细表</p>
          <Mistake_rightTabel
            {...{ yMaxHeight, inpBillingDetailList, ...props }}
          />
        </div>
      </div>
      <div className="MistakeButton">
        {onButton([btnDelete, btnTemplate, btnSava, btnAdd])}
      </div>
    </div>
  );
};
// 计费日期
const Mistake_leftTabel = (props) => {
  const {
      yMaxHeight,
      inpVisitPatient,
      Mistake_leftTabel_reloda,
      inpBillingDetailList,
      setInpBillingDetailList,
    } = props,
    { data, run } = useRequest(
      async (params) => {
        if (!params?.inpVisitId) return;
        const res = await getNoOrderInpBillingDetailList(params);
        if (res?.total <= 0) message?.success('未查询到患者医嘱信息');
        if (inpBillingDetailList) {
          setInpBillingDetailList(
            res?.data?.find(
              (ele) => inpBillingDetailList?.orderDate == ele.orderDate,
            ),
          );
        } else {
          setInpBillingDetailList(res?.data?.[0]);
        }
        return res;
      },
      { manual: true },
    );

  useEffect(
    () =>
      inpVisitPatient?.inpVisitId &&
      run?.({ inpVisitId: inpVisitPatient?.inpVisitId }),
    [inpVisitPatient, Mistake_leftTabel_reloda],
  );
  return (
    <VSTable
      pagination={false}
      className="common_table"
      scroll={{ y: yMaxHeight }}
      value={data?.data}
      // value={[
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      // ]}
      rowClassName={(node) => {
        return node?.orderDate === inpBillingDetailList?.orderDate
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
          : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
      }}
      onRow={(node) => {
        return {
          onClick: () => {
            if (node?.orderDate === inpBillingDetailList?.orderDate)
              return setInpBillingDetailList();
            setInpBillingDetailList(node);
          },
        };
      }}
    >
      <VSTableColumn
        dataIndex={['clinicClass']}
        title="医疗类别"
        valueType="select"
        dataSource={[
          { label: '门诊', value: 'OUTP' },
          { label: '住院', value: 'INP' },
          { label: '急诊', value: 'ERP' },
          { label: '体检', value: 'PHYSICAL_EXAM' },
          { label: '挂号', value: 'CLINIC_REGISTER' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD' }}
      />
    </VSTable>
  );
};
// 费用明细表
const Mistake_rightTabel = (props) => {
  // 常量
  const {
      yMaxHeight,
      inpVisitPatient,
      selectedRows,
      setSelectedRows,
      operationDoctorData,
      onChanegUpdata,
      inpBillingDetailList,
    } = props,
    { common, user } = props?.stores || {},
    { staffInfo } = user || {},
    { applicationList, currentApplication } = common || {};
  const onFetch = useCallback(
    async (params) => {
      if (!inpBillingDetailList) return [];
      return inpBillingDetailList?.inpBillingDetailList;
    },
    [inpBillingDetailList],
  );

  // const onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //       {
  //         itemClass: 1,
  //       },
  //     ],
  //   };
  // });

  return (
    <VSPromiseTable
      onFetch={onFetch}
      rowSelection={{
        selectType: 'multiple',
        selectedRows,
        onSelectedRowsChange: (data) => setSelectedRows(data),
      }}
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {
          const therow =
            vsf?.refs?.inpMedicalTechnologyChargeNoOrderChargeDetailList
              ?.getValues()
              ?.find((ele) => ele.id === key);
          if (
            Object.keys(value)?.includes('amount') &&
            includes(['New', 'Temp'], therow?.TheRowType)
          ) {
            form?.setFieldValue?.(
              [key, 'cost'],
              askTotalAmount([[therow?.price, record?.amount]]),
            );
          }
        },
      }}
      id="inpMedicalTechnologyChargeNoOrderChargeDetailList"
      pagination={false}
      className="common_table"
      scroll={{ y: yMaxHeight, x: 2928 }}
      onRecord={async () => {
        let params = {
          TheRowType: 'New', // 新增标识
          itemName: '请选择项目名称',
          amount: 1, // 数量
          adjustAmount: null, //调整数量
          cost: 0, // 计价费用
          charge: 0, // 应收金额
          orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
          operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
          applyStaffName: staffInfo?.staffName, // 操作人, // 开单人员（默认取医嘱的开单医生，可修改）
          applyStaffId: staffInfo?.id, // 开单人员id
          performStaffName: staffInfo?.staffName, // 员工姓名
          performStaffId: staffInfo?.id, // Long 录入人
          operatorStaffName: staffInfo?.staffName, // 操作人
          operatorStaffId: staffInfo?.id, // Long 操作人
          performDepartmentId: currentApplication?.department?.id, // 科室主键名称
          applyDepartmentId: currentApplication?.department?.id, // 主键
          applyDepartmentName: currentApplication?.department?.departmentName,
          performDepartmentName: currentApplication?.department?.departmentName, // 科室名称
          inpVisitId: inpVisitPatient?.inpVisitId,
          patientId: inpVisitPatient?.patientId,
          ...props?.item,
        };
        if (props?.item?.itemId) {
          params.itemName = {
            label: item?.itemName, // 项目名称
            value: item?.itemId, // 项目编码
          };
          params.cost = props?.item?.price;
        }
        return {
          id: `TEMP_${Math?.random?.()}`,
          ...params,
        };
      }}
      bordered
      onUpdate={async (data) => {
        return await onChanegUpdata(data);
      }}
    >
      <VSTableColumn
        dataIndex={['itemClass']}
        title="类别"
        valueType="select"
        dataSource={vsf.stores.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
        preview
      />

      <VSTableColumn
        dataIndex={['itemName']}
        title="名称"
        valueType="custom"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        width={250}
        fieldProps={{
          placeholder: '请选择',
        }}
        renderFormItem={(schema, rows, form) => {
          let component = onTableFieldEllipsis(rows?.record?.itemName ?? '/');
          if (includes(['New'], rows?.record?.TheRowType))
            component = (
              <ProjectNameSelect
                {...{
                  ...schema?.originProps,
                  ...rows,
                  form,
                  table_id: 'inpMedicalTechnologyChargeNoOrderChargeDetailList',
                }}
              />
            );
          return component;
        }}
      />

      <VSTableColumn
        width={108}
        dataIndex={['itemSpecification']}
        title="规格"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="aspirinDateTimePicker"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
        formItemProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        className="number_input"
        width={108}
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={{
          rules: [
            {
              type: 'number',
              min: 1,
            },
          ],
        }}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        width={108}
        dataIndex={['adjustAmount']}
        title="调整数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          return {
            rules: [
              {
                // validator: (_, value) => {
                //   if (value && value <= 0) return Promise.reject('必须是整数');
                //   if (value > entry?.amount)
                //     return Promise.reject('调整数量不可大于数量');
                //   return Promise.resolve();
                // },
                validator: (_, value) => {
                  if (value === 0) return Promise.reject('不能为0');
                  // 使用正则表达式来匹配可能的输入：负号开头，后面跟着0个或多个数字
                  const regex = /^(-?\d*)$/;
                  if (value && !regex.test(value))
                    return Promise.reject('只能输入“-”负号');
                  if (
                    value &&
                    String(value).startsWith('-') &&
                    Math.abs(value) > entry?.amount
                  )
                    return Promise.reject('调整数量不可大于数量');
                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        preview={(_, node) =>
          !includes(['Sava'], node?.TheRowType) || node?.amount == 0
        }
      />

      <VSTableColumn
        width={108}
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{}}
        preview
        renderFormItem={(_, { record }) => askFixed(record?.price, 2)}
      />

      <VSTableColumn
        width={68}
        dataIndex={['unit']}
        title="单位"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
        formItemProps={{}}
        preview
      />

      <VSTableColumn
        width={108}
        dataIndex={['cost']}
        title="应收金额"
        valueType="digit"
        fieldProps={{}}
        renderFormItem={(_, { record }) => askFixed(record?.cost, 2)}
        preview
      />

      <VSTableColumn
        width={168}
        dataIndex={['materialCode']}
        title="材料条码"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        dataIndex={['performStaffName']}
        title="执行医生"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview
        //={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        dataIndex={['applyStaffName']}
        title="开单医生"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['applyDepartmentName']}
        title="	开单科室"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        preview={(_, node) => !includes(['New', 'Temp'], node?.TheRowType)}
      />
      <VSTableColumn
        dataIndex={['operatorStaffName']}
        title="操作人"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />
      <VSTableColumn
        dataIndex={['billingStatus']}
        title="状态"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { billingStatus }) => {
          let str = '未划价',
            style = { color: 'rgba(48, 163, 19, 1)' };
          if (billingStatus) {
            str = '已划价';
            style = { color: 'rgba(50, 118, 232, 1)' };
          }
          return <sapn style={style}>{str}</sapn>;
        }}
        preview
      />
      <VSTableColumn
        dataIndex={['operateDate']}
        title="计费时间"
        valueType="date"
        formItemProps={{}}
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        preview
      />
      <VSTableColumn
        dataIndex={['medicalInsuranceType']}
        title="医保等级"
        valueType="select"
        dataSource={[
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]}
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        dataIndex={['insuranceCode']}
        title="医保编码"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        preview
      />
      <VSTableColumn
        dataIndex={['insuranceAuditNumber']}
        title="审批备案号"
        valueType="text"
        fieldProps={{}}
        preview
      />
    </VSPromiseTable>
  );
};
export default definePage(TableMenu);
