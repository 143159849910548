/* eslint-disable no-case-declarations */
import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  Checkbox,
  List,
  message,
  Popover,
  Select,
  Tooltip,
  VSTable,
  VSTableColumn,
  VSTableEditable,
  VSTableInstance,
} from '@vs/vsf-kit';
import { useAsyncEffect, useEventListener, useGetState } from 'ahooks';
import classnames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import resolve from 'resolve';

import { InputUnit } from '@/components';
import { isMinScreen } from '@/config';
import { cdssShow } from '@/mock';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import Determine from '@/pages/Index/components/determine';
import {
  askFixed,
  askIsInteger,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import { StateValueType } from '@/pages/Index/components/func/ask/askGroupMedicalOrders';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import {
  numberAndNegative,
  positiveInteger,
  positiveIntegerMessage,
} from '@/pattern';
import { getScrollY, removeObjectFalsy } from '@/utils';
import { add } from '@/utils/setDataUtil';

import { WithPreviewComponent } from '../../../description_select';
import { DosageUnit } from '../../../dosage';
import amount from '../../../dosage/amount';
import Flex from '../../../flex';
import { UsageUnit } from '../../../usage';
import { dataSourceHerb } from '../../in_patient/table/datasource';
import { AuxiliaryRefs, BeIn } from '..';
import Auxiliary from '../assist/auxiliary';
import Header, { HeaderRefs } from '../assist/header';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import { convertOrderInTable, convertOrderOutTable } from './convert';
import {
  orderClassDataSource,
  orderStatusDataSource,
  orderStatusDefaultValue,
} from './datasource';
import render, { countablePreview } from './render';

export type TableRefs = VSTableInstance<any, 'text'> | null;

export interface Ref {
  onOpen: (value?: never) => void;
}

interface OutHospitalProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  header: {
    current: HeaderRefs | null;
  };
  prefixCls?: string;
  children?: React.ReactNode;
  // filterParams: any;
  operation?: any;
  footer: {
    current: {
      group(value: boolean): unknown;
      click: (data) => void;
    };
  };
}

export interface Refs {
  setValue: (value) => never;
  getValue: () => never;
  onSliced: (dataSource) => any[];
  onGroup: (value?: any) => any[];
  cancelGroupDisabled: (value) => boolean;
  onCancelGroup: () => any[];
  onCancelGroupOld: (dataSource: any[], state: any) => any[];
  countablePreview: (value: any) => void;
  onReload: () => void;
  onSave: () => Promise<boolean>;
  onStart: (value: any) => Promise<boolean>;
}

export type Value = SaveOrderEoClinicRecordOrderPersistEo &
  Omit<StateValueType, 'id'> & {
    uuid: string;
    groupIndex: number[];
    groupName: string;
    group: never;
  };

/**
 * 门诊版医嘱表格
 */
const OutHospital: any = forwardRef((props: OutHospitalProps, ref) => {
  const {
    prefixCls = 'aspirin-cpoe-center',
    auxiliary,
    children,
    header,
    footer,
    operation,
  } = props;
  const {
    common: { currentApplication },
    user: { outVisitInfo, getDoctorOrder, currentPatient },
    cdss: { cdssFocus },
  } = vsf?.stores;
  const [value, setValue, getValue] = useGetState<any[]>([]);
  const id = useMemo(() => getUUID(), []);
  const [select] = useOnChangeValue<any>({});
  const tableRef = useRef<TableRefs>(null);
  /**
   * 用于记录正在编辑行
   */
  const edit = useRef(null);
  const search = useRef<string[]>([]);

  const editFlag = useRef(false);

  /**
   * 成组类型数据是否预览判断，组内第一项为编辑态，非组内第一项为预览态
   * @param param
   * @returns
   */
  const groupPreview: any = (index) => {
    const record = value?.find((...[, i]) => i === index);
    return ![null, void 0, '', 'aspirin-order-text-group-first']?.includes(
      record?.groupName,
    );
  };

  /**
   * 皮试的主医嘱编辑限制
   * @param data
   * @returns
   */
  const skinTestPreview = (record) => {
    return (
      record?._skinTest ||
      record?.parentRelatedUuid ||
      getValue()
        ?.map((item) => item?.parentRelatedUuid)
        ?.includes(record?.uuid)
    );
  };

  /**
   * 当长/临下拉选择“临时”且药品勾选处方，该控件可编辑；未勾选处方则不可编辑 选择“长期”时，可编辑"
   * @param record
   * @param index
   * @returns {Boolean}
   */
  const frequencyPreview = (record, index): boolean => {
    return groupPreview(index) || countablePreview(record);
  };

  /**
   *  根据途径判断是否必填
   */
  const administrationAmountPreview = (record) => {
    /**
     * @const showDripSpeedIndicator
     */
    const showDripSpeedIndicator =
      vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map((item) => ({
        ...item,
        ...JSON?.parse(item?.extensionJson || '{}'),
      }))?.find(
        (item) => item?.value == (record as any)?.administration,
      )?.showDripSpeedIndicator;

    return !showDripSpeedIndicator;
  };

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        ['administration'], // 途径
        ['dripSpeed'], //滴速
        ['frequency'], //频次
        ['useDescription'], //用法
        ['performDays'], //天数
        ['administrationAmount'], //注射
      ],
      tip({ onOk, onCancel, contrast_index = [] }) {
        const Columns = vsf.refs?.outHospitalTable?.getColumns(),
          contrast_title: string[] = [];
        let i = 0;
        while (contrast_index?.length > i) {
          const dataIndex = contrast_index?.[i];
          contrast_title?.push(
            `【${
              Columns?.find((ele) => ele?.dataIndex?.[0] == dataIndex?.[0])
                ?.title
            }】`,
          );
          i++;
        }
        Tip({
          // content: '当前医嘱与上一条医嘱不一致，是否成组',
          content: `当前医嘱${contrast_title?.join(
            '与',
          )}与上一条医嘱不一致，是否确认成组`,
          onOk,
          onCancel,
        });
      },
    }),
  );

  const fetch = useMemo(() => {
    return {
      onDrugFetch: async (params: any) => {
        const { storageId, storageList, favoriteType } = params;
        let storageValue = [storageId];
        if (!storageId) {
          storageValue = storageList?.map((item) => item?.id);
        }
        if (!storageList || storageList?.length === 0) {
          return {
            data: [],
            total: 0,
          };
        } else {
          const extraParams = removeObjectFalsy({
            departmentIdIs:
              favoriteType === 'DEPARTMENT'
                ? currentApplication?.department?.id
                : undefined,
          });
          const res =
            await vsf?.services?.DrugDrugController_queryPagefallByDrugDictionaryDrugListQto_270f4c?.(
              {
                qto: {
                  from: params.pagination?.from ?? 0,
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList, // 排序结果
                  ...params.search, // 搜索结果
                  ...params.filters, // 过滤器结果
                  storageIdIn: storageValue,
                  isDoctorFavoriteIs: favoriteType === 'PERSONAL',
                  doctorIdIs: vsf?.stores?.user?.staffInfo?.id,
                  isDepartmentFavoriteIs: favoriteType === 'DEPARTMENT',
                  inputCodeLike: params?.searchValue,
                  // doctorIdIs: params?.mockDoctorId,
                  ...extraParams,
                },
              },
            );
          return {
            data: res?.data?.result ?? [],
            total: res?.data?.count ?? 0,
          };
        }
      },
      onDrugDetailsFetch: async (value) => {
        const drugItemId = value?.drugItemId;
        if (drugItemId) {
          const res: any =
            await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
              {
                drugItemId: drugItemId,
                patientId: 1001,
                departmentId: 193,
                staffId: vsf?.stores?.user?.staffInfo?.id,
              },
            );
          return res?.data;
        }
        return [];
      },
    };
  }, [currentApplication?.department?.id]);

  const onStart = (id) => {
    const start = tableRef?.current?.startEdit(id);
    edit.current = id;
    footer?.current?.group(!start);
  };
  // 未填写字段时，跳到需要填空的第一个
  const validateFieldsReturnFormatValueScrollTo = useCallback(async () => {
    const tableReal = vsf.refs?.outHospitalTable,
      element = document
        ?.querySelector('#outHospitalTable')
        ?.querySelector('.ant-table-body');
    tableReal
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue()
      .then((res) => console.log(res))
      .catch((err) => {
        const Columns = tableReal?.getColumns(),
          empty_first = err?.errorFields?.[0]?.name?.[1],
          empty_first_index = Columns?.findIndex(
            (ele) => empty_first == ele?.dataIndex?.[0],
          );
        element?.scrollTo(
          Number(
            Columns?.slice(0, empty_first_index)?.reduce((Current, next) => {
              return add(Current, next?.width, 0);
            }, 0) /
              2 -
              40,
          ),
          element?.scrollHeight,
        );
      });
    return await tableReal?.getEditForm()?.validateFieldsReturnFormatValue();
  }, []);

  const onSave = useCallback(async () => {
    await validateFieldsReturnFormatValueScrollTo();
    const key = edit?.current;
    const save = await tableRef?.current?.saveEdit();
    const id = getValue()?.find((item) => item?.id == key);
    if (id?.groupIndex?.length) {
      const list = getValue?.()?.filter((...[, index]) =>
        id?.groupIndex?.includes?.(index),
      );
      if (list?.length) {
        const one = list?.[0];
        for (const index in list) {
          const item = list?.[index];
          if (index === '0') {
            tableRef?.current?.update({
              ...item,
              is_one: false,
            });
          } else {
            const resolve =
              await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
                {
                  drugItemId:
                    item?.orderText?.drugItemId ??
                    item?.drugItemId ??
                    item?.drugOrder?.drugItemId,
                  patientId: 1001,
                  departmentId: 193,
                  staffId: vsf?.stores?.user?.staffInfo?.id,
                },
              );
            tableRef?.current?.update({
              ...item,
              totalAmount: {
                ...item?.totalAmount,
                value: amount({
                  performDays: one?.performDays,
                  dosage: item?.dosage?.value,
                  dosagePerUnit: resolve?.data?.dosagePerUnit,
                  amountPerPackage: resolve?.data?.amountPerPackage,
                  extension: one?.frequency,
                  roundingType: one?.roundingType,
                }),
              },
            });
          }
        }
      }
    }
    footer?.current?.group(Boolean(save));
    if (save) {
      edit.current = null;
    }
    return save;
  }, [footer, getValue, validateFieldsReturnFormatValueScrollTo]);
  const onDrug = async (isOther?: boolean) => {
    const record = {
      id: 'create' + Math.random(),
      uuid: getUUID(),
      orderStatus: 'OPEN',
      is_one: true,
      isOther,
    };
    const save = await onSave();
    if (save) {
      new Promise((resolve) => {
        setValue([...getValue(), record]);
        resolve(true);
      })
        ?.then(() => {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(onStart(record?.id));
            }, 100);
          });
        })
        .then(() => {
          const table = document.querySelector(
            `[data-id="${id}"] .ant-table-body`,
          );
          table?.scrollTo(0, table?.scrollHeight);
          setTimeout(() => {
            tableRef?.current
              ?.getEditForm()
              ?.getFieldInstance([record?.id, 'orderText'])
              ?.focus();
          }, 200);
        });
    }
  };

  const getGroup = useCallback(async (data) => {
    const value = tableRef?.current?.getEditForm()?.getFieldsValue(true)?.[
      edit?.current ?? ''
    ];
    const __info__ = data ?? {
      id: edit?.current,
      ...(getValue()?.find((item) => item?.id === edit?.current) ?? {}),
      ...value,
    };

    const drugItemId =
      __info__?.drugOrder?.drugItemId ?? __info__?.orderText?.drugItemId;

    let res: any = {};
    if (drugItemId) {
      res =
        await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
          {
            drugItemId,
            patientId: 1001,
            departmentId: 193,
            staffId: vsf?.stores?.user?.staffInfo?.id,
          },
        );
    }
    const init = res?.data
      ? {
          amountPerPackage: res?.data?.amountPerPackage ?? 0,
          dosagePerUnit: res?.data?.dosagePerUnit ?? 0,
          roundingType: res?.data?.roundingType ?? 'BY_AMOUNT',
          retailPrice: res?.data?.retailPrice,
        }
      : {};
    return {
      ...__info__,
      ...init,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 横向滚动医嘱正文贴边固定
   */
  const table = document.querySelector(`[data-id="${id}"] .ant-table-body`);
  useEventListener(
    'scroll',
    ({ target }) => {
      const th =
        document?.querySelectorAll(
          `[data-id="${id}"] .aspirin-table-order-cell`,
        ) ?? [];
      if (target?.scrollLeft >= 90) {
        for (const item of th) {
          item?.classList?.add('aspirin-table-order-cell-left');
        }
      } else {
        for (const item of th) {
          item?.classList?.remove('aspirin-table-order-cell-left');
        }
      }
    },
    {
      target: table,
    },
  );

  /**
   * 成组
   * @param data
   */
  const onGroup = useCallback(
    async (data?: any) => {
      const form = tableRef?.current?.getEditForm();
      const [key] = Object?.keys(form?.getFieldsValue(true));
      const save = await form?.validateFields([
        [key, 'orderText'],
        [key, 'usage'],
        [key, 'dosage'],
      ]);
      if (save) {
        const value = await getGroup(data);
        const [or] = await group.current?.onGroup(value, getValue());
        if (or) {
          const slice = group.current?.onSliced(or);
          const edit = slice?.find((item) => item?.id == key);
          form?.setFieldValue(
            [key ?? '', 'totalAmount'],
            edit?.drugOrder?.totalAmount,
          );
          setValue(slice);
          new Promise((resolve, reject) => {
            const save = tableRef?.current?.cancelEdit();
            save ? resolve(save) : reject(save);
          }).then(async () => {
            form?.resetFields();
          });
        }
      }
    },
    [getGroup, getValue, setValue],
  );

  /**
   * 取消成组
   */
  const onCancelGroup = (value) => {
    if (!['OPEN', 'SAVE'].includes(value?.orderStatus)) {
      message?.warning('只有保存和开立状态才能取消成组');
    }
    const [, data = []] =
      group.current.onCancelGroup(getValue(), value ?? select?.value) || [];
    for (const item of data) {
      tableRef?.current?.update(item);
    }
  };

  /**
   * 插入一条数据
   */
  const onInsert = useCallback(
    async (_info?: any) => {
      if (await onSave()) {
        const list = getValue() as any;
        const index = list?.findIndex((item) => item?.id === _info?.id);
        const newly = list[index ?? ''];
        const data = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          orderClass: 'DRUG',
          /**
           * 记录这条数据是不是插入的
           */
          insert: true,
        };
        /**
         * 判断如果插入组内直接成组
         */
        if (
          newly?.drugOrder?.groupSubNumber &&
          _info?.drugOrder?.groupSubNumber !== 1
        ) {
          const value = list?.toSpliced(index, 0, data);
          const [and] = await group.current?.intoGroups(
            await getGroup(data),
            value,
            'splice',
          );
          if (and) {
            setValue(group.current?.onSliced(and));
            setTimeout(() => {
              // tableRef?.current?.startEdit(data?.id);
              onStart?.(data?.id);
            }, 200);
          }
        } else {
          setValue(list?.toSpliced(index, 0, data));
          setTimeout(() => {
            // tableRef?.current?.startEdit(data?.id);
            onStart?.(data?.id);
          }, 200);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getGroup, getValue, setValue],
  );

  /**
   * 表格行单/双击操作
   */
  const onTableRowOperation = {
    click: async (data) => {
      if (cdssShow) {
        const _save = await onSave();

        if (!_save) return;
        const _value = value?.find((i) => i?.id == data?.id);
        const _record = convertOrderOutTable(
          [{ ..._value }],
          { outpVisitEncounterId: outVisitInfo?.outpVisit?.id },
          currentPatient.patientId,
        );
        const { orderStatus, ..._params } = _record?.[0];
        cdssFocus(
          {
            patientId: currentPatient.patientId,
            outpVisitId: outVisitInfo?.outpVisit?.id,
          },
          orderStatus == 'OPEN' ? _params : _record?.[0],
        );
      }
      select?.onChange(data);
      footer.current.click?.(data);
    },
    // 双击事件
    doubleClick: (target) => {
      const {
        examOrder,
        herbOrder,
        labOrder,
        orderClass,
        operationOrder,
        pathologyOrder,
      } = target || {};

      if (!['SAVE', 'OPEN'].includes(target?.orderStatus)) {
        message?.warning('只有开立和保存可以修改');
        return;
      }
      if (target?.parentRelatedUuid) {
        message?.warning('皮试带药不可编辑');
        return;
      }
      const METHOD = {
        DRUG: async () => {
          onSave()?.then(() => onStart?.(target?.id));
        },
        HERB: () => {
          auxiliary?.current?.onHerb({
            ...herbOrder,
            id: target?.id,
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
            memo: target?.memo,
            agreedPrescriptionId: herbOrder?.agreedPrescriptionId,
          });
        },
        LAB: () => {
          auxiliary?.current?.onLab({
            ...labOrder,
            orderStatus: target?.orderStatus,
            allPrice: target?.allPrice,
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
          });
        },
        EXAM: () => {
          auxiliary?.current?.onExam({
            uuid: target?.uuid,
            ...examOrder,
            patientSpecialInfo: target?.patientSpecialInfo,
            performDepartment: target?.performDepartment,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
          });
        },
        OPERATION: () => {
          auxiliary?.current?.onOperation({
            uuid: target?.uuid,
            ...operationOrder,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
            patientSpecialInfo: target?.patientSpecialInfo,
          });
        },
        PATHOLOGY: () => {
          auxiliary?.current?.onPathology({
            uuid: target?.uuid,
            ...pathologyOrder,
            performDepartment: target?.performDepartment,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
          });
        },
      };
      if (METHOD?.[orderClass]) {
        METHOD?.[orderClass]?.();
      } else {
        onSave();
        onStart?.(target?.id);
      }
    },
  };

  const [loading] = useOnChangeValue(false);
  const onReload = useCallback(async () => {
    const value = await getDoctorOrder({
      hospitalType: 'out',
      id: outVisitInfo?.id,
    });
    loading?.onChange?.(true);
    if (value) {
      setValue(group?.current?.onSliced?.(convertOrderInTable(value)));
      loading?.onChange?.(false);
    }
  }, [getDoctorOrder, loading, outVisitInfo?.id, setValue]);

  useAsyncEffect(() => onReload?.(), []);

  useImperativeHandle(ref, () => ({
    onDrug,
    setValue: (data) => {
      setValue(data);
    },
    getValue,
    onGroup,
    onOther: () => onDrug?.(true),
    ...tableRef.current,
    onSave,
    onStart,
    onSliced: group.current?.onSliced,
    onReload,
    onCancelGroup,
    countablePreview,
    onCancelGroupOld: group?.current?.onCancelGroup,
    tableRef: document.querySelector(`[data-id="${id}"] .ant-table-body`),
    id: () => edit.current,
  }));

  const handleExtraSubmit = useContext(BeIn);

  const editable = useMemo(() => {
    const ret: VSTableEditable<any> = {
      editType: 'single',
      columnProps: {
        fixed: 'right',
        width: 50,
        hideInTable: true,
      },
      onCanEdit: (target) => !target?.parentRelatedUuid,
      switchMode: {
        onColumnFinish: async () => {
          return true;
        },
        onRowFinish: async () => true,
      },
      onFieldChange: (key, value: any, record: any, form) => {
        if ('frequency' in value || 'performDays' in value) {
          // 某些情况不需要计算注射次数
          const res = administrationAmountPreview(record);
          if (res) return;
          // 总注射次数计算
          const { frequency, performDays } = record;
          const days =
            Number(frequency?.frequencyCount ?? 0) * Number(performDays ?? 0);
          form.setFieldValue([key, 'administrationAmount'], days);
        }
        // if ('orderText' in value) {
        // 默认带出频次
        //   const { frequency } = record;
        //   form.setFieldValue([key, 'frequency'], frequency);
        // }
      },
    };
    return ret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const style: React.CSSProperties = {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  };

  const {
    renderOrderStatus,
    renderOrderText,
    rowClassName,
    rowClassNames,
    renderRetailPrice,
  } = render({
    select,
    getValue,
    onCancelGroup,
  });

  const description = (recode) => {
    const record = tableRef.current?.getEditForm?.()?.getFieldsValue(true)?.[
      recode?.key ?? ''
    ];
    /**
     * @const useDescription
     */
    const useDescription: boolean =
      vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map((item) => ({
        ...item,
        ...JSON?.parse(item?.extensionJson || '{}'),
      }))?.find(
        (item) => item?.value == (record as any)?.administration,
      )?.useRequiredIndicator;
    return useDescription;
  };

  return (
    <VSTable
      id="outHospitalTable"
      value={value}
      pagination={false}
      editable={editable}
      data-id={id}
      scroll={{
        // y: getScrollY(isMinScreen ? 270 : 500),
        y: 0,
        x: 2400,
      }}
      onRow={(record) => ({
        onDoubleClick: () => {
          onTableRowOperation?.doubleClick?.(record);
        },
        onClick: async () => {
          onTableRowOperation?.click(record);
        },
      })}
      ref={tableRef}
      rowClassName={rowClassName}
      bordered
      onChange={(value) => {
        setValue(group?.current?.onSliced(value));
      }}
      className="table_overflow_560 aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-table-order-box"
      onRecord={async () => {
        const record = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          isOther: false,
        };
        // edit.current = record.id;
        setTimeout(() => {
          // tableRef?.current
          //   ?.getEditForm()
          //   ?.getFieldInstance([record?.id, 'orderText'])
          //   ?.focus();
          const table = document.querySelector(
            `[data-id="${id}"] .ant-table-body`,
          );
          table?.scrollTo(0, table?.scrollHeight);
        }, 200);
        return record;
        // return Promise.resolve({
        // id: 'create' + Math.random(),
        // uuid: getUUID(),
        // orderStatus: 'OPEN',
        // is_one: true,
        // isOther: false,
        // });
      }}
    >
      <VSTableColumn
        title="状态"
        valueType="select"
        dataIndex={['orderStatus']}
        dataSource={orderStatusDataSource}
        fieldProps={{
          defaultValue: orderStatusDefaultValue,
        }}
        render={renderOrderStatus}
        preview
        width={100}
        renderFormItem={(schema, record) => {
          return renderOrderStatus(schema, record.record);
        }}
      />
      <VSTableColumn
        title="类别"
        dataIndex={['orderClass']}
        valueType="select"
        dataSource={orderClassDataSource}
        preview
        width={45}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="医嘱"
        dataIndex={['orderText']}
        valueType="dictionary"
        render={renderOrderText}
        fieldProps={(...args) => {
          const [record] = args;
          const recode = value?.find((item) => item?.id === record?.id);
          return {
            isCoder: true,
            width: '100%',
            handleExtraSubmit,
            onDrugFetch: fetch?.onDrugFetch,
            onDrugDetailsFetch: fetch?.onDrugDetailsFetch,
            hospitalType: 'out',
            className: recode?.groupName,
            record: recode,
            getDisabled: group.current?.disabled,
            onChange: async (orderText) => {
              const form: any = tableRef?.current?.getEditForm() || {};
              const key = record?.id;
              const { id, detailInfo, ...rest } = orderText || {};
              const orderClass = rest?.orderClass ?? detailInfo?.orderClass;
              await form?.resetFields(
                [
                  ['dosage'],
                  ['usage'],
                  ['administration'],
                  ['dripSpeed'],
                  ['frequency'],
                  ['useDescription'],
                  ['performDays'],
                  ['totalAmount'],
                  ['drugBillingAttribute'],
                ]?.map((item) => [key, ...item]),
              );

              switch (orderClass) {
                case 'DRUG':
                  const {
                    drugEo: {
                      groupNumber,
                      frequency,
                      groupSubNumber,
                      useDescription,
                      ...drug
                    },
                  } = detailInfo || {};
                  const int = askFixed(
                    (drug?.dosage ?? 0) / (detailInfo?.dosagePerUnit ?? 0),
                    2,
                  );

                  if (recode?.groupName) {
                    const index = getValue()?.findIndex(
                      (item) => item?.id === record?.id,
                    );
                    const one = getValue()[index - 1 || ''];
                    const drugOrder = {
                      retailPrice: detailInfo?.retailPrice, // 存疑
                      dosage: {
                        value: drug?.dosage,
                        unit: drug?.dosageUnit ?? detailInfo?.unit,
                      },
                      usage: {
                        value: Number(int),
                        unit: drug?.usageUnit,
                      },
                      totalAmount: {
                        unit:
                          vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                            (item) => item?.value == drug?.packageUnit,
                          )?.label ?? drug?.packageUnit,
                        value: amount({
                          performDays:
                            one?.group?.performDays ?? one?.performDays,
                          extension: one?.group?.frequency ?? one?.frequency,
                          dosage: drug?.dosage,
                          dosagePerUnit: detailInfo?.dosagePerUnit,
                          amountPerPackage: detailInfo?.amountPerPackage,
                          roundingType: detailInfo?.roundingType,
                        }),
                      },
                    };
                    form.setFieldsValue({
                      [key]: {
                        orderClass: 'DRUG',
                        drugOrder,
                        ...drugOrder,
                        drugPriceItemId: drug?.drugPriceItemId,
                        performDepartmentId: rest?.storageDepartmentId,
                        performDepartment: {
                          departmentName: rest?.storageDepartmentName,
                          id: rest?.storageDepartmentId,
                        },
                        uuid: record?.uuid ?? getUUID(),
                      },
                    });
                  } else {
                    const drugOrder = {
                      retailPrice: detailInfo?.retailPrice, // 存疑
                      dosage: {
                        value: drug?.dosage,
                        unit: drug?.dosageUnit ?? detailInfo?.unit,
                      },
                      usage: {
                        value: Number(int),
                        unit: drug?.usageUnit,
                      },
                      frequency,
                      toxicCode: detailInfo?.drugEo?.toxicCode,
                      totalAmount: {
                        unit:
                          vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                            (item) => item?.value == drug?.packageUnit,
                          )?.label ?? drug?.packageUnit,
                        value: 0,
                      },
                      skinTestContinueUseIndicator:
                        rest?.skinTestContinueUseIndicator,
                      skinTestContinueReason: rest?.skinTestContinueReason,
                      amountPerPackage: detailInfo?.amountPerPackage,
                      dosagePerUnit: detailInfo?.dosagePerUnit,
                      administration: drug?.administration,
                      useDescription,
                    };
                    form.setFieldsValue({
                      [key]: {
                        orderClass: 'DRUG',
                        drugOrder,
                        ...drugOrder,
                        drugPriceItemId: drug?.drugPriceItemId,
                        performDepartmentId: rest?.storageDepartmentId,
                        performDepartment: {
                          departmentName: rest?.storageDepartmentName,
                          id: rest?.storageDepartmentId,
                        },
                        uuid: record?.uuid ?? getUUID(),
                      },
                    });
                  }
                  break;
                case 'EXAM':
                  form.setFieldsValue({
                    [key]: {
                      orderClass: 'EXAM',
                      insert: true,
                    },
                  });
                  auxiliary?.current?.onExam(orderText);
                  break;
                case 'LAB':
                  form.setFieldsValue({
                    [key]: {
                      orderClass: 'LAB',
                      insert: true,
                    },
                  });
                  auxiliary?.current?.onLab(orderText);
                  break;
                default:
                  const retailPrice = askTotalAmount(
                    orderText?.currentPriceItem?.map((item) => [
                      item?.amount,
                      item?.currentPriceItem?.price,
                    ]),
                  );
                  const data = {
                    orderClass,
                    orderText: rest?.clinicItemName,
                    disposalOrder: {
                      itemId: rest?.clinicItemId,
                      itemName: rest?.clinicItemName,
                    },
                    retailPrice,
                    // allPrice: retailPrice,
                  };
                  form?.setFieldsValue({
                    [key]: data,
                  });
              }
            },
            dataSource: getValue(),
          };
        }}
        width={286}
        preview={(...[, record]) => {
          return skinTestPreview(record);
        }}
        className="aspirin-table-order-cell"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="剂量"
        dataIndex={['dosage']}
        valueType="dosage"
        width={100}
        formItemProps={{
          style: { color: '#3276E8' },
          rules: [
            {
              required: true,
            },
            {
              validator: (...[, value]) => {
                if ([undefined, null].includes(value?.value)) {
                  return Promise?.reject('剂量不能为空');
                } else if ((value?.value ?? value) <= 0) {
                  return Promise?.reject('剂量不能小于0');
                } else {
                  return Promise?.resolve();
                }
              },
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            record: value?.find((item) => item?.id === record?.id),
            allowClear: true,
            dataSource: value,
            id: String(record?.id),
            path: {
              performDays: ['performDays'],
              totalAmount: ['totalAmount'],
              frequency: ['frequency'],
              usage: ['usage'],
            },
            table: tableRef,
          };
        }}
        preview={(...[, record]) => countablePreview(record)}
        render={(...[, record]) => <DosageUnit value={record?.dosage} />}
      />
      <VSTableColumn
        title="用量"
        dataIndex={['usage']}
        valueType="usage"
        width={70}
        formItemProps={{
          style: { color: '#3276E8' },
          rules: [
            {
              required: true,
            },
            {
              validator: (...[, value]) =>
                value?.value
                  ? Promise?.resolve()
                  : Promise?.reject('用量不能为空'),
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            allowClear: true,
            record: value?.find((item) => item?.id === record?.id),
            path: {
              dosage: ['dosage'],
            },
            id: String(record?.id),
          };
        }}
        render={(...[, record]) => <UsageUnit value={record?.usage} />}
        preview={(...[, record]) => countablePreview(record)}
      />
      <VSTableColumn
        title="途径"
        dataIndex={['administration']}
        valueType="administrationSelect"
        preview={(...[, record, index]) =>
          groupPreview(index) || countablePreview(record)
        }
        fieldProps={(record) => {
          return {
            record,
            allowClear: false,
            showSearch: true,
            placeholder: '请选择途径',
            onInputKeyDown: (event) => {
              search.current = [search?.current?.at(-1) ?? '', event?.key];
              const [one, two] = search?.current;
              if (one === '/' && two === 'Enter') {
                onGroup(void 0);
              }
            },
            onChange: () => {
              setTimeout(() => {
                const form: any = tableRef?.current?.getEditForm() || {};
                const key = record?.id;
                form?.setFieldValue(key, {
                  ...form?.getFieldValue(key),
                  dripSpeed: null,
                  administrationAmount: null,
                });
              }, 100);
            },
          };
        }}
        width={100}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="滴速"
        dataIndex={['dripSpeed']}
        valueType="text"
        preview={(...[, record, index]) =>
          groupPreview(index) ||
          countablePreview(record) ||
          administrationAmountPreview(record)
        }
        width={120}
        render={(...[, record]) => {
          return record?.dripSpeed ? `${record?.dripSpeed}滴/分` : '';
        }}
        fieldProps={{
          addonAfter: '滴/分',
          allowClear: false,
          onFocus: (v) => {
            v?.currentTarget?.select();
          },
        }}
        formItemProps={{
          style: { color: '#3276E8' },
          rules: [{ pattern: numberAndNegative, message: '请输入正整数' }],
        }}
      />
      <VSTableColumn
        title="频次"
        dataIndex={['frequency']}
        valueType="frequencySelect"
        preview={(...[, record, index]) => {
          return frequencyPreview(record, index);
        }}
        width={100}
        render={(...[, record]) => {
          return record?.frequency?.frequency ?? record?.frequency?.label ?? '';
        }}
        fieldNames={{
          label: 'frequency',
          value: 'frequency',
        }}
        fieldProps={(record) => {
          return {
            record,
            allowClear: false,
            placeholder: '请选择频次',
            showSearch: true,
          };
        }}
        formItemProps={{
          style: { color: '#3276E8' },
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        title="用法"
        dataIndex={['useDescription']}
        valueType="descriptionSelect"
        fieldProps={(record) => {
          const useDescription = description?.({
            ...record,
            key: record?.id,
          });
          return {
            allowClear: true,
            showSearch: true,
            required: useDescription,
          };
        }}
        width={100}
        formItemProps={(...[, recode]) => {
          const useDescription = description({
            ...recode?.entity,
            key: recode?.entity?.id,
          });
          return useDescription
            ? {
                rules: [
                  {
                    required: true,
                  },
                ],
                style: { color: '#3276E8' },
              }
            : {
                style: { color: '#3276E8' },
              };
        }}
        preview={(...[, record, index]) => {
          return groupPreview(index) || countablePreview(record);
        }}
        render={(...[, record]) => {
          return (
            <WithPreviewComponent
              record={record}
              value={record?.useDescription}
            />
          );
        }}
      />
      <VSTableColumn
        title="天数"
        dataIndex={['performDays']}
        valueType="digit"
        width={70}
        fieldProps={{
          addonAfter: '天',
          // allowClear: false,
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
            {
              pattern: positiveInteger,
              message: positiveIntegerMessage,
            },
          ],
          style: { color: '#3276E8' },
        }}
        preview={(...[, record, index]) =>
          groupPreview(index) || countablePreview(record)
        }
        render={(_, value) => {
          return value?.performDays ? value?.performDays + '天' : '';
        }}
      />
      <VSTableColumn
        title="数量"
        dataIndex={['totalAmount']}
        valueType="amount"
        fieldProps={(record) => {
          return {
            allowClear: true,
            width: '100%',
            record,
          };
        }}
        width={70}
        preview={(...[, record]) => !countablePreview(record)}
        render={(...[, record]) => <UsageUnit value={record?.totalAmount} />}
        formItemProps={{
          rules: [
            {
              validator(...[, state]) {
                const value = state?.value ?? state;
                if ([void 0, null].includes(value)) {
                  return Promise.reject('请输入数量');
                }
                if (!Number.isInteger(value)) {
                  return Promise.reject('数量必须是整数');
                }
                if (value < 0) {
                  return Promise.reject('数量必须是正整数');
                }
                if (value === 0) {
                  return Promise.reject('数量不能为0');
                }
                return Promise.resolve();
              },
            },
          ],
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="注射"
        dataIndex={['administrationAmount']}
        valueType="digit"
        width={100}
        preview={(...[, record]) => {
          return (
            countablePreview(record) || administrationAmountPreview(record)
          );
        }}
        render={(...[, record]) => {
          return (
            <Determine condition={Boolean(record?.administrationAmount)}>
              {record?.administrationAmount + '次'}
            </Determine>
          );
        }}
        formItemProps={{
          rules: [
            {
              validator: (...[, value]) => {
                return askIsInteger(value);
              },
            },
          ],
          style: { color: '#3276E8' },
        }}
        fieldProps={{
          width: '100%',
          placeholder: '请输入注射次数',
          addonAfter: '次',
        }}
      />
      <VSTableColumn
        title="执行科室"
        dataIndex={['performDepartment']}
        valueType="performDepartment"
        fieldProps={(...args) => {
          const [record] = args;
          return {
            record,
          };
        }}
        width={170}
        preview={(...[, record]) => !countablePreview(record)}
        formItemProps={{
          style: { color: '#3276E8' },
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        title="单价"
        dataIndex={['retailPrice']}
        valueType="custom"
        fieldProps={(...args) => {
          const [record] = args;
          return {
            record,
          };
        }}
        width={100}
        preview
        render={renderRetailPrice}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
        renderFormItem={(...[, { record }]) => {
          return renderRetailPrice(void 0, record);
        }}
      />
      <VSTableColumn
        title="总价"
        dataIndex={['allPrice']}
        valueType="custom"
        fieldProps={(...args) => {
          const [record] = args;
          return {
            record,
          };
        }}
        width={100}
        preview
        formItemProps={{
          style: { color: '#3276E8' },
        }}
        renderFormItem={(...[, { record }]) => {
          const price = askFixed(record?.allPrice ?? 0, 2);
          return Number?.isNaN(price) || !record?.allPrice ? '' : price + '元';
        }}
        render={(...[, record]) => {
          const price = askFixed(record?.allPrice ?? 0, 2);
          return Number?.isNaN(price) || !record?.allPrice ? '' : price + '元';
        }}
      />
      <VSTableColumn
        title="备注"
        dataIndex={['memo']}
        valueType="text"
        width={100}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="自备"
        dataIndex={['drugBillingAttribute']}
        valueType="check"
        width={60}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
        render={(...[, record]) => {
          return (
            <Determine condition={record?.orderClass === 'DRUG'}>
              <Flex justify="center">
                <Determine condition={record?.drugBillingAttribute === true}>
                  <Icon type="icon-dui" size={24} className="icon-color-dui" />
                </Determine>
              </Flex>
            </Determine>
          );
        }}
        preview={(...[, record, index]) =>
          groupPreview(index) || countablePreview(record)
        }
        fieldProps={(record) => {
          return {
            disabled: record?.prescriptionPreparation,
            style,
          };
        }}
      />
      <VSTableColumn
        title="外配"
        dataIndex={['prescriptionPreparation']}
        valueType="check"
        width={50}
        fieldProps={(record) => {
          return {
            onChange: (value) => {
              // console.log(record);
              // console.log(value);
              if (value) {
                tableRef?.current
                  ?.getEditForm()
                  ?.setFieldValue(
                    [String(record?.id), 'drugBillingAttribute'],
                    true,
                  );
              }
            },
            style,
          };
        }}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
        render={(...[, record]) => {
          // null或0 显示为空
          // 1时显示绿色√
          // 非西药医嘱显示空1
          return (
            <Flex justify="center">
              {record?.prescriptionPreparation ? (
                <Icon type="icon-dui" size={24} className="icon-color-dui" />
              ) : null}
            </Flex>
          );
        }}
      />

      <VSTableColumn
        title="开单医师"
        dataIndex={['doctor']}
        valueType="text"
        width={100}
        preview
        render={(...[, record]) => {
          const { doctor } = record;
          return doctor?.staffName ?? vsf?.stores?.user?.staffInfo?.staffName;
        }}
        renderFormItem={(...[, { record }]) => {
          const { doctor } = record as any;
          return doctor?.staffName ?? vsf?.stores?.user?.staffInfo?.staffName;
        }}
        formItemProps={{
          style: { color: '#3276E8' },
        }}
      />
      <VSTableColumn
        title="操作"
        dataIndex={['edit']}
        valueType="oper"
        renderFormItem={(...[, record]) => {
          return (
            <Button
              type="text"
              onClick={async () => {
                if (!record?.record?.is_one) {
                  tableRef?.current?.cancelEdit();
                } else {
                  if (record?.record?._skin) {
                    const tableData: any[] = getValue?.() ?? [];
                    setValue(
                      tableData?.filter(
                        (item) =>
                          !record?.record?._skinTest?.includes(item?.id),
                      ),
                    );
                  } else if (record?.record?.drugOrder?.groupSubNumber) {
                    /**
                     * 针对插入成组的行, 需要纠正 groupSubNumber
                     */
                    const array = group?.current?.onSliced(
                      getValue().filter(
                        (item) => item?.id !== record?.record?.id,
                      ),
                    );
                    const list = await group.current?.onCancelInsert(
                      array,
                      getValue().filter(
                        (item, index) =>
                          record?.record?.groupIndex?.includes(index) &&
                          record?.record?.id !== item?.id,
                      ),
                    );
                    setValue(list);
                  } else {
                    tableRef?.current?.remove(record?.record);
                  }
                }
                footer?.current?.group(true);
              }}
            >
              取消
            </Button>
          );
        }}
        render={(...[, record]) => {
          return (
            <div className="aspirin-table-operation-container">
              <Determine
                condition={
                  !['PERFORM', 'SUBMIT']?.includes(record?.orderStatus) &&
                  !record?.parentRelatedUuid
                }
              >
                <Tooltip placement="top" title="插入">
                  <Icon
                    type="icon-xiangshangcharu"
                    size={20}
                    onClick={() => onInsert(record)}
                  />
                </Tooltip>
                <Tooltip placement="top" title="删除">
                  <Icon
                    type="icon-wudishanchu"
                    size={20}
                    onClick={() => operation?.delete(record)}
                  />
                </Tooltip>
              </Determine>
            </div>
          );
        }}
        fixed="right"
        width={70}
      />
    </VSTable>
  );
});

OutHospital.displayName = 'OutHospital';
OutHospital.Header = Header;
OutHospital.Auxiliary = Auxiliary;
export default OutHospital;
