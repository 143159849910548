import vsf, { definePage } from '@vs/vsf-boot';
import {
  Menu,
  Modal,
  VSPromiseTable,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getPromiseHeight, setMaxHeight } from './methods';
import Block from '@/module/Block';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import {
  cancelPerformOutpBillingDetails,
  convertGetNoOrderOutpBillingDetailGroupListRes2OutpMedicalTechnologyChargeNoOrderChargeBillingDetailList,
  getNoOrderOutpBillingDetailGroupList,
  getOrderListByOutpVisitId,
  getOrderOutpBillingDetailList,
  saveNoOrderOutpBillingDetails,
  saveOrderOutpBillingDetails,
} from './services';
import ProjectNameSelect from '../ProjectNameSelect';
import dayjs from 'dayjs';
import { includes, isObject } from 'lodash';
import { useRequest } from 'ahooks';
import { onTableFieldEllipsis } from '@/utils/setDataUtil';
import TemplateModalContainer from '../TemplateModalContainer';
import { askFixed, askTotalAmount } from '@/pages/Index/components/func/ask';

const TableDetails = (props) => {
  const [current, setCurrent] = useState('mail'),
    [selectedRows, setSelectedRows] = useState([]),
    [order, setOrder] = useState(),
    [NoOrderOutpBilling, setNoOrderOutpBilling] = useState(),
    [NoOrderOutpReload, setNoOrderOutpReload] = useState(),
    MenuRef = useRef(null),
    [ModalProps, setModalProps] = useState({
      open: false,
    });

  const onChanegMenu = async (e) => {
      setCurrent(e.key);
      setSelectedRows([]);
      setOrder();
      setNoOrderOutpBilling();
    },
    onChanegMenuTableReal = async () => {
      let TableReal;
      if (includes(['mail'], current))
        // 医嘱费用
        TableReal = await vsf?.refs
          ?.outpMedicalTechnologyChargeOrderBillingDetailList;
      if (includes(['app'], current))
        // 非医嘱费用
        TableReal = await vsf?.refs
          ?.outpMedicalTechnologyChargeNoOrderChargeBillingDetailList;
      return TableReal ?? {};
    },
    onChanegMenuBtn = () => {
      if (includes(['mail'], current)) return !order;
      if (includes(['app'], current)) return !props?.outpVisitId;
    },
    onChanegMenuBtnDelete = () => {
      let i = 0;
      while (selectedRows?.length > i) {
        if (selectedRows?.[i]?.billingStatus) return true;
        i++;
      }
      return false;
    },
    //收费弹窗回调
    onTemplateModalCallback = async (params) => {
      const TableReal = await onChanegMenuTableReal();
      await TableReal?.add(params);
      const TableRealValues = await TableReal?.getValues();
      await TableReal?.scrollTo(
        TableRealValues?.[TableRealValues?.length - 1]?.id,
      );
      message?.success('模板导入成功。');
    };
  useEffect(() => setOrder(), [props?.outpVisitId]);

  // 按钮
  const footer = () => {
    const button = [
      {
        disabled: onChanegMenuBtn(),
        text: '新增',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealValues;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.add({});
          TableRealValues = await TableReal?.getValues();
          TableReal?.scrollTo(
            TableRealValues?.[TableRealValues?.length - 1]?.id,
          );
        },
      },
      {
        disabled: onChanegMenuBtn(),
        text: '保存',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.getEditForm()?.validateFieldsReturnFormatValue();
          const request = async (params) => {
            if (params.length <= 0) return message?.error('请新增费用');
            switch (current) {
              // 医嘱
              case 'mail':
                return await saveOrderOutpBillingDetails?.(
                  params?.map?.((ele) => {
                    return {
                      ...ele,
                      orderId: order?.id,
                      TheRowType: 'New',
                    };
                  }),
                );
              // 非医嘱
              case 'app':
                return await saveNoOrderOutpBillingDetails?.(params);

              default:
                return {};
            }
          };
          const res = await request?.(
            TableReal?.getValues()?.filter(
              (ele) =>
                ['New', 'Temp'].includes(ele?.TheRowType) ||
                ['UNBILLING']?.includes(ele?.billingStatus),
            ),
          );
          if (res?.code !== 200) return;
          message?.success('保存成功');
          // if (['mail'].includes(current))
          TableReal?.reload?.();
          // 非医嘱
          if (['app'].includes(current))
            return setNoOrderOutpReload(Math?.random?.());
        },
      },
      {
        disabled: onChanegMenuBtn() || onChanegMenuBtnDelete(),
        text: '删除',
        ghost: true,
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal, TableRealSelectedRowValues;
          TableReal = await onChanegMenuTableReal();
          TableRealSelectedRowValues = await TableReal?.getSelectedRowValues();
          if (TableRealSelectedRowValues?.length <= 0)
            return message?.error('请选择可删除的费用');
          let i = 0,
            r = 0;
          while (TableRealSelectedRowValues?.length > i) {
            if (
              ['New', 'Temp'].includes(TableRealSelectedRowValues[i].TheRowType)
            ) {
              await TableReal?.remove(TableRealSelectedRowValues[i]);
              r++;
            }
            i++;
          }
          setSelectedRows([]);
          r > 0 && message?.success('删除成功');
        },
      },
      {
        disabled: onChanegMenuBtn(),
        text: '取消执行',
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: async () => {
          let TableReal;
          TableReal = await onChanegMenuTableReal();
          await TableReal?.getEditForm()?.validateFieldsReturnFormatValue();
          const request = async (params) => {
            if (params.length <= 0)
              return message?.error('请输入需要取消执行数量');
            if (['mail'].includes(current))
              return await cancelPerformOutpBillingDetails?.(params);
            if (['app'].includes(current))
              return await saveNoOrderOutpBillingDetails?.(params);
          };
          const res = await request?.(
            TableReal?.getValues()?.filter((ele) => ele?.cancelPerformAmount),
          );
          if (res?.code !== 200) return;
          message?.success('退费申请成功，请通知患者前往收费处退费');
          TableReal?.reload?.();
          if (['app'].includes(current))
            return setNoOrderOutpReload(Math?.random?.());
        },
      },
      {
        text: '收费模板',
        methodKey: 'altKey',
        ordinaryKey: '',
        disabled: onChanegMenuBtn(),
        onClick: () => setModalProps({ open: true }),
      },
      {
        text: '另存为模板',
        disabled: true,
        methodKey: 'altKey',
        ordinaryKey: '',
        onClick: () => {},
      },
    ];
    return (
      <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  return (
    <div className="TableDetails" id="TableDetails">
      <Menu
        ref={MenuRef}
        mode="horizontal"
        onClick={onChanegMenu}
        selectedKeys={[current]}
        items={[
          {
            label: '医嘱费用',
            key: 'mail',
          },
          {
            label: '非医嘱费用',
            key: 'app',
          },
        ]}
      />
      <div className="container">
        <Components
          {...{
            ...props,
            current,
            selectedRows,
            setSelectedRows,
            order,
            setOrder,
            onChanegMenuTableReal,
            NoOrderOutpBilling,
            setNoOrderOutpBilling,
            NoOrderOutpReload,
            setNoOrderOutpReload,
          }}
        />
      </div>
      <div className="footer">
        <Block footer={footer()} style={{ padding: '0' }} />
      </div>
      <TemplateModalContainer
        {...{ ModalProps, setModalProps, onTemplateModalCallback }}
      />
    </div>
  );
};

const Components = (props) => {
  const { current } = props;

  const onChanegUpdata = async (ele) => {
    let params = {};
    if (isObject(ele?.applyDepartmentName)) {
      params.applyDepartmentName =
        ele?.applyDepartmentName?.applyDepartmentName;
      params.applyDepartmentId = ele?.applyDepartmentName?.applyDepartmentId;
    }

    if (isObject(ele?.applyStaffName)) {
      params.applyStaffName = ele?.applyStaffName?.staffName;
      params.applyStaffId = ele?.applyStaffName?.staffId;
    }

    if (isObject(ele?.performStaffName)) {
      params.performStaffName = ele?.performStaffName?.staffName;
      params.performStaffId = ele?.performStaffName?.staffId;
    }
    return { ...ele, ...params };
  };
  switch (current) {
    case 'mail':
      return <Do {...{ ...props, onChanegUpdata }} />;
    case 'app':
      return <Not {...{ ...props, onChanegUpdata }} />;
    default:
      return;
  }
};

// 医嘱费用
const Do = (props) => {
  return (
    <div id="DoDeatail">
      <div id="doctor">
        <p className="title" id="doctorTitle">
          医嘱信息
        </p>
        <div className="table">
          <DoctorTable {...{ ...props }} />
        </div>
      </div>
      <div id="expense">
        <p className="title" id="expenseTitle">
          费用信息
        </p>
        <div className="table">
          <ExpenseTable {...{ ...props }} />
        </div>
      </div>
    </div>
  );
};

// 医嘱费用 -- 医嘱信息
const DoctorTable = (props) => {
  const { outpVisitId, order, setOrder } = props,
    [yMaxHeight, setYMaxHeight] = useState(220);
  getPromiseHeight('doctor')?.then((res) => {
    setYMaxHeight(
      res - document?.querySelector('#doctorTitle')?.offsetHeight - 50,
    );
  });

  const onFetch = useCallback(
    async (params) => getOrderListByOutpVisitId({ ...params, outpVisitId }),
    [outpVisitId],
  );

  // const onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //     ],
  //   };
  // }, []);

  return (
    <VSPromiseTable
      scroll={{
        y: yMaxHeight,
      }}
      pagination={false}
      vsid="06387"
      id="outpMedicalTechnologyChargeOrderList"
      onFetch={onFetch}
      rowClassName={(node) => {
        return node?.id === order?.id
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
          : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
      }}
      onRow={(node) => {
        return {
          onClick: () => {
            if (node?.id === order?.id) return setOrder();
            setOrder(node);
          },
        };
      }}
    >
      <VSTableColumn
        width={96}
        dataIndex={['clinicClass']}
        title="医疗类别"
        valueType="select"
        dataSource={[
          { label: '门诊', value: 'OUTP' },
          { label: '住院', value: 'INP' },
          { label: '急诊', value: 'ERP' },
          { label: '体检', value: 'PHYSICAL_EXAM' },
          { label: '挂号', value: 'CLINIC_REGISTER' },
        ]}
        fieldProps={{}}
        bordered
      />

      <VSTableColumn
        dataIndex={['applyStaffName']}
        title="开单医生"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['orderText']}
        width={296}
        title="医嘱正文"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        width={96}
        dataIndex={['dosage']}
        title="剂量"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="select"
        fieldProps={{}}
        dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
      />

      <VSTableColumn
        dataIndex={['frequency']}
        title="频次"
        valueType="text"
        fieldProps={{}}
      />

      <VSTableColumn
        width={96}
        dataIndex={['orderStatus']}
        title="医嘱状态"
        valueType="select"
        dataSource={[
          { label: '保存', value: 'SAVE' },
          { label: '提交', value: 'SUBMIT' },
          { label: '执行', value: 'PERFORM' },
          { label: '停止', value: 'STOP' },
          { label: '作废', value: 'CANCEL' },
          { label: '审核未通过', value: 'AUDIT_FAIL' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['createDateTime']}
        title="开单时间"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
      />

      <VSTableColumn
        width={96}
        dataIndex={['orderClass']}
        title="医嘱类别"
        valueType="select"
        dataSource={[
          { label: '西药', value: 'DRUG' },
          { label: '中药', value: 'HERB' },
          { label: '检验', value: 'LAB' },
          { label: '检查', value: 'EXAM' },
          { label: '治疗', value: 'TREAT' },
          { label: '手术', value: 'OPERATION' },
          { label: '麻醉', value: 'ANESTHESIA' },
          { label: '护理', value: 'NURSING' },
          { label: '膳食', value: 'MEAL' },
          { label: '营养', value: 'NUTRITION' },
          { label: '会诊', value: 'CONSULTATION' },
          { label: '用血', value: 'BLOOD' },
          { label: '其他', value: 'OTHER' },
          { label: '病理', value: 'PATHOLOGY' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['chargeStatus']}
        title="计费状态"
        valueType="select"
        dataSource={[]}
        fieldProps={{}}
        render={(_, node) => {
          let str,
            style,
            argstring = '';
          if (node?.billingStatus === true) argstring = '（已划价）';
          if (node?.billingStatus === false) argstring = '（未划价）';
          switch (node?.chargeStatus) {
            case 'NOT_CHARGE':
              str = '未收费';
              break;
            case 'FINISH_CHARGE':
              style = { color: 'rgba(48, 163, 19, 1)' };
              str = '已收费';
              break;
            case 'PARTIAL_REFUND':
              style = { color: 'rgba(227, 74, 78, 1)' };
              str = '部分退费';
              break;
            case 'ALL_REFUND':
              style = { color: 'rgba(227, 74, 78, 1)' };
              str = '全部退费';
              break;
            default:
              break;
          }
          return <span style={style}>{`${str}${argstring}`}</span>;
        }}
      />
    </VSPromiseTable>
  );
};

// 医嘱费用 -- 费用信息
const ExpenseTable = (props) => {
  const {
      order,
      selectedRows,
      setSelectedRows,
      operationDoctorData,
      outpVisitId,
      onChanegUpdata,
    } = props,
    TableId = 'outpMedicalTechnologyChargeOrderBillingDetailList',
    [yMaxHeight, setYMaxHeight] = useState(220),
    { common, user } = props?.stores || {},
    { staffInfo } = user || {},
    { applicationList, currentApplication } = common || {};
  getPromiseHeight('expense')?.then((res) => {
    setYMaxHeight(
      res - document?.querySelector('#expenseTitle')?.offsetHeight - 75,
    );
  });

  const onFetch = useCallback(
    async (params) =>
      getOrderOutpBillingDetailList({ ...params, orderId: order?.id }),
    [order?.id],
  );

  // const onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //       {
  //         applyStaffName: 1,
  //       },
  //     ],
  //   };
  // }, []);

  return (
    <VSPromiseTable
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {
          const therow = vsf?.refs?.[TableId]?.getValues()?.find(
            (ele) => ele.id === key,
          );
          if (
            Object.keys(value)?.includes('amount') &&
            includes(['New', 'Temp'], therow?.TheRowType)
          ) {
            form?.setFieldValue?.(
              [key, 'cost'],
              askTotalAmount([[therow?.price, record?.amount]]),
            );
          }
        },
      }}
      scroll={{
        y: yMaxHeight,
        x: 2928,
      }}
      vsid="75572"
      id={TableId}
      onFetch={onFetch}
      pagination={false}
      rowSelection={{
        selectType: 'multiple',
        selectedRows,
        onSelectedRowsChange: (data) => setSelectedRows(data),
      }}
      onRecord={async () => {
        let params = {
          orderId: order?.id,
          TheRowType: 'New', // 新增标识
          itemName: '请选择项目名称',
          amount: 1, // 数量
          amountPerform: 0, //执行数量
          cancelPerformAmount: 0, //取消执行数量
          cost: 0, // 计价费用
          charge: 0, // 应收金额
          orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
          operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
          applyStaffName: order?.applyStaffName, // 开单人员（默认取医嘱的开单医生，可修改）
          applyStaffId: order?.applyStaffNameId, // 开单人员id
          performStaffName: staffInfo?.staffName, // 员工姓名
          performStaffId: staffInfo?.id, // Long 录入人
          operatorStaffName: staffInfo?.staffName, // 操作人
          operatorStaffid: staffInfo?.id, // Long 操作人
          performDepartmentId: currentApplication?.department?.id, // 科室主键名称
          applyDepartmentId: currentApplication?.department?.id, // 主键
          applyDepartmentName: currentApplication?.department?.departmentName,
          performDepartmentName: currentApplication?.department?.departmentName, // 科室名称
          outpVisitId,
          ...props?.item,
        };
        if (props?.item?.itemId) {
          params.itemName = {
            label: item?.itemName, // 项目名称
            value: item?.itemId, // 项目编码
          };
          params.cost = props?.item?.price;
        }
        return {
          id: `TEMP_${Math?.random?.()}`,
          ...params,
        };
      }}
      bordered
      onUpdate={async (data) => {
        return await onChanegUpdata(data);
      }}
    >
      <VSTableColumn
        dataIndex={['itemName']}
        title="项目名称"
        valueType="text"
        formItemProps={{
          rules: [
            {
              required: true,
              type: 'object',
            },
          ],
        }}
        width={250}
        fieldProps={{
          placeholder: '请选择',
        }}
        renderFormItem={(...args) => {
          let [schema, rows, form] = args,
            component = null;
          if (includes(['New'], rows?.record?.TheRowType)) {
            component = (
              <ProjectNameSelect
                {...{
                  ...schema?.originProps,
                  ...rows,
                  form,
                  table_id: TableId,
                }}
              />
            );
          } else {
            component = onTableFieldEllipsis(rows?.record?.itemName?.label);
          }
          return component;
        }}
        editable={(_, node) => includes(['New'], node?.TheRowType)}
        render={(_, { itemName }) => onTableFieldEllipsis(itemName)}
      />

      <VSTableColumn
        width={68}
        dataIndex={['itemSpecification']}
        title="规格"
        valueType="text"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        width={68}
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{}}
        editable={false}
        render={(_, { price }) => parseFloat(price ?? 0).toFixed(2)}
      />

      <VSTableColumn
        width={168}
        dataIndex={['materialCode']}
        title="材料条码"
        valueType="text"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        width={108}
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          return {
            rules: [
              {
                validator: (_, value) => {
                  if ([undefined, null, NaN, ''].includes(value))
                    return Promise.reject('数量是必填');
                  if (value && value <= 0)
                    return Promise.reject('数量必须是整数');

                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        width={108}
        dataIndex={['amountPerform']}
        title="执行数量"
        valueType="digit"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        width={108}
        dataIndex={['cancelPerformAmount']}
        title="取消执行数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          const { amountPerform } = vsf?.refs?.[TableId]?.getValues?.()?.find(
            (ele) => ele?.id === entry?.id,
          );
          return {
            rules: [
              {
                validator: (_, value) => {
                  if (value && value <= 0)
                    return Promise.reject('数量必须是正整数');
                  if (value && amountPerform < value)
                    return Promise.reject(
                      '取消执行的数量不能大于当前费用总数量',
                    );
                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        editable={(_, { TheRowType }) => includes(['Sava'], TheRowType)}
      />

      <VSTableColumn
        width={168}
        dataIndex={['cost']}
        title="应收金额"
        valueType="digit"
        fieldProps={{}}
        preview
        renderFormItem={(_, { record }) =>
          parseFloat(record?.cost ?? 0).toFixed(2)
        }
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['performStaffName']}
        title="执行医生"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['applyStaffName']}
        title="开单人员"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['applyDepartmentName']}
        title="开单科室"
        valueType="selectCode"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['operateDate']}
        title="计费日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="aspirinDateTimePicker"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['medicalInsuranceType']}
        title="医保等级"
        valueType="select"
        dataSource={[
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['insuranceAuditNumber']}
        title="审批备案号"
        valueType="text"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['operatorStaffName']}
        title="操作人"
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '必填',
              required: true,
            },
          ],
        }}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['insuranceCode']}
        title="医保编码"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { insuranceCode }) => onTableFieldEllipsis(insuranceCode)}
      />

      <VSTableColumn
        dataIndex={['billingStatus']}
        title="计费状态"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { billingStatus }) => {
          let str, style;
          switch (billingStatus) {
            case 'BILLING':
              str = '已划价';
              style = { color: 'rgba(50, 118, 232, 1)' };
              break;
            case 'UNBILLING':
              str = '未划价';
              style = { color: 'rgba(48, 163, 19, 1)' };
              break;
            case 'SETTLE':
              str = '已结算';
              style = { color: 'rgba(227, 74, 78, 1)' };
              break;
          }

          return <sapn style={style}>{str}</sapn>;
        }}
      />
    </VSPromiseTable>
  );
};

// 非医嘱费用
const Not = (props) => {
  const { onChanegUpdata } = props;
  return (
    <div id="NotDeatail">
      <div id="left">
        <p className="title" id="leftTitle">
          计费日期
        </p>
        <div
          className="table"
          style={{
            paddingRight: '20px',
          }}
        >
          <NotLeftTaleb {...{ ...props }} />
        </div>
      </div>
      <div id="right">
        <p className="title" id="rightTitle">
          费用明细表
        </p>
        <div className="table">
          <NotRightTaleb {...{ ...props, onChanegUpdata }} />
        </div>
      </div>
    </div>
  );
};

// 非医嘱费用 -- 计费日期
const NotLeftTaleb = (props) => {
  const [yMaxHeight, setYMaxHeight] = useState(517),
    {
      outpVisitId,
      NoOrderOutpBilling,
      setNoOrderOutpBilling,
      NoOrderOutpReload,
    } = props;
  getPromiseHeight('left')?.then((res) => {
    setYMaxHeight(
      res - document?.querySelector('#leftTitle')?.offsetHeight - 75,
    );
  });

  const { data, run } = useRequest(
    async (params) => {
      const res = await getNoOrderOutpBillingDetailGroupList(params);
      if (NoOrderOutpBilling) {
        setNoOrderOutpBilling(
          res?.data?.find((ele) => NoOrderOutpBilling?.orderDate),
        );
      } else {
        setNoOrderOutpBilling(res?.data?.[0]);
      }

      return res;
    },
    { manual: true },
  );
  useEffect(() => run?.({ outpVisitId }), [outpVisitId, NoOrderOutpReload]);
  return (
    <VSTable
      scroll={{
        y: yMaxHeight,
      }}
      pagination={false}
      vsid="03454"
      id="outpMedicalTechnologyChargeNoOrderChargeDateList"
      value={data?.data}
      // value={[
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      //   {
      //     clinicClass: 1,
      //   },
      // ]}
      rowClassName={(node) => {
        return node?.orderDate === NoOrderOutpBilling?.orderDate
          ? 'aspirin-vs-table-row-box aspirin-row-selected-box row_font_size'
          : 'aspirin-vs-table-row-box aspirin-row-select-box row_font_size';
      }}
      onRow={(node) => {
        return {
          onClick: () => {
            if (node?.orderDate === NoOrderOutpBilling?.orderDate)
              return setNoOrderOutpBilling();
            setNoOrderOutpBilling(node);
          },
        };
      }}
    >
      <VSTableColumn
        dataIndex={['clinicClass']}
        title="医疗类别"
        valueType="select"
        dataSource={[
          { label: '门诊', value: 'OUTP' },
          { label: '住院', value: 'INP' },
          { label: '急诊', value: 'ERP' },
          { label: '体检', value: 'PHYSICAL_EXAM' },
          { label: '挂号', value: 'CLINIC_REGISTER' },
        ]}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="计费日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD' }}
      />
    </VSTable>
  );
};

// 非医嘱费用 -- 费用明细表
const NotRightTaleb = (props) => {
  const {
      selectedRows,
      setSelectedRows,
      outpVisitId,
      operationDoctorData,
      NoOrderOutpBilling,
      NoOrderOutpReload,
      onChanegUpdata,
    } = props,
    TableId = 'outpMedicalTechnologyChargeNoOrderChargeBillingDetailList',
    [yMaxHeight, setYMaxHeight] = useState(517),
    { common, user } = props?.stores || {},
    { staffInfo } = user || {},
    { applicationList, currentApplication } = common || {},
    onFetch = useCallback(
      async (params) => {
        if (NoOrderOutpBilling?.outpBillingDetailVoList?.length <= 0) return [];
        // TODO: 请确认入参来源及出参用法
        const output =
          convertGetNoOrderOutpBillingDetailGroupListRes2OutpMedicalTechnologyChargeNoOrderChargeBillingDetailList?.(
            NoOrderOutpBilling?.outpBillingDetailVoList,
          );
        return output;
      },
      [NoOrderOutpBilling?.outpBillingDetailVoList],
    );

  // onFetch = useCallback(() => {
  //   return {
  //     data: [
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //       {
  //         itemName: 1,
  //       },
  //     ],
  //   };
  // });

  getPromiseHeight('right')?.then((res) => {
    setYMaxHeight(
      res - document?.querySelector('#rightTitle')?.offsetHeight - 75,
    );
  });
  return (
    <VSPromiseTable
      editable={{
        editType: 'multiple',
        columnProps: {
          hideInTable: true,
        },
        onFieldChange: (key, value, record, form) => {
          const therow = vsf?.refs?.[TableId]?.getValues()?.find(
            (ele) => ele.id === key,
          );
          if (
            Object.keys(value)?.includes('amount') &&
            includes(['New', 'Temp'], therow?.TheRowType)
          ) {
            form?.setFieldValue?.(
              [key, 'cost'],
              askTotalAmount([[therow?.price, record?.amount]]),
            );
          }
        },
      }}
      scroll={{
        y: yMaxHeight,
        x: 2928,
      }}
      pagination={false}
      vsid="54168"
      id={TableId}
      onFetch={onFetch}
      rowSelection={{
        selectType: 'multiple',
        selectedRows,
        onSelectedRowsChange: (data) => setSelectedRows(data),
      }}
      onRecord={async () => {
        let params = {
          TheRowType: 'New', // 新增标识
          itemName: '请选择项目名称',
          amount: 1, // 数量
          amountPerform: 0, //执行数量
          cancelPerformAmount: 0, //取消执行数量
          cost: 0, // 计价费用
          charge: 0, // 应收金额
          orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
          operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
          applyStaffName: staffInfo?.staffName, // 操作人, // 开单人员（默认取医嘱的开单医生，可修改）
          applyStaffId: staffInfo?.id, // 开单人员id
          performStaffName: staffInfo?.staffName, // 员工姓名
          performStaffId: staffInfo?.id, // Long 录入人
          operatorStaffName: staffInfo?.staffName, // 操作人
          operatorStaffid: staffInfo?.id, // Long 操作人
          performDepartmentId: currentApplication?.department?.id, // 科室主键名称
          applyDepartmentId: currentApplication?.department?.id, // 主键
          applyDepartmentName: currentApplication?.department?.departmentName,
          performDepartmentName: currentApplication?.department?.departmentName, // 科室名称
          outpVisitId,
          ...props?.item,
        };
        if (props?.item?.itemId) {
          params.itemName = {
            label: item?.itemName, // 项目名称
            value: item?.itemId, // 项目编码
          };
          params.cost = props?.item?.price;
        }
        return {
          id: `TEMP_${Math?.random?.()}`,
          ...params,
        };
      }}
      bordered
      onUpdate={async (data) => {
        return await onChanegUpdata(data);
      }}
    >
      <VSTableColumn
        dataIndex={['itemName']}
        title="项目名称"
        valueType="select"
        formItemProps={{
          rules: [
            {
              required: true,
              type: 'object',
            },
          ],
        }}
        width={250}
        fieldProps={{
          placeholder: '请选择',
        }}
        renderFormItem={(...args) => {
          let [schema, rows, form] = args,
            component = null;
          if (includes(['New'], rows?.record?.TheRowType)) {
            component = (
              <ProjectNameSelect
                {...{
                  ...schema?.originProps,
                  ...rows,
                  form,
                  table_id: 'outpMedicalTechnologyChargeOrderBillingDetailList',
                }}
              />
            );
          } else {
            component = onTableFieldEllipsis(rows?.record?.itemName?.label); //<span> {rows?.record?.itemName?.label}</span>;
          }
          return component;
        }}
        editable={(_, node) => includes(['New'], node?.TheRowType)}
        render={(_, { itemName }) => onTableFieldEllipsis(itemName)}
      />

      <VSTableColumn
        width={68}
        dataIndex={['itemSpecification']}
        title="规格"
        valueType="text"
        fieldProps={{}}
        preview
      />

      <VSTableColumn
        width={68}
        dataIndex={['price']}
        title="单价"
        valueType="digit"
        fieldProps={{}}
        renderFormItem={(_, { record }) => askFixed(record?.price, 2)}
        preview
      />

      <VSTableColumn
        width={168}
        dataIndex={['materialCode']}
        title="材料条码"
        valueType="text"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        width={108}
        dataIndex={['amount']}
        title="数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          return {
            rules: [
              {
                validator: (_, value) => {
                  if ([undefined, null, NaN, ''].includes(value))
                    return Promise.reject('数量是必填');
                  if (value && value <= 0)
                    return Promise.reject('数量必须是整数');

                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        width={108}
        dataIndex={['amountPerform']}
        title="执行数量"
        valueType="digit"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        width={108}
        dataIndex={['cancelPerformAmount']}
        title="取消执行数量"
        valueType="digit"
        fieldProps={{}}
        formItemProps={(form, { entry, ...conig }) => {
          const { amountPerform } = vsf?.refs?.[TableId]?.getValues?.()?.find(
            (ele) => ele?.id === entry?.id,
          );
          return {
            rules: [
              {
                validator: (_, value) => {
                  if (value && value <= 0)
                    return Promise.reject('数量必须是正整数');
                  if (value && amountPerform < value)
                    return Promise.reject(
                      '取消执行的数量不能大于当前费用总数量',
                    );
                  return Promise.resolve();
                },
              },
            ],
          };
        }}
        editable={(_, { TheRowType }) => includes(['Sava'], TheRowType)}
      />

      <VSTableColumn
        width={168}
        dataIndex={['cost']}
        title="应收金额"
        valueType="digit"
        fieldProps={{}}
        preview
        renderFormItem={(_, { record }) =>
          parseFloat(record?.cost ?? 0).toFixed(2)
        }
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['performStaffName']}
        title="执行医生"
        formItemProps={{}}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['performDepartmentName']}
        title="执行科室"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataSource={operationDoctorData}
        fieldNames={{
          label: 'staffName',
          value: 'staffId',
        }}
        valueType="selectCode"
        dataIndex={['applyStaffName']}
        title="开单人员"
        formItemProps={{}}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataSource={applicationList?.map((element) => ({
          applyDepartmentName: element?.department?.departmentName,
          applyDepartmentId: element?.department?.id,
        }))}
        fieldNames={{
          label: 'applyDepartmentName',
          value: 'applyDepartmentId',
        }}
        dataIndex={['applyDepartmentName']}
        title="开单科室"
        valueType="selectCode"
        formItemProps={{}}
        fieldProps={{}}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['operateDate']}
        title="计费日期"
        valueType="date"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['orderDate']}
        title="业务时间"
        valueType="aspirinDateTimePicker"
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
        editable={(_, node) => includes(['New', 'Temp'], node?.TheRowType)}
      />

      <VSTableColumn
        dataIndex={['medicalInsuranceType']}
        title="医保等级"
        valueType="select"
        dataSource={[
          { label: '甲', value: 'ALPHA' },
          { label: '乙', value: 'BETA' },
          { label: '丙', value: 'GAMMA' },
        ]}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['insuranceAuditNumber']}
        title="审批备案号"
        valueType="text"
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['operatorStaffName']}
        title="操作人"
        valueType="text"
        formItemProps={{}}
        fieldProps={{}}
        editable={false}
      />

      <VSTableColumn
        dataIndex={['insuranceCode']}
        title="医保编码"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { insuranceCode }) => onTableFieldEllipsis(insuranceCode)}
      />

      <VSTableColumn
        dataIndex={['billingStatus']}
        title="计费状态"
        valueType="text"
        fieldProps={{}}
        editable={false}
        render={(_, { billingStatus }) => {
          let str, style;
          switch (billingStatus) {
            case 'BILLING':
              str = '已划价';
              style = { color: 'rgba(50, 118, 232, 1)' };
              break;
            case 'UNBILLING':
              str = '未划价';
              style = { color: 'rgba(48, 163, 19, 1)' };
              break;
            case 'SETTLE':
              str = '已结算';
              style = { color: 'rgba(227, 74, 78, 1)' };
              break;
          }
          return <sapn style={style}>{str}</sapn>;
        }}
      />
    </VSPromiseTable>
  );
};

export default definePage(TableDetails);
