import { getScrollYRem, labelColWidth, labelColWidthRem } from '@/utils';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSFormLayout,
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSFormFooter,
  VSTableAddition,
  VSTableToolbar,
  Text,
  message,
  Modal,
} from '@vs/vsf-kit';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Icon from '@/module/Icon';
import { Screen } from '@/pages/Index/components';
import { CreateScreen } from '@/pages/Index/components/screen';
import CustomSelect from './custom_select';

const DrugApplyDetailList = definePage((props) => {
  const { activityBill, setActivityBill } = props;
  const currentDrugRef = useRef();

  const onScreen = useContext(CreateScreen);

  const [isGenerated, setIsGenerated] = useState(false);
  useEffect(
    () =>
      vsf?.refs?.vSControlledForm282001?.setFieldValue?.(
        'storageId',
        props?.Storages?.[0]?.value,
      ),
    [],
  );
  useEffect(() => {
    if (activityBill) {
      vsf?.refs?.vSControlledForm282001?.setFieldValue?.(
        'storageId',
        activityBill?.storageId,
      );
      vsf?.refs?.vSControlledForm282001?.validateFieldsReturnFormatValue();
    }
  }, [activityBill]);
  const onFetch = useCallback(async (params) => {
    if (params?.extra?.activityBill?.id) {
      // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugApplyController_getListDrugApplyMasterDetailProductVoByDrugApplyMasterId_69a8ec?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            drugApplyMasterId: params?.extra?.activityBill?.id,
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      // TODO: 你需要自己补充入参input来源及出参output用法
      const output =
        convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDrugApplyDetail(
          res,
        );
      setIsGenerated(!!output?.length);
      return {
        data: output ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  }, []);
  /**
   * convertQueryListDrugApplyDropRes2TableDrugApplyDetail
   * @param {any} input
   * @returns {any}
   */
  const convertQueryListDrugApplyDropRes2TableDrugApplyDetail = (data) => {
    const _ = window._ ?? {};
    return data.map((d) => {
      const obj = {};
      obj.drugProductName = _.get(
        d,
        'drugPriceItemInfo.drugProductInfo.drugProductName',
      );
      obj.packageSpecification = _.get(
        d,
        'drugPriceItemInfo.packageSpecification',
      );
      obj.price = _.get(d, 'drugPriceItemInfo.priceItemInfo.price');
      obj.firmName = _.get(
        d,
        'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
      );
      obj.packageUnit = vsf.stores.dbenums?.enums?.MEASURES_DICT?.find(
        (e) => e?.code === _.get(d, 'drugPriceItemInfo.packageUnit'),
      )?.cname;
      obj.amount = _.get(d, 'amount');
      obj.lowLevel = _.get(d, 'lowLevel');
      obj.retailAmount = _.get(d, 'retailAmount');
      obj.packageRatio = _.get(
        d,
        'drugPriceItemInfo.drugProductInfo.packageRatio',
      );
      obj.useAmount = _.get(d, 'useAmount');
      obj.amountPerLowLevel = _.get(d, 'amountPerLowLevel');
      obj.amountPerUseAmount = _.get(d, 'amountPerUseAmount');
      obj.drugPriceItemId = _.get(d, 'drugPriceItemInfo.id');
      obj.key = _.get(d, 'drugPriceItemInfo.id');

      return obj;
    });
  };
  /**
   * convertTableDrugApplyDetail2SubmitDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableDrugApplyDetail2SubmitDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output =
      input?.map((d) => {
        const obj = {};
        obj.id =
          typeof d.id === 'string' && `${d.id}`.includes('create_')
            ? undefined
            : d.id;
        obj.itemNumber = d.itemNumber; // 项目序号
        obj.retailAmount = d.retailAmount; // 零售数量
        obj.drugPriceItemId = d.drugPriceItemId; // 药品价表编码
        obj.drugProductName = d.drugProductName; // 商品名称
        return obj;
      }) ?? [];
    return output;
  };
  /**
   * convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDrugApplyDetail
   * @param {any} input
   * @returns {any}
   */
  const convertGetListDrugApplyMasterDetailProductVoByDrugApplyMasterIdRes2TableDrugApplyDetail =
    (input) => {
      const _ = window._ ?? {};
      const output = [];
      output.push(
        ...(_.get(input, 'data') ?? []).map((d) => {
          const obj = {};
          obj.drugProductName = _.get(
            d,
            'drugPriceItemInfo.drugProductInfo.drugProductName',
          );
          obj.packageSpecification = _.get(
            d,
            'drugPriceItemInfo.packageSpecification',
          );
          obj.price = _.get(d, 'drugPriceItemInfo.priceItemInfo.price');
          obj.firmName = _.get(
            d,
            'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
          );
          obj.packageUnit = _.get(d, 'drugPriceItemInfo.packageUnit');
          obj.amount = _.get(d, 'amount');
          obj.lowLevel = _.get(d, 'lowLevel');
          obj.retailAmount = _.get(d, 'retailAmount');
          obj.packageRatio = _.get(
            d,
            'drugPriceItemInfo.drugProductInfo.packageRatio',
          );
          obj.useAmount = _.get(d, 'useAmount');
          obj.amountPerLowLevel = _.get(d, 'amountPerLowLevel');
          obj.amountPerUseAmount = _.get(d, 'amountPerUseAmount');
          obj.id = _.get(d, 'id');
          obj.drugPriceItemId = _.get(d, 'drugPriceItemInfo.id');
          obj.itemNumber = _.get(d, 'itemNumber');
          return obj;
        }),
      );
      return output;
    };
  /**
   * convertVSControlledForm2820012SaveDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSControlledForm2820012SaveDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = activityBill?.id;
    output.applyNumber = activityBill?.applyNumber; // TODO output.drugApplyMasterDetailVo.applyNumber = null; // String 申请单号
    output.applyType = _.get(input, 'form.applyType'); // TODO output.drugApplyMasterDetailVo.provideStorageId = null; // Long 发放库房
    output.memo = _.get(input, 'form.memo'); // TODO output.drugApplyMasterDetailVo.drugApplyDetailList = []; // List<DrugApplyDetailVo> 主键
    output.provideStorageId = _.get(input, 'form.storageId');
    return output;
  };
  /**
   * convertTableDrugApplyDetail2SaveDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertTableDrugApplyDetail2SaveDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output =
      input?.map((d) => {
        const obj = {};
        obj.id =
          typeof d.id === 'string' && `${d.id}`.includes('create_')
            ? undefined
            : d.id;
        obj.itemNumber = d.itemNumber; // 项目序号
        obj.retailAmount = d.retailAmount; // 零售数量
        obj.drugPriceItemId = d.drugPriceItemId; // 药品价表编码
        obj.drugProductName = d.drugProductName; // 商品名称
        return obj;
      }) ?? [];
    return output;
  };
  /**
   * convertVSControlledForm2820012SubmitDrugApplyReq
   * @param {any} input
   * @returns {any}
   */
  const convertVSControlledForm2820012SubmitDrugApplyReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = activityBill?.id;
    output.applyNumber = activityBill?.applyNumber; // TODO output.drugApplyMasterDetailVo.applyNumber = null; // String 申请单号
    output.applyType = _.get(input, 'form.applyType'); // TODO output.drugApplyMasterDetailVo.provideStorageId = null; // Long 发放库房
    output.memo = _.get(input, 'form.memo'); // TODO output.drugApplyMasterDetailVo.drugApplyDetailList = []; // List<DrugApplyDetailVo> 主键
    output.provideStorageId = _.get(input, 'form.storageId');
    return output;
  };
  /**
   * convertCreateApplyDetailsRes2TableDrugApplyDetail
   * @param {any} input
   * @returns {any}
   */
  const convertCreateApplyDetailsRes2TableDrugApplyDetail = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data') ?? []).map((d) => {
        const obj = {};
        obj.id = `create_${Math.random()}`;
        obj.drugProductName = _.get(
          d,
          'drugPriceItemInfo.drugProductInfo.drugProductName',
        );
        obj.packageSpecification = _.get(
          d,
          'drugPriceItemInfo.packageSpecification',
        );
        obj.price = _.get(d, 'drugPriceItemInfo.priceItemInfo.price');
        obj.firmName = _.get(
          d,
          'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
        );
        obj.packageUnit = _.get(d, 'drugPriceItemInfo.packageUnit');
        obj.amount = _.get(d, 'amount');
        obj.lowLevel = _.get(d, 'lowLevel');
        obj.retailAmount = _.get(d, 'retailAmount');
        obj.packageRatio = _.get(
          d,
          'drugPriceItemInfo.drugProductInfo.packageRatio',
        );
        obj.useAmount = _.get(d, 'useAmount');
        obj.amountPerLowLevel = _.get(d, 'amountPerLowLevel');
        obj.amountPerUseAmount = _.get(d, 'amountPerUseAmount');
        obj.drugPriceItemId = _.get(d, 'drugPriceItemInfo.id');
        return obj;
      }),
    );
    return output;
  };

  const handelGenerateBills = async (params) => {
    if (!(await vsf.refs?.vSControlledForm282001?.getFieldValue('storageId'))) {
      message.error('请选择上级库房');
      return;
    }
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.DrugApplyController_createApplyDetails_23076c?.({
        // TODO: 请检查以下这部分传参是否符合需求
      }); // TODO: 你可能需要整理返回数据格式
    // TODO: 你需要自己补充入参input来源及出参output用法
    if (res?.data) {
      const rows = (await vsf.refs?.tableDrugApplyDetail?.getValues()) ?? [];
      rows?.forEach(async (data) => {
        await vsf.refs?.tableDrugApplyDetail?.remove(data);
      });
      await vsf.refs?.tableDrugApplyDetail?.add(
        convertCreateApplyDetailsRes2TableDrugApplyDetail(res),
      );
      setIsGenerated(true);
    }
  };

  const handelSave = async (params) => {
    try {
      const formParam =
        await vsf.refs?.vSControlledForm282001?.validateFieldsReturnFormatValue();
      //
      // Tips: 以下是你选择的请求函数
      const rows = await vsf.refs?.tableDrugApplyDetail?.getValues();
      if (!rows?.length) return;
      const input = convertVSControlledForm2820012SaveDrugApplyReq({
        form: formParam,
      }); // TODO: 你需要自己补充入参input来源及出参output用法
      await vsf.refs?.tableDrugApplyDetail.getEditForm().validateFields();
      input.drugApplyDetailList =
        convertTableDrugApplyDetail2SaveDrugApplyReq(rows);
      console.debug(JSON.stringify(input));
      const res =
        await vsf.services?.DrugApplyController_saveDrugApply_999999?.({
          // TODO: 请检查以下这部分传参是否符合需求
          drugApplyMasterDetailVo: input,
        }); // TODO: 你可能需要整理返回数据格式
      if (res?.code === 200) {
        message.success('保存成功!');
        vsf.refs?.tableDrugApplyDetail?.reload();
      }
      return res?.data;
    } catch (error) {
      if (
        await vsf.refs?.vSControlledForm282001.getFieldError('storageId')?.[0]
      ) {
        Modal.warning({
          mask: false,
          title: '提示',
          content: '请选择上级库房',
          okText: '关闭',
        });
      }
    }
  };

  const handelSubmit = async (params) => {
    try {
      const formParam =
        await vsf.refs?.vSControlledForm282001?.validateFieldsReturnFormatValue();
      await vsf.refs?.tableDrugApplyDetail.getEditForm().validateFields();
      Modal.confirm({
        title: '提示',
        content: '提交后不可修改，确认提交到上级库房？',
        okText: '是',
        cancelText: '否',
        onOk: async () => {
          //
          // Tips: 以下是你选择的请求函数
          // TODO: 你需要自己补充入参input来源及出参output用法
          const rows = await vsf.refs?.tableDrugApplyDetail?.getValues();
          if (!rows?.length) return;
          const input = convertVSControlledForm2820012SubmitDrugApplyReq({
            form: formParam,
          }); // TODO: 你需要自己补充入参input来源及出参input用法
          input.drugApplyDetailList =
            convertTableDrugApplyDetail2SubmitDrugApplyReq(rows);
          console.debug(JSON.stringify(input));
          const res =
            await vsf.services?.DrugApplyController_submitDrugApply_da8985?.({
              // TODO: 请检查以下这部分传参是否符合需求
              drugApplyMasterDetailVo: input,
            }); // TODO: 你可能需要整理返回数据格式
          if (res.code === 200) {
            message.success('提交成功！');
            vsf.refs?.tableDrugApplyDetail?.reload();
          }
          return res?.data;
        },
      });
    } catch (error) {
      if (
        await vsf.refs?.vSControlledForm282001.getFieldError('storageId')?.[0]
      ) {
        Modal.warning({
          mask: false,
          title: '提示',
          content: '请选择上级库房',
          okText: '关闭',
        });
      }
    }
  };

  const handelDrugNameSelectChange = (v) => {
    currentDrugRef.current = v;
  };

  const handelTableDrugSelectLoadData = useCallback(async (inputCodeLike) => {
    const response =
      await vsf?.services?.DrugApplyController_queryListDrugApplyDrop_3db394?.({
        qto: {
          inputCodeLike,
          size: 999999,
        },
      });
    const data = response?.data?.result ?? [];

    return convertQueryListDrugApplyDropRes2TableDrugApplyDetail(data);
  }, []);

  const handelFieldChange = async (key, value, record, form) => {
    if (value?.drugProductName) {
      form?.setFieldValue(key, {
        ...record,
        packageSpecification: currentDrugRef.current?.packageSpecification,
        price: currentDrugRef.current?.price,
        firmName: currentDrugRef.current?.firmName,
        packageUnit: currentDrugRef.current?.packageUnit,
        amount: currentDrugRef.current?.amount,
        lowLevel: currentDrugRef.current?.lowLevel,
        packageRatio: currentDrugRef.current?.packageRatio,
        useAmount: currentDrugRef.current?.useAmount,
        amountPerLowLevel: currentDrugRef.current?.amountPerLowLevel,
        amountPerUseAmount: currentDrugRef.current?.amountPerUseAmount,
        drugPriceItemId: currentDrugRef?.current?.drugPriceItemId,
        drugProductName: currentDrugRef?.current?.drugProductName,
      });
    }
  };

  const Selected = (props) => {
    return <CustomSelect {...props} />;
  };

  return (
    <>
      <VSControlledForm
        vsid="28200"
        id="vSControlledForm282001"
        value={props.value}
        labelAlign="left"
      >
        <VSFormLayout
          key="0"
          columnCount={4}
          labelCol={labelColWidthRem('6.8rem')}
        >
          <VSFormItem
            name={['applyType']}
            label="申请单类型"
            valueType="select"
            initialValue="09"
            fieldProps={{
              defaultValue: '09',
              className: 'width240',
              disabled: true,
            }}
            dataSource={vsf.stores.dbenums?.enums?.DRUG_IMPEXP_CLASS_DICT}
          />

          <VSFormItem
            name={['storageId']}
            label="上级库房"
            valueType="select"
            fieldProps={{
              className: 'width240',
              showSearch: true,
              disabled: isGenerated,
              // defaultValue: props?.Storages?.[0]?.value,
            }}
            dataSource={props?.Storages}
            rules={[
              {
                required: true,
                message: '请选择上级库房',
              },
            ]}
          />

          <VSFormItem
            name={['memo']}
            label="备注"
            valueType="text"
            rules={[
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ]}
            fieldProps={{
              className: 'width240',
              // disabled: isGenerated
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              // children="一键生成申请单"
              onClick={handelGenerateBills}
            >
              一键生成申请单
            </Button>
          </div>
        </VSFormLayout>
      </VSControlledForm>

      <VSPromiseTable
        pagination={false}
        scroll={{
          x: '114.6rem',
          y: getScrollYRem(26),
        }}
        className="aspirin-table"
        editable={{
          editType: 'multiple',
          columnProps: {
            width: '5.33rem',
            // hideInTable: true
          },
          editText: <Icon type="icon-bianji" className="icon24" />,
          deleteText: <Icon type="icon-shanchu1" className="icon24" />,
          onFieldChange: handelFieldChange,
        }}
        vsid="46723"
        id="tableDrugApplyDetail"
        extraParams={{ activityBill }}
        onRecord={() => ({ id: `create_${Math.random()}` })}
        onFetch={onFetch}
      >
        <VSTableToolbar title="药品列表" style={{ padding: '0 24px' }}>
          <Button
            children="新增"
            position="top"
            onClick={async () => {
              const TheTabel = vsf?.refs?.tableDrugApplyDetail;
              await TheTabel?.add({});
              const theTableValues = TheTabel?.getValues();
              TheTabel?.scrollTo(
                theTableValues?.[theTableValues?.length - 1]?.id,
              );
              setIsGenerated(true);
            }}
            type="primary"
          />
        </VSTableToolbar>

        <VSTableColumn
          width="5.33rem"
          dataIndex={'id'}
          title="序号"
          valueType="text"
          preview
          renderFormItem={({ index }) => index + 1}
        />

        <VSTableColumn
          width="18.66rem"
          dataIndex={['drugProductName']}
          title="药品名称"
          valueType="select"
          formItemProps={{
            rules: [{ required: true, message: '请选择药品' }],
          }}
          renderFormItem={(...arr) => {
            const [schema, recode, form] = arr;
            return (
              <Selected
                tableProps={{
                  pagination: false,
                }}
                recode={recode}
                form={form}
                onChange={schema.originProps.onChange}
                width="36.66rem"
                placeholder="请输入药品名称"
                showLabel="drugProductName"
                handelDrugNameSelectChange={handelDrugNameSelectChange}
                loadData={handelTableDrugSelectLoadData}
                // isPreview={schema?.entity?.id && !`${schema?.entity?.id}`?.includes('create_')}
                columns={[
                  {
                    title: '药品名称',
                    dataIndex: 'drugProductName',
                    key: 'drugProductName',
                  },
                  {
                    title: '药品编码',
                    dataIndex: 'drugPriceItemId',
                    key: 'drugPriceItemId',
                  },
                  {
                    title: '药品规格名称',
                    dataIndex: 'packageSpecification',
                    key: 'packageSpecification',
                  },
                  {
                    title: '厂家',
                    dataIndex: 'firmName',
                    key: 'firmName',
                  },
                  {
                    title: '包装单位',
                    dataIndex: 'packageUnit',
                    key: 'packageUnit',
                  },
                ]}
              />
            );
          }}
        />

        <VSTableColumn
          width="8.66rem"
          dataIndex={['packageSpecification']}
          title="包装规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 50 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="7.33rem"
          dataIndex={['price']}
          title="零售价"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: 'price的值不合法',
                validator: (_, value) => {
                  if (value === undefined) return Promise.resolve();
                  if ((true && value >= 0) || (!true && value > 0)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('price的值不合法'));
                },
              },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="13.86rem"
          dataIndex={['firmName']}
          title="厂家"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6rem"
          dataIndex={['packageUnit']}
          title="单位"
          valueType="select"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 8 },
            ],
          }}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.MEASURES_DICT}
          preview
        />

        <VSTableColumn
          width="8.66em"
          dataIndex={['amount']}
          title="本地库存量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="4.8rem"
          dataIndex={['lowLevel']}
          title="下限"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="7.33em"
          dataIndex={['retailAmount']}
          title="请领数量"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请领数量的值不合法',
                type: 'number',
                min: 1,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
          editable={true}
        />

        <VSTableColumn
          width="7.33em"
          dataIndex={['packageRatio']}
          title="每件数量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.66rem"
          dataIndex={['useAmount']}
          title="消耗量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="6.33em"
          dataIndex={['amountPerLowLevel']}
          title="库存/下限"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          width="8.66em"
          dataIndex={['amountPerUseAmount']}
          title="库存/消耗量"
          valueType="digit"
          fieldProps={{}}
          preview
        />
      </VSPromiseTable>
      <div className="footer">
        <Button
          style={{
            borderColor: '#3276E8',
            color: '#3276E8',
          }}
        >
          打印
        </Button>
        <Button
          style={{
            borderColor: '#3276E8',
            color: '#3276E8',
          }}
          onClick={() => {
            setActivityBill(null);
            onScreen();
          }}
        >
          清屏
        </Button>
        <Button type="primary" onClick={handelSubmit}>
          提交
        </Button>
        <Button type="primary" onClick={handelSave}>
          保存
        </Button>
      </div>
    </>
  );
});

const DrugApplyDetailListScreen = (props) => {
  return (
    <Screen>
      <DrugApplyDetailList {...props} />
    </Screen>
  );
};

export default DrugApplyDetailListScreen;
