/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import vsf, { definePage } from '@vs/vsf-boot';
import { Checkbox, Image, Select, Switch, Tag } from '@vs/vsf-kit';
import { useAsyncEffect } from 'ahooks';
import React, { useEffect, useImperativeHandle } from 'react';

import PeopleImage from '@/assets/people.png';
import Block from '@/module/Block';
import Empty from '@/module/Empty';
import Visible from '@/module/Visible';

import { useOnChangeValue } from '../func/hooks';
import Option from './option';

const timelineType = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '西药/中成药',
    value: 'DRUG',
  },
  {
    label: '中药',
    value: 'HERB',
  },
  {
    label: '检验',
    value: 'LAB',
  },
  {
    label: '检查',
    value: 'EXAM',
  },
  {
    label: '处置',
    value: 'TREAT',
  },
  {
    label: '手术',
    value: 'OPERATION',
  },
  {
    label: '会诊',
    value: 'CONSULTATION',
  },
  { label: '病理', value: 'PATHOLOGY' },
];

const PatientJourney = (props) => {
  const { user } = props?.stores ?? {};
  const { initKey, currentPatient, outVisitInfo, inVisitInfo, hospitalType } =
    user ?? {};

  const [select] = useOnChangeValue(['all']);
  const [check] = useOnChangeValue(false);
  const [update] = useOnChangeValue(false);
  const [list] = useOnChangeValue([]);

  const _patientId = currentPatient?.patientId;
  const _outpVisitIdIs = outVisitInfo?.outpVisit?.id;
  const _inVisitId = inVisitInfo?.inpVisitId;

  useAsyncEffect(async () => {
    if (!_patientId) {
      list?.onChange([]);
    } else {
      const _isTREAT = select?.value?.find((item) => {
        return item == 'TREAT';
      });
      const _id =
        hospitalType == 'out'
          ? { outpVisitIdIs: check?.value ? _outpVisitIdIs : void 0 }
          : // : {
            //     inpVisitIds: 80503,
            //   };
            { inpVisitIdIs: _inVisitId };
      // console.log(inVisitInfo?.inpVisitId, 'drug__inVisitId');
      if (_inVisitId) {
        const res =
          await vsf?.services?.PatientJourneyController_getPatientJourney_95f0ff?.(
            {
              qto: {
                orderClassIn:
                  select?.value?.[0] === 'all'
                    ? void 0
                    : _isTREAT
                    ? [...select?.value, 'OTHER']
                    : select?.value,
                patientIdIs: _patientId,
                ..._id,
                isPatientJourneyIs: true,
              },
            },
          );
        if (res?.code === 200) {
          list?.onChange(res?.data);
        }
      }
    }
  }, [
    _patientId,
    // 范围
    select?.value,
    _outpVisitIdIs,
    // 患者信息
    _inVisitId,
    hospitalType,
    // 本次就诊
    check?.value,
  ]);

  const tagRender = (params) => {
    const { label, value, closable, onClose } = params;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className="timeline">
      <Block
        titleStyle={{ padding: 0 }}
        headerStyle={{ marginBottom: '16px' }}
        desc={
          <Checkbox style={{ marginLeft: '15px' }} {...check}>
            本次就诊
          </Checkbox>
        }
        title={update?.value ? '患者画像' : '患者旅程'}
        extra={
          <div className="toggle">
            <span className="label">
              {update?.value ? '患者画像' : '患者旅程'}
            </span>
            <Switch size="small" {...update} />
          </div>
        }
      >
        <Visible value={!update?.value}>
          <div className="timeline-type">
            <Select
              style={{ width: '100%' }}
              mode="tags"
              size="large"
              placeholder="请选择项目类型"
              // value={select?.value}
              {...select}
              dataSource={timelineType}
              // tagRender={tagRender}
              // onChange={handleChange}
              // onSelect={(e) => {
              //   handleSelect(e);
              // }}
            />
          </div>
          {!list?.value?.length ? (
            <Empty />
          ) : (
            <div className="hideScroll" style={{ overflowY: 'scroll' }}>
              {list?.value?.map((item, index) => {
                return (
                  <Option
                    onClick={() => {
                      // setCurrentItem(item?.orderId);
                    }}
                    data={item}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </Visible>
        <Visible value={update?.value}>
          <div className="people-image-container">
            <Image preview={false} src={PeopleImage} />
          </div>
        </Visible>
      </Block>
    </div>
  );
};

export default definePage(PatientJourney);
