import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStockController_queryDrugs_529e82: {
    method: 'post',
    url: '/api/drug-storage/query-drugs',
    parameterFomatter: (data?: {
      qry: DrugProductHaveStockAndPriceEoDrugDrugPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSplitVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_queryDrugStockSupply_25f6e9: {
    method: 'post',
    url: '/api/drug-storage/query-drug-stock-supply',
    parameterFomatter: (data?: {
      qto: QueryDrugStockSupplyQtoDrugStoragePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugStockSupplyVoDrugStorageEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  DrugStockSupplyController_updateDrugStockSupply_255a7d: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stock-supply',
    parameterFomatter: (data?: {
      stock: DrugStockSupplyVoDrugStorageEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStockSupplyController_updateDrugStockLimit_0ab0d8: {
    method: 'post',
    url: '/api/drug-storage/update-drug-stock-limit',
    parameterFomatter: (data?: {
      stock: DrugStockSupplyVoDrugStorageEntranceWebVo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStockSupplyController_getByDrugProductIdAndPackageSpecificationAndDrugStorageId_7821c4:
    {
      method: 'post',
      url: '/api/drug-storage/get-by-drug-product-id-and-package-specification-and-drug-storage-id',
      parameterFomatter: (data?: {
        var: DrugProductWhitelistDrugProductIdPackageSpecificationDrugStorageIdEoDrugStoragePersistEo;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugProductWhitelistPatientVoDrugStorageEntranceWebVo[],
      ) => data,
    },
  DrugStockSupplyController_deleteDrugProductWhitelist_f7ce9e: {
    method: 'post',
    url: '/api/drug-storage/delete-drug-product-whitelist',
    parameterFomatter: (data?: {
      btoParam: DeleteDrugProductWhitelistBtoDrugStorageServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStockSupplyController_saveDrugProductWhitelists_e211c9: {
    method: 'post',
    url: '/api/drug-storage/save-drug-product-whitelists',
    parameterFomatter: (data?: {
      btos: SaveDrugProductWhitelistBtoDrugStorageServiceBto[];
      drugProductId: number;
      packageSpecification: string;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  PatientMainController_getPagefallBySearchPatientQto_013fbc: {
    method: 'post',
    url: '/api/patient-main/get-pagefall-by-search-patient-qto',
    parameterFomatter: (data?: {
      qto: SearchPatientQtoPatientMainPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientVoDrugStorageEntranceWebVoDrugproductwhitelistpatientvo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
