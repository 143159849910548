import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalTechnologyInpChargeController_getOrderInpOrderCostGroupList_5b7080: {
    method: 'post',
    url: '/api/medical-technology/get-order-inp-order-cost-group-list',
    parameterFomatter: (data?: { orderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
  MedicalTechnologyInpChargeController_getOrderInpBillingDetailList_364d5f: {
    method: 'post',
    url: '/api/medical-technology/get-order-inp-billing-detail-list',
    parameterFomatter: (data?: { orderId: number; itemId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailVoMedicalTechnologyEntranceWebVo[],
    ) => data,
  },
  MedicalTechnologyInpChargeController_saveInpBillingDetails_dcf810: {
    method: 'post',
    url: '/api/medical-technology/save-inp-billing-details',
    parameterFomatter: (data?: {
      saveInpBillingDetailList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
