import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Checkbox,
  Column,
  Columns,
  ConfigProvider,
  Drawer,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormDependency,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { divide } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AspirinSelectInput } from '@/components';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import {
  convertFirstBedData2FirstReqParam,
  convertGet,
  convertGetClinicBedWithPatientVoByIdRes2VSPromiseTableFirstSingleBedPatientRo,
  convertSecondBedData2SecondReqParam,
  currentWardTotalData,
  totalData,
} from '@/pages/Index/manage/bed_card/convert';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import storage from '@/utils/storage';

import {
  convertGetErpVisitTransferOutDetailVoByErpVisitTransferOutDetailQtoRes2ErpVisitTransferOutDetailForm,
  convertQueryErpVisitTransferOutListVoByEWardIdRes2ErpVisitTransferOutTable,
} from './convert';

const Index = (props) => {
  const {
    stores: {
      common: { currentApplication },
    },
  } = props;

  // table当前选中行;
  const [currentErpVisit, setCurrentErpVisit] = useState<any>(null);
  // isempty
  const [isEmpty, setIsEmpty] = useState<any>(true);

  const [open, setOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // form

  // 保存

  // 刷新
  const handelClear = () => {
    setCurrentErpVisit(null);
    setIsEmpty(true);
    // onFetch_t3131();
    vsf.refs?.waitTransferPatientTable?.reload();
  };
  // table
  const onFetch_t6227 = useCallback(async (params) => {
    const res =
      await vsf?.services?.ClinicErpVisitController_queryErpVisitTransferOutListVoByEWardId_286418?.(
        { wardId: params?.wardId },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertQueryErpVisitTransferOutListVoByEWardIdRes2ErpVisitTransferOutTable?.(
        res,
      );
    return output;
  }, []);
  // form
  const onFetch_t6974 = useCallback(async (params) => {
    const res =
      await vsf?.services?.ClinicErpVisitController_getErpVisitTransferOutDetailVoByErpVisitTransferOutDetailQto_a1a29e?.(
        { qto: { erpVisitId: params?.erpVisitId } },
      ); // TODO: 请确认入参来源及出参用法
    const output =
      convertGetErpVisitTransferOutDetailVoByErpVisitTransferOutDetailQtoRes2ErpVisitTransferOutDetailForm?.(
        res,
      );
    return res?.data;
  }, []);

  // const renderSearch = () => {
  //   return (
  //     <div className="patient_transfer_search_input">
  //       <div className="patient_transfer_search_input_item">
  //         <span className="patient_transfer_search_input_item_label">
  //           患者ID
  //         </span>
  //         <Input
  //           height={32}
  //           width={200}
  //           onKeyUp={async (e: any) => {
  //             if (e.code === 'Enter') {
  //               setLoading(true);
  //               const res = await onFetch_t3131();
  //               setLoading(false);
  //               const _item = res?.data?.find(
  //                 (i) => i?.displayId == e?.target?.value,
  //               );
  //               if (_item) {
  //                 getDetail({ erpVisitId: _item?.erpVisitId });
  //               } else {
  //                 setIsEmpty(true);
  //                 message.info('未找到该患者');
  //               }
  //             }
  //           }}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="patient_transfer">
      <div className="patient_transfer_left">
        <div className="patient_transfer_left_form_title">
          <div className="patient_transfer_left_form_title_tag"></div>
          待出院患者
        </div>
        <div className="patient_transfer_table">
          <VSPromiseTable
            // editable={{ editType: 'single' }}
            id="erpVisitTransferOutTable"
            // onFetch={onFetch_t6227}
            onFetch={async () => {
              return [
                // {
                //   preDischargeIndicator: true,
                //   name: '张三',
                //   bedLabel: '12床',
                //   erpVisitId: '12345',
                //   status: true,
                // },
              ];
            }}
            pagination={false}
            className="
            aspirin-table-nopadding
          aspirin-table-header-background-color
           aspirin-table-row-hover-background-color
           aspirin-table-body-empty-transparent-background-color
          "
            onRow={(record: any) => {
              return {
                onClick: () => {
                  if (currentErpVisit?.erpVisitId == record?.erpVisitId) {
                    setCurrentErpVisit(null);
                  } else {
                    setCurrentErpVisit(record);
                  }
                },
              };
            }}
            rowClassName={(record: any, v) => {
              console.log(record, currentErpVisit, 'ppppp');

              return cn(
                currentErpVisit?.erpVisitId == record?.erpVisitId
                  ? 'aspirin-table-row-select-background-color'
                  : '',
              );
            }}
          >
            <VSTableColumn
              dataIndex={['erpVisitId']}
              title="主键"
              valueType="digit"
              hideInTable
              fieldProps={{}}
            />
            {/* 待出院标志 */}
            <VSTableColumn
              dataIndex={['preDischargeIndicator']}
              title=""
              valueType="text"
              fieldProps={{}}
              width={80}
              render={(_, value) => {
                return value?.preDischargeIndicator ? (
                  <div className="patient_transfer_tag">待出院</div>
                ) : (
                  ''
                );
              }}
            />

            <VSTableColumn
              dataIndex={['bedLabel']}
              title="床号"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['status']}
              title="状态"
              valueType="text"
              fieldProps={{}}
              width={80}
              render={(dom, value) => {
                return (
                  <div
                    className={cn(
                      'patient_transfer_status',
                      value?.status
                        ? 'patient_transfer_status_true'
                        : 'patient_transfer_status_false',
                    )}
                  ></div>
                );
              }}
            />
          </VSPromiseTable>
        </div>
      </div>
      <div className="patient_transfer_right">
        {/* <div className="patient_transfer_search">{renderSearch()}</div> */}
        {loading ? (
          <Loading size="normal" />
        ) : isEmpty ? (
          <div className="patient_transfer_form">
            <div className="patient_transfer_form_content">
              <VSForm
                id="erpVisitTransferOutDetailForm"
                params={props.value}
                onFetch={onFetch_t6974}
                onSubmit={async (values) => {
                  const res =
                    await vsf?.services?.ClinicErpAdtController_transferErpPatientOut_792c49?.(
                      { erpVisitId: values?.erpVisitId },
                    );
                  return values;
                }}
                labelAlign="left"
                labelWrap
                readonly
              >
                <Block
                  title="基本信息"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    key="0"
                    labelCol={labelColWidth[6]}
                    columnCount={3}
                    gutter={50}
                  >
                    <VSFormItem
                      name={['displayId']}
                      label="患者ID"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['bedLabel']}
                      label="床号"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['name']}
                      label="姓名"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['gender']}
                      label="性别"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    />

                    <VSFormItem
                      name={['age']}
                      label="年龄"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['birthAddress']}
                      label="出生地"
                      valueType="address"
                      fieldProps={{ showDetail: true }}
                    />

                    <VSFormItem
                      name={['identityCode']}
                      label="人员类别"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
                    />

                    <VSFormItem
                      name={['defaultChargeType']}
                      label="费别"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    />

                    <VSFormItem
                      name={['contactPersonName']}
                      label="联系人"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['contactPersonPhone']}
                      label="联系人电话"
                      valueType="text"
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </Block>
                <Block
                  title="本区域接收信息"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    key="1"
                    labelCol={labelColWidth[6]}
                    columnCount={3}
                    gutter={50}
                  >
                    <VSFormItem
                      name={['arriveHospitalDateTime']}
                      label="到院时间"
                      valueType="date"
                      fieldProps={{
                        className: 'widthAutoFill',
                      }}
                    />

                    <VSFormItem
                      name={['nurseAcceptDateTime']}
                      label="接收时间"
                      valueType="date"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['patientCondition']}
                      label="病情"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={
                        vsf.stores.dbenums?.enums?.PATIENT_STATUS_DICT
                      }
                    />

                    <VSFormItem
                      name={['nursingClass']}
                      label="护理等级"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                    />

                    <VSFormItem
                      name={['staffName']}
                      label="主管医生"
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['diagnosisDescription']}
                      label="诊断"
                      valueType="text"
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </Block>
                <Block
                  title="转出校对信息"
                  titleStyle={{ fontWeight: '600' }}
                  mainStyle={{ padding: '0 16px 0 16px' }}
                  style={{
                    border: '1px solid transparent',
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    key="2"
                    labelCol={labelColWidth[6]}
                    columnCount={3}
                    gutter={50}
                  >
                    <VSFormItem
                      name={['notChargeTally']}
                      label="托收未结算"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notChargeLab']}
                      label="未计费临时检验"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notPerformExam']}
                      label="未执行检查"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notOperateDrugDescription']}
                      label="未发药处方"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notTakeDischargeDrug']}
                      label="未取出出院带药"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notSubmitOrder']}
                      label="未校对医嘱"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notPerformOrder']}
                      label="未执行医嘱"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['notHandleReturnOrder']}
                      label="未处理回退医嘱"
                      valueType="digit"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />
                  </VSFormLayout>
                </Block>
                <Block
                  title=""
                  titleStyle={{
                    fontWeight: '600',
                    padding: '0 16px 0 16px',
                    // borderBottom: '1px solid red',
                  }}
                  mainStyle={{
                    padding: '16px 16px 0 16px',
                    borderTop: '1px solid #d9d9d9',
                  }}
                  style={{
                    height: 'auto',
                    paddingBottom: 0,
                  }}
                >
                  <VSFormLayout
                    key="3"
                    labelCol={labelColWidth[6]}
                    columnCount={3}
                    gutter={50}
                  >
                    <VSFormItem
                      name={['preDischargeDateExpected']}
                      label="转出时间"
                      valueType="date"
                      fieldProps={{ className: 'widthAutoFill' }}
                    />

                    <VSFormItem
                      name={['dischargeWay']}
                      label="转出方式"
                      valueType="select"
                      fieldProps={{}}
                      dataSource={
                        vsf.stores.dbenums?.enums?.ER_DISCHARGE_DISPOSITION_DICT
                      }
                    />

                    <VSFormItem
                      name={['outPatientDisease']}
                      label="病种分类"
                      valueType="text"
                      fieldProps={{}}
                      required
                      readonly={false}
                    />

                    <VSFormItem
                      name={['outPatientDosc1']}
                      label="ROSC"
                      valueType="text"
                      fieldProps={{}}
                      required
                      readonly={false}
                    />
                  </VSFormLayout>
                </Block>
              </VSForm>
            </div>
            <div className="patient_transfer_form_bottom">
              <div
                className="patient_transfer_form_bottom_btn1 patient_transfer_form_bottom_btn"
                onClick={handelClear}
              >
                刷新
              </div>
              <div
                // onClick={handelSave}
                className="patient_transfer_form_bottom_btn2 patient_transfer_form_bottom_btn"
              >
                保存
              </div>
            </div>
          </div>
        ) : (
          <div className="patient_transfer_form">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default definePage(Index);
