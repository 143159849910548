import './index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Drawer,
  message,
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import FormTitle from '@/pages/Index/components/form_title';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { height, heightMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

import { convertCancelInAndOutTransferForm2CancelInAndOutTransferPatientReq } from './convert';

const dataSource = {
  TRANSFER_IN: '转入',
  TRANSFER_OUT: '转出',
  DISCHARGE: '出院',
  NEW_IN: '新入',
};
const formTitleBgc = '#f4f5f7';
const Index = (props, ref) => {
  const { data, status } = props;
  const [open, setOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [currentValue, setCurrent] = useState<any>();
  // 当前病人科室id，患者详情取
  const [department, setDepartment] = useState<any>();

  // 当前医疗组长所在组id
  const [groupId, setGroupId] = useState<any>();
  useEffect(() => {
    if (open) {
      setCurrent(null);
      // setDepartment(res?.)
      setGroupId(data?.groupId);
      vsf.refs?.cancelInAndOutTransferForm?.setFieldsValue(data);
    }
  }, [open, data]);
  console.log(data, 'datadata');

  // 床号
  const getPagefallByClinicBedWithPatient = async () => {
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            bedStatusIs: '0',
            // 写死
            wardIdIs: vsf.stores.common.currentApplication?.department?.id,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
    // setBedList(res?.data?.result);
    return res?.data?.result.map((item) => {
      return { ...item, bedLabel: item?.bedLabel + '床' };
    });
  };

  const handelOpen = (v = false) => {
    setOpen(v);
  };

  const handelGetCurrent = (v) => {
    setCurrent(v);
  };

  const handelCancel = async (messages) => {
    const validate =
      await vsf.refs?.cancelInAndOutTransferForm.validateFields();

    if (!validate) return;
    const values = vsf.refs?.cancelInAndOutTransferForm?.getFieldsValue(data);
    const output =
      convertCancelInAndOutTransferForm2CancelInAndOutTransferPatientReq?.({
        ...data,
        ...values,
      });

    const res =
      await vsf?.services?.ClinicInpAdtController_cancelInAndOutTransferPatient_6e5fcf?.(
        output,
      );
    if (res?.code == 200) {
      message.success(messages);
      setCancelOpen(false);
      handelOpen(false);
      handelGetCurrent(output);
      setTimeout(() => {
        vsf.refs?.cancelInAndOutTransferPatientList?.reload();
      }, 500);
    }
    // setCancelOpen(true);
    // 取消新入
    // Modal.info({
    //   title: '警告',
    //   content: <div>该患者已产生费用，不能取消新入!</div>,
    //   okText: '关闭',
    //   centered: true,
    //   onOk() {},
    // });
    // Modal.info({
    //   title: '取消入出转提示',
    //   content: <div>已下达医嘱，无法取消入科</div>,
    //   okText: '关闭',
    //   centered: true,
    //   onOk() {},
    // });
    // 取消出院
    // Modal.info({
    //   title: '警告',
    //   content: <div>该患者未取消结算，无法进行此操作！</div>,
    //   okText: '关闭',
    //   centered: true,
    //   onOk() {},
    // });
    // Modal.info({
    //   title: '警告',
    //   content: <div>该患者已取消出院，无法再次取消出院！</div>,
    //   okText: '关闭',
    //   centered: true,
    //   onOk() {},
    // });
    // Modal.info({
    //   title: '取消入出转提示',
    //   content: <div>已取消入出转</div>,
    //   okText: '关闭',
    //   centered: true,
    //   onOk() {},
    // });
  };

  //医疗组长
  const getGroupController = async (v) => {
    if (!data?.transferDepartmentId) return;
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // departmentId: department,
          staffRole: '01',
          stopIndicator: false,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result
      ?.map((item) => {
        return {
          ...item?.medicalGroupMemberIndexDtoList?.[0]?.staff,
          groupId: item?.id,
        };
      })
      .filter((item) => {
        return item?.id;
      });
    return _res;
  };
  //主治医师
  const getGroupController02 = async (v) => {
    if (!data?.transferDepartmentId) return;
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // departmentId: data?.transferDepartmentId,
          // staffRole: '02',
          stopIndicator: false,
          groupId: groupId,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result?.map((item) => {
      const _data = item?.medicalGroupMemberIndexDtoList.map((__item) => {
        return __item?.staff;
      });
      return _data;
    });
    const _data = _res?.flat().filter((item) => {
      return item?.id;
    });
    console.log(_data);

    return _data ?? [];
  };

  const renderModal = () => {
    return (
      <div className="cancel_out_hospital_modal">
        <Modal
          className="cancel_out_hospital_modal"
          title="提示"
          width={520}
          centered
          open={cancelOpen}
          onCancel={() => {
            setCancelOpen(false);
          }}
          footer={[
            <Button
              key="1"
              type="primary"
              onClick={() => {
                handelCancel('取消出院成功');
              }}
            >
              取消出院
            </Button>,
            <Button
              key="2"
              type="primary"
              ghost
              onClick={() => {
                message.info('todo');
                // setCancelOpen(false);
              }}
            >
              补退费
            </Button>,
            <Button
              key="3"
              type="primary"
              ghost
              onClick={() => {
                message.info('todo');
                // setCancelOpen(false);
              }}
            >
              处方退药
            </Button>,
          ]}
        >
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ marginRight: 8, paddingTop: 2 }}>
              <Icon type="icon-a-tishijingshi" />
            </div>
            <div style={{ height: 50, overflowY: 'scroll' }}>
              患者取消出院即继续住院治疗，再次出院需要医生重新开立出院通知！仅需要【补退费】或【退药】，建议点击【补退费】跳转补退费进行补费退费，点击【处方退药】跳转退药界面进行退费
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="nurse_cancel_operation_drawer_footer">
        <Button
          className="close"
          onClick={() => {
            handelOpen(false);
          }}
        >
          关闭
        </Button>
        {(data?.status == 'NEW_IN' || data?.status == 'TRANSFER_IN') && (
          <Button
            className="marginLeft"
            type="primary"
            onClick={() => {
              handelCancel(
                data?.status == 'NEW_IN' ? '取消新入成功' : '取消转入成功',
              );
            }}
          >
            {data?.status == 'NEW_IN' ? '取消新入' : '取消转入'}
          </Button>
        )}
        {data?.status == 'TRANSFER_OUT' && (
          <Button
            className="marginLeft"
            type="primary"
            onClick={() => {
              handelCancel('取消转科成功');
            }}
          >
            取消转科
          </Button>
        )}
        {data?.status == 'DISCHARGE' && (
          <Button
            className="marginLeft"
            type="primary"
            onClick={async () => {
              if (!data?.settleIndicator) {
                const validate =
                  await vsf.refs?.cancelInAndOutTransferForm.validateFields();

                if (!validate) return;
                setCancelOpen(true);
              } else {
                handelCancel('取消出院成功');
              }
            }}
          >
            取消出院
          </Button>
        )}
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
    currentValue,
  }));

  return (
    <Drawer
      open={open}
      onClose={() => {
        handelOpen(false);
      }}
      width={1284}
      title={
        <div
          style={{
            color: '#3276E8',
            fontSize: '20px',
            fontWeight: 500,
          }}
        >
          患者信息
        </div>
      }
      destroyOnClose
      footer={renderFooter()}
    >
      <VSForm labelAlign="left" id="cancelInAndOutTransferForm" readonly={true}>
        <FormTitle style={{ padding: 0 }} title="基本信息" isNeedTag={true}>
          <VSFormLayout key="0" columnCount={3} labelWidth={120} gutter={100}>
            <VSFormItem
              name={['name']}
              label="姓名"
              valueType="text"
              rules={[
                {
                  message: '姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['displayId']}
              label="患者ID"
              valueType="text"
              rules={[
                {
                  message: '用于显示的id长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['inpVisitId']}
              label="住院号"
              valueType="digit"
              fieldProps={{}}
            />

            <VSFormItem
              name={['gender']}
              label="性别"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            />

            <VSFormItem
              name={['age']}
              label="年龄"
              valueType="text"
              fieldProps={{}}
            />

            {/* <VSFormItem
              name={['birthday']}
              label="出生日期"
              valueType="date"
              fieldProps={{}}
            /> */}
            <VSFormItem
              name={['chargeType']}
              label="费别"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
            />
            <VSFormItem
              name={['identityCode']}
              label="人员类别"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
            />

            <VSFormItem
              name={['prePaymentAmount']}
              label="预交金(元)"
              valueType="text"
              fieldProps={{}}
              convertValue={(v) => {
                return Number(v || 0).toFixed(2);
              }}
            />

            <VSFormItem
              name={['contact', 'name']}
              label="联系人"
              valueType="text"
              fieldProps={{}}
            />

            <VSFormItem
              name={['contact', 'phoneNumber']}
              label="联系人电话"
              valueType="text"
              rules={[
                {
                  message: '联系电话长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle style={{ padding: 0 }} title="入出转信息" isNeedTag={true}>
          <VSFormLayout key="1" columnCount={3} labelWidth={120} gutter={100}>
            <VSFormItem
              name={['admissionDepartmentName']}
              label="入院科室"
              valueType="text"
              rules={[
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['admissionDateTime']}
              label="入院时间"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm',
              }}
              // render={(_, data) => {
              //   return (
              //     <div
              //       style={{
              //         whiteSpace: 'nowrap',
              //       }}
              //     >
              //       {dayjs(data?.visitStartDate).format('YYYY-MM-DD HH:mm')}
              //     </div>
              //   );
              // }}
            />

            <VSFormItem
              name={['admissionDeptDateTime']}
              label="入科时间"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm',
              }}
            />

            <VSFormItem
              name={['transferDepartmentName']}
              label="转向科室"
              valueType="text"
              rules={[
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['dischargeDeptDateTime']}
              label="转科时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
            />

            <VSFormItem
              name={['dischargeDepartmentName']}
              label="出院科室"
              valueType="text"
              rules={[
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ]}
              fieldProps={{}}
            />
            <VSFormItem
              name={['dischargeDateTime']}
              label="出院时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
            />
            <VSFormItem
              name={['dischargeWay']}
              label="出院方式"
              valueType="select"
              fieldProps={{}}
              dataSource={vsf.stores.dbenums?.enums?.DISCHARGE_DISPOSITION_DICT}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle style={{ padding: 0 }} title="费用信息" isNeedTag={true}>
          <VSFormLayout key="2" columnCount={3} labelWidth={120} gutter={100}>
            <VSFormItem
              name={['prePaymentAmount']}
              label="预交金(元)"
              valueType="text"
              fieldProps={{}}
              convertValue={(v) => {
                return Number(v || 0).toFixed(2);
              }}
            />
            <VSFormItem
              name={['totalCost']}
              label="累计费用(元)"
              valueType="text"
              fieldProps={{}}
              convertValue={(v) => {
                return Number(v || 0).toFixed(2);
              }}
            />

            <VSFormItem
              name={['auditStatus']}
              label="审核状态"
              valueType="select"
              dataSource={[
                { label: '未审核', value: 'NOT_AUDIT' },
                { label: '护士审核', value: 'AUDIT_BY_NURSE' },
                { label: '收费处审核', value: 'AUDIT_BY_BILLING_OFFICE' },
              ]}
              style={{
                color: data?.auditStatus == 'NOT_AUDIT' ? '#E34A4E' : '#30A313',
              }}
              fieldProps={{}}
            />

            <VSFormItem
              name={['settleIndicator']}
              label="结算状态"
              valueType="select"
              dataSource={[
                { label: '未结算', value: false },
                { label: '已结算', value: true },
              ]}
              style={{
                color: !data?.settleIndicator ? '#E34A4E' : '#30A313',
              }}
              initialValue={false}
              fieldProps={{}}
            />
          </VSFormLayout>
        </FormTitle>
        <FormTitle style={{ padding: 0 }} title="在科信息" isNeedTag={true}>
          <VSFormLayout key="3" columnCount={3} labelWidth={120} gutter={100}>
            <VSFormItem
              name={['bed']}
              label="患者床位"
              valueType="codeTableSelect"
              readonly={false}
              fieldNames={{
                value: 'id',
                label: 'bedLabel',
              }}
              dataSource={getPagefallByClinicBedWithPatient}
              required={
                !(data?.status == 'NEW_IN' || data?.status == 'TRANSFER_IN')
              }
              fieldProps={{
                showSearch: true,
                disabled: status == 'TRANSFER_IN',
              }}
              // fieldNames={{ label: 'bedLabel', value: 'id' }}
            />

            <VSFormItem
              name={['nursingClass']}
              label="护理等级"
              valueType="codeTableSelect"
              fieldProps={{
                showSearch: true,
                disabled: status == 'TRANSFER_IN',
              }}
              readonly={false}
              dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
            />

            <VSFormItem
              name={['patientCondition']}
              label="病情"
              valueType="codeTableSelect"
              readonly={false}
              required={
                !(data?.status == 'NEW_IN' || data?.status == 'TRANSFER_IN')
              }
              fieldProps={{
                disabled: true,
                showSearch: true,
              }}
              dataSource={vsf.stores.dbenums?.enums?.PATIENT_STATUS_DICT}
            />
            <VSFormItem
              name={[
                'medicalGroupLeader',
                // 'staffName',
              ]}
              label="医疗组长"
              fieldNames={{
                label: 'staffName',
                value: 'id',
                key: 'groupId',
              }}
              rules={[{ required: true }]}
              valueType="select"
              dataSource={getGroupController}
              transform={(v) => {
                console.log(v, 'pop');
                return {
                  inpVisitVo: {
                    inpVisitExtensionStaffDto: {
                      medicalGroupLeader: { ...v },
                    },
                  },
                };
              }}
              readonly={false}
              fieldProps={{
                showSearch: true,
                disabled: status == 'TRANSFER_IN',
                onSelect: (c) => {
                  console.log(c);
                  setGroupId(c?.key);
                  vsf.refs.cancelInAndOutTransferForm.setFieldsValue({
                    attendingDoctor: '',
                  });
                },
                onClear: () => {
                  vsf.refs.cancelInAndOutTransferForm.setFieldsValue({
                    attendingDoctor: '',
                  });
                },
              }}
            />
            <VSFormItem
              name={['attendingDoctor']}
              label="主治医生"
              valueType="select"
              fieldProps={{
                showSearch: true,
                disabled: status == 'TRANSFER_IN',
              }}
              readonly={false}
              fieldNames={{ label: 'staffName', value: 'id' }}
              dataSource={getGroupController02}
              transform={(v) => {
                return {
                  attendingDoctor: {
                    ...v,
                  },
                };
              }}
            />
            {/* <VSFormItem
              name={['attendingDoctor']}
              label="主治医生"
              readonly={false}
              valueType="codeTableSelect"
              required
              fieldProps={{
                showSearch: true,
                disabled: status == 'TRANSFER_IN',
                dataSource: async (params) => {
                  const res =
                    await vsf?.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.(
                      {
                        // TODO: 请检查以下这部分传参是否符合需求
                        qto: {
                          from: params?.pagination?.from ?? 0,
                          size: params?.pagination?.size ?? 50,
                          orderList: params?.orderList,
                          inputCodeLike: params?.inputCodeLike,
                          ...params?.search,
                          ...params?.filters,
                          ...params?.extra,
                          ...(params ?? {}),
                        },
                      },
                    ); // TODO: 你可能需要整理返回数据格式
                  return res?.data?.result ?? [];
                },
                fieldNames: { label: 'staffName', value: 'id' },
              }}
              //   fieldProps={{ fieldNames: { label: 'staffName', id: 'id' } }}
            /> */}
          </VSFormLayout>
        </FormTitle>
      </VSForm>
      {renderModal()}
    </Drawer>
  );
};

export default forwardRef(Index);
