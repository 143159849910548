import React, { useCallback, useContext, useEffect, useState } from 'react';
import './index.less';
import {
  Col,
  Row,
  VSFormItem,
  Select,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  VSTable,
  Button,
  VSTableAction,
  Space,
  message,
  Modal,
  VSForm,
  VSFormLayout,
  VSFormFooter,
  Form,
  Tooltip,
  Badge,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import {
  getDetailById,
  prepareDrugStaffList,
  secondPrepareDrugStaffList,
  toDispenseWindowList,
  windowDetail,
} from './Service';
import { CreateScreen } from '@/pages/Index/components/screen';
import Screen from '@/pages/Index/components/screen';
const Big = require('big.js');
import Book from '@/assets/icon/book.png';
import { getScrollY } from '@/utils';
import OutInfo from '@/pages/Index/cpoe/center/header/Info/out_info';
import UserInfo from './UserInfo';
import { isObject, omitBy } from 'lodash';
import Tip from '@/pages/Index/components/func/Tip';
import { isMinScreen } from '@/config';
export default function TableWaitDispenseList({
  tabaleParam,
  callback,
  queryAllWindow,
  pageDetailWindow,
  queryList,
  ...props
}) {
  const { staffInfo } = props;
  useEffect(() => onSetPrepareDrugPeopleForm(), [staffInfo]);
  const onSetPrepareDrugPeopleForm = () => {
    vsf?.refs?.PrepareDrugPeopleForm?.setFieldsValue?.({
      prepareDrugStaffId: staffInfo?.id,
      secondPrepareDrugStaffId: staffInfo?.id,
    });
  };
  const { data: detailData, run } = useRequest(getDetailById, {
    manual: true,
  });
  const { data: onLineData } = useRequest(toDispenseWindowList);
  const { data: pageDetail, run: pageDetailRun } = useRequest(windowDetail, {
    manual: true,
  });
  const { data: firstList } = useRequest(prepareDrugStaffList);
  const { data: secondList } = useRequest(secondPrepareDrugStaffList);
  const [prescriptionCount, setPrescriptionCount] = useState();
  const [chargeTotal, setChargeTotal] = useState();
  const [transfer, setTransfer] = useState();
  const [form] = Form.useForm();
  const onScreen = useContext(CreateScreen);
  const [selecRowData, setSelecRowData] = useState([]);
  const [expandedRows, setExpandedRows] = useState();
  // const [isStartAntEnd, setIsStartAntEnd] = useState();
  const convertGetWaitDispenseDetailsRes2TableDispenseDetailRo = (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ..._.get(input, 'data').map((d) => {
        const obj = {};
        obj.prescriptionDate = _.get(d, 'prescriptionDate');
        obj.windowDescription = _.get(
          d,
          'dispenseWindowInfo.windowDescription',
        );
        obj.orderDepartmentName = _.get(d, 'orderDepartmentName');
        obj.orderDoctorName = _.get(d, 'orderDoctorName');
        obj.prescriptionAttributeName = _.get(d, 'prescriptionAttributeName');
        obj.settleDate = _.get(d, 'outpSettleInfo.settleDate');
        obj.totalCharge = _.get(d, 'totalCharge');
        obj.diagnosisName = _.get(d, 'diagnosisName');
        obj.details =
          (_.get(d, 'drugPrescriptionWaitDispenseDetailList') ?? []).map(
            (detail) => {
              const item = {
                detailId: _.get(detail, 'id'), // 主键
                orderText: _.get(detail, 'orderText'), // 医嘱内容
                amountPackageUnit: _.get(detail, 'amountPackageUnit'), // 数量包装单位
                usage: _.get(detail, 'orderDrugInfo.usage'), // 用量
                frequency: _.get(detail, 'orderDrugInfo.frequency.frequency'), // 执行频率描述
                administration: _.get(detail, 'orderDrugInfo.administration'), // 给药途径
                performDays: _.get(detail, 'orderDrugInfo.performDays'), // 执行天数
                dosageFormName: _.get(detail, 'dosageFormName'), // 剂型
                supplyIndicator: _.get(detail, 'supplyIndicator'), // 剂型
                firmName: _.get(
                  detail,
                  'drugPriceItemInfo.drugProductInfo.drugFirmInfo.firmName',
                ), // 厂商
                amount: _.get(detail, 'amount'), // 数量
                price: _.get(detail, 'price'), // 零售价格
              };
              return item;
            },
          ) ?? [];
        obj.id = _.get(d, 'id');
        return obj;
      }),
    );
    return output;
  };
  const expandedRowRender = (record) => {
    return (
      <VSTable
        bordered
        className="aspirin-table aspirin-child-table aspirin-table-nopadding"
        id="tableDispenseDetail-details"
        value={record.details}
        showHeader={false}
        pagination={false}
      >
        <VSTableColumn
          dataIndex={['detailId']}
          title="主键"
          hideInTable
          editable={false}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width="20rem"
          render={(_, values) => {
            if (!values?.supplyIndicator) {
              return (
                <div
                  className="text-overflow-omit"
                  style={{ color: '#E34A4E' }}
                >
                  <Tooltip title={values.orderText}>{values.orderText}</Tooltip>
                </div>
              );
            } else {
              return (
                <div className="text-overflow-omit">
                  <Tooltip title={values.orderText}>{values.orderText}</Tooltip>
                </div>
              );
            }
          }}
        />

        <VSTableColumn
          dataIndex={['amountPackageUnit']}
          title="数量包装单位"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width="6.66rem"
          render={(_, value) => (
            <span style={{ fontWeight: 700 }}>{value?.amountPackageUnit}</span>
          )}
        />

        <VSTableColumn
          dataIndex={['usage']}
          title="用量"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          render={(_, values) => {
            return Number(values?.usage).toFixed(2);
          }}
        />

        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          editable={false}
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          editable={false}
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSTableColumn
          dataIndex={['performDays']}
          title="天数"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          render={(_, v) => {
            return <span>{v.performDays}天</span>;
          }}
        />

        <VSTableColumn
          dataIndex={['dosageFormName']}
          title="剂型"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['price']}
          title="零售金额"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['firmName']}
          title="厂商"
          editable={false}
          valueType="text"
          width="20rem"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                min: 0,
                max: 100,
              },
            ],
          }}
          render={(_, values) => {
            return (
              <div className="text-overflow-omit">
                <Tooltip title={values.firmName}>{values.firmName}</Tooltip>
              </div>
            );
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          editable={false}
          valueType="digit"
          fieldProps={{}}
          render={(_, value) => (
            <span style={{ fontWeight: 600 }}>{value?.amount}</span>
          )}
        />
        <VSTableColumn
          title="操作"
          valueType="option"
          name={'option'}
          width={50}
          render={(_, value) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <img src={Book} style={{ width: '18px', height: '15px' }} />
              </div>
            );
          }}
        />
      </VSTable>
    );
  };

  const onFetch = useCallback(
    async (params) => {
      if (!tabaleParam) return [];
      setSelecRowData([]);
      const res =
        await vsf.services?.DrugPrescriptionDispenseController_getWaitDispenseDetails_323b08?.(
          {
            qto: {
              patientIdIs: tabaleParam?.patId,
              queryDateIs: tabaleParam?.queryDay,
              queryAllWindowIs: queryAllWindow,
              dispenseWindowIdIs: tabaleParam?.windowId,
            },
          },
        );
      if (res?.data && res?.data.length > 0) {
        const data = res?.data;
        let numer = new Big('0'),
          window__id = data?.[0]?.dispenseWindowInfo?.id,
          windows__Tips = false,
          i = 0;

        while (data?.length > i) {
          if (window__id !== data[i]?.dispenseWindowInfo?.id)
            windows__Tips = true;
          numer = numer.plus(new Big(data[i]?.totalCharge));
          i++;
        }
        windows__Tips && message?.success('其他窗口有待发药处方。');
        setChargeTotal(numer.toNumber().toFixed(2));
        setPrescriptionCount(res?.data?.length);
      } else {
        setPrescriptionCount(0);
        setChargeTotal('0.00');
      }
      const output =
        convertGetWaitDispenseDetailsRes2TableDispenseDetailRo(res);
      setSelecRowData(output);
      setExpandedRows(output);
      return output;
    },
    [tabaleParam, queryAllWindow, pageDetail],
  );
  useMount(() => {
    pageDetailRun();
  });

  //确认发药
  const confirmDistribute = async (params) => {
    if (selecRowData?.length <= 0)
      return message?.warning('暂无选择的处方药品！');
    await vsf?.refs?.PrepareDrugPeopleForm?.validateFieldsReturnFormatValue();

    Tip({
      content: '是否确认发药',
      onOk: async () => {
        const dispensingParam = {
          waitDispenseMasterId: selecRowData?.map(({ id }) => id),
        };
        omitBy(
          vsf?.refs?.PrepareDrugPeopleForm?.getFieldsValue(),
          (value, index) =>
            (dispensingParam[index] = isObject(value)
              ? value?.secondPrepareDrugStaffId
              : value),
        );
        const res =
          await vsf.services?.DrugPrescriptionDispenseController_dispensingPrescriptions_582638?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              dispensingParam,
            },
          );
        if (res?.code === 200) {
          message.success('发药成功');
          onSetPrepareDrugPeopleForm();
          queryList();
          setSelecRowData([]);
          setExpandedRows([]);
        }
        return res?.data;
      },
    });
  };

  const modalConfirm = async (param) => {
    if (!pageDetail?.useStatus) {
      const res =
        await vsf.services?.DrugPrescriptionDispenseController_useDispenseWindowNo_5107a0?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            bto: {
              id: pageDetail?.id,
              toDispenseWindowId: transfer,
              useStaffId: pageDetail?.useStaffId,
            },
          },
        ); // TODO: 你可能需要整理返回数据格式

      if (res?.code === 200) {
        message.success('窗口联机成功');
        callback();
        pageDetailRun();
        setOpen(false);
        vsf?.refs?.tableDispenseDetail?.reload();
      }
    } else {
      //窗口签退
      const res =
        await vsf.services?.DrugPrescriptionDispenseController_unUseDispenseWindowNo_3fb802?.(
          {
            bto: {
              id: pageDetail?.id,
              useStaffId: pageDetail?.useStaffId,
              toDispenseWindowId: transfer,
            },
          },
        );
      if (res?.code === 200) {
        onScreen();
        message.success('窗口签退成功');
        callback();
        pageDetailRun({ pageDetail: pageDetail });
        setOpen(false);
        setChargeTotal('0.00');
        setPrescriptionCount('0');
      }
    }
  };
  const [open, setOpen] = useState();

  const queryAllWindowIs =
    vsf?.refs?.drugSelectForm?.getFieldValue('queryAllWindowIs');
  useEffect(() => {
    run({
      id: tabaleParam?.outpVisitId,
      queryDay: tabaleParam?.queryDay,
      queryAllWindowIs: queryAllWindowIs || false,
      pageDetail: pageDetail,
    });
  }, [tabaleParam, queryAllWindowIs, pageDetail]);
  return (
    <Screen>
      <div className="top">
        <UserInfo {...props} outpVisitId={tabaleParam?.outpVisitId} />
      </div>

      <div className="bottom">
        <div className="total-table-prescription">
          <span className="total-chager-chufang">
            共{prescriptionCount || 0}个处方
          </span>
          <span className="total-chager-chufang">
            总金额[{chargeTotal || 0}]
          </span>
        </div>
        <VSPromiseTable
          vsid="52859"
          id="tableDispenseDetail"
          expandable={{
            expandedRows,
            expandDataIndex: "['details']",
            expandedRowRender,
            onExpandedRowsChange: (rows) => setExpandedRows(rows),
          }}
          rowSelection={{
            selectType: 'multiple',
            onSelect: (node, key, selected) => {},
            onSelectedRowsChange: (data, keys) => {
              setSelecRowData(data);
            },
            selectedRows: selecRowData,
          }}
          rowClassName={(...[, rowIndex]) => {
            return 'aspirin-table-row-background-box';
          }}
          onFetch={onFetch}
          pagination={false}
          scroll={{ x: 1100, y: getScrollY(isMinScreen ? 360 : 450) }}
        >
          <VSTableColumn
            dataIndex={['prescriptionDate']}
            valueType="index"
            width={70}
          />
          <VSTableColumn
            dataIndex={['prescriptionDate']}
            // title="处方日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
            render={(_, v) => {
              return (
                <div className="text-parents-style">
                  {v?.prescriptionDate?.split(' ')[0]}
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['windowDescription']}
            // title="窗口描述"
            valueType="text"
            fieldProps={{}}
            render={(_, v) => {
              return (
                <div className="text-parents-style">{v?.windowDescription}</div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderDepartmentName']}
            // title="开单科室"
            valueType="text"
            fieldProps={{}}
            render={(_, v) => {
              return (
                <div className="text-parents-style">
                  {v?.orderDepartmentName}
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['orderDoctorName']}
            // title={
            //   <div className="total-table-prescription">
            //     <span className="total-chager-chufang">
            //       共{prescriptionCount || 0}个处方
            //     </span>
            //     <span className="total-chager-chufang">
            //       总金额[{chargeTotal || 0}]
            //     </span>
            //   </div>
            // }
            // width={200}
            valueType="text"
            fieldProps={{}}
            render={(_, v) => {
              return (
                <div className="text-parents-style">{v?.orderDoctorName}</div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['prescriptionAttributeName']}
            valueType="text"
            fieldProps={{}}
            render={(_, v) => {
              return (
                <div className="text-parents-style">
                  {v?.prescriptionAttributeName}
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['settleDate']}
            // title="结算日期"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD' }}
            width={250}
            render={(_, v) => {
              return (
                <div className="text-parents-style">
                  收费时间: {v?.settleDate}
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['totalCharge']}
            // title="处方实收金额"
            valueType="digit"
            fieldProps={{}}
            render={(_, values) => {
              return (
                <div className="text-parents-style">
                  处方金额: {Number(values?.totalCharge).toFixed(2)}
                </div>
              );
            }}
          />

          <VSTableColumn
            dataIndex={['diagnosisName']}
            // title="诊断"
            valueType="text"
            fieldProps={{}}
            render={(_, values) => {
              return (
                <div
                  className="text-parents-style"
                  style={{ color: '#E34A4E' }}
                >
                  诊断: {values?.diagnosisName}
                </div>
              );
            }}
          />
          {/*
          <VSTableColumn
            dataIndex={['details']}
            // title="明细"
            valueType="listBox"
            fieldProps={{ children: <DateRangePicker /> }}
          /> */}
        </VSPromiseTable>
      </div>

      <div className="center">
        {/* <Form layout="inline" form={form}>
          <Form.Item label={'发药人1'} name={'prepareDrugStaffId'}>
            <Select
              width={200}
              dataSource={firstList}
              fieldNames={{
                label: 'staffName',
                value: 'secondPrepareDrugStaffId',
              }}
            />
          </Form.Item>
          <Form.Item label={'发药人2'} name={'secondPrepareDrugStaffId'}>
            <Select
              width={200}
              dataSource={firstList}
              fieldNames={{
                label: 'staffName',
                value: 'secondPrepareDrugStaffId',
              }}
            />
          </Form.Item>
        </Form> */}
        <VSForm id="PrepareDrugPeopleForm">
          <VSFormLayout columnCount={2}>
            <VSFormItem
              name={['prepareDrugStaffId']}
              label="发药人1"
              valueType="select"
              dataSource={firstList}
              fieldProps={{ width: 160, showSearch: true }}
              fieldNames={{
                label: 'staffName',
                value: 'secondPrepareDrugStaffId',
              }}
              rules={[{ required: true }]}
            />
            <VSFormItem
              name={['secondPrepareDrugStaffId']}
              label="发药人2"
              valueType="select"
              dataSource={firstList}
              fieldProps={{ width: 160, showSearch: true }}
              fieldNames={{
                label: 'staffName',
                value: 'secondPrepareDrugStaffId',
              }}
              rules={[{ required: true }]}
            />
          </VSFormLayout>
        </VSForm>
        <div>
          <Space size={4}>
            <Button
              type="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              窗口联机/签退
            </Button>
            <Button type="primary"> 打印</Button>
            <Button type="primary">问题反馈</Button>
            <Button type="primary" onClick={confirmDistribute}>
              发药确定
            </Button>
          </Space>
        </div>
      </div>
      <div className="other">
        <Modal
          title={
            <div className="ant-modal-dispense-title">窗口设置(西药房)</div>
          }
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={
            <Space size={4}>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                disabled={!pageDetail?.useStatus}
                onClick={modalConfirm}
              >
                窗口签退
              </Button>
              <Button
                type="primary"
                disabled={pageDetail?.useStatus}
                onClick={modalConfirm}
              >
                窗口联机
              </Button>
            </Space>
          }
        >
          <Row>
            <Col span={6} className="modal-dispense-font">
              药房：
            </Col>
            <Col span={6} className="modal-dispense-font-content">
              {pageDetail?.dispenseDrugStorageInfo?.storageName}
            </Col>
          </Row>
          <Row>
            <Col span={6} className="modal-dispense-font">
              当前窗口：
            </Col>
            <Col span={6} className="modal-dispense-font-content">
              {pageDetail?.windowDescription}
            </Col>
          </Row>
          <Row>
            <Col span={6} className="modal-dispense-font">
              联机状态：
            </Col>
            <Col span={6} className="modal-dispense-font-content">
              {pageDetail?.useStatusName || '离线'}
            </Col>
          </Row>
          {pageDetail?.useStatus && (
            <Row>
              <Col span={6} className="modal-dispense-font">
                处方转移:
              </Col>
              <Col span={12}>
                <Select
                  width={200}
                  dataSource={onLineData}
                  fieldNames={{
                    label: 'windowDescription',
                    value: 'toDispenseWindowId',
                  }}
                  onSelect={(v) => {
                    setTransfer(v?.value);
                  }}
                />
              </Col>
            </Row>
          )}
        </Modal>
      </div>
    </Screen>
  );
}

// export const TableWaitDispenseListSreen = () => {
//   return <Screen>
//     <TableWaitDispenseList />
//   </Screen>;
// };
