import vsf, { definePage } from '@vs/vsf-boot';
import { message, VSForm, VSFormItem, VSFormLayout } from '@vs/vsf-kit';
import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { askFixed } from '@/pages/Index/components/func/ask';

import { createPurchasePlans, getByMasterId } from './service';
const Index = forwardRef((props: any, ref: any) => {
  const { orderProps, TableMethod, setHerbType } = props;
  const form = useRef(null);
  const [total, setToal] = useState<number>(0);
  useImperativeHandle(ref, () => {
    return {
      form: form?.current,
      setTotal: (value: number) => setToal(value),
    };
  });
  const onFetch = useCallback(
    async (params) => {
      if (!orderProps?.id)
        return {
          data: [],
        };
      const res = await getByMasterId({
        ...params,
        value: {
          ...orderProps,
        },
      });
      if (res?.code != 200) return;
      setToal?.(Number(res?.data?.purchaseAccount));
      setHerbType(res?.data?.herbType);
      return {
        ...res?.data,
      };
    },
    [orderProps, setHerbType],
  );
  return (
    <VSForm ref={form} id="formHerbPurchasePlanDetailList" onFetch={onFetch}>
      <VSFormLayout key="0" columnCount={4}>
        <VSFormItem
          name={['herbType']}
          label="中药类型"
          valueType="selectCode"
          fieldProps={{
            onChange: (value) =>
              setTimeout(() => {
                props?.setHerbType(value);
              }, 200),
          }}
          dataSource={[
            { label: '全部', value: '' },
            ...vsf.stores.dbenums?.enums?.CHINESE_MEDICINE_DICT,
          ]}
          initialValue={props?.herbType}
        />
        <div className="">采购金额合计：{askFixed(total, 2)}元</div>
        <div className=""></div>
        <div className="">
          {props?.onButton([
            {
              disabled: false,
              text: '生成采购计划',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => {
                const values = await TableMethod?.()?.getValues?.();
                const filterList = values?.map((e) => e?.drugPriceItemId);
                // console.log(vsf?.refs?.tableHerbPurchasePlanDetailList);
                if (['', undefined, null, NaN]?.includes(props?.herbType))
                  return message?.error('请选择中药类型');
                const res = await createPurchasePlans({
                  herbType: props?.herbType,
                });
                if (!res) return;
                await props?.onTableAdd(res);
                message?.success('药品采购计划生成成功');
              },
            },
            {
              disabled: false,
              text: '新增',
              methodKey: 'altKey',
              ordinaryKey: '',
              onClick: async () => {
                await props?.onTableAdd({});
              },
            },
          ])}
        </div>
      </VSFormLayout>
    </VSForm>
  );
});
Index.displayName = 'Search';
export default memo(Index);
