import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientInHosptialController_queryScrollWardPatientList_346269: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-scroll-ward-patient-list',
    parameterFomatter: (data?: {
      qto: PageWardPatientQueryEoClinicInpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientInHospitalBedWardPatientVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PatientInHosptialController_queryPagedWardPatientList_e6e95f: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-paged-ward-patient-list',
    parameterFomatter: (data?: {
      qto: PageWardPatientQueryEoClinicInpVisitPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PatientInHospitalBedWardPatientVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
