import './assets/fonts/DINPro.ttf';
import '@vs/vsf-kit/dist/umd/index.css';
import './style/global.less';
import './style/reset.less';
import './style/vars.less';
import './style/adaptation.less';
import './api';
import './rem';

import { runApp } from '@vs/vsf-boot';
import { vsfKitGlobal } from '@vs/vsf-kit';

import routes from '@/router';
import { loadEnums } from '@/stores/dbenums';
import * as stores from '@/stores/index';

import services from './services';
// import('./components').then(() => {
//   loadEnums();
//   runApp(
//     {
//       stores,
//       services,
//       router: {
//         config: routes,
//       },
//     },
//     vsfKitGlobal,
//   );
// });

Promise.all([import('./components'), import('./pages/Index/components')]).then(
  () => {
    loadEnums();
    runApp(
      {
        stores,
        services,
        router: {
          config: routes,
        },
      },
      vsfKitGlobal,
    );
  },
);
