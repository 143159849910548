import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getWaitDischargePatientVoListByWardId_d7e976: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-wait-discharge-patient-vo-list-by-ward-id',
    parameterFomatter: (data?: { wardId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: WaitDischargePatientVoClinicInpVisitEntranceWebVo[],
    ) => data,
  },
});
