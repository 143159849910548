import {
  Section,
  Button,
  VSTableColumn,
  VSPromiseTable,
  DateRangePicker,
  VSTable,
  VSFormItem,
  VSForm,
  VSFormLayout,
  Todo,
} from '@vs/vsf-kit';
import { useMount, useRequest } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import { filterEmptyValues } from '@/utils/setDataUtil';
import vsf, { definePage } from '@vs/vsf-boot';

import { onFetchTable } from './service.ts';
import { isMinScreen } from '@/config/index';

export const onFilterTableData = (data, label, value) =>
  data?.filter((ele) => window?._?.get(ele, label) === value);

const OutpDispenseRefundApplyTable = (props) => {
  const {
    searchParams,
    dispenseStatus,
    callback,
    selectParamChange,
    orderText,
  } = props;
  const [expandableKeys, setExpandableKeys] = useState([]);

  let arr = [];

  const onFetch = useCallback(
    async (params) => {
      if (searchParams?.patientIdIs) {
        const _data = await onFetchTable({
          ...searchParams,
          dispenseStatus: dispenseStatus ?? 'ALL',
          orderText,
        });

        setExpandableKeys(
          _data?.data?.map((item) => ({ masterId: item?.masterId })),
        );
        return {
          ..._data,
        };
      }
    },
    [searchParams, dispenseStatus, orderText],
  );

  const expandedRowRender = (record) => {
    return (
      <VSTable
        bordered
        className="aspirin-table aspirin-child-table aspirin-table-nopadding"
        editable={{
          saveOnClickOutside: true,
          editType: 'single',
          columnProps: { hideInTable: true },
        }}
        rowKey={'detailId'}
        id="tablePrescListDispenseDetail"
        value={record?.dispenseDetail ?? []}
        showHeader={false}
        pagination={false}
        // rowSelection={{
        //   selectType: 'multiple',
        //   onSelect: (node, key, selected) => {
        //     const dataArr = selectArray?.filter(
        //       (items) => items?.masterId !== record?.masterId,
        //     );
        //     selectArray = dataArr;

        //     if (selected) {
        //       selectArr = selectArr.filter((item) => item.id !== node?.id);

        //       selectArr.push(node);

        //       console.log(selectArr);
        //       console.log(record);

        //       selectArray.push({
        //         ...record,
        //         dispenseDetail: selectArr.filter(
        //           (item) => item?.ids == record?.masterId,
        //         ),
        //       });
        //       selectParamChange(selectArray);
        //     }
        //   },
        // }}
        rowClick={{
          rowClickType: {
            action: 'double-click',
            type: 'edit-save',
          },
        }}
        onChange={(v) => {
          if (arr?.find((item) => record?.masterId === item?.masterId)) {
          }
          const dataArr = arr?.filter(
            (items) => items?.masterId !== record?.masterId,
          );
          arr = dataArr;
          arr.push({ ...record, dispenseDetail: v });

          callback(arr);
        }}
      >
        <VSTableColumn
          width={580}
          dataIndex={['orderText']}
          title="退费项目及规格"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'order_text长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          width={200}
          dataIndex={['firmName']}
          title="厂家"
          editable={false}
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />
        <VSTableColumn
          width={200}
          dataIndex={['amountUnit']}
          title="可退数量"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'amount_unit长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          width={200}
          dataIndex={['refundAmount']}
          title="退药数量"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'refund_amount的值不合法',
                pattern: /^\d+$/,
              },
            ],
          }}
          fieldProps={{
            width: 200,
          }}
          render={(dom, record) => {
            return (
              <div style={{ textAlign: 'right' }}>
                {record?.refundAmount ? (
                  <>
                    {dom}
                    <span style={{ marginLeft: 12 }}>{record?.refundUnit}</span>
                  </>
                ) : (
                  <span style={{ marginRight: 8, color: '#AEB0B3' }}>
                    请双击输入退药数量
                  </span>
                )}
              </div>
            );
          }}
        />

        <VSTableColumn
          width={150}
          dataIndex={['charge']}
          title="金额"
          editable={false}
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: 'charge的值不合法',
                validator: (_, value) => {
                  if (value === undefined) return Promise.resolve();
                  if ((true && value >= 0) || (!true && value > 0)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('charge的值不合法'));
                },
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, value) => (
            <div style={{ textAlign: 'right' }}>
              {Number(value?.charge ?? 0).toFixed(2)}
            </div>
          )}
        />
      </VSTable>
    );
  };
  return (
    <>
      <div className="table_header">
        <div className="content">
          <div>退费项目及规格</div>
          <div>可退数量</div>
          <div>退药数量</div>
          <div>金额</div>
        </div>
      </div>
      <VSPromiseTable
        scroll={{
          x: 1200,
          y: isMinScreen ? 350 : 500,
        }}
        extraParams={{ searchParams, dispenseStatus }}
        className="aspirin-table"
        expandable={{
          expandDataIndex: 'dispenseDetail',
          expandedRowRender,
          showExpandAll: false,
          indentSize: 0,
          expandedRows: expandableKeys,
          expandColumnIndex: -1,
        }}
        vsid="43563"
        id="tablePrescList"
        rowKey={'masterId'}
        onFetch={onFetch}
        pagination={false}
        rowClassName="custom_table_row_class"
      >
        <VSTableColumn
          width={40}
          // title="序号"
          editable={false}
          valueType="text"
          render={(_, __, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {index + 1}
            </div>
          )}
        />
        <VSTableColumn
          width={220}
          dataIndex={['prescriptionNumber']}
          // title="处方号"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '处方号长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom) => <>处方号：{dom}</>}
        />

        <VSTableColumn
          width={180}
          dataIndex={['prescriptionDate']}
          // title="处方日期"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD' }}
          render={(dom) => <>处方日期：{dom}</>}
        />

        <VSTableColumn
          dataIndex={['prescriptionAttribute']}
          // title="处方类型"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
          width={180}
          render={(dom) => <>处方属性：{dom}</>}
        />

        <VSTableColumn
          width={180}
          dataIndex={['orderClass']}
          // title="药品类型"
          editable={false}
          valueType="select"
          dataSource={[
            { label: '西药/中成药', value: 'DRUG' },
            { label: '中药', value: 'HERB' },
            { label: '检验', value: 'LAB' },
            { label: '检查', value: 'EXAM' },
            { label: '病理', value: 'PATHOLOGY' },
            { label: '治疗', value: 'TREAT' },
            { label: '手术', value: 'OPERATION' },
            { label: '麻醉', value: 'ANESTHESIA' },
            { label: '护理', value: 'NURSING' },
            { label: '膳食', value: 'MEAL' },
            { label: '营养', value: 'NUTRITION' },
            { label: '会诊', value: 'CONSULTATION' },
            { label: '用血', value: 'BLOOD' },
            { label: '其他', value: 'OTHER' },
          ]}
          fieldProps={{}}
          render={(dom) => <>药品类型：{dom}</>}
        />

        <VSTableColumn
          width={140}
          dataIndex={['dispenseStatus']}
          // title="发药状态"
          editable={false}
          valueType="select"
          dataSource={[
            { label: '待发药', value: 'NOT_DISPENSE' },
            { label: '已发药', value: 'FINISH_DISPENSE' },
            { label: '已退药', value: 'REFUND' },
          ]}
          fieldProps={{}}
          render={(dom) => <>发药状态：{dom}</>}
        />

        <VSTableColumn
          width={190}
          dataIndex={['orderDepartmentName']}
          // title="开单科室"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'order_department_name长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom) => <>开单科室：{dom}</>}
        />

        <VSTableColumn
          width={160}
          dataIndex={['orderDoctorName']}
          // title="开单医生"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'order_doctor_name长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom) => <>开单医生：{dom}</>}
        />

        <VSTableColumn
          width={230}
          dataIndex={['diagnosisName']}
          // title="诊断"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'diagnosis_name长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom) => <div style={{ color: '#E34A4E' }}>诊断：{dom}</div>}
        />

        <VSTableColumn
          dataIndex={['dispenseDetail']}
          // title="发药明细"
          editable={false}
          valueType="listBox"
          fieldProps={{ children: <DateRangePicker /> }}
        />
      </VSPromiseTable>

      {/* <VSForm vsid="05978" id="formRefundReason">
        <VSFormLayout key="0" columnCount={1}>
          <VSFormItem
            name={['refundReason']}
            label="退药原因"
            valueType="text"
            rules={[
              {
                message: 'refund_reason长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
      </VSForm> */}
      {/* <Todo type="block" content="患者信息和处方列表由同一个接口返回" />

      <Button
        children="保存"
        onClick={async (params) => {
          const param = (vsf.refs.tablePrescList.getValues() ?? [])
            .filter((m) =>
              (m.dispenseDetail ?? []).some((o) => o.refundAmount > 0),
            )
            .map((m) => {
              m.dispenseDetail = m.dispenseDetail.filter(
                (o) => o.refundAmount > 0,
              );
              m.refundReason =
                vsf.refs?.formRefundReason?.getFieldFormatValue('refundReason');
              return m;
            });
          const res =
            await vsf.services?.DrugPrescriptionRefundDispenseController_applyRefund_775a7b?.(
              {
                // TODO: 获取已填退药数量的处方单
                applys: param,
              },
            );
          return res?.data;
        }}
      /> */}
    </>
  );
};
export default definePage(OutpDispenseRefundApplyTable);
