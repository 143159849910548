import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientJourneyController_analysis_61065d: {
    method: 'post',
    url: '/api/clinic-record-order/analysis',
    parameterFomatter: (data?: {
      qto: OrderLabResultByLabIdReportItemCodeQtoClinicReportPersistQto;
      patientId: number;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientOrderLabResultVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
});
