import vsf from '@vs/vsf-boot';
export const setMaxHeight = (nodeId, subtract = 0) => {
  const element: any = document?.getElementById(nodeId);
  return `${element?.offsetHeight - Number(subtract)}px`;
};

export const getPromiseHeight = (nodeId) => {
  return new Promise((resolve, reject) => {
    let element;
    const timer = setInterval(() => {
      if (!element) {
        element = document?.getElementById(nodeId);
        return;
      }
      resolve(element?.offsetHeight);
      clearInterval(timer);
    });
  });
};

export const getIdentification = (params) => {
  if (!params) return false;
  const { identificationClassIn, identificationNumberIn } = params;
  if (!identificationClassIn || !identificationNumberIn) return false;
  switch (identificationClassIn) {
    case '患者ID':
      return { displayIdIs: identificationNumberIn };
    // case '身份证':
    //   return { idNumberIs: identificationNumberIn };
    // case '智慧医保':
    //   return { insuranceNumberIs: identificationNumberIn };
    default:
      return {
        identificationClassIn: [identificationClassIn],
        identificationNumberIn: [identificationNumberIn],
      };
  }
};
export const getEnumsLabel = (enumCode, type) => {
  return vsf.stores.dbenums?.enums?.[enumCode]?.find(({ code }) => type == code)
    ?.cname;
};
export const isEmpty = (value) =>
  ['', NaN, undefined, null, false].includes(value);
