import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTable,
  VSFormItem,
  VSForm,
  VSFormLayout,
  VSTableToolbar,
  VSTableColumn,
  VSTableAddition,
  Table,
  Row,
  Col,
  Divider,
  Form,
  InputNumber,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useMount, useRequest, useUnmount, useUpdateEffect } from 'ahooks';
import ComplexSelect from '@/pages/Index/components/complex_select';
import Loading from '@/module/Loading';
import DrugModal from './DrugModal';
import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import {
  askFixed,
  askIsInteger,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import dayjs from 'dayjs';
import { add } from '@/utils/setDataUtil';
import { isObject } from 'lodash';
import { isMinScreen } from '@/config';
import { getScrollYRem, labelNumberWidth } from '@/utils';

const Selected = ({ recode, onChange, form, value }) => {
  const [dataSource, setDataSource] = useState([]);
  let time = null;
  const { loading, run: getDataSource } = useRequest(
    async (name) => {
      const res =
        await vsf.services?.DrugController_getDrugListForImportStorageDetail_e9c707(
          {
            name,
          },
        );

      setDataSource(
        res.data?.map((item, index) => {
          return { key: index, ...item };
        }),
      );
    },
    {
      manual: true,
      debounceWait: 500,
    },
  );
  const columns = [
    {
      title: '药品名称',
      dataIndex: ['drugProductName'],
      key: 'product_name',
    },
    {
      title: '包装规格',
      dataIndex: ['packageSpecification'],
      key: 'package_specification',
    },
    {
      title: '生产厂家',
      dataIndex: ['firmName'],
      key: 'firm_name',
    },
  ];
  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="productName"
      value={value?.label ?? value}
      onChange={(value) => {
        // vsf?.refs?.ImportStashForm?.setFieldValue('cost', value?.retailPrice);
        form.setFieldValue(recode?.recordKey, {
          ...value,
          discount: 100,
          purchasePrice: value?.retailPrice * (100 / 100),
          batchNumber: value?.qualificationCode,
          drugProduct: { drugFirm: { firmName: value?.firmName } },
          amount: null,
          amountPerPackage: value?.amountPerPackage,
          purchaseAmount: askFixed(0, 2),
          // askFixed(
          //   value?.retailPrice * (100 / 100) * value?.amountPerPackage,
          //   2,
          // ),
        });
        onChange({
          label: value.drugProductName,
          value: value.key,
        });
      }}
      onSearch={(name) => {
        clearTimeout(time);
        if (name.trim()) {
          time = setTimeout(() => {
            getDataSource(name);
          }, 500);
        }
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: '46.66rem',
      }}
      listHeight={1000}
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={columns}
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={24}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        scroll={{
          y: '26.66rem',
        }}
        dataSource={dataSource}
      />
    </ComplexSelect>
  );
};

const DrugStockBatchNumber = ({ recode, onChange, form, value }) => {
  const [dataSource, setDataSource] = useState([]);
  const { drugProductId, packageSpecification } = recode?.record;
  const key = Object?.keys(form?.getFieldsValue())?.at(-1);
  // useUnmount(() => {
  //   setDataSource([]);
  // });
  const { run } = useRequest(
    async (params) => {
      const res =
        await vsf.services?.DrugStorageController_getDrugStockBatchNumberOfDrugProduct_8303b8?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            eo: params,
          },
        ); // TODO: 你可能需要整理返回数据格式

      setDataSource(
        res?.data.map((item, index) => {
          return { ...item, key: index };
        }),
      );
      return res?.data.map((item, index) => {
        return { ...item, key: index };
      });
    },
    {
      manual: true,
    },
  );
  const columns = [
    {
      title: '批号',
      dataIndex: ['batchNumber'],
      key: 'batch_number',
    },
    {
      title: '有效期',
      dataIndex: ['expirationDate'],
      key: 'expiration_date',
    },
  ];
  const ref = useRef(null);

  useEffect(() => {
    if (drugProductId && packageSpecification) {
      run({ packageSpecification, drugProductId });
    }
  }, [packageSpecification, drugProductId]);

  // useUpdateEffect(() => {packageSpecification, drugProductId
  //   ref.current?.focus();
  // }, [recode?.record?.drugProduct?.drugProductName]);

  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="batchNumber"
      value={value}
      onChange={(value) => {
        onChange(value.key);
        form.setFieldValue(key, {
          ...form?.getFieldValue(key),
          expirationDate: value?.expirationDate,
          batchNumber: value?.batchNumber,
        });

        // const data = vsf?.refs?.InventoryListOfDrugsTable?.getValues();
        // setDataSource([]);
      }}
      onSearch={(inputCode, filterOption) => {
        if (inputCode) {
          setDataSource([{ batchNumber: inputCode, key: 1 }]);
        } else {
          setDataSource([]);
        }
      }}
      ref={ref}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: '46.66rem',
      }}
      listHeight={1000}
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={columns}
        scroll={{
          y: '26.66rem',
        }}
        dataSource={dataSource}
      />
    </ComplexSelect>
  );
};

const InputNumberRender = (props) => {
  const { onChange, recode, form, value, schema, ...ret } = props;
  const ref = useRef(null);
  useUpdateEffect(() => {
    ref?.current?.focus();
  }, [recode?.record?.drugProduct?.drugProductName]);
  return (
    <InputNumber
      width="100%"
      value={value}
      onChange={(value) => {
        /**
         * 计算总价
         */
        onChange(value);
        schema?.originProps?.onChange(value);
      }}
      ref={ref}
    />
  );
};

const ImportEdit = (props) => {
  const { selectTableParam } = props;
  const { data: subwarehouseData } = useRequest(async () => {
    const res =
      await vsf.services?.DrugStorageController_getSubStorageList_cb7e95?.({});
    // vsf?.refs?.ImportStashForm.setFieldValue('subStorageId', res?.data?.[0]);
    return res?.data;
  });

  // const { data: pharmacySelectData } = useRequest(async () => {
  //   const res =
  //     await vsf?.services?.DrugImportRecordCreateController_getCandidateImportExportClzList_6a4edf?.(
  //       {
  //         isImport: true,
  //       },
  //     );
  //   return res.data;
  // });
  const { data: pharmacySelectData } = useRequest(async () => {
    const res =
      await vsf?.services?.DrugImportRecordCreateController_getCandidateImportExportClzList_6a4edf?.(
        {
          isImport: true,
        },
      );
    return res.data;
  });

  const { data: getCandidateStorageList, run } = useRequest(
    async () => {
      if (!vsf?.refs?.ImportStashForm.getFieldValue('exportImportCode'))
        return [];
      const clzCode = isObject(
        vsf?.refs?.ImportStashForm.getFieldValue('exportImportCode'),
      )
        ? vsf?.refs?.ImportStashForm.getFieldValue('exportImportCode')?.code
        : vsf?.refs?.ImportStashForm.getFieldValue('exportImportCode');
      const res =
        await vsf?.services?.DrugImportRecordCreateController_getCandidateStorageList_1ef4f5?.(
          {
            clzCode,
          },
        );
      return res.data;
    },
    { manual: true },
  );

  // 西药库 - 待记账单据、药品出库单(双击)
  const { run: importTableDataRequest } = useRequest(
    async (param) => {
      if (selectTableParam) {
        const res =
          await vsf.services?.DrugStorageImportExportController_getExportMasterWithDetailById_c96935(
            { id: param },
          );
        const data = {
          ...res?.data,
          subStorageId: res?.data?.subStorage?.id,
          supplierId: res?.data?.supplierId, //
          drugImportDetailWithProductDtoList: res?.data
            .drugImportDetailWithProductDtoList
            ? res?.data?.drugImportDetailWithProductDtoList
                ?.map((ele, index) => {
                  let obj = {
                    ...ele,
                    index,
                    // drugProductId: ele?.drugPriceItemId ?? ele?.drugProduct?.id,
                    drugProductId: ele?.drugProductId ?? ele?.drugProduct?.id,
                  };
                  return obj;
                })
                ?.sort((a, b) => b?.index - a?.index)
            : res?.data?.drugExportDetailWithProductFirmDtoList,
        };
        const stopwatch = vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT;
        stopwatch.map((item) => {
          if (item?.code == data?.exportImportCode) {
            data.exportImportCode = JSON.parse(
              item?.extensionJson,
            )?.importVsExportCode;
            data.supplierId = data?.subDrugStorage?.subStorageName;
          }
        });
        vsf?.refs?.ImportStashForm?.setFieldsValue(data);
        console.log(subwarehouseData, 'subwarehouseData');

        subwarehouseData.map((item) => {
          console.log(item, 'item');
          if (data.receiveDepartment == item.drugStorageId) {
            vsf?.refs?.ImportStashForm.setFieldValue('subStorageId', item);
          }
        });
        run();
        return res?.data?.drugImportDetailWithProductDtoList;
      }
    },
    { manual: true },
  );

  //药品出库单详情
  const { run: outportTableDataRequest } = useRequest(
    async (param) => {
      if (selectTableParam) {
        const res =
          await vsf.services?.DrugStorageImportExportController_getDrugExportDetailListByDrugExportMasterId_30f5f3(
            { drugExportMasterId: param },
          );
        const dataSource = res?.data?.map((item) => {
          return {
            ...item,
            discount: 100,
            drugProductId: item?.drugProductId ?? item?.drugProduct?.id,
          };
        });
        vsf?.refs?.ImportStashForm?.setFieldsValue({
          drugImportDetailWithProductDtoList: dataSource,
          discount: 100,
          // id: param,
        });

        return res?.data?.drugImportDetailWithProductDtoList;
      }
    },
    { manual: true },
  );
  //药品出库单Form详情
  const { data: convertData, run: outportFormDetail } = useRequest(
    async (param) => {
      if (selectTableParam) {
        const res =
          await vsf?.services?.DrugStorageImportExportController_convertToDrugImportInfo_22cd28?.(
            { exportMasterId: param },
          );
        vsf?.refs?.ImportStashForm?.setFieldsValue({
          ...res?.data,
          subStorageId: res?.data?.subStorage?.id,
          supplierId: res?.data?.supplier?.name || res?.data?.supplierId,
        });

        return res?.data;
      }
    },
    { manual: true },
  );

  const { run: getBatchNumberList } = useRequest(
    async (params) => {
      //
      // Tips: 以下是你选择的请求函数
      const res =
        await vsf.services?.DrugStorageController_getDrugStockBatchNumberOfDrugProduct_8303b8?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            eo: params,
          },
        ); // TODO: 你可能需要整理返回数据格式
      setDataSource(res?.data);
      return res?.data;
    },
    {
      manual: true,
    },
  );

  //新增删除按钮是否可见
  const [addAndDeleteShow, setAddAndDeleteShow] = useState(true);
  //编辑按钮是否可见
  const [count, seCount] = useState([]);
  //新增状态
  const [addBtnStatus, setAddBtnStatus] = useState(false);

  const [code, setCode] = useState();

  useMount(() => {
    vsf?.refs?.ImportStashForm?.setFieldsValue({
      documentNumber: new Date()
        .toLocaleString()
        .replaceAll('/', '')
        .replaceAll(':', '')
        .replace(/\s/, ''),
    });
  });

  useEffect(() => {
    run();
  }, [code]);

  useEffect(() => {
    if (selectTableParam) {
      if (selectTableParam?.drugProvideNoticeId) {
        outportTableDataRequest(selectTableParam?.id);
        outportFormDetail(selectTableParam?.id);
      } else {
        importTableDataRequest(
          selectTableParam?.id || selectTableParam?.drugProvideNoticeId,
        );
      }
    }
  }, [selectTableParam]);
  //TODO
  const validateFields = (e) => {
    // setFormStatus(true);
    // vsf?.refs?.ImportStashForm?.validateFields()
    //   .then((val) => {
    //   })
    //   .catch((err) => {
    //     message.error('入库类型不能为空');
    //   });
  };
  // 应付款
  const setCostTotal = () => {
    const ImportStashForm = vsf?.refs?.ImportStashForm;
    const InventoryListOfDrugsTable = vsf?.refs?.InventoryListOfDrugsTable;
    const Total = InventoryListOfDrugsTable?.getValues()?.reduce(
      (current, element) => {
        return askFixed(
          add(Number(current || 0), Number(element?.purchaseAmount || 0), 2),
          2,
        );
      },
      Number(ImportStashForm?.getFieldValue?.('additionalCost') || 0),
    );
    ImportStashForm?.setFieldValue('cost', Total);
  };

  return (
    <VSForm
      vsid="16990"
      labelAlign="left"
      id="ImportStashForm"
      layout="horizontal"
      onChange={(val) => {
        setAddAndDeleteShow(true);
      }}
      onFieldsChange={(value) => {
        if (value[0].name[0] == 'exportImportCode') {
          const vs = vsf?.refs?.ImportStashForm?.getFieldsValue();
          vs.supplierId = undefined;
          vsf?.refs?.ImportStashForm?.setFieldsValue({ ...vs });
        }
      }}
    >
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="digit"
        fieldProps={{}}
      />
      <VSFormLayout key="3" columnCount={4} labelWidth="6rem" gutter={70}>
        {/* 入库类型 */}
        <VSFormItem
          name={['exportImportCode']}
          label="入库类型"
          valueType="select"
          dataSource={pharmacySelectData}
          // dataSource={() => {

          // }}
          fieldProps={{
            onChange: (value) => {
              setCode(value?.code);
            },
            fieldNames: { label: 'name', value: 'code' },
            disabled: selectTableParam,
          }}
          rules={[
            {
              required: true,
              message: '请选择入库类型',
            },
          ]}
        />

        {/* 入库日期 */}
        <VSFormItem
          name={['importDate']}
          label="入库日期"
          valueType="date"
          initialValue={dayjs().valueOf()}
          fieldProps={{
            width: '100%',
            disabled: selectTableParam,
          }}
          rules={[
            {
              required: true,
              message: '请选择入库日期',
            },
          ]}
        />

        {/* 供货方 */}
        <VSFormItem
          name={['supplierId']}
          label="供货方"
          valueType="select"
          dataSource={getCandidateStorageList}
          fieldProps={{
            disabled: selectTableParam,
            fieldNames: { label: 'name', value: 'id' },
          }}
          rules={[
            {
              required: true,
              message: '请选择供货方',
            },
          ]}
        />

        {/* 应付款 */}
        <VSFormItem
          name={['cost']}
          label="应付款"
          valueType="digit"
          fieldProps={{
            width: '100%',
            addonAfter: '元',
            disabled: selectTableParam,
            disabled: true,
          }}
        />
      </VSFormLayout>

      <VSFormLayout key="4" columnCount={4} labelWidth="6rem" gutter={70}>
        <VSFormItem
          name={['additionalCost']}
          label="附加费"
          valueType="digit"
          fieldProps={{
            width: '100%',
            addonAfter: '元',
            onChange: (v) => setCostTotal(),
          }}
        />

        <VSFormItem
          name={['subStorageId']}
          label="存放库房"
          valueType="select"
          dataSource={subwarehouseData}
          fieldProps={{
            fieldNames: { label: 'subStorageName', value: 'id' },
            // disabled: selectTableParam,
          }}
          rules={[
            {
              required: true,
              message: '请选择存放库房',
            },
          ]}
        />

        <VSFormItem
          name={['memo']}
          label="备注"
          valueType="text"
          rules={[{ message: '数据格式错误', type: 'string', min: 0, max: 40 }]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['documentNumber']}
          label="流水号"
          valueType="text"
          rules={[
            {
              required: true,
              message: '数据格式错误',
              type: 'string',
              min: 0,
              max: 20,
            },
          ]}
          fieldProps={{
            disabled: true,
          }}
        />
      </VSFormLayout>

      <Divider />

      <VSFormLayout key="5" columnCount={1}>
        <VSFormItem
          name={['drugImportDetailWithProductDtoList']}
          valueType="custom"
          fieldProps={{
            disabled: false,
          }}
        >
          <VSTable
            editable={{
              editType: 'multiple',
              onCanDelete: () => null,
              extraOperations: [
                {
                  children: !selectTableParam?.drugProvideNoticeId && (
                    <Icon type="icon-shanchu" className="icon24" />
                  ),
                  onClick: async (_, data) => {
                    const name = data?.drugProduct?.drugProductName;
                    if (String(data?.id)?.startsWith('create')) {
                      await vsf?.refs?.InventoryListOfDrugsTable?.remove(data);
                    } else {
                      Tip({
                        content: `确定删除药品:{ ${name?.label ?? name} }?`,
                        onOk: async () => {
                          await vsf?.refs?.InventoryListOfDrugsTable?.remove(
                            data,
                          );
                        },
                      });
                    }

                    setCostTotal();
                  },
                },
              ],
              onFieldChange: async (key, value, record, form) => {
                if (Object.keys(value)?.includes('amount')) {
                  await form.setFieldValue(
                    [key, 'purchaseAmount'],
                    askTotalAmount(
                      [[record?.purchasePrice, record?.amount]],
                      2,
                    ),
                  );
                  await vsf?.refs?.InventoryListOfDrugsTable?.update?.({
                    ...vsf?.refs?.InventoryListOfDrugsTable?.getValues()?.find(
                      (ele) => ele?.id == key,
                    ),
                    purchaseAmount: askTotalAmount(
                      [[record?.purchasePrice, record?.amount]],
                      2,
                    ),
                  });
                  setCostTotal();
                }
              },
              columnProps: {
                width: '4.66rem',
              },
            }}
            vsid="11347"
            id="InventoryListOfDrugsTable"
            onRecord={() => ({ id: 'create_' + Math.random() })}
            rowClassName="aspirin-vs-table-row-box"
            className="aspirin-table aspirin-table-search-padding-no"
            // scroll={{ y: isMinScreen ? 320 : 440 }}
            scroll={{ y: getScrollYRem(36.8) }}
          >
            <VSTableColumn
              width="4rem"
              dataIndex={['itemNumber']}
              title="序号"
              editable={false}
              valueType="index"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['drugProduct', 'drugProductName']}
              title=" 药品名称"
              valueType={
                selectTableParam?.drugProvideNoticeId ? 'text' : 'text'
              }
              formItemProps={{
                rules: [
                  {
                    // type: 'number',
                    required: true,
                    message: '请选择药品名称',
                    // validator: (_, value) => askIsInteger(value),
                  },
                ],
              }}
              preview
              renderFormItem={(...arr) => {
                const [schema, recode, form] = arr;
                if (selectTableParam?.drugProvideNoticeId) {
                  return recode?.record?.drugProduct?.drugProductName;
                } else {
                  return (
                    <Selected
                      recode={recode}
                      form={form}
                      onChange={schema.originProps.onChange}
                    />
                  );
                }
              }}
            />

            <VSTableColumn
              dataIndex={['drugProductId']}
              title="主键"
              hideInTable={true}
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['drugSpecification']}
              title="最小规格"
              valueType="text"
              ellipsis
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 50 },
                ],
              }}
              fieldProps={{
                disabled: true,
              }}
              renderFormItem={(...[, recode]) =>
                recode.record.drugSpecification
              }
            />

            <VSTableColumn
              dataIndex={['packageSpecification']}
              title="入库规格"
              valueType="text"
              ellipsis
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 50 },
                ],
              }}
              fieldProps={{
                disabled: true,
              }}
              renderFormItem={(...[, recode]) =>
                recode.record.packageSpecification
              }
            />

            <VSTableColumn
              dataIndex={['packageUnit']}
              title="入库单位"
              valueType="select"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 8 },
                ],
              }}
              dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['unit']}
              title="	入库药品的商品层最小单位"
              hideInTable={true}
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 8 },
                ],
              }}
              fieldProps={{
                disabled: selectTableParam?.drugProvideNoticeId,
              }}
            />

            <VSTableColumn
              dataIndex={['drugProduct', 'drugFirm', 'firmName']}
              title="厂家"
              valueType="text"
              fieldProps={{
                disabled: true,
              }}
              preview
              ellipsis
              width={labelNumberWidth(17)}
              // renderFormItem={(...[, recode]) => recode.record.firmName}
              render={(dom, recoad) => {
                return recoad?.firm?.firmName;
              }}
            />

            <VSTableColumn
              dataIndex={['purchasePrice']}
              title="采购价"
              valueType="digit"
              fieldProps={{
                disabled: selectTableParam?.drugProvideNoticeId,
              }}
              preview
            />

            <VSTableColumn
              dataIndex={['retailPrice']}
              title="零售价"
              valueType="digit"
              fieldProps={{
                disabled: true,
              }}
              renderFormItem={(...[, recode]) => recode.record.retailPrice}
            />

            <VSTableColumn
              dataIndex={['discount']}
              title="折扣"
              valueType="digit"
              fieldProps={{
                disabled: true,
              }}
            />

            <VSTableColumn
              dataIndex={['amount']}
              title="数量"
              valueType="digit"
              fieldProps={{}}
              formItemProps={{
                rules: [
                  {
                    type: 'number',
                    required: true,
                    validator: (_, value) => askIsInteger(value),
                  },
                ],
              }}
              renderFormItem={(...[schema, recode, form]) => {
                if (selectTableParam?.drugProvideNoticeId) {
                  return recode?.record?.amount;
                } else {
                  return <InputNumberRender {...{ form, schema, recode }} />;
                }
              }}
            />

            <VSTableColumn
              dataIndex={['batchNumber']}
              title="批号"
              valueType="select"
              // rules={[{ required: true }]}
              formItemProps={{
                rules: [
                  {
                    // type: 'number',
                    required: true,
                    message: '请输入批号',
                    // validator: (_, value) => askIsInteger(value),
                  },
                ],
              }}
              renderFormItem={(...arr) => {
                const [schema, recode, form] = arr;
                if (selectTableParam?.drugProvideNoticeId) {
                  return recode?.record?.batchNumber;
                } else {
                  return (
                    <DrugStockBatchNumber
                      recode={recode}
                      form={form}
                      onChange={schema.originProps.onChange}
                    />
                  );
                }
              }}
            />

            <VSTableColumn
              dataIndex={['batchNumber1']}
              title="批号"
              valueType="text"
              hideInTable
              formItemProps={{}}
            />

            <VSTableColumn
              dataIndex={['expirationDate']}
              title="有效期"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                disabled: selectTableParam?.drugProvideNoticeId,
              }}
              formItemProps={{
                rules: [{ required: true }],
              }}
            />

            <VSTableColumn
              dataIndex={['invoiceDate']}
              title="发票日期"
              valueType="date"
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                disabled: selectTableParam?.drugProvideNoticeId,
              }}
            />

            <VSTableColumn
              dataIndex={['invoiceNumber']}
              title="发票号"
              valueType="text"
              formItemProps={{
                rules: [
                  { message: '数据格式错误', type: 'string', min: 0, max: 10 },
                ],
              }}
              fieldProps={{
                disabled: selectTableParam?.drugProvideNoticeId,
              }}
            />

            <VSTableColumn
              dataIndex={[
                'purchaseAmount',
                // 'retailAmount',
              ]}
              title="金额"
              valueType="digit"
              fieldProps={{
                disabled: true,
              }}
              renderFormItem={(...[, recode]) => recode.record.purchaseAmount}
            />
            <VSTableToolbar title="药品列表">
              {addAndDeleteShow && (
                <VSTableAddition
                  children="添加"
                  onClick={validateFields}
                  disabled={
                    addBtnStatus || selectTableParam?.drugProvideNoticeId
                  }
                />
                // <Button type='primary' onClick={onAddAndEdit}>添加</Button>
              )}
            </VSTableToolbar>
          </VSTable>
        </VSFormItem>
        <DrugModal></DrugModal>
      </VSFormLayout>
    </VSForm>
  );
};

export default definePage(ImportEdit);
