import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpVisitController_getTransferPatientInfoVoByInpVisitId_13efc4: {
    method: 'post',
    url: '/api/clinic-inp-visit/get-transfer-patient-info-vo-by-inp-visit-id',
    parameterFomatter: (data?: { inpVisitId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TransferPatientInfoVoClinicInpVisitEntranceWebVo,
    ) => data,
  },
  ClinicInpAdtController_transferPatientIn_b929c3: {
    method: 'post',
    url: '/api/clinic-inp-adt/transfer-patient-in',
    parameterFomatter: (data?: {
      saveInpVisitForTransferBto: SaveInpVisitForTransferBtoClinicInpVisitServiceBto;
      transferPatientInParameterEo: TransferPatientInParameterEoClinicInpAdtPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
});
