import vsf, { definePage } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';
import './index.less';
import UserInfo from './UserInfo';
import TableMenu from './TableMenu';
import { useRequest } from 'ahooks';
const InpMedicalTechnologyCharge = (props) => {
  const { user } = props?.stores || {},
    { staffInfo } = user || {};
  // 医生
  const { data: operationDoctorData } = useRequest(async (params) => {
    const res =
      await vsf.services?.UserStaffController_queryDoctorListBySearchCode_d60b63?.(
        {
          nameLike: params?.nameLike ?? '',
        },
      );
    if (res?.data?.result?.findIndex((ele) => ele?.id == staffInfo?.id) == -1)
      res?.data?.result?.push({
        id: staffInfo?.id,
        staffName: staffInfo?.staffName,
      });
    return res?.data?.result?.map?.((ele) => {
      return {
        staffId: ele?.id,
        staffName: ele?.staffName,
      };
    });
  });
  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState(),
    [inpVisitPatient, setInpVisitPatient] = useState({}),
    resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);
  return (
    <div className="InpMedicalTechnologyCharge-container">
      <div className="container">
        <UserInfo {...{ setInpVisitPatient }} />
        <TableMenu {...{ inpVisitPatient, operationDoctorData }} />
      </div>
    </div>
  );
};
export default definePage(InpMedicalTechnologyCharge);
