import { createServices } from '@vs/vsf-boot';
export default createServices({
  CdssController_cdssAllergy_486d60: {
    method: 'post',
    url: '/api/external-cdss/cdss-allergy',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssCancelVisits_ba8b7c: {
    method: 'post',
    url: '/api/external-cdss/cdss-cancel-visits',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssLogin_a9c7a3: {
    method: 'post',
    url: '/api/external-cdss/cdss-login',
    parameterFomatter: (data?: {
      loginCdssParamEo: LoginCdssParamEoExternalCdssPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssOrderOperate_6f218b: {
    method: 'post',
    url: '/api/external-cdss/cdss-order-operate',
    parameterFomatter: (data?: {
      outpVisitId: number;
      inpVisitId: number;
      operateBlockedOrderEo: OperateBlockedOrderEoExternalCdssPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssOrderResponse_bf4f9f: {
    method: 'post',
    url: '/api/external-cdss/cdss-order-response',
    parameterFomatter: (data?: {
      outpVisitId: number;
      inpVisitId: number;
      saveOrderEoList: SaveOrderEoClinicRecordOrderPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: FilterOrderVoExternalCdssEntranceWebVo) =>
      data,
  },
  CdssController_cdssVisit_3de904: {
    method: 'post',
    url: '/api/external-cdss/cdss-visit',
    parameterFomatter: (data?: { patientId: number; outpVisitId: number }) =>
      data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssVitalSign_7e85ec: {
    method: 'post',
    url: '/api/external-cdss/cdss-vital-sign',
    parameterFomatter: (data?: { patientId: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssOpenTask_2ba6c9: {
    method: 'post',
    url: '/api/external-cdss/cdss-open-task',
    parameterFomatter: (data?: {
      patientId: number;
      outpVisitId: number;
      inpVisitId: number;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  CdssController_cdssFocus_f9f934: {
    method: 'post',
    url: '/api/external-cdss/cdss-focus',
    parameterFomatter: (data?: {
      patientId: number;
      saveOrderEo: SaveOrderEoClinicRecordOrderPersistEo;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
});
