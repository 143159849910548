import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  Image,
  message,
  Popover,
  Section,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import RightContextMenu from '@/pages/Index/components/right_click_menu';
import {
  convertGet,
  markData,
  occupyBedRightContextMenuData,
  patientDetailConditionIcon,
  renderDrugResistance,
} from '@/pages/Index/manage/bed_card/convert';
import { getScrollY } from '@/utils';
import { handleSex } from '@/utils/index';
import storage from '@/utils/storage';

// 床卡拖拽
const DraggableComponent = ({ id, index, changePosition, renderItem }: any) => {
  const ref = useRef<HTMLTableRowElement>(null);
  // 因为没有定义收集函数，所以返回值数组第一项不要
  const [, drop] = useDrop({
    accept: 'DragDropBox',
    hover: (item: any, monitor) => {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      // if (dragIndex === hoverIndex) return; // 如果回到自己的坑，那就什么都不做
      // changePosition(dragIndex, hoverIndex); // 调用传入的方法完成交换
      item.index = hoverIndex; // 将当前当前移动到Box的index赋值给当前拖动的box，不然会出现两个盒子疯狂抖动！
    },
    drop: (item, monitor) => {},
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'DragDropBox',
    item: { index },
    end: (_index) => {
      if (index == _index?.index || !_index) return; // 如果回到自己的坑或者没有放到指定元素，那就什么都不做

      changePosition(index, _index?.index);
    },
    isDragging: (monitor) => {
      // console.log(monitor.getItem().index);
      return index === monitor.getItem().index;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <div
      ref={drag(drop(ref)) as any} // 这样写可以让它即接收拖拽又实现拖拽
      style={{ opacity: isDragging ? 0.5 : 1, height: '100%' }}
    >
      {renderItem}
    </div>
  );
};

// 性别图标
export const handelSexIcon = (data = '') => {
  if (data == '0') {
    // 未知的性别
    return <Icon className="icon18" type="icon-weizhidexingbie" />;
  } else if (data == '9') {
    // 性别未说明
    return <Icon className="icon18" type="icon-weishuomingxingbie" />;
  } else if (data == '1') {
    // 男
    return <Icon className="icon18" type="icon-nan" />;
  } else if (data == '2') {
    // 女
    return <Icon className="icon18" type="icon-nv" />;
  } else {
    return '';
  }
};

const Index = (props, refs) => {
  const {
    type,
    currentSelectCarBed,
    wardGroup,
    handelRefreshCard,
    bedCardChange,
    radioGroupValue,
    handelViewDetail,
    handelAllergyDetail,
    currentSelectPatientInfoInpVisitId,
    isDisable,
    currentApplication,
    setCurrentOrderPatient,
  } = props;

  // const { currentApplication } = common || {};
  const [cardList, setCardList] = useState<any>([]);
  const [currentSimple, setCurrentSimple] = useState<any>();
  const [isShowTag, setIsShowTag] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  const ref = useRef<HTMLTableRowElement>(null);
  // const currentApplication = storage.get('currentApplication');

  useEffect(() => {
    getCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectCarBed, wardGroup, radioGroupValue, currentApplication]);
  // 详卡列表

  const getCardList = async () => {
    setLoading(true);
    if (type === 'list') return setLoading(false);

    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 1000,
            // 统计选择全科需要传当前病区病区-写死
            // wardIdIs: currentApplication?.department?.id,
            wardIdIs:
              currentApplication?.department?.id ??
              storage.get('currentApplication')?.department?.id,

            //护理组
            bedGroupIdIs: wardGroup,
          },
          ext: { statisticsCode: currentSelectCarBed },
        },
      ); // TODO: 你需要自己补充入参input来源及出参output用法
    setLoading(false);
    const customResult = convertGet(res); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    const _customResult = customResult?.map((item) => {
      return {
        ...item,
        isShowDetail: false,
      };
    });

    setCardList(_customResult);
    return {
      data: _customResult ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  };

  // 码表转换
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    return _res?.[0]?.label;
  };
  // 计算时间
  const handelTime = (time) => {
    const currentTime = new Date();
    return dayjs(currentTime).diff(time ?? currentTime, 'day');
  };
  //床号字体颜色判断
  const handelbedNum = (item) => {
    // console.log(item, '-====================');

    const getTime = (value) => {
      return +new Date(value);
    };
    if (item?.preTransferIndicator) {
      // 待转科
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num1',
        label: '待转科',
      };
    } else if (
      // 当天新入科
      !item?.preTransferIndicator &&
      getTime(item?.admissionWardDateTime) >
        getTime(item?.expectedDischargeDate) &&
      dayjs(item?.admissionWardDateTime).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num2',
        label: '新入科',
      };
    } else if (
      // 今日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().format('YYYY-MM-DD 00:00:00'),
        dayjs().format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num4',
        label: '今日出院',
      };
    } else if (
      // 明日出院
      dayjs(item?.expectedDischargeDate).isBetween(
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 00:00:00'),
        dayjs().add(1, 'day')?.format('YYYY-MM-DD 23:59:59'),
      )
    ) {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num3',
        label: '明日出院',
      };
    } else {
      return {
        class: 'my_bed_card_list_detail_item_content_body_item1_tag_text_num5',
        label: '',
      };
    }
  };
  // 护理等级颜色
  const handelNursingClass = (value) => {
    if (value == 0) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_teji',
        value: '护理等级：特级',
      };
    } else if (value == 1) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_yiji',
        value: '护理等级：一级',
      };
    } else if (value == 2) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_erji',
        value: '护理等级：二级',
      };
    } else if (value == 3) {
      return {
        class: 'my_bed_card_list_detail_item_content_topbgc_sanji',
        value: '护理等级：三级',
      };
    } else {
      return {};
    }
  };
  // 右键禁用
  const handelDisabled = (detail?) => {
    // console.log(detail, '7412_简卡');

    const res = occupyBedRightContextMenuData?.map((item) => {
      // console.log(detail, '====');
      // 入科：只有空床可点击 0
      if (item?.value === 'ruke') {
        return {
          ...item,
          isDisabled: detail?.bedStatus == '0' ? false : true,
        };
      } else if (item?.value === 'zhuanke') {
        return {
          ...item,
          // isDisabled: isDisable || !currentSelectPatientInfoInpVisitId,
          isDisabled: detail && !detail?.preTransferIndicator,
        };
        // disabled={isDisable || !currentSelectPatientInfoInpVisitId}
      } else if (item?.value === 'outHospital') {
        // console.log(
        //   detail && !detail?.dischargeWay,
        //   'detail && !detail?.ischargeWaydetail && !detail?.dischargeWay',
        // );

        return {
          ...item,
          isDisabled: detail && !detail?.dischargeWay,
        };
        // disabled={isDisable || !currentSelectPatientInfoInpVisitId}
      }
      // 占床固定显示，空床、停用不显示：患者详情、医嘱核对、医嘱执行单、单病人管理、护理文书、日费用审核管理、费用查询、人脸认证
      else if (
        item.value === 'detail' ||
        item.value === 'orderCheck' ||
        item.value === 'orderExecute' ||
        item.value === 'singlePatientManagement' ||
        item.value === 'nursingDocuments' ||
        item.value === 'costAuditManagement' ||
        item.value === 'costQuery' ||
        item.value === 'faceAuthentication'
      ) {
        return {
          ...item,
          isDisabled: detail?.bedStatus == '1' ? false : true,
        };
      } else {
        return { ...item };
      }
    });
    // console.log(res, 'zhuan');

    return res;
  };
  // 右键点击
  const handelClick = (type, detail) => {
    // 占床
    // 转科、出院、离床、护理路径条件满足可点击
    // 医嘱核对、医嘱执行单、单病人管理、护理文书、患者详情、日费用审核管理、费用查询、人脸认证固定按钮
    if (detail?.bedStatus == 1) {
      if (type.value === 'detail') {
        // 患者详情
        handelViewDetail?.(detail);
      } else if (type.value === 'zhuanke') {
        // 转科
        vsf?.navigateTo(
          `/index/nurse-station-transfer?displayId=${detail?.displayId}`,
        );
      } else if (type.value === 'orderExecute') {
        // 医嘱执行单-->病区执行/标签
        // console.log(detail, 'detaildetail');

        setCurrentOrderPatient({
          patient: { id: detail?.patientId, inpVisitId: detail?.inpVisitId },
        });
        vsf?.navigateTo(
          `/index/cpoe/medical-order-execute?label=${
            detail?.bedLabel + '-' + detail?.patientName
          }&value=${detail?.bedLabel}`,
        );
      } else if (type.value === 'orderCheck') {
        // 医嘱校对
        setCurrentOrderPatient({ patient: { id: detail?.patientId } });
        vsf?.navigateTo(
          `/index/cpoe/order-check?patientId=${detail?.patientId}`,
        );
      } else if (type.value === 'costAuditManagement') {
        // 日费用审核管理
        // console.log(detail);
        vsf?.navigateTo(
          `/index/day-fee-review?inpVisitId=${detail?.inpVisitId}&bedLabel=${
            detail?.bedLabel + detail?.patientName
          }&patientId=${detail?.patientId}&displayId=${detail?.displayId}`,
        );
      } else if (type.value === 'outHospital') {
        // 出院
        vsf?.navigateTo(
          `/index/nurse-station-out-hospital?inpVisitId=${detail?.inpVisitId}`,
        );
      } else if (type.value === 'allergyHistory') {
        // 过敏史
        handelAllergyDetail?.(detail);
      } else {
        message.info('todo');
      }
    }
    // 空床
    else if (detail?.bedStatus == 0) {
      if (type?.value === 'ruke') {
        vsf.navigateTo(
          `/index/nurse-admission?bedId=${detail?.id}&bedLabel=${detail?.bedLabel}床`,
        );
      } else {
        message.info('todo');
      }
    }
  };
  // 床号
  const renderBedNum = (value, item) => {
    return (
      <div
        className={cn(
          'my_bed_card_list_detail_item_content_body_item1_tag_text_num',
          handelbedNum(item)?.class,
        )}
      >
        {value}床
      </div>
    );
  };
  // 耐药图标
  const renderDrugResistance = (value) => {
    if (value === 'M') {
      return 'icon-naijiayangxilinjinhuangseputaoqiujun';
    } else if (value === 'S') {
      return 'icon-youchuanranxingdemeidu';
    } else if (value === 'C') {
      return 'icon-naitanqingmeixichangganjunkexijun';
    } else if (value === 'E') {
      return 'icon-a-chanESBLschaoguangpu-neixiananmeidegelanshiyinxingjun';
    } else if (value === 'A') {
      return 'icon-naitanqingmeixicusuangai-baomanbudongganjun';
    } else if (value === 'V') {
      return 'icon-naigumeisuchangqiujun';
    } else if (value === 'B') {
      return 'icon-a-yigandaxiaosanyang';
    } else if (value === 'P') {
      return 'icon-quannaiyaoxijun';
    } else if (value === 'H') {
      return 'icon-aizi';
    } else if (value === '乙') {
      return 'icon-yigan';
    } else if (value === '丙') {
      return 'icon-binggan';
    } else if (value === 'T') {
      return 'icon-jiehe';
    } else if (value === 'G') {
      return 'icon-yunchanfuBqunlianqiujunyangxing';
    } else {
      return '';
    }
  };
  // icon
  const renderIcon = (value) => {
    return (
      <div>
        {patientDetailConditionIcon?.map((item: any, index) => {
          if (item?.type === 'arr') {
            // 耐药
            if (item?.isShow === 'drugResistanceType') {
              return value?.[item?.content]?.length === 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={renderDrugResistance(value?.[item?.content]?.[0])}
                  />
                </Popover>
              ) : value?.[item?.isShow]?.length > 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else if (item?.isShow === 'patientAllergyIndicator') {
              // console.log(value, item?.content, value?.[item?.content], '==1');

              // 过敏
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' ； ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else {
              // console.log(value, item);
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            }
          } else if (item?.type === 'string') {
            return value?.[item?.isShow] ? (
              <Popover key={item?.isShow} content={item?.content}>
                <Icon
                  className="nurses_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.icon}
                />
              </Popover>
            ) : (
              ''
            );
          }
        })}
      </div>
    );
  };
  // 压/跌/疼/滑/VTE
  const renderType = (value) => {
    return (
      <div className="my_bed_card_list_detail_item_content_body_item2_content_type">
        {markData?.map((item) => {
          return (
            <div
              key={item?.value}
              className={cn(
                'my_bed_card_list_detail_item_content_body_item2_content_type_item',
                item?.className,
              )}
              // style={{ display: value?.[item?.isShow] ? 'block' : 'none' }}
              style={{
                color: value?.[item?.isShow] ? '' : '#85898D',
                border: value?.[item?.isShow] ? '' : '1px solid #85898D',
                background: value?.[item?.isShow] ? '' : '#fff',
              }}
            >
              <Popover key="index" content={value?.[item?.value]}>
                {item?.label}
              </Popover>
            </div>
          );
        })}
      </div>
    );
  };
  // 详卡-占床
  const occupyBed = (item, index) => {
    return (
      <ContextMenuTrigger id={'contextMenu' + index}>
        <div
          className="my_bed_card_list_detail_item_content custom_my_bed_card_list_detail_item_content_height"
          // newDoctorOrderIndicator
        >
          {/* 新开医嘱通知 */}
          {item?.newDoctorOrderIndicator && (
            <Popover content="有未校对新医嘱">
              <div
                className="custom_my_bed_card_aic_view"
                onClick={() => {
                  vsf?.navigateTo(
                    `/index/cpoe/order-check?patientId=${item?.patientId}`,
                  );
                }}
              ></div>
            </Popover>
          )}
          {RightContextMenu({
            detail: item,
            // dataSource: occupyBedRightContextMenuData,
            dataSource: handelDisabled?.(item),
            borderBottom: [3, 5, 8, 10],
            id: `contextMenu${index}`,
            handelClickMenuItem: handelClick,
          })}
          <Popover content={handelNursingClass(item?.nursingClass)?.value}>
            <div
              className={cn(
                handelNursingClass(item?.nursingClass)?.class,
                'my_bed_card_list_detail_item_content_topbgc',
              )}
            ></div>
          </Popover>
          <div className="my_bed_card_list_detail_item_content_body">
            <div className="my_bed_card_list_detail_item_content_body_item1">
              <div className="my_bed_card_list_detail_item_content_body_item1_tag">
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_text">
                  <Popover content={renderBedNum(item?.bedLabel, item)}>
                    <span className="text_ellipsis" style={{ maxWidth: 60 }}>
                      {renderBedNum(item?.bedLabel, item)}
                    </span>
                  </Popover>
                  <Popover content={handelbedNum(item)?.label}>
                    <span style={{ color: '#85898D' }}>
                      {handelbedNum(item)?.label}
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_icon">
                  {renderIcon(item)}
                </div>
              </div>
              {/* <Popover content={handelNursingClass(item?.nursingClass)?.value}> */}
              <div className="my_bed_card_list_detail_item_content_body_item1_info">
                <div style={{ display: 'flex' }}>
                  <span
                    style={{ maxWidth: 110 }}
                    className="text_ellipsis my_bed_card_list_detail_item_content_body_item1_info_name"
                  >
                    <Popover content={item?.patientName}>
                      {item?.patientName}
                    </Popover>
                  </span>
                  <Popover
                    content={
                      handleSex(
                        item?.patientGender,
                        vsf.stores.dbenums?.enums?.SEX_DICT,
                      )?.label
                    }
                  >
                    {handelSexIcon(item?.patientGender)}
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_info_name_age">
                  <Popover content={item?.patientAge}>
                    {item?.patientAge}
                  </Popover>
                </div>
              </div>
              <div className="my_bed_card_list_detail_item_content_body_item1_type">
                <span
                  className="text_ellipsis"
                  style={{
                    maxWidth: 120,
                    flexShrink: 0,
                    wordBreak: 'break-all',
                  }}
                >
                  <Popover content={item?.displayId}>{item?.displayId}</Popover>
                </span>
                <div className="my_bed_card_list_detail_item_content_body_item1_type_text">
                  <Popover
                    content={handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  >
                    {handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  </Popover>
                </div>
              </div>
              {/* </Popover> */}
            </div>
            <div className="my_bed_card_list_detail_item_content_body_item2">
              <div className="my_bed_card_list_detail_item_content_body_item2_content">
                <div
                  style={{
                    maxWidth: '50%',
                    flexShrink: 0,
                    wordBreak: 'break-word',
                  }}
                  className="text_ellipsis
                  my_bed_card_list_detail_item_content_body_item2_content_item"
                >
                  <Popover content={item?.attendingDoctorName}>
                    <span>医生：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.attendingDoctorName}
                    </span>
                  </Popover>
                </div>
                <div
                  style={{
                    maxWidth: '50%',
                    flexShrink: 0,
                    wordBreak: 'break-word',
                  }}
                  className="text_ellipsis
                  my_bed_card_list_detail_item_content_body_item2_content_item"
                >
                  <Popover content={item?.inChargeNurseName}>
                    <span>护士：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.inChargeNurseName}
                    </span>
                  </Popover>
                </div>
                <div className="text_ellipsis my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.diagnosisDescription}>
                    <span className="text_ellipsis my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.diagnosisDescription ?? '暂无'}
                    </span>
                  </Popover>
                </div>
                <div className="text_ellipsis my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover
                    content={
                      item?.closeAccountIndicator ||
                      (item?.needPayAmount && item?.needPayAmount > 0)
                        ? item?.needPayAmount
                        : item?.prePaymentBalance
                    }
                  >
                    <span>金额：</span>
                    {/* 欠费\冻帐 */}
                    {item?.closeAccountIndicator ? (
                      <Icon type="icon-dongzhang" />
                    ) : item?.needPayAmount && item?.needPayAmount > 0 ? (
                      <span
                        style={{
                          padding: '0 8px',
                          height: '14px',
                          background: 'rgba(255,126,126,0.2)',
                          borderRadius: '2px',
                        }}
                        className="my_bed_card_list_detail_item_content_body_item2_content_item_value"
                      >
                        欠
                      </span>
                    ) : (
                      <span>{item?.prePaymentBalance ?? 0}</span>
                    )}
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.admissionWardDateTime}>
                    <span>入科：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.admissionWardDays}天
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={item?.operationEndDateTime}>
                    <span>术后：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {item?.afterOperationDays}天
                    </span>
                  </Popover>
                </div>
                {renderType(item)}
              </div>
            </div>
          </div>

          {/* {RightContextMenu({
          detail: item,
          dataSource: handelDisabled?.(item),
          borderBottom: [3, 5, 8, 10],
          id: 'contextMenu',
          handelClickMenuItem: handelClick,
        })} */}
        </div>
      </ContextMenuTrigger>
    );
  };
  // 简卡-占床
  const occupyBedSimple = (item, index) => {
    return !item?.isShowDetail ? (
      <div
        className="my_bed_card_list_detail_item_content"
        onClick={() => {
          const _cardList = cardList?.map((_item) => {
            return item?.id == _item?.id
              ? { ..._item, isShowDetail: !_item?.isShowDetail }
              : { ..._item, isShowDetail: false };
          });
          const _res = markData?.filter((markDataItem) => {
            return item?.[markDataItem?.isShow];
          });
          if (_res?.length > 0) {
            setIsShowTag(true);
          } else {
            setIsShowTag(false);
          }
          setCurrentSimple(item);
          setCardList(_cardList);
        }}
        style={{
          border: handelNursingClass(item?.nursingClass)?.value
            ? 'none'
            : '2px solid transparent',
        }}
      >
        {/* 新开医嘱通知 */}
        {item?.newDoctorOrderIndicator && (
          <Popover content="有未校对新医嘱">
            <div
              className="custom_my_bed_card_aic_view"
              onClick={() => {
                vsf?.navigateTo(
                  `/index/cpoe/order-check?patientId=${item?.patientId}`,
                );
              }}
            ></div>
          </Popover>
        )}
        <ContextMenuTrigger
          id={'contextMenuSimple' + index}
          holdToDisplay={200}
        >
          {RightContextMenu({
            detail: item,
            // dataSource: occupyBedRightContextMenuData,
            dataSource: handelDisabled?.(item),
            borderBottom: [3, 5, 8, 10],
            id: `contextMenuSimple${index}`,
            handelClickMenuItem: handelClick,
          })}
          <Popover content={handelNursingClass(item?.nursingClass)?.value}>
            <div
              className={cn(
                handelNursingClass(item?.nursingClass)?.class,
                'my_bed_card_list_detail_item_content_topbgc',
              )}
            ></div>
          </Popover>
          <div
            className="my_bed_card_list_detail_item_content_body"
            style={{
              border: handelNursingClass(item?.nursingClass)?.value
                ? '2px solid transparent'
                : 'none',
            }}
          >
            <div className="my_bed_card_list_detail_item_content_body_item1">
              <div className="my_bed_card_list_detail_item_content_body_item1_tag">
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_text">
                  <Popover content={renderBedNum(item?.bedLabel, item)}>
                    {renderBedNum(item?.bedLabel, item)}
                  </Popover>
                  <Popover content={handelbedNum(item)?.label}>
                    <span style={{ color: '#85898D' }}>
                      {handelbedNum(item)?.label}
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_icon">
                  {renderIcon(item)}
                </div>
              </div>
              {/* <Popover content={handelNursingClass(item?.nursingClass)?.value}> */}
              <div className="my_bed_card_list_detail_item_content_body_item1_info ">
                <div>
                  <span className="my_bed_card_list_detail_item_content_body_item1_info_name">
                    <Popover content={item?.patientName}>
                      {item?.patientName}
                    </Popover>
                  </span>
                  <Popover
                    content={
                      handleSex(
                        item?.patientGender,
                        vsf.stores.dbenums?.enums?.SEX_DICT,
                      )?.label
                    }
                  >
                    {handelSexIcon(item?.patientGender)}
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_info_name_age">
                  <Popover content={item?.patientAge}>
                    {item?.patientAge}
                  </Popover>
                </div>
              </div>
              <div className="my_bed_card_list_detail_item_content_body_item1_type">
                <span>
                  <Popover content={item?.displayId}>{item?.displayId}</Popover>
                </span>
                <div className="my_bed_card_list_detail_item_content_body_item1_type_text">
                  <Popover
                    content={handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  >
                    {handelStopwatch(
                      item?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  </Popover>
                </div>
              </div>
              {/* </Popover> */}
            </div>
          </div>
        </ContextMenuTrigger>
      </div>
    ) : (
      <div
        className="my_bed_card_list_detail_item_content
        my_bed_card_list_detail_item_content1 custom_my_bed_card_list_detail_item_content_height custom_my_bed_card_list_detail_item_content_width"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
          border: handelNursingClass(item?.nursingClass)?.value
            ? 'none'
            : '2px solid #3276E8',
          backgroundColor: '#ebf2ff',
        }}
        onClick={() => {
          const _cardList = cardList?.map((_item) => {
            return item?.id == _item?.id
              ? { ..._item, isShowDetail: !_item?.isShowDetail }
              : { ..._item, isShowDetail: false };
          });
          setCurrentSimple(item);
          setCardList(_cardList);
        }}
      >
        <ContextMenuTrigger
          id={'contextMenuSimple1' + index}
          holdToDisplay={200}
        >
          {RightContextMenu({
            detail: item,
            // dataSource: occupyBedRightContextMenuData,
            dataSource: handelDisabled?.(item),
            borderBottom: [3, 5, 8, 10],
            id: `contextMenuSimple1${index}`,
            handelClickMenuItem: handelClick,
          })}
          <Popover content={handelNursingClass(item?.nursingClass)?.value}>
            <div
              className={cn(
                handelNursingClass(item?.nursingClass)?.class,
                'my_bed_card_list_detail_item_content_topbgc',
              )}
            ></div>
          </Popover>
          <div
            className="my_bed_card_list_detail_item_content_body custom_my_bed_card_list_detail_item_content_body_height"
            style={{
              border: handelNursingClass(item?.nursingClass)?.value
                ? '2px solid #3276E8'
                : 'none',
              borderTop: handelNursingClass(item?.nursingClass)?.value
                ? '2px solid transparent'
                : 'none',
              borderRadius: '0 0 4 4',
            }}
          >
            <div className="my_bed_card_list_detail_item_content_body_item1">
              <div className="my_bed_card_list_detail_item_content_body_item1_tag">
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_text">
                  <Popover
                    content={renderBedNum(
                      currentSimple?.bedLabel,
                      currentSimple,
                    )}
                  >
                    {renderBedNum(currentSimple?.bedLabel, currentSimple)}
                  </Popover>
                  <Popover content={handelbedNum(item)?.label}>
                    <span style={{ color: '#85898D' }}>
                      {handelbedNum(item)?.label}
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_tag_icon">
                  {renderIcon(currentSimple)}
                </div>
              </div>
              <div className="my_bed_card_list_detail_item_content_body_item1_info">
                <div>
                  <span className="my_bed_card_list_detail_item_content_body_item1_info_name">
                    <Popover content={currentSimple?.patientName}>
                      {currentSimple?.patientName}
                    </Popover>
                  </span>
                  <Popover
                    content={
                      handleSex(
                        currentSimple?.patientGender,
                        vsf.stores.dbenums?.enums?.SEX_DICT,
                      )?.label
                    }
                  >
                    {handelSexIcon(currentSimple?.patientGender)}
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item1_info_name_age">
                  <Popover content={currentSimple?.patientAge}>
                    {currentSimple?.patientAge}
                  </Popover>
                </div>
              </div>
              <div className="my_bed_card_list_detail_item_content_body_item1_type">
                <span>
                  <Popover content={currentSimple?.displayId}>
                    {currentSimple?.displayId}
                  </Popover>
                </span>
                <div className="my_bed_card_list_detail_item_content_body_item1_type_text">
                  <Popover
                    content={handelStopwatch(
                      currentSimple?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  >
                    {handelStopwatch(
                      currentSimple?.chargeType,
                      vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT,
                    )}
                  </Popover>
                </div>
              </div>
            </div>
            <div className="my_bed_card_list_detail_item_content_body_item2">
              <div
                style={{
                  border: '1px solid #D6D8D9',
                  backgroundColor: '#ebf2ff',
                }}
                className="my_bed_card_list_detail_item_content_body_item2_content"
              >
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={currentSimple?.attendingDoctorName}>
                    <span>医生：</span>
                    <span>{currentSimple?.attendingDoctorName}</span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={currentSimple?.inChargeNurseName}>
                    <span>护士：</span>
                    <span>{currentSimple?.inChargeNurseName}</span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={currentSimple?.diagnosisDescription}>
                    <span className="text_ellipsis">
                      {currentSimple?.diagnosisDescription ?? '暂无'}
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover
                    content={
                      currentSimple?.closeAccountIndicator ||
                      currentSimple?.needPayAmount > 0
                        ? currentSimple?.needPayAmount
                        : ''
                    }
                  >
                    <span>金额：</span>
                    {currentSimple?.closeAccountIndicator ? (
                      <Icon type="icon-dongzhang" />
                    ) : currentSimple?.needPayAmount &&
                      currentSimple?.needPayAmount > 0 ? (
                      <span
                        style={{
                          padding: '0 4px',
                          height: '14px',
                          background: 'rgba(255,126,126,0.2)',
                          borderRadius: '2px',
                        }}
                        className="my_bed_card_list_detail_item_content_body_item2_content_item_value"
                      >
                        欠
                      </span>
                    ) : (
                      currentSimple.prePaymentBalance ?? 0
                    )}
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={currentSimple?.admissionWardDateTime}>
                    <span>入科：</span>
                    <span className="my_bed_card_list_detail_item_content_body_item2_content_item_value">
                      {handelTime(currentSimple?.admissionWardDateTime)}天
                    </span>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_body_item2_content_item">
                  <Popover content={currentSimple?.operationEndDateTime}>
                    <span>术后：</span>
                    <span>
                      {handelTime(currentSimple?.operationEndDateTime)}天
                    </span>
                  </Popover>
                </div>
                {renderType(currentSimple)}
              </div>
            </div>
          </div>
        </ContextMenuTrigger>
      </div>
    );
  };
  // 床位状态
  const renderBedStatus = (types?, item?, index?) => {
    if (type === 'detail') {
      switch (types) {
        // 空床
        case '0':
          return (
            <div
              style={{ position: 'relative' }}
              className="my_bed_card_list_detail_item_content custom_my_bed_card_list_detail_item_content_height"
            >
              <ContextMenuTrigger
                id={'contextMenuEmpty' + index}
                holdToDisplay={200}
              >
                {RightContextMenu({
                  detail: item,
                  dataSource: handelDisabled(item),
                  borderBottom: [3, 5, 8, 10],
                  id: `contextMenuEmpty${index}`,
                  handelClickMenuItem: handelClick,
                })}
                <div className="my_bed_card_list_detail_item_content_detail_card_empty">
                  <Popover content={item?.bedLabel}>
                    <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                      {item?.bedLabel}床
                    </div>
                  </Popover>
                </div>
                <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed">
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                    <Icon size={88} type="icon-kongchuang" />
                  </div>
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_name">
                    空床
                  </div>
                </div>
              </ContextMenuTrigger>
            </div>
          );
        //  占床
        case '1':
          return occupyBed(item, index);
        // 停用
        case '4':
          return (
            <div
              style={{ position: 'relative', height: 224 }}
              className="my_bed_card_list_detail_item_content"
            >
              {RightContextMenu({
                detail: item,
                // dataSource: occupyBedRightContextMenuData,
                dataSource: handelDisabled(item),
                borderBottom: [3, 5, 8, 10],
                id: 'contextMenuStop',
                // handelDisabled: handelDisabled(item),
              })}
              <ContextMenuTrigger id="contextMenuStop" holdToDisplay={200}>
                <div className="my_bed_card_list_detail_item_content_detail_card_empty">
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                    {item?.bedLabel}
                  </div>
                </div>
                <div
                  style={{ background: 'rgba(0,0,0,.5)' }}
                  className="my_bed_card_list_detail_item_content_detail_card_empty_bed"
                >
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                    <Icon size={88} type="icon-tingyong1" />
                  </div>
                  <div
                    style={{ color: '#fff' }}
                    className="my_bed_card_list_detail_item_content_detail_card_empty_bed_name"
                  >
                    停用
                  </div>
                </div>
              </ContextMenuTrigger>
            </div>
          );
      }
    } else if (type === 'simple') {
      switch (types) {
        // 空床
        case '0':
          return (
            <div
              style={{ position: 'relative' }}
              className="my_bed_card_list_detail_item_content"
            >
              {RightContextMenu({
                detail: item,
                dataSource: handelDisabled(item),
                borderBottom: [3, 5, 8, 10],
                id: `contextMenuSimple2${index}`,
                handelClickMenuItem: handelClick,
              })}

              <ContextMenuTrigger
                id={'contextMenuSimple2' + index}
                holdToDisplay={200}
              >
                <div className="my_bed_card_list_detail_item_content_detail_card_empty">
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                    {item?.bedLabel}
                  </div>
                </div>
                <div
                  style={{ height: 110, width: 292 }}
                  className="my_bed_card_list_detail_item_content_detail_card_empty_bed"
                >
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                    <Icon size={44} type="icon-kongchuang" />
                  </div>
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_name">
                    空床
                  </div>
                </div>
              </ContextMenuTrigger>
            </div>
          );
        //  占床
        case '1':
          return occupyBedSimple(item, index);
        // 停用
        case '4':
          return (
            <div
              style={{ position: 'relative' }}
              className="my_bed_card_list_detail_item_content"
            >
              {RightContextMenu({
                detail: item,
                dataSource: handelDisabled(item),
                borderBottom: [3, 5, 8, 10],
                id: 'contextMenu2',
              })}
              <ContextMenuTrigger id="contextMenu2" holdToDisplay={200}>
                <div className="my_bed_card_list_detail_item_content_detail_card_empty">
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_number">
                    {item?.bedLabel}
                  </div>
                </div>
                <div
                  style={{
                    background: 'rgba(0,0,0,.5)',
                    height: 110,
                    width: 292,
                  }}
                  className="my_bed_card_list_detail_item_content_detail_card_empty_bed"
                >
                  <div className="my_bed_card_list_detail_item_content_detail_card_empty_bed_icon">
                    <Icon size={44} type="icon-tingyong1" />
                  </div>
                  <div
                    style={{ color: '#fff' }}
                    className="my_bed_card_list_detail_item_content_detail_card_empty_bed_name"
                  >
                    停用
                  </div>
                </div>
              </ContextMenuTrigger>
            </div>
          );
      }
    }
  };

  // 床卡拖拽
  const changePosition = (
    dragIndex: string | number,
    hoverIndex: string | number,
  ) => {
    const data = cardList.slice();
    if (data[hoverIndex]?.bedStatus == 0 && data[dragIndex]?.bedStatus == 0) {
      return message.info('空床无法换床!');
    } else if (
      data[hoverIndex]?.bedStatus == 4 ||
      data[dragIndex]?.bedStatus == 4
    ) {
      return message.info('已停用的床无法换床!');
    } else {
      // 打开弹窗
      bedCardChange(data[hoverIndex], data[dragIndex]);
    }
  };

  useImperativeHandle(refs, () => ({
    getCardList,
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      {cardList?.length > 0 && !loading && (
        <div className="my_bed_card_list hideScroll">
          {type === 'detail' &&
            cardList?.map((item, index) => {
              const _renderItem = renderBedStatus(item?.bedStatus, item, index);
              return (
                <div key={item} className="my_bed_card_list_detail_item">
                  <DraggableComponent
                    renderItem={_renderItem}
                    changePosition={changePosition}
                    index={index}
                    {...item}
                    key={'drag_card' + index}
                  ></DraggableComponent>
                </div>
              );
            })}
          {type === 'simple' &&
            cardList?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={cn(
                    'my_bed_card_list_detail_item',
                    currentSimple?.id == item?.id
                      ? 'my_bed_card_list_detail_item_zIndex'
                      : '',
                  )}
                  style={{
                    position: 'relative',
                  }}
                >
                  {/* 新开医嘱通知 */}
                  {item?.newDoctorOrderIndicator && item?.isShowDetail && (
                    <Popover content="有未校对新医嘱">
                      <div
                        className="custom_my_bed_card_aic_view"
                        onClick={() => {
                          vsf?.navigateTo(
                            `/index/cpoe/order-check?patientId=${item?.patientId}`,
                          );
                        }}
                      ></div>
                    </Popover>
                  )}
                  {renderBedStatus(item?.bedStatus, item, index)}
                </div>
              );
            })}
        </div>
      )}
      {cardList?.length <= 0 && !loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Empty />
        </div>
      )}
      {loading && <Loading size="normal" />}
    </DndProvider>
  );
};

export default React.forwardRef(Index);
