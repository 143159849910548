import vsf from '@vs/vsf-boot';

type Params = {
  dosage: number;
  performDays: number;
  dosagePerUnit: number;
  amountPerPackage: number;
  extension: any;
  roundingType: string;
};

export default function amount(params: Params) {
  const {
    dosage,
    performDays,
    dosagePerUnit,
    amountPerPackage,
    extension,
    roundingType,
  } = params;
  // 频次单位有歧义，之前只会算周天，但是产品说这单位维护的时候可以随便填可能会造成代码问题（建议改成选的不可以自定义填）
  enum DAY {
    日,
    周,
    天,
  }
  type FREQUENCY_TYPE = {
    [key in keyof typeof DAY]: () => number;
  };
  const FREQUENCY = (): number => {
    const method: FREQUENCY_TYPE = {
      周: () => {
        return (
          ((extension?.frequencyInterval ?? 0) * 7) /
          extension?.frequencyInterval /
          extension?.frequencyCount
        );
      },
      日: () => {
        return extension?.frequencyCount / extension?.frequencyInterval;
      },
      天() {
        return this.日();
      },
    };
    return method[extension?.frequencyIntervalUnit]?.() ?? 0;
  };
  const ROUNDING = {
    BY_DAY: () => {
      /**
       * 按天取整：按天为一个取整单位
       * 不再汇总使用天数的总使用剂量
       * 而是先以每天的总用量向上取整1次
       * 然后再根据使用天数汇总应发药总量
       * 例如: 医嘱为 剂量20mg，频次BID(日频次数为2)，用药天数16天
       * 计算规则为: (20mg*2)/(10mg*30) = 0.133333；每天向上取整为1盒，16天累计发药量为1盒*16=16盒
       **/
      const DOSAGE = (dosage ?? 0) * (FREQUENCY?.() ?? 0);
      const USAGE = (dosagePerUnit ?? 0) * (amountPerPackage ?? 0);
      return Math?.ceil(DOSAGE / USAGE) * (performDays ?? 0);
    },
    BY_AMOUNT: () => {
      /**
       * 按量取整：是一种最大节约用药的模式
       * 根据医生开立剂量、频次，使用天数，计算出使用天数内使用的全部用量
       * 然后与供应规格 1 个包装下的含量进行比较
       * 得到的数字向上取整就计算出应发药品数量
       * 例如: 医嘱为 剂量 20mg, 频次BID(日频次数为2), 用药天数16天
       * 计算规则为: (20mg*2*16) /(10mg*30) = 2.133333,向上取整为3盒
       */
      const DOSAGE = (dosage ?? 0) * (FREQUENCY?.() ?? 0) * (performDays ?? 0);
      const USAGE = (dosagePerUnit ?? 0) * (amountPerPackage ?? 0);
      return Math?.ceil(DOSAGE / USAGE);
    },
    BY_TIMES: () => {
      /**
       * 按次取整：按每次使用作为一个取整单位
       * 不再汇总使用天数的使用剂量
       * 而是先以每次的用量向上取整
       * 然后再根据使用天数的总次数汇总应发药总量
       * 例如: 医嘱为 剂量20mg，频次BID(日频次数为2)
       * 用药天数16天
       * 计算规则为:20mg/(10mg*30) = 0.666667，每次向上取整为1盒
       * 每天累计2盒，16天累计发药量为1盒*16*2=32盒
       */
      const DOSAGE = dosage ?? 0;
      const USAGE = (dosagePerUnit ?? 0) * (amountPerPackage ?? 0);
      return (
        Math?.ceil(DOSAGE / USAGE) * (performDays ?? 0) * (FREQUENCY?.() ?? 0)
      );
    },
  };
  console.log(
    roundingType ?? 'BY_AMOUNT',
    ROUNDING?.[roundingType ?? 'BY_AMOUNT']?.(),
  );
  return ROUNDING?.[roundingType ?? 'BY_AMOUNT']?.();
}
