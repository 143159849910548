import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemBaseController_getClinicItemClassList_4252cc: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-class-list',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemClassVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
});
