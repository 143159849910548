import './index.less';
import './index.less';

import { Modal, TabPane, Tabs } from '@vs/vsf-kit';
import React, { useState } from 'react';

import Icon from '@/module/Icon';

import Shortcuts from './shortcuts';

type SettingsPropsType = {
  open?: boolean;
  onClose?: () => void;
};

const Settings = (props: SettingsPropsType) => {
  const { open, onClose } = props;
  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="系统设置"
      footer={null}
      className="aspirin-settings-modal"
    >
      <div className="aspirin-settings-main">
        <Tabs
          items={[
            {
              key: 'shortcuts',
              label: '快捷设置',
              children: <Shortcuts />,
            },
          ]}
        />
      </div>
    </Modal>
  );
};

const Index = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenSettings = () => {
    setOpen(true);
  };

  const handleCloseSettings = () => {
    setOpen(false);
  };

  return (
    <div>
      <Icon type="icon-bangzhu" size={26} onClick={handleOpenSettings} />
      <Settings open={open} onClose={handleCloseSettings} />
    </div>
  );
};

export default Index;
