import { createServices } from '@vs/vsf-boot';
export default createServices({
  InpBillingDetailController_changeDetailChargeType_83a389: {
    method: 'post',
    url: '/api/inp-billing/change-detail-charge-type',
    parameterFomatter: (data?: { inpBillingDetailId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  InpBillingDetailController_createInpBillingDetailForNurseStation_3910aa: {
    method: 'post',
    url: '/api/inp-billing/create-inp-billing-detail-for-nurse-station',
    parameterFomatter: (data?: {
      saveInpBillingDetailEoList: SaveInpBillingDetailEoInpBillingPersistEo[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  InpBillingDetailController_getInpBillingDetailGroupInfoForNurseBilling_dbd63f:
    {
      method: 'post',
      url: '/api/inp-billing/get-inp-billing-detail-group-info-for-nurse-billing',
      parameterFomatter: (data?: { inpVisitId: number; itemName: string }) =>
        data,
      responseFormatter: (
        _,
        __,
        data?: InpBillingDetailGroupForNurseStationVoInpBillingEntranceWebVo[],
      ) => data,
    },
  InpBillingDetailController_getInpBillingDetailByItemId_ac57fa: {
    method: 'post',
    url: '/api/inp-billing/get-inp-billing-detail-by-item-id',
    parameterFomatter: (data?: { inpVisitId: number; itemId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: InpBillingDetailForNurseVoInpBillingEntranceWebVo[],
    ) => data,
  },
  PriceListController_getPagedByPriceItemCurrentQto_22ee99: {
    method: 'post',
    url: '/api/price-list/get-paged-by-price-item-current-qto',
    parameterFomatter: (data?: {
      qto: PriceItemCurrentNewQtoPriceListPersistQto;
      isIncludeDrugOrHerb: boolean;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: PriceItemBaseVoPriceListEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  PriceListController_getByPricePatternByPricePatternMasterId_0dd128: {
    method: 'post',
    url: '/api/price-list/get-by-price-pattern-by-price-pattern-masterId',
    parameterFomatter: (data?: { pricePatternMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PricePatternDetailWithPriceItemVoPriceListEntranceWebVo[],
    ) => data,
  },
});
