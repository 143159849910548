import './index.less';
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  compose,
  message,
  Modal,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  withField,
  withRef,
} from '@vs/vsf-kit';
import cn from 'classnames';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

let Index: any = (props, ref) => {
  const { getPatientInfo, tableList } = props;

  // modal显示隐藏
  const [open, setOpen] = useState(false);
  // 选中table行
  const [selectCurrentRow, setSelectCurrentRow] = useState<any>();
  // 患者id
  const [searchId, setSearchId] = useState<any>();

  useImperativeHandle(
    ref,
    () => {
      return {
        handelModal,
        getSearchKey,
        handleSelect,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  console.log(tableList);

  const getTableList = async (params) => {
    if (searchId?.length <= 0) return [];
    const res =
      await vsf.services?.ClinicInpAdmissionController_getAllAvailableByClinicInpAdmissionQto_dcc286?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            patientIdIs: params.extra?.searchId,
          },
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    const _res = res?.data?.map((item, index) => {
      return { ...item, id: index };
    });
    if (res?.code === 200 && res?.data?.length === 1) {
      console.log('我的111');

      handleSelect(res?.data?.[0]);
    }
    if (res?.code === 200 && res?.data?.length > 1) {
      handelModal(true);
    }
    if (res?.code !== 200) {
      handelModal(false);
    }
    return {
      data: _res ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
      code: res?.code,
    };
  };

  // modal显示隐藏
  const handelModal = (isShow) => {
    setOpen(isShow);
  };
  // 选择table行
  const handleSelect = (record) => {
    setSelectCurrentRow(record);
    getPatientInfo(record?.inpAdmissionApplyVo?.id);
    handelModal(false);
  };

  const getSearchKey = (v) => {
    setSearchId(v);
    console.log(v);
  };

  return (
    <Modal
      title={<div className="register_information_my_modal">患者申请单</div>}
      centered
      width={1258}
      open={open}
      onCancel={() => {
        handelModal(false);
      }}
      footer={
        <Button
          onClick={() => {
            handelModal(false);
          }}
          type="primary"
          ghost
        >
          取消
        </Button>
      }
    >
      <VSPromiseTable
        className="aspirin-table
         aspirin-table-header-background-color
         aspirin-table-row-hover-background-color"
        editable={{ editType: 'none' }}
        bordered
        id="clinicInpAdmissionTable"
        onFetch={() => {
          return tableList;
        }}
        scroll={{ y: 600 }}
        rowClick={{
          rowClickType: {
            type: 'default',
            action: 'double-click',
          },
        }}
        onRow={(record) => {
          return {
            onDoubleClick: (v) => {
              handleSelect(record);
            },
            onClick: () => {
              //   setPatientId(record);
              //   // handleSelect(record);
            },
          };
        }}
        extraParams={{ searchId }}
      >
        <VSTableColumn
          dataIndex={['inpAdmissionApplyVo', 'inpAppointDto', 'appointStatus']}
          title="登记状态"
          valueType="select"
          dataSource={[
            { label: '预约', value: 'APPOINT' },
            { label: '取消预约', value: 'CANCEL_APPOINT' },
            { label: '住院通知', value: 'INP_ADMISSION_NOTIFY' },
            { label: '入院登记', value: 'INP_ADMISSION_REGISTER' },
            { label: '取消入院', value: 'CANCEL_INP_ADMISSION' },
            { label: '退出虚拟住院', value: 'LEAVE_VIRTUAL_IN_HOSPITAL' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['patientVo', 'displayId']}
          title="患者ID"
          valueType="digit"
          formItemProps={{
            rules: [
              {
                message: '病人标识的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['patientVo', 'name']}
          title="姓名"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={[
            'inpAdmissionApplyVo',
            'branchInstitution',
            'institutionName',
          ]}
          title="预约院区"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '收治院区的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={[
            'inpAdmissionApplyVo',
            'admissionToDepartment',
            'departmentName',
          ]}
          title="临床科室"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '申请入院科室的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['inpAdmissionApplyVo', 'appointAdmissionDate']}
          title="预约住院日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={150}
        />
        <VSTableColumn
          dataIndex={[
            'inpAdmissionApplyVo',
            'admissionFromDepartment',
            'departmentName',
          ]}
          title="申请科室"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '申请科室的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['inpAdmissionApplyVo', 'applyDoctor', 'staffName']}
          title="申请医生"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '申请医生ID的值不合法',
                type: 'number',
                min: 0,
                max: undefined,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['inpAdmissionApplyVo', 'applyDate']}
          title="申请日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={150}
        />
      </VSPromiseTable>
    </Modal>
  );
};
Index.displayName = 'registerInfomation';
Index = React.forwardRef(Index);
export default compose(
  withRef(),
  withField<any>({
    name: 'registerInfomation',
  }),
)(Index);
