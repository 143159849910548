import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React from 'react';

const PatientMergeLogSearch = (props) => {
  /**
   * convertGetPagedByOperationLogPatientQtoRes2VSPromiseTablePatientMergeLogRo
   * @param {any} input
   * @returns {any}
   */
  const convertGetPagedByOperationLogPatientQtoRes2VSPromiseTablePatientMergeLogRo =
    (input) => {
      const _ = window._ ?? {};
      const output: any = [];
      output.push(
        ...(_.get(input, 'data.result')?.map((d, i) => {
          const obj: any = {};
          obj.id = _.get(d, 'id');
          obj.operateTime = _.get(d, 'operateTime');
          obj.retainDisplayId = _.get(d, 'patientMergeInfo.retainDisplayId');
          const operateType = _.get(d, 'operateType');
          if (operateType == 'CREATE') {
            obj.operateType = '合并';
          } else if (operateType == 'REMOVE') {
            obj.operateType = '取消合并';
          }
          obj.retainId = _.get(d, 'patientMergeInfo.retainId');
          obj.relatedDisplayId = _.get(d, 'patientMergeInfo.relatedDisplayId');
          obj.relatedId = _.get(d, 'patientMergeInfo.relatedId');
          obj.operator = _.get(d, 'operator');
          obj.memo = _.get(d, 'memo');
          return obj;
        }) ?? []),
      );
      return output;
    };
  return (
    <Section>
      <div className="vSPromiseTablePatientMergeLog">
        <VSPromiseTable
          // vsid="09453"
          id="vSPromiseTablePatientMergeLog"
          className="aspirin-table-header-background-color
        aspirin-table-row-hover-background-color
        aspirin-table-nopadding
        aspirin-table-body-empty-transparent-background-color table_overflow_384"
          onFetch={async (params) => {
            const res =
              await vsf?.services?.PatientOperateLogController_getPagedByOperationLogPatientQto_5a3e28?.(
                {
                  qto: {
                    from: params?.pagination?.from ?? 0,
                    size: params?.pagination?.size ?? 100,
                    operateTimeRangeIn: params?.search?.operateTimeRangeIn,
                    sceneIs: 'PATIENT_MERGE',
                  },
                },
              );
            if (res?.code != 200) {
              vsf?.showToast(res?.message ?? '查询异常', 'error');
              return {};
            }
            const results =
              convertGetPagedByOperationLogPatientQtoRes2VSPromiseTablePatientMergeLogRo?.(
                res,
              );
            return {
              data: results ?? [],
              total: res?.data?.count ?? res?.data?.length ?? 0,
            };
          }}
          searchConfig={{
            searchButtonProps: {
              size: 'large',
              className: 'icon16',
            },
            resetButtonProps: {
              size: 'large',
              className: 'icon16',
            },
          }}
          scroll={{
            y: 680,
          }}
        >
          <VSTableColumn
            dataIndex={['operateTimeRangeIn']}
            title="操作日期"
            valueType="dateRange"
            search
            order={0}
            columnKey={'operateTimeRangeIn'}
            hideInTable
            searchConfig={{
              transform: (values) => {
                if (!Array.isArray(values)) return values;
                return {
                  operateTimeRangeIn: {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                };
              },
            }}
            //   transform={(values) => {
            //     if (!Array.isArray(values)) return values;
            //     return ['operateTimeRangeIn'].reduceRight(
            //       (acc, curr) => ({ [curr]: acc } as any),
            //       {
            //         begin: values[0],
            //         end: values[1],
            //         beginInclude: true,
            //         endInclude: true,
            //       },
            //     );
            //   }}
            //   convertValue={(obj) => {
            //     if (Array.isArray(obj)) return obj;
            //     return [obj?.begin, obj?.end];
            //   }}
            //   fieldProps={{ showTime: true }}
          />

          <VSTableColumn
            dataIndex={['operateTime']}
            title="合并时间"
            valueType="aspirinDateTimePicker"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['retainDisplayId']}
            title="保留的患者ID"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '保留患者的显示ID长度不合法',
                  type: 'string',
                  min: 0,
                  max: 36,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['operateType']}
            title="合并类型"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '操作类型长度不合法',
                  type: 'string',
                  min: 0,
                  max: 50,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['relatedDisplayId']}
            title="被合并的患者ID"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '关联的患者显示ID长度不合法',
                  type: 'string',
                  min: 0,
                  max: 36,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['operator']}
            title="操作员"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '操作人长度不合法',
                  type: 'string',
                  min: 0,
                  max: 60,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['memo']}
            title="备注"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '备注长度不合法',
                  type: 'string',
                  min: 0,
                  max: 2048,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </div>
    </Section>
  );
};
export default definePage(PatientMergeLogSearch);
