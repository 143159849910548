import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStorageImportExportController_getExportMasterWithDetailById_c96935: {
    method: 'post',
    url: '/api/drug-storage-import-export/get-export-master-with-detail-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugStorageImportExportController_createUpdateDrugExportMasterWithDetail_40b45d:
    {
      method: 'post',
      url: '/api/drug-storage-import-export/create-update-drug-export-master-with-detail',
      parameterFomatter: (data?: {
        btoParam: CreateUpdateDrugExportMasterWithDetailBtoDrugStorageImportExportServiceBto;
        extApiParam: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
      ) => data,
    },
  DrugStorageImportExportController_invalidDrugExportMaster_edb33f: {
    method: 'post',
    url: '/api/drug-storage-import/invalid-drug-export-master',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DrugStorageImportExportController_accountantExport_d26633: {
    method: 'post',
    url: '/api/drug-storage-import/accountant-export',
    parameterFomatter: (data?: {
      btoParam: CreateUpdateDrugExportMasterWithDetailBtoDrugStorageImportExportServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugExportMasterWithDetailVoDrugStorageImportExportEntranceWebVo,
    ) => data,
  },
  DrugImportRecordCreateController_getCandidateImportExportClzList_6a4edf: {
    method: 'post',
    url: '/api/drug-storage-import/get-candidate-import-export-clz-list',
    parameterFomatter: (data?: { isImport: boolean }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugImportExportClassVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugImportRecordCreateController_getCandidateStorageList_1ef4f5: {
    method: 'post',
    url: '/api/drug-storage-import/get-candidate-storage-list',
    parameterFomatter: (data?: { clzCode: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: StorageForImportExportClassVoDrugStorageImportExportEntranceWebVo[],
    ) => data,
  },
  DrugStorageController_getSubStorageListByDrugStorageId_8b4549: {
    method: 'post',
    url: '/api/drug-storage/get-sub-storage-list-by-drug-storage-id',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: SubDrugStorageVoDrugStorageEntranceWebVo[],
    ) => data,
  },
  ApplicationParameterController_getLocalParameterValues_df5905: {
    method: 'post',
    url: '/api/application/get-local-parameter-values',
    parameterFomatter: (data?: { names: string[] }) => data,
    responseFormatter: (_, __, data?: ParameterValueEoApplicationPersistEo[]) =>
      data,
  },
});
