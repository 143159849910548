import vsf from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import React, {
  cloneElement,
  Fragment,
  useCallback,
  useContext,
  useState,
} from 'react';

import { TreeMenu } from '@/components';
import Container from '@/module/Container';
import Icon from '@/module/Icon';
import { Flex, Screen } from '@/pages/Index/components';
import { useFetch } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import { CreateScreen } from '@/pages/Index/components/screen';

import DictionaryEdit from './edit';
const CodeTableType = (props) => {
  const [value, setValue] = useState<any>({});
  const onRemove = useFetch({
    fetch: async (id) => {
      return vsf?.services?.BaseDictionaryController_deleteBaseCodeType_5624c7?.(
        {
          btoParam: {
            id,
          },
        },
      );
    },
    message: {
      success: '删除成功',
      loading: '正在删除中, 请勿做其他操作',
      error: '删除失败',
    },
    success() {
      vsf?.refs?.CodeTableTree?.handleReload();
    },
  });

  const onSearch = async (value) => {
    const res =
      await vsf?.services?.BaseDictionaryController_queryAllByBaseCodeTypeIndexQto_310527?.(
        { qto: { orNameLike: value, orInputCodeLike: value } },
      );
    return (res?.data?.result ?? [])?.map((item) => ({
      ...item,
      parentTypeId: Number(item?.parentTypeId),
    }));
  };
  const onScreen = useContext(CreateScreen);
  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '24%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '0',
            },
          ],
        }}
      >
        <TreeMenu
          title="数据分类"
          treeProps={{ defaultExpandAll: true }}
          loadData={async () => {
            const res =
              await vsf.services?.BaseDictionaryController_getAll_6ecb98?.({});
            return {
              code: 200,
              data: res?.data?.map((item) => ({
                ...item,
                parentTypeId: Number(item?.parentTypeId),
              })),
            };
          }}
          id="CodeTableTree"
          fieldNames={{
            title: 'name',
            key: 'id',
            children: 'children',
          }}
          row={{
            expand: true,
            extra: (node: any) => {
              return (
                <div
                  className="aspirin-tree-button"
                  onClick={(event) => event?.stopPropagation()}
                >
                  <Icon
                    type="icon-tianjia1"
                    style={{
                      color: 'var(--primary-color)',
                    }}
                    onClick={() => {
                      setValue({
                        parentTypeId: node?.id,
                        parentTypeName: node?.name,
                      });
                    }}
                    size={20}
                  />
                  <Icon
                    type="icon-wudishanchu"
                    size={18}
                    onClick={() => {
                      Tip({
                        content: `删除{【${node?.name}】}同时将删除所有子类型，及相关类型对应的
                        码表项目，是否确认删除`,
                        onOk: () => onRemove(node?.id),
                      });
                    }}
                  />
                </div>
              );
            },
          }}
          reload={false}
          headerRender={(dom: any) => (
            <Flex gap={10}>
              {cloneElement(dom, {
                onClick: () => {
                  dom?.props?.onClick();
                  onScreen?.(() => void 0);
                },
              })}
              <Button
                onClick={() => {
                  if (value) {
                    setValue((value) => ({
                      parentTypeId: value?.id,
                      parentTypeName: value?.name,
                    }));
                  } else {
                    setValue({ parentTypeId: null });
                  }
                }}
              >
                新增
              </Button>
            </Flex>
          )}
          dataRelationFieldNames={{ id: 'id', parentId: 'parentTypeId' }}
          search={{
            status: true,
            searchType: 'request',
            searchKeyWord: 'name',
            searchPlaceholder: '请输入名称或拼音首字母(大写)',
            onSearch,
          }}
          onSelect={(_value) => {
            setValue(_value);
          }}
        />
        <DictionaryEdit value={value} />
      </Container>
    </Fragment>
  );
};

const Major = () => {
  return (
    <Screen>
      <CodeTableType />
    </Screen>
  );
};

export default Major;
