import throttle from 'lodash/throttle';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react';

type direction = 'vertical' | 'horizontal';

// 实现元素左右拖拽的Hook逻辑
const useDragLine = (props: {
  container: MutableRefObject<HTMLDivElement | null>;
  line: MutableRefObject<HTMLDivElement | null>;
  callback: Dispatch<SetStateAction<number>>;
  direction: direction;
  canDrag: boolean;
  minSize: number;
}) => {
  const { container, line, callback, direction, canDrag, minSize } = props;
  const html = document.querySelector('html');
  const handleResize = useCallback(
    (e) => {
      if (container?.current) {
        const containerRect = container?.current?.getBoundingClientRect();
        const clientY = e?.clientY;
        const clientX = e?.clientX;
        if (direction === 'horizontal') {
          const value = clientX - (containerRect?.left ?? 0);
          if (value >= minSize && value <= containerRect?.width - minSize) {
            callback(clientX - (containerRect?.left ?? 0));
          }
        } else if (direction === 'vertical') {
          const value = clientY - (containerRect?.top ?? 0);
          if (value >= minSize && value <= containerRect?.height - minSize) {
            callback(clientY - (containerRect?.top ?? 0));
          }
        }
      }
    },
    [direction, callback, container, minSize],
  );
  useEffect(() => {
    const { current } = line;
    const mouseDown = (e: MouseEvent) => {
      html!.style!.userSelect = 'none';
      const resize = throttle(function (e: MouseEvent) {
        handleResize(e);
      }, 0);

      const resizeUp = function () {
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', resizeUp);
        html!.style!.userSelect = 'text';
      };

      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', resizeUp);
    };
    if (canDrag) {
      if (current) {
        (current as HTMLElement).addEventListener('mousedown', mouseDown);
      }
    } else {
      if (current) {
        (current as HTMLElement).removeEventListener('mousedown', mouseDown);
      }
    }

    return function () {
      if (current) {
        (current as HTMLElement).removeEventListener('mousedown', mouseDown);
      }
    };
  }, [canDrag, handleResize, line, html]);
};

export default useDragLine;
