import React from 'react';

import { Section } from '@vs/vsf-kit';
import { TreeMenu } from '@/components';
import askGetTreeWhole from '@/pages/Index/components/func/ask/askGetTreeWhole';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const InstitutionTree = (props) => {
  const onSearch = async (value) => {
    const res =
      await vsf?.services?.BaseDictionaryController_queryAllByBaseCodeTypeIndexQto_310527?.(
        { qto: { orNameLike: value, orInputCodeLike: value } },
      );
    return (res?.data?.result ?? [])?.map((item) => ({
      ...item,
      parentTypeId: Number(item?.parentTypeId),
    }));
  };
  return (
    <TreeMenu
      id="InstitutionTree"
      expand
      loadData={async () => {
        // Tips: 以下是你选择的请求函数
        const res =
          await vsf.services?.BaseDictionaryController_getAll_6ecb98?.({});

        return {
          code: 200,
          data: res?.data?.map((item) => ({
            ...item,
            parentTypeId: Number(item?.parentTypeId),
          })),
        };
      }}
      fieldNames={{
        title: 'name',
        key: 'id',
        children: 'children',
      }}
      dataRelationFieldNames={{ id: 'id', parentId: 'parentTypeId' }}
      onSelect={(_value, tree) => {
        props.onChange?.(_value, askGetTreeWhole(_value.key, tree));
      }}
      search={{
        status: true,
        searchType: 'request',
        searchKeyWord: 'name',
        searchPlaceholder: '请输入名称或拼音首字母',
        onSearch,
      }}
      title="数据分类"
      reload
      row={{
        extraShow: 'default',
      }}
    />
  );
};
export default definePage(InstitutionTree);
