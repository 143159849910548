import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
  message,
} from '@vs/vsf-kit';
import { useCallback, useEffect, useState } from 'react';
import { getEnumsLabel } from '../OutpMedicalTechnologyCharge/methods';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import {
  getListClinicPatientBedSimpleVoByBedWardId,
  getPatientInpVisitInfo,
  getWardPagedBySearchCode,
  queryPagedByPatientFullQto,
} from './services';
import { getScrollY } from '@/utils';
import { Descriptions } from 'antd';
import { isArray } from 'lodash';

const UserInfo = (props) => {
  // 常量
  const { setInpVisitPatient } = props,
    [PatientDetails, setPatientDetails] = useState(),
    // 	查询当前院区病区（护理单元）列表
    { data: WardPageData } = useRequest(async () => {
      const res = await getWardPagedBySearchCode();
      return res;
    }),
    //查询床位病区的在床患者简单信息列表
    { data: PatientBedSimpleData, run: PatientBedSimpleRun } = useRequest(
      async (params) => {
        const res = await getListClinicPatientBedSimpleVoByBedWardId(params);
        return res?.map((ele) => ({
          ...ele,
          bedLabelAndPatientName: ele?.bedLabel + '-' + ele?.patientName,
        }));
      },
      { manual: true },
    ),
    //获取患者信息
    { data: PatientInpVisitInfoData, run: PatientInpVisitInfoRun } = useRequest(
      async (params) => {
        setInpVisitPatient({});
        vsf?.refs?.inpMedicalTechnologyChargeInpVisitForm?.setFieldValue(
          'displayId',
          params?.displayId,
        );
        const res = await getPatientInpVisitInfo(params);
        if (res?.length <= 0) return message.error('未查询到患者住院记录。');
        return res;
      },
      { manual: true },
    );
  useEffect(() => {
    if (PatientDetails?.id) {
      PatientBedSimpleRun({ bedWardId: PatientDetails?.ward?.id });
      vsf?.refs?.inpMedicalTechnologyChargeInpVisitForm?.setFieldsValue({
        wardInfo: PatientDetails?.ward?.id,
        bedInfo: PatientDetails?.bed?.id,
      });

      setInpVisitPatient({ ...PatientDetails, Math: Math?.random() });
    }
  }, [PatientDetails]);
  // 常量函数
  const inquire = useCallback(async () => {
    const inquireParams =
      vsf?.refs?.inpMedicalTechnologyChargeInpVisitForm?.getFieldValue(
        'displayId',
      );
    let patient, patientInpVisitInfo;
    if (inquireParams)
      patient = await queryPagedByPatientFullQto({
        displayIdIs: inquireParams,
      });
    if (!isArray(patient)) return;

    return PatientInpVisitInfoRun({
      displayId: patient[0]?.displayId,
      patientId: patient[0]?.id,
      inpVisitId: null,
    });
  }, []);
  return (
    <div className="UserInfo">
      <VSForm vsid="27117" id="inpMedicalTechnologyChargeInpVisitForm">
        <VSFormLayout key="0" columnCount={3}>
          <VSFormItem
            name={['wardInfo']}
            label="病区"
            valueType="selectCode"
            fieldProps={{
              fieldNames: { label: 'departmentName', value: 'id' },
              placeholder: '请选择病区',
              onChange: (params) =>
                PatientBedSimpleRun({
                  bedWardId: params?.id,
                }),
            }}
            dataSource={WardPageData}
          />
          <VSFormItem
            name={['bedInfo']}
            label="床位"
            valueType="selectCode"
            fieldProps={{
              fieldNames: {
                label: 'bedLabelAndPatientName',
                value: 'bedId',
              },
              placeholder: '请选择床位',
              onChange: ({
                inpVisitId,
                patientDisplayId: displayId,
                patientId,
              }) =>
                PatientInpVisitInfoRun({ inpVisitId, displayId, patientId }),
            }}
            dataSource={PatientBedSimpleData}
          />

          <VSFormItem
            name={['displayId']}
            label="患者ID"
            valueType="text"
            rules={[]}
            fieldProps={{
              onPressEnter: inquire,
            }}
          />
        </VSFormLayout>
      </VSForm>
      <Descriptions column={7} size="default" className="Descriptions">
        <Descriptions.Item label="姓   名">
          {PatientDetails?.name ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="性   别">
          {getEnumsLabel('SEX_DICT', PatientDetails?.gender) ?? ''}
        </Descriptions.Item>

        <Descriptions.Item label="年龄">
          {PatientDetails?.age ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="费   别">
          {getEnumsLabel('CHARGE_TYPE_DICT', PatientDetails?.chargeType) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="人员类别">
          {getEnumsLabel('IDENTITY_DICT', PatientDetails?.identityCode) ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="护理单元">
          {PatientDetails?.departmentName ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="入院日期">
          {PatientDetails?.admissionDateTime &&
            dayjs(PatientDetails?.admissionDateTime).format('YYYY-MM-DD')}

          {PatientDetails?.admissionDateTime &&
            `（${dayjs().diff(PatientDetails?.admissionDateTime, 'day')}天）`}
        </Descriptions.Item>
      </Descriptions>
      <PatientListModel
        {...{ PatientListData: PatientInpVisitInfoData, setPatientDetails }}
      />
    </div>
  );
};
// 患者接诊记录
const PatientListModel = (props) => {
  const { PatientListData, setPatientDetails } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (PatientListData?.length >= 2) return setOpen(true);
    setPatientDetails(PatientListData?.[0]);
  }, [PatientListData]);
  return (
    <Modal
      title={
        <span style={{ color: 'rgba(50, 118, 232, 1)' }}>患者接诊记录</span>
      }
      open={open}
      onCancel={() => setOpen(false)}
      width={1200}
      footer=""
    >
      <VSTable
        className="common_table"
        scroll={{
          y: getScrollY(400),
        }}
        value={PatientListData}
        rowClick={{
          type: 'default',
          action: 'double-click',
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: false,
          showTotal: undefined,
        }}
        onRow={(node) => {
          return {
            onDoubleClick: () => {
              setOpen(false);
              setPatientDetails(node);
            },
          };
        }}
      >
        <VSTableColumn
          dataIndex={['admissionDateTime']}
          title="入院日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['gender']}
          title="性别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
        />

        <VSTableColumn
          dataIndex={['age']}
          title="年龄"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['chargeType']}
          title="费别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
        />

        <VSTableColumn
          dataIndex={['identityCode']}
          title="人员类别"
          valueType="select"
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
        />
      </VSTable>
    </Modal>
  );
};

export default definePage(UserInfo);
