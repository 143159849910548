import { createServices } from '@vs/vsf-boot';
export default createServices({
  AccountController_resetPassword_68f7e8: {
    method: 'post',
    url: '/api/aspirin-user-account/reset-passowrd',
    parameterFomatter: (data?: { staffId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StaffDetailDtoController_queryPaged_39a7f7: {
    method: 'post',
    url: '/api/user-staff/staff-list/query',
    parameterFomatter: (data?: {
      qto: NewQueryStaffListQtoUserStaffPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StaffListVoUserStaffEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StaffBOController_createStaff_78ec6d: {
    method: 'post',
    url: '/api/user-staff/create-staff',
    parameterFomatter: (data?: {
      btoParam: CreateStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo) =>
      data,
  },
  StaffBOController_updateStaff_85164a: {
    method: 'post',
    url: '/api/user-staff/update-staff',
    parameterFomatter: (data?: {
      btoParam: UpdateStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StaffListVoUserStaffEntranceWebVo) =>
      data,
  },
  StaffBOController_deleteStaff_87868c: {
    method: 'post',
    url: '/api/user-staff/delete-staff',
    parameterFomatter: (data?: {
      btoParam: DeleteStaffBtoUserStaffServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DepartmentDtoController_getAllByDepartmentInCurrentInstitution_c769de: {
    method: 'post',
    url: '/api/organization/get-all-by-department-in-current-institution',
    parameterFomatter: (data?: { name: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoUserStaffEntranceWebVoStafflistvo[],
    ) => data,
  },
});
