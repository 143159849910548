import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicBedController_queryClinicBedDetailVoByClinicBedDetailQtoPaged_14c1f7: {
    method: 'post',
    url: '/api/clinic-bed/query-clinic-bed-detail-vo-by-clinic-bed-detail-qto-paged',
    parameterFomatter: (data?: {
      qto: ClinicBedDetailQtoClinicBedPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ClinicBedDetailVoClinicBedEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ClinicBedController_saveBed_20d0bb: {
    method: 'post',
    url: '/api/clinic-bed/save-bed',
    parameterFomatter: (data?: {
      btoParam: SaveBedBtoClinicBedServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicBedDetailVoClinicBedEntranceWebVo,
    ) => data,
  },
  ClinicBedController_deleteBed_9a64f6: {
    method: 'post',
    url: '/api/clinic-bed/delete-bed',
    parameterFomatter: (data?: {
      btoParam: DeleteBedBtoClinicBedServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicBedWithPatientVoClinicBedEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a: {
    method: 'post',
    url: '/api/organization/get-all-by-department-new-qto-by-department-new-qto',
    parameterFomatter: (data?: {
      qto: DepartmentNewQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DepartmentVoOrganizationEntranceWebVo[],
    ) => data,
  },
  OrganizationController_getAllByInstitutionListQto_a59f48: {
    method: 'post',
    url: '/api/organization/get-all-by-institution-list-qto',
    parameterFomatter: (data?: {
      qto: InstitutionListQtoOrganizationPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVoDepartmentvo[],
    ) => data,
  },
});
