import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicInpAdtController_cancelInAndOutTransferPatient_6e5fcf: {
    method: 'post',
    url: '/api/clinic-inp-adt/cancel-in-and-out-transfer-patient',
    parameterFomatter: (data?: {
      saveInpVisitForCancelInAndOutTransferBto: SaveInpVisitForCancelInAndOutTransferBtoClinicInpVisitServiceBto;
      updateInpTransferRecordBto: UpdateInpTransferRecordBtoClinicInpAdtServiceBto;
      cancelInAndOutTransferEnum: CancelInAndOutTransferEnum;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  ClinicInpVisitController_queryAllByQueryCancelInAndOutTransferQto_7091f3: {
    method: 'post',
    url: '/api/clinic-inp-visit/query-all-by-query-cancel-in-and-out-transfer-qto',
    parameterFomatter: (data?: {
      qto: QueryCancelInAndOutTransferQtoClinicInpVisitPersistQto;
      ext: {
        cancelInAndOutTransferEnum: CancelInAndOutTransferEnum;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CancelInAndOutTransferVoClinicInpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
