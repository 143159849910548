import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import {
  VSForm,
  Row,
  VSTable,
  Radio,
  VSFormItem,
  Button,
  VSFormLayout,
  Tabs,
  VSTableColumn,
  VSPromiseTable,
  Space,
  message,
  Table,
  Checkbox,
} from '@vs/vsf-kit';
import { convertQueryWaitDispenseDrugDetailListRes2TableInpToxiWaitDispenseDetails } from './Service';
import { getScrollY, getScrollYRem } from '@/utils';
import { useAsyncEffect, useRequest } from 'ahooks';
import { calculateAge } from '@/utils/setDataUtil';
import Icon from '@/module/Icon';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import { ComplexSelect } from '@/pages/Index/components';
import { isMinScreen } from '@/config';
const GeneralTable = ({
  wardId,
  tableParam,
  queryTableParam,
  selectedChange,
  isSendSucess,
}) => {
  const { data, run } = useRequest(
    async () => {
      const res =
        await vsf?.services?.DrugPrescriptionDispenseController_queryWaitDispenseDrugDetailList_aed110?.(
          {
            qto: {
              wardIdIs: wardId?.wardId,
              isSpermPoisonHempIs: false,
              inpVisitIdIs: tableParam?.flag?.inpVisitId,
            },
          },
        );
      const data =
        convertQueryWaitDispenseDrugDetailListRes2TableInpToxiWaitDispenseDetails?.(
          res,
        );
      const dataSorce = data?.map((item, index) => {
        const waitDispenseDetail = item?.waitDispenseDetail?.map((obj, i) => {
          return { ...obj, key: `${index}-${i}` };
        });
        return {
          ...item,
          key: `${index}`,
          waitDispenseDetail: waitDispenseDetail,
        };
      });
      return dataSorce;
    },
    { manual: true },
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const [data, setData] = useState();

  // 平铺数据源
  const flattenData = (data) => {
    const flatData = [];
    data.forEach((parent) => {
      flatData.push({
        ...parent,
        checkbox: (
          <Checkbox
            indeterminate={
              parent.waitDispenseDetail.every((child) =>
                selectedRowKeys.includes(child.key),
              )
                ? false
                : parent.waitDispenseDetail.some((child) =>
                    selectedRowKeys.includes(child.key),
                  )
            }
            checked={
              parent.waitDispenseDetail &&
              parent.waitDispenseDetail.every((child) =>
                selectedRowKeys.includes(child.key),
              )
            }
            onChange={() => handleParentCheckboxChange(parent)}
          />
        ),
      });

      parent?.waitDispenseDetail?.forEach((child) => {
        flatData.push({
          ...child,
          checkbox: (
            <Checkbox
              checked={selectedRowKeys?.includes(child.key)}
              onChange={() => handleChildCheckboxChange(child)}
            />
          ),
        });
      });
    });
    return flatData;
  };

  //选择父节点应该做什么
  const handleParentCheckboxChange = (parent) => {
    const childKeys = parent.waitDispenseDetail.map((child) => child.key);
    const allSelected = childKeys.every((key) => {
      return selectedRowKeys.includes(key);
    });
    let newSelectedRowKeys = [...selectedRowKeys];

    if (allSelected) {
      // Unselect all waitDispenseDetail
      newSelectedRowKeys = newSelectedRowKeys.filter(
        (key) => !childKeys.includes(key),
      );
    } else {
      newSelectedRowKeys = [...newSelectedRowKeys, ...childKeys];
    }
    console.log(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //选择子节点应该做什么
  const handleChildCheckboxChange = (child) => {
    let newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.includes(child.key)) {
      // Unselect child
      newSelectedRowKeys = newSelectedRowKeys.filter(
        (key) => key !== child.key,
      );
    } else {
      // Select child
      newSelectedRowKeys.push(child.key);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  //选择所有节点应该做什么
  const onAllSelectChange = (param) => {
    if (param) {
      let allKey = [];
      data?.map((item) => {
        const childKeys = item?.waitDispenseDetail?.map((obj) => {
          allKey?.push(obj?.key);
        });
        allKey?.push(item?.key);
      }),
        setSelectedRowKeys(allKey);
    } else {
      setSelectedRowKeys([]);
    }
  };

  useEffect(() => {
    let arr = [];
    data?.map((item, index) => {
      arr?.push(item);
      item?.waitDispenseDetail?.map((obj) => {
        arr?.push(obj);
      });
    });
    const filteredArray = arr?.filter(
      (item) => selectedRowKeys.includes(item.key) && !item.key.includes('_'),
    );
    selectedChange(filteredArray);
  }, [selectedRowKeys]);

  useEffect(() => {
    if (wardId || tableParam) {
      run();
    }
  }, [tableParam, wardId]);

  useEffect(() => {
    run();
  }, [isSendSucess]);

  const HospitalSelect = (props) => {
    const [
      hospitalDataSource,
      setHospitalDataSource,
      ,
      ,
      getInitHospitalDataSource,
    ] = useGetResetInitState([]);

    const wardId = props?.ward?.wardId;
    useAsyncEffect(async () => {
      if (wardId) {
        const res =
          await vsf?.services?.DrugPrescriptionDispenseController_queryDischargeBedDropListQto_3852ec?.(
            {
              // TODO: 请检查以下这部分传参是否符合需求
              qto: {
                wardIdIs: wardId,
                size: 999999,
              },
              ext: {},
            },
          ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
        if (res?.code === 200) {
          setHospitalDataSource(
            res?.data?.result?.map((item, index) => ({
              ...item,
              inpVisitId: item?.inpVisit?.id,
              bed: item?.inpVisit?.patientInHospital.bed,
              inpVisitNumber: item?.inpVisit?.inpVisitNumber,
              gender: vsf.stores.dbenums.enums.SEX_DICT?.find(
                ({ value }) => value === item?.patient?.gender,
              )?.label,
              label: item?.patient?.name,
              value: item?.patient?.id,
              key: item?.patient?.id,
            })),
          );
        }
      }
    }, [wardId]);

    return (
      <ComplexSelect
        mode="radio"
        placeholder="请选择病区患者"
        hideSelectIcon
        showLabel="label"
        allowClear
        value={props?.value ?? ''}
        onChange={props?.onChange}
        dropdownMatchSelectWidth={700}
        listHeight={1000}
        onSearch={(value, filterOption) => {
          if (value?.trim()) {
            setHospitalDataSource(
              filterOption(value, getInitHospitalDataSource()),
            );
          } else {
            setHospitalDataSource(getInitHospitalDataSource());
          }
        }}
      >
        <Table
          columns={[
            {
              dataIndex: ['bed', 'bedLabel'],
              title: '床号',
            },
            {
              dataIndex: ['inpVisitNumber'],
              title: '住院号',
              width: 198,
            },
            {
              dataIndex: ['patient', 'displayId'],
              title: '患者ID',
            },
            {
              dataIndex: ['patient', 'name'],
              title: '姓名',
            },
            {
              dataIndex: ['gender'],
              title: '性别',
            },
          ]}
          dataSource={hospitalDataSource}
          pagination={false}
          size="middle"
          scroll={{
            y: 300,
          }}
        />
      </ComplexSelect>
    );
  };
  return (
    <>
      <div className="hemp-container">
        <div className="hemp-detaill">
          <VSForm id="hempDetaill">
            <VSFormLayout columnCount={4}>
              {/* <VSFormItem
                name={['flag']}
                label={'患者'}
                valueType="patientSelect"
                fieldProps={{
                  dataSourceParams: wardId?.wardId,
                }}
              ></VSFormItem> */}
              <VSFormItem
                valueType="custom"
                name={['flag']}
                label="患者"
                fieldProps={{}}
                transform={(value) => {
                  return {
                    inHospitalId: value?.id,
                    patientId: value?.patient?.id,
                    wardId: value?.wardId,
                  };
                }}
              >
                <HospitalSelect ward={wardId} />
              </VSFormItem>
              <Button
                children={'查询'}
                type="primary"
                onClick={queryTableParam}
              ></Button>
              <VSFormItem
                name={['id']}
                label="主键"
                style={{ display: 'none' }}
                valueType="digit"
                fieldProps={{}}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <div className="hemp-table">
          <VSPromiseTable
            vsid="09199"
            id="tableInpToxiWaitDispenseDetails1"
            onFetch={() => {
              return flattenData(data);
            }}
            rowClassName={(node) =>
              node?.waitDispenseDetail ? 'aspirin-table-row-background-box' : ''
            }
            pagination={false}
            rowKey={'id'}
            bordered={true}
            scroll={{
              // y: isMinScreen ? 400 : 550,
              y: getScrollYRem(28.6),
            }}
          >
            <VSTableColumn
              dataIndex={['checkbox']}
              title={<Checkbox onChange={onAllSelectChange} />}
              valueType="text"
              width={30}
              render={(dom, val) => {
                return val?.checkbox;
              }}
            />
            <VSTableColumn
              dataIndex={['prescriptionDataTime']}
              title="处方日期"
              valueType="text"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
              render={(_, recode, index) => {
                if (recode.waitDispenseDetail) {
                  return <div>{wardId?.wardName}</div>;
                } else {
                  return <div>{recode?.prescriptionDataTime}</div>;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['orderText']}
              title="医嘱正文"
              valueType="text"
              fieldProps={{}}
              render={(_, recode, index) => {
                if (recode.waitDispenseDetail) {
                  return (
                    <div>{recode?.bedLabel && `${recode?.bedLabel}床`}</div>
                  );
                } else if (recode?.supplyIndicator) {
                  return (
                    <div>
                      {recode?.dangerIndicator && (
                        <Icon
                          type="icon-gaowei"
                          size={20}
                          style={{
                            marginRight: 4,
                          }}
                        />
                      )}
                      {recode?.orderText}
                    </div>
                  );
                } else {
                  return (
                    <div className="danger-text-color">
                      {recode?.dangerIndicator && (
                        <Icon
                          type="icon-gaowei"
                          size={20}
                          style={{
                            marginRight: 4,
                          }}
                        />
                      )}
                      {recode?.orderText ?? '123'}
                    </div>
                  );
                }
              }}
            />
            <VSTableColumn
              dataIndex={['amountAndPackageUnit']}
              title="数量"
              valueType="text"
              fieldProps={{}}
              render={(_, recode, index) => {
                if (recode.waitDispenseDetail) {
                  return <div>{recode?.name}</div>;
                } else {
                  return recode?.amountAndPackageUnit;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['usageAndUnit']}
              title="剂量"
              valueType="text"
              fieldProps={{}}
              render={(_, recode, index) => {
                if (recode.waitDispenseDetail) {
                  return <div>{recode?.displayId}</div>;
                } else {
                  return recode?.usageAndUnit;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['administration']}
              title="途径"
              valueType="select"
              fieldProps={{
                dataSource: vsf.stores.dbenums.enums.PERFORM_FREQ_DICT,
              }}
              dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
              render={(dom, recode) => {
                if (recode?.gender) {
                  return (
                    <div>
                      {vsf.stores.dbenums.enums.SEX_DICT?.find(
                        (item) => item?.value === recode?.gender,
                      )?.label ?? ''}
                    </div>
                  );
                } else {
                  return dom;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['frequency']}
              title="频次"
              valueType="select"
              fieldProps={{
                dataSource: vsf.stores.dbenums.enums.PERFORM_FREQ_DICT,
              }}
              dataSource={vsf.stores.dbenums?.enums?.PERFORM_FREQ_DICT}
              render={(dom, recode) => {
                if (recode?.birthday) {
                  return <div>{calculateAge(recode?.birthday)}岁</div>;
                } else {
                  return dom;
                }
              }}
            />
            <VSTableColumn
              dataIndex={['prescriptionAttribute']}
              title="处方属性"
              valueType="select"
              fieldProps={{
                dataSource: vsf.stores.dbenums.enums.DRUG_TOXI_PROPERTY_DICT,
              }}
              dataSource={vsf.stores.dbenums?.enums?.DRUG_TOXI_PROPERTY_DICT}
            />
            <VSTableColumn
              dataIndex={['price']}
              title="单价"
              valueType="digit"
              fieldProps={{}}
              render={(_, v) => {
                return v?.price && Number(v?.price ?? 0).toFixed(2);
              }}
            />
          </VSPromiseTable>
        </div>
      </div>
    </>
  );
};
export default GeneralTable;
