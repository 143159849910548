import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  VSTable,
  Form,
  VSFormDependency,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2InpTransferRecordTable,
  convertGetMedicalRecordVoByIdRes2MedicalRecordPatientInfo,
} from '../convert';
import {
  phoneNumber,
  phoneNumberMessage,
  idCard,
  idCardMessage,
  postcodeMessage,
  postcode,
} from '@/pattern';
import { Select } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import { askSearch } from '@/pages/Index/components/func/ask';
import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import IdentityDocuments from '@/pages/Index/components/identity_documents';
import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { labelColWidth, labelNumberWidth } from '@/utils';
import { isMinScreen } from '@/config';

const formItemWidth = 240;

const Index = (props) => {
  const { data = {}, patientIdentificationList, getList } = props;
  const { user, common } = props?.stores ?? {};
  const { getServerTime, obstericsDeptCode, currentApplication } = common || {};
  const { initKey, currentPatient, inVisitInfo } = user ?? {};
  const { setCurrentApplication } = common || {};
  const [openModal, setOpenModal] = useState(false);
  const [detail, setDetail] = useState();
  const [changeValue, setChangeValue] = useState();
  // 身份证list
  const [identificationList, setIdentificationList] = useState(
    patientIdentificationList,
  );

  useEffect(() => {
    if (data?.birthday) {
      handelBirthChange(dayjs(data?.birthday).valueOf());
    }
    vsf.refs.medicalRecordPatientInfo.setFieldsValue({ ...data });
    setChangeValue({
      identityNumberUnknownReason: data?.identityNumberUnknownReason,
    });
    setDetail(data);
    setIdentificationList(patientIdentificationList);
    getList();
  }, [data, patientIdentificationList]);

  const handelOpenModal = (v, value, type) => {
    if (v) {
      // 打开弹窗,form身份证同步到弹窗
      const res = identificationList?.map((item) => {
        return item?.identificationClass === '身份证'
          ? {
              ...item,
              identificationNumber:
                vsf?.refs?.medicalRecordPatientInfo.getFieldsValue()?.idNumber,
            }
          : item;
      });
      console.log(
        vsf?.refs?.medicalRecordPatientInfo.getFieldsValue()?.idNumber,
        '身份证',
        res,
      );
      setIdentificationList(res);
    } else {
      // 关掉弹窗,弹窗身份证同步到form
      // 弹窗内点击取消 直接关掉
      if (type) {
        setOpenModal(v);
        setIdentificationList(value);
        console.log(identificationList, '身份证');
      } else {
        // 点击确定 把弹窗内的身份证值赋给外面
        const res = value?.find((item) => {
          return item?.identificationClass === '身份证';
        });
        vsf?.refs?.medicalRecordPatientInfo?.setFieldsValue({
          idNumber: res?.identificationNumber,
        });
        setIdentificationList(value);
        getList(value);
      }
    }
    setOpenModal(v);
  };
  const handelCalculateAge = async (birthdate) => {
    if (!birthdate) return;
    // // 获取出生日期 转化为分钟
    const tempBirthdate = birthdate / 60000;
    // // 获取当前时间 转化为分钟
    const res = await getServerTime();
    const currentTime = (res ?? +new Date()) / 60000;
    // 计算时间差 (分钟)
    const diff = currentTime - tempBirthdate;
    // 1小时以内 xx分钟
    if (currentTime - tempBirthdate < 60) {
      return Math.floor(diff) + '分钟';
    } else if (currentTime - tempBirthdate == 60) {
      // 1小时
      return '1小时0分钟';
    } else if (currentTime - tempBirthdate <= 1440) {
      // 24小时以内
      const hours = Math.floor(diff / 60); // 计算小时数
      const remainingMinutes = diff % 60; // 计算剩余的分钟数
      return Math.floor(hours) + '小时' + Math.floor(remainingMinutes) + '分钟';
    } else if (currentTime - tempBirthdate <= 4320) {
      // 三天以内
      const day = diff / (24 * 60);
      const hours = (diff % (24 * 60)) / 60;
      return Math.floor(day) + '天' + Math.floor(hours) + '小时';
    } else if (currentTime - tempBirthdate <= 40320) {
      // 三天以上不满一个月（28天）
      const day = diff / (24 * 60);
      return Math.floor(day) + '天';
    } else if (currentTime - tempBirthdate <= 518400) {
      // 不满一周岁（360天）
      const month = diff / (30 * 24 * 60);
      const day = (diff % (30 * 24 * 60)) / (24 * 60);
      return Math.floor(month) + '月' + Math.floor(day) + '天';
    } else if (currentTime - tempBirthdate <= 3110400) {
      // 一年以上（360天），六岁以下
      const year = diff / (30 * 24 * 60 * 12);
      const month = (diff % (30 * 24 * 60 * 12)) / (24 * 60 * 30);
      return Math.floor(year) + '年' + Math.floor(month) + '月';
    } else if (currentTime - tempBirthdate > 3110400) {
      const year = diff / (30 * 24 * 60 * 12);
      return Math.floor(year) + '岁';
    } else {
      return '11';
    }
  };
  const handelBirthChange = async (v) => {
    console.log();
    if (!v) return;
    const tempBirthdate = v / 60000;
    const currentTime = await getServerTime();

    const res = await handelCalculateAge(v);

    console.log(currentTime / 60000 - tempBirthdate, v, currentTime);
    if (currentTime / 60000 - tempBirthdate <= 40320) {
      vsf.refs?.medicalRecordPatientInfo?.setFieldsValue({
        under28Day: res,
        underOneYear: '',
        age: '',
      });
    } else if (currentTime / 60000 - tempBirthdate <= 518400) {
      vsf.refs?.medicalRecordPatientInfo?.setFieldsValue({
        under28Day: '',
        underOneYear: res,
        age: '',
      });
    } else {
      vsf.refs?.medicalRecordPatientInfo?.setFieldsValue({
        age: res,
        underOneYear: '',
        under28Day: '',
      });
    }
  };

  const renderTransfer = (index) => {
    return (
      // <VSFormLayout columnCount={3} labelCol={labelColWidth[10]}>
      <VSFormLayout columnCount={3} labelCol={labelNumberWidth(10)}>
        <VSFormItem
          name={['admissionDateTime']}
          label={index + '、转科日期'}
          valueType="text"
          fieldProps={{
            placeholder: '请输入转科日期',
            className: 'width240',
            disabled: true,
          }}
        />
        <VSFormItem
          name={['department', 'departmentName']}
          label="转入科室"
          valueType="text"
          fieldProps={{
            width: 360,
            disabled: true,
          }}
        />
      </VSFormLayout>
    );
  };

  return (
    <div className="homepageinfo_patient_info">
      <VSForm
        vsid="87840"
        id="medicalRecordPatientInfo"
        labelAlign="left"
        labelWrap=""
        // onFetch={async () => {
        //   return detail;
        // }}
      >
        {/* <VSFormLayout key="0" columnCount={3} labelCol={labelColWidth[10]}> */}
        <VSFormLayout key="0" columnCount={3} labelCol={labelNumberWidth(12)}>
          <VSFormItem
            name={['name']}
            label="姓名"
            valueType="text"
            rules={[
              { message: '姓名长度不合法', type: 'string', min: 0, max: 60 },
            ]}
            // hidden
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['gender']}
            label="性别"
            valueType="select"
            fieldProps={{ className: 'width240', showSearch: true }}
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
          />

          <VSFormItem
            name={['birthday']}
            label="出生日期"
            valueType="date"
            fieldProps={{
              className: 'width240',
              format: 'YYYY-MM-DD',
              disabledDate: (current) => {
                return current && current > moment().endOf('day');
              },
              onChange: (v) => {
                handelBirthChange(v);
              },
            }}
          />
          <VSFormItem
            name={['age']}
            label="年龄"
            valueType="text"
            fieldProps={{ className: 'width240', disabled: true }}
          />

          <VSFormItem
            name={['underOneYear']}
            label="年龄不足一周岁的"
            valueType="text"
            fieldProps={{ className: 'width240', disabled: true }}
          />

          <VSFormItem
            name={['under28Day']}
            label="新生儿年龄<=28天的"
            valueType="text"
            fieldProps={{ className: 'width240', disabled: true }}
          />

          <VSFormItem
            name={['firstNewbornWeight']}
            label="新生儿出生体重一孩"
            valueType="text"
            fieldProps={{
              className: 'width240',
              disabled: true,
              addonAfter: (
                <div
                  style={{
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                >
                  克
                </div>
              ),
            }}
          />

          <VSFormItem
            name={['secondNewbornWeight']}
            label="新生儿出生体重二孩"
            valueType="text"
            fieldProps={{
              className: 'width240',
              disabled: true,
              addonAfter: (
                <div
                  style={{
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                >
                  克
                </div>
              ),
            }}
          />
          {/* 病人为新生儿且本院出生 */}
          {obstericsDeptCode ==
            currentApplication?.department.departmentCode && (
            <VSFormItem
              name={['newbornBirthWeight']}
              label="新生儿出生体重"
              valueType="text"
              fieldProps={{
                className: 'width240',
                disabled: true,
                addonAfter: (
                  <div
                    style={{
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                    className="icon14"
                  >
                    克
                  </div>
                ),
              }}
            />
          )}
          {/* 病人为新生儿且不是本院出生 */}
          {/* {obstericsDeptCode ==
            currentApplication?.department.departmentCode && (
            <VSFormItem
              name={['newbornAdmissionWeight']}
              label="新生儿入院体重"
              valueType="text"
              fieldProps={{
                className: 'width240',
                disabled: true,
                addonAfter: (
                  <div
                    style={{
                      fontSize: 14,
                      color: '#343B42;',
                      cursor: 'pointer',
                    }}
                  >
                    克
                  </div>
                ),
              }}
            />
          )} */}

          <VSFormItem
            name={['newbornAdmissionType']}
            label="新生儿入院类型"
            valueType="select"
            dataSource={[
              { label: '正常新生儿', value: 'NORMAL' },
              { label: '早产', value: 'PREMATURE' },
              { label: '有疾病', value: 'DISEASE' },
              { label: '非无菌分娩', value: 'NON_ASEPTIC' },
              { label: '其他', value: 'OTHER' },
            ]}
            fieldProps={{ className: 'width240', showSearch: true }}
          />
        </VSFormLayout>
        <div className="homePageInfomation_line"></div>
        {/* <VSFormLayout key="1" columnCount={3} labelCol={labelColWidth[10]}> */}
        <VSFormLayout key="1" columnCount={3} labelCol={labelNumberWidth(10)}>
          <VSFormItem
            name={['citizenship']}
            label="国籍"
            valueType="custom"
            fieldProps={{
              showSearch: true,
              className: 'width240',
            }}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.COUNTRY_DICT}
              showSearch
              placeholder="请选择国籍"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>

          <VSFormItem
            name={['birthAddress']}
            label="出生地"
            valueType="address"
            fieldProps={{ className: 'width240' }}
          />

          <VSFormItem
            name={['nativeAddress']}
            label="籍贯"
            valueType="address"
            fieldProps={{ className: 'width240' }}
          />

          <VSFormItem
            name={['nation']}
            label="民族"
            valueType="custom"
            fieldProps={{ showSearch: true }}
            // dataSource={vsf.stores.dbenums?.enums?.NATION_DICT}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.NATION_DICT}
              showSearch
              placeholder="请选择民族"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>

          <VSFormItem
            name={['job']}
            label="职业"
            valueType="custom"
            fieldProps={{ showSearch: true }}
            // dataSource={vsf.stores.dbenums?.enums?.OCCUPATION_DICT}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.OCCUPATION_DICT}
              showSearch
              placeholder="请选择职业"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>
          <VSFormItem
            name={['marriageStatus']}
            label="婚姻"
            valueType="custom"
            fieldProps={{ showSearch: true }}
            // dataSource={vsf.stores.dbenums?.enums?.MARITAL_STATUS_DICT}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.MARITAL_STATUS_DICT}
              showSearch
              placeholder="请选择婚姻"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>
        </VSFormLayout>
        <VSFormLayout
          key="25"
          columnCount={2}
          labelCol={labelColWidth[10]}
          gutter={50}
        >
          <VSFormItem
            name={['idNumber']}
            label="身份证号"
            valueType="text"
            rules={[
              {
                pattern: idCard,
                message: idCardMessage,
              },
            ]}
            fieldProps={{
              addonAfter: (
                <div
                  style={{
                    color: '#ebf4ff',
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                  onClick={() => {
                    handelOpenModal(true);
                  }}
                >
                  编辑其他
                </div>
              ),
            }}
            onChange={() => {
              const res = identificationList?.map((item) => {
                return item?.identificationClass === '身份证'
                  ? {
                      ...item,
                      idNumber:
                        vsf?.refs?.medicalRecordPatientInfo.getFieldsValue()
                          ?.idNumber,
                    }
                  : item;
              });
              setIdentificationList(res);
            }}
          />
        </VSFormLayout>
        {/* <VSFormLayout key="2" columnCount={3} labelCol={labelColWidth[10]}> */}
        <VSFormLayout key="2" columnCount={3} labelCol={labelNumberWidth(12)}>
          <VSFormItem
            name={['identityNumberUnknownReason']}
            label="证件不详原因"
            valueType="select"
            dataSource={[
              { label: '三无人员', value: 'THREE_NON_PERSON' },
              { label: '新生儿未办理', value: 'NEWBORN' },
              {
                label: '无完全民事行为能力',
                value: 'WITHOUT_CAPACITY_FOR_CIVIL_CONDUCT',
              },
              { label: '意识障碍', value: 'DISTURBANCE_OF_CONSCIOUSNESS' },
              { label: '其他', value: 'OTHER' },
            ]}
            fieldProps={{
              className: 'width240',
              showSearch: true,
              onSelect: (v) => {
                const res =
                  vsf?.refs?.medicalRecordPatientInfo?.getFieldsValue();
                setChangeValue(res);
              },
            }}
          />
          {changeValue?.identityNumberUnknownReason !== 'OTHER' && (
            <VSFormItem
              name={['identityNumberUnknownReasonDetail']}
              label="其他原因"
              valueType="text"
              rules={[
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ]}
              fieldProps={{ className: 'width240' }}
            />
          )}
        </VSFormLayout>

        <VSFormLayout
          key="21"
          columnCount={2}
          labelCol={labelColWidth[10]}
          gutter={50}
        >
          {/* <VSFormLayout key="26" columnCount={2} labelCol={labelColWidth[10]}> */}
          <VSFormLayout key="26" columnCount={2} labelCol={labelColWidth[10]}>
            <VSFormItem
              name={['currentAddress']}
              label="现住址"
              valueType="address"
              fieldProps={{ className: 'width240' }}
            />
            <VSFormItem
              name={['currentAddress', 'detail']}
              valueType="text"
              style={{ marginLeft: isMinScreen ? '14px' : '72px' }}
              fieldProps={{
                width: '100%',
              }}
            />
          </VSFormLayout>
          {/* <VSFormLayout columnCount={2} labelCol={labelColWidth[10]}> */}
          <VSFormItem
            name={['cellphone']}
            label="电话"
            valueType="text"
            fieldProps={{ className: 'width240' }}
            rules={[
              {
                message: phoneNumberMessage,
                pattern: phoneNumber,
              },
            ]}
          />
          {/* <VSFormItem
              name={['currentAddress', 'postcode']}
              label="邮编"
              valueType="text"
              fieldProps={{ className: 'width240' }}
              rules={[
                {
                  pattern: postcode,
                  message: postcodeMessage,
                },
              ]}
            /> */}
          {/* </VSFormLayout> */}
        </VSFormLayout>
        <VSFormLayout key="30" columnCount={2} labelCol={labelColWidth[10]}>
          <VSFormItem
            name={['currentAddress', 'postcode']}
            label="邮编"
            valueType="text"
            fieldProps={{ className: 'width240' }}
            rules={[
              {
                pattern: postcode,
                message: postcodeMessage,
              },
            ]}
          />
        </VSFormLayout>
        <VSFormLayout
          key="3"
          columnCount={2}
          labelCol={labelColWidth[10]}
          gutter={50}
        >
          <VSFormLayout key="3" columnCount={2} labelCol={labelColWidth[10]}>
            <VSFormItem
              name={['registeredResidenceAddress']}
              label="户口地址"
              valueType="address"
              fieldProps={{ className: 'width240' }}
            />
            <VSFormItem
              name={['registeredResidenceAddress', 'detail']}
              label=""
              valueType="text"
              style={{ marginLeft: isMinScreen ? '14px' : '72px' }}
              fieldProps={{
                width: '100%',
              }}
            />
          </VSFormLayout>
          <VSFormLayout key="3" columnCount={2} labelCol={labelColWidth[10]}>
            <VSFormItem
              name={['registeredResidenceAddress', 'postcode']}
              label="邮编"
              valueType="text"
              fieldProps={{ className: 'width240' }}
              rules={[
                {
                  pattern: postcode,
                  message: postcodeMessage,
                },
              ]}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout
          key="4"
          columnCount={2}
          labelCol={labelColWidth[10]}
          gutter={50}
        >
          {/* <VSFormLayout key="4" columnCount={2} labelCol={labelColWidth[10]}> */}
          <VSFormLayout key="4" columnCount={2} labelCol={labelNumberWidth(14)}>
            <VSFormItem
              name={['workUnit', 'address']}
              label="工作单位及地址"
              fieldProps={{ className: 'width240' }}
              valueType="address"
            />
            <VSFormItem
              name={['workUnit', 'address', 'detail']}
              valueType="text"
              label=""
              style={{ marginLeft: isMinScreen ? '14px' : '72px' }}
              fieldProps={{
                width: '100%',
              }}
            />
          </VSFormLayout>
          <VSFormLayout key="4" columnCount={2} labelCol={labelColWidth[10]}>
            <VSFormItem
              name={['workUnit', 'phoneNumber']}
              label="单位电话"
              fieldProps={{ className: 'width240' }}
              rules={[
                {
                  message: phoneNumberMessage,
                  pattern: phoneNumber,
                },
              ]}
            />
          </VSFormLayout>
          <VSFormLayout labelCol={labelColWidth[10]}>
            <VSFormItem
              name={['workUnit', 'address', 'postcode']}
              label="邮编"
              fieldProps={{ className: 'width240' }}
            />
          </VSFormLayout>
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={3} labelCol={labelColWidth[10]}>
          <VSFormItem
            name={['contact', 'name']}
            label="联系人"
            valueType="text"
            fieldProps={{ className: 'width240' }}
          />
          <VSFormItem
            name={['contact', 'relationshipType']}
            label="关系"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
            fieldProps={{ className: 'width240', showSearch: true }}
          />
          <VSFormItem
            name={['contact', 'phoneNumber']}
            label="联系人电话"
            valueType="text"
            fieldProps={{ className: 'width240' }}
            rules={[
              {
                message: phoneNumberMessage,
                pattern: phoneNumber,
              },
            ]}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={2} labelCol={labelColWidth[10]} gutter={50}>
          {/* <VSFormLayout columnCount={2} labelCol={labelColWidth[10]}> */}
          <VSFormLayout columnCount={2} labelCol={labelNumberWidth(12)}>
            <VSFormItem
              name={['contact', 'address']}
              label="联系人地址"
              valueType="address"
              fieldProps={{ className: 'width240' }}
            />
            <VSFormItem
              name={['contact', 'address', 'detail']}
              label=""
              valueType="text"
              style={{ marginLeft: isMinScreen ? '14px' : '72px' }}
              fieldProps={{
                width: '100%',
              }}
            />
          </VSFormLayout>
        </VSFormLayout>
        {/* <VSFormLayout key="6" columnCount={3} labelCol={labelColWidth[10]}> */}
        <VSFormLayout key="6" columnCount={3} labelCol={labelNumberWidth(8)}>
          <VSFormItem
            name={['patientClass']}
            label="入院途径"
            valueType="custom"
            fieldProps={{ showSearch: true }}
            // dataSource={vsf.stores.dbenums?.enums?.PATIENT_CLASS_DICT}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.PATIENT_CLASS_DICT}
              showSearch
              placeholder="请选择入院途径"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>
          {/* 少门急诊医生 */}
          <VSFormItem
            name={['applyDoctorName']}
            label="门急诊医生"
            valueType="select"
            fieldProps={{
              className: 'width240',
              disabled: true,
            }}

            // dataSource={vsf.stores.dbenums?.enums?.PATIENT_CLASS_DICT}
          />
        </VSFormLayout>
        {/* <VSFormLayout key="7" columnCount={3} labelCol={labelColWidth[10]}> */}
        <VSFormLayout key="7" columnCount={3} labelCol={labelNumberWidth(10)}>
          <VSFormItem
            name={['patientAdmissionCondition']}
            label="入院时情况"
            valueType="custom"
            fieldProps={{ showSearch: true }}
            // dataSource={vsf.stores.dbenums?.enums?.ADMISSION_CONDITION_DICT}
          >
            <Select
              options={vsf.stores.dbenums?.enums?.ADMISSION_CONDITION_DICT}
              showSearch
              placeholder="请选择入院途径"
              filterOption={askSearch}
              className="width240"
            />
          </VSFormItem>
          <VSFormItem
            name={['caseType']}
            label="病例类型"
            valueType="select"
            dataSource={[
              { label: '疑难', value: 'DIFFICULT' },
              { label: '危重', value: 'CRITICAL' },
              { label: '一般', value: 'NORMAL' },
            ]}
            fieldProps={{ showSearch: true, className: 'width240' }}
          />
          <VSFormItem
            name={['rescueTimes']}
            label="抢救情况"
            valueType="digit"
            rules={[
              {
                pattern: /^\+?[1-9]\d*$/,
                message: '请输入正整数',
              },
            ]}
            fieldProps={{
              className: 'width240',
              addonAfter: (
                <div
                  style={{
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                >
                  次
                </div>
              ),
            }}
          />
          <VSFormItem
            name={['rescueSuccessTimes']}
            label="抢救成功"
            valueType="digit"
            rules={[
              {
                pattern: /^\+?[1-9]\d*$/,
                message: '请输入正整数',
              },
            ]}
            fieldProps={{
              className: 'width240',
              addonAfter: (
                <div
                  style={{
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                >
                  次
                </div>
              ),
            }}
          />
          <VSFormItem
            name={['admissionDateTime']}
            label="入院日期"
            valueType="date"
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['admissionDepartmentName']}
            label="入院科别"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['admissionToWardName']}
            label="病房入院"
            valueType="text"
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['transferDepartmentName']}
            label="转科科别"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['dischargeDateTime']}
            label="出院日期"
            valueType="date"
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['dischargeDepartmentName']}
            label="出院科别"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['dischargeWardDepartmentName']}
            label="病房出院"
            valueType="text"
            rules={[
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ]}
            fieldProps={{ className: 'width240', disabled: true }}
          />
          <VSFormItem
            name={['actualHospitalDay']}
            label="实际住院"
            valueType="text"
            fieldProps={{
              className: 'width240',
              disabled: true,
              addonAfter: (
                <div
                  style={{
                    color: '#343B42;',
                    cursor: 'pointer',
                  }}
                  className="icon14"
                >
                  天
                </div>
              ),
            }}
          />
        </VSFormLayout>
        <div className="homePageInfomation_line"></div>
        <div
          style={{
            color: '#343B42',
            height: 34,
            fontWeight: 'bolder',
          }}
          className="icon18"
        >
          转科
        </div>
      </VSForm>
      <VSForm
        id="transferRecord1"
        labelAlign="left"
        onFetch={async () => {
          return detail?.inpTransferRecordForMedicalRecordDtoList?.[0];
        }}
      >
        {renderTransfer(1)}
      </VSForm>
      <VSForm
        id="transferRecord2"
        labelAlign="left"
        onFetch={async () => {
          return detail?.inpTransferRecordForMedicalRecordDtoList?.[1];
        }}
      >
        {renderTransfer(2)}
      </VSForm>
      <VSForm
        id="transferRecord3"
        labelAlign="left"
        onFetch={async () => {
          return detail?.inpTransferRecordForMedicalRecordDtoList?.[2];
        }}
      >
        {renderTransfer(3)}
      </VSForm>
      {
        <IdentityDocuments
          list={identificationList}
          open={openModal}
          handelOpenModal={handelOpenModal}
        />
      }
    </div>
  );
};
export default definePage(Index);
