export const get = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.id = _.get(input, 'data.inpVisitVo.id'); // TODO 请确认本行字段是否符合逻辑
  output.groupId = _.get(input, 'data.groupId');
  output.chargeType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.chargeType',
  ); // TODO 请确认本行字段是否符合逻辑
  output.name = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.name',
  ); // TODO 请确认本行字段是否符合逻辑
  output.gender = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.gender',
  ); // TODO 请确认本行字段是否符合逻辑
  output.age = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.age',
  ); // TODO 请确认本行字段是否符合逻辑
  output.marriageStatus = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.marriageStatus',
  ); // TODO 请确认本行字段是否符合逻辑
  output.contactPersonName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonName',
  ); // TODO 请确认本行字段是否符合逻辑
  output.contactPersonPhone = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonPhone',
  ); // TODO 请确认本行字段是否符合逻辑
  output.medicalGroupLeader = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.medicalGroupLeader',
  ); // TODO 请确认本行字段是否符合逻辑
  output.attendingDoctor = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.attendingDoctor',
  ); // TODO 请确认本行字段是否符合逻辑
  output.controlQualityNurse = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityNurse',
  ); // TODO 请确认本行字段是否符合逻辑
  output.nurseInCharge = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionStaffDto.nurseInCharge',
  ); // TODO 请确认本行字段是否符合逻辑
  output.admissionWardDateTime = _.get(
    input,
    'data.inpVisitVo.patientInHospitalDto.admissionWardDateTime',
  ); // TODO 请确认本行字段是否符合逻辑
  output.bedId = _.get(input, 'data.inpVisitVo.patientInHospitalDto.bedId'); // TODO 请确认本行字段是否符合逻辑
  output.departmentId = _.get(
    input,
    'data.inpVisitVo.patientInHospitalDto.department.id',
  ); // TODO 请确认本行字段是否符合逻辑
  output.bedCost = _.get(input, 'data.inpVisitVo.patientInHospitalDto.bedCost'); // TODO 请确认本行字段是否符合逻辑
  output.admissionDateTime = _.get(input, 'data.inpVisitVo.admissionDateTime'); // TODO 请确认本行字段是否符合逻辑
  output.patientAdmissionCondition = _.get(
    input,
    'data.inpAdmissionApplyVo.admissionCondition',
  ); // TODO 请确认本行字段是否符合逻辑
  output.patientClass = _.get(input, 'data.inpVisitVo.patientClass'); // TODO 请确认本行字段是否符合逻辑
  output.diagnosisRecordVoList = _.get(input, 'data.diagnosisRecordVoList');
  output.height = _.get(input, 'data.height');
  output.weight = _.get(input, 'data.weight');
  output.stayInBed = _.get(input, 'data.stayInBed');
  output.birthAddress = {};
  // output.birthAddress =
  //   _.get(input, 'data.patientVo.birthAddress.province') +
  //   '/' +
  //   _.get(input, 'data.patientVo.birthAddress.city') +
  //   '/';
  // _.get(input, 'data.patientVo.birthAddress.county');
  output.birthAddress.province = _.get(
    input,
    'data.patientVo.birthAddress.province',
  );
  output.birthAddress.city = _.get(input, 'data.patientVo.birthAddress.city');
  output.birthAddress.county = _.get(
    input,
    'data.patientVo.birthAddress.county',
  );
  output.birthAddress.detail = _.get(
    input,
    'data.patientVo.birthAddress.detail',
  );
  output.birthAddress.code = _.get(input, 'data.patientVo.birthAddress.code');
  output.birthAddress.postcode = _.get(
    input,
    'data.patientVo.birthAddress.postcode',
  );
  output.identityCode = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.identityCode',
  );
  output.bedCost = _.get(input, 'data.inpVisitVo.patientInHospitalDto.bedCost'); // TODO 请确认本行字段是否符合逻辑
  return output;
};
export const transform = (input) => {
  const _ = window._ ?? {};
  const output: any = {};
  output.saveInpVisitForTransferBto = {};
  output.saveInpVisitForTransferBto.id = _.get(input, 'id');
  output.saveInpVisitForTransferBto.inpVisitExtensionStaffBto = {};
  output.saveInpVisitForTransferBto.inpVisitExtensionStaffBto.attendingDoctorId =
    _.get(input, 'attendingDoctor.id');
  output.saveInpVisitForTransferBto.inpVisitExtensionStaffBto.controlQualityNurseId =
    _.get(input, 'controlQualityNurse.id');
  output.saveInpVisitForTransferBto.inpVisitExtensionStaffBto.nurseInChargeId =
    _.get(input, 'nurseInCharge.id');
  output.saveInpVisitForTransferBto.inpVisitExtensionStaffBto.medicalGroupLeaderId =
    _.get(input, 'medicalGroupLeader.id');
  output.saveInpVisitForTransferBto.patientInHospitalBto = {};
  output.saveInpVisitForTransferBto.patientInHospitalBto.bedId = _.get(
    input,
    'bedId',
  );
  output.saveInpVisitForTransferBto.patientInHospitalBto.admissionWardDateTime =
    _.get(input, 'admissionWardDateTime');
  output.transferPatientInParameterEo = {};
  output.transferPatientInParameterEo.inpVisitId = _.get(input, 'id'); // Long 住院主记录ID
  output.transferPatientInParameterEo.height = _.get(input, 'height'); // Bigdecimal 身高
  output.transferPatientInParameterEo.weight = _.get(input, 'weight'); // Bigdecimal 体重
  output.transferPatientInParameterEo.stayInBed = _.get(input, 'stayInBed'); // Boolean 卧床
  return output;
};
