import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalInstitutionDtoController_getCurrentInstitution_b22926: {
    method: 'post',
    url: '/api/organization/get-current-institution',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalInstitutionVoOrganizationEntranceWebVo,
    ) => data,
  },
  DepartmentDtoController_getPagedBySearchCode_32d6e0: {
    method: 'post',
    url: '/api/organization/get-paged-by-search-code',
    parameterFomatter: (data?: {
      nameLike: string;
      outpOrInp: string;
      branchInstitutionId: number;
      clinicAttribute: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DepartmentDtoOrganizationManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserGroupController_createChargeGroup_e98873: {
    method: 'post',
    url: '/api/user-group/create-charge-group',
    parameterFomatter: (data?: {
      createChargeGroupBvo: CreateChargeGroupBvoUserGroupEntranceWebBvo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChargeGroupDetailVoUserGroupEntranceWebVo,
    ) => data,
  },
});
