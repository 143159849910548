import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { Page, Column, Columns, Tag, Checkbox, Image } from '@vs/vsf-kit';
import Container from '@/module/Container';
import DoctorBackground from '@/assets/doctor_background.png';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SelectTag from '@/pages/Index/components/func/SelectTag';
import storage from '@/utils/storage';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

const weekList = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const SignSelection = (props) => {
  /**
   * SystemConfig
   * @type {object}
   */
  const [list, setList] = useState([]);
  const [selectList, setSelectList] = useState();
  // 是否全选
  const [allSelect, setAllSellect] = useState(false);
  // 显示本科全部挂号
  const [myDepartment, setMyDepartment] = useState(false);

  useEffect(() => {
    getList();
  }, [myDepartment]);

  const getList = async () => {
    const res =
      await vsf?.services?.OutpRegisterController_getTodayClinicRegister_c256f2?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          showDeptAll: myDepartment,
        },
      ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    if (res.code === 200) {
      const _res = res?.data?.map((item) => {
        return {
          ...item,
          isSelect: item?.timeDescription === dayjs().format('A'),
        };
      });
      setList(_res);
    }
  };

  const handelTimeDescription = (value) => {
    if (value === 'AM') {
      return '上午';
    } else {
      return '下午';
    }
  };

  const handelClinicTypeClass = (value) => {
    if (value === 'COMMON') {
      return '普通号';
    } else {
      return '专家号';
    }
  };

  const handelSelect = (value) => {
    const res = list.map((item) => {
      if (item?.clinicRegisterId === value?.clinicRegisterId) {
        return {
          ...item,
          isSelect: !item?.isSelect,
        };
      } else {
        return item;
      }
    });
    setList(res);
  };

  const handelAllSelect = (isSelect) => {
    const res = list.map((item) => {
      return {
        ...item,
        isSelect: isSelect
          ? true
          : false,
      };
    });
    setList(res);
  };

  const renderListItem = (item) => {
    return (
      <div
        className={cn('list_item', item?.isSelect ? 'list_item_select' : '')}
        onClick={() => {
          handelSelect(item);
        }}
      >
        <div className="list_item_left">
          <Checkbox
            defaultValue={item?.timeDescription === dayjs().format('A')}
            checked={item?.isSelect}
          />
        </div>
        <div className="list_item_center">
          <div className="list_item_center_name">{item?.clinicLabel}</div>
          <div className="list_item_center_type">
            {handelClinicTypeClass(item?.clinicTypeClass)}
          </div>
        </div>
        <div className="list_item_right">
          {handelTimeDescription(item?.timeDescription)}
        </div>
      </div>
    );
  };

  return (
    <Container>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="sign_selection">
          <div className="left size">
            <div className="title">***医生中午好</div>
            <div className="tip">欢迎登陆祝您工作愉快~</div>
            <div className="img">
              <Image preview={false} src={DoctorBackground} />
            </div>
            <div className="data">
              <div className="item">
                <Icon type="icon-rili" />
              </div>
              <div className="item">{dayjs().format('YYYY-MM-DD')}</div>
              <div className="item">{weekList[dayjs().format('d')]}</div>
              <div>{dayjs().format('HH:mm')}</div>
            </div>
          </div>
          <div className="right size">
            <div className="title">请选择今日出诊的号别</div>

            <div
              className={cn(
                'list noscroll hideScroll',
                list?.length > 0 ? '' : 'empty',
              )}
            >
              {list?.length > 0 ? (
                list.map((item) => {
                  return renderListItem(item);
                })
              ) : (
                <Empty />
              )}
            </div>
            <div className="select">
              <Checkbox
                onChange={(value) => {
                  handelAllSelect(value);
                  setAllSellect(value);
                }}
              >
                全选
              </Checkbox>
              <Checkbox
                onChange={(value) => {
                  setMyDepartment(value);
                }}
              >
                显示本科全部号别
              </Checkbox>
            </div>
            <div
              className="btn"
              onClick={() => {
                const res = list.filter((item) => {
                  return item?.isSelect;
                });
                vsf?.navigateTo('/index/cpoe/patient-list');

                vsf?.stores?.cpoe?.setSignSelection(res);
                setSelectList(res);
              }}
            >
              进入我的诊室
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default definePage(SignSelection);
