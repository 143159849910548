import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  DatePicker,
  Input,
  message,
  Section,
  Select,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import dayjs from 'dayjs';
import debounce from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CodeTableSelect } from '@/components';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import { DateParticle } from '@/module/cpoe/medical/editor/core/draw/particle/DateParticle';
import { input } from '@/module/cpoe/medical/editor/core/event/handlers/input';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import { Flex, SelectCode } from '@/pages/Index/components';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY, labelColWidth } from '@/utils';

import FaskRiskModal from '../modal/fall_risk_modal';
import {
  calculateMews,
  cheifClassify,
  cheifVitalsign,
  judgeLevel,
} from './calculate';

const PatientAssessment = (props) => {
  const { isRequired, isRequired1, handelColor, list, detail } = props;
  const [data, setData] = useState<any>([]);
  const [data1, setData1] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  // 体征范围list
  const [vitalsign, setVitalsign] = useState<any>([]);
  // 跌倒风险
  const fallRiskRef = useRef<any>();
  // 无生命迹象/拒绝TPRBP/小儿患者
  const requiredRef = useRef<any>();
  // 无生命迹象/拒绝TPRBP
  const requiredRef1 = useRef<any>();
  const [chiefList, setChiefList] = useState<any>([]);

  const [diedaofxModal, setDiedaofxModal] = useState();

  // 多 vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT
  // 少 vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE

  useEffect(() => {
    requiredRef.current = isRequired;
  }, [isRequired]);

  useEffect(() => {
    requiredRef1.current = isRequired1;
  }, [isRequired1]);

  useEffect(() => {
    getAllByChief();
    getAllByVitalsignItem();
  }, []);

  useEffect(() => {
    handelEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, vitalsign]);

  useEffect(() => {
    const res = calculateMewsScore();
    vsf.refs?.preExamMainForm?.setFieldsValue({
      mewsScore: res,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data1, data2]);

  const handelData = (arr = [], size = 3) => {
    const _arr: any = [];
    const residue = arr?.length % 3;
    const length = residue == 0 ? arr?.length / 3 : arr?.length / 3 + 1;
    for (let i = 0; i < 3; i++) {
      const fieldArr: any = [];
      for (let j = 0; j < length; j++) {
        if (j * 3 + i <= arr.length - 1) {
          fieldArr.push(arr[j * 3 + i]);
        }
      }
      _arr.push(fieldArr);
    }

    return _arr;
  };

  const handelEnums = () => {
    const res1 = (vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE ?? [])
      ?.sort((a, b) => {
        return a?.order - b?.order;
      })
      ?.filter(
        (i) =>
          (i?.code != '01' &&
            i?.code != '11' &&
            i?.code != '12' &&
            i?.code != '02' &&
            i?.code != '03' &&
            i?.code !== '16') ||
          (i?.code == '16' && detail?.gender == '2'),
      )
      ?.map((i) => {
        // item item为服务端返回的项目分类的code，itemName是项目分类的名称;itemValue为服务端返回，是项目类型的value，
        // itemClass是项目分类code,itemClassName是项目分类的名称
        const _children = (vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT ?? [])
          ?.sort((a, b) => {
            return a?.order - b?.order;
          })
          ?.filter((item) => {
            return (
              item?.extensionJson &&
              JSON.parse(item?.extensionJson)?.preExamScoreTypeCode == i.code
            );
          })
          ?.map((i) => {
            let _vitalsign: any = null;
            let _vitalsign1: any = null;
            let _vitalsign2: any = null;

            if (i?.code == '62' || i?.code == '63') {
              _vitalsign1 = vitalsign?.find(
                (pre) =>
                  pre?.id ==
                  (
                    i?.children?.[0]?.extensionJson &&
                    JSON.parse(i?.children?.[0]?.extensionJson)
                  )?.vitalsignItemDictionaryId,
              );
              _vitalsign2 = vitalsign?.find(
                (pre) =>
                  pre?.id ==
                  (
                    i?.children?.[1]?.extensionJson &&
                    JSON.parse(i?.children?.[1]?.extensionJson)
                  )?.vitalsignItemDictionaryId,
              );
            } else {
              _vitalsign = vitalsign?.find(
                (pre) =>
                  pre?.id ==
                  (i?.extensionJson && JSON.parse(i?.extensionJson))
                    ?.vitalsignItemDictionaryId,
              );
            }

            return i?.code == '62' || i?.code == '63'
              ? {
                  ...i,
                  inputUpLimitValueShousuo: _vitalsign1?.inputUpLimit ?? null,
                  inputLowLimitValueShousuo: _vitalsign1?.inputLowLimit ?? null,
                  babyInputUpLimitValueShousuo:
                    _vitalsign1?.babyInputUpLimitValue ?? null,
                  babyInputLowLimitValueShousuo:
                    _vitalsign1?.babyInputLowLimitValue ?? null,
                  inputUpLimitValueShuzhang: _vitalsign2?.inputUpLimit ?? null,
                  inputLowLimitValueShuzhang:
                    _vitalsign2?.inputLowLimit ?? null,
                  babyInputUpLimitValueShuzhang:
                    _vitalsign2?.babyInputUpLimitValue ?? null,
                  babyInputLowLimitValueShuzhang:
                    _vitalsign2?.babyInputLowLimitValue ?? null,
                }
              : {
                  ...i,
                  inputUpLimitValue: _vitalsign?.inputUpLimit ?? null,
                  inputLowLimitValue: _vitalsign?.inputLowLimit ?? null,
                  babyInputUpLimitValue:
                    _vitalsign?.babyInputUpLimitValue ?? null,
                  babyInputLowLimitValue:
                    _vitalsign?.babyInputLowLimitValue ?? null,
                };
          });
        console.log(_children, '_children_children');

        if (_children?.length <= 0) {
          const _findItem = list?.find((item) => item?.item == i?.code);

          return {
            ...i,
            id: Math.random(),
            item: i?.code,
            itemName: i?.label,
            itemValue: [_findItem?.itemValue],
            itemClass: i?.code,
            itemClassName: i?.label,
            extensionJson: i?.extensionJson,
            children: _children,
          };
        } else if (_children?.length == 1) {
          const _findItem = list?.find(
            (item) => item?.item == _children?.[0]?.code,
          );

          return {
            ...i,
            id: Math.random(),
            item: _children?.[0]?.code,
            itemName: _children?.[0]?.label,
            itemValue: [_findItem?.itemValue],
            itemClass: i?.code,
            itemClassName: i?.label,
            extensionJson: _children?.[0]?.extensionJson,
            children: _children,
          };
        } else {
          // const _findItem = list
          //   ?.filter((item) => !xueya?.includes(item?.item))
          //   ?.find((item) => item?.item == i?.item);

          if (i?.code == '08') {
            const tiwen = ['05', '06', '07', '25'];
            const _findItemtiwen = list?.find((item) =>
              tiwen?.includes(item?.item),
            );
            const _findChildren = _children?.find(
              (i) => i?.item == _findItemtiwen?.code,
            );

            return {
              ...i,
              id: Math.random(),
              item: !detail?.id ? _children?.[0]?.code : _findItemtiwen?.item,
              itemName: !detail?.id
                ? _children?.[0]?.label
                : _findChildren?.itemName,
              itemValue: [_findItemtiwen?.itemValue],
              itemClass: i?.code,
              itemClassName: i?.label,
              extensionJson: !detail?.id
                ? _children?.[0]?.extensionJson
                : _findChildren?.extensionJson,
              children: _children,
            };
          } else if (i?.code == '19') {
            const xueya = ['10', '11', '12', '13', '26'];

            const _findItemxueya = list?.filter((item) =>
              xueya?.includes(item?.item),
            );

            const _item = _findItemxueya?.some((i) => i?.item == '10')
              ? '62'
              : _findItemxueya?.some((i) => i?.item == '12')
              ? '63'
              : _findItemxueya?.some((i) => i?.item == '26')
              ? '26'
              : null;

            return {
              ...i,
              id: Math.random(),
              item: !detail?.id ? _children?.[0]?.code : _item,
              itemName: _children?.[0]?.label,
              itemValue:
                _item == '26'
                  ? [_findItemxueya?.[0]?.itemValue]
                  : [
                      _findItemxueya?.[0]?.itemValue,
                      _findItemxueya?.[1]?.itemValue,
                    ],
              itemClass: i?.code,
              itemClassName: i?.label,
              children: _children,
            };
          } else {
            const _findItem = list?.find((item) => i?.code == item?.item);
            return {
              ...i,
              id: Math.random(),
              item: _findItem?.item,
              itemName: _children?.[0]?.label,
              itemValue: [_findItem?.[0]?.itemValue],
              itemClass: i?.code,
              itemClassName: i?.label,
              children: _children,
            };
          }
        }
      });

    const __res = handelData(res1);

    setData(__res?.[0]);
    setData1(__res?.[1]);
    setData2(__res?.[2]);
  };

  // 体征范围
  const getAllByVitalsignItem = async () => {
    const res =
      await vsf.services?.BaseCommonController_getAllByVitalsignItemDictionaryQto_1cee6b(
        {
          qto: {},
        },
      );
    setVitalsign(res?.data ?? []);
    return res?.data ?? [];
  };

  // 判定依据
  const getAllByChief = async () => {
    const res =
      await vsf.services.ComplaintJudgeDictionaryController_getAllByChiefComplaintJudgeDictionaryNewQto_989929(
        { qto: {} },
      );

    if (res?.code == 200) {
      const _res = cheifClassify(res?.data ?? []);

      setChiefList(res?.data ?? []);
      return res?.data ?? [];
    }
    return [];
  };

  // 修改的值属于哪一个table
  const handelFind = (item, callback) => {
    const _data = data?.find((i) => i?.itemClass == item);
    const _data1 = data1?.find((i) => i?.itemClass == item);
    const _data2 = data2?.find((i) => i?.itemClass == item);
    const _findData = _data
      ? { label: 'data', value: data }
      : _data1
      ? { label: 'data1', value: data1 }
      : _data2
      ? { label: 'data2', value: data2 }
      : null;

    const _res = callback(_findData);

    if (_findData?.label == 'data') {
      setData([..._res]);
    } else if (_findData?.label == 'data1') {
      setData1([..._res]);
    } else if (_findData?.label == 'data2') {
      setData2([..._res]);
    }
  };

  // 跌倒风险
  const handelDiedaofx = (v) => {
    handelFind('18', (findData) => {
      const _res = findData?.value?.map((i) => {
        return i?.itemClass == '18'
          ? {
              ...i,
              id: Math.random(),
              itemValue:
                v?.name && v?.name?.length > 0
                  ? [v?.name?.map((i) => i?.label)?.join(';')]
                  : [],
            }
          : i;
      });
      return _res;
    });
  };

  const handelChangeChiefComplaint = (type, res) => {
    // 找出对应的判定依据;在原有的判断依据里，过滤为温度得
    // 自动判定得都要过滤，重新判断，还没做，
    const _patientChiefComplaintVsJudgeDictionaryDtoList = (
      vsf.refs?.preExamMainForm?.getFieldsValue()
        ?.patientChiefComplaintVsJudgeDictionaryDtoList ?? []
    )?.filter((i) => i?.chiefComplaintVsJudgeId);

    // 判断依据和带出来的判定依据
    const hasCheckeList1 = [
      ..._patientChiefComplaintVsJudgeDictionaryDtoList,
      ...res,
    ]?.filter((i) => i?.secondId);

    const hasCheckeList2 = [
      ..._patientChiefComplaintVsJudgeDictionaryDtoList,
      ...res,
    ]?.filter((i) => !i?.secondId);
    // 找出最高等级
    let __res: any = {};

    if ([...hasCheckeList1, ...hasCheckeList2]?.length > 0) {
      __res = judgeLevel([...hasCheckeList1, ...hasCheckeList2]);
    }

    // 值回显到form得判定依据/参考病情分级/实际病情分级
    vsf.refs.preExamMainForm.setFieldsValue({
      patientChiefComplaintVsJudgeDictionary: [
        ...hasCheckeList1,
        ...hasCheckeList2,
      ]
        ?.map(
          (item) =>
            (item?.chiefComplaintName ?? item?.secondName ?? '*') +
            ',' +
            item?.chiefComplaintJudgeName,
        )
        .join('；'),
      patientChiefComplaintVsJudgeDictionaryDtoList: [
        ...hasCheckeList1,
        ...hasCheckeList2,
      ],
      referenceDiseaseLevelIds: __res?.diseaseLevel?.diseaseLevelName,
      referenceDiseaseLevelId: {
        ...__res?.diseaseLevel,
        label: __res?.diseaseLevel?.diseaseLevelName,
        value: __res?.diseaseLevel?.diseaseLevelCode,
      },
      diseaseLevel: {
        ...__res?.diseaseLevel,
        label: __res?.diseaseLevel?.diseaseLevelName,
        value: __res?.diseaseLevel?.diseaseLevelCode,
      },
    });
    // handelColor(__res?.diseaseLevel?.colorCode);
    // setTimeout(() => {
    //   handelColor(__res?.diseaseLevel?.colorCode);
    // }, 500);
  };

  const findItem = (code) => {
    const res = [...data, ...data1, ...data2]?.find(
      (i) => i?.itemClass == code,
    );
    return res && res?.itemValue?.[0] ? Number(res?.itemValue?.[0]) : null;
  };

  const getChangeList = () => {
    const hearRate = findItem('04');
    const systolicPressure = findItem('19');
    const breath = findItem('06');
    const bodyTemperature = findItem('08');
    const calculateConsciousness = findItem('15');
    return {
      hearRate,
      systolicPressure,
      breath,
      bodyTemperature,
      calculateConsciousness,
    };
  };

  // 计算MewsScore
  const calculateMewsScore = () => {
    const {
      hearRate,
      systolicPressure,
      breath,
      bodyTemperature,
      calculateConsciousness,
    } = getChangeList();

    const res = calculateMews(
      hearRate,
      systolicPressure,
      breath,
      bodyTemperature,
      calculateConsciousness,
    );
    return res;
  };

  // table值发生变化时
  const handelTableChange = (v, row, tableId) => {
    if (tableId == 'patientAssessmentFirst') {
      setData(v);
    } else if (tableId == 'patientAssessmentSecond') {
      setData1(v);
    } else if (tableId == 'patientAssessmentThird') {
      setData2(v);
    }

    // 判定依据自动判定
    if (row?.data?.itemClass == '08') {
      // 找出温度对应的判定依据
      const res = cheifClassify(chiefList, '08', row?.data?.itemValue?.[0]);
      handelChangeChiefComplaint('08', res);
    }
    // PEWS计算 心率(脉搏)、收缩压、呼吸、体温、意识(AVPU反应) 找出itemClass
    const _codeList = ['04', '19', '06', '08', '15'];

    const res = calculateMewsScore();

    vsf.refs?.preExamMainForm?.setFieldsValue({
      mewsScore: res,
    });
  };

  const RenderItem: any = (props) => {
    const { schema, config, onChange, ...rest } = props;

    const _showLabel =
      config?.record?.children?.length == 1
        ? config?.record?.itemName
        : config?.record?.itemClassName;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} {...rest}>
        {config?.record?.children?.length > 1 && (
          <div style={{ width: 100, marginRight: 10 }}>
            <SelectCode
              allowClear
              dataSource={config?.record?.children}
              // defaultValue={detail?.id?(config?.record?.children?.[0] ?? null)}
              {...schema?.originProps}
              value={config?.record?.item}
              onChange={onChange}
            />
          </div>
        )}

        <Ellipsis content={_showLabel}>
          {_showLabel}
          {config?.record?.item}/{config?.record?.itemClass}
        </Ellipsis>
      </div>
    );
  };

  const RenderItemValue: any = (props) => {
    const { schema, config, onChange, ...rest } = props;
    // 04:脉搏
    const _inputType = ['05', '14', '08', '04', '45', '19'];
    // 15:AVPU
    const _selectType = ['13', '15'];
    // 末次月经
    const datePick = ['16'];

    const unit = {
      '08': '℃',
      '13': '',
      '15': '',
      '16': '',
      '19': 'mmHg',
      '14': '%',
      '17': 'mmol/L',
      '06': '次/分',
      '04': '次/分',
      '18': '',
    };

    const _result = cheifVitalsign(config?.record?.itemValue, config?.record);
    if (_inputType?.includes(config?.record?.itemClass)) {
      const _showLabel =
        config?.record?.children?.length == 1
          ? config?.record?.itemName
          : config?.record?.itemClassName;

      return (
        <div style={{ display: 'flex' }}>
          <Input
            {...schema?.originProps}
            value={config?.record?.itemValue?.[0]}
            onChange={(v) => {
              if (v?.target?.value && v?.target?.value?.length > 0) {
                onChange([v?.target?.value, config?.record?.itemValue?.[1]]);
              } else {
                if (config?.record?.itemClass == '19') {
                  onChange([null, config?.record?.itemValue?.[1]]);
                } else {
                  onChange([]);
                }
              }
            }}
            allowClear
            placeholder={
              config?.record?.itemClass == '19'
                ? '收缩压'
                : '请输入' + _showLabel
            }
            addonAfter={
              config?.record?.itemClass == '19'
                ? null
                : unit?.[config?.record?.itemClass]
            }
            className={
              config?.record?.itemClass == '19'
                ? _result?.[0]
                  ? ''
                  : 'inputColor'
                : _result
                ? ''
                : 'inputColor'
            }
          />
          {config?.record?.itemClass == '19' && (
            <span className="marginRight5 marginLeft10">/</span>
          )}
          {config?.record?.itemClass == '19' && (
            <Input
              {...schema?.originProps}
              value={config?.record?.itemValue?.[1]}
              onChange={(v) => {
                if (v?.target?.value && v?.target?.value?.length > 0) {
                  onChange([config?.record?.itemValue?.[0], v?.target?.value]);
                } else {
                  onChange([config?.record?.itemValue?.[0], null]);
                }
              }}
              allowClear
              placeholder={
                config?.record?.itemClass == '19'
                  ? '舒张压'
                  : '请输入' + _showLabel
              }
              className={
                config?.record?.itemClass == '19'
                  ? _result?.[1]
                    ? ''
                    : 'inputColor'
                  : _result
                  ? ''
                  : 'inputColor'
              }
            />
          )}

          {config?.record?.itemClass == '19' && (
            <span className="marginRight5 marginLeft10">
              {unit?.[config?.record?.itemClass]}
            </span>
          )}
        </div>
      );
    } else if (_selectType?.includes(config?.record?.itemClass)) {
      const _dataSource = {
        '13': vsf.stores.dbenums?.enums?.BREATH_STATE_DICT,
        '15': vsf.stores.dbenums.enums.AVPU_REACT_DICT,
      };
      return (
        <SelectCode
          {...schema?.originProps}
          value={config?.record?.itemValue?.[0]}
          onChange={(v) => {
            if (v) {
              onChange([v]);
            } else {
              onChange([]);
            }
          }}
          dataSource={_dataSource?.[config?.record?.itemClass] ?? []}
          allowClear
          placeholder={'请选择' + config?.record?.itemName}
        />
      );
    } else if (config?.record?.itemClass == '16') {
      return (
        <DatePicker
          allowClear
          format="YYYY-MM-DD"
          {...schema.originProps}
          value={
            config?.record?.itemValue ? Number(config?.record?.itemValue) : null
          }
          // onChange={onChange}
          onChange={(v) => {
            if (v) {
              onChange([v]);
            } else {
              onChange([]);
            }
          }}
          width="100%"
          disabledDate={(current) => {
            return current && current > dayjs();
          }}
        />
      );
    } else if (config?.record?.itemClass == '18') {
      // 跌倒风险
      return (
        <Input
          value={config?.record?.itemValue?.[0]}
          onClick={() => fallRiskRef?.current?.handelOpen(true, diedaofxModal)}
          onChange={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      );
    } else {
      return (
        <Input
          {...schema?.originProps}
          value={config?.record?.itemValue?.[0]}
          onChange={(v) => {
            if (v?.target?.value?.length > 0) {
              onChange([v?.target?.value]);
            } else {
              onChange([]);
            }
          }}
          addonAfter={unit?.[config?.record?.itemClass]}
          allowClear
          className={_result ? '' : 'inputColor'}
        />
      );
    }
  };

  const renderTable = (tableId, value) => {
    return (
      <div>
        <VSTable
          id={tableId}
          className="
      aspirin-table-nopadding
    aspirin-table-header-background-color
     aspirin-table-row-hover-background-color
     aspirin-table-body-empty-transparent-background-color
    "
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
          }}
          pagination={false}
          bordered
          value={value}
          onChange={(v, row) => handelTableChange(v, row, tableId)}
        >
          <VSTableColumn
            dataIndex={['item']}
            title="评估项目"
            valueType="custom"
            width={isMinScreen ? 120 : 170}
            fieldProps={{
              showSearch: true,
            }}
            formItemProps={(_, config: any) => {
              return {
                rules: [
                  {
                    required:
                      config?.entry?.itemClass == '19'
                        ? requiredRef?.current
                        : config?.entry?.itemClass == '08'
                        ? requiredRef1?.current
                        : false,
                  },
                ],
              };
            }}
            renderFormItem={(schema, config, form, action) => {
              return (
                <RenderItem
                  config={config}
                  schema={schema}
                  onChange={schema.originProps.onChange}
                />
              );
            }}
          />

          <VSTableColumn
            dataIndex={['itemValue']}
            valueType="custom"
            title="项目值"
            width={isMinScreen ? 140 : 200}
            formItemProps={(_, config: any) => {
              return {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      const _value1 = value?.[0];
                      const _value2 = value?.[1];
                      // 其他需要必填判断得数组
                      //脉搏/血氧饱和度/AVPU/呼吸
                      const _arr: any = ['04', '14', '15', '06'];
                      if (config?.entry?.itemClass == '08') {
                        if (isRequired1) {
                          if (_value1 && _value1?.length > 0) {
                            callback();
                          } else {
                            callback('请输入体温');
                          }
                        } else {
                          callback();
                        }
                      } else if (config?.entry?.itemClass == '19') {
                        if (isRequired) {
                          if (
                            _value1 &&
                            _value1?.length > 0 &&
                            _value2 &&
                            _value2?.length > 0
                          ) {
                            callback();
                          } else {
                            callback('请输入血压');
                          }
                        } else {
                          callback();
                        }
                      } else if (_arr?.includes(config?.entry?.itemClass)) {
                        if (isRequired) {
                          if (_value1 && _value1?.length > 0) {
                            callback();
                          } else {
                            callback(config?.entry?.itemName);
                          }
                        } else {
                          callback();
                        }
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              };
            }}
            renderFormItem={(schema, config, form, action) => {
              return (
                <RenderItemValue
                  config={config}
                  schema={schema}
                  onChange={schema.originProps.onChange}
                />
              );
            }}
          />
        </VSTable>
      </div>
    );
  };

  return (
    <div className="patientAssessmentTable">
      <Space.Compact style={{ width: '100%' }}>
        {renderTable('patientAssessmentFirst', data)}
        {renderTable('patientAssessmentSecond', data1)}
        {renderTable('patientAssessmentThird', data2)}
      </Space.Compact>
      {/* 跌倒风险 */}
      <FaskRiskModal ref={fallRiskRef} handelDiedaofx={handelDiedaofx} />
    </div>
  );
};

export default React.memo(PatientAssessment);
