import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Cascader,
  Checkbox,
  CheckboxGroup,
  message,
  Modal,
  Section,
  Select,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import LabItem from '@/pages/Index/manage/finance/labItem/tabs/LabItem';
import { positiveInteger, positiveIntegerMessage } from '@/pattern';

import {
  convertAddCountErpAccidentEventRecordForm2AddCountErpAccidentEventRecordReq,
  convertErpAccidentEventRecordDetailBatchSearchVoByErpAccidentEventRecordDetailBatchSearchTable2SaveSingleErpAccidentEventRecordFuncFlowExecutorReq,
} from '../convert';
const Index = (props, ref) => {
  const { addPatient, addEvent } = props;

  const [open, setOpen] = useState(false);

  const [nameList, setNameList] = useState([]);

  // form
  const [formValue, setFormValue] = useState<any>();

  useEffect(() => {
    if (formValue) {
      setTimeout(() => {
        vsf.refs.createNewAccidentEventForm?.setFieldsValue({
          ...formValue,
          eventCode: formValue?.eventRecordEventCodeIs,
          eventName:
            formValue?.eventRecordEventNameIs?.eventName ??
            formValue?.eventRecordEventNameIs,
        });
      }, 100);
    } else {
      setTimeout(() => {
        vsf.refs.createNewAccidentEventForm?.setFieldsValue({});
      }, 100);
    }
  }, [formValue, open]);

  const handelOpen = (v = false, value?) => {
    setOpen(v);
    setFormValue(value);
  };
  // 选择事件后查询名称

  const submit = async () => {
    await vsf.refs.createNewAccidentEventForm?.validateFieldsReturnFormatValue();
    const _formValue = vsf.refs.createNewAccidentEventForm?.getFieldsValue();
    if (formValue) {
      // 新增患者
      const res1 =
        await vsf?.services?.ClinicErpPreExamController_addCountErpAccidentEventRecord_a23090?.(
          {
            btoParam: { ..._formValue, id: _formValue?.eventId },
            extApiParam: {},
          },
        );
      if (res1?.code == 200) {
        message.success('新增患者成功');
        addPatient(res1?.data?.addErpAccidentEventRecordDetailIdList);
        handelOpen(false);
      }
    } else {
      // 新增事件
      // const _name = nameList?.some((i) => i === _formValue?.eventName);
      // if (_name) return message.info('已有重复的事件名称，请重新输入事件名称');
      const res =
        await vsf?.services?.ClinicErpPreExamController_createNewAccidentEvent_e5b269(
          {
            saveErpAccidentEventRecordMasterBto: {
              ..._formValue,
              eventPatientCount: _formValue?.addCount,
            },
          },
        );
      if (res?.code == 200) {
        message.success('新增事件成功');
        addEvent({
          ..._formValue,
          eventPatientCount: _formValue?.addCount,
        });
        handelOpen(false);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={600}
        open={open}
        bodyStyle={{
          height: 250,
          //   padding: 0,
        }}
        okText="确认 "
        cancelText="取消 "
        title={
          <div style={{ color: '#3276E8', fontWeight: 'bolder' }}>
            突发事件批量分诊信息
          </div>
        }
        onOk={() => {
          submit();
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <VSForm id="createNewAccidentEventForm" labelAlign="left">
          <VSFormItem
            name={['eventId']}
            label="事件id"
            valueType="text"
            hidden
          />
          <VSFormLayout labelWidth={100} columnCount={1}>
            <VSFormItem
              name={['eventCode']}
              label="事件类型"
              valueType="codeTableSelect"
              dataSource={vsf.stores.dbenums.enums.EMERGENCY_EVENT_DICT}
              fieldProps={{
                showSearch: true,
                disabled: formValue,
                onChange: async (v) => {
                  const res =
                    await vsf?.services?.ClinicErpPreExamController_queryErpAccidentEventRecordMasterSearchVoByErpAccidentEventRecordMasterEventNameQto_93a2df?.(
                      {
                        qto: {
                          eventCodeIs: v,
                        },
                      },
                    );
                  setNameList(
                    (res?.data ?? [])?.map((i) => {
                      return i?.eventName;
                    }),
                  );
                },
              }}
              rules={[{ required: formValue ? false : true }]}
            />

            <VSFormItem
              name={['eventName']}
              label="事件名称"
              valueType="text"
              fieldProps={{ disabled: formValue }}
              rules={[{ required: formValue ? false : true }]}
            />

            <VSFormItem
              name={['addCount']}
              label="增加人数"
              valueType="digit"
              fieldProps={{ controls: true, className: 'widthAutoFill' }}
              rules={[
                {
                  pattern: positiveInteger,
                  message: positiveIntegerMessage,
                },
                { required: true },
              ]}
            />
            {/* <VSFormItem
              name={['eventPatientCount']}
              label="事件发生日期自动生成患者id个数"
              valueType="text"
              fieldProps={{}}
             
            /> */}
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 增加患者
