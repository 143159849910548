import { createServices } from '@vs/vsf-boot';
export default createServices({
  OutpVisitEncounterController_queryPagefallByFallOutpVisitEncounterQto_d1ac9a:
    {
      method: 'post',
      url: '/api/clinic-outp-visit/query-pagefall-by-fall-outp-visit-encounter-qto',
      parameterFomatter: (data?: {
        qto: FallOutpVisitEncounterQtoClinicOutpVisitPersistQto;
        ext: any;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  OutpVisitEncounterController_queryOutpVisitEncounterHistory_06bf7b: {
    method: 'post',
    url: '/api/clinic-outp-visit/query-outp-visit-encounter-history',
    parameterFomatter: (data?: {
      qto: OutpVisitEncounterHistoryQtoClinicOutpVisitPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OutpVisitEncounterQueryVoClinicOutpVisitEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
