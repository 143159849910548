import React, { useState, useEffect, useRef, createContext } from 'react';
import {
  Radio,
  VSForm,
  message,
  VSFormLayout,
  VSFormItem,
  Image,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { labelColWidth } from '@/utils';
import { CertificateSearch } from '@/components';
import readCard from '@/assets/icon/readCard.png';

import '@/style/adaptation.less';

export default function checkTitle(props) {
  const {
    getPatientID,
    getCheckList,
    tabValue,
    tabTimer,
    timer,
    setTimer,
    setSearchInp,
  } = props;

  return (
    <VSForm vsid="17359" labelAlign="left" id="ExportRecord">
      <VSFormItem
        name={['id']}
        label="主键"
        style={{ display: 'none' }}
        valueType="text"
        fieldProps={{}}
        id="searchTop"
      />
      <VSFormLayout key="1" columnCount={11} labelCol={labelColWidth[1]}>
        <VSFormItem
          name={['patientIdIs']}
          label="患者ID"
          valueType="text"
          fieldProps={{
            onPressEnter: async (e) => {
              // await getPatientID()
              // console.log(
              //   vsf?.refs?.ExportRecord?.getFieldValue()?.patientIdIs,
              //   '111',
              // );
              // console.log(value, '111');
              await getPatientID(
                vsf?.refs?.ExportRecord?.getFieldValue()?.patientIdIs,
              );
              await getCheckList();
            }, //1000014801
            className: 'patientIdIs',
            suffix: (
              <div
                className="suffix-readCard"
                onClick={() => {
                  message.success('读卡中');
                }}
              >
                <div className="suffix-cardImg">
                  <Image
                    src={readCard}
                    className="suffix-cardImg-image"
                    preview={false}
                  ></Image>
                </div>
                <div className="suffix-cardText">读卡</div>
              </div>
            ),
            placeholder: '请读卡',
          }}
        />
        <VSFormItem
          label="姓名"
          name={['name']}
          className="width180"
          valueType="text"
          fieldProps={{
            disabled: true,
            precision: 2,
            placeholder: '',
          }}
        />
        <VSFormItem
          label="性别"
          name={['gender']}
          valueType="treeSelect"
          className="width100"
          fieldProps={{
            showSearch: true,
            showArrow: false,
            disabled: true,
            precision: 2,
            dataSource: vsf?.stores?.dbenums?.enums?.SEX_DICT,
            placeholder: '',
          }}
        />
        <VSFormItem
          label="年龄"
          name={['age']}
          className="width100"
          valueType="text"
          fieldProps={{
            width: '100%',
            // addonAfter: '元',
            disabled: true,
            precision: 2,
            placeholder: '',
          }}
        />
        <VSFormItem
          label="费别"
          name={['defaultChargeType']}
          valueType="treeSelect"
          className="width200"
          fieldProps={{
            showArrow: false,
            disabled: true,
            precision: 2,
            dataSource: vsf?.stores?.dbenums?.enums?.CHARGE_TYPE_DICT,
            placeholder: '',
          }}
        />
        <VSFormItem
          label="身份证"
          name={['idNumber']}
          valueType="text"
          fieldProps={{
            width: '100%',
            disabled: true,
            precision: 2,
            placeholder: '',
          }}
        />
        <VSFormItem
          label="手机号"
          name={['cellphone']}
          valueType="text"
          fieldProps={{
            width: '100%',
            disabled: true,
            precision: 2,
            placeholder: '',
          }}
        />
      </VSFormLayout>
      <VSFormLayout key="2" columnCount={10} labelCol={labelColWidth[1]}>
        <>
          <Radio.Group
            buttonStyle="solid"
            value={tabValue.value}
            id="checkTabValue"
            onChange={(value) => {
              tabValue?.onChange(value);
              getCheckList(value);
            }}
            optionType="button"
            dataSource={[
              {
                value: 'EXAM',
                label: '检查',
              },
              {
                value: 'LAB',
                label: '检验',
              },
            ]}
          />
        </>
        <VSFormItem
          name={['submitDateTime']}
          label=""
          id="checkSubmitDateTime"
          valueType="dateRange"
          transform={(values) => {
            if (values[0] === 'NaN' || values[1] === 'NaN') return null;
            return {
              createDateTimeRangeIn: {
                begin: values[0],
                end: values[1],
                beginInclude: false,
                endInclude: true,
              },
            };
          }}
          initialValue={timer}
          fieldProps={{
            width: '120%',
            onCalendarChange: async (value) => {
              let time = [
                value[0].format('YYYY-MM-DD HH:mm:ss'),
                value[1].format('YYYY-MM-DD HH:mm:ss'),
              ];
              setTimer(time);
              await tabTimer();
            },
          }}
        />
      </VSFormLayout>
    </VSForm>
  );
}
