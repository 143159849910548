import vsf, { definePage } from '@vs/vsf-boot';
import { useEffect, useState } from 'react';
import { getHerbWaitDispenseDetailByMasterId } from './service';
import { useRequest } from 'ahooks';
import { Descriptions } from '@vs/vsf-kit';
const DrugInfo = ({ id }) => {
  const { data: HerbWaitDispenseData, run: HerbWaitDispenseRun } = useRequest(
    getHerbWaitDispenseDetailByMasterId,
    { manual: true },
  );
  useEffect(() => HerbWaitDispenseRun?.({ id }), [id]);
  const onChangeStyle = (param) => (!param ? { display: 'none' } : {});
  return (
    <Descriptions
      column={4}
      size="default"
      style={onChangeStyle(HerbWaitDispenseData)}
      className="DrugInfoContainer"
    >
      <Descriptions.Item label="处方日期：">
        {HerbWaitDispenseData?.prescriptionDate}
      </Descriptions.Item>
      <Descriptions.Item label="处方来源：">
        {HerbWaitDispenseData?.prescriptionSource}
      </Descriptions.Item>
      <Descriptions.Item label="处方号：">
        {HerbWaitDispenseData?.prescriptionNumber}
      </Descriptions.Item>
      <Descriptions.Item label="开单医生：">
        {HerbWaitDispenseData?.staffName}
      </Descriptions.Item>
      <Descriptions.Item label="病区：">
        {HerbWaitDispenseData?.wardName}
      </Descriptions.Item>
      <Descriptions.Item label="床号：">
        {HerbWaitDispenseData?.bedLabel}
      </Descriptions.Item>
      <Descriptions.Item label="协定方名称：">
        {HerbWaitDispenseData?.agreedPrescriptionName}
      </Descriptions.Item>
      <Descriptions.Item label="诊断：">
        {HerbWaitDispenseData?.diagnosisName}
      </Descriptions.Item>
      <Descriptions.Item label="处方类型：">
        {HerbWaitDispenseData?.herbType}
      </Descriptions.Item>
      <Descriptions.Item label="用法描述：">
        {HerbWaitDispenseData?.useWay}
      </Descriptions.Item>
      <Descriptions.Item label="剂数：">
        {HerbWaitDispenseData?.herbRegimen}
      </Descriptions.Item>
      <Descriptions.Item label="均贴价：">
        {HerbWaitDispenseData?.averageCharge}
      </Descriptions.Item>
      <Descriptions.Item label="总金额：">
        {HerbWaitDispenseData?.totalCharge}
      </Descriptions.Item>
      <Descriptions.Item label="煎药方式：">
        {HerbWaitDispenseData?.decoctMethod}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default definePage(DrugInfo);
