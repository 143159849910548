import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicItemController_allClinicItemDictionaryCharge_d97a65: {
    method: 'post',
    url: '/api/clinic-item-base/all-clinic-item-dictionary-charge',
    parameterFomatter: (data?: {
      qto: ClinicItemDictionaryQtoSimpleClinicItemBasePersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemController_getFavoriteClinicItem_fa660a: {
    method: 'post',
    url: '/api/clinic-item-base/get-favorite-clinic-item',
    parameterFomatter: (data?: {
      favoriteType: FavoriteTypeEnum;
      itemClass: ClinicItemClassEnum;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo[],
    ) => data,
  },
  ClinicItemController_getClinicItemDictionaryLabById_ada097: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-lab-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryLabVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
  ClinicItemController_getClinicItemDictionaryChargeById_5fd2a1: {
    method: 'post',
    url: '/api/clinic-item-base/get-clinic-item-dictionary-charge-by-id',
    parameterFomatter: (data?: { clinicItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ClinicItemDictionaryChargeVoClinicItemBaseEntranceWebVo,
    ) => data,
  },
});
