import { createServices } from '@vs/vsf-boot';
export default createServices({
  PatientLabelConfigController_savePatientLabelConfigList_25782e: {
    method: 'post',
    url: '/api/base-parameter/save-patient-label-config-list',
    parameterFomatter: (data?: {
      btoParamList: SavePatientLabelConfigBtoBaseParameterServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientLabelConfigVoBaseParameterEntranceWebVo[],
    ) => data,
  },
  PatientLabelConfigController_getListPatientLabelConfigVoByStaffId_c961eb: {
    method: 'post',
    url: '/api/base-parameter/get-list-patient-label-config-vo-by-staff-id',
    parameterFomatter: (data?: { staffId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: PatientLabelConfigVoBaseParameterEntranceWebVo[],
    ) => data,
  },
});
