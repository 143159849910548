import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Column, Columns, Modal } from '@vs/vsf-kit';
import React, { Fragment, useState } from 'react';
import ClinicTable from './table';
import Container from '@/module/Container';
import Tree from './tree';
const ClinicManage = (props) => {
  const [selected, setSelected] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const clearPageNo = () => {
    setPageNo(1);
  };
  return (
    <Fragment>
      <Container
        layout={{
          items: [
            {
              gap: '16px',
              // block: '392px',
              block: '20%',
              bgColor: 'var(--background)',
            },
            {
              block: 1,
              bgColor: 'var(--background)',
              padding: '24px 24px',
            },
          ],
        }}
      >
        <Tree
          onChange={(value) => {
            // console.log('点击');
            clearPageNo();
            setSelected(value);
          }}
        />
        <ClinicTable value={selected} pageNo={pageNo} setPageNo={setPageNo} />
      </Container>
    </Fragment>
  );
};
export default definePage(ClinicManage);
