import { createServices } from '@vs/vsf-boot';
export default createServices({
  DispenseWindowSettingController_removeDispenseWindowSetting_44044e: {
    method: 'post',
    url: '/api/drug-prescription-dispense/remove-dispense-window-setting',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  DispenseWindowSettingController_getDispenseWindowSettingPrescriptionCountList_b440b4:
    {
      method: 'post',
      url: '/api/drug-prescription-dispense/get-dispense-window-setting-prescription-count-list',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: DispenseWindowSettingWithPrescriptionCountVoDrugPrescriptionDispenseEntranceWebVo[],
      ) => data,
    },
  DispenseWindowSettingController_saveDispenseWindowSettingList_337cf1: {
    method: 'post',
    url: '/api/drug-prescription-dispense/save-dispense-window-setting-list',
    parameterFomatter: (data?: {
      btoList: CreateDispenseWindowSettingBtoDrugPrescriptionDispenseServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: number[]) => data,
  },
});
