import { createServices } from '@vs/vsf-boot';
export default createServices({
  DrugStoragePurchaseController_createPurchasePlans_935974: {
    method: 'post',
    url: '/api/drug-storage-purchase/create-purchase-plans',
    parameterFomatter: (data?: {
      eo: CreatePurchasePlanInputEoDrugStoragePurchasePersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPurchasePlanOutVoDrugStoragePurchaseEntranceWebVo[],
    ) => data,
  },
  DrugStoragePurchaseController_getByDrugPriceItemId_8fd8db: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-by-drug-price-item-id',
    parameterFomatter: (data?: { drugPriceItemId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPurchasePlanOutVoDrugStoragePurchaseEntranceWebVo,
    ) => data,
  },
  DrugStoragePurchaseController_getByMasterId_0d5695: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-by-master-id',
    parameterFomatter: (data?: { masterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: SavePurchasePlanMasterListVoDrugStoragePurchaseEntranceWebVo,
    ) => data,
  },
  DrugStoragePurchaseController_getPlanDetailByPlanMasterId_e03c43: {
    method: 'post',
    url: '/api/drug-storage-purchase/get-plan-detail-by-plan-master-id',
    parameterFomatter: (data?: { planMasterId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: DrugPurchasePlanOutVoDrugStoragePurchaseEntranceWebVo[],
    ) => data,
  },
  DrugStoragePurchaseController_savePurchasePlan_df26d5: {
    method: 'post',
    url: '/api/drug-storage-purchase/save-purchase-plan',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateSavePurchaseBtoDrugStoragePurchaseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugStoragePurchaseController_submitPurchasePlan_a9b0a3: {
    method: 'post',
    url: '/api/drug-storage-purchase/submit-purchase-plan',
    parameterFomatter: (data?: {
      btoParam: CreateOrUpdateSavePurchaseBtoDrugStoragePurchaseServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  DrugDrugController_getPagedProfileDrugProduct_9f4f4f: {
    method: 'post',
    url: '/api/drug-drug/query-paged-profile-drug-product',
    parameterFomatter: (data?: {
      query: DrugPriceItemForDrugApplyDropEoDrugDrugPersistEo;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: DrugPriceItemProductFirmDictionaryVoDrugDrugEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
});
