import { createServices } from '@vs/vsf-boot';
export default createServices({
  ExamBodyDictionaryDtoController_getByExamClassId_99e20a: {
    method: 'post',
    url: '/api/exam/get-body-by-exam-class-id',
    parameterFomatter: (data?: { examClassId: number }) => data,
    responseFormatter: (_, __, data?: ExamBodyVoExamEntranceWebVo[]) => data,
  },
  ExamClassDictionaryBOController_addAndUpdateExamBody_f95b77: {
    method: 'post',
    url: '/api/exam/add-and-update-exam-body',
    parameterFomatter: (data?: {
      addBodyToExamClassBtoList: AddBodyToExamClassBtoExamServiceBto[];
      updateExamBodyBtoList: UpdateExamBodyBtoExamServiceBto[];
      removeBodyFromExamClassBtoList: RemoveBodyFromExamClassBtoExamServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});
